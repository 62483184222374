import { CreatorDraftContent, CreatorSocialProfile, DraftContent } from '@momentum/routes/campaign/context/queries'
import { FormMethodsType } from '@productwindtom/ui-base'

export type CreatorContent = CreatorDraftContent & {
  content: DraftContent
}

export type ContentEditsFormData = {
  shouldSendToCreator?: boolean
  message: string
  atTime?: string
  visibleToUser?: boolean
}

export type ApproveContentContextType = {
  handle: string
  handleLink?: string
  socialProfile?: CreatorSocialProfile
  approveContentViewMode?: ApproveContentViewMode
  setApproveContentViewMode: React.Dispatch<React.SetStateAction<ApproveContentViewMode | undefined>>
  creator: CreatorContent['creator']
  content: CreatorContent['content']
  handleApproveContent: () => Promise<void>
  handleToggleContentVisibility: (isHidden: boolean) => Promise<void>
  handleContentEdits: (
    data: ContentEditsFormData,
    { reset }: FormMethodsType
  ) => Promise<void>
  selectedAsset: number
  setSelectedAsset: React.Dispatch<React.SetStateAction<number>>
  currentVideoPosition: number
  setCurrentVideoPosition: React.Dispatch<React.SetStateAction<number>>
}

export enum ApproveContentViewMode {
  REVIEW_CONTENT = 'REVIEW_CONTENT',
  REQUEST_EDITS = 'REQUEST_EDITS'
}

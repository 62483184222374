import { SvgIcon, SvgIconProps } from '@mui/material'

export default function PackageIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none">
      <path
        d="M14.6617 5.3001C14.6597 5.2521 14.655 5.20343 14.6423 5.15743C14.6377 5.14077 14.6283 5.12477 14.6223 5.1081C14.6083 5.0701 14.5957 5.03277 14.5757 4.9981C14.565 4.9801 14.5503 4.96543 14.5377 4.9481C14.5163 4.9181 14.4957 4.88743 14.4697 4.86143C14.4543 4.84677 14.4343 4.83477 14.4177 4.82077C14.3917 4.79943 14.3677 4.7761 14.3377 4.7581C14.335 4.7561 14.3317 4.7561 14.3283 4.7541C14.3263 4.75277 14.325 4.75143 14.323 4.7501L8.33701 1.42343C8.13634 1.31143 7.89234 1.3121 7.69034 1.42277L1.67634 4.74943C1.67434 4.75143 1.67234 4.7541 1.66901 4.7561C1.66701 4.75677 1.66434 4.75743 1.66234 4.75877C1.63901 4.7721 1.62167 4.79143 1.59967 4.80743C1.57567 4.82543 1.55034 4.84143 1.52901 4.8621C1.50901 4.88277 1.49367 4.90677 1.47634 4.9301C1.45834 4.95343 1.43834 4.9741 1.42367 4.99943C1.40634 5.0281 1.39701 5.06077 1.38434 5.0921C1.37501 5.1141 1.36301 5.13477 1.35701 5.15877C1.34434 5.20343 1.34034 5.25143 1.33767 5.29877C1.33701 5.3101 1.33301 5.3201 1.33301 5.3321V5.33277V5.33343V10.6668C1.33301 10.9088 1.46434 11.1321 1.67634 11.2494L7.66167 14.5741L7.66234 14.5748C7.66301 14.5748 7.66301 14.5748 7.66367 14.5754L7.67701 14.5828C7.70567 14.5988 7.73701 14.6074 7.76701 14.6188C7.78834 14.6274 7.80901 14.6388 7.83167 14.6448C7.88567 14.6588 7.94301 14.6668 7.99967 14.6668C8.05634 14.6668 8.11367 14.6588 8.16901 14.6448C8.19101 14.6388 8.21167 14.6274 8.23367 14.6188C8.26367 14.6074 8.29501 14.5994 8.32367 14.5828L8.33701 14.5754C8.33767 14.5748 8.33767 14.5748 8.33834 14.5748L8.33901 14.5741L14.3243 11.2494C14.535 11.1321 14.6663 10.9088 14.6663 10.6668V5.33343V5.33277V5.3321C14.6663 5.32077 14.6623 5.31143 14.6617 5.3001ZM7.98101 7.91477L3.37167 5.33677L5.21301 4.3181L9.76901 6.92143L7.98101 7.91477ZM8.01301 2.76877L12.627 5.33343L11.131 6.16477L6.57767 3.56277L8.01301 2.76877ZM2.66634 6.4701L7.33301 9.08077V12.8674L2.66634 10.2741V6.4701ZM8.66634 12.8674L8.66767 9.08143L10.6663 7.96277V10.0001L11.9997 9.33343V7.21677L13.333 6.47077V10.2741L8.66634 12.8674Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

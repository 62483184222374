import { SvgIconProps, SvgIcon } from '@mui/material'

export default function USFlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 50 50" fill="none">
      <path
        d="M49.1378 41.7039H0.862108C0.386034 41.7039 0 41.3179 0 40.8418V9.80742C0 9.33135 0.386034 8.94531 0.862108 8.94531H49.1379C49.614 8.94531 50 9.33135 50 9.80742V40.8418C49.9999 41.318 49.6139 41.7039 49.1378 41.7039Z"
        fill="#F5F5F5"
      />
      <path
        d="M49.9999 11.4653H0V9.80742C0 9.33135 0.386034 8.94531 0.862108 8.94531H49.1379C49.614 8.94531 50 9.33135 50 9.80742L49.9999 11.4653Z"
        fill="#FF4B55"
      />
      <path d="M49.9999 19.0244H0V21.5443H49.9999V19.0244Z" fill="#FF4B55" />
      <path d="M49.9999 13.9844H0V16.5043H49.9999V13.9844Z" fill="#FF4B55" />
      <path
        d="M49.9999 26.5845H0.862108C0.386034 26.5845 0 26.1984 0 25.7224V24.0645H49.9999V26.5845Z"
        fill="#FF4B55"
      />
      <path d="M49.9999 34.1445H0V36.6644H49.9999V34.1445Z" fill="#FF4B55" />
      <path
        d="M49.1378 41.7035H0.862108C0.386034 41.7035 0 41.3175 0 40.8414V39.1836H49.9999V40.8415C49.9999 41.3176 49.6139 41.7035 49.1378 41.7035Z"
        fill="#FF4B55"
      />
      <path d="M49.9999 29.1045H0V31.6244H49.9999V29.1045Z" fill="#FF4B55" />
      <path
        d="M22.4137 8.94531H0.862108C0.386034 8.94531 0 9.33125 0 9.80732V25.7223C0 26.1984 0.386034 26.5844 0.862108 26.5844H22.4138C22.8899 26.5844 23.2759 26.1984 23.2759 25.7223V9.80732C23.2758 9.33125 22.8898 8.94531 22.4137 8.94531Z"
        fill="#41479B"
      />
      <path
        d="M2.42152 10.9237L2.61234 11.496L3.21556 11.5006C3.29368 11.5013 3.32601 11.6009 3.26321 11.6473L2.77796 12.0057L2.95999 12.5808C2.98363 12.6552 2.89876 12.7169 2.83519 12.6714L2.34447 12.3207L1.85375 12.6715C1.79017 12.7169 1.7055 12.6553 1.72894 12.5809L1.91097 12.0058L1.42572 11.6474C1.36293 11.6009 1.39525 11.5014 1.47337 11.5007L2.0766 11.4961L2.26742 10.9238C2.29203 10.8496 2.39671 10.8496 2.42152 10.9237Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.42152 13.9168L2.61234 14.4891L3.21556 14.4937C3.29368 14.4944 3.32601 14.594 3.26321 14.6404L2.77796 14.9988L2.95999 15.5739C2.98363 15.6483 2.89876 15.71 2.83519 15.6645L2.34447 15.3138L1.85375 15.6646C1.79017 15.71 1.7055 15.6484 1.72894 15.574L1.91097 14.9989L1.42572 14.6405C1.36293 14.594 1.39525 14.4945 1.47337 14.4938L2.0766 14.4892L2.26742 13.9169C2.29203 13.8428 2.39671 13.8428 2.42152 13.9168Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.42152 16.911L2.61234 17.4832L3.21556 17.4878C3.29368 17.4885 3.32601 17.5881 3.26321 17.6345L2.77796 17.9929L2.95999 18.568C2.98363 18.6424 2.89876 18.7041 2.83519 18.6586L2.34447 18.3079L1.85375 18.6587C1.79017 18.7041 1.7055 18.6425 1.72894 18.5681L1.91097 17.993L1.42572 17.6346C1.36293 17.5881 1.39525 17.4886 1.47337 17.4879L2.0766 17.4833L2.26742 16.9111C2.29203 16.837 2.39671 16.837 2.42152 16.911Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.42152 19.9042L2.61234 20.4765L3.21556 20.4811C3.29368 20.4817 3.32601 20.5814 3.26321 20.6277L2.77796 20.9861L2.95999 21.5612C2.98363 21.6357 2.89876 21.6974 2.83519 21.6519L2.34447 21.3012L1.85375 21.652C1.79017 21.6974 1.7055 21.6358 1.72894 21.5613L1.91097 20.9862L1.42572 20.6278C1.36293 20.5814 1.39525 20.4818 1.47337 20.4812L2.0766 20.4766L2.26742 19.9043C2.29203 19.8301 2.39671 19.8301 2.42152 19.9042Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.42152 22.8984L2.61234 23.4707L3.21556 23.4753C3.29368 23.476 3.32601 23.5756 3.26321 23.622L2.77796 23.9804L2.95999 24.5555C2.98363 24.6299 2.89876 24.6916 2.83519 24.6461L2.34447 24.2954L1.85375 24.6462C1.79017 24.6916 1.7055 24.63 1.72894 24.5555L1.91097 23.9805L1.42572 23.6221C1.36293 23.5756 1.39525 23.4761 1.47337 23.4754L2.0766 23.4708L2.26742 22.8985C2.29203 22.8242 2.39671 22.8242 2.42152 22.8984Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.74476 12.3915L4.93558 12.9637L5.5388 12.9683C5.61693 12.969 5.64925 13.0686 5.58646 13.115L5.1012 13.4734L5.28323 14.0485C5.30687 14.1229 5.222 14.1846 5.15843 14.1391L4.66771 13.7883L4.17699 14.1391C4.11341 14.1845 4.02875 14.1229 4.05218 14.0485L4.23421 13.4734L3.74896 13.115C3.68617 13.0685 3.71849 12.969 3.79662 12.9683L4.39984 12.9637L4.59066 12.3915C4.61517 12.3174 4.72005 12.3174 4.74476 12.3915Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.74476 15.3847L4.93558 15.957L5.5388 15.9615C5.61693 15.9622 5.64925 16.0618 5.58646 16.1082L5.1012 16.4666L5.28323 17.0417C5.30687 17.1161 5.222 17.1778 5.15843 17.1323L4.66771 16.7816L4.17699 17.1323C4.11341 17.1778 4.02875 17.1162 4.05218 17.0417L4.23421 16.4666L3.74896 16.1082C3.68617 16.0617 3.71849 15.9622 3.79662 15.9615L4.39984 15.957L4.59066 15.3847C4.61517 15.3106 4.72005 15.3106 4.74476 15.3847Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.74476 18.3788L4.93558 18.9511L5.5388 18.9557C5.61693 18.9564 5.64925 19.056 5.58646 19.1024L5.1012 19.4608L5.28323 20.0359C5.30687 20.1103 5.222 20.172 5.15843 20.1265L4.66771 19.7757L4.17699 20.1265C4.11341 20.1719 4.02875 20.1103 4.05218 20.0359L4.23421 19.4608L3.74896 19.1024C3.68617 19.0559 3.71849 18.9564 3.79662 18.9557L4.39984 18.9511L4.59066 18.3788C4.61517 18.3047 4.72005 18.3047 4.74476 18.3788Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.74476 21.3719L4.93558 21.9442L5.5388 21.9488C5.61693 21.9495 5.64925 22.0491 5.58646 22.0955L5.1012 22.4539L5.28323 23.029C5.30687 23.1034 5.222 23.1651 5.15843 23.1196L4.66771 22.7688L4.17699 23.1196C4.11341 23.165 4.02875 23.1034 4.05218 23.029L4.23421 22.4539L3.74896 22.0955C3.68617 22.049 3.71849 21.9495 3.79662 21.9488L4.39984 21.9442L4.59066 21.3719C4.61517 21.2979 4.72005 21.2979 4.74476 21.3719Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.06898 10.9238L7.2598 11.496L7.86302 11.5006C7.94114 11.5013 7.97347 11.6009 7.91068 11.6473L7.42542 12.0057L7.60745 12.5808C7.63109 12.6552 7.54622 12.7169 7.48265 12.6714L6.99193 12.3206L6.50121 12.6714C6.43763 12.7168 6.35296 12.6552 6.3764 12.5808L6.55843 12.0057L6.07318 11.6473C6.01039 11.6008 6.04271 11.5013 6.12084 11.5006L6.72406 11.496L6.91488 10.9238C6.93949 10.8496 7.04427 10.8496 7.06898 10.9238Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.06898 13.9168L7.2598 14.4891L7.86302 14.4937C7.94114 14.4944 7.97347 14.594 7.91068 14.6404L7.42542 14.9988L7.60745 15.5739C7.63109 15.6483 7.54622 15.71 7.48265 15.6645L6.99193 15.3137L6.50121 15.6645C6.43763 15.7099 6.35296 15.6483 6.3764 15.5739L6.55843 14.9988L6.07318 14.6404C6.01039 14.5939 6.04271 14.4944 6.12084 14.4937L6.72406 14.4891L6.91488 13.9168C6.93949 13.8428 7.04427 13.8428 7.06898 13.9168Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.06898 16.911L7.2598 17.4833L7.86302 17.4878C7.94114 17.4885 7.97347 17.5881 7.91068 17.6345L7.42542 17.9929L7.60745 18.568C7.63109 18.6424 7.54622 18.7042 7.48265 18.6586L6.99193 18.3079L6.50121 18.6586C6.43763 18.7041 6.35296 18.6424 6.3764 18.568L6.55843 17.9929L6.07318 17.6345C6.01039 17.588 6.04271 17.4885 6.12084 17.4878L6.72406 17.4833L6.91488 16.911C6.93949 16.837 7.04427 16.837 7.06898 16.911Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.06898 19.9042L7.2598 20.4765L7.86302 20.4811C7.94114 20.4818 7.97347 20.5814 7.91068 20.6278L7.42542 20.9862L7.60745 21.5612C7.63109 21.6357 7.54622 21.6974 7.48265 21.6519L6.99193 21.3011L6.50121 21.6519C6.43763 21.6973 6.35296 21.6357 6.3764 21.5612L6.55843 20.9862L6.07318 20.6278C6.01039 20.5813 6.04271 20.4818 6.12084 20.4811L6.72406 20.4765L6.91488 19.9042C6.93949 19.8301 7.04427 19.8301 7.06898 19.9042Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.06898 22.8984L7.2598 23.4707L7.86302 23.4753C7.94114 23.476 7.97347 23.5756 7.91068 23.622L7.42542 23.9804L7.60745 24.5555C7.63109 24.6299 7.54622 24.6916 7.48265 24.6461L6.99193 24.2953L6.50121 24.6461C6.43763 24.6915 6.35296 24.6299 6.3764 24.5555L6.55843 23.9804L6.07318 23.622C6.01039 23.5755 6.04271 23.476 6.12084 23.4753L6.72406 23.4707L6.91488 22.8984C6.93949 22.8242 7.04427 22.8242 7.06898 22.8984Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.39222 12.3915L9.58304 12.9637L10.1863 12.9683C10.2644 12.969 10.2967 13.0686 10.2339 13.115L9.74866 13.4734L9.9307 14.0485C9.95433 14.1229 9.86947 14.1846 9.80589 14.1391L9.31517 13.7883L8.82445 14.1391C8.76087 14.1845 8.67621 14.1229 8.69964 14.0485L8.88167 13.4734L8.39642 13.115C8.33363 13.0685 8.36595 12.969 8.44408 12.9683L9.0473 12.9637L9.23812 12.3915C9.26263 12.3174 9.36751 12.3174 9.39222 12.3915Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.39222 15.3847L9.58304 15.957L10.1863 15.9615C10.2644 15.9622 10.2967 16.0618 10.2339 16.1082L9.74866 16.4666L9.9307 17.0417C9.95433 17.1161 9.86947 17.1778 9.80589 17.1323L9.31517 16.7816L8.82445 17.1323C8.76087 17.1778 8.67621 17.1162 8.69964 17.0417L8.88167 16.4666L8.39642 16.1082C8.33363 16.0617 8.36595 15.9622 8.44408 15.9615L9.0473 15.957L9.23812 15.3847C9.26263 15.3106 9.36751 15.3106 9.39222 15.3847Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.39222 18.3788L9.58304 18.9511L10.1863 18.9557C10.2644 18.9564 10.2967 19.056 10.2339 19.1024L9.74866 19.4608L9.9307 20.0359C9.95433 20.1103 9.86947 20.172 9.80589 20.1265L9.31517 19.7757L8.82445 20.1265C8.76087 20.1719 8.67621 20.1103 8.69964 20.0359L8.88167 19.4608L8.39642 19.1024C8.33363 19.0559 8.36595 18.9564 8.44408 18.9557L9.0473 18.9511L9.23812 18.3788C9.26263 18.3047 9.36751 18.3047 9.39222 18.3788Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.39222 21.3719L9.58304 21.9442L10.1863 21.9488C10.2644 21.9495 10.2967 22.0491 10.2339 22.0955L9.74866 22.4539L9.9307 23.029C9.95433 23.1034 9.86947 23.1651 9.80589 23.1196L9.31517 22.7688L8.82445 23.1196C8.76087 23.165 8.67621 23.1034 8.69964 23.029L8.88167 22.4539L8.39642 22.0955C8.33363 22.049 8.36595 21.9495 8.44408 21.9488L9.0473 21.9442L9.23812 21.3719C9.26263 21.2979 9.36751 21.2979 9.39222 21.3719Z"
        fill="#F5F5F5"
      />
      <path
        d="M11.7156 10.9238L11.9064 11.496L12.5096 11.5006C12.5877 11.5013 12.62 11.6009 12.5573 11.6473L12.072 12.0057L12.254 12.5808C12.2777 12.6552 12.1928 12.7169 12.1292 12.6714L11.6384 12.3206L11.1477 12.6714C11.0841 12.7168 10.9994 12.6552 11.0229 12.5808L11.2049 12.0057L10.7197 11.6473C10.6569 11.6008 10.6892 11.5013 10.7673 11.5006L11.3705 11.496L11.5614 10.9238C11.5861 10.8496 11.6909 10.8496 11.7156 10.9238Z"
        fill="#F5F5F5"
      />
      <path
        d="M11.7156 13.9168L11.9064 14.4891L12.5096 14.4937C12.5877 14.4944 12.62 14.594 12.5573 14.6404L12.072 14.9988L12.254 15.5739C12.2777 15.6483 12.1928 15.71 12.1292 15.6645L11.6384 15.3137L11.1477 15.6645C11.0841 15.7099 10.9994 15.6483 11.0229 15.5739L11.2049 14.9988L10.7197 14.6404C10.6569 14.5939 10.6892 14.4944 10.7673 14.4937L11.3705 14.4891L11.5614 13.9168C11.5861 13.8428 11.6909 13.8428 11.7156 13.9168Z"
        fill="#F5F5F5"
      />
      <path
        d="M11.7156 16.911L11.9064 17.4833L12.5096 17.4878C12.5877 17.4885 12.62 17.5881 12.5573 17.6345L12.072 17.9929L12.254 18.568C12.2777 18.6424 12.1928 18.7042 12.1292 18.6586L11.6384 18.3079L11.1477 18.6586C11.0841 18.7041 10.9994 18.6424 11.0229 18.568L11.2049 17.9929L10.7197 17.6345C10.6569 17.588 10.6892 17.4885 10.7673 17.4878L11.3705 17.4833L11.5614 16.911C11.5861 16.837 11.6909 16.837 11.7156 16.911Z"
        fill="#F5F5F5"
      />
      <path
        d="M11.7156 19.9042L11.9064 20.4765L12.5096 20.4811C12.5877 20.4818 12.62 20.5814 12.5573 20.6278L12.072 20.9862L12.254 21.5612C12.2777 21.6357 12.1928 21.6974 12.1292 21.6519L11.6384 21.3011L11.1477 21.6519C11.0841 21.6973 10.9994 21.6357 11.0229 21.5612L11.2049 20.9862L10.7197 20.6278C10.6569 20.5813 10.6892 20.4818 10.7673 20.4811L11.3705 20.4765L11.5614 19.9042C11.5861 19.8301 11.6909 19.8301 11.7156 19.9042Z"
        fill="#F5F5F5"
      />
      <path
        d="M11.7156 22.8984L11.9064 23.4707L12.5096 23.4753C12.5877 23.476 12.62 23.5756 12.5573 23.622L12.072 23.9804L12.254 24.5555C12.2777 24.6299 12.1928 24.6916 12.1292 24.6461L11.6384 24.2953L11.1477 24.6461C11.0841 24.6915 10.9994 24.6299 11.0229 24.5555L11.2049 23.9804L10.7197 23.622C10.6569 23.5755 10.6892 23.476 10.7673 23.4753L11.3705 23.4707L11.5614 22.8984C11.5861 22.8242 11.6909 22.8242 11.7156 22.8984Z"
        fill="#F5F5F5"
      />
      <path
        d="M14.0397 12.3915L14.2305 12.9637L14.8337 12.9683C14.9118 12.969 14.9442 13.0686 14.8814 13.115L14.3961 13.4734L14.5782 14.0485C14.6018 14.1229 14.5169 14.1846 14.4534 14.1391L13.9626 13.7883L13.4719 14.1391C13.4083 14.1845 13.3237 14.1229 13.3471 14.0485L13.5291 13.4734L13.0439 13.115C12.9811 13.0685 13.0134 12.969 13.0915 12.9683L13.6948 12.9637L13.8856 12.3915C13.9103 12.3174 14.015 12.3174 14.0397 12.3915Z"
        fill="#F5F5F5"
      />
      <path
        d="M14.0397 15.3847L14.2305 15.957L14.8337 15.9615C14.9118 15.9622 14.9442 16.0618 14.8814 16.1082L14.3961 16.4666L14.5782 17.0417C14.6018 17.1161 14.5169 17.1778 14.4534 17.1323L13.9626 16.7816L13.4719 17.1323C13.4083 17.1778 13.3237 17.1162 13.3471 17.0417L13.5291 16.4666L13.0439 16.1082C12.9811 16.0617 13.0134 15.9622 13.0915 15.9615L13.6948 15.957L13.8856 15.3847C13.9103 15.3106 14.015 15.3106 14.0397 15.3847Z"
        fill="#F5F5F5"
      />
      <path
        d="M14.0397 18.3788L14.2305 18.9511L14.8337 18.9557C14.9118 18.9564 14.9442 19.056 14.8814 19.1024L14.3961 19.4608L14.5782 20.0359C14.6018 20.1103 14.5169 20.172 14.4534 20.1265L13.9626 19.7757L13.4719 20.1265C13.4083 20.1719 13.3237 20.1103 13.3471 20.0359L13.5291 19.4608L13.0439 19.1024C12.9811 19.0559 13.0134 18.9564 13.0915 18.9557L13.6948 18.9511L13.8856 18.3788C13.9103 18.3047 14.015 18.3047 14.0397 18.3788Z"
        fill="#F5F5F5"
      />
      <path
        d="M14.0397 21.3719L14.2305 21.9442L14.8337 21.9488C14.9118 21.9495 14.9442 22.0491 14.8814 22.0955L14.3961 22.4539L14.5782 23.029C14.6018 23.1034 14.5169 23.1651 14.4534 23.1196L13.9626 22.7688L13.4719 23.1196C13.4083 23.165 13.3237 23.1034 13.3471 23.029L13.5291 22.4539L13.0439 22.0955C12.9811 22.049 13.0134 21.9495 13.0915 21.9488L13.6948 21.9442L13.8856 21.3719C13.9103 21.2979 14.015 21.2979 14.0397 21.3719Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.3629 10.9238L16.5537 11.496L17.157 11.5006C17.2351 11.5013 17.2674 11.6009 17.2046 11.6473L16.7194 12.0057L16.9014 12.5808C16.925 12.6552 16.8402 12.7169 16.7766 12.6714L16.2859 12.3206L15.7952 12.6714C15.7316 12.7168 15.6469 12.6552 15.6703 12.5808L15.8524 12.0057L15.3671 11.6473C15.3043 11.6008 15.3367 11.5013 15.4148 11.5006L16.018 11.496L16.2088 10.9238C16.2333 10.8496 16.3382 10.8496 16.3629 10.9238Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.3629 13.9168L16.5537 14.4891L17.157 14.4937C17.2351 14.4944 17.2674 14.594 17.2046 14.6404L16.7194 14.9988L16.9014 15.5739C16.925 15.6483 16.8402 15.71 16.7766 15.6645L16.2859 15.3137L15.7952 15.6645C15.7316 15.7099 15.6469 15.6483 15.6703 15.5739L15.8524 14.9988L15.3671 14.6404C15.3043 14.5939 15.3367 14.4944 15.4148 14.4937L16.018 14.4891L16.2088 13.9168C16.2333 13.8428 16.3382 13.8428 16.3629 13.9168Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.3629 16.911L16.5537 17.4833L17.157 17.4878C17.2351 17.4885 17.2674 17.5881 17.2046 17.6345L16.7194 17.9929L16.9014 18.568C16.925 18.6424 16.8402 18.7042 16.7766 18.6586L16.2859 18.3079L15.7952 18.6586C15.7316 18.7041 15.6469 18.6424 15.6703 18.568L15.8524 17.9929L15.3671 17.6345C15.3043 17.588 15.3367 17.4885 15.4148 17.4878L16.018 17.4833L16.2088 16.911C16.2333 16.837 16.3382 16.837 16.3629 16.911Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.3629 19.9042L16.5537 20.4765L17.157 20.4811C17.2351 20.4818 17.2674 20.5814 17.2046 20.6278L16.7194 20.9862L16.9014 21.5612C16.925 21.6357 16.8402 21.6974 16.7766 21.6519L16.2859 21.3011L15.7952 21.6519C15.7316 21.6973 15.6469 21.6357 15.6703 21.5612L15.8524 20.9862L15.3671 20.6278C15.3043 20.5813 15.3367 20.4818 15.4148 20.4811L16.018 20.4765L16.2088 19.9042C16.2333 19.8301 16.3382 19.8301 16.3629 19.9042Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.3629 22.8984L16.5537 23.4707L17.157 23.4753C17.2351 23.476 17.2674 23.5756 17.2046 23.622L16.7194 23.9804L16.9014 24.5555C16.925 24.6299 16.8402 24.6916 16.7766 24.6461L16.2859 24.2953L15.7952 24.6461C15.7316 24.6915 15.6469 24.6299 15.6703 24.5555L15.8524 23.9804L15.3671 23.622C15.3043 23.5755 15.3367 23.476 15.4148 23.4753L16.018 23.4707L16.2088 22.8984C16.2333 22.8242 16.3382 22.8242 16.3629 22.8984Z"
        fill="#F5F5F5"
      />
      <path
        d="M18.6862 12.3915L18.877 12.9637L19.4802 12.9683C19.5583 12.969 19.5907 13.0686 19.5279 13.115L19.0426 13.4734L19.2246 14.0485C19.2483 14.1229 19.1634 14.1846 19.0998 14.1391L18.6091 13.7883L18.1184 14.1391C18.0548 14.1845 17.9702 14.1229 17.9936 14.0485L18.1756 13.4734L17.6904 13.115C17.6276 13.0685 17.6599 12.969 17.738 12.9683L18.3412 12.9637L18.5321 12.3915C18.5567 12.3174 18.6616 12.3174 18.6862 12.3915Z"
        fill="#F5F5F5"
      />
      <path
        d="M18.6862 15.3847L18.877 15.957L19.4802 15.9615C19.5583 15.9622 19.5907 16.0618 19.5279 16.1082L19.0426 16.4666L19.2246 17.0417C19.2483 17.1161 19.1634 17.1778 19.0998 17.1323L18.6091 16.7816L18.1184 17.1323C18.0548 17.1778 17.9702 17.1162 17.9936 17.0417L18.1756 16.4666L17.6904 16.1082C17.6276 16.0617 17.6599 15.9622 17.738 15.9615L18.3412 15.957L18.5321 15.3847C18.5567 15.3106 18.6616 15.3106 18.6862 15.3847Z"
        fill="#F5F5F5"
      />
      <path
        d="M18.6862 18.3788L18.877 18.9511L19.4802 18.9557C19.5583 18.9564 19.5907 19.056 19.5279 19.1024L19.0426 19.4608L19.2246 20.0359C19.2483 20.1103 19.1634 20.172 19.0998 20.1265L18.6091 19.7757L18.1184 20.1265C18.0548 20.1719 17.9702 20.1103 17.9936 20.0359L18.1756 19.4608L17.6904 19.1024C17.6276 19.0559 17.6599 18.9564 17.738 18.9557L18.3412 18.9511L18.5321 18.3788C18.5567 18.3047 18.6616 18.3047 18.6862 18.3788Z"
        fill="#F5F5F5"
      />
      <path
        d="M18.6862 21.3719L18.877 21.9442L19.4802 21.9488C19.5583 21.9495 19.5907 22.0491 19.5279 22.0955L19.0426 22.4539L19.2246 23.029C19.2483 23.1034 19.1634 23.1651 19.0998 23.1196L18.6091 22.7688L18.1184 23.1196C18.0548 23.165 17.9702 23.1034 17.9936 23.029L18.1756 22.4539L17.6904 22.0955C17.6276 22.049 17.6599 21.9495 17.738 21.9488L18.3412 21.9442L18.5321 21.3719C18.5567 21.2979 18.6616 21.2979 18.6862 21.3719Z"
        fill="#F5F5F5"
      />
      <path
        d="M21.0104 10.9238L21.2012 11.496L21.8044 11.5006C21.8826 11.5013 21.9149 11.6009 21.8521 11.6473L21.3668 12.0057L21.5489 12.5808C21.5725 12.6552 21.4876 12.7169 21.4241 12.6714L20.9333 12.3206L20.4426 12.6714C20.379 12.7168 20.2944 12.6552 20.3178 12.5808L20.4998 12.0057L20.0146 11.6473C19.9518 11.6008 19.9841 11.5013 20.0622 11.5006L20.6655 11.496L20.8563 10.9238C20.8809 10.8496 20.9856 10.8496 21.0104 10.9238Z"
        fill="#F5F5F5"
      />
      <path
        d="M21.0104 13.9168L21.2012 14.4891L21.8044 14.4937C21.8826 14.4944 21.9149 14.594 21.8521 14.6404L21.3668 14.9988L21.5489 15.5739C21.5725 15.6483 21.4876 15.71 21.4241 15.6645L20.9333 15.3137L20.4426 15.6645C20.379 15.7099 20.2944 15.6483 20.3178 15.5739L20.4998 14.9988L20.0146 14.6404C19.9518 14.5939 19.9841 14.4944 20.0622 14.4937L20.6655 14.4891L20.8563 13.9168C20.8809 13.8428 20.9856 13.8428 21.0104 13.9168Z"
        fill="#F5F5F5"
      />
      <path
        d="M21.0104 16.911L21.2012 17.4833L21.8044 17.4878C21.8826 17.4885 21.9149 17.5881 21.8521 17.6345L21.3668 17.9929L21.5489 18.568C21.5725 18.6424 21.4876 18.7042 21.4241 18.6586L20.9333 18.3079L20.4426 18.6586C20.379 18.7041 20.2944 18.6424 20.3178 18.568L20.4998 17.9929L20.0146 17.6345C19.9518 17.588 19.9841 17.4885 20.0622 17.4878L20.6655 17.4833L20.8563 16.911C20.8809 16.837 20.9856 16.837 21.0104 16.911Z"
        fill="#F5F5F5"
      />
      <path
        d="M21.0104 19.9042L21.2012 20.4765L21.8044 20.4811C21.8826 20.4818 21.9149 20.5814 21.8521 20.6278L21.3668 20.9862L21.5489 21.5612C21.5725 21.6357 21.4876 21.6974 21.4241 21.6519L20.9333 21.3011L20.4426 21.6519C20.379 21.6973 20.2944 21.6357 20.3178 21.5612L20.4998 20.9862L20.0146 20.6278C19.9518 20.5813 19.9841 20.4818 20.0622 20.4811L20.6655 20.4765L20.8563 19.9042C20.8809 19.8301 20.9856 19.8301 21.0104 19.9042Z"
        fill="#F5F5F5"
      />
      <path
        d="M21.0104 22.8984L21.2012 23.4707L21.8044 23.4753C21.8826 23.476 21.9149 23.5756 21.8521 23.622L21.3668 23.9804L21.5489 24.5555C21.5725 24.6299 21.4876 24.6916 21.4241 24.6461L20.9333 24.2953L20.4426 24.6461C20.379 24.6915 20.2944 24.6299 20.3178 24.5555L20.4998 23.9804L20.0146 23.622C19.9518 23.5755 19.9841 23.476 20.0622 23.4753L20.6655 23.4707L20.8563 22.8984C20.8809 22.8242 20.9856 22.8242 21.0104 22.8984Z"
        fill="#F5F5F5"
      />
    </SvgIcon>
  )
}

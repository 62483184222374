import { Stack } from '@mui/system'
import { Form, CheckInput, NumberInput, TextInput, DateInput, SubmitButton } from '@productwindtom/ui-base'
import { useController, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import React, { useState, createRef, useEffect } from 'react'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableBody,
  OutlinedInput,
  Box,
  Chip,
  Select,
  MenuItem,
  Button,
  TextField
} from '@mui/material'
import { Add, Close, Remove } from '@mui/icons-material'
import { ListingBadge, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { difference, uniq } from 'lodash'
import { parseCsv } from '@momentum/routes/campaign/e-commerce/seo/editor/csv-parser'
import { DateTime } from 'luxon'

type FormType = {
  campaignId: string
  createdAt?: string | undefined | null
  keywords?:
    | Array<{
        badges?: Array<ValueTypes['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']> | undefined | null
        highestRank?: number | undefined | null
        impressions: number
        keyword: string
        pageOneWin?: boolean | undefined | null
        pageOneWinDate?: DateTime | undefined | null
        sponsoredHighestRank?: number | undefined | null
        sponsoredStartRank?: number | undefined | null
        startRank?: number | undefined | null
      }>
    | undefined
    | null
  productSEOSummaryRecords?:
    | Array<ValueTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']>
    | undefined
    | null
  updatedAt?: string | undefined | null
}

export const SeoEditor = ({
  input,
  onSubmit,
  onClose
}: {
  input: ValueTypes['ModelInputCreatePWProductSeoSummary']
  onSubmit: (input: ValueTypes['ModelInputCreatePWProductSeoSummary']) => Promise<void>
  onClose: () => void
}) => {
  const handleSubmit = async (values: FormType) => {
    const formattedProductSEOSummaryRecords = (values.productSEOSummaryRecords || []).map((r, index) => ({
      ...r,
      weekIndex: index,
      categoryRanks: (r.categoryRanks || []).filter(c => c.rank != null)
    }))

    const formattedKeywords = (values.keywords || [])
      .filter(k => k.keyword && k.keyword.trim())
      .map(k => ({
        ...k,
        keyword: k.keyword.trim(),
        pageOneWinDate: k.pageOneWin && k.pageOneWinDate ? (k.pageOneWinDate as unknown as DateTime)?.toISODate() : null
      }))

    await onSubmit({
      campaignId: input.campaignId,
      productSEOSummaryRecords: formattedProductSEOSummaryRecords,
      keywords: formattedKeywords
    })
  }

  const formattedInput = {
    ...input,
    keywords: (input.keywords || []).map(k => ({
      ...k,
      pageOneWinDate: k.pageOneWinDate ? DateTime.fromISO(k.pageOneWinDate) : undefined
    }))
  }
  return (
    <Form defaultValues={formattedInput} onSubmit={handleSubmit}>
      <SeoEditorBody input={formattedInput} onClose={onClose} />
    </Form>
  )
}

const SeoEditorBody = ({ input, onClose }: { input: FormType; onClose: () => void }) => {
  const methods = useFormContext<ValueTypes['ModelInputCreatePWProductSeoSummary']>()

  const [parsingCsv, setParsingCsv] = useState(false)
  const attachedFile = createRef<HTMLInputElement>()

  const [addCategoryInput, setAddCategoryInput] = useState('')
  const [categories, setCategories] = useState<string[]>(
    input
      ? uniq((input.productSEOSummaryRecords || []).map(s => (s.categoryRanks || []).map(c => c.category)).flat())
      : []
  )

  const selectCsv = async (input: HTMLInputElement) => {
    if (input.files && input.files.length > 0) {
      setParsingCsv(true)
      const data = await parseCsv(input.files[0])
      if (data.keywords) {
        methods.setValue('keywords', data.keywords)
      }
      if (data.impressionRecords) {
        methods.setValue('productSEOSummaryRecords', data.impressionRecords)
      }
      setParsingCsv(false)
    }
  }

  const addKeyword = () => {
    setCategories([...categories, addCategoryInput.trim()])
    setAddCategoryInput('')
  }

  const onRemoveKeyword = (keyword: string) => {
    setCategories(categories.filter(k => k !== keyword))
  }

  return (
    <Stack spacing={2} p={4}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={3}>
          <Box>
            <label htmlFor="upload-csv">
              <input
                style={{ display: 'none' }}
                id="upload-csv"
                name="upload-csv"
                accept="csv"
                //@ts-ignore
                ref={attachedFile}
                value={''}
                onChange={({ target }) => selectCsv(target)}
                type="file"
              />

              <Button color="secondary" variant="contained" component="span" disabled={parsingCsv}>
                Select CSV
              </Button>
            </label>
          </Box>

          <Stack direction={'row'} spacing={2}>
            <TextField value={addCategoryInput} onChange={e => setAddCategoryInput(e.target.value)} />
            <Button onClick={() => addKeyword()} disabled={!addCategoryInput.trim()}>
              Add Category
            </Button>
          </Stack>
        </Stack>
        <Box>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </Stack>
      <SummaryRecordInput
        name={'productSEOSummaryRecords'}
        categories={categories}
        onRemoveCategory={onRemoveKeyword}
      />
      <SearchTermInput name={'keywords'} />
      <Stack direction={'row'} alignItems={'flex-end'}>
        <SubmitButton variant={'outlined'}>Save</SubmitButton>
      </Stack>
    </Stack>
  )
}

const SummaryRecordInput = ({
  name,
  categories,
  onRemoveCategory
}: {
  name: string
  categories: string[]
  onRemoveCategory: (keyword: string) => void
}) => {
  const { fields, append, remove } = useFieldArray({ name })

  return (
    <Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {fields.map((field, index) => (
                <TableCell>{index === 0 ? 'Baseline' : `Week ${index}`}</TableCell>
              ))}
              <TableCell>
                <Stack>
                  <Box>
                    <IconButton onClick={() => append({ weekIndex: fields.length })} size={'small'}>
                      <Add fontSize={'small'} />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton onClick={() => remove(fields.length - 1)} size={'small'}>
                      <Remove fontSize={'small'} />
                    </IconButton>
                  </Box>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Out of Stock</TableCell>
              {fields.map((field, index) => (
                <TableCell key={index}>
                  <CheckInput name={`${name}[${index}].isOutOfStock`} />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Page 1 Wins</TableCell>
              {fields.map((field, index) => (
                <TableCell key={index}>
                  <NumberInput name={`${name}[${index}].topPageWins`} returnAsNumber decimalScale={0} />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Weekly SERP Impressions</TableCell>
              {fields.map((field, index) => (
                <TableCell key={index}>
                  <NumberInput name={`${name}[${index}].impressions`} returnAsNumber decimalScale={0} />
                </TableCell>
              ))}
            </TableRow>
            {categories.map(category => (
              <CategoryRow category={category} onRemove={onRemoveCategory} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

const CategoryRow = ({ category: keyword, onRemove }: { category: string; onRemove: (keyword: string) => void }) => {
  const { watch } = useFormContext<ValueTypes['ModelInputCreatePWProductSeoSummary']>()
  const records = watch('productSEOSummaryRecords')
  return (
    <TableRow>
      <TableCell>
        {keyword}{' '}
        <IconButton size="small" onClick={() => onRemove(keyword)}>
          <Remove fontSize="small" />
        </IconButton>
      </TableCell>
      {(records || []).map((record, index) => (
        <KeywordRankCell key={index} keyword={keyword} index={index} weekRecord={record} />
      ))}
    </TableRow>
  )
}

const KeywordRankCell = ({ keyword, index, weekRecord }: { keyword: string; index: number; weekRecord: any }) => {
  const keywordIndex = (weekRecord.categoryRanks || []).findIndex((r: any) => r.category === keyword)
  const { control } = useFormContext()
  const { append } = useFieldArray({ name: `productSEOSummaryRecords.${index}.categoryRanks`, control })

  useEffect(() => {
    if (keywordIndex < 0) {
      append({ category: keyword })
    }
  }, [keyword])

  return (
    <TableCell>
      <NumberInput
        name={`productSEOSummaryRecords.${index}.categoryRanks.${keywordIndex}..rank`}
        returnAsNumber
        decimalScale={0}
      />
    </TableCell>
  )
}

const SearchTermInput = ({ name }: { name: string }) => {
  const { control } = useFormContext()
  const { append, remove, fields } = useFieldArray({ name, control })

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell>Search Term</TableCell>
          <TableCell>Weekly Impressions</TableCell>
          <TableCell>Page 1 Win</TableCell>
          <TableCell>Starting Rank</TableCell>
          <TableCell>Highest Rank</TableCell>
          <TableCell>Listing Badges</TableCell>
          <TableCell />
        </TableHead>
        <TableBody>
          {fields.map((field, index) => (
            <KeywordRowInput name={name} key={field.id} index={index} onRemove={() => remove(index)} />
          ))}
          <TableRow>
            <TableCell colSpan={5}>
              <IconButton onClick={() => append({ badges: [] })}>
                <Add />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const KeywordRowInput = ({ name, index, onRemove }: { name: string; index: number; onRemove: () => void }) => {
  const { control } = useFormContext()
  const pageOneWin = useWatch({ name: `${name}.${index}.pageOneWin`, control })
  return (
    <TableRow>
      <TableCell>
        <TextInput name={`${name}.${index}.keyword`} />
      </TableCell>
      <TableCell>
        <NumberInput name={`${name}.${index}.impressions`} returnAsNumber decimalScale={0} />
      </TableCell>
      <TableCell>
        <CheckInput name={`${name}.${index}.pageOneWin`} />
        {!!pageOneWin && <DateInput name={`${name}.${index}.pageOneWinDate`} />}
      </TableCell>
      <TableCell>
        <NumberInput name={`${name}.${index}.startRank`} returnAsNumber decimalScale={0} />
      </TableCell>
      <TableCell>
        <NumberInput name={`${name}.${index}.highestRank`} returnAsNumber decimalScale={0} />
      </TableCell>
      <TableCell>
        <KeywordBadgesInput name={`${name}.${index}.badges`} />
      </TableCell>
      <TableCell>
        <IconButton size="small" onClick={onRemove}>
          <Remove fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const BADGE_NAME: { [key: string]: string } = {
  [ListingBadge.AMAZON_CHOICE]: 'Amazon Choice'
}

const KeywordBadgesInput = ({ name }: { name: string }) => {
  const { field } = useController({ name })
  const { value, onChange } = field

  return (
    <Select
      multiple
      value={value.map((b: ValueTypes['ListingBadgeRecord']) => b.badge)}
      onChange={e => {
        const toAdd = difference(
          e.target.value,
          value.map((b: ValueTypes['ListingBadgeRecord']) => b.badge)
        )
        const toRemove = difference(
          value.map((b: ValueTypes['ListingBadgeRecord']) => b.badge),
          e.target.value
        )
        onChange([
          ...value.filter((b: ValueTypes['ListingBadgeRecord']) => !toRemove.includes(b.badge)),
          ...toAdd.map(b => ({ badge: b as ListingBadge }))
        ])
      }}
      input={<OutlinedInput fullWidth />}
      renderValue={selected => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((v: string) => (
            <Chip key={v} label={BADGE_NAME[v]} size="small" />
          ))}
        </Box>
      )}
    >
      {Object.keys(ListingBadge).map(name => (
        <MenuItem key={name} value={name}>
          {BADGE_NAME[name]}
        </MenuItem>
      ))}
    </Select>
  )
}

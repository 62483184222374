import { DialogTitle, Stack, Typography, IconButton, DialogContent, Dialog, Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import React from 'react'

export const ConfirmDeleteDialog = ({
  open,
  onConfirm,
  onClose
}: {
  open: boolean
  onConfirm: () => void
  onClose: () => void
}) => {
  return (
    <Dialog open={open} maxWidth={'sm'} fullWidth onClick={event => event.stopPropagation()}>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'h4'}>Are you sure you want to remove this product?</Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Typography variant={'label3'}></Typography>

          <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
            <Button variant={'text'} onClick={onClose}>
              Cancel
            </Button>
            <Button variant={'contained'} onClick={onConfirm}>
              Remove product
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

import {
  CampaignCreatorStatus,
  Channel,
  ContentType,
  CreatorType,
  ModelTypes,
  Store
} from '@productwindtom/shared-momentum-zeus-types'
import { BRANDS } from '../../types'

export const SAMPLE_CAMPAIGN_MARKER: ModelTypes['CampaignDetails'] = {
  id: 'sample-campaign-marker',
  brandId: BRANDS.CE,
  skuId: 'sample-sku-marker',
  title: 'Writing Marker',
  numCreators: 150,
  numCreatorsJoined: 150,
  numAdditionalBrandAdvocateCreatorsJoined: 0,
  numAdditionalBrandAdvocateCreators: 0,
  numBrandAdvocateCreators: 150,
  numSocialCreators: 0,
  numPremiumUgcCreators: 0,
  numUgcCreators: 0,
  releaseSchedule: {
    socialWeeklySchedule: [],
    premiumUgcWeeklySchedule: [],
    ugcWeeklySchedule: [],
    brandAdvocatesWeeklySchedule: [101, 122]
  },
  createdAt: '2024-02-20T17:36:22.000Z',
  anticipatedStartDate: '2024-02-27T17:36:22.000Z',
  startDate: '2024-02-27T17:36:22.000Z',
  endDate: '2024-03-18T21:52:35.607Z',
  cost: 643200,
  product: {
    id: 'sample-sku-marker',
    name: 'Writing Marker',
    skuId: 'B0CPMFV2ZF',
    price: '$33.48',
    priceCents: 3348,
    image: 'https://media.productwind.com/sample/images/marker.png',
    listingLink: 'https://www.amazon.com/SHARPIE-Creative-Markers-Water-Based-Assorted/dp/B0CPMFV2ZF',
    store: Store.amazon,
    ratingSnapshots: {
      items: []
    },
    brandId: BRANDS.CE,
    recommendations: [],
    createdAt: '2024-01-13T16:22:12.000Z',
    updatedAt: '2024-01-13T16:22:12.000Z',
    availabilityDate: '2023-12-06T00:00:00.000Z',
    childSkus: { items: [] }
  },
  creators: [
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T16:15:42.000Z',
        amountCents: 2184
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5335d0f4-8a12-4de1-88be-fc1bf35a4b06',
        firstName: 'Amirah',
        lastName: 'Shakur',
        email: 'amirahshakur2018@gmail.com',
        tiktokProfile: {
          handle: 'assata_15',
          followers: 272,
          isPrimary: false
        }
      },
      userCampaignId: '5335d0f4-8a12-4de1-88be-fc1bf35a4b06'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T09:16:31.000Z',
        amountCents: 3463
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '21226153-d69e-4379-8b57-0c43165cadb7',
        firstName: 'Destinie',
        lastName: 'Forest',
        email: 'destinieforest18@gmail.com',
        instagramProfile: {
          handle: 'destiniebrianna_',
          followers: 2818,
          engagementRate: 0.8587650816181689,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'destiniebrianna_',
          followers: 9370,
          engagementRate: 14.604024044391938,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'mommyndrose'
        }
      },
      userCampaignId: '21226153-d69e-4379-8b57-0c43165cadb7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T16:34:37.000Z',
        amountCents: 2177
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '9c21288b-04c6-48c0-a9e9-5a7590954ab7',
        firstName: 'Amanda',
        lastName: 'Dantice',
        email: 'amanda.dantice@gmail.com',
        instagramProfile: {
          handle: 'dhampandas_reviews',
          followers: 3321,
          engagementRate: 2.619692863595303,
          isPrimary: true
        }
      },
      userCampaignId: '9c21288b-04c6-48c0-a9e9-5a7590954ab7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T06:14:05.000Z',
        amountCents: 2139
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'b051ee9a-30b4-4743-a6cd-5fb800081bd9',
        firstName: 'Kelly',
        lastName: 'Carter',
        email: 'kelccarter18@gmail.com',
        tiktokProfile: {
          handle: 'being.a.mom.and.beyond',
          followers: 4790,
          isPrimary: false
        }
      },
      userCampaignId: 'b051ee9a-30b4-4743-a6cd-5fb800081bd9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T16:36:00.000Z',
        amountCents: 2202
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'efe2db45-aaca-464d-a12f-2807d8118199',
        firstName: 'Camille',
        lastName: 'Moore',
        email: 'camilleh19@gmail.com',
        instagramProfile: {
          handle: 'withlove.themoores',
          followers: 5800,
          engagementRate: 0.8293103448275863,
          isPrimary: true
        }
      },
      userCampaignId: 'efe2db45-aaca-464d-a12f-2807d8118199'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T06:24:03.000Z',
        amountCents: 2885
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '99be77fa-8039-420a-b1da-f9026e3c01f5',
        firstName: 'Karla',
        lastName: 'Meza',
        email: 'karkari2011@gmail.com',
        instagramProfile: {
          handle: 'karkari2011',
          followers: 4852,
          engagementRate: 9.72588623248145,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'karkari2011',
          followers: 684,
          isPrimary: false
        }
      },
      userCampaignId: '99be77fa-8039-420a-b1da-f9026e3c01f5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T00:02:25.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '94b8a898-aaa4-4479-a30e-3f6371db37fd',
        firstName: 'Katherine',
        lastName: 'Nunez',
        email: 'misspetite@misspetitecosplay.com',
        instagramProfile: {
          handle: 'misspetitecosplay',
          followers: 6357,
          engagementRate: 6.254522573540979,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'misspetitecosplay',
          followers: 857,
          engagementRate: 10.850439882697946,
          isPrimary: false
        }
      },
      userCampaignId: '94b8a898-aaa4-4479-a30e-3f6371db37fd'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T02:45:56.000Z',
        amountCents: 3505
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '79313e23-c141-4d0d-88bc-042639325f80',
        firstName: 'Skyla',
        lastName: 'Lawless',
        email: 'lawlessdairy@gmail.com',
        instagramProfile: {
          handle: 'skyla.lynae_lawless',
          followers: 946,
          engagementRate: 0.10570824524312897,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'savethefamilyfarm',
          followers: 18696,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'skylalawless2368',
          followers: 0
        }
      },
      userCampaignId: '79313e23-c141-4d0d-88bc-042639325f80'
    },
    {
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'cbef7b24-af51-40b9-b4b1-a48ef2f23c3d',
        firstName: 'Nichole',
        lastName: 'Jiang',
        email: 'nichole@nikprboutique.com',
        instagramProfile: {
          handle: 'iam_nicholejiang',
          followers: 3379,
          engagementRate: 2.0449837229949686,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'nicholejiang',
          followers: 72,
          engagementRate: 1.0380622837370241,
          isPrimary: false
        }
      },
      userCampaignId: 'cbef7b24-af51-40b9-b4b1-a48ef2f23c3d'
    },
    {
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5478d9a0-e344-40c6-a5bb-1a6276076508',
        firstName: 'Laura',
        lastName: 'Nettesheim',
        email: 'laurabergen2@gmail.com',
        tiktokProfile: {
          handle: 'threadedphoenix',
          followers: 1515,
          engagementRate: 0.6495464862221695,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'threadedphoenix8023',
          followers: 448
        }
      },
      userCampaignId: '5478d9a0-e344-40c6-a5bb-1a6276076508'
    },
    {
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f0759024-df93-4bb1-a8ce-13bda1c9aa9d',
        firstName: 'Denagail',
        lastName: 'Risden',
        email: 'dena.ugccreator@gmail.com',
        instagramProfile: {
          handle: 'glamnglowbyd',
          followers: 1373,
          engagementRate: 0.6846321922796795,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'ugcwithdena',
          followers: 149,
          engagementRate: 8.095194936341086,
          isPrimary: false
        }
      },
      userCampaignId: 'f0759024-df93-4bb1-a8ce-13bda1c9aa9d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-06T07:42:15.000Z',
        amountCents: 2187
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a6049a18-0e0b-452c-820c-8185b56739cb',
        firstName: 'Heather',
        lastName: 'Rozier',
        email: 'doodler85@myyahoo.com',
        instagramProfile: {
          handle: 'doodler85',
          followers: 316,
          engagementRate: 3.449367088607595,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'doodler85',
          followers: 108833,
          engagementRate: 7.854531923741984,
          isPrimary: true
        }
      },
      userCampaignId: 'a6049a18-0e0b-452c-820c-8185b56739cb'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T19:04:24.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '830584fb-8f12-46ad-b879-a2256dc3839f',
        firstName: 'Ronald',
        lastName: 'Byrd',
        email: 'rjbyrd39@gmail.com',
        tiktokProfile: {
          handle: 'byrdmanjr39',
          followers: 77,
          engagementRate: 4.1655941655941655,
          isPrimary: false
        }
      },
      userCampaignId: '830584fb-8f12-46ad-b879-a2256dc3839f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T19:23:48.000Z',
        amountCents: 2858
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '326e7297-6da9-4776-9821-5547fbdd82f6',
        firstName: 'Angela',
        lastName: 'Walker',
        email: 'angmwalker@gmail.com',
        instagramProfile: {
          handle: 'angmwalker',
          followers: 429,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'anginatown',
          followers: 5,
          isPrimary: false
        }
      },
      userCampaignId: '326e7297-6da9-4776-9821-5547fbdd82f6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T15:05:59.000Z',
        amountCents: 2149
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e8126d32-773d-4c65-823f-ccd844653c71',
        firstName: 'Carolyn',
        lastName: 'Chimon',
        email: 'callyc86@gmail.com',
        instagramProfile: {
          handle: 'lemmeflipthat',
          followers: 2311,
          engagementRate: 0.0410609857978279,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'carolyn_ch86',
          followers: 294,
          isPrimary: true
        }
      },
      userCampaignId: 'e8126d32-773d-4c65-823f-ccd844653c71'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T20:46:54.000Z',
        amountCents: 2176
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '88c1f95e-86cf-4ae8-b65c-b6c459df6000',
        firstName: 'Casey',
        lastName: 'Cohen',
        email: 'caseylauren.cohen@gmail.com',
        instagramProfile: {
          handle: 'caseylcohen',
          followers: 47709,
          engagementRate: 0.35297323356180177,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'caseylcohen',
          followers: 262,
          isPrimary: false
        }
      },
      userCampaignId: '88c1f95e-86cf-4ae8-b65c-b6c459df6000'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3748e4ad-8a8a-46be-aee0-4d2c7ec52366',
        firstName: 'Tiffany',
        lastName: 'Gonzalez',
        email: 'tiffanygonzalez1992@gmail.com',
        instagramProfile: {
          handle: 'tiffanygonzalez155',
          followers: 105,
          engagementRate: 7.6190476190476195,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'tiffanygonzalez2105',
          followers: 7089,
          engagementRate: 7.601613852880708,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'tiffanygonzalez5768'
        }
      },
      userCampaignId: '3748e4ad-8a8a-46be-aee0-4d2c7ec52366'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T22:21:20.000Z',
        amountCents: 2209
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3e947896-4c97-4658-a56f-4c7033b6c881',
        firstName: 'Zamoy',
        lastName: 'Haynes',
        email: 'zamoyh@icloud.com',
        instagramProfile: {
          handle: 'true_z__',
          followers: 2630,
          engagementRate: 4.239543726235741,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'zamoya8',
          followers: 285,
          engagementRate: 4.3876567020250725,
          isPrimary: false
        }
      },
      userCampaignId: '3e947896-4c97-4658-a56f-4c7033b6c881'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T22:44:55.000Z',
        amountCents: 2184
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '2eba337a-03a8-4db8-956f-49d00a791ee8',
        firstName: 'Madison',
        lastName: 'Mort',
        email: 'madisonmort244@gmail.com',
        instagramProfile: {
          handle: 'maddiiii2.0',
          followers: 669,
          engagementRate: 0.8968609865470852,
          isPrimary: false
        },
        tiktokProfile: {
          handle: '_maddi_issues',
          followers: 443,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'maddiissues',
          followers: 2
        }
      },
      userCampaignId: '2eba337a-03a8-4db8-956f-49d00a791ee8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T16:35:33.000Z',
        amountCents: 2937
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '651ee618-4541-482f-85c6-9075b06ba1e1',
        firstName: 'Yane',
        lastName: 'Gordon',
        email: 'itssnae0924@gmail.com',
        tiktokProfile: {
          handle: 'naevibess',
          followers: 247,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'lifeeasnaeeee',
          followers: 1
        }
      },
      userCampaignId: '651ee618-4541-482f-85c6-9075b06ba1e1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-09T03:05:43.000Z',
        amountCents: 2921
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0908874c-4144-4a7b-b5e6-a09c59514992',
        firstName: 'Adrielle',
        lastName: 'Gainer',
        email: 'poohpee1818@gmail.com',
        instagramProfile: {
          handle: 'adriellemonea',
          followers: 8127,
          engagementRate: 0.33468684631475326,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'adriellemonea',
          followers: 30781,
          engagementRate: 10.5873237724842,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'adrielle'
        }
      },
      userCampaignId: '0908874c-4144-4a7b-b5e6-a09c59514992'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-03T07:43:14.000Z',
        amountCents: 2174
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'eb95e513-96a7-447f-84e0-94f161119894',
        firstName: 'Bassam',
        lastName: 'Elghaziri',
        email: 'samghz@aol.com',
        tiktokProfile: {
          handle: 'belghaziri',
          followers: 102,
          isPrimary: true
        }
      },
      userCampaignId: 'eb95e513-96a7-447f-84e0-94f161119894'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T22:03:03.000Z',
        amountCents: 2117
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a064573c-4c59-4bb5-918e-912784af1a93',
        firstName: 'Christine',
        lastName: 'Valderrama',
        email: 'tinvalderrama@yahoo.com',
        tiktokProfile: {
          handle: 'tinvald',
          followers: 7,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'tintintinin',
          followers: 3
        }
      },
      userCampaignId: 'a064573c-4c59-4bb5-918e-912784af1a93'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T18:35:55.000Z',
        amountCents: 2174
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c22349d2-795a-443f-9d50-4986ab82baac',
        firstName: 'Alisha',
        lastName: 'Brown',
        email: 'alibrown72789@gmail.com',
        instagramProfile: {
          handle: 'xiblackwidowtv',
          followers: 1530,
          engagementRate: 3.2679738562091507,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'xiblackwidowtv',
          followers: 1715,
          isPrimary: false
        }
      },
      userCampaignId: 'c22349d2-795a-443f-9d50-4986ab82baac'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T23:44:49.000Z',
        amountCents: 2149
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'dafeb00a-8481-4f16-9225-bb873e10488c',
        firstName: 'Daisy',
        lastName: 'Luong',
        email: 'daisyluong20@gmail.com',
        instagramProfile: {
          handle: 'ugc.daisyylu',
          followers: 988,
          engagementRate: 1.791497975708502,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'ugc.daisyylu',
          followers: 1469,
          engagementRate: 2.380952380952381,
          isPrimary: true
        }
      },
      userCampaignId: 'dafeb00a-8481-4f16-9225-bb873e10488c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T16:26:18.000Z',
        amountCents: 2147
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fef06e35-635c-4988-8e99-3decaaf5c66e',
        firstName: 'Briana',
        lastName: 'Beaty',
        email: 'thepbmoms@gmail.com',
        instagramProfile: {
          handle: 'palmbeachmoms',
          followers: 24867,
          engagementRate: 0.20871033900349859,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thepalmbeachmoms',
          followers: 721,
          engagementRate: 1.9060225016545336,
          isPrimary: true
        }
      },
      userCampaignId: 'fef06e35-635c-4988-8e99-3decaaf5c66e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T16:54:24.000Z',
        amountCents: 3136
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '1f87884e-f596-4f0e-b3b7-0b03ec184126',
        firstName: 'Daphney',
        lastName: 'Smith',
        email: 'daphpromos@gmail.com',
        instagramProfile: {
          handle: 'daphneysmith_',
          followers: 8955,
          engagementRate: 0.24567280848687884,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'daphneysmith_',
          followers: 231,
          engagementRate: 6.979851392488684,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'daphneysmith'
        }
      },
      userCampaignId: '1f87884e-f596-4f0e-b3b7-0b03ec184126'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T21:42:59.000Z',
        amountCents: 2159
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f0e4d6c4-8eea-4594-ba5c-dea2b0349e38',
        firstName: 'Cassie',
        lastName: 'Feldmann',
        email: 'cbfeldmann21@gmail.com',
        instagramProfile: {
          handle: 'cozywithcassie',
          followers: 8393,
          engagementRate: 0.7017752889312523,
          isPrimary: false
        }
      },
      userCampaignId: 'f0e4d6c4-8eea-4594-ba5c-dea2b0349e38'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T09:31:59.000Z',
        amountCents: 2828
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '596648f0-c9e5-4c38-9422-21c25bb109e3',
        firstName: 'Chelsea',
        lastName: 'McQueen',
        email: 'chelsea.mcqueen98@gmail.com',
        instagramProfile: {
          handle: 'miss_chelsealynne',
          followers: 637,
          engagementRate: 0.23547880690737832,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'chelslynne2018',
          followers: 67,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'chelseamcqueen8183'
        }
      },
      userCampaignId: '596648f0-c9e5-4c38-9422-21c25bb109e3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T17:53:48.000Z',
        amountCents: 2858
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '80e17e1f-7940-4ab0-b4c1-a2f697a86a06',
        firstName: 'Dylan',
        lastName: 'Carter',
        email: 'dylancartervt@gmail.com',
        tiktokProfile: {
          handle: 'notyouraverageworker',
          followers: 3009,
          engagementRate: 11.80781403470479,
          isPrimary: false
        }
      },
      userCampaignId: '80e17e1f-7940-4ab0-b4c1-a2f697a86a06'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '01e55b6b-f004-4dbc-982a-e1060883a9d5',
        firstName: 'Britney ',
        lastName: 'Kennedy ',
        email: 'britneykennedy420@yahoo.com',
        instagramProfile: {
          handle: 'britneysplace',
          followers: 0,
          engagementRate: 0.09104477611940298,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'britneykennedy01',
          followers: 2,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'britneykennedy7448'
        }
      },
      userCampaignId: '01e55b6b-f004-4dbc-982a-e1060883a9d5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T17:36:21.000Z',
        amountCents: 2187
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'aab3737f-e621-4739-bd70-f2281ff3c8d3',
        firstName: 'Catrina',
        lastName: 'Dickens',
        email: 'catrinad71@gmail.com',
        instagramProfile: {
          handle: 'catd71',
          followers: 1020,
          engagementRate: 0.6127450980392157,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'catrinaa.dickens',
          followers: 149,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'catrinadickens9914',
          followers: 0
        }
      },
      userCampaignId: 'aab3737f-e621-4739-bd70-f2281ff3c8d3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T19:34:51.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd83cf44d-fd6e-4daf-a00b-2da903cabff1',
        firstName: 'Kay',
        lastName: 'Tolliver',
        email: 'kgrant6wildcat@yahoo.com',
        instagramProfile: {
          handle: 'savvy.deals2',
          followers: 315,
          engagementRate: 0.031746031746031744,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'savvy.deals2',
          followers: 490,
          engagementRate: 1.2295081967213115,
          isPrimary: false
        }
      },
      userCampaignId: 'd83cf44d-fd6e-4daf-a00b-2da903cabff1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T17:43:02.000Z',
        amountCents: 2117
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e3ee3410-1225-4c85-910a-f25d61a5bf4d',
        firstName: 'Emily ',
        lastName: 'Holt',
        email: 'alertemily@gmail.com',
        instagramProfile: {
          handle: 'emilyliterally',
          followers: 429,
          engagementRate: 0.9324009324009324,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'thecrossposter',
          followers: 78,
          isPrimary: false
        }
      },
      userCampaignId: 'e3ee3410-1225-4c85-910a-f25d61a5bf4d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T20:54:09.000Z',
        amountCents: 2162
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '9ab09ab6-ca2d-40a0-99cf-4167ff9291db',
        firstName: 'Mariah',
        lastName: 'Nonette',
        email: 'walkermariah10@gmail.com',
        instagramProfile: {
          handle: 'mariahwalkerr',
          followers: 139298,
          engagementRate: 0.4499705666987322,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mariahwalkerr',
          followers: 150093,
          engagementRate: 8.10195721438325,
          isPrimary: true
        }
      },
      userCampaignId: '9ab09ab6-ca2d-40a0-99cf-4167ff9291db'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T06:03:05.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fc27f0f7-5e5e-4f9c-b2c2-7fd1f508eb42',
        firstName: 'Yanet',
        lastName: 'Rosario',
        email: 'yanetdelrosario3340@gmail.com',
        tiktokProfile: {
          handle: 'sal.sof',
          followers: 327,
          isPrimary: false
        }
      },
      userCampaignId: 'fc27f0f7-5e5e-4f9c-b2c2-7fd1f508eb42'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T20:55:57.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '8abcc550-f087-4370-a5b9-33a00a4122e0',
        firstName: 'Lithunia',
        lastName: 'Donaldson',
        email: 'italiadonaldson@icloud.com',
        instagramProfile: {
          handle: 'iam_talia__',
          followers: 5019,
          engagementRate: 2.70970312811317,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'iam_talia___',
          followers: 33645,
          isPrimary: false
        }
      },
      userCampaignId: '8abcc550-f087-4370-a5b9-33a00a4122e0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T16:46:56.000Z',
        amountCents: 2186
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3b8e3813-4abf-4bb2-b572-3f2a1e6e4090',
        firstName: 'Mindi',
        lastName: 'Champagne',
        email: 'mindichampagne@gmail.com',
        tiktokProfile: {
          handle: 'mindi_champagne',
          followers: 2223,
          engagementRate: 8.328245828245828,
          isPrimary: false
        }
      },
      userCampaignId: '3b8e3813-4abf-4bb2-b572-3f2a1e6e4090'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T16:16:58.000Z',
        amountCents: 1997
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '4c86a549-1fad-45ef-b226-c7d2bc50a5e1',
        firstName: 'Katie',
        lastName: 'Garey',
        email: 'kboyden1995@gmail.com',
        tiktokProfile: {
          handle: 'katiegarey',
          followers: 1613,
          engagementRate: 9.668954804038878,
          isPrimary: false
        }
      },
      userCampaignId: '4c86a549-1fad-45ef-b226-c7d2bc50a5e1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T08:18:50.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '78dacb6f-6e61-4885-abab-855ffbaa1c66',
        firstName: 'Sofi',
        lastName: 'Sal',
        email: 'sofiarlbewl06@gmail.com',
        tiktokProfile: {
          handle: 'vkshuim',
          followers: 0,
          isPrimary: false
        }
      },
      userCampaignId: '78dacb6f-6e61-4885-abab-855ffbaa1c66'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T17:06:50.000Z',
        amountCents: 3257
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '9cb7c166-c88a-46a7-85f5-2cd719aa4637',
        firstName: 'Athena',
        lastName: 'Feliciano',
        email: 'athenafeliciano1019@gmail.com',
        instagramProfile: {
          handle: 'athenafeliciano',
          followers: 1581,
          engagementRate: 3.978494623655914,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'athenafeliciano',
          followers: 4261,
          engagementRate: 12.389134347897235,
          isPrimary: false
        }
      },
      userCampaignId: '9cb7c166-c88a-46a7-85f5-2cd719aa4637'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T17:32:58.000Z',
        amountCents: 2091
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '10a4b718-f999-43cf-be26-92589fac9b88',
        firstName: 'lia ',
        lastName: 'merrel',
        email: 'kaliakingzio@gmail.com',
        tiktokProfile: {
          handle: 'llliiaa444',
          followers: 6,
          engagementRate: 4.137931034482759,
          isPrimary: false
        }
      },
      userCampaignId: '10a4b718-f999-43cf-be26-92589fac9b88'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T19:16:09.000Z',
        amountCents: 2821
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c57df3fd-e336-4c63-91ed-5f8f7bfc9cd5',
        firstName: 'Dayna',
        lastName: 'Hjerpe',
        email: 'daynahjerpe700@gmail.com',
        instagramProfile: {
          handle: 'dayna.hjerpe',
          followers: 1823,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'yourfavoriteswede',
          followers: 296,
          isPrimary: false
        }
      },
      userCampaignId: 'c57df3fd-e336-4c63-91ed-5f8f7bfc9cd5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T19:13:43.000Z',
        amountCents: 2139
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3d6f7a33-9cca-42ab-a16b-c35c78342016',
        firstName: 'Jesse',
        lastName: 'Russo',
        email: 'therucrewnola@gmail.com',
        instagramProfile: {
          handle: 'therucrew',
          followers: 2670,
          engagementRate: 1.052434456928839,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'therucrew',
          followers: 25664,
          engagementRate: 5.577689243027888,
          isPrimary: true
        }
      },
      userCampaignId: '3d6f7a33-9cca-42ab-a16b-c35c78342016'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T14:24:25.000Z',
        amountCents: 2149
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'cc795da5-21da-40d4-bb59-4c6e54681d52',
        firstName: 'Yoisleny',
        lastName: 'Vazquez ',
        email: 'yoislenyvc@gmail.com',
        tiktokProfile: {
          handle: 'yoislenyvc',
          followers: 6,
          engagementRate: 0.2127659574468085,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'user-fw4gn8ow7c',
          followers: 0
        }
      },
      userCampaignId: 'cc795da5-21da-40d4-bb59-4c6e54681d52'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T11:55:42.000Z',
        amountCents: 2192
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fe7ed929-716c-4f16-b50b-c734bdbf695d',
        firstName: 'Amanda',
        lastName: 'Bilal',
        email: 'habituallyhealthylife@gmail.com',
        tiktokProfile: {
          handle: 'habituallyhealthyhabits',
          followers: 1160,
          engagementRate: 17.598371777476256,
          isPrimary: false
        }
      },
      userCampaignId: 'fe7ed929-716c-4f16-b50b-c734bdbf695d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T18:36:36.000Z',
        amountCents: 2103
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'eb52ba04-9267-4687-8f1c-6b013fbca9a6',
        firstName: 'Emily',
        lastName: 'Judd',
        email: 'etomboy86@gmail.com',
        instagramProfile: {
          handle: 'peaches_thecream',
          followers: 282,
          engagementRate: 0.26595744680851063,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'itsme_emilyy',
          followers: 146,
          engagementRate: 5.097730613000659,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'emilyjudd7150'
        }
      },
      userCampaignId: 'eb52ba04-9267-4687-8f1c-6b013fbca9a6'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f4138934-2973-47e6-88da-a989aec39d7a',
        firstName: 'Ahmed',
        lastName: 'Aljassas',
        email: 'ahmed.aljassas@hotmail.com',
        instagramProfile: {
          handle: 'ahmed.jassas',
          followers: 829,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'ahmed.jassas',
          followers: 12,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'ahmedal3761',
          followers: 0
        }
      },
      userCampaignId: 'f4138934-2973-47e6-88da-a989aec39d7a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T20:38:39.000Z',
        amountCents: 2139
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '2f81d065-0a6f-4431-8734-2d4b42f50d17',
        firstName: 'Graciela',
        lastName: 'Perez',
        email: 'graciela.perezcarpio@gmail.com',
        instagramProfile: {
          handle: 'justciela',
          followers: 9282,
          engagementRate: 0.9103641456582634,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'cielatravel',
          followers: 153,
          engagementRate: 5.7640750670241285,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'cielatravel6858',
          followers: 114
        }
      },
      userCampaignId: '2f81d065-0a6f-4431-8734-2d4b42f50d17'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T02:05:50.000Z',
        amountCents: 2000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c82704e6-8a8b-43e3-a1fb-600cd73a1851',
        firstName: 'Gabby',
        lastName: 'Abowitz',
        email: 'gabbyabowitz@gmail.com',
        tiktokProfile: {
          handle: 'gabbyhauls1',
          followers: 1355,
          engagementRate: 9.230769230769232,
          isPrimary: true
        }
      },
      userCampaignId: 'c82704e6-8a8b-43e3-a1fb-600cd73a1851'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T17:19:42.000Z',
        amountCents: 2139
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd3698eec-e5f6-42ec-b145-5de0c554be7c',
        firstName: 'Shanique',
        lastName: 'Newman',
        email: 'shaniquenewman@yahoo.com',
        instagramProfile: {
          handle: 'euqin_nique',
          followers: 1229,
          engagementRate: 0.772986167615948,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'uniqueshani',
          followers: 6650,
          engagementRate: 6.4680617188454175,
          isPrimary: false
        }
      },
      userCampaignId: 'd3698eec-e5f6-42ec-b145-5de0c554be7c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T16:29:29.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5208bb7e-44e9-4db1-ba75-8ba3a79a2734',
        firstName: 'Leighan',
        lastName: 'Stone',
        email: 'leyalee119@gmail.com',
        instagramProfile: {
          handle: 'leyalee___',
          followers: 2184,
          engagementRate: 1.6575091575091576,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'leyalee___',
          followers: 2492,
          engagementRate: 5.752212389380531,
          isPrimary: false
        }
      },
      userCampaignId: '5208bb7e-44e9-4db1-ba75-8ba3a79a2734'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T19:14:08.000Z',
        amountCents: 2186
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '6ccd583c-43eb-494e-8d8a-06708e4cdd37',
        firstName: 'Madelyn',
        lastName: 'Kist',
        email: 'kistmadelyn@gmail.com',
        instagramProfile: {
          handle: 'mad_scienkist',
          followers: 483,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'giardiasis37',
          followers: 0,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'mad_scienkist',
          followers: 156
        }
      },
      userCampaignId: '6ccd583c-43eb-494e-8d8a-06708e4cdd37'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T17:42:55.000Z',
        amountCents: 3279
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '7cd58e41-d949-48dc-abd7-6961234a3976',
        firstName: 'Blaisher',
        lastName: 'Berry',
        email: 'blaisher.olbali@gmail.com',
        instagramProfile: {
          handle: 'royaltys_raw_everything',
          followers: 18,
          engagementRate: 0,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'blaisher.olbaligrowhost',
          followers: 1012,
          engagementRate: 3.6487149889211743,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'royaltysrawboutique',
          followers: 21
        }
      },
      userCampaignId: '7cd58e41-d949-48dc-abd7-6961234a3976'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T14:27:13.000Z',
        amountCents: 4500
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '85815485-3ed7-4882-912b-c7f46c7ce0c4',
        firstName: 'Daniel',
        lastName: 'Leonard',
        email: 'dleonard11@liberty.edu',
        tiktokProfile: {
          handle: 'mac26434',
          followers: 0,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'danielleonard685'
        }
      },
      userCampaignId: '85815485-3ed7-4882-912b-c7f46c7ce0c4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T16:37:10.000Z',
        amountCents: 2117
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-29T15:42:21.483Z',
          createdAt: '2024-02-29T15:42:22.902Z',
          content: [
            {
              id: '885d5e4f-baae-4f1b-9d5b-7549b66ba1c8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a9698c2c-f31b-4bc6-bade-baddd490db30/ugc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9a9cbfb8-41e2-4c1b-880a-c3bcb5ebd4de',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a9698c2c-f31b-4bc6-bade-baddd490db30/ugc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '67320956-4c9f-435e-b9b5-ef247a5b0d08',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a9698c2c-f31b-4bc6-bade-baddd490db30/ugc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6e3c29f8-013f-4f69-b41a-d5746bbdd918'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a9698c2c-f31b-4bc6-bade-baddd490db30',
        firstName: 'Tatiana',
        lastName: 'Bianchini',
        email: 'bianchini_taty@hotmail.com',
        tiktokProfile: {
          handle: 't_bianchini',
          followers: 49199,
          engagementRate: 5.587093319052082,
          isPrimary: false
        }
      },
      userCampaignId: 'a9698c2c-f31b-4bc6-bade-baddd490db30'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T18:33:04.000Z',
        amountCents: 2129
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a28f604c-4bba-427d-8f05-894bba36b881',
        firstName: 'Taylor',
        lastName: 'Henderson',
        email: 'tayhen@udel.edu',
        instagramProfile: {
          handle: 'tayhendersonn',
          followers: 2403,
          engagementRate: 25.734498543487305,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'tayhendersonn',
          followers: 97,
          isPrimary: true
        }
      },
      userCampaignId: 'a28f604c-4bba-427d-8f05-894bba36b881'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T16:45:02.000Z',
        amountCents: 2192
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '40bdeb99-1b08-4990-8c41-f69809740ecd',
        firstName: 'Misty',
        lastName: 'Dowdy',
        email: 'mistyldowdy@gmail.com',
        instagramProfile: {
          handle: 'mistydowdy',
          followers: 5737,
          engagementRate: 1.8406832839463132,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mistydowdy',
          followers: 36518,
          engagementRate: 5.574164397333142,
          isPrimary: true
        }
      },
      userCampaignId: '40bdeb99-1b08-4990-8c41-f69809740ecd'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T20:54:22.000Z',
        amountCents: 2162
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '830c4df3-a898-43bf-9951-e7aab4b6b8f1',
        firstName: 'Shay',
        lastName: 'Daniels',
        email: 'biracialme@icloud.com',
        instagramProfile: {
          handle: 'biracial_goddess123',
          followers: 382,
          engagementRate: 1.3089005235602094,
          isPrimary: false
        }
      },
      userCampaignId: '830c4df3-a898-43bf-9951-e7aab4b6b8f1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T19:02:43.000Z',
        amountCents: 2149
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0f05092f-4644-4921-b303-d21d9fd4cb1c',
        firstName: 'Jessica',
        lastName: 'Philips',
        email: 'jesslivialife@gmail.com',
        instagramProfile: {
          handle: 'jesslivialife',
          followers: 4471,
          engagementRate: 6.0254976515320955,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'jesslivialife',
          followers: 162,
          isPrimary: false
        }
      },
      userCampaignId: '0f05092f-4644-4921-b303-d21d9fd4cb1c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T17:52:38.000Z',
        amountCents: 3136
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'bc3df2f4-3182-4d41-8285-0de5d1e9a12c',
        firstName: 'Samantha',
        lastName: 'Nelson',
        email: 'snelson1991@yahoo.com',
        instagramProfile: {
          handle: 'bosslady_kitten',
          followers: 853,
          engagementRate: 3.6342321219226257,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'sunshinemummy23',
          followers: 2661,
          isPrimary: false
        }
      },
      userCampaignId: 'bc3df2f4-3182-4d41-8285-0de5d1e9a12c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T07:35:51.000Z',
        amountCents: 2954
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '6b362f85-8757-494a-b3c8-de62d98e13ed',
        firstName: 'Adriana',
        lastName: 'Abrica',
        email: 'abricaadriana@gmail.com',
        instagramProfile: {
          handle: 'adrisgoodies_13',
          followers: 168,
          engagementRate: 3.6904761904761907,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'hazel_19',
          followers: 87,
          engagementRate: 2.506666666666667,
          isPrimary: false
        }
      },
      userCampaignId: '6b362f85-8757-494a-b3c8-de62d98e13ed'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T18:03:00.000Z',
        amountCents: 2117
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '57d36b9f-d208-4264-bcad-86419242927c',
        firstName: 'Christine',
        lastName: 'Benton',
        email: 'christinebentonfitness@gmail.com',
        instagramProfile: {
          handle: 'christinebenton.fit',
          followers: 11378,
          engagementRate: 0.9201968711548603,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'christinebenton.fit',
          followers: 654,
          engagementRate: 8.326596604688763,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'christinebentonfitness',
          followers: 2290
        }
      },
      userCampaignId: '57d36b9f-d208-4264-bcad-86419242927c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T19:53:00.000Z',
        amountCents: 2914
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a3c81f76-6207-4267-8e64-60a560764420',
        firstName: 'Janeth Ann',
        lastName: 'Rotaquio',
        email: 'rjanethann@gmail.com',
        tiktokProfile: {
          handle: 'janethxxii',
          followers: 103,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'janethannrotaquio9331',
          followers: 0
        }
      },
      userCampaignId: 'a3c81f76-6207-4267-8e64-60a560764420'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T13:53:23.000Z',
        amountCents: 2203
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '9419a52b-3fda-47d9-a05a-7b1a06d25b98',
        firstName: 'Gloria',
        lastName: 'Beyer',
        email: 'gloria.beyer@hotmail.com',
        instagramProfile: {
          handle: 'thicclikebisquicc',
          followers: 2919,
          engagementRate: 0.5823912298732443,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thicclikebisquicc',
          followers: 226,
          engagementRate: 1.0484927916120577,
          isPrimary: true
        }
      },
      userCampaignId: '9419a52b-3fda-47d9-a05a-7b1a06d25b98'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T16:56:31.000Z',
        amountCents: 2152
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '7d9747be-13bc-4eec-849e-435112c155c0',
        firstName: 'Tomee',
        lastName: 'Jarrett',
        email: 'tomeejarrett@gmail.com',
        instagramProfile: {
          handle: 'thistomee',
          followers: 17866,
          engagementRate: 0.38173066159185043,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thistomee',
          followers: 57,
          isPrimary: true
        }
      },
      userCampaignId: '7d9747be-13bc-4eec-849e-435112c155c0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T17:19:39.000Z',
        amountCents: 2140
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5e5405c5-d938-4616-b97f-356e345b6dfb',
        firstName: 'Kiah',
        lastName: 'Gollaher',
        email: 'kiahbrielle11@gmail.com',
        instagramProfile: {
          handle: 'kiahbrielleg',
          followers: 52,
          engagementRate: 10.576923076923077,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kiah.gollaher',
          followers: 5,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'kiahbriellejohnson3683',
          followers: 4
        }
      },
      userCampaignId: '5e5405c5-d938-4616-b97f-356e345b6dfb'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T19:14:14.000Z',
        amountCents: 2919
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a2912a3e-ba61-4af8-b99a-53ed1cdda6de',
        firstName: 'Aubrey',
        lastName: 'Fox',
        email: 'aubreylfox88@gmail.com',
        instagramProfile: {
          handle: 'aubrey.fox.33',
          followers: 1122,
          engagementRate: 0.9893048128342247,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'aubreylaine333',
          followers: 1553,
          engagementRate: 4.696132596685083,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'aubreyfox88'
        }
      },
      userCampaignId: 'a2912a3e-ba61-4af8-b99a-53ed1cdda6de'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-27T18:34:03.000Z',
        amountCents: 2421
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '8d25ab7a-5318-42e4-bf5b-f20a163cc94c',
        firstName: 'Trina',
        lastName: 'Wright',
        email: 'trinawrightbusiness@gmail.com',
        tiktokProfile: {
          handle: 'duh.its_trina',
          followers: 4931,
          engagementRate: 10.508095716498817,
          isPrimary: false
        }
      },
      userCampaignId: '8d25ab7a-5318-42e4-bf5b-f20a163cc94c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T04:55:48.000Z',
        amountCents: 2959
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'bf8a0f2d-afe3-4fc9-ba9f-6eec77c1ad8f',
        firstName: 'Sara',
        lastName: 'Kastanteen',
        email: 'sark620@gmail.com',
        instagramProfile: {
          handle: 'parivibe__',
          followers: 8529,
          engagementRate: 1.4011021221714153,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'parivibe__',
          followers: 243,
          engagementRate: 10.653225217327781,
          isPrimary: true
        }
      },
      userCampaignId: 'bf8a0f2d-afe3-4fc9-ba9f-6eec77c1ad8f'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '952cc6ee-cac5-4db7-beff-d342cd58a954',
        firstName: 'Aaliyah',
        lastName: 'Palmer',
        email: 'lovefun79@hotmail.com',
        instagramProfile: {
          handle: 'msgaza79',
          followers: 3628,
          engagementRate: 0.09647188533627342,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'msgaza29',
          followers: 1549,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'aaliyahp9683',
          followers: 1
        }
      },
      userCampaignId: '952cc6ee-cac5-4db7-beff-d342cd58a954'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T21:33:51.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'df2a5555-542f-404a-9744-55c8ad23437e',
        firstName: 'Britney Kay',
        lastName: 'Thompson',
        email: 'britneythompson178@gmail.com',
        instagramProfile: {
          handle: '_brit_neeyy',
          followers: 3346,
          engagementRate: 3.167961745367603,
          isPrimary: false
        },
        tiktokProfile: {
          handle: '_brit_neeyy',
          followers: 1984,
          engagementRate: 5.8735459949867135,
          isPrimary: false
        },
        youtubeProfile: {
          handle: '_brit_neeyy',
          followers: 314
        }
      },
      userCampaignId: 'df2a5555-542f-404a-9744-55c8ad23437e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T06:54:26.000Z',
        amountCents: 3138
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '1bdb020a-3021-4cd9-91f7-5c3fc120f718',
        firstName: 'Amoya',
        lastName: 'Lowe',
        email: 'amoyalowe854@gmail.com',
        instagramProfile: {
          handle: 'amoyalowe18',
          followers: 823,
          engagementRate: 0.6075334143377886,
          isPrimary: false
        }
      },
      userCampaignId: '1bdb020a-3021-4cd9-91f7-5c3fc120f718'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T16:56:29.000Z',
        amountCents: 3500
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'eb2e6712-f90f-4a25-9dd4-9df085f33b5e',
        firstName: 'Ana',
        lastName: 'Henriquez',
        email: 'heavenlywaxed@gmail.com',
        tiktokProfile: {
          handle: 'anaa.trrss',
          followers: 265,
          engagementRate: 6.906906906906906,
          isPrimary: false
        }
      },
      userCampaignId: 'eb2e6712-f90f-4a25-9dd4-9df085f33b5e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T17:35:29.000Z',
        amountCents: 2139
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fa30fe53-8806-4064-b043-d00ee62713f5',
        firstName: 'Tosh and Ty',
        lastName: 'Bell',
        email: 'hello@tntswirl.com',
        instagramProfile: {
          handle: 'tntswirl',
          followers: 130427,
          engagementRate: 1.562329885683179,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'tntswirl',
          followers: 26285,
          engagementRate: 4.093934446816527,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'tntswirl.official'
        }
      },
      userCampaignId: 'fa30fe53-8806-4064-b043-d00ee62713f5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T09:26:09.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '2fc95ccd-6b9b-431d-ad6a-91023ae2d731',
        firstName: 'Ashley',
        lastName: 'Dills',
        email: 'ashleydills28@yahoo.com',
        instagramProfile: {
          handle: 'ashley__dills',
          followers: 1491,
          engagementRate: 3.86317907444668,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ashley__dills',
          followers: 368,
          isPrimary: true
        }
      },
      userCampaignId: '2fc95ccd-6b9b-431d-ad6a-91023ae2d731'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '056b173f-3dd0-441b-81b5-d1ea5a835bda',
        firstName: 'Maya',
        lastName: 'Shourie',
        email: 'mayas4h@gmail.com',
        instagramProfile: {
          handle: 'maya4h',
          followers: 2166,
          engagementRate: 3.3748845798707294,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'maya.j.s',
          followers: 1929,
          engagementRate: 8.868723532970357,
          isPrimary: false
        }
      },
      userCampaignId: '056b173f-3dd0-441b-81b5-d1ea5a835bda'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T11:36:34.000Z',
        amountCents: 2129
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c8afa7e9-4f66-4324-92ac-2bbc45238f63',
        firstName: 'Braelyn',
        lastName: 'Perez',
        email: 'braecation@gmail.com',
        instagramProfile: {
          handle: 'braecation',
          followers: 3380,
          engagementRate: 3.7603550295857984,
          isPrimary: true
        }
      },
      userCampaignId: 'c8afa7e9-4f66-4324-92ac-2bbc45238f63'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T15:54:15.000Z',
        amountCents: 2912
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f064fde6-dfd7-43fc-b853-cd969046e6d7',
        firstName: 'Misbah',
        lastName: 'Iqbal',
        email: 'qaisarmisbah@gmail.com',
        instagramProfile: {
          handle: 'mqsfreebies',
          followers: 58486,
          engagementRate: 3.043634374038231,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mqsfreebies',
          followers: 8754,
          engagementRate: 12.058188430503467,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'mqsfreebies'
        }
      },
      userCampaignId: 'f064fde6-dfd7-43fc-b853-cd969046e6d7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-03T20:53:12.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '4d0bc0fc-cec8-412c-ab44-85820f958aa9',
        firstName: 'Blaine',
        lastName: 'Ledbetter',
        email: 'watblaine9@gmail.com',
        tiktokProfile: {
          handle: 'thetinybev',
          followers: 117,
          engagementRate: 2.973977695167286,
          isPrimary: true
        }
      },
      userCampaignId: '4d0bc0fc-cec8-412c-ab44-85820f958aa9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T17:42:52.000Z',
        amountCents: 2163
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '513f7b54-cab9-4bcd-a439-82f1fb7986e1',
        firstName: 'Haylie',
        lastName: 'Powers',
        email: 'omghaylie12@yahoo.com',
        instagramProfile: {
          handle: 'haylie.ugc',
          followers: 1197,
          engagementRate: 6.867167919799499,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'createwithhaylie',
          followers: 5190,
          engagementRate: 7.73889426528892,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'createwithhaylie',
          followers: 1
        }
      },
      userCampaignId: '513f7b54-cab9-4bcd-a439-82f1fb7986e1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T19:44:18.000Z',
        amountCents: 2139
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '98abca8c-3a84-49bb-8728-a5b3e6cfe6d4',
        firstName: 'Jessica',
        lastName: 'Guerra',
        email: 'ugc.jessicaguerra@gmail.com',
        instagramProfile: {
          handle: 'alebasi_g7',
          followers: 10509,
          engagementRate: 0.439623180131316,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'alebasig',
          followers: 1881,
          engagementRate: 3.0733206838179212,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'jessg7',
          followers: 9
        }
      },
      userCampaignId: '98abca8c-3a84-49bb-8728-a5b3e6cfe6d4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T02:44:17.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '4a4119ce-5bbd-42d0-a43b-6859a7d72bec',
        firstName: 'Mariana',
        lastName: 'Komula',
        email: 'bohowildflower.co@gmail.com',
        instagramProfile: {
          handle: 'thebohowildflower',
          followers: 6179,
          engagementRate: 1.793170415924907,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thebohowildflower',
          followers: 1500,
          engagementRate: 11.35646687697161,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'thebohowildflower'
        }
      },
      userCampaignId: '4a4119ce-5bbd-42d0-a43b-6859a7d72bec'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T23:15:52.000Z',
        amountCents: 2091
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd2294b81-2b3c-4156-8c0c-a93e1841a281',
        firstName: 'Brittany',
        lastName: 'Briggs',
        email: 'brittany.briggs92@yahoo.com',
        instagramProfile: {
          handle: 'brittanykbriggs',
          followers: 17674,
          engagementRate: 0.016006563313341635,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'brittanybriggs',
          followers: 1271,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'musicisaboutthetimes',
          followers: 0
        }
      },
      userCampaignId: 'd2294b81-2b3c-4156-8c0c-a93e1841a281'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T16:14:09.000Z',
        amountCents: 2406
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3159ba19-1130-4516-b929-becc1fe14aaf',
        firstName: 'Chantel',
        lastName: 'Smith',
        email: 'chantelsmith.chan@gmail.com',
        tiktokProfile: {
          handle: 'finesse370k',
          followers: 947,
          engagementRate: 6.946296985790212,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'piscesbaddiee',
          followers: 2010
        }
      },
      userCampaignId: '3159ba19-1130-4516-b929-becc1fe14aaf'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T06:33:39.000Z',
        amountCents: 2903
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '64eddb8b-35fc-497a-8ca2-8c310d6e2246',
        firstName: 'Maya',
        lastName: 'Z',
        email: 'zmaya626@gmail.com',
        instagramProfile: {
          handle: 'light.candies',
          followers: 160,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'spikyspooke',
          followers: 3,
          isPrimary: false
        }
      },
      userCampaignId: '64eddb8b-35fc-497a-8ca2-8c310d6e2246'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T21:13:00.000Z',
        amountCents: 2894
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'aa345e15-5a9b-447c-ad36-bf0a085edd4a',
        firstName: 'Caroline',
        lastName: 'Stickels',
        email: 'carolinesticca18@gmail.com',
        tiktokProfile: {
          handle: 'carolinealex03',
          followers: 8887,
          engagementRate: 13.737414904375916,
          isPrimary: false
        }
      },
      userCampaignId: 'aa345e15-5a9b-447c-ad36-bf0a085edd4a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T07:03:50.000Z',
        amountCents: 2937
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '838d9166-37f0-44e9-bdf8-497b7ec08dab',
        firstName: 'Natalia',
        lastName: 'Gonzalez',
        email: 'senka0910@gmail.com',
        instagramProfile: {
          handle: 'natbnyc9',
          followers: 5404,
          engagementRate: 5.0444115470022215,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'natbnyc9',
          followers: 1610,
          engagementRate: 10.097719869706841,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'madridistanat',
          followers: 3840
        }
      },
      userCampaignId: '838d9166-37f0-44e9-bdf8-497b7ec08dab'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T16:22:57.000Z',
        amountCents: 2177
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '43fef1fd-d15c-4728-a6fb-b4a2a2803a02',
        firstName: 'Tess',
        lastName: 'Farnsworth',
        email: 'tess@tessfarnsworth.com',
        instagramProfile: {
          handle: 'tessfarnsworth',
          followers: 1690,
          engagementRate: 1.6331360946745563,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'tessfarnsworth',
          followers: 98,
          engagementRate: 8.521593166372472,
          isPrimary: true
        }
      },
      userCampaignId: '43fef1fd-d15c-4728-a6fb-b4a2a2803a02'
    },
    {
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '7b92b25f-6d83-4956-8603-14ab699c704a',
        firstName: 'Mandy',
        lastName: 'Bradbury',
        email: 'bradburymandy_1@yahoo.com',
        instagramProfile: {
          handle: 'mandy_kayb',
          followers: 863,
          engagementRate: 2.966396292004635,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'mandybradbury',
          followers: 259,
          isPrimary: false
        }
      },
      userCampaignId: '7b92b25f-6d83-4956-8603-14ab699c704a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-06T07:05:51.000Z',
        amountCents: 2129
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '19f83775-141d-4c3c-8a24-0842748ce6f4',
        firstName: 'Chandler',
        lastName: 'Miller',
        email: 'sproutukids@gmail.com',
        instagramProfile: {
          handle: 'its_sprout_u_kids',
          followers: 118292,
          engagementRate: 1.6048422547594092,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sproutukids',
          followers: 8228,
          engagementRate: 9.268292682926829,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'sproutukids'
        }
      },
      userCampaignId: '19f83775-141d-4c3c-8a24-0842748ce6f4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T16:04:05.000Z',
        amountCents: 2943
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ae19f832-9f3b-4ac7-8ede-8d49f412e0f7',
        firstName: 'Lucas',
        lastName: 'Wreist',
        email: 'gibbsnikota12@yahoo.com',
        tiktokProfile: {
          handle: 'tyson.gibbs',
          followers: 0,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'hayley5448',
          followers: 0
        }
      },
      userCampaignId: 'ae19f832-9f3b-4ac7-8ede-8d49f412e0f7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-03T20:06:23.000Z',
        amountCents: 2122
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ee7c89fe-001d-4f70-9fb9-fb28b647110d',
        firstName: 'Babilon',
        lastName: 'Whittington',
        email: 'babilonsbody@gmail.com',
        instagramProfile: {
          handle: 'babilonkay',
          followers: 3549,
          engagementRate: 0.16906170752324598,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'babilonkay',
          followers: 19,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'babilonkay'
        }
      },
      userCampaignId: 'ee7c89fe-001d-4f70-9fb9-fb28b647110d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-03T12:12:19.000Z',
        amountCents: 2189
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '96b99aad-83a6-4d87-a27e-997b5d75d2c2',
        firstName: 'jessica',
        lastName: 'Lee',
        email: 'jessicacarrielee@gmail.com',
        instagramProfile: {
          handle: 'jessicacarrielee',
          followers: 198703,
          engagementRate: 1.1522724870787053,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'jessicacarrielee',
          followers: 73091,
          engagementRate: 7.800594084979981,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'jessicacarrielee'
        }
      },
      userCampaignId: '96b99aad-83a6-4d87-a27e-997b5d75d2c2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T16:24:08.000Z',
        amountCents: 2083
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '47b0d4e4-0c0e-4503-ab99-10c657fca640',
        firstName: 'Carlee',
        lastName: 'Gerren',
        email: 'carleegerren1993@gmail.com',
        instagramProfile: {
          handle: 'carleegerren',
          followers: 25,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'carleelynn',
          followers: 450,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'carleegerren3019',
          followers: 0
        }
      },
      userCampaignId: '47b0d4e4-0c0e-4503-ab99-10c657fca640'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T17:04:38.000Z',
        amountCents: 2129
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f7495c01-597e-4409-9200-97f4e042f593',
        firstName: 'Mackenzie',
        lastName: 'Githens',
        email: 'mackenzielynngithens@gmail.com',
        instagramProfile: {
          handle: 'bakedgod',
          followers: 4940,
          engagementRate: 0.4230769230769231,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mackenziegithens6',
          followers: 821,
          engagementRate: 7.039679574010929,
          isPrimary: true
        }
      },
      userCampaignId: 'f7495c01-597e-4409-9200-97f4e042f593'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T22:16:12.000Z',
        amountCents: 2202
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '13d8f332-015c-4af5-97c2-4d177d7a2ca2',
        firstName: 'Jasmine',
        lastName: 'Lowe',
        email: 'jlowe97123@gmail.com',
        instagramProfile: {
          handle: 'jasmineerudite',
          followers: 1660,
          engagementRate: 0.03733572281959379,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'jasmineerudite',
          followers: 1272,
          engagementRate: 8.34035827186512,
          isPrimary: true
        }
      },
      userCampaignId: '13d8f332-015c-4af5-97c2-4d177d7a2ca2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T12:25:43.000Z',
        amountCents: 2184
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '2bfdd69a-9c00-4b16-81f1-2ce0863bf9eb',
        firstName: 'Ceyda',
        lastName: 'Baradehi',
        email: 'ceydadurmus96@gmail.com',
        instagramProfile: {
          handle: 'ceydabaradehi',
          followers: 28986,
          engagementRate: 3.347478092872421,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'ceydabaradehi',
          followers: 80
        }
      },
      userCampaignId: '2bfdd69a-9c00-4b16-81f1-2ce0863bf9eb'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-28T19:59:49.000Z',
        amountCents: 2129
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd320b9df-388b-490e-afa8-1ca90fda970a',
        firstName: 'Lisa',
        lastName: 'Juliano',
        email: 'two.soul.sistas2@gmail.com',
        instagramProfile: {
          handle: 'two.soul.sistas',
          followers: 8445,
          engagementRate: 1.6341030195381885,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'two.soul.sistas',
          followers: 260,
          engagementRate: 11.190341521223873,
          isPrimary: false
        }
      },
      userCampaignId: 'd320b9df-388b-490e-afa8-1ca90fda970a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T18:14:55.000Z',
        amountCents: 2147
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '93d2eee4-c3c9-4da7-99bf-c77607684127',
        firstName: 'Catie',
        lastName: 'McFadden',
        email: 'clmcfadden11@gmail.com',
        instagramProfile: {
          handle: 'catiemcfadden',
          followers: 1658,
          engagementRate: 1.7490952955367913,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'catiemcfadden',
          followers: 165,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'catiemcfadden482',
          followers: 0
        }
      },
      userCampaignId: '93d2eee4-c3c9-4da7-99bf-c77607684127'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T17:44:33.000Z',
        amountCents: 2174
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '29df1286-5510-4d08-9887-d9b7511c35cd',
        firstName: 'Lamees',
        lastName: 'Attar',
        email: 'lameesattarbashi@hotmail.com',
        instagramProfile: {
          handle: 'cookingwithlamees',
          followers: 184681,
          engagementRate: 4.996940670669966,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'cookingwithlamees',
          followers: 91052,
          engagementRate: 3.5000000000000004,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'cookingwithlamees'
        }
      },
      userCampaignId: '29df1286-5510-4d08-9887-d9b7511c35cd'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T02:13:02.000Z',
        amountCents: 2858
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'df92e70e-2b05-4420-a8c4-2e8c4c88bcff',
        firstName: 'Nami',
        lastName: 'Ayah',
        email: 'taaydecent@gmail.com',
        instagramProfile: {
          handle: 'nami_ayah',
          followers: 8880,
          engagementRate: 5.801801801801802,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'nami.ayah',
          followers: 1590,
          engagementRate: 7.114624505928854,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'nami_ayah',
          followers: 18
        }
      },
      userCampaignId: 'df92e70e-2b05-4420-a8c4-2e8c4c88bcff'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T02:46:16.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fde6348d-bd34-4dcf-a860-d54835934780',
        firstName: 'Jhoen',
        lastName: 'Delgado',
        email: 'jhoendelgado17@gmail.com',
        instagramProfile: {
          handle: 'byjhoen',
          followers: 1880,
          engagementRate: 1.8156028368794326,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'byjhoen',
          followers: 1865,
          engagementRate: 3.0716723549488054,
          isPrimary: false
        }
      },
      userCampaignId: 'fde6348d-bd34-4dcf-a860-d54835934780'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '32537642-4e94-4d03-9058-706b1bfa95c4',
        firstName: 'Gianna',
        lastName: 'Kleinpell',
        email: 'giannamc@aol.com',
        instagramProfile: {
          handle: 'kleinpellteamrealty',
          followers: 325,
          engagementRate: 3.323076923076923,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'gianna.realtor',
          followers: 53,
          isPrimary: false
        }
      },
      userCampaignId: '32537642-4e94-4d03-9058-706b1bfa95c4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T20:46:01.000Z',
        amountCents: 2202
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a8fa09b1-1aab-4756-b25a-3ee6366ca5fc',
        firstName: 'Alondra',
        lastName: 'Oseguera',
        email: 'amog803@gmail.com',
        instagramProfile: {
          handle: 'alondraa_803',
          followers: 893,
          engagementRate: 7.705099778270511,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'virgobby222',
          followers: 790,
          engagementRate: 15.405777166437415,
          isPrimary: false
        }
      },
      userCampaignId: 'a8fa09b1-1aab-4756-b25a-3ee6366ca5fc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T19:16:02.000Z',
        amountCents: 2480
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '4659e024-e613-4175-a8ca-3832e068ddc9',
        firstName: 'King',
        lastName: 'Kas',
        email: 'chefkingkas@gmail.com',
        instagramProfile: {
          handle: 'chefkingkas',
          followers: 5739,
          engagementRate: 0.42516117790555846,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'chefkingkas',
          followers: 105,
          engagementRate: 2.3418818747011,
          isPrimary: true
        }
      },
      userCampaignId: '4659e024-e613-4175-a8ca-3832e068ddc9'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e08cec31-5c9e-42a7-b5f2-b9f9f34ec56c',
        firstName: 'Kimberley',
        lastName: 'Gibson',
        email: 'kimgibson853@gmail.com',
        instagramProfile: {
          handle: 'bend.detrendboutique',
          followers: 175,
          engagementRate: 4.914285714285714,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'babyboo5279',
          followers: 1121,
          isPrimary: false
        }
      },
      userCampaignId: 'e08cec31-5c9e-42a7-b5f2-b9f9f34ec56c'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ff6cb30c-96e5-469c-9f36-6b60b95da4b0',
        firstName: 'Danielle',
        lastName: 'Kruse',
        email: 'danielle.kruse5@gmail.com',
        instagramProfile: {
          handle: 'relatablemom97',
          followers: 12,
          engagementRate: 4.166666666666666,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'relatablemom97',
          followers: 60,
          engagementRate: 0.7662835249042145,
          isPrimary: false
        }
      },
      userCampaignId: 'ff6cb30c-96e5-469c-9f36-6b60b95da4b0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T21:55:54.000Z',
        amountCents: 2172
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '4a228588-61a5-4ecb-b8ab-a8f0f9e509f3',
        firstName: 'Amelia',
        lastName: 'Zamora',
        email: 'mamabearsreviews@gmail.com',
        instagramProfile: {
          handle: 'mamabearreview',
          followers: 15594,
          engagementRate: 8.82775426446069,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mamabearreview',
          followers: 1193,
          engagementRate: 4.280638429868592,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'mamabearreviews'
        }
      },
      userCampaignId: '4a228588-61a5-4ecb-b8ab-a8f0f9e509f3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T21:03:10.000Z',
        amountCents: 2157
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '9f2cdcd3-000d-439c-9a88-5c3902352c3d',
        firstName: 'Alissa',
        lastName: 'Rowell',
        email: 'alissarowell38@icloud.com',
        instagramProfile: {
          handle: 'mona.xlissa',
          followers: 1346,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'alissaxrenee',
          followers: 1507,
          engagementRate: 5.15859653848744,
          isPrimary: false
        }
      },
      userCampaignId: '9f2cdcd3-000d-439c-9a88-5c3902352c3d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T18:10:20.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '067b4d93-e169-49bd-8f21-cd47746ac75e',
        firstName: 'Sofi',
        lastName: 'S',
        email: '8c4d1d65256c2a@beaconmessenger.com',
        tiktokProfile: {
          handle: 'freakysa2',
          followers: 6,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'sofiasalcedo5168',
          followers: 0
        }
      },
      userCampaignId: '067b4d93-e169-49bd-8f21-cd47746ac75e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T17:29:55.000Z',
        amountCents: 3198
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '8f28f4a0-b946-4a69-8b86-17d9c70d35d0',
        firstName: 'Ava',
        lastName: 'Mays',
        email: 'avamayselmax@gmail.com',
        tiktokProfile: {
          handle: 'ava_jane1234',
          followers: 127,
          engagementRate: 7.210002903600465,
          isPrimary: false
        }
      },
      userCampaignId: '8f28f4a0-b946-4a69-8b86-17d9c70d35d0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T23:41:18.000Z',
        amountCents: 2056
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a4bf7899-b8f4-4b61-aaf1-e97d6fd48f14',
        firstName: 'Marsh',
        lastName: 'Do',
        email: 'aboho369@gmail.com',
        instagramProfile: {
          handle: 'marshian.store',
          followers: 0,
          engagementRate: 25,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'itsnotfunnyatalll',
          followers: 49,
          engagementRate: 14.285714285714285,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'minhdohuongnoi',
          followers: 162
        }
      },
      userCampaignId: 'a4bf7899-b8f4-4b61-aaf1-e97d6fd48f14'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T15:05:29.000Z',
        amountCents: 2836
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3222206b-82b4-41d5-ae0f-d55b94366b3c',
        firstName: 'Saschine',
        lastName: 'Miller',
        email: 'ajaylademetrius432@gmail.com',
        instagramProfile: {
          handle: '_saschimi_16',
          followers: 3115,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'saschimi__',
          followers: 3112,
          engagementRate: 10.364145658263306,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'saschimi607',
          followers: 131
        }
      },
      userCampaignId: '3222206b-82b4-41d5-ae0f-d55b94366b3c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T22:05:23.000Z',
        amountCents: 1997
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3efd547e-4425-4bc9-99f7-b1acb04fd1dd',
        firstName: 'Josiah',
        lastName: 'Hill-Meyer',
        email: 'm3istro@gmail.com',
        instagramProfile: {
          handle: 'm3istro_productions',
          followers: 11134,
          engagementRate: 0.01886114603915933,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'm3istro',
          followers: 108,
          engagementRate: 2.2556390977443606,
          isPrimary: false
        }
      },
      userCampaignId: '3efd547e-4425-4bc9-99f7-b1acb04fd1dd'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '1b2532be-6754-4794-a251-e292375a38c8',
        firstName: 'Hayley',
        lastName: 'Bordes',
        email: 'littlemeandfree@gmail.com',
        instagramProfile: {
          handle: 'littlemeandfree',
          followers: 128498,
          engagementRate: 1.166477299257576,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'littlemeandfree',
          followers: 9618,
          engagementRate: 1.893939393939394,
          isPrimary: true
        }
      },
      userCampaignId: '1b2532be-6754-4794-a251-e292375a38c8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T19:35:08.000Z',
        amountCents: 2174
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd58c6530-18f1-45dc-9e65-107637e19ec8',
        firstName: 'Tristen',
        lastName: 'Charles',
        email: 'tcmcqueen97@gmail.com',
        instagramProfile: {
          handle: '__alexndre__',
          followers: 5055,
          engagementRate: 1.5254423563028905,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'alexandrenomcqueen',
          followers: 75,
          engagementRate: 2.9829545454545454,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'alexandre_2.0',
          followers: 8
        }
      },
      userCampaignId: 'd58c6530-18f1-45dc-9e65-107637e19ec8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T19:16:04.000Z',
        amountCents: 2463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '4ad21864-3a6b-429f-a7c5-5cc9ae176bcb',
        firstName: 'Jessica',
        lastName: 'Hernandez',
        email: 'theradlifeofjess@gmail.com',
        instagramProfile: {
          handle: 'aradteacher',
          followers: 990,
          engagementRate: 0.08417508417508418,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'radlifeofjess',
          followers: 45,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'h2opolo20111',
          followers: 21
        }
      },
      userCampaignId: '4ad21864-3a6b-429f-a7c5-5cc9ae176bcb'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '46e88e2d-b64d-4727-ac51-9048e13c7cde',
        firstName: 'Hannah ',
        lastName: 'Lee',
        email: 'survivalofthesahm@gmail.com',
        instagramProfile: {
          handle: 'survivalofthesahm',
          followers: 6092,
          engagementRate: 1.7547603414313855,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'survivalofthesahm',
          followers: 2022,
          engagementRate: 7.340419687980663,
          isPrimary: true
        }
      },
      userCampaignId: '46e88e2d-b64d-4727-ac51-9048e13c7cde'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T05:02:27.000Z',
        amountCents: 2117
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '91b0099c-1f52-4d46-b874-65c8a69d6f37',
        firstName: 'Brooke',
        lastName: 'Carlson',
        email: 'brookecarlson411@yahoo.com',
        tiktokProfile: {
          handle: 'brookeacarlson',
          followers: 1360,
          isPrimary: false
        }
      },
      userCampaignId: '91b0099c-1f52-4d46-b874-65c8a69d6f37'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T10:12:57.000Z',
        amountCents: 2821
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd3bb6072-f009-4882-96a0-152238ac8c0e',
        firstName: 'Holly',
        lastName: 'Gonzalez',
        email: 'cottoncandyholly@gmail.com',
        tiktokProfile: {
          handle: 'hollistergonzalez',
          followers: 87,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'hollygonzalez7863',
          followers: 0
        }
      },
      userCampaignId: 'd3bb6072-f009-4882-96a0-152238ac8c0e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T18:56:50.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'df41da6a-799f-4604-8106-2206d30ee388',
        firstName: 'Soph',
        lastName: 'Sal',
        email: 'ilteyci@mailnesia.com',
        tiktokProfile: {
          handle: 'maeeiwnyc',
          followers: 0,
          isPrimary: false
        }
      },
      userCampaignId: 'df41da6a-799f-4604-8106-2206d30ee388'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T09:22:53.000Z',
        amountCents: 2912
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f2d80ebf-6dfc-435d-b42e-725d51b82f0b',
        firstName: 'Margret',
        lastName: 'Simons',
        email: 'margretsimons@yahoo.com',
        tiktokProfile: {
          handle: 'margretsimons',
          followers: 13,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'margretsimons1693',
          followers: 2
        }
      },
      userCampaignId: 'f2d80ebf-6dfc-435d-b42e-725d51b82f0b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T20:54:49.000Z',
        amountCents: 2202
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '68167f3f-4635-4ebe-aa33-8be33572c2e9',
        firstName: 'Isaac',
        lastName: 'Pastrana',
        email: 'pastrana.isaac@gmail.com',
        instagramProfile: {
          handle: 'kingodinwar',
          followers: 68,
          engagementRate: 10.588235294117647,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kingodinwar',
          followers: 138,
          engagementRate: 2.31990231990232,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'kingodinwargaming'
        }
      },
      userCampaignId: '68167f3f-4635-4ebe-aa33-8be33572c2e9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T19:49:30.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '7d6f02fe-e4fc-4605-967e-65608f114d55',
        firstName: 'Sofi',
        lastName: 'Sal',
        email: 'etme@mailnesia.com',
        tiktokProfile: {
          handle: 'bidibidibonmon',
          followers: 0,
          isPrimary: false
        }
      },
      userCampaignId: '7d6f02fe-e4fc-4605-967e-65608f114d55'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T17:43:00.000Z',
        amountCents: 2384
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '99f6f321-cd37-47f2-97f7-1bda11c338f1',
        firstName: 'Sarah',
        lastName: 'Hampsey',
        email: 'dcanphyfe@gmail.com',
        instagramProfile: {
          handle: 'dcanphyfe',
          followers: 574,
          engagementRate: 0.6097560975609756,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'dcanphyfe',
          followers: 70,
          isPrimary: true
        }
      },
      userCampaignId: '99f6f321-cd37-47f2-97f7-1bda11c338f1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T19:35:08.000Z',
        amountCents: 2858
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '693e0998-b4e2-4679-b6b7-8ce933a39ce0',
        firstName: 'Samira',
        lastName: 'Wideman',
        email: 'Miramira2206@gmail.com',
        tiktokProfile: {
          handle: 'samira.wideman',
          followers: 138,
          isPrimary: false
        }
      },
      userCampaignId: '693e0998-b4e2-4679-b6b7-8ce933a39ce0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T06:34:47.000Z',
        amountCents: 5000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e252e025-9900-44a0-9b33-22ca77f77c9d',
        firstName: 'Sofi',
        lastName: 'Arl',
        email: 'xsof13@mailfence.com',
        tiktokProfile: {
          handle: 'vkshuim',
          followers: 0,
          isPrimary: false
        }
      },
      userCampaignId: 'e252e025-9900-44a0-9b33-22ca77f77c9d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T16:17:43.000Z',
        amountCents: 2429
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '39627140-fa7f-4b2b-8a4e-eaaf08bb168b',
        firstName: 'Lexi',
        lastName: 'Pitts',
        email: 'lex.russ.lr@gmail.com',
        tiktokProfile: {
          handle: 'bigbosslex23',
          followers: 332,
          engagementRate: 13.785330327726719,
          isPrimary: false
        }
      },
      userCampaignId: '39627140-fa7f-4b2b-8a4e-eaaf08bb168b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T19:23:07.000Z',
        amountCents: 2406
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd5523513-6335-425a-9f92-a57754436066',
        firstName: 'Bruna',
        lastName: 'Proenca',
        email: 'brendatur@yahoo.com.br',
        instagramProfile: {
          handle: 'brumaite',
          followers: 2934,
          engagementRate: 6.027039309247899,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'brunamaiteproenca',
          followers: 94,
          isPrimary: false
        }
      },
      userCampaignId: 'd5523513-6335-425a-9f92-a57754436066'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T23:44:20.000Z',
        amountCents: 2919
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'bc04b5c0-5612-4103-b58d-e2ffa1049df7',
        firstName: 'Camryn',
        lastName: 'Moore',
        email: 'camrynmoore2005@gmail.com',
        tiktokProfile: {
          handle: 'cam_moore_',
          followers: 319,
          engagementRate: 14.317180616740089,
          isPrimary: false
        }
      },
      userCampaignId: 'bc04b5c0-5612-4103-b58d-e2ffa1049df7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-29T19:01:19.000Z',
        amountCents: 2000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '77798804-fb55-4f99-9902-b0c7a3aa8595',
        firstName: 'Patsy',
        lastName: 'Collier',
        email: 'patsy.collier@outlook.com',
        instagramProfile: {
          handle: 'collierp1988',
          followers: 196,
          engagementRate: 1.4285714285714286,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mrs.collier36',
          followers: 1855,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'patsybennett8060'
        }
      },
      userCampaignId: '77798804-fb55-4f99-9902-b0c7a3aa8595'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '728ecb8d-3c7f-47ed-a18a-dc86483c2fdf',
        firstName: 'Anna',
        lastName: 'Izotov',
        email: 'an.izotov09@gmail.com',
        instagramProfile: {
          handle: 'anuta.aaaa',
          followers: 630,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'annabondar2511',
          followers: 0
        }
      },
      userCampaignId: '728ecb8d-3c7f-47ed-a18a-dc86483c2fdf'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T23:45:13.000Z',
        amountCents: 2696
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '2c125673-8b48-44e3-92a2-1f39b7db0fbc',
        firstName: 'Tegan',
        lastName: 'Allen',
        email: 'teganraine2@gmail.com',
        instagramProfile: {
          handle: 'greengoddess1212',
          followers: 3,
          engagementRate: 66.66666666666666,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'greengoddess11',
          followers: 16824,
          engagementRate: 1.4775668679896463,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'teganallen5077'
        }
      },
      userCampaignId: '2c125673-8b48-44e3-92a2-1f39b7db0fbc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T08:02:53.000Z',
        amountCents: 2174
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e26e66c7-5ea1-49db-b00f-dd896857d42e',
        firstName: 'Haleina',
        lastName: 'Gibbs',
        email: 'halleandronal@gmail.com',
        instagramProfile: {
          handle: 'halles_hair_supplies',
          followers: 1490,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'halleberi',
          followers: 1393,
          isPrimary: false
        }
      },
      userCampaignId: 'e26e66c7-5ea1-49db-b00f-dd896857d42e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T09:25:40.000Z',
        amountCents: 2887
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0bdf34c2-ebc4-40a5-863b-c7d043a2e8e1',
        firstName: 'Aisha',
        lastName: 'Armstrong',
        email: 'armstra0816@gmail.com',
        instagramProfile: {
          handle: '_lifeasaisha',
          followers: 8438,
          engagementRate: 1.2751836928182034,
          isPrimary: true
        },
        tiktokProfile: {
          handle: '_lifeasaisha',
          followers: 404,
          engagementRate: 5.972434915773354,
          isPrimary: false
        }
      },
      userCampaignId: '0bdf34c2-ebc4-40a5-863b-c7d043a2e8e1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T11:13:52.000Z',
        amountCents: 2208
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '6b2d2fa7-9380-41af-a6fd-565713156aa3',
        firstName: 'Taylor ',
        lastName: 'Haynes',
        email: 'taylorbuxton1410@gmail.com',
        tiktokProfile: {
          handle: 'taylorhaynes1120',
          followers: 61864,
          engagementRate: 13.628208579434048,
          isPrimary: true
        }
      },
      userCampaignId: '6b2d2fa7-9380-41af-a6fd-565713156aa3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-03T16:13:00.000Z',
        amountCents: 2204
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '52ad9b22-e6d3-4073-9d4c-f727d2d433ca',
        firstName: 'Iman',
        lastName: 'Brown ',
        email: 'by.imanpaige@gmail.com',
        tiktokProfile: {
          handle: 'iman.paige',
          followers: 1083,
          engagementRate: 23.81259679917398,
          isPrimary: false
        }
      },
      userCampaignId: '52ad9b22-e6d3-4073-9d4c-f727d2d433ca'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T16:15:01.000Z',
        amountCents: 2162
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a80bbd07-54bd-456b-9448-8553b1c55c59',
        firstName: 'Angel',
        lastName: 'Byles',
        email: 'angelbyles@gmail.com',
        instagramProfile: {
          handle: 'creditwealthteam',
          followers: 24178,
          engagementRate: 0.0008271982794275789,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'mrsangelbyles',
          followers: 643,
          engagementRate: 0,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'gerisfinest'
        }
      },
      userCampaignId: 'a80bbd07-54bd-456b-9448-8553b1c55c59'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T06:51:58.000Z',
        amountCents: 2127
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c1a3ed64-0d53-461c-8f65-9ccba21f2029',
        firstName: 'Idalias',
        lastName: 'Palmer',
        email: 'idaliassocialinquiries@gmail.com',
        instagramProfile: {
          handle: 'idalias.m',
          followers: 2403,
          engagementRate: 3.7952559300873907,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'idali.ass',
          followers: 486,
          engagementRate: 9.074074074074074,
          isPrimary: false
        }
      },
      userCampaignId: 'c1a3ed64-0d53-461c-8f65-9ccba21f2029'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-03T17:33:03.000Z',
        amountCents: 2174
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '52aedf5a-eb1b-409a-840c-a03fb4a28dad',
        firstName: 'Alex',
        lastName: 'Hall',
        email: 'alextayhall@gmail.com',
        instagramProfile: {
          handle: 'althall',
          followers: 2097,
          engagementRate: 1.4592274678111588,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'althall',
          followers: 663,
          engagementRate: 13.158439802904113,
          isPrimary: true
        }
      },
      userCampaignId: '52aedf5a-eb1b-409a-840c-a03fb4a28dad'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T08:22:43.000Z',
        amountCents: 2836
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '1fa9d131-ff8f-4e04-b62d-099f4c7d98eb',
        firstName: 'Breanna',
        lastName: 'Tracey',
        email: 'breannatracey7@gmail.com',
        instagramProfile: {
          handle: '_.iambrii',
          followers: 3325,
          isPrimary: false
        },
        tiktokProfile: {
          handle: '_.iambriii',
          followers: 811,
          engagementRate: 14.893617021276595,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'lifewithchinie248',
          followers: 0
        }
      },
      userCampaignId: '1fa9d131-ff8f-4e04-b62d-099f4c7d98eb'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T07:36:05.000Z',
        amountCents: 2187
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '1e1b661b-ff65-4622-bd47-809f81714d6e',
        firstName: 'Jaz',
        lastName: 'Valencia',
        email: 'jazminevalencia@gmail.com',
        instagramProfile: {
          handle: 'djjazzy',
          followers: 12016,
          engagementRate: 1.9532290279627162,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'iamdjjazzy',
          followers: 45,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'jvagency',
          followers: 289
        }
      },
      userCampaignId: '1e1b661b-ff65-4622-bd47-809f81714d6e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T19:05:08.000Z',
        amountCents: 2162
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '617c1d37-efe3-44b6-8efc-f5a8d2c6ce44',
        firstName: 'Jonae',
        lastName: 'Richmond',
        email: 'jonaeniicole@gmail.com',
        instagramProfile: {
          handle: 'jonaeniicole',
          followers: 2149,
          engagementRate: 1.9637040483946024,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'jonaeniicole',
          followers: 194,
          engagementRate: 10.754281949934125,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'jonaeniicole',
          followers: 3
        }
      },
      userCampaignId: '617c1d37-efe3-44b6-8efc-f5a8d2c6ce44'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T16:24:03.000Z',
        amountCents: 2164
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0b3e9a7a-881e-4b15-a72d-100bbec8954a',
        firstName: 'Patricia',
        lastName: 'Dela Cruz',
        email: 'nursingwithp@gmail.com',
        tiktokProfile: {
          handle: 'nursing.with.p',
          followers: 1182,
          engagementRate: 20.053475935828878,
          isPrimary: false
        }
      },
      userCampaignId: '0b3e9a7a-881e-4b15-a72d-100bbec8954a'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0aa6d633-8ecb-4573-ad51-f7226817f138',
        firstName: 'Sowkarthika',
        lastName: 'Madhanagopalan',
        email: 'sowkarthi.madan@gmail.com',
        instagramProfile: {
          handle: 'its_sowkarthika',
          followers: 1345,
          engagementRate: 2.2379182156133828,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sowkarthikamadhan',
          followers: 1559,
          engagementRate: 0.410958904109589,
          isPrimary: true
        }
      },
      userCampaignId: '0aa6d633-8ecb-4573-ad51-f7226817f138'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-06T18:26:02.000Z',
        amountCents: 2905
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd3d785a8-9abd-4a14-9dde-d015d1f118e9',
        firstName: 'Stacey',
        lastName: 'Brenkus',
        email: 'brenkusvacations@gmail.com',
        instagramProfile: {
          handle: 'brenkus_vacation_styles',
          followers: 5324,
          engagementRate: 0.009316303531179565,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'brenkusvacationstyles',
          followers: 311,
          engagementRate: 1.0769230769230769,
          isPrimary: false
        }
      },
      userCampaignId: 'd3d785a8-9abd-4a14-9dde-d015d1f118e9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T21:23:06.000Z',
        amountCents: 2169
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '88531d66-4b53-4d4e-83e1-ecc25caf1d69',
        firstName: 'Stef',
        lastName: 'Anderson',
        email: 'stefanderson530@gmail.com',
        instagramProfile: {
          handle: 'sideofstef',
          followers: 11216,
          engagementRate: 12.355563480741797,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sideofstef',
          followers: 24949,
          engagementRate: 6.261044270772337,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'sideofstef',
          followers: 58
        }
      },
      userCampaignId: '88531d66-4b53-4d4e-83e1-ecc25caf1d69'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T16:53:20.000Z',
        amountCents: 2187
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '4f2cf5e4-e08a-4834-9f57-98cb0683ff53',
        firstName: 'Benjamin',
        lastName: 'Crismond',
        email: 'luckthreads@gmail.com',
        instagramProfile: {
          handle: 'luckthreads',
          followers: 8892,
          engagementRate: 0.07647323436797121,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'luck_threads',
          followers: 388,
          engagementRate: 3.484320557491289,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'luckthreads1901',
          followers: 56
        }
      },
      userCampaignId: '4f2cf5e4-e08a-4834-9f57-98cb0683ff53'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T19:25:52.000Z',
        amountCents: 2973
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '47e24ba2-3dc0-4f02-80ff-5ab280748c50',
        firstName: 'Alyssa',
        lastName: 'Wong',
        email: 'alyssa.lifestylee@gmail.com',
        instagramProfile: {
          handle: 'alyssa.lifestyle',
          followers: 4498,
          engagementRate: 3.7750111160515782,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'alohhaalyssa',
          followers: 148,
          engagementRate: 3.074866310160428,
          isPrimary: false
        }
      },
      userCampaignId: '47e24ba2-3dc0-4f02-80ff-5ab280748c50'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T18:53:36.000Z',
        amountCents: 2836
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '06899bcd-abc6-415f-b92b-75d30be5a15c',
        firstName: 'Vanasa',
        lastName: 'Palmer',
        email: 'vanasa.vp@gmail.com',
        tiktokProfile: {
          handle: 'nasavp',
          followers: 1017,
          engagementRate: 7.4324324324324325,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'vanasapalmer221',
          followers: 0
        }
      },
      userCampaignId: '06899bcd-abc6-415f-b92b-75d30be5a15c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T18:18:28.000Z',
        amountCents: 3900
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f3eaf606-f158-4995-aa15-2b120f6e4456',
        firstName: 'Denise',
        lastName: 'Frutos',
        email: 'denisefrutos88@gmail.com',
        instagramProfile: {
          handle: 'princessaofdarkness_',
          followers: 1030,
          engagementRate: 5.555555555555555,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'goodnightmoon__',
          followers: 453,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'denisefrutos',
          followers: 5
        }
      },
      userCampaignId: 'f3eaf606-f158-4995-aa15-2b120f6e4456'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-05T22:44:49.000Z',
        amountCents: 2117
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ca5a5a1f-fa44-4491-991b-d55788d191d6',
        firstName: 'Tara',
        lastName: 'Mary',
        email: 'beep08.acrylic@icloud.com',
        instagramProfile: {
          handle: 'product.reviews.by.tm',
          followers: 0,
          engagementRate: 0,
          isPrimary: false
        }
      },
      userCampaignId: 'ca5a5a1f-fa44-4491-991b-d55788d191d6'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '650850fe-bbbe-447f-b783-4e6db737ba42',
        firstName: 'Sonya',
        lastName: 'Nembhard ',
        email: 'tickinembhard@yahoo.com',
        tiktokProfile: {
          handle: 'justalittletick',
          followers: 186,
          isPrimary: false
        }
      },
      userCampaignId: '650850fe-bbbe-447f-b783-4e6db737ba42'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'aa910c91-c193-426e-b999-b13712d1f9c1',
        firstName: 'Patricia',
        lastName: 'Jackson',
        email: 'glamboss305@outlook.com',
        instagramProfile: {
          handle: 'curlyredtricia',
          followers: 940,
          engagementRate: 0.17021276595744683,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'glamboss786',
          followers: 159,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'triciajackson8503',
          followers: 1
        }
      },
      userCampaignId: 'aa910c91-c193-426e-b999-b13712d1f9c1'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '59e97a38-43c9-4d3c-b8ee-0e3082154df0',
        firstName: 'Isabella',
        lastName: 'Trigueros',
        email: 'bellathebeauty861@gmail.com',
        tiktokProfile: {
          handle: 'beautybybella4',
          followers: 199,
          engagementRate: 5.103148751357221,
          isPrimary: false
        }
      },
      userCampaignId: '59e97a38-43c9-4d3c-b8ee-0e3082154df0'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '584519e3-e0fd-4ada-86e4-eda09c99b7ba',
        firstName: 'Nicola',
        lastName: 'Ianeselli',
        email: 'nicola.ianeselli@gmail.com',
        instagramProfile: {
          handle: 'nicolaianeselli',
          followers: 4409,
          engagementRate: 0.5375368564300295,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'nicolaianeselli',
          followers: 30,
          isPrimary: false
        }
      },
      userCampaignId: '584519e3-e0fd-4ada-86e4-eda09c99b7ba'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-07T08:42:24.000Z',
        amountCents: 2194
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5810b0cf-9fe4-4746-a9f7-1b2bc8b96947',
        firstName: 'Deiby',
        lastName: 'Magana',
        email: 'deibymagana.collab@yahoo.com',
        instagramProfile: {
          handle: 'deibyxo',
          followers: 453,
          engagementRate: 1.8763796909492272,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'deibysdailyvlogs',
          followers: 4121,
          engagementRate: 15.882811705266015,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'vlogswdeiby'
        }
      },
      userCampaignId: '5810b0cf-9fe4-4746-a9f7-1b2bc8b96947'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T21:54:45.000Z',
        amountCents: 2162
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ce507c13-34ff-4020-a904-2e90b4d73472',
        firstName: 'Luyana',
        lastName: 'Franco',
        email: 'tunechiiii13@gmail.com',
        instagramProfile: {
          handle: 'luyanafranco',
          followers: 1452,
          engagementRate: 3.8774104683195585,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'luyanafranco',
          followers: 60,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'luyanafranco',
          followers: 28
        }
      },
      userCampaignId: 'ce507c13-34ff-4020-a904-2e90b4d73472'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-06T19:22:39.000Z',
        amountCents: 2124
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '72288c4f-52fc-45a4-866a-26ced9dec1be',
        firstName: 'Sophia',
        lastName: 'Ciano',
        email: 'caffeinated.chaossss@yahoo.com',
        instagramProfile: {
          handle: 'caffeinated.chaossss',
          followers: 16656,
          engagementRate: 6.720701248799232,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'caffeinated.chaossss',
          followers: 2013,
          engagementRate: 13.5702746365105,
          isPrimary: false
        }
      },
      userCampaignId: '72288c4f-52fc-45a4-866a-26ced9dec1be'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'cb3f5ec4-412e-4e67-bb95-afe880294322',
        firstName: 'Lacey',
        lastName: 'Lowe',
        email: 'lrm2010@gmail.com',
        instagramProfile: {
          handle: 'laceylowephotography',
          followers: 760,
          engagementRate: 4.7105263157894735,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'laceyraelowe',
          followers: 39,
          isPrimary: false
        }
      },
      userCampaignId: 'cb3f5ec4-412e-4e67-bb95-afe880294322'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-09T07:02:42.000Z',
        amountCents: 2164
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ef4e8225-dfc6-4c15-97c0-fc940dd20aba',
        firstName: 'Ana Luiza',
        lastName: 'Leme de Souza',
        email: 'analuizalemedesouza@gmail.com',
        instagramProfile: {
          handle: 'analu.leme',
          followers: 1479,
          engagementRate: 2.0689655172413794,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'internation.all',
          followers: 15,
          engagementRate: 0.4329004329004329,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'analuleme'
        }
      },
      userCampaignId: 'ef4e8225-dfc6-4c15-97c0-fc940dd20aba'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '669d8e1f-859d-4fd5-bdbd-ae5c04893d6e',
        firstName: 'Shi-Ann',
        lastName: 'Stephens',
        email: 'stephens.shiann18@yahoo.com',
        tiktokProfile: {
          handle: 'golden._.shii',
          followers: 666,
          engagementRate: 7.297605473204105,
          isPrimary: false
        }
      },
      userCampaignId: '669d8e1f-859d-4fd5-bdbd-ae5c04893d6e'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '26ebabfc-eba6-4f43-81fb-250203335103',
        firstName: 'Renea',
        lastName: 'Bennett',
        email: 'reneaabennett@gmail.com',
        instagramProfile: {
          handle: 'og.chrissyyy',
          followers: 3022,
          engagementRate: 17.835616438356166,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'poochie.looo',
          followers: 1002,
          engagementRate: 10.040160642570282,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'chrissyb.',
          followers: 151
        }
      },
      userCampaignId: '26ebabfc-eba6-4f43-81fb-250203335103'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '75f9de9e-a0f7-481f-9782-552c320d119e',
        firstName: 'Isabell',
        lastName: 'Robertson',
        email: 'isabellchastain@gmail.com',
        tiktokProfile: {
          handle: 'bell_1227',
          followers: 348,
          engagementRate: 2.0589414879766803,
          isPrimary: true
        }
      },
      userCampaignId: '75f9de9e-a0f7-481f-9782-552c320d119e'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '1dc74fe5-4074-4897-b989-9111aec04430',
        firstName: 'George',
        lastName: 'Lopez',
        email: 'glopez4521@gmail.com',
        tiktokProfile: {
          handle: 'gl0pez21',
          followers: 15,
          isPrimary: false
        }
      },
      userCampaignId: '1dc74fe5-4074-4897-b989-9111aec04430'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T07:02:53.000Z',
        amountCents: 2119
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '349ab4c6-1eb1-4d32-86c2-fe18624d343b',
        firstName: 'Jennifer',
        lastName: 'Evans',
        email: 'blondeducky@yahoo.com',
        instagramProfile: {
          handle: 'blonde_ducky',
          followers: 907,
          engagementRate: 0.5733186328555678,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'blondeducky',
          followers: 5,
          isPrimary: false
        }
      },
      userCampaignId: '349ab4c6-1eb1-4d32-86c2-fe18624d343b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T21:56:32.000Z',
        amountCents: 2937
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '135d79a5-e5ce-4190-95db-9b0b88f9a0d7',
        firstName: 'Rianna',
        lastName: 'Rosen',
        email: 'rianna.rosen@gmail.com',
        tiktokProfile: {
          handle: 'riro448',
          followers: 38,
          isPrimary: true
        }
      },
      userCampaignId: '135d79a5-e5ce-4190-95db-9b0b88f9a0d7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-06T20:25:28.000Z',
        amountCents: 3900
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '736e071a-98c0-4e29-9dff-e0220d166ac3',
        firstName: 'Laura',
        lastName: 'Marin',
        email: 'laschanas@outlook.com',
        tiktokProfile: {
          handle: 'sjiajsjaiaishsjja',
          followers: 1,
          isPrimary: false
        }
      },
      userCampaignId: '736e071a-98c0-4e29-9dff-e0220d166ac3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-07T02:12:28.000Z',
        amountCents: 2914
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '8dfb542a-431d-460a-88b5-9aa5502f45f7',
        firstName: 'Kasey',
        lastName: 'Smith',
        email: 'lazydazey95@aol.com',
        instagramProfile: {
          handle: 'nascarkasey_22',
          followers: 58,
          engagementRate: 2.2413793103448274,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kaseyallison',
          followers: 1792,
          engagementRate: 6.382978723404255,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'kaseyallison'
        }
      },
      userCampaignId: '8dfb542a-431d-460a-88b5-9aa5502f45f7'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'eb9b9e9a-eb6b-4a59-8672-37b9396581c4',
        firstName: 'Toniann ',
        lastName: 'Green ',
        email: 'tia.teegreen2@gmail.com',
        instagramProfile: {
          handle: 'tg_love_yourself',
          followers: 278,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'tonianngreen1',
          followers: 5,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'toni-anngreen5234',
          followers: 8
        }
      },
      userCampaignId: 'eb9b9e9a-eb6b-4a59-8672-37b9396581c4'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '75a4878c-d666-4761-9af4-701a5d4903e0',
        firstName: 'Moncelly ',
        lastName: 'Sanchez',
        email: 'moncellys@gmail.com',
        instagramProfile: {
          handle: 'moncelly_s',
          followers: 26,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'brconscleaning',
          followers: 1,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'moncellysanchez8962',
          followers: 0
        }
      },
      userCampaignId: '75a4878c-d666-4761-9af4-701a5d4903e0'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '67e9d625-c338-4fb5-9fa5-7fbc2b6b10d6',
        firstName: 'Kelsey',
        lastName: 'Kelchner',
        email: 'coupondogmom94@gmail.com',
        instagramProfile: {
          handle: 'coupondogmom94',
          followers: 817,
          engagementRate: 0.2692778457772338,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'coupondogmom',
          followers: 2914,
          engagementRate: 1.292517006802721,
          isPrimary: true
        }
      },
      userCampaignId: '67e9d625-c338-4fb5-9fa5-7fbc2b6b10d6'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '6b429165-2115-47b9-ad6e-f951d74f3b06',
        firstName: 'Serena',
        lastName: 'Molinaro',
        email: 'bookserenashells@gmail.com',
        instagramProfile: {
          handle: 'serena_the_realtor',
          followers: 2510,
          engagementRate: 1.2908366533864541,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'serenashells',
          followers: 6,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'serenashells'
        }
      },
      userCampaignId: '6b429165-2115-47b9-ad6e-f951d74f3b06'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c14cb488-e7e5-4972-ad0d-d74c0b9591ef',
        firstName: 'Kenita',
        lastName: 'Chenevert',
        email: 'kenita@milkdrops.org',
        instagramProfile: {
          handle: 'milkdropseverydropcounts',
          followers: 848,
          engagementRate: 2.05188679245283,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'milkdropseverydropcounts',
          followers: 4167,
          engagementRate: 10.909812894880657,
          isPrimary: false
        }
      },
      userCampaignId: 'c14cb488-e7e5-4972-ad0d-d74c0b9591ef'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T11:06:40.000Z',
        amountCents: 2162
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '7ef90d1e-3b4b-4fb5-ae0a-76a1fa25e2b8',
        firstName: 'Anna',
        lastName: 'Abshire',
        email: 'annaabshireyl@gmail.com',
        instagramProfile: {
          handle: 'aromayogamama',
          followers: 1815,
          engagementRate: 0.2865013774104683,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'annaabshirewithselfcareyoga'
        }
      },
      userCampaignId: '7ef90d1e-3b4b-4fb5-ae0a-76a1fa25e2b8'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fa7627f8-b8f9-4654-bf72-104a42185891',
        firstName: 'Kierstyn',
        lastName: 'Garcia',
        email: 'kiley.kierstyn@gmail.com',
        instagramProfile: {
          handle: 'kieerstyn',
          followers: 2529,
          engagementRate: 17.279557137208382,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kierstyngar',
          followers: 3308,
          engagementRate: 29.046624449425522,
          isPrimary: true
        }
      },
      userCampaignId: 'fa7627f8-b8f9-4654-bf72-104a42185891'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e1a7f55d-aff5-43b3-9cf9-2187a18408ba',
        firstName: 'Tetiana',
        lastName: 'Vushtei ',
        email: 'vushtei@gmail.com',
        instagramProfile: {
          handle: 'pinksale_',
          followers: 1414,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'vushtei',
          followers: 8,
          isPrimary: false
        }
      },
      userCampaignId: 'e1a7f55d-aff5-43b3-9cf9-2187a18408ba'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-07T23:33:09.000Z',
        amountCents: 2139
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '21d519c3-12be-4d7f-8fd1-b1f09077af1a',
        firstName: 'Jinni',
        lastName: 'Enterkin',
        email: 'jinnimarie89@gmail.com',
        instagramProfile: {
          handle: 'jinni_marie',
          followers: 460,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'jiinnii_marie89',
          followers: 10615,
          engagementRate: 2.5431568736639782,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'jinnimarie3893',
          followers: 0
        }
      },
      userCampaignId: '21d519c3-12be-4d7f-8fd1-b1f09077af1a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-07T20:24:56.000Z',
        amountCents: 2137
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'bff5704b-4a6c-4a07-89ae-1a3501f18cc1',
        firstName: 'Lydia',
        lastName: 'Wright',
        email: 'lydiawright266@gmail.com',
        tiktokProfile: {
          handle: 'lydiawright_24',
          followers: 4450,
          engagementRate: 14.928128120987303,
          isPrimary: false
        }
      },
      userCampaignId: 'bff5704b-4a6c-4a07-89ae-1a3501f18cc1'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'edb1652a-fd5f-436e-8a0f-3d444bccc7c8',
        firstName: 'George',
        lastName: 'Mossey',
        email: 'georgemossey@gmail.com',
        instagramProfile: {
          handle: 'georgemossey',
          followers: 14951,
          engagementRate: 0.28225536753394426,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'georgemossey',
          followers: 320,
          engagementRate: 5.263157894736842,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'georgemossey',
          followers: 386
        }
      },
      userCampaignId: 'edb1652a-fd5f-436e-8a0f-3d444bccc7c8'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '9e986a8a-2279-48ca-ada9-c18e288a20a2',
        firstName: 'Taylor',
        lastName: 'Hall',
        email: 'taylor.paige71591@gmail.com',
        instagramProfile: {
          handle: 'taylor_helping_hands_cleaning',
          followers: 226,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'tayhall101218',
          followers: 2545,
          isPrimary: false
        }
      },
      userCampaignId: '9e986a8a-2279-48ca-ada9-c18e288a20a2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T21:19:52.000Z',
        amountCents: 3900
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ba9a48a9-8438-41c9-aba0-e066a434b38e',
        firstName: 'Samantha',
        lastName: 'Ayala',
        email: 'killuaxsamantha@gmail.com',
        tiktokProfile: {
          handle: 'samayala48',
          followers: 0,
          isPrimary: true
        }
      },
      userCampaignId: 'ba9a48a9-8438-41c9-aba0-e066a434b38e'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'be5eefcb-a885-4dbe-9658-8528a0947a63',
        firstName: 'Lacee',
        lastName: 'Muller',
        email: 'lacee@abundantlywelleo.com',
        instagramProfile: {
          handle: 'lacemuller',
          followers: 30870,
          engagementRate: 0.6763848396501457,
          isPrimary: false
        }
      },
      userCampaignId: 'be5eefcb-a885-4dbe-9658-8528a0947a63'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-08T23:49:42.000Z',
        amountCents: 7900
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '10286744-c919-463d-a51d-82ed0d28058b',
        firstName: 'Allison',
        lastName: 'Devlin',
        email: 'allieblackstead@gmail.com',
        instagramProfile: {
          handle: 'allieareyouok4',
          followers: 69,
          engagementRate: 0.2898550724637681,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'allieblackstead',
          followers: 485,
          engagementRate: 0,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'allieblackstead'
        }
      },
      userCampaignId: '10286744-c919-463d-a51d-82ed0d28058b'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '07e1a564-56aa-4f46-87fd-262342904e43',
        firstName: 'Andrea',
        lastName: 'Cano',
        email: 'andreacee830@gmail.com',
        instagramProfile: {
          handle: 'mamiiidreaaa',
          followers: 15006,
          engagementRate: 1.8192722910835666,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'modernmexicancasita',
          followers: 26073,
          engagementRate: 4.408144307866004,
          isPrimary: false
        }
      },
      userCampaignId: '07e1a564-56aa-4f46-87fd-262342904e43'
    }
  ]
}

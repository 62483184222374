import { useFeedbackContext } from '@momentum/routes/campaign/e-commerce/feedback/feedbackContext'
import { Box, Divider, Stack } from '@mui/material'
import { FeedbackDoughnut } from './feedback-doughnut'
import { FeedbackTable } from './feedback-table'

export const FeedbackDataDisplay = () => {
  const { themes } = useFeedbackContext()

  return (
    <Stack
      direction="row"
      spacing={3}
      divider={<Divider orientation="vertical" flexItem />}
      alignItems={'stretch'}
      justifyContent={'stretch'}
    >
      <Box flex={1} display={'inline-flex'} justifyContent={'center'}>
        <FeedbackDoughnut data={themes} />
      </Box>
      <FeedbackTable data={themes} />
    </Stack>
  )
}

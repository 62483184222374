import { ROUTES, ROUTE_NAMES_CAMPAIGN } from '@momentum/routes/RouteNames'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { AutoAwesomeOutlined, Checklist, PhotoLibraryOutlined, TrendingUpOutlined } from '@mui/icons-material'
import { Avatar, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { generatePath, useMatch } from 'react-router-dom'

export const useCampaignSubNavDetails = (brandId: string, campaignId: string, skuId: string) => {
  const { contentPendingApprovalCount, creatorsPendingApprovalCount, needsSellerIntegration, needsVendorIntegration } =
    useCampaignContext()

  const overviewMatch = !!useMatch([ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.OVERVIEW, '*'].join('/'))
  const ecommerceMatch = !!useMatch([ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.ECOMMERCE, '*'].join('/'))
  const contentMatch = !!useMatch([ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.CONTENT].join('/'))
  const myActionsMatch = !!useMatch([ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.MY_ACTIONS, '*'].join('/'))

  const actionsCount =
    contentPendingApprovalCount +
    (creatorsPendingApprovalCount || 0) +
    ~~needsSellerIntegration +
    ~~needsVendorIntegration

  return [
    {
      id: 'overview',
      textLabel: 'Overview',
      label: 'Overview',
      to: generatePath([ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.OVERVIEW].join('/'), {
        brandId,
        campaignId
      }),
      icon: AutoAwesomeOutlined,
      selected: overviewMatch
    },
    {
      id: 'myActions',
      textLabel: 'My actions',
      label: (
        <Stack direction={'row'} spacing={0.5}>
          <Typography variant="inherit">My actions</Typography>
          {!!actionsCount && actionsCount > 0 && (
            <Avatar sx={{ ml: 0.5, height: 18, width: 18, bgcolor: theme => theme.palette.primary.main }}>
              <Typography variant={'caption2'}>{actionsCount}</Typography>
            </Avatar>
          )}
        </Stack>
      ),
      to: generatePath([ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.MY_ACTIONS].join('/'), {
        brandId,
        campaignId
      }),
      icon: Checklist,
      selected: myActionsMatch
    },
    {
      id: 'ecommerce',
      textLabel: 'E-commerce',
      label: 'E-commerce',
      to: generatePath([ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.ECOMMERCE].join('/'), {
        brandId,
        campaignId
      }),
      icon: TrendingUpOutlined,
      selected: ecommerceMatch
    },
    {
      id: 'content',
      textLabel: 'Content',
      label: 'Content',
      to: generatePath([ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.CONTENT].join('/'), {
        brandId,
        campaignId
      }),
      icon: PhotoLibraryOutlined,
      selected: contentMatch
    }
  ]
}

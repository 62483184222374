import { Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Role } from '@productwindtom/shared-momentum-zeus-types'
import { capitalize, words } from 'lodash'
import { BaseBrand, BaseCompany } from '../queries'

export enum UserRowType {
  INVITATION,
  USER
}

export type UserRow = {
  id: string
  type: UserRowType
  email: string
  fullName: string
  brands?: BaseBrand[]
  companies?: BaseCompany[]
  primaryBrand?: string
  primaryCompany?: string
  role?: Role
  lastActiveAt?: string
}

export const UserTableColumns: GridColDef<UserRow>[] = [
  {
    field: 'fullName',
    headerName: 'Name',
    flex: 1.5,
    renderCell: ({ row }) => (
      <Stack>
        <Typography variant={'label3'} color={'primary'}>
          {row.fullName}
        </Typography>
        <Typography variant={'label3'}>{row.email}</Typography>
      </Stack>
    )
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    valueFormatter: value => words(value).map(capitalize).join(' ')
  },
  {
    field: 'primaryBrand',
    headerName: 'Primary brand',
    flex: 1
  },
  {
    field: 'primaryCompany',
    headerName: 'Primary company',
    flex: 1
  },
  {
    field: 'lastActiveAt',
    headerName: 'Last Active',
    flex: 1,
    renderCell: ({ row, value }) =>
      row.type === UserRowType.INVITATION ? (
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          Pending Invite
        </Typography>
      ) : value ? (
        new Date(value).toLocaleDateString()
      ) : (
        ''
      )
  }
]

//@ts-nocheck
import {
  ModelTypes,
  ProductCreationSource,
  Store,
  ListingFulfillmentType,
  RecommendationType
} from '@productwindtom/shared-momentum-zeus-types'
import { BRANDS } from '../../types'
import { keyBy } from 'lodash'

export const SAMPLE_PRODUCTS_BY_BRAND_ID: Record<string, ModelTypes['ModelSearchItemssearchBrandProducts']> = {
  [BRANDS.CPG]: {
    items: [
      {
        id: 'sample-sku-laundry',
        brandId: BRANDS.CPG,
        name: 'Laundry Detergent',
        skuId: 'B0C8RD2XHD',
        price: '$14.98',
        priceCents: 1498,
        image: 'https://media.productwind.com/sample/images/laundry.png',
        listingLink: 'https://www.amazon.com/Arm-Hammer-Laundry-Detergent-Packaging/dp/B0C8RD2XHD',
        store: Store.amazon,
        ratingSnapshots: {
          items: []
        },
        categories: [
          'Health, Household & Baby Care',
          'Health & Household',
          'Household Supplies',
          'Laundry',
          'Laundry Detergent',
          'Liquid Detergent'
        ],
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        recommendations: [],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION
      },
      {
        id: 'sample-sku-marker',
        name: 'Writing Marker',
        skuId: 'B0CPMFV2ZF',
        price: '$33.48',
        priceCents: 3348,
        image: 'https://media.productwind.com/sample/images/marker.png',
        listingLink: 'https://www.amazon.com/SHARPIE-Creative-Markers-Water-Based-Assorted/dp/B0CPMFV2ZF',
        store: Store.amazon,
        ratingSnapshots: {
          items: []
        },
        brandId: BRANDS.CE,
        recommendations: [],
        createdAt: '2024-01-13T16:22:12.000Z',
        updatedAt: '2024-01-13T16:22:12.000Z',
        availabilityDate: '2023-12-06T00:00:00.000Z'
      },
      {
        id: 'da5aa3fa-3276-4f26-bd88-ca571bea4ec8',
        skuId: 'B0CPQPMQW6',
        name: 'Arm & Hammer Deodorizing Cat Litter Crystals for Litter Boxes | Calming Odor Neutralizing Cat Litter Odor Eliminator in Lavender & Vanilla Scent | Litter Box Odor Eliminator, 15 Oz Calming 15 Ounce (Pack of 1)',
        price: '$5.62',
        priceCents: 562,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/da5aa3fa-3276-4f26-bd88-ca571bea4ec8/images/1706836479081',
        availabilityDate: '2023-12-07T00:00:00.000Z',
        categories: ['Pet Supplies', 'Cats', 'Litter & Housebreaking', 'Litter'],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.amazon.com/stores/page/EF600F7F-419B-43DE-93D2-E5BA7B742402/search?ref_=ast_bln&terms=*',
        listingLink: 'https://www.amazon.com/Arm-Hammer-Deodorizing-Litter-Crystals/dp/B0CPQPMQW6',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:01:53.398Z',
        ratingSummary: {
          rating: 0,
          numRatings: 0,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-02-21T20:09:48.694Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-03-14T00:53:03.596Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '3f56bb1e-24f1-4d98-8b65-92c311e1a54b',
        skuId: 'B09R2B7BCN',
        name: 'Arm & Hammer for Pets Nubbies Dental Treats for Dogs | Dental Chews Fight Bad Breath, Plaque & Tartar Without Brushing | Chicken Flavor, 20 Count- 24 Pack Dental Dog Chews Chicken 20 Count (Pack of 24)',
        price: '$139.99',
        priceCents: 13999,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/3f56bb1e-24f1-4d98-8b65-92c311e1a54b/images/1706904617286',
        availabilityDate: '2022-01-24T00:00:00.000Z',
        categories: ['Pet Supplies', 'Dogs', 'Treats', 'Cookies, Biscuits & Snacks'],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.amazon.com/stores/page/EF600F7F-419B-43DE-93D2-E5BA7B742402/search?ref_=ast_bln&terms=*',
        listingLink: 'https://www.amazon.com/Arm-Hammer-Nubbies-Without-Brushing/dp/B09R2B7BCN',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:02:10.478Z',
        ratingSummary: {
          rating: 4.4,
          numRatings: 47,
          numReviews: 5
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-04-29T13:00:40.369Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-29T13:00:40.367Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '9109193c-d76c-439e-9bf5-88ebc7638774',
        skuId: 'B0C7Q88PK5',
        name: 'Arm & Hammer Odor Busterz Closet Mate, 4 Count Deodorizer & Odor Busterz Deodorizer Collection, 8 Count',
        price: '$28.73',
        priceCents: 2873,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/9109193c-d76c-439e-9bf5-88ebc7638774/images/1706904792540',
        availabilityDate: '2023-06-12T00:00:00.000Z',
        categories: [
          'Tools & Home Improvement',
          'Health & Household',
          'Household Supplies',
          'Household Cleaning',
          'Carpet Deodorizers'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.amazon.com/stores/page/EF600F7F-419B-43DE-93D2-E5BA7B742402/search?ref_=ast_bln&terms=*',
        listingLink: 'https://www.amazon.com/Arm-Hammer-Busterz-Deodorizer-Collection/dp/B0C7Q88PK5',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:01:55.815Z',
        ratingSummary: {
          rating: 0,
          numRatings: 0,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-02-02T21:10:19.712Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-02-02T21:10:19.713Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '044556dd-1ff3-4e77-92fc-1ae9bdf35521',
        skuId: 'B0BB2WVBJ8',
        name: 'Arm & Hammer Light In-Wash Scent Booster Lavender Escape 18OZ',
        price: '$8.13',
        priceCents: 813,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1709565716755',
        availabilityDate: '2022-08-18T00:00:00.000Z',
        categories: [
          'Health, Household & Baby Care',
          'Health & Household',
          'Household Supplies',
          'Laundry',
          'Laundry Detergent',
          'Liquid Detergent'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.amazon.com/stores/page/EF600F7F-419B-43DE-93D2-E5BA7B742402/search?ref_=ast_bln&terms=*',
        listingLink: 'https://www.amazon.com/Arm-Hammer-Freshness-Booster-Lavender/dp/B0BB2WVBJ8',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:01:39.750Z',
        ratingSummary: {
          rating: 4.7,
          numRatings: 15,
          numReviews: 4
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-03-04T18:19:20.275Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '70a025ff-2a77-41e1-99a8-d142d3ea4f0c',
        skuId: 'B09RLW1KG6',
        name: 'Arm & Hammer for Dogs Training Pads for Stay-at-Home Dogs | New & Improved Super Absorbent, Leak-Proof, Odor Control Quilted Dog Training Pads with Baking Soda | 100 Count - 2 Pack Wee Wee Pads 100 Count (Pack of 2) White',
        price: '$63.98',
        priceCents: 6398,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/70a025ff-2a77-41e1-99a8-d142d3ea4f0c/images/1706836744340',
        availabilityDate: null,
        categories: [
          'Pet Supplies',
          'Dogs',
          'Litter & Housebreaking',
          'Training Pads & Trays',
          'Disposable Training Pads'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.amazon.com/stores/page/EF600F7F-419B-43DE-93D2-E5BA7B742402/search?ref_=ast_bln&terms=*',
        listingLink: 'https://www.amazon.com/Arm-Hammer-Stay-at-Home-Absorbent-Leak-Proof/dp/B09RLW1KG6',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:01:57.242Z',
        ratingSummary: {
          rating: 4.7,
          numRatings: 10,
          numReviews: 2
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-02-02T23:42:13.459Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '1705723f-6cd7-48f7-a92b-f31720eae9a7',
        skuId: 'B005NZ4MII',
        name: 'Arm & Hammer Super Washing Soda Detergent Booster & Household Cleaner, 55oz. Unscented  3 Pound (Pack of 1)',
        price: '$15.49',
        priceCents: 1549,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1707052732988',
        availabilityDate: '2008-11-11T00:00:00.000Z',
        categories: [
          'Health, Household & Baby Care',
          'Health & Household',
          'Household Supplies',
          'Laundry',
          'Laundry Detergent',
          'Powder Detergent'
        ],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Arm-Hammer-Super-Washing-Fluid/dp/B005NZ4MII',
        listingFulfillmentType: ListingFulfillmentType.SELLER,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:02:02.331Z',
        ratingSummary: {
          rating: 4.4,
          numRatings: 15,
          numReviews: 11
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-15T13:02:04.196Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '9657377c-a037-4d86-b0ea-c77d1ce15486',
        skuId: 'B08654D49X',
        name: 'Brita Total 360 BRDROS Undersink Reverse Osmosis Water Filtration System with Brushed Nickel Faucet, Blue',
        price: '$259.00',
        priceCents: 25900,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1711486005920',
        availabilityDate: '2020-03-19T00:00:00.000Z',
        categories: [
          'Tools & Home Improvement',
          'Kitchen & Bath Fixtures',
          'Water Filtration & Softeners',
          'Under-Sink & Countertop Filtration'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.amazon.com/stores/BritaTotal360/page/43C1506F-7779-41E5-A09E-CCF37930E90C?ref_=ast_bln&store_ref=bl_ast_dp_brandLogo_sto',
        listingLink: 'https://www.amazon.com/Brita-BRDROS-Undersink-Reverse-Filtration/dp/B08654D49X',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:28:48.679Z',
        ratingSummary: {
          rating: 3.7,
          numRatings: 53,
          numReviews: 19
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-03-26T20:46:49.587Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: 'c6a0b29d-dcd2-45a4-ab18-4437cfb1c8a6',
        skuId: 'B08654TRDL',
        name: 'Brita Total 360 BRDROF Reverse Osmosis Water Filter Replacement, Regular, White',
        price: '$78.61',
        priceCents: 7861,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1711486007948',
        availabilityDate: '2020-03-19T00:00:00.000Z',
        categories: [
          'Tools & Home Improvement',
          'Kitchen & Bath Fixtures',
          'Water Filtration & Softeners',
          'Replacement Water Filters',
          'Replacement Under-Sink Water Filters'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.amazon.com/stores/BritaTotal360/page/43C1506F-7779-41E5-A09E-CCF37930E90C?ref_=ast_bln&store_ref=bl_ast_dp_brandLogo_sto',
        listingLink: 'https://www.amazon.com/Brita-BRDROF-Undersink-Filter-Replacement/dp/B08654TRDL',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:28:45.853Z',
        ratingSummary: {
          rating: 3.7,
          numRatings: 65,
          numReviews: 19
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-04-15T13:04:18.239Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '068dc404-f9ab-4211-916e-200219cd3e71',
        skuId: 'B0CPTGLBNQ',
        name: 'Brita Refillable Water Filtration System',
        price: '$43.89',
        priceCents: 4389,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/7e1cc586-4bde-46a1-b38c-e336a6f70df4/images/1711486234701',
        availabilityDate: '2023-12-09T00:00:00.000Z',
        categories: [
          'Home & Kitchen',
          'Tools & Home Improvement',
          'Kitchen & Bath Fixtures',
          'Water Filtration & Softeners',
          'Replacement Water Filters',
          'Replacement Pitcher Water Filters'
        ],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Brita-Refillable-Filtration-Pitcher-Everyday/dp/B0CPTGLBNQ',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:28:45.926Z',
        ratingSummary: {
          rating: 4.5,
          numRatings: 30,
          numReviews: 26
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-15T13:04:18.196Z'
          }
        ],

        initialEtailerProductMetric: undefined
      },
      {
        id: '457e4580-0c4b-4d83-a219-bc791ca7dd70',
        skuId: 'B0952MRMRZ',
        name: 'Brita Total 360 BRDROF Reverse Osmosis Water Filter Replacement, Regular, White',
        price: '$78.61',
        priceCents: 7861,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1711485996544',
        availabilityDate: '2020-03-19T00:00:00.000Z',
        categories: [
          'Tools & Home Improvement',
          'Kitchen & Bath Fixtures',
          'Water Filtration & Softeners',
          'Replacement Water Filters',
          'Replacement Under-Sink Water Filters'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.amazon.com/stores/BritaTotal360/page/43C1506F-7779-41E5-A09E-CCF37930E90C?ref_=ast_bln&store_ref=bl_ast_dp_brandLogo_sto',
        listingLink: 'https://www.amazon.com/Brita-BRDROF-Undersink-Filter-Replacement/dp/B08654TRDL',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:28:55.994Z',
        ratingSummary: {
          rating: 3.7,
          numRatings: 33,
          numReviews: 19
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-04-15T13:04:18.003Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-15T13:04:18.041Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: 'c758d214-bc2f-4584-a4da-4f14ec71ed7f',
        skuId: 'B08DJ86XJY',
        name: 'BuiltGO 3 in 1 Energy and Protein Gel Shot - All In One Energy Drink + Pre-Workout + Protein Shake - On The Go Fuel For Your Body - (Peanut Butter Honey) (18 Pack)',
        price: '$0.00',
        priceCents: 0,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/c758d214-bc2f-4584-a4da-4f14ec71ed7f/images/1714201338261',
        availabilityDate: '2020-07-23T15:46:34.519Z',
        categories: ['Grocery'],
        creationSource: ProductCreationSource.SELLER_INTEGRATION,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/BuiltGO-Energy-Protein-Gel-Shot/dp/B08DJ86XJY',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T11:55:10.613Z',
        ratingSummary: {
          rating: 4.2,
          numRatings: 10,
          numReviews: 2
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-29T13:15:03.925Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '02117212-845b-40f6-8cd5-fba6dd583133',
        skuId: '1178468973',
        name: 'Built Bar 12 Pack High Protein and Energy Bars - Low Carb, Low Calorie, Low Sugar - Covered in 100% Real Chocolate - Delicious, Healthy Snack - Gluten Free (Mint Brownie)',
        price: '$44.83',
        priceCents: 4483,
        store: Store.walmart,

        image: 'https://media.productwind.com/products/1178468973/images/1716400256160',
        availabilityDate: null,
        categories: null,
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.walmart.com/search?q=built+bar&typeahead=built&facet=brand%3ABuilt+Bar%7C%7Cbrand%3ABUILT',
        listingLink:
          'https://www.walmart.com/ip/Built-Bar-12-Pack-High-Protein-Energy-Bars-Low-Carb-Calorie-Sugar-Covered-100-Real-Chocolate-Delicious-Healthy-Snack-Gluten-Free-Mint-Brownie/1178468973',
        listingFulfillmentType: null,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-22T17:50:56.159Z',
        ratingSummary: {
          rating: null,
          numRatings: 0,
          numReviews: null
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-05-22T17:50:56.789Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-05-22T17:50:56.792Z'
          }
        ],

        initialEtailerProductMetric: undefined
      },
      {
        id: '5fc34222-c358-4f3b-b95b-1912bd0fb8e8',
        skuId: '2645673491',
        name: 'Built Bar - Protein Bar Salted Caramel - Case Of 12-49 Grm',
        price: '$44.83',
        priceCents: 4483,
        store: Store.walmart,

        image: 'https://media.productwind.com/products/2645673491/images/1716400451217',
        availabilityDate: null,
        categories: null,
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.walmart.com/search?q=built+bar&typeahead=built&facet=brand%3ABuilt+Bar%7C%7Cbrand%3ABUILT',
        listingLink: 'https://www.walmart.com/ip/Built-Bar-Protein-Bar-Salted-Caramel-Case-Of-12-49-Grm/2645673491',
        listingFulfillmentType: null,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-22T17:54:11.217Z',
        ratingSummary: {
          rating: 5,
          numRatings: 1,
          numReviews: 1
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-05-22T17:54:12.063Z'
          }
        ],

        initialEtailerProductMetric: undefined
      },

      {
        id: '571edc3e-90ce-4135-b551-f9320a8cfd37',
        skuId: '5077678027',
        name: 'Built Bar Built Collagen Peptides Powder, Double Chocolate, 16 Oz',
        price: '$23.99',
        priceCents: 2399,
        store: Store.walmart,

        image: 'https://media.productwind.com/products/5077678027/images/1716400321493',
        availabilityDate: null,
        categories: null,
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.walmart.com/search?q=built+bar&typeahead=built&facet=brand%3ABuilt+Bar%7C%7Cbrand%3ABUILT',
        listingLink: 'https://www.walmart.com/ip/Built-Collagen-Peptides-Powder-Double-Chocolate-16-Oz/5077678027',
        listingFulfillmentType: null,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-22T17:52:01.493Z',
        ratingSummary: {
          rating: null,
          numRatings: 0,
          numReviews: null
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-05-22T17:52:02.038Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-05-22T17:52:02.022Z'
          }
        ],

        initialEtailerProductMetric: undefined
      },
      {
        id: '300ddced-4e1c-4f5b-a959-e5beda5f1194',
        skuId: '5618960680',
        name: 'Pack of 2 Built Bar Puff Protein Bar, Collagen, Gluten Free, Brownie Batter, 1.41oz Bars, 4 Count Box',
        price: null,
        store: Store.walmart,

        image: 'https://media.productwind.com/products/5618960680/images/1716400560694',
        availabilityDate: null,
        categories: null,
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.walmart.com/search?q=built+bar&typeahead=built&facet=brand%3ABuilt+Bar%7C%7Cbrand%3ABUILT',
        listingLink:
          'https://www.walmart.com/ip/Pack-of-2-Built-Bar-Puff-Protein-Bar-Collagen-Gluten-Free-Brownie-Batter-1-41oz-Bars-4-Count-Box/5618960680',
        listingFulfillmentType: null,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-22T17:56:00.694Z',
        ratingSummary: {
          rating: null,
          numRatings: 0,
          numReviews: null
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-05-22T17:56:01.219Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-05-22T17:56:01.236Z'
          }
        ],

        initialEtailerProductMetric: undefined
      },
      {
        id: 'ab2d154a-dc50-4dfd-9478-dfc22b8059e5',
        skuId: '2653569575',
        name: 'BUILT Bars Protein Bar, Gluten Free, Coconut Marshmallow Low Sugar & Carb Snack, 1.41 oz Bars, 1 Count',
        price: '$13.20',
        priceCents: 1320,
        store: Store.walmart,

        image: 'https://media.productwind.com/products/2653569575/images/1716400492208',
        availabilityDate: null,
        categories: null,
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl:
          'https://www.walmart.com/search?q=built+bar&typeahead=built&facet=brand%3ABuilt+Bar%7C%7Cbrand%3ABUILT',
        listingLink:
          'https://www.walmart.com/ip/BUILT-Bars-Protein-Bar-Gluten-Free-Coconut-Marshmallow-Low-Sugar-Carb-Snack-1-41-oz-Bars-1-Count/2653569575',
        listingFulfillmentType: null,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-22T17:54:52.208Z',
        ratingSummary: {
          rating: 5,
          numRatings: 1,
          numReviews: 1
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-05-22T17:54:52.912Z'
          }
        ],

        initialEtailerProductMetric: undefined
      }
    ],
    total: 18
  },
  [BRANDS.CE]: {
    items: [
      {
        id: 'sample-sku-headphones',
        name: 'Wireless Headphones',
        skuId: 'B0CKDFM5J2',
        price: '$243.99',
        priceCents: 24399,
        image: 'https://media.productwind.com/sample/images/headphones.png',
        listingLink: 'https://www.amazon.com/Panasonic-SoundSlayer-Lightweight-Microphone-Dimensional/dp/B0CKDFM5J2',
        store: Store.amazon,
        availabilityDate: '2023-10-01T00:00:00.000Z',
        brandId: BRANDS.CE,
        ratingSnapshots: {
          items: []
        },
        categories: [
          'Health, Household & Baby Care',
          'Health & Household',
          'Household Supplies',
          'Laundry',
          'Laundry Detergent',
          'Liquid Detergent'
        ],
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        recommendations: [],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION
      },
      {
        name: 'Keyboard and Mouse',
        price: '$49.99',
        priceCents: 4999,
        image: 'https://media.productwind.com/sample/images/keyboard.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/Logitech-Wireless-Customizable-Bluetooth-Easy-Switch/dp/B0BT4G499X',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CPG,
        id: 'sample-sku-keyboard',
        ratingSnapshots: {
          items: []
        },
        recommendations: []
      },
      {
        id: '7582675b-5795-414d-8dfc-0e8e257013c2',
        skuId: 'B0CT4MLLSQ',
        name: 'Logitech Signature Slim MK955 for Business Wireless Keyboard and Mouse Combo, Quiet Typing, Secure Receiver, Bluetooth, Globally Certified, Windows/Mac/Chrome/Linux - Graphite',
        price: '$99.99',
        priceCents: 9999,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/7582675b-5795-414d-8dfc-0e8e257013c2/images/1711920114167',
        availabilityDate: '2024-03-27T07:00:01Z',
        categories: ['Personal Computer'],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Logitech-Signature-Business-Bluetooth-Certified/dp/B0CT4MLLSQ',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-13T12:44:43.673Z',
        ratingSummary: {
          rating: null,
          numRatings: null,
          numReviews: null
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-04-08T13:11:25.845Z'
          }
        ],

        initialEtailerProductMetric: {
          date: '2024-03-17'
        }
      },
      {
        id: '1f24b43a-1315-4a20-8a6d-1cb859bd3355',
        skuId: 'B0CZ156MYG',
        name: 'Logitech Se Usb Pc Tactile keyboards',
        price: '$79.99',
        priceCents: 7999,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/1f24b43a-1315-4a20-8a6d-1cb859bd3355/images/1711920110838',
        availabilityDate: '2024-03-25T00:00:00.000Z',
        categories: [
          'Electronics',
          'Computers & Accessories',
          'Computer Accessories & Peripherals',
          'Keyboards, Mice & Accessories',
          'Keyboards'
        ],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Logitech-Se-Usb-Tactile-keyboards/dp/B0CZ156MYG',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:48:56.704Z',
        ratingSummary: {
          rating: 0,
          numRatings: 0,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-04-29T13:07:35.213Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-29T13:07:35.211Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '09452ac3-9a59-4e61-a9db-9164cca2a1c0',
        skuId: 'B0CJTHXTVN',
        name: 'Logitech M340 Wireless Mouse with Limited Edition Prints, USB Receiver and Silent Clicks, Portable Wireless Mouse for Laptop, PC, Windows, Chrome, Surface – Blue Floral',
        price: '$19.99',
        priceCents: 1999,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/09452ac3-9a59-4e61-a9db-9164cca2a1c0/images/1715929617141',
        availabilityDate: '2024-06-12T07:00:01-00:00',
        categories: ['BISS Basic'],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/dp/B0CJTHXTVN',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:48:44.065Z',
        ratingSummary: {
          rating: null,
          numRatings: null,
          numReviews: null
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-05-20T14:11:17.182Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-05-20T14:11:17.170Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '30f60a69-3c75-4aca-a4f1-d777fd2d18a8',
        skuId: 'B0CSLX3S9S',
        name: 'Logitech G Litra Beam LX Premium Dual-Sided RGB LED Streaming Key Light + Yeti G Studio Active Dynami XLR Broadcast Micropone Internal Shockmount Desktop Gaming light-(Light + Black Microphone) RGB',
        price: '$499.98',
        priceCents: 49998,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1710761147762',
        availabilityDate: '2024-01-17T00:00:00.000Z',
        categories: [
          'Electronics',
          'Computers & Accessories',
          'Computer Components',
          'External Components',
          'Computer Cases'
        ],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Logitech-Dual-Sided-Streaming-Broadcast-Shockmount/dp/B0CSLX3S9S',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:48:47.150Z',
        ratingSummary: {
          rating: 0,
          numRatings: 0,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-04-08T13:11:25.899Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-08T13:11:25.888Z'
          }
        ],
        initialEtailerProductMetric: {
          date: '2024-01-10'
        }
      },
      {
        id: '2420207a-d6ff-46e7-96a1-a60c067bf35d',
        skuId: 'B099DTFXHZ',
        name: 'Logitech Combo Touch iPad Pro 11" (1st, 2nd, and 3rd Generation) Keyboard Case - Detachable Backlit Keyboard with Kickstand, Click-Anywhere Trackpad, Smart Connector - Oxford Gray Apple Exclusive',
        price: '$199.99',
        priceCents: 19999,
        store: Store.amazon,

        image: null,
        availabilityDate: '2025-12-31T08:00:01.000Z',
        categories: ['Personal Computer'],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/dp/B099DTFXHZ',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:48:36.429Z',
        ratingSummary: {
          rating: null,
          numRatings: null,
          numReviews: null
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-04-22T12:27:12.444Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-22T12:27:12.471Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: 'd10a13a1-95eb-4892-9c33-bdaa5d785a6f',
        skuId: 'B0C3GGZ8HV',
        name: 'Logitech M240 for Business Silent Wireless Mouse, Secure Logi Bolt USB Receiver, Bluetooth, Globally Certified for Windows, Mac, Chrome, Linux, iPadOS, Android - Graphite',
        price: '$21.99',
        priceCents: 2199,
        store: Store.amazon,
        image: 'https://media.productwind.com/products/d10a13a1-95eb-4892-9c33-bdaa5d785a6f/images/1710814692662',
        availabilityDate: '2023-12-01T08:00:00-00:00',
        categories: ['Personal Computer'],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Logitech-Business-Wireless-Bluetooth-Certified/dp/B0C3GGZ8HV',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:51:57.549Z',
        ratingSummary: {
          rating: 4,
          numRatings: 1,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-08T13:12:21.187Z'
          }
        ],

        initialEtailerProductMetric: {
          date: '2023-09-21'
        }
      },
      {
        id: 'b71ceb54-bad7-4541-91f9-26068769721e',
        skuId: 'B08DR2M7QL',
        name: 'Logitech C505e HD Business Webcam - 720p HD External USB Camera for Desktop Or Laptop with Long-Range Microphone, Compatible with PC Or Mac - Grey',
        price: '$54.99',
        priceCents: 5499,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/b71ceb54-bad7-4541-91f9-26068769721e/images/1706837522676',
        availabilityDate: '2022-06-05T07:00:00-00:00',
        categories: ['Personal Computer'],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION,
        creationSourceUrl:
          'https://www.amazon.com/stores/page/01EFDE77-7D1A-41D7-9EEA-7C2FA22AB12F/search?_encoding=UTF8&store_ref=SB_A07697113GUA751AAHODY&pd_rd_plhdr=t&aaxitk=77abe2ee9de258e2f73dad63c1b98339&hsa_cr_id=0&lp_asins=B079JLY5M5%2CB079HR85C3%2CB0BKVY4WKT&lp_query=logitech&lp_slot=auto-sparkle-hsa-tetris&ref_=sbx_be_s_sparkle_lsi4d_cta&pd_rd_w=kJtlb&content-id=amzn1.sym.417820b0-80f2-4084-adb3-fb612550f30b%3Aamzn1.sym.417820b0-80f2-4084-adb3-fb612550f30b&pf_rd_p=417820b0-80f2-4084-adb3-fb612550f30b&pf_rd_r=G51DKDCFPCH13245HTXA&pd_rd_wg=Qc0h6&pd_rd_r=ce2a31ad-ea67-4ac4-b73d-f3ac734213b3&terms=*',
        listingLink: 'https://www.amazon.com/Logitech-C505e-Business-Webcam-Long-Range/dp/B08DR2M7QL',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:51:55.561Z',
        ratingSummary: {
          rating: 3.6,
          numRatings: 24,
          numReviews: 7
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-02-21T20:11:29.972Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-02-02T23:43:04.885Z'
          }
        ],
        initialEtailerProductMetric: {
          date: '2022-03-09'
        }
      },
      {
        id: 'bf3c965c-27a0-40e2-9209-eba4ac4c5dca',
        skuId: 'B0BSBV4QG5',
        name: 'Logitech G Yeti Studio Active Dynamic XLR Broadcast Microphone for Gaming, ClearAmp Active Preamp, Dual-Diaphragm Capsule, Internal Shockmount - Black',
        price: '$349.99',
        priceCents: 34999,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1712575845291',
        availabilityDate: '2024-01-16T00:00:00.000Z',
        categories: ['Electronics', 'Musical Instruments', 'Microphones & Accessories', 'Accessories', 'Preamps'],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Logitech-Broadcast-Microphone-Dual-Diaphragm-Shockmount/dp/B0BSBV4QG5',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:52:11.038Z',
        ratingSummary: {
          rating: 5,
          numRatings: 2,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-08T13:12:21.009Z'
          }
        ],
        initialEtailerProductMetric: {
          date: '2024-03-26'
        }
      },
      {
        id: 'f59f3114-d9f2-4947-938b-c1d8feea6d95',
        skuId: 'B0CZ16RL39',
        name: 'Logitech G Fits Wireless In Ear headphones',
        price: '$159.99',
        priceCents: 15999,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/f59f3114-d9f2-4947-938b-c1d8feea6d95/images/1711920111466',
        availabilityDate: '2024-03-25T12:13:34.992Z',
        categories: ['CE'],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Logitech-Lightspeed-Bluetooth-Beamforming-Microphones/dp/B0B7HKYXYQ',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:51:47.854Z',
        ratingSummary: {
          rating: 3.4,
          numRatings: 261,
          numReviews: 128
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-04-08T13:12:21.252Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '1307b738-76fa-49df-803d-a63880ccb9dd',
        skuId: 'B0CLYHHMFB',
        name: 'Logitech for Creators BlueSnowball iCE Microphone',
        price: '$89.98',
        priceCents: 8998,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/1307b738-76fa-49df-803d-a63880ccb9dd/images/1710140836729',
        availabilityDate: '2023-10-27T11:14:41.077Z',
        categories: ['CE'],
        creationSource: ProductCreationSource.VENDOR_INTEGRATION,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Microphone-Recording-Podcasting-Black-Logitech-Adjustable/dp/B0BXG4N2VD',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:51:55.425Z',
        ratingSummary: {
          rating: 0,
          numRatings: 0,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-05-06T14:25:27.139Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-03-14T17:50:29.062Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '64224070-ade4-41d3-b4ff-e0a857f39d32',
        skuId: 'B0BN7H7G38',
        name: '4 Pcs Panasonic Cr2016 3v Lithium Coin Cell Battery Dl2016 Ecr2016 1 Count (Pack of 1)',
        price: '$8.40',
        priceCents: 840,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1707050857735',
        availabilityDate: '2019-12-05T00:00:00.000Z',
        categories: [
          'Electronics',
          'Health & Household',
          'Household Supplies',
          'Household Batteries',
          'Coin & Button Cell'
        ],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Panasonic-CR2016-Volt-Lithium-Batteries/dp/B0829P15SK',
        listingFulfillmentType: ListingFulfillmentType.HYBRID,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:17:51.384Z',
        ratingSummary: {
          rating: 4.4,
          numRatings: 17,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-03-04T18:08:01.122Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: 'f2d6afef-7eb6-4551-8a54-78f3d5acbb03',
        skuId: 'B07SMTR8Y3',
        name: 'Panasonic DMW-STC14 Lumix S 1.4x Teleconverter w/ 1.4x',
        price: '$497.99',
        priceCents: 49799,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1714388007895',
        availabilityDate: '2019-05-31T00:00:00.000Z',
        categories: ['Electronics', 'Camera & Photo', 'Lenses', 'Camera Lenses', 'SLR Camera Lenses'],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Panasonic-DMW-STC14-Lumix-1-4x-Teleconverter/dp/B07RY4796L',
        listingFulfillmentType: ListingFulfillmentType.HYBRID,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:17:19.205Z',
        ratingSummary: {
          rating: 4.3,
          numRatings: 6,
          numReviews: 2
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-04-29T13:22:20.130Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '7f4b2b5c-92b1-49ee-8a18-5c3833d5c8db',
        skuId: 'B0B3SHM7GT',
        name: 'Panasonic CR2016 3.0 Volt Long Lasting Lithium Coin Cell Batteries in Child Resistant, Standards Based Packaging, 1-Battery Pack',
        price: '$5.99',
        priceCents: 599,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/7f4b2b5c-92b1-49ee-8a18-5c3833d5c8db/images/1706837198264',
        availabilityDate: '2022-06-10T00:00:00.000Z',
        categories: [
          'Electronics',
          'Health & Household',
          'Household Supplies',
          'Household Batteries',
          'Coin & Button Cell'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl: 'https://www.amazon.com/stores/page/F6906AE2-27F4-463F-8810-41DB8D93F02B/search?terms=*',
        listingLink: 'https://www.amazon.com/Panasonic-Batteries-Resistant-Standards-Packaging/dp/B0B3SHM7GT',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:17:17.553Z',
        ratingSummary: {
          rating: 4.4,
          numRatings: 49,
          numReviews: 10
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-02-02T23:36:09.558Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '0be0c2d5-27a4-49b5-abcb-f231b31437ad',
        skuId: 'B0CJ488VR4',
        name: "Panasonic LUMIX DC-G9M2BODY Micro Four Thirds Camera with LUMIX Leica DG SUMMILUX 9mm F1.7 (H-X09) 'Body Only'",
        price: '$2,995.98',
        store: Store.amazon,

        image: 'https://media.productwind.com/products/0be0c2d5-27a4-49b5-abcb-f231b31437ad/images/1706837529918',
        availabilityDate: '2023-12-08T00:00:00.000Z',
        categories: ['Electronics', 'Camera & Photo'],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl: 'https://www.amazon.com/stores/page/F6906AE2-27F4-463F-8810-41DB8D93F02B/search?terms=*',
        listingLink: 'https://www.amazon.com/Panasonic-DC-G9M2LK-Thirds-Camera-SUMMILUX/dp/B0CPTKYYW9',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:18:11.415Z',
        ratingSummary: {
          rating: 5,
          numRatings: 1,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-02-02T23:37:02.142Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '5ab80b43-eac6-4050-a721-78756825eaeb',
        skuId: 'B0BYB7Y2MF',
        name: 'Panasonic Special Kit for Body and Hair, ES-WV60-S + EH-NA67-W Bundle, Bikini Trimmer and Shaver with 4 Attachments Plus Nanoe Salon Hair Dryer with 3 Attachments EH-NA67 Hair Dryer + Bikini Trimmer',
        price: '$124.00',
        priceCents: 12400,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/5ab80b43-eac6-4050-a721-78756825eaeb/images/1706837144162',
        availabilityDate: null,
        categories: [
          'Beauty & Personal Care',
          'Hair Care',
          'Hair Cutting Tools',
          'Hair Clippers & Accessories',
          'Hair Clippers'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl: 'https://www.amazon.com/stores/page/F6906AE2-27F4-463F-8810-41DB8D93F02B/search?terms=*',
        listingLink: 'https://www.amazon.com/Panasonic-Special-ES-WV60-S-EH-NA67-W-Attachments/dp/B0BYB7Y2MF',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:17:27.198Z',
        ratingSummary: {
          rating: 0,
          numRatings: 0,
          numReviews: 0
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-02-21T20:05:18.485Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-02-21T20:05:18.477Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '76ee7f24-fbf3-4c07-8355-29465b7d2bea',
        skuId: 'B0B6GPNQBN',
        name: 'Panasonic Bread Maker',
        price: '$265.98',
        priceCents: 26598,
        store: Store.amazon,

        image: 'https://media.productwind.com/e41d8fa5-f673-4b07-8b49-f5a53a776d89/productImageKey/1659459238646',
        availabilityDate: '2022-08-01T00:00:00.000Z',
        categories: ['Home & Kitchen', 'Kitchen & Dining', 'Small Appliances', 'Bread Machines'],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Panasonic-Automatic-Advanced-Temperature-Dispenser/dp/B0B6GPNQBN',
        listingFulfillmentType: ListingFulfillmentType.SELLER,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T13:17:19.453Z',
        ratingSummary: {
          rating: 3.9,
          numRatings: 156,
          numReviews: 83
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-03-04T18:09:21.658Z'
          }
        ],

        initialEtailerProductMetric: undefined
      },
      {
        id: '06d678dd-ec58-4c6f-bb51-4a981e81e01e',
        skuId: 'B0BSS1YST3',
        name: 'Poly Voyager Free 60 True Wireless Earbuds (Plantronics) – Noise-Canceling Mics for Clear Calls – Active Noise Canceling (ANC) – Portable Charge Case – Compatible w/iPhone, Android – Amazon Exclusive',
        price: '$199.99',
        priceCents: 19999,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/06d678dd-ec58-4c6f-bb51-4a981e81e01e/images/1688562511006',
        availabilityDate: '2023-03-27T00:00:00.000Z',
        categories: [
          'All Departments',
          'Electronics',
          'Headphones, Earbuds & Accessories',
          'Headphones & Earbuds',
          'Earbud Headphones'
        ],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Poly-Voyager-Wireless-Earbuds-Plantronics/dp/B0BSS1YST3',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:18:56.706Z',
        ratingSummary: {
          rating: 3.7,
          numRatings: 438,
          numReviews: 347
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-02-02T23:36:08.760Z'
          }
        ],

        initialEtailerProductMetric: undefined
      },
      {
        id: '638f160b-3f76-4b9f-84aa-270b9a20a62d',
        skuId: 'B0C6FKD6BP',
        name: 'Poly Voyager Free 60+ UC True Wireless Earbuds (Plantronics) – Noise-Canceling Mics for Clear Calls – ANC – Touchscreen Charge Case –Works w/iPhone, Android, PC/Mac – Teams Certified– Amazon Exclusive',
        price: '$299.99',
        priceCents: 29999,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1710377077331',
        availabilityDate: '2023-06-12T00:00:00.000Z',
        categories: [
          'All Departments',
          'Electronics',
          'Computers & Accessories',
          'Computer Accessories & Peripherals',
          'Audio & Video Accessories',
          'Computer Headsets'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl: 'https://www.amazon.com/stores/page/F4BD54E6-2BFB-492D-A777-D4ED104038D5/search?terms=*',
        listingLink: 'https://www.amazon.com/Poly-Voyager-Wireless-Earbuds-Plantronics/dp/B0C6FKD6BP',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:19:03.347Z',
        ratingSummary: {
          rating: 3.7,
          numRatings: 438,
          numReviews: 347
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-03-14T00:45:37.239Z'
          }
        ],

        initialEtailerProductMetric: undefined
      },
      {
        id: '8326a447-b81a-4849-b5fa-0f2ee0ab9b9e',
        skuId: 'B09VYFXV66',
        name: 'Single-Ear Bluetooth Headset',
        price: '$119.01',
        priceCents: 11901,
        store: Store.amazon,

        image:
          'https://media.productwind.com/Plantronics by Poly Voyager 4310 UC Wireless Headset - Single-Ear Bluetooth Headset w/Noise-Canceling Boom Mic - Connect to PC/Mac/Mobile via Bluetooth - Works w/Teams, Zoom & More - Amazon Exclusive/productImageKey/1659973664389',
        availabilityDate: '2021-08-25T00:00:00.000Z',
        categories: [
          'All Departments',
          'Electronics',
          'Computers & Accessories',
          'Computer Accessories & Peripherals',
          'Audio & Video Accessories',
          'Computer Headsets'
        ],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Plantronics-Poly-Voyager-Wireless-Headset/dp/B09VYFXV66',
        listingFulfillmentType: ListingFulfillmentType.HYBRID,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:18:57.900Z',
        ratingSummary: {
          rating: 3.8,
          numRatings: 412,
          numReviews: 214
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-02-02T23:36:09.191Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: '2425c9e9-3513-473b-a810-f5078a304aca',
        skuId: 'B0C6FLJTDM',
        name: 'Poly EncorePro 540 QD Headset (Plantronics) – Works w/Poly Call Center Digital Adapters – Acoustic Hearing Protection – Convertible Wearing Style - Works w/PC, Mac, Teams, Zoom - Amazon Exclusive',
        price: '$69.77',
        priceCents: 6977,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1710768255942',
        availabilityDate: '2023-06-12T00:00:00.000Z',
        categories: [
          'All Departments',
          'Electronics',
          'Computers & Accessories',
          'Computer Accessories & Peripherals',
          'Audio & Video Accessories',
          'Computer Headsets'
        ],
        creationSource: ProductCreationSource.BRAND_STORE_SCRAPE,
        creationSourceUrl: 'https://www.amazon.com/stores/page/F4BD54E6-2BFB-492D-A777-D4ED104038D5/search?terms=*',
        listingLink: 'https://www.amazon.com/Poly-EncorePro-540-Headset-Plantronics/dp/B0C6FLJTDM',
        listingFulfillmentType: ListingFulfillmentType.VENDOR,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:19:00.468Z',
        ratingSummary: {
          rating: 3.6,
          numRatings: 6,
          numReviews: 6
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-03-18T13:27:05.126Z'
          },
          {
            recommendationType: RecommendationType.PRODUCT_LAUNCH,
            createdAt: '2024-03-18T13:27:05.144Z'
          }
        ],

        initialEtailerProductMetric: undefined
      },
      {
        id: '36eb3a6c-d809-43a9-aaab-f6e27d07589f',
        skuId: 'B092WTXGSX',
        name: 'Plantronics Savi Wireless Headset System Black 8.2" x 7.3"',
        price: '$196.87',
        priceCents: 19687,
        store: Store.amazon,

        image: 'https://media.productwind.com/products/undefined/images/1713784137668',
        availabilityDate: '2021-04-19T00:00:00.000Z',
        categories: ['Electronics'],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com/Plantronics-Savi-Wireless-Headset-System/dp/B092WTXGSX',
        listingFulfillmentType: ListingFulfillmentType.SELLER,
        isRecommendationsHidden: undefined,
        lastScrapedAt: '2024-05-20T12:19:00.329Z',
        ratingSummary: {
          rating: 3.6,
          numRatings: 66,
          numReviews: 17
        },
        recommendations: [
          {
            recommendationType: RecommendationType.BOOST_RATING,
            createdAt: '2024-04-22T12:21:28.688Z'
          }
        ],
        initialEtailerProductMetric: undefined
      },
      {
        id: 'sample-sku-water-flosser',
        skuId: 'FLOSSER',
        name: 'Rechargeable Portable Water Flosser',
        active: 'active',
        price: '$49.99',
        priceCents: 4999,
        store: 'amazon',
        productImageKey: 'sample/images/sample-water-flosser.png',
        image: 'https://media.productwind.com/sample/images/sample-water-flosser.png',
        availabilityDate: null,
        categories: [
          'Beauty & Personal Care',
          'Health & Household',
          'Oral Care',
          'Dental Floss & Picks',
          'Power Dental Flossers'
        ],
        creationSource: null,
        creationSourceUrl: null,
        listingLink: 'https://www.amazon.com',
        listingFulfillmentType: 'VENDOR',
        isRecommendationsHidden: null,
        lastScrapedAt: '2024-10-06T22:00:50.914Z',
        ratingSummary: {
          rating: 4.4,
          numRatings: 4180,
          numReviews: 1072
        },
        recommendations: [],
        parentSkuId: null,
        initialEtailerProductMetric: null,
        itemClassification: 'BASE_PRODUCT'
      }
    ],
    total: 23
  }
}

export const SAMPLE_PRODUCTS_BY_ID = keyBy(
  Object.values(SAMPLE_PRODUCTS_BY_BRAND_ID).flatMap(c => c.items),
  'id'
)

import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ContentIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 202 189" fill="none">
      <path
        d="M99.2254 171.271C146.521 171.271 184.861 132.931 184.861 85.6356C184.861 38.3404 146.521 0 99.2254 0C51.9302 0 13.5898 38.3404 13.5898 85.6356C13.5898 132.931 51.9302 171.271 99.2254 171.271Z"
        fill="#AAD994"
      />
      <path
        d="M133.692 12.6586L131.767 13.4757L124.737 16.4605L117.444 19.5602L110.414 22.545L103.121 25.6447L96.0907 28.6295L88.7975 31.7292L81.7675 34.714L74.4742 37.8137L67.4442 40.8053L62.2714 43L59.9889 37.6246C59.3473 36.1187 59.1042 34.5384 59.2123 33.0122C59.3811 30.4933 60.5021 28.0961 62.3659 26.3538C63.2033 25.5637 64.196 24.9086 65.3103 24.4291L69.1663 22.7882L76.1962 19.8032L83.4895 16.7037L90.5194 13.7187L97.8128 10.6191L104.843 7.63417L112.136 4.53462L118.214 1.9549C118.545 1.81309 118.876 1.69827 119.213 1.59697C124.075 0.104546 129.377 2.50199 131.409 7.28316L133.692 12.6586Z"
        fill="#F1F3FA"
      />
      <path
        d="M62.2859 44.0054C61.8942 44.0054 61.516 43.7759 61.354 43.391L59.0714 38.0155C58.3894 36.4083 58.0923 34.6525 58.2206 32.9372C58.4097 30.1347 59.6387 27.528 61.6916 25.6101C62.6235 24.7254 63.7176 24.0164 64.9331 23.4965L117.83 1.029C118.188 0.880435 118.559 0.745315 118.931 0.630512C124.353 -1.03749 130.127 1.657 132.349 6.89737L134.631 12.2728C134.848 12.786 134.611 13.3803 134.098 13.6031L62.6911 43.9311C62.556 43.9783 62.4209 44.0054 62.2859 44.0054ZM122.172 2.15671C121.287 2.15671 120.396 2.28508 119.511 2.5552C119.201 2.64974 118.904 2.75778 118.613 2.87934L65.7165 25.3468C64.7238 25.7722 63.8324 26.3529 63.0693 27.0754C61.3878 28.6422 60.3815 30.7695 60.233 33.0655C60.1317 34.4701 60.3748 35.9085 60.9285 37.2186L62.8194 41.6621L132.362 12.1242L130.478 7.68067C129.026 4.25011 125.711 2.15671 122.172 2.15671Z"
        fill="#243C98"
      />
      <path
        d="M131.768 13.4783L124.738 16.4631L112.137 4.53726L118.214 1.95754C118.545 1.81573 118.876 1.70091 119.214 1.59961L131.768 13.4783Z"
        fill="#243C98"
      />
      <path d="M117.451 19.5588L110.421 22.5436L97.8203 10.6178L104.85 7.63281L117.451 19.5588Z" fill="#243C98" />
      <path d="M103.127 25.6457L96.0973 28.6305L83.4961 16.6979L90.5261 13.7129L103.127 25.6457Z" fill="#243C98" />
      <path d="M88.804 31.7209L81.7809 34.7057L69.1797 22.7799L76.2096 19.7949L88.804 31.7209Z" fill="#243C98" />
      <path
        d="M74.4885 37.8095L67.4585 40.8011L59.2266 33.008C59.3954 30.4891 60.5164 28.0919 62.3802 26.3496L74.4885 37.8095Z"
        fill="#243C98"
      />
      <path d="M137.925 26.1259L62.2695 43.0898L75.2618 101.032L150.917 84.0685L137.925 26.1259Z" fill="white" />
      <path
        d="M75.2806 101.946C74.8146 101.946 74.3959 101.629 74.2946 101.156L61.3017 43.2149C61.1802 42.668 61.5246 42.1277 62.0716 42.0061L137.726 25.0424C137.99 24.9883 138.266 25.029 138.489 25.1775C138.719 25.3194 138.874 25.5489 138.935 25.8123L151.928 83.7536C151.989 84.017 151.941 84.287 151.793 84.5166C151.644 84.7462 151.421 84.9016 151.158 84.9624L75.5034 101.926C75.4291 101.933 75.3549 101.946 75.2806 101.946ZM63.4965 43.7552L76.0437 99.7179L149.72 83.193L137.172 27.2303L63.4965 43.7552Z"
        fill="#243C98"
      />
      <path d="M132.943 38.1624L71.9102 51.8477L73.2799 57.9563L134.313 44.271L132.943 38.1624Z" fill="#243C98" />
      <path
        d="M141.093 188.167H73.2184C73.0428 188.167 72.8673 188.16 72.6985 188.147C66.8233 187.85 62.2109 182.994 62.2109 177.092V81.7049C62.2109 75.8027 66.8233 70.9474 72.7119 70.6502C72.8673 70.6367 73.0428 70.6367 73.2184 70.6367H132.389C132.666 70.6367 132.929 70.7515 133.125 70.9473L151.892 90.6191C152.074 90.8082 152.168 91.0581 152.168 91.3214V177.099C152.168 183.001 147.556 187.857 141.667 188.154C141.478 188.16 141.289 188.167 141.093 188.167ZM73.2184 72.6626C73.0901 72.6626 72.9618 72.6694 72.8335 72.6762C68.0051 72.9193 64.2369 76.8834 64.2369 81.7118V177.099C64.2369 181.921 68.0051 185.891 72.8133 186.134C72.9618 186.141 73.0901 186.148 73.2184 186.148H141.093C141.249 186.148 141.397 186.141 141.553 186.134C146.374 185.891 150.143 181.927 150.143 177.099V91.7333L131.957 72.6694H73.2184V72.6626Z"
        fill="#243C98"
      />
      <path
        d="M79.3471 66.9427L104.32 139.187C105.887 143.718 103.611 148.655 99.2078 150.424C99.066 150.485 98.9242 150.539 98.7756 150.586L41.611 170.346C41.4826 170.386 41.3543 170.427 41.226 170.467C36.661 171.824 31.7852 169.353 30.2118 164.801L2.44319 84.4601C0.869724 79.9086 3.17929 74.9519 7.6093 73.2028C7.73085 73.1488 7.85916 73.1016 7.98747 73.0611L57.8251 55.834L79.3471 66.9427Z"
        fill="white"
      />
      <path
        d="M38.6442 171.858C34.5113 171.858 30.6688 169.258 29.2439 165.132L1.47534 84.7914C-0.273704 79.7266 2.25195 74.2228 7.22896 72.2644C7.35052 72.2104 7.49908 72.1563 7.64765 72.1023L57.4785 54.8753C57.7418 54.7807 58.0255 54.8076 58.2753 54.9292L79.8109 66.0313C80.0405 66.1529 80.216 66.3554 80.3038 66.5985L105.277 138.843C107.012 143.874 104.514 149.364 99.5838 151.35C99.4353 151.41 99.2731 151.471 99.1043 151.532L41.933 171.291C41.7844 171.345 41.6358 171.386 41.4805 171.433C40.5418 171.723 39.5829 171.858 38.6442 171.858ZM57.7283 56.9348L8.30268 74.0201C8.19463 74.0539 8.09336 74.0945 7.99206 74.1418C4.00775 75.7152 1.99534 80.1046 3.38647 84.1362L31.1551 164.477C32.5462 168.509 36.8411 170.717 40.9267 169.502C41.055 169.468 41.1631 169.434 41.2711 169.394L98.4425 149.634C98.5708 149.587 98.6924 149.54 98.8207 149.492C102.758 147.906 104.75 143.529 103.366 139.525L78.5278 67.6655L57.7283 56.9348Z"
        fill="#243C98"
      />
      <path
        d="M79.3452 66.9408L72.0114 69.4732C67.3315 71.0872 62.2262 68.6088 60.6122 63.9289L57.8164 55.832L79.3452 66.9408Z"
        fill="#324AA8"
      />
      <path
        d="M69.0728 70.9863C67.5803 70.9863 66.0947 70.6486 64.7171 69.9733C62.3197 68.805 60.5167 66.7791 59.6523 64.2602L56.8565 56.1633C56.7214 55.7716 56.8362 55.3394 57.1536 55.0692C57.4643 54.7991 57.91 54.7451 58.2814 54.9342L79.8169 66.0364C80.1748 66.2187 80.3909 66.6036 80.3639 67.0088C80.3369 67.414 80.0668 67.7584 79.6819 67.8935L72.348 70.4259C71.2743 70.7973 70.1735 70.9863 69.0728 70.9863ZM59.5915 57.8921L61.5634 63.5985C62.2589 65.6041 63.6906 67.2181 65.6017 68.15C67.5128 69.0819 69.6671 69.2103 71.6727 68.5147L76.7713 66.7521L59.5915 57.8921Z"
        fill="#243C98"
      />
      <path
        d="M39.32 133.378L41.9065 126.22C42.4535 124.694 43.9256 124.025 45.364 124.639L51.1379 127.091C52.5088 127.671 53.9066 127.098 54.5144 125.713L64.4685 109.08C65.164 107.487 66.8928 107.001 68.3717 107.986L83.6539 115.759C86.0715 117.366 86.058 121.384 83.6404 122.222L41.8389 136.673C40.2857 137.207 38.692 135.134 39.32 133.378Z"
        fill="#7C8EE1"
      />
      <path
        d="M41.3344 137.768C40.5916 137.768 39.8555 137.444 39.2545 136.843C38.2685 135.844 37.9106 134.311 38.3698 133.041L40.9563 125.883C41.3209 124.863 42.0233 124.087 42.9349 123.688C43.8196 123.303 44.819 123.31 45.7645 123.709L51.5383 126.16C52.396 126.524 53.2063 126.187 53.5912 125.302C53.6115 125.262 53.625 125.228 53.652 125.187L63.579 108.609C64.0382 107.603 64.8351 106.887 65.8278 106.596C66.8407 106.299 67.928 106.482 68.8937 107.103L84.1286 114.855C84.1624 114.876 84.1961 114.896 84.2299 114.916C85.8034 115.963 86.6947 117.975 86.4449 119.92C86.2423 121.5 85.3239 122.716 83.9801 123.182L42.1786 137.633C41.9017 137.721 41.6181 137.768 41.3344 137.768ZM44.299 125.424C44.1032 125.424 43.9209 125.464 43.7453 125.539C43.3536 125.714 43.0362 126.072 42.8606 126.565L40.2742 133.723C40.0446 134.358 40.3282 135.047 40.6929 135.418C40.8482 135.58 41.1723 135.83 41.5167 135.709L83.3183 121.257C84.1759 120.96 84.3852 120.035 84.4325 119.657C84.5608 118.671 84.2097 117.361 83.1494 116.631L67.9212 108.885C67.8875 108.865 67.8537 108.845 67.8199 108.825C67.3472 108.514 66.8408 108.413 66.3951 108.541C65.9696 108.669 65.6184 109 65.4091 109.487C65.3888 109.527 65.3753 109.561 65.3483 109.601L55.4213 126.18C54.5704 128.044 52.6188 128.821 50.7482 128.024L44.9744 125.572C44.7448 125.478 44.5219 125.424 44.299 125.424Z"
        fill="#243C98"
      />
      <path
        d="M39.9089 111.866C43.2225 109.384 44.2718 105.186 42.2527 102.491C40.2337 99.7952 35.9109 99.622 32.5974 102.104C29.2838 104.586 28.2343 108.783 30.2533 111.478C32.2723 114.174 36.5954 114.347 39.9089 111.866Z"
        fill="#7C8EE1"
      />
      <path
        d="M34.841 114.593C34.7329 114.593 34.6181 114.593 34.51 114.586C32.3761 114.499 30.5595 113.601 29.398 112.047C27.0479 108.907 28.1892 104.065 31.9439 101.256C33.7334 99.9189 35.8539 99.23 37.9068 99.3043C40.0408 99.3921 41.8574 100.29 43.0189 101.844C44.1804 103.397 44.5383 105.396 44.0184 107.462C43.5186 109.461 42.2626 111.298 40.473 112.635C38.778 113.905 36.7926 114.593 34.841 114.593ZM37.576 101.324C36.0633 101.324 34.5033 101.87 33.1594 102.877C30.2961 105.017 29.3372 108.59 31.0187 110.832C31.8088 111.885 33.0784 112.5 34.5911 112.56C36.1848 112.621 37.8393 112.075 39.2575 111.014C40.6756 109.954 41.6683 108.516 42.0532 106.969C42.4179 105.497 42.1883 104.113 41.3982 103.052C40.6081 101.999 39.3385 101.384 37.8258 101.324C37.7448 101.324 37.6637 101.324 37.576 101.324Z"
        fill="#243C98"
      />
      <path
        d="M200.054 103.225L162.743 170.168C160.407 174.362 155.193 175.962 150.925 173.855C150.79 173.788 150.648 173.72 150.513 173.639L97.5422 144.115C97.4274 144.047 97.3058 143.98 97.1978 143.906C93.1324 141.4 91.7211 136.106 94.0711 131.885L135.562 57.446C137.912 53.232 143.159 51.6451 147.427 53.7858C147.549 53.8466 147.67 53.9074 147.785 53.9682L193.962 79.704L200.054 103.225Z"
        fill="white"
      />
      <path
        d="M154.889 175.795C153.404 175.795 151.898 175.465 150.479 174.762C150.331 174.688 150.175 174.614 150.02 174.526L97.0492 145.001C96.9141 144.927 96.7791 144.846 96.644 144.758C92.0992 141.962 90.5663 136.087 93.1797 131.394L134.67 56.9549C137.284 52.2683 143.085 50.4787 147.873 52.8828C147.994 52.9368 148.136 53.0112 148.271 53.0922L194.449 78.8282C194.692 78.9632 194.867 79.193 194.935 79.4563L201.026 102.97C201.094 103.22 201.06 103.49 200.932 103.72L163.621 170.663C161.804 173.931 158.408 175.795 154.889 175.795ZM143.402 53.8486C140.6 53.8486 137.905 55.3342 136.453 57.9408L94.9625 132.38C92.8758 136.121 94.0981 140.801 97.738 143.043C97.8528 143.117 97.9473 143.171 98.0486 143.232L151.02 172.756C151.141 172.824 151.256 172.885 151.378 172.946C155.193 174.83 159.799 173.391 161.865 169.677L198.98 103.092L193.105 80.3882L147.305 54.8616C147.204 54.8076 147.11 54.7535 147.008 54.7063C145.84 54.1255 144.611 53.8486 143.402 53.8486Z"
        fill="#243C98"
      />
      <path
        d="M200.055 103.229L193.262 99.4405C188.926 97.0229 187.373 91.5461 189.784 87.2174L193.964 79.7148L200.055 103.229Z"
        fill="#324AA8"
      />
      <path
        d="M200.056 104.242C199.888 104.242 199.719 104.201 199.563 104.114L192.77 100.325C190.44 99.0287 188.752 96.8947 188.022 94.3218C187.293 91.7488 187.604 89.0545 188.907 86.718L193.087 79.2151C193.29 78.8504 193.688 78.648 194.1 78.702C194.512 78.756 194.85 79.0531 194.951 79.4515L201.042 102.966C201.144 103.357 201.002 103.776 200.678 104.019C200.495 104.168 200.272 104.242 200.056 104.242ZM193.621 82.4162L190.67 87.7105C189.636 89.5676 189.387 91.7218 189.967 93.7747C190.548 95.8277 191.892 97.5226 193.756 98.5625L198.483 101.196L193.621 82.4162Z"
        fill="#243C98"
      />
      <path
        d="M163.558 88.9738C163.389 88.9738 163.221 88.9333 163.065 88.8455L135.526 73.4956C135.04 73.2255 134.865 72.6044 135.135 72.1182C135.405 71.6319 136.026 71.4563 136.512 71.7264L164.051 87.0761C164.537 87.3462 164.713 87.9676 164.443 88.4538C164.254 88.7847 163.909 88.9738 163.558 88.9738Z"
        fill="#243C98"
      />
      <path
        d="M144.051 87.1891C143.882 87.1891 143.713 87.1486 143.558 87.0608L131.659 80.4294C131.173 80.1593 130.997 79.538 131.267 79.0517C131.538 78.5655 132.159 78.3899 132.645 78.66L144.544 85.2914C145.03 85.5615 145.206 86.1828 144.936 86.6691C144.753 87 144.409 87.1891 144.051 87.1891Z"
        fill="#243C98"
      />
      <path
        d="M122.184 126.418C122.015 126.418 121.846 126.377 121.691 126.289L109.792 119.658C109.306 119.388 109.13 118.766 109.4 118.28C109.67 117.794 110.292 117.618 110.778 117.889L122.677 124.52C123.163 124.79 123.339 125.411 123.069 125.898C122.886 126.228 122.542 126.418 122.184 126.418Z"
        fill="#243C98"
      />
      <path
        d="M170.288 117.064C170.119 117.064 169.95 117.024 169.795 116.936L125.171 92.0642C124.685 91.794 124.509 91.1727 124.779 90.6865C125.049 90.2003 125.664 90.0247 126.157 90.2948L170.781 115.166C171.267 115.436 171.443 116.058 171.173 116.544C170.99 116.875 170.646 117.064 170.288 117.064Z"
        fill="#243C98"
      />
      <path
        d="M167.214 122.582C167.045 122.582 166.876 122.541 166.721 122.453L122.097 97.5817C121.61 97.3116 121.435 96.6903 121.705 96.2041C121.975 95.7179 122.596 95.5422 123.083 95.8124L167.707 120.684C168.193 120.954 168.369 121.575 168.098 122.062C167.916 122.392 167.572 122.582 167.214 122.582Z"
        fill="#243C98"
      />
      <path
        d="M164.136 128.099C163.967 128.099 163.798 128.059 163.643 127.971L119.019 103.099C118.532 102.829 118.357 102.208 118.627 101.722C118.897 101.235 119.511 101.06 120.004 101.33L164.629 126.202C165.115 126.472 165.291 127.093 165.02 127.579C164.838 127.91 164.494 128.099 164.136 128.099Z"
        fill="#243C98"
      />
      <path
        d="M161.061 133.617C160.893 133.617 160.724 133.576 160.568 133.488L115.944 108.617C115.458 108.347 115.282 107.725 115.553 107.239C115.823 106.753 116.444 106.577 116.93 106.848L161.554 131.719C162.041 131.989 162.216 132.611 161.946 133.097C161.764 133.434 161.419 133.617 161.061 133.617Z"
        fill="#243C98"
      />
      <path
        d="M151.151 91.3113V177.089C151.151 182.464 146.924 186.867 141.609 187.131C141.441 187.144 141.265 187.144 141.089 187.144H73.2144C73.0658 187.144 72.9105 187.137 72.7619 187.131C67.4203 186.901 63.1523 182.491 63.1523 177.089V81.7017C63.1523 76.2993 67.4203 71.8896 72.7619 71.66C72.9105 71.6465 73.0658 71.6465 73.2144 71.6465H132.385L151.151 91.3113Z"
        fill="white"
      />
      <path
        d="M141.096 188.165H73.2206C73.045 188.165 72.8695 188.158 72.7007 188.145C66.7917 187.888 62.1523 183.033 62.1523 177.09V81.703C62.1523 75.7603 66.7985 70.9049 72.7277 70.6483C72.8762 70.6348 73.0518 70.6348 73.2274 70.6348H132.398C132.675 70.6348 132.938 70.7495 133.134 70.9454L151.901 90.6171C152.083 90.8062 152.177 91.0561 152.177 91.3195V177.097C152.177 182.999 147.565 187.855 141.676 188.152C141.481 188.158 141.291 188.165 141.096 188.165ZM73.2206 72.6607C73.0923 72.6607 72.964 72.6675 72.8357 72.6742C67.9667 72.8835 64.1715 76.8544 64.1715 81.7099V177.097C64.1715 181.952 67.9667 185.916 72.8087 186.132C72.964 186.139 73.0923 186.146 73.2206 186.146H141.096C141.251 186.146 141.4 186.139 141.555 186.132C146.377 185.889 150.145 181.925 150.145 177.097V91.7313L131.959 72.6674H73.2206V72.6607Z"
        fill="#243C98"
      />
      <path
        d="M151.15 91.3083H142.445C136.894 91.3083 132.383 86.8041 132.383 81.2463V71.6367L151.15 91.3083Z"
        fill="#324AA8"
      />
      <path
        d="M151.151 92.3238H142.446C136.341 92.3238 131.371 87.3604 131.371 81.2489V71.6393C131.371 71.2273 131.621 70.856 132.006 70.7007C132.391 70.5453 132.83 70.6398 133.113 70.9437L151.88 90.6154C152.157 90.9058 152.238 91.3379 152.076 91.7161C151.914 92.0943 151.556 92.3238 151.151 92.3238ZM133.397 74.1717V81.2558C133.397 86.2463 137.456 90.3048 142.446 90.3048H148.787L133.397 74.1717Z"
        fill="#243C98"
      />
      <path
        d="M107.153 150.934C120.326 150.934 131.004 140.255 131.004 127.082C131.004 113.909 120.326 103.23 107.153 103.23C93.9796 103.23 83.3008 113.909 83.3008 127.082C83.3008 140.255 93.9796 150.934 107.153 150.934Z"
        fill="#324AA8"
      />
      <path
        d="M117.673 127.369C116.457 128.585 114.492 128.585 113.277 127.369L110.704 124.797V136.972C110.704 138.877 109.157 140.423 107.253 140.423C105.349 140.423 103.802 138.877 103.802 136.972V124.587L101.027 127.363C99.8111 128.578 97.846 128.578 96.6304 127.363C95.4149 126.147 95.4149 124.182 96.6304 122.966L102.755 116.842L104.957 114.647C106.172 113.438 108.138 113.438 109.353 114.647L111.555 116.842L117.68 122.966C118.888 124.189 118.888 126.154 117.673 127.369Z"
        fill="white"
      />
    </SvgIcon>
  )
}

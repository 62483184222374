import {
  CampaignStatus,
  CoarseCampaignStatus,
  UserCampaignAcceptanceStatus,
  ModelTypes
} from '@productwindtom/shared-ws-zeus-types'

export const ACTIVE_SPONSORSHIPS = Object.freeze({
  [CampaignStatus.application_approved]: CampaignStatus.application_approved,
  [CampaignStatus.sponsorship_accepted]: CampaignStatus.sponsorship_accepted,
  [CampaignStatus.invoice_submitted]: CampaignStatus.invoice_submitted,
  [CampaignStatus.product_feedback_submitted]: CampaignStatus.product_feedback_submitted,
  [CampaignStatus.product_feedback_submitted_with_issue]: CampaignStatus.product_feedback_submitted_with_issue,
  [CampaignStatus.content_posted]: CampaignStatus.content_posted,
  [CampaignStatus.content_survey_submitted]: CampaignStatus.content_survey_submitted,
  [CampaignStatus.assets_uploaded]: CampaignStatus.assets_uploaded,
  [CampaignStatus.bonus_approved]: CampaignStatus.bonus_approved,
  [CampaignStatus.close_out_submitted_awaiting_review_link]: CampaignStatus.close_out_submitted_awaiting_review_link,
  [CampaignStatus.close_out_submitted]: CampaignStatus.close_out_submitted,
  [CampaignStatus.discovery_v2]: CampaignStatus.discovery_v2,
  [CampaignStatus.content_survey_mandatory_story_v1]: CampaignStatus.content_survey_mandatory_story_v1,
  [CampaignStatus.discovery_v2_keywordOption]: CampaignStatus.discovery_v2_keywordOption,
  [CampaignStatus.content_posted_v2]: CampaignStatus.content_posted_v2,
  [CampaignStatus.direct_listing_link]: CampaignStatus.direct_listing_link,
  [CampaignStatus.application_approved_ranking]: CampaignStatus.application_approved_ranking,
  [CampaignStatus.posting_instructions_ranking]: CampaignStatus.posting_instructions_ranking,
  [CampaignStatus.waiting_room_ranking]: CampaignStatus.waiting_room_ranking,
  [CampaignStatus.ranking_close_out]: CampaignStatus.ranking_close_out,
  [CampaignStatus.ranking_close_out_no_review]: CampaignStatus.ranking_close_out_no_review,
  [CampaignStatus.create_content_v1]: CampaignStatus.create_content_v1,
  [CampaignStatus.no_purchase_discovery_v1]: CampaignStatus.no_purchase_discovery_v1
})

export const APPLICATION_APPROVED_STATES: readonly string[] = [
  CampaignStatus.application_approved,
  CampaignStatus.application_approved_ranking
]

export const SET_KEYWORD_STATES: readonly CampaignStatus[] = [CampaignStatus.discovery_v2]

export const ACTIVE_CAMPAIGN_START_STATES = Object.freeze({
  [CampaignStatus.application_approved]: CampaignStatus.application_approved,
  [CampaignStatus.application_approved_ranking]: CampaignStatus.application_approved_ranking
})

export const ACTIVE_CAMPAIGN_COMPLETE_STATES = Object.freeze({
  [CampaignStatus.assets_uploaded]: CampaignStatus.assets_uploaded,
  [CampaignStatus.ranking_close_out_no_review]: CampaignStatus.ranking_close_out_no_review
})

export const ACTIVE_CAMPAIGN_CLOSEOUT_STATES = Object.freeze({
  [CampaignStatus.close_out_submitted]: CampaignStatus.close_out_submitted,
  [CampaignStatus.ranking_close_out_no_review]: CampaignStatus.ranking_close_out_no_review
})

export const ACTIVE_CAMPAIGN_AUTO_BONUS_PAYOUT_STATES = Object.freeze({
  [CampaignStatus.assets_uploaded]: CampaignStatus.assets_uploaded,
  [CampaignStatus.close_out_submitted]: CampaignStatus.close_out_submitted,
  [CampaignStatus.ranking_close_out_no_review]: CampaignStatus.ranking_close_out_no_review
})

export const ACTIVE_CAMPAIGN_CONTENT_SUBMITTED_STATES = Object.freeze({
  [CampaignStatus.assets_uploaded]: CampaignStatus.assets_uploaded,
  [CampaignStatus.bonus_approved]: CampaignStatus.bonus_approved,
  [CampaignStatus.close_out_submitted]: CampaignStatus.close_out_submitted,
  [CampaignStatus.close_out_submitted_awaiting_review_link]: CampaignStatus.close_out_submitted_awaiting_review_link
})

export const DISCOVERY_STATES: CampaignStatus[] = [
  CampaignStatus.discovery_v2,
  CampaignStatus.discovery_v2_keywordOption,
  CampaignStatus.no_purchase_discovery_v1
]

export const PARTICIPANT_STATES: CampaignStatus[] = [
  ...DISCOVERY_STATES,
  CampaignStatus.create_content_v1,
  CampaignStatus.assets_uploaded,
  CampaignStatus.bonus_approved
]

export const BEFORE_APPROVAL_STATES: string[] = [CampaignStatus.invite_offered, CampaignStatus.application_submitted]

export const ARCHIVED_STATES: string[] = [CampaignStatus.archived_completed, CampaignStatus.archived_not_completed]

export function getCoarseCampaignStatusForCampaignStatus(campaignStatus: CampaignStatus) {
  if (BEFORE_APPROVAL_STATES.includes(campaignStatus)) {
    return CoarseCampaignStatus.before_approval
  }
  if (ARCHIVED_STATES.includes(campaignStatus)) {
    return CoarseCampaignStatus.archived
  }

  if (campaignStatus === CampaignStatus.purchase_holding_queue) {
    return CoarseCampaignStatus.queued_to_purchase
  }

  return CoarseCampaignStatus.active
}

export function getCampaignAcceptanceStatusCampaignStatus(
  currentCampaignStatus: CampaignStatus,
  nextCampaignStatus: CampaignStatus,
  statusHistory?: string,
  isDefect?: boolean
) {
  const statusHistoryArray = (statusHistory ? JSON.parse(statusHistory) : []) as ModelTypes['StatusHistory'][]
  const beenInParticipatingStates = statusHistoryArray.some(r => PARTICIPANT_STATES.includes(r.campaignStatus))

  if (BEFORE_APPROVAL_STATES.includes(nextCampaignStatus)) {
    return UserCampaignAcceptanceStatus.PENDING
  }

  if (
    [CampaignStatus.application_approved, CampaignStatus.discovery_v2].includes(currentCampaignStatus) &&
    isDefect &&
    !beenInParticipatingStates &&
    ARCHIVED_STATES.includes(nextCampaignStatus)
  ) {
    return UserCampaignAcceptanceStatus.REMOVED
  }
  if (
    [...PARTICIPANT_STATES, CampaignStatus.archived_completed].includes(nextCampaignStatus) ||
    (beenInParticipatingStates && ARCHIVED_STATES.includes(nextCampaignStatus))
  ) {
    return UserCampaignAcceptanceStatus.PARTICIPANT
  }

  if ([CampaignStatus.purchase_holding_queue, CampaignStatus.application_approved].includes(nextCampaignStatus)) {
    return UserCampaignAcceptanceStatus.CONSIDERED
  }

  return UserCampaignAcceptanceStatus.PENDING
}

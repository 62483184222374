// @ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_PRODUCT_KEYWORD_SUMMARIES_HEADPHONES: ModelTypes['ModelItemsProductKeywordSummary'] = {
  items: [
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_amazon keyboard',
      keyword: 'amazon keyboard',
      searchVolume: 571,
      organicStartRank: 76,
      organicHighestRank: 76,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 571,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-29',
          searchVolume: 571,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-28',
          searchVolume: 571,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-27',
          searchVolume: 571,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-26',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-25',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-24',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-23',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-22',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-21',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-20',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-19',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-18',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-17',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-16',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-15',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-14',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-13',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-12',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-11',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-10',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-09',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-08',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-07',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-06',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-05',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-04',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-03',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-02',
          searchVolume: 736,
          organicRank: 90,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-01',
          searchVolume: 736,
          organicRank: 82,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-03-31',
          searchVolume: 736,
          organicRank: 87,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-03-30',
          searchVolume: 736,
          organicRank: 89,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-03-29',
          searchVolume: 652,
          organicRank: 76,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-03-28',
          searchVolume: 652,
          organicRank: 64,
          sponsoredRank: null,
          keywordSales: 34
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_bluetooth keyboard logitech',
      keyword: 'bluetooth keyboard logitech',
      searchVolume: 1210,
      organicStartRank: 32,
      organicHighestRank: 32,
      sponsoredStartRank: 35,
      sponsoredHighestRank: 7,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-11',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 1210,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-30',
          searchVolume: 1210,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-29',
          searchVolume: 1210,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-28',
          searchVolume: 1210,
          organicRank: null,
          sponsoredRank: 47,
          keywordSales: 11
        },
        {
          date: '2024-04-27',
          searchVolume: 1210,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-26',
          searchVolume: 1304,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-25',
          searchVolume: 1304,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-24',
          searchVolume: 1304,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-23',
          searchVolume: 1304,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-22',
          searchVolume: 1304,
          organicRank: null,
          sponsoredRank: 76,
          keywordSales: 11
        },
        {
          date: '2024-04-21',
          searchVolume: 1304,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-20',
          searchVolume: 1304,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-19',
          searchVolume: 1083,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-18',
          searchVolume: 1083,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-17',
          searchVolume: 1083,
          organicRank: null,
          sponsoredRank: 30,
          keywordSales: 11
        },
        {
          date: '2024-04-16',
          searchVolume: 1083,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-15',
          searchVolume: 1083,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-14',
          searchVolume: 1083,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-13',
          searchVolume: 1083,
          organicRank: null,
          sponsoredRank: 52,
          keywordSales: 11
        },
        {
          date: '2024-04-12',
          searchVolume: 1243,
          organicRank: null,
          sponsoredRank: 18,
          keywordSales: 11
        },
        {
          date: '2024-04-11',
          searchVolume: 1243,
          organicRank: null,
          sponsoredRank: 7,
          keywordSales: 11
        },
        {
          date: '2024-04-10',
          searchVolume: 1243,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-09',
          searchVolume: 1243,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-08',
          searchVolume: 1243,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-07',
          searchVolume: 1243,
          organicRank: null,
          sponsoredRank: 35,
          keywordSales: 11
        },
        {
          date: '2024-04-06',
          searchVolume: 1243,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-05',
          searchVolume: 1330,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-04',
          searchVolume: 1330,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-03',
          searchVolume: 1330,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-02',
          searchVolume: 1330,
          organicRank: 56,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-01',
          searchVolume: 1330,
          organicRank: 62,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-03-31',
          searchVolume: 1330,
          organicRank: 40,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-03-30',
          searchVolume: 1330,
          organicRank: 46,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-03-29',
          searchVolume: 1388,
          organicRank: 32,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-03-28',
          searchVolume: 1388,
          organicRank: 43,
          sponsoredRank: null,
          keywordSales: 11
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_bluetooth keyboard mouse combo',
      keyword: 'bluetooth keyboard mouse combo',
      searchVolume: 452,
      organicStartRank: 97,
      organicHighestRank: 10,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-04-06',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-04-03',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 452,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-30',
          searchVolume: 452,
          organicRank: 237,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-29',
          searchVolume: 452,
          organicRank: 36,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-28',
          searchVolume: 452,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-27',
          searchVolume: 452,
          organicRank: 20,
          sponsoredRank: 9,
          keywordSales: 7
        },
        {
          date: '2024-04-26',
          searchVolume: 458,
          organicRank: 20,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-04-25',
          searchVolume: 458,
          organicRank: 25,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-24',
          searchVolume: 458,
          organicRank: 23,
          sponsoredRank: 10,
          keywordSales: 7
        },
        {
          date: '2024-04-23',
          searchVolume: 458,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-22',
          searchVolume: 458,
          organicRank: 15,
          sponsoredRank: 7,
          keywordSales: 7
        },
        {
          date: '2024-04-21',
          searchVolume: 458,
          organicRank: 14,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-20',
          searchVolume: 458,
          organicRank: 10,
          sponsoredRank: 5,
          keywordSales: 7
        },
        {
          date: '2024-04-19',
          searchVolume: 563,
          organicRank: 13,
          sponsoredRank: 4,
          keywordSales: 7
        },
        {
          date: '2024-04-18',
          searchVolume: 563,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-17',
          searchVolume: 563,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-16',
          searchVolume: 563,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-15',
          searchVolume: 563,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-14',
          searchVolume: 563,
          organicRank: 30,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-13',
          searchVolume: 563,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-12',
          searchVolume: 621,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-11',
          searchVolume: 621,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-10',
          searchVolume: 621,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-09',
          searchVolume: 621,
          organicRank: 19,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-08',
          searchVolume: 621,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-07',
          searchVolume: 621,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-06',
          searchVolume: 621,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-05',
          searchVolume: 736,
          organicRank: 29,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-04',
          searchVolume: 736,
          organicRank: 38,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-04-03',
          searchVolume: 736,
          organicRank: 74,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-04-02',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-01',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-31',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-30',
          searchVolume: 736,
          organicRank: 132,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-29',
          searchVolume: 652,
          organicRank: 97,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-28',
          searchVolume: 652,
          organicRank: 65,
          sponsoredRank: null,
          keywordSales: 7
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_computer mouse logitech',
      keyword: 'computer mouse logitech',
      searchVolume: 484,
      organicStartRank: 94,
      organicHighestRank: 89,
      sponsoredStartRank: 40,
      sponsoredHighestRank: 40,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 484,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-29',
          searchVolume: 484,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-28',
          searchVolume: 484,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-27',
          searchVolume: 484,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-26',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-25',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-24',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-23',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-22',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-21',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-20',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-19',
          searchVolume: 476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-18',
          searchVolume: 476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-17',
          searchVolume: 476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-16',
          searchVolume: 476,
          organicRank: null,
          sponsoredRank: 52,
          keywordSales: 10
        },
        {
          date: '2024-04-15',
          searchVolume: 476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-14',
          searchVolume: 476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-13',
          searchVolume: 476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-12',
          searchVolume: 467,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-11',
          searchVolume: 467,
          organicRank: 89,
          sponsoredRank: 40,
          keywordSales: 10
        },
        {
          date: '2024-04-10',
          searchVolume: 467,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-09',
          searchVolume: 467,
          organicRank: 90,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-08',
          searchVolume: 467,
          organicRank: 92,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-07',
          searchVolume: 467,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-06',
          searchVolume: 467,
          organicRank: 94,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-05',
          searchVolume: 551,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-04',
          searchVolume: 551,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-03',
          searchVolume: 551,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-02',
          searchVolume: 551,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-01',
          searchVolume: 551,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-31',
          searchVolume: 551,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-30',
          searchVolume: 551,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-29',
          searchVolume: 542,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-28',
          searchVolume: 542,
          organicRank: 96,
          sponsoredRank: null,
          keywordSales: 10
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_key board and mouse',
      keyword: 'key board and mouse',
      searchVolume: 470,
      organicStartRank: 222,
      organicHighestRank: 14,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-04-24',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-04-05',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 470,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-30',
          searchVolume: 470,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-29',
          searchVolume: 470,
          organicRank: 256,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-28',
          searchVolume: 470,
          organicRank: 221,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-27',
          searchVolume: 470,
          organicRank: 29,
          sponsoredRank: 10,
          keywordSales: 6
        },
        {
          date: '2024-04-26',
          searchVolume: 479,
          organicRank: 23,
          sponsoredRank: 7,
          keywordSales: 6
        },
        {
          date: '2024-04-25',
          searchVolume: 479,
          organicRank: 14,
          sponsoredRank: 28,
          keywordSales: 6
        },
        {
          date: '2024-04-24',
          searchVolume: 479,
          organicRank: 18,
          sponsoredRank: 4,
          keywordSales: 6
        },
        {
          date: '2024-04-23',
          searchVolume: 479,
          organicRank: 37,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-22',
          searchVolume: 479,
          organicRank: 38,
          sponsoredRank: 61,
          keywordSales: 6
        },
        {
          date: '2024-04-21',
          searchVolume: 479,
          organicRank: 32,
          sponsoredRank: 19,
          keywordSales: 6
        },
        {
          date: '2024-04-20',
          searchVolume: 479,
          organicRank: 26,
          sponsoredRank: 20,
          keywordSales: 6
        },
        {
          date: '2024-04-19',
          searchVolume: 464,
          organicRank: 24,
          sponsoredRank: 8,
          keywordSales: 6
        },
        {
          date: '2024-04-18',
          searchVolume: 464,
          organicRank: 24,
          sponsoredRank: 10,
          keywordSales: 6
        },
        {
          date: '2024-04-17',
          searchVolume: 464,
          organicRank: 31,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-16',
          searchVolume: 464,
          organicRank: 217,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-15',
          searchVolume: 464,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-14',
          searchVolume: 464,
          organicRank: 238,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-13',
          searchVolume: 464,
          organicRank: 245,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-12',
          searchVolume: 470,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-11',
          searchVolume: 470,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-10',
          searchVolume: 470,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-09',
          searchVolume: 470,
          organicRank: null,
          sponsoredRank: 3,
          keywordSales: 6
        },
        {
          date: '2024-04-08',
          searchVolume: 470,
          organicRank: 260,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-07',
          searchVolume: 470,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-06',
          searchVolume: 470,
          organicRank: 233,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-05',
          searchVolume: 648,
          organicRank: null,
          sponsoredRank: 13,
          keywordSales: 6
        },
        {
          date: '2024-04-04',
          searchVolume: 648,
          organicRank: 150,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-03',
          searchVolume: 648,
          organicRank: 222,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-02',
          searchVolume: 648,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-01',
          searchVolume: 648,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-03-31',
          searchVolume: 648,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-03-30',
          searchVolume: 648,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-03-29',
          searchVolume: 582,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-03-28',
          searchVolume: 582,
          organicRank: 67,
          sponsoredRank: null,
          keywordSales: 6
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_keyboard and mouse combo',
      keyword: 'keyboard and mouse combo',
      searchVolume: 4337,
      organicStartRank: 104,
      organicHighestRank: 13,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-04-06',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-04-03',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 4337,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-30',
          searchVolume: 4337,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-29',
          searchVolume: 4337,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-28',
          searchVolume: 4337,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-27',
          searchVolume: 4337,
          organicRank: 13,
          sponsoredRank: 40,
          keywordSales: 57
        },
        {
          date: '2024-04-26',
          searchVolume: 4189,
          organicRank: 14,
          sponsoredRank: 11,
          keywordSales: 57
        },
        {
          date: '2024-04-25',
          searchVolume: 4189,
          organicRank: 30,
          sponsoredRank: 5,
          keywordSales: 57
        },
        {
          date: '2024-04-24',
          searchVolume: 4189,
          organicRank: 19,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-04-23',
          searchVolume: 4189,
          organicRank: 19,
          sponsoredRank: 23,
          keywordSales: 57
        },
        {
          date: '2024-04-22',
          searchVolume: 4189,
          organicRank: 17,
          sponsoredRank: 13,
          keywordSales: 57
        },
        {
          date: '2024-04-21',
          searchVolume: 4189,
          organicRank: 16,
          sponsoredRank: 8,
          keywordSales: 57
        },
        {
          date: '2024-04-20',
          searchVolume: 4189,
          organicRank: 15,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-04-19',
          searchVolume: 4058,
          organicRank: 13,
          sponsoredRank: 9,
          keywordSales: 57
        },
        {
          date: '2024-04-18',
          searchVolume: 4058,
          organicRank: 16,
          sponsoredRank: 9,
          keywordSales: 57
        },
        {
          date: '2024-04-17',
          searchVolume: 4058,
          organicRank: 248,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-16',
          searchVolume: 4058,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-15',
          searchVolume: 4058,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-14',
          searchVolume: 4058,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-13',
          searchVolume: 4058,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-12',
          searchVolume: 4005,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-11',
          searchVolume: 4005,
          organicRank: 30,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-10',
          searchVolume: 4005,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-09',
          searchVolume: 4005,
          organicRank: 14,
          sponsoredRank: 4,
          keywordSales: 57
        },
        {
          date: '2024-04-08',
          searchVolume: 4005,
          organicRank: 20,
          sponsoredRank: 9,
          keywordSales: 57
        },
        {
          date: '2024-04-07',
          searchVolume: 4005,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-06',
          searchVolume: 4005,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-05',
          searchVolume: 4486,
          organicRank: 98,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-04-04',
          searchVolume: 4486,
          organicRank: 116,
          sponsoredRank: 10,
          keywordSales: 57
        },
        {
          date: '2024-04-03',
          searchVolume: 4486,
          organicRank: 115,
          sponsoredRank: 13,
          keywordSales: 57
        },
        {
          date: '2024-04-02',
          searchVolume: 4486,
          organicRank: 130,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-04-01',
          searchVolume: 4486,
          organicRank: 124,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-03-31',
          searchVolume: 4486,
          organicRank: 89,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-03-30',
          searchVolume: 4486,
          organicRank: 148,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-03-29',
          searchVolume: 4410,
          organicRank: 104,
          sponsoredRank: null,
          keywordSales: 57
        },
        {
          date: '2024-03-28',
          searchVolume: 4410,
          organicRank: 93,
          sponsoredRank: 31,
          keywordSales: 57
        },
        {
          date: '2024-03-27',
          searchVolume: 4410,
          organicRank: null,
          sponsoredRank: 33,
          keywordSales: 57
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_keyboard and mouse logitech',
      keyword: 'keyboard and mouse logitech',
      searchVolume: 535,
      organicStartRank: 25,
      organicHighestRank: 13,
      sponsoredStartRank: 42,
      sponsoredHighestRank: 7,
      organicPageOneWinDate: '2024-03-31',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-04-11',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 535,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-29',
          searchVolume: 535,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-28',
          searchVolume: 535,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-27',
          searchVolume: 535,
          organicRank: 40,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-26',
          searchVolume: 638,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-25',
          searchVolume: 638,
          organicRank: 28,
          sponsoredRank: 25,
          keywordSales: 8
        },
        {
          date: '2024-04-24',
          searchVolume: 638,
          organicRank: 26,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-23',
          searchVolume: 638,
          organicRank: 35,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-22',
          searchVolume: 638,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-21',
          searchVolume: 638,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-20',
          searchVolume: 638,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-19',
          searchVolume: 633,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-18',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-17',
          searchVolume: 633,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-16',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: 10,
          keywordSales: 8
        },
        {
          date: '2024-04-15',
          searchVolume: 633,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-14',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-13',
          searchVolume: 633,
          organicRank: 13,
          sponsoredRank: 40,
          keywordSales: 8
        },
        {
          date: '2024-04-12',
          searchVolume: 554,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-11',
          searchVolume: 554,
          organicRank: 21,
          sponsoredRank: 7,
          keywordSales: 8
        },
        {
          date: '2024-04-10',
          searchVolume: 554,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-09',
          searchVolume: 554,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-08',
          searchVolume: 554,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-07',
          searchVolume: 554,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-06',
          searchVolume: 554,
          organicRank: 14,
          sponsoredRank: 42,
          keywordSales: 8
        },
        {
          date: '2024-04-05',
          searchVolume: 662,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-04',
          searchVolume: 662,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-03',
          searchVolume: 662,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-02',
          searchVolume: 662,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-01',
          searchVolume: 662,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-31',
          searchVolume: 662,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-30',
          searchVolume: 662,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-29',
          searchVolume: 654,
          organicRank: 25,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-28',
          searchVolume: 654,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 8
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_keyboard and mouse wireless bluetooth',
      keyword: 'keyboard and mouse wireless bluetooth',
      searchVolume: 431,
      organicStartRank: 120,
      organicHighestRank: 30,
      sponsoredStartRank: 47,
      sponsoredHighestRank: 47,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 431,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-29',
          searchVolume: 431,
          organicRank: 249,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-28',
          searchVolume: 431,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-27',
          searchVolume: 431,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-26',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-25',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-24',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-23',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-22',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-21',
          searchVolume: 443,
          organicRank: 61,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-20',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-19',
          searchVolume: 459,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-18',
          searchVolume: 459,
          organicRank: 60,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-17',
          searchVolume: 459,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-16',
          searchVolume: 459,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-15',
          searchVolume: 459,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-14',
          searchVolume: 459,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-13',
          searchVolume: 459,
          organicRank: 30,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-12',
          searchVolume: 366,
          organicRank: null,
          sponsoredRank: 47,
          keywordSales: 5
        },
        {
          date: '2024-04-11',
          searchVolume: 366,
          organicRank: 70,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-10',
          searchVolume: 366,
          organicRank: 45,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-09',
          searchVolume: 366,
          organicRank: 55,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-08',
          searchVolume: 366,
          organicRank: 60,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-07',
          searchVolume: 366,
          organicRank: 72,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-06',
          searchVolume: 366,
          organicRank: 91,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-05',
          searchVolume: 459,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-04',
          searchVolume: 459,
          organicRank: 54,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-03',
          searchVolume: 459,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-02',
          searchVolume: 459,
          organicRank: 109,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-01',
          searchVolume: 459,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-03-31',
          searchVolume: 459,
          organicRank: 100,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-03-30',
          searchVolume: 459,
          organicRank: 120,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-03-29',
          searchVolume: 436,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-03-28',
          searchVolume: 436,
          organicRank: 108,
          sponsoredRank: null,
          keywordSales: 5
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_keyboard and mouse wireless logitech',
      keyword: 'keyboard and mouse wireless logitech',
      searchVolume: 2433,
      organicStartRank: 45,
      organicHighestRank: 7,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-04-03',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-29',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 2433,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 26
        },
        {
          date: '2024-04-30',
          searchVolume: 2433,
          organicRank: 16,
          sponsoredRank: 3,
          keywordSales: 26
        },
        {
          date: '2024-04-29',
          searchVolume: 2433,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 26
        },
        {
          date: '2024-04-28',
          searchVolume: 2433,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 26
        },
        {
          date: '2024-04-27',
          searchVolume: 2433,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-26',
          searchVolume: 2468,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-04-25',
          searchVolume: 2468,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-24',
          searchVolume: 2468,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-04-23',
          searchVolume: 2468,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-22',
          searchVolume: 2468,
          organicRank: 11,
          sponsoredRank: 4,
          keywordSales: 26
        },
        {
          date: '2024-04-21',
          searchVolume: 2468,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-20',
          searchVolume: 2468,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 26
        },
        {
          date: '2024-04-19',
          searchVolume: 2113,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-18',
          searchVolume: 2113,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-17',
          searchVolume: 2113,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 26
        },
        {
          date: '2024-04-16',
          searchVolume: 2113,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-15',
          searchVolume: 2113,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 26
        },
        {
          date: '2024-04-14',
          searchVolume: 2113,
          organicRank: 8,
          sponsoredRank: 4,
          keywordSales: 26
        },
        {
          date: '2024-04-13',
          searchVolume: 2113,
          organicRank: 10,
          sponsoredRank: 10,
          keywordSales: 26
        },
        {
          date: '2024-04-12',
          searchVolume: 2354,
          organicRank: 10,
          sponsoredRank: 11,
          keywordSales: 26
        },
        {
          date: '2024-04-11',
          searchVolume: 2354,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-10',
          searchVolume: 2354,
          organicRank: 9,
          sponsoredRank: 24,
          keywordSales: 26
        },
        {
          date: '2024-04-09',
          searchVolume: 2354,
          organicRank: 10,
          sponsoredRank: 15,
          keywordSales: 26
        },
        {
          date: '2024-04-08',
          searchVolume: 2354,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 26
        },
        {
          date: '2024-04-07',
          searchVolume: 2354,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-06',
          searchVolume: 2354,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-05',
          searchVolume: 2356,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 26
        },
        {
          date: '2024-04-04',
          searchVolume: 2356,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-04-03',
          searchVolume: 2356,
          organicRank: 13,
          sponsoredRank: 6,
          keywordSales: 26
        },
        {
          date: '2024-04-02',
          searchVolume: 2356,
          organicRank: 22,
          sponsoredRank: 3,
          keywordSales: 26
        },
        {
          date: '2024-04-01',
          searchVolume: 2356,
          organicRank: 36,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-03-31',
          searchVolume: 2356,
          organicRank: 35,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-03-30',
          searchVolume: 2356,
          organicRank: 43,
          sponsoredRank: 9,
          keywordSales: 26
        },
        {
          date: '2024-03-29',
          searchVolume: 2472,
          organicRank: 45,
          sponsoredRank: 11,
          keywordSales: 26
        },
        {
          date: '2024-03-28',
          searchVolume: 2472,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: 26
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_keyboard and wireless mouse',
      keyword: 'keyboard and wireless mouse',
      searchVolume: 401,
      organicStartRank: 53,
      organicHighestRank: 53,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 401,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-29',
          searchVolume: 401,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-28',
          searchVolume: 401,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-27',
          searchVolume: 401,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-26',
          searchVolume: 366,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-25',
          searchVolume: 366,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-24',
          searchVolume: 366,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-23',
          searchVolume: 366,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-22',
          searchVolume: 366,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-21',
          searchVolume: 366,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-20',
          searchVolume: 366,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-19',
          searchVolume: 440,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-18',
          searchVolume: 440,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-17',
          searchVolume: 440,
          organicRank: 236,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-16',
          searchVolume: 440,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-15',
          searchVolume: 440,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-14',
          searchVolume: 440,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-13',
          searchVolume: 440,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-12',
          searchVolume: 390,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-11',
          searchVolume: 390,
          organicRank: 244,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-10',
          searchVolume: 390,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-09',
          searchVolume: 390,
          organicRank: 254,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-08',
          searchVolume: 390,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-07',
          searchVolume: 390,
          organicRank: 80,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-06',
          searchVolume: 390,
          organicRank: 76,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-05',
          searchVolume: 467,
          organicRank: 81,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-04',
          searchVolume: 467,
          organicRank: 94,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-03',
          searchVolume: 467,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-02',
          searchVolume: 467,
          organicRank: 56,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-01',
          searchVolume: 467,
          organicRank: 252,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-31',
          searchVolume: 467,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-30',
          searchVolume: 467,
          organicRank: 53,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-29',
          searchVolume: 434,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_keyboard combo',
      keyword: 'keyboard combo',
      searchVolume: 392,
      organicStartRank: 239,
      organicHighestRank: 18,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: '2024-04-12',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-29',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-28',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-27',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-26',
          searchVolume: 391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-25',
          searchVolume: 391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-24',
          searchVolume: 391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-23',
          searchVolume: 391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-22',
          searchVolume: 391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-21',
          searchVolume: 391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-20',
          searchVolume: 391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-19',
          searchVolume: 428,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-18',
          searchVolume: 428,
          organicRank: 218,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-17',
          searchVolume: 428,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-16',
          searchVolume: 428,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-15',
          searchVolume: 428,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-14',
          searchVolume: 428,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-13',
          searchVolume: 428,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-12',
          searchVolume: 376,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-11',
          searchVolume: 376,
          organicRank: 39,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-10',
          searchVolume: 376,
          organicRank: 43,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-09',
          searchVolume: 376,
          organicRank: 46,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-08',
          searchVolume: 376,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-07',
          searchVolume: 376,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-06',
          searchVolume: 376,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-05',
          searchVolume: 364,
          organicRank: 35,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-04',
          searchVolume: 364,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-03',
          searchVolume: 364,
          organicRank: 71,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-02',
          searchVolume: 364,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-01',
          searchVolume: 364,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-31',
          searchVolume: 364,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-30',
          searchVolume: 364,
          organicRank: 239,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-29',
          searchVolume: 359,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_keyboard logitech',
      keyword: 'keyboard logitech',
      searchVolume: 2984,
      organicStartRank: 76,
      organicHighestRank: 76,
      sponsoredStartRank: 34,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-11',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 2984,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-29',
          searchVolume: 2984,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-28',
          searchVolume: 2984,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-27',
          searchVolume: 2984,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-26',
          searchVolume: 2729,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-25',
          searchVolume: 2729,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-24',
          searchVolume: 2729,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-23',
          searchVolume: 2729,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-22',
          searchVolume: 2729,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-21',
          searchVolume: 2729,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-20',
          searchVolume: 2729,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-19',
          searchVolume: 2643,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-18',
          searchVolume: 2643,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-17',
          searchVolume: 2643,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-16',
          searchVolume: 2643,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-15',
          searchVolume: 2643,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-14',
          searchVolume: 2643,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-13',
          searchVolume: 2643,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 17
        },
        {
          date: '2024-04-12',
          searchVolume: 2987,
          organicRank: null,
          sponsoredRank: 17,
          keywordSales: 17
        },
        {
          date: '2024-04-11',
          searchVolume: 2987,
          organicRank: null,
          sponsoredRank: 15,
          keywordSales: 17
        },
        {
          date: '2024-04-10',
          searchVolume: 2987,
          organicRank: null,
          sponsoredRank: 57,
          keywordSales: 17
        },
        {
          date: '2024-04-09',
          searchVolume: 2987,
          organicRank: null,
          sponsoredRank: 34,
          keywordSales: 17
        },
        {
          date: '2024-04-08',
          searchVolume: 2987,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-07',
          searchVolume: 2987,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-06',
          searchVolume: 2987,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-05',
          searchVolume: 3078,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-04',
          searchVolume: 3078,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-03',
          searchVolume: 3078,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-02',
          searchVolume: 3078,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-04-01',
          searchVolume: 3078,
          organicRank: 107,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-03-31',
          searchVolume: 3078,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-03-30',
          searchVolume: 3078,
          organicRank: 89,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-03-29',
          searchVolume: 3140,
          organicRank: 76,
          sponsoredRank: null,
          keywordSales: 17
        },
        {
          date: '2024-03-28',
          searchVolume: 3140,
          organicRank: 38,
          sponsoredRank: null,
          keywordSales: 17
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_keyboard mouse',
      keyword: 'keyboard mouse',
      searchVolume: 2286,
      organicStartRank: 252,
      organicHighestRank: 199,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-04',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 2286,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-29',
          searchVolume: 2286,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-28',
          searchVolume: 2286,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-27',
          searchVolume: 2286,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-26',
          searchVolume: 2119,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-25',
          searchVolume: 2119,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-24',
          searchVolume: 2119,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-23',
          searchVolume: 2119,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-22',
          searchVolume: 2119,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-21',
          searchVolume: 2119,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-20',
          searchVolume: 2119,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-19',
          searchVolume: 2280,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-18',
          searchVolume: 2280,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-17',
          searchVolume: 2280,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-16',
          searchVolume: 2280,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-15',
          searchVolume: 2280,
          organicRank: 209,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-14',
          searchVolume: 2280,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-13',
          searchVolume: 2280,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-12',
          searchVolume: 2353,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-11',
          searchVolume: 2353,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-10',
          searchVolume: 2353,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-09',
          searchVolume: 2353,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-04-08',
          searchVolume: 2353,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-07',
          searchVolume: 2353,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-06',
          searchVolume: 2353,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-05',
          searchVolume: 2317,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-04-04',
          searchVolume: 2317,
          organicRank: 199,
          sponsoredRank: 2,
          keywordSales: 34
        },
        {
          date: '2024-04-03',
          searchVolume: 2317,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-02',
          searchVolume: 2317,
          organicRank: 252,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-04-01',
          searchVolume: 2317,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-03-31',
          searchVolume: 2317,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-03-30',
          searchVolume: 2317,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-03-29',
          searchVolume: 2355,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 34
        },
        {
          date: '2024-03-28',
          searchVolume: 2355,
          organicRank: 81,
          sponsoredRank: null,
          keywordSales: 34
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_keyboard mouse combo wireless',
      keyword: 'keyboard mouse combo wireless',
      searchVolume: 312,
      organicStartRank: 211,
      organicHighestRank: 12,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: '2024-04-14',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 312,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-29',
          searchVolume: 312,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-28',
          searchVolume: 312,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-27',
          searchVolume: 312,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-26',
          searchVolume: 371,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-25',
          searchVolume: 371,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-24',
          searchVolume: 371,
          organicRank: 246,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-23',
          searchVolume: 371,
          organicRank: 242,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-22',
          searchVolume: 371,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-21',
          searchVolume: 371,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-20',
          searchVolume: 371,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-19',
          searchVolume: 380,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-18',
          searchVolume: 380,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-17',
          searchVolume: 380,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-16',
          searchVolume: 380,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-15',
          searchVolume: 380,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-14',
          searchVolume: 380,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-13',
          searchVolume: 380,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-12',
          searchVolume: 366,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-11',
          searchVolume: 366,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-10',
          searchVolume: 366,
          organicRank: 25,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-09',
          searchVolume: 366,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-08',
          searchVolume: 366,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-07',
          searchVolume: 366,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-06',
          searchVolume: 366,
          organicRank: 34,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-05',
          searchVolume: 664,
          organicRank: 47,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-04',
          searchVolume: 664,
          organicRank: 49,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-03',
          searchVolume: 664,
          organicRank: 68,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-02',
          searchVolume: 664,
          organicRank: 60,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-04-01',
          searchVolume: 664,
          organicRank: 67,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-03-31',
          searchVolume: 664,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-03-30',
          searchVolume: 664,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-03-29',
          searchVolume: 654,
          organicRank: 211,
          sponsoredRank: null,
          keywordSales: 5
        },
        {
          date: '2024-03-28',
          searchVolume: 654,
          organicRank: 72,
          sponsoredRank: null,
          keywordSales: 5
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logi keyboard and mouse',
      keyword: 'logi keyboard and mouse',
      searchVolume: 309,
      organicStartRank: 25,
      organicHighestRank: 10,
      sponsoredStartRank: 48,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-03-31',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-31',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 309,
          organicRank: 23,
          sponsoredRank: 7,
          keywordSales: 3
        },
        {
          date: '2024-04-29',
          searchVolume: 309,
          organicRank: 23,
          sponsoredRank: 3,
          keywordSales: 3
        },
        {
          date: '2024-04-28',
          searchVolume: 309,
          organicRank: 22,
          sponsoredRank: 4,
          keywordSales: 3
        },
        {
          date: '2024-04-27',
          searchVolume: 309,
          organicRank: 23,
          sponsoredRank: 6,
          keywordSales: 3
        },
        {
          date: '2024-04-26',
          searchVolume: 364,
          organicRank: 23,
          sponsoredRank: 4,
          keywordSales: 3
        },
        {
          date: '2024-04-25',
          searchVolume: 364,
          organicRank: 25,
          sponsoredRank: 4,
          keywordSales: 3
        },
        {
          date: '2024-04-24',
          searchVolume: 364,
          organicRank: 23,
          sponsoredRank: 7,
          keywordSales: 3
        },
        {
          date: '2024-04-23',
          searchVolume: 364,
          organicRank: 25,
          sponsoredRank: 4,
          keywordSales: 3
        },
        {
          date: '2024-04-22',
          searchVolume: 364,
          organicRank: 22,
          sponsoredRank: 5,
          keywordSales: 3
        },
        {
          date: '2024-04-21',
          searchVolume: 364,
          organicRank: 25,
          sponsoredRank: null,
          keywordSales: 3
        },
        {
          date: '2024-04-20',
          searchVolume: 364,
          organicRank: 22,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-04-19',
          searchVolume: 238,
          organicRank: 22,
          sponsoredRank: 6,
          keywordSales: 3
        },
        {
          date: '2024-04-18',
          searchVolume: 238,
          organicRank: 26,
          sponsoredRank: 16,
          keywordSales: 3
        },
        {
          date: '2024-04-17',
          searchVolume: 238,
          organicRank: null,
          sponsoredRank: 3,
          keywordSales: 3
        },
        {
          date: '2024-04-16',
          searchVolume: 238,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-04-15',
          searchVolume: 238,
          organicRank: 14,
          sponsoredRank: 3,
          keywordSales: 3
        },
        {
          date: '2024-04-14',
          searchVolume: 238,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 3
        },
        {
          date: '2024-04-13',
          searchVolume: 238,
          organicRank: 18,
          sponsoredRank: 28,
          keywordSales: 3
        },
        {
          date: '2024-04-12',
          searchVolume: 279,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3
        },
        {
          date: '2024-04-11',
          searchVolume: 279,
          organicRank: 19,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-04-10',
          searchVolume: 279,
          organicRank: 15,
          sponsoredRank: 6,
          keywordSales: 3
        },
        {
          date: '2024-04-09',
          searchVolume: 279,
          organicRank: null,
          sponsoredRank: 12,
          keywordSales: 3
        },
        {
          date: '2024-04-08',
          searchVolume: 279,
          organicRank: 19,
          sponsoredRank: 9,
          keywordSales: 3
        },
        {
          date: '2024-04-07',
          searchVolume: 279,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-04-06',
          searchVolume: 279,
          organicRank: 18,
          sponsoredRank: 4,
          keywordSales: 3
        },
        {
          date: '2024-04-05',
          searchVolume: 355,
          organicRank: 15,
          sponsoredRank: 3,
          keywordSales: 3
        },
        {
          date: '2024-04-04',
          searchVolume: 355,
          organicRank: 20,
          sponsoredRank: 3,
          keywordSales: 3
        },
        {
          date: '2024-04-03',
          searchVolume: 355,
          organicRank: 15,
          sponsoredRank: 8,
          keywordSales: 3
        },
        {
          date: '2024-04-02',
          searchVolume: 355,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 3
        },
        {
          date: '2024-04-01',
          searchVolume: 355,
          organicRank: 21,
          sponsoredRank: 3,
          keywordSales: 3
        },
        {
          date: '2024-03-31',
          searchVolume: 355,
          organicRank: 16,
          sponsoredRank: 4,
          keywordSales: 3
        },
        {
          date: '2024-03-30',
          searchVolume: 355,
          organicRank: 23,
          sponsoredRank: 23,
          keywordSales: 3
        },
        {
          date: '2024-03-29',
          searchVolume: 321,
          organicRank: 25,
          sponsoredRank: 48,
          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logi wireless keyboard',
      keyword: 'logi wireless keyboard',
      searchVolume: 449,
      organicStartRank: 43,
      organicHighestRank: 43,
      sponsoredStartRank: 58,
      sponsoredHighestRank: 13,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-16',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 449,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-29',
          searchVolume: 449,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-28',
          searchVolume: 449,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-27',
          searchVolume: 449,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-26',
          searchVolume: 441,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-25',
          searchVolume: 441,
          organicRank: null,
          sponsoredRank: 66,
          keywordSales: 6
        },
        {
          date: '2024-04-24',
          searchVolume: 441,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-23',
          searchVolume: 441,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-22',
          searchVolume: 441,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-21',
          searchVolume: 441,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-20',
          searchVolume: 441,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-19',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-18',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: 52,
          keywordSales: 6
        },
        {
          date: '2024-04-17',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-16',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: 13,
          keywordSales: 6
        },
        {
          date: '2024-04-15',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-14',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-13',
          searchVolume: 443,
          organicRank: null,
          sponsoredRank: 48,
          keywordSales: 6
        },
        {
          date: '2024-04-12',
          searchVolume: 422,
          organicRank: null,
          sponsoredRank: 22,
          keywordSales: 6
        },
        {
          date: '2024-04-11',
          searchVolume: 422,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-10',
          searchVolume: 422,
          organicRank: null,
          sponsoredRank: 42,
          keywordSales: 6
        },
        {
          date: '2024-04-09',
          searchVolume: 422,
          organicRank: null,
          sponsoredRank: 58,
          keywordSales: 6
        },
        {
          date: '2024-04-08',
          searchVolume: 422,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-07',
          searchVolume: 422,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-06',
          searchVolume: 422,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-05',
          searchVolume: 454,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-04',
          searchVolume: 454,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-03',
          searchVolume: 454,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-02',
          searchVolume: 454,
          organicRank: 68,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-04-01',
          searchVolume: 454,
          organicRank: 63,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-03-31',
          searchVolume: 454,
          organicRank: 53,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-03-30',
          searchVolume: 454,
          organicRank: 61,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-03-29',
          searchVolume: 405,
          organicRank: 43,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-03-28',
          searchVolume: 405,
          organicRank: 51,
          sponsoredRank: null,
          keywordSales: 6
        },
        {
          date: '2024-03-27',
          searchVolume: 405,
          organicRank: 69,
          sponsoredRank: null,
          keywordSales: 6
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech bluetooth keyboard',
      keyword: 'logitech bluetooth keyboard',
      searchVolume: 2994,
      organicStartRank: 43,
      organicHighestRank: 43,
      sponsoredStartRank: 63,
      sponsoredHighestRank: 6,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-11',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 2994,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-30',
          searchVolume: 2994,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-29',
          searchVolume: 2994,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-28',
          searchVolume: 2994,
          organicRank: null,
          sponsoredRank: 47,
          keywordSales: 35
        },
        {
          date: '2024-04-27',
          searchVolume: 2994,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-26',
          searchVolume: 3046,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-25',
          searchVolume: 3046,
          organicRank: null,
          sponsoredRank: 32,
          keywordSales: 35
        },
        {
          date: '2024-04-24',
          searchVolume: 3046,
          organicRank: null,
          sponsoredRank: 14,
          keywordSales: 35
        },
        {
          date: '2024-04-23',
          searchVolume: 3046,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-22',
          searchVolume: 3046,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-21',
          searchVolume: 3046,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-20',
          searchVolume: 3046,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-19',
          searchVolume: 3220,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-18',
          searchVolume: 3220,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-17',
          searchVolume: 3220,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-16',
          searchVolume: 3220,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-15',
          searchVolume: 3220,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-14',
          searchVolume: 3220,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-13',
          searchVolume: 3220,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-12',
          searchVolume: 3349,
          organicRank: null,
          sponsoredRank: 6,
          keywordSales: 35
        },
        {
          date: '2024-04-11',
          searchVolume: 3349,
          organicRank: null,
          sponsoredRank: 7,
          keywordSales: 35
        },
        {
          date: '2024-04-10',
          searchVolume: 3349,
          organicRank: null,
          sponsoredRank: 41,
          keywordSales: 35
        },
        {
          date: '2024-04-09',
          searchVolume: 3349,
          organicRank: null,
          sponsoredRank: 22,
          keywordSales: 35
        },
        {
          date: '2024-04-08',
          searchVolume: 3349,
          organicRank: null,
          sponsoredRank: 29,
          keywordSales: 35
        },
        {
          date: '2024-04-07',
          searchVolume: 3349,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-06',
          searchVolume: 3349,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-05',
          searchVolume: 3391,
          organicRank: null,
          sponsoredRank: 63,
          keywordSales: 35
        },
        {
          date: '2024-04-04',
          searchVolume: 3391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-03',
          searchVolume: 3391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-02',
          searchVolume: 3391,
          organicRank: 72,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-04-01',
          searchVolume: 3391,
          organicRank: 73,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-03-31',
          searchVolume: 3391,
          organicRank: 73,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-03-30',
          searchVolume: 3391,
          organicRank: 70,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-03-29',
          searchVolume: 3509,
          organicRank: 43,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-03-28',
          searchVolume: 3509,
          organicRank: 36,
          sponsoredRank: null,
          keywordSales: 35
        },
        {
          date: '2024-03-27',
          searchVolume: 3509,
          organicRank: 217,
          sponsoredRank: null,
          keywordSales: 35
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech bluetooth keyboard and mouse combo',
      keyword: 'logitech bluetooth keyboard and mouse combo',
      searchVolume: 1365,
      organicStartRank: 17,
      organicHighestRank: 2,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 29,
      organicPageOneWinDate: '2024-03-29',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 1365,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-29',
          searchVolume: 1365,
          organicRank: 6,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-28',
          searchVolume: 1365,
          organicRank: 4,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-27',
          searchVolume: 1365,
          organicRank: 2,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-26',
          searchVolume: 1183,
          organicRank: 4,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-25',
          searchVolume: 1183,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-24',
          searchVolume: 1183,
          organicRank: 5,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-23',
          searchVolume: 1183,
          organicRank: 8,
          sponsoredRank: 41,
          keywordSales: 29
        },
        {
          date: '2024-04-22',
          searchVolume: 1183,
          organicRank: 4,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-21',
          searchVolume: 1183,
          organicRank: 4,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-20',
          searchVolume: 1183,
          organicRank: 2,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-19',
          searchVolume: 1191,
          organicRank: 2,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-18',
          searchVolume: 1191,
          organicRank: 5,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-17',
          searchVolume: 1191,
          organicRank: 6,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-16',
          searchVolume: 1191,
          organicRank: 8,
          sponsoredRank: 29,
          keywordSales: 29
        },
        {
          date: '2024-04-15',
          searchVolume: 1191,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-14',
          searchVolume: 1191,
          organicRank: 4,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-13',
          searchVolume: 1191,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-12',
          searchVolume: 1321,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-11',
          searchVolume: 1321,
          organicRank: 6,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-10',
          searchVolume: 1321,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-09',
          searchVolume: 1321,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-08',
          searchVolume: 1321,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-07',
          searchVolume: 1321,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-06',
          searchVolume: 1321,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-05',
          searchVolume: 1381,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-04',
          searchVolume: 1381,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-03',
          searchVolume: 1381,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-02',
          searchVolume: 1381,
          organicRank: 6,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-04-01',
          searchVolume: 1381,
          organicRank: 6,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-03-31',
          searchVolume: 1381,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-03-30',
          searchVolume: 1381,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-03-29',
          searchVolume: 1370,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-03-28',
          searchVolume: 1370,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 29
        },
        {
          date: '2024-03-27',
          searchVolume: 1370,
          organicRank: 183,
          sponsoredRank: null,
          keywordSales: 29
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech bluetooth mouse',
      keyword: 'logitech bluetooth mouse',
      searchVolume: 5651,
      organicStartRank: null,
      organicHighestRank: null,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 31,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 5651,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-30',
          searchVolume: 5651,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-29',
          searchVolume: 5651,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-28',
          searchVolume: 5651,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-27',
          searchVolume: 5651,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-26',
          searchVolume: 5814,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-25',
          searchVolume: 5814,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-24',
          searchVolume: 5814,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-23',
          searchVolume: 5814,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-22',
          searchVolume: 5814,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-21',
          searchVolume: 5814,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-20',
          searchVolume: 5814,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-19',
          searchVolume: 5590,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-18',
          searchVolume: 5590,
          organicRank: null,
          sponsoredRank: 58,
          keywordSales: 89
        },
        {
          date: '2024-04-17',
          searchVolume: 5590,
          organicRank: null,
          sponsoredRank: 57,
          keywordSales: 89
        },
        {
          date: '2024-04-16',
          searchVolume: 5590,
          organicRank: null,
          sponsoredRank: 51,
          keywordSales: 89
        },
        {
          date: '2024-04-15',
          searchVolume: 5590,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-14',
          searchVolume: 5590,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-13',
          searchVolume: 5590,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-12',
          searchVolume: 5533,
          organicRank: null,
          sponsoredRank: 70,
          keywordSales: 89
        },
        {
          date: '2024-04-11',
          searchVolume: 5533,
          organicRank: null,
          sponsoredRank: 31,
          keywordSales: 89
        },
        {
          date: '2024-04-10',
          searchVolume: 5533,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-09',
          searchVolume: 5533,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-08',
          searchVolume: 5533,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-07',
          searchVolume: 5533,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-06',
          searchVolume: 5533,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-05',
          searchVolume: 5828,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-04',
          searchVolume: 5828,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-03',
          searchVolume: 5828,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-02',
          searchVolume: 5828,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-04-01',
          searchVolume: 5828,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-03-31',
          searchVolume: 5828,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-03-30',
          searchVolume: 5828,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        },
        {
          date: '2024-03-29',
          searchVolume: 5955,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 89
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech cordless keyboard and mouse combo',
      keyword: 'logitech cordless keyboard and mouse combo',
      searchVolume: 355,
      organicStartRank: 28,
      organicHighestRank: 9,
      sponsoredStartRank: 49,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-04-07',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-31',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 355,
          organicRank: 21,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-29',
          searchVolume: 355,
          organicRank: 16,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-04-28',
          searchVolume: 355,
          organicRank: null,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-04-27',
          searchVolume: 355,
          organicRank: 18,
          sponsoredRank: 5,
          keywordSales: 7
        },
        {
          date: '2024-04-26',
          searchVolume: 263,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-25',
          searchVolume: 263,
          organicRank: 26,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-04-24',
          searchVolume: 263,
          organicRank: 23,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-04-23',
          searchVolume: 263,
          organicRank: 21,
          sponsoredRank: 6,
          keywordSales: 7
        },
        {
          date: '2024-04-22',
          searchVolume: 263,
          organicRank: 24,
          sponsoredRank: 6,
          keywordSales: 7
        },
        {
          date: '2024-04-21',
          searchVolume: 263,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-20',
          searchVolume: 263,
          organicRank: 13,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-04-19',
          searchVolume: 380,
          organicRank: 13,
          sponsoredRank: 4,
          keywordSales: 7
        },
        {
          date: '2024-04-18',
          searchVolume: 380,
          organicRank: 24,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-17',
          searchVolume: 380,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-16',
          searchVolume: 380,
          organicRank: 14,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-15',
          searchVolume: 380,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-04-14',
          searchVolume: 380,
          organicRank: 9,
          sponsoredRank: 17,
          keywordSales: 7
        },
        {
          date: '2024-04-13',
          searchVolume: 380,
          organicRank: 11,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-04-12',
          searchVolume: 352,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-11',
          searchVolume: 352,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-10',
          searchVolume: 352,
          organicRank: 13,
          sponsoredRank: 4,
          keywordSales: 7
        },
        {
          date: '2024-04-09',
          searchVolume: 352,
          organicRank: 16,
          sponsoredRank: 7,
          keywordSales: 7
        },
        {
          date: '2024-04-08',
          searchVolume: 352,
          organicRank: 24,
          sponsoredRank: 4,
          keywordSales: 7
        },
        {
          date: '2024-04-07',
          searchVolume: 352,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-04-06',
          searchVolume: 352,
          organicRank: 26,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-04-05',
          searchVolume: 252,
          organicRank: 26,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-04-04',
          searchVolume: 252,
          organicRank: 50,
          sponsoredRank: 4,
          keywordSales: 7
        },
        {
          date: '2024-04-03',
          searchVolume: 252,
          organicRank: 39,
          sponsoredRank: 28,
          keywordSales: 7
        },
        {
          date: '2024-04-02',
          searchVolume: 252,
          organicRank: 73,
          sponsoredRank: 9,
          keywordSales: 7
        },
        {
          date: '2024-04-01',
          searchVolume: 252,
          organicRank: 72,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-03-31',
          searchVolume: 252,
          organicRank: 45,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-03-30',
          searchVolume: 252,
          organicRank: 63,
          sponsoredRank: 30,
          keywordSales: 7
        },
        {
          date: '2024-03-29',
          searchVolume: 359,
          organicRank: 28,
          sponsoredRank: 49,
          keywordSales: 7
        },
        {
          date: '2024-03-27',
          searchVolume: 359,
          organicRank: 192,
          sponsoredRank: null,
          keywordSales: 7
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech keyboard',
      keyword: 'logitech keyboard',
      searchVolume: 51275,
      organicStartRank: 75,
      organicHighestRank: 75,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 18,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-10',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 51275,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-30',
          searchVolume: 51275,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-29',
          searchVolume: 51275,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-28',
          searchVolume: 51275,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-27',
          searchVolume: 51275,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-26',
          searchVolume: 52028,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-25',
          searchVolume: 52028,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-24',
          searchVolume: 52028,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-23',
          searchVolume: 52028,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-22',
          searchVolume: 52028,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-21',
          searchVolume: 52028,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-20',
          searchVolume: 52028,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-19',
          searchVolume: 49492,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-18',
          searchVolume: 49492,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-17',
          searchVolume: 49492,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-16',
          searchVolume: 49492,
          organicRank: null,
          sponsoredRank: 47,
          keywordSales: 514
        },
        {
          date: '2024-04-14',
          searchVolume: 49492,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-13',
          searchVolume: 49492,
          organicRank: null,
          sponsoredRank: 27,
          keywordSales: 514
        },
        {
          date: '2024-04-12',
          searchVolume: 52235,
          organicRank: null,
          sponsoredRank: 32,
          keywordSales: 514
        },
        {
          date: '2024-04-11',
          searchVolume: 52235,
          organicRank: null,
          sponsoredRank: 37,
          keywordSales: 514
        },
        {
          date: '2024-04-10',
          searchVolume: 52235,
          organicRank: null,
          sponsoredRank: 18,
          keywordSales: 514
        },
        {
          date: '2024-04-09',
          searchVolume: 52235,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-08',
          searchVolume: 52235,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-07',
          searchVolume: 52235,
          organicRank: null,
          sponsoredRank: 64,
          keywordSales: 514
        },
        {
          date: '2024-04-06',
          searchVolume: 52235,
          organicRank: null,
          sponsoredRank: 39,
          keywordSales: 514
        },
        {
          date: '2024-04-05',
          searchVolume: 55693,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-04',
          searchVolume: 55693,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-03',
          searchVolume: 55693,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-02',
          searchVolume: 55693,
          organicRank: 95,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-04-01',
          searchVolume: 55693,
          organicRank: 89,
          sponsoredRank: 28,
          keywordSales: 514
        },
        {
          date: '2024-03-31',
          searchVolume: 55693,
          organicRank: 89,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-03-30',
          searchVolume: 55693,
          organicRank: 84,
          sponsoredRank: 23,
          keywordSales: 514
        },
        {
          date: '2024-03-29',
          searchVolume: 61922,
          organicRank: 75,
          sponsoredRank: null,
          keywordSales: 514
        },
        {
          date: '2024-03-28',
          searchVolume: 61922,
          organicRank: 53,
          sponsoredRank: null,
          keywordSales: 514
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech keyboard and mouse combo',
      keyword: 'logitech keyboard and mouse combo',
      searchVolume: 40830,
      organicStartRank: 20,
      organicHighestRank: 9,
      sponsoredStartRank: 45,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-03-29',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-30',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 40830,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-30',
          searchVolume: 40830,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-29',
          searchVolume: 40830,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-28',
          searchVolume: 40830,
          organicRank: 18,
          sponsoredRank: 9,
          keywordSales: 843
        },
        {
          date: '2024-04-27',
          searchVolume: 40830,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-26',
          searchVolume: 48782,
          organicRank: 29,
          sponsoredRank: 11,
          keywordSales: 843
        },
        {
          date: '2024-04-25',
          searchVolume: 48782,
          organicRank: 27,
          sponsoredRank: 6,
          keywordSales: 843
        },
        {
          date: '2024-04-24',
          searchVolume: 48782,
          organicRank: 26,
          sponsoredRank: 11,
          keywordSales: 843
        },
        {
          date: '2024-04-23',
          searchVolume: 48782,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-22',
          searchVolume: 48782,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-21',
          searchVolume: 48782,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-20',
          searchVolume: 48782,
          organicRank: 24,
          sponsoredRank: 4,
          keywordSales: 843
        },
        {
          date: '2024-04-19',
          searchVolume: 49472,
          organicRank: 25,
          sponsoredRank: 11,
          keywordSales: 843
        },
        {
          date: '2024-04-18',
          searchVolume: 49472,
          organicRank: 23,
          sponsoredRank: 22,
          keywordSales: 843
        },
        {
          date: '2024-04-17',
          searchVolume: 49472,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 843
        },
        {
          date: '2024-04-16',
          searchVolume: 49472,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-15',
          searchVolume: 49472,
          organicRank: 12,
          sponsoredRank: 4,
          keywordSales: 843
        },
        {
          date: '2024-04-14',
          searchVolume: 49472,
          organicRank: 14,
          sponsoredRank: 25,
          keywordSales: 843
        },
        {
          date: '2024-04-13',
          searchVolume: 49472,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 843
        },
        {
          date: '2024-04-12',
          searchVolume: 52118,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-11',
          searchVolume: 52118,
          organicRank: null,
          sponsoredRank: 3,
          keywordSales: 843
        },
        {
          date: '2024-04-10',
          searchVolume: 52118,
          organicRank: null,
          sponsoredRank: 5,
          keywordSales: 843
        },
        {
          date: '2024-04-09',
          searchVolume: 52118,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 843
        },
        {
          date: '2024-04-08',
          searchVolume: 52118,
          organicRank: 16,
          sponsoredRank: 4,
          keywordSales: 843
        },
        {
          date: '2024-04-07',
          searchVolume: 52118,
          organicRank: 15,
          sponsoredRank: 4,
          keywordSales: 843
        },
        {
          date: '2024-04-06',
          searchVolume: 52118,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 843
        },
        {
          date: '2024-04-05',
          searchVolume: 52852,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 843
        },
        {
          date: '2024-04-04',
          searchVolume: 52852,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 843
        },
        {
          date: '2024-04-03',
          searchVolume: 52852,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 843
        },
        {
          date: '2024-04-02',
          searchVolume: 52852,
          organicRank: 11,
          sponsoredRank: 4,
          keywordSales: 843
        },
        {
          date: '2024-04-01',
          searchVolume: 52852,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 843
        },
        {
          date: '2024-03-31',
          searchVolume: 52852,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 843
        },
        {
          date: '2024-03-30',
          searchVolume: 52852,
          organicRank: 14,
          sponsoredRank: 17,
          keywordSales: 843
        },
        {
          date: '2024-03-29',
          searchVolume: 51966,
          organicRank: 20,
          sponsoredRank: 45,
          keywordSales: 843
        },
        {
          date: '2024-03-28',
          searchVolume: 51966,
          organicRank: 49,
          sponsoredRank: 70,
          keywordSales: 843
        },
        {
          date: '2024-03-27',
          searchVolume: 51966,
          organicRank: 162,
          sponsoredRank: null,
          keywordSales: 843
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech keyboard and mouse combo wired',
      keyword: 'logitech keyboard and mouse combo wired',
      searchVolume: 413,
      organicStartRank: 42,
      organicHighestRank: 12,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: '2024-04-06',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 413,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-29',
          searchVolume: 413,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-28',
          searchVolume: 413,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-27',
          searchVolume: 413,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-26',
          searchVolume: 411,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-25',
          searchVolume: 411,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-24',
          searchVolume: 411,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-23',
          searchVolume: 411,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-22',
          searchVolume: 411,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-21',
          searchVolume: 411,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-20',
          searchVolume: 411,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-19',
          searchVolume: 419,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-18',
          searchVolume: 419,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-17',
          searchVolume: 419,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-16',
          searchVolume: 419,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-15',
          searchVolume: 419,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-14',
          searchVolume: 419,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-13',
          searchVolume: 419,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-12',
          searchVolume: 421,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-11',
          searchVolume: 421,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-10',
          searchVolume: 421,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-09',
          searchVolume: 421,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-08',
          searchVolume: 421,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-07',
          searchVolume: 421,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-06',
          searchVolume: 421,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-05',
          searchVolume: 364,
          organicRank: 32,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-04',
          searchVolume: 364,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-03',
          searchVolume: 364,
          organicRank: 25,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-02',
          searchVolume: 364,
          organicRank: 57,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-04-01',
          searchVolume: 364,
          organicRank: 52,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-03-31',
          searchVolume: 364,
          organicRank: 55,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-03-30',
          searchVolume: 364,
          organicRank: 61,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-03-29',
          searchVolume: 400,
          organicRank: 42,
          sponsoredRank: null,
          keywordSales: 20
        },
        {
          date: '2024-03-27',
          searchVolume: 400,
          organicRank: 127,
          sponsoredRank: null,
          keywordSales: 20
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech keyboard and mouse wireless',
      keyword: 'logitech keyboard and mouse wireless',
      searchVolume: 1379,
      organicStartRank: 63,
      organicHighestRank: 5,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-04-03',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-31',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 1379,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-04-30',
          searchVolume: 1379,
          organicRank: 16,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-04-29',
          searchVolume: 1379,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-04-28',
          searchVolume: 1379,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-04-27',
          searchVolume: 1379,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-04-26',
          searchVolume: 1359,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-25',
          searchVolume: 1359,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-24',
          searchVolume: 1359,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-23',
          searchVolume: 1359,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 23
        },
        {
          date: '2024-04-22',
          searchVolume: 1359,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-21',
          searchVolume: 1359,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-20',
          searchVolume: 1359,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-19',
          searchVolume: 1147,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-18',
          searchVolume: 1147,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-17',
          searchVolume: 1147,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-16',
          searchVolume: 1147,
          organicRank: null,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-15',
          searchVolume: 1147,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-04-14',
          searchVolume: 1147,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-04-13',
          searchVolume: 1147,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-12',
          searchVolume: 1249,
          organicRank: null,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-11',
          searchVolume: 1249,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-10',
          searchVolume: 1249,
          organicRank: 11,
          sponsoredRank: 9,
          keywordSales: 23
        },
        {
          date: '2024-04-09',
          searchVolume: 1249,
          organicRank: 11,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-04-08',
          searchVolume: 1249,
          organicRank: 12,
          sponsoredRank: 23,
          keywordSales: 23
        },
        {
          date: '2024-04-07',
          searchVolume: 1249,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-06',
          searchVolume: 1249,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-05',
          searchVolume: 1332,
          organicRank: 15,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-04-04',
          searchVolume: 1332,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-03',
          searchVolume: 1332,
          organicRank: 10,
          sponsoredRank: 11,
          keywordSales: 23
        },
        {
          date: '2024-04-02',
          searchVolume: 1332,
          organicRank: 23,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-04-01',
          searchVolume: 1332,
          organicRank: 57,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-03-31',
          searchVolume: 1332,
          organicRank: 41,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-03-30',
          searchVolume: 1332,
          organicRank: 57,
          sponsoredRank: 23,
          keywordSales: 23
        },
        {
          date: '2024-03-29',
          searchVolume: 1388,
          organicRank: 63,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-03-28',
          searchVolume: 1388,
          organicRank: 31,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-03-27',
          searchVolume: 1388,
          organicRank: 148,
          sponsoredRank: null,
          keywordSales: 23
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech keyboard combo',
      keyword: 'logitech keyboard combo',
      searchVolume: 387,
      organicStartRank: 43,
      organicHighestRank: 5,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 5,
      organicPageOneWinDate: '2024-04-06',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-04-11',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 387,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-29',
          searchVolume: 387,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-28',
          searchVolume: 387,
          organicRank: 5,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-27',
          searchVolume: 387,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-26',
          searchVolume: 364,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-25',
          searchVolume: 364,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-24',
          searchVolume: 364,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-23',
          searchVolume: 364,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-22',
          searchVolume: 364,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-21',
          searchVolume: 364,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-20',
          searchVolume: 364,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-19',
          searchVolume: 400,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-18',
          searchVolume: 400,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-17',
          searchVolume: 400,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-16',
          searchVolume: 400,
          organicRank: 9,
          sponsoredRank: 5,
          keywordSales: 1
        },
        {
          date: '2024-04-15',
          searchVolume: 400,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-14',
          searchVolume: 400,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-13',
          searchVolume: 400,
          organicRank: 7,
          sponsoredRank: 24,
          keywordSales: 1
        },
        {
          date: '2024-04-12',
          searchVolume: 278,
          organicRank: 9,
          sponsoredRank: 11,
          keywordSales: 1
        },
        {
          date: '2024-04-11',
          searchVolume: 278,
          organicRank: 18,
          sponsoredRank: 9,
          keywordSales: 1
        },
        {
          date: '2024-04-10',
          searchVolume: 278,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-09',
          searchVolume: 278,
          organicRank: 20,
          sponsoredRank: 29,
          keywordSales: 1
        },
        {
          date: '2024-04-08',
          searchVolume: 278,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-07',
          searchVolume: 278,
          organicRank: 25,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-06',
          searchVolume: 278,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-05',
          searchVolume: 243,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-04',
          searchVolume: 243,
          organicRank: 31,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-03',
          searchVolume: 243,
          organicRank: 29,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-02',
          searchVolume: 243,
          organicRank: 44,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-01',
          searchVolume: 243,
          organicRank: 41,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-03-31',
          searchVolume: 243,
          organicRank: 44,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-03-30',
          searchVolume: 243,
          organicRank: 58,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-03-29',
          searchVolume: 342,
          organicRank: 43,
          sponsoredRank: null,
          keywordSales: 1
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech keyboard mouse',
      keyword: 'logitech keyboard mouse',
      searchVolume: 844,
      organicStartRank: 27,
      organicHighestRank: 8,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-03-31',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-29',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 844,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-30',
          searchVolume: 844,
          organicRank: 18,
          sponsoredRank: 3,
          keywordSales: 31
        },
        {
          date: '2024-04-29',
          searchVolume: 844,
          organicRank: 13,
          sponsoredRank: 5,
          keywordSales: 31
        },
        {
          date: '2024-04-28',
          searchVolume: 844,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-27',
          searchVolume: 844,
          organicRank: 14,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-04-26',
          searchVolume: 922,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-04-25',
          searchVolume: 922,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-04-24',
          searchVolume: 922,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-04-23',
          searchVolume: 922,
          organicRank: 12,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-04-22',
          searchVolume: 922,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-21',
          searchVolume: 922,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-04-20',
          searchVolume: 922,
          organicRank: 9,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-19',
          searchVolume: 832,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-18',
          searchVolume: 832,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-17',
          searchVolume: 832,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-16',
          searchVolume: 832,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-15',
          searchVolume: 832,
          organicRank: 10,
          sponsoredRank: 11,
          keywordSales: 31
        },
        {
          date: '2024-04-14',
          searchVolume: 832,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-13',
          searchVolume: 832,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-12',
          searchVolume: 1303,
          organicRank: 13,
          sponsoredRank: 6,
          keywordSales: 31
        },
        {
          date: '2024-04-11',
          searchVolume: 1303,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-10',
          searchVolume: 1303,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-09',
          searchVolume: 1303,
          organicRank: 11,
          sponsoredRank: 4,
          keywordSales: 31
        },
        {
          date: '2024-04-08',
          searchVolume: 1303,
          organicRank: 12,
          sponsoredRank: 4,
          keywordSales: 31
        },
        {
          date: '2024-04-07',
          searchVolume: 1303,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-06',
          searchVolume: 1303,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 31
        },
        {
          date: '2024-04-05',
          searchVolume: 1204,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-04',
          searchVolume: 1204,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-04-03',
          searchVolume: 1204,
          organicRank: 12,
          sponsoredRank: 21,
          keywordSales: 31
        },
        {
          date: '2024-04-02',
          searchVolume: 1204,
          organicRank: 16,
          sponsoredRank: 3,
          keywordSales: 31
        },
        {
          date: '2024-04-01',
          searchVolume: 1204,
          organicRank: 14,
          sponsoredRank: 4,
          keywordSales: 31
        },
        {
          date: '2024-03-31',
          searchVolume: 1204,
          organicRank: 20,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-03-30',
          searchVolume: 1204,
          organicRank: 21,
          sponsoredRank: 3,
          keywordSales: 31
        },
        {
          date: '2024-03-29',
          searchVolume: 1305,
          organicRank: 27,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-03-28',
          searchVolume: 1305,
          organicRank: 31,
          sponsoredRank: null,
          keywordSales: 31
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech keyboard mouse combo',
      keyword: 'logitech keyboard mouse combo',
      searchVolume: 644,
      organicStartRank: 21,
      organicHighestRank: 7,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-03-31',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-29',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 644,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-30',
          searchVolume: 644,
          organicRank: 14,
          sponsoredRank: 5,
          keywordSales: 11
        },
        {
          date: '2024-04-29',
          searchVolume: 644,
          organicRank: 12,
          sponsoredRank: 4,
          keywordSales: 11
        },
        {
          date: '2024-04-28',
          searchVolume: 644,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-27',
          searchVolume: 644,
          organicRank: 11,
          sponsoredRank: 3,
          keywordSales: 11
        },
        {
          date: '2024-04-26',
          searchVolume: 584,
          organicRank: 11,
          sponsoredRank: 5,
          keywordSales: 11
        },
        {
          date: '2024-04-25',
          searchVolume: 584,
          organicRank: 12,
          sponsoredRank: 1,
          keywordSales: 11
        },
        {
          date: '2024-04-24',
          searchVolume: 584,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 11
        },
        {
          date: '2024-04-23',
          searchVolume: 584,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 11
        },
        {
          date: '2024-04-22',
          searchVolume: 584,
          organicRank: 9,
          sponsoredRank: 5,
          keywordSales: 11
        },
        {
          date: '2024-04-21',
          searchVolume: 584,
          organicRank: 8,
          sponsoredRank: 1,
          keywordSales: 11
        },
        {
          date: '2024-04-20',
          searchVolume: 584,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 11
        },
        {
          date: '2024-04-19',
          searchVolume: 633,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 11
        },
        {
          date: '2024-04-18',
          searchVolume: 633,
          organicRank: 11,
          sponsoredRank: 3,
          keywordSales: 11
        },
        {
          date: '2024-04-17',
          searchVolume: 633,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 11
        },
        {
          date: '2024-04-16',
          searchVolume: 633,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 11
        },
        {
          date: '2024-04-15',
          searchVolume: 633,
          organicRank: 16,
          sponsoredRank: 10,
          keywordSales: 11
        },
        {
          date: '2024-04-14',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: 39,
          keywordSales: 11
        },
        {
          date: '2024-04-13',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: 6,
          keywordSales: 11
        },
        {
          date: '2024-04-12',
          searchVolume: 621,
          organicRank: null,
          sponsoredRank: 6,
          keywordSales: 11
        },
        {
          date: '2024-04-11',
          searchVolume: 621,
          organicRank: 19,
          sponsoredRank: 2,
          keywordSales: 11
        },
        {
          date: '2024-04-10',
          searchVolume: 621,
          organicRank: 17,
          sponsoredRank: 10,
          keywordSales: 11
        },
        {
          date: '2024-04-09',
          searchVolume: 621,
          organicRank: 16,
          sponsoredRank: 9,
          keywordSales: 11
        },
        {
          date: '2024-04-08',
          searchVolume: 621,
          organicRank: 20,
          sponsoredRank: 4,
          keywordSales: 11
        },
        {
          date: '2024-04-07',
          searchVolume: 621,
          organicRank: 18,
          sponsoredRank: 6,
          keywordSales: 11
        },
        {
          date: '2024-04-06',
          searchVolume: 621,
          organicRank: 14,
          sponsoredRank: 2,
          keywordSales: 11
        },
        {
          date: '2024-04-05',
          searchVolume: 736,
          organicRank: 18,
          sponsoredRank: 4,
          keywordSales: 11
        },
        {
          date: '2024-04-04',
          searchVolume: 736,
          organicRank: 16,
          sponsoredRank: 5,
          keywordSales: 11
        },
        {
          date: '2024-04-03',
          searchVolume: 736,
          organicRank: 16,
          sponsoredRank: 17,
          keywordSales: 11
        },
        {
          date: '2024-04-02',
          searchVolume: 736,
          organicRank: 16,
          sponsoredRank: 5,
          keywordSales: 11
        },
        {
          date: '2024-04-01',
          searchVolume: 736,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 11
        },
        {
          date: '2024-03-31',
          searchVolume: 736,
          organicRank: 16,
          sponsoredRank: 4,
          keywordSales: 11
        },
        {
          date: '2024-03-30',
          searchVolume: 736,
          organicRank: 26,
          sponsoredRank: 10,
          keywordSales: 11
        },
        {
          date: '2024-03-29',
          searchVolume: 724,
          organicRank: 21,
          sponsoredRank: 7,
          keywordSales: 11
        },
        {
          date: '2024-03-28',
          searchVolume: 724,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 11
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech keyboard wired',
      keyword: 'logitech keyboard wired',
      searchVolume: 713,
      organicStartRank: 81,
      organicHighestRank: 67,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 11,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-11',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 713,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-29',
          searchVolume: 713,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-28',
          searchVolume: 713,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-27',
          searchVolume: 713,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-26',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-25',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-24',
          searchVolume: 724,
          organicRank: 74,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-23',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-22',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-21',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-20',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-19',
          searchVolume: 830,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-18',
          searchVolume: 830,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-17',
          searchVolume: 830,
          organicRank: 76,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-16',
          searchVolume: 830,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-15',
          searchVolume: 830,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-14',
          searchVolume: 830,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-13',
          searchVolume: 830,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-12',
          searchVolume: 817,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-11',
          searchVolume: 817,
          organicRank: null,
          sponsoredRank: 11,
          keywordSales: 22
        },
        {
          date: '2024-04-10',
          searchVolume: 817,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-09',
          searchVolume: 817,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-08',
          searchVolume: 817,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-07',
          searchVolume: 817,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-06',
          searchVolume: 817,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-05',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-04',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-03',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-02',
          searchVolume: 736,
          organicRank: 102,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-04-01',
          searchVolume: 736,
          organicRank: 67,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-03-31',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-03-30',
          searchVolume: 736,
          organicRank: 111,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-03-29',
          searchVolume: 654,
          organicRank: 81,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-03-28',
          searchVolume: 654,
          organicRank: 36,
          sponsoredRank: null,
          keywordSales: 22
        },
        {
          date: '2024-03-27',
          searchVolume: 654,
          organicRank: 104,
          sponsoredRank: null,
          keywordSales: 22
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech mouse',
      keyword: 'logitech mouse',
      searchVolume: 93842,
      organicStartRank: null,
      organicHighestRank: null,
      sponsoredStartRank: 64,
      sponsoredHighestRank: 45,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 93842,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-30',
          searchVolume: 93842,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-29',
          searchVolume: 93842,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-28',
          searchVolume: 93842,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-27',
          searchVolume: 93842,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-26',
          searchVolume: 96795,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-25',
          searchVolume: 96795,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-24',
          searchVolume: 96795,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-23',
          searchVolume: 96795,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-22',
          searchVolume: 96795,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-21',
          searchVolume: 96795,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-20',
          searchVolume: 96795,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-19',
          searchVolume: 102636,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-18',
          searchVolume: 102636,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-17',
          searchVolume: 102636,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-16',
          searchVolume: 102636,
          organicRank: null,
          sponsoredRank: 45,
          keywordSales: 1723
        },
        {
          date: '2024-04-15',
          searchVolume: 102636,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-14',
          searchVolume: 102636,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-13',
          searchVolume: 102636,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-12',
          searchVolume: 105875,
          organicRank: null,
          sponsoredRank: 64,
          keywordSales: 1723
        },
        {
          date: '2024-04-11',
          searchVolume: 105875,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-10',
          searchVolume: 105875,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-09',
          searchVolume: 105875,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-08',
          searchVolume: 105875,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-07',
          searchVolume: 105875,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-06',
          searchVolume: 105875,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-05',
          searchVolume: 116565,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-04',
          searchVolume: 116565,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-03',
          searchVolume: 116565,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-02',
          searchVolume: 116565,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-04-01',
          searchVolume: 116565,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-03-31',
          searchVolume: 116565,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-03-30',
          searchVolume: 116565,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        },
        {
          date: '2024-03-29',
          searchVolume: 114855,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1723
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech mouse and keyboard',
      keyword: 'logitech mouse and keyboard',
      searchVolume: 2085,
      organicStartRank: 27,
      organicHighestRank: 12,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-03-31',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-30',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 2085,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-04-30',
          searchVolume: 2085,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-29',
          searchVolume: 2085,
          organicRank: 23,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-28',
          searchVolume: 2085,
          organicRank: 15,
          sponsoredRank: 56,
          keywordSales: 23
        },
        {
          date: '2024-04-27',
          searchVolume: 2085,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-26',
          searchVolume: 1865,
          organicRank: 15,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-25',
          searchVolume: 1865,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-24',
          searchVolume: 1865,
          organicRank: 22,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-23',
          searchVolume: 1865,
          organicRank: 23,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-22',
          searchVolume: 1865,
          organicRank: 21,
          sponsoredRank: 5,
          keywordSales: 23
        },
        {
          date: '2024-04-21',
          searchVolume: 1865,
          organicRank: 18,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-20',
          searchVolume: 1865,
          organicRank: 21,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-19',
          searchVolume: 1797,
          organicRank: 21,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-18',
          searchVolume: 1797,
          organicRank: 19,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-17',
          searchVolume: 1797,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-04-16',
          searchVolume: 1797,
          organicRank: null,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-15',
          searchVolume: 1797,
          organicRank: 20,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-04-14',
          searchVolume: 1797,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-13',
          searchVolume: 1797,
          organicRank: null,
          sponsoredRank: 17,
          keywordSales: 23
        },
        {
          date: '2024-04-12',
          searchVolume: 1773,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-04-11',
          searchVolume: 1773,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-10',
          searchVolume: 1773,
          organicRank: null,
          sponsoredRank: 4,
          keywordSales: 23
        },
        {
          date: '2024-04-09',
          searchVolume: 1773,
          organicRank: 24,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-08',
          searchVolume: 1773,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-07',
          searchVolume: 1773,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-04-06',
          searchVolume: 1773,
          organicRank: 26,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-04-05',
          searchVolume: 1897,
          organicRank: 25,
          sponsoredRank: 4,
          keywordSales: 23
        },
        {
          date: '2024-04-04',
          searchVolume: 1897,
          organicRank: 28,
          sponsoredRank: 5,
          keywordSales: 23
        },
        {
          date: '2024-04-03',
          searchVolume: 1897,
          organicRank: 25,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-02',
          searchVolume: 1897,
          organicRank: 21,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-04-01',
          searchVolume: 1897,
          organicRank: 18,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-03-31',
          searchVolume: 1897,
          organicRank: 18,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-03-30',
          searchVolume: 1897,
          organicRank: 27,
          sponsoredRank: 7,
          keywordSales: 23
        },
        {
          date: '2024-03-29',
          searchVolume: 1860,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 23
        },
        {
          date: '2024-03-28',
          searchVolume: 1860,
          organicRank: 27,
          sponsoredRank: 45,
          keywordSales: 23
        },
        {
          date: '2024-03-27',
          searchVolume: 1860,
          organicRank: 105,
          sponsoredRank: null,
          keywordSales: 23
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech wired keyboard',
      keyword: 'logitech wired keyboard',
      searchVolume: 1586,
      organicStartRank: 55,
      organicHighestRank: 55,
      sponsoredStartRank: 65,
      sponsoredHighestRank: 65,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 1586,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-29',
          searchVolume: 1586,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-28',
          searchVolume: 1586,
          organicRank: 65,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-27',
          searchVolume: 1586,
          organicRank: 57,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-26',
          searchVolume: 1606,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-25',
          searchVolume: 1606,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-24',
          searchVolume: 1606,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-23',
          searchVolume: 1606,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-22',
          searchVolume: 1606,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-21',
          searchVolume: 1606,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-20',
          searchVolume: 1606,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-19',
          searchVolume: 1581,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-18',
          searchVolume: 1581,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-17',
          searchVolume: 1581,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-16',
          searchVolume: 1581,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-15',
          searchVolume: 1581,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-14',
          searchVolume: 1581,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-13',
          searchVolume: 1581,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-12',
          searchVolume: 1561,
          organicRank: null,
          sponsoredRank: 65,
          keywordSales: 42
        },
        {
          date: '2024-04-11',
          searchVolume: 1561,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-10',
          searchVolume: 1561,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-09',
          searchVolume: 1561,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-08',
          searchVolume: 1561,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-07',
          searchVolume: 1561,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-06',
          searchVolume: 1561,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-05',
          searchVolume: 1421,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-04',
          searchVolume: 1421,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-03',
          searchVolume: 1421,
          organicRank: 75,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-02',
          searchVolume: 1421,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-04-01',
          searchVolume: 1421,
          organicRank: 100,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-03-31',
          searchVolume: 1421,
          organicRank: 98,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-03-30',
          searchVolume: 1421,
          organicRank: 109,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-03-29',
          searchVolume: 1637,
          organicRank: 55,
          sponsoredRank: null,
          keywordSales: 42
        },
        {
          date: '2024-03-28',
          searchVolume: 1637,
          organicRank: 46,
          sponsoredRank: null,
          keywordSales: 42
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech wireless keyboard',
      keyword: 'logitech wireless keyboard',
      searchVolume: 12824,
      organicStartRank: 40,
      organicHighestRank: 21,
      sponsoredStartRank: 33,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-11',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 12824,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-30',
          searchVolume: 12824,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-29',
          searchVolume: 12824,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-28',
          searchVolume: 12824,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-27',
          searchVolume: 12824,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-26',
          searchVolume: 13689,
          organicRank: null,
          sponsoredRank: 15,
          keywordSales: 167
        },
        {
          date: '2024-04-25',
          searchVolume: 13689,
          organicRank: null,
          sponsoredRank: 3,
          keywordSales: 167
        },
        {
          date: '2024-04-24',
          searchVolume: 13689,
          organicRank: null,
          sponsoredRank: 18,
          keywordSales: 167
        },
        {
          date: '2024-04-23',
          searchVolume: 13689,
          organicRank: null,
          sponsoredRank: 22,
          keywordSales: 167
        },
        {
          date: '2024-04-22',
          searchVolume: 13689,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-21',
          searchVolume: 13689,
          organicRank: null,
          sponsoredRank: 34,
          keywordSales: 167
        },
        {
          date: '2024-04-20',
          searchVolume: 13689,
          organicRank: null,
          sponsoredRank: 16,
          keywordSales: 167
        },
        {
          date: '2024-04-19',
          searchVolume: 13300,
          organicRank: null,
          sponsoredRank: 54,
          keywordSales: 167
        },
        {
          date: '2024-04-18',
          searchVolume: 13300,
          organicRank: null,
          sponsoredRank: 9,
          keywordSales: 167
        },
        {
          date: '2024-04-17',
          searchVolume: 13300,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-16',
          searchVolume: 13300,
          organicRank: null,
          sponsoredRank: 60,
          keywordSales: 167
        },
        {
          date: '2024-04-14',
          searchVolume: 13300,
          organicRank: null,
          sponsoredRank: 26,
          keywordSales: 167
        },
        {
          date: '2024-04-13',
          searchVolume: 13300,
          organicRank: null,
          sponsoredRank: 18,
          keywordSales: 167
        },
        {
          date: '2024-04-12',
          searchVolume: 13067,
          organicRank: null,
          sponsoredRank: 11,
          keywordSales: 167
        },
        {
          date: '2024-04-11',
          searchVolume: 13067,
          organicRank: null,
          sponsoredRank: 8,
          keywordSales: 167
        },
        {
          date: '2024-04-10',
          searchVolume: 13067,
          organicRank: null,
          sponsoredRank: 39,
          keywordSales: 167
        },
        {
          date: '2024-04-09',
          searchVolume: 13067,
          organicRank: null,
          sponsoredRank: 21,
          keywordSales: 167
        },
        {
          date: '2024-04-08',
          searchVolume: 13067,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-07',
          searchVolume: 13067,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-06',
          searchVolume: 13067,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-05',
          searchVolume: 13245,
          organicRank: 26,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-04',
          searchVolume: 13245,
          organicRank: null,
          sponsoredRank: 33,
          keywordSales: 167
        },
        {
          date: '2024-04-03',
          searchVolume: 13245,
          organicRank: 45,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-02',
          searchVolume: 13245,
          organicRank: 50,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-04-01',
          searchVolume: 13245,
          organicRank: 45,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-03-31',
          searchVolume: 13245,
          organicRank: 41,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-03-30',
          searchVolume: 13245,
          organicRank: 31,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-03-29',
          searchVolume: 13731,
          organicRank: 40,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-03-28',
          searchVolume: 13731,
          organicRank: 49,
          sponsoredRank: null,
          keywordSales: 167
        },
        {
          date: '2024-03-27',
          searchVolume: 13731,
          organicRank: 35,
          sponsoredRank: null,
          keywordSales: 167
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech wireless keyboard and mouse combo',
      keyword: 'logitech wireless keyboard and mouse combo',
      searchVolume: 23603,
      organicStartRank: 23,
      organicHighestRank: 9,
      sponsoredStartRank: 16,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-04-06',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-29',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 23603,
          organicRank: 21,
          sponsoredRank: 2,
          keywordSales: 273
        },
        {
          date: '2024-04-29',
          searchVolume: 23603,
          organicRank: 25,
          sponsoredRank: 3,
          keywordSales: 273
        },
        {
          date: '2024-04-28',
          searchVolume: 23603,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 273
        },
        {
          date: '2024-04-27',
          searchVolume: 23603,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 273
        },
        {
          date: '2024-04-26',
          searchVolume: 20299,
          organicRank: 17,
          sponsoredRank: 4,
          keywordSales: 273
        },
        {
          date: '2024-04-25',
          searchVolume: 20299,
          organicRank: 21,
          sponsoredRank: 2,
          keywordSales: 273
        },
        {
          date: '2024-04-24',
          searchVolume: 20299,
          organicRank: 25,
          sponsoredRank: 3,
          keywordSales: 273
        },
        {
          date: '2024-04-23',
          searchVolume: 20299,
          organicRank: 26,
          sponsoredRank: 6,
          keywordSales: 273
        },
        {
          date: '2024-04-22',
          searchVolume: 20299,
          organicRank: 25,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-21',
          searchVolume: 20299,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-20',
          searchVolume: 20299,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-19',
          searchVolume: 18722,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-18',
          searchVolume: 18722,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-17',
          searchVolume: 18722,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-16',
          searchVolume: 18722,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-15',
          searchVolume: 18722,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-14',
          searchVolume: 18722,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-13',
          searchVolume: 18722,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-12',
          searchVolume: 15323,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 273
        },
        {
          date: '2024-04-11',
          searchVolume: 15323,
          organicRank: 11,
          sponsoredRank: 2,
          keywordSales: 273
        },
        {
          date: '2024-04-10',
          searchVolume: 15323,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 273
        },
        {
          date: '2024-04-09',
          searchVolume: 15323,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 273
        },
        {
          date: '2024-04-08',
          searchVolume: 15323,
          organicRank: 13,
          sponsoredRank: 4,
          keywordSales: 273
        },
        {
          date: '2024-04-07',
          searchVolume: 15323,
          organicRank: 15,
          sponsoredRank: 2,
          keywordSales: 273
        },
        {
          date: '2024-04-06',
          searchVolume: 15323,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 273
        },
        {
          date: '2024-04-05',
          searchVolume: 13912,
          organicRank: 22,
          sponsoredRank: 4,
          keywordSales: 273
        },
        {
          date: '2024-04-04',
          searchVolume: 13912,
          organicRank: 26,
          sponsoredRank: 2,
          keywordSales: 273
        },
        {
          date: '2024-04-03',
          searchVolume: 13912,
          organicRank: 27,
          sponsoredRank: 5,
          keywordSales: 273
        },
        {
          date: '2024-04-02',
          searchVolume: 13912,
          organicRank: 49,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-04-01',
          searchVolume: 13912,
          organicRank: 47,
          sponsoredRank: null,
          keywordSales: 273
        },
        {
          date: '2024-03-30',
          searchVolume: 13912,
          organicRank: 45,
          sponsoredRank: 9,
          keywordSales: 273
        },
        {
          date: '2024-03-29',
          searchVolume: 15201,
          organicRank: 23,
          sponsoredRank: 16,
          keywordSales: 273
        },
        {
          date: '2024-03-28',
          searchVolume: 15201,
          organicRank: 35,
          sponsoredRank: 63,
          keywordSales: 273
        },
        {
          date: '2024-03-27',
          searchVolume: 15201,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 273
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech wireless keyboard mouse combo',
      keyword: 'logitech wireless keyboard mouse combo',
      searchVolume: 466,
      organicStartRank: 17,
      organicHighestRank: 9,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-03-29',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-31',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 466,
          organicRank: 17,
          sponsoredRank: 2,
          keywordSales: 8
        },
        {
          date: '2024-04-29',
          searchVolume: 466,
          organicRank: 18,
          sponsoredRank: 1,
          keywordSales: 8
        },
        {
          date: '2024-04-28',
          searchVolume: 466,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 8
        },
        {
          date: '2024-04-27',
          searchVolume: 466,
          organicRank: 18,
          sponsoredRank: 2,
          keywordSales: 8
        },
        {
          date: '2024-04-26',
          searchVolume: 418,
          organicRank: 18,
          sponsoredRank: 2,
          keywordSales: 8
        },
        {
          date: '2024-04-25',
          searchVolume: 418,
          organicRank: 15,
          sponsoredRank: 1,
          keywordSales: 8
        },
        {
          date: '2024-04-24',
          searchVolume: 418,
          organicRank: 19,
          sponsoredRank: 3,
          keywordSales: 8
        },
        {
          date: '2024-04-23',
          searchVolume: 418,
          organicRank: 17,
          sponsoredRank: 4,
          keywordSales: 8
        },
        {
          date: '2024-04-22',
          searchVolume: 418,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-21',
          searchVolume: 418,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-20',
          searchVolume: 418,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-19',
          searchVolume: 431,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-18',
          searchVolume: 431,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-17',
          searchVolume: 431,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-16',
          searchVolume: 431,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-15',
          searchVolume: 431,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-14',
          searchVolume: 431,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-13',
          searchVolume: 431,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-12',
          searchVolume: 470,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 8
        },
        {
          date: '2024-04-11',
          searchVolume: 470,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 8
        },
        {
          date: '2024-04-10',
          searchVolume: 470,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 8
        },
        {
          date: '2024-04-09',
          searchVolume: 470,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 8
        },
        {
          date: '2024-04-08',
          searchVolume: 470,
          organicRank: 12,
          sponsoredRank: 1,
          keywordSales: 8
        },
        {
          date: '2024-04-07',
          searchVolume: 470,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 8
        },
        {
          date: '2024-04-06',
          searchVolume: 470,
          organicRank: 9,
          sponsoredRank: 4,
          keywordSales: 8
        },
        {
          date: '2024-04-05',
          searchVolume: 469,
          organicRank: 10,
          sponsoredRank: 5,
          keywordSales: 8
        },
        {
          date: '2024-04-04',
          searchVolume: 469,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 8
        },
        {
          date: '2024-04-03',
          searchVolume: 469,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 8
        },
        {
          date: '2024-04-02',
          searchVolume: 469,
          organicRank: 30,
          sponsoredRank: 2,
          keywordSales: 8
        },
        {
          date: '2024-04-01',
          searchVolume: 469,
          organicRank: 33,
          sponsoredRank: 3,
          keywordSales: 8
        },
        {
          date: '2024-03-31',
          searchVolume: 469,
          organicRank: 41,
          sponsoredRank: 3,
          keywordSales: 8
        },
        {
          date: '2024-03-30',
          searchVolume: 469,
          organicRank: 40,
          sponsoredRank: 47,
          keywordSales: 8
        },
        {
          date: '2024-03-29',
          searchVolume: 474,
          organicRank: 17,
          sponsoredRank: 29,
          keywordSales: 8
        },
        {
          date: '2024-03-28',
          searchVolume: 474,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-27',
          searchVolume: 474,
          organicRank: null,
          sponsoredRank: 58,
          keywordSales: 8
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech wireless keyboards',
      keyword: 'logitech wireless keyboards',
      searchVolume: 397,
      organicStartRank: 37,
      organicHighestRank: 33,
      sponsoredStartRank: 18,
      sponsoredHighestRank: 18,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-02',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 397,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-29',
          searchVolume: 397,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-28',
          searchVolume: 397,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-27',
          searchVolume: 397,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-26',
          searchVolume: 434,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-25',
          searchVolume: 434,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-24',
          searchVolume: 434,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-23',
          searchVolume: 434,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-22',
          searchVolume: 434,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-21',
          searchVolume: 434,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-20',
          searchVolume: 434,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-19',
          searchVolume: 403,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-18',
          searchVolume: 403,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-17',
          searchVolume: 403,
          organicRank: null,
          sponsoredRank: 36,
          keywordSales: 1
        },
        {
          date: '2024-04-16',
          searchVolume: 403,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-15',
          searchVolume: 403,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-14',
          searchVolume: 403,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-13',
          searchVolume: 403,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-12',
          searchVolume: 445,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-11',
          searchVolume: 445,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-10',
          searchVolume: 445,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-09',
          searchVolume: 445,
          organicRank: null,
          sponsoredRank: 74,
          keywordSales: 1
        },
        {
          date: '2024-04-08',
          searchVolume: 445,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-07',
          searchVolume: 445,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-06',
          searchVolume: 445,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-05',
          searchVolume: 474,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-04',
          searchVolume: 474,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-03',
          searchVolume: 474,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-04-02',
          searchVolume: 474,
          organicRank: 57,
          sponsoredRank: 18,
          keywordSales: 1
        },
        {
          date: '2024-04-01',
          searchVolume: 474,
          organicRank: 68,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-03-31',
          searchVolume: 474,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-03-30',
          searchVolume: 474,
          organicRank: 37,
          sponsoredRank: null,
          keywordSales: 1
        },
        {
          date: '2024-03-29',
          searchVolume: 449,
          organicRank: 37,
          sponsoredRank: null,
          keywordSales: 1
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech wireless mouse',
      keyword: 'logitech wireless mouse',
      searchVolume: 21045,
      organicStartRank: null,
      organicHighestRank: null,
      sponsoredStartRank: 57,
      sponsoredHighestRank: 11,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-12',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 21045,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-29',
          searchVolume: 21045,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-28',
          searchVolume: 21045,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-27',
          searchVolume: 21045,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-26',
          searchVolume: 21395,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-25',
          searchVolume: 21395,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-24',
          searchVolume: 21395,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-23',
          searchVolume: 21395,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-22',
          searchVolume: 21395,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-21',
          searchVolume: 21395,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-20',
          searchVolume: 21395,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-19',
          searchVolume: 23310,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-18',
          searchVolume: 23310,
          organicRank: null,
          sponsoredRank: 81,
          keywordSales: 363
        },
        {
          date: '2024-04-17',
          searchVolume: 23310,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-16',
          searchVolume: 23310,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-15',
          searchVolume: 23310,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-14',
          searchVolume: 23310,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-13',
          searchVolume: 23310,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-12',
          searchVolume: 20377,
          organicRank: null,
          sponsoredRank: 11,
          keywordSales: 363
        },
        {
          date: '2024-04-11',
          searchVolume: 20377,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-10',
          searchVolume: 20377,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-09',
          searchVolume: 20377,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-08',
          searchVolume: 20377,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-07',
          searchVolume: 20377,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-06',
          searchVolume: 20377,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-05',
          searchVolume: 21722,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-04',
          searchVolume: 21722,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-03',
          searchVolume: 21722,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-04-02',
          searchVolume: 21722,
          organicRank: null,
          sponsoredRank: 57,
          keywordSales: 363
        },
        {
          date: '2024-04-01',
          searchVolume: 21722,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-03-31',
          searchVolume: 21722,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-03-30',
          searchVolume: 21722,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        },
        {
          date: '2024-03-29',
          searchVolume: 21395,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 363
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_logitech wireless mouse bluetooth',
      keyword: 'logitech wireless mouse bluetooth',
      searchVolume: 397,
      organicStartRank: null,
      organicHighestRank: null,
      sponsoredStartRank: 10,
      sponsoredHighestRank: 10,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-11',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 397,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-29',
          searchVolume: 397,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-28',
          searchVolume: 397,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-27',
          searchVolume: 397,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-26',
          searchVolume: 420,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-25',
          searchVolume: 420,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-24',
          searchVolume: 420,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-23',
          searchVolume: 420,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-22',
          searchVolume: 420,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-21',
          searchVolume: 420,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-20',
          searchVolume: 420,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-19',
          searchVolume: 436,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-18',
          searchVolume: 436,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-17',
          searchVolume: 436,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-16',
          searchVolume: 436,
          organicRank: null,
          sponsoredRank: 51,
          keywordSales: 4
        },
        {
          date: '2024-04-15',
          searchVolume: 436,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-14',
          searchVolume: 436,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-13',
          searchVolume: 436,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-12',
          searchVolume: 402,
          organicRank: null,
          sponsoredRank: 57,
          keywordSales: 4
        },
        {
          date: '2024-04-11',
          searchVolume: 402,
          organicRank: null,
          sponsoredRank: 10,
          keywordSales: 4
        },
        {
          date: '2024-04-10',
          searchVolume: 402,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-09',
          searchVolume: 402,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-08',
          searchVolume: 402,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-07',
          searchVolume: 402,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-06',
          searchVolume: 402,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-05',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-04',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-03',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-02',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-04-01',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-03-31',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-03-30',
          searchVolume: 392,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        },
        {
          date: '2024-03-29',
          searchVolume: 422,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 4
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_mac keyboard',
      keyword: 'mac keyboard',
      searchVolume: 11198,
      organicStartRank: 134,
      organicHighestRank: 134,
      sponsoredStartRank: 60,
      sponsoredHighestRank: 60,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 11198,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-29',
          searchVolume: 11198,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-28',
          searchVolume: 11198,
          organicRank: 201,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-27',
          searchVolume: 11198,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-26',
          searchVolume: 11391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-25',
          searchVolume: 11391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-24',
          searchVolume: 11391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-23',
          searchVolume: 11391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-22',
          searchVolume: 11391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-21',
          searchVolume: 11391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-20',
          searchVolume: 11391,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-19',
          searchVolume: 11026,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-17',
          searchVolume: 11026,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-16',
          searchVolume: 11026,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-15',
          searchVolume: 11026,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-14',
          searchVolume: 11026,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-13',
          searchVolume: 11026,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-12',
          searchVolume: 12374,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-11',
          searchVolume: 12374,
          organicRank: null,
          sponsoredRank: 60,
          keywordSales: 227
        },
        {
          date: '2024-04-10',
          searchVolume: 12374,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-09',
          searchVolume: 12374,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-08',
          searchVolume: 12374,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-07',
          searchVolume: 12374,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-06',
          searchVolume: 12374,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-05',
          searchVolume: 13201,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-04',
          searchVolume: 13201,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-03',
          searchVolume: 13201,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-02',
          searchVolume: 13201,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-04-01',
          searchVolume: 13201,
          organicRank: 134,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-03-31',
          searchVolume: 13201,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-03-30',
          searchVolume: 13201,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        },
        {
          date: '2024-03-29',
          searchVolume: 13000,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 227
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_mac keyboard and mouse wireless',
      keyword: 'mac keyboard and mouse wireless',
      searchVolume: 463,
      organicStartRank: 101,
      organicHighestRank: 33,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 463,
          organicRank: 265,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-29',
          searchVolume: 463,
          organicRank: 216,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-28',
          searchVolume: 463,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-27',
          searchVolume: 463,
          organicRank: 73,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-26',
          searchVolume: 542,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-25',
          searchVolume: 542,
          organicRank: 238,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-24',
          searchVolume: 542,
          organicRank: 219,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-23',
          searchVolume: 542,
          organicRank: 53,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-22',
          searchVolume: 542,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-21',
          searchVolume: 542,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-20',
          searchVolume: 542,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-19',
          searchVolume: 527,
          organicRank: 48,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-18',
          searchVolume: 527,
          organicRank: 76,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-17',
          searchVolume: 527,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-16',
          searchVolume: 527,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-15',
          searchVolume: 527,
          organicRank: 40,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-14',
          searchVolume: 527,
          organicRank: 42,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-13',
          searchVolume: 527,
          organicRank: 53,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-12',
          searchVolume: 465,
          organicRank: 36,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-11',
          searchVolume: 465,
          organicRank: 38,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-10',
          searchVolume: 465,
          organicRank: 59,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-09',
          searchVolume: 465,
          organicRank: 76,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-08',
          searchVolume: 465,
          organicRank: 81,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-07',
          searchVolume: 465,
          organicRank: 66,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-06',
          searchVolume: 465,
          organicRank: 44,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-05',
          searchVolume: 589,
          organicRank: 52,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-04',
          searchVolume: 589,
          organicRank: 93,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-03',
          searchVolume: 589,
          organicRank: 98,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-02',
          searchVolume: 589,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-01',
          searchVolume: 589,
          organicRank: 101,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-31',
          searchVolume: 589,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-30',
          searchVolume: 589,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-29',
          searchVolume: 479,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-28',
          searchVolume: 479,
          organicRank: 69,
          sponsoredRank: null,
          keywordSales: 10
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_mac keyboard wireless',
      keyword: 'mac keyboard wireless',
      searchVolume: 1584,
      organicStartRank: 142,
      organicHighestRank: 34,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 1584,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-30',
          searchVolume: 1584,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-29',
          searchVolume: 1584,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-28',
          searchVolume: 1584,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-27',
          searchVolume: 1584,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-26',
          searchVolume: 1613,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-25',
          searchVolume: 1613,
          organicRank: 221,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-24',
          searchVolume: 1613,
          organicRank: 215,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-23',
          searchVolume: 1613,
          organicRank: 218,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-22',
          searchVolume: 1613,
          organicRank: 40,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-21',
          searchVolume: 1613,
          organicRank: 63,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-20',
          searchVolume: 1613,
          organicRank: 244,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-19',
          searchVolume: 1560,
          organicRank: 34,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-18',
          searchVolume: 1560,
          organicRank: 51,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-17',
          searchVolume: 1560,
          organicRank: 42,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-16',
          searchVolume: 1560,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-15',
          searchVolume: 1560,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-14',
          searchVolume: 1560,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-13',
          searchVolume: 1560,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-12',
          searchVolume: 1705,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-11',
          searchVolume: 1705,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-10',
          searchVolume: 1705,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-09',
          searchVolume: 1705,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-08',
          searchVolume: 1705,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-07',
          searchVolume: 1705,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-06',
          searchVolume: 1705,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-05',
          searchVolume: 1822,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-04',
          searchVolume: 1822,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-03',
          searchVolume: 1822,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-02',
          searchVolume: 1822,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-01',
          searchVolume: 1822,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-03-31',
          searchVolume: 1822,
          organicRank: 142,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-03-30',
          searchVolume: 1822,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-03-29',
          searchVolume: 1862,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-03-28',
          searchVolume: 1862,
          organicRank: 94,
          sponsoredRank: null,
          keywordSales: 40
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_mac wireless keyboard',
      keyword: 'mac wireless keyboard',
      searchVolume: 1584,
      organicStartRank: 158,
      organicHighestRank: 158,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 1584,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-29',
          searchVolume: 1584,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-28',
          searchVolume: 1584,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-27',
          searchVolume: 1584,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-26',
          searchVolume: 1559,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-25',
          searchVolume: 1559,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-24',
          searchVolume: 1559,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-23',
          searchVolume: 1559,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-22',
          searchVolume: 1559,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-21',
          searchVolume: 1559,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-20',
          searchVolume: 1559,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-19',
          searchVolume: 1733,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-18',
          searchVolume: 1733,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-17',
          searchVolume: 1733,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-16',
          searchVolume: 1733,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-15',
          searchVolume: 1733,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-14',
          searchVolume: 1733,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-13',
          searchVolume: 1733,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-12',
          searchVolume: 1706,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-11',
          searchVolume: 1706,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-10',
          searchVolume: 1706,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-09',
          searchVolume: 1706,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-08',
          searchVolume: 1706,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-07',
          searchVolume: 1706,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-06',
          searchVolume: 1706,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-05',
          searchVolume: 1664,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-04',
          searchVolume: 1664,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-03',
          searchVolume: 1664,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-02',
          searchVolume: 1664,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-01',
          searchVolume: 1664,
          organicRank: 158,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-03-31',
          searchVolume: 1664,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-03-30',
          searchVolume: 1664,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-03-29',
          searchVolume: 1640,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-03-28',
          searchVolume: 1640,
          organicRank: 90,
          sponsoredRank: null,
          keywordSales: 30
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_mouse and keyboard wireless',
      keyword: 'mouse and keyboard wireless',
      searchVolume: 644,
      organicStartRank: 54,
      organicHighestRank: 52,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 644,
          organicRank: 233,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-29',
          searchVolume: 644,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-28',
          searchVolume: 644,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-27',
          searchVolume: 644,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-26',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-25',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-24',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-23',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-22',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-21',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-20',
          searchVolume: 724,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-19',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-18',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-17',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-16',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-15',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-14',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-13',
          searchVolume: 633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-12',
          searchVolume: 690,
          organicRank: 80,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-11',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-10',
          searchVolume: 690,
          organicRank: 52,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-09',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-08',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-07',
          searchVolume: 690,
          organicRank: 79,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-06',
          searchVolume: 690,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-05',
          searchVolume: 871,
          organicRank: 67,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-04',
          searchVolume: 871,
          organicRank: 95,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-03',
          searchVolume: 871,
          organicRank: 94,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-02',
          searchVolume: 871,
          organicRank: 60,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-04-01',
          searchVolume: 871,
          organicRank: 54,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-31',
          searchVolume: 871,
          organicRank: 58,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-30',
          searchVolume: 871,
          organicRank: 52,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-29',
          searchVolume: 726,
          organicRank: 54,
          sponsoredRank: null,
          keywordSales: 8
        },
        {
          date: '2024-03-28',
          searchVolume: 726,
          organicRank: 73,
          sponsoredRank: null,
          keywordSales: 8
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_mouse keyboard combo wireless',
      keyword: 'mouse keyboard combo wireless',
      searchVolume: 2318,
      organicStartRank: 29,
      organicHighestRank: 12,
      sponsoredStartRank: 38,
      sponsoredHighestRank: 38,
      organicPageOneWinDate: '2024-04-02',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 2318,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-30',
          searchVolume: 2318,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-29',
          searchVolume: 2318,
          organicRank: 38,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-28',
          searchVolume: 2318,
          organicRank: 29,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-27',
          searchVolume: 2318,
          organicRank: 26,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-26',
          searchVolume: 2470,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-25',
          searchVolume: 2470,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-24',
          searchVolume: 2470,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-23',
          searchVolume: 2470,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-22',
          searchVolume: 2470,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-21',
          searchVolume: 2470,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-20',
          searchVolume: 2470,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-19',
          searchVolume: 2390,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-18',
          searchVolume: 2390,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-17',
          searchVolume: 2390,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-16',
          searchVolume: 2390,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-15',
          searchVolume: 2390,
          organicRank: 26,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-14',
          searchVolume: 2390,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-13',
          searchVolume: 2390,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-12',
          searchVolume: 2245,
          organicRank: null,
          sponsoredRank: 57,
          keywordSales: 37
        },
        {
          date: '2024-04-11',
          searchVolume: 2245,
          organicRank: 30,
          sponsoredRank: 38,
          keywordSales: 37
        },
        {
          date: '2024-04-10',
          searchVolume: 2245,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-09',
          searchVolume: 2245,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-08',
          searchVolume: 2245,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-07',
          searchVolume: 2245,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-06',
          searchVolume: 2245,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-05',
          searchVolume: 2511,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-04',
          searchVolume: 2511,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-03',
          searchVolume: 2511,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-02',
          searchVolume: 2511,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-04-01',
          searchVolume: 2511,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-03-31',
          searchVolume: 2511,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-03-30',
          searchVolume: 2511,
          organicRank: 44,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-03-29',
          searchVolume: 2357,
          organicRank: 29,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-03-28',
          searchVolume: 2357,
          organicRank: 35,
          sponsoredRank: null,
          keywordSales: 37
        },
        {
          date: '2024-03-27',
          searchVolume: 2357,
          organicRank: 87,
          sponsoredRank: null,
          keywordSales: 37
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_multi device keyboard and mouse combo',
      keyword: 'multi device keyboard and mouse combo',
      searchVolume: 1164,
      organicStartRank: 22,
      organicHighestRank: 3,
      sponsoredStartRank: 57,
      sponsoredHighestRank: 42,
      organicPageOneWinDate: '2024-04-09',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 1164,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-30',
          searchVolume: 1164,
          organicRank: 4,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-29',
          searchVolume: 1164,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-28',
          searchVolume: 1164,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-27',
          searchVolume: 1164,
          organicRank: 3,
          sponsoredRank: 42,
          keywordSales: 21
        },
        {
          date: '2024-04-26',
          searchVolume: 1309,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-25',
          searchVolume: 1309,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-24',
          searchVolume: 1309,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-23',
          searchVolume: 1309,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-22',
          searchVolume: 1309,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-21',
          searchVolume: 1309,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-20',
          searchVolume: 1309,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-19',
          searchVolume: 1269,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-18',
          searchVolume: 1269,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-17',
          searchVolume: 1269,
          organicRank: 3,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-16',
          searchVolume: 1269,
          organicRank: 4,
          sponsoredRank: 57,
          keywordSales: 21
        },
        {
          date: '2024-04-15',
          searchVolume: 1269,
          organicRank: 5,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-14',
          searchVolume: 1269,
          organicRank: 5,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-13',
          searchVolume: 1269,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-12',
          searchVolume: 1247,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-11',
          searchVolume: 1247,
          organicRank: 25,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-10',
          searchVolume: 1247,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-09',
          searchVolume: 1247,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-08',
          searchVolume: 1247,
          organicRank: 31,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-07',
          searchVolume: 1247,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-06',
          searchVolume: 1247,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-05',
          searchVolume: 875,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-04',
          searchVolume: 875,
          organicRank: 38,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-03',
          searchVolume: 875,
          organicRank: 36,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-02',
          searchVolume: 875,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-04-01',
          searchVolume: 875,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-03-31',
          searchVolume: 875,
          organicRank: 26,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-03-30',
          searchVolume: 875,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-03-29',
          searchVolume: 924,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-03-28',
          searchVolume: 924,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 21
        },
        {
          date: '2024-03-27',
          searchVolume: 924,
          organicRank: 169,
          sponsoredRank: null,
          keywordSales: 21
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_quiet keyboard',
      keyword: 'quiet keyboard',
      searchVolume: 6952,
      organicStartRank: 88,
      organicHighestRank: 42,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 6952,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-30',
          searchVolume: 6952,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-29',
          searchVolume: 6952,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-28',
          searchVolume: 6952,
          organicRank: 72,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-27',
          searchVolume: 6952,
          organicRank: 59,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-26',
          searchVolume: 7476,
          organicRank: 74,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-25',
          searchVolume: 7476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-24',
          searchVolume: 7476,
          organicRank: 56,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-23',
          searchVolume: 7476,
          organicRank: 42,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-22',
          searchVolume: 7476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-21',
          searchVolume: 7476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-20',
          searchVolume: 7476,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-19',
          searchVolume: 7960,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-18',
          searchVolume: 7960,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-17',
          searchVolume: 7960,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-16',
          searchVolume: 7960,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-15',
          searchVolume: 7960,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-14',
          searchVolume: 7960,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-13',
          searchVolume: 7960,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-12',
          searchVolume: 7118,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-11',
          searchVolume: 7118,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-10',
          searchVolume: 7118,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-09',
          searchVolume: 7118,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-08',
          searchVolume: 7118,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-07',
          searchVolume: 7118,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-06',
          searchVolume: 7118,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-05',
          searchVolume: 6655,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-04',
          searchVolume: 6655,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-03',
          searchVolume: 6655,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-02',
          searchVolume: 6655,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-04-01',
          searchVolume: 6655,
          organicRank: 116,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-03-31',
          searchVolume: 6655,
          organicRank: 238,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-03-30',
          searchVolume: 6655,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-03-29',
          searchVolume: 8221,
          organicRank: 88,
          sponsoredRank: null,
          keywordSales: 134
        },
        {
          date: '2024-03-28',
          searchVolume: 8221,
          organicRank: 53,
          sponsoredRank: null,
          keywordSales: 134
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_quiet keyboard and mouse wireless combo',
      keyword: 'quiet keyboard and mouse wireless combo',
      searchVolume: 2434,
      organicStartRank: 53,
      organicHighestRank: 7,
      sponsoredStartRank: 22,
      sponsoredHighestRank: 22,
      organicPageOneWinDate: '2024-04-18',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 2434,
          organicRank: 39,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-30',
          searchVolume: 2434,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-29',
          searchVolume: 2434,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-28',
          searchVolume: 2434,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-27',
          searchVolume: 2434,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-26',
          searchVolume: 2472,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-25',
          searchVolume: 2472,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-24',
          searchVolume: 2472,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-23',
          searchVolume: 2472,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-22',
          searchVolume: 2472,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-21',
          searchVolume: 2472,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-20',
          searchVolume: 2472,
          organicRank: 8,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-19',
          searchVolume: 2643,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-18',
          searchVolume: 2643,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-17',
          searchVolume: 2643,
          organicRank: 61,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-16',
          searchVolume: 2643,
          organicRank: 51,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-15',
          searchVolume: 2643,
          organicRank: 82,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-14',
          searchVolume: 2643,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-13',
          searchVolume: 2643,
          organicRank: 48,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-12',
          searchVolume: 2886,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-11',
          searchVolume: 2886,
          organicRank: 66,
          sponsoredRank: 22,
          keywordSales: 44
        },
        {
          date: '2024-04-10',
          searchVolume: 2886,
          organicRank: 90,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-09',
          searchVolume: 2886,
          organicRank: 80,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-08',
          searchVolume: 2886,
          organicRank: 76,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-07',
          searchVolume: 2886,
          organicRank: 79,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-06',
          searchVolume: 2886,
          organicRank: 73,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-05',
          searchVolume: 2511,
          organicRank: 76,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-04',
          searchVolume: 2511,
          organicRank: 82,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-03',
          searchVolume: 2511,
          organicRank: 75,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-02',
          searchVolume: 2511,
          organicRank: 91,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-04-01',
          searchVolume: 2511,
          organicRank: 87,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-03-31',
          searchVolume: 2511,
          organicRank: 81,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-03-30',
          searchVolume: 2511,
          organicRank: 62,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-03-29',
          searchVolume: 2106,
          organicRank: 53,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-03-28',
          searchVolume: 2106,
          organicRank: 39,
          sponsoredRank: null,
          keywordSales: 44
        },
        {
          date: '2024-03-27',
          searchVolume: 2106,
          organicRank: 238,
          sponsoredRank: null,
          keywordSales: 44
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_quiet keyboard wireless',
      keyword: 'quiet keyboard wireless',
      searchVolume: 1209,
      organicStartRank: 233,
      organicHighestRank: 123,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 1209,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-30',
          searchVolume: 1209,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-29',
          searchVolume: 1209,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-28',
          searchVolume: 1209,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-27',
          searchVolume: 1209,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-26',
          searchVolume: 1386,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-25',
          searchVolume: 1386,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-24',
          searchVolume: 1386,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-23',
          searchVolume: 1386,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-22',
          searchVolume: 1386,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-21',
          searchVolume: 1386,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-20',
          searchVolume: 1386,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-19',
          searchVolume: 1343,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-18',
          searchVolume: 1343,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-17',
          searchVolume: 1343,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-16',
          searchVolume: 1343,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-15',
          searchVolume: 1343,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-14',
          searchVolume: 1343,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-13',
          searchVolume: 1343,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-12',
          searchVolume: 1334,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-11',
          searchVolume: 1334,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-10',
          searchVolume: 1334,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-09',
          searchVolume: 1334,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-08',
          searchVolume: 1334,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-07',
          searchVolume: 1334,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-06',
          searchVolume: 1334,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-05',
          searchVolume: 1633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-04',
          searchVolume: 1633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-03',
          searchVolume: 1633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-02',
          searchVolume: 1633,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-04-01',
          searchVolume: 1633,
          organicRank: 130,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-03-31',
          searchVolume: 1633,
          organicRank: 123,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-03-30',
          searchVolume: 1633,
          organicRank: 233,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-03-29',
          searchVolume: 1791,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 30
        },
        {
          date: '2024-03-28',
          searchVolume: 1791,
          organicRank: 45,
          sponsoredRank: null,
          keywordSales: 30
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_slim keyboard and mouse wireless',
      keyword: 'slim keyboard and mouse wireless',
      searchVolume: 909,
      organicStartRank: 82,
      organicHighestRank: 32,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-04',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 909,
          organicRank: 46,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-29',
          searchVolume: 909,
          organicRank: 47,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-28',
          searchVolume: 909,
          organicRank: 52,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-27',
          searchVolume: 909,
          organicRank: 38,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-26',
          searchVolume: 859,
          organicRank: 41,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-25',
          searchVolume: 859,
          organicRank: 43,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-24',
          searchVolume: 859,
          organicRank: 57,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-23',
          searchVolume: 859,
          organicRank: 48,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-22',
          searchVolume: 859,
          organicRank: 41,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-21',
          searchVolume: 859,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-20',
          searchVolume: 859,
          organicRank: 43,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-19',
          searchVolume: 895,
          organicRank: 42,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-18',
          searchVolume: 895,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-17',
          searchVolume: 895,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-16',
          searchVolume: 895,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-15',
          searchVolume: 895,
          organicRank: 35,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-14',
          searchVolume: 895,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-13',
          searchVolume: 895,
          organicRank: 32,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-12',
          searchVolume: 880,
          organicRank: 56,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-11',
          searchVolume: 880,
          organicRank: 60,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-10',
          searchVolume: 880,
          organicRank: 47,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-09',
          searchVolume: 880,
          organicRank: 62,
          sponsoredRank: 2,
          keywordSales: 15
        },
        {
          date: '2024-04-08',
          searchVolume: 880,
          organicRank: 60,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-07',
          searchVolume: 880,
          organicRank: 60,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-06',
          searchVolume: 880,
          organicRank: 83,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-05',
          searchVolume: 873,
          organicRank: 54,
          sponsoredRank: 1,
          keywordSales: 15
        },
        {
          date: '2024-04-04',
          searchVolume: 873,
          organicRank: 38,
          sponsoredRank: 1,
          keywordSales: 15
        },
        {
          date: '2024-04-03',
          searchVolume: 873,
          organicRank: 64,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-02',
          searchVolume: 873,
          organicRank: 117,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-04-01',
          searchVolume: 873,
          organicRank: 103,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-03-31',
          searchVolume: 873,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-03-30',
          searchVolume: 873,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-03-29',
          searchVolume: 924,
          organicRank: 82,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-03-28',
          searchVolume: 924,
          organicRank: 38,
          sponsoredRank: null,
          keywordSales: 15
        },
        {
          date: '2024-03-27',
          searchVolume: 924,
          organicRank: 181,
          sponsoredRank: null,
          keywordSales: 15
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_slim wireless keyboard and mouse',
      keyword: 'slim wireless keyboard and mouse',
      searchVolume: 845,
      organicStartRank: 57,
      organicHighestRank: 23,
      sponsoredStartRank: 27,
      sponsoredHighestRank: 27,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 845,
          organicRank: 51,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-30',
          searchVolume: 845,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-29',
          searchVolume: 845,
          organicRank: 50,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-28',
          searchVolume: 845,
          organicRank: 43,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-27',
          searchVolume: 845,
          organicRank: 32,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-26',
          searchVolume: 857,
          organicRank: 36,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-25',
          searchVolume: 857,
          organicRank: 40,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-24',
          searchVolume: 857,
          organicRank: 45,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-23',
          searchVolume: 857,
          organicRank: 41,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-22',
          searchVolume: 857,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-21',
          searchVolume: 857,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-20',
          searchVolume: 857,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-19',
          searchVolume: 895,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-18',
          searchVolume: 895,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-17',
          searchVolume: 895,
          organicRank: 48,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-16',
          searchVolume: 895,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-15',
          searchVolume: 895,
          organicRank: 38,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-14',
          searchVolume: 895,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-13',
          searchVolume: 895,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-12',
          searchVolume: 892,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-11',
          searchVolume: 892,
          organicRank: 43,
          sponsoredRank: 27,
          keywordSales: 10
        },
        {
          date: '2024-04-10',
          searchVolume: 892,
          organicRank: 35,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-09',
          searchVolume: 892,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-08',
          searchVolume: 892,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-07',
          searchVolume: 892,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-06',
          searchVolume: 892,
          organicRank: 56,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-05',
          searchVolume: 939,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-04',
          searchVolume: 939,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-03',
          searchVolume: 939,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-02',
          searchVolume: 939,
          organicRank: 84,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-01',
          searchVolume: 939,
          organicRank: 71,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-31',
          searchVolume: 939,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-30',
          searchVolume: 939,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-29',
          searchVolume: 924,
          organicRank: 57,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-28',
          searchVolume: 924,
          organicRank: 39,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-27',
          searchVolume: 924,
          organicRank: 128,
          sponsoredRank: null,
          keywordSales: 10
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_small wireless keyboard and mouse',
      keyword: 'small wireless keyboard and mouse',
      searchVolume: 1209,
      organicStartRank: 92,
      organicHighestRank: 92,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 1209,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-29',
          searchVolume: 1209,
          organicRank: 218,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-28',
          searchVolume: 1209,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-27',
          searchVolume: 1209,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-26',
          searchVolume: 1388,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-25',
          searchVolume: 1388,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-24',
          searchVolume: 1388,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-23',
          searchVolume: 1388,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-22',
          searchVolume: 1388,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-21',
          searchVolume: 1388,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-20',
          searchVolume: 1388,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-19',
          searchVolume: 1269,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-18',
          searchVolume: 1269,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-17',
          searchVolume: 1269,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-16',
          searchVolume: 1269,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-15',
          searchVolume: 1269,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-14',
          searchVolume: 1269,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-13',
          searchVolume: 1269,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-12',
          searchVolume: 1322,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-11',
          searchVolume: 1322,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-10',
          searchVolume: 1322,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-09',
          searchVolume: 1322,
          organicRank: 95,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-08',
          searchVolume: 1322,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-07',
          searchVolume: 1322,
          organicRank: 92,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-06',
          searchVolume: 1322,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-05',
          searchVolume: 1632,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-04',
          searchVolume: 1632,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-03',
          searchVolume: 1632,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-02',
          searchVolume: 1632,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-04-01',
          searchVolume: 1632,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-03-31',
          searchVolume: 1632,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-03-30',
          searchVolume: 1632,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-03-29',
          searchVolume: 1399,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 31
        },
        {
          date: '2024-03-28',
          searchVolume: 1399,
          organicRank: 69,
          sponsoredRank: null,
          keywordSales: 31
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wired logitech keyboard',
      keyword: 'wired logitech keyboard',
      searchVolume: 229,
      organicStartRank: 56,
      organicHighestRank: 56,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 229,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-29',
          searchVolume: 229,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-28',
          searchVolume: 229,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-27',
          searchVolume: 229,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-26',
          searchVolume: 180,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-25',
          searchVolume: 180,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-24',
          searchVolume: 180,
          organicRank: 59,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-23',
          searchVolume: 180,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-22',
          searchVolume: 180,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-21',
          searchVolume: 180,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-20',
          searchVolume: 180,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-19',
          searchVolume: 264,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-18',
          searchVolume: 264,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-17',
          searchVolume: 264,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-16',
          searchVolume: 264,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-15',
          searchVolume: 264,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-14',
          searchVolume: 264,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-13',
          searchVolume: 264,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-12',
          searchVolume: 306,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-11',
          searchVolume: 306,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-10',
          searchVolume: 306,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-09',
          searchVolume: 306,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-08',
          searchVolume: 306,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-07',
          searchVolume: 306,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-06',
          searchVolume: 306,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-05',
          searchVolume: 309,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-04',
          searchVolume: 309,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-03',
          searchVolume: 309,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-02',
          searchVolume: 309,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-01',
          searchVolume: 309,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-31',
          searchVolume: 309,
          organicRank: 77,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-30',
          searchVolume: 309,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-29',
          searchVolume: 404,
          organicRank: 56,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-28',
          searchVolume: 404,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: 10
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wireless keyboard',
      keyword: 'wireless keyboard',
      searchVolume: 163713,
      organicStartRank: 62,
      organicHighestRank: 57,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 163713,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-30',
          searchVolume: 163713,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-29',
          searchVolume: 163713,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-28',
          searchVolume: 163713,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-27',
          searchVolume: 163713,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-26',
          searchVolume: 181937,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-25',
          searchVolume: 181937,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-24',
          searchVolume: 181937,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-23',
          searchVolume: 181937,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-22',
          searchVolume: 181937,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-21',
          searchVolume: 181937,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-20',
          searchVolume: 181937,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-19',
          searchVolume: 180749,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-18',
          searchVolume: 180749,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-17',
          searchVolume: 180749,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-16',
          searchVolume: 180749,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-15',
          searchVolume: 180749,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-14',
          searchVolume: 180749,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-13',
          searchVolume: 180749,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-12',
          searchVolume: 182200,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-11',
          searchVolume: 182200,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-10',
          searchVolume: 182200,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-09',
          searchVolume: 182200,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-08',
          searchVolume: 182200,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-07',
          searchVolume: 182200,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-06',
          searchVolume: 182200,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-05',
          searchVolume: 188486,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-04',
          searchVolume: 188486,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-03',
          searchVolume: 188486,
          organicRank: 57,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-02',
          searchVolume: 188486,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-04-01',
          searchVolume: 188486,
          organicRank: 62,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-03-31',
          searchVolume: 188486,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-03-30',
          searchVolume: 188486,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        },
        {
          date: '2024-03-29',
          searchVolume: 195322,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 3020
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wireless keyboard and mouse',
      keyword: 'wireless keyboard and mouse',
      searchVolume: 215580,
      organicStartRank: 139,
      organicHighestRank: 39,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-04-04',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 215580,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-30',
          searchVolume: 215580,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-29',
          searchVolume: 215580,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-28',
          searchVolume: 215580,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-27',
          searchVolume: 215580,
          organicRank: 66,
          sponsoredRank: 8,
          keywordSales: 2798
        },
        {
          date: '2024-04-26',
          searchVolume: 220947,
          organicRank: 204,
          sponsoredRank: 23,
          keywordSales: 2798
        },
        {
          date: '2024-04-25',
          searchVolume: 220947,
          organicRank: 211,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-24',
          searchVolume: 220947,
          organicRank: 60,
          sponsoredRank: 11,
          keywordSales: 2798
        },
        {
          date: '2024-04-23',
          searchVolume: 220947,
          organicRank: 70,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-22',
          searchVolume: 220947,
          organicRank: 58,
          sponsoredRank: 11,
          keywordSales: 2798
        },
        {
          date: '2024-04-21',
          searchVolume: 220947,
          organicRank: 47,
          sponsoredRank: 10,
          keywordSales: 2798
        },
        {
          date: '2024-04-20',
          searchVolume: 220947,
          organicRank: 44,
          sponsoredRank: 22,
          keywordSales: 2798
        },
        {
          date: '2024-04-19',
          searchVolume: 210498,
          organicRank: 41,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-18',
          searchVolume: 210498,
          organicRank: 45,
          sponsoredRank: 15,
          keywordSales: 2798
        },
        {
          date: '2024-04-17',
          searchVolume: 210498,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-16',
          searchVolume: 210498,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-15',
          searchVolume: 210498,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-14',
          searchVolume: 210498,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-13',
          searchVolume: 210498,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-12',
          searchVolume: 210438,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-11',
          searchVolume: 210438,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-10',
          searchVolume: 210438,
          organicRank: 210,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-09',
          searchVolume: 210438,
          organicRank: null,
          sponsoredRank: 4,
          keywordSales: 2798
        },
        {
          date: '2024-04-08',
          searchVolume: 210438,
          organicRank: 49,
          sponsoredRank: 2,
          keywordSales: 2798
        },
        {
          date: '2024-04-07',
          searchVolume: 210438,
          organicRank: 39,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-06',
          searchVolume: 210438,
          organicRank: 40,
          sponsoredRank: 4,
          keywordSales: 2798
        },
        {
          date: '2024-04-05',
          searchVolume: 210957,
          organicRank: 56,
          sponsoredRank: 3,
          keywordSales: 2798
        },
        {
          date: '2024-04-04',
          searchVolume: 210957,
          organicRank: 53,
          sponsoredRank: 9,
          keywordSales: 2798
        },
        {
          date: '2024-04-03',
          searchVolume: 210957,
          organicRank: 66,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-02',
          searchVolume: 210957,
          organicRank: 56,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-04-01',
          searchVolume: 210957,
          organicRank: 47,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-03-31',
          searchVolume: 210957,
          organicRank: 58,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-03-30',
          searchVolume: 210957,
          organicRank: 120,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-03-29',
          searchVolume: 220979,
          organicRank: 139,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-03-28',
          searchVolume: 220979,
          organicRank: 133,
          sponsoredRank: null,
          keywordSales: 2798
        },
        {
          date: '2024-03-27',
          searchVolume: 220979,
          organicRank: 97,
          sponsoredRank: 21,
          keywordSales: 2798
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wireless keyboard and mouse combos',
      keyword: 'wireless keyboard and mouse combos',
      searchVolume: 270,
      organicStartRank: 52,
      organicHighestRank: 6,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-04-06',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-04-03',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 270,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-29',
          searchVolume: 270,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-28',
          searchVolume: 270,
          organicRank: 237,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-27',
          searchVolume: 270,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-26',
          searchVolume: 221,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-25',
          searchVolume: 221,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-24',
          searchVolume: 221,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-23',
          searchVolume: 221,
          organicRank: 204,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-22',
          searchVolume: 221,
          organicRank: 221,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-21',
          searchVolume: 221,
          organicRank: 199,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-20',
          searchVolume: 221,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-19',
          searchVolume: 262,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-18',
          searchVolume: 262,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-17',
          searchVolume: 262,
          organicRank: 14,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-16',
          searchVolume: 262,
          organicRank: 9,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-15',
          searchVolume: 262,
          organicRank: 6,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-14',
          searchVolume: 262,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-13',
          searchVolume: 262,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-12',
          searchVolume: 327,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-11',
          searchVolume: 327,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-10',
          searchVolume: 327,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-09',
          searchVolume: 327,
          organicRank: 15,
          sponsoredRank: 34,
          keywordSales: null
        },
        {
          date: '2024-04-08',
          searchVolume: 327,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-07',
          searchVolume: 327,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-06',
          searchVolume: 327,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-05',
          searchVolume: 320,
          organicRank: 42,
          sponsoredRank: 9,
          keywordSales: null
        },
        {
          date: '2024-04-04',
          searchVolume: 320,
          organicRank: 31,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-03',
          searchVolume: 320,
          organicRank: 26,
          sponsoredRank: 2,
          keywordSales: null
        },
        {
          date: '2024-04-02',
          searchVolume: 320,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-04-01',
          searchVolume: 320,
          organicRank: 34,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-03-31',
          searchVolume: 320,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-03-30',
          searchVolume: 320,
          organicRank: 43,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-03-29',
          searchVolume: 318,
          organicRank: 52,
          sponsoredRank: null,
          keywordSales: null
        },
        {
          date: '2024-03-28',
          searchVolume: 318,
          organicRank: 130,
          sponsoredRank: null,
          keywordSales: null
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wireless keyboard and mouse logitech',
      keyword: 'wireless keyboard and mouse logitech',
      searchVolume: 3460,
      organicStartRank: 33,
      organicHighestRank: 9,
      sponsoredStartRank: 21,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-04-03',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-03-31',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 3460,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 60
        },
        {
          date: '2024-04-30',
          searchVolume: 3460,
          organicRank: 13,
          sponsoredRank: 9,
          keywordSales: 60
        },
        {
          date: '2024-04-29',
          searchVolume: 3460,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 60
        },
        {
          date: '2024-04-28',
          searchVolume: 3460,
          organicRank: 10,
          sponsoredRank: null,
          keywordSales: 60
        },
        {
          date: '2024-04-27',
          searchVolume: 3460,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 60
        },
        {
          date: '2024-04-26',
          searchVolume: 3370,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 60
        },
        {
          date: '2024-04-25',
          searchVolume: 3370,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 60
        },
        {
          date: '2024-04-24',
          searchVolume: 3370,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-04-23',
          searchVolume: 3370,
          organicRank: 14,
          sponsoredRank: 3,
          keywordSales: 60
        },
        {
          date: '2024-04-22',
          searchVolume: 3370,
          organicRank: 12,
          sponsoredRank: 6,
          keywordSales: 60
        },
        {
          date: '2024-04-21',
          searchVolume: 3370,
          organicRank: 14,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-04-20',
          searchVolume: 3370,
          organicRank: 14,
          sponsoredRank: 1,
          keywordSales: 60
        },
        {
          date: '2024-04-19',
          searchVolume: 3404,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-04-18',
          searchVolume: 3404,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-04-17',
          searchVolume: 3404,
          organicRank: 14,
          sponsoredRank: 4,
          keywordSales: 60
        },
        {
          date: '2024-04-16',
          searchVolume: 3404,
          organicRank: null,
          sponsoredRank: 4,
          keywordSales: 60
        },
        {
          date: '2024-04-15',
          searchVolume: 3404,
          organicRank: null,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-04-14',
          searchVolume: 3404,
          organicRank: null,
          sponsoredRank: 10,
          keywordSales: 60
        },
        {
          date: '2024-04-13',
          searchVolume: 3404,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 60
        },
        {
          date: '2024-04-12',
          searchVolume: 3991,
          organicRank: null,
          sponsoredRank: 35,
          keywordSales: 60
        },
        {
          date: '2024-04-11',
          searchVolume: 3991,
          organicRank: 22,
          sponsoredRank: 4,
          keywordSales: 60
        },
        {
          date: '2024-04-10',
          searchVolume: 3991,
          organicRank: null,
          sponsoredRank: 15,
          keywordSales: 60
        },
        {
          date: '2024-04-09',
          searchVolume: 3991,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 60
        },
        {
          date: '2024-04-08',
          searchVolume: 3991,
          organicRank: 19,
          sponsoredRank: 4,
          keywordSales: 60
        },
        {
          date: '2024-04-07',
          searchVolume: 3991,
          organicRank: 15,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-04-06',
          searchVolume: 3991,
          organicRank: 15,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-04-05',
          searchVolume: 3563,
          organicRank: 17,
          sponsoredRank: 4,
          keywordSales: 60
        },
        {
          date: '2024-04-04',
          searchVolume: 3563,
          organicRank: 24,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-04-03',
          searchVolume: 3563,
          organicRank: 20,
          sponsoredRank: 4,
          keywordSales: 60
        },
        {
          date: '2024-04-02',
          searchVolume: 3563,
          organicRank: 22,
          sponsoredRank: 3,
          keywordSales: 60
        },
        {
          date: '2024-04-01',
          searchVolume: 3563,
          organicRank: 31,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-03-31',
          searchVolume: 3563,
          organicRank: 35,
          sponsoredRank: 2,
          keywordSales: 60
        },
        {
          date: '2024-03-30',
          searchVolume: 3563,
          organicRank: 34,
          sponsoredRank: 21,
          keywordSales: 60
        },
        {
          date: '2024-03-29',
          searchVolume: 3699,
          organicRank: 33,
          sponsoredRank: null,
          keywordSales: 60
        },
        {
          date: '2024-03-28',
          searchVolume: 3699,
          organicRank: 23,
          sponsoredRank: null,
          keywordSales: 60
        },
        {
          date: '2024-03-27',
          searchVolume: 3699,
          organicRank: 168,
          sponsoredRank: null,
          keywordSales: 60
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wireless keyboard mac',
      keyword: 'wireless keyboard mac',
      searchVolume: 1163,
      organicStartRank: 269,
      organicHighestRank: 269,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 1163,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-29',
          searchVolume: 1163,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-28',
          searchVolume: 1163,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-27',
          searchVolume: 1163,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-26',
          searchVolume: 1183,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-25',
          searchVolume: 1183,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-24',
          searchVolume: 1183,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-23',
          searchVolume: 1183,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-22',
          searchVolume: 1183,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-21',
          searchVolume: 1183,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-20',
          searchVolume: 1183,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-19',
          searchVolume: 1262,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-18',
          searchVolume: 1262,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-17',
          searchVolume: 1262,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-16',
          searchVolume: 1262,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-15',
          searchVolume: 1262,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-14',
          searchVolume: 1262,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-13',
          searchVolume: 1262,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-12',
          searchVolume: 1321,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-11',
          searchVolume: 1321,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-10',
          searchVolume: 1321,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-09',
          searchVolume: 1321,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-08',
          searchVolume: 1321,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-07',
          searchVolume: 1321,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-06',
          searchVolume: 1321,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-05',
          searchVolume: 939,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-04',
          searchVolume: 939,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-03',
          searchVolume: 939,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-02',
          searchVolume: 939,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-04-01',
          searchVolume: 939,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-03-31',
          searchVolume: 939,
          organicRank: 269,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-03-30',
          searchVolume: 939,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-03-29',
          searchVolume: 1368,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 14
        },
        {
          date: '2024-03-28',
          searchVolume: 1368,
          organicRank: 81,
          sponsoredRank: null,
          keywordSales: 14
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wireless keyboard mouse',
      keyword: 'wireless keyboard mouse',
      searchVolume: 2073,
      organicStartRank: 41,
      organicHighestRank: 13,
      sponsoredStartRank: 8,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-04-11',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-04-04',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 2073,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-30',
          searchVolume: 2073,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-29',
          searchVolume: 2073,
          organicRank: 29,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-28',
          searchVolume: 2073,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-27',
          searchVolume: 2073,
          organicRank: 18,
          sponsoredRank: 8,
          keywordSales: 40
        },
        {
          date: '2024-04-26',
          searchVolume: 2182,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-25',
          searchVolume: 2182,
          organicRank: 20,
          sponsoredRank: 3,
          keywordSales: 40
        },
        {
          date: '2024-04-24',
          searchVolume: 2182,
          organicRank: 29,
          sponsoredRank: 1,
          keywordSales: 40
        },
        {
          date: '2024-04-23',
          searchVolume: 2182,
          organicRank: 39,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-22',
          searchVolume: 2182,
          organicRank: 34,
          sponsoredRank: 9,
          keywordSales: 40
        },
        {
          date: '2024-04-21',
          searchVolume: 2182,
          organicRank: 32,
          sponsoredRank: 5,
          keywordSales: 40
        },
        {
          date: '2024-04-20',
          searchVolume: 2182,
          organicRank: 26,
          sponsoredRank: 9,
          keywordSales: 40
        },
        {
          date: '2024-04-19',
          searchVolume: 1808,
          organicRank: 26,
          sponsoredRank: 9,
          keywordSales: 40
        },
        {
          date: '2024-04-18',
          searchVolume: 1808,
          organicRank: 23,
          sponsoredRank: 3,
          keywordSales: 40
        },
        {
          date: '2024-04-17',
          searchVolume: 1808,
          organicRank: 22,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-16',
          searchVolume: 1808,
          organicRank: 26,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-15',
          searchVolume: 1808,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-14',
          searchVolume: 1808,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-13',
          searchVolume: 1808,
          organicRank: 25,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-12',
          searchVolume: 2078,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-11',
          searchVolume: 2078,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-10',
          searchVolume: 2078,
          organicRank: 24,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-09',
          searchVolume: 2078,
          organicRank: 42,
          sponsoredRank: 9,
          keywordSales: 40
        },
        {
          date: '2024-04-08',
          searchVolume: 2078,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-07',
          searchVolume: 2078,
          organicRank: 28,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-06',
          searchVolume: 2078,
          organicRank: 34,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-05',
          searchVolume: 1899,
          organicRank: 31,
          sponsoredRank: 4,
          keywordSales: 40
        },
        {
          date: '2024-04-04',
          searchVolume: 1899,
          organicRank: 30,
          sponsoredRank: 8,
          keywordSales: 40
        },
        {
          date: '2024-04-03',
          searchVolume: 1899,
          organicRank: 40,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-02',
          searchVolume: 1899,
          organicRank: 34,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-04-01',
          searchVolume: 1899,
          organicRank: 41,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-03-31',
          searchVolume: 1899,
          organicRank: 40,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-03-30',
          searchVolume: 1899,
          organicRank: 41,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-03-29',
          searchVolume: 2105,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 40
        },
        {
          date: '2024-03-28',
          searchVolume: 2105,
          organicRank: 87,
          sponsoredRank: null,
          keywordSales: 40
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wireless keyboard mouse combo',
      keyword: 'wireless keyboard mouse combo',
      searchVolume: 24815,
      organicStartRank: 104,
      organicHighestRank: 5,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-04-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-04-04',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 24815,
          organicRank: 29,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-30',
          searchVolume: 24815,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-29',
          searchVolume: 24815,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-28',
          searchVolume: 24815,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-27',
          searchVolume: 24815,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-26',
          searchVolume: 28109,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-25',
          searchVolume: 28109,
          organicRank: 30,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-24',
          searchVolume: 28109,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-23',
          searchVolume: 28109,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-22',
          searchVolume: 28109,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-21',
          searchVolume: 28109,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-20',
          searchVolume: 28109,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-19',
          searchVolume: 35539,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-18',
          searchVolume: 35539,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-17',
          searchVolume: 35539,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-16',
          searchVolume: 35539,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-15',
          searchVolume: 35539,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-14',
          searchVolume: 35539,
          organicRank: 5,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-13',
          searchVolume: 35539,
          organicRank: 7,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-12',
          searchVolume: 39522,
          organicRank: 21,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-11',
          searchVolume: 39522,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-10',
          searchVolume: 39522,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-09',
          searchVolume: 39522,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-08',
          searchVolume: 39522,
          organicRank: 9,
          sponsoredRank: 5,
          keywordSales: 457
        },
        {
          date: '2024-04-07',
          searchVolume: 39522,
          organicRank: 6,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-06',
          searchVolume: 39522,
          organicRank: 6,
          sponsoredRank: 4,
          keywordSales: 457
        },
        {
          date: '2024-04-05',
          searchVolume: 39885,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 457
        },
        {
          date: '2024-04-04',
          searchVolume: 39885,
          organicRank: 14,
          sponsoredRank: 1,
          keywordSales: 457
        },
        {
          date: '2024-04-03',
          searchVolume: 39885,
          organicRank: 27,
          sponsoredRank: 23,
          keywordSales: 457
        },
        {
          date: '2024-04-02',
          searchVolume: 39885,
          organicRank: 35,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-04-01',
          searchVolume: 39885,
          organicRank: 69,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-03-31',
          searchVolume: 39885,
          organicRank: 93,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-03-30',
          searchVolume: 39885,
          organicRank: 97,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-03-29',
          searchVolume: 45560,
          organicRank: 104,
          sponsoredRank: null,
          keywordSales: 457
        },
        {
          date: '2024-03-28',
          searchVolume: 45560,
          organicRank: 231,
          sponsoredRank: null,
          keywordSales: 457
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wireless mac keyboard',
      keyword: 'wireless mac keyboard',
      searchVolume: 1164,
      organicStartRank: 260,
      organicHighestRank: 260,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 1164,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-29',
          searchVolume: 1164,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-28',
          searchVolume: 1164,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-27',
          searchVolume: 1164,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-26',
          searchVolume: 1181,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-25',
          searchVolume: 1181,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-24',
          searchVolume: 1181,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-23',
          searchVolume: 1181,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-22',
          searchVolume: 1181,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-21',
          searchVolume: 1181,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-20',
          searchVolume: 1181,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-19',
          searchVolume: 893,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-18',
          searchVolume: 893,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-17',
          searchVolume: 893,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-16',
          searchVolume: 893,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-15',
          searchVolume: 893,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-14',
          searchVolume: 893,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-13',
          searchVolume: 893,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-12',
          searchVolume: 880,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-11',
          searchVolume: 880,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-10',
          searchVolume: 880,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-09',
          searchVolume: 880,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-08',
          searchVolume: 880,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-07',
          searchVolume: 880,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-06',
          searchVolume: 880,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-05',
          searchVolume: 1249,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-04',
          searchVolume: 1249,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-03',
          searchVolume: 1249,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-02',
          searchVolume: 1249,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-04-01',
          searchVolume: 1249,
          organicRank: 260,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-03-31',
          searchVolume: 1249,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-03-30',
          searchVolume: 1249,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-03-29',
          searchVolume: 922,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 16
        },
        {
          date: '2024-03-28',
          searchVolume: 922,
          organicRank: 91,
          sponsoredRank: null,
          keywordSales: 16
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_wireless mouse and keyboard logitech',
      keyword: 'wireless mouse and keyboard logitech',
      searchVolume: 642,
      organicStartRank: 62,
      organicHighestRank: 11,
      sponsoredStartRank: 58,
      sponsoredHighestRank: 15,
      organicPageOneWinDate: '2024-04-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-04-06',
      sponsoredPageOneWin: true,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-05-01',
          searchVolume: 642,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-30',
          searchVolume: 642,
          organicRank: 17,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-29',
          searchVolume: 642,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-28',
          searchVolume: 642,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-27',
          searchVolume: 642,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-26',
          searchVolume: 724,
          organicRank: 11,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-25',
          searchVolume: 724,
          organicRank: 12,
          sponsoredRank: 36,
          keywordSales: 10
        },
        {
          date: '2024-04-24',
          searchVolume: 724,
          organicRank: 13,
          sponsoredRank: 29,
          keywordSales: 10
        },
        {
          date: '2024-04-23',
          searchVolume: 724,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-22',
          searchVolume: 724,
          organicRank: 13,
          sponsoredRank: 18,
          keywordSales: 10
        },
        {
          date: '2024-04-21',
          searchVolume: 724,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-20',
          searchVolume: 724,
          organicRank: 13,
          sponsoredRank: 47,
          keywordSales: 10
        },
        {
          date: '2024-04-19',
          searchVolume: 703,
          organicRank: 12,
          sponsoredRank: 47,
          keywordSales: 10
        },
        {
          date: '2024-04-18',
          searchVolume: 703,
          organicRank: 14,
          sponsoredRank: 54,
          keywordSales: 10
        },
        {
          date: '2024-04-17',
          searchVolume: 703,
          organicRank: 15,
          sponsoredRank: 42,
          keywordSales: 10
        },
        {
          date: '2024-04-16',
          searchVolume: 703,
          organicRank: 19,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-15',
          searchVolume: 703,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-14',
          searchVolume: 703,
          organicRank: 13,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-13',
          searchVolume: 703,
          organicRank: 15,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-12',
          searchVolume: 623,
          organicRank: 19,
          sponsoredRank: 24,
          keywordSales: 10
        },
        {
          date: '2024-04-11',
          searchVolume: 623,
          organicRank: 22,
          sponsoredRank: 22,
          keywordSales: 10
        },
        {
          date: '2024-04-10',
          searchVolume: 623,
          organicRank: 12,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-09',
          searchVolume: 623,
          organicRank: 20,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-08',
          searchVolume: 623,
          organicRank: 22,
          sponsoredRank: 15,
          keywordSales: 10
        },
        {
          date: '2024-04-07',
          searchVolume: 623,
          organicRank: 18,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-06',
          searchVolume: 623,
          organicRank: 17,
          sponsoredRank: 18,
          keywordSales: 10
        },
        {
          date: '2024-04-05',
          searchVolume: 502,
          organicRank: 16,
          sponsoredRank: 58,
          keywordSales: 10
        },
        {
          date: '2024-04-04',
          searchVolume: 502,
          organicRank: 16,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-03',
          searchVolume: 502,
          organicRank: 27,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-02',
          searchVolume: 502,
          organicRank: 45,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-04-01',
          searchVolume: 502,
          organicRank: 79,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-31',
          searchVolume: 502,
          organicRank: 79,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-30',
          searchVolume: 502,
          organicRank: 77,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-29',
          searchVolume: 724,
          organicRank: 62,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-28',
          searchVolume: 724,
          organicRank: 30,
          sponsoredRank: null,
          keywordSales: 10
        },
        {
          date: '2024-03-27',
          searchVolume: 724,
          organicRank: 130,
          sponsoredRank: null,
          keywordSales: 10
        }
      ]
    },
    {
      campaignId: 'sample-campaign-headphones',
      campaignId_keyword: 'sample-campaign-headphones_work keyboard',
      keyword: 'work keyboard',
      searchVolume: 642,
      organicStartRank: 96,
      organicHighestRank: 96,
      sponsoredStartRank: null,
      sponsoredHighestRank: null,
      organicPageOneWinDate: null,
      organicPageOneWin: false,
      sponsoredPageOneWinDate: null,
      sponsoredPageOneWin: false,
      hasAmazonChoiceBadge: null,
      records: [
        {
          date: '2024-04-30',
          searchVolume: 642,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-29',
          searchVolume: 642,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-28',
          searchVolume: 642,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-27',
          searchVolume: 642,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-26',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-25',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-24',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-23',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-22',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-21',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-20',
          searchVolume: 652,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-19',
          searchVolume: 701,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-18',
          searchVolume: 701,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-17',
          searchVolume: 701,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-16',
          searchVolume: 701,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-14',
          searchVolume: 701,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-13',
          searchVolume: 701,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-12',
          searchVolume: 748,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-11',
          searchVolume: 748,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-10',
          searchVolume: 748,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-09',
          searchVolume: 748,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-08',
          searchVolume: 748,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-07',
          searchVolume: 748,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-06',
          searchVolume: 748,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-05',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-04',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-03',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-02',
          searchVolume: 736,
          organicRank: 114,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-04-01',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-31',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-30',
          searchVolume: 736,
          organicRank: null,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-29',
          searchVolume: 654,
          organicRank: 96,
          sponsoredRank: null,
          keywordSales: 7
        },
        {
          date: '2024-03-28',
          searchVolume: 654,
          organicRank: 83,
          sponsoredRank: null,
          keywordSales: 7
        }
      ]
    }
  ],
  nextToken: null
}

import { MEDIA_CDN } from '@momentum/env'

export const getImageKeyFromUrl = (url: string) => {
  if (url.includes('/public/')) {
    return url.split('/public/')[1]
  }

  return url.split('.com/').pop()
}

export const noProductImageAlt = (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
  ev.currentTarget.onerror = null
  ev.currentTarget.src = '/images/no-product.png'
}

export const noProductImageSmallAlt = (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
  ev.currentTarget.onerror = null
  ev.currentTarget.src = '/images/no-product-small.png'
}

export const getCdnImageUrl = (urlKey?: string) => {
  if (urlKey) {
    if (urlKey.startsWith('http')) {
      return urlKey
    }

    return MEDIA_CDN + urlKey
  }
}

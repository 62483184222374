import { yupResolver } from '@hookform/resolvers/yup'
import LogoWhiteIcon from '@momentum/components/icons/logo-white'
import Loading from '@momentum/components/loading'
import { PhoneInput } from '@momentum/components/phone-input'
import { ROUTES } from '@momentum/routes/RouteNames'
import background from '@momentum/routes/account/background-gradient.png'
import { Invitation, getInvitation, signupUser } from '@momentum/routes/account/signup/queries'
import { getRoleOptions } from '@momentum/utils/selectOptions'
import BusinessIcon from '@mui/icons-material/Business'
import { Box, Container, Link, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import { Role } from '@productwindtom/shared-momentum-zeus-types'
import { Form, FormMethodsType, SelectInput, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { getAuthDomainConfigurationFromEmail } from '@productwindtom/shared-momentum'
import { MfaDialog, MfaUser } from '@momentum/components/mfa-dialog'

type SignupData = {
  firstName: string
  lastName: string
  role: Role
  brands: string[]
  phone?: string
  email: string
  password: string
  confirmPassword: string
}

const loginSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  role: yup.mixed<Role>().required('Required'),
  phone: yup
    .string()
    .transform(value => value?.trim()?.replaceAll(/\s/g, ''))
    .test('domain-required', 'Required', (value, { parent }) =>
      getAuthDomainConfigurationFromEmail(parent.email)?.isMfaEnforced ? !!value : true
    ),
  email: yup
    .string()
    .required('Required')
    .email('Invalid email')
    .transform(value => value?.toLowerCase()?.trim()),
  password: yup
    .string()
    .required('Required')
    .matches(/(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .matches(/(?=.*[!@#$%^&*])/, 'Password must contain at least one special character')
    .test({
      name: 'custom-password',
      test: (value, context) => {
        const config = getAuthDomainConfigurationFromEmail(context.parent.email)

        const passwordCharacterLimit = config?.passwordCharacterLimit || 10

        return value.length >= passwordCharacterLimit
          ? true
          : context.createError({ message: `Must be at least ${passwordCharacterLimit} characters long` })
      }
    }),
  brands: yup.array().of(yup.string().required()).required(),
  confirmPassword: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password')], 'Passwords must match')
})

export default function SignUp() {
  const theme = useTheme()
  const params = useParams<{ id?: string }>()
  const inviteParam = params.id

  const navigate = useNavigate()

  const [loaded, setLoaded] = useState<boolean>(false)
  const [invitation, setInvitation] = useState<Invitation | undefined>()
  const [user, setUser] = useState<MfaUser>()
  const [showMfaDialog, setShowMfaDialog] = useState<boolean>(false)

  useEffect(() => {
    if (inviteParam) {
      getInvitation(inviteParam)
        .then(setInvitation)
        .finally(() => setLoaded(true))
    } else {
      setLoaded(true)
    }
  }, [])

  const onSubmit = async (data: SignupData, { setError }: FormMethodsType<SignupData>) => {
    try {
      const signupRes = await signupUser({
        username: data.email,
        password: data.password,
        attributes: JSON.stringify({
          ...(data.phone ? { phone_number: data.phone } : {}),
          'given_name': data.firstName,
          'family_name': data.lastName,
          'custom:role': data.role,
          'custom:invitationId': inviteParam
        })
      })

      if (!signupRes.success) throw signupRes

      toast(
        <Typography data-cy={'successfulLoginText'} variant={'subtitle2'}>
          Successfully signed up for Momentum!
        </Typography>,
        { type: 'success' }
      )
      const resp = await Auth.signIn(data.email, data.password)
      setUser(resp)
      if (resp.challengeName === 'SMS_MFA') {
        setShowMfaDialog(true)
      }
    } catch (e: any) {
      switch (e.code) {
        case 'UsernameExistsException':
          setError('email', { message: 'User already exists' })
          break
        case 'TooManyRequestsException':
          toast(<Typography variant={'subtitle2'}>Please try again later</Typography>, { type: 'error' })
          break
        default:
          toast(<Typography variant={'subtitle2'}>Unknown issue, please try again later</Typography>, { type: 'error' })
          break
      }
    }
  }

  if (!loaded) return <Loading />

  const initialInput = invitation
    ? {
        email: invitation.email,
        firstName: invitation.firstName,
        lastName: invitation.lastName,
        role: invitation.role,
        phone: invitation.phoneNumber,
        brands: invitation.brandIds || []
      }
    : { email: '', password: '', brands: [] }

  return (
    <Container maxWidth={'xl'} sx={{ height: '100vh', alignItems: 'center' }}>
      <Stack direction={'row'} spacing={10} height={'100vh'}>
        <Box py={2} width={'70%'} display={'inline-flex'}>
          <Box
            bgcolor={theme.palette.primary.main}
            borderRadius={'16px'}
            display={'inline-flex'}
            width={'100%'}
            position={'relative'}
          >
            <Stack direction={'row'} spacing={1} alignItems={'center'} position={'absolute'} left={24} top={24}>
              <LogoWhiteIcon />
              <Typography variant={'h3'} mb={2} color={'white'}>
                momentum
              </Typography>
            </Stack>
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              spacing={2}
              width={'100%'}
              sx={{
                background: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            >
              <Typography color={'white'} variant={'h2'} width={'226px'}>
                Welcome to Momentum
              </Typography>
              <Typography color={'white'} variant={'subtitle2'} width={'226px'}>
                Complete the sign up form to join your team on Momentum.
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box alignSelf={'center'} width={'30%'}>
          <Form onSubmit={onSubmit} defaultValues={initialInput} resolver={yupResolver(loginSchema)}>
            <Stack spacing={2} alignItems={'center'}>
              <Stack direction={'row'} alignItems={'center'} width="100%" pb={2} spacing={2}>
                {!!invitation?.company?.logo && (
                  <img
                    src={invitation.company.logo}
                    alt={'Company'}
                    width={64}
                    height={64}
                    style={{ objectFit: 'contain' }}
                  />
                )}
                <Stack spacing={1}>
                  <Typography variant={'h2'}>Sign up</Typography>
                  {!!invitation?.company?.name && (
                    <Typography variant={'subtitle2'} color={'primary'}>
                      <BusinessIcon fontSize={'small'} /> {invitation?.company?.name}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction={'row'} spacing={2} width={'100%'}>
                <TextInput name={'firstName'} primaryText={'First name'} fullWidth />
                <TextInput name={'lastName'} primaryText={'Last name'} fullWidth />
              </Stack>
              <TextInput name={'email'} primaryText={'Email'} fullWidth disabled={!!invitation} />
              <PhoneInput
                name={'phone'}
                primaryText={'Phone number'}
                defaultCountry={'US'}
                onlyCountries={['US', 'CA', 'DE', 'GB', 'PT', 'FR', 'ES', 'IT']}
                fullWidth
              />
              <SelectInput
                options={getRoleOptions(!!invitation?.agencyId && !invitation?.companyId)}
                name={'role'}
                primaryText={'Role'}
                fullWidth
                disableClearable
              />
              <TextInput
                data-cy={'passwordInput'}
                name={'password'}
                primaryText={'Password'}
                type={'password'}
                autoComplete={'new-password'}
                fullWidth
              />
              <TextInput
                data-cy={'confirmPasswordInput'}
                name={'confirmPassword'}
                primaryText={'Re-enter password'}
                type={'password'}
                autoComplete={'new-password'}
                fullWidth
              />
              <Stack
                direction={'row'}
                justifyContent={'flex-start'}
                width={'100%'}
                spacing={2}
                alignItems={'center'}
                pt={1}
              >
                <SubmitButton data-cy={'submit'} variant={'contained'}>
                  Sign up
                </SubmitButton>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography variant={'label2'}>Already have an account?</Typography>
                  <Link component={RouterLink} variant={'link2'} color={'primary'} to={ROUTES.LOGIN}>
                    Log in
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </Form>
        </Box>
      </Stack>
      {user && <MfaDialog open={showMfaDialog} user={user} onClose={() => navigate(ROUTES.LOGIN)} />}
    </Container>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { Close } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import { CampaignCreatorApprovalAction } from '@productwindtom/shared-momentum-zeus-types'
import { Form, SubmitButton, TextInput } from '@productwindtom/ui-base'
import * as yup from 'yup'
import { CampaignCreatorForApproval } from '../../context/queries'

type ApproveCreatorDialogProps = {
  creator: CampaignCreatorForApproval
  action: CampaignCreatorApprovalAction
  handleClose: () => void
  handleConfirm: (creatorId: string, action: CampaignCreatorApprovalAction, feedback?: string | null) => Promise<void>
}

const DIALOG_CONTENT = {
  [CampaignCreatorApprovalAction.APPROVE]: {
    title: 'Are you sure you want to approve this creator?',
    description: 'The creator will be notified and start making content!',
    placeholder: 'Leave your comment...',
    confirmText: 'Approve'
  },
  [CampaignCreatorApprovalAction.REJECT]: {
    title: 'Are you sure you want to reject this creator?',
    description: 'The creator will be notified and removed from your campaign.',
    placeholder: 'Provide feedback',
    confirmText: 'Reject'
  }
}

const schema = yup.object().shape({
  feedback: yup.string().notRequired().trim()
})

type ApproveCreatorFormData = { feedback?: string | null }

export const ApproveCreatorDialog = (props: ApproveCreatorDialogProps) => {
  const content = DIALOG_CONTENT[props.action]

  const onSubmit = async (data: ApproveCreatorFormData) => {
    await props.handleConfirm(props.creator.userCampaignId, props.action, data.feedback || null)
  }

  const handleClose = () => {
    props.handleClose()
  }

  return (
    <Dialog open onClose={handleClose} style={{ padding: 8 }} maxWidth={'xs'}>
      <Form onSubmit={onSubmit} resolver={yupResolver(schema)} >
        <DialogContent>
          <Stack direction="column">
            <Stack direction="row" spacing={2} alignItems={'center'}>
              <Typography variant="h4" flex={1}>
                {content.title}
              </Typography>
              <IconButton
                size="small"
                onClick={handleClose}
                sx={{
                  color: theme => theme.palette.grey.A700
                }}
              >
                <Close />
              </IconButton>
            </Stack>

            <Typography variant="label3" mt={2}>
              {content.description}
            </Typography>

            <Typography variant="label3" mt={3}>
              Provided feedback (optional):
            </Typography>

            <Typography variant="body1" color={theme => theme.palette.grey.A700}>
              Your feedback is not shared with the creator.
            </Typography>

            <TextInput
              name="feedback"
              fullWidth
              autoFocus
              placeholder={content.placeholder}
              variant="outlined"
              autoComplete="off"
              multiline
              rows={4}
              sx={{ pt: 2 }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <SubmitButton variant={'contained'} color={'primary'}>
            {content.confirmText}
          </SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

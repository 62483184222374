import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import 'chart.js/auto'
import 'chartjs-adapter-luxon'
import { sortBy } from 'lodash'
import { DateTime } from 'luxon'
import { Line } from 'react-chartjs-2'

export const SeoGraph = () => {
  const theme = useTheme()
  const { legacyProductSEOSummary, campaignDetails } = useCampaignContext()

  const startDate = DateTime.fromISO(campaignDetails.startDate)
  const endDate = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate) : undefined

  const weeklyImpressions = sortBy(legacyProductSEOSummary?.productSEOSummaryRecords, r => r.weekIndex).reduce(
    (acc, record) => {
      acc.push({
        weekIndex: record.weekIndex,
        impressions: (acc[acc.length - 1]?.impressions ?? 0) + (record.impressions ?? 0)
      })
      return acc
    },
    [] as { weekIndex: number; impressions: number }[]
  )

  const labels = weeklyImpressions.map(({ weekIndex }) => `Week ${weekIndex}`)

  const isWeekStartOfCampaign = (weekIndex: number) => weekIndex === 1

  const isWeekEndOfCampaign = (weekIndex: number) =>
    endDate &&
    startDate
      .plus({ weeks: weekIndex - 1 })
      .startOf('week')
      .equals(endDate.startOf('week'))

  return (
    <Stack spacing={3}>
      <Typography variant={'h4'}>Product impressions in organic search results</Typography>
      <Box>
        <Line
          data={{
            labels,
            datasets: [
              {
                borderColor: theme.palette.primary.main,
                pointStyle: 'circle',
                pointRadius: 9,
                pointBorderWidth: 2,
                pointHoverRadius: 9,
                pointHoverBorderWidth: 2,
                pointBorderColor: 'white',
                pointBackgroundColor: theme.palette.primary.main,
                data: weeklyImpressions.map(({ impressions }) => impressions)
              },
              {
                borderColor: theme.palette.primary.main,
                pointStyle: 'circle',
                pointRadius: 9,
                pointBorderWidth: 2,
                pointHoverRadius: 9,
                pointHoverBorderWidth: 2,
                pointBorderColor: 'white',
                pointBackgroundColor: theme.palette.primary.main,
                showLine: false,
                data: weeklyImpressions.map(({ weekIndex }) =>
                  isWeekStartOfCampaign(weekIndex) || isWeekEndOfCampaign(weekIndex) ? 0 : null
                ) as number[]
              }
            ]
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                ...defaultTooltipOptions,
                filter: tooltipItem => tooltipItem.datasetIndex === 1,
                callbacks: {
                  title: () => '',
                  label: tooltipItem => {
                    if (isWeekStartOfCampaign(tooltipItem.dataIndex + 1)) {
                      return 'Campaign start date'
                    }
                    if (isWeekEndOfCampaign(tooltipItem.dataIndex + 1)) {
                      return 'Campaign end date'
                    }
                    return ''
                  }
                }
              },
              legend: {
                display: false
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  drawOnChartArea: false,
                  drawTicks: false
                },
                offset: false,
                ticks: {
                  padding: 16,
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  }
                },
                border: {
                  color: 'black',
                  width: 3
                }
              },
              x: {
                type: 'category',
                offset: true,
                ticks: {
                  minRotation: 45,
                  padding: 16,
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  color: value => {
                    return isWeekStartOfCampaign(value.index + 1) ||
                      (endDate &&
                        startDate.plus({ weeks: value.index }).startOf('week').equals(endDate.startOf('week')))
                      ? theme.palette.primary.main
                      : 'black'
                  }
                },
                grid: {
                  drawOnChartArea: true,
                  drawTicks: false,
                  color: function (value) {
                    return value.index === 0 ||
                      (endDate &&
                        startDate.plus({ weeks: value.index }).startOf('week').equals(endDate.startOf('week')))
                      ? theme.palette.grey.A400
                      : 'transparent'
                  }
                },
                border: {
                  color: 'black',
                  width: 3
                }
              }
            }
          }}
          height={'560px'}
        />
      </Box>
    </Stack>
  )
}

import { View } from '@react-pdf/renderer'
import { FeedbackPDFProps } from '.'
import { FeedbackDoughnutPDF } from './FeedbackDoughnutPDF'
import { FeedbackTablePDF } from './FeedbackTablePDF'
import { styles } from './styles'

export const FeedbackDataDisplayPDF = (props: FeedbackPDFProps) => {
  return (
    <View
      style={[
        styles.card,
        styles.row,
        styles.debug,
        {
          marginTop: 24,
          paddingVertical: 16,
          paddingHorizontal: 0
        }
      ]}
    >
      <FeedbackDoughnutPDF {...props} />
      <FeedbackTablePDF {...props} />
    </View>
  )
}

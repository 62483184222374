import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Form } from '@productwindtom/ui-base'
import { debounce, keyBy, without } from 'lodash'
import { updateUserCampaignSocialContent } from '../queries'
import { ContentContextProps, ContentContextType, ContentFilterData, ContentSortBy, CreatorAndContent } from '../types'
import { useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material'
import ContentGrid from '../components/ContentGrid'
import ContentFilters from '../components/ContentFilters'
import { Close } from '@mui/icons-material'
import ContentDialog from '../components/ContentDialog'
import ContentActions from '../components/ContentActions'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { captureException } from '@sentry/react'

const ContentProvider = ({ updateContentGroup, onFilterChange, isSearch, content }: ContentContextProps) => {
  const { isAdminView } = useUserSessionContext()
  const [expandedContent, setExpandedContent] = useState<CreatorAndContent | null>(null)
  const [selectedContent, setSelectedContent] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [filteredContent, setFilteredContent] = useState<CreatorAndContent[]>([])

  const formData = useWatch() as ContentFilterData

  const handleFilter = useCallback(
    debounce(async (data: ContentFilterData) => {
      try {
        setIsLoading(true)

        const brandContent = await onFilterChange(data)

        setFilteredContent(brandContent || [])
      } catch (err) {
        captureException(err)
        console.log('err', err)
        toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
          type: 'error'
        })
      } finally {
        setIsLoading(false)
      }
    }, 500),
    [onFilterChange]
  )

  useEffect(() => {
    handleFilter(formData)
  }, [formData, handleFilter])

  const keyedContent = useMemo(() => keyBy(filteredContent, 'groupId'), [filteredContent])

  const selectContent = (contentId: string) => {
    setSelectedContent([...selectedContent, contentId])
  }

  const selectAllContent = () => {
    setSelectedContent((filteredContent || []).map(c => c.groupId))
  }

  const unselectAllContent = () => {
    setSelectedContent([])
  }

  const unselectContent = (contentId: string) => {
    setSelectedContent(without(selectedContent, contentId))
  }

  const favoriteContent = async (groupId: string) => {
    const contentGroup = keyedContent[groupId]

    if (contentGroup) {
      await Promise.all(contentGroup.content.map(c => updateUserCampaignSocialContent({ id: c.id, isFavorite: true })))
      updateContentGroup?.({ groupId, isFavorite: true })
    }
  }

  const unfavoriteContent = async (groupId: string) => {
    const contentGroup = keyedContent[groupId]

    if (contentGroup) {
      await Promise.all(contentGroup.content.map(c => updateUserCampaignSocialContent({ id: c.id, isFavorite: false })))
      updateContentGroup?.({ groupId, isFavorite: false })
    }
  }

  const setContentIsHidden = async (groupId: string, isHidden: boolean) => {
    const contentGroup = keyedContent[groupId]

    if (contentGroup) {
      await Promise.all(
        contentGroup.content.map(c => updateUserCampaignSocialContent({ id: c.id, isHidden: isHidden }))
      )

      updateContentGroup?.({ groupId, isHidden: isHidden })
    }
  }

  const expandContent = (groupId: string) => {
    setExpandedContent(keyedContent[groupId] || null)
  }

  const visibleContent = useMemo(() => {
    return isAdminView ? filteredContent : filteredContent.filter(content => !content.isHidden)
  }, [filteredContent, isAdminView])

  return (
    <ContentContext.Provider
      value={{
        isLoading,
        selectedContent,
        selectContent,
        unselectContent,
        unselectAllContent,
        favoriteContent,
        unfavoriteContent,
        setContentIsHidden,
        setExpandedContent,
        expandContent,
        selectAllContent,
        filteredContent: visibleContent,
        content: content || [],
        isSearch,
        updateContentGroup
      }}
    >
      <Stack spacing={3}>
        <ContentActions />
        <ContentFilters />
        <ContentGrid />
      </Stack>
      <Dialog open={!!expandedContent} onClose={() => setExpandedContent(null)} fullWidth maxWidth={'md'}>
        <Stack direction={'row'} justifyContent={'flex-end'} px={2} pt={1}>
          <IconButton
            aria-label="close"
            onClick={() => setExpandedContent(null)}
            sx={{
              color: theme => theme.palette.grey.A700
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        <DialogContent>{expandedContent && <ContentDialog content={expandedContent} />}</DialogContent>
      </Dialog>
    </ContentContext.Provider>
  )
}

export default (props: ContentContextProps) => (
  <Form
    onSubmit={() => null}
    defaultValues={{
      excludedContentTypes: [],
      sortBy: ContentSortBy.RELEVANCE
    }}
  >
    <ContentProvider {...props} />
  </Form>
)

const ContentContext = createContext<ContentContextType>({} as ContentContextType)
export const useContentContext = () => useContext(ContentContext)

import { createContext, useContext } from 'react'
import { CreateProposalContextType } from './types'

export const CreateProposalContext = createContext<CreateProposalContextType>({
  setSelectedProduct: () => undefined,
  onDeleteProposal: async () => undefined,
  onDuplicateProposal: async () => undefined,
  isDeliverablesVisible: false,
  setIsDeliverablesVisible: () => undefined,
  isFinancialSummaryView: false,
  setIsFinancialSummaryView: () => false,
  selectableProducts: []
})

export const useCreateProposalContext = () => useContext(CreateProposalContext)

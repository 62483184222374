import { Stack } from '@mui/system'
import { Typography } from '@mui/material'
import { NotSupportedIcon } from '@momentum/routes/campaign/content/NotSupportedIcon'

export const NotSupportedState = () => {
  return (
    <Stack pt={12} spacing={4} alignItems={'center'} justifyContent={'center'}>
      <NotSupportedIcon sx={{ height: '145px', width: '210px' }} />
      <Typography variant={'h5'}>Campaigns with only Brand Advocates do not feature content.</Typography>
      <Typography variant={'label4'} width={'327px'} textAlign={'center'}>
        Create a campaign with UGC or Social Creators to generate content for your product.
      </Typography>
    </Stack>
  )
}

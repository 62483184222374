import { SvgIcon, SvgIconProps } from '@mui/material'

export default function PendingCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12" fill="none">
      <mask id="mask0_7913_203925" type={'alpha'} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31372 12 12 9.31371 12 6C12 2.68629 9.31372 0 6 0ZM6.00002 10.7996C8.65054 10.7996 10.7992 8.6509 10.7992 6.00038C10.7992 3.34985 8.65054 1.20117 6.00002 1.20117C3.34949 1.20117 1.20081 3.34985 1.20081 6.00038C1.20081 8.6509 3.34949 10.7996 6.00002 10.7996Z"
          fill="#02C8B0"
        />
      </mask>
      <g mask="url(#mask0_7913_203925)">
        <path
          opacity="0.9"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5021 -1.84255C14.5021 -2.04169 14.3407 -2.20312 14.1416 -2.20312H6.23522C6.03608 -2.20312 5.87464 -2.04169 5.87464 -1.84255V4.52434C5.87464 4.90262 5.36641 5.02565 5.19341 4.68925L3.85107 2.07907C3.76612 1.91388 3.83437 1.7128 3.91639 1.54613C3.95663 1.46436 3.97929 1.372 3.97929 1.27424C3.97929 0.939406 3.7135 0.667969 3.38564 0.667969C3.24984 0.667969 3.10229 0.62306 3.04018 0.502289L2.15753 -1.21402C2.01802 -1.48529 1.62568 -1.47156 1.50547 -1.19121L-3.43155 10.3226C-3.5222 10.534 -3.39438 10.7751 -3.16851 10.8187L14.0732 14.1481C14.2957 14.1911 14.5021 14.0207 14.5021 13.7941V-1.84255Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  )
}

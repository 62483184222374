import { Container, Stack, Typography, Box, Link } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Auth } from 'aws-amplify'
import background from '@momentum/routes/account/background-gradient.png'
import { toast } from 'react-toastify'
import React, { useState } from 'react'
import LogoWhiteIcon from '@momentum/components/icons/logo-white'
import { Form, SubmitButton, TextInput, FormMethodsType } from '@productwindtom/ui-base'
import { Link as RouterLink } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'
import { LoadingButton } from '@mui/lab'

type ForgotPasswordData = {
  email: string
}

const forgotSchema = yup.object().shape({
  email: yup
    .string()
    .required('Required')
    .email('Invalid email')
    .transform(value => value?.toLowerCase()?.trim())
})

export default function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [resending, setResending] = useState(false)

  const onSubmit = async (data: ForgotPasswordData, { setError }: FormMethodsType<ForgotPasswordData>) => {
    try {
      await forgotPassword(data.email)
      setEmail(data.email)
    } catch (e: any) {
      console.log(e.code)
      switch (e.code) {
        case 'UserNotFoundException':
          setError('email', { message: 'User does not exist' })
          break
      }
    }
  }
  const forgotPassword = async (email: string) => {
    await Auth.forgotPassword(email, {
      // Easier for email generation in backend
      origin: window.location.origin
    })
    toast(<Typography variant={'subtitle2'}>Password reset email sent!</Typography>, { type: 'success' })
  }

  const resend = async () => {
    setResending(true)
    try {
      await forgotPassword(email)
      toast(<Typography variant={'subtitle2'}>Password reset email sent!</Typography>, { type: 'success' })
    } catch (e: any) {
      toast(<Typography variant={'subtitle2'}>Failed, please try again later!</Typography>, { type: 'error' })
      console.log(e.code)
    }
    setTimeout(() => setResending(false), 5000)
  }

  return (
    <Container maxWidth={'xl'} sx={{ height: '100vh', alignItems: 'center' }}>
      <Stack direction={'row'} spacing={10} height={'100vh'}>
        <Box py={2} width={'70%'} display={'inline-flex'}>
          <Box
            bgcolor={theme => theme.palette.primary.main}
            borderRadius={'16px'}
            display={'inline-flex'}
            width={'100%'}
            position={'relative'}
          >
            <Stack direction={'row'} spacing={1} alignItems={'center'} position={'absolute'} left={24} top={24}>
              <LogoWhiteIcon fontSize={'large'} />
              <Typography variant={'h3'} mb={2} color={'white'}>
                momentum
              </Typography>
            </Stack>
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              spacing={2}
              width={'100%'}
              sx={{
                background: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            >
              <Typography color={'white'} variant={'h2'} width={'226px'}>
                Momentum
              </Typography>
              <Typography color={'white'} variant={'subtitle2'} width={'226px'}>
                Let's get you a new password!
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box alignSelf={'center'} width={'30%'}>
          {!!email && (
            <Stack spacing={2}>
              <Typography variant={'h2'} textAlign={'center'}>
                Forgot password
              </Typography>
              <Typography data-cy={'successText'} variant={'label3'}>
                An email has been sent to your email address. Please follow the instructions in the email to reset your
                password.
              </Typography>
              <Box>
                <LoadingButton
                  data-cy={'resendButton'}
                  variant={'contained'}
                  color={'primary'}
                  onClick={resend}
                  loading={resending}
                >
                  Resend email
                </LoadingButton>
              </Box>
            </Stack>
          )}
          {!email && (
            <Form onSubmit={onSubmit} resolver={yupResolver(forgotSchema)} defaultValues={{ email: '' }}>
              <Stack spacing={2}>
                <Typography variant={'h2'} textAlign={'center'}>
                  Forgot password
                </Typography>
                <TextInput
                  data-cy={'emailInput'}
                  primaryText={'Email'}
                  name={'email'}
                  variant={'outlined'}
                  placeholder={'Enter your email address'}
                  fullWidth
                />
                <Box>
                  <SubmitButton data-cy={'submitButton'} variant={'contained'} color={'primary'}>
                    Forgot password
                  </SubmitButton>
                </Box>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography variant={'label3'}>Already have an account?</Typography>
                  <Link component={RouterLink} variant={'link1'} color={'primary'} to={ROUTES.LOGIN}>
                    Log in
                  </Link>
                </Stack>
              </Stack>
            </Form>
          )}
        </Box>
      </Stack>
    </Container>
  )
}

import { mApiKey } from "@momentum/api";

export const resetPassword = async (input: { username: string; password: string; code: string }) => {
    return (
      await mApiKey('mutation')({
        resetPassword: [
          input,
          {
            code: true,
            success: true
          }
        ]
      })
    ).resetPassword
  }
  
// @ts-nocheck
import { ModelTypes, ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { generateBrandAdvocates } from '../../generators'
import { BRANDS } from '@momentum/api/interceptors/types'

const startDate = '2023-12-01T00:00:00.000'
const endDate = '2024-01-15T00:00:00.000'

export const SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER: ModelTypes['CampaignDetails'] = {
  id: 'sample-campaign-bluetooth-speaker',
  brandId: BRANDS.CE,
  skuId: 'sample-sku-headphones',
  title: 'Bluetooth Speaker',
  numCreators: 280,
  goal: ProposalGoal.PRODUCT_LAUNCH,
  numCreatorsJoined: 280,
  numAdditionalBrandAdvocateCreatorsJoined: 0,
  numAdditionalBrandAdvocateCreators: 0,
  numBrandAdvocateCreators: 200,
  numSocialCreators: 0,
  numPremiumUgcCreators: 0,
  numUgcCreators: 80,
  expectedSocialPostCount: 0,
  expectedPlacementWins: 10,
  expectedPremiumUgcPostCount: null,
  expectedUgcPostCount: 80,
  expectedReviewsCount: 40,
  expectedClicksCounts: null,
  releaseSchedule: {
    socialWeeklySchedule: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    premiumUgcWeeklySchedule: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ugcWeeklySchedule: [0, 0, 0, 0, 0, 31, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    brandAdvocatesWeeklySchedule: [0, 0, 0, 0, 0, 0, 3, 3, 0, 6, 10, 0, 4, 3, 1]
  },
  startDate,
  endDate,
  createdAt: startDate,
  cost: 9850000,
  product: {
    id: 'sample-sku-speaker',
    name: 'Bluetooth Speaker',
    skuId: 'BTSPEAK',
    priceCents: 24399,
    image: 'https://media.productwind.com/sample/images/speaker.png',
    listingLink: 'https://www.amazon.com',
    store: 'amazon',
    availabilityDate: '2023-10-01T00:00:00.000Z'
  },
  creators: [
    {
      userCampaignId: 'e542dc51-a225-4755-965a-050ebb953073',
      content: [],
      creator: {
        id: '6547d6b4-a374-4987-9b29-a3aec9723654',
        firstName: 'Pauriany',
        lastName: 'Segarra',
        email: 'pauriany@gmail.com',
        instagramProfile: { handle: 'pauriany', followers: 10141, engagementRate: 0.6350458534661276 },
        tiktokProfile: { handle: 'pauriany_', followers: 271, engagementRate: 0.7385524372230428 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-03T23:32:16.000Z', amountCents: 32661 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'c9cf8ed4-a0c8-4089-bf06-e081d22f5e0d',
      content: [],
      creator: {
        id: 'd5337e9b-b412-42f3-9888-5045fb53cfec',
        firstName: 'Nicholas',
        lastName: 'Dunham',
        email: 'nickdunham92@gmail.com',
        instagramProfile: { handle: 'nicholasdunham_', followers: 866, engagementRate: 1.6050808314087759 },
        tiktokProfile: { handle: 'iixyst', followers: 4412, engagementRate: 6.33245382585752 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-20T23:32:23.000Z', amountCents: 31799 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'c28e962f-b5b2-4b85-995b-f97c3cdb663a',
      content: [
        {
          groupId: 'a353fd96-d70c-46b4-baf9-f3ba049f3374',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-13T09:08:03.438Z',
          platformContentLink: null,
          createdAt: '2024-01-11T03:43:12.842Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'e99188e6-4bbf-45fe-9570-f9ceafca990d',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/18188477455302955/VIDEO.mp4' }]
            }
          ]
        },
        {
          groupId: '15522ad1-3cdc-420f-9425-54cb55e0217d',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-11T03:46:14.563Z',
          platformContentLink: null,
          createdAt: '2024-01-11T03:46:15.069Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'd5a7c586-7581-47bd-b4f2-0858e9188b7f',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/900635ef-d35e-4f40-a3bb-5c86f634fae4/ugc/5a6b9bf0-a31b-4888-ab73-3dc3da9ee32e/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '976b6744-ab29-4ca8-a9b9-ebe6bf6fc103',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17908938470818452/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '834630fe-8680-4db2-bd3c-4fecb225c2f5',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/664b274f-3c45-4a81-bff5-34e7a2b505bc/ugc/9abc3ab2-5395-44aa-9bb0-3d92494ba068/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '66eea132-7aa8-4f52-b910-5111feb335eb',
        firstName: 'Ezra',
        lastName: 'Naylor',
        email: 'ezralnaylorjr@gmail.com',
        instagramProfile: { handle: 'ezranaylorjr', followers: 6118, engagementRate: 1.4906832298136645 },
        tiktokProfile: { handle: 'donnybravvo', followers: 72839, engagementRate: 4.199279413591751 },
        youtubeProfile: { handle: 'princeezra.', followers: 25500, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-08T02:15:31.000Z', amountCents: 26999 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'bc73ef2b-05fb-44d6-93b9-1b1e6870b8ea',
      content: [
        {
          groupId: '009df63a-46cc-42c1-a83b-95e1fb5cb276',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-12T18:32:21.315Z',
          platformContentLink: null,
          createdAt: '2024-01-12T18:32:21.567Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '6906b2d9-5fae-40a2-8213-90e1a6a2ea5e',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/7263650797655854382/MEDIA_0.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'bada7180-eb08-4a01-96bf-c8d47da5a6c8',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bc73ef2b-05fb-44d6-93b9-1b1e6870b8ea/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'f33ff3db-d166-4fd4-8660-6e2849c578c9',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17873943999078676/VIDEO.mp4' }]
            }
          ]
        },
        {
          groupId: 'bab59344-132f-4495-b89f-05aa2f1fa434',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-05T14:42:24.858Z',
          platformContentLink: null,
          createdAt: '2024-01-13T02:02:12.464Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '21f810a1-5234-42c6-b467-7c203ef330fb',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6c68f3a8-c98e-46b8-9644-70ebffbf6629/ugc/686030d1-9a1d-4787-a88b-fdb6d482bd04/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '85d8b121-bc87-4c7b-9c22-1e503f994c3d',
        firstName: 'Dustin',
        lastName: 'Medler',
        email: 'dusty@dustyroammedia.com',
        instagramProfile: { handle: 'dusty.roams', followers: 3338, engagementRate: 0.689035350509287 },
        tiktokProfile: { handle: 'thatgingerroams', followers: 8184, engagementRate: 10.322628385378128 },
        youtubeProfile: { handle: 'dustymedler', followers: 682, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T22:23:09.000Z', amountCents: 33154 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '8e91078a-fc99-495b-b74b-fe3c8dfaa8f6',
      content: [
        {
          groupId: 'a0ff4ca0-faf5-4bdf-a307-15be6fd0974f',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-14T09:53:57.301Z',
          platformContentLink: null,
          createdAt: '2024-01-14T09:53:57.939Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '53ce7392-33c6-4c15-b8e7-24fe91db6af2',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0ec10aa4-2bb7-47b3-bfa6-34746e9c215e/ugc/728e001e-0aa1-4f79-8c86-fc378720182d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '549fca7d-38ed-4f43-b336-e5755adb1f41',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/18005343343883443/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '8cee26d2-8407-48d2-aab2-ac28e637e8f2',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/11f76387-0b37-43be-9589-907c0817c6af/ugc/d980cd52-3315-4607-aa11-54e74756d443/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7c07367a-96ba-4934-ac28-245b227df421',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-05T07:19:53.690Z',
          platformContentLink: null,
          createdAt: '2024-01-05T00:06:11.638Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '2dab7771-fa4c-47b4-a50f-aed744082e86',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e91078a-fc99-495b-b74b-fe3c8dfaa8f6/ugc/7c07367a-96ba-4934-ac28-245b227df421/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ee717a2e-e6b5-4168-bdd7-64d75b48c007',
        firstName: 'Priscilla',
        lastName: 'P',
        email: 'sincerelypriscillaxo@gmail.com',
        instagramProfile: null,
        tiktokProfile: { handle: 'sincerely.priscilla', followers: 6839, engagementRate: 12.2354751827626 },
        youtubeProfile: { handle: 'sincerely.priscilla', followers: 27, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-06T20:18:05.000Z', amountCents: 27218 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'b05705f9-244f-4a6d-9bd5-a9fa539c7ffe',
      content: [
        {
          groupId: '3add4e36-bf47-4654-9609-ac5e9101094b',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-07T00:15:31.389Z',
          platformContentLink: null,
          createdAt: '2024-01-07T00:15:31.730Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '87040f97-462d-4ac7-87b4-08eafc8b45c9',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/180f523d-03bd-44d2-b075-661ed75d0684/ugc/b6a11ed3-7a9c-45bb-82d1-c9e315cd5cb6/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '6abe83d1-8700-49d9-af34-ff6515f92095',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b05705f9-244f-4a6d-9bd5-a9fa539c7ffe/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '0957bad9-1092-49c2-bf5f-ac803b18e96b',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b05705f9-244f-4a6d-9bd5-a9fa539c7ffe/ugc/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '590fa728-759e-4d4b-b0ea-635d9fee9287',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-30T12:18:52.071Z',
          platformContentLink: null,
          createdAt: '2024-01-06T22:48:54.392Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '347fac72-1fab-476f-835f-163880dfb5ac',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b05705f9-244f-4a6d-9bd5-a9fa539c7ffe/ugc/590fa728-759e-4d4b-b0ea-635d9fee9287/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6319ed53-d12f-49e4-b68c-3eb6f16b7309',
        firstName: 'Christian',
        lastName: 'Gonzalez',
        email: 'info@dadlifegaming.com',
        instagramProfile: { handle: 'dad.life.gaming', followers: 2851, engagementRate: 2.385128025254297 },
        tiktokProfile: { handle: 'dad.life.gaming', followers: 509, engagementRate: 3.4722222222222223 },
        youtubeProfile: { handle: 'dadlifegaming', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-03T20:53:33.000Z', amountCents: 32849 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '1d319210-670d-4540-9e5c-7dd2b03c7bc0',
      content: [
        {
          groupId: '7c63fbc2-ad83-459f-97fc-51fe44a04341',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-10T12:49:26.060Z',
          platformContentLink: null,
          createdAt: '2024-01-07T22:57:12.606Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '138cf5d2-f714-4930-b16a-b21d62631def',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1d319210-670d-4540-9e5c-7dd2b03c7bc0/ugc/7c63fbc2-ad83-459f-97fc-51fe44a04341/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '81ec1e5c-2779-45fa-8e02-4b2c0bce826f',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-07T22:58:42.334Z',
          platformContentLink: null,
          createdAt: '2024-01-07T22:58:43.007Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '7707f7db-e194-4d5e-a9e3-3e83c153a005',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/18235892890217494/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '802165c0-79f4-4cb5-87f5-f9aeaa02a397',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4adb55fe-7210-4353-9024-5af3b47a01d6/ugc/fc2105ba-bc07-4de6-a11b-fa1aeed6976b/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'a314273b-6efc-44a4-aa5a-180983ebcf9e',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/18352679602076515/VIDEO.mp4' }]
            }
          ]
        }
      ],
      creator: {
        id: 'c683aac3-a487-44b8-bb18-af4a47e10a4f',
        firstName: 'Jonny',
        lastName: 'Fairplay',
        email: 'dqfairplay@yahoo.com',
        instagramProfile: { handle: 'jonnyfairplay', followers: 87219, engagementRate: 0.09630929040690675 },
        tiktokProfile: { handle: 'fairplaytok', followers: 1053, engagementRate: 3.0023094688221708 },
        youtubeProfile: { handle: 'aftershow', followers: 5590, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-08T22:47:37.000Z', amountCents: 26686 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '57d9ebc0-957d-49e0-a288-7b77fa64f86a',
      content: [
        {
          groupId: '7a16cc4d-ca84-42e1-b687-4afa8149ff8a',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-07T11:10:08.084Z',
          platformContentLink: null,
          createdAt: '2024-01-07T11:10:09.280Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'e633e98b-c17f-49c8-bfbb-72dc6e7b4d54',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/d9a03d91-7c38-4e55-b29b-c453270435b4/IS_REQUIRED/3d974b72-9c41-479f-ac43-e4e65d164531/tiktok-story-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'e527b2f7-06f4-4487-942d-407af4b7b687',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/93aec05d-d041-4230-bf0a-41c0aa587b41/ugc/ddece7ae-73e7-4b55-adae-e08aa35d6b4d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '7a838092-ccb9-4ed1-8ba5-dccdf647b276',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/59d87285-46cb-4565-b982-5d228b11fa7a/ugc/a9953df0-b633-4393-a7f1-6b9593ed1ac0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bf7b73d4-174f-4875-94d5-d91bb5cc2667',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-30T22:06:43.438Z',
          platformContentLink: null,
          createdAt: '2024-01-07T11:09:13.355Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'e46ef7a6-f764-4566-8d3f-99769ffa6fd6',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17985491639204816/VIDEO.mp4' }]
            }
          ]
        }
      ],
      creator: {
        id: '5dd9ed66-cdb7-404a-9157-47a98da51b3e',
        firstName: 'Alberto',
        lastName: 'Chavez',
        email: 'itzyomanalberto@gmail.com',
        instagramProfile: { handle: 'yomanalberto', followers: 2094, engagementRate: 1.3419293218720154 },
        tiktokProfile: { handle: 'yomanalberto', followers: 1141, engagementRate: 4.97131931166348 },
        youtubeProfile: { handle: 'yomanalberto', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T03:25:38.000Z', amountCents: 29999 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '9096ea44-8746-4c6e-ba48-daf38694a133',
      content: [
        {
          groupId: 'd1ea0e5b-632b-4bea-8bc3-d181076371a1',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-07T19:33:20.527Z',
          platformContentLink: null,
          createdAt: '2024-01-04T19:27:25.321Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'bd0abb98-1dd1-4d83-8e0f-f2a3f125086c',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6c68f3a8-c98e-46b8-9644-70ebffbf6629/ugc/686030d1-9a1d-4787-a88b-fdb6d482bd04/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ed97f5f9-efd5-4bda-8a2d-36601f2a84f6',
        firstName: 'C.M.',
        lastName: 'Williams',
        email: 'cmwilliams@hyperionprod.com',
        instagramProfile: { handle: 'techie.shooter', followers: 10855, engagementRate: 0.7977890373099953 },
        tiktokProfile: { handle: 'techie.shooter', followers: 3708, engagementRate: 11.442750287686998 },
        youtubeProfile: { handle: 'colormetech', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-08T16:20:22.000Z', amountCents: 26499 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'f952a9e1-43b1-4b80-888e-28a6b685ebcf',
      content: [
        {
          groupId: 'ccfef7b1-bff7-4b87-87c3-b31a6eafe424',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-07T05:14:24.852Z',
          platformContentLink: null,
          createdAt: '2024-01-07T05:14:25.132Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'faab093f-65ab-41a6-82c3-3c7091cd4b66',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17855388933023938/IMAGE.jpg' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '9762a110-b797-4bcb-ac26-1a9e507e2dc9',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5706bbc7-4441-4112-a392-8c31fbda1444/ugc/9ee81e0b-7098-45de-89a5-d06620f2161d/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '5c0523e6-92f8-4a41-807b-85e273837800',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f952a9e1-43b1-4b80-888e-28a6b685ebcf/ugc/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '68ab5411-1fd7-4962-a8e0-5faab354f066',
        firstName: 'Joshua',
        lastName: 'Rivera',
        email: 'mart22good@hotmail.com',
        instagramProfile: { handle: 'unboxedwithjosh', followers: 16294, engagementRate: 0.7898612986375352 },
        tiktokProfile: { handle: 'unboxedwithjosh', followers: 1453, engagementRate: 6.666666666666667 },
        youtubeProfile: { handle: 'unboxedwithjosh', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T18:09:58.000Z', amountCents: 32099 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '159ad29c-652c-4855-ac8d-1e5077bfb306',
      content: [
        {
          groupId: '9de1cc06-acbe-4f38-bc10-b12ac65f2875',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-13T23:00:22.091Z',
          platformContentLink: null,
          createdAt: '2024-01-13T23:00:23.681Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '9d9c309b-a3f2-432c-8d05-781fa2ce8e5b',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4205b8a5-7a07-4858-b041-34596c0b4562/ugc/2db8e913-30a0-42df-90ce-c5556f956f8a/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '7e4bebf4-543c-4d49-b543-174223b231f4',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/159ad29c-652c-4855-ac8d-1e5077bfb306/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '6ead10db-e9e2-485e-b915-e3eb05408711',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9100c8a3-ae81-47ae-8874-fdcb6d03ad6b/ugc/c3660ac6-418d-4387-809f-ee0a0eee8fd2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '092a79ca-73df-481f-8c29-a7529be1573a',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-14T17:27:01.178Z',
          platformContentLink: null,
          createdAt: '2024-01-14T03:43:48.259Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '065aee7a-2a7c-4b4f-bc8b-fe8fca9127d3',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/664b274f-3c45-4a81-bff5-34e7a2b505bc/ugc/9abc3ab2-5395-44aa-9bb0-3d92494ba068/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'abcbc880-fa21-409f-b014-5fe2a8a6c691',
        firstName: 'William',
        lastName: 'Chapman',
        email: 'zanivie22@gmail.com',
        instagramProfile: { handle: 'will.a.chapman', followers: 10576, engagementRate: 0.7327912254160363 },
        tiktokProfile: { handle: 'zani_vie', followers: 1246, engagementRate: 6.651959952486 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-02T06:45:33.000Z', amountCents: 31400 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '9bcb60bf-d29d-4b1d-95cb-fe59938e4904',
      content: [],
      creator: {
        id: 'cd20d578-e447-4316-9f95-bb2af5a54285',
        firstName: 'Olivia',
        lastName: 'Cauley',
        email: 'oliviacauleyy@gmail.com',
        instagramProfile: { handle: 'livireviews', followers: 182, engagementRate: 5.274725274725275 },
        tiktokProfile: { handle: 'livireviews', followers: 10962, engagementRate: 3.7037037037037033 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-04T06:12:15.000Z', amountCents: 32099 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '953d0365-4014-41be-a4c5-386a42ef1317',
      content: [
        {
          groupId: '191f20d9-fdb4-4d08-9c21-fa34e7ef1dea',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-05T01:49:25.479Z',
          platformContentLink: null,
          createdAt: '2024-01-05T01:49:25.969Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '98f64270-87af-4f9c-9c57-4283338550bb',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/507e7c59-4d12-4825-bcac-2f10762e2513/ugc/02380cc6-486c-422c-bcbb-2e4ca5e63b1c/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '32cc3bd2-2670-4fdc-9e3b-6308a4e1cdf7',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/18017001370710834/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '4c102279-93c4-4d4a-88fa-f30d6102b240',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17967693965616715/VIDEO.mp4' }]
            }
          ]
        },
        {
          groupId: '8d05484b-f385-4a4d-88e6-b2078060f576',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-01T20:40:51.039Z',
          platformContentLink: null,
          createdAt: '2024-01-05T02:12:24.621Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'fa94da9d-3611-4b9b-ab89-13ed2da7ba94',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/17f8dca9-d5dc-4bc7-a845-a40a0c64112a/ugc/11de0309-7460-4d4b-b353-f338f379298f/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '53808b5d-83f0-4ae8-994e-cb85dbd28248',
        firstName: 'Elieset',
        lastName: 'Matias',
        email: 'wkeyely@gmail.com',
        instagramProfile: null,
        tiktokProfile: { handle: 'wkeyely', followers: 50194, engagementRate: 2.292856668477884 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-14T20:12:22.000Z', amountCents: 26586 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '724b32af-da83-4bf6-ad34-a457c08a2607',
      content: [
        {
          groupId: 'ba175e68-57df-41db-b3ce-d2ba3adfb6c8',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-07T03:16:39.164Z',
          platformContentLink: null,
          createdAt: '2024-01-07T03:16:39.427Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'aaa2c8a1-8e3d-4544-aab0-cf3fa9d590e9',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0483c622-777b-496b-8f5e-c16eb1f73b92/ugc/f80c78c0-f090-4afd-ba21-a53487396f8a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '2e907005-b4c8-4f45-a3c3-4086a66bb9ad',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5f22fc9-a07a-4b8c-a602-0a1df6d0d25f/ugc/2b40c36d-faa4-437e-917d-eefdbfe45b07/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '2367d79d-ddba-4280-b69d-76070b77a17e',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/724b32af-da83-4bf6-ad34-a457c08a2607/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9191919d-ab9c-49c9-8496-af4dcf04a81b',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-12T00:35:41.170Z',
          platformContentLink: null,
          createdAt: '2024-01-09T13:02:07.672Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'b5b564f3-0ab6-4376-8e56-4f5b1de3a029',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/32e38d0f-eaae-4470-8afb-977c8a854a45/ugc/4b0a8f57-b172-4fa3-af62-b3b15ebad3df/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'fa70efb7-ac4b-4110-928b-f26ca231f13f',
        firstName: 'Joaquin',
        lastName: 'Byrd',
        email: 'thinknext@yahoo.com',
        instagramProfile: { handle: 'therealj_byrd', followers: 658, engagementRate: 2.70516717325228 },
        tiktokProfile: { handle: 'therealj_byrd', followers: 2699, engagementRate: 6.918238993710692 },
        youtubeProfile: { handle: '1blackjab', followers: 268, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T01:22:15.000Z', amountCents: 31799 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '907f3f6e-ebd1-4c2a-b760-3b26bbe3f352',
      content: [
        {
          groupId: 'dc23b9ab-1f4e-4f18-902c-9ecff6c29f47',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-12T02:26:09.981Z',
          platformContentLink: null,
          createdAt: '2024-01-12T02:26:10.879Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'ca30969b-e3ee-4ab7-b335-2eb09fbdbbde',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/61c6fe6b-8309-42ce-a08f-5c1977dd6c27/ugc/0264a1cf-9980-451f-bb61-b13d84c5036d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'fa787f9a-cd7d-459d-b92d-54b0cc0ab650',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17879476352914772/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '2d654c14-b4b6-4c42-891f-850466a64bd8',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/907f3f6e-ebd1-4c2a-b760-3b26bbe3f352/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3bfd2065-bfc0-4a08-8c63-2e460daea26b',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-05T14:54:11.984Z',
          platformContentLink: null,
          createdAt: '2024-01-12T02:15:49.030Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'dc94b034-08f6-43c0-a463-db832001285b',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/907f3f6e-ebd1-4c2a-b760-3b26bbe3f352/ugc/3bfd2065-bfc0-4a08-8c63-2e460daea26b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e4842000-f210-4e87-aced-41282182733d',
        firstName: 'Dawton',
        lastName: 'Marques',
        email: 'dawtonmarques@gmail.com',
        instagramProfile: { handle: 'dawtonmarques', followers: 2156, engagementRate: 0.5890538033395176 },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-04T02:08:34.000Z', amountCents: 32474 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'aca677f6-4ed4-4c06-9405-d3d306d27c91',
      content: [
        {
          groupId: 'e42695f2-e12a-460e-891c-f9a302ddece2',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-10T01:11:20.724Z',
          platformContentLink: null,
          createdAt: '2024-01-10T01:11:22.250Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'c55ded56-acf5-498d-a643-5e424720bbd9',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9100c8a3-ae81-47ae-8874-fdcb6d03ad6b/ugc/5bf8c180-af46-4a73-882b-ffa5009403ce/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '6285ea26-d396-47c3-9009-9a8257b837e3',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aca677f6-4ed4-4c06-9405-d3d306d27c91/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '2e0b8ed5-9273-49ac-8e74-1f7d3e5d5769',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/18047473321476884/thumbnail.jpg' }]
            }
          ]
        },
        {
          groupId: '9d3d686a-72c5-4165-860e-32ae3c35737b',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-05T15:25:05.166Z',
          platformContentLink: null,
          createdAt: '2024-01-10T00:48:28.905Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'a656266a-e001-496f-a838-df4a3e4000ad',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aca677f6-4ed4-4c06-9405-d3d306d27c91/ugc/9d3d686a-72c5-4165-860e-32ae3c35737b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8cfefc19-1561-486d-b436-9fab7622a3bd',
        firstName: 'Darryl',
        lastName: 'Williams',
        email: 'wdarryl89@gmail.com',
        instagramProfile: { handle: 'its.deewilly', followers: 17594, engagementRate: 2.381493691031033 },
        tiktokProfile: { handle: 'deewilly2.0', followers: 3979, engagementRate: 9.601259862973667 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-12T20:44:12.000Z', amountCents: 26624 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'aa37454e-a72c-40ff-99b5-880df800ddf5',
      content: [
        {
          groupId: '0c0a56ba-c4de-4a21-8ba9-09ee752f03e7',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-06T21:01:30.078Z',
          platformContentLink: null,
          createdAt: '2024-01-06T21:01:38.358Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '600af27b-3861-49de-9626-893ff3fa6652',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aa37454e-a72c-40ff-99b5-880df800ddf5/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '893992c1-d7bf-45ee-a715-46679abb2c16',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aa37454e-a72c-40ff-99b5-880df800ddf5/ugc/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'e8de74e6-80fa-4dc3-9ad0-9cb979993214',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aa37454e-a72c-40ff-99b5-880df800ddf5/ugc/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7536e3e2-2d70-4b20-a5cf-e71dfaef2198',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-06T18:59:22.483Z',
          platformContentLink: null,
          createdAt: '2024-01-11T18:14:42.465Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'e9d371c9-c444-4a00-8939-1a932c701c0e',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aa37454e-a72c-40ff-99b5-880df800ddf5/ugc/7536e3e2-2d70-4b20-a5cf-e71dfaef2198/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '98b21dc8-f081-4259-a25b-03774c3a021e',
        firstName: 'Cassie',
        lastName: 'Williams',
        email: 'hello@topnotchnewborncare.com',
        instagramProfile: { handle: 'topnotchnewborncare', followers: 2379, engagementRate: 109.10466582597729 },
        tiktokProfile: { handle: 'topnotchnewborncare', followers: 10, engagementRate: null },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-03T21:49:13.000Z', amountCents: 32324 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'b5352be3-cf25-407d-b0bc-9291298a8f79',
      content: [
        {
          groupId: '92cf903d-5cbf-4558-ba4f-a67c4dedce07',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-07T15:36:45.749Z',
          platformContentLink: null,
          createdAt: '2024-01-13T07:47:03.885Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'b95ced81-a172-470b-b813-508f3ff5ccef',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b5352be3-cf25-407d-b0bc-9291298a8f79/ugc/92cf903d-5cbf-4558-ba4f-a67c4dedce07/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '140558e5-ac83-40c4-8cea-f71533ca1c01',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-13T07:47:52.805Z',
          platformContentLink: null,
          createdAt: '2024-01-13T07:47:56.635Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'f0b08708-a839-4b24-ae4d-de43046dc906',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/62744024-a81e-4cd2-bad8-17b24d6861ff/ugc/7b25112d-fb16-4b23-9bb8-7a5ccf0dc29f/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'ad3d296a-e78c-4c79-9517-ab155cb00b52',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/5d828b5e-a448-4397-8d59-d507045c238e/IS_REQUIRED/30c07c57-415f-4dd6-bb96-7960d17c2dbf/tiktok-story-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '2419bc8c-d856-4e8d-a9b3-ecde753dc659',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/766d9f7d-6952-4860-81c8-136952f3a7e6/IS_REQUIRED/9df0af3d-085d-4452-9b7a-34177f410628/tiktok-story-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0a490730-b8c7-4aa0-aef2-2b996cae0dc9',
        firstName: 'Kristina',
        lastName: 'Urribarres',
        email: 'kurribarres@gmail.com',
        instagramProfile: { handle: 'k3chocolate', followers: 64996, engagementRate: 0.4523355283402056 },
        tiktokProfile: { handle: 'k3chocolate', followers: 440023, engagementRate: 0.19206023926629004 },
        youtubeProfile: { handle: 'k3chocolate', followers: 150000, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T05:54:37.000Z', amountCents: 32849 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '9f807599-61de-46ac-86ed-c1e2c78d3247',
      content: [],
      creator: {
        id: '18ed792e-6c9e-47d1-aa91-8ad1ee130778',
        firstName: 'Darran',
        lastName: 'Hall',
        email: 'marketing@prolix.live',
        instagramProfile: null,
        tiktokProfile: { handle: 'prolix_oc', followers: 34650, engagementRate: 4.769846564376251 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-13T18:33:02.000Z', amountCents: 26999 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '8ce4f200-a235-499a-909d-42bacf4f50b0',
      content: [
        {
          groupId: 'fbe06e55-60e1-4ca8-a593-5d5e90f4a830',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-14T00:23:32.391Z',
          platformContentLink: null,
          createdAt: '2024-01-14T00:23:32.877Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'bb8dc4fe-1476-418d-8d13-c2e1dd189578',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/d5f6f3d2-e3fc-4ccd-9201-b7e7d14ddf5c/IS_REQUIRED/1b26505e-8688-46d9-a360-aa8ae68df768/tiktok-story-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '846aaac6-802c-4724-97df-9e1842f66150',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8ce4f200-a235-499a-909d-42bacf4f50b0/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'c72bd262-bab9-400c-948a-c61813110ec7',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d209c7ab-0723-4d7a-a136-2c32e03e696a/ugc/b3836086-25cd-4450-aef0-6d5c60715f33/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f23eeaa1-3b76-4e1e-be03-641a91761084',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-10T03:35:05.810Z',
          platformContentLink: null,
          createdAt: '2024-01-14T00:22:57.485Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'fc669c8c-3d26-4106-8f84-7bf31488bc66',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/18188477455302955/VIDEO.mp4' }]
            }
          ]
        }
      ],
      creator: {
        id: 'a82af3bd-2241-4474-beb5-f7008f51fe4f',
        firstName: 'Malachi',
        lastName: 'Gross',
        email: 'info@malachigroup.com',
        instagramProfile: { handle: 'lifecoachmalachi', followers: 27941, engagementRate: 0.0887584553165599 },
        tiktokProfile: { handle: 'lifecoachmalachi', followers: 653, engagementRate: 10.50932568149211 },
        youtubeProfile: { handle: 'lifecoachmalachi', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T09:05:22.000Z', amountCents: 32819 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'afe43973-8bc1-4b86-b5f2-43e308d27e63',
      content: [
        {
          groupId: '2991b019-6ec6-42ef-947a-21a68a7ebaff',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-10T04:02:46.426Z',
          platformContentLink: null,
          createdAt: '2024-01-09T23:10:26.638Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'b5ba2df1-380b-4de0-9528-6da717950013',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/afe43973-8bc1-4b86-b5f2-43e308d27e63/ugc/2991b019-6ec6-42ef-947a-21a68a7ebaff/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ff699d8b-21d3-404c-a80c-f00d3a4feca2',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-09T23:17:15.694Z',
          platformContentLink: null,
          createdAt: '2024-01-09T23:17:16.074Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '832b4e9e-a0a0-49a9-b046-abe5dd2645c5',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/afe43973-8bc1-4b86-b5f2-43e308d27e63/ugc/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'a1f5621d-2dd2-41ee-b39d-4ccfa4fcd3bf',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/afe43973-8bc1-4b86-b5f2-43e308d27e63/ugc/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '90ea8244-aa09-4f2d-b30c-0f58b2dec38d',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/40931495-5456-4b1d-84a3-f7073971dfff/IS_REQUIRED/2317f5b3-5461-4a8f-952c-89387e737949/tiktok-story-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8564c084-b9e6-4848-85b7-69b863f5a169',
        firstName: 'Christian',
        lastName: 'Roque',
        email: 'christian.b.roq@gmail.com',
        instagramProfile: { handle: 'christianbryanmusic', followers: 1313, engagementRate: 3.2597105864432594 },
        tiktokProfile: { handle: 'christianbryanmusic', followers: 13837, engagementRate: 11.627906976744185 },
        youtubeProfile: { handle: 'christianbryan', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T10:53:10.000Z', amountCents: 33563 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'ccbd14b7-e15f-4728-9d2e-2626e9fe4c55',
      content: [],
      creator: {
        id: 'd0c53bae-0a80-46ff-a725-6ebeeedb7f78',
        firstName: 'Marquil',
        lastName: 'Cody',
        email: 'anatoliostreetz@gmail.com',
        instagramProfile: { handle: 'anatolio__', followers: 20801, engagementRate: 1.0816787654439692 },
        tiktokProfile: { handle: 'anatolio__', followers: 15493, engagementRate: 13.250623842872091 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-03T21:39:58.000Z', amountCents: 34041 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '513bb440-8b15-493c-891d-a65cc45b8b2d',
      content: [
        {
          groupId: '561da1c7-fca4-4301-9240-3a78590a9a51',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-10T15:49:52.837Z',
          platformContentLink: null,
          createdAt: '2024-01-10T13:13:21.887Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '401b6397-7194-4836-b2f7-a6eed1ee56aa',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17870331939004098/VIDEO.mp4' }]
            }
          ]
        },
        {
          groupId: '46491253-2246-47c5-99e7-18df12260b1f',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-10T13:13:50.034Z',
          platformContentLink: null,
          createdAt: '2024-01-10T13:13:51.111Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'e5db8355-b835-46c5-b225-6116c3fce3ff',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a98a0bff-e290-4797-8b6f-f54bf2c54261/ugc/3c0a5d17-395d-470b-b525-46a5215feda2/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '8585087c-81f7-427c-9534-b5827f79d9da',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/180f523d-03bd-44d2-b075-661ed75d0684/ugc/b6a11ed3-7a9c-45bb-82d1-c9e315cd5cb6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cc0b3bf7-ef2e-402b-a53e-d911b32d80cb',
        firstName: 'Austin',
        lastName: 'Becker',
        email: 'becker.austin@outlook.com',
        instagramProfile: { handle: 'theskierguy', followers: 10170, engagementRate: 1.9626352015732547 },
        tiktokProfile: { handle: 'theskierguyco', followers: 351, engagementRate: null },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-10T12:29:48.000Z', amountCents: 26685 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'd3ea0a30-1878-4541-807c-c241ef096ff2',
      content: [],
      creator: {
        id: '018a91b6-bce9-4daf-a2c8-f72417fa8589',
        firstName: 'Sharanika',
        lastName: 'Akter',
        email: 'sharanika.media@gmail.com',
        instagramProfile: { handle: 'sharanika_', followers: 14125, engagementRate: 0.09959581378563696 },
        tiktokProfile: { handle: 'sharanika_', followers: 51111, engagementRate: 2.132167665402357 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-05T00:15:18.000Z', amountCents: 32661 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'af6e41f0-cd72-42ae-ac06-a3067a364876',
      content: [
        {
          groupId: '039a289c-3f73-41f5-b199-ba45e852159b',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-13T21:45:49.223Z',
          platformContentLink: null,
          createdAt: '2024-01-13T21:45:52.487Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '39dbc0b4-1d14-480f-9fba-34fb1dcd0be1',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/af6e41f0-cd72-42ae-ac06-a3067a364876/ugc/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '92a02250-2aba-4be0-b98d-fa11ceb8e094',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17999624873576220/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'f502bef8-a9bc-46bc-b9f1-9239b5c3e3be',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/CvhoItMrdf3/MEDIA_0.mp4' }]
            }
          ]
        }
      ],
      creator: {
        id: '562204d6-b843-40f6-af7e-8cf81da98aa8',
        firstName: 'Michael',
        lastName: 'Bowman',
        email: 'mikeb3dprints@gmail.com',
        instagramProfile: { handle: 'mikeb3dprints', followers: 206, engagementRate: 1.516990291262136 },
        tiktokProfile: { handle: 'mikeb3d', followers: 1094, engagementRate: 2.4390243902439024 },
        youtubeProfile: { handle: 'mikeb3dprints', followers: 7, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T05:11:57.000Z', amountCents: 33479 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '65e8c426-7c1e-45ea-b418-c8b0fc7194e2',
      content: [],
      creator: {
        id: '709c474c-bb80-488d-bd69-2eecc0bd60e5',
        firstName: 'Adrian',
        lastName: 'Class',
        email: 'afrodominicanonyc@gmail.com',
        instagramProfile: { handle: 'afrodominicano', followers: 28152, engagementRate: 0.995666382495027 },
        tiktokProfile: { handle: 'afrodominicano', followers: 549, engagementRate: 6.646971935007386 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-05T02:34:32.000Z', amountCents: 32661 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '3f19f0d1-74f5-4593-90e2-7b9d7f7105e5',
      content: [],
      creator: {
        id: 'e166b189-8a72-4916-bdfd-1b15d8579480',
        firstName: 'Quionie',
        lastName: 'Gaban',
        email: 'officialquionie@gmail.com',
        instagramProfile: { handle: 'quionie', followers: 24106, engagementRate: 0.2189539783524746 },
        tiktokProfile: { handle: 'bruhkiyo', followers: 4871, engagementRate: 9.195402298850574 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-05T15:25:21.000Z', amountCents: 32099 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '9f9c3007-6f01-4033-b026-f3626c958a96',
      content: [],
      creator: {
        id: 'f058c1bf-1fea-4eb5-b7ef-5ac4e06744fb',
        firstName: 'Vuyo',
        lastName: 'Dasha',
        email: 'vuyodasha@gmail.com',
        instagramProfile: { handle: 'kaioshin.w', followers: 31930, engagementRate: 0.9621046038208582 },
        tiktokProfile: { handle: 'kaioshin.w', followers: 2192, engagementRate: 0 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-05T03:51:50.000Z', amountCents: 25792 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '8dae2d27-1d36-4e6b-b5a8-cf10b3a6b709',
      content: [
        {
          groupId: '9a897614-f02c-4c2f-9e1f-dff93e830010',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-10T15:42:40.562Z',
          platformContentLink: null,
          createdAt: '2024-01-09T19:27:22.290Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '6458c81e-7889-47e1-aae3-01eb7846ba6b',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6c68f3a8-c98e-46b8-9644-70ebffbf6629/ugc/686030d1-9a1d-4787-a88b-fdb6d482bd04/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '00f39017-26f1-4499-809d-5f0b40a69e38',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-09T19:26:10.074Z',
          platformContentLink: null,
          createdAt: '2024-01-09T19:26:11.526Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '7a687a54-6577-4292-a550-1c0157cbb4a7',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17925025706773391/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '862a9b41-b1cf-4f5d-b2de-a81a1952c650',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/40931495-5456-4b1d-84a3-f7073971dfff/IS_REQUIRED/edc168c6-a364-462f-9fb6-001108b221b5/tiktok-story-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '1f7350a2-04f7-48d6-a6b6-f00537e530e7',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/13047604-14f6-44c5-99be-77d88e9c509d/ugc/b64ba1cc-e44f-4b58-9817-a3b2b2aaf523/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e19e15ee-deb2-4098-8755-a9e6c2d80fee',
        firstName: 'Matthew',
        lastName: 'Testi',
        email: 'slizegamingbusiness@gmail.com',
        instagramProfile: { handle: 'slizegaming', followers: 7195, engagementRate: 1.4927032661570536 },
        tiktokProfile: { handle: 'slizegaming', followers: 11884, engagementRate: 5.5971977626270375 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-06T21:12:17.000Z', amountCents: 31649 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '9f6f7313-272f-4441-b98c-95de6a991bfd',
      content: [
        {
          groupId: '3f1db0ac-8af1-44f3-9fe8-4443b427011a',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-08T23:42:42.255Z',
          platformContentLink: null,
          createdAt: '2024-01-08T23:42:42.720Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '8d523802-3d7c-4b47-9fd7-344c3f14c197',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9f6f7313-272f-4441-b98c-95de6a991bfd/ugc/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'c93c69b8-ef6d-4259-92a1-5085ea1c0316',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9f6f7313-272f-4441-b98c-95de6a991bfd/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '52e38d00-0c3e-4fc4-a080-549fb8daa090',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c3364708-62d8-4de8-8d38-7ce51f48c9a2/ugc/05149f65-3d03-4138-b0ad-c2a62ba0d7b7/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8d49c621-d93e-4781-aa29-34053cd0c615',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-10T04:25:14.270Z',
          platformContentLink: null,
          createdAt: '2024-01-06T00:32:55.246Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'db9dadd3-6387-40f3-9e91-6f60c5a3976c',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9f6f7313-272f-4441-b98c-95de6a991bfd/ugc/8d49c621-d93e-4781-aa29-34053cd0c615/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ee974b69-a71b-4ec3-a211-9c0a152818d7',
        firstName: 'Grant',
        lastName: 'Hazzard',
        email: 'gmhazzar07@icloud.com',
        instagramProfile: { handle: 'gorudenheizu_', followers: 1459, engagementRate: null },
        tiktokProfile: { handle: 'gorudenheizu', followers: 5093, engagementRate: 2.9576371583806527 },
        youtubeProfile: { handle: 'gorudenheizu', followers: 109, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T22:30:19.000Z', amountCents: 33171 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '43a6cf0c-26b8-4b5b-a8ea-5f95de302711',
      content: [
        {
          groupId: '6bd55632-f2a1-411e-8f53-47382e87a554',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-02T20:10:23.938Z',
          platformContentLink: null,
          createdAt: '2024-01-02T20:10:24.575Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'c5ac7171-aef9-41c9-9b11-4da696c19840',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/43a6cf0c-26b8-4b5b-a8ea-5f95de302711/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '21c12716-466e-4a36-8c7f-7abcebb485f1',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/43a6cf0c-26b8-4b5b-a8ea-5f95de302711/ugc/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '149c964c-2e91-47a8-bfd7-603186387982',
        firstName: 'Carmina',
        lastName: 'Ambrosio',
        email: 'ugccarmina@gmail.com',
        instagramProfile: { handle: 'ugccarmina', followers: 1071, engagementRate: 3.3333333333333335 },
        tiktokProfile: { handle: 'ugccarmina', followers: 3296, engagementRate: 8.943630958934273 },
        youtubeProfile: { handle: 'ugccarmina_', followers: 291, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-09T17:24:31.000Z', amountCents: 26612 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '29df8dbf-4b7a-4300-a2ce-2c4bd16d1b4f',
      content: [
        {
          groupId: '6a13c8a2-9849-4663-ad37-12354af65219',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-08T03:45:51.012Z',
          platformContentLink: null,
          createdAt: '2024-01-08T03:45:52.339Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '1886ca71-efc8-4dd5-bef1-470ef293ac82',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17898931298834507/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '1761a77c-3db5-4b7b-a771-6facbe540bd4',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29df8dbf-4b7a-4300-a2ce-2c4bd16d1b4f/ugc/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '7698fd76-3b57-4df2-be64-4cc92d8af05e',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/7263518712077094187/MEDIA_0.mp4' }]
            }
          ]
        },
        {
          groupId: '4905d0c8-454b-4259-9096-d1383de2103e',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-10T00:53:05.457Z',
          platformContentLink: null,
          createdAt: '2024-01-08T03:45:15.239Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'c6596185-37e3-4daf-83a8-4caa104f5254',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29df8dbf-4b7a-4300-a2ce-2c4bd16d1b4f/ugc/4905d0c8-454b-4259-9096-d1383de2103e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f834ab22-40dc-4a90-a9fd-b2cef6eb4cf5',
        firstName: 'Nelson',
        lastName: 'Lista',
        email: 'nelsonlistam@gmail.com',
        instagramProfile: { handle: 'nelson_lista', followers: 8978, engagementRate: 9.79950991312096 },
        tiktokProfile: { handle: 'elpanadelugc', followers: 54, engagementRate: 0.6979062811565304 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-05T06:55:23.000Z', amountCents: 32099 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '4c227513-3261-48a4-b201-79d135e006cc',
      content: [
        {
          groupId: '6c10b82a-d62a-4c86-a088-acfb2c6ec1b3',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-12T02:15:06.981Z',
          platformContentLink: null,
          createdAt: '2024-01-11T04:23:16.258Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '9c57ecb4-ab3f-40f2-974c-67b49de3791a',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/d9a03d91-7c38-4e55-b29b-c453270435b4/IS_REQUIRED/a3a0619f-4d2d-4199-9926-fe3a86afff23/tiktok-story-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '59975088-680a-4633-b769-f67877e657d9',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-31T17:10:47.463Z',
          platformContentLink: null,
          createdAt: '2024-01-31T17:10:49.645Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'ac8f507c-bbb4-4a45-ad89-75ed67ea34cf',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/a5a60424-ef13-46a8-ba75-2c8ee1c3d225/IS_REQUIRED/88071536-4393-4f2f-9093-40abe93a8f21/tiktok-story-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '54167a43-13f1-4f1a-9c74-f61d6c7d3ab6',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/4e04daa8-dce6-49e5-82c0-09d2326902e0/IS_REQUIRED/49b61d79-a0ce-4512-9484-835e32c64c20/tiktok-story-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '2da58faf-4c5e-4cda-913f-7b90d50522d1',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4c227513-3261-48a4-b201-79d135e006cc/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd8aa69c6-f614-4030-84e8-4225ca8fc134',
        firstName: 'Mariel',
        lastName: 'Ynoa',
        email: 'marielcollab@gmail.com',
        instagramProfile: { handle: 'marielynoalopez', followers: 7084, engagementRate: 2.0327498588368154 },
        tiktokProfile: { handle: 'marielynoalopz', followers: 69121, engagementRate: 5.92942816085729 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-09T13:33:35.000Z', amountCents: 33422 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'f481ebc9-7f60-4fe6-8e65-1c7315277aa8',
      content: [],
      creator: {
        id: '5ab8155c-148d-4306-843f-94de14fed6e0',
        firstName: 'David',
        lastName: 'Ford Jr',
        email: 'yaboilyriq@gmail.com',
        instagramProfile: { handle: 'crownmelyriqhill', followers: 7483, engagementRate: 0.8686355739676601 },
        tiktokProfile: { handle: 'lyriqhill', followers: 1469, engagementRate: 8.426966292134832 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-10T02:01:13.000Z', amountCents: 32849 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '2d57239b-8ce6-4b7f-bea1-35ed11880229',
      content: [
        {
          groupId: '530b8cac-8774-4646-b4cc-4e0d33a87504',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-14T08:58:09.082Z',
          platformContentLink: null,
          createdAt: '2024-01-14T08:58:11.409Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '094429fe-6a57-4a4f-93a7-9f27e9b19090',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/7263518712077094187/MEDIA_0.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'b14b4d57-fc7e-43dc-a096-4fee66810fb4',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/60446086-0a4c-4ebf-bec1-7adbd72f29f0/ugc/438240f7-d708-44c3-995a-d2dca2024256/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'b49ea24e-8544-4177-9172-452543d65749',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/18005343343883443/VIDEO.mp4' }]
            }
          ]
        },
        {
          groupId: '195220ea-60e5-4ac5-97ac-f3405dbb6b11',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-31T16:56:39.217Z',
          platformContentLink: null,
          createdAt: '2024-01-14T08:55:10.734Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'f0cb2bf3-6104-4d71-ac9d-ce41192c76f6',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2d57239b-8ce6-4b7f-bea1-35ed11880229/ugc/195220ea-60e5-4ac5-97ac-f3405dbb6b11/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6a283b94-19da-464b-baa1-58d62557cc10',
        firstName: 'Jose',
        lastName: 'Barraza',
        email: 'jbarrazajr16@yahoo.com',
        instagramProfile: { handle: 'shotsby.hundo', followers: 8737, engagementRate: 1.5508755865857846 },
        tiktokProfile: { handle: 'shotsby.hundo', followers: 4298, engagementRate: 17.1698282837364 },
        youtubeProfile: { handle: 'hundox', followers: 1200, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T16:59:25.000Z', amountCents: 32579 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '68bd6032-d335-47dd-8fb5-1092f10c4e3b',
      content: [
        {
          groupId: 'bd5bebf0-3d21-4a06-92ab-27e7a0a782b6',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-05T04:36:32.205Z',
          platformContentLink: null,
          createdAt: '2024-01-05T04:36:34.496Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '8aeb44a6-7cd6-41ef-a817-79150dc309f8',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17992287578344948/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '3c9e36c3-4bc3-4ac7-8aaf-1eb6f799d381',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17985491639204816/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '3473e178-f220-438f-a513-06e2cd9f8702',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/C0w0-IBv11P/MEDIA_0.mp4' }]
            }
          ]
        }
      ],
      creator: {
        id: 'de3514a0-7d9b-4bd0-ab2f-ad280bba0c70',
        firstName: 'Jessica',
        lastName: 'Guerra',
        email: 'ugc.jessicaguerra@gmail.com',
        instagramProfile: { handle: 'alebasi_g7', followers: 10491, engagementRate: 0.3269469068725574 },
        tiktokProfile: { handle: 'alebasig', followers: 1889, engagementRate: 2.6490066225165565 },
        youtubeProfile: { handle: 'jessg7', followers: 9, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T00:22:25.000Z', amountCents: 32099 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '31177b9b-0b84-44c0-bd0e-0bbaa010850b',
      content: [
        {
          groupId: 'd9df01e7-4303-43d9-a097-0b431de4a39c',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-13T16:54:17.226Z',
          platformContentLink: null,
          createdAt: '2024-01-13T01:42:14.084Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'c5c5ad9b-ec73-45e3-85f4-7c89131efb1c',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/31177b9b-0b84-44c0-bd0e-0bbaa010850b/ugc/d9df01e7-4303-43d9-a097-0b431de4a39c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3791d2af-41ed-4951-b476-6cba02a58638',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-13T01:29:26.709Z',
          platformContentLink: null,
          createdAt: '2024-01-13T01:29:27.752Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '79b2a6fd-e567-4a69-9a74-9cb743e92eb7',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/7259840218721815851/MEDIA_0.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '67716703-38ea-4d33-bb0b-c3e634004061',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/CyUfglyOhw-/MEDIA_0.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '995539bd-35e8-4a0a-8bbd-3f1c4b011fbd',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/51080149-539d-40c9-8120-c43d6a9d9ad6/ugc/21cfd74d-53fd-4c0a-bed3-42e0a3b64f23/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '00d6e244-a4c4-401b-8c4c-c56bb8f821ac',
        firstName: 'Anderson',
        lastName: 'Nguyen',
        email: 'andersonvannguyen.business@gmail.com',
        instagramProfile: { handle: 'andersonnguyen1', followers: 70561, engagementRate: 28.444466489987384 },
        tiktokProfile: { handle: 'andersonnguyen.official', followers: 131930, engagementRate: 8.273186259314363 },
        youtubeProfile: { handle: 'andersonnguyen.official', followers: 73600, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-12T17:02:22.000Z', amountCents: 32586 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'c938544b-bbf2-471c-941b-2f71c255e566',
      content: [
        {
          groupId: 'd6ae9e54-3ce4-4e2f-b2e0-f98f35645cb5',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-05T04:31:31.190Z',
          platformContentLink: null,
          createdAt: '2024-01-05T04:31:31.696Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'e322264a-1bf5-4068-bda3-137d4705e39d',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0ec10aa4-2bb7-47b3-bfa6-34746e9c215e/ugc/81c6e734-cf8f-4b69-b540-74d277157fe2/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'ad7ddee1-2f50-407d-a28c-2d546b235061',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/cbce0789-163d-45ca-9dea-f9ffa61a7ea6/IS_REQUIRED/e1530308-802c-43ce-93f5-54efdde65142/tiktok-story-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '1a77a914-c10d-4e1e-8218-aaea84f67da9',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e748a56-f756-4708-a2cb-0b54650d3de6/ugc/d5c0e460-8d61-42bf-ae68-17cfc88bec8f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e8715267-a354-43f9-96b0-3353f9edfe82',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-09T16:14:09.455Z',
          platformContentLink: null,
          createdAt: '2024-01-05T04:30:57.876Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '51f25aed-d0d4-43a7-aace-75048c325c27',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c938544b-bbf2-471c-941b-2f71c255e566/ugc/e8715267-a354-43f9-96b0-3353f9edfe82/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9ad35fa4-e85c-400f-a9be-5f4828c30f94',
        firstName: 'Carter',
        lastName: 'James',
        email: 'carter@carterjamesmusic.com',
        instagramProfile: { handle: 'carterjames', followers: 15795, engagementRate: 0.3830326052548275 },
        tiktokProfile: { handle: 'carterjamesmusic', followers: 20339, engagementRate: 10.516066212268745 },
        youtubeProfile: { handle: 'carterjamess', followers: 0, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T01:33:11.000Z', amountCents: 32661 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '702889d4-a0af-44dc-a2c4-f9d6418cbffb',
      content: [],
      creator: {
        id: '44c5bfc6-6ee3-40d1-9266-acbb6c3a8835',
        firstName: 'Samanta',
        lastName: 'Rosas',
        email: 'travelingrosas@gmail.com',
        instagramProfile: { handle: 'travelingrosas', followers: 4659, engagementRate: 1.9317450096587252 },
        tiktokProfile: { handle: 'travelingrosas', followers: 85228, engagementRate: 3.503599661140487 },
        youtubeProfile: { handle: 'travelingrosas', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-14T23:42:51.000Z', amountCents: 31874 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '82c9adaf-0649-461c-9f1a-6cb83807c242',
      content: [
        {
          groupId: '82449182-5629-4f7e-9b9d-f3a950005e42',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-13T18:46:24.884Z',
          platformContentLink: null,
          createdAt: '2024-01-11T00:44:54.294Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'f55bee9b-b1ce-4bf7-89c5-60453ed9ca9c',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/CvQAkxfIGYc/MEDIA_0.mp4' }]
            }
          ]
        },
        {
          groupId: '1023b869-5572-4cbd-9c14-8384672eeb11',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-11T00:46:29.613Z',
          platformContentLink: null,
          createdAt: '2024-01-11T00:46:30.031Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'dccd8e34-0cd4-4827-b808-8644a4a677e1',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/stories/ddb93d97-c075-4c46-8ac7-4d6805491a46/IS_REQUIRED/760b472e-309b-4e6d-8226-05d906194990/tiktok-story-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'c3ce08ff-8e3a-4581-b3d8-674fe6c372b4',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/93aec05d-d041-4230-bf0a-41c0aa587b41/ugc/ddece7ae-73e7-4b55-adae-e08aa35d6b4d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '805f1c30-4249-44e3-9f41-c447ad5570ce',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/82c9adaf-0649-461c-9f1a-6cb83807c242/ugc/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '420d076b-8076-4805-80b6-e0b2ee3ea794',
        firstName: 'Chris',
        lastName: 'Mitchell',
        email: 'chrismitch30@yahoo.com',
        instagramProfile: { handle: 'savingmemoryarchives', followers: 32002, engagementRate: 6.183988500718705 },
        tiktokProfile: { handle: 'savingmemoryarchives', followers: 1329, engagementRate: 2.963692038495188 },
        youtubeProfile: { handle: 'savingmemoryarchives', followers: 801, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-14T12:47:07.000Z', amountCents: 32573 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'f5b12612-7ee2-4da4-a83e-36cb9a96daf5',
      content: [
        {
          groupId: 'b57656d7-4caf-4e3b-8c13-1a24863aead5',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-11T18:24:55.105Z',
          platformContentLink: null,
          createdAt: '2024-01-11T18:24:55.587Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '1a839da0-2f61-4c1a-9851-6d1c89755b7e',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f5b12612-7ee2-4da4-a83e-36cb9a96daf5/ugc/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'ed86f129-caea-462c-8f31-5996a90afe96',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f5b12612-7ee2-4da4-a83e-36cb9a96daf5/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '6368c395-fa02-4fdd-a0a0-1847e852bfc3',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/62744024-a81e-4cd2-bad8-17b24d6861ff/ugc/fc5ca86b-960d-4b23-a3f7-b0e119dc1f97/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'dbba63e7-2dd7-4066-a852-2207df311e8c',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-11T18:19:15.446Z',
          platformContentLink: null,
          createdAt: '2024-01-11T18:19:16.188Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '5597c66a-c3bd-4b25-aaa9-121315ca36dd',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f5b12612-7ee2-4da4-a83e-36cb9a96daf5/ugc/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '3bb49578-3180-4e48-a78b-4fbd67deb686',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17979525380512672/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'e8370573-ae85-4214-bea7-062e95f45269',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/61c6fe6b-8309-42ce-a08f-5c1977dd6c27/ugc/0264a1cf-9980-451f-bb61-b13d84c5036d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '60a379a9-56eb-44a1-8c16-7ba626cf342a',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-11T18:32:01.545Z',
          platformContentLink: null,
          createdAt: '2024-01-11T18:32:04.752Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'efec0db6-6031-402d-9956-509431f53af7',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f5b12612-7ee2-4da4-a83e-36cb9a96daf5/ugc/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '0bf26c57-1e21-4883-8899-8c9b5dd5e6c2',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c700c28-d8b9-4005-9c70-0cb09173f148/ugc/36d07ba3-6bcf-4c47-b7a9-89f6c211697d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '5c787089-4720-452b-a583-4d60fb41b0b8',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17985491639204816/VIDEO.mp4' }]
            }
          ]
        },
        {
          groupId: 'aed0c56d-64ec-41bc-9784-16c8e987f63a',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-11T18:29:38.210Z',
          platformContentLink: null,
          createdAt: '2024-01-11T18:29:38.812Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'a66d3b63-acdb-4047-96e7-07d079879d9e',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ff55a129-7212-4a4d-be2c-a3bb9d45fd21/ugc/c2df6e9a-6cf5-4822-a6e9-a615f9aac8e4/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '17bb619f-4038-4343-ac72-c4cde770b376',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4adb55fe-7210-4353-9024-5af3b47a01d6/ugc/fc2105ba-bc07-4de6-a11b-fa1aeed6976b/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '3926b896-aa45-492b-992c-f50ee56caa7d',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/7263125243835862315/MEDIA_0.mp4' }]
            }
          ]
        },
        {
          groupId: 'fb0c4a74-5d5b-40de-8d5e-aef158f8be8f',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-11T18:28:39.252Z',
          platformContentLink: null,
          createdAt: '2024-01-11T18:28:40.171Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '04293773-5fce-40f6-b685-24f252ef9ebd',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8f7270db-48b2-4637-8031-10108138d777/ugc/cf8ef22c-f103-4d51-aefd-c1e1354c3d1d/UGC-CONTENT-1.mp4'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '6aba6b56-6626-4731-bb56-75c9bbe6b146',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0483c622-777b-496b-8f5e-c16eb1f73b92/ugc/f80c78c0-f090-4afd-ba21-a53487396f8a/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'ae494660-3931-4329-a1d7-fc480ca0e89a',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17885073176907264/VIDEO.mp4' }]
            }
          ]
        },
        {
          groupId: '41bac1bb-f1a8-4700-adfe-954a2179d66b',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-11T18:19:48.085Z',
          platformContentLink: null,
          createdAt: '2024-01-11T18:19:51.170Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'db0d8261-17c6-4e72-b3b6-49a1605be576',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f5b12612-7ee2-4da4-a83e-36cb9a96daf5/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'ebccde80-1af0-4b03-a02c-9505050da5b0',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17851019901063605/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'a34f079f-8565-4dd8-ac06-6e8aad62cb56',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/CvNJSI7s1o9/MEDIA_0.mp4' }]
            }
          ]
        },
        {
          groupId: '88d28f74-5a83-43f7-836e-655bd037cf73',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-13T19:11:00.440Z',
          platformContentLink: null,
          createdAt: '2024-01-11T18:18:34.019Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'eab992cd-6b86-4393-b083-3540da81c431',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/18130547728307461/IMAGE.jpg' }]
            }
          ]
        },
        {
          groupId: '4dfb520e-0473-40e2-bc82-bdaae535e92e',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-11T18:25:39.114Z',
          platformContentLink: null,
          createdAt: '2024-01-11T18:25:43.080Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'ecc15aa9-2534-43d5-a821-f8c99b14ee6a',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/69c50de8-b466-45fb-80d3-adb9b77c520e/ugc/1382faea-3c39-47c8-9db2-542b320ddd15/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'b3d302d3-9ecf-4d94-9e28-752d4980ff7b',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/180f523d-03bd-44d2-b075-661ed75d0684/ugc/b6a11ed3-7a9c-45bb-82d1-c9e315cd5cb6/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '576818bf-e3a0-4fa4-9ebe-99c75a8d7877',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c2bbfac6-be1e-48ea-b519-2529f3d2fa27/ugc/3f75c89c-1027-4577-89f5-dc272bda6d06/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e331e23e-4734-461f-9350-6f20c34f6769',
        firstName: 'Silvia',
        lastName: 'Kerr',
        email: 'silviacreator@hotmail.com',
        instagramProfile: { handle: 'silviakerr.coach', followers: 15132, engagementRate: 0.7850911974623315 },
        tiktokProfile: { handle: 'successfulsereia', followers: 11306, engagementRate: 2.9776858282843213 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-03T01:25:25.000Z', amountCents: 32234 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'fe208785-343f-4d7a-a1aa-79cb3c8a4462',
      content: [],
      creator: {
        id: '51ec8c9a-5bd5-4488-848e-f85743bb7bb2',
        firstName: 'Asia',
        lastName: 'Caraballo',
        email: 'asiacaraxo@gmail.com',
        instagramProfile: { handle: 'asiacaraballo', followers: 1453, engagementRate: 1.9201651754989675 },
        tiktokProfile: { handle: 'asiacaraballo', followers: 205, engagementRate: 15.462427745664739 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-03T20:54:09.000Z', amountCents: 26214 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '2ec4ba47-bf4d-4cc5-89ad-a6bc1fa15f9d',
      content: [],
      creator: {
        id: '9793f12c-f097-40b5-8627-f1973f7ccbd0',
        firstName: 'Bria',
        lastName: 'Jaime',
        email: 'everythingbriaa@gmail.com',
        instagramProfile: { handle: 'briajaime', followers: 11592, engagementRate: 0.4658385093167702 },
        tiktokProfile: { handle: 'briajaimeofficial', followers: 222, engagementRate: 5.723905723905724 },
        youtubeProfile: { handle: 'briajaimeofficial', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T15:46:33.000Z', amountCents: 26826 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'efdc65e7-0ac6-440f-868b-41fa771b87e7',
      content: [
        {
          groupId: '764a004d-39fc-4e2b-8e10-fe029fe076cf',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-06T03:11:11.163Z',
          platformContentLink: null,
          createdAt: '2024-01-06T03:11:11.603Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '971291b8-5ad5-4f78-9a5e-6df101892635',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17868493997969693/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '493f2b04-7560-4d3c-aff6-4b596df2d938',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/efdc65e7-0ac6-440f-868b-41fa771b87e7/ugc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '650d3aca-01e8-45d6-875a-39c68f174b20',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/efdc65e7-0ac6-440f-868b-41fa771b87e7/ugc/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '751a956f-3e2e-4ef2-b02f-06bb676d2462',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-10T04:02:01.041Z',
          platformContentLink: null,
          createdAt: '2024-01-06T03:10:43.679Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '83c73a1c-9c4a-4635-9598-46b6d19e11a1',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/efdc65e7-0ac6-440f-868b-41fa771b87e7/ugc/751a956f-3e2e-4ef2-b02f-06bb676d2462/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4b35b0cf-fcd9-473d-864d-b1d239a7cb4b',
        firstName: 'David',
        lastName: 'Greenberg',
        email: 'smallbeezy@gmail.com',
        instagramProfile: { handle: 'smallbossmc', followers: 17868, engagementRate: 0.6010745466756212 },
        tiktokProfile: { handle: 'smallbossmc', followers: 7429, engagementRate: null },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-06T21:36:40.000Z', amountCents: 27374 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '12550da1-420f-408e-9e4e-e1c81b4b221d',
      content: [],
      creator: {
        id: 'ecb08bdf-6955-455b-bf25-f7b9136640c1',
        firstName: 'Zhé',
        lastName: 'Aqueen',
        email: 'shine@zheaqueen.com',
        instagramProfile: { handle: 'zheaqueen', followers: 8509, engagementRate: 1.9414737336937358 },
        tiktokProfile: { handle: 'zheaqueenmusic', followers: 3236, engagementRate: 9.486952675807164 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-07T05:52:42.000Z', amountCents: 26499 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '2bb84136-bc30-4c2c-897d-938bc3c42f39',
      content: [
        {
          groupId: '0919a7aa-8562-44b4-8fa8-399a4d25bb21',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-13T22:24:26.079Z',
          platformContentLink: null,
          createdAt: '2024-01-13T22:24:26.510Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '16ce248e-9084-4243-af36-e489ea2a87a3',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/posts/C0w0-IBv11P/MEDIA_0.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '29d3395b-25be-49a4-b2f6-5d0441d37b81',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/900635ef-d35e-4f40-a3bb-5c86f634fae4/ugc/5a6b9bf0-a31b-4888-ab73-3dc3da9ee32e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '98a81d79-01d4-4504-b16f-10fa42283bc0',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bb84136-bc30-4c2c-897d-938bc3c42f39/ugc/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5225115a-1318-4ef4-a99a-04548a41aafd',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-07T03:42:48.428Z',
          platformContentLink: null,
          createdAt: '2024-01-13T22:22:41.257Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '4ab01b81-6e46-4f23-b390-db3515c9080b',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bb84136-bc30-4c2c-897d-938bc3c42f39/ugc/5225115a-1318-4ef4-a99a-04548a41aafd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e725dafe-091d-46be-a837-6aacc577896e',
        firstName: 'John',
        lastName: 'Keller',
        email: 'triplejs8082007@live.com',
        instagramProfile: { handle: 'edwardo8089308', followers: 113, engagementRate: 1.1799410029498525 },
        tiktokProfile: { handle: 'edwardo808', followers: 24020, engagementRate: 15.739901981278889 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-08T17:23:41.000Z', amountCents: 26843 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '7665e914-fddd-4d9b-b075-bbb4f2f60ce6',
      content: [
        {
          groupId: '6a17c593-d36d-4702-be62-b2bc2a2fe30a',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-06T00:02:01.069Z',
          platformContentLink: null,
          createdAt: '2024-01-06T00:02:01.467Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: '730e51dc-b16a-408e-95a8-139d42223d73',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17985491639204816/VIDEO.mp4' }]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'baf5c5a9-94c1-4e8f-ac8e-1c50148c2c59',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/51080149-539d-40c9-8120-c43d6a9d9ad6/ugc/5695897d-2f6f-46f9-9840-5c281440b8c7/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'c5b8c5ba-916f-4800-95d5-91b953dd0a53',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7665e914-fddd-4d9b-b075-bbb4f2f60ce6/ugc/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '91c3ef67-a903-4249-abfe-8e0ee1678073',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-02T14:53:26.598Z',
          platformContentLink: null,
          createdAt: '2024-01-07T07:08:17.384Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'b01941fc-226a-4264-82eb-a7a9a35d41c8',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [{ type: 'IMAGE', url: 'https://media.productwind.com/stories/17993152025102355/VIDEO.mp4' }]
            }
          ]
        }
      ],
      creator: {
        id: '4e36c974-888a-411d-8f63-ef487e438b65',
        firstName: 'Savannah',
        lastName: 'Rae',
        email: 'dreamwerksmuzik@gmail.com',
        instagramProfile: { handle: 'thesavannahrae', followers: 61651, engagementRate: 0.44492384551750985 },
        tiktokProfile: { handle: 'thesavannahrae', followers: 172604, engagementRate: 5.084104352983837 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-14T05:13:06.000Z', amountCents: 27311 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '679ca410-a9b4-41d0-ac9f-3fd9aeb19080',
      content: [
        {
          groupId: '632deeb2-d3ef-4a17-abdc-e91baae9f4d4',
          channel: 'UGC',
          contentType: 'UGC_PHOTO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-06T20:10:57.347Z',
          platformContentLink: null,
          createdAt: '2024-01-06T20:10:57.914Z',
          content: [
            {
              comments: null,
              caption: '',
              engagements: null,
              id: 'c6b0369d-2222-4541-b83d-c0c9a78604f1',
              likes: null,
              qualityScore: null,
              views: null,
              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cf6d95c3-1914-41fc-b22a-9b7f1659f4a4/ugc/e8a3138e-0b16-4611-bfa2-e359b97c3065/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'eb5b8e70-2661-4d82-b94d-801577887593',
          channel: 'UGC',
          contentType: 'UGC_VIDEO',
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-06T20:04:05.788Z',
          platformContentLink: null,
          createdAt: '2024-01-12T15:19:12.576Z',
          content: []
        }
      ],
      creator: {
        id: 'd0ea9b56-0d0c-4ba6-9f86-97f22271517c',
        firstName: 'Justin',
        lastName: 'Gustavison',
        email: 'justin.gustavison@gmail.com',
        instagramProfile: { handle: 'gustafilms', followers: 27231, engagementRate: 0.6364070360985642 },
        tiktokProfile: { handle: 'gustafilms_dop', followers: 66, engagementRate: null },
        youtubeProfile: { handle: 'gustafilms_official', followers: 6660, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T15:55:23.000Z', amountCents: 29772 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'ca10620a-e83b-4c24-b6b7-5cbd76293d90',
      content: [],
      creator: {
        id: 'e97686f4-0a2b-4e72-8da4-3fbcb33e03cf',
        firstName: 'Joshua',
        lastName: 'Vega',
        email: 'vega.joshua82@gmail.com',
        instagramProfile: { handle: 'directedby1982', followers: 1244, engagementRate: 2.709003215434084 },
        tiktokProfile: { handle: 'prettydamnawesome', followers: 89, engagementRate: 2.631578947368421 },
        youtubeProfile: { handle: 'pdashow', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-12T20:38:14.000Z', amountCents: 26612 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '674ddf78-45a9-4a1f-8b88-1410c23d992f',
      content: [],
      creator: {
        id: '8c7c1816-3807-454c-a9a4-b93bbaf1c262',
        firstName: 'Josiah ',
        lastName: 'Bell',
        email: 'josiahbell@gmail.com',
        instagramProfile: { handle: 'josiahbell', followers: 97174, engagementRate: 0.7639903677938543 },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-06T21:12:17.000Z', amountCents: 31649 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'c34591b4-1b3b-4756-85e0-9f809bff2902',
      content: [],
      creator: {
        id: 'eda73699-6eca-4180-b639-e337bcec9151',
        firstName: 'Eddie',
        lastName: 'Witt',
        email: 'ewittjr@gmail.com',
        instagramProfile: { handle: 'reallifemovie', followers: 14071, engagementRate: 0.436358467770592 },
        tiktokProfile: { handle: 'reallifemovie', followers: 4, engagementRate: null },
        youtubeProfile: { handle: 'reallifemovie', followers: 258, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-12T20:25:08.000Z', amountCents: 27010 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '2920e629-34ed-40e6-823e-79ebfb879963',
      content: [],
      creator: {
        id: '6434867a-91b2-4e6e-b96f-a3bb54996f9d',
        firstName: 'Sebastian',
        lastName: 'Orta',
        email: '7salvage7@gmail.com',
        instagramProfile: null,
        tiktokProfile: { handle: 'thebashanator', followers: 45704, engagementRate: 10.369255248057224 },
        youtubeProfile: { handle: 'thebashanator', followers: 1140, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-12T20:42:25.000Z', amountCents: 26091 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'e449e87b-d636-47f5-b0fa-3df06a189016',
      content: [],
      creator: {
        id: '3ffb7f68-77b7-421d-91da-c2e93b1f50e6',
        firstName: 'Riels',
        lastName: 'Pierce',
        email: 'cp112199@gmail.com',
        instagramProfile: { handle: 'rielschristian', followers: 7705, engagementRate: 1.0071382219338092 },
        tiktokProfile: { handle: 'rielschristian', followers: 5179, engagementRate: 8.654054875401581 },
        youtubeProfile: { handle: 'rielschristian', followers: 27, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-14T10:04:23.000Z', amountCents: 26913 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'b367c324-2236-4717-aaa1-69784077a0cf',
      content: [],
      creator: {
        id: '80213786-4b22-451a-ae33-9104e1c5063e',
        firstName: 'Jasmin',
        lastName: 'Ibarra',
        email: 'jasmin.ibarra18@gmail.com',
        instagramProfile: { handle: 'jasminibarra0801', followers: 57658, engagementRate: 0.8687432793367789 },
        tiktokProfile: null,
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-13T08:45:44.000Z', amountCents: 26520 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '53a55063-245e-4487-af62-56c5e3c719d6',
      content: [],
      creator: {
        id: '9072748a-6bd3-4229-b5a4-fe2aa0cf1ece',
        firstName: 'Jason',
        lastName: 'Vargas',
        email: 'certifiedcoqui@gmail.com',
        instagramProfile: { handle: 'coquilivee', followers: 735, engagementRate: 1.3741496598639455 },
        tiktokProfile: { handle: 'coquilivee', followers: 273, engagementRate: 4.713482962527362 },
        youtubeProfile: { handle: 'coquilivee', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-14T06:24:01.000Z', amountCents: 26673 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '599000f9-68f2-41f1-ba3f-6b76ea9ba8dc',
      content: [],
      creator: {
        id: '7b2073ff-a965-472d-93cd-d1cfb8a03e1a',
        firstName: 'Jaidah',
        lastName: 'Liverman',
        email: 'info@jaidahofficial.com',
        instagramProfile: { handle: 'jaidahofficial', followers: 2522, engagementRate: 0.9397303727200634 },
        tiktokProfile: { handle: 'jaidahofficial', followers: 421, engagementRate: 0.6493506493506493 },
        youtubeProfile: { handle: 'jaidahofficial', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-08T02:55:55.000Z', amountCents: 26749 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '454e0689-eb84-49ef-a885-3433debe3aff',
      content: [],
      creator: {
        id: '0017d14a-b46f-4cd4-846d-e6c4317de2c5',
        firstName: 'Brandon',
        lastName: 'Kimbro',
        email: 'kimbroplays@gmail.com',
        instagramProfile: { handle: 'kimbroplays', followers: 1213, engagementRate: 2.9431162407254745 },
        tiktokProfile: { handle: 'kimbroplays', followers: 1484, engagementRate: 4.197530864197531 },
        youtubeProfile: { handle: 'kimbroplays', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T16:05:24.000Z', amountCents: 26336 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'ec2a2d36-8735-4469-82d2-9768ee51dbb0',
      content: [],
      creator: {
        id: '29b829fb-bed5-4856-b8a3-84888ce11ea9',
        firstName: 'Dennis',
        lastName: 'Nguyen',
        email: 'dennisnguyen.agent@gmail.com',
        instagramProfile: { handle: 'denntruong', followers: 3940, engagementRate: 0.6649746192893401 },
        tiktokProfile: { handle: 'denntruong', followers: 22, engagementRate: 3.0172413793103448 },
        youtubeProfile: { handle: 'denntruong', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T04:25:19.000Z', amountCents: 25969 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '71883ec5-b239-403a-b5b7-1bdd96e1b1f9',
      content: [],
      creator: {
        id: '91b9a31a-7e1e-47a9-88f1-f9befd0bc056',
        firstName: 'Trevor',
        lastName: 'Gosnell',
        email: 'trevorgosnell95@gmail.com',
        instagramProfile: null,
        tiktokProfile: { handle: 'tre_warner_', followers: 9854, engagementRate: 6.795350234709074 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-07T19:46:22.000Z', amountCents: 26999 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'dbb5cfcf-661c-4afc-86bd-f86eef6dc31b',
      content: [],
      creator: {
        id: '26a4edf3-61c7-40ef-8914-d2316e8c932b',
        firstName: 'Atinuke',
        lastName: 'Milner',
        email: 'kaziahmilner@gmail.com',
        instagramProfile: { handle: 'kaziahmilner', followers: 10228, engagementRate: 1.8126710989440753 },
        tiktokProfile: { handle: 'kaziah.milner', followers: 23, engagementRate: null },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-14T18:15:52.000Z', amountCents: 26214 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'e1327d39-6b1e-4bf3-9dfc-974513be625d',
      content: [],
      creator: {
        id: 'cd7000a7-fffc-44fd-9669-a98bdfff979a',
        firstName: 'Blake',
        lastName: 'Hulett',
        email: 'blakeh.edst@gmail.com',
        instagramProfile: { handle: 'bnation.ent', followers: 242819, engagementRate: 1.8115139260107322 },
        tiktokProfile: { handle: 'bnation.ent', followers: 6664, engagementRate: 3.763440860215054 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-14T16:07:39.000Z', amountCents: 26275 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '23436f29-2f62-4f60-8cd8-59b37cd765e0',
      content: [],
      creator: {
        id: '5d57ed56-bfc7-4e8f-8501-0c39fd24aa3d',
        firstName: 'Reggie',
        lastName: 'Baril',
        email: 'reginaldbaril@gmail.com',
        instagramProfile: { handle: 'itstobyseason', followers: 35458, engagementRate: 0.47972248857803595 },
        tiktokProfile: { handle: 'reggiebfunny', followers: 23893, engagementRate: 9.60890960890961 },
        youtubeProfile: { handle: 'tobymusic', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-06T16:26:56.000Z', amountCents: 26826 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'f220d780-ad06-413d-8c65-fc0b191711f7',
      content: [],
      creator: {
        id: 'fa93d23a-1cb9-4998-981a-4d4156d52bf4',
        firstName: 'Nicholas',
        lastName: 'Mossey',
        email: '216mosseymgmt@gmail.com',
        instagramProfile: { handle: '216mossey', followers: 31531, engagementRate: 1.5494451668375744 },
        tiktokProfile: { handle: '216mssy', followers: 1615, engagementRate: 11.246101632727939 },
        youtubeProfile: { handle: '216mossey', followers: 1270, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-07T01:26:24.000Z', amountCents: 27035 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '6480a617-0915-40c9-9027-18c06e914b68',
      content: [],
      creator: {
        id: '08842476-c1f1-4b82-a45e-4e2150105e2f',
        firstName: 'Charlie',
        lastName: 'Mac',
        email: 'charliemac@funnycharliemac.com',
        instagramProfile: { handle: 'funnycharliemac', followers: 10160, engagementRate: 0.43996062992125984 },
        tiktokProfile: { handle: 'imcharliemac', followers: 4120, engagementRate: 9.70873786407767 },
        youtubeProfile: { handle: 'funnycharliemac', followers: 12700, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-06T19:06:23.000Z', amountCents: 26447 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '999c8366-e6b1-4c75-9c2e-ea7f2f5b60eb',
      content: [],
      creator: {
        id: '65399134-901a-4d03-92a1-603bd539d879',
        firstName: 'Jordan',
        lastName: 'Higgs',
        email: 'jordanhiggscreative@gmail.com',
        instagramProfile: { handle: 'jordanhiggscreative', followers: 1206, engagementRate: 0.6965174129353234 },
        tiktokProfile: { handle: 'jaysokk', followers: 47694, engagementRate: 4.664723032069971 },
        youtubeProfile: { handle: 'jaysokk', followers: 2160, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-09T19:35:14.000Z', amountCents: 26374 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'ca1ab594-fccb-41ad-a460-990589febdbf',
      content: [],
      creator: {
        id: '8414c9a3-c67e-484b-9245-8e2b234de77c',
        firstName: 'Dan',
        lastName: 'Bailey',
        email: 'dbailey754@icloud.com',
        instagramProfile: { handle: 'dan_beardly', followers: 6521, engagementRate: 0.9185707713540868 },
        tiktokProfile: { handle: 'dan_beardly', followers: 1272, engagementRate: 4.104994692770374 },
        youtubeProfile: { handle: 'dbailey754', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-08T21:53:03.000Z', amountCents: 26698 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '20a8ad6a-7349-4ed6-bf15-cebd089fa59c',
      content: [],
      creator: {
        id: '4df48747-6482-4138-9984-6d03ad3fd288',
        firstName: 'Nicola',
        lastName: 'Ianeselli',
        email: 'nicola.ianeselli@gmail.com',
        instagramProfile: { handle: 'nicolaianeselli', followers: 4400, engagementRate: 0.5 },
        tiktokProfile: { handle: 'nicolaianeselli', followers: 30, engagementRate: 0.9868421052631579 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-08T16:20:50.000Z', amountCents: 26214 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'ffce1a90-29af-4e77-acdb-4c732f5e2c35',
      content: [],
      creator: {
        id: '36aca377-62a7-4355-8a46-64d08fcbeabc',
        firstName: 'Kianna',
        lastName: 'Brown',
        email: 'kiannaleria@gmail.com',
        instagramProfile: { handle: 'kiannaleria', followers: 8478, engagementRate: 1.029723991507431 },
        tiktokProfile: { handle: 'kiannaleria', followers: 715, engagementRate: 11.234489308189227 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-10T04:06:41.000Z', amountCents: 26490 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '7013740c-935b-4514-8064-ea4b129a4f29',
      content: [],
      creator: {
        id: '1470ef8b-6444-4007-b312-cb29f2425c7a',
        firstName: 'Ronald',
        lastName: 'Kreutzer',
        email: 'misterkreutzer@gmail.com',
        instagramProfile: { handle: 'ronniekrazy', followers: 1070, engagementRate: 1.0373831775700935 },
        tiktokProfile: { handle: 'ronniekrazy', followers: 46993, engagementRate: 1.7569572612456124 },
        youtubeProfile: { handle: 'ronniekrazy', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-09T18:32:08.000Z', amountCents: 26499 },
      status: 'IN_PROGRESS',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '88baed4d-9356-4d5b-aaa5-67921247a043',
      content: [],
      creator: {
        id: 'ab3de3e1-9453-4b03-9116-cc8b005e5e9d',
        firstName: 'Justin',
        lastName: 'Thomas',
        email: 'jussfitz@gmail.com',
        instagramProfile: { handle: 'justicefitzz', followers: 663, engagementRate: 4.072398190045249 },
        tiktokProfile: { handle: 'justicefitzz', followers: 1256, engagementRate: 14.391570095641 },
        youtubeProfile: { handle: 'itsjustintee', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-20T20:44:36.000Z', amountCents: 31874 },
      status: 'COMPLETED',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'f2c9c134-95a8-4d22-a304-1911fa2aa6a6',
      content: [],
      creator: {
        id: '80f33af7-3cc4-43aa-a252-e03f749c7210',
        firstName: 'Isaac',
        lastName: 'Durant',
        email: 'embeddednature@gmail.com',
        instagramProfile: { handle: 'embeddednature', followers: 16045, engagementRate: 0.04362729822374571 },
        tiktokProfile: { handle: 'embeddednature', followers: 1, engagementRate: null },
        youtubeProfile: { handle: 'embeddednature0', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-20T16:43:12.000Z', amountCents: 32361 },
      status: 'COMPLETED',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'a464a880-c61b-417c-a2ea-e6d10cb7c148',
      content: [],
      creator: {
        id: '4fbadfba-1075-45ae-9df9-93f511fd91da',
        firstName: 'Benjamin',
        lastName: 'Musese',
        email: 'benmuse38@gmail.com',
        instagramProfile: { handle: 'benmuse3', followers: 3324, engagementRate: 1.510228640192539 },
        tiktokProfile: null,
        youtubeProfile: { handle: 'benmuse', followers: 466, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-20T18:26:33.000Z', amountCents: 32399 },
      status: 'COMPLETED',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'f579c565-9b38-4f92-b8f7-18d5906abd73',
      content: [],
      creator: {
        id: '470b2476-a1e7-4570-8a74-2db1c9b2cf90',
        firstName: 'Gina',
        lastName: 'Caulo',
        email: 'gina@gmcinfluence.com',
        instagramProfile: { handle: 'real.mom.stuff', followers: 6687, engagementRate: 0.5577987139225362 },
        tiktokProfile: { handle: 'real.mom.stuff', followers: 11489, engagementRate: 9.6269621421976 },
        youtubeProfile: { handle: 'realmomstuff', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T10:36:32.000Z', amountCents: 32586 },
      status: 'COMPLETED',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'd460195c-9da7-48af-a2e3-31a903c1060d',
      content: [],
      creator: {
        id: '1186abc0-e3c4-476d-8bd8-84dba7d50af0',
        firstName: 'Deya',
        lastName: 'Lopez',
        email: 'natureee99@gmail.com',
        instagramProfile: { handle: '_naturebabe', followers: 26368, engagementRate: 2.4897603155339803 },
        tiktokProfile: { handle: '_natureee__', followers: 32234, engagementRate: 7.450331125827815 },
        youtubeProfile: { handle: 'deya4382', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T00:54:35.000Z', amountCents: 31829 },
      status: 'COMPLETED',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '1ac7c053-da11-43f6-a832-1535ed4ccb20',
      content: [],
      creator: {
        id: 'bf304d05-59da-431e-a37d-265aac682856',
        firstName: 'Jr',
        lastName: 'Sparrow',
        email: 'wvuncommonplace@gmail.com',
        instagramProfile: { handle: 'wvuncommonplace', followers: 1365, engagementRate: 1.032967032967033 },
        tiktokProfile: { handle: 'wvuncommonplace', followers: 4964, engagementRate: 7.384151090342679 },
        youtubeProfile: { handle: 'wvuncommonplace3936', followers: 18, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T20:06:32.000Z', amountCents: 31799 },
      status: 'COMPLETED',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '8aeec149-71cb-4312-8aea-bd6089fb4abf',
      content: [],
      creator: {
        id: '3b4a6760-6126-48bb-9e00-425c4e187124',
        firstName: 'Lady',
        lastName: 'Lino',
        email: 'leihdi@yahoo.com',
        instagramProfile: { handle: 'leihdi', followers: 24576, engagementRate: 1.0677083333333333 },
        tiktokProfile: { handle: 'leihdi', followers: 1149, engagementRate: 5.303030303030303 },
        youtubeProfile: { handle: 'leihdi', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T15:14:28.000Z', amountCents: 33653 },
      status: 'COMPLETED',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'fe966777-58b1-4e39-8dd8-19227520539c',
      content: [],
      creator: {
        id: '4ab5b2a8-4e7a-40b8-8199-976eb7a3c38e',
        firstName: 'Roberto',
        lastName: 'Valderrama',
        email: 'rideboundless@gmail.com',
        instagramProfile: { handle: 'rideboundless', followers: 10372, engagementRate: 0.18607790204396454 },
        tiktokProfile: { handle: 'rideboundless', followers: 390, engagementRate: 4.7273492297409385 },
        youtubeProfile: { handle: 'rideboundless', followers: null, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-05T00:21:53.000Z', amountCents: 32849 },
      status: 'COMPLETED',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '073c1495-a60b-480e-8e3f-66e5ceb8680b',
      content: [],
      creator: {
        id: 'f4ff3237-ff2a-4288-b3fd-0d4c63431065',
        firstName: 'Hassan',
        lastName: 'Chammout',
        email: 'chammouth@gmail.com',
        instagramProfile: { handle: 'hassan_chammout', followers: 617, engagementRate: 11.539708265802268 },
        tiktokProfile: { handle: 'chammouth', followers: 10428, engagementRate: 1.1152416356877324 },
        youtubeProfile: { handle: 'chammouth', followers: 164, engagementRate: null }
      },
      purchaseDetails: { purchaseDate: '2023-12-04T22:34:32.000Z', amountCents: 31799 },
      status: 'COMPLETED',
      creatorType: 'UGC'
    },
    {
      userCampaignId: 'a1519aad-f6a4-4edd-a67e-67314bcfaa3d',
      content: [],
      creator: {
        id: '6768c997-c026-45da-ace7-0df38cf51a4a',
        firstName: 'Misael',
        lastName: 'Miranda',
        email: 'mrmisaelmiranda@gmail.com',
        instagramProfile: { handle: 'veteamisa', followers: 4668, engagementRate: 1.803770351328192 },
        tiktokProfile: { handle: 'veteamisa', followers: 39859, engagementRate: 7.139514731369151 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-05T06:05:14.000Z', amountCents: 32474 },
      status: 'INCOMPLETE',
      creatorType: 'UGC'
    },
    {
      userCampaignId: '5117ea53-6601-48f0-b3b0-099fe87541c9',
      content: [],
      creator: {
        id: '8d7e47e7-a7ea-403e-8170-35fad2fc7d10',
        firstName: 'Jeremy',
        lastName: 'Morris',
        email: 'jeremyavalonmusic@gmail.com',
        instagramProfile: { handle: 'jeremyavalon', followers: 4217, engagementRate: 1.1548494190182594 },
        tiktokProfile: { handle: 'jeremyavalon', followers: 2622, engagementRate: 5.88235294117647 },
        youtubeProfile: null
      },
      purchaseDetails: { purchaseDate: '2023-12-11T18:05:41.000Z', amountCents: 26679 },
      status: 'INCOMPLETE',
      creatorType: 'UGC'
    },
    ...generateBrandAdvocates({
      startDate,
      endDate,
      numCreators: 200
    })
  ]
}

import { Typography } from '@mui/material'
import { toast } from 'react-toastify'

export const errorToast = (message = 'An error has occurred, please try again later!') =>
  toast(<Typography variant={'subtitle2'}>{message}</Typography>, {
    type: 'error'
  })

export const successToast = (message: string) =>
  toast(<Typography variant={'subtitle2'}>{message}</Typography>, {
    type: 'success'
  })

import { SAMPLE_CAMPAIGN_KEYBOARD } from './keyboard'
import { SAMPLE_CAMPAIGN_LAUNDRY } from './laundry'
import { SAMPLE_CAMPAIGN_MARKER } from './marker'
import { SAMPLE_CAMPAIGN_PILLOW } from './pillow'
import { SAMPLE_CAMPAIGN_WHITENING } from './whitening'
import { SAMPLE_CAMPAIGN_HEADPHONES } from './headphones'
import { SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER } from '@momentum/api/interceptors/getCampaign/sample-data/bluetooth-speaker'
import { SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH } from '@momentum/api/interceptors/getCampaign/sample-data/water-flosser-pd-launch'
import { SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getCampaign/sample-data/keyboard-reviews-product-launch'
import { CampaignDetails } from '@momentum/routes/campaign/context/queries'
import { SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/getCampaign/sample-data/energy-drink-pd-existing'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'

export const SAMPLE_CAMPAIGNS: Record<string, CampaignDetails> = {
  'sample-campaign-laundry': SAMPLE_CAMPAIGN_LAUNDRY,
  'sample-campaign-keyboard': SAMPLE_CAMPAIGN_KEYBOARD,
  'sample-campaign-whitening': SAMPLE_CAMPAIGN_WHITENING,
  'sample-campaign-marker': SAMPLE_CAMPAIGN_MARKER,
  'sample-campaign-pillow': SAMPLE_CAMPAIGN_PILLOW,
  'sample-campaign-headphones': SAMPLE_CAMPAIGN_HEADPHONES,
  'sample-campaign-bluetooth-speaker': SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER,
  [SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH.id]: SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH,
  [SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL.id]: SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL,
  [SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id]: SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING,
  [SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id]: SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST,
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id]: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS
}

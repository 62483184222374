import { SvgIcon, SvgIconProps } from '@mui/material'

export default function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26" fill="none">
      <path
        d="M7 7L19 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={'none'}
      />
      <path
        d="M7 19L19 7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={'none'}
      />
    </SvgIcon>
  )
}

import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  debug: {
    // Uncomment to debug layout
    // border: '1px solid red',
    // margin: -1
  },
  page: {
    flexDirection: 'column',
    padding: 15
  },
  column: {
    flexDirection: 'column'
  },
  row: {
    flexDirection: 'row'
  },
  cell: {
    textAlign: 'right',
    alignContent: 'center',
    padding: 4
  },
  card: {
    border: '2px solid #EBEDF6',
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  /* Font styles */
  body1: {
    fontFamily: 'Avenir',
    fontSize: '8px',
    fontWeight: 500,
    //lineHeight: '16px', // commenting out as it doesn't seem to work properly
    letterSpacing: '0.25px'
  },
  caption2: {
    fontFamily: 'Avenir',
    fontSize: '10px',
    fontWeight: 400,
    //lineHeight: '18px',
    letterSpacing: '0.25px'
  },
  caption3: {
    fontFamily: 'Avenir',
    fontSize: '8px',
    fontWeight: 400,
    //lineHeight: '16px',
    letterSpacing: '0.25px'
  },
  label1: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 800,
    letterSpacing: '0.32px'
  },
  label2: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 800,
    //lineHeight: '18px',
    letterSpacing: '0.28px'
  },
  label3: {
    fontFamily: 'Avenir',
    fontSize: '10px',
    fontWeight: 800,
    letterSpacing: '0.25px'
  },
  label4: {
    fontFamily: 'Avenir',
    fontSize: '8px',
    fontWeight: 800,
    letterSpacing: '0.25px'
  }
})

import { Stack, Typography, Button } from '@mui/material'
import { DateTime } from 'luxon'
import { sumBy } from 'lodash'
import { FileDownloadOutlined } from '@mui/icons-material'
import { Channel, ContentType } from '@productwindtom/shared-momentum-zeus-types'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { CreatorAndContent } from '@momentum/sections/content/types'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { CSVLink } from 'react-csv'
import { Headers } from 'react-csv/lib/core'
import { getSocialProfile, SocialChannelToString, ContentTypeToString } from '@momentum/utils/socialUtils'
import { useFlag } from '@unleash/proxy-client-react'
import { useMemo } from 'react'

export const CREATOR_CONTENT_DATA_HEADERS: Headers = [
  { key: 'handle', label: 'Creator handle' },
  { key: 'contentType', label: 'Content type' },
  { key: 'socialPlatform', label: 'Social platform' },
  { key: 'followers', label: 'Creator following' },
  { key: 'creatorEngagementRate', label: 'Profile engagement rate' },
  { key: 'postedAt', label: 'Date posted' },
  { key: 'postLink', label: 'Link to post' },
  { key: 'likes', label: 'Likes' },
  { key: 'comments', label: 'Comments' },
  { key: 'views', label: 'Views' },
  { key: 'engagementRate', label: 'Content engagement rate' }
]

const convertToPercentage = (value?: number) => (value ? value.toFixed(2) + '%' : undefined)

export const Summary = ({ campaignContent }: { campaignContent: CreatorAndContent[] }) => {
  const { isAdminView } = useUserSessionContext()
  const { campaignDetails } = useCampaignContext()
  const contentDownloadFlag = useFlag('CreatorContentDownload')

  const visibleContent = campaignContent.filter(c => !c.isHidden || isAdminView)

  const creatorsMappedForDownload = useMemo(
    () =>
      campaignContent
        .filter(c => c.channel !== Channel.UGC)
        .map(cc => ({
          handle: cc.handle,
          contentType: ContentTypeToString[cc.contentType],
          socialPlatform: SocialChannelToString[cc.channel],
          followers: getSocialProfile(cc.channel, cc)?.followers?.toLocaleString(),
          creatorEngagementRate: convertToPercentage(getSocialProfile(cc.channel, cc)?.engagementRate),
          postLink: cc.contentType !== ContentType.IG_STORY ? cc.platformContentLink : undefined,
          postedAt: cc.submittedAt ? DateTime.fromISO(cc.submittedAt).toLocaleString(DateTime.DATE_MED) : undefined,
          likes: cc.likes?.toLocaleString(),
          comments: cc.comments?.toLocaleString(),
          views: cc.views?.toLocaleString(),
          engagementRate:
            cc.engagements && cc.views ? convertToPercentage((100 * cc.engagements) / cc.views) : undefined
        })),
    [campaignContent]
  )

  const numUGCContent = visibleContent
    .filter(c => c.channel === Channel.UGC)
    .flatMap(c => c.content.flatMap(cc => cc.assets)).length

  const numSocialContent = visibleContent.filter(c => c.channel !== Channel.UGC).flatMap(c => c.content).length

  const content = visibleContent.map(c => c.content).flat()

  const impressions = sumBy(content, 'views')

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant={'h4'}>Campaign content summary</Typography>
          <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
            Last updated: {DateTime.now().minus({ days: 1 }).toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Stack>
        {contentDownloadFlag && (
          <CSVLink
            headers={CREATOR_CONTENT_DATA_HEADERS}
            data={creatorsMappedForDownload}
            filename={`${campaignDetails.product.name}-content.csv`}
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <Button disableRipple startIcon={<FileDownloadOutlined />}>
              Download social content data
            </Button>
          </CSVLink>
        )}
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric label={'pieces of UGC content'} value={!numUGCContent ? '--' : numUGCContent.toLocaleString()} />
        <Metric
          label={'pieces of social content'}
          value={!numSocialContent ? '--' : numSocialContent.toLocaleString()}
        />
        <Metric label={'views'} value={!impressions ? '--' : impressions.toLocaleString()} />
      </Stack>
    </Stack>
  )
}

const Metric = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack alignItems={'center'} spacing={0.5}>
      <Typography variant={'label1'}>{value}</Typography>
      <Typography variant={'body1'}>{label}</Typography>
    </Stack>
  )
}

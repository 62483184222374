import { GridColDef } from '@mui/x-data-grid-premium'
import { Button, Link } from '@mui/material'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { ROUTES, BASE_ROUTES } from '@momentum/routes/RouteNames'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { RegionToFlagIcon } from '@momentum/utils/regionMappings'
import { Region } from '@productwindtom/shared-momentum-zeus-types'

export const BRAND_TABLE_DEFINITION: GridColDef[] = [
  {
    field: 'image',
    headerName: '',
    width: 55,
    sortable: false,
    align: 'center',
    renderCell: ({ value }) =>
      value && (
        <img
          src={getCdnImageUrl(value)}
          alt={'brand'}
          width={32}
          height={32}
          style={{ objectFit: 'contain', verticalAlign: 'middle' }}
        />
      )
  },
  {
    field: 'name',
    headerName: 'Brand',
    flex: 1,

    renderCell: ({ value, row }) => (
      <Link component={RouterLink} to={generatePath(ROUTES.BRAND, { brandId: row.id })} variant={'label2'}>
        {value}
      </Link>
    )
  },
  {
    field: 'region',
    headerName: 'Region',

    renderCell: ({ value }) => RegionToFlagIcon[value as Region]({ sx: { verticalAlign: 'middle' } })
  },
  {
    field: 'proposals',
    headerName: 'Drafts',
    flex: 1
  },
  {
    field: 'recommendations',
    headerName: 'Recommendations',
    flex: 1
  },
  // {
  //   field: 'activeCampaigns',
  //   headerName: 'Active Campaigns'
  // },
  {
    field: 'products',
    headerName: 'Products',
    flex: 1
  },
  {
    field: 'action',
    headerName: '',
    width: 130,
    renderCell: ({ row }) => (
      <Button data-cy={'viewBrand'} variant={'contained'} component={RouterLink} to={getBrandPath(row)}>
        View brand
      </Button>
    )
  }
]

const getBrandPath = ({ id, agencyId }: { id: string; agencyId?: string }) => {
  if (agencyId) {
    return generatePath([BASE_ROUTES.AGENCY, ROUTES.BRAND].join('/'), { brandId: id, agencyId })
  }
  return generatePath(ROUTES.BRAND, { brandId: id })
}

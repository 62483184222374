import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { CheckCircleOutlined } from '@mui/icons-material'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { Channel, CreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { useFlag } from '@unleash/proxy-client-react'
import { sum, sumBy } from 'lodash'
import { ReactNode } from 'react'
import { useSeoContext } from '../../e-commerce/seoV2/context'
import { getCampaignContentCounts, getCampaignReviewMetrics } from '@productwindtom/shared-momentum'

export const Deliverables = () => {
  const { brand } = useBrandContext()
  const { productSeoSummary } = useSeoContext()
  const { campaignDetails, campaignClicks, reviewMetrics, legacyProductSEOSummary } = useCampaignContext()
  const seoV2Flag = useFlag('SeoV2')

  const {
    expectedSocialPostCount,
    expectedReviewsCount,
    expectedClicksCounts,
    expectedPremiumUgcPostCount,
    expectedUgcPostCount,
    expectedPlacementWins
  } = campaignDetails

  const isIntegrated = brand.brandApis.find(api => api.isIntegrated)

  const { socialContentCount, premiumUgcContentCount, ugcContentCount } = getCampaignContentCounts(campaignDetails)

  const clicks = sumBy(campaignClicks, 'clicks')
  const { momentumReviews } = getCampaignReviewMetrics(reviewMetrics)

  // Temporarily change to use SEO V1 pageOneWins
  const pageOneWins = seoV2Flag
    ? sum((productSeoSummary?.productSeoSummaryRecords || []).map(p => p?.organicPageOneWins ?? 0))
    : sum(legacyProductSEOSummary?.keywords.map(s => (s.pageOneWin ? 1 : 0)))

  return (
    <Stack spacing={1}>
      <Row
        deliverable={<Typography variant={'label1'}>Campaign deliverables</Typography>}
        progress={<Typography variant={'label1'}>Campaign progress</Typography>}
      />
      {/*REMOVED UNTIL WE LAUNCH TRAFFIC*/}
      {/*{!!isIntegrated && !!expectedClicksCounts && (*/}
      {/*  <Row*/}
      {/*    deliverable={<Typography variant={'label3'}>Traffic to product detail page</Typography>}*/}
      {/*    progress={*/}
      {/*      <Stack direction={'row'} spacing={1} alignItems={'center'}>*/}
      {/*        {clicks >= expectedClicksCounts && <CheckCircleOutlined color={'success'} />}*/}
      {/*        <Typography variant={'label3'}>*/}
      {/*          {clicks.toLocaleString()} of {expectedClicksCounts.toLocaleString()} glance views driven to PDP*/}
      {/*        </Typography>*/}
      {/*      </Stack>*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
      {!!expectedReviewsCount && (
        <Row
          deliverable={<Typography variant={'label3'}>Incremental reviews & ratings</Typography>}
          progress={
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {momentumReviews >= expectedReviewsCount && <CheckCircleOutlined color={'success'} />}
              <Typography variant={'label3'}>
                {momentumReviews.toLocaleString()} out of {expectedReviewsCount.toLocaleString()} reviews & ratings
              </Typography>
            </Stack>
          }
        />
      )}
      {!!expectedSocialPostCount && (
        <Row
          deliverable={<Typography variant={'label3'}>Social posts & stories</Typography>}
          progress={
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {socialContentCount >= expectedSocialPostCount && <CheckCircleOutlined color={'success'} />}
              <Typography variant={'label3'}>
                {socialContentCount.toLocaleString()} out of {expectedSocialPostCount.toLocaleString()} social posts and
                stories
              </Typography>
            </Stack>
          }
        />
      )}
      {!!expectedPremiumUgcPostCount && (
        <Row
          deliverable={<Typography variant={'label3'}>Premium UGC content</Typography>}
          progress={
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {premiumUgcContentCount >= expectedPremiumUgcPostCount && <CheckCircleOutlined color={'success'} />}
              <Typography variant={'label3'}>
                {premiumUgcContentCount.toLocaleString()} out of {expectedPremiumUgcPostCount.toLocaleString()} pieces
                of premium UGC content
              </Typography>
            </Stack>
          }
        />
      )}
      {!!expectedPlacementWins && (
        <Row
          deliverable={<Typography variant={'label3'}>Organic placement wins</Typography>}
          progress={
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {pageOneWins >= expectedPlacementWins && <CheckCircleOutlined color={'success'} />}
              <Typography variant={'label3'}>
                {pageOneWins.toLocaleString()} out of {expectedPlacementWins.toLocaleString()} estimated organic wins
              </Typography>
            </Stack>
          }
        />
      )}
      {!!expectedUgcPostCount && (
        <Row
          deliverable={<Typography variant={'label3'}>Authentic UGC content</Typography>}
          progress={
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {ugcContentCount >= expectedUgcPostCount && <CheckCircleOutlined color={'success'} />}
              <Typography variant={'label3'}>
                {ugcContentCount.toLocaleString()} out of {expectedUgcPostCount.toLocaleString()} pieces of UGC content
              </Typography>
            </Stack>
          }
        />
      )}
    </Stack>
  )
}

const Row = ({ deliverable, progress }: { deliverable: ReactNode; progress: ReactNode }) => {
  return (
    <Box>
      <Grid container alignItems={'center'} p={1} mb={1}>
        <Grid item xs={6}>
          {deliverable}
        </Grid>
        <Grid item xs={6}>
          {progress}
        </Grid>
      </Grid>
      <Divider />
    </Box>
  )
}

import { Channel, CreatorType, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const getCampaignContentCounts = (campaignDetails: {
  creators: {
    creatorType: CreatorType
    content: {
      channel: Channel
      isHidden: boolean
      content: Partial<ModelTypes['Content'][]>
    }[]
  }[]
}) => {
  const socialContent = campaignDetails.creators
    .flatMap(c => c.content)
    .filter(c => c.channel !== Channel.UGC && !c.isHidden)
    .flatMap(c => c.content)

  const premiumUgcContent = campaignDetails.creators
    .filter(c => c.creatorType === CreatorType.PREMIUM_UGC)
    .flatMap(c => c.content)
    .filter(c => c.channel === Channel.UGC && !c.isHidden)
    .flatMap(c => c.content)

  const ugcContent = campaignDetails.creators
    .flatMap(c => c.content)
    .filter(c => c.channel === Channel.UGC && !c.isHidden)
    .flatMap(c => c.content)

  return {
    socialContentCount: socialContent?.length || 0,
    ugcContentCount: ugcContent?.length || 0,
    premiumUgcContentCount: premiumUgcContent?.length || 0
  }
}

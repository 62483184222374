import { ModelTypes, Role, UserStatus } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_BRANDS } from '../../getBrand/sample-data'
import { SAMPLE_COMPANY } from '../../getCompany/sample-data'
import { BRANDS } from '../../types'

export const SAMPLE_TEAM_USERS: Record<string, ModelTypes['ModelItemsUserCompany']> = {
  ['sample-company']: {
    items: [
      {
        id: 'sample-user-smith',
        email: 'alexander@samplecompany.com',
        firstName: 'Alexander',
        lastName: 'Smith',
        role: Role.ECOMMERCE_MANAGER,
        phoneNumber: '+16132510189',
        lastActiveAt: '2024-04-10T12:00:00.00Z'
      },
      {
        id: 'sample-user-miller',
        email: 'alexander@samplecompany.com',
        firstName: 'David',
        lastName: 'Miller',
        role: Role.ECOMMERCE_MANAGER,
        phoneNumber: '+16132510189',
        lastActiveAt: '2024-04-08T12:00:00.00Z'
      },
      {
        id: 'sample-user-jones',
        email: 'alexander@samplecompany.com',
        firstName: 'Elizabeth',
        lastName: 'Jones',
        role: Role.ECOMMERCE_MANAGER,
        phoneNumber: '+16132510189',
        lastActiveAt: '2024-04-05T12:00:00.00Z'
      },
      {
        id: 'sample-user-wilson',
        email: 'alexander@samplecompany.com',
        firstName: 'Sarah',
        lastName: 'Wilson',
        role: Role.EXECUTIVE,
        phoneNumber: '+16132510189',
        lastActiveAt: '2024-04-06T12:00:00.00Z'
      },
      {
        id: 'sample-user-rodriguez',
        email: 'alexander@samplecompany.com',
        firstName: 'Susan',
        lastName: 'Rodriguez',
        role: Role.SOCIAL_MANAGER,
        phoneNumber: '+16132510189',
        lastActiveAt: '2024-04-07T12:00:00.00Z'
      },
      {
        id: 'sample-user-johnson',
        email: 'alexander@samplecompany.com',
        firstName: 'Christopher',
        lastName: 'Johnson',
        role: Role.OTHER,
        phoneNumber: '+16132510189',
        lastActiveAt: '2024-04-11T12:00:00.00Z'
      },
      {
        id: 'sample-user-brown',
        email: 'alexander@samplecompany.com',
        firstName: 'Charles',
        lastName: 'Brown',
        role: Role.OTHER,
        phoneNumber: '+16132510189',
        lastActiveAt: '2024-04-01T12:00:00.00Z'
      }
    ].map(u => ({
      userId: u.id,
      companyId: 'sample-company',
      user: {
        ...u,
        status: UserStatus.ACTIVE,
        primaryBrandId: SAMPLE_BRANDS[BRANDS.CE].id
      } as ModelTypes['User'],
      company: SAMPLE_COMPANY,
      userBrands: Object.values(SAMPLE_BRANDS).map(brand => {
        return {
          brand,
          brandId: brand.id,
          companyId: SAMPLE_COMPANY.id,
          userId: u.id,
          createdAt: '2024-03-26T12:00:00.00Z',
          updatedAt: '2024-03-26T12:00:00.00Z'
        }
      }),
      createdAt: '2024-03-26T12:00:00.00Z',
      updatedAt: '2024-03-26T12:00:00.00Z'
    }))
  }
}

import { SvgIconProps, SvgIcon } from '@mui/material'

export default function UKFlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 50 50" fill="none">
      <path
        d="M49.6504 41.409H1.3748C0.898729 41.409 0.512695 41.0229 0.512695 40.5468V9.5125C0.512695 9.03642 0.898729 8.65039 1.3748 8.65039H49.6505C50.1266 8.65039 50.5126 9.03642 50.5126 9.5125V40.5468C50.5125 41.0229 50.1265 41.409 49.6504 41.409Z"
        fill="#41479B"
      />
      <path
        d="M50.5125 9.5125C50.5125 9.03642 50.1265 8.65039 49.6504 8.65039H45.7935L29.8228 19.1139V8.65039H21.2022V19.1139L5.23162 8.65039H1.3748C0.898729 8.65039 0.512695 9.03642 0.512695 9.5125V11.7421L14.2147 20.7193H0.512695V29.34H14.2147L0.512695 38.3173V40.5468C0.512695 41.0229 0.898729 41.409 1.3748 41.409H5.23172L21.2024 30.9454V41.409H29.823V30.9454L45.7937 41.409H49.6506C50.1267 41.409 50.5127 41.0229 50.5127 40.5468V38.3173L36.8107 29.3401H50.5125V20.7194H36.8105L50.5125 11.7421V9.5125Z"
        fill="#F5F5F5"
      />
      <path
        d="M50.5125 22.4435H28.0988V8.65039H22.9264V22.4435H0.512695V27.6158H22.9264V41.409H28.0988V27.6158H50.5125V22.4435Z"
        fill="#FF4B55"
      />
      <path
        d="M17.988 29.3398L0.537109 40.6676C0.598144 41.0839 0.941405 41.4088 1.3747 41.4088H2.5621L21.1548 29.3399H17.988V29.3398Z"
        fill="#FF4B55"
      />
      <path
        d="M34.3406 29.3398H31.1738L49.7395 41.391C50.1722 41.3449 50.5137 40.9916 50.5137 40.5466V39.8378L34.3406 29.3398Z"
        fill="#FF4B55"
      />
      <path
        d="M0.513672 10.4641L16.3123 20.7193H19.4791L1.01592 8.73438C0.720995 8.87139 0.513672 9.16582 0.513672 9.5124V10.4641Z"
        fill="#FF4B55"
      />
      <path
        d="M32.99 20.7193L50.4828 9.36416C50.4102 8.96162 50.0742 8.65039 49.6507 8.65039H48.4157L29.8232 20.7193H32.99Z"
        fill="#FF4B55"
      />
    </SvgIcon>
  )
}

import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { TableHeadCell } from '@momentum/routes/campaign/e-commerce/common/TableHeadCell'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import { Stack } from '@mui/system'

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body2
}))

export const SeoTable = () => {
  const { legacyProductSEOSummary } = useCampaignContext()

  return (
    <Stack spacing={3}>
      <Typography variant={'h4'}>Weekly SEO performance</Typography>
      <TableContainer>
        <Table
          sx={{
            display: 'block',
            overflowX: 'auto',
            whiteSpace: 'nowrap'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {legacyProductSEOSummary?.productSEOSummaryRecords.map((_, index) => (
                <TableHeadCell key={index} week={index + 1} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <BodyTableCell>
                <Stack spacing={0.5}>
                  <Stack spacing={0.5} direction={'row'}>
                    <Typography>Weekly search impressions</Typography>
                    <Tooltip
                      title={
                        <Stack direction={'column'} spacing={1}>
                          <Typography variant={'caption1'}>Organic product impressions in search results</Typography>
                          <Typography variant={'caption2'}>
                            This is the number of times per week that ProductWind estimates your product was viewed by
                            customers in the retailer’s organic search results.
                          </Typography>
                        </Stack>
                      }
                    >
                      <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Typography variant="caption2" color={theme => theme.palette.grey.A700}>
                    Organic
                  </Typography>
                </Stack>
              </BodyTableCell>
              {legacyProductSEOSummary?.productSEOSummaryRecords.map((d, index) => (
                <BodyTableCell key={index}>{d.impressions?.toLocaleString() ?? '--'}</BodyTableCell>
              ))}
            </TableRow>
            <TableRow>
              <BodyTableCell>
                <Stack spacing={0.5}>
                  <Stack spacing={0.5} direction={'row'}>
                    <Typography>Weekly page 1 wins</Typography>
                    <Tooltip
                      title={
                        <Stack direction={'column'}>
                          <Typography variant={'caption1'}>Weekly page 1 wins</Typography>
                          <Typography variant={'caption2'}>
                            Your product receives a Page 1 Win for a search term when it is displayed in one of the top
                            20 organic slots in the retailer’s search results.
                          </Typography>
                        </Stack>
                      }
                    >
                      <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Typography variant="caption2" color={theme => theme.palette.grey.A700}>
                    Organic
                  </Typography>
                </Stack>
              </BodyTableCell>
              {legacyProductSEOSummary?.productSEOSummaryRecords.map((d, index) => (
                <BodyTableCell key={index}>{d.topPageWins?.toLocaleString() ?? '--'}</BodyTableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

import { SvgIcon, SvgIconProps } from '@mui/material'

export default function CartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12" fill="none">
      <path
        d="M4 9C3.45 9 3.005 9.45 3.005 10C3.005 10.55 3.45 11 4 11C4.55 11 5 10.55 5 10C5 9.45 4.55 9 4 9ZM9 9C8.45 9 8.005 9.45 8.005 10C8.005 10.55 8.45 11 9 11C9.55 11 10 10.55 10 10C10 9.45 9.55 9 9 9ZM4.55 6.5H8.275C8.65 6.5 8.98 6.295 9.15 5.985L11 2.48L10.125 2L8.275 5.5H4.765L2.635 1H1V2H2L3.8 5.795L3.125 7.015C2.76 7.685 3.24 8.5 4 8.5H10V7.5H4L4.55 6.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

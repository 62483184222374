import StarRating from '@momentum/components/star-rating'
import { GridColDef, GridSortModel } from '@mui/x-data-grid-premium'
import { RecommendationType, Store } from '@productwindtom/shared-momentum-zeus-types'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'
import { Button, Stack, Typography } from '@mui/material'
import TableLink from '@momentum/components/table-link'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import { PRODUCT_LAUNCH_RATING_COUNT } from '@momentum/utils/proposalUtils'
import { RecommendationFieldNames } from './types'

export const RECOMMENDATION_COLUMN_DEFINITIONS: GridColDef[] = [
  {
    field: RecommendationFieldNames.COMPANY,
    headerName: 'Company',
    flex: 1,
    cellClassName: '--wrap-cell',
    renderCell: ({ value }) => (
      <Typography variant={'label4'} className={'--two-lines'}>
        {value}
      </Typography>
    )
  },
  {
    field: RecommendationFieldNames.BRAND,
    headerName: 'Brand',
    flex: 1,
    cellClassName: '--wrap-cell',
    renderCell: ({ value }) => (
      <Typography variant={'label4'} className={'--two-lines'}>
        {value}
      </Typography>
    )
  },
  {
    field: RecommendationFieldNames.IMAGE,
    headerName: 'Image',
    resizable: false,
    sortable: false,
    width: 65,
    renderCell: ({ value }) =>
      value && <img src={value} alt={'product'} width={36} height={36} style={{ objectFit: 'contain' }} />
  },
  {
    field: RecommendationFieldNames.NAME,
    headerName: 'Product Name',
    flex: 1,
    cellClassName: '--wrap-cell',
    renderCell: ({ value, row }) =>
      row.listingLink ? (
        <TableLink to={row.listingLink} label={value} className={'--two-lines'} />
      ) : (
        <Typography variant={'label4'} className={'--two-lines'}>
          {value}
        </Typography>
      )
  },
  {
    field: RecommendationFieldNames.STORE,
    headerName: 'Retailer',
    resizable: false,
    width: 75,
    renderCell: ({ value }) => StoreToIcon[value as Store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })
  },
  {
    field: RecommendationFieldNames.PRICE,
    headerName: 'Price',
    sortable: true,
    valueFormatter: value => (value ? `$${(value / 100).toFixed(2)}` : 'Not found')
  },
  {
    field: RecommendationFieldNames.AVAILABILITY_DATE,
    headerName: 'Date available',
    sortable: true,
    width: 120,
    renderCell: ({ value }) => (value ? new Date(value).toLocaleDateString() : 'Not found')
  },
  {
    field: RecommendationFieldNames.NUMRATINGS,
    headerName: 'Reviews & ratings',
    sortable: true,
    renderCell: ({ value }) => value && value.toLocaleString()
  },
  {
    field: RecommendationFieldNames.RATING,
    headerName: 'Rating',
    sortable: true,
    renderCell: ({ value }) => <StarRating rating={value} />
  },

  {
    field: RecommendationFieldNames.RECOMMENDATION_TYPE,
    headerName: 'Recommendation',
    width: 150,
    valueFormatter: value => RECOMMENDATION_TYPE_TO_DESCRIPTION[value as RecommendationType]
  },
  {
    field: RecommendationFieldNames.LAST_SCRAPED_AT,
    headerName: 'Last updated',
    sortable: true,
    width: 120,
    renderCell: ({ value }) => (value ? new Date(value).toLocaleDateString() : 'Not found')
  },
  {
    field: 'moreAction',
    headerName: '',
    width: 85,
    renderCell: ({ row }) => (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        {row.brandId && (
          <Button
            variant={'contained'}
            component={RouterLink}
            to={generatePath(ROUTES.BRAND_CREATE_PROPOSAL_FROM_RECOMMENDATION, {
              brandId: row.brandId,
              productId: row.id,
              type: row.recommendationType
            })}
          >
            View
          </Button>
        )}
      </Stack>
    )
  }
]

export const DEFAULT_SORT = [{ sort: 'desc', field: 'lastAutoFilledAt' }] as GridSortModel

export const RECOMMENDATION_PRIORITY = {
  [RecommendationType.PRODUCT_LAUNCH]: 1,
  [RecommendationType.BOOST_RATING]: 0
}

export const RECOMMENDATION_TYPE_TO_DESCRIPTION = {
  [RecommendationType.PRODUCT_LAUNCH]: `Needs ${PRODUCT_LAUNCH_RATING_COUNT} reviews`,
  [RecommendationType.BOOST_RATING]: 'Needs 4.3 rating'
}

export const RECOMMENDATION_SORT_MAP = {
  [RecommendationFieldNames.NAME]: 'name.keyword',
  [RecommendationFieldNames.BRAND]: 'brand.keyword',
  [RecommendationFieldNames.COMPANY]: 'company.keyword',
  [RecommendationFieldNames.STORE]: 'storeName.keyword',
  [RecommendationFieldNames.RECOMMENDATION_TYPE]: 'recommendations.recommendationType.keyword',
  [RecommendationFieldNames.PRICE]: 'priceCents',
  [RecommendationFieldNames.LAST_SCRAPED_AT]: 'lastAutoFilledAt'
}

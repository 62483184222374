import { CampaignClick } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_CLICKS_WATER_FLOSSER_PRIME_DAY: { items: CampaignClick[] } = {
  items: [
    {
      date: '2024-06-25',
      clicks: 4
    },
    {
      date: '2024-07-03',
      clicks: 3659
    },
    {
      date: '2024-07-04',
      clicks: 2004
    },
    {
      date: '2024-07-05',
      clicks: 1780
    },
    {
      date: '2024-07-06',
      clicks: 1173
    },
    {
      date: '2024-07-07',
      clicks: 1284
    },
    {
      date: '2024-07-08',
      clicks: 2649
    },
    {
      date: '2024-07-09',
      clicks: 2503
    },
    {
      date: '2024-07-10',
      clicks: 3127
    },
    {
      date: '2024-07-11',
      clicks: 3063
    },
    {
      date: '2024-07-12',
      clicks: 1886
    },
    {
      date: '2024-07-13',
      clicks: 1634
    },
    {
      date: '2024-07-14',
      clicks: 1631
    },
    {
      date: '2024-07-15',
      clicks: 1374
    },
    {
      date: '2024-07-16',
      clicks: 1374
    },
    {
      date: '2024-07-17',
      clicks: 1410
    },
    {
      date: '2024-07-18',
      clicks: 2018
    },
    {
      date: '2024-07-19',
      clicks: 1873
    },
    {
      date: '2024-07-20',
      clicks: 1365
    },
    {
      date: '2024-07-21',
      clicks: 1976
    },
    {
      date: '2024-07-22',
      clicks: 967
    },
    {
      date: '2024-07-23',
      clicks: 1079
    },
    {
      date: '2024-07-24',
      clicks: 748
    },
    {
      date: '2024-07-25',
      clicks: 809
    },
    {
      date: '2024-07-26',
      clicks: 840
    },
    {
      date: '2024-07-27',
      clicks: 512
    },
    {
      date: '2024-07-28',
      clicks: 1417
    },
    {
      date: '2024-07-29',
      clicks: 1076
    },
    {
      date: '2024-07-30',
      clicks: 498
    },
    {
      date: '2024-07-31',
      clicks: 250
    },
    {
      date: '2024-08-01',
      clicks: 273
    },
    {
      date: '2024-08-02',
      clicks: 229
    },
    {
      date: '2024-08-03',
      clicks: 347
    },
    {
      date: '2024-08-04',
      clicks: 227
    },
    {
      date: '2024-08-05',
      clicks: 155
    },
    {
      date: '2024-08-06',
      clicks: 663
    },
    {
      date: '2024-08-07',
      clicks: 61
    },
    {
      date: '2024-08-08',
      clicks: 118
    },
    {
      date: '2024-08-09',
      clicks: 79
    },
    {
      date: '2024-08-10',
      clicks: 103
    },
    {
      date: '2024-08-11',
      clicks: 79
    },
    {
      date: '2024-08-12',
      clicks: 72
    },
    {
      date: '2024-08-13',
      clicks: 63
    },
    {
      date: '2024-08-14',
      clicks: 33
    },
    {
      date: '2024-08-15',
      clicks: 66
    },
    {
      date: '2024-08-16',
      clicks: 95
    },
    {
      date: '2024-08-17',
      clicks: 81
    },
    {
      date: '2024-08-18',
      clicks: 76
    },
    {
      date: '2024-08-19',
      clicks: 66
    },
    {
      date: '2024-08-20',
      clicks: 89
    },
    {
      date: '2024-08-21',
      clicks: 82
    },
    {
      date: '2024-08-22',
      clicks: 135
    },
    {
      date: '2024-08-23',
      clicks: 36
    },
    {
      date: '2024-08-24',
      clicks: 298
    },
    {
      date: '2024-08-25',
      clicks: 111
    },
    {
      date: '2024-08-26',
      clicks: 146
    },
    {
      date: '2024-08-27',
      clicks: 119
    },
    {
      date: '2024-08-28',
      clicks: 183
    },
    {
      date: '2024-08-29',
      clicks: 159
    },
    {
      date: '2024-08-30',
      clicks: 40
    },
    {
      date: '2024-08-31',
      clicks: 49
    },
    {
      date: '2024-09-01',
      clicks: 3
    },
    {
      date: '2024-09-02',
      clicks: 25
    },
    {
      date: '2024-09-03',
      clicks: 17
    },
    {
      date: '2024-09-04',
      clicks: 23
    },
    {
      date: '2024-09-05',
      clicks: 31
    },
    {
      date: '2024-09-06',
      clicks: 51
    },
    {
      date: '2024-09-07',
      clicks: 31
    },
    {
      date: '2024-09-08',
      clicks: 28
    },
    {
      date: '2024-09-09',
      clicks: 49
    },
    {
      date: '2024-09-10',
      clicks: 32
    },
    {
      date: '2024-09-11',
      clicks: 32
    },
    {
      date: '2024-09-12',
      clicks: 27
    },
    {
      date: '2024-09-13',
      clicks: 38
    },
    {
      date: '2024-09-14',
      clicks: 36
    },
    {
      date: '2024-09-15',
      clicks: 64
    },
    {
      date: '2024-09-16',
      clicks: 93
    },
    {
      date: '2024-09-17',
      clicks: 83
    },
    {
      date: '2024-09-18',
      clicks: 34
    },
    {
      date: '2024-09-19',
      clicks: 47
    },
    {
      date: '2024-09-20',
      clicks: 51
    },
    {
      date: '2024-09-21',
      clicks: 14
    },
    {
      date: '2024-09-22',
      clicks: 12
    },
    {
      date: '2024-09-23',
      clicks: 7
    },
    {
      date: '2024-09-24',
      clicks: 13
    },
    {
      date: '2024-09-26',
      clicks: 10
    },
    {
      date: '2024-09-27',
      clicks: 17
    },
    {
      date: '2024-09-28',
      clicks: 2
    },
    {
      date: '2024-09-29',
      clicks: 2
    },
    {
      date: '2024-09-30',
      clicks: 2
    },
    {
      date: '2024-10-01',
      clicks: 1
    },
    {
      date: '2024-10-05',
      clicks: 2
    },
    {
      date: '2024-10-06',
      clicks: 1
    },
    {
      date: '2024-10-07',
      clicks: 1
    }
  ]
}

import StarRating from '@momentum/components/star-rating'
import TableLink from '@momentum/components/table-link'
import { ROUTES } from '@momentum/routes/RouteNames'
import { RECOMMENDATION_TYPE_TO_DESCRIPTION } from '@momentum/routes/recommendations/constants'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import { Close, InfoOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { RecommendationType, Store } from '@productwindtom/shared-momentum-zeus-types'
import { Link as RouterLink, generatePath } from 'react-router-dom'

export const RECOMMENDATION_COLUMN_DEFINITIONS = ({
  hideFunction,
  showFunction
}: {
  hideFunction: (skuId: string) => void
  showFunction: (skuId: string) => void
}): GridColDef[] => [
  {
    field: 'image',
    headerName: 'Image',
    resizable: false,
    width: 65,
    align: 'center',
    renderCell: ({ value }) =>
      value && (
        <img
          src={value}
          alt={'product'}
          width={36}
          height={36}
          style={{ objectFit: 'contain', verticalAlign: 'middle' }}
        />
      )
  },
  {
    field: 'name',
    headerName: 'Product Name',
    flex: 1,
    cellClassName: '--wrap-cell',
    renderCell: ({ value, row }) =>
      row.listingLink ? (
        <TableLink to={row.listingLink} label={value} className={'--two-lines'} />
      ) : (
        <Typography variant={'label4'} className={'--two-lines'}>
          {value}
        </Typography>
      )
  },
  {
    field: 'store',
    headerName: 'Retailer',
    resizable: false,
    width: 75,
    renderCell: ({ value }) => StoreToIcon[value as Store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })
  },
  {
    field: 'variationCount',
    headerName: 'Variations',
    sortable: true,
    width: 110,
    renderHeader: params => (
      <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
        <Typography variant={'label2'}>{params.colDef.headerName}</Typography>
        <Tooltip title={'This is the number of variations that share reviews on the listing.'}>
          <InfoOutlined fontSize={'mSmall'} sx={{ color: theme => theme.palette.grey.A700 }} />
        </Tooltip>
      </Stack>
    ),
    renderCell: ({ value }) => value && value.toLocaleString()
  },
  {
    field: 'price',
    headerName: 'Price',
    sortable: true
  },
  {
    field: 'availabilityDate',
    headerName: 'Date available',
    sortable: true,
    width: 120,
    renderCell: ({ value }) => (value ? new Date(value).toLocaleDateString() : 'Not found')
  },
  {
    field: 'numRatings',
    headerName: 'Reviews & ratings',
    sortable: true,
    renderCell: ({ value }) => value && value.toLocaleString()
  },
  {
    field: 'rating',
    headerName: 'Rating',
    sortable: true,
    renderCell: ({ value }) => <StarRating rating={value} />
  },

  {
    field: 'recommendationType',
    headerName: 'Recommendation',
    width: 150,
    valueFormatter: value => RECOMMENDATION_TYPE_TO_DESCRIPTION[value as RecommendationType]
  },
  {
    field: 'moreAction',
    headerName: '',
    width: 128,
    renderCell: ({ row }) => (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        {row.brandId && (
          <Button
            variant={'contained'}
            component={RouterLink}
            to={generatePath(ROUTES.BRAND_CREATE_PROPOSAL_FROM_RECOMMENDATION, {
              brandId: row.brandId,
              productId: row.id,
              type: row.recommendationType
            })}
          >
            View
          </Button>
        )}
        {!row.isRecommendationsHidden && (
          <Box>
            <IconButton size={'small'} onClick={() => hideFunction(row.id)}>
              <Close fontSize={'mSmall'} sx={{ color: 'black' }} />
            </IconButton>
          </Box>
        )}
        {row.isRecommendationsHidden && (
          <Box>
            <IconButton size={'small'} onClick={() => showFunction(row.id)}>
              <VisibilityOutlined fontSize={'mSmall'} sx={{ color: 'black' }} />
            </IconButton>
          </Box>
        )}
      </Stack>
    )
  }
]

import { currentProfile } from '@momentum/api'
import { uniqueId } from 'lodash'
import { DateTime } from 'luxon'
import { ZeusInterceptor } from '../types'
import { getSampleBrand } from '../utils'

export const adjustSubscriptionCredits: ZeusInterceptor<'mutation', 'adjustSubscriptionCredits'> = async args => {
  const input = args.o.adjustSubscriptionCredits?.[0].input
  const brandId = input?.id
  const sampleBrand = getSampleBrand(brandId)
  if (sampleBrand) {
    return {
      __typename: 'SubscriptionCreditAction',
      id: uniqueId(),
      action: input?.action!,
      actionId: uniqueId(),
      numCredits: input?.numCredits!,
      actionCreatedById: currentProfile.id,
      actionCreatedByFirstName: currentProfile.firstName,
      actionCreatedByLastName: currentProfile.lastName,
      createdAt: DateTime.now().toISO(),
      updatedAt: DateTime.now().toISO()
    }
  }
}

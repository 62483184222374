import { SvgIconProps, SvgIcon } from '@mui/material'

export default function TikTokIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M8.45095 19.7922C8.60723 18.4983 9.1379 17.7738 10.1379 17.0312C11.5688 16.0254 13.3561 16.5943 13.3561 16.5943V13.2192C13.7907 13.2081 14.2254 13.2338 14.6551 13.2961V17.6396C14.6551 17.6396 12.8683 17.0707 11.4375 18.077C10.438 18.8191 9.90623 19.5441 9.7505 20.838C9.74562 21.5406 9.87747 22.459 10.4847 23.2531C10.3345 23.1762 10.1815 23.0884 10.0256 22.99C8.68807 22.0918 8.44444 20.7444 8.45095 19.7922ZM22.0352 6.97849C21.0509 5.8999 20.6786 4.8109 20.5441 4.0459H21.7823C21.7823 4.0459 21.5354 6.05175 23.3347 8.02433L23.3597 8.05085C22.8747 7.74581 22.43 7.38575 22.0352 6.97849ZM28 10.0364V14.2926C28 14.2926 26.42 14.2307 25.2507 13.9332C23.6179 13.5172 22.5685 12.879 22.5685 12.879C22.5685 12.879 21.8436 12.424 21.785 12.3923V21.1812C21.785 21.6706 21.651 22.8927 21.2424 23.912C20.709 25.2455 19.8859 26.1207 19.7345 26.2996C19.7345 26.2996 18.7334 27.4827 16.9672 28.2795C15.3752 28.9982 13.9774 28.98 13.5596 28.9982C13.5596 28.9982 11.1434 29.0939 8.96915 27.6809C8.49898 27.3694 8.06011 27.0167 7.6582 26.6273L7.66906 26.635C9.84383 28.048 12.2595 27.9524 12.2595 27.9524C12.6779 27.9342 14.0756 27.9524 15.6671 27.2336C17.4317 26.4369 18.4344 25.2538 18.4344 25.2538C18.5842 25.0749 19.4111 24.1996 19.9423 22.8657C20.3498 21.8469 20.4849 20.6242 20.4849 20.1349V11.347C20.5435 11.3792 21.2679 11.8343 21.2679 11.8343C21.2679 11.8343 22.3179 12.4729 23.9506 12.8884C25.1204 13.1859 26.7 13.2478 26.7 13.2478V9.91265C27.2404 10.0338 27.7011 10.0666 28 10.0364Z"
        fill="#EE1D52"
      />
      <path
        d="M26.7009 9.91265V13.2467C26.7009 13.2467 25.1213 13.1848 23.9515 12.8874C22.3188 12.4713 21.2688 11.8332 21.2688 11.8332C21.2688 11.8332 20.5444 11.3782 20.4858 11.3459V20.1359C20.4858 20.6253 20.3518 21.8479 19.9432 22.8667C19.4098 24.2007 18.5867 25.0759 18.4353 25.2548C18.4353 25.2548 17.4337 26.4379 15.668 27.2347C14.0765 27.9534 12.6788 27.9352 12.2604 27.9534C12.2604 27.9534 9.84473 28.0491 7.66995 26.6361L7.6591 26.6283C7.42949 26.406 7.21336 26.1712 7.01177 25.9252C6.31777 25.079 5.89237 24.0785 5.78547 23.7929C5.78529 23.7917 5.78529 23.7905 5.78547 23.7893C5.61347 23.2932 5.25209 22.1017 5.30147 20.9477C5.38883 18.9117 6.10507 17.662 6.29444 17.3489C6.79597 16.4952 7.44828 15.7313 8.22233 15.0914C8.90538 14.5391 9.6796 14.0997 10.5132 13.7912C11.4144 13.429 12.3794 13.2348 13.3565 13.2192V16.5943C13.3565 16.5943 11.5691 16.0275 10.1388 17.0312C9.13879 17.7738 8.60812 18.4983 8.45185 19.7922C8.44534 20.7444 8.68897 22.0918 10.0254 22.9905C10.1813 23.0893 10.3343 23.177 10.4845 23.2536C10.7179 23.5571 11.0021 23.8216 11.3255 24.0363C12.631 24.8627 13.7249 24.9204 15.1238 24.3837C16.0565 24.0249 16.7586 23.2162 17.0842 22.3201C17.2888 21.7606 17.2861 21.1973 17.2861 20.6149V4.0459H20.5417C20.6763 4.8109 21.0485 5.8999 22.0328 6.97849C22.4276 7.38575 22.8724 7.74581 23.3573 8.05085C23.5006 8.19907 24.2331 8.93182 25.1734 9.38167C25.6596 9.6142 26.1722 9.79236 26.7009 9.91265Z"
        fill="black"
      />
      <path
        d="M4.49023 22.7568V22.7594L4.57102 22.9784C4.56174 22.9529 4.53172 22.8754 4.49023 22.7568Z"
        fill="#69C9D0"
      />
      <path
        d="M10.5128 13.7916C9.67919 14.1002 8.90498 14.5396 8.22192 15.0918C7.44763 15.7332 6.79548 16.4987 6.29458 17.354C6.10521 17.6661 5.38897 18.9168 5.30161 20.9528C5.25223 22.1068 5.61361 23.2983 5.78561 23.7944C5.78543 23.7956 5.78543 23.7968 5.78561 23.798C5.89413 24.081 6.31791 25.0815 7.01191 25.9303C7.2135 26.1763 7.42963 26.4111 7.65924 26.6334C6.92357 26.1457 6.26746 25.5562 5.71236 24.8839C5.02433 24.0451 4.60001 23.0549 4.48932 22.7626C4.48919 22.7605 4.48919 22.7584 4.48932 22.7564V22.7527C4.31677 22.2571 3.95431 21.0651 4.00477 19.9096C4.09213 17.8736 4.80838 16.6239 4.99775 16.3108C5.4985 15.4553 6.15067 14.6898 6.92509 14.0486C7.608 13.4961 8.38225 13.0567 9.21598 12.7484C9.73602 12.5416 10.2778 12.3891 10.8319 12.2934C11.6669 12.1537 12.5198 12.1415 13.3588 12.2575V13.2196C12.3808 13.2349 11.4148 13.4291 10.5128 13.7916Z"
        fill="#69C9D0"
      />
      <path
        d="M20.5438 4.04635H17.2881V20.6159C17.2881 21.1983 17.2881 21.76 17.0863 22.3211C16.7575 23.2167 16.058 24.0253 15.1258 24.3842C13.7265 24.923 12.6326 24.8632 11.3276 24.0368C11.0036 23.823 10.7187 23.5594 10.4844 23.2567C11.5962 23.8251 12.5913 23.8152 13.8241 23.341C14.7558 22.9821 15.4563 22.1734 15.784 21.2774C15.9891 20.7178 15.9864 20.1546 15.9864 19.5726V3H20.4819C20.4819 3 20.4315 3.41188 20.5438 4.04635ZM26.7002 8.99104V9.9131C26.1725 9.79263 25.6609 9.61447 25.1755 9.38213C24.2352 8.93228 23.5026 8.19952 23.3594 8.0513C23.5256 8.1559 23.6981 8.25106 23.8759 8.33629C25.0192 8.88339 26.1451 9.04669 26.7002 8.99104Z"
        fill="#69C9D0"
      />
    </SvgIcon>
  )
}

import { urlPreTransform } from '@momentum/utils/validationUtils'
import { PaymentTermsType } from '@productwindtom/shared-momentum-zeus-types'
import * as yup from 'yup'

export const agencyFieldsSchema = yup
  .object({
    name: yup
      .string()
      .trim()
      .required('Agency Name is Required.')
      .when('agency', {
        is: (val?: boolean) => val === undefined,
        then: (schema: yup.Schema) => schema.optional()
      }),
    logo: yup
      .string()
      .required('Agency Logo is Required.')
      .when('name', {
        is: (val?: string) => !val,
        then: (schema: yup.Schema) => schema.optional()
      }),
    websiteUrl: yup
      .string()
      .transform(urlPreTransform)
      .url('Not a valid URL')
      .required('Agency Website is Required.')
      .when('name', {
        is: (val?: string) => !val,
        then: (schema: yup.Schema) => schema.optional()
      }),
    paymentTermsType: yup
      .string<PaymentTermsType>()
      .optional()
      .when('name', {
        is: (val?: string) => !!val,
        then: (schema: yup.Schema) => schema.required('Payment type is required.')
      }),
    paymentTermsCustomNetDays: yup
      .number()
      .optional()
      .when('paymentTermsType', {
        is: PaymentTermsType.NET_CUSTOM,
        then: s => s.required('Required'),
        otherwise: s => s.notRequired()
      }),
    id: yup
      .string()
      .required('Required')
      .when('name', {
        is: (val: string) => !val,
        then: (schema: yup.Schema) => schema.optional()
      }),
    existingAgency: yup.boolean().optional()
  })
  .optional()

export const agencyFormSchema = yup.object({
  agency: agencyFieldsSchema
})

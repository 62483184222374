import { CreatorApprovalStatus, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { createContext, useContext } from 'react'
import { CampaignCreatorForApproval } from '../../context/queries'

export enum CreatorTypeFilter {
  SOCIAL = 'SOCIAL',
  UGC = 'UGC'
}

export type ApproveCreatorsContextType = {
  creators: CampaignCreatorForApproval[]
  submitApproval: (input: ValueTypes['SubmitCampaignCreatorApprovalInput']) => Promise<void>
  search?: string
  setSearch: (search?: string) => void
  approvalStatus?: CreatorApprovalStatus
  setApprovalStatus: (status?: CreatorApprovalStatus) => void
  creatorTypeFilter?: CreatorTypeFilter
  setCreatorTypeFilter: (filter?: CreatorTypeFilter) => void
}

const ApproveCreatorsContext = createContext<ApproveCreatorsContextType>({} as any)
ApproveCreatorsContext.displayName = 'ApproveCreatorsContext'

export default ApproveCreatorsContext

export const useApproveCreatorsContext = () => useContext(ApproveCreatorsContext)

import { Button, Box, Stack, Typography } from '@mui/material'
import FinancialFlowRow from './FinancialFlowRow'
import { NumberInput } from '@productwindtom/ui-base'
import FinancialContentRequirements from './FinancialContentRequirements'
import { CreatorType } from '@productwindtom/shared-momentum-zeus-types'
import EmptyBox from './EmptyBox'
import { useFormContext } from 'react-hook-form'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import {
  generateContentRequirements,
  convertToCreatorPricing,
  convertFromCreatorPricing
} from '@momentum/utils/proposalUtils'
import { toLocaleCurrency } from '@productwindtom/shared-node'
import { FinancialFlowSummaryType } from '@momentum/routes/proposals-create/context/types'

const FinancialFlowSummary = ({
  flowSummary,
  productPrice,
  disabled
}: {
  flowSummary: FinancialFlowSummaryType[]
  productPrice: number
  disabled?: boolean
}) => {
  const { setValue, getValues } = useFormContext<ProposalCreateForm>()

  const handleResetDefaults = () => {
    const creatorPricing = getValues().creatorPricing

    const resetCreatorPricing = convertFromCreatorPricing(convertToCreatorPricing(creatorPricing, true))

    setValue('liftNumCreators', 0)
    setValue('liftCloseoutBonus', 0)
    setValue('creatorPricing', resetCreatorPricing)
    setValue('contentRequirements', generateContentRequirements(resetCreatorPricing))
  }

  return (
    <Stack spacing={3}>
      <Typography variant="h4">Flow-level summary</Typography>
      <FinancialFlowRow>
        {flowSummary.map((flow, index) => (
          <Box key={index} flex={1}>
            <Typography variant="label1" textAlign={'center'}>
              {flow.title}
            </Typography>
          </Box>
        ))}
      </FinancialFlowRow>
      <FinancialFlowRow title={'# of creators'}>
        {flowSummary.map((flow, index) => (
          <Box key={index} flex={1}>
            <NumberInput
              name={flow.inputPath ? `${flow.inputPath}.numCreators` : 'liftNumCreators'}
              returnAsNumber
              disabled={disabled}
              allowNegative={false}
            />
          </Box>
        ))}
      </FinancialFlowRow>
      <FinancialFlowRow title={'Price per creator'}>
        {flowSummary.map((flow, index) => (
          <Box key={index} flex={1}>
            <Typography variant="label3">{toLocaleCurrency(flow.price)}</Typography>
          </Box>
        ))}
      </FinancialFlowRow>
      <FinancialFlowRow title={'Product ASP'}>
        {flowSummary.map((_, index) => (
          <Box key={index} flex={1}>
            <Typography variant="label3">{toLocaleCurrency(productPrice / 100)}</Typography>
          </Box>
        ))}
      </FinancialFlowRow>
      <FinancialFlowRow title={'Adjusted product ASP'}>
        {flowSummary.map((_, index) => (
          <Box key={index} flex={1}>
            <Typography variant="label3">{toLocaleCurrency((productPrice / 100) * 1.1)}</Typography>
          </Box>
        ))}
      </FinancialFlowRow>
      <FinancialFlowRow title={'Expected reviews'}>
        {flowSummary.map((flow, index) => (
          <Box key={index} flex={1}>
            <Typography variant="label3">{flow.expectedReviews.toLocaleString()}</Typography>
          </Box>
        ))}
      </FinancialFlowRow>
      <FinancialContentRequirements flowSummary={flowSummary} disabled={disabled} />
      <FinancialFlowRow title={'Total content payment'}>
        {flowSummary.map((flow, index) => (
          <Box key={index} flex={1}>
            {!flow.creatorType || CreatorType.ADVOCATE === flow.creatorType ? (
              <EmptyBox />
            ) : (
              <Typography variant="label3">{toLocaleCurrency(flow.totalContentPayment)}</Typography>
            )}
          </Box>
        ))}
      </FinancialFlowRow>
      <FinancialFlowRow title={'Close-out payment'}>
        {flowSummary.map((flow, index) => (
          <Box key={index} flex={1}>
            <NumberInput
              InputProps={{ startAdornment: '$' }}
              name={flow.inputPath ? `${flow.inputPath}.closeoutBonus` : 'liftCloseoutBonus'}
              returnAsNumber
              returnAsCents
              disabled={disabled}
              allowNegative={false}
            />
          </Box>
        ))}
      </FinancialFlowRow>
      <FinancialFlowRow title={'Total payment to creator'} bgcolor="#F8FBFF">
        {flowSummary.map((flow, index) => (
          <Box key={index} flex={1} alignContent={'center'} justifyContent={'center'}>
            <Typography variant="label3">${flow.totalPayment?.toLocaleString()}</Typography>
          </Box>
        ))}
      </FinancialFlowRow>
      {!disabled && (
        <Button sx={{ alignSelf: 'flex-start' }} variant="text" onClick={handleResetDefaults}>
          Reset to default
        </Button>
      )}
    </Stack>
  )
}

export default FinancialFlowSummary

import { CampaignClick } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_CLICKS_KEYBOARD_REVIEWS_PL: { items: CampaignClick[] } = {
  items: [
    {
      date: '2024-04-22',
      clicks: 136
    },
    {
      date: '2024-04-23',
      clicks: 503
    },
    {
      date: '2024-04-24',
      clicks: 139
    },
    {
      date: '2024-04-25',
      clicks: 92
    },
    {
      date: '2024-04-26',
      clicks: 71
    },
    {
      date: '2024-04-27',
      clicks: 48
    },
    {
      date: '2024-04-28',
      clicks: 57
    },
    {
      date: '2024-04-29',
      clicks: 46
    },
    {
      date: '2024-04-30',
      clicks: 38
    },
    {
      date: '2024-05-01',
      clicks: 42
    },
    {
      date: '2024-05-02',
      clicks: 40
    },
    {
      date: '2024-05-03',
      clicks: 41
    },
    {
      date: '2024-05-04',
      clicks: 21
    },
    {
      date: '2024-05-05',
      clicks: 23
    },
    {
      date: '2024-05-06',
      clicks: 35
    },
    {
      date: '2024-05-07',
      clicks: 27
    },
    {
      date: '2024-05-08',
      clicks: 24
    },
    {
      date: '2024-05-09',
      clicks: 6253
    },
    {
      date: '2024-05-10',
      clicks: 4842
    },
    {
      date: '2024-05-11',
      clicks: 4244
    },
    {
      date: '2024-05-12',
      clicks: 2078
    },
    {
      date: '2024-05-13',
      clicks: 909
    },
    {
      date: '2024-05-14',
      clicks: 1830
    },
    {
      date: '2024-05-15',
      clicks: 1423
    },
    {
      date: '2024-05-16',
      clicks: 1607
    },
    {
      date: '2024-05-17',
      clicks: 1003
    },
    {
      date: '2024-05-18',
      clicks: 560
    },
    {
      date: '2024-05-19',
      clicks: 1050
    },
    {
      date: '2024-05-20',
      clicks: 399
    },
    {
      date: '2024-05-21',
      clicks: 1207
    },
    {
      date: '2024-05-22',
      clicks: 615
    },
    {
      date: '2024-05-23',
      clicks: 680
    },
    {
      date: '2024-05-24',
      clicks: 400
    },
    {
      date: '2024-05-25',
      clicks: 1293
    },
    {
      date: '2024-05-26',
      clicks: 260
    },
    {
      date: '2024-05-27',
      clicks: 317
    },
    {
      date: '2024-05-28',
      clicks: 481
    },
    {
      date: '2024-05-29',
      clicks: 303
    },
    {
      date: '2024-05-30',
      clicks: 285
    },
    {
      date: '2024-05-31',
      clicks: 317
    },
    {
      date: '2024-06-01',
      clicks: 801
    },
    {
      date: '2024-06-02',
      clicks: 559
    },
    {
      date: '2024-06-03',
      clicks: 511
    },
    {
      date: '2024-06-04',
      clicks: 282
    },
    {
      date: '2024-06-05',
      clicks: 392
    },
    {
      date: '2024-06-06',
      clicks: 515
    },
    {
      date: '2024-06-07',
      clicks: 337
    },
    {
      date: '2024-06-08',
      clicks: 318
    },
    {
      date: '2024-06-09',
      clicks: 223
    },
    {
      date: '2024-06-10',
      clicks: 328
    },
    {
      date: '2024-06-11',
      clicks: 300
    },
    {
      date: '2024-06-12',
      clicks: 244
    },
    {
      date: '2024-06-13',
      clicks: 171
    },
    {
      date: '2024-06-14',
      clicks: 292
    },
    {
      date: '2024-06-15',
      clicks: 73
    },
    {
      date: '2024-06-16',
      clicks: 176
    },
    {
      date: '2024-06-17',
      clicks: 244
    },
    {
      date: '2024-06-18',
      clicks: 158
    },
    {
      date: '2024-06-19',
      clicks: 102
    },
    {
      date: '2024-06-20',
      clicks: 180
    },
    {
      date: '2024-06-21',
      clicks: 112
    },
    {
      date: '2024-06-22',
      clicks: 345
    },
    {
      date: '2024-06-23',
      clicks: 296
    },
    {
      date: '2024-06-24',
      clicks: 346
    },
    {
      date: '2024-06-25',
      clicks: 220
    },
    {
      date: '2024-06-26',
      clicks: 338
    },
    {
      date: '2024-06-27',
      clicks: 151
    },
    {
      date: '2024-06-28',
      clicks: 137
    },
    {
      date: '2024-06-29',
      clicks: 155
    },
    {
      date: '2024-06-30',
      clicks: 722
    },
    {
      date: '2024-07-01',
      clicks: 263
    },
    {
      date: '2024-07-02',
      clicks: 350
    },
    {
      date: '2024-07-03',
      clicks: 116
    },
    {
      date: '2024-07-04',
      clicks: 67
    },
    {
      date: '2024-07-05',
      clicks: 97
    },
    {
      date: '2024-07-06',
      clicks: 133
    },
    {
      date: '2024-07-07',
      clicks: 2071
    },
    {
      date: '2024-07-08',
      clicks: 194
    },
    {
      date: '2024-07-09',
      clicks: 193
    },
    {
      date: '2024-07-10',
      clicks: 515
    },
    {
      date: '2024-07-11',
      clicks: 708
    },
    {
      date: '2024-07-12',
      clicks: 391
    },
    {
      date: '2024-07-13',
      clicks: 196
    },
    {
      date: '2024-07-14',
      clicks: 96
    },
    {
      date: '2024-07-15',
      clicks: 165
    },
    {
      date: '2024-07-16',
      clicks: 159
    },
    {
      date: '2024-07-17',
      clicks: 367
    },
    {
      date: '2024-07-18',
      clicks: 232
    },
    {
      date: '2024-07-19',
      clicks: 244
    },
    {
      date: '2024-07-20',
      clicks: 166
    },
    {
      date: '2024-07-21',
      clicks: 377
    },
    {
      date: '2024-07-22',
      clicks: 677
    },
    {
      date: '2024-07-23',
      clicks: 448
    },
    {
      date: '2024-07-24',
      clicks: 774
    },
    {
      date: '2024-07-25',
      clicks: 467
    },
    {
      date: '2024-07-26',
      clicks: 637
    },
    {
      date: '2024-07-27',
      clicks: 263
    },
    {
      date: '2024-07-28',
      clicks: 73
    },
    {
      date: '2024-07-29',
      clicks: 39
    },
    {
      date: '2024-07-30',
      clicks: 20
    },
    {
      date: '2024-07-31',
      clicks: 78
    },
    {
      date: '2024-08-01',
      clicks: 10
    },
    {
      date: '2024-08-02',
      clicks: 7
    },
    {
      date: '2024-08-03',
      clicks: 12
    },
    {
      date: '2024-08-04',
      clicks: 8
    },
    {
      date: '2024-08-05',
      clicks: 3
    },
    {
      date: '2024-08-07',
      clicks: 2
    },
    {
      date: '2024-08-08',
      clicks: 1
    },
    {
      date: '2024-08-09',
      clicks: 3
    },
    {
      date: '2024-08-10',
      clicks: 2
    },
    {
      date: '2024-08-11',
      clicks: 14
    },
    {
      date: '2024-08-13',
      clicks: 1
    },
    {
      date: '2024-08-14',
      clicks: 1
    },
    {
      date: '2024-08-15',
      clicks: 1
    },
    {
      date: '2024-08-17',
      clicks: 1
    },
    {
      date: '2024-08-18',
      clicks: 1
    },
    {
      date: '2024-08-19',
      clicks: 3
    },
    {
      date: '2024-08-20',
      clicks: 1
    },
    {
      date: '2024-08-21',
      clicks: 1
    },
    {
      date: '2024-08-22',
      clicks: 2
    },
    {
      date: '2024-08-27',
      clicks: 2
    },
    {
      date: '2024-08-31',
      clicks: 1
    },
    {
      date: '2024-09-01',
      clicks: 1
    },
    {
      date: '2024-09-04',
      clicks: 1
    },
    {
      date: '2024-09-09',
      clicks: 2
    },
    {
      date: '2024-09-10',
      clicks: 1
    },
    {
      date: '2024-09-11',
      clicks: 1
    },
    {
      date: '2024-09-16',
      clicks: 1
    },
    {
      date: '2024-09-19',
      clicks: 3
    },
    {
      date: '2024-09-20',
      clicks: 1
    },
    {
      date: '2024-09-21',
      clicks: 1
    },
    {
      date: '2024-09-23',
      clicks: 2
    },
    {
      date: '2024-09-25',
      clicks: 1
    },
    {
      date: '2024-09-26',
      clicks: 1
    },
    {
      date: '2024-09-28',
      clicks: 3
    },
    {
      date: '2024-10-05',
      clicks: 1
    }
  ]
}

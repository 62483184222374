import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { FormHelperText } from '@mui/material'
import { InputErrorProps } from './types'

const InputError = ({ name, variant }: InputErrorProps) => {
  const {
    formState: { errors }
  } = useFormContext()

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) =>
        message ? (
          <FormHelperText error variant={variant}>
            {message}
          </FormHelperText>
        ) : null
      }
    />
  )
}

export default InputError

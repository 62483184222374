import { Stack, Typography } from '@mui/material'
import CollectingResultsImage from './image.png'

export const CollectingSEOResults = () => {
  return (
    <Stack alignItems={'center'} spacing={3} pt={12}>
      <img src={CollectingResultsImage} alt={'Collecting results'} width={200} />
      <Typography variant={'h5'} textAlign={'center'}>
        We’re collecting sponsored rank data for your campaign.
      </Typography>
      <Typography variant="label4" textAlign={'center'}>
        The Sponsored rank dashboard will be <br />
        available soon.
      </Typography>
    </Stack>
  )
}

import { Box, SvgIcon } from '@mui/material'
import AmazonIcon from '@momentum/components/icons/amazon'

export const IntegratePrompt = () => {
  return (
    <Box position={'relative'} sx={{ width: 201, height: 193 }}>
      <SvgIcon viewBox="0 0 203 193" fill="none" sx={{ width: 201, height: 193 }}>
        <path
          d="M97.022 192.513C150.183 192.513 193.279 149.417 193.279 96.2565C193.279 43.0955 150.183 0 97.022 0C43.8611 0 0.765625 43.0955 0.765625 96.2565C0.765625 149.417 43.8611 192.513 97.022 192.513Z"
          fill="#94D5D9"
        />
        <path
          d="M185.459 41.3513V159.871C185.459 162.991 182.931 165.526 179.804 165.526H10.3132C7.19347 165.526 4.6582 162.998 4.6582 159.871V41.3513C4.6582 38.2316 7.18588 35.6963 10.3132 35.6963H179.804C182.924 35.6963 185.459 38.2316 185.459 41.3513Z"
          fill="white"
        />
        <path
          d="M179.809 166.657H10.318C6.57585 166.657 3.52441 163.613 3.52441 159.863V41.3435C3.52441 37.6013 6.56826 34.5498 10.318 34.5498H179.809C183.551 34.5498 186.602 37.5937 186.602 41.3435V159.863C186.602 163.613 183.551 166.657 179.809 166.657ZM10.318 36.8346C7.8283 36.8346 5.8016 38.8613 5.8016 41.351V159.871C5.8016 162.36 7.8283 164.387 10.318 164.387H179.809C182.298 164.387 184.325 162.36 184.325 159.871V41.351C184.325 38.8613 182.298 36.8346 179.809 36.8346H10.318Z"
          fill="#243C98"
        />
        <path
          d="M185.336 41.4285V55.4863H4.54297V41.4285C4.54297 38.3088 7.0706 35.7734 10.1979 35.7734H179.689C182.808 35.781 185.336 38.3088 185.336 41.4285Z"
          fill="#324BA8"
        />
        <path
          d="M185.337 56.6248H4.54386C3.91384 56.6248 3.40527 56.1162 3.40527 55.4862V41.4284C3.40527 37.6862 6.44907 34.6348 10.1988 34.6348H179.689C183.432 34.6348 186.483 37.6787 186.483 41.4284V55.4862C186.475 56.1162 185.967 56.6248 185.337 56.6248ZM5.68246 54.3476H184.198V41.4284C184.198 38.9387 182.172 36.9119 179.682 36.9119H10.1912C7.70151 36.9119 5.67486 38.9387 5.67486 41.4284V54.3476H5.68246Z"
          fill="#324AA8"
        />
        <path
          d="M28.0383 47.4465C29.3085 47.4465 30.3382 46.4169 30.3382 45.1467C30.3382 43.8764 29.3085 42.8467 28.0383 42.8467C26.768 42.8467 25.7383 43.8764 25.7383 45.1467C25.7383 46.4169 26.768 47.4465 28.0383 47.4465Z"
          fill="#12B76A"
        />
        <path
          d="M20.5373 47.4465C21.8075 47.4465 22.8373 46.4169 22.8373 45.1467C22.8373 43.8764 21.8075 42.8467 20.5373 42.8467C19.2671 42.8467 18.2373 43.8764 18.2373 45.1467C18.2373 46.4169 19.2671 47.4465 20.5373 47.4465Z"
          fill="#F3C94E"
        />
        <path
          d="M13.048 47.4465C14.3183 47.4465 15.348 46.4169 15.348 45.1467C15.348 43.8764 14.3183 42.8467 13.048 42.8467C11.7778 42.8467 10.748 43.8764 10.748 45.1467C10.748 46.4169 11.7778 47.4465 13.048 47.4465Z"
          fill="#EA0000"
        />
        <path
          d="M167.956 187.198C186.888 187.198 202.235 171.851 202.235 152.919C202.235 133.987 186.888 118.64 167.956 118.64C149.024 118.64 133.677 133.987 133.677 152.919C133.677 171.851 149.024 187.198 167.956 187.198Z"
          fill="#324AA8"
        />
        <path
          d="M180.572 149.237H172.981V141.646C172.981 139.794 171.478 138.299 169.634 138.299H168.29C166.438 138.299 164.943 139.802 164.943 141.646V149.237H157.352C155.5 149.237 154.005 150.74 154.005 152.584V153.928C154.005 155.78 155.508 157.275 157.352 157.275H164.943V164.866C164.943 166.718 166.446 168.213 168.29 168.213H169.634C171.486 168.213 172.981 166.71 172.981 164.866V157.275H180.572C182.424 157.275 183.919 155.772 183.919 153.928V152.584C183.927 150.732 182.424 149.237 180.572 149.237Z"
          fill="white"
        />
      </SvgIcon>
      <AmazonIcon
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-40px, -20px)',
          height: '60px',
          width: '60px'
        }}
      />
    </Box>
  )
}

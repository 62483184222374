import { Channel, ContentType } from '@productwindtom/shared-momentum-zeus-types'
import InstagramIcon from '@momentum/components/icons/social-platforms/instagram'
import TikTokIcon from '@momentum/components/icons/social-platforms/tiktok'
import YouTubeIcon from '@momentum/components/icons/social-platforms/youtube'
import { SvgIconProps } from '@mui/material'
import LogoIcon from '@momentum/components/icons/logo'

export const ContentTypeToString: Record<ContentType, string> = {
  [ContentType.IG_REEL]: 'Feed reel',
  [ContentType.IG_POST]: 'Feed post',
  [ContentType.IG_STORY]: 'Story',
  [ContentType.IG_VIDEO]: 'In-feed video',
  [ContentType.TT_VIDEO]: 'Feed post',
  [ContentType.TT_STORY]: 'Story',
  [ContentType.YT_SHORT]: 'Short',
  [ContentType.YT_VIDEO]: 'Video',
  [ContentType.YT_SHOUTOUT]: 'Shoutout',
  [ContentType.UGC_PHOTO]: 'Photo',
  [ContentType.UGC_VIDEO]: 'Video',
  [ContentType.REVIEW_PHOTO]: 'Photo',
  [ContentType.REVIEW_TEXT]: 'Text'
}

export const ContentTypeToSocialString: Record<ContentType, string> = {
  [ContentType.IG_REEL]: 'Instagram reel',
  [ContentType.IG_POST]: 'Instagram feed post',
  [ContentType.IG_STORY]: 'Instagram story',
  [ContentType.IG_VIDEO]: 'Instagram in-feed video',
  [ContentType.TT_VIDEO]: 'TikTok feed post',
  [ContentType.TT_STORY]: 'TikTok story',
  [ContentType.YT_SHORT]: 'YouTube short',
  [ContentType.YT_VIDEO]: 'YouTube video',
  [ContentType.YT_SHOUTOUT]: 'YouTube shoutout',
  [ContentType.UGC_PHOTO]: 'UGC photo',
  [ContentType.UGC_VIDEO]: 'UGC video',
  [ContentType.REVIEW_PHOTO]: 'Photo review',
  [ContentType.REVIEW_TEXT]: 'Text review'
}

export const SocialChannelToString: Record<Channel, string> = {
  [Channel.INSTAGRAM]: 'Instagram',
  [Channel.TIKTOK]: 'TikTok',
  [Channel.YOUTUBE]: 'YouTube',
  [Channel.UGC]: 'UGC'
}

export const ChannelToContentTypes: Record<Channel, ContentType[]> = {
  [Channel.INSTAGRAM]: [ContentType.IG_REEL, ContentType.IG_POST, ContentType.IG_STORY],
  [Channel.TIKTOK]: [ContentType.TT_VIDEO, ContentType.TT_STORY],
  [Channel.YOUTUBE]: [ContentType.YT_VIDEO, ContentType.YT_SHOUTOUT, ContentType.YT_SHORT],
  [Channel.UGC]: [ContentType.UGC_PHOTO, ContentType.UGC_VIDEO]
}

export const SocialChannelToIcon: Record<Channel, (props: SvgIconProps) => JSX.Element> = {
  [Channel.INSTAGRAM]: InstagramIcon,
  [Channel.TIKTOK]: TikTokIcon,
  [Channel.YOUTUBE]: YouTubeIcon,
  [Channel.UGC]: LogoIcon
}

export const getSocialProfile = <T>(
  channel: Channel,
  creator: {
    instagramProfile?: T
    tiktokProfile?: T
    youtubeProfile?: T
  }
) => {
  switch (channel) {
    case Channel.INSTAGRAM:
      return creator.instagramProfile
    case Channel.TIKTOK:
      return creator.tiktokProfile
    case Channel.YOUTUBE:
      return creator.youtubeProfile
    default:
      return
  }
}

export const getHandle = (
  channel: Channel,
  creator: {
    instagramProfile?: { handle: string }
    tiktokProfile?: { handle: string }
    youtubeProfile?: { handle: string }
  }
) => {
  return getSocialProfile(channel, creator)?.handle
}

export const getHandleLink = (
  channel: Channel,
  creator: {
    instagramProfile?: { handle: string }
    tiktokProfile?: { handle: string }
    youtubeProfile?: { handle: string }
  }
) => {
  switch (channel) {
    case Channel.INSTAGRAM:
      return creator.instagramProfile?.handle
        ? `https://www.instagram.com/${creator.instagramProfile?.handle}`
        : undefined
    case Channel.TIKTOK:
      return creator.tiktokProfile?.handle ? `https://www.tiktok.com/@${creator.tiktokProfile?.handle}` : undefined
    case Channel.YOUTUBE:
      return creator.youtubeProfile ? `https://www.youtube.com/@${creator.youtubeProfile?.handle}` : undefined
    default:
      return
  }
}
export const IG_POST_REGEX = /(?:https?:\/\/(?:www\.)?instagram\.com\/(?:\S*\/)?(?:p|reel|tv)\/([^/?#&]+)).*\/{0,1}/i
export const IG_STORY_REGEX =
  /(((https?:\/\/)?(?:www\.)?instagram\.com\/(?:reel|s)\/([^/?#&]+)).*|((https?:\/\/)?(?:www\.)?instagram\.com\/stories\/(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}\/([^/?#&]+)).*)/i
export const TT_REGEX = /(?:https?:\/\/(?:www\.)?tiktok\.com\/@([^/?#&]+)\/video\/(\d+)).*\/{0,1}/
export const YOUTUBE_REGEX =
  /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
export const YOUTUBE_SHORTS_REGEX = /(?:(?:https?:\/\/(?:www\.))?(?:(?:youtube\.com|youtu.be)))\/shorts\/(\w+).*\/{0,1}/

import { values } from 'lodash'
import { ZeusInterceptor } from '../types'
import { SAMPLE_PROPOSALS_BY_BRAND_ID } from './sample-data'
import { STORAGE } from '../storage'
import { getSampleBrand } from '../utils'

export const listProposalsByBrandId: ZeusInterceptor<'query', 'listProposalsByBrandId'> = async args => {
  const brandId = args.o.listProposalsByBrandId?.[0].brandId!
  const sampleBrand = getSampleBrand(brandId)
  if (sampleBrand) {
    return {
      items: [
        ...values(STORAGE.proposals).filter(p => p.brandId === brandId),
        ...(SAMPLE_PROPOSALS_BY_BRAND_ID[brandId]?.items || [])
      ]
    }
  }
}

import { yupResolver } from '@hookform/resolvers/yup'
import { BackButton } from '@momentum/components/back-button'
import { PhoneInput } from '@momentum/components/phone-input'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { getRoleOptions, ACCESS_LEVEL_OPTIONS } from '@momentum/utils/selectOptions'
import { Stack } from '@mui/material'
import { Role, AccessLevel } from '@productwindtom/shared-momentum-zeus-types'
import { Form, SelectInput, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { isValidPhoneNumber } from 'libphonenumber-js'
import * as yup from 'yup'
import { User, updateUser } from './queries'
import { useFlag } from '@unleash/proxy-client-react'

type UpdateUserFormData = {
  firstName: string
  lastName: string
  role: Role
  phoneNumber: string
  email: string
  primaryBrandId?: string
  // brandIds: string[]
  accessLevel: AccessLevel
}

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Required')
    .email('Invalid email')
    .transform(value => value?.toLowerCase()?.trim()),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  phoneNumber: yup
    .string()
    .transform(value => value?.trim())
    .test('phone', 'Invalid phone number', value => !value || isValidPhoneNumber(value))
    .transform(value => value?.replaceAll(/\s/g, '')),
  role: yup.string().nullable().optional(),
  primaryBrandId: yup.string().nullable().optional(),
  brandIds: yup.array().optional(), //.required('Required').min(1, 'Required')
  accessLevel: yup.string().required('Required')
})

export const UpdateUserForm = ({
  user,
  onCancel,
  onUpdated,
  isAgency
}: {
  user: User
  onCancel: () => void
  onUpdated: (user: User) => void
  isAgency: boolean
}) => {
  const { brands } = useUserSessionContext()
  const viewOnlyPermissionsFlag = useFlag('ViewOnlyPermissions')

  const handleSubmit = async (data: UpdateUserFormData) => {
    const updatedUser = await updateUser({
      id: user.id,
      firstName: data.firstName,
      lastName: data.lastName,
      role: data.role,
      primaryBrandId: data.primaryBrandId,
      accessLevel: data.accessLevel || (viewOnlyPermissionsFlag ? undefined : AccessLevel.FULL_ACCESS)
    })
    onUpdated(updatedUser)
  }

  return (
    <Form defaultValues={{ ...user }} onSubmit={handleSubmit} resolver={yupResolver(schema) as any}>
      <Stack spacing={2}>
        <Stack direction={'row'} spacing={2}>
          <TextInput primaryText={'First name'} name={'firstName'} fullWidth />
          <TextInput primaryText={'Last name'} name={'lastName'} fullWidth />
        </Stack>
        <TextInput primaryText={'Email'} name={'email'} disabled />
        <PhoneInput name={'phoneNumber'} primaryText={'Phone number'} defaultCountry={'US'} disabled />
        <SelectInput options={getRoleOptions(isAgency)} primaryText={'Role'} name={'role'} disableClearable />
        <SelectInput
          options={brands.map(b => ({ label: b.name, value: b.id }))}
          primaryText={'Primary brand'}
          name={'primaryBrandId'}
        />
        {viewOnlyPermissionsFlag && (
          <SelectInput
            data-cy={'accessLevelInput'}
            options={ACCESS_LEVEL_OPTIONS}
            primaryText={'Access level'}
            name={'accessLevel'}
            disableClearable
          />
        )}
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton text={'Cancel'} onClick={onCancel} />
          <SubmitButton variant={'contained'}>Submit</SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

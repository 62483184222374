import { DataGridPremium } from '@mui/x-data-grid-premium'
import { styled } from '@mui/material'

export const DataGrid = styled(DataGridPremium)(({ theme }) => ({
  'border': 'none',
  ...theme.typography.label3,

  '--DataGrid-rowBorderColor': '#C4C4C4',
  '& .MuiDataGrid-columnHeader': {
    color: 'black',
    fontWeight: 800
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    // borderBottom: '1px solid #C4C4C4',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 800
  },

  '& .MuiDataGrid-cell': {
    // borderBottom: '1px solid #C4C4C4',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },

  '& .MuiDataGrid-main': {
    border: 'none'
  },

  '& .MuiDataGrid-withBorderColor': {
    borderColor: '#C4C4C4'
  },

  '& .--wrap-cell': {
    whiteSpace: 'normal !important',
    wordWrap: 'break-word !important',
    display: 'inline-flex'
  },
  '& .--two-lines': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineBreak: '2',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
    // '-webkit-line-clamp': '2',
    // '-webkit-box-orient': 'vertical'
  }
})) as typeof DataGridPremium

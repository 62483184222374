import Row from '@momentum/components/row'
import { Search } from '@mui/icons-material'
import { RecommendationType, Store } from '@productwindtom/shared-momentum-zeus-types'
import { SelectInput, TextInput } from '@productwindtom/ui-base'
import { capitalize, keyBy, sortBy, words } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { RecommendationsFormData } from '../types'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { STORE_DETAILS } from '@momentum/utils/storeUtils'

const RecommendationsFilters = () => {
  const { companies, brands } = useUserSessionContext()
  const { setValue, getValues, watch } = useFormContext<RecommendationsFormData>()
  const { companyIds, brandIds, recommendationTypes } = watch()

  const brandsById = useMemo(() => keyBy(brands, 'id'), [brands])

  const availableBrands = companyIds?.length
    ? brands.filter(brand => brand.companyId && companyIds.includes(brand.companyId))
    : brands

  useEffect(() => {
    const selectedBrands = getValues('brandIds')
    const updatedValue = selectedBrands?.filter((id: string) => {
      const companyId = brandsById?.[id].companyId

      return companyId && companyIds.includes(companyId)
    })

    if (selectedBrands?.length && JSON.stringify(selectedBrands) !== JSON.stringify(updatedValue)) {
      setValue(
        'brandIds',
        selectedBrands?.filter((id: string) => {
          const companyId = brandsById[id].companyId

          return companyId && companyIds.includes(companyId)
        })
      )
    }
  }, [companyIds, setValue, getValues, brandsById])

  return (
    <Row spacing={2}>
      <TextInput
        fullWidth
        name="search"
        placeholder="Search recommendations"
        InputProps={{
          endAdornment: <Search />
        }}
      />
      <SelectInput
        name="retailers"
        placeholder={`Retailer`}
        options={[
          {
            value: Store.amazon,
            label: STORE_DETAILS[Store.amazon].name
          },
          {
            value: Store.walmart,
            label: STORE_DETAILS[Store.walmart].name
          }
        ]}
        fullWidth
      />
      <SelectInput
        name="recommendationTypes"
        placeholder={`Recommendation Type (${recommendationTypes?.length || 0} selected)`}
        options={[...Object.values(RecommendationType), 'ARCHIVED'].map(type => ({
          label: words(type).map(capitalize).join(' '),
          value: type
        }))}
        fullWidth
        multiple
        disableTags
      />
      <SelectInput
        name="companyIds"
        placeholder={`Companies (${companyIds?.length || 0} selected)`}
        options={sortBy(companies, 'name').map(company => ({
          label: company.name,
          value: company.id
        }))}
        fullWidth
        multiple
        disableTags
      />
      <SelectInput
        name="brandIds"
        placeholder={`Brands (${brandIds?.length || 0} selected)`}
        options={sortBy(availableBrands, 'name').map(brand => ({
          label: brand.name,
          value: brand.id
        }))}
        fullWidth
        multiple
        disableTags
      />
    </Row>
  )
}

export default RecommendationsFilters

import { mApi } from '@momentum/api'
import { Selector, InputType, GraphQLTypes, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'

const performanceRecordSelector = Selector('ProductPerformanceRecord')({
  date: true,
  reviewCount: true,
  rating: true,
  salesRank: true
})

const campaignPerformanceProductSelector = Selector('CampaignPerformanceProduct')({
  id: true,
  productName: true,
  productUrl: true,
  productImage: true,
  comparisonMetric: true,
  productPerformanceRecords: performanceRecordSelector,
  reviewDataUploaded: true,
  bestSellerRankDataUploaded: true,
  isVisible: true,
  isCategoryAverage: true,
  createdAt: true
})

const campaignPerformanceSelector = Selector('CampaignPerformance')({
  campaignId: true,
  skuId: true,
  promotedProductId: true,
  isVisible: true,
  isTtrVisible: true,
  isBsrVisible: true,
  isTtrDataUnavailable: true,
  isBsrDataUnavailable: true,
  comparisonMetric: true,
  comparisonMetricValue: true,
  summaryTitle: true
})

const campaignPerformanceSelectorWithProducts = Selector('CampaignPerformance')({
  ...campaignPerformanceSelector,
  products: campaignPerformanceProductSelector
})

export type CampaignPerformanceProduct = InputType<
  GraphQLTypes['CampaignPerformanceProduct'],
  typeof campaignPerformanceProductSelector
>
export type PerformanceRecord = InputType<GraphQLTypes['ProductPerformanceRecord'], typeof performanceRecordSelector>
export type CampaignPerformance = InputType<
  GraphQLTypes['CampaignPerformance'],
  typeof campaignPerformanceSelectorWithProducts
>

export type CampaignPerformanceResponse = InputType<
  GraphQLTypes['CampaignPerformance'],
  typeof campaignPerformanceSelector
>

export const getCampaignPerformance = async (campaignId: string, skuId: string) => {
  return (
    await mApi('query')({
      getCampaignPerformance: [{ campaignId, skuId }, campaignPerformanceSelectorWithProducts]
    })
  ).getCampaignPerformance
}

export const createCampaignPerformance = async (input: ValueTypes['ModelInputCreateCampaignPerformance']) => {
  return (
    await mApi('mutation')({
      createCampaignPerformance: [{ input }, campaignPerformanceSelector]
    })
  ).createCampaignPerformance
}

export const updateCampaignPerformance = async (input: ValueTypes['ModelInputUpdateCampaignPerformance']) => {
  return (
    await mApi('mutation')({
      updateCampaignPerformance: [{ input }, campaignPerformanceSelector]
    })
  ).updateCampaignPerformance
}

export const createCampaignPerformanceProduct = async (
  input: ValueTypes['ModelInputCreateCampaignPerformanceProduct']
) => {
  return (
    await mApi('mutation')({
      createCampaignPerformanceProduct: [{ input }, campaignPerformanceProductSelector]
    })
  ).createCampaignPerformanceProduct
}

export const updateCampaignPerformanceProduct = async (
  input: ValueTypes['ModelInputUpdateCampaignPerformanceProduct']
) => {
  return (
    await mApi('mutation')({
      updateCampaignPerformanceProduct: [{ input }, campaignPerformanceProductSelector]
    })
  ).updateCampaignPerformanceProduct
}

export const deleteCampaignPerformanceProduct = async (id: string) => {
  return (
    await mApi('mutation')({
      deleteCampaignPerformanceProduct: [{ input: { id } }, campaignPerformanceProductSelector]
    })
  ).deleteCampaignPerformanceProduct
}

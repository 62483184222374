import * as yup from 'yup'

export const urlPreTransform = (s?: string) => (s && !s.startsWith('https://') ? `https://${s}` : s)

export const amazonBrandStoreRegex = /\/stores\/page\/[A-Za-z0-9_-]+/
export const brandStoreIdRegex = /\/stores(?:\/[^/]+)?\/(?:Homepage\/)?(?:page\/)?([^/?]+)/
export const walmartBrandStoreRegex = /walmart.*/

export const getInvalidYupFields = (schema: yup.Schema, data: any) => {
  try {
    schema.validateSync(data, { abortEarly: false })
  } catch (err: any) {
    if (err instanceof yup.ValidationError) {
      const invalidFields = err.inner.map(err => err.path)
      console.log('Invalid fields', invalidFields)
    } else {
      console.log('err', err.message)
    }
  }
}

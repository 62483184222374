import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SlickSlider, { Settings } from 'react-slick'
import { Box } from '@mui/material'

const Slider = (props: Settings) => {
  return (
    <Box
      pb={5}
      sx={theme => ({
        '.slick-dots': {
          bottom: -40,
          li: {
            'margin': '0 2px',
            'button:before': {
              fontSize: 16,
              color: theme.palette.primary.main,
              transition: 'all ease-in-out 0.1s'
            },
            ':hover': {
              'button:before': {
                color: theme.palette.primary.light
              }
            },
            '&.slick-active': {
              'button:before': {
                color: theme.palette.primary.dark
              }
            }
          }
        }
      })}
    >
      <SlickSlider {...props} />
    </Box>
  )
}

export default Slider

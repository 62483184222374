import { ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import {
  StarBorder,
  RocketLaunchOutlined,
  ShoppingCartOutlined,
  PublishedWithChangesOutlined
} from '@mui/icons-material'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'
import Package from '@momentum/components/icons/package'
import SaleIcon from '@momentum/components/icons/sale'

export const CampaignGoalTypeToIcon: Record<ProposalGoal, (props?: SvgIconProps) => JSX.Element> = {
  PRODUCT_LAUNCH: (props?: SvgIconProps) => <RocketLaunchOutlined {...props} />,
  BOOST_RATING: (props?: SvgIconProps) => <StarBorder {...props} />,
  EXISTING_PRODUCT: (props?: SvgIconProps) => <Package {...props} />,
  EVERGREEN: (props?: SvgIconProps) => <PublishedWithChangesOutlined {...props} />,
  EVENT: (props?: SvgIconProps) => <SaleIcon {...props} />,
  OTHER: (props?: SvgIconProps) => <ShoppingCartOutlined {...props} />
}

import { PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'


export const CREATOR_TYPE_PRICING_CONFIG: Record<
  PricingCreatorType,
  {
    title: string
    lowerTitle?: string
    description: string
  }
> = {
  [PricingCreatorType.ADVOCATE]: {
    title: 'Brand Advocates',
    lowerTitle: 'brand advocates',
    description:
      'Brand Advocates are excited to test and share your product. These creators are the best way to generate SEO wins, traffic and conversions.'
  },
  [PricingCreatorType.UGC]: {
    title: 'UGC Creators',
    lowerTitle: 'UGC creators',
    description:
      'UGC creators love to write high-quality reviews. They create authentic content but do not publish it to social platforms.'
  },
  [PricingCreatorType.PREMIUM_UGC]: {
    title: 'Premium UGC Creators',
    lowerTitle: 'premium UGC creators',
    description:
      'Premium UGC creators are professional content creators who produce high-quality content. They do not publish content to social platforms.'
  },
  [PricingCreatorType.SOCIAL]: {
    title: 'Social Creators',
    lowerTitle: 'social creators',
    description:
      'Social creators are professional content creators who make and publish content to social platforms like Instagram and TikTok.'
  }
}

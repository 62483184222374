import { mApi } from '@momentum/api'
import { GraphQLTypes, InputType, Selector } from '@productwindtom/shared-momentum-zeus-types'

const productFeedbackSummarySelector = Selector('ProductFeedbackSummary')({
  status: true,
  failureReason: true,
  jsonDownloadUrl: true,
  updatedAt: true
})

export type ProductFeedbackSummary = InputType<
  GraphQLTypes['ProductFeedbackSummary'],
  typeof productFeedbackSummarySelector
>

export const getProductFeedbackSummary = async (campaignId: string) => {
  const { getProductFeedbackSummary } = await mApi('query')({
    getProductFeedbackSummary: [{ campaignId }, productFeedbackSummarySelector]
  })

  return getProductFeedbackSummary
}

export const generateFeedbackReport = async (campaignId: string) => {
  const { generateFeedbackReport } = await mApi('mutation')({
    generateFeedbackReport: [{ campaignId }, productFeedbackSummarySelector]
  })
  return generateFeedbackReport
}

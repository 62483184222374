import { Dialog, FormControlLabel, Radio, Stack, Typography, IconButton, Button, Chip, Box } from '@mui/material'
import { SelectInput, Form, TextInput, SubmitButton, FileInput } from '@productwindtom/ui-base'
import { useController, useFormContext } from 'react-hook-form'
import { Close } from '@mui/icons-material'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import {
  Channel,
  UploadRequestType,
  ContentType,
  ContentUploadType,
  ValueTypes
} from '@productwindtom/shared-momentum-zeus-types'
import { orderBy, without } from 'lodash'
import {
  ChannelToContentTypes,
  ContentTypeToSocialString,
  SocialChannelToString,
  YOUTUBE_SHORTS_REGEX,
  YOUTUBE_REGEX,
  TT_REGEX,
  IG_STORY_REGEX,
  IG_POST_REGEX
} from '@momentum/utils/socialUtils'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import { ObjectSchema } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useUploadFile from '@momentum/hooks/useUploadFile'
import { notEmpty } from '@productwindtom/shared-node'
import { uploadCreatorContent } from '@momentum/routes/campaign/queries'
import { toast } from 'react-toastify'
import { FileList } from './FileList'
import { ContentGroup } from '@momentum/routes/campaign/context/queries'

type FormData = {
  creator: string
  platform?: Channel
  contentType?: ContentType
  uploadType: ContentUploadType
  contentLink?: string
  creatorContent?: string
  creatorContentAssets?: File[]
}

const schema: ObjectSchema<FormData> = yup.object({
  creator: yup.string().required('Required'),
  platform: yup.string<Channel>().when('uploadType', {
    is: ContentUploadType.LINK,
    then: schema => schema.required('Required')
  }),
  contentType: yup.string<ContentType>().when('uploadType', {
    is: ContentUploadType.LINK,
    then: schema => schema.required('Required')
  }),
  uploadType: yup.string<ContentUploadType>().required('Required'),
  contentLink: yup.string().when(['uploadType', 'platform'], (values, schema) => {
    return values[0] !== ContentUploadType.LINK
      ? schema
      : schema
          .required('Required')
          .test(
            'testValidSocialMediaUrl',
            `The URL must be a valid ${SocialChannelToString[values[1] as Channel]} URL`,
            value => testUrl(value, values[1])
          )
  }),
  creatorContent: yup.string().when('uploadType', {
    is: ContentUploadType.FILE,
    then: schema => schema.required('Required')
  }),
  creatorContentAssets: yup.array<File>().when('uploadType', {
    is: ContentUploadType.FILE,
    then: schema => schema.required('Required').min(1)
  })
})

const testUrl = (url?: string, channel?: Channel) => {
  if (url && channel) {
    return channel === Channel.INSTAGRAM
      ? IG_POST_REGEX.test(url) || IG_STORY_REGEX.test(url)
      : channel === Channel.TIKTOK
        ? TT_REGEX.test(url)
        : channel === Channel.YOUTUBE
          ? YOUTUBE_REGEX.test(url) || YOUTUBE_SHORTS_REGEX.test(url)
          : false
  }
  return true
}

export const UploadAssetsDialog = ({
  open,
  onClose,
  onSubmitComplete
}: {
  open: boolean
  onClose: () => void
  onSubmitComplete: (updatedContent: ContentGroup) => void
}) => {
  const uploadFile = useUploadFile()

  const onSubmit = async (data: FormData) => {
    const input: ValueTypes['UploadCreatorContentInput'] = {
      uploadType: data.uploadType,
      channel: data.platform,
      contentType: data.contentType,
      assetKeys: [],
      contentLink: data.contentLink,
      socialContentId: data.creatorContent,
      userCampaignId: data.creator
    }

    if (data.uploadType === ContentUploadType.FILE) {
      //Upload files
      input.assetKeys = (
        await Promise.all(
          (data.creatorContentAssets || []).map((f, index) =>
            uploadFile(f, UploadRequestType.UPLOAD_SOCIAL_CONTENT, {
              name: `MEDIA_${index}`,
              postId: data.creatorContent
            })
          )
        )
      ).filter(notEmpty)
    }

    try {
      onSubmitComplete(await uploadCreatorContent(input))
    } catch (e) {
      toast(<Typography variant={'subtitle2'}>Failed to upload creator assets!</Typography>, { type: 'error' })
    }
  }

  return (
    <Dialog open={open} PaperProps={{ sx: { p: 3 } }}>
      <Stack spacing={2}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'h4'}>Add content</Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <Form resolver={yupResolver(schema)} onSubmit={onSubmit}>
          <Stack spacing={2}>
            <UploadAssetsFormBody />
            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
              <Button variant={'text'} onClick={onClose}>
                Cancel
              </Button>
              <SubmitButton variant={'contained'}>Add content</SubmitButton>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </Dialog>
  )
}

const UploadAssetsFormBody = () => {
  const { campaignDetails } = useCampaignContext()

  const mappedCreators = campaignDetails.creators.map(c => {
    const emptyContent = c.content
      .flatMap(c => c.content.map(cc => ({ contentType: c.contentType, submittedAt: c.submittedAt, ...cc })))
      .filter(c => !c.assets?.length)

    return {
      id: c.userCampaignId,
      emptyContent,
      label: `${c.creator.firstName} ${c.creator.lastName}${emptyContent.length ? ` (${emptyContent.length} assets missing)` : ''} `
    }
  })

  const { watch } = useFormContext()
  const {
    field: { onChange: onTypeChange, onBlur: onTypeBlur, value: uploadType }
  } = useController({ name: 'uploadType' })

  const platform: Channel | undefined = watch('platform')
  const creatorId: string | undefined = watch('creator')

  const creatorOptions = orderBy(mappedCreators, c => c.emptyContent.length, 'desc').map(c => ({
    value: c.id,
    label: c.label
  }))
  const blankSocialContent = mappedCreators.find(c => c.id === creatorId)?.emptyContent || []

  const socialContentOptions = blankSocialContent.map(c => ({
    value: c.id,
    label: `${ContentTypeToSocialString[c.contentType]} on ${DateTime.fromISO(c.submittedAt).toLocaleString(DateTime.DATE_MED)}`
  }))

  return (
    <Stack spacing={3}>
      <SelectInput name={'creator'} options={creatorOptions} primaryText={'Select creator'} />
      <SelectInput
        disabled={uploadType === ContentUploadType.FILE}
        name={'platform'}
        options={without(Object.values(Channel), Channel.UGC).map(c => ({ value: c, label: SocialChannelToString[c] }))}
        primaryText={'Select platform'}
      />
      <SelectInput
        disabled={uploadType === ContentUploadType.FILE}
        name={'contentType'}
        options={
          platform ? ChannelToContentTypes[platform].map(c => ({ value: c, label: ContentTypeToSocialString[c] })) : []
        }
        primaryText={'Select content type'}
      />
      <Stack>
        <FormControlLabel
          value={ContentUploadType.LINK}
          control={
            <Radio
              onBlur={onTypeBlur}
              onClick={() => onTypeChange(ContentUploadType.LINK)}
              checked={uploadType === ContentUploadType.LINK}
            />
          }
          label={
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography variant={'label3'}>Upload link to content</Typography>
              <Chip size={'small'} color={'primary'} label={'Recommended'} />
            </Stack>
          }
        />
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          Momentum will automatically download the asset and content metrics
        </Typography>
      </Stack>
      {uploadType === ContentUploadType.LINK && (
        <TextInput name={'contentLink'} primaryText={'Link to content'} placeholder={'Content URL'} />
      )}

      <Stack>
        <FormControlLabel
          value={ContentUploadType.FILE}
          control={
            <Radio
              onBlur={onTypeBlur}
              onClick={() => onTypeChange(ContentUploadType.FILE)}
              checked={uploadType === ContentUploadType.FILE}
            />
          }
          label={<Typography variant={'label3'}>Upload file</Typography>}
        />
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          Momentum will NOT be able to download any content metrics.
        </Typography>
      </Stack>
      {uploadType === ContentUploadType.FILE && (
        <SelectInput
          name={'creatorContent'}
          options={socialContentOptions}
          primaryText={'Select matching piece of content'}
        />
      )}
      {uploadType === ContentUploadType.FILE && (
        <Box>
          <FileInput
            buttonText={'Attach files'}
            name={'creatorContentAssets'}
            primaryText={'Select matching piece of content'}
            accept={'video/mp4,image/jpeg'}
            multiple
          />
        </Box>
      )}
      {uploadType === ContentUploadType.FILE && <FileList />}
    </Stack>
  )
}

import { Stack, Typography } from '@mui/material'
import BenchmarkProvider from './context/BenchmarkContext'
import BenchmarkProducts from './components/BenchmarkProducts'
import BenchmarkMetrics from './components/BenchmarkMetrics'
import BenchmarkGraph from './components/BenchmarkGraph'

const Benchmark = () => {
  return (
    <BenchmarkProvider>
      <Stack spacing={2}>
        <Typography variant="label1">Compare speed to product launch</Typography>
        <Typography variant="label3">Add up to 3 products</Typography>
        <BenchmarkProducts />
        <BenchmarkMetrics />
        <BenchmarkGraph />
      </Stack>
    </BenchmarkProvider>
  )
}

export default Benchmark

import {
  GraphQLTypes,
  Selector,
  InputType,
  UserInvitationStatus,
  ValueTypes
} from '@productwindtom/shared-momentum-zeus-types'
import { mApi } from '@momentum/api'

const invitationSelector = Selector('UserInvitation')({
  id: true,
  agencyId: true,
  companyId: true,
  brandIds: true,
  status: true,
  email: true,
  isAllBrandAccess: true,
  primaryBrandId: true,
  accessLevel: true,
  phoneNumber: true,
  firstName: true,
  lastName: true,
  role: true,
  createdAt: true
})

export type Invitation = InputType<GraphQLTypes['UserInvitation'], typeof invitationSelector>

export const listInvitationsByCompany = async (companyId: string, nextToken?: string): Promise<Invitation[]> => {
  const resp = await mApi('query')({
    listInvitationsByCompany: [
      { companyId, status: { eq: UserInvitationStatus.PENDING }, nextToken },
      {
        items: invitationSelector,
        nextToken: true
      }
    ]
  })

  if (resp?.listInvitationsByCompany?.nextToken) {
    return [
      ...resp.listInvitationsByCompany.items,
      ...(await listInvitationsByCompany(companyId, resp.listInvitationsByCompany.nextToken))
    ]
  }

  return resp.listInvitationsByCompany.items
}

export const listInvitationsByAgency = async (agencyId: string, nextToken?: string): Promise<Invitation[]> => {
  const resp = await mApi('query')({
    listInvitationsByAgency: [
      { agencyId, status: { eq: UserInvitationStatus.PENDING }, nextToken },
      {
        items: invitationSelector,
        nextToken: true
      }
    ]
  })

  if (resp?.listInvitationsByAgency?.nextToken) {
    return [
      ...resp.listInvitationsByAgency.items,
      ...(await listInvitationsByAgency(agencyId, resp.listInvitationsByAgency.nextToken))
    ]
  }

  return resp.listInvitationsByAgency.items
}

const userSelector = Selector('User')({
  id: true,
  email: true,
  firstName: true,
  lastName: true,
  role: true,
  phoneNumber: true,
  status: true,
  primaryBrandId: true,
  accessLevel: true,
  lastActiveAt: true
})

const userCompanySelector = Selector('UserCompany')({
  companyId: true,
  isAllBrandAccess: true,
  userId: true,
  userBrands: {
    brandId: true
  },
  user: userSelector
})

const userAgencySelector = Selector('UserAgency')({
  userId: true,
  user: userSelector
})

export type User = InputType<GraphQLTypes['User'], typeof userSelector>
export type UserCompany = InputType<GraphQLTypes['UserCompany'], typeof userCompanySelector>
export type UserAgency = InputType<GraphQLTypes['UserAgency'], typeof userAgencySelector>

export const listUserCompanies = async (companyId: string, nextToken?: string): Promise<UserCompany[]> => {
  const { listUserCompaniesByCompanyId: lu } = await mApi('query')({
    listUserCompaniesByCompanyId: [
      { companyId, nextToken },
      {
        items: userCompanySelector,
        nextToken: true
      }
    ]
  })

  if (lu?.nextToken) {
    return [...lu.items, ...(await listUserCompanies(lu.nextToken))]
  }
  return lu.items || []
}

export const listUserAgencies = async (agencyId: string, nextToken?: string): Promise<UserAgency[]> => {
  const { listUserAgenciesByAgencyId: lu } = await mApi('query')({
    listUserAgenciesByAgencyId: [
      { agencyId, nextToken },
      {
        items: userAgencySelector,
        nextToken: true
      }
    ]
  })

  if (lu?.nextToken) {
    return [...lu.items, ...(await listUserAgencies(lu.nextToken))]
  }

  return lu.items || []
}

export const getUser = async (id: string) => {
  const { getUser } = await mApi('query')({
    getUser: [
      {
        id
      },
      userSelector
    ]
  })

  return getUser
}

export const updateUser = async (input: ValueTypes['ModelInputUpdateUser']) => {
  const { updateUser } = await mApi('mutation')({
    updateUser: [
      {
        input
      },
      userSelector
    ]
  })

  return updateUser
}

export const inviteUser = async (input: ValueTypes['UserInvitationInput']) => {
  const { inviteUserToMomentum } = await mApi('mutation')({
    inviteUserToMomentum: [
      {
        input
      },
      invitationSelector
    ]
  })

  return inviteUserToMomentum
}

export const updateInvitation = async (input: ValueTypes['ModelInputUpdateUserInvitation']) => {
  const { updateUserInvitation } = await mApi('mutation')({
    updateUserInvitation: [
      {
        input
      },
      invitationSelector
    ]
  })

  return updateUserInvitation
}

export const deleteInvitation = async (id: string) => {
  const { deleteUserInvitation } = await mApi('mutation')({
    deleteUserInvitation: [
      {
        input: { id }
      },
      invitationSelector
    ]
  })

  return deleteUserInvitation
}

export const deactivateUser = async (id: string) => {
  const { deactivateUser } = await mApi('mutation')({
    deactivateUser: [
      {
        id
      },
      userSelector
    ]
  })

  return deactivateUser
}

export const reactivateUser = async (id: string) => {
  const { reactivateUser } = await mApi('mutation')({
    reactivateUser: [
      {
        id
      },
      userSelector
    ]
  })

  return reactivateUser
}

import {
  ProposalGoal,
  Store,
  CampaignCreatorStatus,
  CreatorType,
  ContentType,
  Channel
} from '@productwindtom/shared-momentum-zeus-types'
import { CampaignDetails } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_WIRELESS_EARBUDS: CampaignDetails = {
  id: 'sample-campaign-wireless-earbuds',
  createdAt: '2024-05-09T23:19:35.995Z',
  skuId: 'sample-sku-wireless-earbuds',

  title: 'Wireless Earbuds - New Product Launch - SEO/Sales + Reviews/Ratings',
  goal: ProposalGoal.PRODUCT_LAUNCH,
  numCreators: 200,
  numCreatorsJoined: 201,

  numBrandAdvocateCreators: 100,
  numSocialCreators: 0,
  numPremiumUgcCreators: 0,
  numUgcCreators: 100,

  expectedUgcPostCount: 100,
  expectedReviewsCount: 50,
  expectedPlacementWins: 5,

  releaseSchedule: {
    ugcWeeklySchedule: [44, 43, 6, 1, 2, 1, 0, 5],
    premiumUgcWeeklySchedule: [],
    socialWeeklySchedule: [],
    brandAdvocatesWeeklySchedule: [0, 15, 48, 37]
  },
  anticipatedStartDate: '2024-06-10T04:00:00.000Z',
  startDate: '2024-06-10T22:56:19.515Z',
  endDate: '2024-08-10T15:00:00.000Z',
  cost: 2999800,
  product: {
    id: 'sample-sku-wireless-earbuds',
    name: 'Wireless Earbuds',
    skuId: 'WEARBUDS',
    priceCents: 14999,
    image: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds.png',
    listingLink: 'https://www.amazon.com',
    store: Store.amazon,
    availabilityDate: '2024-05-31T00:00:00.000Z'
  },
  creators: [
    {
      userCampaignId: '5a6e20be-b1de-403e-89f3-5d90500b2cb0',
      content: [],
      creator: {
        id: 'c85f3cb2-b393-47bb-afd2-11663145248e',
        firstName: 'Tara',
        lastName: 'Smith',
        email: 'tarams78@gmail.com',
        instagramProfile: {
          handle: 'reviews.by.tara',
          followers: 0,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'tarabertone6',
          followers: 2
        },
        youtubeProfile: {
          handle: 'tarabertone2587',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T17:54:13.000Z',
        amountCents: 15899
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '804ed926-63a3-469d-a352-25a06ca90c4a',
      content: [],
      creator: {
        id: '89de6eee-4488-49eb-885f-e16191a33a67',
        firstName: 'Erlia',
        lastName: 'Bonanza',
        email: 'bonanzaerlia@gmail.com',

        tiktokProfile: {
          handle: 'grendyg',
          followers: 2322,
          engagementRate: 13.067948293006298
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T18:45:39.000Z',
        amountCents: 16236
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a9d225cb-0b56-4d1f-8692-6d84336a1881',
      content: [],
      creator: {
        id: 'bd21949b-c7a9-4473-a652-d05996e1da08',
        firstName: 'Raymond',
        lastName: 'Anacaya',
        email: 'stev_jang@yahoo.com',
        instagramProfile: {
          handle: 'raym0nd3o',
          followers: 171,
          engagementRate: 1.1764705882352942
        },
        tiktokProfile: {
          handle: 'jnl.rmlcio',
          followers: 15350,
          engagementRate: 5.012531328320802
        },
        youtubeProfile: {
          handle: 'stevjang1',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T19:14:06.000Z',
        amountCents: 16199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '13909f37-184f-48dc-8972-eeded46b4bdc',
      content: [],
      creator: {
        id: '761c6256-ad1d-4a46-9846-e5be634e7f38',
        firstName: 'Glizzie',
        lastName: 'McGuire',
        email: 'glizziemcguire2@gmail.com',
        instagramProfile: {
          handle: 'glizzieemcguire',
          followers: 54,
          engagementRate: 3.6734693877551026
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T20:13:14.000Z',
        amountCents: 15993
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1c0f8abc-6bf9-4839-9d1e-929adea9828d',
      content: [],
      creator: {
        id: '86f2e8ed-592e-4c18-bff4-8d91d8dfae8c',
        firstName: 'Vio',
        lastName: 'Sych',
        email: 'villison990@gmail.com',
        instagramProfile: {
          handle: 'sychevskaia.marina',
          followers: 1618,
          engagementRate: 4.243922538112897
        },
        tiktokProfile: {
          handle: 'redpantera',
          followers: 1839,
          engagementRate: 10.72961373390558
        },
        youtubeProfile: {
          handle: 'vioson-kz6sd',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T03:56:48.000Z',
        amountCents: 17550
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '587957c3-39c8-40c8-a218-fdb841d6f898',
      content: [],
      creator: {
        id: 'f938b41b-5fe7-4007-bfc1-dc72157b8fcf',
        firstName: 'Radin',
        lastName: 'Galy',
        email: 'viosych777@gmail.com',
        instagramProfile: {
          handle: 'radin_galy',
          followers: 0,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'oliiivka26',
          followers: 122
        },
        youtubeProfile: {
          handle: 'itsoftware-h1d',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T04:06:30.000Z',
        amountCents: 17550
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '85c3a635-4f35-405c-962d-b6b06d234e33',
      content: [],
      creator: {
        id: '01a5129a-db0c-441a-b3e4-ef033f486a85',
        firstName: 'Mohammad',
        lastName: 'Fakhimnia',
        email: '123456789qazwsxedcrfv1@gmail.com',
        instagramProfile: {
          handle: 'blog_flex_mf',
          followers: 342,
          engagementRate: 6.578947368421052
        },
        tiktokProfile: {
          handle: 'mleaon',
          followers: 0
        },
        youtubeProfile: {
          handle: 'marfa-hs4xm',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T04:06:36.000Z',
        amountCents: 17550
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3e0d6ded-105b-4a08-86e6-ae68ba00fcce',
      content: [],
      creator: {
        id: 'd9d12999-286a-4c59-a0f5-2a426860f05a',
        firstName: 'Edvard',
        lastName: 'Taroyan',
        email: 'neldiakrka@gmail.com',
        instagramProfile: {
          handle: 'sarahblascko2',
          followers: 0
        },
        tiktokProfile: {
          handle: 'edvarpyndu6',
          followers: 4
        },
        youtubeProfile: {
          handle: 'gamene-oj3zs',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T04:33:03.000Z',
        amountCents: 15426
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd4b660fc-928c-485a-9917-20b141118e7a',
      content: [],
      creator: {
        id: '6bdb4185-d787-4928-a805-3a701e3c17b3',
        firstName: 'Czarina',
        lastName: 'Tagbak',
        email: 'czarinatagbak123@gmail.com',

        tiktokProfile: {
          handle: 'chachaching_24',
          followers: 74
        },
        youtubeProfile: {
          handle: 'czarinatagbak9527',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T13:35:57.000Z',
        amountCents: 17235
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0294296b-7c6b-4095-998d-9ea68cb7fd30',
      content: [],
      creator: {
        id: '0da3d3ef-3f88-4f75-9fcd-47a530684007',
        firstName: 'Sean',
        lastName: 'Kay',
        email: 'sean.kay777@gmail.com',

        tiktokProfile: {
          handle: 'seankay777',
          followers: 44
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T16:16:03.000Z',
        amountCents: 17550
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '903b6745-9d94-4548-b881-313ccfa463bb',
      content: [],
      creator: {
        id: '44a1f23a-0e88-4580-ad4f-43757b6ae27f',
        firstName: 'Marina',
        lastName: 'Goly',
        email: 'marina0308@mail.ru',

        tiktokProfile: {
          handle: 'user1079824523519',
          followers: 9
        },
        youtubeProfile: {
          handle: 'user-rh8fh6lh3n',
          followers: 267
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T17:31:49.000Z',
        amountCents: 17550
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'edacc8b1-d965-439c-ae91-a79e492ce446',
      content: [],
      creator: {
        id: '5599a7fe-8ac0-4bdd-b2f7-6dcbc62f2e13',
        firstName: 'Shafagh',
        lastName: 'Son',
        email: 'sichyar@gmail.com',

        tiktokProfile: {
          handle: 'user6842391455010',
          followers: 1
        },
        youtubeProfile: {
          handle: 'sahar19821',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T17:53:15.000Z',
        amountCents: 17550
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd13c7ff9-a6cc-4722-a764-9d5bfc24bb7e',
      content: [],
      creator: {
        id: 'd66546d3-cd89-4a7d-b7d4-9b1afa1c2c97',
        firstName: 'Leneisha',
        lastName: 'Gayle',
        email: 'alexdbrown718@gmail.com',

        tiktokProfile: {
          handle: 'alvinbrady426',
          followers: 3
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T19:11:09.000Z',
        amountCents: 20865
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e7503bdc-62aa-4dad-882c-93ca147d2dd3',
      content: [],
      creator: {
        id: '10a2e2a0-2342-40a0-accd-2cf8723266c3',
        firstName: 'Reyna Jane',
        lastName: 'Cotcot',
        email: 'najencotcot@gmail.com',

        tiktokProfile: {
          handle: 'najenxxpochi',
          followers: 32
        },
        youtubeProfile: {
          handle: 'reynajanecotcot933',
          followers: 3
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T19:14:05.000Z',
        amountCents: 16199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ee026466-14f2-4ee7-ab07-e4fec785e1f7',
      content: [],
      creator: {
        id: 'fb4ebf89-5d71-4297-a5ac-6ce25dfab804',
        firstName: 'Ryan',
        lastName: 'Bertone',
        email: 'ryanbertone@icloud.com',
        instagramProfile: {
          handle: 'ryans.reviews',
          followers: 4,
          engagementRate: 125
        },
        tiktokProfile: {
          handle: 'thereaperofhell444555666',
          followers: 1490,
          engagementRate: 6.777777777777777
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-23T21:24:17.000Z',
        amountCents: 15899
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5723ed54-ca4d-4321-adca-edd9bd73c5ff',
      content: [],
      creator: {
        id: '9fc0cea0-5b0b-401e-a044-898157921d0a',
        firstName: 'Najen',
        lastName: 'Cala',
        email: 'najencala1@gmail.com',

        tiktokProfile: {
          handle: 'najen.cala',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T00:15:12.000Z',
        amountCents: 16199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'adeb6c57-8af0-4922-906b-e45f11aa4d60',
      content: [],
      creator: {
        id: '8129e128-4ef7-40b4-b435-f020aaf62e61',
        firstName: 'Ricky',
        lastName: 'Susanto',
        email: 'mike.oneng1974@gmail.com',

        tiktokProfile: {
          handle: 'gen.yuna',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T02:55:57.000Z',
        amountCents: 16199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5ec3676e-858d-446d-90e3-2d333e46444b',
      content: [],
      creator: {
        id: '2b01df8b-3b55-4e23-a1d7-65068e895986',
        firstName: 'Angelina',
        lastName: 'Susantyo',
        email: 'eddy.susantyo1955@gmail.com',

        tiktokProfile: {
          handle: 'susantyo.e',
          followers: 1
        },
        youtubeProfile: {
          handle: 'eddysusantyo',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T02:56:02.000Z',
        amountCents: 16199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '396ce9ee-ace0-4103-885c-19ecec0c11ab',
      content: [],
      creator: {
        id: '4ae8defc-d307-4c4f-9ea6-c6919c4be92e',
        firstName: 'Karla Cezaren',
        lastName: 'Alejo',
        email: 'raven3008@icloud.com',

        tiktokProfile: {
          handle: 'raven.stend',
          followers: 0,
          engagementRate: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T03:25:47.000Z',
        amountCents: 16199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'cace1b2b-3b9e-4dc9-b6fd-4e1bdb313666',
      content: [],
      creator: {
        id: 'd0f17e8e-4562-4ba5-b487-9114dae9e435',
        firstName: 'Jasmin',
        lastName: 'Bonghanoy',
        email: 'bonghanoyjasmin@gmail.com',

        tiktokProfile: {
          handle: '_imjaaas',
          followers: 17,
          engagementRate: 38.46153846153847
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T06:06:23.000Z',
        amountCents: 16954
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f978d0b1-2754-43bc-95e3-d1e461143a65',
      content: [],
      creator: {
        id: '1bcc7557-4cf6-42b6-a008-744ed9ca36da',
        firstName: 'Tara',
        lastName: 'Smith',
        email: 'diva-patrol.0v@icloud.com',

        tiktokProfile: {
          handle: 'tarabertone',
          followers: 0
        },
        youtubeProfile: {
          handle: 'tm-reviews',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T08:14:50.000Z',
        amountCents: 15597
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'f7cbe565-44c5-4a9a-8e74-5ec6471deb49',
      content: [],
      creator: {
        id: '20fe1603-a882-4270-92df-66f9d54219f8',
        firstName: 'Jay',
        lastName: 'Martha',
        email: 'marthaheadley114@gmail.com',

        tiktokProfile: {
          handle: 'mark.martha',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T12:44:23.000Z',
        amountCents: 21828
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7b00a4fd-321a-4955-a028-713c7b797e74',
      content: [],
      creator: {
        id: '7ff701d2-323f-4750-8f75-c9ac6515b233',
        firstName: 'Tara',
        lastName: 'Mary',
        email: 'beep08.acrylic@icloud.com',
        instagramProfile: {
          handle: 'product.reviews.by.tm',
          followers: 0,
          engagementRate: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T13:22:01.000Z',
        amountCents: 15597
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7d7f4682-06da-455d-ab73-4f5e000b4dfe',
      content: [],
      creator: {
        id: 'b864bf5f-878d-49b2-a48b-4d8dddd04b0f',
        firstName: 'JP',
        lastName: 'Bradley',
        email: 'johnpaulbradley0144@icloud.com',
        instagramProfile: {
          handle: 'product.reviews.by.john',
          followers: 0,
          engagementRate: 0
        },

        youtubeProfile: {
          handle: 'jpb9133',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:10:33.000Z',
        amountCents: 11998
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '385d6984-290b-49ac-b01f-ecc245196861',
      content: [],
      creator: {
        id: '214c7b24-e3b7-46bd-b446-3e5196183d93',
        firstName: 'Nia',
        lastName: 'McKenzie',
        email: 'nia_mckenzie@yahoo.com',
        instagramProfile: {
          handle: 'datsadmadgyal',
          followers: 625
        },
        tiktokProfile: {
          handle: 'dannymack4',
          followers: 219
        },
        youtubeProfile: {
          handle: 'niamckenzie3663',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:11:06.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e7ecdd6a-644d-4ff3-8c85-1785344bdd21',
      content: [],
      creator: {
        id: 'f42ab824-1fe0-47f5-8822-2acdc2680ab3',
        firstName: 'Cedella',
        lastName: 'Reid',
        email: 'cedellareid17@gmail.com',
        instagramProfile: {
          handle: 'cedella_reidd',
          followers: 672
        },
        tiktokProfile: {
          handle: 'theareid0',
          followers: 258,
          engagementRate: 6.0037523452157595
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T17:01:43.000Z',
        amountCents: 17555
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4fe7330e-be9f-4484-9800-becb85234670',
      content: [],
      creator: {
        id: 'ebaf1eac-dd3b-4ede-a06a-3cc25fd63423',
        firstName: 'Dee',
        lastName: 'Ophi',
        email: 'opheliadent8@gmail.com',

        tiktokProfile: {
          handle: 'd_contentcreator',
          followers: 0
        },
        youtubeProfile: {
          handle: 'reydanii-gk4xc',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T17:06:42.000Z',
        amountCents: 19153
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c1635b6e-aaa4-485a-98b7-5174244bcfc7',
      content: [],
      creator: {
        id: 'debe8d83-111a-477a-94e0-69bf372a9fe4',
        firstName: 'Monina',
        lastName: 'Anacaya',
        email: 'genshuna1@gmail.com',

        tiktokProfile: {
          handle: 'gen.shuna',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T17:55:38.000Z',
        amountCents: 16011
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4f16313e-fbde-4582-b9be-040ef312e98d',
      content: [],
      creator: {
        id: '5f1ec365-3c2f-4ec6-ac0b-adc8522dc9fe',
        firstName: 'Britony',
        lastName: 'Whyte',
        email: 'tineytw5@gmail.com',

        tiktokProfile: {
          handle: 'brit_ony',
          followers: 283
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T22:53:11.000Z',
        amountCents: 21806
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fde3b8bf-f2a0-45e4-a297-b25d5980ebdd',
      content: [],
      creator: {
        id: '5ca17ff4-9358-4df7-a2cd-a3916edcc164',
        firstName: 'Daina',
        lastName: 'Hickling',
        email: 'tara61800@gmail.com',

        tiktokProfile: {
          handle: 'if.u.know4',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T00:23:18.000Z',
        amountCents: 278
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fd0041e0-70b2-4c71-9c29-2c8b92acf682',
      content: [],
      creator: {
        id: '21093de4-d97f-4fd7-8806-fd8c10900403',
        firstName: 'Brigett',
        lastName: 'Biggs',
        email: 'brigettbiggs8@gmail.com',
        instagramProfile: {
          handle: 'marijuette',
          followers: 97
        },
        tiktokProfile: {
          handle: 'brxgett',
          followers: 333,
          engagementRate: 11.528822055137844
        },
        youtubeProfile: {
          handle: 'brigettbiggs',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T01:46:30.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5ea6f583-18d3-486e-bc74-eb83f5f0b082',
      content: [],
      creator: {
        id: '5d30bfd5-d4e7-4a9d-b2c5-5c6e6158947d',
        firstName: 'Krischelle Ann',
        lastName: 'Dimaranan',
        email: 'krissy.kad@gmail.com',

        tiktokProfile: {
          handle: 'itskrissyd',
          followers: 150
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T07:16:17.000Z',
        amountCents: 16954
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3889913f-180a-451e-b7a1-b6a00288c878',
      content: [],
      creator: {
        id: 'cf71926a-1d80-4894-a811-ab5958ce19a4',
        firstName: 'Mel',
        lastName: 'Bertone',
        email: 'junktab2023@gmail.com',
        instagramProfile: {
          handle: 'melsmentions',
          followers: 0,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'melsmentions',
          followers: 0
        },
        youtubeProfile: {
          handle: 'mel-mentions',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T09:36:17.000Z',
        amountCents: 15899
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a1851e73-29a4-4f85-ad8a-83d8155aab34',
      content: [],
      creator: {
        id: 'cdc2d6bc-2ca2-450c-a77f-b76692cc2a38',
        firstName: 'Jas',
        lastName: 'Ponce',
        email: 'jasmin.bonghanoy@deped.gov.ph',

        tiktokProfile: {
          handle: 'jasminbonghanoy',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T17:35:39.000Z',
        amountCents: 16954
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ff905de1-c185-4f37-87ac-ac3b42d54717',
      content: [],
      creator: {
        id: 'd91820f0-474f-463b-a837-ee178ed3f019',
        firstName: 'Janeth Ann',
        lastName: 'Rotaquio',
        email: 'rjanethann@gmail.com',

        tiktokProfile: {
          handle: 'janethxxii',
          followers: 103
        },
        youtubeProfile: {
          handle: 'janethannrotaquio9331',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T17:36:29.000Z',
        amountCents: 16954
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'fd053ef8-349b-4e19-8b24-cd3db467e24a',
      content: [],
      creator: {
        id: '2eaec267-96e9-4639-93b9-50f8a9c7d5f0',
        firstName: 'Kaye',
        lastName: 'Diamond',
        email: 'kaye.diamond@yahoo.com',

        tiktokProfile: {
          handle: 'kaye4361',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T18:42:28.000Z',
        amountCents: 12291
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'cd11412d-9a63-4b0f-8cbf-a0fb1725bbed',
      content: [],
      creator: {
        id: 'c9f6c768-2f42-4a27-a1c7-3de52d877d2e',
        firstName: 'Headley',
        lastName: 'Martha',
        email: 'janicemartha18@yahoo.com',

        tiktokProfile: {
          handle: 'one1_martha',
          followers: 1417
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T02:09:11.000Z',
        amountCents: 22400
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b2fa4ef9-27dc-4256-ac95-738122b0c747',
      content: [],
      creator: {
        id: '59131d16-d346-4fa3-864f-71d10c69efcd',
        firstName: 'Jenz',
        lastName: 'Den',
        email: 'jennyclarke303@yahoo.com',

        tiktokProfile: {
          handle: 'dee36027',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T14:22:18.000Z',
        amountCents: 24075
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e1ddf0af-cdc6-432a-8c42-a4b6ef0ecd34',
      content: [],
      creator: {
        id: 'ad1178c8-f358-48e5-9f2d-3317457cb553',
        firstName: 'Denz',
        lastName: 'Denton',
        email: 'denzdenton@yahoo.com',

        tiktokProfile: {
          handle: 'denx8996',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T17:18:04.000Z',
        amountCents: 21186
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0daf7e95-14cc-498e-8d92-08df3dbe7f88',
      content: [],
      creator: {
        id: 'c7a61b07-424b-4fbf-9d7a-ea7031f3dd26',
        firstName: 'Eherson',
        lastName: 'Kong',
        email: 'ehersonkong@gmail.com',

        tiktokProfile: {
          handle: 'ehersonkong',
          followers: 10694,
          engagementRate: 5.88235294117647
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T19:25:02.000Z',
        amountCents: 16954
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '64a3e93d-74c2-49bd-bc52-85109467cbd7',
      content: [],
      creator: {
        id: '74f3e7d4-f899-4886-9e53-7b5bcee76f00',
        firstName: 'Kim',
        lastName: 'Lee',
        email: 'lexi70369@gmail.com',

        tiktokProfile: {
          handle: 'low6178',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T22:44:07.000Z',
        amountCents: 22096
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd0581567-76b3-4ca4-8e1f-f994ccbfef0c',
      content: [],
      creator: {
        id: 'cedd52da-e50a-4ff7-83a1-1a688c61fb42',
        firstName: 'Tara',
        lastName: 'Bertone',
        email: 'tara122678@icloud.com',
        instagramProfile: {
          handle: 'mstara143',
          followers: 1014,
          engagementRate: 13.461538461538462
        },
        tiktokProfile: {
          handle: 'mstara143',
          followers: 5
        },
        youtubeProfile: {
          handle: 'tarabertone3329',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T00:01:37.000Z',
        amountCents: 15895
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3da2fc9c-e5cb-4716-8c55-4632f6c5043b',
      content: [],
      creator: {
        id: '8cf4e7c7-38e2-4c52-b3f7-71fea3d601d1',
        firstName: 'Denz',
        lastName: 'Gold',
        email: 'dendd26d@gmail.com',

        tiktokProfile: {
          handle: 'goldend73',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T00:08:30.000Z',
        amountCents: 21186
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a958daa8-c891-4a0b-8c59-eef386421121',
      content: [],
      creator: {
        id: 'be5b36a0-f6f7-40ac-9a7d-b23eb58fec79',
        firstName: 'Eva',
        lastName: 'Eva',
        email: 'jmartha1998@yahoo.com',

        tiktokProfile: {
          handle: 'jj522430',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T04:11:58.000Z',
        amountCents: 21186
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3b61feeb-7989-4892-9774-8e0b29cbe8ad',
      content: [],
      creator: {
        id: 'ef8d6306-be43-4821-b8b1-4176afbf01cd',
        firstName: 'Brit',
        lastName: 'Marz',
        email: 'britmarz@yahoo.com',

        tiktokProfile: {
          handle: '.mary7288',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T12:44:09.000Z',
        amountCents: 21885
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '034fd8fd-4004-440d-8498-238e71c15ebe',
      content: [],
      creator: {
        id: '91db2665-0758-4613-adc9-0643e5b51bf0',
        firstName: 'Britony',
        lastName: 'Whyte',
        email: 'britonywhyte169@gmail.com',

        tiktokProfile: {
          handle: 'bhaddie_15',
          followers: 4
        },
        youtubeProfile: {
          handle: 'britonywhyte6636',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T15:53:26.000Z',
        amountCents: 20000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0e3a3270-138c-4941-bdf2-21245a3f03b6',
      content: [],
      creator: {
        id: 'b7aa6cc0-eb91-4b58-bc60-f501b6331039',
        firstName: 'Donadine',
        lastName: 'Barrett',
        email: 'donadinebarrett@gmail.com',

        tiktokProfile: {
          handle: 'ishanz_ibee',
          followers: 5183,
          engagementRate: 16.333333333333332
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T17:37:18.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7da200a6-70c0-4e8a-ac8c-8e82c1b928a7',
      content: [],
      creator: {
        id: '2ef2ecba-15c9-44a1-949b-3c0a9ea1faac',
        firstName: 'Fatima',
        lastName: 'Inot',
        email: 'fatimainot.teachabroad@gmail.com',
        instagramProfile: {
          handle: 'fottimaaay',
          followers: 1010,
          engagementRate: 2.4533856722276743
        },
        tiktokProfile: {
          handle: 'fatimainot',
          followers: 53
        },
        youtubeProfile: {
          handle: 'fatimainot1222',
          followers: 95
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T18:06:29.000Z',
        amountCents: 16954
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7f2751d6-3590-467e-8807-c5df95965002',
      content: [],
      creator: {
        id: '2225fa5f-29df-4365-bb47-eb504aad5138',
        firstName: 'Denise',
        lastName: 'Smith',
        email: 'zyron5156@gmail.com',

        tiktokProfile: {
          handle: 'dendeninfluence',
          followers: 0
        },
        youtubeProfile: {
          handle: 'zyron-tk5gs',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T19:15:11.000Z',
        amountCents: 6740
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '46ea183a-4326-4749-8b46-5f4696b3c7da',
      content: [],
      creator: {
        id: '29aa0a12-11f7-42cb-89c9-e811e19ec715',
        firstName: 'Joebert',
        lastName: 'Rotaquio',
        email: 'jecelrotaquio0411@gmail.com',

        tiktokProfile: {
          handle: 'jc8j8',
          followers: 5
        },
        youtubeProfile: {
          handle: 'jc-jl7cr',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T20:24:10.000Z',
        amountCents: 16954
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '83edbc17-a420-441e-b084-01bece0d6c66',
      content: [],
      creator: {
        id: 'b09806d6-5afc-43d6-bce6-26acb6ec3078',
        firstName: 'Tara',
        lastName: 'Ertone',
        email: 'limes_bowler0a@icloud.com',

        tiktokProfile: {
          handle: 'malmom07',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T20:33:02.000Z',
        amountCents: 12715
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '11fa21a0-58b9-4508-b51e-f79e79aed94d',
      content: [],
      creator: {
        id: '1f334685-341e-40c0-bfc0-469d7ac2a81c',
        firstName: 'Daina',
        lastName: 'Hickling',
        email: 'dhic9258@flhs.us',
        instagramProfile: {
          handle: 'neisha__xox',
          followers: 1384
        },
        tiktokProfile: {
          handle: 'leneishagayle0',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T21:56:31.000Z',
        amountCents: 13722
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ded14eb6-6f38-4b7a-995c-1ddb95b8d581',
      content: [],
      creator: {
        id: 'e03ed731-128f-4bb2-bb66-4238f6bcd997',
        firstName: 'Alfien',
        lastName: 'Divinagracia',
        email: 'alfienscloset@gmail.com',

        tiktokProfile: {
          handle: 'alfien223',
          followers: 60,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'alfiendivinagracia9293',
          followers: 15
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T22:25:39.000Z',
        amountCents: 17564
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ff72e0a4-7be1-4bcf-9ac8-9b297441956f',
      content: [],
      creator: {
        id: 'c9a4c710-bdf8-41f0-8bea-ac0155c0c37f',
        firstName: 'Brit',
        lastName: 'Denz',
        email: 'britzden@yahoo.com',

        tiktokProfile: {
          handle: 'britz891',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T08:02:37.000Z',
        amountCents: 22096
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2467c206-66e5-4b95-bd93-7a8574f08345',
      content: [],
      creator: {
        id: 'a18d8f00-05ce-4f6d-b1e3-526f52a969d7',
        firstName: 'Ashanti',
        lastName: 'Johnson RCJ',
        email: 'ashantibiz12@gmail.com',

        tiktokProfile: {
          handle: 'they.envy1',
          followers: 6103,
          engagementRate: 5.371846472169951
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T16:23:11.000Z',
        amountCents: 15000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7a15481c-0ce8-4418-a254-e28be2e6ba4b',
      content: [],
      creator: {
        id: 'da065af2-2ce4-4fc1-ac4f-2f00bb81188a',
        firstName: 'Naomi',
        lastName: 'Ricketts',
        email: 'jayy17ricketts@gmail.com',

        tiktokProfile: {
          handle: 'sweet_leo47',
          followers: 233
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T16:33:57.000Z',
        amountCents: 20000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '11438ee0-a4c9-4a65-9010-02fb9fa7e457',
      content: [],
      creator: {
        id: 'f46a2970-b96b-4f37-9df9-99023ce43fcc',
        firstName: 'Suz Marconi',
        lastName: 'Fuentes',
        email: 'suzmarcrf@gmail.com',
        instagramProfile: {
          handle: 'suzmarcrf',
          followers: 207,
          engagementRate: 2.4154589371980677
        },
        tiktokProfile: {
          handle: 'suzmarcrf',
          followers: 86
        },
        youtubeProfile: {
          handle: 'suzmarconi',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T16:56:16.000Z',
        amountCents: 16954
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '11cd8a24-49a8-4dcb-ad3c-6016a20ce468',
      content: [],
      creator: {
        id: '99162f02-112a-451a-9efc-f5a186aeefbe',
        firstName: 'Osias',
        lastName: 'Bartolome',
        email: 'osiasfr2017@gmail.com',

        tiktokProfile: {
          handle: 'osias.bartolome',
          followers: 0
        },
        youtubeProfile: {
          handle: 'osiasbartolome5141',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T19:25:40.000Z',
        amountCents: 17564
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5439ba6f-70c7-4a71-aefb-8958afdf517c',
      content: [],
      creator: {
        id: 'cba11cd9-2503-45cf-a8f0-d14c040926e3',
        firstName: 'Tara',
        lastName: 'Ertone',
        email: 'heroes-wombats.0w@icloud.com',

        tiktokProfile: {
          handle: 'mstreviews',
          followers: 0
        },
        youtubeProfile: {
          handle: 'taraertone-mo6ij',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T21:50:17.000Z',
        amountCents: 15794
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1f635e67-e40e-4a8e-8504-5bd8dc80e2dd',
      content: [],
      creator: {
        id: '1d47acf2-0bb7-4d24-89f1-00ea7c56a77d',
        firstName: 'Venissa O',
        lastName: 'Farquharson',
        email: 'venissafarquharson@gmail.com',

        tiktokProfile: {
          handle: 'venissafarquharso0',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T22:02:23.000Z',
        amountCents: 15148
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a77a535d-fc29-4caf-9a5d-959921d0e470',
      content: [],
      creator: {
        id: '77b94c58-cac7-45e0-afab-fcca2bde5f7c',
        firstName: 'Martina',
        lastName: 'Ross',
        email: 'mross8390@gmail.com',

        tiktokProfile: {
          handle: '_.martinaross',
          followers: 810,
          engagementRate: 13.543859649122808
        },
        youtubeProfile: {
          handle: 'martinaross5741',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T16:12:20.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e7e4e48b-f848-4bad-a1c2-13830d118145',
      content: [],
      creator: {
        id: '1fecea18-13d8-4e2c-bda1-12bc19ebea3b',
        firstName: 'Anika',
        lastName: 'Clarke',
        email: 'niyah.collabs4@gmail.com',

        tiktokProfile: {
          handle: 'mamacita_niyah',
          followers: 5058
        },
        youtubeProfile: {
          handle: 'a.c931',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T17:00:12.000Z',
        amountCents: 15000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '331bcf81-130e-48a9-bbb7-b92db9641beb',
      content: [],
      creator: {
        id: 'd6445742-7043-4f1c-87d5-a54772e76e85',
        firstName: 'Trendi',
        lastName: 'Francis',
        email: 'brizanfrancis@gmail.com',
        instagramProfile: {
          handle: 'fashionwithtrenz',
          followers: 183
        },
        tiktokProfile: {
          handle: 'itztrenniboo',
          followers: 1062,
          engagementRate: 15.505617977528091
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T19:13:59.000Z',
        amountCents: 17077
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '4d4f1dd0-2ad8-4f62-8231-0fb9263141a9',
      content: [],
      creator: {
        id: '23afb3c2-78cc-49a3-8438-800f72333ffb',
        firstName: 'Crystal',
        lastName: 'Gayle',
        email: 'fenlaterorandy@gmail.com',

        tiktokProfile: {
          handle: 'crissy2209',
          followers: 0
        },
        youtubeProfile: {
          handle: 'crystalgayle633',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T03:44:20.000Z',
        amountCents: 7703
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0f787a2c-2bf1-42de-89f5-8767f57b21f2',
      content: [],
      creator: {
        id: 'd5de5812-e788-451d-a1a8-ef70f9611ee2',
        firstName: 'Albert',
        lastName: 'James',
        email: 'flashalbert0301@gmail.com',

        tiktokProfile: {
          handle: 'albertjames741',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T05:23:06.000Z',
        amountCents: 16536
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '2d3e3964-02be-42e0-98ee-9cf471676ce9',
      content: [],
      creator: {
        id: '74863466-a836-4501-bdea-0ce966da430c',
        firstName: 'Anthony',
        lastName: 'Bertone Jr',
        email: 'jigsaw-basque.0e@icloud.com',
        instagramProfile: {
          handle: 'tony.jr.reviews',
          followers: 5,
          engagementRate: 20
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T13:04:35.000Z',
        amountCents: 15174
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '0f163faf-a925-4073-99ba-4ae2b0cc5474',
      content: [],
      creator: {
        id: '7c33d486-7166-40e8-b20e-9a80625f2878',
        firstName: 'Nelle',
        lastName: 'Taylor',
        email: 'jenelle_96@hotmail.com',

        tiktokProfile: {
          handle: 'empress_nellee',
          followers: 1425
        },
        youtubeProfile: {
          handle: 'jenelletaylor5092',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T13:47:29.000Z',
        amountCents: 16000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '606d3800-2ea8-43fd-97ad-7bcf6f6e240a',
      content: [],
      creator: {
        id: 'a205cc50-19b2-44bf-a3e5-0d268f885bea',
        firstName: 'Karisha',
        lastName: 'Coke',
        email: 'rishacoke597@gmail.com',
        instagramProfile: {
          handle: 'rusticfashionboutique',
          followers: 1184,
          engagementRate: 0.05067567567567568
        },
        tiktokProfile: {
          handle: 'syndicate_dolly_',
          followers: 1286,
          engagementRate: 20.417183078829833
        },
        youtubeProfile: {
          handle: 'antisocialqxeen7977',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T16:37:20.000Z',
        amountCents: 14185
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'e6055cb6-40b0-4ee2-af00-f37892f4b37c',
      content: [],
      creator: {
        id: '4e85cd6e-4aef-417b-b010-53926c4e3937',
        firstName: 'Tineisha',
        lastName: 'Reid',
        email: 'cedella.reid26@gmail.com',

        tiktokProfile: {
          handle: 'della3177',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T21:40:48.000Z',
        amountCents: 16175
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '28541a51-e15d-45e2-a0da-d5545b4c0f94',
      content: [],
      creator: {
        id: '6480e605-2f51-4fda-9fcc-f1bed4285823',
        firstName: 'Lauren',
        lastName: 'Dawson',
        email: 'laurenmu95@gmail.com',

        tiktokProfile: {
          handle: 'luxeslife',
          followers: 1171
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T16:12:08.000Z',
        amountCents: 16011
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b97b42b2-9abb-47cd-8a62-071aaef90f19',
      content: [],
      creator: {
        id: 'b5621308-1d9a-4e4c-8f15-1e25fa6794c7',
        firstName: 'Samuel',
        lastName: 'Sawiris',
        email: 'sam3195@live.com',
        instagramProfile: {
          handle: 'sam.sawiris',
          followers: 335,
          engagementRate: 20.8955223880597
        },
        tiktokProfile: {
          handle: '_egyptian_papiiii_',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T16:12:52.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ae927ca6-4981-4542-83aa-85856f6d80c4',
      content: [],
      creator: {
        id: '0e798da8-7ab1-417a-ac6e-9cb9b885eeaf',
        firstName: 'Shakira',
        lastName: 'Rhoden',
        email: 'janelsummerlove@live.com',
        instagramProfile: {
          handle: 'dhayjhanel_remy',
          followers: 1851,
          engagementRate: 1.9611021069692056
        },
        tiktokProfile: {
          handle: 'juju_british',
          followers: 7609,
          engagementRate: 20
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T16:17:41.000Z',
        amountCents: 17077
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '151c8cd8-053f-4933-a438-4feca6bf70d7',
      content: [],
      creator: {
        id: '70008da9-f601-4db5-abfb-fcd12a9c7e43',
        firstName: 'Treshana',
        lastName: 'Bonner',
        email: 'treshbonner@gmail.com',

        tiktokProfile: {
          handle: 'itz_tresh_',
          followers: 1386
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-02T16:40:11.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '542085c6-b43f-4c67-bdd0-d0dda4d0870f',
      content: [],
      creator: {
        id: 'c8e747a1-8da9-4d3f-8ce7-22b26b1a924c',
        firstName: 'Daina ',
        lastName: 'Hickling',
        email: 'lenne8504@gmail.com',

        tiktokProfile: {
          handle: 'lennedslhdv',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T04:51:54.000Z',
        amountCents: 20865
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '988f2350-826b-4b24-8019-f4c90c99e1d1',
      content: [],
      creator: {
        id: 'e787a807-c764-4dc6-9f95-a3e7754121b7',
        firstName: 'Kimberlin',
        lastName: 'Neil',
        email: 'kimberlinneil061115@gmail.com',
        instagramProfile: {
          handle: 'rk_essentials',
          followers: 225
        },
        tiktokProfile: {
          handle: 'k_mamii',
          followers: 81
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T16:12:05.000Z',
        amountCents: 14999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3f7c3f8e-4175-4ab8-9cd0-cc5de98932c0',
      content: [],
      creator: {
        id: '7a7c5bab-2c07-4814-a0e3-e8e25d867df9',
        firstName: 'Samantha',
        lastName: 'Whittaker',
        email: 'samanthawellington28@gmail.com',

        tiktokProfile: {
          handle: 'samanthabritish18',
          followers: 107
        },
        youtubeProfile: {
          handle: 'samanthabritish2183',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T16:45:14.000Z',
        amountCents: 16989
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'b0969b3e-2be9-438d-bb00-5be5dd843aa7',
      content: [],
      creator: {
        id: 'c8d0bae7-a7d7-47e3-9134-0c80869c5a2e',
        firstName: 'Mary',
        lastName: 'Sasil',
        email: 'mannsasil@gmail.com',
        instagramProfile: {
          handle: 'annsasil',
          followers: 806
        },
        tiktokProfile: {
          handle: 'zynahamira05',
          followers: 282,
          engagementRate: 2.578981302385558
        },
        youtubeProfile: {
          handle: 'maryanndapiton9351',
          followers: 36
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T19:13:00.000Z',
        amountCents: 17309
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd39b1b16-1895-456b-835c-8f6f09a9318a',
      content: [],
      creator: {
        id: 'b216780b-3e83-4a16-8fa5-06fbf99bb476',
        firstName: 'Dana',
        lastName: 'Barrett',
        email: 'barrettdana81@gmail.com',

        tiktokProfile: {
          handle: 'dime_rose21',
          followers: 9649,
          engagementRate: 9.50354609929078
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T20:21:13.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '5856ba2b-6e3b-45d5-b54e-29d57df1ba0d',
      content: [],
      creator: {
        id: 'ace3d7cb-1695-44ca-88a7-c5907c793950',
        firstName: 'Swayne',
        lastName: 'Ferguson',
        email: 'swayneferguson121@gmail.com',

        tiktokProfile: {
          handle: 'king.sdf',
          followers: 11
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T21:05:22.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '55801f72-4e93-42a2-9ece-c3c3f61523a7',
      content: [],
      creator: {
        id: '6d91e46a-8674-4244-895a-fbef55523a68',
        firstName: 'Amoy ',
        lastName: 'Barrett',
        email: 'amoybarrett28@gmail.com',

        tiktokProfile: {
          handle: 'dimerose1',
          followers: 437
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T21:11:41.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'abef6614-ed45-41ac-b25e-99619e742efd',
      content: [],
      creator: {
        id: '50132cca-d423-4b06-ba3e-b76264a85518',
        firstName: 'Joy-Dian',
        lastName: 'Daley',
        email: 'joydiandaley508@gmail.com',
        instagramProfile: {
          handle: 'joydiandaley.cosmetics',
          followers: 1366,
          engagementRate: 2.0741825280624697
        },
        tiktokProfile: {
          handle: 'joydiandaley',
          followers: 1075,
          engagementRate: 9.539207760711399
        },
        youtubeProfile: {
          handle: 'joydiandaley',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T21:32:18.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '3b793e9c-f601-4108-b368-2fc7294af510',
      content: [],
      creator: {
        id: 'd81a6a3f-f541-4e68-b9e7-6f1e9cd1f25d',
        firstName: 'Ashekia',
        lastName: 'Hunter',
        email: 'sheka.hunter@gmail.com',
        instagramProfile: {
          handle: 'shek_ia9',
          followers: 1115,
          engagementRate: 0.39461883408071746
        },
        tiktokProfile: {
          handle: 'shekabae',
          followers: 6110,
          engagementRate: 8.244894027637786
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-03T23:24:27.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bc3707e1-242c-4530-9278-a03f5ca93351',
      content: [],
      creator: {
        id: 'ec53ecd8-846e-4ce3-aea8-43916ae02df4',
        firstName: 'Zoann',
        lastName: 'Brown',
        email: 'zobrown056@gmail.com',

        tiktokProfile: {
          handle: 'zagga20',
          followers: 522
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-04T01:38:07.000Z',
        amountCents: 10205
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'bee80f02-b04b-461e-b971-983bf53c40d4',
      content: [],
      creator: {
        id: 'b42fa1e5-d398-45bf-bf49-db6a7a04855d',
        firstName: 'Tashane',
        lastName: 'Easy',
        email: 'tashaneeasy1@gmail.com',

        tiktokProfile: {
          handle: 'wig.reviewer3',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-04T18:15:14.000Z',
        amountCents: 17011
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '30f49fc8-e4e1-4711-ba1e-7d9423a112c4',
      content: [],
      creator: {
        id: 'aba20dc1-8bbb-444f-a1aa-32951d4542fe',
        firstName: 'Keniesha',
        lastName: 'Brown',
        email: 'kenieshabrown37@gmail.com',

        tiktokProfile: {
          handle: 'keniesha33',
          followers: 4399,
          engagementRate: 13.18181818181818
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-04T19:32:16.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '7ed1d95a-9df3-4262-baec-1392bbf4c2ec',
      content: [],
      creator: {
        id: 'ebca23be-4ed1-4a75-8952-798d71e7db00',
        firstName: 'Angella',
        lastName: 'James',
        email: 'angellajames519@gmail.com',

        tiktokProfile: {
          handle: 'jellyjam28',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-04T20:14:00.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8a6de62e-c16f-4ced-8780-372838c71f2a',
      content: [],
      creator: {
        id: 'ea610b9d-4f00-454c-954f-ef27817f7537',
        firstName: 'Jay France',
        lastName: 'Dapiton',
        email: 'jfdapiton@gmail.com',

        tiktokProfile: {
          handle: 'jeeprans',
          followers: 31,
          engagementRate: 2.6506024096385543
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T01:33:38.000Z',
        amountCents: 16199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '09a48db4-f5f6-4180-a62c-2ef775c39b86',
      content: [],
      creator: {
        id: 'e3721829-f169-4390-b88b-611ac5bc8776',
        firstName: 'Danielle',
        lastName: 'Campbell',
        email: 'dcampbell1240@gmail.com',
        instagramProfile: {
          handle: 'danhors_hair_vogue',
          followers: 1004,
          engagementRate: 0.7071713147410359
        },
        tiktokProfile: {
          handle: 'scorpio_rosa',
          followers: 4230,
          engagementRate: 14.873035066505441
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T04:22:44.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'a716272d-00f9-4d9d-872a-24bc9a342597',
      content: [],
      creator: {
        id: '7835dedf-2923-4c4b-9f7b-acb3b1845d96',
        firstName: 'Clara- Ann',
        lastName: 'Smith',
        email: 'smithannclara@gmail.com',
        instagramProfile: {
          handle: 'lippiesbycece',
          followers: 137,
          engagementRate: 9.124087591240876
        },
        tiktokProfile: {
          handle: 'ceceloves3',
          followers: 1460,
          engagementRate: 12.333778710949925
        },
        youtubeProfile: {
          handle: 'missunbotheredcece',
          followers: 30
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T08:12:43.000Z',
        amountCents: 17077
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '06136173-148d-4ff7-b233-d5a88572cfb8',
      content: [],
      creator: {
        id: 'a0748f2a-d92f-4ec7-81cc-24e136038e00',
        firstName: 'Shanakay',
        lastName: 'Noble',
        email: 'nobleterryann5@gmail.com',
        instagramProfile: {
          handle: 'authentic_golden_wear',
          followers: 907,
          engagementRate: 0.2631578947368421
        },
        tiktokProfile: {
          handle: 'shanni__boo',
          followers: 660
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T09:43:06.000Z',
        amountCents: 14247
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '05f34bf7-136c-466e-aaf3-0d5148764009',
      content: [],
      creator: {
        id: 'b8dd1910-5aa9-471e-bbcd-8b01b05feb09',
        firstName: 'Shamalia',
        lastName: 'Noyan',
        email: 'shammysham26@gmail.com',
        instagramProfile: {
          handle: 'sn.essentials_',
          followers: 1234,
          engagementRate: 1.3695299837925445
        },
        tiktokProfile: {
          handle: 'onequeen_',
          followers: 2767,
          engagementRate: 7.931034482758621
        },
        youtubeProfile: {
          handle: 'onequeen7747',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T10:34:16.000Z',
        amountCents: 6400
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'ac0045b9-9449-4f24-9d8a-91fee6d2a160',
      content: [],
      creator: {
        id: '68361e3e-4a02-43ce-9654-10e9322c0b7d',
        firstName: 'Judz',
        lastName: 'Ricketts',
        email: 'rickettsjudene25@gmail.com',

        tiktokProfile: {
          handle: 'j.r3719',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T16:37:12.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'db707f2f-13a5-478a-8bc6-9e38a74977db',
      content: [],
      creator: {
        id: '3f7d7cfe-f22f-49b9-935a-8a1eefc11fc8',
        firstName: 'Sophian',
        lastName: 'Whyte',
        email: 'sbrownwhyte@yahoo.com',

        tiktokProfile: {
          handle: 'nika_whyte',
          followers: 724
        },
        youtubeProfile: {
          handle: 'glendonwhyde8211',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T16:40:09.000Z',
        amountCents: 23000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1bd135e7-2034-4567-8213-593cf8497d3b',
      content: [],
      creator: {
        id: '06b6850f-4910-439c-ac3a-a47df962fd20',
        firstName: 'Feneisha',
        lastName: 'Fletcher',
        email: 'meshabritish@gmail.com',

        tiktokProfile: {
          handle: 'slayqxeenmesha',
          followers: 4193,
          engagementRate: 17.734645525788228
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T17:37:34.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1c680e2c-b542-470a-8ad0-2b8a31da5105',
      content: [],
      creator: {
        id: '15796182-62da-4801-aefa-4ba7cd665c3c',
        firstName: 'Judene',
        lastName: 'Ricketts',
        email: 'rickettsjudz@gmail.com',

        tiktokProfile: {
          handle: 'ricketts.judz',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T17:50:35.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'debce0a5-07ec-47ce-a967-ae2791dad464',
      content: [],
      creator: {
        id: '191075b7-035d-4c5a-aab0-fa70c228650e',
        firstName: 'Sooyen',
        lastName: 'Gordon',
        email: 'beingsu3@gmail.com',
        instagramProfile: {
          handle: 'beingsu3',
          followers: 713,
          engagementRate: 1.6409537166900419
        },
        tiktokProfile: {
          handle: 'sueology',
          followers: 461,
          engagementRate: 9.30232558139535
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T18:52:24.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '8fb36feb-75df-4344-acc4-50926ac0e769',
      content: [],
      creator: {
        id: '0dad7bf0-8a96-4d82-b92c-2204f908c159',
        firstName: 'Yanique',
        lastName: 'Miller',
        email: 'yaniquemiller145@gmail.com',
        instagramProfile: {
          handle: 'inclusiveforyou._',
          followers: 1143,
          engagementRate: 2.2309711286089238
        },
        tiktokProfile: {
          handle: 'millidon_',
          followers: 4774,
          engagementRate: 11.970342468006711
        },
        youtubeProfile: {
          handle: 'ymgang423',
          followers: 188
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T19:13:38.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '1f1dd65c-6893-4d87-ab5d-6649608b329f',
      content: [],
      creator: {
        id: '941bc2e7-4765-40fa-a885-d5768ea50125',
        firstName: 'Judith',
        lastName: 'Simpson-Ricketts',
        email: 'rickettsnaomi24@gmail.com',

        tiktokProfile: {
          handle: 'jaii_1999',
          followers: 14
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T22:02:41.000Z',
        amountCents: 25000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'c9d1f3a0-5fe5-4df0-a9fc-de75ad6ffe7f',
      content: [],
      creator: {
        id: '768668d8-630c-4194-aa27-42da5e4888fd',
        firstName: 'Polly',
        lastName: 'Smith',
        email: 'kadeisha.smith@gmail.com',

        tiktokProfile: {
          handle: 'gennismith',
          followers: 34
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T23:25:31.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: '09a4b44f-f39f-4bff-8e38-cb961dadfec7',
      content: [],
      creator: {
        id: 'd95b6adf-ee2d-43b7-a044-65d7631361b3',
        firstName: 'Shawntae',
        lastName: 'Gregory',
        email: 'shawntae_17@hotmail.com',

        tiktokProfile: {
          handle: 'shawntae1994',
          followers: 2964
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-05T23:32:45.000Z',
        amountCents: 16748
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE
    },
    {
      userCampaignId: 'd3326357-f88c-4ab7-a514-d5f13bfc6e2d',
      content: [
        {
          groupId: '34b0a8ca-8002-4935-b12b-bcbc54609011',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T16:11:52.206Z',

          createdAt: '2024-06-27T21:32:14.747Z',
          content: [
            {
              caption: '',

              id: '0ea19e90-5e83-405d-a27a-8e42614b0945',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d3326357-f88c-4ab7-a514-d5f13bfc6e2d/ugc/34b0a8ca-8002-4935-b12b-bcbc54609011/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '79b9fb81-d10f-457b-9053-64f905276cad',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T19:37:54.598Z',

          createdAt: '2024-06-28T19:49:07.103Z',
          content: [
            {
              caption: '',

              id: 'a4784721-0734-4347-916b-7a799465b091',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d3326357-f88c-4ab7-a514-d5f13bfc6e2d/ugc/79b9fb81-d10f-457b-9053-64f905276cad/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '00d6e244-a4c4-401b-8c4c-c56bb8f821ac',
        firstName: 'Anderson',
        lastName: 'Nguyen',
        email: 'andersonvannguyen.business@gmail.com',
        instagramProfile: {
          handle: 'andersonnguyen1',
          followers: 79131,
          engagementRate: 1.616307136267708
        },
        tiktokProfile: {
          handle: 'andersonnguyen.official',
          followers: 166871,
          engagementRate: 6.89009793876802
        },
        youtubeProfile: {
          handle: 'andersonnguyen.official',
          followers: 73600
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-10T23:02:11.000Z',
        amountCents: 16293
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3844b06f-f77e-4f01-8ed6-318597d28873',
      content: [
        {
          groupId: '5f598411-892b-44cf-bb00-4721081c5c93',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T14:10:34.122Z',

          createdAt: '2024-06-18T02:11:11.988Z',
          content: [
            {
              caption: '',

              id: '05c2a7de-6d2f-4caf-9d6f-b79680f84e29',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3844b06f-f77e-4f01-8ed6-318597d28873/ugc/5f598411-892b-44cf-bb00-4721081c5c93/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '45f774f9-79b2-4e89-b3c1-6931e3bcedaf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T14:10:29.123Z',

          createdAt: '2024-06-18T02:10:00.850Z',
          content: [
            {
              caption: '',

              id: 'c0fe3c70-d35e-466b-9d83-f8ee5f67aa75',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3844b06f-f77e-4f01-8ed6-318597d28873/ugc/45f774f9-79b2-4e89-b3c1-6931e3bcedaf/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4c3dd077-4f96-4810-ab10-f20e4fb02196',
        firstName: 'Benjamin',
        lastName: 'Miller',
        email: 'wrekonizereal@gmail.com',
        instagramProfile: {
          handle: 'wrekonize',
          followers: 106772,
          engagementRate: 0.5252313340576181
        },
        tiktokProfile: {
          handle: 'wrekonizemusic',
          followers: 4063,
          engagementRate: 10.32917139614075
        },
        youtubeProfile: {
          handle: 'wrekonizethemusic',
          followers: 105000
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-10T23:10:58.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'be8514be-7218-438b-a24b-ffdc0c38bca5',
      content: [
        {
          groupId: '4345f38d-25ee-41b8-83c2-9a346446c626',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T15:35:00.421Z',

          createdAt: '2024-06-17T02:43:51.411Z',
          content: [
            {
              caption: '',

              id: '200a7ec9-8134-49d8-ba81-6e7f9e82664b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/be8514be-7218-438b-a24b-ffdc0c38bca5/ugc/4345f38d-25ee-41b8-83c2-9a346446c626/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2e97badf-6446-4647-85d0-e02e1ce7c2bf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-17T15:35:04.006Z',

          createdAt: '2024-06-16T21:34:05.040Z',
          content: [
            {
              caption: '',

              id: '149373d4-8c96-4acc-8512-e2644ba1025f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/be8514be-7218-438b-a24b-ffdc0c38bca5/ugc/2e97badf-6446-4647-85d0-e02e1ce7c2bf/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8eabd912-4ce3-4355-97fa-936968176355',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/be8514be-7218-438b-a24b-ffdc0c38bca5/ugc/2e97badf-6446-4647-85d0-e02e1ce7c2bf/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '71f29894-47ef-47ad-9740-14ebe5767a50',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/be8514be-7218-438b-a24b-ffdc0c38bca5/ugc/2e97badf-6446-4647-85d0-e02e1ce7c2bf/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '33236107-49d4-40ef-8a71-386ce6330595',
        firstName: 'Anahys',
        lastName: 'Ramos',
        email: 'anahysramos99@gmail.com',
        instagramProfile: {
          handle: 'anahysramos',
          followers: 89019,
          engagementRate: 1.0409013806041407
        },
        tiktokProfile: {
          handle: 'anahysramos',
          followers: 5223,
          engagementRate: 5.369127516778524
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-10T23:24:23.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7bde627d-256c-46d0-8642-e97d6b170532',
      content: [
        {
          groupId: '8df7267c-dce6-40ab-8701-1785a9174a5f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T22:28:49.614Z',

          createdAt: '2024-06-14T18:31:54.310Z',
          content: [
            {
              caption: '',

              id: '21ec86d3-acf4-420b-a256-f6d4fde249cd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7bde627d-256c-46d0-8642-e97d6b170532/ugc/8df7267c-dce6-40ab-8701-1785a9174a5f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '1564bf3a-7fae-42ff-b539-56ada10f60c0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-17T22:28:53.050Z',

          createdAt: '2024-06-14T01:43:00.958Z',
          content: [
            {
              caption: '',

              id: 'db8a11da-bc13-4238-8914-29fd5054f243',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7bde627d-256c-46d0-8642-e97d6b170532/ugc/1564bf3a-7fae-42ff-b539-56ada10f60c0/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd4fb13f1-9702-44fd-bcaf-8f31f3537270',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7bde627d-256c-46d0-8642-e97d6b170532/ugc/1564bf3a-7fae-42ff-b539-56ada10f60c0/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'abb07528-0fbd-40ad-94a6-67ab6ae94847',
        firstName: 'Taylor',
        lastName: 'Cortis',
        email: 'contact@tslayz.com',
        instagramProfile: {
          handle: 'tslayz',
          followers: 71062,
          engagementRate: 0.9380540936083983
        },
        tiktokProfile: {
          handle: 'tslayz',
          followers: 113590,
          engagementRate: 1.3172338090010975
        },
        youtubeProfile: {
          handle: 'tslayz'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T08:12:10.000Z',
        amountCents: 15899
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7ceebe0f-fd79-40ab-b0e4-1439ad0ac78f',
      content: [
        {
          groupId: '775e5fa4-cd95-4412-88ee-3c7fb4db5439',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T17:12:54.492Z',

          createdAt: '2024-06-16T17:06:31.666Z',
          content: [
            {
              caption: '',

              id: '06f53f0c-dc9b-494e-8129-015edffffa2d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7ceebe0f-fd79-40ab-b0e4-1439ad0ac78f/ugc/775e5fa4-cd95-4412-88ee-3c7fb4db5439/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '90c4aeeb-eff6-4bdc-b9db-ad0bc2f8e14b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T17:12:50.614Z',

          createdAt: '2024-06-16T22:18:30.845Z',
          content: [
            {
              caption: '',

              id: 'bd95e484-48b6-4533-a23d-3a0fb8c3670e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7ceebe0f-fd79-40ab-b0e4-1439ad0ac78f/ugc/90c4aeeb-eff6-4bdc-b9db-ad0bc2f8e14b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6b5383b5-75a3-4dc1-a4fa-442976874f29',
        firstName: 'Kelly',
        lastName: 'Trudersheim',
        email: 'kellytrudersheim@gmail.com',
        instagramProfile: {
          handle: 'california_kel',
          followers: 5218,
          engagementRate: 0.7684936757378306
        },
        tiktokProfile: {
          handle: 'california_kel',
          followers: 5599,
          engagementRate: 2.5055825055825056
        },
        youtubeProfile: {
          handle: 'kelly92109'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T16:12:18.000Z',
        amountCents: 16161
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3a74c4a5-5f5f-4136-a48e-2368022c2806',
      content: [
        {
          groupId: '0adf1cdb-2aa9-43a3-9476-4568f6ac6a67',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T14:20:16.598Z',

          createdAt: '2024-07-06T19:29:40.962Z',
          content: [
            {
              caption: '',

              id: 'b2d077bb-9c49-449d-9d65-b5a25f04d01f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a74c4a5-5f5f-4136-a48e-2368022c2806/ugc/0adf1cdb-2aa9-43a3-9476-4568f6ac6a67/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ff72e7d0-e3ac-4436-bc8c-eee74f5bd5e6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-07-09T14:20:21.417Z',

          createdAt: '2024-07-06T19:28:59.924Z',
          content: [
            {
              caption: '',

              id: 'da2a3eef-95a6-4c08-a0fe-bbf64b7e052d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a74c4a5-5f5f-4136-a48e-2368022c2806/ugc/ff72e7d0-e3ac-4436-bc8c-eee74f5bd5e6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd74b19b6-fc65-440a-8011-bc75e5b0690d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a74c4a5-5f5f-4136-a48e-2368022c2806/ugc/ff72e7d0-e3ac-4436-bc8c-eee74f5bd5e6/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '98ba50aa-6efc-4695-9fc8-c7740071a3ed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a74c4a5-5f5f-4136-a48e-2368022c2806/ugc/ff72e7d0-e3ac-4436-bc8c-eee74f5bd5e6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f22f30fb-ab9b-41ac-a640-52ea8b55db55',
        firstName: 'Alexa',
        lastName: 'Hughes',
        email: 'alexahughesblog@gmail.com',
        instagramProfile: {
          handle: 'alexajhughes',
          followers: 25362,
          engagementRate: 9.923901900481034
        },
        tiktokProfile: {
          handle: 'alexajhughes',
          followers: 103
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T16:16:24.000Z',
        amountCents: 15869
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '46a5997b-14a6-48cd-a29d-089724d3b560',
      content: [
        {
          groupId: '0df1ec65-ea61-43c4-bcc1-bea177b2cb2c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T13:47:59.200Z',

          createdAt: '2024-06-13T22:20:03.136Z',
          content: [
            {
              caption: '',

              id: 'eaed6b53-5b74-4609-87dd-224269568296',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46a5997b-14a6-48cd-a29d-089724d3b560/ugc/0df1ec65-ea61-43c4-bcc1-bea177b2cb2c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '911f0890-7ef2-40a3-b2ba-a8125c223b2d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T13:48:02.783Z',

          createdAt: '2024-06-13T20:06:31.485Z',
          content: [
            {
              caption: '',

              id: '9caa2de2-3a04-49af-b907-fa56f5b6f104',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46a5997b-14a6-48cd-a29d-089724d3b560/ugc/911f0890-7ef2-40a3-b2ba-a8125c223b2d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e9dc7c9d-1f0d-4c15-8261-c62e18e541f0',
        firstName: 'Darrell',
        lastName: 'Rivera',
        email: 'darrell.rivera@ymail.com',
        instagramProfile: {
          handle: 'dareal08',
          followers: 61992,
          engagementRate: 0.40924635436830553
        },
        tiktokProfile: {
          handle: 'dareal08_',
          followers: 92158,
          engagementRate: 6.6095996328554465
        },
        youtubeProfile: {
          handle: 'dareal08_'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T16:22:09.000Z',
        amountCents: 16255
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f0f1738e-2a88-4bea-bcf2-c506b66ee70a',
      content: [
        {
          groupId: '1e0407c4-ce5d-4ffe-9ad1-2e69972d6bb9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T16:35:34.742Z',

          createdAt: '2024-06-14T23:58:52.639Z',
          content: [
            {
              caption: '',

              id: '0938f5fa-61d1-44c1-ac42-d31f02ce354d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f0f1738e-2a88-4bea-bcf2-c506b66ee70a/ugc/1e0407c4-ce5d-4ffe-9ad1-2e69972d6bb9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '066d6aa9-0d98-409d-829c-02a994ef8de2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T16:35:39.113Z',

          createdAt: '2024-06-14T21:21:42.340Z',
          content: [
            {
              caption: '',

              id: 'b93f39fd-17b7-431a-a8ec-9790b5ae499e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f0f1738e-2a88-4bea-bcf2-c506b66ee70a/ugc/066d6aa9-0d98-409d-829c-02a994ef8de2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ee717a2e-e6b5-4168-bdd7-64d75b48c007',
        firstName: 'Priscilla',
        lastName: 'P',
        email: 'sincerelypriscillaxo@gmail.com',

        tiktokProfile: {
          handle: 'sincerely.priscilla',
          followers: 7809,
          engagementRate: 15.35552193645991
        },
        youtubeProfile: {
          handle: 'sincerely.priscilla',
          followers: 41
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T16:22:13.000Z',
        amountCents: 16330
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '00920dcf-54bd-40fc-9f39-26e0f6f6abb2',
      content: [
        {
          groupId: 'ab9c79e9-ce03-443e-9088-fb888687adda',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T11:20:28.846Z',

          createdAt: '2024-06-19T01:37:39.247Z',
          content: [
            {
              caption: '',

              id: '81f40c2e-53ea-4b1c-9807-836a1bcd92a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00920dcf-54bd-40fc-9f39-26e0f6f6abb2/ugc/ab9c79e9-ce03-443e-9088-fb888687adda/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '79928dc3-0fc2-4b6c-9fbf-04c70c5ae42d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-29T19:58:12.764Z',

          createdAt: '2024-06-18T22:52:46.022Z',
          content: [
            {
              caption: '',

              id: '3d3fa26f-c1d1-469d-b782-bac6f4fa3ef8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00920dcf-54bd-40fc-9f39-26e0f6f6abb2/ugc/79928dc3-0fc2-4b6c-9fbf-04c70c5ae42d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6319ed53-d12f-49e4-b68c-3eb6f16b7309',
        firstName: 'Christian',
        lastName: 'Gonzalez',
        email: 'info@dadlifegaming.com',
        instagramProfile: {
          handle: 'dad.life.gaming',
          followers: 2893,
          engagementRate: 2.305565157276184
        },
        tiktokProfile: {
          handle: 'dad.life.gaming',
          followers: 568,
          engagementRate: 3.341584158415842
        },
        youtubeProfile: {
          handle: 'dadlifegaming'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T17:52:11.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9026c630-5895-4828-b1ac-6187363ccb39',
      content: [
        {
          groupId: '10f09a35-4f73-4908-8b30-f45a343d4b38',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-17T15:57:13.148Z',

          createdAt: '2024-06-15T16:12:13.870Z',
          content: [
            {
              caption: '',

              id: 'ae2876a5-c69b-44ee-9170-bbeaa1328385',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9026c630-5895-4828-b1ac-6187363ccb39/ugc/10f09a35-4f73-4908-8b30-f45a343d4b38/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd2af31b9-1e85-4a3b-bf50-8488e75084ab',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9026c630-5895-4828-b1ac-6187363ccb39/ugc/10f09a35-4f73-4908-8b30-f45a343d4b38/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9460252f-6f4d-4291-8d5a-aa601673d732',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T15:57:08.926Z',

          createdAt: '2024-06-15T16:12:54.959Z',
          content: [
            {
              caption: '',

              id: '23e08e0c-a7c7-4ce9-a80a-18bc39b4ff63',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9026c630-5895-4828-b1ac-6187363ccb39/ugc/9460252f-6f4d-4291-8d5a-aa601673d732/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7e9ef6f2-95cb-4ea3-a90b-0a3dd0396377',
        firstName: 'James',
        lastName: 'Mitchell',
        email: 'gottinightlife@yahoo.com',
        instagramProfile: {
          handle: 'jaygmtv',
          followers: 103458,
          engagementRate: 0.1639312571284966
        },
        tiktokProfile: {
          handle: 'jaygmtv',
          followers: 230
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T18:46:16.000Z',
        amountCents: 16330
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '18b10a72-5043-4bff-b778-963647a85933',
      content: [],
      creator: {
        id: '1e9353c7-05b0-42df-991a-c482a1106710',
        firstName: 'Buse',
        lastName: 'Keskin',
        email: 'busebora08@hotmail.com',
        instagramProfile: {
          handle: 'buses.life.journey',
          followers: 27173,
          engagementRate: 0.4224679475405019
        },
        tiktokProfile: {
          handle: 'tictokers88',
          followers: 17,
          engagementRate: 1.9643944004869143
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T19:22:11.000Z',
        amountCents: 16124
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '542f23eb-5db7-44e2-9b3a-332b7d64ec62',
      content: [],
      creator: {
        id: '1639057f-94ea-4382-9557-11b1e04b9414',
        firstName: 'Jessica',
        lastName: 'West',
        email: 'collabwjess@gmail.com',
        instagramProfile: {
          handle: 'jess.di0rr',
          followers: 42344,
          engagementRate: 0.2326185528055923
        },
        tiktokProfile: {
          handle: 'jess.di0rr',
          followers: 8752,
          engagementRate: 6.8176160138299995
        },
        youtubeProfile: {
          handle: 'jess.di0rr',
          followers: 24
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T19:22:12.000Z',
        amountCents: 17436
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b92f65dc-4606-43cb-aa69-b4ea815888ca',
      content: [
        {
          groupId: '5fcadd0b-9c7b-49b1-becf-cffc5e6d7299',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T14:20:15.266Z',

          createdAt: '2024-06-23T16:56:24.225Z',
          content: [
            {
              caption: '',

              id: '22d6bf3a-0518-4387-bc90-b4d98e1db79f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b92f65dc-4606-43cb-aa69-b4ea815888ca/ugc/5fcadd0b-9c7b-49b1-becf-cffc5e6d7299/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '403cf6d4-5e07-4882-9963-8b68009f2d22',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-21T11:56:37.883Z',

          createdAt: '2024-06-20T17:59:16.717Z',
          content: [
            {
              caption: '',

              id: '7829a045-7257-4737-9785-31c6196a715e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b92f65dc-4606-43cb-aa69-b4ea815888ca/ugc/403cf6d4-5e07-4882-9963-8b68009f2d22/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '80de58dc-7965-46e3-bb03-515a4c2a064c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b92f65dc-4606-43cb-aa69-b4ea815888ca/ugc/403cf6d4-5e07-4882-9963-8b68009f2d22/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4df48747-6482-4138-9984-6d03ad3fd288',
        firstName: 'Nicola',
        lastName: 'Ianeselli',
        email: 'nicola.ianeselli@gmail.com',
        instagramProfile: {
          handle: 'nicolaianeselli',
          followers: 4420,
          engagementRate: 0.38235294117647056
        },
        tiktokProfile: {
          handle: 'nicolaianeselli',
          followers: 34,
          engagementRate: 1.6598360655737707
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T19:22:12.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4e962a09-2a3a-4fb7-b9bc-f9c8b4c75da3',
      content: [
        {
          groupId: '90b7e26b-71f3-4d0e-b62d-31476d699a16',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T16:08:59.736Z',

          createdAt: '2024-06-27T04:26:45.832Z',
          content: [
            {
              caption: '',

              id: '3273ccd7-e498-448a-9526-86d30f88922b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4e962a09-2a3a-4fb7-b9bc-f9c8b4c75da3/ugc/90b7e26b-71f3-4d0e-b62d-31476d699a16/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '337df92d-e3a0-4541-9ba7-9bd19a5511d3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T19:01:21.659Z',

          createdAt: '2024-06-14T01:42:59.081Z',
          content: [
            {
              caption: '',

              id: '55d5d6fb-feb9-4cb0-9c0e-d6a89c4f00ad',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4e962a09-2a3a-4fb7-b9bc-f9c8b4c75da3/ugc/337df92d-e3a0-4541-9ba7-9bd19a5511d3/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '71500ef4-41b1-4b40-967f-5391cbbb324e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4e962a09-2a3a-4fb7-b9bc-f9c8b4c75da3/ugc/337df92d-e3a0-4541-9ba7-9bd19a5511d3/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '71c4efe7-acd6-4698-91b0-2537514b878e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4e962a09-2a3a-4fb7-b9bc-f9c8b4c75da3/ugc/337df92d-e3a0-4541-9ba7-9bd19a5511d3/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a44aa639-605c-48bf-a189-67356d9ac18f',
        firstName: 'Sylvia',
        lastName: 'Reese',
        email: 'styleshhairatlanta@gmail.com',
        instagramProfile: {
          handle: 'styleshkenna',
          followers: 4638,
          engagementRate: 0.5972401897369556
        },
        tiktokProfile: {
          handle: 'styleshkenna',
          followers: 3932,
          engagementRate: 5.832580641812274
        },
        youtubeProfile: {
          handle: 'styleshkenna'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T19:42:12.000Z',
        amountCents: 16334
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1c6fd30e-7cc1-49a1-91d3-eb4867479817',
      content: [
        {
          groupId: '528db531-5c52-47bf-b4da-fe68371d90ba',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T14:26:51.358Z',

          createdAt: '2024-06-23T22:24:23.018Z',
          content: [
            {
              caption: '',

              id: 'd516b27c-b2a6-431f-bf1a-ba52a1dbf075',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c6fd30e-7cc1-49a1-91d3-eb4867479817/ugc/528db531-5c52-47bf-b4da-fe68371d90ba/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '68200fbf-4012-4b45-954e-4a96bc534740',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T14:26:47.183Z',

          createdAt: '2024-06-24T03:19:06.274Z',
          content: [
            {
              caption: '',

              id: '6b8bb883-c9a3-4776-bbeb-86b4e1e84d8f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c6fd30e-7cc1-49a1-91d3-eb4867479817/ugc/68200fbf-4012-4b45-954e-4a96bc534740/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'caef0512-3f5b-4e2f-b56e-5470d1a01af4',
        firstName: 'Sammy',
        lastName: 'Fumagalli',
        email: 'skfumagalli@gmail.com',
        instagramProfile: {
          handle: 'funfitfuma',
          followers: 11799,
          engagementRate: 1.3407915925078395
        },
        tiktokProfile: {
          handle: 'sammyfumagalli',
          followers: 761,
          engagementRate: 3.7037037037037033
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T20:22:10.000Z',
        amountCents: 16236
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fd913aed-3706-495a-a57e-0e389003df4f',
      content: [
        {
          groupId: 'b832134d-4f2f-48ca-a54c-7f6167a22a3f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-22T22:54:54.242Z',

          createdAt: '2024-07-16T19:47:24.962Z',
          content: [
            {
              caption: '',

              id: 'c4cda704-57f3-4461-aaac-f3c79656f479',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fd913aed-3706-495a-a57e-0e389003df4f/ugc/b832134d-4f2f-48ca-a54c-7f6167a22a3f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2f5ba4b6-7d4e-49ed-8de9-b6ed8b7c3748',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fd913aed-3706-495a-a57e-0e389003df4f/ugc/b832134d-4f2f-48ca-a54c-7f6167a22a3f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bd23284b-d41f-4fe0-8616-69e154bb4614',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-22T22:54:50.518Z',

          createdAt: '2024-07-16T19:44:15.688Z',
          content: [
            {
              caption: '',

              id: '2828669b-0413-4206-b7c9-447d9c75da15',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fd913aed-3706-495a-a57e-0e389003df4f/ugc/bd23284b-d41f-4fe0-8616-69e154bb4614/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cc0b3bf7-ef2e-402b-a53e-d911b32d80cb',
        firstName: 'Austin',
        lastName: 'Becker',
        email: 'becker.austin@outlook.com',
        instagramProfile: {
          handle: 'theskierguy',
          followers: 11018,
          engagementRate: 1.1036485750589944
        },
        tiktokProfile: {
          handle: 'theskierguyco',
          followers: 1562,
          engagementRate: 7.877624095383859
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T20:22:11.000Z',
        amountCents: 16348
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '371ccfb2-f831-41e8-8bdc-4897c77bf90a',
      content: [],
      creator: {
        id: '3ec3355d-97ce-4572-87c5-d7d1bbc4fe5d',
        firstName: 'Cynclaire',
        lastName: 'Davis',
        email: 'info@cynclaire.com',
        instagramProfile: {
          handle: 'itscynclaire',
          followers: 11045,
          engagementRate: 0.5196921684019918
        },
        tiktokProfile: {
          handle: 'itscynclaire',
          followers: 455,
          engagementRate: 9.836065573770492
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T20:32:09.000Z',
        amountCents: 17175
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '398aae05-72e5-4289-a4c9-5f25139df22a',
      content: [
        {
          groupId: '6dc2a470-c5a7-4dc9-89ce-1d9bad66951d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-20T15:53:02.099Z',

          createdAt: '2024-06-20T03:41:17.289Z',
          content: [
            {
              caption: '',

              id: '0164ecc3-544e-4e56-8d9b-e7a406acc6c0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/398aae05-72e5-4289-a4c9-5f25139df22a/ugc/6dc2a470-c5a7-4dc9-89ce-1d9bad66951d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '67d862b5-f6db-49c4-963b-745189bc2abd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/398aae05-72e5-4289-a4c9-5f25139df22a/ugc/6dc2a470-c5a7-4dc9-89ce-1d9bad66951d/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '74d11a82-b2e8-4e6d-b7b3-f6dfb192931f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/398aae05-72e5-4289-a4c9-5f25139df22a/ugc/6dc2a470-c5a7-4dc9-89ce-1d9bad66951d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5ac06ffb-f638-4558-84ff-eff0c39af7bf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T15:52:07.515Z',

          createdAt: '2024-06-20T03:40:14.919Z',
          content: [
            {
              caption: '',

              id: '8c74e1da-bd67-4630-ae8c-5d9536b84955',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/398aae05-72e5-4289-a4c9-5f25139df22a/ugc/5ac06ffb-f638-4558-84ff-eff0c39af7bf/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd2ea7fd2-a013-4dd8-8493-217bed186561',
        firstName: 'Neesh',
        lastName: 'Riaz',
        email: 'neeshceo@gmail.com',
        instagramProfile: {
          handle: 'neesh__me',
          followers: 38346,
          engagementRate: 0.9330829812757523
        },
        tiktokProfile: {
          handle: 'neesh_me',
          followers: 36915,
          engagementRate: 9.55207994396863
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T21:52:10.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '85ecc2ff-9890-4949-9c90-4c8fe786d499',
      content: [
        {
          groupId: '2057cb82-d2b7-4579-9552-d4b78174951c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-02T15:46:37.008Z',

          createdAt: '2024-06-28T16:34:47.939Z',
          content: [
            {
              caption: '',

              id: '325421fe-2d5b-46ac-9c4f-57aa0f2e2e19',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85ecc2ff-9890-4949-9c90-4c8fe786d499/ugc/2057cb82-d2b7-4579-9552-d4b78174951c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'abe31ec7-e4c9-47e0-95b3-223959b4fb28',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85ecc2ff-9890-4949-9c90-4c8fe786d499/ugc/2057cb82-d2b7-4579-9552-d4b78174951c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2ae8bf56-fcd1-4f23-9244-9ce70837b725',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85ecc2ff-9890-4949-9c90-4c8fe786d499/ugc/2057cb82-d2b7-4579-9552-d4b78174951c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b2de181e-6259-4927-9b13-3d6a75d2f50d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T15:46:33.349Z',

          createdAt: '2024-06-28T17:39:22.206Z',
          content: [
            {
              caption: '',

              id: 'c728dfab-81fe-48ba-a484-0ec0b08a2b8e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85ecc2ff-9890-4949-9c90-4c8fe786d499/ugc/b2de181e-6259-4927-9b13-3d6a75d2f50d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '54960d91-674b-4ba6-a9e9-f708967fc83a',
        firstName: 'Chris',
        lastName: 'Parker',
        email: 'officialteamhlg@gmail.com',
        instagramProfile: {
          handle: 'hlg_cpthakidd',
          followers: 83041,
          engagementRate: 0.7030262159656073
        },
        tiktokProfile: {
          handle: 'thehlgshow',
          followers: 25734,
          engagementRate: 9.762532981530343
        },
        youtubeProfile: {
          handle: 'officialhlgshow'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T22:31:14.000Z',
        amountCents: 16236
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '43784453-6e60-4181-9697-b57930c3c408',
      content: [
        {
          groupId: 'c1ced5df-dcdf-4f6e-a5ea-debdd0134694',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-24T13:09:04.021Z',

          createdAt: '2024-06-21T10:00:18.494Z',
          content: [
            {
              caption: '',

              id: 'db3dab54-4226-4b8d-a426-23612b4d53c6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/43784453-6e60-4181-9697-b57930c3c408/ugc/c1ced5df-dcdf-4f6e-a5ea-debdd0134694/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b02a9ec3-087d-440e-8ee7-864fec3e6df0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/43784453-6e60-4181-9697-b57930c3c408/ugc/c1ced5df-dcdf-4f6e-a5ea-debdd0134694/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1bc842e8-de56-43a0-9010-f9972dbfe207',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/43784453-6e60-4181-9697-b57930c3c408/ugc/c1ced5df-dcdf-4f6e-a5ea-debdd0134694/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bf652ce8-3840-4e8b-8453-f702471a7441',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-26T06:16:24.847Z',

          createdAt: '2024-06-21T09:59:52.449Z',
          content: [
            {
              caption: '',

              id: '23023003-3053-4391-a932-8fa87d6281f3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/43784453-6e60-4181-9697-b57930c3c408/ugc/bf652ce8-3840-4e8b-8453-f702471a7441/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8294f1e7-f2fa-4921-9a8b-736791286f87',
        firstName: 'Michele',
        lastName: 'Fedecostante',
        email: 'mikynate88@live.it',
        instagramProfile: {
          handle: 'mikynate',
          followers: 102952,
          engagementRate: 3.2854145621260393
        },
        tiktokProfile: {
          handle: 'mikynate',
          followers: 261,
          engagementRate: 0.06959262727519279
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-11T23:22:06.000Z',
        amountCents: 21107
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '99910c93-29dc-4333-9700-e346ccde9c7a',
      content: [
        {
          groupId: 'd1d56529-afd7-4628-80e8-937e4136bfdd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-24T14:36:46.908Z',

          createdAt: '2024-06-21T14:26:02.157Z',
          content: [
            {
              caption: '',

              id: 'fc4e4467-e184-4e5c-ad0d-efa71a604fd7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/99910c93-29dc-4333-9700-e346ccde9c7a/ugc/d1d56529-afd7-4628-80e8-937e4136bfdd/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2446e00e-648f-4d1d-b13a-71b9ac8ef3ae',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/99910c93-29dc-4333-9700-e346ccde9c7a/ugc/d1d56529-afd7-4628-80e8-937e4136bfdd/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4df1fe11-ef6b-41f2-9be9-30c3c2ab375c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/99910c93-29dc-4333-9700-e346ccde9c7a/ugc/d1d56529-afd7-4628-80e8-937e4136bfdd/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd8dfcc39-ba96-4abb-bc0d-7309d39b24dd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T20:03:29.502Z',

          createdAt: '2024-06-24T14:54:45.283Z',
          content: [
            {
              caption: '',

              id: '2d5a761c-db1a-4c5e-8a55-e7416e28ce9a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/99910c93-29dc-4333-9700-e346ccde9c7a/ugc/d8dfcc39-ba96-4abb-bc0d-7309d39b24dd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '554ecf4a-b27e-4d23-a1e8-b9d7c3769e8d',
        firstName: 'Casey',
        lastName: 'Cohen',
        email: 'caseylauren.cohen@gmail.com',
        instagramProfile: {
          handle: 'caseylcohen',
          followers: 48336,
          engagementRate: 2.428624627606753
        },
        tiktokProfile: {
          handle: 'caseylcohen',
          followers: 267
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-12T14:02:20.000Z',
        amountCents: 16330
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8e786d18-e4aa-4eb0-a1d0-5a6ab5c58cf7',
      content: [
        {
          groupId: '85f38bd6-017e-4eea-9ce5-4958487a2768',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-21T14:10:06.189Z',

          createdAt: '2024-06-20T19:45:06.848Z',
          content: [
            {
              caption: '',

              id: '85b10442-54ee-4f7a-872a-8fcbe7e16c8a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e786d18-e4aa-4eb0-a1d0-5a6ab5c58cf7/ugc/85f38bd6-017e-4eea-9ce5-4958487a2768/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '10434835-c731-4bee-934b-413bea7cd8b1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e786d18-e4aa-4eb0-a1d0-5a6ab5c58cf7/ugc/85f38bd6-017e-4eea-9ce5-4958487a2768/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8a8f9347-f27c-4064-94bf-f6d570ceab5d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T23:34:51.740Z',

          createdAt: '2024-06-22T01:54:24.625Z',
          content: [
            {
              caption: '',

              id: '92df0e3f-d3a6-478e-9838-d078eebacf7a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e786d18-e4aa-4eb0-a1d0-5a6ab5c58cf7/ugc/8a8f9347-f27c-4064-94bf-f6d570ceab5d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd2ce7400-7757-4481-8f04-f38b315b1405',
        firstName: 'Chelsea',
        lastName: 'Greer',
        email: 'chelsea757@gmail.com',
        instagramProfile: {
          handle: 'chelsealizgreer',
          followers: 8586,
          engagementRate: 2.9315164220824594
        },
        tiktokProfile: {
          handle: 'chelsealizgreer',
          followers: 168,
          engagementRate: 2.5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T02:46:41.000Z',
        amountCents: 15899
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a66bbe93-c183-49e1-bab4-1133dbed0054',
      content: [],
      creator: {
        id: '58736121-4052-4709-9329-06eef849c6f2',
        firstName: 'Carisia',
        lastName: 'Gallegos',
        email: 'prettycarisia@gmail.com',
        instagramProfile: {
          handle: 'chaicari',
          followers: 2277,
          engagementRate: 6.741326306543698
        },
        tiktokProfile: {
          handle: 'haveyoumetcari',
          followers: 21552,
          engagementRate: 18.336630148103065
        },
        youtubeProfile: {
          handle: 'cari8124',
          followers: 9
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T02:56:43.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5b5a044a-a5b7-446e-9473-f1d10c195dcd',
      content: [
        {
          groupId: '279c117f-4fa9-48b2-bcaa-e8bb4852277c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-21T16:50:29.697Z',

          createdAt: '2024-06-20T19:21:43.254Z',
          content: [
            {
              caption: '',

              id: '673b7877-1813-4060-8964-9cc11659cb32',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5b5a044a-a5b7-446e-9473-f1d10c195dcd/ugc/279c117f-4fa9-48b2-bcaa-e8bb4852277c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'eff7b986-874a-4dac-8708-86a3182cbd83',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5b5a044a-a5b7-446e-9473-f1d10c195dcd/ugc/279c117f-4fa9-48b2-bcaa-e8bb4852277c/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '175837fd-b741-4482-bfd0-7feee11af7ec',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T22:38:54.574Z',

          createdAt: '2024-07-02T04:05:32.668Z',
          content: [
            {
              caption: '',

              id: '96ac8f3d-d9dd-4c1b-8c47-e37530b19f7b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5b5a044a-a5b7-446e-9473-f1d10c195dcd/ugc/175837fd-b741-4482-bfd0-7feee11af7ec/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e7a3d55d-a712-40d5-9760-0fe78bda9098',
        firstName: 'Kaila',
        lastName: 'DeRienzo',
        email: 'kderienzomedia@gmail.com',
        instagramProfile: {
          handle: 'kailaderienzo',
          followers: 3281,
          engagementRate: 2.575434318805242
        },
        tiktokProfile: {
          handle: 'kailaderienzo',
          followers: 1382,
          engagementRate: 6.683089355970652
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T02:56:43.000Z',
        amountCents: 15993
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '995763e6-bfe0-421f-a3b8-d9d246699e92',
      content: [],
      creator: {
        id: '5e620ac3-1fe0-43af-af6a-6f3a7d9c5d8f',
        firstName: 'Randall',
        lastName: 'Wade',
        email: 'saiyarangaming@gmail.com',
        instagramProfile: {
          handle: 'saiya.ran',
          followers: 279,
          engagementRate: 10.824372759856631
        },
        tiktokProfile: {
          handle: 'saiya.ran',
          followers: 6440,
          engagementRate: 4.348345478437462
        },
        youtubeProfile: {
          handle: 'saiyarangaming',
          followers: 2840
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T08:16:37.000Z',
        amountCents: 16199
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '36f3099b-1fb8-4155-893b-06517cce4403',
      content: [
        {
          groupId: '5c06a8cb-53b8-4ffd-8887-95c863032ba1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T11:21:33.762Z',

          createdAt: '2024-06-29T00:28:50.587Z',
          content: [
            {
              caption: '',

              id: 'a7a8c182-70f6-4e1b-be7d-4de47d91a9c7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36f3099b-1fb8-4155-893b-06517cce4403/ugc/5c06a8cb-53b8-4ffd-8887-95c863032ba1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2d59cb4d-1b58-45f9-82bc-2483e18f317c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-01T16:35:07.960Z',

          createdAt: '2024-06-28T20:52:22.465Z',
          content: [
            {
              caption: '',

              id: '8acb6b79-6957-49cd-afca-85530da11999',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36f3099b-1fb8-4155-893b-06517cce4403/ugc/2d59cb4d-1b58-45f9-82bc-2483e18f317c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'dbdd200f-8f38-4d7b-a60e-81f92c3e2d4a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36f3099b-1fb8-4155-893b-06517cce4403/ugc/2d59cb4d-1b58-45f9-82bc-2483e18f317c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '00c245e6-75d2-4daf-800e-8301fce50343',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36f3099b-1fb8-4155-893b-06517cce4403/ugc/2d59cb4d-1b58-45f9-82bc-2483e18f317c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f4ff3237-ff2a-4288-b3fd-0d4c63431065',
        firstName: 'Hassan',
        lastName: 'Chammout',
        email: 'chammouth@gmail.com',
        instagramProfile: {
          handle: 'hassan_chammout',
          followers: 611,
          engagementRate: 11.276595744680852
        },
        tiktokProfile: {
          handle: 'chammouth',
          followers: 11186,
          engagementRate: 2.2268174285420974
        },
        youtubeProfile: {
          handle: 'chammouth',
          followers: 164
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T08:36:14.000Z',
        amountCents: 15899
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '55d8bae7-a6eb-4592-ae06-48d1efaa8f7c',
      content: [
        {
          groupId: 'f98c8573-088f-4a6f-83a8-1ef08eb851b4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-25T20:03:49.805Z',

          createdAt: '2024-06-24T18:42:35.386Z',
          content: [
            {
              caption: '',

              id: '0e36d0b4-75c2-4413-bc5f-df4dd9274efe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55d8bae7-a6eb-4592-ae06-48d1efaa8f7c/ugc/f98c8573-088f-4a6f-83a8-1ef08eb851b4/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '597418c9-2b77-4ab6-8375-2db36b69fafd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55d8bae7-a6eb-4592-ae06-48d1efaa8f7c/ugc/f98c8573-088f-4a6f-83a8-1ef08eb851b4/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '40d98e92-2944-488d-a994-5b31b4f534b3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55d8bae7-a6eb-4592-ae06-48d1efaa8f7c/ugc/f98c8573-088f-4a6f-83a8-1ef08eb851b4/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '302b13cf-0815-4b8b-8c4a-72e6426d327a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T11:37:55.792Z',

          createdAt: '2024-06-29T18:21:36.253Z',
          content: [
            {
              caption: '',

              id: 'd568a9d5-9cff-4ffe-a3da-1c7011387ebe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55d8bae7-a6eb-4592-ae06-48d1efaa8f7c/ugc/302b13cf-0815-4b8b-8c4a-72e6426d327a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e0562f18-292d-48a5-a6a9-592955bc671a',
        firstName: 'Ashley',
        lastName: 'Ramos',
        email: 'ashley.atc@yahoo.com',
        instagramProfile: {
          handle: 'ashleeyy.ramos',
          followers: 1011,
          engagementRate: 4.24901185770751
        },
        tiktokProfile: {
          handle: 'ashleeyy.ramos',
          followers: 16050,
          engagementRate: 8.65313715788146
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T18:26:38.000Z',
        amountCents: 16124
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e07e45a5-f9d1-4128-92c3-8b17cd2178b0',
      content: [
        {
          groupId: '188222ad-499f-4a4e-9e09-2e1becb2e528',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-20T17:39:40.919Z',

          createdAt: '2024-06-19T19:13:52.126Z',
          content: [
            {
              caption: '',

              id: '7e87a51d-b427-454f-84a6-8065398232ff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e07e45a5-f9d1-4128-92c3-8b17cd2178b0/ugc/188222ad-499f-4a4e-9e09-2e1becb2e528/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '65469c54-168b-4e90-91d1-09a5f20e8beb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e07e45a5-f9d1-4128-92c3-8b17cd2178b0/ugc/188222ad-499f-4a4e-9e09-2e1becb2e528/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3958999f-9413-43d3-b614-0f290765ea11',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e07e45a5-f9d1-4128-92c3-8b17cd2178b0/ugc/188222ad-499f-4a4e-9e09-2e1becb2e528/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0c07f260-f796-4806-9803-6548a1758fee',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T17:39:34.496Z',

          createdAt: '2024-06-19T19:13:14.019Z',
          content: [
            {
              caption: '',

              id: 'fb92a922-5785-4b60-8b69-7d12a639ac80',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e07e45a5-f9d1-4128-92c3-8b17cd2178b0/ugc/0c07f260-f796-4806-9803-6548a1758fee/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f4b4654b-44c6-4311-9229-d64f682f25ea',
        firstName: 'Rabia',
        lastName: 'Terzioglu',
        email: 'rabiascookies@gmail.com',
        instagramProfile: {
          handle: 'lifeofpetitelady',
          followers: 48428,
          engagementRate: 4.6985215164780705
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T19:26:37.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6deeee0b-7382-4713-b97e-25bb7f1c6d4d',
      content: [
        {
          groupId: '917cd3c5-a55d-49f0-b793-00a4ea5bbeb0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T14:36:43.525Z',

          createdAt: '2024-06-24T04:23:03.213Z',
          content: [
            {
              caption: '',

              id: '4aa4724c-03c4-4005-9ed8-3690058ec4ec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6deeee0b-7382-4713-b97e-25bb7f1c6d4d/ugc/917cd3c5-a55d-49f0-b793-00a4ea5bbeb0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e48c745f-26cf-470d-b2a7-5c70ee4e190e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T11:24:12.996Z',

          createdAt: '2024-06-23T22:48:38.269Z',
          content: [
            {
              caption: '',

              id: '6265e9b7-66a2-4811-8219-4b906e20da05',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6deeee0b-7382-4713-b97e-25bb7f1c6d4d/ugc/e48c745f-26cf-470d-b2a7-5c70ee4e190e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c65d1d6f-e0f5-4112-9f7b-a56e126eda6c',
        firstName: 'Rachel',
        lastName: 'Thetsy',
        email: 'missmintytok@gmail.com',

        tiktokProfile: {
          handle: 'missmintyy',
          followers: 7160,
          engagementRate: 3.884424631585838
        },
        youtubeProfile: {
          handle: 'missminty9662',
          followers: 166
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T02:16:38.000Z',
        amountCents: 16161
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '805dcb2a-7ef0-43d3-ae37-c88db225dc87',
      content: [],
      creator: {
        id: 'b491d658-6df0-4287-a54e-ff24991464c6',
        firstName: 'Jessie',
        lastName: 'Leming',
        email: 'jess.fitnesstiktok@gmail.com',
        instagramProfile: {
          handle: 'jessie_leming_photography',
          followers: 145,
          engagementRate: 7.379310344827586
        },
        tiktokProfile: {
          handle: 'offbrandathlete',
          followers: 54567,
          engagementRate: 13.052631578947368
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T07:16:38.000Z',
        amountCents: 16649
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5a5d4fef-2f40-4c9d-a3bf-e8e91a63f92e',
      content: [
        {
          groupId: '8a4a5cb9-d2f5-4d46-af04-051b0e00e197',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T15:03:00.334Z',

          createdAt: '2024-06-22T19:00:54.406Z',
          content: [
            {
              caption: '',

              id: '5cba5f7d-a4c6-480f-b0df-921337e8222f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5a5d4fef-2f40-4c9d-a3bf-e8e91a63f92e/ugc/8a4a5cb9-d2f5-4d46-af04-051b0e00e197/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c8fe1b82-ad34-4fc4-bf25-a62f52387f54',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T15:03:03.845Z',

          createdAt: '2024-06-22T19:01:30.197Z',
          content: [
            {
              caption: '',

              id: 'c5704105-5217-4cf5-8a78-a22d1e2122d9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5a5d4fef-2f40-4c9d-a3bf-e8e91a63f92e/ugc/c8fe1b82-ad34-4fc4-bf25-a62f52387f54/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f5a335a0-84f3-45ec-9a25-52cf61d4934d',
        firstName: 'Shinya',
        lastName: 'Kadono',
        email: 'shinyasoccer10@gmail.com',
        instagramProfile: {
          handle: 'shinyakadono',
          followers: 31944,
          engagementRate: 7.391059353869271
        },
        tiktokProfile: {
          handle: 'shinyakadono',
          followers: 153384,
          engagementRate: 6.131301485091078
        },
        youtubeProfile: {
          handle: 'shinsjourneysk'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T10:25:39.000Z',
        amountCents: 16161
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7c976a9f-f7e3-4b8d-b60d-671dd8c69df3',
      content: [
        {
          groupId: '305893df-6e2a-446d-bb72-91bdf4600df7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-21T12:07:11.523Z',

          createdAt: '2024-06-21T02:47:04.962Z',
          content: [
            {
              caption: '',

              id: '3ef9aa09-207f-47be-8f9f-e22c8bad60ba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7c976a9f-f7e3-4b8d-b60d-671dd8c69df3/ugc/305893df-6e2a-446d-bb72-91bdf4600df7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a09f665d-1770-4ada-ac9a-2ad351f06c2d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-21T12:07:15.506Z',

          createdAt: '2024-06-21T01:54:17.865Z',
          content: [
            {
              caption: '',

              id: 'b59cd2b0-90ea-4dff-85e2-a000206fbbcc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7c976a9f-f7e3-4b8d-b60d-671dd8c69df3/ugc/a09f665d-1770-4ada-ac9a-2ad351f06c2d/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '14e36035-214b-4899-bcf7-a191904d47a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7c976a9f-f7e3-4b8d-b60d-671dd8c69df3/ugc/a09f665d-1770-4ada-ac9a-2ad351f06c2d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd3d5359c-30ec-44f2-bf14-fc6b4902a771',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7c976a9f-f7e3-4b8d-b60d-671dd8c69df3/ugc/a09f665d-1770-4ada-ac9a-2ad351f06c2d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'adfaa3e9-4c64-43d5-adb8-f8d399e1a51f',
        firstName: 'Miguel',
        lastName: 'Pagan',
        email: 'wordsbymiguel@gmail.com',
        instagramProfile: {
          handle: 'wordsbymiguel',
          followers: 15874,
          engagementRate: 0.9525009449414136
        },
        tiktokProfile: {
          handle: 'wordsbymiguel',
          followers: 27020,
          engagementRate: 15.94140520966733
        },
        youtubeProfile: {
          handle: 'miguelandyvonne'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T12:22:59.000Z',
        amountCents: 16293
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e0cdf83a-faa5-46fa-a97c-d9673fc07b83',
      content: [
        {
          groupId: '3b1d9de0-1f8b-4f02-bf5a-7d82fa09fe09',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T03:07:40.019Z',

          createdAt: '2024-06-23T02:02:20.258Z',
          content: [
            {
              caption: '',

              id: '141c503c-4166-4374-9186-0d48ecc0c628',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0cdf83a-faa5-46fa-a97c-d9673fc07b83/ugc/3b1d9de0-1f8b-4f02-bf5a-7d82fa09fe09/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '73c8e2b2-80d9-4bd5-b2bf-d894b1a4bc06',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T13:40:39.636Z',

          createdAt: '2024-06-23T01:53:21.805Z',
          content: [
            {
              caption: '',

              id: '9ae8933e-e139-44f8-9484-a47506672234',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0cdf83a-faa5-46fa-a97c-d9673fc07b83/ugc/73c8e2b2-80d9-4bd5-b2bf-d894b1a4bc06/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '687309d9-cb89-414b-8eac-75967eabb762',
        firstName: 'Brittany',
        lastName: 'Wilbourne',
        email: 'coldcoffeegrind@gmail.com',
        instagramProfile: {
          handle: 'brittanywilbourne',
          followers: 7318,
          engagementRate: 0.22410494670675046
        },
        tiktokProfile: {
          handle: 'brittanywilbourne',
          followers: 81,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'coldcoffeegrind'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T17:03:59.000Z',
        amountCents: 15794
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '844834c7-44e6-4054-92fc-b06d78a65901',
      content: [
        {
          groupId: '244aabd8-0466-460f-9619-5cccd6fb989e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-30T02:01:10.982Z',

          createdAt: '2024-08-29T13:15:35.180Z',
          content: [
            {
              caption: '',

              id: '9c896268-cc12-41c4-a01a-30039c976027',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/844834c7-44e6-4054-92fc-b06d78a65901/ugc/244aabd8-0466-460f-9619-5cccd6fb989e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd5993eea-19ad-484b-85be-712ef078c3d2',
        firstName: 'Cinthia',
        lastName: 'Rivera',
        email: 'riveracinthia16@gmail.com',
        instagramProfile: {
          handle: 'riveracinthia_lifestyled',
          followers: 5127,
          engagementRate: 4.154476301930954
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T17:44:33.000Z',
        amountCents: 15974
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6762d294-4ba1-4dd7-a724-580796b1ecd7',
      content: [
        {
          groupId: '5ca0082b-7c28-4590-bfe2-743f2438aff3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T21:29:37.057Z',

          createdAt: '2024-06-30T22:11:02.238Z',
          content: [
            {
              caption: '',

              id: 'c7be1216-637a-452b-b385-cc9f100c5db9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6762d294-4ba1-4dd7-a724-580796b1ecd7/ugc/5ca0082b-7c28-4590-bfe2-743f2438aff3/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'decdfe3c-6c06-4daa-a6c1-22628ca996f5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T14:34:04.052Z',

          createdAt: '2024-06-30T22:16:32.080Z',
          content: [
            {
              caption: '',

              id: 'e64ac639-79a8-4578-95bd-0121066209ad',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6762d294-4ba1-4dd7-a724-580796b1ecd7/ugc/decdfe3c-6c06-4daa-a6c1-22628ca996f5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6a283b94-19da-464b-baa1-58d62557cc10',
        firstName: 'Jose',
        lastName: 'Barraza',
        email: 'jbarrazajr16@yahoo.com',
        instagramProfile: {
          handle: 'shotsby.hundo',
          followers: 8540,
          engagementRate: 3.6768149882903978
        },
        tiktokProfile: {
          handle: 'shotsby.hundo',
          followers: 4307,
          engagementRate: 16.876906564230033
        },
        youtubeProfile: {
          handle: 'hundox',
          followers: 1200
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T19:43:58.000Z',
        amountCents: 16289
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bf7bc2e1-38ef-4879-9521-e388aff5fc71',
      content: [
        {
          groupId: '13bd1d18-4e5d-41be-85c3-2a29abbf2548',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-20T06:19:02.485Z',

          createdAt: '2024-06-18T03:00:59.327Z',
          content: [
            {
              caption: '',

              id: '962f280f-2faf-4f79-a8c1-ba1722f066f1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bf7bc2e1-38ef-4879-9521-e388aff5fc71/ugc/13bd1d18-4e5d-41be-85c3-2a29abbf2548/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ccc9b932-4942-43e2-ac67-f45ece7f48d4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bf7bc2e1-38ef-4879-9521-e388aff5fc71/ugc/13bd1d18-4e5d-41be-85c3-2a29abbf2548/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a525328a-b00d-438d-95c5-1f2737b41e59',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bf7bc2e1-38ef-4879-9521-e388aff5fc71/ugc/13bd1d18-4e5d-41be-85c3-2a29abbf2548/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4c751a59-b8da-45c0-9e31-23649c4ccace',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T06:18:58.578Z',

          createdAt: '2024-06-19T00:08:21.229Z',
          content: [
            {
              caption: '',

              id: '247a0102-b635-4479-8796-70f4255e934f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bf7bc2e1-38ef-4879-9521-e388aff5fc71/ugc/4c751a59-b8da-45c0-9e31-23649c4ccace/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f0463cc7-3c3b-4994-9cbf-d1105be03fa2',
        firstName: 'Marissa',
        lastName: 'Crawley',
        email: 'marissacrawley@outlook.com',
        instagramProfile: {
          handle: 'marissalately',
          followers: 405,
          engagementRate: 3.382716049382716
        },
        tiktokProfile: {
          handle: 'marissalately',
          followers: 4818,
          engagementRate: 17.124435668851696
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T21:40:15.000Z',
        amountCents: 16236
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8c7e414e-2950-4c37-ac30-9f46fd722545',
      content: [
        {
          groupId: '889732ee-7986-4c8f-8da5-b6a32981c53b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T16:25:50.044Z',

          createdAt: '2024-06-28T13:16:05.848Z',
          content: [
            {
              caption: '',

              id: '93cb0ee7-f5b2-452e-bde7-9d5c040f6b05',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c7e414e-2950-4c37-ac30-9f46fd722545/ugc/889732ee-7986-4c8f-8da5-b6a32981c53b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b2f73baa-9a90-4dff-8b84-aeeada739057',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-25T19:53:52.703Z',

          createdAt: '2024-06-24T11:38:16.300Z',
          content: [
            {
              caption: '',

              id: '53a2d357-3a13-4394-b329-393b8766995b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c7e414e-2950-4c37-ac30-9f46fd722545/ugc/b2f73baa-9a90-4dff-8b84-aeeada739057/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f2a89c6a-567b-4d50-9ff5-8941d3423b81',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c7e414e-2950-4c37-ac30-9f46fd722545/ugc/b2f73baa-9a90-4dff-8b84-aeeada739057/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2db88f6d-cc5d-49de-949f-c5e1238675e9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c7e414e-2950-4c37-ac30-9f46fd722545/ugc/b2f73baa-9a90-4dff-8b84-aeeada739057/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a7e9061c-39f3-46a2-9033-fa78c852ff6d',
        firstName: 'Shelby',
        lastName: 'Reyes',
        email: 'aislareyescollabs@gmail.com',
        instagramProfile: {
          handle: 'aisla.sky',
          followers: 13392,
          engagementRate: 1.2753882915173238
        },

        youtubeProfile: {
          handle: 'aislasky',
          followers: 29
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T22:46:39.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5acb375d-e87e-4cec-a800-23d3c74aa933',
      content: [
        {
          groupId: '8fbab505-24a6-46f4-8ed3-ea59051e3348',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-17T15:06:34.713Z',

          createdAt: '2024-07-15T19:53:52.872Z',
          content: [
            {
              caption: '',

              id: '789acb08-84c5-4698-974b-782e65abc4c5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5acb375d-e87e-4cec-a800-23d3c74aa933/ugc/8fbab505-24a6-46f4-8ed3-ea59051e3348/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '98f01438-3714-4d6f-9c62-b2faa817ad7f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-17T15:06:30.074Z',

          createdAt: '2024-07-15T20:31:12.438Z',
          content: [
            {
              caption: '',

              id: '78599d65-00ff-4850-87f0-709626f539f1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5acb375d-e87e-4cec-a800-23d3c74aa933/ugc/98f01438-3714-4d6f-9c62-b2faa817ad7f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '73cd0637-f504-4dbf-ba68-2d640ae2a3fb',
        firstName: 'Kreena',
        lastName: 'Parikh',
        email: 'kreenaparikh@gmail.com',
        instagramProfile: {
          handle: 'life_of_kreena',
          followers: 6128,
          engagementRate: 1.6628590078328984
        },
        tiktokProfile: {
          handle: 'kreeniebeenie',
          followers: 1138,
          engagementRate: 2.815093347888933
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-16T05:45:04.000Z',
        amountCents: 24793
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eca2d32e-d6fa-4ab9-b598-5ee251f33f23',
      content: [
        {
          groupId: '7f05c291-5a02-4bd7-b24e-349167a4a0df',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T05:26:51.997Z',

          createdAt: '2024-06-24T17:19:36.436Z',
          content: [
            {
              caption: '',

              id: 'd4780003-be32-4421-922f-6a18e6dbbd0e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eca2d32e-d6fa-4ab9-b598-5ee251f33f23/ugc/7f05c291-5a02-4bd7-b24e-349167a4a0df/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '78a9a0cb-7bc3-45cb-844e-533954cfa0f9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-26T05:26:56.588Z',

          createdAt: '2024-06-24T17:18:58.433Z',
          content: [
            {
              caption: '',

              id: 'c3cdfcd5-613d-428f-a0f8-9e012fa06352',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eca2d32e-d6fa-4ab9-b598-5ee251f33f23/ugc/78a9a0cb-7bc3-45cb-844e-533954cfa0f9/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6998dd3b-9c5c-48c1-a2a7-220412c34151',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eca2d32e-d6fa-4ab9-b598-5ee251f33f23/ugc/78a9a0cb-7bc3-45cb-844e-533954cfa0f9/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6fff94a5-2ad8-4f7f-bb9d-9f543e19ba1c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eca2d32e-d6fa-4ab9-b598-5ee251f33f23/ugc/78a9a0cb-7bc3-45cb-844e-533954cfa0f9/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd6647fb1-2908-4e39-9cf8-4f96e61b9590',
        firstName: 'Vonnie',
        lastName: 'Norrgard',
        email: 'vonnieugc@gmail.com',
        instagramProfile: {
          handle: 'vonnienono',
          followers: 4553,
          engagementRate: 0.5381067428069405
        },
        tiktokProfile: {
          handle: 'vonnienono',
          followers: 30508,
          engagementRate: 5.5945517316401485
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-16T06:26:06.000Z',
        amountCents: 16278
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '77d936b9-c753-4bc9-8470-03208850e717',
      content: [
        {
          groupId: '14865896-3377-4d5b-a628-d7c0683ea2da',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-01T16:29:04.458Z',

          createdAt: '2024-06-28T18:13:21.351Z',
          content: [
            {
              caption: '',

              id: '4044de74-df9b-4619-8577-963518d7b36d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/77d936b9-c753-4bc9-8470-03208850e717/ugc/14865896-3377-4d5b-a628-d7c0683ea2da/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '063f1a56-09c9-4590-87b5-973d2df5ac0c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/77d936b9-c753-4bc9-8470-03208850e717/ugc/14865896-3377-4d5b-a628-d7c0683ea2da/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'eb1385bf-14f1-4c43-9e09-8db554771ae5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/77d936b9-c753-4bc9-8470-03208850e717/ugc/14865896-3377-4d5b-a628-d7c0683ea2da/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fd6e3f22-602a-4ded-a807-06fb92712516',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T12:18:48.714Z',

          createdAt: '2024-06-29T17:36:58.665Z',
          content: [
            {
              caption: '',

              id: '29a40593-d049-40c2-b6aa-4d96a7395632',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/77d936b9-c753-4bc9-8470-03208850e717/ugc/fd6e3f22-602a-4ded-a807-06fb92712516/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'be10fc95-bc66-4217-ba73-6d25bfa62547',
        firstName: 'Davis',
        lastName: 'Mallory',
        email: 'davis.mallory@gmail.com',
        instagramProfile: {
          handle: 'davismallory',
          followers: 28988,
          engagementRate: 0.25941769007865323
        },
        tiktokProfile: {
          handle: 'davismallory',
          followers: 3190,
          engagementRate: 1.9601394964667014
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-16T06:36:37.000Z',
        amountCents: 16386
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9748512d-4dee-4c13-8e0c-6fea2e62c40b',
      content: [
        {
          groupId: '67898bb8-ecab-49ab-8013-5ba1ca2da29a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T06:21:10.149Z',

          createdAt: '2024-07-03T05:25:49.022Z',
          content: [
            {
              caption: '',

              id: '345b337e-3afb-4af4-8a3a-9afc0190bc60',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9748512d-4dee-4c13-8e0c-6fea2e62c40b/ugc/67898bb8-ecab-49ab-8013-5ba1ca2da29a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '93abd3a3-2616-42fa-a54d-9be28b771bce',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-03T04:52:03.401Z',

          createdAt: '2024-07-01T04:11:03.163Z',
          content: [
            {
              caption: '',

              id: '0ec32d2e-ef25-421e-acc3-c4cad59973b9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9748512d-4dee-4c13-8e0c-6fea2e62c40b/ugc/93abd3a3-2616-42fa-a54d-9be28b771bce/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b8b0fa58-929f-4e1d-9491-5a4d116c6e9c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9748512d-4dee-4c13-8e0c-6fea2e62c40b/ugc/93abd3a3-2616-42fa-a54d-9be28b771bce/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '17171477-2743-425a-8792-0e643d8ddf4a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9748512d-4dee-4c13-8e0c-6fea2e62c40b/ugc/93abd3a3-2616-42fa-a54d-9be28b771bce/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '29a3a9ed-4e8e-4e1e-aede-165610fc422f',
        firstName: 'Ashley',
        lastName: 'Bourne',
        email: 'ashlopez92@gmail.com',
        instagramProfile: {
          handle: 'smashleybourne',
          followers: 12453,
          engagementRate: 2.728659760700233
        },
        tiktokProfile: {
          handle: 'smashleybourne',
          followers: 563,
          engagementRate: 14.76510067114094
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-16T12:13:02.000Z',
        amountCents: 16161
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'be3a0f33-169c-4a94-a209-e19825855e4e',
      content: [],
      creator: {
        id: '47f1c33c-09d2-417f-be2f-e9e2b758ea19',
        firstName: 'Mariana',
        lastName: 'Komula',
        email: 'bohowildflower.co@gmail.com',
        instagramProfile: {
          handle: 'thebohowildflower',
          followers: 6021,
          engagementRate: 1.4499252615844545
        },
        tiktokProfile: {
          handle: 'thebohowildflower',
          followers: 2437,
          engagementRate: 4.081632653061225
        },
        youtubeProfile: {
          handle: 'thebohowildflower'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-16T14:47:01.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '77f23c41-139c-42b1-8ed9-7b3616931a01',
      content: [
        {
          groupId: '08c26d2f-6c03-49b6-bc43-85bf839ea9e5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-09T21:33:20.073Z',

          createdAt: '2024-06-26T19:25:54.195Z',
          content: [
            {
              caption: '',

              id: 'dbf1860d-f343-4cd8-bee0-daba9c99ca72',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/77f23c41-139c-42b1-8ed9-7b3616931a01/ugc/08c26d2f-6c03-49b6-bc43-85bf839ea9e5/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1aaf1ac2-94ab-456e-aba7-dbc08500d174',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/77f23c41-139c-42b1-8ed9-7b3616931a01/ugc/08c26d2f-6c03-49b6-bc43-85bf839ea9e5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd42bc790-ec84-4ecc-9482-ac6dabedb41b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T21:33:15.291Z',

          createdAt: '2024-06-26T13:11:13.598Z',
          content: [
            {
              caption: '',

              id: 'e50365b5-aa82-4a55-a3f6-26d3b7a77c5f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/77f23c41-139c-42b1-8ed9-7b3616931a01/ugc/d42bc790-ec84-4ecc-9482-ac6dabedb41b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9e074feb-8fd5-49a4-a9ae-57d657f77a21',
        firstName: 'Lorena',
        lastName: 'Vargas',
        email: 'lorenaagvargas@gmail.com',
        instagramProfile: {
          handle: 'lorenaa.vv',
          followers: 9531,
          engagementRate: 0.7659217290945337
        },
        tiktokProfile: {
          handle: 'lorenaaandbabies',
          followers: 127,
          engagementRate: 10
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-16T17:06:17.000Z',
        amountCents: 16161
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1ed7f71d-68fc-4ce3-b866-4aeb59d80e09',
      content: [
        {
          groupId: 'd46e9ba3-7045-4777-abdc-7d3fffb53ae4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-06T15:02:38.342Z',

          createdAt: '2024-07-06T04:44:29.548Z',
          content: [
            {
              caption: '',

              id: 'd371ac63-e496-4524-936e-d2c582f6e76a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ed7f71d-68fc-4ce3-b866-4aeb59d80e09/ugc/d46e9ba3-7045-4777-abdc-7d3fffb53ae4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '9025e61c-d1aa-4906-8372-7bafce82d9e0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-06T02:35:10.876Z',

          createdAt: '2024-07-06T04:45:16.315Z',
          content: [
            {
              caption: '',

              id: 'd5bbe2cb-f850-46c1-b4d1-d7adc3179660',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ed7f71d-68fc-4ce3-b866-4aeb59d80e09/ugc/9025e61c-d1aa-4906-8372-7bafce82d9e0/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '086fd863-8c5a-4164-b956-ba0eef839cc7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ed7f71d-68fc-4ce3-b866-4aeb59d80e09/ugc/9025e61c-d1aa-4906-8372-7bafce82d9e0/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b949c727-842a-416a-a427-ffc67e5bd922',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ed7f71d-68fc-4ce3-b866-4aeb59d80e09/ugc/9025e61c-d1aa-4906-8372-7bafce82d9e0/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a64ffceb-9fcd-4935-9612-3ab683d23b88',
        firstName: 'Porshe',
        lastName: 'Minor',
        email: 'porshepower@aol.com',
        instagramProfile: {
          handle: 'dr_porshe911',
          followers: 10115,
          engagementRate: 0.7503707365299062
        },
        tiktokProfile: {
          handle: 'dr_porshe911',
          followers: 268,
          engagementRate: 7.909604519774012
        },
        youtubeProfile: {
          handle: 'porshesperspective',
          followers: 120
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-16T21:36:42.000Z',
        amountCents: 16640
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '862893f8-7464-4e1e-bf2e-e605f5d22efb',
      content: [
        {
          groupId: '0fdbd08f-ac0d-438b-8910-58c2d68204b6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-03T19:27:56.335Z',

          createdAt: '2024-07-01T21:25:30.243Z',
          content: [
            {
              caption: '',

              id: 'f7bc5e4e-3062-46ba-9412-8346a4c99b5f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/862893f8-7464-4e1e-bf2e-e605f5d22efb/ugc/0fdbd08f-ac0d-438b-8910-58c2d68204b6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4e711a7e-7087-4a12-9e11-975bcfb04401',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/862893f8-7464-4e1e-bf2e-e605f5d22efb/ugc/0fdbd08f-ac0d-438b-8910-58c2d68204b6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cdec2aa5-f465-4df1-95ff-4019b2fcd79a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T19:27:52.704Z',

          createdAt: '2024-07-01T21:53:44.258Z',
          content: [
            {
              caption: '',

              id: '1fe68b1b-d45c-40e4-aaf8-3ce83d485a48',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/862893f8-7464-4e1e-bf2e-e605f5d22efb/ugc/cdec2aa5-f465-4df1-95ff-4019b2fcd79a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '98264f43-3f54-461d-b453-5524d774920e',
        firstName: 'Katie',
        lastName: 'Ensminger',
        email: 'katieannensminger@gmail.com',
        instagramProfile: {
          handle: 'katieannensminger',
          followers: 5464,
          engagementRate: 0.8418740849194729
        },
        tiktokProfile: {
          handle: 'katieannensminger',
          followers: 44
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-17T01:55:03.000Z',
        amountCents: 15951
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '457dd9f0-e742-44da-862e-abf448a3fb9c',
      content: [],
      creator: {
        id: 'e8278fdd-f93f-490d-a1e7-8f4bf9ad378c',
        firstName: 'Sierra',
        lastName: 'Herd',
        email: 'sierraherdcontent@gmail.com',
        instagramProfile: {
          handle: 'sierra_herd',
          followers: 1991,
          engagementRate: 5.3016351358892795
        },
        tiktokProfile: {
          handle: 'sierradenaeee',
          followers: 3293,
          engagementRate: 5.948275862068965
        },
        youtubeProfile: {
          handle: 'sierradherd',
          followers: 87
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-17T06:04:17.000Z',
        amountCents: 16349
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '42b83a67-cbca-4a0e-9519-1b58973ce79e',
      content: [
        {
          groupId: 'f03cbbec-5a09-4941-9e7c-8a27994a6656',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-28T14:48:11.944Z',

          createdAt: '2024-06-27T22:22:14.248Z',
          content: [
            {
              caption: '',

              id: 'a19db793-4574-450e-b458-94ccdb9ecb92',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/42b83a67-cbca-4a0e-9519-1b58973ce79e/ugc/f03cbbec-5a09-4941-9e7c-8a27994a6656/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b243fc46-4a15-423a-8f80-b018f0bdbde1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/42b83a67-cbca-4a0e-9519-1b58973ce79e/ugc/f03cbbec-5a09-4941-9e7c-8a27994a6656/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4f3c5320-bc6e-4080-95bf-ca4459c50267',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/42b83a67-cbca-4a0e-9519-1b58973ce79e/ugc/f03cbbec-5a09-4941-9e7c-8a27994a6656/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '55d2dc2e-6a86-4892-aff1-6a2c16aa2fa9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T14:48:08.853Z',

          createdAt: '2024-06-27T22:20:03.839Z',
          content: [
            {
              caption: '',

              id: 'cc519eab-c7f3-4c9e-9cc0-374caec4c0de',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/42b83a67-cbca-4a0e-9519-1b58973ce79e/ugc/55d2dc2e-6a86-4892-aff1-6a2c16aa2fa9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '64a80d6a-9f09-4c5d-ab07-9ddb51765fb2',
        firstName: 'Denise',
        lastName: 'Ferreira',
        email: 'denisecollabs@gmail.com',
        instagramProfile: {
          handle: 'deniiseferreiira',
          followers: 13628,
          engagementRate: 0.52245377164661
        },
        tiktokProfile: {
          handle: 'deniise.ferreira',
          followers: 5989,
          engagementRate: 7.83479733781029
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-17T08:22:10.000Z',
        amountCents: 16311
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '04a52439-a449-4a36-8955-0ad5851d4e79',
      content: [
        {
          groupId: '6f74a068-9ed4-4bba-967a-6bfd17cf781d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T17:55:15.680Z',

          createdAt: '2024-06-20T09:27:15.557Z',
          content: [
            {
              caption: '',

              id: '3c1154d3-7010-4c4b-b0b9-d40f52283f63',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/04a52439-a449-4a36-8955-0ad5851d4e79/ugc/6f74a068-9ed4-4bba-967a-6bfd17cf781d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f99e324f-87b8-41de-942a-dd3a39143d36',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T04:29:03.146Z',

          createdAt: '2024-06-21T07:53:45.371Z',
          content: [
            {
              caption: '',

              id: '4f478e3d-1191-4117-b8cb-db36d20c2cc3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/04a52439-a449-4a36-8955-0ad5851d4e79/ugc/f99e324f-87b8-41de-942a-dd3a39143d36/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '10dd1d26-2f9b-4885-a467-dd629dfcac86',
        firstName: 'Julia',
        lastName: 'Arreola',
        email: 'juliaarreola22@gmail.com',

        tiktokProfile: {
          handle: 'juliaarreolaa',
          followers: 7951,
          engagementRate: 13.910226649221288
        },
        youtubeProfile: {
          handle: 'juliaarreolaa',
          followers: 41
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-17T09:35:05.000Z',
        amountCents: 16251
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e472dbc0-684a-40da-9c4b-a809b7fca8bf',
      content: [
        {
          groupId: '8d7bcbb7-2f8b-4e2f-9cfb-ac6cf1c11375',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T14:54:37.260Z',

          createdAt: '2024-06-24T00:51:30.599Z',
          content: [
            {
              caption: '',

              id: '0d1d1460-c5e1-4b3a-9e52-8b37879bbd81',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e472dbc0-684a-40da-9c4b-a809b7fca8bf/ugc/8d7bcbb7-2f8b-4e2f-9cfb-ac6cf1c11375/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'badba4d3-fc90-470b-a504-10394e5099b6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-24T14:54:41.191Z',

          createdAt: '2024-06-23T22:48:23.238Z',
          content: [
            {
              caption: '',

              id: '717f2a83-fbbb-4051-97fb-9f453fb6b304',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e472dbc0-684a-40da-9c4b-a809b7fca8bf/ugc/badba4d3-fc90-470b-a504-10394e5099b6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '200e7912-dbd1-43d2-a775-a1d71dc3752b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e472dbc0-684a-40da-9c4b-a809b7fca8bf/ugc/badba4d3-fc90-470b-a504-10394e5099b6/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ff443a03-cb34-4e39-813e-3eaca1a72b51',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e472dbc0-684a-40da-9c4b-a809b7fca8bf/ugc/badba4d3-fc90-470b-a504-10394e5099b6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '35e9e43c-4536-4e0c-8913-1cb7ec1d72fe',
        firstName: 'Hilary',
        lastName: 'Griffin',
        email: 'hello@hilarygriffin.com',
        instagramProfile: {
          handle: 'hilary.griffin',
          followers: 3493,
          engagementRate: 1.002004008016032
        },
        tiktokProfile: {
          handle: 'hilary.griffin',
          followers: 394,
          engagementRate: 2.6683608640406606
        },
        youtubeProfile: {
          handle: 'hilary.griffin',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-17T15:06:43.000Z',
        amountCents: 17104
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a5ad49ca-295d-4df3-8a0c-d0916192d37c',
      content: [],
      creator: {
        id: 'b07d5a7b-f97e-40af-a8fa-e91548cd506a',
        firstName: 'Joseph',
        lastName: 'Hammond',
        email: 'fnedior@gmail.com',
        instagramProfile: {
          handle: 'fnedior',
          followers: 33176,
          engagementRate: 0.6785025319508078
        },
        tiktokProfile: {
          handle: '24kdior24k',
          followers: 8662,
          engagementRate: 15.632458233890215
        },
        youtubeProfile: {
          handle: 'fnedior',
          followers: 804
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-17T17:13:14.000Z',
        amountCents: 16261
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1b99070f-0dcd-4097-9183-2d3865fb7fdf',
      content: [
        {
          groupId: '80c73c5e-8031-4515-8a71-5286cf96d78c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-18T15:54:39.725Z',

          createdAt: '2024-07-15T04:28:59.119Z',
          content: [
            {
              caption: '',

              id: '46b1fe4a-5af8-40bc-a014-0d39561f0279',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b99070f-0dcd-4097-9183-2d3865fb7fdf/ugc/80c73c5e-8031-4515-8a71-5286cf96d78c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '567720c2-baff-4fae-8c9f-111dd0a01483',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-18T15:54:48.278Z',

          createdAt: '2024-07-15T03:40:04.232Z',
          content: [
            {
              caption: '',

              id: '5af1c705-a80a-408d-ab84-4cc125d2c4e7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b99070f-0dcd-4097-9183-2d3865fb7fdf/ugc/567720c2-baff-4fae-8c9f-111dd0a01483/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd4ccd593-a775-4d43-9786-f665ef8fcb9f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b99070f-0dcd-4097-9183-2d3865fb7fdf/ugc/567720c2-baff-4fae-8c9f-111dd0a01483/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5cfb513b-2ade-4168-86b8-896e135459fe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b99070f-0dcd-4097-9183-2d3865fb7fdf/ugc/567720c2-baff-4fae-8c9f-111dd0a01483/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6e47a69a-85a6-4666-bfa6-5e3166d43e78',
        firstName: 'Feraz',
        lastName: 'Helo',
        email: 'reanneferas@gmail.com',
        instagramProfile: {
          handle: 'ferazmusic',
          followers: 4741,
          engagementRate: 6.9352457287492095
        },
        tiktokProfile: {
          handle: 'ferazmusic',
          followers: 658,
          engagementRate: 12.530413625304138
        },
        youtubeProfile: {
          handle: 'ferazmusic',
          followers: 411
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-17T18:25:43.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5a51a94a-bd26-405a-a461-7c1f0af1c197',
      content: [],
      creator: {
        id: '352a7cdd-b3fd-42aa-af0e-dfcca983ad5d',
        firstName: 'Sydney',
        lastName: 'Bailey',
        email: 'sydney.collaboration@gmail.com',
        instagramProfile: {
          handle: 'sydney_bailey',
          followers: 3878,
          engagementRate: 1.0288808664259927
        },
        tiktokProfile: {
          handle: 'sydney__bailey',
          followers: 2365,
          engagementRate: 10.66617213849635
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-17T22:56:18.000Z',
        amountCents: 16386
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e8d29f4b-a523-4fb5-8ad3-9aedfb84a4da',
      content: [
        {
          groupId: 'e5c41716-a679-45dc-9e0e-6e6ad984daf5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T12:16:41.590Z',

          createdAt: '2024-06-21T21:00:18.226Z',
          content: [
            {
              caption: '',

              id: 'c44aa5ba-94d3-4765-b2c2-7b4d0a13641f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e8d29f4b-a523-4fb5-8ad3-9aedfb84a4da/ugc/e5c41716-a679-45dc-9e0e-6e6ad984daf5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '06bf7c76-30fa-4a4e-b49e-e857ae3f2879',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T12:16:44.657Z',

          createdAt: '2024-06-21T21:23:14.359Z',
          content: [
            {
              caption: '',

              id: '2e0eeb1b-cdb4-4b32-9722-7b5125db16e0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e8d29f4b-a523-4fb5-8ad3-9aedfb84a4da/ugc/06bf7c76-30fa-4a4e-b49e-e857ae3f2879/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a7f64168-11db-469e-9f1a-0c1bd3112ba0',
        firstName: 'Josiah',
        lastName: 'Capaci',
        email: 'jotheshow@yahoo.com',
        instagramProfile: {
          handle: 'jotheshow',
          followers: 10127,
          engagementRate: 1.597215364866199
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T00:15:32.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7d43166b-fd03-4948-9d7d-ee3978e1a119',
      content: [],
      creator: {
        id: '9b083207-c362-460e-aaa2-b076a8e090b3',
        firstName: 'Carlos',
        lastName: 'Conde',
        email: 'carlosc@rafaelbeats.com',
        instagramProfile: {
          handle: 'iamrafaelbeats',
          followers: 16157,
          engagementRate: 0.27542241752800645
        },
        tiktokProfile: {
          handle: 'rafaelbeats',
          followers: 169
        },
        youtubeProfile: {
          handle: 'rafaelbeats',
          followers: 327
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T01:16:20.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a4f36836-411d-47aa-9dc7-28aeea03ac42',
      content: [
        {
          groupId: '674f6b1e-2b05-4784-b218-0b6df2396f36',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-27T11:16:16.483Z',

          createdAt: '2024-06-24T21:41:43.960Z',
          content: [
            {
              caption: '',

              id: '875830fd-59d1-410c-a724-a42673bd4886',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4f36836-411d-47aa-9dc7-28aeea03ac42/ugc/674f6b1e-2b05-4784-b218-0b6df2396f36/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b3576482-7f6a-4b2e-9b15-25439ace98ff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4f36836-411d-47aa-9dc7-28aeea03ac42/ugc/674f6b1e-2b05-4784-b218-0b6df2396f36/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4c2a3c67-116c-4aee-9aa3-2a45602a3bd4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4f36836-411d-47aa-9dc7-28aeea03ac42/ugc/674f6b1e-2b05-4784-b218-0b6df2396f36/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4da3afc2-cad9-4c2a-82ff-cc7d9d80ca61',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-27T11:16:10.745Z',

          createdAt: '2024-06-24T21:41:18.606Z',
          content: [
            {
              caption: '',

              id: 'b3a9a68a-b3bf-4333-802b-3d22f245ec5a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4f36836-411d-47aa-9dc7-28aeea03ac42/ugc/4da3afc2-cad9-4c2a-82ff-cc7d9d80ca61/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1f05f884-773b-48d5-b766-08bb06a42be3',
        firstName: 'Jordan',
        lastName: 'Boncquet',
        email: 'thecaliforniabelles@gmail.com',
        instagramProfile: {
          handle: 'california_belles',
          followers: 32872,
          engagementRate: 0.33949866147481134
        },
        tiktokProfile: {
          handle: 'california_belles',
          followers: 1170
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T02:54:07.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '36e4a211-5ca9-45cc-ba86-55b822df946c',
      content: [
        {
          groupId: 'effa62a5-e426-465d-82c2-26c83b1e3322',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-08T20:34:21.900Z',

          createdAt: '2024-07-06T16:45:43.337Z',
          content: [
            {
              caption: '',

              id: '1e76bc51-a7a3-48f4-a71b-9bef38f3d273',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36e4a211-5ca9-45cc-ba86-55b822df946c/ugc/effa62a5-e426-465d-82c2-26c83b1e3322/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd3357c14-03c2-434c-a5a6-46724548a48c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36e4a211-5ca9-45cc-ba86-55b822df946c/ugc/effa62a5-e426-465d-82c2-26c83b1e3322/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '907af701-5561-44fa-8c65-f8c96b5821db',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36e4a211-5ca9-45cc-ba86-55b822df946c/ugc/effa62a5-e426-465d-82c2-26c83b1e3322/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '35648655-29a6-4b68-b446-02565865f6a9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-17T14:06:56.744Z',

          createdAt: '2024-07-13T17:44:15.763Z',
          content: [
            {
              caption: '',

              id: 'b9846b77-7c7f-4975-86a5-65d596cb719d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36e4a211-5ca9-45cc-ba86-55b822df946c/ugc/35648655-29a6-4b68-b446-02565865f6a9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '68ae23d2-b9ba-4e27-a5c1-f626e20ee85a',
        firstName: 'Michaela',
        lastName: 'Zahradnikova',
        email: 'theblondeandco1@gmail.com',
        instagramProfile: {
          handle: 'the.blonde.and.co',
          followers: 9652,
          engagementRate: 5.9365934521342725
        },
        tiktokProfile: {
          handle: 'theblondeandco',
          followers: 63
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T05:46:49.000Z',
        amountCents: 16161
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '737c7ec4-c679-4e98-982d-0c1f83ffec67',
      content: [
        {
          groupId: 'f6844c47-dbcc-44de-9fe1-69fa5ab17218',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-30T13:03:02.179Z',

          createdAt: '2024-08-29T23:05:09.574Z',
          content: [
            {
              caption: '',

              id: '11dfdafd-64f1-4864-909a-9a79fab578fd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/737c7ec4-c679-4e98-982d-0c1f83ffec67/ugc/f6844c47-dbcc-44de-9fe1-69fa5ab17218/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '467bfe55-c459-48ba-92a2-a6d54682d06b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/737c7ec4-c679-4e98-982d-0c1f83ffec67/ugc/f6844c47-dbcc-44de-9fe1-69fa5ab17218/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e4b2061f-76e2-4c02-99e0-56ec6738a973',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/737c7ec4-c679-4e98-982d-0c1f83ffec67/ugc/f6844c47-dbcc-44de-9fe1-69fa5ab17218/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '58eb997d-8bd7-4a1d-9a10-742564f04a1b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-30T13:02:57.159Z',

          createdAt: '2024-08-29T22:59:41.859Z',
          content: [
            {
              caption: '',

              id: '199686ec-d4aa-4525-a954-8107cdedb5c0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/737c7ec4-c679-4e98-982d-0c1f83ffec67/ugc/58eb997d-8bd7-4a1d-9a10-742564f04a1b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '89e29832-1792-4f15-b641-3fd75b8fad3b',
        firstName: 'Akeem',
        lastName: 'Muller',
        email: 'collabakeem@gmail.com',
        instagramProfile: {
          handle: 'akmmichs',
          followers: 8548,
          engagementRate: 4.052409920449228
        },
        tiktokProfile: {
          handle: 'akmmichs',
          followers: 6662,
          engagementRate: 15.48425667596652
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T05:52:34.000Z',
        amountCents: 15974
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd7a44437-32e0-4f22-8f30-cc92a4514ca9',
      content: [
        {
          groupId: '2b75457e-8ce2-40a0-a53b-62b0c525f2be',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-20T14:47:23.584Z',

          createdAt: '2024-06-19T20:07:40.918Z',
          content: [
            {
              caption: '',

              id: 'bff6dde9-86df-419e-a755-44b88ab8337b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d7a44437-32e0-4f22-8f30-cc92a4514ca9/ugc/2b75457e-8ce2-40a0-a53b-62b0c525f2be/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bc943c0c-ef6c-4d5a-ac51-922cba7bb7b5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d7a44437-32e0-4f22-8f30-cc92a4514ca9/ugc/2b75457e-8ce2-40a0-a53b-62b0c525f2be/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1351f5c1-a815-4ad6-98c4-991f7244c39c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d7a44437-32e0-4f22-8f30-cc92a4514ca9/ugc/2b75457e-8ce2-40a0-a53b-62b0c525f2be/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '534d2534-65e5-47a0-a124-3713722666dc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T14:47:20.091Z',

          createdAt: '2024-06-19T20:05:27.822Z',
          content: [
            {
              caption: '',

              id: '5b7de941-e0ad-4fa8-befd-69543648a0b4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d7a44437-32e0-4f22-8f30-cc92a4514ca9/ugc/534d2534-65e5-47a0-a124-3713722666dc/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '13bd5bfb-152c-4709-bc6e-619c76a9af17',
        firstName: 'Sara',
        lastName: 'Kast',
        email: 'sark620@gmail.com',
        instagramProfile: {
          handle: 'parivibe__',
          followers: 8308,
          engagementRate: 0.7486759749638903
        },
        tiktokProfile: {
          handle: 'parivibe__',
          followers: 234,
          engagementRate: 11.29532077157656
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T06:04:54.000Z',
        amountCents: 16461
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2220812d-d602-4342-b0a9-bbade869cc24',
      content: [
        {
          groupId: '1edf0e86-78eb-4c09-8e08-8a8c70823e20',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-01T18:04:55.148Z',

          createdAt: '2024-06-26T03:31:38.137Z',
          content: [
            {
              caption: '',

              id: '20b5d502-0a76-4314-9b32-32feb35808b9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2220812d-d602-4342-b0a9-bbade869cc24/ugc/1edf0e86-78eb-4c09-8e08-8a8c70823e20/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '735cc0e4-a87a-4fad-bbde-5421fb2d96e8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2220812d-d602-4342-b0a9-bbade869cc24/ugc/1edf0e86-78eb-4c09-8e08-8a8c70823e20/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e1f310f6-03da-4c10-bcc5-48e55a0b8705',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2220812d-d602-4342-b0a9-bbade869cc24/ugc/1edf0e86-78eb-4c09-8e08-8a8c70823e20/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5c5e5ebb-3924-4388-a318-ae647de50823',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T18:04:51.131Z',

          createdAt: '2024-06-26T03:27:50.634Z',
          content: [
            {
              caption: '',

              id: '61ca4ad4-8f54-42da-bc11-92e327be4953',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2220812d-d602-4342-b0a9-bbade869cc24/ugc/5c5e5ebb-3924-4388-a318-ae647de50823/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1630a4b9-6373-40e6-81f1-73420c1786d6',
        firstName: 'Carson',
        lastName: 'Nicely',
        email: 'carsonnicely@gmail.com',
        instagramProfile: {
          handle: 'carsonnicely',
          followers: 13605,
          engagementRate: 0.9320102903344358
        },
        tiktokProfile: {
          handle: 'carsonnicely',
          followers: 3876,
          engagementRate: 9.585492227979273
        },
        youtubeProfile: {
          handle: 'carsonnicely'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T12:57:19.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '239f381b-a60a-4ada-9d1e-9a1426e265d7',
      content: [
        {
          groupId: '974aa995-04fd-4315-835b-82c5315b0061',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T00:33:47.034Z',

          createdAt: '2024-06-22T18:20:28.457Z',
          content: [
            {
              caption: '',

              id: '98baa033-463f-4eb4-9389-25a017206180',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/239f381b-a60a-4ada-9d1e-9a1426e265d7/ugc/974aa995-04fd-4315-835b-82c5315b0061/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7e962142-4670-4726-b298-8e568f091ae3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T00:33:42.841Z',

          createdAt: '2024-06-22T18:25:21.796Z',
          content: [
            {
              caption: '',

              id: 'a74e6cde-229a-439d-8268-a35216584ce5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/239f381b-a60a-4ada-9d1e-9a1426e265d7/ugc/7e962142-4670-4726-b298-8e568f091ae3/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a69589d4-caa6-46b0-abef-fe0600c88dbc',
        firstName: 'Jessica',
        lastName: 'McGrath',
        email: 'jmspeechpath@gmail.com',
        instagramProfile: {
          handle: 'jessmmmcg',
          followers: 10974,
          engagementRate: 1.8798979405868415
        },
        tiktokProfile: {
          handle: 'jessmmmcg',
          followers: 12938,
          engagementRate: 7.011778734496647
        },
        youtubeProfile: {
          handle: 'jessmmmcg',
          followers: 10
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T13:34:04.000Z',
        amountCents: 15993
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dfc447c5-6635-4d3e-8e1f-cf5b24e475e4',
      content: [
        {
          groupId: '07ccb318-0b0a-4681-989e-e9b683b66afb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-21T12:32:07.538Z',

          createdAt: '2024-06-21T03:32:02.048Z',
          content: [
            {
              caption: '',

              id: 'd2885c5c-ed34-452a-8c8d-551d83bc83f1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dfc447c5-6635-4d3e-8e1f-cf5b24e475e4/ugc/07ccb318-0b0a-4681-989e-e9b683b66afb/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c865e80d-0406-4580-9949-65997c19920c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dfc447c5-6635-4d3e-8e1f-cf5b24e475e4/ugc/07ccb318-0b0a-4681-989e-e9b683b66afb/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '14f72005-104d-42be-a581-074a34e3fc69',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dfc447c5-6635-4d3e-8e1f-cf5b24e475e4/ugc/07ccb318-0b0a-4681-989e-e9b683b66afb/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ea35cc62-90b7-4bbc-9b19-f13a2597e4e9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-21T12:32:03.629Z',

          createdAt: '2024-06-21T04:44:13.344Z',
          content: [
            {
              caption: '',

              id: '83460c3c-21ab-4124-920f-b2eecadad6a1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dfc447c5-6635-4d3e-8e1f-cf5b24e475e4/ugc/ea35cc62-90b7-4bbc-9b19-f13a2597e4e9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '11ab4740-7f4e-4527-8a95-c28a4e297697',
        firstName: 'Daniel',
        lastName: 'Jarvie',
        email: 'djarviechina@gmail.com',
        instagramProfile: {
          handle: 'travelingdannyboy',
          followers: 32378,
          engagementRate: 0.11674593860028412
        },
        tiktokProfile: {
          handle: 'travelingdannyboy',
          followers: 61137,
          engagementRate: 1.317911279669109
        },
        youtubeProfile: {
          handle: 'danieljarvie9588'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T13:55:50.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '95d56149-fa2c-4d99-be94-8de46a91c9d7',
      content: [
        {
          groupId: 'cbc8bddf-76cf-4e4e-a77a-8133a79f50d1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-08T19:41:11.837Z',

          createdAt: '2024-07-02T20:30:31.798Z',
          content: [
            {
              caption: '',

              id: 'b10b96f2-fa77-4153-b616-c7a522299615',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/95d56149-fa2c-4d99-be94-8de46a91c9d7/ugc/cbc8bddf-76cf-4e4e-a77a-8133a79f50d1/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2d523f65-0aae-43b0-83e6-88b0b5efe67f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/95d56149-fa2c-4d99-be94-8de46a91c9d7/ugc/cbc8bddf-76cf-4e4e-a77a-8133a79f50d1/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ade714bd-7ba4-4ee7-9e92-c4dd04382dfc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/95d56149-fa2c-4d99-be94-8de46a91c9d7/ugc/cbc8bddf-76cf-4e4e-a77a-8133a79f50d1/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd5985c52-c02d-43fb-ad9b-76da71c9f5c4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T19:40:54.921Z',

          createdAt: '2024-07-02T22:02:12.768Z',
          content: [
            {
              caption: '',

              id: '789fe332-645e-4d35-80da-405a238db0ca',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/95d56149-fa2c-4d99-be94-8de46a91c9d7/ugc/d5985c52-c02d-43fb-ad9b-76da71c9f5c4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f633e4cc-58da-4951-a560-bae240363e54',
        firstName: 'Danielle',
        lastName: 'Reid',
        email: 'danielle@drandassociates.com',
        instagramProfile: {
          handle: 'iamdaniellereid',
          followers: 9404,
          engagementRate: 1.271799234368354
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T14:46:39.000Z',
        amountCents: 16461
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eff805b5-1a97-47f3-9cde-fad0e12a4b8c',
      content: [
        {
          groupId: 'e01e14cc-666f-4a99-b28d-e78fcca663fd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T14:18:40.716Z',

          createdAt: '2024-06-24T03:04:26.166Z',
          content: [
            {
              caption: '',

              id: 'b6f5650a-47e5-44fc-8c73-5b347d83cc0f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eff805b5-1a97-47f3-9cde-fad0e12a4b8c/ugc/e01e14cc-666f-4a99-b28d-e78fcca663fd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ba96e36b-5424-4f5f-a78a-ac3ec002dca1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-24T14:18:44.316Z',

          createdAt: '2024-06-24T02:50:03.946Z',
          content: [
            {
              caption: '',

              id: 'f3c1073f-ce9b-4c38-8591-e6c3776dd602',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eff805b5-1a97-47f3-9cde-fad0e12a4b8c/ugc/ba96e36b-5424-4f5f-a78a-ac3ec002dca1/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e7946993-1024-423c-bbe4-cf51f9032fcd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eff805b5-1a97-47f3-9cde-fad0e12a4b8c/ugc/ba96e36b-5424-4f5f-a78a-ac3ec002dca1/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '62df4ba0-d03b-40e7-b5d2-f73a6678948a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eff805b5-1a97-47f3-9cde-fad0e12a4b8c/ugc/ba96e36b-5424-4f5f-a78a-ac3ec002dca1/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5fecb676-a27b-4b2b-b91a-6c809cb919c0',
        firstName: 'Cristy',
        lastName: 'Almonte',
        email: 'cristyjalmonte@gmail.com',

        tiktokProfile: {
          handle: 'thecrunchpot',
          followers: 10180,
          engagementRate: 14.303605011976678
        },
        youtubeProfile: {
          handle: 'thecrunchpot',
          followers: 710
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T01:25:27.000Z',
        amountCents: 15993
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c2bf0ee5-ca92-4b44-80dd-00443a88bcfa',
      content: [
        {
          groupId: '03a3e418-f5a8-4ad7-a800-765697292205',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-11T22:25:07.466Z',

          createdAt: '2024-07-09T23:15:55.234Z',
          content: [
            {
              caption: '',

              id: 'da1d06f3-2dd3-45bb-ab58-0e89dc4de0e1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c2bf0ee5-ca92-4b44-80dd-00443a88bcfa/ugc/03a3e418-f5a8-4ad7-a800-765697292205/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bb9ebcee-0bef-4bcb-8b0f-f80b2cf7ba36',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c2bf0ee5-ca92-4b44-80dd-00443a88bcfa/ugc/03a3e418-f5a8-4ad7-a800-765697292205/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7e0058d0-7226-4501-a53f-4da38b7a833e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c2bf0ee5-ca92-4b44-80dd-00443a88bcfa/ugc/03a3e418-f5a8-4ad7-a800-765697292205/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cb796d59-1af4-4f40-8df8-130e777367d5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-11T22:25:04.385Z',

          createdAt: '2024-07-09T23:14:52.802Z',
          content: [
            {
              caption: '',

              id: 'ff36ad74-92b3-4fcd-815a-a6dc45787022',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c2bf0ee5-ca92-4b44-80dd-00443a88bcfa/ugc/cb796d59-1af4-4f40-8df8-130e777367d5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '01f54595-2af6-472b-b70c-00ce9a22fe68',
        firstName: 'Ashlyn',
        lastName: 'Cooke',
        email: 'ashlyncooke.media@gmail.com',
        instagramProfile: {
          handle: 'ashlynmcooke',
          followers: 2034,
          engagementRate: 2.8171091445427727
        },
        tiktokProfile: {
          handle: 'ashlynmcooke',
          followers: 18623,
          engagementRate: 7.619312250713889
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T03:16:44.000Z',
        amountCents: 16199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '04691d27-427e-4eb4-871a-9f6032a137e9',
      content: [
        {
          groupId: '9abc219d-2d3e-4da4-b19a-8408b980282b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T16:10:06.554Z',

          createdAt: '2024-06-28T03:14:59.687Z',
          content: [
            {
              caption: '',

              id: 'f165f723-e1fc-4ea5-a2c1-d4e42e2f1e1e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/04691d27-427e-4eb4-871a-9f6032a137e9/ugc/9abc219d-2d3e-4da4-b19a-8408b980282b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c758b2cd-0285-42ae-b7e0-86ba5cf68e85',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-28T16:10:10.068Z',

          createdAt: '2024-06-28T03:18:47.176Z',
          content: [
            {
              caption: '',

              id: '8117ec5e-6ac3-4752-9b28-bcf325a77169',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/04691d27-427e-4eb4-871a-9f6032a137e9/ugc/c758b2cd-0285-42ae-b7e0-86ba5cf68e85/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c13fc8cb-71c1-441b-a643-a84c0c463de0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/04691d27-427e-4eb4-871a-9f6032a137e9/ugc/c758b2cd-0285-42ae-b7e0-86ba5cf68e85/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '974e87dd-955e-4004-a062-c6d66c1e6915',
        firstName: 'Emily',
        lastName: 'Hart',
        email: 'emmmily.hart@gmail.com',
        instagramProfile: {
          handle: 'emmmily.hart',
          followers: 13430,
          engagementRate: 0.5383469843633656
        },
        tiktokProfile: {
          handle: 'emmmily.hart',
          followers: 1237,
          engagementRate: 5.766496891750063
        },
        youtubeProfile: {
          handle: 'emmmilyhart',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T04:46:39.000Z',
        amountCents: 17071
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1ef059a1-1452-4d30-b69a-81ea410bbb9f',
      content: [
        {
          groupId: '13ec7b4f-4d87-43b5-b78d-84c0c5ce5a43',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-24T12:45:56.717Z',

          createdAt: '2024-06-22T20:14:08.349Z',
          content: [
            {
              caption: '',

              id: '4bd7c188-09db-4dfc-b1f5-d834cb82af90',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ef059a1-1452-4d30-b69a-81ea410bbb9f/ugc/13ec7b4f-4d87-43b5-b78d-84c0c5ce5a43/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '158444fb-60a9-42da-bf7b-84589aa794b2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ef059a1-1452-4d30-b69a-81ea410bbb9f/ugc/13ec7b4f-4d87-43b5-b78d-84c0c5ce5a43/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fc14b2ca-18a0-46f0-87f4-674f1655d158',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ef059a1-1452-4d30-b69a-81ea410bbb9f/ugc/13ec7b4f-4d87-43b5-b78d-84c0c5ce5a43/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '36f6f81c-3c66-4b6a-8aae-8b95d41829c4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T18:15:01.719Z',

          createdAt: '2024-06-23T19:28:52.463Z',
          content: [
            {
              caption: '',

              id: '46f9852f-5e34-4e88-9cec-0c15d266625c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ef059a1-1452-4d30-b69a-81ea410bbb9f/ugc/36f6f81c-3c66-4b6a-8aae-8b95d41829c4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '787594b7-95bb-43f9-8ef7-5f5403344c3c',
        firstName: 'Micaela',
        lastName: 'Stibgen',
        email: 'mstibgen@gmail.com',
        instagramProfile: {
          handle: 'motherhoodiscalling',
          followers: 1391,
          engagementRate: 0.905823148813803
        },
        tiktokProfile: {
          handle: 'mmotherhoodiscalling',
          followers: 11004,
          engagementRate: 2.9572378840671525
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T06:53:20.000Z',
        amountCents: 16499
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ebd04e4f-e44d-4ff7-9a89-6e5f92df601a',
      content: [],
      creator: {
        id: '4e36c974-888a-411d-8f63-ef487e438b65',
        firstName: 'Savannah',
        lastName: 'Rae',
        email: 'dreamwerksmuzik@gmail.com',
        instagramProfile: {
          handle: 'thesavannahrae',
          followers: 62125,
          engagementRate: 0.36780684104627764
        },
        tiktokProfile: {
          handle: 'thesavannahrae',
          followers: 199216,
          engagementRate: 14.35471115106271
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T07:16:36.000Z',
        amountCents: 16386
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '207b0794-dbc8-4ff7-821d-0afae238e900',
      content: [
        {
          groupId: '0356c54a-66c1-478a-83c9-94f70f50dc6e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T15:06:49.981Z',

          createdAt: '2024-06-28T02:54:46.910Z',
          content: [
            {
              caption: '',

              id: '8b4b1c74-794d-491a-8bf2-55522a8cb12f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/207b0794-dbc8-4ff7-821d-0afae238e900/ugc/0356c54a-66c1-478a-83c9-94f70f50dc6e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a667a91f-a3cd-4e0b-8d5f-f0c6cbe61351',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-29T19:31:06.177Z',

          createdAt: '2024-06-28T02:56:17.747Z',
          content: [
            {
              caption: '',

              id: 'a179de1d-001a-4917-b503-b4f14e80f9fb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/207b0794-dbc8-4ff7-821d-0afae238e900/ugc/a667a91f-a3cd-4e0b-8d5f-f0c6cbe61351/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '692f5a0e-ad8b-44cf-a218-d9ef5abe55b4',
        firstName: 'Amelia',
        lastName: 'Hitchens',
        email: 'hitchensamelia@gmail.com',
        instagramProfile: {
          handle: 'meliabadeliaa',
          followers: 3035,
          engagementRate: 2.9617426322533404
        },
        tiktokProfile: {
          handle: 'meliabadeliaa',
          followers: 5658,
          engagementRate: 5.135135135135135
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T07:16:38.000Z',
        amountCents: 16086
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f7691f6f-5d77-4117-a60f-f53daba9834c',
      content: [],
      creator: {
        id: 'c49f517f-99ad-4c44-a6f8-89eb003ababf',
        firstName: 'Ethan',
        lastName: 'Thinks',
        email: 'naturethingyinfo@gmail.com',
        instagramProfile: {
          handle: 'thinkethan',
          followers: 8613,
          engagementRate: 0.5131777545570649
        },
        tiktokProfile: {
          handle: 'thinkethan',
          followers: 1256,
          engagementRate: 3.1841652323580036
        },
        youtubeProfile: {
          handle: 'thinkethan'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T07:16:39.000Z',
        amountCents: 16330
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd887adef-0e22-4fc3-b8cd-69b596ca8afd',
      content: [
        {
          groupId: 'bdfc3e73-8287-4700-b450-da4a499fcde0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-12T12:27:49.704Z',

          createdAt: '2024-07-01T14:22:09.946Z',
          content: [
            {
              caption: '',

              id: 'cac8852f-5f96-4d55-8968-06f678cbdacd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d887adef-0e22-4fc3-b8cd-69b596ca8afd/ugc/bdfc3e73-8287-4700-b450-da4a499fcde0/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd5ce3b59-052c-4de4-9cd4-4060a0352142',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d887adef-0e22-4fc3-b8cd-69b596ca8afd/ugc/bdfc3e73-8287-4700-b450-da4a499fcde0/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '119694d1-6614-4b06-877f-85bee21e1e53',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d887adef-0e22-4fc3-b8cd-69b596ca8afd/ugc/bdfc3e73-8287-4700-b450-da4a499fcde0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b9d68754-1833-4966-90e0-ee66a775aaff',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-12T12:27:40.374Z',

          createdAt: '2024-07-03T17:19:01.053Z',
          content: [
            {
              caption: '',

              id: '7e3b7a29-a0f2-4bae-bc65-1fe836483acf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d887adef-0e22-4fc3-b8cd-69b596ca8afd/ugc/b9d68754-1833-4966-90e0-ee66a775aaff/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b65b34f7-0b0c-4545-b4ea-e877729e3ac6',
        firstName: 'Lasonya',
        lastName: 'Rodgers',
        email: 'lrodg004@gmail.com',
        instagramProfile: {
          handle: 'yourhomegirlsony',
          followers: 3821,
          engagementRate: 3.323737241559801
        },
        tiktokProfile: {
          handle: 'yourhomegirlsony',
          followers: 1362,
          engagementRate: 5.584073639512795
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T07:16:39.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ab6e9eaf-95ef-4672-bf69-38282ff40f6f',
      content: [
        {
          groupId: '9cee1630-c0be-479f-b6c4-8600e1e7265e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-24T11:56:44.646Z',

          createdAt: '2024-06-23T16:30:08.762Z',
          content: [
            {
              caption: '',

              id: 'a8a2cbad-aedb-49ec-a721-53607daf844d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab6e9eaf-95ef-4672-bf69-38282ff40f6f/ugc/9cee1630-c0be-479f-b6c4-8600e1e7265e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b8dac259-ade6-41e1-b7ff-934fa8823326',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab6e9eaf-95ef-4672-bf69-38282ff40f6f/ugc/9cee1630-c0be-479f-b6c4-8600e1e7265e/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0235637e-19a7-46be-a155-6d6e225aceb5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab6e9eaf-95ef-4672-bf69-38282ff40f6f/ugc/9cee1630-c0be-479f-b6c4-8600e1e7265e/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '031405bb-3c80-4a75-8a0e-ceb72f855bf4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-25T01:58:19.632Z',

          createdAt: '2024-06-23T16:27:09.565Z',
          content: [
            {
              caption: '',

              id: '2d88ba08-fb11-47bf-b49e-bac0b97ccbd6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab6e9eaf-95ef-4672-bf69-38282ff40f6f/ugc/031405bb-3c80-4a75-8a0e-ceb72f855bf4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '31926913-8d60-4145-a420-de88114aebcd',
        firstName: 'Casey',
        lastName: 'Starchak',
        email: 'cstarchak@aol.com',
        instagramProfile: {
          handle: 'cstarchak',
          followers: 97373,
          engagementRate: 0.9145245601963583
        },
        tiktokProfile: {
          handle: 'cstarchak',
          followers: 245529,
          engagementRate: 7.912457912457913
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T07:46:20.000Z',
        amountCents: 15974
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a059fb62-51db-4400-b723-314b11e3bbfc',
      content: [],
      creator: {
        id: '01ce86ea-239c-473b-9b74-771515457789',
        firstName: 'Moises',
        lastName: 'Romero',
        email: 'romero.moises10@yahoo.com',
        instagramProfile: {
          handle: 'moy_cess',
          followers: 3190,
          engagementRate: 4.137931034482759
        },
        tiktokProfile: {
          handle: 'moy_cess',
          followers: 179,
          engagementRate: 4.56989247311828
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T08:36:37.000Z',
        amountCents: 16748
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '71c297f9-b4a9-4a9e-ac45-b180e11b2f07',
      content: [
        {
          groupId: 'dc58a1fe-790b-43e6-afc5-eaa6b61c4fe4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-27T15:04:39.014Z',

          createdAt: '2024-06-24T11:54:26.056Z',
          content: [
            {
              caption: '',

              id: '22025d01-9689-4189-9b4f-5f7d6418cc8c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/71c297f9-b4a9-4a9e-ac45-b180e11b2f07/ugc/dc58a1fe-790b-43e6-afc5-eaa6b61c4fe4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd30c2266-8abc-45bd-b5e2-8d42c6f413d0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-27T15:04:43.757Z',

          createdAt: '2024-06-24T11:55:48.023Z',
          content: [
            {
              caption: '',

              id: '323fcf50-8ee6-4de7-9fc9-98f422901e91',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/71c297f9-b4a9-4a9e-ac45-b180e11b2f07/ugc/d30c2266-8abc-45bd-b5e2-8d42c6f413d0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '89e9c045-b8c6-4243-be7c-b989acc67f3b',
        firstName: 'Kassidy',
        lastName: 'Huelsebusch',
        email: 'hakemackcassidy@gmail.com',
        instagramProfile: {
          handle: 'honestly_bare',
          followers: 21267,
          engagementRate: 0.00752339305026567
        },
        tiktokProfile: {
          handle: 'kassidy.hakemack',
          followers: 1
        },
        youtubeProfile: {
          handle: 'honestlybare4102'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T13:06:42.000Z',
        amountCents: 16011
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1e5a50dd-ef6f-4c0e-bbc6-774aba6c4e99',
      content: [
        {
          groupId: '25bc36d4-dfb6-4cee-8b77-b120a242b61a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T17:02:41.720Z',

          createdAt: '2024-06-25T20:11:25.478Z',
          content: [
            {
              caption: '',

              id: '81c7f794-29b9-4d1b-83a0-216b0eee2fa3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1e5a50dd-ef6f-4c0e-bbc6-774aba6c4e99/ugc/25bc36d4-dfb6-4cee-8b77-b120a242b61a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '9ac594bc-84f6-4a2f-bede-004a2f4fc209',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-28T17:02:38.341Z',

          createdAt: '2024-06-25T20:06:03.940Z',
          content: [
            {
              caption: '',

              id: '78b5612a-62b2-4614-85a8-db01dcfb35b8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1e5a50dd-ef6f-4c0e-bbc6-774aba6c4e99/ugc/9ac594bc-84f6-4a2f-bede-004a2f4fc209/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7e6307fd-3f9f-4d6a-a5c3-e58bba12b919',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1e5a50dd-ef6f-4c0e-bbc6-774aba6c4e99/ugc/9ac594bc-84f6-4a2f-bede-004a2f4fc209/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b7a1627c-9a4d-4359-aea3-0cda4048b9b4',
        firstName: 'Jennifer',
        lastName: 'Jarvie',
        email: 'jarvie.jennifer@gmail.com',

        tiktokProfile: {
          handle: 'worldlyjen',
          followers: 22351,
          engagementRate: 10.289389067524116
        },
        youtubeProfile: {
          handle: 'clubjarvie3708',
          followers: 25
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T13:25:48.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '63706384-211b-47ef-9315-ba583e15a42a',
      content: [
        {
          groupId: '61f919bb-c9d3-474f-b94d-0fa3010e5cc8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T20:13:17.350Z',

          createdAt: '2024-06-24T19:56:41.376Z',
          content: [
            {
              caption: '',

              id: '54286079-1edd-472e-8f1c-0871cb6836e9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/63706384-211b-47ef-9315-ba583e15a42a/ugc/61f919bb-c9d3-474f-b94d-0fa3010e5cc8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9ec85342-4b73-4e50-bcc3-55b6359104cf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-25T20:13:13.659Z',

          createdAt: '2024-06-24T19:55:40.555Z',
          content: [
            {
              caption: '',

              id: '22247d00-d376-48e5-b3c3-1fc31f565922',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/63706384-211b-47ef-9315-ba583e15a42a/ugc/9ec85342-4b73-4e50-bcc3-55b6359104cf/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd569077b-a418-40c5-b681-a2bd64524f95',
        firstName: 'Brenton',
        lastName: 'Roggow',
        email: 'brog83@gmail.com',
        instagramProfile: {
          handle: 'brenton.roggow',
          followers: 13705,
          engagementRate: 0.7581174753739511
        },
        tiktokProfile: {
          handle: 'brenton.roggow',
          followers: 257,
          engagementRate: 3.539468330652552
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T14:03:01.000Z',
        amountCents: 16424
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '253d1f7d-093b-4a78-85a7-69c67027b67d',
      content: [
        {
          groupId: '41098b89-fb48-4614-8237-ec8a3039df34',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T20:12:22.079Z',

          createdAt: '2024-06-24T22:02:41.953Z',
          content: [
            {
              caption: '',

              id: 'c0bfd9b8-0c28-4a1a-8047-151df5a003e7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/253d1f7d-093b-4a78-85a7-69c67027b67d/ugc/41098b89-fb48-4614-8237-ec8a3039df34/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '751ee2ca-1c4c-4b16-ac2e-91f40bb3edc3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T20:12:18.547Z',

          createdAt: '2024-06-24T22:03:10.543Z',
          content: [
            {
              caption: '',

              id: '17258bc4-4e83-41ce-b95b-13e6e9123f1e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/253d1f7d-093b-4a78-85a7-69c67027b67d/ugc/751ee2ca-1c4c-4b16-ac2e-91f40bb3edc3/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9d1dccf9-d41c-4781-a0e7-fb70dc8c0dd7',
        firstName: 'Charlene',
        lastName: 'Bottois',
        email: 'charlenebottois@gmail.com',

        tiktokProfile: {
          handle: 'chachabtts',
          followers: 7852,
          engagementRate: 12.906137184115524
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T14:46:38.000Z',
        amountCents: 16330
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'adad6aff-3beb-4369-90ad-251624b03167',
      content: [
        {
          groupId: 'd7a4e40d-1c75-4a8d-9a17-20747ba79b14',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-09T02:14:55.764Z',

          createdAt: '2024-07-04T16:04:55.058Z',
          content: [
            {
              caption: '',

              id: '48af5385-8f72-455d-8298-6f4166fed509',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/adad6aff-3beb-4369-90ad-251624b03167/ugc/d7a4e40d-1c75-4a8d-9a17-20747ba79b14/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '76371a28-9f4c-4f76-9a6c-0d0cab41d8f7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/adad6aff-3beb-4369-90ad-251624b03167/ugc/d7a4e40d-1c75-4a8d-9a17-20747ba79b14/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0ec594cb-9ae9-4b1e-b50c-0baaac023f9d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/adad6aff-3beb-4369-90ad-251624b03167/ugc/d7a4e40d-1c75-4a8d-9a17-20747ba79b14/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd4ebffc7-a6bb-4cf3-b53d-ec30d7afbee9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T02:14:48.177Z',

          createdAt: '2024-07-04T16:29:21.439Z',
          content: [
            {
              caption: '',

              id: '5e692088-d28e-4e1b-8780-58c1c429c051',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/adad6aff-3beb-4369-90ad-251624b03167/ugc/d4ebffc7-a6bb-4cf3-b53d-ec30d7afbee9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8d6ac191-49fe-4ef7-aaa9-9f30d3721e89',
        firstName: 'Kaitlyn ',
        lastName: 'Foster ',
        email: 'thepurrfecttcats@gmail.com',
        instagramProfile: {
          handle: 'thepurrfecttcats',
          followers: 20004,
          engagementRate: 1.3447310537892423
        },
        tiktokProfile: {
          handle: 'thecatduo_',
          followers: 910,
          engagementRate: 10.778443113772456
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T15:52:58.000Z',
        amountCents: 16293
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f4d2be53-f989-47ec-b681-a0aebc6a5f58',
      content: [
        {
          groupId: '0a78705a-7312-4dc3-9c7e-6a26e0e03b86',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T06:32:04.952Z',

          createdAt: '2024-06-22T23:08:50.366Z',
          content: [
            {
              caption: '',

              id: '316a8a49-9836-4aa9-a3bc-a179910a48b9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f4d2be53-f989-47ec-b681-a0aebc6a5f58/ugc/0a78705a-7312-4dc3-9c7e-6a26e0e03b86/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'eb2c6089-09cd-4328-ac4d-160b59604d79',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T06:32:14.895Z',

          createdAt: '2024-06-22T23:21:27.536Z',
          content: [
            {
              caption: '',

              id: 'c2f4ae65-5084-4a11-8bb0-de305ed0af54',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f4d2be53-f989-47ec-b681-a0aebc6a5f58/ugc/eb2c6089-09cd-4328-ac4d-160b59604d79/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd51411e0-3772-49eb-9708-aa535a08f002',
        firstName: 'Ronnitra',
        lastName: 'Blount',
        email: 'blountronnitra@gmail.com',
        instagramProfile: {
          handle: 'nitrab1',
          followers: 22826,
          engagementRate: 0.313239288530623
        },
        tiktokProfile: {
          handle: 'lifeofnitra',
          followers: 31938,
          engagementRate: 2.454247043482171
        },
        youtubeProfile: {
          handle: 'nitrab',
          followers: 739
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T19:52:27.000Z',
        amountCents: 15649
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4f15d51b-32f8-4226-a75a-baffec5ab389',
      content: [],
      creator: {
        id: '3497ef1b-5ca6-4e9f-87be-f00b89fdc734',
        firstName: 'Mariia',
        lastName: 'Tarasova',
        email: 'vanilla1673@gmail.com',
        instagramProfile: {
          handle: 'marytar_pro',
          followers: 59436,
          engagementRate: 2.3627094690086814
        },
        tiktokProfile: {
          handle: 'mary_sunshine_life',
          followers: 197
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T05:46:32.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eb654d38-c387-4d78-9d67-ad685bcbbfa2',
      content: [],
      creator: {
        id: '1f2164ef-eb9a-4ff4-854b-8744ad6bb73d',
        firstName: 'Jonae',
        lastName: 'Richmond',
        email: 'jonaeniicole@gmail.com',
        instagramProfile: {
          handle: 'jonaeniicole',
          followers: 2088,
          engagementRate: 3.529693486590038
        },
        tiktokProfile: {
          handle: 'jonaeniicole',
          followers: 580,
          engagementRate: 12.087912087912088
        },
        youtubeProfile: {
          handle: 'jonaeniicole',
          followers: 3
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T09:46:34.000Z',
        amountCents: 16236
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '45f08cf9-a953-4af6-af71-bb26397e64c8',
      content: [
        {
          groupId: '62281400-ce65-4f94-9cca-bb1707ce20ab',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-25T20:15:01.195Z',

          createdAt: '2024-06-22T13:41:40.116Z',
          content: [
            {
              caption: '',

              id: '725a5819-6529-4457-b21c-9fe7f2a738b4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/45f08cf9-a953-4af6-af71-bb26397e64c8/ugc/62281400-ce65-4f94-9cca-bb1707ce20ab/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '56879e01-8951-40e9-ac54-7cc89f26d91e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/45f08cf9-a953-4af6-af71-bb26397e64c8/ugc/62281400-ce65-4f94-9cca-bb1707ce20ab/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '924b55c4-46bd-4f9c-95f8-fd9b8ee92c9b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T20:12:47.768Z',

          createdAt: '2024-06-22T13:34:29.481Z',
          content: [
            {
              caption: '',

              id: 'c10e6f99-be33-46a0-8926-ab0b8d8d6082',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/45f08cf9-a953-4af6-af71-bb26397e64c8/ugc/924b55c4-46bd-4f9c-95f8-fd9b8ee92c9b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'db186c55-8348-4fae-91ec-517f8305722b',
        firstName: 'Brittany',
        lastName: 'Seal',
        email: 'hi.brittanyseal@gmail.com',
        instagramProfile: {
          handle: 'brittany_sealy',
          followers: 187,
          engagementRate: 8.395721925133689
        },
        tiktokProfile: {
          handle: 'brittanyeseal',
          followers: 3955,
          engagementRate: 2.7202215455227505
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:56:49.000Z',
        amountCents: 16086
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b8e99c2a-abef-4e9e-b600-163792891d30',
      content: [
        {
          groupId: '8f523bee-b624-4a7e-aea1-49f58af3341d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-08T19:46:12.747Z',

          createdAt: '2024-06-28T00:04:06.295Z',
          content: [
            {
              caption: '',

              id: 'cd5bb647-dc32-411b-99e4-f4128b5efe2d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b8e99c2a-abef-4e9e-b600-163792891d30/ugc/8f523bee-b624-4a7e-aea1-49f58af3341d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8705b983-9e00-4903-9da3-3e1f98fce163',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b8e99c2a-abef-4e9e-b600-163792891d30/ugc/8f523bee-b624-4a7e-aea1-49f58af3341d/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '47261b2e-1b9e-4234-94f0-08ea6e619da6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b8e99c2a-abef-4e9e-b600-163792891d30/ugc/8f523bee-b624-4a7e-aea1-49f58af3341d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '87f1c652-50fe-49b0-9389-fd24837ecbbd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T18:53:18.287Z',

          createdAt: '2024-07-01T02:33:21.240Z',
          content: [
            {
              caption: '',

              id: '689b3ab8-281a-4420-a2f8-cba145bc2b2f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b8e99c2a-abef-4e9e-b600-163792891d30/ugc/87f1c652-50fe-49b0-9389-fd24837ecbbd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '64b2f623-8228-475d-88f7-69c93329eff1',
        firstName: 'Tanya',
        lastName: 'Ivanov',
        email: 'ivanovtanya70@gmail.com',
        instagramProfile: {
          handle: 'ivanov.farm.house',
          followers: 2871,
          engagementRate: 2.371995820271682
        },
        tiktokProfile: {
          handle: 'ivanov.farm.house',
          followers: 263,
          engagementRate: 8.58087359779407
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T18:05:57.000Z',
        amountCents: 16274
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ff782ad8-4366-447e-be62-eea96952e9d5',
      content: [
        {
          groupId: 'a46d5cf4-8ab8-4155-a7f1-fa48ec3fbc2a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T15:03:09.586Z',

          createdAt: '2024-06-23T22:06:09.670Z',
          content: [
            {
              caption: '',

              id: '2695610b-bd09-4809-bacf-478500ab7331',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ff782ad8-4366-447e-be62-eea96952e9d5/ugc/a46d5cf4-8ab8-4155-a7f1-fa48ec3fbc2a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'da7cc0b4-3a8b-43be-984b-e0a0226bbed5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-24T15:03:05.792Z',

          createdAt: '2024-06-23T22:05:36.717Z',
          content: [
            {
              caption: '',

              id: '0e223a11-28de-4ddb-85e6-2771681f1abe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ff782ad8-4366-447e-be62-eea96952e9d5/ugc/da7cc0b4-3a8b-43be-984b-e0a0226bbed5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '34955116-7653-4064-acff-38a26d2bb81c',
        firstName: 'Emily',
        lastName: 'Supiot',
        email: 'emilysupiot@gmail.com',
        instagramProfile: {
          handle: 'cozyclicks',
          followers: 18607,
          engagementRate: 0.23915730639006827
        },
        tiktokProfile: {
          handle: 'cozyclicks',
          followers: 6705
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T20:25:48.000Z',
        amountCents: 16289
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fb3d6c1a-7bb4-4115-a1ab-1bac068bd1a4',
      content: [
        {
          groupId: '8110911a-7acf-49c1-860f-8c40dd1dcb6b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-04T15:14:36.383Z',

          createdAt: '2024-06-25T19:42:35.494Z',
          content: [
            {
              caption: '',

              id: 'e868b2e6-6dfa-4f64-b1b7-c966415af015',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fb3d6c1a-7bb4-4115-a1ab-1bac068bd1a4/ugc/8110911a-7acf-49c1-860f-8c40dd1dcb6b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '75fdf910-d84c-458c-92e1-3baf0b899a4e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T17:44:15.242Z',

          createdAt: '2024-06-25T20:07:46.761Z',
          content: [
            {
              caption: '',

              id: '39e17a21-d081-485d-8afd-63bcec50a782',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fb3d6c1a-7bb4-4115-a1ab-1bac068bd1a4/ugc/75fdf910-d84c-458c-92e1-3baf0b899a4e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'fe437d5a-0395-4988-8843-ba9de71f8e07',
        firstName: 'Georgios',
        lastName: 'P',
        email: 'georgiospinsta@gmail.com',
        instagramProfile: {
          handle: 'geo_k_pap',
          followers: 21187,
          engagementRate: 6.602161702931042
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T02:34:16.000Z',
        amountCents: 15936
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fadaf712-4205-49c2-9068-affaf016dd67',
      content: [],
      creator: {
        id: '1186abc0-e3c4-476d-8bd8-84dba7d50af0',
        firstName: 'Deya',
        lastName: 'Lopez',
        email: 'natureee99@gmail.com',
        instagramProfile: {
          handle: 'naturebabe99',
          followers: 26350,
          engagementRate: 2.0907020872865276
        },
        tiktokProfile: {
          handle: 'naturebabe99',
          followers: 42082,
          engagementRate: 13.75555888724568
        },
        youtubeProfile: {
          handle: 'deya4382'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T07:26:10.000Z',
        amountCents: 16214
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1a00adb8-5560-468f-9423-43595b86cb6d',
      content: [
        {
          groupId: 'c6344d9a-186d-4888-841a-3a43d49d99fa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-30T00:16:06.413Z',

          createdAt: '2024-06-26T19:21:12.052Z',
          content: [
            {
              caption: '',

              id: '41285cb8-5967-4a13-a60d-30bd258dfbf2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1a00adb8-5560-468f-9423-43595b86cb6d/ugc/c6344d9a-186d-4888-841a-3a43d49d99fa/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '35472a28-4293-466f-bffa-ac54588b4475',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1a00adb8-5560-468f-9423-43595b86cb6d/ugc/c6344d9a-186d-4888-841a-3a43d49d99fa/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fd413afe-b18a-478f-b903-ba5af8e4b4a2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-30T00:16:03.058Z',

          createdAt: '2024-06-26T19:20:10.625Z',
          content: [
            {
              caption: '',

              id: '9953d297-3c5d-44ae-bbcd-b9e2f2fba093',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1a00adb8-5560-468f-9423-43595b86cb6d/ugc/fd413afe-b18a-478f-b903-ba5af8e4b4a2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ff49a241-51fb-460c-a926-fc6d113b97c6',
        firstName: 'Erin',
        lastName: 'Prince',
        email: 'erinprinceblog@gmail.com',
        instagramProfile: {
          handle: 'erinprince4ever',
          followers: 7059,
          engagementRate: 2.209944751381215
        },
        tiktokProfile: {
          handle: 'erinprince4ever',
          followers: 877,
          engagementRate: 6.842339172623236
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T11:42:51.000Z',
        amountCents: 16536
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '246361dc-b760-476e-9f25-2aedeaeaa011',
      content: [],
      creator: {
        id: 'c3a0e5ba-84b5-494f-b052-c37cb6a12808',
        firstName: 'Arica',
        lastName: 'Lynn',
        email: 'aricalynn2@gmail.com',
        instagramProfile: {
          handle: 'the_maui_mama',
          followers: 4962,
          engagementRate: 0.7557436517533254
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T07:35:44.000Z',
        amountCents: 15706
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2a6c4ed2-ea8d-49e7-87e4-7d30f78e4c1e',
      content: [
        {
          groupId: '3b3fb9cf-368d-4a3b-912d-e9fb01985c6d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-16T02:36:37.969Z',

          createdAt: '2024-07-12T01:49:24.026Z',
          content: [
            {
              caption: '',

              id: 'b60b66ba-8664-4e26-9cc9-6b4c92dca10a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2a6c4ed2-ea8d-49e7-87e4-7d30f78e4c1e/ugc/3b3fb9cf-368d-4a3b-912d-e9fb01985c6d/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '71d2b71e-896b-42ac-bd64-6ec6124d64ab',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2a6c4ed2-ea8d-49e7-87e4-7d30f78e4c1e/ugc/3b3fb9cf-368d-4a3b-912d-e9fb01985c6d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e9c184c1-dc0d-41c8-9fb5-26a1d6228d08',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2a6c4ed2-ea8d-49e7-87e4-7d30f78e4c1e/ugc/3b3fb9cf-368d-4a3b-912d-e9fb01985c6d/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7e8cc3ff-0053-448d-9d4f-7c304653bd05',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-16T02:36:44.409Z',

          createdAt: '2024-07-12T23:45:13.406Z',
          content: [
            {
              caption: '',

              id: 'ca402efe-9600-4aab-b5a1-5e1957431a38',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2a6c4ed2-ea8d-49e7-87e4-7d30f78e4c1e/ugc/7e8cc3ff-0053-448d-9d4f-7c304653bd05/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '16fb7bc5-89c8-4b99-9a74-9e970fc7296e',
        firstName: 'Kodiah',
        lastName: 'Sutherland',
        email: 'kodykodez08@gmail.com',
        instagramProfile: {
          handle: 'kodykodez',
          followers: 1036,
          engagementRate: 4.0347490347490345
        },
        tiktokProfile: {
          handle: 'kodykodez',
          followers: 3630,
          engagementRate: 3.8372392480041206
        },
        youtubeProfile: {
          handle: 'kodykodez',
          followers: 337
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T16:46:46.000Z',
        amountCents: 17077
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '347935be-7e1d-412a-933c-054830bee68c',
      content: [
        {
          groupId: '79b692e0-9b4f-44d5-bc09-3f13b813c1b7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-02T11:44:31.969Z',

          createdAt: '2024-06-30T23:09:19.678Z',
          content: [
            {
              caption: '',

              id: '4a7bcb3e-5597-43af-9afa-5bf43ae93b18',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/347935be-7e1d-412a-933c-054830bee68c/ugc/79b692e0-9b4f-44d5-bc09-3f13b813c1b7/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b3d7fd36-05cd-41b2-a3e8-b00dd5b7a731',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/347935be-7e1d-412a-933c-054830bee68c/ugc/79b692e0-9b4f-44d5-bc09-3f13b813c1b7/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c491120f-82dc-427e-905e-0415cabd6bd4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/347935be-7e1d-412a-933c-054830bee68c/ugc/79b692e0-9b4f-44d5-bc09-3f13b813c1b7/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd33f0a98-9dc1-4b60-9d40-31df4d14fd15',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T19:57:20.982Z',

          createdAt: '2024-06-30T23:04:01.182Z',
          content: [
            {
              caption: '',

              id: 'e88501b5-282a-4e34-8c86-dd6fbe58bb79',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/347935be-7e1d-412a-933c-054830bee68c/ugc/d33f0a98-9dc1-4b60-9d40-31df4d14fd15/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'fb2eb833-0e46-4d08-9c57-b8a5c8819201',
        firstName: 'Yaroslav',
        lastName: 'Yakymets',
        email: 'instarging@gmail.com',
        instagramProfile: {
          handle: 'yaroslav_yakymets',
          followers: 6574,
          engagementRate: 0.19014298752662004
        },
        tiktokProfile: {
          handle: 'lovanilejoy',
          followers: 126,
          engagementRate: 1.3574660633484164
        },
        youtubeProfile: {
          handle: 'yarosnap',
          followers: 227
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T04:05:39.000Z',
        amountCents: 15993
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ea63f7c8-c0dd-4a9a-a466-7227f9c26053',
      content: [],
      creator: {
        id: '22585317-4a58-46b7-9778-8be795917bac',
        firstName: 'Jeff',
        lastName: 'Lam',
        email: 'theangryasianguy@gmail.com',
        instagramProfile: {
          handle: 'angryasianguy',
          followers: 141676,
          engagementRate: 1.5281346170134673
        },

        youtubeProfile: {
          handle: 'violentmochi',
          followers: 21
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T21:35:39.000Z',
        amountCents: 19576
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bbd0bee4-80a5-44e8-b742-452112d99fc6',
      content: [
        {
          groupId: '32a5cd89-5db3-4488-9449-fe955c9ab14b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-03T15:17:41.409Z',

          createdAt: '2024-07-01T23:34:14.405Z',
          content: [
            {
              caption: '',

              id: 'c1d135ce-1edd-47a9-8b0a-8ad937c00964',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bbd0bee4-80a5-44e8-b742-452112d99fc6/ugc/32a5cd89-5db3-4488-9449-fe955c9ab14b/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2f62f37e-0c20-4ea4-8d73-8cce28cb2751',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bbd0bee4-80a5-44e8-b742-452112d99fc6/ugc/32a5cd89-5db3-4488-9449-fe955c9ab14b/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b9f4aac0-1413-4353-bbe7-a8da0aa1f210',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bbd0bee4-80a5-44e8-b742-452112d99fc6/ugc/32a5cd89-5db3-4488-9449-fe955c9ab14b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '34e4c889-2938-4a26-891a-bd037815ff15',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T15:17:34.071Z',

          createdAt: '2024-07-01T23:28:59.666Z',
          content: [
            {
              caption: '',

              id: 'cfb149a3-1bbe-4aac-aa92-fab49384f0de',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bbd0bee4-80a5-44e8-b742-452112d99fc6/ugc/34e4c889-2938-4a26-891a-bd037815ff15/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '12c87ba7-068d-43a5-80d4-14a40a6be253',
        firstName: 'Aladria',
        lastName: 'Brown',
        email: 'laythemuse01@gmail.com',
        instagramProfile: {
          handle: 'laythemuse',
          followers: 2608,
          engagementRate: 3.9800613496932513
        },
        tiktokProfile: {
          handle: 'laythemuse',
          followers: 8121,
          engagementRate: 10.104881000403388
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T17:10:51.000Z',
        amountCents: 16311
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '434ceedc-2c0c-4ed8-b95c-e6af1d818e19',
      content: [],
      creator: {
        id: '54e30e4a-bece-470a-a07b-742579d5bb2f',
        firstName: 'Jenn',
        lastName: 'Ulen',
        email: 'muthajenn@gmail.com',

        tiktokProfile: {
          handle: 'muthajenn',
          followers: 16563,
          engagementRate: 2.4325763125763125
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T17:15:37.000Z',
        amountCents: 16049
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ed9a291c-bcf4-4dec-8a71-80eee4292a58',
      content: [
        {
          groupId: '31610e55-739c-4f18-8f42-bc24c48abc65',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T11:28:32.222Z',

          createdAt: '2024-07-02T23:11:38.182Z',
          content: [
            {
              caption: '',

              id: '97eb12dd-62f6-4345-964b-97d2edc75135',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ed9a291c-bcf4-4dec-8a71-80eee4292a58/ugc/31610e55-739c-4f18-8f42-bc24c48abc65/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '97a16185-d624-49cb-bb7e-411f68eafe43',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T11:28:35.593Z',

          createdAt: '2024-07-02T23:13:14.280Z',
          content: [
            {
              caption: '',

              id: 'f518514b-67c0-47a0-8c37-469fd2d1e31c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ed9a291c-bcf4-4dec-8a71-80eee4292a58/ugc/97a16185-d624-49cb-bb7e-411f68eafe43/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'af07b31c-96a0-4de5-849c-35848704c52b',
        firstName: 'Jamie',
        lastName: 'Eppinger',
        email: 'jamieeppinger1@yahoo.com',

        tiktokProfile: {
          handle: 'jamiexoxomarie',
          followers: 10066,
          engagementRate: 6.849413855171458
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T08:45:09.000Z',
        amountCents: 9962
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4d808fe0-1746-4ae0-bff7-11d383561e29',
      content: [
        {
          groupId: '2fb1ff75-1a9b-4347-ad37-4e0d26b885b4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-16T03:34:30.907Z',

          createdAt: '2024-07-11T04:50:39.255Z',
          content: [
            {
              caption: '',

              id: 'dc4bbdf0-3fdb-4423-8edc-6a58570a6044',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4d808fe0-1746-4ae0-bff7-11d383561e29/ugc/2fb1ff75-1a9b-4347-ad37-4e0d26b885b4/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5bea0a51-809c-481e-acc7-fe5c06032f2d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4d808fe0-1746-4ae0-bff7-11d383561e29/ugc/2fb1ff75-1a9b-4347-ad37-4e0d26b885b4/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '59dd2710-8e0d-4755-b0ca-0cc8cb3b0889',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4d808fe0-1746-4ae0-bff7-11d383561e29/ugc/2fb1ff75-1a9b-4347-ad37-4e0d26b885b4/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '64557ab4-4bf9-4970-a920-db476c6fee09',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-16T03:34:19.591Z',

          createdAt: '2024-07-11T04:49:45.127Z',
          content: [
            {
              caption: '',

              id: 'e89a9330-dea7-4426-b056-42b284aea6cf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4d808fe0-1746-4ae0-bff7-11d383561e29/ugc/64557ab4-4bf9-4970-a920-db476c6fee09/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3e00569c-444a-4892-89a3-53eaff93266c',
        firstName: 'Jermaine',
        lastName: 'Hawk',
        email: 'bookings@stix808.com',
        instagramProfile: {
          handle: 'stix_808',
          followers: 233698,
          engagementRate: 1.384393533534733
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T18:35:20.000Z',
        amountCents: 16536
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e0686581-69d1-4aba-a673-50cddcd3dc90',
      content: [
        {
          groupId: 'ff7ac3cd-856c-43c9-97a7-f378e7d114c1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-24T17:31:27.259Z',

          createdAt: '2024-07-18T17:51:01.993Z',
          content: [
            {
              caption: '',

              id: 'd0d68ee6-3250-4427-9399-5bcc1a75b33a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0686581-69d1-4aba-a673-50cddcd3dc90/ugc/ff7ac3cd-856c-43c9-97a7-f378e7d114c1/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6a5d9411-5504-4ee4-b53b-0fc348782d9a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0686581-69d1-4aba-a673-50cddcd3dc90/ugc/ff7ac3cd-856c-43c9-97a7-f378e7d114c1/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2067db01-080f-4bde-baad-978d7ba37c05',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0686581-69d1-4aba-a673-50cddcd3dc90/ugc/ff7ac3cd-856c-43c9-97a7-f378e7d114c1/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b034cab3-d577-4229-b41a-67f131e3e543',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T17:28:54.799Z',

          createdAt: '2024-07-18T17:49:57.018Z',
          content: [
            {
              caption: '',

              id: 'af5f2a53-faf0-4b8c-b7bd-a2201fd3cd47',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0686581-69d1-4aba-a673-50cddcd3dc90/ugc/b034cab3-d577-4229-b41a-67f131e3e543/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0c00de83-654a-4590-aef5-46fe6bf74e61',
        firstName: 'Dominiquea',
        lastName: 'Nogueira',
        email: 'dominiqueanogueira@outlook.com',
        instagramProfile: {
          handle: 'dominiqueanogueira',
          followers: 2496,
          engagementRate: 0.4286858974358974
        },
        tiktokProfile: {
          handle: 'dominiqueanogueira',
          followers: 185,
          engagementRate: 2.0626225738824644
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-11T20:32:57.000Z',
        amountCents: 16293
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bdf5a14d-826f-4d69-a90d-99d7a6128d7a',
      content: [],
      creator: {
        id: 'e5cb6d19-38c8-4edb-9f9e-049613e80626',
        firstName: 'Ayoehh',
        lastName: 'TV',
        email: 'ayoehhtv@gmail.com',
        instagramProfile: {
          handle: 'ayoehhtv',
          followers: 775,
          engagementRate: 5.032258064516129
        },
        tiktokProfile: {
          handle: 'ayoehhtv',
          followers: 19127,
          engagementRate: 2.7027968165378646
        },
        youtubeProfile: {
          handle: 'ayoehh',
          followers: 1530
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-14T21:54:52.000Z',
        amountCents: 16236
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9cf7aebe-ca6a-432e-96fe-20cb069d5d21',
      content: [
        {
          groupId: '886281a4-1e8a-408a-b626-dc7397e0ab34',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-25T02:58:43.937Z',

          createdAt: '2024-07-19T06:27:34.303Z',
          content: [
            {
              caption: '',

              id: '605c7863-6085-45f5-b2a0-08577b1e7958',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9cf7aebe-ca6a-432e-96fe-20cb069d5d21/ugc/886281a4-1e8a-408a-b626-dc7397e0ab34/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '271aef63-e159-44cf-bb62-bd0e0be6760f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-25T02:58:49.828Z',

          createdAt: '2024-07-19T02:16:39.589Z',
          content: [
            {
              caption: '',

              id: '8db31f32-29cb-48b4-b603-b3513f9fc393',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9cf7aebe-ca6a-432e-96fe-20cb069d5d21/ugc/271aef63-e159-44cf-bb62-bd0e0be6760f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bd2e2a45-f757-46ad-a9ab-782aae67b42b',
        firstName: 'Jannine',
        lastName: 'Saquiton',
        email: 'janniners@yahoo.com',
        instagramProfile: {
          handle: 'janniners',
          followers: 5013,
          engagementRate: 4.083383203670456
        },
        tiktokProfile: {
          handle: 'janniners_',
          followers: 4572,
          engagementRate: 5.097067719998878
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-17T00:12:54.000Z',
        amountCents: 16611
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5bda168e-4a6a-4827-9829-d4c6856c2174',
      content: [],
      creator: {
        id: '50585eb5-62b7-4c52-b1b5-db9729f3ada8',
        firstName: 'Neychea',
        lastName: 'Colvin',
        email: 'info.cheaandrea@gmail.com',
        instagramProfile: {
          handle: 'iamcheaandrea',
          followers: 21964,
          engagementRate: 1.4409943543981059
        },
        tiktokProfile: {
          handle: 'ilycheaandrea',
          followers: 785,
          engagementRate: 14.124293785310735
        },
        youtubeProfile: {
          handle: 'bossylifeempire',
          followers: 967
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T04:00:00.000Z',
        amountCents: 13000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c40a1b8a-dba7-4ece-8e7b-d6843536d172',
      content: [
        {
          groupId: '92fb93c4-735b-47f8-83f3-a2ffec5894e1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-07T23:17:32.186Z',

          createdAt: '2024-08-07T04:37:49.316Z',
          content: [
            {
              caption: '',

              id: 'cb5406d5-2997-4bba-acfa-f2dbbfa74818',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c40a1b8a-dba7-4ece-8e7b-d6843536d172/ugc/92fb93c4-735b-47f8-83f3-a2ffec5894e1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '200d7c68-30b9-4a80-8638-064eae1071a7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-07T23:17:36.664Z',

          createdAt: '2024-08-07T04:38:54.529Z',
          content: [
            {
              caption: '',

              id: '1ee261ca-1597-470f-9a04-7dab72d8ba42',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c40a1b8a-dba7-4ece-8e7b-d6843536d172/ugc/200d7c68-30b9-4a80-8638-064eae1071a7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6ae79a12-6835-4f21-a551-cea083a88a01',
        firstName: 'Adilene',
        lastName: 'Garibay',
        email: 'adilenegaribay@gmail.com',
        instagramProfile: {
          handle: 'lifewith_adi',
          followers: 10499,
          engagementRate: 0.6733974664253739
        },
        tiktokProfile: {
          handle: 'lifewith_adi',
          followers: 1076,
          engagementRate: 11.274118724699347
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T05:31:04.000Z',
        amountCents: 14071
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6629647f-3581-44a1-9a71-cceadd624181',
      content: [
        {
          groupId: '07acbd38-2fad-41f1-b29a-0c5d37950b45',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-12T18:39:47.756Z',

          createdAt: '2024-08-05T22:48:39.302Z',
          content: [
            {
              caption: '',

              id: 'af7f8de8-0723-4153-8c12-b33f7c652234',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6629647f-3581-44a1-9a71-cceadd624181/ugc/07acbd38-2fad-41f1-b29a-0c5d37950b45/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b656da35-7448-4e72-9208-0d1f31c7c2ac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6629647f-3581-44a1-9a71-cceadd624181/ugc/07acbd38-2fad-41f1-b29a-0c5d37950b45/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7297980b-87b6-44cf-9bcd-9581343b8245',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6629647f-3581-44a1-9a71-cceadd624181/ugc/07acbd38-2fad-41f1-b29a-0c5d37950b45/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '26137bdc-a6b7-4d2f-9c44-8f56f21d4120',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-12T18:39:40.895Z',

          createdAt: '2024-08-05T22:38:06.117Z',
          content: [
            {
              caption: '',

              id: 'd88f34a3-800d-4ce9-8874-1f13341aef51',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6629647f-3581-44a1-9a71-cceadd624181/ugc/26137bdc-a6b7-4d2f-9c44-8f56f21d4120/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '63a680e0-4830-4464-8f19-453b2d1b3be1',
        firstName: 'Mayra',
        lastName: 'Deringer',
        email: 'mayraderinger@hotmail.com',
        instagramProfile: {
          handle: 'mayraderinger',
          followers: 69825,
          engagementRate: 0.20938059434300035
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T16:15:10.000Z',
        amountCents: 16349
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a33d6d3a-8039-4764-9604-08b7c577c78f',
      content: [
        {
          groupId: '8c593cc9-2baf-4715-9332-a5da8ce4b123',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-02T03:06:42.012Z',

          createdAt: '2024-08-01T21:56:07.893Z',
          content: [
            {
              caption: '',

              id: '1068a52a-eb5a-4fd2-a8b8-5694ce0b8a42',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a33d6d3a-8039-4764-9604-08b7c577c78f/ugc/8c593cc9-2baf-4715-9332-a5da8ce4b123/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ef2f685d-8bf2-4a14-a9e9-275d561bcade',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a33d6d3a-8039-4764-9604-08b7c577c78f/ugc/8c593cc9-2baf-4715-9332-a5da8ce4b123/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b2ff69fa-84b0-4343-8538-ccd8c75b91ed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a33d6d3a-8039-4764-9604-08b7c577c78f/ugc/8c593cc9-2baf-4715-9332-a5da8ce4b123/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '508dd9a6-92aa-407e-9896-a5e1facca9f6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-02T03:06:38.582Z',

          createdAt: '2024-08-01T21:59:37.235Z',
          content: [
            {
              caption: '',

              id: '0b6357bf-33d2-4db4-b3fb-6afd219c16ea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a33d6d3a-8039-4764-9604-08b7c577c78f/ugc/508dd9a6-92aa-407e-9896-a5e1facca9f6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c023cb0c-1fad-4257-a611-4adf8b0a1d69',
        firstName: 'Selen',
        lastName: 'Ayaz',
        email: 'selenugc@gmail.com',
        instagramProfile: {
          handle: 'selenayazs',
          followers: 129851,
          engagementRate: 2.2471139998921843
        },
        tiktokProfile: {
          handle: 'selenayazugc',
          followers: 2844,
          engagementRate: 2.030456852791878
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-30T17:32:38.000Z',
        amountCents: 14999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0f6b0116-c7f8-4759-ab69-f9accbde18d1',
      content: [],
      creator: {
        id: '76aef590-02f4-49ce-98a0-3f3a964d233c',
        firstName: 'Joe & Delfina',
        lastName: 'Valdez',
        email: 'travelersintranslation@gmail.com',
        instagramProfile: {
          handle: 'travelersintranslation',
          followers: 19846,
          engagementRate: 0.8001612415600122
        },
        tiktokProfile: {
          handle: 'travelersintranslation',
          followers: 35,
          engagementRate: 1.6064257028112447
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    }
  ]
}

import { Divider, IconButton, Menu, MenuItem, MenuItemProps } from '@mui/material'
import { Stack } from '@mui/system'
import { GridMoreVertIcon } from '@mui/x-data-grid-premium'
import { useState } from 'react'

export type MoreMenuItem =
  | {
      menuItemProps?: MenuItemProps
      isHidden?: boolean
      isDivider?: never
    }
  | {
      isDivider: true
    }

const MoreMenu = ({ items }: { items: MoreMenuItem[] }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMoreMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMoreMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <IconButton onClick={handleMoreMenuClick} size={'small'} data-cy={'moreMenu'}>
        <GridMoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleMoreMenuClose}>
        {items?.map((item, index) => {
          if (item?.isDivider) {
            return <Divider key={index} />
          }
          return (
            !item.isHidden && (
              <MenuItem
                key={index}
                {...item.menuItemProps}
                onClick={e => {
                  handleMoreMenuClose()
                  item.menuItemProps?.onClick?.(e)
                }}
              />
            )
          )
        })}
      </Menu>
    </Stack>
  )
}

export default MoreMenu

import { SAMPLE_PRODUCT_KEYWORD_SUMMARIES_HEADPHONES } from './headphones'
import { SAMPLE_CAMPAIGN_SEO_WATER_FLOSSER_PRIME_DAY } from '@momentum/api/interceptors/listProductKeywordSummaries/sample-data/water-flosser-pd-launch'
import { ProductKeywordSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'
import { SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH } from '@momentum/api/interceptors/getCampaign/sample-data/water-flosser-pd-launch'
import { SAMPLE_CAMPAIGN_KEYWORDS_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/listProductKeywordSummaries/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getCampaign/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_KEYWORDS_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/listProductKeywordSummaries/sample-data/energy-drink-pd-existing'
import { SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/getCampaign/sample-data/energy-drink-pd-existing'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_KEYWORDS_WIRELESS_EARBUDS } from '@momentum/api/interceptors/listProductKeywordSummaries/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_KEYWORDS_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/listProductKeywordSummaries/sample-data/outdoor-camera-boost'

export const SAMPLE_PRODUCT_KEYWORD_SUMMARIES: Record<string, { items: ProductKeywordSummary[] }> = {
  'sample-campaign-headphones': SAMPLE_PRODUCT_KEYWORD_SUMMARIES_HEADPHONES,
  'sample-campaign-bluetooth-speaker': SAMPLE_PRODUCT_KEYWORD_SUMMARIES_HEADPHONES,
  [SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH.id]: SAMPLE_CAMPAIGN_SEO_WATER_FLOSSER_PRIME_DAY,
  [SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL.id]: SAMPLE_CAMPAIGN_KEYWORDS_KEYBOARD_REVIEWS_PL,
  [SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id]: SAMPLE_CAMPAIGN_KEYWORDS_ENERGY_DRINK_PD_EXISTING,
  [SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id]: SAMPLE_CAMPAIGN_KEYWORDS_OUTDOOR_CAMERA_BOOST,
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id]: SAMPLE_CAMPAIGN_KEYWORDS_WIRELESS_EARBUDS
}

import CardGrid from '@momentum/components/card-grid'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import DraftContentCard from './DraftContentCard'
import ApproveContentProvider from '../context/ApproveContentContext'
import { Form, SelectInput, TextInput } from '@productwindtom/ui-base'
import Row from '@momentum/components/row'
import { useMemo } from 'react'
import { isNumber } from 'lodash'
import { Channel, DraftStatus } from '@productwindtom/shared-momentum-zeus-types'
import { SocialChannelToString } from '@momentum/utils/socialUtils'
import { DRAFT_CONTENT_STATUS_CONFIG } from '../constants'
import { Search } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'
import { useWatch } from 'react-hook-form'
import { notEmpty } from '@productwindtom/shared-node'
import DraftEmptyState from './DraftEmptyState'

const DraftContentList = () => {
  const { campaignContentForApproval } = useCampaignContext()
  const { search, platform, status } = useWatch()

  const channels = useMemo(
    () =>
      campaignContentForApproval.reduce(
        (acc, { draftContent }) => {
          draftContent?.forEach(({ channel }) => {
            acc[channel] = isNumber(acc[channel]) ? acc[channel] + 1 : 1
          })

          return acc
        },
        {} as {
          [key in Channel]: number
        }
      ),
    [campaignContentForApproval]
  )

  const filteredContent = useMemo(() => {
    const items = campaignContentForApproval
      .map(c =>
        c.draftContent?.map(content => ({
          ...c,
          content
        }))
      )
      .flat()
      .filter(userContent => {
        if (!userContent) return false

        const { creator, content } = userContent!
        const { channel, draftStatus } = content

        const isStatusMatching = status ? draftStatus === status : true
        const isPlatformMatching = platform ? channel === platform : true
        const isSearchMatching = search
          ? [
              creator.firstName,
              creator.lastName,
              `@${creator.youtubeProfile?.handle}`,
              `@${creator.instagramProfile?.handle}`,
              `@${creator.tiktokProfile?.handle}`
            ]
              .filter(notEmpty)
              .join('')
              .toLowerCase()
              .includes(search.toLowerCase())
          : true

        return isStatusMatching && isPlatformMatching && isSearchMatching
      })

    return items
  }, [campaignContentForApproval, search, platform, status])

  return (
    <Stack spacing={3}>
      <Row spacing={2} width={'100%'}>
        <Box
          sx={{
            flex: '0 0 50%'
          }}
        >
          <TextInput
            fullWidth
            name="search"
            placeholder="Search"
            InputProps={{
              endAdornment: <Search />
            }}
          />
        </Box>
        <SelectInput
          fullWidth
          name="platform"
          placeholder="All platforms"
          options={Object.entries(channels).map(([key, value]) => ({
            label: `${SocialChannelToString[key as Channel]} (${value})`,
            value: key
          }))}
        />
        <SelectInput
          fullWidth
          name="status"
          placeholder="All statuses"
          options={Object.keys(DRAFT_CONTENT_STATUS_CONFIG).map(key => ({
            label: `${DRAFT_CONTENT_STATUS_CONFIG[key as keyof typeof DRAFT_CONTENT_STATUS_CONFIG].label}`,
            value: key
          }))}
        />
      </Row>
      {filteredContent.length ? (
        <CardGrid
          items={filteredContent.map(c => (
            <ApproveContentProvider key={c!.content.id!} {...c!}>
              <DraftContentCard />
            </ApproveContentProvider>
          ))}
        />
      ) : (
        <DraftEmptyState status={status} platform={platform} />
      )}
    </Stack>
  )
}

export default () => (
  <Form
    onSubmit={() => null}
    defaultValues={{
      status: DraftStatus.REQUIRES_BRAND_APPROVAL
    }}
  >
    <DraftContentList />
  </Form>
)

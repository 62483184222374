import { Box, List, ListItem, ListItemText, Paper, Popper } from '@mui/material'
import { TextInput } from '@productwindtom/ui-base'
import React from 'react'
import { useWatch } from 'react-hook-form'

export const BrandNameInput = ({
  existingBrandNames,
  name,
  primaryText = 'Brand name',
  placeholder = 'Brand name'
}: {
  existingBrandNames: string[]
  name: string
  primaryText?: string
  placeholder?: string
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const value = useWatch({ name })
  const similarNames = value ? existingBrandNames.filter(b => b.includes(value.toLowerCase())) : []

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleBlur = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <TextInput
        name={`name`}
        primaryText={primaryText}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputProps={{ autoComplete: 'off' }}
        fullWidth
      />
      <Popper id={`name`} open={!!similarNames.length && open} anchorEl={anchorEl} placement={'bottom-start'}>
        <Paper sx={{ px: 1, maxHeight: 200, overflow: 'auto' }}>
          <List>
            {similarNames.map((n, index) => (
              <ListItem key={index} disablePadding>
                <ListItemText primary={n} primaryTypographyProps={{ variant: 'body2' }} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popper>
    </Box>
  )
}

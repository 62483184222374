import { useFormContext } from 'react-hook-form'
import { Stack, Typography } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'

export const FileList = () => {
  const { watch } = useFormContext()

  const files = watch('creatorContentAssets')
  return (
    <Stack>
      {(files || []).map((f: File) => (
        <Stack key={f.name} direction={'row'} spacing={1}>
          <CheckCircleOutline color={'success'} fontSize={'mSmall'} />
          <Typography variant={'label4'} color={theme => theme.palette.grey.A700}>
            {f.name}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}

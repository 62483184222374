import { Stack, Typography } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import {
  useApproveCreatorsContext,
  CreatorTypeFilter
} from '@momentum/routes/campaign/my-actions/approve-creators/approveCreatorsContext'
import { CreatorApprovalStatus } from '@productwindtom/shared-momentum-zeus-types'

export const EmptyState = () => {
  const { approvalStatus, creatorTypeFilter } = useApproveCreatorsContext()

  const text =
    approvalStatus === CreatorApprovalStatus.PENDING
      ? 'You have no pending creators to review!'
      : approvalStatus === CreatorApprovalStatus.REJECTED
        ? `You haven’t rejected any ${creatorTypeFilter === CreatorTypeFilter.UGC ? 'UGC' : 'social'} creators.`
        : `You haven’t approved any ${creatorTypeFilter === CreatorTypeFilter.UGC ? 'UGC' : 'social'} creators.`

  return (
    <Stack
      py={12}
      alignItems={'center'}
      justifyContent={'center'}
      border={theme => `1px solid ${theme.palette.grey.A200}`}
      borderRadius={'4px'}
    >
      <Stack direction={'row'} spacing={1}>
        {approvalStatus === CreatorApprovalStatus.PENDING && <CheckCircleOutline color="success" />}
        <Typography variant={'h5'}>{text}</Typography>
      </Stack>
    </Stack>
  )
}

import { DateTime } from 'luxon'
import { TrafficData } from '../trafficContext'

export const LAUNDRY_TRAFFIC: TrafficData[] = [
  {
    date: '2023-08-01',
    momentumViews: 460,
    otherViews: 10885,
    totalViews: 12345
  },
  {
    date: '2023-08-02',
    momentumViews: 767,
    otherViews: 2056,
    totalViews: 3823
  },
  {
    date: '2023-08-03',
    momentumViews: 118,
    otherViews: 2235,
    totalViews: 4353
  },
  {
    date: '2023-08-04',
    momentumViews: 804,
    otherViews: 2239,
    totalViews: 4043
  },
  {
    date: '2023-08-05',
    momentumViews: 2932,
    otherViews: 2348,
    totalViews: 4280
  },
  {
    date: '2023-08-06',
    momentumViews: 2826,
    otherViews: 2057,
    totalViews: 3883
  },
  {
    date: '2023-08-07',
    momentumViews: 3886,
    otherViews: 3057,
    totalViews: 4943
  },
  {
    date: '2023-08-08',
    momentumViews: 2880,
    otherViews: 3357,
    totalViews: 5237
  },
  {
    date: '2023-08-09',
    momentumViews: 1885,
    otherViews: 3474,
    totalViews: 5359
  },
  {
    date: '2023-08-10',
    momentumViews: 1847,
    otherViews: 3838,
    totalViews: 5685
  },
  {
    date: '2023-08-11',
    momentumViews: 1795,
    otherViews: 4369,
    totalViews: 6164
  },
  {
    date: '2023-08-12',
    momentumViews: 1822,
    otherViews: 5930,
    totalViews: 7752
  },
  {
    date: '2023-08-13',
    momentumViews: 1806,
    otherViews: 5706,
    totalViews: 7512
  },
  {
    date: '2023-08-14',
    momentumViews: 1886,
    otherViews: 5390,
    totalViews: 7276
  },
  {
    date: '2023-08-15',
    momentumViews: 1899,
    otherViews: 6682,
    totalViews: 8581
  },
  {
    date: '2023-08-16',
    momentumViews: 1867,
    otherViews: 5090,
    totalViews: 6957
  },
  {
    date: '2023-08-17',
    momentumViews: 1916,
    otherViews: 5738,
    totalViews: 7654
  },
  {
    date: '2023-08-18',
    momentumViews: 2055,
    otherViews: 5867,
    totalViews: 7922
  },
  {
    date: '2023-08-19',
    momentumViews: 2074,
    otherViews: 10033,
    totalViews: 12107
  },
  {
    date: '2023-08-20',
    momentumViews: 2063,
    otherViews: 9560,
    totalViews: 11623
  },
  {
    date: '2023-08-21',
    momentumViews: 2197,
    otherViews: 8340,
    totalViews: 10537
  },
  {
    date: '2023-08-22',
    momentumViews: 2318,
    otherViews: 7746,
    totalViews: 10064
  },
  {
    date: '2023-08-23',
    momentumViews: 2873,
    otherViews: 7261,
    totalViews: 10134
  },
  {
    date: '2023-08-24',
    momentumViews: 3799,
    otherViews: 8083,
    totalViews: 11882
  },
  {
    date: '2023-08-25',
    momentumViews: 3806,
    otherViews: 8373,
    totalViews: 12179
  },
  {
    date: '2023-08-26',
    momentumViews: 3651,
    otherViews: 8054,
    totalViews: 11705
  },
  {
    date: '2023-08-27',
    momentumViews: 4324,
    otherViews: 8098,
    totalViews: 12422
  },
  {
    date: '2023-08-28',
    momentumViews: 3818,
    otherViews: 16412,
    totalViews: 20230
  },
  {
    date: '2023-08-29',
    momentumViews: 3892,
    otherViews: 17901,
    totalViews: 21793
  },
  {
    date: '2023-08-30',
    momentumViews: 3857,
    otherViews: 16208,
    totalViews: 20065
  },
  {
    date: '2023-08-31',
    momentumViews: 3469,
    otherViews: 20580,
    totalViews: 24049
  },
  {
    date: '2023-09-01',
    momentumViews: 2927,
    otherViews: 19076,
    totalViews: 22003
  },
  {
    date: '2023-09-02',
    momentumViews: 2780,
    otherViews: 11284,
    totalViews: 14064
  },
  {
    date: '2023-09-03',
    momentumViews: 3227,
    otherViews: 10775,
    totalViews: 14002
  },
  {
    date: '2023-09-04',
    momentumViews: 2434,
    otherViews: 10464,
    totalViews: 12898
  },
  {
    date: '2023-09-05',
    momentumViews: 4455,
    otherViews: 10536,
    totalViews: 14991
  },
  {
    date: '2023-09-06',
    momentumViews: 2814,
    otherViews: 10909,
    totalViews: 13723
  },
  {
    date: '2023-09-07',
    momentumViews: 2834,
    otherViews: 10464,
    totalViews: 13298
  },
  {
    date: '2023-09-08',
    momentumViews: 2938,
    otherViews: 10810,
    totalViews: 13748
  },
  {
    date: '2023-09-09',
    momentumViews: 3009,
    otherViews: 10048,
    totalViews: 13057
  },
  {
    date: '2023-09-10',
    momentumViews: 3060,
    otherViews: 10217,
    totalViews: 13277
  },
  {
    date: '2023-09-11',
    momentumViews: 2627,
    otherViews: 10776,
    totalViews: 13403
  },
  {
    date: '2023-09-12',
    momentumViews: 2666,
    otherViews: 10904,
    totalViews: 13570
  },
  {
    date: '2023-09-13',
    momentumViews: 2705,
    otherViews: 13367,
    totalViews: 16072
  },
  {
    date: '2023-09-14',
    momentumViews: 2902,
    otherViews: 13358,
    totalViews: 16260
  },
  {
    date: '2023-09-15',
    momentumViews: 3299,
    otherViews: 12014,
    totalViews: 15313
  },
  {
    date: '2023-09-16',
    momentumViews: 4165,
    otherViews: 13569,
    totalViews: 17734
  },
  {
    date: '2023-09-17',
    momentumViews: 4321,
    otherViews: 12088,
    totalViews: 16409
  },
  {
    date: '2023-09-18',
    momentumViews: 4568,
    otherViews: 13120,
    totalViews: 17688
  },
  {
    date: '2023-09-19',
    momentumViews: 4177,
    otherViews: 15554,
    totalViews: 19731
  }
].map(d => ({ ...d, date: DateTime.fromISO(d.date) }))

import { Alert, Stack, Typography } from '@mui/material'
import React from 'react'
import { CreditDisplay } from '@momentum/components/credit-display'

export const CreditPayment = ({
  creditsRemaining,
  totalCostCredits
}: {
  creditsRemaining: number
  totalCostCredits: number
}) => {
  return (
    <Stack spacing={4}>
      <Stack direction={'row'} spacing={5} alignItems={'center'}>
        <CreditDisplay value={creditsRemaining.toLocaleString()} text={'Credits remaining'} color={'secondary'} data-cy="creditsRemaining" />
        <CreditDisplay value={totalCostCredits.toLocaleString()} text={'Credits for this campaign'} color={'primary'} data-cy="creditsRequired" />
      </Stack>
      {totalCostCredits > creditsRemaining && (
        <Alert severity={'warning'} variant={'outlined'}>
          <Typography color={'black'} variant={'label3'}>
            You have insufficient credits. Add credits or select another payment method.
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}

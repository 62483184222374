import { yupResolver } from '@hookform/resolvers/yup'
import { ROUTES } from '@momentum/routes/RouteNames'
import background from '@momentum/routes/account/background-gradient.png'
import { Box, Container, Typography, useTheme, Link } from '@mui/material'
import { Stack } from '@mui/system'
import { Form, FormMethodsType, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { Auth } from 'aws-amplify'
import { generatePath, useNavigate, Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import LogoWhiteIcon from '@momentum/components/icons/logo-white'
import React, { useState } from 'react'
import { MfaDialog, MfaUser } from '@momentum/components/mfa-dialog'

type LoginData = {
  email: string
  password: string
}

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required('Required')
    .email('Invalid email')
    .transform(value => value?.toLowerCase()?.trim()),
  password: yup.string().required('Required').min(8, 'Must be at least 8 characters long')
})

export default function Login() {
  const navigate = useNavigate()
  const theme = useTheme()

  const [mfaOpen, setMfaOpen] = useState(false)
  const [user, setUser] = useState<MfaUser>()
  const [credentials, setCredentials] = useState<{ email: string; password: string }>()

  const onSubmit = async (data: LoginData, { setError }: FormMethodsType<LoginData>) => {
    try {
      const resp = await Auth.signIn(data.email, data.password)
      if (resp.challengeName === 'SMS_MFA') {
        setCredentials(data)
        setUser(resp)
        setMfaOpen(true)
      } else {
        toast(
          <Typography data-cy={'successfulLoginText'} variant={'subtitle2'}>
            Successfully logged in!
          </Typography>,
          { type: 'success' }
        )
      }
    } catch (e: any) {
      console.log(e)

      if (e.message.includes('ACCOUNT_LOCKED')) {
        toast(
          <Typography variant={'subtitle2'}>
            This account is locked. Please contact your client success manager.
          </Typography>,
          { type: 'error' }
        )
      } else if (e.message.includes('User is disabled')) {
        toast(
          <Typography variant={'subtitle2'}>
            This account no longer exists. Please contact Customer Success.
          </Typography>,
          {
            type: 'error'
          }
        )
      } else if (e.message.includes('PASSWORD_EXPIRED')) {
        toast(<Typography variant={'subtitle2'}>Your password has expired. Please reset your password.</Typography>, {
          type: 'error'
        })
        navigate(ROUTES.FORGOT_PASSWORD)
      } else {
        switch (e.code) {
          case 'UserNotFoundException':
            setError('email', { message: 'User does not exist' })
            break
          case 'NotAuthorizedException':
            setError('password', { message: 'Incorrect password' })
            break
          case 'UserNotConfirmedException':
            await Auth.resendSignUp(data.email)
            navigate(generatePath(ROUTES.VERIFY) + `?email=${data.email}`)
            break
        }
      }
    }
  }

  return (
    <Container maxWidth={'xl'} sx={{ height: '100vh', alignItems: 'center' }}>
      <Stack direction={'row'} spacing={10} height={'100vh'}>
        <Box py={2} width={'70%'} display={'inline-flex'}>
          <Box
            bgcolor={theme.palette.primary.main}
            borderRadius={'16px'}
            display={'inline-flex'}
            width={'100%'}
            position={'relative'}
          >
            <Stack direction={'row'} spacing={1} alignItems={'center'} position={'absolute'} left={24} top={24}>
              <LogoWhiteIcon fontSize={'large'} />
              <Typography variant={'h3'} mb={2} color={'white'}>
                momentum
              </Typography>
            </Stack>
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              spacing={2}
              width={'100%'}
              sx={{
                background: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            >
              <Typography color={'white'} variant={'h2'}>
                Welcome back
              </Typography>
              <Typography color={'white'} variant={'subtitle2'} width={'226px'}>
                Log in to Momentum to launch new products and drive sales.
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box alignSelf={'center'} width={'30%'}>
          <Form onSubmit={onSubmit} defaultValues={{ email: '', password: '' }} resolver={yupResolver(loginSchema)}>
            <Stack spacing={2}>
              <Typography variant={'h2'} textAlign={'center'}>
                Login
              </Typography>
              <TextInput
                data-cy={'email'}
                id={'email'}
                name={'email'}
                primaryText={'Email'}
                fullWidth
                type="email"
                autoComplete={'username'}
              />
              <TextInput
                data-cy={'password'}
                id={'password'}
                name={'password'}
                primaryText={'Password'}
                type={'password'}
                fullWidth
                autoComplete={'current-password'}
              />

              <Box>
                <SubmitButton data-cy={'submit'} id={'submit'} variant={'contained'}>
                  Log in
                </SubmitButton>
              </Box>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant={'label3'}>Trouble logging in?</Typography>
                <Link
                  data-cy={'forgotPasswordLink'}
                  id={'forgotPassword'}
                  component={RouterLink}
                  variant={'link1'}
                  color={'primary'}
                  to={ROUTES.FORGOT_PASSWORD}
                >
                  Forgot password
                </Link>
              </Stack>
            </Stack>
          </Form>
        </Box>
      </Stack>
      {!!user && <MfaDialog open={mfaOpen} onClose={() => setMfaOpen(false)} user={user} credentials={credentials} />}
    </Container>
  )
}

import {
  BrandApiType,
  BrandApiVersion,
  CreditAction,
  ModelTypes,
  Region
} from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_PRODUCTS_BY_BRAND_ID } from '../../listProductsByBrandId/sample-data'
import { BRANDS } from '../../types'

export const SAMPLE_BRANDS: { [key: string]: ModelTypes['Brand'] } = {
  [BRANDS.CE]: {
    id: BRANDS.CE,
    companyId: 'sample-company',
    name: 'Sample Brand CE',
    logo: 'https://media.productwind.com/sample/images/company.png',
    region: Region.US,
    amazonBrandStoreUrl: 'https://www.amazon.com/stores/page/01EFDE77-7D1A-41D7-9EEA-7C2FA22AB12F/search?terms=*',
    isBrandStoreScrapeEnabled: true,
    pricing: [],
    paymentBillingContact: {
      name: 'Alexander Smith',
      email: 'alexander-smith@productwind.com'
    },
    lastScrapedAt: '2024-03-18T13:24:18.762Z',
    subscriptionStartsAt: '2024-03-18T13:24:18.762Z',
    subscriptionEndsAt: '2025-03-18T13:24:18.762Z',
    subscriptionCredits: [
      {
        id: 'sample-credit-add',
        actionId: 'sample-action-id-add',
        action: CreditAction.ADD,
        numCredits: 200000,
        actionCreatedById: 'sample-user-smith',
        actionCreatedByFirstName: 'Alexander',
        actionCreatedByLastName: 'Smith',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z'
      },
      {
        id: 'sample-credit-add',
        actionId: 'sample-action-id-add',
        action: CreditAction.REMOVE,
        numCredits: 50000,
        actionCreatedById: 'sample-user-smith',
        actionCreatedByFirstName: 'Alexander',
        actionCreatedByLastName: 'Smith',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z'
      },
      {
        id: 'sample-credit-remove',
        actionId: 'sample-action-id-remove',
        action: CreditAction.REMOVE,
        numCredits: 50000,
        actionCreatedById: 'sample-user-smith',
        actionCreatedByFirstName: 'Alexander',
        actionCreatedByLastName: 'Smith',
        createdAt: '2023-06-25T15:21:54.650Z',
        updatedAt: '2023-06-25T15:21:54.650Z'
      }
    ],
    brandApis: [
      {
        brandId: BRANDS.CE,
        api: BrandApiType.amazon_na_seller,
        apiVersion: BrandApiVersion.v1,
        enabled: true,
        isIntegrated: true,
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z'
      },
      {
        brandId: BRANDS.CE,
        api: BrandApiType.amazon_na_seller,
        apiVersion: BrandApiVersion.v1,
        enabled: false,
        isIntegrated: false,
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z'
      }
    ],
    products: SAMPLE_PRODUCTS_BY_BRAND_ID[BRANDS.CE],
    proposals: [],
    createdAt: '2023-06-22T15:21:54.650Z',
    updatedAt: '2023-06-22T15:21:54.650Z'
  },
  [BRANDS.CPG]: {
    id: BRANDS.CPG,
    companyId: 'sample-company',
    name: 'Sample Brand CPG',
    logo: 'https://media.productwind.com/sample/images/company.png',
    region: Region.US,
    amazonBrandStoreUrl:
      'https://www.amazon.com/stores/page/EF600F7F-419B-43DE-93D2-E5BA7B742402/search?ref_=ast_bln&terms=*',
    isBrandStoreScrapeEnabled: true,
    pricing: [],
    paymentBillingContact: {
      name: 'Alexander Smith',
      email: 'alexander-smith@productwind.com'
    },
    lastScrapedAt: '2024-03-18T13:24:18.762Z',
    subscriptionStartsAt: '2024-03-18T13:24:18.762Z',
    subscriptionEndsAt: '2025-03-18T13:24:18.762Z',
    subscriptionCredits: [
      {
        id: 'sample-credit-add',
        actionId: 'sample-action-id-add',
        action: CreditAction.ADD,
        numCredits: 100000,
        actionCreatedById: 'sample-user-smith',
        actionCreatedByFirstName: 'Alexander',
        actionCreatedByLastName: 'Smith',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z'
      },
      {
        id: 'sample-credit-remove',
        actionId: 'sample-action-id-remove',
        action: CreditAction.REMOVE,
        numCredits: 50000,
        actionCreatedById: 'sample-user-smith',
        actionCreatedByFirstName: 'Alexander',
        actionCreatedByLastName: 'Smith',
        createdAt: '2023-06-25T15:21:54.650Z',
        updatedAt: '2023-06-25T15:21:54.650Z'
      }
    ],
    brandApis: [
      {
        brandId: BRANDS.CPG,
        api: BrandApiType.amazon_na_seller,
        apiVersion: BrandApiVersion.v1,
        enabled: true,
        isIntegrated: true,
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z'
      },
      {
        brandId: BRANDS.CPG,
        api: BrandApiType.amazon_na_seller,
        apiVersion: BrandApiVersion.v1,
        enabled: false,
        isIntegrated: false,
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z'
      }
    ],
    products: SAMPLE_PRODUCTS_BY_BRAND_ID[BRANDS.CPG],
    proposals: [],
    createdAt: '2023-06-22T15:21:54.650Z',
    updatedAt: '2023-06-22T15:21:54.650Z'
  }
}

// // This is a *temporary* implementation of basic brands to display for agencies
export const AGENCY_SAMPLE_BRANDS: { [key: string]: any } = {
  'sample-brand-1': {
    id: 'sample-brand-1',
    companyId: 'sample-company-electronics',
    name: 'Sample Brand Electronics',
    logo: 'https://media.productwind.com/sample/images/sample-bg-pink.png',
    region: Region.US,
    createdAt: '2023-06-22T15:21:54.650Z',
    updatedAt: '2023-06-22T15:21:54.650Z',
    brandApis: [],
    proposals: [],
    pricing: []
  },
  'sample-brand-2': {
    id: 'sample-brand-2',
    companyId: 'sample-company-electronics',
    name: 'Sample Brand Speakers',
    logo: 'https://media.productwind.com/sample/images/sample-bg-pink.png',
    region: Region.US,
    createdAt: '2023-06-22T15:21:54.650Z',
    updatedAt: '2023-06-22T15:21:54.650Z',
    brandApis: [],
    proposals: [],
    pricing: []
  },
  'sample-brand-3': {
    id: 'sample-brand-3',
    companyId: 'sample-company-kitchen',
    name: 'Sample Brand Dishes',
    logo: 'https://media.productwind.com/sample/images/sample-bg-blue.png',
    region: Region.US,
    createdAt: '2023-06-22T15:21:54.650Z',
    updatedAt: '2023-06-22T15:21:54.650Z',
    brandApis: [],
    proposals: [],
    pricing: []
  }
}

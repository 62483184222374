import { useBrandIntegrationHandler } from '@momentum/hooks/useBrandIntegrationHandler'
import { getSellerBrandApi, getVendorBrandApi } from '@momentum/utils/brandApiUtils'
import { CheckCircleOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Stack, Typography, useTheme } from '@mui/material'
import { BrandApiType, BrandApiVersion } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { useState } from 'react'
import { Brand, BrandApi } from '../queries'

export const BrandIntegrations = ({ brand, readOnly }: { brand: Brand; readOnly: boolean }) => {
  const theme = useTheme()
  const [integrationLoading, setIntegrationLoading] = useState<BrandApiType>()

  const handleIntegration = useBrandIntegrationHandler()

  const vendorBrandApi = getVendorBrandApi(brand.brandApis)
  const sellerBrandApi = getSellerBrandApi(brand.brandApis)

  if (!vendorBrandApi && !sellerBrandApi) {
    return null
  }

  const onIntegrationClicked = async (brandApi: BrandApi) => {
    try {
      setIntegrationLoading(brandApi.api)
      await handleIntegration(brandApi)
    } finally {
      setIntegrationLoading(undefined)
    }
  }

  return (
    <Stack spacing={2}>
      <Typography variant={'h4'}>Retailer integrations</Typography>

      <Stack border={1} borderRadius={'4px'} borderColor={theme.palette.grey.A200} p={3} spacing={3}>
        <Typography variant={'label3'}>Integrate your retailer accounts to run your Momentum campaigns.</Typography>
        {[
          vendorBrandApi && {
            title: 'Amazon vendor account',
            buttonText: 'Link vendor account',
            api: vendorBrandApi
          },
          sellerBrandApi && {
            title: 'Amazon seller account',
            buttonText: 'Link seller account',
            api: sellerBrandApi
          }
        ]
          .filter(notEmpty)
          .map(({ title, api, buttonText }) => {
            return (
              <Stack key={api.api} spacing={1}>
                <Typography variant={'label3'}>{title}</Typography>
                {api.isIntegrated && api.apiVersion === BrandApiVersion.v3 ? (
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <CheckCircleOutlined color={'success'} />
                    <Typography variant={'label3'}>Linked!</Typography>
                  </Stack>
                ) : !readOnly ? (
                  <Stack spacing={1} alignItems="flex-start">
                    <LoadingButton
                      data-cy={`linkAccountButton-${api.api}`}
                      variant="contained"
                      onClick={() => onIntegrationClicked(api)}
                      sx={{
                        alignSelf: 'start'
                      }}
                      loading={api.api === integrationLoading}
                      disabled={!!integrationLoading}
                    >
                      {buttonText}
                    </LoadingButton>
                    {!!api.isIntegrated && (
                      <Alert
                        severity="warning"
                        variant="outlined"
                        sx={{
                          bgcolor: '#FDF4DC',
                          color: 'black'
                        }}
                      >
                        <Typography variant="label3">Reintegrate your {title}</Typography>
                      </Alert>
                    )}
                  </Stack>
                ) : (
                  <Typography variant={'label3'}>Not linked</Typography>
                )}
              </Stack>
            )
          })}
      </Stack>
    </Stack>
  )
}

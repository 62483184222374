import { IntegratePrompt } from '@momentum/components/integrate-prompt'
import { ROUTES } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Button, Stack, Typography } from '@mui/material'
import { Link as RouterLink, generatePath } from 'react-router-dom'

export const NotIntegrated = () => {
  const { brand } = useBrandContext()
  return (
    <Stack alignItems={'center'} spacing={3} pt={12}>
      <IntegratePrompt />
      <Typography variant={'h5'} textAlign={'center'} maxWidth={'442px'}>
        Link your Amazon seller account to display the real-time traffic dashboard.
      </Typography>
      <Button
        variant={'contained'}
        component={RouterLink}
        to={generatePath(ROUTES.BRAND_PROFILE, { brandId: brand.id })}
      >
        Link seller account
      </Button>
    </Stack>
  )
}

import { mApi } from '@momentum/api'
import AmazonIcon from '@momentum/components/icons/amazon'
import WalmartIcon from '@momentum/components/icons/walmart'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { updateBrand } from '@momentum/routes/brand-profile/queries'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { STORE_DETAILS } from '@momentum/utils/storeUtils'
import { InfoOutlined } from '@mui/icons-material'
import { Paper, Tooltip, Typography } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Stack } from '@mui/system'
import { Store } from '@productwindtom/shared-momentum-zeus-types'
import { Form, SubmitButton, SwitchInput } from '@productwindtom/ui-base'
import { isBoolean } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'

type RefreshRecommendationsFormData = {
  scrapeEnabled: boolean
}

const RefreshRecommendations = () => {
  const [store, setStore] = useState(Store.amazon)
  const { refreshBrand } = useUserSessionContext()

  const handleChange = (event: React.SyntheticEvent, store: Store) => {
    setStore(store)
  }
  const { brand, amazonScrapedAt, walmartScrapedAt } = useBrandContext()
  const isBrandStoreScrapeEnabled = brand?.isBrandStoreScrapeEnabled
  const isWalmartScrapeEnabled = brand?.isWalmartScrapeEnabled

  const handleRefreshRecommendations = async () => {
    try {
      const { isSuccess } = (
        await mApi('mutation')({
          refreshRecommendations: [
            {
              brandId: brand.id,
              stores: [store]
            },
            {
              isSuccess: true
            }
          ]
        })
      ).refreshRecommendations

      if (!isSuccess) {
        throw new Error('Refresh failed.')
      }

      toast(
        <Typography variant={'subtitle2'}>Recommendations are being refreshed! Check back in 1-2 hours.</Typography>,
        { type: 'success' }
      )
    } catch (e: any) {
      toast(
        <Typography variant={'subtitle2'}>Recommendations refresh has failed, please try again later.</Typography>,
        { type: 'error' }
      )
      console.log(e.message)
    }
  }

  const handleScrapeEnabled = async (enabled: boolean) => {
    const input = {
      id: brand.id,
      ...(store === Store.walmart
        ? {
            isWalmartScrapeEnabled: enabled
          }
        : {
            isBrandStoreScrapeEnabled: enabled
          })
    }

    await updateBrand(input)

    await refreshBrand(brand.id)
  }

  const scrapeEnabled = store === Store.walmart ? isWalmartScrapeEnabled : isBrandStoreScrapeEnabled

  return (
    <Stack
      px={3}
      py={2}
      border={theme => `1px solid ${theme.palette.grey.A200}`}
      borderRadius={'6px'}
      alignItems={'end'}
      component={Paper}
      spacing={2}
    >
      <Tabs value={store} onChange={handleChange}>
        <Tab
          label={
            <Stack direction={'row'} spacing={1}>
              <AmazonIcon width={12} height={12} />
              <Typography variant="inherit">Amazon</Typography>{' '}
            </Stack>
          }
          value={Store.amazon}
        />
        <Tab
          label={
            <Stack direction={'row'} spacing={1}>
              <WalmartIcon width={12} height={12} />
              <Typography variant="inherit">Walmart</Typography>{' '}
            </Stack>
          }
          value={Store.walmart}
          sx={{
            mx: 0
          }}
        />
      </Tabs>
      <Form
        key={brand.id}
        onSubmit={handleRefreshRecommendations}
        defaultValues={{
          scrapeEnabled
        }}
      >
        <FormBody
          store={store}
          scrapeEnabled={!!scrapeEnabled}
          lastScrapedAt={store === Store.walmart ? walmartScrapedAt : amazonScrapedAt}
          onScrapeEnabled={handleScrapeEnabled}
        />
      </Form>
    </Stack>
  )
}

export default RefreshRecommendations

const FormBody = ({
  store,
  scrapeEnabled,
  lastScrapedAt,
  onScrapeEnabled
}: {
  store: Store
  scrapeEnabled: boolean
  lastScrapedAt?: string
  onScrapeEnabled: (enabled: boolean) => Promise<void>
}) => {
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false)

  const {
    reset,
    formState: { isDirty }
  } = useFormContext<RefreshRecommendationsFormData>()

  const scrapeEnabledWatch = useWatch({ name: 'scrapeEnabled' })

  useEffect(() => {
    reset({
      scrapeEnabled
    })
  }, [store])

  useEffect(() => {
    if (isDirty && isBoolean(scrapeEnabledWatch)) {
      setIsSwitchDisabled(true)
      ;(async () => {
        try {
          await onScrapeEnabled(scrapeEnabledWatch)

          reset({
            scrapeEnabled: scrapeEnabledWatch
          })
        } catch (err) {
          reset()
          toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
            type: 'error'
          })
        } finally {
          setIsSwitchDisabled(false)
        }
      })()
    }
  }, [scrapeEnabledWatch, isDirty, reset])

  return (
    <Stack spacing={2}>
      <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
        <Stack direction={'row'} alignItems={'center'} spacing={5}>
          <Stack pr={7} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Tooltip
              title={
                'Turn on weekly recommendations & alerts ONLY for active Enterprise clients and prospects. Weekly recommendations are refreshed every Sunday night.'
              }
            >
              <InfoOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
            </Tooltip>
            <SwitchInput name="scrapeEnabled" disabled={isSwitchDisabled} />
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
            <Tooltip
              title={
                'Products & recommendations will be refreshed in 1-2 hours. Only refresh recommendations if you are actively talking to the brand.'
              }
            >
              <InfoOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
            </Tooltip>
            <SubmitButton disableOnDirty={false} variant="outlined">
              Refresh {STORE_DETAILS[store].name} products and recommendations
            </SubmitButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
        <Typography variant="label3">Refresh weekly: {scrapeEnabledWatch ? 'On' : 'Off'}</Typography>
        <Typography variant="label3">
          Last updated: {lastScrapedAt ? DateTime.fromISO(lastScrapedAt).toLocaleString(DateTime.DATE_SHORT) : '--'}
        </Typography>
      </Stack>
    </Stack>
  )
}

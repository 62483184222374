import { Form, SubmitButton, NumberInput } from '@productwindtom/ui-base'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/system'
import * as yup from 'yup'
import { CreditAction } from '@productwindtom/shared-momentum-zeus-types'
import { useWatch } from 'react-hook-form'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { BackButton } from '@momentum/components/back-button'

const schema = (creditsRemaining: number) =>
  yup
    .object({
      action: yup.string<CreditAction>().required('Required'),
      numCredits: yup
        .number()
        .required('Required')
        .when('action', {
          is: CreditAction.REMOVE,
          then: schema => schema.max(creditsRemaining, 'You do not have enough credits to use'),
          otherwise: schema => schema
        })
    })
    .noUnknown(true)
export type AdjustCreditsFormData = {
  action: CreditAction
  numCredits: number
}

export const AdjustCreditsForm = ({
  action,
  creditsRemaining,
  onSubmit,
  onCancel
}: {
  action: CreditAction
  creditsRemaining: number
  onCancel: () => void
  onSubmit: (data: AdjustCreditsFormData) => Promise<void>
}) => {
  const handleSubmit = async (submitValues: AdjustCreditsFormData) => {
    await onSubmit(submitValues)
  }

  const defaultValues = {
    action
  }
  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema(creditsRemaining))}>
      <Stack spacing={3}>
        <FormBody creditsRemaining={creditsRemaining} />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} />
          <SubmitButton variant={'contained'} disableOnDirty={false}>
            Submit
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

const FormBody = ({ creditsRemaining }: { creditsRemaining: number }) => {
  const { brands, selectedCompany } = useUserSessionContext()
  const companyBrands = brands.filter(b => b.companyId === selectedCompany?.id)
  const data = useWatch()
  return (
    <Stack spacing={2}>
      <NumberInput
        returnAsNumber
        max={data.action === CreditAction.REMOVE ? creditsRemaining : undefined}
        decimalScale={0}
        name={'numCredits'}
        primaryText={`Enter the amount of credits you want to ${data.action === CreditAction.REMOVE ? 'use' : 'add'}`}
      />
    </Stack>
  )
}

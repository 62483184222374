import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_BRANDS } from '../../getBrand/sample-data'

export const SAMPLE_SUBSCRIPTION_CREDIT_ACTIONS: Record<string, ModelTypes['ModelItemsSubscriptionCreditAction']> = {
  ['sample-company']: {
    items: Object.values(SAMPLE_BRANDS)
      .map(brand => brand.subscriptionCredits)
      .flat()
  },
  ...Object.values(SAMPLE_BRANDS).reduce(
    (acc, brand) => ({
      ...acc,
      [brand.id]: {
        items: brand.subscriptionCredits
      }
    }),
    {}
  )
}

import { Controller, useFormContext } from 'react-hook-form'
import InputBase from '.'
import { VariantInputBaseProps } from './types'

export const withInputBase =
  <T extends VariantInputBaseProps>(Component: React.ComponentType<T>) =>
  (props: React.PropsWithChildren<T>) => {
    const { control } = useFormContext()
    const { name, disabled, primaryText, subtext, fullWidth, variant, hideError } = props

    return (
      <InputBase {...{ name, disabled, primaryText, subtext, fullWidth, variant, hideError }}>
        <Controller
          {...{ name, control }}
          render={({ field }) => {
            return (
              <Component
                {...props}
                field={{
                  ...field,
                  value: field.value === undefined ? '' : field.value
                }}
              />
            )
          }}
        />
      </InputBase>
    )
  }

import { Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { first, sumBy, last } from 'lodash'
import { useReviewsContext, TopLevelViewType } from '@momentum/routes/campaign/e-commerce/reviews/reviewsContext'
import { CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'
import { Metric } from '../common/Metric'
import { getCampaignReviewMetrics } from '@productwindtom/shared-momentum'

const timelineReviewsGoal = 25

export const Summary = () => {
  const { campaignDetails } = useCampaignContext()
  const { reviewsData, topLevelViewType } = useReviewsContext()
  const now = DateTime.now().toISODate()
  const startDateDate = DateTime.fromISO(campaignDetails.startDate).toISODate()!
  const endDateDate = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate).toISODate()! : now

  const startOfCampaignData = reviewsData.filter(d => d.fromDate >= startDateDate)

  const firstRecord = first(startOfCampaignData)
  const lastRecord = last(startOfCampaignData.filter(d => d.toDate <= endDateDate))

  const sumMomentumRatings = sumBy(reviewsData, r => r.momentumReviewCount) // + r.momentumRatingCount)
  const daysToHitGoal = determineDaysToHitMomentumGoal(
    startOfCampaignData,
    campaignDetails.expectedReviewsCount || timelineReviewsGoal
  )
  const daysToHitAnyReviewsGoal = determineDaysToReviewsHitGoal(
    startOfCampaignData,
    campaignDetails.expectedReviewsCount || timelineReviewsGoal
  )

  const { weightedAverage } = getCampaignReviewMetrics(reviewsData)

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant={'h4'}>Campaign reviews summary</Typography>
          <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
            Last updated: {DateTime.now().minus({ days: 1 }).toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        {topLevelViewType === TopLevelViewType.LISTING && (
          <>
            <Metric
              label={`from ${firstRecord?.numRatings?.toLocaleString()} before campaign`}
              value={lastRecord?.numRatings ? `${lastRecord.numRatings.toLocaleString()} reviews & ratings` : '--'}
            />
            <Metric
              label={`from ${firstRecord?.rating?.toFixed(1)} before campaign`}
              value={lastRecord ? `${lastRecord.rating.toFixed(1)} star rating` : '--'}
            />
            <Metric
              label={`to get ${(campaignDetails.expectedReviewsCount || timelineReviewsGoal).toLocaleString()} reviews`}
              value={daysToHitAnyReviewsGoal ? `${daysToHitAnyReviewsGoal.toLocaleString()} days` : '--'}
            />
          </>
        )}
        {topLevelViewType === TopLevelViewType.MOMENTUM && (
          <>
            <Metric
              label={'from ProductWind'}
              value={sumMomentumRatings ? `${sumMomentumRatings.toLocaleString()} reviews & ratings` : '--'}
            />
            <Metric
              label={'avg ProductWind rating'}
              value={weightedAverage ? `${weightedAverage.toFixed(1)} star rating` : '--'}
            />
            <Metric
              label={`to get ${(campaignDetails.expectedReviewsCount || timelineReviewsGoal).toLocaleString()} ProductWind reviews`}
              value={daysToHitGoal ? `${daysToHitGoal.toLocaleString()} days` : '--'}
            />
          </>
        )}
      </Stack>
    </Stack>
  )
}

const determineDaysToHitMomentumGoal = (reviewsData: CampaignProductReviewRatingMetric[], goal: number) => {
  if (!reviewsData.length) return undefined

  const cumulativeSum = reviewsData.map(
    (p => value => ({ sum: (p.sum += value.momentumReviewCount), date: value.fromDate }))({ date: undefined, sum: 0 })
  )
  const dateWhenMet = cumulativeSum.find(s => s.sum >= goal)?.date

  if (dateWhenMet) {
    return DateTime.fromISO(dateWhenMet).diff(DateTime.fromISO(first(reviewsData)!.fromDate), 'days').days
  }
}

const determineDaysToReviewsHitGoal = (reviewsData: CampaignProductReviewRatingMetric[], goal: number) => {
  if (!reviewsData.length) return undefined
  const start = first(reviewsData)!
  const dateWhenMet = reviewsData.find(r => r.numReviews - start.numReviews >= goal)?.fromDate

  if (dateWhenMet) {
    return DateTime.fromISO(dateWhenMet).diff(DateTime.fromISO(start.fromDate), 'days').days
  }
}

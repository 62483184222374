export const COUNTRY_CURRENCY_MAP: Record<string, string> = {
  US: 'USD',
  CA: 'CAD',
  UK: 'GBP',
  DE: 'EUR'
}

export const COUNTRY_LOCALE_MAP: Record<string, string> = {
  US: 'en-US',
  CA: 'en-CA',
  UK: 'en-GB',
  DE: 'de-DE'
}

export const getCurrencyFromLocale = (locale: string): string => COUNTRY_CURRENCY_MAP[locale] || 'USD'

export const getLocaleFromCountry = (country: string): string => COUNTRY_LOCALE_MAP[country] || 'en-US'

export const toCurrencyStringCents = (num: number, locale: string) => {
  return toCurrencyString(num / 100, locale)
}
export const toCurrencyString = (num: number, locale: string) => {
  const currency = getCurrencyFromLocale(locale)
  return new Intl.NumberFormat(`en-${locale}`, { style: 'currency', currency }).format(num)
}

export const parseCurrencyStringToCents = (string: string) => Math.round(Number(string.replace(/[^0-9.-]+/g, '')) * 100)

export const getCurrencySymbol = (locale: string, currency?: string) =>
  new Intl.NumberFormat(getLocaleFromCountry(locale), {
    style: 'currency',
    currency: currency || getCurrencyFromLocale(locale)
  })
    .formatToParts(1)
    .find(x => x.type === 'currency')?.value

export const isEvenDollarAmount = (amount?: number) => {
  return !!amount && amount % 100 === 0
}

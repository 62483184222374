import { BrandApiType, WSLocale } from '../zeus'
import { AmazonSellerType } from './amazon'

export const StoreNameToLocale: Record<string, WSLocale> = {
  amazon: WSLocale.US,
  amazon_ca: WSLocale.CA,
  amazon_uk: WSLocale.UK,
  amazon_de: WSLocale.DE,
  amazon_fr: WSLocale.FR,
  amazon_es: WSLocale.ES,
  amazon_it: WSLocale.IT,
  amazon_jp: WSLocale.JP,
  bestbuy: WSLocale.US,
  walmart: WSLocale.US,
  target: WSLocale.US,
  costco: WSLocale.US
}

export const LocaleToBrandApi = {
  [AmazonSellerType.SELLER]: {
    [WSLocale.US]: BrandApiType.amazon_na_seller,
    [WSLocale.CA]: BrandApiType.amazon_na_seller,
    [WSLocale.MX]: BrandApiType.amazon_na_seller,
    [WSLocale.BR]: BrandApiType.amazon_na_seller,
    [WSLocale.UK]: BrandApiType.amazon_eu_seller,
    [WSLocale.DE]: BrandApiType.amazon_eu_seller,
    [WSLocale.ES]: BrandApiType.amazon_eu_seller,
    [WSLocale.FR]: BrandApiType.amazon_eu_seller,
    [WSLocale.IT]: BrandApiType.amazon_eu_seller,
    [WSLocale.PL]: BrandApiType.amazon_eu_seller,
    [WSLocale.EG]: BrandApiType.amazon_eu_seller,
    [WSLocale.TR]: BrandApiType.amazon_eu_seller,
    [WSLocale.NL]: BrandApiType.amazon_eu_seller,
    [WSLocale.SE]: BrandApiType.amazon_eu_seller,
    [WSLocale.SA]: BrandApiType.amazon_eu_seller,
    [WSLocale.AE]: BrandApiType.amazon_eu_seller,
    [WSLocale.JP]: BrandApiType.amazon_fe_seller,
    [WSLocale.SG]: BrandApiType.amazon_fe_seller,
    [WSLocale.AU]: BrandApiType.amazon_fe_seller,
    [WSLocale.IN]: BrandApiType.amazon_fe_seller
  },
  [AmazonSellerType.VENDOR]: {
    [WSLocale.US]: BrandApiType.amazon_na_vendor,
    [WSLocale.CA]: BrandApiType.amazon_na_vendor,
    [WSLocale.MX]: BrandApiType.amazon_na_vendor,
    [WSLocale.BR]: BrandApiType.amazon_na_vendor,
    [WSLocale.UK]: BrandApiType.amazon_eu_vendor,
    [WSLocale.DE]: BrandApiType.amazon_eu_vendor,
    [WSLocale.ES]: BrandApiType.amazon_eu_vendor,
    [WSLocale.FR]: BrandApiType.amazon_eu_vendor,
    [WSLocale.IT]: BrandApiType.amazon_eu_vendor,
    [WSLocale.PL]: BrandApiType.amazon_eu_vendor,
    [WSLocale.EG]: BrandApiType.amazon_eu_vendor,
    [WSLocale.TR]: BrandApiType.amazon_eu_vendor,
    [WSLocale.NL]: BrandApiType.amazon_eu_vendor,
    [WSLocale.SE]: BrandApiType.amazon_eu_vendor,
    [WSLocale.SA]: BrandApiType.amazon_eu_vendor,
    [WSLocale.AE]: BrandApiType.amazon_eu_vendor,
    [WSLocale.JP]: BrandApiType.amazon_fe_vendor,
    [WSLocale.SG]: BrandApiType.amazon_fe_vendor,
    [WSLocale.AU]: BrandApiType.amazon_fe_vendor,
    [WSLocale.IN]: BrandApiType.amazon_fe_vendor
  }
}

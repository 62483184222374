import * as yup from 'yup'
import { useWatch } from 'react-hook-form'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useMemo } from 'react'
import { brandStoreIdRegex, urlPreTransform, walmartBrandStoreRegex } from '@momentum/utils/validationUtils'
import { TextInput } from '@productwindtom/ui-base'
import { InputAdornment } from '@mui/material'
import { CheckCircleOutlined } from '@mui/icons-material'

export const amazonBrandStoreCheck = yup
  .string()
  .optional()
  .nullable()
  .transform(s => (s ? s : undefined))
  .transform(urlPreTransform)
  .url('Not a valid URL')
  .matches(brandStoreIdRegex, {
    message:
      'Incorrect link format. Amazon Brand Store links begin with “amazon.com/stores/page/” and can be found by clicking on the “visit the brand store” link below the title on a product detail page'
  })

export const walmartBrandStoreCheck = yup
  .string()
  .optional()
  .nullable()
  .transform(s => (s ? s : undefined))
  .transform(urlPreTransform)
  .url('Not a valid URL')
  .matches(walmartBrandStoreRegex, {
    message:
      'Incorrect link format. Walmart Brand Store links must be a valid Walmart listings page.'
  })

export const BrandStoreUrlInput = ({ name, placeholder, disabled }: { name: string; placeholder: string; disabled?: boolean }) => {
  const value = useWatch({ name })
  const { brands } = useUserSessionContext()

  const brandStoreIds = useMemo(
    () =>
      brands
        .map(b => ({ name: b.name, brandStoreId: b.amazonBrandStoreUrl?.match(brandStoreIdRegex)?.[1] }))
        .filter(b => b.brandStoreId),
    [brands]
  )

  const isValid = value && amazonBrandStoreCheck.isValidSync(value)
  const brandStoreId = value?.match(brandStoreIdRegex)?.[1]

  const existingBrandStore = brandStoreId && brandStoreIds.find(b => b.brandStoreId === brandStoreId)
  const brandStoreUrlHasAll = value?.includes('terms=*')
  return (
    <TextInput
      name={name}
      placeholder={placeholder}
      fullWidth
      InputProps={
        isValid
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <CheckCircleOutlined color={'success'} />
                </InputAdornment>
              )
            }
          : undefined
      }
      error={!!existingBrandStore}
      helperText={
        existingBrandStore
          ? `This brand store is already tied to ${existingBrandStore.name}`
          : isValid && brandStoreUrlHasAll
            ? 'Based on the link provided we will attempt to add all products across the entire brand store.'
            : isValid
              ? 'Based on the link provided we will only add products found on this specific page, and will not attempt to add all products across the entire brand store. If you\'d like to include all products, be sure to enter a "*" in the search in the brand store and paste that link here.'
              : undefined
      }
      disabled={disabled}
    />
  )
}

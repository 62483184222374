import { SvgIconProps, SvgIcon } from '@mui/material'

export default function CAFlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 50 50" fill="none">
      <path
        d="M49.3009 41.8319H1.02519C0.54912 41.8319 0.163086 41.4458 0.163086 40.9698V9.93535C0.163086 9.45928 0.54912 9.07324 1.02519 9.07324H49.301C49.7771 9.07324 50.1631 9.45928 50.1631 9.93535V40.9698C50.163 41.4459 49.777 41.8319 49.3009 41.8319Z"
        fill="#F5F5F5"
      />
      <path d="M38.957 9.07324H11.3711V41.832H38.957V9.07324Z" fill="#F5F5F5" />
      <path
        d="M1.02519 9.07324C0.54912 9.07324 0.163086 9.45918 0.163086 9.93525V40.9697C0.163086 41.4457 0.54912 41.8318 1.02519 41.8318H11.37V9.07324H1.02519Z"
        fill="#FF4B55"
      />
      <path
        d="M49.3018 9.07324H38.957V41.8318H49.3018C49.7779 41.8318 50.1639 41.4457 50.1639 40.9697V9.93525C50.1639 9.45918 49.7779 9.07324 49.3018 9.07324Z"
        fill="#FF4B55"
      />
      <path
        d="M30.3953 29.2374L35.5108 26.3143L34.337 25.7274C34.0078 25.5628 33.8193 25.2073 33.868 24.8424L34.25 21.9789L32.0842 22.609C31.6058 22.7482 31.1093 22.4539 31.0017 21.9675L30.7943 21.03L28.7608 23.4098C28.4789 23.7397 27.9419 23.4878 28.0154 23.06L28.8839 18.0104L27.5543 18.3741C27.1659 18.4804 26.7557 18.3036 26.5664 17.9483L25.1663 15.3242V15.3203L25.1653 15.3223L25.1642 15.3203V15.3242L23.7641 17.9483C23.5746 18.3035 23.1644 18.4803 22.7761 18.3741L21.4464 18.0104L22.3148 23.06C22.3884 23.4876 21.8513 23.7396 21.5693 23.4098L19.5358 21.03L19.3284 21.9675C19.2208 22.454 18.7243 22.7481 18.2459 22.609L16.0802 21.9789L16.4621 24.8424C16.5108 25.2072 16.3224 25.5628 15.9932 25.7274L14.8193 26.3143L19.9349 29.2375C20.4647 29.5403 20.697 30.1825 20.4832 30.7541L20.0459 31.9237L24.4292 31.5495C24.6718 31.5288 24.8782 31.7241 24.8711 31.9674L24.733 36.6591H25.5951L25.4569 31.9672C25.4498 31.7238 25.6562 31.5286 25.8988 31.5493L30.2842 31.9237L29.8468 30.7541C29.6333 30.1824 29.8654 29.5401 30.3953 29.2374Z"
        fill="#FF4B55"
      />
    </SvgIcon>
  )
}

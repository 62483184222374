import { Stack, Typography, Button, Dialog, IconButton } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import {
  Invitation,
  deleteInvitation,
  getUser,
  deactivateUser,
  reactivateUser,
  User,
  listInvitationsByCompany,
  listInvitationsByAgency,
  listUserCompanies,
  listUserAgencies
} from './queries'
import { Add, Search, Close } from '@mui/icons-material'
import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { InviteForm } from './InviteForm'
import { DataGrid } from '@momentum/components/table'
import { RowType, UserTableColumns, RowData } from './tableDefinition'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { keyBy, orderBy } from 'lodash'
import { GridRowParams, GridActionsCellItem, GridColDef, GridColType } from '@mui/x-data-grid-premium'
import { notEmpty } from '@productwindtom/shared-node'
import { toast } from 'react-toastify'
import { UserStatus, UserInvitationStatus } from '@productwindtom/shared-momentum-zeus-types'
import { NoInvitations } from '@momentum/routes/team/NoInvitations'
import { UpdateUserForm } from '@momentum/routes/team/UpdateUserForm'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import Loading from '@momentum/components/loading'
import { generatePath, useMatch, useParams } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'
import copy from 'copy-to-clipboard'
import { useFlag } from '@unleash/proxy-client-react'

type UsersUser = User & { brandIds?: string[]; companyId?: string }

export const Team = () => {
  const { companyId } = useParams<{ companyId?: string }>()
  const { isAdminView, brands, selectedCompany, profile, agency, agencyId, isViewOnly } = useUserSessionContext()
  const [inviteOpen, setInviteOpen] = useState<boolean>(false)
  const [selectedUpdateUser, setSelectedUpdateUser] = useState<User | undefined>(undefined)
  const [selectedUpdateInvitation, setSelectedUpdateInvitation] = useState<Invitation | undefined>(undefined)

  const [invitations, setInvitations] = useState<Invitation[] | undefined>()
  const [users, setUsers] = useState<UsersUser[] | undefined>()
  const [search, setSearch] = useState<string>('')

  const viewOnlyPermissionsFlag = useFlag('ViewOnlyPermissions')

  const keyedBrands = keyBy(brands, 'id')
  const crumbs = useClientBreadcrumbs(selectedCompany)

  const isTeamBase = !!useMatch({ path: ROUTES.TEAM_BASE, end: false })
  const isAgency = !!useMatch({ path: ROUTES.AGENCY_TEAM, end: false }) || isTeamBase
  const resourceId = companyId || agencyId
  const selectedCompanyAgencyId = selectedCompany?.agencyId

  const getAgencyResources = useCallback((agencyId: string) => {
    return Promise.all([listInvitationsByAgency(agencyId), listUserAgencies(agencyId)])
  }, [])

  useEffect(() => {
    if (resourceId) {
      if (isAgency) {
        getAgencyResources(resourceId).then(([invitations, usersByAgency]) => {
          setInvitations(invitations)
          setUsers((usersByAgency || []).map(({ user }) => user))
        })
      } else {
        Promise.all([listInvitationsByCompany(resourceId), listUserCompanies(resourceId)]).then(
          async ([invitations, usersByCompany]) => {
            const usersArr: UsersUser[] = (usersByCompany || []).map(({ companyId, user, userBrands }) => ({
              ...user,
              companyId,
              brandIds: userBrands.map(b => b.brandId)
            }))
            const invitationsArr = [...invitations]

            if (selectedCompanyAgencyId) {
              const [agencyInvitations, usersByAgency] = await getAgencyResources(selectedCompanyAgencyId)

              invitationsArr.push(...agencyInvitations)
              usersArr.push(
                ...(usersByAgency || []).map(({ user }) => ({
                  ...user,
                  brandIds: []
                }))
              )
            }

            setInvitations(invitationsArr)
            setUsers(usersArr)
          }
        )
      }
    }
  }, [getAgencyResources, isAgency, resourceId, selectedCompanyAgencyId])

  const loaded = !!invitations && !!users

  const mappedUsers: RowData[] = (users || []).map(user => ({
    id: user.id,
    type: RowType.USER,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    brands: (user?.brandIds || []).map(b => keyedBrands[b]?.name).join(', '),
    role: user.role,
    status: user.status,
    accessLevel: user.accessLevel,
    primaryBrand: user.primaryBrandId ? keyedBrands[user.primaryBrandId]?.name : '',
    lastActiveAt: user.lastActiveAt,
    companyId: user.companyId
  }))

  const mappedInvitations: RowData[] = (invitations || [])
    .filter(i => i.status === UserInvitationStatus.PENDING)
    .map(invitation => ({
      id: invitation.id,
      type: RowType.INVITATION,
      email: invitation.email,
      firstName: invitation.firstName,
      lastName: invitation.lastName,
      brands: (invitation.brandIds || []).map(b => keyedBrands[b!]?.name).join(', '),
      role: invitation.role,
      accessLevel: invitation.accessLevel,
      primaryBrand: invitation.primaryBrandId ? keyedBrands[invitation.primaryBrandId]?.name : '',
      companyId: invitation.companyId
    }))

  const lowerSearch = search.toLowerCase()
  const rows = [...mappedInvitations, ...mappedUsers].filter(
    m =>
      !search ||
      m.email.includes(lowerSearch) ||
      m.firstName?.toLowerCase()?.includes(lowerSearch) ||
      m.lastName?.toLowerCase()?.includes(lowerSearch) ||
      m.brands?.includes(lowerSearch)
  )

  const orderedRows = orderBy(rows, ['type', 'status'], ['asc', 'asc'])

  const onInvitationCreated = async (invitation: Invitation) => {
    setInviteOpen(false)
    const userId = users?.find(u => u.id === invitation.email)?.id
    if (userId) {
      const updatedUser = await getUser(userId)
      if (updatedUser) {
        setUsers(prev => (prev || []).map(u => (u.id === userId ? { ...u, ...updatedUser } : u)))
      }
    } else {
      setInvitations(prev => [...(prev || []), invitation])
    }
    toast(<Typography variant={'subtitle2'}>1 team member invited!</Typography>, { type: 'success' })
  }

  const onInvitationUpdated = async (invitation: Invitation) => {
    setSelectedUpdateInvitation(undefined)
    setInvitations(prev => (prev || []).map(p => (p.id === invitation.id ? invitation : p)))
    toast(<Typography variant={'subtitle2'}>Team member updated!</Typography>, { type: 'success' })
  }

  const onUserUpdated = async (updatedUser: User) => {
    setSelectedUpdateUser(undefined)
    setUsers(prev => (prev || []).map(u => (u.id === updatedUser.id ? { ...u, ...updatedUser } : u)))
    toast(<Typography variant={'subtitle2'}>Team member updated!</Typography>, { type: 'success' })
  }

  const onEditClick = (params: GridRowParams) => {
    if (params.row.type === RowType.INVITATION) {
      setSelectedUpdateInvitation(invitations?.find(i => i.id === params.row.id))
    } else {
      setSelectedUpdateUser(users?.find(u => u.id === params.row.id))
    }
  }

  const onRemoveClick = async (params: GridRowParams<RowData>) => {
    await deleteInvitation(params.row.id)
    setInvitations(prev => (prev || []).filter(i => i.id !== params.row.id))
    toast(<Typography variant={'subtitle2'}>Deleted invitation</Typography>, { type: 'success' })
  }

  const onCopyInvitation = (params: GridRowParams<RowData>) => {
    const link =
      window.location.origin +
      generatePath(ROUTES.SIGN_UP_VIA_INVITATION, {
        id: params.row.id
      })
    copy(link)
    toast(<Typography variant={'subtitle2'}>Copied invitation link!</Typography>, { type: 'success' })
  }

  const onDeactivateClick = async (params: GridRowParams<RowData>) => {
    await deactivateUser(params.row.id)
    setUsers(prev => (prev || []).filter(i => i.id !== params.row.id))
    toast(<Typography variant={'subtitle2'}>Deactivated user</Typography>, { type: 'success' })
  }

  const onReactivateClick = async (params: GridRowParams<RowData>) => {
    const newUser = await reactivateUser(params.row.id)
    setUsers(prev => (prev || []).map(i => (i.id === newUser.id ? { ...i, user: newUser } : i)))
    toast(<Typography variant={'subtitle2'}>Reactivated user</Typography>, { type: 'success' })
  }

  const columnDefinitions: GridColDef[] = [
    ...UserTableColumns(isAgency, viewOnlyPermissionsFlag),
    ...(!isViewOnly
      ? [
          {
            field: 'action',
            type: 'actions' as GridColType,
            maxWidth: 80,
            width: 80,
            getActions: (params: GridRowParams) => {
              if (params?.row?.companyId !== companyId && !isAgency) return []
              if (params?.row?.id === profile.id) return []

              return [
                // <GridActionsCellItem label="Edit" onClick={() => onEditClick(params)} showInMenu />,
                ...(params.row.type === RowType.INVITATION
                  ? [
                      <GridActionsCellItem
                        key={'delete'}
                        label="Delete"
                        onClick={() => onRemoveClick(params)}
                        showInMenu
                      />,
                      <GridActionsCellItem
                        key={'copy'}
                        label="Copy invitation"
                        onClick={() => onCopyInvitation(params)}
                        showInMenu
                      />
                    ]
                  : []),
                params.row.type === RowType.USER &&
                params.row.status === UserStatus.ACTIVE &&
                params.row.id !== profile.id &&
                isAdminView ? (
                  <GridActionsCellItem label="Delete" onClick={() => onDeactivateClick(params)} showInMenu />
                ) : null,
                params.row.type === RowType.USER && isAdminView && params.row.status === UserStatus.INACTIVE ? (
                  <GridActionsCellItem label="Reactivate" onClick={() => onReactivateClick(params)} showInMenu />
                ) : null,
                (params.row.type === RowType.USER && params.row.status === UserStatus.ACTIVE) ||
                params.row.type === RowType.INVITATION ? (
                  <GridActionsCellItem label="Edit" onClick={() => onEditClick(params)} showInMenu />
                ) : null
              ].filter(notEmpty)
            }
          }
        ]
      : [])
  ].map(d => ({ ...d, sortable: false, flex: 1 }))

  if (!loaded) {
    return <Loading />
  }

  const isEmpty = !orderedRows.length

  return (
    <Stack spacing={3} my={3}>
      <Stack spacing={1}>
        <RoutesBreadcrumb crumbs={crumbs} />
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'h3'} data-cy={'teamHeader'}>
            Invite {isAgency ? agency?.name : selectedCompany?.name} team members
          </Typography>
          {!isViewOnly && (
            <Button
              data-cy={'inviteButton'}
              variant={'contained'}
              startIcon={<Add />}
              onClick={() => setInviteOpen(true)}
            >
              Invite
            </Button>
          )}
        </Stack>
        <Typography variant={'h5'}>{isAgency ? agency?.name : selectedCompany?.name}</Typography>
      </Stack>
      {!isEmpty && (
        <DebouncedTextField
          fullWidth
          placeholder={'Search team member or brand'}
          sx={{ mb: 4, maxWidth: 330 }}
          onChange={setSearch}
          InputProps={{
            endAdornment: <Search />
          }}
        />
      )}

      <Dialog open={inviteOpen} fullWidth maxWidth={'xs'} PaperProps={{ sx: { p: 3 } }}>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h3'}>
              Invite {isAgency ? agency?.name : selectedCompany?.name} team members
            </Typography>
            <IconButton onClick={() => setInviteOpen(false)}>
              <Close />
            </IconButton>
          </Stack>
          <InviteForm
            onCancel={() => setInviteOpen(false)}
            onCreated={onInvitationCreated}
            onUpdated={onInvitationUpdated}
          />
        </Stack>
      </Dialog>

      <Dialog open={!!selectedUpdateInvitation} fullWidth maxWidth={'xs'} PaperProps={{ sx: { p: 3 } }}>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h3'}>Update invitation</Typography>
            <IconButton onClick={() => setSelectedUpdateInvitation(undefined)}>
              <Close />
            </IconButton>
          </Stack>
          <InviteForm
            invitation={selectedUpdateInvitation}
            onCancel={() => setSelectedUpdateInvitation(undefined)}
            onCreated={onInvitationCreated}
            onUpdated={onInvitationUpdated}
          />
        </Stack>
      </Dialog>
      {!!selectedUpdateUser && (
        <Dialog open={!!selectedUpdateUser} fullWidth maxWidth={'xs'} PaperProps={{ sx: { p: 3 } }}>
          <Stack spacing={2}>
            <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant={'h3'}>Update team member</Typography>
              <IconButton onClick={() => setSelectedUpdateUser(undefined)}>
                <Close />
              </IconButton>
            </Stack>
            <UpdateUserForm
              user={selectedUpdateUser}
              onCancel={() => setSelectedUpdateUser(undefined)}
              onUpdated={onUserUpdated}
              isAgency={isAgency}
            />
          </Stack>
        </Dialog>
      )}

      {!isEmpty && (
        <DataGrid
          autoHeight
          disableRowSelectionOnClick
          rows={orderedRows}
          columns={columnDefinitions.map(d => ({ ...d, sortable: false, flex: 1 }))}
          disableColumnMenu={true}
          disableColumnReorder={true}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[10, 25, 50]}
        />
      )}
      {isEmpty && <NoInvitations onInviteClick={() => setInviteOpen(true)} />}
    </Stack>
  )
}

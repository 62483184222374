import { useFeedbackContext } from '@momentum/routes/campaign/e-commerce/feedback/feedbackContext'
import { Loop, PsychologyOutlined } from '@mui/icons-material'
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { ProductFeedbackStatus } from '@productwindtom/shared-momentum-zeus-types'

export const GenerateFeedbackButton = () => {
  const { productFeedbackSummary, generateProductFeedback } = useFeedbackContext()
  const [loading, setLoading] = useState(false)

  const onGenerateClick = async () => {
    setLoading(true)
    await generateProductFeedback()
    setLoading(false)
  }

  return (
    <Stack direction={'row'}>
      {!loading &&
        (!productFeedbackSummary ||
          (!productFeedbackSummary.jsonDownloadUrl &&
            productFeedbackSummary.status !== ProductFeedbackStatus.IN_PROGRESS)) && (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <IconButton onClick={onGenerateClick} size={'small'}>
              <PsychologyOutlined sx={{ color: 'black' }} />
            </IconButton>
            <Typography variant={'caption1'}>Generate AI feedback</Typography>
          </Stack>
        )}
      {!!productFeedbackSummary?.jsonDownloadUrl &&
        productFeedbackSummary?.status === ProductFeedbackStatus.COMPLETED && (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <IconButton onClick={generateProductFeedback} size={'small'}>
              <Loop />
            </IconButton>
            <Typography variant={'caption1'}>Regenerate AI feedback</Typography>
          </Stack>
        )}
      {(loading || productFeedbackSummary?.status === ProductFeedbackStatus.IN_PROGRESS) && (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <CircularProgress size={20} />
          <Typography variant={'caption1'}>This can take up to 10 minutes</Typography>
        </Stack>
      )}
    </Stack>
  )
}

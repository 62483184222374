import { first } from 'lodash'
import { DateTime } from 'luxon'

export const determineDaysToHitGoal = <T extends { date: DateTime; isWithinCampaignDates?: boolean }>(
  series: T[],
  getter: (t: T) => number,
  goal: number
) => {
  if (!series.length) return undefined

  const seriesWithinCampaignDate = series.filter(s => !!s.isWithinCampaignDates)

  const cumulativeSumArray: { date: DateTime; sum: number }[] = []

  for (const value of seriesWithinCampaignDate) {
    const lastSum = cumulativeSumArray[cumulativeSumArray.length - 1]?.sum || 0
    cumulativeSumArray.push({ sum: lastSum + getter(value), date: value.date })
  }

  const dateWhenMet = cumulativeSumArray.find(s => s.sum >= goal)?.date

  if (dateWhenMet) {
    return dateWhenMet.diff(first(seriesWithinCampaignDate)!.date, 'days').days
  }
}

export const isWithinPeriod = (date: string, startDate: string, endDate?: string) => {
  return date >= startDate && (!endDate || date <= endDate)
}

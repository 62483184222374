import { FormControlLabel, Radio, Stack, Typography, Backdrop, Button, Chip } from '@mui/material'
import { difference, chunk, keyBy } from 'lodash'
import { FileDownloadOutlined, FileUploadOutlined } from '@mui/icons-material'
import JSZip from 'jszip'
import { notEmpty } from '@productwindtom/shared-node'
import FileSaver from 'file-saver'
import Loading from '@momentum/components/loading'
import { useState, useMemo } from 'react'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { toast } from 'react-toastify'
import { useContentContext } from '../context'
import { CheckInput } from '@productwindtom/ui-base'
import { UploadAssetsDialog } from './upload-assets-dialog'
import { ContentGroup } from '@momentum/routes/campaign/context/queries'

const ContentActions = () => {
  const { isAdminView } = useUserSessionContext()
  const {
    selectedContent,
    content,
    filteredContent,
    selectAllContent,
    unselectAllContent,
    updateContentGroup,
    isSearch
  } = useContentContext()

  const [downloadModalOpen, setDownloadModalOpen] = useState(false)
  const [uploadAssetsDialogOpen, setUploadAssetsDialogOpen] = useState(false)

  const downloadAssets = async () => {
    setDownloadModalOpen(true)
    const zip = new JSZip()

    const noCacheHeaders = new Headers()
    noCacheHeaders.append('pragma', 'no-cache')
    noCacheHeaders.append('cache-control', 'no-cache')

    // mediaKeys with post and index
    const mediaKeys = filteredContent
      .filter(c => selectedContent.includes(c.groupId))
      .flatMap(({ content }) => content.map(a => a.assets.map(u => u.url).filter(notEmpty)))
      .flat()

    const chunkedMediaKeys = chunk(mediaKeys, 10)

    const blobs = []
    for (const chunk of chunkedMediaKeys) {
      const chunkBlobs = await Promise.all(
        chunk.map(mediaKey =>
          fetch(mediaKey, {
            method: 'GET',
            headers: noCacheHeaders
          }).then(resp => {
            return { mediaKey, blob: resp.blob() }
          })
        )
      )
      blobs.push(...chunkBlobs)
    }

    // blobs with post, mediaKey and index
    blobs.forEach((item, index) => {
      if (!item) return

      const { mediaKey, blob } = item

      const split = mediaKey.split('.')
      const extension = split[split.length - 1]
      zip.file(`MEDIA_${index}.${extension}`, blob)
    })

    await zip.generateAsync({ type: 'blob' }).then(zipFile => {
      const fileName = `assets.zip`
      return FileSaver.saveAs(zipFile, fileName)
    })
    setDownloadModalOpen(false)
  }

  const onUploadAssetsClick = () => {
    setUploadAssetsDialogOpen(true)
  }

  const onSubmitComplete = (updatedContent: ContentGroup) => {
    setUploadAssetsDialogOpen(false)
    updateContentGroup?.(updatedContent)

    toast(<Typography variant={'subtitle2'}>Your asset was successfully uploaded!</Typography>, { type: 'success' })
  }

  const isAllSelected =
    difference(
      filteredContent.map(c => c.groupId),
      selectedContent
    ).length === 0

  const piecesOfContentAssets = useMemo(
    () => filteredContent.flatMap(c => c.content.flatMap(c => c.assets)).length,
    [filteredContent]
  )
  const totalPiecesOfContentSelected = useMemo(() => {
    const keyed = keyBy(content, 'groupId')
    return selectedContent
      .map(id => keyed[id])
      .flatMap(c => c?.content.flatMap(c => c.assets))
      .filter(notEmpty).length
  }, [content, selectedContent])

  const favoritedContent = filteredContent.filter(c => c.isFavorite).length

  return (
    <Stack direction={'row'} spacing={5} alignItems={'flex-start'} justifyContent={'flex-end'}>
      {!isSearch && (
        <Stack alignItems={'flex-end'}>
          <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
            {piecesOfContentAssets} pieces of content
          </Typography>
          <FormControlLabel
            control={
              <Radio
                onClick={_ => {
                  if (isAllSelected) {
                    unselectAllContent()
                  } else {
                    selectAllContent()
                  }
                }}
                checked={isAllSelected}
                size={'medium'}
              />
            }
            label="Select all"
            labelPlacement="end"
            componentsProps={{ typography: { variant: 'label3' } }}
          />
        </Stack>
      )}
      <Stack alignItems={'flex-end'} spacing={1}>
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          {totalPiecesOfContentSelected} pieces of content selected
        </Typography>
        <Button
          variant={'text'}
          disabled={!selectedContent.length}
          onClick={downloadAssets}
          disableRipple
          startIcon={<FileDownloadOutlined />}
        >
          Download
        </Button>
      </Stack>
      <Stack alignItems={'flex-end'}>
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          {favoritedContent} pieces of favorited content
        </Typography>
        <CheckInput
          label="Favorited content"
          name="isFavorite"
          checkboxProps={{
            size: 'medium'
          }}
          controlLabelProps={{
            componentsProps: { typography: { variant: 'label3' } }
          }}
        />
      </Stack>
      {isAdminView && !isSearch && (
        <Stack alignItems={'flex-end'} spacing={0.5}>
          <Chip label={'Admin'} color={'primary'} size={'small'} />
          <Button
            variant={'text'}
            onClick={onUploadAssetsClick}
            disableRipple
            startIcon={<FileUploadOutlined />}
            color={'inherit'}
          >
            <Typography variant={'label3'}>Upload assets</Typography>
          </Button>
        </Stack>
      )}
      <UploadAssetsDialog
        open={uploadAssetsDialogOpen}
        onClose={() => setUploadAssetsDialogOpen(false)}
        onSubmitComplete={onSubmitComplete}
      />
      <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={downloadModalOpen}>
        <Loading />
      </Backdrop>
    </Stack>
  )
}

export default ContentActions

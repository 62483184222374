import { BrandApi } from '@momentum/routes/queries'
import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getAmazonAuthorizationUri } from './queries'

export const useBrandIntegrationHandler = () => {
  const { search } = useLocation()
  const [, setSearchParams] = useSearchParams()

  const integrationStatus = new URLSearchParams(search).get('integrationStatus')

  useEffect(() => {
    if (integrationStatus) {
      const isSuccess = integrationStatus === 'success'

      toast(
        <Typography variant={'subtitle2'}>
          {isSuccess
            ? "You've successfully linked your account!"
            : 'Integration failed, please try again or contact your client success manager.'}
        </Typography>,
        { type: isSuccess ? 'success' : 'error' }
      )

      setSearchParams()
    }
  }, [integrationStatus, setSearchParams])

  const handleIntegration = async (brandApi: BrandApi) => {
    try {
      const { api, brandId } = brandApi
      const url = await getAmazonAuthorizationUri(api, brandId, window.location.href?.split('?')[0])
      window.location.href = url
    } catch (err) {
      console.log('err', err)
      toast(
        <Typography variant={'subtitle2'}>
          Integration failed, please try again or contact your client success manager.
        </Typography>,
        { type: 'error' }
      )
    }
  }

  return handleIntegration
}

import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_CLICKS_LAUNDRY: ModelTypes['ModelItemsCampaignClick'] = {
  items: [
    {
      date: '2023-08-02',
      clicks: 1476,
      createdAt: '2023-08-02',
      updatedAt: '2023-08-02'
    },
    {
      date: '2023-08-03',
      clicks: 1276,
      createdAt: '2023-08-03',
      updatedAt: '2023-08-03'
    },
    {
      date: '2023-08-04',
      clicks: 138,
      createdAt: '2023-08-04',
      updatedAt: '2023-08-04'
    },
    {
      date: '2023-08-05',
      clicks: 1457,
      createdAt: '2023-08-05',
      updatedAt: '2023-08-05'
    },
    {
      date: '2023-08-06',
      clicks: 1444,
      createdAt: '2023-08-06',
      updatedAt: '2023-08-06'
    },
    {
      date: '2023-08-07',
      clicks: 1034,
      createdAt: '2023-08-07',
      updatedAt: '2023-08-07'
    },
    {
      date: '2023-08-08',
      clicks: 1381,
      createdAt: '2023-08-08',
      updatedAt: '2023-08-08'
    },
    {
      date: '2023-08-09',
      clicks: 102,
      createdAt: '2023-08-09',
      updatedAt: '2023-08-09'
    },
    {
      date: '2023-08-10',
      clicks: 1162,
      createdAt: '2023-08-10',
      updatedAt: '2023-08-10'
    },
    {
      date: '2023-08-11',
      clicks: 116,
      createdAt: '2023-08-11',
      updatedAt: '2023-08-11'
    },
    {
      date: '2023-08-12',
      clicks: 1697,
      createdAt: '2023-08-12',
      updatedAt: '2023-08-12'
    },
    {
      date: '2023-08-13',
      clicks: 708,
      createdAt: '2023-08-13',
      updatedAt: '2023-08-13'
    },
    {
      date: '2023-08-14',
      clicks: 1535,
      createdAt: '2023-08-14',
      updatedAt: '2023-08-14'
    },
    {
      date: '2023-08-15',
      clicks: 1886,
      createdAt: '2023-08-15',
      updatedAt: '2023-08-15'
    },
    {
      date: '2023-08-16',
      clicks: 922,
      createdAt: '2023-08-16',
      updatedAt: '2023-08-16'
    },
    {
      date: '2023-08-17',
      clicks: 889,
      createdAt: '2023-08-17',
      updatedAt: '2023-08-17'
    },
    {
      date: '2023-08-18',
      clicks: 1228,
      createdAt: '2023-08-18',
      updatedAt: '2023-08-18'
    },
    {
      date: '2023-08-19',
      clicks: 396,
      createdAt: '2023-08-19',
      updatedAt: '2023-08-19'
    },
    {
      date: '2023-08-20',
      clicks: 1506,
      createdAt: '2023-08-20',
      updatedAt: '2023-08-20'
    },
    {
      date: '2023-08-21',
      clicks: 837,
      createdAt: '2023-08-21',
      updatedAt: '2023-08-21'
    },
    {
      date: '2023-08-22',
      clicks: 1351,
      createdAt: '2023-08-22',
      updatedAt: '2023-08-22'
    },
    {
      date: '2023-08-23',
      clicks: 1321,
      createdAt: '2023-08-23',
      updatedAt: '2023-08-23'
    },
    {
      date: '2023-08-24',
      clicks: 165,
      createdAt: '2023-08-24',
      updatedAt: '2023-08-24'
    },
    {
      date: '2023-08-25',
      clicks: 643,
      createdAt: '2023-08-25',
      updatedAt: '2023-08-25'
    },
    {
      date: '2023-08-26',
      clicks: 147,
      createdAt: '2023-08-26',
      updatedAt: '2023-08-26'
    },
    {
      date: '2023-08-27',
      clicks: 1731,
      createdAt: '2023-08-27',
      updatedAt: '2023-08-27'
    },
    {
      date: '2023-08-28',
      clicks: 1445,
      createdAt: '2023-08-28',
      updatedAt: '2023-08-28'
    },
    {
      date: '2023-08-29',
      clicks: 47,
      createdAt: '2023-08-29',
      updatedAt: '2023-08-29'
    },
    {
      date: '2023-08-30',
      clicks: 1758,
      createdAt: '2023-08-30',
      updatedAt: '2023-08-30'
    },
    {
      date: '2023-08-31',
      clicks: 794,
      createdAt: '2023-08-31',
      updatedAt: '2023-08-31'
    },
    {
      date: '2023-09-01',
      clicks: 1609,
      createdAt: '2023-09-01',
      updatedAt: '2023-09-01'
    },
    {
      date: '2023-09-02',
      clicks: 1242,
      createdAt: '2023-09-02',
      updatedAt: '2023-09-02'
    },
    {
      date: '2023-09-03',
      clicks: 1687,
      createdAt: '2023-09-03',
      updatedAt: '2023-09-03'
    },
    {
      date: '2023-09-04',
      clicks: 1474,
      createdAt: '2023-09-04',
      updatedAt: '2023-09-04'
    },
    {
      date: '2023-09-05',
      clicks: 756,
      createdAt: '2023-09-05',
      updatedAt: '2023-09-05'
    },
    {
      date: '2023-09-06',
      clicks: 1334,
      createdAt: '2023-09-06',
      updatedAt: '2023-09-06'
    },
    {
      date: '2023-09-07',
      clicks: 1411,
      createdAt: '2023-09-07',
      updatedAt: '2023-09-07'
    },
    {
      date: '2023-09-08',
      clicks: 722,
      createdAt: '2023-09-08',
      updatedAt: '2023-09-08'
    },
    {
      date: '2023-09-09',
      clicks: 1395,
      createdAt: '2023-09-09',
      updatedAt: '2023-09-09'
    },
    {
      date: '2023-09-10',
      clicks: 948,
      createdAt: '2023-09-10',
      updatedAt: '2023-09-10'
    },
    {
      date: '2023-09-11',
      clicks: 1636,
      createdAt: '2023-09-11',
      updatedAt: '2023-09-11'
    },
    {
      date: '2023-09-12',
      clicks: 1247,
      createdAt: '2023-09-12',
      updatedAt: '2023-09-12'
    },
    {
      date: '2023-09-13',
      clicks: 1806,
      createdAt: '2023-09-13',
      updatedAt: '2023-09-13'
    },
    {
      date: '2023-09-14',
      clicks: 1290,
      createdAt: '2023-09-14',
      updatedAt: '2023-09-14'
    },
    {
      date: '2023-09-15',
      clicks: 1621,
      createdAt: '2023-09-15',
      updatedAt: '2023-09-15'
    },
    {
      date: '2023-09-16',
      clicks: 785,
      createdAt: '2023-09-16',
      updatedAt: '2023-09-16'
    },
    {
      date: '2023-09-17',
      clicks: 1699,
      createdAt: '2023-09-17',
      updatedAt: '2023-09-17'
    },
    {
      date: '2023-09-18',
      clicks: 1488,
      createdAt: '2023-09-18',
      updatedAt: '2023-09-18'
    },
    {
      date: '2023-09-19',
      clicks: 1990,
      createdAt: '2023-09-19',
      updatedAt: '2023-09-19'
    },
    {
      date: '2023-09-20',
      clicks: 1369,
      createdAt: '2023-09-20',
      updatedAt: '2023-09-20'
    }
  ]
}

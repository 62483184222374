import { Box, Divider, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React from 'react'
import {
  KeyboardArrowDownOutlined,
  ViewWeekOutlined,
  CheckBoxOutlined,
  CheckBoxOutlineBlank
} from '@mui/icons-material'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

export const ColumnSelector = ({ apiRef }: { apiRef: GridApiPremium }) => {
  const [visibleColumnsMap, setVisibleColumnsMap] = React.useState<{ [key: string]: boolean }>(
    (apiRef?.getVisibleColumns() || []).reduce((acc, c) => ({ ...acc, [c.field]: true }), {})
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const columns = apiRef.getAllColumns()

  const allSelected = !columns.some(c => !visibleColumnsMap[c.field])

  const handleShowAll = () => {
    apiRef.getAllColumns().forEach(column => apiRef.setColumnVisibility(column.field, true))
    setVisibleColumnsMap(apiRef.getAllColumns().reduce((acc, c) => ({ ...acc, [c.field]: true }), {}))
  }

  const toggleVisibility = (field: string) => {
    apiRef.setColumnVisibility(field, !visibleColumnsMap[field])
    setVisibleColumnsMap(prev => ({ ...prev, [field]: !prev[field] }))
  }

  return (
    <Box>
      <Stack
        direction={'row'}
        alignItems={'center'}
        onClick={handleClick}
        spacing={0.5}
        sx={{
          border: theme => `1px solid ${theme.palette.grey.A200}`,
          borderRadius: '4px',
          p: 1,
          background: theme => theme.palette.grey.A100
        }}
      >
        <ViewWeekOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          Columns
        </Typography>
        <KeyboardArrowDownOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
      </Stack>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} disablePortal sx={{ zIndex: 10 }}>
        <MenuItem onClick={handleShowAll}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            {allSelected ? <CheckBoxOutlined color={'primary'} /> : <CheckBoxOutlineBlank />}
            <Typography variant={'label3'}>Show all columns</Typography>
          </Stack>
        </MenuItem>
        <Divider />
        {columns.map(c => (
          <MenuItem key={c.field} onClick={() => toggleVisibility(c.field)} sx={{ py: 1 }}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              {visibleColumnsMap[c.field] ? <CheckBoxOutlined color={'primary'} /> : <CheckBoxOutlineBlank />}
              <Typography variant={'label3'}>{c.headerName}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

import { Stack, Typography } from '@mui/material'
import React from 'react'
import { CollectingFeedbackIcon } from '@momentum/routes/campaign/e-commerce/feedback/collecting-results/CollectingFeedbackIcon'

export const CollectingResults = () => {
  return (
    <Stack alignItems={'center'} spacing={3} pt={12}>
      <CollectingFeedbackIcon />
      <Typography variant={'h5'} textAlign={'center'} maxWidth={'442px'}>
        We’re collecting product feedback.
      </Typography>
      <Typography>Product feedback will be available soon!</Typography>
    </Stack>
  )
}

import { BoxToggle } from '@momentum/components/box-toggle'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import 'chart.js/auto'
import 'chartjs-adapter-luxon'
import { last } from 'lodash'
import { DateTime } from 'luxon'
import { Line } from 'react-chartjs-2'
import { SalesReportView, useSalesContext } from '../salesContext'
import { useValueFormatter } from '../utils'
import { useMemo } from 'react'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'

type SalesRecord = {
  date: DateTime
  fromMomentum: number
  fromOtherCustomers: number
  total: number
}

export const SalesGraph = () => {
  const theme = useTheme()
  const {
    campaignDetails: { startDate, endDate }
  } = useCampaignContext()
  const {
    isIntegrated,
    filteredSalesData,
    isActivatedCreatorsHidden,
    isOtherCustomersHidden,
    isTotalHidden,
    setIsActivatedCreatorsHidden,
    setIsOtherCustomersHidden,
    setIsTotalHidden,
    salesReportView
  } = useSalesContext()

  const valueFormatter = useValueFormatter()

  const startDateNoTime = startDate.split('T')[0]
  const endDateNoTime = endDate ? endDate.split('T')[0] : undefined

  const cumulative = useMemo(
    () =>
      filteredSalesData.reduce((acc: SalesRecord[], d) => {
        const prev = acc[acc.length - 1]
        const fromMomentum = d.fromMomentum + (prev?.fromMomentum || 0)
        const fromOtherCustomers = d.fromOtherCustomers + (prev?.fromOtherCustomers || 0)
        const total = d.total + (prev?.total || 0)

        return [...acc, { date: d.date, fromMomentum, fromOtherCustomers, total }]
      }, []),
    [filteredSalesData]
  )

  const fromMomentum = cumulative.map(d => d.fromMomentum)
  const fromOtherCustomers = cumulative.map(d => d.fromOtherCustomers)
  const total = cumulative.map(d => d.total)

  const lastRecord = last(cumulative)
  const fromMomentumSum = lastRecord?.fromMomentum || 0
  const fromOtherCustomersSum = Math.max(0, lastRecord?.fromOtherCustomers || 0)
  const totalSum = lastRecord?.total || 0

  const labels = filteredSalesData.map(d => d.date)

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} spacing={2}>
        <BoxToggle
          value={!isActivatedCreatorsHidden}
          color={theme.palette.primary.main}
          onChange={v => setIsActivatedCreatorsHidden(!v)}
        >
          <Stack alignItems={'center'} justifyContent={'stretch'}>
            <Typography variant={'body1'}>Activated Creators</Typography>
            <Typography variant={'label1'}>
              {valueFormatter(fromMomentumSum)} {salesReportView === SalesReportView.UNITS_SOLD ? 'units' : ''}
            </Typography>
          </Stack>
        </BoxToggle>
        {isIntegrated && (
          <BoxToggle
            value={!isOtherCustomersHidden}
            color={theme.palette.secondary.main}
            onChange={v => setIsOtherCustomersHidden(!v)}
          >
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>Other customers</Typography>
              <Typography variant={'label1'}>
                {valueFormatter(fromOtherCustomersSum)} {salesReportView === SalesReportView.UNITS_SOLD ? 'units' : ''}
              </Typography>
            </Stack>
          </BoxToggle>
        )}
        {isIntegrated && (
          <BoxToggle value={!isTotalHidden} color={theme.palette.warning.main} onChange={v => setIsTotalHidden(!v)}>
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>Total</Typography>
              <Typography variant={'label1'}>
                {valueFormatter(totalSum)} {salesReportView === SalesReportView.UNITS_SOLD ? 'units' : ''}
              </Typography>
            </Stack>
          </BoxToggle>
        )}
      </Stack>
      <Box>
        <Line
          data={{
            labels: labels,
            datasets: [
              {
                borderColor: theme.palette.primary.main,
                pointBorderColor: theme.palette.primary.main,
                pointBackgroundColor: theme.palette.primary.main,
                data: fromMomentum,
                hidden: isActivatedCreatorsHidden
              },
              ...(isIntegrated
                ? [
                    {
                      borderColor: theme.palette.secondary.main,
                      pointBorderColor: theme.palette.secondary.main,
                      pointBackgroundColor: theme.palette.secondary.main,
                      data: fromOtherCustomers.map(d => Math.max(0, d)),
                      hidden: isOtherCustomersHidden
                    },
                    {
                      borderColor: theme.palette.warning.main,
                      pointBorderColor: theme.palette.warning.main,
                      pointBackgroundColor: theme.palette.warning.main,
                      data: total,
                      hidden: isTotalHidden
                    }
                  ]
                : [])
            ]
          }}
          options={{
            elements: { point: { radius: 0, hitRadius: 10 } },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                ...defaultTooltipOptions,
                callbacks: {
                  label: item => {
                    const value = item.formattedValue
                    return `${value} ${salesReportView === SalesReportView.UNITS_SOLD ? 'units' : ''}`
                  },
                  title: () => '',
                  footer: () => ''
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  drawOnChartArea: false,
                  drawTicks: false
                },
                offset: true,
                ticks: {
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  color: 'black',
                  padding: 16
                },
                border: {
                  color: 'black',
                  width: 3
                }
              },
              x: {
                type: 'time',
                ticks: {
                  minRotation: 45,
                  padding: 16,
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  color: value => {
                    const date = DateTime.fromMillis(value.tick.value).toISODate()
                    return date === startDateNoTime || date === endDateNoTime ? theme.palette.primary.main : 'black'
                  }
                },
                time: {
                  unit: 'day',
                  displayFormats: {
                    day: 'LL/dd'
                  }
                },
                grid: {
                  drawOnChartArea: true,
                  drawTicks: false,
                  color: function (value) {
                    const date = DateTime.fromMillis(value.tick.value).toISODate()
                    return date === startDateNoTime || date === endDateNoTime ? theme.palette.grey.A400 : 'transparent'
                  }
                },
                border: {
                  color: 'black',
                  width: 3
                }
              }
            }
          }}
          height={'560px'}
        />
      </Box>
    </Stack>
  )
}

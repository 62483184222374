import { FinancialSummaryCreatorType } from '@momentum/routes/proposals-create/context/types'
import FinancialCampaignSummary from '@momentum/routes/proposals-create/modules/financial-summary/FinancialCampaignSummary'
import FinancialCommitments from '@momentum/routes/proposals-create/modules/financial-summary/FinancialCommitments'
import { convertFromCreatorPricing } from '@momentum/utils/proposalUtils'
import { Stack } from '@mui/material'
import { keyBy, sumBy } from 'lodash'
import { Proposal } from '../../context/queries'
import { useFlowSummaryConfig } from '@momentum/routes/proposals-create/context/FinancialSummaryContext/hooks/useFlowSummaryConfig'
import FinancialFlowSummary from '@momentum/routes/proposals-create/modules/financial-summary/FinancialFlowSummary'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useFlag } from '@unleash/proxy-client-react'

export const CampaignFinancialSummary = ({ proposal }: { proposal: Proposal }) => {
  const { isAdmin, isAdminView } = useUserSessionContext()
  const financialSummaryFlag = useFlag('FinancialSummary')

  const creatorPricing = convertFromCreatorPricing(proposal.creatorPricing)
  const creatorsByType = keyBy(creatorPricing, 'type') as FinancialSummaryCreatorType

  const flowSummary = useFlowSummaryConfig(
    creatorPricing,
    creatorsByType,
    proposal.liftNumCreators,
    proposal.liftCloseoutBonus
  )

  const totalCreatorCost = Math.ceil(sumBy(proposal.creatorPricing, creator => creator.price * creator.numCreators))

  if (!isAdmin || !isAdminView || !financialSummaryFlag) return null

  return (
    <Stack spacing={5}>
      <FinancialCommitments creatorsByType={creatorsByType} />
      <FinancialCampaignSummary
        flowSummary={flowSummary}
        productPrice={proposal.productPrice!}
        totalCreatorCostCredits={totalCreatorCost}
        totalProductCostCredits={proposal.totalProductCostCredits || 0}
        submitEnabled
      />
      <FinancialFlowSummary flowSummary={flowSummary} productPrice={proposal.productPrice!} disabled />
    </Stack>
  )
}

export default CampaignFinancialSummary

import { AuthDomainConfig } from './types'

export const authDomainConfigurations: AuthDomainConfig[] = [
  {
    domains: ['nestle'],
    passwordCharacterLimit: 16,
    passwordChangeIntervalDays: 90,
    failedLoginAttempts: 5,
    idleSessionTimeoutMinutes: 15,
    isMfaEnforced: true,
    passwordHistoryLimit: 8
  }
]

export const getAuthDomainConfigurationFromEmail = (emailAddress: string) => {
  const domain = emailAddress.split('@').pop()
  if (!domain) {
    return
  }
  return authDomainConfigurations.find(config =>
    config.domains.find(d => domain.includes(d) || emailAddress.endsWith(`+${d}@productwind.com`))
  )
}

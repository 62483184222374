import { CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_REVIEW_OUTDOOR_CAMERA_BOOST: Array<CampaignProductReviewRatingMetric> = [
  {
    fromDate: '2024-05-16',
    toDate: '2024-05-17',
    numRatings: 269,
    numReviews: 137,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-17',
    toDate: '2024-05-18',
    numRatings: 271,
    numReviews: 139,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 3.4,
    vineRating: 0
  },
  {
    fromDate: '2024-05-18',
    toDate: '2024-05-19',
    numRatings: 273,
    numReviews: 140,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 3.4,
    vineRating: 0
  },
  {
    fromDate: '2024-05-19',
    toDate: '2024-05-20',
    numRatings: 273,
    numReviews: 140,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-20',
    toDate: '2024-05-21',
    numRatings: 273,
    numReviews: 140,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-21',
    toDate: '2024-05-22',
    numRatings: 273,
    numReviews: 140,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-22',
    toDate: '2024-05-23',
    numRatings: 274,
    numReviews: 142,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 3.4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-23',
    toDate: '2024-05-24',
    numRatings: 279,
    numReviews: 144,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 3,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 3.4,
    momentumRating: 4.8,
    organicRating: 4.066666666666666,
    vineRating: 0
  },
  {
    fromDate: '2024-05-24',
    toDate: '2024-05-25',
    numRatings: 280,
    numReviews: 144,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 3.4,
    vineRating: 0
  },
  {
    fromDate: '2024-05-25',
    toDate: '2024-05-26',
    numRatings: 282,
    numReviews: 146,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 3.4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-26',
    toDate: '2024-05-27',
    numRatings: 285,
    numReviews: 148,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 3.4,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-27',
    toDate: '2024-05-28',
    numRatings: 286,
    numReviews: 148,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 1,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.4,
    momentumRating: 0,
    organicRating: 3.4,
    vineRating: 0
  },
  {
    fromDate: '2024-05-28',
    toDate: '2024-05-29',
    numRatings: 299,
    numReviews: 159,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 11,
    momentumReviewRating: 4.799999999999999,
    organicRatingCount: 2,
    reviewsInPeriod: 11,
    reviews: [],
    rating: 3.5,
    momentumRating: 4.799999999999999,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-29',
    toDate: '2024-05-30',
    numRatings: 311,
    numReviews: 167,
    ratingsInPeriod: 4,
    momentumRatingCount: 4,
    vineReviewCount: 0,
    momentumReviewCount: 8,
    momentumReviewRating: 4.8,
    organicRatingCount: 4,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 3.5,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-05-30',
    toDate: '2024-05-31',
    numRatings: 324,
    numReviews: 181,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 13,
    momentumReviewRating: 4.799999999999999,
    organicRatingCount: 0,
    reviewsInPeriod: 13,
    reviews: [],
    rating: 3.6,
    momentumRating: 4.799999999999999,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-05-31',
    toDate: '2024-06-01',
    numRatings: 337,
    numReviews: 194,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 13,
    momentumReviewRating: 4.799999999999999,
    organicRatingCount: 0,
    reviewsInPeriod: 13,
    reviews: [],
    rating: 3.6,
    momentumRating: 4.799999999999999,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-01',
    toDate: '2024-06-02',
    numRatings: 352,
    numReviews: 207,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 13,
    momentumReviewRating: 4.799999999999999,
    organicRatingCount: 2,
    reviewsInPeriod: 13,
    reviews: [],
    rating: 3.7,
    momentumRating: 4.799999999999999,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-06-02',
    toDate: '2024-06-03',
    numRatings: 365,
    numReviews: 216,
    ratingsInPeriod: 4,
    momentumRatingCount: 4,
    vineReviewCount: 0,
    momentumReviewCount: 9,
    momentumReviewRating: 4.8,
    organicRatingCount: 4,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 3.7,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-06-03',
    toDate: '2024-06-04',
    numRatings: 376,
    numReviews: 225,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 9,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 3.7,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-06-04',
    toDate: '2024-06-05',
    numRatings: 399,
    numReviews: 246,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 21,
    momentumReviewRating: 4.799999999999999,
    organicRatingCount: 2,
    reviewsInPeriod: 21,
    reviews: [],
    rating: 3.8,
    momentumRating: 4.799999999999999,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-06-05',
    toDate: '2024-06-06',
    numRatings: 414,
    numReviews: 261,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 15,
    momentumReviewRating: 4.799999999999999,
    organicRatingCount: 0,
    reviewsInPeriod: 15,
    reviews: [],
    rating: 3.8,
    momentumRating: 4.799999999999999,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-06',
    toDate: '2024-06-07',
    numRatings: 436,
    numReviews: 278,
    ratingsInPeriod: 5,
    momentumRatingCount: 5,
    vineReviewCount: 0,
    momentumReviewCount: 17,
    momentumReviewRating: 4.799999999999999,
    organicRatingCount: 5,
    reviewsInPeriod: 17,
    reviews: [],
    rating: 3.8,
    momentumRating: 4.799999999999999,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-06-07',
    toDate: '2024-06-08',
    numRatings: 444,
    numReviews: 288,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 8,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 3.9,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-08',
    toDate: '2024-06-09',
    numRatings: 457,
    numReviews: 301,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 13,
    momentumReviewRating: 4.799999999999999,
    organicRatingCount: 0,
    reviewsInPeriod: 13,
    reviews: [],
    rating: 3.9,
    momentumRating: 4.799999999999999,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-06-09',
    toDate: '2024-06-10',
    numRatings: 466,
    numReviews: 308,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 7,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 3.9,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-06-10',
    toDate: '2024-07-28',
    numRatings: 784,
    numReviews: 533,
    ratingsInPeriod: 93,
    momentumRatingCount: 93,
    vineReviewCount: 0,
    momentumReviewCount: 225,
    momentumReviewRating: 4.799999999999979,
    organicRatingCount: 93,
    reviewsInPeriod: 225,
    reviews: [],
    rating: 4,
    momentumRating: 4.799999999999979,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-07-28',
    toDate: '2024-07-29',
    numRatings: 791,
    numReviews: 536,
    ratingsInPeriod: 4,
    momentumRatingCount: 4,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 4,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-07-29',
    toDate: '2024-07-30',
    numRatings: 804,
    numReviews: 536,
    ratingsInPeriod: 13,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 13,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-07-30',
    toDate: '2024-07-31',
    numRatings: 812,
    numReviews: 546,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 8,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-07-31',
    toDate: '2024-08-01',
    numRatings: 820,
    numReviews: 553,
    ratingsInPeriod: 1,
    momentumRatingCount: 1,
    vineReviewCount: 0,
    momentumReviewCount: 7,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-08-01',
    toDate: '2024-08-02',
    numRatings: 827,
    numReviews: 558,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 5,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-08-02',
    toDate: '2024-08-03',
    numRatings: 836,
    numReviews: 565,
    ratingsInPeriod: 2,
    momentumRatingCount: 2,
    vineReviewCount: 0,
    momentumReviewCount: 7,
    momentumReviewRating: 4.8,
    organicRatingCount: 2,
    reviewsInPeriod: 7,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-08-03',
    toDate: '2024-08-04',
    numRatings: 844,
    numReviews: 570,
    ratingsInPeriod: 3,
    momentumRatingCount: 3,
    vineReviewCount: 0,
    momentumReviewCount: 5,
    momentumReviewRating: 4.8,
    organicRatingCount: 3,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-08-04',
    toDate: '2024-08-06',
    numRatings: 863,
    numReviews: 578,
    ratingsInPeriod: 11,
    momentumRatingCount: 8,
    vineReviewCount: 0,
    momentumReviewCount: 6,
    momentumReviewRating: 4.8,
    organicRatingCount: 13,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4,
    momentumRating: 4.8,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2024-08-06',
    toDate: '2024-08-07',
    numRatings: 867,
    numReviews: 579,
    ratingsInPeriod: 3,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-07',
    toDate: '2024-08-08',
    numRatings: 871,
    numReviews: 581,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-08',
    toDate: '2024-08-09',
    numRatings: 876,
    numReviews: 585,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 5,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-09',
    toDate: '2024-08-10',
    numRatings: 880,
    numReviews: 588,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-10',
    toDate: '2024-08-11',
    numRatings: 883,
    numReviews: 590,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-11',
    toDate: '2024-08-13',
    numRatings: 896,
    numReviews: 598,
    ratingsInPeriod: 5,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 13,
    reviewsInPeriod: 8,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-13',
    toDate: '2024-08-14',
    numRatings: 903,
    numReviews: 601,
    ratingsInPeriod: 4,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 7,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-14',
    toDate: '2024-08-15',
    numRatings: 909,
    numReviews: 607,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 6,
    reviewsInPeriod: 6,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-15',
    toDate: '2024-08-16',
    numRatings: 920,
    numReviews: 610,
    ratingsInPeriod: 8,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 11,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-16',
    toDate: '2024-08-17',
    numRatings: 925,
    numReviews: 613,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 5,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-17',
    toDate: '2024-08-18',
    numRatings: 932,
    numReviews: 617,
    ratingsInPeriod: 3,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 7,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-18',
    toDate: '2024-08-19',
    numRatings: 939,
    numReviews: 619,
    ratingsInPeriod: 5,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 7,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-19',
    toDate: '2024-08-20',
    numRatings: 944,
    numReviews: 621,
    ratingsInPeriod: 3,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 5,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-08-20',
    toDate: '2024-09-11',
    numRatings: 1081,
    numReviews: 713,
    ratingsInPeriod: 45,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 137,
    reviewsInPeriod: 92,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-11',
    toDate: '2024-09-12',
    numRatings: 1087,
    numReviews: 718,
    ratingsInPeriod: 1,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 6,
    reviewsInPeriod: 5,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-12',
    toDate: '2024-09-13',
    numRatings: 1098,
    numReviews: 727,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 11,
    reviewsInPeriod: 9,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-13',
    toDate: '2024-09-14',
    numRatings: 1102,
    numReviews: 729,
    ratingsInPeriod: 2,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-14',
    toDate: '2024-09-15',
    numRatings: 1110,
    numReviews: 729,
    ratingsInPeriod: 8,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 8,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4,
    momentumRating: 0,
    organicRating: 4,
    vineRating: 0
  },
  {
    fromDate: '2024-09-15',
    toDate: '2024-09-16',
    numRatings: 1110,
    numReviews: 729,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 3.6,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-16',
    toDate: '2024-09-17',
    numRatings: 1112,
    numReviews: 732,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 2,
    reviewsInPeriod: 2,
    reviews: [],
    rating: 3.6,
    momentumRating: 0,
    organicRating: 3.6,
    vineRating: 0
  },
  {
    fromDate: '2024-09-17',
    toDate: '2024-09-18',
    numRatings: 1112,
    numReviews: 732,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-18',
    toDate: '2024-09-19',
    numRatings: 1112,
    numReviews: 732,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 0,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2024-09-19',
    toDate: '2024-09-20',
    numRatings: 1482,
    numReviews: 0,
    ratingsInPeriod: 1102,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 370,
    reviewsInPeriod: -732,
    reviews: [],
    rating: 4.2,
    momentumRating: 0,
    organicRating: 4.2,
    vineRating: 0
  },
  {
    fromDate: '2024-09-20',
    toDate: '2024-09-21',
    numRatings: 1486,
    numReviews: 0,
    ratingsInPeriod: 4,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 4,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.2,
    momentumRating: 0,
    organicRating: 4.2,
    vineRating: 0
  },
  {
    fromDate: '2024-09-21',
    toDate: '2024-09-22',
    numRatings: 1489,
    numReviews: 0,
    ratingsInPeriod: 3,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 3,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.2,
    momentumRating: 0,
    organicRating: 4.2,
    vineRating: 0
  },
  {
    fromDate: '2024-09-22',
    toDate: '2024-09-23',
    numRatings: 1544,
    numReviews: 0,
    ratingsInPeriod: 55,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 55,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.1,
    momentumRating: 0,
    organicRating: 4.1,
    vineRating: 0
  },
  {
    fromDate: '2024-09-23',
    toDate: '2024-09-24',
    numRatings: 1544,
    numReviews: 219,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 0,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.1,
    momentumRating: 0,
    organicRating: 0,
    vineRating: 0
  }
]

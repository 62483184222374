import { SocialContentType, UserCampaignSocialContentReviewType } from '@productwindtom/shared-ws-zeus-types'

const SOCIAL_POST_VIDEO = [
  [
    UserCampaignSocialContentReviewType.AUDIO_CHECK
    // Disabled until we implement an escape hatch for creators to dispute the results of the check
    //, UserCampaignSocialContentReviewType.EXPLICIT_AUDIO_CHECK
  ],
  [UserCampaignSocialContentReviewType.ASPECT_RATIO_CHECK]
]

const DEFAULT = [
  [UserCampaignSocialContentReviewType.AUDIO_CHECK],
  [UserCampaignSocialContentReviewType.ASPECT_RATIO_CHECK]
]

const UGC = [[UserCampaignSocialContentReviewType.AUDIO_CHECK, UserCampaignSocialContentReviewType.VIDEO_QUALITY_CHECK]]

const YOUTUBE = [[UserCampaignSocialContentReviewType.AUDIO_CHECK]]

export const socialContentSystemChecksConfig: { [key: string]: UserCampaignSocialContentReviewType[][] } = {
  [SocialContentType.IG_STORY]: [...SOCIAL_POST_VIDEO, [UserCampaignSocialContentReviewType.SIMILAR_CONTENT_CHECK]],
  [SocialContentType.IG_REEL]: SOCIAL_POST_VIDEO,
  [SocialContentType.IG_VIDEO]: SOCIAL_POST_VIDEO,
  [SocialContentType.TT_VIDEO]: SOCIAL_POST_VIDEO,
  [SocialContentType.IG_POST]: SOCIAL_POST_VIDEO,
  [SocialContentType.UGC_VIDEO]: UGC,
  [SocialContentType.UGC_PHOTO]: UGC,
  [SocialContentType.YT_VIDEO]: YOUTUBE,
  [SocialContentType.YT_SHOUTOUT]: YOUTUBE,
  [SocialContentType.YT_SHORT]: YOUTUBE
}

export const getSocialContentSystemChecksConfig = (socialContentType: SocialContentType) => {
  return socialContentSystemChecksConfig[socialContentType] || DEFAULT
}

import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { ProposalCard } from '@momentum/components/proposal-card'
import { generatePath, Link as RouterLink, useNavigate } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'
import { orderBy } from 'lodash'
import { EmptyState } from './EmptyState'
import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { Add, Search } from '@mui/icons-material'
import { useState } from 'react'
import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ProposalStatus } from '@productwindtom/shared-momentum-zeus-types'

export const BrandProposals = () => {
  const { isAdminView } = useUserSessionContext()
  const { brand, proposals } = useBrandContext()

  const [search, setSearch] = useState('')

  const navigate = useNavigate()

  const handleEdit = (proposalId: string, isFinancialSummaryView?: boolean) => {
    navigate(generatePath(ROUTES.BRAND_CREATE_PROPOSAL_EDIT, { brandId: brand.id, id: proposalId }), {
      state: {
        isFinancialSummaryView
      }
    })
  }

  if (!proposals) {
    return <Loading />
  }

  if (!proposals.length) {
    return <EmptyState brandId={brand.id} />
  }

  const lowerSearch = search.toLowerCase()
  const filteredProposals = orderBy(
    proposals.filter(
      p =>
        (!search || p.product?.name.toLowerCase().includes(lowerSearch)) &&
        (isAdminView || p.status === ProposalStatus.PUBLISHED) &&
        p.status !== ProposalStatus.SUBMITTED
    ),
    v => v.updatedAt,
    'desc'
  )

  return (
    <Stack>
      {filteredProposals.length > 0 && (
        <Stack direction={'row'} justifyContent={'space-between'} mt={2} mb={3}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <DebouncedTextField
              placeholder={'Search proposals'}
              sx={{ mb: 4, width: 330 }}
              onChange={setSearch}
              InputProps={{
                endAdornment: <Search />
              }}
            />
          </Stack>
          <Button
            data-cy={'createProposal'}
            variant={'outlined'}
            component={RouterLink}
            startIcon={<Add />}
            to={generatePath(ROUTES.BRAND_CREATE_PROPOSAL, { brandId: brand.id })}
          >
            Create campaign
          </Button>
        </Stack>
      )}
      {filteredProposals.length > 0 && (
        <Stack spacing={3}>
          {filteredProposals.map(p => (
            <ProposalCard
              key={p.id}
              proposal={p}
              brandName={brand?.name || 'Brand'}
              onEditClick={() => handleEdit(p.id)}
              onViewClick={isFinancialSummaryView => handleEdit(p.id, isFinancialSummaryView)}
            />
          ))}
        </Stack>
      )}
      {filteredProposals.length === 0 && <EmptyState brandId={brand.id} />}
    </Stack>
  )
}

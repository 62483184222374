import { DialogTitle, Stack, Typography, IconButton, DialogContent, Dialog } from '@mui/material'
import { Close } from '@mui/icons-material'
import React from 'react'
import { CampaignPerformanceProduct } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { AddProductForm } from '@momentum/routes/campaign/e-commerce/performance/components/add-product-form'

export const EditProductDialog = ({
  open,
  product,
  productIndex,
  onClose
}: {
  open: boolean
  product: CampaignPerformanceProduct
  productIndex: number
  onClose: () => void
}) => {
  return (
    <Dialog open={open} maxWidth={'sm'} fullWidth onClick={event => event.stopPropagation()}>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'h4'}>Edit product</Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <AddProductForm product={product} productIndex={productIndex} onClose={onClose} />
      </DialogContent>
    </Dialog>
  )
}

import { Close } from '@mui/icons-material'
import { Dialog, IconButton, Stack } from '@mui/material'
import Slider from '@momentum/components/slider'
import { useApproveContentContext } from '../context/ApproveContentContext'
import DraftDetails from './DraftDetails'
import { ApproveContentViewMode } from '../types'
import RequestEdits from './RequestEdits'
import DraftCaption from './DraftCaption'
import { DraftStatus } from '@productwindtom/shared-momentum-zeus-types'
import DraftMedia from './DraftMedia'

const ApproveContentDialog = () => {
  const {
    approveContentViewMode,
    setApproveContentViewMode,
    content: { draftMedia, draftStatus },
    setSelectedAsset
  } = useApproveContentContext()

  return (
    <Dialog open={!!approveContentViewMode} maxWidth={'xl'}>
      <Stack p={2.5}>
        <IconButton
          sx={{
            marginLeft: 'auto'
          }}
          onClick={() => setApproveContentViewMode(undefined)}
          size={'small'}
        >
          <Close />
        </IconButton>
        <Stack
          direction="row"
          alignItems={'flex-start'}
          spacing={3}
          p={2.5}
          bgcolor={theme => theme.palette.grey.A100}
          border={theme => `1px solid ${theme.palette.grey.A200}`}
          borderRadius={'4px'}
        >
          <Stack width={375}>
            <Slider dots={(draftMedia?.length || 0) > 1} adaptiveHeight afterChange={setSelectedAsset}>
              {draftMedia?.map((media, index) => <DraftMedia key={index} src={media} />)}
            </Slider>
            {(draftStatus !== DraftStatus.REQUIRES_BRAND_APPROVAL ||
              approveContentViewMode !== ApproveContentViewMode.REVIEW_CONTENT) && <DraftCaption />}
          </Stack>
          <Stack width={275} p={2} border={theme => `1px solid ${theme.palette.grey.A200}`} borderRadius={'4px'}>
            {approveContentViewMode === ApproveContentViewMode.REVIEW_CONTENT ||
            draftStatus !== DraftStatus.REQUIRES_BRAND_APPROVAL ? (
              <DraftDetails />
            ) : (
              <RequestEdits />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ApproveContentDialog

import { ProductKeywordSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'

export const SAMPLE_CAMPAIGN_KEYWORDS_KEYBOARD_REVIEWS_PL: { items: ProductKeywordSummary[] } = {
  items: [
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_100 keyboard',
      keyword: '100 keyboard',
      searchVolume: 368,
      organicStartRank: 109,
      organicHighestRank: 55,
      sponsoredStartRank: 50,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 282,
          organicRank: 65,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 282,
          organicRank: 73,
          sponsoredRank: 20,
          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 282,
          organicRank: 80,

          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 282,
          organicRank: 86,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 282,
          organicRank: 74,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 282,
          organicRank: 55,

          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 385,
          organicRank: 59,
          sponsoredRank: 86,
          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 385,
          organicRank: 63,
          sponsoredRank: 48,
          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 385,
          organicRank: 68,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 385,
          organicRank: 61,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 385,
          organicRank: 73,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 385,
          organicRank: 71,
          sponsoredRank: 25,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 385,
          organicRank: 92,
          sponsoredRank: 25,
          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 349,
          organicRank: 100,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 349,
          organicRank: 77,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 349,
          organicRank: 109,

          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 349,
          organicRank: 97,

          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 349,
          organicRank: 92,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 349,
          organicRank: 105,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 349,
          organicRank: 111,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 247,
          organicRank: 118,
          sponsoredRank: 7,
          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 247,
          organicRank: 106,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 247,
          organicRank: 120,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 247,
          organicRank: 118,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 247,
          organicRank: 109,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 247,
          organicRank: 94,

          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 247,
          organicRank: 82,

          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 368,
          organicRank: 98,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 368,
          organicRank: 89,

          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 368,
          organicRank: 79,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 368,
          organicRank: 121,
          sponsoredRank: 57,
          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 368,
          organicRank: 128,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 368,
          organicRank: 134,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 368,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 319,
          organicRank: 118,

          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 319,
          organicRank: 117,

          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 319,
          organicRank: 125,

          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 319,
          organicRank: 117,

          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 319,
          organicRank: 97,
          sponsoredRank: 58,
          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 319,
          organicRank: 99,
          sponsoredRank: 15,
          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 319,
          organicRank: 120,

          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 287,
          organicRank: 107,

          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 287,
          organicRank: 124,

          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 287,
          organicRank: 120,

          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 287,
          organicRank: 103,

          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 287,
          organicRank: 119,

          keywordSales: 3
        },
        {
          date: '2024-05-05',
          searchVolume: 287,
          organicRank: 116,

          keywordSales: 3
        },
        {
          date: '2024-05-04',
          searchVolume: 287,
          organicRank: 109,

          keywordSales: 3
        },
        {
          date: '2024-05-03',
          searchVolume: 348,
          organicRank: 107,
          sponsoredRank: 50,
          keywordSales: 3
        },
        {
          date: '2024-05-02',
          searchVolume: 348,
          organicRank: 84,

          keywordSales: 3
        },
        {
          date: '2024-05-01',
          searchVolume: 348,
          organicRank: 66,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_60 gaming keyboard',
      keyword: '60 gaming keyboard',
      searchVolume: 286,
      organicStartRank: 7,
      organicHighestRank: 6,
      sponsoredStartRank: 55,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 277,
          organicRank: 13,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 277,
          organicRank: 8,

          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 277,
          organicRank: 13,
          sponsoredRank: 71,
          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 277,
          organicRank: 15,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 277,
          organicRank: 14,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 277,
          organicRank: 15,
          sponsoredRank: 44,
          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 254,
          organicRank: 12,

          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 254,
          organicRank: 10,
          sponsoredRank: 68,
          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 254,
          organicRank: 14,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 254,
          organicRank: 7,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 254,
          organicRank: 17,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 254,
          organicRank: 19,
          sponsoredRank: 5,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 254,
          organicRank: 18,

          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 276,
          organicRank: 11,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 276,
          organicRank: 12,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 276,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 276,
          organicRank: 13,

          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 276,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 276,
          organicRank: 23,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 276,
          organicRank: 6,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 286,
          organicRank: 9,
          sponsoredRank: 63,
          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 286,
          organicRank: 6,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 286,
          organicRank: 7,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 286,
          organicRank: 20,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 286,
          organicRank: 19,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 286,
          organicRank: 12,
          sponsoredRank: 64,
          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 286,
          organicRank: 9,
          sponsoredRank: 45,
          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 286,
          organicRank: 18,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 286,
          organicRank: 9,

          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 286,
          organicRank: 8,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 286,
          organicRank: 11,

          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 286,
          organicRank: 9,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 286,
          organicRank: 9,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 286,
          organicRank: 11,
          sponsoredRank: 61,
          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 255,
          organicRank: 10,
          sponsoredRank: 5,
          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 255,
          organicRank: 7,

          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 255,
          organicRank: 8,

          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 255,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 255,
          organicRank: 13,

          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 255,
          organicRank: 9,

          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 255,
          organicRank: 15,
          sponsoredRank: 60,
          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 236,
          organicRank: 14,
          sponsoredRank: 60,
          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 236,
          organicRank: 7,

          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 236,
          organicRank: 16,
          sponsoredRank: 71,
          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 236,
          organicRank: 15,

          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 236,
          organicRank: 6,

          keywordSales: 3
        },
        {
          date: '2024-05-05',
          searchVolume: 236,
          organicRank: 6,

          keywordSales: 3
        },
        {
          date: '2024-05-04',
          searchVolume: 236,
          organicRank: 7,
          sponsoredRank: 55,
          keywordSales: 3
        },
        {
          date: '2024-05-03',
          searchVolume: 306,
          organicRank: 6,

          keywordSales: 3
        },
        {
          date: '2024-05-02',
          searchVolume: 306,
          organicRank: 6,

          keywordSales: 3
        },
        {
          date: '2024-05-01',
          searchVolume: 306,
          organicRank: 6,
          sponsoredRank: 37,
          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_60 keyboard',
      keyword: '60 keyboard',
      searchVolume: 1202,
      organicStartRank: 18,
      organicHighestRank: 8,
      sponsoredStartRank: 36,
      sponsoredHighestRank: 14,
      organicPageOneWinDate: '2024-05-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 953,
          organicRank: 20,

          keywordSales: 15
        },
        {
          date: '2024-06-19',
          searchVolume: 953,
          organicRank: 15,

          keywordSales: 15
        },
        {
          date: '2024-06-18',
          searchVolume: 953,
          organicRank: 14,
          sponsoredRank: 58,
          keywordSales: 15
        },
        {
          date: '2024-06-17',
          searchVolume: 953,
          organicRank: 8,

          keywordSales: 15
        },
        {
          date: '2024-06-16',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 15
        },
        {
          date: '2024-06-15',
          searchVolume: 953,
          organicRank: 16,

          keywordSales: 15
        },
        {
          date: '2024-06-14',
          searchVolume: 884,
          organicRank: 22,

          keywordSales: 15
        },
        {
          date: '2024-06-13',
          searchVolume: 884,
          organicRank: 23,

          keywordSales: 15
        },
        {
          date: '2024-06-12',
          searchVolume: 884,
          organicRank: 19,

          keywordSales: 15
        },
        {
          date: '2024-06-11',
          searchVolume: 884,
          organicRank: 21,

          keywordSales: 15
        },
        {
          date: '2024-06-10',
          searchVolume: 884,
          organicRank: 17,

          keywordSales: 15
        },
        {
          date: '2024-06-09',
          searchVolume: 884,
          organicRank: 20,

          keywordSales: 15
        },
        {
          date: '2024-06-08',
          searchVolume: 884,
          organicRank: 21,

          keywordSales: 15
        },
        {
          date: '2024-06-07',
          searchVolume: 844,
          organicRank: 24,

          keywordSales: 15
        },
        {
          date: '2024-06-06',
          searchVolume: 844,
          organicRank: 22,

          keywordSales: 15
        },
        {
          date: '2024-06-05',
          searchVolume: 844,
          organicRank: 25,
          sponsoredRank: 14,
          keywordSales: 15
        },
        {
          date: '2024-06-04',
          searchVolume: 844,
          organicRank: 25,

          keywordSales: 15
        },
        {
          date: '2024-06-03',
          searchVolume: 844,
          organicRank: 25,

          keywordSales: 15
        },
        {
          date: '2024-06-02',
          searchVolume: 844,
          organicRank: 17,

          keywordSales: 15
        },
        {
          date: '2024-06-01',
          searchVolume: 844,
          organicRank: 31,

          keywordSales: 15
        },
        {
          date: '2024-05-31',
          searchVolume: 736,
          organicRank: 27,
          sponsoredRank: 38,
          keywordSales: 15
        },
        {
          date: '2024-05-30',
          searchVolume: 736,
          organicRank: 23,

          keywordSales: 15
        },
        {
          date: '2024-05-29',
          searchVolume: 736,
          organicRank: 29,

          keywordSales: 15
        },
        {
          date: '2024-05-28',
          searchVolume: 736,
          organicRank: 23,

          keywordSales: 15
        },
        {
          date: '2024-05-27',
          searchVolume: 736,
          organicRank: 21,

          keywordSales: 15
        },
        {
          date: '2024-05-26',
          searchVolume: 736,
          organicRank: 22,
          sponsoredRank: 79,
          keywordSales: 15
        },
        {
          date: '2024-05-25',
          searchVolume: 736,
          organicRank: 22,

          keywordSales: 15
        },
        {
          date: '2024-05-24',
          searchVolume: 1202,
          organicRank: 22,

          keywordSales: 15
        },
        {
          date: '2024-05-23',
          searchVolume: 1202,
          organicRank: 20,

          keywordSales: 15
        },
        {
          date: '2024-05-22',
          searchVolume: 1202,
          organicRank: 24,

          keywordSales: 15
        },
        {
          date: '2024-05-21',
          searchVolume: 1202,
          organicRank: 26,

          keywordSales: 15
        },
        {
          date: '2024-05-20',
          searchVolume: 1202,
          organicRank: 22,

          keywordSales: 15
        },
        {
          date: '2024-05-19',
          searchVolume: 1202,
          organicRank: 26,

          keywordSales: 15
        },
        {
          date: '2024-05-18',
          searchVolume: 1202,
          organicRank: 24,

          keywordSales: 15
        },
        {
          date: '2024-05-17',
          searchVolume: 797,
          organicRank: 20,
          sponsoredRank: 23,
          keywordSales: 15
        },
        {
          date: '2024-05-16',
          searchVolume: 797,
          organicRank: 25,

          keywordSales: 15
        },
        {
          date: '2024-05-15',
          searchVolume: 797,
          organicRank: 22,

          keywordSales: 15
        },
        {
          date: '2024-05-14',
          searchVolume: 797,
          organicRank: 19,

          keywordSales: 15
        },
        {
          date: '2024-05-13',
          searchVolume: 797,
          organicRank: 19,
          sponsoredRank: 36,
          keywordSales: 15
        },
        {
          date: '2024-05-12',
          searchVolume: 797,
          organicRank: 21,

          keywordSales: 15
        },
        {
          date: '2024-05-11',
          searchVolume: 797,
          organicRank: 19,

          keywordSales: 15
        },
        {
          date: '2024-05-10',
          searchVolume: 675,
          organicRank: 24,

          keywordSales: 15
        },
        {
          date: '2024-05-09',
          searchVolume: 675,
          organicRank: 23,

          keywordSales: 15
        },
        {
          date: '2024-05-08',
          searchVolume: 675,
          organicRank: 23,

          keywordSales: 15
        },
        {
          date: '2024-05-07',
          searchVolume: 675,
          organicRank: 20,

          keywordSales: 15
        },
        {
          date: '2024-05-06',
          searchVolume: 675,
          organicRank: 15,

          keywordSales: 15
        },
        {
          date: '2024-05-05',
          searchVolume: 675,
          organicRank: 13,

          keywordSales: 15
        },
        {
          date: '2024-05-04',
          searchVolume: 675,
          organicRank: 18,

          keywordSales: 15
        },
        {
          date: '2024-05-03',
          searchVolume: 713,
          organicRank: 21,

          keywordSales: 15
        },
        {
          date: '2024-05-02',
          searchVolume: 713,
          organicRank: 16,

          keywordSales: 15
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_60 mechanical keyboard',
      keyword: '60 mechanical keyboard',
      searchVolume: 218,
      organicStartRank: 44,
      organicHighestRank: 19,
      sponsoredStartRank: 81,
      sponsoredHighestRank: 20,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 345,
          organicRank: 27,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 345,
          organicRank: 19,
          sponsoredRank: 20,
          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 345,
          organicRank: 28,

          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 345,
          organicRank: 27,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 345,
          organicRank: 26,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 345,
          organicRank: 31,

          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 363,
          organicRank: 23,

          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 363,
          organicRank: 30,

          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 363,
          organicRank: 28,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 363,
          organicRank: 25,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 363,
          organicRank: 31,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 363,
          organicRank: 30,

          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 363,
          organicRank: 38,

          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 244,
          organicRank: 29,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 244,
          organicRank: 33,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 244,
          organicRank: 33,

          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 244,
          organicRank: 37,
          sponsoredRank: 81,
          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 244,
          organicRank: 43,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 244,
          organicRank: 44,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 244,
          organicRank: 45,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 201,
          organicRank: 41,
          sponsoredRank: 69,
          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 201,
          organicRank: 38,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 201,
          organicRank: 53,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 201,
          organicRank: 54,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 201,
          organicRank: 48,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 201,
          organicRank: 53,

          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 201,
          organicRank: 54,

          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 218,
          organicRank: 43,
          sponsoredRank: 74,
          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 218,
          organicRank: 40,

          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 218,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 218,
          organicRank: 44,

          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 218,
          organicRank: 48,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 218,
          organicRank: 52,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 218,
          organicRank: 52,

          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 178,
          organicRank: 57,
          sponsoredRank: 63,
          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 178,
          organicRank: 39,

          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 178,
          organicRank: 46,

          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 178,
          organicRank: 37,

          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 178,
          organicRank: 31,
          sponsoredRank: 65,
          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 178,
          organicRank: 36,
          sponsoredRank: 92,
          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 178,
          organicRank: 37,

          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 171,
          organicRank: 35,

          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 171,
          organicRank: 38,

          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 171,
          organicRank: 36,

          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 171,
          organicRank: 29,

          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 171,
          organicRank: 66,

          keywordSales: 3
        },
        {
          date: '2024-05-05',
          searchVolume: 171,
          organicRank: 40,
          sponsoredRank: 81,
          keywordSales: 3
        },
        {
          date: '2024-05-04',
          searchVolume: 171,
          organicRank: 44,

          keywordSales: 3
        },
        {
          date: '2024-05-03',
          searchVolume: 243,
          organicRank: 47,

          keywordSales: 3
        },
        {
          date: '2024-05-02',
          searchVolume: 243,
          organicRank: 36,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_60 percent gaming keyboard',
      keyword: '60 percent gaming keyboard',
      searchVolume: 3078,
      organicStartRank: 9,
      organicHighestRank: 6,
      sponsoredStartRank: 69,
      sponsoredHighestRank: 7,
      organicPageOneWinDate: '2024-05-16',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2392,
          organicRank: 13,

          keywordSales: 65
        },
        {
          date: '2024-06-19',
          searchVolume: 2392,
          organicRank: 10,
          sponsoredRank: 9,
          keywordSales: 65
        },
        {
          date: '2024-06-18',
          searchVolume: 2392,
          organicRank: 10,
          sponsoredRank: 30,
          keywordSales: 65
        },
        {
          date: '2024-06-17',
          searchVolume: 2392,
          organicRank: 16,
          sponsoredRank: 21,
          keywordSales: 65
        },
        {
          date: '2024-06-16',
          searchVolume: 2392,
          organicRank: 11,

          keywordSales: 65
        },
        {
          date: '2024-06-15',
          searchVolume: 2392,
          organicRank: 10,

          keywordSales: 65
        },
        {
          date: '2024-06-14',
          searchVolume: 2394,
          organicRank: 14,
          sponsoredRank: 17,
          keywordSales: 65
        },
        {
          date: '2024-06-13',
          searchVolume: 2394,
          organicRank: 11,

          keywordSales: 65
        },
        {
          date: '2024-06-12',
          searchVolume: 2394,
          organicRank: 10,

          keywordSales: 65
        },
        {
          date: '2024-06-11',
          searchVolume: 2394,
          organicRank: 7,

          keywordSales: 65
        },
        {
          date: '2024-06-10',
          searchVolume: 2394,
          organicRank: 8,

          keywordSales: 65
        },
        {
          date: '2024-06-09',
          searchVolume: 2394,
          organicRank: 14,
          sponsoredRank: 30,
          keywordSales: 65
        },
        {
          date: '2024-06-08',
          searchVolume: 2394,
          organicRank: 31,

          keywordSales: 65
        },
        {
          date: '2024-06-07',
          searchVolume: 2434,
          organicRank: 14,

          keywordSales: 65
        },
        {
          date: '2024-06-06',
          searchVolume: 2434,
          organicRank: 10,

          keywordSales: 65
        },
        {
          date: '2024-06-05',
          searchVolume: 2434,
          organicRank: 27,
          sponsoredRank: 8,
          keywordSales: 65
        },
        {
          date: '2024-06-04',
          searchVolume: 2434,
          organicRank: 9,

          keywordSales: 65
        },
        {
          date: '2024-06-03',
          searchVolume: 2434,
          organicRank: 9,

          keywordSales: 65
        },
        {
          date: '2024-06-02',
          searchVolume: 2434,
          organicRank: 9,

          keywordSales: 65
        },
        {
          date: '2024-06-01',
          searchVolume: 2434,
          organicRank: 10,
          sponsoredRank: 66,
          keywordSales: 65
        },
        {
          date: '2024-05-31',
          searchVolume: 3091,
          organicRank: 7,
          sponsoredRank: 19,
          keywordSales: 65
        },
        {
          date: '2024-05-30',
          searchVolume: 3091,
          organicRank: 6,

          keywordSales: 65
        },
        {
          date: '2024-05-29',
          searchVolume: 3091,
          organicRank: 14,

          keywordSales: 65
        },
        {
          date: '2024-05-28',
          searchVolume: 3091,
          organicRank: 14,

          keywordSales: 65
        },
        {
          date: '2024-05-27',
          searchVolume: 3091,
          organicRank: 8,

          keywordSales: 65
        },
        {
          date: '2024-05-26',
          searchVolume: 3091,
          organicRank: 6,
          sponsoredRank: 56,
          keywordSales: 65
        },
        {
          date: '2024-05-25',
          searchVolume: 3091,
          organicRank: 6,

          keywordSales: 65
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 9,

          keywordSales: 65
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 6,

          keywordSales: 65
        },
        {
          date: '2024-05-22',
          searchVolume: 3078,
          organicRank: 7,

          keywordSales: 65
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 13,
          sponsoredRank: 42,
          keywordSales: 65
        },
        {
          date: '2024-05-20',
          searchVolume: 3078,
          organicRank: 15,

          keywordSales: 65
        },
        {
          date: '2024-05-19',
          searchVolume: 3078,
          organicRank: 15,

          keywordSales: 65
        },
        {
          date: '2024-05-18',
          searchVolume: 3078,
          organicRank: 19,
          sponsoredRank: 37,
          keywordSales: 65
        },
        {
          date: '2024-05-17',
          searchVolume: 3562,
          organicRank: 13,
          sponsoredRank: 7,
          keywordSales: 65
        },
        {
          date: '2024-05-16',
          searchVolume: 3562,
          organicRank: 17,
          sponsoredRank: 42,
          keywordSales: 65
        },
        {
          date: '2024-05-15',
          searchVolume: 3562,
          organicRank: 23,

          keywordSales: 65
        },
        {
          date: '2024-05-14',
          searchVolume: 3562,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-13',
          searchVolume: 3562,
          organicRank: 15,

          keywordSales: 65
        },
        {
          date: '2024-05-12',
          searchVolume: 3562,
          organicRank: 18,
          sponsoredRank: 60,
          keywordSales: 65
        },
        {
          date: '2024-05-11',
          searchVolume: 3562,
          organicRank: 14,
          sponsoredRank: 44,
          keywordSales: 65
        },
        {
          date: '2024-05-10',
          searchVolume: 3622,
          organicRank: 17,
          sponsoredRank: 90,
          keywordSales: 65
        },
        {
          date: '2024-05-09',
          searchVolume: 3622,
          organicRank: 20,

          keywordSales: 65
        },
        {
          date: '2024-05-08',
          searchVolume: 3622,
          organicRank: 11,
          sponsoredRank: 24,
          keywordSales: 65
        },
        {
          date: '2024-05-07',
          searchVolume: 3622,
          organicRank: 17,
          sponsoredRank: 60,
          keywordSales: 65
        },
        {
          date: '2024-05-06',
          searchVolume: 3622,
          organicRank: 9,

          keywordSales: 65
        },
        {
          date: '2024-05-05',
          searchVolume: 3622,
          organicRank: 9,
          sponsoredRank: 69,
          keywordSales: 65
        },
        {
          date: '2024-05-04',
          searchVolume: 3622,
          organicRank: 9,

          keywordSales: 65
        },
        {
          date: '2024-05-03',
          searchVolume: 3480,
          organicRank: 7,

          keywordSales: 65
        },
        {
          date: '2024-05-02',
          searchVolume: 3480,
          organicRank: 7,

          keywordSales: 65
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_60 percent keyboard',
      keyword: '60 percent keyboard',
      searchVolume: 10606,
      organicStartRank: 16,
      organicHighestRank: 4,
      sponsoredStartRank: 37,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-07',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 9284,
          organicRank: 13,

          keywordSales: 215
        },
        {
          date: '2024-06-19',
          searchVolume: 9284,
          organicRank: 11,
          sponsoredRank: 7,
          keywordSales: 215
        },
        {
          date: '2024-06-18',
          searchVolume: 9284,
          organicRank: 13,
          sponsoredRank: 57,
          keywordSales: 215
        },
        {
          date: '2024-06-17',
          searchVolume: 9284,
          organicRank: 7,
          sponsoredRank: 62,
          keywordSales: 215
        },
        {
          date: '2024-06-16',
          searchVolume: 9284,
          organicRank: 8,

          keywordSales: 215
        },
        {
          date: '2024-06-15',
          searchVolume: 9284,
          organicRank: 6,
          sponsoredRank: 39,
          keywordSales: 215
        },
        {
          date: '2024-06-14',
          searchVolume: 10859,
          organicRank: 7,
          sponsoredRank: 25,
          keywordSales: 215
        },
        {
          date: '2024-06-13',
          searchVolume: 10859,
          organicRank: 5,

          keywordSales: 215
        },
        {
          date: '2024-06-12',
          searchVolume: 10859,
          organicRank: 5,

          keywordSales: 215
        },
        {
          date: '2024-06-11',
          searchVolume: 10859,
          organicRank: 4,

          keywordSales: 215
        },
        {
          date: '2024-06-10',
          searchVolume: 10859,
          organicRank: 7,

          keywordSales: 215
        },
        {
          date: '2024-06-09',
          searchVolume: 10859,
          organicRank: 5,
          sponsoredRank: 17,
          keywordSales: 215
        },
        {
          date: '2024-06-08',
          searchVolume: 10859,
          organicRank: 7,

          keywordSales: 215
        },
        {
          date: '2024-06-07',
          searchVolume: 30671,
          organicRank: 10,

          keywordSales: 215
        },
        {
          date: '2024-06-06',
          searchVolume: 30671,
          organicRank: 7,

          keywordSales: 215
        },
        {
          date: '2024-06-05',
          searchVolume: 30671,
          organicRank: 7,
          sponsoredRank: 22,
          keywordSales: 215
        },
        {
          date: '2024-06-04',
          searchVolume: 30671,
          organicRank: 9,

          keywordSales: 215
        },
        {
          date: '2024-06-03',
          searchVolume: 30671,
          organicRank: 22,

          keywordSales: 215
        },
        {
          date: '2024-06-02',
          searchVolume: 30671,
          organicRank: 23,

          keywordSales: 215
        },
        {
          date: '2024-06-01',
          searchVolume: 30671,
          organicRank: 16,

          keywordSales: 215
        },
        {
          date: '2024-05-31',
          searchVolume: 9142,
          organicRank: 13,

          keywordSales: 215
        },
        {
          date: '2024-05-30',
          searchVolume: 9142,
          organicRank: 9,

          keywordSales: 215
        },
        {
          date: '2024-05-29',
          searchVolume: 9142,
          organicRank: 12,

          keywordSales: 215
        },
        {
          date: '2024-05-28',
          searchVolume: 9142,
          organicRank: 17,

          keywordSales: 215
        },
        {
          date: '2024-05-27',
          searchVolume: 9142,
          organicRank: 16,

          keywordSales: 215
        },
        {
          date: '2024-05-26',
          searchVolume: 9142,
          organicRank: 15,
          sponsoredRank: 88,
          keywordSales: 215
        },
        {
          date: '2024-05-25',
          searchVolume: 9142,
          organicRank: 17,
          sponsoredRank: 27,
          keywordSales: 215
        },
        {
          date: '2024-05-24',
          searchVolume: 10606,
          organicRank: 7,

          keywordSales: 215
        },
        {
          date: '2024-05-23',
          searchVolume: 10606,
          organicRank: 5,

          keywordSales: 215
        },
        {
          date: '2024-05-22',
          searchVolume: 10606,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 215
        },
        {
          date: '2024-05-21',
          searchVolume: 10606,
          organicRank: 21,

          keywordSales: 215
        },
        {
          date: '2024-05-20',
          searchVolume: 10606,
          organicRank: 24,

          keywordSales: 215
        },
        {
          date: '2024-05-19',
          searchVolume: 10606,
          organicRank: 31,

          keywordSales: 215
        },
        {
          date: '2024-05-18',
          searchVolume: 10606,
          organicRank: 18,
          sponsoredRank: 13,
          keywordSales: 215
        },
        {
          date: '2024-05-17',
          searchVolume: 9125,
          organicRank: 18,
          sponsoredRank: 12,
          keywordSales: 215
        },
        {
          date: '2024-05-16',
          searchVolume: 9125,
          organicRank: 19,
          sponsoredRank: 23,
          keywordSales: 215
        },
        {
          date: '2024-05-15',
          searchVolume: 9125,
          organicRank: 21,
          sponsoredRank: 25,
          keywordSales: 215
        },
        {
          date: '2024-05-14',
          searchVolume: 9125,
          organicRank: 23,
          sponsoredRank: 62,
          keywordSales: 215
        },
        {
          date: '2024-05-13',
          searchVolume: 9125,
          organicRank: 19,
          sponsoredRank: 63,
          keywordSales: 215
        },
        {
          date: '2024-05-12',
          searchVolume: 9125,
          organicRank: 20,
          sponsoredRank: 10,
          keywordSales: 215
        },
        {
          date: '2024-05-11',
          searchVolume: 9125,
          organicRank: 17,
          sponsoredRank: 46,
          keywordSales: 215
        },
        {
          date: '2024-05-10',
          searchVolume: 9282,
          organicRank: 21,
          sponsoredRank: 6,
          keywordSales: 215
        },
        {
          date: '2024-05-09',
          searchVolume: 9282,
          organicRank: 18,
          sponsoredRank: 33,
          keywordSales: 215
        },
        {
          date: '2024-05-08',
          searchVolume: 9282,
          organicRank: 22,
          sponsoredRank: 23,
          keywordSales: 215
        },
        {
          date: '2024-05-07',
          searchVolume: 9282,
          organicRank: 17,
          sponsoredRank: 58,
          keywordSales: 215
        },
        {
          date: '2024-05-06',
          searchVolume: 9282,
          organicRank: 30,
          sponsoredRank: 26,
          keywordSales: 215
        },
        {
          date: '2024-05-05',
          searchVolume: 9282,
          organicRank: 16,
          sponsoredRank: 11,
          keywordSales: 215
        },
        {
          date: '2024-05-04',
          searchVolume: 9282,
          organicRank: 16,
          sponsoredRank: 37,
          keywordSales: 215
        },
        {
          date: '2024-05-03',
          searchVolume: 10564,
          organicRank: 10,

          keywordSales: 215
        },
        {
          date: '2024-05-02',
          searchVolume: 10564,
          organicRank: 17,

          keywordSales: 215
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_65 percent keyboard',
      keyword: '65 percent keyboard',
      searchVolume: 1900,
      organicStartRank: 4,
      organicHighestRank: 1,
      sponsoredStartRank: 61,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-07',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1924,
          organicRank: 1,
          sponsoredRank: 40,
          keywordSales: 14
        },
        {
          date: '2024-06-19',
          searchVolume: 1924,
          organicRank: 2,
          sponsoredRank: 27,
          keywordSales: 14
        },
        {
          date: '2024-06-18',
          searchVolume: 1924,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 14
        },
        {
          date: '2024-06-17',
          searchVolume: 1924,
          organicRank: 2,

          keywordSales: 14
        },
        {
          date: '2024-06-16',
          searchVolume: 1924,
          organicRank: 1,

          keywordSales: 14
        },
        {
          date: '2024-06-15',
          searchVolume: 1924,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 14
        },
        {
          date: '2024-06-14',
          searchVolume: 2173,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 14
        },
        {
          date: '2024-06-13',
          searchVolume: 2173,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 14
        },
        {
          date: '2024-06-12',
          searchVolume: 2173,
          organicRank: 1,

          keywordSales: 14
        },
        {
          date: '2024-06-11',
          searchVolume: 2173,
          organicRank: 1,

          keywordSales: 14
        },
        {
          date: '2024-06-10',
          searchVolume: 2173,
          organicRank: 2,

          keywordSales: 14
        },
        {
          date: '2024-06-09',
          searchVolume: 2173,
          organicRank: 1,
          sponsoredRank: 10,
          keywordSales: 14
        },
        {
          date: '2024-06-08',
          searchVolume: 2173,
          organicRank: 1,
          sponsoredRank: 23,
          keywordSales: 14
        },
        {
          date: '2024-06-07',
          searchVolume: 1830,
          organicRank: 5,

          keywordSales: 14
        },
        {
          date: '2024-06-06',
          searchVolume: 1830,
          organicRank: 1,

          keywordSales: 14
        },
        {
          date: '2024-06-05',
          searchVolume: 1830,
          organicRank: 3,

          keywordSales: 14
        },
        {
          date: '2024-06-04',
          searchVolume: 1830,
          organicRank: 3,

          keywordSales: 14
        },
        {
          date: '2024-06-03',
          searchVolume: 1830,
          organicRank: 1,

          keywordSales: 14
        },
        {
          date: '2024-06-02',
          searchVolume: 1830,
          organicRank: 5,

          keywordSales: 14
        },
        {
          date: '2024-06-01',
          searchVolume: 1830,
          organicRank: 2,

          keywordSales: 14
        },
        {
          date: '2024-05-31',
          searchVolume: 1822,
          organicRank: 5,
          sponsoredRank: 27,
          keywordSales: 14
        },
        {
          date: '2024-05-30',
          searchVolume: 1822,
          organicRank: 3,

          keywordSales: 14
        },
        {
          date: '2024-05-29',
          searchVolume: 1822,
          organicRank: 6,

          keywordSales: 14
        },
        {
          date: '2024-05-28',
          searchVolume: 1822,
          organicRank: 6,

          keywordSales: 14
        },
        {
          date: '2024-05-27',
          searchVolume: 1822,
          organicRank: 9,

          keywordSales: 14
        },
        {
          date: '2024-05-26',
          searchVolume: 1822,
          organicRank: 10,
          sponsoredRank: 8,
          keywordSales: 14
        },
        {
          date: '2024-05-25',
          searchVolume: 1822,
          organicRank: 7,
          sponsoredRank: 63,
          keywordSales: 14
        },
        {
          date: '2024-05-24',
          searchVolume: 1900,
          organicRank: 6,
          sponsoredRank: 76,
          keywordSales: 14
        },
        {
          date: '2024-05-23',
          searchVolume: 1900,
          organicRank: 7,
          sponsoredRank: 70,
          keywordSales: 14
        },
        {
          date: '2024-05-22',
          searchVolume: 1900,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 14
        },
        {
          date: '2024-05-21',
          searchVolume: 1900,
          organicRank: 8,
          sponsoredRank: 58,
          keywordSales: 14
        },
        {
          date: '2024-05-20',
          searchVolume: 1900,
          organicRank: 8,

          keywordSales: 14
        },
        {
          date: '2024-05-19',
          searchVolume: 1900,
          organicRank: 7,

          keywordSales: 14
        },
        {
          date: '2024-05-18',
          searchVolume: 1900,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 14
        },
        {
          date: '2024-05-17',
          searchVolume: 1896,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 14
        },
        {
          date: '2024-05-16',
          searchVolume: 1896,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 14
        },
        {
          date: '2024-05-15',
          searchVolume: 1896,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 14
        },
        {
          date: '2024-05-14',
          searchVolume: 1896,
          organicRank: 6,

          keywordSales: 14
        },
        {
          date: '2024-05-13',
          searchVolume: 1896,
          organicRank: 6,
          sponsoredRank: 1,
          keywordSales: 14
        },
        {
          date: '2024-05-12',
          searchVolume: 1896,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 14
        },
        {
          date: '2024-05-11',
          searchVolume: 1896,
          organicRank: 8,
          sponsoredRank: 41,
          keywordSales: 14
        },
        {
          date: '2024-05-10',
          searchVolume: 1917,
          organicRank: 2,

          keywordSales: 14
        },
        {
          date: '2024-05-09',
          searchVolume: 1917,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 14
        },
        {
          date: '2024-05-08',
          searchVolume: 1917,
          organicRank: 7,
          sponsoredRank: 51,
          keywordSales: 14
        },
        {
          date: '2024-05-07',
          searchVolume: 1917,
          organicRank: 9,
          sponsoredRank: 6,
          keywordSales: 14
        },
        {
          date: '2024-05-06',
          searchVolume: 1917,
          organicRank: 9,
          sponsoredRank: 51,
          keywordSales: 14
        },
        {
          date: '2024-05-05',
          searchVolume: 1917,
          organicRank: 9,
          sponsoredRank: 68,
          keywordSales: 14
        },
        {
          date: '2024-05-04',
          searchVolume: 1917,
          organicRank: 4,
          sponsoredRank: 61,
          keywordSales: 14
        },
        {
          date: '2024-05-03',
          searchVolume: 1836,
          organicRank: 6,

          keywordSales: 14
        },
        {
          date: '2024-05-02',
          searchVolume: 1836,
          organicRank: 3,
          sponsoredRank: 8,
          keywordSales: 14
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_75 keyboard',
      keyword: '75 keyboard',
      searchVolume: 1324,
      organicStartRank: 99,
      organicHighestRank: 46,
      sponsoredStartRank: 49,
      sponsoredHighestRank: 17,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1221,
          organicRank: 105,

          keywordSales: 7
        },
        {
          date: '2024-06-19',
          searchVolume: 1221,
          organicRank: 112,
          sponsoredRank: 74,
          keywordSales: 7
        },
        {
          date: '2024-06-18',
          searchVolume: 1221,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 7
        },
        {
          date: '2024-06-17',
          searchVolume: 1221,
          organicRank: 103,

          keywordSales: 7
        },
        {
          date: '2024-06-16',
          searchVolume: 1221,
          organicRank: 98,

          keywordSales: 7
        },
        {
          date: '2024-06-15',
          searchVolume: 1221,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-14',
          searchVolume: 1349,
          organicRank: 119,

          keywordSales: 7
        },
        {
          date: '2024-06-13',
          searchVolume: 1349,
          organicRank: 111,

          keywordSales: 7
        },
        {
          date: '2024-06-12',
          searchVolume: 1349,
          organicRank: 111,

          keywordSales: 7
        },
        {
          date: '2024-06-11',
          searchVolume: 1349,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-10',
          searchVolume: 1349,
          organicRank: 112,

          keywordSales: 7
        },
        {
          date: '2024-06-09',
          searchVolume: 1349,
          organicRank: 96,

          keywordSales: 7
        },
        {
          date: '2024-06-08',
          searchVolume: 1349,
          organicRank: 61,

          keywordSales: 7
        },
        {
          date: '2024-06-07',
          searchVolume: 909,
          organicRank: 66,

          keywordSales: 7
        },
        {
          date: '2024-06-06',
          searchVolume: 909,
          organicRank: 63,

          keywordSales: 7
        },
        {
          date: '2024-06-05',
          searchVolume: 909,
          organicRank: 80,

          keywordSales: 7
        },
        {
          date: '2024-06-04',
          searchVolume: 909,
          organicRank: 64,

          keywordSales: 7
        },
        {
          date: '2024-06-03',
          searchVolume: 909,
          organicRank: 104,

          keywordSales: 7
        },
        {
          date: '2024-06-02',
          searchVolume: 909,
          organicRank: 46,

          keywordSales: 7
        },
        {
          date: '2024-06-01',
          searchVolume: 909,
          organicRank: 99,

          keywordSales: 7
        },
        {
          date: '2024-05-31',
          searchVolume: 1246,
          organicRank: 94,
          sponsoredRank: 55,
          keywordSales: 7
        },
        {
          date: '2024-05-30',
          searchVolume: 1246,
          organicRank: 88,

          keywordSales: 7
        },
        {
          date: '2024-05-29',
          searchVolume: 1246,
          organicRank: 83,

          keywordSales: 7
        },
        {
          date: '2024-05-28',
          searchVolume: 1246,
          organicRank: 88,

          keywordSales: 7
        },
        {
          date: '2024-05-27',
          searchVolume: 1246,
          organicRank: 95,

          keywordSales: 7
        },
        {
          date: '2024-05-26',
          searchVolume: 1246,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-05-25',
          searchVolume: 1246,
          organicRank: 93,

          keywordSales: 7
        },
        {
          date: '2024-05-24',
          searchVolume: 1324,
          organicRank: 88,

          keywordSales: 7
        },
        {
          date: '2024-05-23',
          searchVolume: 1324,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-05-22',
          searchVolume: 1324,
          organicRank: 94,

          keywordSales: 7
        },
        {
          date: '2024-05-21',
          searchVolume: 1324,
          organicRank: 156,

          keywordSales: 7
        },
        {
          date: '2024-05-20',
          searchVolume: 1324,
          organicRank: 85,

          keywordSales: 7
        },
        {
          date: '2024-05-19',
          searchVolume: 1324,
          organicRank: 77,

          keywordSales: 7
        },
        {
          date: '2024-05-18',
          searchVolume: 1324,
          organicRank: 66,

          keywordSales: 7
        },
        {
          date: '2024-05-17',
          searchVolume: 1198,
          organicRank: 72,
          sponsoredRank: 41,
          keywordSales: 7
        },
        {
          date: '2024-05-16',
          searchVolume: 1198,
          organicRank: 84,

          keywordSales: 7
        },
        {
          date: '2024-05-15',
          searchVolume: 1198,
          organicRank: 85,

          keywordSales: 7
        },
        {
          date: '2024-05-14',
          searchVolume: 1198,
          organicRank: 89,
          sponsoredRank: 19,
          keywordSales: 7
        },
        {
          date: '2024-05-13',
          searchVolume: 1198,
          organicRank: 90,
          sponsoredRank: 61,
          keywordSales: 7
        },
        {
          date: '2024-05-12',
          searchVolume: 1198,
          organicRank: 86,
          sponsoredRank: 23,
          keywordSales: 7
        },
        {
          date: '2024-05-11',
          searchVolume: 1198,
          organicRank: 83,
          sponsoredRank: 17,
          keywordSales: 7
        },
        {
          date: '2024-05-10',
          searchVolume: 953,
          organicRank: 77,
          sponsoredRank: 56,
          keywordSales: 7
        },
        {
          date: '2024-05-09',
          searchVolume: 953,
          organicRank: 79,

          keywordSales: 7
        },
        {
          date: '2024-05-08',
          searchVolume: 953,
          organicRank: 78,
          sponsoredRank: 44,
          keywordSales: 7
        },
        {
          date: '2024-05-07',
          searchVolume: 953,
          organicRank: 83,
          sponsoredRank: 80,
          keywordSales: 7
        },
        {
          date: '2024-05-06',
          searchVolume: 953,
          organicRank: 85,
          sponsoredRank: 49,
          keywordSales: 7
        },
        {
          date: '2024-05-05',
          searchVolume: 953,
          organicRank: 89,

          keywordSales: 7
        },
        {
          date: '2024-05-04',
          searchVolume: 953,
          organicRank: 99,

          keywordSales: 7
        },
        {
          date: '2024-05-03',
          searchVolume: 1283,
          organicRank: 85,

          keywordSales: 7
        },
        {
          date: '2024-05-02',
          searchVolume: 1283,
          organicRank: 90,

          keywordSales: 7
        },
        {
          date: '2024-05-01',
          searchVolume: 1283,
          organicRank: 91,

          keywordSales: 7
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_75 mechanical keyboard',
      keyword: '75 mechanical keyboard',
      searchVolume: 368,
      organicStartRank: 150,
      organicHighestRank: 55,
      sponsoredStartRank: 62,
      sponsoredHighestRank: 17,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 403,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 403,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 403,
          organicRank: 123,

          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 403,
          organicRank: 99,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 403,
          organicRank: 112,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 403,
          organicRank: 112,

          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 279,
          organicRank: 112,

          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 279,
          organicRank: 112,

          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 279,
          organicRank: 109,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 279,
          organicRank: 64,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 279,
          organicRank: 82,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 279,
          organicRank: 113,
          sponsoredRank: 17,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 279,
          organicRank: 112,

          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 348,
          organicRank: 120,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 348,
          organicRank: 94,
          sponsoredRank: 68,
          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 348,
          organicRank: 107,

          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 348,
          organicRank: 110,

          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 348,
          organicRank: 94,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 348,
          organicRank: 100,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 348,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 368,
          organicRank: 126,

          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 368,
          organicRank: 121,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 368,
          organicRank: 105,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 368,
          organicRank: 111,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 368,
          organicRank: 118,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 368,
          organicRank: 119,

          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 368,
          organicRank: 119,

          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 368,
          organicRank: 163,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 368,
          organicRank: 100,

          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 368,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 368,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 368,
          organicRank: 116,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 368,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 368,
          organicRank: 104,

          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 357,
          organicRank: 106,

          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 357,
          organicRank: 99,

          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 357,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 357,
          organicRank: 93,
          sponsoredRank: 79,
          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 357,
          organicRank: 76,
          sponsoredRank: 68,
          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 357,
          organicRank: 107,
          sponsoredRank: 79,
          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 357,
          organicRank: 57,
          sponsoredRank: 29,
          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 326,
          organicRank: 72,
          sponsoredRank: 49,
          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 326,
          organicRank: 85,

          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 326,
          organicRank: 72,
          sponsoredRank: 62,
          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 326,
          organicRank: 60,

          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 326,
          organicRank: 55,

          keywordSales: 3
        },
        {
          date: '2024-05-04',
          searchVolume: 326,
          organicRank: 150,

          keywordSales: 3
        },
        {
          date: '2024-05-03',
          searchVolume: 369,
          organicRank: 139,

          keywordSales: 3
        },
        {
          date: '2024-05-02',
          searchVolume: 369,
          organicRank: 184,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_75 percent keyboard',
      keyword: '75 percent keyboard',
      searchVolume: 3575,
      organicStartRank: 75,
      organicHighestRank: 42,
      sponsoredStartRank: 30,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 4322,
          organicRank: 85,

          keywordSales: 73
        },
        {
          date: '2024-06-20',
          searchVolume: 4322,
          organicRank: 77,

          keywordSales: 73
        },
        {
          date: '2024-06-19',
          searchVolume: 4322,
          organicRank: 65,

          keywordSales: 73
        },
        {
          date: '2024-06-18',
          searchVolume: 4322,
          organicRank: 74,
          sponsoredRank: 67,
          keywordSales: 73
        },
        {
          date: '2024-06-17',
          searchVolume: 4322,
          organicRank: 75,
          sponsoredRank: 47,
          keywordSales: 73
        },
        {
          date: '2024-06-16',
          searchVolume: 4322,
          organicRank: 93,

          keywordSales: 73
        },
        {
          date: '2024-06-15',
          searchVolume: 4322,
          organicRank: 64,

          keywordSales: 73
        },
        {
          date: '2024-06-14',
          searchVolume: 4322,
          organicRank: 62,

          keywordSales: 73
        },
        {
          date: '2024-06-13',
          searchVolume: 4322,
          organicRank: 55,

          keywordSales: 73
        },
        {
          date: '2024-06-12',
          searchVolume: 4322,
          organicRank: 76,

          keywordSales: 73
        },
        {
          date: '2024-06-11',
          searchVolume: 4322,
          organicRank: 63,

          keywordSales: 73
        },
        {
          date: '2024-06-10',
          searchVolume: 4322,
          organicRank: 49,

          keywordSales: 73
        },
        {
          date: '2024-06-09',
          searchVolume: 4322,
          organicRank: 64,
          sponsoredRank: 40,
          keywordSales: 73
        },
        {
          date: '2024-06-08',
          searchVolume: 4322,
          organicRank: 46,
          sponsoredRank: 48,
          keywordSales: 73
        },
        {
          date: '2024-06-07',
          searchVolume: 4126,
          organicRank: 78,

          keywordSales: 73
        },
        {
          date: '2024-06-06',
          searchVolume: 4126,
          organicRank: 77,

          keywordSales: 73
        },
        {
          date: '2024-06-05',
          searchVolume: 4126,
          organicRank: 42,
          sponsoredRank: 3,
          keywordSales: 73
        },
        {
          date: '2024-06-04',
          searchVolume: 4126,
          organicRank: 46,

          keywordSales: 73
        },
        {
          date: '2024-06-03',
          searchVolume: 4126,
          organicRank: 75,

          keywordSales: 73
        },
        {
          date: '2024-06-02',
          searchVolume: 4126,
          organicRank: 46,

          keywordSales: 73
        },
        {
          date: '2024-06-01',
          searchVolume: 4126,
          organicRank: 81,

          keywordSales: 73
        },
        {
          date: '2024-05-31',
          searchVolume: 3575,
          organicRank: 72,
          sponsoredRank: 56,
          keywordSales: 73
        },
        {
          date: '2024-05-30',
          searchVolume: 3575,
          organicRank: 69,

          keywordSales: 73
        },
        {
          date: '2024-05-29',
          searchVolume: 3575,
          organicRank: 96,

          keywordSales: 73
        },
        {
          date: '2024-05-28',
          searchVolume: 3575,
          organicRank: 100,

          keywordSales: 73
        },
        {
          date: '2024-05-27',
          searchVolume: 3575,
          organicRank: 104,

          keywordSales: 73
        },
        {
          date: '2024-05-26',
          searchVolume: 3575,
          organicRank: 108,
          sponsoredRank: 12,
          keywordSales: 73
        },
        {
          date: '2024-05-25',
          searchVolume: 3575,
          organicRank: 111,

          keywordSales: 73
        },
        {
          date: '2024-05-24',
          searchVolume: 3575,
          organicRank: 92,

          keywordSales: 73
        },
        {
          date: '2024-05-23',
          searchVolume: 3575,
          organicRank: 95,
          sponsoredRank: 37,
          keywordSales: 73
        },
        {
          date: '2024-05-22',
          searchVolume: 3575,
          organicRank: 99,
          sponsoredRank: 24,
          keywordSales: 73
        },
        {
          date: '2024-05-21',
          searchVolume: 3575,
          organicRank: 93,
          sponsoredRank: 18,
          keywordSales: 73
        },
        {
          date: '2024-05-20',
          searchVolume: 3575,
          organicRank: 93,

          keywordSales: 73
        },
        {
          date: '2024-05-19',
          searchVolume: 3575,
          organicRank: 102,

          keywordSales: 73
        },
        {
          date: '2024-05-18',
          searchVolume: 3575,
          organicRank: 92,
          sponsoredRank: 63,
          keywordSales: 73
        },
        {
          date: '2024-05-17',
          searchVolume: 4249,
          organicRank: 96,
          sponsoredRank: 31,
          keywordSales: 73
        },
        {
          date: '2024-05-16',
          searchVolume: 4249,
          organicRank: 96,

          keywordSales: 73
        },
        {
          date: '2024-05-15',
          searchVolume: 4249,
          organicRank: 96,

          keywordSales: 73
        },
        {
          date: '2024-05-14',
          searchVolume: 4249,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-13',
          searchVolume: 4249,
          organicRank: 99,

          keywordSales: 73
        },
        {
          date: '2024-05-12',
          searchVolume: 4249,
          organicRank: 85,
          sponsoredRank: 25,
          keywordSales: 73
        },
        {
          date: '2024-05-11',
          searchVolume: 4249,
          organicRank: 95,
          sponsoredRank: 6,
          keywordSales: 73
        },
        {
          date: '2024-05-10',
          searchVolume: 3648,
          organicRank: 80,

          keywordSales: 73
        },
        {
          date: '2024-05-09',
          searchVolume: 3648,
          organicRank: 107,

          keywordSales: 73
        },
        {
          date: '2024-05-08',
          searchVolume: 3648,
          organicRank: 91,

          keywordSales: 73
        },
        {
          date: '2024-05-07',
          searchVolume: 3648,
          organicRank: 100,

          keywordSales: 73
        },
        {
          date: '2024-05-06',
          searchVolume: 3648,
          organicRank: 100,

          keywordSales: 73
        },
        {
          date: '2024-05-05',
          searchVolume: 3648,
          organicRank: 43,

          keywordSales: 73
        },
        {
          date: '2024-05-04',
          searchVolume: 3648,
          organicRank: 75,
          sponsoredRank: 30,
          keywordSales: 73
        },
        {
          date: '2024-05-03',
          searchVolume: 4124,
          organicRank: 52,
          sponsoredRank: 36,
          keywordSales: 73
        },
        {
          date: '2024-05-02',
          searchVolume: 4124,
          organicRank: 90,

          keywordSales: 73
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_alienware keyboard',
      keyword: 'alienware keyboard',
      searchVolume: 3078,
      organicStartRank: 91,
      organicHighestRank: 77,
      sponsoredStartRank: 46,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 103,

          keywordSales: 50
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 100,

          keywordSales: 50
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 100,

          keywordSales: 50
        },
        {
          date: '2024-06-17',
          searchVolume: 2550,
          organicRank: 94,

          keywordSales: 50
        },
        {
          date: '2024-06-16',
          searchVolume: 2550,
          organicRank: 78,

          keywordSales: 50
        },
        {
          date: '2024-06-15',
          searchVolume: 2550,
          organicRank: 91,
          sponsoredRank: 10,
          keywordSales: 50
        },
        {
          date: '2024-06-14',
          searchVolume: 2550,
          organicRank: 95,

          keywordSales: 50
        },
        {
          date: '2024-06-13',
          searchVolume: 2550,
          organicRank: 100,

          keywordSales: 50
        },
        {
          date: '2024-06-12',
          searchVolume: 2550,
          organicRank: 86,

          keywordSales: 50
        },
        {
          date: '2024-06-11',
          searchVolume: 2550,
          organicRank: 81,

          keywordSales: 50
        },
        {
          date: '2024-06-10',
          searchVolume: 2550,
          organicRank: 86,

          keywordSales: 50
        },
        {
          date: '2024-06-09',
          searchVolume: 2550,
          organicRank: 92,

          keywordSales: 50
        },
        {
          date: '2024-06-08',
          searchVolume: 2550,
          organicRank: 80,

          keywordSales: 50
        },
        {
          date: '2024-06-07',
          searchVolume: 2434,
          organicRank: 77,

          keywordSales: 50
        },
        {
          date: '2024-06-06',
          searchVolume: 2434,
          organicRank: 89,

          keywordSales: 50
        },
        {
          date: '2024-06-05',
          searchVolume: 2434,
          organicRank: 82,
          sponsoredRank: 5,
          keywordSales: 50
        },
        {
          date: '2024-06-04',
          searchVolume: 2434,
          organicRank: 91,
          sponsoredRank: 10,
          keywordSales: 50
        },
        {
          date: '2024-06-03',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-02',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-01',
          searchVolume: 2434,
          organicRank: 306,
          sponsoredRank: 35,
          keywordSales: 50
        },
        {
          date: '2024-05-31',
          searchVolume: 3078,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 50
        },
        {
          date: '2024-05-30',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-29',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-28',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-27',
          searchVolume: 3078,
          organicRank: 115,

          keywordSales: 50
        },
        {
          date: '2024-05-26',
          searchVolume: 3078,
          organicRank: 106,
          sponsoredRank: 10,
          keywordSales: 50
        },
        {
          date: '2024-05-25',
          searchVolume: 3078,
          organicRank: 103,

          keywordSales: 50
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 99,

          keywordSales: 50
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 99,

          keywordSales: 50
        },
        {
          date: '2024-05-22',
          searchVolume: 3078,
          organicRank: 108,
          sponsoredRank: 35,
          keywordSales: 50
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 123,

          keywordSales: 50
        },
        {
          date: '2024-05-20',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-19',
          searchVolume: 3078,
          organicRank: 105,

          keywordSales: 50
        },
        {
          date: '2024-05-18',
          searchVolume: 3078,
          organicRank: 129,
          sponsoredRank: 8,
          keywordSales: 50
        },
        {
          date: '2024-05-17',
          searchVolume: 2505,
          organicRank: 119,
          sponsoredRank: 16,
          keywordSales: 50
        },
        {
          date: '2024-05-16',
          searchVolume: 2505,
          organicRank: 306,
          sponsoredRank: 51,
          keywordSales: 50
        },
        {
          date: '2024-05-15',
          searchVolume: 2505,
          organicRank: 115,

          keywordSales: 50
        },
        {
          date: '2024-05-14',
          searchVolume: 2505,
          organicRank: 106,
          sponsoredRank: 6,
          keywordSales: 50
        },
        {
          date: '2024-05-13',
          searchVolume: 2505,
          organicRank: 102,
          sponsoredRank: 8,
          keywordSales: 50
        },
        {
          date: '2024-05-12',
          searchVolume: 2505,
          organicRank: 100,
          sponsoredRank: 13,
          keywordSales: 50
        },
        {
          date: '2024-05-11',
          searchVolume: 2505,
          organicRank: 100,

          keywordSales: 50
        },
        {
          date: '2024-05-10',
          searchVolume: 3126,
          organicRank: 92,

          keywordSales: 50
        },
        {
          date: '2024-05-09',
          searchVolume: 3126,
          organicRank: 103,

          keywordSales: 50
        },
        {
          date: '2024-05-08',
          searchVolume: 3126,
          organicRank: 102,
          sponsoredRank: 46,
          keywordSales: 50
        },
        {
          date: '2024-05-07',
          searchVolume: 3126,
          organicRank: 102,

          keywordSales: 50
        },
        {
          date: '2024-05-06',
          searchVolume: 3126,
          organicRank: 88,

          keywordSales: 50
        },
        {
          date: '2024-05-03',
          searchVolume: 2433,
          organicRank: 91,

          keywordSales: 50
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_aluminum keyboard',
      keyword: 'aluminum keyboard',
      searchVolume: 2217,
      organicStartRank: 66,
      organicHighestRank: 43,
      sponsoredStartRank: 41,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-10',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2428,
          organicRank: 116,
          sponsoredRank: 41,
          keywordSales: 6
        },
        {
          date: '2024-06-19',
          searchVolume: 2428,
          organicRank: 118,
          sponsoredRank: 53,
          keywordSales: 6
        },
        {
          date: '2024-06-18',
          searchVolume: 2428,
          organicRank: 117,
          sponsoredRank: 30,
          keywordSales: 6
        },
        {
          date: '2024-06-17',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-16',
          searchVolume: 2428,
          organicRank: 109,
          sponsoredRank: 76,
          keywordSales: 6
        },
        {
          date: '2024-06-15',
          searchVolume: 2428,
          organicRank: 95,

          keywordSales: 6
        },
        {
          date: '2024-06-14',
          searchVolume: 2430,
          organicRank: 129,
          sponsoredRank: 30,
          keywordSales: 6
        },
        {
          date: '2024-06-13',
          searchVolume: 2430,
          organicRank: 112,

          keywordSales: 6
        },
        {
          date: '2024-06-12',
          searchVolume: 2430,
          organicRank: 116,

          keywordSales: 6
        },
        {
          date: '2024-06-11',
          searchVolume: 2430,
          organicRank: 110,

          keywordSales: 6
        },
        {
          date: '2024-06-10',
          searchVolume: 2430,
          organicRank: 113,

          keywordSales: 6
        },
        {
          date: '2024-06-09',
          searchVolume: 2430,
          organicRank: 78,
          sponsoredRank: 59,
          keywordSales: 6
        },
        {
          date: '2024-06-08',
          searchVolume: 2430,
          organicRank: 76,
          sponsoredRank: 4,
          keywordSales: 6
        },
        {
          date: '2024-06-07',
          searchVolume: 2073,
          organicRank: 63,

          keywordSales: 6
        },
        {
          date: '2024-06-06',
          searchVolume: 2073,
          organicRank: 86,

          keywordSales: 6
        },
        {
          date: '2024-06-05',
          searchVolume: 2073,
          organicRank: 52,

          keywordSales: 6
        },
        {
          date: '2024-06-04',
          searchVolume: 2073,
          organicRank: 50,

          keywordSales: 6
        },
        {
          date: '2024-06-03',
          searchVolume: 2073,
          organicRank: 81,

          keywordSales: 6
        },
        {
          date: '2024-06-02',
          searchVolume: 2073,
          organicRank: 51,

          keywordSales: 6
        },
        {
          date: '2024-06-01',
          searchVolume: 2073,
          organicRank: 78,

          keywordSales: 6
        },
        {
          date: '2024-05-31',
          searchVolume: 2358,
          organicRank: 52,
          sponsoredRank: 48,
          keywordSales: 6
        },
        {
          date: '2024-05-30',
          searchVolume: 2358,
          organicRank: 81,

          keywordSales: 6
        },
        {
          date: '2024-05-29',
          searchVolume: 2358,
          organicRank: 83,
          sponsoredRank: 86,
          keywordSales: 6
        },
        {
          date: '2024-05-28',
          searchVolume: 2358,
          organicRank: 84,

          keywordSales: 6
        },
        {
          date: '2024-05-27',
          searchVolume: 2358,
          organicRank: 102,

          keywordSales: 6
        },
        {
          date: '2024-05-26',
          searchVolume: 2358,
          organicRank: 98,

          keywordSales: 6
        },
        {
          date: '2024-05-25',
          searchVolume: 2358,
          organicRank: 87,
          sponsoredRank: 41,
          keywordSales: 6
        },
        {
          date: '2024-05-24',
          searchVolume: 2217,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 6
        },
        {
          date: '2024-05-23',
          searchVolume: 2217,
          organicRank: 146,

          keywordSales: 6
        },
        {
          date: '2024-05-22',
          searchVolume: 2217,
          organicRank: 149,

          keywordSales: 6
        },
        {
          date: '2024-05-21',
          searchVolume: 2217,
          organicRank: 134,
          sponsoredRank: 30,
          keywordSales: 6
        },
        {
          date: '2024-05-20',
          searchVolume: 2217,
          organicRank: 107,

          keywordSales: 6
        },
        {
          date: '2024-05-19',
          searchVolume: 2217,
          organicRank: 121,

          keywordSales: 6
        },
        {
          date: '2024-05-18',
          searchVolume: 2217,
          organicRank: 109,
          sponsoredRank: 58,
          keywordSales: 6
        },
        {
          date: '2024-05-17',
          searchVolume: 1892,
          organicRank: 100,
          sponsoredRank: 6,
          keywordSales: 6
        },
        {
          date: '2024-05-16',
          searchVolume: 1892,
          organicRank: 102,

          keywordSales: 6
        },
        {
          date: '2024-05-15',
          searchVolume: 1892,
          organicRank: 119,

          keywordSales: 6
        },
        {
          date: '2024-05-14',
          searchVolume: 1892,
          organicRank: 135,
          sponsoredRank: 48,
          keywordSales: 6
        },
        {
          date: '2024-05-13',
          searchVolume: 1892,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 6
        },
        {
          date: '2024-05-12',
          searchVolume: 1892,
          organicRank: 112,
          sponsoredRank: 61,
          keywordSales: 6
        },
        {
          date: '2024-05-11',
          searchVolume: 1892,
          organicRank: 119,

          keywordSales: 6
        },
        {
          date: '2024-05-10',
          searchVolume: 2171,
          organicRank: 121,
          sponsoredRank: 12,
          keywordSales: 6
        },
        {
          date: '2024-05-09',
          searchVolume: 2171,
          organicRank: 114,

          keywordSales: 6
        },
        {
          date: '2024-05-08',
          searchVolume: 2171,
          organicRank: 95,
          sponsoredRank: 60,
          keywordSales: 6
        },
        {
          date: '2024-05-07',
          searchVolume: 2171,
          organicRank: 43,

          keywordSales: 6
        },
        {
          date: '2024-05-06',
          searchVolume: 2171,
          organicRank: 45,
          sponsoredRank: 34,
          keywordSales: 6
        },
        {
          date: '2024-05-05',
          searchVolume: 2171,
          organicRank: 58,
          sponsoredRank: 61,
          keywordSales: 6
        },
        {
          date: '2024-05-04',
          searchVolume: 2171,
          organicRank: 66,
          sponsoredRank: 41,
          keywordSales: 6
        },
        {
          date: '2024-05-03',
          searchVolume: 2286,
          organicRank: 117,
          sponsoredRank: 40,
          keywordSales: 6
        },
        {
          date: '2024-05-02',
          searchVolume: 2286,
          organicRank: 112,

          keywordSales: 6
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_asus keyboard',
      keyword: 'asus keyboard',
      searchVolume: 3568,
      organicStartRank: 84,
      organicHighestRank: 64,
      sponsoredStartRank: 76,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 3438,
          organicRank: 69,

          keywordSales: 76
        },
        {
          date: '2024-06-19',
          searchVolume: 3438,
          organicRank: 96,
          sponsoredRank: 23,
          keywordSales: 76
        },
        {
          date: '2024-06-18',
          searchVolume: 3438,
          organicRank: 86,
          sponsoredRank: 53,
          keywordSales: 76
        },
        {
          date: '2024-06-17',
          searchVolume: 3438,
          organicRank: 104,

          keywordSales: 76
        },
        {
          date: '2024-06-16',
          searchVolume: 3438,
          organicRank: 88,

          keywordSales: 76
        },
        {
          date: '2024-06-15',
          searchVolume: 3438,
          organicRank: 88,
          sponsoredRank: 6,
          keywordSales: 76
        },
        {
          date: '2024-06-14',
          searchVolume: 3620,
          organicRank: 74,
          sponsoredRank: 18,
          keywordSales: 76
        },
        {
          date: '2024-06-13',
          searchVolume: 3620,
          organicRank: 68,
          sponsoredRank: 83,
          keywordSales: 76
        },
        {
          date: '2024-06-12',
          searchVolume: 3620,
          organicRank: 70,

          keywordSales: 76
        },
        {
          date: '2024-06-11',
          searchVolume: 3620,
          organicRank: 81,

          keywordSales: 76
        },
        {
          date: '2024-06-10',
          searchVolume: 3620,
          organicRank: 77,
          sponsoredRank: 91,
          keywordSales: 76
        },
        {
          date: '2024-06-09',
          searchVolume: 3620,
          organicRank: 94,
          sponsoredRank: 84,
          keywordSales: 76
        },
        {
          date: '2024-06-08',
          searchVolume: 3620,
          organicRank: 84,

          keywordSales: 76
        },
        {
          date: '2024-06-07',
          searchVolume: 3451,
          organicRank: 94,

          keywordSales: 76
        },
        {
          date: '2024-06-06',
          searchVolume: 3451,
          organicRank: 83,

          keywordSales: 76
        },
        {
          date: '2024-06-05',
          searchVolume: 3451,
          organicRank: 84,
          sponsoredRank: 8,
          keywordSales: 76
        },
        {
          date: '2024-06-04',
          searchVolume: 3451,
          organicRank: 87,
          sponsoredRank: 4,
          keywordSales: 76
        },
        {
          date: '2024-06-03',
          searchVolume: 3451,
          organicRank: 81,
          sponsoredRank: 4,
          keywordSales: 76
        },
        {
          date: '2024-06-02',
          searchVolume: 3451,
          organicRank: 79,

          keywordSales: 76
        },
        {
          date: '2024-06-01',
          searchVolume: 3451,
          organicRank: 74,

          keywordSales: 76
        },
        {
          date: '2024-05-31',
          searchVolume: 3089,
          organicRank: 69,
          sponsoredRank: 14,
          keywordSales: 76
        },
        {
          date: '2024-05-30',
          searchVolume: 3089,
          organicRank: 83,

          keywordSales: 76
        },
        {
          date: '2024-05-29',
          searchVolume: 3089,
          organicRank: 76,

          keywordSales: 76
        },
        {
          date: '2024-05-28',
          searchVolume: 3089,
          organicRank: 104,

          keywordSales: 76
        },
        {
          date: '2024-05-27',
          searchVolume: 3089,
          organicRank: 72,
          sponsoredRank: 73,
          keywordSales: 76
        },
        {
          date: '2024-05-26',
          searchVolume: 3089,
          organicRank: 78,
          sponsoredRank: 14,
          keywordSales: 76
        },
        {
          date: '2024-05-25',
          searchVolume: 3089,
          organicRank: 77,

          keywordSales: 76
        },
        {
          date: '2024-05-24',
          searchVolume: 3568,
          organicRank: 64,
          sponsoredRank: 21,
          keywordSales: 76
        },
        {
          date: '2024-05-23',
          searchVolume: 3568,
          organicRank: 75,
          sponsoredRank: 20,
          keywordSales: 76
        },
        {
          date: '2024-05-22',
          searchVolume: 3568,
          organicRank: 75,
          sponsoredRank: 83,
          keywordSales: 76
        },
        {
          date: '2024-05-21',
          searchVolume: 3568,
          organicRank: 75,
          sponsoredRank: 3,
          keywordSales: 76
        },
        {
          date: '2024-05-20',
          searchVolume: 3568,
          organicRank: 72,

          keywordSales: 76
        },
        {
          date: '2024-05-19',
          searchVolume: 3568,
          organicRank: 73,
          sponsoredRank: 11,
          keywordSales: 76
        },
        {
          date: '2024-05-18',
          searchVolume: 3568,
          organicRank: 88,
          sponsoredRank: 25,
          keywordSales: 76
        },
        {
          date: '2024-05-17',
          searchVolume: 3570,
          organicRank: 72,
          sponsoredRank: 80,
          keywordSales: 76
        },
        {
          date: '2024-05-16',
          searchVolume: 3570,
          organicRank: 67,
          sponsoredRank: 14,
          keywordSales: 76
        },
        {
          date: '2024-05-15',
          searchVolume: 3570,
          organicRank: 78,

          keywordSales: 76
        },
        {
          date: '2024-05-14',
          searchVolume: 3570,
          organicRank: 88,

          keywordSales: 76
        },
        {
          date: '2024-05-13',
          searchVolume: 3570,
          organicRank: 84,
          sponsoredRank: 41,
          keywordSales: 76
        },
        {
          date: '2024-05-12',
          searchVolume: 3570,
          organicRank: 90,
          sponsoredRank: 17,
          keywordSales: 76
        },
        {
          date: '2024-05-11',
          searchVolume: 3570,
          organicRank: 116,
          sponsoredRank: 18,
          keywordSales: 76
        },
        {
          date: '2024-05-10',
          searchVolume: 4337,
          organicRank: 86,

          keywordSales: 76
        },
        {
          date: '2024-05-09',
          searchVolume: 4337,
          organicRank: 80,
          sponsoredRank: 5,
          keywordSales: 76
        },
        {
          date: '2024-05-08',
          searchVolume: 4337,
          organicRank: 87,

          keywordSales: 76
        },
        {
          date: '2024-05-07',
          searchVolume: 4337,
          organicRank: 88,

          keywordSales: 76
        },
        {
          date: '2024-05-06',
          searchVolume: 4337,
          organicRank: 84,
          sponsoredRank: 76,
          keywordSales: 76
        },
        {
          date: '2024-05-03',
          searchVolume: 4123,
          organicRank: 84,

          keywordSales: 76
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_asus rog keyboard',
      keyword: 'asus rog keyboard',
      searchVolume: 1421,
      organicStartRank: 59,
      organicHighestRank: 45,
      sponsoredStartRank: 20,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-06',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1657,
          organicRank: 64,

          keywordSales: 17
        },
        {
          date: '2024-06-19',
          searchVolume: 1657,
          organicRank: 62,
          sponsoredRank: 48,
          keywordSales: 17
        },
        {
          date: '2024-06-18',
          searchVolume: 1657,
          organicRank: 70,
          sponsoredRank: 28,
          keywordSales: 17
        },
        {
          date: '2024-06-17',
          searchVolume: 1657,
          organicRank: 67,
          sponsoredRank: 14,
          keywordSales: 17
        },
        {
          date: '2024-06-16',
          searchVolume: 1657,
          organicRank: 60,
          sponsoredRank: 38,
          keywordSales: 17
        },
        {
          date: '2024-06-15',
          searchVolume: 1657,
          organicRank: 60,
          sponsoredRank: 12,
          keywordSales: 17
        },
        {
          date: '2024-06-14',
          searchVolume: 1657,
          organicRank: 65,
          sponsoredRank: 66,
          keywordSales: 17
        },
        {
          date: '2024-06-13',
          searchVolume: 1657,
          organicRank: 62,
          sponsoredRank: 34,
          keywordSales: 17
        },
        {
          date: '2024-06-12',
          searchVolume: 1657,
          organicRank: 64,

          keywordSales: 17
        },
        {
          date: '2024-06-11',
          searchVolume: 1657,
          organicRank: 73,

          keywordSales: 17
        },
        {
          date: '2024-06-10',
          searchVolume: 1657,
          organicRank: 55,

          keywordSales: 17
        },
        {
          date: '2024-06-09',
          searchVolume: 1657,
          organicRank: 57,
          sponsoredRank: 5,
          keywordSales: 17
        },
        {
          date: '2024-06-08',
          searchVolume: 1657,
          organicRank: 59,

          keywordSales: 17
        },
        {
          date: '2024-06-07',
          searchVolume: 1533,
          organicRank: 67,

          keywordSales: 17
        },
        {
          date: '2024-06-06',
          searchVolume: 1533,
          organicRank: 60,

          keywordSales: 17
        },
        {
          date: '2024-06-05',
          searchVolume: 1533,
          organicRank: 57,
          sponsoredRank: 13,
          keywordSales: 17
        },
        {
          date: '2024-06-04',
          searchVolume: 1533,
          organicRank: 58,
          sponsoredRank: 34,
          keywordSales: 17
        },
        {
          date: '2024-06-03',
          searchVolume: 1533,
          organicRank: 59,

          keywordSales: 17
        },
        {
          date: '2024-06-02',
          searchVolume: 1533,
          organicRank: 51,

          keywordSales: 17
        },
        {
          date: '2024-06-01',
          searchVolume: 1533,
          organicRank: 57,
          sponsoredRank: 80,
          keywordSales: 17
        },
        {
          date: '2024-05-31',
          searchVolume: 1410,
          organicRank: 51,
          sponsoredRank: 3,
          keywordSales: 17
        },
        {
          date: '2024-05-30',
          searchVolume: 1410,
          organicRank: 55,

          keywordSales: 17
        },
        {
          date: '2024-05-29',
          searchVolume: 1410,
          organicRank: 59,
          sponsoredRank: 60,
          keywordSales: 17
        },
        {
          date: '2024-05-28',
          searchVolume: 1410,
          organicRank: 73,
          sponsoredRank: 56,
          keywordSales: 17
        },
        {
          date: '2024-05-27',
          searchVolume: 1410,
          organicRank: 57,
          sponsoredRank: 64,
          keywordSales: 17
        },
        {
          date: '2024-05-26',
          searchVolume: 1410,
          organicRank: 57,
          sponsoredRank: 12,
          keywordSales: 17
        },
        {
          date: '2024-05-25',
          searchVolume: 1410,
          organicRank: 68,

          keywordSales: 17
        },
        {
          date: '2024-05-24',
          searchVolume: 1421,
          organicRank: 45,
          sponsoredRank: 9,
          keywordSales: 17
        },
        {
          date: '2024-05-23',
          searchVolume: 1421,
          organicRank: 50,
          sponsoredRank: 40,
          keywordSales: 17
        },
        {
          date: '2024-05-22',
          searchVolume: 1421,
          organicRank: 62,

          keywordSales: 17
        },
        {
          date: '2024-05-21',
          searchVolume: 1421,
          organicRank: 48,

          keywordSales: 17
        },
        {
          date: '2024-05-20',
          searchVolume: 1421,
          organicRank: 54,
          sponsoredRank: 5,
          keywordSales: 17
        },
        {
          date: '2024-05-19',
          searchVolume: 1421,
          organicRank: 56,

          keywordSales: 17
        },
        {
          date: '2024-05-18',
          searchVolume: 1421,
          organicRank: 54,
          sponsoredRank: 31,
          keywordSales: 17
        },
        {
          date: '2024-05-17',
          searchVolume: 1885,
          organicRank: 53,
          sponsoredRank: 26,
          keywordSales: 17
        },
        {
          date: '2024-05-16',
          searchVolume: 1885,
          organicRank: 53,

          keywordSales: 17
        },
        {
          date: '2024-05-15',
          searchVolume: 1885,
          organicRank: 56,
          sponsoredRank: 60,
          keywordSales: 17
        },
        {
          date: '2024-05-14',
          searchVolume: 1885,
          organicRank: 63,
          sponsoredRank: 4,
          keywordSales: 17
        },
        {
          date: '2024-05-13',
          searchVolume: 1885,
          organicRank: 63,
          sponsoredRank: 57,
          keywordSales: 17
        },
        {
          date: '2024-05-12',
          searchVolume: 1885,
          organicRank: 64,
          sponsoredRank: 13,
          keywordSales: 17
        },
        {
          date: '2024-05-11',
          searchVolume: 1885,
          organicRank: 57,
          sponsoredRank: 55,
          keywordSales: 17
        },
        {
          date: '2024-05-10',
          searchVolume: 1917,
          organicRank: 58,

          keywordSales: 17
        },
        {
          date: '2024-05-09',
          searchVolume: 1917,
          organicRank: 64,

          keywordSales: 17
        },
        {
          date: '2024-05-08',
          searchVolume: 1917,
          organicRank: 54,
          sponsoredRank: 43,
          keywordSales: 17
        },
        {
          date: '2024-05-07',
          searchVolume: 1917,
          organicRank: 59,

          keywordSales: 17
        },
        {
          date: '2024-05-06',
          searchVolume: 1917,
          organicRank: 58,
          sponsoredRank: 20,
          keywordSales: 17
        },
        {
          date: '2024-05-03',
          searchVolume: 1830,
          organicRank: 59,

          keywordSales: 17
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_aula keyboard',
      keyword: 'aula keyboard',
      searchVolume: 2358,
      organicStartRank: 147,
      organicHighestRank: 97,
      sponsoredStartRank: 10,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2552,
          organicRank: 156,

          keywordSales: 7
        },
        {
          date: '2024-06-19',
          searchVolume: 2552,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 7
        },
        {
          date: '2024-06-18',
          searchVolume: 2552,
          organicRank: 150,
          sponsoredRank: 91,
          keywordSales: 7
        },
        {
          date: '2024-06-17',
          searchVolume: 2552,
          organicRank: 136,
          sponsoredRank: 36,
          keywordSales: 7
        },
        {
          date: '2024-06-16',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-15',
          searchVolume: 2552,
          organicRank: 149,

          keywordSales: 7
        },
        {
          date: '2024-06-14',
          searchVolume: 2550,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 7
        },
        {
          date: '2024-06-13',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-12',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-11',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-10',
          searchVolume: 2550,
          organicRank: 153,

          keywordSales: 7
        },
        {
          date: '2024-06-09',
          searchVolume: 2550,
          organicRank: 130,

          keywordSales: 7
        },
        {
          date: '2024-06-08',
          searchVolume: 2550,
          organicRank: 148,

          keywordSales: 7
        },
        {
          date: '2024-06-07',
          searchVolume: 2147,
          organicRank: 142,

          keywordSales: 7
        },
        {
          date: '2024-06-06',
          searchVolume: 2147,
          organicRank: 146,

          keywordSales: 7
        },
        {
          date: '2024-06-05',
          searchVolume: 2147,
          organicRank: 149,
          sponsoredRank: 32,
          keywordSales: 7
        },
        {
          date: '2024-06-04',
          searchVolume: 2147,
          organicRank: 129,

          keywordSales: 7
        },
        {
          date: '2024-06-03',
          searchVolume: 2147,
          organicRank: 147,

          keywordSales: 7
        },
        {
          date: '2024-06-02',
          searchVolume: 2147,
          organicRank: 145,

          keywordSales: 7
        },
        {
          date: '2024-06-01',
          searchVolume: 2147,
          organicRank: 134,

          keywordSales: 7
        },
        {
          date: '2024-05-31',
          searchVolume: 1899,
          organicRank: 149,
          sponsoredRank: 27,
          keywordSales: 7
        },
        {
          date: '2024-05-30',
          searchVolume: 1899,
          organicRank: 141,

          keywordSales: 7
        },
        {
          date: '2024-05-29',
          searchVolume: 1899,
          organicRank: 129,

          keywordSales: 7
        },
        {
          date: '2024-05-28',
          searchVolume: 1899,
          organicRank: 157,

          keywordSales: 7
        },
        {
          date: '2024-05-27',
          searchVolume: 1899,
          organicRank: 159,

          keywordSales: 7
        },
        {
          date: '2024-05-26',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-05-25',
          searchVolume: 1899,
          organicRank: 124,

          keywordSales: 7
        },
        {
          date: '2024-05-24',
          searchVolume: 2358,
          organicRank: 113,

          keywordSales: 7
        },
        {
          date: '2024-05-23',
          searchVolume: 2358,
          organicRank: 101,

          keywordSales: 7
        },
        {
          date: '2024-05-22',
          searchVolume: 2358,
          organicRank: 116,

          keywordSales: 7
        },
        {
          date: '2024-05-21',
          searchVolume: 2358,
          organicRank: 113,

          keywordSales: 7
        },
        {
          date: '2024-05-20',
          searchVolume: 2358,
          organicRank: 113,
          sponsoredRank: 34,
          keywordSales: 7
        },
        {
          date: '2024-05-19',
          searchVolume: 2358,
          organicRank: 98,

          keywordSales: 7
        },
        {
          date: '2024-05-18',
          searchVolume: 2358,
          organicRank: 97,
          sponsoredRank: 62,
          keywordSales: 7
        },
        {
          date: '2024-05-17',
          searchVolume: 2314,
          organicRank: 106,
          sponsoredRank: 10,
          keywordSales: 7
        },
        {
          date: '2024-05-16',
          searchVolume: 2314,
          organicRank: 117,

          keywordSales: 7
        },
        {
          date: '2024-05-15',
          searchVolume: 2314,
          organicRank: 123,

          keywordSales: 7
        },
        {
          date: '2024-05-14',
          searchVolume: 2314,
          organicRank: 131,

          keywordSales: 7
        },
        {
          date: '2024-05-13',
          searchVolume: 2314,
          organicRank: 128,

          keywordSales: 7
        },
        {
          date: '2024-05-12',
          searchVolume: 2314,
          organicRank: 119,

          keywordSales: 7
        },
        {
          date: '2024-05-11',
          searchVolume: 2314,
          organicRank: 126,

          keywordSales: 7
        },
        {
          date: '2024-05-10',
          searchVolume: 2171,
          organicRank: 129,

          keywordSales: 7
        },
        {
          date: '2024-05-09',
          searchVolume: 2171,
          organicRank: 119,

          keywordSales: 7
        },
        {
          date: '2024-05-08',
          searchVolume: 2171,
          organicRank: 157,

          keywordSales: 7
        },
        {
          date: '2024-05-07',
          searchVolume: 2171,
          organicRank: 139,

          keywordSales: 7
        },
        {
          date: '2024-05-06',
          searchVolume: 2171,
          organicRank: 153,

          keywordSales: 7
        },
        {
          date: '2024-05-03',
          searchVolume: 2243,
          organicRank: 147,

          keywordSales: 7
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_backlit keyboard',
      keyword: 'backlit keyboard',
      searchVolume: 3575,
      organicStartRank: 94,
      organicHighestRank: 46,
      sponsoredStartRank: 81,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 3817,
          organicRank: 242,
          sponsoredRank: 65,
          keywordSales: 87
        },
        {
          date: '2024-06-20',
          searchVolume: 3817,
          organicRank: 109,
          sponsoredRank: 25,
          keywordSales: 87
        },
        {
          date: '2024-06-19',
          searchVolume: 3817,
          organicRank: 86,
          sponsoredRank: 64,
          keywordSales: 87
        },
        {
          date: '2024-06-18',
          searchVolume: 3817,
          organicRank: 306,
          sponsoredRank: 58,
          keywordSales: 87
        },
        {
          date: '2024-06-17',
          searchVolume: 3817,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-16',
          searchVolume: 3817,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-15',
          searchVolume: 3817,
          organicRank: 93,
          sponsoredRank: 18,
          keywordSales: 87
        },
        {
          date: '2024-06-14',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-13',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-12',
          searchVolume: 3620,
          organicRank: 93,

          keywordSales: 87
        },
        {
          date: '2024-06-11',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-10',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-09',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-08',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-07',
          searchVolume: 3453,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-06',
          searchVolume: 3453,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-05',
          searchVolume: 3453,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-06-04',
          searchVolume: 3453,
          organicRank: 77,

          keywordSales: 87
        },
        {
          date: '2024-06-03',
          searchVolume: 3453,
          organicRank: 266,

          keywordSales: 87
        },
        {
          date: '2024-06-02',
          searchVolume: 3453,
          organicRank: 241,

          keywordSales: 87
        },
        {
          date: '2024-06-01',
          searchVolume: 3453,
          organicRank: 277,
          sponsoredRank: 80,
          keywordSales: 87
        },
        {
          date: '2024-05-31',
          searchVolume: 3572,
          organicRank: 306,
          sponsoredRank: 70,
          keywordSales: 87
        },
        {
          date: '2024-05-30',
          searchVolume: 3572,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-05-29',
          searchVolume: 3572,
          organicRank: 256,

          keywordSales: 87
        },
        {
          date: '2024-05-28',
          searchVolume: 3572,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-05-27',
          searchVolume: 3572,
          organicRank: 306,
          sponsoredRank: 51,
          keywordSales: 87
        },
        {
          date: '2024-05-26',
          searchVolume: 3572,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 87
        },
        {
          date: '2024-05-25',
          searchVolume: 3572,
          organicRank: 62,

          keywordSales: 87
        },
        {
          date: '2024-05-24',
          searchVolume: 3575,
          organicRank: 85,

          keywordSales: 87
        },
        {
          date: '2024-05-23',
          searchVolume: 3575,
          organicRank: 70,

          keywordSales: 87
        },
        {
          date: '2024-05-22',
          searchVolume: 3575,
          organicRank: 54,

          keywordSales: 87
        },
        {
          date: '2024-05-21',
          searchVolume: 3575,
          organicRank: 52,

          keywordSales: 87
        },
        {
          date: '2024-05-20',
          searchVolume: 3575,
          organicRank: 53,

          keywordSales: 87
        },
        {
          date: '2024-05-19',
          searchVolume: 3575,
          organicRank: 250,

          keywordSales: 87
        },
        {
          date: '2024-05-18',
          searchVolume: 3575,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 87
        },
        {
          date: '2024-05-17',
          searchVolume: 3559,
          organicRank: 73,
          sponsoredRank: 7,
          keywordSales: 87
        },
        {
          date: '2024-05-16',
          searchVolume: 3559,
          organicRank: 306,
          sponsoredRank: 34,
          keywordSales: 87
        },
        {
          date: '2024-05-15',
          searchVolume: 3559,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 87
        },
        {
          date: '2024-05-14',
          searchVolume: 3559,
          organicRank: 49,
          sponsoredRank: 79,
          keywordSales: 87
        },
        {
          date: '2024-05-13',
          searchVolume: 3559,
          organicRank: 46,
          sponsoredRank: 74,
          keywordSales: 87
        },
        {
          date: '2024-05-12',
          searchVolume: 3559,
          organicRank: 58,
          sponsoredRank: 59,
          keywordSales: 87
        },
        {
          date: '2024-05-11',
          searchVolume: 3559,
          organicRank: 306,
          sponsoredRank: 45,
          keywordSales: 87
        },
        {
          date: '2024-05-10',
          searchVolume: 3629,
          organicRank: 306,
          sponsoredRank: 37,
          keywordSales: 87
        },
        {
          date: '2024-05-09',
          searchVolume: 3629,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 87
        },
        {
          date: '2024-05-08',
          searchVolume: 3629,
          organicRank: 306,
          sponsoredRank: 82,
          keywordSales: 87
        },
        {
          date: '2024-05-07',
          searchVolume: 3629,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 87
        },
        {
          date: '2024-05-06',
          searchVolume: 3629,
          organicRank: 306,
          sponsoredRank: 81,
          keywordSales: 87
        },
        {
          date: '2024-05-05',
          searchVolume: 3629,
          organicRank: 246,

          keywordSales: 87
        },
        {
          date: '2024-05-04',
          searchVolume: 3629,
          organicRank: 94,

          keywordSales: 87
        },
        {
          date: '2024-05-03',
          searchVolume: 3460,
          organicRank: 248,

          keywordSales: 87
        },
        {
          date: '2024-05-02',
          searchVolume: 3460,
          organicRank: 78,

          keywordSales: 87
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_backlit wireless keyboard and mouse',
      keyword: 'backlit wireless keyboard and mouse',
      searchVolume: 1928,
      organicStartRank: 260,
      organicHighestRank: 259,
      sponsoredStartRank: 46,
      sponsoredHighestRank: 20,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-16',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-19',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-18',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-17',
          searchVolume: 2431,
          organicRank: 306,
          sponsoredRank: 54,
          keywordSales: 27
        },
        {
          date: '2024-06-16',
          searchVolume: 2431,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 27
        },
        {
          date: '2024-06-15',
          searchVolume: 2431,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 27
        },
        {
          date: '2024-06-14',
          searchVolume: 1936,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-13',
          searchVolume: 1936,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-12',
          searchVolume: 1936,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-11',
          searchVolume: 1936,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-10',
          searchVolume: 1936,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-09',
          searchVolume: 1936,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-08',
          searchVolume: 1936,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-07',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-06',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-05',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-04',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-03',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-02',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-01',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-31',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-30',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-29',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-28',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-27',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-26',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-25',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-24',
          searchVolume: 1888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-23',
          searchVolume: 1888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-22',
          searchVolume: 1888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-21',
          searchVolume: 1888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-20',
          searchVolume: 1888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-19',
          searchVolume: 1888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-18',
          searchVolume: 1888,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-17',
          searchVolume: 1883,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-16',
          searchVolume: 1883,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-15',
          searchVolume: 1883,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-14',
          searchVolume: 1883,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-13',
          searchVolume: 1883,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-12',
          searchVolume: 1883,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-11',
          searchVolume: 1883,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-10',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-09',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-08',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-07',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-06',
          searchVolume: 1928,
          organicRank: 267,

          keywordSales: 27
        },
        {
          date: '2024-05-05',
          searchVolume: 1928,
          organicRank: 259,

          keywordSales: 27
        },
        {
          date: '2024-05-04',
          searchVolume: 1928,
          organicRank: 260,

          keywordSales: 27
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_black gaming keyboard',
      keyword: 'black gaming keyboard',
      searchVolume: 937,
      organicStartRank: 81,
      organicHighestRank: 47,
      sponsoredStartRank: 27,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1607,
          organicRank: 84,

          keywordSales: 21
        },
        {
          date: '2024-06-20',
          searchVolume: 1607,
          organicRank: 78,

          keywordSales: 21
        },
        {
          date: '2024-06-19',
          searchVolume: 1607,
          organicRank: 73,
          sponsoredRank: 62,
          keywordSales: 21
        },
        {
          date: '2024-06-18',
          searchVolume: 1607,
          organicRank: 72,
          sponsoredRank: 83,
          keywordSales: 21
        },
        {
          date: '2024-06-17',
          searchVolume: 1607,
          organicRank: 117,

          keywordSales: 21
        },
        {
          date: '2024-06-16',
          searchVolume: 1607,
          organicRank: 82,

          keywordSales: 21
        },
        {
          date: '2024-06-15',
          searchVolume: 1607,
          organicRank: 81,
          sponsoredRank: 64,
          keywordSales: 21
        },
        {
          date: '2024-06-14',
          searchVolume: 1432,
          organicRank: 70,

          keywordSales: 21
        },
        {
          date: '2024-06-13',
          searchVolume: 1432,
          organicRank: 72,

          keywordSales: 21
        },
        {
          date: '2024-06-12',
          searchVolume: 1432,
          organicRank: 69,

          keywordSales: 21
        },
        {
          date: '2024-06-11',
          searchVolume: 1432,
          organicRank: 77,

          keywordSales: 21
        },
        {
          date: '2024-06-10',
          searchVolume: 1432,
          organicRank: 94,

          keywordSales: 21
        },
        {
          date: '2024-06-09',
          searchVolume: 1432,
          organicRank: 306,
          sponsoredRank: 87,
          keywordSales: 21
        },
        {
          date: '2024-06-08',
          searchVolume: 1432,
          organicRank: 102,

          keywordSales: 21
        },
        {
          date: '2024-06-07',
          searchVolume: 1166,
          organicRank: 84,

          keywordSales: 21
        },
        {
          date: '2024-06-06',
          searchVolume: 1166,
          organicRank: 85,

          keywordSales: 21
        },
        {
          date: '2024-06-05',
          searchVolume: 1166,
          organicRank: 80,
          sponsoredRank: 18,
          keywordSales: 21
        },
        {
          date: '2024-06-04',
          searchVolume: 1166,
          organicRank: 96,

          keywordSales: 21
        },
        {
          date: '2024-06-03',
          searchVolume: 1166,
          organicRank: 90,

          keywordSales: 21
        },
        {
          date: '2024-06-02',
          searchVolume: 1166,
          organicRank: 97,

          keywordSales: 21
        },
        {
          date: '2024-06-01',
          searchVolume: 1166,
          organicRank: 87,

          keywordSales: 21
        },
        {
          date: '2024-05-31',
          searchVolume: 939,
          organicRank: 90,
          sponsoredRank: 21,
          keywordSales: 21
        },
        {
          date: '2024-05-30',
          searchVolume: 939,
          organicRank: 78,

          keywordSales: 21
        },
        {
          date: '2024-05-29',
          searchVolume: 939,
          organicRank: 81,

          keywordSales: 21
        },
        {
          date: '2024-05-28',
          searchVolume: 939,
          organicRank: 93,

          keywordSales: 21
        },
        {
          date: '2024-05-27',
          searchVolume: 939,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-05-26',
          searchVolume: 939,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 21
        },
        {
          date: '2024-05-25',
          searchVolume: 939,
          organicRank: 96,

          keywordSales: 21
        },
        {
          date: '2024-05-24',
          searchVolume: 937,
          organicRank: 74,

          keywordSales: 21
        },
        {
          date: '2024-05-23',
          searchVolume: 937,
          organicRank: 89,

          keywordSales: 21
        },
        {
          date: '2024-05-22',
          searchVolume: 937,
          organicRank: 94,
          sponsoredRank: 47,
          keywordSales: 21
        },
        {
          date: '2024-05-21',
          searchVolume: 937,
          organicRank: 83,
          sponsoredRank: 64,
          keywordSales: 21
        },
        {
          date: '2024-05-20',
          searchVolume: 937,
          organicRank: 86,

          keywordSales: 21
        },
        {
          date: '2024-05-19',
          searchVolume: 937,
          organicRank: 96,

          keywordSales: 21
        },
        {
          date: '2024-05-18',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-05-17',
          searchVolume: 1328,
          organicRank: 90,
          sponsoredRank: 2,
          keywordSales: 21
        },
        {
          date: '2024-05-16',
          searchVolume: 1328,
          organicRank: 70,

          keywordSales: 21
        },
        {
          date: '2024-05-15',
          searchVolume: 1328,
          organicRank: 84,

          keywordSales: 21
        },
        {
          date: '2024-05-14',
          searchVolume: 1328,
          organicRank: 101,
          sponsoredRank: 20,
          keywordSales: 21
        },
        {
          date: '2024-05-13',
          searchVolume: 1328,
          organicRank: 83,
          sponsoredRank: 87,
          keywordSales: 21
        },
        {
          date: '2024-05-12',
          searchVolume: 1328,
          organicRank: 83,
          sponsoredRank: 41,
          keywordSales: 21
        },
        {
          date: '2024-05-11',
          searchVolume: 1328,
          organicRank: 82,

          keywordSales: 21
        },
        {
          date: '2024-05-10',
          searchVolume: 1430,
          organicRank: 74,

          keywordSales: 21
        },
        {
          date: '2024-05-09',
          searchVolume: 1430,
          organicRank: 92,
          sponsoredRank: 39,
          keywordSales: 21
        },
        {
          date: '2024-05-08',
          searchVolume: 1430,
          organicRank: 75,
          sponsoredRank: 60,
          keywordSales: 21
        },
        {
          date: '2024-05-07',
          searchVolume: 1430,
          organicRank: 74,

          keywordSales: 21
        },
        {
          date: '2024-05-06',
          searchVolume: 1430,
          organicRank: 47,
          sponsoredRank: 75,
          keywordSales: 21
        },
        {
          date: '2024-05-05',
          searchVolume: 1430,
          organicRank: 84,
          sponsoredRank: 49,
          keywordSales: 21
        },
        {
          date: '2024-05-04',
          searchVolume: 1430,
          organicRank: 81,
          sponsoredRank: 27,
          keywordSales: 21
        },
        {
          date: '2024-05-03',
          searchVolume: 1366,
          organicRank: 64,
          sponsoredRank: 64,
          keywordSales: 21
        },
        {
          date: '2024-05-02',
          searchVolume: 1366,
          organicRank: 98,

          keywordSales: 21
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_black keyboard',
      keyword: 'black keyboard',
      searchVolume: 2153,
      organicStartRank: 83,
      organicHighestRank: 83,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2394,
          organicRank: 160,

          keywordSales: 14
        },
        {
          date: '2024-06-19',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 14
        },
        {
          date: '2024-06-18',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 88,
          keywordSales: 14
        },
        {
          date: '2024-06-17',
          searchVolume: 2394,
          organicRank: 239,

          keywordSales: 14
        },
        {
          date: '2024-06-16',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-15',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 14
        },
        {
          date: '2024-06-14',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-13',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-12',
          searchVolume: 2547,
          organicRank: 143,

          keywordSales: 14
        },
        {
          date: '2024-06-11',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-10',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-09',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-08',
          searchVolume: 2547,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 14
        },
        {
          date: '2024-06-07',
          searchVolume: 2279,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-06',
          searchVolume: 2279,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-05',
          searchVolume: 2279,
          organicRank: 306,
          sponsoredRank: 52,
          keywordSales: 14
        },
        {
          date: '2024-06-04',
          searchVolume: 2279,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-03',
          searchVolume: 2279,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-02',
          searchVolume: 2279,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-01',
          searchVolume: 2279,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-31',
          searchVolume: 2217,
          organicRank: 306,
          sponsoredRank: 24,
          keywordSales: 14
        },
        {
          date: '2024-05-30',
          searchVolume: 2217,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-29',
          searchVolume: 2217,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-28',
          searchVolume: 2217,
          organicRank: 241,

          keywordSales: 14
        },
        {
          date: '2024-05-27',
          searchVolume: 2217,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 14
        },
        {
          date: '2024-05-26',
          searchVolume: 2217,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-25',
          searchVolume: 2217,
          organicRank: 306,
          sponsoredRank: 52,
          keywordSales: 14
        },
        {
          date: '2024-05-24',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-23',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-22',
          searchVolume: 2153,
          organicRank: 306,
          sponsoredRank: 18,
          keywordSales: 14
        },
        {
          date: '2024-05-21',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-20',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-19',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-18',
          searchVolume: 2153,
          organicRank: 306,
          sponsoredRank: 58,
          keywordSales: 14
        },
        {
          date: '2024-05-17',
          searchVolume: 2213,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 14
        },
        {
          date: '2024-05-16',
          searchVolume: 2213,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-15',
          searchVolume: 2213,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-14',
          searchVolume: 2213,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-13',
          searchVolume: 2213,
          organicRank: 272,
          sponsoredRank: 22,
          keywordSales: 14
        },
        {
          date: '2024-05-12',
          searchVolume: 2213,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-11',
          searchVolume: 2213,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-10',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-09',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-08',
          searchVolume: 2428,
          organicRank: 159,
          sponsoredRank: 31,
          keywordSales: 14
        },
        {
          date: '2024-05-07',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-06',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-04',
          searchVolume: 2428,
          organicRank: 83,

          keywordSales: 14
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_black keycaps',
      keyword: 'black keycaps',
      searchVolume: 1899,
      organicStartRank: 252,
      organicHighestRank: 171,
      sponsoredStartRank: 43,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-19',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 26
        },
        {
          date: '2024-06-18',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 26
        },
        {
          date: '2024-06-17',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-16',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-15',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 26
        },
        {
          date: '2024-06-14',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-13',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-12',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-11',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-10',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-09',
          searchVolume: 2173,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 26
        },
        {
          date: '2024-06-08',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-07',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-06',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-05',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-04',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-03',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-02',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-01',
          searchVolume: 2085,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 26
        },
        {
          date: '2024-05-31',
          searchVolume: 1897,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 26
        },
        {
          date: '2024-05-30',
          searchVolume: 1897,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-29',
          searchVolume: 1897,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-28',
          searchVolume: 1897,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-27',
          searchVolume: 1897,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-26',
          searchVolume: 1897,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 26
        },
        {
          date: '2024-05-25',
          searchVolume: 1897,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-24',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-23',
          searchVolume: 1899,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 26
        },
        {
          date: '2024-05-22',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-21',
          searchVolume: 1899,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 26
        },
        {
          date: '2024-05-20',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-19',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-18',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-17',
          searchVolume: 2137,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 26
        },
        {
          date: '2024-05-16',
          searchVolume: 2137,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-15',
          searchVolume: 2137,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-14',
          searchVolume: 2137,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-13',
          searchVolume: 2137,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 26
        },
        {
          date: '2024-05-12',
          searchVolume: 2137,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 26
        },
        {
          date: '2024-05-11',
          searchVolume: 2137,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-10',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-09',
          searchVolume: 2352,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 26
        },
        {
          date: '2024-05-08',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-07',
          searchVolume: 2352,
          organicRank: 226,

          keywordSales: 26
        },
        {
          date: '2024-05-06',
          searchVolume: 2352,
          organicRank: 171,

          keywordSales: 26
        },
        {
          date: '2024-05-03',
          searchVolume: 1834,
          organicRank: 252,
          sponsoredRank: 43,
          keywordSales: 26
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_blue keyboard',
      keyword: 'blue keyboard',
      searchVolume: 3094,
      organicStartRank: 167,
      organicHighestRank: 133,
      sponsoredStartRank: 28,
      sponsoredHighestRank: 28,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 3236,
          organicRank: 205,

          keywordSales: 24
        },
        {
          date: '2024-06-20',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-19',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-18',
          searchVolume: 3236,
          organicRank: 242,
          sponsoredRank: 108,
          keywordSales: 24
        },
        {
          date: '2024-06-17',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-16',
          searchVolume: 3236,
          organicRank: 144,

          keywordSales: 24
        },
        {
          date: '2024-06-15',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-14',
          searchVolume: 3432,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-13',
          searchVolume: 3432,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-12',
          searchVolume: 3432,
          organicRank: 204,

          keywordSales: 24
        },
        {
          date: '2024-06-11',
          searchVolume: 3432,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-10',
          searchVolume: 3432,
          organicRank: 237,

          keywordSales: 24
        },
        {
          date: '2024-06-09',
          searchVolume: 3432,
          organicRank: 274,

          keywordSales: 24
        },
        {
          date: '2024-06-08',
          searchVolume: 3432,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-07',
          searchVolume: 2994,
          organicRank: 243,

          keywordSales: 24
        },
        {
          date: '2024-06-06',
          searchVolume: 2994,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-05',
          searchVolume: 2994,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-04',
          searchVolume: 2994,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-03',
          searchVolume: 2994,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-02',
          searchVolume: 2994,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-01',
          searchVolume: 2994,
          organicRank: 133,

          keywordSales: 24
        },
        {
          date: '2024-05-31',
          searchVolume: 2513,
          organicRank: 136,

          keywordSales: 24
        },
        {
          date: '2024-05-30',
          searchVolume: 2513,
          organicRank: 207,

          keywordSales: 24
        },
        {
          date: '2024-05-29',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-28',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-27',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-26',
          searchVolume: 2513,
          organicRank: 269,

          keywordSales: 24
        },
        {
          date: '2024-05-25',
          searchVolume: 2513,
          organicRank: 280,
          sponsoredRank: 69,
          keywordSales: 24
        },
        {
          date: '2024-05-24',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-23',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-22',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-21',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-20',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-19',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-18',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-17',
          searchVolume: 3073,
          organicRank: 143,
          sponsoredRank: 32,
          keywordSales: 24
        },
        {
          date: '2024-05-16',
          searchVolume: 3073,
          organicRank: 306,
          sponsoredRank: 64,
          keywordSales: 24
        },
        {
          date: '2024-05-15',
          searchVolume: 3073,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-14',
          searchVolume: 3073,
          organicRank: 306,
          sponsoredRank: 28,
          keywordSales: 24
        },
        {
          date: '2024-05-13',
          searchVolume: 3073,
          organicRank: 252,

          keywordSales: 24
        },
        {
          date: '2024-05-12',
          searchVolume: 3073,
          organicRank: 229,

          keywordSales: 24
        },
        {
          date: '2024-05-11',
          searchVolume: 3073,
          organicRank: 194,

          keywordSales: 24
        },
        {
          date: '2024-05-10',
          searchVolume: 3241,
          organicRank: 284,

          keywordSales: 24
        },
        {
          date: '2024-05-09',
          searchVolume: 3241,
          organicRank: 259,

          keywordSales: 24
        },
        {
          date: '2024-05-08',
          searchVolume: 3241,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-07',
          searchVolume: 3241,
          organicRank: 182,

          keywordSales: 24
        },
        {
          date: '2024-05-06',
          searchVolume: 3241,
          organicRank: 205,

          keywordSales: 24
        },
        {
          date: '2024-05-05',
          searchVolume: 3241,
          organicRank: 260,

          keywordSales: 24
        },
        {
          date: '2024-05-04',
          searchVolume: 3241,
          organicRank: 167,

          keywordSales: 24
        },
        {
          date: '2024-05-03',
          searchVolume: 3086,
          organicRank: 200,

          keywordSales: 24
        },
        {
          date: '2024-05-02',
          searchVolume: 3086,
          organicRank: 138,

          keywordSales: 24
        },
        {
          date: '2024-05-01',
          searchVolume: 3086,
          organicRank: 182,

          keywordSales: 24
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_blue switch mechanical keyboard',
      keyword: 'blue switch mechanical keyboard',
      searchVolume: 1632,
      organicStartRank: 18,
      organicHighestRank: 18,
      sponsoredStartRank: 36,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-05-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1852,
          organicRank: 51,
          sponsoredRank: 72,
          keywordSales: 12
        },
        {
          date: '2024-06-20',
          searchVolume: 1852,
          organicRank: 79,

          keywordSales: 12
        },
        {
          date: '2024-06-19',
          searchVolume: 1852,
          organicRank: 44,

          keywordSales: 12
        },
        {
          date: '2024-06-18',
          searchVolume: 1852,
          organicRank: 60,
          sponsoredRank: 18,
          keywordSales: 12
        },
        {
          date: '2024-06-17',
          searchVolume: 1852,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 12
        },
        {
          date: '2024-06-16',
          searchVolume: 1852,
          organicRank: 56,

          keywordSales: 12
        },
        {
          date: '2024-06-15',
          searchVolume: 1852,
          organicRank: 74,
          sponsoredRank: 18,
          keywordSales: 12
        },
        {
          date: '2024-06-14',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 12
        },
        {
          date: '2024-06-13',
          searchVolume: 1917,
          organicRank: 88,

          keywordSales: 12
        },
        {
          date: '2024-06-12',
          searchVolume: 1917,
          organicRank: 103,

          keywordSales: 12
        },
        {
          date: '2024-06-11',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 12
        },
        {
          date: '2024-06-10',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 12
        },
        {
          date: '2024-06-09',
          searchVolume: 1917,
          organicRank: 74,
          sponsoredRank: 4,
          keywordSales: 12
        },
        {
          date: '2024-06-08',
          searchVolume: 1917,
          organicRank: 59,

          keywordSales: 12
        },
        {
          date: '2024-06-07',
          searchVolume: 1586,
          organicRank: 55,

          keywordSales: 12
        },
        {
          date: '2024-06-06',
          searchVolume: 1586,
          organicRank: 138,

          keywordSales: 12
        },
        {
          date: '2024-06-05',
          searchVolume: 1586,
          organicRank: 82,
          sponsoredRank: 62,
          keywordSales: 12
        },
        {
          date: '2024-06-04',
          searchVolume: 1586,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 12
        },
        {
          date: '2024-06-03',
          searchVolume: 1586,
          organicRank: 88,

          keywordSales: 12
        },
        {
          date: '2024-06-02',
          searchVolume: 1586,
          organicRank: 47,

          keywordSales: 12
        },
        {
          date: '2024-06-01',
          searchVolume: 1586,
          organicRank: 49,

          keywordSales: 12
        },
        {
          date: '2024-05-31',
          searchVolume: 1637,
          organicRank: 45,
          sponsoredRank: 55,
          keywordSales: 12
        },
        {
          date: '2024-05-30',
          searchVolume: 1637,
          organicRank: 54,

          keywordSales: 12
        },
        {
          date: '2024-05-29',
          searchVolume: 1637,
          organicRank: 54,

          keywordSales: 12
        },
        {
          date: '2024-05-28',
          searchVolume: 1637,
          organicRank: 106,

          keywordSales: 12
        },
        {
          date: '2024-05-27',
          searchVolume: 1637,
          organicRank: 105,

          keywordSales: 12
        },
        {
          date: '2024-05-26',
          searchVolume: 1637,
          organicRank: 50,
          sponsoredRank: 14,
          keywordSales: 12
        },
        {
          date: '2024-05-25',
          searchVolume: 1637,
          organicRank: 38,
          sponsoredRank: 49,
          keywordSales: 12
        },
        {
          date: '2024-05-24',
          searchVolume: 1632,
          organicRank: 34,
          sponsoredRank: 64,
          keywordSales: 12
        },
        {
          date: '2024-05-23',
          searchVolume: 1632,
          organicRank: 35,

          keywordSales: 12
        },
        {
          date: '2024-05-22',
          searchVolume: 1632,
          organicRank: 43,

          keywordSales: 12
        },
        {
          date: '2024-05-21',
          searchVolume: 1632,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 12
        },
        {
          date: '2024-05-20',
          searchVolume: 1632,
          organicRank: 33,

          keywordSales: 12
        },
        {
          date: '2024-05-19',
          searchVolume: 1632,
          organicRank: 44,

          keywordSales: 12
        },
        {
          date: '2024-05-18',
          searchVolume: 1632,
          organicRank: 45,

          keywordSales: 12
        },
        {
          date: '2024-05-17',
          searchVolume: 1580,
          organicRank: 38,
          sponsoredRank: 50,
          keywordSales: 12
        },
        {
          date: '2024-05-16',
          searchVolume: 1580,
          organicRank: 32,

          keywordSales: 12
        },
        {
          date: '2024-05-15',
          searchVolume: 1580,
          organicRank: 31,

          keywordSales: 12
        },
        {
          date: '2024-05-14',
          searchVolume: 1580,
          organicRank: 47,

          keywordSales: 12
        },
        {
          date: '2024-05-13',
          searchVolume: 1580,
          organicRank: 37,
          sponsoredRank: 72,
          keywordSales: 12
        },
        {
          date: '2024-05-12',
          searchVolume: 1580,
          organicRank: 50,

          keywordSales: 12
        },
        {
          date: '2024-05-11',
          searchVolume: 1580,
          organicRank: 30,

          keywordSales: 12
        },
        {
          date: '2024-05-10',
          searchVolume: 1683,
          organicRank: 34,

          keywordSales: 12
        },
        {
          date: '2024-05-09',
          searchVolume: 1683,
          organicRank: 37,

          keywordSales: 12
        },
        {
          date: '2024-05-08',
          searchVolume: 1683,
          organicRank: 27,

          keywordSales: 12
        },
        {
          date: '2024-05-07',
          searchVolume: 1683,
          organicRank: 23,

          keywordSales: 12
        },
        {
          date: '2024-05-06',
          searchVolume: 1683,
          organicRank: 23,

          keywordSales: 12
        },
        {
          date: '2024-05-05',
          searchVolume: 1683,
          organicRank: 27,

          keywordSales: 12
        },
        {
          date: '2024-05-04',
          searchVolume: 1683,
          organicRank: 18,
          sponsoredRank: 36,
          keywordSales: 12
        },
        {
          date: '2024-05-03',
          searchVolume: 1827,
          organicRank: 24,

          keywordSales: 12
        },
        {
          date: '2024-05-02',
          searchVolume: 1827,
          organicRank: 28,

          keywordSales: 12
        },
        {
          date: '2024-05-01',
          searchVolume: 1827,
          organicRank: 19,

          keywordSales: 12
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_cheap gaming keyboard',
      keyword: 'cheap gaming keyboard',
      searchVolume: 1324,
      organicStartRank: 216,
      organicHighestRank: 126,
      sponsoredStartRank: 57,
      sponsoredHighestRank: 22,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1432,
          organicRank: 204,

          keywordSales: 19
        },
        {
          date: '2024-06-19',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-18',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-17',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-16',
          searchVolume: 1432,
          organicRank: 195,

          keywordSales: 19
        },
        {
          date: '2024-06-15',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-14',
          searchVolume: 1432,
          organicRank: 306,
          sponsoredRank: 77,
          keywordSales: 19
        },
        {
          date: '2024-06-13',
          searchVolume: 1432,
          organicRank: 126,

          keywordSales: 19
        },
        {
          date: '2024-06-12',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-11',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-10',
          searchVolume: 1432,
          organicRank: 214,

          keywordSales: 19
        },
        {
          date: '2024-06-09',
          searchVolume: 1432,
          organicRank: 195,

          keywordSales: 19
        },
        {
          date: '2024-06-08',
          searchVolume: 1432,
          organicRank: 188,

          keywordSales: 19
        },
        {
          date: '2024-06-07',
          searchVolume: 1288,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-06',
          searchVolume: 1288,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-05',
          searchVolume: 1288,
          organicRank: 206,

          keywordSales: 19
        },
        {
          date: '2024-06-04',
          searchVolume: 1288,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-03',
          searchVolume: 1288,
          organicRank: 185,

          keywordSales: 19
        },
        {
          date: '2024-06-02',
          searchVolume: 1288,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-01',
          searchVolume: 1288,
          organicRank: 195,

          keywordSales: 19
        },
        {
          date: '2024-05-31',
          searchVolume: 1204,
          organicRank: 243,

          keywordSales: 19
        },
        {
          date: '2024-05-30',
          searchVolume: 1204,
          organicRank: 198,

          keywordSales: 19
        },
        {
          date: '2024-05-29',
          searchVolume: 1204,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-28',
          searchVolume: 1204,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-27',
          searchVolume: 1204,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-26',
          searchVolume: 1204,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-25',
          searchVolume: 1204,
          organicRank: 215,

          keywordSales: 19
        },
        {
          date: '2024-05-24',
          searchVolume: 1324,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-23',
          searchVolume: 1324,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-22',
          searchVolume: 1324,
          organicRank: 242,

          keywordSales: 19
        },
        {
          date: '2024-05-21',
          searchVolume: 1324,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-20',
          searchVolume: 1324,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-19',
          searchVolume: 1324,
          organicRank: 236,

          keywordSales: 19
        },
        {
          date: '2024-05-18',
          searchVolume: 1324,
          organicRank: 248,

          keywordSales: 19
        },
        {
          date: '2024-05-17',
          searchVolume: 1408,
          organicRank: 221,
          sponsoredRank: 22,
          keywordSales: 19
        },
        {
          date: '2024-05-16',
          searchVolume: 1408,
          organicRank: 243,

          keywordSales: 19
        },
        {
          date: '2024-05-15',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-14',
          searchVolume: 1408,
          organicRank: 175,

          keywordSales: 19
        },
        {
          date: '2024-05-13',
          searchVolume: 1408,
          organicRank: 237,

          keywordSales: 19
        },
        {
          date: '2024-05-12',
          searchVolume: 1408,
          organicRank: 306,
          sponsoredRank: 64,
          keywordSales: 19
        },
        {
          date: '2024-05-11',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-10',
          searchVolume: 1403,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 19
        },
        {
          date: '2024-05-09',
          searchVolume: 1403,
          organicRank: 252,

          keywordSales: 19
        },
        {
          date: '2024-05-08',
          searchVolume: 1403,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-07',
          searchVolume: 1403,
          organicRank: 251,

          keywordSales: 19
        },
        {
          date: '2024-05-06',
          searchVolume: 1403,
          organicRank: 244,

          keywordSales: 19
        },
        {
          date: '2024-05-03',
          searchVolume: 1365,
          organicRank: 216,

          keywordSales: 19
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_cherry keyboard',
      keyword: 'cherry keyboard',
      searchVolume: 2153,
      organicStartRank: 116,
      organicHighestRank: 81,
      sponsoredStartRank: 65,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1917,
          organicRank: 147,

          keywordSales: 10
        },
        {
          date: '2024-06-20',
          searchVolume: 1917,
          organicRank: 151,

          keywordSales: 10
        },
        {
          date: '2024-06-19',
          searchVolume: 1917,
          organicRank: 157,

          keywordSales: 10
        },
        {
          date: '2024-06-18',
          searchVolume: 1917,
          organicRank: 267,

          keywordSales: 10
        },
        {
          date: '2024-06-17',
          searchVolume: 1917,
          organicRank: 145,

          keywordSales: 10
        },
        {
          date: '2024-06-16',
          searchVolume: 1917,
          organicRank: 152,
          sponsoredRank: 42,
          keywordSales: 10
        },
        {
          date: '2024-06-15',
          searchVolume: 1917,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 10
        },
        {
          date: '2024-06-14',
          searchVolume: 1924,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 10
        },
        {
          date: '2024-06-13',
          searchVolume: 1924,
          organicRank: 124,

          keywordSales: 10
        },
        {
          date: '2024-06-12',
          searchVolume: 1924,
          organicRank: 124,

          keywordSales: 10
        },
        {
          date: '2024-06-11',
          searchVolume: 1924,
          organicRank: 253,

          keywordSales: 10
        },
        {
          date: '2024-06-10',
          searchVolume: 1924,
          organicRank: 120,

          keywordSales: 10
        },
        {
          date: '2024-06-09',
          searchVolume: 1924,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 10
        },
        {
          date: '2024-06-08',
          searchVolume: 1924,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 10
        },
        {
          date: '2024-06-07',
          searchVolume: 1837,
          organicRank: 116,

          keywordSales: 10
        },
        {
          date: '2024-06-06',
          searchVolume: 1837,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-05',
          searchVolume: 1837,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 10
        },
        {
          date: '2024-06-04',
          searchVolume: 1837,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-03',
          searchVolume: 1837,
          organicRank: 233,

          keywordSales: 10
        },
        {
          date: '2024-06-02',
          searchVolume: 1837,
          organicRank: 191,

          keywordSales: 10
        },
        {
          date: '2024-06-01',
          searchVolume: 1837,
          organicRank: 135,

          keywordSales: 10
        },
        {
          date: '2024-05-31',
          searchVolume: 1895,
          organicRank: 127,
          sponsoredRank: 47,
          keywordSales: 10
        },
        {
          date: '2024-05-30',
          searchVolume: 1895,
          organicRank: 207,
          sponsoredRank: 50,
          keywordSales: 10
        },
        {
          date: '2024-05-29',
          searchVolume: 1895,
          organicRank: 129,
          sponsoredRank: 70,
          keywordSales: 10
        },
        {
          date: '2024-05-28',
          searchVolume: 1895,
          organicRank: 122,

          keywordSales: 10
        },
        {
          date: '2024-05-27',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-05-26',
          searchVolume: 1895,
          organicRank: 123,
          sponsoredRank: 37,
          keywordSales: 10
        },
        {
          date: '2024-05-25',
          searchVolume: 1895,
          organicRank: 122,

          keywordSales: 10
        },
        {
          date: '2024-05-24',
          searchVolume: 2153,
          organicRank: 113,

          keywordSales: 10
        },
        {
          date: '2024-05-23',
          searchVolume: 2153,
          organicRank: 81,

          keywordSales: 10
        },
        {
          date: '2024-05-22',
          searchVolume: 2153,
          organicRank: 81,

          keywordSales: 10
        },
        {
          date: '2024-05-21',
          searchVolume: 2153,
          organicRank: 92,

          keywordSales: 10
        },
        {
          date: '2024-05-20',
          searchVolume: 2153,
          organicRank: 103,

          keywordSales: 10
        },
        {
          date: '2024-05-19',
          searchVolume: 2153,
          organicRank: 104,

          keywordSales: 10
        },
        {
          date: '2024-05-18',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-05-17',
          searchVolume: 1886,
          organicRank: 83,

          keywordSales: 10
        },
        {
          date: '2024-05-16',
          searchVolume: 1886,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-05-15',
          searchVolume: 1886,
          organicRank: 88,
          sponsoredRank: 52,
          keywordSales: 10
        },
        {
          date: '2024-05-14',
          searchVolume: 1886,
          organicRank: 95,

          keywordSales: 10
        },
        {
          date: '2024-05-13',
          searchVolume: 1886,
          organicRank: 148,

          keywordSales: 10
        },
        {
          date: '2024-05-12',
          searchVolume: 1886,
          organicRank: 138,
          sponsoredRank: 76,
          keywordSales: 10
        },
        {
          date: '2024-05-11',
          searchVolume: 1886,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 10
        },
        {
          date: '2024-05-10',
          searchVolume: 1926,
          organicRank: 132,

          keywordSales: 10
        },
        {
          date: '2024-05-09',
          searchVolume: 1926,
          organicRank: 110,

          keywordSales: 10
        },
        {
          date: '2024-05-08',
          searchVolume: 1926,
          organicRank: 117,

          keywordSales: 10
        },
        {
          date: '2024-05-07',
          searchVolume: 1926,
          organicRank: 123,

          keywordSales: 10
        },
        {
          date: '2024-05-06',
          searchVolume: 1926,
          organicRank: 142,

          keywordSales: 10
        },
        {
          date: '2024-05-05',
          searchVolume: 1926,
          organicRank: 134,
          sponsoredRank: 48,
          keywordSales: 10
        },
        {
          date: '2024-05-04',
          searchVolume: 1926,
          organicRank: 116,
          sponsoredRank: 65,
          keywordSales: 10
        },
        {
          date: '2024-05-03',
          searchVolume: 1834,
          organicRank: 107,
          sponsoredRank: 22,
          keywordSales: 10
        },
        {
          date: '2024-05-02',
          searchVolume: 1834,
          organicRank: 108,

          keywordSales: 10
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_clicky keyboard',
      keyword: 'clicky keyboard',
      searchVolume: 4257,
      organicStartRank: 75,
      organicHighestRank: 62,
      sponsoredStartRank: 10,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 4324,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-20',
          searchVolume: 4324,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-19',
          searchVolume: 4324,
          organicRank: 112,
          sponsoredRank: 45,
          keywordSales: 33
        },
        {
          date: '2024-06-18',
          searchVolume: 4324,
          organicRank: 205,
          sponsoredRank: 57,
          keywordSales: 33
        },
        {
          date: '2024-06-17',
          searchVolume: 4324,
          organicRank: 116,

          keywordSales: 33
        },
        {
          date: '2024-06-16',
          searchVolume: 4324,
          organicRank: 62,

          keywordSales: 33
        },
        {
          date: '2024-06-15',
          searchVolume: 4324,
          organicRank: 106,

          keywordSales: 33
        },
        {
          date: '2024-06-14',
          searchVolume: 4322,
          organicRank: 128,
          sponsoredRank: 32,
          keywordSales: 33
        },
        {
          date: '2024-06-13',
          searchVolume: 4322,
          organicRank: 111,

          keywordSales: 33
        },
        {
          date: '2024-06-12',
          searchVolume: 4322,
          organicRank: 65,

          keywordSales: 33
        },
        {
          date: '2024-06-11',
          searchVolume: 4322,
          organicRank: 116,

          keywordSales: 33
        },
        {
          date: '2024-06-10',
          searchVolume: 4322,
          organicRank: 186,

          keywordSales: 33
        },
        {
          date: '2024-06-09',
          searchVolume: 4322,
          organicRank: 78,
          sponsoredRank: 13,
          keywordSales: 33
        },
        {
          date: '2024-06-08',
          searchVolume: 4322,
          organicRank: 216,

          keywordSales: 33
        },
        {
          date: '2024-06-07',
          searchVolume: 4124,
          organicRank: 192,

          keywordSales: 33
        },
        {
          date: '2024-06-06',
          searchVolume: 4124,
          organicRank: 250,

          keywordSales: 33
        },
        {
          date: '2024-06-05',
          searchVolume: 4124,
          organicRank: 126,
          sponsoredRank: 32,
          keywordSales: 33
        },
        {
          date: '2024-06-04',
          searchVolume: 4124,
          organicRank: 187,

          keywordSales: 33
        },
        {
          date: '2024-06-03',
          searchVolume: 4124,
          organicRank: 190,

          keywordSales: 33
        },
        {
          date: '2024-06-02',
          searchVolume: 4124,
          organicRank: 134,

          keywordSales: 33
        },
        {
          date: '2024-06-01',
          searchVolume: 4124,
          organicRank: 132,

          keywordSales: 33
        },
        {
          date: '2024-05-31',
          searchVolume: 3574,
          organicRank: 165,
          sponsoredRank: 22,
          keywordSales: 33
        },
        {
          date: '2024-05-30',
          searchVolume: 3574,
          organicRank: 126,

          keywordSales: 33
        },
        {
          date: '2024-05-29',
          searchVolume: 3574,
          organicRank: 161,

          keywordSales: 33
        },
        {
          date: '2024-05-28',
          searchVolume: 3574,
          organicRank: 82,

          keywordSales: 33
        },
        {
          date: '2024-05-27',
          searchVolume: 3574,
          organicRank: 88,

          keywordSales: 33
        },
        {
          date: '2024-05-26',
          searchVolume: 3574,
          organicRank: 75,
          sponsoredRank: 30,
          keywordSales: 33
        },
        {
          date: '2024-05-25',
          searchVolume: 3574,
          organicRank: 105,

          keywordSales: 33
        },
        {
          date: '2024-05-24',
          searchVolume: 4257,
          organicRank: 85,

          keywordSales: 33
        },
        {
          date: '2024-05-23',
          searchVolume: 4257,
          organicRank: 71,

          keywordSales: 33
        },
        {
          date: '2024-05-22',
          searchVolume: 4257,
          organicRank: 123,
          sponsoredRank: 25,
          keywordSales: 33
        },
        {
          date: '2024-05-21',
          searchVolume: 4257,
          organicRank: 212,

          keywordSales: 33
        },
        {
          date: '2024-05-20',
          searchVolume: 4257,
          organicRank: 106,

          keywordSales: 33
        },
        {
          date: '2024-05-19',
          searchVolume: 4257,
          organicRank: 203,

          keywordSales: 33
        },
        {
          date: '2024-05-18',
          searchVolume: 4257,
          organicRank: 122,

          keywordSales: 33
        },
        {
          date: '2024-05-17',
          searchVolume: 3557,
          organicRank: 203,
          sponsoredRank: 13,
          keywordSales: 33
        },
        {
          date: '2024-05-16',
          searchVolume: 3557,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-15',
          searchVolume: 3557,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 33
        },
        {
          date: '2024-05-14',
          searchVolume: 3557,
          organicRank: 306,
          sponsoredRank: 82,
          keywordSales: 33
        },
        {
          date: '2024-05-13',
          searchVolume: 3557,
          organicRank: 110,
          sponsoredRank: 32,
          keywordSales: 33
        },
        {
          date: '2024-05-12',
          searchVolume: 3557,
          organicRank: 128,
          sponsoredRank: 8,
          keywordSales: 33
        },
        {
          date: '2024-05-11',
          searchVolume: 3557,
          organicRank: 106,
          sponsoredRank: 2,
          keywordSales: 33
        },
        {
          date: '2024-05-10',
          searchVolume: 4378,
          organicRank: 98,
          sponsoredRank: 31,
          keywordSales: 33
        },
        {
          date: '2024-05-09',
          searchVolume: 4378,
          organicRank: 100,
          sponsoredRank: 42,
          keywordSales: 33
        },
        {
          date: '2024-05-08',
          searchVolume: 4378,
          organicRank: 95,
          sponsoredRank: 62,
          keywordSales: 33
        },
        {
          date: '2024-05-07',
          searchVolume: 4378,
          organicRank: 95,
          sponsoredRank: 67,
          keywordSales: 33
        },
        {
          date: '2024-05-06',
          searchVolume: 4378,
          organicRank: 85,
          sponsoredRank: 67,
          keywordSales: 33
        },
        {
          date: '2024-05-05',
          searchVolume: 4378,
          organicRank: 102,

          keywordSales: 33
        },
        {
          date: '2024-05-04',
          searchVolume: 4378,
          organicRank: 75,

          keywordSales: 33
        },
        {
          date: '2024-05-03',
          searchVolume: 4344,
          organicRank: 62,
          sponsoredRank: 10,
          keywordSales: 33
        },
        {
          date: '2024-05-02',
          searchVolume: 4344,
          organicRank: 70,
          sponsoredRank: 79,
          keywordSales: 33
        },
        {
          date: '2024-05-01',
          searchVolume: 4344,
          organicRank: 55,

          keywordSales: 33
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_compact keyboard',
      keyword: 'compact keyboard',
      searchVolume: 2317,
      organicStartRank: 90,
      organicHighestRank: 59,
      sponsoredStartRank: 20,
      sponsoredHighestRank: 20,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2428,
          organicRank: 94,

          keywordSales: 29
        },
        {
          date: '2024-06-19',
          searchVolume: 2428,
          organicRank: 70,
          sponsoredRank: 31,
          keywordSales: 29
        },
        {
          date: '2024-06-18',
          searchVolume: 2428,
          organicRank: 93,

          keywordSales: 29
        },
        {
          date: '2024-06-17',
          searchVolume: 2428,
          organicRank: 96,

          keywordSales: 29
        },
        {
          date: '2024-06-16',
          searchVolume: 2428,
          organicRank: 76,

          keywordSales: 29
        },
        {
          date: '2024-06-15',
          searchVolume: 2428,
          organicRank: 96,

          keywordSales: 29
        },
        {
          date: '2024-06-14',
          searchVolume: 2186,
          organicRank: 92,
          sponsoredRank: 52,
          keywordSales: 29
        },
        {
          date: '2024-06-13',
          searchVolume: 2186,
          organicRank: 73,
          sponsoredRank: 52,
          keywordSales: 29
        },
        {
          date: '2024-06-12',
          searchVolume: 2186,
          organicRank: 76,

          keywordSales: 29
        },
        {
          date: '2024-06-11',
          searchVolume: 2186,
          organicRank: 78,

          keywordSales: 29
        },
        {
          date: '2024-06-10',
          searchVolume: 2186,
          organicRank: 83,

          keywordSales: 29
        },
        {
          date: '2024-06-09',
          searchVolume: 2186,
          organicRank: 97,
          sponsoredRank: 27,
          keywordSales: 29
        },
        {
          date: '2024-06-08',
          searchVolume: 2186,
          organicRank: 97,

          keywordSales: 29
        },
        {
          date: '2024-06-07',
          searchVolume: 2073,
          organicRank: 96,

          keywordSales: 29
        },
        {
          date: '2024-06-06',
          searchVolume: 2073,
          organicRank: 91,

          keywordSales: 29
        },
        {
          date: '2024-06-05',
          searchVolume: 2073,
          organicRank: 177,

          keywordSales: 29
        },
        {
          date: '2024-06-04',
          searchVolume: 2073,
          organicRank: 97,

          keywordSales: 29
        },
        {
          date: '2024-06-03',
          searchVolume: 2073,
          organicRank: 96,

          keywordSales: 29
        },
        {
          date: '2024-06-02',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-06-01',
          searchVolume: 2073,
          organicRank: 105,
          sponsoredRank: 20,
          keywordSales: 29
        },
        {
          date: '2024-05-31',
          searchVolume: 2394,
          organicRank: 101,

          keywordSales: 29
        },
        {
          date: '2024-05-30',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-05-29',
          searchVolume: 2394,
          organicRank: 97,

          keywordSales: 29
        },
        {
          date: '2024-05-28',
          searchVolume: 2394,
          organicRank: 97,

          keywordSales: 29
        },
        {
          date: '2024-05-27',
          searchVolume: 2394,
          organicRank: 101,

          keywordSales: 29
        },
        {
          date: '2024-05-26',
          searchVolume: 2394,
          organicRank: 228,

          keywordSales: 29
        },
        {
          date: '2024-05-25',
          searchVolume: 2394,
          organicRank: 129,

          keywordSales: 29
        },
        {
          date: '2024-05-24',
          searchVolume: 2317,
          organicRank: 101,

          keywordSales: 29
        },
        {
          date: '2024-05-23',
          searchVolume: 2317,
          organicRank: 101,

          keywordSales: 29
        },
        {
          date: '2024-05-22',
          searchVolume: 2317,
          organicRank: 92,

          keywordSales: 29
        },
        {
          date: '2024-05-21',
          searchVolume: 2317,
          organicRank: 89,

          keywordSales: 29
        },
        {
          date: '2024-05-20',
          searchVolume: 2317,
          organicRank: 106,

          keywordSales: 29
        },
        {
          date: '2024-05-19',
          searchVolume: 2317,
          organicRank: 101,

          keywordSales: 29
        },
        {
          date: '2024-05-18',
          searchVolume: 2317,
          organicRank: 59,

          keywordSales: 29
        },
        {
          date: '2024-05-17',
          searchVolume: 2387,
          organicRank: 80,

          keywordSales: 29
        },
        {
          date: '2024-05-16',
          searchVolume: 2387,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-05-15',
          searchVolume: 2387,
          organicRank: 243,

          keywordSales: 29
        },
        {
          date: '2024-05-14',
          searchVolume: 2387,
          organicRank: 88,

          keywordSales: 29
        },
        {
          date: '2024-05-13',
          searchVolume: 2387,
          organicRank: 96,

          keywordSales: 29
        },
        {
          date: '2024-05-12',
          searchVolume: 2387,
          organicRank: 95,

          keywordSales: 29
        },
        {
          date: '2024-05-11',
          searchVolume: 2387,
          organicRank: 60,

          keywordSales: 29
        },
        {
          date: '2024-05-10',
          searchVolume: 2391,
          organicRank: 77,

          keywordSales: 29
        },
        {
          date: '2024-05-09',
          searchVolume: 2391,
          organicRank: 77,

          keywordSales: 29
        },
        {
          date: '2024-05-08',
          searchVolume: 2391,
          organicRank: 83,

          keywordSales: 29
        },
        {
          date: '2024-05-07',
          searchVolume: 2391,
          organicRank: 75,

          keywordSales: 29
        },
        {
          date: '2024-05-06',
          searchVolume: 2391,
          organicRank: 86,

          keywordSales: 29
        },
        {
          date: '2024-05-05',
          searchVolume: 2391,
          organicRank: 109,

          keywordSales: 29
        },
        {
          date: '2024-05-04',
          searchVolume: 2391,
          organicRank: 90,

          keywordSales: 29
        },
        {
          date: '2024-05-02',
          searchVolume: 2316,
          organicRank: 90,

          keywordSales: 29
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_compact wireless keyboard',
      keyword: 'compact wireless keyboard',
      searchVolume: 907,
      organicStartRank: 306,
      organicHighestRank: 119,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 23,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-20',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-19',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-18',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-17',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-15',
          searchVolume: 886,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 11
        },
        {
          date: '2024-06-14',
          searchVolume: 747,
          organicRank: 175,

          keywordSales: 11
        },
        {
          date: '2024-06-13',
          searchVolume: 747,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-12',
          searchVolume: 747,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-11',
          searchVolume: 747,
          organicRank: 134,

          keywordSales: 11
        },
        {
          date: '2024-06-10',
          searchVolume: 747,
          organicRank: 129,

          keywordSales: 11
        },
        {
          date: '2024-06-09',
          searchVolume: 747,
          organicRank: 138,
          sponsoredRank: 85,
          keywordSales: 11
        },
        {
          date: '2024-06-08',
          searchVolume: 747,
          organicRank: 132,
          sponsoredRank: 50,
          keywordSales: 11
        },
        {
          date: '2024-06-07',
          searchVolume: 907,
          organicRank: 119,

          keywordSales: 11
        },
        {
          date: '2024-06-06',
          searchVolume: 907,
          organicRank: 126,

          keywordSales: 11
        },
        {
          date: '2024-06-05',
          searchVolume: 907,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 11
        },
        {
          date: '2024-06-04',
          searchVolume: 907,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-03',
          searchVolume: 907,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-02',
          searchVolume: 907,
          organicRank: 140,

          keywordSales: 11
        },
        {
          date: '2024-06-01',
          searchVolume: 907,
          organicRank: 145,

          keywordSales: 11
        },
        {
          date: '2024-05-31',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-30',
          searchVolume: 937,
          organicRank: 141,

          keywordSales: 11
        },
        {
          date: '2024-05-29',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-28',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-27',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-26',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-25',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-24',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-23',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-22',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-21',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-20',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-19',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-18',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-17',
          searchVolume: 1198,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-16',
          searchVolume: 1198,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-15',
          searchVolume: 1198,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-14',
          searchVolume: 1198,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-13',
          searchVolume: 1198,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-12',
          searchVolume: 1198,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-11',
          searchVolume: 1198,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-10',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-05-09',
          searchVolume: 953,
          organicRank: 250,

          keywordSales: 11
        },
        {
          date: '2024-05-08',
          searchVolume: 953,
          organicRank: 124,

          keywordSales: 11
        },
        {
          date: '2024-05-07',
          searchVolume: 953,
          organicRank: 122,

          keywordSales: 11
        },
        {
          date: '2024-05-06',
          searchVolume: 953,
          organicRank: 306,

          keywordSales: 11
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_cool keyboard',
      keyword: 'cool keyboard',
      searchVolume: 1632,
      organicStartRank: 173,
      organicHighestRank: 115,
      sponsoredStartRank: 72,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1607,
          organicRank: 115,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 1607,
          organicRank: 129,

          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 1607,
          organicRank: 130,
          sponsoredRank: 29,
          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 1607,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 1607,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 1607,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 1432,
          organicRank: 127,
          sponsoredRank: 91,
          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 1432,
          organicRank: 125,

          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 1432,
          organicRank: 124,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 1432,
          organicRank: 125,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 1432,
          organicRank: 215,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 1432,
          organicRank: 121,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 1379,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 1379,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 1379,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 1379,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 1379,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 1379,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 1379,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 1410,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 1632,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 1632,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 1632,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 1632,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 1632,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 1632,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 1632,
          organicRank: 306,
          sponsoredRank: 55,
          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 1408,
          organicRank: 283,

          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 1408,
          organicRank: 226,

          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 1408,
          organicRank: 128,
          sponsoredRank: 62,
          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 1657,
          organicRank: 117,
          sponsoredRank: 72,
          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 1657,
          organicRank: 131,

          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 1657,
          organicRank: 127,

          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 1657,
          organicRank: 194,

          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 1657,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-02',
          searchVolume: 1588,
          organicRank: 173,

          keywordSales: 3
        },
        {
          date: '2024-05-01',
          searchVolume: 1588,
          organicRank: 272,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_corsair headset',
      keyword: 'corsair headset',
      searchVolume: 16642,
      organicStartRank: 261,
      organicHighestRank: 306,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 16692,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-19',
          searchVolume: 16692,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-18',
          searchVolume: 16692,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-17',
          searchVolume: 16692,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-16',
          searchVolume: 16692,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-15',
          searchVolume: 16692,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-14',
          searchVolume: 16657,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-13',
          searchVolume: 16657,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-12',
          searchVolume: 16657,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-11',
          searchVolume: 16657,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-10',
          searchVolume: 16657,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-09',
          searchVolume: 16657,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-08',
          searchVolume: 16657,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-07',
          searchVolume: 15864,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-06',
          searchVolume: 15864,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-05',
          searchVolume: 15864,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-04',
          searchVolume: 15864,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-03',
          searchVolume: 15864,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-02',
          searchVolume: 15864,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-06-01',
          searchVolume: 15864,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-31',
          searchVolume: 16390,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-30',
          searchVolume: 16390,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-29',
          searchVolume: 16390,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-28',
          searchVolume: 16390,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-27',
          searchVolume: 16390,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-26',
          searchVolume: 16390,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-25',
          searchVolume: 16390,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-24',
          searchVolume: 15468,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-23',
          searchVolume: 15468,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-22',
          searchVolume: 15468,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-21',
          searchVolume: 15468,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-20',
          searchVolume: 15468,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-19',
          searchVolume: 15468,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-18',
          searchVolume: 15468,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-17',
          searchVolume: 15913,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-16',
          searchVolume: 15913,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-15',
          searchVolume: 15913,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-14',
          searchVolume: 15913,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-13',
          searchVolume: 15913,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-12',
          searchVolume: 15913,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-11',
          searchVolume: 15913,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-10',
          searchVolume: 16642,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-09',
          searchVolume: 16642,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-08',
          searchVolume: 16642,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-07',
          searchVolume: 16642,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-06',
          searchVolume: 16642,
          organicRank: 306,

          keywordSales: 526
        },
        {
          date: '2024-05-02',
          searchVolume: 14934,
          organicRank: 261,

          keywordSales: 526
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_corsair keyboard',
      keyword: 'corsair keyboard',
      searchVolume: 16659,
      organicStartRank: 61,
      organicHighestRank: 30,
      sponsoredStartRank: 5,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-07',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 14180,
          organicRank: 76,
          sponsoredRank: 29,
          keywordSales: 315
        },
        {
          date: '2024-06-19',
          searchVolume: 14180,
          organicRank: 59,
          sponsoredRank: 4,
          keywordSales: 315
        },
        {
          date: '2024-06-18',
          searchVolume: 14180,
          organicRank: 85,
          sponsoredRank: 7,
          keywordSales: 315
        },
        {
          date: '2024-06-17',
          searchVolume: 14180,
          organicRank: 81,

          keywordSales: 315
        },
        {
          date: '2024-06-16',
          searchVolume: 14180,
          organicRank: 81,

          keywordSales: 315
        },
        {
          date: '2024-06-15',
          searchVolume: 14180,
          organicRank: 79,

          keywordSales: 315
        },
        {
          date: '2024-06-14',
          searchVolume: 13444,
          organicRank: 92,
          sponsoredRank: 24,
          keywordSales: 315
        },
        {
          date: '2024-06-13',
          searchVolume: 13444,
          organicRank: 70,
          sponsoredRank: 54,
          keywordSales: 315
        },
        {
          date: '2024-06-12',
          searchVolume: 13444,
          organicRank: 71,

          keywordSales: 315
        },
        {
          date: '2024-06-11',
          searchVolume: 13444,
          organicRank: 67,

          keywordSales: 315
        },
        {
          date: '2024-06-10',
          searchVolume: 13444,
          organicRank: 74,

          keywordSales: 315
        },
        {
          date: '2024-06-09',
          searchVolume: 13444,
          organicRank: 68,
          sponsoredRank: 8,
          keywordSales: 315
        },
        {
          date: '2024-06-08',
          searchVolume: 13444,
          organicRank: 62,
          sponsoredRank: 1,
          keywordSales: 315
        },
        {
          date: '2024-06-07',
          searchVolume: 13476,
          organicRank: 58,

          keywordSales: 315
        },
        {
          date: '2024-06-06',
          searchVolume: 13476,
          organicRank: 76,

          keywordSales: 315
        },
        {
          date: '2024-06-05',
          searchVolume: 13476,
          organicRank: 78,
          sponsoredRank: 12,
          keywordSales: 315
        },
        {
          date: '2024-06-04',
          searchVolume: 13476,
          organicRank: 58,

          keywordSales: 315
        },
        {
          date: '2024-06-03',
          searchVolume: 13476,
          organicRank: 66,

          keywordSales: 315
        },
        {
          date: '2024-06-02',
          searchVolume: 13476,
          organicRank: 72,

          keywordSales: 315
        },
        {
          date: '2024-06-01',
          searchVolume: 13476,
          organicRank: 66,

          keywordSales: 315
        },
        {
          date: '2024-05-31',
          searchVolume: 13923,
          organicRank: 78,
          sponsoredRank: 7,
          keywordSales: 315
        },
        {
          date: '2024-05-30',
          searchVolume: 13923,
          organicRank: 58,

          keywordSales: 315
        },
        {
          date: '2024-05-29',
          searchVolume: 13923,
          organicRank: 70,

          keywordSales: 315
        },
        {
          date: '2024-05-28',
          searchVolume: 13923,
          organicRank: 59,

          keywordSales: 315
        },
        {
          date: '2024-05-27',
          searchVolume: 13923,
          organicRank: 59,

          keywordSales: 315
        },
        {
          date: '2024-05-26',
          searchVolume: 13923,
          organicRank: 62,
          sponsoredRank: 19,
          keywordSales: 315
        },
        {
          date: '2024-05-25',
          searchVolume: 13923,
          organicRank: 79,
          sponsoredRank: 7,
          keywordSales: 315
        },
        {
          date: '2024-05-24',
          searchVolume: 15445,
          organicRank: 69,
          sponsoredRank: 8,
          keywordSales: 315
        },
        {
          date: '2024-05-23',
          searchVolume: 15445,
          organicRank: 69,

          keywordSales: 315
        },
        {
          date: '2024-05-22',
          searchVolume: 15445,
          organicRank: 85,
          sponsoredRank: 8,
          keywordSales: 315
        },
        {
          date: '2024-05-21',
          searchVolume: 15445,
          organicRank: 68,
          sponsoredRank: 3,
          keywordSales: 315
        },
        {
          date: '2024-05-20',
          searchVolume: 15445,
          organicRank: 76,

          keywordSales: 315
        },
        {
          date: '2024-05-19',
          searchVolume: 15445,
          organicRank: 81,

          keywordSales: 315
        },
        {
          date: '2024-05-18',
          searchVolume: 15445,
          organicRank: 73,
          sponsoredRank: 48,
          keywordSales: 315
        },
        {
          date: '2024-05-17',
          searchVolume: 13947,
          organicRank: 92,

          keywordSales: 315
        },
        {
          date: '2024-05-16',
          searchVolume: 13947,
          organicRank: 72,
          sponsoredRank: 7,
          keywordSales: 315
        },
        {
          date: '2024-05-15',
          searchVolume: 13947,
          organicRank: 37,
          sponsoredRank: 54,
          keywordSales: 315
        },
        {
          date: '2024-05-14',
          searchVolume: 13947,
          organicRank: 34,
          sponsoredRank: 41,
          keywordSales: 315
        },
        {
          date: '2024-05-13',
          searchVolume: 13947,
          organicRank: 36,
          sponsoredRank: 56,
          keywordSales: 315
        },
        {
          date: '2024-05-12',
          searchVolume: 13947,
          organicRank: 31,
          sponsoredRank: 30,
          keywordSales: 315
        },
        {
          date: '2024-05-11',
          searchVolume: 13947,
          organicRank: 37,
          sponsoredRank: 18,
          keywordSales: 315
        },
        {
          date: '2024-05-10',
          searchVolume: 16659,
          organicRank: 35,
          sponsoredRank: 21,
          keywordSales: 315
        },
        {
          date: '2024-05-09',
          searchVolume: 16659,
          organicRank: 33,
          sponsoredRank: 50,
          keywordSales: 315
        },
        {
          date: '2024-05-08',
          searchVolume: 16659,
          organicRank: 30,
          sponsoredRank: 65,
          keywordSales: 315
        },
        {
          date: '2024-05-07',
          searchVolume: 16659,
          organicRank: 33,
          sponsoredRank: 11,
          keywordSales: 315
        },
        {
          date: '2024-05-06',
          searchVolume: 16659,
          organicRank: 38,
          sponsoredRank: 24,
          keywordSales: 315
        },
        {
          date: '2024-05-05',
          searchVolume: 16659,
          organicRank: 35,
          sponsoredRank: 19,
          keywordSales: 315
        },
        {
          date: '2024-05-04',
          searchVolume: 16659,
          organicRank: 61,

          keywordSales: 315
        },
        {
          date: '2024-05-03',
          searchVolume: 15849,
          organicRank: 27,
          sponsoredRank: 5,
          keywordSales: 315
        },
        {
          date: '2024-05-02',
          searchVolume: 15849,
          organicRank: 34,

          keywordSales: 315
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_custom keyboard',
      keyword: 'custom keyboard',
      searchVolume: 3758,
      organicStartRank: 246,
      organicHighestRank: 180,
      sponsoredStartRank: 85,
      sponsoredHighestRank: 34,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-20',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-19',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-18',
          searchVolume: 3627,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 1
        },
        {
          date: '2024-06-17',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-16',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-15',
          searchVolume: 3627,
          organicRank: 306,
          sponsoredRank: 64,
          keywordSales: 1
        },
        {
          date: '2024-06-14',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-13',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-12',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-11',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-10',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-09',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-08',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-07',
          searchVolume: 3458,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-06',
          searchVolume: 3458,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-05',
          searchVolume: 3458,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-04',
          searchVolume: 3458,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-03',
          searchVolume: 3458,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-02',
          searchVolume: 3458,
          organicRank: 201,

          keywordSales: 1
        },
        {
          date: '2024-06-01',
          searchVolume: 3458,
          organicRank: 250,

          keywordSales: 1
        },
        {
          date: '2024-05-31',
          searchVolume: 3572,
          organicRank: 306,
          sponsoredRank: 81,
          keywordSales: 1
        },
        {
          date: '2024-05-30',
          searchVolume: 3572,
          organicRank: 228,

          keywordSales: 1
        },
        {
          date: '2024-05-29',
          searchVolume: 3572,
          organicRank: 236,

          keywordSales: 1
        },
        {
          date: '2024-05-28',
          searchVolume: 3572,
          organicRank: 226,

          keywordSales: 1
        },
        {
          date: '2024-05-27',
          searchVolume: 3572,
          organicRank: 188,

          keywordSales: 1
        },
        {
          date: '2024-05-26',
          searchVolume: 3572,
          organicRank: 190,

          keywordSales: 1
        },
        {
          date: '2024-05-25',
          searchVolume: 3572,
          organicRank: 180,

          keywordSales: 1
        },
        {
          date: '2024-05-24',
          searchVolume: 3758,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-23',
          searchVolume: 3758,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-22',
          searchVolume: 3758,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-21',
          searchVolume: 3758,
          organicRank: 306,
          sponsoredRank: 34,
          keywordSales: 1
        },
        {
          date: '2024-05-20',
          searchVolume: 3758,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-19',
          searchVolume: 3758,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-18',
          searchVolume: 3758,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-17',
          searchVolume: 3811,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-16',
          searchVolume: 3811,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-15',
          searchVolume: 3811,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-14',
          searchVolume: 3811,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-13',
          searchVolume: 3811,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-12',
          searchVolume: 3811,
          organicRank: 306,
          sponsoredRank: 85,
          keywordSales: 1
        },
        {
          date: '2024-05-11',
          searchVolume: 3811,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-10',
          searchVolume: 3817,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-09',
          searchVolume: 3817,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-08',
          searchVolume: 3817,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-07',
          searchVolume: 3817,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-06',
          searchVolume: 3817,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-03',
          searchVolume: 3697,
          organicRank: 246,

          keywordSales: 1
        },
        {
          date: '2024-05-02',
          searchVolume: 3697,
          organicRank: 110,

          keywordSales: 1
        },
        {
          date: '2024-05-01',
          searchVolume: 3697,
          organicRank: 223,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_das keyboard',
      keyword: 'das keyboard',
      searchVolume: 1582,
      organicStartRank: 52,
      organicHighestRank: 17,
      sponsoredStartRank: 62,
      sponsoredHighestRank: 6,
      organicPageOneWinDate: '2024-05-10',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1432,
          organicRank: 86,

          keywordSales: 28
        },
        {
          date: '2024-06-20',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 28
        },
        {
          date: '2024-06-19',
          searchVolume: 1432,
          organicRank: 229,

          keywordSales: 28
        },
        {
          date: '2024-06-18',
          searchVolume: 1432,
          organicRank: 194,

          keywordSales: 28
        },
        {
          date: '2024-06-17',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 28
        },
        {
          date: '2024-06-16',
          searchVolume: 1432,
          organicRank: 105,

          keywordSales: 28
        },
        {
          date: '2024-06-15',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 28
        },
        {
          date: '2024-06-14',
          searchVolume: 1432,
          organicRank: 123,

          keywordSales: 28
        },
        {
          date: '2024-06-13',
          searchVolume: 1432,
          organicRank: 136,

          keywordSales: 28
        },
        {
          date: '2024-06-12',
          searchVolume: 1432,
          organicRank: 45,

          keywordSales: 28
        },
        {
          date: '2024-06-11',
          searchVolume: 1432,
          organicRank: 62,

          keywordSales: 28
        },
        {
          date: '2024-06-10',
          searchVolume: 1432,
          organicRank: 106,

          keywordSales: 28
        },
        {
          date: '2024-06-09',
          searchVolume: 1432,
          organicRank: 52,

          keywordSales: 28
        },
        {
          date: '2024-06-08',
          searchVolume: 1432,
          organicRank: 39,

          keywordSales: 28
        },
        {
          date: '2024-06-07',
          searchVolume: 1584,
          organicRank: 39,

          keywordSales: 28
        },
        {
          date: '2024-06-06',
          searchVolume: 1584,
          organicRank: 209,

          keywordSales: 28
        },
        {
          date: '2024-06-05',
          searchVolume: 1584,
          organicRank: 146,
          sponsoredRank: 51,
          keywordSales: 28
        },
        {
          date: '2024-06-04',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 28
        },
        {
          date: '2024-06-03',
          searchVolume: 1584,
          organicRank: 118,

          keywordSales: 28
        },
        {
          date: '2024-06-02',
          searchVolume: 1584,
          organicRank: 142,

          keywordSales: 28
        },
        {
          date: '2024-06-01',
          searchVolume: 1584,
          organicRank: 22,
          sponsoredRank: 17,
          keywordSales: 28
        },
        {
          date: '2024-05-31',
          searchVolume: 1410,
          organicRank: 126,
          sponsoredRank: 19,
          keywordSales: 28
        },
        {
          date: '2024-05-30',
          searchVolume: 1410,
          organicRank: 29,

          keywordSales: 28
        },
        {
          date: '2024-05-29',
          searchVolume: 1410,
          organicRank: 107,

          keywordSales: 28
        },
        {
          date: '2024-05-28',
          searchVolume: 1410,
          organicRank: 100,

          keywordSales: 28
        },
        {
          date: '2024-05-27',
          searchVolume: 1410,
          organicRank: 133,

          keywordSales: 28
        },
        {
          date: '2024-05-26',
          searchVolume: 1410,
          organicRank: 99,
          sponsoredRank: 10,
          keywordSales: 28
        },
        {
          date: '2024-05-25',
          searchVolume: 1410,
          organicRank: 115,

          keywordSales: 28
        },
        {
          date: '2024-05-24',
          searchVolume: 1582,
          organicRank: 63,

          keywordSales: 28
        },
        {
          date: '2024-05-23',
          searchVolume: 1582,
          organicRank: 98,

          keywordSales: 28
        },
        {
          date: '2024-05-22',
          searchVolume: 1582,
          organicRank: 24,

          keywordSales: 28
        },
        {
          date: '2024-05-21',
          searchVolume: 1582,
          organicRank: 154,

          keywordSales: 28
        },
        {
          date: '2024-05-20',
          searchVolume: 1582,
          organicRank: 62,

          keywordSales: 28
        },
        {
          date: '2024-05-19',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 28
        },
        {
          date: '2024-05-18',
          searchVolume: 1582,
          organicRank: 35,
          sponsoredRank: 20,
          keywordSales: 28
        },
        {
          date: '2024-05-17',
          searchVolume: 1631,
          organicRank: 35,
          sponsoredRank: 25,
          keywordSales: 28
        },
        {
          date: '2024-05-16',
          searchVolume: 1631,
          organicRank: 259,

          keywordSales: 28
        },
        {
          date: '2024-05-15',
          searchVolume: 1631,
          organicRank: 103,
          sponsoredRank: 40,
          keywordSales: 28
        },
        {
          date: '2024-05-14',
          searchVolume: 1631,
          organicRank: 32,
          sponsoredRank: 24,
          keywordSales: 28
        },
        {
          date: '2024-05-13',
          searchVolume: 1631,
          organicRank: 73,
          sponsoredRank: 18,
          keywordSales: 28
        },
        {
          date: '2024-05-12',
          searchVolume: 1631,
          organicRank: 37,
          sponsoredRank: 20,
          keywordSales: 28
        },
        {
          date: '2024-05-11',
          searchVolume: 1631,
          organicRank: 18,
          sponsoredRank: 6,
          keywordSales: 28
        },
        {
          date: '2024-05-10',
          searchVolume: 1664,
          organicRank: 17,
          sponsoredRank: 56,
          keywordSales: 28
        },
        {
          date: '2024-05-09',
          searchVolume: 1664,
          organicRank: 86,

          keywordSales: 28
        },
        {
          date: '2024-05-08',
          searchVolume: 1664,
          organicRank: 34,

          keywordSales: 28
        },
        {
          date: '2024-05-07',
          searchVolume: 1664,
          organicRank: 40,

          keywordSales: 28
        },
        {
          date: '2024-05-06',
          searchVolume: 1664,
          organicRank: 254,

          keywordSales: 28
        },
        {
          date: '2024-05-05',
          searchVolume: 1664,
          organicRank: 63,

          keywordSales: 28
        },
        {
          date: '2024-05-03',
          searchVolume: 1609,
          organicRank: 52,
          sponsoredRank: 62,
          keywordSales: 28
        },
        {
          date: '2024-05-02',
          searchVolume: 1609,
          organicRank: 78,

          keywordSales: 28
        },
        {
          date: '2024-05-01',
          searchVolume: 1609,
          organicRank: 76,

          keywordSales: 28
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_ducky keyboard',
      keyword: 'ducky keyboard',
      searchVolume: 6638,
      organicStartRank: 26,
      organicHighestRank: 8,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-05',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 5819,
          organicRank: 27,

          keywordSales: 118
        },
        {
          date: '2024-06-19',
          searchVolume: 5819,
          organicRank: 29,
          sponsoredRank: 10,
          keywordSales: 118
        },
        {
          date: '2024-06-18',
          searchVolume: 5819,
          organicRank: 26,
          sponsoredRank: 18,
          keywordSales: 118
        },
        {
          date: '2024-06-17',
          searchVolume: 5819,
          organicRank: 30,

          keywordSales: 118
        },
        {
          date: '2024-06-16',
          searchVolume: 5819,
          organicRank: 24,

          keywordSales: 118
        },
        {
          date: '2024-06-15',
          searchVolume: 5819,
          organicRank: 25,
          sponsoredRank: 5,
          keywordSales: 118
        },
        {
          date: '2024-06-14',
          searchVolume: 5696,
          organicRank: 17,
          sponsoredRank: 67,
          keywordSales: 118
        },
        {
          date: '2024-06-13',
          searchVolume: 5696,
          organicRank: 24,

          keywordSales: 118
        },
        {
          date: '2024-06-12',
          searchVolume: 5696,
          organicRank: 20,

          keywordSales: 118
        },
        {
          date: '2024-06-11',
          searchVolume: 5696,
          organicRank: 25,

          keywordSales: 118
        },
        {
          date: '2024-06-10',
          searchVolume: 5696,
          organicRank: 28,

          keywordSales: 118
        },
        {
          date: '2024-06-09',
          searchVolume: 5696,
          organicRank: 21,
          sponsoredRank: 5,
          keywordSales: 118
        },
        {
          date: '2024-06-08',
          searchVolume: 5696,
          organicRank: 25,

          keywordSales: 118
        },
        {
          date: '2024-06-07',
          searchVolume: 5683,
          organicRank: 26,

          keywordSales: 118
        },
        {
          date: '2024-06-06',
          searchVolume: 5683,
          organicRank: 22,

          keywordSales: 118
        },
        {
          date: '2024-06-05',
          searchVolume: 5683,
          organicRank: 20,
          sponsoredRank: 54,
          keywordSales: 118
        },
        {
          date: '2024-06-04',
          searchVolume: 5683,
          organicRank: 20,
          sponsoredRank: 5,
          keywordSales: 118
        },
        {
          date: '2024-06-03',
          searchVolume: 5683,
          organicRank: 20,

          keywordSales: 118
        },
        {
          date: '2024-06-02',
          searchVolume: 5683,
          organicRank: 23,

          keywordSales: 118
        },
        {
          date: '2024-06-01',
          searchVolume: 5683,
          organicRank: 13,
          sponsoredRank: 48,
          keywordSales: 118
        },
        {
          date: '2024-05-31',
          searchVolume: 5609,
          organicRank: 36,
          sponsoredRank: 11,
          keywordSales: 118
        },
        {
          date: '2024-05-30',
          searchVolume: 5609,
          organicRank: 31,
          sponsoredRank: 35,
          keywordSales: 118
        },
        {
          date: '2024-05-29',
          searchVolume: 5609,
          organicRank: 26,

          keywordSales: 118
        },
        {
          date: '2024-05-28',
          searchVolume: 5609,
          organicRank: 30,

          keywordSales: 118
        },
        {
          date: '2024-05-27',
          searchVolume: 5609,
          organicRank: 30,

          keywordSales: 118
        },
        {
          date: '2024-05-26',
          searchVolume: 5609,
          organicRank: 34,
          sponsoredRank: 26,
          keywordSales: 118
        },
        {
          date: '2024-05-25',
          searchVolume: 5609,
          organicRank: 28,
          sponsoredRank: 54,
          keywordSales: 118
        },
        {
          date: '2024-05-24',
          searchVolume: 6638,
          organicRank: 33,
          sponsoredRank: 71,
          keywordSales: 118
        },
        {
          date: '2024-05-23',
          searchVolume: 6638,
          organicRank: 24,

          keywordSales: 118
        },
        {
          date: '2024-05-22',
          searchVolume: 6638,
          organicRank: 22,
          sponsoredRank: 1,
          keywordSales: 118
        },
        {
          date: '2024-05-21',
          searchVolume: 6638,
          organicRank: 32,
          sponsoredRank: 53,
          keywordSales: 118
        },
        {
          date: '2024-05-20',
          searchVolume: 6638,
          organicRank: 27,
          sponsoredRank: 8,
          keywordSales: 118
        },
        {
          date: '2024-05-19',
          searchVolume: 6638,
          organicRank: 22,

          keywordSales: 118
        },
        {
          date: '2024-05-18',
          searchVolume: 6638,
          organicRank: 25,

          keywordSales: 118
        },
        {
          date: '2024-05-17',
          searchVolume: 5894,
          organicRank: 28,
          sponsoredRank: 80,
          keywordSales: 118
        },
        {
          date: '2024-05-16',
          searchVolume: 5894,
          organicRank: 24,
          sponsoredRank: 11,
          keywordSales: 118
        },
        {
          date: '2024-05-15',
          searchVolume: 5894,
          organicRank: 25,
          sponsoredRank: 50,
          keywordSales: 118
        },
        {
          date: '2024-05-14',
          searchVolume: 5894,
          organicRank: 25,
          sponsoredRank: 71,
          keywordSales: 118
        },
        {
          date: '2024-05-13',
          searchVolume: 5894,
          organicRank: 28,
          sponsoredRank: 49,
          keywordSales: 118
        },
        {
          date: '2024-05-12',
          searchVolume: 5894,
          organicRank: 24,
          sponsoredRank: 26,
          keywordSales: 118
        },
        {
          date: '2024-05-11',
          searchVolume: 5894,
          organicRank: 29,
          sponsoredRank: 23,
          keywordSales: 118
        },
        {
          date: '2024-05-10',
          searchVolume: 6751,
          organicRank: 32,
          sponsoredRank: 65,
          keywordSales: 118
        },
        {
          date: '2024-05-09',
          searchVolume: 6751,
          organicRank: 26,
          sponsoredRank: 17,
          keywordSales: 118
        },
        {
          date: '2024-05-08',
          searchVolume: 6751,
          organicRank: 28,
          sponsoredRank: 23,
          keywordSales: 118
        },
        {
          date: '2024-05-07',
          searchVolume: 6751,
          organicRank: 26,
          sponsoredRank: 23,
          keywordSales: 118
        },
        {
          date: '2024-05-06',
          searchVolume: 6751,
          organicRank: 26,
          sponsoredRank: 80,
          keywordSales: 118
        },
        {
          date: '2024-05-05',
          searchVolume: 6751,
          organicRank: 8,
          sponsoredRank: 56,
          keywordSales: 118
        },
        {
          date: '2024-05-04',
          searchVolume: 6751,
          organicRank: 26,
          sponsoredRank: 23,
          keywordSales: 118
        },
        {
          date: '2024-05-03',
          searchVolume: 6440,
          organicRank: 26,
          sponsoredRank: 12,
          keywordSales: 118
        },
        {
          date: '2024-05-02',
          searchVolume: 6440,
          organicRank: 24,

          keywordSales: 118
        },
        {
          date: '2024-05-01',
          searchVolume: 6440,
          organicRank: 23,

          keywordSales: 118
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_full size keyboard',
      keyword: 'full size keyboard',
      searchVolume: 1886,
      organicStartRank: 35,
      organicHighestRank: 10,
      sponsoredStartRank: 37,
      sponsoredHighestRank: 13,
      organicPageOneWinDate: '2024-05-05',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1915,
          organicRank: 37,

          keywordSales: 3
        },
        {
          date: '2024-06-20',
          searchVolume: 1915,
          organicRank: 44,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 1915,
          organicRank: 37,

          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 1915,
          organicRank: 38,

          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 1915,
          organicRank: 35,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 1915,
          organicRank: 45,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 1915,
          organicRank: 42,

          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 1919,
          organicRank: 32,

          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 1919,
          organicRank: 41,

          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 1919,
          organicRank: 35,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 1919,
          organicRank: 52,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 1919,
          organicRank: 47,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 1919,
          organicRank: 45,

          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 1919,
          organicRank: 42,
          sponsoredRank: 79,
          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 1765,
          organicRank: 62,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 1765,
          organicRank: 55,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 1765,
          organicRank: 35,
          sponsoredRank: 70,
          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 1765,
          organicRank: 62,

          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 1765,
          organicRank: 60,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 1765,
          organicRank: 51,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 1765,
          organicRank: 65,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 1633,
          organicRank: 64,
          sponsoredRank: 13,
          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 1633,
          organicRank: 37,
          sponsoredRank: 32,
          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 1633,
          organicRank: 56,
          sponsoredRank: 51,
          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 1633,
          organicRank: 28,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 1633,
          organicRank: 26,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 1633,
          organicRank: 38,

          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 1633,
          organicRank: 42,

          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 1886,
          organicRank: 30,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 1886,
          organicRank: 27,

          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 1886,
          organicRank: 40,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 1886,
          organicRank: 35,
          sponsoredRank: 74,
          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 1886,
          organicRank: 31,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 1886,
          organicRank: 35,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 1886,
          organicRank: 31,

          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 1662,
          organicRank: 32,
          sponsoredRank: 53,
          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 1662,
          organicRank: 25,

          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 1662,
          organicRank: 24,

          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 1662,
          organicRank: 10,

          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 1662,
          organicRank: 10,

          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 1662,
          organicRank: 32,

          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 1662,
          organicRank: 32,

          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 1852,
          organicRank: 19,

          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 1852,
          organicRank: 43,

          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 1852,
          organicRank: 26,

          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 1852,
          organicRank: 26,
          sponsoredRank: 73,
          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 1852,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-05-05',
          searchVolume: 1852,
          organicRank: 17,

          keywordSales: 3
        },
        {
          date: '2024-05-04',
          searchVolume: 1852,
          organicRank: 35,
          sponsoredRank: 37,
          keywordSales: 3
        },
        {
          date: '2024-05-03',
          searchVolume: 1825,
          organicRank: 21,

          keywordSales: 3
        },
        {
          date: '2024-05-02',
          searchVolume: 1825,
          organicRank: 23,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_full size mechanical keyboard',
      keyword: 'full size mechanical keyboard',
      searchVolume: 2153,
      organicStartRank: 52,
      organicHighestRank: 18,
      sponsoredStartRank: 37,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-06-20',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2173,
          organicRank: 18,

          keywordSales: 23
        },
        {
          date: '2024-06-19',
          searchVolume: 2173,
          organicRank: 29,

          keywordSales: 23
        },
        {
          date: '2024-06-18',
          searchVolume: 2173,
          organicRank: 31,
          sponsoredRank: 68,
          keywordSales: 23
        },
        {
          date: '2024-06-17',
          searchVolume: 2173,
          organicRank: 29,

          keywordSales: 23
        },
        {
          date: '2024-06-16',
          searchVolume: 2173,
          organicRank: 43,

          keywordSales: 23
        },
        {
          date: '2024-06-15',
          searchVolume: 2173,
          organicRank: 31,

          keywordSales: 23
        },
        {
          date: '2024-06-14',
          searchVolume: 2251,
          organicRank: 24,

          keywordSales: 23
        },
        {
          date: '2024-06-13',
          searchVolume: 2251,
          organicRank: 30,

          keywordSales: 23
        },
        {
          date: '2024-06-12',
          searchVolume: 2251,
          organicRank: 31,

          keywordSales: 23
        },
        {
          date: '2024-06-11',
          searchVolume: 2251,
          organicRank: 31,

          keywordSales: 23
        },
        {
          date: '2024-06-10',
          searchVolume: 2251,
          organicRank: 28,

          keywordSales: 23
        },
        {
          date: '2024-06-09',
          searchVolume: 2251,
          organicRank: 33,

          keywordSales: 23
        },
        {
          date: '2024-06-08',
          searchVolume: 2251,
          organicRank: 24,
          sponsoredRank: 3,
          keywordSales: 23
        },
        {
          date: '2024-06-07',
          searchVolume: 1839,
          organicRank: 37,
          sponsoredRank: 78,
          keywordSales: 23
        },
        {
          date: '2024-06-06',
          searchVolume: 1839,
          organicRank: 37,

          keywordSales: 23
        },
        {
          date: '2024-06-05',
          searchVolume: 1839,
          organicRank: 43,
          sponsoredRank: 26,
          keywordSales: 23
        },
        {
          date: '2024-06-04',
          searchVolume: 1839,
          organicRank: 47,
          sponsoredRank: 36,
          keywordSales: 23
        },
        {
          date: '2024-06-03',
          searchVolume: 1839,
          organicRank: 41,

          keywordSales: 23
        },
        {
          date: '2024-06-02',
          searchVolume: 1839,
          organicRank: 31,

          keywordSales: 23
        },
        {
          date: '2024-06-01',
          searchVolume: 1839,
          organicRank: 42,
          sponsoredRank: 86,
          keywordSales: 23
        },
        {
          date: '2024-05-31',
          searchVolume: 1895,
          organicRank: 41,
          sponsoredRank: 7,
          keywordSales: 23
        },
        {
          date: '2024-05-30',
          searchVolume: 1895,
          organicRank: 51,

          keywordSales: 23
        },
        {
          date: '2024-05-29',
          searchVolume: 1895,
          organicRank: 37,

          keywordSales: 23
        },
        {
          date: '2024-05-28',
          searchVolume: 1895,
          organicRank: 46,

          keywordSales: 23
        },
        {
          date: '2024-05-27',
          searchVolume: 1895,
          organicRank: 40,

          keywordSales: 23
        },
        {
          date: '2024-05-26',
          searchVolume: 1895,
          organicRank: 45,

          keywordSales: 23
        },
        {
          date: '2024-05-25',
          searchVolume: 1895,
          organicRank: 44,

          keywordSales: 23
        },
        {
          date: '2024-05-24',
          searchVolume: 2153,
          organicRank: 24,

          keywordSales: 23
        },
        {
          date: '2024-05-23',
          searchVolume: 2153,
          organicRank: 34,

          keywordSales: 23
        },
        {
          date: '2024-05-22',
          searchVolume: 2153,
          organicRank: 30,

          keywordSales: 23
        },
        {
          date: '2024-05-21',
          searchVolume: 2153,
          organicRank: 41,

          keywordSales: 23
        },
        {
          date: '2024-05-20',
          searchVolume: 2153,
          organicRank: 42,

          keywordSales: 23
        },
        {
          date: '2024-05-19',
          searchVolume: 2153,
          organicRank: 44,

          keywordSales: 23
        },
        {
          date: '2024-05-18',
          searchVolume: 2153,
          organicRank: 44,

          keywordSales: 23
        },
        {
          date: '2024-05-17',
          searchVolume: 1896,
          organicRank: 53,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-05-16',
          searchVolume: 1896,
          organicRank: 48,

          keywordSales: 23
        },
        {
          date: '2024-05-15',
          searchVolume: 1896,
          organicRank: 55,

          keywordSales: 23
        },
        {
          date: '2024-05-14',
          searchVolume: 1896,
          organicRank: 59,

          keywordSales: 23
        },
        {
          date: '2024-05-13',
          searchVolume: 1896,
          organicRank: 33,

          keywordSales: 23
        },
        {
          date: '2024-05-12',
          searchVolume: 1896,
          organicRank: 38,

          keywordSales: 23
        },
        {
          date: '2024-05-11',
          searchVolume: 1896,
          organicRank: 49,

          keywordSales: 23
        },
        {
          date: '2024-05-10',
          searchVolume: 1917,
          organicRank: 60,

          keywordSales: 23
        },
        {
          date: '2024-05-09',
          searchVolume: 1917,
          organicRank: 46,

          keywordSales: 23
        },
        {
          date: '2024-05-08',
          searchVolume: 1917,
          organicRank: 55,

          keywordSales: 23
        },
        {
          date: '2024-05-07',
          searchVolume: 1917,
          organicRank: 27,

          keywordSales: 23
        },
        {
          date: '2024-05-06',
          searchVolume: 1917,
          organicRank: 45,
          sponsoredRank: 56,
          keywordSales: 23
        },
        {
          date: '2024-05-05',
          searchVolume: 1917,
          organicRank: 51,

          keywordSales: 23
        },
        {
          date: '2024-05-04',
          searchVolume: 1917,
          organicRank: 52,

          keywordSales: 23
        },
        {
          date: '2024-05-03',
          searchVolume: 2073,
          organicRank: 40,
          sponsoredRank: 37,
          keywordSales: 23
        },
        {
          date: '2024-05-02',
          searchVolume: 2073,
          organicRank: 47,

          keywordSales: 23
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gamer keyboard',
      keyword: 'gamer keyboard',
      searchVolume: 1418,
      organicStartRank: 260,
      organicHighestRank: 33,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1432,
          organicRank: 50,

          keywordSales: 15
        },
        {
          date: '2024-06-19',
          searchVolume: 1432,
          organicRank: 50,
          sponsoredRank: 62,
          keywordSales: 15
        },
        {
          date: '2024-06-18',
          searchVolume: 1432,
          organicRank: 52,
          sponsoredRank: 34,
          keywordSales: 15
        },
        {
          date: '2024-06-17',
          searchVolume: 1432,
          organicRank: 55,
          sponsoredRank: 13,
          keywordSales: 15
        },
        {
          date: '2024-06-16',
          searchVolume: 1432,
          organicRank: 53,

          keywordSales: 15
        },
        {
          date: '2024-06-15',
          searchVolume: 1432,
          organicRank: 48,
          sponsoredRank: 2,
          keywordSales: 15
        },
        {
          date: '2024-06-14',
          searchVolume: 1432,
          organicRank: 48,
          sponsoredRank: 25,
          keywordSales: 15
        },
        {
          date: '2024-06-13',
          searchVolume: 1432,
          organicRank: 46,

          keywordSales: 15
        },
        {
          date: '2024-06-12',
          searchVolume: 1432,
          organicRank: 39,

          keywordSales: 15
        },
        {
          date: '2024-06-11',
          searchVolume: 1432,
          organicRank: 40,

          keywordSales: 15
        },
        {
          date: '2024-06-10',
          searchVolume: 1432,
          organicRank: 40,

          keywordSales: 15
        },
        {
          date: '2024-06-09',
          searchVolume: 1432,
          organicRank: 40,
          sponsoredRank: 81,
          keywordSales: 15
        },
        {
          date: '2024-06-08',
          searchVolume: 1432,
          organicRank: 39,
          sponsoredRank: 6,
          keywordSales: 15
        },
        {
          date: '2024-06-07',
          searchVolume: 1365,
          organicRank: 40,

          keywordSales: 15
        },
        {
          date: '2024-06-06',
          searchVolume: 1365,
          organicRank: 36,

          keywordSales: 15
        },
        {
          date: '2024-06-05',
          searchVolume: 1365,
          organicRank: 39,
          sponsoredRank: 13,
          keywordSales: 15
        },
        {
          date: '2024-06-04',
          searchVolume: 1365,
          organicRank: 42,

          keywordSales: 15
        },
        {
          date: '2024-06-03',
          searchVolume: 1365,
          organicRank: 42,

          keywordSales: 15
        },
        {
          date: '2024-06-02',
          searchVolume: 1365,
          organicRank: 42,

          keywordSales: 15
        },
        {
          date: '2024-06-01',
          searchVolume: 1365,
          organicRank: 51,

          keywordSales: 15
        },
        {
          date: '2024-05-31',
          searchVolume: 1408,
          organicRank: 57,

          keywordSales: 15
        },
        {
          date: '2024-05-30',
          searchVolume: 1408,
          organicRank: 55,
          sponsoredRank: 15,
          keywordSales: 15
        },
        {
          date: '2024-05-29',
          searchVolume: 1408,
          organicRank: 46,

          keywordSales: 15
        },
        {
          date: '2024-05-28',
          searchVolume: 1408,
          organicRank: 37,

          keywordSales: 15
        },
        {
          date: '2024-05-27',
          searchVolume: 1408,
          organicRank: 41,

          keywordSales: 15
        },
        {
          date: '2024-05-26',
          searchVolume: 1408,
          organicRank: 37,
          sponsoredRank: 19,
          keywordSales: 15
        },
        {
          date: '2024-05-25',
          searchVolume: 1408,
          organicRank: 42,

          keywordSales: 15
        },
        {
          date: '2024-05-24',
          searchVolume: 1418,
          organicRank: 36,

          keywordSales: 15
        },
        {
          date: '2024-05-23',
          searchVolume: 1418,
          organicRank: 33,
          sponsoredRank: 2,
          keywordSales: 15
        },
        {
          date: '2024-05-22',
          searchVolume: 1418,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 15
        },
        {
          date: '2024-05-21',
          searchVolume: 1418,
          organicRank: 92,
          sponsoredRank: 48,
          keywordSales: 15
        },
        {
          date: '2024-05-20',
          searchVolume: 1418,
          organicRank: 87,
          sponsoredRank: 2,
          keywordSales: 15
        },
        {
          date: '2024-05-19',
          searchVolume: 1418,
          organicRank: 89,
          sponsoredRank: 1,
          keywordSales: 15
        },
        {
          date: '2024-05-18',
          searchVolume: 1418,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 15
        },
        {
          date: '2024-05-17',
          searchVolume: 1383,
          organicRank: 74,
          sponsoredRank: 31,
          keywordSales: 15
        },
        {
          date: '2024-05-16',
          searchVolume: 1383,
          organicRank: 94,

          keywordSales: 15
        },
        {
          date: '2024-05-15',
          searchVolume: 1383,
          organicRank: 306,

          keywordSales: 15
        },
        {
          date: '2024-05-14',
          searchVolume: 1383,
          organicRank: 202,

          keywordSales: 15
        },
        {
          date: '2024-05-13',
          searchVolume: 1383,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 15
        },
        {
          date: '2024-05-12',
          searchVolume: 1383,
          organicRank: 203,
          sponsoredRank: 29,
          keywordSales: 15
        },
        {
          date: '2024-05-11',
          searchVolume: 1383,
          organicRank: 168,
          sponsoredRank: 48,
          keywordSales: 15
        },
        {
          date: '2024-05-10',
          searchVolume: 1609,
          organicRank: 306,

          keywordSales: 15
        },
        {
          date: '2024-05-09',
          searchVolume: 1609,
          organicRank: 204,

          keywordSales: 15
        },
        {
          date: '2024-05-08',
          searchVolume: 1609,
          organicRank: 306,

          keywordSales: 15
        },
        {
          date: '2024-05-07',
          searchVolume: 1609,
          organicRank: 81,

          keywordSales: 15
        },
        {
          date: '2024-05-06',
          searchVolume: 1609,
          organicRank: 306,

          keywordSales: 15
        },
        {
          date: '2024-05-05',
          searchVolume: 1609,
          organicRank: 260,
          sponsoredRank: 13,
          keywordSales: 15
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming',
      keyword: 'gaming',
      searchVolume: 52738,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 70,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 53594,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 358
        },
        {
          date: '2024-06-20',
          searchVolume: 53594,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-19',
          searchVolume: 53594,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-18',
          searchVolume: 53594,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-17',
          searchVolume: 53594,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-16',
          searchVolume: 53594,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-15',
          searchVolume: 53594,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 358
        },
        {
          date: '2024-06-14',
          searchVolume: 52736,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-13',
          searchVolume: 52736,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-12',
          searchVolume: 52736,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-11',
          searchVolume: 52736,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-10',
          searchVolume: 52736,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-09',
          searchVolume: 52736,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-07',
          searchVolume: 50293,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-06',
          searchVolume: 50293,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-05',
          searchVolume: 50293,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-04',
          searchVolume: 50293,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-03',
          searchVolume: 50293,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-02',
          searchVolume: 50293,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-06-01',
          searchVolume: 50293,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-31',
          searchVolume: 51903,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-30',
          searchVolume: 51903,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 358
        },
        {
          date: '2024-05-29',
          searchVolume: 51903,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 358
        },
        {
          date: '2024-05-28',
          searchVolume: 51903,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-27',
          searchVolume: 51903,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-26',
          searchVolume: 51903,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-25',
          searchVolume: 51903,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-24',
          searchVolume: 52738,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-23',
          searchVolume: 52738,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-22',
          searchVolume: 52738,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-21',
          searchVolume: 52738,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-20',
          searchVolume: 52738,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-19',
          searchVolume: 52738,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-18',
          searchVolume: 52738,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-17',
          searchVolume: 37230,
          organicRank: 306,

          keywordSales: 358
        },
        {
          date: '2024-05-16',
          searchVolume: 37230,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 358
        },
        {
          date: '2024-05-15',
          searchVolume: 37230,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 358
        },
        {
          date: '2024-05-14',
          searchVolume: 37230,
          organicRank: 306,
          sponsoredRank: 69,
          keywordSales: 358
        },
        {
          date: '2024-05-13',
          searchVolume: 37230,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 358
        },
        {
          date: '2024-05-12',
          searchVolume: 37230,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 358
        },
        {
          date: '2024-05-11',
          searchVolume: 37230,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 358
        },
        {
          date: '2024-05-10',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 358
        },
        {
          date: '2024-05-09',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 358
        },
        {
          date: '2024-05-08',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 358
        },
        {
          date: '2024-05-07',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 34,
          keywordSales: 358
        },
        {
          date: '2024-05-06',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 70,
          keywordSales: 358
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming keyboard',
      keyword: 'gaming keyboard',
      searchVolume: 187716,
      organicStartRank: 23,
      organicHighestRank: 13,
      sponsoredStartRank: 57,
      sponsoredHighestRank: 5,
      organicPageOneWinDate: '2024-05-07',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 180659,
          organicRank: 31,
          sponsoredRank: 54,
          keywordSales: 2931
        },
        {
          date: '2024-06-19',
          searchVolume: 180659,
          organicRank: 30,
          sponsoredRank: 65,
          keywordSales: 2931
        },
        {
          date: '2024-06-18',
          searchVolume: 180659,
          organicRank: 38,
          sponsoredRank: 37,
          keywordSales: 2931
        },
        {
          date: '2024-06-17',
          searchVolume: 180659,
          organicRank: 24,
          sponsoredRank: 37,
          keywordSales: 2931
        },
        {
          date: '2024-06-16',
          searchVolume: 180659,
          organicRank: 26,
          sponsoredRank: 33,
          keywordSales: 2931
        },
        {
          date: '2024-06-15',
          searchVolume: 180659,
          organicRank: 31,
          sponsoredRank: 38,
          keywordSales: 2931
        },
        {
          date: '2024-06-14',
          searchVolume: 181939,
          organicRank: 26,
          sponsoredRank: 63,
          keywordSales: 2931
        },
        {
          date: '2024-06-13',
          searchVolume: 181939,
          organicRank: 28,

          keywordSales: 2931
        },
        {
          date: '2024-06-12',
          searchVolume: 181939,
          organicRank: 29,

          keywordSales: 2931
        },
        {
          date: '2024-06-11',
          searchVolume: 181939,
          organicRank: 31,

          keywordSales: 2931
        },
        {
          date: '2024-06-10',
          searchVolume: 181939,
          organicRank: 23,
          sponsoredRank: 63,
          keywordSales: 2931
        },
        {
          date: '2024-06-09',
          searchVolume: 181939,
          organicRank: 22,
          sponsoredRank: 17,
          keywordSales: 2931
        },
        {
          date: '2024-06-08',
          searchVolume: 181939,
          organicRank: 22,
          sponsoredRank: 63,
          keywordSales: 2931
        },
        {
          date: '2024-06-07',
          searchVolume: 173718,
          organicRank: 25,
          sponsoredRank: 82,
          keywordSales: 2931
        },
        {
          date: '2024-06-06',
          searchVolume: 173718,
          organicRank: 23,
          sponsoredRank: 61,
          keywordSales: 2931
        },
        {
          date: '2024-06-05',
          searchVolume: 173718,
          organicRank: 16,
          sponsoredRank: 52,
          keywordSales: 2931
        },
        {
          date: '2024-06-04',
          searchVolume: 173718,
          organicRank: 13,

          keywordSales: 2931
        },
        {
          date: '2024-06-03',
          searchVolume: 173718,
          organicRank: 25,

          keywordSales: 2931
        },
        {
          date: '2024-06-02',
          searchVolume: 173718,
          organicRank: 22,

          keywordSales: 2931
        },
        {
          date: '2024-06-01',
          searchVolume: 173718,
          organicRank: 24,
          sponsoredRank: 51,
          keywordSales: 2931
        },
        {
          date: '2024-05-31',
          searchVolume: 166351,
          organicRank: 23,
          sponsoredRank: 55,
          keywordSales: 2931
        },
        {
          date: '2024-05-30',
          searchVolume: 166351,
          organicRank: 22,

          keywordSales: 2931
        },
        {
          date: '2024-05-29',
          searchVolume: 166351,
          organicRank: 23,
          sponsoredRank: 87,
          keywordSales: 2931
        },
        {
          date: '2024-05-28',
          searchVolume: 166351,
          organicRank: 23,

          keywordSales: 2931
        },
        {
          date: '2024-05-27',
          searchVolume: 166351,
          organicRank: 31,
          sponsoredRank: 50,
          keywordSales: 2931
        },
        {
          date: '2024-05-26',
          searchVolume: 166351,
          organicRank: 29,
          sponsoredRank: 21,
          keywordSales: 2931
        },
        {
          date: '2024-05-25',
          searchVolume: 166351,
          organicRank: 32,
          sponsoredRank: 23,
          keywordSales: 2931
        },
        {
          date: '2024-05-24',
          searchVolume: 187716,
          organicRank: 34,
          sponsoredRank: 11,
          keywordSales: 2931
        },
        {
          date: '2024-05-23',
          searchVolume: 187716,
          organicRank: 23,
          sponsoredRank: 89,
          keywordSales: 2931
        },
        {
          date: '2024-05-22',
          searchVolume: 187716,
          organicRank: 26,
          sponsoredRank: 70,
          keywordSales: 2931
        },
        {
          date: '2024-05-21',
          searchVolume: 187716,
          organicRank: 16,
          sponsoredRank: 14,
          keywordSales: 2931
        },
        {
          date: '2024-05-20',
          searchVolume: 187716,
          organicRank: 30,

          keywordSales: 2931
        },
        {
          date: '2024-05-19',
          searchVolume: 187716,
          organicRank: 34,
          sponsoredRank: 27,
          keywordSales: 2931
        },
        {
          date: '2024-05-18',
          searchVolume: 187716,
          organicRank: 26,
          sponsoredRank: 18,
          keywordSales: 2931
        },
        {
          date: '2024-05-17',
          searchVolume: 188881,
          organicRank: 27,
          sponsoredRank: 39,
          keywordSales: 2931
        },
        {
          date: '2024-05-16',
          searchVolume: 188881,
          organicRank: 21,
          sponsoredRank: 11,
          keywordSales: 2931
        },
        {
          date: '2024-05-15',
          searchVolume: 188881,
          organicRank: 22,
          sponsoredRank: 6,
          keywordSales: 2931
        },
        {
          date: '2024-05-14',
          searchVolume: 188881,
          organicRank: 22,
          sponsoredRank: 6,
          keywordSales: 2931
        },
        {
          date: '2024-05-13',
          searchVolume: 188881,
          organicRank: 32,
          sponsoredRank: 5,
          keywordSales: 2931
        },
        {
          date: '2024-05-12',
          searchVolume: 188881,
          organicRank: 32,
          sponsoredRank: 5,
          keywordSales: 2931
        },
        {
          date: '2024-05-11',
          searchVolume: 188881,
          organicRank: 32,
          sponsoredRank: 66,
          keywordSales: 2931
        },
        {
          date: '2024-05-10',
          searchVolume: 189393,
          organicRank: 23,
          sponsoredRank: 51,
          keywordSales: 2931
        },
        {
          date: '2024-05-09',
          searchVolume: 189393,
          organicRank: 37,
          sponsoredRank: 67,
          keywordSales: 2931
        },
        {
          date: '2024-05-08',
          searchVolume: 189393,
          organicRank: 26,
          sponsoredRank: 60,
          keywordSales: 2931
        },
        {
          date: '2024-05-07',
          searchVolume: 189393,
          organicRank: 20,
          sponsoredRank: 67,
          keywordSales: 2931
        },
        {
          date: '2024-05-06',
          searchVolume: 189393,
          organicRank: 21,
          sponsoredRank: 16,
          keywordSales: 2931
        },
        {
          date: '2024-05-05',
          searchVolume: 189393,
          organicRank: 35,
          sponsoredRank: 9,
          keywordSales: 2931
        },
        {
          date: '2024-05-04',
          searchVolume: 189393,
          organicRank: 23,
          sponsoredRank: 57,
          keywordSales: 2931
        },
        {
          date: '2024-05-03',
          searchVolume: 192606,
          organicRank: 26,
          sponsoredRank: 6,
          keywordSales: 2931
        },
        {
          date: '2024-05-02',
          searchVolume: 192606,
          organicRank: 48,
          sponsoredRank: 10,
          keywordSales: 2931
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming keyboard 60',
      keyword: 'gaming keyboard 60',
      searchVolume: 386,
      organicStartRank: 9,
      organicHighestRank: 3,
      sponsoredStartRank: 57,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 310,
          organicRank: 18,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 310,
          organicRank: 16,
          sponsoredRank: 3,
          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 310,
          organicRank: 18,
          sponsoredRank: 34,
          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 310,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 310,
          organicRank: 17,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 310,
          organicRank: 18,
          sponsoredRank: 37,
          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 389,
          organicRank: 17,
          sponsoredRank: 35,
          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 389,
          organicRank: 13,

          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 389,
          organicRank: 12,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 389,
          organicRank: 9,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 389,
          organicRank: 19,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 389,
          organicRank: 7,
          sponsoredRank: 6,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 389,
          organicRank: 13,

          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 273,
          organicRank: 12,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 273,
          organicRank: 14,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 273,
          organicRank: 11,
          sponsoredRank: 1,
          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 273,
          organicRank: 14,
          sponsoredRank: 33,
          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 273,
          organicRank: 20,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 273,
          organicRank: 19,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 273,
          organicRank: 12,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 303,
          organicRank: 17,
          sponsoredRank: 13,
          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 303,
          organicRank: 18,
          sponsoredRank: 56,
          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 303,
          organicRank: 11,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 303,
          organicRank: 12,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 303,
          organicRank: 13,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 303,
          organicRank: 11,
          sponsoredRank: 59,
          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 303,
          organicRank: 8,

          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 386,
          organicRank: 8,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 386,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 386,
          organicRank: 9,
          sponsoredRank: 52,
          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 386,
          organicRank: 9,
          sponsoredRank: 73,
          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 386,
          organicRank: 10,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 386,
          organicRank: 9,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 386,
          organicRank: 20,
          sponsoredRank: 19,
          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 375,
          organicRank: 3,
          sponsoredRank: 8,
          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 375,
          organicRank: 7,

          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 375,
          organicRank: 10,
          sponsoredRank: 80,
          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 375,
          organicRank: 10,

          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 375,
          organicRank: 17,
          sponsoredRank: 14,
          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 375,
          organicRank: 18,

          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 375,
          organicRank: 24,

          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 312,
          organicRank: 17,

          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 312,
          organicRank: 14,

          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 312,
          organicRank: 16,
          sponsoredRank: 31,
          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 312,
          organicRank: 13,

          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 312,
          organicRank: 8,

          keywordSales: 3
        },
        {
          date: '2024-05-05',
          searchVolume: 312,
          organicRank: 8,

          keywordSales: 3
        },
        {
          date: '2024-05-04',
          searchVolume: 312,
          organicRank: 9,

          keywordSales: 3
        },
        {
          date: '2024-05-03',
          searchVolume: 410,
          organicRank: 8,
          sponsoredRank: 57,
          keywordSales: 3
        },
        {
          date: '2024-05-02',
          searchVolume: 410,
          organicRank: 7,

          keywordSales: 3
        },
        {
          date: '2024-05-01',
          searchVolume: 410,
          organicRank: 8,
          sponsoredRank: 25,
          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming keyboard 60 percent',
      keyword: 'gaming keyboard 60 percent',
      searchVolume: 6649,
      organicStartRank: 22,
      organicHighestRank: 14,
      sponsoredStartRank: 69,
      sponsoredHighestRank: 11,
      organicPageOneWinDate: '2024-05-05',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 6757,
          organicRank: 25,
          sponsoredRank: 32,
          keywordSales: 122
        },
        {
          date: '2024-06-19',
          searchVolume: 6757,
          organicRank: 25,
          sponsoredRank: 13,
          keywordSales: 122
        },
        {
          date: '2024-06-18',
          searchVolume: 6757,
          organicRank: 25,
          sponsoredRank: 31,
          keywordSales: 122
        },
        {
          date: '2024-06-17',
          searchVolume: 6757,
          organicRank: 25,
          sponsoredRank: 62,
          keywordSales: 122
        },
        {
          date: '2024-06-16',
          searchVolume: 6757,
          organicRank: 24,

          keywordSales: 122
        },
        {
          date: '2024-06-15',
          searchVolume: 6757,
          organicRank: 19,

          keywordSales: 122
        },
        {
          date: '2024-06-14',
          searchVolume: 6759,
          organicRank: 25,
          sponsoredRank: 52,
          keywordSales: 122
        },
        {
          date: '2024-06-13',
          searchVolume: 6759,
          organicRank: 23,

          keywordSales: 122
        },
        {
          date: '2024-06-12',
          searchVolume: 6759,
          organicRank: 19,

          keywordSales: 122
        },
        {
          date: '2024-06-11',
          searchVolume: 6759,
          organicRank: 14,

          keywordSales: 122
        },
        {
          date: '2024-06-10',
          searchVolume: 6759,
          organicRank: 15,

          keywordSales: 122
        },
        {
          date: '2024-06-09',
          searchVolume: 6759,
          organicRank: 23,

          keywordSales: 122
        },
        {
          date: '2024-06-08',
          searchVolume: 6759,
          organicRank: 23,

          keywordSales: 122
        },
        {
          date: '2024-06-07',
          searchVolume: 6952,
          organicRank: 22,

          keywordSales: 122
        },
        {
          date: '2024-06-06',
          searchVolume: 6952,
          organicRank: 24,

          keywordSales: 122
        },
        {
          date: '2024-06-05',
          searchVolume: 6952,
          organicRank: 17,
          sponsoredRank: 14,
          keywordSales: 122
        },
        {
          date: '2024-06-04',
          searchVolume: 6952,
          organicRank: 16,

          keywordSales: 122
        },
        {
          date: '2024-06-03',
          searchVolume: 6952,
          organicRank: 23,

          keywordSales: 122
        },
        {
          date: '2024-06-02',
          searchVolume: 6952,
          organicRank: 21,

          keywordSales: 122
        },
        {
          date: '2024-06-01',
          searchVolume: 6952,
          organicRank: 18,
          sponsoredRank: 46,
          keywordSales: 122
        },
        {
          date: '2024-05-31',
          searchVolume: 6649,
          organicRank: 24,
          sponsoredRank: 46,
          keywordSales: 122
        },
        {
          date: '2024-05-30',
          searchVolume: 6649,
          organicRank: 20,

          keywordSales: 122
        },
        {
          date: '2024-05-29',
          searchVolume: 6649,
          organicRank: 20,

          keywordSales: 122
        },
        {
          date: '2024-05-28',
          searchVolume: 6649,
          organicRank: 25,

          keywordSales: 122
        },
        {
          date: '2024-05-27',
          searchVolume: 6649,
          organicRank: 24,

          keywordSales: 122
        },
        {
          date: '2024-05-26',
          searchVolume: 6649,
          organicRank: 23,

          keywordSales: 122
        },
        {
          date: '2024-05-25',
          searchVolume: 6649,
          organicRank: 22,
          sponsoredRank: 34,
          keywordSales: 122
        },
        {
          date: '2024-05-24',
          searchVolume: 6649,
          organicRank: 20,

          keywordSales: 122
        },
        {
          date: '2024-05-23',
          searchVolume: 6649,
          organicRank: 21,

          keywordSales: 122
        },
        {
          date: '2024-05-22',
          searchVolume: 6649,
          organicRank: 24,

          keywordSales: 122
        },
        {
          date: '2024-05-21',
          searchVolume: 6649,
          organicRank: 16,
          sponsoredRank: 46,
          keywordSales: 122
        },
        {
          date: '2024-05-20',
          searchVolume: 6649,
          organicRank: 25,

          keywordSales: 122
        },
        {
          date: '2024-05-19',
          searchVolume: 6649,
          organicRank: 30,

          keywordSales: 122
        },
        {
          date: '2024-05-18',
          searchVolume: 6649,
          organicRank: 22,
          sponsoredRank: 11,
          keywordSales: 122
        },
        {
          date: '2024-05-17',
          searchVolume: 6644,
          organicRank: 21,
          sponsoredRank: 16,
          keywordSales: 122
        },
        {
          date: '2024-05-16',
          searchVolume: 6644,
          organicRank: 21,

          keywordSales: 122
        },
        {
          date: '2024-05-15',
          searchVolume: 6644,
          organicRank: 19,
          sponsoredRank: 49,
          keywordSales: 122
        },
        {
          date: '2024-05-14',
          searchVolume: 6644,
          organicRank: 32,

          keywordSales: 122
        },
        {
          date: '2024-05-13',
          searchVolume: 6644,
          organicRank: 28,
          sponsoredRank: 37,
          keywordSales: 122
        },
        {
          date: '2024-05-12',
          searchVolume: 6644,
          organicRank: 24,
          sponsoredRank: 53,
          keywordSales: 122
        },
        {
          date: '2024-05-11',
          searchVolume: 6644,
          organicRank: 19,
          sponsoredRank: 20,
          keywordSales: 122
        },
        {
          date: '2024-05-10',
          searchVolume: 7713,
          organicRank: 20,
          sponsoredRank: 21,
          keywordSales: 122
        },
        {
          date: '2024-05-09',
          searchVolume: 7713,
          organicRank: 19,
          sponsoredRank: 82,
          keywordSales: 122
        },
        {
          date: '2024-05-08',
          searchVolume: 7713,
          organicRank: 24,

          keywordSales: 122
        },
        {
          date: '2024-05-07',
          searchVolume: 7713,
          organicRank: 17,

          keywordSales: 122
        },
        {
          date: '2024-05-06',
          searchVolume: 7713,
          organicRank: 17,
          sponsoredRank: 55,
          keywordSales: 122
        },
        {
          date: '2024-05-05',
          searchVolume: 7713,
          organicRank: 19,
          sponsoredRank: 69,
          keywordSales: 122
        },
        {
          date: '2024-05-04',
          searchVolume: 7713,
          organicRank: 22,

          keywordSales: 122
        },
        {
          date: '2024-05-03',
          searchVolume: 8092,
          organicRank: 24,

          keywordSales: 122
        },
        {
          date: '2024-05-02',
          searchVolume: 8092,
          organicRank: 25,

          keywordSales: 122
        },
        {
          date: '2024-05-01',
          searchVolume: 8092,
          organicRank: 22,

          keywordSales: 122
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming keyboard 75 percent',
      keyword: 'gaming keyboard 75 percent',
      searchVolume: 1895,
      organicStartRank: 109,
      organicHighestRank: 44,
      sponsoredStartRank: 24,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1913,
          organicRank: 74,

          keywordSales: 20
        },
        {
          date: '2024-06-19',
          searchVolume: 1913,
          organicRank: 72,
          sponsoredRank: 40,
          keywordSales: 20
        },
        {
          date: '2024-06-18',
          searchVolume: 1913,
          organicRank: 80,
          sponsoredRank: 25,
          keywordSales: 20
        },
        {
          date: '2024-06-17',
          searchVolume: 1913,
          organicRank: 61,

          keywordSales: 20
        },
        {
          date: '2024-06-16',
          searchVolume: 1913,
          organicRank: 73,
          sponsoredRank: 31,
          keywordSales: 20
        },
        {
          date: '2024-06-15',
          searchVolume: 1913,
          organicRank: 72,
          sponsoredRank: 6,
          keywordSales: 20
        },
        {
          date: '2024-06-14',
          searchVolume: 1848,
          organicRank: 83,
          sponsoredRank: 20,
          keywordSales: 20
        },
        {
          date: '2024-06-13',
          searchVolume: 1848,
          organicRank: 75,

          keywordSales: 20
        },
        {
          date: '2024-06-12',
          searchVolume: 1848,
          organicRank: 80,

          keywordSales: 20
        },
        {
          date: '2024-06-11',
          searchVolume: 1848,
          organicRank: 70,

          keywordSales: 20
        },
        {
          date: '2024-06-10',
          searchVolume: 1848,
          organicRank: 87,

          keywordSales: 20
        },
        {
          date: '2024-06-09',
          searchVolume: 1848,
          organicRank: 89,
          sponsoredRank: 18,
          keywordSales: 20
        },
        {
          date: '2024-06-08',
          searchVolume: 1848,
          organicRank: 75,

          keywordSales: 20
        },
        {
          date: '2024-06-07',
          searchVolume: 1366,
          organicRank: 70,

          keywordSales: 20
        },
        {
          date: '2024-06-06',
          searchVolume: 1366,
          organicRank: 69,

          keywordSales: 20
        },
        {
          date: '2024-06-05',
          searchVolume: 1366,
          organicRank: 76,

          keywordSales: 20
        },
        {
          date: '2024-06-04',
          searchVolume: 1366,
          organicRank: 84,

          keywordSales: 20
        },
        {
          date: '2024-06-03',
          searchVolume: 1366,
          organicRank: 60,

          keywordSales: 20
        },
        {
          date: '2024-06-02',
          searchVolume: 1366,
          organicRank: 77,

          keywordSales: 20
        },
        {
          date: '2024-06-01',
          searchVolume: 1366,
          organicRank: 76,
          sponsoredRank: 83,
          keywordSales: 20
        },
        {
          date: '2024-05-31',
          searchVolume: 1392,
          organicRank: 77,
          sponsoredRank: 3,
          keywordSales: 20
        },
        {
          date: '2024-05-30',
          searchVolume: 1392,
          organicRank: 76,

          keywordSales: 20
        },
        {
          date: '2024-05-29',
          searchVolume: 1392,
          organicRank: 44,

          keywordSales: 20
        },
        {
          date: '2024-05-28',
          searchVolume: 1392,
          organicRank: 64,

          keywordSales: 20
        },
        {
          date: '2024-05-27',
          searchVolume: 1392,
          organicRank: 50,

          keywordSales: 20
        },
        {
          date: '2024-05-26',
          searchVolume: 1392,
          organicRank: 73,

          keywordSales: 20
        },
        {
          date: '2024-05-25',
          searchVolume: 1392,
          organicRank: 76,

          keywordSales: 20
        },
        {
          date: '2024-05-24',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-23',
          searchVolume: 1895,
          organicRank: 64,
          sponsoredRank: 12,
          keywordSales: 20
        },
        {
          date: '2024-05-22',
          searchVolume: 1895,
          organicRank: 85,
          sponsoredRank: 20,
          keywordSales: 20
        },
        {
          date: '2024-05-21',
          searchVolume: 1895,
          organicRank: 79,
          sponsoredRank: 4,
          keywordSales: 20
        },
        {
          date: '2024-05-20',
          searchVolume: 1895,
          organicRank: 75,

          keywordSales: 20
        },
        {
          date: '2024-05-19',
          searchVolume: 1895,
          organicRank: 87,

          keywordSales: 20
        },
        {
          date: '2024-05-18',
          searchVolume: 1895,
          organicRank: 79,
          sponsoredRank: 43,
          keywordSales: 20
        },
        {
          date: '2024-05-17',
          searchVolume: 1631,
          organicRank: 79,
          sponsoredRank: 4,
          keywordSales: 20
        },
        {
          date: '2024-05-16',
          searchVolume: 1631,
          organicRank: 78,
          sponsoredRank: 4,
          keywordSales: 20
        },
        {
          date: '2024-05-15',
          searchVolume: 1631,
          organicRank: 79,

          keywordSales: 20
        },
        {
          date: '2024-05-14',
          searchVolume: 1631,
          organicRank: 85,
          sponsoredRank: 3,
          keywordSales: 20
        },
        {
          date: '2024-05-13',
          searchVolume: 1631,
          organicRank: 86,
          sponsoredRank: 14,
          keywordSales: 20
        },
        {
          date: '2024-05-12',
          searchVolume: 1631,
          organicRank: 87,
          sponsoredRank: 6,
          keywordSales: 20
        },
        {
          date: '2024-05-11',
          searchVolume: 1631,
          organicRank: 75,
          sponsoredRank: 6,
          keywordSales: 20
        },
        {
          date: '2024-05-10',
          searchVolume: 1664,
          organicRank: 79,

          keywordSales: 20
        },
        {
          date: '2024-05-09',
          searchVolume: 1664,
          organicRank: 90,

          keywordSales: 20
        },
        {
          date: '2024-05-08',
          searchVolume: 1664,
          organicRank: 92,
          sponsoredRank: 17,
          keywordSales: 20
        },
        {
          date: '2024-05-07',
          searchVolume: 1664,
          organicRank: 103,
          sponsoredRank: 30,
          keywordSales: 20
        },
        {
          date: '2024-05-06',
          searchVolume: 1664,
          organicRank: 101,

          keywordSales: 20
        },
        {
          date: '2024-05-05',
          searchVolume: 1664,
          organicRank: 105,

          keywordSales: 20
        },
        {
          date: '2024-05-04',
          searchVolume: 1664,
          organicRank: 109,
          sponsoredRank: 24,
          keywordSales: 20
        },
        {
          date: '2024-05-03',
          searchVolume: 1839,
          organicRank: 105,
          sponsoredRank: 36,
          keywordSales: 20
        },
        {
          date: '2024-05-02',
          searchVolume: 1839,
          organicRank: 98,

          keywordSales: 20
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming keyboard and mouse',
      keyword: 'gaming keyboard and mouse',
      searchVolume: 20618,
      organicStartRank: 43,
      organicHighestRank: 40,
      sponsoredStartRank: 77,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-16',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 22067,
          organicRank: 306,

          keywordSales: 438
        },
        {
          date: '2024-06-19',
          searchVolume: 22067,
          organicRank: 306,
          sponsoredRank: 84,
          keywordSales: 438
        },
        {
          date: '2024-06-18',
          searchVolume: 22067,
          organicRank: 154,
          sponsoredRank: 35,
          keywordSales: 438
        },
        {
          date: '2024-06-17',
          searchVolume: 22067,
          organicRank: 141,
          sponsoredRank: 68,
          keywordSales: 438
        },
        {
          date: '2024-06-16',
          searchVolume: 22067,
          organicRank: 133,

          keywordSales: 438
        },
        {
          date: '2024-06-15',
          searchVolume: 22067,
          organicRank: 136,
          sponsoredRank: 71,
          keywordSales: 438
        },
        {
          date: '2024-06-14',
          searchVolume: 22061,
          organicRank: 176,
          sponsoredRank: 12,
          keywordSales: 438
        },
        {
          date: '2024-06-13',
          searchVolume: 22061,
          organicRank: 142,

          keywordSales: 438
        },
        {
          date: '2024-06-12',
          searchVolume: 22061,
          organicRank: 306,

          keywordSales: 438
        },
        {
          date: '2024-06-11',
          searchVolume: 22061,
          organicRank: 189,

          keywordSales: 438
        },
        {
          date: '2024-06-10',
          searchVolume: 22061,
          organicRank: 137,

          keywordSales: 438
        },
        {
          date: '2024-06-09',
          searchVolume: 22061,
          organicRank: 143,

          keywordSales: 438
        },
        {
          date: '2024-06-08',
          searchVolume: 22061,
          organicRank: 219,
          sponsoredRank: 77,
          keywordSales: 438
        },
        {
          date: '2024-06-07',
          searchVolume: 21036,
          organicRank: 159,

          keywordSales: 438
        },
        {
          date: '2024-06-06',
          searchVolume: 21036,
          organicRank: 164,

          keywordSales: 438
        },
        {
          date: '2024-06-05',
          searchVolume: 21036,
          organicRank: 137,
          sponsoredRank: 1,
          keywordSales: 438
        },
        {
          date: '2024-06-04',
          searchVolume: 21036,
          organicRank: 156,

          keywordSales: 438
        },
        {
          date: '2024-06-03',
          searchVolume: 21036,
          organicRank: 169,

          keywordSales: 438
        },
        {
          date: '2024-06-02',
          searchVolume: 21036,
          organicRank: 143,

          keywordSales: 438
        },
        {
          date: '2024-06-01',
          searchVolume: 21036,
          organicRank: 306,
          sponsoredRank: 54,
          keywordSales: 438
        },
        {
          date: '2024-05-31',
          searchVolume: 20623,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 438
        },
        {
          date: '2024-05-30',
          searchVolume: 20623,
          organicRank: 306,

          keywordSales: 438
        },
        {
          date: '2024-05-29',
          searchVolume: 20623,
          organicRank: 157,
          sponsoredRank: 24,
          keywordSales: 438
        },
        {
          date: '2024-05-28',
          searchVolume: 20623,
          organicRank: 145,

          keywordSales: 438
        },
        {
          date: '2024-05-27',
          searchVolume: 20623,
          organicRank: 306,

          keywordSales: 438
        },
        {
          date: '2024-05-26',
          searchVolume: 20623,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 438
        },
        {
          date: '2024-05-25',
          searchVolume: 20623,
          organicRank: 306,
          sponsoredRank: 53,
          keywordSales: 438
        },
        {
          date: '2024-05-24',
          searchVolume: 20618,
          organicRank: 244,

          keywordSales: 438
        },
        {
          date: '2024-05-23',
          searchVolume: 20618,
          organicRank: 168,

          keywordSales: 438
        },
        {
          date: '2024-05-22',
          searchVolume: 20618,
          organicRank: 211,

          keywordSales: 438
        },
        {
          date: '2024-05-21',
          searchVolume: 20618,
          organicRank: 306,

          keywordSales: 438
        },
        {
          date: '2024-05-20',
          searchVolume: 20618,
          organicRank: 195,

          keywordSales: 438
        },
        {
          date: '2024-05-19',
          searchVolume: 20618,
          organicRank: 240,

          keywordSales: 438
        },
        {
          date: '2024-05-18',
          searchVolume: 20618,
          organicRank: 264,

          keywordSales: 438
        },
        {
          date: '2024-05-17',
          searchVolume: 21688,
          organicRank: 218,
          sponsoredRank: 35,
          keywordSales: 438
        },
        {
          date: '2024-05-16',
          searchVolume: 21688,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 438
        },
        {
          date: '2024-05-15',
          searchVolume: 21688,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 438
        },
        {
          date: '2024-05-14',
          searchVolume: 21688,
          organicRank: 306,
          sponsoredRank: 91,
          keywordSales: 438
        },
        {
          date: '2024-05-13',
          searchVolume: 21688,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 438
        },
        {
          date: '2024-05-12',
          searchVolume: 21688,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 438
        },
        {
          date: '2024-05-11',
          searchVolume: 21688,
          organicRank: 306,
          sponsoredRank: 52,
          keywordSales: 438
        },
        {
          date: '2024-05-10',
          searchVolume: 24827,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 438
        },
        {
          date: '2024-05-09',
          searchVolume: 24827,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 438
        },
        {
          date: '2024-05-08',
          searchVolume: 24827,
          organicRank: 306,
          sponsoredRank: 89,
          keywordSales: 438
        },
        {
          date: '2024-05-07',
          searchVolume: 24827,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 438
        },
        {
          date: '2024-05-06',
          searchVolume: 24827,
          organicRank: 306,

          keywordSales: 438
        },
        {
          date: '2024-05-05',
          searchVolume: 24827,
          organicRank: 40,

          keywordSales: 438
        },
        {
          date: '2024-05-04',
          searchVolume: 24827,
          organicRank: 43,
          sponsoredRank: 77,
          keywordSales: 438
        },
        {
          date: '2024-05-02',
          searchVolume: 23759,
          organicRank: 42,
          sponsoredRank: 6,
          keywordSales: 438
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming keyboard mechanical',
      keyword: 'gaming keyboard mechanical',
      searchVolume: 1408,
      organicStartRank: 51,
      organicHighestRank: 20,
      sponsoredStartRank: 44,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-06-02',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2036,
          organicRank: 38,
          sponsoredRank: 84,
          keywordSales: 7
        },
        {
          date: '2024-06-19',
          searchVolume: 2036,
          organicRank: 37,
          sponsoredRank: 33,
          keywordSales: 7
        },
        {
          date: '2024-06-18',
          searchVolume: 2036,
          organicRank: 37,
          sponsoredRank: 35,
          keywordSales: 7
        },
        {
          date: '2024-06-17',
          searchVolume: 2036,
          organicRank: 36,
          sponsoredRank: 23,
          keywordSales: 7
        },
        {
          date: '2024-06-16',
          searchVolume: 2036,
          organicRank: 38,

          keywordSales: 7
        },
        {
          date: '2024-06-15',
          searchVolume: 2036,
          organicRank: 38,
          sponsoredRank: 5,
          keywordSales: 7
        },
        {
          date: '2024-06-14',
          searchVolume: 1850,
          organicRank: 38,
          sponsoredRank: 13,
          keywordSales: 7
        },
        {
          date: '2024-06-13',
          searchVolume: 1850,
          organicRank: 42,
          sponsoredRank: 36,
          keywordSales: 7
        },
        {
          date: '2024-06-12',
          searchVolume: 1850,
          organicRank: 31,

          keywordSales: 7
        },
        {
          date: '2024-06-11',
          searchVolume: 1850,
          organicRank: 32,

          keywordSales: 7
        },
        {
          date: '2024-06-10',
          searchVolume: 1850,
          organicRank: 41,

          keywordSales: 7
        },
        {
          date: '2024-06-09',
          searchVolume: 1850,
          organicRank: 32,
          sponsoredRank: 92,
          keywordSales: 7
        },
        {
          date: '2024-06-08',
          searchVolume: 1850,
          organicRank: 32,
          sponsoredRank: 3,
          keywordSales: 7
        },
        {
          date: '2024-06-07',
          searchVolume: 1366,
          organicRank: 37,

          keywordSales: 7
        },
        {
          date: '2024-06-06',
          searchVolume: 1366,
          organicRank: 40,

          keywordSales: 7
        },
        {
          date: '2024-06-05',
          searchVolume: 1366,
          organicRank: 33,
          sponsoredRank: 62,
          keywordSales: 7
        },
        {
          date: '2024-06-04',
          searchVolume: 1366,
          organicRank: 52,

          keywordSales: 7
        },
        {
          date: '2024-06-03',
          searchVolume: 1366,
          organicRank: 30,

          keywordSales: 7
        },
        {
          date: '2024-06-02',
          searchVolume: 1366,
          organicRank: 20,

          keywordSales: 7
        },
        {
          date: '2024-06-01',
          searchVolume: 1366,
          organicRank: 42,
          sponsoredRank: 11,
          keywordSales: 7
        },
        {
          date: '2024-05-31',
          searchVolume: 1408,
          organicRank: 37,
          sponsoredRank: 69,
          keywordSales: 7
        },
        {
          date: '2024-05-30',
          searchVolume: 1408,
          organicRank: 29,
          sponsoredRank: 10,
          keywordSales: 7
        },
        {
          date: '2024-05-29',
          searchVolume: 1408,
          organicRank: 45,

          keywordSales: 7
        },
        {
          date: '2024-05-28',
          searchVolume: 1408,
          organicRank: 56,

          keywordSales: 7
        },
        {
          date: '2024-05-27',
          searchVolume: 1408,
          organicRank: 42,
          sponsoredRank: 72,
          keywordSales: 7
        },
        {
          date: '2024-05-26',
          searchVolume: 1408,
          organicRank: 44,
          sponsoredRank: 42,
          keywordSales: 7
        },
        {
          date: '2024-05-25',
          searchVolume: 1408,
          organicRank: 45,

          keywordSales: 7
        },
        {
          date: '2024-05-24',
          searchVolume: 1408,
          organicRank: 39,

          keywordSales: 7
        },
        {
          date: '2024-05-23',
          searchVolume: 1408,
          organicRank: 39,
          sponsoredRank: 56,
          keywordSales: 7
        },
        {
          date: '2024-05-22',
          searchVolume: 1408,
          organicRank: 40,
          sponsoredRank: 52,
          keywordSales: 7
        },
        {
          date: '2024-05-21',
          searchVolume: 1408,
          organicRank: 41,
          sponsoredRank: 16,
          keywordSales: 7
        },
        {
          date: '2024-05-20',
          searchVolume: 1408,
          organicRank: 45,

          keywordSales: 7
        },
        {
          date: '2024-05-19',
          searchVolume: 1408,
          organicRank: 46,

          keywordSales: 7
        },
        {
          date: '2024-05-18',
          searchVolume: 1408,
          organicRank: 41,
          sponsoredRank: 25,
          keywordSales: 7
        },
        {
          date: '2024-05-17',
          searchVolume: 1328,
          organicRank: 50,
          sponsoredRank: 9,
          keywordSales: 7
        },
        {
          date: '2024-05-16',
          searchVolume: 1328,
          organicRank: 44,
          sponsoredRank: 72,
          keywordSales: 7
        },
        {
          date: '2024-05-15',
          searchVolume: 1328,
          organicRank: 53,
          sponsoredRank: 45,
          keywordSales: 7
        },
        {
          date: '2024-05-14',
          searchVolume: 1328,
          organicRank: 54,
          sponsoredRank: 44,
          keywordSales: 7
        },
        {
          date: '2024-05-13',
          searchVolume: 1328,
          organicRank: 33,
          sponsoredRank: 73,
          keywordSales: 7
        },
        {
          date: '2024-05-12',
          searchVolume: 1328,
          organicRank: 65,
          sponsoredRank: 54,
          keywordSales: 7
        },
        {
          date: '2024-05-11',
          searchVolume: 1328,
          organicRank: 59,
          sponsoredRank: 72,
          keywordSales: 7
        },
        {
          date: '2024-05-10',
          searchVolume: 1432,
          organicRank: 44,
          sponsoredRank: 65,
          keywordSales: 7
        },
        {
          date: '2024-05-09',
          searchVolume: 1432,
          organicRank: 45,

          keywordSales: 7
        },
        {
          date: '2024-05-08',
          searchVolume: 1432,
          organicRank: 40,
          sponsoredRank: 52,
          keywordSales: 7
        },
        {
          date: '2024-05-07',
          searchVolume: 1432,
          organicRank: 22,

          keywordSales: 7
        },
        {
          date: '2024-05-06',
          searchVolume: 1432,
          organicRank: 41,

          keywordSales: 7
        },
        {
          date: '2024-05-05',
          searchVolume: 1432,
          organicRank: 51,

          keywordSales: 7
        },
        {
          date: '2024-05-04',
          searchVolume: 1432,
          organicRank: 51,

          keywordSales: 7
        },
        {
          date: '2024-05-03',
          searchVolume: 1365,
          organicRank: 61,

          keywordSales: 7
        },
        {
          date: '2024-05-02',
          searchVolume: 1365,
          organicRank: 39,
          sponsoredRank: 44,
          keywordSales: 7
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming keyboard wireless',
      keyword: 'gaming keyboard wireless',
      searchVolume: 5867,
      organicStartRank: 189,
      organicHighestRank: 189,
      sponsoredStartRank: 71,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-10',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 4918,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-20',
          searchVolume: 4918,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-19',
          searchVolume: 4918,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-18',
          searchVolume: 4918,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 42
        },
        {
          date: '2024-06-17',
          searchVolume: 4918,
          organicRank: 206,

          keywordSales: 42
        },
        {
          date: '2024-06-16',
          searchVolume: 4918,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-15',
          searchVolume: 4918,
          organicRank: 251,
          sponsoredRank: 78,
          keywordSales: 42
        },
        {
          date: '2024-06-14',
          searchVolume: 5143,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 42
        },
        {
          date: '2024-06-13',
          searchVolume: 5143,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-12',
          searchVolume: 5143,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-11',
          searchVolume: 5143,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-10',
          searchVolume: 5143,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-09',
          searchVolume: 5143,
          organicRank: 306,
          sponsoredRank: 2,
          keywordSales: 42
        },
        {
          date: '2024-06-08',
          searchVolume: 5143,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-07',
          searchVolume: 4341,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-06',
          searchVolume: 4341,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-05',
          searchVolume: 4341,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-04',
          searchVolume: 4341,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-03',
          searchVolume: 4341,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-02',
          searchVolume: 4341,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-06-01',
          searchVolume: 4341,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-31',
          searchVolume: 4444,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 42
        },
        {
          date: '2024-05-30',
          searchVolume: 4444,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-29',
          searchVolume: 4444,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-28',
          searchVolume: 4444,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-27',
          searchVolume: 4444,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-26',
          searchVolume: 4444,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 42
        },
        {
          date: '2024-05-25',
          searchVolume: 4444,
          organicRank: 212,

          keywordSales: 42
        },
        {
          date: '2024-05-24',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-23',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-22',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-21',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-20',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-19',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 42
        },
        {
          date: '2024-05-18',
          searchVolume: 5867,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 42
        },
        {
          date: '2024-05-17',
          searchVolume: 5058,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 42
        },
        {
          date: '2024-05-16',
          searchVolume: 5058,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 42
        },
        {
          date: '2024-05-15',
          searchVolume: 5058,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 42
        },
        {
          date: '2024-05-14',
          searchVolume: 5058,
          organicRank: 306,
          sponsoredRank: 91,
          keywordSales: 42
        },
        {
          date: '2024-05-13',
          searchVolume: 5058,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 42
        },
        {
          date: '2024-05-12',
          searchVolume: 5058,
          organicRank: 306,
          sponsoredRank: 16,
          keywordSales: 42
        },
        {
          date: '2024-05-11',
          searchVolume: 5058,
          organicRank: 306,
          sponsoredRank: 78,
          keywordSales: 42
        },
        {
          date: '2024-05-10',
          searchVolume: 4716,
          organicRank: 306,
          sponsoredRank: 18,
          keywordSales: 42
        },
        {
          date: '2024-05-09',
          searchVolume: 4716,
          organicRank: 306,
          sponsoredRank: 41,
          keywordSales: 42
        },
        {
          date: '2024-05-08',
          searchVolume: 4716,
          organicRank: 306,
          sponsoredRank: 92,
          keywordSales: 42
        },
        {
          date: '2024-05-07',
          searchVolume: 4716,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 42
        },
        {
          date: '2024-05-06',
          searchVolume: 4716,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 42
        },
        {
          date: '2024-05-05',
          searchVolume: 4716,
          organicRank: 233,

          keywordSales: 42
        },
        {
          date: '2024-05-04',
          searchVolume: 4716,
          organicRank: 189,

          keywordSales: 42
        },
        {
          date: '2024-05-02',
          searchVolume: 5159,
          organicRank: 241,

          keywordSales: 42
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming keyboards',
      keyword: 'gaming keyboards',
      searchVolume: 4171,
      organicStartRank: 36,
      organicHighestRank: 24,
      sponsoredStartRank: 64,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 4333,
          organicRank: 39,
          sponsoredRank: 28,
          keywordSales: 51
        },
        {
          date: '2024-06-19',
          searchVolume: 4333,
          organicRank: 37,
          sponsoredRank: 32,
          keywordSales: 51
        },
        {
          date: '2024-06-18',
          searchVolume: 4333,
          organicRank: 34,
          sponsoredRank: 30,
          keywordSales: 51
        },
        {
          date: '2024-06-17',
          searchVolume: 4333,
          organicRank: 30,

          keywordSales: 51
        },
        {
          date: '2024-06-16',
          searchVolume: 4333,
          organicRank: 29,
          sponsoredRank: 39,
          keywordSales: 51
        },
        {
          date: '2024-06-15',
          searchVolume: 4333,
          organicRank: 30,
          sponsoredRank: 43,
          keywordSales: 51
        },
        {
          date: '2024-06-14',
          searchVolume: 4365,
          organicRank: 31,
          sponsoredRank: 25,
          keywordSales: 51
        },
        {
          date: '2024-06-13',
          searchVolume: 4365,
          organicRank: 27,

          keywordSales: 51
        },
        {
          date: '2024-06-12',
          searchVolume: 4365,
          organicRank: 28,

          keywordSales: 51
        },
        {
          date: '2024-06-11',
          searchVolume: 4365,
          organicRank: 29,

          keywordSales: 51
        },
        {
          date: '2024-06-10',
          searchVolume: 4365,
          organicRank: 27,

          keywordSales: 51
        },
        {
          date: '2024-06-09',
          searchVolume: 4365,
          organicRank: 66,
          sponsoredRank: 43,
          keywordSales: 51
        },
        {
          date: '2024-06-08',
          searchVolume: 4365,
          organicRank: 28,
          sponsoredRank: 39,
          keywordSales: 51
        },
        {
          date: '2024-06-07',
          searchVolume: 4123,
          organicRank: 32,
          sponsoredRank: 40,
          keywordSales: 51
        },
        {
          date: '2024-06-06',
          searchVolume: 4123,
          organicRank: 24,

          keywordSales: 51
        },
        {
          date: '2024-06-05',
          searchVolume: 4123,
          organicRank: 32,
          sponsoredRank: 91,
          keywordSales: 51
        },
        {
          date: '2024-06-04',
          searchVolume: 4123,
          organicRank: 36,

          keywordSales: 51
        },
        {
          date: '2024-06-03',
          searchVolume: 4123,
          organicRank: 35,

          keywordSales: 51
        },
        {
          date: '2024-06-02',
          searchVolume: 4123,
          organicRank: 58,
          sponsoredRank: 41,
          keywordSales: 51
        },
        {
          date: '2024-06-01',
          searchVolume: 4123,
          organicRank: 62,
          sponsoredRank: 46,
          keywordSales: 51
        },
        {
          date: '2024-05-31',
          searchVolume: 3758,
          organicRank: 43,
          sponsoredRank: 70,
          keywordSales: 51
        },
        {
          date: '2024-05-30',
          searchVolume: 3758,
          organicRank: 37,

          keywordSales: 51
        },
        {
          date: '2024-05-29',
          searchVolume: 3758,
          organicRank: 46,
          sponsoredRank: 73,
          keywordSales: 51
        },
        {
          date: '2024-05-28',
          searchVolume: 3758,
          organicRank: 38,
          sponsoredRank: 74,
          keywordSales: 51
        },
        {
          date: '2024-05-27',
          searchVolume: 3758,
          organicRank: 36,
          sponsoredRank: 38,
          keywordSales: 51
        },
        {
          date: '2024-05-26',
          searchVolume: 3758,
          organicRank: 41,
          sponsoredRank: 14,
          keywordSales: 51
        },
        {
          date: '2024-05-25',
          searchVolume: 3758,
          organicRank: 38,
          sponsoredRank: 14,
          keywordSales: 51
        },
        {
          date: '2024-05-24',
          searchVolume: 4171,
          organicRank: 38,
          sponsoredRank: 19,
          keywordSales: 51
        },
        {
          date: '2024-05-23',
          searchVolume: 4171,
          organicRank: 35,
          sponsoredRank: 41,
          keywordSales: 51
        },
        {
          date: '2024-05-22',
          searchVolume: 4171,
          organicRank: 66,

          keywordSales: 51
        },
        {
          date: '2024-05-21',
          searchVolume: 4171,
          organicRank: 35,
          sponsoredRank: 49,
          keywordSales: 51
        },
        {
          date: '2024-05-20',
          searchVolume: 4171,
          organicRank: 30,
          sponsoredRank: 48,
          keywordSales: 51
        },
        {
          date: '2024-05-19',
          searchVolume: 4171,
          organicRank: 41,

          keywordSales: 51
        },
        {
          date: '2024-05-18',
          searchVolume: 4171,
          organicRank: 30,
          sponsoredRank: 27,
          keywordSales: 51
        },
        {
          date: '2024-05-17',
          searchVolume: 4249,
          organicRank: 34,
          sponsoredRank: 18,
          keywordSales: 51
        },
        {
          date: '2024-05-16',
          searchVolume: 4249,
          organicRank: 34,
          sponsoredRank: 7,
          keywordSales: 51
        },
        {
          date: '2024-05-15',
          searchVolume: 4249,
          organicRank: 30,
          sponsoredRank: 46,
          keywordSales: 51
        },
        {
          date: '2024-05-14',
          searchVolume: 4249,
          organicRank: 36,
          sponsoredRank: 11,
          keywordSales: 51
        },
        {
          date: '2024-05-13',
          searchVolume: 4249,
          organicRank: 33,
          sponsoredRank: 24,
          keywordSales: 51
        },
        {
          date: '2024-05-12',
          searchVolume: 4249,
          organicRank: 36,
          sponsoredRank: 10,
          keywordSales: 51
        },
        {
          date: '2024-05-11',
          searchVolume: 4249,
          organicRank: 40,
          sponsoredRank: 10,
          keywordSales: 51
        },
        {
          date: '2024-05-10',
          searchVolume: 4322,
          organicRank: 30,
          sponsoredRank: 21,
          keywordSales: 51
        },
        {
          date: '2024-05-09',
          searchVolume: 4322,
          organicRank: 26,
          sponsoredRank: 67,
          keywordSales: 51
        },
        {
          date: '2024-05-08',
          searchVolume: 4322,
          organicRank: 35,
          sponsoredRank: 69,
          keywordSales: 51
        },
        {
          date: '2024-05-07',
          searchVolume: 4322,
          organicRank: 32,
          sponsoredRank: 73,
          keywordSales: 51
        },
        {
          date: '2024-05-06',
          searchVolume: 4322,
          organicRank: 35,
          sponsoredRank: 63,
          keywordSales: 51
        },
        {
          date: '2024-05-05',
          searchVolume: 4322,
          organicRank: 27,
          sponsoredRank: 73,
          keywordSales: 51
        },
        {
          date: '2024-05-04',
          searchVolume: 4322,
          organicRank: 36,

          keywordSales: 51
        },
        {
          date: '2024-05-03',
          searchVolume: 4170,
          organicRank: 19,
          sponsoredRank: 64,
          keywordSales: 51
        },
        {
          date: '2024-05-02',
          searchVolume: 4170,
          organicRank: 39,
          sponsoredRank: 55,
          keywordSales: 51
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming keypad',
      keyword: 'gaming keypad',
      searchVolume: 2394,
      organicStartRank: 107,
      organicHighestRank: 72,
      sponsoredStartRank: 20,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2548,
          organicRank: 177,

          keywordSales: 28
        },
        {
          date: '2024-06-19',
          searchVolume: 2548,
          organicRank: 165,
          sponsoredRank: 86,
          keywordSales: 28
        },
        {
          date: '2024-06-18',
          searchVolume: 2548,
          organicRank: 147,
          sponsoredRank: 32,
          keywordSales: 28
        },
        {
          date: '2024-06-17',
          searchVolume: 2548,
          organicRank: 138,
          sponsoredRank: 36,
          keywordSales: 28
        },
        {
          date: '2024-06-16',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 28
        },
        {
          date: '2024-06-15',
          searchVolume: 2548,
          organicRank: 152,
          sponsoredRank: 12,
          keywordSales: 28
        },
        {
          date: '2024-06-14',
          searchVolume: 2548,
          organicRank: 198,
          sponsoredRank: 50,
          keywordSales: 28
        },
        {
          date: '2024-06-13',
          searchVolume: 2548,
          organicRank: 147,
          sponsoredRank: 7,
          keywordSales: 28
        },
        {
          date: '2024-06-12',
          searchVolume: 2548,
          organicRank: 147,

          keywordSales: 28
        },
        {
          date: '2024-06-11',
          searchVolume: 2548,
          organicRank: 131,

          keywordSales: 28
        },
        {
          date: '2024-06-10',
          searchVolume: 2548,
          organicRank: 211,

          keywordSales: 28
        },
        {
          date: '2024-06-09',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 28
        },
        {
          date: '2024-06-08',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 28
        },
        {
          date: '2024-06-07',
          searchVolume: 2429,
          organicRank: 204,

          keywordSales: 28
        },
        {
          date: '2024-06-06',
          searchVolume: 2429,
          organicRank: 273,

          keywordSales: 28
        },
        {
          date: '2024-06-05',
          searchVolume: 2429,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 28
        },
        {
          date: '2024-06-04',
          searchVolume: 2429,
          organicRank: 227,

          keywordSales: 28
        },
        {
          date: '2024-06-03',
          searchVolume: 2429,
          organicRank: 212,

          keywordSales: 28
        },
        {
          date: '2024-06-02',
          searchVolume: 2429,
          organicRank: 306,

          keywordSales: 28
        },
        {
          date: '2024-06-01',
          searchVolume: 2429,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 28
        },
        {
          date: '2024-05-31',
          searchVolume: 2509,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 28
        },
        {
          date: '2024-05-30',
          searchVolume: 2509,
          organicRank: 210,

          keywordSales: 28
        },
        {
          date: '2024-05-29',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 28
        },
        {
          date: '2024-05-28',
          searchVolume: 2509,
          organicRank: 116,
          sponsoredRank: 53,
          keywordSales: 28
        },
        {
          date: '2024-05-27',
          searchVolume: 2509,
          organicRank: 120,

          keywordSales: 28
        },
        {
          date: '2024-05-26',
          searchVolume: 2509,
          organicRank: 197,
          sponsoredRank: 14,
          keywordSales: 28
        },
        {
          date: '2024-05-25',
          searchVolume: 2509,
          organicRank: 211,

          keywordSales: 28
        },
        {
          date: '2024-05-24',
          searchVolume: 2394,
          organicRank: 130,

          keywordSales: 28
        },
        {
          date: '2024-05-23',
          searchVolume: 2394,
          organicRank: 213,

          keywordSales: 28
        },
        {
          date: '2024-05-22',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 28
        },
        {
          date: '2024-05-21',
          searchVolume: 2394,
          organicRank: 72,
          sponsoredRank: 26,
          keywordSales: 28
        },
        {
          date: '2024-05-20',
          searchVolume: 2394,
          organicRank: 179,

          keywordSales: 28
        },
        {
          date: '2024-05-19',
          searchVolume: 2394,
          organicRank: 101,

          keywordSales: 28
        },
        {
          date: '2024-05-18',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 55,
          keywordSales: 28
        },
        {
          date: '2024-05-17',
          searchVolume: 2505,
          organicRank: 104,
          sponsoredRank: 11,
          keywordSales: 28
        },
        {
          date: '2024-05-16',
          searchVolume: 2505,
          organicRank: 247,

          keywordSales: 28
        },
        {
          date: '2024-05-15',
          searchVolume: 2505,
          organicRank: 200,

          keywordSales: 28
        },
        {
          date: '2024-05-14',
          searchVolume: 2505,
          organicRank: 197,

          keywordSales: 28
        },
        {
          date: '2024-05-13',
          searchVolume: 2505,
          organicRank: 210,
          sponsoredRank: 36,
          keywordSales: 28
        },
        {
          date: '2024-05-12',
          searchVolume: 2505,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 28
        },
        {
          date: '2024-05-11',
          searchVolume: 2505,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 28
        },
        {
          date: '2024-05-10',
          searchVolume: 2550,
          organicRank: 118,

          keywordSales: 28
        },
        {
          date: '2024-05-09',
          searchVolume: 2550,
          organicRank: 118,

          keywordSales: 28
        },
        {
          date: '2024-05-08',
          searchVolume: 2550,
          organicRank: 196,
          sponsoredRank: 26,
          keywordSales: 28
        },
        {
          date: '2024-05-07',
          searchVolume: 2550,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 28
        },
        {
          date: '2024-05-06',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 28
        },
        {
          date: '2024-05-05',
          searchVolume: 2550,
          organicRank: 117,
          sponsoredRank: 44,
          keywordSales: 28
        },
        {
          date: '2024-05-04',
          searchVolume: 2550,
          organicRank: 107,
          sponsoredRank: 20,
          keywordSales: 28
        },
        {
          date: '2024-05-02',
          searchVolume: 2433,
          organicRank: 213,

          keywordSales: 28
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming mechanical keyboard',
      keyword: 'gaming mechanical keyboard',
      searchVolume: 1664,
      organicStartRank: 37,
      organicHighestRank: 11,
      sponsoredStartRank: 41,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-13',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1352,
          organicRank: 33,

          keywordSales: 7
        },
        {
          date: '2024-06-19',
          searchVolume: 1352,
          organicRank: 26,
          sponsoredRank: 15,
          keywordSales: 7
        },
        {
          date: '2024-06-18',
          searchVolume: 1352,
          organicRank: 19,
          sponsoredRank: 32,
          keywordSales: 7
        },
        {
          date: '2024-06-17',
          searchVolume: 1352,
          organicRank: 23,
          sponsoredRank: 15,
          keywordSales: 7
        },
        {
          date: '2024-06-16',
          searchVolume: 1352,
          organicRank: 31,
          sponsoredRank: 30,
          keywordSales: 7
        },
        {
          date: '2024-06-15',
          searchVolume: 1352,
          organicRank: 33,
          sponsoredRank: 42,
          keywordSales: 7
        },
        {
          date: '2024-06-14',
          searchVolume: 1349,
          organicRank: 30,
          sponsoredRank: 43,
          keywordSales: 7
        },
        {
          date: '2024-06-13',
          searchVolume: 1349,
          organicRank: 22,
          sponsoredRank: 40,
          keywordSales: 7
        },
        {
          date: '2024-06-12',
          searchVolume: 1349,
          organicRank: 31,

          keywordSales: 7
        },
        {
          date: '2024-06-11',
          searchVolume: 1349,
          organicRank: 24,

          keywordSales: 7
        },
        {
          date: '2024-06-10',
          searchVolume: 1349,
          organicRank: 27,

          keywordSales: 7
        },
        {
          date: '2024-06-09',
          searchVolume: 1349,
          organicRank: 36,
          sponsoredRank: 7,
          keywordSales: 7
        },
        {
          date: '2024-06-08',
          searchVolume: 1349,
          organicRank: 18,
          sponsoredRank: 1,
          keywordSales: 7
        },
        {
          date: '2024-06-07',
          searchVolume: 1379,
          organicRank: 23,

          keywordSales: 7
        },
        {
          date: '2024-06-06',
          searchVolume: 1379,
          organicRank: 31,

          keywordSales: 7
        },
        {
          date: '2024-06-05',
          searchVolume: 1379,
          organicRank: 32,
          sponsoredRank: 24,
          keywordSales: 7
        },
        {
          date: '2024-06-04',
          searchVolume: 1379,
          organicRank: 25,

          keywordSales: 7
        },
        {
          date: '2024-06-03',
          searchVolume: 1379,
          organicRank: 42,

          keywordSales: 7
        },
        {
          date: '2024-06-02',
          searchVolume: 1379,
          organicRank: 11,

          keywordSales: 7
        },
        {
          date: '2024-06-01',
          searchVolume: 1379,
          organicRank: 20,

          keywordSales: 7
        },
        {
          date: '2024-05-31',
          searchVolume: 1633,
          organicRank: 50,

          keywordSales: 7
        },
        {
          date: '2024-05-30',
          searchVolume: 1633,
          organicRank: 39,

          keywordSales: 7
        },
        {
          date: '2024-05-29',
          searchVolume: 1633,
          organicRank: 44,
          sponsoredRank: 22,
          keywordSales: 7
        },
        {
          date: '2024-05-28',
          searchVolume: 1633,
          organicRank: 31,

          keywordSales: 7
        },
        {
          date: '2024-05-27',
          searchVolume: 1633,
          organicRank: 27,

          keywordSales: 7
        },
        {
          date: '2024-05-26',
          searchVolume: 1633,
          organicRank: 25,
          sponsoredRank: 14,
          keywordSales: 7
        },
        {
          date: '2024-05-25',
          searchVolume: 1633,
          organicRank: 27,
          sponsoredRank: 65,
          keywordSales: 7
        },
        {
          date: '2024-05-24',
          searchVolume: 1664,
          organicRank: 27,

          keywordSales: 7
        },
        {
          date: '2024-05-23',
          searchVolume: 1664,
          organicRank: 20,

          keywordSales: 7
        },
        {
          date: '2024-05-22',
          searchVolume: 1664,
          organicRank: 15,
          sponsoredRank: 44,
          keywordSales: 7
        },
        {
          date: '2024-05-21',
          searchVolume: 1664,
          organicRank: 22,

          keywordSales: 7
        },
        {
          date: '2024-05-20',
          searchVolume: 1664,
          organicRank: 24,

          keywordSales: 7
        },
        {
          date: '2024-05-19',
          searchVolume: 1664,
          organicRank: 26,

          keywordSales: 7
        },
        {
          date: '2024-05-18',
          searchVolume: 1664,
          organicRank: 31,
          sponsoredRank: 22,
          keywordSales: 7
        },
        {
          date: '2024-05-17',
          searchVolume: 1635,
          organicRank: 29,
          sponsoredRank: 53,
          keywordSales: 7
        },
        {
          date: '2024-05-16',
          searchVolume: 1635,
          organicRank: 25,
          sponsoredRank: 23,
          keywordSales: 7
        },
        {
          date: '2024-05-15',
          searchVolume: 1635,
          organicRank: 26,

          keywordSales: 7
        },
        {
          date: '2024-05-14',
          searchVolume: 1635,
          organicRank: 20,
          sponsoredRank: 32,
          keywordSales: 7
        },
        {
          date: '2024-05-13',
          searchVolume: 1635,
          organicRank: 17,
          sponsoredRank: 34,
          keywordSales: 7
        },
        {
          date: '2024-05-12',
          searchVolume: 1635,
          organicRank: 32,
          sponsoredRank: 87,
          keywordSales: 7
        },
        {
          date: '2024-05-11',
          searchVolume: 1635,
          organicRank: 39,
          sponsoredRank: 31,
          keywordSales: 7
        },
        {
          date: '2024-05-10',
          searchVolume: 1657,
          organicRank: 39,

          keywordSales: 7
        },
        {
          date: '2024-05-09',
          searchVolume: 1657,
          organicRank: 35,
          sponsoredRank: 43,
          keywordSales: 7
        },
        {
          date: '2024-05-08',
          searchVolume: 1657,
          organicRank: 40,

          keywordSales: 7
        },
        {
          date: '2024-05-07',
          searchVolume: 1657,
          organicRank: 30,

          keywordSales: 7
        },
        {
          date: '2024-05-06',
          searchVolume: 1657,
          organicRank: 49,

          keywordSales: 7
        },
        {
          date: '2024-05-05',
          searchVolume: 1657,
          organicRank: 50,
          sponsoredRank: 55,
          keywordSales: 7
        },
        {
          date: '2024-05-04',
          searchVolume: 1657,
          organicRank: 37,

          keywordSales: 7
        },
        {
          date: '2024-05-03',
          searchVolume: 1373,
          organicRank: 21,
          sponsoredRank: 41,
          keywordSales: 7
        },
        {
          date: '2024-05-02',
          searchVolume: 1373,
          organicRank: 24,

          keywordSales: 7
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_gaming mouse and keyboard',
      keyword: 'gaming mouse and keyboard',
      searchVolume: 3758,
      organicStartRank: 271,
      organicHighestRank: 136,
      sponsoredStartRank: 60,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-19',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-18',
          searchVolume: 4322,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 74
        },
        {
          date: '2024-06-17',
          searchVolume: 4322,
          organicRank: 258,
          sponsoredRank: 7,
          keywordSales: 74
        },
        {
          date: '2024-06-16',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-15',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-14',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-13',
          searchVolume: 4322,
          organicRank: 257,

          keywordSales: 74
        },
        {
          date: '2024-06-12',
          searchVolume: 4322,
          organicRank: 136,

          keywordSales: 74
        },
        {
          date: '2024-06-11',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-10',
          searchVolume: 4322,
          organicRank: 239,

          keywordSales: 74
        },
        {
          date: '2024-06-09',
          searchVolume: 4322,
          organicRank: 212,

          keywordSales: 74
        },
        {
          date: '2024-06-08',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-07',
          searchVolume: 3699,
          organicRank: 229,

          keywordSales: 74
        },
        {
          date: '2024-06-06',
          searchVolume: 3699,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-05',
          searchVolume: 3699,
          organicRank: 264,
          sponsoredRank: 37,
          keywordSales: 74
        },
        {
          date: '2024-06-04',
          searchVolume: 3699,
          organicRank: 214,

          keywordSales: 74
        },
        {
          date: '2024-06-03',
          searchVolume: 3699,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-02',
          searchVolume: 3699,
          organicRank: 227,

          keywordSales: 74
        },
        {
          date: '2024-06-01',
          searchVolume: 3699,
          organicRank: 228,

          keywordSales: 74
        },
        {
          date: '2024-05-31',
          searchVolume: 4171,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 74
        },
        {
          date: '2024-05-30',
          searchVolume: 4171,
          organicRank: 253,

          keywordSales: 74
        },
        {
          date: '2024-05-29',
          searchVolume: 4171,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 74
        },
        {
          date: '2024-05-28',
          searchVolume: 4171,
          organicRank: 168,

          keywordSales: 74
        },
        {
          date: '2024-05-27',
          searchVolume: 4171,
          organicRank: 212,

          keywordSales: 74
        },
        {
          date: '2024-05-26',
          searchVolume: 4171,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 74
        },
        {
          date: '2024-05-25',
          searchVolume: 4171,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-24',
          searchVolume: 3758,
          organicRank: 245,

          keywordSales: 74
        },
        {
          date: '2024-05-23',
          searchVolume: 3758,
          organicRank: 234,

          keywordSales: 74
        },
        {
          date: '2024-05-22',
          searchVolume: 3758,
          organicRank: 209,
          sponsoredRank: 50,
          keywordSales: 74
        },
        {
          date: '2024-05-21',
          searchVolume: 3758,
          organicRank: 257,
          sponsoredRank: 27,
          keywordSales: 74
        },
        {
          date: '2024-05-20',
          searchVolume: 3758,
          organicRank: 240,

          keywordSales: 74
        },
        {
          date: '2024-05-19',
          searchVolume: 3758,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-18',
          searchVolume: 3758,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-17',
          searchVolume: 3752,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 74
        },
        {
          date: '2024-05-16',
          searchVolume: 3752,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-15',
          searchVolume: 3752,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-14',
          searchVolume: 3752,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-13',
          searchVolume: 3752,
          organicRank: 220,

          keywordSales: 74
        },
        {
          date: '2024-05-12',
          searchVolume: 3752,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-11',
          searchVolume: 3752,
          organicRank: 260,

          keywordSales: 74
        },
        {
          date: '2024-05-10',
          searchVolume: 4322,
          organicRank: 288,

          keywordSales: 74
        },
        {
          date: '2024-05-09',
          searchVolume: 4322,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 74
        },
        {
          date: '2024-05-08',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-07',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-06',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-05-05',
          searchVolume: 4322,
          organicRank: 271,

          keywordSales: 74
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_glorious keyboard',
      keyword: 'glorious keyboard',
      searchVolume: 2773,
      organicStartRank: 64,
      organicHighestRank: 42,
      sponsoredStartRank: 5,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 44,

          keywordSales: 75
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 42,
          sponsoredRank: 32,
          keywordSales: 75
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 46,
          sponsoredRank: 16,
          keywordSales: 75
        },
        {
          date: '2024-06-17',
          searchVolume: 2550,
          organicRank: 46,
          sponsoredRank: 72,
          keywordSales: 75
        },
        {
          date: '2024-06-16',
          searchVolume: 2550,
          organicRank: 49,

          keywordSales: 75
        },
        {
          date: '2024-06-15',
          searchVolume: 2550,
          organicRank: 51,

          keywordSales: 75
        },
        {
          date: '2024-06-14',
          searchVolume: 2550,
          organicRank: 98,
          sponsoredRank: 10,
          keywordSales: 75
        },
        {
          date: '2024-06-13',
          searchVolume: 2550,
          organicRank: 75,
          sponsoredRank: 44,
          keywordSales: 75
        },
        {
          date: '2024-06-12',
          searchVolume: 2550,
          organicRank: 60,

          keywordSales: 75
        },
        {
          date: '2024-06-11',
          searchVolume: 2550,
          organicRank: 54,

          keywordSales: 75
        },
        {
          date: '2024-06-10',
          searchVolume: 2550,
          organicRank: 65,

          keywordSales: 75
        },
        {
          date: '2024-06-09',
          searchVolume: 2550,
          organicRank: 63,
          sponsoredRank: 7,
          keywordSales: 75
        },
        {
          date: '2024-06-08',
          searchVolume: 2550,
          organicRank: 60,
          sponsoredRank: 7,
          keywordSales: 75
        },
        {
          date: '2024-06-07',
          searchVolume: 2434,
          organicRank: 86,

          keywordSales: 75
        },
        {
          date: '2024-06-06',
          searchVolume: 2434,
          organicRank: 62,

          keywordSales: 75
        },
        {
          date: '2024-06-05',
          searchVolume: 2434,
          organicRank: 61,
          sponsoredRank: 7,
          keywordSales: 75
        },
        {
          date: '2024-06-04',
          searchVolume: 2434,
          organicRank: 61,

          keywordSales: 75
        },
        {
          date: '2024-06-03',
          searchVolume: 2434,
          organicRank: 59,

          keywordSales: 75
        },
        {
          date: '2024-06-02',
          searchVolume: 2434,
          organicRank: 59,

          keywordSales: 75
        },
        {
          date: '2024-06-01',
          searchVolume: 2434,
          organicRank: 64,

          keywordSales: 75
        },
        {
          date: '2024-05-31',
          searchVolume: 2509,
          organicRank: 54,
          sponsoredRank: 16,
          keywordSales: 75
        },
        {
          date: '2024-05-30',
          searchVolume: 2509,
          organicRank: 65,
          sponsoredRank: 4,
          keywordSales: 75
        },
        {
          date: '2024-05-29',
          searchVolume: 2509,
          organicRank: 63,

          keywordSales: 75
        },
        {
          date: '2024-05-28',
          searchVolume: 2509,
          organicRank: 66,

          keywordSales: 75
        },
        {
          date: '2024-05-27',
          searchVolume: 2509,
          organicRank: 65,

          keywordSales: 75
        },
        {
          date: '2024-05-26',
          searchVolume: 2509,
          organicRank: 64,
          sponsoredRank: 4,
          keywordSales: 75
        },
        {
          date: '2024-05-25',
          searchVolume: 2509,
          organicRank: 59,

          keywordSales: 75
        },
        {
          date: '2024-05-24',
          searchVolume: 2773,
          organicRank: 51,

          keywordSales: 75
        },
        {
          date: '2024-05-23',
          searchVolume: 2773,
          organicRank: 60,
          sponsoredRank: 78,
          keywordSales: 75
        },
        {
          date: '2024-05-22',
          searchVolume: 2773,
          organicRank: 65,
          sponsoredRank: 37,
          keywordSales: 75
        },
        {
          date: '2024-05-21',
          searchVolume: 2773,
          organicRank: 50,

          keywordSales: 75
        },
        {
          date: '2024-05-20',
          searchVolume: 2773,
          organicRank: 50,

          keywordSales: 75
        },
        {
          date: '2024-05-19',
          searchVolume: 2773,
          organicRank: 60,

          keywordSales: 75
        },
        {
          date: '2024-05-18',
          searchVolume: 2773,
          organicRank: 62,
          sponsoredRank: 13,
          keywordSales: 75
        },
        {
          date: '2024-05-17',
          searchVolume: 3088,
          organicRank: 66,
          sponsoredRank: 5,
          keywordSales: 75
        },
        {
          date: '2024-05-16',
          searchVolume: 3088,
          organicRank: 63,

          keywordSales: 75
        },
        {
          date: '2024-05-15',
          searchVolume: 3088,
          organicRank: 60,

          keywordSales: 75
        },
        {
          date: '2024-05-14',
          searchVolume: 3088,
          organicRank: 62,
          sponsoredRank: 74,
          keywordSales: 75
        },
        {
          date: '2024-05-13',
          searchVolume: 3088,
          organicRank: 65,
          sponsoredRank: 12,
          keywordSales: 75
        },
        {
          date: '2024-05-12',
          searchVolume: 3088,
          organicRank: 68,
          sponsoredRank: 5,
          keywordSales: 75
        },
        {
          date: '2024-05-11',
          searchVolume: 3088,
          organicRank: 63,
          sponsoredRank: 31,
          keywordSales: 75
        },
        {
          date: '2024-05-10',
          searchVolume: 3126,
          organicRank: 68,

          keywordSales: 75
        },
        {
          date: '2024-05-09',
          searchVolume: 3126,
          organicRank: 64,
          sponsoredRank: 56,
          keywordSales: 75
        },
        {
          date: '2024-05-08',
          searchVolume: 3126,
          organicRank: 67,
          sponsoredRank: 50,
          keywordSales: 75
        },
        {
          date: '2024-05-07',
          searchVolume: 3126,
          organicRank: 65,

          keywordSales: 75
        },
        {
          date: '2024-05-06',
          searchVolume: 3126,
          organicRank: 61,

          keywordSales: 75
        },
        {
          date: '2024-05-05',
          searchVolume: 3126,
          organicRank: 62,

          keywordSales: 75
        },
        {
          date: '2024-05-04',
          searchVolume: 3126,
          organicRank: 64,
          sponsoredRank: 5,
          keywordSales: 75
        },
        {
          date: '2024-05-03',
          searchVolume: 2982,
          organicRank: 93,
          sponsoredRank: 80,
          keywordSales: 75
        },
        {
          date: '2024-05-02',
          searchVolume: 2982,
          organicRank: 67,

          keywordSales: 75
        },
        {
          date: '2024-05-01',
          searchVolume: 2982,
          organicRank: 46,

          keywordSales: 75
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_highground keyboard',
      keyword: 'highground keyboard',
      searchVolume: 2394,
      organicStartRank: 77,
      organicHighestRank: 67,
      sponsoredStartRank: 38,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 77,
          sponsoredRank: 42,
          keywordSales: 35
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 77,
          sponsoredRank: 14,
          keywordSales: 35
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 77,
          sponsoredRank: 20,
          keywordSales: 35
        },
        {
          date: '2024-06-17',
          searchVolume: 3126,
          organicRank: 77,

          keywordSales: 35
        },
        {
          date: '2024-06-16',
          searchVolume: 3126,
          organicRank: 84,

          keywordSales: 35
        },
        {
          date: '2024-06-15',
          searchVolume: 3126,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 35
        },
        {
          date: '2024-06-14',
          searchVolume: 3126,
          organicRank: 89,

          keywordSales: 35
        },
        {
          date: '2024-06-13',
          searchVolume: 3126,
          organicRank: 96,
          sponsoredRank: 49,
          keywordSales: 35
        },
        {
          date: '2024-06-12',
          searchVolume: 3126,
          organicRank: 76,

          keywordSales: 35
        },
        {
          date: '2024-06-11',
          searchVolume: 3126,
          organicRank: 67,

          keywordSales: 35
        },
        {
          date: '2024-06-10',
          searchVolume: 3126,
          organicRank: 88,

          keywordSales: 35
        },
        {
          date: '2024-06-09',
          searchVolume: 3126,
          organicRank: 85,
          sponsoredRank: 29,
          keywordSales: 35
        },
        {
          date: '2024-06-08',
          searchVolume: 3126,
          organicRank: 79,

          keywordSales: 35
        },
        {
          date: '2024-06-07',
          searchVolume: 2982,
          organicRank: 74,

          keywordSales: 35
        },
        {
          date: '2024-06-06',
          searchVolume: 2982,
          organicRank: 85,

          keywordSales: 35
        },
        {
          date: '2024-06-05',
          searchVolume: 2982,
          organicRank: 82,
          sponsoredRank: 35,
          keywordSales: 35
        },
        {
          date: '2024-06-04',
          searchVolume: 2982,
          organicRank: 86,

          keywordSales: 35
        },
        {
          date: '2024-06-03',
          searchVolume: 2982,
          organicRank: 77,

          keywordSales: 35
        },
        {
          date: '2024-06-02',
          searchVolume: 2982,
          organicRank: 80,

          keywordSales: 35
        },
        {
          date: '2024-06-01',
          searchVolume: 2982,
          organicRank: 86,

          keywordSales: 35
        },
        {
          date: '2024-05-31',
          searchVolume: 2394,
          organicRank: 87,

          keywordSales: 35
        },
        {
          date: '2024-05-30',
          searchVolume: 2394,
          organicRank: 79,

          keywordSales: 35
        },
        {
          date: '2024-05-29',
          searchVolume: 2394,
          organicRank: 86,

          keywordSales: 35
        },
        {
          date: '2024-05-28',
          searchVolume: 2394,
          organicRank: 82,

          keywordSales: 35
        },
        {
          date: '2024-05-27',
          searchVolume: 2394,
          organicRank: 83,

          keywordSales: 35
        },
        {
          date: '2024-05-26',
          searchVolume: 2394,
          organicRank: 85,
          sponsoredRank: 35,
          keywordSales: 35
        },
        {
          date: '2024-05-25',
          searchVolume: 2394,
          organicRank: 78,

          keywordSales: 35
        },
        {
          date: '2024-05-24',
          searchVolume: 2394,
          organicRank: 88,

          keywordSales: 35
        },
        {
          date: '2024-05-23',
          searchVolume: 2394,
          organicRank: 85,

          keywordSales: 35
        },
        {
          date: '2024-05-22',
          searchVolume: 2394,
          organicRank: 86,
          sponsoredRank: 59,
          keywordSales: 35
        },
        {
          date: '2024-05-21',
          searchVolume: 2394,
          organicRank: 83,
          sponsoredRank: 11,
          keywordSales: 35
        },
        {
          date: '2024-05-20',
          searchVolume: 2394,
          organicRank: 82,

          keywordSales: 35
        },
        {
          date: '2024-05-19',
          searchVolume: 2394,
          organicRank: 79,

          keywordSales: 35
        },
        {
          date: '2024-05-18',
          searchVolume: 2394,
          organicRank: 97,

          keywordSales: 35
        },
        {
          date: '2024-05-17',
          searchVolume: 2390,
          organicRank: 96,
          sponsoredRank: 24,
          keywordSales: 35
        },
        {
          date: '2024-05-16',
          searchVolume: 2390,
          organicRank: 93,

          keywordSales: 35
        },
        {
          date: '2024-05-15',
          searchVolume: 2390,
          organicRank: 99,

          keywordSales: 35
        },
        {
          date: '2024-05-14',
          searchVolume: 2390,
          organicRank: 102,

          keywordSales: 35
        },
        {
          date: '2024-05-13',
          searchVolume: 2390,
          organicRank: 88,

          keywordSales: 35
        },
        {
          date: '2024-05-12',
          searchVolume: 2390,
          organicRank: 97,

          keywordSales: 35
        },
        {
          date: '2024-05-11',
          searchVolume: 2390,
          organicRank: 85,
          sponsoredRank: 77,
          keywordSales: 35
        },
        {
          date: '2024-05-10',
          searchVolume: 2550,
          organicRank: 97,

          keywordSales: 35
        },
        {
          date: '2024-05-09',
          searchVolume: 2550,
          organicRank: 93,

          keywordSales: 35
        },
        {
          date: '2024-05-08',
          searchVolume: 2550,
          organicRank: 88,
          sponsoredRank: 24,
          keywordSales: 35
        },
        {
          date: '2024-05-07',
          searchVolume: 2550,
          organicRank: 96,

          keywordSales: 35
        },
        {
          date: '2024-05-06',
          searchVolume: 2550,
          organicRank: 72,

          keywordSales: 35
        },
        {
          date: '2024-05-05',
          searchVolume: 2550,
          organicRank: 75,

          keywordSales: 35
        },
        {
          date: '2024-05-04',
          searchVolume: 2550,
          organicRank: 77,
          sponsoredRank: 38,
          keywordSales: 35
        },
        {
          date: '2024-05-03',
          searchVolume: 2286,
          organicRank: 80,

          keywordSales: 35
        },
        {
          date: '2024-05-02',
          searchVolume: 2286,
          organicRank: 81,

          keywordSales: 35
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_hot swappable keyboard',
      keyword: 'hot swappable keyboard',
      searchVolume: 1430,
      organicStartRank: 162,
      organicHighestRank: 64,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-20',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 1352,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 1352,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 1352,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 1352,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 1352,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 1352,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 1352,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 1375,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 1375,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 1375,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 1375,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 1375,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 1375,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 1375,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 1136,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 1136,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 1136,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 1136,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 1136,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 1136,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 1136,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 1200,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 1200,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 1200,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 1200,
          organicRank: 82,

          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 1200,
          organicRank: 76,
          sponsoredRank: 48,
          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 1200,
          organicRank: 64,

          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 1200,
          organicRank: 72,

          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 1430,
          organicRank: 123,

          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 1430,
          organicRank: 131,

          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 1430,
          organicRank: 102,

          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 1430,
          organicRank: 104,

          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 1430,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-05',
          searchVolume: 1430,
          organicRank: 144,
          sponsoredRank: 74,
          keywordSales: 3
        },
        {
          date: '2024-05-04',
          searchVolume: 1430,
          organicRank: 162,
          sponsoredRank: 11,
          keywordSales: 3
        },
        {
          date: '2024-05-03',
          searchVolume: 1582,
          organicRank: 208,
          sponsoredRank: 80,
          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_hot swappable mechanical keyboard',
      keyword: 'hot swappable mechanical keyboard',
      searchVolume: 3126,
      organicStartRank: 248,
      organicHighestRank: 40,
      sponsoredStartRank: 72,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-19',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-18',
          searchVolume: 3618,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 20
        },
        {
          date: '2024-06-17',
          searchVolume: 3618,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 20
        },
        {
          date: '2024-06-16',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-15',
          searchVolume: 3618,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 20
        },
        {
          date: '2024-06-14',
          searchVolume: 3444,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-13',
          searchVolume: 3444,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-12',
          searchVolume: 3444,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-11',
          searchVolume: 3444,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-10',
          searchVolume: 3444,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-09',
          searchVolume: 3444,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-08',
          searchVolume: 3444,
          organicRank: 306,
          sponsoredRank: 62,
          keywordSales: 20
        },
        {
          date: '2024-06-07',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-06',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-05',
          searchVolume: 2982,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 20
        },
        {
          date: '2024-06-04',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-03',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-02',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-01',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-31',
          searchVolume: 2773,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 20
        },
        {
          date: '2024-05-30',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-29',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-28',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-27',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-26',
          searchVolume: 2773,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 20
        },
        {
          date: '2024-05-25',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 306,
          sponsoredRank: 101,
          keywordSales: 20
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-22',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-20',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-19',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-18',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-17',
          searchVolume: 2509,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 20
        },
        {
          date: '2024-05-16',
          searchVolume: 2509,
          organicRank: 306,
          sponsoredRank: 54,
          keywordSales: 20
        },
        {
          date: '2024-05-15',
          searchVolume: 2509,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 20
        },
        {
          date: '2024-05-14',
          searchVolume: 2509,
          organicRank: 79,
          sponsoredRank: 68,
          keywordSales: 20
        },
        {
          date: '2024-05-13',
          searchVolume: 2509,
          organicRank: 306,
          sponsoredRank: 77,
          keywordSales: 20
        },
        {
          date: '2024-05-12',
          searchVolume: 2509,
          organicRank: 40,
          sponsoredRank: 52,
          keywordSales: 20
        },
        {
          date: '2024-05-11',
          searchVolume: 2509,
          organicRank: 53,
          sponsoredRank: 58,
          keywordSales: 20
        },
        {
          date: '2024-05-10',
          searchVolume: 3126,
          organicRank: 306,
          sponsoredRank: 34,
          keywordSales: 20
        },
        {
          date: '2024-05-09',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-08',
          searchVolume: 3126,
          organicRank: 62,
          sponsoredRank: 18,
          keywordSales: 20
        },
        {
          date: '2024-05-07',
          searchVolume: 3126,
          organicRank: 306,
          sponsoredRank: 52,
          keywordSales: 20
        },
        {
          date: '2024-05-06',
          searchVolume: 3126,
          organicRank: 62,
          sponsoredRank: 49,
          keywordSales: 20
        },
        {
          date: '2024-05-05',
          searchVolume: 3126,
          organicRank: 213,
          sponsoredRank: 59,
          keywordSales: 20
        },
        {
          date: '2024-05-02',
          searchVolume: 3462,
          organicRank: 248,
          sponsoredRank: 72,
          keywordSales: 20
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_hp keyboard',
      keyword: 'hp keyboard',
      searchVolume: 3130,
      organicStartRank: 162,
      organicHighestRank: 125,
      sponsoredStartRank: 46,
      sponsoredHighestRank: 40,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2816,
          organicRank: 139,

          keywordSales: 268
        },
        {
          date: '2024-06-19',
          searchVolume: 2816,
          organicRank: 144,

          keywordSales: 268
        },
        {
          date: '2024-06-18',
          searchVolume: 2816,
          organicRank: 145,

          keywordSales: 268
        },
        {
          date: '2024-06-17',
          searchVolume: 2816,
          organicRank: 144,

          keywordSales: 268
        },
        {
          date: '2024-06-16',
          searchVolume: 2816,
          organicRank: 137,

          keywordSales: 268
        },
        {
          date: '2024-06-15',
          searchVolume: 2816,
          organicRank: 141,

          keywordSales: 268
        },
        {
          date: '2024-06-14',
          searchVolume: 3139,
          organicRank: 147,

          keywordSales: 268
        },
        {
          date: '2024-06-13',
          searchVolume: 3139,
          organicRank: 143,

          keywordSales: 268
        },
        {
          date: '2024-06-12',
          searchVolume: 3139,
          organicRank: 137,

          keywordSales: 268
        },
        {
          date: '2024-06-11',
          searchVolume: 3139,
          organicRank: 138,

          keywordSales: 268
        },
        {
          date: '2024-06-10',
          searchVolume: 3139,
          organicRank: 125,

          keywordSales: 268
        },
        {
          date: '2024-06-09',
          searchVolume: 3139,
          organicRank: 174,

          keywordSales: 268
        },
        {
          date: '2024-06-08',
          searchVolume: 3139,
          organicRank: 165,

          keywordSales: 268
        },
        {
          date: '2024-06-07',
          searchVolume: 2434,
          organicRank: 166,

          keywordSales: 268
        },
        {
          date: '2024-06-06',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-06-05',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-06-04',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-06-03',
          searchVolume: 2434,
          organicRank: 162,

          keywordSales: 268
        },
        {
          date: '2024-06-02',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-06-01',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-31',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-30',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-29',
          searchVolume: 3078,
          organicRank: 176,

          keywordSales: 268
        },
        {
          date: '2024-05-28',
          searchVolume: 3078,
          organicRank: 165,

          keywordSales: 268
        },
        {
          date: '2024-05-27',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-26',
          searchVolume: 3078,
          organicRank: 163,
          sponsoredRank: 70,
          keywordSales: 268
        },
        {
          date: '2024-05-25',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 171,

          keywordSales: 268
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-22',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-20',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-19',
          searchVolume: 3078,
          organicRank: 180,

          keywordSales: 268
        },
        {
          date: '2024-05-18',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-17',
          searchVolume: 3073,
          organicRank: 148,

          keywordSales: 268
        },
        {
          date: '2024-05-16',
          searchVolume: 3073,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-15',
          searchVolume: 3073,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-14',
          searchVolume: 3073,
          organicRank: 157,

          keywordSales: 268
        },
        {
          date: '2024-05-13',
          searchVolume: 3073,
          organicRank: 149,
          sponsoredRank: 40,
          keywordSales: 268
        },
        {
          date: '2024-05-12',
          searchVolume: 3073,
          organicRank: 160,
          sponsoredRank: 46,
          keywordSales: 268
        },
        {
          date: '2024-05-11',
          searchVolume: 3073,
          organicRank: 157,

          keywordSales: 268
        },
        {
          date: '2024-05-10',
          searchVolume: 3130,
          organicRank: 159,

          keywordSales: 268
        },
        {
          date: '2024-05-09',
          searchVolume: 3130,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-08',
          searchVolume: 3130,
          organicRank: 306,

          keywordSales: 268
        },
        {
          date: '2024-05-07',
          searchVolume: 3130,
          organicRank: 166,

          keywordSales: 268
        },
        {
          date: '2024-05-06',
          searchVolume: 3130,
          organicRank: 153,

          keywordSales: 268
        },
        {
          date: '2024-05-04',
          searchVolume: 3130,
          organicRank: 162,

          keywordSales: 268
        },
        {
          date: '2024-05-03',
          searchVolume: 2987,
          organicRank: 174,

          keywordSales: 268
        },
        {
          date: '2024-05-02',
          searchVolume: 2987,
          organicRank: 160,

          keywordSales: 268
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_hyperx headset wireless',
      keyword: 'hyperx headset wireless',
      searchVolume: 2511,
      organicStartRank: 163,
      organicHighestRank: 81,
      sponsoredStartRank: 72,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-07',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2552,
          organicRank: 179,

          keywordSales: 49
        },
        {
          date: '2024-06-19',
          searchVolume: 2552,
          organicRank: 161,

          keywordSales: 49
        },
        {
          date: '2024-06-18',
          searchVolume: 2552,
          organicRank: 136,

          keywordSales: 49
        },
        {
          date: '2024-06-17',
          searchVolume: 2552,
          organicRank: 159,

          keywordSales: 49
        },
        {
          date: '2024-06-16',
          searchVolume: 2552,
          organicRank: 134,

          keywordSales: 49
        },
        {
          date: '2024-06-15',
          searchVolume: 2552,
          organicRank: 153,

          keywordSales: 49
        },
        {
          date: '2024-06-14',
          searchVolume: 2548,
          organicRank: 116,

          keywordSales: 49
        },
        {
          date: '2024-06-13',
          searchVolume: 2548,
          organicRank: 112,

          keywordSales: 49
        },
        {
          date: '2024-06-12',
          searchVolume: 2548,
          organicRank: 156,

          keywordSales: 49
        },
        {
          date: '2024-06-11',
          searchVolume: 2548,
          organicRank: 111,

          keywordSales: 49
        },
        {
          date: '2024-06-10',
          searchVolume: 2548,
          organicRank: 116,

          keywordSales: 49
        },
        {
          date: '2024-06-09',
          searchVolume: 2548,
          organicRank: 99,
          sponsoredRank: 87,
          keywordSales: 49
        },
        {
          date: '2024-06-08',
          searchVolume: 2548,
          organicRank: 98,

          keywordSales: 49
        },
        {
          date: '2024-06-07',
          searchVolume: 2073,
          organicRank: 181,

          keywordSales: 49
        },
        {
          date: '2024-06-06',
          searchVolume: 2073,
          organicRank: 145,

          keywordSales: 49
        },
        {
          date: '2024-06-05',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-04',
          searchVolume: 2073,
          organicRank: 101,

          keywordSales: 49
        },
        {
          date: '2024-06-03',
          searchVolume: 2073,
          organicRank: 109,

          keywordSales: 49
        },
        {
          date: '2024-06-02',
          searchVolume: 2073,
          organicRank: 186,

          keywordSales: 49
        },
        {
          date: '2024-06-01',
          searchVolume: 2073,
          organicRank: 102,
          sponsoredRank: 48,
          keywordSales: 49
        },
        {
          date: '2024-05-31',
          searchVolume: 2356,
          organicRank: 163,

          keywordSales: 49
        },
        {
          date: '2024-05-30',
          searchVolume: 2356,
          organicRank: 156,

          keywordSales: 49
        },
        {
          date: '2024-05-29',
          searchVolume: 2356,
          organicRank: 81,

          keywordSales: 49
        },
        {
          date: '2024-05-28',
          searchVolume: 2356,
          organicRank: 228,

          keywordSales: 49
        },
        {
          date: '2024-05-27',
          searchVolume: 2356,
          organicRank: 84,
          sponsoredRank: 38,
          keywordSales: 49
        },
        {
          date: '2024-05-26',
          searchVolume: 2356,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 49
        },
        {
          date: '2024-05-25',
          searchVolume: 2356,
          organicRank: 172,

          keywordSales: 49
        },
        {
          date: '2024-05-24',
          searchVolume: 2511,
          organicRank: 157,

          keywordSales: 49
        },
        {
          date: '2024-05-23',
          searchVolume: 2511,
          organicRank: 92,

          keywordSales: 49
        },
        {
          date: '2024-05-22',
          searchVolume: 2511,
          organicRank: 242,
          sponsoredRank: 20,
          keywordSales: 49
        },
        {
          date: '2024-05-21',
          searchVolume: 2511,
          organicRank: 177,
          sponsoredRank: 32,
          keywordSales: 49
        },
        {
          date: '2024-05-20',
          searchVolume: 2511,
          organicRank: 169,

          keywordSales: 49
        },
        {
          date: '2024-05-19',
          searchVolume: 2511,
          organicRank: 217,

          keywordSales: 49
        },
        {
          date: '2024-05-18',
          searchVolume: 2511,
          organicRank: 224,

          keywordSales: 49
        },
        {
          date: '2024-05-17',
          searchVolume: 2507,
          organicRank: 163,

          keywordSales: 49
        },
        {
          date: '2024-05-16',
          searchVolume: 2507,
          organicRank: 211,

          keywordSales: 49
        },
        {
          date: '2024-05-15',
          searchVolume: 2507,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-05-14',
          searchVolume: 2507,
          organicRank: 199,

          keywordSales: 49
        },
        {
          date: '2024-05-13',
          searchVolume: 2507,
          organicRank: 180,

          keywordSales: 49
        },
        {
          date: '2024-05-12',
          searchVolume: 2507,
          organicRank: 157,

          keywordSales: 49
        },
        {
          date: '2024-05-11',
          searchVolume: 2507,
          organicRank: 116,

          keywordSales: 49
        },
        {
          date: '2024-05-10',
          searchVolume: 2550,
          organicRank: 120,

          keywordSales: 49
        },
        {
          date: '2024-05-09',
          searchVolume: 2550,
          organicRank: 114,

          keywordSales: 49
        },
        {
          date: '2024-05-08',
          searchVolume: 2550,
          organicRank: 161,
          sponsoredRank: 19,
          keywordSales: 49
        },
        {
          date: '2024-05-07',
          searchVolume: 2550,
          organicRank: 113,
          sponsoredRank: 8,
          keywordSales: 49
        },
        {
          date: '2024-05-06',
          searchVolume: 2550,
          organicRank: 121,

          keywordSales: 49
        },
        {
          date: '2024-05-05',
          searchVolume: 2550,
          organicRank: 152,

          keywordSales: 49
        },
        {
          date: '2024-05-04',
          searchVolume: 2550,
          organicRank: 163,
          sponsoredRank: 72,
          keywordSales: 49
        },
        {
          date: '2024-05-03',
          searchVolume: 2280,
          organicRank: 159,
          sponsoredRank: 7,
          keywordSales: 49
        },
        {
          date: '2024-05-02',
          searchVolume: 2280,
          organicRank: 126,

          keywordSales: 49
        },
        {
          date: '2024-05-01',
          searchVolume: 2280,
          organicRank: 160,

          keywordSales: 49
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_hyperx keyboard',
      keyword: 'hyperx keyboard',
      searchVolume: 5733,
      organicStartRank: 2,
      organicHighestRank: 2,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-11',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-16',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 5693,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-06-19',
          searchVolume: 5693,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-06-18',
          searchVolume: 5693,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-06-17',
          searchVolume: 5693,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-06-16',
          searchVolume: 5693,
          organicRank: 2,

          keywordSales: 129
        },
        {
          date: '2024-06-15',
          searchVolume: 5693,
          organicRank: 2,
          sponsoredRank: 34,
          keywordSales: 129
        },
        {
          date: '2024-06-14',
          searchVolume: 5689,
          organicRank: 2,
          sponsoredRank: 13,
          keywordSales: 129
        },
        {
          date: '2024-06-13',
          searchVolume: 5689,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-06-12',
          searchVolume: 5689,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-06-11',
          searchVolume: 5689,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-06-10',
          searchVolume: 5689,
          organicRank: 2,
          sponsoredRank: 11,
          keywordSales: 129
        },
        {
          date: '2024-06-09',
          searchVolume: 5689,
          organicRank: 2,
          sponsoredRank: 33,
          keywordSales: 129
        },
        {
          date: '2024-06-08',
          searchVolume: 5689,
          organicRank: 2,
          sponsoredRank: 8,
          keywordSales: 129
        },
        {
          date: '2024-06-07',
          searchVolume: 5550,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-06-06',
          searchVolume: 5550,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-06-05',
          searchVolume: 5550,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-06-04',
          searchVolume: 5550,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-06-03',
          searchVolume: 5550,
          organicRank: 2,
          sponsoredRank: 70,
          keywordSales: 129
        },
        {
          date: '2024-06-02',
          searchVolume: 5550,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 129
        },
        {
          date: '2024-06-01',
          searchVolume: 5550,
          organicRank: 2,
          sponsoredRank: 13,
          keywordSales: 129
        },
        {
          date: '2024-05-31',
          searchVolume: 5066,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-05-30',
          searchVolume: 5066,
          organicRank: 2,
          sponsoredRank: 17,
          keywordSales: 129
        },
        {
          date: '2024-05-29',
          searchVolume: 5066,
          organicRank: 2,
          sponsoredRank: 8,
          keywordSales: 129
        },
        {
          date: '2024-05-28',
          searchVolume: 5066,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-05-27',
          searchVolume: 5066,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-05-26',
          searchVolume: 5066,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-05-25',
          searchVolume: 5066,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-05-24',
          searchVolume: 5733,
          organicRank: 2,
          sponsoredRank: 40,
          keywordSales: 129
        },
        {
          date: '2024-05-23',
          searchVolume: 5733,
          organicRank: 2,
          sponsoredRank: 57,
          keywordSales: 129
        },
        {
          date: '2024-05-22',
          searchVolume: 5733,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-05-21',
          searchVolume: 5733,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-05-20',
          searchVolume: 5733,
          organicRank: 2,
          sponsoredRank: 28,
          keywordSales: 129
        },
        {
          date: '2024-05-19',
          searchVolume: 5733,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-05-18',
          searchVolume: 5733,
          organicRank: 2,
          sponsoredRank: 34,
          keywordSales: 129
        },
        {
          date: '2024-05-17',
          searchVolume: 5859,
          organicRank: 2,
          sponsoredRank: 13,
          keywordSales: 129
        },
        {
          date: '2024-05-16',
          searchVolume: 5859,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-05-15',
          searchVolume: 5859,
          organicRank: 2,
          sponsoredRank: 75,
          keywordSales: 129
        },
        {
          date: '2024-05-14',
          searchVolume: 5859,
          organicRank: 2,
          sponsoredRank: 20,
          keywordSales: 129
        },
        {
          date: '2024-05-13',
          searchVolume: 5859,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-05-12',
          searchVolume: 5859,
          organicRank: 2,
          sponsoredRank: 13,
          keywordSales: 129
        },
        {
          date: '2024-05-11',
          searchVolume: 5859,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-05-10',
          searchVolume: 5964,
          organicRank: 29,
          sponsoredRank: 2,
          keywordSales: 129
        },
        {
          date: '2024-05-09',
          searchVolume: 5964,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 129
        },
        {
          date: '2024-05-08',
          searchVolume: 5964,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-05-07',
          searchVolume: 5964,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-05-06',
          searchVolume: 5964,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-05-05',
          searchVolume: 5964,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-05-04',
          searchVolume: 5964,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 129
        },
        {
          date: '2024-05-03',
          searchVolume: 5722,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 129
        },
        {
          date: '2024-05-02',
          searchVolume: 5722,
          organicRank: 2,
          sponsoredRank: 41,
          keywordSales: 129
        },
        {
          date: '2024-05-01',
          searchVolume: 5722,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 129
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_key board',
      keyword: 'key board',
      searchVolume: 3564,
      organicStartRank: 56,
      organicHighestRank: 18,
      sponsoredStartRank: 49,
      sponsoredHighestRank: 5,
      organicPageOneWinDate: '2024-05-07',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 3458,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-19',
          searchVolume: 3458,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 45
        },
        {
          date: '2024-06-18',
          searchVolume: 3458,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 45
        },
        {
          date: '2024-06-17',
          searchVolume: 3458,
          organicRank: 187,

          keywordSales: 45
        },
        {
          date: '2024-06-16',
          searchVolume: 3458,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-15',
          searchVolume: 3458,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-14',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-13',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-12',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-11',
          searchVolume: 3620,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-10',
          searchVolume: 3620,
          organicRank: 262,

          keywordSales: 45
        },
        {
          date: '2024-06-09',
          searchVolume: 3620,
          organicRank: 259,

          keywordSales: 45
        },
        {
          date: '2024-06-08',
          searchVolume: 3620,
          organicRank: 101,
          sponsoredRank: 37,
          keywordSales: 45
        },
        {
          date: '2024-06-07',
          searchVolume: 3455,
          organicRank: 114,

          keywordSales: 45
        },
        {
          date: '2024-06-06',
          searchVolume: 3455,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-05',
          searchVolume: 3455,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-04',
          searchVolume: 3455,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-06-03',
          searchVolume: 3455,
          organicRank: 241,

          keywordSales: 45
        },
        {
          date: '2024-06-02',
          searchVolume: 3455,
          organicRank: 108,

          keywordSales: 45
        },
        {
          date: '2024-06-01',
          searchVolume: 3455,
          organicRank: 58,

          keywordSales: 45
        },
        {
          date: '2024-05-31',
          searchVolume: 3563,
          organicRank: 68,

          keywordSales: 45
        },
        {
          date: '2024-05-30',
          searchVolume: 3563,
          organicRank: 75,

          keywordSales: 45
        },
        {
          date: '2024-05-29',
          searchVolume: 3563,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-05-28',
          searchVolume: 3563,
          organicRank: 197,

          keywordSales: 45
        },
        {
          date: '2024-05-27',
          searchVolume: 3563,
          organicRank: 306,

          keywordSales: 45
        },
        {
          date: '2024-05-26',
          searchVolume: 3563,
          organicRank: 51,
          sponsoredRank: 52,
          keywordSales: 45
        },
        {
          date: '2024-05-25',
          searchVolume: 3563,
          organicRank: 76,

          keywordSales: 45
        },
        {
          date: '2024-05-24',
          searchVolume: 3564,
          organicRank: 47,

          keywordSales: 45
        },
        {
          date: '2024-05-23',
          searchVolume: 3564,
          organicRank: 44,

          keywordSales: 45
        },
        {
          date: '2024-05-22',
          searchVolume: 3564,
          organicRank: 103,
          sponsoredRank: 19,
          keywordSales: 45
        },
        {
          date: '2024-05-21',
          searchVolume: 3564,
          organicRank: 57,
          sponsoredRank: 45,
          keywordSales: 45
        },
        {
          date: '2024-05-20',
          searchVolume: 3564,
          organicRank: 49,

          keywordSales: 45
        },
        {
          date: '2024-05-19',
          searchVolume: 3564,
          organicRank: 44,
          sponsoredRank: 46,
          keywordSales: 45
        },
        {
          date: '2024-05-18',
          searchVolume: 3564,
          organicRank: 53,

          keywordSales: 45
        },
        {
          date: '2024-05-17',
          searchVolume: 3570,
          organicRank: 40,
          sponsoredRank: 17,
          keywordSales: 45
        },
        {
          date: '2024-05-16',
          searchVolume: 3570,
          organicRank: 34,
          sponsoredRank: 47,
          keywordSales: 45
        },
        {
          date: '2024-05-15',
          searchVolume: 3570,
          organicRank: 30,
          sponsoredRank: 70,
          keywordSales: 45
        },
        {
          date: '2024-05-14',
          searchVolume: 3570,
          organicRank: 19,
          sponsoredRank: 73,
          keywordSales: 45
        },
        {
          date: '2024-05-13',
          searchVolume: 3570,
          organicRank: 19,
          sponsoredRank: 73,
          keywordSales: 45
        },
        {
          date: '2024-05-12',
          searchVolume: 3570,
          organicRank: 21,
          sponsoredRank: 20,
          keywordSales: 45
        },
        {
          date: '2024-05-11',
          searchVolume: 3570,
          organicRank: 19,
          sponsoredRank: 5,
          keywordSales: 45
        },
        {
          date: '2024-05-10',
          searchVolume: 3618,
          organicRank: 19,
          sponsoredRank: 49,
          keywordSales: 45
        },
        {
          date: '2024-05-09',
          searchVolume: 3618,
          organicRank: 18,

          keywordSales: 45
        },
        {
          date: '2024-05-08',
          searchVolume: 3618,
          organicRank: 25,

          keywordSales: 45
        },
        {
          date: '2024-05-07',
          searchVolume: 3618,
          organicRank: 18,

          keywordSales: 45
        },
        {
          date: '2024-05-06',
          searchVolume: 3618,
          organicRank: 24,

          keywordSales: 45
        },
        {
          date: '2024-05-05',
          searchVolume: 3618,
          organicRank: 72,

          keywordSales: 45
        },
        {
          date: '2024-05-04',
          searchVolume: 3618,
          organicRank: 56,

          keywordSales: 45
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard',
      keyword: 'keyboard',
      searchVolume: 305333,
      organicStartRank: 69,
      organicHighestRank: 19,
      sponsoredStartRank: 52,
      sponsoredHighestRank: 19,
      organicPageOneWinDate: '2024-05-07',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 236533,
          organicRank: 89,

          keywordSales: 3482
        },
        {
          date: '2024-06-19',
          searchVolume: 236533,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-06-18',
          searchVolume: 236533,
          organicRank: 30,
          sponsoredRank: 99,
          keywordSales: 3482
        },
        {
          date: '2024-06-17',
          searchVolume: 236533,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-06-16',
          searchVolume: 236533,
          organicRank: 259,

          keywordSales: 3482
        },
        {
          date: '2024-06-15',
          searchVolume: 236533,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-06-14',
          searchVolume: 244049,
          organicRank: 238,

          keywordSales: 3482
        },
        {
          date: '2024-06-13',
          searchVolume: 244049,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-06-12',
          searchVolume: 244049,
          organicRank: 284,

          keywordSales: 3482
        },
        {
          date: '2024-06-11',
          searchVolume: 244049,
          organicRank: 236,

          keywordSales: 3482
        },
        {
          date: '2024-06-10',
          searchVolume: 244049,
          organicRank: 230,

          keywordSales: 3482
        },
        {
          date: '2024-06-09',
          searchVolume: 244049,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-06-08',
          searchVolume: 244049,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-06-07',
          searchVolume: 227782,
          organicRank: 233,

          keywordSales: 3482
        },
        {
          date: '2024-06-06',
          searchVolume: 227782,
          organicRank: 239,

          keywordSales: 3482
        },
        {
          date: '2024-06-05',
          searchVolume: 227782,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-06-04',
          searchVolume: 227782,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-06-03',
          searchVolume: 227782,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-06-02',
          searchVolume: 227782,
          organicRank: 93,

          keywordSales: 3482
        },
        {
          date: '2024-06-01',
          searchVolume: 227782,
          organicRank: 221,

          keywordSales: 3482
        },
        {
          date: '2024-05-31',
          searchVolume: 291756,
          organicRank: 229,

          keywordSales: 3482
        },
        {
          date: '2024-05-30',
          searchVolume: 291756,
          organicRank: 60,

          keywordSales: 3482
        },
        {
          date: '2024-05-29',
          searchVolume: 291756,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 3482
        },
        {
          date: '2024-05-28',
          searchVolume: 291756,
          organicRank: 306,
          sponsoredRank: 87,
          keywordSales: 3482
        },
        {
          date: '2024-05-27',
          searchVolume: 291756,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-05-26',
          searchVolume: 291756,
          organicRank: 214,
          sponsoredRank: 62,
          keywordSales: 3482
        },
        {
          date: '2024-05-25',
          searchVolume: 291756,
          organicRank: 69,
          sponsoredRank: 52,
          keywordSales: 3482
        },
        {
          date: '2024-05-24',
          searchVolume: 305333,
          organicRank: 71,
          sponsoredRank: 67,
          keywordSales: 3482
        },
        {
          date: '2024-05-23',
          searchVolume: 305333,
          organicRank: 122,
          sponsoredRank: 50,
          keywordSales: 3482
        },
        {
          date: '2024-05-22',
          searchVolume: 305333,
          organicRank: 103,
          sponsoredRank: 52,
          keywordSales: 3482
        },
        {
          date: '2024-05-21',
          searchVolume: 305333,
          organicRank: 33,
          sponsoredRank: 63,
          keywordSales: 3482
        },
        {
          date: '2024-05-20',
          searchVolume: 305333,
          organicRank: 51,
          sponsoredRank: 29,
          keywordSales: 3482
        },
        {
          date: '2024-05-19',
          searchVolume: 305333,
          organicRank: 84,
          sponsoredRank: 22,
          keywordSales: 3482
        },
        {
          date: '2024-05-18',
          searchVolume: 305333,
          organicRank: 41,
          sponsoredRank: 46,
          keywordSales: 3482
        },
        {
          date: '2024-05-17',
          searchVolume: 282222,
          organicRank: 40,
          sponsoredRank: 21,
          keywordSales: 3482
        },
        {
          date: '2024-05-16',
          searchVolume: 282222,
          organicRank: 35,
          sponsoredRank: 90,
          keywordSales: 3482
        },
        {
          date: '2024-05-15',
          searchVolume: 282222,
          organicRank: 29,

          keywordSales: 3482
        },
        {
          date: '2024-05-14',
          searchVolume: 282222,
          organicRank: 21,
          sponsoredRank: 27,
          keywordSales: 3482
        },
        {
          date: '2024-05-13',
          searchVolume: 282222,
          organicRank: 19,
          sponsoredRank: 37,
          keywordSales: 3482
        },
        {
          date: '2024-05-12',
          searchVolume: 282222,
          organicRank: 20,
          sponsoredRank: 19,
          keywordSales: 3482
        },
        {
          date: '2024-05-11',
          searchVolume: 282222,
          organicRank: 19,
          sponsoredRank: 50,
          keywordSales: 3482
        },
        {
          date: '2024-05-10',
          searchVolume: 315371,
          organicRank: 20,

          keywordSales: 3482
        },
        {
          date: '2024-05-09',
          searchVolume: 315371,
          organicRank: 19,
          sponsoredRank: 52,
          keywordSales: 3482
        },
        {
          date: '2024-05-08',
          searchVolume: 315371,
          organicRank: 21,

          keywordSales: 3482
        },
        {
          date: '2024-05-07',
          searchVolume: 315371,
          organicRank: 19,

          keywordSales: 3482
        },
        {
          date: '2024-05-06',
          searchVolume: 315371,
          organicRank: 306,

          keywordSales: 3482
        },
        {
          date: '2024-05-05',
          searchVolume: 315371,
          organicRank: 37,

          keywordSales: 3482
        },
        {
          date: '2024-05-04',
          searchVolume: 315371,
          organicRank: 69,

          keywordSales: 3482
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard 60',
      keyword: 'keyboard 60',
      searchVolume: 472,
      organicStartRank: 10,
      organicHighestRank: 10,
      sponsoredStartRank: 61,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-05-06',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 507,
          organicRank: 16,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 507,
          organicRank: 11,

          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 507,
          organicRank: 11,
          sponsoredRank: 64,
          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 507,
          organicRank: 11,
          sponsoredRank: 31,
          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 507,
          organicRank: 15,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 507,
          organicRank: 14,

          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 491,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 491,
          organicRank: 20,

          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 491,
          organicRank: 18,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 491,
          organicRank: 18,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 491,
          organicRank: 18,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 491,
          organicRank: 22,
          sponsoredRank: 74,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 491,
          organicRank: 19,

          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 484,
          organicRank: 19,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 484,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 484,
          organicRank: 18,

          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 484,
          organicRank: 17,

          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 484,
          organicRank: 20,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 484,
          organicRank: 23,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 484,
          organicRank: 20,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 483,
          organicRank: 20,
          sponsoredRank: 57,
          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 483,
          organicRank: 15,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 483,
          organicRank: 21,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 483,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 483,
          organicRank: 19,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 483,
          organicRank: 21,
          sponsoredRank: 80,
          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 483,
          organicRank: 14,

          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 472,
          organicRank: 17,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 472,
          organicRank: 20,

          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 472,
          organicRank: 21,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 472,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 472,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 472,
          organicRank: 27,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 472,
          organicRank: 25,

          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 488,
          organicRank: 26,
          sponsoredRank: 4,
          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 488,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 488,
          organicRank: 22,

          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 488,
          organicRank: 25,

          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 488,
          organicRank: 18,
          sponsoredRank: 56,
          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 488,
          organicRank: 20,

          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 488,
          organicRank: 18,

          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 509,
          organicRank: 20,

          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 509,
          organicRank: 21,
          sponsoredRank: 61,
          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 509,
          organicRank: 23,

          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 509,
          organicRank: 21,

          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 509,
          organicRank: 10,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard 60 percent',
      keyword: 'keyboard 60 percent',
      searchVolume: 2153,
      organicStartRank: 27,
      organicHighestRank: 6,
      sponsoredStartRank: 67,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-05-07',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1928,
          organicRank: 13,

          keywordSales: 50
        },
        {
          date: '2024-06-19',
          searchVolume: 1928,
          organicRank: 9,
          sponsoredRank: 70,
          keywordSales: 50
        },
        {
          date: '2024-06-18',
          searchVolume: 1928,
          organicRank: 10,

          keywordSales: 50
        },
        {
          date: '2024-06-17',
          searchVolume: 1928,
          organicRank: 8,

          keywordSales: 50
        },
        {
          date: '2024-06-16',
          searchVolume: 1928,
          organicRank: 13,

          keywordSales: 50
        },
        {
          date: '2024-06-15',
          searchVolume: 1928,
          organicRank: 18,
          sponsoredRank: 26,
          keywordSales: 50
        },
        {
          date: '2024-06-14',
          searchVolume: 1930,
          organicRank: 12,

          keywordSales: 50
        },
        {
          date: '2024-06-13',
          searchVolume: 1930,
          organicRank: 11,

          keywordSales: 50
        },
        {
          date: '2024-06-12',
          searchVolume: 1930,
          organicRank: 8,

          keywordSales: 50
        },
        {
          date: '2024-06-11',
          searchVolume: 1930,
          organicRank: 9,

          keywordSales: 50
        },
        {
          date: '2024-06-10',
          searchVolume: 1930,
          organicRank: 11,

          keywordSales: 50
        },
        {
          date: '2024-06-09',
          searchVolume: 1930,
          organicRank: 7,

          keywordSales: 50
        },
        {
          date: '2024-06-08',
          searchVolume: 1930,
          organicRank: 13,

          keywordSales: 50
        },
        {
          date: '2024-06-07',
          searchVolume: 1765,
          organicRank: 11,

          keywordSales: 50
        },
        {
          date: '2024-06-06',
          searchVolume: 1765,
          organicRank: 6,

          keywordSales: 50
        },
        {
          date: '2024-06-05',
          searchVolume: 1765,
          organicRank: 12,
          sponsoredRank: 46,
          keywordSales: 50
        },
        {
          date: '2024-06-04',
          searchVolume: 1765,
          organicRank: 13,

          keywordSales: 50
        },
        {
          date: '2024-06-03',
          searchVolume: 1765,
          organicRank: 17,

          keywordSales: 50
        },
        {
          date: '2024-06-02',
          searchVolume: 1765,
          organicRank: 14,

          keywordSales: 50
        },
        {
          date: '2024-06-01',
          searchVolume: 1765,
          organicRank: 18,

          keywordSales: 50
        },
        {
          date: '2024-05-31',
          searchVolume: 1891,
          organicRank: 21,
          sponsoredRank: 56,
          keywordSales: 50
        },
        {
          date: '2024-05-30',
          searchVolume: 1891,
          organicRank: 14,

          keywordSales: 50
        },
        {
          date: '2024-05-29',
          searchVolume: 1891,
          organicRank: 12,

          keywordSales: 50
        },
        {
          date: '2024-05-28',
          searchVolume: 1891,
          organicRank: 19,

          keywordSales: 50
        },
        {
          date: '2024-05-27',
          searchVolume: 1891,
          organicRank: 12,

          keywordSales: 50
        },
        {
          date: '2024-05-26',
          searchVolume: 1891,
          organicRank: 16,
          sponsoredRank: 52,
          keywordSales: 50
        },
        {
          date: '2024-05-25',
          searchVolume: 1891,
          organicRank: 20,
          sponsoredRank: 40,
          keywordSales: 50
        },
        {
          date: '2024-05-24',
          searchVolume: 2153,
          organicRank: 17,

          keywordSales: 50
        },
        {
          date: '2024-05-23',
          searchVolume: 2153,
          organicRank: 19,

          keywordSales: 50
        },
        {
          date: '2024-05-22',
          searchVolume: 2153,
          organicRank: 12,
          sponsoredRank: 2,
          keywordSales: 50
        },
        {
          date: '2024-05-21',
          searchVolume: 2153,
          organicRank: 15,

          keywordSales: 50
        },
        {
          date: '2024-05-20',
          searchVolume: 2153,
          organicRank: 17,

          keywordSales: 50
        },
        {
          date: '2024-05-19',
          searchVolume: 2153,
          organicRank: 13,

          keywordSales: 50
        },
        {
          date: '2024-05-18',
          searchVolume: 2153,
          organicRank: 15,

          keywordSales: 50
        },
        {
          date: '2024-05-17',
          searchVolume: 2387,
          organicRank: 33,
          sponsoredRank: 52,
          keywordSales: 50
        },
        {
          date: '2024-05-16',
          searchVolume: 2387,
          organicRank: 16,
          sponsoredRank: 18,
          keywordSales: 50
        },
        {
          date: '2024-05-15',
          searchVolume: 2387,
          organicRank: 15,
          sponsoredRank: 39,
          keywordSales: 50
        },
        {
          date: '2024-05-14',
          searchVolume: 2387,
          organicRank: 17,
          sponsoredRank: 31,
          keywordSales: 50
        },
        {
          date: '2024-05-13',
          searchVolume: 2387,
          organicRank: 15,
          sponsoredRank: 63,
          keywordSales: 50
        },
        {
          date: '2024-05-12',
          searchVolume: 2387,
          organicRank: 16,
          sponsoredRank: 29,
          keywordSales: 50
        },
        {
          date: '2024-05-11',
          searchVolume: 2387,
          organicRank: 15,
          sponsoredRank: 18,
          keywordSales: 50
        },
        {
          date: '2024-05-10',
          searchVolume: 2171,
          organicRank: 15,
          sponsoredRank: 45,
          keywordSales: 50
        },
        {
          date: '2024-05-09',
          searchVolume: 2171,
          organicRank: 15,
          sponsoredRank: 46,
          keywordSales: 50
        },
        {
          date: '2024-05-08',
          searchVolume: 2171,
          organicRank: 13,
          sponsoredRank: 75,
          keywordSales: 50
        },
        {
          date: '2024-05-07',
          searchVolume: 2171,
          organicRank: 14,

          keywordSales: 50
        },
        {
          date: '2024-05-06',
          searchVolume: 2171,
          organicRank: 28,
          sponsoredRank: 61,
          keywordSales: 50
        },
        {
          date: '2024-05-05',
          searchVolume: 2171,
          organicRank: 23,

          keywordSales: 50
        },
        {
          date: '2024-05-04',
          searchVolume: 2171,
          organicRank: 27,
          sponsoredRank: 67,
          keywordSales: 50
        },
        {
          date: '2024-05-03',
          searchVolume: 2433,
          organicRank: 14,

          keywordSales: 50
        },
        {
          date: '2024-05-02',
          searchVolume: 2433,
          organicRank: 13,
          sponsoredRank: 31,
          keywordSales: 50
        },
        {
          date: '2024-05-01',
          searchVolume: 2433,
          organicRank: 18,
          sponsoredRank: 55,
          keywordSales: 50
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard 75',
      keyword: 'keyboard 75',
      searchVolume: 423,
      organicStartRank: 56,
      organicHighestRank: 37,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 498,
          organicRank: 102,

          keywordSales: 2
        },
        {
          date: '2024-06-19',
          searchVolume: 498,
          organicRank: 99,

          keywordSales: 2
        },
        {
          date: '2024-06-18',
          searchVolume: 498,
          organicRank: 98,

          keywordSales: 2
        },
        {
          date: '2024-06-17',
          searchVolume: 498,
          organicRank: 94,

          keywordSales: 2
        },
        {
          date: '2024-06-16',
          searchVolume: 498,
          organicRank: 94,

          keywordSales: 2
        },
        {
          date: '2024-06-15',
          searchVolume: 498,
          organicRank: 93,

          keywordSales: 2
        },
        {
          date: '2024-06-14',
          searchVolume: 489,
          organicRank: 80,

          keywordSales: 2
        },
        {
          date: '2024-06-13',
          searchVolume: 489,
          organicRank: 82,

          keywordSales: 2
        },
        {
          date: '2024-06-12',
          searchVolume: 489,
          organicRank: 67,

          keywordSales: 2
        },
        {
          date: '2024-06-11',
          searchVolume: 489,
          organicRank: 64,

          keywordSales: 2
        },
        {
          date: '2024-06-10',
          searchVolume: 489,
          organicRank: 80,

          keywordSales: 2
        },
        {
          date: '2024-06-09',
          searchVolume: 489,
          organicRank: 88,
          sponsoredRank: 66,
          keywordSales: 2
        },
        {
          date: '2024-06-08',
          searchVolume: 489,
          organicRank: 48,

          keywordSales: 2
        },
        {
          date: '2024-06-07',
          searchVolume: 452,
          organicRank: 65,

          keywordSales: 2
        },
        {
          date: '2024-06-06',
          searchVolume: 452,
          organicRank: 82,

          keywordSales: 2
        },
        {
          date: '2024-06-05',
          searchVolume: 452,
          organicRank: 63,
          sponsoredRank: 13,
          keywordSales: 2
        },
        {
          date: '2024-06-04',
          searchVolume: 452,
          organicRank: 78,

          keywordSales: 2
        },
        {
          date: '2024-06-03',
          searchVolume: 452,
          organicRank: 60,

          keywordSales: 2
        },
        {
          date: '2024-06-02',
          searchVolume: 452,
          organicRank: 53,

          keywordSales: 2
        },
        {
          date: '2024-06-01',
          searchVolume: 452,
          organicRank: 67,

          keywordSales: 2
        },
        {
          date: '2024-05-31',
          searchVolume: 458,
          organicRank: 96,

          keywordSales: 2
        },
        {
          date: '2024-05-30',
          searchVolume: 458,
          organicRank: 93,

          keywordSales: 2
        },
        {
          date: '2024-05-29',
          searchVolume: 458,
          organicRank: 91,

          keywordSales: 2
        },
        {
          date: '2024-05-28',
          searchVolume: 458,
          organicRank: 54,

          keywordSales: 2
        },
        {
          date: '2024-05-27',
          searchVolume: 458,
          organicRank: 85,

          keywordSales: 2
        },
        {
          date: '2024-05-26',
          searchVolume: 458,
          organicRank: 93,

          keywordSales: 2
        },
        {
          date: '2024-05-25',
          searchVolume: 458,
          organicRank: 91,

          keywordSales: 2
        },
        {
          date: '2024-05-24',
          searchVolume: 423,
          organicRank: 86,

          keywordSales: 2
        },
        {
          date: '2024-05-23',
          searchVolume: 423,
          organicRank: 78,

          keywordSales: 2
        },
        {
          date: '2024-05-22',
          searchVolume: 423,
          organicRank: 92,

          keywordSales: 2
        },
        {
          date: '2024-05-21',
          searchVolume: 423,
          organicRank: 306,

          keywordSales: 2
        },
        {
          date: '2024-05-20',
          searchVolume: 423,
          organicRank: 89,

          keywordSales: 2
        },
        {
          date: '2024-05-19',
          searchVolume: 423,
          organicRank: 89,

          keywordSales: 2
        },
        {
          date: '2024-05-18',
          searchVolume: 423,
          organicRank: 94,

          keywordSales: 2
        },
        {
          date: '2024-05-17',
          searchVolume: 439,
          organicRank: 87,

          keywordSales: 2
        },
        {
          date: '2024-05-16',
          searchVolume: 439,
          organicRank: 80,

          keywordSales: 2
        },
        {
          date: '2024-05-15',
          searchVolume: 439,
          organicRank: 97,

          keywordSales: 2
        },
        {
          date: '2024-05-14',
          searchVolume: 439,
          organicRank: 82,

          keywordSales: 2
        },
        {
          date: '2024-05-13',
          searchVolume: 439,
          organicRank: 64,

          keywordSales: 2
        },
        {
          date: '2024-05-12',
          searchVolume: 439,
          organicRank: 80,

          keywordSales: 2
        },
        {
          date: '2024-05-11',
          searchVolume: 439,
          organicRank: 51,

          keywordSales: 2
        },
        {
          date: '2024-05-10',
          searchVolume: 400,
          organicRank: 57,

          keywordSales: 2
        },
        {
          date: '2024-05-09',
          searchVolume: 400,
          organicRank: 40,

          keywordSales: 2
        },
        {
          date: '2024-05-08',
          searchVolume: 400,
          organicRank: 48,

          keywordSales: 2
        },
        {
          date: '2024-05-07',
          searchVolume: 400,
          organicRank: 49,

          keywordSales: 2
        },
        {
          date: '2024-05-06',
          searchVolume: 400,
          organicRank: 37,

          keywordSales: 2
        },
        {
          date: '2024-05-05',
          searchVolume: 400,
          organicRank: 41,

          keywordSales: 2
        },
        {
          date: '2024-05-04',
          searchVolume: 400,
          organicRank: 56,

          keywordSales: 2
        },
        {
          date: '2024-05-03',
          searchVolume: 443,
          organicRank: 46,

          keywordSales: 2
        },
        {
          date: '2024-05-02',
          searchVolume: 443,
          organicRank: 48,

          keywordSales: 2
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard and mouse gaming',
      keyword: 'keyboard and mouse gaming',
      searchVolume: 2509,
      organicStartRank: 243,
      organicHighestRank: 118,
      sponsoredStartRank: 24,
      sponsoredHighestRank: 15,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-19',
          searchVolume: 2816,
          organicRank: 242,
          sponsoredRank: 68,
          keywordSales: 30
        },
        {
          date: '2024-06-18',
          searchVolume: 2816,
          organicRank: 193,

          keywordSales: 30
        },
        {
          date: '2024-06-17',
          searchVolume: 2816,
          organicRank: 225,

          keywordSales: 30
        },
        {
          date: '2024-06-16',
          searchVolume: 2816,
          organicRank: 226,

          keywordSales: 30
        },
        {
          date: '2024-06-15',
          searchVolume: 2816,
          organicRank: 182,
          sponsoredRank: 38,
          keywordSales: 30
        },
        {
          date: '2024-06-14',
          searchVolume: 2431,
          organicRank: 197,
          sponsoredRank: 40,
          keywordSales: 30
        },
        {
          date: '2024-06-13',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-12',
          searchVolume: 2431,
          organicRank: 186,

          keywordSales: 30
        },
        {
          date: '2024-06-11',
          searchVolume: 2431,
          organicRank: 161,

          keywordSales: 30
        },
        {
          date: '2024-06-10',
          searchVolume: 2431,
          organicRank: 209,

          keywordSales: 30
        },
        {
          date: '2024-06-09',
          searchVolume: 2431,
          organicRank: 193,

          keywordSales: 30
        },
        {
          date: '2024-06-08',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-07',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-06',
          searchVolume: 2433,
          organicRank: 250,

          keywordSales: 30
        },
        {
          date: '2024-06-05',
          searchVolume: 2433,
          organicRank: 160,
          sponsoredRank: 20,
          keywordSales: 30
        },
        {
          date: '2024-06-04',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-03',
          searchVolume: 2433,
          organicRank: 170,

          keywordSales: 30
        },
        {
          date: '2024-06-02',
          searchVolume: 2433,
          organicRank: 196,

          keywordSales: 30
        },
        {
          date: '2024-06-01',
          searchVolume: 2433,
          organicRank: 253,

          keywordSales: 30
        },
        {
          date: '2024-05-31',
          searchVolume: 2511,
          organicRank: 306,
          sponsoredRank: 62,
          keywordSales: 30
        },
        {
          date: '2024-05-30',
          searchVolume: 2511,
          organicRank: 245,
          sponsoredRank: 22,
          keywordSales: 30
        },
        {
          date: '2024-05-29',
          searchVolume: 2511,
          organicRank: 211,

          keywordSales: 30
        },
        {
          date: '2024-05-28',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-27',
          searchVolume: 2511,
          organicRank: 248,

          keywordSales: 30
        },
        {
          date: '2024-05-26',
          searchVolume: 2511,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 30
        },
        {
          date: '2024-05-25',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-24',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-23',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-22',
          searchVolume: 2509,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 30
        },
        {
          date: '2024-05-21',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-20',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-19',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-18',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-17',
          searchVolume: 2357,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 30
        },
        {
          date: '2024-05-16',
          searchVolume: 2357,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-15',
          searchVolume: 2357,
          organicRank: 170,

          keywordSales: 30
        },
        {
          date: '2024-05-14',
          searchVolume: 2357,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-13',
          searchVolume: 2357,
          organicRank: 231,
          sponsoredRank: 87,
          keywordSales: 30
        },
        {
          date: '2024-05-12',
          searchVolume: 2357,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-11',
          searchVolume: 2357,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-10',
          searchVolume: 3135,
          organicRank: 174,

          keywordSales: 30
        },
        {
          date: '2024-05-09',
          searchVolume: 3135,
          organicRank: 198,

          keywordSales: 30
        },
        {
          date: '2024-05-08',
          searchVolume: 3135,
          organicRank: 118,

          keywordSales: 30
        },
        {
          date: '2024-05-07',
          searchVolume: 3135,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-06',
          searchVolume: 3135,
          organicRank: 232,

          keywordSales: 30
        },
        {
          date: '2024-05-05',
          searchVolume: 3135,
          organicRank: 231,
          sponsoredRank: 24,
          keywordSales: 30
        },
        {
          date: '2024-05-04',
          searchVolume: 3135,
          organicRank: 243,

          keywordSales: 30
        },
        {
          date: '2024-05-01',
          searchVolume: 3093,
          organicRank: 209,

          keywordSales: 30
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard for gaming',
      keyword: 'keyboard for gaming',
      searchVolume: 937,
      organicStartRank: 55,
      organicHighestRank: 21,
      sponsoredStartRank: 26,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 884,
          organicRank: 55,
          sponsoredRank: 51,
          keywordSales: 7
        },
        {
          date: '2024-06-19',
          searchVolume: 884,
          organicRank: 44,
          sponsoredRank: 29,
          keywordSales: 7
        },
        {
          date: '2024-06-18',
          searchVolume: 884,
          organicRank: 46,
          sponsoredRank: 52,
          keywordSales: 7
        },
        {
          date: '2024-06-17',
          searchVolume: 884,
          organicRank: 33,
          sponsoredRank: 74,
          keywordSales: 7
        },
        {
          date: '2024-06-16',
          searchVolume: 884,
          organicRank: 31,

          keywordSales: 7
        },
        {
          date: '2024-06-15',
          searchVolume: 884,
          organicRank: 27,
          sponsoredRank: 47,
          keywordSales: 7
        },
        {
          date: '2024-06-14',
          searchVolume: 1221,
          organicRank: 35,
          sponsoredRank: 67,
          keywordSales: 7
        },
        {
          date: '2024-06-13',
          searchVolume: 1221,
          organicRank: 31,

          keywordSales: 7
        },
        {
          date: '2024-06-12',
          searchVolume: 1221,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-11',
          searchVolume: 1221,
          organicRank: 37,

          keywordSales: 7
        },
        {
          date: '2024-06-10',
          searchVolume: 1221,
          organicRank: 27,

          keywordSales: 7
        },
        {
          date: '2024-06-09',
          searchVolume: 1221,
          organicRank: 28,
          sponsoredRank: 30,
          keywordSales: 7
        },
        {
          date: '2024-06-08',
          searchVolume: 1221,
          organicRank: 28,

          keywordSales: 7
        },
        {
          date: '2024-06-07',
          searchVolume: 845,
          organicRank: 96,

          keywordSales: 7
        },
        {
          date: '2024-06-06',
          searchVolume: 845,
          organicRank: 25,

          keywordSales: 7
        },
        {
          date: '2024-06-05',
          searchVolume: 845,
          organicRank: 21,
          sponsoredRank: 81,
          keywordSales: 7
        },
        {
          date: '2024-06-04',
          searchVolume: 845,
          organicRank: 22,

          keywordSales: 7
        },
        {
          date: '2024-06-03',
          searchVolume: 845,
          organicRank: 28,

          keywordSales: 7
        },
        {
          date: '2024-06-02',
          searchVolume: 845,
          organicRank: 26,

          keywordSales: 7
        },
        {
          date: '2024-06-01',
          searchVolume: 845,
          organicRank: 93,
          sponsoredRank: 78,
          keywordSales: 7
        },
        {
          date: '2024-05-31',
          searchVolume: 736,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 7
        },
        {
          date: '2024-05-30',
          searchVolume: 736,
          organicRank: 38,

          keywordSales: 7
        },
        {
          date: '2024-05-29',
          searchVolume: 736,
          organicRank: 84,

          keywordSales: 7
        },
        {
          date: '2024-05-28',
          searchVolume: 736,
          organicRank: 33,

          keywordSales: 7
        },
        {
          date: '2024-05-27',
          searchVolume: 736,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-05-26',
          searchVolume: 736,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 7
        },
        {
          date: '2024-05-25',
          searchVolume: 736,
          organicRank: 103,

          keywordSales: 7
        },
        {
          date: '2024-05-24',
          searchVolume: 937,
          organicRank: 38,

          keywordSales: 7
        },
        {
          date: '2024-05-23',
          searchVolume: 937,
          organicRank: 31,

          keywordSales: 7
        },
        {
          date: '2024-05-22',
          searchVolume: 937,
          organicRank: 63,

          keywordSales: 7
        },
        {
          date: '2024-05-21',
          searchVolume: 937,
          organicRank: 40,

          keywordSales: 7
        },
        {
          date: '2024-05-20',
          searchVolume: 937,
          organicRank: 31,
          sponsoredRank: 97,
          keywordSales: 7
        },
        {
          date: '2024-05-19',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-05-18',
          searchVolume: 937,
          organicRank: 34,

          keywordSales: 7
        },
        {
          date: '2024-05-17',
          searchVolume: 734,
          organicRank: 306,
          sponsoredRank: 4,
          keywordSales: 7
        },
        {
          date: '2024-05-16',
          searchVolume: 734,
          organicRank: 30,

          keywordSales: 7
        },
        {
          date: '2024-05-15',
          searchVolume: 734,
          organicRank: 26,
          sponsoredRank: 23,
          keywordSales: 7
        },
        {
          date: '2024-05-14',
          searchVolume: 734,
          organicRank: 101,

          keywordSales: 7
        },
        {
          date: '2024-05-13',
          searchVolume: 734,
          organicRank: 41,
          sponsoredRank: 29,
          keywordSales: 7
        },
        {
          date: '2024-05-12',
          searchVolume: 734,
          organicRank: 38,
          sponsoredRank: 72,
          keywordSales: 7
        },
        {
          date: '2024-05-11',
          searchVolume: 734,
          organicRank: 38,
          sponsoredRank: 54,
          keywordSales: 7
        },
        {
          date: '2024-05-10',
          searchVolume: 886,
          organicRank: 101,

          keywordSales: 7
        },
        {
          date: '2024-05-09',
          searchVolume: 886,
          organicRank: 30,
          sponsoredRank: 54,
          keywordSales: 7
        },
        {
          date: '2024-05-08',
          searchVolume: 886,
          organicRank: 29,

          keywordSales: 7
        },
        {
          date: '2024-05-07',
          searchVolume: 886,
          organicRank: 26,

          keywordSales: 7
        },
        {
          date: '2024-05-06',
          searchVolume: 886,
          organicRank: 83,

          keywordSales: 7
        },
        {
          date: '2024-05-05',
          searchVolume: 886,
          organicRank: 53,

          keywordSales: 7
        },
        {
          date: '2024-05-04',
          searchVolume: 886,
          organicRank: 55,
          sponsoredRank: 26,
          keywordSales: 7
        },
        {
          date: '2024-05-03',
          searchVolume: 1210,
          organicRank: 26,
          sponsoredRank: 55,
          keywordSales: 7
        },
        {
          date: '2024-05-02',
          searchVolume: 1210,
          organicRank: 70,
          sponsoredRank: 6,
          keywordSales: 7
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard gaming',
      keyword: 'keyboard gaming',
      searchVolume: 9659,
      organicStartRank: 53,
      organicHighestRank: 29,
      sponsoredStartRank: 69,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 10725,
          organicRank: 43,

          keywordSales: 86
        },
        {
          date: '2024-06-20',
          searchVolume: 10725,
          organicRank: 68,

          keywordSales: 86
        },
        {
          date: '2024-06-19',
          searchVolume: 10725,
          organicRank: 54,

          keywordSales: 86
        },
        {
          date: '2024-06-18',
          searchVolume: 10725,
          organicRank: 57,

          keywordSales: 86
        },
        {
          date: '2024-06-17',
          searchVolume: 10725,
          organicRank: 80,

          keywordSales: 86
        },
        {
          date: '2024-06-16',
          searchVolume: 10725,
          organicRank: 56,

          keywordSales: 86
        },
        {
          date: '2024-06-15',
          searchVolume: 10725,
          organicRank: 103,
          sponsoredRank: 5,
          keywordSales: 86
        },
        {
          date: '2024-06-14',
          searchVolume: 9277,
          organicRank: 34,

          keywordSales: 86
        },
        {
          date: '2024-06-13',
          searchVolume: 9277,
          organicRank: 92,

          keywordSales: 86
        },
        {
          date: '2024-06-12',
          searchVolume: 9277,
          organicRank: 57,

          keywordSales: 86
        },
        {
          date: '2024-06-11',
          searchVolume: 9277,
          organicRank: 49,

          keywordSales: 86
        },
        {
          date: '2024-06-10',
          searchVolume: 9277,
          organicRank: 46,

          keywordSales: 86
        },
        {
          date: '2024-06-09',
          searchVolume: 9277,
          organicRank: 52,
          sponsoredRank: 99,
          keywordSales: 86
        },
        {
          date: '2024-06-08',
          searchVolume: 9277,
          organicRank: 54,

          keywordSales: 86
        },
        {
          date: '2024-06-07',
          searchVolume: 8092,
          organicRank: 35,

          keywordSales: 86
        },
        {
          date: '2024-06-06',
          searchVolume: 8092,
          organicRank: 42,

          keywordSales: 86
        },
        {
          date: '2024-06-05',
          searchVolume: 8092,
          organicRank: 29,
          sponsoredRank: 13,
          keywordSales: 86
        },
        {
          date: '2024-06-04',
          searchVolume: 8092,
          organicRank: 34,

          keywordSales: 86
        },
        {
          date: '2024-06-03',
          searchVolume: 8092,
          organicRank: 41,

          keywordSales: 86
        },
        {
          date: '2024-06-02',
          searchVolume: 8092,
          organicRank: 53,

          keywordSales: 86
        },
        {
          date: '2024-06-01',
          searchVolume: 8092,
          organicRank: 51,

          keywordSales: 86
        },
        {
          date: '2024-05-31',
          searchVolume: 7595,
          organicRank: 52,
          sponsoredRank: 7,
          keywordSales: 86
        },
        {
          date: '2024-05-30',
          searchVolume: 7595,
          organicRank: 39,

          keywordSales: 86
        },
        {
          date: '2024-05-29',
          searchVolume: 7595,
          organicRank: 45,

          keywordSales: 86
        },
        {
          date: '2024-05-28',
          searchVolume: 7595,
          organicRank: 45,

          keywordSales: 86
        },
        {
          date: '2024-05-27',
          searchVolume: 7595,
          organicRank: 51,

          keywordSales: 86
        },
        {
          date: '2024-05-26',
          searchVolume: 7595,
          organicRank: 43,
          sponsoredRank: 12,
          keywordSales: 86
        },
        {
          date: '2024-05-25',
          searchVolume: 7595,
          organicRank: 44,
          sponsoredRank: 53,
          keywordSales: 86
        },
        {
          date: '2024-05-24',
          searchVolume: 8353,
          organicRank: 34,
          sponsoredRank: 17,
          keywordSales: 86
        },
        {
          date: '2024-05-23',
          searchVolume: 8353,
          organicRank: 55,

          keywordSales: 86
        },
        {
          date: '2024-05-22',
          searchVolume: 8353,
          organicRank: 42,
          sponsoredRank: 2,
          keywordSales: 86
        },
        {
          date: '2024-05-21',
          searchVolume: 8353,
          organicRank: 52,

          keywordSales: 86
        },
        {
          date: '2024-05-20',
          searchVolume: 8353,
          organicRank: 52,

          keywordSales: 86
        },
        {
          date: '2024-05-19',
          searchVolume: 8353,
          organicRank: 55,
          sponsoredRank: 30,
          keywordSales: 86
        },
        {
          date: '2024-05-18',
          searchVolume: 8353,
          organicRank: 51,
          sponsoredRank: 17,
          keywordSales: 86
        },
        {
          date: '2024-05-17',
          searchVolume: 8873,
          organicRank: 52,

          keywordSales: 86
        },
        {
          date: '2024-05-16',
          searchVolume: 8873,
          organicRank: 51,
          sponsoredRank: 5,
          keywordSales: 86
        },
        {
          date: '2024-05-15',
          searchVolume: 8873,
          organicRank: 43,
          sponsoredRank: 79,
          keywordSales: 86
        },
        {
          date: '2024-05-14',
          searchVolume: 8873,
          organicRank: 35,
          sponsoredRank: 3,
          keywordSales: 86
        },
        {
          date: '2024-05-13',
          searchVolume: 8873,
          organicRank: 42,
          sponsoredRank: 42,
          keywordSales: 86
        },
        {
          date: '2024-05-12',
          searchVolume: 8873,
          organicRank: 306,
          sponsoredRank: 52,
          keywordSales: 86
        },
        {
          date: '2024-05-11',
          searchVolume: 8873,
          organicRank: 47,
          sponsoredRank: 16,
          keywordSales: 86
        },
        {
          date: '2024-05-10',
          searchVolume: 9659,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 86
        },
        {
          date: '2024-05-09',
          searchVolume: 9659,
          organicRank: 38,
          sponsoredRank: 89,
          keywordSales: 86
        },
        {
          date: '2024-05-08',
          searchVolume: 9659,
          organicRank: 40,
          sponsoredRank: 59,
          keywordSales: 86
        },
        {
          date: '2024-05-07',
          searchVolume: 9659,
          organicRank: 64,
          sponsoredRank: 77,
          keywordSales: 86
        },
        {
          date: '2024-05-06',
          searchVolume: 9659,
          organicRank: 53,
          sponsoredRank: 95,
          keywordSales: 86
        },
        {
          date: '2024-05-05',
          searchVolume: 9659,
          organicRank: 69,
          sponsoredRank: 72,
          keywordSales: 86
        },
        {
          date: '2024-05-04',
          searchVolume: 9659,
          organicRank: 53,
          sponsoredRank: 69,
          keywordSales: 86
        },
        {
          date: '2024-05-03',
          searchVolume: 10285,
          organicRank: 62,

          keywordSales: 86
        },
        {
          date: '2024-05-02',
          searchVolume: 10285,
          organicRank: 70,

          keywordSales: 86
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard light',
      keyword: 'keyboard light',
      searchVolume: 1349,
      organicStartRank: 141,
      organicHighestRank: 132,
      sponsoredStartRank: 82,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-19',
          searchVolume: 1222,
          organicRank: 306,
          sponsoredRank: 64,
          keywordSales: 22
        },
        {
          date: '2024-06-18',
          searchVolume: 1222,
          organicRank: 306,
          sponsoredRank: 100,
          keywordSales: 22
        },
        {
          date: '2024-06-17',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-16',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-15',
          searchVolume: 1222,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 22
        },
        {
          date: '2024-06-14',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-13',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-12',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-11',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-10',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-09',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-08',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-07',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-06',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-05',
          searchVolume: 1345,
          organicRank: 306,
          sponsoredRank: 58,
          keywordSales: 22
        },
        {
          date: '2024-06-04',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-03',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-02',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-01',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-31',
          searchVolume: 1328,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 22
        },
        {
          date: '2024-05-30',
          searchVolume: 1328,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-29',
          searchVolume: 1328,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-28',
          searchVolume: 1328,
          organicRank: 306,
          sponsoredRank: 70,
          keywordSales: 22
        },
        {
          date: '2024-05-27',
          searchVolume: 1328,
          organicRank: 306,
          sponsoredRank: 58,
          keywordSales: 22
        },
        {
          date: '2024-05-26',
          searchVolume: 1328,
          organicRank: 306,
          sponsoredRank: 63,
          keywordSales: 22
        },
        {
          date: '2024-05-25',
          searchVolume: 1328,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-24',
          searchVolume: 1328,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-23',
          searchVolume: 1328,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-22',
          searchVolume: 1328,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-21',
          searchVolume: 1328,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-20',
          searchVolume: 1328,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-19',
          searchVolume: 1328,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-18',
          searchVolume: 1328,
          organicRank: 306,
          sponsoredRank: 63,
          keywordSales: 22
        },
        {
          date: '2024-05-17',
          searchVolume: 1390,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 22
        },
        {
          date: '2024-05-16',
          searchVolume: 1390,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-15',
          searchVolume: 1390,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-14',
          searchVolume: 1390,
          organicRank: 138,

          keywordSales: 22
        },
        {
          date: '2024-05-13',
          searchVolume: 1390,
          organicRank: 225,
          sponsoredRank: 76,
          keywordSales: 22
        },
        {
          date: '2024-05-12',
          searchVolume: 1390,
          organicRank: 132,

          keywordSales: 22
        },
        {
          date: '2024-05-11',
          searchVolume: 1390,
          organicRank: 153,

          keywordSales: 22
        },
        {
          date: '2024-05-10',
          searchVolume: 1349,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-09',
          searchVolume: 1349,
          organicRank: 144,
          sponsoredRank: 70,
          keywordSales: 22
        },
        {
          date: '2024-05-08',
          searchVolume: 1349,
          organicRank: 144,
          sponsoredRank: 82,
          keywordSales: 22
        },
        {
          date: '2024-05-07',
          searchVolume: 1349,
          organicRank: 142,

          keywordSales: 22
        },
        {
          date: '2024-05-06',
          searchVolume: 1349,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-03',
          searchVolume: 1373,
          organicRank: 141,
          sponsoredRank: 82,
          keywordSales: 22
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard mechanical',
      keyword: 'keyboard mechanical',
      searchVolume: 4479,
      organicStartRank: 32,
      organicHighestRank: 29,
      sponsoredStartRank: 55,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 4905,
          organicRank: 78,

          keywordSales: 53
        },
        {
          date: '2024-06-19',
          searchVolume: 4905,
          organicRank: 89,

          keywordSales: 53
        },
        {
          date: '2024-06-18',
          searchVolume: 4905,
          organicRank: 51,
          sponsoredRank: 29,
          keywordSales: 53
        },
        {
          date: '2024-06-17',
          searchVolume: 4905,
          organicRank: 84,
          sponsoredRank: 47,
          keywordSales: 53
        },
        {
          date: '2024-06-16',
          searchVolume: 4905,
          organicRank: 89,
          sponsoredRank: 77,
          keywordSales: 53
        },
        {
          date: '2024-06-15',
          searchVolume: 4905,
          organicRank: 90,
          sponsoredRank: 60,
          keywordSales: 53
        },
        {
          date: '2024-06-14',
          searchVolume: 4550,
          organicRank: 55,
          sponsoredRank: 75,
          keywordSales: 53
        },
        {
          date: '2024-06-13',
          searchVolume: 4550,
          organicRank: 43,
          sponsoredRank: 33,
          keywordSales: 53
        },
        {
          date: '2024-06-12',
          searchVolume: 4550,
          organicRank: 60,

          keywordSales: 53
        },
        {
          date: '2024-06-11',
          searchVolume: 4550,
          organicRank: 81,

          keywordSales: 53
        },
        {
          date: '2024-06-10',
          searchVolume: 4550,
          organicRank: 56,

          keywordSales: 53
        },
        {
          date: '2024-06-09',
          searchVolume: 4550,
          organicRank: 67,
          sponsoredRank: 81,
          keywordSales: 53
        },
        {
          date: '2024-06-08',
          searchVolume: 4550,
          organicRank: 67,
          sponsoredRank: 67,
          keywordSales: 53
        },
        {
          date: '2024-06-07',
          searchVolume: 4342,
          organicRank: 71,

          keywordSales: 53
        },
        {
          date: '2024-06-06',
          searchVolume: 4342,
          organicRank: 57,

          keywordSales: 53
        },
        {
          date: '2024-06-05',
          searchVolume: 4342,
          organicRank: 54,

          keywordSales: 53
        },
        {
          date: '2024-06-04',
          searchVolume: 4342,
          organicRank: 51,

          keywordSales: 53
        },
        {
          date: '2024-06-03',
          searchVolume: 4342,
          organicRank: 66,

          keywordSales: 53
        },
        {
          date: '2024-06-02',
          searchVolume: 4342,
          organicRank: 50,

          keywordSales: 53
        },
        {
          date: '2024-06-01',
          searchVolume: 4342,
          organicRank: 54,

          keywordSales: 53
        },
        {
          date: '2024-05-31',
          searchVolume: 4257,
          organicRank: 50,
          sponsoredRank: 5,
          keywordSales: 53
        },
        {
          date: '2024-05-30',
          searchVolume: 4257,
          organicRank: 58,

          keywordSales: 53
        },
        {
          date: '2024-05-29',
          searchVolume: 4257,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-28',
          searchVolume: 4257,
          organicRank: 49,

          keywordSales: 53
        },
        {
          date: '2024-05-27',
          searchVolume: 4257,
          organicRank: 52,

          keywordSales: 53
        },
        {
          date: '2024-05-26',
          searchVolume: 4257,
          organicRank: 42,

          keywordSales: 53
        },
        {
          date: '2024-05-25',
          searchVolume: 4257,
          organicRank: 42,

          keywordSales: 53
        },
        {
          date: '2024-05-24',
          searchVolume: 4479,
          organicRank: 46,

          keywordSales: 53
        },
        {
          date: '2024-05-23',
          searchVolume: 4479,
          organicRank: 43,

          keywordSales: 53
        },
        {
          date: '2024-05-22',
          searchVolume: 4479,
          organicRank: 49,

          keywordSales: 53
        },
        {
          date: '2024-05-21',
          searchVolume: 4479,
          organicRank: 44,

          keywordSales: 53
        },
        {
          date: '2024-05-20',
          searchVolume: 4479,
          organicRank: 44,

          keywordSales: 53
        },
        {
          date: '2024-05-19',
          searchVolume: 4479,
          organicRank: 36,

          keywordSales: 53
        },
        {
          date: '2024-05-18',
          searchVolume: 4479,
          organicRank: 46,

          keywordSales: 53
        },
        {
          date: '2024-05-17',
          searchVolume: 4302,
          organicRank: 38,
          sponsoredRank: 38,
          keywordSales: 53
        },
        {
          date: '2024-05-16',
          searchVolume: 4302,
          organicRank: 34,

          keywordSales: 53
        },
        {
          date: '2024-05-15',
          searchVolume: 4302,
          organicRank: 32,
          sponsoredRank: 80,
          keywordSales: 53
        },
        {
          date: '2024-05-14',
          searchVolume: 4302,
          organicRank: 31,
          sponsoredRank: 62,
          keywordSales: 53
        },
        {
          date: '2024-05-13',
          searchVolume: 4302,
          organicRank: 29,
          sponsoredRank: 16,
          keywordSales: 53
        },
        {
          date: '2024-05-12',
          searchVolume: 4302,
          organicRank: 31,
          sponsoredRank: 17,
          keywordSales: 53
        },
        {
          date: '2024-05-11',
          searchVolume: 4302,
          organicRank: 29,
          sponsoredRank: 30,
          keywordSales: 53
        },
        {
          date: '2024-05-10',
          searchVolume: 4556,
          organicRank: 32,
          sponsoredRank: 54,
          keywordSales: 53
        },
        {
          date: '2024-05-09',
          searchVolume: 4556,
          organicRank: 32,
          sponsoredRank: 46,
          keywordSales: 53
        },
        {
          date: '2024-05-08',
          searchVolume: 4556,
          organicRank: 30,
          sponsoredRank: 55,
          keywordSales: 53
        },
        {
          date: '2024-05-07',
          searchVolume: 4556,
          organicRank: 31,
          sponsoredRank: 69,
          keywordSales: 53
        },
        {
          date: '2024-05-06',
          searchVolume: 4556,
          organicRank: 38,

          keywordSales: 53
        },
        {
          date: '2024-05-05',
          searchVolume: 4556,
          organicRank: 29,

          keywordSales: 53
        },
        {
          date: '2024-05-04',
          searchVolume: 4556,
          organicRank: 32,

          keywordSales: 53
        },
        {
          date: '2024-05-03',
          searchVolume: 4684,
          organicRank: 28,
          sponsoredRank: 55,
          keywordSales: 53
        },
        {
          date: '2024-05-02',
          searchVolume: 4684,
          organicRank: 28,

          keywordSales: 53
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard switches',
      keyword: 'keyboard switches',
      searchVolume: 6744,
      organicStartRank: 139,
      organicHighestRank: 139,
      sponsoredStartRank: 79,
      sponsoredHighestRank: 38,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 6740,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-19',
          searchVolume: 6740,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 134
        },
        {
          date: '2024-06-18',
          searchVolume: 6740,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-17',
          searchVolume: 6740,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-16',
          searchVolume: 6740,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-15',
          searchVolume: 6740,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-14',
          searchVolume: 6006,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-13',
          searchVolume: 6006,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-12',
          searchVolume: 6006,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-11',
          searchVolume: 6006,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-10',
          searchVolume: 6006,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-09',
          searchVolume: 6006,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-08',
          searchVolume: 6006,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-07',
          searchVolume: 5697,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-06',
          searchVolume: 5697,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-05',
          searchVolume: 5697,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-04',
          searchVolume: 5697,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-03',
          searchVolume: 5697,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-02',
          searchVolume: 5697,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-06-01',
          searchVolume: 5697,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-31',
          searchVolume: 5872,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-30',
          searchVolume: 5872,
          organicRank: 281,

          keywordSales: 134
        },
        {
          date: '2024-05-29',
          searchVolume: 5872,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-28',
          searchVolume: 5872,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-27',
          searchVolume: 5872,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-26',
          searchVolume: 5872,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 134
        },
        {
          date: '2024-05-25',
          searchVolume: 5872,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 134
        },
        {
          date: '2024-05-24',
          searchVolume: 5881,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-23',
          searchVolume: 5881,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-22',
          searchVolume: 5881,
          organicRank: 306,
          sponsoredRank: 55,
          keywordSales: 134
        },
        {
          date: '2024-05-21',
          searchVolume: 5881,
          organicRank: 216,

          keywordSales: 134
        },
        {
          date: '2024-05-20',
          searchVolume: 5881,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-19',
          searchVolume: 5881,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-18',
          searchVolume: 5881,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-17',
          searchVolume: 5877,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 134
        },
        {
          date: '2024-05-16',
          searchVolume: 5877,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-15',
          searchVolume: 5877,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 134
        },
        {
          date: '2024-05-14',
          searchVolume: 5877,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 134
        },
        {
          date: '2024-05-13',
          searchVolume: 5877,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 134
        },
        {
          date: '2024-05-12',
          searchVolume: 5877,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 134
        },
        {
          date: '2024-05-11',
          searchVolume: 5877,
          organicRank: 306,
          sponsoredRank: 82,
          keywordSales: 134
        },
        {
          date: '2024-05-10',
          searchVolume: 6744,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 134
        },
        {
          date: '2024-05-09',
          searchVolume: 6744,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 134
        },
        {
          date: '2024-05-08',
          searchVolume: 6744,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 134
        },
        {
          date: '2024-05-07',
          searchVolume: 6744,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 134
        },
        {
          date: '2024-05-06',
          searchVolume: 6744,
          organicRank: 306,

          keywordSales: 134
        },
        {
          date: '2024-05-05',
          searchVolume: 6744,
          organicRank: 143,

          keywordSales: 134
        },
        {
          date: '2024-05-04',
          searchVolume: 6744,
          organicRank: 139,

          keywordSales: 134
        },
        {
          date: '2024-05-02',
          searchVolume: 6952,
          organicRank: 120,

          keywordSales: 134
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboard with screen',
      keyword: 'keyboard with screen',
      searchVolume: 1661,
      organicStartRank: 192,
      organicHighestRank: 192,
      sponsoredStartRank: 40,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-20',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-19',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-18',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-17',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-16',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-15',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-14',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-13',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-12',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-11',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-10',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-09',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-08',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-07',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-06',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-05',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-04',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-03',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-02',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-01',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-31',
          searchVolume: 1635,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-29',
          searchVolume: 1635,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-28',
          searchVolume: 1635,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-27',
          searchVolume: 1635,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-26',
          searchVolume: 1635,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 6
        },
        {
          date: '2024-05-25',
          searchVolume: 1635,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-24',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-23',
          searchVolume: 1661,
          organicRank: 306,
          sponsoredRank: 63,
          keywordSales: 6
        },
        {
          date: '2024-05-22',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-21',
          searchVolume: 1661,
          organicRank: 306,
          sponsoredRank: 40,
          keywordSales: 6
        },
        {
          date: '2024-05-20',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-19',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-18',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-17',
          searchVolume: 1636,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-16',
          searchVolume: 1636,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-15',
          searchVolume: 1636,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-14',
          searchVolume: 1636,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-12',
          searchVolume: 1636,
          organicRank: 306,
          sponsoredRank: 63,
          keywordSales: 6
        },
        {
          date: '2024-05-11',
          searchVolume: 1636,
          organicRank: 193,
          sponsoredRank: 30,
          keywordSales: 6
        },
        {
          date: '2024-05-10',
          searchVolume: 1923,
          organicRank: 306,
          sponsoredRank: 70,
          keywordSales: 6
        },
        {
          date: '2024-05-09',
          searchVolume: 1923,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-08',
          searchVolume: 1923,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-07',
          searchVolume: 1923,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-06',
          searchVolume: 1923,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-04',
          searchVolume: 1923,
          organicRank: 192,
          sponsoredRank: 40,
          keywordSales: 6
        },
        {
          date: '2024-05-03',
          searchVolume: 1829,
          organicRank: 180,

          keywordSales: 6
        },
        {
          date: '2024-05-02',
          searchVolume: 1829,
          organicRank: 171,

          keywordSales: 6
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keyboards',
      keyword: 'keyboards',
      searchVolume: 19400,
      organicStartRank: 98,
      organicHighestRank: 39,
      sponsoredStartRank: 49,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 16343,
          organicRank: 128,

          keywordSales: 77
        },
        {
          date: '2024-06-20',
          searchVolume: 16343,
          organicRank: 230,
          sponsoredRank: 87,
          keywordSales: 77
        },
        {
          date: '2024-06-19',
          searchVolume: 16343,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 77
        },
        {
          date: '2024-06-18',
          searchVolume: 16343,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 77
        },
        {
          date: '2024-06-17',
          searchVolume: 16343,
          organicRank: 121,
          sponsoredRank: 24,
          keywordSales: 77
        },
        {
          date: '2024-06-15',
          searchVolume: 16343,
          organicRank: 240,
          sponsoredRank: 30,
          keywordSales: 77
        },
        {
          date: '2024-06-14',
          searchVolume: 16709,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 77
        },
        {
          date: '2024-06-13',
          searchVolume: 16709,
          organicRank: 254,
          sponsoredRank: 74,
          keywordSales: 77
        },
        {
          date: '2024-06-12',
          searchVolume: 16709,
          organicRank: 250,

          keywordSales: 77
        },
        {
          date: '2024-06-11',
          searchVolume: 16709,
          organicRank: 125,

          keywordSales: 77
        },
        {
          date: '2024-06-10',
          searchVolume: 16709,
          organicRank: 306,

          keywordSales: 77
        },
        {
          date: '2024-06-09',
          searchVolume: 16709,
          organicRank: 306,
          sponsoredRank: 51,
          keywordSales: 77
        },
        {
          date: '2024-06-08',
          searchVolume: 16709,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 77
        },
        {
          date: '2024-06-07',
          searchVolume: 15860,
          organicRank: 306,

          keywordSales: 77
        },
        {
          date: '2024-06-06',
          searchVolume: 15860,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 77
        },
        {
          date: '2024-06-05',
          searchVolume: 15860,
          organicRank: 306,

          keywordSales: 77
        },
        {
          date: '2024-06-04',
          searchVolume: 15860,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 77
        },
        {
          date: '2024-06-03',
          searchVolume: 15860,
          organicRank: 306,
          sponsoredRank: 51,
          keywordSales: 77
        },
        {
          date: '2024-06-02',
          searchVolume: 15860,
          organicRank: 306,

          keywordSales: 77
        },
        {
          date: '2024-06-01',
          searchVolume: 15860,
          organicRank: 70,
          sponsoredRank: 27,
          keywordSales: 77
        },
        {
          date: '2024-05-31',
          searchVolume: 17368,
          organicRank: 84,
          sponsoredRank: 27,
          keywordSales: 77
        },
        {
          date: '2024-05-30',
          searchVolume: 17368,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 77
        },
        {
          date: '2024-05-29',
          searchVolume: 17368,
          organicRank: 245,
          sponsoredRank: 37,
          keywordSales: 77
        },
        {
          date: '2024-05-28',
          searchVolume: 17368,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 77
        },
        {
          date: '2024-05-27',
          searchVolume: 17368,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 77
        },
        {
          date: '2024-05-26',
          searchVolume: 17368,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 77
        },
        {
          date: '2024-05-25',
          searchVolume: 17368,
          organicRank: 72,
          sponsoredRank: 57,
          keywordSales: 77
        },
        {
          date: '2024-05-24',
          searchVolume: 19400,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 77
        },
        {
          date: '2024-05-23',
          searchVolume: 19400,
          organicRank: 81,
          sponsoredRank: 50,
          keywordSales: 77
        },
        {
          date: '2024-05-22',
          searchVolume: 19400,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 77
        },
        {
          date: '2024-05-21',
          searchVolume: 19400,
          organicRank: 105,
          sponsoredRank: 38,
          keywordSales: 77
        },
        {
          date: '2024-05-20',
          searchVolume: 19400,
          organicRank: 98,
          sponsoredRank: 34,
          keywordSales: 77
        },
        {
          date: '2024-05-19',
          searchVolume: 19400,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 77
        },
        {
          date: '2024-05-18',
          searchVolume: 19400,
          organicRank: 39,
          sponsoredRank: 90,
          keywordSales: 77
        },
        {
          date: '2024-05-17',
          searchVolume: 17708,
          organicRank: 48,
          sponsoredRank: 37,
          keywordSales: 77
        },
        {
          date: '2024-05-16',
          searchVolume: 17708,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 77
        },
        {
          date: '2024-05-15',
          searchVolume: 17708,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 77
        },
        {
          date: '2024-05-14',
          searchVolume: 17708,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 77
        },
        {
          date: '2024-05-13',
          searchVolume: 17708,
          organicRank: 306,
          sponsoredRank: 37,
          keywordSales: 77
        },
        {
          date: '2024-05-12',
          searchVolume: 17708,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 77
        },
        {
          date: '2024-05-11',
          searchVolume: 17708,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 77
        },
        {
          date: '2024-05-10',
          searchVolume: 17994,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 77
        },
        {
          date: '2024-05-09',
          searchVolume: 17994,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 77
        },
        {
          date: '2024-05-08',
          searchVolume: 17994,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 77
        },
        {
          date: '2024-05-07',
          searchVolume: 17994,
          organicRank: 306,
          sponsoredRank: 64,
          keywordSales: 77
        },
        {
          date: '2024-05-06',
          searchVolume: 17994,
          organicRank: 119,
          sponsoredRank: 46,
          keywordSales: 77
        },
        {
          date: '2024-05-05',
          searchVolume: 17994,
          organicRank: 101,
          sponsoredRank: 43,
          keywordSales: 77
        },
        {
          date: '2024-05-04',
          searchVolume: 17994,
          organicRank: 98,

          keywordSales: 77
        },
        {
          date: '2024-05-03',
          searchVolume: 15906,
          organicRank: 100,
          sponsoredRank: 49,
          keywordSales: 77
        },
        {
          date: '2024-05-02',
          searchVolume: 15906,
          organicRank: 107,
          sponsoredRank: 42,
          keywordSales: 77
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keycaps 60 percent',
      keyword: 'keycaps 60 percent',
      searchVolume: 3078,
      organicStartRank: 120,
      organicHighestRank: 70,
      sponsoredStartRank: 50,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 140,

          keywordSales: 25
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 139,

          keywordSales: 25
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 127,
          sponsoredRank: 80,
          keywordSales: 25
        },
        {
          date: '2024-06-17',
          searchVolume: 3126,
          organicRank: 139,

          keywordSales: 25
        },
        {
          date: '2024-06-16',
          searchVolume: 3126,
          organicRank: 137,

          keywordSales: 25
        },
        {
          date: '2024-06-15',
          searchVolume: 3126,
          organicRank: 119,

          keywordSales: 25
        },
        {
          date: '2024-06-14',
          searchVolume: 2548,
          organicRank: 137,

          keywordSales: 25
        },
        {
          date: '2024-06-13',
          searchVolume: 2548,
          organicRank: 140,

          keywordSales: 25
        },
        {
          date: '2024-06-12',
          searchVolume: 2548,
          organicRank: 116,

          keywordSales: 25
        },
        {
          date: '2024-06-11',
          searchVolume: 2548,
          organicRank: 152,

          keywordSales: 25
        },
        {
          date: '2024-06-10',
          searchVolume: 2548,
          organicRank: 145,

          keywordSales: 25
        },
        {
          date: '2024-06-09',
          searchVolume: 2548,
          organicRank: 147,
          sponsoredRank: 49,
          keywordSales: 25
        },
        {
          date: '2024-06-08',
          searchVolume: 2548,
          organicRank: 136,

          keywordSales: 25
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 122,

          keywordSales: 25
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 138,

          keywordSales: 25
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 115,
          sponsoredRank: 86,
          keywordSales: 25
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 104,

          keywordSales: 25
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 103,

          keywordSales: 25
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 107,

          keywordSales: 25
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 109,
          sponsoredRank: 62,
          keywordSales: 25
        },
        {
          date: '2024-05-31',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 25
        },
        {
          date: '2024-05-30',
          searchVolume: 2509,
          organicRank: 217,

          keywordSales: 25
        },
        {
          date: '2024-05-29',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 25
        },
        {
          date: '2024-05-28',
          searchVolume: 2509,
          organicRank: 102,

          keywordSales: 25
        },
        {
          date: '2024-05-27',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 25
        },
        {
          date: '2024-05-26',
          searchVolume: 2509,
          organicRank: 135,

          keywordSales: 25
        },
        {
          date: '2024-05-25',
          searchVolume: 2509,
          organicRank: 122,

          keywordSales: 25
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 141,

          keywordSales: 25
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 76,

          keywordSales: 25
        },
        {
          date: '2024-05-22',
          searchVolume: 3078,
          organicRank: 85,

          keywordSales: 25
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 120,

          keywordSales: 25
        },
        {
          date: '2024-05-20',
          searchVolume: 3078,
          organicRank: 125,

          keywordSales: 25
        },
        {
          date: '2024-05-19',
          searchVolume: 3078,
          organicRank: 127,
          sponsoredRank: 68,
          keywordSales: 25
        },
        {
          date: '2024-05-18',
          searchVolume: 3078,
          organicRank: 85,
          sponsoredRank: 67,
          keywordSales: 25
        },
        {
          date: '2024-05-17',
          searchVolume: 2505,
          organicRank: 131,
          sponsoredRank: 19,
          keywordSales: 25
        },
        {
          date: '2024-05-16',
          searchVolume: 2505,
          organicRank: 131,

          keywordSales: 25
        },
        {
          date: '2024-05-15',
          searchVolume: 2505,
          organicRank: 81,

          keywordSales: 25
        },
        {
          date: '2024-05-14',
          searchVolume: 2505,
          organicRank: 123,
          sponsoredRank: 13,
          keywordSales: 25
        },
        {
          date: '2024-05-13',
          searchVolume: 2505,
          organicRank: 80,
          sponsoredRank: 44,
          keywordSales: 25
        },
        {
          date: '2024-05-12',
          searchVolume: 2505,
          organicRank: 70,
          sponsoredRank: 68,
          keywordSales: 25
        },
        {
          date: '2024-05-11',
          searchVolume: 2505,
          organicRank: 82,
          sponsoredRank: 74,
          keywordSales: 25
        },
        {
          date: '2024-05-10',
          searchVolume: 3126,
          organicRank: 127,
          sponsoredRank: 73,
          keywordSales: 25
        },
        {
          date: '2024-05-09',
          searchVolume: 3126,
          organicRank: 82,
          sponsoredRank: 56,
          keywordSales: 25
        },
        {
          date: '2024-05-08',
          searchVolume: 3126,
          organicRank: 122,

          keywordSales: 25
        },
        {
          date: '2024-05-07',
          searchVolume: 3126,
          organicRank: 73,
          sponsoredRank: 73,
          keywordSales: 25
        },
        {
          date: '2024-05-06',
          searchVolume: 3126,
          organicRank: 82,

          keywordSales: 25
        },
        {
          date: '2024-05-05',
          searchVolume: 3126,
          organicRank: 76,

          keywordSales: 25
        },
        {
          date: '2024-05-04',
          searchVolume: 3126,
          organicRank: 120,
          sponsoredRank: 50,
          keywordSales: 25
        },
        {
          date: '2024-05-03',
          searchVolume: 3092,
          organicRank: 116,

          keywordSales: 25
        },
        {
          date: '2024-05-02',
          searchVolume: 3092,
          organicRank: 120,

          keywordSales: 25
        },
        {
          date: '2024-05-01',
          searchVolume: 3092,
          organicRank: 119,
          sponsoredRank: 32,
          keywordSales: 25
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_keychron',
      keyword: 'keychron',
      searchVolume: 20150,
      organicStartRank: 155,
      organicHighestRank: 182,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-16',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 19757,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-20',
          searchVolume: 19757,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-19',
          searchVolume: 19757,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 202
        },
        {
          date: '2024-06-18',
          searchVolume: 19757,
          organicRank: 306,
          sponsoredRank: 40,
          keywordSales: 202
        },
        {
          date: '2024-06-17',
          searchVolume: 19757,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-16',
          searchVolume: 19757,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-15',
          searchVolume: 19757,
          organicRank: 306,
          sponsoredRank: 78,
          keywordSales: 202
        },
        {
          date: '2024-06-14',
          searchVolume: 20906,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 202
        },
        {
          date: '2024-06-13',
          searchVolume: 20906,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-12',
          searchVolume: 20906,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-11',
          searchVolume: 20906,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-10',
          searchVolume: 20906,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-09',
          searchVolume: 20906,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 202
        },
        {
          date: '2024-06-08',
          searchVolume: 20906,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-07',
          searchVolume: 19500,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-06',
          searchVolume: 19500,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-05',
          searchVolume: 19500,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-04',
          searchVolume: 19500,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-03',
          searchVolume: 19500,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-02',
          searchVolume: 19500,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-06-01',
          searchVolume: 19500,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-31',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-30',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-29',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-28',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-27',
          searchVolume: 19400,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 202
        },
        {
          date: '2024-05-26',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-25',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-24',
          searchVolume: 20150,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-23',
          searchVolume: 20150,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-22',
          searchVolume: 20150,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 202
        },
        {
          date: '2024-05-21',
          searchVolume: 20150,
          organicRank: 306,
          sponsoredRank: 37,
          keywordSales: 202
        },
        {
          date: '2024-05-20',
          searchVolume: 20150,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-19',
          searchVolume: 20150,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-18',
          searchVolume: 20150,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-17',
          searchVolume: 20563,
          organicRank: 306,

          keywordSales: 202
        },
        {
          date: '2024-05-16',
          searchVolume: 20563,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 202
        },
        {
          date: '2024-05-15',
          searchVolume: 20563,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 202
        },
        {
          date: '2024-05-14',
          searchVolume: 20563,
          organicRank: 306,
          sponsoredRank: 76,
          keywordSales: 202
        },
        {
          date: '2024-05-13',
          searchVolume: 20563,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 202
        },
        {
          date: '2024-05-12',
          searchVolume: 20563,
          organicRank: 306,
          sponsoredRank: 63,
          keywordSales: 202
        },
        {
          date: '2024-05-11',
          searchVolume: 20563,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 202
        },
        {
          date: '2024-05-10',
          searchVolume: 20412,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 202
        },
        {
          date: '2024-05-09',
          searchVolume: 20412,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 202
        },
        {
          date: '2024-05-08',
          searchVolume: 20412,
          organicRank: 306,
          sponsoredRank: 62,
          keywordSales: 202
        },
        {
          date: '2024-05-07',
          searchVolume: 20412,
          organicRank: 306,
          sponsoredRank: 41,
          keywordSales: 202
        },
        {
          date: '2024-05-06',
          searchVolume: 20412,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 202
        },
        {
          date: '2024-05-05',
          searchVolume: 20412,
          organicRank: 182,

          keywordSales: 202
        },
        {
          date: '2024-05-03',
          searchVolume: 19484,
          organicRank: 155,
          sponsoredRank: 29,
          keywordSales: 202
        },
        {
          date: '2024-05-02',
          searchVolume: 19484,
          organicRank: 241,

          keywordSales: 202
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_kraken keyboards',
      keyword: 'kraken keyboards',
      searchVolume: 2507,
      organicStartRank: 82,
      organicHighestRank: 76,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2548,
          organicRank: 84,

          keywordSales: 56
        },
        {
          date: '2024-06-19',
          searchVolume: 2548,
          organicRank: 81,

          keywordSales: 56
        },
        {
          date: '2024-06-18',
          searchVolume: 2548,
          organicRank: 91,

          keywordSales: 56
        },
        {
          date: '2024-06-17',
          searchVolume: 2548,
          organicRank: 88,

          keywordSales: 56
        },
        {
          date: '2024-06-16',
          searchVolume: 2548,
          organicRank: 82,

          keywordSales: 56
        },
        {
          date: '2024-06-15',
          searchVolume: 2548,
          organicRank: 79,

          keywordSales: 56
        },
        {
          date: '2024-06-14',
          searchVolume: 2428,
          organicRank: 88,

          keywordSales: 56
        },
        {
          date: '2024-06-13',
          searchVolume: 2428,
          organicRank: 91,

          keywordSales: 56
        },
        {
          date: '2024-06-12',
          searchVolume: 2428,
          organicRank: 84,

          keywordSales: 56
        },
        {
          date: '2024-06-11',
          searchVolume: 2428,
          organicRank: 80,

          keywordSales: 56
        },
        {
          date: '2024-06-10',
          searchVolume: 2428,
          organicRank: 85,

          keywordSales: 56
        },
        {
          date: '2024-06-09',
          searchVolume: 2428,
          organicRank: 88,

          keywordSales: 56
        },
        {
          date: '2024-06-08',
          searchVolume: 2428,
          organicRank: 90,

          keywordSales: 56
        },
        {
          date: '2024-06-07',
          searchVolume: 2319,
          organicRank: 82,

          keywordSales: 56
        },
        {
          date: '2024-06-06',
          searchVolume: 2319,
          organicRank: 77,

          keywordSales: 56
        },
        {
          date: '2024-06-05',
          searchVolume: 2319,
          organicRank: 76,

          keywordSales: 56
        },
        {
          date: '2024-06-04',
          searchVolume: 2319,
          organicRank: 82,

          keywordSales: 56
        },
        {
          date: '2024-06-03',
          searchVolume: 2319,
          organicRank: 82,

          keywordSales: 56
        },
        {
          date: '2024-06-02',
          searchVolume: 2319,
          organicRank: 94,

          keywordSales: 56
        },
        {
          date: '2024-06-01',
          searchVolume: 2319,
          organicRank: 100,

          keywordSales: 56
        },
        {
          date: '2024-05-31',
          searchVolume: 2217,
          organicRank: 106,

          keywordSales: 56
        },
        {
          date: '2024-05-30',
          searchVolume: 2217,
          organicRank: 82,

          keywordSales: 56
        },
        {
          date: '2024-05-29',
          searchVolume: 2217,
          organicRank: 87,

          keywordSales: 56
        },
        {
          date: '2024-05-28',
          searchVolume: 2217,
          organicRank: 87,

          keywordSales: 56
        },
        {
          date: '2024-05-27',
          searchVolume: 2217,
          organicRank: 117,

          keywordSales: 56
        },
        {
          date: '2024-05-26',
          searchVolume: 2217,
          organicRank: 90,

          keywordSales: 56
        },
        {
          date: '2024-05-25',
          searchVolume: 2217,
          organicRank: 95,

          keywordSales: 56
        },
        {
          date: '2024-05-24',
          searchVolume: 2507,
          organicRank: 98,

          keywordSales: 56
        },
        {
          date: '2024-05-23',
          searchVolume: 2507,
          organicRank: 81,

          keywordSales: 56
        },
        {
          date: '2024-05-22',
          searchVolume: 2507,
          organicRank: 81,

          keywordSales: 56
        },
        {
          date: '2024-05-21',
          searchVolume: 2507,
          organicRank: 87,

          keywordSales: 56
        },
        {
          date: '2024-05-20',
          searchVolume: 2507,
          organicRank: 91,

          keywordSales: 56
        },
        {
          date: '2024-05-19',
          searchVolume: 2507,
          organicRank: 96,

          keywordSales: 56
        },
        {
          date: '2024-05-18',
          searchVolume: 2507,
          organicRank: 83,

          keywordSales: 56
        },
        {
          date: '2024-05-17',
          searchVolume: 1896,
          organicRank: 86,

          keywordSales: 56
        },
        {
          date: '2024-05-16',
          searchVolume: 1896,
          organicRank: 88,

          keywordSales: 56
        },
        {
          date: '2024-05-15',
          searchVolume: 1896,
          organicRank: 82,

          keywordSales: 56
        },
        {
          date: '2024-05-14',
          searchVolume: 1896,
          organicRank: 89,

          keywordSales: 56
        },
        {
          date: '2024-05-13',
          searchVolume: 1896,
          organicRank: 112,

          keywordSales: 56
        },
        {
          date: '2024-05-12',
          searchVolume: 1896,
          organicRank: 86,

          keywordSales: 56
        },
        {
          date: '2024-05-11',
          searchVolume: 1896,
          organicRank: 91,

          keywordSales: 56
        },
        {
          date: '2024-05-10',
          searchVolume: 1924,
          organicRank: 85,

          keywordSales: 56
        },
        {
          date: '2024-05-09',
          searchVolume: 1924,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-05-08',
          searchVolume: 1924,
          organicRank: 96,

          keywordSales: 56
        },
        {
          date: '2024-05-07',
          searchVolume: 1924,
          organicRank: 103,

          keywordSales: 56
        },
        {
          date: '2024-05-06',
          searchVolume: 1924,
          organicRank: 81,

          keywordSales: 56
        },
        {
          date: '2024-05-05',
          searchVolume: 1924,
          organicRank: 83,

          keywordSales: 56
        },
        {
          date: '2024-05-04',
          searchVolume: 1924,
          organicRank: 82,

          keywordSales: 56
        },
        {
          date: '2024-05-03',
          searchVolume: 2429,
          organicRank: 77,

          keywordSales: 56
        },
        {
          date: '2024-05-02',
          searchVolume: 2429,
          organicRank: 82,

          keywordSales: 56
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_logitech g305',
      keyword: 'logitech g305',
      searchVolume: 10588,
      organicStartRank: 183,
      organicHighestRank: 126,
      sponsoredStartRank: 73,
      sponsoredHighestRank: 73,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 10753,
          organicRank: 160,

          keywordSales: 563
        },
        {
          date: '2024-06-19',
          searchVolume: 10753,
          organicRank: 151,

          keywordSales: 563
        },
        {
          date: '2024-06-18',
          searchVolume: 10753,
          organicRank: 306,

          keywordSales: 563
        },
        {
          date: '2024-06-17',
          searchVolume: 10753,
          organicRank: 145,

          keywordSales: 563
        },
        {
          date: '2024-06-16',
          searchVolume: 10753,
          organicRank: 173,

          keywordSales: 563
        },
        {
          date: '2024-06-15',
          searchVolume: 10753,
          organicRank: 159,

          keywordSales: 563
        },
        {
          date: '2024-06-14',
          searchVolume: 9284,
          organicRank: 160,

          keywordSales: 563
        },
        {
          date: '2024-06-13',
          searchVolume: 9284,
          organicRank: 190,

          keywordSales: 563
        },
        {
          date: '2024-06-12',
          searchVolume: 9284,
          organicRank: 188,

          keywordSales: 563
        },
        {
          date: '2024-06-11',
          searchVolume: 9284,
          organicRank: 159,

          keywordSales: 563
        },
        {
          date: '2024-06-10',
          searchVolume: 9284,
          organicRank: 158,

          keywordSales: 563
        },
        {
          date: '2024-06-09',
          searchVolume: 9284,
          organicRank: 137,

          keywordSales: 563
        },
        {
          date: '2024-06-08',
          searchVolume: 9284,
          organicRank: 156,

          keywordSales: 563
        },
        {
          date: '2024-06-07',
          searchVolume: 8852,
          organicRank: 138,

          keywordSales: 563
        },
        {
          date: '2024-06-06',
          searchVolume: 8852,
          organicRank: 126,

          keywordSales: 563
        },
        {
          date: '2024-06-05',
          searchVolume: 8852,
          organicRank: 160,

          keywordSales: 563
        },
        {
          date: '2024-06-04',
          searchVolume: 8852,
          organicRank: 154,

          keywordSales: 563
        },
        {
          date: '2024-06-03',
          searchVolume: 8852,
          organicRank: 154,

          keywordSales: 563
        },
        {
          date: '2024-06-02',
          searchVolume: 8852,
          organicRank: 138,

          keywordSales: 563
        },
        {
          date: '2024-06-01',
          searchVolume: 8852,
          organicRank: 158,

          keywordSales: 563
        },
        {
          date: '2024-05-31',
          searchVolume: 8895,
          organicRank: 137,

          keywordSales: 563
        },
        {
          date: '2024-05-30',
          searchVolume: 8895,
          organicRank: 156,

          keywordSales: 563
        },
        {
          date: '2024-05-29',
          searchVolume: 8895,
          organicRank: 167,

          keywordSales: 563
        },
        {
          date: '2024-05-28',
          searchVolume: 8895,
          organicRank: 306,

          keywordSales: 563
        },
        {
          date: '2024-05-27',
          searchVolume: 8895,
          organicRank: 134,

          keywordSales: 563
        },
        {
          date: '2024-05-26',
          searchVolume: 8895,
          organicRank: 166,

          keywordSales: 563
        },
        {
          date: '2024-05-25',
          searchVolume: 8895,
          organicRank: 177,

          keywordSales: 563
        },
        {
          date: '2024-05-24',
          searchVolume: 10588,
          organicRank: 132,

          keywordSales: 563
        },
        {
          date: '2024-05-23',
          searchVolume: 10588,
          organicRank: 140,

          keywordSales: 563
        },
        {
          date: '2024-05-22',
          searchVolume: 10588,
          organicRank: 168,

          keywordSales: 563
        },
        {
          date: '2024-05-21',
          searchVolume: 10588,
          organicRank: 306,

          keywordSales: 563
        },
        {
          date: '2024-05-20',
          searchVolume: 10588,
          organicRank: 131,

          keywordSales: 563
        },
        {
          date: '2024-05-19',
          searchVolume: 10588,
          organicRank: 189,

          keywordSales: 563
        },
        {
          date: '2024-05-18',
          searchVolume: 10588,
          organicRank: 207,

          keywordSales: 563
        },
        {
          date: '2024-05-17',
          searchVolume: 10608,
          organicRank: 140,

          keywordSales: 563
        },
        {
          date: '2024-05-16',
          searchVolume: 10608,
          organicRank: 306,

          keywordSales: 563
        },
        {
          date: '2024-05-15',
          searchVolume: 10608,
          organicRank: 209,

          keywordSales: 563
        },
        {
          date: '2024-05-14',
          searchVolume: 10608,
          organicRank: 204,

          keywordSales: 563
        },
        {
          date: '2024-05-13',
          searchVolume: 10608,
          organicRank: 218,

          keywordSales: 563
        },
        {
          date: '2024-05-12',
          searchVolume: 10608,
          organicRank: 194,

          keywordSales: 563
        },
        {
          date: '2024-05-11',
          searchVolume: 10608,
          organicRank: 213,

          keywordSales: 563
        },
        {
          date: '2024-05-10',
          searchVolume: 11073,
          organicRank: 163,

          keywordSales: 563
        },
        {
          date: '2024-05-09',
          searchVolume: 11073,
          organicRank: 224,

          keywordSales: 563
        },
        {
          date: '2024-05-08',
          searchVolume: 11073,
          organicRank: 161,

          keywordSales: 563
        },
        {
          date: '2024-05-07',
          searchVolume: 11073,
          organicRank: 226,

          keywordSales: 563
        },
        {
          date: '2024-05-06',
          searchVolume: 11073,
          organicRank: 147,

          keywordSales: 563
        },
        {
          date: '2024-05-05',
          searchVolume: 11073,
          organicRank: 146,

          keywordSales: 563
        },
        {
          date: '2024-05-04',
          searchVolume: 11073,
          organicRank: 183,
          sponsoredRank: 73,
          keywordSales: 563
        },
        {
          date: '2024-05-03',
          searchVolume: 10266,
          organicRank: 145,
          sponsoredRank: 39,
          keywordSales: 563
        },
        {
          date: '2024-05-02',
          searchVolume: 10266,
          organicRank: 142,

          keywordSales: 563
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_logitech g502',
      keyword: 'logitech g502',
      searchVolume: 16357,
      organicStartRank: 181,
      organicHighestRank: 146,
      sponsoredStartRank: 56,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-10',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 16596,
          organicRank: 168,

          keywordSales: 700
        },
        {
          date: '2024-06-19',
          searchVolume: 16596,
          organicRank: 294,

          keywordSales: 700
        },
        {
          date: '2024-06-18',
          searchVolume: 16596,
          organicRank: 171,

          keywordSales: 700
        },
        {
          date: '2024-06-17',
          searchVolume: 16596,
          organicRank: 261,

          keywordSales: 700
        },
        {
          date: '2024-06-16',
          searchVolume: 16596,
          organicRank: 175,

          keywordSales: 700
        },
        {
          date: '2024-06-15',
          searchVolume: 16596,
          organicRank: 177,

          keywordSales: 700
        },
        {
          date: '2024-06-14',
          searchVolume: 14167,
          organicRank: 176,
          sponsoredRank: 6,
          keywordSales: 700
        },
        {
          date: '2024-06-13',
          searchVolume: 14167,
          organicRank: 156,

          keywordSales: 700
        },
        {
          date: '2024-06-12',
          searchVolume: 14167,
          organicRank: 157,

          keywordSales: 700
        },
        {
          date: '2024-06-11',
          searchVolume: 14167,
          organicRank: 160,

          keywordSales: 700
        },
        {
          date: '2024-06-10',
          searchVolume: 14167,
          organicRank: 155,

          keywordSales: 700
        },
        {
          date: '2024-06-09',
          searchVolume: 14167,
          organicRank: 146,

          keywordSales: 700
        },
        {
          date: '2024-06-08',
          searchVolume: 14167,
          organicRank: 173,
          sponsoredRank: 74,
          keywordSales: 700
        },
        {
          date: '2024-06-07',
          searchVolume: 13508,
          organicRank: 175,

          keywordSales: 700
        },
        {
          date: '2024-06-06',
          searchVolume: 13508,
          organicRank: 160,

          keywordSales: 700
        },
        {
          date: '2024-06-05',
          searchVolume: 13508,
          organicRank: 164,

          keywordSales: 700
        },
        {
          date: '2024-06-04',
          searchVolume: 13508,
          organicRank: 161,

          keywordSales: 700
        },
        {
          date: '2024-06-03',
          searchVolume: 13508,
          organicRank: 164,

          keywordSales: 700
        },
        {
          date: '2024-06-02',
          searchVolume: 13508,
          organicRank: 155,

          keywordSales: 700
        },
        {
          date: '2024-06-01',
          searchVolume: 13508,
          organicRank: 158,

          keywordSales: 700
        },
        {
          date: '2024-05-31',
          searchVolume: 13949,
          organicRank: 170,
          sponsoredRank: 53,
          keywordSales: 700
        },
        {
          date: '2024-05-30',
          searchVolume: 13949,
          organicRank: 164,
          sponsoredRank: 28,
          keywordSales: 700
        },
        {
          date: '2024-05-29',
          searchVolume: 13949,
          organicRank: 149,

          keywordSales: 700
        },
        {
          date: '2024-05-28',
          searchVolume: 13949,
          organicRank: 182,

          keywordSales: 700
        },
        {
          date: '2024-05-27',
          searchVolume: 13949,
          organicRank: 182,

          keywordSales: 700
        },
        {
          date: '2024-05-26',
          searchVolume: 13949,
          organicRank: 188,

          keywordSales: 700
        },
        {
          date: '2024-05-25',
          searchVolume: 13949,
          organicRank: 306,

          keywordSales: 700
        },
        {
          date: '2024-05-24',
          searchVolume: 16357,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 700
        },
        {
          date: '2024-05-23',
          searchVolume: 16357,
          organicRank: 180,
          sponsoredRank: 42,
          keywordSales: 700
        },
        {
          date: '2024-05-22',
          searchVolume: 16357,
          organicRank: 168,
          sponsoredRank: 8,
          keywordSales: 700
        },
        {
          date: '2024-05-21',
          searchVolume: 16357,
          organicRank: 169,
          sponsoredRank: 34,
          keywordSales: 700
        },
        {
          date: '2024-05-20',
          searchVolume: 16357,
          organicRank: 262,

          keywordSales: 700
        },
        {
          date: '2024-05-19',
          searchVolume: 16357,
          organicRank: 196,
          sponsoredRank: 60,
          keywordSales: 700
        },
        {
          date: '2024-05-18',
          searchVolume: 16357,
          organicRank: 199,

          keywordSales: 700
        },
        {
          date: '2024-05-17',
          searchVolume: 16321,
          organicRank: 180,
          sponsoredRank: 2,
          keywordSales: 700
        },
        {
          date: '2024-05-16',
          searchVolume: 16321,
          organicRank: 187,

          keywordSales: 700
        },
        {
          date: '2024-05-15',
          searchVolume: 16321,
          organicRank: 188,

          keywordSales: 700
        },
        {
          date: '2024-05-14',
          searchVolume: 16321,
          organicRank: 171,
          sponsoredRank: 42,
          keywordSales: 700
        },
        {
          date: '2024-05-13',
          searchVolume: 16321,
          organicRank: 189,

          keywordSales: 700
        },
        {
          date: '2024-05-12',
          searchVolume: 16321,
          organicRank: 188,

          keywordSales: 700
        },
        {
          date: '2024-05-11',
          searchVolume: 16321,
          organicRank: 168,
          sponsoredRank: 53,
          keywordSales: 700
        },
        {
          date: '2024-05-10',
          searchVolume: 17641,
          organicRank: 291,
          sponsoredRank: 19,
          keywordSales: 700
        },
        {
          date: '2024-05-09',
          searchVolume: 17641,
          organicRank: 270,

          keywordSales: 700
        },
        {
          date: '2024-05-08',
          searchVolume: 17641,
          organicRank: 263,
          sponsoredRank: 56,
          keywordSales: 700
        },
        {
          date: '2024-05-07',
          searchVolume: 17641,
          organicRank: 306,

          keywordSales: 700
        },
        {
          date: '2024-05-06',
          searchVolume: 17641,
          organicRank: 163,

          keywordSales: 700
        },
        {
          date: '2024-05-05',
          searchVolume: 17641,
          organicRank: 168,

          keywordSales: 700
        },
        {
          date: '2024-05-04',
          searchVolume: 17641,
          organicRank: 181,

          keywordSales: 700
        },
        {
          date: '2024-05-03',
          searchVolume: 13501,
          organicRank: 186,

          keywordSales: 700
        },
        {
          date: '2024-05-02',
          searchVolume: 13501,
          organicRank: 181,

          keywordSales: 700
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_logitech gaming keyboard',
      keyword: 'logitech gaming keyboard',
      searchVolume: 6638,
      organicStartRank: 105,
      organicHighestRank: 33,
      sponsoredStartRank: 48,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-16',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 6749,
          organicRank: 102,
          sponsoredRank: 69,
          keywordSales: 149
        },
        {
          date: '2024-06-19',
          searchVolume: 6749,
          organicRank: 61,
          sponsoredRank: 18,
          keywordSales: 149
        },
        {
          date: '2024-06-18',
          searchVolume: 6749,
          organicRank: 55,
          sponsoredRank: 5,
          keywordSales: 149
        },
        {
          date: '2024-06-17',
          searchVolume: 6749,
          organicRank: 53,

          keywordSales: 149
        },
        {
          date: '2024-06-16',
          searchVolume: 6749,
          organicRank: 89,

          keywordSales: 149
        },
        {
          date: '2024-06-15',
          searchVolume: 6749,
          organicRank: 99,
          sponsoredRank: 11,
          keywordSales: 149
        },
        {
          date: '2024-06-14',
          searchVolume: 6744,
          organicRank: 42,
          sponsoredRank: 2,
          keywordSales: 149
        },
        {
          date: '2024-06-13',
          searchVolume: 6744,
          organicRank: 49,
          sponsoredRank: 30,
          keywordSales: 149
        },
        {
          date: '2024-06-12',
          searchVolume: 6744,
          organicRank: 46,

          keywordSales: 149
        },
        {
          date: '2024-06-11',
          searchVolume: 6744,
          organicRank: 61,

          keywordSales: 149
        },
        {
          date: '2024-06-10',
          searchVolume: 6744,
          organicRank: 50,

          keywordSales: 149
        },
        {
          date: '2024-06-09',
          searchVolume: 6744,
          organicRank: 48,
          sponsoredRank: 101,
          keywordSales: 149
        },
        {
          date: '2024-06-08',
          searchVolume: 6744,
          organicRank: 63,

          keywordSales: 149
        },
        {
          date: '2024-06-07',
          searchVolume: 6433,
          organicRank: 54,

          keywordSales: 149
        },
        {
          date: '2024-06-06',
          searchVolume: 6433,
          organicRank: 49,

          keywordSales: 149
        },
        {
          date: '2024-06-05',
          searchVolume: 6433,
          organicRank: 48,
          sponsoredRank: 6,
          keywordSales: 149
        },
        {
          date: '2024-06-04',
          searchVolume: 6433,
          organicRank: 47,

          keywordSales: 149
        },
        {
          date: '2024-06-03',
          searchVolume: 6433,
          organicRank: 48,

          keywordSales: 149
        },
        {
          date: '2024-06-02',
          searchVolume: 6433,
          organicRank: 46,

          keywordSales: 149
        },
        {
          date: '2024-06-01',
          searchVolume: 6433,
          organicRank: 47,
          sponsoredRank: 10,
          keywordSales: 149
        },
        {
          date: '2024-05-31',
          searchVolume: 6640,
          organicRank: 42,
          sponsoredRank: 36,
          keywordSales: 149
        },
        {
          date: '2024-05-30',
          searchVolume: 6640,
          organicRank: 48,
          sponsoredRank: 50,
          keywordSales: 149
        },
        {
          date: '2024-05-29',
          searchVolume: 6640,
          organicRank: 105,

          keywordSales: 149
        },
        {
          date: '2024-05-28',
          searchVolume: 6640,
          organicRank: 44,

          keywordSales: 149
        },
        {
          date: '2024-05-27',
          searchVolume: 6640,
          organicRank: 48,

          keywordSales: 149
        },
        {
          date: '2024-05-26',
          searchVolume: 6640,
          organicRank: 40,
          sponsoredRank: 34,
          keywordSales: 149
        },
        {
          date: '2024-05-25',
          searchVolume: 6640,
          organicRank: 42,

          keywordSales: 149
        },
        {
          date: '2024-05-24',
          searchVolume: 6638,
          organicRank: 50,

          keywordSales: 149
        },
        {
          date: '2024-05-23',
          searchVolume: 6638,
          organicRank: 44,
          sponsoredRank: 70,
          keywordSales: 149
        },
        {
          date: '2024-05-22',
          searchVolume: 6638,
          organicRank: 41,
          sponsoredRank: 65,
          keywordSales: 149
        },
        {
          date: '2024-05-21',
          searchVolume: 6638,
          organicRank: 47,

          keywordSales: 149
        },
        {
          date: '2024-05-20',
          searchVolume: 6638,
          organicRank: 41,

          keywordSales: 149
        },
        {
          date: '2024-05-19',
          searchVolume: 6638,
          organicRank: 38,
          sponsoredRank: 6,
          keywordSales: 149
        },
        {
          date: '2024-05-18',
          searchVolume: 6638,
          organicRank: 37,
          sponsoredRank: 30,
          keywordSales: 149
        },
        {
          date: '2024-05-17',
          searchVolume: 5874,
          organicRank: 45,
          sponsoredRank: 4,
          keywordSales: 149
        },
        {
          date: '2024-05-16',
          searchVolume: 5874,
          organicRank: 47,
          sponsoredRank: 3,
          keywordSales: 149
        },
        {
          date: '2024-05-15',
          searchVolume: 5874,
          organicRank: 41,
          sponsoredRank: 26,
          keywordSales: 149
        },
        {
          date: '2024-05-14',
          searchVolume: 5874,
          organicRank: 40,
          sponsoredRank: 47,
          keywordSales: 149
        },
        {
          date: '2024-05-13',
          searchVolume: 5874,
          organicRank: 38,
          sponsoredRank: 60,
          keywordSales: 149
        },
        {
          date: '2024-05-12',
          searchVolume: 5874,
          organicRank: 37,
          sponsoredRank: 44,
          keywordSales: 149
        },
        {
          date: '2024-05-11',
          searchVolume: 5874,
          organicRank: 33,
          sponsoredRank: 53,
          keywordSales: 149
        },
        {
          date: '2024-05-10',
          searchVolume: 6759,
          organicRank: 33,
          sponsoredRank: 66,
          keywordSales: 149
        },
        {
          date: '2024-05-09',
          searchVolume: 6759,
          organicRank: 34,
          sponsoredRank: 24,
          keywordSales: 149
        },
        {
          date: '2024-05-08',
          searchVolume: 6759,
          organicRank: 35,
          sponsoredRank: 71,
          keywordSales: 149
        },
        {
          date: '2024-05-07',
          searchVolume: 6759,
          organicRank: 35,
          sponsoredRank: 53,
          keywordSales: 149
        },
        {
          date: '2024-05-06',
          searchVolume: 6759,
          organicRank: 34,
          sponsoredRank: 28,
          keywordSales: 149
        },
        {
          date: '2024-05-05',
          searchVolume: 6759,
          organicRank: 101,

          keywordSales: 149
        },
        {
          date: '2024-05-04',
          searchVolume: 6759,
          organicRank: 105,

          keywordSales: 149
        },
        {
          date: '2024-05-03',
          searchVolume: 6447,
          organicRank: 66,
          sponsoredRank: 48,
          keywordSales: 149
        },
        {
          date: '2024-05-02',
          searchVolume: 6447,
          organicRank: 105,

          keywordSales: 149
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_logitech keyboard',
      keyword: 'logitech keyboard',
      searchVolume: 55777,
      organicStartRank: 132,
      organicHighestRank: 110,
      sponsoredStartRank: 51,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 50964,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 514
        },
        {
          date: '2024-06-19',
          searchVolume: 50964,
          organicRank: 153,
          sponsoredRank: 24,
          keywordSales: 514
        },
        {
          date: '2024-06-18',
          searchVolume: 50964,
          organicRank: 140,
          sponsoredRank: 30,
          keywordSales: 514
        },
        {
          date: '2024-06-17',
          searchVolume: 50964,
          organicRank: 148,
          sponsoredRank: 47,
          keywordSales: 514
        },
        {
          date: '2024-06-16',
          searchVolume: 50964,
          organicRank: 149,
          sponsoredRank: 33,
          keywordSales: 514
        },
        {
          date: '2024-06-15',
          searchVolume: 50964,
          organicRank: 146,
          sponsoredRank: 54,
          keywordSales: 514
        },
        {
          date: '2024-06-14',
          searchVolume: 52728,
          organicRank: 133,
          sponsoredRank: 17,
          keywordSales: 514
        },
        {
          date: '2024-06-13',
          searchVolume: 52728,
          organicRank: 150,
          sponsoredRank: 46,
          keywordSales: 514
        },
        {
          date: '2024-06-12',
          searchVolume: 52728,
          organicRank: 138,

          keywordSales: 514
        },
        {
          date: '2024-06-11',
          searchVolume: 52728,
          organicRank: 124,

          keywordSales: 514
        },
        {
          date: '2024-06-10',
          searchVolume: 52728,
          organicRank: 163,
          sponsoredRank: 37,
          keywordSales: 514
        },
        {
          date: '2024-06-09',
          searchVolume: 52728,
          organicRank: 306,

          keywordSales: 514
        },
        {
          date: '2024-06-08',
          searchVolume: 52728,
          organicRank: 126,
          sponsoredRank: 31,
          keywordSales: 514
        },
        {
          date: '2024-06-07',
          searchVolume: 51205,
          organicRank: 152,

          keywordSales: 514
        },
        {
          date: '2024-06-06',
          searchVolume: 51205,
          organicRank: 157,

          keywordSales: 514
        },
        {
          date: '2024-06-05',
          searchVolume: 51205,
          organicRank: 142,
          sponsoredRank: 30,
          keywordSales: 514
        },
        {
          date: '2024-06-04',
          searchVolume: 51205,
          organicRank: 166,

          keywordSales: 514
        },
        {
          date: '2024-06-03',
          searchVolume: 51205,
          organicRank: 162,

          keywordSales: 514
        },
        {
          date: '2024-06-02',
          searchVolume: 51205,
          organicRank: 153,

          keywordSales: 514
        },
        {
          date: '2024-06-01',
          searchVolume: 51205,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 514
        },
        {
          date: '2024-05-31',
          searchVolume: 55660,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 514
        },
        {
          date: '2024-05-30',
          searchVolume: 55660,
          organicRank: 155,
          sponsoredRank: 57,
          keywordSales: 514
        },
        {
          date: '2024-05-29',
          searchVolume: 55660,
          organicRank: 143,

          keywordSales: 514
        },
        {
          date: '2024-05-28',
          searchVolume: 55660,
          organicRank: 150,

          keywordSales: 514
        },
        {
          date: '2024-05-27',
          searchVolume: 55660,
          organicRank: 144,
          sponsoredRank: 67,
          keywordSales: 514
        },
        {
          date: '2024-05-26',
          searchVolume: 55660,
          organicRank: 154,

          keywordSales: 514
        },
        {
          date: '2024-05-25',
          searchVolume: 55660,
          organicRank: 154,

          keywordSales: 514
        },
        {
          date: '2024-05-24',
          searchVolume: 55777,
          organicRank: 132,

          keywordSales: 514
        },
        {
          date: '2024-05-23',
          searchVolume: 55777,
          organicRank: 135,

          keywordSales: 514
        },
        {
          date: '2024-05-22',
          searchVolume: 55777,
          organicRank: 142,
          sponsoredRank: 12,
          keywordSales: 514
        },
        {
          date: '2024-05-21',
          searchVolume: 55777,
          organicRank: 147,
          sponsoredRank: 4,
          keywordSales: 514
        },
        {
          date: '2024-05-20',
          searchVolume: 55777,
          organicRank: 139,

          keywordSales: 514
        },
        {
          date: '2024-05-19',
          searchVolume: 55777,
          organicRank: 131,

          keywordSales: 514
        },
        {
          date: '2024-05-18',
          searchVolume: 55777,
          organicRank: 143,
          sponsoredRank: 31,
          keywordSales: 514
        },
        {
          date: '2024-05-17',
          searchVolume: 52802,
          organicRank: 123,
          sponsoredRank: 54,
          keywordSales: 514
        },
        {
          date: '2024-05-16',
          searchVolume: 52802,
          organicRank: 129,
          sponsoredRank: 32,
          keywordSales: 514
        },
        {
          date: '2024-05-15',
          searchVolume: 52802,
          organicRank: 306,
          sponsoredRank: 41,
          keywordSales: 514
        },
        {
          date: '2024-05-14',
          searchVolume: 52802,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 514
        },
        {
          date: '2024-05-13',
          searchVolume: 52802,
          organicRank: 306,
          sponsoredRank: 14,
          keywordSales: 514
        },
        {
          date: '2024-05-12',
          searchVolume: 52802,
          organicRank: 144,
          sponsoredRank: 42,
          keywordSales: 514
        },
        {
          date: '2024-05-11',
          searchVolume: 52802,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 514
        },
        {
          date: '2024-05-10',
          searchVolume: 56630,
          organicRank: 110,
          sponsoredRank: 31,
          keywordSales: 514
        },
        {
          date: '2024-05-09',
          searchVolume: 56630,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 514
        },
        {
          date: '2024-05-08',
          searchVolume: 56630,
          organicRank: 129,
          sponsoredRank: 66,
          keywordSales: 514
        },
        {
          date: '2024-05-07',
          searchVolume: 56630,
          organicRank: 306,
          sponsoredRank: 63,
          keywordSales: 514
        },
        {
          date: '2024-05-06',
          searchVolume: 56630,
          organicRank: 306,
          sponsoredRank: 51,
          keywordSales: 514
        },
        {
          date: '2024-05-05',
          searchVolume: 56630,
          organicRank: 148,

          keywordSales: 514
        },
        {
          date: '2024-05-04',
          searchVolume: 56630,
          organicRank: 132,

          keywordSales: 514
        },
        {
          date: '2024-05-03',
          searchVolume: 51275,
          organicRank: 241,

          keywordSales: 514
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_logitech mechanical keyboard',
      keyword: 'logitech mechanical keyboard',
      searchVolume: 5331,
      organicStartRank: 94,
      organicHighestRank: 52,
      sponsoredStartRank: 33,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 5925,
          organicRank: 101,

          keywordSales: 143
        },
        {
          date: '2024-06-19',
          searchVolume: 5925,
          organicRank: 89,
          sponsoredRank: 90,
          keywordSales: 143
        },
        {
          date: '2024-06-18',
          searchVolume: 5925,
          organicRank: 82,
          sponsoredRank: 44,
          keywordSales: 143
        },
        {
          date: '2024-06-17',
          searchVolume: 5925,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 143
        },
        {
          date: '2024-06-16',
          searchVolume: 5925,
          organicRank: 81,

          keywordSales: 143
        },
        {
          date: '2024-06-15',
          searchVolume: 5925,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 143
        },
        {
          date: '2024-06-14',
          searchVolume: 5986,
          organicRank: 89,
          sponsoredRank: 66,
          keywordSales: 143
        },
        {
          date: '2024-06-13',
          searchVolume: 5986,
          organicRank: 89,

          keywordSales: 143
        },
        {
          date: '2024-06-12',
          searchVolume: 5986,
          organicRank: 101,

          keywordSales: 143
        },
        {
          date: '2024-06-11',
          searchVolume: 5986,
          organicRank: 107,

          keywordSales: 143
        },
        {
          date: '2024-06-10',
          searchVolume: 5986,
          organicRank: 93,

          keywordSales: 143
        },
        {
          date: '2024-06-09',
          searchVolume: 5986,
          organicRank: 80,
          sponsoredRank: 4,
          keywordSales: 143
        },
        {
          date: '2024-06-08',
          searchVolume: 5986,
          organicRank: 86,
          sponsoredRank: 20,
          keywordSales: 143
        },
        {
          date: '2024-06-07',
          searchVolume: 5426,
          organicRank: 95,

          keywordSales: 143
        },
        {
          date: '2024-06-06',
          searchVolume: 5426,
          organicRank: 101,

          keywordSales: 143
        },
        {
          date: '2024-06-05',
          searchVolume: 5426,
          organicRank: 109,
          sponsoredRank: 8,
          keywordSales: 143
        },
        {
          date: '2024-06-04',
          searchVolume: 5426,
          organicRank: 65,

          keywordSales: 143
        },
        {
          date: '2024-06-03',
          searchVolume: 5426,
          organicRank: 78,

          keywordSales: 143
        },
        {
          date: '2024-06-02',
          searchVolume: 5426,
          organicRank: 74,

          keywordSales: 143
        },
        {
          date: '2024-06-01',
          searchVolume: 5426,
          organicRank: 82,
          sponsoredRank: 35,
          keywordSales: 143
        },
        {
          date: '2024-05-31',
          searchVolume: 5601,
          organicRank: 77,
          sponsoredRank: 49,
          keywordSales: 143
        },
        {
          date: '2024-05-30',
          searchVolume: 5601,
          organicRank: 103,

          keywordSales: 143
        },
        {
          date: '2024-05-29',
          searchVolume: 5601,
          organicRank: 97,
          sponsoredRank: 6,
          keywordSales: 143
        },
        {
          date: '2024-05-28',
          searchVolume: 5601,
          organicRank: 196,

          keywordSales: 143
        },
        {
          date: '2024-05-27',
          searchVolume: 5601,
          organicRank: 92,

          keywordSales: 143
        },
        {
          date: '2024-05-26',
          searchVolume: 5601,
          organicRank: 123,
          sponsoredRank: 8,
          keywordSales: 143
        },
        {
          date: '2024-05-25',
          searchVolume: 5601,
          organicRank: 65,

          keywordSales: 143
        },
        {
          date: '2024-05-24',
          searchVolume: 5331,
          organicRank: 113,

          keywordSales: 143
        },
        {
          date: '2024-05-23',
          searchVolume: 5331,
          organicRank: 71,

          keywordSales: 143
        },
        {
          date: '2024-05-22',
          searchVolume: 5331,
          organicRank: 66,

          keywordSales: 143
        },
        {
          date: '2024-05-21',
          searchVolume: 5331,
          organicRank: 66,

          keywordSales: 143
        },
        {
          date: '2024-05-20',
          searchVolume: 5331,
          organicRank: 59,

          keywordSales: 143
        },
        {
          date: '2024-05-19',
          searchVolume: 5331,
          organicRank: 74,

          keywordSales: 143
        },
        {
          date: '2024-05-18',
          searchVolume: 5331,
          organicRank: 70,

          keywordSales: 143
        },
        {
          date: '2024-05-17',
          searchVolume: 5060,
          organicRank: 60,

          keywordSales: 143
        },
        {
          date: '2024-05-16',
          searchVolume: 5060,
          organicRank: 59,

          keywordSales: 143
        },
        {
          date: '2024-05-15',
          searchVolume: 5060,
          organicRank: 52,

          keywordSales: 143
        },
        {
          date: '2024-05-14',
          searchVolume: 5060,
          organicRank: 83,

          keywordSales: 143
        },
        {
          date: '2024-05-13',
          searchVolume: 5060,
          organicRank: 62,
          sponsoredRank: 21,
          keywordSales: 143
        },
        {
          date: '2024-05-12',
          searchVolume: 5060,
          organicRank: 59,
          sponsoredRank: 48,
          keywordSales: 143
        },
        {
          date: '2024-05-11',
          searchVolume: 5060,
          organicRank: 66,
          sponsoredRank: 14,
          keywordSales: 143
        },
        {
          date: '2024-05-10',
          searchVolume: 5698,
          organicRank: 61,

          keywordSales: 143
        },
        {
          date: '2024-05-09',
          searchVolume: 5698,
          organicRank: 61,
          sponsoredRank: 8,
          keywordSales: 143
        },
        {
          date: '2024-05-08',
          searchVolume: 5698,
          organicRank: 74,

          keywordSales: 143
        },
        {
          date: '2024-05-07',
          searchVolume: 5698,
          organicRank: 78,

          keywordSales: 143
        },
        {
          date: '2024-05-06',
          searchVolume: 5698,
          organicRank: 56,
          sponsoredRank: 84,
          keywordSales: 143
        },
        {
          date: '2024-05-05',
          searchVolume: 5698,
          organicRank: 69,

          keywordSales: 143
        },
        {
          date: '2024-05-04',
          searchVolume: 5698,
          organicRank: 94,
          sponsoredRank: 33,
          keywordSales: 143
        },
        {
          date: '2024-05-03',
          searchVolume: 5689,
          organicRank: 93,
          sponsoredRank: 41,
          keywordSales: 143
        },
        {
          date: '2024-05-02',
          searchVolume: 5689,
          organicRank: 89,

          keywordSales: 143
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_logitech mx keyboard',
      keyword: 'logitech mx keyboard',
      searchVolume: 2509,
      organicStartRank: 162,
      organicHighestRank: 159,
      sponsoredStartRank: 47,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-19',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-18',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-17',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-16',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-15',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-14',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-13',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-12',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-11',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-10',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-09',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-08',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-31',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-30',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-29',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-28',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-27',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-26',
          searchVolume: 2513,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 65
        },
        {
          date: '2024-05-25',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-24',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-23',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-22',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-21',
          searchVolume: 2509,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 65
        },
        {
          date: '2024-05-20',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-19',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-18',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-17',
          searchVolume: 2768,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-16',
          searchVolume: 2768,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-15',
          searchVolume: 2768,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-14',
          searchVolume: 2768,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-13',
          searchVolume: 2768,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 65
        },
        {
          date: '2024-05-12',
          searchVolume: 2768,
          organicRank: 159,

          keywordSales: 65
        },
        {
          date: '2024-05-11',
          searchVolume: 2768,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-10',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 65
        },
        {
          date: '2024-05-09',
          searchVolume: 2552,
          organicRank: 175,

          keywordSales: 65
        },
        {
          date: '2024-05-08',
          searchVolume: 2552,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 65
        },
        {
          date: '2024-05-07',
          searchVolume: 2552,
          organicRank: 165,

          keywordSales: 65
        },
        {
          date: '2024-05-06',
          searchVolume: 2552,
          organicRank: 160,
          sponsoredRank: 47,
          keywordSales: 65
        },
        {
          date: '2024-05-03',
          searchVolume: 3278,
          organicRank: 162,

          keywordSales: 65
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_logitech mx mechanical',
      keyword: 'logitech mx mechanical',
      searchVolume: 2507,
      organicStartRank: 86,
      organicHighestRank: 80,
      sponsoredStartRank: 16,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-07',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-19',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-18',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-17',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-16',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-15',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-14',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 89,
          keywordSales: 50
        },
        {
          date: '2024-06-13',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-12',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-11',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 50
        },
        {
          date: '2024-06-10',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-09',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 4,
          keywordSales: 50
        },
        {
          date: '2024-06-08',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 3,
          keywordSales: 50
        },
        {
          date: '2024-06-07',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-06',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-05',
          searchVolume: 2316,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 50
        },
        {
          date: '2024-06-04',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-03',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-02',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-06-01',
          searchVolume: 2316,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-31',
          searchVolume: 2356,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-30',
          searchVolume: 2356,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-29',
          searchVolume: 2356,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-28',
          searchVolume: 2356,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-27',
          searchVolume: 2356,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-26',
          searchVolume: 2356,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-25',
          searchVolume: 2356,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-24',
          searchVolume: 2507,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-23',
          searchVolume: 2507,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-22',
          searchVolume: 2507,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-21',
          searchVolume: 2507,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-20',
          searchVolume: 2507,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-19',
          searchVolume: 2507,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-18',
          searchVolume: 2507,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 50
        },
        {
          date: '2024-05-17',
          searchVolume: 3073,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-16',
          searchVolume: 3073,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-15',
          searchVolume: 3073,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-14',
          searchVolume: 3073,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-13',
          searchVolume: 3073,
          organicRank: 141,

          keywordSales: 50
        },
        {
          date: '2024-05-12',
          searchVolume: 3073,
          organicRank: 125,
          sponsoredRank: 26,
          keywordSales: 50
        },
        {
          date: '2024-05-11',
          searchVolume: 3073,
          organicRank: 136,
          sponsoredRank: 3,
          keywordSales: 50
        },
        {
          date: '2024-05-10',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-09',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 50
        },
        {
          date: '2024-05-08',
          searchVolume: 2251,
          organicRank: 148,
          sponsoredRank: 6,
          keywordSales: 50
        },
        {
          date: '2024-05-07',
          searchVolume: 2251,
          organicRank: 149,
          sponsoredRank: 16,
          keywordSales: 50
        },
        {
          date: '2024-05-06',
          searchVolume: 2251,
          organicRank: 80,

          keywordSales: 50
        },
        {
          date: '2024-05-05',
          searchVolume: 2251,
          organicRank: 87,

          keywordSales: 50
        },
        {
          date: '2024-05-04',
          searchVolume: 2251,
          organicRank: 86,

          keywordSales: 50
        },
        {
          date: '2024-05-03',
          searchVolume: 2073,
          organicRank: 92,

          keywordSales: 50
        },
        {
          date: '2024-05-01',
          searchVolume: 2073,
          organicRank: 85,

          keywordSales: 50
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_logitech mx mechanical keyboard',
      keyword: 'logitech mx mechanical keyboard',
      searchVolume: 1324,
      organicStartRank: 133,
      organicHighestRank: 63,
      sponsoredStartRank: 75,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1846,
          organicRank: 101,

          keywordSales: 82
        },
        {
          date: '2024-06-19',
          searchVolume: 1846,
          organicRank: 79,

          keywordSales: 82
        },
        {
          date: '2024-06-18',
          searchVolume: 1846,
          organicRank: 76,

          keywordSales: 82
        },
        {
          date: '2024-06-17',
          searchVolume: 1846,
          organicRank: 78,

          keywordSales: 82
        },
        {
          date: '2024-06-16',
          searchVolume: 1846,
          organicRank: 78,

          keywordSales: 82
        },
        {
          date: '2024-06-15',
          searchVolume: 1846,
          organicRank: 80,
          sponsoredRank: 65,
          keywordSales: 82
        },
        {
          date: '2024-06-14',
          searchVolume: 1659,
          organicRank: 81,
          sponsoredRank: 8,
          keywordSales: 82
        },
        {
          date: '2024-06-13',
          searchVolume: 1659,
          organicRank: 66,

          keywordSales: 82
        },
        {
          date: '2024-06-12',
          searchVolume: 1659,
          organicRank: 92,

          keywordSales: 82
        },
        {
          date: '2024-06-11',
          searchVolume: 1659,
          organicRank: 77,

          keywordSales: 82
        },
        {
          date: '2024-06-10',
          searchVolume: 1659,
          organicRank: 64,

          keywordSales: 82
        },
        {
          date: '2024-06-09',
          searchVolume: 1659,
          organicRank: 72,
          sponsoredRank: 61,
          keywordSales: 82
        },
        {
          date: '2024-06-08',
          searchVolume: 1659,
          organicRank: 72,

          keywordSales: 82
        },
        {
          date: '2024-06-07',
          searchVolume: 1285,
          organicRank: 74,

          keywordSales: 82
        },
        {
          date: '2024-06-06',
          searchVolume: 1285,
          organicRank: 66,

          keywordSales: 82
        },
        {
          date: '2024-06-05',
          searchVolume: 1285,
          organicRank: 72,

          keywordSales: 82
        },
        {
          date: '2024-06-04',
          searchVolume: 1285,
          organicRank: 71,

          keywordSales: 82
        },
        {
          date: '2024-06-03',
          searchVolume: 1285,
          organicRank: 75,

          keywordSales: 82
        },
        {
          date: '2024-06-02',
          searchVolume: 1285,
          organicRank: 63,

          keywordSales: 82
        },
        {
          date: '2024-06-01',
          searchVolume: 1285,
          organicRank: 106,

          keywordSales: 82
        },
        {
          date: '2024-05-31',
          searchVolume: 1408,
          organicRank: 103,
          sponsoredRank: 8,
          keywordSales: 82
        },
        {
          date: '2024-05-30',
          searchVolume: 1408,
          organicRank: 98,

          keywordSales: 82
        },
        {
          date: '2024-05-29',
          searchVolume: 1408,
          organicRank: 81,

          keywordSales: 82
        },
        {
          date: '2024-05-28',
          searchVolume: 1408,
          organicRank: 71,

          keywordSales: 82
        },
        {
          date: '2024-05-27',
          searchVolume: 1408,
          organicRank: 103,

          keywordSales: 82
        },
        {
          date: '2024-05-26',
          searchVolume: 1408,
          organicRank: 97,
          sponsoredRank: 6,
          keywordSales: 82
        },
        {
          date: '2024-05-25',
          searchVolume: 1408,
          organicRank: 69,

          keywordSales: 82
        },
        {
          date: '2024-05-24',
          searchVolume: 1324,
          organicRank: 85,
          sponsoredRank: 13,
          keywordSales: 82
        },
        {
          date: '2024-05-23',
          searchVolume: 1324,
          organicRank: 79,

          keywordSales: 82
        },
        {
          date: '2024-05-22',
          searchVolume: 1324,
          organicRank: 69,

          keywordSales: 82
        },
        {
          date: '2024-05-21',
          searchVolume: 1324,
          organicRank: 68,
          sponsoredRank: 47,
          keywordSales: 82
        },
        {
          date: '2024-05-20',
          searchVolume: 1324,
          organicRank: 92,

          keywordSales: 82
        },
        {
          date: '2024-05-19',
          searchVolume: 1324,
          organicRank: 83,

          keywordSales: 82
        },
        {
          date: '2024-05-18',
          searchVolume: 1324,
          organicRank: 73,

          keywordSales: 82
        },
        {
          date: '2024-05-17',
          searchVolume: 870,
          organicRank: 70,

          keywordSales: 82
        },
        {
          date: '2024-05-16',
          searchVolume: 870,
          organicRank: 306,

          keywordSales: 82
        },
        {
          date: '2024-05-15',
          searchVolume: 870,
          organicRank: 76,

          keywordSales: 82
        },
        {
          date: '2024-05-14',
          searchVolume: 870,
          organicRank: 101,

          keywordSales: 82
        },
        {
          date: '2024-05-13',
          searchVolume: 870,
          organicRank: 84,

          keywordSales: 82
        },
        {
          date: '2024-05-12',
          searchVolume: 870,
          organicRank: 112,

          keywordSales: 82
        },
        {
          date: '2024-05-11',
          searchVolume: 870,
          organicRank: 117,

          keywordSales: 82
        },
        {
          date: '2024-05-10',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 82
        },
        {
          date: '2024-05-09',
          searchVolume: 1432,
          organicRank: 123,

          keywordSales: 82
        },
        {
          date: '2024-05-08',
          searchVolume: 1432,
          organicRank: 137,

          keywordSales: 82
        },
        {
          date: '2024-05-07',
          searchVolume: 1432,
          organicRank: 136,

          keywordSales: 82
        },
        {
          date: '2024-05-06',
          searchVolume: 1432,
          organicRank: 99,

          keywordSales: 82
        },
        {
          date: '2024-05-05',
          searchVolume: 1432,
          organicRank: 134,

          keywordSales: 82
        },
        {
          date: '2024-05-04',
          searchVolume: 1432,
          organicRank: 133,

          keywordSales: 82
        },
        {
          date: '2024-05-03',
          searchVolume: 2284,
          organicRank: 127,
          sponsoredRank: 75,
          keywordSales: 82
        },
        {
          date: '2024-05-02',
          searchVolume: 2284,
          organicRank: 130,

          keywordSales: 82
        },
        {
          date: '2024-05-01',
          searchVolume: 2284,
          organicRank: 119,
          sponsoredRank: 16,
          keywordSales: 82
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_low profile mechanical keyboard',
      keyword: 'low profile mechanical keyboard',
      searchVolume: 4237,
      organicStartRank: 136,
      organicHighestRank: 104,
      sponsoredStartRank: 58,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 3627,
          organicRank: 127,
          sponsoredRank: 43,
          keywordSales: 33
        },
        {
          date: '2024-06-20',
          searchVolume: 3627,
          organicRank: 146,
          sponsoredRank: 43,
          keywordSales: 33
        },
        {
          date: '2024-06-19',
          searchVolume: 3627,
          organicRank: 104,

          keywordSales: 33
        },
        {
          date: '2024-06-18',
          searchVolume: 3627,
          organicRank: 118,
          sponsoredRank: 32,
          keywordSales: 33
        },
        {
          date: '2024-06-17',
          searchVolume: 3627,
          organicRank: 111,
          sponsoredRank: 43,
          keywordSales: 33
        },
        {
          date: '2024-06-16',
          searchVolume: 3627,
          organicRank: 133,

          keywordSales: 33
        },
        {
          date: '2024-06-15',
          searchVolume: 3627,
          organicRank: 107,

          keywordSales: 33
        },
        {
          date: '2024-06-14',
          searchVolume: 4322,
          organicRank: 306,
          sponsoredRank: 24,
          keywordSales: 33
        },
        {
          date: '2024-06-13',
          searchVolume: 4322,
          organicRank: 112,

          keywordSales: 33
        },
        {
          date: '2024-06-12',
          searchVolume: 4322,
          organicRank: 113,

          keywordSales: 33
        },
        {
          date: '2024-06-11',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-10',
          searchVolume: 4322,
          organicRank: 111,

          keywordSales: 33
        },
        {
          date: '2024-06-09',
          searchVolume: 4322,
          organicRank: 117,
          sponsoredRank: 19,
          keywordSales: 33
        },
        {
          date: '2024-06-08',
          searchVolume: 4322,
          organicRank: 133,

          keywordSales: 33
        },
        {
          date: '2024-06-07',
          searchVolume: 4124,
          organicRank: 138,

          keywordSales: 33
        },
        {
          date: '2024-06-06',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-05',
          searchVolume: 4124,
          organicRank: 134,

          keywordSales: 33
        },
        {
          date: '2024-06-04',
          searchVolume: 4124,
          organicRank: 147,
          sponsoredRank: 7,
          keywordSales: 33
        },
        {
          date: '2024-06-03',
          searchVolume: 4124,
          organicRank: 152,

          keywordSales: 33
        },
        {
          date: '2024-06-02',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-01',
          searchVolume: 4124,
          organicRank: 143,

          keywordSales: 33
        },
        {
          date: '2024-05-31',
          searchVolume: 4267,
          organicRank: 159,
          sponsoredRank: 37,
          keywordSales: 33
        },
        {
          date: '2024-05-30',
          searchVolume: 4267,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 33
        },
        {
          date: '2024-05-29',
          searchVolume: 4267,
          organicRank: 147,

          keywordSales: 33
        },
        {
          date: '2024-05-28',
          searchVolume: 4267,
          organicRank: 148,

          keywordSales: 33
        },
        {
          date: '2024-05-27',
          searchVolume: 4267,
          organicRank: 121,
          sponsoredRank: 52,
          keywordSales: 33
        },
        {
          date: '2024-05-26',
          searchVolume: 4267,
          organicRank: 164,
          sponsoredRank: 51,
          keywordSales: 33
        },
        {
          date: '2024-05-25',
          searchVolume: 4267,
          organicRank: 161,
          sponsoredRank: 18,
          keywordSales: 33
        },
        {
          date: '2024-05-24',
          searchVolume: 4307,
          organicRank: 148,
          sponsoredRank: 18,
          keywordSales: 33
        },
        {
          date: '2024-05-23',
          searchVolume: 4307,
          organicRank: 143,

          keywordSales: 33
        },
        {
          date: '2024-05-22',
          searchVolume: 4307,
          organicRank: 255,
          sponsoredRank: 16,
          keywordSales: 33
        },
        {
          date: '2024-05-21',
          searchVolume: 4307,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 33
        },
        {
          date: '2024-05-20',
          searchVolume: 4307,
          organicRank: 132,

          keywordSales: 33
        },
        {
          date: '2024-05-19',
          searchVolume: 4307,
          organicRank: 192,

          keywordSales: 33
        },
        {
          date: '2024-05-18',
          searchVolume: 4307,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-17',
          searchVolume: 4251,
          organicRank: 138,
          sponsoredRank: 44,
          keywordSales: 33
        },
        {
          date: '2024-05-16',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 40,
          keywordSales: 33
        },
        {
          date: '2024-05-15',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 34,
          keywordSales: 33
        },
        {
          date: '2024-05-14',
          searchVolume: 4251,
          organicRank: 196,
          sponsoredRank: 49,
          keywordSales: 33
        },
        {
          date: '2024-05-13',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 33
        },
        {
          date: '2024-05-12',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 53,
          keywordSales: 33
        },
        {
          date: '2024-05-11',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 33
        },
        {
          date: '2024-05-10',
          searchVolume: 4237,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 33
        },
        {
          date: '2024-05-09',
          searchVolume: 4237,
          organicRank: 211,
          sponsoredRank: 12,
          keywordSales: 33
        },
        {
          date: '2024-05-08',
          searchVolume: 4237,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 33
        },
        {
          date: '2024-05-07',
          searchVolume: 4237,
          organicRank: 145,
          sponsoredRank: 61,
          keywordSales: 33
        },
        {
          date: '2024-05-06',
          searchVolume: 4237,
          organicRank: 126,
          sponsoredRank: 48,
          keywordSales: 33
        },
        {
          date: '2024-05-05',
          searchVolume: 4237,
          organicRank: 147,

          keywordSales: 33
        },
        {
          date: '2024-05-04',
          searchVolume: 4237,
          organicRank: 136,

          keywordSales: 33
        },
        {
          date: '2024-05-03',
          searchVolume: 4124,
          organicRank: 129,
          sponsoredRank: 58,
          keywordSales: 33
        },
        {
          date: '2024-05-02',
          searchVolume: 4124,
          organicRank: 130,
          sponsoredRank: 6,
          keywordSales: 33
        },
        {
          date: '2024-05-01',
          searchVolume: 4124,
          organicRank: 178,
          sponsoredRank: 52,
          keywordSales: 33
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_macro keyboard',
      keyword: 'macro keyboard',
      searchVolume: 1897,
      organicStartRank: 100,
      organicHighestRank: 80,
      sponsoredStartRank: 47,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1928,
          organicRank: 98,
          sponsoredRank: 15,
          keywordSales: 31
        },
        {
          date: '2024-06-20',
          searchVolume: 1928,
          organicRank: 95,

          keywordSales: 31
        },
        {
          date: '2024-06-19',
          searchVolume: 1928,
          organicRank: 121,

          keywordSales: 31
        },
        {
          date: '2024-06-18',
          searchVolume: 1928,
          organicRank: 101,
          sponsoredRank: 91,
          keywordSales: 31
        },
        {
          date: '2024-06-17',
          searchVolume: 1928,
          organicRank: 104,

          keywordSales: 31
        },
        {
          date: '2024-06-16',
          searchVolume: 1928,
          organicRank: 228,

          keywordSales: 31
        },
        {
          date: '2024-06-15',
          searchVolume: 1928,
          organicRank: 112,
          sponsoredRank: 25,
          keywordSales: 31
        },
        {
          date: '2024-06-14',
          searchVolume: 2173,
          organicRank: 108,

          keywordSales: 31
        },
        {
          date: '2024-06-13',
          searchVolume: 2173,
          organicRank: 117,

          keywordSales: 31
        },
        {
          date: '2024-06-12',
          searchVolume: 2173,
          organicRank: 119,

          keywordSales: 31
        },
        {
          date: '2024-06-11',
          searchVolume: 2173,
          organicRank: 140,

          keywordSales: 31
        },
        {
          date: '2024-06-10',
          searchVolume: 2173,
          organicRank: 109,

          keywordSales: 31
        },
        {
          date: '2024-06-09',
          searchVolume: 2173,
          organicRank: 108,
          sponsoredRank: 13,
          keywordSales: 31
        },
        {
          date: '2024-06-08',
          searchVolume: 2173,
          organicRank: 103,
          sponsoredRank: 8,
          keywordSales: 31
        },
        {
          date: '2024-06-07',
          searchVolume: 1836,
          organicRank: 107,

          keywordSales: 31
        },
        {
          date: '2024-06-06',
          searchVolume: 1836,
          organicRank: 149,

          keywordSales: 31
        },
        {
          date: '2024-06-05',
          searchVolume: 1836,
          organicRank: 140,
          sponsoredRank: 62,
          keywordSales: 31
        },
        {
          date: '2024-06-04',
          searchVolume: 1836,
          organicRank: 140,

          keywordSales: 31
        },
        {
          date: '2024-06-03',
          searchVolume: 1836,
          organicRank: 145,

          keywordSales: 31
        },
        {
          date: '2024-06-02',
          searchVolume: 1836,
          organicRank: 154,

          keywordSales: 31
        },
        {
          date: '2024-06-01',
          searchVolume: 1836,
          organicRank: 110,

          keywordSales: 31
        },
        {
          date: '2024-05-31',
          searchVolume: 2138,
          organicRank: 118,
          sponsoredRank: 11,
          keywordSales: 31
        },
        {
          date: '2024-05-30',
          searchVolume: 2138,
          organicRank: 118,

          keywordSales: 31
        },
        {
          date: '2024-05-29',
          searchVolume: 2138,
          organicRank: 110,

          keywordSales: 31
        },
        {
          date: '2024-05-28',
          searchVolume: 2138,
          organicRank: 100,

          keywordSales: 31
        },
        {
          date: '2024-05-27',
          searchVolume: 2138,
          organicRank: 104,

          keywordSales: 31
        },
        {
          date: '2024-05-26',
          searchVolume: 2138,
          organicRank: 104,
          sponsoredRank: 70,
          keywordSales: 31
        },
        {
          date: '2024-05-25',
          searchVolume: 2138,
          organicRank: 97,

          keywordSales: 31
        },
        {
          date: '2024-05-24',
          searchVolume: 1897,
          organicRank: 102,

          keywordSales: 31
        },
        {
          date: '2024-05-23',
          searchVolume: 1897,
          organicRank: 85,

          keywordSales: 31
        },
        {
          date: '2024-05-22',
          searchVolume: 1897,
          organicRank: 93,
          sponsoredRank: 12,
          keywordSales: 31
        },
        {
          date: '2024-05-21',
          searchVolume: 1897,
          organicRank: 86,

          keywordSales: 31
        },
        {
          date: '2024-05-20',
          searchVolume: 1897,
          organicRank: 103,

          keywordSales: 31
        },
        {
          date: '2024-05-19',
          searchVolume: 1897,
          organicRank: 104,

          keywordSales: 31
        },
        {
          date: '2024-05-18',
          searchVolume: 1897,
          organicRank: 112,

          keywordSales: 31
        },
        {
          date: '2024-05-17',
          searchVolume: 1883,
          organicRank: 94,

          keywordSales: 31
        },
        {
          date: '2024-05-16',
          searchVolume: 1883,
          organicRank: 80,

          keywordSales: 31
        },
        {
          date: '2024-05-15',
          searchVolume: 1883,
          organicRank: 97,

          keywordSales: 31
        },
        {
          date: '2024-05-14',
          searchVolume: 1883,
          organicRank: 96,

          keywordSales: 31
        },
        {
          date: '2024-05-13',
          searchVolume: 1883,
          organicRank: 94,
          sponsoredRank: 49,
          keywordSales: 31
        },
        {
          date: '2024-05-12',
          searchVolume: 1883,
          organicRank: 80,
          sponsoredRank: 19,
          keywordSales: 31
        },
        {
          date: '2024-05-11',
          searchVolume: 1883,
          organicRank: 97,
          sponsoredRank: 27,
          keywordSales: 31
        },
        {
          date: '2024-05-10',
          searchVolume: 2062,
          organicRank: 103,
          sponsoredRank: 47,
          keywordSales: 31
        },
        {
          date: '2024-05-09',
          searchVolume: 2062,
          organicRank: 91,

          keywordSales: 31
        },
        {
          date: '2024-05-08',
          searchVolume: 2062,
          organicRank: 123,

          keywordSales: 31
        },
        {
          date: '2024-05-07',
          searchVolume: 2062,
          organicRank: 115,

          keywordSales: 31
        },
        {
          date: '2024-05-06',
          searchVolume: 2062,
          organicRank: 93,

          keywordSales: 31
        },
        {
          date: '2024-05-05',
          searchVolume: 2062,
          organicRank: 101,

          keywordSales: 31
        },
        {
          date: '2024-05-04',
          searchVolume: 2062,
          organicRank: 100,

          keywordSales: 31
        },
        {
          date: '2024-05-03',
          searchVolume: 2318,
          organicRank: 107,

          keywordSales: 31
        },
        {
          date: '2024-05-02',
          searchVolume: 2318,
          organicRank: 103,

          keywordSales: 31
        },
        {
          date: '2024-05-01',
          searchVolume: 2318,
          organicRank: 95,

          keywordSales: 31
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_magegee 60 percent keyboard',
      keyword: 'magegee 60 percent keyboard',
      searchVolume: 1889,
      organicStartRank: 116,
      organicHighestRank: 58,
      sponsoredStartRank: 43,
      sponsoredHighestRank: 43,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 2547,
          organicRank: 78
        },
        {
          date: '2024-06-20',
          searchVolume: 2547,
          organicRank: 80
        },
        {
          date: '2024-06-19',
          searchVolume: 2547,
          organicRank: 74
        },
        {
          date: '2024-06-18',
          searchVolume: 2547,
          organicRank: 88
        },
        {
          date: '2024-06-17',
          searchVolume: 2547,
          organicRank: 80
        },
        {
          date: '2024-06-16',
          searchVolume: 2547,
          organicRank: 84
        },
        {
          date: '2024-06-15',
          searchVolume: 2547,
          organicRank: 89
        },
        {
          date: '2024-06-14',
          searchVolume: 2550,
          organicRank: 86
        },
        {
          date: '2024-06-13',
          searchVolume: 2550,
          organicRank: 88
        },
        {
          date: '2024-06-12',
          searchVolume: 2550,
          organicRank: 85
        },
        {
          date: '2024-06-11',
          searchVolume: 2550,
          organicRank: 92
        },
        {
          date: '2024-06-10',
          searchVolume: 2550,
          organicRank: 88
        },
        {
          date: '2024-06-09',
          searchVolume: 2550,
          organicRank: 93
        },
        {
          date: '2024-06-08',
          searchVolume: 2550,
          organicRank: 78
        },
        {
          date: '2024-06-07',
          searchVolume: 2318,
          organicRank: 75
        },
        {
          date: '2024-06-06',
          searchVolume: 2318,
          organicRank: 71
        },
        {
          date: '2024-06-05',
          searchVolume: 2318,
          organicRank: 72
        },
        {
          date: '2024-06-04',
          searchVolume: 2318,
          organicRank: 75
        },
        {
          date: '2024-06-03',
          searchVolume: 2318,
          organicRank: 72
        },
        {
          date: '2024-06-02',
          searchVolume: 2318,
          organicRank: 58
        },
        {
          date: '2024-06-01',
          searchVolume: 2318,
          organicRank: 61
        },
        {
          date: '2024-05-31',
          searchVolume: 2140,
          organicRank: 63,
          sponsoredRank: 43
        },
        {
          date: '2024-05-30',
          searchVolume: 2140,
          organicRank: 82
        },
        {
          date: '2024-05-29',
          searchVolume: 2140,
          organicRank: 84
        },
        {
          date: '2024-05-28',
          searchVolume: 2140,
          organicRank: 84
        },
        {
          date: '2024-05-27',
          searchVolume: 2140,
          organicRank: 78
        },
        {
          date: '2024-05-26',
          searchVolume: 2140,
          organicRank: 79
        },
        {
          date: '2024-05-25',
          searchVolume: 2140,
          organicRank: 70
        },
        {
          date: '2024-05-24',
          searchVolume: 1889,
          organicRank: 84
        },
        {
          date: '2024-05-23',
          searchVolume: 1889,
          organicRank: 95
        },
        {
          date: '2024-05-22',
          searchVolume: 1889,
          organicRank: 110
        },
        {
          date: '2024-05-21',
          searchVolume: 1889,
          organicRank: 106
        },
        {
          date: '2024-05-20',
          searchVolume: 1889,
          organicRank: 94
        },
        {
          date: '2024-05-19',
          searchVolume: 1889,
          organicRank: 95
        },
        {
          date: '2024-05-18',
          searchVolume: 1889,
          organicRank: 127
        },
        {
          date: '2024-05-17',
          searchVolume: 1892,
          organicRank: 112
        },
        {
          date: '2024-05-16',
          searchVolume: 1892,
          organicRank: 107
        },
        {
          date: '2024-05-15',
          searchVolume: 1892,
          organicRank: 117
        },
        {
          date: '2024-05-14',
          searchVolume: 1892,
          organicRank: 115
        },
        {
          date: '2024-05-13',
          searchVolume: 1892,
          organicRank: 116
        },
        {
          date: '2024-05-12',
          searchVolume: 1892,
          organicRank: 124
        },
        {
          date: '2024-05-11',
          searchVolume: 1892,
          organicRank: 106
        },
        {
          date: '2024-05-10',
          searchVolume: 2251,
          organicRank: 116
        },
        {
          date: '2024-05-09',
          searchVolume: 2251,
          organicRank: 100,
          sponsoredRank: 43
        },
        {
          date: '2024-05-08',
          searchVolume: 2251,
          organicRank: 124
        },
        {
          date: '2024-05-07',
          searchVolume: 2251,
          organicRank: 120
        },
        {
          date: '2024-05-06',
          searchVolume: 2251,
          organicRank: 112
        },
        {
          date: '2024-05-03',
          searchVolume: 2434,
          organicRank: 116
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical gaming keyboard',
      keyword: 'mechanical gaming keyboard',
      searchVolume: 6751,
      organicStartRank: 40,
      organicHighestRank: 27,
      sponsoredStartRank: 68,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 5986,
          organicRank: 42,
          sponsoredRank: 54,
          keywordSales: 97
        },
        {
          date: '2024-06-20',
          searchVolume: 5986,
          organicRank: 46,

          keywordSales: 97
        },
        {
          date: '2024-06-19',
          searchVolume: 5986,
          organicRank: 46,
          sponsoredRank: 60,
          keywordSales: 97
        },
        {
          date: '2024-06-18',
          searchVolume: 5986,
          organicRank: 43,
          sponsoredRank: 42,
          keywordSales: 97
        },
        {
          date: '2024-06-17',
          searchVolume: 5986,
          organicRank: 41,

          keywordSales: 97
        },
        {
          date: '2024-06-16',
          searchVolume: 5986,
          organicRank: 47,

          keywordSales: 97
        },
        {
          date: '2024-06-15',
          searchVolume: 5986,
          organicRank: 41,
          sponsoredRank: 10,
          keywordSales: 97
        },
        {
          date: '2024-06-14',
          searchVolume: 6746,
          organicRank: 49,
          sponsoredRank: 46,
          keywordSales: 97
        },
        {
          date: '2024-06-13',
          searchVolume: 6746,
          organicRank: 34,

          keywordSales: 97
        },
        {
          date: '2024-06-12',
          searchVolume: 6746,
          organicRank: 36,

          keywordSales: 97
        },
        {
          date: '2024-06-11',
          searchVolume: 6746,
          organicRank: 33,

          keywordSales: 97
        },
        {
          date: '2024-06-10',
          searchVolume: 6746,
          organicRank: 27,

          keywordSales: 97
        },
        {
          date: '2024-06-09',
          searchVolume: 6746,
          organicRank: 29,

          keywordSales: 97
        },
        {
          date: '2024-06-08',
          searchVolume: 6746,
          organicRank: 31,
          sponsoredRank: 62,
          keywordSales: 97
        },
        {
          date: '2024-06-07',
          searchVolume: 5545,
          organicRank: 59,

          keywordSales: 97
        },
        {
          date: '2024-06-06',
          searchVolume: 5545,
          organicRank: 39,

          keywordSales: 97
        },
        {
          date: '2024-06-05',
          searchVolume: 5545,
          organicRank: 47,
          sponsoredRank: 1,
          keywordSales: 97
        },
        {
          date: '2024-06-04',
          searchVolume: 5545,
          organicRank: 49,

          keywordSales: 97
        },
        {
          date: '2024-06-03',
          searchVolume: 5545,
          organicRank: 54,

          keywordSales: 97
        },
        {
          date: '2024-06-02',
          searchVolume: 5545,
          organicRank: 55,

          keywordSales: 97
        },
        {
          date: '2024-06-01',
          searchVolume: 5545,
          organicRank: 47,
          sponsoredRank: 18,
          keywordSales: 97
        },
        {
          date: '2024-05-31',
          searchVolume: 5603,
          organicRank: 49,
          sponsoredRank: 18,
          keywordSales: 97
        },
        {
          date: '2024-05-30',
          searchVolume: 5603,
          organicRank: 45,
          sponsoredRank: 59,
          keywordSales: 97
        },
        {
          date: '2024-05-29',
          searchVolume: 5603,
          organicRank: 46,

          keywordSales: 97
        },
        {
          date: '2024-05-28',
          searchVolume: 5603,
          organicRank: 51,

          keywordSales: 97
        },
        {
          date: '2024-05-27',
          searchVolume: 5603,
          organicRank: 68,
          sponsoredRank: 83,
          keywordSales: 97
        },
        {
          date: '2024-05-26',
          searchVolume: 5603,
          organicRank: 51,
          sponsoredRank: 24,
          keywordSales: 97
        },
        {
          date: '2024-05-25',
          searchVolume: 5603,
          organicRank: 54,

          keywordSales: 97
        },
        {
          date: '2024-05-24',
          searchVolume: 5898,
          organicRank: 44,

          keywordSales: 97
        },
        {
          date: '2024-05-23',
          searchVolume: 5898,
          organicRank: 46,

          keywordSales: 97
        },
        {
          date: '2024-05-22',
          searchVolume: 5898,
          organicRank: 47,
          sponsoredRank: 1,
          keywordSales: 97
        },
        {
          date: '2024-05-21',
          searchVolume: 5898,
          organicRank: 47,
          sponsoredRank: 65,
          keywordSales: 97
        },
        {
          date: '2024-05-20',
          searchVolume: 5898,
          organicRank: 40,

          keywordSales: 97
        },
        {
          date: '2024-05-19',
          searchVolume: 5898,
          organicRank: 52,

          keywordSales: 97
        },
        {
          date: '2024-05-18',
          searchVolume: 5898,
          organicRank: 42,
          sponsoredRank: 47,
          keywordSales: 97
        },
        {
          date: '2024-05-17',
          searchVolume: 6628,
          organicRank: 72,
          sponsoredRank: 18,
          keywordSales: 97
        },
        {
          date: '2024-05-16',
          searchVolume: 6628,
          organicRank: 54,
          sponsoredRank: 37,
          keywordSales: 97
        },
        {
          date: '2024-05-15',
          searchVolume: 6628,
          organicRank: 61,
          sponsoredRank: 42,
          keywordSales: 97
        },
        {
          date: '2024-05-14',
          searchVolume: 6628,
          organicRank: 68,
          sponsoredRank: 42,
          keywordSales: 97
        },
        {
          date: '2024-05-13',
          searchVolume: 6628,
          organicRank: 42,
          sponsoredRank: 79,
          keywordSales: 97
        },
        {
          date: '2024-05-12',
          searchVolume: 6628,
          organicRank: 41,
          sponsoredRank: 66,
          keywordSales: 97
        },
        {
          date: '2024-05-11',
          searchVolume: 6628,
          organicRank: 42,
          sponsoredRank: 14,
          keywordSales: 97
        },
        {
          date: '2024-05-10',
          searchVolume: 6751,
          organicRank: 39,
          sponsoredRank: 41,
          keywordSales: 97
        },
        {
          date: '2024-05-09',
          searchVolume: 6751,
          organicRank: 40,
          sponsoredRank: 9,
          keywordSales: 97
        },
        {
          date: '2024-05-08',
          searchVolume: 6751,
          organicRank: 40,
          sponsoredRank: 56,
          keywordSales: 97
        },
        {
          date: '2024-05-07',
          searchVolume: 6751,
          organicRank: 42,
          sponsoredRank: 66,
          keywordSales: 97
        },
        {
          date: '2024-05-06',
          searchVolume: 6751,
          organicRank: 29,
          sponsoredRank: 2,
          keywordSales: 97
        },
        {
          date: '2024-05-05',
          searchVolume: 6751,
          organicRank: 43,
          sponsoredRank: 16,
          keywordSales: 97
        },
        {
          date: '2024-05-04',
          searchVolume: 6751,
          organicRank: 40,
          sponsoredRank: 68,
          keywordSales: 97
        },
        {
          date: '2024-05-03',
          searchVolume: 6447,
          organicRank: 43,
          sponsoredRank: 72,
          keywordSales: 97
        },
        {
          date: '2024-05-02',
          searchVolume: 6447,
          organicRank: 41,

          keywordSales: 97
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard',
      keyword: 'mechanical keyboard',
      searchVolume: 96757,
      organicStartRank: 51,
      organicHighestRank: 13,
      sponsoredStartRank: 48,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-05-06',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 84959,
          organicRank: 39,

          keywordSales: 625
        },
        {
          date: '2024-06-19',
          searchVolume: 84959,
          organicRank: 46,
          sponsoredRank: 85,
          keywordSales: 625
        },
        {
          date: '2024-06-18',
          searchVolume: 84959,
          organicRank: 46,
          sponsoredRank: 82,
          keywordSales: 625
        },
        {
          date: '2024-06-17',
          searchVolume: 84959,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 625
        },
        {
          date: '2024-06-16',
          searchVolume: 84959,
          organicRank: 53,
          sponsoredRank: 75,
          keywordSales: 625
        },
        {
          date: '2024-06-15',
          searchVolume: 84959,
          organicRank: 48,

          keywordSales: 625
        },
        {
          date: '2024-06-14',
          searchVolume: 88711,
          organicRank: 56,

          keywordSales: 625
        },
        {
          date: '2024-06-13',
          searchVolume: 88711,
          organicRank: 13,

          keywordSales: 625
        },
        {
          date: '2024-06-12',
          searchVolume: 88711,
          organicRank: 40,
          sponsoredRank: 71,
          keywordSales: 625
        },
        {
          date: '2024-06-11',
          searchVolume: 88711,
          organicRank: 33,
          sponsoredRank: 54,
          keywordSales: 625
        },
        {
          date: '2024-06-10',
          searchVolume: 88711,
          organicRank: 21,
          sponsoredRank: 84,
          keywordSales: 625
        },
        {
          date: '2024-06-09',
          searchVolume: 88711,
          organicRank: 73,
          sponsoredRank: 5,
          keywordSales: 625
        },
        {
          date: '2024-06-08',
          searchVolume: 88711,
          organicRank: 65,
          sponsoredRank: 3,
          keywordSales: 625
        },
        {
          date: '2024-06-07',
          searchVolume: 93693,
          organicRank: 92,

          keywordSales: 625
        },
        {
          date: '2024-06-06',
          searchVolume: 93693,
          organicRank: 68,
          sponsoredRank: 63,
          keywordSales: 625
        },
        {
          date: '2024-06-05',
          searchVolume: 93693,
          organicRank: 56,
          sponsoredRank: 36,
          keywordSales: 625
        },
        {
          date: '2024-06-04',
          searchVolume: 93693,
          organicRank: 71,
          sponsoredRank: 58,
          keywordSales: 625
        },
        {
          date: '2024-06-03',
          searchVolume: 93693,
          organicRank: 81,

          keywordSales: 625
        },
        {
          date: '2024-06-02',
          searchVolume: 93693,
          organicRank: 96,

          keywordSales: 625
        },
        {
          date: '2024-06-01',
          searchVolume: 93693,
          organicRank: 85,
          sponsoredRank: 17,
          keywordSales: 625
        },
        {
          date: '2024-05-31',
          searchVolume: 96692,
          organicRank: 84,

          keywordSales: 625
        },
        {
          date: '2024-05-30',
          searchVolume: 96692,
          organicRank: 49,

          keywordSales: 625
        },
        {
          date: '2024-05-29',
          searchVolume: 96692,
          organicRank: 76,

          keywordSales: 625
        },
        {
          date: '2024-05-28',
          searchVolume: 96692,
          organicRank: 77,

          keywordSales: 625
        },
        {
          date: '2024-05-27',
          searchVolume: 96692,
          organicRank: 87,

          keywordSales: 625
        },
        {
          date: '2024-05-26',
          searchVolume: 96692,
          organicRank: 78,

          keywordSales: 625
        },
        {
          date: '2024-05-25',
          searchVolume: 96692,
          organicRank: 69,
          sponsoredRank: 52,
          keywordSales: 625
        },
        {
          date: '2024-05-24',
          searchVolume: 96757,
          organicRank: 29,
          sponsoredRank: 92,
          keywordSales: 625
        },
        {
          date: '2024-05-23',
          searchVolume: 96757,
          organicRank: 51,

          keywordSales: 625
        },
        {
          date: '2024-05-22',
          searchVolume: 96757,
          organicRank: 62,

          keywordSales: 625
        },
        {
          date: '2024-05-21',
          searchVolume: 96757,
          organicRank: 19,

          keywordSales: 625
        },
        {
          date: '2024-05-20',
          searchVolume: 96757,
          organicRank: 25,

          keywordSales: 625
        },
        {
          date: '2024-05-19',
          searchVolume: 96757,
          organicRank: 63,

          keywordSales: 625
        },
        {
          date: '2024-05-18',
          searchVolume: 96757,
          organicRank: 74,
          sponsoredRank: 31,
          keywordSales: 625
        },
        {
          date: '2024-05-17',
          searchVolume: 96573,
          organicRank: 79,
          sponsoredRank: 49,
          keywordSales: 625
        },
        {
          date: '2024-05-16',
          searchVolume: 96573,
          organicRank: 62,

          keywordSales: 625
        },
        {
          date: '2024-05-15',
          searchVolume: 96573,
          organicRank: 73,

          keywordSales: 625
        },
        {
          date: '2024-05-14',
          searchVolume: 96573,
          organicRank: 75,
          sponsoredRank: 16,
          keywordSales: 625
        },
        {
          date: '2024-05-13',
          searchVolume: 96573,
          organicRank: 99,
          sponsoredRank: 60,
          keywordSales: 625
        },
        {
          date: '2024-05-12',
          searchVolume: 96573,
          organicRank: 45,

          keywordSales: 625
        },
        {
          date: '2024-05-11',
          searchVolume: 96573,
          organicRank: 32,
          sponsoredRank: 34,
          keywordSales: 625
        },
        {
          date: '2024-05-09',
          searchVolume: 98251,
          organicRank: 30,
          sponsoredRank: 28,
          keywordSales: 625
        },
        {
          date: '2024-05-08',
          searchVolume: 98251,
          organicRank: 81,
          sponsoredRank: 78,
          keywordSales: 625
        },
        {
          date: '2024-05-07',
          searchVolume: 98251,
          organicRank: 126,
          sponsoredRank: 72,
          keywordSales: 625
        },
        {
          date: '2024-05-06',
          searchVolume: 98251,
          organicRank: 18,
          sponsoredRank: 42,
          keywordSales: 625
        },
        {
          date: '2024-05-05',
          searchVolume: 98251,
          organicRank: 65,
          sponsoredRank: 49,
          keywordSales: 625
        },
        {
          date: '2024-05-04',
          searchVolume: 98251,
          organicRank: 51,
          sponsoredRank: 48,
          keywordSales: 625
        },
        {
          date: '2024-05-03',
          searchVolume: 93817,
          organicRank: 49,
          sponsoredRank: 60,
          keywordSales: 625
        },
        {
          date: '2024-05-02',
          searchVolume: 93817,
          organicRank: 50,
          sponsoredRank: 67,
          keywordSales: 625
        },
        {
          date: '2024-05-01',
          searchVolume: 93817,
          organicRank: 31,
          sponsoredRank: 48,
          keywordSales: 625
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard 60',
      keyword: 'mechanical keyboard 60',
      searchVolume: 179,
      organicStartRank: 35,
      organicHighestRank: 16,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-19',
          searchVolume: 99,
          organicRank: 25,

          keywordSales: 2
        },
        {
          date: '2024-06-18',
          searchVolume: 99,
          organicRank: 25,

          keywordSales: 2
        },
        {
          date: '2024-06-17',
          searchVolume: 99,
          organicRank: 17,

          keywordSales: 2
        },
        {
          date: '2024-06-16',
          searchVolume: 99,
          organicRank: 17,

          keywordSales: 2
        },
        {
          date: '2024-06-15',
          searchVolume: 99,
          organicRank: 19,
          sponsoredRank: 6,
          keywordSales: 2
        },
        {
          date: '2024-06-14',
          searchVolume: 117,
          organicRank: 27,
          sponsoredRank: 37,
          keywordSales: 2
        },
        {
          date: '2024-06-13',
          searchVolume: 117,
          organicRank: 19,

          keywordSales: 2
        },
        {
          date: '2024-06-12',
          searchVolume: 117,
          organicRank: 29,

          keywordSales: 2
        },
        {
          date: '2024-06-11',
          searchVolume: 117,
          organicRank: 16,

          keywordSales: 2
        },
        {
          date: '2024-06-10',
          searchVolume: 117,
          organicRank: 21,

          keywordSales: 2
        },
        {
          date: '2024-06-09',
          searchVolume: 117,
          organicRank: 26,

          keywordSales: 2
        },
        {
          date: '2024-06-08',
          searchVolume: 117,
          organicRank: 30,

          keywordSales: 2
        },
        {
          date: '2024-06-07',
          searchVolume: 159,
          organicRank: 18,

          keywordSales: 2
        },
        {
          date: '2024-06-06',
          searchVolume: 159,
          organicRank: 26,

          keywordSales: 2
        },
        {
          date: '2024-06-05',
          searchVolume: 159,
          organicRank: 25,
          sponsoredRank: 45,
          keywordSales: 2
        },
        {
          date: '2024-06-04',
          searchVolume: 159,
          organicRank: 30,

          keywordSales: 2
        },
        {
          date: '2024-06-03',
          searchVolume: 159,
          organicRank: 41,

          keywordSales: 2
        },
        {
          date: '2024-06-02',
          searchVolume: 159,
          organicRank: 33,

          keywordSales: 2
        },
        {
          date: '2024-06-01',
          searchVolume: 159,
          organicRank: 42,

          keywordSales: 2
        },
        {
          date: '2024-05-31',
          searchVolume: 206,
          organicRank: 42,
          sponsoredRank: 76,
          keywordSales: 2
        },
        {
          date: '2024-05-30',
          searchVolume: 206,
          organicRank: 42,

          keywordSales: 2
        },
        {
          date: '2024-05-29',
          searchVolume: 206,
          organicRank: 34,

          keywordSales: 2
        },
        {
          date: '2024-05-28',
          searchVolume: 206,
          organicRank: 38,

          keywordSales: 2
        },
        {
          date: '2024-05-27',
          searchVolume: 206,
          organicRank: 38,

          keywordSales: 2
        },
        {
          date: '2024-05-26',
          searchVolume: 206,
          organicRank: 45,

          keywordSales: 2
        },
        {
          date: '2024-05-25',
          searchVolume: 206,
          organicRank: 29,
          sponsoredRank: 26,
          keywordSales: 2
        },
        {
          date: '2024-05-24',
          searchVolume: 179,
          organicRank: 34,

          keywordSales: 2
        },
        {
          date: '2024-05-23',
          searchVolume: 179,
          organicRank: 45,
          sponsoredRank: 82,
          keywordSales: 2
        },
        {
          date: '2024-05-22',
          searchVolume: 179,
          organicRank: 45,

          keywordSales: 2
        },
        {
          date: '2024-05-21',
          searchVolume: 179,
          organicRank: 41,

          keywordSales: 2
        },
        {
          date: '2024-05-20',
          searchVolume: 179,
          organicRank: 44,

          keywordSales: 2
        },
        {
          date: '2024-05-19',
          searchVolume: 179,
          organicRank: 42,

          keywordSales: 2
        },
        {
          date: '2024-05-18',
          searchVolume: 179,
          organicRank: 44,
          sponsoredRank: 68,
          keywordSales: 2
        },
        {
          date: '2024-05-17',
          searchVolume: 241,
          organicRank: 37,
          sponsoredRank: 69,
          keywordSales: 2
        },
        {
          date: '2024-05-16',
          searchVolume: 241,
          organicRank: 40,

          keywordSales: 2
        },
        {
          date: '2024-05-15',
          searchVolume: 241,
          organicRank: 41,

          keywordSales: 2
        },
        {
          date: '2024-05-14',
          searchVolume: 241,
          organicRank: 33,

          keywordSales: 2
        },
        {
          date: '2024-05-13',
          searchVolume: 241,
          organicRank: 28,
          sponsoredRank: 42,
          keywordSales: 2
        },
        {
          date: '2024-05-12',
          searchVolume: 241,
          organicRank: 35,
          sponsoredRank: 48,
          keywordSales: 2
        },
        {
          date: '2024-05-11',
          searchVolume: 241,
          organicRank: 32,
          sponsoredRank: 6,
          keywordSales: 2
        },
        {
          date: '2024-05-10',
          searchVolume: 270,
          organicRank: 38,

          keywordSales: 2
        },
        {
          date: '2024-05-09',
          searchVolume: 270,
          organicRank: 30,

          keywordSales: 2
        },
        {
          date: '2024-05-08',
          searchVolume: 270,
          organicRank: 31,
          sponsoredRank: 62,
          keywordSales: 2
        },
        {
          date: '2024-05-07',
          searchVolume: 270,
          organicRank: 33,

          keywordSales: 2
        },
        {
          date: '2024-05-06',
          searchVolume: 270,
          organicRank: 32,

          keywordSales: 2
        },
        {
          date: '2024-05-05',
          searchVolume: 270,
          organicRank: 30,

          keywordSales: 2
        },
        {
          date: '2024-05-04',
          searchVolume: 270,
          organicRank: 35,

          keywordSales: 2
        },
        {
          date: '2024-05-03',
          searchVolume: 374,
          organicRank: 19,
          sponsoredRank: 31,
          keywordSales: 2
        },
        {
          date: '2024-05-02',
          searchVolume: 374,
          organicRank: 72,

          keywordSales: 2
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard 60 percent',
      keyword: 'mechanical keyboard 60 percent',
      searchVolume: 2509,
      organicStartRank: 14,
      organicHighestRank: 13,
      sponsoredStartRank: 51,
      sponsoredHighestRank: 9,
      organicPageOneWinDate: '2024-05-14',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2398,
          organicRank: 21,

          keywordSales: 43
        },
        {
          date: '2024-06-19',
          searchVolume: 2398,
          organicRank: 17,
          sponsoredRank: 9,
          keywordSales: 43
        },
        {
          date: '2024-06-18',
          searchVolume: 2398,
          organicRank: 20,
          sponsoredRank: 27,
          keywordSales: 43
        },
        {
          date: '2024-06-17',
          searchVolume: 2398,
          organicRank: 22,
          sponsoredRank: 44,
          keywordSales: 43
        },
        {
          date: '2024-06-16',
          searchVolume: 2398,
          organicRank: 17,

          keywordSales: 43
        },
        {
          date: '2024-06-15',
          searchVolume: 2398,
          organicRank: 25,

          keywordSales: 43
        },
        {
          date: '2024-06-14',
          searchVolume: 2548,
          organicRank: 18,
          sponsoredRank: 58,
          keywordSales: 43
        },
        {
          date: '2024-06-13',
          searchVolume: 2548,
          organicRank: 15,
          sponsoredRank: 63,
          keywordSales: 43
        },
        {
          date: '2024-06-12',
          searchVolume: 2548,
          organicRank: 17,

          keywordSales: 43
        },
        {
          date: '2024-06-11',
          searchVolume: 2548,
          organicRank: 21,

          keywordSales: 43
        },
        {
          date: '2024-06-10',
          searchVolume: 2548,
          organicRank: 16,

          keywordSales: 43
        },
        {
          date: '2024-06-09',
          searchVolume: 2548,
          organicRank: 22,
          sponsoredRank: 12,
          keywordSales: 43
        },
        {
          date: '2024-06-08',
          searchVolume: 2548,
          organicRank: 28,

          keywordSales: 43
        },
        {
          date: '2024-06-07',
          searchVolume: 2429,
          organicRank: 26,

          keywordSales: 43
        },
        {
          date: '2024-06-06',
          searchVolume: 2429,
          organicRank: 26,

          keywordSales: 43
        },
        {
          date: '2024-06-05',
          searchVolume: 2429,
          organicRank: 26,
          sponsoredRank: 55,
          keywordSales: 43
        },
        {
          date: '2024-06-04',
          searchVolume: 2429,
          organicRank: 27,

          keywordSales: 43
        },
        {
          date: '2024-06-03',
          searchVolume: 2429,
          organicRank: 20,

          keywordSales: 43
        },
        {
          date: '2024-06-02',
          searchVolume: 2429,
          organicRank: 21,

          keywordSales: 43
        },
        {
          date: '2024-06-01',
          searchVolume: 2429,
          organicRank: 20,
          sponsoredRank: 66,
          keywordSales: 43
        },
        {
          date: '2024-05-31',
          searchVolume: 2507,
          organicRank: 21,
          sponsoredRank: 79,
          keywordSales: 43
        },
        {
          date: '2024-05-30',
          searchVolume: 2507,
          organicRank: 21,

          keywordSales: 43
        },
        {
          date: '2024-05-29',
          searchVolume: 2507,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-05-28',
          searchVolume: 2507,
          organicRank: 20,

          keywordSales: 43
        },
        {
          date: '2024-05-27',
          searchVolume: 2507,
          organicRank: 19,

          keywordSales: 43
        },
        {
          date: '2024-05-26',
          searchVolume: 2507,
          organicRank: 18,
          sponsoredRank: 62,
          keywordSales: 43
        },
        {
          date: '2024-05-25',
          searchVolume: 2507,
          organicRank: 20,

          keywordSales: 43
        },
        {
          date: '2024-05-24',
          searchVolume: 2509,
          organicRank: 19,

          keywordSales: 43
        },
        {
          date: '2024-05-23',
          searchVolume: 2509,
          organicRank: 17,

          keywordSales: 43
        },
        {
          date: '2024-05-22',
          searchVolume: 2509,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-05-21',
          searchVolume: 2509,
          organicRank: 20,
          sponsoredRank: 88,
          keywordSales: 43
        },
        {
          date: '2024-05-20',
          searchVolume: 2509,
          organicRank: 23,

          keywordSales: 43
        },
        {
          date: '2024-05-19',
          searchVolume: 2509,
          organicRank: 22,

          keywordSales: 43
        },
        {
          date: '2024-05-18',
          searchVolume: 2509,
          organicRank: 36,
          sponsoredRank: 32,
          keywordSales: 43
        },
        {
          date: '2024-05-17',
          searchVolume: 2504,
          organicRank: 20,
          sponsoredRank: 64,
          keywordSales: 43
        },
        {
          date: '2024-05-16',
          searchVolume: 2504,
          organicRank: 17,

          keywordSales: 43
        },
        {
          date: '2024-05-15',
          searchVolume: 2504,
          organicRank: 37,
          sponsoredRank: 52,
          keywordSales: 43
        },
        {
          date: '2024-05-14',
          searchVolume: 2504,
          organicRank: 20,
          sponsoredRank: 54,
          keywordSales: 43
        },
        {
          date: '2024-05-13',
          searchVolume: 2504,
          organicRank: 34,
          sponsoredRank: 32,
          keywordSales: 43
        },
        {
          date: '2024-05-12',
          searchVolume: 2504,
          organicRank: 13,
          sponsoredRank: 57,
          keywordSales: 43
        },
        {
          date: '2024-05-11',
          searchVolume: 2504,
          organicRank: 18,

          keywordSales: 43
        },
        {
          date: '2024-05-10',
          searchVolume: 2550,
          organicRank: 19,

          keywordSales: 43
        },
        {
          date: '2024-05-09',
          searchVolume: 2550,
          organicRank: 15,

          keywordSales: 43
        },
        {
          date: '2024-05-08',
          searchVolume: 2550,
          organicRank: 19,

          keywordSales: 43
        },
        {
          date: '2024-05-07',
          searchVolume: 2550,
          organicRank: 20,

          keywordSales: 43
        },
        {
          date: '2024-05-06',
          searchVolume: 2550,
          organicRank: 16,

          keywordSales: 43
        },
        {
          date: '2024-05-05',
          searchVolume: 2550,
          organicRank: 16,
          sponsoredRank: 49,
          keywordSales: 43
        },
        {
          date: '2024-05-04',
          searchVolume: 2550,
          organicRank: 14,
          sponsoredRank: 51,
          keywordSales: 43
        },
        {
          date: '2024-05-03',
          searchVolume: 2996,
          organicRank: 16,
          sponsoredRank: 30,
          keywordSales: 43
        },
        {
          date: '2024-05-02',
          searchVolume: 2996,
          organicRank: 15,

          keywordSales: 43
        },
        {
          date: '2024-05-01',
          searchVolume: 2996,
          organicRank: 15,

          keywordSales: 43
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard 75',
      keyword: 'mechanical keyboard 75',
      searchVolume: 417,
      organicStartRank: 106,
      organicHighestRank: 58,
      sponsoredStartRank: 49,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 468,
          organicRank: 107,
          sponsoredRank: 50,
          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 468,
          organicRank: 109,
          sponsoredRank: 18,
          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 468,
          organicRank: 116,

          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 468,
          organicRank: 100,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 468,
          organicRank: 108,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 468,
          organicRank: 114,

          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 452,
          organicRank: 104,
          sponsoredRank: 55,
          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 452,
          organicRank: 123,

          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 452,
          organicRank: 101,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 452,
          organicRank: 114,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 452,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 452,
          organicRank: 101,
          sponsoredRank: 11,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 452,
          organicRank: 114,

          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 429,
          organicRank: 108,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 429,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 429,
          organicRank: 102,

          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 429,
          organicRank: 105,

          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 429,
          organicRank: 105,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 429,
          organicRank: 103,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 429,
          organicRank: 103,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 456,
          organicRank: 108,

          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 456,
          organicRank: 109,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 456,
          organicRank: 97,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 456,
          organicRank: 97,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 456,
          organicRank: 104,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 456,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 456,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 417,
          organicRank: 165,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 417,
          organicRank: 104,

          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 417,
          organicRank: 97,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 417,
          organicRank: 102,

          keywordSales: 3
        },
        {
          date: '2024-05-20',
          searchVolume: 417,
          organicRank: 91,

          keywordSales: 3
        },
        {
          date: '2024-05-19',
          searchVolume: 417,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-18',
          searchVolume: 417,
          organicRank: 99,

          keywordSales: 3
        },
        {
          date: '2024-05-17',
          searchVolume: 451,
          organicRank: 102,
          sponsoredRank: 78,
          keywordSales: 3
        },
        {
          date: '2024-05-16',
          searchVolume: 451,
          organicRank: 101,

          keywordSales: 3
        },
        {
          date: '2024-05-15',
          searchVolume: 451,
          organicRank: 112,

          keywordSales: 3
        },
        {
          date: '2024-05-14',
          searchVolume: 451,
          organicRank: 113,

          keywordSales: 3
        },
        {
          date: '2024-05-13',
          searchVolume: 451,
          organicRank: 69,

          keywordSales: 3
        },
        {
          date: '2024-05-12',
          searchVolume: 451,
          organicRank: 105,
          sponsoredRank: 62,
          keywordSales: 3
        },
        {
          date: '2024-05-11',
          searchVolume: 451,
          organicRank: 58,
          sponsoredRank: 49,
          keywordSales: 3
        },
        {
          date: '2024-05-10',
          searchVolume: 483,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-09',
          searchVolume: 483,
          organicRank: 105,

          keywordSales: 3
        },
        {
          date: '2024-05-08',
          searchVolume: 483,
          organicRank: 109,

          keywordSales: 3
        },
        {
          date: '2024-05-07',
          searchVolume: 483,
          organicRank: 100,

          keywordSales: 3
        },
        {
          date: '2024-05-06',
          searchVolume: 483,
          organicRank: 101,

          keywordSales: 3
        },
        {
          date: '2024-05-04',
          searchVolume: 483,
          organicRank: 106,

          keywordSales: 3
        },
        {
          date: '2024-05-03',
          searchVolume: 475,
          organicRank: 176,

          keywordSales: 3
        },
        {
          date: '2024-05-02',
          searchVolume: 475,
          organicRank: 111,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard and mouse',
      keyword: 'mechanical keyboard and mouse',
      searchVolume: 3078,
      organicStartRank: 140,
      organicHighestRank: 137,
      sponsoredStartRank: 14,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 3143,
          organicRank: 249,
          sponsoredRank: 71,
          keywordSales: 27
        },
        {
          date: '2024-06-19',
          searchVolume: 3143,
          organicRank: 155,
          sponsoredRank: 60,
          keywordSales: 27
        },
        {
          date: '2024-06-18',
          searchVolume: 3143,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 27
        },
        {
          date: '2024-06-17',
          searchVolume: 3143,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 27
        },
        {
          date: '2024-06-16',
          searchVolume: 3143,
          organicRank: 220,

          keywordSales: 27
        },
        {
          date: '2024-06-15',
          searchVolume: 3143,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 27
        },
        {
          date: '2024-06-14',
          searchVolume: 3230,
          organicRank: 139,

          keywordSales: 27
        },
        {
          date: '2024-06-13',
          searchVolume: 3230,
          organicRank: 256,

          keywordSales: 27
        },
        {
          date: '2024-06-12',
          searchVolume: 3230,
          organicRank: 160,

          keywordSales: 27
        },
        {
          date: '2024-06-11',
          searchVolume: 3230,
          organicRank: 193,

          keywordSales: 27
        },
        {
          date: '2024-06-10',
          searchVolume: 3230,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-09',
          searchVolume: 3230,
          organicRank: 172,
          sponsoredRank: 92,
          keywordSales: 27
        },
        {
          date: '2024-06-08',
          searchVolume: 3230,
          organicRank: 178,
          sponsoredRank: 17,
          keywordSales: 27
        },
        {
          date: '2024-06-07',
          searchVolume: 2434,
          organicRank: 239,

          keywordSales: 27
        },
        {
          date: '2024-06-06',
          searchVolume: 2434,
          organicRank: 185,

          keywordSales: 27
        },
        {
          date: '2024-06-05',
          searchVolume: 2434,
          organicRank: 186,

          keywordSales: 27
        },
        {
          date: '2024-06-04',
          searchVolume: 2434,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 27
        },
        {
          date: '2024-06-03',
          searchVolume: 2434,
          organicRank: 219,

          keywordSales: 27
        },
        {
          date: '2024-06-02',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-06-01',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-31',
          searchVolume: 2511,
          organicRank: 176,
          sponsoredRank: 7,
          keywordSales: 27
        },
        {
          date: '2024-05-30',
          searchVolume: 2511,
          organicRank: 140,

          keywordSales: 27
        },
        {
          date: '2024-05-29',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-28',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-27',
          searchVolume: 2511,
          organicRank: 232,

          keywordSales: 27
        },
        {
          date: '2024-05-26',
          searchVolume: 2511,
          organicRank: 137,
          sponsoredRank: 31,
          keywordSales: 27
        },
        {
          date: '2024-05-25',
          searchVolume: 2511,
          organicRank: 273,

          keywordSales: 27
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 276,

          keywordSales: 27
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 190,
          sponsoredRank: 64,
          keywordSales: 27
        },
        {
          date: '2024-05-22',
          searchVolume: 3078,
          organicRank: 199,

          keywordSales: 27
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 195,

          keywordSales: 27
        },
        {
          date: '2024-05-20',
          searchVolume: 3078,
          organicRank: 204,

          keywordSales: 27
        },
        {
          date: '2024-05-19',
          searchVolume: 3078,
          organicRank: 194,

          keywordSales: 27
        },
        {
          date: '2024-05-18',
          searchVolume: 3078,
          organicRank: 278,

          keywordSales: 27
        },
        {
          date: '2024-05-17',
          searchVolume: 3398,
          organicRank: 246,
          sponsoredRank: 14,
          keywordSales: 27
        },
        {
          date: '2024-05-16',
          searchVolume: 3398,
          organicRank: 244,

          keywordSales: 27
        },
        {
          date: '2024-05-15',
          searchVolume: 3398,
          organicRank: 221,

          keywordSales: 27
        },
        {
          date: '2024-05-14',
          searchVolume: 3398,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-13',
          searchVolume: 3398,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-12',
          searchVolume: 3398,
          organicRank: 216,

          keywordSales: 27
        },
        {
          date: '2024-05-11',
          searchVolume: 3398,
          organicRank: 265,

          keywordSales: 27
        },
        {
          date: '2024-05-10',
          searchVolume: 3457,
          organicRank: 306,

          keywordSales: 27
        },
        {
          date: '2024-05-09',
          searchVolume: 3457,
          organicRank: 189,

          keywordSales: 27
        },
        {
          date: '2024-05-08',
          searchVolume: 3457,
          organicRank: 212,

          keywordSales: 27
        },
        {
          date: '2024-05-07',
          searchVolume: 3457,
          organicRank: 152,

          keywordSales: 27
        },
        {
          date: '2024-05-06',
          searchVolume: 3457,
          organicRank: 216,

          keywordSales: 27
        },
        {
          date: '2024-05-05',
          searchVolume: 3457,
          organicRank: 147,

          keywordSales: 27
        },
        {
          date: '2024-05-04',
          searchVolume: 3457,
          organicRank: 140,

          keywordSales: 27
        },
        {
          date: '2024-05-03',
          searchVolume: 3088,
          organicRank: 129,

          keywordSales: 27
        },
        {
          date: '2024-05-02',
          searchVolume: 3088,
          organicRank: 131,

          keywordSales: 27
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard brown switches',
      keyword: 'mechanical keyboard brown switches',
      searchVolume: 1584,
      organicStartRank: 89,
      organicHighestRank: 79,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-19',
          searchVolume: 1445,
          organicRank: 115,

          keywordSales: 14
        },
        {
          date: '2024-06-18',
          searchVolume: 1445,
          organicRank: 113,
          sponsoredRank: 45,
          keywordSales: 14
        },
        {
          date: '2024-06-17',
          searchVolume: 1445,
          organicRank: 116,
          sponsoredRank: 23,
          keywordSales: 14
        },
        {
          date: '2024-06-16',
          searchVolume: 1445,
          organicRank: 124,
          sponsoredRank: 67,
          keywordSales: 14
        },
        {
          date: '2024-06-15',
          searchVolume: 1445,
          organicRank: 118,
          sponsoredRank: 4,
          keywordSales: 14
        },
        {
          date: '2024-06-14',
          searchVolume: 1347,
          organicRank: 125,
          sponsoredRank: 76,
          keywordSales: 14
        },
        {
          date: '2024-06-13',
          searchVolume: 1347,
          organicRank: 131,
          sponsoredRank: 51,
          keywordSales: 14
        },
        {
          date: '2024-06-12',
          searchVolume: 1347,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-11',
          searchVolume: 1347,
          organicRank: 135,

          keywordSales: 14
        },
        {
          date: '2024-06-10',
          searchVolume: 1347,
          organicRank: 136,

          keywordSales: 14
        },
        {
          date: '2024-06-09',
          searchVolume: 1347,
          organicRank: 125,
          sponsoredRank: 6,
          keywordSales: 14
        },
        {
          date: '2024-06-08',
          searchVolume: 1347,
          organicRank: 187,
          sponsoredRank: 5,
          keywordSales: 14
        },
        {
          date: '2024-06-07',
          searchVolume: 1290,
          organicRank: 130,

          keywordSales: 14
        },
        {
          date: '2024-06-06',
          searchVolume: 1290,
          organicRank: 119,

          keywordSales: 14
        },
        {
          date: '2024-06-05',
          searchVolume: 1290,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-04',
          searchVolume: 1290,
          organicRank: 121,

          keywordSales: 14
        },
        {
          date: '2024-06-03',
          searchVolume: 1290,
          organicRank: 125,

          keywordSales: 14
        },
        {
          date: '2024-06-02',
          searchVolume: 1290,
          organicRank: 111,

          keywordSales: 14
        },
        {
          date: '2024-06-01',
          searchVolume: 1290,
          organicRank: 120,

          keywordSales: 14
        },
        {
          date: '2024-05-31',
          searchVolume: 1418,
          organicRank: 86,
          sponsoredRank: 33,
          keywordSales: 14
        },
        {
          date: '2024-05-30',
          searchVolume: 1418,
          organicRank: 118,

          keywordSales: 14
        },
        {
          date: '2024-05-29',
          searchVolume: 1418,
          organicRank: 79,

          keywordSales: 14
        },
        {
          date: '2024-05-28',
          searchVolume: 1418,
          organicRank: 95,

          keywordSales: 14
        },
        {
          date: '2024-05-27',
          searchVolume: 1418,
          organicRank: 92,

          keywordSales: 14
        },
        {
          date: '2024-05-26',
          searchVolume: 1418,
          organicRank: 101,
          sponsoredRank: 83,
          keywordSales: 14
        },
        {
          date: '2024-05-25',
          searchVolume: 1418,
          organicRank: 90,
          sponsoredRank: 55,
          keywordSales: 14
        },
        {
          date: '2024-05-24',
          searchVolume: 1584,
          organicRank: 236,

          keywordSales: 14
        },
        {
          date: '2024-05-23',
          searchVolume: 1584,
          organicRank: 90,
          sponsoredRank: 72,
          keywordSales: 14
        },
        {
          date: '2024-05-22',
          searchVolume: 1584,
          organicRank: 79,

          keywordSales: 14
        },
        {
          date: '2024-05-21',
          searchVolume: 1584,
          organicRank: 118,
          sponsoredRank: 52,
          keywordSales: 14
        },
        {
          date: '2024-05-20',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-05-19',
          searchVolume: 1584,
          organicRank: 106,

          keywordSales: 14
        },
        {
          date: '2024-05-18',
          searchVolume: 1584,
          organicRank: 96,
          sponsoredRank: 50,
          keywordSales: 14
        },
        {
          date: '2024-05-17',
          searchVolume: 1419,
          organicRank: 103,
          sponsoredRank: 50,
          keywordSales: 14
        },
        {
          date: '2024-05-16',
          searchVolume: 1419,
          organicRank: 110,

          keywordSales: 14
        },
        {
          date: '2024-05-15',
          searchVolume: 1419,
          organicRank: 116,

          keywordSales: 14
        },
        {
          date: '2024-05-14',
          searchVolume: 1419,
          organicRank: 112,
          sponsoredRank: 50,
          keywordSales: 14
        },
        {
          date: '2024-05-13',
          searchVolume: 1419,
          organicRank: 112,
          sponsoredRank: 42,
          keywordSales: 14
        },
        {
          date: '2024-05-12',
          searchVolume: 1419,
          organicRank: 114,
          sponsoredRank: 57,
          keywordSales: 14
        },
        {
          date: '2024-05-11',
          searchVolume: 1419,
          organicRank: 104,
          sponsoredRank: 3,
          keywordSales: 14
        },
        {
          date: '2024-05-10',
          searchVolume: 1347,
          organicRank: 107,
          sponsoredRank: 55,
          keywordSales: 14
        },
        {
          date: '2024-05-09',
          searchVolume: 1347,
          organicRank: 87,
          sponsoredRank: 66,
          keywordSales: 14
        },
        {
          date: '2024-05-08',
          searchVolume: 1347,
          organicRank: 99,

          keywordSales: 14
        },
        {
          date: '2024-05-07',
          searchVolume: 1347,
          organicRank: 87,

          keywordSales: 14
        },
        {
          date: '2024-05-06',
          searchVolume: 1347,
          organicRank: 118,
          sponsoredRank: 54,
          keywordSales: 14
        },
        {
          date: '2024-05-04',
          searchVolume: 1347,
          organicRank: 89,
          sponsoredRank: 29,
          keywordSales: 14
        },
        {
          date: '2024-05-03',
          searchVolume: 1379,
          organicRank: 102,

          keywordSales: 14
        },
        {
          date: '2024-05-02',
          searchVolume: 1379,
          organicRank: 112,

          keywordSales: 14
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard full size',
      keyword: 'mechanical keyboard full size',
      searchVolume: 3078,
      organicStartRank: 38,
      organicHighestRank: 22,
      sponsoredStartRank: 75,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 3627,
          organicRank: 33,
          sponsoredRank: 66,
          keywordSales: 19
        },
        {
          date: '2024-06-19',
          searchVolume: 3627,
          organicRank: 34,

          keywordSales: 19
        },
        {
          date: '2024-06-18',
          searchVolume: 3627,
          organicRank: 38,

          keywordSales: 19
        },
        {
          date: '2024-06-17',
          searchVolume: 3627,
          organicRank: 38,

          keywordSales: 19
        },
        {
          date: '2024-06-16',
          searchVolume: 3627,
          organicRank: 38,

          keywordSales: 19
        },
        {
          date: '2024-06-15',
          searchVolume: 3627,
          organicRank: 41,
          sponsoredRank: 5,
          keywordSales: 19
        },
        {
          date: '2024-06-14',
          searchVolume: 3626,
          organicRank: 27,

          keywordSales: 19
        },
        {
          date: '2024-06-13',
          searchVolume: 3626,
          organicRank: 39,

          keywordSales: 19
        },
        {
          date: '2024-06-12',
          searchVolume: 3626,
          organicRank: 27,

          keywordSales: 19
        },
        {
          date: '2024-06-11',
          searchVolume: 3626,
          organicRank: 31,

          keywordSales: 19
        },
        {
          date: '2024-06-10',
          searchVolume: 3626,
          organicRank: 28,

          keywordSales: 19
        },
        {
          date: '2024-06-09',
          searchVolume: 3626,
          organicRank: 22,
          sponsoredRank: 24,
          keywordSales: 19
        },
        {
          date: '2024-06-08',
          searchVolume: 3626,
          organicRank: 29,
          sponsoredRank: 2,
          keywordSales: 19
        },
        {
          date: '2024-06-07',
          searchVolume: 3453,
          organicRank: 41,

          keywordSales: 19
        },
        {
          date: '2024-06-06',
          searchVolume: 3453,
          organicRank: 44,

          keywordSales: 19
        },
        {
          date: '2024-06-05',
          searchVolume: 3453,
          organicRank: 43,
          sponsoredRank: 8,
          keywordSales: 19
        },
        {
          date: '2024-06-04',
          searchVolume: 3453,
          organicRank: 47,

          keywordSales: 19
        },
        {
          date: '2024-06-03',
          searchVolume: 3453,
          organicRank: 44,

          keywordSales: 19
        },
        {
          date: '2024-06-02',
          searchVolume: 3453,
          organicRank: 29,

          keywordSales: 19
        },
        {
          date: '2024-06-01',
          searchVolume: 3453,
          organicRank: 57,

          keywordSales: 19
        },
        {
          date: '2024-05-31',
          searchVolume: 3566,
          organicRank: 42,
          sponsoredRank: 92,
          keywordSales: 19
        },
        {
          date: '2024-05-30',
          searchVolume: 3566,
          organicRank: 46,

          keywordSales: 19
        },
        {
          date: '2024-05-29',
          searchVolume: 3566,
          organicRank: 37,
          sponsoredRank: 64,
          keywordSales: 19
        },
        {
          date: '2024-05-28',
          searchVolume: 3566,
          organicRank: 55,

          keywordSales: 19
        },
        {
          date: '2024-05-27',
          searchVolume: 3566,
          organicRank: 54,

          keywordSales: 19
        },
        {
          date: '2024-05-26',
          searchVolume: 3566,
          organicRank: 49,
          sponsoredRank: 13,
          keywordSales: 19
        },
        {
          date: '2024-05-25',
          searchVolume: 3566,
          organicRank: 49,

          keywordSales: 19
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 56,

          keywordSales: 19
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 37,
          sponsoredRank: 28,
          keywordSales: 19
        },
        {
          date: '2024-05-22',
          searchVolume: 3078,
          organicRank: 63,
          sponsoredRank: 40,
          keywordSales: 19
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 46,

          keywordSales: 19
        },
        {
          date: '2024-05-20',
          searchVolume: 3078,
          organicRank: 45,

          keywordSales: 19
        },
        {
          date: '2024-05-19',
          searchVolume: 3078,
          organicRank: 42,

          keywordSales: 19
        },
        {
          date: '2024-05-18',
          searchVolume: 3078,
          organicRank: 40,

          keywordSales: 19
        },
        {
          date: '2024-05-17',
          searchVolume: 2507,
          organicRank: 50,
          sponsoredRank: 3,
          keywordSales: 19
        },
        {
          date: '2024-05-16',
          searchVolume: 2507,
          organicRank: 52,

          keywordSales: 19
        },
        {
          date: '2024-05-15',
          searchVolume: 2507,
          organicRank: 48,
          sponsoredRank: 70,
          keywordSales: 19
        },
        {
          date: '2024-05-14',
          searchVolume: 2507,
          organicRank: 24,
          sponsoredRank: 71,
          keywordSales: 19
        },
        {
          date: '2024-05-13',
          searchVolume: 2507,
          organicRank: 27,
          sponsoredRank: 80,
          keywordSales: 19
        },
        {
          date: '2024-05-12',
          searchVolume: 2507,
          organicRank: 40,
          sponsoredRank: 12,
          keywordSales: 19
        },
        {
          date: '2024-05-11',
          searchVolume: 2507,
          organicRank: 44,
          sponsoredRank: 32,
          keywordSales: 19
        },
        {
          date: '2024-05-10',
          searchVolume: 2552,
          organicRank: 43,
          sponsoredRank: 31,
          keywordSales: 19
        },
        {
          date: '2024-05-09',
          searchVolume: 2552,
          organicRank: 37,

          keywordSales: 19
        },
        {
          date: '2024-05-08',
          searchVolume: 2552,
          organicRank: 36,
          sponsoredRank: 40,
          keywordSales: 19
        },
        {
          date: '2024-05-07',
          searchVolume: 2552,
          organicRank: 42,
          sponsoredRank: 69,
          keywordSales: 19
        },
        {
          date: '2024-05-06',
          searchVolume: 2552,
          organicRank: 39,
          sponsoredRank: 62,
          keywordSales: 19
        },
        {
          date: '2024-05-05',
          searchVolume: 2552,
          organicRank: 39,
          sponsoredRank: 56,
          keywordSales: 19
        },
        {
          date: '2024-05-04',
          searchVolume: 2552,
          organicRank: 38,
          sponsoredRank: 75,
          keywordSales: 19
        },
        {
          date: '2024-05-03',
          searchVolume: 2996,
          organicRank: 40,
          sponsoredRank: 19,
          keywordSales: 19
        },
        {
          date: '2024-05-02',
          searchVolume: 2996,
          organicRank: 37,
          sponsoredRank: 52,
          keywordSales: 19
        },
        {
          date: '2024-05-01',
          searchVolume: 2996,
          organicRank: 46,
          sponsoredRank: 8,
          keywordSales: 19
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard keycaps',
      keyword: 'mechanical keyboard keycaps',
      searchVolume: 1249,
      organicStartRank: 131,
      organicHighestRank: 131,
      sponsoredStartRank: 32,
      sponsoredHighestRank: 32,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1221,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-20',
          searchVolume: 1221,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-19',
          searchVolume: 1221,
          organicRank: 152,

          keywordSales: 1
        },
        {
          date: '2024-06-18',
          searchVolume: 1221,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-17',
          searchVolume: 1221,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-16',
          searchVolume: 1221,
          organicRank: 154,

          keywordSales: 1
        },
        {
          date: '2024-06-15',
          searchVolume: 1221,
          organicRank: 152,

          keywordSales: 1
        },
        {
          date: '2024-06-14',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-13',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-12',
          searchVolume: 886,
          organicRank: 149,

          keywordSales: 1
        },
        {
          date: '2024-06-11',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-10',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-09',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-08',
          searchVolume: 886,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-07',
          searchVolume: 1163,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-06',
          searchVolume: 1163,
          organicRank: 152,

          keywordSales: 1
        },
        {
          date: '2024-06-05',
          searchVolume: 1163,
          organicRank: 165,

          keywordSales: 1
        },
        {
          date: '2024-06-04',
          searchVolume: 1163,
          organicRank: 226,

          keywordSales: 1
        },
        {
          date: '2024-06-03',
          searchVolume: 1163,
          organicRank: 221,

          keywordSales: 1
        },
        {
          date: '2024-06-02',
          searchVolume: 1163,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-01',
          searchVolume: 1163,
          organicRank: 208,

          keywordSales: 1
        },
        {
          date: '2024-05-31',
          searchVolume: 1134,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-30',
          searchVolume: 1134,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-29',
          searchVolume: 1134,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-28',
          searchVolume: 1134,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-27',
          searchVolume: 1134,
          organicRank: 208,

          keywordSales: 1
        },
        {
          date: '2024-05-26',
          searchVolume: 1134,
          organicRank: 235,

          keywordSales: 1
        },
        {
          date: '2024-05-25',
          searchVolume: 1134,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-24',
          searchVolume: 1249,
          organicRank: 204,

          keywordSales: 1
        },
        {
          date: '2024-05-23',
          searchVolume: 1249,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-22',
          searchVolume: 1249,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-21',
          searchVolume: 1249,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-20',
          searchVolume: 1249,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-19',
          searchVolume: 1249,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-18',
          searchVolume: 1249,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-17',
          searchVolume: 937,
          organicRank: 170,
          sponsoredRank: 73,
          keywordSales: 1
        },
        {
          date: '2024-05-16',
          searchVolume: 937,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 1
        },
        {
          date: '2024-05-15',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-14',
          searchVolume: 937,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 1
        },
        {
          date: '2024-05-13',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-12',
          searchVolume: 937,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-11',
          searchVolume: 937,
          organicRank: 226,

          keywordSales: 1
        },
        {
          date: '2024-05-10',
          searchVolume: 1352,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-09',
          searchVolume: 1352,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-08',
          searchVolume: 1352,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-07',
          searchVolume: 1352,
          organicRank: 143,

          keywordSales: 1
        },
        {
          date: '2024-05-06',
          searchVolume: 1352,
          organicRank: 131,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard switches',
      keyword: 'mechanical keyboard switches',
      searchVolume: 1222,
      organicStartRank: 83,
      organicHighestRank: 77,
      sponsoredStartRank: 59,
      sponsoredHighestRank: 59,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1267,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-06-19',
          searchVolume: 1267,
          organicRank: 195,

          keywordSales: 17
        },
        {
          date: '2024-06-18',
          searchVolume: 1267,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-06-17',
          searchVolume: 1267,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-06-16',
          searchVolume: 1267,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-06-15',
          searchVolume: 1267,
          organicRank: 202,

          keywordSales: 17
        },
        {
          date: '2024-06-14',
          searchVolume: 1430,
          organicRank: 180,
          sponsoredRank: 90,
          keywordSales: 17
        },
        {
          date: '2024-06-13',
          searchVolume: 1430,
          organicRank: 209,

          keywordSales: 17
        },
        {
          date: '2024-06-12',
          searchVolume: 1430,
          organicRank: 202,

          keywordSales: 17
        },
        {
          date: '2024-06-11',
          searchVolume: 1430,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-06-10',
          searchVolume: 1430,
          organicRank: 186,

          keywordSales: 17
        },
        {
          date: '2024-06-09',
          searchVolume: 1430,
          organicRank: 193,

          keywordSales: 17
        },
        {
          date: '2024-06-08',
          searchVolume: 1430,
          organicRank: 244,

          keywordSales: 17
        },
        {
          date: '2024-06-07',
          searchVolume: 1290,
          organicRank: 128,

          keywordSales: 17
        },
        {
          date: '2024-06-06',
          searchVolume: 1290,
          organicRank: 133,

          keywordSales: 17
        },
        {
          date: '2024-06-05',
          searchVolume: 1290,
          organicRank: 128,

          keywordSales: 17
        },
        {
          date: '2024-06-04',
          searchVolume: 1290,
          organicRank: 135,

          keywordSales: 17
        },
        {
          date: '2024-06-03',
          searchVolume: 1290,
          organicRank: 148,

          keywordSales: 17
        },
        {
          date: '2024-06-02',
          searchVolume: 1290,
          organicRank: 112,

          keywordSales: 17
        },
        {
          date: '2024-06-01',
          searchVolume: 1290,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-31',
          searchVolume: 1249,
          organicRank: 205,

          keywordSales: 17
        },
        {
          date: '2024-05-30',
          searchVolume: 1249,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-29',
          searchVolume: 1249,
          organicRank: 180,

          keywordSales: 17
        },
        {
          date: '2024-05-28',
          searchVolume: 1249,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-27',
          searchVolume: 1249,
          organicRank: 103,

          keywordSales: 17
        },
        {
          date: '2024-05-26',
          searchVolume: 1249,
          organicRank: 252,

          keywordSales: 17
        },
        {
          date: '2024-05-25',
          searchVolume: 1249,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-24',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-23',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-22',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-21',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-20',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-19',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-18',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-17',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-16',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-15',
          searchVolume: 1202,
          organicRank: 306,
          sponsoredRank: 85,
          keywordSales: 17
        },
        {
          date: '2024-05-14',
          searchVolume: 1202,
          organicRank: 170,

          keywordSales: 17
        },
        {
          date: '2024-05-13',
          searchVolume: 1202,
          organicRank: 87,
          sponsoredRank: 67,
          keywordSales: 17
        },
        {
          date: '2024-05-12',
          searchVolume: 1202,
          organicRank: 113,

          keywordSales: 17
        },
        {
          date: '2024-05-11',
          searchVolume: 1202,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-10',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-09',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 17
        },
        {
          date: '2024-05-08',
          searchVolume: 1222,
          organicRank: 247,

          keywordSales: 17
        },
        {
          date: '2024-05-07',
          searchVolume: 1222,
          organicRank: 105,

          keywordSales: 17
        },
        {
          date: '2024-05-06',
          searchVolume: 1222,
          organicRank: 118,

          keywordSales: 17
        },
        {
          date: '2024-05-05',
          searchVolume: 1222,
          organicRank: 77,
          sponsoredRank: 59,
          keywordSales: 17
        },
        {
          date: '2024-05-04',
          searchVolume: 1222,
          organicRank: 83,

          keywordSales: 17
        },
        {
          date: '2024-05-03',
          searchVolume: 1283,
          organicRank: 104,

          keywordSales: 17
        },
        {
          date: '2024-05-02',
          searchVolume: 1283,
          organicRank: 171,

          keywordSales: 17
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard wireless',
      keyword: 'mechanical keyboard wireless',
      searchVolume: 10610,
      organicStartRank: 229,
      organicHighestRank: 85,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-10',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 9284,
          organicRank: 222,

          keywordSales: 53
        },
        {
          date: '2024-06-20',
          searchVolume: 9284,
          organicRank: 253,

          keywordSales: 53
        },
        {
          date: '2024-06-19',
          searchVolume: 9284,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-18',
          searchVolume: 9284,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-17',
          searchVolume: 9284,
          organicRank: 306,
          sponsoredRank: 24,
          keywordSales: 53
        },
        {
          date: '2024-06-16',
          searchVolume: 9284,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-15',
          searchVolume: 9284,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-14',
          searchVolume: 9284,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-13',
          searchVolume: 9284,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-12',
          searchVolume: 9284,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-11',
          searchVolume: 9284,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-10',
          searchVolume: 9284,
          organicRank: 176,

          keywordSales: 53
        },
        {
          date: '2024-06-09',
          searchVolume: 9284,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 53
        },
        {
          date: '2024-06-08',
          searchVolume: 9284,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 53
        },
        {
          date: '2024-06-07',
          searchVolume: 10239,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-06',
          searchVolume: 10239,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-05',
          searchVolume: 10239,
          organicRank: 306,
          sponsoredRank: 70,
          keywordSales: 53
        },
        {
          date: '2024-06-04',
          searchVolume: 10239,
          organicRank: 213,

          keywordSales: 53
        },
        {
          date: '2024-06-03',
          searchVolume: 10239,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-02',
          searchVolume: 10239,
          organicRank: 226,

          keywordSales: 53
        },
        {
          date: '2024-06-01',
          searchVolume: 10239,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-31',
          searchVolume: 9143,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-30',
          searchVolume: 9143,
          organicRank: 85,
          sponsoredRank: 25,
          keywordSales: 53
        },
        {
          date: '2024-05-29',
          searchVolume: 9143,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-28',
          searchVolume: 9143,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-27',
          searchVolume: 9143,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-26',
          searchVolume: 9143,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-25',
          searchVolume: 9143,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-24',
          searchVolume: 10610,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-23',
          searchVolume: 10610,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-22',
          searchVolume: 10610,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 53
        },
        {
          date: '2024-05-21',
          searchVolume: 10610,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-20',
          searchVolume: 10610,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-19',
          searchVolume: 10610,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-18',
          searchVolume: 10610,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-17',
          searchVolume: 9129,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 53
        },
        {
          date: '2024-05-16',
          searchVolume: 9129,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 53
        },
        {
          date: '2024-05-15',
          searchVolume: 9129,
          organicRank: 306,
          sponsoredRank: 55,
          keywordSales: 53
        },
        {
          date: '2024-05-14',
          searchVolume: 9129,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 53
        },
        {
          date: '2024-05-13',
          searchVolume: 9129,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 53
        },
        {
          date: '2024-05-12',
          searchVolume: 9129,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 53
        },
        {
          date: '2024-05-11',
          searchVolume: 9129,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 53
        },
        {
          date: '2024-05-10',
          searchVolume: 10789,
          organicRank: 306,
          sponsoredRank: 14,
          keywordSales: 53
        },
        {
          date: '2024-05-09',
          searchVolume: 10789,
          organicRank: 306,
          sponsoredRank: 62,
          keywordSales: 53
        },
        {
          date: '2024-05-08',
          searchVolume: 10789,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 53
        },
        {
          date: '2024-05-07',
          searchVolume: 10789,
          organicRank: 306,
          sponsoredRank: 35,
          keywordSales: 53
        },
        {
          date: '2024-05-06',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-05',
          searchVolume: 10789,
          organicRank: 229,
          sponsoredRank: 29,
          keywordSales: 53
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboard with number pad',
      keyword: 'mechanical keyboard with number pad',
      searchVolume: 1884,
      organicStartRank: 121,
      organicHighestRank: 101,
      sponsoredStartRank: 57,
      sponsoredHighestRank: 10,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-20',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-19',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-18',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-17',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-16',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-15',
          searchVolume: 1913,
          organicRank: 189,

          keywordSales: 22
        },
        {
          date: '2024-06-14',
          searchVolume: 1685,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-13',
          searchVolume: 1685,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-12',
          searchVolume: 1685,
          organicRank: 185,

          keywordSales: 22
        },
        {
          date: '2024-06-11',
          searchVolume: 1685,
          organicRank: 268,

          keywordSales: 22
        },
        {
          date: '2024-06-10',
          searchVolume: 1685,
          organicRank: 188,

          keywordSales: 22
        },
        {
          date: '2024-06-09',
          searchVolume: 1685,
          organicRank: 210,

          keywordSales: 22
        },
        {
          date: '2024-06-08',
          searchVolume: 1685,
          organicRank: 193,

          keywordSales: 22
        },
        {
          date: '2024-06-07',
          searchVolume: 1832,
          organicRank: 227,

          keywordSales: 22
        },
        {
          date: '2024-06-06',
          searchVolume: 1832,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-05',
          searchVolume: 1832,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-04',
          searchVolume: 1832,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-03',
          searchVolume: 1832,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-02',
          searchVolume: 1832,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-06-01',
          searchVolume: 1832,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-31',
          searchVolume: 1633,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-30',
          searchVolume: 1633,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-29',
          searchVolume: 1633,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-28',
          searchVolume: 1633,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-27',
          searchVolume: 1633,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-26',
          searchVolume: 1633,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-25',
          searchVolume: 1633,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-24',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-23',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-22',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-21',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-20',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-19',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-18',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-17',
          searchVolume: 1885,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 22
        },
        {
          date: '2024-05-16',
          searchVolume: 1885,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-15',
          searchVolume: 1885,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-14',
          searchVolume: 1885,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-13',
          searchVolume: 1885,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-12',
          searchVolume: 1885,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 22
        },
        {
          date: '2024-05-11',
          searchVolume: 1885,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 22
        },
        {
          date: '2024-05-10',
          searchVolume: 1443,
          organicRank: 101,

          keywordSales: 22
        },
        {
          date: '2024-05-09',
          searchVolume: 1443,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-08',
          searchVolume: 1443,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-07',
          searchVolume: 1443,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-06',
          searchVolume: 1443,
          organicRank: 306,

          keywordSales: 22
        },
        {
          date: '2024-05-01',
          searchVolume: 1827,
          organicRank: 121,

          keywordSales: 22
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mechanical keyboards',
      keyword: 'mechanical keyboards',
      searchVolume: 2140,
      organicStartRank: 40,
      organicHighestRank: 7,
      sponsoredStartRank: 84,
      sponsoredHighestRank: 6,
      organicPageOneWinDate: '2024-05-16',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2547,
          organicRank: 23,
          sponsoredRank: 72,
          keywordSales: 9
        },
        {
          date: '2024-06-19',
          searchVolume: 2547,
          organicRank: 26,
          sponsoredRank: 76,
          keywordSales: 9
        },
        {
          date: '2024-06-18',
          searchVolume: 2547,
          organicRank: 17,
          sponsoredRank: 36,
          keywordSales: 9
        },
        {
          date: '2024-06-17',
          searchVolume: 2547,
          organicRank: 22,
          sponsoredRank: 67,
          keywordSales: 9
        },
        {
          date: '2024-06-16',
          searchVolume: 2547,
          organicRank: 21,
          sponsoredRank: 60,
          keywordSales: 9
        },
        {
          date: '2024-06-15',
          searchVolume: 2547,
          organicRank: 19,
          sponsoredRank: 13,
          keywordSales: 9
        },
        {
          date: '2024-06-14',
          searchVolume: 2428,
          organicRank: 19,
          sponsoredRank: 27,
          keywordSales: 9
        },
        {
          date: '2024-06-13',
          searchVolume: 2428,
          organicRank: 11,

          keywordSales: 9
        },
        {
          date: '2024-06-12',
          searchVolume: 2428,
          organicRank: 19,

          keywordSales: 9
        },
        {
          date: '2024-06-11',
          searchVolume: 2428,
          organicRank: 22,

          keywordSales: 9
        },
        {
          date: '2024-06-10',
          searchVolume: 2428,
          organicRank: 13,

          keywordSales: 9
        },
        {
          date: '2024-06-09',
          searchVolume: 2428,
          organicRank: 25,
          sponsoredRank: 18,
          keywordSales: 9
        },
        {
          date: '2024-06-08',
          searchVolume: 2428,
          organicRank: 18,
          sponsoredRank: 17,
          keywordSales: 9
        },
        {
          date: '2024-06-07',
          searchVolume: 2279,
          organicRank: 21,

          keywordSales: 9
        },
        {
          date: '2024-06-06',
          searchVolume: 2279,
          organicRank: 21,

          keywordSales: 9
        },
        {
          date: '2024-06-05',
          searchVolume: 2279,
          organicRank: 23,
          sponsoredRank: 87,
          keywordSales: 9
        },
        {
          date: '2024-06-04',
          searchVolume: 2279,
          organicRank: 22,

          keywordSales: 9
        },
        {
          date: '2024-06-03',
          searchVolume: 2279,
          organicRank: 12,

          keywordSales: 9
        },
        {
          date: '2024-06-02',
          searchVolume: 2279,
          organicRank: 7,

          keywordSales: 9
        },
        {
          date: '2024-06-01',
          searchVolume: 2279,
          organicRank: 11,

          keywordSales: 9
        },
        {
          date: '2024-05-31',
          searchVolume: 2217,
          organicRank: 16,
          sponsoredRank: 6,
          keywordSales: 9
        },
        {
          date: '2024-05-30',
          searchVolume: 2217,
          organicRank: 22,
          sponsoredRank: 75,
          keywordSales: 9
        },
        {
          date: '2024-05-29',
          searchVolume: 2217,
          organicRank: 19,

          keywordSales: 9
        },
        {
          date: '2024-05-28',
          searchVolume: 2217,
          organicRank: 14,

          keywordSales: 9
        },
        {
          date: '2024-05-27',
          searchVolume: 2217,
          organicRank: 17,

          keywordSales: 9
        },
        {
          date: '2024-05-26',
          searchVolume: 2217,
          organicRank: 18,

          keywordSales: 9
        },
        {
          date: '2024-05-25',
          searchVolume: 2217,
          organicRank: 18,
          sponsoredRank: 30,
          keywordSales: 9
        },
        {
          date: '2024-05-24',
          searchVolume: 2140,
          organicRank: 18,

          keywordSales: 9
        },
        {
          date: '2024-05-23',
          searchVolume: 2140,
          organicRank: 17,

          keywordSales: 9
        },
        {
          date: '2024-05-22',
          searchVolume: 2140,
          organicRank: 15,
          sponsoredRank: 61,
          keywordSales: 9
        },
        {
          date: '2024-05-21',
          searchVolume: 2140,
          organicRank: 11,

          keywordSales: 9
        },
        {
          date: '2024-05-20',
          searchVolume: 2140,
          organicRank: 8,

          keywordSales: 9
        },
        {
          date: '2024-05-19',
          searchVolume: 2140,
          organicRank: 17,

          keywordSales: 9
        },
        {
          date: '2024-05-18',
          searchVolume: 2140,
          organicRank: 16,

          keywordSales: 9
        },
        {
          date: '2024-05-17',
          searchVolume: 2348,
          organicRank: 10,
          sponsoredRank: 39,
          keywordSales: 9
        },
        {
          date: '2024-05-16',
          searchVolume: 2348,
          organicRank: 18,

          keywordSales: 9
        },
        {
          date: '2024-05-15',
          searchVolume: 2348,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-05-14',
          searchVolume: 2348,
          organicRank: 58,

          keywordSales: 9
        },
        {
          date: '2024-05-13',
          searchVolume: 2348,
          organicRank: 45,

          keywordSales: 9
        },
        {
          date: '2024-05-12',
          searchVolume: 2348,
          organicRank: 214,

          keywordSales: 9
        },
        {
          date: '2024-05-11',
          searchVolume: 2348,
          organicRank: 159,
          sponsoredRank: 84,
          keywordSales: 9
        },
        {
          date: '2024-05-10',
          searchVolume: 2431,
          organicRank: 47,

          keywordSales: 9
        },
        {
          date: '2024-05-09',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-05-08',
          searchVolume: 2431,
          organicRank: 204,

          keywordSales: 9
        },
        {
          date: '2024-05-07',
          searchVolume: 2431,
          organicRank: 120,

          keywordSales: 9
        },
        {
          date: '2024-05-06',
          searchVolume: 2431,
          organicRank: 49,

          keywordSales: 9
        },
        {
          date: '2024-05-05',
          searchVolume: 2431,
          organicRank: 62,

          keywordSales: 9
        },
        {
          date: '2024-05-04',
          searchVolume: 2431,
          organicRank: 40,

          keywordSales: 9
        },
        {
          date: '2024-05-02',
          searchVolume: 2284,
          organicRank: 53,

          keywordSales: 9
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mini gaming keyboard',
      keyword: 'mini gaming keyboard',
      searchVolume: 1904,
      organicStartRank: 10,
      organicHighestRank: 5,
      sponsoredStartRank: 43,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1915,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-06-19',
          searchVolume: 1915,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 35
        },
        {
          date: '2024-06-18',
          searchVolume: 1915,
          organicRank: 7,
          sponsoredRank: 17,
          keywordSales: 35
        },
        {
          date: '2024-06-17',
          searchVolume: 1915,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-06-16',
          searchVolume: 1915,
          organicRank: 7,

          keywordSales: 35
        },
        {
          date: '2024-06-15',
          searchVolume: 1915,
          organicRank: 9,

          keywordSales: 35
        },
        {
          date: '2024-06-14',
          searchVolume: 1924,
          organicRank: 8,
          sponsoredRank: 12,
          keywordSales: 35
        },
        {
          date: '2024-06-13',
          searchVolume: 1924,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-06-12',
          searchVolume: 1924,
          organicRank: 7,

          keywordSales: 35
        },
        {
          date: '2024-06-11',
          searchVolume: 1924,
          organicRank: 7,

          keywordSales: 35
        },
        {
          date: '2024-06-10',
          searchVolume: 1924,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-06-09',
          searchVolume: 1924,
          organicRank: 8,
          sponsoredRank: 78,
          keywordSales: 35
        },
        {
          date: '2024-06-08',
          searchVolume: 1924,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-06-07',
          searchVolume: 2147,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-06-06',
          searchVolume: 2147,
          organicRank: 7,

          keywordSales: 35
        },
        {
          date: '2024-06-05',
          searchVolume: 2147,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 35
        },
        {
          date: '2024-06-04',
          searchVolume: 2147,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-06-03',
          searchVolume: 2147,
          organicRank: 5,

          keywordSales: 35
        },
        {
          date: '2024-06-02',
          searchVolume: 2147,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-06-01',
          searchVolume: 2147,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-05-31',
          searchVolume: 1899,
          organicRank: 8,
          sponsoredRank: 20,
          keywordSales: 35
        },
        {
          date: '2024-05-30',
          searchVolume: 1899,
          organicRank: 10,
          sponsoredRank: 24,
          keywordSales: 35
        },
        {
          date: '2024-05-29',
          searchVolume: 1899,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-05-28',
          searchVolume: 1899,
          organicRank: 9,

          keywordSales: 35
        },
        {
          date: '2024-05-27',
          searchVolume: 1899,
          organicRank: 9,

          keywordSales: 35
        },
        {
          date: '2024-05-26',
          searchVolume: 1899,
          organicRank: 6,

          keywordSales: 35
        },
        {
          date: '2024-05-25',
          searchVolume: 1899,
          organicRank: 7,

          keywordSales: 35
        },
        {
          date: '2024-05-24',
          searchVolume: 1904,
          organicRank: 9,

          keywordSales: 35
        },
        {
          date: '2024-05-23',
          searchVolume: 1904,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-05-22',
          searchVolume: 1904,
          organicRank: 8,
          sponsoredRank: 47,
          keywordSales: 35
        },
        {
          date: '2024-05-21',
          searchVolume: 1904,
          organicRank: 7,

          keywordSales: 35
        },
        {
          date: '2024-05-20',
          searchVolume: 1904,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-05-19',
          searchVolume: 1904,
          organicRank: 9,

          keywordSales: 35
        },
        {
          date: '2024-05-18',
          searchVolume: 1904,
          organicRank: 9,

          keywordSales: 35
        },
        {
          date: '2024-05-17',
          searchVolume: 1894,
          organicRank: 7,
          sponsoredRank: 13,
          keywordSales: 35
        },
        {
          date: '2024-05-16',
          searchVolume: 1894,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-05-15',
          searchVolume: 1894,
          organicRank: 13,

          keywordSales: 35
        },
        {
          date: '2024-05-14',
          searchVolume: 1894,
          organicRank: 12,

          keywordSales: 35
        },
        {
          date: '2024-05-13',
          searchVolume: 1894,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-05-12',
          searchVolume: 1894,
          organicRank: 6,

          keywordSales: 35
        },
        {
          date: '2024-05-11',
          searchVolume: 1894,
          organicRank: 10,

          keywordSales: 35
        },
        {
          date: '2024-05-10',
          searchVolume: 2186,
          organicRank: 12,
          sponsoredRank: 65,
          keywordSales: 35
        },
        {
          date: '2024-05-09',
          searchVolume: 2186,
          organicRank: 7,
          sponsoredRank: 85,
          keywordSales: 35
        },
        {
          date: '2024-05-08',
          searchVolume: 2186,
          organicRank: 10,
          sponsoredRank: 42,
          keywordSales: 35
        },
        {
          date: '2024-05-07',
          searchVolume: 2186,
          organicRank: 18,

          keywordSales: 35
        },
        {
          date: '2024-05-06',
          searchVolume: 2186,
          organicRank: 8,

          keywordSales: 35
        },
        {
          date: '2024-05-05',
          searchVolume: 2186,
          organicRank: 10,

          keywordSales: 35
        },
        {
          date: '2024-05-04',
          searchVolume: 2186,
          organicRank: 10,
          sponsoredRank: 43,
          keywordSales: 35
        },
        {
          date: '2024-05-03',
          searchVolume: 2280,
          organicRank: 9,
          sponsoredRank: 56,
          keywordSales: 35
        },
        {
          date: '2024-05-02',
          searchVolume: 2280,
          organicRank: 10,

          keywordSales: 35
        },
        {
          date: '2024-05-01',
          searchVolume: 2280,
          organicRank: 12,

          keywordSales: 35
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mini keyboard',
      keyword: 'mini keyboard',
      searchVolume: 8893,
      organicStartRank: 98,
      organicHighestRank: 76,
      sponsoredStartRank: 71,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 10774,
          organicRank: 109,
          sponsoredRank: 50,
          keywordSales: 154
        },
        {
          date: '2024-06-19',
          searchVolume: 10774,
          organicRank: 107,
          sponsoredRank: 64,
          keywordSales: 154
        },
        {
          date: '2024-06-18',
          searchVolume: 10774,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 154
        },
        {
          date: '2024-06-17',
          searchVolume: 10774,
          organicRank: 123,

          keywordSales: 154
        },
        {
          date: '2024-06-16',
          searchVolume: 10774,
          organicRank: 117,

          keywordSales: 154
        },
        {
          date: '2024-06-15',
          searchVolume: 10774,
          organicRank: 113,

          keywordSales: 154
        },
        {
          date: '2024-06-14',
          searchVolume: 9026,
          organicRank: 122,

          keywordSales: 154
        },
        {
          date: '2024-06-13',
          searchVolume: 9026,
          organicRank: 115,

          keywordSales: 154
        },
        {
          date: '2024-06-12',
          searchVolume: 9026,
          organicRank: 122,

          keywordSales: 154
        },
        {
          date: '2024-06-11',
          searchVolume: 9026,
          organicRank: 105,

          keywordSales: 154
        },
        {
          date: '2024-06-10',
          searchVolume: 9026,
          organicRank: 82,

          keywordSales: 154
        },
        {
          date: '2024-06-09',
          searchVolume: 9026,
          organicRank: 94,
          sponsoredRank: 68,
          keywordSales: 154
        },
        {
          date: '2024-06-08',
          searchVolume: 9026,
          organicRank: 97,

          keywordSales: 154
        },
        {
          date: '2024-06-07',
          searchVolume: 8608,
          organicRank: 93,

          keywordSales: 154
        },
        {
          date: '2024-06-06',
          searchVolume: 8608,
          organicRank: 88,

          keywordSales: 154
        },
        {
          date: '2024-06-05',
          searchVolume: 8608,
          organicRank: 84,
          sponsoredRank: 7,
          keywordSales: 154
        },
        {
          date: '2024-06-04',
          searchVolume: 8608,
          organicRank: 79,

          keywordSales: 154
        },
        {
          date: '2024-06-03',
          searchVolume: 8608,
          organicRank: 83,

          keywordSales: 154
        },
        {
          date: '2024-06-02',
          searchVolume: 8608,
          organicRank: 146,

          keywordSales: 154
        },
        {
          date: '2024-06-01',
          searchVolume: 8608,
          organicRank: 80,

          keywordSales: 154
        },
        {
          date: '2024-05-31',
          searchVolume: 8896,
          organicRank: 76,

          keywordSales: 154
        },
        {
          date: '2024-05-30',
          searchVolume: 8896,
          organicRank: 78,

          keywordSales: 154
        },
        {
          date: '2024-05-29',
          searchVolume: 8896,
          organicRank: 100,

          keywordSales: 154
        },
        {
          date: '2024-05-28',
          searchVolume: 8896,
          organicRank: 306,

          keywordSales: 154
        },
        {
          date: '2024-05-27',
          searchVolume: 8896,
          organicRank: 112,

          keywordSales: 154
        },
        {
          date: '2024-05-26',
          searchVolume: 8896,
          organicRank: 103,

          keywordSales: 154
        },
        {
          date: '2024-05-25',
          searchVolume: 8896,
          organicRank: 106,

          keywordSales: 154
        },
        {
          date: '2024-05-24',
          searchVolume: 8893,
          organicRank: 85,

          keywordSales: 154
        },
        {
          date: '2024-05-23',
          searchVolume: 8893,
          organicRank: 86,

          keywordSales: 154
        },
        {
          date: '2024-05-22',
          searchVolume: 8893,
          organicRank: 101,

          keywordSales: 154
        },
        {
          date: '2024-05-21',
          searchVolume: 8893,
          organicRank: 98,

          keywordSales: 154
        },
        {
          date: '2024-05-20',
          searchVolume: 8893,
          organicRank: 202,

          keywordSales: 154
        },
        {
          date: '2024-05-19',
          searchVolume: 8893,
          organicRank: 90,

          keywordSales: 154
        },
        {
          date: '2024-05-18',
          searchVolume: 8893,
          organicRank: 103,

          keywordSales: 154
        },
        {
          date: '2024-05-17',
          searchVolume: 9125,
          organicRank: 87,

          keywordSales: 154
        },
        {
          date: '2024-05-16',
          searchVolume: 9125,
          organicRank: 93,

          keywordSales: 154
        },
        {
          date: '2024-05-15',
          searchVolume: 9125,
          organicRank: 98,

          keywordSales: 154
        },
        {
          date: '2024-05-14',
          searchVolume: 9125,
          organicRank: 89,

          keywordSales: 154
        },
        {
          date: '2024-05-13',
          searchVolume: 9125,
          organicRank: 90,
          sponsoredRank: 67,
          keywordSales: 154
        },
        {
          date: '2024-05-12',
          searchVolume: 9125,
          organicRank: 97,

          keywordSales: 154
        },
        {
          date: '2024-05-11',
          searchVolume: 9125,
          organicRank: 215,
          sponsoredRank: 71,
          keywordSales: 154
        },
        {
          date: '2024-05-10',
          searchVolume: 9282,
          organicRank: 91,

          keywordSales: 154
        },
        {
          date: '2024-05-09',
          searchVolume: 9282,
          organicRank: 88,

          keywordSales: 154
        },
        {
          date: '2024-05-08',
          searchVolume: 9282,
          organicRank: 97,

          keywordSales: 154
        },
        {
          date: '2024-05-07',
          searchVolume: 9282,
          organicRank: 114,

          keywordSales: 154
        },
        {
          date: '2024-05-06',
          searchVolume: 9282,
          organicRank: 119,

          keywordSales: 154
        },
        {
          date: '2024-05-05',
          searchVolume: 9282,
          organicRank: 117,

          keywordSales: 154
        },
        {
          date: '2024-05-04',
          searchVolume: 9282,
          organicRank: 98,

          keywordSales: 154
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mouse and keyboard combo gaming',
      keyword: 'mouse and keyboard combo gaming',
      searchVolume: 1410,
      organicStartRank: 38,
      organicHighestRank: 38,
      sponsoredStartRank: 83,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1657,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-19',
          searchVolume: 1657,
          organicRank: 306,
          sponsoredRank: 61,
          keywordSales: 6
        },
        {
          date: '2024-06-18',
          searchVolume: 1657,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 6
        },
        {
          date: '2024-06-17',
          searchVolume: 1657,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-16',
          searchVolume: 1657,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-15',
          searchVolume: 1657,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-14',
          searchVolume: 1349,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-13',
          searchVolume: 1349,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-12',
          searchVolume: 1349,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-11',
          searchVolume: 1349,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-10',
          searchVolume: 1349,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-09',
          searchVolume: 1349,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 6
        },
        {
          date: '2024-06-08',
          searchVolume: 1349,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-07',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-06',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-05',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-04',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-03',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-02',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-06-01',
          searchVolume: 1365,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-31',
          searchVolume: 1410,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 6
        },
        {
          date: '2024-05-30',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-29',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-28',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-27',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-26',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-25',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-24',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-23',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-22',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-21',
          searchVolume: 1410,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 6
        },
        {
          date: '2024-05-20',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-19',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-18',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-17',
          searchVolume: 1415,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 6
        },
        {
          date: '2024-05-16',
          searchVolume: 1415,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-15',
          searchVolume: 1415,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-14',
          searchVolume: 1415,
          organicRank: 306,

          keywordSales: 6
        },
        {
          date: '2024-05-13',
          searchVolume: 1415,
          organicRank: 60,
          sponsoredRank: 74,
          keywordSales: 6
        },
        {
          date: '2024-05-12',
          searchVolume: 1415,
          organicRank: 61,

          keywordSales: 6
        },
        {
          date: '2024-05-11',
          searchVolume: 1415,
          organicRank: 71,
          sponsoredRank: 47,
          keywordSales: 6
        },
        {
          date: '2024-05-10',
          searchVolume: 1445,
          organicRank: 55,

          keywordSales: 6
        },
        {
          date: '2024-05-09',
          searchVolume: 1445,
          organicRank: 115,

          keywordSales: 6
        },
        {
          date: '2024-05-08',
          searchVolume: 1445,
          organicRank: 64,

          keywordSales: 6
        },
        {
          date: '2024-05-07',
          searchVolume: 1445,
          organicRank: 306,
          sponsoredRank: 41,
          keywordSales: 6
        },
        {
          date: '2024-05-06',
          searchVolume: 1445,
          organicRank: 181,
          sponsoredRank: 62,
          keywordSales: 6
        },
        {
          date: '2024-05-04',
          searchVolume: 1445,
          organicRank: 38,
          sponsoredRank: 83,
          keywordSales: 6
        },
        {
          date: '2024-05-02',
          searchVolume: 1825,
          organicRank: 30,

          keywordSales: 6
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_mx mechanical keyboard',
      keyword: 'mx mechanical keyboard',
      searchVolume: 1818,
      organicStartRank: 43,
      organicHighestRank: 43,
      sponsoredStartRank: 18,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1919,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 49
        },
        {
          date: '2024-06-19',
          searchVolume: 1919,
          organicRank: 77,
          sponsoredRank: 73,
          keywordSales: 49
        },
        {
          date: '2024-06-18',
          searchVolume: 1919,
          organicRank: 78,

          keywordSales: 49
        },
        {
          date: '2024-06-17',
          searchVolume: 1919,
          organicRank: 74,
          sponsoredRank: 51,
          keywordSales: 49
        },
        {
          date: '2024-06-16',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-15',
          searchVolume: 1919,
          organicRank: 102,
          sponsoredRank: 5,
          keywordSales: 49
        },
        {
          date: '2024-06-14',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-13',
          searchVolume: 1913,
          organicRank: 306,
          sponsoredRank: 35,
          keywordSales: 49
        },
        {
          date: '2024-06-12',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-11',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-10',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-09',
          searchVolume: 1913,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 49
        },
        {
          date: '2024-06-08',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-07',
          searchVolume: 1767,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-06',
          searchVolume: 1767,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-05',
          searchVolume: 1767,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 49
        },
        {
          date: '2024-06-04',
          searchVolume: 1767,
          organicRank: 95,
          sponsoredRank: 5,
          keywordSales: 49
        },
        {
          date: '2024-06-03',
          searchVolume: 1767,
          organicRank: 88,

          keywordSales: 49
        },
        {
          date: '2024-06-02',
          searchVolume: 1767,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-01',
          searchVolume: 1767,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-05-31',
          searchVolume: 1889,
          organicRank: 118,
          sponsoredRank: 18,
          keywordSales: 49
        },
        {
          date: '2024-05-30',
          searchVolume: 1889,
          organicRank: 125,

          keywordSales: 49
        },
        {
          date: '2024-05-29',
          searchVolume: 1889,
          organicRank: 101,

          keywordSales: 49
        },
        {
          date: '2024-05-28',
          searchVolume: 1889,
          organicRank: 100,

          keywordSales: 49
        },
        {
          date: '2024-05-27',
          searchVolume: 1889,
          organicRank: 80,

          keywordSales: 49
        },
        {
          date: '2024-05-26',
          searchVolume: 1889,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-05-25',
          searchVolume: 1889,
          organicRank: 90,

          keywordSales: 49
        },
        {
          date: '2024-05-24',
          searchVolume: 1818,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-05-23',
          searchVolume: 1818,
          organicRank: 76,

          keywordSales: 49
        },
        {
          date: '2024-05-22',
          searchVolume: 1818,
          organicRank: 102,

          keywordSales: 49
        },
        {
          date: '2024-05-21',
          searchVolume: 1818,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 49
        },
        {
          date: '2024-05-20',
          searchVolume: 1818,
          organicRank: 87,

          keywordSales: 49
        },
        {
          date: '2024-05-19',
          searchVolume: 1818,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-05-18',
          searchVolume: 1818,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-05-17',
          searchVolume: 1408,
          organicRank: 123,
          sponsoredRank: 48,
          keywordSales: 49
        },
        {
          date: '2024-05-16',
          searchVolume: 1408,
          organicRank: 132,

          keywordSales: 49
        },
        {
          date: '2024-05-15',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-05-14',
          searchVolume: 1408,
          organicRank: 101,

          keywordSales: 49
        },
        {
          date: '2024-05-13',
          searchVolume: 1408,
          organicRank: 133,

          keywordSales: 49
        },
        {
          date: '2024-05-12',
          searchVolume: 1408,
          organicRank: 116,

          keywordSales: 49
        },
        {
          date: '2024-05-11',
          searchVolume: 1408,
          organicRank: 67,

          keywordSales: 49
        },
        {
          date: '2024-05-10',
          searchVolume: 1609,
          organicRank: 255,
          sponsoredRank: 40,
          keywordSales: 49
        },
        {
          date: '2024-05-09',
          searchVolume: 1609,
          organicRank: 46,
          sponsoredRank: 74,
          keywordSales: 49
        },
        {
          date: '2024-05-08',
          searchVolume: 1609,
          organicRank: 58,

          keywordSales: 49
        },
        {
          date: '2024-05-07',
          searchVolume: 1609,
          organicRank: 60,

          keywordSales: 49
        },
        {
          date: '2024-05-06',
          searchVolume: 1609,
          organicRank: 64,

          keywordSales: 49
        },
        {
          date: '2024-05-05',
          searchVolume: 1609,
          organicRank: 62,

          keywordSales: 49
        },
        {
          date: '2024-05-04',
          searchVolume: 1609,
          organicRank: 43,
          sponsoredRank: 18,
          keywordSales: 49
        },
        {
          date: '2024-05-03',
          searchVolume: 2316,
          organicRank: 93,
          sponsoredRank: 19,
          keywordSales: 49
        },
        {
          date: '2024-05-02',
          searchVolume: 2316,
          organicRank: 77,

          keywordSales: 49
        },
        {
          date: '2024-05-01',
          searchVolume: 2316,
          organicRank: 76,
          sponsoredRank: 6,
          keywordSales: 49
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_pbt keycaps',
      keyword: 'pbt keycaps',
      searchVolume: 4479,
      organicStartRank: 306,
      organicHighestRank: 122,
      sponsoredStartRank: 61,
      sponsoredHighestRank: 25,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 5148,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-19',
          searchVolume: 5148,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-18',
          searchVolume: 5148,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 58
        },
        {
          date: '2024-06-17',
          searchVolume: 5148,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-16',
          searchVolume: 5148,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-15',
          searchVolume: 5148,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-14',
          searchVolume: 4554,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-13',
          searchVolume: 4554,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-12',
          searchVolume: 4554,
          organicRank: 181,

          keywordSales: 58
        },
        {
          date: '2024-06-11',
          searchVolume: 4554,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-10',
          searchVolume: 4554,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-09',
          searchVolume: 4554,
          organicRank: 306,
          sponsoredRank: 62,
          keywordSales: 58
        },
        {
          date: '2024-06-08',
          searchVolume: 4554,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-07',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-06',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-05',
          searchVolume: 4346,
          organicRank: 181,

          keywordSales: 58
        },
        {
          date: '2024-06-04',
          searchVolume: 4346,
          organicRank: 214,

          keywordSales: 58
        },
        {
          date: '2024-06-03',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-02',
          searchVolume: 4346,
          organicRank: 251,

          keywordSales: 58
        },
        {
          date: '2024-06-01',
          searchVolume: 4346,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-05-31',
          searchVolume: 4844,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-05-30',
          searchVolume: 4844,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-05-29',
          searchVolume: 4844,
          organicRank: 153,

          keywordSales: 58
        },
        {
          date: '2024-05-28',
          searchVolume: 4844,
          organicRank: 155,

          keywordSales: 58
        },
        {
          date: '2024-05-27',
          searchVolume: 4844,
          organicRank: 259,

          keywordSales: 58
        },
        {
          date: '2024-05-26',
          searchVolume: 4844,
          organicRank: 306,
          sponsoredRank: 85,
          keywordSales: 58
        },
        {
          date: '2024-05-25',
          searchVolume: 4844,
          organicRank: 151,

          keywordSales: 58
        },
        {
          date: '2024-05-24',
          searchVolume: 4479,
          organicRank: 306,
          sponsoredRank: 62,
          keywordSales: 58
        },
        {
          date: '2024-05-23',
          searchVolume: 4479,
          organicRank: 306,
          sponsoredRank: 92,
          keywordSales: 58
        },
        {
          date: '2024-05-22',
          searchVolume: 4479,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-05-21',
          searchVolume: 4479,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-05-20',
          searchVolume: 4479,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 58
        },
        {
          date: '2024-05-19',
          searchVolume: 4479,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-05-18',
          searchVolume: 4479,
          organicRank: 122,

          keywordSales: 58
        },
        {
          date: '2024-05-17',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 58
        },
        {
          date: '2024-05-16',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 37,
          keywordSales: 58
        },
        {
          date: '2024-05-15',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 85,
          keywordSales: 58
        },
        {
          date: '2024-05-14',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 58
        },
        {
          date: '2024-05-13',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 58
        },
        {
          date: '2024-05-12',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 58
        },
        {
          date: '2024-05-11',
          searchVolume: 4251,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 58
        },
        {
          date: '2024-05-10',
          searchVolume: 4916,
          organicRank: 306,
          sponsoredRank: 87,
          keywordSales: 58
        },
        {
          date: '2024-05-09',
          searchVolume: 4916,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 58
        },
        {
          date: '2024-05-08',
          searchVolume: 4916,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 58
        },
        {
          date: '2024-05-07',
          searchVolume: 4916,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 58
        },
        {
          date: '2024-05-06',
          searchVolume: 4916,
          organicRank: 306,
          sponsoredRank: 61,
          keywordSales: 58
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_pc gaming accessories',
      keyword: 'pc gaming accessories',
      searchVolume: 5867,
      organicStartRank: 234,
      organicHighestRank: 106,
      sponsoredStartRank: 54,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-16',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 5927,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-19',
          searchVolume: 5927,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-18',
          searchVolume: 5927,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-17',
          searchVolume: 5927,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-16',
          searchVolume: 5927,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-15',
          searchVolume: 5927,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-14',
          searchVolume: 5236,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-13',
          searchVolume: 5236,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 20
        },
        {
          date: '2024-06-12',
          searchVolume: 5236,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-11',
          searchVolume: 5236,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-10',
          searchVolume: 5236,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-09',
          searchVolume: 5236,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-08',
          searchVolume: 5236,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-07',
          searchVolume: 5428,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-06',
          searchVolume: 5428,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-05',
          searchVolume: 5428,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-04',
          searchVolume: 5428,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-03',
          searchVolume: 5428,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-02',
          searchVolume: 5428,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-01',
          searchVolume: 5428,
          organicRank: 249,

          keywordSales: 20
        },
        {
          date: '2024-05-31',
          searchVolume: 5718,
          organicRank: 263,
          sponsoredRank: 32,
          keywordSales: 20
        },
        {
          date: '2024-05-30',
          searchVolume: 5718,
          organicRank: 119,

          keywordSales: 20
        },
        {
          date: '2024-05-29',
          searchVolume: 5718,
          organicRank: 222,

          keywordSales: 20
        },
        {
          date: '2024-05-28',
          searchVolume: 5718,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-27',
          searchVolume: 5718,
          organicRank: 225,

          keywordSales: 20
        },
        {
          date: '2024-05-26',
          searchVolume: 5718,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-25',
          searchVolume: 5718,
          organicRank: 117,

          keywordSales: 20
        },
        {
          date: '2024-05-24',
          searchVolume: 5867,
          organicRank: 122,

          keywordSales: 20
        },
        {
          date: '2024-05-23',
          searchVolume: 5867,
          organicRank: 107,

          keywordSales: 20
        },
        {
          date: '2024-05-22',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-21',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-20',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-19',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-18',
          searchVolume: 5867,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-17',
          searchVolume: 4835,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 20
        },
        {
          date: '2024-05-16',
          searchVolume: 4835,
          organicRank: 106,
          sponsoredRank: 12,
          keywordSales: 20
        },
        {
          date: '2024-05-15',
          searchVolume: 4835,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-14',
          searchVolume: 4835,
          organicRank: 230,

          keywordSales: 20
        },
        {
          date: '2024-05-13',
          searchVolume: 4835,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-12',
          searchVolume: 4835,
          organicRank: 251,

          keywordSales: 20
        },
        {
          date: '2024-05-11',
          searchVolume: 4835,
          organicRank: 248,

          keywordSales: 20
        },
        {
          date: '2024-05-10',
          searchVolume: 4911,
          organicRank: 165,

          keywordSales: 20
        },
        {
          date: '2024-05-09',
          searchVolume: 4911,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-08',
          searchVolume: 4911,
          organicRank: 270,

          keywordSales: 20
        },
        {
          date: '2024-05-07',
          searchVolume: 4911,
          organicRank: 159,

          keywordSales: 20
        },
        {
          date: '2024-05-06',
          searchVolume: 4911,
          organicRank: 128,

          keywordSales: 20
        },
        {
          date: '2024-05-05',
          searchVolume: 4911,
          organicRank: 159,

          keywordSales: 20
        },
        {
          date: '2024-05-04',
          searchVolume: 4911,
          organicRank: 234,

          keywordSales: 20
        },
        {
          date: '2024-05-03',
          searchVolume: 4346,
          organicRank: 164,
          sponsoredRank: 54,
          keywordSales: 20
        },
        {
          date: '2024-05-02',
          searchVolume: 4346,
          organicRank: 240,

          keywordSales: 20
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_pc gaming keyboards',
      keyword: 'pc gaming keyboards',
      searchVolume: 8482,
      organicStartRank: 156,
      organicHighestRank: 41,
      sponsoredStartRank: 71,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 7682,
          organicRank: 83,

          keywordSales: 33
        },
        {
          date: '2024-06-19',
          searchVolume: 7682,
          organicRank: 163,

          keywordSales: 33
        },
        {
          date: '2024-06-18',
          searchVolume: 7682,
          organicRank: 164,

          keywordSales: 33
        },
        {
          date: '2024-06-17',
          searchVolume: 7682,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 33
        },
        {
          date: '2024-06-16',
          searchVolume: 7682,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-15',
          searchVolume: 7682,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-14',
          searchVolume: 8480,
          organicRank: 306,
          sponsoredRank: 96,
          keywordSales: 33
        },
        {
          date: '2024-06-13',
          searchVolume: 8480,
          organicRank: 76,

          keywordSales: 33
        },
        {
          date: '2024-06-12',
          searchVolume: 8480,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-11',
          searchVolume: 8480,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-10',
          searchVolume: 8480,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-09',
          searchVolume: 8480,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 33
        },
        {
          date: '2024-06-08',
          searchVolume: 8480,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 33
        },
        {
          date: '2024-06-07',
          searchVolume: 6952,
          organicRank: 78,

          keywordSales: 33
        },
        {
          date: '2024-06-06',
          searchVolume: 6952,
          organicRank: 203,

          keywordSales: 33
        },
        {
          date: '2024-06-05',
          searchVolume: 6952,
          organicRank: 98,
          sponsoredRank: 64,
          keywordSales: 33
        },
        {
          date: '2024-06-04',
          searchVolume: 6952,
          organicRank: 77,

          keywordSales: 33
        },
        {
          date: '2024-06-03',
          searchVolume: 6952,
          organicRank: 75,

          keywordSales: 33
        },
        {
          date: '2024-06-02',
          searchVolume: 6952,
          organicRank: 87,

          keywordSales: 33
        },
        {
          date: '2024-06-01',
          searchVolume: 6952,
          organicRank: 83,
          sponsoredRank: 54,
          keywordSales: 33
        },
        {
          date: '2024-05-31',
          searchVolume: 7554,
          organicRank: 73,

          keywordSales: 33
        },
        {
          date: '2024-05-30',
          searchVolume: 7554,
          organicRank: 60,

          keywordSales: 33
        },
        {
          date: '2024-05-29',
          searchVolume: 7554,
          organicRank: 70,

          keywordSales: 33
        },
        {
          date: '2024-05-28',
          searchVolume: 7554,
          organicRank: 65,

          keywordSales: 33
        },
        {
          date: '2024-05-27',
          searchVolume: 7554,
          organicRank: 70,

          keywordSales: 33
        },
        {
          date: '2024-05-26',
          searchVolume: 7554,
          organicRank: 70,

          keywordSales: 33
        },
        {
          date: '2024-05-25',
          searchVolume: 7554,
          organicRank: 66,
          sponsoredRank: 30,
          keywordSales: 33
        },
        {
          date: '2024-05-24',
          searchVolume: 8350,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-23',
          searchVolume: 8350,
          organicRank: 41,
          sponsoredRank: 6,
          keywordSales: 33
        },
        {
          date: '2024-05-22',
          searchVolume: 8350,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-21',
          searchVolume: 8350,
          organicRank: 48,

          keywordSales: 33
        },
        {
          date: '2024-05-20',
          searchVolume: 8350,
          organicRank: 141,
          sponsoredRank: 52,
          keywordSales: 33
        },
        {
          date: '2024-05-19',
          searchVolume: 8350,
          organicRank: 54,

          keywordSales: 33
        },
        {
          date: '2024-05-18',
          searchVolume: 8350,
          organicRank: 57,
          sponsoredRank: 89,
          keywordSales: 33
        },
        {
          date: '2024-05-17',
          searchVolume: 8339,
          organicRank: 48,
          sponsoredRank: 20,
          keywordSales: 33
        },
        {
          date: '2024-05-16',
          searchVolume: 8339,
          organicRank: 64,

          keywordSales: 33
        },
        {
          date: '2024-05-15',
          searchVolume: 8339,
          organicRank: 65,
          sponsoredRank: 27,
          keywordSales: 33
        },
        {
          date: '2024-05-14',
          searchVolume: 8339,
          organicRank: 60,
          sponsoredRank: 67,
          keywordSales: 33
        },
        {
          date: '2024-05-13',
          searchVolume: 8339,
          organicRank: 66,
          sponsoredRank: 12,
          keywordSales: 33
        },
        {
          date: '2024-05-12',
          searchVolume: 8339,
          organicRank: 62,
          sponsoredRank: 12,
          keywordSales: 33
        },
        {
          date: '2024-05-11',
          searchVolume: 8339,
          organicRank: 59,
          sponsoredRank: 24,
          keywordSales: 33
        },
        {
          date: '2024-05-10',
          searchVolume: 8482,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 33
        },
        {
          date: '2024-05-09',
          searchVolume: 8482,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 33
        },
        {
          date: '2024-05-08',
          searchVolume: 8482,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 33
        },
        {
          date: '2024-05-07',
          searchVolume: 8482,
          organicRank: 65,
          sponsoredRank: 71,
          keywordSales: 33
        },
        {
          date: '2024-05-06',
          searchVolume: 8482,
          organicRank: 69,

          keywordSales: 33
        },
        {
          date: '2024-05-05',
          searchVolume: 8482,
          organicRank: 75,

          keywordSales: 33
        },
        {
          date: '2024-05-03',
          searchVolume: 8606,
          organicRank: 156,

          keywordSales: 33
        },
        {
          date: '2024-05-02',
          searchVolume: 8606,
          organicRank: 35,

          keywordSales: 33
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_pc keyboard',
      keyword: 'pc keyboard',
      searchVolume: 3570,
      organicStartRank: 120,
      organicHighestRank: 75,
      sponsoredStartRank: 87,
      sponsoredHighestRank: 19,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 3618,
          organicRank: 207,

          keywordSales: 83
        },
        {
          date: '2024-06-20',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-19',
          searchVolume: 3618,
          organicRank: 306,
          sponsoredRank: 54,
          keywordSales: 83
        },
        {
          date: '2024-06-18',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-17',
          searchVolume: 3618,
          organicRank: 107,

          keywordSales: 83
        },
        {
          date: '2024-06-16',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-15',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-14',
          searchVolume: 3447,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-13',
          searchVolume: 3447,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-12',
          searchVolume: 3447,
          organicRank: 234,

          keywordSales: 83
        },
        {
          date: '2024-06-11',
          searchVolume: 3447,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-10',
          searchVolume: 3447,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-09',
          searchVolume: 3447,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-08',
          searchVolume: 3447,
          organicRank: 265,

          keywordSales: 83
        },
        {
          date: '2024-06-07',
          searchVolume: 3455,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-06',
          searchVolume: 3455,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-06-05',
          searchVolume: 3455,
          organicRank: 267,

          keywordSales: 83
        },
        {
          date: '2024-06-04',
          searchVolume: 3455,
          organicRank: 92,

          keywordSales: 83
        },
        {
          date: '2024-06-03',
          searchVolume: 3455,
          organicRank: 109,

          keywordSales: 83
        },
        {
          date: '2024-06-02',
          searchVolume: 3455,
          organicRank: 105,

          keywordSales: 83
        },
        {
          date: '2024-06-01',
          searchVolume: 3455,
          organicRank: 111,

          keywordSales: 83
        },
        {
          date: '2024-05-31',
          searchVolume: 3564,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-05-30',
          searchVolume: 3564,
          organicRank: 117,

          keywordSales: 83
        },
        {
          date: '2024-05-29',
          searchVolume: 3564,
          organicRank: 109,
          sponsoredRank: 20,
          keywordSales: 83
        },
        {
          date: '2024-05-28',
          searchVolume: 3564,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-05-27',
          searchVolume: 3564,
          organicRank: 101,

          keywordSales: 83
        },
        {
          date: '2024-05-26',
          searchVolume: 3564,
          organicRank: 93,
          sponsoredRank: 19,
          keywordSales: 83
        },
        {
          date: '2024-05-25',
          searchVolume: 3564,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 83
        },
        {
          date: '2024-05-24',
          searchVolume: 3570,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-05-23',
          searchVolume: 3570,
          organicRank: 108,
          sponsoredRank: 68,
          keywordSales: 83
        },
        {
          date: '2024-05-22',
          searchVolume: 3570,
          organicRank: 271,
          sponsoredRank: 45,
          keywordSales: 83
        },
        {
          date: '2024-05-21',
          searchVolume: 3570,
          organicRank: 104,
          sponsoredRank: 63,
          keywordSales: 83
        },
        {
          date: '2024-05-20',
          searchVolume: 3570,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-05-19',
          searchVolume: 3570,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-05-18',
          searchVolume: 3570,
          organicRank: 306,
          sponsoredRank: 53,
          keywordSales: 83
        },
        {
          date: '2024-05-17',
          searchVolume: 3080,
          organicRank: 306,
          sponsoredRank: 87,
          keywordSales: 83
        },
        {
          date: '2024-05-16',
          searchVolume: 3080,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-05-15',
          searchVolume: 3080,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-05-14',
          searchVolume: 3080,
          organicRank: 242,

          keywordSales: 83
        },
        {
          date: '2024-05-13',
          searchVolume: 3080,
          organicRank: 140,

          keywordSales: 83
        },
        {
          date: '2024-05-12',
          searchVolume: 3080,
          organicRank: 306,

          keywordSales: 83
        },
        {
          date: '2024-05-11',
          searchVolume: 3080,
          organicRank: 75,

          keywordSales: 83
        },
        {
          date: '2024-05-10',
          searchVolume: 3817,
          organicRank: 106,

          keywordSales: 83
        },
        {
          date: '2024-05-09',
          searchVolume: 3817,
          organicRank: 109,

          keywordSales: 83
        },
        {
          date: '2024-05-08',
          searchVolume: 3817,
          organicRank: 102,

          keywordSales: 83
        },
        {
          date: '2024-05-07',
          searchVolume: 3817,
          organicRank: 107,

          keywordSales: 83
        },
        {
          date: '2024-05-06',
          searchVolume: 3817,
          organicRank: 156,

          keywordSales: 83
        },
        {
          date: '2024-05-05',
          searchVolume: 3817,
          organicRank: 110,

          keywordSales: 83
        },
        {
          date: '2024-05-03',
          searchVolume: 3697,
          organicRank: 120,

          keywordSales: 83
        },
        {
          date: '2024-05-02',
          searchVolume: 3697,
          organicRank: 100,

          keywordSales: 83
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_ps5 keyboard',
      keyword: 'ps5 keyboard',
      searchVolume: 2138,
      organicStartRank: 83,
      organicHighestRank: 71,
      sponsoredStartRank: 26,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-06-19',
          searchVolume: 2430,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 73
        },
        {
          date: '2024-06-18',
          searchVolume: 2430,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 73
        },
        {
          date: '2024-06-17',
          searchVolume: 2430,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 73
        },
        {
          date: '2024-06-16',
          searchVolume: 2430,
          organicRank: 306,
          sponsoredRank: 40,
          keywordSales: 73
        },
        {
          date: '2024-06-15',
          searchVolume: 2430,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 73
        },
        {
          date: '2024-06-14',
          searchVolume: 2251,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 73
        },
        {
          date: '2024-06-13',
          searchVolume: 2251,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 73
        },
        {
          date: '2024-06-12',
          searchVolume: 2251,
          organicRank: 229,

          keywordSales: 73
        },
        {
          date: '2024-06-11',
          searchVolume: 2251,
          organicRank: 111,

          keywordSales: 73
        },
        {
          date: '2024-06-10',
          searchVolume: 2251,
          organicRank: 118,

          keywordSales: 73
        },
        {
          date: '2024-06-09',
          searchVolume: 2251,
          organicRank: 117,
          sponsoredRank: 49,
          keywordSales: 73
        },
        {
          date: '2024-06-08',
          searchVolume: 2251,
          organicRank: 115,

          keywordSales: 73
        },
        {
          date: '2024-06-07',
          searchVolume: 2073,
          organicRank: 177,

          keywordSales: 73
        },
        {
          date: '2024-06-06',
          searchVolume: 2073,
          organicRank: 213,

          keywordSales: 73
        },
        {
          date: '2024-06-05',
          searchVolume: 2073,
          organicRank: 257,
          sponsoredRank: 59,
          keywordSales: 73
        },
        {
          date: '2024-06-04',
          searchVolume: 2073,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 73
        },
        {
          date: '2024-06-03',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-06-02',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-06-01',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-31',
          searchVolume: 1895,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 73
        },
        {
          date: '2024-05-30',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-29',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-28',
          searchVolume: 1895,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 73
        },
        {
          date: '2024-05-27',
          searchVolume: 1895,
          organicRank: 306,
          sponsoredRank: 75,
          keywordSales: 73
        },
        {
          date: '2024-05-26',
          searchVolume: 1895,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 73
        },
        {
          date: '2024-05-25',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-24',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-23',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-22',
          searchVolume: 2138,
          organicRank: 306,
          sponsoredRank: 35,
          keywordSales: 73
        },
        {
          date: '2024-05-21',
          searchVolume: 2138,
          organicRank: 306,
          sponsoredRank: 80,
          keywordSales: 73
        },
        {
          date: '2024-05-20',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-19',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-18',
          searchVolume: 2138,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 73
        },
        {
          date: '2024-05-17',
          searchVolume: 1894,
          organicRank: 306,
          sponsoredRank: 24,
          keywordSales: 73
        },
        {
          date: '2024-05-16',
          searchVolume: 1894,
          organicRank: 71,

          keywordSales: 73
        },
        {
          date: '2024-05-15',
          searchVolume: 1894,
          organicRank: 306,

          keywordSales: 73
        },
        {
          date: '2024-05-14',
          searchVolume: 1894,
          organicRank: 85,
          sponsoredRank: 49,
          keywordSales: 73
        },
        {
          date: '2024-05-13',
          searchVolume: 1894,
          organicRank: 83,
          sponsoredRank: 35,
          keywordSales: 73
        },
        {
          date: '2024-05-12',
          searchVolume: 1894,
          organicRank: 86,
          sponsoredRank: 98,
          keywordSales: 73
        },
        {
          date: '2024-05-11',
          searchVolume: 1894,
          organicRank: 88,
          sponsoredRank: 73,
          keywordSales: 73
        },
        {
          date: '2024-05-10',
          searchVolume: 2171,
          organicRank: 77,

          keywordSales: 73
        },
        {
          date: '2024-05-09',
          searchVolume: 2171,
          organicRank: 87,

          keywordSales: 73
        },
        {
          date: '2024-05-08',
          searchVolume: 2171,
          organicRank: 86,
          sponsoredRank: 20,
          keywordSales: 73
        },
        {
          date: '2024-05-07',
          searchVolume: 2171,
          organicRank: 86,

          keywordSales: 73
        },
        {
          date: '2024-05-06',
          searchVolume: 2171,
          organicRank: 81,

          keywordSales: 73
        },
        {
          date: '2024-05-05',
          searchVolume: 2171,
          organicRank: 80,
          sponsoredRank: 67,
          keywordSales: 73
        },
        {
          date: '2024-05-04',
          searchVolume: 2171,
          organicRank: 83,
          sponsoredRank: 26,
          keywordSales: 73
        },
        {
          date: '2024-05-03',
          searchVolume: 2085,
          organicRank: 81,

          keywordSales: 73
        },
        {
          date: '2024-05-02',
          searchVolume: 2085,
          organicRank: 88,

          keywordSales: 73
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_quiet gaming keyboard',
      keyword: 'quiet gaming keyboard',
      searchVolume: 1421,
      organicStartRank: 209,
      organicHighestRank: 101,
      sponsoredStartRank: 70,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1443,
          organicRank: 168,

          keywordSales: 30
        },
        {
          date: '2024-06-19',
          searchVolume: 1443,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 30
        },
        {
          date: '2024-06-18',
          searchVolume: 1443,
          organicRank: 261,

          keywordSales: 30
        },
        {
          date: '2024-06-17',
          searchVolume: 1443,
          organicRank: 183,
          sponsoredRank: 35,
          keywordSales: 30
        },
        {
          date: '2024-06-16',
          searchVolume: 1443,
          organicRank: 164,

          keywordSales: 30
        },
        {
          date: '2024-06-15',
          searchVolume: 1443,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 30
        },
        {
          date: '2024-06-14',
          searchVolume: 1442,
          organicRank: 142,

          keywordSales: 30
        },
        {
          date: '2024-06-13',
          searchVolume: 1442,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 30
        },
        {
          date: '2024-06-12',
          searchVolume: 1442,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-11',
          searchVolume: 1442,
          organicRank: 118,

          keywordSales: 30
        },
        {
          date: '2024-06-10',
          searchVolume: 1442,
          organicRank: 119,

          keywordSales: 30
        },
        {
          date: '2024-06-09',
          searchVolume: 1442,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-08',
          searchVolume: 1442,
          organicRank: 157,

          keywordSales: 30
        },
        {
          date: '2024-06-07',
          searchVolume: 1535,
          organicRank: 101,

          keywordSales: 30
        },
        {
          date: '2024-06-06',
          searchVolume: 1535,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-05',
          searchVolume: 1535,
          organicRank: 207,

          keywordSales: 30
        },
        {
          date: '2024-06-04',
          searchVolume: 1535,
          organicRank: 171,

          keywordSales: 30
        },
        {
          date: '2024-06-03',
          searchVolume: 1535,
          organicRank: 162,

          keywordSales: 30
        },
        {
          date: '2024-06-02',
          searchVolume: 1535,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-01',
          searchVolume: 1535,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-31',
          searchVolume: 1637,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 30
        },
        {
          date: '2024-05-30',
          searchVolume: 1637,
          organicRank: 175,

          keywordSales: 30
        },
        {
          date: '2024-05-29',
          searchVolume: 1637,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-28',
          searchVolume: 1637,
          organicRank: 306,
          sponsoredRank: 70,
          keywordSales: 30
        },
        {
          date: '2024-05-27',
          searchVolume: 1637,
          organicRank: 129,
          sponsoredRank: 63,
          keywordSales: 30
        },
        {
          date: '2024-05-26',
          searchVolume: 1637,
          organicRank: 213,
          sponsoredRank: 18,
          keywordSales: 30
        },
        {
          date: '2024-05-25',
          searchVolume: 1637,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 30
        },
        {
          date: '2024-05-24',
          searchVolume: 1421,
          organicRank: 226,

          keywordSales: 30
        },
        {
          date: '2024-05-23',
          searchVolume: 1421,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-22',
          searchVolume: 1421,
          organicRank: 114,
          sponsoredRank: 50,
          keywordSales: 30
        },
        {
          date: '2024-05-21',
          searchVolume: 1421,
          organicRank: 175,

          keywordSales: 30
        },
        {
          date: '2024-05-20',
          searchVolume: 1421,
          organicRank: 255,

          keywordSales: 30
        },
        {
          date: '2024-05-19',
          searchVolume: 1421,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-18',
          searchVolume: 1421,
          organicRank: 196,
          sponsoredRank: 19,
          keywordSales: 30
        },
        {
          date: '2024-05-17',
          searchVolume: 1580,
          organicRank: 227,
          sponsoredRank: 4,
          keywordSales: 30
        },
        {
          date: '2024-05-16',
          searchVolume: 1580,
          organicRank: 183,

          keywordSales: 30
        },
        {
          date: '2024-05-15',
          searchVolume: 1580,
          organicRank: 212,
          sponsoredRank: 66,
          keywordSales: 30
        },
        {
          date: '2024-05-14',
          searchVolume: 1580,
          organicRank: 206,

          keywordSales: 30
        },
        {
          date: '2024-05-13',
          searchVolume: 1580,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 30
        },
        {
          date: '2024-05-12',
          searchVolume: 1580,
          organicRank: 306,
          sponsoredRank: 35,
          keywordSales: 30
        },
        {
          date: '2024-05-11',
          searchVolume: 1580,
          organicRank: 175,
          sponsoredRank: 2,
          keywordSales: 30
        },
        {
          date: '2024-05-10',
          searchVolume: 1659,
          organicRank: 169,

          keywordSales: 30
        },
        {
          date: '2024-05-09',
          searchVolume: 1659,
          organicRank: 179,

          keywordSales: 30
        },
        {
          date: '2024-05-08',
          searchVolume: 1659,
          organicRank: 176,

          keywordSales: 30
        },
        {
          date: '2024-05-07',
          searchVolume: 1659,
          organicRank: 211,

          keywordSales: 30
        },
        {
          date: '2024-05-06',
          searchVolume: 1659,
          organicRank: 180,
          sponsoredRank: 70,
          keywordSales: 30
        },
        {
          date: '2024-05-04',
          searchVolume: 1659,
          organicRank: 209,

          keywordSales: 30
        },
        {
          date: '2024-05-02',
          searchVolume: 1366,
          organicRank: 106,

          keywordSales: 30
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_quiet mechanical keyboard',
      keyword: 'quiet mechanical keyboard',
      searchVolume: 1584,
      organicStartRank: 108,
      organicHighestRank: 86,
      sponsoredStartRank: 62,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1432,
          organicRank: 88,

          keywordSales: 19
        },
        {
          date: '2024-06-20',
          searchVolume: 1432,
          organicRank: 98,

          keywordSales: 19
        },
        {
          date: '2024-06-19',
          searchVolume: 1432,
          organicRank: 107,

          keywordSales: 19
        },
        {
          date: '2024-06-18',
          searchVolume: 1432,
          organicRank: 114,
          sponsoredRank: 61,
          keywordSales: 19
        },
        {
          date: '2024-06-17',
          searchVolume: 1432,
          organicRank: 106,

          keywordSales: 19
        },
        {
          date: '2024-06-16',
          searchVolume: 1432,
          organicRank: 107,

          keywordSales: 19
        },
        {
          date: '2024-06-15',
          searchVolume: 1432,
          organicRank: 104,
          sponsoredRank: 52,
          keywordSales: 19
        },
        {
          date: '2024-06-14',
          searchVolume: 1661,
          organicRank: 106,
          sponsoredRank: 5,
          keywordSales: 19
        },
        {
          date: '2024-06-13',
          searchVolume: 1661,
          organicRank: 104,

          keywordSales: 19
        },
        {
          date: '2024-06-12',
          searchVolume: 1661,
          organicRank: 86,

          keywordSales: 19
        },
        {
          date: '2024-06-11',
          searchVolume: 1661,
          organicRank: 106,

          keywordSales: 19
        },
        {
          date: '2024-06-10',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-09',
          searchVolume: 1661,
          organicRank: 99,
          sponsoredRank: 2,
          keywordSales: 19
        },
        {
          date: '2024-06-08',
          searchVolume: 1661,
          organicRank: 98,
          sponsoredRank: 23,
          keywordSales: 19
        },
        {
          date: '2024-06-07',
          searchVolume: 1763,
          organicRank: 98,

          keywordSales: 19
        },
        {
          date: '2024-06-06',
          searchVolume: 1763,
          organicRank: 103,

          keywordSales: 19
        },
        {
          date: '2024-06-05',
          searchVolume: 1763,
          organicRank: 207,
          sponsoredRank: 52,
          keywordSales: 19
        },
        {
          date: '2024-06-04',
          searchVolume: 1763,
          organicRank: 164,

          keywordSales: 19
        },
        {
          date: '2024-06-03',
          searchVolume: 1763,
          organicRank: 220,

          keywordSales: 19
        },
        {
          date: '2024-06-02',
          searchVolume: 1763,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-06-01',
          searchVolume: 1763,
          organicRank: 198,

          keywordSales: 19
        },
        {
          date: '2024-05-31',
          searchVolume: 1884,
          organicRank: 107,
          sponsoredRank: 10,
          keywordSales: 19
        },
        {
          date: '2024-05-30',
          searchVolume: 1884,
          organicRank: 162,

          keywordSales: 19
        },
        {
          date: '2024-05-29',
          searchVolume: 1884,
          organicRank: 133,

          keywordSales: 19
        },
        {
          date: '2024-05-28',
          searchVolume: 1884,
          organicRank: 112,
          sponsoredRank: 70,
          keywordSales: 19
        },
        {
          date: '2024-05-27',
          searchVolume: 1884,
          organicRank: 186,

          keywordSales: 19
        },
        {
          date: '2024-05-26',
          searchVolume: 1884,
          organicRank: 255,
          sponsoredRank: 17,
          keywordSales: 19
        },
        {
          date: '2024-05-25',
          searchVolume: 1884,
          organicRank: 160,

          keywordSales: 19
        },
        {
          date: '2024-05-24',
          searchVolume: 1584,
          organicRank: 306,

          keywordSales: 19
        },
        {
          date: '2024-05-23',
          searchVolume: 1584,
          organicRank: 249,

          keywordSales: 19
        },
        {
          date: '2024-05-22',
          searchVolume: 1584,
          organicRank: 108,

          keywordSales: 19
        },
        {
          date: '2024-05-21',
          searchVolume: 1584,
          organicRank: 123,

          keywordSales: 19
        },
        {
          date: '2024-05-20',
          searchVolume: 1584,
          organicRank: 121,

          keywordSales: 19
        },
        {
          date: '2024-05-19',
          searchVolume: 1584,
          organicRank: 203,

          keywordSales: 19
        },
        {
          date: '2024-05-18',
          searchVolume: 1584,
          organicRank: 196,
          sponsoredRank: 30,
          keywordSales: 19
        },
        {
          date: '2024-05-17',
          searchVolume: 1635,
          organicRank: 116,
          sponsoredRank: 4,
          keywordSales: 19
        },
        {
          date: '2024-05-16',
          searchVolume: 1635,
          organicRank: 182,

          keywordSales: 19
        },
        {
          date: '2024-05-15',
          searchVolume: 1635,
          organicRank: 103,

          keywordSales: 19
        },
        {
          date: '2024-05-14',
          searchVolume: 1635,
          organicRank: 101,
          sponsoredRank: 2,
          keywordSales: 19
        },
        {
          date: '2024-05-13',
          searchVolume: 1635,
          organicRank: 88,

          keywordSales: 19
        },
        {
          date: '2024-05-12',
          searchVolume: 1635,
          organicRank: 89,
          sponsoredRank: 85,
          keywordSales: 19
        },
        {
          date: '2024-05-11',
          searchVolume: 1635,
          organicRank: 104,
          sponsoredRank: 84,
          keywordSales: 19
        },
        {
          date: '2024-05-10',
          searchVolume: 1913,
          organicRank: 100,

          keywordSales: 19
        },
        {
          date: '2024-05-09',
          searchVolume: 1913,
          organicRank: 112,

          keywordSales: 19
        },
        {
          date: '2024-05-08',
          searchVolume: 1913,
          organicRank: 98,

          keywordSales: 19
        },
        {
          date: '2024-05-07',
          searchVolume: 1913,
          organicRank: 89,

          keywordSales: 19
        },
        {
          date: '2024-05-06',
          searchVolume: 1913,
          organicRank: 112,

          keywordSales: 19
        },
        {
          date: '2024-05-05',
          searchVolume: 1913,
          organicRank: 98,

          keywordSales: 19
        },
        {
          date: '2024-05-04',
          searchVolume: 1913,
          organicRank: 108,

          keywordSales: 19
        },
        {
          date: '2024-05-03',
          searchVolume: 1582,
          organicRank: 106,

          keywordSales: 19
        },
        {
          date: '2024-05-02',
          searchVolume: 1582,
          organicRank: 90,
          sponsoredRank: 62,
          keywordSales: 19
        },
        {
          date: '2024-05-01',
          searchVolume: 1582,
          organicRank: 82,

          keywordSales: 19
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_rainy 75 keyboard',
      keyword: 'rainy 75 keyboard',
      searchVolume: 6644,
      organicStartRank: 110,
      organicHighestRank: 114,
      sponsoredStartRank: 26,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 6144,
          organicRank: 306
        },
        {
          date: '2024-06-19',
          searchVolume: 6144,
          organicRank: 306
        },
        {
          date: '2024-06-18',
          searchVolume: 6144,
          organicRank: 306
        },
        {
          date: '2024-06-17',
          searchVolume: 6144,
          organicRank: 306
        },
        {
          date: '2024-06-16',
          searchVolume: 6144,
          organicRank: 306
        },
        {
          date: '2024-06-15',
          searchVolume: 6144,
          organicRank: 306
        },
        {
          date: '2024-06-14',
          searchVolume: 5953,
          organicRank: 306
        },
        {
          date: '2024-06-13',
          searchVolume: 5953,
          organicRank: 306
        },
        {
          date: '2024-06-12',
          searchVolume: 5953,
          organicRank: 306
        },
        {
          date: '2024-06-11',
          searchVolume: 5953,
          organicRank: 306
        },
        {
          date: '2024-06-10',
          searchVolume: 5953,
          organicRank: 123
        },
        {
          date: '2024-06-09',
          searchVolume: 5953,
          organicRank: 119
        },
        {
          date: '2024-06-08',
          searchVolume: 5953,
          organicRank: 119
        },
        {
          date: '2024-06-07',
          searchVolume: 6445,
          organicRank: 129
        },
        {
          date: '2024-06-06',
          searchVolume: 6445,
          organicRank: 120
        },
        {
          date: '2024-06-05',
          searchVolume: 6445,
          organicRank: 118
        },
        {
          date: '2024-06-04',
          searchVolume: 6445,
          organicRank: 123
        },
        {
          date: '2024-06-03',
          searchVolume: 6445,
          organicRank: 130
        },
        {
          date: '2024-06-02',
          searchVolume: 6445,
          organicRank: 125
        },
        {
          date: '2024-06-01',
          searchVolume: 6445,
          organicRank: 128
        },
        {
          date: '2024-05-31',
          searchVolume: 6642,
          organicRank: 121,
          sponsoredRank: 30
        },
        {
          date: '2024-05-30',
          searchVolume: 6642,
          organicRank: 124
        },
        {
          date: '2024-05-29',
          searchVolume: 6642,
          organicRank: 121
        },
        {
          date: '2024-05-28',
          searchVolume: 6642,
          organicRank: 306
        },
        {
          date: '2024-05-27',
          searchVolume: 6642,
          organicRank: 117
        },
        {
          date: '2024-05-26',
          searchVolume: 6642,
          organicRank: 124
        },
        {
          date: '2024-05-25',
          searchVolume: 6642,
          organicRank: 125
        },
        {
          date: '2024-05-24',
          searchVolume: 6644,
          organicRank: 120
        },
        {
          date: '2024-05-23',
          searchVolume: 6644,
          organicRank: 121
        },
        {
          date: '2024-05-22',
          searchVolume: 6644,
          organicRank: 127
        },
        {
          date: '2024-05-21',
          searchVolume: 6644,
          organicRank: 116
        },
        {
          date: '2024-05-20',
          searchVolume: 6644,
          organicRank: 118
        },
        {
          date: '2024-05-19',
          searchVolume: 6644,
          organicRank: 121
        },
        {
          date: '2024-05-18',
          searchVolume: 6644,
          organicRank: 122
        },
        {
          date: '2024-05-17',
          searchVolume: 5602,
          organicRank: 130
        },
        {
          date: '2024-05-16',
          searchVolume: 5602,
          organicRank: 135
        },
        {
          date: '2024-05-15',
          searchVolume: 5602,
          organicRank: 306,
          sponsoredRank: 43
        },
        {
          date: '2024-05-14',
          searchVolume: 5602,
          organicRank: 306
        },
        {
          date: '2024-05-13',
          searchVolume: 5602,
          organicRank: 140,
          sponsoredRank: 12
        },
        {
          date: '2024-05-12',
          searchVolume: 5602,
          organicRank: 142,
          sponsoredRank: 2
        },
        {
          date: '2024-05-11',
          searchVolume: 5602,
          organicRank: 306,
          sponsoredRank: 37
        },
        {
          date: '2024-05-10',
          searchVolume: 4378,
          organicRank: 117,
          sponsoredRank: 26
        },
        {
          date: '2024-05-09',
          searchVolume: 4378,
          organicRank: 115
        },
        {
          date: '2024-05-08',
          searchVolume: 4378,
          organicRank: 115
        },
        {
          date: '2024-05-07',
          searchVolume: 4378,
          organicRank: 114
        },
        {
          date: '2024-05-06',
          searchVolume: 4378,
          organicRank: 114
        },
        {
          date: '2024-05-03',
          searchVolume: 4688,
          organicRank: 110
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_rapid trigger keyboard',
      keyword: 'rapid trigger keyboard',
      searchVolume: 2140,
      organicStartRank: 36,
      organicHighestRank: 33,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2547,
          organicRank: 56,
          sponsoredRank: 8
        },
        {
          date: '2024-06-19',
          searchVolume: 2547,
          organicRank: 53,
          sponsoredRank: 8
        },
        {
          date: '2024-06-18',
          searchVolume: 2547,
          organicRank: 51,
          sponsoredRank: 42
        },
        {
          date: '2024-06-17',
          searchVolume: 2547,
          organicRank: 46
        },
        {
          date: '2024-06-16',
          searchVolume: 2547,
          organicRank: 53
        },
        {
          date: '2024-06-15',
          searchVolume: 2547,
          organicRank: 33,
          sponsoredRank: 13
        },
        {
          date: '2024-06-14',
          searchVolume: 2251,
          organicRank: 43,
          sponsoredRank: 6
        },
        {
          date: '2024-06-13',
          searchVolume: 2251,
          organicRank: 48
        },
        {
          date: '2024-06-12',
          searchVolume: 2251,
          organicRank: 49
        },
        {
          date: '2024-06-11',
          searchVolume: 2251,
          organicRank: 46
        },
        {
          date: '2024-06-10',
          searchVolume: 2251,
          organicRank: 45
        },
        {
          date: '2024-06-09',
          searchVolume: 2251,
          organicRank: 47,
          sponsoredRank: 5
        },
        {
          date: '2024-06-08',
          searchVolume: 2251,
          organicRank: 47,
          sponsoredRank: 8
        },
        {
          date: '2024-06-07',
          searchVolume: 1841,
          organicRank: 46
        },
        {
          date: '2024-06-06',
          searchVolume: 1841,
          organicRank: 52
        },
        {
          date: '2024-06-05',
          searchVolume: 1841,
          organicRank: 47,
          sponsoredRank: 30
        },
        {
          date: '2024-06-04',
          searchVolume: 1841,
          organicRank: 53
        },
        {
          date: '2024-06-03',
          searchVolume: 1841,
          organicRank: 56
        },
        {
          date: '2024-06-02',
          searchVolume: 1841,
          organicRank: 52
        },
        {
          date: '2024-06-01',
          searchVolume: 1841,
          organicRank: 49
        },
        {
          date: '2024-05-31',
          searchVolume: 2140,
          organicRank: 48,
          sponsoredRank: 20
        },
        {
          date: '2024-05-30',
          searchVolume: 2140,
          organicRank: 47,
          sponsoredRank: 60
        },
        {
          date: '2024-05-29',
          searchVolume: 2140,
          organicRank: 50
        },
        {
          date: '2024-05-28',
          searchVolume: 2140,
          organicRank: 49,
          sponsoredRank: 31
        },
        {
          date: '2024-05-27',
          searchVolume: 2140,
          organicRank: 50
        },
        {
          date: '2024-05-26',
          searchVolume: 2140,
          organicRank: 49,
          sponsoredRank: 19
        },
        {
          date: '2024-05-25',
          searchVolume: 2140,
          organicRank: 50,
          sponsoredRank: 5
        },
        {
          date: '2024-05-24',
          searchVolume: 2140,
          organicRank: 51
        },
        {
          date: '2024-05-23',
          searchVolume: 2140,
          organicRank: 51
        },
        {
          date: '2024-05-22',
          searchVolume: 2140,
          organicRank: 54,
          sponsoredRank: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 2140,
          organicRank: 51,
          sponsoredRank: 18
        },
        {
          date: '2024-05-20',
          searchVolume: 2140,
          organicRank: 43
        },
        {
          date: '2024-05-19',
          searchVolume: 2140,
          organicRank: 44
        },
        {
          date: '2024-05-18',
          searchVolume: 2140,
          organicRank: 47,
          sponsoredRank: 11
        },
        {
          date: '2024-05-17',
          searchVolume: 2312,
          organicRank: 45,
          sponsoredRank: 56
        },
        {
          date: '2024-05-16',
          searchVolume: 2312,
          organicRank: 48
        },
        {
          date: '2024-05-15',
          searchVolume: 2312,
          organicRank: 50
        },
        {
          date: '2024-05-14',
          searchVolume: 2312,
          organicRank: 45
        },
        {
          date: '2024-05-13',
          searchVolume: 2312,
          organicRank: 47,
          sponsoredRank: 23
        },
        {
          date: '2024-05-12',
          searchVolume: 2312,
          organicRank: 42,
          sponsoredRank: 5
        },
        {
          date: '2024-05-11',
          searchVolume: 2312,
          organicRank: 45
        },
        {
          date: '2024-05-10',
          searchVolume: 2186,
          organicRank: 45,
          sponsoredRank: 29
        },
        {
          date: '2024-05-09',
          searchVolume: 2186,
          organicRank: 47,
          sponsoredRank: 41
        },
        {
          date: '2024-05-08',
          searchVolume: 2186,
          organicRank: 40,
          sponsoredRank: 36
        },
        {
          date: '2024-05-07',
          searchVolume: 2186,
          organicRank: 41,
          sponsoredRank: 29
        },
        {
          date: '2024-05-06',
          searchVolume: 2186,
          organicRank: 36
        },
        {
          date: '2024-05-03',
          searchVolume: 1845,
          organicRank: 36
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_razer gaming keyboard',
      keyword: 'razer gaming keyboard',
      searchVolume: 1888,
      organicStartRank: 50,
      organicHighestRank: 33,
      sponsoredStartRank: 54,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1689,
          organicRank: 42,

          keywordSales: 23
        },
        {
          date: '2024-06-19',
          searchVolume: 1689,
          organicRank: 48,
          sponsoredRank: 8,
          keywordSales: 23
        },
        {
          date: '2024-06-18',
          searchVolume: 1689,
          organicRank: 43,
          sponsoredRank: 23,
          keywordSales: 23
        },
        {
          date: '2024-06-17',
          searchVolume: 1689,
          organicRank: 33,

          keywordSales: 23
        },
        {
          date: '2024-06-16',
          searchVolume: 1689,
          organicRank: 46,
          sponsoredRank: 26,
          keywordSales: 23
        },
        {
          date: '2024-06-15',
          searchVolume: 1689,
          organicRank: 44,
          sponsoredRank: 6,
          keywordSales: 23
        },
        {
          date: '2024-06-14',
          searchVolume: 1852,
          organicRank: 50,
          sponsoredRank: 65,
          keywordSales: 23
        },
        {
          date: '2024-06-13',
          searchVolume: 1852,
          organicRank: 46,
          sponsoredRank: 29,
          keywordSales: 23
        },
        {
          date: '2024-06-12',
          searchVolume: 1852,
          organicRank: 34,

          keywordSales: 23
        },
        {
          date: '2024-06-11',
          searchVolume: 1852,
          organicRank: 47,

          keywordSales: 23
        },
        {
          date: '2024-06-10',
          searchVolume: 1852,
          organicRank: 34,

          keywordSales: 23
        },
        {
          date: '2024-06-09',
          searchVolume: 1852,
          organicRank: 45,
          sponsoredRank: 20,
          keywordSales: 23
        },
        {
          date: '2024-06-08',
          searchVolume: 1852,
          organicRank: 46,
          sponsoredRank: 17,
          keywordSales: 23
        },
        {
          date: '2024-06-07',
          searchVolume: 1830,
          organicRank: 48,

          keywordSales: 23
        },
        {
          date: '2024-06-06',
          searchVolume: 1830,
          organicRank: 35,

          keywordSales: 23
        },
        {
          date: '2024-06-05',
          searchVolume: 1830,
          organicRank: 46,
          sponsoredRank: 29,
          keywordSales: 23
        },
        {
          date: '2024-06-04',
          searchVolume: 1830,
          organicRank: 48,
          sponsoredRank: 10,
          keywordSales: 23
        },
        {
          date: '2024-06-03',
          searchVolume: 1830,
          organicRank: 49,

          keywordSales: 23
        },
        {
          date: '2024-06-02',
          searchVolume: 1830,
          organicRank: 86,

          keywordSales: 23
        },
        {
          date: '2024-06-01',
          searchVolume: 1830,
          organicRank: 46,

          keywordSales: 23
        },
        {
          date: '2024-05-31',
          searchVolume: 1661,
          organicRank: 47,
          sponsoredRank: 32,
          keywordSales: 23
        },
        {
          date: '2024-05-30',
          searchVolume: 1661,
          organicRank: 43,
          sponsoredRank: 24,
          keywordSales: 23
        },
        {
          date: '2024-05-29',
          searchVolume: 1661,
          organicRank: 68,

          keywordSales: 23
        },
        {
          date: '2024-05-28',
          searchVolume: 1661,
          organicRank: 73,

          keywordSales: 23
        },
        {
          date: '2024-05-27',
          searchVolume: 1661,
          organicRank: 66,

          keywordSales: 23
        },
        {
          date: '2024-05-26',
          searchVolume: 1661,
          organicRank: 71,
          sponsoredRank: 8,
          keywordSales: 23
        },
        {
          date: '2024-05-25',
          searchVolume: 1661,
          organicRank: 70,
          sponsoredRank: 12,
          keywordSales: 23
        },
        {
          date: '2024-05-24',
          searchVolume: 1888,
          organicRank: 61,
          sponsoredRank: 29,
          keywordSales: 23
        },
        {
          date: '2024-05-23',
          searchVolume: 1888,
          organicRank: 69,
          sponsoredRank: 73,
          keywordSales: 23
        },
        {
          date: '2024-05-22',
          searchVolume: 1888,
          organicRank: 75,
          sponsoredRank: 6,
          keywordSales: 23
        },
        {
          date: '2024-05-21',
          searchVolume: 1888,
          organicRank: 70,
          sponsoredRank: 23,
          keywordSales: 23
        },
        {
          date: '2024-05-20',
          searchVolume: 1888,
          organicRank: 76,

          keywordSales: 23
        },
        {
          date: '2024-05-19',
          searchVolume: 1888,
          organicRank: 70,

          keywordSales: 23
        },
        {
          date: '2024-05-18',
          searchVolume: 1888,
          organicRank: 64,
          sponsoredRank: 8,
          keywordSales: 23
        },
        {
          date: '2024-05-17',
          searchVolume: 1892,
          organicRank: 72,
          sponsoredRank: 70,
          keywordSales: 23
        },
        {
          date: '2024-05-16',
          searchVolume: 1892,
          organicRank: 73,

          keywordSales: 23
        },
        {
          date: '2024-05-15',
          searchVolume: 1892,
          organicRank: 80,

          keywordSales: 23
        },
        {
          date: '2024-05-14',
          searchVolume: 1892,
          organicRank: 65,
          sponsoredRank: 23,
          keywordSales: 23
        },
        {
          date: '2024-05-13',
          searchVolume: 1892,
          organicRank: 69,
          sponsoredRank: 6,
          keywordSales: 23
        },
        {
          date: '2024-05-12',
          searchVolume: 1892,
          organicRank: 69,
          sponsoredRank: 53,
          keywordSales: 23
        },
        {
          date: '2024-05-11',
          searchVolume: 1892,
          organicRank: 61,
          sponsoredRank: 41,
          keywordSales: 23
        },
        {
          date: '2024-05-10',
          searchVolume: 1924,
          organicRank: 56,
          sponsoredRank: 29,
          keywordSales: 23
        },
        {
          date: '2024-05-09',
          searchVolume: 1924,
          organicRank: 57,

          keywordSales: 23
        },
        {
          date: '2024-05-08',
          searchVolume: 1924,
          organicRank: 74,
          sponsoredRank: 48,
          keywordSales: 23
        },
        {
          date: '2024-05-07',
          searchVolume: 1924,
          organicRank: 65,

          keywordSales: 23
        },
        {
          date: '2024-05-06',
          searchVolume: 1924,
          organicRank: 47,
          sponsoredRank: 54,
          keywordSales: 23
        },
        {
          date: '2024-05-03',
          searchVolume: 1832,
          organicRank: 50,

          keywordSales: 23
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_razer huntsman mini',
      keyword: 'razer huntsman mini',
      searchVolume: 13245,
      organicStartRank: 16,
      organicHighestRank: 15,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-10',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 13426,
          organicRank: 24,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-06-19',
          searchVolume: 13426,
          organicRank: 23,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-06-18',
          searchVolume: 13426,
          organicRank: 23,

          keywordSales: 472
        },
        {
          date: '2024-06-17',
          searchVolume: 13426,
          organicRank: 26,

          keywordSales: 472
        },
        {
          date: '2024-06-16',
          searchVolume: 13426,
          organicRank: 22,
          sponsoredRank: 2,
          keywordSales: 472
        },
        {
          date: '2024-06-15',
          searchVolume: 13426,
          organicRank: 24,
          sponsoredRank: 2,
          keywordSales: 472
        },
        {
          date: '2024-06-14',
          searchVolume: 13426,
          organicRank: 23,

          keywordSales: 472
        },
        {
          date: '2024-06-13',
          searchVolume: 13426,
          organicRank: 23,
          sponsoredRank: 24,
          keywordSales: 472
        },
        {
          date: '2024-06-12',
          searchVolume: 13426,
          organicRank: 23,

          keywordSales: 472
        },
        {
          date: '2024-06-11',
          searchVolume: 13426,
          organicRank: 22,

          keywordSales: 472
        },
        {
          date: '2024-06-10',
          searchVolume: 13426,
          organicRank: 22,

          keywordSales: 472
        },
        {
          date: '2024-06-09',
          searchVolume: 13426,
          organicRank: 20,
          sponsoredRank: 2,
          keywordSales: 472
        },
        {
          date: '2024-06-08',
          searchVolume: 13426,
          organicRank: 23,
          sponsoredRank: 2,
          keywordSales: 472
        },
        {
          date: '2024-06-07',
          searchVolume: 12796,
          organicRank: 18,
          sponsoredRank: 12,
          keywordSales: 472
        },
        {
          date: '2024-06-06',
          searchVolume: 12796,
          organicRank: 36,

          keywordSales: 472
        },
        {
          date: '2024-06-05',
          searchVolume: 12796,
          organicRank: 20,
          sponsoredRank: 25,
          keywordSales: 472
        },
        {
          date: '2024-06-04',
          searchVolume: 12796,
          organicRank: 19,

          keywordSales: 472
        },
        {
          date: '2024-06-03',
          searchVolume: 12796,
          organicRank: 306,

          keywordSales: 472
        },
        {
          date: '2024-06-02',
          searchVolume: 12796,
          organicRank: 18,

          keywordSales: 472
        },
        {
          date: '2024-06-01',
          searchVolume: 12796,
          organicRank: 18,

          keywordSales: 472
        },
        {
          date: '2024-05-31',
          searchVolume: 12012,
          organicRank: 17,
          sponsoredRank: 7,
          keywordSales: 472
        },
        {
          date: '2024-05-30',
          searchVolume: 12012,
          organicRank: 18,

          keywordSales: 472
        },
        {
          date: '2024-05-29',
          searchVolume: 12012,
          organicRank: 21,

          keywordSales: 472
        },
        {
          date: '2024-05-28',
          searchVolume: 12012,
          organicRank: 21,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-27',
          searchVolume: 12012,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-26',
          searchVolume: 12012,
          organicRank: 22,

          keywordSales: 472
        },
        {
          date: '2024-05-25',
          searchVolume: 12012,
          organicRank: 22,

          keywordSales: 472
        },
        {
          date: '2024-05-24',
          searchVolume: 13245,
          organicRank: 21,

          keywordSales: 472
        },
        {
          date: '2024-05-23',
          searchVolume: 13245,
          organicRank: 19,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-22',
          searchVolume: 13245,
          organicRank: 20,

          keywordSales: 472
        },
        {
          date: '2024-05-21',
          searchVolume: 13245,
          organicRank: 22,

          keywordSales: 472
        },
        {
          date: '2024-05-20',
          searchVolume: 13245,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-19',
          searchVolume: 13245,
          organicRank: 22,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-18',
          searchVolume: 13245,
          organicRank: 28,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-17',
          searchVolume: 13228,
          organicRank: 64,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-16',
          searchVolume: 13228,
          organicRank: 21,
          sponsoredRank: 3,
          keywordSales: 472
        },
        {
          date: '2024-05-15',
          searchVolume: 13228,
          organicRank: 23,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-14',
          searchVolume: 13228,
          organicRank: 22,

          keywordSales: 472
        },
        {
          date: '2024-05-13',
          searchVolume: 13228,
          organicRank: 26,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-12',
          searchVolume: 13228,
          organicRank: 22,

          keywordSales: 472
        },
        {
          date: '2024-05-11',
          searchVolume: 13228,
          organicRank: 15,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-10',
          searchVolume: 13439,
          organicRank: 16,
          sponsoredRank: 23,
          keywordSales: 472
        },
        {
          date: '2024-05-09',
          searchVolume: 13439,
          organicRank: 27,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-08',
          searchVolume: 13439,
          organicRank: 21,
          sponsoredRank: 1,
          keywordSales: 472
        },
        {
          date: '2024-05-07',
          searchVolume: 13439,
          organicRank: 40,

          keywordSales: 472
        },
        {
          date: '2024-05-06',
          searchVolume: 13439,
          organicRank: 19,

          keywordSales: 472
        },
        {
          date: '2024-05-03',
          searchVolume: 13499,
          organicRank: 16,

          keywordSales: 472
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_razer keyboard',
      keyword: 'razer keyboard',
      searchVolume: 21733,
      organicStartRank: 81,
      organicHighestRank: 40,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-07',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 24743,
          organicRank: 53,

          keywordSales: 341
        },
        {
          date: '2024-06-20',
          searchVolume: 24743,
          organicRank: 41,

          keywordSales: 341
        },
        {
          date: '2024-06-19',
          searchVolume: 24743,
          organicRank: 40,
          sponsoredRank: 35,
          keywordSales: 341
        },
        {
          date: '2024-06-18',
          searchVolume: 24743,
          organicRank: 67,
          sponsoredRank: 59,
          keywordSales: 341
        },
        {
          date: '2024-06-17',
          searchVolume: 24743,
          organicRank: 71,
          sponsoredRank: 9,
          keywordSales: 341
        },
        {
          date: '2024-06-16',
          searchVolume: 24743,
          organicRank: 65,

          keywordSales: 341
        },
        {
          date: '2024-06-15',
          searchVolume: 24743,
          organicRank: 63,
          sponsoredRank: 2,
          keywordSales: 341
        },
        {
          date: '2024-06-14',
          searchVolume: 24743,
          organicRank: 57,
          sponsoredRank: 2,
          keywordSales: 341
        },
        {
          date: '2024-06-13',
          searchVolume: 24743,
          organicRank: 52,

          keywordSales: 341
        },
        {
          date: '2024-06-12',
          searchVolume: 24743,
          organicRank: 56,

          keywordSales: 341
        },
        {
          date: '2024-06-11',
          searchVolume: 24743,
          organicRank: 61,

          keywordSales: 341
        },
        {
          date: '2024-06-10',
          searchVolume: 24743,
          organicRank: 65,

          keywordSales: 341
        },
        {
          date: '2024-06-09',
          searchVolume: 24743,
          organicRank: 62,
          sponsoredRank: 5,
          keywordSales: 341
        },
        {
          date: '2024-06-08',
          searchVolume: 24743,
          organicRank: 63,
          sponsoredRank: 8,
          keywordSales: 341
        },
        {
          date: '2024-06-07',
          searchVolume: 23603,
          organicRank: 82,

          keywordSales: 341
        },
        {
          date: '2024-06-06',
          searchVolume: 23603,
          organicRank: 63,

          keywordSales: 341
        },
        {
          date: '2024-06-05',
          searchVolume: 23603,
          organicRank: 69,
          sponsoredRank: 4,
          keywordSales: 341
        },
        {
          date: '2024-06-04',
          searchVolume: 23603,
          organicRank: 55,
          sponsoredRank: 5,
          keywordSales: 341
        },
        {
          date: '2024-06-03',
          searchVolume: 23603,
          organicRank: 65,

          keywordSales: 341
        },
        {
          date: '2024-06-02',
          searchVolume: 23603,
          organicRank: 62,

          keywordSales: 341
        },
        {
          date: '2024-06-01',
          searchVolume: 23603,
          organicRank: 64,

          keywordSales: 341
        },
        {
          date: '2024-05-31',
          searchVolume: 23840,
          organicRank: 70,
          sponsoredRank: 13,
          keywordSales: 341
        },
        {
          date: '2024-05-30',
          searchVolume: 23840,
          organicRank: 82,
          sponsoredRank: 90,
          keywordSales: 341
        },
        {
          date: '2024-05-29',
          searchVolume: 23840,
          organicRank: 65,

          keywordSales: 341
        },
        {
          date: '2024-05-28',
          searchVolume: 23840,
          organicRank: 60,
          sponsoredRank: 47,
          keywordSales: 341
        },
        {
          date: '2024-05-27',
          searchVolume: 23840,
          organicRank: 68,

          keywordSales: 341
        },
        {
          date: '2024-05-26',
          searchVolume: 23840,
          organicRank: 84,
          sponsoredRank: 13,
          keywordSales: 341
        },
        {
          date: '2024-05-25',
          searchVolume: 23840,
          organicRank: 62,

          keywordSales: 341
        },
        {
          date: '2024-05-24',
          searchVolume: 21733,
          organicRank: 69,
          sponsoredRank: 53,
          keywordSales: 341
        },
        {
          date: '2024-05-23',
          searchVolume: 21733,
          organicRank: 74,
          sponsoredRank: 8,
          keywordSales: 341
        },
        {
          date: '2024-05-22',
          searchVolume: 21733,
          organicRank: 86,
          sponsoredRank: 2,
          keywordSales: 341
        },
        {
          date: '2024-05-21',
          searchVolume: 21733,
          organicRank: 68,
          sponsoredRank: 9,
          keywordSales: 341
        },
        {
          date: '2024-05-20',
          searchVolume: 21733,
          organicRank: 78,

          keywordSales: 341
        },
        {
          date: '2024-05-19',
          searchVolume: 21733,
          organicRank: 73,

          keywordSales: 341
        },
        {
          date: '2024-05-18',
          searchVolume: 21733,
          organicRank: 67,
          sponsoredRank: 2,
          keywordSales: 341
        },
        {
          date: '2024-05-17',
          searchVolume: 24408,
          organicRank: 74,
          sponsoredRank: 13,
          keywordSales: 341
        },
        {
          date: '2024-05-16',
          searchVolume: 24408,
          organicRank: 68,

          keywordSales: 341
        },
        {
          date: '2024-05-15',
          searchVolume: 24408,
          organicRank: 56,

          keywordSales: 341
        },
        {
          date: '2024-05-14',
          searchVolume: 24408,
          organicRank: 66,
          sponsoredRank: 23,
          keywordSales: 341
        },
        {
          date: '2024-05-13',
          searchVolume: 24408,
          organicRank: 71,
          sponsoredRank: 6,
          keywordSales: 341
        },
        {
          date: '2024-05-12',
          searchVolume: 24408,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 341
        },
        {
          date: '2024-05-11',
          searchVolume: 24408,
          organicRank: 77,
          sponsoredRank: 59,
          keywordSales: 341
        },
        {
          date: '2024-05-10',
          searchVolume: 24860,
          organicRank: 99,

          keywordSales: 341
        },
        {
          date: '2024-05-09',
          searchVolume: 24860,
          organicRank: 85,

          keywordSales: 341
        },
        {
          date: '2024-05-08',
          searchVolume: 24860,
          organicRank: 95,
          sponsoredRank: 17,
          keywordSales: 341
        },
        {
          date: '2024-05-07',
          searchVolume: 24860,
          organicRank: 92,
          sponsoredRank: 12,
          keywordSales: 341
        },
        {
          date: '2024-05-06',
          searchVolume: 24860,
          organicRank: 99,
          sponsoredRank: 90,
          keywordSales: 341
        },
        {
          date: '2024-05-05',
          searchVolume: 24860,
          organicRank: 80,
          sponsoredRank: 8,
          keywordSales: 341
        },
        {
          date: '2024-05-04',
          searchVolume: 24860,
          organicRank: 81,
          sponsoredRank: 7,
          keywordSales: 341
        },
        {
          date: '2024-05-03',
          searchVolume: 23757,
          organicRank: 69,
          sponsoredRank: 5,
          keywordSales: 341
        },
        {
          date: '2024-05-02',
          searchVolume: 23757,
          organicRank: 74,

          keywordSales: 341
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_razer keyboard gaming',
      keyword: 'razer keyboard gaming',
      searchVolume: 3570,
      organicStartRank: 63,
      organicHighestRank: 53,
      sponsoredStartRank: 43,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 3126,
          organicRank: 162,

          keywordSales: 70
        },
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 81,

          keywordSales: 70
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 70,
          sponsoredRank: 38,
          keywordSales: 70
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 92,
          sponsoredRank: 84,
          keywordSales: 70
        },
        {
          date: '2024-06-17',
          searchVolume: 3126,
          organicRank: 72,

          keywordSales: 70
        },
        {
          date: '2024-06-16',
          searchVolume: 3126,
          organicRank: 69,
          sponsoredRank: 42,
          keywordSales: 70
        },
        {
          date: '2024-06-15',
          searchVolume: 3126,
          organicRank: 60,
          sponsoredRank: 4,
          keywordSales: 70
        },
        {
          date: '2024-06-14',
          searchVolume: 3126,
          organicRank: 68,
          sponsoredRank: 5,
          keywordSales: 70
        },
        {
          date: '2024-06-13',
          searchVolume: 3126,
          organicRank: 71,

          keywordSales: 70
        },
        {
          date: '2024-06-12',
          searchVolume: 3126,
          organicRank: 62,

          keywordSales: 70
        },
        {
          date: '2024-06-11',
          searchVolume: 3126,
          organicRank: 59,

          keywordSales: 70
        },
        {
          date: '2024-06-10',
          searchVolume: 3126,
          organicRank: 59,

          keywordSales: 70
        },
        {
          date: '2024-06-09',
          searchVolume: 3126,
          organicRank: 64,
          sponsoredRank: 3,
          keywordSales: 70
        },
        {
          date: '2024-06-08',
          searchVolume: 3126,
          organicRank: 68,
          sponsoredRank: 10,
          keywordSales: 70
        },
        {
          date: '2024-06-07',
          searchVolume: 3265,
          organicRank: 55,

          keywordSales: 70
        },
        {
          date: '2024-06-06',
          searchVolume: 3265,
          organicRank: 60,

          keywordSales: 70
        },
        {
          date: '2024-06-05',
          searchVolume: 3265,
          organicRank: 64,
          sponsoredRank: 11,
          keywordSales: 70
        },
        {
          date: '2024-06-04',
          searchVolume: 3265,
          organicRank: 58,

          keywordSales: 70
        },
        {
          date: '2024-06-03',
          searchVolume: 3265,
          organicRank: 53,

          keywordSales: 70
        },
        {
          date: '2024-06-02',
          searchVolume: 3265,
          organicRank: 54,

          keywordSales: 70
        },
        {
          date: '2024-06-01',
          searchVolume: 3265,
          organicRank: 78,

          keywordSales: 70
        },
        {
          date: '2024-05-31',
          searchVolume: 3190,
          organicRank: 53,
          sponsoredRank: 1,
          keywordSales: 70
        },
        {
          date: '2024-05-30',
          searchVolume: 3190,
          organicRank: 58,

          keywordSales: 70
        },
        {
          date: '2024-05-29',
          searchVolume: 3190,
          organicRank: 56,

          keywordSales: 70
        },
        {
          date: '2024-05-28',
          searchVolume: 3190,
          organicRank: 57,
          sponsoredRank: 60,
          keywordSales: 70
        },
        {
          date: '2024-05-27',
          searchVolume: 3190,
          organicRank: 54,

          keywordSales: 70
        },
        {
          date: '2024-05-26',
          searchVolume: 3190,
          organicRank: 69,
          sponsoredRank: 5,
          keywordSales: 70
        },
        {
          date: '2024-05-25',
          searchVolume: 3190,
          organicRank: 61,

          keywordSales: 70
        },
        {
          date: '2024-05-24',
          searchVolume: 3570,
          organicRank: 71,
          sponsoredRank: 52,
          keywordSales: 70
        },
        {
          date: '2024-05-23',
          searchVolume: 3570,
          organicRank: 57,

          keywordSales: 70
        },
        {
          date: '2024-05-22',
          searchVolume: 3570,
          organicRank: 58,
          sponsoredRank: 45,
          keywordSales: 70
        },
        {
          date: '2024-05-21',
          searchVolume: 3570,
          organicRank: 55,
          sponsoredRank: 35,
          keywordSales: 70
        },
        {
          date: '2024-05-20',
          searchVolume: 3570,
          organicRank: 72,

          keywordSales: 70
        },
        {
          date: '2024-05-19',
          searchVolume: 3570,
          organicRank: 63,

          keywordSales: 70
        },
        {
          date: '2024-05-18',
          searchVolume: 3570,
          organicRank: 68,
          sponsoredRank: 25,
          keywordSales: 70
        },
        {
          date: '2024-05-17',
          searchVolume: 3562,
          organicRank: 55,
          sponsoredRank: 1,
          keywordSales: 70
        },
        {
          date: '2024-05-16',
          searchVolume: 3562,
          organicRank: 66,

          keywordSales: 70
        },
        {
          date: '2024-05-15',
          searchVolume: 3562,
          organicRank: 58,

          keywordSales: 70
        },
        {
          date: '2024-05-14',
          searchVolume: 3562,
          organicRank: 57,

          keywordSales: 70
        },
        {
          date: '2024-05-13',
          searchVolume: 3562,
          organicRank: 64,
          sponsoredRank: 50,
          keywordSales: 70
        },
        {
          date: '2024-05-12',
          searchVolume: 3562,
          organicRank: 78,
          sponsoredRank: 2,
          keywordSales: 70
        },
        {
          date: '2024-05-11',
          searchVolume: 3562,
          organicRank: 54,
          sponsoredRank: 33,
          keywordSales: 70
        },
        {
          date: '2024-05-10',
          searchVolume: 3620,
          organicRank: 81,
          sponsoredRank: 44,
          keywordSales: 70
        },
        {
          date: '2024-05-09',
          searchVolume: 3620,
          organicRank: 54,
          sponsoredRank: 75,
          keywordSales: 70
        },
        {
          date: '2024-05-08',
          searchVolume: 3620,
          organicRank: 55,
          sponsoredRank: 3,
          keywordSales: 70
        },
        {
          date: '2024-05-07',
          searchVolume: 3620,
          organicRank: 68,
          sponsoredRank: 43,
          keywordSales: 70
        },
        {
          date: '2024-05-06',
          searchVolume: 3620,
          organicRank: 53,

          keywordSales: 70
        },
        {
          date: '2024-05-03',
          searchVolume: 3460,
          organicRank: 63,

          keywordSales: 70
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_razor keyboard',
      keyword: 'razor keyboard',
      searchVolume: 1891,
      organicStartRank: 78,
      organicHighestRank: 37,
      sponsoredStartRank: 50,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1657,
          organicRank: 59,

          keywordSales: 24
        },
        {
          date: '2024-06-20',
          searchVolume: 1657,
          organicRank: 57,
          sponsoredRank: 59,
          keywordSales: 24
        },
        {
          date: '2024-06-19',
          searchVolume: 1657,
          organicRank: 37,
          sponsoredRank: 4,
          keywordSales: 24
        },
        {
          date: '2024-06-18',
          searchVolume: 1657,
          organicRank: 59,
          sponsoredRank: 20,
          keywordSales: 24
        },
        {
          date: '2024-06-17',
          searchVolume: 1657,
          organicRank: 58,

          keywordSales: 24
        },
        {
          date: '2024-06-16',
          searchVolume: 1657,
          organicRank: 64,

          keywordSales: 24
        },
        {
          date: '2024-06-15',
          searchVolume: 1657,
          organicRank: 60,
          sponsoredRank: 2,
          keywordSales: 24
        },
        {
          date: '2024-06-14',
          searchVolume: 1659,
          organicRank: 52,
          sponsoredRank: 5,
          keywordSales: 24
        },
        {
          date: '2024-06-13',
          searchVolume: 1659,
          organicRank: 72,

          keywordSales: 24
        },
        {
          date: '2024-06-12',
          searchVolume: 1659,
          organicRank: 62,

          keywordSales: 24
        },
        {
          date: '2024-06-11',
          searchVolume: 1659,
          organicRank: 59,

          keywordSales: 24
        },
        {
          date: '2024-06-10',
          searchVolume: 1659,
          organicRank: 56,

          keywordSales: 24
        },
        {
          date: '2024-06-09',
          searchVolume: 1659,
          organicRank: 70,
          sponsoredRank: 8,
          keywordSales: 24
        },
        {
          date: '2024-06-08',
          searchVolume: 1659,
          organicRank: 64,
          sponsoredRank: 11,
          keywordSales: 24
        },
        {
          date: '2024-06-07',
          searchVolume: 1763,
          organicRank: 55,

          keywordSales: 24
        },
        {
          date: '2024-06-06',
          searchVolume: 1763,
          organicRank: 62,

          keywordSales: 24
        },
        {
          date: '2024-06-05',
          searchVolume: 1763,
          organicRank: 58,
          sponsoredRank: 2,
          keywordSales: 24
        },
        {
          date: '2024-06-04',
          searchVolume: 1763,
          organicRank: 55,
          sponsoredRank: 13,
          keywordSales: 24
        },
        {
          date: '2024-06-03',
          searchVolume: 1763,
          organicRank: 67,

          keywordSales: 24
        },
        {
          date: '2024-06-02',
          searchVolume: 1763,
          organicRank: 54,

          keywordSales: 24
        },
        {
          date: '2024-06-01',
          searchVolume: 1763,
          organicRank: 64,

          keywordSales: 24
        },
        {
          date: '2024-05-31',
          searchVolume: 1889,
          organicRank: 75,
          sponsoredRank: 5,
          keywordSales: 24
        },
        {
          date: '2024-05-30',
          searchVolume: 1889,
          organicRank: 70,

          keywordSales: 24
        },
        {
          date: '2024-05-29',
          searchVolume: 1889,
          organicRank: 73,

          keywordSales: 24
        },
        {
          date: '2024-05-28',
          searchVolume: 1889,
          organicRank: 63,

          keywordSales: 24
        },
        {
          date: '2024-05-27',
          searchVolume: 1889,
          organicRank: 66,
          sponsoredRank: 61,
          keywordSales: 24
        },
        {
          date: '2024-05-26',
          searchVolume: 1889,
          organicRank: 73,
          sponsoredRank: 10,
          keywordSales: 24
        },
        {
          date: '2024-05-25',
          searchVolume: 1889,
          organicRank: 70,

          keywordSales: 24
        },
        {
          date: '2024-05-24',
          searchVolume: 1891,
          organicRank: 59,
          sponsoredRank: 18,
          keywordSales: 24
        },
        {
          date: '2024-05-23',
          searchVolume: 1891,
          organicRank: 73,

          keywordSales: 24
        },
        {
          date: '2024-05-22',
          searchVolume: 1891,
          organicRank: 71,
          sponsoredRank: 7,
          keywordSales: 24
        },
        {
          date: '2024-05-21',
          searchVolume: 1891,
          organicRank: 68,

          keywordSales: 24
        },
        {
          date: '2024-05-20',
          searchVolume: 1891,
          organicRank: 78,

          keywordSales: 24
        },
        {
          date: '2024-05-19',
          searchVolume: 1891,
          organicRank: 74,

          keywordSales: 24
        },
        {
          date: '2024-05-18',
          searchVolume: 1891,
          organicRank: 71,
          sponsoredRank: 41,
          keywordSales: 24
        },
        {
          date: '2024-05-17',
          searchVolume: 1819,
          organicRank: 72,
          sponsoredRank: 1,
          keywordSales: 24
        },
        {
          date: '2024-05-16',
          searchVolume: 1819,
          organicRank: 66,

          keywordSales: 24
        },
        {
          date: '2024-05-15',
          searchVolume: 1819,
          organicRank: 64,
          sponsoredRank: 2,
          keywordSales: 24
        },
        {
          date: '2024-05-14',
          searchVolume: 1819,
          organicRank: 72,
          sponsoredRank: 1,
          keywordSales: 24
        },
        {
          date: '2024-05-13',
          searchVolume: 1819,
          organicRank: 306,
          sponsoredRank: 54,
          keywordSales: 24
        },
        {
          date: '2024-05-12',
          searchVolume: 1819,
          organicRank: 89,
          sponsoredRank: 22,
          keywordSales: 24
        },
        {
          date: '2024-05-11',
          searchVolume: 1819,
          organicRank: 92,
          sponsoredRank: 35,
          keywordSales: 24
        },
        {
          date: '2024-05-10',
          searchVolume: 1657,
          organicRank: 92,
          sponsoredRank: 26,
          keywordSales: 24
        },
        {
          date: '2024-05-09',
          searchVolume: 1657,
          organicRank: 92,

          keywordSales: 24
        },
        {
          date: '2024-05-08',
          searchVolume: 1657,
          organicRank: 70,
          sponsoredRank: 19,
          keywordSales: 24
        },
        {
          date: '2024-05-07',
          searchVolume: 1657,
          organicRank: 92,
          sponsoredRank: 50,
          keywordSales: 24
        },
        {
          date: '2024-05-06',
          searchVolume: 1657,
          organicRank: 60,

          keywordSales: 24
        },
        {
          date: '2024-05-03',
          searchVolume: 1830,
          organicRank: 78,

          keywordSales: 24
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_red dragons keyboard',
      keyword: 'red dragons keyboard',
      searchVolume: 4263,
      organicStartRank: 117,
      organicHighestRank: 106,
      sponsoredStartRank: 48,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 4914,
          organicRank: 129,

          keywordSales: 158
        },
        {
          date: '2024-06-19',
          searchVolume: 4914,
          organicRank: 136,
          sponsoredRank: 4,
          keywordSales: 158
        },
        {
          date: '2024-06-18',
          searchVolume: 4914,
          organicRank: 140,
          sponsoredRank: 30,
          keywordSales: 158
        },
        {
          date: '2024-06-17',
          searchVolume: 4914,
          organicRank: 136,
          sponsoredRank: 16,
          keywordSales: 158
        },
        {
          date: '2024-06-16',
          searchVolume: 4914,
          organicRank: 126,
          sponsoredRank: 54,
          keywordSales: 158
        },
        {
          date: '2024-06-15',
          searchVolume: 4914,
          organicRank: 135,
          sponsoredRank: 23,
          keywordSales: 158
        },
        {
          date: '2024-06-14',
          searchVolume: 4552,
          organicRank: 140,
          sponsoredRank: 13,
          keywordSales: 158
        },
        {
          date: '2024-06-13',
          searchVolume: 4552,
          organicRank: 141,
          sponsoredRank: 17,
          keywordSales: 158
        },
        {
          date: '2024-06-12',
          searchVolume: 4552,
          organicRank: 132,

          keywordSales: 158
        },
        {
          date: '2024-06-11',
          searchVolume: 4552,
          organicRank: 139,

          keywordSales: 158
        },
        {
          date: '2024-06-10',
          searchVolume: 4552,
          organicRank: 136,

          keywordSales: 158
        },
        {
          date: '2024-06-09',
          searchVolume: 4552,
          organicRank: 128,
          sponsoredRank: 12,
          keywordSales: 158
        },
        {
          date: '2024-06-08',
          searchVolume: 4552,
          organicRank: 140,
          sponsoredRank: 10,
          keywordSales: 158
        },
        {
          date: '2024-06-07',
          searchVolume: 4124,
          organicRank: 133,

          keywordSales: 158
        },
        {
          date: '2024-06-06',
          searchVolume: 4124,
          organicRank: 116,

          keywordSales: 158
        },
        {
          date: '2024-06-05',
          searchVolume: 4124,
          organicRank: 118,
          sponsoredRank: 31,
          keywordSales: 158
        },
        {
          date: '2024-06-04',
          searchVolume: 4124,
          organicRank: 111,

          keywordSales: 158
        },
        {
          date: '2024-06-03',
          searchVolume: 4124,
          organicRank: 117,

          keywordSales: 158
        },
        {
          date: '2024-06-02',
          searchVolume: 4124,
          organicRank: 117,

          keywordSales: 158
        },
        {
          date: '2024-06-01',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 158
        },
        {
          date: '2024-05-31',
          searchVolume: 4477,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 158
        },
        {
          date: '2024-05-30',
          searchVolume: 4477,
          organicRank: 306,
          sponsoredRank: 41,
          keywordSales: 158
        },
        {
          date: '2024-05-29',
          searchVolume: 4477,
          organicRank: 306,

          keywordSales: 158
        },
        {
          date: '2024-05-28',
          searchVolume: 4477,
          organicRank: 306,

          keywordSales: 158
        },
        {
          date: '2024-05-27',
          searchVolume: 4477,
          organicRank: 131,

          keywordSales: 158
        },
        {
          date: '2024-05-26',
          searchVolume: 4477,
          organicRank: 128,

          keywordSales: 158
        },
        {
          date: '2024-05-25',
          searchVolume: 4477,
          organicRank: 132,

          keywordSales: 158
        },
        {
          date: '2024-05-24',
          searchVolume: 4263,
          organicRank: 129,

          keywordSales: 158
        },
        {
          date: '2024-05-23',
          searchVolume: 4263,
          organicRank: 127,

          keywordSales: 158
        },
        {
          date: '2024-05-22',
          searchVolume: 4263,
          organicRank: 117,
          sponsoredRank: 12,
          keywordSales: 158
        },
        {
          date: '2024-05-21',
          searchVolume: 4263,
          organicRank: 119,
          sponsoredRank: 35,
          keywordSales: 158
        },
        {
          date: '2024-05-20',
          searchVolume: 4263,
          organicRank: 106,

          keywordSales: 158
        },
        {
          date: '2024-05-19',
          searchVolume: 4263,
          organicRank: 306,

          keywordSales: 158
        },
        {
          date: '2024-05-18',
          searchVolume: 4263,
          organicRank: 118,

          keywordSales: 158
        },
        {
          date: '2024-05-17',
          searchVolume: 4479,
          organicRank: 114,
          sponsoredRank: 4,
          keywordSales: 158
        },
        {
          date: '2024-05-16',
          searchVolume: 4479,
          organicRank: 108,

          keywordSales: 158
        },
        {
          date: '2024-05-15',
          searchVolume: 4479,
          organicRank: 113,
          sponsoredRank: 47,
          keywordSales: 158
        },
        {
          date: '2024-05-14',
          searchVolume: 4479,
          organicRank: 109,

          keywordSales: 158
        },
        {
          date: '2024-05-13',
          searchVolume: 4479,
          organicRank: 306,
          sponsoredRank: 54,
          keywordSales: 158
        },
        {
          date: '2024-05-12',
          searchVolume: 4479,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 158
        },
        {
          date: '2024-05-11',
          searchVolume: 4479,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 158
        },
        {
          date: '2024-05-10',
          searchVolume: 4550,
          organicRank: 131,
          sponsoredRank: 29,
          keywordSales: 158
        },
        {
          date: '2024-05-09',
          searchVolume: 4550,
          organicRank: 126,

          keywordSales: 158
        },
        {
          date: '2024-05-08',
          searchVolume: 4550,
          organicRank: 122,
          sponsoredRank: 24,
          keywordSales: 158
        },
        {
          date: '2024-05-07',
          searchVolume: 4550,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 158
        },
        {
          date: '2024-05-06',
          searchVolume: 4550,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 158
        },
        {
          date: '2024-05-03',
          searchVolume: 4337,
          organicRank: 117,

          keywordSales: 158
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_red switch mechanical keyboard',
      keyword: 'red switch mechanical keyboard',
      searchVolume: 1330,
      organicStartRank: 13,
      organicHighestRank: 13,
      sponsoredStartRank: 57,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-05-13',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 886,
          organicRank: 31,
          sponsoredRank: 15,
          keywordSales: 10
        },
        {
          date: '2024-06-19',
          searchVolume: 886,
          organicRank: 35,
          sponsoredRank: 26,
          keywordSales: 10
        },
        {
          date: '2024-06-18',
          searchVolume: 886,
          organicRank: 35,
          sponsoredRank: 8,
          keywordSales: 10
        },
        {
          date: '2024-06-17',
          searchVolume: 886,
          organicRank: 30,
          sponsoredRank: 39,
          keywordSales: 10
        },
        {
          date: '2024-06-16',
          searchVolume: 886,
          organicRank: 32,

          keywordSales: 10
        },
        {
          date: '2024-06-15',
          searchVolume: 886,
          organicRank: 33,
          sponsoredRank: 8,
          keywordSales: 10
        },
        {
          date: '2024-06-14',
          searchVolume: 1222,
          organicRank: 21,

          keywordSales: 10
        },
        {
          date: '2024-06-13',
          searchVolume: 1222,
          organicRank: 24,
          sponsoredRank: 70,
          keywordSales: 10
        },
        {
          date: '2024-06-12',
          searchVolume: 1222,
          organicRank: 15,

          keywordSales: 10
        },
        {
          date: '2024-06-11',
          searchVolume: 1222,
          organicRank: 32,

          keywordSales: 10
        },
        {
          date: '2024-06-10',
          searchVolume: 1222,
          organicRank: 19,

          keywordSales: 10
        },
        {
          date: '2024-06-09',
          searchVolume: 1222,
          organicRank: 22,
          sponsoredRank: 43,
          keywordSales: 10
        },
        {
          date: '2024-06-08',
          searchVolume: 1222,
          organicRank: 17,
          sponsoredRank: 3,
          keywordSales: 10
        },
        {
          date: '2024-06-07',
          searchVolume: 1288,
          organicRank: 22,

          keywordSales: 10
        },
        {
          date: '2024-06-06',
          searchVolume: 1288,
          organicRank: 21,

          keywordSales: 10
        },
        {
          date: '2024-06-05',
          searchVolume: 1288,
          organicRank: 19,
          sponsoredRank: 13,
          keywordSales: 10
        },
        {
          date: '2024-06-04',
          searchVolume: 1288,
          organicRank: 18,

          keywordSales: 10
        },
        {
          date: '2024-06-03',
          searchVolume: 1288,
          organicRank: 19,

          keywordSales: 10
        },
        {
          date: '2024-06-02',
          searchVolume: 1288,
          organicRank: 13,

          keywordSales: 10
        },
        {
          date: '2024-06-01',
          searchVolume: 1288,
          organicRank: 20,

          keywordSales: 10
        },
        {
          date: '2024-05-31',
          searchVolume: 1136,
          organicRank: 14,
          sponsoredRank: 51,
          keywordSales: 10
        },
        {
          date: '2024-05-30',
          searchVolume: 1136,
          organicRank: 17,
          sponsoredRank: 30,
          keywordSales: 10
        },
        {
          date: '2024-05-29',
          searchVolume: 1136,
          organicRank: 17,
          sponsoredRank: 28,
          keywordSales: 10
        },
        {
          date: '2024-05-28',
          searchVolume: 1136,
          organicRank: 15,
          sponsoredRank: 79,
          keywordSales: 10
        },
        {
          date: '2024-05-27',
          searchVolume: 1136,
          organicRank: 20,

          keywordSales: 10
        },
        {
          date: '2024-05-26',
          searchVolume: 1136,
          organicRank: 15,
          sponsoredRank: 72,
          keywordSales: 10
        },
        {
          date: '2024-05-25',
          searchVolume: 1136,
          organicRank: 16,

          keywordSales: 10
        },
        {
          date: '2024-05-24',
          searchVolume: 1330,
          organicRank: 17,

          keywordSales: 10
        },
        {
          date: '2024-05-23',
          searchVolume: 1330,
          organicRank: 17,
          sponsoredRank: 24,
          keywordSales: 10
        },
        {
          date: '2024-05-22',
          searchVolume: 1330,
          organicRank: 16,
          sponsoredRank: 64,
          keywordSales: 10
        },
        {
          date: '2024-05-21',
          searchVolume: 1330,
          organicRank: 15,
          sponsoredRank: 64,
          keywordSales: 10
        },
        {
          date: '2024-05-20',
          searchVolume: 1330,
          organicRank: 17,

          keywordSales: 10
        },
        {
          date: '2024-05-19',
          searchVolume: 1330,
          organicRank: 17,

          keywordSales: 10
        },
        {
          date: '2024-05-18',
          searchVolume: 1330,
          organicRank: 14,
          sponsoredRank: 54,
          keywordSales: 10
        },
        {
          date: '2024-05-17',
          searchVolume: 1249,
          organicRank: 18,
          sponsoredRank: 66,
          keywordSales: 10
        },
        {
          date: '2024-05-16',
          searchVolume: 1249,
          organicRank: 17,

          keywordSales: 10
        },
        {
          date: '2024-05-15',
          searchVolume: 1249,
          organicRank: 14,

          keywordSales: 10
        },
        {
          date: '2024-05-14',
          searchVolume: 1249,
          organicRank: 14,

          keywordSales: 10
        },
        {
          date: '2024-05-13',
          searchVolume: 1249,
          organicRank: 14,
          sponsoredRank: 61,
          keywordSales: 10
        },
        {
          date: '2024-05-12',
          searchVolume: 1249,
          organicRank: 32,
          sponsoredRank: 5,
          keywordSales: 10
        },
        {
          date: '2024-05-11',
          searchVolume: 1249,
          organicRank: 32,
          sponsoredRank: 6,
          keywordSales: 10
        },
        {
          date: '2024-05-10',
          searchVolume: 1170,
          organicRank: 32,

          keywordSales: 10
        },
        {
          date: '2024-05-09',
          searchVolume: 1170,
          organicRank: 16,
          sponsoredRank: 69,
          keywordSales: 10
        },
        {
          date: '2024-05-08',
          searchVolume: 1170,
          organicRank: 15,

          keywordSales: 10
        },
        {
          date: '2024-05-07',
          searchVolume: 1170,
          organicRank: 15,

          keywordSales: 10
        },
        {
          date: '2024-05-06',
          searchVolume: 1170,
          organicRank: 13,

          keywordSales: 10
        },
        {
          date: '2024-05-05',
          searchVolume: 1170,
          organicRank: 15,

          keywordSales: 10
        },
        {
          date: '2024-05-04',
          searchVolume: 1170,
          organicRank: 13,

          keywordSales: 10
        },
        {
          date: '2024-05-03',
          searchVolume: 1366,
          organicRank: 13,

          keywordSales: 10
        },
        {
          date: '2024-05-02',
          searchVolume: 1366,
          organicRank: 17,
          sponsoredRank: 57,
          keywordSales: 10
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_rgb keyboard',
      keyword: 'rgb keyboard',
      searchVolume: 5067,
      organicStartRank: 39,
      organicHighestRank: 39,
      sponsoredStartRank: 46,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-15',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 5693,
          organicRank: 59,

          keywordSales: 108
        },
        {
          date: '2024-06-20',
          searchVolume: 5693,
          organicRank: 306,
          sponsoredRank: 35,
          keywordSales: 108
        },
        {
          date: '2024-06-19',
          searchVolume: 5693,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 108
        },
        {
          date: '2024-06-18',
          searchVolume: 5693,
          organicRank: 62,
          sponsoredRank: 60,
          keywordSales: 108
        },
        {
          date: '2024-06-17',
          searchVolume: 5693,
          organicRank: 60,
          sponsoredRank: 8,
          keywordSales: 108
        },
        {
          date: '2024-06-16',
          searchVolume: 5693,
          organicRank: 56,
          sponsoredRank: 24,
          keywordSales: 108
        },
        {
          date: '2024-06-15',
          searchVolume: 5693,
          organicRank: 62,
          sponsoredRank: 6,
          keywordSales: 108
        },
        {
          date: '2024-06-14',
          searchVolume: 4911,
          organicRank: 69,

          keywordSales: 108
        },
        {
          date: '2024-06-13',
          searchVolume: 4911,
          organicRank: 58,

          keywordSales: 108
        },
        {
          date: '2024-06-12',
          searchVolume: 4911,
          organicRank: 59,

          keywordSales: 108
        },
        {
          date: '2024-06-11',
          searchVolume: 4911,
          organicRank: 52,

          keywordSales: 108
        },
        {
          date: '2024-06-10',
          searchVolume: 4911,
          organicRank: 60,

          keywordSales: 108
        },
        {
          date: '2024-06-09',
          searchVolume: 4911,
          organicRank: 62,
          sponsoredRank: 46,
          keywordSales: 108
        },
        {
          date: '2024-06-08',
          searchVolume: 4911,
          organicRank: 52,
          sponsoredRank: 24,
          keywordSales: 108
        },
        {
          date: '2024-06-07',
          searchVolume: 4344,
          organicRank: 56,

          keywordSales: 108
        },
        {
          date: '2024-06-06',
          searchVolume: 4344,
          organicRank: 58,

          keywordSales: 108
        },
        {
          date: '2024-06-05',
          searchVolume: 4344,
          organicRank: 56,
          sponsoredRank: 66,
          keywordSales: 108
        },
        {
          date: '2024-06-04',
          searchVolume: 4344,
          organicRank: 65,
          sponsoredRank: 22,
          keywordSales: 108
        },
        {
          date: '2024-06-03',
          searchVolume: 4344,
          organicRank: 60,

          keywordSales: 108
        },
        {
          date: '2024-06-02',
          searchVolume: 4344,
          organicRank: 55,

          keywordSales: 108
        },
        {
          date: '2024-06-01',
          searchVolume: 4344,
          organicRank: 57,

          keywordSales: 108
        },
        {
          date: '2024-05-31',
          searchVolume: 4486,
          organicRank: 61,
          sponsoredRank: 80,
          keywordSales: 108
        },
        {
          date: '2024-05-30',
          searchVolume: 4486,
          organicRank: 60,

          keywordSales: 108
        },
        {
          date: '2024-05-29',
          searchVolume: 4486,
          organicRank: 61,

          keywordSales: 108
        },
        {
          date: '2024-05-28',
          searchVolume: 4486,
          organicRank: 63,

          keywordSales: 108
        },
        {
          date: '2024-05-27',
          searchVolume: 4486,
          organicRank: 54,

          keywordSales: 108
        },
        {
          date: '2024-05-26',
          searchVolume: 4486,
          organicRank: 60,
          sponsoredRank: 78,
          keywordSales: 108
        },
        {
          date: '2024-05-25',
          searchVolume: 4486,
          organicRank: 59,

          keywordSales: 108
        },
        {
          date: '2024-05-24',
          searchVolume: 5067,
          organicRank: 48,

          keywordSales: 108
        },
        {
          date: '2024-05-23',
          searchVolume: 5067,
          organicRank: 53,

          keywordSales: 108
        },
        {
          date: '2024-05-22',
          searchVolume: 5067,
          organicRank: 61,
          sponsoredRank: 20,
          keywordSales: 108
        },
        {
          date: '2024-05-21',
          searchVolume: 5067,
          organicRank: 62,
          sponsoredRank: 4,
          keywordSales: 108
        },
        {
          date: '2024-05-20',
          searchVolume: 5067,
          organicRank: 53,
          sponsoredRank: 42,
          keywordSales: 108
        },
        {
          date: '2024-05-19',
          searchVolume: 5067,
          organicRank: 53,

          keywordSales: 108
        },
        {
          date: '2024-05-18',
          searchVolume: 5067,
          organicRank: 62,
          sponsoredRank: 90,
          keywordSales: 108
        },
        {
          date: '2024-05-17',
          searchVolume: 4473,
          organicRank: 47,
          sponsoredRank: 61,
          keywordSales: 108
        },
        {
          date: '2024-05-16',
          searchVolume: 4473,
          organicRank: 47,
          sponsoredRank: 4,
          keywordSales: 108
        },
        {
          date: '2024-05-15',
          searchVolume: 4473,
          organicRank: 53,
          sponsoredRank: 4,
          keywordSales: 108
        },
        {
          date: '2024-05-14',
          searchVolume: 4473,
          organicRank: 50,
          sponsoredRank: 38,
          keywordSales: 108
        },
        {
          date: '2024-05-13',
          searchVolume: 4473,
          organicRank: 49,
          sponsoredRank: 58,
          keywordSales: 108
        },
        {
          date: '2024-05-12',
          searchVolume: 4473,
          organicRank: 53,
          sponsoredRank: 66,
          keywordSales: 108
        },
        {
          date: '2024-05-11',
          searchVolume: 4473,
          organicRank: 53,
          sponsoredRank: 35,
          keywordSales: 108
        },
        {
          date: '2024-05-10',
          searchVolume: 4918,
          organicRank: 54,
          sponsoredRank: 44,
          keywordSales: 108
        },
        {
          date: '2024-05-09',
          searchVolume: 4918,
          organicRank: 56,
          sponsoredRank: 54,
          keywordSales: 108
        },
        {
          date: '2024-05-08',
          searchVolume: 4918,
          organicRank: 60,
          sponsoredRank: 70,
          keywordSales: 108
        },
        {
          date: '2024-05-07',
          searchVolume: 4918,
          organicRank: 57,
          sponsoredRank: 50,
          keywordSales: 108
        },
        {
          date: '2024-05-06',
          searchVolume: 4918,
          organicRank: 54,
          sponsoredRank: 90,
          keywordSales: 108
        },
        {
          date: '2024-05-05',
          searchVolume: 4918,
          organicRank: 42,
          sponsoredRank: 63,
          keywordSales: 108
        },
        {
          date: '2024-05-04',
          searchVolume: 4918,
          organicRank: 39,
          sponsoredRank: 46,
          keywordSales: 108
        },
        {
          date: '2024-05-02',
          searchVolume: 5162,
          organicRank: 38,
          sponsoredRank: 59,
          keywordSales: 108
        },
        {
          date: '2024-05-01',
          searchVolume: 5162,
          organicRank: 59,
          sponsoredRank: 55,
          keywordSales: 108
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_rgb keyboard and mouse',
      keyword: 'rgb keyboard and mouse',
      searchVolume: 1326,
      organicStartRank: 112,
      organicHighestRank: 66,
      sponsoredStartRank: 62,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1351,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-19',
          searchVolume: 1351,
          organicRank: 235,

          keywordSales: 26
        },
        {
          date: '2024-06-18',
          searchVolume: 1351,
          organicRank: 306,
          sponsoredRank: 55,
          keywordSales: 26
        },
        {
          date: '2024-06-17',
          searchVolume: 1351,
          organicRank: 282,

          keywordSales: 26
        },
        {
          date: '2024-06-16',
          searchVolume: 1351,
          organicRank: 169,

          keywordSales: 26
        },
        {
          date: '2024-06-15',
          searchVolume: 1351,
          organicRank: 167,
          sponsoredRank: 4,
          keywordSales: 26
        },
        {
          date: '2024-06-14',
          searchVolume: 1221,
          organicRank: 129,
          sponsoredRank: 49,
          keywordSales: 26
        },
        {
          date: '2024-06-13',
          searchVolume: 1221,
          organicRank: 261,

          keywordSales: 26
        },
        {
          date: '2024-06-12',
          searchVolume: 1221,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-11',
          searchVolume: 1221,
          organicRank: 170,

          keywordSales: 26
        },
        {
          date: '2024-06-10',
          searchVolume: 1221,
          organicRank: 209,

          keywordSales: 26
        },
        {
          date: '2024-06-09',
          searchVolume: 1221,
          organicRank: 306,
          sponsoredRank: 83,
          keywordSales: 26
        },
        {
          date: '2024-06-08',
          searchVolume: 1221,
          organicRank: 100,
          sponsoredRank: 4,
          keywordSales: 26
        },
        {
          date: '2024-06-07',
          searchVolume: 1210,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-06',
          searchVolume: 1210,
          organicRank: 152,

          keywordSales: 26
        },
        {
          date: '2024-06-05',
          searchVolume: 1210,
          organicRank: 182,
          sponsoredRank: 44,
          keywordSales: 26
        },
        {
          date: '2024-06-04',
          searchVolume: 1210,
          organicRank: 275,

          keywordSales: 26
        },
        {
          date: '2024-06-03',
          searchVolume: 1210,
          organicRank: 239,

          keywordSales: 26
        },
        {
          date: '2024-06-02',
          searchVolume: 1210,
          organicRank: 114,

          keywordSales: 26
        },
        {
          date: '2024-06-01',
          searchVolume: 1210,
          organicRank: 168,

          keywordSales: 26
        },
        {
          date: '2024-05-31',
          searchVolume: 1324,
          organicRank: 105,
          sponsoredRank: 13,
          keywordSales: 26
        },
        {
          date: '2024-05-30',
          searchVolume: 1324,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-29',
          searchVolume: 1324,
          organicRank: 111,

          keywordSales: 26
        },
        {
          date: '2024-05-28',
          searchVolume: 1324,
          organicRank: 114,

          keywordSales: 26
        },
        {
          date: '2024-05-27',
          searchVolume: 1324,
          organicRank: 170,

          keywordSales: 26
        },
        {
          date: '2024-05-26',
          searchVolume: 1324,
          organicRank: 108,
          sponsoredRank: 7,
          keywordSales: 26
        },
        {
          date: '2024-05-25',
          searchVolume: 1324,
          organicRank: 97,

          keywordSales: 26
        },
        {
          date: '2024-05-24',
          searchVolume: 1326,
          organicRank: 87,

          keywordSales: 26
        },
        {
          date: '2024-05-23',
          searchVolume: 1326,
          organicRank: 82,
          sponsoredRank: 67,
          keywordSales: 26
        },
        {
          date: '2024-05-22',
          searchVolume: 1326,
          organicRank: 75,

          keywordSales: 26
        },
        {
          date: '2024-05-21',
          searchVolume: 1326,
          organicRank: 71,

          keywordSales: 26
        },
        {
          date: '2024-05-20',
          searchVolume: 1326,
          organicRank: 66,

          keywordSales: 26
        },
        {
          date: '2024-05-19',
          searchVolume: 1326,
          organicRank: 88,

          keywordSales: 26
        },
        {
          date: '2024-05-18',
          searchVolume: 1326,
          organicRank: 77,
          sponsoredRank: 49,
          keywordSales: 26
        },
        {
          date: '2024-05-17',
          searchVolume: 1328,
          organicRank: 83,
          sponsoredRank: 2,
          keywordSales: 26
        },
        {
          date: '2024-05-16',
          searchVolume: 1328,
          organicRank: 80,

          keywordSales: 26
        },
        {
          date: '2024-05-15',
          searchVolume: 1328,
          organicRank: 94,

          keywordSales: 26
        },
        {
          date: '2024-05-14',
          searchVolume: 1328,
          organicRank: 88,

          keywordSales: 26
        },
        {
          date: '2024-05-13',
          searchVolume: 1328,
          organicRank: 84,
          sponsoredRank: 26,
          keywordSales: 26
        },
        {
          date: '2024-05-12',
          searchVolume: 1328,
          organicRank: 87,
          sponsoredRank: 59,
          keywordSales: 26
        },
        {
          date: '2024-05-11',
          searchVolume: 1328,
          organicRank: 118,
          sponsoredRank: 70,
          keywordSales: 26
        },
        {
          date: '2024-05-10',
          searchVolume: 1410,
          organicRank: 103,

          keywordSales: 26
        },
        {
          date: '2024-05-09',
          searchVolume: 1410,
          organicRank: 115,

          keywordSales: 26
        },
        {
          date: '2024-05-08',
          searchVolume: 1410,
          organicRank: 117,

          keywordSales: 26
        },
        {
          date: '2024-05-07',
          searchVolume: 1410,
          organicRank: 207,

          keywordSales: 26
        },
        {
          date: '2024-05-06',
          searchVolume: 1410,
          organicRank: 114,

          keywordSales: 26
        },
        {
          date: '2024-05-05',
          searchVolume: 1410,
          organicRank: 103,

          keywordSales: 26
        },
        {
          date: '2024-05-04',
          searchVolume: 1410,
          organicRank: 112,
          sponsoredRank: 62,
          keywordSales: 26
        },
        {
          date: '2024-05-03',
          searchVolume: 1533,
          organicRank: 106,

          keywordSales: 26
        },
        {
          date: '2024-05-02',
          searchVolume: 1533,
          organicRank: 106,

          keywordSales: 26
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_rk royal kludge rk61',
      keyword: 'rk royal kludge rk61',
      searchVolume: 5334,
      organicStartRank: 106,
      organicHighestRank: 98,
      sponsoredStartRank: 26,
      sponsoredHighestRank: 14,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 4549,
          organicRank: 108,

          keywordSales: 232
        },
        {
          date: '2024-06-19',
          searchVolume: 4549,
          organicRank: 121,

          keywordSales: 232
        },
        {
          date: '2024-06-18',
          searchVolume: 4549,
          organicRank: 119,

          keywordSales: 232
        },
        {
          date: '2024-06-17',
          searchVolume: 4549,
          organicRank: 100,

          keywordSales: 232
        },
        {
          date: '2024-06-16',
          searchVolume: 4549,
          organicRank: 113,

          keywordSales: 232
        },
        {
          date: '2024-06-15',
          searchVolume: 4549,
          organicRank: 114,

          keywordSales: 232
        },
        {
          date: '2024-06-14',
          searchVolume: 4552,
          organicRank: 106,

          keywordSales: 232
        },
        {
          date: '2024-06-13',
          searchVolume: 4552,
          organicRank: 112,

          keywordSales: 232
        },
        {
          date: '2024-06-12',
          searchVolume: 4552,
          organicRank: 115,

          keywordSales: 232
        },
        {
          date: '2024-06-11',
          searchVolume: 4552,
          organicRank: 108,

          keywordSales: 232
        },
        {
          date: '2024-06-10',
          searchVolume: 4552,
          organicRank: 100,

          keywordSales: 232
        },
        {
          date: '2024-06-09',
          searchVolume: 4552,
          organicRank: 100,
          sponsoredRank: 20,
          keywordSales: 232
        },
        {
          date: '2024-06-08',
          searchVolume: 4552,
          organicRank: 119,

          keywordSales: 232
        },
        {
          date: '2024-06-07',
          searchVolume: 5433,
          organicRank: 110,

          keywordSales: 232
        },
        {
          date: '2024-06-06',
          searchVolume: 5433,
          organicRank: 116,

          keywordSales: 232
        },
        {
          date: '2024-06-05',
          searchVolume: 5433,
          organicRank: 113,

          keywordSales: 232
        },
        {
          date: '2024-06-04',
          searchVolume: 5433,
          organicRank: 115,

          keywordSales: 232
        },
        {
          date: '2024-06-03',
          searchVolume: 5433,
          organicRank: 114,

          keywordSales: 232
        },
        {
          date: '2024-06-02',
          searchVolume: 5433,
          organicRank: 116,

          keywordSales: 232
        },
        {
          date: '2024-06-01',
          searchVolume: 5433,
          organicRank: 98,

          keywordSales: 232
        },
        {
          date: '2024-05-31',
          searchVolume: 4844,
          organicRank: 118,
          sponsoredRank: 19,
          keywordSales: 232
        },
        {
          date: '2024-05-30',
          searchVolume: 4844,
          organicRank: 115,

          keywordSales: 232
        },
        {
          date: '2024-05-29',
          searchVolume: 4844,
          organicRank: 101,

          keywordSales: 232
        },
        {
          date: '2024-05-28',
          searchVolume: 4844,
          organicRank: 114,

          keywordSales: 232
        },
        {
          date: '2024-05-27',
          searchVolume: 4844,
          organicRank: 116,

          keywordSales: 232
        },
        {
          date: '2024-05-26',
          searchVolume: 4844,
          organicRank: 100,

          keywordSales: 232
        },
        {
          date: '2024-05-25',
          searchVolume: 4844,
          organicRank: 106,

          keywordSales: 232
        },
        {
          date: '2024-05-24',
          searchVolume: 5334,
          organicRank: 114,

          keywordSales: 232
        },
        {
          date: '2024-05-23',
          searchVolume: 5334,
          organicRank: 117,

          keywordSales: 232
        },
        {
          date: '2024-05-22',
          searchVolume: 5334,
          organicRank: 116,
          sponsoredRank: 14,
          keywordSales: 232
        },
        {
          date: '2024-05-21',
          searchVolume: 5334,
          organicRank: 110,

          keywordSales: 232
        },
        {
          date: '2024-05-20',
          searchVolume: 5334,
          organicRank: 118,

          keywordSales: 232
        },
        {
          date: '2024-05-19',
          searchVolume: 5334,
          organicRank: 119,

          keywordSales: 232
        },
        {
          date: '2024-05-18',
          searchVolume: 5334,
          organicRank: 112,
          sponsoredRank: 32,
          keywordSales: 232
        },
        {
          date: '2024-05-17',
          searchVolume: 5324,
          organicRank: 120,
          sponsoredRank: 25,
          keywordSales: 232
        },
        {
          date: '2024-05-16',
          searchVolume: 5324,
          organicRank: 112,

          keywordSales: 232
        },
        {
          date: '2024-05-15',
          searchVolume: 5324,
          organicRank: 120,

          keywordSales: 232
        },
        {
          date: '2024-05-14',
          searchVolume: 5324,
          organicRank: 119,
          sponsoredRank: 38,
          keywordSales: 232
        },
        {
          date: '2024-05-13',
          searchVolume: 5324,
          organicRank: 118,

          keywordSales: 232
        },
        {
          date: '2024-05-12',
          searchVolume: 5324,
          organicRank: 113,
          sponsoredRank: 29,
          keywordSales: 232
        },
        {
          date: '2024-05-11',
          searchVolume: 5324,
          organicRank: 115,

          keywordSales: 232
        },
        {
          date: '2024-05-09',
          searchVolume: 5934,
          organicRank: 103,
          sponsoredRank: 31,
          keywordSales: 232
        },
        {
          date: '2024-05-08',
          searchVolume: 5934,
          organicRank: 104,
          sponsoredRank: 50,
          keywordSales: 232
        },
        {
          date: '2024-05-07',
          searchVolume: 5934,
          organicRank: 118,
          sponsoredRank: 33,
          keywordSales: 232
        },
        {
          date: '2024-05-06',
          searchVolume: 5934,
          organicRank: 109,
          sponsoredRank: 38,
          keywordSales: 232
        },
        {
          date: '2024-05-05',
          searchVolume: 5934,
          organicRank: 107,
          sponsoredRank: 19,
          keywordSales: 232
        },
        {
          date: '2024-05-04',
          searchVolume: 5934,
          organicRank: 106,
          sponsoredRank: 26,
          keywordSales: 232
        },
        {
          date: '2024-05-03',
          searchVolume: 6952,
          organicRank: 103,
          sponsoredRank: 31,
          keywordSales: 232
        },
        {
          date: '2024-05-02',
          searchVolume: 6952,
          organicRank: 101,
          sponsoredRank: 38,
          keywordSales: 232
        },
        {
          date: '2024-05-01',
          searchVolume: 6952,
          organicRank: 115,
          sponsoredRank: 20,
          keywordSales: 232
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_rog keyboard',
      keyword: 'rog keyboard',
      searchVolume: 2354,
      organicStartRank: 120,
      organicHighestRank: 97,
      sponsoredStartRank: 55,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2023,
          organicRank: 115,

          keywordSales: 29
        },
        {
          date: '2024-06-19',
          searchVolume: 2023,
          organicRank: 125,

          keywordSales: 29
        },
        {
          date: '2024-06-18',
          searchVolume: 2023,
          organicRank: 111,
          sponsoredRank: 69,
          keywordSales: 29
        },
        {
          date: '2024-06-17',
          searchVolume: 2023,
          organicRank: 105,
          sponsoredRank: 30,
          keywordSales: 29
        },
        {
          date: '2024-06-16',
          searchVolume: 2023,
          organicRank: 111,

          keywordSales: 29
        },
        {
          date: '2024-06-15',
          searchVolume: 2023,
          organicRank: 103,

          keywordSales: 29
        },
        {
          date: '2024-06-14',
          searchVolume: 2186,
          organicRank: 102,
          sponsoredRank: 36,
          keywordSales: 29
        },
        {
          date: '2024-06-13',
          searchVolume: 2186,
          organicRank: 104,
          sponsoredRank: 60,
          keywordSales: 29
        },
        {
          date: '2024-06-12',
          searchVolume: 2186,
          organicRank: 113,

          keywordSales: 29
        },
        {
          date: '2024-06-11',
          searchVolume: 2186,
          organicRank: 109,

          keywordSales: 29
        },
        {
          date: '2024-06-10',
          searchVolume: 2186,
          organicRank: 115,

          keywordSales: 29
        },
        {
          date: '2024-06-09',
          searchVolume: 2186,
          organicRank: 104,
          sponsoredRank: 49,
          keywordSales: 29
        },
        {
          date: '2024-06-08',
          searchVolume: 2186,
          organicRank: 126,

          keywordSales: 29
        },
        {
          date: '2024-06-07',
          searchVolume: 1846,
          organicRank: 113,

          keywordSales: 29
        },
        {
          date: '2024-06-06',
          searchVolume: 1846,
          organicRank: 122,

          keywordSales: 29
        },
        {
          date: '2024-06-05',
          searchVolume: 1846,
          organicRank: 114,
          sponsoredRank: 59,
          keywordSales: 29
        },
        {
          date: '2024-06-04',
          searchVolume: 1846,
          organicRank: 115,

          keywordSales: 29
        },
        {
          date: '2024-06-03',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-06-02',
          searchVolume: 1846,
          organicRank: 106,

          keywordSales: 29
        },
        {
          date: '2024-06-01',
          searchVolume: 1846,
          organicRank: 110,

          keywordSales: 29
        },
        {
          date: '2024-05-31',
          searchVolume: 2140,
          organicRank: 119,
          sponsoredRank: 36,
          keywordSales: 29
        },
        {
          date: '2024-05-30',
          searchVolume: 2140,
          organicRank: 116,

          keywordSales: 29
        },
        {
          date: '2024-05-29',
          searchVolume: 2140,
          organicRank: 116,

          keywordSales: 29
        },
        {
          date: '2024-05-28',
          searchVolume: 2140,
          organicRank: 121,

          keywordSales: 29
        },
        {
          date: '2024-05-27',
          searchVolume: 2140,
          organicRank: 123,

          keywordSales: 29
        },
        {
          date: '2024-05-26',
          searchVolume: 2140,
          organicRank: 117,
          sponsoredRank: 63,
          keywordSales: 29
        },
        {
          date: '2024-05-25',
          searchVolume: 2140,
          organicRank: 115,

          keywordSales: 29
        },
        {
          date: '2024-05-24',
          searchVolume: 2354,
          organicRank: 115,
          sponsoredRank: 64,
          keywordSales: 29
        },
        {
          date: '2024-05-23',
          searchVolume: 2354,
          organicRank: 114,

          keywordSales: 29
        },
        {
          date: '2024-05-22',
          searchVolume: 2354,
          organicRank: 115,

          keywordSales: 29
        },
        {
          date: '2024-05-21',
          searchVolume: 2354,
          organicRank: 113,

          keywordSales: 29
        },
        {
          date: '2024-05-20',
          searchVolume: 2354,
          organicRank: 109,

          keywordSales: 29
        },
        {
          date: '2024-05-19',
          searchVolume: 2354,
          organicRank: 107,

          keywordSales: 29
        },
        {
          date: '2024-05-18',
          searchVolume: 2354,
          organicRank: 101,
          sponsoredRank: 11,
          keywordSales: 29
        },
        {
          date: '2024-05-17',
          searchVolume: 2389,
          organicRank: 99,
          sponsoredRank: 16,
          keywordSales: 29
        },
        {
          date: '2024-05-16',
          searchVolume: 2389,
          organicRank: 113,

          keywordSales: 29
        },
        {
          date: '2024-05-15',
          searchVolume: 2389,
          organicRank: 97,

          keywordSales: 29
        },
        {
          date: '2024-05-14',
          searchVolume: 2389,
          organicRank: 98,

          keywordSales: 29
        },
        {
          date: '2024-05-13',
          searchVolume: 2389,
          organicRank: 100,

          keywordSales: 29
        },
        {
          date: '2024-05-12',
          searchVolume: 2389,
          organicRank: 99,

          keywordSales: 29
        },
        {
          date: '2024-05-11',
          searchVolume: 2389,
          organicRank: 117,

          keywordSales: 29
        },
        {
          date: '2024-05-10',
          searchVolume: 2547,
          organicRank: 104,

          keywordSales: 29
        },
        {
          date: '2024-05-09',
          searchVolume: 2547,
          organicRank: 101,

          keywordSales: 29
        },
        {
          date: '2024-05-08',
          searchVolume: 2547,
          organicRank: 124,
          sponsoredRank: 61,
          keywordSales: 29
        },
        {
          date: '2024-05-07',
          searchVolume: 2547,
          organicRank: 130,

          keywordSales: 29
        },
        {
          date: '2024-05-06',
          searchVolume: 2547,
          organicRank: 119,

          keywordSales: 29
        },
        {
          date: '2024-05-05',
          searchVolume: 2547,
          organicRank: 121,
          sponsoredRank: 55,
          keywordSales: 29
        },
        {
          date: '2024-05-04',
          searchVolume: 2547,
          organicRank: 120,

          keywordSales: 29
        },
        {
          date: '2024-05-03',
          searchVolume: 2433,
          organicRank: 115,

          keywordSales: 29
        },
        {
          date: '2024-05-02',
          searchVolume: 2433,
          organicRank: 169,

          keywordSales: 29
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_royal kludge rk61',
      keyword: 'royal kludge rk61',
      searchVolume: 2140,
      organicStartRank: 90,
      organicHighestRank: 81,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 2430,
          organicRank: 87,

          keywordSales: 46
        },
        {
          date: '2024-06-19',
          searchVolume: 2430,
          organicRank: 97,
          sponsoredRank: 31,
          keywordSales: 46
        },
        {
          date: '2024-06-18',
          searchVolume: 2430,
          organicRank: 87,

          keywordSales: 46
        },
        {
          date: '2024-06-17',
          searchVolume: 2430,
          organicRank: 111,

          keywordSales: 46
        },
        {
          date: '2024-06-16',
          searchVolume: 2430,
          organicRank: 106,

          keywordSales: 46
        },
        {
          date: '2024-06-15',
          searchVolume: 2430,
          organicRank: 115,

          keywordSales: 46
        },
        {
          date: '2024-06-14',
          searchVolume: 2392,
          organicRank: 110,
          sponsoredRank: 8,
          keywordSales: 46
        },
        {
          date: '2024-06-13',
          searchVolume: 2392,
          organicRank: 112,

          keywordSales: 46
        },
        {
          date: '2024-06-12',
          searchVolume: 2392,
          organicRank: 112,

          keywordSales: 46
        },
        {
          date: '2024-06-11',
          searchVolume: 2392,
          organicRank: 112,

          keywordSales: 46
        },
        {
          date: '2024-06-10',
          searchVolume: 2392,
          organicRank: 111,

          keywordSales: 46
        },
        {
          date: '2024-06-09',
          searchVolume: 2392,
          organicRank: 111,
          sponsoredRank: 32,
          keywordSales: 46
        },
        {
          date: '2024-06-08',
          searchVolume: 2392,
          organicRank: 122,

          keywordSales: 46
        },
        {
          date: '2024-06-07',
          searchVolume: 2085,
          organicRank: 110,

          keywordSales: 46
        },
        {
          date: '2024-06-06',
          searchVolume: 2085,
          organicRank: 112,

          keywordSales: 46
        },
        {
          date: '2024-06-05',
          searchVolume: 2085,
          organicRank: 103,

          keywordSales: 46
        },
        {
          date: '2024-06-04',
          searchVolume: 2085,
          organicRank: 102,

          keywordSales: 46
        },
        {
          date: '2024-06-03',
          searchVolume: 2085,
          organicRank: 103,

          keywordSales: 46
        },
        {
          date: '2024-06-02',
          searchVolume: 2085,
          organicRank: 109,

          keywordSales: 46
        },
        {
          date: '2024-06-01',
          searchVolume: 2085,
          organicRank: 100,

          keywordSales: 46
        },
        {
          date: '2024-05-31',
          searchVolume: 2153,
          organicRank: 99,
          sponsoredRank: 26,
          keywordSales: 46
        },
        {
          date: '2024-05-30',
          searchVolume: 2153,
          organicRank: 107,

          keywordSales: 46
        },
        {
          date: '2024-05-29',
          searchVolume: 2153,
          organicRank: 102,

          keywordSales: 46
        },
        {
          date: '2024-05-28',
          searchVolume: 2153,
          organicRank: 110,

          keywordSales: 46
        },
        {
          date: '2024-05-27',
          searchVolume: 2153,
          organicRank: 119,

          keywordSales: 46
        },
        {
          date: '2024-05-26',
          searchVolume: 2153,
          organicRank: 115,

          keywordSales: 46
        },
        {
          date: '2024-05-25',
          searchVolume: 2153,
          organicRank: 100,

          keywordSales: 46
        },
        {
          date: '2024-05-24',
          searchVolume: 2140,
          organicRank: 103,

          keywordSales: 46
        },
        {
          date: '2024-05-23',
          searchVolume: 2140,
          organicRank: 94,

          keywordSales: 46
        },
        {
          date: '2024-05-22',
          searchVolume: 2140,
          organicRank: 96,

          keywordSales: 46
        },
        {
          date: '2024-05-21',
          searchVolume: 2140,
          organicRank: 92,

          keywordSales: 46
        },
        {
          date: '2024-05-20',
          searchVolume: 2140,
          organicRank: 95,

          keywordSales: 46
        },
        {
          date: '2024-05-19',
          searchVolume: 2140,
          organicRank: 97,

          keywordSales: 46
        },
        {
          date: '2024-05-18',
          searchVolume: 2140,
          organicRank: 110,

          keywordSales: 46
        },
        {
          date: '2024-05-17',
          searchVolume: 2389,
          organicRank: 102,
          sponsoredRank: 38,
          keywordSales: 46
        },
        {
          date: '2024-05-16',
          searchVolume: 2389,
          organicRank: 111,

          keywordSales: 46
        },
        {
          date: '2024-05-15',
          searchVolume: 2389,
          organicRank: 102,

          keywordSales: 46
        },
        {
          date: '2024-05-14',
          searchVolume: 2389,
          organicRank: 102,

          keywordSales: 46
        },
        {
          date: '2024-05-13',
          searchVolume: 2389,
          organicRank: 101,

          keywordSales: 46
        },
        {
          date: '2024-05-12',
          searchVolume: 2389,
          organicRank: 105,

          keywordSales: 46
        },
        {
          date: '2024-05-11',
          searchVolume: 2389,
          organicRank: 81,

          keywordSales: 46
        },
        {
          date: '2024-05-10',
          searchVolume: 2428,
          organicRank: 90,

          keywordSales: 46
        },
        {
          date: '2024-05-09',
          searchVolume: 2428,
          organicRank: 97,

          keywordSales: 46
        },
        {
          date: '2024-05-08',
          searchVolume: 2428,
          organicRank: 114,

          keywordSales: 46
        },
        {
          date: '2024-05-07',
          searchVolume: 2428,
          organicRank: 96,

          keywordSales: 46
        },
        {
          date: '2024-05-06',
          searchVolume: 2428,
          organicRank: 94,

          keywordSales: 46
        },
        {
          date: '2024-05-05',
          searchVolume: 2428,
          organicRank: 94,

          keywordSales: 46
        },
        {
          date: '2024-05-04',
          searchVolume: 2428,
          organicRank: 90,

          keywordSales: 46
        },
        {
          date: '2024-05-03',
          searchVolume: 2433,
          organicRank: 99,
          sponsoredRank: 31,
          keywordSales: 46
        },
        {
          date: '2024-05-02',
          searchVolume: 2433,
          organicRank: 89,

          keywordSales: 46
        },
        {
          date: '2024-05-01',
          searchVolume: 2433,
          organicRank: 104,

          keywordSales: 46
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_silent gaming keyboard',
      keyword: 'silent gaming keyboard',
      searchVolume: 1410,
      organicStartRank: 100,
      organicHighestRank: 43,
      sponsoredStartRank: 43,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1848,
          organicRank: 78,

          keywordSales: 18
        },
        {
          date: '2024-06-20',
          searchVolume: 1848,
          organicRank: 306,

          keywordSales: 18
        },
        {
          date: '2024-06-19',
          searchVolume: 1848,
          organicRank: 96,
          sponsoredRank: 12,
          keywordSales: 18
        },
        {
          date: '2024-06-18',
          searchVolume: 1848,
          organicRank: 92,
          sponsoredRank: 78,
          keywordSales: 18
        },
        {
          date: '2024-06-17',
          searchVolume: 1848,
          organicRank: 124,

          keywordSales: 18
        },
        {
          date: '2024-06-16',
          searchVolume: 1848,
          organicRank: 306,

          keywordSales: 18
        },
        {
          date: '2024-06-15',
          searchVolume: 1848,
          organicRank: 107,

          keywordSales: 18
        },
        {
          date: '2024-06-14',
          searchVolume: 1664,
          organicRank: 118,
          sponsoredRank: 9,
          keywordSales: 18
        },
        {
          date: '2024-06-13',
          searchVolume: 1664,
          organicRank: 306,

          keywordSales: 18
        },
        {
          date: '2024-06-12',
          searchVolume: 1664,
          organicRank: 168,

          keywordSales: 18
        },
        {
          date: '2024-06-11',
          searchVolume: 1664,
          organicRank: 102,

          keywordSales: 18
        },
        {
          date: '2024-06-10',
          searchVolume: 1664,
          organicRank: 109,
          sponsoredRank: 6,
          keywordSales: 18
        },
        {
          date: '2024-06-09',
          searchVolume: 1664,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 18
        },
        {
          date: '2024-06-08',
          searchVolume: 1664,
          organicRank: 103,
          sponsoredRank: 20,
          keywordSales: 18
        },
        {
          date: '2024-06-07',
          searchVolume: 1584,
          organicRank: 165,

          keywordSales: 18
        },
        {
          date: '2024-06-06',
          searchVolume: 1584,
          organicRank: 43,

          keywordSales: 18
        },
        {
          date: '2024-06-05',
          searchVolume: 1584,
          organicRank: 99,
          sponsoredRank: 7,
          keywordSales: 18
        },
        {
          date: '2024-06-04',
          searchVolume: 1584,
          organicRank: 99,

          keywordSales: 18
        },
        {
          date: '2024-06-03',
          searchVolume: 1584,
          organicRank: 107,

          keywordSales: 18
        },
        {
          date: '2024-06-02',
          searchVolume: 1584,
          organicRank: 100,

          keywordSales: 18
        },
        {
          date: '2024-06-01',
          searchVolume: 1584,
          organicRank: 101,

          keywordSales: 18
        },
        {
          date: '2024-05-31',
          searchVolume: 1423,
          organicRank: 105,
          sponsoredRank: 37,
          keywordSales: 18
        },
        {
          date: '2024-05-30',
          searchVolume: 1423,
          organicRank: 102,

          keywordSales: 18
        },
        {
          date: '2024-05-29',
          searchVolume: 1423,
          organicRank: 98,

          keywordSales: 18
        },
        {
          date: '2024-05-28',
          searchVolume: 1423,
          organicRank: 100,

          keywordSales: 18
        },
        {
          date: '2024-05-27',
          searchVolume: 1423,
          organicRank: 117,

          keywordSales: 18
        },
        {
          date: '2024-05-26',
          searchVolume: 1423,
          organicRank: 104,
          sponsoredRank: 8,
          keywordSales: 18
        },
        {
          date: '2024-05-25',
          searchVolume: 1423,
          organicRank: 109,

          keywordSales: 18
        },
        {
          date: '2024-05-24',
          searchVolume: 1410,
          organicRank: 100,

          keywordSales: 18
        },
        {
          date: '2024-05-23',
          searchVolume: 1410,
          organicRank: 100,

          keywordSales: 18
        },
        {
          date: '2024-05-22',
          searchVolume: 1410,
          organicRank: 94,
          sponsoredRank: 72,
          keywordSales: 18
        },
        {
          date: '2024-05-21',
          searchVolume: 1410,
          organicRank: 98,

          keywordSales: 18
        },
        {
          date: '2024-05-20',
          searchVolume: 1410,
          organicRank: 96,

          keywordSales: 18
        },
        {
          date: '2024-05-19',
          searchVolume: 1410,
          organicRank: 97,

          keywordSales: 18
        },
        {
          date: '2024-05-18',
          searchVolume: 1410,
          organicRank: 107,
          sponsoredRank: 11,
          keywordSales: 18
        },
        {
          date: '2024-05-17',
          searchVolume: 1635,
          organicRank: 93,
          sponsoredRank: 64,
          keywordSales: 18
        },
        {
          date: '2024-05-16',
          searchVolume: 1635,
          organicRank: 91,

          keywordSales: 18
        },
        {
          date: '2024-05-15',
          searchVolume: 1635,
          organicRank: 95,

          keywordSales: 18
        },
        {
          date: '2024-05-14',
          searchVolume: 1635,
          organicRank: 306,

          keywordSales: 18
        },
        {
          date: '2024-05-13',
          searchVolume: 1635,
          organicRank: 107,
          sponsoredRank: 40,
          keywordSales: 18
        },
        {
          date: '2024-05-12',
          searchVolume: 1635,
          organicRank: 306,
          sponsoredRank: 14,
          keywordSales: 18
        },
        {
          date: '2024-05-11',
          searchVolume: 1635,
          organicRank: 95,
          sponsoredRank: 29,
          keywordSales: 18
        },
        {
          date: '2024-05-10',
          searchVolume: 1572,
          organicRank: 102,

          keywordSales: 18
        },
        {
          date: '2024-05-09',
          searchVolume: 1572,
          organicRank: 96,

          keywordSales: 18
        },
        {
          date: '2024-05-08',
          searchVolume: 1572,
          organicRank: 103,

          keywordSales: 18
        },
        {
          date: '2024-05-07',
          searchVolume: 1572,
          organicRank: 166,

          keywordSales: 18
        },
        {
          date: '2024-05-06',
          searchVolume: 1572,
          organicRank: 94,
          sponsoredRank: 68,
          keywordSales: 18
        },
        {
          date: '2024-05-05',
          searchVolume: 1572,
          organicRank: 100,
          sponsoredRank: 1,
          keywordSales: 18
        },
        {
          date: '2024-05-03',
          searchVolume: 1586,
          organicRank: 100,
          sponsoredRank: 43,
          keywordSales: 18
        },
        {
          date: '2024-05-02',
          searchVolume: 1586,
          organicRank: 254,

          keywordSales: 18
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_silent mechanical keyboard',
      keyword: 'silent mechanical keyboard',
      searchVolume: 2511,
      organicStartRank: 52,
      organicHighestRank: 49,
      sponsoredStartRank: 12,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 100,

          keywordSales: 20
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 109,
          sponsoredRank: 35,
          keywordSales: 20
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 73,

          keywordSales: 20
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 91,
          sponsoredRank: 56,
          keywordSales: 20
        },
        {
          date: '2024-06-17',
          searchVolume: 2550,
          organicRank: 97,

          keywordSales: 20
        },
        {
          date: '2024-06-16',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-15',
          searchVolume: 2550,
          organicRank: 115,
          sponsoredRank: 36,
          keywordSales: 20
        },
        {
          date: '2024-06-14',
          searchVolume: 2548,
          organicRank: 88,

          keywordSales: 20
        },
        {
          date: '2024-06-13',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-12',
          searchVolume: 2548,
          organicRank: 103,

          keywordSales: 20
        },
        {
          date: '2024-06-11',
          searchVolume: 2548,
          organicRank: 81,

          keywordSales: 20
        },
        {
          date: '2024-06-10',
          searchVolume: 2548,
          organicRank: 89,

          keywordSales: 20
        },
        {
          date: '2024-06-09',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 20
        },
        {
          date: '2024-06-08',
          searchVolume: 2548,
          organicRank: 89,
          sponsoredRank: 55,
          keywordSales: 20
        },
        {
          date: '2024-06-07',
          searchVolume: 2429,
          organicRank: 98,

          keywordSales: 20
        },
        {
          date: '2024-06-06',
          searchVolume: 2429,
          organicRank: 90,

          keywordSales: 20
        },
        {
          date: '2024-06-05',
          searchVolume: 2429,
          organicRank: 160,
          sponsoredRank: 24,
          keywordSales: 20
        },
        {
          date: '2024-06-04',
          searchVolume: 2429,
          organicRank: 103,

          keywordSales: 20
        },
        {
          date: '2024-06-03',
          searchVolume: 2429,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-02',
          searchVolume: 2429,
          organicRank: 74,

          keywordSales: 20
        },
        {
          date: '2024-06-01',
          searchVolume: 2429,
          organicRank: 84,

          keywordSales: 20
        },
        {
          date: '2024-05-31',
          searchVolume: 2392,
          organicRank: 81,
          sponsoredRank: 19,
          keywordSales: 20
        },
        {
          date: '2024-05-30',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-29',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-28',
          searchVolume: 2392,
          organicRank: 92,

          keywordSales: 20
        },
        {
          date: '2024-05-27',
          searchVolume: 2392,
          organicRank: 83,

          keywordSales: 20
        },
        {
          date: '2024-05-26',
          searchVolume: 2392,
          organicRank: 83,
          sponsoredRank: 12,
          keywordSales: 20
        },
        {
          date: '2024-05-25',
          searchVolume: 2392,
          organicRank: 90,

          keywordSales: 20
        },
        {
          date: '2024-05-24',
          searchVolume: 2511,
          organicRank: 79,

          keywordSales: 20
        },
        {
          date: '2024-05-23',
          searchVolume: 2511,
          organicRank: 69,

          keywordSales: 20
        },
        {
          date: '2024-05-22',
          searchVolume: 2511,
          organicRank: 75,

          keywordSales: 20
        },
        {
          date: '2024-05-21',
          searchVolume: 2511,
          organicRank: 49,

          keywordSales: 20
        },
        {
          date: '2024-05-20',
          searchVolume: 2511,
          organicRank: 76,

          keywordSales: 20
        },
        {
          date: '2024-05-19',
          searchVolume: 2511,
          organicRank: 70,

          keywordSales: 20
        },
        {
          date: '2024-05-18',
          searchVolume: 2511,
          organicRank: 72,

          keywordSales: 20
        },
        {
          date: '2024-05-17',
          searchVolume: 2507,
          organicRank: 75,
          sponsoredRank: 4,
          keywordSales: 20
        },
        {
          date: '2024-05-16',
          searchVolume: 2507,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 20
        },
        {
          date: '2024-05-15',
          searchVolume: 2507,
          organicRank: 306,
          sponsoredRank: 14,
          keywordSales: 20
        },
        {
          date: '2024-05-14',
          searchVolume: 2507,
          organicRank: 72,
          sponsoredRank: 50,
          keywordSales: 20
        },
        {
          date: '2024-05-13',
          searchVolume: 2507,
          organicRank: 306,
          sponsoredRank: 18,
          keywordSales: 20
        },
        {
          date: '2024-05-12',
          searchVolume: 2507,
          organicRank: 76,
          sponsoredRank: 23,
          keywordSales: 20
        },
        {
          date: '2024-05-11',
          searchVolume: 2507,
          organicRank: 80,
          sponsoredRank: 18,
          keywordSales: 20
        },
        {
          date: '2024-05-10',
          searchVolume: 2550,
          organicRank: 76,
          sponsoredRank: 29,
          keywordSales: 20
        },
        {
          date: '2024-05-09',
          searchVolume: 2550,
          organicRank: 83,
          sponsoredRank: 44,
          keywordSales: 20
        },
        {
          date: '2024-05-08',
          searchVolume: 2550,
          organicRank: 83,
          sponsoredRank: 56,
          keywordSales: 20
        },
        {
          date: '2024-05-07',
          searchVolume: 2550,
          organicRank: 61,
          sponsoredRank: 41,
          keywordSales: 20
        },
        {
          date: '2024-05-06',
          searchVolume: 2550,
          organicRank: 55,
          sponsoredRank: 31,
          keywordSales: 20
        },
        {
          date: '2024-05-05',
          searchVolume: 2550,
          organicRank: 56,

          keywordSales: 20
        },
        {
          date: '2024-05-04',
          searchVolume: 2550,
          organicRank: 52,

          keywordSales: 20
        },
        {
          date: '2024-05-03',
          searchVolume: 2434,
          organicRank: 50,
          sponsoredRank: 12,
          keywordSales: 20
        },
        {
          date: '2024-05-02',
          searchVolume: 2434,
          organicRank: 61,

          keywordSales: 20
        },
        {
          date: '2024-05-01',
          searchVolume: 2434,
          organicRank: 69,

          keywordSales: 20
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_small gaming keyboard',
      keyword: 'small gaming keyboard',
      searchVolume: 1419,
      organicStartRank: 92,
      organicHighestRank: 75,
      sponsoredStartRank: 42,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-16',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-20',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-19',
          searchVolume: 1661,
          organicRank: 99,
          sponsoredRank: 31,
          keywordSales: 30
        },
        {
          date: '2024-06-18',
          searchVolume: 1661,
          organicRank: 101,
          sponsoredRank: 66,
          keywordSales: 30
        },
        {
          date: '2024-06-17',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-16',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-06-15',
          searchVolume: 1661,
          organicRank: 103,

          keywordSales: 30
        },
        {
          date: '2024-06-14',
          searchVolume: 1663,
          organicRank: 96,
          sponsoredRank: 8,
          keywordSales: 30
        },
        {
          date: '2024-06-13',
          searchVolume: 1663,
          organicRank: 97,

          keywordSales: 30
        },
        {
          date: '2024-06-12',
          searchVolume: 1663,
          organicRank: 75,

          keywordSales: 30
        },
        {
          date: '2024-06-11',
          searchVolume: 1663,
          organicRank: 100,

          keywordSales: 30
        },
        {
          date: '2024-06-10',
          searchVolume: 1663,
          organicRank: 103,

          keywordSales: 30
        },
        {
          date: '2024-06-09',
          searchVolume: 1663,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 30
        },
        {
          date: '2024-06-08',
          searchVolume: 1663,
          organicRank: 100,

          keywordSales: 30
        },
        {
          date: '2024-06-07',
          searchVolume: 1586,
          organicRank: 97,

          keywordSales: 30
        },
        {
          date: '2024-06-06',
          searchVolume: 1586,
          organicRank: 180,

          keywordSales: 30
        },
        {
          date: '2024-06-05',
          searchVolume: 1586,
          organicRank: 97,

          keywordSales: 30
        },
        {
          date: '2024-06-04',
          searchVolume: 1586,
          organicRank: 97,

          keywordSales: 30
        },
        {
          date: '2024-06-03',
          searchVolume: 1586,
          organicRank: 103,

          keywordSales: 30
        },
        {
          date: '2024-06-02',
          searchVolume: 1586,
          organicRank: 102,

          keywordSales: 30
        },
        {
          date: '2024-06-01',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-31',
          searchVolume: 1632,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 30
        },
        {
          date: '2024-05-30',
          searchVolume: 1632,
          organicRank: 181,

          keywordSales: 30
        },
        {
          date: '2024-05-29',
          searchVolume: 1632,
          organicRank: 111,

          keywordSales: 30
        },
        {
          date: '2024-05-28',
          searchVolume: 1632,
          organicRank: 110,

          keywordSales: 30
        },
        {
          date: '2024-05-27',
          searchVolume: 1632,
          organicRank: 106,

          keywordSales: 30
        },
        {
          date: '2024-05-26',
          searchVolume: 1632,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 30
        },
        {
          date: '2024-05-25',
          searchVolume: 1632,
          organicRank: 104,

          keywordSales: 30
        },
        {
          date: '2024-05-24',
          searchVolume: 1419,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-23',
          searchVolume: 1419,
          organicRank: 105,

          keywordSales: 30
        },
        {
          date: '2024-05-22',
          searchVolume: 1419,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-21',
          searchVolume: 1419,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-20',
          searchVolume: 1419,
          organicRank: 251,

          keywordSales: 30
        },
        {
          date: '2024-05-19',
          searchVolume: 1419,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-18',
          searchVolume: 1419,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-17',
          searchVolume: 1580,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 30
        },
        {
          date: '2024-05-16',
          searchVolume: 1580,
          organicRank: 236,
          sponsoredRank: 17,
          keywordSales: 30
        },
        {
          date: '2024-05-15',
          searchVolume: 1580,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-14',
          searchVolume: 1580,
          organicRank: 189,

          keywordSales: 30
        },
        {
          date: '2024-05-13',
          searchVolume: 1580,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 30
        },
        {
          date: '2024-05-12',
          searchVolume: 1580,
          organicRank: 222,

          keywordSales: 30
        },
        {
          date: '2024-05-11',
          searchVolume: 1580,
          organicRank: 253,
          sponsoredRank: 40,
          keywordSales: 30
        },
        {
          date: '2024-05-10',
          searchVolume: 1663,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-09',
          searchVolume: 1663,
          organicRank: 189,

          keywordSales: 30
        },
        {
          date: '2024-05-08',
          searchVolume: 1663,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 30
        },
        {
          date: '2024-05-07',
          searchVolume: 1663,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-06',
          searchVolume: 1663,
          organicRank: 306,

          keywordSales: 30
        },
        {
          date: '2024-05-05',
          searchVolume: 1663,
          organicRank: 101,

          keywordSales: 30
        },
        {
          date: '2024-05-03',
          searchVolume: 1582,
          organicRank: 92,

          keywordSales: 30
        },
        {
          date: '2024-05-02',
          searchVolume: 1582,
          organicRank: 93,

          keywordSales: 30
        },
        {
          date: '2024-05-01',
          searchVolume: 1582,
          organicRank: 114,

          keywordSales: 30
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_small keyboard',
      keyword: 'small keyboard',
      searchVolume: 4480,
      organicStartRank: 137,
      organicHighestRank: 223,
      sponsoredStartRank: 73,
      sponsoredHighestRank: 19,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-19',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-18',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-17',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-16',
          searchVolume: 4547,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 95
        },
        {
          date: '2024-06-15',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-14',
          searchVolume: 4368,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-13',
          searchVolume: 4368,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-12',
          searchVolume: 4368,
          organicRank: 223,

          keywordSales: 95
        },
        {
          date: '2024-06-11',
          searchVolume: 4368,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-10',
          searchVolume: 4368,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-09',
          searchVolume: 4368,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-08',
          searchVolume: 4368,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-07',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-06',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-05',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-04',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-03',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-02',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-06-01',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-31',
          searchVolume: 4305,
          organicRank: 306,
          sponsoredRank: 52,
          keywordSales: 95
        },
        {
          date: '2024-05-30',
          searchVolume: 4305,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-29',
          searchVolume: 4305,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-28',
          searchVolume: 4305,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-27',
          searchVolume: 4305,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-26',
          searchVolume: 4305,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-25',
          searchVolume: 4305,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-24',
          searchVolume: 4480,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-23',
          searchVolume: 4480,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-22',
          searchVolume: 4480,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-21',
          searchVolume: 4480,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-20',
          searchVolume: 4480,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-19',
          searchVolume: 4480,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-18',
          searchVolume: 4480,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 95
        },
        {
          date: '2024-05-17',
          searchVolume: 4473,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-16',
          searchVolume: 4473,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-15',
          searchVolume: 4473,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-14',
          searchVolume: 4473,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 95
        },
        {
          date: '2024-05-13',
          searchVolume: 4473,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 95
        },
        {
          date: '2024-05-12',
          searchVolume: 4473,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-11',
          searchVolume: 4473,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-10',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-09',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-08',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-07',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-06',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 95
        },
        {
          date: '2024-05-02',
          searchVolume: 4167,
          organicRank: 137,

          keywordSales: 95
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_steelseries keyboard',
      keyword: 'steelseries keyboard',
      searchVolume: 9134,
      organicStartRank: 40,
      organicHighestRank: 22,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-12',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 9281,
          organicRank: 91,

          keywordSales: 270
        },
        {
          date: '2024-06-20',
          searchVolume: 9281,
          organicRank: 57,
          sponsoredRank: 31,
          keywordSales: 270
        },
        {
          date: '2024-06-19',
          searchVolume: 9281,
          organicRank: 49,
          sponsoredRank: 18,
          keywordSales: 270
        },
        {
          date: '2024-06-18',
          searchVolume: 9281,
          organicRank: 101,
          sponsoredRank: 36,
          keywordSales: 270
        },
        {
          date: '2024-06-17',
          searchVolume: 9281,
          organicRank: 56,
          sponsoredRank: 13,
          keywordSales: 270
        },
        {
          date: '2024-06-16',
          searchVolume: 9281,
          organicRank: 58,

          keywordSales: 270
        },
        {
          date: '2024-06-15',
          searchVolume: 9281,
          organicRank: 57,
          sponsoredRank: 12,
          keywordSales: 270
        },
        {
          date: '2024-06-14',
          searchVolume: 9281,
          organicRank: 50,
          sponsoredRank: 79,
          keywordSales: 270
        },
        {
          date: '2024-06-13',
          searchVolume: 9281,
          organicRank: 98,

          keywordSales: 270
        },
        {
          date: '2024-06-12',
          searchVolume: 9281,
          organicRank: 45,

          keywordSales: 270
        },
        {
          date: '2024-06-11',
          searchVolume: 9281,
          organicRank: 43,

          keywordSales: 270
        },
        {
          date: '2024-06-10',
          searchVolume: 9281,
          organicRank: 52,
          sponsoredRank: 47,
          keywordSales: 270
        },
        {
          date: '2024-06-09',
          searchVolume: 9281,
          organicRank: 50,
          sponsoredRank: 28,
          keywordSales: 270
        },
        {
          date: '2024-06-08',
          searchVolume: 9281,
          organicRank: 50,
          sponsoredRank: 20,
          keywordSales: 270
        },
        {
          date: '2024-06-07',
          searchVolume: 8854,
          organicRank: 47,

          keywordSales: 270
        },
        {
          date: '2024-06-06',
          searchVolume: 8854,
          organicRank: 53,

          keywordSales: 270
        },
        {
          date: '2024-06-05',
          searchVolume: 8854,
          organicRank: 46,
          sponsoredRank: 19,
          keywordSales: 270
        },
        {
          date: '2024-06-04',
          searchVolume: 8854,
          organicRank: 63,

          keywordSales: 270
        },
        {
          date: '2024-06-03',
          searchVolume: 8854,
          organicRank: 50,
          sponsoredRank: 16,
          keywordSales: 270
        },
        {
          date: '2024-06-02',
          searchVolume: 8854,
          organicRank: 47,

          keywordSales: 270
        },
        {
          date: '2024-06-01',
          searchVolume: 8854,
          organicRank: 42,
          sponsoredRank: 80,
          keywordSales: 270
        },
        {
          date: '2024-05-31',
          searchVolume: 8875,
          organicRank: 42,
          sponsoredRank: 36,
          keywordSales: 270
        },
        {
          date: '2024-05-30',
          searchVolume: 8875,
          organicRank: 48,

          keywordSales: 270
        },
        {
          date: '2024-05-29',
          searchVolume: 8875,
          organicRank: 51,
          sponsoredRank: 89,
          keywordSales: 270
        },
        {
          date: '2024-05-28',
          searchVolume: 8875,
          organicRank: 43,

          keywordSales: 270
        },
        {
          date: '2024-05-27',
          searchVolume: 8875,
          organicRank: 37,

          keywordSales: 270
        },
        {
          date: '2024-05-26',
          searchVolume: 8875,
          organicRank: 46,
          sponsoredRank: 19,
          keywordSales: 270
        },
        {
          date: '2024-05-25',
          searchVolume: 8875,
          organicRank: 40,
          sponsoredRank: 40,
          keywordSales: 270
        },
        {
          date: '2024-05-24',
          searchVolume: 9134,
          organicRank: 43,

          keywordSales: 270
        },
        {
          date: '2024-05-23',
          searchVolume: 9134,
          organicRank: 46,
          sponsoredRank: 15,
          keywordSales: 270
        },
        {
          date: '2024-05-22',
          searchVolume: 9134,
          organicRank: 43,
          sponsoredRank: 8,
          keywordSales: 270
        },
        {
          date: '2024-05-21',
          searchVolume: 9134,
          organicRank: 41,

          keywordSales: 270
        },
        {
          date: '2024-05-20',
          searchVolume: 9134,
          organicRank: 42,
          sponsoredRank: 81,
          keywordSales: 270
        },
        {
          date: '2024-05-19',
          searchVolume: 9134,
          organicRank: 48,

          keywordSales: 270
        },
        {
          date: '2024-05-18',
          searchVolume: 9134,
          organicRank: 45,
          sponsoredRank: 25,
          keywordSales: 270
        },
        {
          date: '2024-05-17',
          searchVolume: 9129,
          organicRank: 46,
          sponsoredRank: 6,
          keywordSales: 270
        },
        {
          date: '2024-05-16',
          searchVolume: 9129,
          organicRank: 35,

          keywordSales: 270
        },
        {
          date: '2024-05-15',
          searchVolume: 9129,
          organicRank: 45,

          keywordSales: 270
        },
        {
          date: '2024-05-14',
          searchVolume: 9129,
          organicRank: 47,

          keywordSales: 270
        },
        {
          date: '2024-05-13',
          searchVolume: 9129,
          organicRank: 46,
          sponsoredRank: 23,
          keywordSales: 270
        },
        {
          date: '2024-05-12',
          searchVolume: 9129,
          organicRank: 46,
          sponsoredRank: 19,
          keywordSales: 270
        },
        {
          date: '2024-05-11',
          searchVolume: 9129,
          organicRank: 38,
          sponsoredRank: 30,
          keywordSales: 270
        },
        {
          date: '2024-05-10',
          searchVolume: 10753,
          organicRank: 47,

          keywordSales: 270
        },
        {
          date: '2024-05-09',
          searchVolume: 10753,
          organicRank: 22,

          keywordSales: 270
        },
        {
          date: '2024-05-08',
          searchVolume: 10753,
          organicRank: 49,
          sponsoredRank: 36,
          keywordSales: 270
        },
        {
          date: '2024-05-07',
          searchVolume: 10753,
          organicRank: 49,

          keywordSales: 270
        },
        {
          date: '2024-05-06',
          searchVolume: 10753,
          organicRank: 42,
          sponsoredRank: 8,
          keywordSales: 270
        },
        {
          date: '2024-05-05',
          searchVolume: 10753,
          organicRank: 40,

          keywordSales: 270
        },
        {
          date: '2024-05-04',
          searchVolume: 10753,
          organicRank: 40,
          sponsoredRank: 13,
          keywordSales: 270
        },
        {
          date: '2024-05-03',
          searchVolume: 10539,
          organicRank: 50,
          sponsoredRank: 14,
          keywordSales: 270
        },
        {
          date: '2024-05-02',
          searchVolume: 10539,
          organicRank: 47,

          keywordSales: 270
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_thocky keyboard',
      keyword: 'thocky keyboard',
      searchVolume: 3420,
      organicStartRank: 147,
      organicHighestRank: 147,
      sponsoredStartRank: 57,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 3133,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-20',
          searchVolume: 3133,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-19',
          searchVolume: 3133,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-18',
          searchVolume: 3133,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-17',
          searchVolume: 3133,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-16',
          searchVolume: 3133,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 44
        },
        {
          date: '2024-06-15',
          searchVolume: 3133,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 44
        },
        {
          date: '2024-06-14',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-13',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-12',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-11',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-10',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-09',
          searchVolume: 3126,
          organicRank: 306,
          sponsoredRank: 18,
          keywordSales: 44
        },
        {
          date: '2024-06-08',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-07',
          searchVolume: 3272,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-06',
          searchVolume: 3272,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-05',
          searchVolume: 3272,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-04',
          searchVolume: 3272,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-03',
          searchVolume: 3272,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-02',
          searchVolume: 3272,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 44
        },
        {
          date: '2024-06-01',
          searchVolume: 3272,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-31',
          searchVolume: 2511,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 44
        },
        {
          date: '2024-05-30',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-29',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-28',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-27',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-26',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-25',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-24',
          searchVolume: 3420,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-23',
          searchVolume: 3420,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-22',
          searchVolume: 3420,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 44
        },
        {
          date: '2024-05-21',
          searchVolume: 3420,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-20',
          searchVolume: 3420,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-19',
          searchVolume: 3420,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-18',
          searchVolume: 3420,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-17',
          searchVolume: 3073,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 44
        },
        {
          date: '2024-05-16',
          searchVolume: 3073,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 44
        },
        {
          date: '2024-05-15',
          searchVolume: 3073,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-14',
          searchVolume: 3073,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-13',
          searchVolume: 3073,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 44
        },
        {
          date: '2024-05-12',
          searchVolume: 3073,
          organicRank: 221,
          sponsoredRank: 22,
          keywordSales: 44
        },
        {
          date: '2024-05-11',
          searchVolume: 3073,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 44
        },
        {
          date: '2024-05-10',
          searchVolume: 3126,
          organicRank: 155,
          sponsoredRank: 60,
          keywordSales: 44
        },
        {
          date: '2024-05-09',
          searchVolume: 3126,
          organicRank: 220,
          sponsoredRank: 74,
          keywordSales: 44
        },
        {
          date: '2024-05-08',
          searchVolume: 3126,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 44
        },
        {
          date: '2024-05-07',
          searchVolume: 3126,
          organicRank: 157,
          sponsoredRank: 75,
          keywordSales: 44
        },
        {
          date: '2024-05-06',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-05',
          searchVolume: 3126,
          organicRank: 154,
          sponsoredRank: 67,
          keywordSales: 44
        },
        {
          date: '2024-05-04',
          searchVolume: 3126,
          organicRank: 147,
          sponsoredRank: 57,
          keywordSales: 44
        },
        {
          date: '2024-05-03',
          searchVolume: 2433,
          organicRank: 142,
          sponsoredRank: 65,
          keywordSales: 44
        },
        {
          date: '2024-05-02',
          searchVolume: 2433,
          organicRank: 149,
          sponsoredRank: 34,
          keywordSales: 44
        },
        {
          date: '2024-05-01',
          searchVolume: 2433,
          organicRank: 165,
          sponsoredRank: 24,
          keywordSales: 44
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_tkl keyboard',
      keyword: 'tkl keyboard',
      searchVolume: 2354,
      organicStartRank: 19,
      organicHighestRank: 8,
      sponsoredStartRank: 12,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-05-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 2394,
          organicRank: 10,
          sponsoredRank: 76,
          keywordSales: 57
        },
        {
          date: '2024-06-20',
          searchVolume: 2394,
          organicRank: 12,
          sponsoredRank: 9,
          keywordSales: 57
        },
        {
          date: '2024-06-19',
          searchVolume: 2394,
          organicRank: 13,
          sponsoredRank: 24,
          keywordSales: 57
        },
        {
          date: '2024-06-18',
          searchVolume: 2394,
          organicRank: 15,
          sponsoredRank: 11,
          keywordSales: 57
        },
        {
          date: '2024-06-17',
          searchVolume: 2394,
          organicRank: 14,
          sponsoredRank: 13,
          keywordSales: 57
        },
        {
          date: '2024-06-16',
          searchVolume: 2394,
          organicRank: 16,

          keywordSales: 57
        },
        {
          date: '2024-06-15',
          searchVolume: 2394,
          organicRank: 18,
          sponsoredRank: 18,
          keywordSales: 57
        },
        {
          date: '2024-06-14',
          searchVolume: 2394,
          organicRank: 19,
          sponsoredRank: 6,
          keywordSales: 57
        },
        {
          date: '2024-06-13',
          searchVolume: 2394,
          organicRank: 15,

          keywordSales: 57
        },
        {
          date: '2024-06-12',
          searchVolume: 2394,
          organicRank: 15,

          keywordSales: 57
        },
        {
          date: '2024-06-11',
          searchVolume: 2394,
          organicRank: 16,

          keywordSales: 57
        },
        {
          date: '2024-06-10',
          searchVolume: 2394,
          organicRank: 18,
          sponsoredRank: 35,
          keywordSales: 57
        },
        {
          date: '2024-06-09',
          searchVolume: 2394,
          organicRank: 18,
          sponsoredRank: 17,
          keywordSales: 57
        },
        {
          date: '2024-06-08',
          searchVolume: 2394,
          organicRank: 20,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-06-07',
          searchVolume: 2316,
          organicRank: 13,
          sponsoredRank: 35,
          keywordSales: 57
        },
        {
          date: '2024-06-06',
          searchVolume: 2316,
          organicRank: 15,

          keywordSales: 57
        },
        {
          date: '2024-06-05',
          searchVolume: 2316,
          organicRank: 15,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-06-04',
          searchVolume: 2316,
          organicRank: 19,
          sponsoredRank: 18,
          keywordSales: 57
        },
        {
          date: '2024-06-03',
          searchVolume: 2316,
          organicRank: 18,

          keywordSales: 57
        },
        {
          date: '2024-06-02',
          searchVolume: 2316,
          organicRank: 17,

          keywordSales: 57
        },
        {
          date: '2024-06-01',
          searchVolume: 2316,
          organicRank: 12,

          keywordSales: 57
        },
        {
          date: '2024-05-31',
          searchVolume: 1900,
          organicRank: 17,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-05-30',
          searchVolume: 1900,
          organicRank: 19,
          sponsoredRank: 26,
          keywordSales: 57
        },
        {
          date: '2024-05-29',
          searchVolume: 1900,
          organicRank: 11,

          keywordSales: 57
        },
        {
          date: '2024-05-28',
          searchVolume: 1900,
          organicRank: 18,

          keywordSales: 57
        },
        {
          date: '2024-05-27',
          searchVolume: 1900,
          organicRank: 15,

          keywordSales: 57
        },
        {
          date: '2024-05-26',
          searchVolume: 1900,
          organicRank: 15,
          sponsoredRank: 14,
          keywordSales: 57
        },
        {
          date: '2024-05-25',
          searchVolume: 1900,
          organicRank: 15,
          sponsoredRank: 2,
          keywordSales: 57
        },
        {
          date: '2024-05-24',
          searchVolume: 2354,
          organicRank: 16,
          sponsoredRank: 3,
          keywordSales: 57
        },
        {
          date: '2024-05-23',
          searchVolume: 2354,
          organicRank: 14,

          keywordSales: 57
        },
        {
          date: '2024-05-22',
          searchVolume: 2354,
          organicRank: 10,
          sponsoredRank: 44,
          keywordSales: 57
        },
        {
          date: '2024-05-21',
          searchVolume: 2354,
          organicRank: 11,
          sponsoredRank: 29,
          keywordSales: 57
        },
        {
          date: '2024-05-20',
          searchVolume: 2354,
          organicRank: 10,

          keywordSales: 57
        },
        {
          date: '2024-05-19',
          searchVolume: 2354,
          organicRank: 17,
          sponsoredRank: 60,
          keywordSales: 57
        },
        {
          date: '2024-05-18',
          searchVolume: 2354,
          organicRank: 15,
          sponsoredRank: 29,
          keywordSales: 57
        },
        {
          date: '2024-05-17',
          searchVolume: 2507,
          organicRank: 11,
          sponsoredRank: 11,
          keywordSales: 57
        },
        {
          date: '2024-05-16',
          searchVolume: 2507,
          organicRank: 8,

          keywordSales: 57
        },
        {
          date: '2024-05-15',
          searchVolume: 2507,
          organicRank: 11,

          keywordSales: 57
        },
        {
          date: '2024-05-14',
          searchVolume: 2507,
          organicRank: 12,
          sponsoredRank: 30,
          keywordSales: 57
        },
        {
          date: '2024-05-13',
          searchVolume: 2507,
          organicRank: 12,
          sponsoredRank: 35,
          keywordSales: 57
        },
        {
          date: '2024-05-12',
          searchVolume: 2507,
          organicRank: 13,
          sponsoredRank: 41,
          keywordSales: 57
        },
        {
          date: '2024-05-11',
          searchVolume: 2507,
          organicRank: 11,
          sponsoredRank: 29,
          keywordSales: 57
        },
        {
          date: '2024-05-10',
          searchVolume: 2389,
          organicRank: 10,
          sponsoredRank: 5,
          keywordSales: 57
        },
        {
          date: '2024-05-09',
          searchVolume: 2389,
          organicRank: 12,
          sponsoredRank: 64,
          keywordSales: 57
        },
        {
          date: '2024-05-08',
          searchVolume: 2389,
          organicRank: 9,

          keywordSales: 57
        },
        {
          date: '2024-05-07',
          searchVolume: 2389,
          organicRank: 11,

          keywordSales: 57
        },
        {
          date: '2024-05-06',
          searchVolume: 2389,
          organicRank: 10,

          keywordSales: 57
        },
        {
          date: '2024-05-05',
          searchVolume: 2389,
          organicRank: 9,
          sponsoredRank: 8,
          keywordSales: 57
        },
        {
          date: '2024-05-04',
          searchVolume: 2389,
          organicRank: 19,
          sponsoredRank: 12,
          keywordSales: 57
        },
        {
          date: '2024-05-03',
          searchVolume: 2431,
          organicRank: 22,
          sponsoredRank: 24,
          keywordSales: 57
        },
        {
          date: '2024-05-02',
          searchVolume: 2431,
          organicRank: 9,
          sponsoredRank: 50,
          keywordSales: 57
        },
        {
          date: '2024-05-01',
          searchVolume: 2431,
          organicRank: 10,

          keywordSales: 57
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_tkl mechanical keyboard',
      keyword: 'tkl mechanical keyboard',
      searchVolume: 2509,
      organicStartRank: 6,
      organicHighestRank: 4,
      sponsoredStartRank: 35,
      sponsoredHighestRank: 7,
      organicPageOneWinDate: '2024-05-11',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 3132,
          organicRank: 7,

          keywordSales: 26
        },
        {
          date: '2024-06-19',
          searchVolume: 3132,
          organicRank: 8,
          sponsoredRank: 50,
          keywordSales: 26
        },
        {
          date: '2024-06-18',
          searchVolume: 3132,
          organicRank: 5,
          sponsoredRank: 35,
          keywordSales: 26
        },
        {
          date: '2024-06-17',
          searchVolume: 3132,
          organicRank: 8,
          sponsoredRank: 72,
          keywordSales: 26
        },
        {
          date: '2024-06-16',
          searchVolume: 3132,
          organicRank: 7,
          sponsoredRank: 36,
          keywordSales: 26
        },
        {
          date: '2024-06-15',
          searchVolume: 3132,
          organicRank: 11,
          sponsoredRank: 7,
          keywordSales: 26
        },
        {
          date: '2024-06-14',
          searchVolume: 3141,
          organicRank: 8,
          sponsoredRank: 47,
          keywordSales: 26
        },
        {
          date: '2024-06-13',
          searchVolume: 3141,
          organicRank: 8,

          keywordSales: 26
        },
        {
          date: '2024-06-12',
          searchVolume: 3141,
          organicRank: 9,

          keywordSales: 26
        },
        {
          date: '2024-06-11',
          searchVolume: 3141,
          organicRank: 7,

          keywordSales: 26
        },
        {
          date: '2024-06-10',
          searchVolume: 3141,
          organicRank: 5,

          keywordSales: 26
        },
        {
          date: '2024-06-09',
          searchVolume: 3141,
          organicRank: 6,
          sponsoredRank: 14,
          keywordSales: 26
        },
        {
          date: '2024-06-08',
          searchVolume: 3141,
          organicRank: 5,
          sponsoredRank: 17,
          keywordSales: 26
        },
        {
          date: '2024-06-07',
          searchVolume: 2982,
          organicRank: 7,

          keywordSales: 26
        },
        {
          date: '2024-06-06',
          searchVolume: 2982,
          organicRank: 5,

          keywordSales: 26
        },
        {
          date: '2024-06-05',
          searchVolume: 2982,
          organicRank: 5,
          sponsoredRank: 47,
          keywordSales: 26
        },
        {
          date: '2024-06-04',
          searchVolume: 2982,
          organicRank: 5,
          sponsoredRank: 36,
          keywordSales: 26
        },
        {
          date: '2024-06-03',
          searchVolume: 2982,
          organicRank: 5,

          keywordSales: 26
        },
        {
          date: '2024-06-02',
          searchVolume: 2982,
          organicRank: 4,

          keywordSales: 26
        },
        {
          date: '2024-06-01',
          searchVolume: 2982,
          organicRank: 5,
          sponsoredRank: 47,
          keywordSales: 26
        },
        {
          date: '2024-05-31',
          searchVolume: 2773,
          organicRank: 7,
          sponsoredRank: 19,
          keywordSales: 26
        },
        {
          date: '2024-05-30',
          searchVolume: 2773,
          organicRank: 5,
          sponsoredRank: 30,
          keywordSales: 26
        },
        {
          date: '2024-05-29',
          searchVolume: 2773,
          organicRank: 6,
          sponsoredRank: 47,
          keywordSales: 26
        },
        {
          date: '2024-05-28',
          searchVolume: 2773,
          organicRank: 7,

          keywordSales: 26
        },
        {
          date: '2024-05-27',
          searchVolume: 2773,
          organicRank: 6,
          sponsoredRank: 66,
          keywordSales: 26
        },
        {
          date: '2024-05-26',
          searchVolume: 2773,
          organicRank: 5,
          sponsoredRank: 17,
          keywordSales: 26
        },
        {
          date: '2024-05-25',
          searchVolume: 2773,
          organicRank: 5,
          sponsoredRank: 9,
          keywordSales: 26
        },
        {
          date: '2024-05-24',
          searchVolume: 2509,
          organicRank: 5,
          sponsoredRank: 44,
          keywordSales: 26
        },
        {
          date: '2024-05-23',
          searchVolume: 2509,
          organicRank: 5,

          keywordSales: 26
        },
        {
          date: '2024-05-22',
          searchVolume: 2509,
          organicRank: 5,

          keywordSales: 26
        },
        {
          date: '2024-05-21',
          searchVolume: 2509,
          organicRank: 7,

          keywordSales: 26
        },
        {
          date: '2024-05-20',
          searchVolume: 2509,
          organicRank: 11,

          keywordSales: 26
        },
        {
          date: '2024-05-19',
          searchVolume: 2509,
          organicRank: 7,

          keywordSales: 26
        },
        {
          date: '2024-05-18',
          searchVolume: 2509,
          organicRank: 7,
          sponsoredRank: 35,
          keywordSales: 26
        },
        {
          date: '2024-05-17',
          searchVolume: 2509,
          organicRank: 7,
          sponsoredRank: 41,
          keywordSales: 26
        },
        {
          date: '2024-05-16',
          searchVolume: 2509,
          organicRank: 5,

          keywordSales: 26
        },
        {
          date: '2024-05-15',
          searchVolume: 2509,
          organicRank: 7,

          keywordSales: 26
        },
        {
          date: '2024-05-14',
          searchVolume: 2509,
          organicRank: 6,

          keywordSales: 26
        },
        {
          date: '2024-05-13',
          searchVolume: 2509,
          organicRank: 6,
          sponsoredRank: 17,
          keywordSales: 26
        },
        {
          date: '2024-05-12',
          searchVolume: 2509,
          organicRank: 5,
          sponsoredRank: 53,
          keywordSales: 26
        },
        {
          date: '2024-05-11',
          searchVolume: 2509,
          organicRank: 5,
          sponsoredRank: 59,
          keywordSales: 26
        },
        {
          date: '2024-05-10',
          searchVolume: 3429,
          organicRank: 32,
          sponsoredRank: 66,
          keywordSales: 26
        },
        {
          date: '2024-05-09',
          searchVolume: 3429,
          organicRank: 5,
          sponsoredRank: 35,
          keywordSales: 26
        },
        {
          date: '2024-05-08',
          searchVolume: 3429,
          organicRank: 5,
          sponsoredRank: 62,
          keywordSales: 26
        },
        {
          date: '2024-05-07',
          searchVolume: 3429,
          organicRank: 6,
          sponsoredRank: 42,
          keywordSales: 26
        },
        {
          date: '2024-05-06',
          searchVolume: 3429,
          organicRank: 4,

          keywordSales: 26
        },
        {
          date: '2024-05-05',
          searchVolume: 3429,
          organicRank: 5,

          keywordSales: 26
        },
        {
          date: '2024-05-04',
          searchVolume: 3429,
          organicRank: 6,
          sponsoredRank: 35,
          keywordSales: 26
        },
        {
          date: '2024-05-03',
          searchVolume: 3000,
          organicRank: 6,
          sponsoredRank: 18,
          keywordSales: 26
        },
        {
          date: '2024-05-02',
          searchVolume: 3000,
          organicRank: 5,
          sponsoredRank: 36,
          keywordSales: 26
        },
        {
          date: '2024-05-01',
          searchVolume: 3000,
          organicRank: 5,

          keywordSales: 26
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_travel keyboard',
      keyword: 'travel keyboard',
      searchVolume: 3618,
      organicStartRank: 168,
      organicHighestRank: 168,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-20',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-19',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-18',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-17',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-16',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-15',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-14',
          searchVolume: 3930,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-13',
          searchVolume: 3930,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-12',
          searchVolume: 3930,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-11',
          searchVolume: 3930,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-10',
          searchVolume: 3930,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-09',
          searchVolume: 3930,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-08',
          searchVolume: 3930,
          organicRank: 168,

          keywordSales: 59
        },
        {
          date: '2024-06-07',
          searchVolume: 3306,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-06',
          searchVolume: 3306,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-05',
          searchVolume: 3306,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-04',
          searchVolume: 3306,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-03',
          searchVolume: 3306,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-02',
          searchVolume: 3306,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-01',
          searchVolume: 3306,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-31',
          searchVolume: 3826,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-30',
          searchVolume: 3826,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-29',
          searchVolume: 3826,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-28',
          searchVolume: 3826,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-27',
          searchVolume: 3826,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-26',
          searchVolume: 3826,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-25',
          searchVolume: 3826,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-24',
          searchVolume: 3572,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-23',
          searchVolume: 3572,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-22',
          searchVolume: 3572,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-21',
          searchVolume: 3572,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-20',
          searchVolume: 3572,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-19',
          searchVolume: 3572,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-18',
          searchVolume: 3572,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-17',
          searchVolume: 3416,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-16',
          searchVolume: 3416,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-15',
          searchVolume: 3416,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-14',
          searchVolume: 3416,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-13',
          searchVolume: 3416,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-12',
          searchVolume: 3416,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-11',
          searchVolume: 3416,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-10',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-09',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-08',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-07',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-06',
          searchVolume: 3618,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-02',
          searchVolume: 3641,
          organicRank: 168,

          keywordSales: 59
        },
        {
          date: '2024-05-01',
          searchVolume: 3641,
          organicRank: 173,

          keywordSales: 59
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_wired mechanical keyboard',
      keyword: 'wired mechanical keyboard',
      searchVolume: 1408,
      organicStartRank: 259,
      organicHighestRank: 54,
      sponsoredStartRank: 74,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-11',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 1412,
          organicRank: 71,
          sponsoredRank: 61,
          keywordSales: 8
        },
        {
          date: '2024-06-20',
          searchVolume: 1412,
          organicRank: 75,

          keywordSales: 8
        },
        {
          date: '2024-06-19',
          searchVolume: 1412,
          organicRank: 85,
          sponsoredRank: 56,
          keywordSales: 8
        },
        {
          date: '2024-06-18',
          searchVolume: 1412,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 8
        },
        {
          date: '2024-06-17',
          searchVolume: 1412,
          organicRank: 77,
          sponsoredRank: 26,
          keywordSales: 8
        },
        {
          date: '2024-06-16',
          searchVolume: 1412,
          organicRank: 170,

          keywordSales: 8
        },
        {
          date: '2024-06-15',
          searchVolume: 1412,
          organicRank: 210,

          keywordSales: 8
        },
        {
          date: '2024-06-14',
          searchVolume: 1430,
          organicRank: 101,

          keywordSales: 8
        },
        {
          date: '2024-06-13',
          searchVolume: 1430,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-06-12',
          searchVolume: 1430,
          organicRank: 194,

          keywordSales: 8
        },
        {
          date: '2024-06-11',
          searchVolume: 1430,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-06-10',
          searchVolume: 1430,
          organicRank: 233,
          sponsoredRank: 90,
          keywordSales: 8
        },
        {
          date: '2024-06-09',
          searchVolume: 1430,
          organicRank: 256,
          sponsoredRank: 37,
          keywordSales: 8
        },
        {
          date: '2024-06-08',
          searchVolume: 1430,
          organicRank: 306,
          sponsoredRank: 4,
          keywordSales: 8
        },
        {
          date: '2024-06-07',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-06-06',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-06-05',
          searchVolume: 1366,
          organicRank: 103,

          keywordSales: 8
        },
        {
          date: '2024-06-04',
          searchVolume: 1366,
          organicRank: 174,

          keywordSales: 8
        },
        {
          date: '2024-06-03',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-06-02',
          searchVolume: 1366,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-06-01',
          searchVolume: 1366,
          organicRank: 198,

          keywordSales: 8
        },
        {
          date: '2024-05-31',
          searchVolume: 1410,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 8
        },
        {
          date: '2024-05-30',
          searchVolume: 1410,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-05-29',
          searchVolume: 1410,
          organicRank: 59,

          keywordSales: 8
        },
        {
          date: '2024-05-28',
          searchVolume: 1410,
          organicRank: 91,

          keywordSales: 8
        },
        {
          date: '2024-05-27',
          searchVolume: 1410,
          organicRank: 54,

          keywordSales: 8
        },
        {
          date: '2024-05-26',
          searchVolume: 1410,
          organicRank: 192,

          keywordSales: 8
        },
        {
          date: '2024-05-25',
          searchVolume: 1410,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 8
        },
        {
          date: '2024-05-24',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-05-23',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-05-22',
          searchVolume: 1408,
          organicRank: 246,
          sponsoredRank: 86,
          keywordSales: 8
        },
        {
          date: '2024-05-21',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-05-20',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-05-19',
          searchVolume: 1408,
          organicRank: 163,

          keywordSales: 8
        },
        {
          date: '2024-05-18',
          searchVolume: 1408,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-05-17',
          searchVolume: 1406,
          organicRank: 181,
          sponsoredRank: 38,
          keywordSales: 8
        },
        {
          date: '2024-05-16',
          searchVolume: 1406,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-05-15',
          searchVolume: 1406,
          organicRank: 198,

          keywordSales: 8
        },
        {
          date: '2024-05-14',
          searchVolume: 1406,
          organicRank: 138,

          keywordSales: 8
        },
        {
          date: '2024-05-13',
          searchVolume: 1406,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-05-12',
          searchVolume: 1406,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 8
        },
        {
          date: '2024-05-11',
          searchVolume: 1406,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 8
        },
        {
          date: '2024-05-10',
          searchVolume: 1609,
          organicRank: 130,

          keywordSales: 8
        },
        {
          date: '2024-05-09',
          searchVolume: 1609,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 8
        },
        {
          date: '2024-05-08',
          searchVolume: 1609,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 8
        },
        {
          date: '2024-05-07',
          searchVolume: 1609,
          organicRank: 264,

          keywordSales: 8
        },
        {
          date: '2024-05-06',
          searchVolume: 1609,
          organicRank: 306,

          keywordSales: 8
        },
        {
          date: '2024-05-05',
          searchVolume: 1609,
          organicRank: 259,

          keywordSales: 8
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_wireless gaming keyboard',
      keyword: 'wireless gaming keyboard',
      searchVolume: 13216,
      organicStartRank: 201,
      organicHighestRank: 23,
      sponsoredStartRank: 44,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 13405,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-20',
          searchVolume: 13405,
          organicRank: 246,

          keywordSales: 176
        },
        {
          date: '2024-06-19',
          searchVolume: 13405,
          organicRank: 32,

          keywordSales: 176
        },
        {
          date: '2024-06-18',
          searchVolume: 13405,
          organicRank: 40,
          sponsoredRank: 24,
          keywordSales: 176
        },
        {
          date: '2024-06-17',
          searchVolume: 13405,
          organicRank: 35,

          keywordSales: 176
        },
        {
          date: '2024-06-16',
          searchVolume: 13405,
          organicRank: 28,

          keywordSales: 176
        },
        {
          date: '2024-06-15',
          searchVolume: 13405,
          organicRank: 31,
          sponsoredRank: 18,
          keywordSales: 176
        },
        {
          date: '2024-06-14',
          searchVolume: 13437,
          organicRank: 30,

          keywordSales: 176
        },
        {
          date: '2024-06-13',
          searchVolume: 13437,
          organicRank: 27,

          keywordSales: 176
        },
        {
          date: '2024-06-12',
          searchVolume: 13437,
          organicRank: 29,

          keywordSales: 176
        },
        {
          date: '2024-06-11',
          searchVolume: 13437,
          organicRank: 32,

          keywordSales: 176
        },
        {
          date: '2024-06-10',
          searchVolume: 13437,
          organicRank: 29,

          keywordSales: 176
        },
        {
          date: '2024-06-09',
          searchVolume: 13437,
          organicRank: 32,

          keywordSales: 176
        },
        {
          date: '2024-06-08',
          searchVolume: 13437,
          organicRank: 32,
          sponsoredRank: 58,
          keywordSales: 176
        },
        {
          date: '2024-06-07',
          searchVolume: 12813,
          organicRank: 32,

          keywordSales: 176
        },
        {
          date: '2024-06-06',
          searchVolume: 12813,
          organicRank: 32,

          keywordSales: 176
        },
        {
          date: '2024-06-05',
          searchVolume: 12813,
          organicRank: 33,

          keywordSales: 176
        },
        {
          date: '2024-06-04',
          searchVolume: 12813,
          organicRank: 28,

          keywordSales: 176
        },
        {
          date: '2024-06-03',
          searchVolume: 12813,
          organicRank: 32,

          keywordSales: 176
        },
        {
          date: '2024-06-02',
          searchVolume: 12813,
          organicRank: 33,

          keywordSales: 176
        },
        {
          date: '2024-06-01',
          searchVolume: 12813,
          organicRank: 30,

          keywordSales: 176
        },
        {
          date: '2024-05-31',
          searchVolume: 11575,
          organicRank: 23,
          sponsoredRank: 2,
          keywordSales: 176
        },
        {
          date: '2024-05-30',
          searchVolume: 11575,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 176
        },
        {
          date: '2024-05-29',
          searchVolume: 11575,
          organicRank: 226,

          keywordSales: 176
        },
        {
          date: '2024-05-28',
          searchVolume: 11575,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-05-27',
          searchVolume: 11575,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-05-26',
          searchVolume: 11575,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 176
        },
        {
          date: '2024-05-25',
          searchVolume: 11575,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 176
        },
        {
          date: '2024-05-24',
          searchVolume: 13216,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-05-23',
          searchVolume: 13216,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-05-22',
          searchVolume: 13216,
          organicRank: 306,
          sponsoredRank: 14,
          keywordSales: 176
        },
        {
          date: '2024-05-21',
          searchVolume: 13216,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-05-20',
          searchVolume: 13216,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-05-19',
          searchVolume: 13216,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-05-18',
          searchVolume: 13216,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-05-17',
          searchVolume: 13200,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 176
        },
        {
          date: '2024-05-16',
          searchVolume: 13200,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 176
        },
        {
          date: '2024-05-15',
          searchVolume: 13200,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 176
        },
        {
          date: '2024-05-14',
          searchVolume: 13200,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 176
        },
        {
          date: '2024-05-13',
          searchVolume: 13200,
          organicRank: 306,
          sponsoredRank: 78,
          keywordSales: 176
        },
        {
          date: '2024-05-12',
          searchVolume: 13200,
          organicRank: 306,
          sponsoredRank: 86,
          keywordSales: 176
        },
        {
          date: '2024-05-11',
          searchVolume: 13200,
          organicRank: 306,
          sponsoredRank: 41,
          keywordSales: 176
        },
        {
          date: '2024-05-10',
          searchVolume: 13441,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 176
        },
        {
          date: '2024-05-09',
          searchVolume: 13441,
          organicRank: 306,
          sponsoredRank: 91,
          keywordSales: 176
        },
        {
          date: '2024-05-08',
          searchVolume: 13441,
          organicRank: 306,
          sponsoredRank: 78,
          keywordSales: 176
        },
        {
          date: '2024-05-07',
          searchVolume: 13441,
          organicRank: 306,
          sponsoredRank: 53,
          keywordSales: 176
        },
        {
          date: '2024-05-06',
          searchVolume: 13441,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 176
        },
        {
          date: '2024-05-05',
          searchVolume: 13441,
          organicRank: 201,

          keywordSales: 176
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_wireless gaming keyboard and mouse',
      keyword: 'wireless gaming keyboard and mouse',
      searchVolume: 4257,
      organicStartRank: 306,
      organicHighestRank: 219,
      sponsoredStartRank: 79,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-16',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-19',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-18',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-17',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-16',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-15',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-14',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-13',
          searchVolume: 4322,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 70
        },
        {
          date: '2024-06-12',
          searchVolume: 4322,
          organicRank: 239,

          keywordSales: 70
        },
        {
          date: '2024-06-11',
          searchVolume: 4322,
          organicRank: 259,

          keywordSales: 70
        },
        {
          date: '2024-06-10',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-09',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-08',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-07',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-06',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-05',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-04',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-03',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-02',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-06-01',
          searchVolume: 4124,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-31',
          searchVolume: 4259,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-30',
          searchVolume: 4259,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-29',
          searchVolume: 4259,
          organicRank: 219,

          keywordSales: 70
        },
        {
          date: '2024-05-28',
          searchVolume: 4259,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-27',
          searchVolume: 4259,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-26',
          searchVolume: 4259,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 70
        },
        {
          date: '2024-05-25',
          searchVolume: 4259,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-24',
          searchVolume: 4257,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-23',
          searchVolume: 4257,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-22',
          searchVolume: 4257,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-21',
          searchVolume: 4257,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-20',
          searchVolume: 4257,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-19',
          searchVolume: 4257,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-18',
          searchVolume: 4257,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-17',
          searchVolume: 4249,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-16',
          searchVolume: 4249,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 70
        },
        {
          date: '2024-05-15',
          searchVolume: 4249,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-14',
          searchVolume: 4249,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-13',
          searchVolume: 4249,
          organicRank: 306,
          sponsoredRank: 88,
          keywordSales: 70
        },
        {
          date: '2024-05-12',
          searchVolume: 4249,
          organicRank: 306,
          sponsoredRank: 91,
          keywordSales: 70
        },
        {
          date: '2024-05-11',
          searchVolume: 4249,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 70
        },
        {
          date: '2024-05-10',
          searchVolume: 4547,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 70
        },
        {
          date: '2024-05-09',
          searchVolume: 4547,
          organicRank: 306,
          sponsoredRank: 86,
          keywordSales: 70
        },
        {
          date: '2024-05-08',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 70
        },
        {
          date: '2024-05-07',
          searchVolume: 4547,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 70
        },
        {
          date: '2024-05-06',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 70
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_wireless keyboard',
      keyword: 'wireless keyboard',
      searchVolume: 166344,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 86,
      sponsoredHighestRank: 18,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 156173,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-20',
          searchVolume: 156173,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-19',
          searchVolume: 156173,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-18',
          searchVolume: 156173,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-17',
          searchVolume: 156173,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-16',
          searchVolume: 156173,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-15',
          searchVolume: 156173,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-14',
          searchVolume: 168935,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-13',
          searchVolume: 168935,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-12',
          searchVolume: 168935,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-11',
          searchVolume: 168935,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-10',
          searchVolume: 168935,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-09',
          searchVolume: 168935,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-08',
          searchVolume: 168935,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-07',
          searchVolume: 148335,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-06',
          searchVolume: 148335,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-05',
          searchVolume: 148335,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-04',
          searchVolume: 148335,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-03',
          searchVolume: 148335,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-02',
          searchVolume: 148335,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-06-01',
          searchVolume: 148335,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-31',
          searchVolume: 155914,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-30',
          searchVolume: 155914,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-29',
          searchVolume: 155914,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-28',
          searchVolume: 155914,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-27',
          searchVolume: 155914,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-26',
          searchVolume: 155914,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-25',
          searchVolume: 155914,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-24',
          searchVolume: 166344,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-23',
          searchVolume: 166344,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-22',
          searchVolume: 166344,
          organicRank: 306,
          sponsoredRank: 80,
          keywordSales: 3020
        },
        {
          date: '2024-05-21',
          searchVolume: 166344,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 3020
        },
        {
          date: '2024-05-20',
          searchVolume: 166344,
          organicRank: 306,
          sponsoredRank: 18,
          keywordSales: 3020
        },
        {
          date: '2024-05-19',
          searchVolume: 166344,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-18',
          searchVolume: 166344,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-17',
          searchVolume: 166080,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 3020
        },
        {
          date: '2024-05-16',
          searchVolume: 166080,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 3020
        },
        {
          date: '2024-05-15',
          searchVolume: 166080,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-14',
          searchVolume: 166080,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 3020
        },
        {
          date: '2024-05-13',
          searchVolume: 166080,
          organicRank: 306,
          sponsoredRank: 80,
          keywordSales: 3020
        },
        {
          date: '2024-05-12',
          searchVolume: 166080,
          organicRank: 306,
          sponsoredRank: 82,
          keywordSales: 3020
        },
        {
          date: '2024-05-11',
          searchVolume: 166080,
          organicRank: 306,
          sponsoredRank: 86,
          keywordSales: 3020
        },
        {
          date: '2024-05-10',
          searchVolume: 168948,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-09',
          searchVolume: 168948,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-08',
          searchVolume: 168948,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-07',
          searchVolume: 168948,
          organicRank: 306,

          keywordSales: 3020
        },
        {
          date: '2024-05-06',
          searchVolume: 168948,
          organicRank: 306,

          keywordSales: 3020
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_wireless mechanical keyboard',
      keyword: 'wireless mechanical keyboard',
      searchVolume: 13925,
      organicStartRank: 222,
      organicHighestRank: 55,
      sponsoredStartRank: 60,
      sponsoredHighestRank: 19,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-21',
          searchVolume: 14157,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-20',
          searchVolume: 14157,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-19',
          searchVolume: 14157,
          organicRank: 69,

          keywordSales: 53
        },
        {
          date: '2024-06-18',
          searchVolume: 14157,
          organicRank: 83,

          keywordSales: 53
        },
        {
          date: '2024-06-17',
          searchVolume: 14157,
          organicRank: 81,

          keywordSales: 53
        },
        {
          date: '2024-06-16',
          searchVolume: 14157,
          organicRank: 55,

          keywordSales: 53
        },
        {
          date: '2024-06-15',
          searchVolume: 14157,
          organicRank: 64,
          sponsoredRank: 84,
          keywordSales: 53
        },
        {
          date: '2024-06-14',
          searchVolume: 14180,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-13',
          searchVolume: 14180,
          organicRank: 212,

          keywordSales: 53
        },
        {
          date: '2024-06-12',
          searchVolume: 14180,
          organicRank: 200,

          keywordSales: 53
        },
        {
          date: '2024-06-11',
          searchVolume: 14180,
          organicRank: 181,

          keywordSales: 53
        },
        {
          date: '2024-06-10',
          searchVolume: 14180,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-09',
          searchVolume: 14180,
          organicRank: 306,
          sponsoredRank: 78,
          keywordSales: 53
        },
        {
          date: '2024-06-08',
          searchVolume: 14180,
          organicRank: 129,

          keywordSales: 53
        },
        {
          date: '2024-06-07',
          searchVolume: 13470,
          organicRank: 216,

          keywordSales: 53
        },
        {
          date: '2024-06-06',
          searchVolume: 13470,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-06-05',
          searchVolume: 13470,
          organicRank: 183,
          sponsoredRank: 31,
          keywordSales: 53
        },
        {
          date: '2024-06-04',
          searchVolume: 13470,
          organicRank: 234,

          keywordSales: 53
        },
        {
          date: '2024-06-03',
          searchVolume: 13470,
          organicRank: 212,

          keywordSales: 53
        },
        {
          date: '2024-06-02',
          searchVolume: 13470,
          organicRank: 171,

          keywordSales: 53
        },
        {
          date: '2024-06-01',
          searchVolume: 13470,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-31',
          searchVolume: 13248,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 53
        },
        {
          date: '2024-05-30',
          searchVolume: 13248,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-29',
          searchVolume: 13248,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-28',
          searchVolume: 13248,
          organicRank: 94,

          keywordSales: 53
        },
        {
          date: '2024-05-27',
          searchVolume: 13248,
          organicRank: 99,

          keywordSales: 53
        },
        {
          date: '2024-05-26',
          searchVolume: 13248,
          organicRank: 306,
          sponsoredRank: 19,
          keywordSales: 53
        },
        {
          date: '2024-05-25',
          searchVolume: 13248,
          organicRank: 202,

          keywordSales: 53
        },
        {
          date: '2024-05-24',
          searchVolume: 13925,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-23',
          searchVolume: 13925,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-22',
          searchVolume: 13925,
          organicRank: 99,

          keywordSales: 53
        },
        {
          date: '2024-05-21',
          searchVolume: 13925,
          organicRank: 90,

          keywordSales: 53
        },
        {
          date: '2024-05-20',
          searchVolume: 13925,
          organicRank: 179,

          keywordSales: 53
        },
        {
          date: '2024-05-19',
          searchVolume: 13925,
          organicRank: 179,

          keywordSales: 53
        },
        {
          date: '2024-05-18',
          searchVolume: 13925,
          organicRank: 88,

          keywordSales: 53
        },
        {
          date: '2024-05-17',
          searchVolume: 13922,
          organicRank: 78,
          sponsoredRank: 44,
          keywordSales: 53
        },
        {
          date: '2024-05-16',
          searchVolume: 13922,
          organicRank: 67,

          keywordSales: 53
        },
        {
          date: '2024-05-15',
          searchVolume: 13922,
          organicRank: 306,

          keywordSales: 53
        },
        {
          date: '2024-05-14',
          searchVolume: 13922,
          organicRank: 192,
          sponsoredRank: 20,
          keywordSales: 53
        },
        {
          date: '2024-05-12',
          searchVolume: 13922,
          organicRank: 175,
          sponsoredRank: 42,
          keywordSales: 53
        },
        {
          date: '2024-05-11',
          searchVolume: 13922,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 53
        },
        {
          date: '2024-05-10',
          searchVolume: 14161,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 53
        },
        {
          date: '2024-05-09',
          searchVolume: 14161,
          organicRank: 234,
          sponsoredRank: 42,
          keywordSales: 53
        },
        {
          date: '2024-05-08',
          searchVolume: 14161,
          organicRank: 306,
          sponsoredRank: 83,
          keywordSales: 53
        },
        {
          date: '2024-05-07',
          searchVolume: 14161,
          organicRank: 306,
          sponsoredRank: 80,
          keywordSales: 53
        },
        {
          date: '2024-05-06',
          searchVolume: 14161,
          organicRank: 167,

          keywordSales: 53
        },
        {
          date: '2024-05-05',
          searchVolume: 14161,
          organicRank: 239,
          sponsoredRank: 31,
          keywordSales: 53
        },
        {
          date: '2024-05-04',
          searchVolume: 14161,
          organicRank: 222,
          sponsoredRank: 60,
          keywordSales: 53
        },
        {
          date: '2024-05-03',
          searchVolume: 15826,
          organicRank: 233,
          sponsoredRank: 23,
          keywordSales: 53
        },
        {
          date: '2024-05-02',
          searchVolume: 15826,
          organicRank: 236,
          sponsoredRank: 24,
          keywordSales: 53
        },
        {
          date: '2024-05-01',
          searchVolume: 15826,
          organicRank: 245,
          sponsoredRank: 61,
          keywordSales: 53
        }
      ]
    },
    {
      campaignId: 'd2968f8e-6c4d-4134-be37-8be9ebd28097',
      campaignId_keyword: 'd2968f8e-6c4d-4134-be37-8be9ebd28097_xbox keyboard',
      keyword: 'xbox keyboard',
      searchVolume: 1659,
      organicStartRank: 42,
      organicHighestRank: 32,
      sponsoredStartRank: 34,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-06-20',
          searchVolume: 1609,
          organicRank: 306,

          keywordSales: 55
        },
        {
          date: '2024-06-19',
          searchVolume: 1609,
          organicRank: 306,
          sponsoredRank: 3,
          keywordSales: 55
        },
        {
          date: '2024-06-18',
          searchVolume: 1609,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 55
        },
        {
          date: '2024-06-17',
          searchVolume: 1609,
          organicRank: 306,
          sponsoredRank: 37,
          keywordSales: 55
        },
        {
          date: '2024-06-16',
          searchVolume: 1609,
          organicRank: 117,

          keywordSales: 55
        },
        {
          date: '2024-06-15',
          searchVolume: 1609,
          organicRank: 108,
          sponsoredRank: 23,
          keywordSales: 55
        },
        {
          date: '2024-06-14',
          searchVolume: 1852,
          organicRank: 306,
          sponsoredRank: 28,
          keywordSales: 55
        },
        {
          date: '2024-06-13',
          searchVolume: 1852,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 55
        },
        {
          date: '2024-06-12',
          searchVolume: 1852,
          organicRank: 306,

          keywordSales: 55
        },
        {
          date: '2024-06-11',
          searchVolume: 1852,
          organicRank: 306,

          keywordSales: 55
        },
        {
          date: '2024-06-10',
          searchVolume: 1852,
          organicRank: 306,

          keywordSales: 55
        },
        {
          date: '2024-06-09',
          searchVolume: 1852,
          organicRank: 190,
          sponsoredRank: 21,
          keywordSales: 55
        },
        {
          date: '2024-06-08',
          searchVolume: 1852,
          organicRank: 306,

          keywordSales: 55
        },
        {
          date: '2024-06-07',
          searchVolume: 1761,
          organicRank: 306,

          keywordSales: 55
        },
        {
          date: '2024-06-06',
          searchVolume: 1761,
          organicRank: 306,

          keywordSales: 55
        },
        {
          date: '2024-06-05',
          searchVolume: 1761,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 55
        },
        {
          date: '2024-06-04',
          searchVolume: 1761,
          organicRank: 306,

          keywordSales: 55
        },
        {
          date: '2024-06-03',
          searchVolume: 1761,
          organicRank: 84,

          keywordSales: 55
        },
        {
          date: '2024-06-02',
          searchVolume: 1761,
          organicRank: 85,
          sponsoredRank: 38,
          keywordSales: 55
        },
        {
          date: '2024-06-01',
          searchVolume: 1761,
          organicRank: 85,
          sponsoredRank: 47,
          keywordSales: 55
        },
        {
          date: '2024-05-31',
          searchVolume: 1633,
          organicRank: 81,
          sponsoredRank: 7,
          keywordSales: 55
        },
        {
          date: '2024-05-30',
          searchVolume: 1633,
          organicRank: 306,

          keywordSales: 55
        },
        {
          date: '2024-05-29',
          searchVolume: 1633,
          organicRank: 93,

          keywordSales: 55
        },
        {
          date: '2024-05-28',
          searchVolume: 1633,
          organicRank: 91,
          sponsoredRank: 47,
          keywordSales: 55
        },
        {
          date: '2024-05-27',
          searchVolume: 1633,
          organicRank: 73,
          sponsoredRank: 62,
          keywordSales: 55
        },
        {
          date: '2024-05-26',
          searchVolume: 1633,
          organicRank: 87,
          sponsoredRank: 49,
          keywordSales: 55
        },
        {
          date: '2024-05-25',
          searchVolume: 1633,
          organicRank: 90,
          sponsoredRank: 53,
          keywordSales: 55
        },
        {
          date: '2024-05-24',
          searchVolume: 1659,
          organicRank: 85,

          keywordSales: 55
        },
        {
          date: '2024-05-23',
          searchVolume: 1659,
          organicRank: 79,

          keywordSales: 55
        },
        {
          date: '2024-05-22',
          searchVolume: 1659,
          organicRank: 85,
          sponsoredRank: 29,
          keywordSales: 55
        },
        {
          date: '2024-05-21',
          searchVolume: 1659,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 55
        },
        {
          date: '2024-05-20',
          searchVolume: 1659,
          organicRank: 86,

          keywordSales: 55
        },
        {
          date: '2024-05-19',
          searchVolume: 1659,
          organicRank: 47,

          keywordSales: 55
        },
        {
          date: '2024-05-18',
          searchVolume: 1659,
          organicRank: 48,
          sponsoredRank: 27,
          keywordSales: 55
        },
        {
          date: '2024-05-17',
          searchVolume: 1821,
          organicRank: 39,
          sponsoredRank: 31,
          keywordSales: 55
        },
        {
          date: '2024-05-16',
          searchVolume: 1821,
          organicRank: 39,

          keywordSales: 55
        },
        {
          date: '2024-05-15',
          searchVolume: 1821,
          organicRank: 35,

          keywordSales: 55
        },
        {
          date: '2024-05-14',
          searchVolume: 1821,
          organicRank: 37,

          keywordSales: 55
        },
        {
          date: '2024-05-13',
          searchVolume: 1821,
          organicRank: 33,
          sponsoredRank: 56,
          keywordSales: 55
        },
        {
          date: '2024-05-12',
          searchVolume: 1821,
          organicRank: 306,
          sponsoredRank: 78,
          keywordSales: 55
        },
        {
          date: '2024-05-11',
          searchVolume: 1821,
          organicRank: 37,
          sponsoredRank: 72,
          keywordSales: 55
        },
        {
          date: '2024-05-10',
          searchVolume: 1663,
          organicRank: 33,

          keywordSales: 55
        },
        {
          date: '2024-05-09',
          searchVolume: 1663,
          organicRank: 60,

          keywordSales: 55
        },
        {
          date: '2024-05-08',
          searchVolume: 1663,
          organicRank: 34,

          keywordSales: 55
        },
        {
          date: '2024-05-07',
          searchVolume: 1663,
          organicRank: 32,

          keywordSales: 55
        },
        {
          date: '2024-05-06',
          searchVolume: 1663,
          organicRank: 44,
          sponsoredRank: 83,
          keywordSales: 55
        },
        {
          date: '2024-05-04',
          searchVolume: 1663,
          organicRank: 42,

          keywordSales: 55
        },
        {
          date: '2024-05-03',
          searchVolume: 1830,
          organicRank: 29,
          sponsoredRank: 34,
          keywordSales: 55
        },
        {
          date: '2024-05-02',
          searchVolume: 1830,
          organicRank: 32,

          keywordSales: 55
        },
        {
          date: '2024-05-01',
          searchVolume: 1830,
          organicRank: 31,

          keywordSales: 55
        }
      ]
    }
  ]
}

import { ChildFormProps } from './types'
import { withFormProvider } from './withFormProvider'

const Form = ({ onSubmit, autocomplete, children }: ChildFormProps) => (
  <form autoComplete={autocomplete} onSubmit={onSubmit}>
    {children}
  </form>
)

export default withFormProvider(Form)
export * from './withFormProvider'

import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTES, ROUTE_NAMES_BRAND } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Tab, Tabs } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFlag } from '@unleash/proxy-client-react'
import { useEffect } from 'react'
import { Link as RouterLink, generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

const useStyles = makeStyles({
  tab: {
    textTransform: 'none'
  }
})

const BrandTabs = () => {
  const { brandId: id } = useParams<{ brandId: string }>()
  const { isAdminView } = useUserSessionContext()
  const { recommendations, proposals, campaigns } = useBrandContext()
  const navigate = useNavigate()
  const basePath = generatePath(ROUTES.BRAND, { brandId: id })
  const brandProposalsFlag = useFlag('BrandProposals')
  const recommendationsFlag = useFlag('Recommendations')

  const { pathname } = useLocation()
  const routeMatch = useRouteMatch([
    `${basePath}/${ROUTE_NAMES_BRAND.PROPOSALS}`,
    `${basePath}/${ROUTE_NAMES_BRAND.CAMPAIGNS}`,
    `${basePath}/${ROUTE_NAMES_BRAND.RECOMMENDATIONS}`,
    `${basePath}/${ROUTE_NAMES_BRAND.PRODUCTS}`
  ])

  const currentTab =
    routeMatch?.pattern?.path || `${basePath}/${getDefaultView(isAdminView, !!proposals?.length, !!campaigns?.length)}`

  const classes = useStyles()

  useEffect(() => {
    if (pathname === basePath && proposals && campaigns) {
      navigate(`${basePath}/${getDefaultView(isAdminView, !!proposals.length, !!campaigns.length)}`, {
        replace: true
      })
    }
  }, [pathname, proposals, recommendations, campaigns])

  // const recommendationCount = recommendations.map(r => r.recommendations).flat().length

  return (
    <Tabs value={currentTab}>
      {(isAdminView || recommendationsFlag) && (
        <Tab
          data-cy={'recommendationsTab'}
          sx={{
            pr: 0
          }}
          label={
            // <Badge
            //   badgeContent={recommendationCount}
            //   color="primary"
            //   slotProps={recommendationCount ? { badge: { style: { right: -18, top: 8 } } } : {}}
            //   sx={recommendationCount ? { mr: 4.5 } : {}}
            // >
            'Recommendations'
            // </Badge>
          }
          value={`${basePath}/${ROUTE_NAMES_BRAND.RECOMMENDATIONS}`}
          to={ROUTE_NAMES_BRAND.RECOMMENDATIONS}
          component={RouterLink}
          className={classes.tab}
        />
      )}
      {(isAdminView || brandProposalsFlag) && (
        <Tab
          data-cy={'proposalsTab'}
          label="Proposals"
          value={`${basePath}/${ROUTE_NAMES_BRAND.PROPOSALS}`}
          to={ROUTE_NAMES_BRAND.PROPOSALS}
          component={RouterLink}
          className={classes.tab}
        />
      )}
      <Tab
        data-cy={'campaignsTab'}
        label="Campaigns"
        value={`${basePath}/${ROUTE_NAMES_BRAND.CAMPAIGNS}`}
        to={ROUTE_NAMES_BRAND.CAMPAIGNS}
        component={RouterLink}
        className={classes.tab}
      />
    </Tabs>
  )
}

export default BrandTabs

const getDefaultView = (isAdminView: boolean, hasDrafts: boolean, hasCampaigns: boolean) => {
  if (hasCampaigns) {
    return ROUTE_NAMES_BRAND.CAMPAIGNS
  }

  if (isAdminView && !hasCampaigns) {
    return ROUTE_NAMES_BRAND.RECOMMENDATIONS
  }

  return ROUTE_NAMES_BRAND.PROPOSALS
}

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}

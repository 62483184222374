import { useFieldArray, useFormContext } from 'react-hook-form'
import EmptyBox from './EmptyBox'
import FinancialFlowRow from './FinancialFlowRow'
import { Box, Stack, Button } from '@mui/material'
import { NumberInput, SelectInput } from '@productwindtom/ui-base'
import { Add } from '@mui/icons-material'
import CloseButton from '@momentum/components/close-button'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { ContentTypeToSocialString } from '@momentum/utils/socialUtils'
import { v4 } from 'uuid'
import { FinancialFlowSummaryType } from '@momentum/routes/proposals-create/context/types'

const FinancialContentRequirements = ({
  flowSummary,
  disabled
}: {
  flowSummary: FinancialFlowSummaryType[]
  disabled?: boolean
}) => {
  const { control, getValues, reset } = useFormContext<ProposalCreateForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contentRequirements'
  })

  const handleRemoveRow = (index: number) => {
    const values = getValues()

    reset({
      ...values,
      creatorPricing: values.creatorPricing.map(cp => {
        const socialCreatorPricing = cp?.socialCreatorPricing?.map(scp => ({
          ...scp,
          contentRequirements: scp?.contentRequirements?.filter((_, requirementIndex) => requirementIndex !== index)
        }))

        const contentRequirements = cp.contentRequirements?.filter((_, requirementIndex) => requirementIndex !== index)

        return {
          ...cp,
          socialCreatorPricing,
          contentRequirements
        }
      })
    })

    remove(index)
  }

  return (
    <Stack spacing={1}>
      {fields.map((field, index) => {
        return (
          <Stack spacing={1} key={field.id} position={'relative'}>
            {!disabled && (
              <CloseButton
                iconButtonProps={{
                  onClick: () => handleRemoveRow(index),
                  sx: {
                    position: 'absolute',
                    right: '100%',
                    top: '4px'
                  }
                }}
                iconProps={{
                  fontSize: 'small'
                }}
              />
            )}
            <FinancialFlowRow title={'Content type'}>
              {flowSummary.map(flow => (
                <Box key={`${field.id}-${flow.creatorType}`} flex={1} alignItems={'center'} justifyContent={'center'}>
                  {flow.contentTypes ? (
                    <Stack spacing={1}>
                      <SelectInput
                        name={`${flow.inputPath}.contentRequirements.${index}.contentType`}
                        fullWidth
                        options={flow.contentTypes.map(contentType => ({
                          label: ContentTypeToSocialString[contentType],
                          value: contentType
                        }))}
                        disabled={disabled}
                      />
                      <NumberInput
                        InputProps={{ startAdornment: '$' }}
                        name={`${flow.inputPath}.contentRequirements.${index}.bonus`}
                        returnAsNumber
                        returnAsCents
                        disabled={disabled}
                        allowNegative={false}
                      />
                    </Stack>
                  ) : (
                    <Stack spacing={1} height="100%">
                      <EmptyBox />
                      <EmptyBox />
                    </Stack>
                  )}
                </Box>
              ))}
            </FinancialFlowRow>
          </Stack>
        )
      })}
      {!disabled && (
        <Box alignSelf={'flex-start'}>
          <Button
            onClick={() =>
              append({
                id: v4()
              })
            }
            startIcon={<Add />}
          >
            Content Type
          </Button>
        </Box>
      )}
    </Stack>
  )
}

export default FinancialContentRequirements

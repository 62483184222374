import { SvgIcon, SvgIconProps } from '@mui/material'

export default function BonusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 17" fill="none">
      <path
        d="M14.9996 5.83268C14.9996 5.95848 14.9386 6.14578 14.6843 6.38627C14.4286 6.62809 14.0228 6.87659 13.4661 7.09928C12.3564 7.54318 10.7775 7.83264 8.99967 7.83264C7.22183 7.83264 5.643 7.54318 4.53324 7.09928C3.97651 6.87659 3.57072 6.62809 3.31501 6.38627C3.06071 6.14578 2.99972 5.95848 2.99972 5.83268C2.99972 5.70689 3.06071 5.51959 3.31501 5.2791C3.57072 5.03727 3.97651 4.78878 4.53324 4.56609C5.643 4.12218 7.22183 3.83272 8.99967 3.83272C10.7775 3.83272 12.3564 4.12218 13.4661 4.56609C14.0228 4.78878 14.4286 5.03727 14.6843 5.2791C14.9386 5.51959 14.9996 5.70689 14.9996 5.83268Z"
        stroke="currentColor"
        strokeWidth="1.33341"
        fill="none"
      />
      <line x1="2.99972" y1="5.83398" x2="2.99972" y2="11.1673" stroke="#324AA8" strokeWidth="1.33341" />
      <line x1="15.0007" y1="5.83398" x2="15.0007" y2="11.1673" stroke="currentColor" strokeWidth="1.33341" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70695 11.168H2.33398C2.33398 12.6407 5.31875 13.8346 9.00065 13.8346C12.6825 13.8346 15.6673 12.6407 15.6673 11.168H14.2943C14.2775 11.1872 14.2556 11.2103 14.2272 11.2372C14.0532 11.4018 13.7297 11.6114 13.2195 11.8155C12.2064 12.2208 10.7154 12.5012 9.00065 12.5012C7.28591 12.5012 5.79491 12.2208 4.78182 11.8155C4.27158 11.6114 3.94815 11.4018 3.77408 11.2372C3.74567 11.2103 3.72375 11.1872 3.70695 11.168ZM3.6555 11.2407C3.65529 11.2407 3.65608 11.2384 3.65851 11.2338C3.65693 11.2384 3.65571 11.2407 3.6555 11.2407ZM14.3428 11.2338C14.3452 11.2384 14.346 11.2407 14.3458 11.2407C14.3456 11.2407 14.3444 11.2384 14.3428 11.2338Z"
        fill="#324AA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70695 8.5H2.33398C2.33398 9.97276 5.31875 11.1667 9.00065 11.1667C12.6825 11.1667 15.6673 9.97276 15.6673 8.5H14.2943C14.2775 8.51926 14.2556 8.54232 14.2272 8.56919C14.0532 8.7338 13.7297 8.94348 13.2195 9.14757C12.2064 9.55281 10.7154 9.83325 9.00065 9.83325C7.28591 9.83325 5.79491 9.55281 4.78182 9.14757C4.27158 8.94348 3.94815 8.7338 3.77408 8.56919C3.74567 8.54232 3.72375 8.51925 3.70695 8.5ZM3.6555 8.57277C3.65529 8.57277 3.65608 8.57046 3.65851 8.56583C3.65693 8.57046 3.65571 8.57277 3.6555 8.57277ZM14.3428 8.56583C14.3452 8.57045 14.346 8.57277 14.3458 8.57277C14.3456 8.57277 14.3444 8.57046 14.3428 8.56583Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

import { ButtonOwnProps, ChipOwnProps } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import { DraftStatus } from '@productwindtom/shared-momentum-zeus-types'

export const DRAFT_CONTENT_STATUS_CONFIG = {
  [DraftStatus.REQUIRES_BRAND_APPROVAL]: {
    label: 'Pending review',
    chip: {
      label: 'Pending review',
      color: 'secondary' as ChipOwnProps['color']
    },
    button: {
      text: 'Review content',
      color: 'primary' as ButtonOwnProps['color'],
      variant: 'contained' as ButtonOwnProps['variant']
    },
    icon: null
  },
  [DraftStatus.APPROVED]: {
    label: 'Approved',
    button: {
      text: 'View approved content',
      color: 'white1' as ButtonOwnProps['color'],
      variant: 'outlined' as ButtonOwnProps['variant']
    },
    chip: null,
    icon: <CheckCircleOutline fontSize={'mSmall'} color={'success'} />
  },
  [DraftStatus.REQUIRES_USER_CHANGES]: {
    label: 'Requested changes',
    chip: {
      label: 'Edits requested'
    },
    button: {
      text: 'View edit requests',
      color: 'white1' as ButtonOwnProps['color'],
      variant: 'outlined' as ButtonOwnProps['variant']
    },
    icon: null
  }
}

import { ROUTES, ROUTE_NAMES_BRAND } from '@momentum/routes/RouteNames'
import { generatePath, useLocation } from 'react-router-dom'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

export const useClientBreadcrumbs = (
  company?: { id: string; name: string },
  brand?: { id: string; name: string },
  campaign?: { id: string; name: string; skuId: string }
) => {
  const { isAdminView, agency } = useUserSessionContext()
  const agencyId = agency?.id

  const { pathname } = useLocation()
  const isBrandMatch = brand && pathname.includes(generatePath(ROUTES.BRAND, { brandId: brand.id }))

  const adminStartingCrumbs = [
    { label: 'Companies & brands', to: agencyId ? generatePath(ROUTES.AGENCY, { agencyId }) : ROUTES.ADMIN_BASE },
    ...(company
      ? [
          {
            label: company.name,
            to: generatePath(ROUTES.COMPANY, { companyId: company.id })
          }
        ]
      : []),
    ...(brand
      ? [
          {
            label: brand.name,
            to: generatePath([ROUTES.BRAND, ROUTE_NAMES_BRAND.CAMPAIGNS].join('/'), { brandId: brand.id })
          }
        ]
      : [])
  ]

  const nonAdminStartingCrumbs = [
    ...(brand && isBrandMatch
      ? [
          {
            label: 'Campaigns',
            to: generatePath([ROUTES.BRAND, ROUTE_NAMES_BRAND.CAMPAIGNS].join('/'), { brandId: brand.id })
          }
        ]
      : [])
  ]

  return [
    ...(isAdminView ? adminStartingCrumbs : nonAdminStartingCrumbs),
    ...(brand && campaign
      ? [
          {
            label: campaign.name,
            to: generatePath(ROUTES.CAMPAIGN, { brandId: brand.id, campaignId: campaign.id })
          }
        ]
      : [])
  ]
}

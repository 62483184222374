import {SvgIcon, SvgIconProps} from '@mui/material';
;

export default function CheckOutlineIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26"
             fill="none">
      <path d="M1 1H25M1 1V25M1 1L25 25M25 1V25M25 1L1 25M25 25H1"
            stroke="white"
            strokeWidth="0.13"
            fill="none"/>
      <circle cx="13"
              cy="13"
              r="10"
              stroke="white"
              strokeWidth="0.13"
              fill="none"/>
      <rect x="3"
            y="5"
            width="20"
            height="16"
            rx="1"
            stroke="white"
            strokeWidth="0.13"
            fill="none"/>
      <rect x="5"
            y="3"
            width="16"
            height="20"
            rx="1"
            stroke="white"
            strokeWidth="0.13"
            fill="none"/>
      <rect x="4"
            y="4"
            width="18"
            height="18"
            rx="1"
            stroke="white"
            strokeWidth="0.13"
            fill="none"/>
      <circle cx="13"
              cy="13"
              r="4"
              stroke="white"
              strokeWidth="0.13"
              fill="none"/>
      <path d="M9 13.2727L11.8235 16L16.5 11.3235"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"/>
      <circle cx="13"
              cy="13"
              r="9"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"/>
    </SvgIcon>
  );
}




import { sumBy } from 'lodash'
import { DateTime } from 'luxon'

export const getCampaignSeoMetrics = (
  startDate: string,
  seoSummary?: {
    productSeoSummaryRecords: {
      fromDate: string
      organicPageOneWins?: number
      sponsoredPageOneWins?: number
      searchVolume?: number
    }[]
  }
) => {
  const campaignStartWeek = DateTime.fromISO(startDate).startOf('week').toISODate()!
  const filteredRecords = seoSummary?.productSeoSummaryRecords.filter(r => r.fromDate >= campaignStartWeek) ?? []

  const searchVolumeSum = sumBy(filteredRecords, r => r.searchVolume ?? 0)
  const organicPageOneWinsSum = sumBy(filteredRecords, r => r.organicPageOneWins ?? 0)
  const sponsoredPageOneWinsSum = sumBy(filteredRecords, r => r.sponsoredPageOneWins ?? 0)

  return {
    searchVolumeSum,
    organicPageOneWinsSum,
    sponsoredPageOneWinsSum
  }
}

import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { useTrafficContext } from '@momentum/routes/campaign/e-commerce/traffic/trafficContext'
import { Stack, Typography } from '@mui/material'
import { sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { Metric } from '../common/Metric'
import { determineDaysToHitGoal } from '../common/utils'

export const timelineViewGoal = 3000

export const Summary = () => {
  const { campaignDetails } = useCampaignContext()
  const { trafficData, maxDate } = useTrafficContext()

  const sumViews = sumBy(trafficData, 'momentumViews')

  const daysToHitGoal = determineDaysToHitGoal(trafficData, t => t.momentumViews, timelineViewGoal)
  const cpc = sumViews ? campaignDetails.cost / (sumViews || 1) : 0

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant={'h4'}>Campaign traffic summary</Typography>
          <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
            Last updated: {maxDate.toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric
          label={'activated creators'}
          value={campaignDetails.numCreatorsJoined ? campaignDetails.numCreatorsJoined.toLocaleString() : '--'}
        />
        <Metric label={'views from ProductWind'} value={sumViews ? sumViews.toLocaleString() : '--'} />
        <Metric
          label={'CPC'}
          value={
            cpc ? (cpc / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '--'
          }
        />
        <Metric
          label={`to reach ${timelineViewGoal.toLocaleString()} views`}
          value={daysToHitGoal ? `${daysToHitGoal.toLocaleString()} days` : '--'}
        />
      </Stack>
    </Stack>
  )
}

import { useEffect, useState } from 'react'

export default function useResizing() {
  const [isResizing, setIsResizing] = useState(false)
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined
    const handleResize = () => {
      clearTimeout(timeout)

      setIsResizing(true)

      timeout = setTimeout(() => {
        setIsResizing(false)
      }, 150)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isResizing
}

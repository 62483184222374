import { Selector } from '@productwindtom/shared-momentum-zeus-types'

export const creatorContentSelector = Selector('CreatorContent')({
  userCampaignId: true,
  content: {
    groupId: true,
    channel: true,
    contentType: true,
    isHidden: true,
    isFavorite: true,
    submittedAt: true,
    platformContentLink: true,
    createdAt: true,
    content: {
      comments: true,
      caption: true,
      engagements: true,
      id: true,
      likes: true,
      qualityScore: true,
      views: true,
      assets: {
        type: true,
        url: true
      }
    }
  },
  creator: {
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    instagramProfile: {
      handle: true,
      followers: true,
      engagementRate: true
    },
    tiktokProfile: {
      handle: true,
      followers: true,
      engagementRate: true
    },
    youtubeProfile: {
      handle: true,
      followers: true,
      engagementRate: true
    }
  }
})

import { CreatorApprovalStatus, CreatorType, ModelTypes, Region } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CREATORS_FOR_APPROVAL_LAUNDRY: Array<ModelTypes['CampaignCreatorForApproval']> = [
  {
    dateOfBirth: '1990-06-03',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T16:31:12.406Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'Very beauty focused especially on IG'
    },
    userCampaignId: '4e9bbcc6-6a13-451a-83d6-ade05c2f76c8',
    creator: {
      sponsoredPosts: [],
      id: '4e9bbcc6-6a13-451a-83d6-ade05c2f76c8',
      firstName: 'Ana',
      lastName: 'Dourado',
      email: 'karolsullivan1@gmail.com',
      instagramProfile: {
        handle: 'karolsullivan',
        followers: 224687,
        engagementRate: 4.310930316395697,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'karolsullivan1',
        followers: 138907,
        engagementRate: 4.7968797487589905,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1991-12-26',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T17:41:49.673Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'minimal sponsored content, some foul language'
    },
    userCampaignId: '4f56ad44-8cff-4d73-972c-655acdaaec00',
    creator: {
      sponsoredPosts: [],
      id: '4f56ad44-8cff-4d73-972c-655acdaaec00',
      firstName: 'Reyhauna',
      lastName: 'Yates',
      email: 'reyhaunayates@gmail.com',
      instagramProfile: {
        handle: 'lifeofreyhauna',
        followers: 26237,
        engagementRate: 24.10069748827991,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'lifeofreyhauna',
        followers: 74025,
        engagementRate: 7.647674215859116,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'lifeofreyhauna'
      }
    }
  },
  {
    dateOfBirth: '1987-09-27',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T16:30:37.068Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'would like more TikTok reach'
    },
    userCampaignId: 'c33202f1-733f-45ec-8525-ac80339b1c18',
    creator: {
      sponsoredPosts: [],
      id: 'c33202f1-733f-45ec-8525-ac80339b1c18',
      firstName: 'Samanta',
      lastName: 'Cancelier',
      email: 'samantabio@hotmail.com',
      instagramProfile: {
        handle: 'meularnoseua',
        followers: 285498,
        engagementRate: 2.003901953779011,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'meularnoseua',
        followers: 11496,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1981-11-05',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T16:32:28.895Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'would like more TikTok reach'
    },
    userCampaignId: 'b53f8e1d-7716-4048-a818-0ea8efa325fd',
    creator: {
      sponsoredPosts: [],
      id: 'b53f8e1d-7716-4048-a818-0ea8efa325fd',
      firstName: 'Danielle',
      lastName: 'Taylor',
      email: 'danny-alvarenga@hotmail.com',
      instagramProfile: {
        handle: 'cantinhonoseua',
        followers: 279197,
        engagementRate: 4.995397515016279,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'cantinhonoseua',
        followers: 3129,
        engagementRate: 6.198347107438017,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'cantinhonoseua'
      }
    }
  },
  {
    dateOfBirth: '1991-11-08',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T17:38:48.023Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com'
    },
    userCampaignId: '886af559-9d11-4ac7-a415-b85f3dd4cd7c',
    creator: {
      sponsoredPosts: [],
      id: '886af559-9d11-4ac7-a415-b85f3dd4cd7c',
      firstName: 'Queen',
      lastName: 'Ekejija',
      email: 'himylifeisqueen@gmail.com',
      instagramProfile: {
        handle: 'mylifeisqueen',
        followers: 57826,
        engagementRate: 4.604234158413939,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'mylifeisqueen_',
        followers: 41763,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1987-01-29',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T16:32:03.151Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'good to have her in the mix but need more TikTok'
    },
    userCampaignId: '85611f6d-5faa-4d4b-87d7-74abb1ba35ac',
    creator: {
      sponsoredPosts: [],
      id: '85611f6d-5faa-4d4b-87d7-74abb1ba35ac',
      firstName: 'Elen',
      lastName: 'del Rosario',
      email: 'elenmdelrosario@gmail.com',
      instagramProfile: {
        handle: 'elenmarri',
        followers: 246891,
        engagementRate: 1.2629459964113718,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1993-06-13',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-14T18:59:51.832Z'
    },
    userCampaignId: '808a0c5b-c7b1-464c-91f4-9f2dd3d6ef7a',
    creator: {
      sponsoredPosts: [],
      id: '808a0c5b-c7b1-464c-91f4-9f2dd3d6ef7a',
      firstName: 'Emily',
      lastName: 'Quach',
      email: 'contact.helloemy@yahoo.com',
      instagramProfile: {
        handle: 'lovelyemy',
        followers: 60709,
        engagementRate: 0.20145283236422934,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1994-03-06',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:00:40.286Z'
    },
    userCampaignId: '1c326b62-d0d7-4333-aebc-602060ef5313',
    creator: {
      sponsoredPosts: [],
      id: '1c326b62-d0d7-4333-aebc-602060ef5313',
      firstName: 'Erin',
      lastName: 'Bowlware',
      email: 'erin@herhappyhustle.com',
      instagramProfile: {
        handle: 'heyitserintay',
        followers: 15320,
        engagementRate: 2.101827676240209,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'heyitserintay',
        followers: 164,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1992-01-27',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-26T19:36:53.714Z'
    },
    userCampaignId: '33680235-b270-44e4-ad9a-e089be79d138',
    creator: {
      sponsoredPosts: [],
      id: '33680235-b270-44e4-ad9a-e089be79d138',
      firstName: 'Mariann',
      lastName: 'Yip',
      email: 'mariann.yip@gmail.com',
      instagramProfile: {
        handle: 'mariann_yip',
        followers: 112912,
        engagementRate: 0.7566954796655803,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'mariann_yip',
        followers: 3997,
        engagementRate: 10.584958217270195,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1997-04-23',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:12:11.790Z'
    },
    userCampaignId: 'f50ad31a-7e9d-4a7c-aa58-aee7242eaf58',
    creator: {
      sponsoredPosts: [],
      id: 'f50ad31a-7e9d-4a7c-aa58-aee7242eaf58',
      firstName: 'Kaitlyn',
      lastName: 'Corbin',
      email: 'kaitlynmackenzee@gmail.com',
      instagramProfile: {
        handle: 'kaitlynmackenzee',
        followers: 9211,
        engagementRate: 3.559874063619585,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'kaitlynmackenzee',
        followers: 13349,
        engagementRate: 4.577115150810604,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1998-07-10',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:14:28.443Z'
    },
    userCampaignId: '17eb1a42-b395-42bb-9028-9c19c5817436',
    creator: {
      sponsoredPosts: [],
      id: '17eb1a42-b395-42bb-9028-9c19c5817436',
      firstName: 'Aunt',
      lastName: 'Sassy',
      email: 'auntsassy.collab@gmail.com',
      instagramProfile: {
        handle: 'auntsassyashy',
        followers: 32706,
        engagementRate: 7.13691677368067,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'auntsassyashy',
        followers: 6476,
        engagementRate: 4.574363308156677,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'auntsassyashy'
      }
    }
  },
  {
    dateOfBirth: '1995-03-23',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-16T15:34:44.439Z'
    },
    userCampaignId: 'b5f7f1ca-a833-4842-9f09-5ce276555747',
    creator: {
      sponsoredPosts: [],
      id: 'b5f7f1ca-a833-4842-9f09-5ce276555747',
      firstName: 'Ana',
      lastName: 'Abala',
      email: 'abdala.carol@gmail.com',
      instagramProfile: {
        handle: 'carolinaabdala',
        followers: 145107,
        engagementRate: 1.9309199418360246,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'imcarolinaabdala',
        followers: 17314,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1996-05-30',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-16T15:35:00.133Z'
    },
    userCampaignId: '554baa5e-3184-4bc9-98db-3d3942ee65c0',
    creator: {
      sponsoredPosts: [],
      id: '554baa5e-3184-4bc9-98db-3d3942ee65c0',
      firstName: 'Stef',
      lastName: 'Anderson',
      email: 'stefanderson530@gmail.com',
      instagramProfile: {
        handle: 'sideofstef',
        followers: 10646,
        engagementRate: 3.579748262258126,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'sideofstef',
        followers: 24977,
        engagementRate: 5.00361390367304,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'sideofstef',
        followers: 58
      }
    }
  },
  {
    dateOfBirth: '1994-08-23',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-26T19:40:39.387Z'
    },
    userCampaignId: 'faf53c7c-42ac-468e-8d58-35a9de43e026',
    creator: {
      sponsoredPosts: [],
      id: 'faf53c7c-42ac-468e-8d58-35a9de43e026',
      firstName: 'Kalah',
      lastName: 'Gonzales',
      email: 'kalahgonzales@gmail.com',
      instagramProfile: {
        handle: 'kalahgonzales',
        followers: 32293,
        engagementRate: 3.7435357507819034,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1998-02-19',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-26T19:40:59.173Z'
    },
    userCampaignId: 'df8ca55e-57e2-49d0-8e25-5c71a6bff72e',
    creator: {
      sponsoredPosts: [],
      id: 'df8ca55e-57e2-49d0-8e25-5c71a6bff72e',
      firstName: 'Sara',
      lastName: 'Feller',
      email: 'sarafeller64@gmail.com',
      instagramProfile: {
        handle: 'lifewiththe_fellers',
        followers: 27518,
        engagementRate: 3.320372120066865,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'sara.amira.f',
        followers: 76,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1998-06-06',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-27T17:51:09.671Z',
      reviewedByUserId: '3bb86eb1-b3fb-4fb4-a9a1-bf38210a8624',
      reviewedByUserEmail: 'lada@productwind.com'
    },
    userCampaignId: 'b583184d-f15f-4bf1-bfdb-4f24a9f5c353',
    creator: {
      sponsoredPosts: [],
      id: 'b583184d-f15f-4bf1-bfdb-4f24a9f5c353',
      firstName: 'Vanessa',
      lastName: 'Acuna',
      email: 'jnvutube@gmail.com',
      instagramProfile: {
        handle: 'this.season.of.life',
        followers: 24020,
        engagementRate: 2.6915070774354706,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'vanessamilian07',
        followers: 3566,
        engagementRate: 5.766616655519604,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1986-02-11',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-27T17:51:25.210Z',
      reviewedByUserId: '3bb86eb1-b3fb-4fb4-a9a1-bf38210a8624',
      reviewedByUserEmail: 'lada@productwind.com'
    },
    userCampaignId: '8f39b95a-e095-4d2a-a4ba-12d42cbfafbe',
    creator: {
      sponsoredPosts: [],
      id: '8f39b95a-e095-4d2a-a4ba-12d42cbfafbe',
      firstName: 'Svitlana',
      lastName: 'Ato',
      email: 'svitlanaato@gmail.com',
      instagramProfile: {
        handle: 'lanaato',
        followers: 100549,
        engagementRate: 3.4392186893952204,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'lanaato',
        followers: 18030,
        engagementRate: 1.6561312481730488,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'lanaato3257'
      }
    }
  },
  {
    dateOfBirth: '1989-02-03',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-27T17:51:32.115Z',
      reviewedByUserId: '3bb86eb1-b3fb-4fb4-a9a1-bf38210a8624',
      reviewedByUserEmail: 'lada@productwind.com'
    },
    userCampaignId: 'bed50908-bd13-473d-91cf-3c9555b7382f',
    creator: {
      sponsoredPosts: [],
      id: 'bed50908-bd13-473d-91cf-3c9555b7382f',
      firstName: 'Jennifer',
      lastName: 'Wakumelo',
      email: 'wakumelocollabs@gmail.com',
      instagramProfile: {
        handle: 'our.blog.life',
        followers: 32863,
        engagementRate: 5.056750753126617,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'our.blog.life',
        followers: 6040,
        engagementRate: 2.564102564102564,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1992-08-24',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-01-30T17:01:56.126Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'n/a'
    },
    userCampaignId: 'c331c6e6-5601-44b5-aab2-a206d4c584a1',
    creator: {
      sponsoredPosts: [],
      id: 'c331c6e6-5601-44b5-aab2-a206d4c584a1',
      firstName: 'Jessica',
      lastName: 'Lemos',
      email: 'jehgaertner@gmail.com',
      instagramProfile: {
        handle: 'jehgaertnerlemos',
        followers: 10651,
        engagementRate: 7.793634400525773,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'jehgaertner',
        followers: 43,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1997-02-04',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-01-30T17:02:06.480Z',
      reviewedByUserId: '5682ff89-889b-447d-9785-d6b473ae0a3f',
      reviewedByUserEmail: 'paula_macdonald@colpal.com',
      feedback: 'Provocative content'
    },
    userCampaignId: 'fe01847d-46c7-4432-9778-2e8fae72dc86',
    creator: {
      sponsoredPosts: [],
      id: 'fe01847d-46c7-4432-9778-2e8fae72dc86',
      firstName: 'Cindy',
      lastName: 'D',
      email: 'alphaklassinc@gmail.com',
      instagramProfile: {
        handle: 'alphachiq',
        followers: 14521,
        engagementRate: 4.375043041112871,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'alphachiq',
        followers: 2797,
        engagementRate: 3.783783783783784,
        isPrimary: true
      }
    }
  }
]

import { SvgIconProps, SvgIcon } from '@mui/material'

export const EmptyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 202 154" fill="none">
      <path
        d="M100.954 154C143.48 154 177.954 119.526 177.954 77C177.954 34.4741 143.48 0 100.954 0C58.4281 0 23.9541 34.4741 23.9541 77C23.9541 119.526 58.4281 154 100.954 154Z"
        fill="#94D5D9"
      />
      <path
        d="M186.085 133.039H1.26335C0.759368 133.039 0.352539 132.633 0.352539 132.129C0.352539 131.625 0.759368 131.218 1.26335 131.218H186.085C186.589 131.218 186.996 131.625 186.996 132.129C186.996 132.633 186.589 133.039 186.085 133.039Z"
        fill="#243C98"
      />
      <path d="M37.2529 29.3047H158.585L165.046 53.4714L186.086 132.129H62.2759" fill="white" />
      <path
        d="M186.085 133.04H62.2695C61.7655 133.04 61.3587 132.633 61.3587 132.129C61.3587 131.625 61.7655 131.218 62.2695 131.218H184.895L157.874 30.2152H37.2526C36.7486 30.2152 36.3418 29.8083 36.3418 29.3044C36.3418 28.8004 36.7486 28.3936 37.2526 28.3936H158.585C158.998 28.3936 159.356 28.6728 159.465 29.0675L186.972 131.892C187.045 132.165 186.984 132.457 186.814 132.681C186.638 132.906 186.371 133.04 186.085 133.04Z"
        fill="#243C98"
      />
      <path
        d="M84.2075 121.387C83.8068 121.387 83.4424 121.12 83.3331 120.719L66.5378 60.0528C66.4042 59.567 66.6896 59.069 67.1753 58.9294C67.6611 58.7958 68.159 59.0812 68.2987 59.567L85.094 120.233C85.2276 120.719 84.9422 121.217 84.4564 121.356C84.3714 121.375 84.2865 121.387 84.2075 121.387Z"
        fill="#243C98"
      />
      <path
        d="M104.269 121.387C103.868 121.387 103.504 121.12 103.395 120.719L86.5993 60.0528C86.4657 59.567 86.7511 59.069 87.2369 58.9294C87.7227 58.7958 88.2206 59.0812 88.3602 59.567L105.156 120.233C105.289 120.719 105.004 121.217 104.518 121.356C104.433 121.375 104.348 121.387 104.269 121.387Z"
        fill="#243C98"
      />
      <path
        d="M124.333 121.387C123.932 121.387 123.567 121.12 123.458 120.719L106.663 60.0528C106.529 59.567 106.815 59.069 107.3 58.9294C107.786 58.7958 108.284 59.0812 108.424 59.567L125.219 120.233C125.353 120.719 125.067 121.217 124.581 121.356C124.496 121.375 124.411 121.387 124.333 121.387Z"
        fill="#243C98"
      />
      <path
        d="M144.394 121.387C143.993 121.387 143.629 121.12 143.52 120.719L141.049 111.781C140.915 111.295 141.2 110.798 141.686 110.658C142.172 110.524 142.67 110.81 142.809 111.295L145.281 120.234C145.414 120.719 145.129 121.217 144.643 121.357C144.558 121.375 144.479 121.387 144.394 121.387Z"
        fill="#243C98"
      />
      <path
        d="M139.451 103.517C139.05 103.517 138.686 103.25 138.577 102.849L126.73 60.0528C126.597 59.567 126.882 59.069 127.368 58.9294C127.854 58.7958 128.351 59.0812 128.491 59.567L140.338 102.363C140.471 102.849 140.186 103.347 139.7 103.486C139.609 103.505 139.53 103.517 139.451 103.517Z"
        fill="#243C98"
      />
      <path
        d="M164.456 121.387C164.055 121.387 163.69 121.12 163.581 120.719L146.786 60.0528C146.652 59.567 146.938 59.069 147.423 58.9294C147.909 58.7958 148.407 59.0812 148.547 59.567L165.342 120.233C165.476 120.719 165.19 121.217 164.705 121.356C164.62 121.375 164.541 121.387 164.456 121.387Z"
        fill="#243C98"
      />
      <path
        d="M161.214 68.1468H120.3C119.796 68.1468 119.39 67.74 119.39 67.236C119.39 66.732 119.796 66.3252 120.3 66.3252H161.214C161.718 66.3252 162.125 66.732 162.125 67.236C162.125 67.74 161.718 68.1468 161.214 68.1468Z"
        fill="#243C98"
      />
      <path
        d="M114.598 68.1468H104.269C103.765 68.1468 103.358 67.74 103.358 67.236C103.358 66.732 103.765 66.3252 104.269 66.3252H114.598C115.102 66.3252 115.509 66.732 115.509 67.236C115.509 67.74 115.102 68.1468 114.598 68.1468Z"
        fill="#243C98"
      />
      <path
        d="M97.0017 68.1468H89.527C89.023 68.1468 88.6162 67.74 88.6162 67.236C88.6162 66.732 89.023 66.3252 89.527 66.3252H97.0017C97.5057 66.3252 97.9125 66.732 97.9125 67.236C97.9125 67.74 97.5057 68.1468 97.0017 68.1468Z"
        fill="#243C98"
      />
      <path
        d="M80.7831 68.1468H58.3893C57.8853 68.1468 57.4785 67.74 57.4785 67.236C57.4785 66.732 57.8853 66.3252 58.3893 66.3252H80.7831C81.2871 66.3252 81.694 66.732 81.694 67.236C81.694 67.74 81.2811 68.1468 80.7831 68.1468Z"
        fill="#243C98"
      />
      <path
        d="M164.462 83.224H62.2751C61.7711 83.224 61.3643 82.8171 61.3643 82.3132C61.3643 81.8092 61.7711 81.4023 62.2751 81.4023H164.462C164.966 81.4023 165.373 81.8092 165.373 82.3132C165.373 82.8171 164.96 83.224 164.462 83.224Z"
        fill="#243C98"
      />
      <path
        d="M164.462 112.449H62.2751C61.7711 112.449 61.3643 112.042 61.3643 111.538C61.3643 111.034 61.7711 110.627 62.2751 110.627H164.462C164.966 110.627 165.373 111.034 165.373 111.538C165.373 112.042 164.96 112.449 164.462 112.449Z"
        fill="#243C98"
      />
      <path
        d="M164.462 97.8392H129.451C128.947 97.8392 128.54 97.4324 128.54 96.9284C128.54 96.4244 128.947 96.0176 129.451 96.0176H164.462C164.966 96.0176 165.373 96.4244 165.373 96.9284C165.373 97.4324 164.96 97.8392 164.462 97.8392Z"
        fill="#243C98"
      />
      <path
        d="M124.332 97.8392H89.5329C89.0289 97.8392 88.6221 97.4324 88.6221 96.9284C88.6221 96.4244 89.0289 96.0176 89.5329 96.0176H124.332C124.836 96.0176 125.243 96.4244 125.243 96.9284C125.243 97.4324 124.836 97.8392 124.332 97.8392Z"
        fill="#243C98"
      />
      <path
        d="M83.2966 97.8392H62.2751C61.7711 97.8392 61.3643 97.4324 61.3643 96.9284C61.3643 96.4244 61.7711 96.0176 62.2751 96.0176H83.2966C83.8006 96.0176 84.2074 96.4244 84.2074 96.9284C84.2074 97.4324 83.7945 97.8392 83.2966 97.8392Z"
        fill="#243C98"
      />
      <path
        d="M62.2758 132.129H14.1182L31.8183 53.4714L37.2528 29.3047L43.1306 53.4714L62.2758 132.129Z"
        fill="#767676"
      />
      <path
        d="M62.2761 133.04H14.1185C13.8392 133.04 13.5781 132.912 13.4081 132.7C13.238 132.487 13.1713 132.202 13.232 131.929L36.3666 29.1039C36.4577 28.691 36.822 28.3996 37.247 28.3936H37.2531C37.6721 28.3936 38.0364 28.6789 38.1396 29.0918L63.1627 131.916C63.2295 132.19 63.1687 132.475 62.9926 132.694C62.8165 132.912 62.5554 133.04 62.2761 133.04ZM15.254 131.218H61.1164L37.2896 33.2997L15.254 131.218Z"
        fill="#243C98"
      />
      <path d="M165.045 53.4714H43.1297L37.252 29.3047H158.584L165.045 53.4714Z" fill="#EA0000" />
      <path
        d="M165.045 54.3819H43.1296C42.7107 54.3819 42.3463 54.0966 42.2431 53.6837L36.3653 29.5168C36.2985 29.2436 36.3593 28.9582 36.5354 28.7396C36.7054 28.521 36.9725 28.3936 37.2519 28.3936H158.584C158.997 28.3936 159.355 28.6728 159.465 29.0675L165.925 53.2344C165.998 53.5076 165.937 53.7991 165.767 54.0238C165.597 54.2484 165.33 54.3819 165.045 54.3819ZM43.8461 52.5603H163.861L157.886 30.2152H38.4116L43.8461 52.5603Z"
        fill="#243C98"
      />
      <path
        d="M31.8179 54.3819C31.7511 54.3819 31.6843 54.3759 31.6175 54.3577C31.1257 54.2484 30.816 53.7626 30.9314 53.2708L36.3659 29.1039C36.4752 28.6121 36.9609 28.3023 37.4528 28.4177C37.9446 28.527 38.2543 29.0128 38.1389 29.5046L32.7044 53.6715C32.6072 54.0905 32.2308 54.3819 31.8179 54.3819Z"
        fill="#243C98"
      />
      <path
        d="M70.0404 46.2156C72.1867 46.2156 73.9265 44.4758 73.9265 42.3296C73.9265 40.1833 72.1867 38.4434 70.0404 38.4434C67.8942 38.4434 66.1543 40.1833 66.1543 42.3296C66.1543 44.4758 67.8942 46.2156 70.0404 46.2156Z"
        fill="white"
      />
      <path
        d="M70.0401 47.1261C67.3988 47.1261 65.2432 44.9767 65.2432 42.3293C65.2432 39.6818 67.3927 37.5322 70.0401 37.5322C72.6875 37.5322 74.837 39.6818 74.837 42.3293C74.837 44.9767 72.6815 47.1261 70.0401 47.1261ZM70.0401 39.3538C68.4007 39.3538 67.0648 40.6898 67.0648 42.3293C67.0648 43.9687 68.4007 45.3045 70.0401 45.3045C71.6796 45.3045 73.0154 43.9687 73.0154 42.3293C73.0154 40.6898 71.6796 39.3538 70.0401 39.3538Z"
        fill="#243C98"
      />
      <path
        d="M67.5625 43.695C67.0585 43.695 66.6517 43.2882 66.6517 42.7842C66.6517 42.2802 67.0585 41.8734 67.5625 41.8734C71.7826 41.8734 75.2194 36.9003 75.2194 30.7918C75.2194 24.6833 71.7826 19.7103 67.5625 19.7103C63.7189 19.7103 60.4521 23.8696 59.9663 29.3831C59.9238 29.8871 59.4745 30.2514 58.9766 30.2089C58.4726 30.1664 58.1022 29.7231 58.1508 29.2191C58.7215 22.7584 62.7716 17.8887 67.5625 17.8887C72.7905 17.8887 77.041 23.6754 77.041 30.7918C77.041 37.9083 72.7845 43.695 67.5625 43.695Z"
        fill="#243C98"
      />
      <path
        d="M141.442 46.2156C143.588 46.2156 145.328 44.4758 145.328 42.3296C145.328 40.1833 143.588 38.4434 141.442 38.4434C139.296 38.4434 137.556 40.1833 137.556 42.3296C137.556 44.4758 139.296 46.2156 141.442 46.2156Z"
        fill="white"
      />
      <path
        d="M141.441 47.1261C138.8 47.1261 136.645 44.9767 136.645 42.3293C136.645 39.6818 138.794 37.5322 141.441 37.5322C144.089 37.5322 146.238 39.6818 146.238 42.3293C146.238 44.9767 144.089 47.1261 141.441 47.1261ZM141.441 39.3538C139.802 39.3538 138.466 40.6898 138.466 42.3293C138.466 43.9687 139.802 45.3045 141.441 45.3045C143.081 45.3045 144.417 43.9687 144.417 42.3293C144.417 40.6898 143.081 39.3538 141.441 39.3538Z"
        fill="#243C98"
      />
      <path
        d="M138.964 43.695C138.46 43.695 138.053 43.2882 138.053 42.7842C138.053 42.2802 138.46 41.8734 138.964 41.8734C143.184 41.8734 146.621 36.9003 146.621 30.7918C146.621 24.6833 143.184 19.7103 138.964 19.7103C135.12 19.7103 131.86 23.8696 131.368 29.3831C131.325 29.8871 130.882 30.2514 130.378 30.2089C129.874 30.1664 129.504 29.7231 129.552 29.2191C130.123 22.7584 134.167 17.8887 138.964 17.8887C144.192 17.8887 148.442 23.6754 148.442 30.7918C148.442 37.9083 144.192 43.695 138.964 43.695Z"
        fill="#243C98"
      />
      <path
        d="M173.316 144.984C188.461 144.984 200.737 132.707 200.737 117.562C200.737 102.418 188.461 90.1406 173.316 90.1406C158.172 90.1406 145.895 102.418 145.895 117.562C145.895 132.707 158.172 144.984 173.316 144.984Z"
        fill="#324AA8"
      />
      <path
        d="M173.316 145.894C157.692 145.894 144.983 133.185 144.983 117.562C144.983 101.938 157.692 89.2295 173.316 89.2295C188.939 89.2295 201.648 101.938 201.648 117.562C201.642 133.185 188.933 145.894 173.316 145.894ZM173.316 91.0511C158.7 91.0511 146.805 102.94 146.805 117.562C146.805 132.177 158.694 144.073 173.316 144.073C187.931 144.073 199.826 132.183 199.826 117.562C199.82 102.946 187.931 91.0511 173.316 91.0511Z"
        fill="#243C98"
      />
      <path
        d="M183.881 114.215H175.532V105.733C175.532 104.002 174.129 102.605 172.404 102.605C170.674 102.605 169.277 104.008 169.277 105.733V117.342C169.277 119.073 170.68 120.469 172.404 120.469H184.014C185.775 120.469 187.196 119.018 187.141 117.245C187.087 115.533 185.593 114.215 183.881 114.215Z"
        fill="white"
      />
      <path
        d="M184.014 121.387H172.404C170.176 121.387 168.366 119.577 168.366 117.349V105.739C168.366 103.511 170.176 101.701 172.404 101.701C174.633 101.701 176.442 103.511 176.442 105.739V113.311H183.88C186.115 113.311 187.985 115.066 188.052 117.227C188.082 118.332 187.682 119.377 186.91 120.166C186.145 120.95 185.113 121.387 184.014 121.387ZM172.398 103.517C171.178 103.517 170.182 104.512 170.182 105.733V117.343C170.182 118.569 171.178 119.559 172.398 119.559H184.008C184.615 119.559 185.18 119.322 185.599 118.885C186.018 118.448 186.242 117.877 186.224 117.27C186.188 116.086 185.137 115.12 183.874 115.12H175.525C175.021 115.12 174.614 114.714 174.614 114.21V105.727C174.62 104.512 173.625 103.517 172.398 103.517Z"
        fill="#243C98"
      />
    </SvgIcon>
  )
}

import { SvgIconProps, SvgIcon } from '@mui/material'
import React from 'react'

export const EmptyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 168 137" fill="none">
      <path
        d="M69 137C106.831 137 137.5 106.332 137.5 68.5C137.5 30.6685 106.831 0 69 0C31.1685 0 0.5 30.6685 0.5 68.5C0.5 106.332 31.1685 137 69 137Z"
        fill="#94D5D9"
      />
      <path
        d="M133.212 26.4452V110.789C133.212 113.009 131.413 114.813 129.188 114.813H8.57118C6.35104 114.813 4.54688 113.014 4.54688 110.789V26.4452C4.54688 24.2251 6.34564 22.4209 8.57118 22.4209H129.188C131.413 22.4209 133.212 24.2197 133.212 26.4452Z"
        fill="white"
      />
      <path
        d="M129.193 115.618H8.57676C5.91368 115.618 3.74219 113.452 3.74219 110.784V26.44C3.74219 23.777 5.90828 21.6055 8.57676 21.6055H129.193C131.856 21.6055 134.028 23.7716 134.028 26.44V110.784C134.028 113.452 131.862 115.618 129.193 115.618ZM8.58216 23.2314C6.81038 23.2314 5.36813 24.6737 5.36813 26.4454V110.789C5.36813 112.561 6.81038 114.003 8.58216 114.003H129.199C130.97 114.003 132.413 112.561 132.413 110.789V26.4454C132.413 24.6737 130.97 23.2314 129.199 23.2314H8.58216Z"
        fill="#243C98"
      />
      <path
        d="M133.206 26.4452V36.4493H4.54688V26.4452C4.54688 24.2251 6.34564 22.4209 8.57118 22.4209H129.188C131.408 22.4209 133.206 24.2197 133.206 26.4452Z"
        fill="#324AA8"
      />
      <path
        d="M133.22 37.2544H4.56027C4.11192 37.2544 3.75 36.8925 3.75 36.4441V26.44C3.75 23.777 5.91609 21.6055 8.58457 21.6055H129.201C131.864 21.6055 134.036 23.7716 134.036 26.44V36.4441C134.03 36.8925 133.668 37.2544 133.22 37.2544ZM5.37053 35.6339H132.41V26.44C132.41 24.6683 130.967 23.226 129.196 23.226H8.57916C6.80738 23.226 5.36513 24.6683 5.36513 26.44V35.6339H5.37053Z"
        fill="#243C98"
      />
      <path
        d="M125.152 67.5194H12.6384C12.19 67.5194 11.8281 67.2141 11.8281 66.836V42.2742C11.8281 41.8961 12.19 41.5908 12.6384 41.5908H125.152C125.6 41.5908 125.962 41.8961 125.962 42.2742V66.836C125.962 67.2096 125.6 67.5194 125.152 67.5194ZM13.4541 66.1526H124.347V42.9576H13.4541V66.1526Z"
        fill="#243C98"
      />
      <path
        d="M36.2237 46.4346L38.1034 51.4554L43.3059 52.7551L39.1132 56.0954L39.4835 61.4445L35.0113 58.4852L30.0394 60.4942L31.472 55.3266L28.0234 51.2178L33.3831 50.9802L36.2237 46.4346Z"
        fill="#F3C94E"
      />
      <path
        d="M39.4873 61.968C39.386 61.968 39.2847 61.9401 39.1974 61.8807L34.9557 59.0751L30.2389 60.9793C30.0537 61.0527 29.8441 61.0177 29.6939 60.8849C29.5436 60.7522 29.4842 60.546 29.5366 60.3539L30.8923 55.4518L27.6219 51.5561C27.4927 51.4024 27.4647 51.1893 27.5451 51.0076C27.6254 50.8259 27.8036 50.7035 28.0028 50.6966L33.083 50.473L35.7803 46.1614C35.8851 45.9937 36.0738 45.8959 36.2764 45.9169C36.4756 45.9378 36.6433 46.0671 36.7132 46.2558L38.4951 51.0181L43.432 52.2479C43.6242 52.2968 43.7744 52.4505 43.8163 52.6462C43.8583 52.8419 43.7884 53.0445 43.6312 53.1668L39.6516 56.3358L40.0045 61.409C40.0185 61.6082 39.9171 61.7968 39.7459 61.8981C39.6726 61.9436 39.5782 61.968 39.4873 61.968ZM35.0151 57.9605C35.1165 57.9605 35.2178 57.9884 35.3051 58.0478L38.8934 60.4202L38.5929 56.1297C38.5824 55.9584 38.6558 55.7907 38.7886 55.6824L42.1532 53.0026L37.9815 51.9614C37.8138 51.9195 37.6775 51.7972 37.6181 51.6365L36.1122 47.608L33.8307 51.2556C33.7398 51.4024 33.5826 51.4932 33.4079 51.5002L29.1104 51.6889L31.8741 54.9836C31.9859 55.1164 32.0243 55.2946 31.9789 55.4588L30.8329 59.6027L34.8194 57.9919C34.8823 57.9745 34.9487 57.9605 35.0151 57.9605Z"
        fill="#F3C94E"
      />
      <path
        d="M58.4893 46.4346L60.3691 51.4554L65.5716 52.7551L61.3788 56.0954L61.7492 61.4445L57.2769 58.4852L52.3051 60.4942L53.7376 55.3266L50.2891 51.2178L55.6488 50.9802L58.4893 46.4346Z"
        fill="#F3C94E"
      />
      <path
        d="M61.753 61.968C61.6516 61.968 61.5503 61.9401 61.463 61.8807L57.2213 59.0751L52.5045 60.9793C52.3193 61.0527 52.1097 61.0177 51.9595 60.8849C51.8093 60.7522 51.7498 60.546 51.8022 60.3539L53.1579 55.4518L49.8876 51.5561C49.7583 51.4024 49.7303 51.1893 49.8107 51.0076C49.8911 50.8259 50.0692 50.7035 50.2684 50.6966L55.3486 50.473L58.0459 46.1614C58.1507 45.9937 58.3394 45.8959 58.5421 45.9169C58.7412 45.9378 58.9089 46.0671 58.9788 46.2558L60.7607 51.0181L65.6976 52.2479C65.8898 52.2968 66.04 52.4505 66.082 52.6462C66.1239 52.8419 66.054 53.0445 65.8968 53.1668L61.9172 56.3358L62.2701 61.409C62.2841 61.6082 62.1828 61.7968 62.0116 61.8981C61.9382 61.9436 61.8438 61.968 61.753 61.968ZM57.2808 57.9605C57.3821 57.9605 57.4834 57.9884 57.5707 58.0478L61.159 60.4202L60.8585 56.1297C60.8481 55.9584 60.9214 55.7907 61.0542 55.6824L64.4188 53.0026L60.2471 51.9614C60.0794 51.9195 59.9431 51.7972 59.8837 51.6365L58.3779 47.608L56.0963 51.2556C56.0055 51.4024 55.8482 51.4932 55.6735 51.5002L51.376 51.6889L54.1397 54.9836C54.2515 55.1164 54.2899 55.2946 54.2445 55.4588L53.0985 59.6027L57.0851 57.9919C57.148 57.9745 57.2144 57.9605 57.2808 57.9605Z"
        fill="#F3C94E"
      />
      <path
        d="M80.7628 46.4346L82.6425 51.4554L87.845 52.7551L83.6523 56.0954L84.0226 61.4445L79.5504 58.4852L74.5785 60.4942L76.011 55.3266L72.5625 51.2178L77.9222 50.9802L80.7628 46.4346Z"
        fill="#F3C94E"
      />
      <path
        d="M84.0264 61.968C83.9251 61.968 83.8238 61.9401 83.7364 61.8807L79.4948 59.0751L74.778 60.9793C74.5928 61.0527 74.3832 61.0177 74.2329 60.8849C74.0827 60.7522 74.0233 60.546 74.0757 60.3539L75.4313 55.4518L72.161 51.5561C72.0317 51.4024 72.0038 51.1893 72.0841 51.0076C72.1645 50.8259 72.3427 50.7035 72.5418 50.6966L77.622 50.473L80.3193 46.1614C80.4242 45.9937 80.6128 45.8959 80.8155 45.9169C81.0147 45.9378 81.1824 46.0671 81.2522 46.2558L83.0341 51.0181L87.9711 52.2479C88.1632 52.2968 88.3135 52.4505 88.3554 52.6462C88.3973 52.8419 88.3275 53.0445 88.1703 53.1668L84.1907 56.3358L84.5435 61.409C84.5575 61.6082 84.4562 61.7968 84.285 61.8981C84.2116 61.9436 84.1172 61.968 84.0264 61.968ZM79.5542 57.9605C79.6555 57.9605 79.7568 57.9884 79.8442 58.0478L83.4325 60.4202L83.132 56.1297C83.1215 55.9584 83.1948 55.7907 83.3276 55.6824L86.6923 53.0026L82.5205 51.9614C82.3528 51.9195 82.2166 51.7972 82.1572 51.6365L80.6513 47.608L78.3697 51.2556C78.2789 51.4024 78.1217 51.4932 77.947 51.5002L73.6494 51.6889L76.4131 54.9836C76.5249 55.1164 76.5634 55.2946 76.5179 55.4588L75.372 59.6027L79.3585 57.9919C79.4214 57.9745 79.4878 57.9605 79.5542 57.9605Z"
        fill="#F3C94E"
      />
      <path
        d="M103.028 46.4346L104.908 51.4554L110.111 52.7551L105.918 56.0954L106.288 61.4445L101.816 58.4852L96.8441 60.4942L98.2766 55.3266L94.8281 51.2178L100.188 50.9802L103.028 46.4346Z"
        fill="#F3C94E"
      />
      <path
        d="M106.292 61.968C106.191 61.968 106.089 61.9401 106.002 61.8807L101.76 59.0751L97.0436 60.9793C96.8584 61.0527 96.6488 61.0177 96.4986 60.8849C96.3483 60.7522 96.2889 60.546 96.3413 60.3539L97.6969 55.4518L94.4266 51.5561C94.2974 51.4024 94.2694 51.1893 94.3498 51.0076C94.4301 50.8259 94.6083 50.7035 94.8075 50.6966L99.8877 50.473L102.585 46.1614C102.69 45.9937 102.878 45.8959 103.081 45.9169C103.28 45.9378 103.448 46.0671 103.518 46.2558L105.3 51.0181L110.237 52.2479C110.429 52.2968 110.579 52.4505 110.621 52.6462C110.663 52.8419 110.593 53.0445 110.436 53.1668L106.456 56.3358L106.809 61.409C106.823 61.6082 106.722 61.7968 106.551 61.8981C106.477 61.9436 106.383 61.968 106.292 61.968ZM101.82 57.9605C101.921 57.9605 102.022 57.9884 102.11 58.0478L105.698 60.4202L105.398 56.1297C105.387 55.9584 105.46 55.7907 105.593 55.6824L108.958 53.0026L104.786 51.9614C104.618 51.9195 104.482 51.7972 104.423 51.6365L102.917 47.608L100.635 51.2556C100.545 51.4024 100.387 51.4932 100.213 51.5002L95.915 51.6889L98.6788 54.9836C98.7906 55.1164 98.829 55.2946 98.7836 55.4588L97.6376 59.6027L101.624 57.9919C101.687 57.9745 101.753 57.9605 101.82 57.9605Z"
        fill="#F3C94E"
      />
      <path
        d="M21.2774 30.7305C22.1813 30.7305 22.9141 29.9977 22.9141 29.0938C22.9141 28.1898 22.1813 27.457 21.2774 27.457C20.3734 27.457 19.6406 28.1898 19.6406 29.0938C19.6406 29.9977 20.3734 30.7305 21.2774 30.7305Z"
        fill="#12B76A"
      />
      <path
        d="M15.9493 30.7305C16.8532 30.7305 17.586 29.9977 17.586 29.0938C17.586 28.1898 16.8532 27.457 15.9493 27.457C15.0453 27.457 14.3125 28.1898 14.3125 29.0938C14.3125 29.9977 15.0453 30.7305 15.9493 30.7305Z"
        fill="#F3C94E"
      />
      <path
        d="M10.6289 30.7305C11.5329 30.7305 12.2657 29.9977 12.2657 29.0938C12.2657 28.1898 11.5329 27.457 10.6289 27.457C9.725 27.457 8.99219 28.1898 8.99219 29.0938C8.99219 29.9977 9.725 30.7305 10.6289 30.7305Z"
        fill="#EA0000"
      />
      <path d="M91.8605 72.1182H78.0156V85.9629H91.8605V72.1182Z" fill="#7C8EE1" />
      <path
        d="M91.8606 86.7689H78.0212C77.5729 86.7689 77.2109 86.407 77.2109 85.9587V72.114C77.2109 71.6656 77.5729 71.3037 78.0212 71.3037H91.8606C92.309 71.3037 92.6709 71.6656 92.6709 72.114V85.9587C92.6709 86.407 92.309 86.7689 91.8606 86.7689ZM78.8315 85.1484H91.0504V72.9242H78.8315V85.1484Z"
        fill="#243C98"
      />
      <path d="M91.8605 94.2656H78.0156V108.11H91.8605V94.2656Z" fill="#7C8EE1" />
      <path
        d="M91.8606 108.915H78.0212C77.5729 108.915 77.2109 108.554 77.2109 108.105V94.2605C77.2109 93.8121 77.5729 93.4502 78.0212 93.4502H91.8606C92.309 93.4502 92.6709 93.8121 92.6709 94.2605V108.105C92.6709 108.554 92.309 108.915 91.8606 108.915ZM78.8315 107.295H91.0504V95.0707H78.8315V107.295Z"
        fill="#243C98"
      />
      <path
        d="M124.277 73.5219H94.5993C94.151 73.5219 93.7891 73.16 93.7891 72.7116C93.7891 72.2633 94.151 71.9014 94.5993 71.9014H124.277C124.725 71.9014 125.087 72.2633 125.087 72.7116C125.087 73.16 124.725 73.5219 124.277 73.5219Z"
        fill="#243C98"
      />
      <path
        d="M124.277 77.0766H94.5993C94.151 77.0766 93.7891 76.7147 93.7891 76.2663C93.7891 75.818 94.151 75.4561 94.5993 75.4561H124.277C124.725 75.4561 125.087 75.818 125.087 76.2663C125.087 76.7147 124.725 77.0766 124.277 77.0766Z"
        fill="#243C98"
      />
      <path
        d="M124.277 80.6323H94.5993C94.151 80.6323 93.7891 80.2703 93.7891 79.822C93.7891 79.3736 94.151 79.0117 94.5993 79.0117H124.277C124.725 79.0117 125.087 79.3736 125.087 79.822C125.087 80.2703 124.725 80.6323 124.277 80.6323Z"
        fill="#243C98"
      />
      <path
        d="M112.209 84.1899H94.5993C94.151 84.1899 93.7891 83.828 93.7891 83.3796C93.7891 82.9313 94.151 82.5693 94.5993 82.5693H112.209C112.658 82.5693 113.019 82.9313 113.019 83.3796C113.019 83.828 112.658 84.1899 112.209 84.1899Z"
        fill="#243C98"
      />
      <path
        d="M124.277 95.6703H94.5993C94.151 95.6703 93.7891 95.3084 93.7891 94.8601C93.7891 94.4117 94.151 94.0498 94.5993 94.0498H124.277C124.725 94.0498 125.087 94.4117 125.087 94.8601C125.087 95.3084 124.725 95.6703 124.277 95.6703Z"
        fill="#243C98"
      />
      <path
        d="M124.277 99.225H94.5993C94.151 99.225 93.7891 98.8631 93.7891 98.4148C93.7891 97.9664 94.151 97.6045 94.5993 97.6045H124.277C124.725 97.6045 125.087 97.9664 125.087 98.4148C125.087 98.8631 124.725 99.225 124.277 99.225Z"
        fill="#243C98"
      />
      <path
        d="M124.277 102.779H94.5993C94.151 102.779 93.7891 102.417 93.7891 101.968C93.7891 101.52 94.151 101.158 94.5993 101.158H124.277C124.725 101.158 125.087 101.52 125.087 101.968C125.087 102.417 124.725 102.779 124.277 102.779Z"
        fill="#243C98"
      />
      <path
        d="M112.209 106.339H94.5993C94.151 106.339 93.7891 105.977 93.7891 105.529C93.7891 105.081 94.151 104.719 94.5993 104.719H112.209C112.658 104.719 113.019 105.081 113.019 105.529C113.019 105.977 112.658 106.339 112.209 106.339Z"
        fill="#243C98"
      />
      <path
        d="M16.7754 103.175C16.2198 103.175 15.6777 102.904 15.3524 102.402C14.8442 101.616 15.061 100.566 15.8471 100.058L24.2971 94.5484C25.1441 93.9995 26.2351 93.9928 27.0822 94.5416L30.4771 96.7304L40.4654 89.6356C41.3259 89.0257 42.4915 89.0054 43.3724 89.5882L49.6134 93.742L65.5851 81.382C66.3238 80.8061 67.3876 80.9484 67.9636 81.687C68.5396 82.4256 68.404 83.4895 67.6586 84.0655L51.2126 96.7913C50.3453 97.4622 49.1391 97.5097 48.2243 96.8998L41.963 92.7323L31.9815 99.8204C31.1209 100.43 29.996 100.457 29.1083 99.8881L25.6931 97.6926L17.6902 102.904C17.4124 103.087 17.0871 103.175 16.7754 103.175Z"
        fill="#243C98"
      />
      <rect x="11.8359" y="71.3037" width="59.4196" height="37.8125" rx="1.08036" fill="none" />
      <rect
        x="11.8359"
        y="71.3037"
        width="59.4196"
        height="37.8125"
        rx="1.08036"
        stroke="#243C98"
        strokeWidth="2"
        fill="none"
      />
      <path
        d="M166.887 43.3107C166.887 59.1447 154.051 71.9806 138.217 71.9806C122.383 71.9806 109.547 59.1447 109.547 43.3107C109.547 27.4767 122.383 14.6406 138.217 14.6406C154.051 14.6406 166.887 27.4767 166.887 43.3107Z"
        fill="#324AA8"
        stroke="#243C98"
        strokeWidth="2"
      />
      <path
        d="M142.477 43.3105L149.137 36.6505C150.157 35.6305 150.417 34.0005 149.607 32.8005C148.517 31.1605 146.277 31.0004 144.957 32.3204L138.217 39.0605L131.557 32.4005C130.537 31.3805 128.907 31.1205 127.707 31.9205C126.067 33.0105 125.907 35.2504 127.227 36.5704L133.967 43.3105L127.317 49.9604C126.337 50.9404 126.077 52.7405 126.867 53.8705C127.477 54.7405 128.417 55.1805 129.357 55.1805C130.127 55.1805 130.897 54.8905 131.487 54.3005L138.227 47.5605L144.967 54.3005C145.557 54.8905 146.327 55.1805 147.097 55.1805C148.037 55.1805 148.977 54.7405 149.587 53.8705C150.377 52.7305 150.117 50.9404 149.137 49.9604L142.477 43.3105Z"
        fill="white"
      />
    </SvgIcon>
  )
}

import { Stack, Typography, Paper, Box } from '@mui/material'
import { useFeedbackContext } from '../feedbackContext'
import React from 'react'
import { orderBy, min } from 'lodash'
import Slider from '@momentum/components/slider'

export const ThemeSummary = () => {
  const { selectedTheme } = useFeedbackContext()

  if (!selectedTheme) return null

  const feedback = orderBy(
    [
      ...selectedTheme.positiveFeedback.map(f => ({ isPositive: true, feedback: f })),
      ...selectedTheme.criticalFeedback.map(f => ({ isPositive: false, feedback: f }))
    ],
    s => s.feedback.length,
    'desc'
  )

  return (
    <Stack spacing={4}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography variant={'h4'}>{selectedTheme.theme}</Typography>
        <Typography variant={'body1'} color={theme => theme.palette.grey.A700}>
          {selectedTheme.positiveFeedback.length + selectedTheme.criticalFeedback.length} creators said this
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant={'label1'}>AI summary</Typography>
        <Typography variant={'body1'}>{selectedTheme.summary}</Typography>
      </Stack>
      <Stack spacing={2} key={selectedTheme.theme}>
        <Typography variant={'label1'}>Select quotes about “{selectedTheme.theme}”</Typography>
        <Box>
          <Slider
            dots={(feedback.length || 0) > 1}
            adaptiveHeight
            slidesToShow={min([3, feedback.length])}
            slidesToScroll={3}
          >
            {feedback.map((f, index) => (
              <Box key={index} sx={{ verticalAlign: 'top' }}>
                <Box px={1} pb={1}>
                  <Paper
                    sx={{
                      height: 125,
                      width: '100%'
                    }}
                    elevation={0}
                  >
                    <Typography
                      p={2}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                      sx={{
                        display: '-webkit-box',
                        WebkitLineBreak: '5',
                        WebkitLineClamp: '5',
                        WebkitBoxOrient: 'vertical'
                      }}
                    >
                      {f.feedback}
                    </Typography>
                  </Paper>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Stack>
    </Stack>
  )
}

import { ZeusInterceptor } from '../types'
import { isSampleContent } from '../utils'

export const approveContent: ZeusInterceptor<'mutation', 'approveContent'> = async args => {
  const id = args.o.approveContent?.[0].input.userCampaignSocialContentId
  if (id && isSampleContent(id)) {
    return {
      userCampaignSocialContentId: id
    }
  }
}

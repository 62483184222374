import { Stack, Typography, Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Role, UserStatus, AccessLevel } from '@productwindtom/shared-momentum-zeus-types'
import { words, capitalize } from 'lodash'
import { InfoOutlined } from '@mui/icons-material'
import { ACCESS_LEVEL_TO_STRING } from '@momentum/utils/accessLevelUtils'

export enum RowType {
  INVITATION,
  USER
}

export type RowData = {
  id: string
  type: RowType
  email: string
  firstName?: string
  lastName?: string
  brands?: string
  role?: Role
  status?: UserStatus
  lastActiveAt?: string
  companyId?: string
}

export const UserTableColumns = (isAgency: boolean, viewOnlyPermissionsFlag: boolean): GridColDef<RowData>[] => [
  {
    field: 'name',
    headerName: 'Name',

    renderCell: ({ row }) => (
      <Stack>
        <Typography variant={'label3'} color={'primary'}>
          {row.firstName} {row.lastName}
        </Typography>
        <Typography variant={'label3'}>{row.email}</Typography>
      </Stack>
    )
  },
  {
    field: 'role',
    headerName: 'Role',
    valueFormatter: (value?: string) => words(value).map(capitalize).join(' ')
  },
  ...(viewOnlyPermissionsFlag
    ? [
        {
          field: 'accessLevel',
          headerName: 'Access level',
          renderHeader: (params: any) => (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <span className={params.colDef.headerClassName as string}>{params.colDef.headerName}</span>
              <Tooltip
                title={
                  <Stack spacing={2}>
                    <Typography variant={'body1'}>
                      The <b>Editor</b> role has full access to schedule campaigns and edit Profile fields.
                    </Typography>
                    <Typography variant={'body1'}>
                      The <b>View-Only</b> role can create proposals but cannot schedule campaigns and cannot edit the
                      Brand Profile.
                    </Typography>
                  </Stack>
                }
              >
                <InfoOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
              </Tooltip>
            </Stack>
          ),
          valueFormatter: (value?: AccessLevel) => ACCESS_LEVEL_TO_STRING[value || AccessLevel.FULL_ACCESS]
        }
      ]
    : []),
  ...(!isAgency
    ? [
        {
          field: 'primaryBrand',
          headerName: 'Primary brand'
        }
      ]
    : []),
  {
    field: 'lastActiveAt',
    headerName: 'Last Active',
    renderCell: ({ row, value }) =>
      row.type === RowType.INVITATION ? (
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          Pending Invite
        </Typography>
      ) : row.status === UserStatus.INACTIVE ? (
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          Deactivated
        </Typography>
      ) : value ? (
        new Date(value).toLocaleDateString()
      ) : (
        ''
      )
  }
]

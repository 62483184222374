import { Circle, Svg, Text, View } from '@react-pdf/renderer'
import { FeedbackPDFProps } from '.'
import { styles } from './styles'

export const FeedbackTablePDF = ({ themes }: FeedbackPDFProps) => {
  return (
    <View
      style={[
        styles.column,
        styles.debug,
        {
          marginLeft: 8,
          width: 322,
          justifyContent: 'center'
        }
      ]}
    >
      {/* HEADERS */}
      <View style={[styles.row, styles.debug]}>
        <View
          style={[
            styles.cell,
            styles.debug,
            {
              width: '10%',
              minWidth: '10%'
            }
          ]}
        />
        <View
          style={[
            styles.cell,
            styles.debug,
            {
              textAlign: 'left',
              width: '50%',
              minWidth: '50%'
            }
          ]}
        >
          <Text style={[styles.label2]}>Feedback</Text>
        </View>
        <View
          style={[
            styles.cell,
            styles.debug,
            {
              width: '25%',
              minWidth: '25%'
            }
          ]}
        >
          <Text style={[styles.label2]}>Creators</Text>
        </View>
        <View style={[styles.cell, styles.debug, { width: '15%', minWidth: '15%' }]}>
          <Text style={[styles.label2]}>%</Text>
        </View>
      </View>
      {/* ROWS */}
      {themes.map((t, index) => (
        <View key={index} style={[styles.row, styles.debug]}>
          <View
            style={[
              styles.cell,
              styles.debug,
              {
                padding: 0,
                width: '10%',
                minWidth: '10%',
                justifyContent: 'center'
              }
            ]}
          >
            <View
              style={[
                styles.debug,
                {
                  height: 16
                }
              ]}
            >
              <Svg
                style={[
                  styles.debug,
                  {
                    marginLeft: 7
                  }
                ]}
              >
                <Circle cx="8" cy="8" r="7" fill={t.color} />
              </Svg>
            </View>
          </View>
          <View
            style={[
              styles.cell,
              styles.debug,
              {
                textAlign: 'left',
                width: '50%',
                minWidth: '50%'
              }
            ]}
          >
            <Text
              style={[
                styles.label3,
                {
                  fontWeight: 400
                }
              ]}
            >
              {t.theme}
            </Text>
          </View>
          <View
            style={[
              styles.cell,
              styles.debug,
              {
                width: '25%',
                minWidth: '25%'
              }
            ]}
          >
            <Text
              style={[
                styles.label3,
                {
                  fontWeight: 400
                }
              ]}
            >
              {t.positiveFeedback.length + t.criticalFeedback.length}
            </Text>
          </View>
          <View
            style={[
              styles.cell,
              styles.debug,
              {
                width: '15%',
                minWidth: '15%'
              }
            ]}
          >
            <Text
              style={[
                styles.label3,
                {
                  fontWeight: 400
                }
              ]}
            >
              {t.percentage}%
            </Text>
          </View>
        </View>
      ))}
    </View>
  )
}

import Papa, { ParseError } from 'papaparse'
import { DateTime } from 'luxon'
import { ListingBadge, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'

export const parseCsv = async (file: File) => {
  const rows = await parseFile(file)

  const keywords = parseKeywords(rows)
  const impressionRecords = parseImpressions(rows)

  return {
    keywords,
    impressionRecords
  }
}

const parseKeywords = (rows: (string | number)[][]) => {
  const headerIndex = findIndexOfHeader(rows, 'searchterm')
  if (headerIndex < 0) {
    return undefined
  }

  const header = rows[headerIndex]
  const searchTermIndex = findCellIndexInRow(header, 'searchterm')
  const msvIndex = findCellIndexInRow(header, 'msv')
  const pageOneWinIndex = findCellIndexInRow(header, 'page1')
  const startingOrganicRankIndex = findCellIndexInRow(header, 'startingorganicrank')
  const highestOrganicRankIndex = findCellIndexInRow(header, 'highestorganicrank')
  const amazonChoiceIndex = findCellIndexInRow(header, 'amazonchoice')

  const keywords = []

  let foundLastRow = false
  let index = headerIndex + 1
  while (!foundLastRow) {
    const row = rows[index]
    if (!rows[index]) {
      foundLastRow = true
      break
    }
    const searchTerm = row[searchTermIndex] as string

    if (!searchTerm || !searchTerm.trim()) {
      index++
      continue
    }

    const msv = row[msvIndex] as number
    const pageOneWin = row[pageOneWinIndex] as string
    const startingOrganicRank = row[startingOrganicRankIndex] as number
    const highestOrganicRank = row[highestOrganicRankIndex] as number
    const amazonChoice = row[amazonChoiceIndex] as string

    const amazonChoiceBadgeDate = (amazonChoice || '').trim()
    const hasPageOneWin = !!(pageOneWin || '').trim()

    const keyword: ValueTypes['ModelInputCreatePWProductSeoSummaryKeywordsInput'] = {
      keyword: searchTerm.trim(),
      impressions: convertToNumber(msv)!,
      pageOneWin: hasPageOneWin,
      pageOneWinDate: hasPageOneWin
        ? (DateTime.fromFormat(pageOneWin.replace('✅', '').replace('?', '').trim(), 'M/d') as any)
        : undefined,
      startRank: convertToNumber(startingOrganicRank),
      highestRank: convertToNumber(highestOrganicRank),
      badges: amazonChoiceBadgeDate
        ? [
            {
              badge: ListingBadge.AMAZON_CHOICE,
              date: DateTime.fromFormat(amazonChoiceBadgeDate, 'M/d').toISODate()
            }
          ]
        : []
    }
    keywords.push(keyword)

    index++
  }
  return keywords
}

const parseImpressions = (rows: (string | number)[][]) => {
  const headerIndex = findIndexOfHeader(rows, 'impressioninsearch')
  console.log(rows)
  if (headerIndex < 0) {
    return undefined
  }

  const header = rows[headerIndex]
  const weekIndex = findCellIndexInRow(header, 'week')
  const impressionsIndex = findCellIndexInRow(header, 'impressioninsearch')
  const totalPageOneWinsIndex = findCellIndexInRow(header, 'page1wins')
  const outOfStockIndex = findCellIndexInRow(header, 'outofstock')

  const impressionRecords: ValueTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput'][] = []
  let foundLastRow = false
  let index = headerIndex + 1
  while (!foundLastRow) {
    const row = rows[index]
    if (!rows[index]) {
      foundLastRow = true
      break
    }

    const week = row[weekIndex] as string
    if (week === undefined || week === null) {
      index++
      continue
    }

    const impressions = row[impressionsIndex] as string
    const totalPageOneWins = row[totalPageOneWinsIndex] as string
    const outOfStock = row[outOfStockIndex] as string
    console.log(index - headerIndex - 1)
    const impressionRecord: ValueTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput'] = {
      weekIndex: index - headerIndex - 1,
      impressions: convertToNumber(impressions),
      topPageWins: convertToNumber(totalPageOneWins),
      isOutOfStock: outOfStock ? outOfStock.toLowerCase().trim() === 'y' : false,
      categoryRanks: []
    }
    console.log(impressionRecord)
    impressionRecords.push(impressionRecord)
    index++
  }
  return impressionRecords
}

const convertToNumber = (value: string | number) => {
  if (value == null || value === '') return undefined
  if (typeof value === 'number') {
    return value
  }
  return Number(value.replaceAll(',', '').replaceAll('>', ''))
}

const findCellIndexInRow = (row: (string | number)[], searchTerm: string) => {
  return row.findIndex(cell => {
    if (typeof cell === 'string') {
      return cell.toLowerCase().replaceAll(' ', '').includes(searchTerm)
    }
    return false
  })
}

const findIndexOfHeader = (data: (string | number)[][], searchTerm: string) => {
  return data.findIndex(
    (row: (string | number)[]) =>
      !!row.find(
        r => typeof r === 'string' && (r as string).trim().toLowerCase().replaceAll(' ', '').includes(searchTerm)
      )
  )
}

const parseFile = async (file: File): Promise<(string | number)[][]> => {
  return await new Promise((res, err) => {
    //@ts-ignore
    Papa.parse(file as File, {
      dynamicTyping: true,
      complete: results => {
        const data = results.data
        if (data.length > 1) {
          res(results.data as (string | number)[][])
        }
        err('Unable to parse')
      },
      error(error: ParseError) {
        err(error)
      }
    })
  })
}

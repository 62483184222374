import { BrandApiType, ItemClassification } from '../zeus'
import { LocaleToBrandApi } from './converters'
import { getLocaleFromUrl } from './locale'

export enum AmazonSellerType {
  SELLER = 'SELLER',
  VENDOR = 'VENDOR'
}

export const getBrandApiFromStoreUrl = (url: string): BrandApiType | undefined => {
  const { hostname, pathname } = new URL(url)
  const locale = getLocaleFromUrl(url)

  console.log(hostname, pathname, locale)

  return (
    locale &&
    LocaleToBrandApi[pathname.includes('/stores/') ? AmazonSellerType.VENDOR : AmazonSellerType.SELLER][locale]
  )
}

export const getItemClassification = (itemClassification: string) => {
  switch (itemClassification) {
    case ItemClassification.VARIATION_PARENT:
    case ItemClassification.PRODUCT_BUNDLE:
    case ItemClassification.BASE_PRODUCT:
      return itemClassification
    default:
      console.log(`UNKNOWN ITEM CLASSIFICATION - ${itemClassification}`)

      return ItemClassification.BASE_PRODUCT
  }
}

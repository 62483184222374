import { CognitoUser } from 'amazon-cognito-identity-js'
import { Dialog, DialogTitle, IconButton, DialogContent, Typography, CircularProgress } from '@mui/material'
import { Form, SubmitButton } from '@productwindtom/ui-base'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/system'
import { Close } from '@mui/icons-material'
import LogoWithTextIcon from '@momentum/components/icons/logo-with-text'
import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import VerificationCodeInput from '@momentum/components/verification-code-input'
import { useController } from 'react-hook-form'

export type MfaUser = CognitoUser & {
  challengeParam: { CODE_DELIVERY_DELIVERY_MEDIUM?: string; CODE_DELIVERY_DESTINATION?: string }
}

type MfaDialogProps = {
  open: boolean
  onClose: () => void
  user: MfaUser
  credentials?: { email: string; password: string }
}

type MfaData = {
  code: string
}

const mfaSchema = yup.object().shape({
  code: yup.string().required('Required').length(6)
})

export const MfaDialog = ({ open, user, onClose, credentials }: MfaDialogProps) => {
  const [internalUser, setInternalUser] = useState<MfaUser>()
  const [resendingCode, setResendingCode] = useState(false)

  const onMfaSubmit = async ({ code }: MfaData) => {
    try {
      await Auth.confirmSignIn(internalUser || user, code, 'SMS_MFA')
      toast(
        <Typography data-cy={'successfulLoginText'} variant={'subtitle2'}>
          Successfully logged in!
        </Typography>,
        { type: 'success' }
      )
    } catch (e: any) {
      console.log(e)
      if (e.code === 'CodeMismatchException') {
        toast(<Typography variant={'subtitle2'}>Invalid code, please try again.</Typography>, { type: 'error' })
      } else if (e.code === 'NotAuthorizedException') {
        toast(<Typography variant={'subtitle2'}>Code has expired</Typography>, { type: 'error' })
        onClose()
      }
    }
  }

  const resendMfaCode = async () => {
    if (credentials) {
      setResendingCode(true)
      try {
        const resp = await Auth.signIn(credentials.email, credentials.password)
        setInternalUser(resp)
        toast(<Typography variant={'subtitle2'}>Code resent</Typography>, { type: 'success' })
      } catch (e: any) {
        console.log(e)
        toast(<Typography variant={'subtitle2'}>Failed to resend code</Typography>, { type: 'error' })
      }
      setResendingCode(false)
    }
  }

  return (
    <Dialog open={open}>
      <Form onSubmit={onMfaSubmit} defaultValues={{ code: '' }} resolver={yupResolver(mfaSchema)}>
        <DialogTitle>
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <IconButton onClick={onClose} size={'small'}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4} alignItems={'center'} mb={4}>
            <LogoWithTextIcon sx={{ height: '18px', width: '133px' }} />
            <Typography variant={'h3'} textAlign={'center'}>
              Two-factor authentication
            </Typography>
            <Typography variant={'label3'} textAlign={'center'}>
              We've sent a code to the phone number ending in{' '}
              {(internalUser || user).challengeParam?.CODE_DELIVERY_DESTINATION?.slice(-4)}. Add it below to finish
              logging in.
            </Typography>
            <FormVerificationCodeInput name={'code'} />
            <SubmitButton data-cy={'submit'} id={'submit'} variant={'contained'}>
              Verify
            </SubmitButton>
            {!!credentials && (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography color={theme => theme.palette.grey.A700}>Didn't receive code?</Typography>
                {resendingCode ? (
                  <CircularProgress size={12} />
                ) : (
                  <Typography variant={'link2'} color={'primary'} sx={{ cursor: 'pointer' }} onClick={resendMfaCode}>
                    Resend code
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Form>
    </Dialog>
  )
}

const FormVerificationCodeInput = ({ name }: { name: string }) => {
  const {
    formState: { isSubmitting },
    field: { value, onChange }
  } = useController({ name })

  return <VerificationCodeInput onChange={onChange} value={value} success={isSubmitting} />
}

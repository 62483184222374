import { useCampaignSubNavDetails } from '@momentum/hooks/useCampaignSubNavDetails'
import { ROUTES, ROUTE_NAMES_CAMPAIGN } from '@momentum/routes/RouteNames'
import { ExpandCircleDownOutlined, SvgIconComponent } from '@mui/icons-material'
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { useEffect } from 'react'
import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'

export const COLLAPSED_WIDTH = 60
export const EXPANDED_WIDTH = 220

export const NavBar = () => {
  const { navCollapsed: collapsed, setNavCollapsed: setCollapsed } = useCampaignContext()

  const navigate = useNavigate()
  const { brandId, campaignId, skuId } = useParams<{ brandId: string; campaignId: string; skuId: string }>()

  const isBasePath = !!useMatch(ROUTES.CAMPAIGN)

  const campaignSubNavDetails = useCampaignSubNavDetails(brandId!, campaignId!, skuId!)

  useEffect(() => {
    if (isBasePath) {
      navigate(
        generatePath([ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.OVERVIEW].join('/'), {
          brandId,
          campaignId
        }),
        { replace: true }
      )
    }
  }, [isBasePath])

  return (
    <Box
      sx={{ transition: 'all 0.5s' }}
      display={'block'}
      flex={'0 0 auto'}
      width={collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH}
      bgcolor={theme => theme.palette.grey.A100}
    >
      <Stack
        sx={{ transition: 'all 0.5s' }}
        bgcolor={theme => theme.palette.grey.A100}
        minWidth={collapsed ? COLLAPSED_WIDTH - 16 : EXPANDED_WIDTH - 40}
        width={collapsed ? COLLAPSED_WIDTH - 16 : EXPANDED_WIDTH - 40}
        alignItems={'center'}
        px={collapsed ? 1 : 2.5}
        py={2}
        height={'100%'}
        position={'fixed'}
        zIndex={3}
      >
        <IconButton
          sx={{ ml: 'auto', mr: collapsed ? 'auto' : 'none' }}
          size={'small'}
          onClick={() => setCollapsed(!collapsed)}
        >
          <ExpandCircleDownOutlined
            sx={{ transform: collapsed ? 'rotate(-90deg)' : 'rotate(90deg)', color: 'black' }}
          />
        </IconButton>

        <List data-cy={'campaignSubNav'} sx={{ width: '100%', pt: 16 }}>
          <Stack spacing={1}>
            {campaignSubNavDetails.map((s, idx) => (
              <NavItem
                key={s.id || idx}
                dataCy={s.id}
                selected={s.selected}
                text={s.label}
                icon={s.icon}
                collapsed={collapsed}
                onClick={() => navigate(s.to)}
              />
            ))}
          </Stack>
        </List>
      </Stack>
    </Box>
  )
}

const NavItem = ({
  selected,
  dataCy,
  text,
  collapsed,
  icon,
  onClick
}: {
  selected?: boolean
  dataCy?: string
  text: JSX.Element | string
  collapsed: boolean
  icon: SvgIconComponent
  onClick: () => void
}) => (
  <ListItemButton
    onClick={onClick}
    dense
    sx={{ borderRadius: '4px', px: collapsed ? 1 : 2 }}
    selected={selected}
    color={'black'}
    data-cy={dataCy}
  >
    <ListItemIcon sx={{ minWidth: collapsed ? 24 : 40 }}>
      <SvgIcon component={icon} sx={{ color: theme => (selected ? theme.palette.primary.main : 'black') }} />
    </ListItemIcon>
    {!collapsed && (
      <ListItemText
        primary={
          <Typography noWrap variant={selected ? 'label2' : 'label3'} color={selected ? 'primary' : 'inherit'}>
            {text}
          </Typography>
        }
      />
    )}
  </ListItemButton>
)

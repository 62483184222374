import { NoItems } from '@momentum/components/no-items'
import { ROUTES, ROUTE_NAMES_BRAND } from '@momentum/routes/RouteNames'
import { Add } from '@mui/icons-material'
import { Button, Link, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Link as RouterLink, generatePath } from 'react-router-dom'

export const EmptyState = ({ brandId }: { brandId: string }) => {
  return (
    <Stack mt={12} alignItems={'center'} justifyContent={'center'} data-cy="proposalEmptyState">
      <NoItems />
      <Typography variant={'h5'} my={4}>
        You have no saved proposals.
      </Typography>
      <Button
        sx={{ mb: 2 }}
        data-cy={'createProposal'}
        variant={'contained'}
        component={RouterLink}
        to={generatePath(ROUTES.BRAND_CREATE_PROPOSAL, { brandId })}
        startIcon={<Add />}
      >
        Create proposal
      </Button>
      <Link
        component={RouterLink}
        to={generatePath(`${ROUTES.BRAND}/${ROUTE_NAMES_BRAND.RECOMMENDATIONS}`, { brandId })}
      >
        View recommendations
      </Link>
    </Stack>
  )
}

import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import theme from './global'

export const wsTheme = createTheme(
  deepmerge(theme, {
    palette: {
      primary: {
        main: '#FF5B55',
        light: '#FFF8F8'
      },
      secondary: {
        main: '#FF5B55',
        light: '#FFF8F8'
      }
    }
  })
)

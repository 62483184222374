import { getPercentValue } from '@productwindtom/shared-node'
import { Image, Text, View } from '@react-pdf/renderer'
import { FeedbackPDFProps } from '.'
import { OTHER_THEME } from '../constants'
import ThumbUpImage from './images/thumb-up.png'
import { styles } from './styles'

export const SummaryPDF = ({ productFeedbackSummary, aiSummary, themes }: FeedbackPDFProps) => {
  const feedbackEntries = aiSummary?.feedback.filter(f => f.sentiment !== 'neutral').length || 0

  const nonOtherThemes = themes.filter(t => t.theme !== OTHER_THEME)

  return (
    <>
      <View
        style={[
          styles.card,
          styles.column,
          styles.debug,
          {
            alignItems: 'center'
          }
        ]}
      >
        <Text style={[styles.label1, styles.debug, { alignSelf: 'flex-start' }]}>Product feedback summary</Text>

        <Text style={[styles.label2, {}]}>{aiSummary ? feedbackEntries.toLocaleString() : '--'}</Text>
        <Text
          style={{
            ...styles.caption2,
            marginTop: 4
          }}
        >
          pieces of feedback
        </Text>
      </View>

      <View
        style={[
          styles.card,
          styles.column,
          styles.debug,
          {
            marginTop: 24
          }
        ]}
      >
        <Text
          style={[
            styles.label2,
            styles.debug,
            {
              marginLeft: 16
            }
          ]}
        >
          Main themes
        </Text>
        <View
          style={[
            styles.row,
            styles.debug,
            {
              marginTop: 19
            }
          ]}
        >
          {nonOtherThemes.map((t, index) => (
            <View
              key={index}
              style={[
                styles.column,
                styles.card,
                styles.debug,
                {
                  width: `${100 / nonOtherThemes.length}%`,
                  marginRight: nonOtherThemes.length - 1 === index ? 0 : 4,
                  padding: 6
                }
              ]}
            >
              <Text
                style={[
                  styles.label3,
                  styles.debug,
                  {
                    flexGrow: 1
                  }
                ]}
              >
                {t.theme}
              </Text>
              <View
                style={[
                  styles.row,
                  styles.debug,
                  {
                    marginTop: 8,
                    alignContent: 'center',
                    alignItems: 'center'
                  }
                ]}
              >
                <Image
                  source={ThumbUpImage}
                  style={{
                    width: 16,
                    height: 16
                  }}
                />
                <Text
                  style={[
                    styles.label4,
                    styles.debug,
                    {
                      marginLeft: 4,
                      marginRight: 8,
                      marginTop: 2,
                      alignContent: 'center',
                      alignItems: 'center'
                    }
                  ]}
                >
                  {getPercentValue(t.positiveFeedback.length, t.criticalFeedback.length + t.positiveFeedback.length, 0)}
                  %
                </Text>
                <Image
                  source={ThumbUpImage}
                  style={{
                    width: 16,
                    height: 16
                  }}
                />
                <Text
                  style={[
                    styles.label4,
                    styles.debug,
                    {
                      marginLeft: 4,
                      marginTop: 2
                    }
                  ]}
                >
                  {getPercentValue(t.criticalFeedback.length, t.criticalFeedback.length + t.positiveFeedback.length, 0)}
                  %
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </>
  )
}

import { mApi } from '@momentum/api'
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const approveContent = async (input: ModelTypes['ApproveContentInput']) => {
  return (
    await mApi('mutation')({
      approveContent: [
        {
          input
        },
        {
          userCampaignSocialContentId: true
        }
      ]
    })
  ).approveContent
}

export const toggleContentVisibility = async (input: ModelTypes['ToggleContentVisibilityInput']) => {
  return (
    await mApi('mutation')({
      toggleContentVisibility: [
        {
          input
        },
        {
          userCampaignSocialContentId: true,
          isHidden: true
        }
      ]
    })
  ).toggleContentVisibility
}

export const requestContentEdits = async (input: ModelTypes['RequestContentEditsInput']) => {
  return (
    await mApi('mutation')({
      requestContentEdits: [
        {
          input
        },
        {
          message: true,
          forAssetAtIndex: true,
          atTime: true,
          createdByFirstName: true,
          createdByLastName: true,
          visibleToUser: true,
          createdAt: true
        }
      ]
    })
  ).requestContentEdits
}

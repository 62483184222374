import { isNumber, last } from 'lodash'

export const getCampaignReviewsGoalHit = (
  expectedReviewsCount: number = 0,
  reviewMetrics: {
    fromDate: string
    momentumReviewCount: number
  }[] = []
) => {
  const reviewsAccumulated = (reviewMetrics || []).reduce(
    (acc: { date: string; momentumReviewCount: number }[], r) => [
      ...acc,
      { date: r.fromDate, momentumReviewCount: (last(acc)?.momentumReviewCount || 0) + r.momentumReviewCount }
    ],
    []
  )

  return isNumber(expectedReviewsCount)
    ? reviewsAccumulated.find(r => r.momentumReviewCount >= expectedReviewsCount)?.date
    : undefined
}

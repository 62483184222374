import { ZeusInterceptor } from '../types'
import { AGENCY_SAMPLE_COMPANIES, SAMPLE_COMPANY } from './sample-data'

export const getCompany: ZeusInterceptor<'query', 'getCompany'> = async args => {
  const companies = [SAMPLE_COMPANY, ...AGENCY_SAMPLE_COMPANIES]
  const company = companies.find(c => c.id === args.o.getCompany?.[0].id)

  if (company) {
    return company
  }
}

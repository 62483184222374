import { EmptyIcon } from './EmptyIcon'
import { Stack, Typography } from '@mui/material'
import React from 'react'

export const EmptyStateType = () => {
  return (
    <Stack pt={12} spacing={3} alignItems={'center'} justifyContent={'center'}>
      <EmptyIcon sx={{ height: '156px', width: '204px' }} />
      <Typography variant={'h5'}>Benchmarking data is not available for this campaign type.</Typography>
      <Typography variant={'label4'}>Benchmarking data is only available for Product Launch campaign.</Typography>
    </Stack>
  )
}

import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'

export const updateUserCampaignSocialContent = async (
  input: ValueTypes['ModelInputUpdateUserCampaignSocialContent']
) => {
  return (
    await mApi('mutation')({
      updateUserCampaignSocialContent: [{ input }, { id: true }]
    })
  ).updateUserCampaignSocialContent
}

// @ts-nocheck
import { CreatorApprovalStatus, CreatorType, ModelTypes, Region } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CREATORS_FOR_APPROVAL_HEADPHONES: Array<ModelTypes['CampaignCreatorForApproval']> = [
  {
    userCampaignId: 'ab9d1397-1775-4db3-be4c-152cabcc1f4d',
    dateOfBirth: null,
    locale: 'CA',
    creator: {
      instagramProfile: {
        handle: 'yo52gaming',
        followers: 4319,
        engagementRate: 94.94790460754805,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'aricyo52',
        followers: 100120,
        engagementRate: 9.026881818471931,
        isPrimary: false
      },
      youtubeProfile: {
        handle: 'aricyo52',
        followers: 876,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: ['https://www.tiktok.com/@aricyo52/video/7283982363909098757'],
      email: 'yo52gaming@gmail.com',
      firstName: 'Aric',
      lastName: 'Yo52'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'REJECTED',
      updatedAt: '2023-11-30T15:55:10.402Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: 'primarily Canadian; would prefer to keep in the US.'
    }
  },
  {
    userCampaignId: '460888f6-b835-46ff-b74b-a34a5b4c9ba3',
    dateOfBirth: '1998-06-07',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'victorflaggjr',
        followers: 15090,
        engagementRate: 0.9419672441541229,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'vflaggjr',
        followers: 79,
        engagementRate: null,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: [
        'https://www.instagram.com/reel/CiNy7--jqnS/?igshid=MzRlODBiNWFlZA==',
        'https://www.instagram.com/reel/Ch7upWmD5Fv/?igshid=MzRlODBiNWFlZA==',
        'https://www.instagram.com/reel/CUqlKDzDgdT/?igshid=MzRlODBiNWFlZA=='
      ],
      email: 'victor22_f@yahoo.com',
      firstName: 'Victor',
      lastName: 'Flagg'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'REJECTED',
      updatedAt: '2023-11-30T16:00:12.796Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: 'Not enough gaming content.'
    }
  },
  {
    userCampaignId: 'f4c48bcb-c3dd-41e2-80fe-2d1ad0de3416',
    dateOfBirth: '1992-01-20',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'illestplayzz',
        followers: 28643,
        engagementRate: 0.001113710156059072,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'kwisegaming',
        followers: 71453,
        engagementRate: 3.929296020117164,
        isPrimary: false
      },
      youtubeProfile: {
        handle: 'kwise',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [],
      email: 'kwise401@gmail.com',
      firstName: 'Kadeem',
      lastName: 'Leslie'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'REJECTED',
      updatedAt: '2023-11-30T16:02:05.254Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: 'Not enough gaming content.'
    }
  },
  {
    userCampaignId: '84697953-d28b-41cc-97b5-77c76c267444',
    dateOfBirth: '2002-09-03',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'heytayven',
        followers: 4117,
        engagementRate: 3.0143308234151083,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'heytayven',
        followers: 41990,
        engagementRate: 2.1333019509233346,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: ['https://www.tiktok.com/@heytayven/video/7298440065817267502'],
      email: 'heytayvenbusiness@gmail.com',
      firstName: 'Tayven',
      lastName: 'Jonathan'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'PENDING',
      updatedAt: '2023-11-30T15:53:37.846Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '23082897-078c-4c91-a1b4-7408c63d2ae5',
    dateOfBirth: '2003-02-22',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'happyslappin',
        followers: 506,
        engagementRate: 18.181818181818183,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'thehappyslapper',
        followers: 54269,
        engagementRate: 12.324620871425184,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'thehappyslapper',
        followers: 147,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZTRoRxKu7/?t=1',
        'https://www.tiktok.com/t/ZTRoRPW1a/?t=1',
        'https://www.tiktok.com/@thehappyslapper/video/7236424646017436971',
        'https://www.tiktok.com/@thehappyslapper/video/7228306111521164587'
      ],
      email: 'happyslapper222@gmail.com',
      firstName: 'Cain',
      lastName: 'Meyer'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'PENDING',
      updatedAt: '2023-11-30T15:57:12.762Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '543ea10f-fbc4-42a9-945c-127732199bcd',
    dateOfBirth: '1997-03-07',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'skibidichili',
        followers: 22406,
        engagementRate: 3.628950050968399,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'waynetwinzfn',
        followers: 3440,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT8exofwK/',
        'https://www.tiktok.com/t/ZT8exEqUx/',
        'https://www.tiktok.com/t/ZT8ex7h6U/'
      ],
      email: 'hernandezmatt11@gmail.com',
      firstName: 'Matthew',
      lastName: 'Hernandez'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:57:28.314Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: 'ed190bf1-dbe3-484d-af60-1567b5b1b386',
    dateOfBirth: '1994-02-08',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'tslayz',
        followers: 73709,
        engagementRate: 0.5634318739909645,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'tslayz',
        followers: 115052,
        engagementRate: 4.7361299052774015,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'tslayz',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/ChpngXYp7sI/?igshid=ZWQyN2ExYTkwZQ==',
        'https://www.tiktok.com/t/ZT8JRqGWw/',
        'https://www.tiktok.com/t/ZT8JREnxd/'
      ],
      email: 'contact@tslayz.com',
      firstName: 'Taylor',
      lastName: 'Cortis'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:59:45.216Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback:
        "Good with her but please ensure she's wearing an appropriate top when wearing/talking about SoundSlayer (no suggestive or overly revealing tops)"
    }
  },
  {
    userCampaignId: '46e30d80-d468-4932-b4f5-5c19060f621a',
    dateOfBirth: '2001-10-07',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'jnatysin',
        followers: 44048,
        engagementRate: 5.04047093451067,
        isPrimary: true
      },
      youtubeProfile: null,
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT8JE5fop/',
        'https://www.tiktok.com/t/ZT8JEVEBc/',
        'https://www.tiktok.com/t/ZT8JEhRUD/'
      ],
      email: 'jnatysin@yahoo.com',
      firstName: 'Jacob',
      lastName: 'Natysin'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:00:31.901Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '46e30d80-d468-4932-b4f5-5c19060f621a',
    dateOfBirth: '2001-10-07',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'jnatysin',
        followers: 44048,
        engagementRate: 5.04047093451067,
        isPrimary: true
      },
      youtubeProfile: null,
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT8JE5fop/',
        'https://www.tiktok.com/t/ZT8JEVEBc/',
        'https://www.tiktok.com/t/ZT8JEhRUD/'
      ],
      email: 'jnatysin@yahoo.com',
      firstName: 'Jacob',
      lastName: 'Natysin'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:00:31.901Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '46e30d80-d468-4932-b4f5-5c19060f621a',
    dateOfBirth: '2001-10-07',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'jnatysin',
        followers: 44048,
        engagementRate: 5.04047093451067,
        isPrimary: true
      },
      youtubeProfile: null,
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT8JE5fop/',
        'https://www.tiktok.com/t/ZT8JEVEBc/',
        'https://www.tiktok.com/t/ZT8JEhRUD/'
      ],
      email: 'jnatysin@yahoo.com',
      firstName: 'Jacob',
      lastName: 'Natysin'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:00:31.901Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '46e30d80-d468-4932-b4f5-5c19060f621a',
    dateOfBirth: '2001-10-07',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'jnatysin',
        followers: 44048,
        engagementRate: 5.04047093451067,
        isPrimary: true
      },
      youtubeProfile: null,
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT8JE5fop/',
        'https://www.tiktok.com/t/ZT8JEVEBc/',
        'https://www.tiktok.com/t/ZT8JEhRUD/'
      ],
      email: 'jnatysin@yahoo.com',
      firstName: 'Jacob',
      lastName: 'Natysin'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:00:31.901Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '6af2b876-c6d5-4c11-ad4c-74b0550377f4',
    dateOfBirth: '1992-03-30',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'itsjayesco',
        followers: 88060,
        engagementRate: 5.299886500071857,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: ['https://www.tiktok.com/@itsjayesco/video/7294740328710409515'],
      email: 'itsjayesco@gmail.com',
      firstName: 'Jose',
      lastName: 'Escobar'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:00:47.718Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '8d2222d9-fe89-4633-85a2-7d3a8c360323',
    dateOfBirth: '1993-11-27',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'theoceaneopz',
        followers: 8359,
        engagementRate: 2.1892570881684414,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'theoceaneopz',
        followers: 31212,
        engagementRate: 5.226661326972132,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'theoceaneopz',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CjddriBv0nS/?igshid=MzRlODBiNWFlZA==',
        'https://www.tiktok.com/t/ZT8ek6t27/',
        'https://www.instagram.com/tv/Cd_d1ynFf_u/?igshid=MzRlODBiNWFlZA=='
      ],
      email: 'oceanebusiness@gmail.com',
      firstName: 'Oceane',
      lastName: 'Mathis'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'PENDING',
      updatedAt: '2023-11-30T16:01:14.112Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '4e0aaf54-176b-4148-a581-146e42a934fc',
    dateOfBirth: '1997-05-21',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'yonugggaming',
        followers: 917,
        engagementRate: 7.404580152671756,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'yonugggaming',
        followers: 27947,
        engagementRate: 12.941176470588237,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'yonugggaming',
        followers: 588,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/@yonugggaming/video/7285065194701671723',
        'https://www.instagram.com/reel/CwTMbG_snW1/',
        'https://www.instagram.com/reel/CoFrI2oOpdY/'
      ],
      email: 'yonugggaming@gmail.com',
      firstName: 'Yonugg',
      lastName: 'Gaming'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:05:03.006Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback:
        "He's Ok from a content perspective but a little concerned he was just promoting headsets/gaming headphones a couple of weeks ago - if this is too close perhaps we pass."
    }
  },
  {
    userCampaignId: '46889128-abbb-4cf8-9eb8-2545538db8c7',
    dateOfBirth: '2002-11-13',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'necxzz',
        followers: 22492,
        engagementRate: 0.9269962653387871,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'necx_zz',
        followers: 54179,
        engagementRate: 0.7924699592732225,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: [],
      email: 'necxzzbusiness@gmail.com',
      firstName: 'Ryan',
      lastName: 'Levenson'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:54:09.652Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: 'f4e57b54-8c87-401a-895e-d7b7f2bce576',
    dateOfBirth: '1993-01-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'thewinniedapoohhh',
        followers: 4759,
        engagementRate: 1.979407438537508,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'thewinniedapoohhh',
        followers: 51118,
        engagementRate: 16.997518610421835,
        isPrimary: false
      },
      youtubeProfile: {
        handle: 'winniedapoohhh',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: ['https://www.tiktok.com/@thewinniedapoohhh/video/7314799094344518955'],
      email: 'winniedapoohhh1@gmail.com',
      firstName: 'Jeanie',
      lastName: 'McDonald'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:56:25.980Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: 'ab9d1397-1775-4db3-be4c-152cabcc1f4d',
    dateOfBirth: null,
    locale: 'CA',
    creator: {
      instagramProfile: {
        handle: 'yo52gaming',
        followers: 4319,
        engagementRate: 94.94790460754805,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'aricyo52',
        followers: 100120,
        engagementRate: 9.026881818471931,
        isPrimary: false
      },
      youtubeProfile: {
        handle: 'aricyo52',
        followers: 876,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: ['https://www.tiktok.com/@aricyo52/video/7283982363909098757'],
      email: 'yo52gaming@gmail.com',
      firstName: 'Aric',
      lastName: 'Yo52'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'REJECTED',
      updatedAt: '2023-11-30T15:55:10.402Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: 'primarily Canadian; would prefer to keep in the US.'
    }
  },
  {
    userCampaignId: '460888f6-b835-46ff-b74b-a34a5b4c9ba3',
    dateOfBirth: '1998-06-07',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'victorflaggjr',
        followers: 15090,
        engagementRate: 0.9419672441541229,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'vflaggjr',
        followers: 79,
        engagementRate: null,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: [
        'https://www.instagram.com/reel/CiNy7--jqnS/?igshid=MzRlODBiNWFlZA==',
        'https://www.instagram.com/reel/Ch7upWmD5Fv/?igshid=MzRlODBiNWFlZA==',
        'https://www.instagram.com/reel/CUqlKDzDgdT/?igshid=MzRlODBiNWFlZA=='
      ],
      email: 'victor22_f@yahoo.com',
      firstName: 'Victor',
      lastName: 'Flagg'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'REJECTED',
      updatedAt: '2023-11-30T16:00:12.796Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: 'Not enough gaming content.'
    }
  },
  {
    userCampaignId: 'f4c48bcb-c3dd-41e2-80fe-2d1ad0de3416',
    dateOfBirth: '1992-01-20',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'illestplayzz',
        followers: 28643,
        engagementRate: 0.001113710156059072,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'kwisegaming',
        followers: 71453,
        engagementRate: 3.929296020117164,
        isPrimary: false
      },
      youtubeProfile: {
        handle: 'kwise',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [],
      email: 'kwise401@gmail.com',
      firstName: 'Kadeem',
      lastName: 'Leslie'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'REJECTED',
      updatedAt: '2023-11-30T16:02:05.254Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: 'Not enough gaming content.'
    }
  },
  {
    userCampaignId: '84697953-d28b-41cc-97b5-77c76c267444',
    dateOfBirth: '2002-09-03',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'heytayven',
        followers: 4117,
        engagementRate: 3.0143308234151083,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'heytayven',
        followers: 41990,
        engagementRate: 2.1333019509233346,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: ['https://www.tiktok.com/@heytayven/video/7298440065817267502'],
      email: 'heytayvenbusiness@gmail.com',
      firstName: 'Tayven',
      lastName: 'Jonathan'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:53:37.846Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '23082897-078c-4c91-a1b4-7408c63d2ae5',
    dateOfBirth: '2003-02-22',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'happyslappin',
        followers: 506,
        engagementRate: 18.181818181818183,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'thehappyslapper',
        followers: 54269,
        engagementRate: 12.324620871425184,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'thehappyslapper',
        followers: 147,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZTRoRxKu7/?t=1',
        'https://www.tiktok.com/t/ZTRoRPW1a/?t=1',
        'https://www.tiktok.com/@thehappyslapper/video/7236424646017436971',
        'https://www.tiktok.com/@thehappyslapper/video/7228306111521164587'
      ],
      email: 'happyslapper222@gmail.com',
      firstName: 'Cain',
      lastName: 'Meyer'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:57:12.762Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '543ea10f-fbc4-42a9-945c-127732199bcd',
    dateOfBirth: '1997-03-07',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'skibidichili',
        followers: 22406,
        engagementRate: 3.628950050968399,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'waynetwinzfn',
        followers: 3440,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT8exofwK/',
        'https://www.tiktok.com/t/ZT8exEqUx/',
        'https://www.tiktok.com/t/ZT8ex7h6U/'
      ],
      email: 'hernandezmatt11@gmail.com',
      firstName: 'Matthew',
      lastName: 'Hernandez'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:57:28.314Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: 'ed190bf1-dbe3-484d-af60-1567b5b1b386',
    dateOfBirth: '1994-02-08',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'tslayz',
        followers: 73709,
        engagementRate: 0.5634318739909645,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'tslayz',
        followers: 115052,
        engagementRate: 4.7361299052774015,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'tslayz',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/ChpngXYp7sI/?igshid=ZWQyN2ExYTkwZQ==',
        'https://www.tiktok.com/t/ZT8JRqGWw/',
        'https://www.tiktok.com/t/ZT8JREnxd/'
      ],
      email: 'contact@tslayz.com',
      firstName: 'Taylor',
      lastName: 'Cortis'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:59:45.216Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback:
        "Good with her but please ensure she's wearing an appropriate top when wearing/talking about SoundSlayer (no suggestive or overly revealing tops)"
    }
  },
  {
    userCampaignId: '46e30d80-d468-4932-b4f5-5c19060f621a',
    dateOfBirth: '2001-10-07',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'jnatysin',
        followers: 44048,
        engagementRate: 5.04047093451067,
        isPrimary: true
      },
      youtubeProfile: null,
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT8JE5fop/',
        'https://www.tiktok.com/t/ZT8JEVEBc/',
        'https://www.tiktok.com/t/ZT8JEhRUD/'
      ],
      email: 'jnatysin@yahoo.com',
      firstName: 'Jacob',
      lastName: 'Natysin'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:00:31.901Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '6af2b876-c6d5-4c11-ad4c-74b0550377f4',
    dateOfBirth: '1992-03-30',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'itsjayesco',
        followers: 88060,
        engagementRate: 5.299886500071857,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: ['https://www.tiktok.com/@itsjayesco/video/7294740328710409515'],
      email: 'itsjayesco@gmail.com',
      firstName: 'Jose',
      lastName: 'Escobar'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:00:47.718Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '8d2222d9-fe89-4633-85a2-7d3a8c360323',
    dateOfBirth: '1993-11-27',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'theoceaneopz',
        followers: 8359,
        engagementRate: 2.1892570881684414,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'theoceaneopz',
        followers: 31212,
        engagementRate: 5.226661326972132,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'theoceaneopz',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CjddriBv0nS/?igshid=MzRlODBiNWFlZA==',
        'https://www.tiktok.com/t/ZT8ek6t27/',
        'https://www.instagram.com/tv/Cd_d1ynFf_u/?igshid=MzRlODBiNWFlZA=='
      ],
      email: 'oceanebusiness@gmail.com',
      firstName: 'Oceane',
      lastName: 'Mathis'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:01:14.112Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '4e0aaf54-176b-4148-a581-146e42a934fc',
    dateOfBirth: '1997-05-21',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'yonugggaming',
        followers: 917,
        engagementRate: 7.404580152671756,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'yonugggaming',
        followers: 27947,
        engagementRate: 12.941176470588237,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'yonugggaming',
        followers: 588,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/@yonugggaming/video/7285065194701671723',
        'https://www.instagram.com/reel/CwTMbG_snW1/',
        'https://www.instagram.com/reel/CoFrI2oOpdY/'
      ],
      email: 'yonugggaming@gmail.com',
      firstName: 'Yonugg',
      lastName: 'Gaming'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:05:03.006Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback:
        "He's Ok from a content perspective but a little concerned he was just promoting headsets/gaming headphones a couple of weeks ago - if this is too close perhaps we pass."
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '46889128-abbb-4cf8-9eb8-2545538db8c7',
    dateOfBirth: '2002-11-13',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'necxzz',
        followers: 22492,
        engagementRate: 0.9269962653387871,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'necx_zz',
        followers: 54179,
        engagementRate: 0.7924699592732225,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: [],
      email: 'necxzzbusiness@gmail.com',
      firstName: 'Ryan',
      lastName: 'Levenson'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:54:09.652Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: 'f4e57b54-8c87-401a-895e-d7b7f2bce576',
    dateOfBirth: '1993-01-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'thewinniedapoohhh',
        followers: 4759,
        engagementRate: 1.979407438537508,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'thewinniedapoohhh',
        followers: 51118,
        engagementRate: 16.997518610421835,
        isPrimary: false
      },
      youtubeProfile: {
        handle: 'winniedapoohhh',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: ['https://www.tiktok.com/@thewinniedapoohhh/video/7314799094344518955'],
      email: 'winniedapoohhh1@gmail.com',
      firstName: 'Jeanie',
      lastName: 'McDonald'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:56:25.980Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: 'ab9d1397-1775-4db3-be4c-152cabcc1f4d',
    dateOfBirth: null,
    locale: 'CA',
    creator: {
      instagramProfile: {
        handle: 'yo52gaming',
        followers: 4319,
        engagementRate: 94.94790460754805,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'aricyo52',
        followers: 100120,
        engagementRate: 9.026881818471931,
        isPrimary: false
      },
      youtubeProfile: {
        handle: 'aricyo52',
        followers: 876,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: ['https://www.tiktok.com/@aricyo52/video/7283982363909098757'],
      email: 'yo52gaming@gmail.com',
      firstName: 'Aric',
      lastName: 'Yo52'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'REJECTED',
      updatedAt: '2023-11-30T15:55:10.402Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: 'primarily Canadian; would prefer to keep in the US.'
    }
  },
  {
    userCampaignId: '460888f6-b835-46ff-b74b-a34a5b4c9ba3',
    dateOfBirth: '1998-06-07',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'victorflaggjr',
        followers: 15090,
        engagementRate: 0.9419672441541229,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'vflaggjr',
        followers: 79,
        engagementRate: null,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: [
        'https://www.instagram.com/reel/CiNy7--jqnS/?igshid=MzRlODBiNWFlZA==',
        'https://www.instagram.com/reel/Ch7upWmD5Fv/?igshid=MzRlODBiNWFlZA==',
        'https://www.instagram.com/reel/CUqlKDzDgdT/?igshid=MzRlODBiNWFlZA=='
      ],
      email: 'victor22_f@yahoo.com',
      firstName: 'Victor',
      lastName: 'Flagg'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'REJECTED',
      updatedAt: '2023-11-30T16:00:12.796Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: 'Not enough gaming content.'
    }
  },
  {
    userCampaignId: 'f4c48bcb-c3dd-41e2-80fe-2d1ad0de3416',
    dateOfBirth: '1992-01-20',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'illestplayzz',
        followers: 28643,
        engagementRate: 0.001113710156059072,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'kwisegaming',
        followers: 71453,
        engagementRate: 3.929296020117164,
        isPrimary: false
      },
      youtubeProfile: {
        handle: 'kwise',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [],
      email: 'kwise401@gmail.com',
      firstName: 'Kadeem',
      lastName: 'Leslie'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'REJECTED',
      updatedAt: '2023-11-30T16:02:05.254Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: 'Not enough gaming content.'
    }
  },
  {
    userCampaignId: '84697953-d28b-41cc-97b5-77c76c267444',
    dateOfBirth: '2002-09-03',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'heytayven',
        followers: 4117,
        engagementRate: 3.0143308234151083,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'heytayven',
        followers: 41990,
        engagementRate: 2.1333019509233346,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: ['https://www.tiktok.com/@heytayven/video/7298440065817267502'],
      email: 'heytayvenbusiness@gmail.com',
      firstName: 'Tayven',
      lastName: 'Jonathan'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:53:37.846Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '23082897-078c-4c91-a1b4-7408c63d2ae5',
    dateOfBirth: '2003-02-22',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'happyslappin',
        followers: 506,
        engagementRate: 18.181818181818183,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'thehappyslapper',
        followers: 54269,
        engagementRate: 12.324620871425184,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'thehappyslapper',
        followers: 147,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZTRoRxKu7/?t=1',
        'https://www.tiktok.com/t/ZTRoRPW1a/?t=1',
        'https://www.tiktok.com/@thehappyslapper/video/7236424646017436971',
        'https://www.tiktok.com/@thehappyslapper/video/7228306111521164587'
      ],
      email: 'happyslapper222@gmail.com',
      firstName: 'Cain',
      lastName: 'Meyer'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:57:12.762Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '543ea10f-fbc4-42a9-945c-127732199bcd',
    dateOfBirth: '1997-03-07',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'skibidichili',
        followers: 22406,
        engagementRate: 3.628950050968399,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'waynetwinzfn',
        followers: 3440,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT8exofwK/',
        'https://www.tiktok.com/t/ZT8exEqUx/',
        'https://www.tiktok.com/t/ZT8ex7h6U/'
      ],
      email: 'hernandezmatt11@gmail.com',
      firstName: 'Matthew',
      lastName: 'Hernandez'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:57:28.314Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: 'ed190bf1-dbe3-484d-af60-1567b5b1b386',
    dateOfBirth: '1994-02-08',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'tslayz',
        followers: 73709,
        engagementRate: 0.5634318739909645,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'tslayz',
        followers: 115052,
        engagementRate: 4.7361299052774015,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'tslayz',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/ChpngXYp7sI/?igshid=ZWQyN2ExYTkwZQ==',
        'https://www.tiktok.com/t/ZT8JRqGWw/',
        'https://www.tiktok.com/t/ZT8JREnxd/'
      ],
      email: 'contact@tslayz.com',
      firstName: 'Taylor',
      lastName: 'Cortis'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T15:59:45.216Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback:
        "Good with her but please ensure she's wearing an appropriate top when wearing/talking about SoundSlayer (no suggestive or overly revealing tops)"
    }
  },
  {
    userCampaignId: '46e30d80-d468-4932-b4f5-5c19060f621a',
    dateOfBirth: '2001-10-07',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'jnatysin',
        followers: 44048,
        engagementRate: 5.04047093451067,
        isPrimary: true
      },
      youtubeProfile: null,
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT8JE5fop/',
        'https://www.tiktok.com/t/ZT8JEVEBc/',
        'https://www.tiktok.com/t/ZT8JEhRUD/'
      ],
      email: 'jnatysin@yahoo.com',
      firstName: 'Jacob',
      lastName: 'Natysin'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:00:31.901Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '6af2b876-c6d5-4c11-ad4c-74b0550377f4',
    dateOfBirth: '1992-03-30',
    locale: 'US',
    creator: {
      instagramProfile: null,
      tiktokProfile: {
        handle: 'itsjayesco',
        followers: 88060,
        engagementRate: 5.299886500071857,
        isPrimary: false
      },
      youtubeProfile: null,
      sponsoredPosts: ['https://www.tiktok.com/@itsjayesco/video/7294740328710409515'],
      email: 'itsjayesco@gmail.com',
      firstName: 'Jose',
      lastName: 'Escobar'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:00:47.718Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '8d2222d9-fe89-4633-85a2-7d3a8c360323',
    dateOfBirth: '1993-11-27',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'theoceaneopz',
        followers: 8359,
        engagementRate: 2.1892570881684414,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'theoceaneopz',
        followers: 31212,
        engagementRate: 5.226661326972132,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'theoceaneopz',
        followers: null,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CjddriBv0nS/?igshid=MzRlODBiNWFlZA==',
        'https://www.tiktok.com/t/ZT8ek6t27/',
        'https://www.instagram.com/tv/Cd_d1ynFf_u/?igshid=MzRlODBiNWFlZA=='
      ],
      email: 'oceanebusiness@gmail.com',
      firstName: 'Oceane',
      lastName: 'Mathis'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:01:14.112Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '4e0aaf54-176b-4148-a581-146e42a934fc',
    dateOfBirth: '1997-05-21',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'yonugggaming',
        followers: 917,
        engagementRate: 7.404580152671756,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'yonugggaming',
        followers: 27947,
        engagementRate: 12.941176470588237,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'yonugggaming',
        followers: 588,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/@yonugggaming/video/7285065194701671723',
        'https://www.instagram.com/reel/CwTMbG_snW1/',
        'https://www.instagram.com/reel/CoFrI2oOpdY/'
      ],
      email: 'yonugggaming@gmail.com',
      firstName: 'Yonugg',
      lastName: 'Gaming'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:05:03.006Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback:
        "He's Ok from a content perspective but a little concerned he was just promoting headsets/gaming headphones a couple of weeks ago - if this is too close perhaps we pass."
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'APPROVED',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: '50dff259-8eaf-44f1-9470-904d56d4f37d',
    dateOfBirth: '1995-02-26',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'daneeyelle',
        followers: 8193,
        engagementRate: 2.976931526913219,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'daneeyelle',
        followers: 13665,
        engagementRate: 9.005425797557901,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'daneeyelle',
        followers: 636,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.instagram.com/reel/CqMDLYLJcWH/?igshid=ZjE2NGZiNDQ=',
        'https://www.tiktok.com/t/ZTRcKkPg5/',
        'https://www.tiktok.com/t/ZTRcK9qaq/',
        'https://www.tiktok.com/@daneeyelle/video/7205365867691986219',
        'https://www.instagram.com/reel/Co5q4GvsXUp/'
      ],
      email: 'daneeyelle@gmail.com',
      firstName: 'Danielle',
      lastName: 'Overcast'
    },
    creatorType: 'IG',
    approvalState: {
      status: 'PENDING',
      updatedAt: '2023-11-30T16:06:20.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback: null
    }
  },
  {
    userCampaignId: 'bc1f9992-c085-4064-b0cb-6485bd836a89',
    dateOfBirth: '1986-02-10',
    locale: 'US',
    creator: {
      instagramProfile: {
        handle: 'punchbrotv',
        followers: 1272,
        engagementRate: 0.5345911949685535,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'punchbro',
        followers: 76750,
        engagementRate: 2.559867877786953,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'punchbro',
        followers: 4120,
        engagementRate: null,
        isPrimary: null
      },
      sponsoredPosts: [
        'https://www.tiktok.com/t/ZT88puf45/',
        'https://www.tiktok.com/t/ZT88p9Ecr/',
        'https://www.tiktok.com/t/ZT88p7URT/'
      ],
      email: 'tv@punchbro.tv',
      firstName: 'Tim',
      lastName: 'Vallier'
    },
    creatorType: 'TT',
    approvalState: {
      status: 'PENDING',
      updatedAt: '2023-11-30T16:03:47.409Z',
      reviewedByUserId: '142195cd-71ab-4fe7-9f00-be69bc84adef',
      reviewedByUserEmail: 'melissa.housel@us.panasonic.com',
      reviewedByUserName: null,
      feedback:
        "This one is OK so long as he's able to use SoundSlayer with a PC (or worst case PS5). No Switch or Xbox. If that's not possible, we should remove him."
    }
  }
]

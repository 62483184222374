import { ProposalGoal, PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import { DateTime } from 'luxon'
import { keyBy, max } from 'lodash'

import { getTimelineSteps, toWeeklyPartition } from '@productwindtom/shared-momentum'

export const useTimelineSteps = ({
  goal,
  creatorPricing,
  launchDate,
  eventDate,
  isVendor,
  estimatedUnitsSoldPerWeek,
  reviewsGoal,
  isDailySchedule
}: {
  goal?: ProposalGoal
  creatorPricing: ProposalCreatorPricingInput[]
  launchDate?: DateTime
  eventDate?: DateTime
  isVendor?: boolean
  estimatedUnitsSoldPerWeek?: number
  reviewsGoal?: number
  isDailySchedule?: boolean
}) => {
  const isMonthly = goal === ProposalGoal.EVERGREEN

  const creatorPricingByType = keyBy(creatorPricing, 'type')

  const timelineSteps = getTimelineSteps({
    premiumUgcWeeklySchedule: getFormatSchedule(
      creatorPricingByType[PricingCreatorType.PREMIUM_UGC]?.schedule || [],
      isDailySchedule
    ),
    ugcWeeklySchedule: getFormatSchedule(creatorPricingByType[PricingCreatorType.UGC]?.schedule || [], isDailySchedule),
    socialWeeklySchedule: getFormatSchedule(
      creatorPricingByType[PricingCreatorType.SOCIAL]?.schedule || [],
      isDailySchedule
    ),
    brandAdvocatesWeeklySchedule: getFormatSchedule(
      creatorPricingByType[PricingCreatorType.ADVOCATE]?.schedule || [],
      isDailySchedule
    ),
    hasBrandAdvocates: !!creatorPricingByType[PricingCreatorType.ADVOCATE]?.numCreators,
    hasUgc: !!creatorPricingByType[PricingCreatorType.UGC]?.numCreators,
    hasPremiumUgc: !!creatorPricingByType[PricingCreatorType.PREMIUM_UGC]?.numCreators,
    hasSocial: !!creatorPricingByType[PricingCreatorType.SOCIAL]?.numCreators,
    startDate: launchDate,
    eventDate,
    unitsSoldPerWeek: estimatedUnitsSoldPerWeek,
    reviewsGoal,
    isVendor: isVendor
  })
  const lastDate = max(timelineSteps.map(step => step.date))

  const durationWeeks = launchDate && lastDate ? Math.ceil(lastDate.diff(launchDate, 'weeks').weeks) : undefined

  return {
    isMonthly,
    timelineSteps,
    durationWeeks
  }
}

const getFormatSchedule = (schedule: number[], isDaily?: boolean) => {
  return isDaily ? toWeeklyPartition(schedule) : schedule
}

import { CreatorApprovalStatus, CreatorType, ModelTypes, Region } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CREATORS_FOR_APPROVAL_PILLOW: Array<ModelTypes['CampaignCreatorForApproval']> = [
  {
    dateOfBirth: '1993-06-13',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T18:59:51.832Z'
    },
    userCampaignId: '808a0c5b-c7b1-464c-91f4-9f2dd3d6ef7a',
    creator: {
      sponsoredPosts: [],
      id: '808a0c5b-c7b1-464c-91f4-9f2dd3d6ef7a',
      firstName: 'Emily',
      lastName: 'Quach',
      email: 'contact.helloemy@yahoo.com',
      instagramProfile: {
        handle: 'lovelyemy',
        followers: 60709,
        engagementRate: 0.20145283236422934,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1994-03-06',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:00:40.286Z'
    },
    userCampaignId: '1c326b62-d0d7-4333-aebc-602060ef5313',
    creator: {
      sponsoredPosts: [],
      id: '1c326b62-d0d7-4333-aebc-602060ef5313',
      firstName: 'Erin',
      lastName: 'Bowlware',
      email: 'erin@herhappyhustle.com',
      instagramProfile: {
        handle: 'heyitserintay',
        followers: 15320,
        engagementRate: 2.101827676240209,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'heyitserintay',
        followers: 164,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1992-01-27',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-26T19:36:53.714Z'
    },
    userCampaignId: '33680235-b270-44e4-ad9a-e089be79d138',
    creator: {
      sponsoredPosts: [],
      id: '33680235-b270-44e4-ad9a-e089be79d138',
      firstName: 'Mariann',
      lastName: 'Yip',
      email: 'mariann.yip@gmail.com',
      instagramProfile: {
        handle: 'mariann_yip',
        followers: 112912,
        engagementRate: 0.7566954796655803,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'mariann_yip',
        followers: 3997,
        engagementRate: 10.584958217270195,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1996-09-18',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:03:42.247Z'
    },
    userCampaignId: '2f274835-d964-4b49-9a52-d7119b1a30fe',
    creator: {
      sponsoredPosts: [],
      id: '2f274835-d964-4b49-9a52-d7119b1a30fe',
      firstName: 'Aubrei',
      lastName: 'Jones',
      email: 'aubrei2014@gmail.com',
      tiktokProfile: {
        handle: 'earlgreypls',
        followers: 43142,
        engagementRate: 12.472108265320578,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1998-02-28',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:11:33.574Z'
    },
    userCampaignId: 'fce1b1da-54b4-4a01-84d2-cf4cdc49057a',
    creator: {
      sponsoredPosts: [],
      id: 'fce1b1da-54b4-4a01-84d2-cf4cdc49057a',
      firstName: 'Madison',
      lastName: 'Allshouse',
      email: 'madison@houseofprieto.com',
      instagramProfile: {
        handle: 'madisonallshouse',
        followers: 2874,
        engagementRate: 2.3208072372999307,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'madisonallshouse',
        followers: 66467,
        engagementRate: 5.514447782433394,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1997-04-23',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:12:11.790Z'
    },
    userCampaignId: 'f50ad31a-7e9d-4a7c-aa58-aee7242eaf58',
    creator: {
      sponsoredPosts: [],
      id: 'f50ad31a-7e9d-4a7c-aa58-aee7242eaf58',
      firstName: 'Kaitlyn',
      lastName: 'Corbin',
      email: 'kaitlynmackenzee@gmail.com',
      instagramProfile: {
        handle: 'kaitlynmackenzee',
        followers: 9211,
        engagementRate: 3.559874063619585,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'kaitlynmackenzee',
        followers: 13349,
        engagementRate: 4.577115150810604,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1998-07-10',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:14:28.443Z'
    },
    userCampaignId: '17eb1a42-b395-42bb-9028-9c19c5817436',
    creator: {
      sponsoredPosts: [],
      id: '17eb1a42-b395-42bb-9028-9c19c5817436',
      firstName: 'Aunt',
      lastName: 'Sassy',
      email: 'auntsassy.collab@gmail.com',
      instagramProfile: {
        handle: 'auntsassyashy',
        followers: 32706,
        engagementRate: 7.13691677368067,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'auntsassyashy',
        followers: 6476,
        engagementRate: 4.574363308156677,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'auntsassyashy'
      }
    }
  },
  {
    dateOfBirth: '1993-07-08',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:15:24.915Z'
    },
    userCampaignId: 'ff62a556-015c-44d8-a1ea-24c94bac9e7f',
    creator: {
      sponsoredPosts: [],
      id: 'ff62a556-015c-44d8-a1ea-24c94bac9e7f',
      firstName: 'Caroline',
      lastName: 'Coscia',
      email: 'carolinecosc@gmail.com',
      tiktokProfile: {
        handle: 'carolinecosc',
        followers: 14712,
        engagementRate: 5.1381029804940965,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1994-06-29',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-26T19:39:01.256Z'
    },
    userCampaignId: '5ff5651c-d6cd-48a7-99e4-32bb99b316c5',
    creator: {
      sponsoredPosts: [],
      id: '5ff5651c-d6cd-48a7-99e4-32bb99b316c5',
      firstName: 'evelyn',
      lastName: 'hidalgo',
      email: 'fromevelynsheart@gmail.com',
      instagramProfile: {
        handle: 'fromevelynsheart',
        followers: 16467,
        engagementRate: 3.032732130928524,
        isPrimary: true
      },
      tiktokProfile: {
        handle: '_fromevelynsheart',
        followers: 52521,
        engagementRate: 5.899602520168822,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1995-03-23',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-16T15:34:44.439Z'
    },
    userCampaignId: 'b5f7f1ca-a833-4842-9f09-5ce276555747',
    creator: {
      sponsoredPosts: [],
      id: 'b5f7f1ca-a833-4842-9f09-5ce276555747',
      firstName: 'Ana',
      lastName: 'Abala',
      email: 'abdala.carol@gmail.com',
      instagramProfile: {
        handle: 'carolinaabdala',
        followers: 145107,
        engagementRate: 1.9309199418360246,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'imcarolinaabdala',
        followers: 17314,
        isPrimary: true
      }
    }
  },
  {
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:17:35.453Z'
    },
    userCampaignId: 'fa5b4993-9902-4adf-b727-99d90c89d8bf',
    creator: {
      sponsoredPosts: [],
      id: 'fa5b4993-9902-4adf-b727-99d90c89d8bf',
      firstName: 'Hannah',
      lastName: 'Walker',
      email: 'hannah13christine@gmail.com',
      instagramProfile: {
        handle: 'hannahhwalker__',
        followers: 111280,
        engagementRate: 3.063533429187635,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'hannahhwalker__',
        followers: 124073,
        engagementRate: 3.841795075280678,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1995-12-15',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-26T19:40:20.892Z'
    },
    userCampaignId: 'd1e04fe8-ecc3-4f82-9c64-9dcddd9dbfc0',
    creator: {
      sponsoredPosts: [],
      id: 'd1e04fe8-ecc3-4f82-9c64-9dcddd9dbfc0',
      firstName: 'TaJarre',
      lastName: 'Crisler',
      email: 'tajarrecrisler@yahoo.com',
      instagramProfile: {
        handle: 'tajarrecrisler',
        followers: 23342,
        engagementRate: 1.325936080884243,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'tajarrecrisler',
        followers: 85983,
        engagementRate: 11.315909313338752,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1992-08-20',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T18:59:30.723Z'
    },
    userCampaignId: '8bde0d0a-4b77-4b3f-a06b-4ea2fe6833ba',
    creator: {
      sponsoredPosts: [],
      id: '8bde0d0a-4b77-4b3f-a06b-4ea2fe6833ba',
      firstName: 'cristina ',
      lastName: 'sisson',
      email: 'cristina_moralesw@yahoo.com',
      instagramProfile: {
        handle: 'cristina.sisson',
        followers: 13335,
        engagementRate: 1.001874765654293,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'cristinasiss',
        followers: 10208,
        engagementRate: 5.554962999003078,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1996-05-30',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-16T15:35:00.133Z'
    },
    userCampaignId: '554baa5e-3184-4bc9-98db-3d3942ee65c0',
    creator: {
      sponsoredPosts: [],
      id: '554baa5e-3184-4bc9-98db-3d3942ee65c0',
      firstName: 'Stef',
      lastName: 'Anderson',
      email: 'stefanderson530@gmail.com',
      instagramProfile: {
        handle: 'sideofstef',
        followers: 10646,
        engagementRate: 3.579748262258126,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'sideofstef',
        followers: 24977,
        engagementRate: 5.00361390367304,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'sideofstef',
        followers: 58
      }
    }
  },
  {
    dateOfBirth: '1999-03-31',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:23:20.992Z'
    },
    userCampaignId: '90409e23-5757-4066-a93f-f1ed1d377fce',
    creator: {
      sponsoredPosts: [],
      id: '90409e23-5757-4066-a93f-f1ed1d377fce',
      firstName: 'Gicelle',
      lastName: 'Arciga',
      email: 'gicellexx@gmail.com',
      tiktokProfile: {
        handle: 'gicellex',
        followers: 13275,
        engagementRate: 8.444131073963707,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1995-10-28',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-14T19:23:45.247Z'
    },
    userCampaignId: '6b04e09b-8810-4f05-ac94-320a21c7fbaf',
    creator: {
      sponsoredPosts: [],
      id: '6b04e09b-8810-4f05-ac94-320a21c7fbaf',
      firstName: 'Rachel',
      lastName: 'Samford',
      email: 'rachelsamford@gmail.com',
      tiktokProfile: {
        handle: 'rachelsamford',
        followers: 20142,
        engagementRate: 6.668308227114716,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'rachelsamford'
      }
    }
  },
  {
    dateOfBirth: '1994-08-23',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-26T19:40:39.387Z'
    },
    userCampaignId: 'faf53c7c-42ac-468e-8d58-35a9de43e026',
    creator: {
      sponsoredPosts: [],
      id: 'faf53c7c-42ac-468e-8d58-35a9de43e026',
      firstName: 'Kalah',
      lastName: 'Gonzales',
      email: 'kalahgonzales@gmail.com',
      instagramProfile: {
        handle: 'kalahgonzales',
        followers: 32293,
        engagementRate: 3.7435357507819034,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1998-02-19',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2024-02-26T19:40:59.173Z'
    },
    userCampaignId: 'df8ca55e-57e2-49d0-8e25-5c71a6bff72e',
    creator: {
      sponsoredPosts: [],
      id: 'df8ca55e-57e2-49d0-8e25-5c71a6bff72e',
      firstName: 'Sara',
      lastName: 'Feller',
      email: 'sarafeller64@gmail.com',
      instagramProfile: {
        handle: 'lifewiththe_fellers',
        followers: 27518,
        engagementRate: 3.320372120066865,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'sara.amira.f',
        followers: 76,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1998-06-06',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-27T17:51:09.671Z',
      reviewedByUserId: '3bb86eb1-b3fb-4fb4-a9a1-bf38210a8624',
      reviewedByUserEmail: 'lada@productwind.com'
    },
    userCampaignId: 'b583184d-f15f-4bf1-bfdb-4f24a9f5c353',
    creator: {
      sponsoredPosts: [],
      id: 'b583184d-f15f-4bf1-bfdb-4f24a9f5c353',
      firstName: 'Vanessa',
      lastName: 'Acuna',
      email: 'jnvutube@gmail.com',
      instagramProfile: {
        handle: 'this.season.of.life',
        followers: 24020,
        engagementRate: 2.6915070774354706,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'vanessamilian07',
        followers: 3566,
        engagementRate: 5.766616655519604,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1986-02-11',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-27T17:51:25.210Z',
      reviewedByUserId: '3bb86eb1-b3fb-4fb4-a9a1-bf38210a8624',
      reviewedByUserEmail: 'lada@productwind.com'
    },
    userCampaignId: '8f39b95a-e095-4d2a-a4ba-12d42cbfafbe',
    creator: {
      sponsoredPosts: [],
      id: '8f39b95a-e095-4d2a-a4ba-12d42cbfafbe',
      firstName: 'Svitlana',
      lastName: 'Ato',
      email: 'svitlanaato@gmail.com',
      instagramProfile: {
        handle: 'lanaato',
        followers: 100549,
        engagementRate: 3.4392186893952204,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'lanaato',
        followers: 18030,
        engagementRate: 1.6561312481730488,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'lanaato3257'
      }
    }
  },
  {
    dateOfBirth: '1994-10-04',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-27T17:51:46.592Z',
      reviewedByUserId: '3bb86eb1-b3fb-4fb4-a9a1-bf38210a8624',
      reviewedByUserEmail: 'lada@productwind.com'
    },
    userCampaignId: '2f44d75d-d004-4734-b9fd-309ec55af8bd',
    creator: {
      sponsoredPosts: [],
      id: '2f44d75d-d004-4734-b9fd-309ec55af8bd',
      firstName: 'Alyssa',
      lastName: 'Kimber',
      email: 'alyssakimberstyle@gmail.com',
      instagramProfile: {
        handle: 'alyssa_kimber',
        followers: 41546,
        engagementRate: 0.25899003514177055,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'alyssakimber',
        followers: 513150,
        engagementRate: 2.6213262028130826,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'alyssakimber',
        followers: 1660
      }
    }
  },
  {
    dateOfBirth: '1989-02-03',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-27T17:51:32.115Z',
      reviewedByUserId: '3bb86eb1-b3fb-4fb4-a9a1-bf38210a8624',
      reviewedByUserEmail: 'lada@productwind.com'
    },
    userCampaignId: 'bed50908-bd13-473d-91cf-3c9555b7382f',
    creator: {
      sponsoredPosts: [],
      id: 'bed50908-bd13-473d-91cf-3c9555b7382f',
      firstName: 'Jennifer',
      lastName: 'Wakumelo',
      email: 'wakumelocollabs@gmail.com',
      instagramProfile: {
        handle: 'our.blog.life',
        followers: 32863,
        engagementRate: 5.056750753126617,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'our.blog.life',
        followers: 6040,
        engagementRate: 2.564102564102564,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1998-03-16',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2024-02-27T17:51:20.088Z',
      reviewedByUserId: '3bb86eb1-b3fb-4fb4-a9a1-bf38210a8624',
      reviewedByUserEmail: 'lada@productwind.com'
    },
    userCampaignId: '18122d52-9884-4423-9661-7e41fcb9fc97',
    creator: {
      sponsoredPosts: [],
      id: '18122d52-9884-4423-9661-7e41fcb9fc97',
      firstName: 'Morgan',
      lastName: 'Barbieri',
      email: 'morganbarbieri12@gmail.com',
      instagramProfile: {
        handle: 'morganbarbierii',
        followers: 12844,
        engagementRate: 19.391933976954217,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'morganbarbierii',
        followers: 141558,
        engagementRate: 12.757342974806846,
        isPrimary: false
      }
    }
  }
]

import { mApi } from '@momentum/api'
import { ModelSortDirection } from '@productwindtom/shared-momentum-zeus-types'
import {
  ProductKeywordRecord,
  ProductKeywordSummary,
  productKeywordRecordSelector,
  productKeywordSummarySelector,
  productSeoSummarySelector
} from './selectors'

export const getProductSeoSummary = async (campaignId: string) => {
  return (
    await mApi('query')({
      getProductSeoSummary: [{ campaignId }, productSeoSummarySelector]
    })
  ).getProductSeoSummary
}

export const listProductKeywordSummaries = async (
  campaignId: string,
  nextToken?: string
): Promise<ProductKeywordSummary[]> => {
  const response = (
    await mApi('query')({
      listProductKeywordSummaries: [
        { campaignId, nextToken },
        {
          items: productKeywordSummarySelector,
          nextToken: true
        }
      ]
    })
  ).listProductKeywordSummaries

  if (response.nextToken) {
    return [...response.items, ...(await listProductKeywordSummaries(campaignId, response.nextToken))]
  }

  return response.items
}

export const listProductKeywordRecords = async (
  campaignId: string,
  keyword: string,
  nextToken?: string
): Promise<ProductKeywordRecord[]> => {
  const response = (
    await mApi('query')({
      listProductKeywordRecords: [
        {
          campaignId_keyword: `${campaignId}_${keyword}`,
          sortDirection: ModelSortDirection.ASC,
          nextToken
        },
        {
          items: productKeywordRecordSelector,
          nextToken: true
        }
      ]
    })
  ).listProductKeywordRecords

  if (response.nextToken) {
    return [...response.items, ...(await listProductKeywordRecords(campaignId, keyword, response.nextToken))]
  }

  return response.items
}

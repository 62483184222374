import { CampaignPerformance } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'
import { PerformanceComparisonMetric, PerformanceSummaryTitleType } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_PERFORMANCE_WIRELESS_EARBUDS: CampaignPerformance = {
  campaignId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id,
  skuId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.skuId,
  promotedProductId: '254d3fb9-b5db-414b-9a10-bfc147ff15e1',
  isVisible: false,
  isTtrVisible: true,

  comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
  comparisonMetricValue: 100,
  summaryTitle: PerformanceSummaryTitleType.SIMILAR_PRODUCT,
  products: [
    {
      id: '9ee4de79-d658-4a4e-9ce2-8292300c08e7',
      productName: 'Bluetooth Headphones with 16 Hours Playtime',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds-2.png',
      productPerformanceRecords: [
        {
          date: '2021-12-25',
          salesRank: 5046
        },
        {
          date: '2021-12-26',

          salesRank: 4164
        },
        {
          date: '2021-12-27',

          salesRank: 1292
        },
        {
          date: '2021-12-28',

          salesRank: 1183
        },
        {
          date: '2021-12-29',

          salesRank: 1084
        },
        {
          date: '2021-12-30',

          salesRank: 923
        },
        {
          date: '2021-12-31',

          salesRank: 837
        },
        {
          date: '2022-01-01',

          salesRank: 606
        },
        {
          date: '2022-01-02',

          salesRank: 96
        },
        {
          date: '2022-01-03',

          salesRank: 157
        },
        {
          date: '2022-01-04',
          reviewCount: 2,
          rating: 5,
          salesRank: 278
        },
        {
          date: '2022-01-05',
          reviewCount: 2,
          rating: 5,
          salesRank: 329
        },
        {
          date: '2022-01-06',
          reviewCount: 2,
          rating: 5,
          salesRank: 480
        },
        {
          date: '2022-01-07',
          reviewCount: 2,
          rating: 5,
          salesRank: 877
        },
        {
          date: '2022-01-08',
          reviewCount: 2,
          rating: 5,
          salesRank: 1357
        },
        {
          date: '2022-01-09',
          reviewCount: 2,
          rating: 5,
          salesRank: 1827
        },
        {
          date: '2022-01-10',
          reviewCount: 2,
          rating: 5,
          salesRank: 2373
        },
        {
          date: '2022-01-11',
          reviewCount: 7,
          rating: 5,
          salesRank: 3060
        },
        {
          date: '2022-01-12',
          reviewCount: 7,
          rating: 5,
          salesRank: 3088
        },
        {
          date: '2022-01-13',
          reviewCount: 7,
          rating: 5,
          salesRank: 2880
        },
        {
          date: '2022-01-14',
          reviewCount: 7,
          rating: 5,
          salesRank: 2740
        },
        {
          date: '2022-01-15',
          reviewCount: 7,
          rating: 5,
          salesRank: 2965
        },
        {
          date: '2022-01-16',
          reviewCount: 7,
          rating: 5,
          salesRank: 3217
        },
        {
          date: '2022-01-17',
          reviewCount: 7,
          rating: 5,
          salesRank: 3688
        },
        {
          date: '2022-01-18',
          reviewCount: 10,
          rating: 4,
          salesRank: 4184
        },
        {
          date: '2022-01-19',
          reviewCount: 10,
          rating: 4,
          salesRank: 4596
        },
        {
          date: '2022-01-20',
          reviewCount: 10,
          rating: 4,
          salesRank: 4593
        },
        {
          date: '2022-01-21',
          reviewCount: 10,
          rating: 4,
          salesRank: 4861
        },
        {
          date: '2022-01-22',
          reviewCount: 10,
          rating: 4,
          salesRank: 4738
        },
        {
          date: '2022-01-23',
          reviewCount: 10,
          rating: 4,
          salesRank: 4700
        },
        {
          date: '2022-01-24',
          reviewCount: 10,
          rating: 4,
          salesRank: 4252
        },
        {
          date: '2022-01-25',
          reviewCount: 12,
          rating: 4,
          salesRank: 2889
        },
        {
          date: '2022-01-26',
          reviewCount: 12,
          rating: 4,
          salesRank: 1437
        },
        {
          date: '2022-01-27',
          reviewCount: 12,
          rating: 4,
          salesRank: 1077
        },
        {
          date: '2022-01-28',
          reviewCount: 12,
          rating: 4,
          salesRank: 832
        },
        {
          date: '2022-01-29',
          reviewCount: 12,
          rating: 4,
          salesRank: 732
        },
        {
          date: '2022-01-30',
          reviewCount: 12,
          rating: 4,
          salesRank: 628
        },
        {
          date: '2022-01-31',
          reviewCount: 12,
          rating: 4,
          salesRank: 593
        },
        {
          date: '2022-02-01',
          reviewCount: 12,
          rating: 4,
          salesRank: 359
        },
        {
          date: '2022-02-02',
          reviewCount: 15,
          rating: 4,
          salesRank: 411
        },
        {
          date: '2022-02-03',
          reviewCount: 15,
          rating: 4,
          salesRank: 256
        },
        {
          date: '2022-02-04',
          reviewCount: 15,
          rating: 4,
          salesRank: 219
        },
        {
          date: '2022-02-05',
          reviewCount: 15,
          rating: 4,
          salesRank: 201
        },
        {
          date: '2022-02-06',
          reviewCount: 15,
          rating: 4,
          salesRank: 170
        },
        {
          date: '2022-02-07',
          reviewCount: 15,
          rating: 4,
          salesRank: 123
        },
        {
          date: '2022-02-08',
          reviewCount: 15,
          rating: 4,
          salesRank: 119
        },
        {
          date: '2022-02-09',
          reviewCount: 21,
          rating: 4,
          salesRank: 154
        },
        {
          date: '2022-02-10',
          reviewCount: 21,
          rating: 4,
          salesRank: 120
        },
        {
          date: '2022-02-11',
          reviewCount: 21,
          rating: 4,
          salesRank: 211
        },
        {
          date: '2022-02-12',
          reviewCount: 21,
          rating: 4,
          salesRank: 147
        },
        {
          date: '2022-02-13',
          reviewCount: 21,
          rating: 4,
          salesRank: 157
        },
        {
          date: '2022-02-14',
          reviewCount: 21,
          rating: 4,
          salesRank: 150
        },
        {
          date: '2022-02-15',
          reviewCount: 21,
          rating: 4,
          salesRank: 161
        },
        {
          date: '2022-02-16',
          reviewCount: 34,
          rating: 4,
          salesRank: 156
        },
        {
          date: '2022-02-17',
          reviewCount: 34,
          rating: 4,
          salesRank: 123
        },
        {
          date: '2022-02-18',
          reviewCount: 34,
          rating: 4,
          salesRank: 124
        },
        {
          date: '2022-02-19',
          reviewCount: 34,
          rating: 4,
          salesRank: 119
        },
        {
          date: '2022-02-20',
          reviewCount: 34,
          rating: 4,
          salesRank: 90
        },
        {
          date: '2022-02-21',
          reviewCount: 34,
          rating: 4,
          salesRank: 120
        },
        {
          date: '2022-02-22',
          reviewCount: 34,
          rating: 4,
          salesRank: 83
        },
        {
          date: '2022-02-23',
          reviewCount: 61,
          rating: 4,
          salesRank: 114
        },
        {
          date: '2022-02-24',
          reviewCount: 61,
          rating: 4,
          salesRank: 164
        },
        {
          date: '2022-02-25',
          reviewCount: 61,
          rating: 4,
          salesRank: 142
        },
        {
          date: '2022-02-26',
          reviewCount: 61,
          rating: 4,
          salesRank: 143
        },
        {
          date: '2022-02-27',
          reviewCount: 61,
          rating: 4,
          salesRank: 152
        },
        {
          date: '2022-02-28',
          reviewCount: 61,
          rating: 4,
          salesRank: 144
        },
        {
          date: '2022-03-01',
          reviewCount: 61,
          rating: 4,
          salesRank: 126
        },
        {
          date: '2022-03-02',
          reviewCount: 61,
          rating: 4,
          salesRank: 119
        },
        {
          date: '2022-03-03',
          reviewCount: 72,
          rating: 4,
          salesRank: 144
        },
        {
          date: '2022-03-04',
          reviewCount: 72,
          rating: 4,
          salesRank: 134
        },
        {
          date: '2022-03-05',
          reviewCount: 72,
          rating: 4,
          salesRank: 134
        },
        {
          date: '2022-03-06',
          reviewCount: 72,
          rating: 4,
          salesRank: 161
        },
        {
          date: '2022-03-07',
          reviewCount: 72,
          rating: 4,
          salesRank: 121
        },
        {
          date: '2022-03-08',
          reviewCount: 72,
          rating: 4,
          salesRank: 121
        },
        {
          date: '2022-03-09',
          reviewCount: 72,
          rating: 4,
          salesRank: 128
        },
        {
          date: '2022-03-10',
          reviewCount: 104,
          rating: 4,
          salesRank: 135
        },
        {
          date: '2022-03-11',
          reviewCount: 104,
          rating: 4,
          salesRank: 183
        },
        {
          date: '2022-03-12',
          reviewCount: 104,
          rating: 4,
          salesRank: 167
        },
        {
          date: '2022-03-13',
          reviewCount: 104,
          rating: 4,
          salesRank: 148
        },
        {
          date: '2022-03-14',
          reviewCount: 104,
          rating: 4,
          salesRank: 143
        },
        {
          date: '2022-03-15',
          reviewCount: 104,
          rating: 4,
          salesRank: 143
        },
        {
          date: '2022-03-16',
          reviewCount: 104,
          rating: 4,
          salesRank: 147
        },
        {
          date: '2022-03-17',
          reviewCount: 146,
          rating: 4,
          salesRank: 126
        },
        {
          date: '2022-03-18',
          reviewCount: 146,
          rating: 4,
          salesRank: 165
        },
        {
          date: '2022-03-19',
          reviewCount: 146,
          rating: 4,
          salesRank: 170
        },
        {
          date: '2022-03-20',
          reviewCount: 146,
          rating: 4,
          salesRank: 132
        },
        {
          date: '2022-03-21',
          reviewCount: 146,
          rating: 4,
          salesRank: 122
        },
        {
          date: '2022-03-22',
          reviewCount: 146,
          rating: 4,
          salesRank: 167
        },
        {
          date: '2022-03-23',
          reviewCount: 146,
          rating: 4,
          salesRank: 174
        },
        {
          date: '2022-03-24',
          reviewCount: 160,
          rating: 4,
          salesRank: 181
        },
        {
          date: '2022-03-25',
          reviewCount: 160,
          rating: 4,
          salesRank: 212
        },
        {
          date: '2022-03-26',
          reviewCount: 160,
          rating: 4,
          salesRank: 200
        },
        {
          date: '2022-03-27',
          reviewCount: 160,
          rating: 4,
          salesRank: 190
        },
        {
          date: '2022-03-28',
          reviewCount: 160,
          rating: 4,
          salesRank: 215
        },
        {
          date: '2022-03-29',
          reviewCount: 160,
          rating: 4,
          salesRank: 169
        },
        {
          date: '2022-03-30',
          reviewCount: 160,
          rating: 4,
          salesRank: 173
        },
        {
          date: '2022-03-31',
          reviewCount: 181,
          rating: 4,
          salesRank: 228
        },
        {
          date: '2022-04-01',
          reviewCount: 181,
          rating: 4,
          salesRank: 270
        },
        {
          date: '2022-04-02',
          reviewCount: 181,
          rating: 4,
          salesRank: 273
        },
        {
          date: '2022-04-03',
          reviewCount: 181,
          rating: 4,
          salesRank: 293
        },
        {
          date: '2022-04-04',
          reviewCount: 181,
          rating: 4,
          salesRank: 193
        },
        {
          date: '2022-04-05',
          reviewCount: 181,
          rating: 4,
          salesRank: 219
        },
        {
          date: '2022-04-06',
          reviewCount: 181,
          rating: 4,
          salesRank: 203
        },
        {
          date: '2022-04-07',
          reviewCount: 218,
          rating: 4,
          salesRank: 201
        },
        {
          date: '2022-04-08',
          reviewCount: 218,
          rating: 4,
          salesRank: 169
        },
        {
          date: '2022-04-09',
          reviewCount: 218,
          rating: 4,
          salesRank: 158
        },
        {
          date: '2022-04-10',
          reviewCount: 218,
          rating: 4,
          salesRank: 201
        },
        {
          date: '2022-04-11',
          reviewCount: 218,
          rating: 4,
          salesRank: 195
        },
        {
          date: '2022-04-12',
          reviewCount: 218,
          rating: 4,
          salesRank: 176
        },
        {
          date: '2022-04-13',
          reviewCount: 218,
          rating: 4,
          salesRank: 177
        },
        {
          date: '2022-04-14',
          reviewCount: 266,
          rating: 4,
          salesRank: 178
        },
        {
          date: '2022-04-15',
          reviewCount: 266,
          rating: 4,
          salesRank: 182
        },
        {
          date: '2022-04-16',
          reviewCount: 266,
          rating: 4
        },
        {
          date: '2022-04-17',
          reviewCount: 266,
          rating: 4,
          salesRank: 139
        },
        {
          date: '2022-04-18',
          reviewCount: 266,
          rating: 4,
          salesRank: 174
        },
        {
          date: '2022-04-19',
          reviewCount: 266,
          rating: 4,
          salesRank: 144
        },
        {
          date: '2022-04-20',
          reviewCount: 266,
          rating: 4,
          salesRank: 105
        },
        {
          date: '2022-04-21',
          reviewCount: 266,
          rating: 4,
          salesRank: 95
        },
        {
          date: '2022-04-22',
          reviewCount: 319,
          rating: 4,
          salesRank: 119
        },
        {
          date: '2022-04-23',
          reviewCount: 319,
          rating: 4,
          salesRank: 125
        },
        {
          date: '2022-04-24',
          reviewCount: 319,
          rating: 4,
          salesRank: 103
        },
        {
          date: '2022-04-25',
          reviewCount: 319,
          rating: 4,
          salesRank: 65
        },
        {
          date: '2022-04-26',
          reviewCount: 319,
          rating: 4,
          salesRank: 55
        },
        {
          date: '2022-04-27',
          reviewCount: 319,
          rating: 4,
          salesRank: 77
        },
        {
          date: '2022-04-28',
          reviewCount: 319,
          rating: 4,
          salesRank: 81
        },
        {
          date: '2022-04-29',
          reviewCount: 366,
          rating: 4,
          salesRank: 92
        },
        {
          date: '2022-04-30',
          reviewCount: 366,
          rating: 4,
          salesRank: 75
        },
        {
          date: '2022-05-01',
          reviewCount: 366,
          rating: 4,
          salesRank: 86
        },
        {
          date: '2022-05-02',
          reviewCount: 366,
          rating: 4,
          salesRank: 49
        },
        {
          date: '2022-05-03',
          reviewCount: 366,
          rating: 4,
          salesRank: 61
        },
        {
          date: '2022-05-04',
          reviewCount: 366,
          rating: 4,
          salesRank: 61
        },
        {
          date: '2022-05-05',
          reviewCount: 366,
          rating: 4,
          salesRank: 68
        },
        {
          date: '2022-05-06',
          reviewCount: 400,
          rating: 4,
          salesRank: 65
        },
        {
          date: '2022-05-07',
          reviewCount: 400,
          rating: 4,
          salesRank: 69
        },
        {
          date: '2022-05-08',
          reviewCount: 400,
          rating: 4,
          salesRank: 72
        },
        {
          date: '2022-05-09',
          reviewCount: 400,
          rating: 4,
          salesRank: 68
        },
        {
          date: '2022-05-10',
          reviewCount: 400,
          rating: 4,
          salesRank: 58
        },
        {
          date: '2022-05-11',
          reviewCount: 400,
          rating: 4,
          salesRank: 51
        },
        {
          date: '2022-05-12',
          reviewCount: 400,
          rating: 4,
          salesRank: 61
        },
        {
          date: '2022-05-13',
          reviewCount: 400,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2022-05-14',
          reviewCount: 457,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2022-05-15',
          reviewCount: 457,
          rating: 4,
          salesRank: 44
        },
        {
          date: '2022-05-16',
          reviewCount: 457,
          rating: 4,
          salesRank: 26
        },
        {
          date: '2022-05-17',
          reviewCount: 457,
          rating: 4,
          salesRank: 30
        },
        {
          date: '2022-05-18',
          reviewCount: 457,
          rating: 4,
          salesRank: 34
        },
        {
          date: '2022-05-19',
          reviewCount: 457,
          rating: 4,
          salesRank: 37
        },
        {
          date: '2022-05-20',
          reviewCount: 457,
          rating: 4,
          salesRank: 38
        },
        {
          date: '2022-05-21',
          reviewCount: 524,
          rating: 4,
          salesRank: 34
        },
        {
          date: '2022-05-22',
          reviewCount: 524,
          rating: 4,
          salesRank: 29
        },
        {
          date: '2022-05-23',
          reviewCount: 524,
          rating: 4,
          salesRank: 23
        },
        {
          date: '2022-05-24',
          reviewCount: 524,
          rating: 4,
          salesRank: 22
        },
        {
          date: '2022-05-25',
          reviewCount: 524,
          rating: 4,
          salesRank: 25
        },
        {
          date: '2022-05-26',
          reviewCount: 524,
          rating: 4,
          salesRank: 26
        },
        {
          date: '2022-05-27',
          reviewCount: 524,
          rating: 4,
          salesRank: 21
        },
        {
          date: '2022-05-28',
          reviewCount: 524,
          rating: 4,
          salesRank: 24
        },
        {
          date: '2022-05-29',
          reviewCount: 524,
          rating: 4,
          salesRank: 23
        },
        {
          date: '2022-05-30',
          reviewCount: 524,
          rating: 4,
          salesRank: 23
        },
        {
          date: '2022-05-31',
          reviewCount: 524,
          rating: 4,
          salesRank: 24
        },
        {
          date: '2022-06-01',
          reviewCount: 624,
          rating: 4,
          salesRank: 22
        },
        {
          date: '2022-06-02',
          reviewCount: 624,
          rating: 4,
          salesRank: 24
        },
        {
          date: '2022-06-03',
          reviewCount: 624,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2022-06-04',
          reviewCount: 624,
          rating: 4,
          salesRank: 36
        },
        {
          date: '2022-06-05',
          reviewCount: 624,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2022-06-06',
          reviewCount: 624,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2022-06-07',
          reviewCount: 624,
          rating: 4,
          salesRank: 25
        },
        {
          date: '2022-06-08',
          reviewCount: 624,
          rating: 4,
          salesRank: 30
        },
        {
          date: '2022-06-09',
          reviewCount: 624,
          rating: 4,
          salesRank: 37
        },
        {
          date: '2022-06-10',
          reviewCount: 624,
          rating: 4,
          salesRank: 39
        },
        {
          date: '2022-06-11',
          reviewCount: 725,
          rating: 4,
          salesRank: 37
        },
        {
          date: '2022-06-12',
          reviewCount: 725,
          rating: 4,
          salesRank: 44
        },
        {
          date: '2022-06-13',
          reviewCount: 725,
          rating: 4,
          salesRank: 45
        },
        {
          date: '2022-06-14',
          reviewCount: 725,
          rating: 4,
          salesRank: 49
        },
        {
          date: '2022-06-15',
          reviewCount: 725,
          rating: 4,
          salesRank: 33
        },
        {
          date: '2022-06-16',
          reviewCount: 725,
          rating: 4,
          salesRank: 32
        },
        {
          date: '2022-06-17',
          reviewCount: 725,
          rating: 4,
          salesRank: 42
        },
        {
          date: '2022-06-18',
          reviewCount: 725,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2022-06-19',
          reviewCount: 725,
          rating: 4
        },
        {
          date: '2022-06-20',
          reviewCount: 807,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2022-06-21',
          reviewCount: 807,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2022-06-22',
          reviewCount: 807,
          rating: 4,
          salesRank: 32
        },
        {
          date: '2022-06-23',
          reviewCount: 807,
          rating: 4
        },
        {
          date: '2022-06-24',
          reviewCount: 807,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2022-06-25',
          reviewCount: 807,
          rating: 4,
          salesRank: 32
        },
        {
          date: '2022-06-26',
          reviewCount: 807,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2022-06-27',
          reviewCount: 807,
          rating: 4,
          salesRank: 27
        },
        {
          date: '2022-06-28',
          reviewCount: 807,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2022-06-29',
          reviewCount: 807,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2022-06-30',
          reviewCount: 948,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2022-07-01',
          reviewCount: 948,
          rating: 4,
          salesRank: 35
        },
        {
          date: '2022-07-02',
          reviewCount: 948,
          rating: 4,
          salesRank: 40
        },
        {
          date: '2022-07-03',
          reviewCount: 948,
          rating: 4,
          salesRank: 38
        },
        {
          date: '2022-07-04',
          reviewCount: 948,
          rating: 4,
          salesRank: 43
        },
        {
          date: '2022-07-05',
          reviewCount: 948,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2022-07-06',
          reviewCount: 948,
          rating: 4,
          salesRank: 29
        },
        {
          date: '2022-07-07',
          reviewCount: 948,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2022-07-08',
          reviewCount: 948,
          rating: 4,
          salesRank: 44
        },
        {
          date: '2022-07-09',
          reviewCount: 948,
          rating: 4,
          salesRank: 42
        },
        {
          date: '2022-07-10',
          reviewCount: 1104,
          rating: 4,
          salesRank: 35
        },
        {
          date: '2022-07-11',
          reviewCount: 1104,
          rating: 4,
          salesRank: 43
        },
        {
          date: '2022-07-12',
          reviewCount: 1104,
          rating: 4,
          salesRank: 82
        },
        {
          date: '2022-07-13',
          reviewCount: 1104,
          rating: 4,
          salesRank: 65
        },
        {
          date: '2022-07-14',
          reviewCount: 1104,
          rating: 4,
          salesRank: 63
        },
        {
          date: '2022-07-15',
          reviewCount: 1104,
          rating: 4,
          salesRank: 62
        },
        {
          date: '2022-07-16',
          reviewCount: 1104,
          rating: 4,
          salesRank: 59
        },
        {
          date: '2022-07-17',
          reviewCount: 1104,
          rating: 4,
          salesRank: 56
        },
        {
          date: '2022-07-18',
          reviewCount: 1104,
          rating: 4,
          salesRank: 53
        },
        {
          date: '2022-07-19',
          reviewCount: 1104,
          rating: 4,
          salesRank: 47
        },
        {
          date: '2022-07-20',
          reviewCount: 1104,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2022-07-21',
          reviewCount: 1294,
          rating: 4,
          salesRank: 48
        },
        {
          date: '2022-07-22',
          reviewCount: 1294,
          rating: 4,
          salesRank: 50
        },
        {
          date: '2022-07-23',
          reviewCount: 1294,
          rating: 4,
          salesRank: 45
        },
        {
          date: '2022-07-24',
          reviewCount: 1294,
          rating: 4,
          salesRank: 45
        },
        {
          date: '2022-07-25',
          reviewCount: 1294,
          rating: 4,
          salesRank: 45
        },
        {
          date: '2022-07-26',
          reviewCount: 1294,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2022-07-27',
          reviewCount: 1294,
          rating: 4,
          salesRank: 50
        },
        {
          date: '2022-07-28',
          reviewCount: 1294,
          rating: 4,
          salesRank: 51
        },
        {
          date: '2022-07-29',
          reviewCount: 1294,
          rating: 4,
          salesRank: 55
        },
        {
          date: '2022-07-30',
          reviewCount: 1294,
          rating: 4,
          salesRank: 72
        },
        {
          date: '2022-07-31',
          reviewCount: 1473,
          rating: 4,
          salesRank: 61
        },
        {
          date: '2022-08-01',
          reviewCount: 1473,
          rating: 4,
          salesRank: 63
        },
        {
          date: '2022-08-02',
          reviewCount: 1473,
          rating: 4,
          salesRank: 72
        },
        {
          date: '2022-08-03',
          reviewCount: 1473,
          rating: 4,
          salesRank: 52
        },
        {
          date: '2022-08-04',
          reviewCount: 1473,
          rating: 4,
          salesRank: 61
        },
        {
          date: '2022-08-05',
          reviewCount: 1473,
          rating: 4,
          salesRank: 65
        },
        {
          date: '2022-08-06',
          reviewCount: 1473,
          rating: 4,
          salesRank: 63
        },
        {
          date: '2022-08-07',
          reviewCount: 1473,
          rating: 4,
          salesRank: 81
        },
        {
          date: '2022-08-08',
          reviewCount: 1473,
          rating: 4,
          salesRank: 68
        },
        {
          date: '2022-08-09',
          reviewCount: 1473,
          rating: 4,
          salesRank: 82
        },
        {
          date: '2022-08-10',
          reviewCount: 1473,
          rating: 4,
          salesRank: 57
        },
        {
          date: '2022-08-11',
          reviewCount: 1473,
          rating: 4,
          salesRank: 81
        },
        {
          date: '2022-08-12',
          reviewCount: 1473,
          rating: 4,
          salesRank: 88
        },
        {
          date: '2022-08-13',
          reviewCount: 1726,
          rating: 4,
          salesRank: 99
        },
        {
          date: '2022-08-14',
          reviewCount: 1726,
          rating: 4,
          salesRank: 84
        },
        {
          date: '2022-08-15',
          reviewCount: 1726,
          rating: 4,
          salesRank: 84
        },
        {
          date: '2022-08-16',
          reviewCount: 1726,
          rating: 4,
          salesRank: 125
        },
        {
          date: '2022-08-17',
          reviewCount: 1726,
          rating: 4,
          salesRank: 96
        },
        {
          date: '2022-08-18',
          reviewCount: 1726,
          rating: 4,
          salesRank: 90
        },
        {
          date: '2022-08-19',
          reviewCount: 1726,
          rating: 4,
          salesRank: 85
        },
        {
          date: '2022-08-20',
          reviewCount: 1726,
          rating: 4,
          salesRank: 53
        },
        {
          date: '2022-08-21',
          reviewCount: 1726,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2022-08-22',
          reviewCount: 1726,
          rating: 4,
          salesRank: 65
        },
        {
          date: '2022-08-23',
          reviewCount: 1925,
          rating: 4,
          salesRank: 87
        },
        {
          date: '2022-08-24',
          reviewCount: 1925,
          rating: 4,
          salesRank: 97
        },
        {
          date: '2022-08-25',
          reviewCount: 1925,
          rating: 4,
          salesRank: 98
        },
        {
          date: '2022-08-26',
          reviewCount: 1925,
          rating: 4,
          salesRank: 109
        },
        {
          date: '2022-08-27',
          reviewCount: 1925,
          rating: 4,
          salesRank: 89
        },
        {
          date: '2022-08-28',
          reviewCount: 1925,
          rating: 4,
          salesRank: 79
        },
        {
          date: '2022-08-29',
          reviewCount: 1925,
          rating: 4,
          salesRank: 57
        },
        {
          date: '2022-08-30',
          reviewCount: 1925,
          rating: 4,
          salesRank: 75
        },
        {
          date: '2022-08-31',
          reviewCount: 1925,
          rating: 4,
          salesRank: 71
        },
        {
          date: '2022-09-01',
          reviewCount: 1925,
          rating: 4,
          salesRank: 58
        },
        {
          date: '2022-09-02',
          reviewCount: 1925,
          rating: 4,
          salesRank: 91
        },
        {
          date: '2022-09-03',
          reviewCount: 1925,
          rating: 4,
          salesRank: 48
        },
        {
          date: '2022-09-04',
          reviewCount: 2197,
          rating: 4,
          salesRank: 32
        },
        {
          date: '2022-09-05',
          reviewCount: 2197,
          rating: 4,
          salesRank: 37
        },
        {
          date: '2022-09-06',
          reviewCount: 2197,
          rating: 4,
          salesRank: 39
        },
        {
          date: '2022-09-07',
          reviewCount: 2197,
          rating: 4,
          salesRank: 33
        },
        {
          date: '2022-09-08',
          reviewCount: 2197,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2022-09-09',
          reviewCount: 2197,
          rating: 4
        },
        {
          date: '2022-09-10',
          reviewCount: 2197,
          rating: 4,
          salesRank: 26
        },
        {
          date: '2022-09-11',
          reviewCount: 2197,
          rating: 4,
          salesRank: 31
        },
        {
          date: '2022-09-12',
          reviewCount: 2197,
          rating: 4,
          salesRank: 33
        },
        {
          date: '2022-09-13',
          reviewCount: 2197,
          rating: 4,
          salesRank: 19
        },
        {
          date: '2022-09-14',
          reviewCount: 2197,
          rating: 4,
          salesRank: 33
        },
        {
          date: '2022-09-15',
          reviewCount: 2197,
          rating: 4,
          salesRank: 41
        },
        {
          date: '2022-09-16',
          reviewCount: 2197,
          rating: 4,
          salesRank: 45
        },
        {
          date: '2022-09-17',
          reviewCount: 2398,
          rating: 4
        },
        {
          date: '2022-09-18',
          reviewCount: 2398,
          rating: 4,
          salesRank: 38
        },
        {
          date: '2022-09-19',
          reviewCount: 2398,
          rating: 4,
          salesRank: 49
        },
        {
          date: '2022-09-20',
          reviewCount: 2398,
          rating: 4,
          salesRank: 48
        },
        {
          date: '2022-09-21',
          reviewCount: 2398,
          rating: 4,
          salesRank: 49
        },
        {
          date: '2022-09-22',
          reviewCount: 2398,
          rating: 4,
          salesRank: 51
        },
        {
          date: '2022-09-23',
          reviewCount: 2398,
          rating: 4,
          salesRank: 272
        },
        {
          date: '2022-09-24',
          reviewCount: 2398,
          rating: 4,
          salesRank: 298
        },
        {
          date: '2022-09-25',
          reviewCount: 2398,
          rating: 4,
          salesRank: 295
        },
        {
          date: '2022-09-26',
          reviewCount: 2560,
          rating: 4,
          salesRank: 169
        },
        {
          date: '2022-09-27',
          reviewCount: 2560,
          rating: 4,
          salesRank: 159
        },
        {
          date: '2022-09-28',
          reviewCount: 2560,
          rating: 4,
          salesRank: 150
        },
        {
          date: '2022-09-29',
          reviewCount: 2560,
          rating: 4,
          salesRank: 158
        },
        {
          date: '2022-09-30',
          reviewCount: 2560,
          rating: 4,
          salesRank: 143
        },
        {
          date: '2022-10-01',
          reviewCount: 2560,
          rating: 4,
          salesRank: 160
        },
        {
          date: '2022-10-02',
          reviewCount: 2560,
          rating: 4,
          salesRank: 207
        },
        {
          date: '2022-10-03',
          reviewCount: 2560,
          rating: 4,
          salesRank: 165
        },
        {
          date: '2022-10-04',
          reviewCount: 2702,
          rating: 4,
          salesRank: 175
        },
        {
          date: '2022-10-05',
          reviewCount: 2702,
          rating: 4,
          salesRank: 205
        },
        {
          date: '2022-10-06',
          reviewCount: 2702,
          rating: 4,
          salesRank: 195
        },
        {
          date: '2022-10-07',
          reviewCount: 2702,
          rating: 4,
          salesRank: 201
        },
        {
          date: '2022-10-08',
          reviewCount: 2702,
          rating: 4,
          salesRank: 227
        },
        {
          date: '2022-10-09',
          reviewCount: 2702,
          rating: 4,
          salesRank: 237
        },
        {
          date: '2022-10-10',
          reviewCount: 2702,
          rating: 4,
          salesRank: 210
        },
        {
          date: '2022-10-11',
          reviewCount: 2702,
          rating: 4,
          salesRank: 421
        },
        {
          date: '2022-10-12',
          reviewCount: 2702,
          rating: 4,
          salesRank: 359
        },
        {
          date: '2022-10-13',
          reviewCount: 2839,
          rating: 4,
          salesRank: 394
        },
        {
          date: '2022-10-14',
          reviewCount: 2839,
          rating: 4,
          salesRank: 372
        },
        {
          date: '2022-10-15',
          reviewCount: 2839,
          rating: 4,
          salesRank: 379
        },
        {
          date: '2022-10-16',
          reviewCount: 2839,
          rating: 4,
          salesRank: 356
        },
        {
          date: '2022-10-17',
          reviewCount: 2839,
          rating: 4,
          salesRank: 407
        },
        {
          date: '2022-10-18',
          reviewCount: 2839,
          rating: 4,
          salesRank: 370
        },
        {
          date: '2022-10-19',
          reviewCount: 2839,
          rating: 4,
          salesRank: 445
        },
        {
          date: '2022-10-20',
          reviewCount: 2839,
          rating: 4,
          salesRank: 554
        },
        {
          date: '2022-10-21',
          reviewCount: 2958,
          rating: 4,
          salesRank: 648
        },
        {
          date: '2022-10-22',
          reviewCount: 2971,
          rating: 4,
          salesRank: 780
        },
        {
          date: '2022-10-23',
          reviewCount: 2980,
          rating: 4,
          salesRank: 1000
        },
        {
          date: '2022-10-24',
          reviewCount: 2991,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2022-10-25',
          reviewCount: 3001,
          rating: 4,
          salesRank: 214
        },
        {
          date: '2022-10-26',
          reviewCount: 3008,
          rating: 4,
          salesRank: 213
        },
        {
          date: '2022-10-27',
          reviewCount: 3023,
          rating: 4,
          salesRank: 181
        },
        {
          date: '2022-10-28',
          reviewCount: 3023,
          rating: 4,
          salesRank: 182
        },
        {
          date: '2022-10-29',
          reviewCount: 3023,
          rating: 4,
          salesRank: 167
        },
        {
          date: '2022-10-30',
          reviewCount: 3060,
          rating: 4,
          salesRank: 166
        },
        {
          date: '2022-10-31',
          reviewCount: 3060,
          rating: 4,
          salesRank: 209
        },
        {
          date: '2022-11-01',
          reviewCount: 3060,
          rating: 4,
          salesRank: 147
        },
        {
          date: '2022-11-02',
          reviewCount: 3060,
          rating: 4,
          salesRank: 190
        },
        {
          date: '2022-11-03',
          reviewCount: 3108,
          rating: 4,
          salesRank: 266
        },
        {
          date: '2022-11-04',
          reviewCount: 3119,
          rating: 4,
          salesRank: 348
        },
        {
          date: '2022-11-05',
          reviewCount: 3126,
          rating: 4,
          salesRank: 370
        },
        {
          date: '2022-11-06',
          reviewCount: 3134,
          rating: 4,
          salesRank: 406
        },
        {
          date: '2022-11-07',
          reviewCount: 3152,
          rating: 4,
          salesRank: 427
        },
        {
          date: '2022-11-08',
          reviewCount: 3168,
          rating: 4,
          salesRank: 331
        },
        {
          date: '2022-11-09',
          reviewCount: 3179,
          rating: 4,
          salesRank: 362
        },
        {
          date: '2022-11-10',
          reviewCount: 3190,
          rating: 4,
          salesRank: 368
        },
        {
          date: '2022-11-11',
          reviewCount: 3190,
          rating: 4,
          salesRank: 427
        },
        {
          date: '2022-11-12',
          reviewCount: 3214,
          rating: 4,
          salesRank: 515
        },
        {
          date: '2022-11-13',
          reviewCount: 3225,
          rating: 4,
          salesRank: 258
        },
        {
          date: '2022-11-14',
          reviewCount: 3238,
          rating: 4,
          salesRank: 336
        },
        {
          date: '2022-11-15',
          reviewCount: 3253,
          rating: 4,
          salesRank: 224
        },
        {
          date: '2022-11-16',
          reviewCount: 3269,
          rating: 4,
          salesRank: 264
        },
        {
          date: '2022-11-17',
          reviewCount: 3282,
          rating: 4,
          salesRank: 451
        },
        {
          date: '2022-11-18',
          reviewCount: 3291,
          rating: 4,
          salesRank: 511
        },
        {
          date: '2022-11-19',
          reviewCount: 3300,
          rating: 4,
          salesRank: 675
        },
        {
          date: '2022-11-20',
          reviewCount: 3313,
          rating: 4,
          salesRank: 760
        },
        {
          date: '2022-11-21',
          reviewCount: 3317,
          rating: 4,
          salesRank: 946
        },
        {
          date: '2022-11-22',
          reviewCount: 3325,
          rating: 4,
          salesRank: 1097
        },
        {
          date: '2022-11-23',
          reviewCount: 3337,
          rating: 4,
          salesRank: 1082
        },
        {
          date: '2022-11-24',
          reviewCount: 3343,
          rating: 4,
          salesRank: 843
        },
        {
          date: '2022-11-25',
          reviewCount: 3344,
          rating: 4,
          salesRank: 702
        },
        {
          date: '2022-11-26',
          reviewCount: 3347,
          rating: 4,
          salesRank: 664
        },
        {
          date: '2022-11-27',
          reviewCount: 3349,
          rating: 4,
          salesRank: 596
        },
        {
          date: '2022-11-28',
          reviewCount: 3353,
          rating: 4,
          salesRank: 536
        },
        {
          date: '2022-11-29',
          reviewCount: 3361,
          rating: 4,
          salesRank: 460
        },
        {
          date: '2022-11-30',
          reviewCount: 3376,
          rating: 4,
          salesRank: 374
        },
        {
          date: '2022-12-01',
          reviewCount: 3384,
          rating: 4,
          salesRank: 312
        },
        {
          date: '2022-12-02',
          reviewCount: 3394,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2022-12-03',
          reviewCount: 3407,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2022-12-04',
          reviewCount: 3416,
          rating: 4,
          salesRank: 278
        },
        {
          date: '2022-12-05',
          reviewCount: 3422,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2022-12-06',
          reviewCount: 3430,
          rating: 4,
          salesRank: 284
        },
        {
          date: '2022-12-07',
          reviewCount: 3440,
          rating: 4,
          salesRank: 308
        },
        {
          date: '2022-12-08',
          reviewCount: 3445,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2022-12-09',
          reviewCount: 3452,
          rating: 4,
          salesRank: 315
        },
        {
          date: '2022-12-10',
          reviewCount: 3460,
          rating: 4,
          salesRank: 309
        },
        {
          date: '2022-12-11',
          reviewCount: 3473,
          rating: 4,
          salesRank: 313
        },
        {
          date: '2022-12-12',
          reviewCount: 3473,
          rating: 4,
          salesRank: 219
        },
        {
          date: '2022-12-13',
          reviewCount: 3473,
          rating: 4,
          salesRank: 256
        },
        {
          date: '2022-12-14',
          reviewCount: 3497,
          rating: 4,
          salesRank: 272
        },
        {
          date: '2022-12-15',
          reviewCount: 3497,
          rating: 4,
          salesRank: 318
        },
        {
          date: '2022-12-16',
          reviewCount: 3497,
          rating: 4,
          salesRank: 327
        },
        {
          date: '2022-12-17',
          reviewCount: 3497,
          rating: 4,
          salesRank: 308
        },
        {
          date: '2022-12-18',
          reviewCount: 3542,
          rating: 4,
          salesRank: 347
        },
        {
          date: '2022-12-19',
          reviewCount: 3542,
          rating: 4,
          salesRank: 362
        },
        {
          date: '2022-12-20',
          reviewCount: 3542,
          rating: 4,
          salesRank: 341
        },
        {
          date: '2022-12-21',
          reviewCount: 3542,
          rating: 4,
          salesRank: 358
        },
        {
          date: '2022-12-22',
          reviewCount: 3575,
          rating: 4,
          salesRank: 274
        },
        {
          date: '2022-12-23',
          reviewCount: 3575,
          rating: 4,
          salesRank: 258
        },
        {
          date: '2022-12-24',
          reviewCount: 3575,
          rating: 4,
          salesRank: 285
        },
        {
          date: '2022-12-25',
          reviewCount: 3575,
          rating: 4,
          salesRank: 359
        },
        {
          date: '2022-12-26',
          reviewCount: 3603,
          rating: 4,
          salesRank: 296
        },
        {
          date: '2022-12-27',
          reviewCount: 3603,
          rating: 4,
          salesRank: 238
        },
        {
          date: '2022-12-28',
          reviewCount: 3603,
          rating: 4,
          salesRank: 233
        },
        {
          date: '2022-12-29',
          reviewCount: 3603,
          rating: 4,
          salesRank: 213
        },
        {
          date: '2022-12-30',
          reviewCount: 3644,
          rating: 4,
          salesRank: 191
        },
        {
          date: '2022-12-31',
          reviewCount: 3644,
          rating: 4,
          salesRank: 200
        },
        {
          date: '2023-01-01',
          reviewCount: 3644,
          rating: 4,
          salesRank: 183
        },
        {
          date: '2023-01-02',
          reviewCount: 3669,
          rating: 4,
          salesRank: 197
        },
        {
          date: '2023-01-03',
          reviewCount: 3669,
          rating: 4,
          salesRank: 178
        },
        {
          date: '2023-01-04',
          reviewCount: 3669,
          rating: 4,
          salesRank: 183
        },
        {
          date: '2023-01-05',
          reviewCount: 3669,
          rating: 4,
          salesRank: 192
        },
        {
          date: '2023-01-06',
          reviewCount: 3723,
          rating: 4,
          salesRank: 201
        },
        {
          date: '2023-01-07',
          reviewCount: 3723,
          rating: 4,
          salesRank: 209
        },
        {
          date: '2023-01-08',
          reviewCount: 3723,
          rating: 4,
          salesRank: 228
        },
        {
          date: '2023-01-09',
          reviewCount: 3751,
          rating: 4,
          salesRank: 250
        },
        {
          date: '2023-01-10',
          reviewCount: 3751,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2023-01-11',
          reviewCount: 3751,
          rating: 4,
          salesRank: 258
        },
        {
          date: '2023-01-12',
          reviewCount: 3751,
          rating: 4,
          salesRank: 221
        },
        {
          date: '2023-01-13',
          reviewCount: 3794,
          rating: 4,
          salesRank: 135
        },
        {
          date: '2023-01-14',
          reviewCount: 3794,
          rating: 4,
          salesRank: 83
        },
        {
          date: '2023-01-15',
          reviewCount: 3794,
          rating: 4,
          salesRank: 98
        },
        {
          date: '2023-01-16',
          reviewCount: 3794,
          rating: 4,
          salesRank: 173
        },
        {
          date: '2023-01-17',
          reviewCount: 3833,
          rating: 4,
          salesRank: 160
        },
        {
          date: '2023-01-18',
          reviewCount: 3833,
          rating: 4,
          salesRank: 135
        },
        {
          date: '2023-01-19',
          reviewCount: 3833,
          rating: 4,
          salesRank: 109
        },
        {
          date: '2023-01-20',
          reviewCount: 3833,
          rating: 4,
          salesRank: 120
        },
        {
          date: '2023-01-21',
          reviewCount: 3883,
          rating: 4,
          salesRank: 159
        },
        {
          date: '2023-01-22',
          reviewCount: 3883,
          rating: 4,
          salesRank: 99
        },
        {
          date: '2023-01-23',
          reviewCount: 3883,
          rating: 4,
          salesRank: 136
        },
        {
          date: '2023-01-24',
          reviewCount: 3916,
          rating: 4,
          salesRank: 160
        },
        {
          date: '2023-01-25',
          reviewCount: 3916,
          rating: 4,
          salesRank: 155
        },
        {
          date: '2023-01-26',
          reviewCount: 3916,
          rating: 4,
          salesRank: 158
        },
        {
          date: '2023-01-27',
          reviewCount: 3956,
          rating: 4,
          salesRank: 222
        },
        {
          date: '2023-01-28',
          reviewCount: 3956,
          rating: 4,
          salesRank: 87
        },
        {
          date: '2023-01-29',
          reviewCount: 3956,
          rating: 4,
          salesRank: 137
        },
        {
          date: '2023-01-30',
          reviewCount: 3988,
          rating: 4,
          salesRank: 151
        },
        {
          date: '2023-01-31',
          reviewCount: 3988,
          rating: 4,
          salesRank: 179
        },
        {
          date: '2023-02-01',
          reviewCount: 3988,
          rating: 4,
          salesRank: 98
        },
        {
          date: '2023-02-02',
          reviewCount: 3988,
          rating: 4,
          salesRank: 131
        },
        {
          date: '2023-02-03',
          reviewCount: 4015,
          rating: 4,
          salesRank: 199
        },
        {
          date: '2023-02-04',
          reviewCount: 4015,
          rating: 4,
          salesRank: 260
        },
        {
          date: '2023-02-05',
          reviewCount: 4015,
          rating: 4,
          salesRank: 313
        },
        {
          date: '2023-02-06',
          reviewCount: 4041,
          rating: 4,
          salesRank: 291
        },
        {
          date: '2023-02-07',
          reviewCount: 4041,
          rating: 4,
          salesRank: 251
        },
        {
          date: '2023-02-08',
          reviewCount: 4041,
          rating: 4,
          salesRank: 234
        },
        {
          date: '2023-02-09',
          reviewCount: 4066,
          rating: 4,
          salesRank: 315
        },
        {
          date: '2023-02-10',
          reviewCount: 4066,
          rating: 4,
          salesRank: 408
        },
        {
          date: '2023-02-11',
          reviewCount: 4066,
          rating: 4,
          salesRank: 453
        },
        {
          date: '2023-02-12',
          reviewCount: 4066,
          rating: 4,
          salesRank: 465
        },
        {
          date: '2023-02-13',
          reviewCount: 4101,
          rating: 4,
          salesRank: 458
        },
        {
          date: '2023-02-14',
          reviewCount: 4101,
          rating: 4,
          salesRank: 532
        },
        {
          date: '2023-02-15',
          reviewCount: 4101,
          rating: 4,
          salesRank: 384
        },
        {
          date: '2023-02-16',
          reviewCount: 4131,
          rating: 4,
          salesRank: 498
        },
        {
          date: '2023-02-17',
          reviewCount: 4131,
          rating: 4,
          salesRank: 514
        },
        {
          date: '2023-02-18',
          reviewCount: 4131,
          rating: 4,
          salesRank: 460
        },
        {
          date: '2023-02-19',
          reviewCount: 4131,
          rating: 4,
          salesRank: 405
        },
        {
          date: '2023-02-20',
          reviewCount: 4196,
          rating: 4,
          salesRank: 368
        },
        {
          date: '2023-02-21',
          reviewCount: 4196,
          rating: 4,
          salesRank: 305
        },
        {
          date: '2023-02-22',
          reviewCount: 4196,
          rating: 4,
          salesRank: 328
        },
        {
          date: '2023-02-23',
          reviewCount: 4196,
          rating: 4,
          salesRank: 291
        },
        {
          date: '2023-02-24',
          reviewCount: 4245,
          rating: 4,
          salesRank: 278
        },
        {
          date: '2023-02-25',
          reviewCount: 4245,
          rating: 4,
          salesRank: 317
        },
        {
          date: '2023-02-26',
          reviewCount: 4245,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2023-02-27',
          reviewCount: 4285,
          rating: 4,
          salesRank: 201
        },
        {
          date: '2023-02-28',
          reviewCount: 4285,
          rating: 4,
          salesRank: 179
        },
        {
          date: '2023-03-01',
          reviewCount: 4285,
          rating: 4,
          salesRank: 188
        },
        {
          date: '2023-03-02',
          reviewCount: 4285,
          rating: 4,
          salesRank: 194
        },
        {
          date: '2023-03-03',
          reviewCount: 4341,
          rating: 4,
          salesRank: 153
        },
        {
          date: '2023-03-04',
          reviewCount: 4341,
          rating: 4,
          salesRank: 139
        },
        {
          date: '2023-03-05',
          reviewCount: 4341,
          rating: 4,
          salesRank: 147
        },
        {
          date: '2023-03-06',
          reviewCount: 4378,
          rating: 4,
          salesRank: 175
        },
        {
          date: '2023-03-07',
          reviewCount: 4378,
          rating: 4,
          salesRank: 132
        },
        {
          date: '2023-03-08',
          reviewCount: 4378,
          rating: 4,
          salesRank: 150
        },
        {
          date: '2023-03-09',
          reviewCount: 4418,
          rating: 4,
          salesRank: 190
        },
        {
          date: '2023-03-10',
          reviewCount: 4418,
          rating: 4,
          salesRank: 222
        },
        {
          date: '2023-03-11',
          reviewCount: 4418,
          rating: 4,
          salesRank: 216
        },
        {
          date: '2023-03-12',
          reviewCount: 4462,
          rating: 4,
          salesRank: 281
        },
        {
          date: '2023-03-13',
          reviewCount: 4462,
          rating: 4,
          salesRank: 145
        },
        {
          date: '2023-03-14',
          reviewCount: 4462,
          rating: 4,
          salesRank: 199
        },
        {
          date: '2023-03-15',
          reviewCount: 4497,
          rating: 4,
          salesRank: 236
        },
        {
          date: '2023-03-16',
          reviewCount: 4497,
          rating: 4,
          salesRank: 226
        },
        {
          date: '2023-03-17',
          reviewCount: 4497,
          rating: 4,
          salesRank: 278
        },
        {
          date: '2023-03-18',
          reviewCount: 4497,
          rating: 4,
          salesRank: 372
        },
        {
          date: '2023-03-19',
          reviewCount: 4540,
          rating: 4,
          salesRank: 343
        },
        {
          date: '2023-03-20',
          reviewCount: 4540,
          rating: 4,
          salesRank: 270
        },
        {
          date: '2023-03-21',
          reviewCount: 4540,
          rating: 4,
          salesRank: 168
        },
        {
          date: '2023-03-22',
          reviewCount: 4540,
          rating: 4,
          salesRank: 248
        },
        {
          date: '2023-03-23',
          reviewCount: 4598,
          rating: 4,
          salesRank: 217
        },
        {
          date: '2023-03-24',
          reviewCount: 4598,
          rating: 4,
          salesRank: 303
        },
        {
          date: '2023-03-25',
          reviewCount: 4598,
          rating: 4,
          salesRank: 292
        },
        {
          date: '2023-03-26',
          reviewCount: 4598,
          rating: 4,
          salesRank: 310
        },
        {
          date: '2023-03-27',
          reviewCount: 4653,
          rating: 4,
          salesRank: 196
        },
        {
          date: '2023-03-28',
          reviewCount: 4653,
          rating: 4,
          salesRank: 183
        },
        {
          date: '2023-03-29',
          reviewCount: 4653,
          rating: 4,
          salesRank: 157
        },
        {
          date: '2023-03-30',
          reviewCount: 4696,
          rating: 4,
          salesRank: 154
        },
        {
          date: '2023-03-31',
          reviewCount: 4696,
          rating: 4,
          salesRank: 165
        },
        {
          date: '2023-04-01',
          reviewCount: 4696,
          rating: 4,
          salesRank: 135
        },
        {
          date: '2023-04-02',
          reviewCount: 4696,
          rating: 4,
          salesRank: 155
        },
        {
          date: '2023-04-03',
          reviewCount: 4742,
          rating: 4,
          salesRank: 187
        },
        {
          date: '2023-04-04',
          reviewCount: 4742,
          rating: 4,
          salesRank: 171
        },
        {
          date: '2023-04-05',
          reviewCount: 4742,
          rating: 4,
          salesRank: 228
        },
        {
          date: '2023-04-06',
          reviewCount: 4782,
          rating: 4,
          salesRank: 216
        },
        {
          date: '2023-04-07',
          reviewCount: 4782,
          rating: 4,
          salesRank: 241
        },
        {
          date: '2023-04-08',
          reviewCount: 4782,
          rating: 4,
          salesRank: 222
        },
        {
          date: '2023-04-09',
          reviewCount: 4782,
          rating: 4,
          salesRank: 246
        },
        {
          date: '2023-04-10',
          reviewCount: 4829,
          rating: 4,
          salesRank: 223
        },
        {
          date: '2023-04-11',
          reviewCount: 4829,
          rating: 4,
          salesRank: 197
        },
        {
          date: '2023-04-12',
          reviewCount: 4829,
          rating: 4,
          salesRank: 188
        },
        {
          date: '2023-04-13',
          reviewCount: 4829,
          rating: 4,
          salesRank: 202
        },
        {
          date: '2023-04-14',
          reviewCount: 4873,
          rating: 4,
          salesRank: 200
        },
        {
          date: '2023-04-15',
          reviewCount: 4873,
          rating: 4,
          salesRank: 148
        },
        {
          date: '2023-04-16',
          reviewCount: 4873,
          rating: 4,
          salesRank: 183
        },
        {
          date: '2023-04-17',
          reviewCount: 4916,
          rating: 4,
          salesRank: 193
        },
        {
          date: '2023-04-18',
          reviewCount: 4916,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2023-04-19',
          reviewCount: 4916,
          rating: 4,
          salesRank: 252
        },
        {
          date: '2023-04-20',
          reviewCount: 4954,
          rating: 4,
          salesRank: 282
        },
        {
          date: '2023-04-21',
          reviewCount: 4954,
          rating: 4,
          salesRank: 246
        },
        {
          date: '2023-04-22',
          reviewCount: 4954,
          rating: 4,
          salesRank: 269
        },
        {
          date: '2023-04-23',
          reviewCount: 4954,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2023-04-24',
          reviewCount: 5007,
          rating: 4,
          salesRank: 207
        },
        {
          date: '2023-04-25',
          reviewCount: 5007,
          rating: 4,
          salesRank: 133
        },
        {
          date: '2023-04-26',
          reviewCount: 5007,
          rating: 4,
          salesRank: 116
        },
        {
          date: '2023-04-27',
          reviewCount: 5007,
          rating: 4,
          salesRank: 139
        },
        {
          date: '2023-04-28',
          reviewCount: 5053,
          rating: 4,
          salesRank: 135
        },
        {
          date: '2023-04-29',
          reviewCount: 5053,
          rating: 4,
          salesRank: 138
        },
        {
          date: '2023-04-30',
          reviewCount: 5053,
          rating: 4,
          salesRank: 164
        },
        {
          date: '2023-05-01',
          reviewCount: 5053,
          rating: 4,
          salesRank: 127
        },
        {
          date: '2023-05-02',
          reviewCount: 5096,
          rating: 4,
          salesRank: 173
        },
        {
          date: '2023-05-03',
          reviewCount: 5096,
          rating: 4,
          salesRank: 182
        },
        {
          date: '2023-05-04',
          reviewCount: 5096,
          rating: 4,
          salesRank: 232
        },
        {
          date: '2023-05-05',
          reviewCount: 5096,
          rating: 4,
          salesRank: 297
        },
        {
          date: '2023-05-06',
          reviewCount: 5140,
          rating: 4,
          salesRank: 310
        },
        {
          date: '2023-05-07',
          reviewCount: 5140,
          rating: 4,
          salesRank: 327
        },
        {
          date: '2023-05-08',
          reviewCount: 5140,
          rating: 4,
          salesRank: 311
        },
        {
          date: '2023-05-09',
          reviewCount: 5181,
          rating: 4,
          salesRank: 311
        },
        {
          date: '2023-05-10',
          reviewCount: 5181,
          rating: 4,
          salesRank: 272
        },
        {
          date: '2023-05-11',
          reviewCount: 5181,
          rating: 4,
          salesRank: 358
        },
        {
          date: '2023-05-12',
          reviewCount: 5181,
          rating: 4,
          salesRank: 401
        },
        {
          date: '2023-05-13',
          reviewCount: 5219,
          rating: 4,
          salesRank: 243
        },
        {
          date: '2023-05-14',
          reviewCount: 5219,
          rating: 4,
          salesRank: 404
        },
        {
          date: '2023-05-15',
          reviewCount: 5219,
          rating: 4,
          salesRank: 330
        },
        {
          date: '2023-05-16',
          reviewCount: 5256,
          rating: 4,
          salesRank: 276
        },
        {
          date: '2023-05-17',
          reviewCount: 5256,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2023-05-18',
          reviewCount: 5256,
          rating: 4,
          salesRank: 328
        },
        {
          date: '2023-05-19',
          reviewCount: 5256,
          rating: 4,
          salesRank: 405
        },
        {
          date: '2023-05-20',
          reviewCount: 5293,
          rating: 4,
          salesRank: 358
        },
        {
          date: '2023-05-21',
          reviewCount: 5293,
          rating: 4,
          salesRank: 326
        },
        {
          date: '2023-05-22',
          reviewCount: 5293,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2023-05-23',
          reviewCount: 5327,
          rating: 4,
          salesRank: 219
        },
        {
          date: '2023-05-24',
          reviewCount: 5327,
          rating: 4,
          salesRank: 172
        },
        {
          date: '2023-05-25',
          reviewCount: 5327,
          rating: 4,
          salesRank: 163
        },
        {
          date: '2023-05-26',
          reviewCount: 5353,
          rating: 4,
          salesRank: 184
        },
        {
          date: '2023-05-27',
          reviewCount: 5353,
          rating: 4,
          salesRank: 184
        },
        {
          date: '2023-05-28',
          reviewCount: 5353,
          rating: 4,
          salesRank: 186
        },
        {
          date: '2023-05-29',
          reviewCount: 5353,
          rating: 4,
          salesRank: 186
        },
        {
          date: '2023-05-30',
          reviewCount: 5395,
          rating: 4,
          salesRank: 256
        },
        {
          date: '2023-05-31',
          reviewCount: 5395,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2023-06-01',
          reviewCount: 5395,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2023-06-02',
          reviewCount: 5395,
          rating: 4,
          salesRank: 377
        },
        {
          date: '2023-06-03',
          reviewCount: 5443,
          rating: 4,
          salesRank: 277
        },
        {
          date: '2023-06-04',
          reviewCount: 5443,
          rating: 4,
          salesRank: 360
        },
        {
          date: '2023-06-05',
          reviewCount: 5443,
          rating: 4,
          salesRank: 280
        },
        {
          date: '2023-06-06',
          reviewCount: 5468,
          rating: 4,
          salesRank: 305
        },
        {
          date: '2023-06-07',
          reviewCount: 5468,
          rating: 4,
          salesRank: 300
        },
        {
          date: '2023-06-08',
          reviewCount: 5468,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2023-06-09',
          reviewCount: 5499,
          rating: 4,
          salesRank: 353
        },
        {
          date: '2023-06-10',
          reviewCount: 5499,
          rating: 4,
          salesRank: 376
        },
        {
          date: '2023-06-11',
          reviewCount: 5499,
          rating: 4,
          salesRank: 419
        },
        {
          date: '2023-06-12',
          reviewCount: 5499,
          rating: 4,
          salesRank: 293
        },
        {
          date: '2023-06-13',
          reviewCount: 5526,
          rating: 4,
          salesRank: 300
        },
        {
          date: '2023-06-14',
          reviewCount: 5526,
          rating: 4,
          salesRank: 270
        },
        {
          date: '2023-06-15',
          reviewCount: 5526,
          rating: 4,
          salesRank: 252
        },
        {
          date: '2023-06-16',
          reviewCount: 5559,
          rating: 4,
          salesRank: 199
        },
        {
          date: '2023-06-17',
          reviewCount: 5559,
          rating: 4,
          salesRank: 192
        },
        {
          date: '2023-06-18',
          reviewCount: 5559,
          rating: 4,
          salesRank: 225
        },
        {
          date: '2023-06-19',
          reviewCount: 5559,
          rating: 4,
          salesRank: 211
        },
        {
          date: '2023-06-20',
          reviewCount: 5600,
          rating: 4,
          salesRank: 237
        },
        {
          date: '2023-06-21',
          reviewCount: 5600,
          rating: 4,
          salesRank: 268
        },
        {
          date: '2023-06-22',
          reviewCount: 5600,
          rating: 4,
          salesRank: 315
        },
        {
          date: '2023-06-23',
          reviewCount: 5600,
          rating: 4,
          salesRank: 342
        },
        {
          date: '2023-06-24',
          reviewCount: 5638,
          rating: 4,
          salesRank: 363
        },
        {
          date: '2023-06-25',
          reviewCount: 5638,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2023-06-26',
          reviewCount: 5638,
          rating: 4,
          salesRank: 332
        },
        {
          date: '2023-06-27',
          reviewCount: 5660,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2023-06-28',
          reviewCount: 5660,
          rating: 4,
          salesRank: 330
        },
        {
          date: '2023-06-29',
          reviewCount: 5660,
          rating: 4,
          salesRank: 345
        },
        {
          date: '2023-06-30',
          reviewCount: 5685,
          rating: 4,
          salesRank: 401
        },
        {
          date: '2023-07-01',
          reviewCount: 5685,
          rating: 4,
          salesRank: 340
        },
        {
          date: '2023-07-02',
          reviewCount: 5685,
          rating: 4,
          salesRank: 364
        },
        {
          date: '2023-07-03',
          reviewCount: 5685,
          rating: 4,
          salesRank: 254
        },
        {
          date: '2023-07-04',
          reviewCount: 5685,
          rating: 4,
          salesRank: 243
        },
        {
          date: '2023-07-05',
          reviewCount: 5685,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2023-07-06',
          reviewCount: 5751,
          rating: 4,
          salesRank: 193
        },
        {
          date: '2023-07-07',
          reviewCount: 5751,
          rating: 4,
          salesRank: 210
        },
        {
          date: '2023-07-08',
          reviewCount: 5751,
          rating: 4,
          salesRank: 207
        },
        {
          date: '2023-07-09',
          reviewCount: 5751,
          rating: 4,
          salesRank: 202
        },
        {
          date: '2023-07-10',
          reviewCount: 5751,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2023-07-11',
          reviewCount: 5815,
          rating: 4,
          salesRank: 572
        },
        {
          date: '2023-07-12',
          reviewCount: 5815,
          rating: 4,
          salesRank: 596
        },
        {
          date: '2023-07-13',
          reviewCount: 5815,
          rating: 4,
          salesRank: 613
        },
        {
          date: '2023-07-14',
          reviewCount: 5815,
          rating: 4,
          salesRank: 526
        },
        {
          date: '2023-07-15',
          reviewCount: 5815,
          rating: 4,
          salesRank: 469
        },
        {
          date: '2023-07-16',
          reviewCount: 5868,
          rating: 4,
          salesRank: 426
        },
        {
          date: '2023-07-17',
          reviewCount: 5868,
          rating: 4,
          salesRank: 322
        },
        {
          date: '2023-07-18',
          reviewCount: 5868,
          rating: 4,
          salesRank: 344
        },
        {
          date: '2023-07-19',
          reviewCount: 5868,
          rating: 4,
          salesRank: 385
        },
        {
          date: '2023-07-20',
          reviewCount: 5923,
          rating: 4,
          salesRank: 459
        },
        {
          date: '2023-07-21',
          reviewCount: 5923,
          rating: 4,
          salesRank: 433
        },
        {
          date: '2023-07-22',
          reviewCount: 5923,
          rating: 4,
          salesRank: 524
        },
        {
          date: '2023-07-23',
          reviewCount: 5923,
          rating: 4,
          salesRank: 512
        },
        {
          date: '2023-07-24',
          reviewCount: 5923,
          rating: 4,
          salesRank: 543
        },
        {
          date: '2023-07-25',
          reviewCount: 5968,
          rating: 4,
          salesRank: 475
        },
        {
          date: '2023-07-26',
          reviewCount: 5968,
          rating: 4,
          salesRank: 505
        },
        {
          date: '2023-07-27',
          reviewCount: 5968,
          rating: 4,
          salesRank: 540
        },
        {
          date: '2023-07-28',
          reviewCount: 5968,
          rating: 4,
          salesRank: 559
        },
        {
          date: '2023-07-29',
          reviewCount: 5968,
          rating: 4,
          salesRank: 532
        },
        {
          date: '2023-07-30',
          reviewCount: 6025,
          rating: 4,
          salesRank: 605
        },
        {
          date: '2023-07-31',
          reviewCount: 6025,
          rating: 4,
          salesRank: 465
        },
        {
          date: '2023-08-01',
          reviewCount: 6025,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2023-08-02',
          reviewCount: 6025,
          rating: 4,
          salesRank: 311
        },
        {
          date: '2023-08-03',
          reviewCount: 6063,
          rating: 4,
          salesRank: 279
        },
        {
          date: '2023-08-04',
          reviewCount: 6063,
          rating: 4,
          salesRank: 307
        },
        {
          date: '2023-08-05',
          reviewCount: 6063,
          rating: 4,
          salesRank: 323
        },
        {
          date: '2023-08-06',
          reviewCount: 6063,
          rating: 4,
          salesRank: 288
        },
        {
          date: '2023-08-07',
          reviewCount: 6063,
          rating: 4,
          salesRank: 286
        },
        {
          date: '2023-08-08',
          reviewCount: 6110,
          rating: 4,
          salesRank: 388
        },
        {
          date: '2023-08-09',
          reviewCount: 6110,
          rating: 4,
          salesRank: 437
        },
        {
          date: '2023-08-10',
          reviewCount: 6110,
          rating: 4,
          salesRank: 399
        },
        {
          date: '2023-08-11',
          reviewCount: 6110,
          rating: 4,
          salesRank: 422
        },
        {
          date: '2023-08-12',
          reviewCount: 6110,
          rating: 4,
          salesRank: 354
        },
        {
          date: '2023-08-13',
          reviewCount: 6168,
          rating: 4,
          salesRank: 464
        },
        {
          date: '2023-08-14',
          reviewCount: 6168,
          rating: 4,
          salesRank: 487
        },
        {
          date: '2023-08-15',
          reviewCount: 6168,
          rating: 4,
          salesRank: 490
        },
        {
          date: '2023-08-16',
          reviewCount: 6168,
          rating: 4,
          salesRank: 531
        },
        {
          date: '2023-08-17',
          reviewCount: 6168,
          rating: 4,
          salesRank: 557
        },
        {
          date: '2023-08-18',
          reviewCount: 6168,
          rating: 4,
          salesRank: 649
        },
        {
          date: '2023-08-19',
          reviewCount: 6168,
          rating: 4,
          salesRank: 619
        },
        {
          date: '2023-08-20',
          reviewCount: 6238,
          rating: 4,
          salesRank: 690
        },
        {
          date: '2023-08-21',
          reviewCount: 6238,
          rating: 4,
          salesRank: 542
        },
        {
          date: '2023-08-22',
          reviewCount: 6238,
          rating: 4,
          salesRank: 598
        },
        {
          date: '2023-08-23',
          reviewCount: 6238,
          rating: 4,
          salesRank: 552
        },
        {
          date: '2023-08-24',
          reviewCount: 6269,
          rating: 4,
          salesRank: 471
        },
        {
          date: '2023-08-25',
          reviewCount: 6269,
          rating: 4,
          salesRank: 521
        },
        {
          date: '2023-08-26',
          reviewCount: 6269,
          rating: 4,
          salesRank: 654
        },
        {
          date: '2023-08-27',
          reviewCount: 6295,
          rating: 4,
          salesRank: 600
        },
        {
          date: '2023-08-28',
          reviewCount: 6295,
          rating: 4,
          salesRank: 661
        },
        {
          date: '2023-08-29',
          reviewCount: 6295,
          rating: 4,
          salesRank: 264
        },
        {
          date: '2023-08-30',
          reviewCount: 6295,
          rating: 4,
          salesRank: 237
        },
        {
          date: '2023-08-31',
          reviewCount: 6343,
          rating: 4,
          salesRank: 209
        },
        {
          date: '2023-09-01',
          reviewCount: 6343,
          rating: 4,
          salesRank: 236
        },
        {
          date: '2023-09-02',
          reviewCount: 6343,
          rating: 4,
          salesRank: 234
        },
        {
          date: '2023-09-03',
          reviewCount: 6369,
          rating: 4,
          salesRank: 218
        },
        {
          date: '2023-09-04',
          reviewCount: 6369,
          rating: 4,
          salesRank: 351
        },
        {
          date: '2023-09-05',
          reviewCount: 6369,
          rating: 4,
          salesRank: 418
        },
        {
          date: '2023-09-06',
          reviewCount: 6369,
          rating: 4,
          salesRank: 430
        },
        {
          date: '2023-09-07',
          reviewCount: 6411,
          rating: 4,
          salesRank: 488
        },
        {
          date: '2023-09-08',
          reviewCount: 6411,
          rating: 4,
          salesRank: 634
        },
        {
          date: '2023-09-09',
          reviewCount: 6411,
          rating: 4,
          salesRank: 456
        },
        {
          date: '2023-09-10',
          reviewCount: 6435,
          rating: 4,
          salesRank: 423
        },
        {
          date: '2023-09-11',
          reviewCount: 6435,
          rating: 4,
          salesRank: 446
        },
        {
          date: '2023-09-12',
          reviewCount: 6435,
          rating: 4,
          salesRank: 375
        },
        {
          date: '2023-09-13',
          reviewCount: 6435,
          rating: 4,
          salesRank: 495
        },
        {
          date: '2023-09-14',
          reviewCount: 6457,
          rating: 4,
          salesRank: 556
        },
        {
          date: '2023-09-15',
          reviewCount: 6457,
          rating: 4,
          salesRank: 525
        },
        {
          date: '2023-09-16',
          reviewCount: 6457,
          rating: 4,
          salesRank: 599
        },
        {
          date: '2023-09-17',
          reviewCount: 6483,
          rating: 4,
          salesRank: 672
        },
        {
          date: '2023-09-18',
          reviewCount: 6483,
          rating: 4,
          salesRank: 715
        },
        {
          date: '2023-09-19',
          reviewCount: 6483,
          rating: 4,
          salesRank: 707
        },
        {
          date: '2023-09-20',
          reviewCount: 6483,
          rating: 4,
          salesRank: 699
        },
        {
          date: '2023-09-21',
          reviewCount: 6514,
          rating: 4,
          salesRank: 862
        },
        {
          date: '2023-09-22',
          reviewCount: 6514,
          rating: 4,
          salesRank: 808
        },
        {
          date: '2023-09-23',
          reviewCount: 6514,
          rating: 4,
          salesRank: 834
        },
        {
          date: '2023-09-24',
          reviewCount: 6514,
          rating: 4,
          salesRank: 568
        },
        {
          date: '2023-09-25',
          reviewCount: 6548,
          rating: 4,
          salesRank: 405
        },
        {
          date: '2023-09-26',
          reviewCount: 6548,
          rating: 4,
          salesRank: 350
        },
        {
          date: '2023-09-27',
          reviewCount: 6548,
          rating: 4,
          salesRank: 321
        },
        {
          date: '2023-09-28',
          reviewCount: 6568,
          rating: 4,
          salesRank: 306
        },
        {
          date: '2023-09-29',
          reviewCount: 6568,
          rating: 4,
          salesRank: 323
        },
        {
          date: '2023-09-30',
          reviewCount: 6568,
          rating: 4,
          salesRank: 380
        },
        {
          date: '2023-10-01',
          reviewCount: 6599,
          rating: 4,
          salesRank: 461
        },
        {
          date: '2023-10-02',
          reviewCount: 6599,
          rating: 4,
          salesRank: 436
        },
        {
          date: '2023-10-03',
          reviewCount: 6599,
          rating: 4,
          salesRank: 379
        },
        {
          date: '2023-10-04',
          reviewCount: 6599,
          rating: 4,
          salesRank: 297
        },
        {
          date: '2023-10-05',
          reviewCount: 6599,
          rating: 4,
          salesRank: 215
        },
        {
          date: '2023-10-06',
          reviewCount: 6632,
          rating: 4,
          salesRank: 223
        },
        {
          date: '2023-10-07',
          reviewCount: 6632,
          rating: 4,
          salesRank: 273
        },
        {
          date: '2023-10-08',
          reviewCount: 6632,
          rating: 4,
          salesRank: 363
        },
        {
          date: '2023-10-09',
          reviewCount: 6632,
          rating: 4,
          salesRank: 312
        },
        {
          date: '2023-10-10',
          reviewCount: 6632,
          rating: 4,
          salesRank: 375
        },
        {
          date: '2023-10-11',
          reviewCount: 6632,
          rating: 4,
          salesRank: 404
        },
        {
          date: '2023-10-12',
          reviewCount: 6671,
          rating: 4,
          salesRank: 431
        },
        {
          date: '2023-10-13',
          reviewCount: 6671,
          rating: 4,
          salesRank: 434
        },
        {
          date: '2023-10-14',
          reviewCount: 6671,
          rating: 4,
          salesRank: 466
        },
        {
          date: '2023-10-15',
          reviewCount: 6671,
          rating: 4,
          salesRank: 542
        },
        {
          date: '2023-10-16',
          reviewCount: 6694,
          rating: 4,
          salesRank: 419
        },
        {
          date: '2023-10-17',
          reviewCount: 6694,
          rating: 4,
          salesRank: 622
        },
        {
          date: '2023-10-18',
          reviewCount: 6694,
          rating: 4,
          salesRank: 624
        },
        {
          date: '2023-10-19',
          reviewCount: 6609,
          rating: 4,
          salesRank: 620
        },
        {
          date: '2023-10-20',
          reviewCount: 6609,
          rating: 4,
          salesRank: 691
        },
        {
          date: '2023-10-21',
          reviewCount: 6609,
          rating: 4,
          salesRank: 575
        },
        {
          date: '2023-10-22',
          reviewCount: 6609,
          rating: 4,
          salesRank: 559
        },
        {
          date: '2023-10-23',
          reviewCount: 6630,
          rating: 4,
          salesRank: 355
        },
        {
          date: '2023-10-24',
          reviewCount: 6630,
          rating: 4,
          salesRank: 267
        },
        {
          date: '2023-10-25',
          reviewCount: 6630,
          rating: 4,
          salesRank: 237
        },
        {
          date: '2023-10-26',
          reviewCount: 6642,
          rating: 4,
          salesRank: 235
        },
        {
          date: '2023-10-27',
          reviewCount: 6642,
          rating: 4,
          salesRank: 197
        },
        {
          date: '2023-10-28',
          reviewCount: 6642,
          rating: 4,
          salesRank: 180
        },
        {
          date: '2023-10-29',
          reviewCount: 6642,
          rating: 4,
          salesRank: 211
        },
        {
          date: '2023-10-30',
          reviewCount: 6670,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2023-10-31',
          reviewCount: 6670,
          rating: 4,
          salesRank: 288
        },
        {
          date: '2023-11-01',
          reviewCount: 6670,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2023-11-02',
          reviewCount: 6670,
          rating: 4,
          salesRank: 397
        },
        {
          date: '2023-11-03',
          reviewCount: 6699,
          rating: 4,
          salesRank: 385
        },
        {
          date: '2023-11-04',
          reviewCount: 6699,
          rating: 4,
          salesRank: 347
        },
        {
          date: '2023-11-05',
          reviewCount: 6699,
          rating: 4,
          salesRank: 353
        },
        {
          date: '2023-11-06',
          reviewCount: 6699,
          rating: 4,
          salesRank: 315
        },
        {
          date: '2023-11-07',
          reviewCount: 6734,
          rating: 4,
          salesRank: 342
        },
        {
          date: '2023-11-08',
          reviewCount: 6734,
          rating: 4,
          salesRank: 420
        },
        {
          date: '2023-11-09',
          reviewCount: 6734,
          rating: 4,
          salesRank: 573
        },
        {
          date: '2023-11-10',
          reviewCount: 6734,
          rating: 4,
          salesRank: 671
        },
        {
          date: '2023-11-11',
          reviewCount: 6751,
          rating: 4,
          salesRank: 726
        },
        {
          date: '2023-11-12',
          reviewCount: 6751,
          rating: 4,
          salesRank: 726
        },
        {
          date: '2023-11-13',
          reviewCount: 6751,
          rating: 4,
          salesRank: 653
        },
        {
          date: '2023-11-14',
          reviewCount: 6770,
          rating: 4,
          salesRank: 531
        },
        {
          date: '2023-11-15',
          reviewCount: 6770,
          rating: 4,
          salesRank: 426
        },
        {
          date: '2023-11-16',
          reviewCount: 6770,
          rating: 4,
          salesRank: 494
        },
        {
          date: '2023-11-17',
          reviewCount: 6770,
          rating: 4,
          salesRank: 546
        },
        {
          date: '2023-11-18',
          reviewCount: 6795,
          rating: 4,
          salesRank: 549
        },
        {
          date: '2023-11-19',
          reviewCount: 6795,
          rating: 4,
          salesRank: 722
        },
        {
          date: '2023-11-20',
          reviewCount: 6795,
          rating: 4,
          salesRank: 478
        },
        {
          date: '2023-11-21',
          reviewCount: 6805,
          rating: 4,
          salesRank: 566
        },
        {
          date: '2023-11-22',
          reviewCount: 6805,
          rating: 4,
          salesRank: 565
        },
        {
          date: '2023-11-23',
          reviewCount: 6805,
          rating: 4,
          salesRank: 635
        },
        {
          date: '2023-11-24',
          reviewCount: 6805,
          rating: 4,
          salesRank: 825
        },
        {
          date: '2023-11-25',
          reviewCount: 6828,
          rating: 4,
          salesRank: 812
        },
        {
          date: '2023-11-26',
          reviewCount: 6828,
          rating: 4,
          salesRank: 702
        },
        {
          date: '2023-11-27',
          reviewCount: 6828,
          rating: 4,
          salesRank: 635
        },
        {
          date: '2023-11-28',
          reviewCount: 6846,
          rating: 4,
          salesRank: 568
        },
        {
          date: '2023-11-29',
          reviewCount: 6846,
          rating: 4,
          salesRank: 373
        },
        {
          date: '2023-11-30',
          reviewCount: 6846,
          rating: 4,
          salesRank: 271
        },
        {
          date: '2023-12-01',
          reviewCount: 6846,
          rating: 4,
          salesRank: 229
        },
        {
          date: '2023-12-02',
          reviewCount: 6872,
          rating: 4,
          salesRank: 185
        },
        {
          date: '2023-12-03',
          reviewCount: 6872,
          rating: 4,
          salesRank: 187
        },
        {
          date: '2023-12-04',
          reviewCount: 6872,
          rating: 4,
          salesRank: 253
        },
        {
          date: '2023-12-05',
          reviewCount: 6872,
          rating: 4,
          salesRank: 307
        },
        {
          date: '2023-12-06',
          reviewCount: 6904,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2023-12-07',
          reviewCount: 6904,
          rating: 4,
          salesRank: 400
        },
        {
          date: '2023-12-08',
          reviewCount: 6904,
          rating: 4,
          salesRank: 479
        },
        {
          date: '2023-12-09',
          reviewCount: 6904,
          rating: 4,
          salesRank: 552
        },
        {
          date: '2023-12-10',
          reviewCount: 6941,
          rating: 4,
          salesRank: 624
        },
        {
          date: '2023-12-11',
          reviewCount: 6941,
          rating: 4,
          salesRank: 641
        },
        {
          date: '2023-12-12',
          reviewCount: 6941,
          rating: 4,
          salesRank: 679
        },
        {
          date: '2023-12-13',
          reviewCount: 6941,
          rating: 4,
          salesRank: 688
        },
        {
          date: '2023-12-14',
          reviewCount: 6974,
          rating: 4,
          salesRank: 638
        },
        {
          date: '2023-12-15',
          reviewCount: 6974,
          rating: 4,
          salesRank: 648
        },
        {
          date: '2023-12-16',
          reviewCount: 6974,
          rating: 4,
          salesRank: 652
        },
        {
          date: '2023-12-17',
          reviewCount: 6997,
          rating: 4,
          salesRank: 743
        },
        {
          date: '2023-12-18',
          reviewCount: 6997,
          rating: 4,
          salesRank: 623
        },
        {
          date: '2023-12-19',
          reviewCount: 6997,
          rating: 4,
          salesRank: 628
        },
        {
          date: '2023-12-20',
          reviewCount: 6997,
          rating: 4,
          salesRank: 666
        },
        {
          date: '2023-12-21',
          reviewCount: 7021,
          rating: 4,
          salesRank: 728
        },
        {
          date: '2023-12-22',
          reviewCount: 7021,
          rating: 4,
          salesRank: 609
        },
        {
          date: '2023-12-23',
          reviewCount: 7021,
          rating: 4,
          salesRank: 525
        },
        {
          date: '2023-12-24',
          reviewCount: 7046,
          rating: 4,
          salesRank: 601
        },
        {
          date: '2023-12-25',
          reviewCount: 7046,
          rating: 4,
          salesRank: 772
        },
        {
          date: '2023-12-26',
          reviewCount: 7046,
          rating: 4,
          salesRank: 718
        },
        {
          date: '2023-12-27',
          reviewCount: 7046,
          rating: 4,
          salesRank: 630
        },
        {
          date: '2023-12-28',
          reviewCount: 7073,
          rating: 4,
          salesRank: 607
        },
        {
          date: '2023-12-29',
          reviewCount: 7073,
          rating: 4,
          salesRank: 541
        },
        {
          date: '2023-12-30',
          reviewCount: 7073,
          rating: 4,
          salesRank: 567
        },
        {
          date: '2023-12-31',
          reviewCount: 7098,
          rating: 4,
          salesRank: 457
        },
        {
          date: '2024-01-01',
          reviewCount: 7098,
          rating: 4,
          salesRank: 265
        },
        {
          date: '2024-01-02',
          reviewCount: 7098,
          rating: 4,
          salesRank: 203
        },
        {
          date: '2024-01-03',
          reviewCount: 7128,
          rating: 4,
          salesRank: 183
        },
        {
          date: '2024-01-04',
          reviewCount: 7128,
          rating: 4,
          salesRank: 173
        },
        {
          date: '2024-01-05',
          reviewCount: 7128,
          rating: 4,
          salesRank: 154
        },
        {
          date: '2024-01-06',
          reviewCount: 7128,
          rating: 4,
          salesRank: 157
        },
        {
          date: '2024-01-07',
          reviewCount: 7157,
          rating: 4,
          salesRank: 162
        },
        {
          date: '2024-01-08',
          reviewCount: 7157,
          rating: 4,
          salesRank: 202
        },
        {
          date: '2024-01-09',
          reviewCount: 7157,
          rating: 4,
          salesRank: 265
        },
        {
          date: '2024-01-10',
          reviewCount: 7175,
          rating: 4,
          salesRank: 279
        },
        {
          date: '2024-01-11',
          reviewCount: 7175,
          rating: 4,
          salesRank: 317
        },
        {
          date: '2024-01-12',
          reviewCount: 7175,
          rating: 4,
          salesRank: 337
        },
        {
          date: '2024-01-13',
          reviewCount: 7197,
          rating: 4,
          salesRank: 385
        },
        {
          date: '2024-01-14',
          reviewCount: 7197,
          rating: 4,
          salesRank: 310
        },
        {
          date: '2024-01-15',
          reviewCount: 7197,
          rating: 4,
          salesRank: 418
        },
        {
          date: '2024-01-16',
          reviewCount: 7224,
          rating: 4,
          salesRank: 315
        },
        {
          date: '2024-01-17',
          reviewCount: 7224,
          rating: 4,
          salesRank: 377
        },
        {
          date: '2024-01-18',
          reviewCount: 7224,
          rating: 4,
          salesRank: 439
        },
        {
          date: '2024-01-19',
          reviewCount: 7224,
          rating: 4,
          salesRank: 524
        },
        {
          date: '2024-01-20',
          reviewCount: 7251,
          rating: 4,
          salesRank: 610
        },
        {
          date: '2024-01-21',
          reviewCount: 7251,
          rating: 4,
          salesRank: 613
        },
        {
          date: '2024-01-22',
          reviewCount: 7251,
          rating: 4,
          salesRank: 524
        },
        {
          date: '2024-01-23',
          reviewCount: 7273,
          rating: 4,
          salesRank: 471
        },
        {
          date: '2024-01-24',
          reviewCount: 7273,
          rating: 4,
          salesRank: 440
        },
        {
          date: '2024-01-25',
          reviewCount: 7273,
          rating: 4,
          salesRank: 406
        },
        {
          date: '2024-01-26',
          reviewCount: 7292,
          rating: 4,
          salesRank: 380
        },
        {
          date: '2024-01-27',
          reviewCount: 7292,
          rating: 4,
          salesRank: 426
        },
        {
          date: '2024-01-28',
          reviewCount: 7292,
          rating: 4,
          salesRank: 544
        },
        {
          date: '2024-01-29',
          reviewCount: 7316,
          rating: 4,
          salesRank: 349
        },
        {
          date: '2024-01-30',
          reviewCount: 7316,
          rating: 4,
          salesRank: 233
        },
        {
          date: '2024-01-31',
          reviewCount: 7316,
          rating: 4,
          salesRank: 186
        },
        {
          date: '2024-02-01',
          reviewCount: 7338,
          rating: 4,
          salesRank: 152
        },
        {
          date: '2024-02-02',
          reviewCount: 7338,
          rating: 4,
          salesRank: 147
        },
        {
          date: '2024-02-03',
          reviewCount: 7338,
          rating: 4,
          salesRank: 123
        },
        {
          date: '2024-02-04',
          reviewCount: 7352,
          rating: 4,
          salesRank: 136
        },
        {
          date: '2024-02-05',
          reviewCount: 7352,
          rating: 4,
          salesRank: 135
        },
        {
          date: '2024-02-06',
          reviewCount: 7352,
          rating: 4,
          salesRank: 197
        },
        {
          date: '2024-02-07',
          reviewCount: 7352,
          rating: 4,
          salesRank: 242
        },
        {
          date: '2024-02-08',
          reviewCount: 7086,
          rating: 4,
          salesRank: 270
        },
        {
          date: '2024-02-09',
          reviewCount: 7086,
          rating: 4,
          salesRank: 307
        },
        {
          date: '2024-02-10',
          reviewCount: 7086,
          rating: 4,
          salesRank: 337
        },
        {
          date: '2024-02-11',
          reviewCount: 7395,
          rating: 4,
          salesRank: 430
        },
        {
          date: '2024-02-12',
          reviewCount: 7395,
          rating: 4,
          salesRank: 355
        },
        {
          date: '2024-02-13',
          reviewCount: 7395,
          rating: 4,
          salesRank: 255
        },
        {
          date: '2024-02-14',
          reviewCount: 7416,
          rating: 4,
          salesRank: 319
        },
        {
          date: '2024-02-15',
          reviewCount: 7416,
          rating: 4,
          salesRank: 353
        },
        {
          date: '2024-02-16',
          reviewCount: 7416,
          rating: 4,
          salesRank: 298
        },
        {
          date: '2024-02-17',
          reviewCount: 7416,
          rating: 4,
          salesRank: 326
        },
        {
          date: '2024-02-18',
          reviewCount: 7435,
          rating: 4,
          salesRank: 403
        },
        {
          date: '2024-02-19',
          reviewCount: 7435,
          rating: 4,
          salesRank: 341
        },
        {
          date: '2024-02-20',
          reviewCount: 7435,
          rating: 4,
          salesRank: 406
        },
        {
          date: '2024-02-21',
          reviewCount: 7435,
          rating: 4,
          salesRank: 312
        },
        {
          date: '2024-02-22',
          reviewCount: 7465,
          rating: 4,
          salesRank: 322
        },
        {
          date: '2024-02-23',
          reviewCount: 7465,
          rating: 4,
          salesRank: 328
        },
        {
          date: '2024-02-24',
          reviewCount: 7465,
          rating: 4,
          salesRank: 364
        },
        {
          date: '2024-02-25',
          reviewCount: 7465,
          rating: 4,
          salesRank: 383
        },
        {
          date: '2024-02-26',
          reviewCount: 7494,
          rating: 4,
          salesRank: 326
        },
        {
          date: '2024-02-27',
          reviewCount: 7494,
          rating: 4,
          salesRank: 308
        },
        {
          date: '2024-02-28',
          reviewCount: 7494,
          rating: 4,
          salesRank: 320
        },
        {
          date: '2024-02-29',
          reviewCount: 7515,
          rating: 4,
          salesRank: 293
        },
        {
          date: '2024-03-01',
          reviewCount: 7515,
          rating: 4,
          salesRank: 353
        },
        {
          date: '2024-03-02',
          reviewCount: 7515,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2024-03-03',
          reviewCount: 7515,
          rating: 4,
          salesRank: 299
        },
        {
          date: '2024-03-04',
          reviewCount: 7538,
          rating: 4,
          salesRank: 190
        },
        {
          date: '2024-03-05',
          reviewCount: 7538,
          rating: 4,
          salesRank: 159
        },
        {
          date: '2024-03-06',
          reviewCount: 7538,
          rating: 4,
          salesRank: 157
        },
        {
          date: '2024-03-07',
          reviewCount: 7563,
          rating: 4,
          salesRank: 141
        },
        {
          date: '2024-03-08',
          reviewCount: 7563,
          rating: 4,
          salesRank: 148
        },
        {
          date: '2024-03-09',
          reviewCount: 7563,
          rating: 4,
          salesRank: 118
        },
        {
          date: '2024-03-10',
          reviewCount: 7563,
          rating: 4,
          salesRank: 124
        },
        {
          date: '2024-03-11',
          reviewCount: 7590,
          rating: 4,
          salesRank: 165
        },
        {
          date: '2024-03-12',
          reviewCount: 7590,
          rating: 4,
          salesRank: 157
        },
        {
          date: '2024-03-13',
          reviewCount: 7590,
          rating: 4,
          salesRank: 179
        },
        {
          date: '2024-03-14',
          reviewCount: 7590,
          rating: 4,
          salesRank: 222
        },
        {
          date: '2024-03-15',
          reviewCount: 7618,
          rating: 4,
          salesRank: 239
        },
        {
          date: '2024-03-16',
          reviewCount: 7618,
          rating: 4,
          salesRank: 255
        },
        {
          date: '2024-03-17',
          reviewCount: 7618,
          rating: 4,
          salesRank: 316
        },
        {
          date: '2024-03-18',
          reviewCount: 7618,
          rating: 4,
          salesRank: 256
        },
        {
          date: '2024-03-19',
          reviewCount: 7654,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2024-03-20',
          reviewCount: 7654,
          rating: 4,
          salesRank: 338
        },
        {
          date: '2024-03-21',
          reviewCount: 7654,
          rating: 4,
          salesRank: 413
        },
        {
          date: '2024-03-22',
          reviewCount: 7681,
          rating: 4,
          salesRank: 448
        },
        {
          date: '2024-03-23',
          reviewCount: 7681,
          rating: 4,
          salesRank: 430
        },
        {
          date: '2024-03-24',
          reviewCount: 7681,
          rating: 4,
          salesRank: 501
        },
        {
          date: '2024-03-25',
          reviewCount: 7681,
          rating: 4,
          salesRank: 490
        },
        {
          date: '2024-03-26',
          reviewCount: 7720,
          rating: 4,
          salesRank: 499
        },
        {
          date: '2024-03-27',
          reviewCount: 7720,
          rating: 4,
          salesRank: 451
        },
        {
          date: '2024-03-28',
          reviewCount: 7720,
          rating: 4,
          salesRank: 427
        },
        {
          date: '2024-03-29',
          reviewCount: 7720,
          rating: 4,
          salesRank: 384
        },
        {
          date: '2024-03-30',
          reviewCount: 7720,
          rating: 4,
          salesRank: 408
        },
        {
          date: '2024-03-31',
          reviewCount: 7720,
          rating: 4,
          salesRank: 310
        },
        {
          date: '2024-04-01',
          reviewCount: 7720,
          rating: 4,
          salesRank: 195
        },
        {
          date: '2024-04-02',
          reviewCount: 7720,
          rating: 4,
          salesRank: 165
        },
        {
          date: '2024-04-03',
          reviewCount: 7780,
          rating: 4,
          salesRank: 160
        },
        {
          date: '2024-04-04',
          reviewCount: 7780,
          rating: 4,
          salesRank: 163
        },
        {
          date: '2024-04-05',
          reviewCount: 7780,
          rating: 4,
          salesRank: 151
        },
        {
          date: '2024-04-06',
          reviewCount: 7780,
          rating: 4,
          salesRank: 167
        },
        {
          date: '2024-04-07',
          reviewCount: 7780,
          rating: 4,
          salesRank: 177
        },
        {
          date: '2024-04-08',
          reviewCount: 7780,
          rating: 4,
          salesRank: 224
        },
        {
          date: '2024-04-09',
          reviewCount: 7780,
          rating: 4,
          salesRank: 258
        },
        {
          date: '2024-04-10',
          reviewCount: 7780,
          rating: 4,
          salesRank: 285
        },
        {
          date: '2024-04-11',
          reviewCount: 7506,
          rating: 4,
          salesRank: 202
        },
        {
          date: '2024-04-12',
          reviewCount: 7506,
          rating: 4,
          salesRank: 282
        },
        {
          date: '2024-04-13',
          reviewCount: 7506,
          rating: 4,
          salesRank: 268
        },
        {
          date: '2024-04-14',
          reviewCount: 7520,
          rating: 4,
          salesRank: 265
        },
        {
          date: '2024-04-15',
          reviewCount: 7520,
          rating: 4,
          salesRank: 283
        },
        {
          date: '2024-04-16',
          reviewCount: 7520,
          rating: 4,
          salesRank: 285
        },
        {
          date: '2024-04-17',
          reviewCount: 7520,
          rating: 4,
          salesRank: 305
        },
        {
          date: '2024-04-18',
          reviewCount: 7858,
          rating: 4,
          salesRank: 316
        },
        {
          date: '2024-04-19',
          reviewCount: 7858,
          rating: 4,
          salesRank: 251
        },
        {
          date: '2024-04-20',
          reviewCount: 7858,
          rating: 4,
          salesRank: 295
        },
        {
          date: '2024-04-21',
          reviewCount: 7879,
          rating: 4,
          salesRank: 363
        },
        {
          date: '2024-04-22',
          reviewCount: 7879,
          rating: 4,
          salesRank: 267
        },
        {
          date: '2024-04-23',
          reviewCount: 7879,
          rating: 4,
          salesRank: 279
        },
        {
          date: '2024-04-24',
          reviewCount: 7879,
          rating: 4,
          salesRank: 319
        },
        {
          date: '2024-04-25',
          reviewCount: 7912,
          rating: 4,
          salesRank: 364
        },
        {
          date: '2024-04-26',
          reviewCount: 7912,
          rating: 4,
          salesRank: 370
        },
        {
          date: '2024-04-27',
          reviewCount: 7912,
          rating: 4,
          salesRank: 460
        },
        {
          date: '2024-04-28',
          reviewCount: 7934,
          rating: 4,
          salesRank: 443
        },
        {
          date: '2024-04-29',
          reviewCount: 7934,
          rating: 4,
          salesRank: 291
        },
        {
          date: '2024-04-30',
          reviewCount: 7934,
          rating: 4,
          salesRank: 179
        },
        {
          date: '2024-05-01',
          reviewCount: 7934,
          rating: 4,
          salesRank: 158
        },
        {
          date: '2024-05-02',
          reviewCount: 7965,
          rating: 4,
          salesRank: 136
        },
        {
          date: '2024-05-03',
          reviewCount: 7965,
          rating: 4,
          salesRank: 120
        },
        {
          date: '2024-05-04',
          reviewCount: 7965,
          rating: 4,
          salesRank: 127
        },
        {
          date: '2024-05-05',
          reviewCount: 7981,
          rating: 4,
          salesRank: 148
        },
        {
          date: '2024-05-06',
          reviewCount: 7981,
          rating: 4,
          salesRank: 164
        },
        {
          date: '2024-05-07',
          reviewCount: 7981,
          rating: 4,
          salesRank: 176
        },
        {
          date: '2024-05-08',
          reviewCount: 7981,
          rating: 4,
          salesRank: 157
        },
        {
          date: '2024-05-09',
          reviewCount: 7981,
          rating: 4,
          salesRank: 209
        },
        {
          date: '2024-05-10',
          reviewCount: 7981,
          rating: 4,
          salesRank: 226
        },
        {
          date: '2024-05-11',
          reviewCount: 7753,
          rating: 4,
          salesRank: 218
        },
        {
          date: '2024-05-12',
          reviewCount: 7753,
          rating: 4,
          salesRank: 267
        },
        {
          date: '2024-05-13',
          reviewCount: 7753,
          rating: 4,
          salesRank: 233
        },
        {
          date: '2024-05-14',
          reviewCount: 7753,
          rating: 4,
          salesRank: 234
        },
        {
          date: '2024-05-15',
          reviewCount: 7753,
          rating: 4,
          salesRank: 189
        },
        {
          date: '2024-05-16',
          reviewCount: 7790,
          rating: 4,
          salesRank: 229
        },
        {
          date: '2024-05-17',
          reviewCount: 7790,
          rating: 4,
          salesRank: 224
        },
        {
          date: '2024-05-18',
          reviewCount: 7790,
          rating: 4,
          salesRank: 259
        },
        {
          date: '2024-05-19',
          reviewCount: 7790,
          rating: 4,
          salesRank: 241
        },
        {
          date: '2024-05-20',
          reviewCount: 7790,
          rating: 4,
          salesRank: 250
        },
        {
          date: '2024-05-21',
          reviewCount: 7790,
          rating: 4,
          salesRank: 203
        },
        {
          date: '2024-05-22',
          reviewCount: 7823,
          rating: 4,
          salesRank: 206
        },
        {
          date: '2024-05-23',
          reviewCount: 7823,
          rating: 4,
          salesRank: 137
        },
        {
          date: '2024-05-24',
          reviewCount: 7823,
          rating: 4,
          salesRank: 166
        },
        {
          date: '2024-05-25',
          reviewCount: 7823,
          rating: 4,
          salesRank: 208
        },
        {
          date: '2024-05-26',
          reviewCount: 7823,
          rating: 4,
          salesRank: 207
        },
        {
          date: '2024-05-27',
          reviewCount: 7879,
          rating: 4,
          salesRank: 136
        },
        {
          date: '2024-05-28',
          reviewCount: 7879,
          rating: 4,
          salesRank: 113
        },
        {
          date: '2024-05-29',
          reviewCount: 7879,
          rating: 4,
          salesRank: 93
        },
        {
          date: '2024-05-30',
          reviewCount: 7903,
          rating: 4,
          salesRank: 85
        },
        {
          date: '2024-05-31',
          reviewCount: 7903,
          rating: 4,
          salesRank: 88
        },
        {
          date: '2024-06-01',
          reviewCount: 7903,
          rating: 4,
          salesRank: 89
        },
        {
          date: '2024-06-02',
          reviewCount: 7937,
          rating: 4,
          salesRank: 83
        },
        {
          date: '2024-06-03',
          reviewCount: 7937,
          rating: 4,
          salesRank: 114
        },
        {
          date: '2024-06-04',
          reviewCount: 7937,
          rating: 4,
          salesRank: 139
        },
        {
          date: '2024-06-05',
          reviewCount: 7937,
          rating: 4,
          salesRank: 123
        },
        {
          date: '2024-06-06',
          reviewCount: 7972,
          rating: 4,
          salesRank: 123
        },
        {
          date: '2024-06-07',
          reviewCount: 7972,
          rating: 4,
          salesRank: 164
        },
        {
          date: '2024-06-08',
          reviewCount: 7972,
          rating: 4,
          salesRank: 175
        },
        {
          date: '2024-06-09',
          reviewCount: 7987,
          rating: 4,
          salesRank: 215
        },
        {
          date: '2024-06-10',
          reviewCount: 7987,
          rating: 4,
          salesRank: 242
        },
        {
          date: '2024-06-11',
          reviewCount: 7987,
          rating: 4,
          salesRank: 266
        },
        {
          date: '2024-06-12',
          reviewCount: 8018,
          rating: 4,
          salesRank: 151
        },
        {
          date: '2024-06-13',
          reviewCount: 8018,
          rating: 4,
          salesRank: 192
        },
        {
          date: '2024-06-14',
          reviewCount: 8018,
          rating: 4,
          salesRank: 227
        },
        {
          date: '2024-06-15',
          reviewCount: 8018,
          rating: 4,
          salesRank: 246
        },
        {
          date: '2024-06-16',
          reviewCount: 8055,
          rating: 4,
          salesRank: 285
        },
        {
          date: '2024-06-17',
          reviewCount: 8055,
          rating: 4,
          salesRank: 238
        },
        {
          date: '2024-06-18',
          reviewCount: 8055,
          rating: 4,
          salesRank: 245
        },
        {
          date: '2024-06-19',
          reviewCount: 8055,
          rating: 4,
          salesRank: 247
        },
        {
          date: '2024-06-20',
          reviewCount: 8101,
          rating: 4,
          salesRank: 224
        },
        {
          date: '2024-06-21',
          reviewCount: 8101,
          rating: 4,
          salesRank: 231
        },
        {
          date: '2024-06-22',
          reviewCount: 8101,
          rating: 4,
          salesRank: 234
        },
        {
          date: '2024-06-23',
          reviewCount: 8138,
          rating: 4,
          salesRank: 199
        },
        {
          date: '2024-06-24',
          reviewCount: 8138,
          rating: 4,
          salesRank: 130
        },
        {
          date: '2024-06-25',
          reviewCount: 8138,
          rating: 4,
          salesRank: 118
        },
        {
          date: '2024-06-26',
          reviewCount: 8138,
          rating: 4,
          salesRank: 103
        },
        {
          date: '2024-06-27',
          reviewCount: 8138,
          rating: 4,
          salesRank: 107
        },
        {
          date: '2024-06-28',
          reviewCount: 8138,
          rating: 4,
          salesRank: 125
        },
        {
          date: '2024-06-29',
          reviewCount: 8138,
          rating: 4,
          salesRank: 130
        },
        {
          date: '2024-06-30',
          reviewCount: 8195,
          rating: 4,
          salesRank: 131
        },
        {
          date: '2024-07-01',
          reviewCount: 8195,
          rating: 4,
          salesRank: 176
        },
        {
          date: '2024-07-02',
          reviewCount: 8195,
          rating: 4,
          salesRank: 195
        },
        {
          date: '2024-07-03',
          reviewCount: 8232,
          rating: 4,
          salesRank: 197
        },
        {
          date: '2024-07-04',
          reviewCount: 8232,
          rating: 4,
          salesRank: 197
        },
        {
          date: '2024-07-05',
          reviewCount: 8232,
          rating: 4,
          salesRank: 241
        },
        {
          date: '2024-07-06',
          reviewCount: 8232,
          rating: 4,
          salesRank: 230
        },
        {
          date: '2024-07-07',
          reviewCount: 8264,
          rating: 4,
          salesRank: 281
        },
        {
          date: '2024-07-08',
          reviewCount: 8264,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2024-07-09',
          reviewCount: 8264,
          rating: 4,
          salesRank: 280
        },
        {
          date: '2024-07-10',
          reviewCount: 8264,
          rating: 4,
          salesRank: 299
        },
        {
          date: '2024-07-11',
          reviewCount: 8297,
          rating: 4,
          salesRank: 358
        },
        {
          date: '2024-07-12',
          reviewCount: 8297,
          rating: 4,
          salesRank: 450
        },
        {
          date: '2024-07-13',
          reviewCount: 8297,
          rating: 4,
          salesRank: 394
        },
        {
          date: '2024-07-14',
          reviewCount: 8328,
          rating: 4,
          salesRank: 403
        },
        {
          date: '2024-07-15',
          reviewCount: 8328,
          rating: 4,
          salesRank: 430
        },
        {
          date: '2024-07-16',
          reviewCount: 8328,
          rating: 4,
          salesRank: 641
        },
        {
          date: '2024-07-17',
          reviewCount: 8328,
          rating: 4,
          salesRank: 580
        },
        {
          date: '2024-07-18',
          reviewCount: 8367,
          rating: 4,
          salesRank: 604
        },
        {
          date: '2024-07-19',
          reviewCount: 8367,
          rating: 4,
          salesRank: 548
        },
        {
          date: '2024-07-20',
          reviewCount: 8367,
          rating: 4,
          salesRank: 453
        },
        {
          date: '2024-07-21',
          reviewCount: 8391,
          rating: 4,
          salesRank: 447
        },
        {
          date: '2024-07-22',
          reviewCount: 8391,
          rating: 4,
          salesRank: 303
        },
        {
          date: '2024-07-23',
          reviewCount: 8391,
          rating: 4,
          salesRank: 226
        },
        {
          date: '2024-07-24',
          reviewCount: 8391,
          rating: 4,
          salesRank: 207
        },
        {
          date: '2024-07-25',
          reviewCount: 8425,
          rating: 4,
          salesRank: 206
        },
        {
          date: '2024-07-26',
          reviewCount: 8425,
          rating: 4,
          salesRank: 204
        },
        {
          date: '2024-07-27',
          reviewCount: 8425,
          rating: 4,
          salesRank: 208
        },
        {
          date: '2024-07-28',
          reviewCount: 8446,
          rating: 4,
          salesRank: 202
        },
        {
          date: '2024-07-29',
          reviewCount: 8446,
          rating: 4,
          salesRank: 244
        },
        {
          date: '2024-07-30',
          reviewCount: 8446,
          rating: 4,
          salesRank: 324
        },
        {
          date: '2024-07-31',
          reviewCount: 8446,
          rating: 4,
          salesRank: 322
        },
        {
          date: '2024-08-01',
          reviewCount: 8483,
          rating: 4,
          salesRank: 351
        },
        {
          date: '2024-08-02',
          reviewCount: 8483,
          rating: 4,
          salesRank: 378
        },
        {
          date: '2024-08-03',
          reviewCount: 8483,
          rating: 4,
          salesRank: 374
        },
        {
          date: '2024-08-04',
          reviewCount: 8350,
          rating: 4,
          salesRank: 282
        },
        {
          date: '2024-08-05',
          reviewCount: 8350,
          rating: 4,
          salesRank: 401
        },
        {
          date: '2024-08-06',
          reviewCount: 8350,
          rating: 4,
          salesRank: 411
        },
        {
          date: '2024-08-07',
          reviewCount: 8350,
          rating: 4,
          salesRank: 383
        },
        {
          date: '2024-08-08',
          reviewCount: 8387,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 8387,
          rating: 4,
          salesRank: 433
        },
        {
          date: '2024-08-10',
          reviewCount: 8387,
          rating: 4,
          salesRank: 456
        },
        {
          date: '2024-08-11',
          reviewCount: 8402,
          rating: 4,
          salesRank: 481
        },
        {
          date: '2024-08-12',
          reviewCount: 8402,
          rating: 4,
          salesRank: 502
        },
        {
          date: '2024-08-13',
          reviewCount: 8402,
          rating: 4,
          salesRank: 402
        },
        {
          date: '2024-08-14',
          reviewCount: 8402,
          rating: 4,
          salesRank: 421
        },
        {
          date: '2024-08-15',
          reviewCount: 8436,
          rating: 4,
          salesRank: 453
        },
        {
          date: '2024-08-16',
          reviewCount: 8436,
          rating: 4,
          salesRank: 466
        },
        {
          date: '2024-08-17',
          reviewCount: 8436,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 8460,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 8460,
          rating: 4,
          salesRank: 291
        },
        {
          date: '2024-08-20',
          reviewCount: 8460,
          rating: 4,
          salesRank: 279
        },
        {
          date: '2024-08-21',
          reviewCount: 8460,
          rating: 4,
          salesRank: 225
        },
        {
          date: '2024-08-22',
          reviewCount: 8501,
          rating: 4,
          salesRank: 189
        },
        {
          date: '2024-08-23',

          salesRank: 198
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-23T18:23:51.322Z'
    },
    {
      id: '254d3fb9-b5db-414b-9a10-bfc147ff15e1',
      productName: 'Wireless Earbuds',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds.png',
      comparisonMetric: PerformanceComparisonMetric.BEST_SELLER_RANK,
      productPerformanceRecords: [
        {
          date: '2024-06-10',

          salesRank: 41550
        },
        {
          date: '2024-06-11',

          salesRank: 6791
        },
        {
          date: '2024-06-12',

          salesRank: 8574
        },
        {
          date: '2024-06-13',

          salesRank: 6771
        },
        {
          date: '2024-06-14',

          salesRank: 10426
        },
        {
          date: '2024-06-15',

          salesRank: 7511
        },
        {
          date: '2024-06-16',

          salesRank: 7339
        },
        {
          date: '2024-06-17',

          salesRank: 12351
        },
        {
          date: '2024-06-18',

          salesRank: 24241
        },
        {
          date: '2024-06-19',

          salesRank: 35888
        },
        {
          date: '2024-06-20',

          salesRank: 31400
        },
        {
          date: '2024-06-21',

          salesRank: 64465
        },
        {
          date: '2024-06-22',
          reviewCount: 9,
          rating: 4,
          salesRank: 20474
        },
        {
          date: '2024-06-23',
          reviewCount: 9,
          rating: 4,
          salesRank: 24510
        },
        {
          date: '2024-06-24',
          reviewCount: 9,
          rating: 4,
          salesRank: 32328
        },
        {
          date: '2024-06-25',
          reviewCount: 9,
          rating: 4,
          salesRank: 19338
        },
        {
          date: '2024-06-26',
          reviewCount: 9,
          rating: 4,
          salesRank: 24919
        },
        {
          date: '2024-06-27',
          reviewCount: 9,
          rating: 4,
          salesRank: 35671
        },
        {
          date: '2024-06-28',
          reviewCount: 9,
          rating: 4,
          salesRank: 27748
        },
        {
          date: '2024-06-29',
          reviewCount: 9,
          rating: 4,
          salesRank: 12771
        },
        {
          date: '2024-06-30',
          reviewCount: 9,
          rating: 4,
          salesRank: 20926
        },
        {
          date: '2024-07-01',
          reviewCount: 39,
          rating: 4,
          salesRank: 23536
        },
        {
          date: '2024-07-02',
          reviewCount: 39,
          rating: 4,
          salesRank: 14926
        },
        {
          date: '2024-07-03',
          reviewCount: 39,
          rating: 4,
          salesRank: 26233
        },
        {
          date: '2024-07-04',
          reviewCount: 39,
          rating: 4,
          salesRank: 17884
        },
        {
          date: '2024-07-05',
          reviewCount: 39,
          rating: 4,
          salesRank: 25315
        },
        {
          date: '2024-07-06',
          reviewCount: 50,
          rating: 4,
          salesRank: 31089
        },
        {
          date: '2024-07-07',
          reviewCount: 50,
          rating: 4,
          salesRank: 66006
        },
        {
          date: '2024-07-08',
          reviewCount: 50,
          rating: 4,
          salesRank: 91345
        },
        {
          date: '2024-07-09',
          reviewCount: 50,
          rating: 4,
          salesRank: 49958
        },
        {
          date: '2024-07-10',
          reviewCount: 50,
          rating: 4,
          salesRank: 35697
        },
        {
          date: '2024-07-11',
          reviewCount: 50,
          rating: 4,
          salesRank: 31091
        },
        {
          date: '2024-07-12',
          reviewCount: 50,
          rating: 4,
          salesRank: 74536
        },
        {
          date: '2024-07-13',
          reviewCount: 50,
          rating: 4,
          salesRank: 72589
        },
        {
          date: '2024-07-14',
          reviewCount: 62,
          rating: 4,
          salesRank: 90871
        },
        {
          date: '2024-07-15',
          reviewCount: 62,
          rating: 4,
          salesRank: 39442
        },
        {
          date: '2024-07-16',
          reviewCount: 62,
          rating: 4,
          salesRank: 52822
        },
        {
          date: '2024-07-17',
          reviewCount: 62,
          rating: 4,
          salesRank: 53808
        },
        {
          date: '2024-07-18',
          reviewCount: 62,
          rating: 4,
          salesRank: 52206
        },
        {
          date: '2024-07-19',
          reviewCount: 62,
          rating: 4,
          salesRank: 61241
        },
        {
          date: '2024-07-20',
          reviewCount: 62,
          rating: 4,
          salesRank: 43979
        },
        {
          date: '2024-07-21',
          reviewCount: 62,
          rating: 4,
          salesRank: 86053
        },
        {
          date: '2024-07-22',
          reviewCount: 62,
          rating: 4,
          salesRank: 112501
        },
        {
          date: '2024-07-23',
          reviewCount: 62,
          rating: 4,
          salesRank: 132119
        },
        {
          date: '2024-07-24',
          reviewCount: 62,
          rating: 4,
          salesRank: 47601
        },
        {
          date: '2024-07-25',
          reviewCount: 62,
          rating: 4,
          salesRank: 100419
        },
        {
          date: '2024-07-26',
          reviewCount: 62,
          rating: 4,
          salesRank: 91099
        },
        {
          date: '2024-07-27',
          reviewCount: 62,
          rating: 4,
          salesRank: 110398
        },
        {
          date: '2024-07-28',
          reviewCount: 62,
          rating: 4,
          salesRank: 128502
        },
        {
          date: '2024-07-29',
          reviewCount: 87,
          rating: 4,
          salesRank: 143924
        },
        {
          date: '2024-07-30',
          reviewCount: 87,
          rating: 4,
          salesRank: 21884
        },
        {
          date: '2024-07-31',
          reviewCount: 87,
          rating: 4,
          salesRank: 56163
        },
        {
          date: '2024-08-01',
          reviewCount: 87,
          rating: 4,
          salesRank: 89323
        },
        {
          date: '2024-08-02',
          reviewCount: 87,
          rating: 4,
          salesRank: 35198
        },
        {
          date: '2024-08-03',
          reviewCount: 92,
          rating: 4,
          salesRank: 41383
        },
        {
          date: '2024-08-04',
          reviewCount: 92,
          rating: 4,
          salesRank: 32392
        },
        {
          date: '2024-08-05',
          reviewCount: 92,
          rating: 4,
          salesRank: 49370
        },
        {
          date: '2024-08-06',
          reviewCount: 92,
          rating: 4,
          salesRank: 44269
        },
        {
          date: '2024-08-07',
          reviewCount: 92,
          rating: 4,
          salesRank: 48686
        },
        {
          date: '2024-08-08',
          reviewCount: 98,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 98,
          rating: 4,
          salesRank: 31367
        },
        {
          date: '2024-08-10',
          reviewCount: 98,
          rating: 4,
          salesRank: 36089
        },
        {
          date: '2024-08-11',
          reviewCount: 98,
          rating: 4,
          salesRank: 26194
        },
        {
          date: '2024-08-12',
          reviewCount: 98,
          rating: 4,
          salesRank: 77946
        },
        {
          date: '2024-08-13',
          reviewCount: 98,
          rating: 4,
          salesRank: 43380
        },
        {
          date: '2024-08-14',
          reviewCount: 98,
          rating: 4,
          salesRank: 36856
        },
        {
          date: '2024-08-15',
          reviewCount: 98,
          rating: 4,
          salesRank: 32229
        },
        {
          date: '2024-08-16',
          reviewCount: 98,
          rating: 4,
          salesRank: 35745
        },
        {
          date: '2024-08-17',
          reviewCount: 98,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 98,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 102,
          rating: 4,
          salesRank: 32191
        },
        {
          date: '2024-08-20',
          reviewCount: 102,
          rating: 4,
          salesRank: 39743
        },
        {
          date: '2024-08-21',
          reviewCount: 102,
          rating: 4,
          salesRank: 20033
        },
        {
          date: '2024-08-22',
          reviewCount: 102,
          rating: 4,
          salesRank: 47521
        },
        {
          date: '2024-08-23',

          salesRank: 53339
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-05-12T14:59:08.457Z'
    },
    {
      id: 'de28723c-99be-4c95-878e-1cd171d4b5fc',
      productName: 'True Wireless Bluetooth Earbuds',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds-3.png',
      productPerformanceRecords: [
        {
          date: '2023-10-10',

          salesRank: 4966
        },
        {
          date: '2023-10-11',

          salesRank: 6718
        },
        {
          date: '2023-10-12',

          salesRank: 8039
        },
        {
          date: '2023-10-13',

          salesRank: 5416
        },
        {
          date: '2023-10-14',

          salesRank: 7370
        },
        {
          date: '2023-10-15',
          reviewCount: 5,
          rating: 4,
          salesRank: 4763
        },
        {
          date: '2023-10-16',
          reviewCount: 5,
          rating: 4,
          salesRank: 4230
        },
        {
          date: '2023-10-17',
          reviewCount: 5,
          rating: 4,
          salesRank: 4586
        },
        {
          date: '2023-10-18',
          reviewCount: 5,
          rating: 4,
          salesRank: 4780
        },
        {
          date: '2023-10-19',
          reviewCount: 5,
          rating: 4,
          salesRank: 4124
        },
        {
          date: '2023-10-20',
          reviewCount: 9,
          rating: 3,
          salesRank: 4372
        },
        {
          date: '2023-10-21',
          reviewCount: 9,
          rating: 3,
          salesRank: 3744
        },
        {
          date: '2023-10-22',
          reviewCount: 9,
          rating: 3,
          salesRank: 2997
        },
        {
          date: '2023-10-23',
          reviewCount: 9,
          rating: 3,
          salesRank: 4104
        },
        {
          date: '2023-10-24',
          reviewCount: 9,
          rating: 3,
          salesRank: 2028
        },
        {
          date: '2023-10-25',
          reviewCount: 9,
          rating: 3,
          salesRank: 2084
        },
        {
          date: '2023-10-26',
          reviewCount: 12,
          rating: 3,
          salesRank: 2494
        },
        {
          date: '2023-10-27',
          reviewCount: 12,
          rating: 3,
          salesRank: 2325
        },
        {
          date: '2023-10-28',
          reviewCount: 12,
          rating: 3,
          salesRank: 2188
        },
        {
          date: '2023-10-29',
          reviewCount: 12,
          rating: 3,
          salesRank: 2540
        },
        {
          date: '2023-10-30',
          reviewCount: 12,
          rating: 3,
          salesRank: 2458
        },
        {
          date: '2023-10-31',
          reviewCount: 12,
          rating: 3,
          salesRank: 3126
        },
        {
          date: '2023-11-01',
          reviewCount: 19,
          rating: 3,
          salesRank: 2701
        },
        {
          date: '2023-11-02',
          reviewCount: 19,
          rating: 3,
          salesRank: 3290
        },
        {
          date: '2023-11-03',
          reviewCount: 19,
          rating: 3,
          salesRank: 2355
        },
        {
          date: '2023-11-04',
          reviewCount: 19,
          rating: 3,
          salesRank: 1901
        },
        {
          date: '2023-11-05',
          reviewCount: 19,
          rating: 3,
          salesRank: 2165
        },
        {
          date: '2023-11-06',
          reviewCount: 19,
          rating: 3,
          salesRank: 2919
        },
        {
          date: '2023-11-07',
          reviewCount: 22,
          rating: 3,
          salesRank: 2234
        },
        {
          date: '2023-11-08',
          reviewCount: 22,
          rating: 3,
          salesRank: 2679
        },
        {
          date: '2023-11-09',
          reviewCount: 22,
          rating: 3,
          salesRank: 2636
        },
        {
          date: '2023-11-10',
          reviewCount: 22,
          rating: 3,
          salesRank: 2335
        },
        {
          date: '2023-11-11',
          reviewCount: 22,
          rating: 3,
          salesRank: 3742
        },
        {
          date: '2023-11-12',
          reviewCount: 22,
          rating: 3,
          salesRank: 3209
        },
        {
          date: '2023-11-13',
          reviewCount: 22,
          rating: 3,
          salesRank: 3022
        },
        {
          date: '2023-11-14',
          reviewCount: 22,
          rating: 3,
          salesRank: 2607
        },
        {
          date: '2023-11-15',
          reviewCount: 22,
          rating: 3,
          salesRank: 2838
        },
        {
          date: '2023-11-16',
          reviewCount: 22,
          rating: 3,
          salesRank: 2627
        },
        {
          date: '2023-11-17',
          reviewCount: 23,
          rating: 3,
          salesRank: 561
        },
        {
          date: '2023-11-18',
          reviewCount: 23,
          rating: 3,
          salesRank: 435
        },
        {
          date: '2023-11-19',
          reviewCount: 23,
          rating: 3,
          salesRank: 419
        },
        {
          date: '2023-11-20',
          reviewCount: 23,
          rating: 3,
          salesRank: 404
        },
        {
          date: '2023-11-21',
          reviewCount: 23,
          rating: 3,
          salesRank: 403
        },
        {
          date: '2023-11-22',
          reviewCount: 28,
          rating: 4,
          salesRank: 312
        },
        {
          date: '2023-11-23',
          reviewCount: 28,
          rating: 4,
          salesRank: 305
        },
        {
          date: '2023-11-24',
          reviewCount: 28,
          rating: 4,
          salesRank: 267
        },
        {
          date: '2023-11-25',
          reviewCount: 28,
          rating: 4,
          salesRank: 253
        },
        {
          date: '2023-11-26',
          reviewCount: 28,
          rating: 4,
          salesRank: 259
        },
        {
          date: '2023-11-27',
          reviewCount: 40,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2023-11-28',
          reviewCount: 40,
          rating: 4,
          salesRank: 243
        },
        {
          date: '2023-11-29',
          reviewCount: 40,
          rating: 4,
          salesRank: 347
        },
        {
          date: '2023-11-30',
          reviewCount: 40,
          rating: 4,
          salesRank: 482
        },
        {
          date: '2023-12-01',
          reviewCount: 56,
          rating: 4,
          salesRank: 696
        },
        {
          date: '2023-12-02',
          reviewCount: 56,
          rating: 4,
          salesRank: 697
        },
        {
          date: '2023-12-03',
          reviewCount: 56,
          rating: 4,
          salesRank: 925
        },
        {
          date: '2023-12-04',
          reviewCount: 65,
          rating: 4,
          salesRank: 938
        },
        {
          date: '2023-12-05',
          reviewCount: 65,
          rating: 4,
          salesRank: 356
        },
        {
          date: '2023-12-06',
          reviewCount: 65,
          rating: 4,
          salesRank: 441
        },
        {
          date: '2023-12-07',
          reviewCount: 80,
          rating: 4,
          salesRank: 343
        },
        {
          date: '2023-12-08',
          reviewCount: 80,
          rating: 4,
          salesRank: 409
        },
        {
          date: '2023-12-09',
          reviewCount: 80,
          rating: 4,
          salesRank: 402
        },
        {
          date: '2023-12-10',
          reviewCount: 80,
          rating: 4,
          salesRank: 399
        },
        {
          date: '2023-12-11',
          reviewCount: 86,
          rating: 4,
          salesRank: 399
        },
        {
          date: '2023-12-12',
          reviewCount: 86,
          rating: 4,
          salesRank: 375
        },
        {
          date: '2023-12-13',
          reviewCount: 86,
          rating: 4,
          salesRank: 335
        },
        {
          date: '2023-12-14',
          reviewCount: 86,
          rating: 4,
          salesRank: 303
        },
        {
          date: '2023-12-15',
          reviewCount: 112,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2023-12-16',
          reviewCount: 112,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2023-12-17',
          reviewCount: 112,
          rating: 4,
          salesRank: 305
        },
        {
          date: '2023-12-18',
          reviewCount: 112,
          rating: 4,
          salesRank: 306
        },
        {
          date: '2023-12-19',
          reviewCount: 131,
          rating: 4,
          salesRank: 181
        },
        {
          date: '2023-12-20',
          reviewCount: 131,
          rating: 4,
          salesRank: 207
        },
        {
          date: '2023-12-21',
          reviewCount: 131,
          rating: 4,
          salesRank: 223
        },
        {
          date: '2023-12-22',
          reviewCount: 142,
          rating: 4,
          salesRank: 215
        },
        {
          date: '2023-12-23',
          reviewCount: 142,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2023-12-24',
          reviewCount: 142,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2023-12-25',
          reviewCount: 142,
          rating: 4,
          salesRank: 323
        },
        {
          date: '2023-12-26',
          reviewCount: 169,
          rating: 4,
          salesRank: 357
        },
        {
          date: '2023-12-27',
          reviewCount: 169,
          rating: 4,
          salesRank: 204
        },
        {
          date: '2023-12-28',
          reviewCount: 169,
          rating: 4,
          salesRank: 146
        },
        {
          date: '2023-12-29',
          reviewCount: 169,
          rating: 4,
          salesRank: 212
        },
        {
          date: '2023-12-30',
          reviewCount: 198,
          rating: 4,
          salesRank: 273
        },
        {
          date: '2023-12-31',
          reviewCount: 198,
          rating: 4,
          salesRank: 351
        },
        {
          date: '2024-01-01',
          reviewCount: 198,
          rating: 4,
          salesRank: 387
        },
        {
          date: '2024-01-02',
          reviewCount: 227,
          rating: 4,
          salesRank: 250
        },
        {
          date: '2024-01-03',
          reviewCount: 227,
          rating: 4,
          salesRank: 175
        },
        {
          date: '2024-01-04',
          reviewCount: 227,
          rating: 4,
          salesRank: 241
        },
        {
          date: '2024-01-05',
          reviewCount: 227,
          rating: 4,
          salesRank: 264
        },
        {
          date: '2024-01-06',
          reviewCount: 258,
          rating: 4,
          salesRank: 369
        },
        {
          date: '2024-01-07',
          reviewCount: 258,
          rating: 4,
          salesRank: 382
        },
        {
          date: '2024-01-08',
          reviewCount: 258,
          rating: 4,
          salesRank: 437
        },
        {
          date: '2024-01-09',
          reviewCount: 293,
          rating: 4,
          salesRank: 420
        },
        {
          date: '2024-01-10',
          reviewCount: 293,
          rating: 4,
          salesRank: 458
        },
        {
          date: '2024-01-11',
          reviewCount: 293,
          rating: 4,
          salesRank: 429
        },
        {
          date: '2024-01-12',
          reviewCount: 293,
          rating: 4,
          salesRank: 349
        },
        {
          date: '2024-01-13',
          reviewCount: 316,
          rating: 4,
          salesRank: 382
        },
        {
          date: '2024-01-14',
          reviewCount: 316,
          rating: 4,
          salesRank: 450
        },
        {
          date: '2024-01-15',
          reviewCount: 316,
          rating: 4,
          salesRank: 567
        },
        {
          date: '2024-01-16',
          reviewCount: 347,
          rating: 4,
          salesRank: 585
        },
        {
          date: '2024-01-17',
          reviewCount: 347,
          rating: 4,
          salesRank: 622
        },
        {
          date: '2024-01-18',
          reviewCount: 347,
          rating: 4,
          salesRank: 601
        },
        {
          date: '2024-01-19',
          reviewCount: 347,
          rating: 4,
          salesRank: 617
        },
        {
          date: '2024-01-20',
          reviewCount: 383,
          rating: 4,
          salesRank: 585
        },
        {
          date: '2024-01-21',
          reviewCount: 383,
          rating: 4,
          salesRank: 719
        },
        {
          date: '2024-01-22',
          reviewCount: 383,
          rating: 4,
          salesRank: 839
        },
        {
          date: '2024-01-23',
          reviewCount: 410,
          rating: 4,
          salesRank: 553
        },
        {
          date: '2024-01-24',
          reviewCount: 410,
          rating: 4,
          salesRank: 635
        },
        {
          date: '2024-01-25',
          reviewCount: 410,
          rating: 4,
          salesRank: 657
        },
        {
          date: '2024-01-26',
          reviewCount: 435,
          rating: 4,
          salesRank: 618
        },
        {
          date: '2024-01-27',
          reviewCount: 435,
          rating: 4,
          salesRank: 838
        },
        {
          date: '2024-01-28',
          reviewCount: 435,
          rating: 4,
          salesRank: 829
        },
        {
          date: '2024-01-29',
          reviewCount: 435,
          rating: 4,
          salesRank: 682
        },
        {
          date: '2024-01-30',
          reviewCount: 470,
          rating: 4,
          salesRank: 643
        },
        {
          date: '2024-01-31',
          reviewCount: 470,
          rating: 4,
          salesRank: 533
        },
        {
          date: '2024-02-01',
          reviewCount: 470,
          rating: 4,
          salesRank: 355
        },
        {
          date: '2024-02-02',
          reviewCount: 489,
          rating: 4,
          salesRank: 262
        },
        {
          date: '2024-02-03',
          reviewCount: 489,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2024-02-04',
          reviewCount: 489,
          rating: 4,
          salesRank: 237
        },
        {
          date: '2024-02-05',
          reviewCount: 489,
          rating: 4,
          salesRank: 225
        },
        {
          date: '2024-02-06',
          reviewCount: 521,
          rating: 4,
          salesRank: 226
        },
        {
          date: '2024-02-07',
          reviewCount: 521,
          rating: 4,
          salesRank: 187
        },
        {
          date: '2024-02-08',
          reviewCount: 521,
          rating: 4,
          salesRank: 172
        },
        {
          date: '2024-02-09',
          reviewCount: 521,
          rating: 4,
          salesRank: 193
        },
        {
          date: '2024-02-10',
          reviewCount: 558,
          rating: 4,
          salesRank: 281
        },
        {
          date: '2024-02-11',
          reviewCount: 558,
          rating: 4,
          salesRank: 306
        },
        {
          date: '2024-02-12',
          reviewCount: 558,
          rating: 4,
          salesRank: 276
        },
        {
          date: '2024-02-13',
          reviewCount: 581,
          rating: 4,
          salesRank: 262
        },
        {
          date: '2024-02-14',
          reviewCount: 581,
          rating: 4,
          salesRank: 248
        },
        {
          date: '2024-02-15',
          reviewCount: 581,
          rating: 4,
          salesRank: 235
        },
        {
          date: '2024-02-16',
          reviewCount: 581,
          rating: 4,
          salesRank: 235
        },
        {
          date: '2024-02-17',
          reviewCount: 604,
          rating: 4,
          salesRank: 311
        },
        {
          date: '2024-02-18',
          reviewCount: 604,
          rating: 4,
          salesRank: 318
        },
        {
          date: '2024-02-19',
          reviewCount: 604,
          rating: 4,
          salesRank: 340
        },
        {
          date: '2024-02-20',
          reviewCount: 633,
          rating: 4,
          salesRank: 296
        },
        {
          date: '2024-02-21',
          reviewCount: 633,
          rating: 4,
          salesRank: 266
        },
        {
          date: '2024-02-22',
          reviewCount: 633,
          rating: 4,
          salesRank: 343
        },
        {
          date: '2024-02-23',
          reviewCount: 633,
          rating: 4,
          salesRank: 429
        },
        {
          date: '2024-02-24',
          reviewCount: 667,
          rating: 4,
          salesRank: 465
        },
        {
          date: '2024-02-25',
          reviewCount: 667,
          rating: 4,
          salesRank: 466
        },
        {
          date: '2024-02-26',
          reviewCount: 667,
          rating: 4,
          salesRank: 527
        },
        {
          date: '2024-02-27',
          reviewCount: 687,
          rating: 4,
          salesRank: 461
        },
        {
          date: '2024-02-28',
          reviewCount: 687,
          rating: 4,
          salesRank: 402
        },
        {
          date: '2024-02-29',
          reviewCount: 687,
          rating: 4,
          salesRank: 397
        },
        {
          date: '2024-03-01',
          reviewCount: 712,
          rating: 4,
          salesRank: 385
        },
        {
          date: '2024-03-02',
          reviewCount: 712,
          rating: 4,
          salesRank: 438
        },
        {
          date: '2024-03-03',
          reviewCount: 712,
          rating: 4,
          salesRank: 505
        },
        {
          date: '2024-03-04',
          reviewCount: 712,
          rating: 4,
          salesRank: 471
        },
        {
          date: '2024-03-05',
          reviewCount: 745,
          rating: 4,
          salesRank: 489
        },
        {
          date: '2024-03-06',
          reviewCount: 745,
          rating: 4,
          salesRank: 426
        },
        {
          date: '2024-03-07',
          reviewCount: 745,
          rating: 4,
          salesRank: 371
        },
        {
          date: '2024-03-08',
          reviewCount: 745,
          rating: 4,
          salesRank: 397
        },
        {
          date: '2024-03-09',
          reviewCount: 784,
          rating: 4,
          salesRank: 407
        },
        {
          date: '2024-03-10',
          reviewCount: 784,
          rating: 4,
          salesRank: 379
        },
        {
          date: '2024-03-11',
          reviewCount: 784,
          rating: 4,
          salesRank: 408
        },
        {
          date: '2024-03-12',
          reviewCount: 803,
          rating: 4,
          salesRank: 362
        },
        {
          date: '2024-03-13',
          reviewCount: 803,
          rating: 4,
          salesRank: 268
        },
        {
          date: '2024-03-14',
          reviewCount: 803,
          rating: 4,
          salesRank: 441
        },
        {
          date: '2024-03-15',
          reviewCount: 823,
          rating: 4,
          salesRank: 379
        },
        {
          date: '2024-03-16',
          reviewCount: 823,
          rating: 4,
          salesRank: 452
        },
        {
          date: '2024-03-17',
          reviewCount: 823,
          rating: 4,
          salesRank: 472
        },
        {
          date: '2024-03-18',
          reviewCount: 823,
          rating: 4,
          salesRank: 500
        },
        {
          date: '2024-03-19',
          reviewCount: 858,
          rating: 4,
          salesRank: 424
        },
        {
          date: '2024-03-20',
          reviewCount: 858,
          rating: 4,
          salesRank: 296
        },
        {
          date: '2024-03-21',
          reviewCount: 858,
          rating: 4,
          salesRank: 254
        },
        {
          date: '2024-03-22',
          reviewCount: 858,
          rating: 4,
          salesRank: 421
        },
        {
          date: '2024-03-23',
          reviewCount: 858,
          rating: 4,
          salesRank: 487
        },
        {
          date: '2024-03-24',
          reviewCount: 858,
          rating: 4,
          salesRank: 625
        },
        {
          date: '2024-03-25',
          reviewCount: 858,
          rating: 4,
          salesRank: 516
        },
        {
          date: '2024-03-26',
          reviewCount: 924,
          rating: 4,
          salesRank: 462
        },
        {
          date: '2024-03-27',
          reviewCount: 924,
          rating: 4,
          salesRank: 494
        },
        {
          date: '2024-03-28',
          reviewCount: 924,
          rating: 4,
          salesRank: 435
        },
        {
          date: '2024-03-29',
          reviewCount: 924,
          rating: 4,
          salesRank: 379
        },
        {
          date: '2024-03-30',
          reviewCount: 954,
          rating: 4,
          salesRank: 360
        },
        {
          date: '2024-03-31',
          reviewCount: 954,
          rating: 4,
          salesRank: 394
        },
        {
          date: '2024-04-01',
          reviewCount: 954,
          rating: 4,
          salesRank: 398
        },
        {
          date: '2024-04-02',
          reviewCount: 954,
          rating: 4,
          salesRank: 295
        },
        {
          date: '2024-04-03',
          reviewCount: 981,
          rating: 4,
          salesRank: 219
        },
        {
          date: '2024-04-04',
          reviewCount: 981,
          rating: 4,
          salesRank: 280
        },
        {
          date: '2024-04-05',
          reviewCount: 981,
          rating: 4,
          salesRank: 444
        },
        {
          date: '2024-04-06',
          reviewCount: 981,
          rating: 4,
          salesRank: 480
        },
        {
          date: '2024-04-07',
          reviewCount: 1014,
          rating: 4,
          salesRank: 462
        },
        {
          date: '2024-04-08',
          reviewCount: 1014,
          rating: 4,
          salesRank: 427
        },
        {
          date: '2024-04-09',
          reviewCount: 1014,
          rating: 4,
          salesRank: 242
        },
        {
          date: '2024-04-10',
          reviewCount: 1014,
          rating: 4,
          salesRank: 198
        },
        {
          date: '2024-04-11',
          reviewCount: 1041,
          rating: 4,
          salesRank: 268
        },
        {
          date: '2024-04-12',
          reviewCount: 1041,
          rating: 4,
          salesRank: 391
        },
        {
          date: '2024-04-13',
          reviewCount: 1041,
          rating: 4,
          salesRank: 373
        },
        {
          date: '2024-04-14',
          reviewCount: 1069,
          rating: 4,
          salesRank: 415
        },
        {
          date: '2024-04-15',
          reviewCount: 1069,
          rating: 4,
          salesRank: 433
        },
        {
          date: '2024-04-16',
          reviewCount: 1069,
          rating: 4,
          salesRank: 239
        },
        {
          date: '2024-04-17',
          reviewCount: 1069,
          rating: 4,
          salesRank: 196
        },
        {
          date: '2024-04-18',
          reviewCount: 1103,
          rating: 4,
          salesRank: 278
        },
        {
          date: '2024-04-19',
          reviewCount: 1103,
          rating: 4,
          salesRank: 366
        },
        {
          date: '2024-04-20',
          reviewCount: 1103,
          rating: 4,
          salesRank: 476
        },
        {
          date: '2024-04-21',
          reviewCount: 1127,
          rating: 4,
          salesRank: 545
        },
        {
          date: '2024-04-22',
          reviewCount: 1127,
          rating: 4,
          salesRank: 637
        },
        {
          date: '2024-04-23',
          reviewCount: 1127,
          rating: 4,
          salesRank: 421
        },
        {
          date: '2024-04-24',
          reviewCount: 1127,
          rating: 4,
          salesRank: 301
        },
        {
          date: '2024-04-25',
          reviewCount: 1161,
          rating: 4,
          salesRank: 376
        },
        {
          date: '2024-04-26',
          reviewCount: 1161,
          rating: 4,
          salesRank: 497
        },
        {
          date: '2024-04-27',
          reviewCount: 1161,
          rating: 4,
          salesRank: 673
        },
        {
          date: '2024-04-28',
          reviewCount: 1182,
          rating: 4,
          salesRank: 806
        },
        {
          date: '2024-04-29',
          reviewCount: 1182,
          rating: 4,
          salesRank: 451
        },
        {
          date: '2024-04-30',
          reviewCount: 1182,
          rating: 4,
          salesRank: 400
        },
        {
          date: '2024-05-01',
          reviewCount: 1182,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2024-05-02',
          reviewCount: 1212,
          rating: 4,
          salesRank: 286
        },
        {
          date: '2024-05-03',
          reviewCount: 1212,
          rating: 4,
          salesRank: 281
        },
        {
          date: '2024-05-04',
          reviewCount: 1212,
          rating: 4,
          salesRank: 285
        },
        {
          date: '2024-05-05',
          reviewCount: 1233,
          rating: 4,
          salesRank: 319
        },
        {
          date: '2024-05-06',
          reviewCount: 1233,
          rating: 4,
          salesRank: 311
        },
        {
          date: '2024-05-07',
          reviewCount: 1233,
          rating: 4,
          salesRank: 248
        },
        {
          date: '2024-05-08',
          reviewCount: 1233,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2024-05-09',
          reviewCount: 1272,
          rating: 4,
          salesRank: 281
        },
        {
          date: '2024-05-10',
          reviewCount: 1272,
          rating: 4,
          salesRank: 255
        },
        {
          date: '2024-05-11',
          reviewCount: 1272,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2024-05-12',
          reviewCount: 1297,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2024-05-13',
          reviewCount: 1297,
          rating: 4,
          salesRank: 254
        },
        {
          date: '2024-05-14',
          reviewCount: 1297,
          rating: 4,
          salesRank: 291
        },
        {
          date: '2024-05-15',
          reviewCount: 1297,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2024-05-16',
          reviewCount: 1329,
          rating: 4,
          salesRank: 295
        },
        {
          date: '2024-05-17',
          reviewCount: 1329,
          rating: 4,
          salesRank: 368
        },
        {
          date: '2024-05-18',
          reviewCount: 1329,
          rating: 4,
          salesRank: 382
        },
        {
          date: '2024-05-19',
          reviewCount: 1352,
          rating: 4,
          salesRank: 351
        },
        {
          date: '2024-05-20',
          reviewCount: 1352,
          rating: 4,
          salesRank: 525
        },
        {
          date: '2024-05-21',
          reviewCount: 1352,
          rating: 4,
          salesRank: 221
        },
        {
          date: '2024-05-22',
          reviewCount: 1352,
          rating: 4,
          salesRank: 178
        },
        {
          date: '2024-05-23',
          reviewCount: 1371,
          rating: 4,
          salesRank: 331
        },
        {
          date: '2024-05-24',
          reviewCount: 1371,
          rating: 4,
          salesRank: 610
        },
        {
          date: '2024-05-25',
          reviewCount: 1371,
          rating: 4,
          salesRank: 688
        },
        {
          date: '2024-05-26',
          reviewCount: 1403,
          rating: 4,
          salesRank: 660
        },
        {
          date: '2024-05-27',
          reviewCount: 1403,
          rating: 4,
          salesRank: 524
        },
        {
          date: '2024-05-28',
          reviewCount: 1403,
          rating: 4,
          salesRank: 268
        },
        {
          date: '2024-05-29',
          reviewCount: 1403,
          rating: 4,
          salesRank: 274
        },
        {
          date: '2024-05-30',
          reviewCount: 1428,
          rating: 4,
          salesRank: 312
        },
        {
          date: '2024-05-31',
          reviewCount: 1428,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2024-06-01',
          reviewCount: 1428,
          rating: 4,
          salesRank: 319
        },
        {
          date: '2024-06-02',
          reviewCount: 1442,
          rating: 4,
          salesRank: 412
        },
        {
          date: '2024-06-03',
          reviewCount: 1442,
          rating: 4,
          salesRank: 398
        },
        {
          date: '2024-06-04',
          reviewCount: 1442,
          rating: 4,
          salesRank: 328
        },
        {
          date: '2024-06-05',
          reviewCount: 1442,
          rating: 4,
          salesRank: 436
        },
        {
          date: '2024-06-06',
          reviewCount: 1474,
          rating: 4,
          salesRank: 386
        },
        {
          date: '2024-06-07',
          reviewCount: 1474,
          rating: 4,
          salesRank: 312
        },
        {
          date: '2024-06-08',
          reviewCount: 1474,
          rating: 4,
          salesRank: 273
        },
        {
          date: '2024-06-09',
          reviewCount: 1504,
          rating: 4,
          salesRank: 306
        },
        {
          date: '2024-06-10',
          reviewCount: 1504,
          rating: 4,
          salesRank: 282
        },
        {
          date: '2024-06-11',
          reviewCount: 1504,
          rating: 4,
          salesRank: 225
        },
        {
          date: '2024-06-12',
          reviewCount: 1535,
          rating: 4,
          salesRank: 238
        },
        {
          date: '2024-06-13',
          reviewCount: 1535,
          rating: 4,
          salesRank: 226
        },
        {
          date: '2024-06-14',
          reviewCount: 1535,
          rating: 4,
          salesRank: 188
        },
        {
          date: '2024-06-15',
          reviewCount: 1535,
          rating: 4,
          salesRank: 239
        },
        {
          date: '2024-06-16',
          reviewCount: 1557,
          rating: 4,
          salesRank: 233
        },
        {
          date: '2024-06-17',
          reviewCount: 1557,
          rating: 4,
          salesRank: 335
        },
        {
          date: '2024-06-18',
          reviewCount: 1557,
          rating: 4,
          salesRank: 333
        },
        {
          date: '2024-06-19',
          reviewCount: 1557,
          rating: 4,
          salesRank: 271
        },
        {
          date: '2024-06-20',
          reviewCount: 1590,
          rating: 4,
          salesRank: 519
        },
        {
          date: '2024-06-21',
          reviewCount: 1590,
          rating: 4,
          salesRank: 545
        },
        {
          date: '2024-06-22',
          reviewCount: 1590,
          rating: 4,
          salesRank: 642
        },
        {
          date: '2024-06-23',
          reviewCount: 1624,
          rating: 4,
          salesRank: 785
        },
        {
          date: '2024-06-24',
          reviewCount: 1624,
          rating: 4,
          salesRank: 427
        },
        {
          date: '2024-06-25',
          reviewCount: 1624,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2024-06-26',
          reviewCount: 1624,
          rating: 4,
          salesRank: 320
        },
        {
          date: '2024-06-27',
          reviewCount: 1624,
          rating: 4,
          salesRank: 276
        },
        {
          date: '2024-06-28',
          reviewCount: 1624,
          rating: 4,
          salesRank: 315
        },
        {
          date: '2024-06-29',
          reviewCount: 1624,
          rating: 4,
          salesRank: 254
        },
        {
          date: '2024-06-30',
          reviewCount: 1624,
          rating: 4,
          salesRank: 252
        },
        {
          date: '2024-07-01',
          reviewCount: 1694,
          rating: 4,
          salesRank: 270
        },
        {
          date: '2024-07-02',
          reviewCount: 1694,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2024-07-03',
          reviewCount: 1694,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2024-07-04',
          reviewCount: 1728,
          rating: 4,
          salesRank: 274
        },
        {
          date: '2024-07-05',
          reviewCount: 1728,
          rating: 4,
          salesRank: 273
        },
        {
          date: '2024-07-06',
          reviewCount: 1728,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2024-07-07',
          reviewCount: 1757,
          rating: 4,
          salesRank: 346
        },
        {
          date: '2024-07-08',
          reviewCount: 1757,
          rating: 4,
          salesRank: 341
        },
        {
          date: '2024-07-09',
          reviewCount: 1757,
          rating: 4,
          salesRank: 346
        },
        {
          date: '2024-07-10',
          reviewCount: 1757,
          rating: 4,
          salesRank: 373
        },
        {
          date: '2024-07-11',
          reviewCount: 1786,
          rating: 4,
          salesRank: 347
        },
        {
          date: '2024-07-12',
          reviewCount: 1786,
          rating: 4,
          salesRank: 313
        },
        {
          date: '2024-07-13',
          reviewCount: 1786,
          rating: 4,
          salesRank: 319
        },
        {
          date: '2024-07-14',
          reviewCount: 1806,
          rating: 4,
          salesRank: 340
        },
        {
          date: '2024-07-15',
          reviewCount: 1806,
          rating: 4,
          salesRank: 560
        },
        {
          date: '2024-07-16',
          reviewCount: 1806,
          rating: 4,
          salesRank: 317
        },
        {
          date: '2024-07-17',
          reviewCount: 1806,
          rating: 4,
          salesRank: 197
        },
        {
          date: '2024-07-18',
          reviewCount: 1840,
          rating: 4,
          salesRank: 180
        },
        {
          date: '2024-07-19',
          reviewCount: 1840,
          rating: 4,
          salesRank: 219
        },
        {
          date: '2024-07-20',
          reviewCount: 1840,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2024-07-21',
          reviewCount: 1881,
          rating: 4,
          salesRank: 348
        },
        {
          date: '2024-07-22',
          reviewCount: 1881,
          rating: 4,
          salesRank: 351
        },
        {
          date: '2024-07-23',
          reviewCount: 1881,
          rating: 4,
          salesRank: 306
        },
        {
          date: '2024-07-24',
          reviewCount: 1881,
          rating: 4,
          salesRank: 281
        },
        {
          date: '2024-07-25',
          reviewCount: 1923,
          rating: 4,
          salesRank: 208
        },
        {
          date: '2024-07-26',
          reviewCount: 1923,
          rating: 4,
          salesRank: 194
        },
        {
          date: '2024-07-27',
          reviewCount: 1923,
          rating: 4,
          salesRank: 181
        },
        {
          date: '2024-07-28',
          reviewCount: 1960,
          rating: 4,
          salesRank: 162
        },
        {
          date: '2024-07-29',
          reviewCount: 1960,
          rating: 4,
          salesRank: 163
        },
        {
          date: '2024-07-30',
          reviewCount: 1960,
          rating: 4,
          salesRank: 169
        },
        {
          date: '2024-07-31',
          reviewCount: 1960,
          rating: 4,
          salesRank: 169
        },
        {
          date: '2024-08-01',
          reviewCount: 1994,
          rating: 4,
          salesRank: 186
        },
        {
          date: '2024-08-02',
          reviewCount: 1994,
          rating: 4,
          salesRank: 169
        },
        {
          date: '2024-08-03',
          reviewCount: 1994,
          rating: 4,
          salesRank: 183
        },
        {
          date: '2024-08-04',
          reviewCount: 2036,
          rating: 4,
          salesRank: 200
        },
        {
          date: '2024-08-05',
          reviewCount: 2036,
          rating: 4,
          salesRank: 336
        },
        {
          date: '2024-08-06',
          reviewCount: 2036,
          rating: 4,
          salesRank: 327
        },
        {
          date: '2024-08-07',
          reviewCount: 2036,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2024-08-08',
          reviewCount: 2080,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2024-08-09',
          reviewCount: 2080,
          rating: 4,
          salesRank: 688
        },
        {
          date: '2024-08-10',
          reviewCount: 2080,
          rating: 4,
          salesRank: 1029
        },
        {
          date: '2024-08-11',
          reviewCount: 2114,
          rating: 4,
          salesRank: 1131
        },
        {
          date: '2024-08-12',
          reviewCount: 2114,
          rating: 4,
          salesRank: 725
        },
        {
          date: '2024-08-13',
          reviewCount: 2114,
          rating: 4,
          salesRank: 577
        },
        {
          date: '2024-08-14',
          reviewCount: 2114,
          rating: 4,
          salesRank: 405
        },
        {
          date: '2024-08-15',
          reviewCount: 2145,
          rating: 4,
          salesRank: 418
        },
        {
          date: '2024-08-16',
          reviewCount: 2145,
          rating: 4,
          salesRank: 365
        },
        {
          date: '2024-08-17',
          reviewCount: 2145,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 2179,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 2179,
          rating: 4,
          salesRank: 351
        },
        {
          date: '2024-08-20',
          reviewCount: 2179,
          rating: 4,
          salesRank: 390
        },
        {
          date: '2024-08-21',
          reviewCount: 2179,
          rating: 4,
          salesRank: 419
        },
        {
          date: '2024-08-22',
          reviewCount: 2223,
          rating: 4,
          salesRank: 396
        },
        {
          date: '2024-08-23',

          salesRank: 331
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-23T18:22:01.426Z'
    },
    {
      id: '40a1e75b-1bd0-4318-b4dc-ff12212a70d9',
      productName: 'In Ear Earbud Headphone',
      productUrl: 'https://www.amazon.com',
      productImage: 'https://media.productwind.com/sample/images/sample-sku-wireless-earbuds-3.png',
      productPerformanceRecords: [
        {
          date: '2018-05-26',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-05-27',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-05-28',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-05-29',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-05-30',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-05-31',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-01',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-02',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-03',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-04',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-05',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-06',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-07',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-08',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-09',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-10',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-11',
          reviewCount: 1,
          rating: 1
        },
        {
          date: '2018-06-12',
          reviewCount: 2,
          rating: 2
        },
        {
          date: '2018-06-13',
          reviewCount: 2,
          rating: 2
        },
        {
          date: '2018-06-14',
          reviewCount: 2,
          rating: 2
        },
        {
          date: '2018-06-15',
          reviewCount: 2,
          rating: 2
        },
        {
          date: '2018-06-16',
          reviewCount: 3,
          rating: 2
        },
        {
          date: '2018-06-17',
          reviewCount: 3,
          rating: 2
        },
        {
          date: '2018-06-18',
          reviewCount: 3,
          rating: 2
        },
        {
          date: '2018-06-19',
          reviewCount: 3,
          rating: 2
        },
        {
          date: '2018-06-20',
          reviewCount: 4,
          rating: 2
        },
        {
          date: '2018-06-21',
          reviewCount: 4,
          rating: 2
        },
        {
          date: '2018-06-22',
          reviewCount: 4,
          rating: 2
        },
        {
          date: '2018-06-23',
          reviewCount: 4,
          rating: 2
        },
        {
          date: '2018-06-24',
          reviewCount: 4,
          rating: 2
        },
        {
          date: '2018-06-25',
          reviewCount: 4,
          rating: 2
        },
        {
          date: '2018-06-26',
          reviewCount: 8,
          rating: 2
        },
        {
          date: '2018-06-27',
          reviewCount: 8,
          rating: 2
        },
        {
          date: '2018-06-28',
          reviewCount: 9,
          rating: 3
        },
        {
          date: '2018-06-29',
          reviewCount: 9,
          rating: 3
        },
        {
          date: '2018-06-30',
          reviewCount: 11,
          rating: 3
        },
        {
          date: '2018-07-01',
          reviewCount: 11,
          rating: 3
        },
        {
          date: '2018-07-02',
          reviewCount: 11,
          rating: 3
        },
        {
          date: '2018-07-03',
          reviewCount: 11,
          rating: 3
        },
        {
          date: '2018-07-04',
          reviewCount: 11,
          rating: 3
        },
        {
          date: '2018-07-05',
          reviewCount: 11,
          rating: 3
        },
        {
          date: '2018-07-06',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-07',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-08',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-09',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-10',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-11',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-12',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-13',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-14',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-15',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-16',
          reviewCount: 12,
          rating: 3
        },
        {
          date: '2018-07-17',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2018-07-18',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2018-07-19',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2018-07-20',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2018-07-21',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2018-07-22',
          reviewCount: 13,
          rating: 3
        },
        {
          date: '2018-07-23',
          reviewCount: 14,
          rating: 3
        },
        {
          date: '2018-07-24',
          reviewCount: 14,
          rating: 3
        },
        {
          date: '2018-07-25',
          reviewCount: 14,
          rating: 3
        },
        {
          date: '2018-07-26',
          reviewCount: 14,
          rating: 3
        },
        {
          date: '2018-07-27',
          reviewCount: 14,
          rating: 3
        },
        {
          date: '2018-07-28',
          reviewCount: 14,
          rating: 3
        },
        {
          date: '2018-07-29',
          reviewCount: 14,
          rating: 3
        },
        {
          date: '2018-07-30',
          reviewCount: 15,
          rating: 3
        },
        {
          date: '2018-07-31',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-01',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-02',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-03',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-04',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-05',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-06',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-07',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-08',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-09',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-10',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-11',
          reviewCount: 16,
          rating: 3
        },
        {
          date: '2018-08-12',
          reviewCount: 17,
          rating: 3
        },
        {
          date: '2018-08-13',
          reviewCount: 17,
          rating: 3
        },
        {
          date: '2018-08-14',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2018-08-15',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2018-08-16',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2018-08-17',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2018-08-18',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2018-08-19',
          reviewCount: 19,
          rating: 3
        },
        {
          date: '2018-08-20',
          reviewCount: 19,
          rating: 3
        },
        {
          date: '2018-08-21',
          reviewCount: 20,
          rating: 3
        },
        {
          date: '2018-08-22',
          reviewCount: 20,
          rating: 3
        },
        {
          date: '2018-08-23',
          reviewCount: 18,
          rating: 3
        },
        {
          date: '2018-08-24',
          reviewCount: 18,
          rating: 3,
          salesRank: 156
        },
        {
          date: '2018-08-25',
          reviewCount: 18,
          rating: 3,
          salesRank: 106
        },
        {
          date: '2018-08-26',
          reviewCount: 18,
          rating: 3,
          salesRank: 84
        },
        {
          date: '2018-08-27',
          reviewCount: 18,
          rating: 3,
          salesRank: 86
        },
        {
          date: '2018-08-28',
          reviewCount: 18,
          rating: 3,
          salesRank: 115
        },
        {
          date: '2018-08-29',
          reviewCount: 18,
          rating: 3,
          salesRank: 137
        },
        {
          date: '2018-08-30',
          reviewCount: 21,
          rating: 3,
          salesRank: 161
        },
        {
          date: '2018-08-31',
          reviewCount: 21,
          rating: 3,
          salesRank: 151
        },
        {
          date: '2018-09-01',
          reviewCount: 21,
          rating: 3,
          salesRank: 168
        },
        {
          date: '2018-09-02',
          reviewCount: 21,
          rating: 3,
          salesRank: 213
        },
        {
          date: '2018-09-03',
          reviewCount: 21,
          rating: 3,
          salesRank: 312
        },
        {
          date: '2018-09-04',
          reviewCount: 21,
          rating: 3,
          salesRank: 367
        },
        {
          date: '2018-09-05',
          reviewCount: 21,
          rating: 3,
          salesRank: 337
        },
        {
          date: '2018-09-06',
          reviewCount: 23,
          rating: 3,
          salesRank: 372
        },
        {
          date: '2018-09-07',
          reviewCount: 23,
          rating: 3,
          salesRank: 296
        },
        {
          date: '2018-09-08',
          reviewCount: 23,
          rating: 3,
          salesRank: 391
        },
        {
          date: '2018-09-09',
          reviewCount: 25,
          rating: 3,
          salesRank: 477
        },
        {
          date: '2018-09-10',
          reviewCount: 25,
          rating: 3,
          salesRank: 496
        },
        {
          date: '2018-09-11',
          reviewCount: 25,
          rating: 3,
          salesRank: 377
        },
        {
          date: '2018-09-12',
          reviewCount: 26,
          rating: 3,
          salesRank: 337
        },
        {
          date: '2018-09-13',
          reviewCount: 26,
          rating: 3,
          salesRank: 429
        },
        {
          date: '2018-09-14',
          reviewCount: 26,
          rating: 3,
          salesRank: 317
        },
        {
          date: '2018-09-15',
          reviewCount: 26,
          rating: 3,
          salesRank: 269
        },
        {
          date: '2018-09-16',
          reviewCount: 26,
          rating: 3,
          salesRank: 325
        },
        {
          date: '2018-09-17',
          reviewCount: 26,
          rating: 3,
          salesRank: 204
        },
        {
          date: '2018-09-18',
          reviewCount: 26,
          rating: 3,
          salesRank: 320
        },
        {
          date: '2018-09-19',
          reviewCount: 26,
          rating: 3,
          salesRank: 343
        },
        {
          date: '2018-09-20',
          reviewCount: 26,
          rating: 3,
          salesRank: 6092
        },
        {
          date: '2018-09-21',
          reviewCount: 26,
          rating: 3,
          salesRank: 6687
        },
        {
          date: '2018-09-22',
          reviewCount: 29,
          rating: 3,
          salesRank: 6451
        },
        {
          date: '2018-09-23',
          reviewCount: 29,
          rating: 3,
          salesRank: 5134
        },
        {
          date: '2018-09-24',
          reviewCount: 29,
          rating: 3,
          salesRank: 4043
        },
        {
          date: '2018-09-25',
          reviewCount: 29,
          rating: 3,
          salesRank: 6404
        },
        {
          date: '2018-09-26',
          reviewCount: 29,
          rating: 3,
          salesRank: 3843
        },
        {
          date: '2018-09-27',
          reviewCount: 29,
          rating: 3,
          salesRank: 5064
        },
        {
          date: '2018-09-28',
          reviewCount: 29,
          rating: 3,
          salesRank: 5387
        },
        {
          date: '2018-09-29',
          reviewCount: 29,
          rating: 3,
          salesRank: 3899
        },
        {
          date: '2018-09-30',
          reviewCount: 29,
          rating: 3,
          salesRank: 2953
        },
        {
          date: '2018-10-01',
          reviewCount: 29,
          rating: 3,
          salesRank: 3306
        },
        {
          date: '2018-10-02',
          reviewCount: 29,
          rating: 3,
          salesRank: 3899
        },
        {
          date: '2018-10-03',
          reviewCount: 29,
          rating: 3,
          salesRank: 3423
        },
        {
          date: '2018-10-04',
          reviewCount: 29,
          rating: 3,
          salesRank: 3453
        },
        {
          date: '2018-10-05',
          reviewCount: 29,
          rating: 3,
          salesRank: 4249
        },
        {
          date: '2018-10-06',
          reviewCount: 29,
          rating: 3,
          salesRank: 4551
        },
        {
          date: '2018-10-07',
          reviewCount: 29,
          rating: 3,
          salesRank: 3584
        },
        {
          date: '2018-10-08',
          reviewCount: 29,
          rating: 3,
          salesRank: 4219
        },
        {
          date: '2018-10-09',
          reviewCount: 29,
          rating: 3,
          salesRank: 6928
        },
        {
          date: '2018-10-10',
          reviewCount: 29,
          rating: 3,
          salesRank: 7717
        },
        {
          date: '2018-10-11',
          reviewCount: 29,
          rating: 3,
          salesRank: 9274
        },
        {
          date: '2018-10-12',
          reviewCount: 29,
          rating: 3,
          salesRank: 10051
        },
        {
          date: '2018-10-13',
          reviewCount: 29,
          rating: 3,
          salesRank: 12958
        },
        {
          date: '2018-10-14',
          reviewCount: 29,
          rating: 3,
          salesRank: 12653
        },
        {
          date: '2018-10-15',
          reviewCount: 29,
          rating: 3,
          salesRank: 14650
        },
        {
          date: '2018-10-16',
          reviewCount: 29,
          rating: 3,
          salesRank: 8849
        },
        {
          date: '2018-10-17',
          reviewCount: 29,
          rating: 3,
          salesRank: 13060
        },
        {
          date: '2018-10-18',
          reviewCount: 29,
          rating: 3,
          salesRank: 8622
        },
        {
          date: '2018-10-19',
          reviewCount: 29,
          rating: 3,
          salesRank: 16021
        },
        {
          date: '2018-10-20',
          reviewCount: 29,
          rating: 3,
          salesRank: 14899
        },
        {
          date: '2018-10-21',
          reviewCount: 29,
          rating: 3,
          salesRank: 14870
        },
        {
          date: '2018-10-22',
          reviewCount: 29,
          rating: 3,
          salesRank: 19990
        },
        {
          date: '2018-10-23',
          reviewCount: 29,
          rating: 3,
          salesRank: 16815
        },
        {
          date: '2018-10-24',
          reviewCount: 29,
          rating: 3,
          salesRank: 13319
        },
        {
          date: '2018-10-25',
          reviewCount: 29,
          rating: 3,
          salesRank: 17136
        },
        {
          date: '2018-10-26',
          reviewCount: 29,
          rating: 3,
          salesRank: 11273
        },
        {
          date: '2018-10-27',
          reviewCount: 29,
          rating: 3,
          salesRank: 9877
        },
        {
          date: '2018-10-28',
          reviewCount: 29,
          rating: 3,
          salesRank: 11763
        },
        {
          date: '2018-10-29',
          reviewCount: 29,
          rating: 3,
          salesRank: 9236
        },
        {
          date: '2018-10-30',
          reviewCount: 29,
          rating: 3,
          salesRank: 9790
        },
        {
          date: '2018-10-31',
          reviewCount: 29,
          rating: 3,
          salesRank: 11807
        },
        {
          date: '2018-11-01',
          reviewCount: 29,
          rating: 3,
          salesRank: 14403
        },
        {
          date: '2018-11-02',
          reviewCount: 29,
          rating: 3,
          salesRank: 11029
        },
        {
          date: '2018-11-03',
          reviewCount: 29,
          rating: 3,
          salesRank: 13772
        },
        {
          date: '2018-11-04',
          reviewCount: 29,
          rating: 3,
          salesRank: 7385
        },
        {
          date: '2018-11-05',
          reviewCount: 29,
          rating: 3,
          salesRank: 10546
        },
        {
          date: '2018-11-06',
          reviewCount: 29,
          rating: 3,
          salesRank: 6889
        },
        {
          date: '2018-11-07',
          reviewCount: 29,
          rating: 3,
          salesRank: 5965
        },
        {
          date: '2018-11-08',
          reviewCount: 29,
          rating: 3,
          salesRank: 6569
        },
        {
          date: '2018-11-09',
          reviewCount: 29,
          rating: 3,
          salesRank: 7065
        },
        {
          date: '2018-11-10',
          reviewCount: 29,
          rating: 3,
          salesRank: 8617
        },
        {
          date: '2018-11-11',
          reviewCount: 29,
          rating: 3,
          salesRank: 6625
        },
        {
          date: '2018-11-12',
          reviewCount: 29,
          rating: 3,
          salesRank: 10015
        },
        {
          date: '2018-11-13',
          reviewCount: 36,
          rating: 3,
          salesRank: 6497
        },
        {
          date: '2018-11-14',
          reviewCount: 36,
          rating: 3,
          salesRank: 8931
        },
        {
          date: '2018-11-15',
          reviewCount: 36,
          rating: 3,
          salesRank: 10240
        },
        {
          date: '2018-11-16',
          reviewCount: 36,
          rating: 3,
          salesRank: 13502
        },
        {
          date: '2018-11-17',
          reviewCount: 36,
          rating: 3,
          salesRank: 15438
        },
        {
          date: '2018-11-18',
          reviewCount: 44,
          rating: 3
        },
        {
          date: '2018-11-19',
          reviewCount: 44,
          rating: 3,
          salesRank: 12831
        },
        {
          date: '2018-11-20',
          reviewCount: 44,
          rating: 3
        },
        {
          date: '2018-11-21',
          reviewCount: 44,
          rating: 3
        },
        {
          date: '2018-11-22',
          reviewCount: 44,
          rating: 3
        },
        {
          date: '2018-11-23',
          reviewCount: 44,
          rating: 3
        },
        {
          date: '2018-11-24',
          reviewCount: 45,
          rating: 3
        },
        {
          date: '2018-11-25',
          reviewCount: 45,
          rating: 3
        },
        {
          date: '2018-11-26',
          reviewCount: 45,
          rating: 3
        },
        {
          date: '2018-11-27',
          reviewCount: 45,
          rating: 3,
          salesRank: 9602
        },
        {
          date: '2018-11-28',
          reviewCount: 45,
          rating: 3,
          salesRank: 11399
        },
        {
          date: '2018-11-29',
          reviewCount: 45,
          rating: 3,
          salesRank: 14579
        },
        {
          date: '2018-11-30',
          reviewCount: 46,
          rating: 3,
          salesRank: 18095
        },
        {
          date: '2018-12-01',
          reviewCount: 46,
          rating: 3,
          salesRank: 11524
        },
        {
          date: '2018-12-02',
          reviewCount: 46,
          rating: 3,
          salesRank: 15024
        },
        {
          date: '2018-12-03',
          reviewCount: 46,
          rating: 3,
          salesRank: 9392
        },
        {
          date: '2018-12-04',
          reviewCount: 46,
          rating: 3,
          salesRank: 9624
        },
        {
          date: '2018-12-05',
          reviewCount: 46,
          rating: 3,
          salesRank: 12267
        },
        {
          date: '2018-12-06',
          reviewCount: 46,
          rating: 3,
          salesRank: 15861
        },
        {
          date: '2018-12-07',
          reviewCount: 46,
          rating: 3,
          salesRank: 12313
        },
        {
          date: '2018-12-08',
          reviewCount: 46,
          rating: 3,
          salesRank: 6336
        },
        {
          date: '2018-12-09',
          reviewCount: 46,
          rating: 3,
          salesRank: 5471
        },
        {
          date: '2018-12-10',
          reviewCount: 46,
          rating: 3,
          salesRank: 4323
        },
        {
          date: '2018-12-11',
          reviewCount: 46,
          rating: 3,
          salesRank: 4305
        },
        {
          date: '2018-12-12',
          reviewCount: 46,
          rating: 3,
          salesRank: 4778
        },
        {
          date: '2018-12-13',
          reviewCount: 46,
          rating: 3,
          salesRank: 4368
        },
        {
          date: '2018-12-14',
          reviewCount: 46,
          rating: 3,
          salesRank: 3310
        },
        {
          date: '2018-12-15',
          reviewCount: 46,
          rating: 3,
          salesRank: 3442
        },
        {
          date: '2018-12-16',
          reviewCount: 46,
          rating: 3,
          salesRank: 2878
        },
        {
          date: '2018-12-17',
          reviewCount: 46,
          rating: 3,
          salesRank: 2468
        },
        {
          date: '2018-12-18',
          reviewCount: 46,
          rating: 3,
          salesRank: 2208
        },
        {
          date: '2018-12-19',
          reviewCount: 52,
          rating: 3,
          salesRank: 1907
        },
        {
          date: '2018-12-20',
          reviewCount: 52,
          rating: 3,
          salesRank: 2237
        },
        {
          date: '2018-12-21',
          reviewCount: 52,
          rating: 3,
          salesRank: 1994
        },
        {
          date: '2018-12-22',
          reviewCount: 52,
          rating: 3,
          salesRank: 2556
        },
        {
          date: '2018-12-23',
          reviewCount: 52,
          rating: 3,
          salesRank: 2410
        },
        {
          date: '2018-12-24',
          reviewCount: 52,
          rating: 3,
          salesRank: 2611
        },
        {
          date: '2018-12-25',
          reviewCount: 57,
          rating: 3,
          salesRank: 2314
        },
        {
          date: '2018-12-26',
          reviewCount: 57,
          rating: 3,
          salesRank: 2463
        },
        {
          date: '2018-12-27',
          reviewCount: 57,
          rating: 3,
          salesRank: 2091
        },
        {
          date: '2018-12-28',
          reviewCount: 57,
          rating: 3,
          salesRank: 2053
        },
        {
          date: '2018-12-29',
          reviewCount: 57,
          rating: 3,
          salesRank: 2440
        },
        {
          date: '2018-12-30',
          reviewCount: 57,
          rating: 3,
          salesRank: 2298
        },
        {
          date: '2018-12-31',
          reviewCount: 61,
          rating: 4,
          salesRank: 2402
        },
        {
          date: '2019-01-01',
          reviewCount: 61,
          rating: 4,
          salesRank: 4420
        },
        {
          date: '2019-01-02',
          reviewCount: 61,
          rating: 4,
          salesRank: 6188
        },
        {
          date: '2019-01-03',
          reviewCount: 61,
          rating: 4,
          salesRank: 7210
        },
        {
          date: '2019-01-04',
          reviewCount: 61,
          rating: 4,
          salesRank: 9965
        },
        {
          date: '2019-01-05',
          reviewCount: 61,
          rating: 4,
          salesRank: 10083
        },
        {
          date: '2019-01-06',
          reviewCount: 61,
          rating: 4,
          salesRank: 11017
        },
        {
          date: '2019-01-07',
          reviewCount: 61,
          rating: 4,
          salesRank: 7489
        },
        {
          date: '2019-01-08',
          reviewCount: 61,
          rating: 4,
          salesRank: 7510
        },
        {
          date: '2019-01-09',
          reviewCount: 61,
          rating: 4
        },
        {
          date: '2019-01-10',
          reviewCount: 61,
          rating: 4,
          salesRank: 9105
        },
        {
          date: '2019-01-11',
          reviewCount: 61,
          rating: 4,
          salesRank: 9151
        },
        {
          date: '2019-01-12',
          reviewCount: 61,
          rating: 4,
          salesRank: 8260
        },
        {
          date: '2019-01-13',
          reviewCount: 61,
          rating: 4,
          salesRank: 9976
        },
        {
          date: '2019-01-14',
          reviewCount: 61,
          rating: 4,
          salesRank: 8851
        },
        {
          date: '2019-01-15',
          reviewCount: 61,
          rating: 4,
          salesRank: 8971
        },
        {
          date: '2019-01-16',
          reviewCount: 61,
          rating: 4,
          salesRank: 6473
        },
        {
          date: '2019-01-17',
          reviewCount: 61,
          rating: 4,
          salesRank: 7244
        },
        {
          date: '2019-01-18',
          reviewCount: 61,
          rating: 4,
          salesRank: 6764
        },
        {
          date: '2019-01-19',
          reviewCount: 61,
          rating: 4,
          salesRank: 6495
        },
        {
          date: '2019-01-20',
          reviewCount: 61,
          rating: 4,
          salesRank: 6717
        },
        {
          date: '2019-01-21',
          reviewCount: 61,
          rating: 4,
          salesRank: 6878
        },
        {
          date: '2019-01-22',
          reviewCount: 61,
          rating: 4,
          salesRank: 7784
        },
        {
          date: '2019-01-23',
          reviewCount: 61,
          rating: 4,
          salesRank: 7445
        },
        {
          date: '2019-01-24',
          reviewCount: 61,
          rating: 4,
          salesRank: 5940
        },
        {
          date: '2019-01-25',
          reviewCount: 61,
          rating: 4,
          salesRank: 10176
        },
        {
          date: '2019-01-26',
          reviewCount: 61,
          rating: 4,
          salesRank: 11876
        },
        {
          date: '2019-01-27',
          reviewCount: 61,
          rating: 4,
          salesRank: 9302
        },
        {
          date: '2019-01-28',
          reviewCount: 61,
          rating: 4,
          salesRank: 7362
        },
        {
          date: '2019-01-29',
          reviewCount: 61,
          rating: 4,
          salesRank: 7885
        },
        {
          date: '2019-01-30',
          reviewCount: 76,
          rating: 3,
          salesRank: 10536
        },
        {
          date: '2019-01-31',
          reviewCount: 76,
          rating: 3,
          salesRank: 11020
        },
        {
          date: '2019-02-01',
          reviewCount: 76,
          rating: 3,
          salesRank: 8809
        },
        {
          date: '2019-02-02',
          reviewCount: 76,
          rating: 3,
          salesRank: 7001
        },
        {
          date: '2019-02-03',
          reviewCount: 76,
          rating: 3,
          salesRank: 9400
        },
        {
          date: '2019-02-04',
          reviewCount: 76,
          rating: 3,
          salesRank: 6650
        },
        {
          date: '2019-02-05',
          reviewCount: 78,
          rating: 3,
          salesRank: 8569
        },
        {
          date: '2019-02-06',
          reviewCount: 78,
          rating: 3,
          salesRank: 8548
        },
        {
          date: '2019-02-07',
          reviewCount: 78,
          rating: 3,
          salesRank: 9039
        },
        {
          date: '2019-02-08',
          reviewCount: 78,
          rating: 3,
          salesRank: 9179
        },
        {
          date: '2019-02-09',
          reviewCount: 78,
          rating: 3,
          salesRank: 10494
        },
        {
          date: '2019-02-10',
          reviewCount: 78,
          rating: 3,
          salesRank: 7931
        },
        {
          date: '2019-02-11',
          reviewCount: 78,
          rating: 3,
          salesRank: 10858
        },
        {
          date: '2019-02-12',
          reviewCount: 78,
          rating: 3,
          salesRank: 8329
        },
        {
          date: '2019-02-13',
          reviewCount: 78,
          rating: 3,
          salesRank: 10189
        },
        {
          date: '2019-02-14',
          reviewCount: 78,
          rating: 3,
          salesRank: 9360
        },
        {
          date: '2019-02-15',
          reviewCount: 78,
          rating: 3,
          salesRank: 10051
        },
        {
          date: '2019-02-16',
          reviewCount: 78,
          rating: 3,
          salesRank: 7977
        },
        {
          date: '2019-02-17',
          reviewCount: 81,
          rating: 3,
          salesRank: 8252
        },
        {
          date: '2019-02-18',
          reviewCount: 81,
          rating: 3,
          salesRank: 10265
        },
        {
          date: '2019-02-19',
          reviewCount: 81,
          rating: 3,
          salesRank: 9343
        },
        {
          date: '2019-02-20',
          reviewCount: 81,
          rating: 3,
          salesRank: 8199
        },
        {
          date: '2019-02-21',
          reviewCount: 81,
          rating: 3,
          salesRank: 9233
        },
        {
          date: '2019-02-22',
          reviewCount: 81,
          rating: 3,
          salesRank: 6314
        },
        {
          date: '2019-02-23',
          reviewCount: 81,
          rating: 3,
          salesRank: 9031
        },
        {
          date: '2019-02-24',
          reviewCount: 81,
          rating: 3,
          salesRank: 8706
        },
        {
          date: '2019-02-25',
          reviewCount: 81,
          rating: 3,
          salesRank: 8052
        },
        {
          date: '2019-02-26',
          reviewCount: 81,
          rating: 3,
          salesRank: 7620
        },
        {
          date: '2019-02-27',
          reviewCount: 81,
          rating: 3,
          salesRank: 9599
        },
        {
          date: '2019-02-28',
          reviewCount: 81,
          rating: 3,
          salesRank: 11191
        },
        {
          date: '2019-03-01',
          reviewCount: 86,
          rating: 3,
          salesRank: 11179
        },
        {
          date: '2019-03-02',
          reviewCount: 86,
          rating: 3,
          salesRank: 7119
        },
        {
          date: '2019-03-03',
          reviewCount: 86,
          rating: 3,
          salesRank: 7845
        },
        {
          date: '2019-03-04',
          reviewCount: 86,
          rating: 3,
          salesRank: 10669
        },
        {
          date: '2019-03-05',
          reviewCount: 86,
          rating: 3,
          salesRank: 8950
        },
        {
          date: '2019-03-06',
          reviewCount: 86,
          rating: 3,
          salesRank: 7853
        },
        {
          date: '2019-03-07',
          reviewCount: 89,
          rating: 3,
          salesRank: 7523
        },
        {
          date: '2019-03-08',
          reviewCount: 89,
          rating: 3,
          salesRank: 6571
        },
        {
          date: '2019-03-09',
          reviewCount: 89,
          rating: 3,
          salesRank: 7424
        },
        {
          date: '2019-03-10',
          reviewCount: 89,
          rating: 3,
          salesRank: 7923
        },
        {
          date: '2019-03-11',
          reviewCount: 89,
          rating: 3,
          salesRank: 6943
        },
        {
          date: '2019-03-12',
          reviewCount: 89,
          rating: 3,
          salesRank: 7773
        },
        {
          date: '2019-03-13',
          reviewCount: 93,
          rating: 3,
          salesRank: 11725
        },
        {
          date: '2019-03-14',
          reviewCount: 93,
          rating: 3,
          salesRank: 8486
        },
        {
          date: '2019-03-15',
          reviewCount: 93,
          rating: 3,
          salesRank: 6243
        },
        {
          date: '2019-03-16',
          reviewCount: 93,
          rating: 3,
          salesRank: 9230
        },
        {
          date: '2019-03-17',
          reviewCount: 93,
          rating: 3,
          salesRank: 8583
        },
        {
          date: '2019-03-18',
          reviewCount: 93,
          rating: 3,
          salesRank: 7542
        },
        {
          date: '2019-03-19',
          reviewCount: 93,
          rating: 3,
          salesRank: 7281
        },
        {
          date: '2019-03-20',
          reviewCount: 93,
          rating: 3,
          salesRank: 6705
        },
        {
          date: '2019-03-21',
          reviewCount: 93,
          rating: 3,
          salesRank: 9635
        },
        {
          date: '2019-03-22',
          reviewCount: 93,
          rating: 3,
          salesRank: 10204
        },
        {
          date: '2019-03-23',
          reviewCount: 93,
          rating: 3,
          salesRank: 9687
        },
        {
          date: '2019-03-24',
          reviewCount: 93,
          rating: 3,
          salesRank: 9775
        },
        {
          date: '2019-03-25',
          reviewCount: 93,
          rating: 3,
          salesRank: 9627
        },
        {
          date: '2019-03-26',
          reviewCount: 93,
          rating: 3,
          salesRank: 9381
        },
        {
          date: '2019-03-27',
          reviewCount: 93,
          rating: 3,
          salesRank: 7261
        },
        {
          date: '2019-03-28',
          reviewCount: 96,
          rating: 4,
          salesRank: 9795
        },
        {
          date: '2019-03-29',
          reviewCount: 96,
          rating: 4,
          salesRank: 9879
        },
        {
          date: '2019-03-30',
          reviewCount: 96,
          rating: 4,
          salesRank: 6147
        },
        {
          date: '2019-03-31',
          reviewCount: 96,
          rating: 4,
          salesRank: 9095
        },
        {
          date: '2019-04-01',
          reviewCount: 96,
          rating: 4,
          salesRank: 6608
        },
        {
          date: '2019-04-02',
          reviewCount: 96,
          rating: 4,
          salesRank: 7230
        },
        {
          date: '2019-04-03',
          reviewCount: 96,
          rating: 4,
          salesRank: 9385
        },
        {
          date: '2019-04-04',
          reviewCount: 96,
          rating: 4,
          salesRank: 8597
        },
        {
          date: '2019-04-05',
          reviewCount: 96,
          rating: 4,
          salesRank: 8586
        },
        {
          date: '2019-04-06',
          reviewCount: 96,
          rating: 4,
          salesRank: 6845
        },
        {
          date: '2019-04-07',
          reviewCount: 96,
          rating: 4,
          salesRank: 10111
        },
        {
          date: '2019-04-08',
          reviewCount: 96,
          rating: 4,
          salesRank: 7462
        },
        {
          date: '2019-04-09',
          reviewCount: 96,
          rating: 4,
          salesRank: 10197
        },
        {
          date: '2019-04-10',
          reviewCount: 96,
          rating: 4,
          salesRank: 11738
        },
        {
          date: '2019-04-11',
          reviewCount: 96,
          rating: 4,
          salesRank: 6074
        },
        {
          date: '2019-04-12',
          reviewCount: 96,
          rating: 4,
          salesRank: 6729
        },
        {
          date: '2019-04-13',
          reviewCount: 96,
          rating: 4,
          salesRank: 7502
        },
        {
          date: '2019-04-14',
          reviewCount: 96,
          rating: 4,
          salesRank: 8701
        },
        {
          date: '2019-04-15',
          reviewCount: 96,
          rating: 4,
          salesRank: 10103
        },
        {
          date: '2019-04-16',
          reviewCount: 96,
          rating: 4,
          salesRank: 8019
        },
        {
          date: '2019-04-17',
          reviewCount: 106,
          rating: 4,
          salesRank: 7443
        },
        {
          date: '2019-04-18',
          reviewCount: 106,
          rating: 4,
          salesRank: 8557
        },
        {
          date: '2019-04-19',
          reviewCount: 106,
          rating: 4,
          salesRank: 6817
        },
        {
          date: '2019-04-20',
          reviewCount: 106,
          rating: 4,
          salesRank: 11667
        },
        {
          date: '2019-04-21',
          reviewCount: 106,
          rating: 4,
          salesRank: 10732
        },
        {
          date: '2019-04-22',
          reviewCount: 106,
          rating: 4,
          salesRank: 9414
        },
        {
          date: '2019-04-23',
          reviewCount: 106,
          rating: 4,
          salesRank: 13307
        },
        {
          date: '2019-04-24',
          reviewCount: 107,
          rating: 4,
          salesRank: 7982
        },
        {
          date: '2019-04-25',
          reviewCount: 107,
          rating: 4,
          salesRank: 8092
        },
        {
          date: '2019-04-26',
          reviewCount: 107,
          rating: 4,
          salesRank: 9636
        },
        {
          date: '2019-04-27',
          reviewCount: 107,
          rating: 4,
          salesRank: 13811
        },
        {
          date: '2019-04-28',
          reviewCount: 107,
          rating: 4,
          salesRank: 9436
        },
        {
          date: '2019-04-29',
          reviewCount: 107,
          rating: 4,
          salesRank: 8405
        },
        {
          date: '2019-04-30',
          reviewCount: 107,
          rating: 4,
          salesRank: 10304
        },
        {
          date: '2019-05-01',
          reviewCount: 111,
          rating: 4,
          salesRank: 10101
        },
        {
          date: '2019-05-02',
          reviewCount: 111,
          rating: 4,
          salesRank: 9094
        },
        {
          date: '2019-05-03',
          reviewCount: 111,
          rating: 4,
          salesRank: 8637
        },
        {
          date: '2019-05-04',
          reviewCount: 111,
          rating: 4,
          salesRank: 6744
        },
        {
          date: '2019-05-05',
          reviewCount: 111,
          rating: 4,
          salesRank: 6162
        },
        {
          date: '2019-05-06',
          reviewCount: 111,
          rating: 4,
          salesRank: 7845
        },
        {
          date: '2019-05-07',
          reviewCount: 111,
          rating: 4,
          salesRank: 7364
        },
        {
          date: '2019-05-08',
          reviewCount: 111,
          rating: 4,
          salesRank: 10221
        },
        {
          date: '2019-05-09',
          reviewCount: 114,
          rating: 4,
          salesRank: 6841
        },
        {
          date: '2019-05-10',
          reviewCount: 114,
          rating: 4,
          salesRank: 9868
        },
        {
          date: '2019-05-11',
          reviewCount: 114,
          rating: 4,
          salesRank: 9967
        },
        {
          date: '2019-05-12',
          reviewCount: 114,
          rating: 4,
          salesRank: 8508
        },
        {
          date: '2019-05-13',
          reviewCount: 114,
          rating: 4,
          salesRank: 11222
        },
        {
          date: '2019-05-14',
          reviewCount: 114,
          rating: 4,
          salesRank: 10003
        },
        {
          date: '2019-05-15',
          reviewCount: 114,
          rating: 4,
          salesRank: 8415
        },
        {
          date: '2019-05-16',
          reviewCount: 114,
          rating: 4,
          salesRank: 8442
        },
        {
          date: '2019-05-17',
          reviewCount: 114,
          rating: 4,
          salesRank: 8726
        },
        {
          date: '2019-05-18',
          reviewCount: 117,
          rating: 4,
          salesRank: 9206
        },
        {
          date: '2019-05-19',
          reviewCount: 117,
          rating: 4,
          salesRank: 9426
        },
        {
          date: '2019-05-20',
          reviewCount: 117,
          rating: 4,
          salesRank: 5946
        },
        {
          date: '2019-05-21',
          reviewCount: 117,
          rating: 4,
          salesRank: 6222
        },
        {
          date: '2019-05-22',
          reviewCount: 117,
          rating: 4,
          salesRank: 6569
        },
        {
          date: '2019-05-23',
          reviewCount: 117,
          rating: 4,
          salesRank: 7712
        },
        {
          date: '2019-05-24',
          reviewCount: 117,
          rating: 4,
          salesRank: 9731
        },
        {
          date: '2019-05-25',
          reviewCount: 118,
          rating: 4,
          salesRank: 6287
        },
        {
          date: '2019-05-26',
          reviewCount: 118,
          rating: 4,
          salesRank: 7135
        },
        {
          date: '2019-05-27',
          reviewCount: 118,
          rating: 4,
          salesRank: 5317
        },
        {
          date: '2019-05-28',
          reviewCount: 118,
          rating: 4,
          salesRank: 6239
        },
        {
          date: '2019-05-29',
          reviewCount: 118,
          rating: 4,
          salesRank: 7651
        },
        {
          date: '2019-05-30',
          reviewCount: 118,
          rating: 4,
          salesRank: 11634
        },
        {
          date: '2019-05-31',
          reviewCount: 118,
          rating: 4,
          salesRank: 8542
        },
        {
          date: '2019-06-01',
          reviewCount: 121,
          rating: 4,
          salesRank: 11814
        },
        {
          date: '2019-06-02',
          reviewCount: 121,
          rating: 4,
          salesRank: 11676
        },
        {
          date: '2019-06-03',
          reviewCount: 121,
          rating: 4,
          salesRank: 7195
        },
        {
          date: '2019-06-04',
          reviewCount: 121,
          rating: 4,
          salesRank: 8609
        },
        {
          date: '2019-06-05',
          reviewCount: 121,
          rating: 4,
          salesRank: 9516
        },
        {
          date: '2019-06-06',
          reviewCount: 121,
          rating: 4,
          salesRank: 8117
        },
        {
          date: '2019-06-07',
          reviewCount: 121,
          rating: 4,
          salesRank: 10432
        },
        {
          date: '2019-06-08',
          reviewCount: 123,
          rating: 4,
          salesRank: 8740
        },
        {
          date: '2019-06-09',
          reviewCount: 123,
          rating: 4,
          salesRank: 13906
        },
        {
          date: '2019-06-10',
          reviewCount: 123,
          rating: 4,
          salesRank: 11317
        },
        {
          date: '2019-06-11',
          reviewCount: 123,
          rating: 4,
          salesRank: 8691
        },
        {
          date: '2019-06-12',
          reviewCount: 123,
          rating: 4,
          salesRank: 9422
        },
        {
          date: '2019-06-13',
          reviewCount: 123,
          rating: 4,
          salesRank: 10872
        },
        {
          date: '2019-06-14',
          reviewCount: 123,
          rating: 4,
          salesRank: 12517
        },
        {
          date: '2019-06-15',
          reviewCount: 123,
          rating: 4,
          salesRank: 11435
        },
        {
          date: '2019-06-16',
          reviewCount: 123,
          rating: 4,
          salesRank: 10094
        },
        {
          date: '2019-06-17',
          reviewCount: 123,
          rating: 4,
          salesRank: 10076
        },
        {
          date: '2019-06-18',
          reviewCount: 123,
          rating: 4,
          salesRank: 10755
        },
        {
          date: '2019-06-19',
          reviewCount: 123,
          rating: 4,
          salesRank: 8765
        },
        {
          date: '2019-06-20',
          reviewCount: 123,
          rating: 4,
          salesRank: 7321
        },
        {
          date: '2019-06-21',
          reviewCount: 123,
          rating: 4,
          salesRank: 11619
        },
        {
          date: '2019-06-22',
          reviewCount: 123,
          rating: 4,
          salesRank: 9755
        },
        {
          date: '2019-06-23',
          reviewCount: 123,
          rating: 4,
          salesRank: 8170
        },
        {
          date: '2019-06-24',
          reviewCount: 123,
          rating: 4,
          salesRank: 12745
        },
        {
          date: '2019-06-25',
          reviewCount: 123,
          rating: 4,
          salesRank: 14243
        },
        {
          date: '2019-06-26',
          reviewCount: 123,
          rating: 4,
          salesRank: 13554
        },
        {
          date: '2019-06-27',
          reviewCount: 123,
          rating: 4,
          salesRank: 10273
        },
        {
          date: '2019-06-28',
          reviewCount: 123,
          rating: 4,
          salesRank: 12107
        },
        {
          date: '2019-06-29',
          reviewCount: 135,
          rating: 4,
          salesRank: 9944
        },
        {
          date: '2019-06-30',
          reviewCount: 135,
          rating: 4,
          salesRank: 10818
        },
        {
          date: '2019-07-01',
          reviewCount: 135,
          rating: 4,
          salesRank: 9999
        },
        {
          date: '2019-07-02',
          reviewCount: 135,
          rating: 4,
          salesRank: 8814
        },
        {
          date: '2019-07-03',
          reviewCount: 135,
          rating: 4,
          salesRank: 6593
        },
        {
          date: '2019-07-04',
          reviewCount: 135,
          rating: 4,
          salesRank: 5946
        },
        {
          date: '2019-07-05',
          reviewCount: 135,
          rating: 4,
          salesRank: 5076
        },
        {
          date: '2019-07-06',
          reviewCount: 137,
          rating: 4,
          salesRank: 4761
        },
        {
          date: '2019-07-07',
          reviewCount: 137,
          rating: 4,
          salesRank: 6606
        },
        {
          date: '2019-07-08',
          reviewCount: 137,
          rating: 4,
          salesRank: 5119
        },
        {
          date: '2019-07-09',
          reviewCount: 137,
          rating: 4,
          salesRank: 5040
        },
        {
          date: '2019-07-10',
          reviewCount: 137,
          rating: 4,
          salesRank: 4155
        },
        {
          date: '2019-07-11',
          reviewCount: 137,
          rating: 4,
          salesRank: 3680
        },
        {
          date: '2019-07-12',
          reviewCount: 137,
          rating: 4,
          salesRank: 3537
        },
        {
          date: '2019-07-13',
          reviewCount: 137,
          rating: 4,
          salesRank: 3464
        },
        {
          date: '2019-07-14',
          reviewCount: 137,
          rating: 4,
          salesRank: 3073
        },
        {
          date: '2019-07-15',
          reviewCount: 137,
          rating: 4,
          salesRank: 6072
        },
        {
          date: '2019-07-16',
          reviewCount: 137,
          rating: 4,
          salesRank: 4938
        },
        {
          date: '2019-07-17',
          reviewCount: 141,
          rating: 4,
          salesRank: 5317
        },
        {
          date: '2019-07-18',
          reviewCount: 141,
          rating: 4,
          salesRank: 6217
        },
        {
          date: '2019-07-19',
          reviewCount: 141,
          rating: 4,
          salesRank: 4938
        },
        {
          date: '2019-07-20',
          reviewCount: 141,
          rating: 4,
          salesRank: 3791
        },
        {
          date: '2019-07-21',
          reviewCount: 141,
          rating: 4,
          salesRank: 3825
        },
        {
          date: '2019-07-22',
          reviewCount: 141,
          rating: 4,
          salesRank: 3308
        },
        {
          date: '2019-07-23',
          reviewCount: 141,
          rating: 4,
          salesRank: 3404
        },
        {
          date: '2019-07-24',
          reviewCount: 141,
          rating: 4,
          salesRank: 4363
        },
        {
          date: '2019-07-25',
          reviewCount: 141,
          rating: 4,
          salesRank: 4610
        },
        {
          date: '2019-07-26',
          reviewCount: 148,
          rating: 4,
          salesRank: 6338
        },
        {
          date: '2019-07-27',
          reviewCount: 148,
          rating: 4,
          salesRank: 4731
        },
        {
          date: '2019-07-28',
          reviewCount: 148,
          rating: 4,
          salesRank: 3195
        },
        {
          date: '2019-07-29',
          reviewCount: 148,
          rating: 4,
          salesRank: 2656
        },
        {
          date: '2019-07-30',
          reviewCount: 148,
          rating: 4,
          salesRank: 2414
        },
        {
          date: '2019-07-31',
          reviewCount: 148,
          rating: 4,
          salesRank: 2476
        },
        {
          date: '2019-08-01',
          reviewCount: 148,
          rating: 4,
          salesRank: 1980
        },
        {
          date: '2019-08-02',
          reviewCount: 153,
          rating: 4,
          salesRank: 2320
        },
        {
          date: '2019-08-03',
          reviewCount: 153,
          rating: 4,
          salesRank: 1524
        },
        {
          date: '2019-08-04',
          reviewCount: 153,
          rating: 4,
          salesRank: 1856
        },
        {
          date: '2019-08-05',
          reviewCount: 153,
          rating: 4,
          salesRank: 1243
        },
        {
          date: '2019-08-06',
          reviewCount: 153,
          rating: 4,
          salesRank: 1381
        },
        {
          date: '2019-08-07',
          reviewCount: 153,
          rating: 4,
          salesRank: 1204
        },
        {
          date: '2019-08-08',
          reviewCount: 153,
          rating: 4,
          salesRank: 1302
        },
        {
          date: '2019-08-09',
          reviewCount: 154,
          rating: 4,
          salesRank: 1406
        },
        {
          date: '2019-08-10',
          reviewCount: 154,
          rating: 4,
          salesRank: 1330
        },
        {
          date: '2019-08-11',
          reviewCount: 154,
          rating: 4,
          salesRank: 1806
        },
        {
          date: '2019-08-12',
          reviewCount: 154,
          rating: 4,
          salesRank: 1869
        },
        {
          date: '2019-08-13',
          reviewCount: 154,
          rating: 4,
          salesRank: 1814
        },
        {
          date: '2019-08-14',
          reviewCount: 154,
          rating: 4,
          salesRank: 2021
        },
        {
          date: '2019-08-15',
          reviewCount: 154,
          rating: 4,
          salesRank: 2146
        },
        {
          date: '2019-08-16',
          reviewCount: 154,
          rating: 4,
          salesRank: 2500
        },
        {
          date: '2019-08-17',
          reviewCount: 161,
          rating: 4,
          salesRank: 2029
        },
        {
          date: '2019-08-18',
          reviewCount: 161,
          rating: 4,
          salesRank: 3731
        },
        {
          date: '2019-08-19',
          reviewCount: 161,
          rating: 4,
          salesRank: 4231
        },
        {
          date: '2019-08-20',
          reviewCount: 161,
          rating: 4,
          salesRank: 2834
        },
        {
          date: '2019-08-21',
          reviewCount: 161,
          rating: 4,
          salesRank: 4263
        },
        {
          date: '2019-08-22',
          reviewCount: 161,
          rating: 4,
          salesRank: 3679
        },
        {
          date: '2019-08-23',
          reviewCount: 161,
          rating: 4,
          salesRank: 4408
        },
        {
          date: '2019-08-24',
          reviewCount: 161,
          rating: 4,
          salesRank: 5011
        },
        {
          date: '2019-08-25',
          reviewCount: 167,
          rating: 4,
          salesRank: 4566
        },
        {
          date: '2019-08-26',
          reviewCount: 167,
          rating: 4,
          salesRank: 3628
        },
        {
          date: '2019-08-27',
          reviewCount: 167,
          rating: 4,
          salesRank: 3416
        },
        {
          date: '2019-08-28',
          reviewCount: 167,
          rating: 4,
          salesRank: 4403
        },
        {
          date: '2019-08-29',
          reviewCount: 167,
          rating: 4,
          salesRank: 3741
        },
        {
          date: '2019-08-30',
          reviewCount: 167,
          rating: 4,
          salesRank: 5110
        },
        {
          date: '2019-08-31',
          reviewCount: 173,
          rating: 4,
          salesRank: 4314
        },
        {
          date: '2019-09-01',
          reviewCount: 173,
          rating: 4,
          salesRank: 5489
        },
        {
          date: '2019-09-02',
          reviewCount: 173,
          rating: 4,
          salesRank: 4281
        },
        {
          date: '2019-09-03',
          reviewCount: 173,
          rating: 4,
          salesRank: 4204
        },
        {
          date: '2019-09-04',
          reviewCount: 173,
          rating: 4,
          salesRank: 5542
        },
        {
          date: '2019-09-05',
          reviewCount: 173,
          rating: 4,
          salesRank: 4685
        },
        {
          date: '2019-09-06',
          reviewCount: 173,
          rating: 4,
          salesRank: 5124
        },
        {
          date: '2019-09-07',
          reviewCount: 177,
          rating: 4,
          salesRank: 6971
        },
        {
          date: '2019-09-08',
          reviewCount: 177,
          rating: 4,
          salesRank: 6291
        },
        {
          date: '2019-09-09',
          reviewCount: 177,
          rating: 4,
          salesRank: 5300
        },
        {
          date: '2019-09-10',
          reviewCount: 177,
          rating: 4,
          salesRank: 5070
        },
        {
          date: '2019-09-11',
          reviewCount: 177,
          rating: 4,
          salesRank: 6852
        },
        {
          date: '2019-09-12',
          reviewCount: 177,
          rating: 4,
          salesRank: 5591
        },
        {
          date: '2019-09-13',
          reviewCount: 177,
          rating: 4,
          salesRank: 6089
        },
        {
          date: '2019-09-14',
          reviewCount: 183,
          rating: 4,
          salesRank: 8744
        },
        {
          date: '2019-09-15',
          reviewCount: 183,
          rating: 4,
          salesRank: 8771
        },
        {
          date: '2019-09-16',
          reviewCount: 183,
          rating: 4,
          salesRank: 9675
        },
        {
          date: '2019-09-17',
          reviewCount: 183,
          rating: 4,
          salesRank: 10750
        },
        {
          date: '2019-09-18',
          reviewCount: 183,
          rating: 4,
          salesRank: 9990
        },
        {
          date: '2019-09-19',
          reviewCount: 183,
          rating: 4,
          salesRank: 9896
        },
        {
          date: '2019-09-20',
          reviewCount: 183,
          rating: 4,
          salesRank: 8013
        },
        {
          date: '2019-09-21',
          reviewCount: 183,
          rating: 4,
          salesRank: 9650
        },
        {
          date: '2019-09-22',
          reviewCount: 184,
          rating: 4,
          salesRank: 8734
        },
        {
          date: '2019-09-23',
          reviewCount: 184,
          rating: 4,
          salesRank: 11071
        },
        {
          date: '2019-09-24',
          reviewCount: 184,
          rating: 4,
          salesRank: 9198
        },
        {
          date: '2019-09-25',
          reviewCount: 184,
          rating: 4,
          salesRank: 10764
        },
        {
          date: '2019-09-26',
          reviewCount: 184,
          rating: 4,
          salesRank: 10836
        },
        {
          date: '2019-09-27',
          reviewCount: 184,
          rating: 4,
          salesRank: 8588
        },
        {
          date: '2019-09-28',
          reviewCount: 184,
          rating: 4,
          salesRank: 13639
        },
        {
          date: '2019-09-29',
          reviewCount: 184,
          rating: 4,
          salesRank: 7769
        },
        {
          date: '2019-09-30',
          reviewCount: 184,
          rating: 4,
          salesRank: 7412
        },
        {
          date: '2019-10-01',
          reviewCount: 184,
          rating: 4,
          salesRank: 7724
        },
        {
          date: '2019-10-02',
          reviewCount: 184,
          rating: 4,
          salesRank: 7969
        },
        {
          date: '2019-10-03',
          reviewCount: 184,
          rating: 4,
          salesRank: 10541
        },
        {
          date: '2019-10-04',
          reviewCount: 184,
          rating: 4,
          salesRank: 10376
        },
        {
          date: '2019-10-05',
          reviewCount: 184,
          rating: 4,
          salesRank: 11064
        },
        {
          date: '2019-10-06',
          reviewCount: 184,
          rating: 4,
          salesRank: 11331
        },
        {
          date: '2019-10-07',
          reviewCount: 184,
          rating: 4,
          salesRank: 11077
        },
        {
          date: '2019-10-08',
          reviewCount: 184,
          rating: 4,
          salesRank: 12547
        },
        {
          date: '2019-10-09',
          reviewCount: 184,
          rating: 4,
          salesRank: 9644
        },
        {
          date: '2019-10-10',
          reviewCount: 184,
          rating: 4,
          salesRank: 9952
        },
        {
          date: '2019-10-11',
          reviewCount: 184,
          rating: 4,
          salesRank: 10989
        },
        {
          date: '2019-10-12',
          reviewCount: 184,
          rating: 4,
          salesRank: 8610
        },
        {
          date: '2019-10-13',
          reviewCount: 184,
          rating: 4,
          salesRank: 10970
        },
        {
          date: '2019-10-14',
          reviewCount: 184,
          rating: 4,
          salesRank: 11832
        },
        {
          date: '2019-10-15',
          reviewCount: 184,
          rating: 4,
          salesRank: 11746
        },
        {
          date: '2019-10-16',
          reviewCount: 184,
          rating: 4,
          salesRank: 15031
        },
        {
          date: '2019-10-17',
          reviewCount: 184,
          rating: 4,
          salesRank: 13518
        },
        {
          date: '2019-10-18',
          reviewCount: 184,
          rating: 4,
          salesRank: 15052
        },
        {
          date: '2019-10-19',
          reviewCount: 184,
          rating: 4,
          salesRank: 13564
        },
        {
          date: '2019-10-20',
          reviewCount: 184,
          rating: 4,
          salesRank: 8757
        },
        {
          date: '2019-10-21',
          reviewCount: 184,
          rating: 4,
          salesRank: 11813
        },
        {
          date: '2019-10-22',
          reviewCount: 184,
          rating: 4,
          salesRank: 10079
        },
        {
          date: '2019-10-23',
          reviewCount: 184,
          rating: 4,
          salesRank: 12480
        },
        {
          date: '2019-10-24',
          reviewCount: 184,
          rating: 4,
          salesRank: 8384
        },
        {
          date: '2019-10-25',
          reviewCount: 184,
          rating: 4,
          salesRank: 12102
        },
        {
          date: '2019-10-26',
          reviewCount: 184,
          rating: 4,
          salesRank: 11944
        },
        {
          date: '2019-10-27',
          reviewCount: 184,
          rating: 4,
          salesRank: 9737
        },
        {
          date: '2019-10-28',
          reviewCount: 184,
          rating: 4,
          salesRank: 17012
        },
        {
          date: '2019-10-29',
          reviewCount: 184,
          rating: 4,
          salesRank: 11229
        },
        {
          date: '2019-10-30',
          reviewCount: 184,
          rating: 4,
          salesRank: 9668
        },
        {
          date: '2019-10-31',
          reviewCount: 184,
          rating: 4,
          salesRank: 10822
        },
        {
          date: '2019-11-01',
          reviewCount: 216,
          rating: 4,
          salesRank: 8812
        },
        {
          date: '2019-11-02',
          reviewCount: 216,
          rating: 4,
          salesRank: 8380
        },
        {
          date: '2019-11-03',
          reviewCount: 216,
          rating: 4,
          salesRank: 9631
        },
        {
          date: '2019-11-04',
          reviewCount: 217,
          rating: 4,
          salesRank: 11976
        },
        {
          date: '2019-11-05',
          reviewCount: 217,
          rating: 4,
          salesRank: 9986
        },
        {
          date: '2019-11-06',
          reviewCount: 217,
          rating: 4,
          salesRank: 7861
        },
        {
          date: '2019-11-07',
          reviewCount: 217,
          rating: 4,
          salesRank: 10361
        },
        {
          date: '2019-11-08',
          reviewCount: 219,
          rating: 4,
          salesRank: 15317
        },
        {
          date: '2019-11-09',
          reviewCount: 219,
          rating: 4,
          salesRank: 9662
        },
        {
          date: '2019-11-10',
          reviewCount: 219,
          rating: 4,
          salesRank: 12903
        },
        {
          date: '2019-11-11',
          reviewCount: 219,
          rating: 4,
          salesRank: 10520
        },
        {
          date: '2019-11-12',
          reviewCount: 219,
          rating: 4,
          salesRank: 13357
        },
        {
          date: '2019-11-13',
          reviewCount: 219,
          rating: 4,
          salesRank: 11932
        },
        {
          date: '2019-11-14',
          reviewCount: 219,
          rating: 4,
          salesRank: 8778
        },
        {
          date: '2019-11-15',
          reviewCount: 222,
          rating: 4,
          salesRank: 11712
        },
        {
          date: '2019-11-16',
          reviewCount: 222,
          rating: 4,
          salesRank: 8963
        },
        {
          date: '2019-11-17',
          reviewCount: 222,
          rating: 4,
          salesRank: 9572
        },
        {
          date: '2019-11-18',
          reviewCount: 222,
          rating: 4,
          salesRank: 9610
        },
        {
          date: '2019-11-19',
          reviewCount: 222,
          rating: 4,
          salesRank: 9701
        },
        {
          date: '2019-11-20',
          reviewCount: 222,
          rating: 4,
          salesRank: 9666
        },
        {
          date: '2019-11-21',
          reviewCount: 222,
          rating: 4,
          salesRank: 11133
        },
        {
          date: '2019-11-22',
          reviewCount: 222,
          rating: 4,
          salesRank: 11406
        },
        {
          date: '2019-11-23',
          reviewCount: 222,
          rating: 4,
          salesRank: 11313
        },
        {
          date: '2019-11-24',
          reviewCount: 222,
          rating: 4,
          salesRank: 4948
        },
        {
          date: '2019-11-25',
          reviewCount: 222,
          rating: 4,
          salesRank: 3026
        },
        {
          date: '2019-11-26',
          reviewCount: 222,
          rating: 4,
          salesRank: 4024
        },
        {
          date: '2019-11-27',
          reviewCount: 222,
          rating: 4,
          salesRank: 3205
        },
        {
          date: '2019-11-28',
          reviewCount: 222,
          rating: 4,
          salesRank: 3575
        },
        {
          date: '2019-11-29',
          reviewCount: 222,
          rating: 4,
          salesRank: 2584
        },
        {
          date: '2019-11-30',
          reviewCount: 236,
          rating: 4,
          salesRank: 1399
        },
        {
          date: '2019-12-01',
          reviewCount: 236,
          rating: 4,
          salesRank: 1904
        },
        {
          date: '2019-12-02',
          reviewCount: 236,
          rating: 4,
          salesRank: 1476
        },
        {
          date: '2019-12-03',
          reviewCount: 236,
          rating: 4,
          salesRank: 1621
        },
        {
          date: '2019-12-04',
          reviewCount: 236,
          rating: 4,
          salesRank: 1442
        },
        {
          date: '2019-12-05',
          reviewCount: 236,
          rating: 4,
          salesRank: 1975
        },
        {
          date: '2019-12-06',
          reviewCount: 236,
          rating: 4,
          salesRank: 1990
        },
        {
          date: '2019-12-07',
          reviewCount: 236,
          rating: 4,
          salesRank: 2566
        },
        {
          date: '2019-12-08',
          reviewCount: 236,
          rating: 4,
          salesRank: 3083
        },
        {
          date: '2019-12-09',
          reviewCount: 236,
          rating: 4,
          salesRank: 2833
        },
        {
          date: '2019-12-10',
          reviewCount: 236,
          rating: 4,
          salesRank: 3603
        },
        {
          date: '2019-12-11',
          reviewCount: 236,
          rating: 4,
          salesRank: 3702
        },
        {
          date: '2019-12-12',
          reviewCount: 254,
          rating: 4,
          salesRank: 4523
        },
        {
          date: '2019-12-13',
          reviewCount: 254,
          rating: 4,
          salesRank: 7523
        },
        {
          date: '2019-12-14',
          reviewCount: 254,
          rating: 4,
          salesRank: 8572
        },
        {
          date: '2019-12-15',
          reviewCount: 254,
          rating: 4,
          salesRank: 8402
        },
        {
          date: '2019-12-16',
          reviewCount: 254,
          rating: 4,
          salesRank: 6124
        },
        {
          date: '2019-12-17',
          reviewCount: 254,
          rating: 4,
          salesRank: 5479
        },
        {
          date: '2019-12-18',
          reviewCount: 254,
          rating: 4,
          salesRank: 4876
        },
        {
          date: '2019-12-19',
          reviewCount: 254,
          rating: 4,
          salesRank: 4145
        },
        {
          date: '2019-12-20',
          reviewCount: 307,
          rating: 4,
          salesRank: 4095
        },
        {
          date: '2019-12-21',
          reviewCount: 307,
          rating: 4,
          salesRank: 4130
        },
        {
          date: '2019-12-22',
          reviewCount: 307,
          rating: 4,
          salesRank: 4265
        },
        {
          date: '2019-12-23',
          reviewCount: 307,
          rating: 4,
          salesRank: 4858
        },
        {
          date: '2019-12-24',
          reviewCount: 307,
          rating: 4,
          salesRank: 5506
        },
        {
          date: '2019-12-25',
          reviewCount: 307,
          rating: 4,
          salesRank: 8395
        },
        {
          date: '2019-12-26',
          reviewCount: 307,
          rating: 4,
          salesRank: 8606
        },
        {
          date: '2019-12-27',
          reviewCount: 307,
          rating: 4,
          salesRank: 7961
        },
        {
          date: '2019-12-28',
          reviewCount: 337,
          rating: 4,
          salesRank: 7133
        },
        {
          date: '2019-12-29',
          reviewCount: 337,
          rating: 4,
          salesRank: 5375
        },
        {
          date: '2019-12-30',
          reviewCount: 337,
          rating: 4,
          salesRank: 7887
        },
        {
          date: '2019-12-31',
          reviewCount: 337,
          rating: 4,
          salesRank: 7386
        },
        {
          date: '2020-01-01',
          reviewCount: 337,
          rating: 4,
          salesRank: 10186
        },
        {
          date: '2020-01-02',
          reviewCount: 337,
          rating: 4,
          salesRank: 9904
        },
        {
          date: '2020-01-03',
          reviewCount: 337,
          rating: 4,
          salesRank: 8618
        },
        {
          date: '2020-01-04',
          reviewCount: 389,
          rating: 4,
          salesRank: 11511
        },
        {
          date: '2020-01-05',
          reviewCount: 389,
          rating: 4,
          salesRank: 9529
        },
        {
          date: '2020-01-06',
          reviewCount: 389,
          rating: 4,
          salesRank: 7273
        },
        {
          date: '2020-01-07',
          reviewCount: 389,
          rating: 4,
          salesRank: 7518
        },
        {
          date: '2020-01-08',
          reviewCount: 389,
          rating: 4,
          salesRank: 6542
        },
        {
          date: '2020-01-09',
          reviewCount: 389,
          rating: 4,
          salesRank: 8852
        },
        {
          date: '2020-01-10',
          reviewCount: 389,
          rating: 4,
          salesRank: 8456
        },
        {
          date: '2020-01-11',
          reviewCount: 389,
          rating: 4,
          salesRank: 5689
        },
        {
          date: '2020-01-12',
          reviewCount: 389,
          rating: 4,
          salesRank: 6344
        },
        {
          date: '2020-01-13',
          reviewCount: 440,
          rating: 4,
          salesRank: 5918
        },
        {
          date: '2020-01-14',
          reviewCount: 440,
          rating: 4,
          salesRank: 7745
        },
        {
          date: '2020-01-15',
          reviewCount: 440,
          rating: 4,
          salesRank: 5972
        },
        {
          date: '2020-01-16',
          reviewCount: 440,
          rating: 4,
          salesRank: 8138
        },
        {
          date: '2020-01-17',
          reviewCount: 440,
          rating: 4,
          salesRank: 7306
        },
        {
          date: '2020-01-18',
          reviewCount: 440,
          rating: 4,
          salesRank: 7291
        },
        {
          date: '2020-01-19',
          reviewCount: 440,
          rating: 4,
          salesRank: 8634
        },
        {
          date: '2020-01-20',
          reviewCount: 440,
          rating: 4,
          salesRank: 8159
        },
        {
          date: '2020-01-21',
          reviewCount: 440,
          rating: 4,
          salesRank: 4759
        },
        {
          date: '2020-01-22',
          reviewCount: 477,
          rating: 4,
          salesRank: 5441
        },
        {
          date: '2020-01-23',
          reviewCount: 477,
          rating: 4,
          salesRank: 5879
        },
        {
          date: '2020-01-24',
          reviewCount: 477,
          rating: 4,
          salesRank: 7376
        },
        {
          date: '2020-01-25',
          reviewCount: 477,
          rating: 4,
          salesRank: 10071
        },
        {
          date: '2020-01-26',
          reviewCount: 477,
          rating: 4,
          salesRank: 7121
        },
        {
          date: '2020-01-27',
          reviewCount: 477,
          rating: 4,
          salesRank: 5260
        },
        {
          date: '2020-01-28',
          reviewCount: 477,
          rating: 4,
          salesRank: 6552
        },
        {
          date: '2020-01-29',
          reviewCount: 477,
          rating: 4,
          salesRank: 5845
        },
        {
          date: '2020-01-30',
          reviewCount: 477,
          rating: 4,
          salesRank: 5878
        },
        {
          date: '2020-01-31',
          reviewCount: 509,
          rating: 4,
          salesRank: 9185
        },
        {
          date: '2020-02-01',
          reviewCount: 509,
          rating: 4,
          salesRank: 8296
        },
        {
          date: '2020-02-02',
          reviewCount: 509,
          rating: 4,
          salesRank: 8291
        },
        {
          date: '2020-02-03',
          reviewCount: 509,
          rating: 4,
          salesRank: 8278
        },
        {
          date: '2020-02-04',
          reviewCount: 509,
          rating: 4,
          salesRank: 6717
        },
        {
          date: '2020-02-05',
          reviewCount: 509,
          rating: 4,
          salesRank: 6188
        },
        {
          date: '2020-02-06',
          reviewCount: 509,
          rating: 4,
          salesRank: 7094
        },
        {
          date: '2020-02-07',
          reviewCount: 509,
          rating: 4,
          salesRank: 6436
        },
        {
          date: '2020-02-08',
          reviewCount: 509,
          rating: 4,
          salesRank: 8385
        },
        {
          date: '2020-02-09',
          reviewCount: 509,
          rating: 4,
          salesRank: 7787
        },
        {
          date: '2020-02-10',
          reviewCount: 509,
          rating: 4,
          salesRank: 6679
        },
        {
          date: '2020-02-11',
          reviewCount: 509,
          rating: 4,
          salesRank: 8554
        },
        {
          date: '2020-02-12',
          reviewCount: 559,
          rating: 4,
          salesRank: 6136
        },
        {
          date: '2020-02-13',
          reviewCount: 559,
          rating: 4,
          salesRank: 6697
        },
        {
          date: '2020-02-14',
          reviewCount: 559,
          rating: 4,
          salesRank: 10987
        },
        {
          date: '2020-02-15',
          reviewCount: 559,
          rating: 4,
          salesRank: 11965
        },
        {
          date: '2020-02-16',
          reviewCount: 559,
          rating: 4,
          salesRank: 7419
        },
        {
          date: '2020-02-17',
          reviewCount: 559,
          rating: 4,
          salesRank: 9762
        },
        {
          date: '2020-02-18',
          reviewCount: 559,
          rating: 4,
          salesRank: 7239
        },
        {
          date: '2020-02-19',
          reviewCount: 559,
          rating: 4,
          salesRank: 6234
        },
        {
          date: '2020-02-20',
          reviewCount: 559,
          rating: 4,
          salesRank: 6938
        },
        {
          date: '2020-02-21',
          reviewCount: 559,
          rating: 4,
          salesRank: 5608
        },
        {
          date: '2020-02-22',
          reviewCount: 559,
          rating: 4,
          salesRank: 6736
        },
        {
          date: '2020-02-23',
          reviewCount: 559,
          rating: 4,
          salesRank: 4456
        },
        {
          date: '2020-02-24',
          reviewCount: 559,
          rating: 4,
          salesRank: 4827
        },
        {
          date: '2020-02-25',
          reviewCount: 603,
          rating: 4,
          salesRank: 7256
        },
        {
          date: '2020-02-26',
          reviewCount: 603,
          rating: 4,
          salesRank: 6606
        },
        {
          date: '2020-02-27',
          reviewCount: 603,
          rating: 4,
          salesRank: 5454
        },
        {
          date: '2020-02-28',
          reviewCount: 603,
          rating: 4,
          salesRank: 4740
        },
        {
          date: '2020-02-29',
          reviewCount: 603,
          rating: 4,
          salesRank: 6845
        },
        {
          date: '2020-03-01',
          reviewCount: 603,
          rating: 4,
          salesRank: 5760
        },
        {
          date: '2020-03-02',
          reviewCount: 603,
          rating: 4,
          salesRank: 5032
        },
        {
          date: '2020-03-03',
          reviewCount: 603,
          rating: 4,
          salesRank: 6081
        },
        {
          date: '2020-03-04',
          reviewCount: 603,
          rating: 4,
          salesRank: 6314
        },
        {
          date: '2020-03-05',
          reviewCount: 603,
          rating: 4,
          salesRank: 8054
        },
        {
          date: '2020-03-06',
          reviewCount: 630,
          rating: 4,
          salesRank: 9489
        },
        {
          date: '2020-03-07',
          reviewCount: 630,
          rating: 4,
          salesRank: 11185
        },
        {
          date: '2020-03-08',
          reviewCount: 630,
          rating: 4,
          salesRank: 8906
        },
        {
          date: '2020-03-09',
          reviewCount: 630,
          rating: 4,
          salesRank: 6904
        },
        {
          date: '2020-03-10',
          reviewCount: 630,
          rating: 4,
          salesRank: 8052
        },
        {
          date: '2020-03-11',
          reviewCount: 630,
          rating: 4,
          salesRank: 7322
        },
        {
          date: '2020-03-12',
          reviewCount: 630,
          rating: 4,
          salesRank: 6583
        },
        {
          date: '2020-03-13',
          reviewCount: 630,
          rating: 4,
          salesRank: 7178
        },
        {
          date: '2020-03-14',
          reviewCount: 630,
          rating: 4,
          salesRank: 7658
        },
        {
          date: '2020-03-15',
          reviewCount: 630,
          rating: 4,
          salesRank: 8280
        },
        {
          date: '2020-03-16',
          reviewCount: 630,
          rating: 4,
          salesRank: 6892
        },
        {
          date: '2020-03-17',
          reviewCount: 630,
          rating: 4,
          salesRank: 5601
        },
        {
          date: '2020-03-18',
          reviewCount: 667,
          rating: 4,
          salesRank: 4426
        },
        {
          date: '2020-03-19',
          reviewCount: 667,
          rating: 4,
          salesRank: 520
        },
        {
          date: '2020-03-20',
          reviewCount: 667,
          rating: 4,
          salesRank: 476
        },
        {
          date: '2020-03-21',
          reviewCount: 667,
          rating: 4,
          salesRank: 450
        },
        {
          date: '2020-03-22',
          reviewCount: 667,
          rating: 4,
          salesRank: 515
        },
        {
          date: '2020-03-23',
          reviewCount: 667,
          rating: 4,
          salesRank: 452
        },
        {
          date: '2020-03-24',
          reviewCount: 667,
          rating: 4,
          salesRank: 509
        },
        {
          date: '2020-03-25',
          reviewCount: 667,
          rating: 4,
          salesRank: 584
        },
        {
          date: '2020-03-26',
          reviewCount: 694,
          rating: 4,
          salesRank: 587
        },
        {
          date: '2020-03-27',
          reviewCount: 694,
          rating: 4,
          salesRank: 663
        },
        {
          date: '2020-03-28',
          reviewCount: 694,
          rating: 4,
          salesRank: 640
        },
        {
          date: '2020-03-29',
          reviewCount: 694,
          rating: 4,
          salesRank: 510
        },
        {
          date: '2020-03-30',
          reviewCount: 694,
          rating: 4,
          salesRank: 381
        },
        {
          date: '2020-03-31',
          reviewCount: 694,
          rating: 4,
          salesRank: 547
        },
        {
          date: '2020-04-01',
          reviewCount: 694,
          rating: 4,
          salesRank: 493
        },
        {
          date: '2020-04-02',
          reviewCount: 694,
          rating: 4,
          salesRank: 370
        },
        {
          date: '2020-04-03',
          reviewCount: 694,
          rating: 4,
          salesRank: 460
        },
        {
          date: '2020-04-04',
          reviewCount: 707,
          rating: 4,
          salesRank: 374
        },
        {
          date: '2020-04-05',
          reviewCount: 707,
          rating: 4,
          salesRank: 415
        },
        {
          date: '2020-04-06',
          reviewCount: 707,
          rating: 4,
          salesRank: 298
        },
        {
          date: '2020-04-07',
          reviewCount: 707,
          rating: 4,
          salesRank: 421
        },
        {
          date: '2020-04-08',
          reviewCount: 707,
          rating: 4,
          salesRank: 441
        },
        {
          date: '2020-04-09',
          reviewCount: 707,
          rating: 4,
          salesRank: 505
        },
        {
          date: '2020-04-10',
          reviewCount: 707,
          rating: 4,
          salesRank: 499
        },
        {
          date: '2020-04-11',
          reviewCount: 707,
          rating: 4,
          salesRank: 581
        },
        {
          date: '2020-04-12',
          reviewCount: 707,
          rating: 4,
          salesRank: 509
        },
        {
          date: '2020-04-13',
          reviewCount: 707,
          rating: 4,
          salesRank: 360
        },
        {
          date: '2020-04-14',
          reviewCount: 707,
          rating: 4,
          salesRank: 520
        },
        {
          date: '2020-04-15',
          reviewCount: 722,
          rating: 4,
          salesRank: 433
        },
        {
          date: '2020-04-16',
          reviewCount: 722,
          rating: 4,
          salesRank: 420
        },
        {
          date: '2020-04-17',
          reviewCount: 722,
          rating: 4,
          salesRank: 379
        },
        {
          date: '2020-04-18',
          reviewCount: 722,
          rating: 4,
          salesRank: 332
        },
        {
          date: '2020-04-19',
          reviewCount: 722,
          rating: 4,
          salesRank: 364
        },
        {
          date: '2020-04-20',
          reviewCount: 722,
          rating: 4,
          salesRank: 397
        },
        {
          date: '2020-04-21',
          reviewCount: 722,
          rating: 4,
          salesRank: 433
        },
        {
          date: '2020-04-22',
          reviewCount: 722,
          rating: 4,
          salesRank: 559
        },
        {
          date: '2020-04-23',
          reviewCount: 722,
          rating: 4,
          salesRank: 503
        },
        {
          date: '2020-04-24',
          reviewCount: 722,
          rating: 4,
          salesRank: 559
        },
        {
          date: '2020-04-25',
          reviewCount: 722,
          rating: 4,
          salesRank: 388
        },
        {
          date: '2020-04-26',
          reviewCount: 722,
          rating: 4,
          salesRank: 426
        },
        {
          date: '2020-04-27',
          reviewCount: 722,
          rating: 4,
          salesRank: 435
        },
        {
          date: '2020-04-28',
          reviewCount: 722,
          rating: 4,
          salesRank: 465
        },
        {
          date: '2020-04-29',
          reviewCount: 747,
          rating: 4,
          salesRank: 384
        },
        {
          date: '2020-04-30',
          reviewCount: 747,
          rating: 4,
          salesRank: 476
        },
        {
          date: '2020-05-01',
          reviewCount: 747,
          rating: 4,
          salesRank: 416
        },
        {
          date: '2020-05-02',
          reviewCount: 747,
          rating: 4,
          salesRank: 322
        },
        {
          date: '2020-05-03',
          reviewCount: 747,
          rating: 4,
          salesRank: 310
        },
        {
          date: '2020-05-04',
          reviewCount: 747,
          rating: 4,
          salesRank: 365
        },
        {
          date: '2020-05-05',
          reviewCount: 747,
          rating: 4,
          salesRank: 315
        },
        {
          date: '2020-05-06',
          reviewCount: 747,
          rating: 4,
          salesRank: 384
        },
        {
          date: '2020-05-07',
          reviewCount: 747,
          rating: 4,
          salesRank: 321
        },
        {
          date: '2020-05-08',
          reviewCount: 747,
          rating: 4,
          salesRank: 353
        },
        {
          date: '2020-05-09',
          reviewCount: 747,
          rating: 4,
          salesRank: 403
        },
        {
          date: '2020-05-10',
          reviewCount: 747,
          rating: 4,
          salesRank: 451
        },
        {
          date: '2020-05-11',
          reviewCount: 778,
          rating: 4,
          salesRank: 359
        },
        {
          date: '2020-05-12',
          reviewCount: 778,
          rating: 4,
          salesRank: 338
        },
        {
          date: '2020-05-13',
          reviewCount: 778,
          rating: 4,
          salesRank: 251
        },
        {
          date: '2020-05-14',
          reviewCount: 778,
          rating: 4,
          salesRank: 296
        },
        {
          date: '2020-05-15',
          reviewCount: 778,
          rating: 4,
          salesRank: 306
        },
        {
          date: '2020-05-16',
          reviewCount: 778,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2020-05-17',
          reviewCount: 778,
          rating: 4,
          salesRank: 337
        },
        {
          date: '2020-05-18',
          reviewCount: 778,
          rating: 4,
          salesRank: 306
        },
        {
          date: '2020-05-19',
          reviewCount: 795,
          rating: 4,
          salesRank: 339
        },
        {
          date: '2020-05-20',
          reviewCount: 795,
          rating: 4,
          salesRank: 406
        },
        {
          date: '2020-05-21',
          reviewCount: 795,
          rating: 4,
          salesRank: 448
        },
        {
          date: '2020-05-22',
          reviewCount: 795,
          rating: 4,
          salesRank: 105
        },
        {
          date: '2020-05-23',
          reviewCount: 795,
          rating: 4
        },
        {
          date: '2020-05-24',
          reviewCount: 795,
          rating: 4
        },
        {
          date: '2020-05-25',
          reviewCount: 795,
          rating: 4,
          salesRank: 100
        },
        {
          date: '2020-05-26',
          reviewCount: 795,
          rating: 4,
          salesRank: 107
        },
        {
          date: '2020-05-27',
          reviewCount: 795,
          rating: 4,
          salesRank: 107
        },
        {
          date: '2020-05-28',
          reviewCount: 824,
          rating: 4,
          salesRank: 112
        },
        {
          date: '2020-05-29',
          reviewCount: 824,
          rating: 4,
          salesRank: 102
        },
        {
          date: '2020-05-30',
          reviewCount: 824,
          rating: 4,
          salesRank: 109
        },
        {
          date: '2020-05-31',
          reviewCount: 824,
          rating: 4,
          salesRank: 85
        },
        {
          date: '2020-06-01',
          reviewCount: 824,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2020-06-02',
          reviewCount: 824,
          rating: 4,
          salesRank: 320
        },
        {
          date: '2020-06-03',
          reviewCount: 824,
          rating: 4,
          salesRank: 308
        },
        {
          date: '2020-06-04',
          reviewCount: 824,
          rating: 4,
          salesRank: 297
        },
        {
          date: '2020-06-05',
          reviewCount: 824,
          rating: 4,
          salesRank: 413
        },
        {
          date: '2020-06-06',
          reviewCount: 824,
          rating: 4,
          salesRank: 255
        },
        {
          date: '2020-06-07',
          reviewCount: 824,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2020-06-08',
          reviewCount: 850,
          rating: 4,
          salesRank: 255
        },
        {
          date: '2020-06-09',
          reviewCount: 850,
          rating: 4,
          salesRank: 300
        },
        {
          date: '2020-06-10',
          reviewCount: 850,
          rating: 4,
          salesRank: 284
        },
        {
          date: '2020-06-11',
          reviewCount: 850,
          rating: 4,
          salesRank: 291
        },
        {
          date: '2020-06-12',
          reviewCount: 850,
          rating: 4,
          salesRank: 309
        },
        {
          date: '2020-06-13',
          reviewCount: 850,
          rating: 4,
          salesRank: 284
        },
        {
          date: '2020-06-14',
          reviewCount: 850,
          rating: 4,
          salesRank: 238
        },
        {
          date: '2020-06-15',
          reviewCount: 850,
          rating: 4,
          salesRank: 279
        },
        {
          date: '2020-06-16',
          reviewCount: 850,
          rating: 4,
          salesRank: 308
        },
        {
          date: '2020-06-17',
          reviewCount: 850,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2020-06-18',
          reviewCount: 882,
          rating: 4,
          salesRank: 334
        },
        {
          date: '2020-06-19',
          reviewCount: 882,
          rating: 4,
          salesRank: 298
        },
        {
          date: '2020-06-20',
          reviewCount: 882,
          rating: 4,
          salesRank: 248
        },
        {
          date: '2020-06-21',
          reviewCount: 882,
          rating: 4,
          salesRank: 293
        },
        {
          date: '2020-06-22',
          reviewCount: 882,
          rating: 4,
          salesRank: 268
        },
        {
          date: '2020-06-23',
          reviewCount: 882,
          rating: 4,
          salesRank: 337
        },
        {
          date: '2020-06-24',
          reviewCount: 882,
          rating: 4,
          salesRank: 282
        },
        {
          date: '2020-06-25',
          reviewCount: 882,
          rating: 4,
          salesRank: 323
        },
        {
          date: '2020-06-26',
          reviewCount: 882,
          rating: 4,
          salesRank: 109
        },
        {
          date: '2020-06-27',
          reviewCount: 882,
          rating: 4,
          salesRank: 204
        },
        {
          date: '2020-06-28',
          reviewCount: 882,
          rating: 4,
          salesRank: 231
        },
        {
          date: '2020-06-29',
          reviewCount: 912,
          rating: 4,
          salesRank: 185
        },
        {
          date: '2020-06-30',
          reviewCount: 912,
          rating: 4,
          salesRank: 211
        },
        {
          date: '2020-07-01',
          reviewCount: 912,
          rating: 4,
          salesRank: 194
        },
        {
          date: '2020-07-02',
          reviewCount: 912,
          rating: 4,
          salesRank: 211
        },
        {
          date: '2020-07-03',
          reviewCount: 912,
          rating: 4,
          salesRank: 252
        },
        {
          date: '2020-07-04',
          reviewCount: 912,
          rating: 4,
          salesRank: 316
        },
        {
          date: '2020-07-05',
          reviewCount: 912,
          rating: 4,
          salesRank: 233
        },
        {
          date: '2020-07-06',
          reviewCount: 912,
          rating: 4,
          salesRank: 262
        },
        {
          date: '2020-07-07',
          reviewCount: 912,
          rating: 4,
          salesRank: 234
        },
        {
          date: '2020-07-08',
          reviewCount: 936,
          rating: 4,
          salesRank: 245
        },
        {
          date: '2020-07-09',
          reviewCount: 936,
          rating: 4,
          salesRank: 247
        },
        {
          date: '2020-07-10',
          reviewCount: 936,
          rating: 4,
          salesRank: 297
        },
        {
          date: '2020-07-11',
          reviewCount: 936,
          rating: 4,
          salesRank: 297
        },
        {
          date: '2020-07-12',
          reviewCount: 936,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2020-07-13',
          reviewCount: 936,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2020-07-14',
          reviewCount: 936,
          rating: 4,
          salesRank: 265
        },
        {
          date: '2020-07-15',
          reviewCount: 936,
          rating: 4,
          salesRank: 221
        },
        {
          date: '2020-07-16',
          reviewCount: 936,
          rating: 4,
          salesRank: 232
        },
        {
          date: '2020-07-17',
          reviewCount: 936,
          rating: 4,
          salesRank: 225
        },
        {
          date: '2020-07-18',
          reviewCount: 936,
          rating: 4,
          salesRank: 244
        },
        {
          date: '2020-07-19',
          reviewCount: 936,
          rating: 4,
          salesRank: 235
        },
        {
          date: '2020-07-20',
          reviewCount: 936,
          rating: 4,
          salesRank: 191
        },
        {
          date: '2020-07-21',
          reviewCount: 1014,
          rating: 4,
          salesRank: 182
        },
        {
          date: '2020-07-22',
          reviewCount: 1014,
          rating: 4,
          salesRank: 194
        },
        {
          date: '2020-07-23',
          reviewCount: 1014,
          rating: 4,
          salesRank: 187
        },
        {
          date: '2020-07-24',
          reviewCount: 1014,
          rating: 4,
          salesRank: 185
        },
        {
          date: '2020-07-25',
          reviewCount: 1014,
          rating: 4,
          salesRank: 235
        },
        {
          date: '2020-07-26',
          reviewCount: 1014,
          rating: 4,
          salesRank: 190
        },
        {
          date: '2020-07-27',
          reviewCount: 1014,
          rating: 4,
          salesRank: 169
        },
        {
          date: '2020-07-28',
          reviewCount: 1014,
          rating: 4,
          salesRank: 159
        },
        {
          date: '2020-07-29',
          reviewCount: 1107,
          rating: 4,
          salesRank: 164
        },
        {
          date: '2020-07-30',
          reviewCount: 1107,
          rating: 4,
          salesRank: 174
        },
        {
          date: '2020-07-31',
          reviewCount: 1107,
          rating: 4,
          salesRank: 151
        },
        {
          date: '2020-08-01',
          reviewCount: 1107,
          rating: 4,
          salesRank: 149
        },
        {
          date: '2020-08-02',
          reviewCount: 1107,
          rating: 4,
          salesRank: 129
        },
        {
          date: '2020-08-03',
          reviewCount: 1107,
          rating: 4,
          salesRank: 134
        },
        {
          date: '2020-08-04',
          reviewCount: 1107,
          rating: 4,
          salesRank: 124
        },
        {
          date: '2020-08-05',
          reviewCount: 1107,
          rating: 4,
          salesRank: 117
        },
        {
          date: '2020-08-06',
          reviewCount: 1107,
          rating: 4,
          salesRank: 128
        },
        {
          date: '2020-08-07',
          reviewCount: 1234,
          rating: 4,
          salesRank: 125
        },
        {
          date: '2020-08-08',
          reviewCount: 1234,
          rating: 4,
          salesRank: 121
        },
        {
          date: '2020-08-09',
          reviewCount: 1234,
          rating: 4,
          salesRank: 105
        },
        {
          date: '2020-08-10',
          reviewCount: 1234,
          rating: 4,
          salesRank: 102
        },
        {
          date: '2020-08-11',
          reviewCount: 1234,
          rating: 4
        },
        {
          date: '2020-08-12',
          reviewCount: 1234,
          rating: 4,
          salesRank: 74
        },
        {
          date: '2020-08-13',
          reviewCount: 1234,
          rating: 4,
          salesRank: 75
        },
        {
          date: '2020-08-14',
          reviewCount: 1234,
          rating: 4,
          salesRank: 77
        },
        {
          date: '2020-08-15',
          reviewCount: 1234,
          rating: 4,
          salesRank: 88
        },
        {
          date: '2020-08-16',
          reviewCount: 1234,
          rating: 4,
          salesRank: 79
        },
        {
          date: '2020-08-17',
          reviewCount: 1234,
          rating: 4,
          salesRank: 72
        },
        {
          date: '2020-08-18',
          reviewCount: 1447,
          rating: 4,
          salesRank: 65
        },
        {
          date: '2020-08-19',
          reviewCount: 1447,
          rating: 4,
          salesRank: 56
        },
        {
          date: '2020-08-20',
          reviewCount: 1447,
          rating: 4,
          salesRank: 75
        },
        {
          date: '2020-08-21',
          reviewCount: 1447,
          rating: 4,
          salesRank: 78
        },
        {
          date: '2020-08-22',
          reviewCount: 1447,
          rating: 4,
          salesRank: 83
        },
        {
          date: '2020-08-23',
          reviewCount: 1447,
          rating: 4,
          salesRank: 83
        },
        {
          date: '2020-08-24',
          reviewCount: 1447,
          rating: 4,
          salesRank: 96
        },
        {
          date: '2020-08-25',
          reviewCount: 1447,
          rating: 4,
          salesRank: 83
        },
        {
          date: '2020-08-26',
          reviewCount: 1447,
          rating: 4,
          salesRank: 82
        },
        {
          date: '2020-08-27',
          reviewCount: 1447,
          rating: 4,
          salesRank: 80
        },
        {
          date: '2020-08-28',
          reviewCount: 1748,
          rating: 4,
          salesRank: 90
        },
        {
          date: '2020-08-29',
          reviewCount: 1748,
          rating: 4,
          salesRank: 111
        },
        {
          date: '2020-08-30',
          reviewCount: 1748,
          rating: 4,
          salesRank: 95
        },
        {
          date: '2020-08-31',
          reviewCount: 1748,
          rating: 4,
          salesRank: 88
        },
        {
          date: '2020-09-01',
          reviewCount: 1748,
          rating: 4,
          salesRank: 82
        },
        {
          date: '2020-09-02',
          reviewCount: 1748,
          rating: 4,
          salesRank: 89
        },
        {
          date: '2020-09-03',
          reviewCount: 1906,
          rating: 4,
          salesRank: 92
        },
        {
          date: '2020-09-04',
          reviewCount: 1906,
          rating: 4,
          salesRank: 89
        },
        {
          date: '2020-09-05',
          reviewCount: 1906,
          rating: 4,
          salesRank: 103
        },
        {
          date: '2020-09-06',
          reviewCount: 1906,
          rating: 4,
          salesRank: 107
        },
        {
          date: '2020-09-07',
          reviewCount: 1906,
          rating: 4,
          salesRank: 100
        },
        {
          date: '2020-09-08',
          reviewCount: 1906,
          rating: 4,
          salesRank: 99
        },
        {
          date: '2020-09-09',
          reviewCount: 1906,
          rating: 4,
          salesRank: 81
        },
        {
          date: '2020-09-10',
          reviewCount: 1906,
          rating: 4,
          salesRank: 93
        },
        {
          date: '2020-09-11',
          reviewCount: 1906,
          rating: 4,
          salesRank: 94
        },
        {
          date: '2020-09-12',
          reviewCount: 1906,
          rating: 4,
          salesRank: 108
        },
        {
          date: '2020-09-13',
          reviewCount: 1906,
          rating: 4,
          salesRank: 93
        },
        {
          date: '2020-09-14',
          reviewCount: 1906,
          rating: 4,
          salesRank: 101
        },
        {
          date: '2020-09-15',
          reviewCount: 1906,
          rating: 4,
          salesRank: 89
        },
        {
          date: '2020-09-16',
          reviewCount: 1906,
          rating: 4,
          salesRank: 86
        },
        {
          date: '2020-09-17',
          reviewCount: 1906,
          rating: 4,
          salesRank: 108
        },
        {
          date: '2020-09-18',
          reviewCount: 1906,
          rating: 4,
          salesRank: 136
        },
        {
          date: '2020-09-19',
          reviewCount: 1906,
          rating: 4,
          salesRank: 132
        },
        {
          date: '2020-09-20',
          reviewCount: 1906,
          rating: 4,
          salesRank: 147
        },
        {
          date: '2020-09-21',
          reviewCount: 1906,
          rating: 4,
          salesRank: 160
        },
        {
          date: '2020-09-22',
          reviewCount: 1906,
          rating: 4,
          salesRank: 176
        },
        {
          date: '2020-09-23',
          reviewCount: 1906,
          rating: 4,
          salesRank: 182
        },
        {
          date: '2020-09-24',
          reviewCount: 2458,
          rating: 4,
          salesRank: 158
        },
        {
          date: '2020-09-25',
          reviewCount: 2458,
          rating: 4,
          salesRank: 157
        },
        {
          date: '2020-09-26',
          reviewCount: 2458,
          rating: 4,
          salesRank: 145
        },
        {
          date: '2020-09-27',
          reviewCount: 2458,
          rating: 4,
          salesRank: 128
        },
        {
          date: '2020-09-28',
          reviewCount: 2458,
          rating: 4,
          salesRank: 98
        },
        {
          date: '2020-09-29',
          reviewCount: 2458,
          rating: 4,
          salesRank: 103
        },
        {
          date: '2020-09-30',
          reviewCount: 2458,
          rating: 4,
          salesRank: 102
        },
        {
          date: '2020-10-01',
          reviewCount: 2629,
          rating: 4,
          salesRank: 110
        },
        {
          date: '2020-10-02',
          reviewCount: 2629,
          rating: 4,
          salesRank: 114
        },
        {
          date: '2020-10-03',
          reviewCount: 2629,
          rating: 4,
          salesRank: 120
        },
        {
          date: '2020-10-04',
          reviewCount: 2629,
          rating: 4,
          salesRank: 132
        },
        {
          date: '2020-10-05',
          reviewCount: 2629,
          rating: 4,
          salesRank: 141
        },
        {
          date: '2020-10-06',
          reviewCount: 2629,
          rating: 4,
          salesRank: 146
        },
        {
          date: '2020-10-07',
          reviewCount: 2629,
          rating: 4,
          salesRank: 178
        },
        {
          date: '2020-10-08',
          reviewCount: 2837,
          rating: 4,
          salesRank: 182
        },
        {
          date: '2020-10-09',
          reviewCount: 2837,
          rating: 4,
          salesRank: 193
        },
        {
          date: '2020-10-10',
          reviewCount: 2837,
          rating: 4,
          salesRank: 261
        },
        {
          date: '2020-10-11',
          reviewCount: 2837,
          rating: 4,
          salesRank: 227
        },
        {
          date: '2020-10-12',
          reviewCount: 2837,
          rating: 4,
          salesRank: 206
        },
        {
          date: '2020-10-13',
          reviewCount: 2837,
          rating: 4,
          salesRank: 276
        },
        {
          date: '2020-10-14',
          reviewCount: 2837,
          rating: 4,
          salesRank: 394
        },
        {
          date: '2020-10-15',
          reviewCount: 2971,
          rating: 4,
          salesRank: 430
        },
        {
          date: '2020-10-16',
          reviewCount: 2971,
          rating: 4,
          salesRank: 371
        },
        {
          date: '2020-10-17',
          reviewCount: 2971,
          rating: 4,
          salesRank: 328
        },
        {
          date: '2020-10-18',
          reviewCount: 2971,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2020-10-19',
          reviewCount: 2971,
          rating: 4,
          salesRank: 213
        },
        {
          date: '2020-10-20',
          reviewCount: 2971,
          rating: 4,
          salesRank: 286
        },
        {
          date: '2020-10-21',
          reviewCount: 2971,
          rating: 4,
          salesRank: 237
        },
        {
          date: '2020-10-22',
          reviewCount: 2971,
          rating: 4,
          salesRank: 219
        },
        {
          date: '2020-10-23',
          reviewCount: 2971,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2020-10-24',
          reviewCount: 3251,
          rating: 4,
          salesRank: 288
        },
        {
          date: '2020-10-25',
          reviewCount: 3251,
          rating: 4,
          salesRank: 354
        },
        {
          date: '2020-10-26',
          reviewCount: 3251,
          rating: 4,
          salesRank: 284
        },
        {
          date: '2020-10-27',
          reviewCount: 3251,
          rating: 4,
          salesRank: 235
        },
        {
          date: '2020-10-28',
          reviewCount: 3251,
          rating: 4,
          salesRank: 251
        },
        {
          date: '2020-10-29',
          reviewCount: 3251,
          rating: 4,
          salesRank: 252
        },
        {
          date: '2020-10-30',
          reviewCount: 3251,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2020-10-31',
          reviewCount: 3443,
          rating: 4,
          salesRank: 290
        },
        {
          date: '2020-11-01',
          reviewCount: 3443,
          rating: 4,
          salesRank: 300
        },
        {
          date: '2020-11-02',
          reviewCount: 3443,
          rating: 4,
          salesRank: 282
        },
        {
          date: '2020-11-03',
          reviewCount: 3443,
          rating: 4,
          salesRank: 360
        },
        {
          date: '2020-11-04',
          reviewCount: 3443,
          rating: 4,
          salesRank: 298
        },
        {
          date: '2020-11-05',
          reviewCount: 3443,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2020-11-06',
          reviewCount: 3443,
          rating: 4,
          salesRank: 283
        },
        {
          date: '2020-11-07',
          reviewCount: 3443,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2020-11-08',
          reviewCount: 3443,
          rating: 4,
          salesRank: 253
        },
        {
          date: '2020-11-09',
          reviewCount: 3712,
          rating: 4,
          salesRank: 283
        },
        {
          date: '2020-11-10',
          reviewCount: 3712,
          rating: 4,
          salesRank: 286
        },
        {
          date: '2020-11-11',
          reviewCount: 3712,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2020-11-12',
          reviewCount: 3712,
          rating: 4,
          salesRank: 411
        },
        {
          date: '2020-11-13',
          reviewCount: 3712,
          rating: 4,
          salesRank: 356
        },
        {
          date: '2020-11-14',
          reviewCount: 3712,
          rating: 4,
          salesRank: 378
        },
        {
          date: '2020-11-15',
          reviewCount: 3712,
          rating: 4,
          salesRank: 303
        },
        {
          date: '2020-11-16',
          reviewCount: 3712,
          rating: 4,
          salesRank: 271
        },
        {
          date: '2020-11-17',
          reviewCount: 3712,
          rating: 4,
          salesRank: 291
        },
        {
          date: '2020-11-18',
          reviewCount: 3892,
          rating: 4,
          salesRank: 291
        },
        {
          date: '2020-11-19',
          reviewCount: 3892,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2020-11-20',
          reviewCount: 3892,
          rating: 4,
          salesRank: 327
        },
        {
          date: '2020-11-21',
          reviewCount: 3892,
          rating: 4,
          salesRank: 384
        },
        {
          date: '2020-11-22',
          reviewCount: 3892,
          rating: 4,
          salesRank: 330
        },
        {
          date: '2020-11-23',
          reviewCount: 3892,
          rating: 4,
          salesRank: 391
        },
        {
          date: '2020-11-24',
          reviewCount: 3892,
          rating: 4,
          salesRank: 360
        },
        {
          date: '2020-11-25',
          reviewCount: 3892,
          rating: 4,
          salesRank: 331
        },
        {
          date: '2020-11-26',
          reviewCount: 4096,
          rating: 4,
          salesRank: 386
        },
        {
          date: '2020-11-27',
          reviewCount: 4096,
          rating: 4,
          salesRank: 518
        },
        {
          date: '2020-11-28',
          reviewCount: 4096,
          rating: 4,
          salesRank: 529
        },
        {
          date: '2020-11-29',
          reviewCount: 4096,
          rating: 4,
          salesRank: 445
        },
        {
          date: '2020-11-30',
          reviewCount: 4096,
          rating: 4,
          salesRank: 446
        },
        {
          date: '2020-12-01',
          reviewCount: 4096,
          rating: 4,
          salesRank: 471
        },
        {
          date: '2020-12-02',
          reviewCount: 4096,
          rating: 4,
          salesRank: 448
        },
        {
          date: '2020-12-03',
          reviewCount: 4096,
          rating: 4,
          salesRank: 398
        },
        {
          date: '2020-12-04',
          reviewCount: 4180,
          rating: 4,
          salesRank: 419
        },
        {
          date: '2020-12-05',
          reviewCount: 4180,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2020-12-06',
          reviewCount: 4180,
          rating: 4,
          salesRank: 349
        },
        {
          date: '2020-12-07',
          reviewCount: 4180,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2020-12-08',
          reviewCount: 4180,
          rating: 4,
          salesRank: 317
        },
        {
          date: '2020-12-09',
          reviewCount: 4180,
          rating: 4,
          salesRank: 270
        },
        {
          date: '2020-12-10',
          reviewCount: 4180,
          rating: 4,
          salesRank: 293
        },
        {
          date: '2020-12-11',
          reviewCount: 4364,
          rating: 4,
          salesRank: 267
        },
        {
          date: '2020-12-12',
          reviewCount: 4364,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2020-12-13',
          reviewCount: 4364,
          rating: 4
        },
        {
          date: '2020-12-14',
          reviewCount: 4364,
          rating: 4,
          salesRank: 358
        },
        {
          date: '2020-12-15',
          reviewCount: 4364,
          rating: 4,
          salesRank: 383
        },
        {
          date: '2020-12-16',
          reviewCount: 4364,
          rating: 4,
          salesRank: 324
        },
        {
          date: '2020-12-17',
          reviewCount: 4364,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2020-12-18',
          reviewCount: 4364,
          rating: 4,
          salesRank: 325
        },
        {
          date: '2020-12-19',
          reviewCount: 4521,
          rating: 4,
          salesRank: 404
        },
        {
          date: '2020-12-20',
          reviewCount: 4521,
          rating: 4,
          salesRank: 346
        },
        {
          date: '2020-12-21',
          reviewCount: 4521,
          rating: 4,
          salesRank: 346
        },
        {
          date: '2020-12-22',
          reviewCount: 4521,
          rating: 4,
          salesRank: 324
        },
        {
          date: '2020-12-23',
          reviewCount: 4521,
          rating: 4,
          salesRank: 353
        },
        {
          date: '2020-12-24',
          reviewCount: 4521,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2020-12-25',
          reviewCount: 4521,
          rating: 4,
          salesRank: 374
        },
        {
          date: '2020-12-26',
          reviewCount: 4521,
          rating: 4,
          salesRank: 371
        },
        {
          date: '2020-12-27',
          reviewCount: 4673,
          rating: 4,
          salesRank: 347
        },
        {
          date: '2020-12-28',
          reviewCount: 4673,
          rating: 4,
          salesRank: 319
        },
        {
          date: '2020-12-29',
          reviewCount: 4673,
          rating: 4,
          salesRank: 330
        },
        {
          date: '2020-12-30',
          reviewCount: 4673,
          rating: 4,
          salesRank: 371
        },
        {
          date: '2020-12-31',
          reviewCount: 4673,
          rating: 4,
          salesRank: 335
        },
        {
          date: '2021-01-01',
          reviewCount: 4673,
          rating: 4,
          salesRank: 310
        },
        {
          date: '2021-01-02',
          reviewCount: 4673,
          rating: 4,
          salesRank: 233
        },
        {
          date: '2021-01-03',
          reviewCount: 4851,
          rating: 4,
          salesRank: 288
        },
        {
          date: '2021-01-04',
          reviewCount: 4851,
          rating: 4,
          salesRank: 266
        },
        {
          date: '2021-01-05',
          reviewCount: 4851,
          rating: 4,
          salesRank: 325
        },
        {
          date: '2021-01-06',
          reviewCount: 4851,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2021-01-07',
          reviewCount: 4851,
          rating: 4,
          salesRank: 280
        },
        {
          date: '2021-01-08',
          reviewCount: 4851,
          rating: 4,
          salesRank: 297
        },
        {
          date: '2021-01-09',
          reviewCount: 4851,
          rating: 4,
          salesRank: 317
        },
        {
          date: '2021-01-10',
          reviewCount: 4851,
          rating: 4,
          salesRank: 368
        },
        {
          date: '2021-01-11',
          reviewCount: 5023,
          rating: 4,
          salesRank: 319
        },
        {
          date: '2021-01-12',
          reviewCount: 5023,
          rating: 4,
          salesRank: 473
        },
        {
          date: '2021-01-13',
          reviewCount: 5023,
          rating: 4,
          salesRank: 540
        },
        {
          date: '2021-01-14',
          reviewCount: 5023,
          rating: 4,
          salesRank: 530
        },
        {
          date: '2021-01-15',
          reviewCount: 5023,
          rating: 4,
          salesRank: 762
        },
        {
          date: '2021-01-16',
          reviewCount: 5023,
          rating: 4,
          salesRank: 607
        },
        {
          date: '2021-01-17',
          reviewCount: 5023,
          rating: 4,
          salesRank: 571
        },
        {
          date: '2021-01-18',
          reviewCount: 5023,
          rating: 4,
          salesRank: 590
        },
        {
          date: '2021-01-19',
          reviewCount: 5183,
          rating: 4,
          salesRank: 593
        },
        {
          date: '2021-01-20',
          reviewCount: 5183,
          rating: 4,
          salesRank: 625
        },
        {
          date: '2021-01-21',
          reviewCount: 5183,
          rating: 4,
          salesRank: 808
        },
        {
          date: '2021-01-22',
          reviewCount: 5183,
          rating: 4,
          salesRank: 628
        },
        {
          date: '2021-01-23',
          reviewCount: 5183,
          rating: 4,
          salesRank: 442
        },
        {
          date: '2021-01-24',
          reviewCount: 5183,
          rating: 4,
          salesRank: 460
        },
        {
          date: '2021-01-25',
          reviewCount: 5183,
          rating: 4,
          salesRank: 552
        },
        {
          date: '2021-01-26',
          reviewCount: 5333,
          rating: 4,
          salesRank: 628
        },
        {
          date: '2021-01-27',
          reviewCount: 5333,
          rating: 4,
          salesRank: 585
        },
        {
          date: '2021-01-28',
          reviewCount: 5333,
          rating: 4,
          salesRank: 534
        },
        {
          date: '2021-01-29',
          reviewCount: 5333,
          rating: 4,
          salesRank: 643
        },
        {
          date: '2021-01-30',
          reviewCount: 5333,
          rating: 4,
          salesRank: 615
        },
        {
          date: '2021-01-31',
          reviewCount: 5333,
          rating: 4,
          salesRank: 634
        },
        {
          date: '2021-02-01',
          reviewCount: 5333,
          rating: 4,
          salesRank: 737
        },
        {
          date: '2021-02-02',
          reviewCount: 5440,
          rating: 4,
          salesRank: 554
        },
        {
          date: '2021-02-03',
          reviewCount: 5440,
          rating: 4,
          salesRank: 521
        },
        {
          date: '2021-02-04',
          reviewCount: 5440,
          rating: 4,
          salesRank: 510
        },
        {
          date: '2021-02-05',
          reviewCount: 5440,
          rating: 4,
          salesRank: 604
        },
        {
          date: '2021-02-06',
          reviewCount: 5440,
          rating: 4,
          salesRank: 639
        },
        {
          date: '2021-02-07',
          reviewCount: 5440,
          rating: 4,
          salesRank: 726
        },
        {
          date: '2021-02-08',
          reviewCount: 5440,
          rating: 4,
          salesRank: 528
        },
        {
          date: '2021-02-09',
          reviewCount: 5573,
          rating: 4,
          salesRank: 570
        },
        {
          date: '2021-02-10',
          reviewCount: 5573,
          rating: 4,
          salesRank: 713
        },
        {
          date: '2021-02-11',
          reviewCount: 5573,
          rating: 4,
          salesRank: 582
        },
        {
          date: '2021-02-12',
          reviewCount: 5573,
          rating: 4,
          salesRank: 544
        },
        {
          date: '2021-02-13',
          reviewCount: 5573,
          rating: 4,
          salesRank: 737
        },
        {
          date: '2021-02-14',
          reviewCount: 5573,
          rating: 4,
          salesRank: 593
        },
        {
          date: '2021-02-15',
          reviewCount: 5573,
          rating: 4,
          salesRank: 612
        },
        {
          date: '2021-02-16',
          reviewCount: 5663,
          rating: 4,
          salesRank: 480
        },
        {
          date: '2021-02-17',
          reviewCount: 5663,
          rating: 4,
          salesRank: 680
        },
        {
          date: '2021-02-18',
          reviewCount: 5663,
          rating: 4,
          salesRank: 490
        },
        {
          date: '2021-02-19',
          reviewCount: 5663,
          rating: 4,
          salesRank: 709
        },
        {
          date: '2021-02-20',
          reviewCount: 5663,
          rating: 4,
          salesRank: 566
        },
        {
          date: '2021-02-21',
          reviewCount: 5663,
          rating: 4,
          salesRank: 565
        },
        {
          date: '2021-02-22',
          reviewCount: 5663,
          rating: 4,
          salesRank: 497
        },
        {
          date: '2021-02-23',
          reviewCount: 5719,
          rating: 4,
          salesRank: 512
        },
        {
          date: '2021-02-24',
          reviewCount: 5719,
          rating: 4,
          salesRank: 532
        },
        {
          date: '2021-02-25',
          reviewCount: 5719,
          rating: 4,
          salesRank: 593
        },
        {
          date: '2021-02-26',
          reviewCount: 5719,
          rating: 4,
          salesRank: 578
        },
        {
          date: '2021-02-27',
          reviewCount: 5719,
          rating: 4,
          salesRank: 468
        },
        {
          date: '2021-02-28',
          reviewCount: 5719,
          rating: 4,
          salesRank: 330
        },
        {
          date: '2021-03-01',
          reviewCount: 5719,
          rating: 4,
          salesRank: 394
        },
        {
          date: '2021-03-02',
          reviewCount: 5769,
          rating: 4,
          salesRank: 523
        },
        {
          date: '2021-03-03',
          reviewCount: 5769,
          rating: 4,
          salesRank: 535
        },
        {
          date: '2021-03-04',
          reviewCount: 5769,
          rating: 4,
          salesRank: 515
        },
        {
          date: '2021-03-05',
          reviewCount: 5769,
          rating: 4,
          salesRank: 449
        },
        {
          date: '2021-03-06',
          reviewCount: 5769,
          rating: 4,
          salesRank: 575
        },
        {
          date: '2021-03-07',
          reviewCount: 5769,
          rating: 4,
          salesRank: 522
        },
        {
          date: '2021-03-08',
          reviewCount: 5769,
          rating: 4,
          salesRank: 477
        },
        {
          date: '2021-03-09',
          reviewCount: 5840,
          rating: 4,
          salesRank: 515
        },
        {
          date: '2021-03-10',
          reviewCount: 5840,
          rating: 4,
          salesRank: 496
        },
        {
          date: '2021-03-11',
          reviewCount: 5840,
          rating: 4,
          salesRank: 523
        },
        {
          date: '2021-03-12',
          reviewCount: 5840,
          rating: 4,
          salesRank: 474
        },
        {
          date: '2021-03-13',
          reviewCount: 5840,
          rating: 4,
          salesRank: 479
        },
        {
          date: '2021-03-14',
          reviewCount: 5840,
          rating: 4,
          salesRank: 455
        },
        {
          date: '2021-03-15',
          reviewCount: 5840,
          rating: 4,
          salesRank: 430
        },
        {
          date: '2021-03-16',
          reviewCount: 5888,
          rating: 4,
          salesRank: 399
        },
        {
          date: '2021-03-17',
          reviewCount: 5888,
          rating: 4,
          salesRank: 545
        },
        {
          date: '2021-03-18',
          reviewCount: 5888,
          rating: 4,
          salesRank: 440
        },
        {
          date: '2021-03-19',
          reviewCount: 5888,
          rating: 4,
          salesRank: 458
        },
        {
          date: '2021-03-20',
          reviewCount: 5888,
          rating: 4,
          salesRank: 473
        },
        {
          date: '2021-03-21',
          reviewCount: 5888,
          rating: 4,
          salesRank: 562
        },
        {
          date: '2021-03-22',
          reviewCount: 5888,
          rating: 4,
          salesRank: 520
        },
        {
          date: '2021-03-23',
          reviewCount: 5888,
          rating: 4,
          salesRank: 530
        },
        {
          date: '2021-03-24',
          reviewCount: 1567,
          rating: 4,
          salesRank: 523
        },
        {
          date: '2021-03-25',
          reviewCount: 1567,
          rating: 4,
          salesRank: 488
        },
        {
          date: '2021-03-26',
          reviewCount: 1567,
          rating: 4,
          salesRank: 510
        },
        {
          date: '2021-03-27',
          reviewCount: 1567,
          rating: 4,
          salesRank: 619
        },
        {
          date: '2021-03-28',
          reviewCount: 1567,
          rating: 4,
          salesRank: 497
        },
        {
          date: '2021-03-29',
          reviewCount: 1567,
          rating: 4,
          salesRank: 489
        },
        {
          date: '2021-03-30',
          reviewCount: 1567,
          rating: 4,
          salesRank: 559
        },
        {
          date: '2021-03-31',
          reviewCount: 1567,
          rating: 4,
          salesRank: 421
        },
        {
          date: '2021-04-01',
          reviewCount: 1567,
          rating: 4,
          salesRank: 465
        },
        {
          date: '2021-04-02',
          reviewCount: 1567,
          rating: 4,
          salesRank: 448
        },
        {
          date: '2021-04-03',
          reviewCount: 1567,
          rating: 4,
          salesRank: 489
        },
        {
          date: '2021-04-04',
          reviewCount: 1609,
          rating: 4,
          salesRank: 436
        },
        {
          date: '2021-04-05',
          reviewCount: 1609,
          rating: 4,
          salesRank: 475
        },
        {
          date: '2021-04-06',
          reviewCount: 1609,
          rating: 4,
          salesRank: 492
        },
        {
          date: '2021-04-07',
          reviewCount: 1609,
          rating: 4,
          salesRank: 378
        },
        {
          date: '2021-04-08',
          reviewCount: 1609,
          rating: 4,
          salesRank: 451
        },
        {
          date: '2021-04-09',
          reviewCount: 1609,
          rating: 4,
          salesRank: 421
        },
        {
          date: '2021-04-10',
          reviewCount: 1609,
          rating: 4,
          salesRank: 536
        },
        {
          date: '2021-04-11',
          reviewCount: 1609,
          rating: 4,
          salesRank: 455
        },
        {
          date: '2021-04-12',
          reviewCount: 1609,
          rating: 4,
          salesRank: 449
        },
        {
          date: '2021-04-13',
          reviewCount: 1609,
          rating: 4,
          salesRank: 466
        },
        {
          date: '2021-04-14',
          reviewCount: 1635,
          rating: 4,
          salesRank: 524
        },
        {
          date: '2021-04-15',
          reviewCount: 1635,
          rating: 4,
          salesRank: 458
        },
        {
          date: '2021-04-16',
          reviewCount: 1635,
          rating: 4,
          salesRank: 400
        },
        {
          date: '2021-04-17',
          reviewCount: 1635,
          rating: 4,
          salesRank: 464
        },
        {
          date: '2021-04-18',
          reviewCount: 1635,
          rating: 4,
          salesRank: 487
        },
        {
          date: '2021-04-19',
          reviewCount: 1635,
          rating: 4,
          salesRank: 432
        },
        {
          date: '2021-04-20',
          reviewCount: 1635,
          rating: 4,
          salesRank: 551
        },
        {
          date: '2021-04-21',
          reviewCount: 1635,
          rating: 4,
          salesRank: 554
        },
        {
          date: '2021-04-22',
          reviewCount: 1635,
          rating: 4,
          salesRank: 533
        },
        {
          date: '2021-04-23',
          reviewCount: 1661,
          rating: 4,
          salesRank: 572
        },
        {
          date: '2021-04-24',
          reviewCount: 1661,
          rating: 4,
          salesRank: 557
        },
        {
          date: '2021-04-25',
          reviewCount: 1661,
          rating: 4,
          salesRank: 549
        },
        {
          date: '2021-04-26',
          reviewCount: 1661,
          rating: 4,
          salesRank: 451
        },
        {
          date: '2021-04-27',
          reviewCount: 1661,
          rating: 4,
          salesRank: 542
        },
        {
          date: '2021-04-28',
          reviewCount: 1661,
          rating: 4,
          salesRank: 563
        },
        {
          date: '2021-04-29',
          reviewCount: 1661,
          rating: 4,
          salesRank: 674
        },
        {
          date: '2021-04-30',
          reviewCount: 1661,
          rating: 4,
          salesRank: 664
        },
        {
          date: '2021-05-01',
          reviewCount: 1661,
          rating: 4,
          salesRank: 534
        },
        {
          date: '2021-05-02',
          reviewCount: 1661,
          rating: 4,
          salesRank: 703
        },
        {
          date: '2021-05-03',
          reviewCount: 1676,
          rating: 4,
          salesRank: 546
        },
        {
          date: '2021-05-04',
          reviewCount: 1676,
          rating: 4,
          salesRank: 489
        },
        {
          date: '2021-05-05',
          reviewCount: 1676,
          rating: 4,
          salesRank: 425
        },
        {
          date: '2021-05-06',
          reviewCount: 1676,
          rating: 4,
          salesRank: 419
        },
        {
          date: '2021-05-07',
          reviewCount: 1676,
          rating: 4,
          salesRank: 487
        },
        {
          date: '2021-05-08',
          reviewCount: 1676,
          rating: 4,
          salesRank: 439
        },
        {
          date: '2021-05-09',
          reviewCount: 1676,
          rating: 4,
          salesRank: 379
        },
        {
          date: '2021-05-10',
          reviewCount: 1691,
          rating: 4,
          salesRank: 417
        },
        {
          date: '2021-05-11',
          reviewCount: 1691,
          rating: 4,
          salesRank: 553
        },
        {
          date: '2021-05-12',
          reviewCount: 1691,
          rating: 4,
          salesRank: 585
        },
        {
          date: '2021-05-13',
          reviewCount: 1691,
          rating: 4,
          salesRank: 471
        },
        {
          date: '2021-05-14',
          reviewCount: 1691,
          rating: 4,
          salesRank: 424
        },
        {
          date: '2021-05-15',
          reviewCount: 1691,
          rating: 4,
          salesRank: 465
        },
        {
          date: '2021-05-16',
          reviewCount: 1691,
          rating: 4,
          salesRank: 508
        },
        {
          date: '2021-05-17',
          reviewCount: 1702,
          rating: 4,
          salesRank: 517
        },
        {
          date: '2021-05-18',
          reviewCount: 1702,
          rating: 4,
          salesRank: 530
        },
        {
          date: '2021-05-19',
          reviewCount: 1702,
          rating: 4,
          salesRank: 507
        },
        {
          date: '2021-05-20',
          reviewCount: 1702,
          rating: 4,
          salesRank: 428
        },
        {
          date: '2021-05-21',
          reviewCount: 1702,
          rating: 4,
          salesRank: 494
        },
        {
          date: '2021-05-22',
          reviewCount: 1702,
          rating: 4,
          salesRank: 420
        },
        {
          date: '2021-05-23',
          reviewCount: 1702,
          rating: 4,
          salesRank: 458
        },
        {
          date: '2021-05-24',
          reviewCount: 1704,
          rating: 4,
          salesRank: 463
        },
        {
          date: '2021-05-25',
          reviewCount: 1704,
          rating: 4,
          salesRank: 539
        },
        {
          date: '2021-05-26',
          reviewCount: 1704,
          rating: 4,
          salesRank: 497
        },
        {
          date: '2021-05-27',
          reviewCount: 1704,
          rating: 4,
          salesRank: 477
        },
        {
          date: '2021-05-28',
          reviewCount: 1704,
          rating: 4,
          salesRank: 611
        },
        {
          date: '2021-05-29',
          reviewCount: 1704,
          rating: 4,
          salesRank: 484
        },
        {
          date: '2021-05-30',
          reviewCount: 1704,
          rating: 4,
          salesRank: 625
        },
        {
          date: '2021-05-31',
          reviewCount: 1704,
          rating: 4,
          salesRank: 528
        },
        {
          date: '2021-06-01',
          reviewCount: 6670,
          rating: 4,
          salesRank: 492
        },
        {
          date: '2021-06-02',
          reviewCount: 6670,
          rating: 4,
          salesRank: 444
        },
        {
          date: '2021-06-03',
          reviewCount: 6670,
          rating: 4,
          salesRank: 483
        },
        {
          date: '2021-06-04',
          reviewCount: 6670,
          rating: 4,
          salesRank: 424
        },
        {
          date: '2021-06-05',
          reviewCount: 6670,
          rating: 4,
          salesRank: 464
        },
        {
          date: '2021-06-06',
          reviewCount: 6670,
          rating: 4,
          salesRank: 452
        },
        {
          date: '2021-06-07',
          reviewCount: 6670,
          rating: 4,
          salesRank: 373
        },
        {
          date: '2021-06-08',
          reviewCount: 6670,
          rating: 4,
          salesRank: 450
        },
        {
          date: '2021-06-09',
          reviewCount: 6715,
          rating: 4,
          salesRank: 390
        },
        {
          date: '2021-06-10',
          reviewCount: 6715,
          rating: 4,
          salesRank: 411
        },
        {
          date: '2021-06-11',
          reviewCount: 6715,
          rating: 4,
          salesRank: 430
        },
        {
          date: '2021-06-12',
          reviewCount: 6715,
          rating: 4,
          salesRank: 518
        },
        {
          date: '2021-06-13',
          reviewCount: 6715,
          rating: 4,
          salesRank: 412
        },
        {
          date: '2021-06-14',
          reviewCount: 6715,
          rating: 4,
          salesRank: 323
        },
        {
          date: '2021-06-15',
          reviewCount: 6715,
          rating: 4,
          salesRank: 392
        },
        {
          date: '2021-06-16',
          reviewCount: 6715,
          rating: 4,
          salesRank: 415
        },
        {
          date: '2021-06-17',
          reviewCount: 6715,
          rating: 4,
          salesRank: 413
        },
        {
          date: '2021-06-18',
          reviewCount: 6792,
          rating: 4,
          salesRank: 409
        },
        {
          date: '2021-06-19',
          reviewCount: 6792,
          rating: 4,
          salesRank: 403
        },
        {
          date: '2021-06-20',
          reviewCount: 6792,
          rating: 4,
          salesRank: 367
        },
        {
          date: '2021-06-21',
          reviewCount: 6792,
          rating: 4,
          salesRank: 550
        },
        {
          date: '2021-06-22',
          reviewCount: 6792,
          rating: 4,
          salesRank: 638
        },
        {
          date: '2021-06-23',
          reviewCount: 6792,
          rating: 4,
          salesRank: 588
        },
        {
          date: '2021-06-24',
          reviewCount: 6792,
          rating: 4,
          salesRank: 484
        },
        {
          date: '2021-06-25',
          reviewCount: 6792,
          rating: 4,
          salesRank: 462
        },
        {
          date: '2021-06-26',
          reviewCount: 6792,
          rating: 4,
          salesRank: 514
        },
        {
          date: '2021-06-27',
          reviewCount: 6792,
          rating: 4,
          salesRank: 454
        },
        {
          date: '2021-06-28',
          reviewCount: 6821,
          rating: 4,
          salesRank: 466
        },
        {
          date: '2021-06-29',
          reviewCount: 6821,
          rating: 4,
          salesRank: 512
        },
        {
          date: '2021-06-30',
          reviewCount: 6821,
          rating: 4,
          salesRank: 385
        },
        {
          date: '2021-07-01',
          reviewCount: 6821,
          rating: 4,
          salesRank: 400
        },
        {
          date: '2021-07-02',
          reviewCount: 6821,
          rating: 4,
          salesRank: 308
        },
        {
          date: '2021-07-03',
          reviewCount: 6821,
          rating: 4,
          salesRank: 301
        },
        {
          date: '2021-07-04',
          reviewCount: 6821,
          rating: 4,
          salesRank: 275
        },
        {
          date: '2021-07-05',
          reviewCount: 6821,
          rating: 4,
          salesRank: 267
        },
        {
          date: '2021-07-06',
          reviewCount: 6821,
          rating: 4,
          salesRank: 255
        },
        {
          date: '2021-07-07',
          reviewCount: 6864,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2021-07-08',
          reviewCount: 6864,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2021-07-09',
          reviewCount: 6864,
          rating: 4,
          salesRank: 234
        },
        {
          date: '2021-07-10',
          reviewCount: 6864,
          rating: 4,
          salesRank: 253
        },
        {
          date: '2021-07-11',
          reviewCount: 6864,
          rating: 4,
          salesRank: 241
        },
        {
          date: '2021-07-12',
          reviewCount: 6864,
          rating: 4,
          salesRank: 229
        },
        {
          date: '2021-07-13',
          reviewCount: 6864,
          rating: 4,
          salesRank: 216
        },
        {
          date: '2021-07-14',
          reviewCount: 6864,
          rating: 4,
          salesRank: 176
        },
        {
          date: '2021-07-15',
          reviewCount: 6904,
          rating: 4,
          salesRank: 199
        },
        {
          date: '2021-07-16',
          reviewCount: 6904,
          rating: 4,
          salesRank: 193
        },
        {
          date: '2021-07-17',
          reviewCount: 6904,
          rating: 4,
          salesRank: 170
        },
        {
          date: '2021-07-18',
          reviewCount: 6904,
          rating: 4,
          salesRank: 152
        },
        {
          date: '2021-07-19',
          reviewCount: 6904,
          rating: 4,
          salesRank: 179
        },
        {
          date: '2021-07-20',
          reviewCount: 6904,
          rating: 4,
          salesRank: 162
        },
        {
          date: '2021-07-21',
          reviewCount: 6904,
          rating: 4,
          salesRank: 151
        },
        {
          date: '2021-07-22',
          reviewCount: 6904,
          rating: 4,
          salesRank: 143
        },
        {
          date: '2021-07-23',
          reviewCount: 6942,
          rating: 4,
          salesRank: 135
        },
        {
          date: '2021-07-24',
          reviewCount: 6942,
          rating: 4,
          salesRank: 134
        },
        {
          date: '2021-07-25',
          reviewCount: 6942,
          rating: 4,
          salesRank: 130
        },
        {
          date: '2021-07-26',
          reviewCount: 6942,
          rating: 4,
          salesRank: 114
        },
        {
          date: '2021-07-27',
          reviewCount: 6942,
          rating: 4,
          salesRank: 66
        },
        {
          date: '2021-07-28',
          reviewCount: 6942,
          rating: 4,
          salesRank: 85
        },
        {
          date: '2021-07-29',
          reviewCount: 6942,
          rating: 4,
          salesRank: 74
        },
        {
          date: '2021-07-30',
          reviewCount: 6942,
          rating: 4,
          salesRank: 81
        },
        {
          date: '2021-07-31',
          reviewCount: 6999,
          rating: 4,
          salesRank: 67
        },
        {
          date: '2021-08-01',
          reviewCount: 6999,
          rating: 4,
          salesRank: 55
        },
        {
          date: '2021-08-02',
          reviewCount: 6999,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2021-08-03',
          reviewCount: 6999,
          rating: 4,
          salesRank: 51
        },
        {
          date: '2021-08-04',
          reviewCount: 6999,
          rating: 4,
          salesRank: 45
        },
        {
          date: '2021-08-05',
          reviewCount: 6999,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2021-08-06',
          reviewCount: 6999,
          rating: 4,
          salesRank: 43
        },
        {
          date: '2021-08-07',
          reviewCount: 7059,
          rating: 4,
          salesRank: 32
        },
        {
          date: '2021-08-08',
          reviewCount: 7059,
          rating: 4,
          salesRank: 30
        },
        {
          date: '2021-08-09',
          reviewCount: 7059,
          rating: 4,
          salesRank: 28
        },
        {
          date: '2021-08-10',
          reviewCount: 7059,
          rating: 4,
          salesRank: 25
        },
        {
          date: '2021-08-11',
          reviewCount: 7059,
          rating: 4,
          salesRank: 19
        },
        {
          date: '2021-08-12',
          reviewCount: 7059,
          rating: 4,
          salesRank: 21
        },
        {
          date: '2021-08-13',
          reviewCount: 7059,
          rating: 4,
          salesRank: 22
        },
        {
          date: '2021-08-14',
          reviewCount: 7128,
          rating: 4,
          salesRank: 24
        },
        {
          date: '2021-08-15',
          reviewCount: 7128,
          rating: 4,
          salesRank: 36
        },
        {
          date: '2021-08-16',
          reviewCount: 7128,
          rating: 4,
          salesRank: 34
        },
        {
          date: '2021-08-17',
          reviewCount: 7128,
          rating: 4,
          salesRank: 37
        },
        {
          date: '2021-08-18',
          reviewCount: 7128,
          rating: 4,
          salesRank: 38
        },
        {
          date: '2021-08-19',
          reviewCount: 7128,
          rating: 4,
          salesRank: 33
        },
        {
          date: '2021-08-20',
          reviewCount: 7128,
          rating: 4,
          salesRank: 40
        },
        {
          date: '2021-08-21',
          reviewCount: 7206,
          rating: 4,
          salesRank: 36
        },
        {
          date: '2021-08-22',
          reviewCount: 7206,
          rating: 4,
          salesRank: 38
        },
        {
          date: '2021-08-23',
          reviewCount: 7206,
          rating: 4,
          salesRank: 52
        },
        {
          date: '2021-08-24',
          reviewCount: 7206,
          rating: 4,
          salesRank: 51
        },
        {
          date: '2021-08-25',
          reviewCount: 7206,
          rating: 4,
          salesRank: 90
        },
        {
          date: '2021-08-26',
          reviewCount: 7206,
          rating: 4,
          salesRank: 61
        },
        {
          date: '2021-08-27',
          reviewCount: 7206,
          rating: 4,
          salesRank: 56
        },
        {
          date: '2021-08-28',
          reviewCount: 7206,
          rating: 4,
          salesRank: 50
        },
        {
          date: '2021-08-29',
          reviewCount: 7303,
          rating: 4,
          salesRank: 58
        },
        {
          date: '2021-08-30',
          reviewCount: 7303,
          rating: 4,
          salesRank: 59
        },
        {
          date: '2021-08-31',
          reviewCount: 7303,
          rating: 4,
          salesRank: 45
        },
        {
          date: '2021-09-01',
          reviewCount: 7303,
          rating: 4,
          salesRank: 44
        },
        {
          date: '2021-09-02',
          reviewCount: 7303,
          rating: 4,
          salesRank: 43
        },
        {
          date: '2021-09-03',
          reviewCount: 7303,
          rating: 4,
          salesRank: 44
        },
        {
          date: '2021-09-04',
          reviewCount: 7303,
          rating: 4,
          salesRank: 42
        },
        {
          date: '2021-09-05',
          reviewCount: 7303,
          rating: 4,
          salesRank: 38
        },
        {
          date: '2021-09-06',
          reviewCount: 7303,
          rating: 4,
          salesRank: 40
        },
        {
          date: '2021-09-07',
          reviewCount: 7303,
          rating: 4,
          salesRank: 38
        },
        {
          date: '2021-09-08',
          reviewCount: 7438,
          rating: 4,
          salesRank: 37
        },
        {
          date: '2021-09-09',
          reviewCount: 7438,
          rating: 4,
          salesRank: 37
        },
        {
          date: '2021-09-10',
          reviewCount: 7438,
          rating: 4,
          salesRank: 41
        },
        {
          date: '2021-09-11',
          reviewCount: 7438,
          rating: 4,
          salesRank: 43
        },
        {
          date: '2021-09-12',
          reviewCount: 7438,
          rating: 4,
          salesRank: 47
        },
        {
          date: '2021-09-13',
          reviewCount: 7438,
          rating: 4,
          salesRank: 53
        },
        {
          date: '2021-09-14',
          reviewCount: 7438,
          rating: 4,
          salesRank: 56
        },
        {
          date: '2021-09-15',
          reviewCount: 7438,
          rating: 4,
          salesRank: 60
        },
        {
          date: '2021-09-16',
          reviewCount: 7438,
          rating: 4,
          salesRank: 80
        },
        {
          date: '2021-09-17',
          reviewCount: 7438,
          rating: 4,
          salesRank: 62
        },
        {
          date: '2021-09-18',
          reviewCount: 7438,
          rating: 4,
          salesRank: 80
        },
        {
          date: '2021-09-19',
          reviewCount: 7438,
          rating: 4,
          salesRank: 68
        },
        {
          date: '2021-09-20',
          reviewCount: 8538,
          rating: 4,
          salesRank: 64
        },
        {
          date: '2021-09-21',
          reviewCount: 8538,
          rating: 4,
          salesRank: 69
        },
        {
          date: '2021-09-22',
          reviewCount: 8538,
          rating: 4,
          salesRank: 95
        },
        {
          date: '2021-09-23',
          reviewCount: 8538,
          rating: 4,
          salesRank: 86
        },
        {
          date: '2021-09-24',
          reviewCount: 8538,
          rating: 4,
          salesRank: 92
        },
        {
          date: '2021-09-25',
          reviewCount: 8538,
          rating: 4,
          salesRank: 98
        },
        {
          date: '2021-09-26',
          reviewCount: 8538,
          rating: 4,
          salesRank: 121
        },
        {
          date: '2021-09-27',
          reviewCount: 8538,
          rating: 4,
          salesRank: 117
        },
        {
          date: '2021-09-28',
          reviewCount: 8538,
          rating: 4,
          salesRank: 88
        },
        {
          date: '2021-09-29',
          reviewCount: 8538,
          rating: 4,
          salesRank: 90
        },
        {
          date: '2021-09-30',
          reviewCount: 8538,
          rating: 4,
          salesRank: 113
        },
        {
          date: '2021-10-01',
          reviewCount: 8538,
          rating: 4,
          salesRank: 99
        },
        {
          date: '2021-10-02',
          reviewCount: 8698,
          rating: 4,
          salesRank: 139
        },
        {
          date: '2021-10-03',
          reviewCount: 8698,
          rating: 4,
          salesRank: 134
        },
        {
          date: '2021-10-04',
          reviewCount: 8698,
          rating: 4,
          salesRank: 114
        },
        {
          date: '2021-10-05',
          reviewCount: 8698,
          rating: 4,
          salesRank: 133
        },
        {
          date: '2021-10-06',
          reviewCount: 8698,
          rating: 4,
          salesRank: 103
        },
        {
          date: '2021-10-07',
          reviewCount: 8698,
          rating: 4,
          salesRank: 116
        },
        {
          date: '2021-10-08',
          reviewCount: 8698,
          rating: 4,
          salesRank: 109
        },
        {
          date: '2021-10-09',
          reviewCount: 8801,
          rating: 4,
          salesRank: 85
        },
        {
          date: '2021-10-10',
          reviewCount: 8801,
          rating: 4,
          salesRank: 90
        },
        {
          date: '2021-10-11',
          reviewCount: 8801,
          rating: 4,
          salesRank: 97
        },
        {
          date: '2021-10-12',
          reviewCount: 8801,
          rating: 4,
          salesRank: 88
        },
        {
          date: '2021-10-13',
          reviewCount: 8801,
          rating: 4,
          salesRank: 113
        },
        {
          date: '2021-10-14',
          reviewCount: 8801,
          rating: 4,
          salesRank: 109
        },
        {
          date: '2021-10-15',
          reviewCount: 8801,
          rating: 4,
          salesRank: 132
        },
        {
          date: '2021-10-16',
          reviewCount: 8907,
          rating: 4,
          salesRank: 163
        },
        {
          date: '2021-10-17',
          reviewCount: 8907,
          rating: 4,
          salesRank: 157
        },
        {
          date: '2021-10-18',
          reviewCount: 8907,
          rating: 4,
          salesRank: 179
        },
        {
          date: '2021-10-19',
          reviewCount: 8907,
          rating: 4,
          salesRank: 171
        },
        {
          date: '2021-10-20',
          reviewCount: 8907,
          rating: 4,
          salesRank: 187
        },
        {
          date: '2021-10-21',
          reviewCount: 8907,
          rating: 4,
          salesRank: 217
        },
        {
          date: '2021-10-22',
          reviewCount: 8907,
          rating: 4,
          salesRank: 293
        },
        {
          date: '2021-10-23',
          reviewCount: 8907,
          rating: 4,
          salesRank: 269
        },
        {
          date: '2021-10-24',
          reviewCount: 9006,
          rating: 4,
          salesRank: 259
        },
        {
          date: '2021-10-25',
          reviewCount: 9006,
          rating: 4,
          salesRank: 265
        },
        {
          date: '2021-10-26',
          reviewCount: 9006,
          rating: 4,
          salesRank: 317
        },
        {
          date: '2021-10-27',
          reviewCount: 9006,
          rating: 4,
          salesRank: 300
        },
        {
          date: '2021-10-28',
          reviewCount: 9006,
          rating: 4,
          salesRank: 293
        },
        {
          date: '2021-10-29',
          reviewCount: 9006,
          rating: 4,
          salesRank: 321
        },
        {
          date: '2021-10-30',
          reviewCount: 9006,
          rating: 4,
          salesRank: 279
        },
        {
          date: '2021-10-31',
          reviewCount: 9006,
          rating: 4,
          salesRank: 242
        },
        {
          date: '2021-11-01',
          reviewCount: 9006,
          rating: 4,
          salesRank: 190
        },
        {
          date: '2021-11-02',
          reviewCount: 9122,
          rating: 4,
          salesRank: 236
        },
        {
          date: '2021-11-03',
          reviewCount: 9122,
          rating: 4,
          salesRank: 211
        },
        {
          date: '2021-11-04',
          reviewCount: 9122,
          rating: 4,
          salesRank: 207
        },
        {
          date: '2021-11-05',
          reviewCount: 9122,
          rating: 4,
          salesRank: 210
        },
        {
          date: '2021-11-06',
          reviewCount: 9122,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2021-11-07',
          reviewCount: 9122,
          rating: 4,
          salesRank: 240
        },
        {
          date: '2021-11-08',
          reviewCount: 9122,
          rating: 4,
          salesRank: 220
        },
        {
          date: '2021-11-09',
          reviewCount: 9122,
          rating: 4,
          salesRank: 200
        },
        {
          date: '2021-11-10',
          reviewCount: 9122,
          rating: 4,
          salesRank: 208
        },
        {
          date: '2021-11-11',
          reviewCount: 9216,
          rating: 4,
          salesRank: 194
        },
        {
          date: '2021-11-12',
          reviewCount: 9216,
          rating: 4,
          salesRank: 224
        },
        {
          date: '2021-11-13',
          reviewCount: 9216,
          rating: 4,
          salesRank: 206
        },
        {
          date: '2021-11-14',
          reviewCount: 9216,
          rating: 4,
          salesRank: 197
        },
        {
          date: '2021-11-15',
          reviewCount: 9216,
          rating: 4,
          salesRank: 171
        },
        {
          date: '2021-11-16',
          reviewCount: 9216,
          rating: 4,
          salesRank: 143
        },
        {
          date: '2021-11-17',
          reviewCount: 9216,
          rating: 4,
          salesRank: 153
        },
        {
          date: '2021-11-18',
          reviewCount: 9216,
          rating: 4,
          salesRank: 198
        },
        {
          date: '2021-11-19',
          reviewCount: 9328,
          rating: 4,
          salesRank: 197
        },
        {
          date: '2021-11-20',
          reviewCount: 9328,
          rating: 4,
          salesRank: 204
        },
        {
          date: '2021-11-21',
          reviewCount: 9328,
          rating: 4,
          salesRank: 176
        },
        {
          date: '2021-11-22',
          reviewCount: 9328,
          rating: 4,
          salesRank: 212
        },
        {
          date: '2021-11-23',
          reviewCount: 9328,
          rating: 4,
          salesRank: 204
        },
        {
          date: '2021-11-24',
          reviewCount: 9328,
          rating: 4,
          salesRank: 283
        },
        {
          date: '2021-11-25',
          reviewCount: 9328,
          rating: 4,
          salesRank: 271
        },
        {
          date: '2021-11-26',
          reviewCount: 9404,
          rating: 4,
          salesRank: 382
        },
        {
          date: '2021-11-27',
          reviewCount: 9404,
          rating: 4,
          salesRank: 421
        },
        {
          date: '2021-11-28',
          reviewCount: 9404,
          rating: 4,
          salesRank: 352
        },
        {
          date: '2021-11-29',
          reviewCount: 9404,
          rating: 4,
          salesRank: 327
        },
        {
          date: '2021-11-30',
          reviewCount: 9404,
          rating: 4,
          salesRank: 433
        },
        {
          date: '2021-12-01',
          reviewCount: 9404,
          rating: 4,
          salesRank: 311
        },
        {
          date: '2021-12-02',
          reviewCount: 9404,
          rating: 4,
          salesRank: 240
        },
        {
          date: '2021-12-03',
          reviewCount: 9427,
          rating: 4,
          salesRank: 245
        },
        {
          date: '2021-12-04',
          reviewCount: 9427,
          rating: 4,
          salesRank: 252
        },
        {
          date: '2021-12-05',
          reviewCount: 9427,
          rating: 4,
          salesRank: 230
        },
        {
          date: '2021-12-06',
          reviewCount: 9427,
          rating: 4,
          salesRank: 247
        },
        {
          date: '2021-12-07',
          reviewCount: 9427,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2021-12-08',
          reviewCount: 9427,
          rating: 4,
          salesRank: 255
        },
        {
          date: '2021-12-09',
          reviewCount: 9427,
          rating: 4,
          salesRank: 257
        },
        {
          date: '2021-12-10',
          reviewCount: 9427,
          rating: 4
        },
        {
          date: '2021-12-11',
          reviewCount: 9427,
          rating: 4,
          salesRank: 313
        },
        {
          date: '2021-12-12',
          reviewCount: 9427,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2021-12-13',
          reviewCount: 9525,
          rating: 4,
          salesRank: 259
        },
        {
          date: '2021-12-14',
          reviewCount: 9525,
          rating: 4,
          salesRank: 253
        },
        {
          date: '2021-12-15',
          reviewCount: 9525,
          rating: 4,
          salesRank: 286
        },
        {
          date: '2021-12-16',
          reviewCount: 9525,
          rating: 4,
          salesRank: 282
        },
        {
          date: '2021-12-17',
          reviewCount: 9525,
          rating: 4,
          salesRank: 313
        },
        {
          date: '2021-12-18',
          reviewCount: 9525,
          rating: 4,
          salesRank: 340
        },
        {
          date: '2021-12-19',
          reviewCount: 9525,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2021-12-20',
          reviewCount: 9585,
          rating: 4,
          salesRank: 365
        },
        {
          date: '2021-12-21',
          reviewCount: 9585,
          rating: 4,
          salesRank: 340
        },
        {
          date: '2021-12-22',
          reviewCount: 9585,
          rating: 4,
          salesRank: 372
        },
        {
          date: '2021-12-23',
          reviewCount: 9585,
          rating: 4,
          salesRank: 394
        },
        {
          date: '2021-12-24',
          reviewCount: 9585,
          rating: 4,
          salesRank: 414
        },
        {
          date: '2021-12-25',
          reviewCount: 9585,
          rating: 4,
          salesRank: 426
        },
        {
          date: '2021-12-26',
          reviewCount: 9585,
          rating: 4,
          salesRank: 539
        },
        {
          date: '2021-12-27',
          reviewCount: 9646,
          rating: 4,
          salesRank: 468
        },
        {
          date: '2021-12-28',
          reviewCount: 9646,
          rating: 4,
          salesRank: 558
        },
        {
          date: '2021-12-29',
          reviewCount: 9646,
          rating: 4,
          salesRank: 440
        },
        {
          date: '2021-12-30',
          reviewCount: 9646,
          rating: 4,
          salesRank: 441
        },
        {
          date: '2021-12-31',
          reviewCount: 9646,
          rating: 4,
          salesRank: 421
        },
        {
          date: '2022-01-01',
          reviewCount: 9646,
          rating: 4,
          salesRank: 486
        },
        {
          date: '2022-01-02',
          reviewCount: 9646,
          rating: 4,
          salesRank: 447
        },
        {
          date: '2022-01-03',
          reviewCount: 8563,
          rating: 4,
          salesRank: 523
        },
        {
          date: '2022-01-04',
          reviewCount: 8563,
          rating: 4,
          salesRank: 528
        },
        {
          date: '2022-01-05',
          reviewCount: 8563,
          rating: 4,
          salesRank: 649
        },
        {
          date: '2022-01-06',
          reviewCount: 8563,
          rating: 4,
          salesRank: 684
        },
        {
          date: '2022-01-07',
          reviewCount: 8563,
          rating: 4,
          salesRank: 961
        },
        {
          date: '2022-01-08',
          reviewCount: 8563,
          rating: 4,
          salesRank: 949
        },
        {
          date: '2022-01-09',
          reviewCount: 8563,
          rating: 4,
          salesRank: 1156
        },
        {
          date: '2022-01-10',
          reviewCount: 8620,
          rating: 4,
          salesRank: 1166
        },
        {
          date: '2022-01-11',
          reviewCount: 8620,
          rating: 4,
          salesRank: 1012
        },
        {
          date: '2022-01-12',
          reviewCount: 8620,
          rating: 4,
          salesRank: 1193
        },
        {
          date: '2022-01-13',
          reviewCount: 8620,
          rating: 4,
          salesRank: 1066
        },
        {
          date: '2022-01-14',
          reviewCount: 8620,
          rating: 4,
          salesRank: 973
        },
        {
          date: '2022-01-15',
          reviewCount: 8620,
          rating: 4,
          salesRank: 804
        },
        {
          date: '2022-01-16',
          reviewCount: 8620,
          rating: 4,
          salesRank: 739
        },
        {
          date: '2022-01-17',
          reviewCount: 8674,
          rating: 4,
          salesRank: 563
        },
        {
          date: '2022-01-18',
          reviewCount: 8674,
          rating: 4,
          salesRank: 524
        },
        {
          date: '2022-01-19',
          reviewCount: 8674,
          rating: 4,
          salesRank: 514
        },
        {
          date: '2022-01-20',
          reviewCount: 8674,
          rating: 4,
          salesRank: 614
        },
        {
          date: '2022-01-21',
          reviewCount: 8674,
          rating: 4,
          salesRank: 369
        },
        {
          date: '2022-01-22',
          reviewCount: 8674,
          rating: 4,
          salesRank: 330
        },
        {
          date: '2022-01-23',
          reviewCount: 8674,
          rating: 4,
          salesRank: 321
        },
        {
          date: '2022-01-24',
          reviewCount: 8740,
          rating: 4,
          salesRank: 391
        },
        {
          date: '2022-01-25',
          reviewCount: 8740,
          rating: 4,
          salesRank: 392
        },
        {
          date: '2022-01-26',
          reviewCount: 8740,
          rating: 4,
          salesRank: 391
        },
        {
          date: '2022-01-27',
          reviewCount: 8740,
          rating: 4,
          salesRank: 351
        },
        {
          date: '2022-01-28',
          reviewCount: 8740,
          rating: 4,
          salesRank: 327
        },
        {
          date: '2022-01-29',
          reviewCount: 8740,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2022-01-30',
          reviewCount: 8740,
          rating: 4,
          salesRank: 300
        },
        {
          date: '2022-01-31',
          reviewCount: 8779,
          rating: 4,
          salesRank: 266
        },
        {
          date: '2022-02-01',
          reviewCount: 8779,
          rating: 4,
          salesRank: 232
        },
        {
          date: '2022-02-02',
          reviewCount: 8779,
          rating: 4,
          salesRank: 271
        },
        {
          date: '2022-02-03',
          reviewCount: 8779,
          rating: 4,
          salesRank: 294
        },
        {
          date: '2022-02-04',
          reviewCount: 8779,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2022-02-05',
          reviewCount: 8779,
          rating: 4,
          salesRank: 213
        },
        {
          date: '2022-02-06',
          reviewCount: 8779,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2022-02-07',
          reviewCount: 8807,
          rating: 4,
          salesRank: 311
        },
        {
          date: '2022-02-08',
          reviewCount: 8807,
          rating: 4,
          salesRank: 354
        },
        {
          date: '2022-02-09',
          reviewCount: 8807,
          rating: 4,
          salesRank: 387
        },
        {
          date: '2022-02-10',
          reviewCount: 8807,
          rating: 4,
          salesRank: 432
        },
        {
          date: '2022-02-11',
          reviewCount: 8807,
          rating: 4,
          salesRank: 478
        },
        {
          date: '2022-02-12',
          reviewCount: 8807,
          rating: 4,
          salesRank: 455
        },
        {
          date: '2022-02-13',
          reviewCount: 8807,
          rating: 4,
          salesRank: 353
        },
        {
          date: '2022-02-14',
          reviewCount: 8847,
          rating: 4,
          salesRank: 328
        },
        {
          date: '2022-02-15',
          reviewCount: 8847,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2022-02-16',
          reviewCount: 8847,
          rating: 4,
          salesRank: 376
        },
        {
          date: '2022-02-17',
          reviewCount: 8847,
          rating: 4,
          salesRank: 368
        },
        {
          date: '2022-02-18',
          reviewCount: 8847,
          rating: 4,
          salesRank: 463
        },
        {
          date: '2022-02-19',
          reviewCount: 8847,
          rating: 4,
          salesRank: 374
        },
        {
          date: '2022-02-20',
          reviewCount: 8847,
          rating: 4,
          salesRank: 331
        },
        {
          date: '2022-02-21',
          reviewCount: 8847,
          rating: 4,
          salesRank: 395
        },
        {
          date: '2022-02-22',
          reviewCount: 8890,
          rating: 4,
          salesRank: 286
        },
        {
          date: '2022-02-23',
          reviewCount: 8890,
          rating: 4,
          salesRank: 236
        },
        {
          date: '2022-02-24',
          reviewCount: 8890,
          rating: 4,
          salesRank: 284
        },
        {
          date: '2022-02-25',
          reviewCount: 8890,
          rating: 4,
          salesRank: 229
        },
        {
          date: '2022-02-26',
          reviewCount: 8890,
          rating: 4,
          salesRank: 280
        },
        {
          date: '2022-02-27',
          reviewCount: 8890,
          rating: 4,
          salesRank: 216
        },
        {
          date: '2022-02-28',
          reviewCount: 8890,
          rating: 4,
          salesRank: 237
        },
        {
          date: '2022-03-01',
          reviewCount: 8934,
          rating: 4,
          salesRank: 239
        },
        {
          date: '2022-03-02',
          reviewCount: 8934,
          rating: 4,
          salesRank: 267
        },
        {
          date: '2022-03-03',
          reviewCount: 8934,
          rating: 4,
          salesRank: 234
        },
        {
          date: '2022-03-04',
          reviewCount: 8934,
          rating: 4,
          salesRank: 267
        },
        {
          date: '2022-03-05',
          reviewCount: 8934,
          rating: 4,
          salesRank: 271
        },
        {
          date: '2022-03-06',
          reviewCount: 8934,
          rating: 4,
          salesRank: 274
        },
        {
          date: '2022-03-07',
          reviewCount: 8934,
          rating: 4,
          salesRank: 245
        },
        {
          date: '2022-03-08',
          reviewCount: 8984,
          rating: 4,
          salesRank: 227
        },
        {
          date: '2022-03-09',
          reviewCount: 8984,
          rating: 4,
          salesRank: 286
        },
        {
          date: '2022-03-10',
          reviewCount: 8984,
          rating: 4,
          salesRank: 301
        },
        {
          date: '2022-03-11',
          reviewCount: 8984,
          rating: 4,
          salesRank: 341
        },
        {
          date: '2022-03-12',
          reviewCount: 8984,
          rating: 4,
          salesRank: 277
        },
        {
          date: '2022-03-13',
          reviewCount: 8984,
          rating: 4,
          salesRank: 293
        },
        {
          date: '2022-03-14',
          reviewCount: 8984,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2022-03-15',
          reviewCount: 9017,
          rating: 4,
          salesRank: 278
        },
        {
          date: '2022-03-16',
          reviewCount: 9017,
          rating: 4,
          salesRank: 327
        },
        {
          date: '2022-03-17',
          reviewCount: 9017,
          rating: 4,
          salesRank: 299
        },
        {
          date: '2022-03-18',
          reviewCount: 9017,
          rating: 4,
          salesRank: 280
        },
        {
          date: '2022-03-19',
          reviewCount: 9017,
          rating: 4,
          salesRank: 356
        },
        {
          date: '2022-03-20',
          reviewCount: 9017,
          rating: 4,
          salesRank: 293
        },
        {
          date: '2022-03-21',
          reviewCount: 9017,
          rating: 4,
          salesRank: 290
        },
        {
          date: '2022-03-22',
          reviewCount: 9065,
          rating: 4,
          salesRank: 292
        },
        {
          date: '2022-03-23',
          reviewCount: 9065,
          rating: 4,
          salesRank: 259
        },
        {
          date: '2022-03-24',
          reviewCount: 9065,
          rating: 4,
          salesRank: 219
        },
        {
          date: '2022-03-25',
          reviewCount: 9065,
          rating: 4,
          salesRank: 213
        },
        {
          date: '2022-03-26',
          reviewCount: 9065,
          rating: 4,
          salesRank: 264
        },
        {
          date: '2022-03-27',
          reviewCount: 9065,
          rating: 4,
          salesRank: 206
        },
        {
          date: '2022-03-28',
          reviewCount: 9065,
          rating: 4,
          salesRank: 261
        },
        {
          date: '2022-03-29',
          reviewCount: 9116,
          rating: 4,
          salesRank: 301
        },
        {
          date: '2022-03-30',
          reviewCount: 9116,
          rating: 4,
          salesRank: 324
        },
        {
          date: '2022-03-31',
          reviewCount: 9116,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2022-04-01',
          reviewCount: 9116,
          rating: 4,
          salesRank: 261
        },
        {
          date: '2022-04-02',
          reviewCount: 9116,
          rating: 4,
          salesRank: 316
        },
        {
          date: '2022-04-03',
          reviewCount: 9116,
          rating: 4,
          salesRank: 323
        },
        {
          date: '2022-04-04',
          reviewCount: 9116,
          rating: 4,
          salesRank: 288
        },
        {
          date: '2022-04-05',
          reviewCount: 9142,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2022-04-06',
          reviewCount: 9142,
          rating: 4,
          salesRank: 273
        },
        {
          date: '2022-04-07',
          reviewCount: 9142,
          rating: 4,
          salesRank: 274
        },
        {
          date: '2022-04-08',
          reviewCount: 9142,
          rating: 4,
          salesRank: 364
        },
        {
          date: '2022-04-09',
          reviewCount: 9142,
          rating: 4,
          salesRank: 309
        },
        {
          date: '2022-04-10',
          reviewCount: 9142,
          rating: 4,
          salesRank: 318
        },
        {
          date: '2022-04-11',
          reviewCount: 9142,
          rating: 4,
          salesRank: 316
        },
        {
          date: '2022-04-12',
          reviewCount: 9142,
          rating: 4,
          salesRank: 270
        },
        {
          date: '2022-04-13',
          reviewCount: 9192,
          rating: 4,
          salesRank: 283
        },
        {
          date: '2022-04-14',
          reviewCount: 9192,
          rating: 4,
          salesRank: 388
        },
        {
          date: '2022-04-15',
          reviewCount: 9192,
          rating: 4,
          salesRank: 444
        },
        {
          date: '2022-04-16',
          reviewCount: 9192,
          rating: 4,
          salesRank: 350
        },
        {
          date: '2022-04-17',
          reviewCount: 9192,
          rating: 4,
          salesRank: 409
        },
        {
          date: '2022-04-18',
          reviewCount: 9192,
          rating: 4,
          salesRank: 454
        },
        {
          date: '2022-04-19',
          reviewCount: 9192,
          rating: 4,
          salesRank: 413
        },
        {
          date: '2022-04-20',
          reviewCount: 9192,
          rating: 4,
          salesRank: 367
        },
        {
          date: '2022-04-21',
          reviewCount: 9229,
          rating: 4,
          salesRank: 306
        },
        {
          date: '2022-04-22',
          reviewCount: 9229,
          rating: 4,
          salesRank: 349
        },
        {
          date: '2022-04-23',
          reviewCount: 9229,
          rating: 4,
          salesRank: 387
        },
        {
          date: '2022-04-24',
          reviewCount: 9229,
          rating: 4,
          salesRank: 409
        },
        {
          date: '2022-04-25',
          reviewCount: 9229,
          rating: 4,
          salesRank: 485
        },
        {
          date: '2022-04-26',
          reviewCount: 9229,
          rating: 4,
          salesRank: 540
        },
        {
          date: '2022-04-27',
          reviewCount: 9229,
          rating: 4,
          salesRank: 647
        },
        {
          date: '2022-04-28',
          reviewCount: 9272,
          rating: 4,
          salesRank: 575
        },
        {
          date: '2022-04-29',
          reviewCount: 9272,
          rating: 4,
          salesRank: 565
        },
        {
          date: '2022-04-30',
          reviewCount: 9272,
          rating: 4,
          salesRank: 587
        },
        {
          date: '2022-05-01',
          reviewCount: 9272,
          rating: 4,
          salesRank: 507
        },
        {
          date: '2022-05-02',
          reviewCount: 9272,
          rating: 4,
          salesRank: 470
        },
        {
          date: '2022-05-03',
          reviewCount: 9272,
          rating: 4,
          salesRank: 420
        },
        {
          date: '2022-05-04',
          reviewCount: 9272,
          rating: 4,
          salesRank: 448
        },
        {
          date: '2022-05-05',
          reviewCount: 9307,
          rating: 4,
          salesRank: 368
        },
        {
          date: '2022-05-06',
          reviewCount: 9307,
          rating: 4,
          salesRank: 348
        },
        {
          date: '2022-05-07',
          reviewCount: 9307,
          rating: 4,
          salesRank: 324
        },
        {
          date: '2022-05-08',
          reviewCount: 9307,
          rating: 4,
          salesRank: 350
        },
        {
          date: '2022-05-09',
          reviewCount: 9307,
          rating: 4,
          salesRank: 337
        },
        {
          date: '2022-05-10',
          reviewCount: 9307,
          rating: 4,
          salesRank: 336
        },
        {
          date: '2022-05-11',
          reviewCount: 9307,
          rating: 4,
          salesRank: 365
        },
        {
          date: '2022-05-12',
          reviewCount: 9340,
          rating: 4,
          salesRank: 349
        },
        {
          date: '2022-05-13',
          reviewCount: 9340,
          rating: 4,
          salesRank: 396
        },
        {
          date: '2022-05-14',
          reviewCount: 9340,
          rating: 4,
          salesRank: 384
        },
        {
          date: '2022-05-15',
          reviewCount: 9340,
          rating: 4,
          salesRank: 383
        },
        {
          date: '2022-05-16',
          reviewCount: 9340,
          rating: 4,
          salesRank: 288
        },
        {
          date: '2022-05-17',
          reviewCount: 9340,
          rating: 4,
          salesRank: 332
        },
        {
          date: '2022-05-18',
          reviewCount: 9340,
          rating: 4,
          salesRank: 392
        },
        {
          date: '2022-05-19',
          reviewCount: 9402,
          rating: 4,
          salesRank: 356
        },
        {
          date: '2022-05-20',
          reviewCount: 9402,
          rating: 4,
          salesRank: 380
        },
        {
          date: '2022-05-21',
          reviewCount: 9402,
          rating: 4,
          salesRank: 338
        },
        {
          date: '2022-05-22',
          reviewCount: 9402,
          rating: 4,
          salesRank: 384
        },
        {
          date: '2022-05-23',
          reviewCount: 9402,
          rating: 4,
          salesRank: 326
        },
        {
          date: '2022-05-24',
          reviewCount: 9402,
          rating: 4,
          salesRank: 299
        },
        {
          date: '2022-05-25',
          reviewCount: 9402,
          rating: 4,
          salesRank: 289
        },
        {
          date: '2022-05-26',
          reviewCount: 9402,
          rating: 4,
          salesRank: 339
        },
        {
          date: '2022-05-27',
          reviewCount: 9402,
          rating: 4,
          salesRank: 425
        },
        {
          date: '2022-05-28',
          reviewCount: 9402,
          rating: 4,
          salesRank: 461
        },
        {
          date: '2022-05-29',
          reviewCount: 9464,
          rating: 4,
          salesRank: 323
        },
        {
          date: '2022-05-30',
          reviewCount: 9464,
          rating: 4
        },
        {
          date: '2022-05-31',
          reviewCount: 9464,
          rating: 4,
          salesRank: 383
        },
        {
          date: '2022-06-01',
          reviewCount: 9464,
          rating: 4,
          salesRank: 400
        },
        {
          date: '2022-06-02',
          reviewCount: 9464,
          rating: 4,
          salesRank: 332
        },
        {
          date: '2022-06-03',
          reviewCount: 9464,
          rating: 4,
          salesRank: 364
        },
        {
          date: '2022-06-04',
          reviewCount: 9464,
          rating: 4,
          salesRank: 361
        },
        {
          date: '2022-06-05',
          reviewCount: 9464,
          rating: 4,
          salesRank: 348
        },
        {
          date: '2022-06-06',
          reviewCount: 9464,
          rating: 4,
          salesRank: 333
        },
        {
          date: '2022-06-07',
          reviewCount: 9464,
          rating: 4,
          salesRank: 332
        },
        {
          date: '2022-06-08',
          reviewCount: 9525,
          rating: 4,
          salesRank: 357
        },
        {
          date: '2022-06-09',
          reviewCount: 9525,
          rating: 4,
          salesRank: 405
        },
        {
          date: '2022-06-10',
          reviewCount: 9525,
          rating: 4,
          salesRank: 456
        },
        {
          date: '2022-06-11',
          reviewCount: 9525,
          rating: 4,
          salesRank: 415
        },
        {
          date: '2022-06-12',
          reviewCount: 9525,
          rating: 4,
          salesRank: 442
        },
        {
          date: '2022-06-13',
          reviewCount: 9525,
          rating: 4,
          salesRank: 358
        },
        {
          date: '2022-06-14',
          reviewCount: 9525,
          rating: 4,
          salesRank: 443
        },
        {
          date: '2022-06-15',
          reviewCount: 9525,
          rating: 4,
          salesRank: 400
        },
        {
          date: '2022-06-16',
          reviewCount: 9525,
          rating: 4,
          salesRank: 382
        },
        {
          date: '2022-06-17',
          reviewCount: 9563,
          rating: 4
        },
        {
          date: '2022-06-18',
          reviewCount: 9563,
          rating: 4,
          salesRank: 376
        },
        {
          date: '2022-06-19',
          reviewCount: 9563,
          rating: 4,
          salesRank: 334
        },
        {
          date: '2022-06-20',
          reviewCount: 9563,
          rating: 4,
          salesRank: 328
        },
        {
          date: '2022-06-21',
          reviewCount: 9563,
          rating: 4,
          salesRank: 283
        },
        {
          date: '2022-06-22',
          reviewCount: 9563,
          rating: 4,
          salesRank: 313
        },
        {
          date: '2022-06-23',
          reviewCount: 9563,
          rating: 4,
          salesRank: 343
        },
        {
          date: '2022-06-24',
          reviewCount: 9563,
          rating: 4,
          salesRank: 348
        },
        {
          date: '2022-06-25',
          reviewCount: 9563,
          rating: 4,
          salesRank: 399
        },
        {
          date: '2022-06-26',
          reviewCount: 9563,
          rating: 4,
          salesRank: 367
        },
        {
          date: '2022-06-27',
          reviewCount: 9619,
          rating: 4,
          salesRank: 376
        },
        {
          date: '2022-06-28',
          reviewCount: 9619,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2022-06-29',
          reviewCount: 9619,
          rating: 4,
          salesRank: 339
        },
        {
          date: '2022-06-30',
          reviewCount: 9619,
          rating: 4
        },
        {
          date: '2022-07-01',
          reviewCount: 9619,
          rating: 4,
          salesRank: 256
        },
        {
          date: '2022-07-02',
          reviewCount: 9619,
          rating: 4,
          salesRank: 281
        },
        {
          date: '2022-07-03',
          reviewCount: 9619,
          rating: 4,
          salesRank: 250
        },
        {
          date: '2022-07-04',
          reviewCount: 9619,
          rating: 4,
          salesRank: 255
        },
        {
          date: '2022-07-05',
          reviewCount: 9619,
          rating: 4,
          salesRank: 270
        },
        {
          date: '2022-07-06',
          reviewCount: 9619,
          rating: 4,
          salesRank: 281
        },
        {
          date: '2022-07-07',
          reviewCount: 9672,
          rating: 4,
          salesRank: 209
        },
        {
          date: '2022-07-08',
          reviewCount: 9672,
          rating: 4,
          salesRank: 275
        },
        {
          date: '2022-07-09',
          reviewCount: 9672,
          rating: 4,
          salesRank: 258
        },
        {
          date: '2022-07-10',
          reviewCount: 9672,
          rating: 4,
          salesRank: 233
        },
        {
          date: '2022-07-11',
          reviewCount: 9672,
          rating: 4,
          salesRank: 203
        },
        {
          date: '2022-07-12',
          reviewCount: 9672,
          rating: 4,
          salesRank: 337
        },
        {
          date: '2022-07-13',
          reviewCount: 9672,
          rating: 4,
          salesRank: 365
        },
        {
          date: '2022-07-14',
          reviewCount: 9672,
          rating: 4,
          salesRank: 370
        },
        {
          date: '2022-07-15',
          reviewCount: 9672,
          rating: 4,
          salesRank: 333
        },
        {
          date: '2022-07-16',
          reviewCount: 9672,
          rating: 4,
          salesRank: 292
        },
        {
          date: '2022-07-17',
          reviewCount: 9672,
          rating: 4,
          salesRank: 259
        },
        {
          date: '2022-07-18',
          reviewCount: 9672,
          rating: 4,
          salesRank: 228
        },
        {
          date: '2022-07-19',
          reviewCount: 9724,
          rating: 4,
          salesRank: 199
        },
        {
          date: '2022-07-20',
          reviewCount: 9724,
          rating: 4,
          salesRank: 198
        },
        {
          date: '2022-07-21',
          reviewCount: 9724,
          rating: 4,
          salesRank: 201
        },
        {
          date: '2022-07-22',
          reviewCount: 9724,
          rating: 4,
          salesRank: 205
        },
        {
          date: '2022-07-23',
          reviewCount: 9724,
          rating: 4,
          salesRank: 204
        },
        {
          date: '2022-07-24',
          reviewCount: 9724,
          rating: 4,
          salesRank: 199
        },
        {
          date: '2022-07-25',
          reviewCount: 9724,
          rating: 4,
          salesRank: 202
        },
        {
          date: '2022-07-26',
          reviewCount: 9724,
          rating: 4,
          salesRank: 210
        },
        {
          date: '2022-07-27',
          reviewCount: 9724,
          rating: 4,
          salesRank: 206
        },
        {
          date: '2022-07-28',
          reviewCount: 9796,
          rating: 4,
          salesRank: 208
        },
        {
          date: '2022-07-29',
          reviewCount: 9796,
          rating: 4,
          salesRank: 204
        },
        {
          date: '2022-07-30',
          reviewCount: 9796,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2022-07-31',
          reviewCount: 9796,
          rating: 4,
          salesRank: 199
        },
        {
          date: '2022-08-01',
          reviewCount: 9796,
          rating: 4,
          salesRank: 235
        },
        {
          date: '2022-08-02',
          reviewCount: 9796,
          rating: 4,
          salesRank: 216
        },
        {
          date: '2022-08-03',
          reviewCount: 9796,
          rating: 4,
          salesRank: 237
        },
        {
          date: '2022-08-04',
          reviewCount: 9796,
          rating: 4,
          salesRank: 205
        },
        {
          date: '2022-08-05',
          reviewCount: 9796,
          rating: 4,
          salesRank: 247
        },
        {
          date: '2022-08-06',
          reviewCount: 9796,
          rating: 4,
          salesRank: 203
        },
        {
          date: '2022-08-07',
          reviewCount: 9796,
          rating: 4,
          salesRank: 180
        },
        {
          date: '2022-08-08',
          reviewCount: 9796,
          rating: 4,
          salesRank: 161
        },
        {
          date: '2022-08-09',
          reviewCount: 9796,
          rating: 4,
          salesRank: 134
        },
        {
          date: '2022-08-10',
          reviewCount: 9894,
          rating: 4,
          salesRank: 131
        },
        {
          date: '2022-08-11',
          reviewCount: 9894,
          rating: 4,
          salesRank: 137
        },
        {
          date: '2022-08-12',
          reviewCount: 9894,
          rating: 4,
          salesRank: 133
        },
        {
          date: '2022-08-13',
          reviewCount: 9894,
          rating: 4,
          salesRank: 126
        },
        {
          date: '2022-08-14',
          reviewCount: 9894,
          rating: 4,
          salesRank: 156
        },
        {
          date: '2022-08-15',
          reviewCount: 9894,
          rating: 4,
          salesRank: 63
        },
        {
          date: '2022-08-16',
          reviewCount: 9894,
          rating: 4,
          salesRank: 68
        },
        {
          date: '2022-08-17',
          reviewCount: 9894,
          rating: 4,
          salesRank: 62
        },
        {
          date: '2022-08-18',
          reviewCount: 9894,
          rating: 4,
          salesRank: 56
        },
        {
          date: '2022-08-19',
          reviewCount: 9894,
          rating: 4,
          salesRank: 59
        },
        {
          date: '2022-08-20',
          reviewCount: 9973,
          rating: 4,
          salesRank: 61
        },
        {
          date: '2022-08-21',
          reviewCount: 9973,
          rating: 4,
          salesRank: 58
        },
        {
          date: '2022-08-22',
          reviewCount: 9973,
          rating: 4,
          salesRank: 48
        },
        {
          date: '2022-08-23',
          reviewCount: 9973,
          rating: 4,
          salesRank: 47
        },
        {
          date: '2022-08-24',
          reviewCount: 9973,
          rating: 4,
          salesRank: 45
        },
        {
          date: '2022-08-25',
          reviewCount: 9973,
          rating: 4,
          salesRank: 44
        },
        {
          date: '2022-08-26',
          reviewCount: 9973,
          rating: 4,
          salesRank: 42
        },
        {
          date: '2022-08-27',
          reviewCount: 9973,
          rating: 4,
          salesRank: 46
        },
        {
          date: '2022-08-28',
          reviewCount: 9973,
          rating: 4,
          salesRank: 45
        },
        {
          date: '2022-08-29',
          reviewCount: 9973,
          rating: 4,
          salesRank: 42
        },
        {
          date: '2022-08-30',
          reviewCount: 9973,
          rating: 4,
          salesRank: 42
        },
        {
          date: '2022-08-31',
          reviewCount: 9973,
          rating: 4,
          salesRank: 44
        },
        {
          date: '2022-09-01',
          reviewCount: 10079,
          rating: 4,
          salesRank: 48
        },
        {
          date: '2022-09-02',
          reviewCount: 10079,
          rating: 4,
          salesRank: 42
        },
        {
          date: '2022-09-03',
          reviewCount: 10079,
          rating: 4,
          salesRank: 57
        },
        {
          date: '2022-09-04',
          reviewCount: 10079,
          rating: 4,
          salesRank: 66
        },
        {
          date: '2022-09-05',
          reviewCount: 10079,
          rating: 4,
          salesRank: 92
        },
        {
          date: '2022-09-06',
          reviewCount: 10079,
          rating: 4,
          salesRank: 97
        },
        {
          date: '2022-09-07',
          reviewCount: 10079,
          rating: 4,
          salesRank: 88
        },
        {
          date: '2022-09-08',
          reviewCount: 10079,
          rating: 4,
          salesRank: 124
        },
        {
          date: '2022-09-09',
          reviewCount: 10079,
          rating: 4,
          salesRank: 137
        },
        {
          date: '2022-09-10',
          reviewCount: 10079,
          rating: 4,
          salesRank: 166
        },
        {
          date: '2022-09-11',
          reviewCount: 10079,
          rating: 4,
          salesRank: 179
        },
        {
          date: '2022-09-12',
          reviewCount: 10079,
          rating: 4,
          salesRank: 182
        },
        {
          date: '2022-09-13',
          reviewCount: 10079,
          rating: 4,
          salesRank: 194
        },
        {
          date: '2022-09-14',
          reviewCount: 10169,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2022-09-15',
          reviewCount: 10169,
          rating: 4,
          salesRank: 236
        },
        {
          date: '2022-09-16',
          reviewCount: 10169,
          rating: 4,
          salesRank: 247
        },
        {
          date: '2022-09-17',
          reviewCount: 10169,
          rating: 4,
          salesRank: 233
        },
        {
          date: '2022-09-18',
          reviewCount: 10169,
          rating: 4,
          salesRank: 250
        },
        {
          date: '2022-09-19',
          reviewCount: 10169,
          rating: 4,
          salesRank: 229
        },
        {
          date: '2022-09-20',
          reviewCount: 10169,
          rating: 4
        },
        {
          date: '2022-09-21',
          reviewCount: 10169,
          rating: 4,
          salesRank: 206
        },
        {
          date: '2022-09-22',
          reviewCount: 10169,
          rating: 4,
          salesRank: 208
        },
        {
          date: '2022-09-23',
          reviewCount: 10169,
          rating: 4,
          salesRank: 1728
        },
        {
          date: '2022-09-24',
          reviewCount: 10279,
          rating: 4,
          salesRank: 1522
        },
        {
          date: '2022-09-25',
          reviewCount: 10279,
          rating: 4,
          salesRank: 1882
        },
        {
          date: '2022-09-26',
          reviewCount: 10279,
          rating: 4,
          salesRank: 1818
        },
        {
          date: '2022-09-27',
          reviewCount: 10279,
          rating: 4,
          salesRank: 1827
        },
        {
          date: '2022-09-28',
          reviewCount: 10279,
          rating: 4,
          salesRank: 2515
        },
        {
          date: '2022-09-29',
          reviewCount: 10279,
          rating: 4,
          salesRank: 2401
        },
        {
          date: '2022-09-30',
          reviewCount: 10279,
          rating: 4,
          salesRank: 2830
        },
        {
          date: '2022-10-01',
          reviewCount: 10279,
          rating: 4,
          salesRank: 2782
        },
        {
          date: '2022-10-02',
          reviewCount: 10359,
          rating: 4,
          salesRank: 2640
        },
        {
          date: '2022-10-03',
          reviewCount: 10359,
          rating: 4,
          salesRank: 2147
        },
        {
          date: '2022-10-04',
          reviewCount: 10359,
          rating: 4,
          salesRank: 2028
        },
        {
          date: '2022-10-05',
          reviewCount: 10359,
          rating: 4,
          salesRank: 2050
        },
        {
          date: '2022-10-06',
          reviewCount: 10359,
          rating: 4,
          salesRank: 1900
        },
        {
          date: '2022-10-07',
          reviewCount: 10359,
          rating: 4,
          salesRank: 2713
        },
        {
          date: '2022-10-08',
          reviewCount: 10359,
          rating: 4,
          salesRank: 2457
        },
        {
          date: '2022-10-09',
          reviewCount: 10359,
          rating: 4,
          salesRank: 2105
        },
        {
          date: '2022-10-10',
          reviewCount: 10449,
          rating: 4,
          salesRank: 2057
        },
        {
          date: '2022-10-11',
          reviewCount: 10449,
          rating: 4,
          salesRank: 4318
        },
        {
          date: '2022-10-12',
          reviewCount: 10449,
          rating: 4,
          salesRank: 4736
        },
        {
          date: '2022-10-13',
          reviewCount: 10449,
          rating: 4,
          salesRank: 3697
        },
        {
          date: '2022-10-14',
          reviewCount: 10449,
          rating: 4,
          salesRank: 3139
        },
        {
          date: '2022-10-15',
          reviewCount: 10449,
          rating: 4,
          salesRank: 2368
        },
        {
          date: '2022-10-16',
          reviewCount: 10449,
          rating: 4,
          salesRank: 2772
        },
        {
          date: '2022-10-17',
          reviewCount: 10449,
          rating: 4,
          salesRank: 2598
        },
        {
          date: '2022-10-18',
          reviewCount: 10449,
          rating: 4,
          salesRank: 2541
        },
        {
          date: '2022-10-19',
          reviewCount: 10491,
          rating: 4,
          salesRank: 2101
        },
        {
          date: '2022-10-20',
          reviewCount: 10491,
          rating: 4,
          salesRank: 2315
        },
        {
          date: '2022-10-21',
          reviewCount: 10491,
          rating: 4,
          salesRank: 2398
        },
        {
          date: '2022-10-22',
          reviewCount: 10491,
          rating: 4,
          salesRank: 2326
        },
        {
          date: '2022-10-23',
          reviewCount: 10491,
          rating: 4,
          salesRank: 2635
        },
        {
          date: '2022-10-24',
          reviewCount: 10491,
          rating: 4,
          salesRank: 2606
        },
        {
          date: '2022-10-25',
          reviewCount: 10491,
          rating: 4,
          salesRank: 2788
        },
        {
          date: '2022-10-26',
          reviewCount: 10491,
          rating: 4,
          salesRank: 2237
        },
        {
          date: '2022-10-27',
          reviewCount: 10491,
          rating: 4,
          salesRank: 2371
        },
        {
          date: '2022-10-28',
          reviewCount: 10559,
          rating: 4,
          salesRank: 2651
        },
        {
          date: '2022-10-29',
          reviewCount: 10559,
          rating: 4,
          salesRank: 2422
        },
        {
          date: '2022-10-30',
          reviewCount: 10559,
          rating: 4,
          salesRank: 2197
        },
        {
          date: '2022-10-31',
          reviewCount: 10559,
          rating: 4,
          salesRank: 2337
        },
        {
          date: '2022-11-01',
          reviewCount: 10559,
          rating: 4,
          salesRank: 2125
        },
        {
          date: '2022-11-02',
          reviewCount: 10559,
          rating: 4,
          salesRank: 2306
        },
        {
          date: '2022-11-03',
          reviewCount: 10559,
          rating: 4,
          salesRank: 2426
        },
        {
          date: '2022-11-04',
          reviewCount: 10559,
          rating: 4,
          salesRank: 2239
        },
        {
          date: '2022-11-05',
          reviewCount: 10559,
          rating: 4,
          salesRank: 2268
        },
        {
          date: '2022-11-06',
          reviewCount: 10604,
          rating: 4,
          salesRank: 2488
        },
        {
          date: '2022-11-07',
          reviewCount: 10604,
          rating: 4,
          salesRank: 2411
        },
        {
          date: '2022-11-08',
          reviewCount: 10604,
          rating: 4,
          salesRank: 2392
        },
        {
          date: '2022-11-09',
          reviewCount: 10604,
          rating: 4,
          salesRank: 2481
        },
        {
          date: '2022-11-10',
          reviewCount: 10604,
          rating: 4,
          salesRank: 2256
        },
        {
          date: '2022-11-11',
          reviewCount: 10604,
          rating: 4,
          salesRank: 2601
        },
        {
          date: '2022-11-12',
          reviewCount: 10604,
          rating: 4,
          salesRank: 3125
        },
        {
          date: '2022-11-13',
          reviewCount: 10604,
          rating: 4,
          salesRank: 2090
        },
        {
          date: '2022-11-14',
          reviewCount: 10604,
          rating: 4,
          salesRank: 2581
        },
        {
          date: '2022-11-15',
          reviewCount: 10604,
          rating: 4,
          salesRank: 2507
        },
        {
          date: '2022-11-16',
          reviewCount: 10655,
          rating: 4,
          salesRank: 2244
        },
        {
          date: '2022-11-17',
          reviewCount: 10655,
          rating: 4,
          salesRank: 2657
        },
        {
          date: '2022-11-18',
          reviewCount: 10655,
          rating: 4,
          salesRank: 2758
        },
        {
          date: '2022-11-19',
          reviewCount: 10655,
          rating: 4,
          salesRank: 2956
        },
        {
          date: '2022-11-20',
          reviewCount: 10655,
          rating: 4,
          salesRank: 2932
        },
        {
          date: '2022-11-21',
          reviewCount: 10655,
          rating: 4,
          salesRank: 3217
        },
        {
          date: '2022-11-22',
          reviewCount: 10655,
          rating: 4,
          salesRank: 3962
        },
        {
          date: '2022-11-23',
          reviewCount: 10655,
          rating: 4,
          salesRank: 4261
        },
        {
          date: '2022-11-24',
          reviewCount: 10655,
          rating: 4,
          salesRank: 4963
        },
        {
          date: '2022-11-25',
          reviewCount: 10655,
          rating: 4,
          salesRank: 5435
        },
        {
          date: '2022-11-26',
          reviewCount: 10655,
          rating: 4,
          salesRank: 4501
        },
        {
          date: '2022-11-27',
          reviewCount: 10690,
          rating: 4,
          salesRank: 4087
        },
        {
          date: '2022-11-28',
          reviewCount: 10690,
          rating: 4,
          salesRank: 4933
        },
        {
          date: '2022-11-29',
          reviewCount: 10690,
          rating: 4,
          salesRank: 4582
        },
        {
          date: '2022-11-30',
          reviewCount: 10690,
          rating: 4,
          salesRank: 3962
        },
        {
          date: '2022-12-01',
          reviewCount: 10690,
          rating: 4,
          salesRank: 3703
        },
        {
          date: '2022-12-02',
          reviewCount: 10690,
          rating: 4,
          salesRank: 3507
        },
        {
          date: '2022-12-03',
          reviewCount: 10690,
          rating: 4,
          salesRank: 3519
        },
        {
          date: '2022-12-04',
          reviewCount: 10690,
          rating: 4,
          salesRank: 3312
        },
        {
          date: '2022-12-05',
          reviewCount: 10690,
          rating: 4,
          salesRank: 2898
        },
        {
          date: '2022-12-06',
          reviewCount: 10690,
          rating: 4,
          salesRank: 3029
        },
        {
          date: '2022-12-07',
          reviewCount: 10736,
          rating: 4,
          salesRank: 3104
        },
        {
          date: '2022-12-08',
          reviewCount: 10736,
          rating: 4,
          salesRank: 2887
        },
        {
          date: '2022-12-09',
          reviewCount: 10736,
          rating: 4,
          salesRank: 2876
        },
        {
          date: '2022-12-10',
          reviewCount: 10736,
          rating: 4,
          salesRank: 3025
        },
        {
          date: '2022-12-11',
          reviewCount: 10736,
          rating: 4,
          salesRank: 2730
        },
        {
          date: '2022-12-12',
          reviewCount: 10754,
          rating: 4,
          salesRank: 2510
        },
        {
          date: '2022-12-13',
          reviewCount: 10754,
          rating: 4,
          salesRank: 2544
        },
        {
          date: '2022-12-14',
          reviewCount: 10754,
          rating: 4,
          salesRank: 2326
        },
        {
          date: '2022-12-15',
          reviewCount: 10754,
          rating: 4,
          salesRank: 2470
        },
        {
          date: '2022-12-16',
          reviewCount: 10754,
          rating: 4,
          salesRank: 2584
        },
        {
          date: '2022-12-17',
          reviewCount: 10754,
          rating: 4,
          salesRank: 2421
        },
        {
          date: '2022-12-18',
          reviewCount: 10776,
          rating: 4,
          salesRank: 2187
        },
        {
          date: '2022-12-19',
          reviewCount: 10776,
          rating: 4,
          salesRank: 2390
        },
        {
          date: '2022-12-20',
          reviewCount: 10776,
          rating: 4,
          salesRank: 2292
        },
        {
          date: '2022-12-21',
          reviewCount: 10776,
          rating: 4,
          salesRank: 2532
        },
        {
          date: '2022-12-22',
          reviewCount: 10776,
          rating: 4,
          salesRank: 2517
        },
        {
          date: '2022-12-23',
          reviewCount: 10776,
          rating: 4,
          salesRank: 2584
        },
        {
          date: '2022-12-24',
          reviewCount: 10790,
          rating: 4,
          salesRank: 2896
        },
        {
          date: '2022-12-25',
          reviewCount: 10790,
          rating: 4,
          salesRank: 2973
        },
        {
          date: '2022-12-26',
          reviewCount: 10790,
          rating: 4,
          salesRank: 3814
        },
        {
          date: '2022-12-27',
          reviewCount: 10790,
          rating: 4,
          salesRank: 3350
        },
        {
          date: '2022-12-28',
          reviewCount: 10790,
          rating: 4,
          salesRank: 3592
        },
        {
          date: '2022-12-29',
          reviewCount: 10803,
          rating: 4,
          salesRank: 4071
        },
        {
          date: '2022-12-30',
          reviewCount: 10803,
          rating: 4,
          salesRank: 2993
        },
        {
          date: '2022-12-31',
          reviewCount: 10803,
          rating: 4,
          salesRank: 2508
        },
        {
          date: '2023-01-01',
          reviewCount: 10803,
          rating: 4,
          salesRank: 2353
        },
        {
          date: '2023-01-02',
          reviewCount: 10803,
          rating: 4,
          salesRank: 2840
        },
        {
          date: '2023-01-03',
          reviewCount: 10822,
          rating: 4,
          salesRank: 2077
        },
        {
          date: '2023-01-04',
          reviewCount: 10822,
          rating: 4,
          salesRank: 2058
        },
        {
          date: '2023-01-05',
          reviewCount: 10822,
          rating: 4,
          salesRank: 2284
        },
        {
          date: '2023-01-06',
          reviewCount: 10822,
          rating: 4,
          salesRank: 2184
        },
        {
          date: '2023-01-07',
          reviewCount: 10822,
          rating: 4,
          salesRank: 2078
        },
        {
          date: '2023-01-08',
          reviewCount: 10822,
          rating: 4,
          salesRank: 1981
        },
        {
          date: '2023-01-09',
          reviewCount: 10839,
          rating: 4,
          salesRank: 2046
        },
        {
          date: '2023-01-10',
          reviewCount: 10839,
          rating: 4,
          salesRank: 1931
        },
        {
          date: '2023-01-11',
          reviewCount: 10839,
          rating: 4,
          salesRank: 1877
        },
        {
          date: '2023-01-12',
          reviewCount: 10839,
          rating: 4,
          salesRank: 1867
        },
        {
          date: '2023-01-13',
          reviewCount: 10839,
          rating: 4,
          salesRank: 2202
        },
        {
          date: '2023-01-14',
          reviewCount: 10851,
          rating: 4,
          salesRank: 2634
        },
        {
          date: '2023-01-15',
          reviewCount: 10851,
          rating: 4,
          salesRank: 3188
        },
        {
          date: '2023-01-16',
          reviewCount: 10851,
          rating: 4,
          salesRank: 3590
        },
        {
          date: '2023-01-17',
          reviewCount: 10851,
          rating: 4,
          salesRank: 2899
        },
        {
          date: '2023-01-18',
          reviewCount: 10851,
          rating: 4,
          salesRank: 2612
        },
        {
          date: '2023-01-19',
          reviewCount: 10851,
          rating: 4,
          salesRank: 2990
        },
        {
          date: '2023-01-20',
          reviewCount: 10872,
          rating: 4,
          salesRank: 2880
        },
        {
          date: '2023-01-21',
          reviewCount: 10872,
          rating: 4,
          salesRank: 2693
        },
        {
          date: '2023-01-22',
          reviewCount: 10872,
          rating: 4,
          salesRank: 3176
        },
        {
          date: '2023-01-23',
          reviewCount: 10872,
          rating: 4,
          salesRank: 3330
        },
        {
          date: '2023-01-24',
          reviewCount: 10872,
          rating: 4,
          salesRank: 2701
        },
        {
          date: '2023-01-25',
          reviewCount: 10885,
          rating: 4,
          salesRank: 3310
        },
        {
          date: '2023-01-26',
          reviewCount: 10885,
          rating: 4,
          salesRank: 2560
        },
        {
          date: '2023-01-27',
          reviewCount: 10885,
          rating: 4,
          salesRank: 2898
        },
        {
          date: '2023-01-28',
          reviewCount: 10885,
          rating: 4,
          salesRank: 3730
        },
        {
          date: '2023-01-29',
          reviewCount: 10885,
          rating: 4,
          salesRank: 2958
        },
        {
          date: '2023-01-30',
          reviewCount: 10899,
          rating: 4,
          salesRank: 2505
        },
        {
          date: '2023-01-31',
          reviewCount: 10899,
          rating: 4,
          salesRank: 3011
        },
        {
          date: '2023-02-01',
          reviewCount: 10899,
          rating: 4,
          salesRank: 2856
        },
        {
          date: '2023-02-02',
          reviewCount: 10899,
          rating: 4,
          salesRank: 3189
        },
        {
          date: '2023-02-03',
          reviewCount: 10899,
          rating: 4,
          salesRank: 3515
        },
        {
          date: '2023-02-04',
          reviewCount: 10899,
          rating: 4,
          salesRank: 3100
        },
        {
          date: '2023-02-05',
          reviewCount: 10904,
          rating: 4,
          salesRank: 3605
        },
        {
          date: '2023-02-06',
          reviewCount: 10904,
          rating: 4,
          salesRank: 3443
        },
        {
          date: '2023-02-07',
          reviewCount: 10904,
          rating: 4,
          salesRank: 3465
        },
        {
          date: '2023-02-08',
          reviewCount: 10904,
          rating: 4,
          salesRank: 3712
        },
        {
          date: '2023-02-09',
          reviewCount: 10904,
          rating: 4,
          salesRank: 3617
        },
        {
          date: '2023-02-10',
          reviewCount: 10910,
          rating: 4,
          salesRank: 3157
        },
        {
          date: '2023-02-11',
          reviewCount: 10910,
          rating: 4,
          salesRank: 2877
        },
        {
          date: '2023-02-12',
          reviewCount: 10910,
          rating: 4,
          salesRank: 2506
        },
        {
          date: '2023-02-13',
          reviewCount: 10910,
          rating: 4,
          salesRank: 2665
        },
        {
          date: '2023-02-14',
          reviewCount: 10910,
          rating: 4,
          salesRank: 1874
        },
        {
          date: '2023-02-15',
          reviewCount: 10910,
          rating: 4,
          salesRank: 2709
        },
        {
          date: '2023-02-16',
          reviewCount: 10918,
          rating: 4,
          salesRank: 2265
        },
        {
          date: '2023-02-17',
          reviewCount: 10918,
          rating: 4,
          salesRank: 2849
        },
        {
          date: '2023-02-18',
          reviewCount: 10918,
          rating: 4,
          salesRank: 2489
        },
        {
          date: '2023-02-19',
          reviewCount: 10918,
          rating: 4,
          salesRank: 2306
        },
        {
          date: '2023-02-20',
          reviewCount: 10918,
          rating: 4,
          salesRank: 2231
        },
        {
          date: '2023-02-21',
          reviewCount: 10918,
          rating: 4,
          salesRank: 2066
        },
        {
          date: '2023-02-22',
          reviewCount: 10934,
          rating: 4,
          salesRank: 2286
        },
        {
          date: '2023-02-23',
          reviewCount: 10934,
          rating: 4,
          salesRank: 2196
        },
        {
          date: '2023-02-24',
          reviewCount: 10934,
          rating: 4,
          salesRank: 2364
        },
        {
          date: '2023-02-25',
          reviewCount: 10934,
          rating: 4,
          salesRank: 2732
        },
        {
          date: '2023-02-26',
          reviewCount: 10934,
          rating: 4,
          salesRank: 2088
        },
        {
          date: '2023-02-27',
          reviewCount: 10934,
          rating: 4,
          salesRank: 2236
        },
        {
          date: '2023-02-28',
          reviewCount: 10953,
          rating: 4,
          salesRank: 3212
        },
        {
          date: '2023-03-01',
          reviewCount: 10953,
          rating: 4,
          salesRank: 3505
        },
        {
          date: '2023-03-02',
          reviewCount: 10953,
          rating: 4,
          salesRank: 2784
        },
        {
          date: '2023-03-03',
          reviewCount: 10953,
          rating: 4,
          salesRank: 2850
        },
        {
          date: '2023-03-04',
          reviewCount: 10953,
          rating: 4,
          salesRank: 3127
        },
        {
          date: '2023-03-05',
          reviewCount: 10964,
          rating: 4,
          salesRank: 1517
        },
        {
          date: '2023-03-06',
          reviewCount: 10964,
          rating: 4,
          salesRank: 2261
        },
        {
          date: '2023-03-07',
          reviewCount: 10964,
          rating: 4,
          salesRank: 2950
        },
        {
          date: '2023-03-08',
          reviewCount: 10964,
          rating: 4,
          salesRank: 2621
        },
        {
          date: '2023-03-09',
          reviewCount: 10964,
          rating: 4,
          salesRank: 2807
        },
        {
          date: '2023-03-10',
          reviewCount: 10964,
          rating: 4,
          salesRank: 3923
        },
        {
          date: '2023-03-11',
          reviewCount: 10976,
          rating: 4,
          salesRank: 3391
        },
        {
          date: '2023-03-12',
          reviewCount: 10976,
          rating: 4,
          salesRank: 3254
        },
        {
          date: '2023-03-13',
          reviewCount: 10976,
          rating: 4,
          salesRank: 2891
        },
        {
          date: '2023-03-14',
          reviewCount: 10976,
          rating: 4,
          salesRank: 3080
        },
        {
          date: '2023-03-15',
          reviewCount: 10976,
          rating: 4,
          salesRank: 3358
        },
        {
          date: '2023-03-16',
          reviewCount: 10976,
          rating: 4,
          salesRank: 3077
        },
        {
          date: '2023-03-17',
          reviewCount: 10994,
          rating: 4,
          salesRank: 3684
        },
        {
          date: '2023-03-18',
          reviewCount: 10994,
          rating: 4,
          salesRank: 2983
        },
        {
          date: '2023-03-19',
          reviewCount: 10994,
          rating: 4,
          salesRank: 3792
        },
        {
          date: '2023-03-20',
          reviewCount: 10994,
          rating: 4,
          salesRank: 4532
        },
        {
          date: '2023-03-21',
          reviewCount: 10994,
          rating: 4,
          salesRank: 3133
        },
        {
          date: '2023-03-22',
          reviewCount: 10994,
          rating: 4,
          salesRank: 3325
        },
        {
          date: '2023-03-23',
          reviewCount: 11008,
          rating: 4,
          salesRank: 3955
        },
        {
          date: '2023-03-24',
          reviewCount: 11008,
          rating: 4,
          salesRank: 4165
        },
        {
          date: '2023-03-25',
          reviewCount: 11008,
          rating: 4,
          salesRank: 4096
        },
        {
          date: '2023-03-26',
          reviewCount: 11008,
          rating: 4,
          salesRank: 4923
        },
        {
          date: '2023-03-27',
          reviewCount: 11008,
          rating: 4,
          salesRank: 3785
        },
        {
          date: '2023-03-28',
          reviewCount: 11008,
          rating: 4,
          salesRank: 4295
        },
        {
          date: '2023-03-29',
          reviewCount: 11026,
          rating: 4,
          salesRank: 3980
        },
        {
          date: '2023-03-30',
          reviewCount: 11026,
          rating: 4,
          salesRank: 3749
        },
        {
          date: '2023-03-31',
          reviewCount: 11026,
          rating: 4,
          salesRank: 3371
        },
        {
          date: '2023-04-01',
          reviewCount: 11026,
          rating: 4,
          salesRank: 4444
        },
        {
          date: '2023-04-02',
          reviewCount: 11026,
          rating: 4,
          salesRank: 3702
        },
        {
          date: '2023-04-03',
          reviewCount: 11026,
          rating: 4,
          salesRank: 3907
        },
        {
          date: '2023-04-04',
          reviewCount: 11043,
          rating: 4,
          salesRank: 4476
        },
        {
          date: '2023-04-05',
          reviewCount: 11043,
          rating: 4,
          salesRank: 4337
        },
        {
          date: '2023-04-06',
          reviewCount: 11043,
          rating: 4,
          salesRank: 4279
        },
        {
          date: '2023-04-07',
          reviewCount: 11043,
          rating: 4,
          salesRank: 4967
        },
        {
          date: '2023-04-08',
          reviewCount: 11043,
          rating: 4,
          salesRank: 4656
        },
        {
          date: '2023-04-09',
          reviewCount: 11056,
          rating: 4,
          salesRank: 5341
        },
        {
          date: '2023-04-10',
          reviewCount: 11056,
          rating: 4,
          salesRank: 2759
        },
        {
          date: '2023-04-11',
          reviewCount: 11056,
          rating: 4,
          salesRank: 3219
        },
        {
          date: '2023-04-12',
          reviewCount: 11056,
          rating: 4,
          salesRank: 2772
        },
        {
          date: '2023-04-13',
          reviewCount: 11056,
          rating: 4,
          salesRank: 3024
        },
        {
          date: '2023-04-14',
          reviewCount: 11056,
          rating: 4,
          salesRank: 2775
        },
        {
          date: '2023-04-15',
          reviewCount: 11076,
          rating: 4,
          salesRank: 3836
        },
        {
          date: '2023-04-16',
          reviewCount: 11076,
          rating: 4,
          salesRank: 3625
        },
        {
          date: '2023-04-17',
          reviewCount: 11076,
          rating: 4,
          salesRank: 4091
        },
        {
          date: '2023-04-18',
          reviewCount: 11076,
          rating: 4,
          salesRank: 3360
        },
        {
          date: '2023-04-19',
          reviewCount: 11076,
          rating: 4,
          salesRank: 3514
        },
        {
          date: '2023-04-20',
          reviewCount: 11080,
          rating: 4,
          salesRank: 3924
        },
        {
          date: '2023-04-21',
          reviewCount: 11080,
          rating: 4,
          salesRank: 3773
        },
        {
          date: '2023-04-22',
          reviewCount: 11080,
          rating: 4,
          salesRank: 2941
        },
        {
          date: '2023-04-23',
          reviewCount: 11080,
          rating: 4,
          salesRank: 3384
        },
        {
          date: '2023-04-24',
          reviewCount: 11080,
          rating: 4,
          salesRank: 2688
        },
        {
          date: '2023-04-25',
          reviewCount: 11080,
          rating: 4,
          salesRank: 2402
        },
        {
          date: '2023-04-26',
          reviewCount: 11102,
          rating: 4,
          salesRank: 2925
        },
        {
          date: '2023-04-27',
          reviewCount: 11102,
          rating: 4,
          salesRank: 3324
        },
        {
          date: '2023-04-28',
          reviewCount: 11102,
          rating: 4,
          salesRank: 3730
        },
        {
          date: '2023-04-29',
          reviewCount: 11102,
          rating: 4,
          salesRank: 4102
        },
        {
          date: '2023-04-30',
          reviewCount: 11102,
          rating: 4,
          salesRank: 3406
        },
        {
          date: '2023-05-01',
          reviewCount: 11102,
          rating: 4,
          salesRank: 3624
        },
        {
          date: '2023-05-02',
          reviewCount: 11117,
          rating: 4,
          salesRank: 3327
        },
        {
          date: '2023-05-03',
          reviewCount: 11117,
          rating: 4,
          salesRank: 2475
        },
        {
          date: '2023-05-04',
          reviewCount: 11117,
          rating: 4,
          salesRank: 3015
        },
        {
          date: '2023-05-05',
          reviewCount: 11117,
          rating: 4,
          salesRank: 3610
        },
        {
          date: '2023-05-06',
          reviewCount: 11117,
          rating: 4,
          salesRank: 3007
        },
        {
          date: '2023-05-07',
          reviewCount: 11117,
          rating: 4,
          salesRank: 2831
        },
        {
          date: '2023-05-08',
          reviewCount: 11131,
          rating: 4,
          salesRank: 3724
        },
        {
          date: '2023-05-09',
          reviewCount: 11131,
          rating: 4,
          salesRank: 2981
        },
        {
          date: '2023-05-10',
          reviewCount: 11131,
          rating: 4,
          salesRank: 3474
        },
        {
          date: '2023-05-11',
          reviewCount: 11131,
          rating: 4,
          salesRank: 3179
        },
        {
          date: '2023-05-12',
          reviewCount: 11131,
          rating: 4,
          salesRank: 4562
        },
        {
          date: '2023-05-13',
          reviewCount: 11131,
          rating: 4,
          salesRank: 4029
        },
        {
          date: '2023-05-14',
          reviewCount: 11141,
          rating: 4,
          salesRank: 3097
        },
        {
          date: '2023-05-15',
          reviewCount: 11141,
          rating: 4,
          salesRank: 4579
        },
        {
          date: '2023-05-16',
          reviewCount: 11141,
          rating: 4,
          salesRank: 3414
        },
        {
          date: '2023-05-17',
          reviewCount: 11141,
          rating: 4,
          salesRank: 3887
        },
        {
          date: '2023-05-18',
          reviewCount: 11141,
          rating: 4,
          salesRank: 4103
        },
        {
          date: '2023-05-19',
          reviewCount: 11141,
          rating: 4,
          salesRank: 6136
        },
        {
          date: '2023-05-20',
          reviewCount: 11165,
          rating: 4,
          salesRank: 5291
        },
        {
          date: '2023-05-21',
          reviewCount: 11165,
          rating: 4,
          salesRank: 4278
        },
        {
          date: '2023-05-22',
          reviewCount: 11165,
          rating: 4,
          salesRank: 3756
        },
        {
          date: '2023-05-23',
          reviewCount: 11165,
          rating: 4,
          salesRank: 4016
        },
        {
          date: '2023-05-24',
          reviewCount: 11165,
          rating: 4,
          salesRank: 4177
        },
        {
          date: '2023-05-25',
          reviewCount: 11175,
          rating: 4,
          salesRank: 3456
        },
        {
          date: '2023-05-26',
          reviewCount: 11175,
          rating: 4,
          salesRank: 4119
        },
        {
          date: '2023-05-27',
          reviewCount: 11175,
          rating: 4,
          salesRank: 3559
        },
        {
          date: '2023-05-28',
          reviewCount: 11175,
          rating: 4,
          salesRank: 3051
        },
        {
          date: '2023-05-29',
          reviewCount: 11175,
          rating: 4
        },
        {
          date: '2023-05-30',
          reviewCount: 11175,
          rating: 4,
          salesRank: 3888
        },
        {
          date: '2023-05-31',
          reviewCount: 11185,
          rating: 4,
          salesRank: 3779
        },
        {
          date: '2023-06-01',
          reviewCount: 11185,
          rating: 4,
          salesRank: 3049
        },
        {
          date: '2023-06-02',
          reviewCount: 11185,
          rating: 4,
          salesRank: 3529
        },
        {
          date: '2023-06-03',
          reviewCount: 11185,
          rating: 4,
          salesRank: 4145
        },
        {
          date: '2023-06-04',
          reviewCount: 11185,
          rating: 4,
          salesRank: 4492
        },
        {
          date: '2023-06-05',
          reviewCount: 11196,
          rating: 4,
          salesRank: 3534
        },
        {
          date: '2023-06-06',
          reviewCount: 11196,
          rating: 4,
          salesRank: 4191
        },
        {
          date: '2023-06-07',
          reviewCount: 11196,
          rating: 4,
          salesRank: 4623
        },
        {
          date: '2023-06-08',
          reviewCount: 11196,
          rating: 4,
          salesRank: 4707
        },
        {
          date: '2023-06-09',
          reviewCount: 11196,
          rating: 4,
          salesRank: 3808
        },
        {
          date: '2023-06-10',
          reviewCount: 11196,
          rating: 4,
          salesRank: 3406
        },
        {
          date: '2023-06-11',
          reviewCount: 11206,
          rating: 4,
          salesRank: 4080
        },
        {
          date: '2023-06-12',
          reviewCount: 11206,
          rating: 4,
          salesRank: 4301
        },
        {
          date: '2023-06-13',
          reviewCount: 11206,
          rating: 4,
          salesRank: 4053
        },
        {
          date: '2023-06-14',
          reviewCount: 11206,
          rating: 4,
          salesRank: 3844
        },
        {
          date: '2023-06-15',
          reviewCount: 11206,
          rating: 4,
          salesRank: 3305
        },
        {
          date: '2023-06-16',
          reviewCount: 11216,
          rating: 4,
          salesRank: 4022
        },
        {
          date: '2023-06-17',
          reviewCount: 11216,
          rating: 4,
          salesRank: 4230
        },
        {
          date: '2023-06-18',
          reviewCount: 11216,
          rating: 4,
          salesRank: 2893
        },
        {
          date: '2023-06-19',
          reviewCount: 11216,
          rating: 4,
          salesRank: 3621
        },
        {
          date: '2023-06-20',
          reviewCount: 11216,
          rating: 4,
          salesRank: 3559
        },
        {
          date: '2023-06-21',
          reviewCount: 11216,
          rating: 4,
          salesRank: 3640
        },
        {
          date: '2023-06-22',
          reviewCount: 11227,
          rating: 4,
          salesRank: 4137
        },
        {
          date: '2023-06-23',
          reviewCount: 11227,
          rating: 4,
          salesRank: 2892
        },
        {
          date: '2023-06-24',
          reviewCount: 11227,
          rating: 4,
          salesRank: 2863
        },
        {
          date: '2023-06-25',
          reviewCount: 11227,
          rating: 4,
          salesRank: 3227
        },
        {
          date: '2023-06-26',
          reviewCount: 11227,
          rating: 4,
          salesRank: 2997
        },
        {
          date: '2023-06-27',
          reviewCount: 11241,
          rating: 4,
          salesRank: 3332
        },
        {
          date: '2023-06-28',
          reviewCount: 11241,
          rating: 4,
          salesRank: 3811
        },
        {
          date: '2023-06-29',
          reviewCount: 11241,
          rating: 4,
          salesRank: 3218
        },
        {
          date: '2023-06-30',
          reviewCount: 11241,
          rating: 4,
          salesRank: 3604
        },
        {
          date: '2023-07-01',
          reviewCount: 11241,
          rating: 4,
          salesRank: 3137
        },
        {
          date: '2023-07-02',
          reviewCount: 11261,
          rating: 4,
          salesRank: 4821
        },
        {
          date: '2023-07-03',
          reviewCount: 11261,
          rating: 4,
          salesRank: 3556
        },
        {
          date: '2023-07-04',
          reviewCount: 11261,
          rating: 4,
          salesRank: 3875
        },
        {
          date: '2023-07-05',
          reviewCount: 11261,
          rating: 4,
          salesRank: 3885
        },
        {
          date: '2023-07-06',
          reviewCount: 11261,
          rating: 4,
          salesRank: 3381
        },
        {
          date: '2023-07-07',
          reviewCount: 11261,
          rating: 4,
          salesRank: 3023
        },
        {
          date: '2023-07-08',
          reviewCount: 11275,
          rating: 4,
          salesRank: 2664
        },
        {
          date: '2023-07-09',
          reviewCount: 11275,
          rating: 4,
          salesRank: 2301
        },
        {
          date: '2023-07-10',
          reviewCount: 11275,
          rating: 4,
          salesRank: 2106
        },
        {
          date: '2023-07-11',
          reviewCount: 11275,
          rating: 4,
          salesRank: 1601
        },
        {
          date: '2023-07-12',
          reviewCount: 11275,
          rating: 4,
          salesRank: 1587
        },
        {
          date: '2023-07-13',
          reviewCount: 11293,
          rating: 4,
          salesRank: 1495
        },
        {
          date: '2023-07-14',
          reviewCount: 11293,
          rating: 4,
          salesRank: 1554
        },
        {
          date: '2023-07-15',
          reviewCount: 11293,
          rating: 4,
          salesRank: 1750
        },
        {
          date: '2023-07-16',
          reviewCount: 11293,
          rating: 4,
          salesRank: 1552
        },
        {
          date: '2023-07-17',
          reviewCount: 11293,
          rating: 4,
          salesRank: 1535
        },
        {
          date: '2023-07-18',
          reviewCount: 11293,
          rating: 4,
          salesRank: 1449
        },
        {
          date: '2023-07-19',
          reviewCount: 11087,
          rating: 4,
          salesRank: 1353
        },
        {
          date: '2023-07-20',
          reviewCount: 11087,
          rating: 4,
          salesRank: 1543
        },
        {
          date: '2023-07-21',
          reviewCount: 11087,
          rating: 4,
          salesRank: 1488
        },
        {
          date: '2023-07-22',
          reviewCount: 11087,
          rating: 4,
          salesRank: 1814
        },
        {
          date: '2023-07-23',
          reviewCount: 11087,
          rating: 4,
          salesRank: 1444
        },
        {
          date: '2023-07-24',
          reviewCount: 11081,
          rating: 4,
          salesRank: 1257
        },
        {
          date: '2023-07-25',
          reviewCount: 11081,
          rating: 4,
          salesRank: 1101
        },
        {
          date: '2023-07-26',
          reviewCount: 11081,
          rating: 4,
          salesRank: 1184
        },
        {
          date: '2023-07-27',
          reviewCount: 11081,
          rating: 4,
          salesRank: 1145
        },
        {
          date: '2023-07-28',
          reviewCount: 11081,
          rating: 4,
          salesRank: 1099
        },
        {
          date: '2023-07-29',
          reviewCount: 11081,
          rating: 4,
          salesRank: 806
        },
        {
          date: '2023-07-30',
          reviewCount: 11104,
          rating: 4,
          salesRank: 659
        },
        {
          date: '2023-07-31',
          reviewCount: 11104,
          rating: 4,
          salesRank: 622
        },
        {
          date: '2023-08-01',
          reviewCount: 11104,
          rating: 4,
          salesRank: 744
        },
        {
          date: '2023-08-02',
          reviewCount: 11104,
          rating: 4,
          salesRank: 804
        },
        {
          date: '2023-08-03',
          reviewCount: 11104,
          rating: 4,
          salesRank: 1018
        },
        {
          date: '2023-08-04',
          reviewCount: 11104,
          rating: 4,
          salesRank: 1187
        },
        {
          date: '2023-08-05',
          reviewCount: 11122,
          rating: 4,
          salesRank: 905
        },
        {
          date: '2023-08-06',
          reviewCount: 11122,
          rating: 4,
          salesRank: 780
        },
        {
          date: '2023-08-07',
          reviewCount: 11122,
          rating: 4,
          salesRank: 595
        },
        {
          date: '2023-08-08',
          reviewCount: 11122,
          rating: 4,
          salesRank: 478
        },
        {
          date: '2023-08-09',
          reviewCount: 11122,
          rating: 4,
          salesRank: 449
        },
        {
          date: '2023-08-10',
          reviewCount: 11139,
          rating: 4,
          salesRank: 449
        },
        {
          date: '2023-08-11',
          reviewCount: 11139,
          rating: 4,
          salesRank: 392
        },
        {
          date: '2023-08-12',
          reviewCount: 11139,
          rating: 4,
          salesRank: 338
        },
        {
          date: '2023-08-13',
          reviewCount: 11139,
          rating: 4,
          salesRank: 315
        },
        {
          date: '2023-08-14',
          reviewCount: 11139,
          rating: 4,
          salesRank: 355
        },
        {
          date: '2023-08-15',
          reviewCount: 11139,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2023-08-16',
          reviewCount: 11165,
          rating: 4,
          salesRank: 348
        },
        {
          date: '2023-08-17',
          reviewCount: 11165,
          rating: 4,
          salesRank: 621
        },
        {
          date: '2023-08-18',
          reviewCount: 11165,
          rating: 4,
          salesRank: 675
        },
        {
          date: '2023-08-19',
          reviewCount: 11165,
          rating: 4,
          salesRank: 519
        },
        {
          date: '2023-08-20',
          reviewCount: 11165,
          rating: 4,
          salesRank: 481
        },
        {
          date: '2023-08-21',
          reviewCount: 11165,
          rating: 4,
          salesRank: 475
        },
        {
          date: '2023-08-22',
          reviewCount: 11190,
          rating: 4,
          salesRank: 387
        },
        {
          date: '2023-08-23',
          reviewCount: 11190,
          rating: 4,
          salesRank: 411
        },
        {
          date: '2023-08-24',
          reviewCount: 11190,
          rating: 4,
          salesRank: 405
        },
        {
          date: '2023-08-25',
          reviewCount: 11190,
          rating: 4,
          salesRank: 511
        },
        {
          date: '2023-08-26',
          reviewCount: 11190,
          rating: 4,
          salesRank: 655
        },
        {
          date: '2023-08-27',
          reviewCount: 11213,
          rating: 4,
          salesRank: 636
        },
        {
          date: '2023-08-28',
          reviewCount: 11213,
          rating: 4,
          salesRank: 539
        },
        {
          date: '2023-08-29',
          reviewCount: 11213,
          rating: 4,
          salesRank: 451
        },
        {
          date: '2023-08-30',
          reviewCount: 11213,
          rating: 4,
          salesRank: 456
        },
        {
          date: '2023-08-31',
          reviewCount: 11225,
          rating: 4,
          salesRank: 473
        },
        {
          date: '2023-09-01',
          reviewCount: 11225,
          rating: 4,
          salesRank: 718
        },
        {
          date: '2023-09-02',
          reviewCount: 11225,
          rating: 4,
          salesRank: 956
        },
        {
          date: '2023-09-03',
          reviewCount: 11233,
          rating: 4,
          salesRank: 1191
        },
        {
          date: '2023-09-04',
          reviewCount: 11233,
          rating: 4,
          salesRank: 1135
        },
        {
          date: '2023-09-05',
          reviewCount: 11233,
          rating: 4,
          salesRank: 986
        },
        {
          date: '2023-09-06',
          reviewCount: 11233,
          rating: 4,
          salesRank: 1229
        },
        {
          date: '2023-09-07',
          reviewCount: 11244,
          rating: 4,
          salesRank: 1387
        },
        {
          date: '2023-09-08',
          reviewCount: 11244,
          rating: 4,
          salesRank: 1575
        },
        {
          date: '2023-09-09',
          reviewCount: 11244,
          rating: 4,
          salesRank: 2333
        },
        {
          date: '2023-09-10',
          reviewCount: 11244,
          rating: 4,
          salesRank: 2380
        },
        {
          date: '2023-09-11',
          reviewCount: 11272,
          rating: 4,
          salesRank: 2823
        },
        {
          date: '2023-09-12',
          reviewCount: 11272,
          rating: 4,
          salesRank: 2939
        },
        {
          date: '2023-09-13',
          reviewCount: 11272,
          rating: 4,
          salesRank: 3519
        },
        {
          date: '2023-09-14',
          reviewCount: 11272,
          rating: 4,
          salesRank: 2979
        },
        {
          date: '2023-09-15',
          reviewCount: 11272,
          rating: 4,
          salesRank: 3347
        },
        {
          date: '2023-09-16',
          reviewCount: 11304,
          rating: 4,
          salesRank: 2982
        },
        {
          date: '2023-09-17',
          reviewCount: 11304,
          rating: 4,
          salesRank: 3759
        },
        {
          date: '2023-09-18',
          reviewCount: 11304,
          rating: 4,
          salesRank: 3873
        },
        {
          date: '2023-09-19',
          reviewCount: 11304,
          rating: 4,
          salesRank: 3835
        },
        {
          date: '2023-09-20',
          reviewCount: 11304,
          rating: 4,
          salesRank: 4030
        },
        {
          date: '2023-09-21',
          reviewCount: 11304,
          rating: 4,
          salesRank: 3920
        },
        {
          date: '2023-09-22',
          reviewCount: 11329,
          rating: 4,
          salesRank: 3491
        },
        {
          date: '2023-09-23',
          reviewCount: 11329,
          rating: 4,
          salesRank: 3430
        },
        {
          date: '2023-09-24',
          reviewCount: 11329,
          rating: 4,
          salesRank: 4039
        },
        {
          date: '2023-09-25',
          reviewCount: 11329,
          rating: 4,
          salesRank: 3300
        },
        {
          date: '2023-09-26',
          reviewCount: 11329,
          rating: 4,
          salesRank: 3814
        },
        {
          date: '2023-09-27',
          reviewCount: 11329,
          rating: 4,
          salesRank: 4156
        },
        {
          date: '2023-09-28',
          reviewCount: 11363,
          rating: 4,
          salesRank: 3622
        },
        {
          date: '2023-09-29',
          reviewCount: 11363,
          rating: 4,
          salesRank: 3124
        },
        {
          date: '2023-09-30',
          reviewCount: 11363,
          rating: 4,
          salesRank: 2984
        },
        {
          date: '2023-10-01',
          reviewCount: 11363,
          rating: 4,
          salesRank: 3857
        },
        {
          date: '2023-10-02',
          reviewCount: 11363,
          rating: 4,
          salesRank: 3089
        },
        {
          date: '2023-10-03',
          reviewCount: 11386,
          rating: 4,
          salesRank: 2686
        },
        {
          date: '2023-10-04',
          reviewCount: 11386,
          rating: 4,
          salesRank: 2468
        },
        {
          date: '2023-10-05',
          reviewCount: 11386,
          rating: 4,
          salesRank: 2106
        },
        {
          date: '2023-10-06',
          reviewCount: 11386,
          rating: 4,
          salesRank: 2379
        },
        {
          date: '2023-10-07',
          reviewCount: 11386,
          rating: 4,
          salesRank: 2259
        },
        {
          date: '2023-10-08',
          reviewCount: 11414,
          rating: 4,
          salesRank: 2264
        },
        {
          date: '2023-10-09',
          reviewCount: 11414,
          rating: 4,
          salesRank: 2745
        },
        {
          date: '2023-10-10',
          reviewCount: 11414,
          rating: 4,
          salesRank: 2606
        },
        {
          date: '2023-10-11',
          reviewCount: 11414,
          rating: 4,
          salesRank: 5653
        },
        {
          date: '2023-10-12',
          reviewCount: 11414,
          rating: 4,
          salesRank: 6428
        },
        {
          date: '2023-10-13',
          reviewCount: 11441,
          rating: 4,
          salesRank: 4813
        },
        {
          date: '2023-10-14',
          reviewCount: 11441,
          rating: 4,
          salesRank: 3779
        },
        {
          date: '2023-10-15',
          reviewCount: 11441,
          rating: 4,
          salesRank: 3768
        },
        {
          date: '2023-10-16',
          reviewCount: 11441,
          rating: 4,
          salesRank: 4061
        },
        {
          date: '2023-10-17',
          reviewCount: 11441,
          rating: 4,
          salesRank: 3273
        },
        {
          date: '2023-10-18',
          reviewCount: 11441,
          rating: 4,
          salesRank: 4183
        },
        {
          date: '2023-10-19',
          reviewCount: 11472,
          rating: 4,
          salesRank: 4038
        },
        {
          date: '2023-10-20',
          reviewCount: 11472,
          rating: 4,
          salesRank: 4153
        },
        {
          date: '2023-10-21',
          reviewCount: 11472,
          rating: 4,
          salesRank: 5391
        },
        {
          date: '2023-10-22',
          reviewCount: 11472,
          rating: 4,
          salesRank: 4338
        },
        {
          date: '2023-10-23',
          reviewCount: 11472,
          rating: 4,
          salesRank: 4202
        },
        {
          date: '2023-10-24',
          reviewCount: 11472,
          rating: 4,
          salesRank: 5320
        },
        {
          date: '2023-10-25',
          reviewCount: 11496,
          rating: 4,
          salesRank: 5128
        },
        {
          date: '2023-10-26',
          reviewCount: 11496,
          rating: 4,
          salesRank: 5880
        },
        {
          date: '2023-10-27',
          reviewCount: 11496,
          rating: 4,
          salesRank: 4634
        },
        {
          date: '2023-10-28',
          reviewCount: 11496,
          rating: 4,
          salesRank: 5438
        },
        {
          date: '2023-10-29',
          reviewCount: 11496,
          rating: 4,
          salesRank: 6242
        },
        {
          date: '2023-10-30',
          reviewCount: 11517,
          rating: 4,
          salesRank: 4946
        },
        {
          date: '2023-10-31',
          reviewCount: 11517,
          rating: 4,
          salesRank: 5332
        },
        {
          date: '2023-11-01',
          reviewCount: 11517,
          rating: 4,
          salesRank: 3626
        },
        {
          date: '2023-11-02',
          reviewCount: 11517,
          rating: 4,
          salesRank: 4400
        },
        {
          date: '2023-11-03',
          reviewCount: 11517,
          rating: 4,
          salesRank: 4480
        },
        {
          date: '2023-11-04',
          reviewCount: 11517,
          rating: 4,
          salesRank: 3616
        },
        {
          date: '2023-11-05',
          reviewCount: 11535,
          rating: 4,
          salesRank: 4879
        },
        {
          date: '2023-11-06',
          reviewCount: 11535,
          rating: 4,
          salesRank: 4923
        },
        {
          date: '2023-11-07',
          reviewCount: 11535,
          rating: 4,
          salesRank: 5072
        },
        {
          date: '2023-11-08',
          reviewCount: 11535,
          rating: 4,
          salesRank: 5805
        },
        {
          date: '2023-11-09',
          reviewCount: 11535,
          rating: 4,
          salesRank: 4741
        },
        {
          date: '2023-11-10',
          reviewCount: 11535,
          rating: 4,
          salesRank: 5354
        },
        {
          date: '2023-11-11',
          reviewCount: 11557,
          rating: 4,
          salesRank: 4839
        },
        {
          date: '2023-11-12',
          reviewCount: 11557,
          rating: 4,
          salesRank: 4460
        },
        {
          date: '2023-11-13',
          reviewCount: 11557,
          rating: 4,
          salesRank: 4904
        },
        {
          date: '2023-11-14',
          reviewCount: 11557,
          rating: 4,
          salesRank: 6020
        },
        {
          date: '2023-11-15',
          reviewCount: 11557,
          rating: 4,
          salesRank: 4644
        },
        {
          date: '2023-11-16',
          reviewCount: 11560,
          rating: 4,
          salesRank: 5272
        },
        {
          date: '2023-11-17',
          reviewCount: 11560,
          rating: 4,
          salesRank: 7355
        },
        {
          date: '2023-11-18',
          reviewCount: 11560,
          rating: 4,
          salesRank: 6475
        },
        {
          date: '2023-11-19',
          reviewCount: 11560,
          rating: 4,
          salesRank: 7226
        },
        {
          date: '2023-11-20',
          reviewCount: 11560,
          rating: 4,
          salesRank: 7905
        },
        {
          date: '2023-11-21',
          reviewCount: 11566,
          rating: 4,
          salesRank: 7724
        },
        {
          date: '2023-11-22',
          reviewCount: 11566,
          rating: 4,
          salesRank: 9031
        },
        {
          date: '2023-11-23',
          reviewCount: 11566,
          rating: 4,
          salesRank: 8513
        },
        {
          date: '2023-11-24',
          reviewCount: 11566,
          rating: 4,
          salesRank: 10760
        },
        {
          date: '2023-11-25',
          reviewCount: 11566,
          rating: 4,
          salesRank: 9020
        },
        {
          date: '2023-11-26',
          reviewCount: 11578,
          rating: 4,
          salesRank: 8773
        },
        {
          date: '2023-11-27',
          reviewCount: 11578,
          rating: 4,
          salesRank: 4712
        },
        {
          date: '2023-11-28',
          reviewCount: 11578,
          rating: 4,
          salesRank: 5760
        },
        {
          date: '2023-11-29',
          reviewCount: 11578,
          rating: 4,
          salesRank: 4127
        },
        {
          date: '2023-11-30',
          reviewCount: 11578,
          rating: 4,
          salesRank: 5536
        },
        {
          date: '2023-12-01',
          reviewCount: 11578,
          rating: 4,
          salesRank: 5378
        },
        {
          date: '2023-12-02',
          reviewCount: 11592,
          rating: 4,
          salesRank: 6262
        },
        {
          date: '2023-12-03',
          reviewCount: 11592,
          rating: 4,
          salesRank: 5944
        },
        {
          date: '2023-12-04',
          reviewCount: 11592,
          rating: 4,
          salesRank: 6177
        },
        {
          date: '2023-12-05',
          reviewCount: 11592,
          rating: 4,
          salesRank: 4741
        },
        {
          date: '2023-12-06',
          reviewCount: 11592,
          rating: 4,
          salesRank: 4758
        },
        {
          date: '2023-12-07',
          reviewCount: 11606,
          rating: 4,
          salesRank: 6060
        },
        {
          date: '2023-12-08',
          reviewCount: 11606,
          rating: 4,
          salesRank: 7703
        },
        {
          date: '2023-12-09',
          reviewCount: 11606,
          rating: 4,
          salesRank: 6457
        },
        {
          date: '2023-12-10',
          reviewCount: 11606,
          rating: 4,
          salesRank: 7354
        },
        {
          date: '2023-12-11',
          reviewCount: 11606,
          rating: 4,
          salesRank: 5865
        },
        {
          date: '2023-12-12',
          reviewCount: 11606,
          rating: 4,
          salesRank: 5846
        },
        {
          date: '2023-12-13',
          reviewCount: 11614,
          rating: 4,
          salesRank: 5548
        },
        {
          date: '2023-12-14',
          reviewCount: 11614,
          rating: 4,
          salesRank: 6526
        },
        {
          date: '2023-12-15',
          reviewCount: 11614,
          rating: 4,
          salesRank: 6330
        },
        {
          date: '2023-12-16',
          reviewCount: 11614,
          rating: 4,
          salesRank: 5380
        },
        {
          date: '2023-12-17',
          reviewCount: 11614,
          rating: 4,
          salesRank: 5816
        },
        {
          date: '2023-12-18',
          reviewCount: 11614,
          rating: 4,
          salesRank: 6917
        },
        {
          date: '2023-12-19',
          reviewCount: 11623,
          rating: 4,
          salesRank: 5311
        },
        {
          date: '2023-12-20',
          reviewCount: 11623,
          rating: 4,
          salesRank: 4939
        },
        {
          date: '2023-12-21',
          reviewCount: 11623,
          rating: 4,
          salesRank: 5643
        },
        {
          date: '2023-12-22',
          reviewCount: 11623,
          rating: 4,
          salesRank: 5477
        },
        {
          date: '2023-12-23',
          reviewCount: 11623,
          rating: 4,
          salesRank: 6130
        },
        {
          date: '2023-12-24',
          reviewCount: 11623,
          rating: 4,
          salesRank: 6121
        },
        {
          date: '2023-12-25',
          reviewCount: 11635,
          rating: 4,
          salesRank: 7051
        },
        {
          date: '2023-12-26',
          reviewCount: 11635,
          rating: 4,
          salesRank: 6060
        },
        {
          date: '2023-12-27',
          reviewCount: 11635,
          rating: 4,
          salesRank: 6225
        },
        {
          date: '2023-12-28',
          reviewCount: 11635,
          rating: 4,
          salesRank: 6476
        },
        {
          date: '2023-12-29',
          reviewCount: 11635,
          rating: 4,
          salesRank: 5953
        },
        {
          date: '2023-12-30',
          reviewCount: 11635,
          rating: 4,
          salesRank: 4597
        },
        {
          date: '2023-12-31',
          reviewCount: 11652,
          rating: 4,
          salesRank: 3734
        },
        {
          date: '2024-01-01',
          reviewCount: 11652,
          rating: 4,
          salesRank: 4813
        },
        {
          date: '2024-01-02',
          reviewCount: 11652,
          rating: 4,
          salesRank: 3332
        },
        {
          date: '2024-01-03',
          reviewCount: 11652,
          rating: 4,
          salesRank: 3378
        },
        {
          date: '2024-01-04',
          reviewCount: 11652,
          rating: 4,
          salesRank: 3092
        },
        {
          date: '2024-01-05',
          reviewCount: 11652,
          rating: 4,
          salesRank: 3686
        },
        {
          date: '2024-01-06',
          reviewCount: 11662,
          rating: 4,
          salesRank: 4523
        },
        {
          date: '2024-01-07',
          reviewCount: 11662,
          rating: 4,
          salesRank: 4061
        },
        {
          date: '2024-01-08',
          reviewCount: 11662,
          rating: 4,
          salesRank: 3763
        },
        {
          date: '2024-01-09',
          reviewCount: 11662,
          rating: 4,
          salesRank: 4271
        },
        {
          date: '2024-01-10',
          reviewCount: 11662,
          rating: 4,
          salesRank: 3765
        },
        {
          date: '2024-01-11',
          reviewCount: 11669,
          rating: 4,
          salesRank: 2995
        },
        {
          date: '2024-01-12',
          reviewCount: 11669,
          rating: 4,
          salesRank: 3166
        },
        {
          date: '2024-01-13',
          reviewCount: 11669,
          rating: 4,
          salesRank: 3058
        },
        {
          date: '2024-01-14',
          reviewCount: 11669,
          rating: 4,
          salesRank: 3705
        },
        {
          date: '2024-01-15',
          reviewCount: 11669,
          rating: 4,
          salesRank: 3385
        },
        {
          date: '2024-01-16',
          reviewCount: 11678,
          rating: 4,
          salesRank: 2535
        },
        {
          date: '2024-01-17',
          reviewCount: 11678,
          rating: 4,
          salesRank: 2880
        },
        {
          date: '2024-01-18',
          reviewCount: 11678,
          rating: 4,
          salesRank: 2511
        },
        {
          date: '2024-01-19',
          reviewCount: 11678,
          rating: 4,
          salesRank: 2530
        },
        {
          date: '2024-01-20',
          reviewCount: 11678,
          rating: 4,
          salesRank: 3487
        },
        {
          date: '2024-01-21',
          reviewCount: 11678,
          rating: 4,
          salesRank: 3161
        },
        {
          date: '2024-01-22',
          reviewCount: 11684,
          rating: 4,
          salesRank: 3127
        },
        {
          date: '2024-01-23',
          reviewCount: 11684,
          rating: 4,
          salesRank: 2766
        },
        {
          date: '2024-01-24',
          reviewCount: 11684,
          rating: 4,
          salesRank: 2688
        },
        {
          date: '2024-01-25',
          reviewCount: 11684,
          rating: 4,
          salesRank: 2345
        },
        {
          date: '2024-01-26',
          reviewCount: 11684,
          rating: 4,
          salesRank: 2832
        },
        {
          date: '2024-01-27',
          reviewCount: 11701,
          rating: 4,
          salesRank: 3182
        },
        {
          date: '2024-01-28',
          reviewCount: 11701,
          rating: 4,
          salesRank: 3339
        },
        {
          date: '2024-01-29',
          reviewCount: 11701,
          rating: 4,
          salesRank: 3645
        },
        {
          date: '2024-01-30',
          reviewCount: 11701,
          rating: 4,
          salesRank: 2876
        },
        {
          date: '2024-01-31',
          reviewCount: 11701,
          rating: 4,
          salesRank: 4027
        },
        {
          date: '2024-02-01',
          reviewCount: 11707,
          rating: 4,
          salesRank: 3636
        },
        {
          date: '2024-02-02',
          reviewCount: 11707,
          rating: 4,
          salesRank: 3442
        },
        {
          date: '2024-02-03',
          reviewCount: 11707,
          rating: 4,
          salesRank: 3654
        },
        {
          date: '2024-02-04',
          reviewCount: 11707,
          rating: 4,
          salesRank: 3033
        },
        {
          date: '2024-02-05',
          reviewCount: 11707,
          rating: 4,
          salesRank: 2929
        },
        {
          date: '2024-02-06',
          reviewCount: 11717,
          rating: 4,
          salesRank: 4621
        },
        {
          date: '2024-02-07',
          reviewCount: 11717,
          rating: 4,
          salesRank: 4702
        },
        {
          date: '2024-02-08',
          reviewCount: 11717,
          rating: 4,
          salesRank: 3685
        },
        {
          date: '2024-02-09',
          reviewCount: 11717,
          rating: 4,
          salesRank: 4144
        },
        {
          date: '2024-02-10',
          reviewCount: 11717,
          rating: 4,
          salesRank: 2936
        },
        {
          date: '2024-02-11',
          reviewCount: 11717,
          rating: 4,
          salesRank: 3791
        },
        {
          date: '2024-02-12',
          reviewCount: 11729,
          rating: 4,
          salesRank: 3626
        },
        {
          date: '2024-02-13',
          reviewCount: 11729,
          rating: 4,
          salesRank: 3326
        },
        {
          date: '2024-02-14',
          reviewCount: 11729,
          rating: 4,
          salesRank: 3441
        },
        {
          date: '2024-02-15',
          reviewCount: 11729,
          rating: 4,
          salesRank: 4613
        },
        {
          date: '2024-02-16',
          reviewCount: 11729,
          rating: 4,
          salesRank: 2993
        },
        {
          date: '2024-02-17',
          reviewCount: 11737,
          rating: 4,
          salesRank: 3297
        },
        {
          date: '2024-02-18',
          reviewCount: 11737,
          rating: 4,
          salesRank: 3302
        },
        {
          date: '2024-02-19',
          reviewCount: 11737,
          rating: 4,
          salesRank: 3207
        },
        {
          date: '2024-02-20',
          reviewCount: 11737,
          rating: 4,
          salesRank: 3712
        },
        {
          date: '2024-02-21',
          reviewCount: 11737,
          rating: 4,
          salesRank: 3046
        },
        {
          date: '2024-02-22',
          reviewCount: 11747,
          rating: 4,
          salesRank: 3972
        },
        {
          date: '2024-02-23',
          reviewCount: 11747,
          rating: 4,
          salesRank: 4148
        },
        {
          date: '2024-02-24',
          reviewCount: 11747,
          rating: 4,
          salesRank: 3526
        },
        {
          date: '2024-02-25',
          reviewCount: 11747,
          rating: 4,
          salesRank: 4150
        },
        {
          date: '2024-02-26',
          reviewCount: 11747,
          rating: 4,
          salesRank: 3038
        },
        {
          date: '2024-02-27',
          reviewCount: 11747,
          rating: 4,
          salesRank: 3339
        },
        {
          date: '2024-02-28',
          reviewCount: 11758,
          rating: 4,
          salesRank: 3838
        },
        {
          date: '2024-02-29',
          reviewCount: 11758,
          rating: 4,
          salesRank: 3235
        },
        {
          date: '2024-03-01',
          reviewCount: 11758,
          rating: 4,
          salesRank: 3769
        },
        {
          date: '2024-03-02',
          reviewCount: 11758,
          rating: 4,
          salesRank: 3500
        },
        {
          date: '2024-03-03',
          reviewCount: 11758,
          rating: 4,
          salesRank: 3989
        },
        {
          date: '2024-03-04',
          reviewCount: 11758,
          rating: 4,
          salesRank: 3389
        },
        {
          date: '2024-03-05',
          reviewCount: 11770,
          rating: 4,
          salesRank: 3022
        },
        {
          date: '2024-03-06',
          reviewCount: 11770,
          rating: 4,
          salesRank: 3643
        },
        {
          date: '2024-03-07',
          reviewCount: 11770,
          rating: 4,
          salesRank: 3722
        },
        {
          date: '2024-03-08',
          reviewCount: 11770,
          rating: 4,
          salesRank: 4203
        },
        {
          date: '2024-03-09',
          reviewCount: 11770,
          rating: 4,
          salesRank: 4544
        },
        {
          date: '2024-03-10',
          reviewCount: 11787,
          rating: 4,
          salesRank: 5513
        },
        {
          date: '2024-03-11',
          reviewCount: 11787,
          rating: 4,
          salesRank: 4223
        },
        {
          date: '2024-03-12',
          reviewCount: 11787,
          rating: 4,
          salesRank: 3427
        },
        {
          date: '2024-03-13',
          reviewCount: 11787,
          rating: 4,
          salesRank: 3793
        },
        {
          date: '2024-03-14',
          reviewCount: 11787,
          rating: 4,
          salesRank: 3553
        },
        {
          date: '2024-03-15',
          reviewCount: 11791,
          rating: 4,
          salesRank: 4450
        },
        {
          date: '2024-03-16',
          reviewCount: 11791,
          rating: 4,
          salesRank: 3374
        },
        {
          date: '2024-03-17',
          reviewCount: 11791,
          rating: 4,
          salesRank: 4186
        },
        {
          date: '2024-03-18',
          reviewCount: 11791,
          rating: 4,
          salesRank: 3697
        },
        {
          date: '2024-03-19',
          reviewCount: 11791,
          rating: 4,
          salesRank: 4204
        },
        {
          date: '2024-03-20',
          reviewCount: 11799,
          rating: 4,
          salesRank: 4354
        },
        {
          date: '2024-03-21',
          reviewCount: 11799,
          rating: 4,
          salesRank: 4530
        },
        {
          date: '2024-03-22',
          reviewCount: 11799,
          rating: 4,
          salesRank: 4630
        },
        {
          date: '2024-03-23',
          reviewCount: 11799,
          rating: 4,
          salesRank: 3693
        },
        {
          date: '2024-03-24',
          reviewCount: 11799,
          rating: 4,
          salesRank: 3684
        },
        {
          date: '2024-03-25',
          reviewCount: 11799,
          rating: 4,
          salesRank: 4327
        },
        {
          date: '2024-03-26',
          reviewCount: 11813,
          rating: 4,
          salesRank: 3992
        },
        {
          date: '2024-03-27',
          reviewCount: 11813,
          rating: 4,
          salesRank: 3641
        },
        {
          date: '2024-03-28',
          reviewCount: 11813,
          rating: 4,
          salesRank: 3636
        },
        {
          date: '2024-03-29',
          reviewCount: 11813,
          rating: 4,
          salesRank: 2668
        },
        {
          date: '2024-03-30',
          reviewCount: 11813,
          rating: 4,
          salesRank: 2709
        },
        {
          date: '2024-03-31',
          reviewCount: 11813,
          rating: 4,
          salesRank: 2953
        },
        {
          date: '2024-04-01',
          reviewCount: 11813,
          rating: 4,
          salesRank: 3984
        },
        {
          date: '2024-04-02',
          reviewCount: 11813,
          rating: 4,
          salesRank: 4051
        },
        {
          date: '2024-04-03',
          reviewCount: 11813,
          rating: 4,
          salesRank: 3329
        },
        {
          date: '2024-04-04',
          reviewCount: 11813,
          rating: 4,
          salesRank: 3688
        },
        {
          date: '2024-04-05',
          reviewCount: 11813,
          rating: 4,
          salesRank: 4017
        },
        {
          date: '2024-04-06',
          reviewCount: 11831,
          rating: 4,
          salesRank: 4169
        },
        {
          date: '2024-04-07',
          reviewCount: 11831,
          rating: 4,
          salesRank: 3411
        },
        {
          date: '2024-04-08',
          reviewCount: 11831,
          rating: 4,
          salesRank: 3229
        },
        {
          date: '2024-04-09',
          reviewCount: 11831,
          rating: 4,
          salesRank: 3217
        },
        {
          date: '2024-04-10',
          reviewCount: 11831,
          rating: 4,
          salesRank: 3176
        },
        {
          date: '2024-04-11',
          reviewCount: 11839,
          rating: 4,
          salesRank: 3059
        },
        {
          date: '2024-04-12',
          reviewCount: 11839,
          rating: 4,
          salesRank: 3633
        },
        {
          date: '2024-04-13',
          reviewCount: 11839,
          rating: 4,
          salesRank: 3452
        },
        {
          date: '2024-04-14',
          reviewCount: 11839,
          rating: 4,
          salesRank: 3564
        },
        {
          date: '2024-04-15',
          reviewCount: 11839,
          rating: 4,
          salesRank: 2945
        },
        {
          date: '2024-04-16',
          reviewCount: 11839,
          rating: 4,
          salesRank: 3289
        },
        {
          date: '2024-04-17',
          reviewCount: 11846,
          rating: 4,
          salesRank: 5259
        },
        {
          date: '2024-04-18',
          reviewCount: 11846,
          rating: 4,
          salesRank: 4851
        },
        {
          date: '2024-04-19',
          reviewCount: 11846,
          rating: 4,
          salesRank: 4690
        },
        {
          date: '2024-04-20',
          reviewCount: 11846,
          rating: 4,
          salesRank: 5341
        },
        {
          date: '2024-04-21',
          reviewCount: 11846,
          rating: 4,
          salesRank: 4972
        },
        {
          date: '2024-04-22',
          reviewCount: 11856,
          rating: 4,
          salesRank: 5539
        },
        {
          date: '2024-04-23',
          reviewCount: 11856,
          rating: 4,
          salesRank: 3823
        },
        {
          date: '2024-04-24',
          reviewCount: 11856,
          rating: 4,
          salesRank: 4631
        },
        {
          date: '2024-04-25',
          reviewCount: 11856,
          rating: 4,
          salesRank: 4255
        },
        {
          date: '2024-04-26',
          reviewCount: 11856,
          rating: 4,
          salesRank: 4116
        },
        {
          date: '2024-04-27',
          reviewCount: 11856,
          rating: 4,
          salesRank: 3950
        },
        {
          date: '2024-04-28',
          reviewCount: 11871,
          rating: 4,
          salesRank: 4064
        },
        {
          date: '2024-04-29',
          reviewCount: 11871,
          rating: 4,
          salesRank: 3459
        },
        {
          date: '2024-04-30',
          reviewCount: 11871,
          rating: 4,
          salesRank: 6518
        },
        {
          date: '2024-05-01',
          reviewCount: 11871,
          rating: 4,
          salesRank: 4820
        },
        {
          date: '2024-05-02',
          reviewCount: 11871,
          rating: 4,
          salesRank: 5387
        },
        {
          date: '2024-05-03',
          reviewCount: 11871,
          rating: 4,
          salesRank: 5770
        },
        {
          date: '2024-05-04',
          reviewCount: 11889,
          rating: 4,
          salesRank: 4132
        },
        {
          date: '2024-05-05',
          reviewCount: 11889,
          rating: 4,
          salesRank: 4089
        },
        {
          date: '2024-05-06',
          reviewCount: 11889,
          rating: 4,
          salesRank: 4441
        },
        {
          date: '2024-05-07',
          reviewCount: 11889,
          rating: 4,
          salesRank: 3817
        },
        {
          date: '2024-05-08',
          reviewCount: 11889,
          rating: 4,
          salesRank: 5319
        },
        {
          date: '2024-05-09',
          reviewCount: 11889,
          rating: 4,
          salesRank: 6141
        },
        {
          date: '2024-05-10',
          reviewCount: 11903,
          rating: 4,
          salesRank: 6748
        },
        {
          date: '2024-05-11',
          reviewCount: 11903,
          rating: 4,
          salesRank: 9658
        },
        {
          date: '2024-05-12',
          reviewCount: 11903,
          rating: 4,
          salesRank: 8316
        },
        {
          date: '2024-05-13',
          reviewCount: 11903,
          rating: 4,
          salesRank: 7882
        },
        {
          date: '2024-05-14',
          reviewCount: 11903,
          rating: 4,
          salesRank: 6533
        },
        {
          date: '2024-05-15',
          reviewCount: 11903,
          rating: 4,
          salesRank: 6135
        },
        {
          date: '2024-05-16',
          reviewCount: 11911,
          rating: 4,
          salesRank: 7918
        },
        {
          date: '2024-05-17',
          reviewCount: 11911,
          rating: 4,
          salesRank: 5244
        },
        {
          date: '2024-05-18',
          reviewCount: 11911,
          rating: 4,
          salesRank: 5101
        },
        {
          date: '2024-05-19',
          reviewCount: 11911,
          rating: 4,
          salesRank: 5947
        },
        {
          date: '2024-05-20',
          reviewCount: 11911,
          rating: 4,
          salesRank: 5043
        },
        {
          date: '2024-05-21',
          reviewCount: 11911,
          rating: 4,
          salesRank: 4554
        },
        {
          date: '2024-05-22',
          reviewCount: 11923,
          rating: 4,
          salesRank: 5526
        },
        {
          date: '2024-05-23',
          reviewCount: 11923,
          rating: 4,
          salesRank: 5513
        },
        {
          date: '2024-05-24',
          reviewCount: 11923,
          rating: 4,
          salesRank: 5585
        },
        {
          date: '2024-05-25',
          reviewCount: 11923,
          rating: 4,
          salesRank: 5890
        },
        {
          date: '2024-05-26',
          reviewCount: 11923,
          rating: 4,
          salesRank: 4546
        },
        {
          date: '2024-05-27',
          reviewCount: 11932,
          rating: 4,
          salesRank: 6553
        },
        {
          date: '2024-05-28',
          reviewCount: 11932,
          rating: 4,
          salesRank: 4608
        },
        {
          date: '2024-05-29',
          reviewCount: 11932,
          rating: 4,
          salesRank: 5882
        },
        {
          date: '2024-05-30',
          reviewCount: 11932,
          rating: 4,
          salesRank: 4822
        },
        {
          date: '2024-05-31',
          reviewCount: 11932,
          rating: 4,
          salesRank: 7086
        },
        {
          date: '2024-06-01',
          reviewCount: 11932,
          rating: 4,
          salesRank: 6418
        },
        {
          date: '2024-06-02',
          reviewCount: 11943,
          rating: 4,
          salesRank: 5351
        },
        {
          date: '2024-06-03',
          reviewCount: 11943,
          rating: 4,
          salesRank: 4060
        },
        {
          date: '2024-06-04',
          reviewCount: 11943,
          rating: 4,
          salesRank: 5594
        },
        {
          date: '2024-06-05',
          reviewCount: 11943,
          rating: 4,
          salesRank: 4860
        },
        {
          date: '2024-06-06',
          reviewCount: 11943,
          rating: 4,
          salesRank: 4700
        },
        {
          date: '2024-06-07',
          reviewCount: 11943,
          rating: 4,
          salesRank: 4870
        },
        {
          date: '2024-06-08',
          reviewCount: 11955,
          rating: 4,
          salesRank: 4820
        },
        {
          date: '2024-06-09',
          reviewCount: 11955,
          rating: 4,
          salesRank: 5180
        },
        {
          date: '2024-06-10',
          reviewCount: 11955,
          rating: 4,
          salesRank: 6755
        },
        {
          date: '2024-06-11',
          reviewCount: 11955,
          rating: 4,
          salesRank: 5643
        },
        {
          date: '2024-06-12',
          reviewCount: 11955,
          rating: 4,
          salesRank: 4882
        },
        {
          date: '2024-06-13',
          reviewCount: 11966,
          rating: 4,
          salesRank: 4504
        },
        {
          date: '2024-06-14',
          reviewCount: 11966,
          rating: 4,
          salesRank: 4162
        },
        {
          date: '2024-06-15',
          reviewCount: 11966,
          rating: 4,
          salesRank: 3820
        },
        {
          date: '2024-06-16',
          reviewCount: 11966,
          rating: 4,
          salesRank: 3909
        },
        {
          date: '2024-06-17',
          reviewCount: 11966,
          rating: 4,
          salesRank: 5556
        },
        {
          date: '2024-06-18',
          reviewCount: 11966,
          rating: 4,
          salesRank: 4349
        },
        {
          date: '2024-06-19',
          reviewCount: 11977,
          rating: 4,
          salesRank: 3958
        },
        {
          date: '2024-06-20',
          reviewCount: 11977,
          rating: 4,
          salesRank: 3327
        },
        {
          date: '2024-06-21',
          reviewCount: 11977,
          rating: 4,
          salesRank: 4554
        },
        {
          date: '2024-06-22',
          reviewCount: 11977,
          rating: 4,
          salesRank: 4887
        },
        {
          date: '2024-06-23',
          reviewCount: 11977,
          rating: 4,
          salesRank: 3260
        },
        {
          date: '2024-06-24',
          reviewCount: 11986,
          rating: 4,
          salesRank: 3659
        },
        {
          date: '2024-06-25',
          reviewCount: 11986,
          rating: 4,
          salesRank: 3255
        },
        {
          date: '2024-06-26',
          reviewCount: 11986,
          rating: 4,
          salesRank: 4807
        },
        {
          date: '2024-06-27',
          reviewCount: 11986,
          rating: 4,
          salesRank: 3236
        },
        {
          date: '2024-06-28',
          reviewCount: 11986,
          rating: 4,
          salesRank: 4088
        },
        {
          date: '2024-06-29',
          reviewCount: 11986,
          rating: 4,
          salesRank: 3602
        },
        {
          date: '2024-06-30',
          reviewCount: 12000,
          rating: 4,
          salesRank: 2586
        },
        {
          date: '2024-07-01',
          reviewCount: 12000,
          rating: 4,
          salesRank: 2623
        },
        {
          date: '2024-07-02',
          reviewCount: 12000,
          rating: 4,
          salesRank: 3130
        },
        {
          date: '2024-07-03',
          reviewCount: 12000,
          rating: 4,
          salesRank: 3303
        },
        {
          date: '2024-07-04',
          reviewCount: 12000,
          rating: 4,
          salesRank: 2887
        },
        {
          date: '2024-07-05',
          reviewCount: 12007,
          rating: 4,
          salesRank: 2458
        },
        {
          date: '2024-07-06',
          reviewCount: 12007,
          rating: 4,
          salesRank: 2220
        },
        {
          date: '2024-07-07',
          reviewCount: 12007,
          rating: 4,
          salesRank: 2001
        },
        {
          date: '2024-07-08',
          reviewCount: 12007,
          rating: 4,
          salesRank: 2075
        },
        {
          date: '2024-07-09',
          reviewCount: 12007,
          rating: 4,
          salesRank: 2082
        },
        {
          date: '2024-07-10',
          reviewCount: 12007,
          rating: 4,
          salesRank: 1808
        },
        {
          date: '2024-07-11',
          reviewCount: 12019,
          rating: 4,
          salesRank: 1791
        },
        {
          date: '2024-07-12',
          reviewCount: 12019,
          rating: 4,
          salesRank: 1505
        },
        {
          date: '2024-07-13',
          reviewCount: 12019,
          rating: 4,
          salesRank: 1816
        },
        {
          date: '2024-07-14',
          reviewCount: 12019,
          rating: 4,
          salesRank: 1406
        },
        {
          date: '2024-07-15',
          reviewCount: 12019,
          rating: 4,
          salesRank: 1103
        },
        {
          date: '2024-07-16',
          reviewCount: 12019,
          rating: 4,
          salesRank: 2370
        },
        {
          date: '2024-07-17',
          reviewCount: 12035,
          rating: 4,
          salesRank: 2267
        },
        {
          date: '2024-07-18',
          reviewCount: 12035,
          rating: 4,
          salesRank: 2085
        },
        {
          date: '2024-07-19',
          reviewCount: 12035,
          rating: 4,
          salesRank: 1694
        },
        {
          date: '2024-07-20',
          reviewCount: 12035,
          rating: 4,
          salesRank: 1000
        },
        {
          date: '2024-07-21',
          reviewCount: 12035,
          rating: 4,
          salesRank: 827
        },
        {
          date: '2024-07-22',
          reviewCount: 12043,
          rating: 4,
          salesRank: 714
        },
        {
          date: '2024-07-23',
          reviewCount: 12043,
          rating: 4,
          salesRank: 562
        },
        {
          date: '2024-07-24',
          reviewCount: 12043,
          rating: 4,
          salesRank: 534
        },
        {
          date: '2024-07-25',
          reviewCount: 12043,
          rating: 4,
          salesRank: 460
        },
        {
          date: '2024-07-26',
          reviewCount: 12043,
          rating: 4,
          salesRank: 422
        },
        {
          date: '2024-07-27',
          reviewCount: 12043,
          rating: 4,
          salesRank: 335
        },
        {
          date: '2024-07-28',
          reviewCount: 12056,
          rating: 4,
          salesRank: 314
        },
        {
          date: '2024-07-29',
          reviewCount: 12056,
          rating: 4,
          salesRank: 262
        },
        {
          date: '2024-07-30',
          reviewCount: 12056,
          rating: 4,
          salesRank: 207
        },
        {
          date: '2024-07-31',
          reviewCount: 12056,
          rating: 4,
          salesRank: 171
        },
        {
          date: '2024-08-01',
          reviewCount: 12056,
          rating: 4,
          salesRank: 211
        },
        {
          date: '2024-08-02',
          reviewCount: 12064,
          rating: 4,
          salesRank: 223
        },
        {
          date: '2024-08-03',
          reviewCount: 12064,
          rating: 4,
          salesRank: 206
        },
        {
          date: '2024-08-04',
          reviewCount: 12064,
          rating: 4,
          salesRank: 176
        },
        {
          date: '2024-08-05',
          reviewCount: 12074,
          rating: 4,
          salesRank: 216
        },
        {
          date: '2024-08-06',
          reviewCount: 12074,
          rating: 4,
          salesRank: 203
        },
        {
          date: '2024-08-07',
          reviewCount: 12074,
          rating: 4,
          salesRank: 218
        },
        {
          date: '2024-08-08',
          reviewCount: 12074,
          rating: 4
        },
        {
          date: '2024-08-09',
          reviewCount: 12080,
          rating: 4,
          salesRank: 221
        },
        {
          date: '2024-08-10',
          reviewCount: 12080,
          rating: 4,
          salesRank: 259
        },
        {
          date: '2024-08-11',
          reviewCount: 12080,
          rating: 4,
          salesRank: 277
        },
        {
          date: '2024-08-12',
          reviewCount: 12087,
          rating: 4,
          salesRank: 299
        },
        {
          date: '2024-08-13',
          reviewCount: 12087,
          rating: 4,
          salesRank: 316
        },
        {
          date: '2024-08-14',
          reviewCount: 12087,
          rating: 4,
          salesRank: 369
        },
        {
          date: '2024-08-15',
          reviewCount: 12087,
          rating: 4,
          salesRank: 475
        },
        {
          date: '2024-08-16',
          reviewCount: 12087,
          rating: 4,
          salesRank: 476
        },
        {
          date: '2024-08-17',
          reviewCount: 12098,
          rating: 4
        },
        {
          date: '2024-08-18',
          reviewCount: 12098,
          rating: 4
        },
        {
          date: '2024-08-19',
          reviewCount: 12098,
          rating: 4,
          salesRank: 1125
        },
        {
          date: '2024-08-20',
          reviewCount: 12105,
          rating: 4,
          salesRank: 1182
        },
        {
          date: '2024-08-21',
          reviewCount: 12105,
          rating: 4,
          salesRank: 1103
        },
        {
          date: '2024-08-22',
          reviewCount: 12105,
          rating: 4,
          salesRank: 1865
        },
        {
          date: '2024-08-23',

          salesRank: 1701
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,

      createdAt: '2024-08-23T18:25:37.947Z'
    }
  ]
}

import { Stack } from '@mui/material'
import { Outlet, useMatch } from 'react-router-dom'
import BrandHeader from './components/brand-header'
import BrandTabs from './components/brand-tabs'
import { ROUTES } from '@momentum/routes/RouteNames'
import Loading from '@momentum/components/loading'

export const Brand = () => {
  const routeMatch = useMatch(ROUTES.BRAND)

  return (
    <Stack height={'100%'}>
      <BrandHeader />
      <BrandTabs />
      {routeMatch ? <Loading /> : <Outlet />}
    </Stack>
  )
}

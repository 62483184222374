import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input'
import { InputBase } from '@productwindtom/ui-base'
import { useFormContext, useController } from 'react-hook-form'
import React from 'react'
/*

Tried to put this in story book but test results in this error:

 /Users/geoffreykinson/IdeaProjects/productwind/node_modules/mui-tel-input/dist/mui-tel-input.es.js:1
    ({"Object.<anonymous>":function(module,exports,require,__dirname,__filename,jest){import { jsx as N, jsxs as H, Fragment as Fe } from "react/jsx-runtime";
                                                                                      ^^^^^^

    SyntaxError: Cannot use import statement outside a module

 */

export const PhoneInput = ({
  name,
  primaryText,
  contentEditable,
  fullWidth,
  ...props
}: { primaryText: string; name: string } & MuiTelInputProps) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value }
  } = useController({ name, control })

  return (
    <InputBase name={name} primaryText={primaryText} fullWidth={fullWidth}>
      <MuiTelInput
        {...props}
        fullWidth={fullWidth}
        contentEditable={contentEditable as any}
        value={value}
        onChange={v => {
          if (v.length === 0) {
            onChange('+')
          } else {
            onChange(v)
          }
        }}
      />
    </InputBase>
  )
}

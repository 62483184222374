import { useParams, Navigate } from 'react-router-dom'
import React from 'react'
import { Container, Stack } from '@mui/system'
import { Typography, Box } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'

import { ROUTES } from '@momentum/routes/RouteNames'
import background from '@momentum/routes/account/background-gradient.png'
import { Form, TextInput, SubmitButton } from '@productwindtom/ui-base'
import LogoWhiteIcon from '@momentum/components/icons/logo-white'
import { passwordValidator } from '@momentum/routes/account/utils'

type SetNewPasswordData = {
  email: string
  tempPassword: string
  password: string
  confirmPassword: string
}

const setNewPasswordSchema = yup.object().shape({
  email: yup.string().required(),
  tempPassword: yup.string().required(),
  password: passwordValidator,
  confirmPassword: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password')], 'Your passwords do not match.')
})

export const Join = () => {
  const { email, tempPassword } = useParams<{ email: string; tempPassword: string }>()

  const onSubmit = async (data: SetNewPasswordData) => {
    try {
      const resp = await Auth.signIn(data.email, data.tempPassword)
      if (resp.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(resp, data.password)
      }

      toast(<Typography variant={'subtitle2'}>Password successfully set!</Typography>, { type: 'success' })
      // navigate
    } catch (e: any) {
      console.log(e.code)
    }
  }

  if (!email || !tempPassword) {
    return <Navigate to={ROUTES.LOGIN} replace={true} />
  }

  const defaultValues = {
    email: email ?? '',
    tempPassword: tempPassword ?? '',
    password: '',
    confirmPassword: ''
  }

  return (
    <Container maxWidth={'xl'} sx={{ height: '100vh', alignItems: 'center' }}>
      <Stack direction={'row'} spacing={10} height={'100vh'}>
        <Box py={2} width={'70%'} display={'inline-flex'}>
          <Box
            bgcolor={theme => theme.palette.primary.main}
            borderRadius={'16px'}
            display={'inline-flex'}
            width={'100%'}
            position={'relative'}
          >
            <Stack direction={'row'} spacing={1} alignItems={'center'} position={'absolute'} left={24} top={24}>
              <LogoWhiteIcon fontSize={'large'} />
              <Typography variant={'h3'} mb={2} color={'white'}>
                momentum
              </Typography>
            </Stack>
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              spacing={2}
              width={'100%'}
              sx={{
                background: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            >
              <Typography color={'white'} variant={'h2'} width={'226px'}>
                Welcome to Momentum
              </Typography>
              <Typography color={'white'} variant={'subtitle2'} width={'226px'}>
                Complete your new password to join Momentum.
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box alignSelf={'center'} width={'30%'}>
          <Form onSubmit={onSubmit} defaultValues={defaultValues} resolver={yupResolver(setNewPasswordSchema)}>
            <Stack spacing={2} alignItems={'center'}>
              <Typography variant={'h2'}>Set New Password</Typography>
              <TextInput
                name={'password'}
                primaryText={'Password'}
                type={'password'}
                fullWidth
                autoComplete={'new-password'}
              />
              <TextInput
                name={'confirmPassword'}
                primaryText={'Re-enter Password'}
                type={'password'}
                fullWidth
                autoComplete={'new-password'}
              />
              <Stack direction={'row'} justifyContent={'flex-start'} width={'100%'}>
                <SubmitButton variant={'contained'}>Set password</SubmitButton>
              </Stack>
            </Stack>
          </Form>
        </Box>
      </Stack>
    </Container>
  )
}

import { Box, BoxProps, Stack, Typography } from '@mui/material'
import { ImageNotSupportedOutlined } from '@mui/icons-material'

export const NoPreview = (props: BoxProps) => (
  <Box
    {...props}
    bgcolor={theme => theme.palette.grey.A200}
    display={'flex'}
    alignItems={'center'}
    justifyContent={'center'}
  >
    <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} spacing={1}>
      <ImageNotSupportedOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
      <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
        No preview available
      </Typography>
    </Stack>
  </Box>
)

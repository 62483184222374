import { Box, Button, Paper, Stack } from '@mui/material'
import { useApproveContentContext } from '../context/ApproveContentContext'
import { ApproveContentViewMode, ContentEditsFormData } from '../types'
import { ChevronLeft } from '@mui/icons-material'
import { CheckInput, Form, SelectInput, SubmitButton, SwitchInput, TextInput } from '@productwindtom/ui-base'
import Row from '@momentum/components/row'
import LogoWhiteIcon from '@momentum/components/icons/logo-white'
import { useFormContext } from 'react-hook-form'
import ReviewFeedback from './ReviewFeedback'
import { DateTime } from 'luxon'
import { isVideo } from '@productwindtom/shared-node'
import SendIcon from '@mui/icons-material/Send'

const RequestEdits = () => {
  const { setApproveContentViewMode, content, currentVideoPosition, selectedAsset } = useApproveContentContext()
  const { reviewFeedback } = content
  const { setValue, watch } = useFormContext<ContentEditsFormData>()

  const message = watch('message')

  const asset = content.draftMedia?.[selectedAsset]
  
  return (
    <Stack spacing={3} alignItems={'flex-start'}>
      <Button
        sx={{
          color: 'black'
        }}
        variant="text"
        onClick={() => setApproveContentViewMode(ApproveContentViewMode.REVIEW_CONTENT)}
      >
        <ChevronLeft />
        Back
      </Button>
      {!!reviewFeedback?.length && <ReviewFeedback />}
      <Paper elevation={0} sx={{ p: 1, width: '100%', boxSizing: 'border-box' }}>
        <Row spacing={1} alignItems={'flex-start'}>
          <Box
            position={'relative'}
            bgcolor={theme => theme.palette.primary.dark}
            borderRadius={'50%'}
            width={24}
            height={24}
            minWidth={24}
            minHeight={24}
            display={'flex'}
            alignContent={'center'}
            alignItems={'center'}
            p={0.5}
            boxSizing={'border-box'}
          >
            <LogoWhiteIcon
              sx={{
                width: '100%'
              }}
            />
          </Box>
          <TextInput
            fullWidth
            multiline
            minRows={4}
            name="message"
            placeholder="Leave your comment..."
            sx={{
              border: 'none'
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true
            }}
          />

          <SubmitButton
            onClick={() => {
              setValue('shouldSendToCreator', false)
            }}
            variant="text"
            disabled={!message?.trim()}
            sx={{
              alignSelf: 'center',
              minWidth: 0
            }}
          >
            <SendIcon />
          </SubmitButton>
        </Row>
        <Row justifyContent={'space-between'} spacing={2}>
          {!!asset && isVideo(asset) && (
            <CheckInput
              name="atTime"
              options={[{ value: 'true', label: DateTime.fromMillis(currentVideoPosition * 1000).toFormat('mm:ss') }]}
              checkboxProps={{
                size: 'medium'
              }}
            />
          )}
          <SelectInput
            name="visibleToUser"
            fullWidth
            disableClearable
            options={[
              {
                label: 'Show to creator',
                value: true
              },
              {
                label: 'Internal only',
                value: false
              }
            ]}
          />
        </Row>
      </Paper>

      <SubmitButton
        onClick={() => {
          setValue('shouldSendToCreator', true)
        }}
        variant="contained"
        disableOnDirty={false}
      >
        Send edits to creator
      </SubmitButton>
    </Stack>
  )
}

export default () => {
  const { handleContentEdits } = useApproveContentContext()

  return (
    <Form
      onSubmit={handleContentEdits as any}
      defaultValues={{
        visibleToUser: true,
        message: ''
      }}
    >
      <RequestEdits />
    </Form>
  )
}

import { TableHeadCell } from '@momentum/routes/campaign/e-commerce/common/TableHeadCell'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material'
import { first, last, sumBy } from 'lodash'
import { useSalesContext } from '../salesContext'
import { useValueFormatter } from '../utils'

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body2
}))

export const SalesTable = () => {
  const { filteredSalesData, isIntegrated } = useSalesContext()
  const valueFormatter = useValueFormatter()

  const minDate = first(filteredSalesData)?.date
  const maxDate = last(filteredSalesData)?.date

  if (!minDate || !maxDate) return null

  const weeks = Math.ceil(maxDate.diff(minDate, 'weeks').weeks || 1)
  const weeksArray = new Array(weeks).fill(0)

  const weekData = weeksArray.map((_, index) => {
    const start = minDate.plus({ weeks: index })
    const end = start.plus({ weeks: 1 })
    const weekRangeData = filteredSalesData.filter(d => d.date >= start && d.date < end)
    return {
      start,
      end,
      fromMomentum: sumBy(weekRangeData, i => i.fromMomentum),
      fromOtherCustomers: Math.max(
        0,
        sumBy(weekRangeData, i => i.fromOtherCustomers)
      ),
      totalSum: sumBy(weekRangeData, i => i.total)
    }
  })

  return (
    <TableContainer>
      <Table
        sx={{
          display: 'block',
          overflowX: 'auto',
          whiteSpace: 'nowrap'
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {weekData.map((d, index) => (
              <TableHeadCell key={index} week={index + 1} date={d.start} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <BodyTableCell>Activated creators</BodyTableCell>
            {weekData.map((d, index) => (
              <BodyTableCell key={index}>{valueFormatter(d.fromMomentum)}</BodyTableCell>
            ))}
          </TableRow>
          {isIntegrated && (
            <TableRow>
              <BodyTableCell>Other customers</BodyTableCell>
              {weekData.map((d, index) => (
                <BodyTableCell key={index}>{valueFormatter(Math.max(0, d.fromOtherCustomers))}</BodyTableCell>
              ))}
            </TableRow>
          )}
          {isIntegrated && (
            <TableRow>
              <BodyTableCell>Total</BodyTableCell>
              {weekData.map((d, index) => (
                <BodyTableCell key={index}>{valueFormatter(d.totalSum)}</BodyTableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import Loading from '@momentum/components/loading'
import { PricingForm, PricingFormValues, schema } from '@momentum/components/pricing-form'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { createPricing, deletePricing, updatePricing } from '@momentum/routes/company/queries'
import { Company } from '@momentum/routes/queries'
import { Typography } from '@mui/material'
import { createRegionalStorePricings, getCompanyStorePricing } from '@productwindtom/shared-momentum'
import { Form } from '@productwindtom/ui-base'
import { captureException } from '@sentry/react'
import { useMemo } from 'react'
import { toast } from 'react-toastify'

export const CompanyPricing = ({ company }: { company: Company }) => {
  const { refreshCompany, selectedCompany, agencies } = useUserSessionContext()

  const companyPricing = useMemo(
    () =>
      company
        ? getCompanyStorePricing(
            company?.pricing,
            (agencies.find(a => a.id === company?.agencyId) ?? company.agency)?.clientPricing
          )
        : undefined,
    [selectedCompany, agencies]
  )

  const handleSubmit = async (submitValues: PricingFormValues) => {
    try {
      await Promise.all(
        createRegionalStorePricings(company.id, submitValues.pricing).map(input => {
          const anyNotBlank = !!input.creatorPricing.find(cp => cp.price)

          if ((company.pricing || []).find(p => p.store === input.store)) {
            if (anyNotBlank) {
              return updatePricing(input)
            } else {
              return deletePricing({ id: company.id, store: input.store })
            }
          } else if (anyNotBlank) {
            return createPricing(input)
          }
        })
      )
      await refreshCompany(company.id)
      toast(<Typography variant={'subtitle2'}>Company pricing saved!</Typography>, { type: 'success' })
    } catch (error) {
      captureException(error)
      toast(<Typography variant={'subtitle2'}>Failed to save company pricing!</Typography>, { type: 'error' })
    }
  }

  if (!companyPricing) {
    return <Loading />
  }

  const defaultValues = {
    pricing: companyPricing
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <PricingForm initialValues={defaultValues} title={'Company pricing'} submitText={'Save company pricing'} />
    </Form>
  )
}

import { SvgIconProps, SvgIcon } from '@mui/material'
import React from 'react'

export const EmptyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 202 201" fill="none">
      <path
        d="M100.999 200.17C156.274 200.17 201.084 155.36 201.084 100.085C201.084 44.8096 156.274 0 100.999 0C45.7236 0 0.914062 44.8096 0.914062 100.085C0.914062 155.36 45.7236 200.17 100.999 200.17Z"
        fill="#94D5D9"
      />
      <path
        d="M167.882 67.6484H30.2046C27.5764 67.6484 25.4375 65.5173 25.4375 62.8812V29.0777C25.4375 26.4494 27.5685 24.3105 30.2046 24.3105H167.882C170.51 24.3105 172.649 26.4416 172.649 29.0777V62.8812C172.649 65.5173 170.518 67.6484 167.882 67.6484Z"
        fill="white"
      />
      <path
        d="M167.882 68.8325H30.2049C26.9216 68.8325 24.2539 66.1648 24.2539 62.8815V29.0779C24.2539 25.7947 26.9216 23.127 30.2049 23.127H167.882C171.165 23.127 173.833 25.7947 173.833 29.0779V62.8815C173.833 66.1648 171.165 68.8325 167.882 68.8325ZM30.2049 25.5026C28.2318 25.5026 26.6217 27.1127 26.6217 29.0858V62.8894C26.6217 64.8626 28.2318 66.4727 30.2049 66.4727H167.882C169.855 66.4727 171.465 64.8705 171.465 62.8894V29.0858C171.465 27.1127 169.855 25.5026 167.882 25.5026H30.2049Z"
        fill="#324BA8"
      />
      <path
        d="M162.709 40.2293H83.701C82.8557 40.2293 82.1641 39.5189 82.1641 38.6508C82.1641 37.7826 82.8557 37.0723 83.701 37.0723H162.709C163.555 37.0723 164.246 37.7826 164.246 38.6508C164.246 39.5189 163.555 40.2293 162.709 40.2293Z"
        fill="#324BA8"
      />
      <path
        d="M127.62 54.8933H83.701C82.8557 54.8933 82.1641 54.183 82.1641 53.3148C82.1641 52.4467 82.8557 51.7363 83.701 51.7363H127.62C128.465 51.7363 129.157 52.4467 129.157 53.3148C129.157 54.183 128.473 54.8933 127.62 54.8933Z"
        fill="#324BA8"
      />
      <path
        d="M40.7202 52.2296C43.9916 52.2296 46.6436 49.5776 46.6436 46.3062C46.6436 43.0348 43.9916 40.3828 40.7202 40.3828C37.4488 40.3828 34.7969 43.0348 34.7969 46.3062C34.7969 49.5776 37.4488 52.2296 40.7202 52.2296Z"
        fill="#12B76A"
      />
      <path
        d="M43.4878 43.9324L39.4678 47.0481L38.2358 45.4251C38.0383 45.1645 37.6671 45.1131 37.4065 45.3106C37.1459 45.508 37.0946 45.8792 37.292 46.1398L38.8873 48.2407C38.9821 48.3671 39.1243 48.45 39.2822 48.4697C39.3099 48.4737 39.3336 48.4737 39.3612 48.4737C39.4916 48.4737 39.6179 48.4303 39.7245 48.3513L44.2184 44.8683C44.4751 44.6669 44.5225 44.2957 44.325 44.039C44.1276 43.7824 43.7445 43.735 43.4878 43.9324Z"
        fill="white"
      />
      <path
        d="M74.409 45.8981C74.409 48.9613 73.1786 51.7341 71.1856 53.7531C69.1579 55.811 66.3374 57.0848 63.218 57.0848C60.1028 57.0848 57.2823 55.811 55.2503 53.7531C53.2573 51.7341 52.0312 48.9613 52.0312 45.8981C52.0312 39.7199 57.0397 34.707 63.218 34.707C69.3962 34.707 74.409 39.7199 74.409 45.8981Z"
        fill="#F8FBFF"
      />
      <path
        d="M63.2194 57.7342C60.0263 57.7342 57.0325 56.4821 54.7882 54.2075C52.5916 51.9762 51.3828 49.0258 51.3828 45.8976C51.3828 39.3684 56.6945 34.0566 63.2194 34.0566C69.7486 34.0566 75.0603 39.3684 75.0603 45.8976C75.0603 49.0301 73.8472 51.9806 71.6506 54.2075C69.4107 56.4821 66.4169 57.7342 63.2194 57.7342ZM63.2194 35.3564C57.4094 35.3564 52.6826 40.0833 52.6826 45.8976C52.6826 48.6835 53.7614 51.309 55.7154 53.2976C57.7127 55.3209 60.3772 56.4387 63.2194 56.4387C66.0659 56.4387 68.7304 55.3253 70.7234 53.2976C72.6818 51.3133 73.7606 48.6878 73.7606 45.8976C73.7606 40.0833 69.0337 35.3564 63.2194 35.3564Z"
        fill="#243C98"
      />
      <path
        d="M63.2209 46.4458C65.3816 46.4458 67.1333 44.6941 67.1333 42.5334C67.1333 40.3727 65.3816 38.6211 63.2209 38.6211C61.0602 38.6211 59.3086 40.3727 59.3086 42.5334C59.3086 44.6941 61.0602 46.4458 63.2209 46.4458Z"
        fill="#243C98"
      />
      <path
        d="M71.1892 53.7557C69.1615 55.8136 66.341 57.0874 63.2215 57.0874C60.1064 57.0874 57.2859 55.8136 55.2539 53.7557C55.8648 50.5149 59.1966 48.041 63.2215 48.041C67.2465 48.041 70.5826 50.5149 71.1892 53.7557Z"
        fill="#243C98"
      />
      <path
        d="M167.882 175.775H30.2046C27.5764 175.775 25.4375 173.644 25.4375 171.008V137.205C25.4375 134.576 27.5685 132.438 30.2046 132.438H167.882C170.51 132.438 172.649 134.569 172.649 137.205V171.008C172.649 173.644 170.518 175.775 167.882 175.775Z"
        fill="white"
      />
      <path
        d="M167.882 176.959H30.2049C26.9216 176.959 24.2539 174.292 24.2539 171.008V137.205C24.2539 133.922 26.9216 131.254 30.2049 131.254H167.882C171.165 131.254 173.833 133.922 173.833 137.205V171.008C173.833 174.292 171.165 176.959 167.882 176.959ZM30.2049 133.63C28.2318 133.63 26.6217 135.24 26.6217 137.213V171.016C26.6217 172.99 28.2318 174.6 30.2049 174.6H167.882C169.855 174.6 171.465 172.997 171.465 171.016V137.213C171.465 135.24 169.855 133.63 167.882 133.63H30.2049Z"
        fill="#324BA8"
      />
      <path
        d="M162.709 148.354H83.701C82.8557 148.354 82.1641 147.644 82.1641 146.776C82.1641 145.908 82.8557 145.197 83.701 145.197H162.709C163.555 145.197 164.246 145.908 164.246 146.776C164.246 147.644 163.555 148.354 162.709 148.354Z"
        fill="#324BA8"
      />
      <path
        d="M127.62 163.018H83.701C82.8557 163.018 82.1641 162.308 82.1641 161.44C82.1641 160.572 82.8557 159.861 83.701 159.861H127.62C128.465 159.861 129.157 160.572 129.157 161.44C129.157 162.308 128.473 163.018 127.62 163.018Z"
        fill="#324BA8"
      />
      <path
        d="M40.7202 160.357C43.9916 160.357 46.6436 157.705 46.6436 154.433C46.6436 151.162 43.9916 148.51 40.7202 148.51C37.4488 148.51 34.7969 151.162 34.7969 154.433C34.7969 157.705 37.4488 160.357 40.7202 160.357Z"
        fill="#12B76A"
      />
      <path
        d="M43.4878 152.059L39.4678 155.175L38.2358 153.552C38.0383 153.291 37.6671 153.24 37.4065 153.438C37.1459 153.635 37.0946 154.006 37.292 154.267L38.8873 156.368C38.9821 156.494 39.1243 156.577 39.2822 156.597C39.3099 156.601 39.3336 156.601 39.3612 156.601C39.4916 156.601 39.6179 156.557 39.7245 156.478L44.2184 152.995C44.4751 152.794 44.5225 152.423 44.325 152.166C44.1276 151.909 43.7445 151.862 43.4878 152.059Z"
        fill="white"
      />
      <path
        d="M74.409 154.025C74.409 157.088 73.1786 159.861 71.1856 161.88C69.1579 163.938 66.3374 165.212 63.218 165.212C60.1028 165.212 57.2823 163.938 55.2503 161.88C53.2573 159.861 52.0312 157.088 52.0312 154.025C52.0312 147.847 57.0397 142.834 63.218 142.834C69.3962 142.834 74.409 147.847 74.409 154.025Z"
        fill="#F8FBFF"
      />
      <path
        d="M63.2194 165.861C60.0263 165.861 57.0325 164.609 54.7882 162.334C52.5916 160.103 51.3828 157.153 51.3828 154.025C51.3828 147.495 56.6945 142.184 63.2194 142.184C69.7486 142.184 75.0603 147.495 75.0603 154.025C75.0603 157.157 73.8472 160.108 71.6506 162.334C69.4107 164.609 66.4169 165.861 63.2194 165.861ZM63.2194 143.483C57.4094 143.483 52.6826 148.21 52.6826 154.025C52.6826 156.81 53.7614 159.436 55.7154 161.425C57.7127 163.448 60.3772 164.566 63.2194 164.566C66.0659 164.566 68.7304 163.452 70.7234 161.425C72.6818 159.44 73.7606 156.815 73.7606 154.025C73.7606 148.21 69.0337 143.483 63.2194 143.483Z"
        fill="#243C98"
      />
      <path
        d="M63.2209 154.567C65.3816 154.567 67.1333 152.815 67.1333 150.655C67.1333 148.494 65.3816 146.742 63.2209 146.742C61.0602 146.742 59.3086 148.494 59.3086 150.655C59.3086 152.815 61.0602 154.567 63.2209 154.567Z"
        fill="#243C98"
      />
      <path
        d="M71.1892 161.877C69.1615 163.935 66.341 165.208 63.2215 165.208C60.1064 165.208 57.2859 163.935 55.2539 161.877C55.8648 158.636 59.1966 156.162 63.2215 156.162C67.2465 156.162 70.5826 158.636 71.1892 161.877Z"
        fill="#243C98"
      />
      <path
        d="M148.94 122.105H11.2632C8.635 122.105 6.49609 119.974 6.49609 117.338V83.5347C6.49609 80.9065 8.6271 78.7676 11.2632 78.7676H148.94C151.568 78.7676 153.707 80.8986 153.707 83.5347V117.338C153.707 119.974 151.576 122.105 148.94 122.105Z"
        fill="white"
      />
      <path
        d="M148.94 123.29H11.2635C7.9802 123.29 5.3125 120.622 5.3125 117.339V83.535C5.3125 80.2517 7.9802 77.584 11.2635 77.584H148.94C152.224 77.584 154.891 80.2517 154.891 83.535V117.339C154.891 120.622 152.224 123.29 148.94 123.29ZM11.2635 79.9596C9.29036 79.9596 7.68025 81.5697 7.68025 83.5429V117.346C7.68025 119.32 9.29036 120.93 11.2635 120.93H148.94C150.914 120.93 152.524 119.327 152.524 117.346V83.5429C152.524 81.5697 150.914 79.9596 148.94 79.9596H11.2635Z"
        fill="#324BA8"
      />
      <path
        d="M143.764 94.6902H64.7557C63.9104 94.6902 63.2188 93.9799 63.2188 93.1117C63.2188 92.2435 63.9104 91.5332 64.7557 91.5332H143.764C144.609 91.5332 145.301 92.2435 145.301 93.1117C145.301 93.9799 144.609 94.6902 143.764 94.6902Z"
        fill="#324BA8"
      />
      <path
        d="M108.675 109.354H64.7557C63.9104 109.354 63.2188 108.644 63.2188 107.776C63.2188 106.908 63.9104 106.197 64.7557 106.197H108.675C109.52 106.197 110.212 106.908 110.212 107.776C110.212 108.644 109.528 109.354 108.675 109.354Z"
        fill="#324BA8"
      />
      <path
        d="M21.7826 106.686C25.0518 106.686 27.702 104.036 27.702 100.767C27.702 97.4979 25.0518 94.8477 21.7826 94.8477C18.5135 94.8477 15.8633 97.4979 15.8633 100.767C15.8633 104.036 18.5135 106.686 21.7826 106.686Z"
        fill="#EA0000"
      />
      <path
        d="M24.7395 98.2072C24.4685 97.9361 24.032 97.9361 23.7656 98.2072L21.9831 99.99L20.2007 98.2072C19.9296 97.9361 19.4932 97.9361 19.2267 98.2072C18.9557 98.4783 18.9557 98.9149 19.2267 99.1814L21.0091 100.964L19.2267 102.747C18.9557 103.018 18.9557 103.455 19.2267 103.721C19.3599 103.854 19.5391 103.923 19.7137 103.923C19.8882 103.923 20.0674 103.854 20.2007 103.721L21.9831 101.938L23.7656 103.721C23.8988 103.854 24.078 103.923 24.2525 103.923C24.4271 103.923 24.6063 103.854 24.7395 103.721C25.0106 103.45 25.0106 103.013 24.7395 102.747L22.9571 100.964L24.7395 99.1814C25.0106 98.9103 25.0106 98.4737 24.7395 98.2072Z"
        fill="white"
      />
      <path
        d="M55.4637 100.355C55.4637 103.418 54.2333 106.191 52.2403 108.21C50.2126 110.268 47.3921 111.542 44.2727 111.542C41.1575 111.542 38.337 110.268 36.305 108.21C34.312 106.191 33.0859 103.418 33.0859 100.355C33.0859 94.1769 38.0944 89.1641 44.2727 89.1641C50.4509 89.1641 55.4637 94.1769 55.4637 100.355Z"
        fill="#F8FBFF"
      />
      <path
        d="M44.2741 112.191C41.081 112.191 38.0872 110.939 35.8429 108.665C33.6463 106.433 32.4375 103.483 32.4375 100.355C32.4375 93.8255 37.7492 88.5137 44.2741 88.5137C50.8033 88.5137 56.115 93.8255 56.115 100.355C56.115 103.487 54.9019 106.438 52.7053 108.665C50.4653 110.939 47.4715 112.191 44.2741 112.191ZM44.2741 89.8134C38.4641 89.8134 33.7373 94.5403 33.7373 100.355C33.7373 103.141 34.8161 105.766 36.7701 107.755C38.7674 109.778 41.4319 110.896 44.2741 110.896C47.1206 110.896 49.7851 109.782 51.7781 107.755C53.7364 105.77 54.8153 103.145 54.8153 100.355C54.8153 94.5403 50.0884 89.8134 44.2741 89.8134Z"
        fill="#243C98"
      />
      <path
        d="M44.2717 100.903C46.4324 100.903 48.1841 99.1512 48.1841 96.9905C48.1841 94.8297 46.4324 93.0781 44.2717 93.0781C42.111 93.0781 40.3594 94.8297 40.3594 96.9905C40.3594 99.1512 42.111 100.903 44.2717 100.903Z"
        fill="#243C98"
      />
      <path
        d="M52.2399 108.213C50.2123 110.271 47.3918 111.544 44.2723 111.544C41.1572 111.544 38.3367 110.271 36.3047 108.213C36.9156 104.972 40.2473 102.498 44.2723 102.498C48.2973 102.498 51.6334 104.972 52.2399 108.213Z"
        fill="#243C98"
      />
    </SvgIcon>
  )
}

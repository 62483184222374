import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material'
import { FeedbackDataInput } from '../types'
import { Circle } from '@mui/icons-material'

const StyledTableCell = styled(TableCell)(theme => ({
  border: 'none',
  padding: '8px 16px'
}))

export const FeedbackTable = ({ data }: { data: FeedbackDataInput[] }) => {
  return (
    <Box flex={1}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align={'left'}>
                <Typography variant={'label2'}>Feedback</Typography>
              </StyledTableCell>
              <StyledTableCell align={'right'}>
                <Typography variant={'label2'}>Creators</Typography>
              </StyledTableCell>
              <StyledTableCell align={'right'}>
                <Typography variant={'label2'}>%</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((t, index) => (
              <TableRow key={index}>
                <StyledTableCell>
                  <Circle fontSize={'mSmall'} sx={{ color: t.color }} />
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant={'label3'}>{t.theme}</Typography>
                </StyledTableCell>
                <StyledTableCell align={'right'}>
                  <Typography variant={'label3'}>{t.positiveFeedback.length + t.criticalFeedback.length}</Typography>
                </StyledTableCell>
                <StyledTableCell align={'right'}>
                  <Typography variant={'label3'}>{t.percentage}%</Typography>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

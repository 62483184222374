import { mApi } from '@momentum/api'
import { Currency, GraphQLTypes, InputType, Selector } from '@productwindtom/shared-momentum-zeus-types'

export const creatorPricingSelector = Selector('ProposalCreatorPricing')({
  type: true,
  price: true,
  numCreators: true,
  hidden: true,
  schedule: true,
  closeoutBonus: true,
  contentRequirements: {
    contentType: true,
    bonus: true
  }
})

const productSelector = Selector('Product')({
  id: true,
  name: true,
  image: true,
  price: true,
  priceCents: true,
  store: true,
  skuId: true,
  listingLink: true,
  ratingSummary: {
    rating: true,
    numRatings: true,
    numReviews: true
  },
  parentSkuId: true
})
export type Product = InputType<GraphQLTypes['Product'], typeof productSelector>

export const proposalSelector = Selector('Proposal')({
  id: true,
  title: true,
  brandId: true,
  status: true,
  statusChangedAt: true,
  goal: true,
  ratingGoal: true,
  estimatedAverageRating: true,
  durationWeeks: true,
  productId: true,
  exchangeRate: true,
  productVariationSkus: true,
  productPriceOverride: true,
  launchDate: true,
  estimatedUnitsSoldPerMonth: true,
  searchTerms: true,
  benchmarkProducts: true,
  isDailyScheduling: true,
  creatorPricing: creatorPricingSelector,
  paymentType: true,

  invoiceId: true,
  invoiceDueDate: true,
  invoiceLink: true,
  invoicePaidDate: true,
  invoicePaidAmount: true,
  invoiceStatus: true,
  invoicePONumber: true,

  totalCredits: true,
  totalCreditsPaid: true,

  paymentBillingContact: {
    name: true,
    email: true
  },

  billingContacts: {
    name: true,
    email: true
  },

  createdBy: {
    firstName: true,
    lastName: true
  },
  updatedBy: {
    firstName: true,
    lastName: true
  },

  hiddenNumReviews: true,
  hiddenEndRating: true,
  hiddenPdpTraffic: true,
  hiddenRetailEfficiency: true,
  hiddenSeo: true,
  hiddenSocialContent: true,
  hiddenUgcContent: true,
  updatedAt: true,
  createdAt: true,
  referralCost: true,
  operatingCost: true,
  liftNumCreators: true,
  liftCloseoutBonus: true,

  product: productSelector,

  hubspotDealId: true
})

export type Proposal = InputType<GraphQLTypes['Proposal'], typeof proposalSelector>
export const getProposal = async (id: string) => {
  return (
    await mApi('query')({
      getProposal: [{ id }, proposalSelector]
    })
  ).getProposal
}

export const getProduct = async (id: string) => {
  return (
    await mApi('query')({
      getProduct: [{ id }, productSelector]
    })
  ).getProduct
}

export const requestExchangeRate = async (from: Currency) => {
  return (
    await mApi('query')({
      requestExchangeRate: [
        {
          input: {
            from
          }
        },
        {
          rate: true
        }
      ]
    })
  ).requestExchangeRate?.rate
}

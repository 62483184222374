import { ContentApprovalType } from '@productwindtom/shared-ws-zeus-types'
import { ApprovalConfig } from './types'

export const USER_ROLES_CONFIG: ApprovalConfig[] = [
  {
    name: 'PH Team',
    approvalType: ContentApprovalType.ADMIN_APPROVAL
  },
  {
    id: '19550321-bc01-45ac-877b-c84ff8a9550a',
    name: 'Lada',
    approvalType: ContentApprovalType.MODERATOR_APPROVAL
  },
  {
    id: '2ea553ab-cdf2-44ec-a148-64431f3369ba',
    name: 'Alexis',
    approvalType: ContentApprovalType.MODERATOR_APPROVAL
  },
  {
    id: '657be315-8035-49d3-9c9b-c1e209df0b94',
    name: 'Jenna',
    approvalType: ContentApprovalType.MODERATOR_APPROVAL
  },
  {
    id: 'd36bff67-5a0f-435a-abe2-fdad6b55d70f',
    name: 'Clare',
    approvalType: ContentApprovalType.MODERATOR_APPROVAL
  },
  {
    id: '4617a0c1-a80e-46f0-aee4-db65dc5c121f',
    name: 'Sarah',
    approvalType: ContentApprovalType.MODERATOR_APPROVAL
  },
  {
    name: 'Client',
    approvalType: ContentApprovalType.BRAND_APPROVAL
  }
]

export const PH_TEAM_MEMBERS = [
  {
    id: 'cc70eb82-ea3b-489a-8eb5-4044680ffd31',
    name: 'Diane'
  },
  { id: '02b11e9c-6b94-4863-aefd-fdb8d669c621', name: 'Angela' },
  { id: '3c4fc864-afe4-4669-b31e-aebe986d6809', name: 'Janelle' },
  { id: 'e06b3d72-d2e4-4f3f-b9db-94924b273263', name: 'Kristel' },
  { id: '5bc4070b-aac0-4aed-9516-dea969f3c002', name: 'Maylerie' }
]

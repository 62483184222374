import { SAMPLE_CAMPAIGN_PERFORMANCE_HEADPHONES } from './headphones'
import { SAMPLE_CAMPAIGN_PERFORMANCE_LAUNDRY } from './laundry'
import { SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER } from '@momentum/api/interceptors/getCampaign/sample-data/bluetooth-speaker'
import { SAMPLE_CAMPAIGN_HEADPHONES } from '@momentum/api/interceptors/getCampaign/sample-data/headphones'
import { SAMPLE_CAMPAIGN_LAUNDRY } from '@momentum/api/interceptors/getCampaign/sample-data/laundry'
import { SAMPLE_CAMPAIGN_CAMPAIGN_PERFORMANCE_WATER_FLOSSER_PRIME_DAY } from '@momentum/api/interceptors/getCampaignPerformance/sample-data/water-flosser-pd-launch'
import { SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH } from '@momentum/api/interceptors/getCampaign/sample-data/water-flosser-pd-launch'
import { CampaignPerformance } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { SAMPLE_CAMPAIGN_PERFORMANCE_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaignPerformance/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_PERFORMANCE_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaignPerformance/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_PERFORMANCE_BLUETOOTH_SPEAKER } from '@momentum/api/interceptors/getCampaignPerformance/sample-data/bluetooth-speaker'

export const SAMPLE_CAMPAIGN_PERFORMANCE: Record<string, CampaignPerformance> = {
  [SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER.id]: SAMPLE_CAMPAIGN_PERFORMANCE_BLUETOOTH_SPEAKER({
    campaignId: 'sample-campaign-bluetooth-speaker',
    promotedProductId: 'sample-sku-speaker',
    skuId: 'sample-sku-speaker'
  }),
  [SAMPLE_CAMPAIGN_HEADPHONES.id]: SAMPLE_CAMPAIGN_PERFORMANCE_HEADPHONES({
    campaignId: 'sample-campaign-headphones',
    promotedProductId: 'sample-sku-headphones',
    skuId: 'sample-sku-headphones'
  }),
  [SAMPLE_CAMPAIGN_LAUNDRY.id]: SAMPLE_CAMPAIGN_PERFORMANCE_LAUNDRY,
  [SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH.id]: SAMPLE_CAMPAIGN_CAMPAIGN_PERFORMANCE_WATER_FLOSSER_PRIME_DAY,
  [SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id]: SAMPLE_CAMPAIGN_PERFORMANCE_OUTDOOR_CAMERA_BOOST,
  [SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id]: SAMPLE_CAMPAIGN_PERFORMANCE_WIRELESS_EARBUDS
}

import { Button, Link, Stack, Typography } from '@mui/material'
import { useApproveContentContext } from '../context/ApproveContentContext'
import { OpenInNewOutlined } from '@mui/icons-material'
import Row from '@momentum/components/row'
import { ContentTypeToSocialString } from '@momentum/utils/socialUtils'
import DraftCaption from './DraftCaption'
import { Form, SubmitButton } from '@productwindtom/ui-base'
import { ApproveContentViewMode } from '../types'
import { DraftStatus } from '@productwindtom/shared-momentum-zeus-types'
import ReviewFeedback from './ReviewFeedback'
import ReviewedAt from './ReviewedAt'
import TimeToAction from './TimeToAction'
import { DateTime } from 'luxon'

const DraftDetails = () => {
  const {
    handle,
    handleLink,
    socialProfile,
    content: { contentType, draftStatus, draftSubmittedAt },
    handleApproveContent,
    setApproveContentViewMode
  } = useApproveContentContext()

  return (
    <Stack spacing={3}>
      {draftStatus !== DraftStatus.REQUIRES_BRAND_APPROVAL && <ReviewedAt row />}
      <Stack spacing={1}>
        <TimeToAction />
        <Row spacing={1}>
          {handle && <Typography variant="link1">@{handle}</Typography>}
          {!!handleLink && (
            <Link href={handleLink} target={'_blank'} sx={{ verticalAlign: 'sub', display: 'flex' }}>
              <OpenInNewOutlined fontSize={'mSmall'} color={'primary'} />
            </Link>
          )}
        </Row>
        {socialProfile && (
          <Row spacing={1}>
            {socialProfile.followers && (
              <Typography variant="body1" noWrap>
                {socialProfile.followers.toLocaleString()} followers
              </Typography>
            )}
            {socialProfile.engagementRate && (
              <>
                <Typography>{'\u2022'}</Typography>
                <Typography variant="body1" noWrap>
                  {socialProfile.engagementRate?.toFixed(1)}% engagement rate
                </Typography>
              </>
            )}
          </Row>
        )}
      </Stack>
      <Stack spacing={1}>
        <Row spacing={1}>
          <Typography variant="link2">Submitted to Momentum:</Typography>
          <Typography variant="body1">
            {DateTime.fromISO(draftSubmittedAt).toLocaleString(DateTime.DATE_SHORT)}
          </Typography>
        </Row>
        <Row spacing={1}>
          <Typography variant="link2">Content type:</Typography>
          <Typography variant="body1">{ContentTypeToSocialString[contentType]}</Typography>
        </Row>
      </Stack>
      {draftStatus === DraftStatus.REQUIRES_BRAND_APPROVAL ? (
        <>
          <DraftCaption />

          <Stack spacing={1} alignItems={'flex-start'}>
            <Form onSubmit={handleApproveContent}>
              <SubmitButton variant="contained" color="primary" disableOnDirty={false}>
                Approve creator to publish
              </SubmitButton>
            </Form>
            <Button variant="text" onClick={() => setApproveContentViewMode(ApproveContentViewMode.REQUEST_EDITS)}>
              I need edits
            </Button>
          </Stack>
        </>
      ) : (
        <ReviewFeedback />
      )}
    </Stack>
  )
}

export default DraftDetails

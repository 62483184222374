import { BoxToggle } from '@momentum/components/box-toggle'
import { Stack, Typography, useTheme } from '@mui/material'
import { BENCHMARK_PRODUCT_COLORS, BENCHMARK_TYPE_TO_GOAL_STRING, BENCHMARK_TYPE_TO_VALUE } from '../constants'
import { get } from 'lodash'
import { BenchmarkType } from '@productwindtom/shared-momentum-zeus-types'
import { useMemo } from 'react'

const BenchmarkGraphToggle = ({
  index,
  isPromotedProduct,
  onToggle,
  metricGoal,
  metricType,
  enabled,
  data
}: {
  index: number
  isPromotedProduct: boolean
  metricGoal: number
  metricType: BenchmarkType
  onToggle: () => void
  enabled: boolean
  data: number[]
}) => {
  const theme = useTheme()

  const timeToMetric = useMemo(() => {
    return data.findIndex(metric => {
      return metric >= metricGoal
    })
  }, [data, metricGoal])

  return (
    <BoxToggle
      key={index}
      value={enabled}
      readonly={isPromotedProduct}
      color={get(theme.palette, BENCHMARK_PRODUCT_COLORS[index])}
      maxWidth={'25%'}
      onChange={onToggle}
    >
      <Stack alignItems={'center'} spacing={2}>
        <Typography variant={'label1'}>{isPromotedProduct ? 'Promoted product' : `Product ${index}`}</Typography>
        <Stack alignItems={'center'} spacing={0.5}>
          <Typography variant={'label3'}>{timeToMetric >= 0 ? `${timeToMetric.toLocaleString()} days` : `--`}</Typography>
          <Typography variant={'body1'}>
            time to {metricGoal.toLocaleString()} {BENCHMARK_TYPE_TO_GOAL_STRING[metricType]}
          </Typography>
        </Stack>
      </Stack>
    </BoxToggle>
  )
}

export default BenchmarkGraphToggle

import { Close } from '@mui/icons-material'
import { IconButton, IconButtonProps, SvgIconOwnProps } from '@mui/material'

const CloseButton = ({ iconButtonProps, iconProps }: { iconButtonProps?: IconButtonProps; iconProps?: SvgIconOwnProps }) => {
  return (
    <IconButton {...iconButtonProps}>
      <Close {...iconProps} />
    </IconButton>
  )
}

export default CloseButton

import { SvgIcon, SvgIconProps } from '@mui/material'
import { ReactNode } from 'react'

export default function SaleIcon(props: SvgIconProps): ReactNode {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M12.6676 9.33376C13.7743 9.33376 14.6676 10.2271 14.6676 11.3338C14.6676 12.4404 13.7743 13.3338 12.6676 13.3338C11.5609 13.3338 10.6676 12.4404 10.6676 11.3338C10.6676 10.2271 11.5609 9.33376 12.6676 9.33376ZM19.3343 18.6671C20.4409 18.6671 21.3343 19.5604 21.3343 20.6671C21.3343 21.7738 20.4409 22.6671 19.3343 22.6671C18.2276 22.6671 17.3343 21.7738 17.3343 20.6671C17.3343 19.5604 18.2276 18.6671 19.3343 18.6671ZM11.2143 22.6671L9.33427 20.7871L20.7876 9.33376L22.6676 11.2138L11.2143 22.6671ZM24.8676 3.80043L25.6809 8.94709L30.3609 11.3338L28.0009 16.0004L30.3743 20.6671L25.6543 23.0538L24.8409 28.2004L19.6543 27.3871L15.9609 31.0804L12.2543 27.3338L7.1076 28.1871L6.28093 23.0004L1.6276 20.6271L4.00093 15.9604L1.64093 11.3338L6.32093 8.92043L7.13427 3.82709L12.2943 4.66709L16.0009 0.927094L19.6809 4.61376L24.8676 3.80043ZM26.8009 12.4938L23.3343 10.6671L22.6676 6.81376L18.8009 7.37376L16.0009 4.66709L13.2009 7.37376L9.33427 6.81376L8.6676 10.6671L5.20093 12.4938L6.93427 16.0004L5.20093 19.5071L8.6676 21.3338L9.33427 25.1871L13.2009 24.6271L16.0009 27.3338L18.8009 24.6271L22.6676 25.1871L23.3343 21.3338L26.8009 19.5071L25.0676 16.0004L26.8009 12.4938Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

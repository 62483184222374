import { DateTime, Interval } from 'luxon'

export const generateEtailerProductMetrics = ({
  startDate,
  endDate,
  desiredViews,
  daysToReachViewsGoal,
  desiredUnitsSold
}: {
  startDate: string
  endDate: string
  desiredViews: number
  daysToReachViewsGoal: DaysToReach
  desiredUnitsSold: number
}) => {
  const start = DateTime.fromISO(startDate)
  const end = DateTime.fromISO(endDate).plus({ days: 1 })
  const interval = Interval.fromDateTimes(start, end)
  const days = Array.from(interval.splitBy({ days: 1 }), d => d?.start?.toISODate())

  const totalDays = days.length
  const viewsPerDay = calculatePerDayMetrics(totalDays, desiredViews, daysToReachViewsGoal)
  const unitsSoldPerDay = calculatePerDayMetrics(totalDays, desiredUnitsSold)

  const items = days.map((day, index) => ({
    date: day,
    pageViews: viewsPerDay[index],
    totalSalesAmount: null,
    unitsSold: unitsSoldPerDay[index]
  }))

  return {
    items: items,
    nextToken: null
  }
}

export type DaysToReach = {
  goal: number
  days: number
}

const calculatePerDayMetrics = (totalDays: number, desiredMetric: number, daysToReach?: DaysToReach) => {
  const minPerDay = Math.floor((desiredMetric / totalDays) * 0.5)
  const maxPerDay = Math.floor((desiredMetric / totalDays) * 1.5)

  const daysToReachMetrics = daysToReach && calculatePerDayMetrics(daysToReach.days, daysToReach.goal)

  const metricsPerDay = Array(totalDays)
    .fill(0)
    .reduce((acc, _, i) => {
      let metricForThisDay

      if (daysToReachMetrics && daysToReachMetrics[i]) {
        metricForThisDay = daysToReachMetrics[i]
      } else {
        const remainingMetric = desiredMetric - acc.reduce((a: number, b: number) => a + b, 0)
        const maxForThisDay = Math.min(maxPerDay, remainingMetric - (totalDays - i - 1) * minPerDay)
        metricForThisDay = Math.floor(Math.random() * (maxForThisDay - minPerDay + 1)) + minPerDay
      }

      acc[i] = metricForThisDay

      return acc
    }, Array(totalDays).fill(0))

  const remainingMetric = desiredMetric - metricsPerDay.reduce((a: number, b: number) => a + b, 0)
  for (let i = 0; i < remainingMetric; i++) {
    metricsPerDay[i % totalDays] += 1
  }

  return metricsPerDay
}

export const generateBrandAdvocates = ({
  startDate,
  endDate,
  numCreators
}: {
  startDate: string
  endDate: string
  numCreators: number
}) => {
  const start = DateTime.fromISO(startDate)
  const end = DateTime.fromISO(endDate).plus({ days: 1 })
  const interval = Interval.fromDateTimes(start, end)
  const days = Array.from(interval.splitBy({ days: 1 }), d => d?.start?.toISODate())

  return Array.from({ length: numCreators }, () => {
    const randomDay = Math.floor(Math.random() * days.length)
    const purchaseDate = days[randomDay]

    return {
      purchaseDetails: {
        purchaseDate: purchaseDate,
        amountCents: 32099
      },
      status: 'COMPLETED',
      creatorType: 'ADVOCATE',
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '79313e23-c141-4d0d-88bc-042639325f80',
        firstName: 'user',
        lastName: 'user',
        email: 'user@gmail.com',
        instagramProfile: {
          handle: 'user',
          followers: 946,
          engagementRate: 0.10570824524312897,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'user',
          followers: 18696,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'user',
          followers: 0
        }
      },
      userCampaignId: '79313e23-c141-4d0d-88bc-042639325f80'
    }
  })
}

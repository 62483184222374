import { Stack } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { NoItems } from '@momentum/components/no-items'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'

export const NotIntegratedState = ({ brandId }: { brandId: string }) => {
  return (
    <Stack alignItems={'center'} mt={12} spacing={4}>
      <NoItems />
      <Typography variant={'h5'} textAlign={'center'} alignItems={'center'} width={552}>
        You have no recommendations yet because you don’t have an integrated retailer account.
      </Typography>
      <Button variant={'contained'} component={RouterLink} to={generatePath(ROUTES.BRAND_PROFILE, { brandId })}>
        Integrate account
      </Button>
    </Stack>
  )
}

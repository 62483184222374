import { Channel, ContentType, DraftStatus, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CONTENT_PENDING_APPROVAL_MARKER: Array<ModelTypes['CreatorDraftContent']> = [
  {
    id: '18834533-3e72-4bcc-9bc5-16383a2f8f8b',
    creator: {
      id: 'a9698c2c-f31b-4bc6-bade-baddd490db30',
      email: 'bianchini_taty@hotmail.com',
      firstName: 'Tatiana',
      lastName: 'Bianchini',
      tiktokProfile: {
        handle: 't_bianchini',
        followers: 49301,
        engagementRate: 5.619501363240296,
        isPrimary: false
      }
    },
    draftContent: [
      {
        id: 'e59146fc-15dd-46e2-a5d7-f2749768acbf',
        channel: Channel.UGC,
        contentType: ContentType.UGC_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2024-02-29T16:05:51.797Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: ['a9698c2c-f31b-4bc6-bade-baddd490db30/ugc/UGC-CONTENT-1.mp4']
      }
    ]
  }
]

/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const'
import fetch, { Response } from 'node-fetch'
import { WebSocket } from 'ws'

export const HOST = 'Specify host'

export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query)
    const wsString = queryString.replace('http', 'ws')
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString
    const webSocketOptions = options[1]?.websocket || [host]
    const ws = new WebSocket(...webSocketOptions)
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data)
            const data = parsed.data
            return e(data)
          }
        }
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e
      },
      open: (e: () => void) => {
        ws.onopen = e
      }
    }
  } catch {
    throw new Error('No websockets implemented')
  }
}
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then(text => {
          try {
            reject(JSON.parse(text))
          } catch (err) {
            reject(text)
          }
        })
        .catch(reject)
    })
  }
  return response.json() as Promise<GraphQLResponse>
}

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {}
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response)
          }
          return response.data
        })
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response)
        }
        return response.data
      })
  }

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars
}: {
  props: AllTypesPropsType
  returns: ReturnTypesType
  ops: Operations
  options?: OperationOptions
  scalars?: ScalarDefinition
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = []
  ): string => {
    const keyForPath = purifyGraphQLKey(k)
    const newPath = [p, keyForPath].join(SEPARATOR)
    if (!o) {
      return ''
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k
    }
    if (typeof o === 'string') {
      return `${k} ${o}`
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars
      })(o[0], newPath)
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}'
            )
          }
          const operationName = Object.keys(objectUnderAlias)[0]
          const operation = objectUnderAlias[operationName]
          return ibb(`${alias}:${operationName}`, operation, p, false, vars)
        })
        .join('\n')
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : ''
    const keyForDirectives = o.__directives ?? ''
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(e => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`
    }
    const varsString = vars.map(v => `${v.name}: ${v.graphQLType}`).join(', ')
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`
  }
  return ibb
}

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars
      }),
      ops?.variables
    ).then(data => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops
        })
      }
      return data
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options))

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars
      })
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops
              })
            )
          }
          return fnToCall(data)
        })
    }
    return returnedFunction
  }

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options))
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions
    scalars?: ScalarDefinition
  }
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars
  })(operation, o as VType)

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>()

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>()
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS
  }
})

export const ZeusScalars = ZeusSelect<ScalarCoders>()

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp
}: {
  ops: O
  response: any
  returns: ReturnTypesType
  scalars?: Record<string, ScalarResolver | undefined>
  initialOp: keyof O
  initialZeusQuery: InputValueType | VType
}) => {
  if (!scalars) {
    return response
  }
  const builder = PrepareScalarPaths({
    ops,
    returns
  })

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery)
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]])
    return r
  }
  return response
}

export const traverseResponse = ({
  resolvers,
  scalarPaths
}: {
  scalarPaths: { [x: string]: `scalar.${string}` }
  resolvers: {
    [x: string]: ScalarResolver | undefined
  }
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map(eachO => ibb(k, eachO, p))
    }
    if (o == null) {
      return o
    }
    const scalarPathString = p.join(SEPARATOR)
    const currentScalarString = scalarPaths[scalarPathString]
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode
      if (currentDecoder) {
        return currentDecoder(o)
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const)
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v
      return a
    }, {})
    return objectFromEntries
  }
  return ibb
}

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined
            }
      }
}

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined
      }
    | `scalar.${string}`
    | undefined
}
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType
}
export type VType = undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType

export type PlainType = boolean | number | string | null | undefined
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType
    }
  | Array<ZeusArgsType>

export type Operations = Record<string, string>

export type VariableDefinition = {
  [x: string]: unknown
}

export const SEPARATOR = '|'

export type fetchOptions = Parameters<typeof fetch>
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]]
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>
export type SubscriptionFunction = (query: string) => any
type NotUndefined<T> = T extends undefined ? never : T
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>

export type OperationOptions = {
  operationName?: string
}

export type ScalarCoder = Record<string, (s: unknown) => string>

export interface GraphQLResponse {
  data?: Record<string, any>
  errors?: Array<{
    message: string
  }>
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('')
    console.error(response)
  }
  override toString() {
    return 'GraphQL Response Error'
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? (typeof Ops)[O] : never
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders
}

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return
  }
  const oKey = mappedParts[0]
  const returnP1 = returns[oKey]
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]]
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns)
    }
    return undefined
  }
  return returnP1 as `scalar.${string}` | undefined
}

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey]
      const isScalar = ExtractScalar(extractionArray, returns)
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar
        }
        return partOfTree
      }
      return {}
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false)
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}'
            )
          }
          const operationName = Object.keys(objectUnderAlias)[0]
          const operation = objectUnderAlias[operationName]
          return ibb(alias, operationName, operation, p, pOriginals, false)
        })
        .reduce((a, b) => ({
          ...a,
          ...b
        }))
    }
    const keyName = root ? ops[k] : k
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false
        )
      })
      .reduce((a, b) => ({
        ...a,
        ...b
      }))
  }
  return ibb
}

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '')

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>')
  if (isField) {
    return {
      v: isField,
      __type: 'field'
    } as const
  }
  return {
    v: isArg,
    __type: 'arg'
  } as const
}

type Part = ReturnType<typeof mapPart>

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v]
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v]
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum'
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not'
      }
      const propsP2 = propsP1[mappedParts[1].v]
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map(mp => mp.v)
            .join(SEPARATOR)}`
        )
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not'
        }
        const propsP3 = propsP2[mappedParts[2].v]
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map(mp => mp.v)
              .join(SEPARATOR)}`
          )
        }
      }
    }
  }
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not'
    }
    const oKey = ops[mappedParts[0].v]
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v]
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not'
      const returnP2 = returnP1[mappedParts[1].v]
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map(mp => mp.v)
            .join(SEPARATOR)}`
        )
      }
    }
  }
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter(l => l.length > 0)
    const mappedParts = parts.map(mapPart)
    const propsP1 = ResolvePropsType(mappedParts)
    if (propsP1) {
      return propsP1
    }
    const returnP1 = ResolveReturnType(mappedParts)
    if (returnP1) {
      return returnP1
    }
    return 'not'
  }
  return rpp
}

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars
}: {
  props: AllTypesPropsType
  returns: ReturnTypesType
  ops: Operations
  scalars?: ScalarDefinition
  vars: Array<{ name: string; graphQLType: string }>
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR)
        const v = vars.find(v => v.name === varName)
        if (!v) {
          vars.push({
            name: varName,
            graphQLType
          })
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`
            )
          }
        }
        return varName
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p)
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.')
      const scalarKey = splittedScalar.join('.')
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a)
    }
    if (Array.isArray(a)) {
      return `[${a.map(arr => arb(arr, p, false)).join(', ')}]`
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a
      }
      return `${JSON.stringify(a)}`
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n')
      if (!root) {
        return `{${returnedObjectString}}`
      }
      return returnedObjectString
    }
    return `${a}`
  }
  return arb
}

export const resolverFor = <
  X,
  T extends keyof ResolverInputTypes,
  Z extends keyof ResolverInputTypes[T],
  RET = unknown
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : RET
) => fn as (args?: any, source?: any) => RET

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>

export type WithTypeNameValue<T> = T & {
  __typename?: boolean
  __directives?: string
}
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>
}
type DeepAnify<T> = {
  [P in keyof T]?: any
}
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T
export type ScalarDefinition = Record<string, ScalarResolver>

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S
type IsArray<T, U, SCLR extends ScalarDefinition> =
  T extends Array<infer R> ? InputType<R, U, SCLR>[] : InputType<T, U, SCLR>
type FlattenArray<T> = T extends Array<infer R> ? R : T
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> =
  FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
    ? {
        [P in keyof SRC]: SRC[P] extends '__union' & infer R
          ? P extends keyof DST
            ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
            : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : never, SCLR>
          : never
      }[keyof SRC] & {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>
      }
    : {
        [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
          ? IsScalar<SRC[P], SCLR>
          : IsArray<SRC[P], DST[P], SCLR>
      }

export type MapType<SRC, DST, SCLR extends ScalarDefinition> =
  SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST, SCLR> : never
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> =
  IsPayLoad<DST> extends { __alias: infer R }
    ? {
        [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>]
      } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
    : MapType<SRC, IsPayLoad<DST>, SCLR>
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void
  open: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>

export type ScalarResolver = {
  encode?: (s: unknown) => string
  decode?: (s: unknown) => unknown
}

export type SelectionFunction<V> = <T>(t: T | V) => T

type BuiltInVariableTypes = {
  ['String']: string
  ['Int']: number
  ['Float']: number
  ['ID']: unknown
  ['Boolean']: boolean

  ['AWSJSON']: string
  ['AWSDateTime']: string
  ['AWSDate']: string
  ['AWSTime']: string
  ['AWSEmail']: string
  ['AWSPhone']: string
  ['AWSURL']: string
  ['AWSIpAddress']: string
  ['AWSTimestamp']: number
}
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`
type VR<T extends string> = VariableRequired<VariableRequired<T>>

export type GraphQLVariableType = VR<AllVariableTypes>

type ExtractVariableTypeString<T extends string> =
  T extends VR<infer R1>
    ? R1 extends VR<infer R2>
      ? R2 extends VR<infer R3>
        ? R3 extends VR<infer R4>
          ? R4 extends VR<infer R5>
            ? R5
            : R4
          : R3
        : R2
      : R1
    : T

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
    ? NonNullable<DecomposeType<R, Type>>
    : Type | undefined

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
    ? BuiltInVariableTypes[T]
    : any

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K
}[keyof T]

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>

type OptionalKeys<T> = {
  [P in keyof T]?: T[P]
}

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name
  ' __zeus_type': T
}

export type ExtractVariables<Query> =
  Query extends Variable<infer VType, infer VName>
    ? { [key in VName]: GetVariableType<VType> }
    : Query extends [infer Inputs, infer Outputs]
      ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
      : Query extends string | number | boolean
        ? // eslint-disable-next-line @typescript-eslint/ban-types
          {}
        : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never

export const START_VAR_NAME = `$ZEUS_VAR`
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>
}
type ZEUS_INTERFACES = never
export type ScalarCoders = {}
type ZEUS_UNIONS = never

export type ValueTypes = {
  ['AdminSendPushMessageResponse']: AliasType<{
    message?: boolean | `@${string}`
    statusCode?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ApplyToCampaignOutput']: AliasType<{
    campaignStatus?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isAutoApproved?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ApprovalFilterValue']: AliasType<{
    filter?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ApproveUserCampaignForPurchaseResponse']: AliasType<{
    approvalOutcome?: boolean | `@${string}`
    campaignStatus?: boolean | `@${string}`
    coarseCampaignStatus?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoApprovalCriteria']: AliasType<{
    completedCampaigns?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoApprovalV2Config']: AliasType<{
    autoApprovalCriteria?: ValueTypes['AutoApprovalCriteria']
    enabled?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuyDistribution']: AliasType<{
    createdAt?: boolean | `@${string}`
    description?: boolean | `@${string}`
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    schedule?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuyFlowConfiguration']: AliasType<{
    createdAt?: boolean | `@${string}`
    dailyPurchaseMax?: boolean | `@${string}`
    delayStrategy?: boolean | `@${string}`
    flowId?: boolean | `@${string}`
    insufficientCreators?: boolean | `@${string}`
    latestSkipReason?: boolean | `@${string}`
    pauseReason?: boolean | `@${string}`
    releaseSchedule?: [
      {
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelAutoBuyFlowReleaseScheduleConnection']
    ]
    schedule?: ValueTypes['AutoBuySchedule']
    startDate?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuyFlowReleaseSchedule']: AliasType<{
    actualPurchases?: boolean | `@${string}`
    actualVerifiedPurchases?: boolean | `@${string}`
    adjustedDesiredPurchases?: boolean | `@${string}`
    autoApprovalSeats?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    desiredPurchases?: boolean | `@${string}`
    flowId?: boolean | `@${string}`
    manualPurchases?: boolean | `@${string}`
    manualReleases?: boolean | `@${string}`
    projectedReleaseCount?: boolean | `@${string}`
    releaseCount?: boolean | `@${string}`
    released?: boolean | `@${string}`
    skippedReason?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuyRelease']: AliasType<{
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    releaseDateTime?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuySchedule']: AliasType<{
    date?: boolean | `@${string}`
    desiredPurchaseCount?: boolean | `@${string}`
    willPurchasePercentage?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BillingContact']: AliasType<{
    email?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Brand']: AliasType<{
    active?: boolean | `@${string}`
    amazonBrandName?: boolean | `@${string}`
    brandApis?: [
      {
        filter?: ValueTypes['ModelBrandApiFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelBrandApiConnection']
    ]
    brandDetailsSubmitted?: boolean | `@${string}`
    brandInstaHandle?: boolean | `@${string}`
    brandLogoImageKey?: boolean | `@${string}`
    brandStoreConfirmationImage?: boolean | `@${string}`
    brandStoreConfirmationMobileImage?: boolean | `@${string}`
    brandStoreScrapeUrl?: boolean | `@${string}`
    brandTikTokHandle?: boolean | `@${string}`
    companyId?: boolean | `@${string}`
    contactEmails?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    defaultCreatorPreference?: boolean | `@${string}`
    defaultCreatorPreferenceDetails?: boolean | `@${string}`
    defaultHashtags?: boolean | `@${string}`
    defaultMarketingBriefKey?: boolean | `@${string}`
    defaultPostingGuidelines?: boolean | `@${string}`
    defaultPostingInspirationLinks?: boolean | `@${string}`
    emailsSent?: boolean | `@${string}`
    fullScrapedAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isBrandStoreScrapeEnabled?: boolean | `@${string}`
    isSeller?: boolean | `@${string}`
    isVendor?: boolean | `@${string}`
    isWalmartScrapeEnabled?: boolean | `@${string}`
    lastScrapedAt?: boolean | `@${string}`
    name?: boolean | `@${string}`
    nameAudioKey?: boolean | `@${string}`
    paymentBillingContact?: ValueTypes['BillingContact']
    platform?: boolean | `@${string}`
    postingGuidanceDocument?: boolean | `@${string}`
    region?: boolean | `@${string}`
    socialMediaExampleUrls?: boolean | `@${string}`
    subscriptionEndsAt?: boolean | `@${string}`
    subscriptionStartsAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    walmartBrandName?: boolean | `@${string}`
    walmartLastScrapedAt?: boolean | `@${string}`
    walmartScrapeUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BrandApi']: AliasType<{
    accessToken?: boolean | `@${string}`
    api?: boolean | `@${string}`
    apiVersion?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    enabled?: boolean | `@${string}`
    locale?: boolean | `@${string}`
    refreshToken?: boolean | `@${string}`
    shop?: boolean | `@${string}`
    shopId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BudgetDetail']: AliasType<{
    authorizedUnits?: boolean | `@${string}`
    baseUnits?: boolean | `@${string}`
    budgetSpent?: boolean | `@${string}`
    budgetSplit?: ValueTypes['BudgetSplitComponent']
    committedReviewsCount?: boolean | `@${string}`
    contentGuidelinesSubmitted?: boolean | `@${string}`
    creatorTargetingEnabled?: boolean | `@${string}`
    creatorTargetingSubmitted?: boolean | `@${string}`
    lifestyleCommittedContentCount?: boolean | `@${string}`
    marketingFee?: boolean | `@${string}`
    premiumLifestyleCommittedContentCount?: boolean | `@${string}`
    productKeywordsEnabled?: boolean | `@${string}`
    productKeywordsSubmitted?: boolean | `@${string}`
    promoCodeSubmitted?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    socialCommittedContentCount?: boolean | `@${string}`
    totalProductCost?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BudgetSplitComponent']: AliasType<{
    autoApprovalSeatsAvailable?: boolean | `@${string}`
    autoBuyActive?: boolean | `@${string}`
    autoBuySchedule?: boolean | `@${string}`
    count?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    unitsPurchased?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Campaign']: AliasType<{
    aboutSection?: boolean | `@${string}`
    active?: boolean | `@${string}`
    actualStartDate?: boolean | `@${string}`
    amountPaidByClient?: boolean | `@${string}`
    anticipatedStartDate?: boolean | `@${string}`
    applicationForm?: boolean | `@${string}`
    applicationProductLink?: ValueTypes['UserLink']
    applicationProductLinkId?: boolean | `@${string}`
    applicationStatus?: boolean | `@${string}`
    attributionRefUrlSuffix?: boolean | `@${string}`
    authorizedUnits?: boolean | `@${string}`
    badges?: ValueTypes['CampaignBadge']
    brand?: ValueTypes['Brand']
    brandId?: boolean | `@${string}`
    brandProductShort?: boolean | `@${string}`
    brandStoreLink?: boolean | `@${string}`
    budgetSpent?: boolean | `@${string}`
    campaignProductType?: boolean | `@${string}`
    campaignProducts?: [
      {
        filter?: ValueTypes['ModelProductFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductConnection']
    ]
    campaignReferralBonusAmount?: boolean | `@${string}`
    campaignReferralBonusEnabled?: boolean | `@${string}`
    campaignStateMachine?: boolean | `@${string}`
    closeoutReleaseFailureReason?: boolean | `@${string}`
    closeoutReleaseRate?: ValueTypes['Rate']
    collisionSkuDensity?: ValueTypes['CollisionDensityValue']
    commissionFlatRate?: boolean | `@${string}`
    commissionPercentageRate?: boolean | `@${string}`
    committedClicks?: boolean | `@${string}`
    committedPlacementWinsCount?: boolean | `@${string}`
    committedReviewsCount?: boolean | `@${string}`
    contentApprovalRequired?: boolean | `@${string}`
    contentCompleteDeadline?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    creationSourceVersion?: boolean | `@${string}`
    dailyClicks?: [
      {
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignClicksFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCampaignClicksConnection']
    ]
    earlyReviewEnabled?: boolean | `@${string}`
    enableContentComplianceReview?: boolean | `@${string}`
    endDate?: boolean | `@${string}`
    excludeGender?: boolean | `@${string}`
    expectedBonus?: boolean | `@${string}`
    followersCountCeiling?: boolean | `@${string}`
    followersCountFloor?: boolean | `@${string}`
    goal?: boolean | `@${string}`
    id?: boolean | `@${string}`
    influencerApprovalEnabled?: boolean | `@${string}`
    lifestyleCommittedContentCount?: boolean | `@${string}`
    localeV1?: boolean | `@${string}`
    marketingFee?: boolean | `@${string}`
    marketingFormFilled?: boolean | `@${string}`
    newCreatorsOnly?: boolean | `@${string}`
    payForClicksEnabled?: boolean | `@${string}`
    payForClicksGeneratedPerPeriod?: ValueTypes['ClicksRecords']
    payForClicksMinClicks?: boolean | `@${string}`
    payForClicksOriginalLink?: boolean | `@${string}`
    payForClicksTotalClicksGenerated?: boolean | `@${string}`
    payForPerformanceEnabled?: boolean | `@${string}`
    postingGuidance?: boolean | `@${string}`
    preApplicationProductImage?: boolean | `@${string}`
    preApplicationProductPriceRange?: boolean | `@${string}`
    preApplicationUgcProductImage?: boolean | `@${string}`
    preLaunchDate?: boolean | `@${string}`
    premiumLifestyleCommittedContentCount?: boolean | `@${string}`
    productKeywords?: [
      {
        filter?: ValueTypes['ModelProductKeywordsFilterInput'] | undefined | null
        keyword?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductKeywordsConnection']
    ]
    requiredProductLinksEnabled?: boolean | `@${string}`
    reviewVotingTarget?: boolean | `@${string}`
    sample?: boolean | `@${string}`
    shellCampaignBase?: boolean | `@${string}`
    sku?: ValueTypes['Sku']
    skuId?: boolean | `@${string}`
    skuPriceOverride?: boolean | `@${string}`
    socialCommittedContentCount?: boolean | `@${string}`
    sourcingPriority?: boolean | `@${string}`
    sponsorshipInstructions?: boolean | `@${string}`
    title?: boolean | `@${string}`
    totalEarlyReviewsAllowed?: boolean | `@${string}`
    totalProductCost?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userAutoApproved?: boolean | `@${string}`
    visibleToUser?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignBadge']: AliasType<{
    type?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignClicks']: AliasType<{
    campaignId?: boolean | `@${string}`
    clicks?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignForUser']: AliasType<{
    about?: boolean | `@${string}`
    badges?: ValueTypes['CampaignBadge']
    brandName?: boolean | `@${string}`
    campaignProductType?: boolean | `@${string}`
    campaignReferralBonusAmount?: boolean | `@${string}`
    campaignReferralEnabled?: boolean | `@${string}`
    contentApprovalLeadDays?: boolean | `@${string}`
    expectedBonusMax?: boolean | `@${string}`
    expectedBonusMin?: boolean | `@${string}`
    flowForUser?: ValueTypes['CampaignForUserFlow']
    flows?: ValueTypes['CampaignForUserFlow']
    hasUserApplied?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isAdminOnlyView?: boolean | `@${string}`
    isFreeGift?: boolean | `@${string}`
    isPayForClicksEnabled?: boolean | `@${string}`
    isPayForPerformanceEnabled?: boolean | `@${string}`
    isShipToCustomer?: boolean | `@${string}`
    isUserAutoApproved?: boolean | `@${string}`
    locale?: boolean | `@${string}`
    payForPerformanceBonusFlatRate?: boolean | `@${string}`
    payForPerformanceBonusPercentageRate?: boolean | `@${string}`
    postingDeadline?: boolean | `@${string}`
    preApplicationProductImage?: boolean | `@${string}`
    preApplicationProductName?: boolean | `@${string}`
    preApplicationProductPrice?: boolean | `@${string}`
    preApplicationUgcProductImage?: boolean | `@${string}`
    productForUser?: ValueTypes['CampaignForUserProduct']
    productImage?: boolean | `@${string}`
    productLink?: boolean | `@${string}`
    productPrice?: boolean | `@${string}`
    productTitle?: boolean | `@${string}`
    products?: ValueTypes['CampaignForUserProduct']
    productsForUser?: ValueTypes['CampaignForUserProduct']
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    specialInstructions?: boolean | `@${string}`
    sponsorshipInstructions?: boolean | `@${string}`
    storeName?: boolean | `@${string}`
    title?: boolean | `@${string}`
    totalSeatsAvailable?: boolean | `@${string}`
    userCampaignCreatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userCampaignStatus?: boolean | `@${string}`
    userSpecificBonusConfig?: ValueTypes['UserCampaignBonusConfig']
    __typename?: boolean | `@${string}`
  }>
  ['CampaignForUserFlow']: AliasType<{
    autoApprovalSeatsAvailable?: boolean | `@${string}`
    contentRequirement?: ValueTypes['ProductSocialContent']
    customFtcHashtags?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isPayForClicksEnabled?: boolean | `@${string}`
    payForImpressionsBonusConfig?: ValueTypes['PayForImpressionsBonusConfig']
    qualityLevel?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignForUserProduct']: AliasType<{
    autoApprovalSeatsAvailable?: boolean | `@${string}`
    contentRequirement?: ValueTypes['ProductSocialContent']
    customFtcHashtags?: boolean | `@${string}`
    flowId?: boolean | `@${string}`
    id?: boolean | `@${string}`
    image?: boolean | `@${string}`
    isPayForClicksEnabled?: boolean | `@${string}`
    payForImpressionsBonusConfig?: ValueTypes['PayForImpressionsBonusConfig']
    price?: boolean | `@${string}`
    productLink?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    storeName?: boolean | `@${string}`
    title?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignInvite']: AliasType<{
    campaign?: ValueTypes['Campaign']
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    notificationStatus?: boolean | `@${string}`
    priority?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignReferralBonus']: AliasType<{
    campaignName?: boolean | `@${string}`
    payoutDate?: boolean | `@${string}`
    totalEarnings?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignSourcingProspect']: AliasType<{
    bonusConfig?: ValueTypes['UserCampaignBonusConfig']
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    createdById?: boolean | `@${string}`
    instagramFollowersInt?: boolean | `@${string}`
    instagramHandle?: boolean | `@${string}`
    internalNotes?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelNoteConnection']
    ]
    internalNotesId?: boolean | `@${string}`
    name?: boolean | `@${string}`
    platformInviteSource?: boolean | `@${string}`
    platformStatus?: boolean | `@${string}`
    preApprovalStatus?: boolean | `@${string}`
    preApprovalStatusById?: boolean | `@${string}`
    priority?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    sourcingSource?: boolean | `@${string}`
    tiktokFollowersInt?: boolean | `@${string}`
    tiktokHandle?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userCampaign?: ValueTypes['UserCampaign']
    userCampaignId?: boolean | `@${string}`
    userIdentifier?: boolean | `@${string}`
    youtubeChannelFollowersInt?: boolean | `@${string}`
    youtubeChannelHandle?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CardCreateOutput']: AliasType<{
    cardId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CardDeleteOutput']: AliasType<{
    cardId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CardDetails']: AliasType<{
    address?: boolean | `@${string}`
    cvc?: boolean | `@${string}`
    expMonth?: boolean | `@${string}`
    expYear?: boolean | `@${string}`
    last4?: boolean | `@${string}`
    name?: boolean | `@${string}`
    number?: boolean | `@${string}`
    primaryAccountIdentifier?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ChannelConfiguration']: AliasType<{
    attributes?: ValueTypes['KeyAttribute']
    enabled?: boolean | `@${string}`
    fromAddress?: boolean | `@${string}`
    templateId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ChatConversationV2']: AliasType<{
    active?: boolean | `@${string}`
    adminLastReadMessageIndex?: boolean | `@${string}`
    adminUnreadCount?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    chatType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    creatorLastReadMessageIndex?: boolean | `@${string}`
    creatorUnreadCount?: boolean | `@${string}`
    lastMessage?: boolean | `@${string}`
    lastMessageDate?: boolean | `@${string}`
    lastMessageId?: boolean | `@${string}`
    lastMessageSource?: boolean | `@${string}`
    resourceId?: boolean | `@${string}`
    status?: boolean | `@${string}`
    statusUpdatedAt?: boolean | `@${string}`
    statusUpdatedByFirstName?: boolean | `@${string}`
    statusUpdatedById?: boolean | `@${string}`
    statusUpdatedByLastName?: boolean | `@${string}`
    twilioConversationId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userCampaignId?: boolean | `@${string}`
    userEmail?: boolean | `@${string}`
    userFirstName?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    userLastName?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ClicksRecords']: AliasType<{
    clicks?: boolean | `@${string}`
    from?: boolean | `@${string}`
    to?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CognitoUserAttribute']: AliasType<{
    Name?: boolean | `@${string}`
    Value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CognitoUserModel']: AliasType<{
    UserAttributes?: ValueTypes['CognitoUserAttribute']
    Username?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CollisionDensityValue']: AliasType<{
    density?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CommunicationNotification']: AliasType<{
    address?: boolean | `@${string}`
    attributes?: ValueTypes['KeyAttribute']
    campaignId?: boolean | `@${string}`
    channel?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    expectedSentAt?: boolean | `@${string}`
    experimentTreatment?: boolean | `@${string}`
    id?: boolean | `@${string}`
    notificationId?: boolean | `@${string}`
    sentAt?: boolean | `@${string}`
    status?: boolean | `@${string}`
    statusMessage?: boolean | `@${string}`
    templateId?: boolean | `@${string}`
    traceId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Company']: AliasType<{
    agencyId?: boolean | `@${string}`
    brands?: [
      {
        filter?: ValueTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelBrandConnection']
    ]
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    logo?: boolean | `@${string}`
    name?: boolean | `@${string}`
    paymentTermsCustomNetDays?: boolean | `@${string}`
    paymentTermsType?: boolean | `@${string}`
    subscriptionEndsAt?: boolean | `@${string}`
    subscriptionStartsAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    websiteUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ConnectedAccount']: AliasType<{
    accountId?: boolean | `@${string}`
    accountType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentApprovalConfiguration']: AliasType<{
    contentApprovalAssignee?: boolean | `@${string}`
    contentApprovalType?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentComplianceReviewCriteria']: AliasType<{
    compliant?: boolean | `@${string}`
    criteria?: boolean | `@${string}`
    requirement?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentGuidance']: AliasType<{
    instance?: boolean | `@${string}`
    text?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentLabelResult']: AliasType<{
    confidence?: boolean | `@${string}`
    label?: boolean | `@${string}`
    severity?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentRatingSponsorshipContent']: AliasType<{
    inputSource?: boolean | `@${string}`
    isCompliant?: boolean | `@${string}`
    isInappropriate?: boolean | `@${string}`
    link?: boolean | `@${string}`
    qualityRating?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentSafetyAnalysis']: AliasType<{
    isBrandSafe?: boolean | `@${string}`
    summary?: ValueTypes['ContentSafetyLabelSummary']
    textInstances?: ValueTypes['ContentSafetyTextInstance']
    __typename?: boolean | `@${string}`
  }>
  ['ContentSafetyLabelSummary']: AliasType<{
    confidence?: boolean | `@${string}`
    label?: boolean | `@${string}`
    severity?: ValueTypes['ContentSafetySeverity']
    __typename?: boolean | `@${string}`
  }>
  ['ContentSafetySeverity']: AliasType<{
    high?: boolean | `@${string}`
    low?: boolean | `@${string}`
    medium?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentSafetyTextInstance']: AliasType<{
    labels?: ValueTypes['ContentLabelResult']
    text?: boolean | `@${string}`
    timestampEnd?: boolean | `@${string}`
    timestampStart?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Conversation']: AliasType<{
    contactOwner?: ValueTypes['User']
    contactOwnerId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    lastContactAt?: boolean | `@${string}`
    messages?: [
      {
        filter?: ValueTypes['ModelConversationMessageFilterInput'] | undefined | null
        id?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelConversationMessageConnection']
    ]
    status?: boolean | `@${string}`
    statusChangedAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ConversationInternalStatusDetails']: AliasType<{
    status?: boolean | `@${string}`
    statusUpdatedAt?: boolean | `@${string}`
    statusUpdatedByFirstName?: boolean | `@${string}`
    statusUpdatedById?: boolean | `@${string}`
    statusUpdatedByLastName?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ConversationMessage']: AliasType<{
    channel?: boolean | `@${string}`
    conversation?: ValueTypes['Conversation']
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    messageContent?: boolean | `@${string}`
    messageId?: boolean | `@${string}`
    messageMediaKeys?: boolean | `@${string}`
    replyFrom?: ValueTypes['User']
    replyFromId?: boolean | `@${string}`
    sendAt?: boolean | `@${string}`
    sendAttempts?: boolean | `@${string}`
    sentAt?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorContentRatingRawValue']: AliasType<{
    name?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorReferralBonus']: AliasType<{
    earnings?: boolean | `@${string}`
    payoutDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorReferralSignup']: AliasType<{
    signupDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['EditUserSocialContentResponse']: AliasType<{
    externalUrl?: boolean | `@${string}`
    id?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['EtailerSkuMetrics']: AliasType<{
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    pageViews?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    totalSalesAmount?: boolean | `@${string}`
    unitsSold?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormDefinition']: AliasType<{
    formFields?: ValueTypes['FormFieldDefinition']
    name?: boolean | `@${string}`
    pages?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormFieldDefinition']: AliasType<{
    bonus?: boolean | `@${string}`
    componentType?: boolean | `@${string}`
    destination?: boolean | `@${string}`
    formName?: boolean | `@${string}`
    isProfileQuestion?: boolean | `@${string}`
    link?: boolean | `@${string}`
    options?: boolean | `@${string}`
    page?: boolean | `@${string}`
    placeholder?: boolean | `@${string}`
    primaryText?: boolean | `@${string}`
    secondaryText?: boolean | `@${string}`
    sequenceComponent?: ValueTypes['FormSequenceComponent']
    sequenceId?: boolean | `@${string}`
    tooltipText?: boolean | `@${string}`
    type?: boolean | `@${string}`
    validation?: ValueTypes['FormValidation']
    withOther?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormSequence']: AliasType<{
    components?: ValueTypes['FormSequenceComponent']
    id?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormSequenceComponent']: AliasType<{
    componentName?: boolean | `@${string}`
    componentType?: boolean | `@${string}`
    content?: boolean | `@${string}`
    id?: boolean | `@${string}`
    images?: boolean | `@${string}`
    numbered?: boolean | `@${string}`
    placeholder?: boolean | `@${string}`
    type?: boolean | `@${string}`
    validation?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormSequenceConfiguration']: AliasType<{
    active?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    sequenceName?: boolean | `@${string}`
    sequences?: ValueTypes['FormSequence']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormValidation']: AliasType<{
    type?: boolean | `@${string}`
    validationText?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['GetConversationsAccessTokenResponse']: AliasType<{
    accessToken?: boolean | `@${string}`
    ttl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['GetFeatureFlagResponse']: AliasType<{
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InspirationalContent']: AliasType<{
    socialChannel?: boolean | `@${string}`
    type?: boolean | `@${string}`
    url?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InstagramCountryDistributionItem']: AliasType<{
    count?: boolean | `@${string}`
    countryCode?: boolean | `@${string}`
    pctDistribution?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InstagramGenderAgeDistribution']: AliasType<{
    femaleCount?: boolean | `@${string}`
    genderAgeDistribution?: ValueTypes['InstagramGenderAgeDistributionItem']
    maleCount?: boolean | `@${string}`
    unspecifiedCount?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InstagramGenderAgeDistributionItem']: AliasType<{
    ageRangeEnd?: boolean | `@${string}`
    ageRangeStart?: boolean | `@${string}`
    count?: boolean | `@${string}`
    gender?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InstagramStoryScreen']: AliasType<{
    createdAt?: boolean | `@${string}`
    exits?: boolean | `@${string}`
    expirationUnixTime?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    id?: boolean | `@${string}`
    impressions?: boolean | `@${string}`
    instagramUserId?: boolean | `@${string}`
    mediaId?: boolean | `@${string}`
    postedAt?: boolean | `@${string}`
    postingDate?: boolean | `@${string}`
    reach?: boolean | `@${string}`
    replies?: boolean | `@${string}`
    s3Bucket?: boolean | `@${string}`
    tapsBack?: boolean | `@${string}`
    tapsForward?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['KeyAttribute']: AliasType<{
    key?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['KeywordResponse']: AliasType<{
    hash?: boolean | `@${string}`
    keywordId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ListCampaignsForUserResponse']: AliasType<{
    campaigns?: ValueTypes['CampaignForUser']
    emptyReason?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Message']: AliasType<{
    body?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaign?: ValueTypes['UserCampaign']
    userCampaignId?: boolean | `@${string}`
    userHasSeen?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['MessagingChannelConfiguration']: AliasType<{
    attributes?: ValueTypes['KeyAttribute']
    enabled?: boolean | `@${string}`
    templateContent?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['MinMaxRange']: AliasType<{
    max?: boolean | `@${string}`
    min?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelAutoBuyDistributionConnection']: AliasType<{
    items?: ValueTypes['AutoBuyDistribution']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelAutoBuyFlowConfigurationConnection']: AliasType<{
    items?: ValueTypes['AutoBuyFlowConfiguration']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelAutoBuyFlowReleaseScheduleConnection']: AliasType<{
    items?: ValueTypes['AutoBuyFlowReleaseSchedule']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelAutoBuyReleaseConnection']: AliasType<{
    items?: ValueTypes['AutoBuyRelease']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelBrandApiConnection']: AliasType<{
    items?: ValueTypes['BrandApi']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelBrandConnection']: AliasType<{
    items?: ValueTypes['Brand']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCampaignClicksConnection']: AliasType<{
    items?: ValueTypes['CampaignClicks']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCampaignConnection']: AliasType<{
    items?: ValueTypes['Campaign']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCampaignInviteConnection']: AliasType<{
    items?: ValueTypes['CampaignInvite']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCampaignSourcingProspectConnection']: AliasType<{
    items?: ValueTypes['CampaignSourcingProspect']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelChatConversationV2Connection']: AliasType<{
    items?: ValueTypes['ChatConversationV2']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCommunicationNotificationConnection']: AliasType<{
    items?: ValueTypes['CommunicationNotification']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCompanyConnection']: AliasType<{
    items?: ValueTypes['Company']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelConnectedAccountConnection']: AliasType<{
    items?: ValueTypes['ConnectedAccount']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelConversationConnection']: AliasType<{
    items?: ValueTypes['Conversation']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelConversationMessageConnection']: AliasType<{
    items?: ValueTypes['ConversationMessage']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelEtailerSkuMetricsConnection']: AliasType<{
    items?: ValueTypes['EtailerSkuMetrics']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelFormSequenceConfigurationConnection']: AliasType<{
    items?: ValueTypes['FormSequenceConfiguration']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelInstagramStoryScreenConnection']: AliasType<{
    items?: ValueTypes['InstagramStoryScreen']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelMessageConnection']: AliasType<{
    items?: ValueTypes['Message']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelNoteConnection']: AliasType<{
    items?: ValueTypes['Note']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelNotificationConfigurationConnection']: AliasType<{
    items?: ValueTypes['NotificationConfiguration']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelProductBonusConfigSnapshotConnection']: AliasType<{
    items?: ValueTypes['ProductBonusConfigSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelProductConnection']: AliasType<{
    items?: ValueTypes['Product']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelProductKeywordsConnection']: AliasType<{
    items?: ValueTypes['ProductKeywords']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelProductRatingSnapshotConnection']: AliasType<{
    items?: ValueTypes['ProductRatingSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelPurchaseConnection']: AliasType<{
    items?: ValueTypes['Purchase']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelReferralConnection']: AliasType<{
    items?: ValueTypes['Referral']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelReviewConnection']: AliasType<{
    items?: ValueTypes['Review']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSkuConnection']: AliasType<{
    items?: ValueTypes['Sku']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSkuSnapshotConnection']: AliasType<{
    items?: ValueTypes['SkuSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserBonusPayoutConnection']: AliasType<{
    items?: ValueTypes['UserBonusPayout']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignChangelogConnection']: AliasType<{
    items?: ValueTypes['UserCampaignChangelog']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignConnection']: AliasType<{
    items?: ValueTypes['UserCampaign']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignSnapshotConnection']: AliasType<{
    items?: ValueTypes['UserCampaignSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignSocialContentConnection']: AliasType<{
    items?: ValueTypes['UserCampaignSocialContent']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignSocialContentDraftHistoryConnection']: AliasType<{
    items?: ValueTypes['UserCampaignSocialContentDraftHistory']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignSocialContentReviewConnection']: AliasType<{
    items?: ValueTypes['UserCampaignSocialContentReview']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserConnection']: AliasType<{
    items?: ValueTypes['User']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserDeviceSnapshotConnection']: AliasType<{
    items?: ValueTypes['UserDeviceSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserFollowerConnection']: AliasType<{
    items?: ValueTypes['UserFollower']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserLinkConnection']: AliasType<{
    items?: ValueTypes['UserLink']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserNotificationPreferenceConnection']: AliasType<{
    items?: ValueTypes['UserNotificationPreference']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserPayoutRequestConnection']: AliasType<{
    items?: ValueTypes['UserPayoutRequest']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserStagingApprovalConnection']: AliasType<{
    items?: ValueTypes['UserStagingApproval']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelVideoContentScanConnection']: AliasType<{
    items?: ValueTypes['VideoContentScan']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Mutation']: AliasType<{
    adminSendPushMessage?: [
      { input: ValueTypes['AdminSendPushNotificationInput'] },
      ValueTypes['AdminSendPushMessageResponse']
    ]
    applyToCampaign?: [{ input: ValueTypes['ApplyToCampaignInput'] }, ValueTypes['ApplyToCampaignOutput']]
    approveUserCampaignForPurchase?: [
      { input?: ValueTypes['ApproveUserCampaignForPurchaseInput'] | undefined | null },
      ValueTypes['ApproveUserCampaignForPurchaseResponse']
    ]
    createAutoBuyDistribution?: [
      {
        condition?: ValueTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null
        input: ValueTypes['CreateAutoBuyDistributionInput']
      },
      ValueTypes['AutoBuyDistribution']
    ]
    createAutoBuyFlowConfiguration?: [
      {
        condition?: ValueTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null
        input: ValueTypes['CreateAutoBuyFlowConfigurationInput']
      },
      ValueTypes['AutoBuyFlowConfiguration']
    ]
    createAutoBuyFlowReleaseSchedule?: [
      {
        condition?: ValueTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null
        input: ValueTypes['CreateAutoBuyFlowReleaseScheduleInput']
      },
      ValueTypes['AutoBuyFlowReleaseSchedule']
    ]
    createAutoBuyRelease?: [
      {
        condition?: ValueTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null
        input: ValueTypes['CreateAutoBuyReleaseInput']
      },
      ValueTypes['AutoBuyRelease']
    ]
    createBrand?: [
      { condition?: ValueTypes['ModelBrandConditionInput'] | undefined | null; input: ValueTypes['CreateBrandInput'] },
      ValueTypes['Brand']
    ]
    createBrandApi?: [
      {
        condition?: ValueTypes['ModelBrandApiConditionInput'] | undefined | null
        input: ValueTypes['CreateBrandApiInput']
      },
      ValueTypes['BrandApi']
    ]
    createCampaign?: [
      {
        condition?: ValueTypes['ModelCampaignConditionInput'] | undefined | null
        input: ValueTypes['CreateCampaignInput']
      },
      ValueTypes['Campaign']
    ]
    createCampaignClicks?: [
      {
        condition?: ValueTypes['ModelCampaignClicksConditionInput'] | undefined | null
        input: ValueTypes['CreateCampaignClicksInput']
      },
      ValueTypes['CampaignClicks']
    ]
    createCampaignInvite?: [
      {
        condition?: ValueTypes['ModelCampaignInviteConditionInput'] | undefined | null
        input: ValueTypes['CreateCampaignInviteInput']
      },
      ValueTypes['CampaignInvite']
    ]
    createCampaignSourcingProspect?: [
      {
        condition?: ValueTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null
        input: ValueTypes['CreateCampaignSourcingProspectInput']
      },
      ValueTypes['CampaignSourcingProspect']
    ]
    createChatConversationV2?: [
      {
        condition?: ValueTypes['ModelChatConversationV2ConditionInput'] | undefined | null
        input: ValueTypes['CreateChatConversationV2Input']
      },
      ValueTypes['ChatConversationV2']
    ]
    createCommunicationNotification?: [
      {
        condition?: ValueTypes['ModelCommunicationNotificationConditionInput'] | undefined | null
        input: ValueTypes['CreateCommunicationNotificationInput']
      },
      ValueTypes['CommunicationNotification']
    ]
    createCompany?: [
      {
        condition?: ValueTypes['ModelCompanyConditionInput'] | undefined | null
        input: ValueTypes['CreateCompanyInput']
      },
      ValueTypes['Company']
    ]
    createConnectedAccount?: [
      {
        condition?: ValueTypes['ModelConnectedAccountConditionInput'] | undefined | null
        input: ValueTypes['CreateConnectedAccountInput']
      },
      ValueTypes['ConnectedAccount']
    ]
    createConversation?: [
      {
        condition?: ValueTypes['ModelConversationConditionInput'] | undefined | null
        input: ValueTypes['CreateConversationInput']
      },
      ValueTypes['Conversation']
    ]
    createConversationMessage?: [
      {
        condition?: ValueTypes['ModelConversationMessageConditionInput'] | undefined | null
        input: ValueTypes['CreateConversationMessageInput']
      },
      ValueTypes['ConversationMessage']
    ]
    createEtailerSkuMetrics?: [
      {
        condition?: ValueTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null
        input: ValueTypes['CreateEtailerSkuMetricsInput']
      },
      ValueTypes['EtailerSkuMetrics']
    ]
    createFormSequenceConfiguration?: [
      {
        condition?: ValueTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null
        input: ValueTypes['CreateFormSequenceConfigurationInput']
      },
      ValueTypes['FormSequenceConfiguration']
    ]
    createInstagramStoryScreen?: [
      {
        condition?: ValueTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null
        input: ValueTypes['CreateInstagramStoryScreenInput']
      },
      ValueTypes['InstagramStoryScreen']
    ]
    createManualPurchase?: [
      { amount: number; purchaseDate?: string | undefined | null; userCampaignId: string },
      ValueTypes['UserCampaign']
    ]
    createMessage?: [
      {
        condition?: ValueTypes['ModelMessageConditionInput'] | undefined | null
        input: ValueTypes['CreateMessageInput']
      },
      ValueTypes['Message']
    ]
    createNote?: [
      { condition?: ValueTypes['ModelNoteConditionInput'] | undefined | null; input: ValueTypes['CreateNoteInput'] },
      ValueTypes['Note']
    ]
    createNotificationConfiguration?: [
      {
        condition?: ValueTypes['ModelNotificationConfigurationConditionInput'] | undefined | null
        input: ValueTypes['CreateNotificationConfigurationInput']
      },
      ValueTypes['NotificationConfiguration']
    ]
    createProduct?: [
      {
        condition?: ValueTypes['ModelProductConditionInput'] | undefined | null
        input: ValueTypes['CreateProductInput']
      },
      ValueTypes['Product']
    ]
    createProductBonusConfigSnapshot?: [
      {
        condition?: ValueTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null
        input: ValueTypes['CreateProductBonusConfigSnapshotInput']
      },
      ValueTypes['ProductBonusConfigSnapshot']
    ]
    createProductFromUrl?: [{ input: ValueTypes['CreateProductFromUrlInput'] }, ValueTypes['Sku']]
    createProductKeywords?: [
      {
        condition?: ValueTypes['ModelProductKeywordsConditionInput'] | undefined | null
        input: ValueTypes['CreateProductKeywordsInput']
      },
      ValueTypes['ProductKeywords']
    ]
    createProductRatingSnapshot?: [
      {
        condition?: ValueTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null
        input: ValueTypes['CreateProductRatingSnapshotInput']
      },
      ValueTypes['ProductRatingSnapshot']
    ]
    createPurchase?: [
      {
        condition?: ValueTypes['ModelPurchaseConditionInput'] | undefined | null
        input: ValueTypes['CreatePurchaseInput']
      },
      ValueTypes['Purchase']
    ]
    createReferral?: [
      {
        condition?: ValueTypes['ModelReferralConditionInput'] | undefined | null
        input: ValueTypes['CreateReferralInput']
      },
      ValueTypes['Referral']
    ]
    createReview?: [
      {
        condition?: ValueTypes['ModelReviewConditionInput'] | undefined | null
        input: ValueTypes['CreateReviewInput']
      },
      ValueTypes['Review']
    ]
    createShortLink?: [{ campaignId: string; link: string; userId?: string | undefined | null }, ValueTypes['UserLink']]
    createSku?: [
      { condition?: ValueTypes['ModelSkuConditionInput'] | undefined | null; input: ValueTypes['CreateSkuInput'] },
      ValueTypes['Sku']
    ]
    createSkuSnapshot?: [
      {
        condition?: ValueTypes['ModelSkuSnapshotConditionInput'] | undefined | null
        input: ValueTypes['CreateSkuSnapshotInput']
      },
      ValueTypes['SkuSnapshot']
    ]
    createTwilioConversation?: [
      { input: ValueTypes['CreateTwilioConversationInput'] },
      ValueTypes['ChatConversationV2']
    ]
    createUser?: [
      { condition?: ValueTypes['ModelUserConditionInput'] | undefined | null; input: ValueTypes['CreateUserInput'] },
      ValueTypes['User']
    ]
    createUserBonusPayout?: [
      {
        condition?: ValueTypes['ModelUserBonusPayoutConditionInput'] | undefined | null
        input: ValueTypes['CreateUserBonusPayoutInput']
      },
      ValueTypes['UserBonusPayout']
    ]
    createUserCampaign?: [
      {
        condition?: ValueTypes['ModelUserCampaignConditionInput'] | undefined | null
        input: ValueTypes['CreateUserCampaignInput']
      },
      ValueTypes['UserCampaign']
    ]
    createUserCampaignChangelog?: [
      {
        condition?: ValueTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null
        input: ValueTypes['CreateUserCampaignChangelogInput']
      },
      ValueTypes['UserCampaignChangelog']
    ]
    createUserCampaignSnapshot?: [
      {
        condition?: ValueTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null
        input: ValueTypes['CreateUserCampaignSnapshotInput']
      },
      ValueTypes['UserCampaignSnapshot']
    ]
    createUserCampaignSocialContent?: [
      {
        condition?: ValueTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null
        input: ValueTypes['CreateUserCampaignSocialContentInput']
      },
      ValueTypes['UserCampaignSocialContent']
    ]
    createUserCampaignSocialContentDraftHistory?: [
      {
        condition?: ValueTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null
        input: ValueTypes['CreateUserCampaignSocialContentDraftHistoryInput']
      },
      ValueTypes['UserCampaignSocialContentDraftHistory']
    ]
    createUserCampaignSocialContentReview?: [
      {
        condition?: ValueTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null
        input: ValueTypes['CreateUserCampaignSocialContentReviewInput']
      },
      ValueTypes['UserCampaignSocialContentReview']
    ]
    createUserDeviceSnapshot?: [
      {
        condition?: ValueTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null
        input: ValueTypes['CreateUserDeviceSnapshotInput']
      },
      ValueTypes['UserDeviceSnapshot']
    ]
    createUserFollower?: [
      {
        condition?: ValueTypes['ModelUserFollowerConditionInput'] | undefined | null
        input: ValueTypes['CreateUserFollowerInput']
      },
      ValueTypes['UserFollower']
    ]
    createUserLink?: [
      {
        condition?: ValueTypes['ModelUserLinkConditionInput'] | undefined | null
        input: ValueTypes['CreateUserLinkInput']
      },
      ValueTypes['UserLink']
    ]
    createUserNotificationPreference?: [
      {
        condition?: ValueTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null
        input: ValueTypes['CreateUserNotificationPreferenceInput']
      },
      ValueTypes['UserNotificationPreference']
    ]
    createUserPayoutRequest?: [
      {
        condition?: ValueTypes['ModelUserPayoutRequestConditionInput'] | undefined | null
        input: ValueTypes['CreateUserPayoutRequestInput']
      },
      ValueTypes['UserPayoutRequest']
    ]
    createUserStagingApproval?: [
      {
        condition?: ValueTypes['ModelUserStagingApprovalConditionInput'] | undefined | null
        input: ValueTypes['CreateUserStagingApprovalInput']
      },
      ValueTypes['UserStagingApproval']
    ]
    createVideoContentScan?: [
      {
        condition?: ValueTypes['ModelVideoContentScanConditionInput'] | undefined | null
        input: ValueTypes['CreateVideoContentScanInput']
      },
      ValueTypes['VideoContentScan']
    ]
    deleteAutoBuyDistribution?: [
      {
        condition?: ValueTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null
        input: ValueTypes['DeleteAutoBuyDistributionInput']
      },
      ValueTypes['AutoBuyDistribution']
    ]
    deleteAutoBuyFlowConfiguration?: [
      {
        condition?: ValueTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null
        input: ValueTypes['DeleteAutoBuyFlowConfigurationInput']
      },
      ValueTypes['AutoBuyFlowConfiguration']
    ]
    deleteAutoBuyFlowReleaseSchedule?: [
      {
        condition?: ValueTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null
        input: ValueTypes['DeleteAutoBuyFlowReleaseScheduleInput']
      },
      ValueTypes['AutoBuyFlowReleaseSchedule']
    ]
    deleteAutoBuyRelease?: [
      {
        condition?: ValueTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null
        input: ValueTypes['DeleteAutoBuyReleaseInput']
      },
      ValueTypes['AutoBuyRelease']
    ]
    deleteBrand?: [
      { condition?: ValueTypes['ModelBrandConditionInput'] | undefined | null; input: ValueTypes['DeleteBrandInput'] },
      ValueTypes['Brand']
    ]
    deleteBrandApi?: [
      {
        condition?: ValueTypes['ModelBrandApiConditionInput'] | undefined | null
        input: ValueTypes['DeleteBrandApiInput']
      },
      ValueTypes['BrandApi']
    ]
    deleteCampaign?: [
      {
        condition?: ValueTypes['ModelCampaignConditionInput'] | undefined | null
        input: ValueTypes['DeleteCampaignInput']
      },
      ValueTypes['Campaign']
    ]
    deleteCampaignClicks?: [
      {
        condition?: ValueTypes['ModelCampaignClicksConditionInput'] | undefined | null
        input: ValueTypes['DeleteCampaignClicksInput']
      },
      ValueTypes['CampaignClicks']
    ]
    deleteCampaignInvite?: [
      {
        condition?: ValueTypes['ModelCampaignInviteConditionInput'] | undefined | null
        input: ValueTypes['DeleteCampaignInviteInput']
      },
      ValueTypes['CampaignInvite']
    ]
    deleteCampaignSourcingProspect?: [
      {
        condition?: ValueTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null
        input: ValueTypes['DeleteCampaignSourcingProspectInput']
      },
      ValueTypes['CampaignSourcingProspect']
    ]
    deleteChatConversationV2?: [
      {
        condition?: ValueTypes['ModelChatConversationV2ConditionInput'] | undefined | null
        input: ValueTypes['DeleteChatConversationV2Input']
      },
      ValueTypes['ChatConversationV2']
    ]
    deleteCommunicationNotification?: [
      {
        condition?: ValueTypes['ModelCommunicationNotificationConditionInput'] | undefined | null
        input: ValueTypes['DeleteCommunicationNotificationInput']
      },
      ValueTypes['CommunicationNotification']
    ]
    deleteCompany?: [
      {
        condition?: ValueTypes['ModelCompanyConditionInput'] | undefined | null
        input: ValueTypes['DeleteCompanyInput']
      },
      ValueTypes['Company']
    ]
    deleteConnectedAccount?: [
      {
        condition?: ValueTypes['ModelConnectedAccountConditionInput'] | undefined | null
        input: ValueTypes['DeleteConnectedAccountInput']
      },
      ValueTypes['ConnectedAccount']
    ]
    deleteConversation?: [
      {
        condition?: ValueTypes['ModelConversationConditionInput'] | undefined | null
        input: ValueTypes['DeleteConversationInput']
      },
      ValueTypes['Conversation']
    ]
    deleteConversationMessage?: [
      {
        condition?: ValueTypes['ModelConversationMessageConditionInput'] | undefined | null
        input: ValueTypes['DeleteConversationMessageInput']
      },
      ValueTypes['ConversationMessage']
    ]
    deleteEtailerSkuMetrics?: [
      {
        condition?: ValueTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null
        input: ValueTypes['DeleteEtailerSkuMetricsInput']
      },
      ValueTypes['EtailerSkuMetrics']
    ]
    deleteFormSequenceConfiguration?: [
      {
        condition?: ValueTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null
        input: ValueTypes['DeleteFormSequenceConfigurationInput']
      },
      ValueTypes['FormSequenceConfiguration']
    ]
    deleteInstagramStoryScreen?: [
      {
        condition?: ValueTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null
        input: ValueTypes['DeleteInstagramStoryScreenInput']
      },
      ValueTypes['InstagramStoryScreen']
    ]
    deleteMessage?: [
      {
        condition?: ValueTypes['ModelMessageConditionInput'] | undefined | null
        input: ValueTypes['DeleteMessageInput']
      },
      ValueTypes['Message']
    ]
    deleteNote?: [
      { condition?: ValueTypes['ModelNoteConditionInput'] | undefined | null; input: ValueTypes['DeleteNoteInput'] },
      ValueTypes['Note']
    ]
    deleteNotificationConfiguration?: [
      {
        condition?: ValueTypes['ModelNotificationConfigurationConditionInput'] | undefined | null
        input: ValueTypes['DeleteNotificationConfigurationInput']
      },
      ValueTypes['NotificationConfiguration']
    ]
    deleteProduct?: [
      {
        condition?: ValueTypes['ModelProductConditionInput'] | undefined | null
        input: ValueTypes['DeleteProductInput']
      },
      ValueTypes['Product']
    ]
    deleteProductBonusConfigSnapshot?: [
      {
        condition?: ValueTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null
        input: ValueTypes['DeleteProductBonusConfigSnapshotInput']
      },
      ValueTypes['ProductBonusConfigSnapshot']
    ]
    deleteProductKeywords?: [
      {
        condition?: ValueTypes['ModelProductKeywordsConditionInput'] | undefined | null
        input: ValueTypes['DeleteProductKeywordsInput']
      },
      ValueTypes['ProductKeywords']
    ]
    deleteProductRatingSnapshot?: [
      {
        condition?: ValueTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null
        input: ValueTypes['DeleteProductRatingSnapshotInput']
      },
      ValueTypes['ProductRatingSnapshot']
    ]
    deletePurchase?: [
      {
        condition?: ValueTypes['ModelPurchaseConditionInput'] | undefined | null
        input: ValueTypes['DeletePurchaseInput']
      },
      ValueTypes['Purchase']
    ]
    deleteReferral?: [
      {
        condition?: ValueTypes['ModelReferralConditionInput'] | undefined | null
        input: ValueTypes['DeleteReferralInput']
      },
      ValueTypes['Referral']
    ]
    deleteReview?: [
      {
        condition?: ValueTypes['ModelReviewConditionInput'] | undefined | null
        input: ValueTypes['DeleteReviewInput']
      },
      ValueTypes['Review']
    ]
    deleteSku?: [
      { condition?: ValueTypes['ModelSkuConditionInput'] | undefined | null; input: ValueTypes['DeleteSkuInput'] },
      ValueTypes['Sku']
    ]
    deleteSkuSnapshot?: [
      {
        condition?: ValueTypes['ModelSkuSnapshotConditionInput'] | undefined | null
        input: ValueTypes['DeleteSkuSnapshotInput']
      },
      ValueTypes['SkuSnapshot']
    ]
    deleteUser?: [
      { condition?: ValueTypes['ModelUserConditionInput'] | undefined | null; input: ValueTypes['DeleteUserInput'] },
      ValueTypes['User']
    ]
    deleteUserBonusPayout?: [
      {
        condition?: ValueTypes['ModelUserBonusPayoutConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserBonusPayoutInput']
      },
      ValueTypes['UserBonusPayout']
    ]
    deleteUserCampaign?: [
      {
        condition?: ValueTypes['ModelUserCampaignConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserCampaignInput']
      },
      ValueTypes['UserCampaign']
    ]
    deleteUserCampaignChangelog?: [
      {
        condition?: ValueTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserCampaignChangelogInput']
      },
      ValueTypes['UserCampaignChangelog']
    ]
    deleteUserCampaignSnapshot?: [
      {
        condition?: ValueTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserCampaignSnapshotInput']
      },
      ValueTypes['UserCampaignSnapshot']
    ]
    deleteUserCampaignSocialContent?: [
      {
        condition?: ValueTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserCampaignSocialContentInput']
      },
      ValueTypes['UserCampaignSocialContent']
    ]
    deleteUserCampaignSocialContentDraftHistory?: [
      {
        condition?: ValueTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserCampaignSocialContentDraftHistoryInput']
      },
      ValueTypes['UserCampaignSocialContentDraftHistory']
    ]
    deleteUserCampaignSocialContentReview?: [
      {
        condition?: ValueTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserCampaignSocialContentReviewInput']
      },
      ValueTypes['UserCampaignSocialContentReview']
    ]
    deleteUserDeviceSnapshot?: [
      {
        condition?: ValueTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserDeviceSnapshotInput']
      },
      ValueTypes['UserDeviceSnapshot']
    ]
    deleteUserFollower?: [
      {
        condition?: ValueTypes['ModelUserFollowerConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserFollowerInput']
      },
      ValueTypes['UserFollower']
    ]
    deleteUserLink?: [
      {
        condition?: ValueTypes['ModelUserLinkConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserLinkInput']
      },
      ValueTypes['UserLink']
    ]
    deleteUserNotificationPreference?: [
      {
        condition?: ValueTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserNotificationPreferenceInput']
      },
      ValueTypes['UserNotificationPreference']
    ]
    deleteUserPayoutRequest?: [
      {
        condition?: ValueTypes['ModelUserPayoutRequestConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserPayoutRequestInput']
      },
      ValueTypes['UserPayoutRequest']
    ]
    deleteUserStagingApproval?: [
      {
        condition?: ValueTypes['ModelUserStagingApprovalConditionInput'] | undefined | null
        input: ValueTypes['DeleteUserStagingApprovalInput']
      },
      ValueTypes['UserStagingApproval']
    ]
    deleteVideoContentScan?: [
      {
        condition?: ValueTypes['ModelVideoContentScanConditionInput'] | undefined | null
        input: ValueTypes['DeleteVideoContentScanInput']
      },
      ValueTypes['VideoContentScan']
    ]
    editUserSocialContent?: [
      { externalUrl: string; userCampaignSocialContentId: string },
      ValueTypes['EditUserSocialContentResponse']
    ]
    executeBrandSafetyAnalysis?: [{ userId: string }, boolean | `@${string}`]
    generateSocialVerificationCode?: [
      { socialChannel?: ValueTypes['SocialChannel'] | undefined | null },
      ValueTypes['SocialVerificationCode']
    ]
    handleUserCampaignStagingApprovals?: [
      {
        approvalFeedback?: string | undefined | null
        userCampaignId: string
        userStagingApprovalAction: ValueTypes['UserStagingApprovalAction']
        userStagingApprovalType: ValueTypes['UserStagingApprovalType']
      },
      ValueTypes['UserStagingApproval']
    ]
    inviteUsersToCampaign?: [{ input: ValueTypes['InviteUsersToCampaignInput'] }, ValueTypes['CampaignInvite']]
    markCreatorAsFavorite?: [{ isFavorite: boolean; userId: string }, ValueTypes['UserFollower']]
    processSelectedInstagramStories?: [
      { storyIds: Array<string | undefined | null>; userCampaignId: string },
      ValueTypes['InstagramStoryScreen']
    ]
    purchasesCardCreate?: [
      { hasAgreedTerms?: boolean | undefined | null; userCampaignId: string },
      ValueTypes['CardCreateOutput']
    ]
    purchasesCardDelete?: [{ userCampaignId: string }, ValueTypes['CardDeleteOutput']]
    purchasesEphemeralCreate?: [{ userCampaignId: string }, boolean | `@${string}`]
    refreshSocialToken?: [
      {
        accessToken?: string | undefined | null
        accountId?: string | undefined | null
        codeVerifier?: string | undefined | null
        redirectURI?: string | undefined | null
        refreshToken?: string | undefined | null
        social: ValueTypes['SocialType']
      },
      boolean | `@${string}`
    ]
    requestUserBalancePayout?: [{ userId: string }, ValueTypes['UserPayoutRequest']]
    requestUserDeactivation?: [{ deactivationReason: string; userId: string }, boolean | `@${string}`]
    resetUnverifiedUserAttributes?: [{ userId?: string | undefined | null }, ValueTypes['ResetUserAttributeResponse']]
    resetUserEmail?: [{ email: string; userId?: string | undefined | null }, ValueTypes['ResetUserAttributeResponse']]
    resetUserPhoneNumber?: [
      { phoneNumber: string; userId?: string | undefined | null },
      ValueTypes['ResetUserAttributeResponse']
    ]
    rollbackUserCampaign?: [
      {
        additionalArguments?: ValueTypes['RollbackAdditionalArguments'] | undefined | null
        rollbackState: ValueTypes['CampaignStatus']
        userCampaignId: string
      },
      ValueTypes['UserCampaign']
    ]
    scrapeMediaAssetToStorage?: [{ assetId: string; prefix: string; url: string }, boolean | `@${string}`]
    socialUnlink?: [{ socialChannel: ValueTypes['SocialChannel'] }, ValueTypes['SocialUnlinkResult']]
    submitUserCampaignStateChange?: [
      { input: ValueTypes['SubmitUserCampaignStateChangeInput'] },
      ValueTypes['UserCampaign']
    ]
    updateAutoBuyDistribution?: [
      {
        condition?: ValueTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null
        input: ValueTypes['UpdateAutoBuyDistributionInput']
      },
      ValueTypes['AutoBuyDistribution']
    ]
    updateAutoBuyFlowConfiguration?: [
      {
        condition?: ValueTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null
        input: ValueTypes['UpdateAutoBuyFlowConfigurationInput']
      },
      ValueTypes['AutoBuyFlowConfiguration']
    ]
    updateAutoBuyFlowReleaseSchedule?: [
      {
        condition?: ValueTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null
        input: ValueTypes['UpdateAutoBuyFlowReleaseScheduleInput']
      },
      ValueTypes['AutoBuyFlowReleaseSchedule']
    ]
    updateAutoBuyRelease?: [
      {
        condition?: ValueTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null
        input: ValueTypes['UpdateAutoBuyReleaseInput']
      },
      ValueTypes['AutoBuyRelease']
    ]
    updateBrand?: [
      { condition?: ValueTypes['ModelBrandConditionInput'] | undefined | null; input: ValueTypes['UpdateBrandInput'] },
      ValueTypes['Brand']
    ]
    updateBrandApi?: [
      {
        condition?: ValueTypes['ModelBrandApiConditionInput'] | undefined | null
        input: ValueTypes['UpdateBrandApiInput']
      },
      ValueTypes['BrandApi']
    ]
    updateCampaign?: [
      {
        condition?: ValueTypes['ModelCampaignConditionInput'] | undefined | null
        input: ValueTypes['UpdateCampaignInput']
      },
      ValueTypes['Campaign']
    ]
    updateCampaignClicks?: [
      {
        condition?: ValueTypes['ModelCampaignClicksConditionInput'] | undefined | null
        input: ValueTypes['UpdateCampaignClicksInput']
      },
      ValueTypes['CampaignClicks']
    ]
    updateCampaignInvite?: [
      {
        condition?: ValueTypes['ModelCampaignInviteConditionInput'] | undefined | null
        input: ValueTypes['UpdateCampaignInviteInput']
      },
      ValueTypes['CampaignInvite']
    ]
    updateCampaignSourcingProspect?: [
      {
        condition?: ValueTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null
        input: ValueTypes['UpdateCampaignSourcingProspectInput']
      },
      ValueTypes['CampaignSourcingProspect']
    ]
    updateChatConversationV2?: [
      {
        condition?: ValueTypes['ModelChatConversationV2ConditionInput'] | undefined | null
        input: ValueTypes['UpdateChatConversationV2Input']
      },
      ValueTypes['ChatConversationV2']
    ]
    updateCommunicationNotification?: [
      {
        condition?: ValueTypes['ModelCommunicationNotificationConditionInput'] | undefined | null
        input: ValueTypes['UpdateCommunicationNotificationInput']
      },
      ValueTypes['CommunicationNotification']
    ]
    updateCompany?: [
      {
        condition?: ValueTypes['ModelCompanyConditionInput'] | undefined | null
        input: ValueTypes['UpdateCompanyInput']
      },
      ValueTypes['Company']
    ]
    updateConnectedAccount?: [
      {
        condition?: ValueTypes['ModelConnectedAccountConditionInput'] | undefined | null
        input: ValueTypes['UpdateConnectedAccountInput']
      },
      ValueTypes['ConnectedAccount']
    ]
    updateContentApprovals?: [
      { input: ValueTypes['UpdateContentApprovalsInput'] },
      ValueTypes['UserCampaignSocialContentReview']
    ]
    updateConversation?: [
      {
        condition?: ValueTypes['ModelConversationConditionInput'] | undefined | null
        input: ValueTypes['UpdateConversationInput']
      },
      ValueTypes['Conversation']
    ]
    updateConversationMessage?: [
      {
        condition?: ValueTypes['ModelConversationMessageConditionInput'] | undefined | null
        input: ValueTypes['UpdateConversationMessageInput']
      },
      ValueTypes['ConversationMessage']
    ]
    updateEtailerSkuMetrics?: [
      {
        condition?: ValueTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null
        input: ValueTypes['UpdateEtailerSkuMetricsInput']
      },
      ValueTypes['EtailerSkuMetrics']
    ]
    updateFormSequenceConfiguration?: [
      {
        condition?: ValueTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null
        input: ValueTypes['UpdateFormSequenceConfigurationInput']
      },
      ValueTypes['FormSequenceConfiguration']
    ]
    updateInstagramStoryScreen?: [
      {
        condition?: ValueTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null
        input: ValueTypes['UpdateInstagramStoryScreenInput']
      },
      ValueTypes['InstagramStoryScreen']
    ]
    updateMessage?: [
      {
        condition?: ValueTypes['ModelMessageConditionInput'] | undefined | null
        input: ValueTypes['UpdateMessageInput']
      },
      ValueTypes['Message']
    ]
    updateNote?: [
      { condition?: ValueTypes['ModelNoteConditionInput'] | undefined | null; input: ValueTypes['UpdateNoteInput'] },
      ValueTypes['Note']
    ]
    updateNotificationConfiguration?: [
      {
        condition?: ValueTypes['ModelNotificationConfigurationConditionInput'] | undefined | null
        input: ValueTypes['UpdateNotificationConfigurationInput']
      },
      ValueTypes['NotificationConfiguration']
    ]
    updateProduct?: [
      {
        condition?: ValueTypes['ModelProductConditionInput'] | undefined | null
        input: ValueTypes['UpdateProductInput']
      },
      ValueTypes['Product']
    ]
    updateProductBonusConfigSnapshot?: [
      {
        condition?: ValueTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null
        input: ValueTypes['UpdateProductBonusConfigSnapshotInput']
      },
      ValueTypes['ProductBonusConfigSnapshot']
    ]
    updateProductKeywords?: [
      {
        condition?: ValueTypes['ModelProductKeywordsConditionInput'] | undefined | null
        input: ValueTypes['UpdateProductKeywordsInput']
      },
      ValueTypes['ProductKeywords']
    ]
    updateProductRatingSnapshot?: [
      {
        condition?: ValueTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null
        input: ValueTypes['UpdateProductRatingSnapshotInput']
      },
      ValueTypes['ProductRatingSnapshot']
    ]
    updatePurchase?: [
      {
        condition?: ValueTypes['ModelPurchaseConditionInput'] | undefined | null
        input: ValueTypes['UpdatePurchaseInput']
      },
      ValueTypes['Purchase']
    ]
    updateReferral?: [
      {
        condition?: ValueTypes['ModelReferralConditionInput'] | undefined | null
        input: ValueTypes['UpdateReferralInput']
      },
      ValueTypes['Referral']
    ]
    updateReview?: [
      {
        condition?: ValueTypes['ModelReviewConditionInput'] | undefined | null
        input: ValueTypes['UpdateReviewInput']
      },
      ValueTypes['Review']
    ]
    updateSku?: [
      { condition?: ValueTypes['ModelSkuConditionInput'] | undefined | null; input: ValueTypes['UpdateSkuInput'] },
      ValueTypes['Sku']
    ]
    updateSkuSnapshot?: [
      {
        condition?: ValueTypes['ModelSkuSnapshotConditionInput'] | undefined | null
        input: ValueTypes['UpdateSkuSnapshotInput']
      },
      ValueTypes['SkuSnapshot']
    ]
    updateUser?: [
      { condition?: ValueTypes['ModelUserConditionInput'] | undefined | null; input: ValueTypes['UpdateUserInput'] },
      ValueTypes['User']
    ]
    updateUserBonusPayout?: [
      {
        condition?: ValueTypes['ModelUserBonusPayoutConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserBonusPayoutInput']
      },
      ValueTypes['UserBonusPayout']
    ]
    updateUserCampaign?: [
      {
        condition?: ValueTypes['ModelUserCampaignConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserCampaignInput']
      },
      ValueTypes['UserCampaign']
    ]
    updateUserCampaignChangelog?: [
      {
        condition?: ValueTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserCampaignChangelogInput']
      },
      ValueTypes['UserCampaignChangelog']
    ]
    updateUserCampaignSnapshot?: [
      {
        condition?: ValueTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserCampaignSnapshotInput']
      },
      ValueTypes['UserCampaignSnapshot']
    ]
    updateUserCampaignSocialContent?: [
      {
        condition?: ValueTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserCampaignSocialContentInput']
      },
      ValueTypes['UserCampaignSocialContent']
    ]
    updateUserCampaignSocialContentDraftHistory?: [
      {
        condition?: ValueTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserCampaignSocialContentDraftHistoryInput']
      },
      ValueTypes['UserCampaignSocialContentDraftHistory']
    ]
    updateUserCampaignSocialContentReview?: [
      {
        condition?: ValueTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserCampaignSocialContentReviewInput']
      },
      ValueTypes['UserCampaignSocialContentReview']
    ]
    updateUserDeviceSnapshot?: [
      {
        condition?: ValueTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserDeviceSnapshotInput']
      },
      ValueTypes['UserDeviceSnapshot']
    ]
    updateUserFollower?: [
      {
        condition?: ValueTypes['ModelUserFollowerConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserFollowerInput']
      },
      ValueTypes['UserFollower']
    ]
    updateUserLink?: [
      {
        condition?: ValueTypes['ModelUserLinkConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserLinkInput']
      },
      ValueTypes['UserLink']
    ]
    updateUserNotificationPreference?: [
      {
        condition?: ValueTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserNotificationPreferenceInput']
      },
      ValueTypes['UserNotificationPreference']
    ]
    updateUserPayoutRequest?: [
      {
        condition?: ValueTypes['ModelUserPayoutRequestConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserPayoutRequestInput']
      },
      ValueTypes['UserPayoutRequest']
    ]
    updateUserPhone?: [{ phoneNumber: string; userId: string }, ValueTypes['UpdateUserPhoneResult']]
    updateUserStagingApproval?: [
      {
        condition?: ValueTypes['ModelUserStagingApprovalConditionInput'] | undefined | null
        input: ValueTypes['UpdateUserStagingApprovalInput']
      },
      ValueTypes['UserStagingApproval']
    ]
    updateVideoContentScan?: [
      {
        condition?: ValueTypes['ModelVideoContentScanConditionInput'] | undefined | null
        input: ValueTypes['UpdateVideoContentScanInput']
      },
      ValueTypes['VideoContentScan']
    ]
    verifySocialVerificationCode?: [
      { handle?: string | undefined | null; id: string },
      ValueTypes['VerifySocialCodeResult']
    ]
    __typename?: boolean | `@${string}`
  }>
  ['Note']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    note?: boolean | `@${string}`
    parentId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['NotificationConfiguration']: AliasType<{
    category?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    description?: boolean | `@${string}`
    email?: ValueTypes['ChannelConfiguration']
    enabled?: boolean | `@${string}`
    id?: boolean | `@${string}`
    inAppChatChannel?: ValueTypes['MessagingChannelConfiguration']
    name?: boolean | `@${string}`
    occurrenceConstraint?: ValueTypes['OccurrenceConstraint']
    push?: ValueTypes['PushChannelConfiguration']
    sms?: ValueTypes['ChannelConfiguration']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['OccurrenceConstraint']: AliasType<{
    period?: boolean | `@${string}`
    type?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['OrderConfirmationScreenshotVerificationOutput']: AliasType<{
    orderDate?: boolean | `@${string}`
    orderId?: boolean | `@${string}`
    orderTotal?: boolean | `@${string}`
    rejectionMessage?: boolean | `@${string}`
    result?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PayForImpressionsBonusConfig']: AliasType<{
    enabled?: boolean | `@${string}`
    growthRate?: boolean | `@${string}`
    impressionsBaseline?: boolean | `@${string}`
    impressionsCap?: boolean | `@${string}`
    impressionsIncrement?: boolean | `@${string}`
    initialBonus?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PayForPostingContentEarlyConfig']: AliasType<{
    bonusCents?: boolean | `@${string}`
    daysBeforeDeadline?: boolean | `@${string}`
    enabled?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Product']: AliasType<{
    accountRequired?: boolean | `@${string}`
    active?: boolean | `@${string}`
    approvalFilters?: ValueTypes['ApprovalFilterValue']
    autoApprovalSeatsAvailable?: boolean | `@${string}`
    autoApprovalStatusTiers?: boolean | `@${string}`
    autoApprovalV2Config?: ValueTypes['AutoApprovalV2Config']
    autoBuyConfiguration?: ValueTypes['AutoBuyFlowConfiguration']
    autoBuyReleaseSchedule?: [
      {
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelAutoBuyFlowReleaseScheduleConnection']
    ]
    automaticReviewRelease?: boolean | `@${string}`
    baseBonus?: boolean | `@${string}`
    bonusHistory?: [
      {
        filter?: ValueTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        snapshotAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductBonusConfigSnapshotConnection']
    ]
    brandAdvocateCount?: boolean | `@${string}`
    brandTalkingPoints?: boolean | `@${string}`
    buyOnlinePickupInStore?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    campaignStateMachine?: boolean | `@${string}`
    closeoutFormBonus?: boolean | `@${string}`
    contentApprovalConfiguration?: ValueTypes['ContentApprovalConfiguration']
    contentApprovalLeadDays?: boolean | `@${string}`
    contentRequirement?: ValueTypes['ProductSocialContent']
    createdAt?: boolean | `@${string}`
    creatorCount?: boolean | `@${string}`
    creatorPreference?: boolean | `@${string}`
    creatorPreferenceDetails?: boolean | `@${string}`
    customFtcHashtags?: boolean | `@${string}`
    customHashtags?: boolean | `@${string}`
    directPurchaseLink?: boolean | `@${string}`
    engagementRateInstagramRangeRequirement?: ValueTypes['MinMaxRange']
    engagementRateTiktokRangeRequirement?: ValueTypes['MinMaxRange']
    followersInstagramRangeRequirement?: ValueTypes['MinMaxRange']
    followersTiktokRangeRequirement?: ValueTypes['MinMaxRange']
    hideBrandContentApprovals?: boolean | `@${string}`
    id?: boolean | `@${string}`
    impressionsInstagramRangeRequirement?: ValueTypes['MinMaxRange']
    impressionsTiktokRangeRequirement?: ValueTypes['MinMaxRange']
    instaHandle?: boolean | `@${string}`
    internalCreatorsStagingGuidance?: boolean | `@${string}`
    internalDescription?: boolean | `@${string}`
    internalName?: boolean | `@${string}`
    internalNotes?: boolean | `@${string}`
    isDirectListingPurchase?: boolean | `@${string}`
    isQualityRequirementsEnabled?: boolean | `@${string}`
    listingGuidance?: boolean | `@${string}`
    maxDiscoveryBonus?: boolean | `@${string}`
    minimumCreatorQualityScore?: boolean | `@${string}`
    nonRetailReviewLink?: boolean | `@${string}`
    nonRetailReviewWebsiteName?: boolean | `@${string}`
    payForClicksEnabled?: boolean | `@${string}`
    payForClicksMinClicks?: boolean | `@${string}`
    payForImpressionsBonus?: ValueTypes['PayForImpressionsBonusConfig']
    payForPerformanceBonus?: ValueTypes['StatusTierBonusConfig']
    payForPostingContentEarly?: ValueTypes['PayForPostingContentEarlyConfig']
    postingDeadlineDaysRelativeToApproval?: boolean | `@${string}`
    productLink?: ValueTypes['UserLink']
    productLinkId?: boolean | `@${string}`
    productPostingGuidance?: boolean | `@${string}`
    productPostingGuidanceDocument?: boolean | `@${string}`
    promoCode?: boolean | `@${string}`
    promoCodeGuidance?: boolean | `@${string}`
    promoCodeType?: boolean | `@${string}`
    promoDiscountAmount?: boolean | `@${string}`
    promoDiscountType?: boolean | `@${string}`
    promoEndDate?: boolean | `@${string}`
    promoLink?: boolean | `@${string}`
    promoStartDate?: boolean | `@${string}`
    promotionLink?: ValueTypes['UserLink']
    promotionLinkId?: boolean | `@${string}`
    purchaseByPromoCode?: boolean | `@${string}`
    purchaseDiscountCode?: boolean | `@${string}`
    purchaseDiscountCodeDetails?: boolean | `@${string}`
    purchaseDiscountCodePercentage?: boolean | `@${string}`
    qualityLevel?: boolean | `@${string}`
    requestEarlyProductFeedback?: boolean | `@${string}`
    requestProductFeedback?: boolean | `@${string}`
    requiredProductLinkEnabled?: boolean | `@${string}`
    sequence?: ValueTypes['FormSequenceConfiguration']
    sequenceId?: boolean | `@${string}`
    shopifyPriceRule?: boolean | `@${string}`
    shopifyPromotionId?: boolean | `@${string}`
    shopifyPromotionType?: boolean | `@${string}`
    sku?: ValueTypes['Sku']
    skuId?: boolean | `@${string}`
    socialMediaExampleUrls?: boolean | `@${string}`
    stagingApprovalConfiguration?: ValueTypes['StagingApprovalConfiguration']
    stagingApprovalRequiredNumCreatorsClientReviewed?: boolean | `@${string}`
    stagingRequirementType?: boolean | `@${string}`
    stagingSocialQualityRequirement?: boolean | `@${string}`
    tikTokHandle?: boolean | `@${string}`
    unitsPurchased?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    validateProductFoundVariations?: boolean | `@${string}`
    visibleAt?: boolean | `@${string}`
    visibleEndsAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductBonusConfigSnapshot']: AliasType<{
    closeoutSurveyBonus?: boolean | `@${string}`
    contentBonuses?: ValueTypes['ProductSocialContent']
    createdAt?: boolean | `@${string}`
    discoveryBonus?: boolean | `@${string}`
    payForImpressionsBonus?: ValueTypes['PayForImpressionsBonusConfig']
    productId?: boolean | `@${string}`
    snapshotAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductKeywords']: AliasType<{
    adminPriority?: boolean | `@${string}`
    brandPriority?: boolean | `@${string}`
    brandResponse?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    creationSource?: boolean | `@${string}`
    endDate?: boolean | `@${string}`
    heliumAccount?: boolean | `@${string}`
    heliumAsin?: boolean | `@${string}`
    heliumLink?: boolean | `@${string}`
    id?: boolean | `@${string}`
    keyword?: boolean | `@${string}`
    monthlySearchVolume?: boolean | `@${string}`
    rank?: boolean | `@${string}`
    rankingHistory?: ValueTypes['RankPoint']
    rankingLastSkippedAt?: boolean | `@${string}`
    rankingLastSkippedByUserId?: boolean | `@${string}`
    rankingLastUpdatedAt?: boolean | `@${string}`
    rankingLastUpdatedByUserId?: boolean | `@${string}`
    recommended?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    startDate?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    usedForCampaign?: boolean | `@${string}`
    visible?: boolean | `@${string}`
    weight?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductRatingSnapshot']: AliasType<{
    createdAt?: boolean | `@${string}`
    numFiveStar?: boolean | `@${string}`
    numFourStar?: boolean | `@${string}`
    numOneStar?: boolean | `@${string}`
    numRatings?: boolean | `@${string}`
    numReviews?: boolean | `@${string}`
    numThreeStar?: boolean | `@${string}`
    numTwoStar?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    snapshotAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductSocialContent']: AliasType<{
    bonus?: boolean | `@${string}`
    brandContentGuidance?: ValueTypes['ContentGuidance']
    brandContentRequirements?: boolean | `@${string}`
    bronzeBonus?: boolean | `@${string}`
    channel?: boolean | `@${string}`
    diamondBonus?: boolean | `@${string}`
    goldBonus?: boolean | `@${string}`
    inspirationalContent?: ValueTypes['InspirationalContent']
    instances?: boolean | `@${string}`
    platinumBonus?: boolean | `@${string}`
    requirementLevel?: boolean | `@${string}`
    requiresManualApproval?: boolean | `@${string}`
    silverBonus?: boolean | `@${string}`
    talkingPoints?: ValueTypes['ContentGuidance']
    totalExpectedCount?: boolean | `@${string}`
    type?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Purchase']: AliasType<{
    amount?: boolean | `@${string}`
    cardId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    currency?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isFraud?: boolean | `@${string}`
    keywordAtPurchase?: boolean | `@${string}`
    purchasedAt?: boolean | `@${string}`
    shippedAt?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PushAddress']: AliasType<{
    address?: boolean | `@${string}`
    pushChannel?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PushChannelConfiguration']: AliasType<{
    attributes?: ValueTypes['KeyAttribute']
    enabled?: boolean | `@${string}`
    templateId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Query']: AliasType<{
    ByAgencyId?: [
      {
        agencyId?: string | undefined | null
        filter?: ValueTypes['ModelCompanyFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCompanyConnection']
    ]
    ByBrandId?: [
      {
        brandId?: string | undefined | null
        filter?: ValueTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCampaignConnection']
    ]
    BySkuIdByStoreName?: [
      {
        filter?: ValueTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        storeName?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelSkuConnection']
    ]
    ByVisibilityToUser?: [
      {
        filter?: ValueTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        visibleToUser?: string | undefined | null
      },
      ValueTypes['ModelCampaignConnection']
    ]
    ByVisibilityToUserByCreatedAt?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        visibleToUser?: string | undefined | null
      },
      ValueTypes['ModelCampaignConnection']
    ]
    CampaignsBySkuId?: [
      {
        filter?: ValueTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCampaignConnection']
    ]
    asinSnapshotsBySkuId?: [
      {
        filter?: ValueTypes['ModelSkuSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        marketplaceSkuSnapshotDate?: ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyConditionInput'] | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelSkuSnapshotConnection']
    ]
    autoBuyFlowConfigurationByStatusByStartDate?: [
      {
        filter?: ValueTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        startDate?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        status?: ValueTypes['AutoBuyStatus'] | undefined | null
      },
      ValueTypes['ModelAutoBuyFlowConfigurationConnection']
    ]
    autoBuyReleasesByProductIdDate?: [
      {
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelAutoBuyReleaseConnection']
    ]
    brandApiByShopId?: [
      {
        filter?: ValueTypes['ModelBrandApiFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        shopId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelBrandApiConnection']
    ]
    brandStoreScrapeUrl?: [
      {
        brandStoreScrapeUrl?: string | undefined | null
        filter?: ValueTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelBrandConnection']
    ]
    brandsByActive?: [
      {
        active?: string | undefined | null
        filter?: ValueTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelBrandConnection']
    ]
    brandsByActiveBySubscriptionEndsAt?: [
      {
        active?: string | undefined | null
        filter?: ValueTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        subscriptionEndsAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelBrandConnection']
    ]
    brandsByCompanyId?: [
      {
        companyId?: string | undefined | null
        filter?: ValueTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelBrandConnection']
    ]
    brandsByPlatform?: [
      {
        filter?: ValueTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        platform?: ValueTypes['PlatformSource'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelBrandConnection']
    ]
    byCampaignByCoarseCampaignStatus?: [
      {
        campaignId?: string | undefined | null
        coarseCampaignStatus?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    bySkuBySnapshotAt?: [
      {
        filter?: ValueTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sku?: string | undefined | null
        snapshotAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductRatingSnapshotConnection']
    ]
    byUserByCoarseCampaignStatus?: [
      {
        coarseCampaignStatus?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    campaignInviteByCampaignByUser?: [
      {
        campaignId?: string | undefined | null
        filter?: ValueTypes['ModelCampaignInviteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelCampaignInviteConnection']
    ]
    campaignInviteByNotificationStatusByPriority?: [
      {
        filter?: ValueTypes['ModelCampaignInviteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        notificationStatus?: ValueTypes['NotificationStatus'] | undefined | null
        priority?: ValueTypes['ModelIntKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCampaignInviteConnection']
    ]
    campaignInviteByUserByCreatedAt?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignInviteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelCampaignInviteConnection']
    ]
    campaignSourcingProspectsByIgHandle?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        instagramHandle?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCampaignSourcingProspectConnection']
    ]
    campaignSourcingProspectsByTiktokHandle?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        tiktokHandle?: string | undefined | null
      },
      ValueTypes['ModelCampaignSourcingProspectConnection']
    ]
    campaignSourcingProspectsByUserIdentifier?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userIdentifier?: string | undefined | null
      },
      ValueTypes['ModelCampaignSourcingProspectConnection']
    ]
    campaignSourcingProspectsByYoutubeHandle?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        youtubeChannelHandle?: string | undefined | null
      },
      ValueTypes['ModelCampaignSourcingProspectConnection']
    ]
    campaignsByActive?: [
      {
        active?: string | undefined | null
        filter?: ValueTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCampaignConnection']
    ]
    campaignsByActiveByAnticipatedStartDate?: [
      {
        active?: string | undefined | null
        anticipatedStartDate?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCampaignConnection']
    ]
    campaignsByPayForPerformanceActive?: [
      {
        active?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payForPerformanceEnabled?: ValueTypes['PayForPerformanceFlag'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCampaignConnection']
    ]
    chatConversationsV2ByActiveByAdminUnreadCount?: [
      {
        active?: ValueTypes['Active'] | undefined | null
        adminUnreadCount?: ValueTypes['ModelIntKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelChatConversationV2Connection']
    ]
    chatConversationsV2ByChatTypeByActive?: [
      {
        active?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        chatType?: ValueTypes['ChatType'] | undefined | null
        filter?: ValueTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelChatConversationV2Connection']
    ]
    chatConversationsV2ByChatTypeByAdminUnreadCount?: [
      {
        adminUnreadCount?: ValueTypes['ModelIntKeyConditionInput'] | undefined | null
        chatType?: ValueTypes['ChatType'] | undefined | null
        filter?: ValueTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelChatConversationV2Connection']
    ]
    chatConversationsV2ByUserIdByActive?: [
      {
        active?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelChatConversationV2Connection']
    ]
    communicationNotificationsByTraceId?: [
      {
        filter?: ValueTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        traceId?: string | undefined | null
      },
      ValueTypes['ModelCommunicationNotificationConnection']
    ]
    communicationNotificationsByUserCampaignId?: [
      {
        filter?: ValueTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ValueTypes['ModelCommunicationNotificationConnection']
    ]
    communicationNotificationsByUserId?: [
      {
        filter?: ValueTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelCommunicationNotificationConnection']
    ]
    communicationNotificationsByUserIdNotificationId?: [
      {
        filter?: ValueTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        notificationId?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelCommunicationNotificationConnection']
    ]
    connectedAccountsByAccountIdByAccountType?: [
      {
        accountId?: string | undefined | null
        accountType?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelConnectedAccountFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelConnectedAccountConnection']
    ]
    conversationByContactOwnerIdByStatus?: [
      {
        contactOwnerId?: string | undefined | null
        filter?: ValueTypes['ModelConversationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        status?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelConversationConnection']
    ]
    conversationByStatusByStatusChange?: [
      {
        filter?: ValueTypes['ModelConversationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        status?: ValueTypes['ConversationStatus'] | undefined | null
        statusChangedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelConversationConnection']
    ]
    conversationMessageByMessageId?: [
      {
        filter?: ValueTypes['ModelConversationMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        messageId?: string | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelConversationMessageConnection']
    ]
    conversationMessageByStatusBySendAt?: [
      {
        filter?: ValueTypes['ModelConversationMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sendAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        status?: ValueTypes['ConversationMessageStatus'] | undefined | null
      },
      ValueTypes['ModelConversationMessageConnection']
    ]
    conversationMessageByStatusBySendAttempts?: [
      {
        filter?: ValueTypes['ModelConversationMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sendAttempts?: ValueTypes['ModelIntKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        status?: ValueTypes['ConversationMessageStatus'] | undefined | null
      },
      ValueTypes['ModelConversationMessageConnection']
    ]
    draftHistoryBySocialContentId?: [
      {
        filter?: ValueTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        snapshotDate?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignSocialContentId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentDraftHistoryConnection']
    ]
    getAutoBuyDistribution?: [{ id: string }, ValueTypes['AutoBuyDistribution']]
    getAutoBuyFlowConfiguration?: [{ flowId: string }, ValueTypes['AutoBuyFlowConfiguration']]
    getAutoBuyFlowReleaseSchedule?: [{ date: string; flowId: string }, ValueTypes['AutoBuyFlowReleaseSchedule']]
    getAutoBuyRelease?: [{ date: string; userCampaignId: string }, ValueTypes['AutoBuyRelease']]
    getBrand?: [{ id: string }, ValueTypes['Brand']]
    getBrandApi?: [{ api: ValueTypes['BrandApiType']; brandId: string }, ValueTypes['BrandApi']]
    getCampaign?: [{ id: string }, ValueTypes['Campaign']]
    getCampaignClicks?: [{ campaignId: string; date: string }, ValueTypes['CampaignClicks']]
    getCampaignDetailsForUser?: [
      { campaignId: string; productId?: string | undefined | null },
      ValueTypes['CampaignForUser']
    ]
    getCampaignInvite?: [{ id: string }, ValueTypes['CampaignInvite']]
    getCampaignSourcingProspect?: [
      { campaignId: string; userIdentifier: string },
      ValueTypes['CampaignSourcingProspect']
    ]
    getChatConversationV2?: [{ chatType: ValueTypes['ChatType']; resourceId: string }, ValueTypes['ChatConversationV2']]
    getCommunicationNotification?: [{ id: string }, ValueTypes['CommunicationNotification']]
    getCompany?: [{ id: string }, ValueTypes['Company']]
    getConnectedAccount?: [{ accountId: string; userId: string }, ValueTypes['ConnectedAccount']]
    getConversation?: [{ userId: string }, ValueTypes['Conversation']]
    getConversationMessage?: [{ id: string; userId: string }, ValueTypes['ConversationMessage']]
    getConversationsAccessToken?: [
      { isSupport?: boolean | undefined | null },
      ValueTypes['GetConversationsAccessTokenResponse']
    ]
    getEtailerSkuMetrics?: [{ date: string; skuId: string }, ValueTypes['EtailerSkuMetrics']]
    getFeatureFlag?: [
      { environment: ValueTypes['FeatureFlagEnvironment']; featureFlag: string; userId?: string | undefined | null },
      ValueTypes['GetFeatureFlagResponse']
    ]
    getFormDefinition?: [
      {
        campaignId: string
        formType?: ValueTypes['FormType'] | undefined | null
        productId?: string | undefined | null
      },
      ValueTypes['FormDefinition']
    ]
    getFormSequenceConfiguration?: [{ id: string }, ValueTypes['FormSequenceConfiguration']]
    getInstagramStoryScreen?: [{ id: string }, ValueTypes['InstagramStoryScreen']]
    getMessage?: [{ id: string }, ValueTypes['Message']]
    getNote?: [{ id: string }, ValueTypes['Note']]
    getNotificationConfiguration?: [{ id: string }, ValueTypes['NotificationConfiguration']]
    getProduct?: [{ id: string }, ValueTypes['Product']]
    getProductBonusConfigSnapshot?: [
      { productId: string; snapshotAt: string },
      ValueTypes['ProductBonusConfigSnapshot']
    ]
    getProductKeywords?: [{ id: string }, ValueTypes['ProductKeywords']]
    getProductRatingSnapshot?: [{ skuId: string; snapshotAt: string }, ValueTypes['ProductRatingSnapshot']]
    getPurchase?: [{ id: string }, ValueTypes['Purchase']]
    getReferral?: [{ id: string }, ValueTypes['Referral']]
    getReview?: [{ id: string }, ValueTypes['Review']]
    getSku?: [{ id: string }, ValueTypes['Sku']]
    getSkuInformation?: [{ countryCode: string; skuId: string }, ValueTypes['SkuInformation']]
    getSkuSnapshot?: [{ marketplaceSku: string; snapshotDate: string }, ValueTypes['SkuSnapshot']]
    getSocialMediaPosts?: [
      { dateSince: string; dateUntil: string; socialChannel: ValueTypes['SocialChannel']; userId: string },
      ValueTypes['SocialMediaPost']
    ]
    getUser?: [{ id: string }, ValueTypes['User']]
    getUserBonusPayout?: [{ id: string }, ValueTypes['UserBonusPayout']]
    getUserCampaign?: [{ id: string }, ValueTypes['UserCampaign']]
    getUserCampaignChangelog?: [{ createdAt: string; userCampaignId: string }, ValueTypes['UserCampaignChangelog']]
    getUserCampaignSnapshot?: [
      { userCampaignId: string; userCampaignVersion: number },
      ValueTypes['UserCampaignSnapshot']
    ]
    getUserCampaignSocialContent?: [{ id: string }, ValueTypes['UserCampaignSocialContent']]
    getUserCampaignSocialContentDraftHistory?: [{ id: string }, ValueTypes['UserCampaignSocialContentDraftHistory']]
    getUserCampaignSocialContentReview?: [
      { reviewType: ValueTypes['UserCampaignSocialContentReviewType']; userCampaignSocialContentId: string },
      ValueTypes['UserCampaignSocialContentReview']
    ]
    getUserDeviceSnapshot?: [{ id: string }, ValueTypes['UserDeviceSnapshot']]
    getUserFollower?: [{ followerUserId: string; userId: string }, ValueTypes['UserFollower']]
    getUserLink?: [{ id: string }, ValueTypes['UserLink']]
    getUserNotificationPreference?: [{ category: string; userId: string }, ValueTypes['UserNotificationPreference']]
    getUserPayoutRequest?: [{ id: string }, ValueTypes['UserPayoutRequest']]
    getUserStagingApproval?: [{ id: string }, ValueTypes['UserStagingApproval']]
    getVideoContentScan?: [{ id: string }, ValueTypes['VideoContentScan']]
    instagramStoryByExternalId?: [
      {
        externalId?: string | undefined | null
        filter?: ValueTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelInstagramStoryScreenConnection']
    ]
    instagramStoryByInstagramUserId?: [
      {
        filter?: ValueTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
        instagramUserId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        postingDate?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelInstagramStoryScreenConnection']
    ]
    instagramStoryByMediaId?: [
      {
        filter?: ValueTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
        limit?: number | undefined | null
        mediaId?: string | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelInstagramStoryScreenConnection']
    ]
    listAutoBuyDistributions?: [
      {
        filter?: ValueTypes['ModelAutoBuyDistributionFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelAutoBuyDistributionConnection']
    ]
    listAutoBuyFlowConfigurations?: [
      {
        filter?: ValueTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null
        flowId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelAutoBuyFlowConfigurationConnection']
    ]
    listAutoBuyFlowReleaseSchedules?: [
      {
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null
        flowId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelAutoBuyFlowReleaseScheduleConnection']
    ]
    listAutoBuyReleases?: [
      {
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ValueTypes['ModelAutoBuyReleaseConnection']
    ]
    listBrandApis?: [
      {
        api?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        brandId?: string | undefined | null
        filter?: ValueTypes['ModelBrandApiFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelBrandApiConnection']
    ]
    listBrands?: [
      {
        filter?: ValueTypes['ModelBrandFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelBrandConnection']
    ]
    listCampaignClickss?: [
      {
        campaignId?: string | undefined | null
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelCampaignClicksFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCampaignClicksConnection']
    ]
    listCampaignInvites?: [
      {
        filter?: ValueTypes['ModelCampaignInviteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelCampaignInviteConnection']
    ]
    listCampaignSourcingProspects?: [
      {
        campaignId?: string | undefined | null
        filter?: ValueTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userIdentifier?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelCampaignSourcingProspectConnection']
    ]
    listCampaigns?: [
      {
        filter?: ValueTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelCampaignConnection']
    ]
    listCampaignsForUsersToApplyTo?: [
      { userId?: string | undefined | null },
      ValueTypes['ListCampaignsForUserResponse']
    ]
    listChatConversationV2s?: [
      {
        chatType?: ValueTypes['ChatType'] | undefined | null
        filter?: ValueTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        resourceId?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelChatConversationV2Connection']
    ]
    listCommunicationNotifications?: [
      {
        filter?: ValueTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCommunicationNotificationConnection']
    ]
    listCompanys?: [
      {
        filter?: ValueTypes['ModelCompanyFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCompanyConnection']
    ]
    listConnectedAccounts?: [
      {
        accountId?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelConnectedAccountFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelConnectedAccountConnection']
    ]
    listConversationMessages?: [
      {
        filter?: ValueTypes['ModelConversationMessageFilterInput'] | undefined | null
        id?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelConversationMessageConnection']
    ]
    listConversations?: [
      {
        filter?: ValueTypes['ModelConversationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelConversationConnection']
    ]
    listEtailerSkuMetricss?: [
      {
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelEtailerSkuMetricsFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelEtailerSkuMetricsConnection']
    ]
    listFormSequenceConfigurations?: [
      {
        filter?: ValueTypes['ModelFormSequenceConfigurationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelFormSequenceConfigurationConnection']
    ]
    listInstagramStoryScreens?: [
      {
        filter?: ValueTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelInstagramStoryScreenConnection']
    ]
    listMessages?: [
      {
        filter?: ValueTypes['ModelMessageFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelMessageConnection']
    ]
    listNotes?: [
      {
        filter?: ValueTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelNoteConnection']
    ]
    listNotificationConfigurations?: [
      {
        filter?: ValueTypes['ModelNotificationConfigurationFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelNotificationConfigurationConnection']
    ]
    listProductBonusConfigSnapshots?: [
      {
        filter?: ValueTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        snapshotAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductBonusConfigSnapshotConnection']
    ]
    listProductKeywordss?: [
      {
        filter?: ValueTypes['ModelProductKeywordsFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductKeywordsConnection']
    ]
    listProductRatingSnapshots?: [
      {
        filter?: ValueTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        snapshotAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductRatingSnapshotConnection']
    ]
    listProducts?: [
      {
        filter?: ValueTypes['ModelProductFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductConnection']
    ]
    listPurchases?: [
      {
        filter?: ValueTypes['ModelPurchaseFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelPurchaseConnection']
    ]
    listReferrals?: [
      {
        filter?: ValueTypes['ModelReferralFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelReferralConnection']
    ]
    listReviews?: [
      {
        filter?: ValueTypes['ModelReviewFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelReviewConnection']
    ]
    listSkuSnapshots?: [
      {
        filter?: ValueTypes['ModelSkuSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        marketplaceSku?: string | undefined | null
        nextToken?: string | undefined | null
        snapshotDate?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelSkuSnapshotConnection']
    ]
    listSkus?: [
      {
        filter?: ValueTypes['ModelSkuFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelSkuConnection']
    ]
    listUserBonusPayouts?: [
      {
        filter?: ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserBonusPayoutConnection']
    ]
    listUserCampaignChangelogs?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignChangelogConnection']
    ]
    listUserCampaignSnapshots?: [
      {
        filter?: ValueTypes['ModelUserCampaignSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
        userCampaignVersion?: ValueTypes['ModelIntKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSnapshotConnection']
    ]
    listUserCampaignSocialContentDraftHistorys?: [
      {
        filter?: ValueTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentDraftHistoryConnection']
    ]
    listUserCampaignSocialContentReviews?: [
      {
        filter?: ValueTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewType?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignSocialContentId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentReviewConnection']
    ]
    listUserCampaignSocialContents?: [
      {
        filter?: ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentConnection']
    ]
    listUserCampaigns?: [
      {
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    listUserDeviceSnapshots?: [
      {
        filter?: ValueTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserDeviceSnapshotConnection']
    ]
    listUserFollowers?: [
      {
        filter?: ValueTypes['ModelUserFollowerFilterInput'] | undefined | null
        followerUserId?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserFollowerConnection']
    ]
    listUserLinks?: [
      {
        filter?: ValueTypes['ModelUserLinkFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelUserLinkConnection']
    ]
    listUserNotificationPreferences?: [
      {
        category?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserNotificationPreferenceConnection']
    ]
    listUserPayoutRequests?: [
      {
        filter?: ValueTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserPayoutRequestConnection']
    ]
    listUserStagingApprovals?: [
      {
        filter?: ValueTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ValueTypes['ModelUserStagingApprovalConnection']
    ]
    listUsers?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    listVideoContentScans?: [
      {
        filter?: ValueTypes['ModelVideoContentScanFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelVideoContentScanConnection']
    ]
    messagesByUserCampaign?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ValueTypes['ModelMessageConnection']
    ]
    notesByParentId?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        parentId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelNoteConnection']
    ]
    osSearchUsers?: [{ search: ValueTypes['UserSearchInput'] }, ValueTypes['UserSearchOutput']]
    productKeywordsByCampaignIdByKeyword?: [
      {
        campaignId?: string | undefined | null
        filter?: ValueTypes['ModelProductKeywordsFilterInput'] | undefined | null
        keyword?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductKeywordsConnection']
    ]
    productsByActive?: [
      {
        active?: string | undefined | null
        filter?: ValueTypes['ModelProductFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductConnection']
    ]
    productsByCampaignId?: [
      {
        campaignId?: string | undefined | null
        filter?: ValueTypes['ModelProductFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductConnection']
    ]
    productsBySkuId?: [
      {
        filter?: ValueTypes['ModelProductFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelProductConnection']
    ]
    purchasesCardRetrieve?: [{ userCampaignId: string }, ValueTypes['CardDetails']]
    referralsByReferrerUserIdReferredUserId?: [
      {
        filter?: ValueTypes['ModelReferralFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        referredUserId?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
        referrerUserId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelReferralConnection']
    ]
    requestSocialContentScript?: [
      {
        contentRequirementLevel: ValueTypes['ContentRequirementLevel']
        socialContentType: ValueTypes['SocialContentType']
        userCampaignId: string
      },
      ValueTypes['RequestSocialContentScriptResponse']
    ]
    reviewsByAttributedUserId?: [
      {
        attributedUserId?: string | undefined | null
        filter?: ValueTypes['ModelReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelReviewConnection']
    ]
    reviewsByProductId?: [
      {
        filter?: ValueTypes['ModelReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        reviewedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelReviewConnection']
    ]
    reviewsByReviewerId?: [
      {
        filter?: ValueTypes['ModelReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        reviewerId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelReviewConnection']
    ]
    reviewsBySkuId?: [
      {
        filter?: ValueTypes['ModelReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelReviewConnection']
    ]
    skusByActive?: [
      {
        active?: string | undefined | null
        filter?: ValueTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelSkuConnection']
    ]
    skusByActiveByAvailabilityDate?: [
      {
        active?: string | undefined | null
        availabilityDate?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelSkuConnection']
    ]
    skusByActiveByReleaseDate?: [
      {
        active?: string | undefined | null
        filter?: ValueTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        releaseDate?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelSkuConnection']
    ]
    skusByBrand?: [
      {
        brandId?: string | undefined | null
        filter?: ValueTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelSkuConnection']
    ]
    skusByParentSkuId?: [
      {
        filter?: ValueTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        parentSkuId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelSkuConnection']
    ]
    socialContentByApprovalStatusByApprovedAt?: [
      {
        approvalStatus?: ValueTypes['ContentApprovalStatus'] | undefined | null
        approvedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByApprovalStatusByDraftSubmittedAt?: [
      {
        approvalStatus?: ValueTypes['ContentApprovalStatus'] | undefined | null
        draftSubmittedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByDraftStatusByEnteredBrandApprovalAt?: [
      {
        draftEnteredBrandApprovalAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        draftStatus?: ValueTypes['DraftStatus'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByReviewStatusByPostedAt?: [
      {
        complianceReviewStatus?: ValueTypes['ContentComplianceReviewStatus'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        postedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByReviewStatusByReviewedAt?: [
      {
        complianceReviewStatus?: ValueTypes['ContentComplianceReviewStatus'] | undefined | null
        complianceReviewedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByUserCampaignId?: [
      {
        filter?: ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentReviewBySocialContentIdByCreatedAt?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignSocialContentId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentReviewConnection']
    ]
    socialContentReviewByTypeByStatus?: [
      {
        filter?: ValueTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewStatus?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        reviewType?: ValueTypes['UserCampaignSocialContentReviewType'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentReviewConnection']
    ]
    userBonusesByPayoutBatchId?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutBatchId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByPayoutState?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutState?: ValueTypes['BonusPayoutState'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByUserCampaignId?: [
      {
        filter?: ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ValueTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByUserId?: [
      {
        filter?: ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByUserIdByCreatedAt?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByUserIdByPayoutState?: [
      {
        filter?: ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutState?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserBonusPayoutConnection']
    ]
    userCampaignByCampaignByAcceptanceStatus?: [
      {
        campaignAcceptanceStatus?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        campaignId?: string | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCampaignByCard?: [
      {
        campaignId?: string | undefined | null
        cardId?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCampaignByStatus?: [
      {
        campaignId?: string | undefined | null
        campaignStatus?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCampaignByUser?: [
      {
        campaignId?: string | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCardId?: [
      {
        cardId?: string | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCoarseCampaignStatus?: [
      {
        coarseCampaignStatus?: ValueTypes['CoarseCampaignStatus'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByKeywordHash?: [
      {
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        keywordsV2Hash?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByOrderId?: [
      {
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        orderId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByProductByCampaignAcceptanceStatus?: [
      {
        campaignAcceptanceStatus?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByProductByCampaignStatus?: [
      {
        campaignStatus?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByStatusByCreatedAt?: [
      {
        campaignStatus?: ValueTypes['CampaignStatus'] | undefined | null
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByUser?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignByUserByCard?: [
      {
        cardId?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userCampaignChangelogByUserCampaignIdByEntryType?: [
      {
        entryType?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignChangelogConnection']
    ]
    userCampaignsByUserByCampaignAcceptanceStatus?: [
      {
        campaignAcceptanceStatus?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    userDeviceSnapshotByIdfvBySnapshotAt?: [
      {
        filter?: ValueTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null
        idfv?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        snapshotAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserDeviceSnapshotConnection']
    ]
    userFollowerByFollowerUserId?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserFollowerFilterInput'] | undefined | null
        followerUserId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserFollowerConnection']
    ]
    userFollowerByUserIdByIsFavorite?: [
      {
        filter?: ValueTypes['ModelUserFollowerFilterInput'] | undefined | null
        isFavorite?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserFollowerConnection']
    ]
    userLinksByCampaignIdUniqueClicks?: [
      {
        campaignId?: string | undefined | null
        filter?: ValueTypes['ModelUserLinkFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        uniqueClicks?: ValueTypes['ModelIntKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelUserLinkConnection']
    ]
    userLinksByUserEncodedLink?: [
      {
        filter?: ValueTypes['ModelUserLinkFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userEncodedLink?: string | undefined | null
      },
      ValueTypes['ModelUserLinkConnection']
    ]
    userPayoutRequestsByPayoutBatchId?: [
      {
        filter?: ValueTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutBatchId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserPayoutRequestConnection']
    ]
    userPayoutRequestsByPayoutState?: [
      {
        filter?: ValueTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutState?: ValueTypes['BonusPayoutState'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserPayoutRequestConnection']
    ]
    userPayoutRequestsByUserId?: [
      {
        filter?: ValueTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelUserPayoutRequestConnection']
    ]
    userStagingApprovalsByOutcomeByType?: [
      {
        filter?: ValueTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userStagingApprovalOutcome?: ValueTypes['UserStagingApprovalOutcome'] | undefined | null
        userStagingApprovalType?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ValueTypes['ModelUserStagingApprovalConnection']
    ]
    userStagingApprovalsByUserCampaignId?: [
      {
        filter?: ValueTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ValueTypes['ModelUserStagingApprovalConnection']
    ]
    usersByActiveByCreatedAt?: [
      {
        active?: ValueTypes['Active'] | undefined | null
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByActiveByLastActiveAt?: [
      {
        active?: ValueTypes['Active'] | undefined | null
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        lastActiveAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByAmznProfileId?: [
      {
        amznProfileId?: string | undefined | null
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByAmznReviewerId?: [
      {
        amznReviewerId?: string | undefined | null
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByContentRatingSubmittedBy?: [
      {
        contentRatingCompletedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        contentRatingSubmittedBy?: string | undefined | null
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByContentReviewStatusByCompletedAt?: [
      {
        contentRatingCompletedAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        contentRatingReviewStatus?: ValueTypes['ContentRatingReviewStatus'] | undefined | null
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByContentReviewStatusByLastActiveAt?: [
      {
        contentRatingReviewStatus?: ValueTypes['ContentRatingReviewStatus'] | undefined | null
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        lastActiveAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByEmail?: [
      {
        email?: string | undefined | null
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByFacebookUserId?: [
      {
        facebookUserId?: string | undefined | null
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByInstagram?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        instagramHandle?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByInstagramTrailingPostEngagementAvg?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        instagramTrailingPostEngagementAvg?: ValueTypes['ModelFloatKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        type?: string | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByInstagramUserId?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        instagramUserId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByReferralCode?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        referralCode?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByReferrer?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        referrer?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersBySupportPriorityFlagByLastActiveAt?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        lastActiveAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        supportPriorityFlag?: ValueTypes['SupportPriorityFlag'] | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByTiktokAccountId?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        tiktokAccountId?: string | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByTiktokHandle?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        tiktokHandle?: string | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    usersByYoutubeAccountId?: [
      {
        filter?: ValueTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        youtubeChannelId?: string | undefined | null
      },
      ValueTypes['ModelUserConnection']
    ]
    verifyOrderConfirmationScreenshot?: [
      { screenshotKey: string; storeName?: string | undefined | null; userCampaignId?: string | undefined | null },
      ValueTypes['OrderConfirmationScreenshotVerificationOutput']
    ]
    verifyReviewScreenshot?: [{ screenshotKey: string }, boolean | `@${string}`]
    videoContentScanByProcessId?: [
      {
        filter?: ValueTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        processId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelVideoContentScanConnection']
    ]
    videoContentScanByScanStatus?: [
      {
        filter?: ValueTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        scanStatus?: ValueTypes['ContentScanStatus'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelVideoContentScanConnection']
    ]
    videoContentScanByUrl?: [
      {
        filter?: ValueTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        url?: string | undefined | null
      },
      ValueTypes['ModelVideoContentScanConnection']
    ]
    videoContentScanByUserId?: [
      {
        filter?: ValueTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelVideoContentScanConnection']
    ]
    __typename?: boolean | `@${string}`
  }>
  ['RankPoint']: AliasType<{
    rank?: boolean | `@${string}`
    rankAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Rate']: AliasType<{
    count?: boolean | `@${string}`
    isIntegrationFallbackRate?: boolean | `@${string}`
    period?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Referral']: AliasType<{
    bonusPayout?: ValueTypes['UserBonusPayout']
    bonusPayoutId?: boolean | `@${string}`
    campaign?: ValueTypes['Campaign']
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    referralType?: boolean | `@${string}`
    referredUserId?: boolean | `@${string}`
    referrerUserId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ReferralBonuses']: AliasType<{
    campaignReferralBonuses?: ValueTypes['CampaignReferralBonus']
    creatorReferralBonuses?: ValueTypes['CreatorReferralBonus']
    creatorReferralSignups?: ValueTypes['CreatorReferralSignup']
    __typename?: boolean | `@${string}`
  }>
  ['RequestSocialContentScriptResponse']: AliasType<{
    status?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ResetUserAttributeResponse']: AliasType<{
    cognitoUserModel?: ValueTypes['CognitoUserModel']
    statusCode?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Review']: AliasType<{
    attributedUserId?: boolean | `@${string}`
    author?: boolean | `@${string}`
    authorBadge?: boolean | `@${string}`
    badge?: boolean | `@${string}`
    commentCount?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    helpfulReactions?: boolean | `@${string}`
    id?: boolean | `@${string}`
    negativeReactions?: boolean | `@${string}`
    photos?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    productVariant?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    removed?: boolean | `@${string}`
    reviewedAt?: boolean | `@${string}`
    reviewedSku?: boolean | `@${string}`
    reviewerId?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    store?: boolean | `@${string}`
    text?: boolean | `@${string}`
    title?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    videos?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SequenceFormSubmissionValues']: AliasType<{
    componentId?: boolean | `@${string}`
    componentName?: boolean | `@${string}`
    componentType?: boolean | `@${string}`
    sequenceId?: boolean | `@${string}`
    type?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Sku']: AliasType<{
    active?: boolean | `@${string}`
    approxRetailPrice?: boolean | `@${string}`
    approxShippingCost?: boolean | `@${string}`
    attributes?: ValueTypes['SkuAttribute']
    availabilityDate?: boolean | `@${string}`
    availabilityNotified?: boolean | `@${string}`
    availabilityNotifiedAt?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    brandListingKey?: boolean | `@${string}`
    brandStoreId?: boolean | `@${string}`
    bundle?: boolean | `@${string}`
    childSkus?: [
      {
        filter?: ValueTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelSkuConnection']
    ]
    createdAt?: boolean | `@${string}`
    createdByUserId?: boolean | `@${string}`
    creationSource?: boolean | `@${string}`
    creationSourceUrl?: boolean | `@${string}`
    currency?: boolean | `@${string}`
    currentBuyBoxPrice?: boolean | `@${string}`
    currentBuyBoxPriceCents?: boolean | `@${string}`
    estimatedAvailabilityDate?: boolean | `@${string}`
    estimatedDeliveryDays?: boolean | `@${string}`
    estimatedMonthlyUnitsSold?: boolean | `@${string}`
    estimatedWeeklyUnitsSold?: boolean | `@${string}`
    exactListingTitle?: boolean | `@${string}`
    exactRetailPrice?: boolean | `@${string}`
    fullReviewScrapeComplete?: boolean | `@${string}`
    id?: boolean | `@${string}`
    inventory?: boolean | `@${string}`
    iosAppProviderId?: boolean | `@${string}`
    isAplusPage?: boolean | `@${string}`
    isPrime?: boolean | `@${string}`
    isRecommendationsHidden?: boolean | `@${string}`
    itemClassification?: boolean | `@${string}`
    lastAutoFilledAt?: boolean | `@${string}`
    lastKnownBuyBoxPrice?: boolean | `@${string}`
    lastKnownBuyBoxPriceCents?: boolean | `@${string}`
    listingBrand?: boolean | `@${string}`
    listingCategory?: boolean | `@${string}`
    listingDescription?: boolean | `@${string}`
    listingFulfillmentType?: boolean | `@${string}`
    listingGuidance?: boolean | `@${string}`
    listingLink?: boolean | `@${string}`
    mccValue?: boolean | `@${string}`
    minimumOrderQuantity?: boolean | `@${string}`
    name?: boolean | `@${string}`
    nameAudioKey?: boolean | `@${string}`
    nonSponsoredListingKey?: boolean | `@${string}`
    parentSku?: ValueTypes['Sku']
    parentSkuId?: boolean | `@${string}`
    priceCents?: boolean | `@${string}`
    product?: boolean | `@${string}`
    productImageKey?: boolean | `@${string}`
    productPostingGuidance?: boolean | `@${string}`
    productPostingGuidanceDocument?: boolean | `@${string}`
    productSearchImageKey?: boolean | `@${string}`
    releaseDate?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    sponsoredListingKey?: boolean | `@${string}`
    storeName?: boolean | `@${string}`
    targetReviewRate?: ValueTypes['Rate']
    targetReviewRateInvalid?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SkuAttribute']: AliasType<{
    name?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SkuDimensions']: AliasType<{
    name?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SkuInformation']: AliasType<{
    brand?: boolean | `@${string}`
    childSkus?: boolean | `@${string}`
    id?: boolean | `@${string}`
    image?: boolean | `@${string}`
    link?: boolean | `@${string}`
    name?: boolean | `@${string}`
    parentSku?: boolean | `@${string}`
    price?: boolean | `@${string}`
    regularPrice?: boolean | `@${string}`
    shippingCost?: boolean | `@${string}`
    store?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SkuSnapshot']: AliasType<{
    creationDateTime?: boolean | `@${string}`
    inventory?: boolean | `@${string}`
    marketplaceSku?: boolean | `@${string}`
    price?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    snapshotDate?: boolean | `@${string}`
    updatedDateTime?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialMediaFeedback']: AliasType<{
    comments?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialMediaPost']: AliasType<{
    caption?: boolean | `@${string}`
    contentType?: boolean | `@${string}`
    groupTypes?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isCopyrightedContent?: boolean | `@${string}`
    link?: boolean | `@${string}`
    mediaUrl?: boolean | `@${string}`
    postedAt?: boolean | `@${string}`
    views?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialMetric']: AliasType<{
    name?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialUnlinkResult']: AliasType<{
    userId?: boolean | `@${string}`
    verified?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialVerificationCode']: AliasType<{
    code?: boolean | `@${string}`
    id?: boolean | `@${string}`
    ttl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['StagingApprovalConfiguration']: AliasType<{
    stagingApprovalAssignee?: boolean | `@${string}`
    stagingApprovalType?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['StatusHistory']: AliasType<{
    campaignStatus?: boolean | `@${string}`
    createdById?: boolean | `@${string}`
    source?: boolean | `@${string}`
    time?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['StatusTierBonusConfig']: AliasType<{
    baseBonus?: boolean | `@${string}`
    diamondBonus?: boolean | `@${string}`
    goldBonus?: boolean | `@${string}`
    platinumBonus?: boolean | `@${string}`
    silverBonus?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['StatusTierEntry']: AliasType<{
    statusTier?: boolean | `@${string}`
    statusTierAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Subscription']: AliasType<{
    onCreateAutoBuyDistribution?: ValueTypes['AutoBuyDistribution']
    onCreateAutoBuyFlowConfiguration?: ValueTypes['AutoBuyFlowConfiguration']
    onCreateAutoBuyFlowReleaseSchedule?: ValueTypes['AutoBuyFlowReleaseSchedule']
    onCreateAutoBuyRelease?: ValueTypes['AutoBuyRelease']
    onCreateBrand?: ValueTypes['Brand']
    onCreateBrandApi?: ValueTypes['BrandApi']
    onCreateCampaign?: ValueTypes['Campaign']
    onCreateCampaignClicks?: ValueTypes['CampaignClicks']
    onCreateCampaignInvite?: [{ userId?: string | undefined | null }, ValueTypes['CampaignInvite']]
    onCreateCampaignSourcingProspect?: ValueTypes['CampaignSourcingProspect']
    onCreateChatConversationV2?: [{ userId?: string | undefined | null }, ValueTypes['ChatConversationV2']]
    onCreateCommunicationNotification?: [
      { userId?: string | undefined | null },
      ValueTypes['CommunicationNotification']
    ]
    onCreateCompany?: ValueTypes['Company']
    onCreateConnectedAccount?: [{ userId?: string | undefined | null }, ValueTypes['ConnectedAccount']]
    onCreateConversation?: [{ userId?: string | undefined | null }, ValueTypes['Conversation']]
    onCreateConversationMessage?: [{ userId?: string | undefined | null }, ValueTypes['ConversationMessage']]
    onCreateEtailerSkuMetrics?: ValueTypes['EtailerSkuMetrics']
    onCreateFormSequenceConfiguration?: ValueTypes['FormSequenceConfiguration']
    onCreateInstagramStoryScreen?: ValueTypes['InstagramStoryScreen']
    onCreateMessage?: [{ userId?: string | undefined | null }, ValueTypes['Message']]
    onCreateNote?: [{ userId?: string | undefined | null }, ValueTypes['Note']]
    onCreateNotificationConfiguration?: ValueTypes['NotificationConfiguration']
    onCreateProduct?: ValueTypes['Product']
    onCreateProductBonusConfigSnapshot?: ValueTypes['ProductBonusConfigSnapshot']
    onCreateProductKeywords?: ValueTypes['ProductKeywords']
    onCreateProductRatingSnapshot?: ValueTypes['ProductRatingSnapshot']
    onCreatePurchase?: ValueTypes['Purchase']
    onCreateReferral?: [{ referrerUserId?: string | undefined | null }, ValueTypes['Referral']]
    onCreateReview?: ValueTypes['Review']
    onCreateSku?: ValueTypes['Sku']
    onCreateSkuSnapshot?: ValueTypes['SkuSnapshot']
    onCreateUser?: [{ id?: string | undefined | null }, ValueTypes['User']]
    onCreateUserBonusPayout?: [{ userId?: string | undefined | null }, ValueTypes['UserBonusPayout']]
    onCreateUserCampaign?: [{ userId?: string | undefined | null }, ValueTypes['UserCampaign']]
    onCreateUserCampaignChangelog?: ValueTypes['UserCampaignChangelog']
    onCreateUserCampaignSnapshot?: ValueTypes['UserCampaignSnapshot']
    onCreateUserCampaignSocialContent?: ValueTypes['UserCampaignSocialContent']
    onCreateUserCampaignSocialContentDraftHistory?: ValueTypes['UserCampaignSocialContentDraftHistory']
    onCreateUserCampaignSocialContentReview?: [
      { userId?: string | undefined | null },
      ValueTypes['UserCampaignSocialContentReview']
    ]
    onCreateUserDeviceSnapshot?: ValueTypes['UserDeviceSnapshot']
    onCreateUserFollower?: [{ followerUserId?: string | undefined | null }, ValueTypes['UserFollower']]
    onCreateUserLink?: ValueTypes['UserLink']
    onCreateUserNotificationPreference?: [
      { userId?: string | undefined | null },
      ValueTypes['UserNotificationPreference']
    ]
    onCreateUserPayoutRequest?: [{ userId?: string | undefined | null }, ValueTypes['UserPayoutRequest']]
    onCreateUserStagingApproval?: ValueTypes['UserStagingApproval']
    onCreateVideoContentScan?: ValueTypes['VideoContentScan']
    onDeleteAutoBuyDistribution?: ValueTypes['AutoBuyDistribution']
    onDeleteAutoBuyFlowConfiguration?: ValueTypes['AutoBuyFlowConfiguration']
    onDeleteAutoBuyFlowReleaseSchedule?: ValueTypes['AutoBuyFlowReleaseSchedule']
    onDeleteAutoBuyRelease?: ValueTypes['AutoBuyRelease']
    onDeleteBrand?: ValueTypes['Brand']
    onDeleteBrandApi?: ValueTypes['BrandApi']
    onDeleteCampaign?: ValueTypes['Campaign']
    onDeleteCampaignClicks?: ValueTypes['CampaignClicks']
    onDeleteCampaignInvite?: [{ userId?: string | undefined | null }, ValueTypes['CampaignInvite']]
    onDeleteCampaignSourcingProspect?: ValueTypes['CampaignSourcingProspect']
    onDeleteChatConversationV2?: [{ userId?: string | undefined | null }, ValueTypes['ChatConversationV2']]
    onDeleteCommunicationNotification?: [
      { userId?: string | undefined | null },
      ValueTypes['CommunicationNotification']
    ]
    onDeleteCompany?: ValueTypes['Company']
    onDeleteConnectedAccount?: [{ userId?: string | undefined | null }, ValueTypes['ConnectedAccount']]
    onDeleteConversation?: [{ userId?: string | undefined | null }, ValueTypes['Conversation']]
    onDeleteConversationMessage?: [{ userId?: string | undefined | null }, ValueTypes['ConversationMessage']]
    onDeleteEtailerSkuMetrics?: ValueTypes['EtailerSkuMetrics']
    onDeleteFormSequenceConfiguration?: ValueTypes['FormSequenceConfiguration']
    onDeleteInstagramStoryScreen?: ValueTypes['InstagramStoryScreen']
    onDeleteMessage?: [{ userId?: string | undefined | null }, ValueTypes['Message']]
    onDeleteNote?: [{ userId?: string | undefined | null }, ValueTypes['Note']]
    onDeleteNotificationConfiguration?: ValueTypes['NotificationConfiguration']
    onDeleteProduct?: ValueTypes['Product']
    onDeleteProductBonusConfigSnapshot?: ValueTypes['ProductBonusConfigSnapshot']
    onDeleteProductKeywords?: ValueTypes['ProductKeywords']
    onDeleteProductRatingSnapshot?: ValueTypes['ProductRatingSnapshot']
    onDeletePurchase?: ValueTypes['Purchase']
    onDeleteReferral?: [{ referrerUserId?: string | undefined | null }, ValueTypes['Referral']]
    onDeleteReview?: ValueTypes['Review']
    onDeleteSku?: ValueTypes['Sku']
    onDeleteSkuSnapshot?: ValueTypes['SkuSnapshot']
    onDeleteUser?: [{ id?: string | undefined | null }, ValueTypes['User']]
    onDeleteUserBonusPayout?: [{ userId?: string | undefined | null }, ValueTypes['UserBonusPayout']]
    onDeleteUserCampaign?: [{ userId?: string | undefined | null }, ValueTypes['UserCampaign']]
    onDeleteUserCampaignChangelog?: ValueTypes['UserCampaignChangelog']
    onDeleteUserCampaignSnapshot?: ValueTypes['UserCampaignSnapshot']
    onDeleteUserCampaignSocialContent?: ValueTypes['UserCampaignSocialContent']
    onDeleteUserCampaignSocialContentDraftHistory?: ValueTypes['UserCampaignSocialContentDraftHistory']
    onDeleteUserCampaignSocialContentReview?: [
      { userId?: string | undefined | null },
      ValueTypes['UserCampaignSocialContentReview']
    ]
    onDeleteUserDeviceSnapshot?: ValueTypes['UserDeviceSnapshot']
    onDeleteUserFollower?: [{ followerUserId?: string | undefined | null }, ValueTypes['UserFollower']]
    onDeleteUserLink?: ValueTypes['UserLink']
    onDeleteUserNotificationPreference?: [
      { userId?: string | undefined | null },
      ValueTypes['UserNotificationPreference']
    ]
    onDeleteUserPayoutRequest?: [{ userId?: string | undefined | null }, ValueTypes['UserPayoutRequest']]
    onDeleteUserStagingApproval?: ValueTypes['UserStagingApproval']
    onDeleteVideoContentScan?: ValueTypes['VideoContentScan']
    onUpdateAutoBuyDistribution?: ValueTypes['AutoBuyDistribution']
    onUpdateAutoBuyFlowConfiguration?: ValueTypes['AutoBuyFlowConfiguration']
    onUpdateAutoBuyFlowReleaseSchedule?: ValueTypes['AutoBuyFlowReleaseSchedule']
    onUpdateAutoBuyRelease?: ValueTypes['AutoBuyRelease']
    onUpdateBrand?: ValueTypes['Brand']
    onUpdateBrandApi?: ValueTypes['BrandApi']
    onUpdateCampaign?: ValueTypes['Campaign']
    onUpdateCampaignClicks?: ValueTypes['CampaignClicks']
    onUpdateCampaignInvite?: [{ userId?: string | undefined | null }, ValueTypes['CampaignInvite']]
    onUpdateCampaignSourcingProspect?: ValueTypes['CampaignSourcingProspect']
    onUpdateChatConversationV2?: [{ userId?: string | undefined | null }, ValueTypes['ChatConversationV2']]
    onUpdateCommunicationNotification?: [
      { userId?: string | undefined | null },
      ValueTypes['CommunicationNotification']
    ]
    onUpdateCompany?: ValueTypes['Company']
    onUpdateConnectedAccount?: [{ userId?: string | undefined | null }, ValueTypes['ConnectedAccount']]
    onUpdateConversation?: [{ userId?: string | undefined | null }, ValueTypes['Conversation']]
    onUpdateConversationMessage?: [{ userId?: string | undefined | null }, ValueTypes['ConversationMessage']]
    onUpdateEtailerSkuMetrics?: ValueTypes['EtailerSkuMetrics']
    onUpdateFormSequenceConfiguration?: ValueTypes['FormSequenceConfiguration']
    onUpdateInstagramStoryScreen?: ValueTypes['InstagramStoryScreen']
    onUpdateMessage?: [{ userId?: string | undefined | null }, ValueTypes['Message']]
    onUpdateNote?: [{ userId?: string | undefined | null }, ValueTypes['Note']]
    onUpdateNotificationConfiguration?: ValueTypes['NotificationConfiguration']
    onUpdateProduct?: ValueTypes['Product']
    onUpdateProductBonusConfigSnapshot?: ValueTypes['ProductBonusConfigSnapshot']
    onUpdateProductKeywords?: ValueTypes['ProductKeywords']
    onUpdateProductRatingSnapshot?: ValueTypes['ProductRatingSnapshot']
    onUpdatePurchase?: ValueTypes['Purchase']
    onUpdateReferral?: [{ referrerUserId?: string | undefined | null }, ValueTypes['Referral']]
    onUpdateReview?: ValueTypes['Review']
    onUpdateSku?: ValueTypes['Sku']
    onUpdateSkuSnapshot?: ValueTypes['SkuSnapshot']
    onUpdateUser?: [{ id?: string | undefined | null }, ValueTypes['User']]
    onUpdateUserBonusPayout?: [{ userId?: string | undefined | null }, ValueTypes['UserBonusPayout']]
    onUpdateUserCampaign?: [{ userId?: string | undefined | null }, ValueTypes['UserCampaign']]
    onUpdateUserCampaignChangelog?: ValueTypes['UserCampaignChangelog']
    onUpdateUserCampaignSnapshot?: ValueTypes['UserCampaignSnapshot']
    onUpdateUserCampaignSocialContent?: ValueTypes['UserCampaignSocialContent']
    onUpdateUserCampaignSocialContentDraftHistory?: ValueTypes['UserCampaignSocialContentDraftHistory']
    onUpdateUserCampaignSocialContentReview?: [
      { userId?: string | undefined | null },
      ValueTypes['UserCampaignSocialContentReview']
    ]
    onUpdateUserDeviceSnapshot?: ValueTypes['UserDeviceSnapshot']
    onUpdateUserFollower?: [{ followerUserId?: string | undefined | null }, ValueTypes['UserFollower']]
    onUpdateUserLink?: ValueTypes['UserLink']
    onUpdateUserNotificationPreference?: [
      { userId?: string | undefined | null },
      ValueTypes['UserNotificationPreference']
    ]
    onUpdateUserPayoutRequest?: [{ userId?: string | undefined | null }, ValueTypes['UserPayoutRequest']]
    onUpdateUserStagingApproval?: ValueTypes['UserStagingApproval']
    onUpdateVideoContentScan?: ValueTypes['VideoContentScan']
    __typename?: boolean | `@${string}`
  }>
  ['UpdateUserPhoneResult']: AliasType<{
    phoneNumber?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['User']: AliasType<{
    active?: boolean | `@${string}`
    addressComponents?: boolean | `@${string}`
    amznLastChecked?: boolean | `@${string}`
    amznLink?: boolean | `@${string}`
    amznPrime?: boolean | `@${string}`
    amznProfileId?: boolean | `@${string}`
    amznReviewerId?: boolean | `@${string}`
    amznVerified?: boolean | `@${string}`
    attributedReviewCount?: boolean | `@${string}`
    blogWebsite?: boolean | `@${string}`
    brandContentRatingAvg?: boolean | `@${string}`
    campaignAcceptanceRate?: boolean | `@${string}`
    campaignApprovalRate?: boolean | `@${string}`
    campaignCompletionRate?: boolean | `@${string}`
    campaignReviewRate?: boolean | `@${string}`
    cardProfileUpdates?: boolean | `@${string}`
    cardholderId?: boolean | `@${string}`
    cardholderRegion?: boolean | `@${string}`
    characteristics?: ValueTypes['UserCharacteristic']
    characteristicsUpdatedAt?: boolean | `@${string}`
    claimedReviewCount?: boolean | `@${string}`
    collisionSkuSet?: boolean | `@${string}`
    collisionSkuSetCalculatedAt?: boolean | `@${string}`
    completedLifestyleCampaignCount?: boolean | `@${string}`
    completedLiftCampaignCount?: boolean | `@${string}`
    completedSocialCampaignCount?: boolean | `@${string}`
    connectedAccounts?: [
      {
        accountId?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelConnectedAccountFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelConnectedAccountConnection']
    ]
    contentRating?: ValueTypes['UserContentRating']
    contentRatingCompletedAt?: boolean | `@${string}`
    contentRatingCompletionTimeMillis?: boolean | `@${string}`
    contentRatingInstagramCompletionTimeMillis?: boolean | `@${string}`
    contentRatingInstagramSponsorshipContent?: ValueTypes['ContentRatingSponsorshipContent']
    contentRatingQualityScore?: boolean | `@${string}`
    contentRatingReviewStatus?: boolean | `@${string}`
    contentRatingSponsorshipContent?: ValueTypes['ContentRatingSponsorshipContent']
    contentRatingSubmittedBy?: boolean | `@${string}`
    contentRatingTiktokCompletionTimeMillis?: boolean | `@${string}`
    contentRatingTiktokSponsorshipContent?: ValueTypes['ContentRatingSponsorshipContent']
    contentTypes?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date_of_birth?: boolean | `@${string}`
    deactivationDate?: boolean | `@${string}`
    deactivationReason?: boolean | `@${string}`
    declineToReviewCount?: boolean | `@${string}`
    deranked?: boolean | `@${string}`
    email?: boolean | `@${string}`
    emailOptOut?: boolean | `@${string}`
    facebookAccessToken?: boolean | `@${string}`
    facebookAccessTokenExpiry?: boolean | `@${string}`
    facebookPageId?: boolean | `@${string}`
    facebookPageIsSubscribed?: boolean | `@${string}`
    facebookPermissionScopes?: boolean | `@${string}`
    facebookUserId?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    followedUsers?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserFollowerFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserFollowerConnection']
    ]
    followers?: [
      {
        filter?: ValueTypes['ModelUserFollowerFilterInput'] | undefined | null
        followerUserId?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserFollowerConnection']
    ]
    followsWesponsored?: boolean | `@${string}`
    fraudReason?: boolean | `@${string}`
    fraudReviewAt?: boolean | `@${string}`
    fraudReviewStatus?: boolean | `@${string}`
    gender?: boolean | `@${string}`
    hasTrueEngagement?: boolean | `@${string}`
    id?: boolean | `@${string}`
    idfaId?: boolean | `@${string}`
    incompleteCampaignCount?: boolean | `@${string}`
    instagramBio?: boolean | `@${string}`
    instagramBusinessCategoryName?: boolean | `@${string}`
    instagramCategoryName?: boolean | `@${string}`
    instagramChecked?: boolean | `@${string}`
    instagramContentTypeRating?: boolean | `@${string}`
    instagramExternalLink?: boolean | `@${string}`
    instagramFollowerCountryDistribution?: ValueTypes['InstagramCountryDistributionItem']
    instagramFollowerGenderAgeDistribution?: ValueTypes['InstagramGenderAgeDistribution']
    instagramFollowersInt?: boolean | `@${string}`
    instagramHandle?: boolean | `@${string}`
    instagramIsBusiness?: boolean | `@${string}`
    instagramIsPrivate?: boolean | `@${string}`
    instagramIsRecentlyJoined?: boolean | `@${string}`
    instagramIsVerified?: boolean | `@${string}`
    instagramLastChecked?: boolean | `@${string}`
    instagramMedianShares?: boolean | `@${string}`
    instagramMetricsRetrievedAt?: boolean | `@${string}`
    instagramName?: boolean | `@${string}`
    instagramPostsInt?: boolean | `@${string}`
    instagramPriority?: boolean | `@${string}`
    instagramProfilePic?: boolean | `@${string}`
    instagramTrailingComments?: boolean | `@${string}`
    instagramTrailingDateOfOldestPost?: boolean | `@${string}`
    instagramTrailingLikes?: boolean | `@${string}`
    instagramTrailingNumberOfPosts?: boolean | `@${string}`
    instagramTrailingPostEngagementAvg?: boolean | `@${string}`
    instagramTrailingPostEngagementRateAvg?: boolean | `@${string}`
    instagramTrailingPostEngagementRateImpressionsAvg?: boolean | `@${string}`
    instagramTrailingPostEngagementRateMedian?: boolean | `@${string}`
    instagramTrailingPostImpressionsAvg?: boolean | `@${string}`
    instagramTrailingPostImpressionsMedian?: boolean | `@${string}`
    instagramTrailingPostSponsoredPercentage?: boolean | `@${string}`
    instagramTrailingPostViewsMedian?: boolean | `@${string}`
    instagramUserId?: boolean | `@${string}`
    instagramVerificationMethod?: boolean | `@${string}`
    internalGender?: boolean | `@${string}`
    internalNotes?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelNoteConnection']
    ]
    isBrandSafe?: boolean | `@${string}`
    isBrandSafeReason?: boolean | `@${string}`
    isBrandSafeUpdatedAt?: boolean | `@${string}`
    lastActiveAt?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    manualScoringLockedUntil?: boolean | `@${string}`
    moreinfo?: boolean | `@${string}`
    notificationPreferences?: [
      {
        category?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserNotificationPreferenceConnection']
    ]
    numEvenDollarPurchases?: boolean | `@${string}`
    numPurchases?: boolean | `@${string}`
    paypalEmail?: boolean | `@${string}`
    phoneNumber?: boolean | `@${string}`
    profilePicture?: boolean | `@${string}`
    pushAddresses?: ValueTypes['PushAddress']
    pushOptOut?: boolean | `@${string}`
    referralCode?: boolean | `@${string}`
    referralCount?: boolean | `@${string}`
    referrer?: boolean | `@${string}`
    signupCountry?: boolean | `@${string}`
    smsOptOut?: boolean | `@${string}`
    socialQuality?: boolean | `@${string}`
    specialAttributes?: ValueTypes['UserAttributes']
    statusTier?: boolean | `@${string}`
    statusTierHistory?: ValueTypes['StatusTierEntry']
    statusTierLastUpdatedAt?: boolean | `@${string}`
    supportPriorityAcknowledgedAt?: boolean | `@${string}`
    supportPriorityFlag?: boolean | `@${string}`
    supportPriorityFlagSetAt?: boolean | `@${string}`
    targetAccount?: boolean | `@${string}`
    tiktokAccessToken?: boolean | `@${string}`
    tiktokAccessTokenExpiry?: boolean | `@${string}`
    tiktokAccountId?: boolean | `@${string}`
    tiktokAveragePostEngagementRate?: boolean | `@${string}`
    tiktokAveragePostEngagementRateViews?: boolean | `@${string}`
    tiktokBio?: boolean | `@${string}`
    tiktokContentTypeRating?: boolean | `@${string}`
    tiktokFollowersInt?: boolean | `@${string}`
    tiktokHandle?: boolean | `@${string}`
    tiktokMedianComments?: boolean | `@${string}`
    tiktokMedianLikes?: boolean | `@${string}`
    tiktokMedianPostEngagement?: boolean | `@${string}`
    tiktokMedianPostEngagementRate?: boolean | `@${string}`
    tiktokMedianPostEngagementRateViews?: boolean | `@${string}`
    tiktokMedianShares?: boolean | `@${string}`
    tiktokMedianViews?: boolean | `@${string}`
    tiktokMetricsRetrievedAt?: boolean | `@${string}`
    tiktokName?: boolean | `@${string}`
    tiktokPermissionScopes?: boolean | `@${string}`
    tiktokPriority?: boolean | `@${string}`
    tiktokProfilePic?: boolean | `@${string}`
    tiktokRefreshToken?: boolean | `@${string}`
    tiktokRefreshTokenExpiry?: boolean | `@${string}`
    tiktokSponsoredPostPercentage?: boolean | `@${string}`
    tiktokVerificationMethod?: boolean | `@${string}`
    tiktokVerified?: boolean | `@${string}`
    timeZone?: boolean | `@${string}`
    trackingStatus?: boolean | `@${string}`
    twitchAccount?: boolean | `@${string}`
    type?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaigns?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignConnection']
    ]
    utmReferral?: ValueTypes['UtmReferral']
    videoContentScans?: [
      {
        filter?: ValueTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelVideoContentScanConnection']
    ]
    wmartAccount?: boolean | `@${string}`
    youtubeAccessToken?: boolean | `@${string}`
    youtubeAccessTokenExpiry?: boolean | `@${string}`
    youtubeAccount?: boolean | `@${string}`
    youtubeAveragePostEngagementRate?: boolean | `@${string}`
    youtubeChannelDescription?: boolean | `@${string}`
    youtubeChannelFollowersInt?: boolean | `@${string}`
    youtubeChannelHandle?: boolean | `@${string}`
    youtubeChannelId?: boolean | `@${string}`
    youtubeChannelPicture?: boolean | `@${string}`
    youtubeChannelTitle?: boolean | `@${string}`
    youtubeIdToken?: boolean | `@${string}`
    youtubeMedianViews?: boolean | `@${string}`
    youtubeMetricsRetrievedAt?: boolean | `@${string}`
    youtubePermissionScopes?: boolean | `@${string}`
    youtubeRefreshToken?: boolean | `@${string}`
    youtubeVerificationMethod?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserAttributes']: AliasType<{
    isMother?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserBonusPayout']: AliasType<{
    adminPaymentApproved?: boolean | `@${string}`
    bonusType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    failureReason?: boolean | `@${string}`
    id?: boolean | `@${string}`
    manuallyCreatedBy?: boolean | `@${string}`
    payoutAmount?: boolean | `@${string}`
    payoutAmountCents?: boolean | `@${string}`
    payoutBatchId?: boolean | `@${string}`
    payoutDate?: boolean | `@${string}`
    payoutLocale?: boolean | `@${string}`
    payoutState?: boolean | `@${string}`
    quantity?: boolean | `@${string}`
    referenceId?: boolean | `@${string}`
    releaseAt?: boolean | `@${string}`
    revocationType?: boolean | `@${string}`
    revokedAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaign']: AliasType<{
    accountScreenShot?: boolean | `@${string}`
    amznReviewLink?: boolean | `@${string}`
    amznReviewSubmissionStatus?: boolean | `@${string}`
    applicationRejectedByUser?: ValueTypes['User']
    applicationRejectedByUserId?: boolean | `@${string}`
    approvalOutcome?: boolean | `@${string}`
    approvalStatus?: boolean | `@${string}`
    archivedFeedback?: boolean | `@${string}`
    archivedReason?: boolean | `@${string}`
    autoBuyApprovalDate?: boolean | `@${string}`
    autoBuyRevokeDate?: boolean | `@${string}`
    bestBuyReviewScreenShot?: boolean | `@${string}`
    bonusConfig?: ValueTypes['UserCampaignBonusConfig']
    campaign?: ValueTypes['Campaign']
    campaignAcceptanceStatus?: boolean | `@${string}`
    campaignApplication?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    campaignStatus?: boolean | `@${string}`
    campaignStatusOnArchive?: boolean | `@${string}`
    cardId?: boolean | `@${string}`
    cardRegion?: boolean | `@${string}`
    changelog?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignChangelogConnection']
    ]
    closeoutSurvey?: boolean | `@${string}`
    coarseCampaignStatus?: boolean | `@${string}`
    communications?: [
      {
        filter?: ValueTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelCommunicationNotificationConnection']
    ]
    contentApprovalLeadDays?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    discoverySurvey?: boolean | `@${string}`
    id?: boolean | `@${string}`
    influencerApprovalInviteFeedback?: boolean | `@${string}`
    influencerApprovalRejectFeedback?: boolean | `@${string}`
    internalNotes?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelNoteConnection']
    ]
    invoiceKey?: boolean | `@${string}`
    isAutoApproved?: boolean | `@${string}`
    keyword?: ValueTypes['ProductKeywords']
    keywordAtPurchase?: boolean | `@${string}`
    keywordId?: boolean | `@${string}`
    keywordsV2Hash?: boolean | `@${string}`
    listingKey?: boolean | `@${string}`
    localeV1?: boolean | `@${string}`
    manualPurchase?: boolean | `@${string}`
    messages?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelMessageConnection']
    ]
    moderatorStagingApprovalAt?: boolean | `@${string}`
    moderatorStagingApprovalBy?: boolean | `@${string}`
    moderatorStagingApprovalOutcome?: boolean | `@${string}`
    orderId?: boolean | `@${string}`
    orderIdInvalidReason?: boolean | `@${string}`
    orderIdSubmissionDate?: boolean | `@${string}`
    orderIdValidationDate?: boolean | `@${string}`
    orderIdValidationStatus?: boolean | `@${string}`
    orderStatus?: boolean | `@${string}`
    payForClickLink?: ValueTypes['UserLink']
    payForClickLinkId?: boolean | `@${string}`
    paypalEmailAtSubmission?: boolean | `@${string}`
    postingDeadline?: boolean | `@${string}`
    postingDeadlineExtended?: boolean | `@${string}`
    previousCardIds?: boolean | `@${string}`
    priority?: boolean | `@${string}`
    product?: ValueTypes['Product']
    productCriticalFeedback?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    productPositiveFeedback?: boolean | `@${string}`
    prospect?: ValueTypes['CampaignSourcingProspect']
    purchases?: [
      {
        filter?: ValueTypes['ModelPurchaseFilterInput'] | undefined | null
        id?: ValueTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelPurchaseConnection']
    ]
    rankPriority?: boolean | `@${string}`
    reviewScreenshot?: boolean | `@${string}`
    reviewScreenshotVerificationResult?: boolean | `@${string}`
    schedulingReleaseAt?: boolean | `@${string}`
    searchPhraseKey?: boolean | `@${string}`
    selectedFlow?: boolean | `@${string}`
    selfieVideoApprovalDate?: boolean | `@${string}`
    selfieVideoApprovalNotes?: boolean | `@${string}`
    selfieVideoApprovalStatus?: boolean | `@${string}`
    selfieVideoKey?: boolean | `@${string}`
    selfieVideoRejectionReason?: boolean | `@${string}`
    selfieVideoSubmissionRequired?: boolean | `@${string}`
    selfieVideoSubmittedAt?: boolean | `@${string}`
    sequenceFormSubmissionValues?: ValueTypes['SequenceFormSubmissionValues']
    shippingAddress?: boolean | `@${string}`
    showEarlyReviewSurvey?: boolean | `@${string}`
    socialContent?: [
      {
        filter?: ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentConnection']
    ]
    statusHistory?: boolean | `@${string}`
    totalBonuses?: [
      {
        filter?: ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserBonusPayoutConnection']
    ]
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userId?: boolean | `@${string}`
    userStagingApprovals?: [
      {
        filter?: ValueTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserStagingApprovalConnection']
    ]
    walmartReviewScreenShot?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignBonusConfig']: AliasType<{
    closeoutSurveyBonus?: boolean | `@${string}`
    contentBonuses?: ValueTypes['ProductSocialContent']
    discoveryBonus?: boolean | `@${string}`
    expectedBonus?: boolean | `@${string}`
    hasCustomBonusConfig?: boolean | `@${string}`
    payForImpressionsBonus?: ValueTypes['PayForImpressionsBonusConfig']
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignChangelog']: AliasType<{
    createdAt?: boolean | `@${string}`
    createdByUser?: ValueTypes['User']
    createdByUserId?: boolean | `@${string}`
    entryType?: boolean | `@${string}`
    extraData?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaign?: ValueTypes['UserCampaign']
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSnapshot']: AliasType<{
    createdAt?: boolean | `@${string}`
    snapshot?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userCampaignVersion?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContent']: AliasType<{
    approvalFeedback?: boolean | `@${string}`
    approvalStatus?: boolean | `@${string}`
    approvedAt?: boolean | `@${string}`
    approverPWUserId?: boolean | `@${string}`
    approverUserId?: boolean | `@${string}`
    bonusEarned?: boolean | `@${string}`
    bonusOverride?: boolean | `@${string}`
    caption?: boolean | `@${string}`
    channel?: boolean | `@${string}`
    clientFeedback?: ValueTypes['SocialMediaFeedback']
    complianceReview?: ValueTypes['ContentComplianceReviewCriteria']
    complianceReviewStatus?: boolean | `@${string}`
    complianceReviewUserId?: boolean | `@${string}`
    complianceReviewedAt?: boolean | `@${string}`
    contentType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    draftEnteredAdminApprovalAt?: boolean | `@${string}`
    draftEnteredBrandApprovalAt?: boolean | `@${string}`
    draftFeedback?: boolean | `@${string}`
    draftHistory?: boolean | `@${string}`
    draftHistoryV2?: [
      {
        filter?: ValueTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        snapshotDate?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentDraftHistoryConnection']
    ]
    draftMedia?: boolean | `@${string}`
    draftPreApprovedText?: boolean | `@${string}`
    draftQualityScore?: boolean | `@${string}`
    draftReview?: ValueTypes['ContentComplianceReviewCriteria']
    draftStatus?: boolean | `@${string}`
    draftSubmittedAt?: boolean | `@${string}`
    draftText?: boolean | `@${string}`
    earlySubmissionBonusEarnedCents?: boolean | `@${string}`
    experimentId?: boolean | `@${string}`
    externalUrl?: boolean | `@${string}`
    groupId?: boolean | `@${string}`
    hashtags?: boolean | `@${string}`
    id?: boolean | `@${string}`
    instance?: boolean | `@${string}`
    internalFeedback?: ValueTypes['SocialMediaFeedback']
    isFavorite?: boolean | `@${string}`
    isHidden?: boolean | `@${string}`
    isPurchased?: boolean | `@${string}`
    isUnavailable?: boolean | `@${string}`
    manualUploadType?: boolean | `@${string}`
    mediaId?: boolean | `@${string}`
    mediaUrls?: boolean | `@${string}`
    metrics?: ValueTypes['SocialMetric']
    paymentId?: boolean | `@${string}`
    postedAt?: boolean | `@${string}`
    qualityScore?: boolean | `@${string}`
    rejectionReason?: boolean | `@${string}`
    requestedSocialScript?: boolean | `@${string}`
    requirementLevel?: boolean | `@${string}`
    reviews?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ValueTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
      },
      ValueTypes['ModelUserCampaignSocialContentReviewConnection']
    ]
    scrapeStatus?: boolean | `@${string}`
    unavailableAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    usedRequestedSocialScript?: boolean | `@${string}`
    userCampaign?: ValueTypes['UserCampaign']
    userCampaignId?: boolean | `@${string}`
    videoEndTime?: boolean | `@${string}`
    videoStartTime?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContentDraftHistory']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    snapshot?: boolean | `@${string}`
    snapshotDate?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignSocialContentId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContentReview']: AliasType<{
    createdAt?: boolean | `@${string}`
    reviewFeedback?: ValueTypes['UserCampaignSocialContentReviewFeedback']
    reviewOutcome?: boolean | `@${string}`
    reviewStatus?: boolean | `@${string}`
    reviewType?: boolean | `@${string}`
    reviewedAt?: boolean | `@${string}`
    reviewer?: ValueTypes['User']
    reviewerAssigneeId?: boolean | `@${string}`
    reviewerFirstName?: boolean | `@${string}`
    reviewerId?: boolean | `@${string}`
    reviewerLastName?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignSocialContent?: ValueTypes['UserCampaignSocialContent']
    userCampaignSocialContentId?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContentReviewFeedback']: AliasType<{
    atTime?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    createdByFirstName?: boolean | `@${string}`
    createdByLastName?: boolean | `@${string}`
    createdByUserId?: boolean | `@${string}`
    forAssetAtIndex?: boolean | `@${string}`
    id?: boolean | `@${string}`
    message?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    visibility?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCharacteristic']: AliasType<{
    characteristic?: boolean | `@${string}`
    reason?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserContentRating']: AliasType<{
    instagramContentQualityRating?: boolean | `@${string}`
    instagramContentTypeRating?: boolean | `@${string}`
    instagramCreatorContentScore?: boolean | `@${string}`
    instagramCreatorContentScoreRaw?: ValueTypes['CreatorContentRatingRawValue']
    instagramCreatorSponsorshipContentScore?: boolean | `@${string}`
    instagramSubmittedBy?: boolean | `@${string}`
    submittedBy?: boolean | `@${string}`
    tiktokContentQualityRating?: boolean | `@${string}`
    tiktokContentTypeRating?: boolean | `@${string}`
    tiktokCreatorContentScore?: boolean | `@${string}`
    tiktokCreatorContentScoreRaw?: ValueTypes['CreatorContentRatingRawValue']
    tiktokCreatorSponsorshipContentScore?: boolean | `@${string}`
    tiktokSubmittedBy?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserDeviceSnapshot']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    idfv?: boolean | `@${string}`
    ipAddress?: boolean | `@${string}`
    isEmulator?: boolean | `@${string}`
    snapshotAt?: boolean | `@${string}`
    timezone?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserFollower']: AliasType<{
    createdAt?: boolean | `@${string}`
    follower?: ValueTypes['User']
    followerUserId?: boolean | `@${string}`
    isFavorite?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserLink']: AliasType<{
    campaignId?: boolean | `@${string}`
    clicksPerPeriod?: ValueTypes['ClicksRecords']
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    linkId?: boolean | `@${string}`
    originalLink?: boolean | `@${string}`
    rank?: boolean | `@${string}`
    storageType?: boolean | `@${string}`
    uniqueClicks?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userEncodedLink?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserNotificationPreference']: AliasType<{
    category?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    emailOptOut?: boolean | `@${string}`
    pushOptOut?: boolean | `@${string}`
    smsOptOut?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserPayoutRequest']: AliasType<{
    createdAt?: boolean | `@${string}`
    failureReason?: boolean | `@${string}`
    id?: boolean | `@${string}`
    manuallyApprovedBy?: boolean | `@${string}`
    payoutAmountCents?: boolean | `@${string}`
    payoutBatchId?: boolean | `@${string}`
    payoutDate?: boolean | `@${string}`
    payoutLocale?: boolean | `@${string}`
    payoutState?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserSearchOutput']: AliasType<{
    items?: ValueTypes['User']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserStagingApproval']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    reviewFeedback?: boolean | `@${string}`
    reviewedAt?: boolean | `@${string}`
    reviewedBy?: boolean | `@${string}`
    reviewedByEmail?: boolean | `@${string}`
    reviewedByName?: boolean | `@${string}`
    reviewedBySource?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userStagingApprovalOutcome?: boolean | `@${string}`
    userStagingApprovalType?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UtmReferral']: AliasType<{
    campaign?: boolean | `@${string}`
    content?: boolean | `@${string}`
    medium?: boolean | `@${string}`
    source?: boolean | `@${string}`
    term?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['VerifySocialCodeResult']: AliasType<{
    followers?: boolean | `@${string}`
    handle?: boolean | `@${string}`
    isBusinessAccount?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    verified?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['VideoContentScan']: AliasType<{
    audioDuration?: boolean | `@${string}`
    audioEndAt?: boolean | `@${string}`
    audioStartFrom?: boolean | `@${string}`
    contentSafetyAnalysis?: ValueTypes['ContentSafetyAnalysis']
    creationDateTime?: boolean | `@${string}`
    id?: boolean | `@${string}`
    processFinishedAt?: boolean | `@${string}`
    processId?: boolean | `@${string}`
    processInitiatedAt?: boolean | `@${string}`
    scanStatus?: boolean | `@${string}`
    socialMediaUrl?: boolean | `@${string}`
    transcription?: boolean | `@${string}`
    transcriptionConfidence?: boolean | `@${string}`
    updatedDateTime?: boolean | `@${string}`
    url?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Active']: Active
  ['AdminUpdateContentApprovalType']: AdminUpdateContentApprovalType
  ['AmazonReviewSubmissionStatus']: AmazonReviewSubmissionStatus
  ['ApplicationStatus']: ApplicationStatus
  ['ApprovalOutcome']: ApprovalOutcome
  ['ApprovalSort']: ApprovalSort
  ['ArchivedReason']: ArchivedReason
  ['AutoBuyDelayStrategy']: AutoBuyDelayStrategy
  ['AutoBuySkipReason']: AutoBuySkipReason
  ['AutoBuyStatus']: AutoBuyStatus
  ['BOPISValue']: BOPISValue
  ['BonusPayoutState']: BonusPayoutState
  ['BonusPayoutTypes']: BonusPayoutTypes
  ['BonusRequestTypes']: BonusRequestTypes
  ['BrandApiType']: BrandApiType
  ['BrandApiVersion']: BrandApiVersion
  ['BrandSafe']: BrandSafe
  ['CampaignBadgeType']: CampaignBadgeType
  ['CampaignGoal']: CampaignGoal
  ['CampaignProductType']: CampaignProductType
  ['CampaignReferralBonusFlag']: CampaignReferralBonusFlag
  ['CampaignSourcingSource']: CampaignSourcingSource
  ['CampaignStatus']: CampaignStatus
  ['Channel']: Channel
  ['ChatType']: ChatType
  ['CoarseCampaignStatus']: CoarseCampaignStatus
  ['ComplianceReviewCriteria']: ComplianceReviewCriteria
  ['ConnectedAccountType']: ConnectedAccountType
  ['ContentApprovalStatus']: ContentApprovalStatus
  ['ContentApprovalType']: ContentApprovalType
  ['ContentComplianceReviewStatus']: ContentComplianceReviewStatus
  ['ContentQualityRating']: ContentQualityRating
  ['ContentRatingReviewStatus']: ContentRatingReviewStatus
  ['ContentRatingSponsorshipContentInputSource']: ContentRatingSponsorshipContentInputSource
  ['ContentRequirementLevel']: ContentRequirementLevel
  ['ContentSafetyLabel']: ContentSafetyLabel
  ['ContentScanStatus']: ContentScanStatus
  ['ContentTypeRating']: ContentTypeRating
  ['ConversationChannel']: ConversationChannel
  ['ConversationInternalStatus']: ConversationInternalStatus
  ['ConversationMessageSource']: ConversationMessageSource
  ['ConversationMessageStatus']: ConversationMessageStatus
  ['ConversationStatus']: ConversationStatus
  ['CreationSourceVersion']: CreationSourceVersion
  ['CreatorPreference']: CreatorPreference
  ['Currency']: Currency
  ['Deranked_Reason']: Deranked_Reason
  ['DraftStatus']: DraftStatus
  ['EnabledFlag']: EnabledFlag
  ['FavoriteFlag']: FavoriteFlag
  ['FeatureFlagEnvironment']: FeatureFlagEnvironment
  ['FormComponentType']: FormComponentType
  ['FormFieldType']: FormFieldType
  ['FormType']: FormType
  ['FormValidationType']: FormValidationType
  ['FraudReason']: FraudReason
  ['FraudReviewStatus']: FraudReviewStatus
  ['InfluencerApprovalFlagV2']: InfluencerApprovalFlagV2
  ['InspirationalContentType']: InspirationalContentType
  ['InstagramGenderAgeDistributionGender']: InstagramGenderAgeDistributionGender
  ['ItemClassification']: ItemClassification
  ['KeywordAcceptanceResponse']: KeywordAcceptanceResponse
  ['LinkStorageType']: LinkStorageType
  ['ListingFulfillmentType']: ListingFulfillmentType
  ['ManualUploadType']: ManualUploadType
  ['ModelAttributeTypes']: ModelAttributeTypes
  ['ModelSortDirection']: ModelSortDirection
  ['NotificationStatus']: NotificationStatus
  ['OccurrenceConstraintPeriod']: OccurrenceConstraintPeriod
  ['OccurrenceConstraintType']: OccurrenceConstraintType
  ['OrderConfirmationScreenshotVerificationResult']: OrderConfirmationScreenshotVerificationResult
  ['OrderIdValidationStatus']: OrderIdValidationStatus
  ['Paused']: Paused
  ['PayForPerformanceFlag']: PayForPerformanceFlag
  ['PaymentStatus']: PaymentStatus
  ['PaymentTermsType']: PaymentTermsType
  ['Period']: Period
  ['PlatformInviteSource']: PlatformInviteSource
  ['PlatformPriority']: PlatformPriority
  ['PlatformSource']: PlatformSource
  ['PlatformStatus']: PlatformStatus
  ['PreApprovalStatus']: PreApprovalStatus
  ['ProductKeywordCreationSource']: ProductKeywordCreationSource
  ['ProductQualityLevel']: ProductQualityLevel
  ['PromoCodeType']: PromoCodeType
  ['PromoDiscountType']: PromoDiscountType
  ['PurchaseStatus']: PurchaseStatus
  ['PushChannel']: PushChannel
  ['ReferralType']: ReferralType
  ['RequiredProductLinkFlag']: RequiredProductLinkFlag
  ['ReviewFeedbackVisibility']: ReviewFeedbackVisibility
  ['ReviewScreenshotVerificationResult']: ReviewScreenshotVerificationResult
  ['RevocationType']: RevocationType
  ['ScrapeStatus']: ScrapeStatus
  ['SearchInputField']: SearchInputField
  ['SearchInputQueryScriptSource']: SearchInputQueryScriptSource
  ['SearchInputScriptType']: SearchInputScriptType
  ['SearchInputSortScriptSource']: SearchInputSortScriptSource
  ['SelfieVideoApprovalStatus']: SelfieVideoApprovalStatus
  ['ShopifyPromotionType']: ShopifyPromotionType
  ['SkuCreationSource']: SkuCreationSource
  ['SocialChannel']: SocialChannel
  ['SocialContentType']: SocialContentType
  ['SocialMediaPostContentType']: SocialMediaPostContentType
  ['SocialMediaPostGroupType']: SocialMediaPostGroupType
  ['SocialMetricName']: SocialMetricName
  ['SocialQuality']: SocialQuality
  ['SocialType']: SocialType
  ['SocialVerificationMethod']: SocialVerificationMethod
  ['SortOrder']: SortOrder
  ['SourcePriority']: SourcePriority
  ['StagingApprovalOutcome']: StagingApprovalOutcome
  ['StagingRequirementType']: StagingRequirementType
  ['StateChangeSource']: StateChangeSource
  ['StatusTier']: StatusTier
  ['StripeRegion']: StripeRegion
  ['SupportPriorityFlag']: SupportPriorityFlag
  ['TrackingStatus']: TrackingStatus
  ['USAState']: USAState
  ['UserCampaignAcceptanceStatus']: UserCampaignAcceptanceStatus
  ['UserCampaignApprovalStatus']: UserCampaignApprovalStatus
  ['UserCampaignChangelogEntryType']: UserCampaignChangelogEntryType
  ['UserCampaignSocialContentReviewOutcome']: UserCampaignSocialContentReviewOutcome
  ['UserCampaignSocialContentReviewStatus']: UserCampaignSocialContentReviewStatus
  ['UserCampaignSocialContentReviewType']: UserCampaignSocialContentReviewType
  ['UserCampaignStateChangeRequestType']: UserCampaignStateChangeRequestType
  ['UserFilterGender']: UserFilterGender
  ['UserGender']: UserGender
  ['UserStagingApprovalAction']: UserStagingApprovalAction
  ['UserStagingApprovalOutcome']: UserStagingApprovalOutcome
  ['UserStagingApprovalReviewSource']: UserStagingApprovalReviewSource
  ['UserStagingApprovalType']: UserStagingApprovalType
  ['ValidMccCodes']: ValidMccCodes
  ['WSLocale']: WSLocale
  ['AddressComponentsSearchFields']: {
    country?: ValueTypes['SearchInputString'] | undefined | null
    state?: ValueTypes['SearchInputString'] | undefined | null
  }
  ['AdminSendPushNotificationInput']: {
    body?: string | undefined | null
    notificationId: string
    userCampaigns?: Array<string> | undefined | null
    userIds?: Array<string> | undefined | null
  }
  ['ApplyToCampaignInput']: {
    autoApprove?: boolean | undefined | null
    campaignApplication?: string | undefined | null
    campaignId: string
    productId?: string | undefined | null
    userId: string
  }
  ['ApprovalFilterValueInput']: {
    filter: string
    value: string
  }
  ['ApproveUserCampaignForPurchaseInput']: {
    autoBuyApprovalDate?: string | undefined | null
    autoJoin?: boolean | undefined | null
    userCampaignId: string
  }
  ['AutoApprovalCriteriaInput']: {
    completedCampaigns?: number | undefined | null
  }
  ['AutoApprovalV2ConfigInput']: {
    autoApprovalCriteria?: ValueTypes['AutoApprovalCriteriaInput'] | undefined | null
    enabled?: boolean | undefined | null
  }
  ['AutoBuyScheduleInput']: {
    date: string
    desiredPurchaseCount: number
    willPurchasePercentage?: number | undefined | null
  }
  ['BillingContactInput']: {
    email: string
    name: string
  }
  ['CampaignBadgeInput']: {
    type: ValueTypes['CampaignBadgeType']
    value: string
  }
  ['ChannelConfigurationInput']: {
    attributes?: Array<ValueTypes['KeyAttributeInput'] | undefined | null> | undefined | null
    enabled?: boolean | undefined | null
    fromAddress?: string | undefined | null
    templateId: string
  }
  ['ClicksRecordsInput']: {
    clicks: number
    from: string
    to: string
  }
  ['CollisionDensityValueInput']: {
    density: number
    skuId: string
  }
  ['ContentApprovalConfigurationInput']: {
    contentApprovalAssignee?: string | undefined | null
    contentApprovalType: ValueTypes['ContentApprovalType']
  }
  ['ContentComplianceReviewCriteriaInput']: {
    compliant?: boolean | undefined | null
    criteria: ValueTypes['ComplianceReviewCriteria']
    requirement?: string | undefined | null
  }
  ['ContentGuidanceInput']: {
    instance: number
    text: string
  }
  ['ContentLabelResultInput']: {
    confidence?: number | undefined | null
    label: ValueTypes['ContentSafetyLabel']
    severity?: number | undefined | null
  }
  ['ContentRatingSponsorshipContentInput']: {
    inputSource?: ValueTypes['ContentRatingSponsorshipContentInputSource'] | undefined | null
    isCompliant?: boolean | undefined | null
    isInappropriate?: boolean | undefined | null
    link: string
    qualityRating?: number | undefined | null
  }
  ['ContentSafetyAnalysisInput']: {
    isBrandSafe?: ValueTypes['BrandSafe'] | undefined | null
    summary?: Array<ValueTypes['ContentSafetyLabelSummaryInput'] | undefined | null> | undefined | null
    textInstances?: Array<ValueTypes['ContentSafetyTextInstanceInput'] | undefined | null> | undefined | null
  }
  ['ContentSafetyLabelSummaryInput']: {
    confidence?: number | undefined | null
    label?: ValueTypes['ContentSafetyLabel'] | undefined | null
    severity?: ValueTypes['ContentSafetySeverityInput'] | undefined | null
  }
  ['ContentSafetySeverityInput']: {
    high?: number | undefined | null
    low?: number | undefined | null
    medium?: number | undefined | null
  }
  ['ContentSafetyTextInstanceInput']: {
    labels?: Array<ValueTypes['ContentLabelResultInput'] | undefined | null> | undefined | null
    text: string
    timestampEnd?: number | undefined | null
    timestampStart?: number | undefined | null
  }
  ['CreateAutoBuyDistributionInput']: {
    createdAt?: string | undefined | null
    description?: string | undefined | null
    id?: string | undefined | null
    name: string
    schedule: Array<number>
    updatedAt?: string | undefined | null
  }
  ['CreateAutoBuyFlowConfigurationInput']: {
    dailyPurchaseMax?: number | undefined | null
    delayStrategy?: ValueTypes['AutoBuyDelayStrategy'] | undefined | null
    flowId: string
    insufficientCreators?: string | undefined | null
    latestSkipReason?: ValueTypes['AutoBuySkipReason'] | undefined | null
    pauseReason?: ValueTypes['AutoBuySkipReason'] | undefined | null
    schedule?: Array<ValueTypes['AutoBuyScheduleInput']> | undefined | null
    startDate: string
    status: ValueTypes['AutoBuyStatus']
  }
  ['CreateAutoBuyFlowReleaseScheduleInput']: {
    actualPurchases?: number | undefined | null
    actualVerifiedPurchases?: number | undefined | null
    adjustedDesiredPurchases?: number | undefined | null
    autoApprovalSeats?: number | undefined | null
    date: string
    desiredPurchases?: number | undefined | null
    flowId: string
    manualPurchases?: number | undefined | null
    manualReleases?: number | undefined | null
    projectedReleaseCount?: number | undefined | null
    releaseCount?: number | undefined | null
    released?: boolean | undefined | null
    skippedReason?: ValueTypes['AutoBuySkipReason'] | undefined | null
  }
  ['CreateAutoBuyReleaseInput']: {
    date: string
    productId: string
    releaseDateTime: string
    skuId?: string | undefined | null
    userCampaignId: string
  }
  ['CreateBrandApiInput']: {
    accessToken?: string | undefined | null
    api: ValueTypes['BrandApiType']
    apiVersion?: ValueTypes['BrandApiVersion'] | undefined | null
    brandId: string
    enabled?: boolean | undefined | null
    locale?: ValueTypes['WSLocale'] | undefined | null
    refreshToken?: string | undefined | null
    shop?: string | undefined | null
    shopId?: string | undefined | null
  }
  ['CreateBrandInput']: {
    active?: string | undefined | null
    amazonBrandName?: string | undefined | null
    brandDetailsSubmitted?: boolean | undefined | null
    brandInstaHandle?: string | undefined | null
    brandLogoImageKey?: string | undefined | null
    brandStoreConfirmationImage?: string | undefined | null
    brandStoreConfirmationMobileImage?: string | undefined | null
    brandStoreScrapeUrl?: string | undefined | null
    brandTikTokHandle?: string | undefined | null
    companyId?: string | undefined | null
    contactEmails?: Array<string | undefined | null> | undefined | null
    defaultCreatorPreference?: ValueTypes['CreatorPreference'] | undefined | null
    defaultCreatorPreferenceDetails?: string | undefined | null
    defaultHashtags?: Array<string> | undefined | null
    defaultMarketingBriefKey?: string | undefined | null
    defaultPostingGuidelines?: Array<string> | undefined | null
    defaultPostingInspirationLinks?: Array<string> | undefined | null
    emailsSent?: Array<string | undefined | null> | undefined | null
    fullScrapedAt?: string | undefined | null
    id?: string | undefined | null
    isBrandStoreScrapeEnabled?: boolean | undefined | null
    isSeller?: string | undefined | null
    isVendor?: string | undefined | null
    isWalmartScrapeEnabled?: boolean | undefined | null
    lastScrapedAt?: string | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    paymentBillingContact?: ValueTypes['BillingContactInput'] | undefined | null
    platform?: ValueTypes['PlatformSource'] | undefined | null
    postingGuidanceDocument?: string | undefined | null
    region?: ValueTypes['WSLocale'] | undefined | null
    socialMediaExampleUrls?: Array<string> | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    walmartBrandName?: string | undefined | null
    walmartLastScrapedAt?: string | undefined | null
    walmartScrapeUrl?: string | undefined | null
  }
  ['CreateCampaignClicksInput']: {
    campaignId: string
    clicks: number
    date: string
  }
  ['CreateCampaignInput']: {
    aboutSection?: string | undefined | null
    active: string
    actualStartDate?: string | undefined | null
    amountPaidByClient?: number | undefined | null
    anticipatedStartDate: string
    applicationForm?: string | undefined | null
    applicationProductLinkId?: string | undefined | null
    applicationStatus?: ValueTypes['ApplicationStatus'] | undefined | null
    attributionRefUrlSuffix?: string | undefined | null
    authorizedUnits?: number | undefined | null
    badges?: Array<ValueTypes['CampaignBadgeInput'] | undefined | null> | undefined | null
    brandId: string
    brandProductShort?: string | undefined | null
    brandStoreLink?: string | undefined | null
    budgetSpent?: number | undefined | null
    campaignProductType?: ValueTypes['CampaignProductType'] | undefined | null
    campaignReferralBonusAmount?: number | undefined | null
    campaignReferralBonusEnabled?: ValueTypes['CampaignReferralBonusFlag'] | undefined | null
    campaignStateMachine?: string | undefined | null
    closeoutReleaseFailureReason?: string | undefined | null
    closeoutReleaseRate?: ValueTypes['RateInput'] | undefined | null
    collisionSkuDensity?: Array<ValueTypes['CollisionDensityValueInput']> | undefined | null
    commissionFlatRate?: number | undefined | null
    commissionPercentageRate?: number | undefined | null
    committedClicks?: number | undefined | null
    committedPlacementWinsCount?: number | undefined | null
    committedReviewsCount?: number | undefined | null
    contentApprovalRequired?: boolean | undefined | null
    contentCompleteDeadline?: string | undefined | null
    createdAt?: string | undefined | null
    creationSourceVersion?: ValueTypes['CreationSourceVersion'] | undefined | null
    earlyReviewEnabled?: boolean | undefined | null
    enableContentComplianceReview?: boolean | undefined | null
    endDate?: string | undefined | null
    excludeGender?: ValueTypes['UserFilterGender'] | undefined | null
    expectedBonus?: string | undefined | null
    followersCountCeiling?: number | undefined | null
    followersCountFloor?: number | undefined | null
    goal?: ValueTypes['CampaignGoal'] | undefined | null
    id?: string | undefined | null
    influencerApprovalEnabled?: ValueTypes['InfluencerApprovalFlagV2'] | undefined | null
    lifestyleCommittedContentCount?: number | undefined | null
    localeV1?: ValueTypes['WSLocale'] | undefined | null
    marketingFee?: number | undefined | null
    marketingFormFilled?: boolean | undefined | null
    newCreatorsOnly?: boolean | undefined | null
    payForClicksEnabled?: ValueTypes['EnabledFlag'] | undefined | null
    payForClicksGeneratedPerPeriod?: Array<ValueTypes['ClicksRecordsInput']> | undefined | null
    payForClicksMinClicks?: number | undefined | null
    payForClicksOriginalLink?: string | undefined | null
    payForClicksTotalClicksGenerated?: number | undefined | null
    payForPerformanceEnabled?: ValueTypes['PayForPerformanceFlag'] | undefined | null
    postingGuidance?: string | undefined | null
    preApplicationProductImage?: string | undefined | null
    preApplicationProductPriceRange?: string | undefined | null
    preApplicationUgcProductImage?: string | undefined | null
    preLaunchDate?: string | undefined | null
    premiumLifestyleCommittedContentCount?: number | undefined | null
    requiredProductLinksEnabled?: ValueTypes['RequiredProductLinkFlag'] | undefined | null
    reviewVotingTarget?: string | undefined | null
    sample?: boolean | undefined | null
    shellCampaignBase?: string | undefined | null
    skuId: string
    skuPriceOverride?: number | undefined | null
    socialCommittedContentCount?: number | undefined | null
    sourcingPriority?: number | undefined | null
    sponsorshipInstructions?: string | undefined | null
    title: string
    totalEarlyReviewsAllowed?: number | undefined | null
    totalProductCost?: number | undefined | null
    userAutoApproved?: boolean | undefined | null
    visibleToUser: string
  }
  ['CreateCampaignInviteInput']: {
    campaignId: string
    createdAt?: string | undefined | null
    id?: string | undefined | null
    notificationStatus: ValueTypes['NotificationStatus']
    priority: number
    updatedAt?: string | undefined | null
    userId: string
  }
  ['CreateCampaignSourcingProspectInput']: {
    bonusConfig?: ValueTypes['UserCampaignBonusConfigInput'] | undefined | null
    campaignId: string
    createdAt?: string | undefined | null
    createdById?: string | undefined | null
    instagramFollowersInt?: number | undefined | null
    instagramHandle?: string | undefined | null
    internalNotesId?: string | undefined | null
    name?: string | undefined | null
    platformInviteSource: ValueTypes['PlatformInviteSource']
    platformStatus?: ValueTypes['PlatformStatus'] | undefined | null
    preApprovalStatus?: ValueTypes['PreApprovalStatus'] | undefined | null
    preApprovalStatusById?: string | undefined | null
    priority?: ValueTypes['SourcePriority'] | undefined | null
    productId?: string | undefined | null
    sourcingSource: ValueTypes['CampaignSourcingSource']
    tiktokFollowersInt?: number | undefined | null
    tiktokHandle?: string | undefined | null
    userCampaignId?: string | undefined | null
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined | null
    youtubeChannelHandle?: string | undefined | null
  }
  ['CreateChatConversationV2Input']: {
    active: ValueTypes['Active']
    adminLastReadMessageIndex?: number | undefined | null
    adminUnreadCount: number
    campaignId?: string | undefined | null
    chatType: ValueTypes['ChatType']
    creatorLastReadMessageIndex?: number | undefined | null
    creatorUnreadCount: number
    lastMessage?: string | undefined | null
    lastMessageDate?: string | undefined | null
    lastMessageId?: string | undefined | null
    lastMessageSource?: ValueTypes['ConversationMessageSource'] | undefined | null
    resourceId: string
    status: ValueTypes['ConversationInternalStatus']
    statusUpdatedAt?: string | undefined | null
    statusUpdatedByFirstName?: string | undefined | null
    statusUpdatedById?: string | undefined | null
    statusUpdatedByLastName?: string | undefined | null
    twilioConversationId: string
    userCampaignId?: string | undefined | null
    userEmail?: string | undefined | null
    userFirstName?: string | undefined | null
    userId: string
    userLastName?: string | undefined | null
  }
  ['CreateCommunicationNotificationInput']: {
    address: string
    attributes?: Array<ValueTypes['KeyAttributeInput']> | undefined | null
    campaignId?: string | undefined | null
    channel: ValueTypes['Channel']
    expectedSentAt: string
    experimentTreatment?: string | undefined | null
    id?: string | undefined | null
    notificationId: string
    sentAt: string
    status?: string | undefined | null
    statusMessage?: string | undefined | null
    templateId?: string | undefined | null
    traceId: string
    userCampaignId?: string | undefined | null
    userId: string
  }
  ['CreateCompanyInput']: {
    agencyId?: string | undefined | null
    id?: string | undefined | null
    logo?: string | undefined | null
    name: string
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ValueTypes['PaymentTermsType'] | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['CreateConnectedAccountInput']: {
    accountId: string
    accountType: ValueTypes['ConnectedAccountType']
    createdAt?: string | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['CreateConversationInput']: {
    contactOwnerId?: string | undefined | null
    lastContactAt?: string | undefined | null
    status?: ValueTypes['ConversationStatus'] | undefined | null
    statusChangedAt?: string | undefined | null
    userId: string
  }
  ['CreateConversationMessageInput']: {
    channel: ValueTypes['ConversationChannel']
    id?: string | undefined | null
    messageContent: string
    messageId?: string | undefined | null
    messageMediaKeys?: Array<string> | undefined | null
    replyFromId?: string | undefined | null
    sendAt?: string | undefined | null
    sendAttempts?: number | undefined | null
    sentAt?: string | undefined | null
    status: ValueTypes['ConversationMessageStatus']
    userId: string
  }
  ['CreateEtailerSkuMetricsInput']: {
    date: string
    pageViews?: number | undefined | null
    sku: string
    skuId: string
    totalSalesAmount?: number | undefined | null
    unitsSold?: number | undefined | null
  }
  ['CreateFormSequenceConfigurationInput']: {
    active: ValueTypes['Active']
    id?: string | undefined | null
    sequenceName: string
    sequences?: Array<ValueTypes['FormSequenceInput']> | undefined | null
  }
  ['CreateInstagramStoryScreenInput']: {
    exits?: number | undefined | null
    expirationUnixTime: number
    externalId?: string | undefined | null
    id?: string | undefined | null
    impressions?: number | undefined | null
    instagramUserId: string
    mediaId: string
    postedAt?: string | undefined | null
    postingDate: string
    reach?: number | undefined | null
    replies?: number | undefined | null
    s3Bucket?: string | undefined | null
    tapsBack?: number | undefined | null
    tapsForward?: number | undefined | null
    userCampaignId?: string | undefined | null
  }
  ['CreateMessageInput']: {
    body: string
    createdAt?: string | undefined | null
    id?: string | undefined | null
    updatedAt?: string | undefined | null
    userCampaignId?: string | undefined | null
    userHasSeen?: boolean | undefined | null
    userId: string
  }
  ['CreateNoteInput']: {
    createdAt?: string | undefined | null
    id?: string | undefined | null
    note: string
    parentId: string
    userId: string
  }
  ['CreateNotificationConfigurationInput']: {
    category: string
    description?: string | undefined | null
    email?: ValueTypes['ChannelConfigurationInput'] | undefined | null
    enabled?: boolean | undefined | null
    id?: string | undefined | null
    inAppChatChannel?: ValueTypes['MessagingChannelConfigurationInput'] | undefined | null
    name: string
    occurrenceConstraint?: ValueTypes['OccurrenceConstraintInput'] | undefined | null
    push?: ValueTypes['PushChannelConfigurationInput'] | undefined | null
    sms?: ValueTypes['ChannelConfigurationInput'] | undefined | null
  }
  ['CreateProductBonusConfigSnapshotInput']: {
    closeoutSurveyBonus?: number | undefined | null
    contentBonuses?: Array<ValueTypes['ProductSocialContentInput']> | undefined | null
    discoveryBonus?: number | undefined | null
    payForImpressionsBonus?: ValueTypes['PayForImpressionsBonusConfigInput'] | undefined | null
    productId: string
    snapshotAt: string
  }
  ['CreateProductFromUrlInput']: {
    brandId: string
    creationSource: ValueTypes['SkuCreationSource']
    productUrl: string
  }
  ['CreateProductInput']: {
    accountRequired?: boolean | undefined | null
    active?: string | undefined | null
    approvalFilters?: Array<ValueTypes['ApprovalFilterValueInput']> | undefined | null
    autoApprovalSeatsAvailable?: number | undefined | null
    autoApprovalStatusTiers?: Array<ValueTypes['StatusTier']> | undefined | null
    autoApprovalV2Config?: ValueTypes['AutoApprovalV2ConfigInput'] | undefined | null
    automaticReviewRelease?: boolean | undefined | null
    baseBonus?: number | undefined | null
    brandAdvocateCount?: number | undefined | null
    brandTalkingPoints?: Array<string> | undefined | null
    buyOnlinePickupInStore?: ValueTypes['BOPISValue'] | undefined | null
    campaignId?: string | undefined | null
    campaignStateMachine?: string | undefined | null
    closeoutFormBonus?: number | undefined | null
    contentApprovalConfiguration?: Array<ValueTypes['ContentApprovalConfigurationInput']> | undefined | null
    contentApprovalLeadDays?: number | undefined | null
    contentRequirement?: Array<ValueTypes['ProductSocialContentInput']> | undefined | null
    creatorCount?: number | undefined | null
    creatorPreference?: ValueTypes['CreatorPreference'] | undefined | null
    creatorPreferenceDetails?: string | undefined | null
    customFtcHashtags?: Array<string> | undefined | null
    customHashtags?: Array<string> | undefined | null
    directPurchaseLink?: string | undefined | null
    engagementRateInstagramRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    engagementRateTiktokRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    followersInstagramRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    followersTiktokRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    hideBrandContentApprovals?: boolean | undefined | null
    id?: string | undefined | null
    impressionsInstagramRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    impressionsTiktokRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    instaHandle?: string | undefined | null
    internalCreatorsStagingGuidance?: string | undefined | null
    internalDescription?: string | undefined | null
    internalName?: string | undefined | null
    internalNotes?: string | undefined | null
    isDirectListingPurchase?: boolean | undefined | null
    isQualityRequirementsEnabled?: boolean | undefined | null
    listingGuidance?: string | undefined | null
    maxDiscoveryBonus?: number | undefined | null
    minimumCreatorQualityScore?: number | undefined | null
    nonRetailReviewLink?: string | undefined | null
    nonRetailReviewWebsiteName?: string | undefined | null
    payForClicksEnabled?: ValueTypes['EnabledFlag'] | undefined | null
    payForClicksMinClicks?: number | undefined | null
    payForImpressionsBonus?: ValueTypes['PayForImpressionsBonusConfigInput'] | undefined | null
    payForPerformanceBonus?: ValueTypes['StatusTierBonusConfigInput'] | undefined | null
    payForPostingContentEarly?: ValueTypes['PayForPostingContentEarlyConfigInput'] | undefined | null
    postingDeadlineDaysRelativeToApproval?: number | undefined | null
    productLinkId?: string | undefined | null
    productPostingGuidance?: string | undefined | null
    productPostingGuidanceDocument?: string | undefined | null
    promoCode?: string | undefined | null
    promoCodeGuidance?: string | undefined | null
    promoCodeType?: ValueTypes['PromoCodeType'] | undefined | null
    promoDiscountAmount?: number | undefined | null
    promoDiscountType?: ValueTypes['PromoDiscountType'] | undefined | null
    promoEndDate?: string | undefined | null
    promoLink?: string | undefined | null
    promoStartDate?: string | undefined | null
    promotionLinkId?: string | undefined | null
    purchaseByPromoCode?: boolean | undefined | null
    purchaseDiscountCode?: string | undefined | null
    purchaseDiscountCodeDetails?: string | undefined | null
    purchaseDiscountCodePercentage?: string | undefined | null
    qualityLevel?: ValueTypes['ProductQualityLevel'] | undefined | null
    requestEarlyProductFeedback?: boolean | undefined | null
    requestProductFeedback?: boolean | undefined | null
    requiredProductLinkEnabled?: ValueTypes['RequiredProductLinkFlag'] | undefined | null
    sequenceId?: string | undefined | null
    shopifyPriceRule?: string | undefined | null
    shopifyPromotionId?: string | undefined | null
    shopifyPromotionType?: ValueTypes['ShopifyPromotionType'] | undefined | null
    skuId: string
    socialMediaExampleUrls?: Array<string> | undefined | null
    stagingApprovalConfiguration?: Array<ValueTypes['StagingApprovalConfigurationInput']> | undefined | null
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined | null
    stagingRequirementType?: ValueTypes['StagingRequirementType'] | undefined | null
    stagingSocialQualityRequirement?: ValueTypes['SocialQuality'] | undefined | null
    tikTokHandle?: string | undefined | null
    unitsPurchased?: number | undefined | null
    validateProductFoundVariations?: Array<string> | undefined | null
    visibleAt?: string | undefined | null
    visibleEndsAt?: string | undefined | null
  }
  ['CreateProductKeywordsInput']: {
    adminPriority?: number | undefined | null
    brandPriority?: number | undefined | null
    brandResponse?: ValueTypes['KeywordAcceptanceResponse'] | undefined | null
    campaignId: string
    creationSource?: ValueTypes['ProductKeywordCreationSource'] | undefined | null
    endDate?: string | undefined | null
    heliumAccount?: string | undefined | null
    heliumAsin?: string | undefined | null
    heliumLink?: string | undefined | null
    id?: string | undefined | null
    keyword: string
    monthlySearchVolume?: number | undefined | null
    rank?: number | undefined | null
    rankingHistory?: Array<ValueTypes['RankPointInput']> | undefined | null
    rankingLastSkippedAt?: string | undefined | null
    rankingLastSkippedByUserId?: string | undefined | null
    rankingLastUpdatedAt?: string | undefined | null
    rankingLastUpdatedByUserId?: string | undefined | null
    recommended?: boolean | undefined | null
    skuId?: string | undefined | null
    startDate?: string | undefined | null
    usedForCampaign?: boolean | undefined | null
    visible?: boolean | undefined | null
    weight?: number | undefined | null
  }
  ['CreateProductRatingSnapshotInput']: {
    numFiveStar?: number | undefined | null
    numFourStar?: number | undefined | null
    numOneStar?: number | undefined | null
    numRatings?: number | undefined | null
    numReviews?: number | undefined | null
    numThreeStar?: number | undefined | null
    numTwoStar?: number | undefined | null
    rating?: number | undefined | null
    sku: string
    skuId: string
    snapshotAt: string
  }
  ['CreatePurchaseInput']: {
    amount: number
    cardId?: string | undefined | null
    currency: string
    id?: string | undefined | null
    isFraud?: boolean | undefined | null
    keywordAtPurchase?: string | undefined | null
    purchasedAt: string
    shippedAt?: string | undefined | null
    status: ValueTypes['PurchaseStatus']
    userCampaignId: string
  }
  ['CreateReferralInput']: {
    bonusPayoutId?: string | undefined | null
    campaignId?: string | undefined | null
    id?: string | undefined | null
    referralType?: ValueTypes['ReferralType'] | undefined | null
    referredUserId: string
    referrerUserId: string
  }
  ['CreateReviewInput']: {
    attributedUserId?: string | undefined | null
    author?: string | undefined | null
    authorBadge?: string | undefined | null
    badge?: string | undefined | null
    commentCount?: number | undefined | null
    helpfulReactions?: number | undefined | null
    id?: string | undefined | null
    negativeReactions?: number | undefined | null
    photos?: Array<string> | undefined | null
    productId: string
    productVariant?: string | undefined | null
    rating?: number | undefined | null
    removed?: boolean | undefined | null
    reviewedAt: string
    reviewedSku?: string | undefined | null
    reviewerId?: string | undefined | null
    sku?: string | undefined | null
    skuId: string
    store?: string | undefined | null
    text?: string | undefined | null
    title?: string | undefined | null
    userCampaignId?: string | undefined | null
    videos?: Array<string> | undefined | null
  }
  ['CreateSkuInput']: {
    active?: string | undefined | null
    approxRetailPrice?: string | undefined | null
    approxShippingCost?: number | undefined | null
    attributes?: Array<ValueTypes['SkuAttributeInput']> | undefined | null
    availabilityDate?: string | undefined | null
    availabilityNotified?: boolean | undefined | null
    availabilityNotifiedAt?: string | undefined | null
    brandId?: string | undefined | null
    brandListingKey?: string | undefined | null
    brandStoreId?: string | undefined | null
    bundle?: string | undefined | null
    createdByUserId?: string | undefined | null
    creationSource?: ValueTypes['SkuCreationSource'] | undefined | null
    creationSourceUrl?: string | undefined | null
    currency?: ValueTypes['Currency'] | undefined | null
    currentBuyBoxPrice?: number | undefined | null
    currentBuyBoxPriceCents?: number | undefined | null
    estimatedAvailabilityDate?: string | undefined | null
    estimatedDeliveryDays?: number | undefined | null
    estimatedMonthlyUnitsSold?: number | undefined | null
    estimatedWeeklyUnitsSold?: number | undefined | null
    exactListingTitle?: string | undefined | null
    exactRetailPrice?: string | undefined | null
    fullReviewScrapeComplete?: boolean | undefined | null
    id?: string | undefined | null
    inventory?: number | undefined | null
    iosAppProviderId?: string | undefined | null
    isAplusPage?: string | undefined | null
    isPrime?: string | undefined | null
    isRecommendationsHidden?: boolean | undefined | null
    itemClassification?: ValueTypes['ItemClassification'] | undefined | null
    lastAutoFilledAt?: string | undefined | null
    lastKnownBuyBoxPrice?: number | undefined | null
    lastKnownBuyBoxPriceCents?: number | undefined | null
    listingBrand?: string | undefined | null
    listingCategory?: Array<string> | undefined | null
    listingDescription?: string | undefined | null
    listingFulfillmentType?: ValueTypes['ListingFulfillmentType'] | undefined | null
    listingGuidance?: string | undefined | null
    listingLink?: string | undefined | null
    mccValue?: ValueTypes['ValidMccCodes'] | undefined | null
    minimumOrderQuantity?: number | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    nonSponsoredListingKey?: string | undefined | null
    parentSkuId?: string | undefined | null
    priceCents?: number | undefined | null
    product?: string | undefined | null
    productImageKey?: string | undefined | null
    productPostingGuidance?: string | undefined | null
    productPostingGuidanceDocument?: string | undefined | null
    productSearchImageKey?: string | undefined | null
    releaseDate?: string | undefined | null
    skuId?: string | undefined | null
    sponsoredListingKey?: string | undefined | null
    storeName?: string | undefined | null
    targetReviewRate?: ValueTypes['RateInput'] | undefined | null
    targetReviewRateInvalid?: string | undefined | null
  }
  ['CreateSkuSnapshotInput']: {
    inventory?: number | undefined | null
    marketplaceSku: string
    price?: number | undefined | null
    skuId: string
    snapshotDate: string
  }
  ['CreateTwilioConversationInput']: {
    chatType: ValueTypes['ChatType']
    resourceId: string
  }
  ['CreateUserBonusPayoutInput']: {
    adminPaymentApproved?: boolean | undefined | null
    bonusType: ValueTypes['BonusPayoutTypes']
    createdAt?: string | undefined | null
    failureReason?: string | undefined | null
    id?: string | undefined | null
    manuallyCreatedBy?: string | undefined | null
    payoutAmount?: number | undefined | null
    payoutAmountCents?: number | undefined | null
    payoutBatchId?: string | undefined | null
    payoutDate?: string | undefined | null
    payoutLocale?: ValueTypes['WSLocale'] | undefined | null
    payoutState?: ValueTypes['BonusPayoutState'] | undefined | null
    quantity?: number | undefined | null
    referenceId?: string | undefined | null
    releaseAt?: string | undefined | null
    revocationType?: ValueTypes['RevocationType'] | undefined | null
    revokedAt?: string | undefined | null
    userCampaignId?: string | undefined | null
    userId: string
  }
  ['CreateUserCampaignChangelogInput']: {
    createdAt?: string | undefined | null
    createdByUserId?: string | undefined | null
    entryType: ValueTypes['UserCampaignChangelogEntryType']
    extraData?: string | undefined | null
    userCampaignId: string
  }
  ['CreateUserCampaignInput']: {
    accountScreenShot?: string | undefined | null
    amznReviewLink?: string | undefined | null
    amznReviewSubmissionStatus?: ValueTypes['AmazonReviewSubmissionStatus'] | undefined | null
    applicationRejectedByUserId?: string | undefined | null
    approvalOutcome?: ValueTypes['ApprovalOutcome'] | undefined | null
    approvalStatus?: ValueTypes['UserCampaignApprovalStatus'] | undefined | null
    archivedFeedback?: string | undefined | null
    archivedReason?: ValueTypes['ArchivedReason'] | undefined | null
    autoBuyApprovalDate?: string | undefined | null
    autoBuyRevokeDate?: string | undefined | null
    bestBuyReviewScreenShot?: string | undefined | null
    bonusConfig?: ValueTypes['UserCampaignBonusConfigInput'] | undefined | null
    campaignAcceptanceStatus?: ValueTypes['UserCampaignAcceptanceStatus'] | undefined | null
    campaignApplication?: string | undefined | null
    campaignId: string
    campaignStatus?: ValueTypes['CampaignStatus'] | undefined | null
    campaignStatusOnArchive?: string | undefined | null
    cardId?: string | undefined | null
    cardRegion?: ValueTypes['StripeRegion'] | undefined | null
    closeoutSurvey?: string | undefined | null
    coarseCampaignStatus?: ValueTypes['CoarseCampaignStatus'] | undefined | null
    contentApprovalLeadDays?: number | undefined | null
    createdAt?: string | undefined | null
    discoverySurvey?: string | undefined | null
    id?: string | undefined | null
    influencerApprovalInviteFeedback?: string | undefined | null
    influencerApprovalRejectFeedback?: string | undefined | null
    invoiceKey?: string | undefined | null
    isAutoApproved?: boolean | undefined | null
    keywordAtPurchase?: string | undefined | null
    keywordId?: string | undefined | null
    keywordsV2Hash?: string | undefined | null
    listingKey?: string | undefined | null
    localeV1?: ValueTypes['WSLocale'] | undefined | null
    manualPurchase?: boolean | undefined | null
    moderatorStagingApprovalAt?: string | undefined | null
    moderatorStagingApprovalBy?: string | undefined | null
    moderatorStagingApprovalOutcome?: ValueTypes['StagingApprovalOutcome'] | undefined | null
    orderId?: string | undefined | null
    orderIdInvalidReason?: string | undefined | null
    orderIdSubmissionDate?: string | undefined | null
    orderIdValidationDate?: string | undefined | null
    orderIdValidationStatus?: ValueTypes['OrderIdValidationStatus'] | undefined | null
    orderStatus?: string | undefined | null
    payForClickLinkId?: string | undefined | null
    paypalEmailAtSubmission?: string | undefined | null
    postingDeadline?: string | undefined | null
    postingDeadlineExtended?: boolean | undefined | null
    previousCardIds?: Array<string> | undefined | null
    priority?: ValueTypes['SourcePriority'] | undefined | null
    productCriticalFeedback?: string | undefined | null
    productId?: string | undefined | null
    productPositiveFeedback?: string | undefined | null
    rankPriority?: number | undefined | null
    reviewScreenshot?: string | undefined | null
    reviewScreenshotVerificationResult?: ValueTypes['ReviewScreenshotVerificationResult'] | undefined | null
    schedulingReleaseAt?: string | undefined | null
    searchPhraseKey?: string | undefined | null
    selectedFlow?: string | undefined | null
    selfieVideoApprovalDate?: string | undefined | null
    selfieVideoApprovalNotes?: string | undefined | null
    selfieVideoApprovalStatus?: ValueTypes['SelfieVideoApprovalStatus'] | undefined | null
    selfieVideoKey?: string | undefined | null
    selfieVideoRejectionReason?: string | undefined | null
    selfieVideoSubmissionRequired?: boolean | undefined | null
    selfieVideoSubmittedAt?: string | undefined | null
    sequenceFormSubmissionValues?: Array<ValueTypes['SequenceFormSubmissionValuesInput']> | undefined | null
    shippingAddress?: string | undefined | null
    showEarlyReviewSurvey?: boolean | undefined | null
    statusHistory?: string | undefined | null
    updatedAt?: string | undefined | null
    userId: string
    walmartReviewScreenShot?: string | undefined | null
  }
  ['CreateUserCampaignSnapshotInput']: {
    snapshot: string
    userCampaignId: string
    userCampaignVersion: number
  }
  ['CreateUserCampaignSocialContentDraftHistoryInput']: {
    id?: string | undefined | null
    snapshot: string
    snapshotDate: string
    userCampaignSocialContentId: string
  }
  ['CreateUserCampaignSocialContentInput']: {
    approvalFeedback?: string | undefined | null
    approvalStatus?: ValueTypes['ContentApprovalStatus'] | undefined | null
    approvedAt?: string | undefined | null
    approverPWUserId?: string | undefined | null
    approverUserId?: string | undefined | null
    bonusEarned?: number | undefined | null
    bonusOverride?: number | undefined | null
    caption?: string | undefined | null
    channel: ValueTypes['SocialChannel']
    clientFeedback?: Array<ValueTypes['SocialMediaFeedbackInput']> | undefined | null
    complianceReview?: Array<ValueTypes['ContentComplianceReviewCriteriaInput']> | undefined | null
    complianceReviewStatus?: ValueTypes['ContentComplianceReviewStatus'] | undefined | null
    complianceReviewUserId?: string | undefined | null
    complianceReviewedAt?: string | undefined | null
    contentType?: ValueTypes['SocialContentType'] | undefined | null
    draftEnteredAdminApprovalAt?: string | undefined | null
    draftEnteredBrandApprovalAt?: string | undefined | null
    draftFeedback?: Array<string> | undefined | null
    draftHistory?: Array<string> | undefined | null
    draftMedia?: Array<string> | undefined | null
    draftPreApprovedText?: string | undefined | null
    draftQualityScore?: number | undefined | null
    draftReview?: Array<ValueTypes['ContentComplianceReviewCriteriaInput']> | undefined | null
    draftStatus?: ValueTypes['DraftStatus'] | undefined | null
    draftSubmittedAt?: string | undefined | null
    draftText?: string | undefined | null
    earlySubmissionBonusEarnedCents?: number | undefined | null
    experimentId?: string | undefined | null
    externalUrl?: string | undefined | null
    groupId?: string | undefined | null
    hashtags?: Array<string> | undefined | null
    id?: string | undefined | null
    instance?: number | undefined | null
    internalFeedback?: Array<ValueTypes['SocialMediaFeedbackInput']> | undefined | null
    isFavorite?: boolean | undefined | null
    isHidden?: boolean | undefined | null
    isPurchased?: boolean | undefined | null
    isUnavailable?: boolean | undefined | null
    manualUploadType?: ValueTypes['ManualUploadType'] | undefined | null
    mediaId?: string | undefined | null
    mediaUrls?: Array<string> | undefined | null
    metrics?: Array<ValueTypes['SocialMetricInput']> | undefined | null
    paymentId?: string | undefined | null
    postedAt?: string | undefined | null
    qualityScore?: number | undefined | null
    rejectionReason?: string | undefined | null
    requestedSocialScript?: boolean | undefined | null
    requirementLevel?: ValueTypes['ContentRequirementLevel'] | undefined | null
    scrapeStatus?: ValueTypes['ScrapeStatus'] | undefined | null
    unavailableAt?: string | undefined | null
    usedRequestedSocialScript?: boolean | undefined | null
    userCampaignId: string
    videoEndTime?: string | undefined | null
    videoStartTime?: string | undefined | null
  }
  ['CreateUserCampaignSocialContentReviewInput']: {
    createdAt?: string | undefined | null
    reviewFeedback?: Array<ValueTypes['UserCampaignSocialContentReviewFeedbackInput']> | undefined | null
    reviewOutcome?: ValueTypes['UserCampaignSocialContentReviewOutcome'] | undefined | null
    reviewStatus?: ValueTypes['UserCampaignSocialContentReviewStatus'] | undefined | null
    reviewType: ValueTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined | null
    reviewerAssigneeId?: string | undefined | null
    reviewerFirstName?: string | undefined | null
    reviewerId?: string | undefined | null
    reviewerLastName?: string | undefined | null
    userCampaignSocialContentId: string
    userId: string
  }
  ['CreateUserDeviceSnapshotInput']: {
    id?: string | undefined | null
    idfv?: string | undefined | null
    ipAddress?: string | undefined | null
    isEmulator?: boolean | undefined | null
    snapshotAt: string
    timezone?: string | undefined | null
    userId: string
  }
  ['CreateUserFollowerInput']: {
    createdAt?: string | undefined | null
    followerUserId: string
    isFavorite?: ValueTypes['FavoriteFlag'] | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['CreateUserInput']: {
    active?: ValueTypes['Active'] | undefined | null
    addressComponents?: string | undefined | null
    amznLastChecked?: string | undefined | null
    amznLink?: string | undefined | null
    amznPrime?: string | undefined | null
    amznProfileId?: string | undefined | null
    amznReviewerId?: string | undefined | null
    amznVerified?: string | undefined | null
    attributedReviewCount?: number | undefined | null
    blogWebsite?: string | undefined | null
    brandContentRatingAvg?: number | undefined | null
    campaignAcceptanceRate?: number | undefined | null
    campaignApprovalRate?: number | undefined | null
    campaignCompletionRate?: number | undefined | null
    campaignReviewRate?: number | undefined | null
    cardProfileUpdates?: string | undefined | null
    cardholderId?: string | undefined | null
    cardholderRegion?: ValueTypes['StripeRegion'] | undefined | null
    characteristics?: Array<ValueTypes['UserCharacteristicInput']> | undefined | null
    characteristicsUpdatedAt?: string | undefined | null
    claimedReviewCount?: number | undefined | null
    collisionSkuSet?: Array<string> | undefined | null
    collisionSkuSetCalculatedAt?: string | undefined | null
    completedLifestyleCampaignCount?: number | undefined | null
    completedLiftCampaignCount?: number | undefined | null
    completedSocialCampaignCount?: number | undefined | null
    contentRating?: ValueTypes['UserContentRatingInput'] | undefined | null
    contentRatingCompletedAt?: string | undefined | null
    contentRatingCompletionTimeMillis?: number | undefined | null
    contentRatingInstagramCompletionTimeMillis?: number | undefined | null
    contentRatingInstagramSponsorshipContent?:
      | Array<ValueTypes['ContentRatingSponsorshipContentInput']>
      | undefined
      | null
    contentRatingQualityScore?: number | undefined | null
    contentRatingReviewStatus?: ValueTypes['ContentRatingReviewStatus'] | undefined | null
    contentRatingSponsorshipContent?: Array<ValueTypes['ContentRatingSponsorshipContentInput']> | undefined | null
    contentRatingSubmittedBy?: string | undefined | null
    contentRatingTiktokCompletionTimeMillis?: number | undefined | null
    contentRatingTiktokSponsorshipContent?: Array<ValueTypes['ContentRatingSponsorshipContentInput']> | undefined | null
    contentTypes?: Array<string> | undefined | null
    createdAt?: string | undefined | null
    date_of_birth?: string | undefined | null
    deactivationDate?: string | undefined | null
    deactivationReason?: string | undefined | null
    declineToReviewCount?: number | undefined | null
    deranked?: ValueTypes['Deranked_Reason'] | undefined | null
    email?: string | undefined | null
    emailOptOut?: boolean | undefined | null
    facebookAccessToken?: string | undefined | null
    facebookAccessTokenExpiry?: string | undefined | null
    facebookPageId?: string | undefined | null
    facebookPageIsSubscribed?: boolean | undefined | null
    facebookPermissionScopes?: Array<string | undefined | null> | undefined | null
    facebookUserId?: string | undefined | null
    firstName?: string | undefined | null
    followsWesponsored?: string | undefined | null
    fraudReason?: ValueTypes['FraudReason'] | undefined | null
    fraudReviewAt?: string | undefined | null
    fraudReviewStatus?: ValueTypes['FraudReviewStatus'] | undefined | null
    gender?: string | undefined | null
    hasTrueEngagement?: boolean | undefined | null
    id?: string | undefined | null
    idfaId?: string | undefined | null
    incompleteCampaignCount?: number | undefined | null
    instagramBio?: string | undefined | null
    instagramBusinessCategoryName?: string | undefined | null
    instagramCategoryName?: string | undefined | null
    instagramChecked?: string | undefined | null
    instagramContentTypeRating?: Array<ValueTypes['ContentTypeRating']> | undefined | null
    instagramExternalLink?: string | undefined | null
    instagramFollowerCountryDistribution?: Array<ValueTypes['InstagramCountryDistributionItemInput']> | undefined | null
    instagramFollowerGenderAgeDistribution?: ValueTypes['InstagramGenderAgeDistributionInput'] | undefined | null
    instagramFollowersInt?: number | undefined | null
    instagramHandle?: string | undefined | null
    instagramIsBusiness?: string | undefined | null
    instagramIsPrivate?: string | undefined | null
    instagramIsRecentlyJoined?: string | undefined | null
    instagramIsVerified?: string | undefined | null
    instagramLastChecked?: string | undefined | null
    instagramMedianShares?: number | undefined | null
    instagramMetricsRetrievedAt?: string | undefined | null
    instagramName?: string | undefined | null
    instagramPostsInt?: number | undefined | null
    instagramPriority?: ValueTypes['PlatformPriority'] | undefined | null
    instagramProfilePic?: string | undefined | null
    instagramTrailingComments?: number | undefined | null
    instagramTrailingDateOfOldestPost?: string | undefined | null
    instagramTrailingLikes?: number | undefined | null
    instagramTrailingNumberOfPosts?: number | undefined | null
    instagramTrailingPostEngagementAvg?: number | undefined | null
    instagramTrailingPostEngagementRateAvg?: number | undefined | null
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined | null
    instagramTrailingPostEngagementRateMedian?: number | undefined | null
    instagramTrailingPostImpressionsAvg?: number | undefined | null
    instagramTrailingPostImpressionsMedian?: number | undefined | null
    instagramTrailingPostSponsoredPercentage?: number | undefined | null
    instagramTrailingPostViewsMedian?: number | undefined | null
    instagramUserId?: string | undefined | null
    instagramVerificationMethod?: ValueTypes['SocialVerificationMethod'] | undefined | null
    internalGender?: ValueTypes['UserGender'] | undefined | null
    isBrandSafe?: ValueTypes['BrandSafe'] | undefined | null
    isBrandSafeReason?: string | undefined | null
    isBrandSafeUpdatedAt?: string | undefined | null
    lastActiveAt?: string | undefined | null
    lastName?: string | undefined | null
    manualScoringLockedUntil?: string | undefined | null
    moreinfo?: string | undefined | null
    numEvenDollarPurchases?: number | undefined | null
    numPurchases?: number | undefined | null
    paypalEmail?: string | undefined | null
    phoneNumber?: string | undefined | null
    profilePicture?: string | undefined | null
    pushAddresses?: Array<ValueTypes['PushAddressInput']> | undefined | null
    pushOptOut?: boolean | undefined | null
    referralCode?: string | undefined | null
    referralCount?: number | undefined | null
    referrer?: string | undefined | null
    signupCountry?: string | undefined | null
    smsOptOut?: boolean | undefined | null
    socialQuality?: ValueTypes['SocialQuality'] | undefined | null
    specialAttributes?: ValueTypes['UserAttributesInput'] | undefined | null
    statusTier?: ValueTypes['StatusTier'] | undefined | null
    statusTierHistory?: Array<ValueTypes['StatusTierEntryInput']> | undefined | null
    statusTierLastUpdatedAt?: string | undefined | null
    supportPriorityAcknowledgedAt?: string | undefined | null
    supportPriorityFlag?: ValueTypes['SupportPriorityFlag'] | undefined | null
    supportPriorityFlagSetAt?: string | undefined | null
    targetAccount?: string | undefined | null
    tiktokAccessToken?: string | undefined | null
    tiktokAccessTokenExpiry?: string | undefined | null
    tiktokAccountId?: string | undefined | null
    tiktokAveragePostEngagementRate?: number | undefined | null
    tiktokAveragePostEngagementRateViews?: number | undefined | null
    tiktokBio?: string | undefined | null
    tiktokContentTypeRating?: Array<ValueTypes['ContentTypeRating']> | undefined | null
    tiktokFollowersInt?: number | undefined | null
    tiktokHandle?: string | undefined | null
    tiktokMedianComments?: number | undefined | null
    tiktokMedianLikes?: number | undefined | null
    tiktokMedianPostEngagement?: number | undefined | null
    tiktokMedianPostEngagementRate?: number | undefined | null
    tiktokMedianPostEngagementRateViews?: number | undefined | null
    tiktokMedianShares?: number | undefined | null
    tiktokMedianViews?: number | undefined | null
    tiktokMetricsRetrievedAt?: string | undefined | null
    tiktokName?: string | undefined | null
    tiktokPermissionScopes?: Array<string> | undefined | null
    tiktokPriority?: ValueTypes['PlatformPriority'] | undefined | null
    tiktokProfilePic?: string | undefined | null
    tiktokRefreshToken?: string | undefined | null
    tiktokRefreshTokenExpiry?: string | undefined | null
    tiktokSponsoredPostPercentage?: number | undefined | null
    tiktokVerificationMethod?: ValueTypes['SocialVerificationMethod'] | undefined | null
    tiktokVerified?: string | undefined | null
    timeZone?: string | undefined | null
    trackingStatus?: ValueTypes['TrackingStatus'] | undefined | null
    twitchAccount?: string | undefined | null
    type: string
    utmReferral?: ValueTypes['UtmReferralInput'] | undefined | null
    wmartAccount?: string | undefined | null
    youtubeAccessToken?: string | undefined | null
    youtubeAccessTokenExpiry?: string | undefined | null
    youtubeAccount?: string | undefined | null
    youtubeAveragePostEngagementRate?: number | undefined | null
    youtubeChannelDescription?: string | undefined | null
    youtubeChannelFollowersInt?: number | undefined | null
    youtubeChannelHandle?: string | undefined | null
    youtubeChannelId?: string | undefined | null
    youtubeChannelPicture?: string | undefined | null
    youtubeChannelTitle?: string | undefined | null
    youtubeIdToken?: string | undefined | null
    youtubeMedianViews?: number | undefined | null
    youtubeMetricsRetrievedAt?: string | undefined | null
    youtubePermissionScopes?: Array<string> | undefined | null
    youtubeRefreshToken?: string | undefined | null
    youtubeVerificationMethod?: ValueTypes['SocialVerificationMethod'] | undefined | null
  }
  ['CreateUserLinkInput']: {
    campaignId?: string | undefined | null
    clicksPerPeriod?: Array<ValueTypes['ClicksRecordsInput']> | undefined | null
    createdAt?: string | undefined | null
    id?: string | undefined | null
    linkId?: string | undefined | null
    originalLink: string
    rank?: number | undefined | null
    storageType?: ValueTypes['LinkStorageType'] | undefined | null
    uniqueClicks: number
    updatedAt?: string | undefined | null
    userEncodedLink: string
    userId?: string | undefined | null
  }
  ['CreateUserNotificationPreferenceInput']: {
    category: string
    emailOptOut?: boolean | undefined | null
    pushOptOut?: boolean | undefined | null
    smsOptOut?: boolean | undefined | null
    userId: string
  }
  ['CreateUserPayoutRequestInput']: {
    failureReason?: string | undefined | null
    id?: string | undefined | null
    manuallyApprovedBy?: string | undefined | null
    payoutAmountCents?: number | undefined | null
    payoutBatchId?: string | undefined | null
    payoutDate?: string | undefined | null
    payoutLocale?: ValueTypes['WSLocale'] | undefined | null
    payoutState: ValueTypes['BonusPayoutState']
    userId: string
  }
  ['CreateUserStagingApprovalInput']: {
    id?: string | undefined | null
    reviewFeedback?: string | undefined | null
    reviewedAt?: string | undefined | null
    reviewedBy?: string | undefined | null
    reviewedByEmail?: string | undefined | null
    reviewedByName?: string | undefined | null
    reviewedBySource?: ValueTypes['UserStagingApprovalReviewSource'] | undefined | null
    userCampaignId: string
    userStagingApprovalOutcome: ValueTypes['UserStagingApprovalOutcome']
    userStagingApprovalType: ValueTypes['UserStagingApprovalType']
  }
  ['CreateVideoContentScanInput']: {
    audioDuration?: number | undefined | null
    audioEndAt?: number | undefined | null
    audioStartFrom?: number | undefined | null
    contentSafetyAnalysis?: ValueTypes['ContentSafetyAnalysisInput'] | undefined | null
    id?: string | undefined | null
    processFinishedAt?: string | undefined | null
    processId?: string | undefined | null
    processInitiatedAt?: string | undefined | null
    scanStatus: ValueTypes['ContentScanStatus']
    socialMediaUrl?: string | undefined | null
    transcription?: string | undefined | null
    transcriptionConfidence?: number | undefined | null
    url: string
    userId: string
  }
  ['CreatorContentRatingRawValueInput']: {
    name: string
    value?: string | undefined | null
  }
  ['DeleteAutoBuyDistributionInput']: {
    id: string
  }
  ['DeleteAutoBuyFlowConfigurationInput']: {
    flowId: string
  }
  ['DeleteAutoBuyFlowReleaseScheduleInput']: {
    date: string
    flowId: string
  }
  ['DeleteAutoBuyReleaseInput']: {
    date: string
    userCampaignId: string
  }
  ['DeleteBrandApiInput']: {
    api: ValueTypes['BrandApiType']
    brandId: string
  }
  ['DeleteBrandInput']: {
    id: string
  }
  ['DeleteCampaignClicksInput']: {
    campaignId: string
    date: string
  }
  ['DeleteCampaignInput']: {
    id: string
  }
  ['DeleteCampaignInviteInput']: {
    id: string
  }
  ['DeleteCampaignSourcingProspectInput']: {
    campaignId: string
    userIdentifier: string
  }
  ['DeleteChatConversationV2Input']: {
    chatType: ValueTypes['ChatType']
    resourceId: string
  }
  ['DeleteCommunicationNotificationInput']: {
    id: string
  }
  ['DeleteCompanyInput']: {
    id: string
  }
  ['DeleteConnectedAccountInput']: {
    accountId: string
    userId: string
  }
  ['DeleteConversationInput']: {
    userId: string
  }
  ['DeleteConversationMessageInput']: {
    id: string
    userId: string
  }
  ['DeleteEtailerSkuMetricsInput']: {
    date: string
    skuId: string
  }
  ['DeleteFormSequenceConfigurationInput']: {
    id: string
  }
  ['DeleteInstagramStoryScreenInput']: {
    id: string
  }
  ['DeleteMessageInput']: {
    id: string
  }
  ['DeleteNoteInput']: {
    id: string
  }
  ['DeleteNotificationConfigurationInput']: {
    id: string
  }
  ['DeleteProductBonusConfigSnapshotInput']: {
    productId: string
    snapshotAt: string
  }
  ['DeleteProductInput']: {
    id: string
  }
  ['DeleteProductKeywordsInput']: {
    id: string
  }
  ['DeleteProductRatingSnapshotInput']: {
    skuId: string
    snapshotAt: string
  }
  ['DeletePurchaseInput']: {
    id: string
  }
  ['DeleteReferralInput']: {
    id: string
  }
  ['DeleteReviewInput']: {
    id: string
  }
  ['DeleteSkuInput']: {
    id: string
  }
  ['DeleteSkuSnapshotInput']: {
    marketplaceSku: string
    snapshotDate: string
  }
  ['DeleteUserBonusPayoutInput']: {
    id: string
  }
  ['DeleteUserCampaignChangelogInput']: {
    createdAt: string
    userCampaignId: string
  }
  ['DeleteUserCampaignInput']: {
    id: string
  }
  ['DeleteUserCampaignSnapshotInput']: {
    userCampaignId: string
    userCampaignVersion: number
  }
  ['DeleteUserCampaignSocialContentDraftHistoryInput']: {
    id: string
  }
  ['DeleteUserCampaignSocialContentInput']: {
    id: string
  }
  ['DeleteUserCampaignSocialContentReviewInput']: {
    reviewType: ValueTypes['UserCampaignSocialContentReviewType']
    userCampaignSocialContentId: string
  }
  ['DeleteUserDeviceSnapshotInput']: {
    id: string
  }
  ['DeleteUserFollowerInput']: {
    followerUserId: string
    userId: string
  }
  ['DeleteUserInput']: {
    id: string
  }
  ['DeleteUserLinkInput']: {
    id: string
  }
  ['DeleteUserNotificationPreferenceInput']: {
    category: string
    userId: string
  }
  ['DeleteUserPayoutRequestInput']: {
    id: string
  }
  ['DeleteUserStagingApprovalInput']: {
    id: string
  }
  ['DeleteVideoContentScanInput']: {
    id: string
  }
  ['FollowersSearchFields']: {
    followerUserId?: ValueTypes['SearchInputString'] | undefined | null
    isFavorite?: ValueTypes['SearchInputString'] | undefined | null
  }
  ['FormSequenceComponentInput']: {
    componentName: string
    componentType: ValueTypes['FormComponentType']
    content?: string | undefined | null
    id: string
    images?: Array<string> | undefined | null
    numbered?: boolean | undefined | null
    placeholder?: string | undefined | null
    type?: ValueTypes['FormFieldType'] | undefined | null
    validation?: Array<ValueTypes['FormValidationType']> | undefined | null
  }
  ['FormSequenceInput']: {
    components?: Array<ValueTypes['FormSequenceComponentInput']> | undefined | null
    id: string
  }
  ['InspirationalContentInput']: {
    socialChannel?: ValueTypes['SocialChannel'] | undefined | null
    type: ValueTypes['InspirationalContentType']
    url: string
  }
  ['InstagramCountryDistributionItemInput']: {
    count: number
    countryCode: string
    pctDistribution: number
  }
  ['InstagramFollowerCountryDistributionSearchFields']: {
    countryCode: ValueTypes['SearchInputString']
    pctDistribution: ValueTypes['SearchInputNumber']
  }
  ['InstagramGenderAgeDistributionInput']: {
    femaleCount: number
    genderAgeDistribution?: Array<ValueTypes['InstagramGenderAgeDistributionItemInput']> | undefined | null
    maleCount: number
    unspecifiedCount: number
  }
  ['InstagramGenderAgeDistributionItemInput']: {
    ageRangeEnd?: number | undefined | null
    ageRangeStart: number
    count: number
    gender: ValueTypes['InstagramGenderAgeDistributionGender']
  }
  ['InviteUsersToCampaignInput']: {
    campaignId: string
    userIds: Array<string>
  }
  ['KeyAttributeInput']: {
    key: string
    value: string
  }
  ['MessagingChannelConfigurationInput']: {
    attributes?: Array<ValueTypes['KeyAttributeInput']> | undefined | null
    enabled: boolean
    templateContent: string
  }
  ['MinMaxRangeInput']: {
    max?: number | undefined | null
    min?: number | undefined | null
  }
  ['ModelActiveInput']: {
    eq?: ValueTypes['Active'] | undefined | null
    ne?: ValueTypes['Active'] | undefined | null
  }
  ['ModelAmazonReviewSubmissionStatusInput']: {
    eq?: ValueTypes['AmazonReviewSubmissionStatus'] | undefined | null
    ne?: ValueTypes['AmazonReviewSubmissionStatus'] | undefined | null
  }
  ['ModelApplicationStatusInput']: {
    eq?: ValueTypes['ApplicationStatus'] | undefined | null
    ne?: ValueTypes['ApplicationStatus'] | undefined | null
  }
  ['ModelApprovalOutcomeInput']: {
    eq?: ValueTypes['ApprovalOutcome'] | undefined | null
    ne?: ValueTypes['ApprovalOutcome'] | undefined | null
  }
  ['ModelArchivedReasonInput']: {
    eq?: ValueTypes['ArchivedReason'] | undefined | null
    ne?: ValueTypes['ArchivedReason'] | undefined | null
  }
  ['ModelAutoBuyDelayStrategyInput']: {
    eq?: ValueTypes['AutoBuyDelayStrategy'] | undefined | null
    ne?: ValueTypes['AutoBuyDelayStrategy'] | undefined | null
  }
  ['ModelAutoBuyDistributionConditionInput']: {
    and?: Array<ValueTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    description?: ValueTypes['ModelStringInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null> | undefined | null
    schedule?: ValueTypes['ModelIntInput'] | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelAutoBuyDistributionFilterInput']: {
    and?: Array<ValueTypes['ModelAutoBuyDistributionFilterInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    description?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelAutoBuyDistributionFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelAutoBuyDistributionFilterInput'] | undefined | null> | undefined | null
    schedule?: ValueTypes['ModelIntInput'] | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelAutoBuyFlowConfigurationConditionInput']: {
    and?: Array<ValueTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null> | undefined | null
    dailyPurchaseMax?: ValueTypes['ModelIntInput'] | undefined | null
    delayStrategy?: ValueTypes['ModelAutoBuyDelayStrategyInput'] | undefined | null
    insufficientCreators?: ValueTypes['ModelStringInput'] | undefined | null
    latestSkipReason?: ValueTypes['ModelAutoBuySkipReasonInput'] | undefined | null
    not?: ValueTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null> | undefined | null
    pauseReason?: ValueTypes['ModelAutoBuySkipReasonInput'] | undefined | null
    startDate?: ValueTypes['ModelStringInput'] | undefined | null
    status?: ValueTypes['ModelAutoBuyStatusInput'] | undefined | null
  }
  ['ModelAutoBuyFlowConfigurationFilterInput']: {
    and?: Array<ValueTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null> | undefined | null
    dailyPurchaseMax?: ValueTypes['ModelIntInput'] | undefined | null
    delayStrategy?: ValueTypes['ModelAutoBuyDelayStrategyInput'] | undefined | null
    flowId?: ValueTypes['ModelIDInput'] | undefined | null
    insufficientCreators?: ValueTypes['ModelStringInput'] | undefined | null
    latestSkipReason?: ValueTypes['ModelAutoBuySkipReasonInput'] | undefined | null
    not?: ValueTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null> | undefined | null
    pauseReason?: ValueTypes['ModelAutoBuySkipReasonInput'] | undefined | null
    startDate?: ValueTypes['ModelStringInput'] | undefined | null
    status?: ValueTypes['ModelAutoBuyStatusInput'] | undefined | null
  }
  ['ModelAutoBuyFlowReleaseScheduleConditionInput']: {
    actualPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    actualVerifiedPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    adjustedDesiredPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null> | undefined | null
    autoApprovalSeats?: ValueTypes['ModelIntInput'] | undefined | null
    desiredPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    manualPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    manualReleases?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null> | undefined | null
    projectedReleaseCount?: ValueTypes['ModelIntInput'] | undefined | null
    releaseCount?: ValueTypes['ModelIntInput'] | undefined | null
    released?: ValueTypes['ModelBooleanInput'] | undefined | null
    skippedReason?: ValueTypes['ModelAutoBuySkipReasonInput'] | undefined | null
  }
  ['ModelAutoBuyFlowReleaseScheduleFilterInput']: {
    actualPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    actualVerifiedPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    adjustedDesiredPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null> | undefined | null
    autoApprovalSeats?: ValueTypes['ModelIntInput'] | undefined | null
    date?: ValueTypes['ModelStringInput'] | undefined | null
    desiredPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    flowId?: ValueTypes['ModelIDInput'] | undefined | null
    manualPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    manualReleases?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null> | undefined | null
    projectedReleaseCount?: ValueTypes['ModelIntInput'] | undefined | null
    releaseCount?: ValueTypes['ModelIntInput'] | undefined | null
    released?: ValueTypes['ModelBooleanInput'] | undefined | null
    skippedReason?: ValueTypes['ModelAutoBuySkipReasonInput'] | undefined | null
  }
  ['ModelAutoBuyReleaseConditionInput']: {
    and?: Array<ValueTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null> | undefined | null
    not?: ValueTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null> | undefined | null
    productId?: ValueTypes['ModelIDInput'] | undefined | null
    releaseDateTime?: ValueTypes['ModelStringInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelAutoBuyReleaseFilterInput']: {
    and?: Array<ValueTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null> | undefined | null
    date?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null> | undefined | null
    productId?: ValueTypes['ModelIDInput'] | undefined | null
    releaseDateTime?: ValueTypes['ModelStringInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelAutoBuySkipReasonInput']: {
    eq?: ValueTypes['AutoBuySkipReason'] | undefined | null
    ne?: ValueTypes['AutoBuySkipReason'] | undefined | null
  }
  ['ModelAutoBuyStatusInput']: {
    eq?: ValueTypes['AutoBuyStatus'] | undefined | null
    ne?: ValueTypes['AutoBuyStatus'] | undefined | null
  }
  ['ModelBOPISValueInput']: {
    eq?: ValueTypes['BOPISValue'] | undefined | null
    ne?: ValueTypes['BOPISValue'] | undefined | null
  }
  ['ModelBonusPayoutStateInput']: {
    eq?: ValueTypes['BonusPayoutState'] | undefined | null
    ne?: ValueTypes['BonusPayoutState'] | undefined | null
  }
  ['ModelBonusPayoutTypesInput']: {
    eq?: ValueTypes['BonusPayoutTypes'] | undefined | null
    ne?: ValueTypes['BonusPayoutTypes'] | undefined | null
  }
  ['ModelBooleanInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ValueTypes['ModelAttributeTypes'] | undefined | null
    eq?: boolean | undefined | null
    ne?: boolean | undefined | null
  }
  ['ModelBrandApiConditionInput']: {
    accessToken?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelBrandApiConditionInput'] | undefined | null> | undefined | null
    apiVersion?: ValueTypes['ModelBrandApiVersionInput'] | undefined | null
    enabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    locale?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    not?: ValueTypes['ModelBrandApiConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelBrandApiConditionInput'] | undefined | null> | undefined | null
    refreshToken?: ValueTypes['ModelStringInput'] | undefined | null
    shop?: ValueTypes['ModelStringInput'] | undefined | null
    shopId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelBrandApiFilterInput']: {
    accessToken?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelBrandApiFilterInput'] | undefined | null> | undefined | null
    api?: ValueTypes['ModelBrandApiTypeInput'] | undefined | null
    apiVersion?: ValueTypes['ModelBrandApiVersionInput'] | undefined | null
    brandId?: ValueTypes['ModelIDInput'] | undefined | null
    enabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    locale?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    not?: ValueTypes['ModelBrandApiFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelBrandApiFilterInput'] | undefined | null> | undefined | null
    refreshToken?: ValueTypes['ModelStringInput'] | undefined | null
    shop?: ValueTypes['ModelStringInput'] | undefined | null
    shopId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelBrandApiTypeInput']: {
    eq?: ValueTypes['BrandApiType'] | undefined | null
    ne?: ValueTypes['BrandApiType'] | undefined | null
  }
  ['ModelBrandApiVersionInput']: {
    eq?: ValueTypes['BrandApiVersion'] | undefined | null
    ne?: ValueTypes['BrandApiVersion'] | undefined | null
  }
  ['ModelBrandConditionInput']: {
    active?: ValueTypes['ModelStringInput'] | undefined | null
    amazonBrandName?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelBrandConditionInput'] | undefined | null> | undefined | null
    brandDetailsSubmitted?: ValueTypes['ModelBooleanInput'] | undefined | null
    brandInstaHandle?: ValueTypes['ModelStringInput'] | undefined | null
    brandLogoImageKey?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreConfirmationImage?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreConfirmationMobileImage?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreScrapeUrl?: ValueTypes['ModelStringInput'] | undefined | null
    brandTikTokHandle?: ValueTypes['ModelStringInput'] | undefined | null
    companyId?: ValueTypes['ModelIDInput'] | undefined | null
    contactEmails?: ValueTypes['ModelStringInput'] | undefined | null
    defaultCreatorPreference?: ValueTypes['ModelCreatorPreferenceInput'] | undefined | null
    defaultCreatorPreferenceDetails?: ValueTypes['ModelStringInput'] | undefined | null
    defaultHashtags?: ValueTypes['ModelStringInput'] | undefined | null
    defaultMarketingBriefKey?: ValueTypes['ModelStringInput'] | undefined | null
    defaultPostingGuidelines?: ValueTypes['ModelStringInput'] | undefined | null
    defaultPostingInspirationLinks?: ValueTypes['ModelStringInput'] | undefined | null
    emailsSent?: ValueTypes['ModelStringInput'] | undefined | null
    fullScrapedAt?: ValueTypes['ModelStringInput'] | undefined | null
    isBrandStoreScrapeEnabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    isSeller?: ValueTypes['ModelStringInput'] | undefined | null
    isVendor?: ValueTypes['ModelStringInput'] | undefined | null
    isWalmartScrapeEnabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    lastScrapedAt?: ValueTypes['ModelStringInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    nameAudioKey?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelBrandConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelBrandConditionInput'] | undefined | null> | undefined | null
    platform?: ValueTypes['ModelPlatformSourceInput'] | undefined | null
    postingGuidanceDocument?: ValueTypes['ModelStringInput'] | undefined | null
    region?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    socialMediaExampleUrls?: ValueTypes['ModelStringInput'] | undefined | null
    subscriptionEndsAt?: ValueTypes['ModelStringInput'] | undefined | null
    subscriptionStartsAt?: ValueTypes['ModelStringInput'] | undefined | null
    walmartBrandName?: ValueTypes['ModelStringInput'] | undefined | null
    walmartLastScrapedAt?: ValueTypes['ModelStringInput'] | undefined | null
    walmartScrapeUrl?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelBrandFilterInput']: {
    active?: ValueTypes['ModelStringInput'] | undefined | null
    amazonBrandName?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelBrandFilterInput'] | undefined | null> | undefined | null
    brandDetailsSubmitted?: ValueTypes['ModelBooleanInput'] | undefined | null
    brandInstaHandle?: ValueTypes['ModelStringInput'] | undefined | null
    brandLogoImageKey?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreConfirmationImage?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreConfirmationMobileImage?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreScrapeUrl?: ValueTypes['ModelStringInput'] | undefined | null
    brandTikTokHandle?: ValueTypes['ModelStringInput'] | undefined | null
    companyId?: ValueTypes['ModelIDInput'] | undefined | null
    contactEmails?: ValueTypes['ModelStringInput'] | undefined | null
    defaultCreatorPreference?: ValueTypes['ModelCreatorPreferenceInput'] | undefined | null
    defaultCreatorPreferenceDetails?: ValueTypes['ModelStringInput'] | undefined | null
    defaultHashtags?: ValueTypes['ModelStringInput'] | undefined | null
    defaultMarketingBriefKey?: ValueTypes['ModelStringInput'] | undefined | null
    defaultPostingGuidelines?: ValueTypes['ModelStringInput'] | undefined | null
    defaultPostingInspirationLinks?: ValueTypes['ModelStringInput'] | undefined | null
    emailsSent?: ValueTypes['ModelStringInput'] | undefined | null
    fullScrapedAt?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    isBrandStoreScrapeEnabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    isSeller?: ValueTypes['ModelStringInput'] | undefined | null
    isVendor?: ValueTypes['ModelStringInput'] | undefined | null
    isWalmartScrapeEnabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    lastScrapedAt?: ValueTypes['ModelStringInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    nameAudioKey?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelBrandFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelBrandFilterInput'] | undefined | null> | undefined | null
    platform?: ValueTypes['ModelPlatformSourceInput'] | undefined | null
    postingGuidanceDocument?: ValueTypes['ModelStringInput'] | undefined | null
    region?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    socialMediaExampleUrls?: ValueTypes['ModelStringInput'] | undefined | null
    subscriptionEndsAt?: ValueTypes['ModelStringInput'] | undefined | null
    subscriptionStartsAt?: ValueTypes['ModelStringInput'] | undefined | null
    walmartBrandName?: ValueTypes['ModelStringInput'] | undefined | null
    walmartLastScrapedAt?: ValueTypes['ModelStringInput'] | undefined | null
    walmartScrapeUrl?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelBrandSafeInput']: {
    eq?: ValueTypes['BrandSafe'] | undefined | null
    ne?: ValueTypes['BrandSafe'] | undefined | null
  }
  ['ModelCampaignClicksConditionInput']: {
    and?: Array<ValueTypes['ModelCampaignClicksConditionInput'] | undefined | null> | undefined | null
    clicks?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelCampaignClicksConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelCampaignClicksConditionInput'] | undefined | null> | undefined | null
  }
  ['ModelCampaignClicksFilterInput']: {
    and?: Array<ValueTypes['ModelCampaignClicksFilterInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    clicks?: ValueTypes['ModelIntInput'] | undefined | null
    date?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelCampaignClicksFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelCampaignClicksFilterInput'] | undefined | null> | undefined | null
  }
  ['ModelCampaignConditionInput']: {
    aboutSection?: ValueTypes['ModelStringInput'] | undefined | null
    active?: ValueTypes['ModelStringInput'] | undefined | null
    actualStartDate?: ValueTypes['ModelStringInput'] | undefined | null
    amountPaidByClient?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelCampaignConditionInput'] | undefined | null> | undefined | null
    anticipatedStartDate?: ValueTypes['ModelStringInput'] | undefined | null
    applicationForm?: ValueTypes['ModelStringInput'] | undefined | null
    applicationProductLinkId?: ValueTypes['ModelIDInput'] | undefined | null
    applicationStatus?: ValueTypes['ModelApplicationStatusInput'] | undefined | null
    attributionRefUrlSuffix?: ValueTypes['ModelStringInput'] | undefined | null
    authorizedUnits?: ValueTypes['ModelIntInput'] | undefined | null
    brandId?: ValueTypes['ModelIDInput'] | undefined | null
    brandProductShort?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreLink?: ValueTypes['ModelStringInput'] | undefined | null
    budgetSpent?: ValueTypes['ModelIntInput'] | undefined | null
    campaignProductType?: ValueTypes['ModelCampaignProductTypeInput'] | undefined | null
    campaignReferralBonusAmount?: ValueTypes['ModelIntInput'] | undefined | null
    campaignReferralBonusEnabled?: ValueTypes['ModelCampaignReferralBonusFlagInput'] | undefined | null
    campaignStateMachine?: ValueTypes['ModelStringInput'] | undefined | null
    closeoutReleaseFailureReason?: ValueTypes['ModelStringInput'] | undefined | null
    commissionFlatRate?: ValueTypes['ModelIntInput'] | undefined | null
    commissionPercentageRate?: ValueTypes['ModelIntInput'] | undefined | null
    committedClicks?: ValueTypes['ModelIntInput'] | undefined | null
    committedPlacementWinsCount?: ValueTypes['ModelIntInput'] | undefined | null
    committedReviewsCount?: ValueTypes['ModelIntInput'] | undefined | null
    contentApprovalRequired?: ValueTypes['ModelBooleanInput'] | undefined | null
    contentCompleteDeadline?: ValueTypes['ModelStringInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    creationSourceVersion?: ValueTypes['ModelCreationSourceVersionInput'] | undefined | null
    earlyReviewEnabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    enableContentComplianceReview?: ValueTypes['ModelBooleanInput'] | undefined | null
    endDate?: ValueTypes['ModelStringInput'] | undefined | null
    excludeGender?: ValueTypes['ModelUserFilterGenderInput'] | undefined | null
    expectedBonus?: ValueTypes['ModelStringInput'] | undefined | null
    followersCountCeiling?: ValueTypes['ModelIntInput'] | undefined | null
    followersCountFloor?: ValueTypes['ModelIntInput'] | undefined | null
    goal?: ValueTypes['ModelCampaignGoalInput'] | undefined | null
    influencerApprovalEnabled?: ValueTypes['ModelInfluencerApprovalFlagV2Input'] | undefined | null
    lifestyleCommittedContentCount?: ValueTypes['ModelIntInput'] | undefined | null
    localeV1?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    marketingFee?: ValueTypes['ModelIntInput'] | undefined | null
    marketingFormFilled?: ValueTypes['ModelBooleanInput'] | undefined | null
    newCreatorsOnly?: ValueTypes['ModelBooleanInput'] | undefined | null
    not?: ValueTypes['ModelCampaignConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelCampaignConditionInput'] | undefined | null> | undefined | null
    payForClicksEnabled?: ValueTypes['ModelEnabledFlagInput'] | undefined | null
    payForClicksMinClicks?: ValueTypes['ModelIntInput'] | undefined | null
    payForClicksOriginalLink?: ValueTypes['ModelStringInput'] | undefined | null
    payForClicksTotalClicksGenerated?: ValueTypes['ModelIntInput'] | undefined | null
    payForPerformanceEnabled?: ValueTypes['ModelPayForPerformanceFlagInput'] | undefined | null
    postingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    preApplicationProductImage?: ValueTypes['ModelStringInput'] | undefined | null
    preApplicationProductPriceRange?: ValueTypes['ModelStringInput'] | undefined | null
    preApplicationUgcProductImage?: ValueTypes['ModelStringInput'] | undefined | null
    preLaunchDate?: ValueTypes['ModelStringInput'] | undefined | null
    premiumLifestyleCommittedContentCount?: ValueTypes['ModelIntInput'] | undefined | null
    requiredProductLinksEnabled?: ValueTypes['ModelRequiredProductLinkFlagInput'] | undefined | null
    reviewVotingTarget?: ValueTypes['ModelStringInput'] | undefined | null
    sample?: ValueTypes['ModelBooleanInput'] | undefined | null
    shellCampaignBase?: ValueTypes['ModelIDInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    skuPriceOverride?: ValueTypes['ModelIntInput'] | undefined | null
    socialCommittedContentCount?: ValueTypes['ModelIntInput'] | undefined | null
    sourcingPriority?: ValueTypes['ModelIntInput'] | undefined | null
    sponsorshipInstructions?: ValueTypes['ModelStringInput'] | undefined | null
    title?: ValueTypes['ModelStringInput'] | undefined | null
    totalEarlyReviewsAllowed?: ValueTypes['ModelIntInput'] | undefined | null
    totalProductCost?: ValueTypes['ModelIntInput'] | undefined | null
    userAutoApproved?: ValueTypes['ModelBooleanInput'] | undefined | null
    visibleToUser?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignFilterInput']: {
    aboutSection?: ValueTypes['ModelStringInput'] | undefined | null
    active?: ValueTypes['ModelStringInput'] | undefined | null
    actualStartDate?: ValueTypes['ModelStringInput'] | undefined | null
    amountPaidByClient?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelCampaignFilterInput'] | undefined | null> | undefined | null
    anticipatedStartDate?: ValueTypes['ModelStringInput'] | undefined | null
    applicationForm?: ValueTypes['ModelStringInput'] | undefined | null
    applicationProductLinkId?: ValueTypes['ModelIDInput'] | undefined | null
    applicationStatus?: ValueTypes['ModelApplicationStatusInput'] | undefined | null
    attributionRefUrlSuffix?: ValueTypes['ModelStringInput'] | undefined | null
    authorizedUnits?: ValueTypes['ModelIntInput'] | undefined | null
    brandId?: ValueTypes['ModelIDInput'] | undefined | null
    brandProductShort?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreLink?: ValueTypes['ModelStringInput'] | undefined | null
    budgetSpent?: ValueTypes['ModelIntInput'] | undefined | null
    campaignProductType?: ValueTypes['ModelCampaignProductTypeInput'] | undefined | null
    campaignReferralBonusAmount?: ValueTypes['ModelIntInput'] | undefined | null
    campaignReferralBonusEnabled?: ValueTypes['ModelCampaignReferralBonusFlagInput'] | undefined | null
    campaignStateMachine?: ValueTypes['ModelStringInput'] | undefined | null
    closeoutReleaseFailureReason?: ValueTypes['ModelStringInput'] | undefined | null
    commissionFlatRate?: ValueTypes['ModelIntInput'] | undefined | null
    commissionPercentageRate?: ValueTypes['ModelIntInput'] | undefined | null
    committedClicks?: ValueTypes['ModelIntInput'] | undefined | null
    committedPlacementWinsCount?: ValueTypes['ModelIntInput'] | undefined | null
    committedReviewsCount?: ValueTypes['ModelIntInput'] | undefined | null
    contentApprovalRequired?: ValueTypes['ModelBooleanInput'] | undefined | null
    contentCompleteDeadline?: ValueTypes['ModelStringInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    creationSourceVersion?: ValueTypes['ModelCreationSourceVersionInput'] | undefined | null
    earlyReviewEnabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    enableContentComplianceReview?: ValueTypes['ModelBooleanInput'] | undefined | null
    endDate?: ValueTypes['ModelStringInput'] | undefined | null
    excludeGender?: ValueTypes['ModelUserFilterGenderInput'] | undefined | null
    expectedBonus?: ValueTypes['ModelStringInput'] | undefined | null
    followersCountCeiling?: ValueTypes['ModelIntInput'] | undefined | null
    followersCountFloor?: ValueTypes['ModelIntInput'] | undefined | null
    goal?: ValueTypes['ModelCampaignGoalInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    influencerApprovalEnabled?: ValueTypes['ModelInfluencerApprovalFlagV2Input'] | undefined | null
    lifestyleCommittedContentCount?: ValueTypes['ModelIntInput'] | undefined | null
    localeV1?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    marketingFee?: ValueTypes['ModelIntInput'] | undefined | null
    marketingFormFilled?: ValueTypes['ModelBooleanInput'] | undefined | null
    newCreatorsOnly?: ValueTypes['ModelBooleanInput'] | undefined | null
    not?: ValueTypes['ModelCampaignFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelCampaignFilterInput'] | undefined | null> | undefined | null
    payForClicksEnabled?: ValueTypes['ModelEnabledFlagInput'] | undefined | null
    payForClicksMinClicks?: ValueTypes['ModelIntInput'] | undefined | null
    payForClicksOriginalLink?: ValueTypes['ModelStringInput'] | undefined | null
    payForClicksTotalClicksGenerated?: ValueTypes['ModelIntInput'] | undefined | null
    payForPerformanceEnabled?: ValueTypes['ModelPayForPerformanceFlagInput'] | undefined | null
    postingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    preApplicationProductImage?: ValueTypes['ModelStringInput'] | undefined | null
    preApplicationProductPriceRange?: ValueTypes['ModelStringInput'] | undefined | null
    preApplicationUgcProductImage?: ValueTypes['ModelStringInput'] | undefined | null
    preLaunchDate?: ValueTypes['ModelStringInput'] | undefined | null
    premiumLifestyleCommittedContentCount?: ValueTypes['ModelIntInput'] | undefined | null
    requiredProductLinksEnabled?: ValueTypes['ModelRequiredProductLinkFlagInput'] | undefined | null
    reviewVotingTarget?: ValueTypes['ModelStringInput'] | undefined | null
    sample?: ValueTypes['ModelBooleanInput'] | undefined | null
    shellCampaignBase?: ValueTypes['ModelIDInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    skuPriceOverride?: ValueTypes['ModelIntInput'] | undefined | null
    socialCommittedContentCount?: ValueTypes['ModelIntInput'] | undefined | null
    sourcingPriority?: ValueTypes['ModelIntInput'] | undefined | null
    sponsorshipInstructions?: ValueTypes['ModelStringInput'] | undefined | null
    title?: ValueTypes['ModelStringInput'] | undefined | null
    totalEarlyReviewsAllowed?: ValueTypes['ModelIntInput'] | undefined | null
    totalProductCost?: ValueTypes['ModelIntInput'] | undefined | null
    userAutoApproved?: ValueTypes['ModelBooleanInput'] | undefined | null
    visibleToUser?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignGoalInput']: {
    eq?: ValueTypes['CampaignGoal'] | undefined | null
    ne?: ValueTypes['CampaignGoal'] | undefined | null
  }
  ['ModelCampaignInviteConditionInput']: {
    and?: Array<ValueTypes['ModelCampaignInviteConditionInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelCampaignInviteConditionInput'] | undefined | null
    notificationStatus?: ValueTypes['ModelNotificationStatusInput'] | undefined | null
    or?: Array<ValueTypes['ModelCampaignInviteConditionInput'] | undefined | null> | undefined | null
    priority?: ValueTypes['ModelIntInput'] | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignInviteFilterInput']: {
    and?: Array<ValueTypes['ModelCampaignInviteFilterInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelCampaignInviteFilterInput'] | undefined | null
    notificationStatus?: ValueTypes['ModelNotificationStatusInput'] | undefined | null
    or?: Array<ValueTypes['ModelCampaignInviteFilterInput'] | undefined | null> | undefined | null
    priority?: ValueTypes['ModelIntInput'] | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelCampaignProductTypeInput']: {
    eq?: ValueTypes['CampaignProductType'] | undefined | null
    ne?: ValueTypes['CampaignProductType'] | undefined | null
  }
  ['ModelCampaignReferralBonusFlagInput']: {
    eq?: ValueTypes['CampaignReferralBonusFlag'] | undefined | null
    ne?: ValueTypes['CampaignReferralBonusFlag'] | undefined | null
  }
  ['ModelCampaignSourcingProspectConditionInput']: {
    and?: Array<ValueTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    createdById?: ValueTypes['ModelIDInput'] | undefined | null
    instagramFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    instagramHandle?: ValueTypes['ModelStringInput'] | undefined | null
    internalNotesId?: ValueTypes['ModelIDInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null> | undefined | null
    platformInviteSource?: ValueTypes['ModelPlatformInviteSourceInput'] | undefined | null
    platformStatus?: ValueTypes['ModelPlatformStatusInput'] | undefined | null
    preApprovalStatus?: ValueTypes['ModelPreApprovalStatusInput'] | undefined | null
    preApprovalStatusById?: ValueTypes['ModelIDInput'] | undefined | null
    priority?: ValueTypes['ModelSourcePriorityInput'] | undefined | null
    productId?: ValueTypes['ModelStringInput'] | undefined | null
    sourcingSource?: ValueTypes['ModelCampaignSourcingSourceInput'] | undefined | null
    tiktokFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokHandle?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    youtubeChannelFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    youtubeChannelHandle?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignSourcingProspectFilterInput']: {
    and?: Array<ValueTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    createdById?: ValueTypes['ModelIDInput'] | undefined | null
    instagramFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    instagramHandle?: ValueTypes['ModelStringInput'] | undefined | null
    internalNotesId?: ValueTypes['ModelIDInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null> | undefined | null
    platformInviteSource?: ValueTypes['ModelPlatformInviteSourceInput'] | undefined | null
    platformStatus?: ValueTypes['ModelPlatformStatusInput'] | undefined | null
    preApprovalStatus?: ValueTypes['ModelPreApprovalStatusInput'] | undefined | null
    preApprovalStatusById?: ValueTypes['ModelIDInput'] | undefined | null
    priority?: ValueTypes['ModelSourcePriorityInput'] | undefined | null
    productId?: ValueTypes['ModelStringInput'] | undefined | null
    sourcingSource?: ValueTypes['ModelCampaignSourcingSourceInput'] | undefined | null
    tiktokFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokHandle?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    userIdentifier?: ValueTypes['ModelIDInput'] | undefined | null
    youtubeChannelFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    youtubeChannelHandle?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignSourcingSourceInput']: {
    eq?: ValueTypes['CampaignSourcingSource'] | undefined | null
    ne?: ValueTypes['CampaignSourcingSource'] | undefined | null
  }
  ['ModelCampaignStatusInput']: {
    eq?: ValueTypes['CampaignStatus'] | undefined | null
    ne?: ValueTypes['CampaignStatus'] | undefined | null
  }
  ['ModelChannelInput']: {
    eq?: ValueTypes['Channel'] | undefined | null
    ne?: ValueTypes['Channel'] | undefined | null
  }
  ['ModelChatConversationV2ConditionInput']: {
    active?: ValueTypes['ModelActiveInput'] | undefined | null
    adminLastReadMessageIndex?: ValueTypes['ModelIntInput'] | undefined | null
    adminUnreadCount?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelChatConversationV2ConditionInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelStringInput'] | undefined | null
    creatorLastReadMessageIndex?: ValueTypes['ModelIntInput'] | undefined | null
    creatorUnreadCount?: ValueTypes['ModelIntInput'] | undefined | null
    lastMessage?: ValueTypes['ModelStringInput'] | undefined | null
    lastMessageDate?: ValueTypes['ModelStringInput'] | undefined | null
    lastMessageId?: ValueTypes['ModelStringInput'] | undefined | null
    lastMessageSource?: ValueTypes['ModelConversationMessageSourceInput'] | undefined | null
    not?: ValueTypes['ModelChatConversationV2ConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelChatConversationV2ConditionInput'] | undefined | null> | undefined | null
    status?: ValueTypes['ModelConversationInternalStatusInput'] | undefined | null
    statusUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    statusUpdatedByFirstName?: ValueTypes['ModelStringInput'] | undefined | null
    statusUpdatedById?: ValueTypes['ModelStringInput'] | undefined | null
    statusUpdatedByLastName?: ValueTypes['ModelStringInput'] | undefined | null
    twilioConversationId?: ValueTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelStringInput'] | undefined | null
    userEmail?: ValueTypes['ModelStringInput'] | undefined | null
    userFirstName?: ValueTypes['ModelStringInput'] | undefined | null
    userLastName?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelChatConversationV2FilterInput']: {
    active?: ValueTypes['ModelActiveInput'] | undefined | null
    adminLastReadMessageIndex?: ValueTypes['ModelIntInput'] | undefined | null
    adminUnreadCount?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelChatConversationV2FilterInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelStringInput'] | undefined | null
    chatType?: ValueTypes['ModelChatTypeInput'] | undefined | null
    creatorLastReadMessageIndex?: ValueTypes['ModelIntInput'] | undefined | null
    creatorUnreadCount?: ValueTypes['ModelIntInput'] | undefined | null
    lastMessage?: ValueTypes['ModelStringInput'] | undefined | null
    lastMessageDate?: ValueTypes['ModelStringInput'] | undefined | null
    lastMessageId?: ValueTypes['ModelStringInput'] | undefined | null
    lastMessageSource?: ValueTypes['ModelConversationMessageSourceInput'] | undefined | null
    not?: ValueTypes['ModelChatConversationV2FilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelChatConversationV2FilterInput'] | undefined | null> | undefined | null
    resourceId?: ValueTypes['ModelIDInput'] | undefined | null
    status?: ValueTypes['ModelConversationInternalStatusInput'] | undefined | null
    statusUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    statusUpdatedByFirstName?: ValueTypes['ModelStringInput'] | undefined | null
    statusUpdatedById?: ValueTypes['ModelStringInput'] | undefined | null
    statusUpdatedByLastName?: ValueTypes['ModelStringInput'] | undefined | null
    twilioConversationId?: ValueTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelStringInput'] | undefined | null
    userEmail?: ValueTypes['ModelStringInput'] | undefined | null
    userFirstName?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
    userLastName?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelChatTypeInput']: {
    eq?: ValueTypes['ChatType'] | undefined | null
    ne?: ValueTypes['ChatType'] | undefined | null
  }
  ['ModelCoarseCampaignStatusInput']: {
    eq?: ValueTypes['CoarseCampaignStatus'] | undefined | null
    ne?: ValueTypes['CoarseCampaignStatus'] | undefined | null
  }
  ['ModelCommunicationNotificationConditionInput']: {
    address?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelCommunicationNotificationConditionInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    channel?: ValueTypes['ModelChannelInput'] | undefined | null
    expectedSentAt?: ValueTypes['ModelStringInput'] | undefined | null
    experimentTreatment?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelCommunicationNotificationConditionInput'] | undefined | null
    notificationId?: ValueTypes['ModelStringInput'] | undefined | null
    or?: Array<ValueTypes['ModelCommunicationNotificationConditionInput'] | undefined | null> | undefined | null
    sentAt?: ValueTypes['ModelStringInput'] | undefined | null
    status?: ValueTypes['ModelStringInput'] | undefined | null
    statusMessage?: ValueTypes['ModelStringInput'] | undefined | null
    templateId?: ValueTypes['ModelStringInput'] | undefined | null
    traceId?: ValueTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelCommunicationNotificationFilterInput']: {
    address?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelCommunicationNotificationFilterInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    channel?: ValueTypes['ModelChannelInput'] | undefined | null
    expectedSentAt?: ValueTypes['ModelStringInput'] | undefined | null
    experimentTreatment?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
    notificationId?: ValueTypes['ModelStringInput'] | undefined | null
    or?: Array<ValueTypes['ModelCommunicationNotificationFilterInput'] | undefined | null> | undefined | null
    sentAt?: ValueTypes['ModelStringInput'] | undefined | null
    status?: ValueTypes['ModelStringInput'] | undefined | null
    statusMessage?: ValueTypes['ModelStringInput'] | undefined | null
    templateId?: ValueTypes['ModelStringInput'] | undefined | null
    traceId?: ValueTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelCompanyConditionInput']: {
    agencyId?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelCompanyConditionInput'] | undefined | null> | undefined | null
    logo?: ValueTypes['ModelStringInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelCompanyConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelCompanyConditionInput'] | undefined | null> | undefined | null
    paymentTermsCustomNetDays?: ValueTypes['ModelIntInput'] | undefined | null
    paymentTermsType?: ValueTypes['ModelPaymentTermsTypeInput'] | undefined | null
    subscriptionEndsAt?: ValueTypes['ModelStringInput'] | undefined | null
    subscriptionStartsAt?: ValueTypes['ModelStringInput'] | undefined | null
    websiteUrl?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCompanyFilterInput']: {
    agencyId?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelCompanyFilterInput'] | undefined | null> | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    logo?: ValueTypes['ModelStringInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelCompanyFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelCompanyFilterInput'] | undefined | null> | undefined | null
    paymentTermsCustomNetDays?: ValueTypes['ModelIntInput'] | undefined | null
    paymentTermsType?: ValueTypes['ModelPaymentTermsTypeInput'] | undefined | null
    subscriptionEndsAt?: ValueTypes['ModelStringInput'] | undefined | null
    subscriptionStartsAt?: ValueTypes['ModelStringInput'] | undefined | null
    websiteUrl?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelConnectedAccountConditionInput']: {
    accountType?: ValueTypes['ModelConnectedAccountTypeInput'] | undefined | null
    and?: Array<ValueTypes['ModelConnectedAccountConditionInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelConnectedAccountConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelConnectedAccountConditionInput'] | undefined | null> | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelConnectedAccountFilterInput']: {
    accountId?: ValueTypes['ModelIDInput'] | undefined | null
    accountType?: ValueTypes['ModelConnectedAccountTypeInput'] | undefined | null
    and?: Array<ValueTypes['ModelConnectedAccountFilterInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelConnectedAccountFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelConnectedAccountFilterInput'] | undefined | null> | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelConnectedAccountTypeInput']: {
    eq?: ValueTypes['ConnectedAccountType'] | undefined | null
    ne?: ValueTypes['ConnectedAccountType'] | undefined | null
  }
  ['ModelContentApprovalStatusInput']: {
    eq?: ValueTypes['ContentApprovalStatus'] | undefined | null
    ne?: ValueTypes['ContentApprovalStatus'] | undefined | null
  }
  ['ModelContentComplianceReviewStatusInput']: {
    eq?: ValueTypes['ContentComplianceReviewStatus'] | undefined | null
    ne?: ValueTypes['ContentComplianceReviewStatus'] | undefined | null
  }
  ['ModelContentRatingReviewStatusInput']: {
    eq?: ValueTypes['ContentRatingReviewStatus'] | undefined | null
    ne?: ValueTypes['ContentRatingReviewStatus'] | undefined | null
  }
  ['ModelContentRequirementLevelInput']: {
    eq?: ValueTypes['ContentRequirementLevel'] | undefined | null
    ne?: ValueTypes['ContentRequirementLevel'] | undefined | null
  }
  ['ModelContentScanStatusInput']: {
    eq?: ValueTypes['ContentScanStatus'] | undefined | null
    ne?: ValueTypes['ContentScanStatus'] | undefined | null
  }
  ['ModelContentTypeRatingListInput']: {
    contains?: ValueTypes['ContentTypeRating'] | undefined | null
    eq?: Array<ValueTypes['ContentTypeRating'] | undefined | null> | undefined | null
    ne?: Array<ValueTypes['ContentTypeRating'] | undefined | null> | undefined | null
    notContains?: ValueTypes['ContentTypeRating'] | undefined | null
  }
  ['ModelConversationChannelInput']: {
    eq?: ValueTypes['ConversationChannel'] | undefined | null
    ne?: ValueTypes['ConversationChannel'] | undefined | null
  }
  ['ModelConversationConditionInput']: {
    and?: Array<ValueTypes['ModelConversationConditionInput'] | undefined | null> | undefined | null
    contactOwnerId?: ValueTypes['ModelIDInput'] | undefined | null
    lastContactAt?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelConversationConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelConversationConditionInput'] | undefined | null> | undefined | null
    status?: ValueTypes['ModelConversationStatusInput'] | undefined | null
    statusChangedAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelConversationFilterInput']: {
    and?: Array<ValueTypes['ModelConversationFilterInput'] | undefined | null> | undefined | null
    contactOwnerId?: ValueTypes['ModelIDInput'] | undefined | null
    lastContactAt?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelConversationFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelConversationFilterInput'] | undefined | null> | undefined | null
    status?: ValueTypes['ModelConversationStatusInput'] | undefined | null
    statusChangedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelConversationInternalStatusInput']: {
    eq?: ValueTypes['ConversationInternalStatus'] | undefined | null
    ne?: ValueTypes['ConversationInternalStatus'] | undefined | null
  }
  ['ModelConversationMessageConditionInput']: {
    and?: Array<ValueTypes['ModelConversationMessageConditionInput'] | undefined | null> | undefined | null
    channel?: ValueTypes['ModelConversationChannelInput'] | undefined | null
    messageContent?: ValueTypes['ModelStringInput'] | undefined | null
    messageId?: ValueTypes['ModelStringInput'] | undefined | null
    messageMediaKeys?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelConversationMessageConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelConversationMessageConditionInput'] | undefined | null> | undefined | null
    replyFromId?: ValueTypes['ModelIDInput'] | undefined | null
    sendAt?: ValueTypes['ModelStringInput'] | undefined | null
    sendAttempts?: ValueTypes['ModelIntInput'] | undefined | null
    sentAt?: ValueTypes['ModelStringInput'] | undefined | null
    status?: ValueTypes['ModelConversationMessageStatusInput'] | undefined | null
  }
  ['ModelConversationMessageFilterInput']: {
    and?: Array<ValueTypes['ModelConversationMessageFilterInput'] | undefined | null> | undefined | null
    channel?: ValueTypes['ModelConversationChannelInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    messageContent?: ValueTypes['ModelStringInput'] | undefined | null
    messageId?: ValueTypes['ModelStringInput'] | undefined | null
    messageMediaKeys?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelConversationMessageFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelConversationMessageFilterInput'] | undefined | null> | undefined | null
    replyFromId?: ValueTypes['ModelIDInput'] | undefined | null
    sendAt?: ValueTypes['ModelStringInput'] | undefined | null
    sendAttempts?: ValueTypes['ModelIntInput'] | undefined | null
    sentAt?: ValueTypes['ModelStringInput'] | undefined | null
    status?: ValueTypes['ModelConversationMessageStatusInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelConversationMessageSourceInput']: {
    eq?: ValueTypes['ConversationMessageSource'] | undefined | null
    ne?: ValueTypes['ConversationMessageSource'] | undefined | null
  }
  ['ModelConversationMessageStatusInput']: {
    eq?: ValueTypes['ConversationMessageStatus'] | undefined | null
    ne?: ValueTypes['ConversationMessageStatus'] | undefined | null
  }
  ['ModelConversationStatusInput']: {
    eq?: ValueTypes['ConversationStatus'] | undefined | null
    ne?: ValueTypes['ConversationStatus'] | undefined | null
  }
  ['ModelCreationSourceVersionInput']: {
    eq?: ValueTypes['CreationSourceVersion'] | undefined | null
    ne?: ValueTypes['CreationSourceVersion'] | undefined | null
  }
  ['ModelCreatorPreferenceInput']: {
    eq?: ValueTypes['CreatorPreference'] | undefined | null
    ne?: ValueTypes['CreatorPreference'] | undefined | null
  }
  ['ModelCurrencyInput']: {
    eq?: ValueTypes['Currency'] | undefined | null
    ne?: ValueTypes['Currency'] | undefined | null
  }
  ['ModelDeranked_ReasonInput']: {
    eq?: ValueTypes['Deranked_Reason'] | undefined | null
    ne?: ValueTypes['Deranked_Reason'] | undefined | null
  }
  ['ModelDraftStatusInput']: {
    eq?: ValueTypes['DraftStatus'] | undefined | null
    ne?: ValueTypes['DraftStatus'] | undefined | null
  }
  ['ModelEnabledFlagInput']: {
    eq?: ValueTypes['EnabledFlag'] | undefined | null
    ne?: ValueTypes['EnabledFlag'] | undefined | null
  }
  ['ModelEtailerSkuMetricsConditionInput']: {
    and?: Array<ValueTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null> | undefined | null
    not?: ValueTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null> | undefined | null
    pageViews?: ValueTypes['ModelIntInput'] | undefined | null
    sku?: ValueTypes['ModelStringInput'] | undefined | null
    totalSalesAmount?: ValueTypes['ModelIntInput'] | undefined | null
    unitsSold?: ValueTypes['ModelIntInput'] | undefined | null
  }
  ['ModelEtailerSkuMetricsFilterInput']: {
    and?: Array<ValueTypes['ModelEtailerSkuMetricsFilterInput'] | undefined | null> | undefined | null
    date?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelEtailerSkuMetricsFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelEtailerSkuMetricsFilterInput'] | undefined | null> | undefined | null
    pageViews?: ValueTypes['ModelIntInput'] | undefined | null
    sku?: ValueTypes['ModelStringInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    totalSalesAmount?: ValueTypes['ModelIntInput'] | undefined | null
    unitsSold?: ValueTypes['ModelIntInput'] | undefined | null
  }
  ['ModelFavoriteFlagInput']: {
    eq?: ValueTypes['FavoriteFlag'] | undefined | null
    ne?: ValueTypes['FavoriteFlag'] | undefined | null
  }
  ['ModelFloatInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ValueTypes['ModelAttributeTypes'] | undefined | null
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
    ne?: number | undefined | null
  }
  ['ModelFloatKeyConditionInput']: {
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
  }
  ['ModelFormSequenceConfigurationConditionInput']: {
    active?: ValueTypes['ModelActiveInput'] | undefined | null
    and?: Array<ValueTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null> | undefined | null
    not?: ValueTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null> | undefined | null
    sequenceName?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelFormSequenceConfigurationFilterInput']: {
    active?: ValueTypes['ModelActiveInput'] | undefined | null
    and?: Array<ValueTypes['ModelFormSequenceConfigurationFilterInput'] | undefined | null> | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelFormSequenceConfigurationFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelFormSequenceConfigurationFilterInput'] | undefined | null> | undefined | null
    sequenceName?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelFraudReasonInput']: {
    eq?: ValueTypes['FraudReason'] | undefined | null
    ne?: ValueTypes['FraudReason'] | undefined | null
  }
  ['ModelFraudReviewStatusInput']: {
    eq?: ValueTypes['FraudReviewStatus'] | undefined | null
    ne?: ValueTypes['FraudReviewStatus'] | undefined | null
  }
  ['ModelIDInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ValueTypes['ModelAttributeTypes'] | undefined | null
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    contains?: string | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
    ne?: string | undefined | null
    notContains?: string | undefined | null
    size?: ValueTypes['ModelSizeInput'] | undefined | null
  }
  ['ModelIDKeyConditionInput']: {
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
  }
  ['ModelInfluencerApprovalFlagV2Input']: {
    eq?: ValueTypes['InfluencerApprovalFlagV2'] | undefined | null
    ne?: ValueTypes['InfluencerApprovalFlagV2'] | undefined | null
  }
  ['ModelInstagramStoryScreenConditionInput']: {
    and?: Array<ValueTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null> | undefined | null
    exits?: ValueTypes['ModelIntInput'] | undefined | null
    expirationUnixTime?: ValueTypes['ModelIntInput'] | undefined | null
    externalId?: ValueTypes['ModelStringInput'] | undefined | null
    impressions?: ValueTypes['ModelIntInput'] | undefined | null
    instagramUserId?: ValueTypes['ModelIDInput'] | undefined | null
    mediaId?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null> | undefined | null
    postedAt?: ValueTypes['ModelStringInput'] | undefined | null
    postingDate?: ValueTypes['ModelStringInput'] | undefined | null
    reach?: ValueTypes['ModelIntInput'] | undefined | null
    replies?: ValueTypes['ModelIntInput'] | undefined | null
    s3Bucket?: ValueTypes['ModelStringInput'] | undefined | null
    tapsBack?: ValueTypes['ModelIntInput'] | undefined | null
    tapsForward?: ValueTypes['ModelIntInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelInstagramStoryScreenFilterInput']: {
    and?: Array<ValueTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null> | undefined | null
    exits?: ValueTypes['ModelIntInput'] | undefined | null
    expirationUnixTime?: ValueTypes['ModelIntInput'] | undefined | null
    externalId?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    impressions?: ValueTypes['ModelIntInput'] | undefined | null
    instagramUserId?: ValueTypes['ModelIDInput'] | undefined | null
    mediaId?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null> | undefined | null
    postedAt?: ValueTypes['ModelStringInput'] | undefined | null
    postingDate?: ValueTypes['ModelStringInput'] | undefined | null
    reach?: ValueTypes['ModelIntInput'] | undefined | null
    replies?: ValueTypes['ModelIntInput'] | undefined | null
    s3Bucket?: ValueTypes['ModelStringInput'] | undefined | null
    tapsBack?: ValueTypes['ModelIntInput'] | undefined | null
    tapsForward?: ValueTypes['ModelIntInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelIntInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ValueTypes['ModelAttributeTypes'] | undefined | null
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
    ne?: number | undefined | null
  }
  ['ModelIntKeyConditionInput']: {
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
  }
  ['ModelItemClassificationInput']: {
    eq?: ValueTypes['ItemClassification'] | undefined | null
    ne?: ValueTypes['ItemClassification'] | undefined | null
  }
  ['ModelKeywordAcceptanceResponseInput']: {
    eq?: ValueTypes['KeywordAcceptanceResponse'] | undefined | null
    ne?: ValueTypes['KeywordAcceptanceResponse'] | undefined | null
  }
  ['ModelLinkStorageTypeInput']: {
    eq?: ValueTypes['LinkStorageType'] | undefined | null
    ne?: ValueTypes['LinkStorageType'] | undefined | null
  }
  ['ModelListingFulfillmentTypeInput']: {
    eq?: ValueTypes['ListingFulfillmentType'] | undefined | null
    ne?: ValueTypes['ListingFulfillmentType'] | undefined | null
  }
  ['ModelManualUploadTypeInput']: {
    eq?: ValueTypes['ManualUploadType'] | undefined | null
    ne?: ValueTypes['ManualUploadType'] | undefined | null
  }
  ['ModelMessageConditionInput']: {
    and?: Array<ValueTypes['ModelMessageConditionInput'] | undefined | null> | undefined | null
    body?: ValueTypes['ModelStringInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelMessageConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelMessageConditionInput'] | undefined | null> | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    userHasSeen?: ValueTypes['ModelBooleanInput'] | undefined | null
  }
  ['ModelMessageFilterInput']: {
    and?: Array<ValueTypes['ModelMessageFilterInput'] | undefined | null> | undefined | null
    body?: ValueTypes['ModelStringInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelMessageFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelMessageFilterInput'] | undefined | null> | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    userHasSeen?: ValueTypes['ModelBooleanInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelNoteConditionInput']: {
    and?: Array<ValueTypes['ModelNoteConditionInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelNoteConditionInput'] | undefined | null
    note?: ValueTypes['ModelStringInput'] | undefined | null
    or?: Array<ValueTypes['ModelNoteConditionInput'] | undefined | null> | undefined | null
    parentId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelNoteFilterInput']: {
    and?: Array<ValueTypes['ModelNoteFilterInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelNoteFilterInput'] | undefined | null
    note?: ValueTypes['ModelStringInput'] | undefined | null
    or?: Array<ValueTypes['ModelNoteFilterInput'] | undefined | null> | undefined | null
    parentId?: ValueTypes['ModelIDInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelNotificationConfigurationConditionInput']: {
    and?: Array<ValueTypes['ModelNotificationConfigurationConditionInput'] | undefined | null> | undefined | null
    category?: ValueTypes['ModelStringInput'] | undefined | null
    description?: ValueTypes['ModelStringInput'] | undefined | null
    enabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelNotificationConfigurationConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelNotificationConfigurationConditionInput'] | undefined | null> | undefined | null
  }
  ['ModelNotificationConfigurationFilterInput']: {
    and?: Array<ValueTypes['ModelNotificationConfigurationFilterInput'] | undefined | null> | undefined | null
    category?: ValueTypes['ModelStringInput'] | undefined | null
    description?: ValueTypes['ModelStringInput'] | undefined | null
    enabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelNotificationConfigurationFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelNotificationConfigurationFilterInput'] | undefined | null> | undefined | null
  }
  ['ModelNotificationStatusInput']: {
    eq?: ValueTypes['NotificationStatus'] | undefined | null
    ne?: ValueTypes['NotificationStatus'] | undefined | null
  }
  ['ModelOrderIdValidationStatusInput']: {
    eq?: ValueTypes['OrderIdValidationStatus'] | undefined | null
    ne?: ValueTypes['OrderIdValidationStatus'] | undefined | null
  }
  ['ModelPayForPerformanceFlagInput']: {
    eq?: ValueTypes['PayForPerformanceFlag'] | undefined | null
    ne?: ValueTypes['PayForPerformanceFlag'] | undefined | null
  }
  ['ModelPaymentTermsTypeInput']: {
    eq?: ValueTypes['PaymentTermsType'] | undefined | null
    ne?: ValueTypes['PaymentTermsType'] | undefined | null
  }
  ['ModelPlatformInviteSourceInput']: {
    eq?: ValueTypes['PlatformInviteSource'] | undefined | null
    ne?: ValueTypes['PlatformInviteSource'] | undefined | null
  }
  ['ModelPlatformPriorityInput']: {
    eq?: ValueTypes['PlatformPriority'] | undefined | null
    ne?: ValueTypes['PlatformPriority'] | undefined | null
  }
  ['ModelPlatformSourceInput']: {
    eq?: ValueTypes['PlatformSource'] | undefined | null
    ne?: ValueTypes['PlatformSource'] | undefined | null
  }
  ['ModelPlatformStatusInput']: {
    eq?: ValueTypes['PlatformStatus'] | undefined | null
    ne?: ValueTypes['PlatformStatus'] | undefined | null
  }
  ['ModelPreApprovalStatusInput']: {
    eq?: ValueTypes['PreApprovalStatus'] | undefined | null
    ne?: ValueTypes['PreApprovalStatus'] | undefined | null
  }
  ['ModelProductBonusConfigSnapshotConditionInput']: {
    and?: Array<ValueTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null> | undefined | null
    closeoutSurveyBonus?: ValueTypes['ModelIntInput'] | undefined | null
    discoveryBonus?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null> | undefined | null
  }
  ['ModelProductBonusConfigSnapshotFilterInput']: {
    and?: Array<ValueTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null> | undefined | null
    closeoutSurveyBonus?: ValueTypes['ModelIntInput'] | undefined | null
    discoveryBonus?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null> | undefined | null
    productId?: ValueTypes['ModelIDInput'] | undefined | null
    snapshotAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelProductConditionInput']: {
    accountRequired?: ValueTypes['ModelBooleanInput'] | undefined | null
    active?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelProductConditionInput'] | undefined | null> | undefined | null
    autoApprovalSeatsAvailable?: ValueTypes['ModelIntInput'] | undefined | null
    autoApprovalStatusTiers?: ValueTypes['ModelStatusTierListInput'] | undefined | null
    automaticReviewRelease?: ValueTypes['ModelBooleanInput'] | undefined | null
    baseBonus?: ValueTypes['ModelIntInput'] | undefined | null
    brandAdvocateCount?: ValueTypes['ModelIntInput'] | undefined | null
    brandTalkingPoints?: ValueTypes['ModelStringInput'] | undefined | null
    buyOnlinePickupInStore?: ValueTypes['ModelBOPISValueInput'] | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    campaignStateMachine?: ValueTypes['ModelStringInput'] | undefined | null
    closeoutFormBonus?: ValueTypes['ModelIntInput'] | undefined | null
    contentApprovalLeadDays?: ValueTypes['ModelIntInput'] | undefined | null
    creatorCount?: ValueTypes['ModelIntInput'] | undefined | null
    creatorPreference?: ValueTypes['ModelCreatorPreferenceInput'] | undefined | null
    creatorPreferenceDetails?: ValueTypes['ModelStringInput'] | undefined | null
    customFtcHashtags?: ValueTypes['ModelStringInput'] | undefined | null
    customHashtags?: ValueTypes['ModelStringInput'] | undefined | null
    directPurchaseLink?: ValueTypes['ModelStringInput'] | undefined | null
    hideBrandContentApprovals?: ValueTypes['ModelBooleanInput'] | undefined | null
    instaHandle?: ValueTypes['ModelStringInput'] | undefined | null
    internalCreatorsStagingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    internalDescription?: ValueTypes['ModelStringInput'] | undefined | null
    internalName?: ValueTypes['ModelStringInput'] | undefined | null
    internalNotes?: ValueTypes['ModelStringInput'] | undefined | null
    isDirectListingPurchase?: ValueTypes['ModelBooleanInput'] | undefined | null
    isQualityRequirementsEnabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    listingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    maxDiscoveryBonus?: ValueTypes['ModelIntInput'] | undefined | null
    minimumCreatorQualityScore?: ValueTypes['ModelIntInput'] | undefined | null
    nonRetailReviewLink?: ValueTypes['ModelStringInput'] | undefined | null
    nonRetailReviewWebsiteName?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelProductConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelProductConditionInput'] | undefined | null> | undefined | null
    payForClicksEnabled?: ValueTypes['ModelEnabledFlagInput'] | undefined | null
    payForClicksMinClicks?: ValueTypes['ModelIntInput'] | undefined | null
    postingDeadlineDaysRelativeToApproval?: ValueTypes['ModelIntInput'] | undefined | null
    productLinkId?: ValueTypes['ModelIDInput'] | undefined | null
    productPostingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    productPostingGuidanceDocument?: ValueTypes['ModelStringInput'] | undefined | null
    promoCode?: ValueTypes['ModelStringInput'] | undefined | null
    promoCodeGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    promoCodeType?: ValueTypes['ModelPromoCodeTypeInput'] | undefined | null
    promoDiscountAmount?: ValueTypes['ModelFloatInput'] | undefined | null
    promoDiscountType?: ValueTypes['ModelPromoDiscountTypeInput'] | undefined | null
    promoEndDate?: ValueTypes['ModelStringInput'] | undefined | null
    promoLink?: ValueTypes['ModelStringInput'] | undefined | null
    promoStartDate?: ValueTypes['ModelStringInput'] | undefined | null
    promotionLinkId?: ValueTypes['ModelIDInput'] | undefined | null
    purchaseByPromoCode?: ValueTypes['ModelBooleanInput'] | undefined | null
    purchaseDiscountCode?: ValueTypes['ModelStringInput'] | undefined | null
    purchaseDiscountCodeDetails?: ValueTypes['ModelStringInput'] | undefined | null
    purchaseDiscountCodePercentage?: ValueTypes['ModelStringInput'] | undefined | null
    qualityLevel?: ValueTypes['ModelProductQualityLevelInput'] | undefined | null
    requestEarlyProductFeedback?: ValueTypes['ModelBooleanInput'] | undefined | null
    requestProductFeedback?: ValueTypes['ModelBooleanInput'] | undefined | null
    requiredProductLinkEnabled?: ValueTypes['ModelRequiredProductLinkFlagInput'] | undefined | null
    sequenceId?: ValueTypes['ModelIDInput'] | undefined | null
    shopifyPriceRule?: ValueTypes['ModelStringInput'] | undefined | null
    shopifyPromotionId?: ValueTypes['ModelStringInput'] | undefined | null
    shopifyPromotionType?: ValueTypes['ModelShopifyPromotionTypeInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    socialMediaExampleUrls?: ValueTypes['ModelStringInput'] | undefined | null
    stagingApprovalRequiredNumCreatorsClientReviewed?: ValueTypes['ModelIntInput'] | undefined | null
    stagingRequirementType?: ValueTypes['ModelStagingRequirementTypeInput'] | undefined | null
    stagingSocialQualityRequirement?: ValueTypes['ModelSocialQualityInput'] | undefined | null
    tikTokHandle?: ValueTypes['ModelStringInput'] | undefined | null
    unitsPurchased?: ValueTypes['ModelIntInput'] | undefined | null
    validateProductFoundVariations?: ValueTypes['ModelStringInput'] | undefined | null
    visibleAt?: ValueTypes['ModelStringInput'] | undefined | null
    visibleEndsAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelProductFilterInput']: {
    accountRequired?: ValueTypes['ModelBooleanInput'] | undefined | null
    active?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelProductFilterInput'] | undefined | null> | undefined | null
    autoApprovalSeatsAvailable?: ValueTypes['ModelIntInput'] | undefined | null
    autoApprovalStatusTiers?: ValueTypes['ModelStatusTierListInput'] | undefined | null
    automaticReviewRelease?: ValueTypes['ModelBooleanInput'] | undefined | null
    baseBonus?: ValueTypes['ModelIntInput'] | undefined | null
    brandAdvocateCount?: ValueTypes['ModelIntInput'] | undefined | null
    brandTalkingPoints?: ValueTypes['ModelStringInput'] | undefined | null
    buyOnlinePickupInStore?: ValueTypes['ModelBOPISValueInput'] | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    campaignStateMachine?: ValueTypes['ModelStringInput'] | undefined | null
    closeoutFormBonus?: ValueTypes['ModelIntInput'] | undefined | null
    contentApprovalLeadDays?: ValueTypes['ModelIntInput'] | undefined | null
    creatorCount?: ValueTypes['ModelIntInput'] | undefined | null
    creatorPreference?: ValueTypes['ModelCreatorPreferenceInput'] | undefined | null
    creatorPreferenceDetails?: ValueTypes['ModelStringInput'] | undefined | null
    customFtcHashtags?: ValueTypes['ModelStringInput'] | undefined | null
    customHashtags?: ValueTypes['ModelStringInput'] | undefined | null
    directPurchaseLink?: ValueTypes['ModelStringInput'] | undefined | null
    hideBrandContentApprovals?: ValueTypes['ModelBooleanInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    instaHandle?: ValueTypes['ModelStringInput'] | undefined | null
    internalCreatorsStagingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    internalDescription?: ValueTypes['ModelStringInput'] | undefined | null
    internalName?: ValueTypes['ModelStringInput'] | undefined | null
    internalNotes?: ValueTypes['ModelStringInput'] | undefined | null
    isDirectListingPurchase?: ValueTypes['ModelBooleanInput'] | undefined | null
    isQualityRequirementsEnabled?: ValueTypes['ModelBooleanInput'] | undefined | null
    listingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    maxDiscoveryBonus?: ValueTypes['ModelIntInput'] | undefined | null
    minimumCreatorQualityScore?: ValueTypes['ModelIntInput'] | undefined | null
    nonRetailReviewLink?: ValueTypes['ModelStringInput'] | undefined | null
    nonRetailReviewWebsiteName?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelProductFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelProductFilterInput'] | undefined | null> | undefined | null
    payForClicksEnabled?: ValueTypes['ModelEnabledFlagInput'] | undefined | null
    payForClicksMinClicks?: ValueTypes['ModelIntInput'] | undefined | null
    postingDeadlineDaysRelativeToApproval?: ValueTypes['ModelIntInput'] | undefined | null
    productLinkId?: ValueTypes['ModelIDInput'] | undefined | null
    productPostingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    productPostingGuidanceDocument?: ValueTypes['ModelStringInput'] | undefined | null
    promoCode?: ValueTypes['ModelStringInput'] | undefined | null
    promoCodeGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    promoCodeType?: ValueTypes['ModelPromoCodeTypeInput'] | undefined | null
    promoDiscountAmount?: ValueTypes['ModelFloatInput'] | undefined | null
    promoDiscountType?: ValueTypes['ModelPromoDiscountTypeInput'] | undefined | null
    promoEndDate?: ValueTypes['ModelStringInput'] | undefined | null
    promoLink?: ValueTypes['ModelStringInput'] | undefined | null
    promoStartDate?: ValueTypes['ModelStringInput'] | undefined | null
    promotionLinkId?: ValueTypes['ModelIDInput'] | undefined | null
    purchaseByPromoCode?: ValueTypes['ModelBooleanInput'] | undefined | null
    purchaseDiscountCode?: ValueTypes['ModelStringInput'] | undefined | null
    purchaseDiscountCodeDetails?: ValueTypes['ModelStringInput'] | undefined | null
    purchaseDiscountCodePercentage?: ValueTypes['ModelStringInput'] | undefined | null
    qualityLevel?: ValueTypes['ModelProductQualityLevelInput'] | undefined | null
    requestEarlyProductFeedback?: ValueTypes['ModelBooleanInput'] | undefined | null
    requestProductFeedback?: ValueTypes['ModelBooleanInput'] | undefined | null
    requiredProductLinkEnabled?: ValueTypes['ModelRequiredProductLinkFlagInput'] | undefined | null
    sequenceId?: ValueTypes['ModelIDInput'] | undefined | null
    shopifyPriceRule?: ValueTypes['ModelStringInput'] | undefined | null
    shopifyPromotionId?: ValueTypes['ModelStringInput'] | undefined | null
    shopifyPromotionType?: ValueTypes['ModelShopifyPromotionTypeInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    socialMediaExampleUrls?: ValueTypes['ModelStringInput'] | undefined | null
    stagingApprovalRequiredNumCreatorsClientReviewed?: ValueTypes['ModelIntInput'] | undefined | null
    stagingRequirementType?: ValueTypes['ModelStagingRequirementTypeInput'] | undefined | null
    stagingSocialQualityRequirement?: ValueTypes['ModelSocialQualityInput'] | undefined | null
    tikTokHandle?: ValueTypes['ModelStringInput'] | undefined | null
    unitsPurchased?: ValueTypes['ModelIntInput'] | undefined | null
    validateProductFoundVariations?: ValueTypes['ModelStringInput'] | undefined | null
    visibleAt?: ValueTypes['ModelStringInput'] | undefined | null
    visibleEndsAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelProductKeywordCreationSourceInput']: {
    eq?: ValueTypes['ProductKeywordCreationSource'] | undefined | null
    ne?: ValueTypes['ProductKeywordCreationSource'] | undefined | null
  }
  ['ModelProductKeywordsConditionInput']: {
    adminPriority?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelProductKeywordsConditionInput'] | undefined | null> | undefined | null
    brandPriority?: ValueTypes['ModelIntInput'] | undefined | null
    brandResponse?: ValueTypes['ModelKeywordAcceptanceResponseInput'] | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    creationSource?: ValueTypes['ModelProductKeywordCreationSourceInput'] | undefined | null
    endDate?: ValueTypes['ModelStringInput'] | undefined | null
    heliumAccount?: ValueTypes['ModelStringInput'] | undefined | null
    heliumAsin?: ValueTypes['ModelStringInput'] | undefined | null
    heliumLink?: ValueTypes['ModelStringInput'] | undefined | null
    keyword?: ValueTypes['ModelStringInput'] | undefined | null
    monthlySearchVolume?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelProductKeywordsConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelProductKeywordsConditionInput'] | undefined | null> | undefined | null
    rank?: ValueTypes['ModelIntInput'] | undefined | null
    rankingLastSkippedAt?: ValueTypes['ModelStringInput'] | undefined | null
    rankingLastSkippedByUserId?: ValueTypes['ModelIDInput'] | undefined | null
    rankingLastUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    rankingLastUpdatedByUserId?: ValueTypes['ModelIDInput'] | undefined | null
    recommended?: ValueTypes['ModelBooleanInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    startDate?: ValueTypes['ModelStringInput'] | undefined | null
    usedForCampaign?: ValueTypes['ModelBooleanInput'] | undefined | null
    visible?: ValueTypes['ModelBooleanInput'] | undefined | null
    weight?: ValueTypes['ModelIntInput'] | undefined | null
  }
  ['ModelProductKeywordsFilterInput']: {
    adminPriority?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelProductKeywordsFilterInput'] | undefined | null> | undefined | null
    brandPriority?: ValueTypes['ModelIntInput'] | undefined | null
    brandResponse?: ValueTypes['ModelKeywordAcceptanceResponseInput'] | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    creationSource?: ValueTypes['ModelProductKeywordCreationSourceInput'] | undefined | null
    endDate?: ValueTypes['ModelStringInput'] | undefined | null
    heliumAccount?: ValueTypes['ModelStringInput'] | undefined | null
    heliumAsin?: ValueTypes['ModelStringInput'] | undefined | null
    heliumLink?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    keyword?: ValueTypes['ModelStringInput'] | undefined | null
    monthlySearchVolume?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelProductKeywordsFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelProductKeywordsFilterInput'] | undefined | null> | undefined | null
    rank?: ValueTypes['ModelIntInput'] | undefined | null
    rankingLastSkippedAt?: ValueTypes['ModelStringInput'] | undefined | null
    rankingLastSkippedByUserId?: ValueTypes['ModelIDInput'] | undefined | null
    rankingLastUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    rankingLastUpdatedByUserId?: ValueTypes['ModelIDInput'] | undefined | null
    recommended?: ValueTypes['ModelBooleanInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    startDate?: ValueTypes['ModelStringInput'] | undefined | null
    usedForCampaign?: ValueTypes['ModelBooleanInput'] | undefined | null
    visible?: ValueTypes['ModelBooleanInput'] | undefined | null
    weight?: ValueTypes['ModelIntInput'] | undefined | null
  }
  ['ModelProductQualityLevelInput']: {
    eq?: ValueTypes['ProductQualityLevel'] | undefined | null
    ne?: ValueTypes['ProductQualityLevel'] | undefined | null
  }
  ['ModelProductRatingSnapshotConditionInput']: {
    and?: Array<ValueTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null> | undefined | null
    not?: ValueTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null
    numFiveStar?: ValueTypes['ModelIntInput'] | undefined | null
    numFourStar?: ValueTypes['ModelIntInput'] | undefined | null
    numOneStar?: ValueTypes['ModelIntInput'] | undefined | null
    numRatings?: ValueTypes['ModelIntInput'] | undefined | null
    numReviews?: ValueTypes['ModelIntInput'] | undefined | null
    numThreeStar?: ValueTypes['ModelIntInput'] | undefined | null
    numTwoStar?: ValueTypes['ModelIntInput'] | undefined | null
    or?: Array<ValueTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null> | undefined | null
    rating?: ValueTypes['ModelFloatInput'] | undefined | null
    sku?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelProductRatingSnapshotFilterInput']: {
    and?: Array<ValueTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null> | undefined | null
    not?: ValueTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null
    numFiveStar?: ValueTypes['ModelIntInput'] | undefined | null
    numFourStar?: ValueTypes['ModelIntInput'] | undefined | null
    numOneStar?: ValueTypes['ModelIntInput'] | undefined | null
    numRatings?: ValueTypes['ModelIntInput'] | undefined | null
    numReviews?: ValueTypes['ModelIntInput'] | undefined | null
    numThreeStar?: ValueTypes['ModelIntInput'] | undefined | null
    numTwoStar?: ValueTypes['ModelIntInput'] | undefined | null
    or?: Array<ValueTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null> | undefined | null
    rating?: ValueTypes['ModelFloatInput'] | undefined | null
    sku?: ValueTypes['ModelStringInput'] | undefined | null
    skuId?: ValueTypes['ModelStringInput'] | undefined | null
    snapshotAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelPromoCodeTypeInput']: {
    eq?: ValueTypes['PromoCodeType'] | undefined | null
    ne?: ValueTypes['PromoCodeType'] | undefined | null
  }
  ['ModelPromoDiscountTypeInput']: {
    eq?: ValueTypes['PromoDiscountType'] | undefined | null
    ne?: ValueTypes['PromoDiscountType'] | undefined | null
  }
  ['ModelPurchaseConditionInput']: {
    amount?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelPurchaseConditionInput'] | undefined | null> | undefined | null
    cardId?: ValueTypes['ModelStringInput'] | undefined | null
    currency?: ValueTypes['ModelStringInput'] | undefined | null
    isFraud?: ValueTypes['ModelBooleanInput'] | undefined | null
    keywordAtPurchase?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelPurchaseConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelPurchaseConditionInput'] | undefined | null> | undefined | null
    purchasedAt?: ValueTypes['ModelStringInput'] | undefined | null
    shippedAt?: ValueTypes['ModelStringInput'] | undefined | null
    status?: ValueTypes['ModelPurchaseStatusInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelPurchaseFilterInput']: {
    amount?: ValueTypes['ModelIntInput'] | undefined | null
    and?: Array<ValueTypes['ModelPurchaseFilterInput'] | undefined | null> | undefined | null
    cardId?: ValueTypes['ModelStringInput'] | undefined | null
    currency?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    isFraud?: ValueTypes['ModelBooleanInput'] | undefined | null
    keywordAtPurchase?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelPurchaseFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelPurchaseFilterInput'] | undefined | null> | undefined | null
    purchasedAt?: ValueTypes['ModelStringInput'] | undefined | null
    shippedAt?: ValueTypes['ModelStringInput'] | undefined | null
    status?: ValueTypes['ModelPurchaseStatusInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelPurchaseStatusInput']: {
    eq?: ValueTypes['PurchaseStatus'] | undefined | null
    ne?: ValueTypes['PurchaseStatus'] | undefined | null
  }
  ['ModelReferralConditionInput']: {
    and?: Array<ValueTypes['ModelReferralConditionInput'] | undefined | null> | undefined | null
    bonusPayoutId?: ValueTypes['ModelIDInput'] | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelReferralConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelReferralConditionInput'] | undefined | null> | undefined | null
    referralType?: ValueTypes['ModelReferralTypeInput'] | undefined | null
    referredUserId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelReferralFilterInput']: {
    and?: Array<ValueTypes['ModelReferralFilterInput'] | undefined | null> | undefined | null
    bonusPayoutId?: ValueTypes['ModelIDInput'] | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelReferralFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelReferralFilterInput'] | undefined | null> | undefined | null
    referralType?: ValueTypes['ModelReferralTypeInput'] | undefined | null
    referredUserId?: ValueTypes['ModelIDInput'] | undefined | null
    referrerUserId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelReferralTypeInput']: {
    eq?: ValueTypes['ReferralType'] | undefined | null
    ne?: ValueTypes['ReferralType'] | undefined | null
  }
  ['ModelRequiredProductLinkFlagInput']: {
    eq?: ValueTypes['RequiredProductLinkFlag'] | undefined | null
    ne?: ValueTypes['RequiredProductLinkFlag'] | undefined | null
  }
  ['ModelReviewConditionInput']: {
    and?: Array<ValueTypes['ModelReviewConditionInput'] | undefined | null> | undefined | null
    attributedUserId?: ValueTypes['ModelStringInput'] | undefined | null
    author?: ValueTypes['ModelStringInput'] | undefined | null
    authorBadge?: ValueTypes['ModelStringInput'] | undefined | null
    badge?: ValueTypes['ModelStringInput'] | undefined | null
    commentCount?: ValueTypes['ModelIntInput'] | undefined | null
    helpfulReactions?: ValueTypes['ModelIntInput'] | undefined | null
    negativeReactions?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelReviewConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelReviewConditionInput'] | undefined | null> | undefined | null
    photos?: ValueTypes['ModelStringInput'] | undefined | null
    productId?: ValueTypes['ModelStringInput'] | undefined | null
    productVariant?: ValueTypes['ModelStringInput'] | undefined | null
    rating?: ValueTypes['ModelIntInput'] | undefined | null
    removed?: ValueTypes['ModelBooleanInput'] | undefined | null
    reviewedAt?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedSku?: ValueTypes['ModelStringInput'] | undefined | null
    reviewerId?: ValueTypes['ModelStringInput'] | undefined | null
    sku?: ValueTypes['ModelStringInput'] | undefined | null
    skuId?: ValueTypes['ModelStringInput'] | undefined | null
    store?: ValueTypes['ModelStringInput'] | undefined | null
    text?: ValueTypes['ModelStringInput'] | undefined | null
    title?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelStringInput'] | undefined | null
    videos?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelReviewFilterInput']: {
    and?: Array<ValueTypes['ModelReviewFilterInput'] | undefined | null> | undefined | null
    attributedUserId?: ValueTypes['ModelStringInput'] | undefined | null
    author?: ValueTypes['ModelStringInput'] | undefined | null
    authorBadge?: ValueTypes['ModelStringInput'] | undefined | null
    badge?: ValueTypes['ModelStringInput'] | undefined | null
    commentCount?: ValueTypes['ModelIntInput'] | undefined | null
    helpfulReactions?: ValueTypes['ModelIntInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    negativeReactions?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelReviewFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelReviewFilterInput'] | undefined | null> | undefined | null
    photos?: ValueTypes['ModelStringInput'] | undefined | null
    productId?: ValueTypes['ModelStringInput'] | undefined | null
    productVariant?: ValueTypes['ModelStringInput'] | undefined | null
    rating?: ValueTypes['ModelIntInput'] | undefined | null
    removed?: ValueTypes['ModelBooleanInput'] | undefined | null
    reviewedAt?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedSku?: ValueTypes['ModelStringInput'] | undefined | null
    reviewerId?: ValueTypes['ModelStringInput'] | undefined | null
    sku?: ValueTypes['ModelStringInput'] | undefined | null
    skuId?: ValueTypes['ModelStringInput'] | undefined | null
    store?: ValueTypes['ModelStringInput'] | undefined | null
    text?: ValueTypes['ModelStringInput'] | undefined | null
    title?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelStringInput'] | undefined | null
    videos?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelReviewScreenshotVerificationResultInput']: {
    eq?: ValueTypes['ReviewScreenshotVerificationResult'] | undefined | null
    ne?: ValueTypes['ReviewScreenshotVerificationResult'] | undefined | null
  }
  ['ModelRevocationTypeInput']: {
    eq?: ValueTypes['RevocationType'] | undefined | null
    ne?: ValueTypes['RevocationType'] | undefined | null
  }
  ['ModelScrapeStatusInput']: {
    eq?: ValueTypes['ScrapeStatus'] | undefined | null
    ne?: ValueTypes['ScrapeStatus'] | undefined | null
  }
  ['ModelSelfieVideoApprovalStatusInput']: {
    eq?: ValueTypes['SelfieVideoApprovalStatus'] | undefined | null
    ne?: ValueTypes['SelfieVideoApprovalStatus'] | undefined | null
  }
  ['ModelShopifyPromotionTypeInput']: {
    eq?: ValueTypes['ShopifyPromotionType'] | undefined | null
    ne?: ValueTypes['ShopifyPromotionType'] | undefined | null
  }
  ['ModelSizeInput']: {
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
    ne?: number | undefined | null
  }
  ['ModelSkuConditionInput']: {
    active?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelSkuConditionInput'] | undefined | null> | undefined | null
    approxRetailPrice?: ValueTypes['ModelStringInput'] | undefined | null
    approxShippingCost?: ValueTypes['ModelIntInput'] | undefined | null
    availabilityDate?: ValueTypes['ModelStringInput'] | undefined | null
    availabilityNotified?: ValueTypes['ModelBooleanInput'] | undefined | null
    availabilityNotifiedAt?: ValueTypes['ModelStringInput'] | undefined | null
    brandId?: ValueTypes['ModelIDInput'] | undefined | null
    brandListingKey?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreId?: ValueTypes['ModelStringInput'] | undefined | null
    bundle?: ValueTypes['ModelStringInput'] | undefined | null
    createdByUserId?: ValueTypes['ModelStringInput'] | undefined | null
    creationSource?: ValueTypes['ModelSkuCreationSourceInput'] | undefined | null
    creationSourceUrl?: ValueTypes['ModelStringInput'] | undefined | null
    currency?: ValueTypes['ModelCurrencyInput'] | undefined | null
    currentBuyBoxPrice?: ValueTypes['ModelIntInput'] | undefined | null
    currentBuyBoxPriceCents?: ValueTypes['ModelIntInput'] | undefined | null
    estimatedAvailabilityDate?: ValueTypes['ModelStringInput'] | undefined | null
    estimatedDeliveryDays?: ValueTypes['ModelIntInput'] | undefined | null
    estimatedMonthlyUnitsSold?: ValueTypes['ModelIntInput'] | undefined | null
    estimatedWeeklyUnitsSold?: ValueTypes['ModelIntInput'] | undefined | null
    exactListingTitle?: ValueTypes['ModelStringInput'] | undefined | null
    exactRetailPrice?: ValueTypes['ModelStringInput'] | undefined | null
    fullReviewScrapeComplete?: ValueTypes['ModelBooleanInput'] | undefined | null
    inventory?: ValueTypes['ModelIntInput'] | undefined | null
    iosAppProviderId?: ValueTypes['ModelStringInput'] | undefined | null
    isAplusPage?: ValueTypes['ModelStringInput'] | undefined | null
    isPrime?: ValueTypes['ModelStringInput'] | undefined | null
    isRecommendationsHidden?: ValueTypes['ModelBooleanInput'] | undefined | null
    itemClassification?: ValueTypes['ModelItemClassificationInput'] | undefined | null
    lastAutoFilledAt?: ValueTypes['ModelStringInput'] | undefined | null
    lastKnownBuyBoxPrice?: ValueTypes['ModelIntInput'] | undefined | null
    lastKnownBuyBoxPriceCents?: ValueTypes['ModelIntInput'] | undefined | null
    listingBrand?: ValueTypes['ModelStringInput'] | undefined | null
    listingCategory?: ValueTypes['ModelStringInput'] | undefined | null
    listingDescription?: ValueTypes['ModelStringInput'] | undefined | null
    listingFulfillmentType?: ValueTypes['ModelListingFulfillmentTypeInput'] | undefined | null
    listingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    listingLink?: ValueTypes['ModelStringInput'] | undefined | null
    mccValue?: ValueTypes['ModelValidMccCodesInput'] | undefined | null
    minimumOrderQuantity?: ValueTypes['ModelIntInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    nameAudioKey?: ValueTypes['ModelStringInput'] | undefined | null
    nonSponsoredListingKey?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelSkuConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelSkuConditionInput'] | undefined | null> | undefined | null
    parentSkuId?: ValueTypes['ModelIDInput'] | undefined | null
    priceCents?: ValueTypes['ModelIntInput'] | undefined | null
    product?: ValueTypes['ModelStringInput'] | undefined | null
    productImageKey?: ValueTypes['ModelStringInput'] | undefined | null
    productPostingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    productPostingGuidanceDocument?: ValueTypes['ModelStringInput'] | undefined | null
    productSearchImageKey?: ValueTypes['ModelStringInput'] | undefined | null
    releaseDate?: ValueTypes['ModelStringInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    sponsoredListingKey?: ValueTypes['ModelStringInput'] | undefined | null
    storeName?: ValueTypes['ModelStringInput'] | undefined | null
    targetReviewRateInvalid?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelSkuCreationSourceInput']: {
    eq?: ValueTypes['SkuCreationSource'] | undefined | null
    ne?: ValueTypes['SkuCreationSource'] | undefined | null
  }
  ['ModelSkuFilterInput']: {
    active?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelSkuFilterInput'] | undefined | null> | undefined | null
    approxRetailPrice?: ValueTypes['ModelStringInput'] | undefined | null
    approxShippingCost?: ValueTypes['ModelIntInput'] | undefined | null
    availabilityDate?: ValueTypes['ModelStringInput'] | undefined | null
    availabilityNotified?: ValueTypes['ModelBooleanInput'] | undefined | null
    availabilityNotifiedAt?: ValueTypes['ModelStringInput'] | undefined | null
    brandId?: ValueTypes['ModelIDInput'] | undefined | null
    brandListingKey?: ValueTypes['ModelStringInput'] | undefined | null
    brandStoreId?: ValueTypes['ModelStringInput'] | undefined | null
    bundle?: ValueTypes['ModelStringInput'] | undefined | null
    createdByUserId?: ValueTypes['ModelStringInput'] | undefined | null
    creationSource?: ValueTypes['ModelSkuCreationSourceInput'] | undefined | null
    creationSourceUrl?: ValueTypes['ModelStringInput'] | undefined | null
    currency?: ValueTypes['ModelCurrencyInput'] | undefined | null
    currentBuyBoxPrice?: ValueTypes['ModelIntInput'] | undefined | null
    currentBuyBoxPriceCents?: ValueTypes['ModelIntInput'] | undefined | null
    estimatedAvailabilityDate?: ValueTypes['ModelStringInput'] | undefined | null
    estimatedDeliveryDays?: ValueTypes['ModelIntInput'] | undefined | null
    estimatedMonthlyUnitsSold?: ValueTypes['ModelIntInput'] | undefined | null
    estimatedWeeklyUnitsSold?: ValueTypes['ModelIntInput'] | undefined | null
    exactListingTitle?: ValueTypes['ModelStringInput'] | undefined | null
    exactRetailPrice?: ValueTypes['ModelStringInput'] | undefined | null
    fullReviewScrapeComplete?: ValueTypes['ModelBooleanInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    inventory?: ValueTypes['ModelIntInput'] | undefined | null
    iosAppProviderId?: ValueTypes['ModelStringInput'] | undefined | null
    isAplusPage?: ValueTypes['ModelStringInput'] | undefined | null
    isPrime?: ValueTypes['ModelStringInput'] | undefined | null
    isRecommendationsHidden?: ValueTypes['ModelBooleanInput'] | undefined | null
    itemClassification?: ValueTypes['ModelItemClassificationInput'] | undefined | null
    lastAutoFilledAt?: ValueTypes['ModelStringInput'] | undefined | null
    lastKnownBuyBoxPrice?: ValueTypes['ModelIntInput'] | undefined | null
    lastKnownBuyBoxPriceCents?: ValueTypes['ModelIntInput'] | undefined | null
    listingBrand?: ValueTypes['ModelStringInput'] | undefined | null
    listingCategory?: ValueTypes['ModelStringInput'] | undefined | null
    listingDescription?: ValueTypes['ModelStringInput'] | undefined | null
    listingFulfillmentType?: ValueTypes['ModelListingFulfillmentTypeInput'] | undefined | null
    listingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    listingLink?: ValueTypes['ModelStringInput'] | undefined | null
    mccValue?: ValueTypes['ModelValidMccCodesInput'] | undefined | null
    minimumOrderQuantity?: ValueTypes['ModelIntInput'] | undefined | null
    name?: ValueTypes['ModelStringInput'] | undefined | null
    nameAudioKey?: ValueTypes['ModelStringInput'] | undefined | null
    nonSponsoredListingKey?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelSkuFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelSkuFilterInput'] | undefined | null> | undefined | null
    parentSkuId?: ValueTypes['ModelIDInput'] | undefined | null
    priceCents?: ValueTypes['ModelIntInput'] | undefined | null
    product?: ValueTypes['ModelStringInput'] | undefined | null
    productImageKey?: ValueTypes['ModelStringInput'] | undefined | null
    productPostingGuidance?: ValueTypes['ModelStringInput'] | undefined | null
    productPostingGuidanceDocument?: ValueTypes['ModelStringInput'] | undefined | null
    productSearchImageKey?: ValueTypes['ModelStringInput'] | undefined | null
    releaseDate?: ValueTypes['ModelStringInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    sponsoredListingKey?: ValueTypes['ModelStringInput'] | undefined | null
    storeName?: ValueTypes['ModelStringInput'] | undefined | null
    targetReviewRateInvalid?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelSkuSnapshotBySkuIdCompositeKeyConditionInput']: {
    beginsWith?: ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    between?: Array<ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null> | undefined | null
    eq?: ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    ge?: ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    gt?: ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    le?: ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    lt?: ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
  }
  ['ModelSkuSnapshotBySkuIdCompositeKeyInput']: {
    marketplaceSku?: string | undefined | null
    snapshotDate?: string | undefined | null
  }
  ['ModelSkuSnapshotConditionInput']: {
    and?: Array<ValueTypes['ModelSkuSnapshotConditionInput'] | undefined | null> | undefined | null
    inventory?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelSkuSnapshotConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelSkuSnapshotConditionInput'] | undefined | null> | undefined | null
    price?: ValueTypes['ModelIntInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelSkuSnapshotFilterInput']: {
    and?: Array<ValueTypes['ModelSkuSnapshotFilterInput'] | undefined | null> | undefined | null
    inventory?: ValueTypes['ModelIntInput'] | undefined | null
    marketplaceSku?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelSkuSnapshotFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelSkuSnapshotFilterInput'] | undefined | null> | undefined | null
    price?: ValueTypes['ModelIntInput'] | undefined | null
    skuId?: ValueTypes['ModelIDInput'] | undefined | null
    snapshotDate?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelSocialChannelInput']: {
    eq?: ValueTypes['SocialChannel'] | undefined | null
    ne?: ValueTypes['SocialChannel'] | undefined | null
  }
  ['ModelSocialContentTypeInput']: {
    eq?: ValueTypes['SocialContentType'] | undefined | null
    ne?: ValueTypes['SocialContentType'] | undefined | null
  }
  ['ModelSocialQualityInput']: {
    eq?: ValueTypes['SocialQuality'] | undefined | null
    ne?: ValueTypes['SocialQuality'] | undefined | null
  }
  ['ModelSocialVerificationMethodInput']: {
    eq?: ValueTypes['SocialVerificationMethod'] | undefined | null
    ne?: ValueTypes['SocialVerificationMethod'] | undefined | null
  }
  ['ModelSourcePriorityInput']: {
    eq?: ValueTypes['SourcePriority'] | undefined | null
    ne?: ValueTypes['SourcePriority'] | undefined | null
  }
  ['ModelStagingApprovalOutcomeInput']: {
    eq?: ValueTypes['StagingApprovalOutcome'] | undefined | null
    ne?: ValueTypes['StagingApprovalOutcome'] | undefined | null
  }
  ['ModelStagingRequirementTypeInput']: {
    eq?: ValueTypes['StagingRequirementType'] | undefined | null
    ne?: ValueTypes['StagingRequirementType'] | undefined | null
  }
  ['ModelStatusTierInput']: {
    eq?: ValueTypes['StatusTier'] | undefined | null
    ne?: ValueTypes['StatusTier'] | undefined | null
  }
  ['ModelStatusTierListInput']: {
    contains?: ValueTypes['StatusTier'] | undefined | null
    eq?: Array<ValueTypes['StatusTier'] | undefined | null> | undefined | null
    ne?: Array<ValueTypes['StatusTier'] | undefined | null> | undefined | null
    notContains?: ValueTypes['StatusTier'] | undefined | null
  }
  ['ModelStringInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ValueTypes['ModelAttributeTypes'] | undefined | null
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    contains?: string | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
    ne?: string | undefined | null
    notContains?: string | undefined | null
    size?: ValueTypes['ModelSizeInput'] | undefined | null
  }
  ['ModelStringKeyConditionInput']: {
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
  }
  ['ModelStripeRegionInput']: {
    eq?: ValueTypes['StripeRegion'] | undefined | null
    ne?: ValueTypes['StripeRegion'] | undefined | null
  }
  ['ModelSupportPriorityFlagInput']: {
    eq?: ValueTypes['SupportPriorityFlag'] | undefined | null
    ne?: ValueTypes['SupportPriorityFlag'] | undefined | null
  }
  ['ModelTrackingStatusInput']: {
    eq?: ValueTypes['TrackingStatus'] | undefined | null
    ne?: ValueTypes['TrackingStatus'] | undefined | null
  }
  ['ModelUserBonusPayoutConditionInput']: {
    adminPaymentApproved?: ValueTypes['ModelBooleanInput'] | undefined | null
    and?: Array<ValueTypes['ModelUserBonusPayoutConditionInput'] | undefined | null> | undefined | null
    bonusType?: ValueTypes['ModelBonusPayoutTypesInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    failureReason?: ValueTypes['ModelStringInput'] | undefined | null
    manuallyCreatedBy?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserBonusPayoutConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserBonusPayoutConditionInput'] | undefined | null> | undefined | null
    payoutAmount?: ValueTypes['ModelIntInput'] | undefined | null
    payoutAmountCents?: ValueTypes['ModelIntInput'] | undefined | null
    payoutBatchId?: ValueTypes['ModelStringInput'] | undefined | null
    payoutDate?: ValueTypes['ModelStringInput'] | undefined | null
    payoutLocale?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    payoutState?: ValueTypes['ModelBonusPayoutStateInput'] | undefined | null
    quantity?: ValueTypes['ModelIntInput'] | undefined | null
    referenceId?: ValueTypes['ModelStringInput'] | undefined | null
    releaseAt?: ValueTypes['ModelStringInput'] | undefined | null
    revocationType?: ValueTypes['ModelRevocationTypeInput'] | undefined | null
    revokedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserBonusPayoutFilterInput']: {
    adminPaymentApproved?: ValueTypes['ModelBooleanInput'] | undefined | null
    and?: Array<ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null> | undefined | null
    bonusType?: ValueTypes['ModelBonusPayoutTypesInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    failureReason?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    manuallyCreatedBy?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserBonusPayoutFilterInput'] | undefined | null> | undefined | null
    payoutAmount?: ValueTypes['ModelIntInput'] | undefined | null
    payoutAmountCents?: ValueTypes['ModelIntInput'] | undefined | null
    payoutBatchId?: ValueTypes['ModelStringInput'] | undefined | null
    payoutDate?: ValueTypes['ModelStringInput'] | undefined | null
    payoutLocale?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    payoutState?: ValueTypes['ModelBonusPayoutStateInput'] | undefined | null
    quantity?: ValueTypes['ModelIntInput'] | undefined | null
    referenceId?: ValueTypes['ModelStringInput'] | undefined | null
    releaseAt?: ValueTypes['ModelStringInput'] | undefined | null
    revocationType?: ValueTypes['ModelRevocationTypeInput'] | undefined | null
    revokedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserCampaignAcceptanceStatusInput']: {
    eq?: ValueTypes['UserCampaignAcceptanceStatus'] | undefined | null
    ne?: ValueTypes['UserCampaignAcceptanceStatus'] | undefined | null
  }
  ['ModelUserCampaignApprovalStatusInput']: {
    eq?: ValueTypes['UserCampaignApprovalStatus'] | undefined | null
    ne?: ValueTypes['UserCampaignApprovalStatus'] | undefined | null
  }
  ['ModelUserCampaignChangelogConditionInput']: {
    and?: Array<ValueTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null> | undefined | null
    createdByUserId?: ValueTypes['ModelIDInput'] | undefined | null
    entryType?: ValueTypes['ModelUserCampaignChangelogEntryTypeInput'] | undefined | null
    extraData?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null> | undefined | null
  }
  ['ModelUserCampaignChangelogEntryTypeInput']: {
    eq?: ValueTypes['UserCampaignChangelogEntryType'] | undefined | null
    ne?: ValueTypes['UserCampaignChangelogEntryType'] | undefined | null
  }
  ['ModelUserCampaignChangelogFilterInput']: {
    and?: Array<ValueTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    createdByUserId?: ValueTypes['ModelIDInput'] | undefined | null
    entryType?: ValueTypes['ModelUserCampaignChangelogEntryTypeInput'] | undefined | null
    extraData?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null> | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserCampaignConditionInput']: {
    accountScreenShot?: ValueTypes['ModelStringInput'] | undefined | null
    amznReviewLink?: ValueTypes['ModelStringInput'] | undefined | null
    amznReviewSubmissionStatus?: ValueTypes['ModelAmazonReviewSubmissionStatusInput'] | undefined | null
    and?: Array<ValueTypes['ModelUserCampaignConditionInput'] | undefined | null> | undefined | null
    applicationRejectedByUserId?: ValueTypes['ModelIDInput'] | undefined | null
    approvalOutcome?: ValueTypes['ModelApprovalOutcomeInput'] | undefined | null
    approvalStatus?: ValueTypes['ModelUserCampaignApprovalStatusInput'] | undefined | null
    archivedFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    archivedReason?: ValueTypes['ModelArchivedReasonInput'] | undefined | null
    autoBuyApprovalDate?: ValueTypes['ModelStringInput'] | undefined | null
    autoBuyRevokeDate?: ValueTypes['ModelStringInput'] | undefined | null
    bestBuyReviewScreenShot?: ValueTypes['ModelStringInput'] | undefined | null
    campaignAcceptanceStatus?: ValueTypes['ModelUserCampaignAcceptanceStatusInput'] | undefined | null
    campaignApplication?: ValueTypes['ModelStringInput'] | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    campaignStatus?: ValueTypes['ModelCampaignStatusInput'] | undefined | null
    campaignStatusOnArchive?: ValueTypes['ModelStringInput'] | undefined | null
    cardId?: ValueTypes['ModelStringInput'] | undefined | null
    cardRegion?: ValueTypes['ModelStripeRegionInput'] | undefined | null
    closeoutSurvey?: ValueTypes['ModelStringInput'] | undefined | null
    coarseCampaignStatus?: ValueTypes['ModelCoarseCampaignStatusInput'] | undefined | null
    contentApprovalLeadDays?: ValueTypes['ModelIntInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    discoverySurvey?: ValueTypes['ModelStringInput'] | undefined | null
    influencerApprovalInviteFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    influencerApprovalRejectFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    invoiceKey?: ValueTypes['ModelStringInput'] | undefined | null
    isAutoApproved?: ValueTypes['ModelBooleanInput'] | undefined | null
    keywordAtPurchase?: ValueTypes['ModelStringInput'] | undefined | null
    keywordId?: ValueTypes['ModelIDInput'] | undefined | null
    keywordsV2Hash?: ValueTypes['ModelStringInput'] | undefined | null
    listingKey?: ValueTypes['ModelStringInput'] | undefined | null
    localeV1?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    manualPurchase?: ValueTypes['ModelBooleanInput'] | undefined | null
    moderatorStagingApprovalAt?: ValueTypes['ModelStringInput'] | undefined | null
    moderatorStagingApprovalBy?: ValueTypes['ModelStringInput'] | undefined | null
    moderatorStagingApprovalOutcome?: ValueTypes['ModelStagingApprovalOutcomeInput'] | undefined | null
    not?: ValueTypes['ModelUserCampaignConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignConditionInput'] | undefined | null> | undefined | null
    orderId?: ValueTypes['ModelStringInput'] | undefined | null
    orderIdInvalidReason?: ValueTypes['ModelStringInput'] | undefined | null
    orderIdSubmissionDate?: ValueTypes['ModelStringInput'] | undefined | null
    orderIdValidationDate?: ValueTypes['ModelStringInput'] | undefined | null
    orderIdValidationStatus?: ValueTypes['ModelOrderIdValidationStatusInput'] | undefined | null
    orderStatus?: ValueTypes['ModelStringInput'] | undefined | null
    payForClickLinkId?: ValueTypes['ModelIDInput'] | undefined | null
    paypalEmailAtSubmission?: ValueTypes['ModelStringInput'] | undefined | null
    postingDeadline?: ValueTypes['ModelStringInput'] | undefined | null
    postingDeadlineExtended?: ValueTypes['ModelBooleanInput'] | undefined | null
    previousCardIds?: ValueTypes['ModelStringInput'] | undefined | null
    priority?: ValueTypes['ModelSourcePriorityInput'] | undefined | null
    productCriticalFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    productId?: ValueTypes['ModelIDInput'] | undefined | null
    productPositiveFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    rankPriority?: ValueTypes['ModelIntInput'] | undefined | null
    reviewScreenshot?: ValueTypes['ModelStringInput'] | undefined | null
    reviewScreenshotVerificationResult?: ValueTypes['ModelReviewScreenshotVerificationResultInput'] | undefined | null
    schedulingReleaseAt?: ValueTypes['ModelStringInput'] | undefined | null
    searchPhraseKey?: ValueTypes['ModelStringInput'] | undefined | null
    selectedFlow?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalDate?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalNotes?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalStatus?: ValueTypes['ModelSelfieVideoApprovalStatusInput'] | undefined | null
    selfieVideoKey?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoRejectionReason?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoSubmissionRequired?: ValueTypes['ModelBooleanInput'] | undefined | null
    selfieVideoSubmittedAt?: ValueTypes['ModelStringInput'] | undefined | null
    shippingAddress?: ValueTypes['ModelStringInput'] | undefined | null
    showEarlyReviewSurvey?: ValueTypes['ModelBooleanInput'] | undefined | null
    statusHistory?: ValueTypes['ModelStringInput'] | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    walmartReviewScreenShot?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignFilterInput']: {
    accountScreenShot?: ValueTypes['ModelStringInput'] | undefined | null
    amznReviewLink?: ValueTypes['ModelStringInput'] | undefined | null
    amznReviewSubmissionStatus?: ValueTypes['ModelAmazonReviewSubmissionStatusInput'] | undefined | null
    and?: Array<ValueTypes['ModelUserCampaignFilterInput'] | undefined | null> | undefined | null
    applicationRejectedByUserId?: ValueTypes['ModelIDInput'] | undefined | null
    approvalOutcome?: ValueTypes['ModelApprovalOutcomeInput'] | undefined | null
    approvalStatus?: ValueTypes['ModelUserCampaignApprovalStatusInput'] | undefined | null
    archivedFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    archivedReason?: ValueTypes['ModelArchivedReasonInput'] | undefined | null
    autoBuyApprovalDate?: ValueTypes['ModelStringInput'] | undefined | null
    autoBuyRevokeDate?: ValueTypes['ModelStringInput'] | undefined | null
    bestBuyReviewScreenShot?: ValueTypes['ModelStringInput'] | undefined | null
    campaignAcceptanceStatus?: ValueTypes['ModelUserCampaignAcceptanceStatusInput'] | undefined | null
    campaignApplication?: ValueTypes['ModelStringInput'] | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    campaignStatus?: ValueTypes['ModelCampaignStatusInput'] | undefined | null
    campaignStatusOnArchive?: ValueTypes['ModelStringInput'] | undefined | null
    cardId?: ValueTypes['ModelStringInput'] | undefined | null
    cardRegion?: ValueTypes['ModelStripeRegionInput'] | undefined | null
    closeoutSurvey?: ValueTypes['ModelStringInput'] | undefined | null
    coarseCampaignStatus?: ValueTypes['ModelCoarseCampaignStatusInput'] | undefined | null
    contentApprovalLeadDays?: ValueTypes['ModelIntInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    discoverySurvey?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    influencerApprovalInviteFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    influencerApprovalRejectFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    invoiceKey?: ValueTypes['ModelStringInput'] | undefined | null
    isAutoApproved?: ValueTypes['ModelBooleanInput'] | undefined | null
    keywordAtPurchase?: ValueTypes['ModelStringInput'] | undefined | null
    keywordId?: ValueTypes['ModelIDInput'] | undefined | null
    keywordsV2Hash?: ValueTypes['ModelStringInput'] | undefined | null
    listingKey?: ValueTypes['ModelStringInput'] | undefined | null
    localeV1?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    manualPurchase?: ValueTypes['ModelBooleanInput'] | undefined | null
    moderatorStagingApprovalAt?: ValueTypes['ModelStringInput'] | undefined | null
    moderatorStagingApprovalBy?: ValueTypes['ModelStringInput'] | undefined | null
    moderatorStagingApprovalOutcome?: ValueTypes['ModelStagingApprovalOutcomeInput'] | undefined | null
    not?: ValueTypes['ModelUserCampaignFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignFilterInput'] | undefined | null> | undefined | null
    orderId?: ValueTypes['ModelStringInput'] | undefined | null
    orderIdInvalidReason?: ValueTypes['ModelStringInput'] | undefined | null
    orderIdSubmissionDate?: ValueTypes['ModelStringInput'] | undefined | null
    orderIdValidationDate?: ValueTypes['ModelStringInput'] | undefined | null
    orderIdValidationStatus?: ValueTypes['ModelOrderIdValidationStatusInput'] | undefined | null
    orderStatus?: ValueTypes['ModelStringInput'] | undefined | null
    payForClickLinkId?: ValueTypes['ModelIDInput'] | undefined | null
    paypalEmailAtSubmission?: ValueTypes['ModelStringInput'] | undefined | null
    postingDeadline?: ValueTypes['ModelStringInput'] | undefined | null
    postingDeadlineExtended?: ValueTypes['ModelBooleanInput'] | undefined | null
    previousCardIds?: ValueTypes['ModelStringInput'] | undefined | null
    priority?: ValueTypes['ModelSourcePriorityInput'] | undefined | null
    productCriticalFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    productId?: ValueTypes['ModelIDInput'] | undefined | null
    productPositiveFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    rankPriority?: ValueTypes['ModelIntInput'] | undefined | null
    reviewScreenshot?: ValueTypes['ModelStringInput'] | undefined | null
    reviewScreenshotVerificationResult?: ValueTypes['ModelReviewScreenshotVerificationResultInput'] | undefined | null
    schedulingReleaseAt?: ValueTypes['ModelStringInput'] | undefined | null
    searchPhraseKey?: ValueTypes['ModelStringInput'] | undefined | null
    selectedFlow?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalDate?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalNotes?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalStatus?: ValueTypes['ModelSelfieVideoApprovalStatusInput'] | undefined | null
    selfieVideoKey?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoRejectionReason?: ValueTypes['ModelStringInput'] | undefined | null
    selfieVideoSubmissionRequired?: ValueTypes['ModelBooleanInput'] | undefined | null
    selfieVideoSubmittedAt?: ValueTypes['ModelStringInput'] | undefined | null
    shippingAddress?: ValueTypes['ModelStringInput'] | undefined | null
    showEarlyReviewSurvey?: ValueTypes['ModelBooleanInput'] | undefined | null
    statusHistory?: ValueTypes['ModelStringInput'] | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
    walmartReviewScreenShot?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSnapshotConditionInput']: {
    and?: Array<ValueTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null> | undefined | null
    not?: ValueTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null> | undefined | null
    snapshot?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSnapshotFilterInput']: {
    and?: Array<ValueTypes['ModelUserCampaignSnapshotFilterInput'] | undefined | null> | undefined | null
    not?: ValueTypes['ModelUserCampaignSnapshotFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignSnapshotFilterInput'] | undefined | null> | undefined | null
    snapshot?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    userCampaignVersion?: ValueTypes['ModelIntInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentConditionInput']: {
    and?: Array<ValueTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null> | undefined | null
    approvalFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    approvalStatus?: ValueTypes['ModelContentApprovalStatusInput'] | undefined | null
    approvedAt?: ValueTypes['ModelStringInput'] | undefined | null
    approverPWUserId?: ValueTypes['ModelStringInput'] | undefined | null
    approverUserId?: ValueTypes['ModelIDInput'] | undefined | null
    bonusEarned?: ValueTypes['ModelIntInput'] | undefined | null
    bonusOverride?: ValueTypes['ModelIntInput'] | undefined | null
    caption?: ValueTypes['ModelStringInput'] | undefined | null
    channel?: ValueTypes['ModelSocialChannelInput'] | undefined | null
    complianceReviewStatus?: ValueTypes['ModelContentComplianceReviewStatusInput'] | undefined | null
    complianceReviewUserId?: ValueTypes['ModelIDInput'] | undefined | null
    complianceReviewedAt?: ValueTypes['ModelStringInput'] | undefined | null
    contentType?: ValueTypes['ModelSocialContentTypeInput'] | undefined | null
    draftEnteredAdminApprovalAt?: ValueTypes['ModelStringInput'] | undefined | null
    draftEnteredBrandApprovalAt?: ValueTypes['ModelStringInput'] | undefined | null
    draftFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    draftHistory?: ValueTypes['ModelStringInput'] | undefined | null
    draftMedia?: ValueTypes['ModelStringInput'] | undefined | null
    draftPreApprovedText?: ValueTypes['ModelStringInput'] | undefined | null
    draftQualityScore?: ValueTypes['ModelIntInput'] | undefined | null
    draftStatus?: ValueTypes['ModelDraftStatusInput'] | undefined | null
    draftSubmittedAt?: ValueTypes['ModelStringInput'] | undefined | null
    draftText?: ValueTypes['ModelStringInput'] | undefined | null
    earlySubmissionBonusEarnedCents?: ValueTypes['ModelIntInput'] | undefined | null
    experimentId?: ValueTypes['ModelStringInput'] | undefined | null
    externalUrl?: ValueTypes['ModelStringInput'] | undefined | null
    groupId?: ValueTypes['ModelStringInput'] | undefined | null
    hashtags?: ValueTypes['ModelStringInput'] | undefined | null
    instance?: ValueTypes['ModelIntInput'] | undefined | null
    isFavorite?: ValueTypes['ModelBooleanInput'] | undefined | null
    isHidden?: ValueTypes['ModelBooleanInput'] | undefined | null
    isPurchased?: ValueTypes['ModelBooleanInput'] | undefined | null
    isUnavailable?: ValueTypes['ModelBooleanInput'] | undefined | null
    manualUploadType?: ValueTypes['ModelManualUploadTypeInput'] | undefined | null
    mediaId?: ValueTypes['ModelStringInput'] | undefined | null
    mediaUrls?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null> | undefined | null
    paymentId?: ValueTypes['ModelStringInput'] | undefined | null
    postedAt?: ValueTypes['ModelStringInput'] | undefined | null
    qualityScore?: ValueTypes['ModelIntInput'] | undefined | null
    rejectionReason?: ValueTypes['ModelStringInput'] | undefined | null
    requestedSocialScript?: ValueTypes['ModelBooleanInput'] | undefined | null
    requirementLevel?: ValueTypes['ModelContentRequirementLevelInput'] | undefined | null
    scrapeStatus?: ValueTypes['ModelScrapeStatusInput'] | undefined | null
    unavailableAt?: ValueTypes['ModelStringInput'] | undefined | null
    usedRequestedSocialScript?: ValueTypes['ModelBooleanInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    videoEndTime?: ValueTypes['ModelStringInput'] | undefined | null
    videoStartTime?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentDraftHistoryConditionInput']: {
    and?:
      | Array<ValueTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null>
      | undefined
      | null
    not?: ValueTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null
    or?:
      | Array<ValueTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null>
      | undefined
      | null
    snapshot?: ValueTypes['ModelStringInput'] | undefined | null
    snapshotDate?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignSocialContentId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentDraftHistoryFilterInput']: {
    and?:
      | Array<ValueTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null>
      | undefined
      | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null
    or?:
      | Array<ValueTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null>
      | undefined
      | null
    snapshot?: ValueTypes['ModelStringInput'] | undefined | null
    snapshotDate?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignSocialContentId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentFilterInput']: {
    and?: Array<ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null> | undefined | null
    approvalFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    approvalStatus?: ValueTypes['ModelContentApprovalStatusInput'] | undefined | null
    approvedAt?: ValueTypes['ModelStringInput'] | undefined | null
    approverPWUserId?: ValueTypes['ModelStringInput'] | undefined | null
    approverUserId?: ValueTypes['ModelIDInput'] | undefined | null
    bonusEarned?: ValueTypes['ModelIntInput'] | undefined | null
    bonusOverride?: ValueTypes['ModelIntInput'] | undefined | null
    caption?: ValueTypes['ModelStringInput'] | undefined | null
    channel?: ValueTypes['ModelSocialChannelInput'] | undefined | null
    complianceReviewStatus?: ValueTypes['ModelContentComplianceReviewStatusInput'] | undefined | null
    complianceReviewUserId?: ValueTypes['ModelIDInput'] | undefined | null
    complianceReviewedAt?: ValueTypes['ModelStringInput'] | undefined | null
    contentType?: ValueTypes['ModelSocialContentTypeInput'] | undefined | null
    draftEnteredAdminApprovalAt?: ValueTypes['ModelStringInput'] | undefined | null
    draftEnteredBrandApprovalAt?: ValueTypes['ModelStringInput'] | undefined | null
    draftFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    draftHistory?: ValueTypes['ModelStringInput'] | undefined | null
    draftMedia?: ValueTypes['ModelStringInput'] | undefined | null
    draftPreApprovedText?: ValueTypes['ModelStringInput'] | undefined | null
    draftQualityScore?: ValueTypes['ModelIntInput'] | undefined | null
    draftStatus?: ValueTypes['ModelDraftStatusInput'] | undefined | null
    draftSubmittedAt?: ValueTypes['ModelStringInput'] | undefined | null
    draftText?: ValueTypes['ModelStringInput'] | undefined | null
    earlySubmissionBonusEarnedCents?: ValueTypes['ModelIntInput'] | undefined | null
    experimentId?: ValueTypes['ModelStringInput'] | undefined | null
    externalUrl?: ValueTypes['ModelStringInput'] | undefined | null
    groupId?: ValueTypes['ModelStringInput'] | undefined | null
    hashtags?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    instance?: ValueTypes['ModelIntInput'] | undefined | null
    isFavorite?: ValueTypes['ModelBooleanInput'] | undefined | null
    isHidden?: ValueTypes['ModelBooleanInput'] | undefined | null
    isPurchased?: ValueTypes['ModelBooleanInput'] | undefined | null
    isUnavailable?: ValueTypes['ModelBooleanInput'] | undefined | null
    manualUploadType?: ValueTypes['ModelManualUploadTypeInput'] | undefined | null
    mediaId?: ValueTypes['ModelStringInput'] | undefined | null
    mediaUrls?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null> | undefined | null
    paymentId?: ValueTypes['ModelStringInput'] | undefined | null
    postedAt?: ValueTypes['ModelStringInput'] | undefined | null
    qualityScore?: ValueTypes['ModelIntInput'] | undefined | null
    rejectionReason?: ValueTypes['ModelStringInput'] | undefined | null
    requestedSocialScript?: ValueTypes['ModelBooleanInput'] | undefined | null
    requirementLevel?: ValueTypes['ModelContentRequirementLevelInput'] | undefined | null
    scrapeStatus?: ValueTypes['ModelScrapeStatusInput'] | undefined | null
    unavailableAt?: ValueTypes['ModelStringInput'] | undefined | null
    usedRequestedSocialScript?: ValueTypes['ModelBooleanInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    videoEndTime?: ValueTypes['ModelStringInput'] | undefined | null
    videoStartTime?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewConditionInput']: {
    and?: Array<ValueTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null> | undefined | null
    reviewOutcome?: ValueTypes['ModelUserCampaignSocialContentReviewOutcomeInput'] | undefined | null
    reviewStatus?: ValueTypes['ModelUserCampaignSocialContentReviewStatusInput'] | undefined | null
    reviewedAt?: ValueTypes['ModelStringInput'] | undefined | null
    reviewerAssigneeId?: ValueTypes['ModelStringInput'] | undefined | null
    reviewerFirstName?: ValueTypes['ModelStringInput'] | undefined | null
    reviewerId?: ValueTypes['ModelIDInput'] | undefined | null
    reviewerLastName?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewFilterInput']: {
    and?: Array<ValueTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null> | undefined | null
    reviewOutcome?: ValueTypes['ModelUserCampaignSocialContentReviewOutcomeInput'] | undefined | null
    reviewStatus?: ValueTypes['ModelUserCampaignSocialContentReviewStatusInput'] | undefined | null
    reviewType?: ValueTypes['ModelUserCampaignSocialContentReviewTypeInput'] | undefined | null
    reviewedAt?: ValueTypes['ModelStringInput'] | undefined | null
    reviewerAssigneeId?: ValueTypes['ModelStringInput'] | undefined | null
    reviewerFirstName?: ValueTypes['ModelStringInput'] | undefined | null
    reviewerId?: ValueTypes['ModelIDInput'] | undefined | null
    reviewerLastName?: ValueTypes['ModelStringInput'] | undefined | null
    userCampaignSocialContentId?: ValueTypes['ModelIDInput'] | undefined | null
    userId?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewOutcomeInput']: {
    eq?: ValueTypes['UserCampaignSocialContentReviewOutcome'] | undefined | null
    ne?: ValueTypes['UserCampaignSocialContentReviewOutcome'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewStatusInput']: {
    eq?: ValueTypes['UserCampaignSocialContentReviewStatus'] | undefined | null
    ne?: ValueTypes['UserCampaignSocialContentReviewStatus'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewTypeInput']: {
    eq?: ValueTypes['UserCampaignSocialContentReviewType'] | undefined | null
    ne?: ValueTypes['UserCampaignSocialContentReviewType'] | undefined | null
  }
  ['ModelUserConditionInput']: {
    active?: ValueTypes['ModelActiveInput'] | undefined | null
    addressComponents?: ValueTypes['ModelStringInput'] | undefined | null
    amznLastChecked?: ValueTypes['ModelStringInput'] | undefined | null
    amznLink?: ValueTypes['ModelStringInput'] | undefined | null
    amznPrime?: ValueTypes['ModelStringInput'] | undefined | null
    amznProfileId?: ValueTypes['ModelStringInput'] | undefined | null
    amznReviewerId?: ValueTypes['ModelStringInput'] | undefined | null
    amznVerified?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelUserConditionInput'] | undefined | null> | undefined | null
    attributedReviewCount?: ValueTypes['ModelIntInput'] | undefined | null
    blogWebsite?: ValueTypes['ModelStringInput'] | undefined | null
    brandContentRatingAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    campaignAcceptanceRate?: ValueTypes['ModelIntInput'] | undefined | null
    campaignApprovalRate?: ValueTypes['ModelIntInput'] | undefined | null
    campaignCompletionRate?: ValueTypes['ModelIntInput'] | undefined | null
    campaignReviewRate?: ValueTypes['ModelIntInput'] | undefined | null
    cardProfileUpdates?: ValueTypes['ModelStringInput'] | undefined | null
    cardholderId?: ValueTypes['ModelStringInput'] | undefined | null
    cardholderRegion?: ValueTypes['ModelStripeRegionInput'] | undefined | null
    characteristicsUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    claimedReviewCount?: ValueTypes['ModelIntInput'] | undefined | null
    collisionSkuSet?: ValueTypes['ModelStringInput'] | undefined | null
    collisionSkuSetCalculatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    completedLifestyleCampaignCount?: ValueTypes['ModelIntInput'] | undefined | null
    completedLiftCampaignCount?: ValueTypes['ModelIntInput'] | undefined | null
    completedSocialCampaignCount?: ValueTypes['ModelIntInput'] | undefined | null
    contentRatingCompletedAt?: ValueTypes['ModelStringInput'] | undefined | null
    contentRatingCompletionTimeMillis?: ValueTypes['ModelIntInput'] | undefined | null
    contentRatingInstagramCompletionTimeMillis?: ValueTypes['ModelIntInput'] | undefined | null
    contentRatingQualityScore?: ValueTypes['ModelIntInput'] | undefined | null
    contentRatingReviewStatus?: ValueTypes['ModelContentRatingReviewStatusInput'] | undefined | null
    contentRatingSubmittedBy?: ValueTypes['ModelStringInput'] | undefined | null
    contentRatingTiktokCompletionTimeMillis?: ValueTypes['ModelIntInput'] | undefined | null
    contentTypes?: ValueTypes['ModelStringInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    date_of_birth?: ValueTypes['ModelStringInput'] | undefined | null
    deactivationDate?: ValueTypes['ModelStringInput'] | undefined | null
    deactivationReason?: ValueTypes['ModelStringInput'] | undefined | null
    declineToReviewCount?: ValueTypes['ModelIntInput'] | undefined | null
    deranked?: ValueTypes['ModelDeranked_ReasonInput'] | undefined | null
    email?: ValueTypes['ModelStringInput'] | undefined | null
    emailOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    facebookAccessToken?: ValueTypes['ModelStringInput'] | undefined | null
    facebookAccessTokenExpiry?: ValueTypes['ModelStringInput'] | undefined | null
    facebookPageId?: ValueTypes['ModelStringInput'] | undefined | null
    facebookPageIsSubscribed?: ValueTypes['ModelBooleanInput'] | undefined | null
    facebookPermissionScopes?: ValueTypes['ModelStringInput'] | undefined | null
    facebookUserId?: ValueTypes['ModelStringInput'] | undefined | null
    firstName?: ValueTypes['ModelStringInput'] | undefined | null
    followsWesponsored?: ValueTypes['ModelStringInput'] | undefined | null
    fraudReason?: ValueTypes['ModelFraudReasonInput'] | undefined | null
    fraudReviewAt?: ValueTypes['ModelStringInput'] | undefined | null
    fraudReviewStatus?: ValueTypes['ModelFraudReviewStatusInput'] | undefined | null
    gender?: ValueTypes['ModelStringInput'] | undefined | null
    hasTrueEngagement?: ValueTypes['ModelBooleanInput'] | undefined | null
    idfaId?: ValueTypes['ModelStringInput'] | undefined | null
    incompleteCampaignCount?: ValueTypes['ModelIntInput'] | undefined | null
    instagramBio?: ValueTypes['ModelStringInput'] | undefined | null
    instagramBusinessCategoryName?: ValueTypes['ModelStringInput'] | undefined | null
    instagramCategoryName?: ValueTypes['ModelStringInput'] | undefined | null
    instagramChecked?: ValueTypes['ModelStringInput'] | undefined | null
    instagramContentTypeRating?: ValueTypes['ModelContentTypeRatingListInput'] | undefined | null
    instagramExternalLink?: ValueTypes['ModelStringInput'] | undefined | null
    instagramFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    instagramHandle?: ValueTypes['ModelStringInput'] | undefined | null
    instagramIsBusiness?: ValueTypes['ModelStringInput'] | undefined | null
    instagramIsPrivate?: ValueTypes['ModelStringInput'] | undefined | null
    instagramIsRecentlyJoined?: ValueTypes['ModelStringInput'] | undefined | null
    instagramIsVerified?: ValueTypes['ModelStringInput'] | undefined | null
    instagramLastChecked?: ValueTypes['ModelStringInput'] | undefined | null
    instagramMedianShares?: ValueTypes['ModelIntInput'] | undefined | null
    instagramMetricsRetrievedAt?: ValueTypes['ModelStringInput'] | undefined | null
    instagramName?: ValueTypes['ModelStringInput'] | undefined | null
    instagramPostsInt?: ValueTypes['ModelIntInput'] | undefined | null
    instagramPriority?: ValueTypes['ModelPlatformPriorityInput'] | undefined | null
    instagramProfilePic?: ValueTypes['ModelStringInput'] | undefined | null
    instagramTrailingComments?: ValueTypes['ModelIntInput'] | undefined | null
    instagramTrailingDateOfOldestPost?: ValueTypes['ModelStringInput'] | undefined | null
    instagramTrailingLikes?: ValueTypes['ModelIntInput'] | undefined | null
    instagramTrailingNumberOfPosts?: ValueTypes['ModelIntInput'] | undefined | null
    instagramTrailingPostEngagementAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateImpressionsAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateMedian?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostImpressionsAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostImpressionsMedian?: ValueTypes['ModelIntInput'] | undefined | null
    instagramTrailingPostSponsoredPercentage?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostViewsMedian?: ValueTypes['ModelIntInput'] | undefined | null
    instagramUserId?: ValueTypes['ModelStringInput'] | undefined | null
    instagramVerificationMethod?: ValueTypes['ModelSocialVerificationMethodInput'] | undefined | null
    internalGender?: ValueTypes['ModelUserGenderInput'] | undefined | null
    isBrandSafe?: ValueTypes['ModelBrandSafeInput'] | undefined | null
    isBrandSafeReason?: ValueTypes['ModelStringInput'] | undefined | null
    isBrandSafeUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    lastActiveAt?: ValueTypes['ModelStringInput'] | undefined | null
    lastName?: ValueTypes['ModelStringInput'] | undefined | null
    manualScoringLockedUntil?: ValueTypes['ModelStringInput'] | undefined | null
    moreinfo?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserConditionInput'] | undefined | null
    numEvenDollarPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    numPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserConditionInput'] | undefined | null> | undefined | null
    paypalEmail?: ValueTypes['ModelStringInput'] | undefined | null
    phoneNumber?: ValueTypes['ModelStringInput'] | undefined | null
    profilePicture?: ValueTypes['ModelStringInput'] | undefined | null
    pushOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    referralCode?: ValueTypes['ModelStringInput'] | undefined | null
    referralCount?: ValueTypes['ModelIntInput'] | undefined | null
    referrer?: ValueTypes['ModelStringInput'] | undefined | null
    signupCountry?: ValueTypes['ModelStringInput'] | undefined | null
    smsOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    socialQuality?: ValueTypes['ModelSocialQualityInput'] | undefined | null
    statusTier?: ValueTypes['ModelStatusTierInput'] | undefined | null
    statusTierLastUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    supportPriorityAcknowledgedAt?: ValueTypes['ModelStringInput'] | undefined | null
    supportPriorityFlag?: ValueTypes['ModelSupportPriorityFlagInput'] | undefined | null
    supportPriorityFlagSetAt?: ValueTypes['ModelStringInput'] | undefined | null
    targetAccount?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokAccessToken?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokAccessTokenExpiry?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokAccountId?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokAveragePostEngagementRate?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokAveragePostEngagementRateViews?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokBio?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokContentTypeRating?: ValueTypes['ModelContentTypeRatingListInput'] | undefined | null
    tiktokFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokHandle?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokMedianComments?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokMedianLikes?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokMedianPostEngagement?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokMedianPostEngagementRate?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokMedianPostEngagementRateViews?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokMedianShares?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokMedianViews?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokMetricsRetrievedAt?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokName?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokPermissionScopes?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokPriority?: ValueTypes['ModelPlatformPriorityInput'] | undefined | null
    tiktokProfilePic?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokRefreshToken?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokRefreshTokenExpiry?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokSponsoredPostPercentage?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokVerificationMethod?: ValueTypes['ModelSocialVerificationMethodInput'] | undefined | null
    tiktokVerified?: ValueTypes['ModelStringInput'] | undefined | null
    timeZone?: ValueTypes['ModelStringInput'] | undefined | null
    trackingStatus?: ValueTypes['ModelTrackingStatusInput'] | undefined | null
    twitchAccount?: ValueTypes['ModelStringInput'] | undefined | null
    type?: ValueTypes['ModelStringInput'] | undefined | null
    wmartAccount?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeAccessToken?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeAccessTokenExpiry?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeAccount?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeAveragePostEngagementRate?: ValueTypes['ModelFloatInput'] | undefined | null
    youtubeChannelDescription?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeChannelFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    youtubeChannelHandle?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeChannelId?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeChannelPicture?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeChannelTitle?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeIdToken?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeMedianViews?: ValueTypes['ModelIntInput'] | undefined | null
    youtubeMetricsRetrievedAt?: ValueTypes['ModelStringInput'] | undefined | null
    youtubePermissionScopes?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeRefreshToken?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeVerificationMethod?: ValueTypes['ModelSocialVerificationMethodInput'] | undefined | null
  }
  ['ModelUserDeviceSnapshotConditionInput']: {
    and?: Array<ValueTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null> | undefined | null
    idfv?: ValueTypes['ModelStringInput'] | undefined | null
    ipAddress?: ValueTypes['ModelStringInput'] | undefined | null
    isEmulator?: ValueTypes['ModelBooleanInput'] | undefined | null
    not?: ValueTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null> | undefined | null
    snapshotAt?: ValueTypes['ModelStringInput'] | undefined | null
    timezone?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserDeviceSnapshotFilterInput']: {
    and?: Array<ValueTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null> | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    idfv?: ValueTypes['ModelStringInput'] | undefined | null
    ipAddress?: ValueTypes['ModelStringInput'] | undefined | null
    isEmulator?: ValueTypes['ModelBooleanInput'] | undefined | null
    not?: ValueTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null> | undefined | null
    snapshotAt?: ValueTypes['ModelStringInput'] | undefined | null
    timezone?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserFilterGenderInput']: {
    eq?: ValueTypes['UserFilterGender'] | undefined | null
    ne?: ValueTypes['UserFilterGender'] | undefined | null
  }
  ['ModelUserFilterInput']: {
    active?: ValueTypes['ModelActiveInput'] | undefined | null
    addressComponents?: ValueTypes['ModelStringInput'] | undefined | null
    amznLastChecked?: ValueTypes['ModelStringInput'] | undefined | null
    amznLink?: ValueTypes['ModelStringInput'] | undefined | null
    amznPrime?: ValueTypes['ModelStringInput'] | undefined | null
    amznProfileId?: ValueTypes['ModelStringInput'] | undefined | null
    amznReviewerId?: ValueTypes['ModelStringInput'] | undefined | null
    amznVerified?: ValueTypes['ModelStringInput'] | undefined | null
    and?: Array<ValueTypes['ModelUserFilterInput'] | undefined | null> | undefined | null
    attributedReviewCount?: ValueTypes['ModelIntInput'] | undefined | null
    blogWebsite?: ValueTypes['ModelStringInput'] | undefined | null
    brandContentRatingAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    campaignAcceptanceRate?: ValueTypes['ModelIntInput'] | undefined | null
    campaignApprovalRate?: ValueTypes['ModelIntInput'] | undefined | null
    campaignCompletionRate?: ValueTypes['ModelIntInput'] | undefined | null
    campaignReviewRate?: ValueTypes['ModelIntInput'] | undefined | null
    cardProfileUpdates?: ValueTypes['ModelStringInput'] | undefined | null
    cardholderId?: ValueTypes['ModelStringInput'] | undefined | null
    cardholderRegion?: ValueTypes['ModelStripeRegionInput'] | undefined | null
    characteristicsUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    claimedReviewCount?: ValueTypes['ModelIntInput'] | undefined | null
    collisionSkuSet?: ValueTypes['ModelStringInput'] | undefined | null
    collisionSkuSetCalculatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    completedLifestyleCampaignCount?: ValueTypes['ModelIntInput'] | undefined | null
    completedLiftCampaignCount?: ValueTypes['ModelIntInput'] | undefined | null
    completedSocialCampaignCount?: ValueTypes['ModelIntInput'] | undefined | null
    contentRatingCompletedAt?: ValueTypes['ModelStringInput'] | undefined | null
    contentRatingCompletionTimeMillis?: ValueTypes['ModelIntInput'] | undefined | null
    contentRatingInstagramCompletionTimeMillis?: ValueTypes['ModelIntInput'] | undefined | null
    contentRatingQualityScore?: ValueTypes['ModelIntInput'] | undefined | null
    contentRatingReviewStatus?: ValueTypes['ModelContentRatingReviewStatusInput'] | undefined | null
    contentRatingSubmittedBy?: ValueTypes['ModelStringInput'] | undefined | null
    contentRatingTiktokCompletionTimeMillis?: ValueTypes['ModelIntInput'] | undefined | null
    contentTypes?: ValueTypes['ModelStringInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    date_of_birth?: ValueTypes['ModelStringInput'] | undefined | null
    deactivationDate?: ValueTypes['ModelStringInput'] | undefined | null
    deactivationReason?: ValueTypes['ModelStringInput'] | undefined | null
    declineToReviewCount?: ValueTypes['ModelIntInput'] | undefined | null
    deranked?: ValueTypes['ModelDeranked_ReasonInput'] | undefined | null
    email?: ValueTypes['ModelStringInput'] | undefined | null
    emailOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    facebookAccessToken?: ValueTypes['ModelStringInput'] | undefined | null
    facebookAccessTokenExpiry?: ValueTypes['ModelStringInput'] | undefined | null
    facebookPageId?: ValueTypes['ModelStringInput'] | undefined | null
    facebookPageIsSubscribed?: ValueTypes['ModelBooleanInput'] | undefined | null
    facebookPermissionScopes?: ValueTypes['ModelStringInput'] | undefined | null
    facebookUserId?: ValueTypes['ModelStringInput'] | undefined | null
    firstName?: ValueTypes['ModelStringInput'] | undefined | null
    followsWesponsored?: ValueTypes['ModelStringInput'] | undefined | null
    fraudReason?: ValueTypes['ModelFraudReasonInput'] | undefined | null
    fraudReviewAt?: ValueTypes['ModelStringInput'] | undefined | null
    fraudReviewStatus?: ValueTypes['ModelFraudReviewStatusInput'] | undefined | null
    gender?: ValueTypes['ModelStringInput'] | undefined | null
    hasTrueEngagement?: ValueTypes['ModelBooleanInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    idfaId?: ValueTypes['ModelStringInput'] | undefined | null
    incompleteCampaignCount?: ValueTypes['ModelIntInput'] | undefined | null
    instagramBio?: ValueTypes['ModelStringInput'] | undefined | null
    instagramBusinessCategoryName?: ValueTypes['ModelStringInput'] | undefined | null
    instagramCategoryName?: ValueTypes['ModelStringInput'] | undefined | null
    instagramChecked?: ValueTypes['ModelStringInput'] | undefined | null
    instagramContentTypeRating?: ValueTypes['ModelContentTypeRatingListInput'] | undefined | null
    instagramExternalLink?: ValueTypes['ModelStringInput'] | undefined | null
    instagramFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    instagramHandle?: ValueTypes['ModelStringInput'] | undefined | null
    instagramIsBusiness?: ValueTypes['ModelStringInput'] | undefined | null
    instagramIsPrivate?: ValueTypes['ModelStringInput'] | undefined | null
    instagramIsRecentlyJoined?: ValueTypes['ModelStringInput'] | undefined | null
    instagramIsVerified?: ValueTypes['ModelStringInput'] | undefined | null
    instagramLastChecked?: ValueTypes['ModelStringInput'] | undefined | null
    instagramMedianShares?: ValueTypes['ModelIntInput'] | undefined | null
    instagramMetricsRetrievedAt?: ValueTypes['ModelStringInput'] | undefined | null
    instagramName?: ValueTypes['ModelStringInput'] | undefined | null
    instagramPostsInt?: ValueTypes['ModelIntInput'] | undefined | null
    instagramPriority?: ValueTypes['ModelPlatformPriorityInput'] | undefined | null
    instagramProfilePic?: ValueTypes['ModelStringInput'] | undefined | null
    instagramTrailingComments?: ValueTypes['ModelIntInput'] | undefined | null
    instagramTrailingDateOfOldestPost?: ValueTypes['ModelStringInput'] | undefined | null
    instagramTrailingLikes?: ValueTypes['ModelIntInput'] | undefined | null
    instagramTrailingNumberOfPosts?: ValueTypes['ModelIntInput'] | undefined | null
    instagramTrailingPostEngagementAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateImpressionsAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateMedian?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostImpressionsAvg?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostImpressionsMedian?: ValueTypes['ModelIntInput'] | undefined | null
    instagramTrailingPostSponsoredPercentage?: ValueTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostViewsMedian?: ValueTypes['ModelIntInput'] | undefined | null
    instagramUserId?: ValueTypes['ModelStringInput'] | undefined | null
    instagramVerificationMethod?: ValueTypes['ModelSocialVerificationMethodInput'] | undefined | null
    internalGender?: ValueTypes['ModelUserGenderInput'] | undefined | null
    isBrandSafe?: ValueTypes['ModelBrandSafeInput'] | undefined | null
    isBrandSafeReason?: ValueTypes['ModelStringInput'] | undefined | null
    isBrandSafeUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    lastActiveAt?: ValueTypes['ModelStringInput'] | undefined | null
    lastName?: ValueTypes['ModelStringInput'] | undefined | null
    manualScoringLockedUntil?: ValueTypes['ModelStringInput'] | undefined | null
    moreinfo?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserFilterInput'] | undefined | null
    numEvenDollarPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    numPurchases?: ValueTypes['ModelIntInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserFilterInput'] | undefined | null> | undefined | null
    paypalEmail?: ValueTypes['ModelStringInput'] | undefined | null
    phoneNumber?: ValueTypes['ModelStringInput'] | undefined | null
    profilePicture?: ValueTypes['ModelStringInput'] | undefined | null
    pushOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    referralCode?: ValueTypes['ModelStringInput'] | undefined | null
    referralCount?: ValueTypes['ModelIntInput'] | undefined | null
    referrer?: ValueTypes['ModelStringInput'] | undefined | null
    signupCountry?: ValueTypes['ModelStringInput'] | undefined | null
    smsOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    socialQuality?: ValueTypes['ModelSocialQualityInput'] | undefined | null
    statusTier?: ValueTypes['ModelStatusTierInput'] | undefined | null
    statusTierLastUpdatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    supportPriorityAcknowledgedAt?: ValueTypes['ModelStringInput'] | undefined | null
    supportPriorityFlag?: ValueTypes['ModelSupportPriorityFlagInput'] | undefined | null
    supportPriorityFlagSetAt?: ValueTypes['ModelStringInput'] | undefined | null
    targetAccount?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokAccessToken?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokAccessTokenExpiry?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokAccountId?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokAveragePostEngagementRate?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokAveragePostEngagementRateViews?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokBio?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokContentTypeRating?: ValueTypes['ModelContentTypeRatingListInput'] | undefined | null
    tiktokFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokHandle?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokMedianComments?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokMedianLikes?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokMedianPostEngagement?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokMedianPostEngagementRate?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokMedianPostEngagementRateViews?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokMedianShares?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokMedianViews?: ValueTypes['ModelIntInput'] | undefined | null
    tiktokMetricsRetrievedAt?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokName?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokPermissionScopes?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokPriority?: ValueTypes['ModelPlatformPriorityInput'] | undefined | null
    tiktokProfilePic?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokRefreshToken?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokRefreshTokenExpiry?: ValueTypes['ModelStringInput'] | undefined | null
    tiktokSponsoredPostPercentage?: ValueTypes['ModelFloatInput'] | undefined | null
    tiktokVerificationMethod?: ValueTypes['ModelSocialVerificationMethodInput'] | undefined | null
    tiktokVerified?: ValueTypes['ModelStringInput'] | undefined | null
    timeZone?: ValueTypes['ModelStringInput'] | undefined | null
    trackingStatus?: ValueTypes['ModelTrackingStatusInput'] | undefined | null
    twitchAccount?: ValueTypes['ModelStringInput'] | undefined | null
    type?: ValueTypes['ModelStringInput'] | undefined | null
    wmartAccount?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeAccessToken?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeAccessTokenExpiry?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeAccount?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeAveragePostEngagementRate?: ValueTypes['ModelFloatInput'] | undefined | null
    youtubeChannelDescription?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeChannelFollowersInt?: ValueTypes['ModelIntInput'] | undefined | null
    youtubeChannelHandle?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeChannelId?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeChannelPicture?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeChannelTitle?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeIdToken?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeMedianViews?: ValueTypes['ModelIntInput'] | undefined | null
    youtubeMetricsRetrievedAt?: ValueTypes['ModelStringInput'] | undefined | null
    youtubePermissionScopes?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeRefreshToken?: ValueTypes['ModelStringInput'] | undefined | null
    youtubeVerificationMethod?: ValueTypes['ModelSocialVerificationMethodInput'] | undefined | null
  }
  ['ModelUserFollowerConditionInput']: {
    and?: Array<ValueTypes['ModelUserFollowerConditionInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    isFavorite?: ValueTypes['ModelFavoriteFlagInput'] | undefined | null
    not?: ValueTypes['ModelUserFollowerConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserFollowerConditionInput'] | undefined | null> | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserFollowerFilterInput']: {
    and?: Array<ValueTypes['ModelUserFollowerFilterInput'] | undefined | null> | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    followerUserId?: ValueTypes['ModelIDInput'] | undefined | null
    isFavorite?: ValueTypes['ModelFavoriteFlagInput'] | undefined | null
    not?: ValueTypes['ModelUserFollowerFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserFollowerFilterInput'] | undefined | null> | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserGenderInput']: {
    eq?: ValueTypes['UserGender'] | undefined | null
    ne?: ValueTypes['UserGender'] | undefined | null
  }
  ['ModelUserLinkConditionInput']: {
    and?: Array<ValueTypes['ModelUserLinkConditionInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    linkId?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserLinkConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserLinkConditionInput'] | undefined | null> | undefined | null
    originalLink?: ValueTypes['ModelStringInput'] | undefined | null
    rank?: ValueTypes['ModelIntInput'] | undefined | null
    storageType?: ValueTypes['ModelLinkStorageTypeInput'] | undefined | null
    uniqueClicks?: ValueTypes['ModelIntInput'] | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userEncodedLink?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserLinkFilterInput']: {
    and?: Array<ValueTypes['ModelUserLinkFilterInput'] | undefined | null> | undefined | null
    campaignId?: ValueTypes['ModelIDInput'] | undefined | null
    createdAt?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    linkId?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserLinkFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserLinkFilterInput'] | undefined | null> | undefined | null
    originalLink?: ValueTypes['ModelStringInput'] | undefined | null
    rank?: ValueTypes['ModelIntInput'] | undefined | null
    storageType?: ValueTypes['ModelLinkStorageTypeInput'] | undefined | null
    uniqueClicks?: ValueTypes['ModelIntInput'] | undefined | null
    updatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    userEncodedLink?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserNotificationPreferenceConditionInput']: {
    and?: Array<ValueTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null> | undefined | null
    emailOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    not?: ValueTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null> | undefined | null
    pushOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    smsOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
  }
  ['ModelUserNotificationPreferenceFilterInput']: {
    and?: Array<ValueTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null> | undefined | null
    category?: ValueTypes['ModelStringInput'] | undefined | null
    emailOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    not?: ValueTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null> | undefined | null
    pushOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    smsOptOut?: ValueTypes['ModelBooleanInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserPayoutRequestConditionInput']: {
    and?: Array<ValueTypes['ModelUserPayoutRequestConditionInput'] | undefined | null> | undefined | null
    failureReason?: ValueTypes['ModelStringInput'] | undefined | null
    manuallyApprovedBy?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserPayoutRequestConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserPayoutRequestConditionInput'] | undefined | null> | undefined | null
    payoutAmountCents?: ValueTypes['ModelIntInput'] | undefined | null
    payoutBatchId?: ValueTypes['ModelStringInput'] | undefined | null
    payoutDate?: ValueTypes['ModelStringInput'] | undefined | null
    payoutLocale?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    payoutState?: ValueTypes['ModelBonusPayoutStateInput'] | undefined | null
  }
  ['ModelUserPayoutRequestFilterInput']: {
    and?: Array<ValueTypes['ModelUserPayoutRequestFilterInput'] | undefined | null> | undefined | null
    failureReason?: ValueTypes['ModelStringInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    manuallyApprovedBy?: ValueTypes['ModelStringInput'] | undefined | null
    not?: ValueTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserPayoutRequestFilterInput'] | undefined | null> | undefined | null
    payoutAmountCents?: ValueTypes['ModelIntInput'] | undefined | null
    payoutBatchId?: ValueTypes['ModelStringInput'] | undefined | null
    payoutDate?: ValueTypes['ModelStringInput'] | undefined | null
    payoutLocale?: ValueTypes['ModelWSLocaleInput'] | undefined | null
    payoutState?: ValueTypes['ModelBonusPayoutStateInput'] | undefined | null
    userId?: ValueTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserStagingApprovalConditionInput']: {
    and?: Array<ValueTypes['ModelUserStagingApprovalConditionInput'] | undefined | null> | undefined | null
    not?: ValueTypes['ModelUserStagingApprovalConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserStagingApprovalConditionInput'] | undefined | null> | undefined | null
    reviewFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedAt?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedBy?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedByEmail?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedByName?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedBySource?: ValueTypes['ModelUserStagingApprovalReviewSourceInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    userStagingApprovalOutcome?: ValueTypes['ModelUserStagingApprovalOutcomeInput'] | undefined | null
    userStagingApprovalType?: ValueTypes['ModelUserStagingApprovalTypeInput'] | undefined | null
  }
  ['ModelUserStagingApprovalFilterInput']: {
    and?: Array<ValueTypes['ModelUserStagingApprovalFilterInput'] | undefined | null> | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelUserStagingApprovalFilterInput'] | undefined | null> | undefined | null
    reviewFeedback?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedAt?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedBy?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedByEmail?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedByName?: ValueTypes['ModelStringInput'] | undefined | null
    reviewedBySource?: ValueTypes['ModelUserStagingApprovalReviewSourceInput'] | undefined | null
    userCampaignId?: ValueTypes['ModelIDInput'] | undefined | null
    userStagingApprovalOutcome?: ValueTypes['ModelUserStagingApprovalOutcomeInput'] | undefined | null
    userStagingApprovalType?: ValueTypes['ModelUserStagingApprovalTypeInput'] | undefined | null
  }
  ['ModelUserStagingApprovalOutcomeInput']: {
    eq?: ValueTypes['UserStagingApprovalOutcome'] | undefined | null
    ne?: ValueTypes['UserStagingApprovalOutcome'] | undefined | null
  }
  ['ModelUserStagingApprovalReviewSourceInput']: {
    eq?: ValueTypes['UserStagingApprovalReviewSource'] | undefined | null
    ne?: ValueTypes['UserStagingApprovalReviewSource'] | undefined | null
  }
  ['ModelUserStagingApprovalTypeInput']: {
    eq?: ValueTypes['UserStagingApprovalType'] | undefined | null
    ne?: ValueTypes['UserStagingApprovalType'] | undefined | null
  }
  ['ModelValidMccCodesInput']: {
    eq?: ValueTypes['ValidMccCodes'] | undefined | null
    ne?: ValueTypes['ValidMccCodes'] | undefined | null
  }
  ['ModelVideoContentScanConditionInput']: {
    and?: Array<ValueTypes['ModelVideoContentScanConditionInput'] | undefined | null> | undefined | null
    audioDuration?: ValueTypes['ModelIntInput'] | undefined | null
    audioEndAt?: ValueTypes['ModelIntInput'] | undefined | null
    audioStartFrom?: ValueTypes['ModelIntInput'] | undefined | null
    not?: ValueTypes['ModelVideoContentScanConditionInput'] | undefined | null
    or?: Array<ValueTypes['ModelVideoContentScanConditionInput'] | undefined | null> | undefined | null
    processFinishedAt?: ValueTypes['ModelStringInput'] | undefined | null
    processId?: ValueTypes['ModelStringInput'] | undefined | null
    processInitiatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    scanStatus?: ValueTypes['ModelContentScanStatusInput'] | undefined | null
    socialMediaUrl?: ValueTypes['ModelStringInput'] | undefined | null
    transcription?: ValueTypes['ModelStringInput'] | undefined | null
    transcriptionConfidence?: ValueTypes['ModelFloatInput'] | undefined | null
    url?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelVideoContentScanFilterInput']: {
    and?: Array<ValueTypes['ModelVideoContentScanFilterInput'] | undefined | null> | undefined | null
    audioDuration?: ValueTypes['ModelIntInput'] | undefined | null
    audioEndAt?: ValueTypes['ModelIntInput'] | undefined | null
    audioStartFrom?: ValueTypes['ModelIntInput'] | undefined | null
    id?: ValueTypes['ModelIDInput'] | undefined | null
    not?: ValueTypes['ModelVideoContentScanFilterInput'] | undefined | null
    or?: Array<ValueTypes['ModelVideoContentScanFilterInput'] | undefined | null> | undefined | null
    processFinishedAt?: ValueTypes['ModelStringInput'] | undefined | null
    processId?: ValueTypes['ModelStringInput'] | undefined | null
    processInitiatedAt?: ValueTypes['ModelStringInput'] | undefined | null
    scanStatus?: ValueTypes['ModelContentScanStatusInput'] | undefined | null
    socialMediaUrl?: ValueTypes['ModelStringInput'] | undefined | null
    transcription?: ValueTypes['ModelStringInput'] | undefined | null
    transcriptionConfidence?: ValueTypes['ModelFloatInput'] | undefined | null
    url?: ValueTypes['ModelStringInput'] | undefined | null
    userId?: ValueTypes['ModelIDInput'] | undefined | null
  }
  ['ModelWSLocaleInput']: {
    eq?: ValueTypes['WSLocale'] | undefined | null
    ne?: ValueTypes['WSLocale'] | undefined | null
  }
  ['OccurrenceConstraintInput']: {
    period?: ValueTypes['OccurrenceConstraintPeriod'] | undefined | null
    type: ValueTypes['OccurrenceConstraintType']
    value?: number | undefined | null
  }
  ['PayForImpressionsBonusConfigInput']: {
    enabled?: boolean | undefined | null
    growthRate?: number | undefined | null
    impressionsBaseline?: number | undefined | null
    impressionsCap?: number | undefined | null
    impressionsIncrement?: number | undefined | null
    initialBonus?: number | undefined | null
  }
  ['PayForPostingContentEarlyConfigInput']: {
    bonusCents?: number | undefined | null
    daysBeforeDeadline?: number | undefined | null
    enabled?: boolean | undefined | null
  }
  ['ProductSocialContentInput']: {
    bonus: number
    brandContentGuidance?: Array<ValueTypes['ContentGuidanceInput']> | undefined | null
    brandContentRequirements?: Array<string> | undefined | null
    bronzeBonus?: number | undefined | null
    channel: ValueTypes['SocialChannel']
    diamondBonus?: number | undefined | null
    goldBonus?: number | undefined | null
    inspirationalContent?: Array<ValueTypes['InspirationalContentInput']> | undefined | null
    instances: number
    platinumBonus?: number | undefined | null
    requirementLevel: ValueTypes['ContentRequirementLevel']
    requiresManualApproval?: boolean | undefined | null
    silverBonus?: number | undefined | null
    talkingPoints?: Array<ValueTypes['ContentGuidanceInput']> | undefined | null
    totalExpectedCount?: number | undefined | null
    type: ValueTypes['SocialContentType']
  }
  ['ProspectSearchFields']: {
    sourcingSource?: ValueTypes['SearchInputString'] | undefined | null
  }
  ['PushAddressInput']: {
    address?: string | undefined | null
    pushChannel?: ValueTypes['PushChannel'] | undefined | null
  }
  ['PushChannelConfigurationInput']: {
    attributes?: Array<ValueTypes['KeyAttributeInput'] | undefined | null> | undefined | null
    enabled?: boolean | undefined | null
    templateId: string
  }
  ['RankPointInput']: {
    rank: number
    rankAt: string
  }
  ['RateInput']: {
    count?: number | undefined | null
    isIntegrationFallbackRate?: boolean | undefined | null
    period?: ValueTypes['Period'] | undefined | null
  }
  ['RequestedContentChangesInput']: {
    message?: string | undefined | null
    rating?: number | undefined | null
  }
  ['RollbackAdditionalArguments']: {
    removeApprovals?: boolean | undefined | null
    removeBonuses?: boolean | undefined | null
    removeCard?: boolean | undefined | null
    removeContent?: boolean | undefined | null
    removeKeyword?: boolean | undefined | null
    resetBonusConfig?: boolean | undefined | null
  }
  ['SearchInputBoolean']: {
    match?: boolean | undefined | null
  }
  ['SearchInputNumber']: {
    field?: ValueTypes['SearchInputField'] | undefined | null
    match?: number | undefined | null
    not?: boolean | undefined | null
    range?: ValueTypes['SearchInputNumberRange'] | undefined | null
  }
  ['SearchInputNumberRange']: {
    gt?: number | undefined | null
    gte?: number | undefined | null
    lt?: number | undefined | null
    lte?: number | undefined | null
  }
  ['SearchInputQueryScript']: {
    params?: ValueTypes['SearchInputScriptParams'] | undefined | null
    source: ValueTypes['SearchInputQueryScriptSource']
  }
  ['SearchInputScriptParams']: {
    fieldNames?: Array<string> | undefined | null
    max?: number | undefined | null
    min?: number | undefined | null
  }
  ['SearchInputSortScript']: {
    params?: ValueTypes['SearchInputScriptParams'] | undefined | null
    source: ValueTypes['SearchInputSortScriptSource']
    type: ValueTypes['SearchInputScriptType']
  }
  ['SearchInputString']: {
    field?: ValueTypes['SearchInputField'] | undefined | null
    match?: string | undefined | null
    match_phrase_prefix?: string | undefined | null
    not?: boolean | undefined | null
    prefix?: string | undefined | null
    range?: ValueTypes['SearchInputStringRange'] | undefined | null
    regexp?: string | undefined | null
    wildcard?: string | undefined | null
  }
  ['SearchInputStringRange']: {
    gt?: string | undefined | null
    gte?: string | undefined | null
    lt?: string | undefined | null
    lte?: string | undefined | null
  }
  ['SequenceFormSubmissionValuesInput']: {
    componentId: string
    componentName: string
    componentType?: ValueTypes['FormComponentType'] | undefined | null
    sequenceId: string
    type?: ValueTypes['FormFieldType'] | undefined | null
    value: string
  }
  ['SkuAttributeInput']: {
    name: string
    value: string
  }
  ['SocialMediaFeedbackInput']: {
    comments?: string | undefined | null
    rating?: number | undefined | null
  }
  ['SocialMetricInput']: {
    name: ValueTypes['SocialMetricName']
    value: number
  }
  ['StagingApprovalConfigurationInput']: {
    stagingApprovalAssignee?: string | undefined | null
    stagingApprovalType: ValueTypes['UserStagingApprovalType']
  }
  ['StatusTierBonusConfigInput']: {
    baseBonus?: number | undefined | null
    diamondBonus?: number | undefined | null
    goldBonus?: number | undefined | null
    platinumBonus?: number | undefined | null
    silverBonus?: number | undefined | null
  }
  ['StatusTierEntryInput']: {
    statusTier: ValueTypes['StatusTier']
    statusTierAt: string
  }
  ['SubmitUserCampaignStateChangeInput']: {
    bonusRequest?: ValueTypes['BonusRequestTypes'] | undefined | null
    campaignStatus?: ValueTypes['CampaignStatus'] | undefined | null
    defect?: boolean | undefined | null
    id: string
    requestedByUserId?: string | undefined | null
    requestedUpdates?: string | undefined | null
    stateChangeRequestType?: ValueTypes['UserCampaignStateChangeRequestType'] | undefined | null
  }
  ['UpdateAutoBuyDistributionInput']: {
    createdAt?: string | undefined | null
    description?: string | undefined | null
    id: string
    name?: string | undefined | null
    schedule?: Array<number> | undefined | null
    updatedAt?: string | undefined | null
  }
  ['UpdateAutoBuyFlowConfigurationInput']: {
    dailyPurchaseMax?: number | undefined | null
    delayStrategy?: ValueTypes['AutoBuyDelayStrategy'] | undefined | null
    flowId: string
    insufficientCreators?: string | undefined | null
    latestSkipReason?: ValueTypes['AutoBuySkipReason'] | undefined | null
    pauseReason?: ValueTypes['AutoBuySkipReason'] | undefined | null
    schedule?: Array<ValueTypes['AutoBuyScheduleInput']> | undefined | null
    startDate?: string | undefined | null
    status?: ValueTypes['AutoBuyStatus'] | undefined | null
  }
  ['UpdateAutoBuyFlowReleaseScheduleInput']: {
    actualPurchases?: number | undefined | null
    actualVerifiedPurchases?: number | undefined | null
    adjustedDesiredPurchases?: number | undefined | null
    autoApprovalSeats?: number | undefined | null
    date: string
    desiredPurchases?: number | undefined | null
    flowId: string
    manualPurchases?: number | undefined | null
    manualReleases?: number | undefined | null
    projectedReleaseCount?: number | undefined | null
    releaseCount?: number | undefined | null
    released?: boolean | undefined | null
    skippedReason?: ValueTypes['AutoBuySkipReason'] | undefined | null
  }
  ['UpdateAutoBuyReleaseInput']: {
    date: string
    productId?: string | undefined | null
    releaseDateTime?: string | undefined | null
    skuId?: string | undefined | null
    userCampaignId: string
  }
  ['UpdateBrandApiInput']: {
    accessToken?: string | undefined | null
    api: ValueTypes['BrandApiType']
    apiVersion?: ValueTypes['BrandApiVersion'] | undefined | null
    brandId: string
    enabled?: boolean | undefined | null
    locale?: ValueTypes['WSLocale'] | undefined | null
    refreshToken?: string | undefined | null
    shop?: string | undefined | null
    shopId?: string | undefined | null
  }
  ['UpdateBrandInput']: {
    active?: string | undefined | null
    amazonBrandName?: string | undefined | null
    brandDetailsSubmitted?: boolean | undefined | null
    brandInstaHandle?: string | undefined | null
    brandLogoImageKey?: string | undefined | null
    brandStoreConfirmationImage?: string | undefined | null
    brandStoreConfirmationMobileImage?: string | undefined | null
    brandStoreScrapeUrl?: string | undefined | null
    brandTikTokHandle?: string | undefined | null
    companyId?: string | undefined | null
    contactEmails?: Array<string | undefined | null> | undefined | null
    defaultCreatorPreference?: ValueTypes['CreatorPreference'] | undefined | null
    defaultCreatorPreferenceDetails?: string | undefined | null
    defaultHashtags?: Array<string> | undefined | null
    defaultMarketingBriefKey?: string | undefined | null
    defaultPostingGuidelines?: Array<string> | undefined | null
    defaultPostingInspirationLinks?: Array<string> | undefined | null
    emailsSent?: Array<string | undefined | null> | undefined | null
    fullScrapedAt?: string | undefined | null
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined | null
    isSeller?: string | undefined | null
    isVendor?: string | undefined | null
    isWalmartScrapeEnabled?: boolean | undefined | null
    lastScrapedAt?: string | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    paymentBillingContact?: ValueTypes['BillingContactInput'] | undefined | null
    platform?: ValueTypes['PlatformSource'] | undefined | null
    postingGuidanceDocument?: string | undefined | null
    region?: ValueTypes['WSLocale'] | undefined | null
    socialMediaExampleUrls?: Array<string> | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    walmartBrandName?: string | undefined | null
    walmartLastScrapedAt?: string | undefined | null
    walmartScrapeUrl?: string | undefined | null
  }
  ['UpdateCampaignClicksInput']: {
    campaignId: string
    clicks?: number | undefined | null
    date: string
  }
  ['UpdateCampaignInput']: {
    aboutSection?: string | undefined | null
    active?: string | undefined | null
    actualStartDate?: string | undefined | null
    amountPaidByClient?: number | undefined | null
    anticipatedStartDate?: string | undefined | null
    applicationForm?: string | undefined | null
    applicationProductLinkId?: string | undefined | null
    applicationStatus?: ValueTypes['ApplicationStatus'] | undefined | null
    attributionRefUrlSuffix?: string | undefined | null
    authorizedUnits?: number | undefined | null
    badges?: Array<ValueTypes['CampaignBadgeInput'] | undefined | null> | undefined | null
    brandId?: string | undefined | null
    brandProductShort?: string | undefined | null
    brandStoreLink?: string | undefined | null
    budgetSpent?: number | undefined | null
    campaignProductType?: ValueTypes['CampaignProductType'] | undefined | null
    campaignReferralBonusAmount?: number | undefined | null
    campaignReferralBonusEnabled?: ValueTypes['CampaignReferralBonusFlag'] | undefined | null
    campaignStateMachine?: string | undefined | null
    closeoutReleaseFailureReason?: string | undefined | null
    closeoutReleaseRate?: ValueTypes['RateInput'] | undefined | null
    collisionSkuDensity?: Array<ValueTypes['CollisionDensityValueInput']> | undefined | null
    commissionFlatRate?: number | undefined | null
    commissionPercentageRate?: number | undefined | null
    committedClicks?: number | undefined | null
    committedPlacementWinsCount?: number | undefined | null
    committedReviewsCount?: number | undefined | null
    contentApprovalRequired?: boolean | undefined | null
    contentCompleteDeadline?: string | undefined | null
    createdAt?: string | undefined | null
    creationSourceVersion?: ValueTypes['CreationSourceVersion'] | undefined | null
    earlyReviewEnabled?: boolean | undefined | null
    enableContentComplianceReview?: boolean | undefined | null
    endDate?: string | undefined | null
    excludeGender?: ValueTypes['UserFilterGender'] | undefined | null
    expectedBonus?: string | undefined | null
    followersCountCeiling?: number | undefined | null
    followersCountFloor?: number | undefined | null
    goal?: ValueTypes['CampaignGoal'] | undefined | null
    id: string
    influencerApprovalEnabled?: ValueTypes['InfluencerApprovalFlagV2'] | undefined | null
    lifestyleCommittedContentCount?: number | undefined | null
    localeV1?: ValueTypes['WSLocale'] | undefined | null
    marketingFee?: number | undefined | null
    marketingFormFilled?: boolean | undefined | null
    newCreatorsOnly?: boolean | undefined | null
    payForClicksEnabled?: ValueTypes['EnabledFlag'] | undefined | null
    payForClicksGeneratedPerPeriod?: Array<ValueTypes['ClicksRecordsInput']> | undefined | null
    payForClicksMinClicks?: number | undefined | null
    payForClicksOriginalLink?: string | undefined | null
    payForClicksTotalClicksGenerated?: number | undefined | null
    payForPerformanceEnabled?: ValueTypes['PayForPerformanceFlag'] | undefined | null
    postingGuidance?: string | undefined | null
    preApplicationProductImage?: string | undefined | null
    preApplicationProductPriceRange?: string | undefined | null
    preApplicationUgcProductImage?: string | undefined | null
    preLaunchDate?: string | undefined | null
    premiumLifestyleCommittedContentCount?: number | undefined | null
    requiredProductLinksEnabled?: ValueTypes['RequiredProductLinkFlag'] | undefined | null
    reviewVotingTarget?: string | undefined | null
    sample?: boolean | undefined | null
    shellCampaignBase?: string | undefined | null
    skuId?: string | undefined | null
    skuPriceOverride?: number | undefined | null
    socialCommittedContentCount?: number | undefined | null
    sourcingPriority?: number | undefined | null
    sponsorshipInstructions?: string | undefined | null
    title?: string | undefined | null
    totalEarlyReviewsAllowed?: number | undefined | null
    totalProductCost?: number | undefined | null
    userAutoApproved?: boolean | undefined | null
    visibleToUser?: string | undefined | null
  }
  ['UpdateCampaignInviteInput']: {
    campaignId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    notificationStatus?: ValueTypes['NotificationStatus'] | undefined | null
    priority?: number | undefined | null
    updatedAt?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateCampaignSourcingProspectInput']: {
    bonusConfig?: ValueTypes['UserCampaignBonusConfigInput'] | undefined | null
    campaignId: string
    createdAt?: string | undefined | null
    createdById?: string | undefined | null
    instagramFollowersInt?: number | undefined | null
    instagramHandle?: string | undefined | null
    internalNotesId?: string | undefined | null
    name?: string | undefined | null
    platformInviteSource?: ValueTypes['PlatformInviteSource'] | undefined | null
    platformStatus?: ValueTypes['PlatformStatus'] | undefined | null
    preApprovalStatus?: ValueTypes['PreApprovalStatus'] | undefined | null
    preApprovalStatusById?: string | undefined | null
    priority?: ValueTypes['SourcePriority'] | undefined | null
    productId?: string | undefined | null
    sourcingSource?: ValueTypes['CampaignSourcingSource'] | undefined | null
    tiktokFollowersInt?: number | undefined | null
    tiktokHandle?: string | undefined | null
    userCampaignId?: string | undefined | null
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined | null
    youtubeChannelHandle?: string | undefined | null
  }
  ['UpdateChatConversationV2Input']: {
    active?: ValueTypes['Active'] | undefined | null
    adminLastReadMessageIndex?: number | undefined | null
    adminUnreadCount?: number | undefined | null
    campaignId?: string | undefined | null
    chatType: ValueTypes['ChatType']
    creatorLastReadMessageIndex?: number | undefined | null
    creatorUnreadCount?: number | undefined | null
    lastMessage?: string | undefined | null
    lastMessageDate?: string | undefined | null
    lastMessageId?: string | undefined | null
    lastMessageSource?: ValueTypes['ConversationMessageSource'] | undefined | null
    resourceId: string
    status?: ValueTypes['ConversationInternalStatus'] | undefined | null
    statusUpdatedAt?: string | undefined | null
    statusUpdatedByFirstName?: string | undefined | null
    statusUpdatedById?: string | undefined | null
    statusUpdatedByLastName?: string | undefined | null
    twilioConversationId?: string | undefined | null
    userCampaignId?: string | undefined | null
    userEmail?: string | undefined | null
    userFirstName?: string | undefined | null
    userId?: string | undefined | null
    userLastName?: string | undefined | null
  }
  ['UpdateCommunicationNotificationInput']: {
    address?: string | undefined | null
    attributes?: Array<ValueTypes['KeyAttributeInput']> | undefined | null
    campaignId?: string | undefined | null
    channel?: ValueTypes['Channel'] | undefined | null
    expectedSentAt?: string | undefined | null
    experimentTreatment?: string | undefined | null
    id: string
    notificationId?: string | undefined | null
    sentAt?: string | undefined | null
    status?: string | undefined | null
    statusMessage?: string | undefined | null
    templateId?: string | undefined | null
    traceId?: string | undefined | null
    userCampaignId?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateCompanyInput']: {
    agencyId?: string | undefined | null
    id: string
    logo?: string | undefined | null
    name?: string | undefined | null
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ValueTypes['PaymentTermsType'] | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['UpdateConnectedAccountInput']: {
    accountId: string
    accountType?: ValueTypes['ConnectedAccountType'] | undefined | null
    createdAt?: string | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['UpdateContentApprovalsInput']: {
    bonusOverride?: number | undefined | null
    editedCaption?: string | undefined | null
    nextContentApprovalAssignee?: string | undefined | null
    rejectionReason?: string | undefined | null
    requestType: ValueTypes['AdminUpdateContentApprovalType']
    requestedContentChanges?: Array<ValueTypes['RequestedContentChangesInput']> | undefined | null
    reviewType: ValueTypes['UserCampaignSocialContentReviewType']
    userCampaignSocialContentId: string
  }
  ['UpdateConversationInput']: {
    contactOwnerId?: string | undefined | null
    lastContactAt?: string | undefined | null
    status?: ValueTypes['ConversationStatus'] | undefined | null
    statusChangedAt?: string | undefined | null
    userId: string
  }
  ['UpdateConversationMessageInput']: {
    channel?: ValueTypes['ConversationChannel'] | undefined | null
    id: string
    messageContent?: string | undefined | null
    messageId?: string | undefined | null
    messageMediaKeys?: Array<string> | undefined | null
    replyFromId?: string | undefined | null
    sendAt?: string | undefined | null
    sendAttempts?: number | undefined | null
    sentAt?: string | undefined | null
    status?: ValueTypes['ConversationMessageStatus'] | undefined | null
    userId: string
  }
  ['UpdateEtailerSkuMetricsInput']: {
    date: string
    pageViews?: number | undefined | null
    sku?: string | undefined | null
    skuId: string
    totalSalesAmount?: number | undefined | null
    unitsSold?: number | undefined | null
  }
  ['UpdateFormSequenceConfigurationInput']: {
    active?: ValueTypes['Active'] | undefined | null
    id: string
    sequenceName?: string | undefined | null
    sequences?: Array<ValueTypes['FormSequenceInput']> | undefined | null
  }
  ['UpdateInstagramStoryScreenInput']: {
    exits?: number | undefined | null
    expirationUnixTime?: number | undefined | null
    externalId?: string | undefined | null
    id: string
    impressions?: number | undefined | null
    instagramUserId?: string | undefined | null
    mediaId?: string | undefined | null
    postedAt?: string | undefined | null
    postingDate?: string | undefined | null
    reach?: number | undefined | null
    replies?: number | undefined | null
    s3Bucket?: string | undefined | null
    tapsBack?: number | undefined | null
    tapsForward?: number | undefined | null
    userCampaignId?: string | undefined | null
  }
  ['UpdateMessageInput']: {
    body?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    updatedAt?: string | undefined | null
    userCampaignId?: string | undefined | null
    userHasSeen?: boolean | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateNoteInput']: {
    createdAt?: string | undefined | null
    id: string
    note?: string | undefined | null
    parentId?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateNotificationConfigurationInput']: {
    category?: string | undefined | null
    description?: string | undefined | null
    email?: ValueTypes['ChannelConfigurationInput'] | undefined | null
    enabled?: boolean | undefined | null
    id: string
    inAppChatChannel?: ValueTypes['MessagingChannelConfigurationInput'] | undefined | null
    name?: string | undefined | null
    occurrenceConstraint?: ValueTypes['OccurrenceConstraintInput'] | undefined | null
    push?: ValueTypes['PushChannelConfigurationInput'] | undefined | null
    sms?: ValueTypes['ChannelConfigurationInput'] | undefined | null
  }
  ['UpdateProductBonusConfigSnapshotInput']: {
    closeoutSurveyBonus?: number | undefined | null
    contentBonuses?: Array<ValueTypes['ProductSocialContentInput']> | undefined | null
    discoveryBonus?: number | undefined | null
    payForImpressionsBonus?: ValueTypes['PayForImpressionsBonusConfigInput'] | undefined | null
    productId: string
    snapshotAt: string
  }
  ['UpdateProductInput']: {
    accountRequired?: boolean | undefined | null
    active?: string | undefined | null
    approvalFilters?: Array<ValueTypes['ApprovalFilterValueInput']> | undefined | null
    autoApprovalSeatsAvailable?: number | undefined | null
    autoApprovalStatusTiers?: Array<ValueTypes['StatusTier']> | undefined | null
    autoApprovalV2Config?: ValueTypes['AutoApprovalV2ConfigInput'] | undefined | null
    automaticReviewRelease?: boolean | undefined | null
    baseBonus?: number | undefined | null
    brandAdvocateCount?: number | undefined | null
    brandTalkingPoints?: Array<string> | undefined | null
    buyOnlinePickupInStore?: ValueTypes['BOPISValue'] | undefined | null
    campaignId?: string | undefined | null
    campaignStateMachine?: string | undefined | null
    closeoutFormBonus?: number | undefined | null
    contentApprovalConfiguration?: Array<ValueTypes['ContentApprovalConfigurationInput']> | undefined | null
    contentApprovalLeadDays?: number | undefined | null
    contentRequirement?: Array<ValueTypes['ProductSocialContentInput']> | undefined | null
    creatorCount?: number | undefined | null
    creatorPreference?: ValueTypes['CreatorPreference'] | undefined | null
    creatorPreferenceDetails?: string | undefined | null
    customFtcHashtags?: Array<string> | undefined | null
    customHashtags?: Array<string> | undefined | null
    directPurchaseLink?: string | undefined | null
    engagementRateInstagramRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    engagementRateTiktokRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    followersInstagramRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    followersTiktokRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    hideBrandContentApprovals?: boolean | undefined | null
    id: string
    impressionsInstagramRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    impressionsTiktokRangeRequirement?: ValueTypes['MinMaxRangeInput'] | undefined | null
    instaHandle?: string | undefined | null
    internalCreatorsStagingGuidance?: string | undefined | null
    internalDescription?: string | undefined | null
    internalName?: string | undefined | null
    internalNotes?: string | undefined | null
    isDirectListingPurchase?: boolean | undefined | null
    isQualityRequirementsEnabled?: boolean | undefined | null
    listingGuidance?: string | undefined | null
    maxDiscoveryBonus?: number | undefined | null
    minimumCreatorQualityScore?: number | undefined | null
    nonRetailReviewLink?: string | undefined | null
    nonRetailReviewWebsiteName?: string | undefined | null
    payForClicksEnabled?: ValueTypes['EnabledFlag'] | undefined | null
    payForClicksMinClicks?: number | undefined | null
    payForImpressionsBonus?: ValueTypes['PayForImpressionsBonusConfigInput'] | undefined | null
    payForPerformanceBonus?: ValueTypes['StatusTierBonusConfigInput'] | undefined | null
    payForPostingContentEarly?: ValueTypes['PayForPostingContentEarlyConfigInput'] | undefined | null
    postingDeadlineDaysRelativeToApproval?: number | undefined | null
    productLinkId?: string | undefined | null
    productPostingGuidance?: string | undefined | null
    productPostingGuidanceDocument?: string | undefined | null
    promoCode?: string | undefined | null
    promoCodeGuidance?: string | undefined | null
    promoCodeType?: ValueTypes['PromoCodeType'] | undefined | null
    promoDiscountAmount?: number | undefined | null
    promoDiscountType?: ValueTypes['PromoDiscountType'] | undefined | null
    promoEndDate?: string | undefined | null
    promoLink?: string | undefined | null
    promoStartDate?: string | undefined | null
    promotionLinkId?: string | undefined | null
    purchaseByPromoCode?: boolean | undefined | null
    purchaseDiscountCode?: string | undefined | null
    purchaseDiscountCodeDetails?: string | undefined | null
    purchaseDiscountCodePercentage?: string | undefined | null
    qualityLevel?: ValueTypes['ProductQualityLevel'] | undefined | null
    requestEarlyProductFeedback?: boolean | undefined | null
    requestProductFeedback?: boolean | undefined | null
    requiredProductLinkEnabled?: ValueTypes['RequiredProductLinkFlag'] | undefined | null
    sequenceId?: string | undefined | null
    shopifyPriceRule?: string | undefined | null
    shopifyPromotionId?: string | undefined | null
    shopifyPromotionType?: ValueTypes['ShopifyPromotionType'] | undefined | null
    skuId?: string | undefined | null
    socialMediaExampleUrls?: Array<string> | undefined | null
    stagingApprovalConfiguration?: Array<ValueTypes['StagingApprovalConfigurationInput']> | undefined | null
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined | null
    stagingRequirementType?: ValueTypes['StagingRequirementType'] | undefined | null
    stagingSocialQualityRequirement?: ValueTypes['SocialQuality'] | undefined | null
    tikTokHandle?: string | undefined | null
    unitsPurchased?: number | undefined | null
    validateProductFoundVariations?: Array<string> | undefined | null
    visibleAt?: string | undefined | null
    visibleEndsAt?: string | undefined | null
  }
  ['UpdateProductKeywordsInput']: {
    adminPriority?: number | undefined | null
    brandPriority?: number | undefined | null
    brandResponse?: ValueTypes['KeywordAcceptanceResponse'] | undefined | null
    campaignId?: string | undefined | null
    creationSource?: ValueTypes['ProductKeywordCreationSource'] | undefined | null
    endDate?: string | undefined | null
    heliumAccount?: string | undefined | null
    heliumAsin?: string | undefined | null
    heliumLink?: string | undefined | null
    id: string
    keyword?: string | undefined | null
    monthlySearchVolume?: number | undefined | null
    rank?: number | undefined | null
    rankingHistory?: Array<ValueTypes['RankPointInput']> | undefined | null
    rankingLastSkippedAt?: string | undefined | null
    rankingLastSkippedByUserId?: string | undefined | null
    rankingLastUpdatedAt?: string | undefined | null
    rankingLastUpdatedByUserId?: string | undefined | null
    recommended?: boolean | undefined | null
    skuId?: string | undefined | null
    startDate?: string | undefined | null
    usedForCampaign?: boolean | undefined | null
    visible?: boolean | undefined | null
    weight?: number | undefined | null
  }
  ['UpdateProductRatingSnapshotInput']: {
    numFiveStar?: number | undefined | null
    numFourStar?: number | undefined | null
    numOneStar?: number | undefined | null
    numRatings?: number | undefined | null
    numReviews?: number | undefined | null
    numThreeStar?: number | undefined | null
    numTwoStar?: number | undefined | null
    rating?: number | undefined | null
    sku?: string | undefined | null
    skuId: string
    snapshotAt: string
  }
  ['UpdatePurchaseInput']: {
    amount?: number | undefined | null
    cardId?: string | undefined | null
    currency?: string | undefined | null
    id: string
    isFraud?: boolean | undefined | null
    keywordAtPurchase?: string | undefined | null
    purchasedAt?: string | undefined | null
    shippedAt?: string | undefined | null
    status?: ValueTypes['PurchaseStatus'] | undefined | null
    userCampaignId?: string | undefined | null
  }
  ['UpdateReferralInput']: {
    bonusPayoutId?: string | undefined | null
    campaignId?: string | undefined | null
    id: string
    referralType?: ValueTypes['ReferralType'] | undefined | null
    referredUserId?: string | undefined | null
    referrerUserId?: string | undefined | null
  }
  ['UpdateReviewInput']: {
    attributedUserId?: string | undefined | null
    author?: string | undefined | null
    authorBadge?: string | undefined | null
    badge?: string | undefined | null
    commentCount?: number | undefined | null
    helpfulReactions?: number | undefined | null
    id: string
    negativeReactions?: number | undefined | null
    photos?: Array<string> | undefined | null
    productId?: string | undefined | null
    productVariant?: string | undefined | null
    rating?: number | undefined | null
    removed?: boolean | undefined | null
    reviewedAt?: string | undefined | null
    reviewedSku?: string | undefined | null
    reviewerId?: string | undefined | null
    sku?: string | undefined | null
    skuId?: string | undefined | null
    store?: string | undefined | null
    text?: string | undefined | null
    title?: string | undefined | null
    userCampaignId?: string | undefined | null
    videos?: Array<string> | undefined | null
  }
  ['UpdateSkuInput']: {
    active?: string | undefined | null
    approxRetailPrice?: string | undefined | null
    approxShippingCost?: number | undefined | null
    attributes?: Array<ValueTypes['SkuAttributeInput']> | undefined | null
    availabilityDate?: string | undefined | null
    availabilityNotified?: boolean | undefined | null
    availabilityNotifiedAt?: string | undefined | null
    brandId?: string | undefined | null
    brandListingKey?: string | undefined | null
    brandStoreId?: string | undefined | null
    bundle?: string | undefined | null
    createdByUserId?: string | undefined | null
    creationSource?: ValueTypes['SkuCreationSource'] | undefined | null
    creationSourceUrl?: string | undefined | null
    currency?: ValueTypes['Currency'] | undefined | null
    currentBuyBoxPrice?: number | undefined | null
    currentBuyBoxPriceCents?: number | undefined | null
    estimatedAvailabilityDate?: string | undefined | null
    estimatedDeliveryDays?: number | undefined | null
    estimatedMonthlyUnitsSold?: number | undefined | null
    estimatedWeeklyUnitsSold?: number | undefined | null
    exactListingTitle?: string | undefined | null
    exactRetailPrice?: string | undefined | null
    fullReviewScrapeComplete?: boolean | undefined | null
    id: string
    inventory?: number | undefined | null
    iosAppProviderId?: string | undefined | null
    isAplusPage?: string | undefined | null
    isPrime?: string | undefined | null
    isRecommendationsHidden?: boolean | undefined | null
    itemClassification?: ValueTypes['ItemClassification'] | undefined | null
    lastAutoFilledAt?: string | undefined | null
    lastKnownBuyBoxPrice?: number | undefined | null
    lastKnownBuyBoxPriceCents?: number | undefined | null
    listingBrand?: string | undefined | null
    listingCategory?: Array<string> | undefined | null
    listingDescription?: string | undefined | null
    listingFulfillmentType?: ValueTypes['ListingFulfillmentType'] | undefined | null
    listingGuidance?: string | undefined | null
    listingLink?: string | undefined | null
    mccValue?: ValueTypes['ValidMccCodes'] | undefined | null
    minimumOrderQuantity?: number | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    nonSponsoredListingKey?: string | undefined | null
    parentSkuId?: string | undefined | null
    priceCents?: number | undefined | null
    product?: string | undefined | null
    productImageKey?: string | undefined | null
    productPostingGuidance?: string | undefined | null
    productPostingGuidanceDocument?: string | undefined | null
    productSearchImageKey?: string | undefined | null
    releaseDate?: string | undefined | null
    skuId?: string | undefined | null
    sponsoredListingKey?: string | undefined | null
    storeName?: string | undefined | null
    targetReviewRate?: ValueTypes['RateInput'] | undefined | null
    targetReviewRateInvalid?: string | undefined | null
  }
  ['UpdateSkuSnapshotInput']: {
    inventory?: number | undefined | null
    marketplaceSku: string
    price?: number | undefined | null
    skuId?: string | undefined | null
    snapshotDate: string
  }
  ['UpdateUserBonusPayoutInput']: {
    adminPaymentApproved?: boolean | undefined | null
    bonusType?: ValueTypes['BonusPayoutTypes'] | undefined | null
    createdAt?: string | undefined | null
    failureReason?: string | undefined | null
    id: string
    manuallyCreatedBy?: string | undefined | null
    payoutAmount?: number | undefined | null
    payoutAmountCents?: number | undefined | null
    payoutBatchId?: string | undefined | null
    payoutDate?: string | undefined | null
    payoutLocale?: ValueTypes['WSLocale'] | undefined | null
    payoutState?: ValueTypes['BonusPayoutState'] | undefined | null
    quantity?: number | undefined | null
    referenceId?: string | undefined | null
    releaseAt?: string | undefined | null
    revocationType?: ValueTypes['RevocationType'] | undefined | null
    revokedAt?: string | undefined | null
    userCampaignId?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateUserCampaignChangelogInput']: {
    createdAt: string
    createdByUserId?: string | undefined | null
    entryType?: ValueTypes['UserCampaignChangelogEntryType'] | undefined | null
    extraData?: string | undefined | null
    userCampaignId: string
  }
  ['UpdateUserCampaignInput']: {
    accountScreenShot?: string | undefined | null
    amznReviewLink?: string | undefined | null
    amznReviewSubmissionStatus?: ValueTypes['AmazonReviewSubmissionStatus'] | undefined | null
    applicationRejectedByUserId?: string | undefined | null
    approvalOutcome?: ValueTypes['ApprovalOutcome'] | undefined | null
    approvalStatus?: ValueTypes['UserCampaignApprovalStatus'] | undefined | null
    archivedFeedback?: string | undefined | null
    archivedReason?: ValueTypes['ArchivedReason'] | undefined | null
    autoBuyApprovalDate?: string | undefined | null
    autoBuyRevokeDate?: string | undefined | null
    bestBuyReviewScreenShot?: string | undefined | null
    bonusConfig?: ValueTypes['UserCampaignBonusConfigInput'] | undefined | null
    campaignAcceptanceStatus?: ValueTypes['UserCampaignAcceptanceStatus'] | undefined | null
    campaignApplication?: string | undefined | null
    campaignId?: string | undefined | null
    campaignStatus?: ValueTypes['CampaignStatus'] | undefined | null
    campaignStatusOnArchive?: string | undefined | null
    cardId?: string | undefined | null
    cardRegion?: ValueTypes['StripeRegion'] | undefined | null
    closeoutSurvey?: string | undefined | null
    coarseCampaignStatus?: ValueTypes['CoarseCampaignStatus'] | undefined | null
    contentApprovalLeadDays?: number | undefined | null
    createdAt?: string | undefined | null
    discoverySurvey?: string | undefined | null
    id: string
    influencerApprovalInviteFeedback?: string | undefined | null
    influencerApprovalRejectFeedback?: string | undefined | null
    invoiceKey?: string | undefined | null
    isAutoApproved?: boolean | undefined | null
    keywordAtPurchase?: string | undefined | null
    keywordId?: string | undefined | null
    keywordsV2Hash?: string | undefined | null
    listingKey?: string | undefined | null
    localeV1?: ValueTypes['WSLocale'] | undefined | null
    manualPurchase?: boolean | undefined | null
    moderatorStagingApprovalAt?: string | undefined | null
    moderatorStagingApprovalBy?: string | undefined | null
    moderatorStagingApprovalOutcome?: ValueTypes['StagingApprovalOutcome'] | undefined | null
    orderId?: string | undefined | null
    orderIdInvalidReason?: string | undefined | null
    orderIdSubmissionDate?: string | undefined | null
    orderIdValidationDate?: string | undefined | null
    orderIdValidationStatus?: ValueTypes['OrderIdValidationStatus'] | undefined | null
    orderStatus?: string | undefined | null
    payForClickLinkId?: string | undefined | null
    paypalEmailAtSubmission?: string | undefined | null
    postingDeadline?: string | undefined | null
    postingDeadlineExtended?: boolean | undefined | null
    previousCardIds?: Array<string> | undefined | null
    priority?: ValueTypes['SourcePriority'] | undefined | null
    productCriticalFeedback?: string | undefined | null
    productId?: string | undefined | null
    productPositiveFeedback?: string | undefined | null
    rankPriority?: number | undefined | null
    reviewScreenshot?: string | undefined | null
    reviewScreenshotVerificationResult?: ValueTypes['ReviewScreenshotVerificationResult'] | undefined | null
    schedulingReleaseAt?: string | undefined | null
    searchPhraseKey?: string | undefined | null
    selectedFlow?: string | undefined | null
    selfieVideoApprovalDate?: string | undefined | null
    selfieVideoApprovalNotes?: string | undefined | null
    selfieVideoApprovalStatus?: ValueTypes['SelfieVideoApprovalStatus'] | undefined | null
    selfieVideoKey?: string | undefined | null
    selfieVideoRejectionReason?: string | undefined | null
    selfieVideoSubmissionRequired?: boolean | undefined | null
    selfieVideoSubmittedAt?: string | undefined | null
    sequenceFormSubmissionValues?: Array<ValueTypes['SequenceFormSubmissionValuesInput']> | undefined | null
    shippingAddress?: string | undefined | null
    showEarlyReviewSurvey?: boolean | undefined | null
    statusHistory?: string | undefined | null
    updatedAt?: string | undefined | null
    userId?: string | undefined | null
    walmartReviewScreenShot?: string | undefined | null
  }
  ['UpdateUserCampaignSnapshotInput']: {
    snapshot?: string | undefined | null
    userCampaignId: string
    userCampaignVersion: number
  }
  ['UpdateUserCampaignSocialContentDraftHistoryInput']: {
    id: string
    snapshot?: string | undefined | null
    snapshotDate?: string | undefined | null
    userCampaignSocialContentId?: string | undefined | null
  }
  ['UpdateUserCampaignSocialContentInput']: {
    approvalFeedback?: string | undefined | null
    approvalStatus?: ValueTypes['ContentApprovalStatus'] | undefined | null
    approvedAt?: string | undefined | null
    approverPWUserId?: string | undefined | null
    approverUserId?: string | undefined | null
    bonusEarned?: number | undefined | null
    bonusOverride?: number | undefined | null
    caption?: string | undefined | null
    channel?: ValueTypes['SocialChannel'] | undefined | null
    clientFeedback?: Array<ValueTypes['SocialMediaFeedbackInput']> | undefined | null
    complianceReview?: Array<ValueTypes['ContentComplianceReviewCriteriaInput']> | undefined | null
    complianceReviewStatus?: ValueTypes['ContentComplianceReviewStatus'] | undefined | null
    complianceReviewUserId?: string | undefined | null
    complianceReviewedAt?: string | undefined | null
    contentType?: ValueTypes['SocialContentType'] | undefined | null
    draftEnteredAdminApprovalAt?: string | undefined | null
    draftEnteredBrandApprovalAt?: string | undefined | null
    draftFeedback?: Array<string> | undefined | null
    draftHistory?: Array<string> | undefined | null
    draftMedia?: Array<string> | undefined | null
    draftPreApprovedText?: string | undefined | null
    draftQualityScore?: number | undefined | null
    draftReview?: Array<ValueTypes['ContentComplianceReviewCriteriaInput']> | undefined | null
    draftStatus?: ValueTypes['DraftStatus'] | undefined | null
    draftSubmittedAt?: string | undefined | null
    draftText?: string | undefined | null
    earlySubmissionBonusEarnedCents?: number | undefined | null
    experimentId?: string | undefined | null
    externalUrl?: string | undefined | null
    groupId?: string | undefined | null
    hashtags?: Array<string> | undefined | null
    id: string
    instance?: number | undefined | null
    internalFeedback?: Array<ValueTypes['SocialMediaFeedbackInput']> | undefined | null
    isFavorite?: boolean | undefined | null
    isHidden?: boolean | undefined | null
    isPurchased?: boolean | undefined | null
    isUnavailable?: boolean | undefined | null
    manualUploadType?: ValueTypes['ManualUploadType'] | undefined | null
    mediaId?: string | undefined | null
    mediaUrls?: Array<string> | undefined | null
    metrics?: Array<ValueTypes['SocialMetricInput']> | undefined | null
    paymentId?: string | undefined | null
    postedAt?: string | undefined | null
    qualityScore?: number | undefined | null
    rejectionReason?: string | undefined | null
    requestedSocialScript?: boolean | undefined | null
    requirementLevel?: ValueTypes['ContentRequirementLevel'] | undefined | null
    scrapeStatus?: ValueTypes['ScrapeStatus'] | undefined | null
    unavailableAt?: string | undefined | null
    usedRequestedSocialScript?: boolean | undefined | null
    userCampaignId?: string | undefined | null
    videoEndTime?: string | undefined | null
    videoStartTime?: string | undefined | null
  }
  ['UpdateUserCampaignSocialContentReviewInput']: {
    createdAt?: string | undefined | null
    reviewFeedback?: Array<ValueTypes['UserCampaignSocialContentReviewFeedbackInput']> | undefined | null
    reviewOutcome?: ValueTypes['UserCampaignSocialContentReviewOutcome'] | undefined | null
    reviewStatus?: ValueTypes['UserCampaignSocialContentReviewStatus'] | undefined | null
    reviewType: ValueTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined | null
    reviewerAssigneeId?: string | undefined | null
    reviewerFirstName?: string | undefined | null
    reviewerId?: string | undefined | null
    reviewerLastName?: string | undefined | null
    userCampaignSocialContentId: string
    userId?: string | undefined | null
  }
  ['UpdateUserDeviceSnapshotInput']: {
    id: string
    idfv?: string | undefined | null
    ipAddress?: string | undefined | null
    isEmulator?: boolean | undefined | null
    snapshotAt?: string | undefined | null
    timezone?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateUserFollowerInput']: {
    createdAt?: string | undefined | null
    followerUserId: string
    isFavorite?: ValueTypes['FavoriteFlag'] | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['UpdateUserInput']: {
    active?: ValueTypes['Active'] | undefined | null
    addressComponents?: string | undefined | null
    amznLastChecked?: string | undefined | null
    amznLink?: string | undefined | null
    amznPrime?: string | undefined | null
    amznProfileId?: string | undefined | null
    amznReviewerId?: string | undefined | null
    amznVerified?: string | undefined | null
    attributedReviewCount?: number | undefined | null
    blogWebsite?: string | undefined | null
    brandContentRatingAvg?: number | undefined | null
    campaignAcceptanceRate?: number | undefined | null
    campaignApprovalRate?: number | undefined | null
    campaignCompletionRate?: number | undefined | null
    campaignReviewRate?: number | undefined | null
    cardProfileUpdates?: string | undefined | null
    cardholderId?: string | undefined | null
    cardholderRegion?: ValueTypes['StripeRegion'] | undefined | null
    characteristics?: Array<ValueTypes['UserCharacteristicInput']> | undefined | null
    characteristicsUpdatedAt?: string | undefined | null
    claimedReviewCount?: number | undefined | null
    collisionSkuSet?: Array<string> | undefined | null
    collisionSkuSetCalculatedAt?: string | undefined | null
    completedLifestyleCampaignCount?: number | undefined | null
    completedLiftCampaignCount?: number | undefined | null
    completedSocialCampaignCount?: number | undefined | null
    contentRating?: ValueTypes['UserContentRatingInput'] | undefined | null
    contentRatingCompletedAt?: string | undefined | null
    contentRatingCompletionTimeMillis?: number | undefined | null
    contentRatingInstagramCompletionTimeMillis?: number | undefined | null
    contentRatingInstagramSponsorshipContent?:
      | Array<ValueTypes['ContentRatingSponsorshipContentInput']>
      | undefined
      | null
    contentRatingQualityScore?: number | undefined | null
    contentRatingReviewStatus?: ValueTypes['ContentRatingReviewStatus'] | undefined | null
    contentRatingSponsorshipContent?: Array<ValueTypes['ContentRatingSponsorshipContentInput']> | undefined | null
    contentRatingSubmittedBy?: string | undefined | null
    contentRatingTiktokCompletionTimeMillis?: number | undefined | null
    contentRatingTiktokSponsorshipContent?: Array<ValueTypes['ContentRatingSponsorshipContentInput']> | undefined | null
    contentTypes?: Array<string> | undefined | null
    createdAt?: string | undefined | null
    date_of_birth?: string | undefined | null
    deactivationDate?: string | undefined | null
    deactivationReason?: string | undefined | null
    declineToReviewCount?: number | undefined | null
    deranked?: ValueTypes['Deranked_Reason'] | undefined | null
    email?: string | undefined | null
    emailOptOut?: boolean | undefined | null
    facebookAccessToken?: string | undefined | null
    facebookAccessTokenExpiry?: string | undefined | null
    facebookPageId?: string | undefined | null
    facebookPageIsSubscribed?: boolean | undefined | null
    facebookPermissionScopes?: Array<string | undefined | null> | undefined | null
    facebookUserId?: string | undefined | null
    firstName?: string | undefined | null
    followsWesponsored?: string | undefined | null
    fraudReason?: ValueTypes['FraudReason'] | undefined | null
    fraudReviewAt?: string | undefined | null
    fraudReviewStatus?: ValueTypes['FraudReviewStatus'] | undefined | null
    gender?: string | undefined | null
    hasTrueEngagement?: boolean | undefined | null
    id: string
    idfaId?: string | undefined | null
    incompleteCampaignCount?: number | undefined | null
    instagramBio?: string | undefined | null
    instagramBusinessCategoryName?: string | undefined | null
    instagramCategoryName?: string | undefined | null
    instagramChecked?: string | undefined | null
    instagramContentTypeRating?: Array<ValueTypes['ContentTypeRating']> | undefined | null
    instagramExternalLink?: string | undefined | null
    instagramFollowerCountryDistribution?: Array<ValueTypes['InstagramCountryDistributionItemInput']> | undefined | null
    instagramFollowerGenderAgeDistribution?: ValueTypes['InstagramGenderAgeDistributionInput'] | undefined | null
    instagramFollowersInt?: number | undefined | null
    instagramHandle?: string | undefined | null
    instagramIsBusiness?: string | undefined | null
    instagramIsPrivate?: string | undefined | null
    instagramIsRecentlyJoined?: string | undefined | null
    instagramIsVerified?: string | undefined | null
    instagramLastChecked?: string | undefined | null
    instagramMedianShares?: number | undefined | null
    instagramMetricsRetrievedAt?: string | undefined | null
    instagramName?: string | undefined | null
    instagramPostsInt?: number | undefined | null
    instagramPriority?: ValueTypes['PlatformPriority'] | undefined | null
    instagramProfilePic?: string | undefined | null
    instagramTrailingComments?: number | undefined | null
    instagramTrailingDateOfOldestPost?: string | undefined | null
    instagramTrailingLikes?: number | undefined | null
    instagramTrailingNumberOfPosts?: number | undefined | null
    instagramTrailingPostEngagementAvg?: number | undefined | null
    instagramTrailingPostEngagementRateAvg?: number | undefined | null
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined | null
    instagramTrailingPostEngagementRateMedian?: number | undefined | null
    instagramTrailingPostImpressionsAvg?: number | undefined | null
    instagramTrailingPostImpressionsMedian?: number | undefined | null
    instagramTrailingPostSponsoredPercentage?: number | undefined | null
    instagramTrailingPostViewsMedian?: number | undefined | null
    instagramUserId?: string | undefined | null
    instagramVerificationMethod?: ValueTypes['SocialVerificationMethod'] | undefined | null
    internalGender?: ValueTypes['UserGender'] | undefined | null
    isBrandSafe?: ValueTypes['BrandSafe'] | undefined | null
    isBrandSafeReason?: string | undefined | null
    isBrandSafeUpdatedAt?: string | undefined | null
    lastActiveAt?: string | undefined | null
    lastName?: string | undefined | null
    manualScoringLockedUntil?: string | undefined | null
    moreinfo?: string | undefined | null
    numEvenDollarPurchases?: number | undefined | null
    numPurchases?: number | undefined | null
    paypalEmail?: string | undefined | null
    phoneNumber?: string | undefined | null
    profilePicture?: string | undefined | null
    pushAddresses?: Array<ValueTypes['PushAddressInput']> | undefined | null
    pushOptOut?: boolean | undefined | null
    referralCode?: string | undefined | null
    referralCount?: number | undefined | null
    referrer?: string | undefined | null
    signupCountry?: string | undefined | null
    smsOptOut?: boolean | undefined | null
    socialQuality?: ValueTypes['SocialQuality'] | undefined | null
    specialAttributes?: ValueTypes['UserAttributesInput'] | undefined | null
    statusTier?: ValueTypes['StatusTier'] | undefined | null
    statusTierHistory?: Array<ValueTypes['StatusTierEntryInput']> | undefined | null
    statusTierLastUpdatedAt?: string | undefined | null
    supportPriorityAcknowledgedAt?: string | undefined | null
    supportPriorityFlag?: ValueTypes['SupportPriorityFlag'] | undefined | null
    supportPriorityFlagSetAt?: string | undefined | null
    targetAccount?: string | undefined | null
    tiktokAccessToken?: string | undefined | null
    tiktokAccessTokenExpiry?: string | undefined | null
    tiktokAccountId?: string | undefined | null
    tiktokAveragePostEngagementRate?: number | undefined | null
    tiktokAveragePostEngagementRateViews?: number | undefined | null
    tiktokBio?: string | undefined | null
    tiktokContentTypeRating?: Array<ValueTypes['ContentTypeRating']> | undefined | null
    tiktokFollowersInt?: number | undefined | null
    tiktokHandle?: string | undefined | null
    tiktokMedianComments?: number | undefined | null
    tiktokMedianLikes?: number | undefined | null
    tiktokMedianPostEngagement?: number | undefined | null
    tiktokMedianPostEngagementRate?: number | undefined | null
    tiktokMedianPostEngagementRateViews?: number | undefined | null
    tiktokMedianShares?: number | undefined | null
    tiktokMedianViews?: number | undefined | null
    tiktokMetricsRetrievedAt?: string | undefined | null
    tiktokName?: string | undefined | null
    tiktokPermissionScopes?: Array<string> | undefined | null
    tiktokPriority?: ValueTypes['PlatformPriority'] | undefined | null
    tiktokProfilePic?: string | undefined | null
    tiktokRefreshToken?: string | undefined | null
    tiktokRefreshTokenExpiry?: string | undefined | null
    tiktokSponsoredPostPercentage?: number | undefined | null
    tiktokVerificationMethod?: ValueTypes['SocialVerificationMethod'] | undefined | null
    tiktokVerified?: string | undefined | null
    timeZone?: string | undefined | null
    trackingStatus?: ValueTypes['TrackingStatus'] | undefined | null
    twitchAccount?: string | undefined | null
    type?: string | undefined | null
    utmReferral?: ValueTypes['UtmReferralInput'] | undefined | null
    wmartAccount?: string | undefined | null
    youtubeAccessToken?: string | undefined | null
    youtubeAccessTokenExpiry?: string | undefined | null
    youtubeAccount?: string | undefined | null
    youtubeAveragePostEngagementRate?: number | undefined | null
    youtubeChannelDescription?: string | undefined | null
    youtubeChannelFollowersInt?: number | undefined | null
    youtubeChannelHandle?: string | undefined | null
    youtubeChannelId?: string | undefined | null
    youtubeChannelPicture?: string | undefined | null
    youtubeChannelTitle?: string | undefined | null
    youtubeIdToken?: string | undefined | null
    youtubeMedianViews?: number | undefined | null
    youtubeMetricsRetrievedAt?: string | undefined | null
    youtubePermissionScopes?: Array<string> | undefined | null
    youtubeRefreshToken?: string | undefined | null
    youtubeVerificationMethod?: ValueTypes['SocialVerificationMethod'] | undefined | null
  }
  ['UpdateUserLinkInput']: {
    campaignId?: string | undefined | null
    clicksPerPeriod?: Array<ValueTypes['ClicksRecordsInput']> | undefined | null
    createdAt?: string | undefined | null
    id: string
    linkId?: string | undefined | null
    originalLink?: string | undefined | null
    rank?: number | undefined | null
    storageType?: ValueTypes['LinkStorageType'] | undefined | null
    uniqueClicks?: number | undefined | null
    updatedAt?: string | undefined | null
    userEncodedLink?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateUserNotificationPreferenceInput']: {
    category: string
    emailOptOut?: boolean | undefined | null
    pushOptOut?: boolean | undefined | null
    smsOptOut?: boolean | undefined | null
    userId: string
  }
  ['UpdateUserPayoutRequestInput']: {
    failureReason?: string | undefined | null
    id: string
    manuallyApprovedBy?: string | undefined | null
    payoutAmountCents?: number | undefined | null
    payoutBatchId?: string | undefined | null
    payoutDate?: string | undefined | null
    payoutLocale?: ValueTypes['WSLocale'] | undefined | null
    payoutState?: ValueTypes['BonusPayoutState'] | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateUserStagingApprovalInput']: {
    id: string
    reviewFeedback?: string | undefined | null
    reviewedAt?: string | undefined | null
    reviewedBy?: string | undefined | null
    reviewedByEmail?: string | undefined | null
    reviewedByName?: string | undefined | null
    reviewedBySource?: ValueTypes['UserStagingApprovalReviewSource'] | undefined | null
    userCampaignId?: string | undefined | null
    userStagingApprovalOutcome?: ValueTypes['UserStagingApprovalOutcome'] | undefined | null
    userStagingApprovalType?: ValueTypes['UserStagingApprovalType'] | undefined | null
  }
  ['UpdateVideoContentScanInput']: {
    audioDuration?: number | undefined | null
    audioEndAt?: number | undefined | null
    audioStartFrom?: number | undefined | null
    contentSafetyAnalysis?: ValueTypes['ContentSafetyAnalysisInput'] | undefined | null
    id: string
    processFinishedAt?: string | undefined | null
    processId?: string | undefined | null
    processInitiatedAt?: string | undefined | null
    scanStatus?: ValueTypes['ContentScanStatus'] | undefined | null
    socialMediaUrl?: string | undefined | null
    transcription?: string | undefined | null
    transcriptionConfidence?: number | undefined | null
    url?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UserAttributesInput']: {
    isMother?: boolean | undefined | null
  }
  ['UserCampaignBonusConfigInput']: {
    closeoutSurveyBonus?: number | undefined | null
    contentBonuses?: Array<ValueTypes['ProductSocialContentInput']> | undefined | null
    discoveryBonus?: number | undefined | null
    expectedBonus?: string | undefined | null
    hasCustomBonusConfig?: boolean | undefined | null
    payForImpressionsBonus?: ValueTypes['PayForImpressionsBonusConfigInput'] | undefined | null
  }
  ['UserCampaignSearchFields']: {
    campaignId?: ValueTypes['SearchInputString'] | undefined | null
    campaignStatus?: ValueTypes['SearchInputString'] | undefined | null
    prospect?: ValueTypes['ProspectSearchFields'] | undefined | null
  }
  ['UserCampaignSocialContentReviewFeedbackInput']: {
    atTime?: number | undefined | null
    createdAt?: string | undefined | null
    createdByFirstName?: string | undefined | null
    createdByLastName?: string | undefined | null
    createdByUserId?: string | undefined | null
    forAssetAtIndex?: number | undefined | null
    id?: string | undefined | null
    message?: string | undefined | null
    rating?: number | undefined | null
    updatedAt?: string | undefined | null
    visibility?: ValueTypes['ReviewFeedbackVisibility'] | undefined | null
  }
  ['UserCharacteristicInput']: {
    characteristic: string
    reason?: string | undefined | null
  }
  ['UserContentRatingInput']: {
    instagramContentQualityRating?: ValueTypes['ContentQualityRating'] | undefined | null
    instagramContentTypeRating?: Array<ValueTypes['ContentTypeRating']> | undefined | null
    instagramCreatorContentScore?: number | undefined | null
    instagramCreatorContentScoreRaw?: Array<ValueTypes['CreatorContentRatingRawValueInput']> | undefined | null
    instagramCreatorSponsorshipContentScore?: number | undefined | null
    instagramSubmittedBy?: string | undefined | null
    submittedBy?: string | undefined | null
    tiktokContentQualityRating?: ValueTypes['ContentQualityRating'] | undefined | null
    tiktokContentTypeRating?: Array<ValueTypes['ContentTypeRating']> | undefined | null
    tiktokCreatorContentScore?: number | undefined | null
    tiktokCreatorContentScoreRaw?: Array<ValueTypes['CreatorContentRatingRawValueInput']> | undefined | null
    tiktokCreatorSponsorshipContentScore?: number | undefined | null
    tiktokSubmittedBy?: string | undefined | null
  }
  ['UserSearchFields']: {
    addressComponents?: ValueTypes['AddressComponentsSearchFields'] | undefined | null
    campaignAcceptanceRate?: ValueTypes['SearchInputNumber'] | undefined | null
    campaignCompletionRate?: ValueTypes['SearchInputNumber'] | undefined | null
    campaignReviewRate?: ValueTypes['SearchInputNumber'] | undefined | null
    claimedReviewCount?: ValueTypes['SearchInputNumber'] | undefined | null
    collisionSkuSet?: ValueTypes['SearchInputString'] | undefined | null
    contentRatingQualityScore?: ValueTypes['SearchInputNumber'] | undefined | null
    createdAt?: ValueTypes['SearchInputString'] | undefined | null
    declineToReviewCount?: ValueTypes['SearchInputNumber'] | undefined | null
    email?: ValueTypes['SearchInputString'] | undefined | null
    firstName?: ValueTypes['SearchInputString'] | undefined | null
    followers?: ValueTypes['FollowersSearchFields'] | undefined | null
    gender?: ValueTypes['SearchInputString'] | undefined | null
    incompleteCampaignCount?: ValueTypes['SearchInputNumber'] | undefined | null
    instagramContentTypeRating?: ValueTypes['SearchInputString'] | undefined | null
    instagramFollowerCountryDistribution?:
      | ValueTypes['InstagramFollowerCountryDistributionSearchFields']
      | undefined
      | null
    instagramFollowersInt?: ValueTypes['SearchInputNumber'] | undefined | null
    instagramHandle?: ValueTypes['SearchInputString'] | undefined | null
    instagramTrailingPostEngagementRateAvg?: ValueTypes['SearchInputNumber'] | undefined | null
    instagramTrailingPostEngagementRateMedian?: ValueTypes['SearchInputNumber'] | undefined | null
    instagramTrailingPostImpressionsAvg?: ValueTypes['SearchInputNumber'] | undefined | null
    instagramTrailingPostImpressionsMedian?: ValueTypes['SearchInputNumber'] | undefined | null
    instagramTrailingPostSponsoredPercentage?: ValueTypes['SearchInputNumber'] | undefined | null
    instagramVerificationMethod?: ValueTypes['SearchInputString'] | undefined | null
    lastName?: ValueTypes['SearchInputString'] | undefined | null
    osScript?: ValueTypes['SearchInputQueryScript'] | undefined | null
    socialQuality?: ValueTypes['SearchInputString'] | undefined | null
    tiktokAveragePostEngagementRate?: ValueTypes['SearchInputNumber'] | undefined | null
    tiktokContentTypeRating?: ValueTypes['SearchInputString'] | undefined | null
    tiktokFollowersInt?: ValueTypes['SearchInputNumber'] | undefined | null
    tiktokHandle?: ValueTypes['SearchInputString'] | undefined | null
    tiktokMedianPostEngagementRateViews?: ValueTypes['SearchInputNumber'] | undefined | null
    tiktokMedianViews?: ValueTypes['SearchInputNumber'] | undefined | null
    tiktokSponsoredPostPercentage?: ValueTypes['SearchInputNumber'] | undefined | null
    tiktokVerificationMethod?: ValueTypes['SearchInputString'] | undefined | null
    userCampaigns?: ValueTypes['UserCampaignSearchFields'] | undefined | null
    youtubeAveragePostEngagementRate?: ValueTypes['SearchInputNumber'] | undefined | null
    youtubeChannelFollowersInt?: ValueTypes['SearchInputNumber'] | undefined | null
    youtubeChannelHandle?: ValueTypes['SearchInputString'] | undefined | null
    youtubeMedianViews?: ValueTypes['SearchInputNumber'] | undefined | null
    youtubeVerificationMethod?: ValueTypes['SearchInputString'] | undefined | null
  }
  ['UserSearchInput']: {
    and?: Array<ValueTypes['UserSearchFields']> | undefined | null
    from?: number | undefined | null
    not?: Array<ValueTypes['UserSearchFields']> | undefined | null
    or?: Array<Array<ValueTypes['UserSearchFields']> | undefined | null> | undefined | null
    size?: number | undefined | null
    sort?: Array<ValueTypes['UserSearchInputSort']> | undefined | null
  }
  ['UserSearchInputSort']: {
    field?: string | undefined | null
    order: ValueTypes['SortOrder']
    osScript?: ValueTypes['SearchInputSortScript'] | undefined | null
  }
  ['UtmReferralInput']: {
    campaign?: string | undefined | null
    content?: string | undefined | null
    medium?: string | undefined | null
    source?: string | undefined | null
    term?: string | undefined | null
  }
}

export type ResolverInputTypes = {
  ['AdminSendPushMessageResponse']: AliasType<{
    message?: boolean | `@${string}`
    statusCode?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ApplyToCampaignOutput']: AliasType<{
    campaignStatus?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isAutoApproved?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ApprovalFilterValue']: AliasType<{
    filter?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ApproveUserCampaignForPurchaseResponse']: AliasType<{
    approvalOutcome?: boolean | `@${string}`
    campaignStatus?: boolean | `@${string}`
    coarseCampaignStatus?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoApprovalCriteria']: AliasType<{
    completedCampaigns?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoApprovalV2Config']: AliasType<{
    autoApprovalCriteria?: ResolverInputTypes['AutoApprovalCriteria']
    enabled?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuyDistribution']: AliasType<{
    createdAt?: boolean | `@${string}`
    description?: boolean | `@${string}`
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    schedule?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuyFlowConfiguration']: AliasType<{
    createdAt?: boolean | `@${string}`
    dailyPurchaseMax?: boolean | `@${string}`
    delayStrategy?: boolean | `@${string}`
    flowId?: boolean | `@${string}`
    insufficientCreators?: boolean | `@${string}`
    latestSkipReason?: boolean | `@${string}`
    pauseReason?: boolean | `@${string}`
    releaseSchedule?: [
      {
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleConnection']
    ]
    schedule?: ResolverInputTypes['AutoBuySchedule']
    startDate?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuyFlowReleaseSchedule']: AliasType<{
    actualPurchases?: boolean | `@${string}`
    actualVerifiedPurchases?: boolean | `@${string}`
    adjustedDesiredPurchases?: boolean | `@${string}`
    autoApprovalSeats?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    desiredPurchases?: boolean | `@${string}`
    flowId?: boolean | `@${string}`
    manualPurchases?: boolean | `@${string}`
    manualReleases?: boolean | `@${string}`
    projectedReleaseCount?: boolean | `@${string}`
    releaseCount?: boolean | `@${string}`
    released?: boolean | `@${string}`
    skippedReason?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuyRelease']: AliasType<{
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    releaseDateTime?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AutoBuySchedule']: AliasType<{
    date?: boolean | `@${string}`
    desiredPurchaseCount?: boolean | `@${string}`
    willPurchasePercentage?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BillingContact']: AliasType<{
    email?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Brand']: AliasType<{
    active?: boolean | `@${string}`
    amazonBrandName?: boolean | `@${string}`
    brandApis?: [
      {
        filter?: ResolverInputTypes['ModelBrandApiFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelBrandApiConnection']
    ]
    brandDetailsSubmitted?: boolean | `@${string}`
    brandInstaHandle?: boolean | `@${string}`
    brandLogoImageKey?: boolean | `@${string}`
    brandStoreConfirmationImage?: boolean | `@${string}`
    brandStoreConfirmationMobileImage?: boolean | `@${string}`
    brandStoreScrapeUrl?: boolean | `@${string}`
    brandTikTokHandle?: boolean | `@${string}`
    companyId?: boolean | `@${string}`
    contactEmails?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    defaultCreatorPreference?: boolean | `@${string}`
    defaultCreatorPreferenceDetails?: boolean | `@${string}`
    defaultHashtags?: boolean | `@${string}`
    defaultMarketingBriefKey?: boolean | `@${string}`
    defaultPostingGuidelines?: boolean | `@${string}`
    defaultPostingInspirationLinks?: boolean | `@${string}`
    emailsSent?: boolean | `@${string}`
    fullScrapedAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isBrandStoreScrapeEnabled?: boolean | `@${string}`
    isSeller?: boolean | `@${string}`
    isVendor?: boolean | `@${string}`
    isWalmartScrapeEnabled?: boolean | `@${string}`
    lastScrapedAt?: boolean | `@${string}`
    name?: boolean | `@${string}`
    nameAudioKey?: boolean | `@${string}`
    paymentBillingContact?: ResolverInputTypes['BillingContact']
    platform?: boolean | `@${string}`
    postingGuidanceDocument?: boolean | `@${string}`
    region?: boolean | `@${string}`
    socialMediaExampleUrls?: boolean | `@${string}`
    subscriptionEndsAt?: boolean | `@${string}`
    subscriptionStartsAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    walmartBrandName?: boolean | `@${string}`
    walmartLastScrapedAt?: boolean | `@${string}`
    walmartScrapeUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BrandApi']: AliasType<{
    accessToken?: boolean | `@${string}`
    api?: boolean | `@${string}`
    apiVersion?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    enabled?: boolean | `@${string}`
    locale?: boolean | `@${string}`
    refreshToken?: boolean | `@${string}`
    shop?: boolean | `@${string}`
    shopId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BudgetDetail']: AliasType<{
    authorizedUnits?: boolean | `@${string}`
    baseUnits?: boolean | `@${string}`
    budgetSpent?: boolean | `@${string}`
    budgetSplit?: ResolverInputTypes['BudgetSplitComponent']
    committedReviewsCount?: boolean | `@${string}`
    contentGuidelinesSubmitted?: boolean | `@${string}`
    creatorTargetingEnabled?: boolean | `@${string}`
    creatorTargetingSubmitted?: boolean | `@${string}`
    lifestyleCommittedContentCount?: boolean | `@${string}`
    marketingFee?: boolean | `@${string}`
    premiumLifestyleCommittedContentCount?: boolean | `@${string}`
    productKeywordsEnabled?: boolean | `@${string}`
    productKeywordsSubmitted?: boolean | `@${string}`
    promoCodeSubmitted?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    socialCommittedContentCount?: boolean | `@${string}`
    totalProductCost?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BudgetSplitComponent']: AliasType<{
    autoApprovalSeatsAvailable?: boolean | `@${string}`
    autoBuyActive?: boolean | `@${string}`
    autoBuySchedule?: boolean | `@${string}`
    count?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    unitsPurchased?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Campaign']: AliasType<{
    aboutSection?: boolean | `@${string}`
    active?: boolean | `@${string}`
    actualStartDate?: boolean | `@${string}`
    amountPaidByClient?: boolean | `@${string}`
    anticipatedStartDate?: boolean | `@${string}`
    applicationForm?: boolean | `@${string}`
    applicationProductLink?: ResolverInputTypes['UserLink']
    applicationProductLinkId?: boolean | `@${string}`
    applicationStatus?: boolean | `@${string}`
    attributionRefUrlSuffix?: boolean | `@${string}`
    authorizedUnits?: boolean | `@${string}`
    badges?: ResolverInputTypes['CampaignBadge']
    brand?: ResolverInputTypes['Brand']
    brandId?: boolean | `@${string}`
    brandProductShort?: boolean | `@${string}`
    brandStoreLink?: boolean | `@${string}`
    budgetSpent?: boolean | `@${string}`
    campaignProductType?: boolean | `@${string}`
    campaignProducts?: [
      {
        filter?: ResolverInputTypes['ModelProductFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductConnection']
    ]
    campaignReferralBonusAmount?: boolean | `@${string}`
    campaignReferralBonusEnabled?: boolean | `@${string}`
    campaignStateMachine?: boolean | `@${string}`
    closeoutReleaseFailureReason?: boolean | `@${string}`
    closeoutReleaseRate?: ResolverInputTypes['Rate']
    collisionSkuDensity?: ResolverInputTypes['CollisionDensityValue']
    commissionFlatRate?: boolean | `@${string}`
    commissionPercentageRate?: boolean | `@${string}`
    committedClicks?: boolean | `@${string}`
    committedPlacementWinsCount?: boolean | `@${string}`
    committedReviewsCount?: boolean | `@${string}`
    contentApprovalRequired?: boolean | `@${string}`
    contentCompleteDeadline?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    creationSourceVersion?: boolean | `@${string}`
    dailyClicks?: [
      {
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignClicksFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignClicksConnection']
    ]
    earlyReviewEnabled?: boolean | `@${string}`
    enableContentComplianceReview?: boolean | `@${string}`
    endDate?: boolean | `@${string}`
    excludeGender?: boolean | `@${string}`
    expectedBonus?: boolean | `@${string}`
    followersCountCeiling?: boolean | `@${string}`
    followersCountFloor?: boolean | `@${string}`
    goal?: boolean | `@${string}`
    id?: boolean | `@${string}`
    influencerApprovalEnabled?: boolean | `@${string}`
    lifestyleCommittedContentCount?: boolean | `@${string}`
    localeV1?: boolean | `@${string}`
    marketingFee?: boolean | `@${string}`
    marketingFormFilled?: boolean | `@${string}`
    newCreatorsOnly?: boolean | `@${string}`
    payForClicksEnabled?: boolean | `@${string}`
    payForClicksGeneratedPerPeriod?: ResolverInputTypes['ClicksRecords']
    payForClicksMinClicks?: boolean | `@${string}`
    payForClicksOriginalLink?: boolean | `@${string}`
    payForClicksTotalClicksGenerated?: boolean | `@${string}`
    payForPerformanceEnabled?: boolean | `@${string}`
    postingGuidance?: boolean | `@${string}`
    preApplicationProductImage?: boolean | `@${string}`
    preApplicationProductPriceRange?: boolean | `@${string}`
    preApplicationUgcProductImage?: boolean | `@${string}`
    preLaunchDate?: boolean | `@${string}`
    premiumLifestyleCommittedContentCount?: boolean | `@${string}`
    productKeywords?: [
      {
        filter?: ResolverInputTypes['ModelProductKeywordsFilterInput'] | undefined | null
        keyword?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductKeywordsConnection']
    ]
    requiredProductLinksEnabled?: boolean | `@${string}`
    reviewVotingTarget?: boolean | `@${string}`
    sample?: boolean | `@${string}`
    shellCampaignBase?: boolean | `@${string}`
    sku?: ResolverInputTypes['Sku']
    skuId?: boolean | `@${string}`
    skuPriceOverride?: boolean | `@${string}`
    socialCommittedContentCount?: boolean | `@${string}`
    sourcingPriority?: boolean | `@${string}`
    sponsorshipInstructions?: boolean | `@${string}`
    title?: boolean | `@${string}`
    totalEarlyReviewsAllowed?: boolean | `@${string}`
    totalProductCost?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userAutoApproved?: boolean | `@${string}`
    visibleToUser?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignBadge']: AliasType<{
    type?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignClicks']: AliasType<{
    campaignId?: boolean | `@${string}`
    clicks?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignForUser']: AliasType<{
    about?: boolean | `@${string}`
    badges?: ResolverInputTypes['CampaignBadge']
    brandName?: boolean | `@${string}`
    campaignProductType?: boolean | `@${string}`
    campaignReferralBonusAmount?: boolean | `@${string}`
    campaignReferralEnabled?: boolean | `@${string}`
    contentApprovalLeadDays?: boolean | `@${string}`
    expectedBonusMax?: boolean | `@${string}`
    expectedBonusMin?: boolean | `@${string}`
    flowForUser?: ResolverInputTypes['CampaignForUserFlow']
    flows?: ResolverInputTypes['CampaignForUserFlow']
    hasUserApplied?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isAdminOnlyView?: boolean | `@${string}`
    isFreeGift?: boolean | `@${string}`
    isPayForClicksEnabled?: boolean | `@${string}`
    isPayForPerformanceEnabled?: boolean | `@${string}`
    isShipToCustomer?: boolean | `@${string}`
    isUserAutoApproved?: boolean | `@${string}`
    locale?: boolean | `@${string}`
    payForPerformanceBonusFlatRate?: boolean | `@${string}`
    payForPerformanceBonusPercentageRate?: boolean | `@${string}`
    postingDeadline?: boolean | `@${string}`
    preApplicationProductImage?: boolean | `@${string}`
    preApplicationProductName?: boolean | `@${string}`
    preApplicationProductPrice?: boolean | `@${string}`
    preApplicationUgcProductImage?: boolean | `@${string}`
    productForUser?: ResolverInputTypes['CampaignForUserProduct']
    productImage?: boolean | `@${string}`
    productLink?: boolean | `@${string}`
    productPrice?: boolean | `@${string}`
    productTitle?: boolean | `@${string}`
    products?: ResolverInputTypes['CampaignForUserProduct']
    productsForUser?: ResolverInputTypes['CampaignForUserProduct']
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    specialInstructions?: boolean | `@${string}`
    sponsorshipInstructions?: boolean | `@${string}`
    storeName?: boolean | `@${string}`
    title?: boolean | `@${string}`
    totalSeatsAvailable?: boolean | `@${string}`
    userCampaignCreatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userCampaignStatus?: boolean | `@${string}`
    userSpecificBonusConfig?: ResolverInputTypes['UserCampaignBonusConfig']
    __typename?: boolean | `@${string}`
  }>
  ['CampaignForUserFlow']: AliasType<{
    autoApprovalSeatsAvailable?: boolean | `@${string}`
    contentRequirement?: ResolverInputTypes['ProductSocialContent']
    customFtcHashtags?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isPayForClicksEnabled?: boolean | `@${string}`
    payForImpressionsBonusConfig?: ResolverInputTypes['PayForImpressionsBonusConfig']
    qualityLevel?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignForUserProduct']: AliasType<{
    autoApprovalSeatsAvailable?: boolean | `@${string}`
    contentRequirement?: ResolverInputTypes['ProductSocialContent']
    customFtcHashtags?: boolean | `@${string}`
    flowId?: boolean | `@${string}`
    id?: boolean | `@${string}`
    image?: boolean | `@${string}`
    isPayForClicksEnabled?: boolean | `@${string}`
    payForImpressionsBonusConfig?: ResolverInputTypes['PayForImpressionsBonusConfig']
    price?: boolean | `@${string}`
    productLink?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    storeName?: boolean | `@${string}`
    title?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignInvite']: AliasType<{
    campaign?: ResolverInputTypes['Campaign']
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    notificationStatus?: boolean | `@${string}`
    priority?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignReferralBonus']: AliasType<{
    campaignName?: boolean | `@${string}`
    payoutDate?: boolean | `@${string}`
    totalEarnings?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignSourcingProspect']: AliasType<{
    bonusConfig?: ResolverInputTypes['UserCampaignBonusConfig']
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    createdById?: boolean | `@${string}`
    instagramFollowersInt?: boolean | `@${string}`
    instagramHandle?: boolean | `@${string}`
    internalNotes?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelNoteConnection']
    ]
    internalNotesId?: boolean | `@${string}`
    name?: boolean | `@${string}`
    platformInviteSource?: boolean | `@${string}`
    platformStatus?: boolean | `@${string}`
    preApprovalStatus?: boolean | `@${string}`
    preApprovalStatusById?: boolean | `@${string}`
    priority?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    sourcingSource?: boolean | `@${string}`
    tiktokFollowersInt?: boolean | `@${string}`
    tiktokHandle?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userCampaign?: ResolverInputTypes['UserCampaign']
    userCampaignId?: boolean | `@${string}`
    userIdentifier?: boolean | `@${string}`
    youtubeChannelFollowersInt?: boolean | `@${string}`
    youtubeChannelHandle?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CardCreateOutput']: AliasType<{
    cardId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CardDeleteOutput']: AliasType<{
    cardId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CardDetails']: AliasType<{
    address?: boolean | `@${string}`
    cvc?: boolean | `@${string}`
    expMonth?: boolean | `@${string}`
    expYear?: boolean | `@${string}`
    last4?: boolean | `@${string}`
    name?: boolean | `@${string}`
    number?: boolean | `@${string}`
    primaryAccountIdentifier?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ChannelConfiguration']: AliasType<{
    attributes?: ResolverInputTypes['KeyAttribute']
    enabled?: boolean | `@${string}`
    fromAddress?: boolean | `@${string}`
    templateId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ChatConversationV2']: AliasType<{
    active?: boolean | `@${string}`
    adminLastReadMessageIndex?: boolean | `@${string}`
    adminUnreadCount?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    chatType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    creatorLastReadMessageIndex?: boolean | `@${string}`
    creatorUnreadCount?: boolean | `@${string}`
    lastMessage?: boolean | `@${string}`
    lastMessageDate?: boolean | `@${string}`
    lastMessageId?: boolean | `@${string}`
    lastMessageSource?: boolean | `@${string}`
    resourceId?: boolean | `@${string}`
    status?: boolean | `@${string}`
    statusUpdatedAt?: boolean | `@${string}`
    statusUpdatedByFirstName?: boolean | `@${string}`
    statusUpdatedById?: boolean | `@${string}`
    statusUpdatedByLastName?: boolean | `@${string}`
    twilioConversationId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userCampaignId?: boolean | `@${string}`
    userEmail?: boolean | `@${string}`
    userFirstName?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    userLastName?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ClicksRecords']: AliasType<{
    clicks?: boolean | `@${string}`
    from?: boolean | `@${string}`
    to?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CognitoUserAttribute']: AliasType<{
    Name?: boolean | `@${string}`
    Value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CognitoUserModel']: AliasType<{
    UserAttributes?: ResolverInputTypes['CognitoUserAttribute']
    Username?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CollisionDensityValue']: AliasType<{
    density?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CommunicationNotification']: AliasType<{
    address?: boolean | `@${string}`
    attributes?: ResolverInputTypes['KeyAttribute']
    campaignId?: boolean | `@${string}`
    channel?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    expectedSentAt?: boolean | `@${string}`
    experimentTreatment?: boolean | `@${string}`
    id?: boolean | `@${string}`
    notificationId?: boolean | `@${string}`
    sentAt?: boolean | `@${string}`
    status?: boolean | `@${string}`
    statusMessage?: boolean | `@${string}`
    templateId?: boolean | `@${string}`
    traceId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Company']: AliasType<{
    agencyId?: boolean | `@${string}`
    brands?: [
      {
        filter?: ResolverInputTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelBrandConnection']
    ]
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    logo?: boolean | `@${string}`
    name?: boolean | `@${string}`
    paymentTermsCustomNetDays?: boolean | `@${string}`
    paymentTermsType?: boolean | `@${string}`
    subscriptionEndsAt?: boolean | `@${string}`
    subscriptionStartsAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    websiteUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ConnectedAccount']: AliasType<{
    accountId?: boolean | `@${string}`
    accountType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentApprovalConfiguration']: AliasType<{
    contentApprovalAssignee?: boolean | `@${string}`
    contentApprovalType?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentComplianceReviewCriteria']: AliasType<{
    compliant?: boolean | `@${string}`
    criteria?: boolean | `@${string}`
    requirement?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentGuidance']: AliasType<{
    instance?: boolean | `@${string}`
    text?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentLabelResult']: AliasType<{
    confidence?: boolean | `@${string}`
    label?: boolean | `@${string}`
    severity?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentRatingSponsorshipContent']: AliasType<{
    inputSource?: boolean | `@${string}`
    isCompliant?: boolean | `@${string}`
    isInappropriate?: boolean | `@${string}`
    link?: boolean | `@${string}`
    qualityRating?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentSafetyAnalysis']: AliasType<{
    isBrandSafe?: boolean | `@${string}`
    summary?: ResolverInputTypes['ContentSafetyLabelSummary']
    textInstances?: ResolverInputTypes['ContentSafetyTextInstance']
    __typename?: boolean | `@${string}`
  }>
  ['ContentSafetyLabelSummary']: AliasType<{
    confidence?: boolean | `@${string}`
    label?: boolean | `@${string}`
    severity?: ResolverInputTypes['ContentSafetySeverity']
    __typename?: boolean | `@${string}`
  }>
  ['ContentSafetySeverity']: AliasType<{
    high?: boolean | `@${string}`
    low?: boolean | `@${string}`
    medium?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentSafetyTextInstance']: AliasType<{
    labels?: ResolverInputTypes['ContentLabelResult']
    text?: boolean | `@${string}`
    timestampEnd?: boolean | `@${string}`
    timestampStart?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Conversation']: AliasType<{
    contactOwner?: ResolverInputTypes['User']
    contactOwnerId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    lastContactAt?: boolean | `@${string}`
    messages?: [
      {
        filter?: ResolverInputTypes['ModelConversationMessageFilterInput'] | undefined | null
        id?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelConversationMessageConnection']
    ]
    status?: boolean | `@${string}`
    statusChangedAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ConversationInternalStatusDetails']: AliasType<{
    status?: boolean | `@${string}`
    statusUpdatedAt?: boolean | `@${string}`
    statusUpdatedByFirstName?: boolean | `@${string}`
    statusUpdatedById?: boolean | `@${string}`
    statusUpdatedByLastName?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ConversationMessage']: AliasType<{
    channel?: boolean | `@${string}`
    conversation?: ResolverInputTypes['Conversation']
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    messageContent?: boolean | `@${string}`
    messageId?: boolean | `@${string}`
    messageMediaKeys?: boolean | `@${string}`
    replyFrom?: ResolverInputTypes['User']
    replyFromId?: boolean | `@${string}`
    sendAt?: boolean | `@${string}`
    sendAttempts?: boolean | `@${string}`
    sentAt?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorContentRatingRawValue']: AliasType<{
    name?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorReferralBonus']: AliasType<{
    earnings?: boolean | `@${string}`
    payoutDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorReferralSignup']: AliasType<{
    signupDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['EditUserSocialContentResponse']: AliasType<{
    externalUrl?: boolean | `@${string}`
    id?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['EtailerSkuMetrics']: AliasType<{
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    pageViews?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    totalSalesAmount?: boolean | `@${string}`
    unitsSold?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormDefinition']: AliasType<{
    formFields?: ResolverInputTypes['FormFieldDefinition']
    name?: boolean | `@${string}`
    pages?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormFieldDefinition']: AliasType<{
    bonus?: boolean | `@${string}`
    componentType?: boolean | `@${string}`
    destination?: boolean | `@${string}`
    formName?: boolean | `@${string}`
    isProfileQuestion?: boolean | `@${string}`
    link?: boolean | `@${string}`
    options?: boolean | `@${string}`
    page?: boolean | `@${string}`
    placeholder?: boolean | `@${string}`
    primaryText?: boolean | `@${string}`
    secondaryText?: boolean | `@${string}`
    sequenceComponent?: ResolverInputTypes['FormSequenceComponent']
    sequenceId?: boolean | `@${string}`
    tooltipText?: boolean | `@${string}`
    type?: boolean | `@${string}`
    validation?: ResolverInputTypes['FormValidation']
    withOther?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormSequence']: AliasType<{
    components?: ResolverInputTypes['FormSequenceComponent']
    id?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormSequenceComponent']: AliasType<{
    componentName?: boolean | `@${string}`
    componentType?: boolean | `@${string}`
    content?: boolean | `@${string}`
    id?: boolean | `@${string}`
    images?: boolean | `@${string}`
    numbered?: boolean | `@${string}`
    placeholder?: boolean | `@${string}`
    type?: boolean | `@${string}`
    validation?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormSequenceConfiguration']: AliasType<{
    active?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    sequenceName?: boolean | `@${string}`
    sequences?: ResolverInputTypes['FormSequence']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['FormValidation']: AliasType<{
    type?: boolean | `@${string}`
    validationText?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['GetConversationsAccessTokenResponse']: AliasType<{
    accessToken?: boolean | `@${string}`
    ttl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['GetFeatureFlagResponse']: AliasType<{
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InspirationalContent']: AliasType<{
    socialChannel?: boolean | `@${string}`
    type?: boolean | `@${string}`
    url?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InstagramCountryDistributionItem']: AliasType<{
    count?: boolean | `@${string}`
    countryCode?: boolean | `@${string}`
    pctDistribution?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InstagramGenderAgeDistribution']: AliasType<{
    femaleCount?: boolean | `@${string}`
    genderAgeDistribution?: ResolverInputTypes['InstagramGenderAgeDistributionItem']
    maleCount?: boolean | `@${string}`
    unspecifiedCount?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InstagramGenderAgeDistributionItem']: AliasType<{
    ageRangeEnd?: boolean | `@${string}`
    ageRangeStart?: boolean | `@${string}`
    count?: boolean | `@${string}`
    gender?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['InstagramStoryScreen']: AliasType<{
    createdAt?: boolean | `@${string}`
    exits?: boolean | `@${string}`
    expirationUnixTime?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    id?: boolean | `@${string}`
    impressions?: boolean | `@${string}`
    instagramUserId?: boolean | `@${string}`
    mediaId?: boolean | `@${string}`
    postedAt?: boolean | `@${string}`
    postingDate?: boolean | `@${string}`
    reach?: boolean | `@${string}`
    replies?: boolean | `@${string}`
    s3Bucket?: boolean | `@${string}`
    tapsBack?: boolean | `@${string}`
    tapsForward?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['KeyAttribute']: AliasType<{
    key?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['KeywordResponse']: AliasType<{
    hash?: boolean | `@${string}`
    keywordId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ListCampaignsForUserResponse']: AliasType<{
    campaigns?: ResolverInputTypes['CampaignForUser']
    emptyReason?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Message']: AliasType<{
    body?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaign?: ResolverInputTypes['UserCampaign']
    userCampaignId?: boolean | `@${string}`
    userHasSeen?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['MessagingChannelConfiguration']: AliasType<{
    attributes?: ResolverInputTypes['KeyAttribute']
    enabled?: boolean | `@${string}`
    templateContent?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['MinMaxRange']: AliasType<{
    max?: boolean | `@${string}`
    min?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelAutoBuyDistributionConnection']: AliasType<{
    items?: ResolverInputTypes['AutoBuyDistribution']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelAutoBuyFlowConfigurationConnection']: AliasType<{
    items?: ResolverInputTypes['AutoBuyFlowConfiguration']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelAutoBuyFlowReleaseScheduleConnection']: AliasType<{
    items?: ResolverInputTypes['AutoBuyFlowReleaseSchedule']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelAutoBuyReleaseConnection']: AliasType<{
    items?: ResolverInputTypes['AutoBuyRelease']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelBrandApiConnection']: AliasType<{
    items?: ResolverInputTypes['BrandApi']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelBrandConnection']: AliasType<{
    items?: ResolverInputTypes['Brand']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCampaignClicksConnection']: AliasType<{
    items?: ResolverInputTypes['CampaignClicks']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCampaignConnection']: AliasType<{
    items?: ResolverInputTypes['Campaign']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCampaignInviteConnection']: AliasType<{
    items?: ResolverInputTypes['CampaignInvite']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCampaignSourcingProspectConnection']: AliasType<{
    items?: ResolverInputTypes['CampaignSourcingProspect']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelChatConversationV2Connection']: AliasType<{
    items?: ResolverInputTypes['ChatConversationV2']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCommunicationNotificationConnection']: AliasType<{
    items?: ResolverInputTypes['CommunicationNotification']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelCompanyConnection']: AliasType<{
    items?: ResolverInputTypes['Company']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelConnectedAccountConnection']: AliasType<{
    items?: ResolverInputTypes['ConnectedAccount']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelConversationConnection']: AliasType<{
    items?: ResolverInputTypes['Conversation']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelConversationMessageConnection']: AliasType<{
    items?: ResolverInputTypes['ConversationMessage']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelEtailerSkuMetricsConnection']: AliasType<{
    items?: ResolverInputTypes['EtailerSkuMetrics']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelFormSequenceConfigurationConnection']: AliasType<{
    items?: ResolverInputTypes['FormSequenceConfiguration']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelInstagramStoryScreenConnection']: AliasType<{
    items?: ResolverInputTypes['InstagramStoryScreen']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelMessageConnection']: AliasType<{
    items?: ResolverInputTypes['Message']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelNoteConnection']: AliasType<{
    items?: ResolverInputTypes['Note']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelNotificationConfigurationConnection']: AliasType<{
    items?: ResolverInputTypes['NotificationConfiguration']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelProductBonusConfigSnapshotConnection']: AliasType<{
    items?: ResolverInputTypes['ProductBonusConfigSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelProductConnection']: AliasType<{
    items?: ResolverInputTypes['Product']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelProductKeywordsConnection']: AliasType<{
    items?: ResolverInputTypes['ProductKeywords']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelProductRatingSnapshotConnection']: AliasType<{
    items?: ResolverInputTypes['ProductRatingSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelPurchaseConnection']: AliasType<{
    items?: ResolverInputTypes['Purchase']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelReferralConnection']: AliasType<{
    items?: ResolverInputTypes['Referral']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelReviewConnection']: AliasType<{
    items?: ResolverInputTypes['Review']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSkuConnection']: AliasType<{
    items?: ResolverInputTypes['Sku']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSkuSnapshotConnection']: AliasType<{
    items?: ResolverInputTypes['SkuSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserBonusPayoutConnection']: AliasType<{
    items?: ResolverInputTypes['UserBonusPayout']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignChangelogConnection']: AliasType<{
    items?: ResolverInputTypes['UserCampaignChangelog']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignConnection']: AliasType<{
    items?: ResolverInputTypes['UserCampaign']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignSnapshotConnection']: AliasType<{
    items?: ResolverInputTypes['UserCampaignSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignSocialContentConnection']: AliasType<{
    items?: ResolverInputTypes['UserCampaignSocialContent']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignSocialContentDraftHistoryConnection']: AliasType<{
    items?: ResolverInputTypes['UserCampaignSocialContentDraftHistory']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserCampaignSocialContentReviewConnection']: AliasType<{
    items?: ResolverInputTypes['UserCampaignSocialContentReview']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserConnection']: AliasType<{
    items?: ResolverInputTypes['User']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserDeviceSnapshotConnection']: AliasType<{
    items?: ResolverInputTypes['UserDeviceSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserFollowerConnection']: AliasType<{
    items?: ResolverInputTypes['UserFollower']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserLinkConnection']: AliasType<{
    items?: ResolverInputTypes['UserLink']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserNotificationPreferenceConnection']: AliasType<{
    items?: ResolverInputTypes['UserNotificationPreference']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserPayoutRequestConnection']: AliasType<{
    items?: ResolverInputTypes['UserPayoutRequest']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelUserStagingApprovalConnection']: AliasType<{
    items?: ResolverInputTypes['UserStagingApproval']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelVideoContentScanConnection']: AliasType<{
    items?: ResolverInputTypes['VideoContentScan']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Mutation']: AliasType<{
    adminSendPushMessage?: [
      { input: ResolverInputTypes['AdminSendPushNotificationInput'] },
      ResolverInputTypes['AdminSendPushMessageResponse']
    ]
    applyToCampaign?: [
      { input: ResolverInputTypes['ApplyToCampaignInput'] },
      ResolverInputTypes['ApplyToCampaignOutput']
    ]
    approveUserCampaignForPurchase?: [
      { input?: ResolverInputTypes['ApproveUserCampaignForPurchaseInput'] | undefined | null },
      ResolverInputTypes['ApproveUserCampaignForPurchaseResponse']
    ]
    createAutoBuyDistribution?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateAutoBuyDistributionInput']
      },
      ResolverInputTypes['AutoBuyDistribution']
    ]
    createAutoBuyFlowConfiguration?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateAutoBuyFlowConfigurationInput']
      },
      ResolverInputTypes['AutoBuyFlowConfiguration']
    ]
    createAutoBuyFlowReleaseSchedule?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateAutoBuyFlowReleaseScheduleInput']
      },
      ResolverInputTypes['AutoBuyFlowReleaseSchedule']
    ]
    createAutoBuyRelease?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateAutoBuyReleaseInput']
      },
      ResolverInputTypes['AutoBuyRelease']
    ]
    createBrand?: [
      {
        condition?: ResolverInputTypes['ModelBrandConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateBrandInput']
      },
      ResolverInputTypes['Brand']
    ]
    createBrandApi?: [
      {
        condition?: ResolverInputTypes['ModelBrandApiConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateBrandApiInput']
      },
      ResolverInputTypes['BrandApi']
    ]
    createCampaign?: [
      {
        condition?: ResolverInputTypes['ModelCampaignConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateCampaignInput']
      },
      ResolverInputTypes['Campaign']
    ]
    createCampaignClicks?: [
      {
        condition?: ResolverInputTypes['ModelCampaignClicksConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateCampaignClicksInput']
      },
      ResolverInputTypes['CampaignClicks']
    ]
    createCampaignInvite?: [
      {
        condition?: ResolverInputTypes['ModelCampaignInviteConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateCampaignInviteInput']
      },
      ResolverInputTypes['CampaignInvite']
    ]
    createCampaignSourcingProspect?: [
      {
        condition?: ResolverInputTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateCampaignSourcingProspectInput']
      },
      ResolverInputTypes['CampaignSourcingProspect']
    ]
    createChatConversationV2?: [
      {
        condition?: ResolverInputTypes['ModelChatConversationV2ConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateChatConversationV2Input']
      },
      ResolverInputTypes['ChatConversationV2']
    ]
    createCommunicationNotification?: [
      {
        condition?: ResolverInputTypes['ModelCommunicationNotificationConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateCommunicationNotificationInput']
      },
      ResolverInputTypes['CommunicationNotification']
    ]
    createCompany?: [
      {
        condition?: ResolverInputTypes['ModelCompanyConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateCompanyInput']
      },
      ResolverInputTypes['Company']
    ]
    createConnectedAccount?: [
      {
        condition?: ResolverInputTypes['ModelConnectedAccountConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateConnectedAccountInput']
      },
      ResolverInputTypes['ConnectedAccount']
    ]
    createConversation?: [
      {
        condition?: ResolverInputTypes['ModelConversationConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateConversationInput']
      },
      ResolverInputTypes['Conversation']
    ]
    createConversationMessage?: [
      {
        condition?: ResolverInputTypes['ModelConversationMessageConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateConversationMessageInput']
      },
      ResolverInputTypes['ConversationMessage']
    ]
    createEtailerSkuMetrics?: [
      {
        condition?: ResolverInputTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateEtailerSkuMetricsInput']
      },
      ResolverInputTypes['EtailerSkuMetrics']
    ]
    createFormSequenceConfiguration?: [
      {
        condition?: ResolverInputTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateFormSequenceConfigurationInput']
      },
      ResolverInputTypes['FormSequenceConfiguration']
    ]
    createInstagramStoryScreen?: [
      {
        condition?: ResolverInputTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateInstagramStoryScreenInput']
      },
      ResolverInputTypes['InstagramStoryScreen']
    ]
    createManualPurchase?: [
      { amount: number; purchaseDate?: string | undefined | null; userCampaignId: string },
      ResolverInputTypes['UserCampaign']
    ]
    createMessage?: [
      {
        condition?: ResolverInputTypes['ModelMessageConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateMessageInput']
      },
      ResolverInputTypes['Message']
    ]
    createNote?: [
      {
        condition?: ResolverInputTypes['ModelNoteConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateNoteInput']
      },
      ResolverInputTypes['Note']
    ]
    createNotificationConfiguration?: [
      {
        condition?: ResolverInputTypes['ModelNotificationConfigurationConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateNotificationConfigurationInput']
      },
      ResolverInputTypes['NotificationConfiguration']
    ]
    createProduct?: [
      {
        condition?: ResolverInputTypes['ModelProductConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateProductInput']
      },
      ResolverInputTypes['Product']
    ]
    createProductBonusConfigSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateProductBonusConfigSnapshotInput']
      },
      ResolverInputTypes['ProductBonusConfigSnapshot']
    ]
    createProductFromUrl?: [{ input: ResolverInputTypes['CreateProductFromUrlInput'] }, ResolverInputTypes['Sku']]
    createProductKeywords?: [
      {
        condition?: ResolverInputTypes['ModelProductKeywordsConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateProductKeywordsInput']
      },
      ResolverInputTypes['ProductKeywords']
    ]
    createProductRatingSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateProductRatingSnapshotInput']
      },
      ResolverInputTypes['ProductRatingSnapshot']
    ]
    createPurchase?: [
      {
        condition?: ResolverInputTypes['ModelPurchaseConditionInput'] | undefined | null
        input: ResolverInputTypes['CreatePurchaseInput']
      },
      ResolverInputTypes['Purchase']
    ]
    createReferral?: [
      {
        condition?: ResolverInputTypes['ModelReferralConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateReferralInput']
      },
      ResolverInputTypes['Referral']
    ]
    createReview?: [
      {
        condition?: ResolverInputTypes['ModelReviewConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateReviewInput']
      },
      ResolverInputTypes['Review']
    ]
    createShortLink?: [
      { campaignId: string; link: string; userId?: string | undefined | null },
      ResolverInputTypes['UserLink']
    ]
    createSku?: [
      {
        condition?: ResolverInputTypes['ModelSkuConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateSkuInput']
      },
      ResolverInputTypes['Sku']
    ]
    createSkuSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelSkuSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateSkuSnapshotInput']
      },
      ResolverInputTypes['SkuSnapshot']
    ]
    createTwilioConversation?: [
      { input: ResolverInputTypes['CreateTwilioConversationInput'] },
      ResolverInputTypes['ChatConversationV2']
    ]
    createUser?: [
      {
        condition?: ResolverInputTypes['ModelUserConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserInput']
      },
      ResolverInputTypes['User']
    ]
    createUserBonusPayout?: [
      {
        condition?: ResolverInputTypes['ModelUserBonusPayoutConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserBonusPayoutInput']
      },
      ResolverInputTypes['UserBonusPayout']
    ]
    createUserCampaign?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserCampaignInput']
      },
      ResolverInputTypes['UserCampaign']
    ]
    createUserCampaignChangelog?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserCampaignChangelogInput']
      },
      ResolverInputTypes['UserCampaignChangelog']
    ]
    createUserCampaignSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserCampaignSnapshotInput']
      },
      ResolverInputTypes['UserCampaignSnapshot']
    ]
    createUserCampaignSocialContent?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserCampaignSocialContentInput']
      },
      ResolverInputTypes['UserCampaignSocialContent']
    ]
    createUserCampaignSocialContentDraftHistory?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserCampaignSocialContentDraftHistoryInput']
      },
      ResolverInputTypes['UserCampaignSocialContentDraftHistory']
    ]
    createUserCampaignSocialContentReview?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserCampaignSocialContentReviewInput']
      },
      ResolverInputTypes['UserCampaignSocialContentReview']
    ]
    createUserDeviceSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserDeviceSnapshotInput']
      },
      ResolverInputTypes['UserDeviceSnapshot']
    ]
    createUserFollower?: [
      {
        condition?: ResolverInputTypes['ModelUserFollowerConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserFollowerInput']
      },
      ResolverInputTypes['UserFollower']
    ]
    createUserLink?: [
      {
        condition?: ResolverInputTypes['ModelUserLinkConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserLinkInput']
      },
      ResolverInputTypes['UserLink']
    ]
    createUserNotificationPreference?: [
      {
        condition?: ResolverInputTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserNotificationPreferenceInput']
      },
      ResolverInputTypes['UserNotificationPreference']
    ]
    createUserPayoutRequest?: [
      {
        condition?: ResolverInputTypes['ModelUserPayoutRequestConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserPayoutRequestInput']
      },
      ResolverInputTypes['UserPayoutRequest']
    ]
    createUserStagingApproval?: [
      {
        condition?: ResolverInputTypes['ModelUserStagingApprovalConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateUserStagingApprovalInput']
      },
      ResolverInputTypes['UserStagingApproval']
    ]
    createVideoContentScan?: [
      {
        condition?: ResolverInputTypes['ModelVideoContentScanConditionInput'] | undefined | null
        input: ResolverInputTypes['CreateVideoContentScanInput']
      },
      ResolverInputTypes['VideoContentScan']
    ]
    deleteAutoBuyDistribution?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteAutoBuyDistributionInput']
      },
      ResolverInputTypes['AutoBuyDistribution']
    ]
    deleteAutoBuyFlowConfiguration?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteAutoBuyFlowConfigurationInput']
      },
      ResolverInputTypes['AutoBuyFlowConfiguration']
    ]
    deleteAutoBuyFlowReleaseSchedule?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteAutoBuyFlowReleaseScheduleInput']
      },
      ResolverInputTypes['AutoBuyFlowReleaseSchedule']
    ]
    deleteAutoBuyRelease?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteAutoBuyReleaseInput']
      },
      ResolverInputTypes['AutoBuyRelease']
    ]
    deleteBrand?: [
      {
        condition?: ResolverInputTypes['ModelBrandConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteBrandInput']
      },
      ResolverInputTypes['Brand']
    ]
    deleteBrandApi?: [
      {
        condition?: ResolverInputTypes['ModelBrandApiConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteBrandApiInput']
      },
      ResolverInputTypes['BrandApi']
    ]
    deleteCampaign?: [
      {
        condition?: ResolverInputTypes['ModelCampaignConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteCampaignInput']
      },
      ResolverInputTypes['Campaign']
    ]
    deleteCampaignClicks?: [
      {
        condition?: ResolverInputTypes['ModelCampaignClicksConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteCampaignClicksInput']
      },
      ResolverInputTypes['CampaignClicks']
    ]
    deleteCampaignInvite?: [
      {
        condition?: ResolverInputTypes['ModelCampaignInviteConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteCampaignInviteInput']
      },
      ResolverInputTypes['CampaignInvite']
    ]
    deleteCampaignSourcingProspect?: [
      {
        condition?: ResolverInputTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteCampaignSourcingProspectInput']
      },
      ResolverInputTypes['CampaignSourcingProspect']
    ]
    deleteChatConversationV2?: [
      {
        condition?: ResolverInputTypes['ModelChatConversationV2ConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteChatConversationV2Input']
      },
      ResolverInputTypes['ChatConversationV2']
    ]
    deleteCommunicationNotification?: [
      {
        condition?: ResolverInputTypes['ModelCommunicationNotificationConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteCommunicationNotificationInput']
      },
      ResolverInputTypes['CommunicationNotification']
    ]
    deleteCompany?: [
      {
        condition?: ResolverInputTypes['ModelCompanyConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteCompanyInput']
      },
      ResolverInputTypes['Company']
    ]
    deleteConnectedAccount?: [
      {
        condition?: ResolverInputTypes['ModelConnectedAccountConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteConnectedAccountInput']
      },
      ResolverInputTypes['ConnectedAccount']
    ]
    deleteConversation?: [
      {
        condition?: ResolverInputTypes['ModelConversationConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteConversationInput']
      },
      ResolverInputTypes['Conversation']
    ]
    deleteConversationMessage?: [
      {
        condition?: ResolverInputTypes['ModelConversationMessageConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteConversationMessageInput']
      },
      ResolverInputTypes['ConversationMessage']
    ]
    deleteEtailerSkuMetrics?: [
      {
        condition?: ResolverInputTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteEtailerSkuMetricsInput']
      },
      ResolverInputTypes['EtailerSkuMetrics']
    ]
    deleteFormSequenceConfiguration?: [
      {
        condition?: ResolverInputTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteFormSequenceConfigurationInput']
      },
      ResolverInputTypes['FormSequenceConfiguration']
    ]
    deleteInstagramStoryScreen?: [
      {
        condition?: ResolverInputTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteInstagramStoryScreenInput']
      },
      ResolverInputTypes['InstagramStoryScreen']
    ]
    deleteMessage?: [
      {
        condition?: ResolverInputTypes['ModelMessageConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteMessageInput']
      },
      ResolverInputTypes['Message']
    ]
    deleteNote?: [
      {
        condition?: ResolverInputTypes['ModelNoteConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteNoteInput']
      },
      ResolverInputTypes['Note']
    ]
    deleteNotificationConfiguration?: [
      {
        condition?: ResolverInputTypes['ModelNotificationConfigurationConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteNotificationConfigurationInput']
      },
      ResolverInputTypes['NotificationConfiguration']
    ]
    deleteProduct?: [
      {
        condition?: ResolverInputTypes['ModelProductConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteProductInput']
      },
      ResolverInputTypes['Product']
    ]
    deleteProductBonusConfigSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteProductBonusConfigSnapshotInput']
      },
      ResolverInputTypes['ProductBonusConfigSnapshot']
    ]
    deleteProductKeywords?: [
      {
        condition?: ResolverInputTypes['ModelProductKeywordsConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteProductKeywordsInput']
      },
      ResolverInputTypes['ProductKeywords']
    ]
    deleteProductRatingSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteProductRatingSnapshotInput']
      },
      ResolverInputTypes['ProductRatingSnapshot']
    ]
    deletePurchase?: [
      {
        condition?: ResolverInputTypes['ModelPurchaseConditionInput'] | undefined | null
        input: ResolverInputTypes['DeletePurchaseInput']
      },
      ResolverInputTypes['Purchase']
    ]
    deleteReferral?: [
      {
        condition?: ResolverInputTypes['ModelReferralConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteReferralInput']
      },
      ResolverInputTypes['Referral']
    ]
    deleteReview?: [
      {
        condition?: ResolverInputTypes['ModelReviewConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteReviewInput']
      },
      ResolverInputTypes['Review']
    ]
    deleteSku?: [
      {
        condition?: ResolverInputTypes['ModelSkuConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteSkuInput']
      },
      ResolverInputTypes['Sku']
    ]
    deleteSkuSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelSkuSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteSkuSnapshotInput']
      },
      ResolverInputTypes['SkuSnapshot']
    ]
    deleteUser?: [
      {
        condition?: ResolverInputTypes['ModelUserConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserInput']
      },
      ResolverInputTypes['User']
    ]
    deleteUserBonusPayout?: [
      {
        condition?: ResolverInputTypes['ModelUserBonusPayoutConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserBonusPayoutInput']
      },
      ResolverInputTypes['UserBonusPayout']
    ]
    deleteUserCampaign?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserCampaignInput']
      },
      ResolverInputTypes['UserCampaign']
    ]
    deleteUserCampaignChangelog?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserCampaignChangelogInput']
      },
      ResolverInputTypes['UserCampaignChangelog']
    ]
    deleteUserCampaignSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserCampaignSnapshotInput']
      },
      ResolverInputTypes['UserCampaignSnapshot']
    ]
    deleteUserCampaignSocialContent?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserCampaignSocialContentInput']
      },
      ResolverInputTypes['UserCampaignSocialContent']
    ]
    deleteUserCampaignSocialContentDraftHistory?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserCampaignSocialContentDraftHistoryInput']
      },
      ResolverInputTypes['UserCampaignSocialContentDraftHistory']
    ]
    deleteUserCampaignSocialContentReview?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserCampaignSocialContentReviewInput']
      },
      ResolverInputTypes['UserCampaignSocialContentReview']
    ]
    deleteUserDeviceSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserDeviceSnapshotInput']
      },
      ResolverInputTypes['UserDeviceSnapshot']
    ]
    deleteUserFollower?: [
      {
        condition?: ResolverInputTypes['ModelUserFollowerConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserFollowerInput']
      },
      ResolverInputTypes['UserFollower']
    ]
    deleteUserLink?: [
      {
        condition?: ResolverInputTypes['ModelUserLinkConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserLinkInput']
      },
      ResolverInputTypes['UserLink']
    ]
    deleteUserNotificationPreference?: [
      {
        condition?: ResolverInputTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserNotificationPreferenceInput']
      },
      ResolverInputTypes['UserNotificationPreference']
    ]
    deleteUserPayoutRequest?: [
      {
        condition?: ResolverInputTypes['ModelUserPayoutRequestConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserPayoutRequestInput']
      },
      ResolverInputTypes['UserPayoutRequest']
    ]
    deleteUserStagingApproval?: [
      {
        condition?: ResolverInputTypes['ModelUserStagingApprovalConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteUserStagingApprovalInput']
      },
      ResolverInputTypes['UserStagingApproval']
    ]
    deleteVideoContentScan?: [
      {
        condition?: ResolverInputTypes['ModelVideoContentScanConditionInput'] | undefined | null
        input: ResolverInputTypes['DeleteVideoContentScanInput']
      },
      ResolverInputTypes['VideoContentScan']
    ]
    editUserSocialContent?: [
      { externalUrl: string; userCampaignSocialContentId: string },
      ResolverInputTypes['EditUserSocialContentResponse']
    ]
    executeBrandSafetyAnalysis?: [{ userId: string }, boolean | `@${string}`]
    generateSocialVerificationCode?: [
      { socialChannel?: ResolverInputTypes['SocialChannel'] | undefined | null },
      ResolverInputTypes['SocialVerificationCode']
    ]
    handleUserCampaignStagingApprovals?: [
      {
        approvalFeedback?: string | undefined | null
        userCampaignId: string
        userStagingApprovalAction: ResolverInputTypes['UserStagingApprovalAction']
        userStagingApprovalType: ResolverInputTypes['UserStagingApprovalType']
      },
      ResolverInputTypes['UserStagingApproval']
    ]
    inviteUsersToCampaign?: [
      { input: ResolverInputTypes['InviteUsersToCampaignInput'] },
      ResolverInputTypes['CampaignInvite']
    ]
    markCreatorAsFavorite?: [{ isFavorite: boolean; userId: string }, ResolverInputTypes['UserFollower']]
    processSelectedInstagramStories?: [
      { storyIds: Array<string | undefined | null>; userCampaignId: string },
      ResolverInputTypes['InstagramStoryScreen']
    ]
    purchasesCardCreate?: [
      { hasAgreedTerms?: boolean | undefined | null; userCampaignId: string },
      ResolverInputTypes['CardCreateOutput']
    ]
    purchasesCardDelete?: [{ userCampaignId: string }, ResolverInputTypes['CardDeleteOutput']]
    purchasesEphemeralCreate?: [{ userCampaignId: string }, boolean | `@${string}`]
    refreshSocialToken?: [
      {
        accessToken?: string | undefined | null
        accountId?: string | undefined | null
        codeVerifier?: string | undefined | null
        redirectURI?: string | undefined | null
        refreshToken?: string | undefined | null
        social: ResolverInputTypes['SocialType']
      },
      boolean | `@${string}`
    ]
    requestUserBalancePayout?: [{ userId: string }, ResolverInputTypes['UserPayoutRequest']]
    requestUserDeactivation?: [{ deactivationReason: string; userId: string }, boolean | `@${string}`]
    resetUnverifiedUserAttributes?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['ResetUserAttributeResponse']
    ]
    resetUserEmail?: [
      { email: string; userId?: string | undefined | null },
      ResolverInputTypes['ResetUserAttributeResponse']
    ]
    resetUserPhoneNumber?: [
      { phoneNumber: string; userId?: string | undefined | null },
      ResolverInputTypes['ResetUserAttributeResponse']
    ]
    rollbackUserCampaign?: [
      {
        additionalArguments?: ResolverInputTypes['RollbackAdditionalArguments'] | undefined | null
        rollbackState: ResolverInputTypes['CampaignStatus']
        userCampaignId: string
      },
      ResolverInputTypes['UserCampaign']
    ]
    scrapeMediaAssetToStorage?: [{ assetId: string; prefix: string; url: string }, boolean | `@${string}`]
    socialUnlink?: [{ socialChannel: ResolverInputTypes['SocialChannel'] }, ResolverInputTypes['SocialUnlinkResult']]
    submitUserCampaignStateChange?: [
      { input: ResolverInputTypes['SubmitUserCampaignStateChangeInput'] },
      ResolverInputTypes['UserCampaign']
    ]
    updateAutoBuyDistribution?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateAutoBuyDistributionInput']
      },
      ResolverInputTypes['AutoBuyDistribution']
    ]
    updateAutoBuyFlowConfiguration?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateAutoBuyFlowConfigurationInput']
      },
      ResolverInputTypes['AutoBuyFlowConfiguration']
    ]
    updateAutoBuyFlowReleaseSchedule?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateAutoBuyFlowReleaseScheduleInput']
      },
      ResolverInputTypes['AutoBuyFlowReleaseSchedule']
    ]
    updateAutoBuyRelease?: [
      {
        condition?: ResolverInputTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateAutoBuyReleaseInput']
      },
      ResolverInputTypes['AutoBuyRelease']
    ]
    updateBrand?: [
      {
        condition?: ResolverInputTypes['ModelBrandConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateBrandInput']
      },
      ResolverInputTypes['Brand']
    ]
    updateBrandApi?: [
      {
        condition?: ResolverInputTypes['ModelBrandApiConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateBrandApiInput']
      },
      ResolverInputTypes['BrandApi']
    ]
    updateCampaign?: [
      {
        condition?: ResolverInputTypes['ModelCampaignConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateCampaignInput']
      },
      ResolverInputTypes['Campaign']
    ]
    updateCampaignClicks?: [
      {
        condition?: ResolverInputTypes['ModelCampaignClicksConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateCampaignClicksInput']
      },
      ResolverInputTypes['CampaignClicks']
    ]
    updateCampaignInvite?: [
      {
        condition?: ResolverInputTypes['ModelCampaignInviteConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateCampaignInviteInput']
      },
      ResolverInputTypes['CampaignInvite']
    ]
    updateCampaignSourcingProspect?: [
      {
        condition?: ResolverInputTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateCampaignSourcingProspectInput']
      },
      ResolverInputTypes['CampaignSourcingProspect']
    ]
    updateChatConversationV2?: [
      {
        condition?: ResolverInputTypes['ModelChatConversationV2ConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateChatConversationV2Input']
      },
      ResolverInputTypes['ChatConversationV2']
    ]
    updateCommunicationNotification?: [
      {
        condition?: ResolverInputTypes['ModelCommunicationNotificationConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateCommunicationNotificationInput']
      },
      ResolverInputTypes['CommunicationNotification']
    ]
    updateCompany?: [
      {
        condition?: ResolverInputTypes['ModelCompanyConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateCompanyInput']
      },
      ResolverInputTypes['Company']
    ]
    updateConnectedAccount?: [
      {
        condition?: ResolverInputTypes['ModelConnectedAccountConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateConnectedAccountInput']
      },
      ResolverInputTypes['ConnectedAccount']
    ]
    updateContentApprovals?: [
      { input: ResolverInputTypes['UpdateContentApprovalsInput'] },
      ResolverInputTypes['UserCampaignSocialContentReview']
    ]
    updateConversation?: [
      {
        condition?: ResolverInputTypes['ModelConversationConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateConversationInput']
      },
      ResolverInputTypes['Conversation']
    ]
    updateConversationMessage?: [
      {
        condition?: ResolverInputTypes['ModelConversationMessageConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateConversationMessageInput']
      },
      ResolverInputTypes['ConversationMessage']
    ]
    updateEtailerSkuMetrics?: [
      {
        condition?: ResolverInputTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateEtailerSkuMetricsInput']
      },
      ResolverInputTypes['EtailerSkuMetrics']
    ]
    updateFormSequenceConfiguration?: [
      {
        condition?: ResolverInputTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateFormSequenceConfigurationInput']
      },
      ResolverInputTypes['FormSequenceConfiguration']
    ]
    updateInstagramStoryScreen?: [
      {
        condition?: ResolverInputTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateInstagramStoryScreenInput']
      },
      ResolverInputTypes['InstagramStoryScreen']
    ]
    updateMessage?: [
      {
        condition?: ResolverInputTypes['ModelMessageConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateMessageInput']
      },
      ResolverInputTypes['Message']
    ]
    updateNote?: [
      {
        condition?: ResolverInputTypes['ModelNoteConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateNoteInput']
      },
      ResolverInputTypes['Note']
    ]
    updateNotificationConfiguration?: [
      {
        condition?: ResolverInputTypes['ModelNotificationConfigurationConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateNotificationConfigurationInput']
      },
      ResolverInputTypes['NotificationConfiguration']
    ]
    updateProduct?: [
      {
        condition?: ResolverInputTypes['ModelProductConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateProductInput']
      },
      ResolverInputTypes['Product']
    ]
    updateProductBonusConfigSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateProductBonusConfigSnapshotInput']
      },
      ResolverInputTypes['ProductBonusConfigSnapshot']
    ]
    updateProductKeywords?: [
      {
        condition?: ResolverInputTypes['ModelProductKeywordsConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateProductKeywordsInput']
      },
      ResolverInputTypes['ProductKeywords']
    ]
    updateProductRatingSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateProductRatingSnapshotInput']
      },
      ResolverInputTypes['ProductRatingSnapshot']
    ]
    updatePurchase?: [
      {
        condition?: ResolverInputTypes['ModelPurchaseConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdatePurchaseInput']
      },
      ResolverInputTypes['Purchase']
    ]
    updateReferral?: [
      {
        condition?: ResolverInputTypes['ModelReferralConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateReferralInput']
      },
      ResolverInputTypes['Referral']
    ]
    updateReview?: [
      {
        condition?: ResolverInputTypes['ModelReviewConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateReviewInput']
      },
      ResolverInputTypes['Review']
    ]
    updateSku?: [
      {
        condition?: ResolverInputTypes['ModelSkuConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateSkuInput']
      },
      ResolverInputTypes['Sku']
    ]
    updateSkuSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelSkuSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateSkuSnapshotInput']
      },
      ResolverInputTypes['SkuSnapshot']
    ]
    updateUser?: [
      {
        condition?: ResolverInputTypes['ModelUserConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserInput']
      },
      ResolverInputTypes['User']
    ]
    updateUserBonusPayout?: [
      {
        condition?: ResolverInputTypes['ModelUserBonusPayoutConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserBonusPayoutInput']
      },
      ResolverInputTypes['UserBonusPayout']
    ]
    updateUserCampaign?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserCampaignInput']
      },
      ResolverInputTypes['UserCampaign']
    ]
    updateUserCampaignChangelog?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserCampaignChangelogInput']
      },
      ResolverInputTypes['UserCampaignChangelog']
    ]
    updateUserCampaignSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserCampaignSnapshotInput']
      },
      ResolverInputTypes['UserCampaignSnapshot']
    ]
    updateUserCampaignSocialContent?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserCampaignSocialContentInput']
      },
      ResolverInputTypes['UserCampaignSocialContent']
    ]
    updateUserCampaignSocialContentDraftHistory?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserCampaignSocialContentDraftHistoryInput']
      },
      ResolverInputTypes['UserCampaignSocialContentDraftHistory']
    ]
    updateUserCampaignSocialContentReview?: [
      {
        condition?: ResolverInputTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserCampaignSocialContentReviewInput']
      },
      ResolverInputTypes['UserCampaignSocialContentReview']
    ]
    updateUserDeviceSnapshot?: [
      {
        condition?: ResolverInputTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserDeviceSnapshotInput']
      },
      ResolverInputTypes['UserDeviceSnapshot']
    ]
    updateUserFollower?: [
      {
        condition?: ResolverInputTypes['ModelUserFollowerConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserFollowerInput']
      },
      ResolverInputTypes['UserFollower']
    ]
    updateUserLink?: [
      {
        condition?: ResolverInputTypes['ModelUserLinkConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserLinkInput']
      },
      ResolverInputTypes['UserLink']
    ]
    updateUserNotificationPreference?: [
      {
        condition?: ResolverInputTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserNotificationPreferenceInput']
      },
      ResolverInputTypes['UserNotificationPreference']
    ]
    updateUserPayoutRequest?: [
      {
        condition?: ResolverInputTypes['ModelUserPayoutRequestConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserPayoutRequestInput']
      },
      ResolverInputTypes['UserPayoutRequest']
    ]
    updateUserPhone?: [{ phoneNumber: string; userId: string }, ResolverInputTypes['UpdateUserPhoneResult']]
    updateUserStagingApproval?: [
      {
        condition?: ResolverInputTypes['ModelUserStagingApprovalConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateUserStagingApprovalInput']
      },
      ResolverInputTypes['UserStagingApproval']
    ]
    updateVideoContentScan?: [
      {
        condition?: ResolverInputTypes['ModelVideoContentScanConditionInput'] | undefined | null
        input: ResolverInputTypes['UpdateVideoContentScanInput']
      },
      ResolverInputTypes['VideoContentScan']
    ]
    verifySocialVerificationCode?: [
      { handle?: string | undefined | null; id: string },
      ResolverInputTypes['VerifySocialCodeResult']
    ]
    __typename?: boolean | `@${string}`
  }>
  ['Note']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    note?: boolean | `@${string}`
    parentId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['NotificationConfiguration']: AliasType<{
    category?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    description?: boolean | `@${string}`
    email?: ResolverInputTypes['ChannelConfiguration']
    enabled?: boolean | `@${string}`
    id?: boolean | `@${string}`
    inAppChatChannel?: ResolverInputTypes['MessagingChannelConfiguration']
    name?: boolean | `@${string}`
    occurrenceConstraint?: ResolverInputTypes['OccurrenceConstraint']
    push?: ResolverInputTypes['PushChannelConfiguration']
    sms?: ResolverInputTypes['ChannelConfiguration']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['OccurrenceConstraint']: AliasType<{
    period?: boolean | `@${string}`
    type?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['OrderConfirmationScreenshotVerificationOutput']: AliasType<{
    orderDate?: boolean | `@${string}`
    orderId?: boolean | `@${string}`
    orderTotal?: boolean | `@${string}`
    rejectionMessage?: boolean | `@${string}`
    result?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PayForImpressionsBonusConfig']: AliasType<{
    enabled?: boolean | `@${string}`
    growthRate?: boolean | `@${string}`
    impressionsBaseline?: boolean | `@${string}`
    impressionsCap?: boolean | `@${string}`
    impressionsIncrement?: boolean | `@${string}`
    initialBonus?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PayForPostingContentEarlyConfig']: AliasType<{
    bonusCents?: boolean | `@${string}`
    daysBeforeDeadline?: boolean | `@${string}`
    enabled?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Product']: AliasType<{
    accountRequired?: boolean | `@${string}`
    active?: boolean | `@${string}`
    approvalFilters?: ResolverInputTypes['ApprovalFilterValue']
    autoApprovalSeatsAvailable?: boolean | `@${string}`
    autoApprovalStatusTiers?: boolean | `@${string}`
    autoApprovalV2Config?: ResolverInputTypes['AutoApprovalV2Config']
    autoBuyConfiguration?: ResolverInputTypes['AutoBuyFlowConfiguration']
    autoBuyReleaseSchedule?: [
      {
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleConnection']
    ]
    automaticReviewRelease?: boolean | `@${string}`
    baseBonus?: boolean | `@${string}`
    bonusHistory?: [
      {
        filter?: ResolverInputTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        snapshotAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductBonusConfigSnapshotConnection']
    ]
    brandAdvocateCount?: boolean | `@${string}`
    brandTalkingPoints?: boolean | `@${string}`
    buyOnlinePickupInStore?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    campaignStateMachine?: boolean | `@${string}`
    closeoutFormBonus?: boolean | `@${string}`
    contentApprovalConfiguration?: ResolverInputTypes['ContentApprovalConfiguration']
    contentApprovalLeadDays?: boolean | `@${string}`
    contentRequirement?: ResolverInputTypes['ProductSocialContent']
    createdAt?: boolean | `@${string}`
    creatorCount?: boolean | `@${string}`
    creatorPreference?: boolean | `@${string}`
    creatorPreferenceDetails?: boolean | `@${string}`
    customFtcHashtags?: boolean | `@${string}`
    customHashtags?: boolean | `@${string}`
    directPurchaseLink?: boolean | `@${string}`
    engagementRateInstagramRangeRequirement?: ResolverInputTypes['MinMaxRange']
    engagementRateTiktokRangeRequirement?: ResolverInputTypes['MinMaxRange']
    followersInstagramRangeRequirement?: ResolverInputTypes['MinMaxRange']
    followersTiktokRangeRequirement?: ResolverInputTypes['MinMaxRange']
    hideBrandContentApprovals?: boolean | `@${string}`
    id?: boolean | `@${string}`
    impressionsInstagramRangeRequirement?: ResolverInputTypes['MinMaxRange']
    impressionsTiktokRangeRequirement?: ResolverInputTypes['MinMaxRange']
    instaHandle?: boolean | `@${string}`
    internalCreatorsStagingGuidance?: boolean | `@${string}`
    internalDescription?: boolean | `@${string}`
    internalName?: boolean | `@${string}`
    internalNotes?: boolean | `@${string}`
    isDirectListingPurchase?: boolean | `@${string}`
    isQualityRequirementsEnabled?: boolean | `@${string}`
    listingGuidance?: boolean | `@${string}`
    maxDiscoveryBonus?: boolean | `@${string}`
    minimumCreatorQualityScore?: boolean | `@${string}`
    nonRetailReviewLink?: boolean | `@${string}`
    nonRetailReviewWebsiteName?: boolean | `@${string}`
    payForClicksEnabled?: boolean | `@${string}`
    payForClicksMinClicks?: boolean | `@${string}`
    payForImpressionsBonus?: ResolverInputTypes['PayForImpressionsBonusConfig']
    payForPerformanceBonus?: ResolverInputTypes['StatusTierBonusConfig']
    payForPostingContentEarly?: ResolverInputTypes['PayForPostingContentEarlyConfig']
    postingDeadlineDaysRelativeToApproval?: boolean | `@${string}`
    productLink?: ResolverInputTypes['UserLink']
    productLinkId?: boolean | `@${string}`
    productPostingGuidance?: boolean | `@${string}`
    productPostingGuidanceDocument?: boolean | `@${string}`
    promoCode?: boolean | `@${string}`
    promoCodeGuidance?: boolean | `@${string}`
    promoCodeType?: boolean | `@${string}`
    promoDiscountAmount?: boolean | `@${string}`
    promoDiscountType?: boolean | `@${string}`
    promoEndDate?: boolean | `@${string}`
    promoLink?: boolean | `@${string}`
    promoStartDate?: boolean | `@${string}`
    promotionLink?: ResolverInputTypes['UserLink']
    promotionLinkId?: boolean | `@${string}`
    purchaseByPromoCode?: boolean | `@${string}`
    purchaseDiscountCode?: boolean | `@${string}`
    purchaseDiscountCodeDetails?: boolean | `@${string}`
    purchaseDiscountCodePercentage?: boolean | `@${string}`
    qualityLevel?: boolean | `@${string}`
    requestEarlyProductFeedback?: boolean | `@${string}`
    requestProductFeedback?: boolean | `@${string}`
    requiredProductLinkEnabled?: boolean | `@${string}`
    sequence?: ResolverInputTypes['FormSequenceConfiguration']
    sequenceId?: boolean | `@${string}`
    shopifyPriceRule?: boolean | `@${string}`
    shopifyPromotionId?: boolean | `@${string}`
    shopifyPromotionType?: boolean | `@${string}`
    sku?: ResolverInputTypes['Sku']
    skuId?: boolean | `@${string}`
    socialMediaExampleUrls?: boolean | `@${string}`
    stagingApprovalConfiguration?: ResolverInputTypes['StagingApprovalConfiguration']
    stagingApprovalRequiredNumCreatorsClientReviewed?: boolean | `@${string}`
    stagingRequirementType?: boolean | `@${string}`
    stagingSocialQualityRequirement?: boolean | `@${string}`
    tikTokHandle?: boolean | `@${string}`
    unitsPurchased?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    validateProductFoundVariations?: boolean | `@${string}`
    visibleAt?: boolean | `@${string}`
    visibleEndsAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductBonusConfigSnapshot']: AliasType<{
    closeoutSurveyBonus?: boolean | `@${string}`
    contentBonuses?: ResolverInputTypes['ProductSocialContent']
    createdAt?: boolean | `@${string}`
    discoveryBonus?: boolean | `@${string}`
    payForImpressionsBonus?: ResolverInputTypes['PayForImpressionsBonusConfig']
    productId?: boolean | `@${string}`
    snapshotAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductKeywords']: AliasType<{
    adminPriority?: boolean | `@${string}`
    brandPriority?: boolean | `@${string}`
    brandResponse?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    creationSource?: boolean | `@${string}`
    endDate?: boolean | `@${string}`
    heliumAccount?: boolean | `@${string}`
    heliumAsin?: boolean | `@${string}`
    heliumLink?: boolean | `@${string}`
    id?: boolean | `@${string}`
    keyword?: boolean | `@${string}`
    monthlySearchVolume?: boolean | `@${string}`
    rank?: boolean | `@${string}`
    rankingHistory?: ResolverInputTypes['RankPoint']
    rankingLastSkippedAt?: boolean | `@${string}`
    rankingLastSkippedByUserId?: boolean | `@${string}`
    rankingLastUpdatedAt?: boolean | `@${string}`
    rankingLastUpdatedByUserId?: boolean | `@${string}`
    recommended?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    startDate?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    usedForCampaign?: boolean | `@${string}`
    visible?: boolean | `@${string}`
    weight?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductRatingSnapshot']: AliasType<{
    createdAt?: boolean | `@${string}`
    numFiveStar?: boolean | `@${string}`
    numFourStar?: boolean | `@${string}`
    numOneStar?: boolean | `@${string}`
    numRatings?: boolean | `@${string}`
    numReviews?: boolean | `@${string}`
    numThreeStar?: boolean | `@${string}`
    numTwoStar?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    snapshotAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductSocialContent']: AliasType<{
    bonus?: boolean | `@${string}`
    brandContentGuidance?: ResolverInputTypes['ContentGuidance']
    brandContentRequirements?: boolean | `@${string}`
    bronzeBonus?: boolean | `@${string}`
    channel?: boolean | `@${string}`
    diamondBonus?: boolean | `@${string}`
    goldBonus?: boolean | `@${string}`
    inspirationalContent?: ResolverInputTypes['InspirationalContent']
    instances?: boolean | `@${string}`
    platinumBonus?: boolean | `@${string}`
    requirementLevel?: boolean | `@${string}`
    requiresManualApproval?: boolean | `@${string}`
    silverBonus?: boolean | `@${string}`
    talkingPoints?: ResolverInputTypes['ContentGuidance']
    totalExpectedCount?: boolean | `@${string}`
    type?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Purchase']: AliasType<{
    amount?: boolean | `@${string}`
    cardId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    currency?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isFraud?: boolean | `@${string}`
    keywordAtPurchase?: boolean | `@${string}`
    purchasedAt?: boolean | `@${string}`
    shippedAt?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PushAddress']: AliasType<{
    address?: boolean | `@${string}`
    pushChannel?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PushChannelConfiguration']: AliasType<{
    attributes?: ResolverInputTypes['KeyAttribute']
    enabled?: boolean | `@${string}`
    templateId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Query']: AliasType<{
    ByAgencyId?: [
      {
        agencyId?: string | undefined | null
        filter?: ResolverInputTypes['ModelCompanyFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCompanyConnection']
    ]
    ByBrandId?: [
      {
        brandId?: string | undefined | null
        filter?: ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignConnection']
    ]
    BySkuIdByStoreName?: [
      {
        filter?: ResolverInputTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        storeName?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelSkuConnection']
    ]
    ByVisibilityToUser?: [
      {
        filter?: ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        visibleToUser?: string | undefined | null
      },
      ResolverInputTypes['ModelCampaignConnection']
    ]
    ByVisibilityToUserByCreatedAt?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        visibleToUser?: string | undefined | null
      },
      ResolverInputTypes['ModelCampaignConnection']
    ]
    CampaignsBySkuId?: [
      {
        filter?: ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignConnection']
    ]
    asinSnapshotsBySkuId?: [
      {
        filter?: ResolverInputTypes['ModelSkuSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        marketplaceSkuSnapshotDate?:
          | ResolverInputTypes['ModelSkuSnapshotBySkuIdCompositeKeyConditionInput']
          | undefined
          | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelSkuSnapshotConnection']
    ]
    autoBuyFlowConfigurationByStatusByStartDate?: [
      {
        filter?: ResolverInputTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        startDate?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        status?: ResolverInputTypes['AutoBuyStatus'] | undefined | null
      },
      ResolverInputTypes['ModelAutoBuyFlowConfigurationConnection']
    ]
    autoBuyReleasesByProductIdDate?: [
      {
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelAutoBuyReleaseConnection']
    ]
    brandApiByShopId?: [
      {
        filter?: ResolverInputTypes['ModelBrandApiFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        shopId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelBrandApiConnection']
    ]
    brandStoreScrapeUrl?: [
      {
        brandStoreScrapeUrl?: string | undefined | null
        filter?: ResolverInputTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelBrandConnection']
    ]
    brandsByActive?: [
      {
        active?: string | undefined | null
        filter?: ResolverInputTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelBrandConnection']
    ]
    brandsByActiveBySubscriptionEndsAt?: [
      {
        active?: string | undefined | null
        filter?: ResolverInputTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        subscriptionEndsAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelBrandConnection']
    ]
    brandsByCompanyId?: [
      {
        companyId?: string | undefined | null
        filter?: ResolverInputTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelBrandConnection']
    ]
    brandsByPlatform?: [
      {
        filter?: ResolverInputTypes['ModelBrandFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        platform?: ResolverInputTypes['PlatformSource'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelBrandConnection']
    ]
    byCampaignByCoarseCampaignStatus?: [
      {
        campaignId?: string | undefined | null
        coarseCampaignStatus?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    bySkuBySnapshotAt?: [
      {
        filter?: ResolverInputTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sku?: string | undefined | null
        snapshotAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductRatingSnapshotConnection']
    ]
    byUserByCoarseCampaignStatus?: [
      {
        coarseCampaignStatus?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    campaignInviteByCampaignByUser?: [
      {
        campaignId?: string | undefined | null
        filter?: ResolverInputTypes['ModelCampaignInviteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignInviteConnection']
    ]
    campaignInviteByNotificationStatusByPriority?: [
      {
        filter?: ResolverInputTypes['ModelCampaignInviteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        notificationStatus?: ResolverInputTypes['NotificationStatus'] | undefined | null
        priority?: ResolverInputTypes['ModelIntKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignInviteConnection']
    ]
    campaignInviteByUserByCreatedAt?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignInviteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelCampaignInviteConnection']
    ]
    campaignSourcingProspectsByIgHandle?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        instagramHandle?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignSourcingProspectConnection']
    ]
    campaignSourcingProspectsByTiktokHandle?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        tiktokHandle?: string | undefined | null
      },
      ResolverInputTypes['ModelCampaignSourcingProspectConnection']
    ]
    campaignSourcingProspectsByUserIdentifier?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userIdentifier?: string | undefined | null
      },
      ResolverInputTypes['ModelCampaignSourcingProspectConnection']
    ]
    campaignSourcingProspectsByYoutubeHandle?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        youtubeChannelHandle?: string | undefined | null
      },
      ResolverInputTypes['ModelCampaignSourcingProspectConnection']
    ]
    campaignsByActive?: [
      {
        active?: string | undefined | null
        filter?: ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignConnection']
    ]
    campaignsByActiveByAnticipatedStartDate?: [
      {
        active?: string | undefined | null
        anticipatedStartDate?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignConnection']
    ]
    campaignsByPayForPerformanceActive?: [
      {
        active?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payForPerformanceEnabled?: ResolverInputTypes['PayForPerformanceFlag'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignConnection']
    ]
    chatConversationsV2ByActiveByAdminUnreadCount?: [
      {
        active?: ResolverInputTypes['Active'] | undefined | null
        adminUnreadCount?: ResolverInputTypes['ModelIntKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelChatConversationV2Connection']
    ]
    chatConversationsV2ByChatTypeByActive?: [
      {
        active?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        chatType?: ResolverInputTypes['ChatType'] | undefined | null
        filter?: ResolverInputTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelChatConversationV2Connection']
    ]
    chatConversationsV2ByChatTypeByAdminUnreadCount?: [
      {
        adminUnreadCount?: ResolverInputTypes['ModelIntKeyConditionInput'] | undefined | null
        chatType?: ResolverInputTypes['ChatType'] | undefined | null
        filter?: ResolverInputTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelChatConversationV2Connection']
    ]
    chatConversationsV2ByUserIdByActive?: [
      {
        active?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelChatConversationV2Connection']
    ]
    communicationNotificationsByTraceId?: [
      {
        filter?: ResolverInputTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        traceId?: string | undefined | null
      },
      ResolverInputTypes['ModelCommunicationNotificationConnection']
    ]
    communicationNotificationsByUserCampaignId?: [
      {
        filter?: ResolverInputTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ResolverInputTypes['ModelCommunicationNotificationConnection']
    ]
    communicationNotificationsByUserId?: [
      {
        filter?: ResolverInputTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelCommunicationNotificationConnection']
    ]
    communicationNotificationsByUserIdNotificationId?: [
      {
        filter?: ResolverInputTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        notificationId?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelCommunicationNotificationConnection']
    ]
    connectedAccountsByAccountIdByAccountType?: [
      {
        accountId?: string | undefined | null
        accountType?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelConnectedAccountFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelConnectedAccountConnection']
    ]
    conversationByContactOwnerIdByStatus?: [
      {
        contactOwnerId?: string | undefined | null
        filter?: ResolverInputTypes['ModelConversationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        status?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelConversationConnection']
    ]
    conversationByStatusByStatusChange?: [
      {
        filter?: ResolverInputTypes['ModelConversationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        status?: ResolverInputTypes['ConversationStatus'] | undefined | null
        statusChangedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelConversationConnection']
    ]
    conversationMessageByMessageId?: [
      {
        filter?: ResolverInputTypes['ModelConversationMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        messageId?: string | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelConversationMessageConnection']
    ]
    conversationMessageByStatusBySendAt?: [
      {
        filter?: ResolverInputTypes['ModelConversationMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sendAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        status?: ResolverInputTypes['ConversationMessageStatus'] | undefined | null
      },
      ResolverInputTypes['ModelConversationMessageConnection']
    ]
    conversationMessageByStatusBySendAttempts?: [
      {
        filter?: ResolverInputTypes['ModelConversationMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sendAttempts?: ResolverInputTypes['ModelIntKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        status?: ResolverInputTypes['ConversationMessageStatus'] | undefined | null
      },
      ResolverInputTypes['ModelConversationMessageConnection']
    ]
    draftHistoryBySocialContentId?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        snapshotDate?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignSocialContentId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryConnection']
    ]
    getAutoBuyDistribution?: [{ id: string }, ResolverInputTypes['AutoBuyDistribution']]
    getAutoBuyFlowConfiguration?: [{ flowId: string }, ResolverInputTypes['AutoBuyFlowConfiguration']]
    getAutoBuyFlowReleaseSchedule?: [{ date: string; flowId: string }, ResolverInputTypes['AutoBuyFlowReleaseSchedule']]
    getAutoBuyRelease?: [{ date: string; userCampaignId: string }, ResolverInputTypes['AutoBuyRelease']]
    getBrand?: [{ id: string }, ResolverInputTypes['Brand']]
    getBrandApi?: [{ api: ResolverInputTypes['BrandApiType']; brandId: string }, ResolverInputTypes['BrandApi']]
    getCampaign?: [{ id: string }, ResolverInputTypes['Campaign']]
    getCampaignClicks?: [{ campaignId: string; date: string }, ResolverInputTypes['CampaignClicks']]
    getCampaignDetailsForUser?: [
      { campaignId: string; productId?: string | undefined | null },
      ResolverInputTypes['CampaignForUser']
    ]
    getCampaignInvite?: [{ id: string }, ResolverInputTypes['CampaignInvite']]
    getCampaignSourcingProspect?: [
      { campaignId: string; userIdentifier: string },
      ResolverInputTypes['CampaignSourcingProspect']
    ]
    getChatConversationV2?: [
      { chatType: ResolverInputTypes['ChatType']; resourceId: string },
      ResolverInputTypes['ChatConversationV2']
    ]
    getCommunicationNotification?: [{ id: string }, ResolverInputTypes['CommunicationNotification']]
    getCompany?: [{ id: string }, ResolverInputTypes['Company']]
    getConnectedAccount?: [{ accountId: string; userId: string }, ResolverInputTypes['ConnectedAccount']]
    getConversation?: [{ userId: string }, ResolverInputTypes['Conversation']]
    getConversationMessage?: [{ id: string; userId: string }, ResolverInputTypes['ConversationMessage']]
    getConversationsAccessToken?: [
      { isSupport?: boolean | undefined | null },
      ResolverInputTypes['GetConversationsAccessTokenResponse']
    ]
    getEtailerSkuMetrics?: [{ date: string; skuId: string }, ResolverInputTypes['EtailerSkuMetrics']]
    getFeatureFlag?: [
      {
        environment: ResolverInputTypes['FeatureFlagEnvironment']
        featureFlag: string
        userId?: string | undefined | null
      },
      ResolverInputTypes['GetFeatureFlagResponse']
    ]
    getFormDefinition?: [
      {
        campaignId: string
        formType?: ResolverInputTypes['FormType'] | undefined | null
        productId?: string | undefined | null
      },
      ResolverInputTypes['FormDefinition']
    ]
    getFormSequenceConfiguration?: [{ id: string }, ResolverInputTypes['FormSequenceConfiguration']]
    getInstagramStoryScreen?: [{ id: string }, ResolverInputTypes['InstagramStoryScreen']]
    getMessage?: [{ id: string }, ResolverInputTypes['Message']]
    getNote?: [{ id: string }, ResolverInputTypes['Note']]
    getNotificationConfiguration?: [{ id: string }, ResolverInputTypes['NotificationConfiguration']]
    getProduct?: [{ id: string }, ResolverInputTypes['Product']]
    getProductBonusConfigSnapshot?: [
      { productId: string; snapshotAt: string },
      ResolverInputTypes['ProductBonusConfigSnapshot']
    ]
    getProductKeywords?: [{ id: string }, ResolverInputTypes['ProductKeywords']]
    getProductRatingSnapshot?: [{ skuId: string; snapshotAt: string }, ResolverInputTypes['ProductRatingSnapshot']]
    getPurchase?: [{ id: string }, ResolverInputTypes['Purchase']]
    getReferral?: [{ id: string }, ResolverInputTypes['Referral']]
    getReview?: [{ id: string }, ResolverInputTypes['Review']]
    getSku?: [{ id: string }, ResolverInputTypes['Sku']]
    getSkuInformation?: [{ countryCode: string; skuId: string }, ResolverInputTypes['SkuInformation']]
    getSkuSnapshot?: [{ marketplaceSku: string; snapshotDate: string }, ResolverInputTypes['SkuSnapshot']]
    getSocialMediaPosts?: [
      { dateSince: string; dateUntil: string; socialChannel: ResolverInputTypes['SocialChannel']; userId: string },
      ResolverInputTypes['SocialMediaPost']
    ]
    getUser?: [{ id: string }, ResolverInputTypes['User']]
    getUserBonusPayout?: [{ id: string }, ResolverInputTypes['UserBonusPayout']]
    getUserCampaign?: [{ id: string }, ResolverInputTypes['UserCampaign']]
    getUserCampaignChangelog?: [
      { createdAt: string; userCampaignId: string },
      ResolverInputTypes['UserCampaignChangelog']
    ]
    getUserCampaignSnapshot?: [
      { userCampaignId: string; userCampaignVersion: number },
      ResolverInputTypes['UserCampaignSnapshot']
    ]
    getUserCampaignSocialContent?: [{ id: string }, ResolverInputTypes['UserCampaignSocialContent']]
    getUserCampaignSocialContentDraftHistory?: [
      { id: string },
      ResolverInputTypes['UserCampaignSocialContentDraftHistory']
    ]
    getUserCampaignSocialContentReview?: [
      { reviewType: ResolverInputTypes['UserCampaignSocialContentReviewType']; userCampaignSocialContentId: string },
      ResolverInputTypes['UserCampaignSocialContentReview']
    ]
    getUserDeviceSnapshot?: [{ id: string }, ResolverInputTypes['UserDeviceSnapshot']]
    getUserFollower?: [{ followerUserId: string; userId: string }, ResolverInputTypes['UserFollower']]
    getUserLink?: [{ id: string }, ResolverInputTypes['UserLink']]
    getUserNotificationPreference?: [
      { category: string; userId: string },
      ResolverInputTypes['UserNotificationPreference']
    ]
    getUserPayoutRequest?: [{ id: string }, ResolverInputTypes['UserPayoutRequest']]
    getUserStagingApproval?: [{ id: string }, ResolverInputTypes['UserStagingApproval']]
    getVideoContentScan?: [{ id: string }, ResolverInputTypes['VideoContentScan']]
    instagramStoryByExternalId?: [
      {
        externalId?: string | undefined | null
        filter?: ResolverInputTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelInstagramStoryScreenConnection']
    ]
    instagramStoryByInstagramUserId?: [
      {
        filter?: ResolverInputTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
        instagramUserId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        postingDate?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelInstagramStoryScreenConnection']
    ]
    instagramStoryByMediaId?: [
      {
        filter?: ResolverInputTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
        limit?: number | undefined | null
        mediaId?: string | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelInstagramStoryScreenConnection']
    ]
    listAutoBuyDistributions?: [
      {
        filter?: ResolverInputTypes['ModelAutoBuyDistributionFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelAutoBuyDistributionConnection']
    ]
    listAutoBuyFlowConfigurations?: [
      {
        filter?: ResolverInputTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null
        flowId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelAutoBuyFlowConfigurationConnection']
    ]
    listAutoBuyFlowReleaseSchedules?: [
      {
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null
        flowId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleConnection']
    ]
    listAutoBuyReleases?: [
      {
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ResolverInputTypes['ModelAutoBuyReleaseConnection']
    ]
    listBrandApis?: [
      {
        api?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        brandId?: string | undefined | null
        filter?: ResolverInputTypes['ModelBrandApiFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelBrandApiConnection']
    ]
    listBrands?: [
      {
        filter?: ResolverInputTypes['ModelBrandFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelBrandConnection']
    ]
    listCampaignClickss?: [
      {
        campaignId?: string | undefined | null
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelCampaignClicksFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignClicksConnection']
    ]
    listCampaignInvites?: [
      {
        filter?: ResolverInputTypes['ModelCampaignInviteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelCampaignInviteConnection']
    ]
    listCampaignSourcingProspects?: [
      {
        campaignId?: string | undefined | null
        filter?: ResolverInputTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userIdentifier?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelCampaignSourcingProspectConnection']
    ]
    listCampaigns?: [
      {
        filter?: ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelCampaignConnection']
    ]
    listCampaignsForUsersToApplyTo?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['ListCampaignsForUserResponse']
    ]
    listChatConversationV2s?: [
      {
        chatType?: ResolverInputTypes['ChatType'] | undefined | null
        filter?: ResolverInputTypes['ModelChatConversationV2FilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        resourceId?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelChatConversationV2Connection']
    ]
    listCommunicationNotifications?: [
      {
        filter?: ResolverInputTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCommunicationNotificationConnection']
    ]
    listCompanys?: [
      {
        filter?: ResolverInputTypes['ModelCompanyFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCompanyConnection']
    ]
    listConnectedAccounts?: [
      {
        accountId?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelConnectedAccountFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelConnectedAccountConnection']
    ]
    listConversationMessages?: [
      {
        filter?: ResolverInputTypes['ModelConversationMessageFilterInput'] | undefined | null
        id?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelConversationMessageConnection']
    ]
    listConversations?: [
      {
        filter?: ResolverInputTypes['ModelConversationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelConversationConnection']
    ]
    listEtailerSkuMetricss?: [
      {
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelEtailerSkuMetricsFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelEtailerSkuMetricsConnection']
    ]
    listFormSequenceConfigurations?: [
      {
        filter?: ResolverInputTypes['ModelFormSequenceConfigurationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelFormSequenceConfigurationConnection']
    ]
    listInstagramStoryScreens?: [
      {
        filter?: ResolverInputTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelInstagramStoryScreenConnection']
    ]
    listMessages?: [
      {
        filter?: ResolverInputTypes['ModelMessageFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelMessageConnection']
    ]
    listNotes?: [
      {
        filter?: ResolverInputTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelNoteConnection']
    ]
    listNotificationConfigurations?: [
      {
        filter?: ResolverInputTypes['ModelNotificationConfigurationFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelNotificationConfigurationConnection']
    ]
    listProductBonusConfigSnapshots?: [
      {
        filter?: ResolverInputTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        snapshotAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductBonusConfigSnapshotConnection']
    ]
    listProductKeywordss?: [
      {
        filter?: ResolverInputTypes['ModelProductKeywordsFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductKeywordsConnection']
    ]
    listProductRatingSnapshots?: [
      {
        filter?: ResolverInputTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        snapshotAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductRatingSnapshotConnection']
    ]
    listProducts?: [
      {
        filter?: ResolverInputTypes['ModelProductFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductConnection']
    ]
    listPurchases?: [
      {
        filter?: ResolverInputTypes['ModelPurchaseFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelPurchaseConnection']
    ]
    listReferrals?: [
      {
        filter?: ResolverInputTypes['ModelReferralFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelReferralConnection']
    ]
    listReviews?: [
      {
        filter?: ResolverInputTypes['ModelReviewFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelReviewConnection']
    ]
    listSkuSnapshots?: [
      {
        filter?: ResolverInputTypes['ModelSkuSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        marketplaceSku?: string | undefined | null
        nextToken?: string | undefined | null
        snapshotDate?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelSkuSnapshotConnection']
    ]
    listSkus?: [
      {
        filter?: ResolverInputTypes['ModelSkuFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelSkuConnection']
    ]
    listUserBonusPayouts?: [
      {
        filter?: ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserBonusPayoutConnection']
    ]
    listUserCampaignChangelogs?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignChangelogConnection']
    ]
    listUserCampaignSnapshots?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignSnapshotFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
        userCampaignVersion?: ResolverInputTypes['ModelIntKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSnapshotConnection']
    ]
    listUserCampaignSocialContentDraftHistorys?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryConnection']
    ]
    listUserCampaignSocialContentReviews?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewType?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignSocialContentId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentReviewConnection']
    ]
    listUserCampaignSocialContents?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentConnection']
    ]
    listUserCampaigns?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    listUserDeviceSnapshots?: [
      {
        filter?: ResolverInputTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserDeviceSnapshotConnection']
    ]
    listUserFollowers?: [
      {
        filter?: ResolverInputTypes['ModelUserFollowerFilterInput'] | undefined | null
        followerUserId?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserFollowerConnection']
    ]
    listUserLinks?: [
      {
        filter?: ResolverInputTypes['ModelUserLinkFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelUserLinkConnection']
    ]
    listUserNotificationPreferences?: [
      {
        category?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserNotificationPreferenceConnection']
    ]
    listUserPayoutRequests?: [
      {
        filter?: ResolverInputTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserPayoutRequestConnection']
    ]
    listUserStagingApprovals?: [
      {
        filter?: ResolverInputTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
      },
      ResolverInputTypes['ModelUserStagingApprovalConnection']
    ]
    listUsers?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    listVideoContentScans?: [
      {
        filter?: ResolverInputTypes['ModelVideoContentScanFilterInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelVideoContentScanConnection']
    ]
    messagesByUserCampaign?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ResolverInputTypes['ModelMessageConnection']
    ]
    notesByParentId?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        parentId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelNoteConnection']
    ]
    osSearchUsers?: [{ search: ResolverInputTypes['UserSearchInput'] }, ResolverInputTypes['UserSearchOutput']]
    productKeywordsByCampaignIdByKeyword?: [
      {
        campaignId?: string | undefined | null
        filter?: ResolverInputTypes['ModelProductKeywordsFilterInput'] | undefined | null
        keyword?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductKeywordsConnection']
    ]
    productsByActive?: [
      {
        active?: string | undefined | null
        filter?: ResolverInputTypes['ModelProductFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductConnection']
    ]
    productsByCampaignId?: [
      {
        campaignId?: string | undefined | null
        filter?: ResolverInputTypes['ModelProductFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductConnection']
    ]
    productsBySkuId?: [
      {
        filter?: ResolverInputTypes['ModelProductFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelProductConnection']
    ]
    purchasesCardRetrieve?: [{ userCampaignId: string }, ResolverInputTypes['CardDetails']]
    referralsByReferrerUserIdReferredUserId?: [
      {
        filter?: ResolverInputTypes['ModelReferralFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        referredUserId?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
        referrerUserId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelReferralConnection']
    ]
    requestSocialContentScript?: [
      {
        contentRequirementLevel: ResolverInputTypes['ContentRequirementLevel']
        socialContentType: ResolverInputTypes['SocialContentType']
        userCampaignId: string
      },
      ResolverInputTypes['RequestSocialContentScriptResponse']
    ]
    reviewsByAttributedUserId?: [
      {
        attributedUserId?: string | undefined | null
        filter?: ResolverInputTypes['ModelReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelReviewConnection']
    ]
    reviewsByProductId?: [
      {
        filter?: ResolverInputTypes['ModelReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        reviewedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelReviewConnection']
    ]
    reviewsByReviewerId?: [
      {
        filter?: ResolverInputTypes['ModelReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        reviewerId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelReviewConnection']
    ]
    reviewsBySkuId?: [
      {
        filter?: ResolverInputTypes['ModelReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelReviewConnection']
    ]
    skusByActive?: [
      {
        active?: string | undefined | null
        filter?: ResolverInputTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelSkuConnection']
    ]
    skusByActiveByAvailabilityDate?: [
      {
        active?: string | undefined | null
        availabilityDate?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelSkuConnection']
    ]
    skusByActiveByReleaseDate?: [
      {
        active?: string | undefined | null
        filter?: ResolverInputTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        releaseDate?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelSkuConnection']
    ]
    skusByBrand?: [
      {
        brandId?: string | undefined | null
        filter?: ResolverInputTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelSkuConnection']
    ]
    skusByParentSkuId?: [
      {
        filter?: ResolverInputTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        parentSkuId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelSkuConnection']
    ]
    socialContentByApprovalStatusByApprovedAt?: [
      {
        approvalStatus?: ResolverInputTypes['ContentApprovalStatus'] | undefined | null
        approvedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByApprovalStatusByDraftSubmittedAt?: [
      {
        approvalStatus?: ResolverInputTypes['ContentApprovalStatus'] | undefined | null
        draftSubmittedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByDraftStatusByEnteredBrandApprovalAt?: [
      {
        draftEnteredBrandApprovalAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        draftStatus?: ResolverInputTypes['DraftStatus'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByReviewStatusByPostedAt?: [
      {
        complianceReviewStatus?: ResolverInputTypes['ContentComplianceReviewStatus'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        postedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByReviewStatusByReviewedAt?: [
      {
        complianceReviewStatus?: ResolverInputTypes['ContentComplianceReviewStatus'] | undefined | null
        complianceReviewedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentByUserCampaignId?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentConnection']
    ]
    socialContentReviewBySocialContentIdByCreatedAt?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignSocialContentId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentReviewConnection']
    ]
    socialContentReviewByTypeByStatus?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        reviewStatus?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        reviewType?: ResolverInputTypes['UserCampaignSocialContentReviewType'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentReviewConnection']
    ]
    userBonusesByPayoutBatchId?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutBatchId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByPayoutState?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutState?: ResolverInputTypes['BonusPayoutState'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByUserCampaignId?: [
      {
        filter?: ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByUserId?: [
      {
        filter?: ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByUserIdByCreatedAt?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserBonusPayoutConnection']
    ]
    userBonusesByUserIdByPayoutState?: [
      {
        filter?: ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutState?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserBonusPayoutConnection']
    ]
    userCampaignByCampaignByAcceptanceStatus?: [
      {
        campaignAcceptanceStatus?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        campaignId?: string | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCampaignByCard?: [
      {
        campaignId?: string | undefined | null
        cardId?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCampaignByStatus?: [
      {
        campaignId?: string | undefined | null
        campaignStatus?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCampaignByUser?: [
      {
        campaignId?: string | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCardId?: [
      {
        cardId?: string | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByCoarseCampaignStatus?: [
      {
        coarseCampaignStatus?: ResolverInputTypes['CoarseCampaignStatus'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByKeywordHash?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        keywordsV2Hash?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByOrderId?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        orderId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByProductByCampaignAcceptanceStatus?: [
      {
        campaignAcceptanceStatus?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByProductByCampaignStatus?: [
      {
        campaignStatus?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        productId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByStatusByCreatedAt?: [
      {
        campaignStatus?: ResolverInputTypes['CampaignStatus'] | undefined | null
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByUser?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignByUserByCard?: [
      {
        cardId?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userCampaignChangelogByUserCampaignIdByEntryType?: [
      {
        entryType?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignChangelogConnection']
    ]
    userCampaignsByUserByCampaignAcceptanceStatus?: [
      {
        campaignAcceptanceStatus?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    userDeviceSnapshotByIdfvBySnapshotAt?: [
      {
        filter?: ResolverInputTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null
        idfv?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        snapshotAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserDeviceSnapshotConnection']
    ]
    userFollowerByFollowerUserId?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserFollowerFilterInput'] | undefined | null
        followerUserId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserFollowerConnection']
    ]
    userFollowerByUserIdByIsFavorite?: [
      {
        filter?: ResolverInputTypes['ModelUserFollowerFilterInput'] | undefined | null
        isFavorite?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserFollowerConnection']
    ]
    userLinksByCampaignIdUniqueClicks?: [
      {
        campaignId?: string | undefined | null
        filter?: ResolverInputTypes['ModelUserLinkFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        uniqueClicks?: ResolverInputTypes['ModelIntKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelUserLinkConnection']
    ]
    userLinksByUserEncodedLink?: [
      {
        filter?: ResolverInputTypes['ModelUserLinkFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userEncodedLink?: string | undefined | null
      },
      ResolverInputTypes['ModelUserLinkConnection']
    ]
    userPayoutRequestsByPayoutBatchId?: [
      {
        filter?: ResolverInputTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutBatchId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserPayoutRequestConnection']
    ]
    userPayoutRequestsByPayoutState?: [
      {
        filter?: ResolverInputTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        payoutState?: ResolverInputTypes['BonusPayoutState'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserPayoutRequestConnection']
    ]
    userPayoutRequestsByUserId?: [
      {
        filter?: ResolverInputTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserPayoutRequestConnection']
    ]
    userStagingApprovalsByOutcomeByType?: [
      {
        filter?: ResolverInputTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userStagingApprovalOutcome?: ResolverInputTypes['UserStagingApprovalOutcome'] | undefined | null
        userStagingApprovalType?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
      },
      ResolverInputTypes['ModelUserStagingApprovalConnection']
    ]
    userStagingApprovalsByUserCampaignId?: [
      {
        filter?: ResolverInputTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userCampaignId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserStagingApprovalConnection']
    ]
    usersByActiveByCreatedAt?: [
      {
        active?: ResolverInputTypes['Active'] | undefined | null
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByActiveByLastActiveAt?: [
      {
        active?: ResolverInputTypes['Active'] | undefined | null
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        lastActiveAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByAmznProfileId?: [
      {
        amznProfileId?: string | undefined | null
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByAmznReviewerId?: [
      {
        amznReviewerId?: string | undefined | null
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByContentRatingSubmittedBy?: [
      {
        contentRatingCompletedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        contentRatingSubmittedBy?: string | undefined | null
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByContentReviewStatusByCompletedAt?: [
      {
        contentRatingCompletedAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        contentRatingReviewStatus?: ResolverInputTypes['ContentRatingReviewStatus'] | undefined | null
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByContentReviewStatusByLastActiveAt?: [
      {
        contentRatingReviewStatus?: ResolverInputTypes['ContentRatingReviewStatus'] | undefined | null
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        lastActiveAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByEmail?: [
      {
        email?: string | undefined | null
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByFacebookUserId?: [
      {
        facebookUserId?: string | undefined | null
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByInstagram?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        instagramHandle?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByInstagramTrailingPostEngagementAvg?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        instagramTrailingPostEngagementAvg?: ResolverInputTypes['ModelFloatKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        type?: string | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByInstagramUserId?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        instagramUserId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByReferralCode?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        referralCode?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByReferrer?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        referrer?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersBySupportPriorityFlagByLastActiveAt?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        lastActiveAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        supportPriorityFlag?: ResolverInputTypes['SupportPriorityFlag'] | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByTiktokAccountId?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        tiktokAccountId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByTiktokHandle?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        tiktokHandle?: string | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    usersByYoutubeAccountId?: [
      {
        filter?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        youtubeChannelId?: string | undefined | null
      },
      ResolverInputTypes['ModelUserConnection']
    ]
    verifyOrderConfirmationScreenshot?: [
      { screenshotKey: string; storeName?: string | undefined | null; userCampaignId?: string | undefined | null },
      ResolverInputTypes['OrderConfirmationScreenshotVerificationOutput']
    ]
    verifyReviewScreenshot?: [{ screenshotKey: string }, boolean | `@${string}`]
    videoContentScanByProcessId?: [
      {
        filter?: ResolverInputTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        processId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelVideoContentScanConnection']
    ]
    videoContentScanByScanStatus?: [
      {
        filter?: ResolverInputTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        scanStatus?: ResolverInputTypes['ContentScanStatus'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelVideoContentScanConnection']
    ]
    videoContentScanByUrl?: [
      {
        filter?: ResolverInputTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        url?: string | undefined | null
      },
      ResolverInputTypes['ModelVideoContentScanConnection']
    ]
    videoContentScanByUserId?: [
      {
        filter?: ResolverInputTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelVideoContentScanConnection']
    ]
    __typename?: boolean | `@${string}`
  }>
  ['RankPoint']: AliasType<{
    rank?: boolean | `@${string}`
    rankAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Rate']: AliasType<{
    count?: boolean | `@${string}`
    isIntegrationFallbackRate?: boolean | `@${string}`
    period?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Referral']: AliasType<{
    bonusPayout?: ResolverInputTypes['UserBonusPayout']
    bonusPayoutId?: boolean | `@${string}`
    campaign?: ResolverInputTypes['Campaign']
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    referralType?: boolean | `@${string}`
    referredUserId?: boolean | `@${string}`
    referrerUserId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ReferralBonuses']: AliasType<{
    campaignReferralBonuses?: ResolverInputTypes['CampaignReferralBonus']
    creatorReferralBonuses?: ResolverInputTypes['CreatorReferralBonus']
    creatorReferralSignups?: ResolverInputTypes['CreatorReferralSignup']
    __typename?: boolean | `@${string}`
  }>
  ['RequestSocialContentScriptResponse']: AliasType<{
    status?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ResetUserAttributeResponse']: AliasType<{
    cognitoUserModel?: ResolverInputTypes['CognitoUserModel']
    statusCode?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Review']: AliasType<{
    attributedUserId?: boolean | `@${string}`
    author?: boolean | `@${string}`
    authorBadge?: boolean | `@${string}`
    badge?: boolean | `@${string}`
    commentCount?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    helpfulReactions?: boolean | `@${string}`
    id?: boolean | `@${string}`
    negativeReactions?: boolean | `@${string}`
    photos?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    productVariant?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    removed?: boolean | `@${string}`
    reviewedAt?: boolean | `@${string}`
    reviewedSku?: boolean | `@${string}`
    reviewerId?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    store?: boolean | `@${string}`
    text?: boolean | `@${string}`
    title?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    videos?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SequenceFormSubmissionValues']: AliasType<{
    componentId?: boolean | `@${string}`
    componentName?: boolean | `@${string}`
    componentType?: boolean | `@${string}`
    sequenceId?: boolean | `@${string}`
    type?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Sku']: AliasType<{
    active?: boolean | `@${string}`
    approxRetailPrice?: boolean | `@${string}`
    approxShippingCost?: boolean | `@${string}`
    attributes?: ResolverInputTypes['SkuAttribute']
    availabilityDate?: boolean | `@${string}`
    availabilityNotified?: boolean | `@${string}`
    availabilityNotifiedAt?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    brandListingKey?: boolean | `@${string}`
    brandStoreId?: boolean | `@${string}`
    bundle?: boolean | `@${string}`
    childSkus?: [
      {
        filter?: ResolverInputTypes['ModelSkuFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelSkuConnection']
    ]
    createdAt?: boolean | `@${string}`
    createdByUserId?: boolean | `@${string}`
    creationSource?: boolean | `@${string}`
    creationSourceUrl?: boolean | `@${string}`
    currency?: boolean | `@${string}`
    currentBuyBoxPrice?: boolean | `@${string}`
    currentBuyBoxPriceCents?: boolean | `@${string}`
    estimatedAvailabilityDate?: boolean | `@${string}`
    estimatedDeliveryDays?: boolean | `@${string}`
    estimatedMonthlyUnitsSold?: boolean | `@${string}`
    estimatedWeeklyUnitsSold?: boolean | `@${string}`
    exactListingTitle?: boolean | `@${string}`
    exactRetailPrice?: boolean | `@${string}`
    fullReviewScrapeComplete?: boolean | `@${string}`
    id?: boolean | `@${string}`
    inventory?: boolean | `@${string}`
    iosAppProviderId?: boolean | `@${string}`
    isAplusPage?: boolean | `@${string}`
    isPrime?: boolean | `@${string}`
    isRecommendationsHidden?: boolean | `@${string}`
    itemClassification?: boolean | `@${string}`
    lastAutoFilledAt?: boolean | `@${string}`
    lastKnownBuyBoxPrice?: boolean | `@${string}`
    lastKnownBuyBoxPriceCents?: boolean | `@${string}`
    listingBrand?: boolean | `@${string}`
    listingCategory?: boolean | `@${string}`
    listingDescription?: boolean | `@${string}`
    listingFulfillmentType?: boolean | `@${string}`
    listingGuidance?: boolean | `@${string}`
    listingLink?: boolean | `@${string}`
    mccValue?: boolean | `@${string}`
    minimumOrderQuantity?: boolean | `@${string}`
    name?: boolean | `@${string}`
    nameAudioKey?: boolean | `@${string}`
    nonSponsoredListingKey?: boolean | `@${string}`
    parentSku?: ResolverInputTypes['Sku']
    parentSkuId?: boolean | `@${string}`
    priceCents?: boolean | `@${string}`
    product?: boolean | `@${string}`
    productImageKey?: boolean | `@${string}`
    productPostingGuidance?: boolean | `@${string}`
    productPostingGuidanceDocument?: boolean | `@${string}`
    productSearchImageKey?: boolean | `@${string}`
    releaseDate?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    sponsoredListingKey?: boolean | `@${string}`
    storeName?: boolean | `@${string}`
    targetReviewRate?: ResolverInputTypes['Rate']
    targetReviewRateInvalid?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SkuAttribute']: AliasType<{
    name?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SkuDimensions']: AliasType<{
    name?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SkuInformation']: AliasType<{
    brand?: boolean | `@${string}`
    childSkus?: boolean | `@${string}`
    id?: boolean | `@${string}`
    image?: boolean | `@${string}`
    link?: boolean | `@${string}`
    name?: boolean | `@${string}`
    parentSku?: boolean | `@${string}`
    price?: boolean | `@${string}`
    regularPrice?: boolean | `@${string}`
    shippingCost?: boolean | `@${string}`
    store?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SkuSnapshot']: AliasType<{
    creationDateTime?: boolean | `@${string}`
    inventory?: boolean | `@${string}`
    marketplaceSku?: boolean | `@${string}`
    price?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    snapshotDate?: boolean | `@${string}`
    updatedDateTime?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialMediaFeedback']: AliasType<{
    comments?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialMediaPost']: AliasType<{
    caption?: boolean | `@${string}`
    contentType?: boolean | `@${string}`
    groupTypes?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isCopyrightedContent?: boolean | `@${string}`
    link?: boolean | `@${string}`
    mediaUrl?: boolean | `@${string}`
    postedAt?: boolean | `@${string}`
    views?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialMetric']: AliasType<{
    name?: boolean | `@${string}`
    value?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialUnlinkResult']: AliasType<{
    userId?: boolean | `@${string}`
    verified?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialVerificationCode']: AliasType<{
    code?: boolean | `@${string}`
    id?: boolean | `@${string}`
    ttl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['StagingApprovalConfiguration']: AliasType<{
    stagingApprovalAssignee?: boolean | `@${string}`
    stagingApprovalType?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['StatusHistory']: AliasType<{
    campaignStatus?: boolean | `@${string}`
    createdById?: boolean | `@${string}`
    source?: boolean | `@${string}`
    time?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['StatusTierBonusConfig']: AliasType<{
    baseBonus?: boolean | `@${string}`
    diamondBonus?: boolean | `@${string}`
    goldBonus?: boolean | `@${string}`
    platinumBonus?: boolean | `@${string}`
    silverBonus?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['StatusTierEntry']: AliasType<{
    statusTier?: boolean | `@${string}`
    statusTierAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Subscription']: AliasType<{
    onCreateAutoBuyDistribution?: ResolverInputTypes['AutoBuyDistribution']
    onCreateAutoBuyFlowConfiguration?: ResolverInputTypes['AutoBuyFlowConfiguration']
    onCreateAutoBuyFlowReleaseSchedule?: ResolverInputTypes['AutoBuyFlowReleaseSchedule']
    onCreateAutoBuyRelease?: ResolverInputTypes['AutoBuyRelease']
    onCreateBrand?: ResolverInputTypes['Brand']
    onCreateBrandApi?: ResolverInputTypes['BrandApi']
    onCreateCampaign?: ResolverInputTypes['Campaign']
    onCreateCampaignClicks?: ResolverInputTypes['CampaignClicks']
    onCreateCampaignInvite?: [{ userId?: string | undefined | null }, ResolverInputTypes['CampaignInvite']]
    onCreateCampaignSourcingProspect?: ResolverInputTypes['CampaignSourcingProspect']
    onCreateChatConversationV2?: [{ userId?: string | undefined | null }, ResolverInputTypes['ChatConversationV2']]
    onCreateCommunicationNotification?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['CommunicationNotification']
    ]
    onCreateCompany?: ResolverInputTypes['Company']
    onCreateConnectedAccount?: [{ userId?: string | undefined | null }, ResolverInputTypes['ConnectedAccount']]
    onCreateConversation?: [{ userId?: string | undefined | null }, ResolverInputTypes['Conversation']]
    onCreateConversationMessage?: [{ userId?: string | undefined | null }, ResolverInputTypes['ConversationMessage']]
    onCreateEtailerSkuMetrics?: ResolverInputTypes['EtailerSkuMetrics']
    onCreateFormSequenceConfiguration?: ResolverInputTypes['FormSequenceConfiguration']
    onCreateInstagramStoryScreen?: ResolverInputTypes['InstagramStoryScreen']
    onCreateMessage?: [{ userId?: string | undefined | null }, ResolverInputTypes['Message']]
    onCreateNote?: [{ userId?: string | undefined | null }, ResolverInputTypes['Note']]
    onCreateNotificationConfiguration?: ResolverInputTypes['NotificationConfiguration']
    onCreateProduct?: ResolverInputTypes['Product']
    onCreateProductBonusConfigSnapshot?: ResolverInputTypes['ProductBonusConfigSnapshot']
    onCreateProductKeywords?: ResolverInputTypes['ProductKeywords']
    onCreateProductRatingSnapshot?: ResolverInputTypes['ProductRatingSnapshot']
    onCreatePurchase?: ResolverInputTypes['Purchase']
    onCreateReferral?: [{ referrerUserId?: string | undefined | null }, ResolverInputTypes['Referral']]
    onCreateReview?: ResolverInputTypes['Review']
    onCreateSku?: ResolverInputTypes['Sku']
    onCreateSkuSnapshot?: ResolverInputTypes['SkuSnapshot']
    onCreateUser?: [{ id?: string | undefined | null }, ResolverInputTypes['User']]
    onCreateUserBonusPayout?: [{ userId?: string | undefined | null }, ResolverInputTypes['UserBonusPayout']]
    onCreateUserCampaign?: [{ userId?: string | undefined | null }, ResolverInputTypes['UserCampaign']]
    onCreateUserCampaignChangelog?: ResolverInputTypes['UserCampaignChangelog']
    onCreateUserCampaignSnapshot?: ResolverInputTypes['UserCampaignSnapshot']
    onCreateUserCampaignSocialContent?: ResolverInputTypes['UserCampaignSocialContent']
    onCreateUserCampaignSocialContentDraftHistory?: ResolverInputTypes['UserCampaignSocialContentDraftHistory']
    onCreateUserCampaignSocialContentReview?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['UserCampaignSocialContentReview']
    ]
    onCreateUserDeviceSnapshot?: ResolverInputTypes['UserDeviceSnapshot']
    onCreateUserFollower?: [{ followerUserId?: string | undefined | null }, ResolverInputTypes['UserFollower']]
    onCreateUserLink?: ResolverInputTypes['UserLink']
    onCreateUserNotificationPreference?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['UserNotificationPreference']
    ]
    onCreateUserPayoutRequest?: [{ userId?: string | undefined | null }, ResolverInputTypes['UserPayoutRequest']]
    onCreateUserStagingApproval?: ResolverInputTypes['UserStagingApproval']
    onCreateVideoContentScan?: ResolverInputTypes['VideoContentScan']
    onDeleteAutoBuyDistribution?: ResolverInputTypes['AutoBuyDistribution']
    onDeleteAutoBuyFlowConfiguration?: ResolverInputTypes['AutoBuyFlowConfiguration']
    onDeleteAutoBuyFlowReleaseSchedule?: ResolverInputTypes['AutoBuyFlowReleaseSchedule']
    onDeleteAutoBuyRelease?: ResolverInputTypes['AutoBuyRelease']
    onDeleteBrand?: ResolverInputTypes['Brand']
    onDeleteBrandApi?: ResolverInputTypes['BrandApi']
    onDeleteCampaign?: ResolverInputTypes['Campaign']
    onDeleteCampaignClicks?: ResolverInputTypes['CampaignClicks']
    onDeleteCampaignInvite?: [{ userId?: string | undefined | null }, ResolverInputTypes['CampaignInvite']]
    onDeleteCampaignSourcingProspect?: ResolverInputTypes['CampaignSourcingProspect']
    onDeleteChatConversationV2?: [{ userId?: string | undefined | null }, ResolverInputTypes['ChatConversationV2']]
    onDeleteCommunicationNotification?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['CommunicationNotification']
    ]
    onDeleteCompany?: ResolverInputTypes['Company']
    onDeleteConnectedAccount?: [{ userId?: string | undefined | null }, ResolverInputTypes['ConnectedAccount']]
    onDeleteConversation?: [{ userId?: string | undefined | null }, ResolverInputTypes['Conversation']]
    onDeleteConversationMessage?: [{ userId?: string | undefined | null }, ResolverInputTypes['ConversationMessage']]
    onDeleteEtailerSkuMetrics?: ResolverInputTypes['EtailerSkuMetrics']
    onDeleteFormSequenceConfiguration?: ResolverInputTypes['FormSequenceConfiguration']
    onDeleteInstagramStoryScreen?: ResolverInputTypes['InstagramStoryScreen']
    onDeleteMessage?: [{ userId?: string | undefined | null }, ResolverInputTypes['Message']]
    onDeleteNote?: [{ userId?: string | undefined | null }, ResolverInputTypes['Note']]
    onDeleteNotificationConfiguration?: ResolverInputTypes['NotificationConfiguration']
    onDeleteProduct?: ResolverInputTypes['Product']
    onDeleteProductBonusConfigSnapshot?: ResolverInputTypes['ProductBonusConfigSnapshot']
    onDeleteProductKeywords?: ResolverInputTypes['ProductKeywords']
    onDeleteProductRatingSnapshot?: ResolverInputTypes['ProductRatingSnapshot']
    onDeletePurchase?: ResolverInputTypes['Purchase']
    onDeleteReferral?: [{ referrerUserId?: string | undefined | null }, ResolverInputTypes['Referral']]
    onDeleteReview?: ResolverInputTypes['Review']
    onDeleteSku?: ResolverInputTypes['Sku']
    onDeleteSkuSnapshot?: ResolverInputTypes['SkuSnapshot']
    onDeleteUser?: [{ id?: string | undefined | null }, ResolverInputTypes['User']]
    onDeleteUserBonusPayout?: [{ userId?: string | undefined | null }, ResolverInputTypes['UserBonusPayout']]
    onDeleteUserCampaign?: [{ userId?: string | undefined | null }, ResolverInputTypes['UserCampaign']]
    onDeleteUserCampaignChangelog?: ResolverInputTypes['UserCampaignChangelog']
    onDeleteUserCampaignSnapshot?: ResolverInputTypes['UserCampaignSnapshot']
    onDeleteUserCampaignSocialContent?: ResolverInputTypes['UserCampaignSocialContent']
    onDeleteUserCampaignSocialContentDraftHistory?: ResolverInputTypes['UserCampaignSocialContentDraftHistory']
    onDeleteUserCampaignSocialContentReview?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['UserCampaignSocialContentReview']
    ]
    onDeleteUserDeviceSnapshot?: ResolverInputTypes['UserDeviceSnapshot']
    onDeleteUserFollower?: [{ followerUserId?: string | undefined | null }, ResolverInputTypes['UserFollower']]
    onDeleteUserLink?: ResolverInputTypes['UserLink']
    onDeleteUserNotificationPreference?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['UserNotificationPreference']
    ]
    onDeleteUserPayoutRequest?: [{ userId?: string | undefined | null }, ResolverInputTypes['UserPayoutRequest']]
    onDeleteUserStagingApproval?: ResolverInputTypes['UserStagingApproval']
    onDeleteVideoContentScan?: ResolverInputTypes['VideoContentScan']
    onUpdateAutoBuyDistribution?: ResolverInputTypes['AutoBuyDistribution']
    onUpdateAutoBuyFlowConfiguration?: ResolverInputTypes['AutoBuyFlowConfiguration']
    onUpdateAutoBuyFlowReleaseSchedule?: ResolverInputTypes['AutoBuyFlowReleaseSchedule']
    onUpdateAutoBuyRelease?: ResolverInputTypes['AutoBuyRelease']
    onUpdateBrand?: ResolverInputTypes['Brand']
    onUpdateBrandApi?: ResolverInputTypes['BrandApi']
    onUpdateCampaign?: ResolverInputTypes['Campaign']
    onUpdateCampaignClicks?: ResolverInputTypes['CampaignClicks']
    onUpdateCampaignInvite?: [{ userId?: string | undefined | null }, ResolverInputTypes['CampaignInvite']]
    onUpdateCampaignSourcingProspect?: ResolverInputTypes['CampaignSourcingProspect']
    onUpdateChatConversationV2?: [{ userId?: string | undefined | null }, ResolverInputTypes['ChatConversationV2']]
    onUpdateCommunicationNotification?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['CommunicationNotification']
    ]
    onUpdateCompany?: ResolverInputTypes['Company']
    onUpdateConnectedAccount?: [{ userId?: string | undefined | null }, ResolverInputTypes['ConnectedAccount']]
    onUpdateConversation?: [{ userId?: string | undefined | null }, ResolverInputTypes['Conversation']]
    onUpdateConversationMessage?: [{ userId?: string | undefined | null }, ResolverInputTypes['ConversationMessage']]
    onUpdateEtailerSkuMetrics?: ResolverInputTypes['EtailerSkuMetrics']
    onUpdateFormSequenceConfiguration?: ResolverInputTypes['FormSequenceConfiguration']
    onUpdateInstagramStoryScreen?: ResolverInputTypes['InstagramStoryScreen']
    onUpdateMessage?: [{ userId?: string | undefined | null }, ResolverInputTypes['Message']]
    onUpdateNote?: [{ userId?: string | undefined | null }, ResolverInputTypes['Note']]
    onUpdateNotificationConfiguration?: ResolverInputTypes['NotificationConfiguration']
    onUpdateProduct?: ResolverInputTypes['Product']
    onUpdateProductBonusConfigSnapshot?: ResolverInputTypes['ProductBonusConfigSnapshot']
    onUpdateProductKeywords?: ResolverInputTypes['ProductKeywords']
    onUpdateProductRatingSnapshot?: ResolverInputTypes['ProductRatingSnapshot']
    onUpdatePurchase?: ResolverInputTypes['Purchase']
    onUpdateReferral?: [{ referrerUserId?: string | undefined | null }, ResolverInputTypes['Referral']]
    onUpdateReview?: ResolverInputTypes['Review']
    onUpdateSku?: ResolverInputTypes['Sku']
    onUpdateSkuSnapshot?: ResolverInputTypes['SkuSnapshot']
    onUpdateUser?: [{ id?: string | undefined | null }, ResolverInputTypes['User']]
    onUpdateUserBonusPayout?: [{ userId?: string | undefined | null }, ResolverInputTypes['UserBonusPayout']]
    onUpdateUserCampaign?: [{ userId?: string | undefined | null }, ResolverInputTypes['UserCampaign']]
    onUpdateUserCampaignChangelog?: ResolverInputTypes['UserCampaignChangelog']
    onUpdateUserCampaignSnapshot?: ResolverInputTypes['UserCampaignSnapshot']
    onUpdateUserCampaignSocialContent?: ResolverInputTypes['UserCampaignSocialContent']
    onUpdateUserCampaignSocialContentDraftHistory?: ResolverInputTypes['UserCampaignSocialContentDraftHistory']
    onUpdateUserCampaignSocialContentReview?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['UserCampaignSocialContentReview']
    ]
    onUpdateUserDeviceSnapshot?: ResolverInputTypes['UserDeviceSnapshot']
    onUpdateUserFollower?: [{ followerUserId?: string | undefined | null }, ResolverInputTypes['UserFollower']]
    onUpdateUserLink?: ResolverInputTypes['UserLink']
    onUpdateUserNotificationPreference?: [
      { userId?: string | undefined | null },
      ResolverInputTypes['UserNotificationPreference']
    ]
    onUpdateUserPayoutRequest?: [{ userId?: string | undefined | null }, ResolverInputTypes['UserPayoutRequest']]
    onUpdateUserStagingApproval?: ResolverInputTypes['UserStagingApproval']
    onUpdateVideoContentScan?: ResolverInputTypes['VideoContentScan']
    __typename?: boolean | `@${string}`
  }>
  ['UpdateUserPhoneResult']: AliasType<{
    phoneNumber?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['User']: AliasType<{
    active?: boolean | `@${string}`
    addressComponents?: boolean | `@${string}`
    amznLastChecked?: boolean | `@${string}`
    amznLink?: boolean | `@${string}`
    amznPrime?: boolean | `@${string}`
    amznProfileId?: boolean | `@${string}`
    amznReviewerId?: boolean | `@${string}`
    amznVerified?: boolean | `@${string}`
    attributedReviewCount?: boolean | `@${string}`
    blogWebsite?: boolean | `@${string}`
    brandContentRatingAvg?: boolean | `@${string}`
    campaignAcceptanceRate?: boolean | `@${string}`
    campaignApprovalRate?: boolean | `@${string}`
    campaignCompletionRate?: boolean | `@${string}`
    campaignReviewRate?: boolean | `@${string}`
    cardProfileUpdates?: boolean | `@${string}`
    cardholderId?: boolean | `@${string}`
    cardholderRegion?: boolean | `@${string}`
    characteristics?: ResolverInputTypes['UserCharacteristic']
    characteristicsUpdatedAt?: boolean | `@${string}`
    claimedReviewCount?: boolean | `@${string}`
    collisionSkuSet?: boolean | `@${string}`
    collisionSkuSetCalculatedAt?: boolean | `@${string}`
    completedLifestyleCampaignCount?: boolean | `@${string}`
    completedLiftCampaignCount?: boolean | `@${string}`
    completedSocialCampaignCount?: boolean | `@${string}`
    connectedAccounts?: [
      {
        accountId?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelConnectedAccountFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelConnectedAccountConnection']
    ]
    contentRating?: ResolverInputTypes['UserContentRating']
    contentRatingCompletedAt?: boolean | `@${string}`
    contentRatingCompletionTimeMillis?: boolean | `@${string}`
    contentRatingInstagramCompletionTimeMillis?: boolean | `@${string}`
    contentRatingInstagramSponsorshipContent?: ResolverInputTypes['ContentRatingSponsorshipContent']
    contentRatingQualityScore?: boolean | `@${string}`
    contentRatingReviewStatus?: boolean | `@${string}`
    contentRatingSponsorshipContent?: ResolverInputTypes['ContentRatingSponsorshipContent']
    contentRatingSubmittedBy?: boolean | `@${string}`
    contentRatingTiktokCompletionTimeMillis?: boolean | `@${string}`
    contentRatingTiktokSponsorshipContent?: ResolverInputTypes['ContentRatingSponsorshipContent']
    contentTypes?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date_of_birth?: boolean | `@${string}`
    deactivationDate?: boolean | `@${string}`
    deactivationReason?: boolean | `@${string}`
    declineToReviewCount?: boolean | `@${string}`
    deranked?: boolean | `@${string}`
    email?: boolean | `@${string}`
    emailOptOut?: boolean | `@${string}`
    facebookAccessToken?: boolean | `@${string}`
    facebookAccessTokenExpiry?: boolean | `@${string}`
    facebookPageId?: boolean | `@${string}`
    facebookPageIsSubscribed?: boolean | `@${string}`
    facebookPermissionScopes?: boolean | `@${string}`
    facebookUserId?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    followedUsers?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserFollowerFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserFollowerConnection']
    ]
    followers?: [
      {
        filter?: ResolverInputTypes['ModelUserFollowerFilterInput'] | undefined | null
        followerUserId?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserFollowerConnection']
    ]
    followsWesponsored?: boolean | `@${string}`
    fraudReason?: boolean | `@${string}`
    fraudReviewAt?: boolean | `@${string}`
    fraudReviewStatus?: boolean | `@${string}`
    gender?: boolean | `@${string}`
    hasTrueEngagement?: boolean | `@${string}`
    id?: boolean | `@${string}`
    idfaId?: boolean | `@${string}`
    incompleteCampaignCount?: boolean | `@${string}`
    instagramBio?: boolean | `@${string}`
    instagramBusinessCategoryName?: boolean | `@${string}`
    instagramCategoryName?: boolean | `@${string}`
    instagramChecked?: boolean | `@${string}`
    instagramContentTypeRating?: boolean | `@${string}`
    instagramExternalLink?: boolean | `@${string}`
    instagramFollowerCountryDistribution?: ResolverInputTypes['InstagramCountryDistributionItem']
    instagramFollowerGenderAgeDistribution?: ResolverInputTypes['InstagramGenderAgeDistribution']
    instagramFollowersInt?: boolean | `@${string}`
    instagramHandle?: boolean | `@${string}`
    instagramIsBusiness?: boolean | `@${string}`
    instagramIsPrivate?: boolean | `@${string}`
    instagramIsRecentlyJoined?: boolean | `@${string}`
    instagramIsVerified?: boolean | `@${string}`
    instagramLastChecked?: boolean | `@${string}`
    instagramMedianShares?: boolean | `@${string}`
    instagramMetricsRetrievedAt?: boolean | `@${string}`
    instagramName?: boolean | `@${string}`
    instagramPostsInt?: boolean | `@${string}`
    instagramPriority?: boolean | `@${string}`
    instagramProfilePic?: boolean | `@${string}`
    instagramTrailingComments?: boolean | `@${string}`
    instagramTrailingDateOfOldestPost?: boolean | `@${string}`
    instagramTrailingLikes?: boolean | `@${string}`
    instagramTrailingNumberOfPosts?: boolean | `@${string}`
    instagramTrailingPostEngagementAvg?: boolean | `@${string}`
    instagramTrailingPostEngagementRateAvg?: boolean | `@${string}`
    instagramTrailingPostEngagementRateImpressionsAvg?: boolean | `@${string}`
    instagramTrailingPostEngagementRateMedian?: boolean | `@${string}`
    instagramTrailingPostImpressionsAvg?: boolean | `@${string}`
    instagramTrailingPostImpressionsMedian?: boolean | `@${string}`
    instagramTrailingPostSponsoredPercentage?: boolean | `@${string}`
    instagramTrailingPostViewsMedian?: boolean | `@${string}`
    instagramUserId?: boolean | `@${string}`
    instagramVerificationMethod?: boolean | `@${string}`
    internalGender?: boolean | `@${string}`
    internalNotes?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelNoteConnection']
    ]
    isBrandSafe?: boolean | `@${string}`
    isBrandSafeReason?: boolean | `@${string}`
    isBrandSafeUpdatedAt?: boolean | `@${string}`
    lastActiveAt?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    manualScoringLockedUntil?: boolean | `@${string}`
    moreinfo?: boolean | `@${string}`
    notificationPreferences?: [
      {
        category?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserNotificationPreferenceConnection']
    ]
    numEvenDollarPurchases?: boolean | `@${string}`
    numPurchases?: boolean | `@${string}`
    paypalEmail?: boolean | `@${string}`
    phoneNumber?: boolean | `@${string}`
    profilePicture?: boolean | `@${string}`
    pushAddresses?: ResolverInputTypes['PushAddress']
    pushOptOut?: boolean | `@${string}`
    referralCode?: boolean | `@${string}`
    referralCount?: boolean | `@${string}`
    referrer?: boolean | `@${string}`
    signupCountry?: boolean | `@${string}`
    smsOptOut?: boolean | `@${string}`
    socialQuality?: boolean | `@${string}`
    specialAttributes?: ResolverInputTypes['UserAttributes']
    statusTier?: boolean | `@${string}`
    statusTierHistory?: ResolverInputTypes['StatusTierEntry']
    statusTierLastUpdatedAt?: boolean | `@${string}`
    supportPriorityAcknowledgedAt?: boolean | `@${string}`
    supportPriorityFlag?: boolean | `@${string}`
    supportPriorityFlagSetAt?: boolean | `@${string}`
    targetAccount?: boolean | `@${string}`
    tiktokAccessToken?: boolean | `@${string}`
    tiktokAccessTokenExpiry?: boolean | `@${string}`
    tiktokAccountId?: boolean | `@${string}`
    tiktokAveragePostEngagementRate?: boolean | `@${string}`
    tiktokAveragePostEngagementRateViews?: boolean | `@${string}`
    tiktokBio?: boolean | `@${string}`
    tiktokContentTypeRating?: boolean | `@${string}`
    tiktokFollowersInt?: boolean | `@${string}`
    tiktokHandle?: boolean | `@${string}`
    tiktokMedianComments?: boolean | `@${string}`
    tiktokMedianLikes?: boolean | `@${string}`
    tiktokMedianPostEngagement?: boolean | `@${string}`
    tiktokMedianPostEngagementRate?: boolean | `@${string}`
    tiktokMedianPostEngagementRateViews?: boolean | `@${string}`
    tiktokMedianShares?: boolean | `@${string}`
    tiktokMedianViews?: boolean | `@${string}`
    tiktokMetricsRetrievedAt?: boolean | `@${string}`
    tiktokName?: boolean | `@${string}`
    tiktokPermissionScopes?: boolean | `@${string}`
    tiktokPriority?: boolean | `@${string}`
    tiktokProfilePic?: boolean | `@${string}`
    tiktokRefreshToken?: boolean | `@${string}`
    tiktokRefreshTokenExpiry?: boolean | `@${string}`
    tiktokSponsoredPostPercentage?: boolean | `@${string}`
    tiktokVerificationMethod?: boolean | `@${string}`
    tiktokVerified?: boolean | `@${string}`
    timeZone?: boolean | `@${string}`
    trackingStatus?: boolean | `@${string}`
    twitchAccount?: boolean | `@${string}`
    type?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaigns?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignConnection']
    ]
    utmReferral?: ResolverInputTypes['UtmReferral']
    videoContentScans?: [
      {
        filter?: ResolverInputTypes['ModelVideoContentScanFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelVideoContentScanConnection']
    ]
    wmartAccount?: boolean | `@${string}`
    youtubeAccessToken?: boolean | `@${string}`
    youtubeAccessTokenExpiry?: boolean | `@${string}`
    youtubeAccount?: boolean | `@${string}`
    youtubeAveragePostEngagementRate?: boolean | `@${string}`
    youtubeChannelDescription?: boolean | `@${string}`
    youtubeChannelFollowersInt?: boolean | `@${string}`
    youtubeChannelHandle?: boolean | `@${string}`
    youtubeChannelId?: boolean | `@${string}`
    youtubeChannelPicture?: boolean | `@${string}`
    youtubeChannelTitle?: boolean | `@${string}`
    youtubeIdToken?: boolean | `@${string}`
    youtubeMedianViews?: boolean | `@${string}`
    youtubeMetricsRetrievedAt?: boolean | `@${string}`
    youtubePermissionScopes?: boolean | `@${string}`
    youtubeRefreshToken?: boolean | `@${string}`
    youtubeVerificationMethod?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserAttributes']: AliasType<{
    isMother?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserBonusPayout']: AliasType<{
    adminPaymentApproved?: boolean | `@${string}`
    bonusType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    failureReason?: boolean | `@${string}`
    id?: boolean | `@${string}`
    manuallyCreatedBy?: boolean | `@${string}`
    payoutAmount?: boolean | `@${string}`
    payoutAmountCents?: boolean | `@${string}`
    payoutBatchId?: boolean | `@${string}`
    payoutDate?: boolean | `@${string}`
    payoutLocale?: boolean | `@${string}`
    payoutState?: boolean | `@${string}`
    quantity?: boolean | `@${string}`
    referenceId?: boolean | `@${string}`
    releaseAt?: boolean | `@${string}`
    revocationType?: boolean | `@${string}`
    revokedAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaign']: AliasType<{
    accountScreenShot?: boolean | `@${string}`
    amznReviewLink?: boolean | `@${string}`
    amznReviewSubmissionStatus?: boolean | `@${string}`
    applicationRejectedByUser?: ResolverInputTypes['User']
    applicationRejectedByUserId?: boolean | `@${string}`
    approvalOutcome?: boolean | `@${string}`
    approvalStatus?: boolean | `@${string}`
    archivedFeedback?: boolean | `@${string}`
    archivedReason?: boolean | `@${string}`
    autoBuyApprovalDate?: boolean | `@${string}`
    autoBuyRevokeDate?: boolean | `@${string}`
    bestBuyReviewScreenShot?: boolean | `@${string}`
    bonusConfig?: ResolverInputTypes['UserCampaignBonusConfig']
    campaign?: ResolverInputTypes['Campaign']
    campaignAcceptanceStatus?: boolean | `@${string}`
    campaignApplication?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    campaignStatus?: boolean | `@${string}`
    campaignStatusOnArchive?: boolean | `@${string}`
    cardId?: boolean | `@${string}`
    cardRegion?: boolean | `@${string}`
    changelog?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignChangelogConnection']
    ]
    closeoutSurvey?: boolean | `@${string}`
    coarseCampaignStatus?: boolean | `@${string}`
    communications?: [
      {
        filter?: ResolverInputTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelCommunicationNotificationConnection']
    ]
    contentApprovalLeadDays?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    discoverySurvey?: boolean | `@${string}`
    id?: boolean | `@${string}`
    influencerApprovalInviteFeedback?: boolean | `@${string}`
    influencerApprovalRejectFeedback?: boolean | `@${string}`
    internalNotes?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelNoteFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelNoteConnection']
    ]
    invoiceKey?: boolean | `@${string}`
    isAutoApproved?: boolean | `@${string}`
    keyword?: ResolverInputTypes['ProductKeywords']
    keywordAtPurchase?: boolean | `@${string}`
    keywordId?: boolean | `@${string}`
    keywordsV2Hash?: boolean | `@${string}`
    listingKey?: boolean | `@${string}`
    localeV1?: boolean | `@${string}`
    manualPurchase?: boolean | `@${string}`
    messages?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelMessageFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelMessageConnection']
    ]
    moderatorStagingApprovalAt?: boolean | `@${string}`
    moderatorStagingApprovalBy?: boolean | `@${string}`
    moderatorStagingApprovalOutcome?: boolean | `@${string}`
    orderId?: boolean | `@${string}`
    orderIdInvalidReason?: boolean | `@${string}`
    orderIdSubmissionDate?: boolean | `@${string}`
    orderIdValidationDate?: boolean | `@${string}`
    orderIdValidationStatus?: boolean | `@${string}`
    orderStatus?: boolean | `@${string}`
    payForClickLink?: ResolverInputTypes['UserLink']
    payForClickLinkId?: boolean | `@${string}`
    paypalEmailAtSubmission?: boolean | `@${string}`
    postingDeadline?: boolean | `@${string}`
    postingDeadlineExtended?: boolean | `@${string}`
    previousCardIds?: boolean | `@${string}`
    priority?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    productCriticalFeedback?: boolean | `@${string}`
    productId?: boolean | `@${string}`
    productPositiveFeedback?: boolean | `@${string}`
    prospect?: ResolverInputTypes['CampaignSourcingProspect']
    purchases?: [
      {
        filter?: ResolverInputTypes['ModelPurchaseFilterInput'] | undefined | null
        id?: ResolverInputTypes['ModelIDKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelPurchaseConnection']
    ]
    rankPriority?: boolean | `@${string}`
    reviewScreenshot?: boolean | `@${string}`
    reviewScreenshotVerificationResult?: boolean | `@${string}`
    schedulingReleaseAt?: boolean | `@${string}`
    searchPhraseKey?: boolean | `@${string}`
    selectedFlow?: boolean | `@${string}`
    selfieVideoApprovalDate?: boolean | `@${string}`
    selfieVideoApprovalNotes?: boolean | `@${string}`
    selfieVideoApprovalStatus?: boolean | `@${string}`
    selfieVideoKey?: boolean | `@${string}`
    selfieVideoRejectionReason?: boolean | `@${string}`
    selfieVideoSubmissionRequired?: boolean | `@${string}`
    selfieVideoSubmittedAt?: boolean | `@${string}`
    sequenceFormSubmissionValues?: ResolverInputTypes['SequenceFormSubmissionValues']
    shippingAddress?: boolean | `@${string}`
    showEarlyReviewSurvey?: boolean | `@${string}`
    socialContent?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentConnection']
    ]
    statusHistory?: boolean | `@${string}`
    totalBonuses?: [
      {
        filter?: ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserBonusPayoutConnection']
    ]
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userId?: boolean | `@${string}`
    userStagingApprovals?: [
      {
        filter?: ResolverInputTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserStagingApprovalConnection']
    ]
    walmartReviewScreenShot?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignBonusConfig']: AliasType<{
    closeoutSurveyBonus?: boolean | `@${string}`
    contentBonuses?: ResolverInputTypes['ProductSocialContent']
    discoveryBonus?: boolean | `@${string}`
    expectedBonus?: boolean | `@${string}`
    hasCustomBonusConfig?: boolean | `@${string}`
    payForImpressionsBonus?: ResolverInputTypes['PayForImpressionsBonusConfig']
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignChangelog']: AliasType<{
    createdAt?: boolean | `@${string}`
    createdByUser?: ResolverInputTypes['User']
    createdByUserId?: boolean | `@${string}`
    entryType?: boolean | `@${string}`
    extraData?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaign?: ResolverInputTypes['UserCampaign']
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSnapshot']: AliasType<{
    createdAt?: boolean | `@${string}`
    snapshot?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userCampaignVersion?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContent']: AliasType<{
    approvalFeedback?: boolean | `@${string}`
    approvalStatus?: boolean | `@${string}`
    approvedAt?: boolean | `@${string}`
    approverPWUserId?: boolean | `@${string}`
    approverUserId?: boolean | `@${string}`
    bonusEarned?: boolean | `@${string}`
    bonusOverride?: boolean | `@${string}`
    caption?: boolean | `@${string}`
    channel?: boolean | `@${string}`
    clientFeedback?: ResolverInputTypes['SocialMediaFeedback']
    complianceReview?: ResolverInputTypes['ContentComplianceReviewCriteria']
    complianceReviewStatus?: boolean | `@${string}`
    complianceReviewUserId?: boolean | `@${string}`
    complianceReviewedAt?: boolean | `@${string}`
    contentType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    draftEnteredAdminApprovalAt?: boolean | `@${string}`
    draftEnteredBrandApprovalAt?: boolean | `@${string}`
    draftFeedback?: boolean | `@${string}`
    draftHistory?: boolean | `@${string}`
    draftHistoryV2?: [
      {
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        snapshotDate?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryConnection']
    ]
    draftMedia?: boolean | `@${string}`
    draftPreApprovedText?: boolean | `@${string}`
    draftQualityScore?: boolean | `@${string}`
    draftReview?: ResolverInputTypes['ContentComplianceReviewCriteria']
    draftStatus?: boolean | `@${string}`
    draftSubmittedAt?: boolean | `@${string}`
    draftText?: boolean | `@${string}`
    earlySubmissionBonusEarnedCents?: boolean | `@${string}`
    experimentId?: boolean | `@${string}`
    externalUrl?: boolean | `@${string}`
    groupId?: boolean | `@${string}`
    hashtags?: boolean | `@${string}`
    id?: boolean | `@${string}`
    instance?: boolean | `@${string}`
    internalFeedback?: ResolverInputTypes['SocialMediaFeedback']
    isFavorite?: boolean | `@${string}`
    isHidden?: boolean | `@${string}`
    isPurchased?: boolean | `@${string}`
    isUnavailable?: boolean | `@${string}`
    manualUploadType?: boolean | `@${string}`
    mediaId?: boolean | `@${string}`
    mediaUrls?: boolean | `@${string}`
    metrics?: ResolverInputTypes['SocialMetric']
    paymentId?: boolean | `@${string}`
    postedAt?: boolean | `@${string}`
    qualityScore?: boolean | `@${string}`
    rejectionReason?: boolean | `@${string}`
    requestedSocialScript?: boolean | `@${string}`
    requirementLevel?: boolean | `@${string}`
    reviews?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        filter?: ResolverInputTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
      },
      ResolverInputTypes['ModelUserCampaignSocialContentReviewConnection']
    ]
    scrapeStatus?: boolean | `@${string}`
    unavailableAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    usedRequestedSocialScript?: boolean | `@${string}`
    userCampaign?: ResolverInputTypes['UserCampaign']
    userCampaignId?: boolean | `@${string}`
    videoEndTime?: boolean | `@${string}`
    videoStartTime?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContentDraftHistory']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    snapshot?: boolean | `@${string}`
    snapshotDate?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignSocialContentId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContentReview']: AliasType<{
    createdAt?: boolean | `@${string}`
    reviewFeedback?: ResolverInputTypes['UserCampaignSocialContentReviewFeedback']
    reviewOutcome?: boolean | `@${string}`
    reviewStatus?: boolean | `@${string}`
    reviewType?: boolean | `@${string}`
    reviewedAt?: boolean | `@${string}`
    reviewer?: ResolverInputTypes['User']
    reviewerAssigneeId?: boolean | `@${string}`
    reviewerFirstName?: boolean | `@${string}`
    reviewerId?: boolean | `@${string}`
    reviewerLastName?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignSocialContent?: ResolverInputTypes['UserCampaignSocialContent']
    userCampaignSocialContentId?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContentReviewFeedback']: AliasType<{
    atTime?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    createdByFirstName?: boolean | `@${string}`
    createdByLastName?: boolean | `@${string}`
    createdByUserId?: boolean | `@${string}`
    forAssetAtIndex?: boolean | `@${string}`
    id?: boolean | `@${string}`
    message?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    visibility?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCharacteristic']: AliasType<{
    characteristic?: boolean | `@${string}`
    reason?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserContentRating']: AliasType<{
    instagramContentQualityRating?: boolean | `@${string}`
    instagramContentTypeRating?: boolean | `@${string}`
    instagramCreatorContentScore?: boolean | `@${string}`
    instagramCreatorContentScoreRaw?: ResolverInputTypes['CreatorContentRatingRawValue']
    instagramCreatorSponsorshipContentScore?: boolean | `@${string}`
    instagramSubmittedBy?: boolean | `@${string}`
    submittedBy?: boolean | `@${string}`
    tiktokContentQualityRating?: boolean | `@${string}`
    tiktokContentTypeRating?: boolean | `@${string}`
    tiktokCreatorContentScore?: boolean | `@${string}`
    tiktokCreatorContentScoreRaw?: ResolverInputTypes['CreatorContentRatingRawValue']
    tiktokCreatorSponsorshipContentScore?: boolean | `@${string}`
    tiktokSubmittedBy?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserDeviceSnapshot']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    idfv?: boolean | `@${string}`
    ipAddress?: boolean | `@${string}`
    isEmulator?: boolean | `@${string}`
    snapshotAt?: boolean | `@${string}`
    timezone?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserFollower']: AliasType<{
    createdAt?: boolean | `@${string}`
    follower?: ResolverInputTypes['User']
    followerUserId?: boolean | `@${string}`
    isFavorite?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserLink']: AliasType<{
    campaignId?: boolean | `@${string}`
    clicksPerPeriod?: ResolverInputTypes['ClicksRecords']
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    linkId?: boolean | `@${string}`
    originalLink?: boolean | `@${string}`
    rank?: boolean | `@${string}`
    storageType?: boolean | `@${string}`
    uniqueClicks?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userEncodedLink?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserNotificationPreference']: AliasType<{
    category?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    emailOptOut?: boolean | `@${string}`
    pushOptOut?: boolean | `@${string}`
    smsOptOut?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserPayoutRequest']: AliasType<{
    createdAt?: boolean | `@${string}`
    failureReason?: boolean | `@${string}`
    id?: boolean | `@${string}`
    manuallyApprovedBy?: boolean | `@${string}`
    payoutAmountCents?: boolean | `@${string}`
    payoutBatchId?: boolean | `@${string}`
    payoutDate?: boolean | `@${string}`
    payoutLocale?: boolean | `@${string}`
    payoutState?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserSearchOutput']: AliasType<{
    items?: ResolverInputTypes['User']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserStagingApproval']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    reviewFeedback?: boolean | `@${string}`
    reviewedAt?: boolean | `@${string}`
    reviewedBy?: boolean | `@${string}`
    reviewedByEmail?: boolean | `@${string}`
    reviewedByName?: boolean | `@${string}`
    reviewedBySource?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    userStagingApprovalOutcome?: boolean | `@${string}`
    userStagingApprovalType?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UtmReferral']: AliasType<{
    campaign?: boolean | `@${string}`
    content?: boolean | `@${string}`
    medium?: boolean | `@${string}`
    source?: boolean | `@${string}`
    term?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['VerifySocialCodeResult']: AliasType<{
    followers?: boolean | `@${string}`
    handle?: boolean | `@${string}`
    isBusinessAccount?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    verified?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['VideoContentScan']: AliasType<{
    audioDuration?: boolean | `@${string}`
    audioEndAt?: boolean | `@${string}`
    audioStartFrom?: boolean | `@${string}`
    contentSafetyAnalysis?: ResolverInputTypes['ContentSafetyAnalysis']
    creationDateTime?: boolean | `@${string}`
    id?: boolean | `@${string}`
    processFinishedAt?: boolean | `@${string}`
    processId?: boolean | `@${string}`
    processInitiatedAt?: boolean | `@${string}`
    scanStatus?: boolean | `@${string}`
    socialMediaUrl?: boolean | `@${string}`
    transcription?: boolean | `@${string}`
    transcriptionConfidence?: boolean | `@${string}`
    updatedDateTime?: boolean | `@${string}`
    url?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Active']: Active
  ['AdminUpdateContentApprovalType']: AdminUpdateContentApprovalType
  ['AmazonReviewSubmissionStatus']: AmazonReviewSubmissionStatus
  ['ApplicationStatus']: ApplicationStatus
  ['ApprovalOutcome']: ApprovalOutcome
  ['ApprovalSort']: ApprovalSort
  ['ArchivedReason']: ArchivedReason
  ['AutoBuyDelayStrategy']: AutoBuyDelayStrategy
  ['AutoBuySkipReason']: AutoBuySkipReason
  ['AutoBuyStatus']: AutoBuyStatus
  ['BOPISValue']: BOPISValue
  ['BonusPayoutState']: BonusPayoutState
  ['BonusPayoutTypes']: BonusPayoutTypes
  ['BonusRequestTypes']: BonusRequestTypes
  ['BrandApiType']: BrandApiType
  ['BrandApiVersion']: BrandApiVersion
  ['BrandSafe']: BrandSafe
  ['CampaignBadgeType']: CampaignBadgeType
  ['CampaignGoal']: CampaignGoal
  ['CampaignProductType']: CampaignProductType
  ['CampaignReferralBonusFlag']: CampaignReferralBonusFlag
  ['CampaignSourcingSource']: CampaignSourcingSource
  ['CampaignStatus']: CampaignStatus
  ['Channel']: Channel
  ['ChatType']: ChatType
  ['CoarseCampaignStatus']: CoarseCampaignStatus
  ['ComplianceReviewCriteria']: ComplianceReviewCriteria
  ['ConnectedAccountType']: ConnectedAccountType
  ['ContentApprovalStatus']: ContentApprovalStatus
  ['ContentApprovalType']: ContentApprovalType
  ['ContentComplianceReviewStatus']: ContentComplianceReviewStatus
  ['ContentQualityRating']: ContentQualityRating
  ['ContentRatingReviewStatus']: ContentRatingReviewStatus
  ['ContentRatingSponsorshipContentInputSource']: ContentRatingSponsorshipContentInputSource
  ['ContentRequirementLevel']: ContentRequirementLevel
  ['ContentSafetyLabel']: ContentSafetyLabel
  ['ContentScanStatus']: ContentScanStatus
  ['ContentTypeRating']: ContentTypeRating
  ['ConversationChannel']: ConversationChannel
  ['ConversationInternalStatus']: ConversationInternalStatus
  ['ConversationMessageSource']: ConversationMessageSource
  ['ConversationMessageStatus']: ConversationMessageStatus
  ['ConversationStatus']: ConversationStatus
  ['CreationSourceVersion']: CreationSourceVersion
  ['CreatorPreference']: CreatorPreference
  ['Currency']: Currency
  ['Deranked_Reason']: Deranked_Reason
  ['DraftStatus']: DraftStatus
  ['EnabledFlag']: EnabledFlag
  ['FavoriteFlag']: FavoriteFlag
  ['FeatureFlagEnvironment']: FeatureFlagEnvironment
  ['FormComponentType']: FormComponentType
  ['FormFieldType']: FormFieldType
  ['FormType']: FormType
  ['FormValidationType']: FormValidationType
  ['FraudReason']: FraudReason
  ['FraudReviewStatus']: FraudReviewStatus
  ['InfluencerApprovalFlagV2']: InfluencerApprovalFlagV2
  ['InspirationalContentType']: InspirationalContentType
  ['InstagramGenderAgeDistributionGender']: InstagramGenderAgeDistributionGender
  ['ItemClassification']: ItemClassification
  ['KeywordAcceptanceResponse']: KeywordAcceptanceResponse
  ['LinkStorageType']: LinkStorageType
  ['ListingFulfillmentType']: ListingFulfillmentType
  ['ManualUploadType']: ManualUploadType
  ['ModelAttributeTypes']: ModelAttributeTypes
  ['ModelSortDirection']: ModelSortDirection
  ['NotificationStatus']: NotificationStatus
  ['OccurrenceConstraintPeriod']: OccurrenceConstraintPeriod
  ['OccurrenceConstraintType']: OccurrenceConstraintType
  ['OrderConfirmationScreenshotVerificationResult']: OrderConfirmationScreenshotVerificationResult
  ['OrderIdValidationStatus']: OrderIdValidationStatus
  ['Paused']: Paused
  ['PayForPerformanceFlag']: PayForPerformanceFlag
  ['PaymentStatus']: PaymentStatus
  ['PaymentTermsType']: PaymentTermsType
  ['Period']: Period
  ['PlatformInviteSource']: PlatformInviteSource
  ['PlatformPriority']: PlatformPriority
  ['PlatformSource']: PlatformSource
  ['PlatformStatus']: PlatformStatus
  ['PreApprovalStatus']: PreApprovalStatus
  ['ProductKeywordCreationSource']: ProductKeywordCreationSource
  ['ProductQualityLevel']: ProductQualityLevel
  ['PromoCodeType']: PromoCodeType
  ['PromoDiscountType']: PromoDiscountType
  ['PurchaseStatus']: PurchaseStatus
  ['PushChannel']: PushChannel
  ['ReferralType']: ReferralType
  ['RequiredProductLinkFlag']: RequiredProductLinkFlag
  ['ReviewFeedbackVisibility']: ReviewFeedbackVisibility
  ['ReviewScreenshotVerificationResult']: ReviewScreenshotVerificationResult
  ['RevocationType']: RevocationType
  ['ScrapeStatus']: ScrapeStatus
  ['SearchInputField']: SearchInputField
  ['SearchInputQueryScriptSource']: SearchInputQueryScriptSource
  ['SearchInputScriptType']: SearchInputScriptType
  ['SearchInputSortScriptSource']: SearchInputSortScriptSource
  ['SelfieVideoApprovalStatus']: SelfieVideoApprovalStatus
  ['ShopifyPromotionType']: ShopifyPromotionType
  ['SkuCreationSource']: SkuCreationSource
  ['SocialChannel']: SocialChannel
  ['SocialContentType']: SocialContentType
  ['SocialMediaPostContentType']: SocialMediaPostContentType
  ['SocialMediaPostGroupType']: SocialMediaPostGroupType
  ['SocialMetricName']: SocialMetricName
  ['SocialQuality']: SocialQuality
  ['SocialType']: SocialType
  ['SocialVerificationMethod']: SocialVerificationMethod
  ['SortOrder']: SortOrder
  ['SourcePriority']: SourcePriority
  ['StagingApprovalOutcome']: StagingApprovalOutcome
  ['StagingRequirementType']: StagingRequirementType
  ['StateChangeSource']: StateChangeSource
  ['StatusTier']: StatusTier
  ['StripeRegion']: StripeRegion
  ['SupportPriorityFlag']: SupportPriorityFlag
  ['TrackingStatus']: TrackingStatus
  ['USAState']: USAState
  ['UserCampaignAcceptanceStatus']: UserCampaignAcceptanceStatus
  ['UserCampaignApprovalStatus']: UserCampaignApprovalStatus
  ['UserCampaignChangelogEntryType']: UserCampaignChangelogEntryType
  ['UserCampaignSocialContentReviewOutcome']: UserCampaignSocialContentReviewOutcome
  ['UserCampaignSocialContentReviewStatus']: UserCampaignSocialContentReviewStatus
  ['UserCampaignSocialContentReviewType']: UserCampaignSocialContentReviewType
  ['UserCampaignStateChangeRequestType']: UserCampaignStateChangeRequestType
  ['UserFilterGender']: UserFilterGender
  ['UserGender']: UserGender
  ['UserStagingApprovalAction']: UserStagingApprovalAction
  ['UserStagingApprovalOutcome']: UserStagingApprovalOutcome
  ['UserStagingApprovalReviewSource']: UserStagingApprovalReviewSource
  ['UserStagingApprovalType']: UserStagingApprovalType
  ['ValidMccCodes']: ValidMccCodes
  ['WSLocale']: WSLocale
  ['AddressComponentsSearchFields']: {
    country?: ResolverInputTypes['SearchInputString'] | undefined | null
    state?: ResolverInputTypes['SearchInputString'] | undefined | null
  }
  ['AdminSendPushNotificationInput']: {
    body?: string | undefined | null
    notificationId: string
    userCampaigns?: Array<string> | undefined | null
    userIds?: Array<string> | undefined | null
  }
  ['ApplyToCampaignInput']: {
    autoApprove?: boolean | undefined | null
    campaignApplication?: string | undefined | null
    campaignId: string
    productId?: string | undefined | null
    userId: string
  }
  ['ApprovalFilterValueInput']: {
    filter: string
    value: string
  }
  ['ApproveUserCampaignForPurchaseInput']: {
    autoBuyApprovalDate?: string | undefined | null
    autoJoin?: boolean | undefined | null
    userCampaignId: string
  }
  ['AutoApprovalCriteriaInput']: {
    completedCampaigns?: number | undefined | null
  }
  ['AutoApprovalV2ConfigInput']: {
    autoApprovalCriteria?: ResolverInputTypes['AutoApprovalCriteriaInput'] | undefined | null
    enabled?: boolean | undefined | null
  }
  ['AutoBuyScheduleInput']: {
    date: string
    desiredPurchaseCount: number
    willPurchasePercentage?: number | undefined | null
  }
  ['BillingContactInput']: {
    email: string
    name: string
  }
  ['CampaignBadgeInput']: {
    type: ResolverInputTypes['CampaignBadgeType']
    value: string
  }
  ['ChannelConfigurationInput']: {
    attributes?: Array<ResolverInputTypes['KeyAttributeInput'] | undefined | null> | undefined | null
    enabled?: boolean | undefined | null
    fromAddress?: string | undefined | null
    templateId: string
  }
  ['ClicksRecordsInput']: {
    clicks: number
    from: string
    to: string
  }
  ['CollisionDensityValueInput']: {
    density: number
    skuId: string
  }
  ['ContentApprovalConfigurationInput']: {
    contentApprovalAssignee?: string | undefined | null
    contentApprovalType: ResolverInputTypes['ContentApprovalType']
  }
  ['ContentComplianceReviewCriteriaInput']: {
    compliant?: boolean | undefined | null
    criteria: ResolverInputTypes['ComplianceReviewCriteria']
    requirement?: string | undefined | null
  }
  ['ContentGuidanceInput']: {
    instance: number
    text: string
  }
  ['ContentLabelResultInput']: {
    confidence?: number | undefined | null
    label: ResolverInputTypes['ContentSafetyLabel']
    severity?: number | undefined | null
  }
  ['ContentRatingSponsorshipContentInput']: {
    inputSource?: ResolverInputTypes['ContentRatingSponsorshipContentInputSource'] | undefined | null
    isCompliant?: boolean | undefined | null
    isInappropriate?: boolean | undefined | null
    link: string
    qualityRating?: number | undefined | null
  }
  ['ContentSafetyAnalysisInput']: {
    isBrandSafe?: ResolverInputTypes['BrandSafe'] | undefined | null
    summary?: Array<ResolverInputTypes['ContentSafetyLabelSummaryInput'] | undefined | null> | undefined | null
    textInstances?: Array<ResolverInputTypes['ContentSafetyTextInstanceInput'] | undefined | null> | undefined | null
  }
  ['ContentSafetyLabelSummaryInput']: {
    confidence?: number | undefined | null
    label?: ResolverInputTypes['ContentSafetyLabel'] | undefined | null
    severity?: ResolverInputTypes['ContentSafetySeverityInput'] | undefined | null
  }
  ['ContentSafetySeverityInput']: {
    high?: number | undefined | null
    low?: number | undefined | null
    medium?: number | undefined | null
  }
  ['ContentSafetyTextInstanceInput']: {
    labels?: Array<ResolverInputTypes['ContentLabelResultInput'] | undefined | null> | undefined | null
    text: string
    timestampEnd?: number | undefined | null
    timestampStart?: number | undefined | null
  }
  ['CreateAutoBuyDistributionInput']: {
    createdAt?: string | undefined | null
    description?: string | undefined | null
    id?: string | undefined | null
    name: string
    schedule: Array<number>
    updatedAt?: string | undefined | null
  }
  ['CreateAutoBuyFlowConfigurationInput']: {
    dailyPurchaseMax?: number | undefined | null
    delayStrategy?: ResolverInputTypes['AutoBuyDelayStrategy'] | undefined | null
    flowId: string
    insufficientCreators?: string | undefined | null
    latestSkipReason?: ResolverInputTypes['AutoBuySkipReason'] | undefined | null
    pauseReason?: ResolverInputTypes['AutoBuySkipReason'] | undefined | null
    schedule?: Array<ResolverInputTypes['AutoBuyScheduleInput']> | undefined | null
    startDate: string
    status: ResolverInputTypes['AutoBuyStatus']
  }
  ['CreateAutoBuyFlowReleaseScheduleInput']: {
    actualPurchases?: number | undefined | null
    actualVerifiedPurchases?: number | undefined | null
    adjustedDesiredPurchases?: number | undefined | null
    autoApprovalSeats?: number | undefined | null
    date: string
    desiredPurchases?: number | undefined | null
    flowId: string
    manualPurchases?: number | undefined | null
    manualReleases?: number | undefined | null
    projectedReleaseCount?: number | undefined | null
    releaseCount?: number | undefined | null
    released?: boolean | undefined | null
    skippedReason?: ResolverInputTypes['AutoBuySkipReason'] | undefined | null
  }
  ['CreateAutoBuyReleaseInput']: {
    date: string
    productId: string
    releaseDateTime: string
    skuId?: string | undefined | null
    userCampaignId: string
  }
  ['CreateBrandApiInput']: {
    accessToken?: string | undefined | null
    api: ResolverInputTypes['BrandApiType']
    apiVersion?: ResolverInputTypes['BrandApiVersion'] | undefined | null
    brandId: string
    enabled?: boolean | undefined | null
    locale?: ResolverInputTypes['WSLocale'] | undefined | null
    refreshToken?: string | undefined | null
    shop?: string | undefined | null
    shopId?: string | undefined | null
  }
  ['CreateBrandInput']: {
    active?: string | undefined | null
    amazonBrandName?: string | undefined | null
    brandDetailsSubmitted?: boolean | undefined | null
    brandInstaHandle?: string | undefined | null
    brandLogoImageKey?: string | undefined | null
    brandStoreConfirmationImage?: string | undefined | null
    brandStoreConfirmationMobileImage?: string | undefined | null
    brandStoreScrapeUrl?: string | undefined | null
    brandTikTokHandle?: string | undefined | null
    companyId?: string | undefined | null
    contactEmails?: Array<string | undefined | null> | undefined | null
    defaultCreatorPreference?: ResolverInputTypes['CreatorPreference'] | undefined | null
    defaultCreatorPreferenceDetails?: string | undefined | null
    defaultHashtags?: Array<string> | undefined | null
    defaultMarketingBriefKey?: string | undefined | null
    defaultPostingGuidelines?: Array<string> | undefined | null
    defaultPostingInspirationLinks?: Array<string> | undefined | null
    emailsSent?: Array<string | undefined | null> | undefined | null
    fullScrapedAt?: string | undefined | null
    id?: string | undefined | null
    isBrandStoreScrapeEnabled?: boolean | undefined | null
    isSeller?: string | undefined | null
    isVendor?: string | undefined | null
    isWalmartScrapeEnabled?: boolean | undefined | null
    lastScrapedAt?: string | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    paymentBillingContact?: ResolverInputTypes['BillingContactInput'] | undefined | null
    platform?: ResolverInputTypes['PlatformSource'] | undefined | null
    postingGuidanceDocument?: string | undefined | null
    region?: ResolverInputTypes['WSLocale'] | undefined | null
    socialMediaExampleUrls?: Array<string> | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    walmartBrandName?: string | undefined | null
    walmartLastScrapedAt?: string | undefined | null
    walmartScrapeUrl?: string | undefined | null
  }
  ['CreateCampaignClicksInput']: {
    campaignId: string
    clicks: number
    date: string
  }
  ['CreateCampaignInput']: {
    aboutSection?: string | undefined | null
    active: string
    actualStartDate?: string | undefined | null
    amountPaidByClient?: number | undefined | null
    anticipatedStartDate: string
    applicationForm?: string | undefined | null
    applicationProductLinkId?: string | undefined | null
    applicationStatus?: ResolverInputTypes['ApplicationStatus'] | undefined | null
    attributionRefUrlSuffix?: string | undefined | null
    authorizedUnits?: number | undefined | null
    badges?: Array<ResolverInputTypes['CampaignBadgeInput'] | undefined | null> | undefined | null
    brandId: string
    brandProductShort?: string | undefined | null
    brandStoreLink?: string | undefined | null
    budgetSpent?: number | undefined | null
    campaignProductType?: ResolverInputTypes['CampaignProductType'] | undefined | null
    campaignReferralBonusAmount?: number | undefined | null
    campaignReferralBonusEnabled?: ResolverInputTypes['CampaignReferralBonusFlag'] | undefined | null
    campaignStateMachine?: string | undefined | null
    closeoutReleaseFailureReason?: string | undefined | null
    closeoutReleaseRate?: ResolverInputTypes['RateInput'] | undefined | null
    collisionSkuDensity?: Array<ResolverInputTypes['CollisionDensityValueInput']> | undefined | null
    commissionFlatRate?: number | undefined | null
    commissionPercentageRate?: number | undefined | null
    committedClicks?: number | undefined | null
    committedPlacementWinsCount?: number | undefined | null
    committedReviewsCount?: number | undefined | null
    contentApprovalRequired?: boolean | undefined | null
    contentCompleteDeadline?: string | undefined | null
    createdAt?: string | undefined | null
    creationSourceVersion?: ResolverInputTypes['CreationSourceVersion'] | undefined | null
    earlyReviewEnabled?: boolean | undefined | null
    enableContentComplianceReview?: boolean | undefined | null
    endDate?: string | undefined | null
    excludeGender?: ResolverInputTypes['UserFilterGender'] | undefined | null
    expectedBonus?: string | undefined | null
    followersCountCeiling?: number | undefined | null
    followersCountFloor?: number | undefined | null
    goal?: ResolverInputTypes['CampaignGoal'] | undefined | null
    id?: string | undefined | null
    influencerApprovalEnabled?: ResolverInputTypes['InfluencerApprovalFlagV2'] | undefined | null
    lifestyleCommittedContentCount?: number | undefined | null
    localeV1?: ResolverInputTypes['WSLocale'] | undefined | null
    marketingFee?: number | undefined | null
    marketingFormFilled?: boolean | undefined | null
    newCreatorsOnly?: boolean | undefined | null
    payForClicksEnabled?: ResolverInputTypes['EnabledFlag'] | undefined | null
    payForClicksGeneratedPerPeriod?: Array<ResolverInputTypes['ClicksRecordsInput']> | undefined | null
    payForClicksMinClicks?: number | undefined | null
    payForClicksOriginalLink?: string | undefined | null
    payForClicksTotalClicksGenerated?: number | undefined | null
    payForPerformanceEnabled?: ResolverInputTypes['PayForPerformanceFlag'] | undefined | null
    postingGuidance?: string | undefined | null
    preApplicationProductImage?: string | undefined | null
    preApplicationProductPriceRange?: string | undefined | null
    preApplicationUgcProductImage?: string | undefined | null
    preLaunchDate?: string | undefined | null
    premiumLifestyleCommittedContentCount?: number | undefined | null
    requiredProductLinksEnabled?: ResolverInputTypes['RequiredProductLinkFlag'] | undefined | null
    reviewVotingTarget?: string | undefined | null
    sample?: boolean | undefined | null
    shellCampaignBase?: string | undefined | null
    skuId: string
    skuPriceOverride?: number | undefined | null
    socialCommittedContentCount?: number | undefined | null
    sourcingPriority?: number | undefined | null
    sponsorshipInstructions?: string | undefined | null
    title: string
    totalEarlyReviewsAllowed?: number | undefined | null
    totalProductCost?: number | undefined | null
    userAutoApproved?: boolean | undefined | null
    visibleToUser: string
  }
  ['CreateCampaignInviteInput']: {
    campaignId: string
    createdAt?: string | undefined | null
    id?: string | undefined | null
    notificationStatus: ResolverInputTypes['NotificationStatus']
    priority: number
    updatedAt?: string | undefined | null
    userId: string
  }
  ['CreateCampaignSourcingProspectInput']: {
    bonusConfig?: ResolverInputTypes['UserCampaignBonusConfigInput'] | undefined | null
    campaignId: string
    createdAt?: string | undefined | null
    createdById?: string | undefined | null
    instagramFollowersInt?: number | undefined | null
    instagramHandle?: string | undefined | null
    internalNotesId?: string | undefined | null
    name?: string | undefined | null
    platformInviteSource: ResolverInputTypes['PlatformInviteSource']
    platformStatus?: ResolverInputTypes['PlatformStatus'] | undefined | null
    preApprovalStatus?: ResolverInputTypes['PreApprovalStatus'] | undefined | null
    preApprovalStatusById?: string | undefined | null
    priority?: ResolverInputTypes['SourcePriority'] | undefined | null
    productId?: string | undefined | null
    sourcingSource: ResolverInputTypes['CampaignSourcingSource']
    tiktokFollowersInt?: number | undefined | null
    tiktokHandle?: string | undefined | null
    userCampaignId?: string | undefined | null
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined | null
    youtubeChannelHandle?: string | undefined | null
  }
  ['CreateChatConversationV2Input']: {
    active: ResolverInputTypes['Active']
    adminLastReadMessageIndex?: number | undefined | null
    adminUnreadCount: number
    campaignId?: string | undefined | null
    chatType: ResolverInputTypes['ChatType']
    creatorLastReadMessageIndex?: number | undefined | null
    creatorUnreadCount: number
    lastMessage?: string | undefined | null
    lastMessageDate?: string | undefined | null
    lastMessageId?: string | undefined | null
    lastMessageSource?: ResolverInputTypes['ConversationMessageSource'] | undefined | null
    resourceId: string
    status: ResolverInputTypes['ConversationInternalStatus']
    statusUpdatedAt?: string | undefined | null
    statusUpdatedByFirstName?: string | undefined | null
    statusUpdatedById?: string | undefined | null
    statusUpdatedByLastName?: string | undefined | null
    twilioConversationId: string
    userCampaignId?: string | undefined | null
    userEmail?: string | undefined | null
    userFirstName?: string | undefined | null
    userId: string
    userLastName?: string | undefined | null
  }
  ['CreateCommunicationNotificationInput']: {
    address: string
    attributes?: Array<ResolverInputTypes['KeyAttributeInput']> | undefined | null
    campaignId?: string | undefined | null
    channel: ResolverInputTypes['Channel']
    expectedSentAt: string
    experimentTreatment?: string | undefined | null
    id?: string | undefined | null
    notificationId: string
    sentAt: string
    status?: string | undefined | null
    statusMessage?: string | undefined | null
    templateId?: string | undefined | null
    traceId: string
    userCampaignId?: string | undefined | null
    userId: string
  }
  ['CreateCompanyInput']: {
    agencyId?: string | undefined | null
    id?: string | undefined | null
    logo?: string | undefined | null
    name: string
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ResolverInputTypes['PaymentTermsType'] | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['CreateConnectedAccountInput']: {
    accountId: string
    accountType: ResolverInputTypes['ConnectedAccountType']
    createdAt?: string | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['CreateConversationInput']: {
    contactOwnerId?: string | undefined | null
    lastContactAt?: string | undefined | null
    status?: ResolverInputTypes['ConversationStatus'] | undefined | null
    statusChangedAt?: string | undefined | null
    userId: string
  }
  ['CreateConversationMessageInput']: {
    channel: ResolverInputTypes['ConversationChannel']
    id?: string | undefined | null
    messageContent: string
    messageId?: string | undefined | null
    messageMediaKeys?: Array<string> | undefined | null
    replyFromId?: string | undefined | null
    sendAt?: string | undefined | null
    sendAttempts?: number | undefined | null
    sentAt?: string | undefined | null
    status: ResolverInputTypes['ConversationMessageStatus']
    userId: string
  }
  ['CreateEtailerSkuMetricsInput']: {
    date: string
    pageViews?: number | undefined | null
    sku: string
    skuId: string
    totalSalesAmount?: number | undefined | null
    unitsSold?: number | undefined | null
  }
  ['CreateFormSequenceConfigurationInput']: {
    active: ResolverInputTypes['Active']
    id?: string | undefined | null
    sequenceName: string
    sequences?: Array<ResolverInputTypes['FormSequenceInput']> | undefined | null
  }
  ['CreateInstagramStoryScreenInput']: {
    exits?: number | undefined | null
    expirationUnixTime: number
    externalId?: string | undefined | null
    id?: string | undefined | null
    impressions?: number | undefined | null
    instagramUserId: string
    mediaId: string
    postedAt?: string | undefined | null
    postingDate: string
    reach?: number | undefined | null
    replies?: number | undefined | null
    s3Bucket?: string | undefined | null
    tapsBack?: number | undefined | null
    tapsForward?: number | undefined | null
    userCampaignId?: string | undefined | null
  }
  ['CreateMessageInput']: {
    body: string
    createdAt?: string | undefined | null
    id?: string | undefined | null
    updatedAt?: string | undefined | null
    userCampaignId?: string | undefined | null
    userHasSeen?: boolean | undefined | null
    userId: string
  }
  ['CreateNoteInput']: {
    createdAt?: string | undefined | null
    id?: string | undefined | null
    note: string
    parentId: string
    userId: string
  }
  ['CreateNotificationConfigurationInput']: {
    category: string
    description?: string | undefined | null
    email?: ResolverInputTypes['ChannelConfigurationInput'] | undefined | null
    enabled?: boolean | undefined | null
    id?: string | undefined | null
    inAppChatChannel?: ResolverInputTypes['MessagingChannelConfigurationInput'] | undefined | null
    name: string
    occurrenceConstraint?: ResolverInputTypes['OccurrenceConstraintInput'] | undefined | null
    push?: ResolverInputTypes['PushChannelConfigurationInput'] | undefined | null
    sms?: ResolverInputTypes['ChannelConfigurationInput'] | undefined | null
  }
  ['CreateProductBonusConfigSnapshotInput']: {
    closeoutSurveyBonus?: number | undefined | null
    contentBonuses?: Array<ResolverInputTypes['ProductSocialContentInput']> | undefined | null
    discoveryBonus?: number | undefined | null
    payForImpressionsBonus?: ResolverInputTypes['PayForImpressionsBonusConfigInput'] | undefined | null
    productId: string
    snapshotAt: string
  }
  ['CreateProductFromUrlInput']: {
    brandId: string
    creationSource: ResolverInputTypes['SkuCreationSource']
    productUrl: string
  }
  ['CreateProductInput']: {
    accountRequired?: boolean | undefined | null
    active?: string | undefined | null
    approvalFilters?: Array<ResolverInputTypes['ApprovalFilterValueInput']> | undefined | null
    autoApprovalSeatsAvailable?: number | undefined | null
    autoApprovalStatusTiers?: Array<ResolverInputTypes['StatusTier']> | undefined | null
    autoApprovalV2Config?: ResolverInputTypes['AutoApprovalV2ConfigInput'] | undefined | null
    automaticReviewRelease?: boolean | undefined | null
    baseBonus?: number | undefined | null
    brandAdvocateCount?: number | undefined | null
    brandTalkingPoints?: Array<string> | undefined | null
    buyOnlinePickupInStore?: ResolverInputTypes['BOPISValue'] | undefined | null
    campaignId?: string | undefined | null
    campaignStateMachine?: string | undefined | null
    closeoutFormBonus?: number | undefined | null
    contentApprovalConfiguration?: Array<ResolverInputTypes['ContentApprovalConfigurationInput']> | undefined | null
    contentApprovalLeadDays?: number | undefined | null
    contentRequirement?: Array<ResolverInputTypes['ProductSocialContentInput']> | undefined | null
    creatorCount?: number | undefined | null
    creatorPreference?: ResolverInputTypes['CreatorPreference'] | undefined | null
    creatorPreferenceDetails?: string | undefined | null
    customFtcHashtags?: Array<string> | undefined | null
    customHashtags?: Array<string> | undefined | null
    directPurchaseLink?: string | undefined | null
    engagementRateInstagramRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    engagementRateTiktokRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    followersInstagramRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    followersTiktokRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    hideBrandContentApprovals?: boolean | undefined | null
    id?: string | undefined | null
    impressionsInstagramRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    impressionsTiktokRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    instaHandle?: string | undefined | null
    internalCreatorsStagingGuidance?: string | undefined | null
    internalDescription?: string | undefined | null
    internalName?: string | undefined | null
    internalNotes?: string | undefined | null
    isDirectListingPurchase?: boolean | undefined | null
    isQualityRequirementsEnabled?: boolean | undefined | null
    listingGuidance?: string | undefined | null
    maxDiscoveryBonus?: number | undefined | null
    minimumCreatorQualityScore?: number | undefined | null
    nonRetailReviewLink?: string | undefined | null
    nonRetailReviewWebsiteName?: string | undefined | null
    payForClicksEnabled?: ResolverInputTypes['EnabledFlag'] | undefined | null
    payForClicksMinClicks?: number | undefined | null
    payForImpressionsBonus?: ResolverInputTypes['PayForImpressionsBonusConfigInput'] | undefined | null
    payForPerformanceBonus?: ResolverInputTypes['StatusTierBonusConfigInput'] | undefined | null
    payForPostingContentEarly?: ResolverInputTypes['PayForPostingContentEarlyConfigInput'] | undefined | null
    postingDeadlineDaysRelativeToApproval?: number | undefined | null
    productLinkId?: string | undefined | null
    productPostingGuidance?: string | undefined | null
    productPostingGuidanceDocument?: string | undefined | null
    promoCode?: string | undefined | null
    promoCodeGuidance?: string | undefined | null
    promoCodeType?: ResolverInputTypes['PromoCodeType'] | undefined | null
    promoDiscountAmount?: number | undefined | null
    promoDiscountType?: ResolverInputTypes['PromoDiscountType'] | undefined | null
    promoEndDate?: string | undefined | null
    promoLink?: string | undefined | null
    promoStartDate?: string | undefined | null
    promotionLinkId?: string | undefined | null
    purchaseByPromoCode?: boolean | undefined | null
    purchaseDiscountCode?: string | undefined | null
    purchaseDiscountCodeDetails?: string | undefined | null
    purchaseDiscountCodePercentage?: string | undefined | null
    qualityLevel?: ResolverInputTypes['ProductQualityLevel'] | undefined | null
    requestEarlyProductFeedback?: boolean | undefined | null
    requestProductFeedback?: boolean | undefined | null
    requiredProductLinkEnabled?: ResolverInputTypes['RequiredProductLinkFlag'] | undefined | null
    sequenceId?: string | undefined | null
    shopifyPriceRule?: string | undefined | null
    shopifyPromotionId?: string | undefined | null
    shopifyPromotionType?: ResolverInputTypes['ShopifyPromotionType'] | undefined | null
    skuId: string
    socialMediaExampleUrls?: Array<string> | undefined | null
    stagingApprovalConfiguration?: Array<ResolverInputTypes['StagingApprovalConfigurationInput']> | undefined | null
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined | null
    stagingRequirementType?: ResolverInputTypes['StagingRequirementType'] | undefined | null
    stagingSocialQualityRequirement?: ResolverInputTypes['SocialQuality'] | undefined | null
    tikTokHandle?: string | undefined | null
    unitsPurchased?: number | undefined | null
    validateProductFoundVariations?: Array<string> | undefined | null
    visibleAt?: string | undefined | null
    visibleEndsAt?: string | undefined | null
  }
  ['CreateProductKeywordsInput']: {
    adminPriority?: number | undefined | null
    brandPriority?: number | undefined | null
    brandResponse?: ResolverInputTypes['KeywordAcceptanceResponse'] | undefined | null
    campaignId: string
    creationSource?: ResolverInputTypes['ProductKeywordCreationSource'] | undefined | null
    endDate?: string | undefined | null
    heliumAccount?: string | undefined | null
    heliumAsin?: string | undefined | null
    heliumLink?: string | undefined | null
    id?: string | undefined | null
    keyword: string
    monthlySearchVolume?: number | undefined | null
    rank?: number | undefined | null
    rankingHistory?: Array<ResolverInputTypes['RankPointInput']> | undefined | null
    rankingLastSkippedAt?: string | undefined | null
    rankingLastSkippedByUserId?: string | undefined | null
    rankingLastUpdatedAt?: string | undefined | null
    rankingLastUpdatedByUserId?: string | undefined | null
    recommended?: boolean | undefined | null
    skuId?: string | undefined | null
    startDate?: string | undefined | null
    usedForCampaign?: boolean | undefined | null
    visible?: boolean | undefined | null
    weight?: number | undefined | null
  }
  ['CreateProductRatingSnapshotInput']: {
    numFiveStar?: number | undefined | null
    numFourStar?: number | undefined | null
    numOneStar?: number | undefined | null
    numRatings?: number | undefined | null
    numReviews?: number | undefined | null
    numThreeStar?: number | undefined | null
    numTwoStar?: number | undefined | null
    rating?: number | undefined | null
    sku: string
    skuId: string
    snapshotAt: string
  }
  ['CreatePurchaseInput']: {
    amount: number
    cardId?: string | undefined | null
    currency: string
    id?: string | undefined | null
    isFraud?: boolean | undefined | null
    keywordAtPurchase?: string | undefined | null
    purchasedAt: string
    shippedAt?: string | undefined | null
    status: ResolverInputTypes['PurchaseStatus']
    userCampaignId: string
  }
  ['CreateReferralInput']: {
    bonusPayoutId?: string | undefined | null
    campaignId?: string | undefined | null
    id?: string | undefined | null
    referralType?: ResolverInputTypes['ReferralType'] | undefined | null
    referredUserId: string
    referrerUserId: string
  }
  ['CreateReviewInput']: {
    attributedUserId?: string | undefined | null
    author?: string | undefined | null
    authorBadge?: string | undefined | null
    badge?: string | undefined | null
    commentCount?: number | undefined | null
    helpfulReactions?: number | undefined | null
    id?: string | undefined | null
    negativeReactions?: number | undefined | null
    photos?: Array<string> | undefined | null
    productId: string
    productVariant?: string | undefined | null
    rating?: number | undefined | null
    removed?: boolean | undefined | null
    reviewedAt: string
    reviewedSku?: string | undefined | null
    reviewerId?: string | undefined | null
    sku?: string | undefined | null
    skuId: string
    store?: string | undefined | null
    text?: string | undefined | null
    title?: string | undefined | null
    userCampaignId?: string | undefined | null
    videos?: Array<string> | undefined | null
  }
  ['CreateSkuInput']: {
    active?: string | undefined | null
    approxRetailPrice?: string | undefined | null
    approxShippingCost?: number | undefined | null
    attributes?: Array<ResolverInputTypes['SkuAttributeInput']> | undefined | null
    availabilityDate?: string | undefined | null
    availabilityNotified?: boolean | undefined | null
    availabilityNotifiedAt?: string | undefined | null
    brandId?: string | undefined | null
    brandListingKey?: string | undefined | null
    brandStoreId?: string | undefined | null
    bundle?: string | undefined | null
    createdByUserId?: string | undefined | null
    creationSource?: ResolverInputTypes['SkuCreationSource'] | undefined | null
    creationSourceUrl?: string | undefined | null
    currency?: ResolverInputTypes['Currency'] | undefined | null
    currentBuyBoxPrice?: number | undefined | null
    currentBuyBoxPriceCents?: number | undefined | null
    estimatedAvailabilityDate?: string | undefined | null
    estimatedDeliveryDays?: number | undefined | null
    estimatedMonthlyUnitsSold?: number | undefined | null
    estimatedWeeklyUnitsSold?: number | undefined | null
    exactListingTitle?: string | undefined | null
    exactRetailPrice?: string | undefined | null
    fullReviewScrapeComplete?: boolean | undefined | null
    id?: string | undefined | null
    inventory?: number | undefined | null
    iosAppProviderId?: string | undefined | null
    isAplusPage?: string | undefined | null
    isPrime?: string | undefined | null
    isRecommendationsHidden?: boolean | undefined | null
    itemClassification?: ResolverInputTypes['ItemClassification'] | undefined | null
    lastAutoFilledAt?: string | undefined | null
    lastKnownBuyBoxPrice?: number | undefined | null
    lastKnownBuyBoxPriceCents?: number | undefined | null
    listingBrand?: string | undefined | null
    listingCategory?: Array<string> | undefined | null
    listingDescription?: string | undefined | null
    listingFulfillmentType?: ResolverInputTypes['ListingFulfillmentType'] | undefined | null
    listingGuidance?: string | undefined | null
    listingLink?: string | undefined | null
    mccValue?: ResolverInputTypes['ValidMccCodes'] | undefined | null
    minimumOrderQuantity?: number | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    nonSponsoredListingKey?: string | undefined | null
    parentSkuId?: string | undefined | null
    priceCents?: number | undefined | null
    product?: string | undefined | null
    productImageKey?: string | undefined | null
    productPostingGuidance?: string | undefined | null
    productPostingGuidanceDocument?: string | undefined | null
    productSearchImageKey?: string | undefined | null
    releaseDate?: string | undefined | null
    skuId?: string | undefined | null
    sponsoredListingKey?: string | undefined | null
    storeName?: string | undefined | null
    targetReviewRate?: ResolverInputTypes['RateInput'] | undefined | null
    targetReviewRateInvalid?: string | undefined | null
  }
  ['CreateSkuSnapshotInput']: {
    inventory?: number | undefined | null
    marketplaceSku: string
    price?: number | undefined | null
    skuId: string
    snapshotDate: string
  }
  ['CreateTwilioConversationInput']: {
    chatType: ResolverInputTypes['ChatType']
    resourceId: string
  }
  ['CreateUserBonusPayoutInput']: {
    adminPaymentApproved?: boolean | undefined | null
    bonusType: ResolverInputTypes['BonusPayoutTypes']
    createdAt?: string | undefined | null
    failureReason?: string | undefined | null
    id?: string | undefined | null
    manuallyCreatedBy?: string | undefined | null
    payoutAmount?: number | undefined | null
    payoutAmountCents?: number | undefined | null
    payoutBatchId?: string | undefined | null
    payoutDate?: string | undefined | null
    payoutLocale?: ResolverInputTypes['WSLocale'] | undefined | null
    payoutState?: ResolverInputTypes['BonusPayoutState'] | undefined | null
    quantity?: number | undefined | null
    referenceId?: string | undefined | null
    releaseAt?: string | undefined | null
    revocationType?: ResolverInputTypes['RevocationType'] | undefined | null
    revokedAt?: string | undefined | null
    userCampaignId?: string | undefined | null
    userId: string
  }
  ['CreateUserCampaignChangelogInput']: {
    createdAt?: string | undefined | null
    createdByUserId?: string | undefined | null
    entryType: ResolverInputTypes['UserCampaignChangelogEntryType']
    extraData?: string | undefined | null
    userCampaignId: string
  }
  ['CreateUserCampaignInput']: {
    accountScreenShot?: string | undefined | null
    amznReviewLink?: string | undefined | null
    amznReviewSubmissionStatus?: ResolverInputTypes['AmazonReviewSubmissionStatus'] | undefined | null
    applicationRejectedByUserId?: string | undefined | null
    approvalOutcome?: ResolverInputTypes['ApprovalOutcome'] | undefined | null
    approvalStatus?: ResolverInputTypes['UserCampaignApprovalStatus'] | undefined | null
    archivedFeedback?: string | undefined | null
    archivedReason?: ResolverInputTypes['ArchivedReason'] | undefined | null
    autoBuyApprovalDate?: string | undefined | null
    autoBuyRevokeDate?: string | undefined | null
    bestBuyReviewScreenShot?: string | undefined | null
    bonusConfig?: ResolverInputTypes['UserCampaignBonusConfigInput'] | undefined | null
    campaignAcceptanceStatus?: ResolverInputTypes['UserCampaignAcceptanceStatus'] | undefined | null
    campaignApplication?: string | undefined | null
    campaignId: string
    campaignStatus?: ResolverInputTypes['CampaignStatus'] | undefined | null
    campaignStatusOnArchive?: string | undefined | null
    cardId?: string | undefined | null
    cardRegion?: ResolverInputTypes['StripeRegion'] | undefined | null
    closeoutSurvey?: string | undefined | null
    coarseCampaignStatus?: ResolverInputTypes['CoarseCampaignStatus'] | undefined | null
    contentApprovalLeadDays?: number | undefined | null
    createdAt?: string | undefined | null
    discoverySurvey?: string | undefined | null
    id?: string | undefined | null
    influencerApprovalInviteFeedback?: string | undefined | null
    influencerApprovalRejectFeedback?: string | undefined | null
    invoiceKey?: string | undefined | null
    isAutoApproved?: boolean | undefined | null
    keywordAtPurchase?: string | undefined | null
    keywordId?: string | undefined | null
    keywordsV2Hash?: string | undefined | null
    listingKey?: string | undefined | null
    localeV1?: ResolverInputTypes['WSLocale'] | undefined | null
    manualPurchase?: boolean | undefined | null
    moderatorStagingApprovalAt?: string | undefined | null
    moderatorStagingApprovalBy?: string | undefined | null
    moderatorStagingApprovalOutcome?: ResolverInputTypes['StagingApprovalOutcome'] | undefined | null
    orderId?: string | undefined | null
    orderIdInvalidReason?: string | undefined | null
    orderIdSubmissionDate?: string | undefined | null
    orderIdValidationDate?: string | undefined | null
    orderIdValidationStatus?: ResolverInputTypes['OrderIdValidationStatus'] | undefined | null
    orderStatus?: string | undefined | null
    payForClickLinkId?: string | undefined | null
    paypalEmailAtSubmission?: string | undefined | null
    postingDeadline?: string | undefined | null
    postingDeadlineExtended?: boolean | undefined | null
    previousCardIds?: Array<string> | undefined | null
    priority?: ResolverInputTypes['SourcePriority'] | undefined | null
    productCriticalFeedback?: string | undefined | null
    productId?: string | undefined | null
    productPositiveFeedback?: string | undefined | null
    rankPriority?: number | undefined | null
    reviewScreenshot?: string | undefined | null
    reviewScreenshotVerificationResult?: ResolverInputTypes['ReviewScreenshotVerificationResult'] | undefined | null
    schedulingReleaseAt?: string | undefined | null
    searchPhraseKey?: string | undefined | null
    selectedFlow?: string | undefined | null
    selfieVideoApprovalDate?: string | undefined | null
    selfieVideoApprovalNotes?: string | undefined | null
    selfieVideoApprovalStatus?: ResolverInputTypes['SelfieVideoApprovalStatus'] | undefined | null
    selfieVideoKey?: string | undefined | null
    selfieVideoRejectionReason?: string | undefined | null
    selfieVideoSubmissionRequired?: boolean | undefined | null
    selfieVideoSubmittedAt?: string | undefined | null
    sequenceFormSubmissionValues?: Array<ResolverInputTypes['SequenceFormSubmissionValuesInput']> | undefined | null
    shippingAddress?: string | undefined | null
    showEarlyReviewSurvey?: boolean | undefined | null
    statusHistory?: string | undefined | null
    updatedAt?: string | undefined | null
    userId: string
    walmartReviewScreenShot?: string | undefined | null
  }
  ['CreateUserCampaignSnapshotInput']: {
    snapshot: string
    userCampaignId: string
    userCampaignVersion: number
  }
  ['CreateUserCampaignSocialContentDraftHistoryInput']: {
    id?: string | undefined | null
    snapshot: string
    snapshotDate: string
    userCampaignSocialContentId: string
  }
  ['CreateUserCampaignSocialContentInput']: {
    approvalFeedback?: string | undefined | null
    approvalStatus?: ResolverInputTypes['ContentApprovalStatus'] | undefined | null
    approvedAt?: string | undefined | null
    approverPWUserId?: string | undefined | null
    approverUserId?: string | undefined | null
    bonusEarned?: number | undefined | null
    bonusOverride?: number | undefined | null
    caption?: string | undefined | null
    channel: ResolverInputTypes['SocialChannel']
    clientFeedback?: Array<ResolverInputTypes['SocialMediaFeedbackInput']> | undefined | null
    complianceReview?: Array<ResolverInputTypes['ContentComplianceReviewCriteriaInput']> | undefined | null
    complianceReviewStatus?: ResolverInputTypes['ContentComplianceReviewStatus'] | undefined | null
    complianceReviewUserId?: string | undefined | null
    complianceReviewedAt?: string | undefined | null
    contentType?: ResolverInputTypes['SocialContentType'] | undefined | null
    draftEnteredAdminApprovalAt?: string | undefined | null
    draftEnteredBrandApprovalAt?: string | undefined | null
    draftFeedback?: Array<string> | undefined | null
    draftHistory?: Array<string> | undefined | null
    draftMedia?: Array<string> | undefined | null
    draftPreApprovedText?: string | undefined | null
    draftQualityScore?: number | undefined | null
    draftReview?: Array<ResolverInputTypes['ContentComplianceReviewCriteriaInput']> | undefined | null
    draftStatus?: ResolverInputTypes['DraftStatus'] | undefined | null
    draftSubmittedAt?: string | undefined | null
    draftText?: string | undefined | null
    earlySubmissionBonusEarnedCents?: number | undefined | null
    experimentId?: string | undefined | null
    externalUrl?: string | undefined | null
    groupId?: string | undefined | null
    hashtags?: Array<string> | undefined | null
    id?: string | undefined | null
    instance?: number | undefined | null
    internalFeedback?: Array<ResolverInputTypes['SocialMediaFeedbackInput']> | undefined | null
    isFavorite?: boolean | undefined | null
    isHidden?: boolean | undefined | null
    isPurchased?: boolean | undefined | null
    isUnavailable?: boolean | undefined | null
    manualUploadType?: ResolverInputTypes['ManualUploadType'] | undefined | null
    mediaId?: string | undefined | null
    mediaUrls?: Array<string> | undefined | null
    metrics?: Array<ResolverInputTypes['SocialMetricInput']> | undefined | null
    paymentId?: string | undefined | null
    postedAt?: string | undefined | null
    qualityScore?: number | undefined | null
    rejectionReason?: string | undefined | null
    requestedSocialScript?: boolean | undefined | null
    requirementLevel?: ResolverInputTypes['ContentRequirementLevel'] | undefined | null
    scrapeStatus?: ResolverInputTypes['ScrapeStatus'] | undefined | null
    unavailableAt?: string | undefined | null
    usedRequestedSocialScript?: boolean | undefined | null
    userCampaignId: string
    videoEndTime?: string | undefined | null
    videoStartTime?: string | undefined | null
  }
  ['CreateUserCampaignSocialContentReviewInput']: {
    createdAt?: string | undefined | null
    reviewFeedback?: Array<ResolverInputTypes['UserCampaignSocialContentReviewFeedbackInput']> | undefined | null
    reviewOutcome?: ResolverInputTypes['UserCampaignSocialContentReviewOutcome'] | undefined | null
    reviewStatus?: ResolverInputTypes['UserCampaignSocialContentReviewStatus'] | undefined | null
    reviewType: ResolverInputTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined | null
    reviewerAssigneeId?: string | undefined | null
    reviewerFirstName?: string | undefined | null
    reviewerId?: string | undefined | null
    reviewerLastName?: string | undefined | null
    userCampaignSocialContentId: string
    userId: string
  }
  ['CreateUserDeviceSnapshotInput']: {
    id?: string | undefined | null
    idfv?: string | undefined | null
    ipAddress?: string | undefined | null
    isEmulator?: boolean | undefined | null
    snapshotAt: string
    timezone?: string | undefined | null
    userId: string
  }
  ['CreateUserFollowerInput']: {
    createdAt?: string | undefined | null
    followerUserId: string
    isFavorite?: ResolverInputTypes['FavoriteFlag'] | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['CreateUserInput']: {
    active?: ResolverInputTypes['Active'] | undefined | null
    addressComponents?: string | undefined | null
    amznLastChecked?: string | undefined | null
    amznLink?: string | undefined | null
    amznPrime?: string | undefined | null
    amznProfileId?: string | undefined | null
    amznReviewerId?: string | undefined | null
    amznVerified?: string | undefined | null
    attributedReviewCount?: number | undefined | null
    blogWebsite?: string | undefined | null
    brandContentRatingAvg?: number | undefined | null
    campaignAcceptanceRate?: number | undefined | null
    campaignApprovalRate?: number | undefined | null
    campaignCompletionRate?: number | undefined | null
    campaignReviewRate?: number | undefined | null
    cardProfileUpdates?: string | undefined | null
    cardholderId?: string | undefined | null
    cardholderRegion?: ResolverInputTypes['StripeRegion'] | undefined | null
    characteristics?: Array<ResolverInputTypes['UserCharacteristicInput']> | undefined | null
    characteristicsUpdatedAt?: string | undefined | null
    claimedReviewCount?: number | undefined | null
    collisionSkuSet?: Array<string> | undefined | null
    collisionSkuSetCalculatedAt?: string | undefined | null
    completedLifestyleCampaignCount?: number | undefined | null
    completedLiftCampaignCount?: number | undefined | null
    completedSocialCampaignCount?: number | undefined | null
    contentRating?: ResolverInputTypes['UserContentRatingInput'] | undefined | null
    contentRatingCompletedAt?: string | undefined | null
    contentRatingCompletionTimeMillis?: number | undefined | null
    contentRatingInstagramCompletionTimeMillis?: number | undefined | null
    contentRatingInstagramSponsorshipContent?:
      | Array<ResolverInputTypes['ContentRatingSponsorshipContentInput']>
      | undefined
      | null
    contentRatingQualityScore?: number | undefined | null
    contentRatingReviewStatus?: ResolverInputTypes['ContentRatingReviewStatus'] | undefined | null
    contentRatingSponsorshipContent?:
      | Array<ResolverInputTypes['ContentRatingSponsorshipContentInput']>
      | undefined
      | null
    contentRatingSubmittedBy?: string | undefined | null
    contentRatingTiktokCompletionTimeMillis?: number | undefined | null
    contentRatingTiktokSponsorshipContent?:
      | Array<ResolverInputTypes['ContentRatingSponsorshipContentInput']>
      | undefined
      | null
    contentTypes?: Array<string> | undefined | null
    createdAt?: string | undefined | null
    date_of_birth?: string | undefined | null
    deactivationDate?: string | undefined | null
    deactivationReason?: string | undefined | null
    declineToReviewCount?: number | undefined | null
    deranked?: ResolverInputTypes['Deranked_Reason'] | undefined | null
    email?: string | undefined | null
    emailOptOut?: boolean | undefined | null
    facebookAccessToken?: string | undefined | null
    facebookAccessTokenExpiry?: string | undefined | null
    facebookPageId?: string | undefined | null
    facebookPageIsSubscribed?: boolean | undefined | null
    facebookPermissionScopes?: Array<string | undefined | null> | undefined | null
    facebookUserId?: string | undefined | null
    firstName?: string | undefined | null
    followsWesponsored?: string | undefined | null
    fraudReason?: ResolverInputTypes['FraudReason'] | undefined | null
    fraudReviewAt?: string | undefined | null
    fraudReviewStatus?: ResolverInputTypes['FraudReviewStatus'] | undefined | null
    gender?: string | undefined | null
    hasTrueEngagement?: boolean | undefined | null
    id?: string | undefined | null
    idfaId?: string | undefined | null
    incompleteCampaignCount?: number | undefined | null
    instagramBio?: string | undefined | null
    instagramBusinessCategoryName?: string | undefined | null
    instagramCategoryName?: string | undefined | null
    instagramChecked?: string | undefined | null
    instagramContentTypeRating?: Array<ResolverInputTypes['ContentTypeRating']> | undefined | null
    instagramExternalLink?: string | undefined | null
    instagramFollowerCountryDistribution?:
      | Array<ResolverInputTypes['InstagramCountryDistributionItemInput']>
      | undefined
      | null
    instagramFollowerGenderAgeDistribution?:
      | ResolverInputTypes['InstagramGenderAgeDistributionInput']
      | undefined
      | null
    instagramFollowersInt?: number | undefined | null
    instagramHandle?: string | undefined | null
    instagramIsBusiness?: string | undefined | null
    instagramIsPrivate?: string | undefined | null
    instagramIsRecentlyJoined?: string | undefined | null
    instagramIsVerified?: string | undefined | null
    instagramLastChecked?: string | undefined | null
    instagramMedianShares?: number | undefined | null
    instagramMetricsRetrievedAt?: string | undefined | null
    instagramName?: string | undefined | null
    instagramPostsInt?: number | undefined | null
    instagramPriority?: ResolverInputTypes['PlatformPriority'] | undefined | null
    instagramProfilePic?: string | undefined | null
    instagramTrailingComments?: number | undefined | null
    instagramTrailingDateOfOldestPost?: string | undefined | null
    instagramTrailingLikes?: number | undefined | null
    instagramTrailingNumberOfPosts?: number | undefined | null
    instagramTrailingPostEngagementAvg?: number | undefined | null
    instagramTrailingPostEngagementRateAvg?: number | undefined | null
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined | null
    instagramTrailingPostEngagementRateMedian?: number | undefined | null
    instagramTrailingPostImpressionsAvg?: number | undefined | null
    instagramTrailingPostImpressionsMedian?: number | undefined | null
    instagramTrailingPostSponsoredPercentage?: number | undefined | null
    instagramTrailingPostViewsMedian?: number | undefined | null
    instagramUserId?: string | undefined | null
    instagramVerificationMethod?: ResolverInputTypes['SocialVerificationMethod'] | undefined | null
    internalGender?: ResolverInputTypes['UserGender'] | undefined | null
    isBrandSafe?: ResolverInputTypes['BrandSafe'] | undefined | null
    isBrandSafeReason?: string | undefined | null
    isBrandSafeUpdatedAt?: string | undefined | null
    lastActiveAt?: string | undefined | null
    lastName?: string | undefined | null
    manualScoringLockedUntil?: string | undefined | null
    moreinfo?: string | undefined | null
    numEvenDollarPurchases?: number | undefined | null
    numPurchases?: number | undefined | null
    paypalEmail?: string | undefined | null
    phoneNumber?: string | undefined | null
    profilePicture?: string | undefined | null
    pushAddresses?: Array<ResolverInputTypes['PushAddressInput']> | undefined | null
    pushOptOut?: boolean | undefined | null
    referralCode?: string | undefined | null
    referralCount?: number | undefined | null
    referrer?: string | undefined | null
    signupCountry?: string | undefined | null
    smsOptOut?: boolean | undefined | null
    socialQuality?: ResolverInputTypes['SocialQuality'] | undefined | null
    specialAttributes?: ResolverInputTypes['UserAttributesInput'] | undefined | null
    statusTier?: ResolverInputTypes['StatusTier'] | undefined | null
    statusTierHistory?: Array<ResolverInputTypes['StatusTierEntryInput']> | undefined | null
    statusTierLastUpdatedAt?: string | undefined | null
    supportPriorityAcknowledgedAt?: string | undefined | null
    supportPriorityFlag?: ResolverInputTypes['SupportPriorityFlag'] | undefined | null
    supportPriorityFlagSetAt?: string | undefined | null
    targetAccount?: string | undefined | null
    tiktokAccessToken?: string | undefined | null
    tiktokAccessTokenExpiry?: string | undefined | null
    tiktokAccountId?: string | undefined | null
    tiktokAveragePostEngagementRate?: number | undefined | null
    tiktokAveragePostEngagementRateViews?: number | undefined | null
    tiktokBio?: string | undefined | null
    tiktokContentTypeRating?: Array<ResolverInputTypes['ContentTypeRating']> | undefined | null
    tiktokFollowersInt?: number | undefined | null
    tiktokHandle?: string | undefined | null
    tiktokMedianComments?: number | undefined | null
    tiktokMedianLikes?: number | undefined | null
    tiktokMedianPostEngagement?: number | undefined | null
    tiktokMedianPostEngagementRate?: number | undefined | null
    tiktokMedianPostEngagementRateViews?: number | undefined | null
    tiktokMedianShares?: number | undefined | null
    tiktokMedianViews?: number | undefined | null
    tiktokMetricsRetrievedAt?: string | undefined | null
    tiktokName?: string | undefined | null
    tiktokPermissionScopes?: Array<string> | undefined | null
    tiktokPriority?: ResolverInputTypes['PlatformPriority'] | undefined | null
    tiktokProfilePic?: string | undefined | null
    tiktokRefreshToken?: string | undefined | null
    tiktokRefreshTokenExpiry?: string | undefined | null
    tiktokSponsoredPostPercentage?: number | undefined | null
    tiktokVerificationMethod?: ResolverInputTypes['SocialVerificationMethod'] | undefined | null
    tiktokVerified?: string | undefined | null
    timeZone?: string | undefined | null
    trackingStatus?: ResolverInputTypes['TrackingStatus'] | undefined | null
    twitchAccount?: string | undefined | null
    type: string
    utmReferral?: ResolverInputTypes['UtmReferralInput'] | undefined | null
    wmartAccount?: string | undefined | null
    youtubeAccessToken?: string | undefined | null
    youtubeAccessTokenExpiry?: string | undefined | null
    youtubeAccount?: string | undefined | null
    youtubeAveragePostEngagementRate?: number | undefined | null
    youtubeChannelDescription?: string | undefined | null
    youtubeChannelFollowersInt?: number | undefined | null
    youtubeChannelHandle?: string | undefined | null
    youtubeChannelId?: string | undefined | null
    youtubeChannelPicture?: string | undefined | null
    youtubeChannelTitle?: string | undefined | null
    youtubeIdToken?: string | undefined | null
    youtubeMedianViews?: number | undefined | null
    youtubeMetricsRetrievedAt?: string | undefined | null
    youtubePermissionScopes?: Array<string> | undefined | null
    youtubeRefreshToken?: string | undefined | null
    youtubeVerificationMethod?: ResolverInputTypes['SocialVerificationMethod'] | undefined | null
  }
  ['CreateUserLinkInput']: {
    campaignId?: string | undefined | null
    clicksPerPeriod?: Array<ResolverInputTypes['ClicksRecordsInput']> | undefined | null
    createdAt?: string | undefined | null
    id?: string | undefined | null
    linkId?: string | undefined | null
    originalLink: string
    rank?: number | undefined | null
    storageType?: ResolverInputTypes['LinkStorageType'] | undefined | null
    uniqueClicks: number
    updatedAt?: string | undefined | null
    userEncodedLink: string
    userId?: string | undefined | null
  }
  ['CreateUserNotificationPreferenceInput']: {
    category: string
    emailOptOut?: boolean | undefined | null
    pushOptOut?: boolean | undefined | null
    smsOptOut?: boolean | undefined | null
    userId: string
  }
  ['CreateUserPayoutRequestInput']: {
    failureReason?: string | undefined | null
    id?: string | undefined | null
    manuallyApprovedBy?: string | undefined | null
    payoutAmountCents?: number | undefined | null
    payoutBatchId?: string | undefined | null
    payoutDate?: string | undefined | null
    payoutLocale?: ResolverInputTypes['WSLocale'] | undefined | null
    payoutState: ResolverInputTypes['BonusPayoutState']
    userId: string
  }
  ['CreateUserStagingApprovalInput']: {
    id?: string | undefined | null
    reviewFeedback?: string | undefined | null
    reviewedAt?: string | undefined | null
    reviewedBy?: string | undefined | null
    reviewedByEmail?: string | undefined | null
    reviewedByName?: string | undefined | null
    reviewedBySource?: ResolverInputTypes['UserStagingApprovalReviewSource'] | undefined | null
    userCampaignId: string
    userStagingApprovalOutcome: ResolverInputTypes['UserStagingApprovalOutcome']
    userStagingApprovalType: ResolverInputTypes['UserStagingApprovalType']
  }
  ['CreateVideoContentScanInput']: {
    audioDuration?: number | undefined | null
    audioEndAt?: number | undefined | null
    audioStartFrom?: number | undefined | null
    contentSafetyAnalysis?: ResolverInputTypes['ContentSafetyAnalysisInput'] | undefined | null
    id?: string | undefined | null
    processFinishedAt?: string | undefined | null
    processId?: string | undefined | null
    processInitiatedAt?: string | undefined | null
    scanStatus: ResolverInputTypes['ContentScanStatus']
    socialMediaUrl?: string | undefined | null
    transcription?: string | undefined | null
    transcriptionConfidence?: number | undefined | null
    url: string
    userId: string
  }
  ['CreatorContentRatingRawValueInput']: {
    name: string
    value?: string | undefined | null
  }
  ['DeleteAutoBuyDistributionInput']: {
    id: string
  }
  ['DeleteAutoBuyFlowConfigurationInput']: {
    flowId: string
  }
  ['DeleteAutoBuyFlowReleaseScheduleInput']: {
    date: string
    flowId: string
  }
  ['DeleteAutoBuyReleaseInput']: {
    date: string
    userCampaignId: string
  }
  ['DeleteBrandApiInput']: {
    api: ResolverInputTypes['BrandApiType']
    brandId: string
  }
  ['DeleteBrandInput']: {
    id: string
  }
  ['DeleteCampaignClicksInput']: {
    campaignId: string
    date: string
  }
  ['DeleteCampaignInput']: {
    id: string
  }
  ['DeleteCampaignInviteInput']: {
    id: string
  }
  ['DeleteCampaignSourcingProspectInput']: {
    campaignId: string
    userIdentifier: string
  }
  ['DeleteChatConversationV2Input']: {
    chatType: ResolverInputTypes['ChatType']
    resourceId: string
  }
  ['DeleteCommunicationNotificationInput']: {
    id: string
  }
  ['DeleteCompanyInput']: {
    id: string
  }
  ['DeleteConnectedAccountInput']: {
    accountId: string
    userId: string
  }
  ['DeleteConversationInput']: {
    userId: string
  }
  ['DeleteConversationMessageInput']: {
    id: string
    userId: string
  }
  ['DeleteEtailerSkuMetricsInput']: {
    date: string
    skuId: string
  }
  ['DeleteFormSequenceConfigurationInput']: {
    id: string
  }
  ['DeleteInstagramStoryScreenInput']: {
    id: string
  }
  ['DeleteMessageInput']: {
    id: string
  }
  ['DeleteNoteInput']: {
    id: string
  }
  ['DeleteNotificationConfigurationInput']: {
    id: string
  }
  ['DeleteProductBonusConfigSnapshotInput']: {
    productId: string
    snapshotAt: string
  }
  ['DeleteProductInput']: {
    id: string
  }
  ['DeleteProductKeywordsInput']: {
    id: string
  }
  ['DeleteProductRatingSnapshotInput']: {
    skuId: string
    snapshotAt: string
  }
  ['DeletePurchaseInput']: {
    id: string
  }
  ['DeleteReferralInput']: {
    id: string
  }
  ['DeleteReviewInput']: {
    id: string
  }
  ['DeleteSkuInput']: {
    id: string
  }
  ['DeleteSkuSnapshotInput']: {
    marketplaceSku: string
    snapshotDate: string
  }
  ['DeleteUserBonusPayoutInput']: {
    id: string
  }
  ['DeleteUserCampaignChangelogInput']: {
    createdAt: string
    userCampaignId: string
  }
  ['DeleteUserCampaignInput']: {
    id: string
  }
  ['DeleteUserCampaignSnapshotInput']: {
    userCampaignId: string
    userCampaignVersion: number
  }
  ['DeleteUserCampaignSocialContentDraftHistoryInput']: {
    id: string
  }
  ['DeleteUserCampaignSocialContentInput']: {
    id: string
  }
  ['DeleteUserCampaignSocialContentReviewInput']: {
    reviewType: ResolverInputTypes['UserCampaignSocialContentReviewType']
    userCampaignSocialContentId: string
  }
  ['DeleteUserDeviceSnapshotInput']: {
    id: string
  }
  ['DeleteUserFollowerInput']: {
    followerUserId: string
    userId: string
  }
  ['DeleteUserInput']: {
    id: string
  }
  ['DeleteUserLinkInput']: {
    id: string
  }
  ['DeleteUserNotificationPreferenceInput']: {
    category: string
    userId: string
  }
  ['DeleteUserPayoutRequestInput']: {
    id: string
  }
  ['DeleteUserStagingApprovalInput']: {
    id: string
  }
  ['DeleteVideoContentScanInput']: {
    id: string
  }
  ['FollowersSearchFields']: {
    followerUserId?: ResolverInputTypes['SearchInputString'] | undefined | null
    isFavorite?: ResolverInputTypes['SearchInputString'] | undefined | null
  }
  ['FormSequenceComponentInput']: {
    componentName: string
    componentType: ResolverInputTypes['FormComponentType']
    content?: string | undefined | null
    id: string
    images?: Array<string> | undefined | null
    numbered?: boolean | undefined | null
    placeholder?: string | undefined | null
    type?: ResolverInputTypes['FormFieldType'] | undefined | null
    validation?: Array<ResolverInputTypes['FormValidationType']> | undefined | null
  }
  ['FormSequenceInput']: {
    components?: Array<ResolverInputTypes['FormSequenceComponentInput']> | undefined | null
    id: string
  }
  ['InspirationalContentInput']: {
    socialChannel?: ResolverInputTypes['SocialChannel'] | undefined | null
    type: ResolverInputTypes['InspirationalContentType']
    url: string
  }
  ['InstagramCountryDistributionItemInput']: {
    count: number
    countryCode: string
    pctDistribution: number
  }
  ['InstagramFollowerCountryDistributionSearchFields']: {
    countryCode: ResolverInputTypes['SearchInputString']
    pctDistribution: ResolverInputTypes['SearchInputNumber']
  }
  ['InstagramGenderAgeDistributionInput']: {
    femaleCount: number
    genderAgeDistribution?: Array<ResolverInputTypes['InstagramGenderAgeDistributionItemInput']> | undefined | null
    maleCount: number
    unspecifiedCount: number
  }
  ['InstagramGenderAgeDistributionItemInput']: {
    ageRangeEnd?: number | undefined | null
    ageRangeStart: number
    count: number
    gender: ResolverInputTypes['InstagramGenderAgeDistributionGender']
  }
  ['InviteUsersToCampaignInput']: {
    campaignId: string
    userIds: Array<string>
  }
  ['KeyAttributeInput']: {
    key: string
    value: string
  }
  ['MessagingChannelConfigurationInput']: {
    attributes?: Array<ResolverInputTypes['KeyAttributeInput']> | undefined | null
    enabled: boolean
    templateContent: string
  }
  ['MinMaxRangeInput']: {
    max?: number | undefined | null
    min?: number | undefined | null
  }
  ['ModelActiveInput']: {
    eq?: ResolverInputTypes['Active'] | undefined | null
    ne?: ResolverInputTypes['Active'] | undefined | null
  }
  ['ModelAmazonReviewSubmissionStatusInput']: {
    eq?: ResolverInputTypes['AmazonReviewSubmissionStatus'] | undefined | null
    ne?: ResolverInputTypes['AmazonReviewSubmissionStatus'] | undefined | null
  }
  ['ModelApplicationStatusInput']: {
    eq?: ResolverInputTypes['ApplicationStatus'] | undefined | null
    ne?: ResolverInputTypes['ApplicationStatus'] | undefined | null
  }
  ['ModelApprovalOutcomeInput']: {
    eq?: ResolverInputTypes['ApprovalOutcome'] | undefined | null
    ne?: ResolverInputTypes['ApprovalOutcome'] | undefined | null
  }
  ['ModelArchivedReasonInput']: {
    eq?: ResolverInputTypes['ArchivedReason'] | undefined | null
    ne?: ResolverInputTypes['ArchivedReason'] | undefined | null
  }
  ['ModelAutoBuyDelayStrategyInput']: {
    eq?: ResolverInputTypes['AutoBuyDelayStrategy'] | undefined | null
    ne?: ResolverInputTypes['AutoBuyDelayStrategy'] | undefined | null
  }
  ['ModelAutoBuyDistributionConditionInput']: {
    and?: Array<ResolverInputTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    description?: ResolverInputTypes['ModelStringInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelAutoBuyDistributionConditionInput'] | undefined | null> | undefined | null
    schedule?: ResolverInputTypes['ModelIntInput'] | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelAutoBuyDistributionFilterInput']: {
    and?: Array<ResolverInputTypes['ModelAutoBuyDistributionFilterInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    description?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelAutoBuyDistributionFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelAutoBuyDistributionFilterInput'] | undefined | null> | undefined | null
    schedule?: ResolverInputTypes['ModelIntInput'] | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelAutoBuyFlowConfigurationConditionInput']: {
    and?: Array<ResolverInputTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null> | undefined | null
    dailyPurchaseMax?: ResolverInputTypes['ModelIntInput'] | undefined | null
    delayStrategy?: ResolverInputTypes['ModelAutoBuyDelayStrategyInput'] | undefined | null
    insufficientCreators?: ResolverInputTypes['ModelStringInput'] | undefined | null
    latestSkipReason?: ResolverInputTypes['ModelAutoBuySkipReasonInput'] | undefined | null
    not?: ResolverInputTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined | null> | undefined | null
    pauseReason?: ResolverInputTypes['ModelAutoBuySkipReasonInput'] | undefined | null
    startDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    status?: ResolverInputTypes['ModelAutoBuyStatusInput'] | undefined | null
  }
  ['ModelAutoBuyFlowConfigurationFilterInput']: {
    and?: Array<ResolverInputTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null> | undefined | null
    dailyPurchaseMax?: ResolverInputTypes['ModelIntInput'] | undefined | null
    delayStrategy?: ResolverInputTypes['ModelAutoBuyDelayStrategyInput'] | undefined | null
    flowId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    insufficientCreators?: ResolverInputTypes['ModelStringInput'] | undefined | null
    latestSkipReason?: ResolverInputTypes['ModelAutoBuySkipReasonInput'] | undefined | null
    not?: ResolverInputTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined | null> | undefined | null
    pauseReason?: ResolverInputTypes['ModelAutoBuySkipReasonInput'] | undefined | null
    startDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    status?: ResolverInputTypes['ModelAutoBuyStatusInput'] | undefined | null
  }
  ['ModelAutoBuyFlowReleaseScheduleConditionInput']: {
    actualPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    actualVerifiedPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    adjustedDesiredPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?:
      | Array<ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null>
      | undefined
      | null
    autoApprovalSeats?: ResolverInputTypes['ModelIntInput'] | undefined | null
    desiredPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    manualPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    manualReleases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null
    or?:
      | Array<ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined | null>
      | undefined
      | null
    projectedReleaseCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    releaseCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    released?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    skippedReason?: ResolverInputTypes['ModelAutoBuySkipReasonInput'] | undefined | null
  }
  ['ModelAutoBuyFlowReleaseScheduleFilterInput']: {
    actualPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    actualVerifiedPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    adjustedDesiredPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null> | undefined | null
    autoApprovalSeats?: ResolverInputTypes['ModelIntInput'] | undefined | null
    date?: ResolverInputTypes['ModelStringInput'] | undefined | null
    desiredPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    flowId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    manualPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    manualReleases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined | null> | undefined | null
    projectedReleaseCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    releaseCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    released?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    skippedReason?: ResolverInputTypes['ModelAutoBuySkipReasonInput'] | undefined | null
  }
  ['ModelAutoBuyReleaseConditionInput']: {
    and?: Array<ResolverInputTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null> | undefined | null
    not?: ResolverInputTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelAutoBuyReleaseConditionInput'] | undefined | null> | undefined | null
    productId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    releaseDateTime?: ResolverInputTypes['ModelStringInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelAutoBuyReleaseFilterInput']: {
    and?: Array<ResolverInputTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null> | undefined | null
    date?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelAutoBuyReleaseFilterInput'] | undefined | null> | undefined | null
    productId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    releaseDateTime?: ResolverInputTypes['ModelStringInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelAutoBuySkipReasonInput']: {
    eq?: ResolverInputTypes['AutoBuySkipReason'] | undefined | null
    ne?: ResolverInputTypes['AutoBuySkipReason'] | undefined | null
  }
  ['ModelAutoBuyStatusInput']: {
    eq?: ResolverInputTypes['AutoBuyStatus'] | undefined | null
    ne?: ResolverInputTypes['AutoBuyStatus'] | undefined | null
  }
  ['ModelBOPISValueInput']: {
    eq?: ResolverInputTypes['BOPISValue'] | undefined | null
    ne?: ResolverInputTypes['BOPISValue'] | undefined | null
  }
  ['ModelBonusPayoutStateInput']: {
    eq?: ResolverInputTypes['BonusPayoutState'] | undefined | null
    ne?: ResolverInputTypes['BonusPayoutState'] | undefined | null
  }
  ['ModelBonusPayoutTypesInput']: {
    eq?: ResolverInputTypes['BonusPayoutTypes'] | undefined | null
    ne?: ResolverInputTypes['BonusPayoutTypes'] | undefined | null
  }
  ['ModelBooleanInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ResolverInputTypes['ModelAttributeTypes'] | undefined | null
    eq?: boolean | undefined | null
    ne?: boolean | undefined | null
  }
  ['ModelBrandApiConditionInput']: {
    accessToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelBrandApiConditionInput'] | undefined | null> | undefined | null
    apiVersion?: ResolverInputTypes['ModelBrandApiVersionInput'] | undefined | null
    enabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    locale?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    not?: ResolverInputTypes['ModelBrandApiConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelBrandApiConditionInput'] | undefined | null> | undefined | null
    refreshToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shop?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shopId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelBrandApiFilterInput']: {
    accessToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelBrandApiFilterInput'] | undefined | null> | undefined | null
    api?: ResolverInputTypes['ModelBrandApiTypeInput'] | undefined | null
    apiVersion?: ResolverInputTypes['ModelBrandApiVersionInput'] | undefined | null
    brandId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    enabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    locale?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    not?: ResolverInputTypes['ModelBrandApiFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelBrandApiFilterInput'] | undefined | null> | undefined | null
    refreshToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shop?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shopId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelBrandApiTypeInput']: {
    eq?: ResolverInputTypes['BrandApiType'] | undefined | null
    ne?: ResolverInputTypes['BrandApiType'] | undefined | null
  }
  ['ModelBrandApiVersionInput']: {
    eq?: ResolverInputTypes['BrandApiVersion'] | undefined | null
    ne?: ResolverInputTypes['BrandApiVersion'] | undefined | null
  }
  ['ModelBrandConditionInput']: {
    active?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amazonBrandName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelBrandConditionInput'] | undefined | null> | undefined | null
    brandDetailsSubmitted?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    brandInstaHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandLogoImageKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreConfirmationImage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreConfirmationMobileImage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreScrapeUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandTikTokHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    companyId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    contactEmails?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultCreatorPreference?: ResolverInputTypes['ModelCreatorPreferenceInput'] | undefined | null
    defaultCreatorPreferenceDetails?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultHashtags?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultMarketingBriefKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultPostingGuidelines?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultPostingInspirationLinks?: ResolverInputTypes['ModelStringInput'] | undefined | null
    emailsSent?: ResolverInputTypes['ModelStringInput'] | undefined | null
    fullScrapedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isBrandStoreScrapeEnabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isSeller?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isVendor?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isWalmartScrapeEnabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    lastScrapedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    nameAudioKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelBrandConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelBrandConditionInput'] | undefined | null> | undefined | null
    platform?: ResolverInputTypes['ModelPlatformSourceInput'] | undefined | null
    postingGuidanceDocument?: ResolverInputTypes['ModelStringInput'] | undefined | null
    region?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    socialMediaExampleUrls?: ResolverInputTypes['ModelStringInput'] | undefined | null
    subscriptionEndsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    subscriptionStartsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    walmartBrandName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    walmartLastScrapedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    walmartScrapeUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelBrandFilterInput']: {
    active?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amazonBrandName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelBrandFilterInput'] | undefined | null> | undefined | null
    brandDetailsSubmitted?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    brandInstaHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandLogoImageKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreConfirmationImage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreConfirmationMobileImage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreScrapeUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandTikTokHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    companyId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    contactEmails?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultCreatorPreference?: ResolverInputTypes['ModelCreatorPreferenceInput'] | undefined | null
    defaultCreatorPreferenceDetails?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultHashtags?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultMarketingBriefKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultPostingGuidelines?: ResolverInputTypes['ModelStringInput'] | undefined | null
    defaultPostingInspirationLinks?: ResolverInputTypes['ModelStringInput'] | undefined | null
    emailsSent?: ResolverInputTypes['ModelStringInput'] | undefined | null
    fullScrapedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    isBrandStoreScrapeEnabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isSeller?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isVendor?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isWalmartScrapeEnabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    lastScrapedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    nameAudioKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelBrandFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelBrandFilterInput'] | undefined | null> | undefined | null
    platform?: ResolverInputTypes['ModelPlatformSourceInput'] | undefined | null
    postingGuidanceDocument?: ResolverInputTypes['ModelStringInput'] | undefined | null
    region?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    socialMediaExampleUrls?: ResolverInputTypes['ModelStringInput'] | undefined | null
    subscriptionEndsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    subscriptionStartsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    walmartBrandName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    walmartLastScrapedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    walmartScrapeUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelBrandSafeInput']: {
    eq?: ResolverInputTypes['BrandSafe'] | undefined | null
    ne?: ResolverInputTypes['BrandSafe'] | undefined | null
  }
  ['ModelCampaignClicksConditionInput']: {
    and?: Array<ResolverInputTypes['ModelCampaignClicksConditionInput'] | undefined | null> | undefined | null
    clicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelCampaignClicksConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCampaignClicksConditionInput'] | undefined | null> | undefined | null
  }
  ['ModelCampaignClicksFilterInput']: {
    and?: Array<ResolverInputTypes['ModelCampaignClicksFilterInput'] | undefined | null> | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    clicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    date?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelCampaignClicksFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCampaignClicksFilterInput'] | undefined | null> | undefined | null
  }
  ['ModelCampaignConditionInput']: {
    aboutSection?: ResolverInputTypes['ModelStringInput'] | undefined | null
    active?: ResolverInputTypes['ModelStringInput'] | undefined | null
    actualStartDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amountPaidByClient?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelCampaignConditionInput'] | undefined | null> | undefined | null
    anticipatedStartDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    applicationForm?: ResolverInputTypes['ModelStringInput'] | undefined | null
    applicationProductLinkId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    applicationStatus?: ResolverInputTypes['ModelApplicationStatusInput'] | undefined | null
    attributionRefUrlSuffix?: ResolverInputTypes['ModelStringInput'] | undefined | null
    authorizedUnits?: ResolverInputTypes['ModelIntInput'] | undefined | null
    brandId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    brandProductShort?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    budgetSpent?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignProductType?: ResolverInputTypes['ModelCampaignProductTypeInput'] | undefined | null
    campaignReferralBonusAmount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignReferralBonusEnabled?: ResolverInputTypes['ModelCampaignReferralBonusFlagInput'] | undefined | null
    campaignStateMachine?: ResolverInputTypes['ModelStringInput'] | undefined | null
    closeoutReleaseFailureReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    commissionFlatRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    commissionPercentageRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    committedClicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    committedPlacementWinsCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    committedReviewsCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentApprovalRequired?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    contentCompleteDeadline?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    creationSourceVersion?: ResolverInputTypes['ModelCreationSourceVersionInput'] | undefined | null
    earlyReviewEnabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    enableContentComplianceReview?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    endDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    excludeGender?: ResolverInputTypes['ModelUserFilterGenderInput'] | undefined | null
    expectedBonus?: ResolverInputTypes['ModelStringInput'] | undefined | null
    followersCountCeiling?: ResolverInputTypes['ModelIntInput'] | undefined | null
    followersCountFloor?: ResolverInputTypes['ModelIntInput'] | undefined | null
    goal?: ResolverInputTypes['ModelCampaignGoalInput'] | undefined | null
    influencerApprovalEnabled?: ResolverInputTypes['ModelInfluencerApprovalFlagV2Input'] | undefined | null
    lifestyleCommittedContentCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    localeV1?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    marketingFee?: ResolverInputTypes['ModelIntInput'] | undefined | null
    marketingFormFilled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    newCreatorsOnly?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    not?: ResolverInputTypes['ModelCampaignConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCampaignConditionInput'] | undefined | null> | undefined | null
    payForClicksEnabled?: ResolverInputTypes['ModelEnabledFlagInput'] | undefined | null
    payForClicksMinClicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payForClicksOriginalLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payForClicksTotalClicksGenerated?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payForPerformanceEnabled?: ResolverInputTypes['ModelPayForPerformanceFlagInput'] | undefined | null
    postingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    preApplicationProductImage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    preApplicationProductPriceRange?: ResolverInputTypes['ModelStringInput'] | undefined | null
    preApplicationUgcProductImage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    preLaunchDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    premiumLifestyleCommittedContentCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    requiredProductLinksEnabled?: ResolverInputTypes['ModelRequiredProductLinkFlagInput'] | undefined | null
    reviewVotingTarget?: ResolverInputTypes['ModelStringInput'] | undefined | null
    sample?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    shellCampaignBase?: ResolverInputTypes['ModelIDInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    skuPriceOverride?: ResolverInputTypes['ModelIntInput'] | undefined | null
    socialCommittedContentCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    sourcingPriority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    sponsorshipInstructions?: ResolverInputTypes['ModelStringInput'] | undefined | null
    title?: ResolverInputTypes['ModelStringInput'] | undefined | null
    totalEarlyReviewsAllowed?: ResolverInputTypes['ModelIntInput'] | undefined | null
    totalProductCost?: ResolverInputTypes['ModelIntInput'] | undefined | null
    userAutoApproved?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    visibleToUser?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignFilterInput']: {
    aboutSection?: ResolverInputTypes['ModelStringInput'] | undefined | null
    active?: ResolverInputTypes['ModelStringInput'] | undefined | null
    actualStartDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amountPaidByClient?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null> | undefined | null
    anticipatedStartDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    applicationForm?: ResolverInputTypes['ModelStringInput'] | undefined | null
    applicationProductLinkId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    applicationStatus?: ResolverInputTypes['ModelApplicationStatusInput'] | undefined | null
    attributionRefUrlSuffix?: ResolverInputTypes['ModelStringInput'] | undefined | null
    authorizedUnits?: ResolverInputTypes['ModelIntInput'] | undefined | null
    brandId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    brandProductShort?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    budgetSpent?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignProductType?: ResolverInputTypes['ModelCampaignProductTypeInput'] | undefined | null
    campaignReferralBonusAmount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignReferralBonusEnabled?: ResolverInputTypes['ModelCampaignReferralBonusFlagInput'] | undefined | null
    campaignStateMachine?: ResolverInputTypes['ModelStringInput'] | undefined | null
    closeoutReleaseFailureReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    commissionFlatRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    commissionPercentageRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    committedClicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    committedPlacementWinsCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    committedReviewsCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentApprovalRequired?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    contentCompleteDeadline?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    creationSourceVersion?: ResolverInputTypes['ModelCreationSourceVersionInput'] | undefined | null
    earlyReviewEnabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    enableContentComplianceReview?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    endDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    excludeGender?: ResolverInputTypes['ModelUserFilterGenderInput'] | undefined | null
    expectedBonus?: ResolverInputTypes['ModelStringInput'] | undefined | null
    followersCountCeiling?: ResolverInputTypes['ModelIntInput'] | undefined | null
    followersCountFloor?: ResolverInputTypes['ModelIntInput'] | undefined | null
    goal?: ResolverInputTypes['ModelCampaignGoalInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    influencerApprovalEnabled?: ResolverInputTypes['ModelInfluencerApprovalFlagV2Input'] | undefined | null
    lifestyleCommittedContentCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    localeV1?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    marketingFee?: ResolverInputTypes['ModelIntInput'] | undefined | null
    marketingFormFilled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    newCreatorsOnly?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    not?: ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCampaignFilterInput'] | undefined | null> | undefined | null
    payForClicksEnabled?: ResolverInputTypes['ModelEnabledFlagInput'] | undefined | null
    payForClicksMinClicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payForClicksOriginalLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payForClicksTotalClicksGenerated?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payForPerformanceEnabled?: ResolverInputTypes['ModelPayForPerformanceFlagInput'] | undefined | null
    postingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    preApplicationProductImage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    preApplicationProductPriceRange?: ResolverInputTypes['ModelStringInput'] | undefined | null
    preApplicationUgcProductImage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    preLaunchDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    premiumLifestyleCommittedContentCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    requiredProductLinksEnabled?: ResolverInputTypes['ModelRequiredProductLinkFlagInput'] | undefined | null
    reviewVotingTarget?: ResolverInputTypes['ModelStringInput'] | undefined | null
    sample?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    shellCampaignBase?: ResolverInputTypes['ModelIDInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    skuPriceOverride?: ResolverInputTypes['ModelIntInput'] | undefined | null
    socialCommittedContentCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    sourcingPriority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    sponsorshipInstructions?: ResolverInputTypes['ModelStringInput'] | undefined | null
    title?: ResolverInputTypes['ModelStringInput'] | undefined | null
    totalEarlyReviewsAllowed?: ResolverInputTypes['ModelIntInput'] | undefined | null
    totalProductCost?: ResolverInputTypes['ModelIntInput'] | undefined | null
    userAutoApproved?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    visibleToUser?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignGoalInput']: {
    eq?: ResolverInputTypes['CampaignGoal'] | undefined | null
    ne?: ResolverInputTypes['CampaignGoal'] | undefined | null
  }
  ['ModelCampaignInviteConditionInput']: {
    and?: Array<ResolverInputTypes['ModelCampaignInviteConditionInput'] | undefined | null> | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelCampaignInviteConditionInput'] | undefined | null
    notificationStatus?: ResolverInputTypes['ModelNotificationStatusInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCampaignInviteConditionInput'] | undefined | null> | undefined | null
    priority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignInviteFilterInput']: {
    and?: Array<ResolverInputTypes['ModelCampaignInviteFilterInput'] | undefined | null> | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelCampaignInviteFilterInput'] | undefined | null
    notificationStatus?: ResolverInputTypes['ModelNotificationStatusInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCampaignInviteFilterInput'] | undefined | null> | undefined | null
    priority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelCampaignProductTypeInput']: {
    eq?: ResolverInputTypes['CampaignProductType'] | undefined | null
    ne?: ResolverInputTypes['CampaignProductType'] | undefined | null
  }
  ['ModelCampaignReferralBonusFlagInput']: {
    eq?: ResolverInputTypes['CampaignReferralBonusFlag'] | undefined | null
    ne?: ResolverInputTypes['CampaignReferralBonusFlag'] | undefined | null
  }
  ['ModelCampaignSourcingProspectConditionInput']: {
    and?: Array<ResolverInputTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdById?: ResolverInputTypes['ModelIDInput'] | undefined | null
    instagramFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalNotesId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCampaignSourcingProspectConditionInput'] | undefined | null> | undefined | null
    platformInviteSource?: ResolverInputTypes['ModelPlatformInviteSourceInput'] | undefined | null
    platformStatus?: ResolverInputTypes['ModelPlatformStatusInput'] | undefined | null
    preApprovalStatus?: ResolverInputTypes['ModelPreApprovalStatusInput'] | undefined | null
    preApprovalStatusById?: ResolverInputTypes['ModelIDInput'] | undefined | null
    priority?: ResolverInputTypes['ModelSourcePriorityInput'] | undefined | null
    productId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    sourcingSource?: ResolverInputTypes['ModelCampaignSourcingSourceInput'] | undefined | null
    tiktokFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    youtubeChannelFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    youtubeChannelHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignSourcingProspectFilterInput']: {
    and?: Array<ResolverInputTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null> | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdById?: ResolverInputTypes['ModelIDInput'] | undefined | null
    instagramFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalNotesId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCampaignSourcingProspectFilterInput'] | undefined | null> | undefined | null
    platformInviteSource?: ResolverInputTypes['ModelPlatformInviteSourceInput'] | undefined | null
    platformStatus?: ResolverInputTypes['ModelPlatformStatusInput'] | undefined | null
    preApprovalStatus?: ResolverInputTypes['ModelPreApprovalStatusInput'] | undefined | null
    preApprovalStatusById?: ResolverInputTypes['ModelIDInput'] | undefined | null
    priority?: ResolverInputTypes['ModelSourcePriorityInput'] | undefined | null
    productId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    sourcingSource?: ResolverInputTypes['ModelCampaignSourcingSourceInput'] | undefined | null
    tiktokFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userIdentifier?: ResolverInputTypes['ModelIDInput'] | undefined | null
    youtubeChannelFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    youtubeChannelHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCampaignSourcingSourceInput']: {
    eq?: ResolverInputTypes['CampaignSourcingSource'] | undefined | null
    ne?: ResolverInputTypes['CampaignSourcingSource'] | undefined | null
  }
  ['ModelCampaignStatusInput']: {
    eq?: ResolverInputTypes['CampaignStatus'] | undefined | null
    ne?: ResolverInputTypes['CampaignStatus'] | undefined | null
  }
  ['ModelChannelInput']: {
    eq?: ResolverInputTypes['Channel'] | undefined | null
    ne?: ResolverInputTypes['Channel'] | undefined | null
  }
  ['ModelChatConversationV2ConditionInput']: {
    active?: ResolverInputTypes['ModelActiveInput'] | undefined | null
    adminLastReadMessageIndex?: ResolverInputTypes['ModelIntInput'] | undefined | null
    adminUnreadCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelChatConversationV2ConditionInput'] | undefined | null> | undefined | null
    campaignId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    creatorLastReadMessageIndex?: ResolverInputTypes['ModelIntInput'] | undefined | null
    creatorUnreadCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    lastMessage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastMessageDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastMessageId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastMessageSource?: ResolverInputTypes['ModelConversationMessageSourceInput'] | undefined | null
    not?: ResolverInputTypes['ModelChatConversationV2ConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelChatConversationV2ConditionInput'] | undefined | null> | undefined | null
    status?: ResolverInputTypes['ModelConversationInternalStatusInput'] | undefined | null
    statusUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    statusUpdatedByFirstName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    statusUpdatedById?: ResolverInputTypes['ModelStringInput'] | undefined | null
    statusUpdatedByLastName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    twilioConversationId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userEmail?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userFirstName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userLastName?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelChatConversationV2FilterInput']: {
    active?: ResolverInputTypes['ModelActiveInput'] | undefined | null
    adminLastReadMessageIndex?: ResolverInputTypes['ModelIntInput'] | undefined | null
    adminUnreadCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelChatConversationV2FilterInput'] | undefined | null> | undefined | null
    campaignId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    chatType?: ResolverInputTypes['ModelChatTypeInput'] | undefined | null
    creatorLastReadMessageIndex?: ResolverInputTypes['ModelIntInput'] | undefined | null
    creatorUnreadCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    lastMessage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastMessageDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastMessageId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastMessageSource?: ResolverInputTypes['ModelConversationMessageSourceInput'] | undefined | null
    not?: ResolverInputTypes['ModelChatConversationV2FilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelChatConversationV2FilterInput'] | undefined | null> | undefined | null
    resourceId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    status?: ResolverInputTypes['ModelConversationInternalStatusInput'] | undefined | null
    statusUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    statusUpdatedByFirstName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    statusUpdatedById?: ResolverInputTypes['ModelStringInput'] | undefined | null
    statusUpdatedByLastName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    twilioConversationId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userEmail?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userFirstName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userLastName?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelChatTypeInput']: {
    eq?: ResolverInputTypes['ChatType'] | undefined | null
    ne?: ResolverInputTypes['ChatType'] | undefined | null
  }
  ['ModelCoarseCampaignStatusInput']: {
    eq?: ResolverInputTypes['CoarseCampaignStatus'] | undefined | null
    ne?: ResolverInputTypes['CoarseCampaignStatus'] | undefined | null
  }
  ['ModelCommunicationNotificationConditionInput']: {
    address?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?:
      | Array<ResolverInputTypes['ModelCommunicationNotificationConditionInput'] | undefined | null>
      | undefined
      | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    channel?: ResolverInputTypes['ModelChannelInput'] | undefined | null
    expectedSentAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    experimentTreatment?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelCommunicationNotificationConditionInput'] | undefined | null
    notificationId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCommunicationNotificationConditionInput'] | undefined | null> | undefined | null
    sentAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    status?: ResolverInputTypes['ModelStringInput'] | undefined | null
    statusMessage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    templateId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    traceId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelCommunicationNotificationFilterInput']: {
    address?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelCommunicationNotificationFilterInput'] | undefined | null> | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    channel?: ResolverInputTypes['ModelChannelInput'] | undefined | null
    expectedSentAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    experimentTreatment?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelCommunicationNotificationFilterInput'] | undefined | null
    notificationId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCommunicationNotificationFilterInput'] | undefined | null> | undefined | null
    sentAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    status?: ResolverInputTypes['ModelStringInput'] | undefined | null
    statusMessage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    templateId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    traceId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelCompanyConditionInput']: {
    agencyId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelCompanyConditionInput'] | undefined | null> | undefined | null
    logo?: ResolverInputTypes['ModelStringInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelCompanyConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCompanyConditionInput'] | undefined | null> | undefined | null
    paymentTermsCustomNetDays?: ResolverInputTypes['ModelIntInput'] | undefined | null
    paymentTermsType?: ResolverInputTypes['ModelPaymentTermsTypeInput'] | undefined | null
    subscriptionEndsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    subscriptionStartsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    websiteUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelCompanyFilterInput']: {
    agencyId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelCompanyFilterInput'] | undefined | null> | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    logo?: ResolverInputTypes['ModelStringInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelCompanyFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelCompanyFilterInput'] | undefined | null> | undefined | null
    paymentTermsCustomNetDays?: ResolverInputTypes['ModelIntInput'] | undefined | null
    paymentTermsType?: ResolverInputTypes['ModelPaymentTermsTypeInput'] | undefined | null
    subscriptionEndsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    subscriptionStartsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    websiteUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelConnectedAccountConditionInput']: {
    accountType?: ResolverInputTypes['ModelConnectedAccountTypeInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelConnectedAccountConditionInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelConnectedAccountConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelConnectedAccountConditionInput'] | undefined | null> | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelConnectedAccountFilterInput']: {
    accountId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    accountType?: ResolverInputTypes['ModelConnectedAccountTypeInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelConnectedAccountFilterInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelConnectedAccountFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelConnectedAccountFilterInput'] | undefined | null> | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelConnectedAccountTypeInput']: {
    eq?: ResolverInputTypes['ConnectedAccountType'] | undefined | null
    ne?: ResolverInputTypes['ConnectedAccountType'] | undefined | null
  }
  ['ModelContentApprovalStatusInput']: {
    eq?: ResolverInputTypes['ContentApprovalStatus'] | undefined | null
    ne?: ResolverInputTypes['ContentApprovalStatus'] | undefined | null
  }
  ['ModelContentComplianceReviewStatusInput']: {
    eq?: ResolverInputTypes['ContentComplianceReviewStatus'] | undefined | null
    ne?: ResolverInputTypes['ContentComplianceReviewStatus'] | undefined | null
  }
  ['ModelContentRatingReviewStatusInput']: {
    eq?: ResolverInputTypes['ContentRatingReviewStatus'] | undefined | null
    ne?: ResolverInputTypes['ContentRatingReviewStatus'] | undefined | null
  }
  ['ModelContentRequirementLevelInput']: {
    eq?: ResolverInputTypes['ContentRequirementLevel'] | undefined | null
    ne?: ResolverInputTypes['ContentRequirementLevel'] | undefined | null
  }
  ['ModelContentScanStatusInput']: {
    eq?: ResolverInputTypes['ContentScanStatus'] | undefined | null
    ne?: ResolverInputTypes['ContentScanStatus'] | undefined | null
  }
  ['ModelContentTypeRatingListInput']: {
    contains?: ResolverInputTypes['ContentTypeRating'] | undefined | null
    eq?: Array<ResolverInputTypes['ContentTypeRating'] | undefined | null> | undefined | null
    ne?: Array<ResolverInputTypes['ContentTypeRating'] | undefined | null> | undefined | null
    notContains?: ResolverInputTypes['ContentTypeRating'] | undefined | null
  }
  ['ModelConversationChannelInput']: {
    eq?: ResolverInputTypes['ConversationChannel'] | undefined | null
    ne?: ResolverInputTypes['ConversationChannel'] | undefined | null
  }
  ['ModelConversationConditionInput']: {
    and?: Array<ResolverInputTypes['ModelConversationConditionInput'] | undefined | null> | undefined | null
    contactOwnerId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    lastContactAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelConversationConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelConversationConditionInput'] | undefined | null> | undefined | null
    status?: ResolverInputTypes['ModelConversationStatusInput'] | undefined | null
    statusChangedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelConversationFilterInput']: {
    and?: Array<ResolverInputTypes['ModelConversationFilterInput'] | undefined | null> | undefined | null
    contactOwnerId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    lastContactAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelConversationFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelConversationFilterInput'] | undefined | null> | undefined | null
    status?: ResolverInputTypes['ModelConversationStatusInput'] | undefined | null
    statusChangedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelConversationInternalStatusInput']: {
    eq?: ResolverInputTypes['ConversationInternalStatus'] | undefined | null
    ne?: ResolverInputTypes['ConversationInternalStatus'] | undefined | null
  }
  ['ModelConversationMessageConditionInput']: {
    and?: Array<ResolverInputTypes['ModelConversationMessageConditionInput'] | undefined | null> | undefined | null
    channel?: ResolverInputTypes['ModelConversationChannelInput'] | undefined | null
    messageContent?: ResolverInputTypes['ModelStringInput'] | undefined | null
    messageId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    messageMediaKeys?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelConversationMessageConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelConversationMessageConditionInput'] | undefined | null> | undefined | null
    replyFromId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    sendAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    sendAttempts?: ResolverInputTypes['ModelIntInput'] | undefined | null
    sentAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    status?: ResolverInputTypes['ModelConversationMessageStatusInput'] | undefined | null
  }
  ['ModelConversationMessageFilterInput']: {
    and?: Array<ResolverInputTypes['ModelConversationMessageFilterInput'] | undefined | null> | undefined | null
    channel?: ResolverInputTypes['ModelConversationChannelInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    messageContent?: ResolverInputTypes['ModelStringInput'] | undefined | null
    messageId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    messageMediaKeys?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelConversationMessageFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelConversationMessageFilterInput'] | undefined | null> | undefined | null
    replyFromId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    sendAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    sendAttempts?: ResolverInputTypes['ModelIntInput'] | undefined | null
    sentAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    status?: ResolverInputTypes['ModelConversationMessageStatusInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelConversationMessageSourceInput']: {
    eq?: ResolverInputTypes['ConversationMessageSource'] | undefined | null
    ne?: ResolverInputTypes['ConversationMessageSource'] | undefined | null
  }
  ['ModelConversationMessageStatusInput']: {
    eq?: ResolverInputTypes['ConversationMessageStatus'] | undefined | null
    ne?: ResolverInputTypes['ConversationMessageStatus'] | undefined | null
  }
  ['ModelConversationStatusInput']: {
    eq?: ResolverInputTypes['ConversationStatus'] | undefined | null
    ne?: ResolverInputTypes['ConversationStatus'] | undefined | null
  }
  ['ModelCreationSourceVersionInput']: {
    eq?: ResolverInputTypes['CreationSourceVersion'] | undefined | null
    ne?: ResolverInputTypes['CreationSourceVersion'] | undefined | null
  }
  ['ModelCreatorPreferenceInput']: {
    eq?: ResolverInputTypes['CreatorPreference'] | undefined | null
    ne?: ResolverInputTypes['CreatorPreference'] | undefined | null
  }
  ['ModelCurrencyInput']: {
    eq?: ResolverInputTypes['Currency'] | undefined | null
    ne?: ResolverInputTypes['Currency'] | undefined | null
  }
  ['ModelDeranked_ReasonInput']: {
    eq?: ResolverInputTypes['Deranked_Reason'] | undefined | null
    ne?: ResolverInputTypes['Deranked_Reason'] | undefined | null
  }
  ['ModelDraftStatusInput']: {
    eq?: ResolverInputTypes['DraftStatus'] | undefined | null
    ne?: ResolverInputTypes['DraftStatus'] | undefined | null
  }
  ['ModelEnabledFlagInput']: {
    eq?: ResolverInputTypes['EnabledFlag'] | undefined | null
    ne?: ResolverInputTypes['EnabledFlag'] | undefined | null
  }
  ['ModelEtailerSkuMetricsConditionInput']: {
    and?: Array<ResolverInputTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null> | undefined | null
    not?: ResolverInputTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelEtailerSkuMetricsConditionInput'] | undefined | null> | undefined | null
    pageViews?: ResolverInputTypes['ModelIntInput'] | undefined | null
    sku?: ResolverInputTypes['ModelStringInput'] | undefined | null
    totalSalesAmount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    unitsSold?: ResolverInputTypes['ModelIntInput'] | undefined | null
  }
  ['ModelEtailerSkuMetricsFilterInput']: {
    and?: Array<ResolverInputTypes['ModelEtailerSkuMetricsFilterInput'] | undefined | null> | undefined | null
    date?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelEtailerSkuMetricsFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelEtailerSkuMetricsFilterInput'] | undefined | null> | undefined | null
    pageViews?: ResolverInputTypes['ModelIntInput'] | undefined | null
    sku?: ResolverInputTypes['ModelStringInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    totalSalesAmount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    unitsSold?: ResolverInputTypes['ModelIntInput'] | undefined | null
  }
  ['ModelFavoriteFlagInput']: {
    eq?: ResolverInputTypes['FavoriteFlag'] | undefined | null
    ne?: ResolverInputTypes['FavoriteFlag'] | undefined | null
  }
  ['ModelFloatInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ResolverInputTypes['ModelAttributeTypes'] | undefined | null
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
    ne?: number | undefined | null
  }
  ['ModelFloatKeyConditionInput']: {
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
  }
  ['ModelFormSequenceConfigurationConditionInput']: {
    active?: ResolverInputTypes['ModelActiveInput'] | undefined | null
    and?:
      | Array<ResolverInputTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null>
      | undefined
      | null
    not?: ResolverInputTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelFormSequenceConfigurationConditionInput'] | undefined | null> | undefined | null
    sequenceName?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelFormSequenceConfigurationFilterInput']: {
    active?: ResolverInputTypes['ModelActiveInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelFormSequenceConfigurationFilterInput'] | undefined | null> | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelFormSequenceConfigurationFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelFormSequenceConfigurationFilterInput'] | undefined | null> | undefined | null
    sequenceName?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelFraudReasonInput']: {
    eq?: ResolverInputTypes['FraudReason'] | undefined | null
    ne?: ResolverInputTypes['FraudReason'] | undefined | null
  }
  ['ModelFraudReviewStatusInput']: {
    eq?: ResolverInputTypes['FraudReviewStatus'] | undefined | null
    ne?: ResolverInputTypes['FraudReviewStatus'] | undefined | null
  }
  ['ModelIDInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ResolverInputTypes['ModelAttributeTypes'] | undefined | null
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    contains?: string | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
    ne?: string | undefined | null
    notContains?: string | undefined | null
    size?: ResolverInputTypes['ModelSizeInput'] | undefined | null
  }
  ['ModelIDKeyConditionInput']: {
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
  }
  ['ModelInfluencerApprovalFlagV2Input']: {
    eq?: ResolverInputTypes['InfluencerApprovalFlagV2'] | undefined | null
    ne?: ResolverInputTypes['InfluencerApprovalFlagV2'] | undefined | null
  }
  ['ModelInstagramStoryScreenConditionInput']: {
    and?: Array<ResolverInputTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null> | undefined | null
    exits?: ResolverInputTypes['ModelIntInput'] | undefined | null
    expirationUnixTime?: ResolverInputTypes['ModelIntInput'] | undefined | null
    externalId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    impressions?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    mediaId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelInstagramStoryScreenConditionInput'] | undefined | null> | undefined | null
    postedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    postingDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reach?: ResolverInputTypes['ModelIntInput'] | undefined | null
    replies?: ResolverInputTypes['ModelIntInput'] | undefined | null
    s3Bucket?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tapsBack?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tapsForward?: ResolverInputTypes['ModelIntInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelInstagramStoryScreenFilterInput']: {
    and?: Array<ResolverInputTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null> | undefined | null
    exits?: ResolverInputTypes['ModelIntInput'] | undefined | null
    expirationUnixTime?: ResolverInputTypes['ModelIntInput'] | undefined | null
    externalId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    impressions?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    mediaId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelInstagramStoryScreenFilterInput'] | undefined | null> | undefined | null
    postedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    postingDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reach?: ResolverInputTypes['ModelIntInput'] | undefined | null
    replies?: ResolverInputTypes['ModelIntInput'] | undefined | null
    s3Bucket?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tapsBack?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tapsForward?: ResolverInputTypes['ModelIntInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelIntInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ResolverInputTypes['ModelAttributeTypes'] | undefined | null
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
    ne?: number | undefined | null
  }
  ['ModelIntKeyConditionInput']: {
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
  }
  ['ModelItemClassificationInput']: {
    eq?: ResolverInputTypes['ItemClassification'] | undefined | null
    ne?: ResolverInputTypes['ItemClassification'] | undefined | null
  }
  ['ModelKeywordAcceptanceResponseInput']: {
    eq?: ResolverInputTypes['KeywordAcceptanceResponse'] | undefined | null
    ne?: ResolverInputTypes['KeywordAcceptanceResponse'] | undefined | null
  }
  ['ModelLinkStorageTypeInput']: {
    eq?: ResolverInputTypes['LinkStorageType'] | undefined | null
    ne?: ResolverInputTypes['LinkStorageType'] | undefined | null
  }
  ['ModelListingFulfillmentTypeInput']: {
    eq?: ResolverInputTypes['ListingFulfillmentType'] | undefined | null
    ne?: ResolverInputTypes['ListingFulfillmentType'] | undefined | null
  }
  ['ModelManualUploadTypeInput']: {
    eq?: ResolverInputTypes['ManualUploadType'] | undefined | null
    ne?: ResolverInputTypes['ManualUploadType'] | undefined | null
  }
  ['ModelMessageConditionInput']: {
    and?: Array<ResolverInputTypes['ModelMessageConditionInput'] | undefined | null> | undefined | null
    body?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelMessageConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelMessageConditionInput'] | undefined | null> | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userHasSeen?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
  }
  ['ModelMessageFilterInput']: {
    and?: Array<ResolverInputTypes['ModelMessageFilterInput'] | undefined | null> | undefined | null
    body?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelMessageFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelMessageFilterInput'] | undefined | null> | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userHasSeen?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelNoteConditionInput']: {
    and?: Array<ResolverInputTypes['ModelNoteConditionInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelNoteConditionInput'] | undefined | null
    note?: ResolverInputTypes['ModelStringInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelNoteConditionInput'] | undefined | null> | undefined | null
    parentId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelNoteFilterInput']: {
    and?: Array<ResolverInputTypes['ModelNoteFilterInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelNoteFilterInput'] | undefined | null
    note?: ResolverInputTypes['ModelStringInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelNoteFilterInput'] | undefined | null> | undefined | null
    parentId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelNotificationConfigurationConditionInput']: {
    and?:
      | Array<ResolverInputTypes['ModelNotificationConfigurationConditionInput'] | undefined | null>
      | undefined
      | null
    category?: ResolverInputTypes['ModelStringInput'] | undefined | null
    description?: ResolverInputTypes['ModelStringInput'] | undefined | null
    enabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelNotificationConfigurationConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelNotificationConfigurationConditionInput'] | undefined | null> | undefined | null
  }
  ['ModelNotificationConfigurationFilterInput']: {
    and?: Array<ResolverInputTypes['ModelNotificationConfigurationFilterInput'] | undefined | null> | undefined | null
    category?: ResolverInputTypes['ModelStringInput'] | undefined | null
    description?: ResolverInputTypes['ModelStringInput'] | undefined | null
    enabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelNotificationConfigurationFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelNotificationConfigurationFilterInput'] | undefined | null> | undefined | null
  }
  ['ModelNotificationStatusInput']: {
    eq?: ResolverInputTypes['NotificationStatus'] | undefined | null
    ne?: ResolverInputTypes['NotificationStatus'] | undefined | null
  }
  ['ModelOrderIdValidationStatusInput']: {
    eq?: ResolverInputTypes['OrderIdValidationStatus'] | undefined | null
    ne?: ResolverInputTypes['OrderIdValidationStatus'] | undefined | null
  }
  ['ModelPayForPerformanceFlagInput']: {
    eq?: ResolverInputTypes['PayForPerformanceFlag'] | undefined | null
    ne?: ResolverInputTypes['PayForPerformanceFlag'] | undefined | null
  }
  ['ModelPaymentTermsTypeInput']: {
    eq?: ResolverInputTypes['PaymentTermsType'] | undefined | null
    ne?: ResolverInputTypes['PaymentTermsType'] | undefined | null
  }
  ['ModelPlatformInviteSourceInput']: {
    eq?: ResolverInputTypes['PlatformInviteSource'] | undefined | null
    ne?: ResolverInputTypes['PlatformInviteSource'] | undefined | null
  }
  ['ModelPlatformPriorityInput']: {
    eq?: ResolverInputTypes['PlatformPriority'] | undefined | null
    ne?: ResolverInputTypes['PlatformPriority'] | undefined | null
  }
  ['ModelPlatformSourceInput']: {
    eq?: ResolverInputTypes['PlatformSource'] | undefined | null
    ne?: ResolverInputTypes['PlatformSource'] | undefined | null
  }
  ['ModelPlatformStatusInput']: {
    eq?: ResolverInputTypes['PlatformStatus'] | undefined | null
    ne?: ResolverInputTypes['PlatformStatus'] | undefined | null
  }
  ['ModelPreApprovalStatusInput']: {
    eq?: ResolverInputTypes['PreApprovalStatus'] | undefined | null
    ne?: ResolverInputTypes['PreApprovalStatus'] | undefined | null
  }
  ['ModelProductBonusConfigSnapshotConditionInput']: {
    and?:
      | Array<ResolverInputTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null>
      | undefined
      | null
    closeoutSurveyBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    discoveryBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null
    or?:
      | Array<ResolverInputTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined | null>
      | undefined
      | null
  }
  ['ModelProductBonusConfigSnapshotFilterInput']: {
    and?: Array<ResolverInputTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null> | undefined | null
    closeoutSurveyBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    discoveryBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined | null> | undefined | null
    productId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    snapshotAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelProductConditionInput']: {
    accountRequired?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    active?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelProductConditionInput'] | undefined | null> | undefined | null
    autoApprovalSeatsAvailable?: ResolverInputTypes['ModelIntInput'] | undefined | null
    autoApprovalStatusTiers?: ResolverInputTypes['ModelStatusTierListInput'] | undefined | null
    automaticReviewRelease?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    baseBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    brandAdvocateCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    brandTalkingPoints?: ResolverInputTypes['ModelStringInput'] | undefined | null
    buyOnlinePickupInStore?: ResolverInputTypes['ModelBOPISValueInput'] | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    campaignStateMachine?: ResolverInputTypes['ModelStringInput'] | undefined | null
    closeoutFormBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentApprovalLeadDays?: ResolverInputTypes['ModelIntInput'] | undefined | null
    creatorCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    creatorPreference?: ResolverInputTypes['ModelCreatorPreferenceInput'] | undefined | null
    creatorPreferenceDetails?: ResolverInputTypes['ModelStringInput'] | undefined | null
    customFtcHashtags?: ResolverInputTypes['ModelStringInput'] | undefined | null
    customHashtags?: ResolverInputTypes['ModelStringInput'] | undefined | null
    directPurchaseLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    hideBrandContentApprovals?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    instaHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalCreatorsStagingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalDescription?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalNotes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isDirectListingPurchase?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isQualityRequirementsEnabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    listingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    maxDiscoveryBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    minimumCreatorQualityScore?: ResolverInputTypes['ModelIntInput'] | undefined | null
    nonRetailReviewLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    nonRetailReviewWebsiteName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelProductConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelProductConditionInput'] | undefined | null> | undefined | null
    payForClicksEnabled?: ResolverInputTypes['ModelEnabledFlagInput'] | undefined | null
    payForClicksMinClicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    postingDeadlineDaysRelativeToApproval?: ResolverInputTypes['ModelIntInput'] | undefined | null
    productLinkId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    productPostingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productPostingGuidanceDocument?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoCode?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoCodeGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoCodeType?: ResolverInputTypes['ModelPromoCodeTypeInput'] | undefined | null
    promoDiscountAmount?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    promoDiscountType?: ResolverInputTypes['ModelPromoDiscountTypeInput'] | undefined | null
    promoEndDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoStartDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promotionLinkId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    purchaseByPromoCode?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    purchaseDiscountCode?: ResolverInputTypes['ModelStringInput'] | undefined | null
    purchaseDiscountCodeDetails?: ResolverInputTypes['ModelStringInput'] | undefined | null
    purchaseDiscountCodePercentage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    qualityLevel?: ResolverInputTypes['ModelProductQualityLevelInput'] | undefined | null
    requestEarlyProductFeedback?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    requestProductFeedback?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    requiredProductLinkEnabled?: ResolverInputTypes['ModelRequiredProductLinkFlagInput'] | undefined | null
    sequenceId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    shopifyPriceRule?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shopifyPromotionId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shopifyPromotionType?: ResolverInputTypes['ModelShopifyPromotionTypeInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    socialMediaExampleUrls?: ResolverInputTypes['ModelStringInput'] | undefined | null
    stagingApprovalRequiredNumCreatorsClientReviewed?: ResolverInputTypes['ModelIntInput'] | undefined | null
    stagingRequirementType?: ResolverInputTypes['ModelStagingRequirementTypeInput'] | undefined | null
    stagingSocialQualityRequirement?: ResolverInputTypes['ModelSocialQualityInput'] | undefined | null
    tikTokHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    unitsPurchased?: ResolverInputTypes['ModelIntInput'] | undefined | null
    validateProductFoundVariations?: ResolverInputTypes['ModelStringInput'] | undefined | null
    visibleAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    visibleEndsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelProductFilterInput']: {
    accountRequired?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    active?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelProductFilterInput'] | undefined | null> | undefined | null
    autoApprovalSeatsAvailable?: ResolverInputTypes['ModelIntInput'] | undefined | null
    autoApprovalStatusTiers?: ResolverInputTypes['ModelStatusTierListInput'] | undefined | null
    automaticReviewRelease?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    baseBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    brandAdvocateCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    brandTalkingPoints?: ResolverInputTypes['ModelStringInput'] | undefined | null
    buyOnlinePickupInStore?: ResolverInputTypes['ModelBOPISValueInput'] | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    campaignStateMachine?: ResolverInputTypes['ModelStringInput'] | undefined | null
    closeoutFormBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentApprovalLeadDays?: ResolverInputTypes['ModelIntInput'] | undefined | null
    creatorCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    creatorPreference?: ResolverInputTypes['ModelCreatorPreferenceInput'] | undefined | null
    creatorPreferenceDetails?: ResolverInputTypes['ModelStringInput'] | undefined | null
    customFtcHashtags?: ResolverInputTypes['ModelStringInput'] | undefined | null
    customHashtags?: ResolverInputTypes['ModelStringInput'] | undefined | null
    directPurchaseLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    hideBrandContentApprovals?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    instaHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalCreatorsStagingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalDescription?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    internalNotes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isDirectListingPurchase?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isQualityRequirementsEnabled?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    listingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    maxDiscoveryBonus?: ResolverInputTypes['ModelIntInput'] | undefined | null
    minimumCreatorQualityScore?: ResolverInputTypes['ModelIntInput'] | undefined | null
    nonRetailReviewLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    nonRetailReviewWebsiteName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelProductFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelProductFilterInput'] | undefined | null> | undefined | null
    payForClicksEnabled?: ResolverInputTypes['ModelEnabledFlagInput'] | undefined | null
    payForClicksMinClicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    postingDeadlineDaysRelativeToApproval?: ResolverInputTypes['ModelIntInput'] | undefined | null
    productLinkId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    productPostingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productPostingGuidanceDocument?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoCode?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoCodeGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoCodeType?: ResolverInputTypes['ModelPromoCodeTypeInput'] | undefined | null
    promoDiscountAmount?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    promoDiscountType?: ResolverInputTypes['ModelPromoDiscountTypeInput'] | undefined | null
    promoEndDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promoStartDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    promotionLinkId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    purchaseByPromoCode?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    purchaseDiscountCode?: ResolverInputTypes['ModelStringInput'] | undefined | null
    purchaseDiscountCodeDetails?: ResolverInputTypes['ModelStringInput'] | undefined | null
    purchaseDiscountCodePercentage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    qualityLevel?: ResolverInputTypes['ModelProductQualityLevelInput'] | undefined | null
    requestEarlyProductFeedback?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    requestProductFeedback?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    requiredProductLinkEnabled?: ResolverInputTypes['ModelRequiredProductLinkFlagInput'] | undefined | null
    sequenceId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    shopifyPriceRule?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shopifyPromotionId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shopifyPromotionType?: ResolverInputTypes['ModelShopifyPromotionTypeInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    socialMediaExampleUrls?: ResolverInputTypes['ModelStringInput'] | undefined | null
    stagingApprovalRequiredNumCreatorsClientReviewed?: ResolverInputTypes['ModelIntInput'] | undefined | null
    stagingRequirementType?: ResolverInputTypes['ModelStagingRequirementTypeInput'] | undefined | null
    stagingSocialQualityRequirement?: ResolverInputTypes['ModelSocialQualityInput'] | undefined | null
    tikTokHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    unitsPurchased?: ResolverInputTypes['ModelIntInput'] | undefined | null
    validateProductFoundVariations?: ResolverInputTypes['ModelStringInput'] | undefined | null
    visibleAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    visibleEndsAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelProductKeywordCreationSourceInput']: {
    eq?: ResolverInputTypes['ProductKeywordCreationSource'] | undefined | null
    ne?: ResolverInputTypes['ProductKeywordCreationSource'] | undefined | null
  }
  ['ModelProductKeywordsConditionInput']: {
    adminPriority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelProductKeywordsConditionInput'] | undefined | null> | undefined | null
    brandPriority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    brandResponse?: ResolverInputTypes['ModelKeywordAcceptanceResponseInput'] | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    creationSource?: ResolverInputTypes['ModelProductKeywordCreationSourceInput'] | undefined | null
    endDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    heliumAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    heliumAsin?: ResolverInputTypes['ModelStringInput'] | undefined | null
    heliumLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    keyword?: ResolverInputTypes['ModelStringInput'] | undefined | null
    monthlySearchVolume?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelProductKeywordsConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelProductKeywordsConditionInput'] | undefined | null> | undefined | null
    rank?: ResolverInputTypes['ModelIntInput'] | undefined | null
    rankingLastSkippedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rankingLastSkippedByUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    rankingLastUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rankingLastUpdatedByUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    recommended?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    startDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    usedForCampaign?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    visible?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    weight?: ResolverInputTypes['ModelIntInput'] | undefined | null
  }
  ['ModelProductKeywordsFilterInput']: {
    adminPriority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelProductKeywordsFilterInput'] | undefined | null> | undefined | null
    brandPriority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    brandResponse?: ResolverInputTypes['ModelKeywordAcceptanceResponseInput'] | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    creationSource?: ResolverInputTypes['ModelProductKeywordCreationSourceInput'] | undefined | null
    endDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    heliumAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    heliumAsin?: ResolverInputTypes['ModelStringInput'] | undefined | null
    heliumLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    keyword?: ResolverInputTypes['ModelStringInput'] | undefined | null
    monthlySearchVolume?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelProductKeywordsFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelProductKeywordsFilterInput'] | undefined | null> | undefined | null
    rank?: ResolverInputTypes['ModelIntInput'] | undefined | null
    rankingLastSkippedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rankingLastSkippedByUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    rankingLastUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rankingLastUpdatedByUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    recommended?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    startDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    usedForCampaign?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    visible?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    weight?: ResolverInputTypes['ModelIntInput'] | undefined | null
  }
  ['ModelProductQualityLevelInput']: {
    eq?: ResolverInputTypes['ProductQualityLevel'] | undefined | null
    ne?: ResolverInputTypes['ProductQualityLevel'] | undefined | null
  }
  ['ModelProductRatingSnapshotConditionInput']: {
    and?: Array<ResolverInputTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null> | undefined | null
    not?: ResolverInputTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null
    numFiveStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numFourStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numOneStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numRatings?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numReviews?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numThreeStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numTwoStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelProductRatingSnapshotConditionInput'] | undefined | null> | undefined | null
    rating?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    sku?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelProductRatingSnapshotFilterInput']: {
    and?: Array<ResolverInputTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null> | undefined | null
    not?: ResolverInputTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null
    numFiveStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numFourStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numOneStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numRatings?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numReviews?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numThreeStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numTwoStar?: ResolverInputTypes['ModelIntInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelProductRatingSnapshotFilterInput'] | undefined | null> | undefined | null
    rating?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    sku?: ResolverInputTypes['ModelStringInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    snapshotAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelPromoCodeTypeInput']: {
    eq?: ResolverInputTypes['PromoCodeType'] | undefined | null
    ne?: ResolverInputTypes['PromoCodeType'] | undefined | null
  }
  ['ModelPromoDiscountTypeInput']: {
    eq?: ResolverInputTypes['PromoDiscountType'] | undefined | null
    ne?: ResolverInputTypes['PromoDiscountType'] | undefined | null
  }
  ['ModelPurchaseConditionInput']: {
    amount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelPurchaseConditionInput'] | undefined | null> | undefined | null
    cardId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    currency?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isFraud?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    keywordAtPurchase?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelPurchaseConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelPurchaseConditionInput'] | undefined | null> | undefined | null
    purchasedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shippedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    status?: ResolverInputTypes['ModelPurchaseStatusInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelPurchaseFilterInput']: {
    amount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelPurchaseFilterInput'] | undefined | null> | undefined | null
    cardId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    currency?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    isFraud?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    keywordAtPurchase?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelPurchaseFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelPurchaseFilterInput'] | undefined | null> | undefined | null
    purchasedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shippedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    status?: ResolverInputTypes['ModelPurchaseStatusInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelPurchaseStatusInput']: {
    eq?: ResolverInputTypes['PurchaseStatus'] | undefined | null
    ne?: ResolverInputTypes['PurchaseStatus'] | undefined | null
  }
  ['ModelReferralConditionInput']: {
    and?: Array<ResolverInputTypes['ModelReferralConditionInput'] | undefined | null> | undefined | null
    bonusPayoutId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelReferralConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelReferralConditionInput'] | undefined | null> | undefined | null
    referralType?: ResolverInputTypes['ModelReferralTypeInput'] | undefined | null
    referredUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelReferralFilterInput']: {
    and?: Array<ResolverInputTypes['ModelReferralFilterInput'] | undefined | null> | undefined | null
    bonusPayoutId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelReferralFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelReferralFilterInput'] | undefined | null> | undefined | null
    referralType?: ResolverInputTypes['ModelReferralTypeInput'] | undefined | null
    referredUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    referrerUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelReferralTypeInput']: {
    eq?: ResolverInputTypes['ReferralType'] | undefined | null
    ne?: ResolverInputTypes['ReferralType'] | undefined | null
  }
  ['ModelRequiredProductLinkFlagInput']: {
    eq?: ResolverInputTypes['RequiredProductLinkFlag'] | undefined | null
    ne?: ResolverInputTypes['RequiredProductLinkFlag'] | undefined | null
  }
  ['ModelReviewConditionInput']: {
    and?: Array<ResolverInputTypes['ModelReviewConditionInput'] | undefined | null> | undefined | null
    attributedUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    author?: ResolverInputTypes['ModelStringInput'] | undefined | null
    authorBadge?: ResolverInputTypes['ModelStringInput'] | undefined | null
    badge?: ResolverInputTypes['ModelStringInput'] | undefined | null
    commentCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    helpfulReactions?: ResolverInputTypes['ModelIntInput'] | undefined | null
    negativeReactions?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelReviewConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelReviewConditionInput'] | undefined | null> | undefined | null
    photos?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productVariant?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rating?: ResolverInputTypes['ModelIntInput'] | undefined | null
    removed?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    reviewedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedSku?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewerId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    sku?: ResolverInputTypes['ModelStringInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    store?: ResolverInputTypes['ModelStringInput'] | undefined | null
    text?: ResolverInputTypes['ModelStringInput'] | undefined | null
    title?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    videos?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelReviewFilterInput']: {
    and?: Array<ResolverInputTypes['ModelReviewFilterInput'] | undefined | null> | undefined | null
    attributedUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    author?: ResolverInputTypes['ModelStringInput'] | undefined | null
    authorBadge?: ResolverInputTypes['ModelStringInput'] | undefined | null
    badge?: ResolverInputTypes['ModelStringInput'] | undefined | null
    commentCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    helpfulReactions?: ResolverInputTypes['ModelIntInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    negativeReactions?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelReviewFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelReviewFilterInput'] | undefined | null> | undefined | null
    photos?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productVariant?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rating?: ResolverInputTypes['ModelIntInput'] | undefined | null
    removed?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    reviewedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedSku?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewerId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    sku?: ResolverInputTypes['ModelStringInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    store?: ResolverInputTypes['ModelStringInput'] | undefined | null
    text?: ResolverInputTypes['ModelStringInput'] | undefined | null
    title?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    videos?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelReviewScreenshotVerificationResultInput']: {
    eq?: ResolverInputTypes['ReviewScreenshotVerificationResult'] | undefined | null
    ne?: ResolverInputTypes['ReviewScreenshotVerificationResult'] | undefined | null
  }
  ['ModelRevocationTypeInput']: {
    eq?: ResolverInputTypes['RevocationType'] | undefined | null
    ne?: ResolverInputTypes['RevocationType'] | undefined | null
  }
  ['ModelScrapeStatusInput']: {
    eq?: ResolverInputTypes['ScrapeStatus'] | undefined | null
    ne?: ResolverInputTypes['ScrapeStatus'] | undefined | null
  }
  ['ModelSelfieVideoApprovalStatusInput']: {
    eq?: ResolverInputTypes['SelfieVideoApprovalStatus'] | undefined | null
    ne?: ResolverInputTypes['SelfieVideoApprovalStatus'] | undefined | null
  }
  ['ModelShopifyPromotionTypeInput']: {
    eq?: ResolverInputTypes['ShopifyPromotionType'] | undefined | null
    ne?: ResolverInputTypes['ShopifyPromotionType'] | undefined | null
  }
  ['ModelSizeInput']: {
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
    ne?: number | undefined | null
  }
  ['ModelSkuConditionInput']: {
    active?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelSkuConditionInput'] | undefined | null> | undefined | null
    approxRetailPrice?: ResolverInputTypes['ModelStringInput'] | undefined | null
    approxShippingCost?: ResolverInputTypes['ModelIntInput'] | undefined | null
    availabilityDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    availabilityNotified?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    availabilityNotifiedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    brandListingKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    bundle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdByUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    creationSource?: ResolverInputTypes['ModelSkuCreationSourceInput'] | undefined | null
    creationSourceUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
    currency?: ResolverInputTypes['ModelCurrencyInput'] | undefined | null
    currentBuyBoxPrice?: ResolverInputTypes['ModelIntInput'] | undefined | null
    currentBuyBoxPriceCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    estimatedAvailabilityDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    estimatedDeliveryDays?: ResolverInputTypes['ModelIntInput'] | undefined | null
    estimatedMonthlyUnitsSold?: ResolverInputTypes['ModelIntInput'] | undefined | null
    estimatedWeeklyUnitsSold?: ResolverInputTypes['ModelIntInput'] | undefined | null
    exactListingTitle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    exactRetailPrice?: ResolverInputTypes['ModelStringInput'] | undefined | null
    fullReviewScrapeComplete?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    inventory?: ResolverInputTypes['ModelIntInput'] | undefined | null
    iosAppProviderId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isAplusPage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isPrime?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isRecommendationsHidden?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    itemClassification?: ResolverInputTypes['ModelItemClassificationInput'] | undefined | null
    lastAutoFilledAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastKnownBuyBoxPrice?: ResolverInputTypes['ModelIntInput'] | undefined | null
    lastKnownBuyBoxPriceCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    listingBrand?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingCategory?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingDescription?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingFulfillmentType?: ResolverInputTypes['ModelListingFulfillmentTypeInput'] | undefined | null
    listingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    mccValue?: ResolverInputTypes['ModelValidMccCodesInput'] | undefined | null
    minimumOrderQuantity?: ResolverInputTypes['ModelIntInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    nameAudioKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    nonSponsoredListingKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelSkuConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelSkuConditionInput'] | undefined | null> | undefined | null
    parentSkuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    priceCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    product?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productImageKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productPostingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productPostingGuidanceDocument?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productSearchImageKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    releaseDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    sponsoredListingKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    storeName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    targetReviewRateInvalid?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelSkuCreationSourceInput']: {
    eq?: ResolverInputTypes['SkuCreationSource'] | undefined | null
    ne?: ResolverInputTypes['SkuCreationSource'] | undefined | null
  }
  ['ModelSkuFilterInput']: {
    active?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelSkuFilterInput'] | undefined | null> | undefined | null
    approxRetailPrice?: ResolverInputTypes['ModelStringInput'] | undefined | null
    approxShippingCost?: ResolverInputTypes['ModelIntInput'] | undefined | null
    availabilityDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    availabilityNotified?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    availabilityNotifiedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    brandListingKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandStoreId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    bundle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdByUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    creationSource?: ResolverInputTypes['ModelSkuCreationSourceInput'] | undefined | null
    creationSourceUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
    currency?: ResolverInputTypes['ModelCurrencyInput'] | undefined | null
    currentBuyBoxPrice?: ResolverInputTypes['ModelIntInput'] | undefined | null
    currentBuyBoxPriceCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    estimatedAvailabilityDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    estimatedDeliveryDays?: ResolverInputTypes['ModelIntInput'] | undefined | null
    estimatedMonthlyUnitsSold?: ResolverInputTypes['ModelIntInput'] | undefined | null
    estimatedWeeklyUnitsSold?: ResolverInputTypes['ModelIntInput'] | undefined | null
    exactListingTitle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    exactRetailPrice?: ResolverInputTypes['ModelStringInput'] | undefined | null
    fullReviewScrapeComplete?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    inventory?: ResolverInputTypes['ModelIntInput'] | undefined | null
    iosAppProviderId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isAplusPage?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isPrime?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isRecommendationsHidden?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    itemClassification?: ResolverInputTypes['ModelItemClassificationInput'] | undefined | null
    lastAutoFilledAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastKnownBuyBoxPrice?: ResolverInputTypes['ModelIntInput'] | undefined | null
    lastKnownBuyBoxPriceCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    listingBrand?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingCategory?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingDescription?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingFulfillmentType?: ResolverInputTypes['ModelListingFulfillmentTypeInput'] | undefined | null
    listingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    mccValue?: ResolverInputTypes['ModelValidMccCodesInput'] | undefined | null
    minimumOrderQuantity?: ResolverInputTypes['ModelIntInput'] | undefined | null
    name?: ResolverInputTypes['ModelStringInput'] | undefined | null
    nameAudioKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    nonSponsoredListingKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelSkuFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelSkuFilterInput'] | undefined | null> | undefined | null
    parentSkuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    priceCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    product?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productImageKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productPostingGuidance?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productPostingGuidanceDocument?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productSearchImageKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    releaseDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    sponsoredListingKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    storeName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    targetReviewRateInvalid?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelSkuSnapshotBySkuIdCompositeKeyConditionInput']: {
    beginsWith?: ResolverInputTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    between?:
      | Array<ResolverInputTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null>
      | undefined
      | null
    eq?: ResolverInputTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    ge?: ResolverInputTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    gt?: ResolverInputTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    le?: ResolverInputTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
    lt?: ResolverInputTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined | null
  }
  ['ModelSkuSnapshotBySkuIdCompositeKeyInput']: {
    marketplaceSku?: string | undefined | null
    snapshotDate?: string | undefined | null
  }
  ['ModelSkuSnapshotConditionInput']: {
    and?: Array<ResolverInputTypes['ModelSkuSnapshotConditionInput'] | undefined | null> | undefined | null
    inventory?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelSkuSnapshotConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelSkuSnapshotConditionInput'] | undefined | null> | undefined | null
    price?: ResolverInputTypes['ModelIntInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelSkuSnapshotFilterInput']: {
    and?: Array<ResolverInputTypes['ModelSkuSnapshotFilterInput'] | undefined | null> | undefined | null
    inventory?: ResolverInputTypes['ModelIntInput'] | undefined | null
    marketplaceSku?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelSkuSnapshotFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelSkuSnapshotFilterInput'] | undefined | null> | undefined | null
    price?: ResolverInputTypes['ModelIntInput'] | undefined | null
    skuId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    snapshotDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelSocialChannelInput']: {
    eq?: ResolverInputTypes['SocialChannel'] | undefined | null
    ne?: ResolverInputTypes['SocialChannel'] | undefined | null
  }
  ['ModelSocialContentTypeInput']: {
    eq?: ResolverInputTypes['SocialContentType'] | undefined | null
    ne?: ResolverInputTypes['SocialContentType'] | undefined | null
  }
  ['ModelSocialQualityInput']: {
    eq?: ResolverInputTypes['SocialQuality'] | undefined | null
    ne?: ResolverInputTypes['SocialQuality'] | undefined | null
  }
  ['ModelSocialVerificationMethodInput']: {
    eq?: ResolverInputTypes['SocialVerificationMethod'] | undefined | null
    ne?: ResolverInputTypes['SocialVerificationMethod'] | undefined | null
  }
  ['ModelSourcePriorityInput']: {
    eq?: ResolverInputTypes['SourcePriority'] | undefined | null
    ne?: ResolverInputTypes['SourcePriority'] | undefined | null
  }
  ['ModelStagingApprovalOutcomeInput']: {
    eq?: ResolverInputTypes['StagingApprovalOutcome'] | undefined | null
    ne?: ResolverInputTypes['StagingApprovalOutcome'] | undefined | null
  }
  ['ModelStagingRequirementTypeInput']: {
    eq?: ResolverInputTypes['StagingRequirementType'] | undefined | null
    ne?: ResolverInputTypes['StagingRequirementType'] | undefined | null
  }
  ['ModelStatusTierInput']: {
    eq?: ResolverInputTypes['StatusTier'] | undefined | null
    ne?: ResolverInputTypes['StatusTier'] | undefined | null
  }
  ['ModelStatusTierListInput']: {
    contains?: ResolverInputTypes['StatusTier'] | undefined | null
    eq?: Array<ResolverInputTypes['StatusTier'] | undefined | null> | undefined | null
    ne?: Array<ResolverInputTypes['StatusTier'] | undefined | null> | undefined | null
    notContains?: ResolverInputTypes['StatusTier'] | undefined | null
  }
  ['ModelStringInput']: {
    attributeExists?: boolean | undefined | null
    attributeType?: ResolverInputTypes['ModelAttributeTypes'] | undefined | null
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    contains?: string | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
    ne?: string | undefined | null
    notContains?: string | undefined | null
    size?: ResolverInputTypes['ModelSizeInput'] | undefined | null
  }
  ['ModelStringKeyConditionInput']: {
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
  }
  ['ModelStripeRegionInput']: {
    eq?: ResolverInputTypes['StripeRegion'] | undefined | null
    ne?: ResolverInputTypes['StripeRegion'] | undefined | null
  }
  ['ModelSupportPriorityFlagInput']: {
    eq?: ResolverInputTypes['SupportPriorityFlag'] | undefined | null
    ne?: ResolverInputTypes['SupportPriorityFlag'] | undefined | null
  }
  ['ModelTrackingStatusInput']: {
    eq?: ResolverInputTypes['TrackingStatus'] | undefined | null
    ne?: ResolverInputTypes['TrackingStatus'] | undefined | null
  }
  ['ModelUserBonusPayoutConditionInput']: {
    adminPaymentApproved?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelUserBonusPayoutConditionInput'] | undefined | null> | undefined | null
    bonusType?: ResolverInputTypes['ModelBonusPayoutTypesInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    failureReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    manuallyCreatedBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserBonusPayoutConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserBonusPayoutConditionInput'] | undefined | null> | undefined | null
    payoutAmount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payoutAmountCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payoutBatchId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payoutDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payoutLocale?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    payoutState?: ResolverInputTypes['ModelBonusPayoutStateInput'] | undefined | null
    quantity?: ResolverInputTypes['ModelIntInput'] | undefined | null
    referenceId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    releaseAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    revocationType?: ResolverInputTypes['ModelRevocationTypeInput'] | undefined | null
    revokedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserBonusPayoutFilterInput']: {
    adminPaymentApproved?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null> | undefined | null
    bonusType?: ResolverInputTypes['ModelBonusPayoutTypesInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    failureReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    manuallyCreatedBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserBonusPayoutFilterInput'] | undefined | null> | undefined | null
    payoutAmount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payoutAmountCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payoutBatchId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payoutDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payoutLocale?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    payoutState?: ResolverInputTypes['ModelBonusPayoutStateInput'] | undefined | null
    quantity?: ResolverInputTypes['ModelIntInput'] | undefined | null
    referenceId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    releaseAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    revocationType?: ResolverInputTypes['ModelRevocationTypeInput'] | undefined | null
    revokedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserCampaignAcceptanceStatusInput']: {
    eq?: ResolverInputTypes['UserCampaignAcceptanceStatus'] | undefined | null
    ne?: ResolverInputTypes['UserCampaignAcceptanceStatus'] | undefined | null
  }
  ['ModelUserCampaignApprovalStatusInput']: {
    eq?: ResolverInputTypes['UserCampaignApprovalStatus'] | undefined | null
    ne?: ResolverInputTypes['UserCampaignApprovalStatus'] | undefined | null
  }
  ['ModelUserCampaignChangelogConditionInput']: {
    and?: Array<ResolverInputTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null> | undefined | null
    createdByUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    entryType?: ResolverInputTypes['ModelUserCampaignChangelogEntryTypeInput'] | undefined | null
    extraData?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserCampaignChangelogConditionInput'] | undefined | null> | undefined | null
  }
  ['ModelUserCampaignChangelogEntryTypeInput']: {
    eq?: ResolverInputTypes['UserCampaignChangelogEntryType'] | undefined | null
    ne?: ResolverInputTypes['UserCampaignChangelogEntryType'] | undefined | null
  }
  ['ModelUserCampaignChangelogFilterInput']: {
    and?: Array<ResolverInputTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdByUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    entryType?: ResolverInputTypes['ModelUserCampaignChangelogEntryTypeInput'] | undefined | null
    extraData?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserCampaignChangelogFilterInput'] | undefined | null> | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserCampaignConditionInput']: {
    accountScreenShot?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznReviewLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznReviewSubmissionStatus?: ResolverInputTypes['ModelAmazonReviewSubmissionStatusInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelUserCampaignConditionInput'] | undefined | null> | undefined | null
    applicationRejectedByUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    approvalOutcome?: ResolverInputTypes['ModelApprovalOutcomeInput'] | undefined | null
    approvalStatus?: ResolverInputTypes['ModelUserCampaignApprovalStatusInput'] | undefined | null
    archivedFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    archivedReason?: ResolverInputTypes['ModelArchivedReasonInput'] | undefined | null
    autoBuyApprovalDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    autoBuyRevokeDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    bestBuyReviewScreenShot?: ResolverInputTypes['ModelStringInput'] | undefined | null
    campaignAcceptanceStatus?: ResolverInputTypes['ModelUserCampaignAcceptanceStatusInput'] | undefined | null
    campaignApplication?: ResolverInputTypes['ModelStringInput'] | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    campaignStatus?: ResolverInputTypes['ModelCampaignStatusInput'] | undefined | null
    campaignStatusOnArchive?: ResolverInputTypes['ModelStringInput'] | undefined | null
    cardId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    cardRegion?: ResolverInputTypes['ModelStripeRegionInput'] | undefined | null
    closeoutSurvey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    coarseCampaignStatus?: ResolverInputTypes['ModelCoarseCampaignStatusInput'] | undefined | null
    contentApprovalLeadDays?: ResolverInputTypes['ModelIntInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    discoverySurvey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    influencerApprovalInviteFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    influencerApprovalRejectFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    invoiceKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isAutoApproved?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    keywordAtPurchase?: ResolverInputTypes['ModelStringInput'] | undefined | null
    keywordId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    keywordsV2Hash?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    localeV1?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    manualPurchase?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    moderatorStagingApprovalAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    moderatorStagingApprovalBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    moderatorStagingApprovalOutcome?: ResolverInputTypes['ModelStagingApprovalOutcomeInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserCampaignConditionInput'] | undefined | null> | undefined | null
    orderId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    orderIdInvalidReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    orderIdSubmissionDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    orderIdValidationDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    orderIdValidationStatus?: ResolverInputTypes['ModelOrderIdValidationStatusInput'] | undefined | null
    orderStatus?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payForClickLinkId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    paypalEmailAtSubmission?: ResolverInputTypes['ModelStringInput'] | undefined | null
    postingDeadline?: ResolverInputTypes['ModelStringInput'] | undefined | null
    postingDeadlineExtended?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    previousCardIds?: ResolverInputTypes['ModelStringInput'] | undefined | null
    priority?: ResolverInputTypes['ModelSourcePriorityInput'] | undefined | null
    productCriticalFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    productPositiveFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rankPriority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    reviewScreenshot?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewScreenshotVerificationResult?:
      | ResolverInputTypes['ModelReviewScreenshotVerificationResultInput']
      | undefined
      | null
    schedulingReleaseAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    searchPhraseKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selectedFlow?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalNotes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalStatus?: ResolverInputTypes['ModelSelfieVideoApprovalStatusInput'] | undefined | null
    selfieVideoKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoRejectionReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoSubmissionRequired?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    selfieVideoSubmittedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shippingAddress?: ResolverInputTypes['ModelStringInput'] | undefined | null
    showEarlyReviewSurvey?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    statusHistory?: ResolverInputTypes['ModelStringInput'] | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    walmartReviewScreenShot?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignFilterInput']: {
    accountScreenShot?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznReviewLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznReviewSubmissionStatus?: ResolverInputTypes['ModelAmazonReviewSubmissionStatusInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null> | undefined | null
    applicationRejectedByUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    approvalOutcome?: ResolverInputTypes['ModelApprovalOutcomeInput'] | undefined | null
    approvalStatus?: ResolverInputTypes['ModelUserCampaignApprovalStatusInput'] | undefined | null
    archivedFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    archivedReason?: ResolverInputTypes['ModelArchivedReasonInput'] | undefined | null
    autoBuyApprovalDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    autoBuyRevokeDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    bestBuyReviewScreenShot?: ResolverInputTypes['ModelStringInput'] | undefined | null
    campaignAcceptanceStatus?: ResolverInputTypes['ModelUserCampaignAcceptanceStatusInput'] | undefined | null
    campaignApplication?: ResolverInputTypes['ModelStringInput'] | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    campaignStatus?: ResolverInputTypes['ModelCampaignStatusInput'] | undefined | null
    campaignStatusOnArchive?: ResolverInputTypes['ModelStringInput'] | undefined | null
    cardId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    cardRegion?: ResolverInputTypes['ModelStripeRegionInput'] | undefined | null
    closeoutSurvey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    coarseCampaignStatus?: ResolverInputTypes['ModelCoarseCampaignStatusInput'] | undefined | null
    contentApprovalLeadDays?: ResolverInputTypes['ModelIntInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    discoverySurvey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    influencerApprovalInviteFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    influencerApprovalRejectFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    invoiceKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isAutoApproved?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    keywordAtPurchase?: ResolverInputTypes['ModelStringInput'] | undefined | null
    keywordId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    keywordsV2Hash?: ResolverInputTypes['ModelStringInput'] | undefined | null
    listingKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    localeV1?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    manualPurchase?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    moderatorStagingApprovalAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    moderatorStagingApprovalBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    moderatorStagingApprovalOutcome?: ResolverInputTypes['ModelStagingApprovalOutcomeInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserCampaignFilterInput'] | undefined | null> | undefined | null
    orderId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    orderIdInvalidReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    orderIdSubmissionDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    orderIdValidationDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    orderIdValidationStatus?: ResolverInputTypes['ModelOrderIdValidationStatusInput'] | undefined | null
    orderStatus?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payForClickLinkId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    paypalEmailAtSubmission?: ResolverInputTypes['ModelStringInput'] | undefined | null
    postingDeadline?: ResolverInputTypes['ModelStringInput'] | undefined | null
    postingDeadlineExtended?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    previousCardIds?: ResolverInputTypes['ModelStringInput'] | undefined | null
    priority?: ResolverInputTypes['ModelSourcePriorityInput'] | undefined | null
    productCriticalFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    productId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    productPositiveFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rankPriority?: ResolverInputTypes['ModelIntInput'] | undefined | null
    reviewScreenshot?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewScreenshotVerificationResult?:
      | ResolverInputTypes['ModelReviewScreenshotVerificationResultInput']
      | undefined
      | null
    schedulingReleaseAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    searchPhraseKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selectedFlow?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalNotes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoApprovalStatus?: ResolverInputTypes['ModelSelfieVideoApprovalStatusInput'] | undefined | null
    selfieVideoKey?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoRejectionReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    selfieVideoSubmissionRequired?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    selfieVideoSubmittedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    shippingAddress?: ResolverInputTypes['ModelStringInput'] | undefined | null
    showEarlyReviewSurvey?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    statusHistory?: ResolverInputTypes['ModelStringInput'] | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    walmartReviewScreenShot?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSnapshotConditionInput']: {
    and?: Array<ResolverInputTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null> | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserCampaignSnapshotConditionInput'] | undefined | null> | undefined | null
    snapshot?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSnapshotFilterInput']: {
    and?: Array<ResolverInputTypes['ModelUserCampaignSnapshotFilterInput'] | undefined | null> | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignSnapshotFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserCampaignSnapshotFilterInput'] | undefined | null> | undefined | null
    snapshot?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userCampaignVersion?: ResolverInputTypes['ModelIntInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentConditionInput']: {
    and?:
      | Array<ResolverInputTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null>
      | undefined
      | null
    approvalFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    approvalStatus?: ResolverInputTypes['ModelContentApprovalStatusInput'] | undefined | null
    approvedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    approverPWUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    approverUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    bonusEarned?: ResolverInputTypes['ModelIntInput'] | undefined | null
    bonusOverride?: ResolverInputTypes['ModelIntInput'] | undefined | null
    caption?: ResolverInputTypes['ModelStringInput'] | undefined | null
    channel?: ResolverInputTypes['ModelSocialChannelInput'] | undefined | null
    complianceReviewStatus?: ResolverInputTypes['ModelContentComplianceReviewStatusInput'] | undefined | null
    complianceReviewUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    complianceReviewedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    contentType?: ResolverInputTypes['ModelSocialContentTypeInput'] | undefined | null
    draftEnteredAdminApprovalAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftEnteredBrandApprovalAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftHistory?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftMedia?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftPreApprovedText?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftQualityScore?: ResolverInputTypes['ModelIntInput'] | undefined | null
    draftStatus?: ResolverInputTypes['ModelDraftStatusInput'] | undefined | null
    draftSubmittedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftText?: ResolverInputTypes['ModelStringInput'] | undefined | null
    earlySubmissionBonusEarnedCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    experimentId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    externalUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
    groupId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    hashtags?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instance?: ResolverInputTypes['ModelIntInput'] | undefined | null
    isFavorite?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isHidden?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isPurchased?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isUnavailable?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    manualUploadType?: ResolverInputTypes['ModelManualUploadTypeInput'] | undefined | null
    mediaId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    mediaUrls?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserCampaignSocialContentConditionInput'] | undefined | null> | undefined | null
    paymentId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    postedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    qualityScore?: ResolverInputTypes['ModelIntInput'] | undefined | null
    rejectionReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    requestedSocialScript?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    requirementLevel?: ResolverInputTypes['ModelContentRequirementLevelInput'] | undefined | null
    scrapeStatus?: ResolverInputTypes['ModelScrapeStatusInput'] | undefined | null
    unavailableAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    usedRequestedSocialScript?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    videoEndTime?: ResolverInputTypes['ModelStringInput'] | undefined | null
    videoStartTime?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentDraftHistoryConditionInput']: {
    and?:
      | Array<ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null>
      | undefined
      | null
    not?: ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null
    or?:
      | Array<ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined | null>
      | undefined
      | null
    snapshot?: ResolverInputTypes['ModelStringInput'] | undefined | null
    snapshotDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignSocialContentId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentDraftHistoryFilterInput']: {
    and?:
      | Array<ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null>
      | undefined
      | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null
    or?:
      | Array<ResolverInputTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined | null>
      | undefined
      | null
    snapshot?: ResolverInputTypes['ModelStringInput'] | undefined | null
    snapshotDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignSocialContentId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentFilterInput']: {
    and?: Array<ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null> | undefined | null
    approvalFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    approvalStatus?: ResolverInputTypes['ModelContentApprovalStatusInput'] | undefined | null
    approvedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    approverPWUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    approverUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    bonusEarned?: ResolverInputTypes['ModelIntInput'] | undefined | null
    bonusOverride?: ResolverInputTypes['ModelIntInput'] | undefined | null
    caption?: ResolverInputTypes['ModelStringInput'] | undefined | null
    channel?: ResolverInputTypes['ModelSocialChannelInput'] | undefined | null
    complianceReviewStatus?: ResolverInputTypes['ModelContentComplianceReviewStatusInput'] | undefined | null
    complianceReviewUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    complianceReviewedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    contentType?: ResolverInputTypes['ModelSocialContentTypeInput'] | undefined | null
    draftEnteredAdminApprovalAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftEnteredBrandApprovalAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftHistory?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftMedia?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftPreApprovedText?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftQualityScore?: ResolverInputTypes['ModelIntInput'] | undefined | null
    draftStatus?: ResolverInputTypes['ModelDraftStatusInput'] | undefined | null
    draftSubmittedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    draftText?: ResolverInputTypes['ModelStringInput'] | undefined | null
    earlySubmissionBonusEarnedCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    experimentId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    externalUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
    groupId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    hashtags?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    instance?: ResolverInputTypes['ModelIntInput'] | undefined | null
    isFavorite?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isHidden?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isPurchased?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    isUnavailable?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    manualUploadType?: ResolverInputTypes['ModelManualUploadTypeInput'] | undefined | null
    mediaId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    mediaUrls?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserCampaignSocialContentFilterInput'] | undefined | null> | undefined | null
    paymentId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    postedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    qualityScore?: ResolverInputTypes['ModelIntInput'] | undefined | null
    rejectionReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    requestedSocialScript?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    requirementLevel?: ResolverInputTypes['ModelContentRequirementLevelInput'] | undefined | null
    scrapeStatus?: ResolverInputTypes['ModelScrapeStatusInput'] | undefined | null
    unavailableAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    usedRequestedSocialScript?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    videoEndTime?: ResolverInputTypes['ModelStringInput'] | undefined | null
    videoStartTime?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewConditionInput']: {
    and?:
      | Array<ResolverInputTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null>
      | undefined
      | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null
    or?:
      | Array<ResolverInputTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined | null>
      | undefined
      | null
    reviewOutcome?: ResolverInputTypes['ModelUserCampaignSocialContentReviewOutcomeInput'] | undefined | null
    reviewStatus?: ResolverInputTypes['ModelUserCampaignSocialContentReviewStatusInput'] | undefined | null
    reviewedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewerAssigneeId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewerFirstName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewerId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    reviewerLastName?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewFilterInput']: {
    and?:
      | Array<ResolverInputTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null>
      | undefined
      | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null
    or?:
      | Array<ResolverInputTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined | null>
      | undefined
      | null
    reviewOutcome?: ResolverInputTypes['ModelUserCampaignSocialContentReviewOutcomeInput'] | undefined | null
    reviewStatus?: ResolverInputTypes['ModelUserCampaignSocialContentReviewStatusInput'] | undefined | null
    reviewType?: ResolverInputTypes['ModelUserCampaignSocialContentReviewTypeInput'] | undefined | null
    reviewedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewerAssigneeId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewerFirstName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewerId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    reviewerLastName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userCampaignSocialContentId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userId?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewOutcomeInput']: {
    eq?: ResolverInputTypes['UserCampaignSocialContentReviewOutcome'] | undefined | null
    ne?: ResolverInputTypes['UserCampaignSocialContentReviewOutcome'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewStatusInput']: {
    eq?: ResolverInputTypes['UserCampaignSocialContentReviewStatus'] | undefined | null
    ne?: ResolverInputTypes['UserCampaignSocialContentReviewStatus'] | undefined | null
  }
  ['ModelUserCampaignSocialContentReviewTypeInput']: {
    eq?: ResolverInputTypes['UserCampaignSocialContentReviewType'] | undefined | null
    ne?: ResolverInputTypes['UserCampaignSocialContentReviewType'] | undefined | null
  }
  ['ModelUserConditionInput']: {
    active?: ResolverInputTypes['ModelActiveInput'] | undefined | null
    addressComponents?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznLastChecked?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznPrime?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznProfileId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznReviewerId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznVerified?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelUserConditionInput'] | undefined | null> | undefined | null
    attributedReviewCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    blogWebsite?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandContentRatingAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    campaignAcceptanceRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignApprovalRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignCompletionRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignReviewRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    cardProfileUpdates?: ResolverInputTypes['ModelStringInput'] | undefined | null
    cardholderId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    cardholderRegion?: ResolverInputTypes['ModelStripeRegionInput'] | undefined | null
    characteristicsUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    claimedReviewCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    collisionSkuSet?: ResolverInputTypes['ModelStringInput'] | undefined | null
    collisionSkuSetCalculatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    completedLifestyleCampaignCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    completedLiftCampaignCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    completedSocialCampaignCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentRatingCompletedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    contentRatingCompletionTimeMillis?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentRatingInstagramCompletionTimeMillis?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentRatingQualityScore?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentRatingReviewStatus?: ResolverInputTypes['ModelContentRatingReviewStatusInput'] | undefined | null
    contentRatingSubmittedBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    contentRatingTiktokCompletionTimeMillis?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentTypes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    date_of_birth?: ResolverInputTypes['ModelStringInput'] | undefined | null
    deactivationDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    deactivationReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    declineToReviewCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    deranked?: ResolverInputTypes['ModelDeranked_ReasonInput'] | undefined | null
    email?: ResolverInputTypes['ModelStringInput'] | undefined | null
    emailOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    facebookAccessToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    facebookAccessTokenExpiry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    facebookPageId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    facebookPageIsSubscribed?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    facebookPermissionScopes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    facebookUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    firstName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    followsWesponsored?: ResolverInputTypes['ModelStringInput'] | undefined | null
    fraudReason?: ResolverInputTypes['ModelFraudReasonInput'] | undefined | null
    fraudReviewAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    fraudReviewStatus?: ResolverInputTypes['ModelFraudReviewStatusInput'] | undefined | null
    gender?: ResolverInputTypes['ModelStringInput'] | undefined | null
    hasTrueEngagement?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    idfaId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    incompleteCampaignCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramBio?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramBusinessCategoryName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramCategoryName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramChecked?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramContentTypeRating?: ResolverInputTypes['ModelContentTypeRatingListInput'] | undefined | null
    instagramExternalLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramIsBusiness?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramIsPrivate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramIsRecentlyJoined?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramIsVerified?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramLastChecked?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramMedianShares?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramMetricsRetrievedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramPostsInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramPriority?: ResolverInputTypes['ModelPlatformPriorityInput'] | undefined | null
    instagramProfilePic?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramTrailingComments?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramTrailingDateOfOldestPost?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramTrailingLikes?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramTrailingNumberOfPosts?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramTrailingPostEngagementAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateImpressionsAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateMedian?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostImpressionsAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostImpressionsMedian?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramTrailingPostSponsoredPercentage?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostViewsMedian?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramVerificationMethod?: ResolverInputTypes['ModelSocialVerificationMethodInput'] | undefined | null
    internalGender?: ResolverInputTypes['ModelUserGenderInput'] | undefined | null
    isBrandSafe?: ResolverInputTypes['ModelBrandSafeInput'] | undefined | null
    isBrandSafeReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isBrandSafeUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastActiveAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    manualScoringLockedUntil?: ResolverInputTypes['ModelStringInput'] | undefined | null
    moreinfo?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserConditionInput'] | undefined | null
    numEvenDollarPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserConditionInput'] | undefined | null> | undefined | null
    paypalEmail?: ResolverInputTypes['ModelStringInput'] | undefined | null
    phoneNumber?: ResolverInputTypes['ModelStringInput'] | undefined | null
    profilePicture?: ResolverInputTypes['ModelStringInput'] | undefined | null
    pushOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    referralCode?: ResolverInputTypes['ModelStringInput'] | undefined | null
    referralCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    referrer?: ResolverInputTypes['ModelStringInput'] | undefined | null
    signupCountry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    smsOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    socialQuality?: ResolverInputTypes['ModelSocialQualityInput'] | undefined | null
    statusTier?: ResolverInputTypes['ModelStatusTierInput'] | undefined | null
    statusTierLastUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    supportPriorityAcknowledgedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    supportPriorityFlag?: ResolverInputTypes['ModelSupportPriorityFlagInput'] | undefined | null
    supportPriorityFlagSetAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    targetAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokAccessToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokAccessTokenExpiry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokAccountId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokAveragePostEngagementRate?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokAveragePostEngagementRateViews?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokBio?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokContentTypeRating?: ResolverInputTypes['ModelContentTypeRatingListInput'] | undefined | null
    tiktokFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokMedianComments?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokMedianLikes?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokMedianPostEngagement?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokMedianPostEngagementRate?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokMedianPostEngagementRateViews?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokMedianShares?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokMedianViews?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokMetricsRetrievedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokPermissionScopes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokPriority?: ResolverInputTypes['ModelPlatformPriorityInput'] | undefined | null
    tiktokProfilePic?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokRefreshToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokRefreshTokenExpiry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokSponsoredPostPercentage?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokVerificationMethod?: ResolverInputTypes['ModelSocialVerificationMethodInput'] | undefined | null
    tiktokVerified?: ResolverInputTypes['ModelStringInput'] | undefined | null
    timeZone?: ResolverInputTypes['ModelStringInput'] | undefined | null
    trackingStatus?: ResolverInputTypes['ModelTrackingStatusInput'] | undefined | null
    twitchAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    type?: ResolverInputTypes['ModelStringInput'] | undefined | null
    wmartAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeAccessToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeAccessTokenExpiry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeAveragePostEngagementRate?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    youtubeChannelDescription?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeChannelFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    youtubeChannelHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeChannelId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeChannelPicture?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeChannelTitle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeIdToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeMedianViews?: ResolverInputTypes['ModelIntInput'] | undefined | null
    youtubeMetricsRetrievedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubePermissionScopes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeRefreshToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeVerificationMethod?: ResolverInputTypes['ModelSocialVerificationMethodInput'] | undefined | null
  }
  ['ModelUserDeviceSnapshotConditionInput']: {
    and?: Array<ResolverInputTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null> | undefined | null
    idfv?: ResolverInputTypes['ModelStringInput'] | undefined | null
    ipAddress?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isEmulator?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserDeviceSnapshotConditionInput'] | undefined | null> | undefined | null
    snapshotAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    timezone?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserDeviceSnapshotFilterInput']: {
    and?: Array<ResolverInputTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null> | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    idfv?: ResolverInputTypes['ModelStringInput'] | undefined | null
    ipAddress?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isEmulator?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserDeviceSnapshotFilterInput'] | undefined | null> | undefined | null
    snapshotAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    timezone?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserFilterGenderInput']: {
    eq?: ResolverInputTypes['UserFilterGender'] | undefined | null
    ne?: ResolverInputTypes['UserFilterGender'] | undefined | null
  }
  ['ModelUserFilterInput']: {
    active?: ResolverInputTypes['ModelActiveInput'] | undefined | null
    addressComponents?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznLastChecked?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznPrime?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznProfileId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznReviewerId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    amznVerified?: ResolverInputTypes['ModelStringInput'] | undefined | null
    and?: Array<ResolverInputTypes['ModelUserFilterInput'] | undefined | null> | undefined | null
    attributedReviewCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    blogWebsite?: ResolverInputTypes['ModelStringInput'] | undefined | null
    brandContentRatingAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    campaignAcceptanceRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignApprovalRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignCompletionRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    campaignReviewRate?: ResolverInputTypes['ModelIntInput'] | undefined | null
    cardProfileUpdates?: ResolverInputTypes['ModelStringInput'] | undefined | null
    cardholderId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    cardholderRegion?: ResolverInputTypes['ModelStripeRegionInput'] | undefined | null
    characteristicsUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    claimedReviewCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    collisionSkuSet?: ResolverInputTypes['ModelStringInput'] | undefined | null
    collisionSkuSetCalculatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    completedLifestyleCampaignCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    completedLiftCampaignCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    completedSocialCampaignCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentRatingCompletedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    contentRatingCompletionTimeMillis?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentRatingInstagramCompletionTimeMillis?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentRatingQualityScore?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentRatingReviewStatus?: ResolverInputTypes['ModelContentRatingReviewStatusInput'] | undefined | null
    contentRatingSubmittedBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    contentRatingTiktokCompletionTimeMillis?: ResolverInputTypes['ModelIntInput'] | undefined | null
    contentTypes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    date_of_birth?: ResolverInputTypes['ModelStringInput'] | undefined | null
    deactivationDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    deactivationReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    declineToReviewCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    deranked?: ResolverInputTypes['ModelDeranked_ReasonInput'] | undefined | null
    email?: ResolverInputTypes['ModelStringInput'] | undefined | null
    emailOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    facebookAccessToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    facebookAccessTokenExpiry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    facebookPageId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    facebookPageIsSubscribed?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    facebookPermissionScopes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    facebookUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    firstName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    followsWesponsored?: ResolverInputTypes['ModelStringInput'] | undefined | null
    fraudReason?: ResolverInputTypes['ModelFraudReasonInput'] | undefined | null
    fraudReviewAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    fraudReviewStatus?: ResolverInputTypes['ModelFraudReviewStatusInput'] | undefined | null
    gender?: ResolverInputTypes['ModelStringInput'] | undefined | null
    hasTrueEngagement?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    idfaId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    incompleteCampaignCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramBio?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramBusinessCategoryName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramCategoryName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramChecked?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramContentTypeRating?: ResolverInputTypes['ModelContentTypeRatingListInput'] | undefined | null
    instagramExternalLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramIsBusiness?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramIsPrivate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramIsRecentlyJoined?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramIsVerified?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramLastChecked?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramMedianShares?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramMetricsRetrievedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramPostsInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramPriority?: ResolverInputTypes['ModelPlatformPriorityInput'] | undefined | null
    instagramProfilePic?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramTrailingComments?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramTrailingDateOfOldestPost?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramTrailingLikes?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramTrailingNumberOfPosts?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramTrailingPostEngagementAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateImpressionsAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostEngagementRateMedian?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostImpressionsAvg?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostImpressionsMedian?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramTrailingPostSponsoredPercentage?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    instagramTrailingPostViewsMedian?: ResolverInputTypes['ModelIntInput'] | undefined | null
    instagramUserId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    instagramVerificationMethod?: ResolverInputTypes['ModelSocialVerificationMethodInput'] | undefined | null
    internalGender?: ResolverInputTypes['ModelUserGenderInput'] | undefined | null
    isBrandSafe?: ResolverInputTypes['ModelBrandSafeInput'] | undefined | null
    isBrandSafeReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isBrandSafeUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastActiveAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    lastName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    manualScoringLockedUntil?: ResolverInputTypes['ModelStringInput'] | undefined | null
    moreinfo?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserFilterInput'] | undefined | null
    numEvenDollarPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    numPurchases?: ResolverInputTypes['ModelIntInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserFilterInput'] | undefined | null> | undefined | null
    paypalEmail?: ResolverInputTypes['ModelStringInput'] | undefined | null
    phoneNumber?: ResolverInputTypes['ModelStringInput'] | undefined | null
    profilePicture?: ResolverInputTypes['ModelStringInput'] | undefined | null
    pushOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    referralCode?: ResolverInputTypes['ModelStringInput'] | undefined | null
    referralCount?: ResolverInputTypes['ModelIntInput'] | undefined | null
    referrer?: ResolverInputTypes['ModelStringInput'] | undefined | null
    signupCountry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    smsOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    socialQuality?: ResolverInputTypes['ModelSocialQualityInput'] | undefined | null
    statusTier?: ResolverInputTypes['ModelStatusTierInput'] | undefined | null
    statusTierLastUpdatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    supportPriorityAcknowledgedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    supportPriorityFlag?: ResolverInputTypes['ModelSupportPriorityFlagInput'] | undefined | null
    supportPriorityFlagSetAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    targetAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokAccessToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokAccessTokenExpiry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokAccountId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokAveragePostEngagementRate?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokAveragePostEngagementRateViews?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokBio?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokContentTypeRating?: ResolverInputTypes['ModelContentTypeRatingListInput'] | undefined | null
    tiktokFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokMedianComments?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokMedianLikes?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokMedianPostEngagement?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokMedianPostEngagementRate?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokMedianPostEngagementRateViews?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokMedianShares?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokMedianViews?: ResolverInputTypes['ModelIntInput'] | undefined | null
    tiktokMetricsRetrievedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokPermissionScopes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokPriority?: ResolverInputTypes['ModelPlatformPriorityInput'] | undefined | null
    tiktokProfilePic?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokRefreshToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokRefreshTokenExpiry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    tiktokSponsoredPostPercentage?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    tiktokVerificationMethod?: ResolverInputTypes['ModelSocialVerificationMethodInput'] | undefined | null
    tiktokVerified?: ResolverInputTypes['ModelStringInput'] | undefined | null
    timeZone?: ResolverInputTypes['ModelStringInput'] | undefined | null
    trackingStatus?: ResolverInputTypes['ModelTrackingStatusInput'] | undefined | null
    twitchAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    type?: ResolverInputTypes['ModelStringInput'] | undefined | null
    wmartAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeAccessToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeAccessTokenExpiry?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeAccount?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeAveragePostEngagementRate?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    youtubeChannelDescription?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeChannelFollowersInt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    youtubeChannelHandle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeChannelId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeChannelPicture?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeChannelTitle?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeIdToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeMedianViews?: ResolverInputTypes['ModelIntInput'] | undefined | null
    youtubeMetricsRetrievedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubePermissionScopes?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeRefreshToken?: ResolverInputTypes['ModelStringInput'] | undefined | null
    youtubeVerificationMethod?: ResolverInputTypes['ModelSocialVerificationMethodInput'] | undefined | null
  }
  ['ModelUserFollowerConditionInput']: {
    and?: Array<ResolverInputTypes['ModelUserFollowerConditionInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    isFavorite?: ResolverInputTypes['ModelFavoriteFlagInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserFollowerConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserFollowerConditionInput'] | undefined | null> | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserFollowerFilterInput']: {
    and?: Array<ResolverInputTypes['ModelUserFollowerFilterInput'] | undefined | null> | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    followerUserId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    isFavorite?: ResolverInputTypes['ModelFavoriteFlagInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserFollowerFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserFollowerFilterInput'] | undefined | null> | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserGenderInput']: {
    eq?: ResolverInputTypes['UserGender'] | undefined | null
    ne?: ResolverInputTypes['UserGender'] | undefined | null
  }
  ['ModelUserLinkConditionInput']: {
    and?: Array<ResolverInputTypes['ModelUserLinkConditionInput'] | undefined | null> | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    linkId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserLinkConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserLinkConditionInput'] | undefined | null> | undefined | null
    originalLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rank?: ResolverInputTypes['ModelIntInput'] | undefined | null
    storageType?: ResolverInputTypes['ModelLinkStorageTypeInput'] | undefined | null
    uniqueClicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userEncodedLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserLinkFilterInput']: {
    and?: Array<ResolverInputTypes['ModelUserLinkFilterInput'] | undefined | null> | undefined | null
    campaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    createdAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    linkId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserLinkFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserLinkFilterInput'] | undefined | null> | undefined | null
    originalLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    rank?: ResolverInputTypes['ModelIntInput'] | undefined | null
    storageType?: ResolverInputTypes['ModelLinkStorageTypeInput'] | undefined | null
    uniqueClicks?: ResolverInputTypes['ModelIntInput'] | undefined | null
    updatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userEncodedLink?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserNotificationPreferenceConditionInput']: {
    and?:
      | Array<ResolverInputTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null>
      | undefined
      | null
    emailOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null
    or?:
      | Array<ResolverInputTypes['ModelUserNotificationPreferenceConditionInput'] | undefined | null>
      | undefined
      | null
    pushOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    smsOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
  }
  ['ModelUserNotificationPreferenceFilterInput']: {
    and?: Array<ResolverInputTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null> | undefined | null
    category?: ResolverInputTypes['ModelStringInput'] | undefined | null
    emailOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserNotificationPreferenceFilterInput'] | undefined | null> | undefined | null
    pushOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    smsOptOut?: ResolverInputTypes['ModelBooleanInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelUserPayoutRequestConditionInput']: {
    and?: Array<ResolverInputTypes['ModelUserPayoutRequestConditionInput'] | undefined | null> | undefined | null
    failureReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    manuallyApprovedBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserPayoutRequestConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserPayoutRequestConditionInput'] | undefined | null> | undefined | null
    payoutAmountCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payoutBatchId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payoutDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payoutLocale?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    payoutState?: ResolverInputTypes['ModelBonusPayoutStateInput'] | undefined | null
  }
  ['ModelUserPayoutRequestFilterInput']: {
    and?: Array<ResolverInputTypes['ModelUserPayoutRequestFilterInput'] | undefined | null> | undefined | null
    failureReason?: ResolverInputTypes['ModelStringInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    manuallyApprovedBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserPayoutRequestFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserPayoutRequestFilterInput'] | undefined | null> | undefined | null
    payoutAmountCents?: ResolverInputTypes['ModelIntInput'] | undefined | null
    payoutBatchId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payoutDate?: ResolverInputTypes['ModelStringInput'] | undefined | null
    payoutLocale?: ResolverInputTypes['ModelWSLocaleInput'] | undefined | null
    payoutState?: ResolverInputTypes['ModelBonusPayoutStateInput'] | undefined | null
    userId?: ResolverInputTypes['ModelStringInput'] | undefined | null
  }
  ['ModelUserStagingApprovalConditionInput']: {
    and?: Array<ResolverInputTypes['ModelUserStagingApprovalConditionInput'] | undefined | null> | undefined | null
    not?: ResolverInputTypes['ModelUserStagingApprovalConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserStagingApprovalConditionInput'] | undefined | null> | undefined | null
    reviewFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedByEmail?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedByName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedBySource?: ResolverInputTypes['ModelUserStagingApprovalReviewSourceInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userStagingApprovalOutcome?: ResolverInputTypes['ModelUserStagingApprovalOutcomeInput'] | undefined | null
    userStagingApprovalType?: ResolverInputTypes['ModelUserStagingApprovalTypeInput'] | undefined | null
  }
  ['ModelUserStagingApprovalFilterInput']: {
    and?: Array<ResolverInputTypes['ModelUserStagingApprovalFilterInput'] | undefined | null> | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelUserStagingApprovalFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelUserStagingApprovalFilterInput'] | undefined | null> | undefined | null
    reviewFeedback?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedBy?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedByEmail?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedByName?: ResolverInputTypes['ModelStringInput'] | undefined | null
    reviewedBySource?: ResolverInputTypes['ModelUserStagingApprovalReviewSourceInput'] | undefined | null
    userCampaignId?: ResolverInputTypes['ModelIDInput'] | undefined | null
    userStagingApprovalOutcome?: ResolverInputTypes['ModelUserStagingApprovalOutcomeInput'] | undefined | null
    userStagingApprovalType?: ResolverInputTypes['ModelUserStagingApprovalTypeInput'] | undefined | null
  }
  ['ModelUserStagingApprovalOutcomeInput']: {
    eq?: ResolverInputTypes['UserStagingApprovalOutcome'] | undefined | null
    ne?: ResolverInputTypes['UserStagingApprovalOutcome'] | undefined | null
  }
  ['ModelUserStagingApprovalReviewSourceInput']: {
    eq?: ResolverInputTypes['UserStagingApprovalReviewSource'] | undefined | null
    ne?: ResolverInputTypes['UserStagingApprovalReviewSource'] | undefined | null
  }
  ['ModelUserStagingApprovalTypeInput']: {
    eq?: ResolverInputTypes['UserStagingApprovalType'] | undefined | null
    ne?: ResolverInputTypes['UserStagingApprovalType'] | undefined | null
  }
  ['ModelValidMccCodesInput']: {
    eq?: ResolverInputTypes['ValidMccCodes'] | undefined | null
    ne?: ResolverInputTypes['ValidMccCodes'] | undefined | null
  }
  ['ModelVideoContentScanConditionInput']: {
    and?: Array<ResolverInputTypes['ModelVideoContentScanConditionInput'] | undefined | null> | undefined | null
    audioDuration?: ResolverInputTypes['ModelIntInput'] | undefined | null
    audioEndAt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    audioStartFrom?: ResolverInputTypes['ModelIntInput'] | undefined | null
    not?: ResolverInputTypes['ModelVideoContentScanConditionInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelVideoContentScanConditionInput'] | undefined | null> | undefined | null
    processFinishedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    processId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    processInitiatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    scanStatus?: ResolverInputTypes['ModelContentScanStatusInput'] | undefined | null
    socialMediaUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
    transcription?: ResolverInputTypes['ModelStringInput'] | undefined | null
    transcriptionConfidence?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    url?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelVideoContentScanFilterInput']: {
    and?: Array<ResolverInputTypes['ModelVideoContentScanFilterInput'] | undefined | null> | undefined | null
    audioDuration?: ResolverInputTypes['ModelIntInput'] | undefined | null
    audioEndAt?: ResolverInputTypes['ModelIntInput'] | undefined | null
    audioStartFrom?: ResolverInputTypes['ModelIntInput'] | undefined | null
    id?: ResolverInputTypes['ModelIDInput'] | undefined | null
    not?: ResolverInputTypes['ModelVideoContentScanFilterInput'] | undefined | null
    or?: Array<ResolverInputTypes['ModelVideoContentScanFilterInput'] | undefined | null> | undefined | null
    processFinishedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    processId?: ResolverInputTypes['ModelStringInput'] | undefined | null
    processInitiatedAt?: ResolverInputTypes['ModelStringInput'] | undefined | null
    scanStatus?: ResolverInputTypes['ModelContentScanStatusInput'] | undefined | null
    socialMediaUrl?: ResolverInputTypes['ModelStringInput'] | undefined | null
    transcription?: ResolverInputTypes['ModelStringInput'] | undefined | null
    transcriptionConfidence?: ResolverInputTypes['ModelFloatInput'] | undefined | null
    url?: ResolverInputTypes['ModelStringInput'] | undefined | null
    userId?: ResolverInputTypes['ModelIDInput'] | undefined | null
  }
  ['ModelWSLocaleInput']: {
    eq?: ResolverInputTypes['WSLocale'] | undefined | null
    ne?: ResolverInputTypes['WSLocale'] | undefined | null
  }
  ['OccurrenceConstraintInput']: {
    period?: ResolverInputTypes['OccurrenceConstraintPeriod'] | undefined | null
    type: ResolverInputTypes['OccurrenceConstraintType']
    value?: number | undefined | null
  }
  ['PayForImpressionsBonusConfigInput']: {
    enabled?: boolean | undefined | null
    growthRate?: number | undefined | null
    impressionsBaseline?: number | undefined | null
    impressionsCap?: number | undefined | null
    impressionsIncrement?: number | undefined | null
    initialBonus?: number | undefined | null
  }
  ['PayForPostingContentEarlyConfigInput']: {
    bonusCents?: number | undefined | null
    daysBeforeDeadline?: number | undefined | null
    enabled?: boolean | undefined | null
  }
  ['ProductSocialContentInput']: {
    bonus: number
    brandContentGuidance?: Array<ResolverInputTypes['ContentGuidanceInput']> | undefined | null
    brandContentRequirements?: Array<string> | undefined | null
    bronzeBonus?: number | undefined | null
    channel: ResolverInputTypes['SocialChannel']
    diamondBonus?: number | undefined | null
    goldBonus?: number | undefined | null
    inspirationalContent?: Array<ResolverInputTypes['InspirationalContentInput']> | undefined | null
    instances: number
    platinumBonus?: number | undefined | null
    requirementLevel: ResolverInputTypes['ContentRequirementLevel']
    requiresManualApproval?: boolean | undefined | null
    silverBonus?: number | undefined | null
    talkingPoints?: Array<ResolverInputTypes['ContentGuidanceInput']> | undefined | null
    totalExpectedCount?: number | undefined | null
    type: ResolverInputTypes['SocialContentType']
  }
  ['ProspectSearchFields']: {
    sourcingSource?: ResolverInputTypes['SearchInputString'] | undefined | null
  }
  ['PushAddressInput']: {
    address?: string | undefined | null
    pushChannel?: ResolverInputTypes['PushChannel'] | undefined | null
  }
  ['PushChannelConfigurationInput']: {
    attributes?: Array<ResolverInputTypes['KeyAttributeInput'] | undefined | null> | undefined | null
    enabled?: boolean | undefined | null
    templateId: string
  }
  ['RankPointInput']: {
    rank: number
    rankAt: string
  }
  ['RateInput']: {
    count?: number | undefined | null
    isIntegrationFallbackRate?: boolean | undefined | null
    period?: ResolverInputTypes['Period'] | undefined | null
  }
  ['RequestedContentChangesInput']: {
    message?: string | undefined | null
    rating?: number | undefined | null
  }
  ['RollbackAdditionalArguments']: {
    removeApprovals?: boolean | undefined | null
    removeBonuses?: boolean | undefined | null
    removeCard?: boolean | undefined | null
    removeContent?: boolean | undefined | null
    removeKeyword?: boolean | undefined | null
    resetBonusConfig?: boolean | undefined | null
  }
  ['SearchInputBoolean']: {
    match?: boolean | undefined | null
  }
  ['SearchInputNumber']: {
    field?: ResolverInputTypes['SearchInputField'] | undefined | null
    match?: number | undefined | null
    not?: boolean | undefined | null
    range?: ResolverInputTypes['SearchInputNumberRange'] | undefined | null
  }
  ['SearchInputNumberRange']: {
    gt?: number | undefined | null
    gte?: number | undefined | null
    lt?: number | undefined | null
    lte?: number | undefined | null
  }
  ['SearchInputQueryScript']: {
    params?: ResolverInputTypes['SearchInputScriptParams'] | undefined | null
    source: ResolverInputTypes['SearchInputQueryScriptSource']
  }
  ['SearchInputScriptParams']: {
    fieldNames?: Array<string> | undefined | null
    max?: number | undefined | null
    min?: number | undefined | null
  }
  ['SearchInputSortScript']: {
    params?: ResolverInputTypes['SearchInputScriptParams'] | undefined | null
    source: ResolverInputTypes['SearchInputSortScriptSource']
    type: ResolverInputTypes['SearchInputScriptType']
  }
  ['SearchInputString']: {
    field?: ResolverInputTypes['SearchInputField'] | undefined | null
    match?: string | undefined | null
    match_phrase_prefix?: string | undefined | null
    not?: boolean | undefined | null
    prefix?: string | undefined | null
    range?: ResolverInputTypes['SearchInputStringRange'] | undefined | null
    regexp?: string | undefined | null
    wildcard?: string | undefined | null
  }
  ['SearchInputStringRange']: {
    gt?: string | undefined | null
    gte?: string | undefined | null
    lt?: string | undefined | null
    lte?: string | undefined | null
  }
  ['SequenceFormSubmissionValuesInput']: {
    componentId: string
    componentName: string
    componentType?: ResolverInputTypes['FormComponentType'] | undefined | null
    sequenceId: string
    type?: ResolverInputTypes['FormFieldType'] | undefined | null
    value: string
  }
  ['SkuAttributeInput']: {
    name: string
    value: string
  }
  ['SocialMediaFeedbackInput']: {
    comments?: string | undefined | null
    rating?: number | undefined | null
  }
  ['SocialMetricInput']: {
    name: ResolverInputTypes['SocialMetricName']
    value: number
  }
  ['StagingApprovalConfigurationInput']: {
    stagingApprovalAssignee?: string | undefined | null
    stagingApprovalType: ResolverInputTypes['UserStagingApprovalType']
  }
  ['StatusTierBonusConfigInput']: {
    baseBonus?: number | undefined | null
    diamondBonus?: number | undefined | null
    goldBonus?: number | undefined | null
    platinumBonus?: number | undefined | null
    silverBonus?: number | undefined | null
  }
  ['StatusTierEntryInput']: {
    statusTier: ResolverInputTypes['StatusTier']
    statusTierAt: string
  }
  ['SubmitUserCampaignStateChangeInput']: {
    bonusRequest?: ResolverInputTypes['BonusRequestTypes'] | undefined | null
    campaignStatus?: ResolverInputTypes['CampaignStatus'] | undefined | null
    defect?: boolean | undefined | null
    id: string
    requestedByUserId?: string | undefined | null
    requestedUpdates?: string | undefined | null
    stateChangeRequestType?: ResolverInputTypes['UserCampaignStateChangeRequestType'] | undefined | null
  }
  ['UpdateAutoBuyDistributionInput']: {
    createdAt?: string | undefined | null
    description?: string | undefined | null
    id: string
    name?: string | undefined | null
    schedule?: Array<number> | undefined | null
    updatedAt?: string | undefined | null
  }
  ['UpdateAutoBuyFlowConfigurationInput']: {
    dailyPurchaseMax?: number | undefined | null
    delayStrategy?: ResolverInputTypes['AutoBuyDelayStrategy'] | undefined | null
    flowId: string
    insufficientCreators?: string | undefined | null
    latestSkipReason?: ResolverInputTypes['AutoBuySkipReason'] | undefined | null
    pauseReason?: ResolverInputTypes['AutoBuySkipReason'] | undefined | null
    schedule?: Array<ResolverInputTypes['AutoBuyScheduleInput']> | undefined | null
    startDate?: string | undefined | null
    status?: ResolverInputTypes['AutoBuyStatus'] | undefined | null
  }
  ['UpdateAutoBuyFlowReleaseScheduleInput']: {
    actualPurchases?: number | undefined | null
    actualVerifiedPurchases?: number | undefined | null
    adjustedDesiredPurchases?: number | undefined | null
    autoApprovalSeats?: number | undefined | null
    date: string
    desiredPurchases?: number | undefined | null
    flowId: string
    manualPurchases?: number | undefined | null
    manualReleases?: number | undefined | null
    projectedReleaseCount?: number | undefined | null
    releaseCount?: number | undefined | null
    released?: boolean | undefined | null
    skippedReason?: ResolverInputTypes['AutoBuySkipReason'] | undefined | null
  }
  ['UpdateAutoBuyReleaseInput']: {
    date: string
    productId?: string | undefined | null
    releaseDateTime?: string | undefined | null
    skuId?: string | undefined | null
    userCampaignId: string
  }
  ['UpdateBrandApiInput']: {
    accessToken?: string | undefined | null
    api: ResolverInputTypes['BrandApiType']
    apiVersion?: ResolverInputTypes['BrandApiVersion'] | undefined | null
    brandId: string
    enabled?: boolean | undefined | null
    locale?: ResolverInputTypes['WSLocale'] | undefined | null
    refreshToken?: string | undefined | null
    shop?: string | undefined | null
    shopId?: string | undefined | null
  }
  ['UpdateBrandInput']: {
    active?: string | undefined | null
    amazonBrandName?: string | undefined | null
    brandDetailsSubmitted?: boolean | undefined | null
    brandInstaHandle?: string | undefined | null
    brandLogoImageKey?: string | undefined | null
    brandStoreConfirmationImage?: string | undefined | null
    brandStoreConfirmationMobileImage?: string | undefined | null
    brandStoreScrapeUrl?: string | undefined | null
    brandTikTokHandle?: string | undefined | null
    companyId?: string | undefined | null
    contactEmails?: Array<string | undefined | null> | undefined | null
    defaultCreatorPreference?: ResolverInputTypes['CreatorPreference'] | undefined | null
    defaultCreatorPreferenceDetails?: string | undefined | null
    defaultHashtags?: Array<string> | undefined | null
    defaultMarketingBriefKey?: string | undefined | null
    defaultPostingGuidelines?: Array<string> | undefined | null
    defaultPostingInspirationLinks?: Array<string> | undefined | null
    emailsSent?: Array<string | undefined | null> | undefined | null
    fullScrapedAt?: string | undefined | null
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined | null
    isSeller?: string | undefined | null
    isVendor?: string | undefined | null
    isWalmartScrapeEnabled?: boolean | undefined | null
    lastScrapedAt?: string | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    paymentBillingContact?: ResolverInputTypes['BillingContactInput'] | undefined | null
    platform?: ResolverInputTypes['PlatformSource'] | undefined | null
    postingGuidanceDocument?: string | undefined | null
    region?: ResolverInputTypes['WSLocale'] | undefined | null
    socialMediaExampleUrls?: Array<string> | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    walmartBrandName?: string | undefined | null
    walmartLastScrapedAt?: string | undefined | null
    walmartScrapeUrl?: string | undefined | null
  }
  ['UpdateCampaignClicksInput']: {
    campaignId: string
    clicks?: number | undefined | null
    date: string
  }
  ['UpdateCampaignInput']: {
    aboutSection?: string | undefined | null
    active?: string | undefined | null
    actualStartDate?: string | undefined | null
    amountPaidByClient?: number | undefined | null
    anticipatedStartDate?: string | undefined | null
    applicationForm?: string | undefined | null
    applicationProductLinkId?: string | undefined | null
    applicationStatus?: ResolverInputTypes['ApplicationStatus'] | undefined | null
    attributionRefUrlSuffix?: string | undefined | null
    authorizedUnits?: number | undefined | null
    badges?: Array<ResolverInputTypes['CampaignBadgeInput'] | undefined | null> | undefined | null
    brandId?: string | undefined | null
    brandProductShort?: string | undefined | null
    brandStoreLink?: string | undefined | null
    budgetSpent?: number | undefined | null
    campaignProductType?: ResolverInputTypes['CampaignProductType'] | undefined | null
    campaignReferralBonusAmount?: number | undefined | null
    campaignReferralBonusEnabled?: ResolverInputTypes['CampaignReferralBonusFlag'] | undefined | null
    campaignStateMachine?: string | undefined | null
    closeoutReleaseFailureReason?: string | undefined | null
    closeoutReleaseRate?: ResolverInputTypes['RateInput'] | undefined | null
    collisionSkuDensity?: Array<ResolverInputTypes['CollisionDensityValueInput']> | undefined | null
    commissionFlatRate?: number | undefined | null
    commissionPercentageRate?: number | undefined | null
    committedClicks?: number | undefined | null
    committedPlacementWinsCount?: number | undefined | null
    committedReviewsCount?: number | undefined | null
    contentApprovalRequired?: boolean | undefined | null
    contentCompleteDeadline?: string | undefined | null
    createdAt?: string | undefined | null
    creationSourceVersion?: ResolverInputTypes['CreationSourceVersion'] | undefined | null
    earlyReviewEnabled?: boolean | undefined | null
    enableContentComplianceReview?: boolean | undefined | null
    endDate?: string | undefined | null
    excludeGender?: ResolverInputTypes['UserFilterGender'] | undefined | null
    expectedBonus?: string | undefined | null
    followersCountCeiling?: number | undefined | null
    followersCountFloor?: number | undefined | null
    goal?: ResolverInputTypes['CampaignGoal'] | undefined | null
    id: string
    influencerApprovalEnabled?: ResolverInputTypes['InfluencerApprovalFlagV2'] | undefined | null
    lifestyleCommittedContentCount?: number | undefined | null
    localeV1?: ResolverInputTypes['WSLocale'] | undefined | null
    marketingFee?: number | undefined | null
    marketingFormFilled?: boolean | undefined | null
    newCreatorsOnly?: boolean | undefined | null
    payForClicksEnabled?: ResolverInputTypes['EnabledFlag'] | undefined | null
    payForClicksGeneratedPerPeriod?: Array<ResolverInputTypes['ClicksRecordsInput']> | undefined | null
    payForClicksMinClicks?: number | undefined | null
    payForClicksOriginalLink?: string | undefined | null
    payForClicksTotalClicksGenerated?: number | undefined | null
    payForPerformanceEnabled?: ResolverInputTypes['PayForPerformanceFlag'] | undefined | null
    postingGuidance?: string | undefined | null
    preApplicationProductImage?: string | undefined | null
    preApplicationProductPriceRange?: string | undefined | null
    preApplicationUgcProductImage?: string | undefined | null
    preLaunchDate?: string | undefined | null
    premiumLifestyleCommittedContentCount?: number | undefined | null
    requiredProductLinksEnabled?: ResolverInputTypes['RequiredProductLinkFlag'] | undefined | null
    reviewVotingTarget?: string | undefined | null
    sample?: boolean | undefined | null
    shellCampaignBase?: string | undefined | null
    skuId?: string | undefined | null
    skuPriceOverride?: number | undefined | null
    socialCommittedContentCount?: number | undefined | null
    sourcingPriority?: number | undefined | null
    sponsorshipInstructions?: string | undefined | null
    title?: string | undefined | null
    totalEarlyReviewsAllowed?: number | undefined | null
    totalProductCost?: number | undefined | null
    userAutoApproved?: boolean | undefined | null
    visibleToUser?: string | undefined | null
  }
  ['UpdateCampaignInviteInput']: {
    campaignId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    notificationStatus?: ResolverInputTypes['NotificationStatus'] | undefined | null
    priority?: number | undefined | null
    updatedAt?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateCampaignSourcingProspectInput']: {
    bonusConfig?: ResolverInputTypes['UserCampaignBonusConfigInput'] | undefined | null
    campaignId: string
    createdAt?: string | undefined | null
    createdById?: string | undefined | null
    instagramFollowersInt?: number | undefined | null
    instagramHandle?: string | undefined | null
    internalNotesId?: string | undefined | null
    name?: string | undefined | null
    platformInviteSource?: ResolverInputTypes['PlatformInviteSource'] | undefined | null
    platformStatus?: ResolverInputTypes['PlatformStatus'] | undefined | null
    preApprovalStatus?: ResolverInputTypes['PreApprovalStatus'] | undefined | null
    preApprovalStatusById?: string | undefined | null
    priority?: ResolverInputTypes['SourcePriority'] | undefined | null
    productId?: string | undefined | null
    sourcingSource?: ResolverInputTypes['CampaignSourcingSource'] | undefined | null
    tiktokFollowersInt?: number | undefined | null
    tiktokHandle?: string | undefined | null
    userCampaignId?: string | undefined | null
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined | null
    youtubeChannelHandle?: string | undefined | null
  }
  ['UpdateChatConversationV2Input']: {
    active?: ResolverInputTypes['Active'] | undefined | null
    adminLastReadMessageIndex?: number | undefined | null
    adminUnreadCount?: number | undefined | null
    campaignId?: string | undefined | null
    chatType: ResolverInputTypes['ChatType']
    creatorLastReadMessageIndex?: number | undefined | null
    creatorUnreadCount?: number | undefined | null
    lastMessage?: string | undefined | null
    lastMessageDate?: string | undefined | null
    lastMessageId?: string | undefined | null
    lastMessageSource?: ResolverInputTypes['ConversationMessageSource'] | undefined | null
    resourceId: string
    status?: ResolverInputTypes['ConversationInternalStatus'] | undefined | null
    statusUpdatedAt?: string | undefined | null
    statusUpdatedByFirstName?: string | undefined | null
    statusUpdatedById?: string | undefined | null
    statusUpdatedByLastName?: string | undefined | null
    twilioConversationId?: string | undefined | null
    userCampaignId?: string | undefined | null
    userEmail?: string | undefined | null
    userFirstName?: string | undefined | null
    userId?: string | undefined | null
    userLastName?: string | undefined | null
  }
  ['UpdateCommunicationNotificationInput']: {
    address?: string | undefined | null
    attributes?: Array<ResolverInputTypes['KeyAttributeInput']> | undefined | null
    campaignId?: string | undefined | null
    channel?: ResolverInputTypes['Channel'] | undefined | null
    expectedSentAt?: string | undefined | null
    experimentTreatment?: string | undefined | null
    id: string
    notificationId?: string | undefined | null
    sentAt?: string | undefined | null
    status?: string | undefined | null
    statusMessage?: string | undefined | null
    templateId?: string | undefined | null
    traceId?: string | undefined | null
    userCampaignId?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateCompanyInput']: {
    agencyId?: string | undefined | null
    id: string
    logo?: string | undefined | null
    name?: string | undefined | null
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ResolverInputTypes['PaymentTermsType'] | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['UpdateConnectedAccountInput']: {
    accountId: string
    accountType?: ResolverInputTypes['ConnectedAccountType'] | undefined | null
    createdAt?: string | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['UpdateContentApprovalsInput']: {
    bonusOverride?: number | undefined | null
    editedCaption?: string | undefined | null
    nextContentApprovalAssignee?: string | undefined | null
    rejectionReason?: string | undefined | null
    requestType: ResolverInputTypes['AdminUpdateContentApprovalType']
    requestedContentChanges?: Array<ResolverInputTypes['RequestedContentChangesInput']> | undefined | null
    reviewType: ResolverInputTypes['UserCampaignSocialContentReviewType']
    userCampaignSocialContentId: string
  }
  ['UpdateConversationInput']: {
    contactOwnerId?: string | undefined | null
    lastContactAt?: string | undefined | null
    status?: ResolverInputTypes['ConversationStatus'] | undefined | null
    statusChangedAt?: string | undefined | null
    userId: string
  }
  ['UpdateConversationMessageInput']: {
    channel?: ResolverInputTypes['ConversationChannel'] | undefined | null
    id: string
    messageContent?: string | undefined | null
    messageId?: string | undefined | null
    messageMediaKeys?: Array<string> | undefined | null
    replyFromId?: string | undefined | null
    sendAt?: string | undefined | null
    sendAttempts?: number | undefined | null
    sentAt?: string | undefined | null
    status?: ResolverInputTypes['ConversationMessageStatus'] | undefined | null
    userId: string
  }
  ['UpdateEtailerSkuMetricsInput']: {
    date: string
    pageViews?: number | undefined | null
    sku?: string | undefined | null
    skuId: string
    totalSalesAmount?: number | undefined | null
    unitsSold?: number | undefined | null
  }
  ['UpdateFormSequenceConfigurationInput']: {
    active?: ResolverInputTypes['Active'] | undefined | null
    id: string
    sequenceName?: string | undefined | null
    sequences?: Array<ResolverInputTypes['FormSequenceInput']> | undefined | null
  }
  ['UpdateInstagramStoryScreenInput']: {
    exits?: number | undefined | null
    expirationUnixTime?: number | undefined | null
    externalId?: string | undefined | null
    id: string
    impressions?: number | undefined | null
    instagramUserId?: string | undefined | null
    mediaId?: string | undefined | null
    postedAt?: string | undefined | null
    postingDate?: string | undefined | null
    reach?: number | undefined | null
    replies?: number | undefined | null
    s3Bucket?: string | undefined | null
    tapsBack?: number | undefined | null
    tapsForward?: number | undefined | null
    userCampaignId?: string | undefined | null
  }
  ['UpdateMessageInput']: {
    body?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    updatedAt?: string | undefined | null
    userCampaignId?: string | undefined | null
    userHasSeen?: boolean | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateNoteInput']: {
    createdAt?: string | undefined | null
    id: string
    note?: string | undefined | null
    parentId?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateNotificationConfigurationInput']: {
    category?: string | undefined | null
    description?: string | undefined | null
    email?: ResolverInputTypes['ChannelConfigurationInput'] | undefined | null
    enabled?: boolean | undefined | null
    id: string
    inAppChatChannel?: ResolverInputTypes['MessagingChannelConfigurationInput'] | undefined | null
    name?: string | undefined | null
    occurrenceConstraint?: ResolverInputTypes['OccurrenceConstraintInput'] | undefined | null
    push?: ResolverInputTypes['PushChannelConfigurationInput'] | undefined | null
    sms?: ResolverInputTypes['ChannelConfigurationInput'] | undefined | null
  }
  ['UpdateProductBonusConfigSnapshotInput']: {
    closeoutSurveyBonus?: number | undefined | null
    contentBonuses?: Array<ResolverInputTypes['ProductSocialContentInput']> | undefined | null
    discoveryBonus?: number | undefined | null
    payForImpressionsBonus?: ResolverInputTypes['PayForImpressionsBonusConfigInput'] | undefined | null
    productId: string
    snapshotAt: string
  }
  ['UpdateProductInput']: {
    accountRequired?: boolean | undefined | null
    active?: string | undefined | null
    approvalFilters?: Array<ResolverInputTypes['ApprovalFilterValueInput']> | undefined | null
    autoApprovalSeatsAvailable?: number | undefined | null
    autoApprovalStatusTiers?: Array<ResolverInputTypes['StatusTier']> | undefined | null
    autoApprovalV2Config?: ResolverInputTypes['AutoApprovalV2ConfigInput'] | undefined | null
    automaticReviewRelease?: boolean | undefined | null
    baseBonus?: number | undefined | null
    brandAdvocateCount?: number | undefined | null
    brandTalkingPoints?: Array<string> | undefined | null
    buyOnlinePickupInStore?: ResolverInputTypes['BOPISValue'] | undefined | null
    campaignId?: string | undefined | null
    campaignStateMachine?: string | undefined | null
    closeoutFormBonus?: number | undefined | null
    contentApprovalConfiguration?: Array<ResolverInputTypes['ContentApprovalConfigurationInput']> | undefined | null
    contentApprovalLeadDays?: number | undefined | null
    contentRequirement?: Array<ResolverInputTypes['ProductSocialContentInput']> | undefined | null
    creatorCount?: number | undefined | null
    creatorPreference?: ResolverInputTypes['CreatorPreference'] | undefined | null
    creatorPreferenceDetails?: string | undefined | null
    customFtcHashtags?: Array<string> | undefined | null
    customHashtags?: Array<string> | undefined | null
    directPurchaseLink?: string | undefined | null
    engagementRateInstagramRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    engagementRateTiktokRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    followersInstagramRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    followersTiktokRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    hideBrandContentApprovals?: boolean | undefined | null
    id: string
    impressionsInstagramRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    impressionsTiktokRangeRequirement?: ResolverInputTypes['MinMaxRangeInput'] | undefined | null
    instaHandle?: string | undefined | null
    internalCreatorsStagingGuidance?: string | undefined | null
    internalDescription?: string | undefined | null
    internalName?: string | undefined | null
    internalNotes?: string | undefined | null
    isDirectListingPurchase?: boolean | undefined | null
    isQualityRequirementsEnabled?: boolean | undefined | null
    listingGuidance?: string | undefined | null
    maxDiscoveryBonus?: number | undefined | null
    minimumCreatorQualityScore?: number | undefined | null
    nonRetailReviewLink?: string | undefined | null
    nonRetailReviewWebsiteName?: string | undefined | null
    payForClicksEnabled?: ResolverInputTypes['EnabledFlag'] | undefined | null
    payForClicksMinClicks?: number | undefined | null
    payForImpressionsBonus?: ResolverInputTypes['PayForImpressionsBonusConfigInput'] | undefined | null
    payForPerformanceBonus?: ResolverInputTypes['StatusTierBonusConfigInput'] | undefined | null
    payForPostingContentEarly?: ResolverInputTypes['PayForPostingContentEarlyConfigInput'] | undefined | null
    postingDeadlineDaysRelativeToApproval?: number | undefined | null
    productLinkId?: string | undefined | null
    productPostingGuidance?: string | undefined | null
    productPostingGuidanceDocument?: string | undefined | null
    promoCode?: string | undefined | null
    promoCodeGuidance?: string | undefined | null
    promoCodeType?: ResolverInputTypes['PromoCodeType'] | undefined | null
    promoDiscountAmount?: number | undefined | null
    promoDiscountType?: ResolverInputTypes['PromoDiscountType'] | undefined | null
    promoEndDate?: string | undefined | null
    promoLink?: string | undefined | null
    promoStartDate?: string | undefined | null
    promotionLinkId?: string | undefined | null
    purchaseByPromoCode?: boolean | undefined | null
    purchaseDiscountCode?: string | undefined | null
    purchaseDiscountCodeDetails?: string | undefined | null
    purchaseDiscountCodePercentage?: string | undefined | null
    qualityLevel?: ResolverInputTypes['ProductQualityLevel'] | undefined | null
    requestEarlyProductFeedback?: boolean | undefined | null
    requestProductFeedback?: boolean | undefined | null
    requiredProductLinkEnabled?: ResolverInputTypes['RequiredProductLinkFlag'] | undefined | null
    sequenceId?: string | undefined | null
    shopifyPriceRule?: string | undefined | null
    shopifyPromotionId?: string | undefined | null
    shopifyPromotionType?: ResolverInputTypes['ShopifyPromotionType'] | undefined | null
    skuId?: string | undefined | null
    socialMediaExampleUrls?: Array<string> | undefined | null
    stagingApprovalConfiguration?: Array<ResolverInputTypes['StagingApprovalConfigurationInput']> | undefined | null
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined | null
    stagingRequirementType?: ResolverInputTypes['StagingRequirementType'] | undefined | null
    stagingSocialQualityRequirement?: ResolverInputTypes['SocialQuality'] | undefined | null
    tikTokHandle?: string | undefined | null
    unitsPurchased?: number | undefined | null
    validateProductFoundVariations?: Array<string> | undefined | null
    visibleAt?: string | undefined | null
    visibleEndsAt?: string | undefined | null
  }
  ['UpdateProductKeywordsInput']: {
    adminPriority?: number | undefined | null
    brandPriority?: number | undefined | null
    brandResponse?: ResolverInputTypes['KeywordAcceptanceResponse'] | undefined | null
    campaignId?: string | undefined | null
    creationSource?: ResolverInputTypes['ProductKeywordCreationSource'] | undefined | null
    endDate?: string | undefined | null
    heliumAccount?: string | undefined | null
    heliumAsin?: string | undefined | null
    heliumLink?: string | undefined | null
    id: string
    keyword?: string | undefined | null
    monthlySearchVolume?: number | undefined | null
    rank?: number | undefined | null
    rankingHistory?: Array<ResolverInputTypes['RankPointInput']> | undefined | null
    rankingLastSkippedAt?: string | undefined | null
    rankingLastSkippedByUserId?: string | undefined | null
    rankingLastUpdatedAt?: string | undefined | null
    rankingLastUpdatedByUserId?: string | undefined | null
    recommended?: boolean | undefined | null
    skuId?: string | undefined | null
    startDate?: string | undefined | null
    usedForCampaign?: boolean | undefined | null
    visible?: boolean | undefined | null
    weight?: number | undefined | null
  }
  ['UpdateProductRatingSnapshotInput']: {
    numFiveStar?: number | undefined | null
    numFourStar?: number | undefined | null
    numOneStar?: number | undefined | null
    numRatings?: number | undefined | null
    numReviews?: number | undefined | null
    numThreeStar?: number | undefined | null
    numTwoStar?: number | undefined | null
    rating?: number | undefined | null
    sku?: string | undefined | null
    skuId: string
    snapshotAt: string
  }
  ['UpdatePurchaseInput']: {
    amount?: number | undefined | null
    cardId?: string | undefined | null
    currency?: string | undefined | null
    id: string
    isFraud?: boolean | undefined | null
    keywordAtPurchase?: string | undefined | null
    purchasedAt?: string | undefined | null
    shippedAt?: string | undefined | null
    status?: ResolverInputTypes['PurchaseStatus'] | undefined | null
    userCampaignId?: string | undefined | null
  }
  ['UpdateReferralInput']: {
    bonusPayoutId?: string | undefined | null
    campaignId?: string | undefined | null
    id: string
    referralType?: ResolverInputTypes['ReferralType'] | undefined | null
    referredUserId?: string | undefined | null
    referrerUserId?: string | undefined | null
  }
  ['UpdateReviewInput']: {
    attributedUserId?: string | undefined | null
    author?: string | undefined | null
    authorBadge?: string | undefined | null
    badge?: string | undefined | null
    commentCount?: number | undefined | null
    helpfulReactions?: number | undefined | null
    id: string
    negativeReactions?: number | undefined | null
    photos?: Array<string> | undefined | null
    productId?: string | undefined | null
    productVariant?: string | undefined | null
    rating?: number | undefined | null
    removed?: boolean | undefined | null
    reviewedAt?: string | undefined | null
    reviewedSku?: string | undefined | null
    reviewerId?: string | undefined | null
    sku?: string | undefined | null
    skuId?: string | undefined | null
    store?: string | undefined | null
    text?: string | undefined | null
    title?: string | undefined | null
    userCampaignId?: string | undefined | null
    videos?: Array<string> | undefined | null
  }
  ['UpdateSkuInput']: {
    active?: string | undefined | null
    approxRetailPrice?: string | undefined | null
    approxShippingCost?: number | undefined | null
    attributes?: Array<ResolverInputTypes['SkuAttributeInput']> | undefined | null
    availabilityDate?: string | undefined | null
    availabilityNotified?: boolean | undefined | null
    availabilityNotifiedAt?: string | undefined | null
    brandId?: string | undefined | null
    brandListingKey?: string | undefined | null
    brandStoreId?: string | undefined | null
    bundle?: string | undefined | null
    createdByUserId?: string | undefined | null
    creationSource?: ResolverInputTypes['SkuCreationSource'] | undefined | null
    creationSourceUrl?: string | undefined | null
    currency?: ResolverInputTypes['Currency'] | undefined | null
    currentBuyBoxPrice?: number | undefined | null
    currentBuyBoxPriceCents?: number | undefined | null
    estimatedAvailabilityDate?: string | undefined | null
    estimatedDeliveryDays?: number | undefined | null
    estimatedMonthlyUnitsSold?: number | undefined | null
    estimatedWeeklyUnitsSold?: number | undefined | null
    exactListingTitle?: string | undefined | null
    exactRetailPrice?: string | undefined | null
    fullReviewScrapeComplete?: boolean | undefined | null
    id: string
    inventory?: number | undefined | null
    iosAppProviderId?: string | undefined | null
    isAplusPage?: string | undefined | null
    isPrime?: string | undefined | null
    isRecommendationsHidden?: boolean | undefined | null
    itemClassification?: ResolverInputTypes['ItemClassification'] | undefined | null
    lastAutoFilledAt?: string | undefined | null
    lastKnownBuyBoxPrice?: number | undefined | null
    lastKnownBuyBoxPriceCents?: number | undefined | null
    listingBrand?: string | undefined | null
    listingCategory?: Array<string> | undefined | null
    listingDescription?: string | undefined | null
    listingFulfillmentType?: ResolverInputTypes['ListingFulfillmentType'] | undefined | null
    listingGuidance?: string | undefined | null
    listingLink?: string | undefined | null
    mccValue?: ResolverInputTypes['ValidMccCodes'] | undefined | null
    minimumOrderQuantity?: number | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    nonSponsoredListingKey?: string | undefined | null
    parentSkuId?: string | undefined | null
    priceCents?: number | undefined | null
    product?: string | undefined | null
    productImageKey?: string | undefined | null
    productPostingGuidance?: string | undefined | null
    productPostingGuidanceDocument?: string | undefined | null
    productSearchImageKey?: string | undefined | null
    releaseDate?: string | undefined | null
    skuId?: string | undefined | null
    sponsoredListingKey?: string | undefined | null
    storeName?: string | undefined | null
    targetReviewRate?: ResolverInputTypes['RateInput'] | undefined | null
    targetReviewRateInvalid?: string | undefined | null
  }
  ['UpdateSkuSnapshotInput']: {
    inventory?: number | undefined | null
    marketplaceSku: string
    price?: number | undefined | null
    skuId?: string | undefined | null
    snapshotDate: string
  }
  ['UpdateUserBonusPayoutInput']: {
    adminPaymentApproved?: boolean | undefined | null
    bonusType?: ResolverInputTypes['BonusPayoutTypes'] | undefined | null
    createdAt?: string | undefined | null
    failureReason?: string | undefined | null
    id: string
    manuallyCreatedBy?: string | undefined | null
    payoutAmount?: number | undefined | null
    payoutAmountCents?: number | undefined | null
    payoutBatchId?: string | undefined | null
    payoutDate?: string | undefined | null
    payoutLocale?: ResolverInputTypes['WSLocale'] | undefined | null
    payoutState?: ResolverInputTypes['BonusPayoutState'] | undefined | null
    quantity?: number | undefined | null
    referenceId?: string | undefined | null
    releaseAt?: string | undefined | null
    revocationType?: ResolverInputTypes['RevocationType'] | undefined | null
    revokedAt?: string | undefined | null
    userCampaignId?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateUserCampaignChangelogInput']: {
    createdAt: string
    createdByUserId?: string | undefined | null
    entryType?: ResolverInputTypes['UserCampaignChangelogEntryType'] | undefined | null
    extraData?: string | undefined | null
    userCampaignId: string
  }
  ['UpdateUserCampaignInput']: {
    accountScreenShot?: string | undefined | null
    amznReviewLink?: string | undefined | null
    amznReviewSubmissionStatus?: ResolverInputTypes['AmazonReviewSubmissionStatus'] | undefined | null
    applicationRejectedByUserId?: string | undefined | null
    approvalOutcome?: ResolverInputTypes['ApprovalOutcome'] | undefined | null
    approvalStatus?: ResolverInputTypes['UserCampaignApprovalStatus'] | undefined | null
    archivedFeedback?: string | undefined | null
    archivedReason?: ResolverInputTypes['ArchivedReason'] | undefined | null
    autoBuyApprovalDate?: string | undefined | null
    autoBuyRevokeDate?: string | undefined | null
    bestBuyReviewScreenShot?: string | undefined | null
    bonusConfig?: ResolverInputTypes['UserCampaignBonusConfigInput'] | undefined | null
    campaignAcceptanceStatus?: ResolverInputTypes['UserCampaignAcceptanceStatus'] | undefined | null
    campaignApplication?: string | undefined | null
    campaignId?: string | undefined | null
    campaignStatus?: ResolverInputTypes['CampaignStatus'] | undefined | null
    campaignStatusOnArchive?: string | undefined | null
    cardId?: string | undefined | null
    cardRegion?: ResolverInputTypes['StripeRegion'] | undefined | null
    closeoutSurvey?: string | undefined | null
    coarseCampaignStatus?: ResolverInputTypes['CoarseCampaignStatus'] | undefined | null
    contentApprovalLeadDays?: number | undefined | null
    createdAt?: string | undefined | null
    discoverySurvey?: string | undefined | null
    id: string
    influencerApprovalInviteFeedback?: string | undefined | null
    influencerApprovalRejectFeedback?: string | undefined | null
    invoiceKey?: string | undefined | null
    isAutoApproved?: boolean | undefined | null
    keywordAtPurchase?: string | undefined | null
    keywordId?: string | undefined | null
    keywordsV2Hash?: string | undefined | null
    listingKey?: string | undefined | null
    localeV1?: ResolverInputTypes['WSLocale'] | undefined | null
    manualPurchase?: boolean | undefined | null
    moderatorStagingApprovalAt?: string | undefined | null
    moderatorStagingApprovalBy?: string | undefined | null
    moderatorStagingApprovalOutcome?: ResolverInputTypes['StagingApprovalOutcome'] | undefined | null
    orderId?: string | undefined | null
    orderIdInvalidReason?: string | undefined | null
    orderIdSubmissionDate?: string | undefined | null
    orderIdValidationDate?: string | undefined | null
    orderIdValidationStatus?: ResolverInputTypes['OrderIdValidationStatus'] | undefined | null
    orderStatus?: string | undefined | null
    payForClickLinkId?: string | undefined | null
    paypalEmailAtSubmission?: string | undefined | null
    postingDeadline?: string | undefined | null
    postingDeadlineExtended?: boolean | undefined | null
    previousCardIds?: Array<string> | undefined | null
    priority?: ResolverInputTypes['SourcePriority'] | undefined | null
    productCriticalFeedback?: string | undefined | null
    productId?: string | undefined | null
    productPositiveFeedback?: string | undefined | null
    rankPriority?: number | undefined | null
    reviewScreenshot?: string | undefined | null
    reviewScreenshotVerificationResult?: ResolverInputTypes['ReviewScreenshotVerificationResult'] | undefined | null
    schedulingReleaseAt?: string | undefined | null
    searchPhraseKey?: string | undefined | null
    selectedFlow?: string | undefined | null
    selfieVideoApprovalDate?: string | undefined | null
    selfieVideoApprovalNotes?: string | undefined | null
    selfieVideoApprovalStatus?: ResolverInputTypes['SelfieVideoApprovalStatus'] | undefined | null
    selfieVideoKey?: string | undefined | null
    selfieVideoRejectionReason?: string | undefined | null
    selfieVideoSubmissionRequired?: boolean | undefined | null
    selfieVideoSubmittedAt?: string | undefined | null
    sequenceFormSubmissionValues?: Array<ResolverInputTypes['SequenceFormSubmissionValuesInput']> | undefined | null
    shippingAddress?: string | undefined | null
    showEarlyReviewSurvey?: boolean | undefined | null
    statusHistory?: string | undefined | null
    updatedAt?: string | undefined | null
    userId?: string | undefined | null
    walmartReviewScreenShot?: string | undefined | null
  }
  ['UpdateUserCampaignSnapshotInput']: {
    snapshot?: string | undefined | null
    userCampaignId: string
    userCampaignVersion: number
  }
  ['UpdateUserCampaignSocialContentDraftHistoryInput']: {
    id: string
    snapshot?: string | undefined | null
    snapshotDate?: string | undefined | null
    userCampaignSocialContentId?: string | undefined | null
  }
  ['UpdateUserCampaignSocialContentInput']: {
    approvalFeedback?: string | undefined | null
    approvalStatus?: ResolverInputTypes['ContentApprovalStatus'] | undefined | null
    approvedAt?: string | undefined | null
    approverPWUserId?: string | undefined | null
    approverUserId?: string | undefined | null
    bonusEarned?: number | undefined | null
    bonusOverride?: number | undefined | null
    caption?: string | undefined | null
    channel?: ResolverInputTypes['SocialChannel'] | undefined | null
    clientFeedback?: Array<ResolverInputTypes['SocialMediaFeedbackInput']> | undefined | null
    complianceReview?: Array<ResolverInputTypes['ContentComplianceReviewCriteriaInput']> | undefined | null
    complianceReviewStatus?: ResolverInputTypes['ContentComplianceReviewStatus'] | undefined | null
    complianceReviewUserId?: string | undefined | null
    complianceReviewedAt?: string | undefined | null
    contentType?: ResolverInputTypes['SocialContentType'] | undefined | null
    draftEnteredAdminApprovalAt?: string | undefined | null
    draftEnteredBrandApprovalAt?: string | undefined | null
    draftFeedback?: Array<string> | undefined | null
    draftHistory?: Array<string> | undefined | null
    draftMedia?: Array<string> | undefined | null
    draftPreApprovedText?: string | undefined | null
    draftQualityScore?: number | undefined | null
    draftReview?: Array<ResolverInputTypes['ContentComplianceReviewCriteriaInput']> | undefined | null
    draftStatus?: ResolverInputTypes['DraftStatus'] | undefined | null
    draftSubmittedAt?: string | undefined | null
    draftText?: string | undefined | null
    earlySubmissionBonusEarnedCents?: number | undefined | null
    experimentId?: string | undefined | null
    externalUrl?: string | undefined | null
    groupId?: string | undefined | null
    hashtags?: Array<string> | undefined | null
    id: string
    instance?: number | undefined | null
    internalFeedback?: Array<ResolverInputTypes['SocialMediaFeedbackInput']> | undefined | null
    isFavorite?: boolean | undefined | null
    isHidden?: boolean | undefined | null
    isPurchased?: boolean | undefined | null
    isUnavailable?: boolean | undefined | null
    manualUploadType?: ResolverInputTypes['ManualUploadType'] | undefined | null
    mediaId?: string | undefined | null
    mediaUrls?: Array<string> | undefined | null
    metrics?: Array<ResolverInputTypes['SocialMetricInput']> | undefined | null
    paymentId?: string | undefined | null
    postedAt?: string | undefined | null
    qualityScore?: number | undefined | null
    rejectionReason?: string | undefined | null
    requestedSocialScript?: boolean | undefined | null
    requirementLevel?: ResolverInputTypes['ContentRequirementLevel'] | undefined | null
    scrapeStatus?: ResolverInputTypes['ScrapeStatus'] | undefined | null
    unavailableAt?: string | undefined | null
    usedRequestedSocialScript?: boolean | undefined | null
    userCampaignId?: string | undefined | null
    videoEndTime?: string | undefined | null
    videoStartTime?: string | undefined | null
  }
  ['UpdateUserCampaignSocialContentReviewInput']: {
    createdAt?: string | undefined | null
    reviewFeedback?: Array<ResolverInputTypes['UserCampaignSocialContentReviewFeedbackInput']> | undefined | null
    reviewOutcome?: ResolverInputTypes['UserCampaignSocialContentReviewOutcome'] | undefined | null
    reviewStatus?: ResolverInputTypes['UserCampaignSocialContentReviewStatus'] | undefined | null
    reviewType: ResolverInputTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined | null
    reviewerAssigneeId?: string | undefined | null
    reviewerFirstName?: string | undefined | null
    reviewerId?: string | undefined | null
    reviewerLastName?: string | undefined | null
    userCampaignSocialContentId: string
    userId?: string | undefined | null
  }
  ['UpdateUserDeviceSnapshotInput']: {
    id: string
    idfv?: string | undefined | null
    ipAddress?: string | undefined | null
    isEmulator?: boolean | undefined | null
    snapshotAt?: string | undefined | null
    timezone?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateUserFollowerInput']: {
    createdAt?: string | undefined | null
    followerUserId: string
    isFavorite?: ResolverInputTypes['FavoriteFlag'] | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['UpdateUserInput']: {
    active?: ResolverInputTypes['Active'] | undefined | null
    addressComponents?: string | undefined | null
    amznLastChecked?: string | undefined | null
    amznLink?: string | undefined | null
    amznPrime?: string | undefined | null
    amznProfileId?: string | undefined | null
    amznReviewerId?: string | undefined | null
    amznVerified?: string | undefined | null
    attributedReviewCount?: number | undefined | null
    blogWebsite?: string | undefined | null
    brandContentRatingAvg?: number | undefined | null
    campaignAcceptanceRate?: number | undefined | null
    campaignApprovalRate?: number | undefined | null
    campaignCompletionRate?: number | undefined | null
    campaignReviewRate?: number | undefined | null
    cardProfileUpdates?: string | undefined | null
    cardholderId?: string | undefined | null
    cardholderRegion?: ResolverInputTypes['StripeRegion'] | undefined | null
    characteristics?: Array<ResolverInputTypes['UserCharacteristicInput']> | undefined | null
    characteristicsUpdatedAt?: string | undefined | null
    claimedReviewCount?: number | undefined | null
    collisionSkuSet?: Array<string> | undefined | null
    collisionSkuSetCalculatedAt?: string | undefined | null
    completedLifestyleCampaignCount?: number | undefined | null
    completedLiftCampaignCount?: number | undefined | null
    completedSocialCampaignCount?: number | undefined | null
    contentRating?: ResolverInputTypes['UserContentRatingInput'] | undefined | null
    contentRatingCompletedAt?: string | undefined | null
    contentRatingCompletionTimeMillis?: number | undefined | null
    contentRatingInstagramCompletionTimeMillis?: number | undefined | null
    contentRatingInstagramSponsorshipContent?:
      | Array<ResolverInputTypes['ContentRatingSponsorshipContentInput']>
      | undefined
      | null
    contentRatingQualityScore?: number | undefined | null
    contentRatingReviewStatus?: ResolverInputTypes['ContentRatingReviewStatus'] | undefined | null
    contentRatingSponsorshipContent?:
      | Array<ResolverInputTypes['ContentRatingSponsorshipContentInput']>
      | undefined
      | null
    contentRatingSubmittedBy?: string | undefined | null
    contentRatingTiktokCompletionTimeMillis?: number | undefined | null
    contentRatingTiktokSponsorshipContent?:
      | Array<ResolverInputTypes['ContentRatingSponsorshipContentInput']>
      | undefined
      | null
    contentTypes?: Array<string> | undefined | null
    createdAt?: string | undefined | null
    date_of_birth?: string | undefined | null
    deactivationDate?: string | undefined | null
    deactivationReason?: string | undefined | null
    declineToReviewCount?: number | undefined | null
    deranked?: ResolverInputTypes['Deranked_Reason'] | undefined | null
    email?: string | undefined | null
    emailOptOut?: boolean | undefined | null
    facebookAccessToken?: string | undefined | null
    facebookAccessTokenExpiry?: string | undefined | null
    facebookPageId?: string | undefined | null
    facebookPageIsSubscribed?: boolean | undefined | null
    facebookPermissionScopes?: Array<string | undefined | null> | undefined | null
    facebookUserId?: string | undefined | null
    firstName?: string | undefined | null
    followsWesponsored?: string | undefined | null
    fraudReason?: ResolverInputTypes['FraudReason'] | undefined | null
    fraudReviewAt?: string | undefined | null
    fraudReviewStatus?: ResolverInputTypes['FraudReviewStatus'] | undefined | null
    gender?: string | undefined | null
    hasTrueEngagement?: boolean | undefined | null
    id: string
    idfaId?: string | undefined | null
    incompleteCampaignCount?: number | undefined | null
    instagramBio?: string | undefined | null
    instagramBusinessCategoryName?: string | undefined | null
    instagramCategoryName?: string | undefined | null
    instagramChecked?: string | undefined | null
    instagramContentTypeRating?: Array<ResolverInputTypes['ContentTypeRating']> | undefined | null
    instagramExternalLink?: string | undefined | null
    instagramFollowerCountryDistribution?:
      | Array<ResolverInputTypes['InstagramCountryDistributionItemInput']>
      | undefined
      | null
    instagramFollowerGenderAgeDistribution?:
      | ResolverInputTypes['InstagramGenderAgeDistributionInput']
      | undefined
      | null
    instagramFollowersInt?: number | undefined | null
    instagramHandle?: string | undefined | null
    instagramIsBusiness?: string | undefined | null
    instagramIsPrivate?: string | undefined | null
    instagramIsRecentlyJoined?: string | undefined | null
    instagramIsVerified?: string | undefined | null
    instagramLastChecked?: string | undefined | null
    instagramMedianShares?: number | undefined | null
    instagramMetricsRetrievedAt?: string | undefined | null
    instagramName?: string | undefined | null
    instagramPostsInt?: number | undefined | null
    instagramPriority?: ResolverInputTypes['PlatformPriority'] | undefined | null
    instagramProfilePic?: string | undefined | null
    instagramTrailingComments?: number | undefined | null
    instagramTrailingDateOfOldestPost?: string | undefined | null
    instagramTrailingLikes?: number | undefined | null
    instagramTrailingNumberOfPosts?: number | undefined | null
    instagramTrailingPostEngagementAvg?: number | undefined | null
    instagramTrailingPostEngagementRateAvg?: number | undefined | null
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined | null
    instagramTrailingPostEngagementRateMedian?: number | undefined | null
    instagramTrailingPostImpressionsAvg?: number | undefined | null
    instagramTrailingPostImpressionsMedian?: number | undefined | null
    instagramTrailingPostSponsoredPercentage?: number | undefined | null
    instagramTrailingPostViewsMedian?: number | undefined | null
    instagramUserId?: string | undefined | null
    instagramVerificationMethod?: ResolverInputTypes['SocialVerificationMethod'] | undefined | null
    internalGender?: ResolverInputTypes['UserGender'] | undefined | null
    isBrandSafe?: ResolverInputTypes['BrandSafe'] | undefined | null
    isBrandSafeReason?: string | undefined | null
    isBrandSafeUpdatedAt?: string | undefined | null
    lastActiveAt?: string | undefined | null
    lastName?: string | undefined | null
    manualScoringLockedUntil?: string | undefined | null
    moreinfo?: string | undefined | null
    numEvenDollarPurchases?: number | undefined | null
    numPurchases?: number | undefined | null
    paypalEmail?: string | undefined | null
    phoneNumber?: string | undefined | null
    profilePicture?: string | undefined | null
    pushAddresses?: Array<ResolverInputTypes['PushAddressInput']> | undefined | null
    pushOptOut?: boolean | undefined | null
    referralCode?: string | undefined | null
    referralCount?: number | undefined | null
    referrer?: string | undefined | null
    signupCountry?: string | undefined | null
    smsOptOut?: boolean | undefined | null
    socialQuality?: ResolverInputTypes['SocialQuality'] | undefined | null
    specialAttributes?: ResolverInputTypes['UserAttributesInput'] | undefined | null
    statusTier?: ResolverInputTypes['StatusTier'] | undefined | null
    statusTierHistory?: Array<ResolverInputTypes['StatusTierEntryInput']> | undefined | null
    statusTierLastUpdatedAt?: string | undefined | null
    supportPriorityAcknowledgedAt?: string | undefined | null
    supportPriorityFlag?: ResolverInputTypes['SupportPriorityFlag'] | undefined | null
    supportPriorityFlagSetAt?: string | undefined | null
    targetAccount?: string | undefined | null
    tiktokAccessToken?: string | undefined | null
    tiktokAccessTokenExpiry?: string | undefined | null
    tiktokAccountId?: string | undefined | null
    tiktokAveragePostEngagementRate?: number | undefined | null
    tiktokAveragePostEngagementRateViews?: number | undefined | null
    tiktokBio?: string | undefined | null
    tiktokContentTypeRating?: Array<ResolverInputTypes['ContentTypeRating']> | undefined | null
    tiktokFollowersInt?: number | undefined | null
    tiktokHandle?: string | undefined | null
    tiktokMedianComments?: number | undefined | null
    tiktokMedianLikes?: number | undefined | null
    tiktokMedianPostEngagement?: number | undefined | null
    tiktokMedianPostEngagementRate?: number | undefined | null
    tiktokMedianPostEngagementRateViews?: number | undefined | null
    tiktokMedianShares?: number | undefined | null
    tiktokMedianViews?: number | undefined | null
    tiktokMetricsRetrievedAt?: string | undefined | null
    tiktokName?: string | undefined | null
    tiktokPermissionScopes?: Array<string> | undefined | null
    tiktokPriority?: ResolverInputTypes['PlatformPriority'] | undefined | null
    tiktokProfilePic?: string | undefined | null
    tiktokRefreshToken?: string | undefined | null
    tiktokRefreshTokenExpiry?: string | undefined | null
    tiktokSponsoredPostPercentage?: number | undefined | null
    tiktokVerificationMethod?: ResolverInputTypes['SocialVerificationMethod'] | undefined | null
    tiktokVerified?: string | undefined | null
    timeZone?: string | undefined | null
    trackingStatus?: ResolverInputTypes['TrackingStatus'] | undefined | null
    twitchAccount?: string | undefined | null
    type?: string | undefined | null
    utmReferral?: ResolverInputTypes['UtmReferralInput'] | undefined | null
    wmartAccount?: string | undefined | null
    youtubeAccessToken?: string | undefined | null
    youtubeAccessTokenExpiry?: string | undefined | null
    youtubeAccount?: string | undefined | null
    youtubeAveragePostEngagementRate?: number | undefined | null
    youtubeChannelDescription?: string | undefined | null
    youtubeChannelFollowersInt?: number | undefined | null
    youtubeChannelHandle?: string | undefined | null
    youtubeChannelId?: string | undefined | null
    youtubeChannelPicture?: string | undefined | null
    youtubeChannelTitle?: string | undefined | null
    youtubeIdToken?: string | undefined | null
    youtubeMedianViews?: number | undefined | null
    youtubeMetricsRetrievedAt?: string | undefined | null
    youtubePermissionScopes?: Array<string> | undefined | null
    youtubeRefreshToken?: string | undefined | null
    youtubeVerificationMethod?: ResolverInputTypes['SocialVerificationMethod'] | undefined | null
  }
  ['UpdateUserLinkInput']: {
    campaignId?: string | undefined | null
    clicksPerPeriod?: Array<ResolverInputTypes['ClicksRecordsInput']> | undefined | null
    createdAt?: string | undefined | null
    id: string
    linkId?: string | undefined | null
    originalLink?: string | undefined | null
    rank?: number | undefined | null
    storageType?: ResolverInputTypes['LinkStorageType'] | undefined | null
    uniqueClicks?: number | undefined | null
    updatedAt?: string | undefined | null
    userEncodedLink?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateUserNotificationPreferenceInput']: {
    category: string
    emailOptOut?: boolean | undefined | null
    pushOptOut?: boolean | undefined | null
    smsOptOut?: boolean | undefined | null
    userId: string
  }
  ['UpdateUserPayoutRequestInput']: {
    failureReason?: string | undefined | null
    id: string
    manuallyApprovedBy?: string | undefined | null
    payoutAmountCents?: number | undefined | null
    payoutBatchId?: string | undefined | null
    payoutDate?: string | undefined | null
    payoutLocale?: ResolverInputTypes['WSLocale'] | undefined | null
    payoutState?: ResolverInputTypes['BonusPayoutState'] | undefined | null
    userId?: string | undefined | null
  }
  ['UpdateUserStagingApprovalInput']: {
    id: string
    reviewFeedback?: string | undefined | null
    reviewedAt?: string | undefined | null
    reviewedBy?: string | undefined | null
    reviewedByEmail?: string | undefined | null
    reviewedByName?: string | undefined | null
    reviewedBySource?: ResolverInputTypes['UserStagingApprovalReviewSource'] | undefined | null
    userCampaignId?: string | undefined | null
    userStagingApprovalOutcome?: ResolverInputTypes['UserStagingApprovalOutcome'] | undefined | null
    userStagingApprovalType?: ResolverInputTypes['UserStagingApprovalType'] | undefined | null
  }
  ['UpdateVideoContentScanInput']: {
    audioDuration?: number | undefined | null
    audioEndAt?: number | undefined | null
    audioStartFrom?: number | undefined | null
    contentSafetyAnalysis?: ResolverInputTypes['ContentSafetyAnalysisInput'] | undefined | null
    id: string
    processFinishedAt?: string | undefined | null
    processId?: string | undefined | null
    processInitiatedAt?: string | undefined | null
    scanStatus?: ResolverInputTypes['ContentScanStatus'] | undefined | null
    socialMediaUrl?: string | undefined | null
    transcription?: string | undefined | null
    transcriptionConfidence?: number | undefined | null
    url?: string | undefined | null
    userId?: string | undefined | null
  }
  ['UserAttributesInput']: {
    isMother?: boolean | undefined | null
  }
  ['UserCampaignBonusConfigInput']: {
    closeoutSurveyBonus?: number | undefined | null
    contentBonuses?: Array<ResolverInputTypes['ProductSocialContentInput']> | undefined | null
    discoveryBonus?: number | undefined | null
    expectedBonus?: string | undefined | null
    hasCustomBonusConfig?: boolean | undefined | null
    payForImpressionsBonus?: ResolverInputTypes['PayForImpressionsBonusConfigInput'] | undefined | null
  }
  ['UserCampaignSearchFields']: {
    campaignId?: ResolverInputTypes['SearchInputString'] | undefined | null
    campaignStatus?: ResolverInputTypes['SearchInputString'] | undefined | null
    prospect?: ResolverInputTypes['ProspectSearchFields'] | undefined | null
  }
  ['UserCampaignSocialContentReviewFeedbackInput']: {
    atTime?: number | undefined | null
    createdAt?: string | undefined | null
    createdByFirstName?: string | undefined | null
    createdByLastName?: string | undefined | null
    createdByUserId?: string | undefined | null
    forAssetAtIndex?: number | undefined | null
    id?: string | undefined | null
    message?: string | undefined | null
    rating?: number | undefined | null
    updatedAt?: string | undefined | null
    visibility?: ResolverInputTypes['ReviewFeedbackVisibility'] | undefined | null
  }
  ['UserCharacteristicInput']: {
    characteristic: string
    reason?: string | undefined | null
  }
  ['UserContentRatingInput']: {
    instagramContentQualityRating?: ResolverInputTypes['ContentQualityRating'] | undefined | null
    instagramContentTypeRating?: Array<ResolverInputTypes['ContentTypeRating']> | undefined | null
    instagramCreatorContentScore?: number | undefined | null
    instagramCreatorContentScoreRaw?: Array<ResolverInputTypes['CreatorContentRatingRawValueInput']> | undefined | null
    instagramCreatorSponsorshipContentScore?: number | undefined | null
    instagramSubmittedBy?: string | undefined | null
    submittedBy?: string | undefined | null
    tiktokContentQualityRating?: ResolverInputTypes['ContentQualityRating'] | undefined | null
    tiktokContentTypeRating?: Array<ResolverInputTypes['ContentTypeRating']> | undefined | null
    tiktokCreatorContentScore?: number | undefined | null
    tiktokCreatorContentScoreRaw?: Array<ResolverInputTypes['CreatorContentRatingRawValueInput']> | undefined | null
    tiktokCreatorSponsorshipContentScore?: number | undefined | null
    tiktokSubmittedBy?: string | undefined | null
  }
  ['UserSearchFields']: {
    addressComponents?: ResolverInputTypes['AddressComponentsSearchFields'] | undefined | null
    campaignAcceptanceRate?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    campaignCompletionRate?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    campaignReviewRate?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    claimedReviewCount?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    collisionSkuSet?: ResolverInputTypes['SearchInputString'] | undefined | null
    contentRatingQualityScore?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    createdAt?: ResolverInputTypes['SearchInputString'] | undefined | null
    declineToReviewCount?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    email?: ResolverInputTypes['SearchInputString'] | undefined | null
    firstName?: ResolverInputTypes['SearchInputString'] | undefined | null
    followers?: ResolverInputTypes['FollowersSearchFields'] | undefined | null
    gender?: ResolverInputTypes['SearchInputString'] | undefined | null
    incompleteCampaignCount?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    instagramContentTypeRating?: ResolverInputTypes['SearchInputString'] | undefined | null
    instagramFollowerCountryDistribution?:
      | ResolverInputTypes['InstagramFollowerCountryDistributionSearchFields']
      | undefined
      | null
    instagramFollowersInt?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    instagramHandle?: ResolverInputTypes['SearchInputString'] | undefined | null
    instagramTrailingPostEngagementRateAvg?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    instagramTrailingPostEngagementRateMedian?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    instagramTrailingPostImpressionsAvg?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    instagramTrailingPostImpressionsMedian?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    instagramTrailingPostSponsoredPercentage?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    instagramVerificationMethod?: ResolverInputTypes['SearchInputString'] | undefined | null
    lastName?: ResolverInputTypes['SearchInputString'] | undefined | null
    osScript?: ResolverInputTypes['SearchInputQueryScript'] | undefined | null
    socialQuality?: ResolverInputTypes['SearchInputString'] | undefined | null
    tiktokAveragePostEngagementRate?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    tiktokContentTypeRating?: ResolverInputTypes['SearchInputString'] | undefined | null
    tiktokFollowersInt?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    tiktokHandle?: ResolverInputTypes['SearchInputString'] | undefined | null
    tiktokMedianPostEngagementRateViews?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    tiktokMedianViews?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    tiktokSponsoredPostPercentage?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    tiktokVerificationMethod?: ResolverInputTypes['SearchInputString'] | undefined | null
    userCampaigns?: ResolverInputTypes['UserCampaignSearchFields'] | undefined | null
    youtubeAveragePostEngagementRate?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    youtubeChannelFollowersInt?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    youtubeChannelHandle?: ResolverInputTypes['SearchInputString'] | undefined | null
    youtubeMedianViews?: ResolverInputTypes['SearchInputNumber'] | undefined | null
    youtubeVerificationMethod?: ResolverInputTypes['SearchInputString'] | undefined | null
  }
  ['UserSearchInput']: {
    and?: Array<ResolverInputTypes['UserSearchFields']> | undefined | null
    from?: number | undefined | null
    not?: Array<ResolverInputTypes['UserSearchFields']> | undefined | null
    or?: Array<Array<ResolverInputTypes['UserSearchFields']> | undefined | null> | undefined | null
    size?: number | undefined | null
    sort?: Array<ResolverInputTypes['UserSearchInputSort']> | undefined | null
  }
  ['UserSearchInputSort']: {
    field?: string | undefined | null
    order: ResolverInputTypes['SortOrder']
    osScript?: ResolverInputTypes['SearchInputSortScript'] | undefined | null
  }
  ['UtmReferralInput']: {
    campaign?: string | undefined | null
    content?: string | undefined | null
    medium?: string | undefined | null
    source?: string | undefined | null
    term?: string | undefined | null
  }
}

export type ModelTypes = {
  ['AdminSendPushMessageResponse']: {
    message?: string | undefined
    statusCode: number
  }
  ['ApplyToCampaignOutput']: {
    campaignStatus: ModelTypes['CampaignStatus']
    id: string
    isAutoApproved?: boolean | undefined
    productId?: string | undefined
  }
  ['ApprovalFilterValue']: {
    filter: string
    value: string
  }
  ['ApproveUserCampaignForPurchaseResponse']: {
    approvalOutcome?: ModelTypes['ApprovalOutcome'] | undefined
    campaignStatus: ModelTypes['CampaignStatus']
    coarseCampaignStatus: ModelTypes['CoarseCampaignStatus']
    userCampaignId: string
  }
  ['AutoApprovalCriteria']: {
    completedCampaigns?: number | undefined
  }
  ['AutoApprovalV2Config']: {
    autoApprovalCriteria?: ModelTypes['AutoApprovalCriteria'] | undefined
    enabled?: boolean | undefined
  }
  ['AutoBuyDistribution']: {
    createdAt: string
    description?: string | undefined
    id: string
    name: string
    schedule: Array<number>
    updatedAt: string
  }
  ['AutoBuyFlowConfiguration']: {
    createdAt: string
    dailyPurchaseMax?: number | undefined
    delayStrategy?: ModelTypes['AutoBuyDelayStrategy'] | undefined
    flowId: string
    insufficientCreators?: string | undefined
    latestSkipReason?: ModelTypes['AutoBuySkipReason'] | undefined
    pauseReason?: ModelTypes['AutoBuySkipReason'] | undefined
    releaseSchedule?: ModelTypes['ModelAutoBuyFlowReleaseScheduleConnection'] | undefined
    schedule?: Array<ModelTypes['AutoBuySchedule']> | undefined
    startDate: string
    status: ModelTypes['AutoBuyStatus']
    updatedAt: string
  }
  ['AutoBuyFlowReleaseSchedule']: {
    actualPurchases?: number | undefined
    actualVerifiedPurchases?: number | undefined
    adjustedDesiredPurchases?: number | undefined
    autoApprovalSeats?: number | undefined
    createdAt: string
    date: string
    desiredPurchases?: number | undefined
    flowId: string
    manualPurchases?: number | undefined
    manualReleases?: number | undefined
    projectedReleaseCount?: number | undefined
    releaseCount?: number | undefined
    released?: boolean | undefined
    skippedReason?: ModelTypes['AutoBuySkipReason'] | undefined
    updatedAt: string
  }
  ['AutoBuyRelease']: {
    createdAt: string
    date: string
    productId: string
    releaseDateTime: string
    skuId?: string | undefined
    updatedAt: string
    userCampaignId: string
  }
  ['AutoBuySchedule']: {
    date: string
    desiredPurchaseCount: number
    willPurchasePercentage?: number | undefined
  }
  ['BillingContact']: {
    email: string
    name: string
  }
  ['Brand']: {
    active?: string | undefined
    amazonBrandName?: string | undefined
    brandApis?: ModelTypes['ModelBrandApiConnection'] | undefined
    brandDetailsSubmitted?: boolean | undefined
    brandInstaHandle?: string | undefined
    brandLogoImageKey?: string | undefined
    brandStoreConfirmationImage?: string | undefined
    brandStoreConfirmationMobileImage?: string | undefined
    brandStoreScrapeUrl?: string | undefined
    brandTikTokHandle?: string | undefined
    companyId?: string | undefined
    contactEmails?: Array<string | undefined> | undefined
    createdAt: string
    defaultCreatorPreference?: ModelTypes['CreatorPreference'] | undefined
    defaultCreatorPreferenceDetails?: string | undefined
    defaultHashtags?: Array<string> | undefined
    defaultMarketingBriefKey?: string | undefined
    defaultPostingGuidelines?: Array<string> | undefined
    defaultPostingInspirationLinks?: Array<string> | undefined
    emailsSent?: Array<string | undefined> | undefined
    fullScrapedAt?: string | undefined
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined
    isSeller?: string | undefined
    isVendor?: string | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    lastScrapedAt?: string | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    paymentBillingContact?: ModelTypes['BillingContact'] | undefined
    platform?: ModelTypes['PlatformSource'] | undefined
    postingGuidanceDocument?: string | undefined
    region?: ModelTypes['WSLocale'] | undefined
    socialMediaExampleUrls?: Array<string> | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    updatedAt: string
    walmartBrandName?: string | undefined
    walmartLastScrapedAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['BrandApi']: {
    accessToken?: string | undefined
    api: ModelTypes['BrandApiType']
    apiVersion?: ModelTypes['BrandApiVersion'] | undefined
    brandId: string
    createdAt: string
    enabled?: boolean | undefined
    locale?: ModelTypes['WSLocale'] | undefined
    refreshToken?: string | undefined
    shop?: string | undefined
    shopId?: string | undefined
    updatedAt: string
  }
  ['BudgetDetail']: {
    authorizedUnits?: number | undefined
    baseUnits?: number | undefined
    budgetSpent?: number | undefined
    budgetSplit?: Array<ModelTypes['BudgetSplitComponent']> | undefined
    committedReviewsCount?: number | undefined
    contentGuidelinesSubmitted?: boolean | undefined
    creatorTargetingEnabled?: boolean | undefined
    creatorTargetingSubmitted?: boolean | undefined
    lifestyleCommittedContentCount?: number | undefined
    marketingFee?: number | undefined
    premiumLifestyleCommittedContentCount?: number | undefined
    productKeywordsEnabled?: boolean | undefined
    productKeywordsSubmitted?: boolean | undefined
    promoCodeSubmitted?: boolean | undefined
    skuId?: string | undefined
    socialCommittedContentCount?: number | undefined
    totalProductCost?: number | undefined
  }
  ['BudgetSplitComponent']: {
    autoApprovalSeatsAvailable?: number | undefined
    autoBuyActive?: ModelTypes['Paused'] | undefined
    autoBuySchedule?: string | undefined
    count?: number | undefined
    productId?: string | undefined
    unitsPurchased?: number | undefined
  }
  ['Campaign']: {
    aboutSection?: string | undefined
    active: string
    actualStartDate?: string | undefined
    amountPaidByClient?: number | undefined
    anticipatedStartDate: string
    applicationForm?: string | undefined
    applicationProductLink?: ModelTypes['UserLink'] | undefined
    applicationProductLinkId?: string | undefined
    applicationStatus?: ModelTypes['ApplicationStatus'] | undefined
    attributionRefUrlSuffix?: string | undefined
    authorizedUnits?: number | undefined
    badges?: Array<ModelTypes['CampaignBadge'] | undefined> | undefined
    brand?: ModelTypes['Brand'] | undefined
    brandId: string
    brandProductShort?: string | undefined
    brandStoreLink?: string | undefined
    budgetSpent?: number | undefined
    campaignProductType?: ModelTypes['CampaignProductType'] | undefined
    campaignProducts?: ModelTypes['ModelProductConnection'] | undefined
    campaignReferralBonusAmount?: number | undefined
    campaignReferralBonusEnabled?: ModelTypes['CampaignReferralBonusFlag'] | undefined
    campaignStateMachine?: string | undefined
    closeoutReleaseFailureReason?: string | undefined
    closeoutReleaseRate?: ModelTypes['Rate'] | undefined
    collisionSkuDensity?: Array<ModelTypes['CollisionDensityValue']> | undefined
    commissionFlatRate?: number | undefined
    commissionPercentageRate?: number | undefined
    committedClicks?: number | undefined
    committedPlacementWinsCount?: number | undefined
    committedReviewsCount?: number | undefined
    contentApprovalRequired?: boolean | undefined
    contentCompleteDeadline?: string | undefined
    createdAt: string
    creationSourceVersion?: ModelTypes['CreationSourceVersion'] | undefined
    dailyClicks?: ModelTypes['ModelCampaignClicksConnection'] | undefined
    earlyReviewEnabled?: boolean | undefined
    enableContentComplianceReview?: boolean | undefined
    endDate?: string | undefined
    excludeGender?: ModelTypes['UserFilterGender'] | undefined
    expectedBonus?: string | undefined
    followersCountCeiling?: number | undefined
    followersCountFloor?: number | undefined
    goal?: ModelTypes['CampaignGoal'] | undefined
    id: string
    influencerApprovalEnabled?: ModelTypes['InfluencerApprovalFlagV2'] | undefined
    lifestyleCommittedContentCount?: number | undefined
    localeV1?: ModelTypes['WSLocale'] | undefined
    marketingFee?: number | undefined
    marketingFormFilled?: boolean | undefined
    newCreatorsOnly?: boolean | undefined
    payForClicksEnabled?: ModelTypes['EnabledFlag'] | undefined
    payForClicksGeneratedPerPeriod?: Array<ModelTypes['ClicksRecords']> | undefined
    payForClicksMinClicks?: number | undefined
    payForClicksOriginalLink?: string | undefined
    payForClicksTotalClicksGenerated?: number | undefined
    payForPerformanceEnabled?: ModelTypes['PayForPerformanceFlag'] | undefined
    postingGuidance?: string | undefined
    preApplicationProductImage?: string | undefined
    preApplicationProductPriceRange?: string | undefined
    preApplicationUgcProductImage?: string | undefined
    preLaunchDate?: string | undefined
    premiumLifestyleCommittedContentCount?: number | undefined
    productKeywords?: ModelTypes['ModelProductKeywordsConnection'] | undefined
    requiredProductLinksEnabled?: ModelTypes['RequiredProductLinkFlag'] | undefined
    reviewVotingTarget?: string | undefined
    sample?: boolean | undefined
    shellCampaignBase?: string | undefined
    sku: ModelTypes['Sku']
    skuId: string
    skuPriceOverride?: number | undefined
    socialCommittedContentCount?: number | undefined
    sourcingPriority?: number | undefined
    sponsorshipInstructions?: string | undefined
    title: string
    totalEarlyReviewsAllowed?: number | undefined
    totalProductCost?: number | undefined
    updatedAt: string
    userAutoApproved?: boolean | undefined
    visibleToUser: string
  }
  ['CampaignBadge']: {
    type: ModelTypes['CampaignBadgeType']
    value: string
  }
  ['CampaignClicks']: {
    campaignId: string
    clicks: number
    createdAt: string
    date: string
    updatedAt: string
  }
  ['CampaignForUser']: {
    about?: string | undefined
    badges: Array<ModelTypes['CampaignBadge']>
    brandName: string
    campaignProductType: ModelTypes['CampaignProductType']
    campaignReferralBonusAmount?: number | undefined
    campaignReferralEnabled: boolean
    contentApprovalLeadDays?: number | undefined
    expectedBonusMax?: number | undefined
    expectedBonusMin?: number | undefined
    flowForUser?: ModelTypes['CampaignForUserFlow'] | undefined
    flows: Array<ModelTypes['CampaignForUserFlow']>
    hasUserApplied: boolean
    id: string
    isAdminOnlyView: boolean
    isFreeGift: boolean
    isPayForClicksEnabled: boolean
    isPayForPerformanceEnabled: boolean
    isShipToCustomer: boolean
    isUserAutoApproved: boolean
    locale: ModelTypes['WSLocale']
    payForPerformanceBonusFlatRate?: number | undefined
    payForPerformanceBonusPercentageRate?: number | undefined
    postingDeadline?: string | undefined
    preApplicationProductImage?: string | undefined
    preApplicationProductName?: string | undefined
    preApplicationProductPrice?: string | undefined
    preApplicationUgcProductImage?: string | undefined
    productForUser?: ModelTypes['CampaignForUserProduct'] | undefined
    productImage?: string | undefined
    productLink?: string | undefined
    productPrice?: number | undefined
    productTitle: string
    products: Array<ModelTypes['CampaignForUserProduct']>
    productsForUser?: Array<ModelTypes['CampaignForUserProduct']> | undefined
    sku?: string | undefined
    skuId: string
    specialInstructions?: string | undefined
    sponsorshipInstructions?: string | undefined
    storeName: string
    title?: string | undefined
    totalSeatsAvailable?: number | undefined
    userCampaignCreatedAt?: string | undefined
    userCampaignId?: string | undefined
    userCampaignStatus?: ModelTypes['CampaignStatus'] | undefined
    userSpecificBonusConfig?: ModelTypes['UserCampaignBonusConfig'] | undefined
  }
  ['CampaignForUserFlow']: {
    autoApprovalSeatsAvailable?: number | undefined
    contentRequirement: Array<ModelTypes['ProductSocialContent']>
    customFtcHashtags?: Array<string> | undefined
    id: string
    isPayForClicksEnabled?: boolean | undefined
    payForImpressionsBonusConfig?: ModelTypes['PayForImpressionsBonusConfig'] | undefined
    qualityLevel?: ModelTypes['ProductQualityLevel'] | undefined
  }
  ['CampaignForUserProduct']: {
    autoApprovalSeatsAvailable?: number | undefined
    contentRequirement: Array<ModelTypes['ProductSocialContent']>
    customFtcHashtags?: Array<string> | undefined
    flowId: string
    id: string
    image?: string | undefined
    isPayForClicksEnabled?: boolean | undefined
    payForImpressionsBonusConfig?: ModelTypes['PayForImpressionsBonusConfig'] | undefined
    price?: number | undefined
    productLink?: string | undefined
    sku?: string | undefined
    storeName: string
    title?: string | undefined
  }
  ['CampaignInvite']: {
    campaign?: ModelTypes['Campaign'] | undefined
    campaignId: string
    createdAt: string
    id: string
    notificationStatus: ModelTypes['NotificationStatus']
    priority: number
    updatedAt: string
    user?: ModelTypes['User'] | undefined
    userId: string
  }
  ['CampaignReferralBonus']: {
    campaignName: string
    payoutDate?: string | undefined
    totalEarnings?: string | undefined
  }
  ['CampaignSourcingProspect']: {
    bonusConfig?: ModelTypes['UserCampaignBonusConfig'] | undefined
    campaignId: string
    createdAt: string
    createdById?: string | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    internalNotes?: ModelTypes['ModelNoteConnection'] | undefined
    internalNotesId?: string | undefined
    name?: string | undefined
    platformInviteSource: ModelTypes['PlatformInviteSource']
    platformStatus?: ModelTypes['PlatformStatus'] | undefined
    preApprovalStatus?: ModelTypes['PreApprovalStatus'] | undefined
    preApprovalStatusById?: string | undefined
    priority?: ModelTypes['SourcePriority'] | undefined
    productId?: string | undefined
    sourcingSource: ModelTypes['CampaignSourcingSource']
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    updatedAt: string
    user?: ModelTypes['User'] | undefined
    userCampaign?: ModelTypes['UserCampaign'] | undefined
    userCampaignId?: string | undefined
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
  }
  ['CardCreateOutput']: {
    cardId?: string | undefined
  }
  ['CardDeleteOutput']: {
    cardId?: string | undefined
  }
  ['CardDetails']: {
    address: string
    cvc: string
    expMonth: string
    expYear: string
    last4: string
    name: string
    number: string
    primaryAccountIdentifier?: string | undefined
  }
  ['ChannelConfiguration']: {
    attributes?: Array<ModelTypes['KeyAttribute'] | undefined> | undefined
    enabled?: boolean | undefined
    fromAddress?: string | undefined
    templateId: string
  }
  ['ChatConversationV2']: {
    active: ModelTypes['Active']
    adminLastReadMessageIndex?: number | undefined
    adminUnreadCount: number
    campaignId?: string | undefined
    chatType: ModelTypes['ChatType']
    createdAt: string
    creatorLastReadMessageIndex?: number | undefined
    creatorUnreadCount: number
    lastMessage?: string | undefined
    lastMessageDate?: string | undefined
    lastMessageId?: string | undefined
    lastMessageSource?: ModelTypes['ConversationMessageSource'] | undefined
    resourceId: string
    status: ModelTypes['ConversationInternalStatus']
    statusUpdatedAt?: string | undefined
    statusUpdatedByFirstName?: string | undefined
    statusUpdatedById?: string | undefined
    statusUpdatedByLastName?: string | undefined
    twilioConversationId: string
    updatedAt: string
    user?: ModelTypes['User'] | undefined
    userCampaignId?: string | undefined
    userEmail?: string | undefined
    userFirstName?: string | undefined
    userId: string
    userLastName?: string | undefined
  }
  ['ClicksRecords']: {
    clicks: number
    from: string
    to: string
  }
  ['CognitoUserAttribute']: {
    Name: string
    Value: string
  }
  ['CognitoUserModel']: {
    UserAttributes: Array<ModelTypes['CognitoUserAttribute'] | undefined>
    Username: string
  }
  ['CollisionDensityValue']: {
    density: number
    skuId: string
  }
  ['CommunicationNotification']: {
    address: string
    attributes?: Array<ModelTypes['KeyAttribute']> | undefined
    campaignId?: string | undefined
    channel: ModelTypes['Channel']
    createdAt: string
    expectedSentAt: string
    experimentTreatment?: string | undefined
    id: string
    notificationId: string
    sentAt: string
    status?: string | undefined
    statusMessage?: string | undefined
    templateId?: string | undefined
    traceId: string
    updatedAt: string
    userCampaignId?: string | undefined
    userId: string
  }
  ['Company']: {
    agencyId?: string | undefined
    brands?: ModelTypes['ModelBrandConnection'] | undefined
    createdAt: string
    id: string
    logo?: string | undefined
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: ModelTypes['PaymentTermsType'] | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    updatedAt: string
    websiteUrl?: string | undefined
  }
  ['ConnectedAccount']: {
    accountId: string
    accountType: ModelTypes['ConnectedAccountType']
    createdAt?: string | undefined
    updatedAt?: string | undefined
    user?: ModelTypes['User'] | undefined
    userId: string
  }
  ['ContentApprovalConfiguration']: {
    contentApprovalAssignee?: string | undefined
    contentApprovalType: ModelTypes['ContentApprovalType']
  }
  ['ContentComplianceReviewCriteria']: {
    compliant?: boolean | undefined
    criteria: ModelTypes['ComplianceReviewCriteria']
    requirement?: string | undefined
  }
  ['ContentGuidance']: {
    instance: number
    text: string
  }
  ['ContentLabelResult']: {
    confidence?: number | undefined
    label: ModelTypes['ContentSafetyLabel']
    severity?: number | undefined
  }
  ['ContentRatingSponsorshipContent']: {
    inputSource?: ModelTypes['ContentRatingSponsorshipContentInputSource'] | undefined
    isCompliant?: boolean | undefined
    isInappropriate?: boolean | undefined
    link: string
    qualityRating?: number | undefined
  }
  ['ContentSafetyAnalysis']: {
    isBrandSafe?: ModelTypes['BrandSafe'] | undefined
    summary?: Array<ModelTypes['ContentSafetyLabelSummary'] | undefined> | undefined
    textInstances?: Array<ModelTypes['ContentSafetyTextInstance'] | undefined> | undefined
  }
  ['ContentSafetyLabelSummary']: {
    confidence?: number | undefined
    label?: ModelTypes['ContentSafetyLabel'] | undefined
    severity?: ModelTypes['ContentSafetySeverity'] | undefined
  }
  ['ContentSafetySeverity']: {
    high?: number | undefined
    low?: number | undefined
    medium?: number | undefined
  }
  ['ContentSafetyTextInstance']: {
    labels?: Array<ModelTypes['ContentLabelResult'] | undefined> | undefined
    text: string
    timestampEnd?: number | undefined
    timestampStart?: number | undefined
  }
  ['Conversation']: {
    contactOwner?: ModelTypes['User'] | undefined
    contactOwnerId?: string | undefined
    createdAt: string
    lastContactAt?: string | undefined
    messages?: ModelTypes['ModelConversationMessageConnection'] | undefined
    status?: ModelTypes['ConversationStatus'] | undefined
    statusChangedAt?: string | undefined
    updatedAt: string
    user: ModelTypes['User']
    userId: string
  }
  ['ConversationInternalStatusDetails']: {
    status?: ModelTypes['ConversationInternalStatus'] | undefined
    statusUpdatedAt?: string | undefined
    statusUpdatedByFirstName?: string | undefined
    statusUpdatedById?: string | undefined
    statusUpdatedByLastName?: string | undefined
  }
  ['ConversationMessage']: {
    channel: ModelTypes['ConversationChannel']
    conversation: ModelTypes['Conversation']
    createdAt: string
    id: string
    messageContent: string
    messageId?: string | undefined
    messageMediaKeys?: Array<string> | undefined
    replyFrom?: ModelTypes['User'] | undefined
    replyFromId?: string | undefined
    sendAt?: string | undefined
    sendAttempts?: number | undefined
    sentAt?: string | undefined
    status: ModelTypes['ConversationMessageStatus']
    updatedAt: string
    user?: ModelTypes['User'] | undefined
    userId: string
  }
  ['CreatorContentRatingRawValue']: {
    name: string
    value?: string | undefined
  }
  ['CreatorReferralBonus']: {
    earnings: number
    payoutDate?: string | undefined
  }
  ['CreatorReferralSignup']: {
    signupDate: string
  }
  ['EditUserSocialContentResponse']: {
    externalUrl: string
    id: string
  }
  ['EtailerSkuMetrics']: {
    createdAt: string
    date: string
    pageViews?: number | undefined
    sku: string
    skuId: string
    totalSalesAmount?: number | undefined
    unitsSold?: number | undefined
    updatedAt: string
  }
  ['FormDefinition']: {
    formFields?: Array<ModelTypes['FormFieldDefinition']> | undefined
    name?: string | undefined
    pages?: number | undefined
  }
  ['FormFieldDefinition']: {
    bonus?: string | undefined
    componentType?: ModelTypes['FormComponentType'] | undefined
    destination?: string | undefined
    formName?: string | undefined
    isProfileQuestion?: boolean | undefined
    link?: string | undefined
    options?: Array<string> | undefined
    page?: number | undefined
    placeholder?: string | undefined
    primaryText?: string | undefined
    secondaryText?: string | undefined
    sequenceComponent?: ModelTypes['FormSequenceComponent'] | undefined
    sequenceId?: string | undefined
    tooltipText?: string | undefined
    type?: ModelTypes['FormFieldType'] | undefined
    validation?: Array<ModelTypes['FormValidation']> | undefined
    withOther?: boolean | undefined
  }
  ['FormSequence']: {
    components?: Array<ModelTypes['FormSequenceComponent']> | undefined
    id: string
  }
  ['FormSequenceComponent']: {
    componentName: string
    componentType: ModelTypes['FormComponentType']
    content?: string | undefined
    id: string
    images?: Array<string> | undefined
    numbered?: boolean | undefined
    placeholder?: string | undefined
    type?: ModelTypes['FormFieldType'] | undefined
    validation?: Array<ModelTypes['FormValidationType']> | undefined
  }
  ['FormSequenceConfiguration']: {
    active: ModelTypes['Active']
    createdAt: string
    id: string
    sequenceName: string
    sequences?: Array<ModelTypes['FormSequence']> | undefined
    updatedAt: string
  }
  ['FormValidation']: {
    type: ModelTypes['FormValidationType']
    validationText?: string | undefined
  }
  ['GetConversationsAccessTokenResponse']: {
    accessToken: string
    ttl?: number | undefined
  }
  ['GetFeatureFlagResponse']: {
    value?: string | undefined
  }
  ['InspirationalContent']: {
    socialChannel?: ModelTypes['SocialChannel'] | undefined
    type: ModelTypes['InspirationalContentType']
    url: string
  }
  ['InstagramCountryDistributionItem']: {
    count: number
    countryCode: string
    pctDistribution: number
  }
  ['InstagramGenderAgeDistribution']: {
    femaleCount: number
    genderAgeDistribution?: Array<ModelTypes['InstagramGenderAgeDistributionItem']> | undefined
    maleCount: number
    unspecifiedCount: number
  }
  ['InstagramGenderAgeDistributionItem']: {
    ageRangeEnd?: number | undefined
    ageRangeStart: number
    count: number
    gender: ModelTypes['InstagramGenderAgeDistributionGender']
  }
  ['InstagramStoryScreen']: {
    createdAt: string
    exits?: number | undefined
    expirationUnixTime: number
    externalId?: string | undefined
    id: string
    impressions?: number | undefined
    instagramUserId: string
    mediaId: string
    postedAt?: string | undefined
    postingDate: string
    reach?: number | undefined
    replies?: number | undefined
    s3Bucket?: string | undefined
    tapsBack?: number | undefined
    tapsForward?: number | undefined
    updatedAt: string
    userCampaignId?: string | undefined
  }
  ['KeyAttribute']: {
    key: string
    value: string
  }
  ['KeywordResponse']: {
    hash?: string | undefined
    keywordId?: string | undefined
  }
  ['ListCampaignsForUserResponse']: {
    campaigns: Array<ModelTypes['CampaignForUser']>
    emptyReason?: string | undefined
  }
  ['Message']: {
    body: string
    createdAt?: string | undefined
    id: string
    updatedAt?: string | undefined
    userCampaign?: ModelTypes['UserCampaign'] | undefined
    userCampaignId?: string | undefined
    userHasSeen?: boolean | undefined
    userId: string
  }
  ['MessagingChannelConfiguration']: {
    attributes?: Array<ModelTypes['KeyAttribute']> | undefined
    enabled: boolean
    templateContent: string
  }
  ['MinMaxRange']: {
    max?: number | undefined
    min?: number | undefined
  }
  ['ModelAutoBuyDistributionConnection']: {
    items: Array<ModelTypes['AutoBuyDistribution'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelAutoBuyFlowConfigurationConnection']: {
    items: Array<ModelTypes['AutoBuyFlowConfiguration'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelAutoBuyFlowReleaseScheduleConnection']: {
    items: Array<ModelTypes['AutoBuyFlowReleaseSchedule'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelAutoBuyReleaseConnection']: {
    items: Array<ModelTypes['AutoBuyRelease'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelBrandApiConnection']: {
    items: Array<ModelTypes['BrandApi'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelBrandConnection']: {
    items: Array<ModelTypes['Brand'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCampaignClicksConnection']: {
    items: Array<ModelTypes['CampaignClicks'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCampaignConnection']: {
    items: Array<ModelTypes['Campaign'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCampaignInviteConnection']: {
    items: Array<ModelTypes['CampaignInvite'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCampaignSourcingProspectConnection']: {
    items: Array<ModelTypes['CampaignSourcingProspect'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelChatConversationV2Connection']: {
    items: Array<ModelTypes['ChatConversationV2'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCommunicationNotificationConnection']: {
    items: Array<ModelTypes['CommunicationNotification'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCompanyConnection']: {
    items: Array<ModelTypes['Company'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelConnectedAccountConnection']: {
    items: Array<ModelTypes['ConnectedAccount'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelConversationConnection']: {
    items: Array<ModelTypes['Conversation'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelConversationMessageConnection']: {
    items: Array<ModelTypes['ConversationMessage'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelEtailerSkuMetricsConnection']: {
    items: Array<ModelTypes['EtailerSkuMetrics'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelFormSequenceConfigurationConnection']: {
    items: Array<ModelTypes['FormSequenceConfiguration'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelInstagramStoryScreenConnection']: {
    items: Array<ModelTypes['InstagramStoryScreen'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelMessageConnection']: {
    items: Array<ModelTypes['Message'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelNoteConnection']: {
    items: Array<ModelTypes['Note'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelNotificationConfigurationConnection']: {
    items: Array<ModelTypes['NotificationConfiguration'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelProductBonusConfigSnapshotConnection']: {
    items: Array<ModelTypes['ProductBonusConfigSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelProductConnection']: {
    items: Array<ModelTypes['Product'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelProductKeywordsConnection']: {
    items: Array<ModelTypes['ProductKeywords'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelProductRatingSnapshotConnection']: {
    items: Array<ModelTypes['ProductRatingSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelPurchaseConnection']: {
    items: Array<ModelTypes['Purchase'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelReferralConnection']: {
    items: Array<ModelTypes['Referral'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelReviewConnection']: {
    items: Array<ModelTypes['Review'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelSkuConnection']: {
    items: Array<ModelTypes['Sku'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelSkuSnapshotConnection']: {
    items: Array<ModelTypes['SkuSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserBonusPayoutConnection']: {
    items: Array<ModelTypes['UserBonusPayout'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignChangelogConnection']: {
    items: Array<ModelTypes['UserCampaignChangelog'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignConnection']: {
    items: Array<ModelTypes['UserCampaign'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignSnapshotConnection']: {
    items: Array<ModelTypes['UserCampaignSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignSocialContentConnection']: {
    items: Array<ModelTypes['UserCampaignSocialContent'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignSocialContentDraftHistoryConnection']: {
    items: Array<ModelTypes['UserCampaignSocialContentDraftHistory'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignSocialContentReviewConnection']: {
    items: Array<ModelTypes['UserCampaignSocialContentReview'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserConnection']: {
    items: Array<ModelTypes['User'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserDeviceSnapshotConnection']: {
    items: Array<ModelTypes['UserDeviceSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserFollowerConnection']: {
    items: Array<ModelTypes['UserFollower'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserLinkConnection']: {
    items: Array<ModelTypes['UserLink'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserNotificationPreferenceConnection']: {
    items: Array<ModelTypes['UserNotificationPreference'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserPayoutRequestConnection']: {
    items: Array<ModelTypes['UserPayoutRequest'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserStagingApprovalConnection']: {
    items: Array<ModelTypes['UserStagingApproval'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelVideoContentScanConnection']: {
    items: Array<ModelTypes['VideoContentScan'] | undefined>
    nextToken?: string | undefined
  }
  ['Mutation']: {
    adminSendPushMessage?: ModelTypes['AdminSendPushMessageResponse'] | undefined
    applyToCampaign?: ModelTypes['ApplyToCampaignOutput'] | undefined
    approveUserCampaignForPurchase?: ModelTypes['ApproveUserCampaignForPurchaseResponse'] | undefined
    createAutoBuyDistribution?: ModelTypes['AutoBuyDistribution'] | undefined
    createAutoBuyFlowConfiguration?: ModelTypes['AutoBuyFlowConfiguration'] | undefined
    createAutoBuyFlowReleaseSchedule?: ModelTypes['AutoBuyFlowReleaseSchedule'] | undefined
    createAutoBuyRelease?: ModelTypes['AutoBuyRelease'] | undefined
    createBrand?: ModelTypes['Brand'] | undefined
    createBrandApi?: ModelTypes['BrandApi'] | undefined
    createCampaign?: ModelTypes['Campaign'] | undefined
    createCampaignClicks?: ModelTypes['CampaignClicks'] | undefined
    createCampaignInvite?: ModelTypes['CampaignInvite'] | undefined
    createCampaignSourcingProspect?: ModelTypes['CampaignSourcingProspect'] | undefined
    createChatConversationV2?: ModelTypes['ChatConversationV2'] | undefined
    createCommunicationNotification?: ModelTypes['CommunicationNotification'] | undefined
    createCompany?: ModelTypes['Company'] | undefined
    createConnectedAccount?: ModelTypes['ConnectedAccount'] | undefined
    createConversation?: ModelTypes['Conversation'] | undefined
    createConversationMessage?: ModelTypes['ConversationMessage'] | undefined
    createEtailerSkuMetrics?: ModelTypes['EtailerSkuMetrics'] | undefined
    createFormSequenceConfiguration?: ModelTypes['FormSequenceConfiguration'] | undefined
    createInstagramStoryScreen?: ModelTypes['InstagramStoryScreen'] | undefined
    createManualPurchase?: ModelTypes['UserCampaign'] | undefined
    createMessage?: ModelTypes['Message'] | undefined
    createNote?: ModelTypes['Note'] | undefined
    createNotificationConfiguration?: ModelTypes['NotificationConfiguration'] | undefined
    createProduct?: ModelTypes['Product'] | undefined
    createProductBonusConfigSnapshot?: ModelTypes['ProductBonusConfigSnapshot'] | undefined
    createProductFromUrl?: Array<ModelTypes['Sku']> | undefined
    createProductKeywords?: ModelTypes['ProductKeywords'] | undefined
    createProductRatingSnapshot?: ModelTypes['ProductRatingSnapshot'] | undefined
    createPurchase?: ModelTypes['Purchase'] | undefined
    createReferral?: ModelTypes['Referral'] | undefined
    createReview?: ModelTypes['Review'] | undefined
    createShortLink: ModelTypes['UserLink']
    createSku?: ModelTypes['Sku'] | undefined
    createSkuSnapshot?: ModelTypes['SkuSnapshot'] | undefined
    createTwilioConversation: ModelTypes['ChatConversationV2']
    createUser?: ModelTypes['User'] | undefined
    createUserBonusPayout?: ModelTypes['UserBonusPayout'] | undefined
    createUserCampaign?: ModelTypes['UserCampaign'] | undefined
    createUserCampaignChangelog?: ModelTypes['UserCampaignChangelog'] | undefined
    createUserCampaignSnapshot?: ModelTypes['UserCampaignSnapshot'] | undefined
    createUserCampaignSocialContent?: ModelTypes['UserCampaignSocialContent'] | undefined
    createUserCampaignSocialContentDraftHistory?: ModelTypes['UserCampaignSocialContentDraftHistory'] | undefined
    createUserCampaignSocialContentReview?: ModelTypes['UserCampaignSocialContentReview'] | undefined
    createUserDeviceSnapshot?: ModelTypes['UserDeviceSnapshot'] | undefined
    createUserFollower?: ModelTypes['UserFollower'] | undefined
    createUserLink?: ModelTypes['UserLink'] | undefined
    createUserNotificationPreference?: ModelTypes['UserNotificationPreference'] | undefined
    createUserPayoutRequest?: ModelTypes['UserPayoutRequest'] | undefined
    createUserStagingApproval?: ModelTypes['UserStagingApproval'] | undefined
    createVideoContentScan?: ModelTypes['VideoContentScan'] | undefined
    deleteAutoBuyDistribution?: ModelTypes['AutoBuyDistribution'] | undefined
    deleteAutoBuyFlowConfiguration?: ModelTypes['AutoBuyFlowConfiguration'] | undefined
    deleteAutoBuyFlowReleaseSchedule?: ModelTypes['AutoBuyFlowReleaseSchedule'] | undefined
    deleteAutoBuyRelease?: ModelTypes['AutoBuyRelease'] | undefined
    deleteBrand?: ModelTypes['Brand'] | undefined
    deleteBrandApi?: ModelTypes['BrandApi'] | undefined
    deleteCampaign?: ModelTypes['Campaign'] | undefined
    deleteCampaignClicks?: ModelTypes['CampaignClicks'] | undefined
    deleteCampaignInvite?: ModelTypes['CampaignInvite'] | undefined
    deleteCampaignSourcingProspect?: ModelTypes['CampaignSourcingProspect'] | undefined
    deleteChatConversationV2?: ModelTypes['ChatConversationV2'] | undefined
    deleteCommunicationNotification?: ModelTypes['CommunicationNotification'] | undefined
    deleteCompany?: ModelTypes['Company'] | undefined
    deleteConnectedAccount?: ModelTypes['ConnectedAccount'] | undefined
    deleteConversation?: ModelTypes['Conversation'] | undefined
    deleteConversationMessage?: ModelTypes['ConversationMessage'] | undefined
    deleteEtailerSkuMetrics?: ModelTypes['EtailerSkuMetrics'] | undefined
    deleteFormSequenceConfiguration?: ModelTypes['FormSequenceConfiguration'] | undefined
    deleteInstagramStoryScreen?: ModelTypes['InstagramStoryScreen'] | undefined
    deleteMessage?: ModelTypes['Message'] | undefined
    deleteNote?: ModelTypes['Note'] | undefined
    deleteNotificationConfiguration?: ModelTypes['NotificationConfiguration'] | undefined
    deleteProduct?: ModelTypes['Product'] | undefined
    deleteProductBonusConfigSnapshot?: ModelTypes['ProductBonusConfigSnapshot'] | undefined
    deleteProductKeywords?: ModelTypes['ProductKeywords'] | undefined
    deleteProductRatingSnapshot?: ModelTypes['ProductRatingSnapshot'] | undefined
    deletePurchase?: ModelTypes['Purchase'] | undefined
    deleteReferral?: ModelTypes['Referral'] | undefined
    deleteReview?: ModelTypes['Review'] | undefined
    deleteSku?: ModelTypes['Sku'] | undefined
    deleteSkuSnapshot?: ModelTypes['SkuSnapshot'] | undefined
    deleteUser?: ModelTypes['User'] | undefined
    deleteUserBonusPayout?: ModelTypes['UserBonusPayout'] | undefined
    deleteUserCampaign?: ModelTypes['UserCampaign'] | undefined
    deleteUserCampaignChangelog?: ModelTypes['UserCampaignChangelog'] | undefined
    deleteUserCampaignSnapshot?: ModelTypes['UserCampaignSnapshot'] | undefined
    deleteUserCampaignSocialContent?: ModelTypes['UserCampaignSocialContent'] | undefined
    deleteUserCampaignSocialContentDraftHistory?: ModelTypes['UserCampaignSocialContentDraftHistory'] | undefined
    deleteUserCampaignSocialContentReview?: ModelTypes['UserCampaignSocialContentReview'] | undefined
    deleteUserDeviceSnapshot?: ModelTypes['UserDeviceSnapshot'] | undefined
    deleteUserFollower?: ModelTypes['UserFollower'] | undefined
    deleteUserLink?: ModelTypes['UserLink'] | undefined
    deleteUserNotificationPreference?: ModelTypes['UserNotificationPreference'] | undefined
    deleteUserPayoutRequest?: ModelTypes['UserPayoutRequest'] | undefined
    deleteUserStagingApproval?: ModelTypes['UserStagingApproval'] | undefined
    deleteVideoContentScan?: ModelTypes['VideoContentScan'] | undefined
    editUserSocialContent: ModelTypes['EditUserSocialContentResponse']
    executeBrandSafetyAnalysis: boolean
    generateSocialVerificationCode: ModelTypes['SocialVerificationCode']
    handleUserCampaignStagingApprovals?: Array<ModelTypes['UserStagingApproval']> | undefined
    inviteUsersToCampaign: Array<ModelTypes['CampaignInvite']>
    markCreatorAsFavorite: ModelTypes['UserFollower']
    processSelectedInstagramStories?: Array<ModelTypes['InstagramStoryScreen'] | undefined> | undefined
    purchasesCardCreate: ModelTypes['CardCreateOutput']
    purchasesCardDelete: ModelTypes['CardDeleteOutput']
    purchasesEphemeralCreate: string
    refreshSocialToken?: string | undefined
    requestUserBalancePayout?: Array<ModelTypes['UserPayoutRequest']> | undefined
    requestUserDeactivation?: string | undefined
    resetUnverifiedUserAttributes?: ModelTypes['ResetUserAttributeResponse'] | undefined
    resetUserEmail?: ModelTypes['ResetUserAttributeResponse'] | undefined
    resetUserPhoneNumber?: ModelTypes['ResetUserAttributeResponse'] | undefined
    rollbackUserCampaign?: ModelTypes['UserCampaign'] | undefined
    scrapeMediaAssetToStorage: string
    socialUnlink: ModelTypes['SocialUnlinkResult']
    submitUserCampaignStateChange: ModelTypes['UserCampaign']
    updateAutoBuyDistribution?: ModelTypes['AutoBuyDistribution'] | undefined
    updateAutoBuyFlowConfiguration?: ModelTypes['AutoBuyFlowConfiguration'] | undefined
    updateAutoBuyFlowReleaseSchedule?: ModelTypes['AutoBuyFlowReleaseSchedule'] | undefined
    updateAutoBuyRelease?: ModelTypes['AutoBuyRelease'] | undefined
    updateBrand?: ModelTypes['Brand'] | undefined
    updateBrandApi?: ModelTypes['BrandApi'] | undefined
    updateCampaign?: ModelTypes['Campaign'] | undefined
    updateCampaignClicks?: ModelTypes['CampaignClicks'] | undefined
    updateCampaignInvite?: ModelTypes['CampaignInvite'] | undefined
    updateCampaignSourcingProspect?: ModelTypes['CampaignSourcingProspect'] | undefined
    updateChatConversationV2?: ModelTypes['ChatConversationV2'] | undefined
    updateCommunicationNotification?: ModelTypes['CommunicationNotification'] | undefined
    updateCompany?: ModelTypes['Company'] | undefined
    updateConnectedAccount?: ModelTypes['ConnectedAccount'] | undefined
    updateContentApprovals: Array<ModelTypes['UserCampaignSocialContentReview']>
    updateConversation?: ModelTypes['Conversation'] | undefined
    updateConversationMessage?: ModelTypes['ConversationMessage'] | undefined
    updateEtailerSkuMetrics?: ModelTypes['EtailerSkuMetrics'] | undefined
    updateFormSequenceConfiguration?: ModelTypes['FormSequenceConfiguration'] | undefined
    updateInstagramStoryScreen?: ModelTypes['InstagramStoryScreen'] | undefined
    updateMessage?: ModelTypes['Message'] | undefined
    updateNote?: ModelTypes['Note'] | undefined
    updateNotificationConfiguration?: ModelTypes['NotificationConfiguration'] | undefined
    updateProduct?: ModelTypes['Product'] | undefined
    updateProductBonusConfigSnapshot?: ModelTypes['ProductBonusConfigSnapshot'] | undefined
    updateProductKeywords?: ModelTypes['ProductKeywords'] | undefined
    updateProductRatingSnapshot?: ModelTypes['ProductRatingSnapshot'] | undefined
    updatePurchase?: ModelTypes['Purchase'] | undefined
    updateReferral?: ModelTypes['Referral'] | undefined
    updateReview?: ModelTypes['Review'] | undefined
    updateSku?: ModelTypes['Sku'] | undefined
    updateSkuSnapshot?: ModelTypes['SkuSnapshot'] | undefined
    updateUser?: ModelTypes['User'] | undefined
    updateUserBonusPayout?: ModelTypes['UserBonusPayout'] | undefined
    updateUserCampaign?: ModelTypes['UserCampaign'] | undefined
    updateUserCampaignChangelog?: ModelTypes['UserCampaignChangelog'] | undefined
    updateUserCampaignSnapshot?: ModelTypes['UserCampaignSnapshot'] | undefined
    updateUserCampaignSocialContent?: ModelTypes['UserCampaignSocialContent'] | undefined
    updateUserCampaignSocialContentDraftHistory?: ModelTypes['UserCampaignSocialContentDraftHistory'] | undefined
    updateUserCampaignSocialContentReview?: ModelTypes['UserCampaignSocialContentReview'] | undefined
    updateUserDeviceSnapshot?: ModelTypes['UserDeviceSnapshot'] | undefined
    updateUserFollower?: ModelTypes['UserFollower'] | undefined
    updateUserLink?: ModelTypes['UserLink'] | undefined
    updateUserNotificationPreference?: ModelTypes['UserNotificationPreference'] | undefined
    updateUserPayoutRequest?: ModelTypes['UserPayoutRequest'] | undefined
    updateUserPhone: ModelTypes['UpdateUserPhoneResult']
    updateUserStagingApproval?: ModelTypes['UserStagingApproval'] | undefined
    updateVideoContentScan?: ModelTypes['VideoContentScan'] | undefined
    verifySocialVerificationCode: ModelTypes['VerifySocialCodeResult']
  }
  ['Note']: {
    createdAt: string
    id: string
    note: string
    parentId: string
    updatedAt: string
    user?: ModelTypes['User'] | undefined
    userId: string
  }
  ['NotificationConfiguration']: {
    category: string
    createdAt: string
    description?: string | undefined
    email?: ModelTypes['ChannelConfiguration'] | undefined
    enabled?: boolean | undefined
    id: string
    inAppChatChannel?: ModelTypes['MessagingChannelConfiguration'] | undefined
    name: string
    occurrenceConstraint?: ModelTypes['OccurrenceConstraint'] | undefined
    push?: ModelTypes['PushChannelConfiguration'] | undefined
    sms?: ModelTypes['ChannelConfiguration'] | undefined
    updatedAt: string
  }
  ['OccurrenceConstraint']: {
    period?: ModelTypes['OccurrenceConstraintPeriod'] | undefined
    type: ModelTypes['OccurrenceConstraintType']
    value?: number | undefined
  }
  ['OrderConfirmationScreenshotVerificationOutput']: {
    orderDate?: string | undefined
    orderId?: string | undefined
    orderTotal?: number | undefined
    rejectionMessage?: string | undefined
    result: ModelTypes['OrderConfirmationScreenshotVerificationResult']
  }
  ['PayForImpressionsBonusConfig']: {
    enabled?: boolean | undefined
    growthRate?: number | undefined
    impressionsBaseline?: number | undefined
    impressionsCap?: number | undefined
    impressionsIncrement?: number | undefined
    initialBonus?: number | undefined
  }
  ['PayForPostingContentEarlyConfig']: {
    bonusCents?: number | undefined
    daysBeforeDeadline?: number | undefined
    enabled?: boolean | undefined
  }
  ['Product']: {
    accountRequired?: boolean | undefined
    active?: string | undefined
    approvalFilters?: Array<ModelTypes['ApprovalFilterValue']> | undefined
    autoApprovalSeatsAvailable?: number | undefined
    autoApprovalStatusTiers?: Array<ModelTypes['StatusTier']> | undefined
    autoApprovalV2Config?: ModelTypes['AutoApprovalV2Config'] | undefined
    autoBuyConfiguration?: ModelTypes['AutoBuyFlowConfiguration'] | undefined
    autoBuyReleaseSchedule?: ModelTypes['ModelAutoBuyFlowReleaseScheduleConnection'] | undefined
    automaticReviewRelease?: boolean | undefined
    baseBonus?: number | undefined
    bonusHistory?: ModelTypes['ModelProductBonusConfigSnapshotConnection'] | undefined
    brandAdvocateCount?: number | undefined
    brandTalkingPoints?: Array<string> | undefined
    buyOnlinePickupInStore?: ModelTypes['BOPISValue'] | undefined
    campaignId?: string | undefined
    campaignStateMachine?: string | undefined
    closeoutFormBonus?: number | undefined
    contentApprovalConfiguration?: Array<ModelTypes['ContentApprovalConfiguration']> | undefined
    contentApprovalLeadDays?: number | undefined
    contentRequirement?: Array<ModelTypes['ProductSocialContent']> | undefined
    createdAt: string
    creatorCount?: number | undefined
    creatorPreference?: ModelTypes['CreatorPreference'] | undefined
    creatorPreferenceDetails?: string | undefined
    customFtcHashtags?: Array<string> | undefined
    customHashtags?: Array<string> | undefined
    directPurchaseLink?: string | undefined
    engagementRateInstagramRangeRequirement?: ModelTypes['MinMaxRange'] | undefined
    engagementRateTiktokRangeRequirement?: ModelTypes['MinMaxRange'] | undefined
    followersInstagramRangeRequirement?: ModelTypes['MinMaxRange'] | undefined
    followersTiktokRangeRequirement?: ModelTypes['MinMaxRange'] | undefined
    hideBrandContentApprovals?: boolean | undefined
    id: string
    impressionsInstagramRangeRequirement?: ModelTypes['MinMaxRange'] | undefined
    impressionsTiktokRangeRequirement?: ModelTypes['MinMaxRange'] | undefined
    instaHandle?: string | undefined
    internalCreatorsStagingGuidance?: string | undefined
    internalDescription?: string | undefined
    internalName?: string | undefined
    internalNotes?: string | undefined
    isDirectListingPurchase?: boolean | undefined
    isQualityRequirementsEnabled?: boolean | undefined
    listingGuidance?: string | undefined
    maxDiscoveryBonus?: number | undefined
    minimumCreatorQualityScore?: number | undefined
    nonRetailReviewLink?: string | undefined
    nonRetailReviewWebsiteName?: string | undefined
    payForClicksEnabled?: ModelTypes['EnabledFlag'] | undefined
    payForClicksMinClicks?: number | undefined
    payForImpressionsBonus?: ModelTypes['PayForImpressionsBonusConfig'] | undefined
    payForPerformanceBonus?: ModelTypes['StatusTierBonusConfig'] | undefined
    payForPostingContentEarly?: ModelTypes['PayForPostingContentEarlyConfig'] | undefined
    postingDeadlineDaysRelativeToApproval?: number | undefined
    productLink?: ModelTypes['UserLink'] | undefined
    productLinkId?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    promoCode?: string | undefined
    promoCodeGuidance?: string | undefined
    promoCodeType?: ModelTypes['PromoCodeType'] | undefined
    promoDiscountAmount?: number | undefined
    promoDiscountType?: ModelTypes['PromoDiscountType'] | undefined
    promoEndDate?: string | undefined
    promoLink?: string | undefined
    promoStartDate?: string | undefined
    promotionLink?: ModelTypes['UserLink'] | undefined
    promotionLinkId?: string | undefined
    purchaseByPromoCode?: boolean | undefined
    purchaseDiscountCode?: string | undefined
    purchaseDiscountCodeDetails?: string | undefined
    purchaseDiscountCodePercentage?: string | undefined
    qualityLevel?: ModelTypes['ProductQualityLevel'] | undefined
    requestEarlyProductFeedback?: boolean | undefined
    requestProductFeedback?: boolean | undefined
    requiredProductLinkEnabled?: ModelTypes['RequiredProductLinkFlag'] | undefined
    sequence?: ModelTypes['FormSequenceConfiguration'] | undefined
    sequenceId?: string | undefined
    shopifyPriceRule?: string | undefined
    shopifyPromotionId?: string | undefined
    shopifyPromotionType?: ModelTypes['ShopifyPromotionType'] | undefined
    sku?: ModelTypes['Sku'] | undefined
    skuId: string
    socialMediaExampleUrls?: Array<string> | undefined
    stagingApprovalConfiguration?: Array<ModelTypes['StagingApprovalConfiguration']> | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined
    stagingRequirementType?: ModelTypes['StagingRequirementType'] | undefined
    stagingSocialQualityRequirement?: ModelTypes['SocialQuality'] | undefined
    tikTokHandle?: string | undefined
    unitsPurchased?: number | undefined
    updatedAt: string
    validateProductFoundVariations?: Array<string> | undefined
    visibleAt?: string | undefined
    visibleEndsAt?: string | undefined
  }
  ['ProductBonusConfigSnapshot']: {
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<ModelTypes['ProductSocialContent']> | undefined
    createdAt: string
    discoveryBonus?: number | undefined
    payForImpressionsBonus?: ModelTypes['PayForImpressionsBonusConfig'] | undefined
    productId: string
    snapshotAt: string
    updatedAt: string
  }
  ['ProductKeywords']: {
    adminPriority?: number | undefined
    brandPriority?: number | undefined
    brandResponse?: ModelTypes['KeywordAcceptanceResponse'] | undefined
    campaignId: string
    createdAt: string
    creationSource?: ModelTypes['ProductKeywordCreationSource'] | undefined
    endDate?: string | undefined
    heliumAccount?: string | undefined
    heliumAsin?: string | undefined
    heliumLink?: string | undefined
    id: string
    keyword: string
    monthlySearchVolume?: number | undefined
    rank?: number | undefined
    rankingHistory?: Array<ModelTypes['RankPoint']> | undefined
    rankingLastSkippedAt?: string | undefined
    rankingLastSkippedByUserId?: string | undefined
    rankingLastUpdatedAt?: string | undefined
    rankingLastUpdatedByUserId?: string | undefined
    recommended?: boolean | undefined
    skuId?: string | undefined
    startDate?: string | undefined
    updatedAt: string
    usedForCampaign?: boolean | undefined
    visible?: boolean | undefined
    weight?: number | undefined
  }
  ['ProductRatingSnapshot']: {
    createdAt: string
    numFiveStar?: number | undefined
    numFourStar?: number | undefined
    numOneStar?: number | undefined
    numRatings?: number | undefined
    numReviews?: number | undefined
    numThreeStar?: number | undefined
    numTwoStar?: number | undefined
    rating?: number | undefined
    sku: string
    skuId: string
    snapshotAt: string
    updatedAt: string
  }
  ['ProductSocialContent']: {
    bonus: number
    brandContentGuidance?: Array<ModelTypes['ContentGuidance']> | undefined
    brandContentRequirements?: Array<string> | undefined
    bronzeBonus?: number | undefined
    channel: ModelTypes['SocialChannel']
    diamondBonus?: number | undefined
    goldBonus?: number | undefined
    inspirationalContent?: Array<ModelTypes['InspirationalContent']> | undefined
    instances: number
    platinumBonus?: number | undefined
    requirementLevel: ModelTypes['ContentRequirementLevel']
    requiresManualApproval?: boolean | undefined
    silverBonus?: number | undefined
    talkingPoints?: Array<ModelTypes['ContentGuidance']> | undefined
    totalExpectedCount?: number | undefined
    type: ModelTypes['SocialContentType']
  }
  ['Purchase']: {
    amount: number
    cardId?: string | undefined
    createdAt: string
    currency: string
    id: string
    isFraud?: boolean | undefined
    keywordAtPurchase?: string | undefined
    purchasedAt: string
    shippedAt?: string | undefined
    status: ModelTypes['PurchaseStatus']
    updatedAt: string
    userCampaignId: string
  }
  ['PushAddress']: {
    address?: string | undefined
    pushChannel?: ModelTypes['PushChannel'] | undefined
  }
  ['PushChannelConfiguration']: {
    attributes?: Array<ModelTypes['KeyAttribute'] | undefined> | undefined
    enabled?: boolean | undefined
    templateId: string
  }
  ['Query']: {
    ByAgencyId?: ModelTypes['ModelCompanyConnection'] | undefined
    ByBrandId?: ModelTypes['ModelCampaignConnection'] | undefined
    BySkuIdByStoreName?: ModelTypes['ModelSkuConnection'] | undefined
    ByVisibilityToUser?: ModelTypes['ModelCampaignConnection'] | undefined
    ByVisibilityToUserByCreatedAt?: ModelTypes['ModelCampaignConnection'] | undefined
    CampaignsBySkuId?: ModelTypes['ModelCampaignConnection'] | undefined
    asinSnapshotsBySkuId?: ModelTypes['ModelSkuSnapshotConnection'] | undefined
    autoBuyFlowConfigurationByStatusByStartDate?: ModelTypes['ModelAutoBuyFlowConfigurationConnection'] | undefined
    autoBuyReleasesByProductIdDate?: ModelTypes['ModelAutoBuyReleaseConnection'] | undefined
    brandApiByShopId?: ModelTypes['ModelBrandApiConnection'] | undefined
    brandStoreScrapeUrl?: ModelTypes['ModelBrandConnection'] | undefined
    brandsByActive?: ModelTypes['ModelBrandConnection'] | undefined
    brandsByActiveBySubscriptionEndsAt?: ModelTypes['ModelBrandConnection'] | undefined
    brandsByCompanyId?: ModelTypes['ModelBrandConnection'] | undefined
    brandsByPlatform?: ModelTypes['ModelBrandConnection'] | undefined
    byCampaignByCoarseCampaignStatus?: ModelTypes['ModelUserCampaignConnection'] | undefined
    bySkuBySnapshotAt?: ModelTypes['ModelProductRatingSnapshotConnection'] | undefined
    byUserByCoarseCampaignStatus?: ModelTypes['ModelUserCampaignConnection'] | undefined
    campaignInviteByCampaignByUser?: ModelTypes['ModelCampaignInviteConnection'] | undefined
    campaignInviteByNotificationStatusByPriority?: ModelTypes['ModelCampaignInviteConnection'] | undefined
    campaignInviteByUserByCreatedAt?: ModelTypes['ModelCampaignInviteConnection'] | undefined
    campaignSourcingProspectsByIgHandle?: ModelTypes['ModelCampaignSourcingProspectConnection'] | undefined
    campaignSourcingProspectsByTiktokHandle?: ModelTypes['ModelCampaignSourcingProspectConnection'] | undefined
    campaignSourcingProspectsByUserIdentifier?: ModelTypes['ModelCampaignSourcingProspectConnection'] | undefined
    campaignSourcingProspectsByYoutubeHandle?: ModelTypes['ModelCampaignSourcingProspectConnection'] | undefined
    campaignsByActive?: ModelTypes['ModelCampaignConnection'] | undefined
    campaignsByActiveByAnticipatedStartDate?: ModelTypes['ModelCampaignConnection'] | undefined
    campaignsByPayForPerformanceActive?: ModelTypes['ModelCampaignConnection'] | undefined
    chatConversationsV2ByActiveByAdminUnreadCount?: ModelTypes['ModelChatConversationV2Connection'] | undefined
    chatConversationsV2ByChatTypeByActive?: ModelTypes['ModelChatConversationV2Connection'] | undefined
    chatConversationsV2ByChatTypeByAdminUnreadCount?: ModelTypes['ModelChatConversationV2Connection'] | undefined
    chatConversationsV2ByUserIdByActive?: ModelTypes['ModelChatConversationV2Connection'] | undefined
    communicationNotificationsByTraceId?: ModelTypes['ModelCommunicationNotificationConnection'] | undefined
    communicationNotificationsByUserCampaignId?: ModelTypes['ModelCommunicationNotificationConnection'] | undefined
    communicationNotificationsByUserId?: ModelTypes['ModelCommunicationNotificationConnection'] | undefined
    communicationNotificationsByUserIdNotificationId?:
      | ModelTypes['ModelCommunicationNotificationConnection']
      | undefined
    connectedAccountsByAccountIdByAccountType?: ModelTypes['ModelConnectedAccountConnection'] | undefined
    conversationByContactOwnerIdByStatus?: ModelTypes['ModelConversationConnection'] | undefined
    conversationByStatusByStatusChange?: ModelTypes['ModelConversationConnection'] | undefined
    conversationMessageByMessageId?: ModelTypes['ModelConversationMessageConnection'] | undefined
    conversationMessageByStatusBySendAt?: ModelTypes['ModelConversationMessageConnection'] | undefined
    conversationMessageByStatusBySendAttempts?: ModelTypes['ModelConversationMessageConnection'] | undefined
    draftHistoryBySocialContentId?: ModelTypes['ModelUserCampaignSocialContentDraftHistoryConnection'] | undefined
    getAutoBuyDistribution?: ModelTypes['AutoBuyDistribution'] | undefined
    getAutoBuyFlowConfiguration?: ModelTypes['AutoBuyFlowConfiguration'] | undefined
    getAutoBuyFlowReleaseSchedule?: ModelTypes['AutoBuyFlowReleaseSchedule'] | undefined
    getAutoBuyRelease?: ModelTypes['AutoBuyRelease'] | undefined
    getBrand?: ModelTypes['Brand'] | undefined
    getBrandApi?: ModelTypes['BrandApi'] | undefined
    getCampaign?: ModelTypes['Campaign'] | undefined
    getCampaignClicks?: ModelTypes['CampaignClicks'] | undefined
    getCampaignDetailsForUser?: ModelTypes['CampaignForUser'] | undefined
    getCampaignInvite?: ModelTypes['CampaignInvite'] | undefined
    getCampaignSourcingProspect?: ModelTypes['CampaignSourcingProspect'] | undefined
    getChatConversationV2?: ModelTypes['ChatConversationV2'] | undefined
    getCommunicationNotification?: ModelTypes['CommunicationNotification'] | undefined
    getCompany?: ModelTypes['Company'] | undefined
    getConnectedAccount?: ModelTypes['ConnectedAccount'] | undefined
    getConversation?: ModelTypes['Conversation'] | undefined
    getConversationMessage?: ModelTypes['ConversationMessage'] | undefined
    getConversationsAccessToken: ModelTypes['GetConversationsAccessTokenResponse']
    getEtailerSkuMetrics?: ModelTypes['EtailerSkuMetrics'] | undefined
    getFeatureFlag: ModelTypes['GetFeatureFlagResponse']
    getFormDefinition?: ModelTypes['FormDefinition'] | undefined
    getFormSequenceConfiguration?: ModelTypes['FormSequenceConfiguration'] | undefined
    getInstagramStoryScreen?: ModelTypes['InstagramStoryScreen'] | undefined
    getMessage?: ModelTypes['Message'] | undefined
    getNote?: ModelTypes['Note'] | undefined
    getNotificationConfiguration?: ModelTypes['NotificationConfiguration'] | undefined
    getProduct?: ModelTypes['Product'] | undefined
    getProductBonusConfigSnapshot?: ModelTypes['ProductBonusConfigSnapshot'] | undefined
    getProductKeywords?: ModelTypes['ProductKeywords'] | undefined
    getProductRatingSnapshot?: ModelTypes['ProductRatingSnapshot'] | undefined
    getPurchase?: ModelTypes['Purchase'] | undefined
    getReferral?: ModelTypes['Referral'] | undefined
    getReview?: ModelTypes['Review'] | undefined
    getSku?: ModelTypes['Sku'] | undefined
    getSkuInformation?: ModelTypes['SkuInformation'] | undefined
    getSkuSnapshot?: ModelTypes['SkuSnapshot'] | undefined
    getSocialMediaPosts?: Array<ModelTypes['SocialMediaPost']> | undefined
    getUser?: ModelTypes['User'] | undefined
    getUserBonusPayout?: ModelTypes['UserBonusPayout'] | undefined
    getUserCampaign?: ModelTypes['UserCampaign'] | undefined
    getUserCampaignChangelog?: ModelTypes['UserCampaignChangelog'] | undefined
    getUserCampaignSnapshot?: ModelTypes['UserCampaignSnapshot'] | undefined
    getUserCampaignSocialContent?: ModelTypes['UserCampaignSocialContent'] | undefined
    getUserCampaignSocialContentDraftHistory?: ModelTypes['UserCampaignSocialContentDraftHistory'] | undefined
    getUserCampaignSocialContentReview?: ModelTypes['UserCampaignSocialContentReview'] | undefined
    getUserDeviceSnapshot?: ModelTypes['UserDeviceSnapshot'] | undefined
    getUserFollower?: ModelTypes['UserFollower'] | undefined
    getUserLink?: ModelTypes['UserLink'] | undefined
    getUserNotificationPreference?: ModelTypes['UserNotificationPreference'] | undefined
    getUserPayoutRequest?: ModelTypes['UserPayoutRequest'] | undefined
    getUserStagingApproval?: ModelTypes['UserStagingApproval'] | undefined
    getVideoContentScan?: ModelTypes['VideoContentScan'] | undefined
    instagramStoryByExternalId?: ModelTypes['ModelInstagramStoryScreenConnection'] | undefined
    instagramStoryByInstagramUserId?: ModelTypes['ModelInstagramStoryScreenConnection'] | undefined
    instagramStoryByMediaId?: ModelTypes['ModelInstagramStoryScreenConnection'] | undefined
    listAutoBuyDistributions?: ModelTypes['ModelAutoBuyDistributionConnection'] | undefined
    listAutoBuyFlowConfigurations?: ModelTypes['ModelAutoBuyFlowConfigurationConnection'] | undefined
    listAutoBuyFlowReleaseSchedules?: ModelTypes['ModelAutoBuyFlowReleaseScheduleConnection'] | undefined
    listAutoBuyReleases?: ModelTypes['ModelAutoBuyReleaseConnection'] | undefined
    listBrandApis?: ModelTypes['ModelBrandApiConnection'] | undefined
    listBrands?: ModelTypes['ModelBrandConnection'] | undefined
    listCampaignClickss?: ModelTypes['ModelCampaignClicksConnection'] | undefined
    listCampaignInvites?: ModelTypes['ModelCampaignInviteConnection'] | undefined
    listCampaignSourcingProspects?: ModelTypes['ModelCampaignSourcingProspectConnection'] | undefined
    listCampaigns?: ModelTypes['ModelCampaignConnection'] | undefined
    listCampaignsForUsersToApplyTo: ModelTypes['ListCampaignsForUserResponse']
    listChatConversationV2s?: ModelTypes['ModelChatConversationV2Connection'] | undefined
    listCommunicationNotifications?: ModelTypes['ModelCommunicationNotificationConnection'] | undefined
    listCompanys?: ModelTypes['ModelCompanyConnection'] | undefined
    listConnectedAccounts?: ModelTypes['ModelConnectedAccountConnection'] | undefined
    listConversationMessages?: ModelTypes['ModelConversationMessageConnection'] | undefined
    listConversations?: ModelTypes['ModelConversationConnection'] | undefined
    listEtailerSkuMetricss?: ModelTypes['ModelEtailerSkuMetricsConnection'] | undefined
    listFormSequenceConfigurations?: ModelTypes['ModelFormSequenceConfigurationConnection'] | undefined
    listInstagramStoryScreens?: ModelTypes['ModelInstagramStoryScreenConnection'] | undefined
    listMessages?: ModelTypes['ModelMessageConnection'] | undefined
    listNotes?: ModelTypes['ModelNoteConnection'] | undefined
    listNotificationConfigurations?: ModelTypes['ModelNotificationConfigurationConnection'] | undefined
    listProductBonusConfigSnapshots?: ModelTypes['ModelProductBonusConfigSnapshotConnection'] | undefined
    listProductKeywordss?: ModelTypes['ModelProductKeywordsConnection'] | undefined
    listProductRatingSnapshots?: ModelTypes['ModelProductRatingSnapshotConnection'] | undefined
    listProducts?: ModelTypes['ModelProductConnection'] | undefined
    listPurchases?: ModelTypes['ModelPurchaseConnection'] | undefined
    listReferrals?: ModelTypes['ModelReferralConnection'] | undefined
    listReviews?: ModelTypes['ModelReviewConnection'] | undefined
    listSkuSnapshots?: ModelTypes['ModelSkuSnapshotConnection'] | undefined
    listSkus?: ModelTypes['ModelSkuConnection'] | undefined
    listUserBonusPayouts?: ModelTypes['ModelUserBonusPayoutConnection'] | undefined
    listUserCampaignChangelogs?: ModelTypes['ModelUserCampaignChangelogConnection'] | undefined
    listUserCampaignSnapshots?: ModelTypes['ModelUserCampaignSnapshotConnection'] | undefined
    listUserCampaignSocialContentDraftHistorys?:
      | ModelTypes['ModelUserCampaignSocialContentDraftHistoryConnection']
      | undefined
    listUserCampaignSocialContentReviews?: ModelTypes['ModelUserCampaignSocialContentReviewConnection'] | undefined
    listUserCampaignSocialContents?: ModelTypes['ModelUserCampaignSocialContentConnection'] | undefined
    listUserCampaigns?: ModelTypes['ModelUserCampaignConnection'] | undefined
    listUserDeviceSnapshots?: ModelTypes['ModelUserDeviceSnapshotConnection'] | undefined
    listUserFollowers?: ModelTypes['ModelUserFollowerConnection'] | undefined
    listUserLinks?: ModelTypes['ModelUserLinkConnection'] | undefined
    listUserNotificationPreferences?: ModelTypes['ModelUserNotificationPreferenceConnection'] | undefined
    listUserPayoutRequests?: ModelTypes['ModelUserPayoutRequestConnection'] | undefined
    listUserStagingApprovals?: ModelTypes['ModelUserStagingApprovalConnection'] | undefined
    listUsers?: ModelTypes['ModelUserConnection'] | undefined
    listVideoContentScans?: ModelTypes['ModelVideoContentScanConnection'] | undefined
    messagesByUserCampaign?: ModelTypes['ModelMessageConnection'] | undefined
    notesByParentId?: ModelTypes['ModelNoteConnection'] | undefined
    osSearchUsers: ModelTypes['UserSearchOutput']
    productKeywordsByCampaignIdByKeyword?: ModelTypes['ModelProductKeywordsConnection'] | undefined
    productsByActive?: ModelTypes['ModelProductConnection'] | undefined
    productsByCampaignId?: ModelTypes['ModelProductConnection'] | undefined
    productsBySkuId?: ModelTypes['ModelProductConnection'] | undefined
    purchasesCardRetrieve: ModelTypes['CardDetails']
    referralsByReferrerUserIdReferredUserId?: ModelTypes['ModelReferralConnection'] | undefined
    requestSocialContentScript: ModelTypes['RequestSocialContentScriptResponse']
    reviewsByAttributedUserId?: ModelTypes['ModelReviewConnection'] | undefined
    reviewsByProductId?: ModelTypes['ModelReviewConnection'] | undefined
    reviewsByReviewerId?: ModelTypes['ModelReviewConnection'] | undefined
    reviewsBySkuId?: ModelTypes['ModelReviewConnection'] | undefined
    skusByActive?: ModelTypes['ModelSkuConnection'] | undefined
    skusByActiveByAvailabilityDate?: ModelTypes['ModelSkuConnection'] | undefined
    skusByActiveByReleaseDate?: ModelTypes['ModelSkuConnection'] | undefined
    skusByBrand?: ModelTypes['ModelSkuConnection'] | undefined
    skusByParentSkuId?: ModelTypes['ModelSkuConnection'] | undefined
    socialContentByApprovalStatusByApprovedAt?: ModelTypes['ModelUserCampaignSocialContentConnection'] | undefined
    socialContentByApprovalStatusByDraftSubmittedAt?: ModelTypes['ModelUserCampaignSocialContentConnection'] | undefined
    socialContentByDraftStatusByEnteredBrandApprovalAt?:
      | ModelTypes['ModelUserCampaignSocialContentConnection']
      | undefined
    socialContentByReviewStatusByPostedAt?: ModelTypes['ModelUserCampaignSocialContentConnection'] | undefined
    socialContentByReviewStatusByReviewedAt?: ModelTypes['ModelUserCampaignSocialContentConnection'] | undefined
    socialContentByUserCampaignId?: ModelTypes['ModelUserCampaignSocialContentConnection'] | undefined
    socialContentReviewBySocialContentIdByCreatedAt?:
      | ModelTypes['ModelUserCampaignSocialContentReviewConnection']
      | undefined
    socialContentReviewByTypeByStatus?: ModelTypes['ModelUserCampaignSocialContentReviewConnection'] | undefined
    userBonusesByPayoutBatchId?: ModelTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByPayoutState?: ModelTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByUserCampaignId?: ModelTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByUserId?: ModelTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByUserIdByCreatedAt?: ModelTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByUserIdByPayoutState?: ModelTypes['ModelUserBonusPayoutConnection'] | undefined
    userCampaignByCampaignByAcceptanceStatus?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCampaignByCard?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCampaignByStatus?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCampaignByUser?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCardId?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCoarseCampaignStatus?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByKeywordHash?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByOrderId?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByProductByCampaignAcceptanceStatus?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByProductByCampaignStatus?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByStatusByCreatedAt?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByUser?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByUserByCard?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userCampaignChangelogByUserCampaignIdByEntryType?: ModelTypes['ModelUserCampaignChangelogConnection'] | undefined
    userCampaignsByUserByCampaignAcceptanceStatus?: ModelTypes['ModelUserCampaignConnection'] | undefined
    userDeviceSnapshotByIdfvBySnapshotAt?: ModelTypes['ModelUserDeviceSnapshotConnection'] | undefined
    userFollowerByFollowerUserId?: ModelTypes['ModelUserFollowerConnection'] | undefined
    userFollowerByUserIdByIsFavorite?: ModelTypes['ModelUserFollowerConnection'] | undefined
    userLinksByCampaignIdUniqueClicks?: ModelTypes['ModelUserLinkConnection'] | undefined
    userLinksByUserEncodedLink?: ModelTypes['ModelUserLinkConnection'] | undefined
    userPayoutRequestsByPayoutBatchId?: ModelTypes['ModelUserPayoutRequestConnection'] | undefined
    userPayoutRequestsByPayoutState?: ModelTypes['ModelUserPayoutRequestConnection'] | undefined
    userPayoutRequestsByUserId?: ModelTypes['ModelUserPayoutRequestConnection'] | undefined
    userStagingApprovalsByOutcomeByType?: ModelTypes['ModelUserStagingApprovalConnection'] | undefined
    userStagingApprovalsByUserCampaignId?: ModelTypes['ModelUserStagingApprovalConnection'] | undefined
    usersByActiveByCreatedAt?: ModelTypes['ModelUserConnection'] | undefined
    usersByActiveByLastActiveAt?: ModelTypes['ModelUserConnection'] | undefined
    usersByAmznProfileId?: ModelTypes['ModelUserConnection'] | undefined
    usersByAmznReviewerId?: ModelTypes['ModelUserConnection'] | undefined
    usersByContentRatingSubmittedBy?: ModelTypes['ModelUserConnection'] | undefined
    usersByContentReviewStatusByCompletedAt?: ModelTypes['ModelUserConnection'] | undefined
    usersByContentReviewStatusByLastActiveAt?: ModelTypes['ModelUserConnection'] | undefined
    usersByEmail?: ModelTypes['ModelUserConnection'] | undefined
    usersByFacebookUserId?: ModelTypes['ModelUserConnection'] | undefined
    usersByInstagram?: ModelTypes['ModelUserConnection'] | undefined
    usersByInstagramTrailingPostEngagementAvg?: ModelTypes['ModelUserConnection'] | undefined
    usersByInstagramUserId?: ModelTypes['ModelUserConnection'] | undefined
    usersByReferralCode?: ModelTypes['ModelUserConnection'] | undefined
    usersByReferrer?: ModelTypes['ModelUserConnection'] | undefined
    usersBySupportPriorityFlagByLastActiveAt?: ModelTypes['ModelUserConnection'] | undefined
    usersByTiktokAccountId?: ModelTypes['ModelUserConnection'] | undefined
    usersByTiktokHandle?: ModelTypes['ModelUserConnection'] | undefined
    usersByYoutubeAccountId?: ModelTypes['ModelUserConnection'] | undefined
    verifyOrderConfirmationScreenshot: ModelTypes['OrderConfirmationScreenshotVerificationOutput']
    verifyReviewScreenshot: ModelTypes['ReviewScreenshotVerificationResult']
    videoContentScanByProcessId?: ModelTypes['ModelVideoContentScanConnection'] | undefined
    videoContentScanByScanStatus?: ModelTypes['ModelVideoContentScanConnection'] | undefined
    videoContentScanByUrl?: ModelTypes['ModelVideoContentScanConnection'] | undefined
    videoContentScanByUserId?: ModelTypes['ModelVideoContentScanConnection'] | undefined
  }
  ['RankPoint']: {
    rank: number
    rankAt: string
  }
  ['Rate']: {
    count?: number | undefined
    isIntegrationFallbackRate?: boolean | undefined
    period?: ModelTypes['Period'] | undefined
  }
  ['Referral']: {
    bonusPayout?: ModelTypes['UserBonusPayout'] | undefined
    bonusPayoutId?: string | undefined
    campaign?: ModelTypes['Campaign'] | undefined
    campaignId?: string | undefined
    createdAt: string
    id: string
    referralType?: ModelTypes['ReferralType'] | undefined
    referredUserId: string
    referrerUserId: string
    updatedAt: string
  }
  ['ReferralBonuses']: {
    campaignReferralBonuses: Array<ModelTypes['CampaignReferralBonus']>
    creatorReferralBonuses: Array<ModelTypes['CreatorReferralBonus']>
    creatorReferralSignups: Array<ModelTypes['CreatorReferralSignup']>
  }
  ['RequestSocialContentScriptResponse']: {
    status: string
  }
  ['ResetUserAttributeResponse']: {
    cognitoUserModel?: ModelTypes['CognitoUserModel'] | undefined
    statusCode: number
  }
  ['Review']: {
    attributedUserId?: string | undefined
    author?: string | undefined
    authorBadge?: string | undefined
    badge?: string | undefined
    commentCount?: number | undefined
    createdAt: string
    helpfulReactions?: number | undefined
    id: string
    negativeReactions?: number | undefined
    photos?: Array<string> | undefined
    productId: string
    productVariant?: string | undefined
    rating?: number | undefined
    removed?: boolean | undefined
    reviewedAt: string
    reviewedSku?: string | undefined
    reviewerId?: string | undefined
    sku?: string | undefined
    skuId: string
    store?: string | undefined
    text?: string | undefined
    title?: string | undefined
    updatedAt: string
    userCampaignId?: string | undefined
    videos?: Array<string> | undefined
  }
  ['SequenceFormSubmissionValues']: {
    componentId: string
    componentName: string
    componentType?: ModelTypes['FormComponentType'] | undefined
    sequenceId: string
    type?: ModelTypes['FormFieldType'] | undefined
    value: string
  }
  ['Sku']: {
    active?: string | undefined
    approxRetailPrice?: string | undefined
    approxShippingCost?: number | undefined
    attributes?: Array<ModelTypes['SkuAttribute']> | undefined
    availabilityDate?: string | undefined
    availabilityNotified?: boolean | undefined
    availabilityNotifiedAt?: string | undefined
    brandId?: string | undefined
    brandListingKey?: string | undefined
    brandStoreId?: string | undefined
    bundle?: string | undefined
    childSkus?: ModelTypes['ModelSkuConnection'] | undefined
    createdAt: string
    createdByUserId?: string | undefined
    creationSource?: ModelTypes['SkuCreationSource'] | undefined
    creationSourceUrl?: string | undefined
    currency?: ModelTypes['Currency'] | undefined
    currentBuyBoxPrice?: number | undefined
    currentBuyBoxPriceCents?: number | undefined
    estimatedAvailabilityDate?: string | undefined
    estimatedDeliveryDays?: number | undefined
    estimatedMonthlyUnitsSold?: number | undefined
    estimatedWeeklyUnitsSold?: number | undefined
    exactListingTitle?: string | undefined
    exactRetailPrice?: string | undefined
    fullReviewScrapeComplete?: boolean | undefined
    id: string
    inventory?: number | undefined
    iosAppProviderId?: string | undefined
    isAplusPage?: string | undefined
    isPrime?: string | undefined
    isRecommendationsHidden?: boolean | undefined
    itemClassification?: ModelTypes['ItemClassification'] | undefined
    lastAutoFilledAt?: string | undefined
    lastKnownBuyBoxPrice?: number | undefined
    lastKnownBuyBoxPriceCents?: number | undefined
    listingBrand?: string | undefined
    listingCategory?: Array<string> | undefined
    listingDescription?: string | undefined
    listingFulfillmentType?: ModelTypes['ListingFulfillmentType'] | undefined
    listingGuidance?: string | undefined
    listingLink?: string | undefined
    mccValue?: ModelTypes['ValidMccCodes'] | undefined
    minimumOrderQuantity?: number | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    nonSponsoredListingKey?: string | undefined
    parentSku?: ModelTypes['Sku'] | undefined
    parentSkuId?: string | undefined
    priceCents?: number | undefined
    product?: string | undefined
    productImageKey?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    productSearchImageKey?: string | undefined
    releaseDate?: string | undefined
    skuId?: string | undefined
    sponsoredListingKey?: string | undefined
    storeName?: string | undefined
    targetReviewRate?: ModelTypes['Rate'] | undefined
    targetReviewRateInvalid?: string | undefined
    updatedAt: string
  }
  ['SkuAttribute']: {
    name: string
    value: string
  }
  ['SkuDimensions']: {
    name?: string | undefined
    value?: string | undefined
  }
  ['SkuInformation']: {
    brand?: string | undefined
    childSkus?: Array<string | undefined> | undefined
    id: string
    image?: string | undefined
    link?: string | undefined
    name: string
    parentSku?: string | undefined
    price?: number | undefined
    regularPrice?: number | undefined
    shippingCost?: number | undefined
    store?: string | undefined
  }
  ['SkuSnapshot']: {
    creationDateTime: string
    inventory?: number | undefined
    marketplaceSku: string
    price?: number | undefined
    skuId: string
    snapshotDate: string
    updatedDateTime: string
  }
  ['SocialMediaFeedback']: {
    comments?: string | undefined
    rating?: number | undefined
  }
  ['SocialMediaPost']: {
    caption?: string | undefined
    contentType?: ModelTypes['SocialMediaPostContentType'] | undefined
    groupTypes?: Array<ModelTypes['SocialMediaPostGroupType'] | undefined> | undefined
    id?: string | undefined
    isCopyrightedContent?: boolean | undefined
    link?: string | undefined
    mediaUrl?: string | undefined
    postedAt?: string | undefined
    views?: number | undefined
  }
  ['SocialMetric']: {
    name: ModelTypes['SocialMetricName']
    value: number
  }
  ['SocialUnlinkResult']: {
    userId: string
    verified: boolean
  }
  ['SocialVerificationCode']: {
    code: string
    id: string
    ttl: number
  }
  ['StagingApprovalConfiguration']: {
    stagingApprovalAssignee?: string | undefined
    stagingApprovalType: ModelTypes['UserStagingApprovalType']
  }
  ['StatusHistory']: {
    campaignStatus: ModelTypes['CampaignStatus']
    createdById?: string | undefined
    source?: ModelTypes['StateChangeSource'] | undefined
    time: number
  }
  ['StatusTierBonusConfig']: {
    baseBonus?: number | undefined
    diamondBonus?: number | undefined
    goldBonus?: number | undefined
    platinumBonus?: number | undefined
    silverBonus?: number | undefined
  }
  ['StatusTierEntry']: {
    statusTier: ModelTypes['StatusTier']
    statusTierAt: string
  }
  ['Subscription']: {
    onCreateAutoBuyDistribution?: ModelTypes['AutoBuyDistribution'] | undefined
    onCreateAutoBuyFlowConfiguration?: ModelTypes['AutoBuyFlowConfiguration'] | undefined
    onCreateAutoBuyFlowReleaseSchedule?: ModelTypes['AutoBuyFlowReleaseSchedule'] | undefined
    onCreateAutoBuyRelease?: ModelTypes['AutoBuyRelease'] | undefined
    onCreateBrand?: ModelTypes['Brand'] | undefined
    onCreateBrandApi?: ModelTypes['BrandApi'] | undefined
    onCreateCampaign?: ModelTypes['Campaign'] | undefined
    onCreateCampaignClicks?: ModelTypes['CampaignClicks'] | undefined
    onCreateCampaignInvite?: ModelTypes['CampaignInvite'] | undefined
    onCreateCampaignSourcingProspect?: ModelTypes['CampaignSourcingProspect'] | undefined
    onCreateChatConversationV2?: ModelTypes['ChatConversationV2'] | undefined
    onCreateCommunicationNotification?: ModelTypes['CommunicationNotification'] | undefined
    onCreateCompany?: ModelTypes['Company'] | undefined
    onCreateConnectedAccount?: ModelTypes['ConnectedAccount'] | undefined
    onCreateConversation?: ModelTypes['Conversation'] | undefined
    onCreateConversationMessage?: ModelTypes['ConversationMessage'] | undefined
    onCreateEtailerSkuMetrics?: ModelTypes['EtailerSkuMetrics'] | undefined
    onCreateFormSequenceConfiguration?: ModelTypes['FormSequenceConfiguration'] | undefined
    onCreateInstagramStoryScreen?: ModelTypes['InstagramStoryScreen'] | undefined
    onCreateMessage?: ModelTypes['Message'] | undefined
    onCreateNote?: ModelTypes['Note'] | undefined
    onCreateNotificationConfiguration?: ModelTypes['NotificationConfiguration'] | undefined
    onCreateProduct?: ModelTypes['Product'] | undefined
    onCreateProductBonusConfigSnapshot?: ModelTypes['ProductBonusConfigSnapshot'] | undefined
    onCreateProductKeywords?: ModelTypes['ProductKeywords'] | undefined
    onCreateProductRatingSnapshot?: ModelTypes['ProductRatingSnapshot'] | undefined
    onCreatePurchase?: ModelTypes['Purchase'] | undefined
    onCreateReferral?: ModelTypes['Referral'] | undefined
    onCreateReview?: ModelTypes['Review'] | undefined
    onCreateSku?: ModelTypes['Sku'] | undefined
    onCreateSkuSnapshot?: ModelTypes['SkuSnapshot'] | undefined
    onCreateUser?: ModelTypes['User'] | undefined
    onCreateUserBonusPayout?: ModelTypes['UserBonusPayout'] | undefined
    onCreateUserCampaign?: ModelTypes['UserCampaign'] | undefined
    onCreateUserCampaignChangelog?: ModelTypes['UserCampaignChangelog'] | undefined
    onCreateUserCampaignSnapshot?: ModelTypes['UserCampaignSnapshot'] | undefined
    onCreateUserCampaignSocialContent?: ModelTypes['UserCampaignSocialContent'] | undefined
    onCreateUserCampaignSocialContentDraftHistory?: ModelTypes['UserCampaignSocialContentDraftHistory'] | undefined
    onCreateUserCampaignSocialContentReview?: ModelTypes['UserCampaignSocialContentReview'] | undefined
    onCreateUserDeviceSnapshot?: ModelTypes['UserDeviceSnapshot'] | undefined
    onCreateUserFollower?: ModelTypes['UserFollower'] | undefined
    onCreateUserLink?: ModelTypes['UserLink'] | undefined
    onCreateUserNotificationPreference?: ModelTypes['UserNotificationPreference'] | undefined
    onCreateUserPayoutRequest?: ModelTypes['UserPayoutRequest'] | undefined
    onCreateUserStagingApproval?: ModelTypes['UserStagingApproval'] | undefined
    onCreateVideoContentScan?: ModelTypes['VideoContentScan'] | undefined
    onDeleteAutoBuyDistribution?: ModelTypes['AutoBuyDistribution'] | undefined
    onDeleteAutoBuyFlowConfiguration?: ModelTypes['AutoBuyFlowConfiguration'] | undefined
    onDeleteAutoBuyFlowReleaseSchedule?: ModelTypes['AutoBuyFlowReleaseSchedule'] | undefined
    onDeleteAutoBuyRelease?: ModelTypes['AutoBuyRelease'] | undefined
    onDeleteBrand?: ModelTypes['Brand'] | undefined
    onDeleteBrandApi?: ModelTypes['BrandApi'] | undefined
    onDeleteCampaign?: ModelTypes['Campaign'] | undefined
    onDeleteCampaignClicks?: ModelTypes['CampaignClicks'] | undefined
    onDeleteCampaignInvite?: ModelTypes['CampaignInvite'] | undefined
    onDeleteCampaignSourcingProspect?: ModelTypes['CampaignSourcingProspect'] | undefined
    onDeleteChatConversationV2?: ModelTypes['ChatConversationV2'] | undefined
    onDeleteCommunicationNotification?: ModelTypes['CommunicationNotification'] | undefined
    onDeleteCompany?: ModelTypes['Company'] | undefined
    onDeleteConnectedAccount?: ModelTypes['ConnectedAccount'] | undefined
    onDeleteConversation?: ModelTypes['Conversation'] | undefined
    onDeleteConversationMessage?: ModelTypes['ConversationMessage'] | undefined
    onDeleteEtailerSkuMetrics?: ModelTypes['EtailerSkuMetrics'] | undefined
    onDeleteFormSequenceConfiguration?: ModelTypes['FormSequenceConfiguration'] | undefined
    onDeleteInstagramStoryScreen?: ModelTypes['InstagramStoryScreen'] | undefined
    onDeleteMessage?: ModelTypes['Message'] | undefined
    onDeleteNote?: ModelTypes['Note'] | undefined
    onDeleteNotificationConfiguration?: ModelTypes['NotificationConfiguration'] | undefined
    onDeleteProduct?: ModelTypes['Product'] | undefined
    onDeleteProductBonusConfigSnapshot?: ModelTypes['ProductBonusConfigSnapshot'] | undefined
    onDeleteProductKeywords?: ModelTypes['ProductKeywords'] | undefined
    onDeleteProductRatingSnapshot?: ModelTypes['ProductRatingSnapshot'] | undefined
    onDeletePurchase?: ModelTypes['Purchase'] | undefined
    onDeleteReferral?: ModelTypes['Referral'] | undefined
    onDeleteReview?: ModelTypes['Review'] | undefined
    onDeleteSku?: ModelTypes['Sku'] | undefined
    onDeleteSkuSnapshot?: ModelTypes['SkuSnapshot'] | undefined
    onDeleteUser?: ModelTypes['User'] | undefined
    onDeleteUserBonusPayout?: ModelTypes['UserBonusPayout'] | undefined
    onDeleteUserCampaign?: ModelTypes['UserCampaign'] | undefined
    onDeleteUserCampaignChangelog?: ModelTypes['UserCampaignChangelog'] | undefined
    onDeleteUserCampaignSnapshot?: ModelTypes['UserCampaignSnapshot'] | undefined
    onDeleteUserCampaignSocialContent?: ModelTypes['UserCampaignSocialContent'] | undefined
    onDeleteUserCampaignSocialContentDraftHistory?: ModelTypes['UserCampaignSocialContentDraftHistory'] | undefined
    onDeleteUserCampaignSocialContentReview?: ModelTypes['UserCampaignSocialContentReview'] | undefined
    onDeleteUserDeviceSnapshot?: ModelTypes['UserDeviceSnapshot'] | undefined
    onDeleteUserFollower?: ModelTypes['UserFollower'] | undefined
    onDeleteUserLink?: ModelTypes['UserLink'] | undefined
    onDeleteUserNotificationPreference?: ModelTypes['UserNotificationPreference'] | undefined
    onDeleteUserPayoutRequest?: ModelTypes['UserPayoutRequest'] | undefined
    onDeleteUserStagingApproval?: ModelTypes['UserStagingApproval'] | undefined
    onDeleteVideoContentScan?: ModelTypes['VideoContentScan'] | undefined
    onUpdateAutoBuyDistribution?: ModelTypes['AutoBuyDistribution'] | undefined
    onUpdateAutoBuyFlowConfiguration?: ModelTypes['AutoBuyFlowConfiguration'] | undefined
    onUpdateAutoBuyFlowReleaseSchedule?: ModelTypes['AutoBuyFlowReleaseSchedule'] | undefined
    onUpdateAutoBuyRelease?: ModelTypes['AutoBuyRelease'] | undefined
    onUpdateBrand?: ModelTypes['Brand'] | undefined
    onUpdateBrandApi?: ModelTypes['BrandApi'] | undefined
    onUpdateCampaign?: ModelTypes['Campaign'] | undefined
    onUpdateCampaignClicks?: ModelTypes['CampaignClicks'] | undefined
    onUpdateCampaignInvite?: ModelTypes['CampaignInvite'] | undefined
    onUpdateCampaignSourcingProspect?: ModelTypes['CampaignSourcingProspect'] | undefined
    onUpdateChatConversationV2?: ModelTypes['ChatConversationV2'] | undefined
    onUpdateCommunicationNotification?: ModelTypes['CommunicationNotification'] | undefined
    onUpdateCompany?: ModelTypes['Company'] | undefined
    onUpdateConnectedAccount?: ModelTypes['ConnectedAccount'] | undefined
    onUpdateConversation?: ModelTypes['Conversation'] | undefined
    onUpdateConversationMessage?: ModelTypes['ConversationMessage'] | undefined
    onUpdateEtailerSkuMetrics?: ModelTypes['EtailerSkuMetrics'] | undefined
    onUpdateFormSequenceConfiguration?: ModelTypes['FormSequenceConfiguration'] | undefined
    onUpdateInstagramStoryScreen?: ModelTypes['InstagramStoryScreen'] | undefined
    onUpdateMessage?: ModelTypes['Message'] | undefined
    onUpdateNote?: ModelTypes['Note'] | undefined
    onUpdateNotificationConfiguration?: ModelTypes['NotificationConfiguration'] | undefined
    onUpdateProduct?: ModelTypes['Product'] | undefined
    onUpdateProductBonusConfigSnapshot?: ModelTypes['ProductBonusConfigSnapshot'] | undefined
    onUpdateProductKeywords?: ModelTypes['ProductKeywords'] | undefined
    onUpdateProductRatingSnapshot?: ModelTypes['ProductRatingSnapshot'] | undefined
    onUpdatePurchase?: ModelTypes['Purchase'] | undefined
    onUpdateReferral?: ModelTypes['Referral'] | undefined
    onUpdateReview?: ModelTypes['Review'] | undefined
    onUpdateSku?: ModelTypes['Sku'] | undefined
    onUpdateSkuSnapshot?: ModelTypes['SkuSnapshot'] | undefined
    onUpdateUser?: ModelTypes['User'] | undefined
    onUpdateUserBonusPayout?: ModelTypes['UserBonusPayout'] | undefined
    onUpdateUserCampaign?: ModelTypes['UserCampaign'] | undefined
    onUpdateUserCampaignChangelog?: ModelTypes['UserCampaignChangelog'] | undefined
    onUpdateUserCampaignSnapshot?: ModelTypes['UserCampaignSnapshot'] | undefined
    onUpdateUserCampaignSocialContent?: ModelTypes['UserCampaignSocialContent'] | undefined
    onUpdateUserCampaignSocialContentDraftHistory?: ModelTypes['UserCampaignSocialContentDraftHistory'] | undefined
    onUpdateUserCampaignSocialContentReview?: ModelTypes['UserCampaignSocialContentReview'] | undefined
    onUpdateUserDeviceSnapshot?: ModelTypes['UserDeviceSnapshot'] | undefined
    onUpdateUserFollower?: ModelTypes['UserFollower'] | undefined
    onUpdateUserLink?: ModelTypes['UserLink'] | undefined
    onUpdateUserNotificationPreference?: ModelTypes['UserNotificationPreference'] | undefined
    onUpdateUserPayoutRequest?: ModelTypes['UserPayoutRequest'] | undefined
    onUpdateUserStagingApproval?: ModelTypes['UserStagingApproval'] | undefined
    onUpdateVideoContentScan?: ModelTypes['VideoContentScan'] | undefined
  }
  ['UpdateUserPhoneResult']: {
    phoneNumber: string
    userId: string
  }
  ['User']: {
    active?: ModelTypes['Active'] | undefined
    addressComponents?: string | undefined
    amznLastChecked?: string | undefined
    amznLink?: string | undefined
    amznPrime?: string | undefined
    amznProfileId?: string | undefined
    amznReviewerId?: string | undefined
    amznVerified?: string | undefined
    attributedReviewCount?: number | undefined
    blogWebsite?: string | undefined
    brandContentRatingAvg?: number | undefined
    campaignAcceptanceRate?: number | undefined
    campaignApprovalRate?: number | undefined
    campaignCompletionRate?: number | undefined
    campaignReviewRate?: number | undefined
    cardProfileUpdates?: string | undefined
    cardholderId?: string | undefined
    cardholderRegion?: ModelTypes['StripeRegion'] | undefined
    characteristics?: Array<ModelTypes['UserCharacteristic']> | undefined
    characteristicsUpdatedAt?: string | undefined
    claimedReviewCount?: number | undefined
    collisionSkuSet?: Array<string> | undefined
    collisionSkuSetCalculatedAt?: string | undefined
    completedLifestyleCampaignCount?: number | undefined
    completedLiftCampaignCount?: number | undefined
    completedSocialCampaignCount?: number | undefined
    connectedAccounts?: ModelTypes['ModelConnectedAccountConnection'] | undefined
    contentRating?: ModelTypes['UserContentRating'] | undefined
    contentRatingCompletedAt?: string | undefined
    contentRatingCompletionTimeMillis?: number | undefined
    contentRatingInstagramCompletionTimeMillis?: number | undefined
    contentRatingInstagramSponsorshipContent?: Array<ModelTypes['ContentRatingSponsorshipContent']> | undefined
    contentRatingQualityScore?: number | undefined
    contentRatingReviewStatus?: ModelTypes['ContentRatingReviewStatus'] | undefined
    contentRatingSponsorshipContent?: Array<ModelTypes['ContentRatingSponsorshipContent']> | undefined
    contentRatingSubmittedBy?: string | undefined
    contentRatingTiktokCompletionTimeMillis?: number | undefined
    contentRatingTiktokSponsorshipContent?: Array<ModelTypes['ContentRatingSponsorshipContent']> | undefined
    contentTypes?: Array<string> | undefined
    createdAt?: string | undefined
    date_of_birth?: string | undefined
    deactivationDate?: string | undefined
    deactivationReason?: string | undefined
    declineToReviewCount?: number | undefined
    deranked?: ModelTypes['Deranked_Reason'] | undefined
    email?: string | undefined
    emailOptOut?: boolean | undefined
    facebookAccessToken?: string | undefined
    facebookAccessTokenExpiry?: string | undefined
    facebookPageId?: string | undefined
    facebookPageIsSubscribed?: boolean | undefined
    facebookPermissionScopes?: Array<string | undefined> | undefined
    facebookUserId?: string | undefined
    firstName?: string | undefined
    followedUsers?: ModelTypes['ModelUserFollowerConnection'] | undefined
    followers?: ModelTypes['ModelUserFollowerConnection'] | undefined
    followsWesponsored?: string | undefined
    fraudReason?: ModelTypes['FraudReason'] | undefined
    fraudReviewAt?: string | undefined
    fraudReviewStatus?: ModelTypes['FraudReviewStatus'] | undefined
    gender?: string | undefined
    hasTrueEngagement?: boolean | undefined
    id: string
    idfaId?: string | undefined
    incompleteCampaignCount?: number | undefined
    instagramBio?: string | undefined
    instagramBusinessCategoryName?: string | undefined
    instagramCategoryName?: string | undefined
    instagramChecked?: string | undefined
    instagramContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    instagramExternalLink?: string | undefined
    instagramFollowerCountryDistribution?: Array<ModelTypes['InstagramCountryDistributionItem']> | undefined
    instagramFollowerGenderAgeDistribution?: ModelTypes['InstagramGenderAgeDistribution'] | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    instagramIsBusiness?: string | undefined
    instagramIsPrivate?: string | undefined
    instagramIsRecentlyJoined?: string | undefined
    instagramIsVerified?: string | undefined
    instagramLastChecked?: string | undefined
    instagramMedianShares?: number | undefined
    instagramMetricsRetrievedAt?: string | undefined
    instagramName?: string | undefined
    instagramPostsInt?: number | undefined
    instagramPriority?: ModelTypes['PlatformPriority'] | undefined
    instagramProfilePic?: string | undefined
    instagramTrailingComments?: number | undefined
    instagramTrailingDateOfOldestPost?: string | undefined
    instagramTrailingLikes?: number | undefined
    instagramTrailingNumberOfPosts?: number | undefined
    instagramTrailingPostEngagementAvg?: number | undefined
    instagramTrailingPostEngagementRateAvg?: number | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined
    instagramTrailingPostEngagementRateMedian?: number | undefined
    instagramTrailingPostImpressionsAvg?: number | undefined
    instagramTrailingPostImpressionsMedian?: number | undefined
    instagramTrailingPostSponsoredPercentage?: number | undefined
    instagramTrailingPostViewsMedian?: number | undefined
    instagramUserId?: string | undefined
    instagramVerificationMethod?: ModelTypes['SocialVerificationMethod'] | undefined
    internalGender?: ModelTypes['UserGender'] | undefined
    internalNotes?: ModelTypes['ModelNoteConnection'] | undefined
    isBrandSafe?: ModelTypes['BrandSafe'] | undefined
    isBrandSafeReason?: string | undefined
    isBrandSafeUpdatedAt?: string | undefined
    lastActiveAt?: string | undefined
    lastName?: string | undefined
    manualScoringLockedUntil?: string | undefined
    moreinfo?: string | undefined
    notificationPreferences?: ModelTypes['ModelUserNotificationPreferenceConnection'] | undefined
    numEvenDollarPurchases?: number | undefined
    numPurchases?: number | undefined
    paypalEmail?: string | undefined
    phoneNumber?: string | undefined
    profilePicture?: string | undefined
    pushAddresses?: Array<ModelTypes['PushAddress']> | undefined
    pushOptOut?: boolean | undefined
    referralCode?: string | undefined
    referralCount?: number | undefined
    referrer?: string | undefined
    signupCountry?: string | undefined
    smsOptOut?: boolean | undefined
    socialQuality?: ModelTypes['SocialQuality'] | undefined
    specialAttributes?: ModelTypes['UserAttributes'] | undefined
    statusTier?: ModelTypes['StatusTier'] | undefined
    statusTierHistory?: Array<ModelTypes['StatusTierEntry']> | undefined
    statusTierLastUpdatedAt?: string | undefined
    supportPriorityAcknowledgedAt?: string | undefined
    supportPriorityFlag?: ModelTypes['SupportPriorityFlag'] | undefined
    supportPriorityFlagSetAt?: string | undefined
    targetAccount?: string | undefined
    tiktokAccessToken?: string | undefined
    tiktokAccessTokenExpiry?: string | undefined
    tiktokAccountId?: string | undefined
    tiktokAveragePostEngagementRate?: number | undefined
    tiktokAveragePostEngagementRateViews?: number | undefined
    tiktokBio?: string | undefined
    tiktokContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    tiktokMedianComments?: number | undefined
    tiktokMedianLikes?: number | undefined
    tiktokMedianPostEngagement?: number | undefined
    tiktokMedianPostEngagementRate?: number | undefined
    tiktokMedianPostEngagementRateViews?: number | undefined
    tiktokMedianShares?: number | undefined
    tiktokMedianViews?: number | undefined
    tiktokMetricsRetrievedAt?: string | undefined
    tiktokName?: string | undefined
    tiktokPermissionScopes?: Array<string> | undefined
    tiktokPriority?: ModelTypes['PlatformPriority'] | undefined
    tiktokProfilePic?: string | undefined
    tiktokRefreshToken?: string | undefined
    tiktokRefreshTokenExpiry?: string | undefined
    tiktokSponsoredPostPercentage?: number | undefined
    tiktokVerificationMethod?: ModelTypes['SocialVerificationMethod'] | undefined
    tiktokVerified?: string | undefined
    timeZone?: string | undefined
    trackingStatus?: ModelTypes['TrackingStatus'] | undefined
    twitchAccount?: string | undefined
    type: string
    updatedAt: string
    userCampaigns?: ModelTypes['ModelUserCampaignConnection'] | undefined
    utmReferral?: ModelTypes['UtmReferral'] | undefined
    videoContentScans?: ModelTypes['ModelVideoContentScanConnection'] | undefined
    wmartAccount?: string | undefined
    youtubeAccessToken?: string | undefined
    youtubeAccessTokenExpiry?: string | undefined
    youtubeAccount?: string | undefined
    youtubeAveragePostEngagementRate?: number | undefined
    youtubeChannelDescription?: string | undefined
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
    youtubeChannelId?: string | undefined
    youtubeChannelPicture?: string | undefined
    youtubeChannelTitle?: string | undefined
    youtubeIdToken?: string | undefined
    youtubeMedianViews?: number | undefined
    youtubeMetricsRetrievedAt?: string | undefined
    youtubePermissionScopes?: Array<string> | undefined
    youtubeRefreshToken?: string | undefined
    youtubeVerificationMethod?: ModelTypes['SocialVerificationMethod'] | undefined
  }
  ['UserAttributes']: {
    isMother?: boolean | undefined
  }
  ['UserBonusPayout']: {
    adminPaymentApproved?: boolean | undefined
    bonusType: ModelTypes['BonusPayoutTypes']
    createdAt?: string | undefined
    failureReason?: string | undefined
    id: string
    manuallyCreatedBy?: string | undefined
    payoutAmount?: number | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: ModelTypes['WSLocale'] | undefined
    payoutState?: ModelTypes['BonusPayoutState'] | undefined
    quantity?: number | undefined
    referenceId?: string | undefined
    releaseAt?: string | undefined
    revocationType?: ModelTypes['RevocationType'] | undefined
    revokedAt?: string | undefined
    updatedAt: string
    userCampaignId?: string | undefined
    userId: string
  }
  ['UserCampaign']: {
    accountScreenShot?: string | undefined
    amznReviewLink?: string | undefined
    amznReviewSubmissionStatus?: ModelTypes['AmazonReviewSubmissionStatus'] | undefined
    applicationRejectedByUser?: ModelTypes['User'] | undefined
    applicationRejectedByUserId?: string | undefined
    approvalOutcome?: ModelTypes['ApprovalOutcome'] | undefined
    approvalStatus?: ModelTypes['UserCampaignApprovalStatus'] | undefined
    archivedFeedback?: string | undefined
    archivedReason?: ModelTypes['ArchivedReason'] | undefined
    autoBuyApprovalDate?: string | undefined
    autoBuyRevokeDate?: string | undefined
    bestBuyReviewScreenShot?: string | undefined
    bonusConfig?: ModelTypes['UserCampaignBonusConfig'] | undefined
    campaign?: ModelTypes['Campaign'] | undefined
    campaignAcceptanceStatus?: ModelTypes['UserCampaignAcceptanceStatus'] | undefined
    campaignApplication?: string | undefined
    campaignId: string
    campaignStatus?: ModelTypes['CampaignStatus'] | undefined
    campaignStatusOnArchive?: string | undefined
    cardId?: string | undefined
    cardRegion?: ModelTypes['StripeRegion'] | undefined
    changelog?: ModelTypes['ModelUserCampaignChangelogConnection'] | undefined
    closeoutSurvey?: string | undefined
    coarseCampaignStatus?: ModelTypes['CoarseCampaignStatus'] | undefined
    communications?: ModelTypes['ModelCommunicationNotificationConnection'] | undefined
    contentApprovalLeadDays?: number | undefined
    createdAt?: string | undefined
    discoverySurvey?: string | undefined
    id: string
    influencerApprovalInviteFeedback?: string | undefined
    influencerApprovalRejectFeedback?: string | undefined
    internalNotes?: ModelTypes['ModelNoteConnection'] | undefined
    invoiceKey?: string | undefined
    isAutoApproved?: boolean | undefined
    keyword?: ModelTypes['ProductKeywords'] | undefined
    keywordAtPurchase?: string | undefined
    keywordId?: string | undefined
    keywordsV2Hash?: string | undefined
    listingKey?: string | undefined
    localeV1?: ModelTypes['WSLocale'] | undefined
    manualPurchase?: boolean | undefined
    messages?: ModelTypes['ModelMessageConnection'] | undefined
    moderatorStagingApprovalAt?: string | undefined
    moderatorStagingApprovalBy?: string | undefined
    moderatorStagingApprovalOutcome?: ModelTypes['StagingApprovalOutcome'] | undefined
    orderId?: string | undefined
    orderIdInvalidReason?: string | undefined
    orderIdSubmissionDate?: string | undefined
    orderIdValidationDate?: string | undefined
    orderIdValidationStatus?: ModelTypes['OrderIdValidationStatus'] | undefined
    orderStatus?: string | undefined
    payForClickLink?: ModelTypes['UserLink'] | undefined
    payForClickLinkId?: string | undefined
    paypalEmailAtSubmission?: string | undefined
    postingDeadline?: string | undefined
    postingDeadlineExtended?: boolean | undefined
    previousCardIds?: Array<string> | undefined
    priority?: ModelTypes['SourcePriority'] | undefined
    product?: ModelTypes['Product'] | undefined
    productCriticalFeedback?: string | undefined
    productId?: string | undefined
    productPositiveFeedback?: string | undefined
    prospect?: ModelTypes['CampaignSourcingProspect'] | undefined
    purchases?: ModelTypes['ModelPurchaseConnection'] | undefined
    rankPriority?: number | undefined
    reviewScreenshot?: string | undefined
    reviewScreenshotVerificationResult?: ModelTypes['ReviewScreenshotVerificationResult'] | undefined
    schedulingReleaseAt?: string | undefined
    searchPhraseKey?: string | undefined
    selectedFlow?: string | undefined
    selfieVideoApprovalDate?: string | undefined
    selfieVideoApprovalNotes?: string | undefined
    selfieVideoApprovalStatus?: ModelTypes['SelfieVideoApprovalStatus'] | undefined
    selfieVideoKey?: string | undefined
    selfieVideoRejectionReason?: string | undefined
    selfieVideoSubmissionRequired?: boolean | undefined
    selfieVideoSubmittedAt?: string | undefined
    sequenceFormSubmissionValues?: Array<ModelTypes['SequenceFormSubmissionValues']> | undefined
    shippingAddress?: string | undefined
    showEarlyReviewSurvey?: boolean | undefined
    socialContent?: ModelTypes['ModelUserCampaignSocialContentConnection'] | undefined
    statusHistory?: string | undefined
    totalBonuses?: ModelTypes['ModelUserBonusPayoutConnection'] | undefined
    updatedAt?: string | undefined
    user?: ModelTypes['User'] | undefined
    userId: string
    userStagingApprovals?: ModelTypes['ModelUserStagingApprovalConnection'] | undefined
    walmartReviewScreenShot?: string | undefined
  }
  ['UserCampaignBonusConfig']: {
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<ModelTypes['ProductSocialContent']> | undefined
    discoveryBonus?: number | undefined
    expectedBonus?: string | undefined
    hasCustomBonusConfig?: boolean | undefined
    payForImpressionsBonus?: ModelTypes['PayForImpressionsBonusConfig'] | undefined
  }
  ['UserCampaignChangelog']: {
    createdAt: string
    createdByUser?: ModelTypes['User'] | undefined
    createdByUserId?: string | undefined
    entryType: ModelTypes['UserCampaignChangelogEntryType']
    extraData?: string | undefined
    updatedAt: string
    userCampaign?: ModelTypes['UserCampaign'] | undefined
    userCampaignId: string
  }
  ['UserCampaignSnapshot']: {
    createdAt: string
    snapshot: string
    updatedAt: string
    userCampaignId: string
    userCampaignVersion: number
  }
  ['UserCampaignSocialContent']: {
    approvalFeedback?: string | undefined
    approvalStatus?: ModelTypes['ContentApprovalStatus'] | undefined
    approvedAt?: string | undefined
    approverPWUserId?: string | undefined
    approverUserId?: string | undefined
    bonusEarned?: number | undefined
    bonusOverride?: number | undefined
    caption?: string | undefined
    channel: ModelTypes['SocialChannel']
    clientFeedback?: Array<ModelTypes['SocialMediaFeedback']> | undefined
    complianceReview?: Array<ModelTypes['ContentComplianceReviewCriteria']> | undefined
    complianceReviewStatus?: ModelTypes['ContentComplianceReviewStatus'] | undefined
    complianceReviewUserId?: string | undefined
    complianceReviewedAt?: string | undefined
    contentType?: ModelTypes['SocialContentType'] | undefined
    createdAt: string
    draftEnteredAdminApprovalAt?: string | undefined
    draftEnteredBrandApprovalAt?: string | undefined
    draftFeedback?: Array<string> | undefined
    draftHistory?: Array<string> | undefined
    draftHistoryV2?: ModelTypes['ModelUserCampaignSocialContentDraftHistoryConnection'] | undefined
    draftMedia?: Array<string> | undefined
    draftPreApprovedText?: string | undefined
    draftQualityScore?: number | undefined
    draftReview?: Array<ModelTypes['ContentComplianceReviewCriteria']> | undefined
    draftStatus?: ModelTypes['DraftStatus'] | undefined
    draftSubmittedAt?: string | undefined
    draftText?: string | undefined
    earlySubmissionBonusEarnedCents?: number | undefined
    experimentId?: string | undefined
    externalUrl?: string | undefined
    groupId?: string | undefined
    hashtags?: Array<string> | undefined
    id: string
    instance?: number | undefined
    internalFeedback?: Array<ModelTypes['SocialMediaFeedback']> | undefined
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    isPurchased?: boolean | undefined
    isUnavailable?: boolean | undefined
    manualUploadType?: ModelTypes['ManualUploadType'] | undefined
    mediaId?: string | undefined
    mediaUrls?: Array<string> | undefined
    metrics?: Array<ModelTypes['SocialMetric']> | undefined
    paymentId?: string | undefined
    postedAt?: string | undefined
    qualityScore?: number | undefined
    rejectionReason?: string | undefined
    requestedSocialScript?: boolean | undefined
    requirementLevel?: ModelTypes['ContentRequirementLevel'] | undefined
    reviews?: ModelTypes['ModelUserCampaignSocialContentReviewConnection'] | undefined
    scrapeStatus?: ModelTypes['ScrapeStatus'] | undefined
    unavailableAt?: string | undefined
    updatedAt: string
    usedRequestedSocialScript?: boolean | undefined
    userCampaign?: ModelTypes['UserCampaign'] | undefined
    userCampaignId: string
    videoEndTime?: string | undefined
    videoStartTime?: string | undefined
  }
  ['UserCampaignSocialContentDraftHistory']: {
    createdAt: string
    id: string
    snapshot: string
    snapshotDate: string
    updatedAt: string
    userCampaignSocialContentId: string
  }
  ['UserCampaignSocialContentReview']: {
    createdAt: string
    reviewFeedback?: Array<ModelTypes['UserCampaignSocialContentReviewFeedback']> | undefined
    reviewOutcome?: ModelTypes['UserCampaignSocialContentReviewOutcome'] | undefined
    reviewStatus?: ModelTypes['UserCampaignSocialContentReviewStatus'] | undefined
    reviewType: ModelTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined
    reviewer?: ModelTypes['User'] | undefined
    reviewerAssigneeId?: string | undefined
    reviewerFirstName?: string | undefined
    reviewerId?: string | undefined
    reviewerLastName?: string | undefined
    updatedAt: string
    userCampaignSocialContent?: ModelTypes['UserCampaignSocialContent'] | undefined
    userCampaignSocialContentId: string
    userId: string
  }
  ['UserCampaignSocialContentReviewFeedback']: {
    atTime?: number | undefined
    createdAt?: string | undefined
    createdByFirstName?: string | undefined
    createdByLastName?: string | undefined
    createdByUserId?: string | undefined
    forAssetAtIndex?: number | undefined
    id?: string | undefined
    message?: string | undefined
    rating?: number | undefined
    updatedAt?: string | undefined
    visibility?: ModelTypes['ReviewFeedbackVisibility'] | undefined
  }
  ['UserCharacteristic']: {
    characteristic: string
    reason?: string | undefined
  }
  ['UserContentRating']: {
    instagramContentQualityRating?: ModelTypes['ContentQualityRating'] | undefined
    instagramContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    instagramCreatorContentScore?: number | undefined
    instagramCreatorContentScoreRaw?: Array<ModelTypes['CreatorContentRatingRawValue']> | undefined
    instagramCreatorSponsorshipContentScore?: number | undefined
    instagramSubmittedBy?: string | undefined
    submittedBy?: string | undefined
    tiktokContentQualityRating?: ModelTypes['ContentQualityRating'] | undefined
    tiktokContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    tiktokCreatorContentScore?: number | undefined
    tiktokCreatorContentScoreRaw?: Array<ModelTypes['CreatorContentRatingRawValue']> | undefined
    tiktokCreatorSponsorshipContentScore?: number | undefined
    tiktokSubmittedBy?: string | undefined
  }
  ['UserDeviceSnapshot']: {
    createdAt: string
    id: string
    idfv?: string | undefined
    ipAddress?: string | undefined
    isEmulator?: boolean | undefined
    snapshotAt: string
    timezone?: string | undefined
    updatedAt: string
    userId: string
  }
  ['UserFollower']: {
    createdAt: string
    follower?: ModelTypes['User'] | undefined
    followerUserId: string
    isFavorite?: ModelTypes['FavoriteFlag'] | undefined
    updatedAt: string
    user?: ModelTypes['User'] | undefined
    userId: string
  }
  ['UserLink']: {
    campaignId?: string | undefined
    clicksPerPeriod?: Array<ModelTypes['ClicksRecords']> | undefined
    createdAt?: string | undefined
    id: string
    linkId?: string | undefined
    originalLink: string
    rank?: number | undefined
    storageType?: ModelTypes['LinkStorageType'] | undefined
    uniqueClicks: number
    updatedAt?: string | undefined
    userEncodedLink: string
    userId?: string | undefined
  }
  ['UserNotificationPreference']: {
    category: string
    createdAt: string
    emailOptOut?: boolean | undefined
    pushOptOut?: boolean | undefined
    smsOptOut?: boolean | undefined
    updatedAt: string
    userId: string
  }
  ['UserPayoutRequest']: {
    createdAt: string
    failureReason?: string | undefined
    id: string
    manuallyApprovedBy?: string | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: ModelTypes['WSLocale'] | undefined
    payoutState: ModelTypes['BonusPayoutState']
    updatedAt: string
    userId: string
  }
  ['UserSearchOutput']: {
    items: Array<ModelTypes['User']>
    total?: number | undefined
  }
  ['UserStagingApproval']: {
    createdAt: string
    id: string
    reviewFeedback?: string | undefined
    reviewedAt?: string | undefined
    reviewedBy?: string | undefined
    reviewedByEmail?: string | undefined
    reviewedByName?: string | undefined
    reviewedBySource?: ModelTypes['UserStagingApprovalReviewSource'] | undefined
    updatedAt: string
    userCampaignId: string
    userStagingApprovalOutcome: ModelTypes['UserStagingApprovalOutcome']
    userStagingApprovalType: ModelTypes['UserStagingApprovalType']
  }
  ['UtmReferral']: {
    campaign?: string | undefined
    content?: string | undefined
    medium?: string | undefined
    source?: string | undefined
    term?: string | undefined
  }
  ['VerifySocialCodeResult']: {
    followers?: number | undefined
    handle: string
    isBusinessAccount?: boolean | undefined
    userId: string
    verified: boolean
  }
  ['VideoContentScan']: {
    audioDuration?: number | undefined
    audioEndAt?: number | undefined
    audioStartFrom?: number | undefined
    contentSafetyAnalysis?: ModelTypes['ContentSafetyAnalysis'] | undefined
    creationDateTime: string
    id: string
    processFinishedAt?: string | undefined
    processId?: string | undefined
    processInitiatedAt?: string | undefined
    scanStatus: ModelTypes['ContentScanStatus']
    socialMediaUrl?: string | undefined
    transcription?: string | undefined
    transcriptionConfidence?: number | undefined
    updatedDateTime: string
    url: string
    userId: string
  }
  ['Active']: Active
  ['AdminUpdateContentApprovalType']: AdminUpdateContentApprovalType
  ['AmazonReviewSubmissionStatus']: AmazonReviewSubmissionStatus
  ['ApplicationStatus']: ApplicationStatus
  ['ApprovalOutcome']: ApprovalOutcome
  ['ApprovalSort']: ApprovalSort
  ['ArchivedReason']: ArchivedReason
  ['AutoBuyDelayStrategy']: AutoBuyDelayStrategy
  ['AutoBuySkipReason']: AutoBuySkipReason
  ['AutoBuyStatus']: AutoBuyStatus
  ['BOPISValue']: BOPISValue
  ['BonusPayoutState']: BonusPayoutState
  ['BonusPayoutTypes']: BonusPayoutTypes
  ['BonusRequestTypes']: BonusRequestTypes
  ['BrandApiType']: BrandApiType
  ['BrandApiVersion']: BrandApiVersion
  ['BrandSafe']: BrandSafe
  ['CampaignBadgeType']: CampaignBadgeType
  ['CampaignGoal']: CampaignGoal
  ['CampaignProductType']: CampaignProductType
  ['CampaignReferralBonusFlag']: CampaignReferralBonusFlag
  ['CampaignSourcingSource']: CampaignSourcingSource
  ['CampaignStatus']: CampaignStatus
  ['Channel']: Channel
  ['ChatType']: ChatType
  ['CoarseCampaignStatus']: CoarseCampaignStatus
  ['ComplianceReviewCriteria']: ComplianceReviewCriteria
  ['ConnectedAccountType']: ConnectedAccountType
  ['ContentApprovalStatus']: ContentApprovalStatus
  ['ContentApprovalType']: ContentApprovalType
  ['ContentComplianceReviewStatus']: ContentComplianceReviewStatus
  ['ContentQualityRating']: ContentQualityRating
  ['ContentRatingReviewStatus']: ContentRatingReviewStatus
  ['ContentRatingSponsorshipContentInputSource']: ContentRatingSponsorshipContentInputSource
  ['ContentRequirementLevel']: ContentRequirementLevel
  ['ContentSafetyLabel']: ContentSafetyLabel
  ['ContentScanStatus']: ContentScanStatus
  ['ContentTypeRating']: ContentTypeRating
  ['ConversationChannel']: ConversationChannel
  ['ConversationInternalStatus']: ConversationInternalStatus
  ['ConversationMessageSource']: ConversationMessageSource
  ['ConversationMessageStatus']: ConversationMessageStatus
  ['ConversationStatus']: ConversationStatus
  ['CreationSourceVersion']: CreationSourceVersion
  ['CreatorPreference']: CreatorPreference
  ['Currency']: Currency
  ['Deranked_Reason']: Deranked_Reason
  ['DraftStatus']: DraftStatus
  ['EnabledFlag']: EnabledFlag
  ['FavoriteFlag']: FavoriteFlag
  ['FeatureFlagEnvironment']: FeatureFlagEnvironment
  ['FormComponentType']: FormComponentType
  ['FormFieldType']: FormFieldType
  ['FormType']: FormType
  ['FormValidationType']: FormValidationType
  ['FraudReason']: FraudReason
  ['FraudReviewStatus']: FraudReviewStatus
  ['InfluencerApprovalFlagV2']: InfluencerApprovalFlagV2
  ['InspirationalContentType']: InspirationalContentType
  ['InstagramGenderAgeDistributionGender']: InstagramGenderAgeDistributionGender
  ['ItemClassification']: ItemClassification
  ['KeywordAcceptanceResponse']: KeywordAcceptanceResponse
  ['LinkStorageType']: LinkStorageType
  ['ListingFulfillmentType']: ListingFulfillmentType
  ['ManualUploadType']: ManualUploadType
  ['ModelAttributeTypes']: ModelAttributeTypes
  ['ModelSortDirection']: ModelSortDirection
  ['NotificationStatus']: NotificationStatus
  ['OccurrenceConstraintPeriod']: OccurrenceConstraintPeriod
  ['OccurrenceConstraintType']: OccurrenceConstraintType
  ['OrderConfirmationScreenshotVerificationResult']: OrderConfirmationScreenshotVerificationResult
  ['OrderIdValidationStatus']: OrderIdValidationStatus
  ['Paused']: Paused
  ['PayForPerformanceFlag']: PayForPerformanceFlag
  ['PaymentStatus']: PaymentStatus
  ['PaymentTermsType']: PaymentTermsType
  ['Period']: Period
  ['PlatformInviteSource']: PlatformInviteSource
  ['PlatformPriority']: PlatformPriority
  ['PlatformSource']: PlatformSource
  ['PlatformStatus']: PlatformStatus
  ['PreApprovalStatus']: PreApprovalStatus
  ['ProductKeywordCreationSource']: ProductKeywordCreationSource
  ['ProductQualityLevel']: ProductQualityLevel
  ['PromoCodeType']: PromoCodeType
  ['PromoDiscountType']: PromoDiscountType
  ['PurchaseStatus']: PurchaseStatus
  ['PushChannel']: PushChannel
  ['ReferralType']: ReferralType
  ['RequiredProductLinkFlag']: RequiredProductLinkFlag
  ['ReviewFeedbackVisibility']: ReviewFeedbackVisibility
  ['ReviewScreenshotVerificationResult']: ReviewScreenshotVerificationResult
  ['RevocationType']: RevocationType
  ['ScrapeStatus']: ScrapeStatus
  ['SearchInputField']: SearchInputField
  ['SearchInputQueryScriptSource']: SearchInputQueryScriptSource
  ['SearchInputScriptType']: SearchInputScriptType
  ['SearchInputSortScriptSource']: SearchInputSortScriptSource
  ['SelfieVideoApprovalStatus']: SelfieVideoApprovalStatus
  ['ShopifyPromotionType']: ShopifyPromotionType
  ['SkuCreationSource']: SkuCreationSource
  ['SocialChannel']: SocialChannel
  ['SocialContentType']: SocialContentType
  ['SocialMediaPostContentType']: SocialMediaPostContentType
  ['SocialMediaPostGroupType']: SocialMediaPostGroupType
  ['SocialMetricName']: SocialMetricName
  ['SocialQuality']: SocialQuality
  ['SocialType']: SocialType
  ['SocialVerificationMethod']: SocialVerificationMethod
  ['SortOrder']: SortOrder
  ['SourcePriority']: SourcePriority
  ['StagingApprovalOutcome']: StagingApprovalOutcome
  ['StagingRequirementType']: StagingRequirementType
  ['StateChangeSource']: StateChangeSource
  ['StatusTier']: StatusTier
  ['StripeRegion']: StripeRegion
  ['SupportPriorityFlag']: SupportPriorityFlag
  ['TrackingStatus']: TrackingStatus
  ['USAState']: USAState
  ['UserCampaignAcceptanceStatus']: UserCampaignAcceptanceStatus
  ['UserCampaignApprovalStatus']: UserCampaignApprovalStatus
  ['UserCampaignChangelogEntryType']: UserCampaignChangelogEntryType
  ['UserCampaignSocialContentReviewOutcome']: UserCampaignSocialContentReviewOutcome
  ['UserCampaignSocialContentReviewStatus']: UserCampaignSocialContentReviewStatus
  ['UserCampaignSocialContentReviewType']: UserCampaignSocialContentReviewType
  ['UserCampaignStateChangeRequestType']: UserCampaignStateChangeRequestType
  ['UserFilterGender']: UserFilterGender
  ['UserGender']: UserGender
  ['UserStagingApprovalAction']: UserStagingApprovalAction
  ['UserStagingApprovalOutcome']: UserStagingApprovalOutcome
  ['UserStagingApprovalReviewSource']: UserStagingApprovalReviewSource
  ['UserStagingApprovalType']: UserStagingApprovalType
  ['ValidMccCodes']: ValidMccCodes
  ['WSLocale']: WSLocale
  ['AddressComponentsSearchFields']: {
    country?: ModelTypes['SearchInputString'] | undefined
    state?: ModelTypes['SearchInputString'] | undefined
  }
  ['AdminSendPushNotificationInput']: {
    body?: string | undefined
    notificationId: string
    userCampaigns?: Array<string> | undefined
    userIds?: Array<string> | undefined
  }
  ['ApplyToCampaignInput']: {
    autoApprove?: boolean | undefined
    campaignApplication?: string | undefined
    campaignId: string
    productId?: string | undefined
    userId: string
  }
  ['ApprovalFilterValueInput']: {
    filter: string
    value: string
  }
  ['ApproveUserCampaignForPurchaseInput']: {
    autoBuyApprovalDate?: string | undefined
    autoJoin?: boolean | undefined
    userCampaignId: string
  }
  ['AutoApprovalCriteriaInput']: {
    completedCampaigns?: number | undefined
  }
  ['AutoApprovalV2ConfigInput']: {
    autoApprovalCriteria?: ModelTypes['AutoApprovalCriteriaInput'] | undefined
    enabled?: boolean | undefined
  }
  ['AutoBuyScheduleInput']: {
    date: string
    desiredPurchaseCount: number
    willPurchasePercentage?: number | undefined
  }
  ['BillingContactInput']: {
    email: string
    name: string
  }
  ['CampaignBadgeInput']: {
    type: ModelTypes['CampaignBadgeType']
    value: string
  }
  ['ChannelConfigurationInput']: {
    attributes?: Array<ModelTypes['KeyAttributeInput'] | undefined> | undefined
    enabled?: boolean | undefined
    fromAddress?: string | undefined
    templateId: string
  }
  ['ClicksRecordsInput']: {
    clicks: number
    from: string
    to: string
  }
  ['CollisionDensityValueInput']: {
    density: number
    skuId: string
  }
  ['ContentApprovalConfigurationInput']: {
    contentApprovalAssignee?: string | undefined
    contentApprovalType: ModelTypes['ContentApprovalType']
  }
  ['ContentComplianceReviewCriteriaInput']: {
    compliant?: boolean | undefined
    criteria: ModelTypes['ComplianceReviewCriteria']
    requirement?: string | undefined
  }
  ['ContentGuidanceInput']: {
    instance: number
    text: string
  }
  ['ContentLabelResultInput']: {
    confidence?: number | undefined
    label: ModelTypes['ContentSafetyLabel']
    severity?: number | undefined
  }
  ['ContentRatingSponsorshipContentInput']: {
    inputSource?: ModelTypes['ContentRatingSponsorshipContentInputSource'] | undefined
    isCompliant?: boolean | undefined
    isInappropriate?: boolean | undefined
    link: string
    qualityRating?: number | undefined
  }
  ['ContentSafetyAnalysisInput']: {
    isBrandSafe?: ModelTypes['BrandSafe'] | undefined
    summary?: Array<ModelTypes['ContentSafetyLabelSummaryInput'] | undefined> | undefined
    textInstances?: Array<ModelTypes['ContentSafetyTextInstanceInput'] | undefined> | undefined
  }
  ['ContentSafetyLabelSummaryInput']: {
    confidence?: number | undefined
    label?: ModelTypes['ContentSafetyLabel'] | undefined
    severity?: ModelTypes['ContentSafetySeverityInput'] | undefined
  }
  ['ContentSafetySeverityInput']: {
    high?: number | undefined
    low?: number | undefined
    medium?: number | undefined
  }
  ['ContentSafetyTextInstanceInput']: {
    labels?: Array<ModelTypes['ContentLabelResultInput'] | undefined> | undefined
    text: string
    timestampEnd?: number | undefined
    timestampStart?: number | undefined
  }
  ['CreateAutoBuyDistributionInput']: {
    createdAt?: string | undefined
    description?: string | undefined
    id?: string | undefined
    name: string
    schedule: Array<number>
    updatedAt?: string | undefined
  }
  ['CreateAutoBuyFlowConfigurationInput']: {
    dailyPurchaseMax?: number | undefined
    delayStrategy?: ModelTypes['AutoBuyDelayStrategy'] | undefined
    flowId: string
    insufficientCreators?: string | undefined
    latestSkipReason?: ModelTypes['AutoBuySkipReason'] | undefined
    pauseReason?: ModelTypes['AutoBuySkipReason'] | undefined
    schedule?: Array<ModelTypes['AutoBuyScheduleInput']> | undefined
    startDate: string
    status: ModelTypes['AutoBuyStatus']
  }
  ['CreateAutoBuyFlowReleaseScheduleInput']: {
    actualPurchases?: number | undefined
    actualVerifiedPurchases?: number | undefined
    adjustedDesiredPurchases?: number | undefined
    autoApprovalSeats?: number | undefined
    date: string
    desiredPurchases?: number | undefined
    flowId: string
    manualPurchases?: number | undefined
    manualReleases?: number | undefined
    projectedReleaseCount?: number | undefined
    releaseCount?: number | undefined
    released?: boolean | undefined
    skippedReason?: ModelTypes['AutoBuySkipReason'] | undefined
  }
  ['CreateAutoBuyReleaseInput']: {
    date: string
    productId: string
    releaseDateTime: string
    skuId?: string | undefined
    userCampaignId: string
  }
  ['CreateBrandApiInput']: {
    accessToken?: string | undefined
    api: ModelTypes['BrandApiType']
    apiVersion?: ModelTypes['BrandApiVersion'] | undefined
    brandId: string
    enabled?: boolean | undefined
    locale?: ModelTypes['WSLocale'] | undefined
    refreshToken?: string | undefined
    shop?: string | undefined
    shopId?: string | undefined
  }
  ['CreateBrandInput']: {
    active?: string | undefined
    amazonBrandName?: string | undefined
    brandDetailsSubmitted?: boolean | undefined
    brandInstaHandle?: string | undefined
    brandLogoImageKey?: string | undefined
    brandStoreConfirmationImage?: string | undefined
    brandStoreConfirmationMobileImage?: string | undefined
    brandStoreScrapeUrl?: string | undefined
    brandTikTokHandle?: string | undefined
    companyId?: string | undefined
    contactEmails?: Array<string | undefined> | undefined
    defaultCreatorPreference?: ModelTypes['CreatorPreference'] | undefined
    defaultCreatorPreferenceDetails?: string | undefined
    defaultHashtags?: Array<string> | undefined
    defaultMarketingBriefKey?: string | undefined
    defaultPostingGuidelines?: Array<string> | undefined
    defaultPostingInspirationLinks?: Array<string> | undefined
    emailsSent?: Array<string | undefined> | undefined
    fullScrapedAt?: string | undefined
    id?: string | undefined
    isBrandStoreScrapeEnabled?: boolean | undefined
    isSeller?: string | undefined
    isVendor?: string | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    lastScrapedAt?: string | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    paymentBillingContact?: ModelTypes['BillingContactInput'] | undefined
    platform?: ModelTypes['PlatformSource'] | undefined
    postingGuidanceDocument?: string | undefined
    region?: ModelTypes['WSLocale'] | undefined
    socialMediaExampleUrls?: Array<string> | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    walmartBrandName?: string | undefined
    walmartLastScrapedAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['CreateCampaignClicksInput']: {
    campaignId: string
    clicks: number
    date: string
  }
  ['CreateCampaignInput']: {
    aboutSection?: string | undefined
    active: string
    actualStartDate?: string | undefined
    amountPaidByClient?: number | undefined
    anticipatedStartDate: string
    applicationForm?: string | undefined
    applicationProductLinkId?: string | undefined
    applicationStatus?: ModelTypes['ApplicationStatus'] | undefined
    attributionRefUrlSuffix?: string | undefined
    authorizedUnits?: number | undefined
    badges?: Array<ModelTypes['CampaignBadgeInput'] | undefined> | undefined
    brandId: string
    brandProductShort?: string | undefined
    brandStoreLink?: string | undefined
    budgetSpent?: number | undefined
    campaignProductType?: ModelTypes['CampaignProductType'] | undefined
    campaignReferralBonusAmount?: number | undefined
    campaignReferralBonusEnabled?: ModelTypes['CampaignReferralBonusFlag'] | undefined
    campaignStateMachine?: string | undefined
    closeoutReleaseFailureReason?: string | undefined
    closeoutReleaseRate?: ModelTypes['RateInput'] | undefined
    collisionSkuDensity?: Array<ModelTypes['CollisionDensityValueInput']> | undefined
    commissionFlatRate?: number | undefined
    commissionPercentageRate?: number | undefined
    committedClicks?: number | undefined
    committedPlacementWinsCount?: number | undefined
    committedReviewsCount?: number | undefined
    contentApprovalRequired?: boolean | undefined
    contentCompleteDeadline?: string | undefined
    createdAt?: string | undefined
    creationSourceVersion?: ModelTypes['CreationSourceVersion'] | undefined
    earlyReviewEnabled?: boolean | undefined
    enableContentComplianceReview?: boolean | undefined
    endDate?: string | undefined
    excludeGender?: ModelTypes['UserFilterGender'] | undefined
    expectedBonus?: string | undefined
    followersCountCeiling?: number | undefined
    followersCountFloor?: number | undefined
    goal?: ModelTypes['CampaignGoal'] | undefined
    id?: string | undefined
    influencerApprovalEnabled?: ModelTypes['InfluencerApprovalFlagV2'] | undefined
    lifestyleCommittedContentCount?: number | undefined
    localeV1?: ModelTypes['WSLocale'] | undefined
    marketingFee?: number | undefined
    marketingFormFilled?: boolean | undefined
    newCreatorsOnly?: boolean | undefined
    payForClicksEnabled?: ModelTypes['EnabledFlag'] | undefined
    payForClicksGeneratedPerPeriod?: Array<ModelTypes['ClicksRecordsInput']> | undefined
    payForClicksMinClicks?: number | undefined
    payForClicksOriginalLink?: string | undefined
    payForClicksTotalClicksGenerated?: number | undefined
    payForPerformanceEnabled?: ModelTypes['PayForPerformanceFlag'] | undefined
    postingGuidance?: string | undefined
    preApplicationProductImage?: string | undefined
    preApplicationProductPriceRange?: string | undefined
    preApplicationUgcProductImage?: string | undefined
    preLaunchDate?: string | undefined
    premiumLifestyleCommittedContentCount?: number | undefined
    requiredProductLinksEnabled?: ModelTypes['RequiredProductLinkFlag'] | undefined
    reviewVotingTarget?: string | undefined
    sample?: boolean | undefined
    shellCampaignBase?: string | undefined
    skuId: string
    skuPriceOverride?: number | undefined
    socialCommittedContentCount?: number | undefined
    sourcingPriority?: number | undefined
    sponsorshipInstructions?: string | undefined
    title: string
    totalEarlyReviewsAllowed?: number | undefined
    totalProductCost?: number | undefined
    userAutoApproved?: boolean | undefined
    visibleToUser: string
  }
  ['CreateCampaignInviteInput']: {
    campaignId: string
    createdAt?: string | undefined
    id?: string | undefined
    notificationStatus: ModelTypes['NotificationStatus']
    priority: number
    updatedAt?: string | undefined
    userId: string
  }
  ['CreateCampaignSourcingProspectInput']: {
    bonusConfig?: ModelTypes['UserCampaignBonusConfigInput'] | undefined
    campaignId: string
    createdAt?: string | undefined
    createdById?: string | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    internalNotesId?: string | undefined
    name?: string | undefined
    platformInviteSource: ModelTypes['PlatformInviteSource']
    platformStatus?: ModelTypes['PlatformStatus'] | undefined
    preApprovalStatus?: ModelTypes['PreApprovalStatus'] | undefined
    preApprovalStatusById?: string | undefined
    priority?: ModelTypes['SourcePriority'] | undefined
    productId?: string | undefined
    sourcingSource: ModelTypes['CampaignSourcingSource']
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    userCampaignId?: string | undefined
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
  }
  ['CreateChatConversationV2Input']: {
    active: ModelTypes['Active']
    adminLastReadMessageIndex?: number | undefined
    adminUnreadCount: number
    campaignId?: string | undefined
    chatType: ModelTypes['ChatType']
    creatorLastReadMessageIndex?: number | undefined
    creatorUnreadCount: number
    lastMessage?: string | undefined
    lastMessageDate?: string | undefined
    lastMessageId?: string | undefined
    lastMessageSource?: ModelTypes['ConversationMessageSource'] | undefined
    resourceId: string
    status: ModelTypes['ConversationInternalStatus']
    statusUpdatedAt?: string | undefined
    statusUpdatedByFirstName?: string | undefined
    statusUpdatedById?: string | undefined
    statusUpdatedByLastName?: string | undefined
    twilioConversationId: string
    userCampaignId?: string | undefined
    userEmail?: string | undefined
    userFirstName?: string | undefined
    userId: string
    userLastName?: string | undefined
  }
  ['CreateCommunicationNotificationInput']: {
    address: string
    attributes?: Array<ModelTypes['KeyAttributeInput']> | undefined
    campaignId?: string | undefined
    channel: ModelTypes['Channel']
    expectedSentAt: string
    experimentTreatment?: string | undefined
    id?: string | undefined
    notificationId: string
    sentAt: string
    status?: string | undefined
    statusMessage?: string | undefined
    templateId?: string | undefined
    traceId: string
    userCampaignId?: string | undefined
    userId: string
  }
  ['CreateCompanyInput']: {
    agencyId?: string | undefined
    id?: string | undefined
    logo?: string | undefined
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: ModelTypes['PaymentTermsType'] | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['CreateConnectedAccountInput']: {
    accountId: string
    accountType: ModelTypes['ConnectedAccountType']
    createdAt?: string | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['CreateConversationInput']: {
    contactOwnerId?: string | undefined
    lastContactAt?: string | undefined
    status?: ModelTypes['ConversationStatus'] | undefined
    statusChangedAt?: string | undefined
    userId: string
  }
  ['CreateConversationMessageInput']: {
    channel: ModelTypes['ConversationChannel']
    id?: string | undefined
    messageContent: string
    messageId?: string | undefined
    messageMediaKeys?: Array<string> | undefined
    replyFromId?: string | undefined
    sendAt?: string | undefined
    sendAttempts?: number | undefined
    sentAt?: string | undefined
    status: ModelTypes['ConversationMessageStatus']
    userId: string
  }
  ['CreateEtailerSkuMetricsInput']: {
    date: string
    pageViews?: number | undefined
    sku: string
    skuId: string
    totalSalesAmount?: number | undefined
    unitsSold?: number | undefined
  }
  ['CreateFormSequenceConfigurationInput']: {
    active: ModelTypes['Active']
    id?: string | undefined
    sequenceName: string
    sequences?: Array<ModelTypes['FormSequenceInput']> | undefined
  }
  ['CreateInstagramStoryScreenInput']: {
    exits?: number | undefined
    expirationUnixTime: number
    externalId?: string | undefined
    id?: string | undefined
    impressions?: number | undefined
    instagramUserId: string
    mediaId: string
    postedAt?: string | undefined
    postingDate: string
    reach?: number | undefined
    replies?: number | undefined
    s3Bucket?: string | undefined
    tapsBack?: number | undefined
    tapsForward?: number | undefined
    userCampaignId?: string | undefined
  }
  ['CreateMessageInput']: {
    body: string
    createdAt?: string | undefined
    id?: string | undefined
    updatedAt?: string | undefined
    userCampaignId?: string | undefined
    userHasSeen?: boolean | undefined
    userId: string
  }
  ['CreateNoteInput']: {
    createdAt?: string | undefined
    id?: string | undefined
    note: string
    parentId: string
    userId: string
  }
  ['CreateNotificationConfigurationInput']: {
    category: string
    description?: string | undefined
    email?: ModelTypes['ChannelConfigurationInput'] | undefined
    enabled?: boolean | undefined
    id?: string | undefined
    inAppChatChannel?: ModelTypes['MessagingChannelConfigurationInput'] | undefined
    name: string
    occurrenceConstraint?: ModelTypes['OccurrenceConstraintInput'] | undefined
    push?: ModelTypes['PushChannelConfigurationInput'] | undefined
    sms?: ModelTypes['ChannelConfigurationInput'] | undefined
  }
  ['CreateProductBonusConfigSnapshotInput']: {
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<ModelTypes['ProductSocialContentInput']> | undefined
    discoveryBonus?: number | undefined
    payForImpressionsBonus?: ModelTypes['PayForImpressionsBonusConfigInput'] | undefined
    productId: string
    snapshotAt: string
  }
  ['CreateProductFromUrlInput']: {
    brandId: string
    creationSource: ModelTypes['SkuCreationSource']
    productUrl: string
  }
  ['CreateProductInput']: {
    accountRequired?: boolean | undefined
    active?: string | undefined
    approvalFilters?: Array<ModelTypes['ApprovalFilterValueInput']> | undefined
    autoApprovalSeatsAvailable?: number | undefined
    autoApprovalStatusTiers?: Array<ModelTypes['StatusTier']> | undefined
    autoApprovalV2Config?: ModelTypes['AutoApprovalV2ConfigInput'] | undefined
    automaticReviewRelease?: boolean | undefined
    baseBonus?: number | undefined
    brandAdvocateCount?: number | undefined
    brandTalkingPoints?: Array<string> | undefined
    buyOnlinePickupInStore?: ModelTypes['BOPISValue'] | undefined
    campaignId?: string | undefined
    campaignStateMachine?: string | undefined
    closeoutFormBonus?: number | undefined
    contentApprovalConfiguration?: Array<ModelTypes['ContentApprovalConfigurationInput']> | undefined
    contentApprovalLeadDays?: number | undefined
    contentRequirement?: Array<ModelTypes['ProductSocialContentInput']> | undefined
    creatorCount?: number | undefined
    creatorPreference?: ModelTypes['CreatorPreference'] | undefined
    creatorPreferenceDetails?: string | undefined
    customFtcHashtags?: Array<string> | undefined
    customHashtags?: Array<string> | undefined
    directPurchaseLink?: string | undefined
    engagementRateInstagramRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    engagementRateTiktokRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    followersInstagramRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    followersTiktokRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    hideBrandContentApprovals?: boolean | undefined
    id?: string | undefined
    impressionsInstagramRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    impressionsTiktokRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    instaHandle?: string | undefined
    internalCreatorsStagingGuidance?: string | undefined
    internalDescription?: string | undefined
    internalName?: string | undefined
    internalNotes?: string | undefined
    isDirectListingPurchase?: boolean | undefined
    isQualityRequirementsEnabled?: boolean | undefined
    listingGuidance?: string | undefined
    maxDiscoveryBonus?: number | undefined
    minimumCreatorQualityScore?: number | undefined
    nonRetailReviewLink?: string | undefined
    nonRetailReviewWebsiteName?: string | undefined
    payForClicksEnabled?: ModelTypes['EnabledFlag'] | undefined
    payForClicksMinClicks?: number | undefined
    payForImpressionsBonus?: ModelTypes['PayForImpressionsBonusConfigInput'] | undefined
    payForPerformanceBonus?: ModelTypes['StatusTierBonusConfigInput'] | undefined
    payForPostingContentEarly?: ModelTypes['PayForPostingContentEarlyConfigInput'] | undefined
    postingDeadlineDaysRelativeToApproval?: number | undefined
    productLinkId?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    promoCode?: string | undefined
    promoCodeGuidance?: string | undefined
    promoCodeType?: ModelTypes['PromoCodeType'] | undefined
    promoDiscountAmount?: number | undefined
    promoDiscountType?: ModelTypes['PromoDiscountType'] | undefined
    promoEndDate?: string | undefined
    promoLink?: string | undefined
    promoStartDate?: string | undefined
    promotionLinkId?: string | undefined
    purchaseByPromoCode?: boolean | undefined
    purchaseDiscountCode?: string | undefined
    purchaseDiscountCodeDetails?: string | undefined
    purchaseDiscountCodePercentage?: string | undefined
    qualityLevel?: ModelTypes['ProductQualityLevel'] | undefined
    requestEarlyProductFeedback?: boolean | undefined
    requestProductFeedback?: boolean | undefined
    requiredProductLinkEnabled?: ModelTypes['RequiredProductLinkFlag'] | undefined
    sequenceId?: string | undefined
    shopifyPriceRule?: string | undefined
    shopifyPromotionId?: string | undefined
    shopifyPromotionType?: ModelTypes['ShopifyPromotionType'] | undefined
    skuId: string
    socialMediaExampleUrls?: Array<string> | undefined
    stagingApprovalConfiguration?: Array<ModelTypes['StagingApprovalConfigurationInput']> | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined
    stagingRequirementType?: ModelTypes['StagingRequirementType'] | undefined
    stagingSocialQualityRequirement?: ModelTypes['SocialQuality'] | undefined
    tikTokHandle?: string | undefined
    unitsPurchased?: number | undefined
    validateProductFoundVariations?: Array<string> | undefined
    visibleAt?: string | undefined
    visibleEndsAt?: string | undefined
  }
  ['CreateProductKeywordsInput']: {
    adminPriority?: number | undefined
    brandPriority?: number | undefined
    brandResponse?: ModelTypes['KeywordAcceptanceResponse'] | undefined
    campaignId: string
    creationSource?: ModelTypes['ProductKeywordCreationSource'] | undefined
    endDate?: string | undefined
    heliumAccount?: string | undefined
    heliumAsin?: string | undefined
    heliumLink?: string | undefined
    id?: string | undefined
    keyword: string
    monthlySearchVolume?: number | undefined
    rank?: number | undefined
    rankingHistory?: Array<ModelTypes['RankPointInput']> | undefined
    rankingLastSkippedAt?: string | undefined
    rankingLastSkippedByUserId?: string | undefined
    rankingLastUpdatedAt?: string | undefined
    rankingLastUpdatedByUserId?: string | undefined
    recommended?: boolean | undefined
    skuId?: string | undefined
    startDate?: string | undefined
    usedForCampaign?: boolean | undefined
    visible?: boolean | undefined
    weight?: number | undefined
  }
  ['CreateProductRatingSnapshotInput']: {
    numFiveStar?: number | undefined
    numFourStar?: number | undefined
    numOneStar?: number | undefined
    numRatings?: number | undefined
    numReviews?: number | undefined
    numThreeStar?: number | undefined
    numTwoStar?: number | undefined
    rating?: number | undefined
    sku: string
    skuId: string
    snapshotAt: string
  }
  ['CreatePurchaseInput']: {
    amount: number
    cardId?: string | undefined
    currency: string
    id?: string | undefined
    isFraud?: boolean | undefined
    keywordAtPurchase?: string | undefined
    purchasedAt: string
    shippedAt?: string | undefined
    status: ModelTypes['PurchaseStatus']
    userCampaignId: string
  }
  ['CreateReferralInput']: {
    bonusPayoutId?: string | undefined
    campaignId?: string | undefined
    id?: string | undefined
    referralType?: ModelTypes['ReferralType'] | undefined
    referredUserId: string
    referrerUserId: string
  }
  ['CreateReviewInput']: {
    attributedUserId?: string | undefined
    author?: string | undefined
    authorBadge?: string | undefined
    badge?: string | undefined
    commentCount?: number | undefined
    helpfulReactions?: number | undefined
    id?: string | undefined
    negativeReactions?: number | undefined
    photos?: Array<string> | undefined
    productId: string
    productVariant?: string | undefined
    rating?: number | undefined
    removed?: boolean | undefined
    reviewedAt: string
    reviewedSku?: string | undefined
    reviewerId?: string | undefined
    sku?: string | undefined
    skuId: string
    store?: string | undefined
    text?: string | undefined
    title?: string | undefined
    userCampaignId?: string | undefined
    videos?: Array<string> | undefined
  }
  ['CreateSkuInput']: {
    active?: string | undefined
    approxRetailPrice?: string | undefined
    approxShippingCost?: number | undefined
    attributes?: Array<ModelTypes['SkuAttributeInput']> | undefined
    availabilityDate?: string | undefined
    availabilityNotified?: boolean | undefined
    availabilityNotifiedAt?: string | undefined
    brandId?: string | undefined
    brandListingKey?: string | undefined
    brandStoreId?: string | undefined
    bundle?: string | undefined
    createdByUserId?: string | undefined
    creationSource?: ModelTypes['SkuCreationSource'] | undefined
    creationSourceUrl?: string | undefined
    currency?: ModelTypes['Currency'] | undefined
    currentBuyBoxPrice?: number | undefined
    currentBuyBoxPriceCents?: number | undefined
    estimatedAvailabilityDate?: string | undefined
    estimatedDeliveryDays?: number | undefined
    estimatedMonthlyUnitsSold?: number | undefined
    estimatedWeeklyUnitsSold?: number | undefined
    exactListingTitle?: string | undefined
    exactRetailPrice?: string | undefined
    fullReviewScrapeComplete?: boolean | undefined
    id?: string | undefined
    inventory?: number | undefined
    iosAppProviderId?: string | undefined
    isAplusPage?: string | undefined
    isPrime?: string | undefined
    isRecommendationsHidden?: boolean | undefined
    itemClassification?: ModelTypes['ItemClassification'] | undefined
    lastAutoFilledAt?: string | undefined
    lastKnownBuyBoxPrice?: number | undefined
    lastKnownBuyBoxPriceCents?: number | undefined
    listingBrand?: string | undefined
    listingCategory?: Array<string> | undefined
    listingDescription?: string | undefined
    listingFulfillmentType?: ModelTypes['ListingFulfillmentType'] | undefined
    listingGuidance?: string | undefined
    listingLink?: string | undefined
    mccValue?: ModelTypes['ValidMccCodes'] | undefined
    minimumOrderQuantity?: number | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    nonSponsoredListingKey?: string | undefined
    parentSkuId?: string | undefined
    priceCents?: number | undefined
    product?: string | undefined
    productImageKey?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    productSearchImageKey?: string | undefined
    releaseDate?: string | undefined
    skuId?: string | undefined
    sponsoredListingKey?: string | undefined
    storeName?: string | undefined
    targetReviewRate?: ModelTypes['RateInput'] | undefined
    targetReviewRateInvalid?: string | undefined
  }
  ['CreateSkuSnapshotInput']: {
    inventory?: number | undefined
    marketplaceSku: string
    price?: number | undefined
    skuId: string
    snapshotDate: string
  }
  ['CreateTwilioConversationInput']: {
    chatType: ModelTypes['ChatType']
    resourceId: string
  }
  ['CreateUserBonusPayoutInput']: {
    adminPaymentApproved?: boolean | undefined
    bonusType: ModelTypes['BonusPayoutTypes']
    createdAt?: string | undefined
    failureReason?: string | undefined
    id?: string | undefined
    manuallyCreatedBy?: string | undefined
    payoutAmount?: number | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: ModelTypes['WSLocale'] | undefined
    payoutState?: ModelTypes['BonusPayoutState'] | undefined
    quantity?: number | undefined
    referenceId?: string | undefined
    releaseAt?: string | undefined
    revocationType?: ModelTypes['RevocationType'] | undefined
    revokedAt?: string | undefined
    userCampaignId?: string | undefined
    userId: string
  }
  ['CreateUserCampaignChangelogInput']: {
    createdAt?: string | undefined
    createdByUserId?: string | undefined
    entryType: ModelTypes['UserCampaignChangelogEntryType']
    extraData?: string | undefined
    userCampaignId: string
  }
  ['CreateUserCampaignInput']: {
    accountScreenShot?: string | undefined
    amznReviewLink?: string | undefined
    amznReviewSubmissionStatus?: ModelTypes['AmazonReviewSubmissionStatus'] | undefined
    applicationRejectedByUserId?: string | undefined
    approvalOutcome?: ModelTypes['ApprovalOutcome'] | undefined
    approvalStatus?: ModelTypes['UserCampaignApprovalStatus'] | undefined
    archivedFeedback?: string | undefined
    archivedReason?: ModelTypes['ArchivedReason'] | undefined
    autoBuyApprovalDate?: string | undefined
    autoBuyRevokeDate?: string | undefined
    bestBuyReviewScreenShot?: string | undefined
    bonusConfig?: ModelTypes['UserCampaignBonusConfigInput'] | undefined
    campaignAcceptanceStatus?: ModelTypes['UserCampaignAcceptanceStatus'] | undefined
    campaignApplication?: string | undefined
    campaignId: string
    campaignStatus?: ModelTypes['CampaignStatus'] | undefined
    campaignStatusOnArchive?: string | undefined
    cardId?: string | undefined
    cardRegion?: ModelTypes['StripeRegion'] | undefined
    closeoutSurvey?: string | undefined
    coarseCampaignStatus?: ModelTypes['CoarseCampaignStatus'] | undefined
    contentApprovalLeadDays?: number | undefined
    createdAt?: string | undefined
    discoverySurvey?: string | undefined
    id?: string | undefined
    influencerApprovalInviteFeedback?: string | undefined
    influencerApprovalRejectFeedback?: string | undefined
    invoiceKey?: string | undefined
    isAutoApproved?: boolean | undefined
    keywordAtPurchase?: string | undefined
    keywordId?: string | undefined
    keywordsV2Hash?: string | undefined
    listingKey?: string | undefined
    localeV1?: ModelTypes['WSLocale'] | undefined
    manualPurchase?: boolean | undefined
    moderatorStagingApprovalAt?: string | undefined
    moderatorStagingApprovalBy?: string | undefined
    moderatorStagingApprovalOutcome?: ModelTypes['StagingApprovalOutcome'] | undefined
    orderId?: string | undefined
    orderIdInvalidReason?: string | undefined
    orderIdSubmissionDate?: string | undefined
    orderIdValidationDate?: string | undefined
    orderIdValidationStatus?: ModelTypes['OrderIdValidationStatus'] | undefined
    orderStatus?: string | undefined
    payForClickLinkId?: string | undefined
    paypalEmailAtSubmission?: string | undefined
    postingDeadline?: string | undefined
    postingDeadlineExtended?: boolean | undefined
    previousCardIds?: Array<string> | undefined
    priority?: ModelTypes['SourcePriority'] | undefined
    productCriticalFeedback?: string | undefined
    productId?: string | undefined
    productPositiveFeedback?: string | undefined
    rankPriority?: number | undefined
    reviewScreenshot?: string | undefined
    reviewScreenshotVerificationResult?: ModelTypes['ReviewScreenshotVerificationResult'] | undefined
    schedulingReleaseAt?: string | undefined
    searchPhraseKey?: string | undefined
    selectedFlow?: string | undefined
    selfieVideoApprovalDate?: string | undefined
    selfieVideoApprovalNotes?: string | undefined
    selfieVideoApprovalStatus?: ModelTypes['SelfieVideoApprovalStatus'] | undefined
    selfieVideoKey?: string | undefined
    selfieVideoRejectionReason?: string | undefined
    selfieVideoSubmissionRequired?: boolean | undefined
    selfieVideoSubmittedAt?: string | undefined
    sequenceFormSubmissionValues?: Array<ModelTypes['SequenceFormSubmissionValuesInput']> | undefined
    shippingAddress?: string | undefined
    showEarlyReviewSurvey?: boolean | undefined
    statusHistory?: string | undefined
    updatedAt?: string | undefined
    userId: string
    walmartReviewScreenShot?: string | undefined
  }
  ['CreateUserCampaignSnapshotInput']: {
    snapshot: string
    userCampaignId: string
    userCampaignVersion: number
  }
  ['CreateUserCampaignSocialContentDraftHistoryInput']: {
    id?: string | undefined
    snapshot: string
    snapshotDate: string
    userCampaignSocialContentId: string
  }
  ['CreateUserCampaignSocialContentInput']: {
    approvalFeedback?: string | undefined
    approvalStatus?: ModelTypes['ContentApprovalStatus'] | undefined
    approvedAt?: string | undefined
    approverPWUserId?: string | undefined
    approverUserId?: string | undefined
    bonusEarned?: number | undefined
    bonusOverride?: number | undefined
    caption?: string | undefined
    channel: ModelTypes['SocialChannel']
    clientFeedback?: Array<ModelTypes['SocialMediaFeedbackInput']> | undefined
    complianceReview?: Array<ModelTypes['ContentComplianceReviewCriteriaInput']> | undefined
    complianceReviewStatus?: ModelTypes['ContentComplianceReviewStatus'] | undefined
    complianceReviewUserId?: string | undefined
    complianceReviewedAt?: string | undefined
    contentType?: ModelTypes['SocialContentType'] | undefined
    draftEnteredAdminApprovalAt?: string | undefined
    draftEnteredBrandApprovalAt?: string | undefined
    draftFeedback?: Array<string> | undefined
    draftHistory?: Array<string> | undefined
    draftMedia?: Array<string> | undefined
    draftPreApprovedText?: string | undefined
    draftQualityScore?: number | undefined
    draftReview?: Array<ModelTypes['ContentComplianceReviewCriteriaInput']> | undefined
    draftStatus?: ModelTypes['DraftStatus'] | undefined
    draftSubmittedAt?: string | undefined
    draftText?: string | undefined
    earlySubmissionBonusEarnedCents?: number | undefined
    experimentId?: string | undefined
    externalUrl?: string | undefined
    groupId?: string | undefined
    hashtags?: Array<string> | undefined
    id?: string | undefined
    instance?: number | undefined
    internalFeedback?: Array<ModelTypes['SocialMediaFeedbackInput']> | undefined
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    isPurchased?: boolean | undefined
    isUnavailable?: boolean | undefined
    manualUploadType?: ModelTypes['ManualUploadType'] | undefined
    mediaId?: string | undefined
    mediaUrls?: Array<string> | undefined
    metrics?: Array<ModelTypes['SocialMetricInput']> | undefined
    paymentId?: string | undefined
    postedAt?: string | undefined
    qualityScore?: number | undefined
    rejectionReason?: string | undefined
    requestedSocialScript?: boolean | undefined
    requirementLevel?: ModelTypes['ContentRequirementLevel'] | undefined
    scrapeStatus?: ModelTypes['ScrapeStatus'] | undefined
    unavailableAt?: string | undefined
    usedRequestedSocialScript?: boolean | undefined
    userCampaignId: string
    videoEndTime?: string | undefined
    videoStartTime?: string | undefined
  }
  ['CreateUserCampaignSocialContentReviewInput']: {
    createdAt?: string | undefined
    reviewFeedback?: Array<ModelTypes['UserCampaignSocialContentReviewFeedbackInput']> | undefined
    reviewOutcome?: ModelTypes['UserCampaignSocialContentReviewOutcome'] | undefined
    reviewStatus?: ModelTypes['UserCampaignSocialContentReviewStatus'] | undefined
    reviewType: ModelTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined
    reviewerAssigneeId?: string | undefined
    reviewerFirstName?: string | undefined
    reviewerId?: string | undefined
    reviewerLastName?: string | undefined
    userCampaignSocialContentId: string
    userId: string
  }
  ['CreateUserDeviceSnapshotInput']: {
    id?: string | undefined
    idfv?: string | undefined
    ipAddress?: string | undefined
    isEmulator?: boolean | undefined
    snapshotAt: string
    timezone?: string | undefined
    userId: string
  }
  ['CreateUserFollowerInput']: {
    createdAt?: string | undefined
    followerUserId: string
    isFavorite?: ModelTypes['FavoriteFlag'] | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['CreateUserInput']: {
    active?: ModelTypes['Active'] | undefined
    addressComponents?: string | undefined
    amznLastChecked?: string | undefined
    amznLink?: string | undefined
    amznPrime?: string | undefined
    amznProfileId?: string | undefined
    amznReviewerId?: string | undefined
    amznVerified?: string | undefined
    attributedReviewCount?: number | undefined
    blogWebsite?: string | undefined
    brandContentRatingAvg?: number | undefined
    campaignAcceptanceRate?: number | undefined
    campaignApprovalRate?: number | undefined
    campaignCompletionRate?: number | undefined
    campaignReviewRate?: number | undefined
    cardProfileUpdates?: string | undefined
    cardholderId?: string | undefined
    cardholderRegion?: ModelTypes['StripeRegion'] | undefined
    characteristics?: Array<ModelTypes['UserCharacteristicInput']> | undefined
    characteristicsUpdatedAt?: string | undefined
    claimedReviewCount?: number | undefined
    collisionSkuSet?: Array<string> | undefined
    collisionSkuSetCalculatedAt?: string | undefined
    completedLifestyleCampaignCount?: number | undefined
    completedLiftCampaignCount?: number | undefined
    completedSocialCampaignCount?: number | undefined
    contentRating?: ModelTypes['UserContentRatingInput'] | undefined
    contentRatingCompletedAt?: string | undefined
    contentRatingCompletionTimeMillis?: number | undefined
    contentRatingInstagramCompletionTimeMillis?: number | undefined
    contentRatingInstagramSponsorshipContent?: Array<ModelTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentRatingQualityScore?: number | undefined
    contentRatingReviewStatus?: ModelTypes['ContentRatingReviewStatus'] | undefined
    contentRatingSponsorshipContent?: Array<ModelTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentRatingSubmittedBy?: string | undefined
    contentRatingTiktokCompletionTimeMillis?: number | undefined
    contentRatingTiktokSponsorshipContent?: Array<ModelTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentTypes?: Array<string> | undefined
    createdAt?: string | undefined
    date_of_birth?: string | undefined
    deactivationDate?: string | undefined
    deactivationReason?: string | undefined
    declineToReviewCount?: number | undefined
    deranked?: ModelTypes['Deranked_Reason'] | undefined
    email?: string | undefined
    emailOptOut?: boolean | undefined
    facebookAccessToken?: string | undefined
    facebookAccessTokenExpiry?: string | undefined
    facebookPageId?: string | undefined
    facebookPageIsSubscribed?: boolean | undefined
    facebookPermissionScopes?: Array<string | undefined> | undefined
    facebookUserId?: string | undefined
    firstName?: string | undefined
    followsWesponsored?: string | undefined
    fraudReason?: ModelTypes['FraudReason'] | undefined
    fraudReviewAt?: string | undefined
    fraudReviewStatus?: ModelTypes['FraudReviewStatus'] | undefined
    gender?: string | undefined
    hasTrueEngagement?: boolean | undefined
    id?: string | undefined
    idfaId?: string | undefined
    incompleteCampaignCount?: number | undefined
    instagramBio?: string | undefined
    instagramBusinessCategoryName?: string | undefined
    instagramCategoryName?: string | undefined
    instagramChecked?: string | undefined
    instagramContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    instagramExternalLink?: string | undefined
    instagramFollowerCountryDistribution?: Array<ModelTypes['InstagramCountryDistributionItemInput']> | undefined
    instagramFollowerGenderAgeDistribution?: ModelTypes['InstagramGenderAgeDistributionInput'] | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    instagramIsBusiness?: string | undefined
    instagramIsPrivate?: string | undefined
    instagramIsRecentlyJoined?: string | undefined
    instagramIsVerified?: string | undefined
    instagramLastChecked?: string | undefined
    instagramMedianShares?: number | undefined
    instagramMetricsRetrievedAt?: string | undefined
    instagramName?: string | undefined
    instagramPostsInt?: number | undefined
    instagramPriority?: ModelTypes['PlatformPriority'] | undefined
    instagramProfilePic?: string | undefined
    instagramTrailingComments?: number | undefined
    instagramTrailingDateOfOldestPost?: string | undefined
    instagramTrailingLikes?: number | undefined
    instagramTrailingNumberOfPosts?: number | undefined
    instagramTrailingPostEngagementAvg?: number | undefined
    instagramTrailingPostEngagementRateAvg?: number | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined
    instagramTrailingPostEngagementRateMedian?: number | undefined
    instagramTrailingPostImpressionsAvg?: number | undefined
    instagramTrailingPostImpressionsMedian?: number | undefined
    instagramTrailingPostSponsoredPercentage?: number | undefined
    instagramTrailingPostViewsMedian?: number | undefined
    instagramUserId?: string | undefined
    instagramVerificationMethod?: ModelTypes['SocialVerificationMethod'] | undefined
    internalGender?: ModelTypes['UserGender'] | undefined
    isBrandSafe?: ModelTypes['BrandSafe'] | undefined
    isBrandSafeReason?: string | undefined
    isBrandSafeUpdatedAt?: string | undefined
    lastActiveAt?: string | undefined
    lastName?: string | undefined
    manualScoringLockedUntil?: string | undefined
    moreinfo?: string | undefined
    numEvenDollarPurchases?: number | undefined
    numPurchases?: number | undefined
    paypalEmail?: string | undefined
    phoneNumber?: string | undefined
    profilePicture?: string | undefined
    pushAddresses?: Array<ModelTypes['PushAddressInput']> | undefined
    pushOptOut?: boolean | undefined
    referralCode?: string | undefined
    referralCount?: number | undefined
    referrer?: string | undefined
    signupCountry?: string | undefined
    smsOptOut?: boolean | undefined
    socialQuality?: ModelTypes['SocialQuality'] | undefined
    specialAttributes?: ModelTypes['UserAttributesInput'] | undefined
    statusTier?: ModelTypes['StatusTier'] | undefined
    statusTierHistory?: Array<ModelTypes['StatusTierEntryInput']> | undefined
    statusTierLastUpdatedAt?: string | undefined
    supportPriorityAcknowledgedAt?: string | undefined
    supportPriorityFlag?: ModelTypes['SupportPriorityFlag'] | undefined
    supportPriorityFlagSetAt?: string | undefined
    targetAccount?: string | undefined
    tiktokAccessToken?: string | undefined
    tiktokAccessTokenExpiry?: string | undefined
    tiktokAccountId?: string | undefined
    tiktokAveragePostEngagementRate?: number | undefined
    tiktokAveragePostEngagementRateViews?: number | undefined
    tiktokBio?: string | undefined
    tiktokContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    tiktokMedianComments?: number | undefined
    tiktokMedianLikes?: number | undefined
    tiktokMedianPostEngagement?: number | undefined
    tiktokMedianPostEngagementRate?: number | undefined
    tiktokMedianPostEngagementRateViews?: number | undefined
    tiktokMedianShares?: number | undefined
    tiktokMedianViews?: number | undefined
    tiktokMetricsRetrievedAt?: string | undefined
    tiktokName?: string | undefined
    tiktokPermissionScopes?: Array<string> | undefined
    tiktokPriority?: ModelTypes['PlatformPriority'] | undefined
    tiktokProfilePic?: string | undefined
    tiktokRefreshToken?: string | undefined
    tiktokRefreshTokenExpiry?: string | undefined
    tiktokSponsoredPostPercentage?: number | undefined
    tiktokVerificationMethod?: ModelTypes['SocialVerificationMethod'] | undefined
    tiktokVerified?: string | undefined
    timeZone?: string | undefined
    trackingStatus?: ModelTypes['TrackingStatus'] | undefined
    twitchAccount?: string | undefined
    type: string
    utmReferral?: ModelTypes['UtmReferralInput'] | undefined
    wmartAccount?: string | undefined
    youtubeAccessToken?: string | undefined
    youtubeAccessTokenExpiry?: string | undefined
    youtubeAccount?: string | undefined
    youtubeAveragePostEngagementRate?: number | undefined
    youtubeChannelDescription?: string | undefined
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
    youtubeChannelId?: string | undefined
    youtubeChannelPicture?: string | undefined
    youtubeChannelTitle?: string | undefined
    youtubeIdToken?: string | undefined
    youtubeMedianViews?: number | undefined
    youtubeMetricsRetrievedAt?: string | undefined
    youtubePermissionScopes?: Array<string> | undefined
    youtubeRefreshToken?: string | undefined
    youtubeVerificationMethod?: ModelTypes['SocialVerificationMethod'] | undefined
  }
  ['CreateUserLinkInput']: {
    campaignId?: string | undefined
    clicksPerPeriod?: Array<ModelTypes['ClicksRecordsInput']> | undefined
    createdAt?: string | undefined
    id?: string | undefined
    linkId?: string | undefined
    originalLink: string
    rank?: number | undefined
    storageType?: ModelTypes['LinkStorageType'] | undefined
    uniqueClicks: number
    updatedAt?: string | undefined
    userEncodedLink: string
    userId?: string | undefined
  }
  ['CreateUserNotificationPreferenceInput']: {
    category: string
    emailOptOut?: boolean | undefined
    pushOptOut?: boolean | undefined
    smsOptOut?: boolean | undefined
    userId: string
  }
  ['CreateUserPayoutRequestInput']: {
    failureReason?: string | undefined
    id?: string | undefined
    manuallyApprovedBy?: string | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: ModelTypes['WSLocale'] | undefined
    payoutState: ModelTypes['BonusPayoutState']
    userId: string
  }
  ['CreateUserStagingApprovalInput']: {
    id?: string | undefined
    reviewFeedback?: string | undefined
    reviewedAt?: string | undefined
    reviewedBy?: string | undefined
    reviewedByEmail?: string | undefined
    reviewedByName?: string | undefined
    reviewedBySource?: ModelTypes['UserStagingApprovalReviewSource'] | undefined
    userCampaignId: string
    userStagingApprovalOutcome: ModelTypes['UserStagingApprovalOutcome']
    userStagingApprovalType: ModelTypes['UserStagingApprovalType']
  }
  ['CreateVideoContentScanInput']: {
    audioDuration?: number | undefined
    audioEndAt?: number | undefined
    audioStartFrom?: number | undefined
    contentSafetyAnalysis?: ModelTypes['ContentSafetyAnalysisInput'] | undefined
    id?: string | undefined
    processFinishedAt?: string | undefined
    processId?: string | undefined
    processInitiatedAt?: string | undefined
    scanStatus: ModelTypes['ContentScanStatus']
    socialMediaUrl?: string | undefined
    transcription?: string | undefined
    transcriptionConfidence?: number | undefined
    url: string
    userId: string
  }
  ['CreatorContentRatingRawValueInput']: {
    name: string
    value?: string | undefined
  }
  ['DeleteAutoBuyDistributionInput']: {
    id: string
  }
  ['DeleteAutoBuyFlowConfigurationInput']: {
    flowId: string
  }
  ['DeleteAutoBuyFlowReleaseScheduleInput']: {
    date: string
    flowId: string
  }
  ['DeleteAutoBuyReleaseInput']: {
    date: string
    userCampaignId: string
  }
  ['DeleteBrandApiInput']: {
    api: ModelTypes['BrandApiType']
    brandId: string
  }
  ['DeleteBrandInput']: {
    id: string
  }
  ['DeleteCampaignClicksInput']: {
    campaignId: string
    date: string
  }
  ['DeleteCampaignInput']: {
    id: string
  }
  ['DeleteCampaignInviteInput']: {
    id: string
  }
  ['DeleteCampaignSourcingProspectInput']: {
    campaignId: string
    userIdentifier: string
  }
  ['DeleteChatConversationV2Input']: {
    chatType: ModelTypes['ChatType']
    resourceId: string
  }
  ['DeleteCommunicationNotificationInput']: {
    id: string
  }
  ['DeleteCompanyInput']: {
    id: string
  }
  ['DeleteConnectedAccountInput']: {
    accountId: string
    userId: string
  }
  ['DeleteConversationInput']: {
    userId: string
  }
  ['DeleteConversationMessageInput']: {
    id: string
    userId: string
  }
  ['DeleteEtailerSkuMetricsInput']: {
    date: string
    skuId: string
  }
  ['DeleteFormSequenceConfigurationInput']: {
    id: string
  }
  ['DeleteInstagramStoryScreenInput']: {
    id: string
  }
  ['DeleteMessageInput']: {
    id: string
  }
  ['DeleteNoteInput']: {
    id: string
  }
  ['DeleteNotificationConfigurationInput']: {
    id: string
  }
  ['DeleteProductBonusConfigSnapshotInput']: {
    productId: string
    snapshotAt: string
  }
  ['DeleteProductInput']: {
    id: string
  }
  ['DeleteProductKeywordsInput']: {
    id: string
  }
  ['DeleteProductRatingSnapshotInput']: {
    skuId: string
    snapshotAt: string
  }
  ['DeletePurchaseInput']: {
    id: string
  }
  ['DeleteReferralInput']: {
    id: string
  }
  ['DeleteReviewInput']: {
    id: string
  }
  ['DeleteSkuInput']: {
    id: string
  }
  ['DeleteSkuSnapshotInput']: {
    marketplaceSku: string
    snapshotDate: string
  }
  ['DeleteUserBonusPayoutInput']: {
    id: string
  }
  ['DeleteUserCampaignChangelogInput']: {
    createdAt: string
    userCampaignId: string
  }
  ['DeleteUserCampaignInput']: {
    id: string
  }
  ['DeleteUserCampaignSnapshotInput']: {
    userCampaignId: string
    userCampaignVersion: number
  }
  ['DeleteUserCampaignSocialContentDraftHistoryInput']: {
    id: string
  }
  ['DeleteUserCampaignSocialContentInput']: {
    id: string
  }
  ['DeleteUserCampaignSocialContentReviewInput']: {
    reviewType: ModelTypes['UserCampaignSocialContentReviewType']
    userCampaignSocialContentId: string
  }
  ['DeleteUserDeviceSnapshotInput']: {
    id: string
  }
  ['DeleteUserFollowerInput']: {
    followerUserId: string
    userId: string
  }
  ['DeleteUserInput']: {
    id: string
  }
  ['DeleteUserLinkInput']: {
    id: string
  }
  ['DeleteUserNotificationPreferenceInput']: {
    category: string
    userId: string
  }
  ['DeleteUserPayoutRequestInput']: {
    id: string
  }
  ['DeleteUserStagingApprovalInput']: {
    id: string
  }
  ['DeleteVideoContentScanInput']: {
    id: string
  }
  ['FollowersSearchFields']: {
    followerUserId?: ModelTypes['SearchInputString'] | undefined
    isFavorite?: ModelTypes['SearchInputString'] | undefined
  }
  ['FormSequenceComponentInput']: {
    componentName: string
    componentType: ModelTypes['FormComponentType']
    content?: string | undefined
    id: string
    images?: Array<string> | undefined
    numbered?: boolean | undefined
    placeholder?: string | undefined
    type?: ModelTypes['FormFieldType'] | undefined
    validation?: Array<ModelTypes['FormValidationType']> | undefined
  }
  ['FormSequenceInput']: {
    components?: Array<ModelTypes['FormSequenceComponentInput']> | undefined
    id: string
  }
  ['InspirationalContentInput']: {
    socialChannel?: ModelTypes['SocialChannel'] | undefined
    type: ModelTypes['InspirationalContentType']
    url: string
  }
  ['InstagramCountryDistributionItemInput']: {
    count: number
    countryCode: string
    pctDistribution: number
  }
  ['InstagramFollowerCountryDistributionSearchFields']: {
    countryCode: ModelTypes['SearchInputString']
    pctDistribution: ModelTypes['SearchInputNumber']
  }
  ['InstagramGenderAgeDistributionInput']: {
    femaleCount: number
    genderAgeDistribution?: Array<ModelTypes['InstagramGenderAgeDistributionItemInput']> | undefined
    maleCount: number
    unspecifiedCount: number
  }
  ['InstagramGenderAgeDistributionItemInput']: {
    ageRangeEnd?: number | undefined
    ageRangeStart: number
    count: number
    gender: ModelTypes['InstagramGenderAgeDistributionGender']
  }
  ['InviteUsersToCampaignInput']: {
    campaignId: string
    userIds: Array<string>
  }
  ['KeyAttributeInput']: {
    key: string
    value: string
  }
  ['MessagingChannelConfigurationInput']: {
    attributes?: Array<ModelTypes['KeyAttributeInput']> | undefined
    enabled: boolean
    templateContent: string
  }
  ['MinMaxRangeInput']: {
    max?: number | undefined
    min?: number | undefined
  }
  ['ModelActiveInput']: {
    eq?: ModelTypes['Active'] | undefined
    ne?: ModelTypes['Active'] | undefined
  }
  ['ModelAmazonReviewSubmissionStatusInput']: {
    eq?: ModelTypes['AmazonReviewSubmissionStatus'] | undefined
    ne?: ModelTypes['AmazonReviewSubmissionStatus'] | undefined
  }
  ['ModelApplicationStatusInput']: {
    eq?: ModelTypes['ApplicationStatus'] | undefined
    ne?: ModelTypes['ApplicationStatus'] | undefined
  }
  ['ModelApprovalOutcomeInput']: {
    eq?: ModelTypes['ApprovalOutcome'] | undefined
    ne?: ModelTypes['ApprovalOutcome'] | undefined
  }
  ['ModelArchivedReasonInput']: {
    eq?: ModelTypes['ArchivedReason'] | undefined
    ne?: ModelTypes['ArchivedReason'] | undefined
  }
  ['ModelAutoBuyDelayStrategyInput']: {
    eq?: ModelTypes['AutoBuyDelayStrategy'] | undefined
    ne?: ModelTypes['AutoBuyDelayStrategy'] | undefined
  }
  ['ModelAutoBuyDistributionConditionInput']: {
    and?: Array<ModelTypes['ModelAutoBuyDistributionConditionInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    description?: ModelTypes['ModelStringInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelAutoBuyDistributionConditionInput'] | undefined
    or?: Array<ModelTypes['ModelAutoBuyDistributionConditionInput'] | undefined> | undefined
    schedule?: ModelTypes['ModelIntInput'] | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelAutoBuyDistributionFilterInput']: {
    and?: Array<ModelTypes['ModelAutoBuyDistributionFilterInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    description?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelAutoBuyDistributionFilterInput'] | undefined
    or?: Array<ModelTypes['ModelAutoBuyDistributionFilterInput'] | undefined> | undefined
    schedule?: ModelTypes['ModelIntInput'] | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelAutoBuyFlowConfigurationConditionInput']: {
    and?: Array<ModelTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined> | undefined
    dailyPurchaseMax?: ModelTypes['ModelIntInput'] | undefined
    delayStrategy?: ModelTypes['ModelAutoBuyDelayStrategyInput'] | undefined
    insufficientCreators?: ModelTypes['ModelStringInput'] | undefined
    latestSkipReason?: ModelTypes['ModelAutoBuySkipReasonInput'] | undefined
    not?: ModelTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined
    or?: Array<ModelTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined> | undefined
    pauseReason?: ModelTypes['ModelAutoBuySkipReasonInput'] | undefined
    startDate?: ModelTypes['ModelStringInput'] | undefined
    status?: ModelTypes['ModelAutoBuyStatusInput'] | undefined
  }
  ['ModelAutoBuyFlowConfigurationFilterInput']: {
    and?: Array<ModelTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined> | undefined
    dailyPurchaseMax?: ModelTypes['ModelIntInput'] | undefined
    delayStrategy?: ModelTypes['ModelAutoBuyDelayStrategyInput'] | undefined
    flowId?: ModelTypes['ModelIDInput'] | undefined
    insufficientCreators?: ModelTypes['ModelStringInput'] | undefined
    latestSkipReason?: ModelTypes['ModelAutoBuySkipReasonInput'] | undefined
    not?: ModelTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined
    or?: Array<ModelTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined> | undefined
    pauseReason?: ModelTypes['ModelAutoBuySkipReasonInput'] | undefined
    startDate?: ModelTypes['ModelStringInput'] | undefined
    status?: ModelTypes['ModelAutoBuyStatusInput'] | undefined
  }
  ['ModelAutoBuyFlowReleaseScheduleConditionInput']: {
    actualPurchases?: ModelTypes['ModelIntInput'] | undefined
    actualVerifiedPurchases?: ModelTypes['ModelIntInput'] | undefined
    adjustedDesiredPurchases?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined> | undefined
    autoApprovalSeats?: ModelTypes['ModelIntInput'] | undefined
    desiredPurchases?: ModelTypes['ModelIntInput'] | undefined
    manualPurchases?: ModelTypes['ModelIntInput'] | undefined
    manualReleases?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined
    or?: Array<ModelTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined> | undefined
    projectedReleaseCount?: ModelTypes['ModelIntInput'] | undefined
    releaseCount?: ModelTypes['ModelIntInput'] | undefined
    released?: ModelTypes['ModelBooleanInput'] | undefined
    skippedReason?: ModelTypes['ModelAutoBuySkipReasonInput'] | undefined
  }
  ['ModelAutoBuyFlowReleaseScheduleFilterInput']: {
    actualPurchases?: ModelTypes['ModelIntInput'] | undefined
    actualVerifiedPurchases?: ModelTypes['ModelIntInput'] | undefined
    adjustedDesiredPurchases?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined> | undefined
    autoApprovalSeats?: ModelTypes['ModelIntInput'] | undefined
    date?: ModelTypes['ModelStringInput'] | undefined
    desiredPurchases?: ModelTypes['ModelIntInput'] | undefined
    flowId?: ModelTypes['ModelIDInput'] | undefined
    manualPurchases?: ModelTypes['ModelIntInput'] | undefined
    manualReleases?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined
    or?: Array<ModelTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined> | undefined
    projectedReleaseCount?: ModelTypes['ModelIntInput'] | undefined
    releaseCount?: ModelTypes['ModelIntInput'] | undefined
    released?: ModelTypes['ModelBooleanInput'] | undefined
    skippedReason?: ModelTypes['ModelAutoBuySkipReasonInput'] | undefined
  }
  ['ModelAutoBuyReleaseConditionInput']: {
    and?: Array<ModelTypes['ModelAutoBuyReleaseConditionInput'] | undefined> | undefined
    not?: ModelTypes['ModelAutoBuyReleaseConditionInput'] | undefined
    or?: Array<ModelTypes['ModelAutoBuyReleaseConditionInput'] | undefined> | undefined
    productId?: ModelTypes['ModelIDInput'] | undefined
    releaseDateTime?: ModelTypes['ModelStringInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelAutoBuyReleaseFilterInput']: {
    and?: Array<ModelTypes['ModelAutoBuyReleaseFilterInput'] | undefined> | undefined
    date?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelAutoBuyReleaseFilterInput'] | undefined
    or?: Array<ModelTypes['ModelAutoBuyReleaseFilterInput'] | undefined> | undefined
    productId?: ModelTypes['ModelIDInput'] | undefined
    releaseDateTime?: ModelTypes['ModelStringInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelAutoBuySkipReasonInput']: {
    eq?: ModelTypes['AutoBuySkipReason'] | undefined
    ne?: ModelTypes['AutoBuySkipReason'] | undefined
  }
  ['ModelAutoBuyStatusInput']: {
    eq?: ModelTypes['AutoBuyStatus'] | undefined
    ne?: ModelTypes['AutoBuyStatus'] | undefined
  }
  ['ModelBOPISValueInput']: {
    eq?: ModelTypes['BOPISValue'] | undefined
    ne?: ModelTypes['BOPISValue'] | undefined
  }
  ['ModelBonusPayoutStateInput']: {
    eq?: ModelTypes['BonusPayoutState'] | undefined
    ne?: ModelTypes['BonusPayoutState'] | undefined
  }
  ['ModelBonusPayoutTypesInput']: {
    eq?: ModelTypes['BonusPayoutTypes'] | undefined
    ne?: ModelTypes['BonusPayoutTypes'] | undefined
  }
  ['ModelBooleanInput']: {
    attributeExists?: boolean | undefined
    attributeType?: ModelTypes['ModelAttributeTypes'] | undefined
    eq?: boolean | undefined
    ne?: boolean | undefined
  }
  ['ModelBrandApiConditionInput']: {
    accessToken?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelBrandApiConditionInput'] | undefined> | undefined
    apiVersion?: ModelTypes['ModelBrandApiVersionInput'] | undefined
    enabled?: ModelTypes['ModelBooleanInput'] | undefined
    locale?: ModelTypes['ModelWSLocaleInput'] | undefined
    not?: ModelTypes['ModelBrandApiConditionInput'] | undefined
    or?: Array<ModelTypes['ModelBrandApiConditionInput'] | undefined> | undefined
    refreshToken?: ModelTypes['ModelStringInput'] | undefined
    shop?: ModelTypes['ModelStringInput'] | undefined
    shopId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelBrandApiFilterInput']: {
    accessToken?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelBrandApiFilterInput'] | undefined> | undefined
    api?: ModelTypes['ModelBrandApiTypeInput'] | undefined
    apiVersion?: ModelTypes['ModelBrandApiVersionInput'] | undefined
    brandId?: ModelTypes['ModelIDInput'] | undefined
    enabled?: ModelTypes['ModelBooleanInput'] | undefined
    locale?: ModelTypes['ModelWSLocaleInput'] | undefined
    not?: ModelTypes['ModelBrandApiFilterInput'] | undefined
    or?: Array<ModelTypes['ModelBrandApiFilterInput'] | undefined> | undefined
    refreshToken?: ModelTypes['ModelStringInput'] | undefined
    shop?: ModelTypes['ModelStringInput'] | undefined
    shopId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelBrandApiTypeInput']: {
    eq?: ModelTypes['BrandApiType'] | undefined
    ne?: ModelTypes['BrandApiType'] | undefined
  }
  ['ModelBrandApiVersionInput']: {
    eq?: ModelTypes['BrandApiVersion'] | undefined
    ne?: ModelTypes['BrandApiVersion'] | undefined
  }
  ['ModelBrandConditionInput']: {
    active?: ModelTypes['ModelStringInput'] | undefined
    amazonBrandName?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelBrandConditionInput'] | undefined> | undefined
    brandDetailsSubmitted?: ModelTypes['ModelBooleanInput'] | undefined
    brandInstaHandle?: ModelTypes['ModelStringInput'] | undefined
    brandLogoImageKey?: ModelTypes['ModelStringInput'] | undefined
    brandStoreConfirmationImage?: ModelTypes['ModelStringInput'] | undefined
    brandStoreConfirmationMobileImage?: ModelTypes['ModelStringInput'] | undefined
    brandStoreScrapeUrl?: ModelTypes['ModelStringInput'] | undefined
    brandTikTokHandle?: ModelTypes['ModelStringInput'] | undefined
    companyId?: ModelTypes['ModelIDInput'] | undefined
    contactEmails?: ModelTypes['ModelStringInput'] | undefined
    defaultCreatorPreference?: ModelTypes['ModelCreatorPreferenceInput'] | undefined
    defaultCreatorPreferenceDetails?: ModelTypes['ModelStringInput'] | undefined
    defaultHashtags?: ModelTypes['ModelStringInput'] | undefined
    defaultMarketingBriefKey?: ModelTypes['ModelStringInput'] | undefined
    defaultPostingGuidelines?: ModelTypes['ModelStringInput'] | undefined
    defaultPostingInspirationLinks?: ModelTypes['ModelStringInput'] | undefined
    emailsSent?: ModelTypes['ModelStringInput'] | undefined
    fullScrapedAt?: ModelTypes['ModelStringInput'] | undefined
    isBrandStoreScrapeEnabled?: ModelTypes['ModelBooleanInput'] | undefined
    isSeller?: ModelTypes['ModelStringInput'] | undefined
    isVendor?: ModelTypes['ModelStringInput'] | undefined
    isWalmartScrapeEnabled?: ModelTypes['ModelBooleanInput'] | undefined
    lastScrapedAt?: ModelTypes['ModelStringInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    nameAudioKey?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelBrandConditionInput'] | undefined
    or?: Array<ModelTypes['ModelBrandConditionInput'] | undefined> | undefined
    platform?: ModelTypes['ModelPlatformSourceInput'] | undefined
    postingGuidanceDocument?: ModelTypes['ModelStringInput'] | undefined
    region?: ModelTypes['ModelWSLocaleInput'] | undefined
    socialMediaExampleUrls?: ModelTypes['ModelStringInput'] | undefined
    subscriptionEndsAt?: ModelTypes['ModelStringInput'] | undefined
    subscriptionStartsAt?: ModelTypes['ModelStringInput'] | undefined
    walmartBrandName?: ModelTypes['ModelStringInput'] | undefined
    walmartLastScrapedAt?: ModelTypes['ModelStringInput'] | undefined
    walmartScrapeUrl?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelBrandFilterInput']: {
    active?: ModelTypes['ModelStringInput'] | undefined
    amazonBrandName?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelBrandFilterInput'] | undefined> | undefined
    brandDetailsSubmitted?: ModelTypes['ModelBooleanInput'] | undefined
    brandInstaHandle?: ModelTypes['ModelStringInput'] | undefined
    brandLogoImageKey?: ModelTypes['ModelStringInput'] | undefined
    brandStoreConfirmationImage?: ModelTypes['ModelStringInput'] | undefined
    brandStoreConfirmationMobileImage?: ModelTypes['ModelStringInput'] | undefined
    brandStoreScrapeUrl?: ModelTypes['ModelStringInput'] | undefined
    brandTikTokHandle?: ModelTypes['ModelStringInput'] | undefined
    companyId?: ModelTypes['ModelIDInput'] | undefined
    contactEmails?: ModelTypes['ModelStringInput'] | undefined
    defaultCreatorPreference?: ModelTypes['ModelCreatorPreferenceInput'] | undefined
    defaultCreatorPreferenceDetails?: ModelTypes['ModelStringInput'] | undefined
    defaultHashtags?: ModelTypes['ModelStringInput'] | undefined
    defaultMarketingBriefKey?: ModelTypes['ModelStringInput'] | undefined
    defaultPostingGuidelines?: ModelTypes['ModelStringInput'] | undefined
    defaultPostingInspirationLinks?: ModelTypes['ModelStringInput'] | undefined
    emailsSent?: ModelTypes['ModelStringInput'] | undefined
    fullScrapedAt?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    isBrandStoreScrapeEnabled?: ModelTypes['ModelBooleanInput'] | undefined
    isSeller?: ModelTypes['ModelStringInput'] | undefined
    isVendor?: ModelTypes['ModelStringInput'] | undefined
    isWalmartScrapeEnabled?: ModelTypes['ModelBooleanInput'] | undefined
    lastScrapedAt?: ModelTypes['ModelStringInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    nameAudioKey?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelBrandFilterInput'] | undefined
    or?: Array<ModelTypes['ModelBrandFilterInput'] | undefined> | undefined
    platform?: ModelTypes['ModelPlatformSourceInput'] | undefined
    postingGuidanceDocument?: ModelTypes['ModelStringInput'] | undefined
    region?: ModelTypes['ModelWSLocaleInput'] | undefined
    socialMediaExampleUrls?: ModelTypes['ModelStringInput'] | undefined
    subscriptionEndsAt?: ModelTypes['ModelStringInput'] | undefined
    subscriptionStartsAt?: ModelTypes['ModelStringInput'] | undefined
    walmartBrandName?: ModelTypes['ModelStringInput'] | undefined
    walmartLastScrapedAt?: ModelTypes['ModelStringInput'] | undefined
    walmartScrapeUrl?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelBrandSafeInput']: {
    eq?: ModelTypes['BrandSafe'] | undefined
    ne?: ModelTypes['BrandSafe'] | undefined
  }
  ['ModelCampaignClicksConditionInput']: {
    and?: Array<ModelTypes['ModelCampaignClicksConditionInput'] | undefined> | undefined
    clicks?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelCampaignClicksConditionInput'] | undefined
    or?: Array<ModelTypes['ModelCampaignClicksConditionInput'] | undefined> | undefined
  }
  ['ModelCampaignClicksFilterInput']: {
    and?: Array<ModelTypes['ModelCampaignClicksFilterInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    clicks?: ModelTypes['ModelIntInput'] | undefined
    date?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelCampaignClicksFilterInput'] | undefined
    or?: Array<ModelTypes['ModelCampaignClicksFilterInput'] | undefined> | undefined
  }
  ['ModelCampaignConditionInput']: {
    aboutSection?: ModelTypes['ModelStringInput'] | undefined
    active?: ModelTypes['ModelStringInput'] | undefined
    actualStartDate?: ModelTypes['ModelStringInput'] | undefined
    amountPaidByClient?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelCampaignConditionInput'] | undefined> | undefined
    anticipatedStartDate?: ModelTypes['ModelStringInput'] | undefined
    applicationForm?: ModelTypes['ModelStringInput'] | undefined
    applicationProductLinkId?: ModelTypes['ModelIDInput'] | undefined
    applicationStatus?: ModelTypes['ModelApplicationStatusInput'] | undefined
    attributionRefUrlSuffix?: ModelTypes['ModelStringInput'] | undefined
    authorizedUnits?: ModelTypes['ModelIntInput'] | undefined
    brandId?: ModelTypes['ModelIDInput'] | undefined
    brandProductShort?: ModelTypes['ModelStringInput'] | undefined
    brandStoreLink?: ModelTypes['ModelStringInput'] | undefined
    budgetSpent?: ModelTypes['ModelIntInput'] | undefined
    campaignProductType?: ModelTypes['ModelCampaignProductTypeInput'] | undefined
    campaignReferralBonusAmount?: ModelTypes['ModelIntInput'] | undefined
    campaignReferralBonusEnabled?: ModelTypes['ModelCampaignReferralBonusFlagInput'] | undefined
    campaignStateMachine?: ModelTypes['ModelStringInput'] | undefined
    closeoutReleaseFailureReason?: ModelTypes['ModelStringInput'] | undefined
    commissionFlatRate?: ModelTypes['ModelIntInput'] | undefined
    commissionPercentageRate?: ModelTypes['ModelIntInput'] | undefined
    committedClicks?: ModelTypes['ModelIntInput'] | undefined
    committedPlacementWinsCount?: ModelTypes['ModelIntInput'] | undefined
    committedReviewsCount?: ModelTypes['ModelIntInput'] | undefined
    contentApprovalRequired?: ModelTypes['ModelBooleanInput'] | undefined
    contentCompleteDeadline?: ModelTypes['ModelStringInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    creationSourceVersion?: ModelTypes['ModelCreationSourceVersionInput'] | undefined
    earlyReviewEnabled?: ModelTypes['ModelBooleanInput'] | undefined
    enableContentComplianceReview?: ModelTypes['ModelBooleanInput'] | undefined
    endDate?: ModelTypes['ModelStringInput'] | undefined
    excludeGender?: ModelTypes['ModelUserFilterGenderInput'] | undefined
    expectedBonus?: ModelTypes['ModelStringInput'] | undefined
    followersCountCeiling?: ModelTypes['ModelIntInput'] | undefined
    followersCountFloor?: ModelTypes['ModelIntInput'] | undefined
    goal?: ModelTypes['ModelCampaignGoalInput'] | undefined
    influencerApprovalEnabled?: ModelTypes['ModelInfluencerApprovalFlagV2Input'] | undefined
    lifestyleCommittedContentCount?: ModelTypes['ModelIntInput'] | undefined
    localeV1?: ModelTypes['ModelWSLocaleInput'] | undefined
    marketingFee?: ModelTypes['ModelIntInput'] | undefined
    marketingFormFilled?: ModelTypes['ModelBooleanInput'] | undefined
    newCreatorsOnly?: ModelTypes['ModelBooleanInput'] | undefined
    not?: ModelTypes['ModelCampaignConditionInput'] | undefined
    or?: Array<ModelTypes['ModelCampaignConditionInput'] | undefined> | undefined
    payForClicksEnabled?: ModelTypes['ModelEnabledFlagInput'] | undefined
    payForClicksMinClicks?: ModelTypes['ModelIntInput'] | undefined
    payForClicksOriginalLink?: ModelTypes['ModelStringInput'] | undefined
    payForClicksTotalClicksGenerated?: ModelTypes['ModelIntInput'] | undefined
    payForPerformanceEnabled?: ModelTypes['ModelPayForPerformanceFlagInput'] | undefined
    postingGuidance?: ModelTypes['ModelStringInput'] | undefined
    preApplicationProductImage?: ModelTypes['ModelStringInput'] | undefined
    preApplicationProductPriceRange?: ModelTypes['ModelStringInput'] | undefined
    preApplicationUgcProductImage?: ModelTypes['ModelStringInput'] | undefined
    preLaunchDate?: ModelTypes['ModelStringInput'] | undefined
    premiumLifestyleCommittedContentCount?: ModelTypes['ModelIntInput'] | undefined
    requiredProductLinksEnabled?: ModelTypes['ModelRequiredProductLinkFlagInput'] | undefined
    reviewVotingTarget?: ModelTypes['ModelStringInput'] | undefined
    sample?: ModelTypes['ModelBooleanInput'] | undefined
    shellCampaignBase?: ModelTypes['ModelIDInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    skuPriceOverride?: ModelTypes['ModelIntInput'] | undefined
    socialCommittedContentCount?: ModelTypes['ModelIntInput'] | undefined
    sourcingPriority?: ModelTypes['ModelIntInput'] | undefined
    sponsorshipInstructions?: ModelTypes['ModelStringInput'] | undefined
    title?: ModelTypes['ModelStringInput'] | undefined
    totalEarlyReviewsAllowed?: ModelTypes['ModelIntInput'] | undefined
    totalProductCost?: ModelTypes['ModelIntInput'] | undefined
    userAutoApproved?: ModelTypes['ModelBooleanInput'] | undefined
    visibleToUser?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignFilterInput']: {
    aboutSection?: ModelTypes['ModelStringInput'] | undefined
    active?: ModelTypes['ModelStringInput'] | undefined
    actualStartDate?: ModelTypes['ModelStringInput'] | undefined
    amountPaidByClient?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelCampaignFilterInput'] | undefined> | undefined
    anticipatedStartDate?: ModelTypes['ModelStringInput'] | undefined
    applicationForm?: ModelTypes['ModelStringInput'] | undefined
    applicationProductLinkId?: ModelTypes['ModelIDInput'] | undefined
    applicationStatus?: ModelTypes['ModelApplicationStatusInput'] | undefined
    attributionRefUrlSuffix?: ModelTypes['ModelStringInput'] | undefined
    authorizedUnits?: ModelTypes['ModelIntInput'] | undefined
    brandId?: ModelTypes['ModelIDInput'] | undefined
    brandProductShort?: ModelTypes['ModelStringInput'] | undefined
    brandStoreLink?: ModelTypes['ModelStringInput'] | undefined
    budgetSpent?: ModelTypes['ModelIntInput'] | undefined
    campaignProductType?: ModelTypes['ModelCampaignProductTypeInput'] | undefined
    campaignReferralBonusAmount?: ModelTypes['ModelIntInput'] | undefined
    campaignReferralBonusEnabled?: ModelTypes['ModelCampaignReferralBonusFlagInput'] | undefined
    campaignStateMachine?: ModelTypes['ModelStringInput'] | undefined
    closeoutReleaseFailureReason?: ModelTypes['ModelStringInput'] | undefined
    commissionFlatRate?: ModelTypes['ModelIntInput'] | undefined
    commissionPercentageRate?: ModelTypes['ModelIntInput'] | undefined
    committedClicks?: ModelTypes['ModelIntInput'] | undefined
    committedPlacementWinsCount?: ModelTypes['ModelIntInput'] | undefined
    committedReviewsCount?: ModelTypes['ModelIntInput'] | undefined
    contentApprovalRequired?: ModelTypes['ModelBooleanInput'] | undefined
    contentCompleteDeadline?: ModelTypes['ModelStringInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    creationSourceVersion?: ModelTypes['ModelCreationSourceVersionInput'] | undefined
    earlyReviewEnabled?: ModelTypes['ModelBooleanInput'] | undefined
    enableContentComplianceReview?: ModelTypes['ModelBooleanInput'] | undefined
    endDate?: ModelTypes['ModelStringInput'] | undefined
    excludeGender?: ModelTypes['ModelUserFilterGenderInput'] | undefined
    expectedBonus?: ModelTypes['ModelStringInput'] | undefined
    followersCountCeiling?: ModelTypes['ModelIntInput'] | undefined
    followersCountFloor?: ModelTypes['ModelIntInput'] | undefined
    goal?: ModelTypes['ModelCampaignGoalInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    influencerApprovalEnabled?: ModelTypes['ModelInfluencerApprovalFlagV2Input'] | undefined
    lifestyleCommittedContentCount?: ModelTypes['ModelIntInput'] | undefined
    localeV1?: ModelTypes['ModelWSLocaleInput'] | undefined
    marketingFee?: ModelTypes['ModelIntInput'] | undefined
    marketingFormFilled?: ModelTypes['ModelBooleanInput'] | undefined
    newCreatorsOnly?: ModelTypes['ModelBooleanInput'] | undefined
    not?: ModelTypes['ModelCampaignFilterInput'] | undefined
    or?: Array<ModelTypes['ModelCampaignFilterInput'] | undefined> | undefined
    payForClicksEnabled?: ModelTypes['ModelEnabledFlagInput'] | undefined
    payForClicksMinClicks?: ModelTypes['ModelIntInput'] | undefined
    payForClicksOriginalLink?: ModelTypes['ModelStringInput'] | undefined
    payForClicksTotalClicksGenerated?: ModelTypes['ModelIntInput'] | undefined
    payForPerformanceEnabled?: ModelTypes['ModelPayForPerformanceFlagInput'] | undefined
    postingGuidance?: ModelTypes['ModelStringInput'] | undefined
    preApplicationProductImage?: ModelTypes['ModelStringInput'] | undefined
    preApplicationProductPriceRange?: ModelTypes['ModelStringInput'] | undefined
    preApplicationUgcProductImage?: ModelTypes['ModelStringInput'] | undefined
    preLaunchDate?: ModelTypes['ModelStringInput'] | undefined
    premiumLifestyleCommittedContentCount?: ModelTypes['ModelIntInput'] | undefined
    requiredProductLinksEnabled?: ModelTypes['ModelRequiredProductLinkFlagInput'] | undefined
    reviewVotingTarget?: ModelTypes['ModelStringInput'] | undefined
    sample?: ModelTypes['ModelBooleanInput'] | undefined
    shellCampaignBase?: ModelTypes['ModelIDInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    skuPriceOverride?: ModelTypes['ModelIntInput'] | undefined
    socialCommittedContentCount?: ModelTypes['ModelIntInput'] | undefined
    sourcingPriority?: ModelTypes['ModelIntInput'] | undefined
    sponsorshipInstructions?: ModelTypes['ModelStringInput'] | undefined
    title?: ModelTypes['ModelStringInput'] | undefined
    totalEarlyReviewsAllowed?: ModelTypes['ModelIntInput'] | undefined
    totalProductCost?: ModelTypes['ModelIntInput'] | undefined
    userAutoApproved?: ModelTypes['ModelBooleanInput'] | undefined
    visibleToUser?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignGoalInput']: {
    eq?: ModelTypes['CampaignGoal'] | undefined
    ne?: ModelTypes['CampaignGoal'] | undefined
  }
  ['ModelCampaignInviteConditionInput']: {
    and?: Array<ModelTypes['ModelCampaignInviteConditionInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelCampaignInviteConditionInput'] | undefined
    notificationStatus?: ModelTypes['ModelNotificationStatusInput'] | undefined
    or?: Array<ModelTypes['ModelCampaignInviteConditionInput'] | undefined> | undefined
    priority?: ModelTypes['ModelIntInput'] | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignInviteFilterInput']: {
    and?: Array<ModelTypes['ModelCampaignInviteFilterInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelCampaignInviteFilterInput'] | undefined
    notificationStatus?: ModelTypes['ModelNotificationStatusInput'] | undefined
    or?: Array<ModelTypes['ModelCampaignInviteFilterInput'] | undefined> | undefined
    priority?: ModelTypes['ModelIntInput'] | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelCampaignProductTypeInput']: {
    eq?: ModelTypes['CampaignProductType'] | undefined
    ne?: ModelTypes['CampaignProductType'] | undefined
  }
  ['ModelCampaignReferralBonusFlagInput']: {
    eq?: ModelTypes['CampaignReferralBonusFlag'] | undefined
    ne?: ModelTypes['CampaignReferralBonusFlag'] | undefined
  }
  ['ModelCampaignSourcingProspectConditionInput']: {
    and?: Array<ModelTypes['ModelCampaignSourcingProspectConditionInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    createdById?: ModelTypes['ModelIDInput'] | undefined
    instagramFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    instagramHandle?: ModelTypes['ModelStringInput'] | undefined
    internalNotesId?: ModelTypes['ModelIDInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelCampaignSourcingProspectConditionInput'] | undefined
    or?: Array<ModelTypes['ModelCampaignSourcingProspectConditionInput'] | undefined> | undefined
    platformInviteSource?: ModelTypes['ModelPlatformInviteSourceInput'] | undefined
    platformStatus?: ModelTypes['ModelPlatformStatusInput'] | undefined
    preApprovalStatus?: ModelTypes['ModelPreApprovalStatusInput'] | undefined
    preApprovalStatusById?: ModelTypes['ModelIDInput'] | undefined
    priority?: ModelTypes['ModelSourcePriorityInput'] | undefined
    productId?: ModelTypes['ModelStringInput'] | undefined
    sourcingSource?: ModelTypes['ModelCampaignSourcingSourceInput'] | undefined
    tiktokFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    tiktokHandle?: ModelTypes['ModelStringInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    youtubeChannelFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    youtubeChannelHandle?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignSourcingProspectFilterInput']: {
    and?: Array<ModelTypes['ModelCampaignSourcingProspectFilterInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    createdById?: ModelTypes['ModelIDInput'] | undefined
    instagramFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    instagramHandle?: ModelTypes['ModelStringInput'] | undefined
    internalNotesId?: ModelTypes['ModelIDInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelCampaignSourcingProspectFilterInput'] | undefined
    or?: Array<ModelTypes['ModelCampaignSourcingProspectFilterInput'] | undefined> | undefined
    platformInviteSource?: ModelTypes['ModelPlatformInviteSourceInput'] | undefined
    platformStatus?: ModelTypes['ModelPlatformStatusInput'] | undefined
    preApprovalStatus?: ModelTypes['ModelPreApprovalStatusInput'] | undefined
    preApprovalStatusById?: ModelTypes['ModelIDInput'] | undefined
    priority?: ModelTypes['ModelSourcePriorityInput'] | undefined
    productId?: ModelTypes['ModelStringInput'] | undefined
    sourcingSource?: ModelTypes['ModelCampaignSourcingSourceInput'] | undefined
    tiktokFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    tiktokHandle?: ModelTypes['ModelStringInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    userIdentifier?: ModelTypes['ModelIDInput'] | undefined
    youtubeChannelFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    youtubeChannelHandle?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignSourcingSourceInput']: {
    eq?: ModelTypes['CampaignSourcingSource'] | undefined
    ne?: ModelTypes['CampaignSourcingSource'] | undefined
  }
  ['ModelCampaignStatusInput']: {
    eq?: ModelTypes['CampaignStatus'] | undefined
    ne?: ModelTypes['CampaignStatus'] | undefined
  }
  ['ModelChannelInput']: {
    eq?: ModelTypes['Channel'] | undefined
    ne?: ModelTypes['Channel'] | undefined
  }
  ['ModelChatConversationV2ConditionInput']: {
    active?: ModelTypes['ModelActiveInput'] | undefined
    adminLastReadMessageIndex?: ModelTypes['ModelIntInput'] | undefined
    adminUnreadCount?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelChatConversationV2ConditionInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelStringInput'] | undefined
    creatorLastReadMessageIndex?: ModelTypes['ModelIntInput'] | undefined
    creatorUnreadCount?: ModelTypes['ModelIntInput'] | undefined
    lastMessage?: ModelTypes['ModelStringInput'] | undefined
    lastMessageDate?: ModelTypes['ModelStringInput'] | undefined
    lastMessageId?: ModelTypes['ModelStringInput'] | undefined
    lastMessageSource?: ModelTypes['ModelConversationMessageSourceInput'] | undefined
    not?: ModelTypes['ModelChatConversationV2ConditionInput'] | undefined
    or?: Array<ModelTypes['ModelChatConversationV2ConditionInput'] | undefined> | undefined
    status?: ModelTypes['ModelConversationInternalStatusInput'] | undefined
    statusUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    statusUpdatedByFirstName?: ModelTypes['ModelStringInput'] | undefined
    statusUpdatedById?: ModelTypes['ModelStringInput'] | undefined
    statusUpdatedByLastName?: ModelTypes['ModelStringInput'] | undefined
    twilioConversationId?: ModelTypes['ModelIDInput'] | undefined
    userCampaignId?: ModelTypes['ModelStringInput'] | undefined
    userEmail?: ModelTypes['ModelStringInput'] | undefined
    userFirstName?: ModelTypes['ModelStringInput'] | undefined
    userLastName?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelChatConversationV2FilterInput']: {
    active?: ModelTypes['ModelActiveInput'] | undefined
    adminLastReadMessageIndex?: ModelTypes['ModelIntInput'] | undefined
    adminUnreadCount?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelChatConversationV2FilterInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelStringInput'] | undefined
    chatType?: ModelTypes['ModelChatTypeInput'] | undefined
    creatorLastReadMessageIndex?: ModelTypes['ModelIntInput'] | undefined
    creatorUnreadCount?: ModelTypes['ModelIntInput'] | undefined
    lastMessage?: ModelTypes['ModelStringInput'] | undefined
    lastMessageDate?: ModelTypes['ModelStringInput'] | undefined
    lastMessageId?: ModelTypes['ModelStringInput'] | undefined
    lastMessageSource?: ModelTypes['ModelConversationMessageSourceInput'] | undefined
    not?: ModelTypes['ModelChatConversationV2FilterInput'] | undefined
    or?: Array<ModelTypes['ModelChatConversationV2FilterInput'] | undefined> | undefined
    resourceId?: ModelTypes['ModelIDInput'] | undefined
    status?: ModelTypes['ModelConversationInternalStatusInput'] | undefined
    statusUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    statusUpdatedByFirstName?: ModelTypes['ModelStringInput'] | undefined
    statusUpdatedById?: ModelTypes['ModelStringInput'] | undefined
    statusUpdatedByLastName?: ModelTypes['ModelStringInput'] | undefined
    twilioConversationId?: ModelTypes['ModelIDInput'] | undefined
    userCampaignId?: ModelTypes['ModelStringInput'] | undefined
    userEmail?: ModelTypes['ModelStringInput'] | undefined
    userFirstName?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
    userLastName?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelChatTypeInput']: {
    eq?: ModelTypes['ChatType'] | undefined
    ne?: ModelTypes['ChatType'] | undefined
  }
  ['ModelCoarseCampaignStatusInput']: {
    eq?: ModelTypes['CoarseCampaignStatus'] | undefined
    ne?: ModelTypes['CoarseCampaignStatus'] | undefined
  }
  ['ModelCommunicationNotificationConditionInput']: {
    address?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelCommunicationNotificationConditionInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    channel?: ModelTypes['ModelChannelInput'] | undefined
    expectedSentAt?: ModelTypes['ModelStringInput'] | undefined
    experimentTreatment?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelCommunicationNotificationConditionInput'] | undefined
    notificationId?: ModelTypes['ModelStringInput'] | undefined
    or?: Array<ModelTypes['ModelCommunicationNotificationConditionInput'] | undefined> | undefined
    sentAt?: ModelTypes['ModelStringInput'] | undefined
    status?: ModelTypes['ModelStringInput'] | undefined
    statusMessage?: ModelTypes['ModelStringInput'] | undefined
    templateId?: ModelTypes['ModelStringInput'] | undefined
    traceId?: ModelTypes['ModelIDInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelCommunicationNotificationFilterInput']: {
    address?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelCommunicationNotificationFilterInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    channel?: ModelTypes['ModelChannelInput'] | undefined
    expectedSentAt?: ModelTypes['ModelStringInput'] | undefined
    experimentTreatment?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelCommunicationNotificationFilterInput'] | undefined
    notificationId?: ModelTypes['ModelStringInput'] | undefined
    or?: Array<ModelTypes['ModelCommunicationNotificationFilterInput'] | undefined> | undefined
    sentAt?: ModelTypes['ModelStringInput'] | undefined
    status?: ModelTypes['ModelStringInput'] | undefined
    statusMessage?: ModelTypes['ModelStringInput'] | undefined
    templateId?: ModelTypes['ModelStringInput'] | undefined
    traceId?: ModelTypes['ModelIDInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelCompanyConditionInput']: {
    agencyId?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelCompanyConditionInput'] | undefined> | undefined
    logo?: ModelTypes['ModelStringInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelCompanyConditionInput'] | undefined
    or?: Array<ModelTypes['ModelCompanyConditionInput'] | undefined> | undefined
    paymentTermsCustomNetDays?: ModelTypes['ModelIntInput'] | undefined
    paymentTermsType?: ModelTypes['ModelPaymentTermsTypeInput'] | undefined
    subscriptionEndsAt?: ModelTypes['ModelStringInput'] | undefined
    subscriptionStartsAt?: ModelTypes['ModelStringInput'] | undefined
    websiteUrl?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelCompanyFilterInput']: {
    agencyId?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelCompanyFilterInput'] | undefined> | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    logo?: ModelTypes['ModelStringInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelCompanyFilterInput'] | undefined
    or?: Array<ModelTypes['ModelCompanyFilterInput'] | undefined> | undefined
    paymentTermsCustomNetDays?: ModelTypes['ModelIntInput'] | undefined
    paymentTermsType?: ModelTypes['ModelPaymentTermsTypeInput'] | undefined
    subscriptionEndsAt?: ModelTypes['ModelStringInput'] | undefined
    subscriptionStartsAt?: ModelTypes['ModelStringInput'] | undefined
    websiteUrl?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelConnectedAccountConditionInput']: {
    accountType?: ModelTypes['ModelConnectedAccountTypeInput'] | undefined
    and?: Array<ModelTypes['ModelConnectedAccountConditionInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelConnectedAccountConditionInput'] | undefined
    or?: Array<ModelTypes['ModelConnectedAccountConditionInput'] | undefined> | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelConnectedAccountFilterInput']: {
    accountId?: ModelTypes['ModelIDInput'] | undefined
    accountType?: ModelTypes['ModelConnectedAccountTypeInput'] | undefined
    and?: Array<ModelTypes['ModelConnectedAccountFilterInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelConnectedAccountFilterInput'] | undefined
    or?: Array<ModelTypes['ModelConnectedAccountFilterInput'] | undefined> | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelConnectedAccountTypeInput']: {
    eq?: ModelTypes['ConnectedAccountType'] | undefined
    ne?: ModelTypes['ConnectedAccountType'] | undefined
  }
  ['ModelContentApprovalStatusInput']: {
    eq?: ModelTypes['ContentApprovalStatus'] | undefined
    ne?: ModelTypes['ContentApprovalStatus'] | undefined
  }
  ['ModelContentComplianceReviewStatusInput']: {
    eq?: ModelTypes['ContentComplianceReviewStatus'] | undefined
    ne?: ModelTypes['ContentComplianceReviewStatus'] | undefined
  }
  ['ModelContentRatingReviewStatusInput']: {
    eq?: ModelTypes['ContentRatingReviewStatus'] | undefined
    ne?: ModelTypes['ContentRatingReviewStatus'] | undefined
  }
  ['ModelContentRequirementLevelInput']: {
    eq?: ModelTypes['ContentRequirementLevel'] | undefined
    ne?: ModelTypes['ContentRequirementLevel'] | undefined
  }
  ['ModelContentScanStatusInput']: {
    eq?: ModelTypes['ContentScanStatus'] | undefined
    ne?: ModelTypes['ContentScanStatus'] | undefined
  }
  ['ModelContentTypeRatingListInput']: {
    contains?: ModelTypes['ContentTypeRating'] | undefined
    eq?: Array<ModelTypes['ContentTypeRating'] | undefined> | undefined
    ne?: Array<ModelTypes['ContentTypeRating'] | undefined> | undefined
    notContains?: ModelTypes['ContentTypeRating'] | undefined
  }
  ['ModelConversationChannelInput']: {
    eq?: ModelTypes['ConversationChannel'] | undefined
    ne?: ModelTypes['ConversationChannel'] | undefined
  }
  ['ModelConversationConditionInput']: {
    and?: Array<ModelTypes['ModelConversationConditionInput'] | undefined> | undefined
    contactOwnerId?: ModelTypes['ModelIDInput'] | undefined
    lastContactAt?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelConversationConditionInput'] | undefined
    or?: Array<ModelTypes['ModelConversationConditionInput'] | undefined> | undefined
    status?: ModelTypes['ModelConversationStatusInput'] | undefined
    statusChangedAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelConversationFilterInput']: {
    and?: Array<ModelTypes['ModelConversationFilterInput'] | undefined> | undefined
    contactOwnerId?: ModelTypes['ModelIDInput'] | undefined
    lastContactAt?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelConversationFilterInput'] | undefined
    or?: Array<ModelTypes['ModelConversationFilterInput'] | undefined> | undefined
    status?: ModelTypes['ModelConversationStatusInput'] | undefined
    statusChangedAt?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelConversationInternalStatusInput']: {
    eq?: ModelTypes['ConversationInternalStatus'] | undefined
    ne?: ModelTypes['ConversationInternalStatus'] | undefined
  }
  ['ModelConversationMessageConditionInput']: {
    and?: Array<ModelTypes['ModelConversationMessageConditionInput'] | undefined> | undefined
    channel?: ModelTypes['ModelConversationChannelInput'] | undefined
    messageContent?: ModelTypes['ModelStringInput'] | undefined
    messageId?: ModelTypes['ModelStringInput'] | undefined
    messageMediaKeys?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelConversationMessageConditionInput'] | undefined
    or?: Array<ModelTypes['ModelConversationMessageConditionInput'] | undefined> | undefined
    replyFromId?: ModelTypes['ModelIDInput'] | undefined
    sendAt?: ModelTypes['ModelStringInput'] | undefined
    sendAttempts?: ModelTypes['ModelIntInput'] | undefined
    sentAt?: ModelTypes['ModelStringInput'] | undefined
    status?: ModelTypes['ModelConversationMessageStatusInput'] | undefined
  }
  ['ModelConversationMessageFilterInput']: {
    and?: Array<ModelTypes['ModelConversationMessageFilterInput'] | undefined> | undefined
    channel?: ModelTypes['ModelConversationChannelInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    messageContent?: ModelTypes['ModelStringInput'] | undefined
    messageId?: ModelTypes['ModelStringInput'] | undefined
    messageMediaKeys?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelConversationMessageFilterInput'] | undefined
    or?: Array<ModelTypes['ModelConversationMessageFilterInput'] | undefined> | undefined
    replyFromId?: ModelTypes['ModelIDInput'] | undefined
    sendAt?: ModelTypes['ModelStringInput'] | undefined
    sendAttempts?: ModelTypes['ModelIntInput'] | undefined
    sentAt?: ModelTypes['ModelStringInput'] | undefined
    status?: ModelTypes['ModelConversationMessageStatusInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelConversationMessageSourceInput']: {
    eq?: ModelTypes['ConversationMessageSource'] | undefined
    ne?: ModelTypes['ConversationMessageSource'] | undefined
  }
  ['ModelConversationMessageStatusInput']: {
    eq?: ModelTypes['ConversationMessageStatus'] | undefined
    ne?: ModelTypes['ConversationMessageStatus'] | undefined
  }
  ['ModelConversationStatusInput']: {
    eq?: ModelTypes['ConversationStatus'] | undefined
    ne?: ModelTypes['ConversationStatus'] | undefined
  }
  ['ModelCreationSourceVersionInput']: {
    eq?: ModelTypes['CreationSourceVersion'] | undefined
    ne?: ModelTypes['CreationSourceVersion'] | undefined
  }
  ['ModelCreatorPreferenceInput']: {
    eq?: ModelTypes['CreatorPreference'] | undefined
    ne?: ModelTypes['CreatorPreference'] | undefined
  }
  ['ModelCurrencyInput']: {
    eq?: ModelTypes['Currency'] | undefined
    ne?: ModelTypes['Currency'] | undefined
  }
  ['ModelDeranked_ReasonInput']: {
    eq?: ModelTypes['Deranked_Reason'] | undefined
    ne?: ModelTypes['Deranked_Reason'] | undefined
  }
  ['ModelDraftStatusInput']: {
    eq?: ModelTypes['DraftStatus'] | undefined
    ne?: ModelTypes['DraftStatus'] | undefined
  }
  ['ModelEnabledFlagInput']: {
    eq?: ModelTypes['EnabledFlag'] | undefined
    ne?: ModelTypes['EnabledFlag'] | undefined
  }
  ['ModelEtailerSkuMetricsConditionInput']: {
    and?: Array<ModelTypes['ModelEtailerSkuMetricsConditionInput'] | undefined> | undefined
    not?: ModelTypes['ModelEtailerSkuMetricsConditionInput'] | undefined
    or?: Array<ModelTypes['ModelEtailerSkuMetricsConditionInput'] | undefined> | undefined
    pageViews?: ModelTypes['ModelIntInput'] | undefined
    sku?: ModelTypes['ModelStringInput'] | undefined
    totalSalesAmount?: ModelTypes['ModelIntInput'] | undefined
    unitsSold?: ModelTypes['ModelIntInput'] | undefined
  }
  ['ModelEtailerSkuMetricsFilterInput']: {
    and?: Array<ModelTypes['ModelEtailerSkuMetricsFilterInput'] | undefined> | undefined
    date?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelEtailerSkuMetricsFilterInput'] | undefined
    or?: Array<ModelTypes['ModelEtailerSkuMetricsFilterInput'] | undefined> | undefined
    pageViews?: ModelTypes['ModelIntInput'] | undefined
    sku?: ModelTypes['ModelStringInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    totalSalesAmount?: ModelTypes['ModelIntInput'] | undefined
    unitsSold?: ModelTypes['ModelIntInput'] | undefined
  }
  ['ModelFavoriteFlagInput']: {
    eq?: ModelTypes['FavoriteFlag'] | undefined
    ne?: ModelTypes['FavoriteFlag'] | undefined
  }
  ['ModelFloatInput']: {
    attributeExists?: boolean | undefined
    attributeType?: ModelTypes['ModelAttributeTypes'] | undefined
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
    ne?: number | undefined
  }
  ['ModelFloatKeyConditionInput']: {
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
  }
  ['ModelFormSequenceConfigurationConditionInput']: {
    active?: ModelTypes['ModelActiveInput'] | undefined
    and?: Array<ModelTypes['ModelFormSequenceConfigurationConditionInput'] | undefined> | undefined
    not?: ModelTypes['ModelFormSequenceConfigurationConditionInput'] | undefined
    or?: Array<ModelTypes['ModelFormSequenceConfigurationConditionInput'] | undefined> | undefined
    sequenceName?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelFormSequenceConfigurationFilterInput']: {
    active?: ModelTypes['ModelActiveInput'] | undefined
    and?: Array<ModelTypes['ModelFormSequenceConfigurationFilterInput'] | undefined> | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelFormSequenceConfigurationFilterInput'] | undefined
    or?: Array<ModelTypes['ModelFormSequenceConfigurationFilterInput'] | undefined> | undefined
    sequenceName?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelFraudReasonInput']: {
    eq?: ModelTypes['FraudReason'] | undefined
    ne?: ModelTypes['FraudReason'] | undefined
  }
  ['ModelFraudReviewStatusInput']: {
    eq?: ModelTypes['FraudReviewStatus'] | undefined
    ne?: ModelTypes['FraudReviewStatus'] | undefined
  }
  ['ModelIDInput']: {
    attributeExists?: boolean | undefined
    attributeType?: ModelTypes['ModelAttributeTypes'] | undefined
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    contains?: string | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
    ne?: string | undefined
    notContains?: string | undefined
    size?: ModelTypes['ModelSizeInput'] | undefined
  }
  ['ModelIDKeyConditionInput']: {
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
  }
  ['ModelInfluencerApprovalFlagV2Input']: {
    eq?: ModelTypes['InfluencerApprovalFlagV2'] | undefined
    ne?: ModelTypes['InfluencerApprovalFlagV2'] | undefined
  }
  ['ModelInstagramStoryScreenConditionInput']: {
    and?: Array<ModelTypes['ModelInstagramStoryScreenConditionInput'] | undefined> | undefined
    exits?: ModelTypes['ModelIntInput'] | undefined
    expirationUnixTime?: ModelTypes['ModelIntInput'] | undefined
    externalId?: ModelTypes['ModelStringInput'] | undefined
    impressions?: ModelTypes['ModelIntInput'] | undefined
    instagramUserId?: ModelTypes['ModelIDInput'] | undefined
    mediaId?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelInstagramStoryScreenConditionInput'] | undefined
    or?: Array<ModelTypes['ModelInstagramStoryScreenConditionInput'] | undefined> | undefined
    postedAt?: ModelTypes['ModelStringInput'] | undefined
    postingDate?: ModelTypes['ModelStringInput'] | undefined
    reach?: ModelTypes['ModelIntInput'] | undefined
    replies?: ModelTypes['ModelIntInput'] | undefined
    s3Bucket?: ModelTypes['ModelStringInput'] | undefined
    tapsBack?: ModelTypes['ModelIntInput'] | undefined
    tapsForward?: ModelTypes['ModelIntInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelInstagramStoryScreenFilterInput']: {
    and?: Array<ModelTypes['ModelInstagramStoryScreenFilterInput'] | undefined> | undefined
    exits?: ModelTypes['ModelIntInput'] | undefined
    expirationUnixTime?: ModelTypes['ModelIntInput'] | undefined
    externalId?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    impressions?: ModelTypes['ModelIntInput'] | undefined
    instagramUserId?: ModelTypes['ModelIDInput'] | undefined
    mediaId?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelInstagramStoryScreenFilterInput'] | undefined
    or?: Array<ModelTypes['ModelInstagramStoryScreenFilterInput'] | undefined> | undefined
    postedAt?: ModelTypes['ModelStringInput'] | undefined
    postingDate?: ModelTypes['ModelStringInput'] | undefined
    reach?: ModelTypes['ModelIntInput'] | undefined
    replies?: ModelTypes['ModelIntInput'] | undefined
    s3Bucket?: ModelTypes['ModelStringInput'] | undefined
    tapsBack?: ModelTypes['ModelIntInput'] | undefined
    tapsForward?: ModelTypes['ModelIntInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelIntInput']: {
    attributeExists?: boolean | undefined
    attributeType?: ModelTypes['ModelAttributeTypes'] | undefined
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
    ne?: number | undefined
  }
  ['ModelIntKeyConditionInput']: {
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
  }
  ['ModelItemClassificationInput']: {
    eq?: ModelTypes['ItemClassification'] | undefined
    ne?: ModelTypes['ItemClassification'] | undefined
  }
  ['ModelKeywordAcceptanceResponseInput']: {
    eq?: ModelTypes['KeywordAcceptanceResponse'] | undefined
    ne?: ModelTypes['KeywordAcceptanceResponse'] | undefined
  }
  ['ModelLinkStorageTypeInput']: {
    eq?: ModelTypes['LinkStorageType'] | undefined
    ne?: ModelTypes['LinkStorageType'] | undefined
  }
  ['ModelListingFulfillmentTypeInput']: {
    eq?: ModelTypes['ListingFulfillmentType'] | undefined
    ne?: ModelTypes['ListingFulfillmentType'] | undefined
  }
  ['ModelManualUploadTypeInput']: {
    eq?: ModelTypes['ManualUploadType'] | undefined
    ne?: ModelTypes['ManualUploadType'] | undefined
  }
  ['ModelMessageConditionInput']: {
    and?: Array<ModelTypes['ModelMessageConditionInput'] | undefined> | undefined
    body?: ModelTypes['ModelStringInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelMessageConditionInput'] | undefined
    or?: Array<ModelTypes['ModelMessageConditionInput'] | undefined> | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    userHasSeen?: ModelTypes['ModelBooleanInput'] | undefined
  }
  ['ModelMessageFilterInput']: {
    and?: Array<ModelTypes['ModelMessageFilterInput'] | undefined> | undefined
    body?: ModelTypes['ModelStringInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelMessageFilterInput'] | undefined
    or?: Array<ModelTypes['ModelMessageFilterInput'] | undefined> | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    userHasSeen?: ModelTypes['ModelBooleanInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelNoteConditionInput']: {
    and?: Array<ModelTypes['ModelNoteConditionInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelNoteConditionInput'] | undefined
    note?: ModelTypes['ModelStringInput'] | undefined
    or?: Array<ModelTypes['ModelNoteConditionInput'] | undefined> | undefined
    parentId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelNoteFilterInput']: {
    and?: Array<ModelTypes['ModelNoteFilterInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelNoteFilterInput'] | undefined
    note?: ModelTypes['ModelStringInput'] | undefined
    or?: Array<ModelTypes['ModelNoteFilterInput'] | undefined> | undefined
    parentId?: ModelTypes['ModelIDInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelNotificationConfigurationConditionInput']: {
    and?: Array<ModelTypes['ModelNotificationConfigurationConditionInput'] | undefined> | undefined
    category?: ModelTypes['ModelStringInput'] | undefined
    description?: ModelTypes['ModelStringInput'] | undefined
    enabled?: ModelTypes['ModelBooleanInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelNotificationConfigurationConditionInput'] | undefined
    or?: Array<ModelTypes['ModelNotificationConfigurationConditionInput'] | undefined> | undefined
  }
  ['ModelNotificationConfigurationFilterInput']: {
    and?: Array<ModelTypes['ModelNotificationConfigurationFilterInput'] | undefined> | undefined
    category?: ModelTypes['ModelStringInput'] | undefined
    description?: ModelTypes['ModelStringInput'] | undefined
    enabled?: ModelTypes['ModelBooleanInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelNotificationConfigurationFilterInput'] | undefined
    or?: Array<ModelTypes['ModelNotificationConfigurationFilterInput'] | undefined> | undefined
  }
  ['ModelNotificationStatusInput']: {
    eq?: ModelTypes['NotificationStatus'] | undefined
    ne?: ModelTypes['NotificationStatus'] | undefined
  }
  ['ModelOrderIdValidationStatusInput']: {
    eq?: ModelTypes['OrderIdValidationStatus'] | undefined
    ne?: ModelTypes['OrderIdValidationStatus'] | undefined
  }
  ['ModelPayForPerformanceFlagInput']: {
    eq?: ModelTypes['PayForPerformanceFlag'] | undefined
    ne?: ModelTypes['PayForPerformanceFlag'] | undefined
  }
  ['ModelPaymentTermsTypeInput']: {
    eq?: ModelTypes['PaymentTermsType'] | undefined
    ne?: ModelTypes['PaymentTermsType'] | undefined
  }
  ['ModelPlatformInviteSourceInput']: {
    eq?: ModelTypes['PlatformInviteSource'] | undefined
    ne?: ModelTypes['PlatformInviteSource'] | undefined
  }
  ['ModelPlatformPriorityInput']: {
    eq?: ModelTypes['PlatformPriority'] | undefined
    ne?: ModelTypes['PlatformPriority'] | undefined
  }
  ['ModelPlatformSourceInput']: {
    eq?: ModelTypes['PlatformSource'] | undefined
    ne?: ModelTypes['PlatformSource'] | undefined
  }
  ['ModelPlatformStatusInput']: {
    eq?: ModelTypes['PlatformStatus'] | undefined
    ne?: ModelTypes['PlatformStatus'] | undefined
  }
  ['ModelPreApprovalStatusInput']: {
    eq?: ModelTypes['PreApprovalStatus'] | undefined
    ne?: ModelTypes['PreApprovalStatus'] | undefined
  }
  ['ModelProductBonusConfigSnapshotConditionInput']: {
    and?: Array<ModelTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined> | undefined
    closeoutSurveyBonus?: ModelTypes['ModelIntInput'] | undefined
    discoveryBonus?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined
    or?: Array<ModelTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined> | undefined
  }
  ['ModelProductBonusConfigSnapshotFilterInput']: {
    and?: Array<ModelTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined> | undefined
    closeoutSurveyBonus?: ModelTypes['ModelIntInput'] | undefined
    discoveryBonus?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined
    or?: Array<ModelTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined> | undefined
    productId?: ModelTypes['ModelIDInput'] | undefined
    snapshotAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelProductConditionInput']: {
    accountRequired?: ModelTypes['ModelBooleanInput'] | undefined
    active?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelProductConditionInput'] | undefined> | undefined
    autoApprovalSeatsAvailable?: ModelTypes['ModelIntInput'] | undefined
    autoApprovalStatusTiers?: ModelTypes['ModelStatusTierListInput'] | undefined
    automaticReviewRelease?: ModelTypes['ModelBooleanInput'] | undefined
    baseBonus?: ModelTypes['ModelIntInput'] | undefined
    brandAdvocateCount?: ModelTypes['ModelIntInput'] | undefined
    brandTalkingPoints?: ModelTypes['ModelStringInput'] | undefined
    buyOnlinePickupInStore?: ModelTypes['ModelBOPISValueInput'] | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    campaignStateMachine?: ModelTypes['ModelStringInput'] | undefined
    closeoutFormBonus?: ModelTypes['ModelIntInput'] | undefined
    contentApprovalLeadDays?: ModelTypes['ModelIntInput'] | undefined
    creatorCount?: ModelTypes['ModelIntInput'] | undefined
    creatorPreference?: ModelTypes['ModelCreatorPreferenceInput'] | undefined
    creatorPreferenceDetails?: ModelTypes['ModelStringInput'] | undefined
    customFtcHashtags?: ModelTypes['ModelStringInput'] | undefined
    customHashtags?: ModelTypes['ModelStringInput'] | undefined
    directPurchaseLink?: ModelTypes['ModelStringInput'] | undefined
    hideBrandContentApprovals?: ModelTypes['ModelBooleanInput'] | undefined
    instaHandle?: ModelTypes['ModelStringInput'] | undefined
    internalCreatorsStagingGuidance?: ModelTypes['ModelStringInput'] | undefined
    internalDescription?: ModelTypes['ModelStringInput'] | undefined
    internalName?: ModelTypes['ModelStringInput'] | undefined
    internalNotes?: ModelTypes['ModelStringInput'] | undefined
    isDirectListingPurchase?: ModelTypes['ModelBooleanInput'] | undefined
    isQualityRequirementsEnabled?: ModelTypes['ModelBooleanInput'] | undefined
    listingGuidance?: ModelTypes['ModelStringInput'] | undefined
    maxDiscoveryBonus?: ModelTypes['ModelIntInput'] | undefined
    minimumCreatorQualityScore?: ModelTypes['ModelIntInput'] | undefined
    nonRetailReviewLink?: ModelTypes['ModelStringInput'] | undefined
    nonRetailReviewWebsiteName?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelProductConditionInput'] | undefined
    or?: Array<ModelTypes['ModelProductConditionInput'] | undefined> | undefined
    payForClicksEnabled?: ModelTypes['ModelEnabledFlagInput'] | undefined
    payForClicksMinClicks?: ModelTypes['ModelIntInput'] | undefined
    postingDeadlineDaysRelativeToApproval?: ModelTypes['ModelIntInput'] | undefined
    productLinkId?: ModelTypes['ModelIDInput'] | undefined
    productPostingGuidance?: ModelTypes['ModelStringInput'] | undefined
    productPostingGuidanceDocument?: ModelTypes['ModelStringInput'] | undefined
    promoCode?: ModelTypes['ModelStringInput'] | undefined
    promoCodeGuidance?: ModelTypes['ModelStringInput'] | undefined
    promoCodeType?: ModelTypes['ModelPromoCodeTypeInput'] | undefined
    promoDiscountAmount?: ModelTypes['ModelFloatInput'] | undefined
    promoDiscountType?: ModelTypes['ModelPromoDiscountTypeInput'] | undefined
    promoEndDate?: ModelTypes['ModelStringInput'] | undefined
    promoLink?: ModelTypes['ModelStringInput'] | undefined
    promoStartDate?: ModelTypes['ModelStringInput'] | undefined
    promotionLinkId?: ModelTypes['ModelIDInput'] | undefined
    purchaseByPromoCode?: ModelTypes['ModelBooleanInput'] | undefined
    purchaseDiscountCode?: ModelTypes['ModelStringInput'] | undefined
    purchaseDiscountCodeDetails?: ModelTypes['ModelStringInput'] | undefined
    purchaseDiscountCodePercentage?: ModelTypes['ModelStringInput'] | undefined
    qualityLevel?: ModelTypes['ModelProductQualityLevelInput'] | undefined
    requestEarlyProductFeedback?: ModelTypes['ModelBooleanInput'] | undefined
    requestProductFeedback?: ModelTypes['ModelBooleanInput'] | undefined
    requiredProductLinkEnabled?: ModelTypes['ModelRequiredProductLinkFlagInput'] | undefined
    sequenceId?: ModelTypes['ModelIDInput'] | undefined
    shopifyPriceRule?: ModelTypes['ModelStringInput'] | undefined
    shopifyPromotionId?: ModelTypes['ModelStringInput'] | undefined
    shopifyPromotionType?: ModelTypes['ModelShopifyPromotionTypeInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    socialMediaExampleUrls?: ModelTypes['ModelStringInput'] | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: ModelTypes['ModelIntInput'] | undefined
    stagingRequirementType?: ModelTypes['ModelStagingRequirementTypeInput'] | undefined
    stagingSocialQualityRequirement?: ModelTypes['ModelSocialQualityInput'] | undefined
    tikTokHandle?: ModelTypes['ModelStringInput'] | undefined
    unitsPurchased?: ModelTypes['ModelIntInput'] | undefined
    validateProductFoundVariations?: ModelTypes['ModelStringInput'] | undefined
    visibleAt?: ModelTypes['ModelStringInput'] | undefined
    visibleEndsAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelProductFilterInput']: {
    accountRequired?: ModelTypes['ModelBooleanInput'] | undefined
    active?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelProductFilterInput'] | undefined> | undefined
    autoApprovalSeatsAvailable?: ModelTypes['ModelIntInput'] | undefined
    autoApprovalStatusTiers?: ModelTypes['ModelStatusTierListInput'] | undefined
    automaticReviewRelease?: ModelTypes['ModelBooleanInput'] | undefined
    baseBonus?: ModelTypes['ModelIntInput'] | undefined
    brandAdvocateCount?: ModelTypes['ModelIntInput'] | undefined
    brandTalkingPoints?: ModelTypes['ModelStringInput'] | undefined
    buyOnlinePickupInStore?: ModelTypes['ModelBOPISValueInput'] | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    campaignStateMachine?: ModelTypes['ModelStringInput'] | undefined
    closeoutFormBonus?: ModelTypes['ModelIntInput'] | undefined
    contentApprovalLeadDays?: ModelTypes['ModelIntInput'] | undefined
    creatorCount?: ModelTypes['ModelIntInput'] | undefined
    creatorPreference?: ModelTypes['ModelCreatorPreferenceInput'] | undefined
    creatorPreferenceDetails?: ModelTypes['ModelStringInput'] | undefined
    customFtcHashtags?: ModelTypes['ModelStringInput'] | undefined
    customHashtags?: ModelTypes['ModelStringInput'] | undefined
    directPurchaseLink?: ModelTypes['ModelStringInput'] | undefined
    hideBrandContentApprovals?: ModelTypes['ModelBooleanInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    instaHandle?: ModelTypes['ModelStringInput'] | undefined
    internalCreatorsStagingGuidance?: ModelTypes['ModelStringInput'] | undefined
    internalDescription?: ModelTypes['ModelStringInput'] | undefined
    internalName?: ModelTypes['ModelStringInput'] | undefined
    internalNotes?: ModelTypes['ModelStringInput'] | undefined
    isDirectListingPurchase?: ModelTypes['ModelBooleanInput'] | undefined
    isQualityRequirementsEnabled?: ModelTypes['ModelBooleanInput'] | undefined
    listingGuidance?: ModelTypes['ModelStringInput'] | undefined
    maxDiscoveryBonus?: ModelTypes['ModelIntInput'] | undefined
    minimumCreatorQualityScore?: ModelTypes['ModelIntInput'] | undefined
    nonRetailReviewLink?: ModelTypes['ModelStringInput'] | undefined
    nonRetailReviewWebsiteName?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelProductFilterInput'] | undefined
    or?: Array<ModelTypes['ModelProductFilterInput'] | undefined> | undefined
    payForClicksEnabled?: ModelTypes['ModelEnabledFlagInput'] | undefined
    payForClicksMinClicks?: ModelTypes['ModelIntInput'] | undefined
    postingDeadlineDaysRelativeToApproval?: ModelTypes['ModelIntInput'] | undefined
    productLinkId?: ModelTypes['ModelIDInput'] | undefined
    productPostingGuidance?: ModelTypes['ModelStringInput'] | undefined
    productPostingGuidanceDocument?: ModelTypes['ModelStringInput'] | undefined
    promoCode?: ModelTypes['ModelStringInput'] | undefined
    promoCodeGuidance?: ModelTypes['ModelStringInput'] | undefined
    promoCodeType?: ModelTypes['ModelPromoCodeTypeInput'] | undefined
    promoDiscountAmount?: ModelTypes['ModelFloatInput'] | undefined
    promoDiscountType?: ModelTypes['ModelPromoDiscountTypeInput'] | undefined
    promoEndDate?: ModelTypes['ModelStringInput'] | undefined
    promoLink?: ModelTypes['ModelStringInput'] | undefined
    promoStartDate?: ModelTypes['ModelStringInput'] | undefined
    promotionLinkId?: ModelTypes['ModelIDInput'] | undefined
    purchaseByPromoCode?: ModelTypes['ModelBooleanInput'] | undefined
    purchaseDiscountCode?: ModelTypes['ModelStringInput'] | undefined
    purchaseDiscountCodeDetails?: ModelTypes['ModelStringInput'] | undefined
    purchaseDiscountCodePercentage?: ModelTypes['ModelStringInput'] | undefined
    qualityLevel?: ModelTypes['ModelProductQualityLevelInput'] | undefined
    requestEarlyProductFeedback?: ModelTypes['ModelBooleanInput'] | undefined
    requestProductFeedback?: ModelTypes['ModelBooleanInput'] | undefined
    requiredProductLinkEnabled?: ModelTypes['ModelRequiredProductLinkFlagInput'] | undefined
    sequenceId?: ModelTypes['ModelIDInput'] | undefined
    shopifyPriceRule?: ModelTypes['ModelStringInput'] | undefined
    shopifyPromotionId?: ModelTypes['ModelStringInput'] | undefined
    shopifyPromotionType?: ModelTypes['ModelShopifyPromotionTypeInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    socialMediaExampleUrls?: ModelTypes['ModelStringInput'] | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: ModelTypes['ModelIntInput'] | undefined
    stagingRequirementType?: ModelTypes['ModelStagingRequirementTypeInput'] | undefined
    stagingSocialQualityRequirement?: ModelTypes['ModelSocialQualityInput'] | undefined
    tikTokHandle?: ModelTypes['ModelStringInput'] | undefined
    unitsPurchased?: ModelTypes['ModelIntInput'] | undefined
    validateProductFoundVariations?: ModelTypes['ModelStringInput'] | undefined
    visibleAt?: ModelTypes['ModelStringInput'] | undefined
    visibleEndsAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelProductKeywordCreationSourceInput']: {
    eq?: ModelTypes['ProductKeywordCreationSource'] | undefined
    ne?: ModelTypes['ProductKeywordCreationSource'] | undefined
  }
  ['ModelProductKeywordsConditionInput']: {
    adminPriority?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelProductKeywordsConditionInput'] | undefined> | undefined
    brandPriority?: ModelTypes['ModelIntInput'] | undefined
    brandResponse?: ModelTypes['ModelKeywordAcceptanceResponseInput'] | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    creationSource?: ModelTypes['ModelProductKeywordCreationSourceInput'] | undefined
    endDate?: ModelTypes['ModelStringInput'] | undefined
    heliumAccount?: ModelTypes['ModelStringInput'] | undefined
    heliumAsin?: ModelTypes['ModelStringInput'] | undefined
    heliumLink?: ModelTypes['ModelStringInput'] | undefined
    keyword?: ModelTypes['ModelStringInput'] | undefined
    monthlySearchVolume?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelProductKeywordsConditionInput'] | undefined
    or?: Array<ModelTypes['ModelProductKeywordsConditionInput'] | undefined> | undefined
    rank?: ModelTypes['ModelIntInput'] | undefined
    rankingLastSkippedAt?: ModelTypes['ModelStringInput'] | undefined
    rankingLastSkippedByUserId?: ModelTypes['ModelIDInput'] | undefined
    rankingLastUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    rankingLastUpdatedByUserId?: ModelTypes['ModelIDInput'] | undefined
    recommended?: ModelTypes['ModelBooleanInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    startDate?: ModelTypes['ModelStringInput'] | undefined
    usedForCampaign?: ModelTypes['ModelBooleanInput'] | undefined
    visible?: ModelTypes['ModelBooleanInput'] | undefined
    weight?: ModelTypes['ModelIntInput'] | undefined
  }
  ['ModelProductKeywordsFilterInput']: {
    adminPriority?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelProductKeywordsFilterInput'] | undefined> | undefined
    brandPriority?: ModelTypes['ModelIntInput'] | undefined
    brandResponse?: ModelTypes['ModelKeywordAcceptanceResponseInput'] | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    creationSource?: ModelTypes['ModelProductKeywordCreationSourceInput'] | undefined
    endDate?: ModelTypes['ModelStringInput'] | undefined
    heliumAccount?: ModelTypes['ModelStringInput'] | undefined
    heliumAsin?: ModelTypes['ModelStringInput'] | undefined
    heliumLink?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    keyword?: ModelTypes['ModelStringInput'] | undefined
    monthlySearchVolume?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelProductKeywordsFilterInput'] | undefined
    or?: Array<ModelTypes['ModelProductKeywordsFilterInput'] | undefined> | undefined
    rank?: ModelTypes['ModelIntInput'] | undefined
    rankingLastSkippedAt?: ModelTypes['ModelStringInput'] | undefined
    rankingLastSkippedByUserId?: ModelTypes['ModelIDInput'] | undefined
    rankingLastUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    rankingLastUpdatedByUserId?: ModelTypes['ModelIDInput'] | undefined
    recommended?: ModelTypes['ModelBooleanInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    startDate?: ModelTypes['ModelStringInput'] | undefined
    usedForCampaign?: ModelTypes['ModelBooleanInput'] | undefined
    visible?: ModelTypes['ModelBooleanInput'] | undefined
    weight?: ModelTypes['ModelIntInput'] | undefined
  }
  ['ModelProductQualityLevelInput']: {
    eq?: ModelTypes['ProductQualityLevel'] | undefined
    ne?: ModelTypes['ProductQualityLevel'] | undefined
  }
  ['ModelProductRatingSnapshotConditionInput']: {
    and?: Array<ModelTypes['ModelProductRatingSnapshotConditionInput'] | undefined> | undefined
    not?: ModelTypes['ModelProductRatingSnapshotConditionInput'] | undefined
    numFiveStar?: ModelTypes['ModelIntInput'] | undefined
    numFourStar?: ModelTypes['ModelIntInput'] | undefined
    numOneStar?: ModelTypes['ModelIntInput'] | undefined
    numRatings?: ModelTypes['ModelIntInput'] | undefined
    numReviews?: ModelTypes['ModelIntInput'] | undefined
    numThreeStar?: ModelTypes['ModelIntInput'] | undefined
    numTwoStar?: ModelTypes['ModelIntInput'] | undefined
    or?: Array<ModelTypes['ModelProductRatingSnapshotConditionInput'] | undefined> | undefined
    rating?: ModelTypes['ModelFloatInput'] | undefined
    sku?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelProductRatingSnapshotFilterInput']: {
    and?: Array<ModelTypes['ModelProductRatingSnapshotFilterInput'] | undefined> | undefined
    not?: ModelTypes['ModelProductRatingSnapshotFilterInput'] | undefined
    numFiveStar?: ModelTypes['ModelIntInput'] | undefined
    numFourStar?: ModelTypes['ModelIntInput'] | undefined
    numOneStar?: ModelTypes['ModelIntInput'] | undefined
    numRatings?: ModelTypes['ModelIntInput'] | undefined
    numReviews?: ModelTypes['ModelIntInput'] | undefined
    numThreeStar?: ModelTypes['ModelIntInput'] | undefined
    numTwoStar?: ModelTypes['ModelIntInput'] | undefined
    or?: Array<ModelTypes['ModelProductRatingSnapshotFilterInput'] | undefined> | undefined
    rating?: ModelTypes['ModelFloatInput'] | undefined
    sku?: ModelTypes['ModelStringInput'] | undefined
    skuId?: ModelTypes['ModelStringInput'] | undefined
    snapshotAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelPromoCodeTypeInput']: {
    eq?: ModelTypes['PromoCodeType'] | undefined
    ne?: ModelTypes['PromoCodeType'] | undefined
  }
  ['ModelPromoDiscountTypeInput']: {
    eq?: ModelTypes['PromoDiscountType'] | undefined
    ne?: ModelTypes['PromoDiscountType'] | undefined
  }
  ['ModelPurchaseConditionInput']: {
    amount?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelPurchaseConditionInput'] | undefined> | undefined
    cardId?: ModelTypes['ModelStringInput'] | undefined
    currency?: ModelTypes['ModelStringInput'] | undefined
    isFraud?: ModelTypes['ModelBooleanInput'] | undefined
    keywordAtPurchase?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelPurchaseConditionInput'] | undefined
    or?: Array<ModelTypes['ModelPurchaseConditionInput'] | undefined> | undefined
    purchasedAt?: ModelTypes['ModelStringInput'] | undefined
    shippedAt?: ModelTypes['ModelStringInput'] | undefined
    status?: ModelTypes['ModelPurchaseStatusInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelPurchaseFilterInput']: {
    amount?: ModelTypes['ModelIntInput'] | undefined
    and?: Array<ModelTypes['ModelPurchaseFilterInput'] | undefined> | undefined
    cardId?: ModelTypes['ModelStringInput'] | undefined
    currency?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    isFraud?: ModelTypes['ModelBooleanInput'] | undefined
    keywordAtPurchase?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelPurchaseFilterInput'] | undefined
    or?: Array<ModelTypes['ModelPurchaseFilterInput'] | undefined> | undefined
    purchasedAt?: ModelTypes['ModelStringInput'] | undefined
    shippedAt?: ModelTypes['ModelStringInput'] | undefined
    status?: ModelTypes['ModelPurchaseStatusInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelPurchaseStatusInput']: {
    eq?: ModelTypes['PurchaseStatus'] | undefined
    ne?: ModelTypes['PurchaseStatus'] | undefined
  }
  ['ModelReferralConditionInput']: {
    and?: Array<ModelTypes['ModelReferralConditionInput'] | undefined> | undefined
    bonusPayoutId?: ModelTypes['ModelIDInput'] | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelReferralConditionInput'] | undefined
    or?: Array<ModelTypes['ModelReferralConditionInput'] | undefined> | undefined
    referralType?: ModelTypes['ModelReferralTypeInput'] | undefined
    referredUserId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelReferralFilterInput']: {
    and?: Array<ModelTypes['ModelReferralFilterInput'] | undefined> | undefined
    bonusPayoutId?: ModelTypes['ModelIDInput'] | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelReferralFilterInput'] | undefined
    or?: Array<ModelTypes['ModelReferralFilterInput'] | undefined> | undefined
    referralType?: ModelTypes['ModelReferralTypeInput'] | undefined
    referredUserId?: ModelTypes['ModelIDInput'] | undefined
    referrerUserId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelReferralTypeInput']: {
    eq?: ModelTypes['ReferralType'] | undefined
    ne?: ModelTypes['ReferralType'] | undefined
  }
  ['ModelRequiredProductLinkFlagInput']: {
    eq?: ModelTypes['RequiredProductLinkFlag'] | undefined
    ne?: ModelTypes['RequiredProductLinkFlag'] | undefined
  }
  ['ModelReviewConditionInput']: {
    and?: Array<ModelTypes['ModelReviewConditionInput'] | undefined> | undefined
    attributedUserId?: ModelTypes['ModelStringInput'] | undefined
    author?: ModelTypes['ModelStringInput'] | undefined
    authorBadge?: ModelTypes['ModelStringInput'] | undefined
    badge?: ModelTypes['ModelStringInput'] | undefined
    commentCount?: ModelTypes['ModelIntInput'] | undefined
    helpfulReactions?: ModelTypes['ModelIntInput'] | undefined
    negativeReactions?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelReviewConditionInput'] | undefined
    or?: Array<ModelTypes['ModelReviewConditionInput'] | undefined> | undefined
    photos?: ModelTypes['ModelStringInput'] | undefined
    productId?: ModelTypes['ModelStringInput'] | undefined
    productVariant?: ModelTypes['ModelStringInput'] | undefined
    rating?: ModelTypes['ModelIntInput'] | undefined
    removed?: ModelTypes['ModelBooleanInput'] | undefined
    reviewedAt?: ModelTypes['ModelStringInput'] | undefined
    reviewedSku?: ModelTypes['ModelStringInput'] | undefined
    reviewerId?: ModelTypes['ModelStringInput'] | undefined
    sku?: ModelTypes['ModelStringInput'] | undefined
    skuId?: ModelTypes['ModelStringInput'] | undefined
    store?: ModelTypes['ModelStringInput'] | undefined
    text?: ModelTypes['ModelStringInput'] | undefined
    title?: ModelTypes['ModelStringInput'] | undefined
    userCampaignId?: ModelTypes['ModelStringInput'] | undefined
    videos?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelReviewFilterInput']: {
    and?: Array<ModelTypes['ModelReviewFilterInput'] | undefined> | undefined
    attributedUserId?: ModelTypes['ModelStringInput'] | undefined
    author?: ModelTypes['ModelStringInput'] | undefined
    authorBadge?: ModelTypes['ModelStringInput'] | undefined
    badge?: ModelTypes['ModelStringInput'] | undefined
    commentCount?: ModelTypes['ModelIntInput'] | undefined
    helpfulReactions?: ModelTypes['ModelIntInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    negativeReactions?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelReviewFilterInput'] | undefined
    or?: Array<ModelTypes['ModelReviewFilterInput'] | undefined> | undefined
    photos?: ModelTypes['ModelStringInput'] | undefined
    productId?: ModelTypes['ModelStringInput'] | undefined
    productVariant?: ModelTypes['ModelStringInput'] | undefined
    rating?: ModelTypes['ModelIntInput'] | undefined
    removed?: ModelTypes['ModelBooleanInput'] | undefined
    reviewedAt?: ModelTypes['ModelStringInput'] | undefined
    reviewedSku?: ModelTypes['ModelStringInput'] | undefined
    reviewerId?: ModelTypes['ModelStringInput'] | undefined
    sku?: ModelTypes['ModelStringInput'] | undefined
    skuId?: ModelTypes['ModelStringInput'] | undefined
    store?: ModelTypes['ModelStringInput'] | undefined
    text?: ModelTypes['ModelStringInput'] | undefined
    title?: ModelTypes['ModelStringInput'] | undefined
    userCampaignId?: ModelTypes['ModelStringInput'] | undefined
    videos?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelReviewScreenshotVerificationResultInput']: {
    eq?: ModelTypes['ReviewScreenshotVerificationResult'] | undefined
    ne?: ModelTypes['ReviewScreenshotVerificationResult'] | undefined
  }
  ['ModelRevocationTypeInput']: {
    eq?: ModelTypes['RevocationType'] | undefined
    ne?: ModelTypes['RevocationType'] | undefined
  }
  ['ModelScrapeStatusInput']: {
    eq?: ModelTypes['ScrapeStatus'] | undefined
    ne?: ModelTypes['ScrapeStatus'] | undefined
  }
  ['ModelSelfieVideoApprovalStatusInput']: {
    eq?: ModelTypes['SelfieVideoApprovalStatus'] | undefined
    ne?: ModelTypes['SelfieVideoApprovalStatus'] | undefined
  }
  ['ModelShopifyPromotionTypeInput']: {
    eq?: ModelTypes['ShopifyPromotionType'] | undefined
    ne?: ModelTypes['ShopifyPromotionType'] | undefined
  }
  ['ModelSizeInput']: {
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
    ne?: number | undefined
  }
  ['ModelSkuConditionInput']: {
    active?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelSkuConditionInput'] | undefined> | undefined
    approxRetailPrice?: ModelTypes['ModelStringInput'] | undefined
    approxShippingCost?: ModelTypes['ModelIntInput'] | undefined
    availabilityDate?: ModelTypes['ModelStringInput'] | undefined
    availabilityNotified?: ModelTypes['ModelBooleanInput'] | undefined
    availabilityNotifiedAt?: ModelTypes['ModelStringInput'] | undefined
    brandId?: ModelTypes['ModelIDInput'] | undefined
    brandListingKey?: ModelTypes['ModelStringInput'] | undefined
    brandStoreId?: ModelTypes['ModelStringInput'] | undefined
    bundle?: ModelTypes['ModelStringInput'] | undefined
    createdByUserId?: ModelTypes['ModelStringInput'] | undefined
    creationSource?: ModelTypes['ModelSkuCreationSourceInput'] | undefined
    creationSourceUrl?: ModelTypes['ModelStringInput'] | undefined
    currency?: ModelTypes['ModelCurrencyInput'] | undefined
    currentBuyBoxPrice?: ModelTypes['ModelIntInput'] | undefined
    currentBuyBoxPriceCents?: ModelTypes['ModelIntInput'] | undefined
    estimatedAvailabilityDate?: ModelTypes['ModelStringInput'] | undefined
    estimatedDeliveryDays?: ModelTypes['ModelIntInput'] | undefined
    estimatedMonthlyUnitsSold?: ModelTypes['ModelIntInput'] | undefined
    estimatedWeeklyUnitsSold?: ModelTypes['ModelIntInput'] | undefined
    exactListingTitle?: ModelTypes['ModelStringInput'] | undefined
    exactRetailPrice?: ModelTypes['ModelStringInput'] | undefined
    fullReviewScrapeComplete?: ModelTypes['ModelBooleanInput'] | undefined
    inventory?: ModelTypes['ModelIntInput'] | undefined
    iosAppProviderId?: ModelTypes['ModelStringInput'] | undefined
    isAplusPage?: ModelTypes['ModelStringInput'] | undefined
    isPrime?: ModelTypes['ModelStringInput'] | undefined
    isRecommendationsHidden?: ModelTypes['ModelBooleanInput'] | undefined
    itemClassification?: ModelTypes['ModelItemClassificationInput'] | undefined
    lastAutoFilledAt?: ModelTypes['ModelStringInput'] | undefined
    lastKnownBuyBoxPrice?: ModelTypes['ModelIntInput'] | undefined
    lastKnownBuyBoxPriceCents?: ModelTypes['ModelIntInput'] | undefined
    listingBrand?: ModelTypes['ModelStringInput'] | undefined
    listingCategory?: ModelTypes['ModelStringInput'] | undefined
    listingDescription?: ModelTypes['ModelStringInput'] | undefined
    listingFulfillmentType?: ModelTypes['ModelListingFulfillmentTypeInput'] | undefined
    listingGuidance?: ModelTypes['ModelStringInput'] | undefined
    listingLink?: ModelTypes['ModelStringInput'] | undefined
    mccValue?: ModelTypes['ModelValidMccCodesInput'] | undefined
    minimumOrderQuantity?: ModelTypes['ModelIntInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    nameAudioKey?: ModelTypes['ModelStringInput'] | undefined
    nonSponsoredListingKey?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelSkuConditionInput'] | undefined
    or?: Array<ModelTypes['ModelSkuConditionInput'] | undefined> | undefined
    parentSkuId?: ModelTypes['ModelIDInput'] | undefined
    priceCents?: ModelTypes['ModelIntInput'] | undefined
    product?: ModelTypes['ModelStringInput'] | undefined
    productImageKey?: ModelTypes['ModelStringInput'] | undefined
    productPostingGuidance?: ModelTypes['ModelStringInput'] | undefined
    productPostingGuidanceDocument?: ModelTypes['ModelStringInput'] | undefined
    productSearchImageKey?: ModelTypes['ModelStringInput'] | undefined
    releaseDate?: ModelTypes['ModelStringInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    sponsoredListingKey?: ModelTypes['ModelStringInput'] | undefined
    storeName?: ModelTypes['ModelStringInput'] | undefined
    targetReviewRateInvalid?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelSkuCreationSourceInput']: {
    eq?: ModelTypes['SkuCreationSource'] | undefined
    ne?: ModelTypes['SkuCreationSource'] | undefined
  }
  ['ModelSkuFilterInput']: {
    active?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelSkuFilterInput'] | undefined> | undefined
    approxRetailPrice?: ModelTypes['ModelStringInput'] | undefined
    approxShippingCost?: ModelTypes['ModelIntInput'] | undefined
    availabilityDate?: ModelTypes['ModelStringInput'] | undefined
    availabilityNotified?: ModelTypes['ModelBooleanInput'] | undefined
    availabilityNotifiedAt?: ModelTypes['ModelStringInput'] | undefined
    brandId?: ModelTypes['ModelIDInput'] | undefined
    brandListingKey?: ModelTypes['ModelStringInput'] | undefined
    brandStoreId?: ModelTypes['ModelStringInput'] | undefined
    bundle?: ModelTypes['ModelStringInput'] | undefined
    createdByUserId?: ModelTypes['ModelStringInput'] | undefined
    creationSource?: ModelTypes['ModelSkuCreationSourceInput'] | undefined
    creationSourceUrl?: ModelTypes['ModelStringInput'] | undefined
    currency?: ModelTypes['ModelCurrencyInput'] | undefined
    currentBuyBoxPrice?: ModelTypes['ModelIntInput'] | undefined
    currentBuyBoxPriceCents?: ModelTypes['ModelIntInput'] | undefined
    estimatedAvailabilityDate?: ModelTypes['ModelStringInput'] | undefined
    estimatedDeliveryDays?: ModelTypes['ModelIntInput'] | undefined
    estimatedMonthlyUnitsSold?: ModelTypes['ModelIntInput'] | undefined
    estimatedWeeklyUnitsSold?: ModelTypes['ModelIntInput'] | undefined
    exactListingTitle?: ModelTypes['ModelStringInput'] | undefined
    exactRetailPrice?: ModelTypes['ModelStringInput'] | undefined
    fullReviewScrapeComplete?: ModelTypes['ModelBooleanInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    inventory?: ModelTypes['ModelIntInput'] | undefined
    iosAppProviderId?: ModelTypes['ModelStringInput'] | undefined
    isAplusPage?: ModelTypes['ModelStringInput'] | undefined
    isPrime?: ModelTypes['ModelStringInput'] | undefined
    isRecommendationsHidden?: ModelTypes['ModelBooleanInput'] | undefined
    itemClassification?: ModelTypes['ModelItemClassificationInput'] | undefined
    lastAutoFilledAt?: ModelTypes['ModelStringInput'] | undefined
    lastKnownBuyBoxPrice?: ModelTypes['ModelIntInput'] | undefined
    lastKnownBuyBoxPriceCents?: ModelTypes['ModelIntInput'] | undefined
    listingBrand?: ModelTypes['ModelStringInput'] | undefined
    listingCategory?: ModelTypes['ModelStringInput'] | undefined
    listingDescription?: ModelTypes['ModelStringInput'] | undefined
    listingFulfillmentType?: ModelTypes['ModelListingFulfillmentTypeInput'] | undefined
    listingGuidance?: ModelTypes['ModelStringInput'] | undefined
    listingLink?: ModelTypes['ModelStringInput'] | undefined
    mccValue?: ModelTypes['ModelValidMccCodesInput'] | undefined
    minimumOrderQuantity?: ModelTypes['ModelIntInput'] | undefined
    name?: ModelTypes['ModelStringInput'] | undefined
    nameAudioKey?: ModelTypes['ModelStringInput'] | undefined
    nonSponsoredListingKey?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelSkuFilterInput'] | undefined
    or?: Array<ModelTypes['ModelSkuFilterInput'] | undefined> | undefined
    parentSkuId?: ModelTypes['ModelIDInput'] | undefined
    priceCents?: ModelTypes['ModelIntInput'] | undefined
    product?: ModelTypes['ModelStringInput'] | undefined
    productImageKey?: ModelTypes['ModelStringInput'] | undefined
    productPostingGuidance?: ModelTypes['ModelStringInput'] | undefined
    productPostingGuidanceDocument?: ModelTypes['ModelStringInput'] | undefined
    productSearchImageKey?: ModelTypes['ModelStringInput'] | undefined
    releaseDate?: ModelTypes['ModelStringInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    sponsoredListingKey?: ModelTypes['ModelStringInput'] | undefined
    storeName?: ModelTypes['ModelStringInput'] | undefined
    targetReviewRateInvalid?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelSkuSnapshotBySkuIdCompositeKeyConditionInput']: {
    beginsWith?: ModelTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    between?: Array<ModelTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined> | undefined
    eq?: ModelTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    ge?: ModelTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    gt?: ModelTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    le?: ModelTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    lt?: ModelTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
  }
  ['ModelSkuSnapshotBySkuIdCompositeKeyInput']: {
    marketplaceSku?: string | undefined
    snapshotDate?: string | undefined
  }
  ['ModelSkuSnapshotConditionInput']: {
    and?: Array<ModelTypes['ModelSkuSnapshotConditionInput'] | undefined> | undefined
    inventory?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelSkuSnapshotConditionInput'] | undefined
    or?: Array<ModelTypes['ModelSkuSnapshotConditionInput'] | undefined> | undefined
    price?: ModelTypes['ModelIntInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelSkuSnapshotFilterInput']: {
    and?: Array<ModelTypes['ModelSkuSnapshotFilterInput'] | undefined> | undefined
    inventory?: ModelTypes['ModelIntInput'] | undefined
    marketplaceSku?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelSkuSnapshotFilterInput'] | undefined
    or?: Array<ModelTypes['ModelSkuSnapshotFilterInput'] | undefined> | undefined
    price?: ModelTypes['ModelIntInput'] | undefined
    skuId?: ModelTypes['ModelIDInput'] | undefined
    snapshotDate?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelSocialChannelInput']: {
    eq?: ModelTypes['SocialChannel'] | undefined
    ne?: ModelTypes['SocialChannel'] | undefined
  }
  ['ModelSocialContentTypeInput']: {
    eq?: ModelTypes['SocialContentType'] | undefined
    ne?: ModelTypes['SocialContentType'] | undefined
  }
  ['ModelSocialQualityInput']: {
    eq?: ModelTypes['SocialQuality'] | undefined
    ne?: ModelTypes['SocialQuality'] | undefined
  }
  ['ModelSocialVerificationMethodInput']: {
    eq?: ModelTypes['SocialVerificationMethod'] | undefined
    ne?: ModelTypes['SocialVerificationMethod'] | undefined
  }
  ['ModelSourcePriorityInput']: {
    eq?: ModelTypes['SourcePriority'] | undefined
    ne?: ModelTypes['SourcePriority'] | undefined
  }
  ['ModelStagingApprovalOutcomeInput']: {
    eq?: ModelTypes['StagingApprovalOutcome'] | undefined
    ne?: ModelTypes['StagingApprovalOutcome'] | undefined
  }
  ['ModelStagingRequirementTypeInput']: {
    eq?: ModelTypes['StagingRequirementType'] | undefined
    ne?: ModelTypes['StagingRequirementType'] | undefined
  }
  ['ModelStatusTierInput']: {
    eq?: ModelTypes['StatusTier'] | undefined
    ne?: ModelTypes['StatusTier'] | undefined
  }
  ['ModelStatusTierListInput']: {
    contains?: ModelTypes['StatusTier'] | undefined
    eq?: Array<ModelTypes['StatusTier'] | undefined> | undefined
    ne?: Array<ModelTypes['StatusTier'] | undefined> | undefined
    notContains?: ModelTypes['StatusTier'] | undefined
  }
  ['ModelStringInput']: {
    attributeExists?: boolean | undefined
    attributeType?: ModelTypes['ModelAttributeTypes'] | undefined
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    contains?: string | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
    ne?: string | undefined
    notContains?: string | undefined
    size?: ModelTypes['ModelSizeInput'] | undefined
  }
  ['ModelStringKeyConditionInput']: {
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
  }
  ['ModelStripeRegionInput']: {
    eq?: ModelTypes['StripeRegion'] | undefined
    ne?: ModelTypes['StripeRegion'] | undefined
  }
  ['ModelSupportPriorityFlagInput']: {
    eq?: ModelTypes['SupportPriorityFlag'] | undefined
    ne?: ModelTypes['SupportPriorityFlag'] | undefined
  }
  ['ModelTrackingStatusInput']: {
    eq?: ModelTypes['TrackingStatus'] | undefined
    ne?: ModelTypes['TrackingStatus'] | undefined
  }
  ['ModelUserBonusPayoutConditionInput']: {
    adminPaymentApproved?: ModelTypes['ModelBooleanInput'] | undefined
    and?: Array<ModelTypes['ModelUserBonusPayoutConditionInput'] | undefined> | undefined
    bonusType?: ModelTypes['ModelBonusPayoutTypesInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    failureReason?: ModelTypes['ModelStringInput'] | undefined
    manuallyCreatedBy?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserBonusPayoutConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserBonusPayoutConditionInput'] | undefined> | undefined
    payoutAmount?: ModelTypes['ModelIntInput'] | undefined
    payoutAmountCents?: ModelTypes['ModelIntInput'] | undefined
    payoutBatchId?: ModelTypes['ModelStringInput'] | undefined
    payoutDate?: ModelTypes['ModelStringInput'] | undefined
    payoutLocale?: ModelTypes['ModelWSLocaleInput'] | undefined
    payoutState?: ModelTypes['ModelBonusPayoutStateInput'] | undefined
    quantity?: ModelTypes['ModelIntInput'] | undefined
    referenceId?: ModelTypes['ModelStringInput'] | undefined
    releaseAt?: ModelTypes['ModelStringInput'] | undefined
    revocationType?: ModelTypes['ModelRevocationTypeInput'] | undefined
    revokedAt?: ModelTypes['ModelStringInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserBonusPayoutFilterInput']: {
    adminPaymentApproved?: ModelTypes['ModelBooleanInput'] | undefined
    and?: Array<ModelTypes['ModelUserBonusPayoutFilterInput'] | undefined> | undefined
    bonusType?: ModelTypes['ModelBonusPayoutTypesInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    failureReason?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    manuallyCreatedBy?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserBonusPayoutFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserBonusPayoutFilterInput'] | undefined> | undefined
    payoutAmount?: ModelTypes['ModelIntInput'] | undefined
    payoutAmountCents?: ModelTypes['ModelIntInput'] | undefined
    payoutBatchId?: ModelTypes['ModelStringInput'] | undefined
    payoutDate?: ModelTypes['ModelStringInput'] | undefined
    payoutLocale?: ModelTypes['ModelWSLocaleInput'] | undefined
    payoutState?: ModelTypes['ModelBonusPayoutStateInput'] | undefined
    quantity?: ModelTypes['ModelIntInput'] | undefined
    referenceId?: ModelTypes['ModelStringInput'] | undefined
    releaseAt?: ModelTypes['ModelStringInput'] | undefined
    revocationType?: ModelTypes['ModelRevocationTypeInput'] | undefined
    revokedAt?: ModelTypes['ModelStringInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserCampaignAcceptanceStatusInput']: {
    eq?: ModelTypes['UserCampaignAcceptanceStatus'] | undefined
    ne?: ModelTypes['UserCampaignAcceptanceStatus'] | undefined
  }
  ['ModelUserCampaignApprovalStatusInput']: {
    eq?: ModelTypes['UserCampaignApprovalStatus'] | undefined
    ne?: ModelTypes['UserCampaignApprovalStatus'] | undefined
  }
  ['ModelUserCampaignChangelogConditionInput']: {
    and?: Array<ModelTypes['ModelUserCampaignChangelogConditionInput'] | undefined> | undefined
    createdByUserId?: ModelTypes['ModelIDInput'] | undefined
    entryType?: ModelTypes['ModelUserCampaignChangelogEntryTypeInput'] | undefined
    extraData?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserCampaignChangelogConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignChangelogConditionInput'] | undefined> | undefined
  }
  ['ModelUserCampaignChangelogEntryTypeInput']: {
    eq?: ModelTypes['UserCampaignChangelogEntryType'] | undefined
    ne?: ModelTypes['UserCampaignChangelogEntryType'] | undefined
  }
  ['ModelUserCampaignChangelogFilterInput']: {
    and?: Array<ModelTypes['ModelUserCampaignChangelogFilterInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    createdByUserId?: ModelTypes['ModelIDInput'] | undefined
    entryType?: ModelTypes['ModelUserCampaignChangelogEntryTypeInput'] | undefined
    extraData?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserCampaignChangelogFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignChangelogFilterInput'] | undefined> | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserCampaignConditionInput']: {
    accountScreenShot?: ModelTypes['ModelStringInput'] | undefined
    amznReviewLink?: ModelTypes['ModelStringInput'] | undefined
    amznReviewSubmissionStatus?: ModelTypes['ModelAmazonReviewSubmissionStatusInput'] | undefined
    and?: Array<ModelTypes['ModelUserCampaignConditionInput'] | undefined> | undefined
    applicationRejectedByUserId?: ModelTypes['ModelIDInput'] | undefined
    approvalOutcome?: ModelTypes['ModelApprovalOutcomeInput'] | undefined
    approvalStatus?: ModelTypes['ModelUserCampaignApprovalStatusInput'] | undefined
    archivedFeedback?: ModelTypes['ModelStringInput'] | undefined
    archivedReason?: ModelTypes['ModelArchivedReasonInput'] | undefined
    autoBuyApprovalDate?: ModelTypes['ModelStringInput'] | undefined
    autoBuyRevokeDate?: ModelTypes['ModelStringInput'] | undefined
    bestBuyReviewScreenShot?: ModelTypes['ModelStringInput'] | undefined
    campaignAcceptanceStatus?: ModelTypes['ModelUserCampaignAcceptanceStatusInput'] | undefined
    campaignApplication?: ModelTypes['ModelStringInput'] | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    campaignStatus?: ModelTypes['ModelCampaignStatusInput'] | undefined
    campaignStatusOnArchive?: ModelTypes['ModelStringInput'] | undefined
    cardId?: ModelTypes['ModelStringInput'] | undefined
    cardRegion?: ModelTypes['ModelStripeRegionInput'] | undefined
    closeoutSurvey?: ModelTypes['ModelStringInput'] | undefined
    coarseCampaignStatus?: ModelTypes['ModelCoarseCampaignStatusInput'] | undefined
    contentApprovalLeadDays?: ModelTypes['ModelIntInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    discoverySurvey?: ModelTypes['ModelStringInput'] | undefined
    influencerApprovalInviteFeedback?: ModelTypes['ModelStringInput'] | undefined
    influencerApprovalRejectFeedback?: ModelTypes['ModelStringInput'] | undefined
    invoiceKey?: ModelTypes['ModelStringInput'] | undefined
    isAutoApproved?: ModelTypes['ModelBooleanInput'] | undefined
    keywordAtPurchase?: ModelTypes['ModelStringInput'] | undefined
    keywordId?: ModelTypes['ModelIDInput'] | undefined
    keywordsV2Hash?: ModelTypes['ModelStringInput'] | undefined
    listingKey?: ModelTypes['ModelStringInput'] | undefined
    localeV1?: ModelTypes['ModelWSLocaleInput'] | undefined
    manualPurchase?: ModelTypes['ModelBooleanInput'] | undefined
    moderatorStagingApprovalAt?: ModelTypes['ModelStringInput'] | undefined
    moderatorStagingApprovalBy?: ModelTypes['ModelStringInput'] | undefined
    moderatorStagingApprovalOutcome?: ModelTypes['ModelStagingApprovalOutcomeInput'] | undefined
    not?: ModelTypes['ModelUserCampaignConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignConditionInput'] | undefined> | undefined
    orderId?: ModelTypes['ModelStringInput'] | undefined
    orderIdInvalidReason?: ModelTypes['ModelStringInput'] | undefined
    orderIdSubmissionDate?: ModelTypes['ModelStringInput'] | undefined
    orderIdValidationDate?: ModelTypes['ModelStringInput'] | undefined
    orderIdValidationStatus?: ModelTypes['ModelOrderIdValidationStatusInput'] | undefined
    orderStatus?: ModelTypes['ModelStringInput'] | undefined
    payForClickLinkId?: ModelTypes['ModelIDInput'] | undefined
    paypalEmailAtSubmission?: ModelTypes['ModelStringInput'] | undefined
    postingDeadline?: ModelTypes['ModelStringInput'] | undefined
    postingDeadlineExtended?: ModelTypes['ModelBooleanInput'] | undefined
    previousCardIds?: ModelTypes['ModelStringInput'] | undefined
    priority?: ModelTypes['ModelSourcePriorityInput'] | undefined
    productCriticalFeedback?: ModelTypes['ModelStringInput'] | undefined
    productId?: ModelTypes['ModelIDInput'] | undefined
    productPositiveFeedback?: ModelTypes['ModelStringInput'] | undefined
    rankPriority?: ModelTypes['ModelIntInput'] | undefined
    reviewScreenshot?: ModelTypes['ModelStringInput'] | undefined
    reviewScreenshotVerificationResult?: ModelTypes['ModelReviewScreenshotVerificationResultInput'] | undefined
    schedulingReleaseAt?: ModelTypes['ModelStringInput'] | undefined
    searchPhraseKey?: ModelTypes['ModelStringInput'] | undefined
    selectedFlow?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoApprovalDate?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoApprovalNotes?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoApprovalStatus?: ModelTypes['ModelSelfieVideoApprovalStatusInput'] | undefined
    selfieVideoKey?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoRejectionReason?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoSubmissionRequired?: ModelTypes['ModelBooleanInput'] | undefined
    selfieVideoSubmittedAt?: ModelTypes['ModelStringInput'] | undefined
    shippingAddress?: ModelTypes['ModelStringInput'] | undefined
    showEarlyReviewSurvey?: ModelTypes['ModelBooleanInput'] | undefined
    statusHistory?: ModelTypes['ModelStringInput'] | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
    walmartReviewScreenShot?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignFilterInput']: {
    accountScreenShot?: ModelTypes['ModelStringInput'] | undefined
    amznReviewLink?: ModelTypes['ModelStringInput'] | undefined
    amznReviewSubmissionStatus?: ModelTypes['ModelAmazonReviewSubmissionStatusInput'] | undefined
    and?: Array<ModelTypes['ModelUserCampaignFilterInput'] | undefined> | undefined
    applicationRejectedByUserId?: ModelTypes['ModelIDInput'] | undefined
    approvalOutcome?: ModelTypes['ModelApprovalOutcomeInput'] | undefined
    approvalStatus?: ModelTypes['ModelUserCampaignApprovalStatusInput'] | undefined
    archivedFeedback?: ModelTypes['ModelStringInput'] | undefined
    archivedReason?: ModelTypes['ModelArchivedReasonInput'] | undefined
    autoBuyApprovalDate?: ModelTypes['ModelStringInput'] | undefined
    autoBuyRevokeDate?: ModelTypes['ModelStringInput'] | undefined
    bestBuyReviewScreenShot?: ModelTypes['ModelStringInput'] | undefined
    campaignAcceptanceStatus?: ModelTypes['ModelUserCampaignAcceptanceStatusInput'] | undefined
    campaignApplication?: ModelTypes['ModelStringInput'] | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    campaignStatus?: ModelTypes['ModelCampaignStatusInput'] | undefined
    campaignStatusOnArchive?: ModelTypes['ModelStringInput'] | undefined
    cardId?: ModelTypes['ModelStringInput'] | undefined
    cardRegion?: ModelTypes['ModelStripeRegionInput'] | undefined
    closeoutSurvey?: ModelTypes['ModelStringInput'] | undefined
    coarseCampaignStatus?: ModelTypes['ModelCoarseCampaignStatusInput'] | undefined
    contentApprovalLeadDays?: ModelTypes['ModelIntInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    discoverySurvey?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    influencerApprovalInviteFeedback?: ModelTypes['ModelStringInput'] | undefined
    influencerApprovalRejectFeedback?: ModelTypes['ModelStringInput'] | undefined
    invoiceKey?: ModelTypes['ModelStringInput'] | undefined
    isAutoApproved?: ModelTypes['ModelBooleanInput'] | undefined
    keywordAtPurchase?: ModelTypes['ModelStringInput'] | undefined
    keywordId?: ModelTypes['ModelIDInput'] | undefined
    keywordsV2Hash?: ModelTypes['ModelStringInput'] | undefined
    listingKey?: ModelTypes['ModelStringInput'] | undefined
    localeV1?: ModelTypes['ModelWSLocaleInput'] | undefined
    manualPurchase?: ModelTypes['ModelBooleanInput'] | undefined
    moderatorStagingApprovalAt?: ModelTypes['ModelStringInput'] | undefined
    moderatorStagingApprovalBy?: ModelTypes['ModelStringInput'] | undefined
    moderatorStagingApprovalOutcome?: ModelTypes['ModelStagingApprovalOutcomeInput'] | undefined
    not?: ModelTypes['ModelUserCampaignFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignFilterInput'] | undefined> | undefined
    orderId?: ModelTypes['ModelStringInput'] | undefined
    orderIdInvalidReason?: ModelTypes['ModelStringInput'] | undefined
    orderIdSubmissionDate?: ModelTypes['ModelStringInput'] | undefined
    orderIdValidationDate?: ModelTypes['ModelStringInput'] | undefined
    orderIdValidationStatus?: ModelTypes['ModelOrderIdValidationStatusInput'] | undefined
    orderStatus?: ModelTypes['ModelStringInput'] | undefined
    payForClickLinkId?: ModelTypes['ModelIDInput'] | undefined
    paypalEmailAtSubmission?: ModelTypes['ModelStringInput'] | undefined
    postingDeadline?: ModelTypes['ModelStringInput'] | undefined
    postingDeadlineExtended?: ModelTypes['ModelBooleanInput'] | undefined
    previousCardIds?: ModelTypes['ModelStringInput'] | undefined
    priority?: ModelTypes['ModelSourcePriorityInput'] | undefined
    productCriticalFeedback?: ModelTypes['ModelStringInput'] | undefined
    productId?: ModelTypes['ModelIDInput'] | undefined
    productPositiveFeedback?: ModelTypes['ModelStringInput'] | undefined
    rankPriority?: ModelTypes['ModelIntInput'] | undefined
    reviewScreenshot?: ModelTypes['ModelStringInput'] | undefined
    reviewScreenshotVerificationResult?: ModelTypes['ModelReviewScreenshotVerificationResultInput'] | undefined
    schedulingReleaseAt?: ModelTypes['ModelStringInput'] | undefined
    searchPhraseKey?: ModelTypes['ModelStringInput'] | undefined
    selectedFlow?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoApprovalDate?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoApprovalNotes?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoApprovalStatus?: ModelTypes['ModelSelfieVideoApprovalStatusInput'] | undefined
    selfieVideoKey?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoRejectionReason?: ModelTypes['ModelStringInput'] | undefined
    selfieVideoSubmissionRequired?: ModelTypes['ModelBooleanInput'] | undefined
    selfieVideoSubmittedAt?: ModelTypes['ModelStringInput'] | undefined
    shippingAddress?: ModelTypes['ModelStringInput'] | undefined
    showEarlyReviewSurvey?: ModelTypes['ModelBooleanInput'] | undefined
    statusHistory?: ModelTypes['ModelStringInput'] | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
    walmartReviewScreenShot?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSnapshotConditionInput']: {
    and?: Array<ModelTypes['ModelUserCampaignSnapshotConditionInput'] | undefined> | undefined
    not?: ModelTypes['ModelUserCampaignSnapshotConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignSnapshotConditionInput'] | undefined> | undefined
    snapshot?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSnapshotFilterInput']: {
    and?: Array<ModelTypes['ModelUserCampaignSnapshotFilterInput'] | undefined> | undefined
    not?: ModelTypes['ModelUserCampaignSnapshotFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignSnapshotFilterInput'] | undefined> | undefined
    snapshot?: ModelTypes['ModelStringInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    userCampaignVersion?: ModelTypes['ModelIntInput'] | undefined
  }
  ['ModelUserCampaignSocialContentConditionInput']: {
    and?: Array<ModelTypes['ModelUserCampaignSocialContentConditionInput'] | undefined> | undefined
    approvalFeedback?: ModelTypes['ModelStringInput'] | undefined
    approvalStatus?: ModelTypes['ModelContentApprovalStatusInput'] | undefined
    approvedAt?: ModelTypes['ModelStringInput'] | undefined
    approverPWUserId?: ModelTypes['ModelStringInput'] | undefined
    approverUserId?: ModelTypes['ModelIDInput'] | undefined
    bonusEarned?: ModelTypes['ModelIntInput'] | undefined
    bonusOverride?: ModelTypes['ModelIntInput'] | undefined
    caption?: ModelTypes['ModelStringInput'] | undefined
    channel?: ModelTypes['ModelSocialChannelInput'] | undefined
    complianceReviewStatus?: ModelTypes['ModelContentComplianceReviewStatusInput'] | undefined
    complianceReviewUserId?: ModelTypes['ModelIDInput'] | undefined
    complianceReviewedAt?: ModelTypes['ModelStringInput'] | undefined
    contentType?: ModelTypes['ModelSocialContentTypeInput'] | undefined
    draftEnteredAdminApprovalAt?: ModelTypes['ModelStringInput'] | undefined
    draftEnteredBrandApprovalAt?: ModelTypes['ModelStringInput'] | undefined
    draftFeedback?: ModelTypes['ModelStringInput'] | undefined
    draftHistory?: ModelTypes['ModelStringInput'] | undefined
    draftMedia?: ModelTypes['ModelStringInput'] | undefined
    draftPreApprovedText?: ModelTypes['ModelStringInput'] | undefined
    draftQualityScore?: ModelTypes['ModelIntInput'] | undefined
    draftStatus?: ModelTypes['ModelDraftStatusInput'] | undefined
    draftSubmittedAt?: ModelTypes['ModelStringInput'] | undefined
    draftText?: ModelTypes['ModelStringInput'] | undefined
    earlySubmissionBonusEarnedCents?: ModelTypes['ModelIntInput'] | undefined
    experimentId?: ModelTypes['ModelStringInput'] | undefined
    externalUrl?: ModelTypes['ModelStringInput'] | undefined
    groupId?: ModelTypes['ModelStringInput'] | undefined
    hashtags?: ModelTypes['ModelStringInput'] | undefined
    instance?: ModelTypes['ModelIntInput'] | undefined
    isFavorite?: ModelTypes['ModelBooleanInput'] | undefined
    isHidden?: ModelTypes['ModelBooleanInput'] | undefined
    isPurchased?: ModelTypes['ModelBooleanInput'] | undefined
    isUnavailable?: ModelTypes['ModelBooleanInput'] | undefined
    manualUploadType?: ModelTypes['ModelManualUploadTypeInput'] | undefined
    mediaId?: ModelTypes['ModelStringInput'] | undefined
    mediaUrls?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserCampaignSocialContentConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignSocialContentConditionInput'] | undefined> | undefined
    paymentId?: ModelTypes['ModelStringInput'] | undefined
    postedAt?: ModelTypes['ModelStringInput'] | undefined
    qualityScore?: ModelTypes['ModelIntInput'] | undefined
    rejectionReason?: ModelTypes['ModelStringInput'] | undefined
    requestedSocialScript?: ModelTypes['ModelBooleanInput'] | undefined
    requirementLevel?: ModelTypes['ModelContentRequirementLevelInput'] | undefined
    scrapeStatus?: ModelTypes['ModelScrapeStatusInput'] | undefined
    unavailableAt?: ModelTypes['ModelStringInput'] | undefined
    usedRequestedSocialScript?: ModelTypes['ModelBooleanInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    videoEndTime?: ModelTypes['ModelStringInput'] | undefined
    videoStartTime?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSocialContentDraftHistoryConditionInput']: {
    and?: Array<ModelTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined> | undefined
    not?: ModelTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined> | undefined
    snapshot?: ModelTypes['ModelStringInput'] | undefined
    snapshotDate?: ModelTypes['ModelStringInput'] | undefined
    userCampaignSocialContentId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserCampaignSocialContentDraftHistoryFilterInput']: {
    and?: Array<ModelTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined> | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined> | undefined
    snapshot?: ModelTypes['ModelStringInput'] | undefined
    snapshotDate?: ModelTypes['ModelStringInput'] | undefined
    userCampaignSocialContentId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserCampaignSocialContentFilterInput']: {
    and?: Array<ModelTypes['ModelUserCampaignSocialContentFilterInput'] | undefined> | undefined
    approvalFeedback?: ModelTypes['ModelStringInput'] | undefined
    approvalStatus?: ModelTypes['ModelContentApprovalStatusInput'] | undefined
    approvedAt?: ModelTypes['ModelStringInput'] | undefined
    approverPWUserId?: ModelTypes['ModelStringInput'] | undefined
    approverUserId?: ModelTypes['ModelIDInput'] | undefined
    bonusEarned?: ModelTypes['ModelIntInput'] | undefined
    bonusOverride?: ModelTypes['ModelIntInput'] | undefined
    caption?: ModelTypes['ModelStringInput'] | undefined
    channel?: ModelTypes['ModelSocialChannelInput'] | undefined
    complianceReviewStatus?: ModelTypes['ModelContentComplianceReviewStatusInput'] | undefined
    complianceReviewUserId?: ModelTypes['ModelIDInput'] | undefined
    complianceReviewedAt?: ModelTypes['ModelStringInput'] | undefined
    contentType?: ModelTypes['ModelSocialContentTypeInput'] | undefined
    draftEnteredAdminApprovalAt?: ModelTypes['ModelStringInput'] | undefined
    draftEnteredBrandApprovalAt?: ModelTypes['ModelStringInput'] | undefined
    draftFeedback?: ModelTypes['ModelStringInput'] | undefined
    draftHistory?: ModelTypes['ModelStringInput'] | undefined
    draftMedia?: ModelTypes['ModelStringInput'] | undefined
    draftPreApprovedText?: ModelTypes['ModelStringInput'] | undefined
    draftQualityScore?: ModelTypes['ModelIntInput'] | undefined
    draftStatus?: ModelTypes['ModelDraftStatusInput'] | undefined
    draftSubmittedAt?: ModelTypes['ModelStringInput'] | undefined
    draftText?: ModelTypes['ModelStringInput'] | undefined
    earlySubmissionBonusEarnedCents?: ModelTypes['ModelIntInput'] | undefined
    experimentId?: ModelTypes['ModelStringInput'] | undefined
    externalUrl?: ModelTypes['ModelStringInput'] | undefined
    groupId?: ModelTypes['ModelStringInput'] | undefined
    hashtags?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    instance?: ModelTypes['ModelIntInput'] | undefined
    isFavorite?: ModelTypes['ModelBooleanInput'] | undefined
    isHidden?: ModelTypes['ModelBooleanInput'] | undefined
    isPurchased?: ModelTypes['ModelBooleanInput'] | undefined
    isUnavailable?: ModelTypes['ModelBooleanInput'] | undefined
    manualUploadType?: ModelTypes['ModelManualUploadTypeInput'] | undefined
    mediaId?: ModelTypes['ModelStringInput'] | undefined
    mediaUrls?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserCampaignSocialContentFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignSocialContentFilterInput'] | undefined> | undefined
    paymentId?: ModelTypes['ModelStringInput'] | undefined
    postedAt?: ModelTypes['ModelStringInput'] | undefined
    qualityScore?: ModelTypes['ModelIntInput'] | undefined
    rejectionReason?: ModelTypes['ModelStringInput'] | undefined
    requestedSocialScript?: ModelTypes['ModelBooleanInput'] | undefined
    requirementLevel?: ModelTypes['ModelContentRequirementLevelInput'] | undefined
    scrapeStatus?: ModelTypes['ModelScrapeStatusInput'] | undefined
    unavailableAt?: ModelTypes['ModelStringInput'] | undefined
    usedRequestedSocialScript?: ModelTypes['ModelBooleanInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    videoEndTime?: ModelTypes['ModelStringInput'] | undefined
    videoStartTime?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewConditionInput']: {
    and?: Array<ModelTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined> | undefined
    reviewOutcome?: ModelTypes['ModelUserCampaignSocialContentReviewOutcomeInput'] | undefined
    reviewStatus?: ModelTypes['ModelUserCampaignSocialContentReviewStatusInput'] | undefined
    reviewedAt?: ModelTypes['ModelStringInput'] | undefined
    reviewerAssigneeId?: ModelTypes['ModelStringInput'] | undefined
    reviewerFirstName?: ModelTypes['ModelStringInput'] | undefined
    reviewerId?: ModelTypes['ModelIDInput'] | undefined
    reviewerLastName?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewFilterInput']: {
    and?: Array<ModelTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined> | undefined
    reviewOutcome?: ModelTypes['ModelUserCampaignSocialContentReviewOutcomeInput'] | undefined
    reviewStatus?: ModelTypes['ModelUserCampaignSocialContentReviewStatusInput'] | undefined
    reviewType?: ModelTypes['ModelUserCampaignSocialContentReviewTypeInput'] | undefined
    reviewedAt?: ModelTypes['ModelStringInput'] | undefined
    reviewerAssigneeId?: ModelTypes['ModelStringInput'] | undefined
    reviewerFirstName?: ModelTypes['ModelStringInput'] | undefined
    reviewerId?: ModelTypes['ModelIDInput'] | undefined
    reviewerLastName?: ModelTypes['ModelStringInput'] | undefined
    userCampaignSocialContentId?: ModelTypes['ModelIDInput'] | undefined
    userId?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewOutcomeInput']: {
    eq?: ModelTypes['UserCampaignSocialContentReviewOutcome'] | undefined
    ne?: ModelTypes['UserCampaignSocialContentReviewOutcome'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewStatusInput']: {
    eq?: ModelTypes['UserCampaignSocialContentReviewStatus'] | undefined
    ne?: ModelTypes['UserCampaignSocialContentReviewStatus'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewTypeInput']: {
    eq?: ModelTypes['UserCampaignSocialContentReviewType'] | undefined
    ne?: ModelTypes['UserCampaignSocialContentReviewType'] | undefined
  }
  ['ModelUserConditionInput']: {
    active?: ModelTypes['ModelActiveInput'] | undefined
    addressComponents?: ModelTypes['ModelStringInput'] | undefined
    amznLastChecked?: ModelTypes['ModelStringInput'] | undefined
    amznLink?: ModelTypes['ModelStringInput'] | undefined
    amznPrime?: ModelTypes['ModelStringInput'] | undefined
    amznProfileId?: ModelTypes['ModelStringInput'] | undefined
    amznReviewerId?: ModelTypes['ModelStringInput'] | undefined
    amznVerified?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelUserConditionInput'] | undefined> | undefined
    attributedReviewCount?: ModelTypes['ModelIntInput'] | undefined
    blogWebsite?: ModelTypes['ModelStringInput'] | undefined
    brandContentRatingAvg?: ModelTypes['ModelFloatInput'] | undefined
    campaignAcceptanceRate?: ModelTypes['ModelIntInput'] | undefined
    campaignApprovalRate?: ModelTypes['ModelIntInput'] | undefined
    campaignCompletionRate?: ModelTypes['ModelIntInput'] | undefined
    campaignReviewRate?: ModelTypes['ModelIntInput'] | undefined
    cardProfileUpdates?: ModelTypes['ModelStringInput'] | undefined
    cardholderId?: ModelTypes['ModelStringInput'] | undefined
    cardholderRegion?: ModelTypes['ModelStripeRegionInput'] | undefined
    characteristicsUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    claimedReviewCount?: ModelTypes['ModelIntInput'] | undefined
    collisionSkuSet?: ModelTypes['ModelStringInput'] | undefined
    collisionSkuSetCalculatedAt?: ModelTypes['ModelStringInput'] | undefined
    completedLifestyleCampaignCount?: ModelTypes['ModelIntInput'] | undefined
    completedLiftCampaignCount?: ModelTypes['ModelIntInput'] | undefined
    completedSocialCampaignCount?: ModelTypes['ModelIntInput'] | undefined
    contentRatingCompletedAt?: ModelTypes['ModelStringInput'] | undefined
    contentRatingCompletionTimeMillis?: ModelTypes['ModelIntInput'] | undefined
    contentRatingInstagramCompletionTimeMillis?: ModelTypes['ModelIntInput'] | undefined
    contentRatingQualityScore?: ModelTypes['ModelIntInput'] | undefined
    contentRatingReviewStatus?: ModelTypes['ModelContentRatingReviewStatusInput'] | undefined
    contentRatingSubmittedBy?: ModelTypes['ModelStringInput'] | undefined
    contentRatingTiktokCompletionTimeMillis?: ModelTypes['ModelIntInput'] | undefined
    contentTypes?: ModelTypes['ModelStringInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    date_of_birth?: ModelTypes['ModelStringInput'] | undefined
    deactivationDate?: ModelTypes['ModelStringInput'] | undefined
    deactivationReason?: ModelTypes['ModelStringInput'] | undefined
    declineToReviewCount?: ModelTypes['ModelIntInput'] | undefined
    deranked?: ModelTypes['ModelDeranked_ReasonInput'] | undefined
    email?: ModelTypes['ModelStringInput'] | undefined
    emailOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    facebookAccessToken?: ModelTypes['ModelStringInput'] | undefined
    facebookAccessTokenExpiry?: ModelTypes['ModelStringInput'] | undefined
    facebookPageId?: ModelTypes['ModelStringInput'] | undefined
    facebookPageIsSubscribed?: ModelTypes['ModelBooleanInput'] | undefined
    facebookPermissionScopes?: ModelTypes['ModelStringInput'] | undefined
    facebookUserId?: ModelTypes['ModelStringInput'] | undefined
    firstName?: ModelTypes['ModelStringInput'] | undefined
    followsWesponsored?: ModelTypes['ModelStringInput'] | undefined
    fraudReason?: ModelTypes['ModelFraudReasonInput'] | undefined
    fraudReviewAt?: ModelTypes['ModelStringInput'] | undefined
    fraudReviewStatus?: ModelTypes['ModelFraudReviewStatusInput'] | undefined
    gender?: ModelTypes['ModelStringInput'] | undefined
    hasTrueEngagement?: ModelTypes['ModelBooleanInput'] | undefined
    idfaId?: ModelTypes['ModelStringInput'] | undefined
    incompleteCampaignCount?: ModelTypes['ModelIntInput'] | undefined
    instagramBio?: ModelTypes['ModelStringInput'] | undefined
    instagramBusinessCategoryName?: ModelTypes['ModelStringInput'] | undefined
    instagramCategoryName?: ModelTypes['ModelStringInput'] | undefined
    instagramChecked?: ModelTypes['ModelStringInput'] | undefined
    instagramContentTypeRating?: ModelTypes['ModelContentTypeRatingListInput'] | undefined
    instagramExternalLink?: ModelTypes['ModelStringInput'] | undefined
    instagramFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    instagramHandle?: ModelTypes['ModelStringInput'] | undefined
    instagramIsBusiness?: ModelTypes['ModelStringInput'] | undefined
    instagramIsPrivate?: ModelTypes['ModelStringInput'] | undefined
    instagramIsRecentlyJoined?: ModelTypes['ModelStringInput'] | undefined
    instagramIsVerified?: ModelTypes['ModelStringInput'] | undefined
    instagramLastChecked?: ModelTypes['ModelStringInput'] | undefined
    instagramMedianShares?: ModelTypes['ModelIntInput'] | undefined
    instagramMetricsRetrievedAt?: ModelTypes['ModelStringInput'] | undefined
    instagramName?: ModelTypes['ModelStringInput'] | undefined
    instagramPostsInt?: ModelTypes['ModelIntInput'] | undefined
    instagramPriority?: ModelTypes['ModelPlatformPriorityInput'] | undefined
    instagramProfilePic?: ModelTypes['ModelStringInput'] | undefined
    instagramTrailingComments?: ModelTypes['ModelIntInput'] | undefined
    instagramTrailingDateOfOldestPost?: ModelTypes['ModelStringInput'] | undefined
    instagramTrailingLikes?: ModelTypes['ModelIntInput'] | undefined
    instagramTrailingNumberOfPosts?: ModelTypes['ModelIntInput'] | undefined
    instagramTrailingPostEngagementAvg?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateAvg?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateMedian?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostImpressionsAvg?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostImpressionsMedian?: ModelTypes['ModelIntInput'] | undefined
    instagramTrailingPostSponsoredPercentage?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostViewsMedian?: ModelTypes['ModelIntInput'] | undefined
    instagramUserId?: ModelTypes['ModelStringInput'] | undefined
    instagramVerificationMethod?: ModelTypes['ModelSocialVerificationMethodInput'] | undefined
    internalGender?: ModelTypes['ModelUserGenderInput'] | undefined
    isBrandSafe?: ModelTypes['ModelBrandSafeInput'] | undefined
    isBrandSafeReason?: ModelTypes['ModelStringInput'] | undefined
    isBrandSafeUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    lastActiveAt?: ModelTypes['ModelStringInput'] | undefined
    lastName?: ModelTypes['ModelStringInput'] | undefined
    manualScoringLockedUntil?: ModelTypes['ModelStringInput'] | undefined
    moreinfo?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserConditionInput'] | undefined
    numEvenDollarPurchases?: ModelTypes['ModelIntInput'] | undefined
    numPurchases?: ModelTypes['ModelIntInput'] | undefined
    or?: Array<ModelTypes['ModelUserConditionInput'] | undefined> | undefined
    paypalEmail?: ModelTypes['ModelStringInput'] | undefined
    phoneNumber?: ModelTypes['ModelStringInput'] | undefined
    profilePicture?: ModelTypes['ModelStringInput'] | undefined
    pushOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    referralCode?: ModelTypes['ModelStringInput'] | undefined
    referralCount?: ModelTypes['ModelIntInput'] | undefined
    referrer?: ModelTypes['ModelStringInput'] | undefined
    signupCountry?: ModelTypes['ModelStringInput'] | undefined
    smsOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    socialQuality?: ModelTypes['ModelSocialQualityInput'] | undefined
    statusTier?: ModelTypes['ModelStatusTierInput'] | undefined
    statusTierLastUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    supportPriorityAcknowledgedAt?: ModelTypes['ModelStringInput'] | undefined
    supportPriorityFlag?: ModelTypes['ModelSupportPriorityFlagInput'] | undefined
    supportPriorityFlagSetAt?: ModelTypes['ModelStringInput'] | undefined
    targetAccount?: ModelTypes['ModelStringInput'] | undefined
    tiktokAccessToken?: ModelTypes['ModelStringInput'] | undefined
    tiktokAccessTokenExpiry?: ModelTypes['ModelStringInput'] | undefined
    tiktokAccountId?: ModelTypes['ModelStringInput'] | undefined
    tiktokAveragePostEngagementRate?: ModelTypes['ModelFloatInput'] | undefined
    tiktokAveragePostEngagementRateViews?: ModelTypes['ModelFloatInput'] | undefined
    tiktokBio?: ModelTypes['ModelStringInput'] | undefined
    tiktokContentTypeRating?: ModelTypes['ModelContentTypeRatingListInput'] | undefined
    tiktokFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    tiktokHandle?: ModelTypes['ModelStringInput'] | undefined
    tiktokMedianComments?: ModelTypes['ModelIntInput'] | undefined
    tiktokMedianLikes?: ModelTypes['ModelIntInput'] | undefined
    tiktokMedianPostEngagement?: ModelTypes['ModelFloatInput'] | undefined
    tiktokMedianPostEngagementRate?: ModelTypes['ModelFloatInput'] | undefined
    tiktokMedianPostEngagementRateViews?: ModelTypes['ModelFloatInput'] | undefined
    tiktokMedianShares?: ModelTypes['ModelIntInput'] | undefined
    tiktokMedianViews?: ModelTypes['ModelIntInput'] | undefined
    tiktokMetricsRetrievedAt?: ModelTypes['ModelStringInput'] | undefined
    tiktokName?: ModelTypes['ModelStringInput'] | undefined
    tiktokPermissionScopes?: ModelTypes['ModelStringInput'] | undefined
    tiktokPriority?: ModelTypes['ModelPlatformPriorityInput'] | undefined
    tiktokProfilePic?: ModelTypes['ModelStringInput'] | undefined
    tiktokRefreshToken?: ModelTypes['ModelStringInput'] | undefined
    tiktokRefreshTokenExpiry?: ModelTypes['ModelStringInput'] | undefined
    tiktokSponsoredPostPercentage?: ModelTypes['ModelFloatInput'] | undefined
    tiktokVerificationMethod?: ModelTypes['ModelSocialVerificationMethodInput'] | undefined
    tiktokVerified?: ModelTypes['ModelStringInput'] | undefined
    timeZone?: ModelTypes['ModelStringInput'] | undefined
    trackingStatus?: ModelTypes['ModelTrackingStatusInput'] | undefined
    twitchAccount?: ModelTypes['ModelStringInput'] | undefined
    type?: ModelTypes['ModelStringInput'] | undefined
    wmartAccount?: ModelTypes['ModelStringInput'] | undefined
    youtubeAccessToken?: ModelTypes['ModelStringInput'] | undefined
    youtubeAccessTokenExpiry?: ModelTypes['ModelStringInput'] | undefined
    youtubeAccount?: ModelTypes['ModelStringInput'] | undefined
    youtubeAveragePostEngagementRate?: ModelTypes['ModelFloatInput'] | undefined
    youtubeChannelDescription?: ModelTypes['ModelStringInput'] | undefined
    youtubeChannelFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    youtubeChannelHandle?: ModelTypes['ModelStringInput'] | undefined
    youtubeChannelId?: ModelTypes['ModelStringInput'] | undefined
    youtubeChannelPicture?: ModelTypes['ModelStringInput'] | undefined
    youtubeChannelTitle?: ModelTypes['ModelStringInput'] | undefined
    youtubeIdToken?: ModelTypes['ModelStringInput'] | undefined
    youtubeMedianViews?: ModelTypes['ModelIntInput'] | undefined
    youtubeMetricsRetrievedAt?: ModelTypes['ModelStringInput'] | undefined
    youtubePermissionScopes?: ModelTypes['ModelStringInput'] | undefined
    youtubeRefreshToken?: ModelTypes['ModelStringInput'] | undefined
    youtubeVerificationMethod?: ModelTypes['ModelSocialVerificationMethodInput'] | undefined
  }
  ['ModelUserDeviceSnapshotConditionInput']: {
    and?: Array<ModelTypes['ModelUserDeviceSnapshotConditionInput'] | undefined> | undefined
    idfv?: ModelTypes['ModelStringInput'] | undefined
    ipAddress?: ModelTypes['ModelStringInput'] | undefined
    isEmulator?: ModelTypes['ModelBooleanInput'] | undefined
    not?: ModelTypes['ModelUserDeviceSnapshotConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserDeviceSnapshotConditionInput'] | undefined> | undefined
    snapshotAt?: ModelTypes['ModelStringInput'] | undefined
    timezone?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserDeviceSnapshotFilterInput']: {
    and?: Array<ModelTypes['ModelUserDeviceSnapshotFilterInput'] | undefined> | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    idfv?: ModelTypes['ModelStringInput'] | undefined
    ipAddress?: ModelTypes['ModelStringInput'] | undefined
    isEmulator?: ModelTypes['ModelBooleanInput'] | undefined
    not?: ModelTypes['ModelUserDeviceSnapshotFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserDeviceSnapshotFilterInput'] | undefined> | undefined
    snapshotAt?: ModelTypes['ModelStringInput'] | undefined
    timezone?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserFilterGenderInput']: {
    eq?: ModelTypes['UserFilterGender'] | undefined
    ne?: ModelTypes['UserFilterGender'] | undefined
  }
  ['ModelUserFilterInput']: {
    active?: ModelTypes['ModelActiveInput'] | undefined
    addressComponents?: ModelTypes['ModelStringInput'] | undefined
    amznLastChecked?: ModelTypes['ModelStringInput'] | undefined
    amznLink?: ModelTypes['ModelStringInput'] | undefined
    amznPrime?: ModelTypes['ModelStringInput'] | undefined
    amznProfileId?: ModelTypes['ModelStringInput'] | undefined
    amznReviewerId?: ModelTypes['ModelStringInput'] | undefined
    amznVerified?: ModelTypes['ModelStringInput'] | undefined
    and?: Array<ModelTypes['ModelUserFilterInput'] | undefined> | undefined
    attributedReviewCount?: ModelTypes['ModelIntInput'] | undefined
    blogWebsite?: ModelTypes['ModelStringInput'] | undefined
    brandContentRatingAvg?: ModelTypes['ModelFloatInput'] | undefined
    campaignAcceptanceRate?: ModelTypes['ModelIntInput'] | undefined
    campaignApprovalRate?: ModelTypes['ModelIntInput'] | undefined
    campaignCompletionRate?: ModelTypes['ModelIntInput'] | undefined
    campaignReviewRate?: ModelTypes['ModelIntInput'] | undefined
    cardProfileUpdates?: ModelTypes['ModelStringInput'] | undefined
    cardholderId?: ModelTypes['ModelStringInput'] | undefined
    cardholderRegion?: ModelTypes['ModelStripeRegionInput'] | undefined
    characteristicsUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    claimedReviewCount?: ModelTypes['ModelIntInput'] | undefined
    collisionSkuSet?: ModelTypes['ModelStringInput'] | undefined
    collisionSkuSetCalculatedAt?: ModelTypes['ModelStringInput'] | undefined
    completedLifestyleCampaignCount?: ModelTypes['ModelIntInput'] | undefined
    completedLiftCampaignCount?: ModelTypes['ModelIntInput'] | undefined
    completedSocialCampaignCount?: ModelTypes['ModelIntInput'] | undefined
    contentRatingCompletedAt?: ModelTypes['ModelStringInput'] | undefined
    contentRatingCompletionTimeMillis?: ModelTypes['ModelIntInput'] | undefined
    contentRatingInstagramCompletionTimeMillis?: ModelTypes['ModelIntInput'] | undefined
    contentRatingQualityScore?: ModelTypes['ModelIntInput'] | undefined
    contentRatingReviewStatus?: ModelTypes['ModelContentRatingReviewStatusInput'] | undefined
    contentRatingSubmittedBy?: ModelTypes['ModelStringInput'] | undefined
    contentRatingTiktokCompletionTimeMillis?: ModelTypes['ModelIntInput'] | undefined
    contentTypes?: ModelTypes['ModelStringInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    date_of_birth?: ModelTypes['ModelStringInput'] | undefined
    deactivationDate?: ModelTypes['ModelStringInput'] | undefined
    deactivationReason?: ModelTypes['ModelStringInput'] | undefined
    declineToReviewCount?: ModelTypes['ModelIntInput'] | undefined
    deranked?: ModelTypes['ModelDeranked_ReasonInput'] | undefined
    email?: ModelTypes['ModelStringInput'] | undefined
    emailOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    facebookAccessToken?: ModelTypes['ModelStringInput'] | undefined
    facebookAccessTokenExpiry?: ModelTypes['ModelStringInput'] | undefined
    facebookPageId?: ModelTypes['ModelStringInput'] | undefined
    facebookPageIsSubscribed?: ModelTypes['ModelBooleanInput'] | undefined
    facebookPermissionScopes?: ModelTypes['ModelStringInput'] | undefined
    facebookUserId?: ModelTypes['ModelStringInput'] | undefined
    firstName?: ModelTypes['ModelStringInput'] | undefined
    followsWesponsored?: ModelTypes['ModelStringInput'] | undefined
    fraudReason?: ModelTypes['ModelFraudReasonInput'] | undefined
    fraudReviewAt?: ModelTypes['ModelStringInput'] | undefined
    fraudReviewStatus?: ModelTypes['ModelFraudReviewStatusInput'] | undefined
    gender?: ModelTypes['ModelStringInput'] | undefined
    hasTrueEngagement?: ModelTypes['ModelBooleanInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    idfaId?: ModelTypes['ModelStringInput'] | undefined
    incompleteCampaignCount?: ModelTypes['ModelIntInput'] | undefined
    instagramBio?: ModelTypes['ModelStringInput'] | undefined
    instagramBusinessCategoryName?: ModelTypes['ModelStringInput'] | undefined
    instagramCategoryName?: ModelTypes['ModelStringInput'] | undefined
    instagramChecked?: ModelTypes['ModelStringInput'] | undefined
    instagramContentTypeRating?: ModelTypes['ModelContentTypeRatingListInput'] | undefined
    instagramExternalLink?: ModelTypes['ModelStringInput'] | undefined
    instagramFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    instagramHandle?: ModelTypes['ModelStringInput'] | undefined
    instagramIsBusiness?: ModelTypes['ModelStringInput'] | undefined
    instagramIsPrivate?: ModelTypes['ModelStringInput'] | undefined
    instagramIsRecentlyJoined?: ModelTypes['ModelStringInput'] | undefined
    instagramIsVerified?: ModelTypes['ModelStringInput'] | undefined
    instagramLastChecked?: ModelTypes['ModelStringInput'] | undefined
    instagramMedianShares?: ModelTypes['ModelIntInput'] | undefined
    instagramMetricsRetrievedAt?: ModelTypes['ModelStringInput'] | undefined
    instagramName?: ModelTypes['ModelStringInput'] | undefined
    instagramPostsInt?: ModelTypes['ModelIntInput'] | undefined
    instagramPriority?: ModelTypes['ModelPlatformPriorityInput'] | undefined
    instagramProfilePic?: ModelTypes['ModelStringInput'] | undefined
    instagramTrailingComments?: ModelTypes['ModelIntInput'] | undefined
    instagramTrailingDateOfOldestPost?: ModelTypes['ModelStringInput'] | undefined
    instagramTrailingLikes?: ModelTypes['ModelIntInput'] | undefined
    instagramTrailingNumberOfPosts?: ModelTypes['ModelIntInput'] | undefined
    instagramTrailingPostEngagementAvg?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateAvg?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateMedian?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostImpressionsAvg?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostImpressionsMedian?: ModelTypes['ModelIntInput'] | undefined
    instagramTrailingPostSponsoredPercentage?: ModelTypes['ModelFloatInput'] | undefined
    instagramTrailingPostViewsMedian?: ModelTypes['ModelIntInput'] | undefined
    instagramUserId?: ModelTypes['ModelStringInput'] | undefined
    instagramVerificationMethod?: ModelTypes['ModelSocialVerificationMethodInput'] | undefined
    internalGender?: ModelTypes['ModelUserGenderInput'] | undefined
    isBrandSafe?: ModelTypes['ModelBrandSafeInput'] | undefined
    isBrandSafeReason?: ModelTypes['ModelStringInput'] | undefined
    isBrandSafeUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    lastActiveAt?: ModelTypes['ModelStringInput'] | undefined
    lastName?: ModelTypes['ModelStringInput'] | undefined
    manualScoringLockedUntil?: ModelTypes['ModelStringInput'] | undefined
    moreinfo?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserFilterInput'] | undefined
    numEvenDollarPurchases?: ModelTypes['ModelIntInput'] | undefined
    numPurchases?: ModelTypes['ModelIntInput'] | undefined
    or?: Array<ModelTypes['ModelUserFilterInput'] | undefined> | undefined
    paypalEmail?: ModelTypes['ModelStringInput'] | undefined
    phoneNumber?: ModelTypes['ModelStringInput'] | undefined
    profilePicture?: ModelTypes['ModelStringInput'] | undefined
    pushOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    referralCode?: ModelTypes['ModelStringInput'] | undefined
    referralCount?: ModelTypes['ModelIntInput'] | undefined
    referrer?: ModelTypes['ModelStringInput'] | undefined
    signupCountry?: ModelTypes['ModelStringInput'] | undefined
    smsOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    socialQuality?: ModelTypes['ModelSocialQualityInput'] | undefined
    statusTier?: ModelTypes['ModelStatusTierInput'] | undefined
    statusTierLastUpdatedAt?: ModelTypes['ModelStringInput'] | undefined
    supportPriorityAcknowledgedAt?: ModelTypes['ModelStringInput'] | undefined
    supportPriorityFlag?: ModelTypes['ModelSupportPriorityFlagInput'] | undefined
    supportPriorityFlagSetAt?: ModelTypes['ModelStringInput'] | undefined
    targetAccount?: ModelTypes['ModelStringInput'] | undefined
    tiktokAccessToken?: ModelTypes['ModelStringInput'] | undefined
    tiktokAccessTokenExpiry?: ModelTypes['ModelStringInput'] | undefined
    tiktokAccountId?: ModelTypes['ModelStringInput'] | undefined
    tiktokAveragePostEngagementRate?: ModelTypes['ModelFloatInput'] | undefined
    tiktokAveragePostEngagementRateViews?: ModelTypes['ModelFloatInput'] | undefined
    tiktokBio?: ModelTypes['ModelStringInput'] | undefined
    tiktokContentTypeRating?: ModelTypes['ModelContentTypeRatingListInput'] | undefined
    tiktokFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    tiktokHandle?: ModelTypes['ModelStringInput'] | undefined
    tiktokMedianComments?: ModelTypes['ModelIntInput'] | undefined
    tiktokMedianLikes?: ModelTypes['ModelIntInput'] | undefined
    tiktokMedianPostEngagement?: ModelTypes['ModelFloatInput'] | undefined
    tiktokMedianPostEngagementRate?: ModelTypes['ModelFloatInput'] | undefined
    tiktokMedianPostEngagementRateViews?: ModelTypes['ModelFloatInput'] | undefined
    tiktokMedianShares?: ModelTypes['ModelIntInput'] | undefined
    tiktokMedianViews?: ModelTypes['ModelIntInput'] | undefined
    tiktokMetricsRetrievedAt?: ModelTypes['ModelStringInput'] | undefined
    tiktokName?: ModelTypes['ModelStringInput'] | undefined
    tiktokPermissionScopes?: ModelTypes['ModelStringInput'] | undefined
    tiktokPriority?: ModelTypes['ModelPlatformPriorityInput'] | undefined
    tiktokProfilePic?: ModelTypes['ModelStringInput'] | undefined
    tiktokRefreshToken?: ModelTypes['ModelStringInput'] | undefined
    tiktokRefreshTokenExpiry?: ModelTypes['ModelStringInput'] | undefined
    tiktokSponsoredPostPercentage?: ModelTypes['ModelFloatInput'] | undefined
    tiktokVerificationMethod?: ModelTypes['ModelSocialVerificationMethodInput'] | undefined
    tiktokVerified?: ModelTypes['ModelStringInput'] | undefined
    timeZone?: ModelTypes['ModelStringInput'] | undefined
    trackingStatus?: ModelTypes['ModelTrackingStatusInput'] | undefined
    twitchAccount?: ModelTypes['ModelStringInput'] | undefined
    type?: ModelTypes['ModelStringInput'] | undefined
    wmartAccount?: ModelTypes['ModelStringInput'] | undefined
    youtubeAccessToken?: ModelTypes['ModelStringInput'] | undefined
    youtubeAccessTokenExpiry?: ModelTypes['ModelStringInput'] | undefined
    youtubeAccount?: ModelTypes['ModelStringInput'] | undefined
    youtubeAveragePostEngagementRate?: ModelTypes['ModelFloatInput'] | undefined
    youtubeChannelDescription?: ModelTypes['ModelStringInput'] | undefined
    youtubeChannelFollowersInt?: ModelTypes['ModelIntInput'] | undefined
    youtubeChannelHandle?: ModelTypes['ModelStringInput'] | undefined
    youtubeChannelId?: ModelTypes['ModelStringInput'] | undefined
    youtubeChannelPicture?: ModelTypes['ModelStringInput'] | undefined
    youtubeChannelTitle?: ModelTypes['ModelStringInput'] | undefined
    youtubeIdToken?: ModelTypes['ModelStringInput'] | undefined
    youtubeMedianViews?: ModelTypes['ModelIntInput'] | undefined
    youtubeMetricsRetrievedAt?: ModelTypes['ModelStringInput'] | undefined
    youtubePermissionScopes?: ModelTypes['ModelStringInput'] | undefined
    youtubeRefreshToken?: ModelTypes['ModelStringInput'] | undefined
    youtubeVerificationMethod?: ModelTypes['ModelSocialVerificationMethodInput'] | undefined
  }
  ['ModelUserFollowerConditionInput']: {
    and?: Array<ModelTypes['ModelUserFollowerConditionInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    isFavorite?: ModelTypes['ModelFavoriteFlagInput'] | undefined
    not?: ModelTypes['ModelUserFollowerConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserFollowerConditionInput'] | undefined> | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserFollowerFilterInput']: {
    and?: Array<ModelTypes['ModelUserFollowerFilterInput'] | undefined> | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    followerUserId?: ModelTypes['ModelIDInput'] | undefined
    isFavorite?: ModelTypes['ModelFavoriteFlagInput'] | undefined
    not?: ModelTypes['ModelUserFollowerFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserFollowerFilterInput'] | undefined> | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserGenderInput']: {
    eq?: ModelTypes['UserGender'] | undefined
    ne?: ModelTypes['UserGender'] | undefined
  }
  ['ModelUserLinkConditionInput']: {
    and?: Array<ModelTypes['ModelUserLinkConditionInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    linkId?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserLinkConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserLinkConditionInput'] | undefined> | undefined
    originalLink?: ModelTypes['ModelStringInput'] | undefined
    rank?: ModelTypes['ModelIntInput'] | undefined
    storageType?: ModelTypes['ModelLinkStorageTypeInput'] | undefined
    uniqueClicks?: ModelTypes['ModelIntInput'] | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
    userEncodedLink?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserLinkFilterInput']: {
    and?: Array<ModelTypes['ModelUserLinkFilterInput'] | undefined> | undefined
    campaignId?: ModelTypes['ModelIDInput'] | undefined
    createdAt?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    linkId?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserLinkFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserLinkFilterInput'] | undefined> | undefined
    originalLink?: ModelTypes['ModelStringInput'] | undefined
    rank?: ModelTypes['ModelIntInput'] | undefined
    storageType?: ModelTypes['ModelLinkStorageTypeInput'] | undefined
    uniqueClicks?: ModelTypes['ModelIntInput'] | undefined
    updatedAt?: ModelTypes['ModelStringInput'] | undefined
    userEncodedLink?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserNotificationPreferenceConditionInput']: {
    and?: Array<ModelTypes['ModelUserNotificationPreferenceConditionInput'] | undefined> | undefined
    emailOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    not?: ModelTypes['ModelUserNotificationPreferenceConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserNotificationPreferenceConditionInput'] | undefined> | undefined
    pushOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    smsOptOut?: ModelTypes['ModelBooleanInput'] | undefined
  }
  ['ModelUserNotificationPreferenceFilterInput']: {
    and?: Array<ModelTypes['ModelUserNotificationPreferenceFilterInput'] | undefined> | undefined
    category?: ModelTypes['ModelStringInput'] | undefined
    emailOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    not?: ModelTypes['ModelUserNotificationPreferenceFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserNotificationPreferenceFilterInput'] | undefined> | undefined
    pushOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    smsOptOut?: ModelTypes['ModelBooleanInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelUserPayoutRequestConditionInput']: {
    and?: Array<ModelTypes['ModelUserPayoutRequestConditionInput'] | undefined> | undefined
    failureReason?: ModelTypes['ModelStringInput'] | undefined
    manuallyApprovedBy?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserPayoutRequestConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserPayoutRequestConditionInput'] | undefined> | undefined
    payoutAmountCents?: ModelTypes['ModelIntInput'] | undefined
    payoutBatchId?: ModelTypes['ModelStringInput'] | undefined
    payoutDate?: ModelTypes['ModelStringInput'] | undefined
    payoutLocale?: ModelTypes['ModelWSLocaleInput'] | undefined
    payoutState?: ModelTypes['ModelBonusPayoutStateInput'] | undefined
  }
  ['ModelUserPayoutRequestFilterInput']: {
    and?: Array<ModelTypes['ModelUserPayoutRequestFilterInput'] | undefined> | undefined
    failureReason?: ModelTypes['ModelStringInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    manuallyApprovedBy?: ModelTypes['ModelStringInput'] | undefined
    not?: ModelTypes['ModelUserPayoutRequestFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserPayoutRequestFilterInput'] | undefined> | undefined
    payoutAmountCents?: ModelTypes['ModelIntInput'] | undefined
    payoutBatchId?: ModelTypes['ModelStringInput'] | undefined
    payoutDate?: ModelTypes['ModelStringInput'] | undefined
    payoutLocale?: ModelTypes['ModelWSLocaleInput'] | undefined
    payoutState?: ModelTypes['ModelBonusPayoutStateInput'] | undefined
    userId?: ModelTypes['ModelStringInput'] | undefined
  }
  ['ModelUserStagingApprovalConditionInput']: {
    and?: Array<ModelTypes['ModelUserStagingApprovalConditionInput'] | undefined> | undefined
    not?: ModelTypes['ModelUserStagingApprovalConditionInput'] | undefined
    or?: Array<ModelTypes['ModelUserStagingApprovalConditionInput'] | undefined> | undefined
    reviewFeedback?: ModelTypes['ModelStringInput'] | undefined
    reviewedAt?: ModelTypes['ModelStringInput'] | undefined
    reviewedBy?: ModelTypes['ModelStringInput'] | undefined
    reviewedByEmail?: ModelTypes['ModelStringInput'] | undefined
    reviewedByName?: ModelTypes['ModelStringInput'] | undefined
    reviewedBySource?: ModelTypes['ModelUserStagingApprovalReviewSourceInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    userStagingApprovalOutcome?: ModelTypes['ModelUserStagingApprovalOutcomeInput'] | undefined
    userStagingApprovalType?: ModelTypes['ModelUserStagingApprovalTypeInput'] | undefined
  }
  ['ModelUserStagingApprovalFilterInput']: {
    and?: Array<ModelTypes['ModelUserStagingApprovalFilterInput'] | undefined> | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelUserStagingApprovalFilterInput'] | undefined
    or?: Array<ModelTypes['ModelUserStagingApprovalFilterInput'] | undefined> | undefined
    reviewFeedback?: ModelTypes['ModelStringInput'] | undefined
    reviewedAt?: ModelTypes['ModelStringInput'] | undefined
    reviewedBy?: ModelTypes['ModelStringInput'] | undefined
    reviewedByEmail?: ModelTypes['ModelStringInput'] | undefined
    reviewedByName?: ModelTypes['ModelStringInput'] | undefined
    reviewedBySource?: ModelTypes['ModelUserStagingApprovalReviewSourceInput'] | undefined
    userCampaignId?: ModelTypes['ModelIDInput'] | undefined
    userStagingApprovalOutcome?: ModelTypes['ModelUserStagingApprovalOutcomeInput'] | undefined
    userStagingApprovalType?: ModelTypes['ModelUserStagingApprovalTypeInput'] | undefined
  }
  ['ModelUserStagingApprovalOutcomeInput']: {
    eq?: ModelTypes['UserStagingApprovalOutcome'] | undefined
    ne?: ModelTypes['UserStagingApprovalOutcome'] | undefined
  }
  ['ModelUserStagingApprovalReviewSourceInput']: {
    eq?: ModelTypes['UserStagingApprovalReviewSource'] | undefined
    ne?: ModelTypes['UserStagingApprovalReviewSource'] | undefined
  }
  ['ModelUserStagingApprovalTypeInput']: {
    eq?: ModelTypes['UserStagingApprovalType'] | undefined
    ne?: ModelTypes['UserStagingApprovalType'] | undefined
  }
  ['ModelValidMccCodesInput']: {
    eq?: ModelTypes['ValidMccCodes'] | undefined
    ne?: ModelTypes['ValidMccCodes'] | undefined
  }
  ['ModelVideoContentScanConditionInput']: {
    and?: Array<ModelTypes['ModelVideoContentScanConditionInput'] | undefined> | undefined
    audioDuration?: ModelTypes['ModelIntInput'] | undefined
    audioEndAt?: ModelTypes['ModelIntInput'] | undefined
    audioStartFrom?: ModelTypes['ModelIntInput'] | undefined
    not?: ModelTypes['ModelVideoContentScanConditionInput'] | undefined
    or?: Array<ModelTypes['ModelVideoContentScanConditionInput'] | undefined> | undefined
    processFinishedAt?: ModelTypes['ModelStringInput'] | undefined
    processId?: ModelTypes['ModelStringInput'] | undefined
    processInitiatedAt?: ModelTypes['ModelStringInput'] | undefined
    scanStatus?: ModelTypes['ModelContentScanStatusInput'] | undefined
    socialMediaUrl?: ModelTypes['ModelStringInput'] | undefined
    transcription?: ModelTypes['ModelStringInput'] | undefined
    transcriptionConfidence?: ModelTypes['ModelFloatInput'] | undefined
    url?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelVideoContentScanFilterInput']: {
    and?: Array<ModelTypes['ModelVideoContentScanFilterInput'] | undefined> | undefined
    audioDuration?: ModelTypes['ModelIntInput'] | undefined
    audioEndAt?: ModelTypes['ModelIntInput'] | undefined
    audioStartFrom?: ModelTypes['ModelIntInput'] | undefined
    id?: ModelTypes['ModelIDInput'] | undefined
    not?: ModelTypes['ModelVideoContentScanFilterInput'] | undefined
    or?: Array<ModelTypes['ModelVideoContentScanFilterInput'] | undefined> | undefined
    processFinishedAt?: ModelTypes['ModelStringInput'] | undefined
    processId?: ModelTypes['ModelStringInput'] | undefined
    processInitiatedAt?: ModelTypes['ModelStringInput'] | undefined
    scanStatus?: ModelTypes['ModelContentScanStatusInput'] | undefined
    socialMediaUrl?: ModelTypes['ModelStringInput'] | undefined
    transcription?: ModelTypes['ModelStringInput'] | undefined
    transcriptionConfidence?: ModelTypes['ModelFloatInput'] | undefined
    url?: ModelTypes['ModelStringInput'] | undefined
    userId?: ModelTypes['ModelIDInput'] | undefined
  }
  ['ModelWSLocaleInput']: {
    eq?: ModelTypes['WSLocale'] | undefined
    ne?: ModelTypes['WSLocale'] | undefined
  }
  ['OccurrenceConstraintInput']: {
    period?: ModelTypes['OccurrenceConstraintPeriod'] | undefined
    type: ModelTypes['OccurrenceConstraintType']
    value?: number | undefined
  }
  ['PayForImpressionsBonusConfigInput']: {
    enabled?: boolean | undefined
    growthRate?: number | undefined
    impressionsBaseline?: number | undefined
    impressionsCap?: number | undefined
    impressionsIncrement?: number | undefined
    initialBonus?: number | undefined
  }
  ['PayForPostingContentEarlyConfigInput']: {
    bonusCents?: number | undefined
    daysBeforeDeadline?: number | undefined
    enabled?: boolean | undefined
  }
  ['ProductSocialContentInput']: {
    bonus: number
    brandContentGuidance?: Array<ModelTypes['ContentGuidanceInput']> | undefined
    brandContentRequirements?: Array<string> | undefined
    bronzeBonus?: number | undefined
    channel: ModelTypes['SocialChannel']
    diamondBonus?: number | undefined
    goldBonus?: number | undefined
    inspirationalContent?: Array<ModelTypes['InspirationalContentInput']> | undefined
    instances: number
    platinumBonus?: number | undefined
    requirementLevel: ModelTypes['ContentRequirementLevel']
    requiresManualApproval?: boolean | undefined
    silverBonus?: number | undefined
    talkingPoints?: Array<ModelTypes['ContentGuidanceInput']> | undefined
    totalExpectedCount?: number | undefined
    type: ModelTypes['SocialContentType']
  }
  ['ProspectSearchFields']: {
    sourcingSource?: ModelTypes['SearchInputString'] | undefined
  }
  ['PushAddressInput']: {
    address?: string | undefined
    pushChannel?: ModelTypes['PushChannel'] | undefined
  }
  ['PushChannelConfigurationInput']: {
    attributes?: Array<ModelTypes['KeyAttributeInput'] | undefined> | undefined
    enabled?: boolean | undefined
    templateId: string
  }
  ['RankPointInput']: {
    rank: number
    rankAt: string
  }
  ['RateInput']: {
    count?: number | undefined
    isIntegrationFallbackRate?: boolean | undefined
    period?: ModelTypes['Period'] | undefined
  }
  ['RequestedContentChangesInput']: {
    message?: string | undefined
    rating?: number | undefined
  }
  ['RollbackAdditionalArguments']: {
    removeApprovals?: boolean | undefined
    removeBonuses?: boolean | undefined
    removeCard?: boolean | undefined
    removeContent?: boolean | undefined
    removeKeyword?: boolean | undefined
    resetBonusConfig?: boolean | undefined
  }
  ['SearchInputBoolean']: {
    match?: boolean | undefined
  }
  ['SearchInputNumber']: {
    field?: ModelTypes['SearchInputField'] | undefined
    match?: number | undefined
    not?: boolean | undefined
    range?: ModelTypes['SearchInputNumberRange'] | undefined
  }
  ['SearchInputNumberRange']: {
    gt?: number | undefined
    gte?: number | undefined
    lt?: number | undefined
    lte?: number | undefined
  }
  ['SearchInputQueryScript']: {
    params?: ModelTypes['SearchInputScriptParams'] | undefined
    source: ModelTypes['SearchInputQueryScriptSource']
  }
  ['SearchInputScriptParams']: {
    fieldNames?: Array<string> | undefined
    max?: number | undefined
    min?: number | undefined
  }
  ['SearchInputSortScript']: {
    params?: ModelTypes['SearchInputScriptParams'] | undefined
    source: ModelTypes['SearchInputSortScriptSource']
    type: ModelTypes['SearchInputScriptType']
  }
  ['SearchInputString']: {
    field?: ModelTypes['SearchInputField'] | undefined
    match?: string | undefined
    match_phrase_prefix?: string | undefined
    not?: boolean | undefined
    prefix?: string | undefined
    range?: ModelTypes['SearchInputStringRange'] | undefined
    regexp?: string | undefined
    wildcard?: string | undefined
  }
  ['SearchInputStringRange']: {
    gt?: string | undefined
    gte?: string | undefined
    lt?: string | undefined
    lte?: string | undefined
  }
  ['SequenceFormSubmissionValuesInput']: {
    componentId: string
    componentName: string
    componentType?: ModelTypes['FormComponentType'] | undefined
    sequenceId: string
    type?: ModelTypes['FormFieldType'] | undefined
    value: string
  }
  ['SkuAttributeInput']: {
    name: string
    value: string
  }
  ['SocialMediaFeedbackInput']: {
    comments?: string | undefined
    rating?: number | undefined
  }
  ['SocialMetricInput']: {
    name: ModelTypes['SocialMetricName']
    value: number
  }
  ['StagingApprovalConfigurationInput']: {
    stagingApprovalAssignee?: string | undefined
    stagingApprovalType: ModelTypes['UserStagingApprovalType']
  }
  ['StatusTierBonusConfigInput']: {
    baseBonus?: number | undefined
    diamondBonus?: number | undefined
    goldBonus?: number | undefined
    platinumBonus?: number | undefined
    silverBonus?: number | undefined
  }
  ['StatusTierEntryInput']: {
    statusTier: ModelTypes['StatusTier']
    statusTierAt: string
  }
  ['SubmitUserCampaignStateChangeInput']: {
    bonusRequest?: ModelTypes['BonusRequestTypes'] | undefined
    campaignStatus?: ModelTypes['CampaignStatus'] | undefined
    defect?: boolean | undefined
    id: string
    requestedByUserId?: string | undefined
    requestedUpdates?: string | undefined
    stateChangeRequestType?: ModelTypes['UserCampaignStateChangeRequestType'] | undefined
  }
  ['UpdateAutoBuyDistributionInput']: {
    createdAt?: string | undefined
    description?: string | undefined
    id: string
    name?: string | undefined
    schedule?: Array<number> | undefined
    updatedAt?: string | undefined
  }
  ['UpdateAutoBuyFlowConfigurationInput']: {
    dailyPurchaseMax?: number | undefined
    delayStrategy?: ModelTypes['AutoBuyDelayStrategy'] | undefined
    flowId: string
    insufficientCreators?: string | undefined
    latestSkipReason?: ModelTypes['AutoBuySkipReason'] | undefined
    pauseReason?: ModelTypes['AutoBuySkipReason'] | undefined
    schedule?: Array<ModelTypes['AutoBuyScheduleInput']> | undefined
    startDate?: string | undefined
    status?: ModelTypes['AutoBuyStatus'] | undefined
  }
  ['UpdateAutoBuyFlowReleaseScheduleInput']: {
    actualPurchases?: number | undefined
    actualVerifiedPurchases?: number | undefined
    adjustedDesiredPurchases?: number | undefined
    autoApprovalSeats?: number | undefined
    date: string
    desiredPurchases?: number | undefined
    flowId: string
    manualPurchases?: number | undefined
    manualReleases?: number | undefined
    projectedReleaseCount?: number | undefined
    releaseCount?: number | undefined
    released?: boolean | undefined
    skippedReason?: ModelTypes['AutoBuySkipReason'] | undefined
  }
  ['UpdateAutoBuyReleaseInput']: {
    date: string
    productId?: string | undefined
    releaseDateTime?: string | undefined
    skuId?: string | undefined
    userCampaignId: string
  }
  ['UpdateBrandApiInput']: {
    accessToken?: string | undefined
    api: ModelTypes['BrandApiType']
    apiVersion?: ModelTypes['BrandApiVersion'] | undefined
    brandId: string
    enabled?: boolean | undefined
    locale?: ModelTypes['WSLocale'] | undefined
    refreshToken?: string | undefined
    shop?: string | undefined
    shopId?: string | undefined
  }
  ['UpdateBrandInput']: {
    active?: string | undefined
    amazonBrandName?: string | undefined
    brandDetailsSubmitted?: boolean | undefined
    brandInstaHandle?: string | undefined
    brandLogoImageKey?: string | undefined
    brandStoreConfirmationImage?: string | undefined
    brandStoreConfirmationMobileImage?: string | undefined
    brandStoreScrapeUrl?: string | undefined
    brandTikTokHandle?: string | undefined
    companyId?: string | undefined
    contactEmails?: Array<string | undefined> | undefined
    defaultCreatorPreference?: ModelTypes['CreatorPreference'] | undefined
    defaultCreatorPreferenceDetails?: string | undefined
    defaultHashtags?: Array<string> | undefined
    defaultMarketingBriefKey?: string | undefined
    defaultPostingGuidelines?: Array<string> | undefined
    defaultPostingInspirationLinks?: Array<string> | undefined
    emailsSent?: Array<string | undefined> | undefined
    fullScrapedAt?: string | undefined
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined
    isSeller?: string | undefined
    isVendor?: string | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    lastScrapedAt?: string | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    paymentBillingContact?: ModelTypes['BillingContactInput'] | undefined
    platform?: ModelTypes['PlatformSource'] | undefined
    postingGuidanceDocument?: string | undefined
    region?: ModelTypes['WSLocale'] | undefined
    socialMediaExampleUrls?: Array<string> | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    walmartBrandName?: string | undefined
    walmartLastScrapedAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['UpdateCampaignClicksInput']: {
    campaignId: string
    clicks?: number | undefined
    date: string
  }
  ['UpdateCampaignInput']: {
    aboutSection?: string | undefined
    active?: string | undefined
    actualStartDate?: string | undefined
    amountPaidByClient?: number | undefined
    anticipatedStartDate?: string | undefined
    applicationForm?: string | undefined
    applicationProductLinkId?: string | undefined
    applicationStatus?: ModelTypes['ApplicationStatus'] | undefined
    attributionRefUrlSuffix?: string | undefined
    authorizedUnits?: number | undefined
    badges?: Array<ModelTypes['CampaignBadgeInput'] | undefined> | undefined
    brandId?: string | undefined
    brandProductShort?: string | undefined
    brandStoreLink?: string | undefined
    budgetSpent?: number | undefined
    campaignProductType?: ModelTypes['CampaignProductType'] | undefined
    campaignReferralBonusAmount?: number | undefined
    campaignReferralBonusEnabled?: ModelTypes['CampaignReferralBonusFlag'] | undefined
    campaignStateMachine?: string | undefined
    closeoutReleaseFailureReason?: string | undefined
    closeoutReleaseRate?: ModelTypes['RateInput'] | undefined
    collisionSkuDensity?: Array<ModelTypes['CollisionDensityValueInput']> | undefined
    commissionFlatRate?: number | undefined
    commissionPercentageRate?: number | undefined
    committedClicks?: number | undefined
    committedPlacementWinsCount?: number | undefined
    committedReviewsCount?: number | undefined
    contentApprovalRequired?: boolean | undefined
    contentCompleteDeadline?: string | undefined
    createdAt?: string | undefined
    creationSourceVersion?: ModelTypes['CreationSourceVersion'] | undefined
    earlyReviewEnabled?: boolean | undefined
    enableContentComplianceReview?: boolean | undefined
    endDate?: string | undefined
    excludeGender?: ModelTypes['UserFilterGender'] | undefined
    expectedBonus?: string | undefined
    followersCountCeiling?: number | undefined
    followersCountFloor?: number | undefined
    goal?: ModelTypes['CampaignGoal'] | undefined
    id: string
    influencerApprovalEnabled?: ModelTypes['InfluencerApprovalFlagV2'] | undefined
    lifestyleCommittedContentCount?: number | undefined
    localeV1?: ModelTypes['WSLocale'] | undefined
    marketingFee?: number | undefined
    marketingFormFilled?: boolean | undefined
    newCreatorsOnly?: boolean | undefined
    payForClicksEnabled?: ModelTypes['EnabledFlag'] | undefined
    payForClicksGeneratedPerPeriod?: Array<ModelTypes['ClicksRecordsInput']> | undefined
    payForClicksMinClicks?: number | undefined
    payForClicksOriginalLink?: string | undefined
    payForClicksTotalClicksGenerated?: number | undefined
    payForPerformanceEnabled?: ModelTypes['PayForPerformanceFlag'] | undefined
    postingGuidance?: string | undefined
    preApplicationProductImage?: string | undefined
    preApplicationProductPriceRange?: string | undefined
    preApplicationUgcProductImage?: string | undefined
    preLaunchDate?: string | undefined
    premiumLifestyleCommittedContentCount?: number | undefined
    requiredProductLinksEnabled?: ModelTypes['RequiredProductLinkFlag'] | undefined
    reviewVotingTarget?: string | undefined
    sample?: boolean | undefined
    shellCampaignBase?: string | undefined
    skuId?: string | undefined
    skuPriceOverride?: number | undefined
    socialCommittedContentCount?: number | undefined
    sourcingPriority?: number | undefined
    sponsorshipInstructions?: string | undefined
    title?: string | undefined
    totalEarlyReviewsAllowed?: number | undefined
    totalProductCost?: number | undefined
    userAutoApproved?: boolean | undefined
    visibleToUser?: string | undefined
  }
  ['UpdateCampaignInviteInput']: {
    campaignId?: string | undefined
    createdAt?: string | undefined
    id: string
    notificationStatus?: ModelTypes['NotificationStatus'] | undefined
    priority?: number | undefined
    updatedAt?: string | undefined
    userId?: string | undefined
  }
  ['UpdateCampaignSourcingProspectInput']: {
    bonusConfig?: ModelTypes['UserCampaignBonusConfigInput'] | undefined
    campaignId: string
    createdAt?: string | undefined
    createdById?: string | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    internalNotesId?: string | undefined
    name?: string | undefined
    platformInviteSource?: ModelTypes['PlatformInviteSource'] | undefined
    platformStatus?: ModelTypes['PlatformStatus'] | undefined
    preApprovalStatus?: ModelTypes['PreApprovalStatus'] | undefined
    preApprovalStatusById?: string | undefined
    priority?: ModelTypes['SourcePriority'] | undefined
    productId?: string | undefined
    sourcingSource?: ModelTypes['CampaignSourcingSource'] | undefined
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    userCampaignId?: string | undefined
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
  }
  ['UpdateChatConversationV2Input']: {
    active?: ModelTypes['Active'] | undefined
    adminLastReadMessageIndex?: number | undefined
    adminUnreadCount?: number | undefined
    campaignId?: string | undefined
    chatType: ModelTypes['ChatType']
    creatorLastReadMessageIndex?: number | undefined
    creatorUnreadCount?: number | undefined
    lastMessage?: string | undefined
    lastMessageDate?: string | undefined
    lastMessageId?: string | undefined
    lastMessageSource?: ModelTypes['ConversationMessageSource'] | undefined
    resourceId: string
    status?: ModelTypes['ConversationInternalStatus'] | undefined
    statusUpdatedAt?: string | undefined
    statusUpdatedByFirstName?: string | undefined
    statusUpdatedById?: string | undefined
    statusUpdatedByLastName?: string | undefined
    twilioConversationId?: string | undefined
    userCampaignId?: string | undefined
    userEmail?: string | undefined
    userFirstName?: string | undefined
    userId?: string | undefined
    userLastName?: string | undefined
  }
  ['UpdateCommunicationNotificationInput']: {
    address?: string | undefined
    attributes?: Array<ModelTypes['KeyAttributeInput']> | undefined
    campaignId?: string | undefined
    channel?: ModelTypes['Channel'] | undefined
    expectedSentAt?: string | undefined
    experimentTreatment?: string | undefined
    id: string
    notificationId?: string | undefined
    sentAt?: string | undefined
    status?: string | undefined
    statusMessage?: string | undefined
    templateId?: string | undefined
    traceId?: string | undefined
    userCampaignId?: string | undefined
    userId?: string | undefined
  }
  ['UpdateCompanyInput']: {
    agencyId?: string | undefined
    id: string
    logo?: string | undefined
    name?: string | undefined
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: ModelTypes['PaymentTermsType'] | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['UpdateConnectedAccountInput']: {
    accountId: string
    accountType?: ModelTypes['ConnectedAccountType'] | undefined
    createdAt?: string | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['UpdateContentApprovalsInput']: {
    bonusOverride?: number | undefined
    editedCaption?: string | undefined
    nextContentApprovalAssignee?: string | undefined
    rejectionReason?: string | undefined
    requestType: ModelTypes['AdminUpdateContentApprovalType']
    requestedContentChanges?: Array<ModelTypes['RequestedContentChangesInput']> | undefined
    reviewType: ModelTypes['UserCampaignSocialContentReviewType']
    userCampaignSocialContentId: string
  }
  ['UpdateConversationInput']: {
    contactOwnerId?: string | undefined
    lastContactAt?: string | undefined
    status?: ModelTypes['ConversationStatus'] | undefined
    statusChangedAt?: string | undefined
    userId: string
  }
  ['UpdateConversationMessageInput']: {
    channel?: ModelTypes['ConversationChannel'] | undefined
    id: string
    messageContent?: string | undefined
    messageId?: string | undefined
    messageMediaKeys?: Array<string> | undefined
    replyFromId?: string | undefined
    sendAt?: string | undefined
    sendAttempts?: number | undefined
    sentAt?: string | undefined
    status?: ModelTypes['ConversationMessageStatus'] | undefined
    userId: string
  }
  ['UpdateEtailerSkuMetricsInput']: {
    date: string
    pageViews?: number | undefined
    sku?: string | undefined
    skuId: string
    totalSalesAmount?: number | undefined
    unitsSold?: number | undefined
  }
  ['UpdateFormSequenceConfigurationInput']: {
    active?: ModelTypes['Active'] | undefined
    id: string
    sequenceName?: string | undefined
    sequences?: Array<ModelTypes['FormSequenceInput']> | undefined
  }
  ['UpdateInstagramStoryScreenInput']: {
    exits?: number | undefined
    expirationUnixTime?: number | undefined
    externalId?: string | undefined
    id: string
    impressions?: number | undefined
    instagramUserId?: string | undefined
    mediaId?: string | undefined
    postedAt?: string | undefined
    postingDate?: string | undefined
    reach?: number | undefined
    replies?: number | undefined
    s3Bucket?: string | undefined
    tapsBack?: number | undefined
    tapsForward?: number | undefined
    userCampaignId?: string | undefined
  }
  ['UpdateMessageInput']: {
    body?: string | undefined
    createdAt?: string | undefined
    id: string
    updatedAt?: string | undefined
    userCampaignId?: string | undefined
    userHasSeen?: boolean | undefined
    userId?: string | undefined
  }
  ['UpdateNoteInput']: {
    createdAt?: string | undefined
    id: string
    note?: string | undefined
    parentId?: string | undefined
    userId?: string | undefined
  }
  ['UpdateNotificationConfigurationInput']: {
    category?: string | undefined
    description?: string | undefined
    email?: ModelTypes['ChannelConfigurationInput'] | undefined
    enabled?: boolean | undefined
    id: string
    inAppChatChannel?: ModelTypes['MessagingChannelConfigurationInput'] | undefined
    name?: string | undefined
    occurrenceConstraint?: ModelTypes['OccurrenceConstraintInput'] | undefined
    push?: ModelTypes['PushChannelConfigurationInput'] | undefined
    sms?: ModelTypes['ChannelConfigurationInput'] | undefined
  }
  ['UpdateProductBonusConfigSnapshotInput']: {
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<ModelTypes['ProductSocialContentInput']> | undefined
    discoveryBonus?: number | undefined
    payForImpressionsBonus?: ModelTypes['PayForImpressionsBonusConfigInput'] | undefined
    productId: string
    snapshotAt: string
  }
  ['UpdateProductInput']: {
    accountRequired?: boolean | undefined
    active?: string | undefined
    approvalFilters?: Array<ModelTypes['ApprovalFilterValueInput']> | undefined
    autoApprovalSeatsAvailable?: number | undefined
    autoApprovalStatusTiers?: Array<ModelTypes['StatusTier']> | undefined
    autoApprovalV2Config?: ModelTypes['AutoApprovalV2ConfigInput'] | undefined
    automaticReviewRelease?: boolean | undefined
    baseBonus?: number | undefined
    brandAdvocateCount?: number | undefined
    brandTalkingPoints?: Array<string> | undefined
    buyOnlinePickupInStore?: ModelTypes['BOPISValue'] | undefined
    campaignId?: string | undefined
    campaignStateMachine?: string | undefined
    closeoutFormBonus?: number | undefined
    contentApprovalConfiguration?: Array<ModelTypes['ContentApprovalConfigurationInput']> | undefined
    contentApprovalLeadDays?: number | undefined
    contentRequirement?: Array<ModelTypes['ProductSocialContentInput']> | undefined
    creatorCount?: number | undefined
    creatorPreference?: ModelTypes['CreatorPreference'] | undefined
    creatorPreferenceDetails?: string | undefined
    customFtcHashtags?: Array<string> | undefined
    customHashtags?: Array<string> | undefined
    directPurchaseLink?: string | undefined
    engagementRateInstagramRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    engagementRateTiktokRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    followersInstagramRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    followersTiktokRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    hideBrandContentApprovals?: boolean | undefined
    id: string
    impressionsInstagramRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    impressionsTiktokRangeRequirement?: ModelTypes['MinMaxRangeInput'] | undefined
    instaHandle?: string | undefined
    internalCreatorsStagingGuidance?: string | undefined
    internalDescription?: string | undefined
    internalName?: string | undefined
    internalNotes?: string | undefined
    isDirectListingPurchase?: boolean | undefined
    isQualityRequirementsEnabled?: boolean | undefined
    listingGuidance?: string | undefined
    maxDiscoveryBonus?: number | undefined
    minimumCreatorQualityScore?: number | undefined
    nonRetailReviewLink?: string | undefined
    nonRetailReviewWebsiteName?: string | undefined
    payForClicksEnabled?: ModelTypes['EnabledFlag'] | undefined
    payForClicksMinClicks?: number | undefined
    payForImpressionsBonus?: ModelTypes['PayForImpressionsBonusConfigInput'] | undefined
    payForPerformanceBonus?: ModelTypes['StatusTierBonusConfigInput'] | undefined
    payForPostingContentEarly?: ModelTypes['PayForPostingContentEarlyConfigInput'] | undefined
    postingDeadlineDaysRelativeToApproval?: number | undefined
    productLinkId?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    promoCode?: string | undefined
    promoCodeGuidance?: string | undefined
    promoCodeType?: ModelTypes['PromoCodeType'] | undefined
    promoDiscountAmount?: number | undefined
    promoDiscountType?: ModelTypes['PromoDiscountType'] | undefined
    promoEndDate?: string | undefined
    promoLink?: string | undefined
    promoStartDate?: string | undefined
    promotionLinkId?: string | undefined
    purchaseByPromoCode?: boolean | undefined
    purchaseDiscountCode?: string | undefined
    purchaseDiscountCodeDetails?: string | undefined
    purchaseDiscountCodePercentage?: string | undefined
    qualityLevel?: ModelTypes['ProductQualityLevel'] | undefined
    requestEarlyProductFeedback?: boolean | undefined
    requestProductFeedback?: boolean | undefined
    requiredProductLinkEnabled?: ModelTypes['RequiredProductLinkFlag'] | undefined
    sequenceId?: string | undefined
    shopifyPriceRule?: string | undefined
    shopifyPromotionId?: string | undefined
    shopifyPromotionType?: ModelTypes['ShopifyPromotionType'] | undefined
    skuId?: string | undefined
    socialMediaExampleUrls?: Array<string> | undefined
    stagingApprovalConfiguration?: Array<ModelTypes['StagingApprovalConfigurationInput']> | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined
    stagingRequirementType?: ModelTypes['StagingRequirementType'] | undefined
    stagingSocialQualityRequirement?: ModelTypes['SocialQuality'] | undefined
    tikTokHandle?: string | undefined
    unitsPurchased?: number | undefined
    validateProductFoundVariations?: Array<string> | undefined
    visibleAt?: string | undefined
    visibleEndsAt?: string | undefined
  }
  ['UpdateProductKeywordsInput']: {
    adminPriority?: number | undefined
    brandPriority?: number | undefined
    brandResponse?: ModelTypes['KeywordAcceptanceResponse'] | undefined
    campaignId?: string | undefined
    creationSource?: ModelTypes['ProductKeywordCreationSource'] | undefined
    endDate?: string | undefined
    heliumAccount?: string | undefined
    heliumAsin?: string | undefined
    heliumLink?: string | undefined
    id: string
    keyword?: string | undefined
    monthlySearchVolume?: number | undefined
    rank?: number | undefined
    rankingHistory?: Array<ModelTypes['RankPointInput']> | undefined
    rankingLastSkippedAt?: string | undefined
    rankingLastSkippedByUserId?: string | undefined
    rankingLastUpdatedAt?: string | undefined
    rankingLastUpdatedByUserId?: string | undefined
    recommended?: boolean | undefined
    skuId?: string | undefined
    startDate?: string | undefined
    usedForCampaign?: boolean | undefined
    visible?: boolean | undefined
    weight?: number | undefined
  }
  ['UpdateProductRatingSnapshotInput']: {
    numFiveStar?: number | undefined
    numFourStar?: number | undefined
    numOneStar?: number | undefined
    numRatings?: number | undefined
    numReviews?: number | undefined
    numThreeStar?: number | undefined
    numTwoStar?: number | undefined
    rating?: number | undefined
    sku?: string | undefined
    skuId: string
    snapshotAt: string
  }
  ['UpdatePurchaseInput']: {
    amount?: number | undefined
    cardId?: string | undefined
    currency?: string | undefined
    id: string
    isFraud?: boolean | undefined
    keywordAtPurchase?: string | undefined
    purchasedAt?: string | undefined
    shippedAt?: string | undefined
    status?: ModelTypes['PurchaseStatus'] | undefined
    userCampaignId?: string | undefined
  }
  ['UpdateReferralInput']: {
    bonusPayoutId?: string | undefined
    campaignId?: string | undefined
    id: string
    referralType?: ModelTypes['ReferralType'] | undefined
    referredUserId?: string | undefined
    referrerUserId?: string | undefined
  }
  ['UpdateReviewInput']: {
    attributedUserId?: string | undefined
    author?: string | undefined
    authorBadge?: string | undefined
    badge?: string | undefined
    commentCount?: number | undefined
    helpfulReactions?: number | undefined
    id: string
    negativeReactions?: number | undefined
    photos?: Array<string> | undefined
    productId?: string | undefined
    productVariant?: string | undefined
    rating?: number | undefined
    removed?: boolean | undefined
    reviewedAt?: string | undefined
    reviewedSku?: string | undefined
    reviewerId?: string | undefined
    sku?: string | undefined
    skuId?: string | undefined
    store?: string | undefined
    text?: string | undefined
    title?: string | undefined
    userCampaignId?: string | undefined
    videos?: Array<string> | undefined
  }
  ['UpdateSkuInput']: {
    active?: string | undefined
    approxRetailPrice?: string | undefined
    approxShippingCost?: number | undefined
    attributes?: Array<ModelTypes['SkuAttributeInput']> | undefined
    availabilityDate?: string | undefined
    availabilityNotified?: boolean | undefined
    availabilityNotifiedAt?: string | undefined
    brandId?: string | undefined
    brandListingKey?: string | undefined
    brandStoreId?: string | undefined
    bundle?: string | undefined
    createdByUserId?: string | undefined
    creationSource?: ModelTypes['SkuCreationSource'] | undefined
    creationSourceUrl?: string | undefined
    currency?: ModelTypes['Currency'] | undefined
    currentBuyBoxPrice?: number | undefined
    currentBuyBoxPriceCents?: number | undefined
    estimatedAvailabilityDate?: string | undefined
    estimatedDeliveryDays?: number | undefined
    estimatedMonthlyUnitsSold?: number | undefined
    estimatedWeeklyUnitsSold?: number | undefined
    exactListingTitle?: string | undefined
    exactRetailPrice?: string | undefined
    fullReviewScrapeComplete?: boolean | undefined
    id: string
    inventory?: number | undefined
    iosAppProviderId?: string | undefined
    isAplusPage?: string | undefined
    isPrime?: string | undefined
    isRecommendationsHidden?: boolean | undefined
    itemClassification?: ModelTypes['ItemClassification'] | undefined
    lastAutoFilledAt?: string | undefined
    lastKnownBuyBoxPrice?: number | undefined
    lastKnownBuyBoxPriceCents?: number | undefined
    listingBrand?: string | undefined
    listingCategory?: Array<string> | undefined
    listingDescription?: string | undefined
    listingFulfillmentType?: ModelTypes['ListingFulfillmentType'] | undefined
    listingGuidance?: string | undefined
    listingLink?: string | undefined
    mccValue?: ModelTypes['ValidMccCodes'] | undefined
    minimumOrderQuantity?: number | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    nonSponsoredListingKey?: string | undefined
    parentSkuId?: string | undefined
    priceCents?: number | undefined
    product?: string | undefined
    productImageKey?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    productSearchImageKey?: string | undefined
    releaseDate?: string | undefined
    skuId?: string | undefined
    sponsoredListingKey?: string | undefined
    storeName?: string | undefined
    targetReviewRate?: ModelTypes['RateInput'] | undefined
    targetReviewRateInvalid?: string | undefined
  }
  ['UpdateSkuSnapshotInput']: {
    inventory?: number | undefined
    marketplaceSku: string
    price?: number | undefined
    skuId?: string | undefined
    snapshotDate: string
  }
  ['UpdateUserBonusPayoutInput']: {
    adminPaymentApproved?: boolean | undefined
    bonusType?: ModelTypes['BonusPayoutTypes'] | undefined
    createdAt?: string | undefined
    failureReason?: string | undefined
    id: string
    manuallyCreatedBy?: string | undefined
    payoutAmount?: number | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: ModelTypes['WSLocale'] | undefined
    payoutState?: ModelTypes['BonusPayoutState'] | undefined
    quantity?: number | undefined
    referenceId?: string | undefined
    releaseAt?: string | undefined
    revocationType?: ModelTypes['RevocationType'] | undefined
    revokedAt?: string | undefined
    userCampaignId?: string | undefined
    userId?: string | undefined
  }
  ['UpdateUserCampaignChangelogInput']: {
    createdAt: string
    createdByUserId?: string | undefined
    entryType?: ModelTypes['UserCampaignChangelogEntryType'] | undefined
    extraData?: string | undefined
    userCampaignId: string
  }
  ['UpdateUserCampaignInput']: {
    accountScreenShot?: string | undefined
    amznReviewLink?: string | undefined
    amznReviewSubmissionStatus?: ModelTypes['AmazonReviewSubmissionStatus'] | undefined
    applicationRejectedByUserId?: string | undefined
    approvalOutcome?: ModelTypes['ApprovalOutcome'] | undefined
    approvalStatus?: ModelTypes['UserCampaignApprovalStatus'] | undefined
    archivedFeedback?: string | undefined
    archivedReason?: ModelTypes['ArchivedReason'] | undefined
    autoBuyApprovalDate?: string | undefined
    autoBuyRevokeDate?: string | undefined
    bestBuyReviewScreenShot?: string | undefined
    bonusConfig?: ModelTypes['UserCampaignBonusConfigInput'] | undefined
    campaignAcceptanceStatus?: ModelTypes['UserCampaignAcceptanceStatus'] | undefined
    campaignApplication?: string | undefined
    campaignId?: string | undefined
    campaignStatus?: ModelTypes['CampaignStatus'] | undefined
    campaignStatusOnArchive?: string | undefined
    cardId?: string | undefined
    cardRegion?: ModelTypes['StripeRegion'] | undefined
    closeoutSurvey?: string | undefined
    coarseCampaignStatus?: ModelTypes['CoarseCampaignStatus'] | undefined
    contentApprovalLeadDays?: number | undefined
    createdAt?: string | undefined
    discoverySurvey?: string | undefined
    id: string
    influencerApprovalInviteFeedback?: string | undefined
    influencerApprovalRejectFeedback?: string | undefined
    invoiceKey?: string | undefined
    isAutoApproved?: boolean | undefined
    keywordAtPurchase?: string | undefined
    keywordId?: string | undefined
    keywordsV2Hash?: string | undefined
    listingKey?: string | undefined
    localeV1?: ModelTypes['WSLocale'] | undefined
    manualPurchase?: boolean | undefined
    moderatorStagingApprovalAt?: string | undefined
    moderatorStagingApprovalBy?: string | undefined
    moderatorStagingApprovalOutcome?: ModelTypes['StagingApprovalOutcome'] | undefined
    orderId?: string | undefined
    orderIdInvalidReason?: string | undefined
    orderIdSubmissionDate?: string | undefined
    orderIdValidationDate?: string | undefined
    orderIdValidationStatus?: ModelTypes['OrderIdValidationStatus'] | undefined
    orderStatus?: string | undefined
    payForClickLinkId?: string | undefined
    paypalEmailAtSubmission?: string | undefined
    postingDeadline?: string | undefined
    postingDeadlineExtended?: boolean | undefined
    previousCardIds?: Array<string> | undefined
    priority?: ModelTypes['SourcePriority'] | undefined
    productCriticalFeedback?: string | undefined
    productId?: string | undefined
    productPositiveFeedback?: string | undefined
    rankPriority?: number | undefined
    reviewScreenshot?: string | undefined
    reviewScreenshotVerificationResult?: ModelTypes['ReviewScreenshotVerificationResult'] | undefined
    schedulingReleaseAt?: string | undefined
    searchPhraseKey?: string | undefined
    selectedFlow?: string | undefined
    selfieVideoApprovalDate?: string | undefined
    selfieVideoApprovalNotes?: string | undefined
    selfieVideoApprovalStatus?: ModelTypes['SelfieVideoApprovalStatus'] | undefined
    selfieVideoKey?: string | undefined
    selfieVideoRejectionReason?: string | undefined
    selfieVideoSubmissionRequired?: boolean | undefined
    selfieVideoSubmittedAt?: string | undefined
    sequenceFormSubmissionValues?: Array<ModelTypes['SequenceFormSubmissionValuesInput']> | undefined
    shippingAddress?: string | undefined
    showEarlyReviewSurvey?: boolean | undefined
    statusHistory?: string | undefined
    updatedAt?: string | undefined
    userId?: string | undefined
    walmartReviewScreenShot?: string | undefined
  }
  ['UpdateUserCampaignSnapshotInput']: {
    snapshot?: string | undefined
    userCampaignId: string
    userCampaignVersion: number
  }
  ['UpdateUserCampaignSocialContentDraftHistoryInput']: {
    id: string
    snapshot?: string | undefined
    snapshotDate?: string | undefined
    userCampaignSocialContentId?: string | undefined
  }
  ['UpdateUserCampaignSocialContentInput']: {
    approvalFeedback?: string | undefined
    approvalStatus?: ModelTypes['ContentApprovalStatus'] | undefined
    approvedAt?: string | undefined
    approverPWUserId?: string | undefined
    approverUserId?: string | undefined
    bonusEarned?: number | undefined
    bonusOverride?: number | undefined
    caption?: string | undefined
    channel?: ModelTypes['SocialChannel'] | undefined
    clientFeedback?: Array<ModelTypes['SocialMediaFeedbackInput']> | undefined
    complianceReview?: Array<ModelTypes['ContentComplianceReviewCriteriaInput']> | undefined
    complianceReviewStatus?: ModelTypes['ContentComplianceReviewStatus'] | undefined
    complianceReviewUserId?: string | undefined
    complianceReviewedAt?: string | undefined
    contentType?: ModelTypes['SocialContentType'] | undefined
    draftEnteredAdminApprovalAt?: string | undefined
    draftEnteredBrandApprovalAt?: string | undefined
    draftFeedback?: Array<string> | undefined
    draftHistory?: Array<string> | undefined
    draftMedia?: Array<string> | undefined
    draftPreApprovedText?: string | undefined
    draftQualityScore?: number | undefined
    draftReview?: Array<ModelTypes['ContentComplianceReviewCriteriaInput']> | undefined
    draftStatus?: ModelTypes['DraftStatus'] | undefined
    draftSubmittedAt?: string | undefined
    draftText?: string | undefined
    earlySubmissionBonusEarnedCents?: number | undefined
    experimentId?: string | undefined
    externalUrl?: string | undefined
    groupId?: string | undefined
    hashtags?: Array<string> | undefined
    id: string
    instance?: number | undefined
    internalFeedback?: Array<ModelTypes['SocialMediaFeedbackInput']> | undefined
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    isPurchased?: boolean | undefined
    isUnavailable?: boolean | undefined
    manualUploadType?: ModelTypes['ManualUploadType'] | undefined
    mediaId?: string | undefined
    mediaUrls?: Array<string> | undefined
    metrics?: Array<ModelTypes['SocialMetricInput']> | undefined
    paymentId?: string | undefined
    postedAt?: string | undefined
    qualityScore?: number | undefined
    rejectionReason?: string | undefined
    requestedSocialScript?: boolean | undefined
    requirementLevel?: ModelTypes['ContentRequirementLevel'] | undefined
    scrapeStatus?: ModelTypes['ScrapeStatus'] | undefined
    unavailableAt?: string | undefined
    usedRequestedSocialScript?: boolean | undefined
    userCampaignId?: string | undefined
    videoEndTime?: string | undefined
    videoStartTime?: string | undefined
  }
  ['UpdateUserCampaignSocialContentReviewInput']: {
    createdAt?: string | undefined
    reviewFeedback?: Array<ModelTypes['UserCampaignSocialContentReviewFeedbackInput']> | undefined
    reviewOutcome?: ModelTypes['UserCampaignSocialContentReviewOutcome'] | undefined
    reviewStatus?: ModelTypes['UserCampaignSocialContentReviewStatus'] | undefined
    reviewType: ModelTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined
    reviewerAssigneeId?: string | undefined
    reviewerFirstName?: string | undefined
    reviewerId?: string | undefined
    reviewerLastName?: string | undefined
    userCampaignSocialContentId: string
    userId?: string | undefined
  }
  ['UpdateUserDeviceSnapshotInput']: {
    id: string
    idfv?: string | undefined
    ipAddress?: string | undefined
    isEmulator?: boolean | undefined
    snapshotAt?: string | undefined
    timezone?: string | undefined
    userId?: string | undefined
  }
  ['UpdateUserFollowerInput']: {
    createdAt?: string | undefined
    followerUserId: string
    isFavorite?: ModelTypes['FavoriteFlag'] | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['UpdateUserInput']: {
    active?: ModelTypes['Active'] | undefined
    addressComponents?: string | undefined
    amznLastChecked?: string | undefined
    amznLink?: string | undefined
    amznPrime?: string | undefined
    amznProfileId?: string | undefined
    amznReviewerId?: string | undefined
    amznVerified?: string | undefined
    attributedReviewCount?: number | undefined
    blogWebsite?: string | undefined
    brandContentRatingAvg?: number | undefined
    campaignAcceptanceRate?: number | undefined
    campaignApprovalRate?: number | undefined
    campaignCompletionRate?: number | undefined
    campaignReviewRate?: number | undefined
    cardProfileUpdates?: string | undefined
    cardholderId?: string | undefined
    cardholderRegion?: ModelTypes['StripeRegion'] | undefined
    characteristics?: Array<ModelTypes['UserCharacteristicInput']> | undefined
    characteristicsUpdatedAt?: string | undefined
    claimedReviewCount?: number | undefined
    collisionSkuSet?: Array<string> | undefined
    collisionSkuSetCalculatedAt?: string | undefined
    completedLifestyleCampaignCount?: number | undefined
    completedLiftCampaignCount?: number | undefined
    completedSocialCampaignCount?: number | undefined
    contentRating?: ModelTypes['UserContentRatingInput'] | undefined
    contentRatingCompletedAt?: string | undefined
    contentRatingCompletionTimeMillis?: number | undefined
    contentRatingInstagramCompletionTimeMillis?: number | undefined
    contentRatingInstagramSponsorshipContent?: Array<ModelTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentRatingQualityScore?: number | undefined
    contentRatingReviewStatus?: ModelTypes['ContentRatingReviewStatus'] | undefined
    contentRatingSponsorshipContent?: Array<ModelTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentRatingSubmittedBy?: string | undefined
    contentRatingTiktokCompletionTimeMillis?: number | undefined
    contentRatingTiktokSponsorshipContent?: Array<ModelTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentTypes?: Array<string> | undefined
    createdAt?: string | undefined
    date_of_birth?: string | undefined
    deactivationDate?: string | undefined
    deactivationReason?: string | undefined
    declineToReviewCount?: number | undefined
    deranked?: ModelTypes['Deranked_Reason'] | undefined
    email?: string | undefined
    emailOptOut?: boolean | undefined
    facebookAccessToken?: string | undefined
    facebookAccessTokenExpiry?: string | undefined
    facebookPageId?: string | undefined
    facebookPageIsSubscribed?: boolean | undefined
    facebookPermissionScopes?: Array<string | undefined> | undefined
    facebookUserId?: string | undefined
    firstName?: string | undefined
    followsWesponsored?: string | undefined
    fraudReason?: ModelTypes['FraudReason'] | undefined
    fraudReviewAt?: string | undefined
    fraudReviewStatus?: ModelTypes['FraudReviewStatus'] | undefined
    gender?: string | undefined
    hasTrueEngagement?: boolean | undefined
    id: string
    idfaId?: string | undefined
    incompleteCampaignCount?: number | undefined
    instagramBio?: string | undefined
    instagramBusinessCategoryName?: string | undefined
    instagramCategoryName?: string | undefined
    instagramChecked?: string | undefined
    instagramContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    instagramExternalLink?: string | undefined
    instagramFollowerCountryDistribution?: Array<ModelTypes['InstagramCountryDistributionItemInput']> | undefined
    instagramFollowerGenderAgeDistribution?: ModelTypes['InstagramGenderAgeDistributionInput'] | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    instagramIsBusiness?: string | undefined
    instagramIsPrivate?: string | undefined
    instagramIsRecentlyJoined?: string | undefined
    instagramIsVerified?: string | undefined
    instagramLastChecked?: string | undefined
    instagramMedianShares?: number | undefined
    instagramMetricsRetrievedAt?: string | undefined
    instagramName?: string | undefined
    instagramPostsInt?: number | undefined
    instagramPriority?: ModelTypes['PlatformPriority'] | undefined
    instagramProfilePic?: string | undefined
    instagramTrailingComments?: number | undefined
    instagramTrailingDateOfOldestPost?: string | undefined
    instagramTrailingLikes?: number | undefined
    instagramTrailingNumberOfPosts?: number | undefined
    instagramTrailingPostEngagementAvg?: number | undefined
    instagramTrailingPostEngagementRateAvg?: number | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined
    instagramTrailingPostEngagementRateMedian?: number | undefined
    instagramTrailingPostImpressionsAvg?: number | undefined
    instagramTrailingPostImpressionsMedian?: number | undefined
    instagramTrailingPostSponsoredPercentage?: number | undefined
    instagramTrailingPostViewsMedian?: number | undefined
    instagramUserId?: string | undefined
    instagramVerificationMethod?: ModelTypes['SocialVerificationMethod'] | undefined
    internalGender?: ModelTypes['UserGender'] | undefined
    isBrandSafe?: ModelTypes['BrandSafe'] | undefined
    isBrandSafeReason?: string | undefined
    isBrandSafeUpdatedAt?: string | undefined
    lastActiveAt?: string | undefined
    lastName?: string | undefined
    manualScoringLockedUntil?: string | undefined
    moreinfo?: string | undefined
    numEvenDollarPurchases?: number | undefined
    numPurchases?: number | undefined
    paypalEmail?: string | undefined
    phoneNumber?: string | undefined
    profilePicture?: string | undefined
    pushAddresses?: Array<ModelTypes['PushAddressInput']> | undefined
    pushOptOut?: boolean | undefined
    referralCode?: string | undefined
    referralCount?: number | undefined
    referrer?: string | undefined
    signupCountry?: string | undefined
    smsOptOut?: boolean | undefined
    socialQuality?: ModelTypes['SocialQuality'] | undefined
    specialAttributes?: ModelTypes['UserAttributesInput'] | undefined
    statusTier?: ModelTypes['StatusTier'] | undefined
    statusTierHistory?: Array<ModelTypes['StatusTierEntryInput']> | undefined
    statusTierLastUpdatedAt?: string | undefined
    supportPriorityAcknowledgedAt?: string | undefined
    supportPriorityFlag?: ModelTypes['SupportPriorityFlag'] | undefined
    supportPriorityFlagSetAt?: string | undefined
    targetAccount?: string | undefined
    tiktokAccessToken?: string | undefined
    tiktokAccessTokenExpiry?: string | undefined
    tiktokAccountId?: string | undefined
    tiktokAveragePostEngagementRate?: number | undefined
    tiktokAveragePostEngagementRateViews?: number | undefined
    tiktokBio?: string | undefined
    tiktokContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    tiktokMedianComments?: number | undefined
    tiktokMedianLikes?: number | undefined
    tiktokMedianPostEngagement?: number | undefined
    tiktokMedianPostEngagementRate?: number | undefined
    tiktokMedianPostEngagementRateViews?: number | undefined
    tiktokMedianShares?: number | undefined
    tiktokMedianViews?: number | undefined
    tiktokMetricsRetrievedAt?: string | undefined
    tiktokName?: string | undefined
    tiktokPermissionScopes?: Array<string> | undefined
    tiktokPriority?: ModelTypes['PlatformPriority'] | undefined
    tiktokProfilePic?: string | undefined
    tiktokRefreshToken?: string | undefined
    tiktokRefreshTokenExpiry?: string | undefined
    tiktokSponsoredPostPercentage?: number | undefined
    tiktokVerificationMethod?: ModelTypes['SocialVerificationMethod'] | undefined
    tiktokVerified?: string | undefined
    timeZone?: string | undefined
    trackingStatus?: ModelTypes['TrackingStatus'] | undefined
    twitchAccount?: string | undefined
    type?: string | undefined
    utmReferral?: ModelTypes['UtmReferralInput'] | undefined
    wmartAccount?: string | undefined
    youtubeAccessToken?: string | undefined
    youtubeAccessTokenExpiry?: string | undefined
    youtubeAccount?: string | undefined
    youtubeAveragePostEngagementRate?: number | undefined
    youtubeChannelDescription?: string | undefined
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
    youtubeChannelId?: string | undefined
    youtubeChannelPicture?: string | undefined
    youtubeChannelTitle?: string | undefined
    youtubeIdToken?: string | undefined
    youtubeMedianViews?: number | undefined
    youtubeMetricsRetrievedAt?: string | undefined
    youtubePermissionScopes?: Array<string> | undefined
    youtubeRefreshToken?: string | undefined
    youtubeVerificationMethod?: ModelTypes['SocialVerificationMethod'] | undefined
  }
  ['UpdateUserLinkInput']: {
    campaignId?: string | undefined
    clicksPerPeriod?: Array<ModelTypes['ClicksRecordsInput']> | undefined
    createdAt?: string | undefined
    id: string
    linkId?: string | undefined
    originalLink?: string | undefined
    rank?: number | undefined
    storageType?: ModelTypes['LinkStorageType'] | undefined
    uniqueClicks?: number | undefined
    updatedAt?: string | undefined
    userEncodedLink?: string | undefined
    userId?: string | undefined
  }
  ['UpdateUserNotificationPreferenceInput']: {
    category: string
    emailOptOut?: boolean | undefined
    pushOptOut?: boolean | undefined
    smsOptOut?: boolean | undefined
    userId: string
  }
  ['UpdateUserPayoutRequestInput']: {
    failureReason?: string | undefined
    id: string
    manuallyApprovedBy?: string | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: ModelTypes['WSLocale'] | undefined
    payoutState?: ModelTypes['BonusPayoutState'] | undefined
    userId?: string | undefined
  }
  ['UpdateUserStagingApprovalInput']: {
    id: string
    reviewFeedback?: string | undefined
    reviewedAt?: string | undefined
    reviewedBy?: string | undefined
    reviewedByEmail?: string | undefined
    reviewedByName?: string | undefined
    reviewedBySource?: ModelTypes['UserStagingApprovalReviewSource'] | undefined
    userCampaignId?: string | undefined
    userStagingApprovalOutcome?: ModelTypes['UserStagingApprovalOutcome'] | undefined
    userStagingApprovalType?: ModelTypes['UserStagingApprovalType'] | undefined
  }
  ['UpdateVideoContentScanInput']: {
    audioDuration?: number | undefined
    audioEndAt?: number | undefined
    audioStartFrom?: number | undefined
    contentSafetyAnalysis?: ModelTypes['ContentSafetyAnalysisInput'] | undefined
    id: string
    processFinishedAt?: string | undefined
    processId?: string | undefined
    processInitiatedAt?: string | undefined
    scanStatus?: ModelTypes['ContentScanStatus'] | undefined
    socialMediaUrl?: string | undefined
    transcription?: string | undefined
    transcriptionConfidence?: number | undefined
    url?: string | undefined
    userId?: string | undefined
  }
  ['UserAttributesInput']: {
    isMother?: boolean | undefined
  }
  ['UserCampaignBonusConfigInput']: {
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<ModelTypes['ProductSocialContentInput']> | undefined
    discoveryBonus?: number | undefined
    expectedBonus?: string | undefined
    hasCustomBonusConfig?: boolean | undefined
    payForImpressionsBonus?: ModelTypes['PayForImpressionsBonusConfigInput'] | undefined
  }
  ['UserCampaignSearchFields']: {
    campaignId?: ModelTypes['SearchInputString'] | undefined
    campaignStatus?: ModelTypes['SearchInputString'] | undefined
    prospect?: ModelTypes['ProspectSearchFields'] | undefined
  }
  ['UserCampaignSocialContentReviewFeedbackInput']: {
    atTime?: number | undefined
    createdAt?: string | undefined
    createdByFirstName?: string | undefined
    createdByLastName?: string | undefined
    createdByUserId?: string | undefined
    forAssetAtIndex?: number | undefined
    id?: string | undefined
    message?: string | undefined
    rating?: number | undefined
    updatedAt?: string | undefined
    visibility?: ModelTypes['ReviewFeedbackVisibility'] | undefined
  }
  ['UserCharacteristicInput']: {
    characteristic: string
    reason?: string | undefined
  }
  ['UserContentRatingInput']: {
    instagramContentQualityRating?: ModelTypes['ContentQualityRating'] | undefined
    instagramContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    instagramCreatorContentScore?: number | undefined
    instagramCreatorContentScoreRaw?: Array<ModelTypes['CreatorContentRatingRawValueInput']> | undefined
    instagramCreatorSponsorshipContentScore?: number | undefined
    instagramSubmittedBy?: string | undefined
    submittedBy?: string | undefined
    tiktokContentQualityRating?: ModelTypes['ContentQualityRating'] | undefined
    tiktokContentTypeRating?: Array<ModelTypes['ContentTypeRating']> | undefined
    tiktokCreatorContentScore?: number | undefined
    tiktokCreatorContentScoreRaw?: Array<ModelTypes['CreatorContentRatingRawValueInput']> | undefined
    tiktokCreatorSponsorshipContentScore?: number | undefined
    tiktokSubmittedBy?: string | undefined
  }
  ['UserSearchFields']: {
    addressComponents?: ModelTypes['AddressComponentsSearchFields'] | undefined
    campaignAcceptanceRate?: ModelTypes['SearchInputNumber'] | undefined
    campaignCompletionRate?: ModelTypes['SearchInputNumber'] | undefined
    campaignReviewRate?: ModelTypes['SearchInputNumber'] | undefined
    claimedReviewCount?: ModelTypes['SearchInputNumber'] | undefined
    collisionSkuSet?: ModelTypes['SearchInputString'] | undefined
    contentRatingQualityScore?: ModelTypes['SearchInputNumber'] | undefined
    createdAt?: ModelTypes['SearchInputString'] | undefined
    declineToReviewCount?: ModelTypes['SearchInputNumber'] | undefined
    email?: ModelTypes['SearchInputString'] | undefined
    firstName?: ModelTypes['SearchInputString'] | undefined
    followers?: ModelTypes['FollowersSearchFields'] | undefined
    gender?: ModelTypes['SearchInputString'] | undefined
    incompleteCampaignCount?: ModelTypes['SearchInputNumber'] | undefined
    instagramContentTypeRating?: ModelTypes['SearchInputString'] | undefined
    instagramFollowerCountryDistribution?: ModelTypes['InstagramFollowerCountryDistributionSearchFields'] | undefined
    instagramFollowersInt?: ModelTypes['SearchInputNumber'] | undefined
    instagramHandle?: ModelTypes['SearchInputString'] | undefined
    instagramTrailingPostEngagementRateAvg?: ModelTypes['SearchInputNumber'] | undefined
    instagramTrailingPostEngagementRateMedian?: ModelTypes['SearchInputNumber'] | undefined
    instagramTrailingPostImpressionsAvg?: ModelTypes['SearchInputNumber'] | undefined
    instagramTrailingPostImpressionsMedian?: ModelTypes['SearchInputNumber'] | undefined
    instagramTrailingPostSponsoredPercentage?: ModelTypes['SearchInputNumber'] | undefined
    instagramVerificationMethod?: ModelTypes['SearchInputString'] | undefined
    lastName?: ModelTypes['SearchInputString'] | undefined
    osScript?: ModelTypes['SearchInputQueryScript'] | undefined
    socialQuality?: ModelTypes['SearchInputString'] | undefined
    tiktokAveragePostEngagementRate?: ModelTypes['SearchInputNumber'] | undefined
    tiktokContentTypeRating?: ModelTypes['SearchInputString'] | undefined
    tiktokFollowersInt?: ModelTypes['SearchInputNumber'] | undefined
    tiktokHandle?: ModelTypes['SearchInputString'] | undefined
    tiktokMedianPostEngagementRateViews?: ModelTypes['SearchInputNumber'] | undefined
    tiktokMedianViews?: ModelTypes['SearchInputNumber'] | undefined
    tiktokSponsoredPostPercentage?: ModelTypes['SearchInputNumber'] | undefined
    tiktokVerificationMethod?: ModelTypes['SearchInputString'] | undefined
    userCampaigns?: ModelTypes['UserCampaignSearchFields'] | undefined
    youtubeAveragePostEngagementRate?: ModelTypes['SearchInputNumber'] | undefined
    youtubeChannelFollowersInt?: ModelTypes['SearchInputNumber'] | undefined
    youtubeChannelHandle?: ModelTypes['SearchInputString'] | undefined
    youtubeMedianViews?: ModelTypes['SearchInputNumber'] | undefined
    youtubeVerificationMethod?: ModelTypes['SearchInputString'] | undefined
  }
  ['UserSearchInput']: {
    and?: Array<ModelTypes['UserSearchFields']> | undefined
    from?: number | undefined
    not?: Array<ModelTypes['UserSearchFields']> | undefined
    or?: Array<Array<ModelTypes['UserSearchFields']> | undefined> | undefined
    size?: number | undefined
    sort?: Array<ModelTypes['UserSearchInputSort']> | undefined
  }
  ['UserSearchInputSort']: {
    field?: string | undefined
    order: ModelTypes['SortOrder']
    osScript?: ModelTypes['SearchInputSortScript'] | undefined
  }
  ['UtmReferralInput']: {
    campaign?: string | undefined
    content?: string | undefined
    medium?: string | undefined
    source?: string | undefined
    term?: string | undefined
  }
}

export type GraphQLTypes = {
  ['AdminSendPushMessageResponse']: {
    __typename: 'AdminSendPushMessageResponse'
    message?: string | undefined
    statusCode: number
  }
  ['ApplyToCampaignOutput']: {
    __typename: 'ApplyToCampaignOutput'
    campaignStatus: GraphQLTypes['CampaignStatus']
    id: string
    isAutoApproved?: boolean | undefined
    productId?: string | undefined
  }
  ['ApprovalFilterValue']: {
    __typename: 'ApprovalFilterValue'
    filter: string
    value: string
  }
  ['ApproveUserCampaignForPurchaseResponse']: {
    __typename: 'ApproveUserCampaignForPurchaseResponse'
    approvalOutcome?: GraphQLTypes['ApprovalOutcome'] | undefined
    campaignStatus: GraphQLTypes['CampaignStatus']
    coarseCampaignStatus: GraphQLTypes['CoarseCampaignStatus']
    userCampaignId: string
  }
  ['AutoApprovalCriteria']: {
    __typename: 'AutoApprovalCriteria'
    completedCampaigns?: number | undefined
  }
  ['AutoApprovalV2Config']: {
    __typename: 'AutoApprovalV2Config'
    autoApprovalCriteria?: GraphQLTypes['AutoApprovalCriteria'] | undefined
    enabled?: boolean | undefined
  }
  ['AutoBuyDistribution']: {
    __typename: 'AutoBuyDistribution'
    createdAt: string
    description?: string | undefined
    id: string
    name: string
    schedule: Array<number>
    updatedAt: string
  }
  ['AutoBuyFlowConfiguration']: {
    __typename: 'AutoBuyFlowConfiguration'
    createdAt: string
    dailyPurchaseMax?: number | undefined
    delayStrategy?: GraphQLTypes['AutoBuyDelayStrategy'] | undefined
    flowId: string
    insufficientCreators?: string | undefined
    latestSkipReason?: GraphQLTypes['AutoBuySkipReason'] | undefined
    pauseReason?: GraphQLTypes['AutoBuySkipReason'] | undefined
    releaseSchedule?: GraphQLTypes['ModelAutoBuyFlowReleaseScheduleConnection'] | undefined
    schedule?: Array<GraphQLTypes['AutoBuySchedule']> | undefined
    startDate: string
    status: GraphQLTypes['AutoBuyStatus']
    updatedAt: string
  }
  ['AutoBuyFlowReleaseSchedule']: {
    __typename: 'AutoBuyFlowReleaseSchedule'
    actualPurchases?: number | undefined
    actualVerifiedPurchases?: number | undefined
    adjustedDesiredPurchases?: number | undefined
    autoApprovalSeats?: number | undefined
    createdAt: string
    date: string
    desiredPurchases?: number | undefined
    flowId: string
    manualPurchases?: number | undefined
    manualReleases?: number | undefined
    projectedReleaseCount?: number | undefined
    releaseCount?: number | undefined
    released?: boolean | undefined
    skippedReason?: GraphQLTypes['AutoBuySkipReason'] | undefined
    updatedAt: string
  }
  ['AutoBuyRelease']: {
    __typename: 'AutoBuyRelease'
    createdAt: string
    date: string
    productId: string
    releaseDateTime: string
    skuId?: string | undefined
    updatedAt: string
    userCampaignId: string
  }
  ['AutoBuySchedule']: {
    __typename: 'AutoBuySchedule'
    date: string
    desiredPurchaseCount: number
    willPurchasePercentage?: number | undefined
  }
  ['BillingContact']: {
    __typename: 'BillingContact'
    email: string
    name: string
  }
  ['Brand']: {
    __typename: 'Brand'
    active?: string | undefined
    amazonBrandName?: string | undefined
    brandApis?: GraphQLTypes['ModelBrandApiConnection'] | undefined
    brandDetailsSubmitted?: boolean | undefined
    brandInstaHandle?: string | undefined
    brandLogoImageKey?: string | undefined
    brandStoreConfirmationImage?: string | undefined
    brandStoreConfirmationMobileImage?: string | undefined
    brandStoreScrapeUrl?: string | undefined
    brandTikTokHandle?: string | undefined
    companyId?: string | undefined
    contactEmails?: Array<string | undefined> | undefined
    createdAt: string
    defaultCreatorPreference?: GraphQLTypes['CreatorPreference'] | undefined
    defaultCreatorPreferenceDetails?: string | undefined
    defaultHashtags?: Array<string> | undefined
    defaultMarketingBriefKey?: string | undefined
    defaultPostingGuidelines?: Array<string> | undefined
    defaultPostingInspirationLinks?: Array<string> | undefined
    emailsSent?: Array<string | undefined> | undefined
    fullScrapedAt?: string | undefined
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined
    isSeller?: string | undefined
    isVendor?: string | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    lastScrapedAt?: string | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    paymentBillingContact?: GraphQLTypes['BillingContact'] | undefined
    platform?: GraphQLTypes['PlatformSource'] | undefined
    postingGuidanceDocument?: string | undefined
    region?: GraphQLTypes['WSLocale'] | undefined
    socialMediaExampleUrls?: Array<string> | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    updatedAt: string
    walmartBrandName?: string | undefined
    walmartLastScrapedAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['BrandApi']: {
    __typename: 'BrandApi'
    accessToken?: string | undefined
    api: GraphQLTypes['BrandApiType']
    apiVersion?: GraphQLTypes['BrandApiVersion'] | undefined
    brandId: string
    createdAt: string
    enabled?: boolean | undefined
    locale?: GraphQLTypes['WSLocale'] | undefined
    refreshToken?: string | undefined
    shop?: string | undefined
    shopId?: string | undefined
    updatedAt: string
  }
  ['BudgetDetail']: {
    __typename: 'BudgetDetail'
    authorizedUnits?: number | undefined
    baseUnits?: number | undefined
    budgetSpent?: number | undefined
    budgetSplit?: Array<GraphQLTypes['BudgetSplitComponent']> | undefined
    committedReviewsCount?: number | undefined
    contentGuidelinesSubmitted?: boolean | undefined
    creatorTargetingEnabled?: boolean | undefined
    creatorTargetingSubmitted?: boolean | undefined
    lifestyleCommittedContentCount?: number | undefined
    marketingFee?: number | undefined
    premiumLifestyleCommittedContentCount?: number | undefined
    productKeywordsEnabled?: boolean | undefined
    productKeywordsSubmitted?: boolean | undefined
    promoCodeSubmitted?: boolean | undefined
    skuId?: string | undefined
    socialCommittedContentCount?: number | undefined
    totalProductCost?: number | undefined
  }
  ['BudgetSplitComponent']: {
    __typename: 'BudgetSplitComponent'
    autoApprovalSeatsAvailable?: number | undefined
    autoBuyActive?: GraphQLTypes['Paused'] | undefined
    autoBuySchedule?: string | undefined
    count?: number | undefined
    productId?: string | undefined
    unitsPurchased?: number | undefined
  }
  ['Campaign']: {
    __typename: 'Campaign'
    aboutSection?: string | undefined
    active: string
    actualStartDate?: string | undefined
    amountPaidByClient?: number | undefined
    anticipatedStartDate: string
    applicationForm?: string | undefined
    applicationProductLink?: GraphQLTypes['UserLink'] | undefined
    applicationProductLinkId?: string | undefined
    applicationStatus?: GraphQLTypes['ApplicationStatus'] | undefined
    attributionRefUrlSuffix?: string | undefined
    authorizedUnits?: number | undefined
    badges?: Array<GraphQLTypes['CampaignBadge'] | undefined> | undefined
    brand?: GraphQLTypes['Brand'] | undefined
    brandId: string
    brandProductShort?: string | undefined
    brandStoreLink?: string | undefined
    budgetSpent?: number | undefined
    campaignProductType?: GraphQLTypes['CampaignProductType'] | undefined
    campaignProducts?: GraphQLTypes['ModelProductConnection'] | undefined
    campaignReferralBonusAmount?: number | undefined
    campaignReferralBonusEnabled?: GraphQLTypes['CampaignReferralBonusFlag'] | undefined
    campaignStateMachine?: string | undefined
    closeoutReleaseFailureReason?: string | undefined
    closeoutReleaseRate?: GraphQLTypes['Rate'] | undefined
    collisionSkuDensity?: Array<GraphQLTypes['CollisionDensityValue']> | undefined
    commissionFlatRate?: number | undefined
    commissionPercentageRate?: number | undefined
    committedClicks?: number | undefined
    committedPlacementWinsCount?: number | undefined
    committedReviewsCount?: number | undefined
    contentApprovalRequired?: boolean | undefined
    contentCompleteDeadline?: string | undefined
    createdAt: string
    creationSourceVersion?: GraphQLTypes['CreationSourceVersion'] | undefined
    dailyClicks?: GraphQLTypes['ModelCampaignClicksConnection'] | undefined
    earlyReviewEnabled?: boolean | undefined
    enableContentComplianceReview?: boolean | undefined
    endDate?: string | undefined
    excludeGender?: GraphQLTypes['UserFilterGender'] | undefined
    expectedBonus?: string | undefined
    followersCountCeiling?: number | undefined
    followersCountFloor?: number | undefined
    goal?: GraphQLTypes['CampaignGoal'] | undefined
    id: string
    influencerApprovalEnabled?: GraphQLTypes['InfluencerApprovalFlagV2'] | undefined
    lifestyleCommittedContentCount?: number | undefined
    localeV1?: GraphQLTypes['WSLocale'] | undefined
    marketingFee?: number | undefined
    marketingFormFilled?: boolean | undefined
    newCreatorsOnly?: boolean | undefined
    payForClicksEnabled?: GraphQLTypes['EnabledFlag'] | undefined
    payForClicksGeneratedPerPeriod?: Array<GraphQLTypes['ClicksRecords']> | undefined
    payForClicksMinClicks?: number | undefined
    payForClicksOriginalLink?: string | undefined
    payForClicksTotalClicksGenerated?: number | undefined
    payForPerformanceEnabled?: GraphQLTypes['PayForPerformanceFlag'] | undefined
    postingGuidance?: string | undefined
    preApplicationProductImage?: string | undefined
    preApplicationProductPriceRange?: string | undefined
    preApplicationUgcProductImage?: string | undefined
    preLaunchDate?: string | undefined
    premiumLifestyleCommittedContentCount?: number | undefined
    productKeywords?: GraphQLTypes['ModelProductKeywordsConnection'] | undefined
    requiredProductLinksEnabled?: GraphQLTypes['RequiredProductLinkFlag'] | undefined
    reviewVotingTarget?: string | undefined
    sample?: boolean | undefined
    shellCampaignBase?: string | undefined
    sku: GraphQLTypes['Sku']
    skuId: string
    skuPriceOverride?: number | undefined
    socialCommittedContentCount?: number | undefined
    sourcingPriority?: number | undefined
    sponsorshipInstructions?: string | undefined
    title: string
    totalEarlyReviewsAllowed?: number | undefined
    totalProductCost?: number | undefined
    updatedAt: string
    userAutoApproved?: boolean | undefined
    visibleToUser: string
  }
  ['CampaignBadge']: {
    __typename: 'CampaignBadge'
    type: GraphQLTypes['CampaignBadgeType']
    value: string
  }
  ['CampaignClicks']: {
    __typename: 'CampaignClicks'
    campaignId: string
    clicks: number
    createdAt: string
    date: string
    updatedAt: string
  }
  ['CampaignForUser']: {
    __typename: 'CampaignForUser'
    about?: string | undefined
    badges: Array<GraphQLTypes['CampaignBadge']>
    brandName: string
    campaignProductType: GraphQLTypes['CampaignProductType']
    campaignReferralBonusAmount?: number | undefined
    campaignReferralEnabled: boolean
    contentApprovalLeadDays?: number | undefined
    expectedBonusMax?: number | undefined
    expectedBonusMin?: number | undefined
    flowForUser?: GraphQLTypes['CampaignForUserFlow'] | undefined
    flows: Array<GraphQLTypes['CampaignForUserFlow']>
    hasUserApplied: boolean
    id: string
    isAdminOnlyView: boolean
    isFreeGift: boolean
    isPayForClicksEnabled: boolean
    isPayForPerformanceEnabled: boolean
    isShipToCustomer: boolean
    isUserAutoApproved: boolean
    locale: GraphQLTypes['WSLocale']
    payForPerformanceBonusFlatRate?: number | undefined
    payForPerformanceBonusPercentageRate?: number | undefined
    postingDeadline?: string | undefined
    preApplicationProductImage?: string | undefined
    preApplicationProductName?: string | undefined
    preApplicationProductPrice?: string | undefined
    preApplicationUgcProductImage?: string | undefined
    productForUser?: GraphQLTypes['CampaignForUserProduct'] | undefined
    productImage?: string | undefined
    productLink?: string | undefined
    productPrice?: number | undefined
    productTitle: string
    products: Array<GraphQLTypes['CampaignForUserProduct']>
    productsForUser?: Array<GraphQLTypes['CampaignForUserProduct']> | undefined
    sku?: string | undefined
    skuId: string
    specialInstructions?: string | undefined
    sponsorshipInstructions?: string | undefined
    storeName: string
    title?: string | undefined
    totalSeatsAvailable?: number | undefined
    userCampaignCreatedAt?: string | undefined
    userCampaignId?: string | undefined
    userCampaignStatus?: GraphQLTypes['CampaignStatus'] | undefined
    userSpecificBonusConfig?: GraphQLTypes['UserCampaignBonusConfig'] | undefined
  }
  ['CampaignForUserFlow']: {
    __typename: 'CampaignForUserFlow'
    autoApprovalSeatsAvailable?: number | undefined
    contentRequirement: Array<GraphQLTypes['ProductSocialContent']>
    customFtcHashtags?: Array<string> | undefined
    id: string
    isPayForClicksEnabled?: boolean | undefined
    payForImpressionsBonusConfig?: GraphQLTypes['PayForImpressionsBonusConfig'] | undefined
    qualityLevel?: GraphQLTypes['ProductQualityLevel'] | undefined
  }
  ['CampaignForUserProduct']: {
    __typename: 'CampaignForUserProduct'
    autoApprovalSeatsAvailable?: number | undefined
    contentRequirement: Array<GraphQLTypes['ProductSocialContent']>
    customFtcHashtags?: Array<string> | undefined
    flowId: string
    id: string
    image?: string | undefined
    isPayForClicksEnabled?: boolean | undefined
    payForImpressionsBonusConfig?: GraphQLTypes['PayForImpressionsBonusConfig'] | undefined
    price?: number | undefined
    productLink?: string | undefined
    sku?: string | undefined
    storeName: string
    title?: string | undefined
  }
  ['CampaignInvite']: {
    __typename: 'CampaignInvite'
    campaign?: GraphQLTypes['Campaign'] | undefined
    campaignId: string
    createdAt: string
    id: string
    notificationStatus: GraphQLTypes['NotificationStatus']
    priority: number
    updatedAt: string
    user?: GraphQLTypes['User'] | undefined
    userId: string
  }
  ['CampaignReferralBonus']: {
    __typename: 'CampaignReferralBonus'
    campaignName: string
    payoutDate?: string | undefined
    totalEarnings?: string | undefined
  }
  ['CampaignSourcingProspect']: {
    __typename: 'CampaignSourcingProspect'
    bonusConfig?: GraphQLTypes['UserCampaignBonusConfig'] | undefined
    campaignId: string
    createdAt: string
    createdById?: string | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    internalNotes?: GraphQLTypes['ModelNoteConnection'] | undefined
    internalNotesId?: string | undefined
    name?: string | undefined
    platformInviteSource: GraphQLTypes['PlatformInviteSource']
    platformStatus?: GraphQLTypes['PlatformStatus'] | undefined
    preApprovalStatus?: GraphQLTypes['PreApprovalStatus'] | undefined
    preApprovalStatusById?: string | undefined
    priority?: GraphQLTypes['SourcePriority'] | undefined
    productId?: string | undefined
    sourcingSource: GraphQLTypes['CampaignSourcingSource']
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    updatedAt: string
    user?: GraphQLTypes['User'] | undefined
    userCampaign?: GraphQLTypes['UserCampaign'] | undefined
    userCampaignId?: string | undefined
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
  }
  ['CardCreateOutput']: {
    __typename: 'CardCreateOutput'
    cardId?: string | undefined
  }
  ['CardDeleteOutput']: {
    __typename: 'CardDeleteOutput'
    cardId?: string | undefined
  }
  ['CardDetails']: {
    __typename: 'CardDetails'
    address: string
    cvc: string
    expMonth: string
    expYear: string
    last4: string
    name: string
    number: string
    primaryAccountIdentifier?: string | undefined
  }
  ['ChannelConfiguration']: {
    __typename: 'ChannelConfiguration'
    attributes?: Array<GraphQLTypes['KeyAttribute'] | undefined> | undefined
    enabled?: boolean | undefined
    fromAddress?: string | undefined
    templateId: string
  }
  ['ChatConversationV2']: {
    __typename: 'ChatConversationV2'
    active: GraphQLTypes['Active']
    adminLastReadMessageIndex?: number | undefined
    adminUnreadCount: number
    campaignId?: string | undefined
    chatType: GraphQLTypes['ChatType']
    createdAt: string
    creatorLastReadMessageIndex?: number | undefined
    creatorUnreadCount: number
    lastMessage?: string | undefined
    lastMessageDate?: string | undefined
    lastMessageId?: string | undefined
    lastMessageSource?: GraphQLTypes['ConversationMessageSource'] | undefined
    resourceId: string
    status: GraphQLTypes['ConversationInternalStatus']
    statusUpdatedAt?: string | undefined
    statusUpdatedByFirstName?: string | undefined
    statusUpdatedById?: string | undefined
    statusUpdatedByLastName?: string | undefined
    twilioConversationId: string
    updatedAt: string
    user?: GraphQLTypes['User'] | undefined
    userCampaignId?: string | undefined
    userEmail?: string | undefined
    userFirstName?: string | undefined
    userId: string
    userLastName?: string | undefined
  }
  ['ClicksRecords']: {
    __typename: 'ClicksRecords'
    clicks: number
    from: string
    to: string
  }
  ['CognitoUserAttribute']: {
    __typename: 'CognitoUserAttribute'
    Name: string
    Value: string
  }
  ['CognitoUserModel']: {
    __typename: 'CognitoUserModel'
    UserAttributes: Array<GraphQLTypes['CognitoUserAttribute'] | undefined>
    Username: string
  }
  ['CollisionDensityValue']: {
    __typename: 'CollisionDensityValue'
    density: number
    skuId: string
  }
  ['CommunicationNotification']: {
    __typename: 'CommunicationNotification'
    address: string
    attributes?: Array<GraphQLTypes['KeyAttribute']> | undefined
    campaignId?: string | undefined
    channel: GraphQLTypes['Channel']
    createdAt: string
    expectedSentAt: string
    experimentTreatment?: string | undefined
    id: string
    notificationId: string
    sentAt: string
    status?: string | undefined
    statusMessage?: string | undefined
    templateId?: string | undefined
    traceId: string
    updatedAt: string
    userCampaignId?: string | undefined
    userId: string
  }
  ['Company']: {
    __typename: 'Company'
    agencyId?: string | undefined
    brands?: GraphQLTypes['ModelBrandConnection'] | undefined
    createdAt: string
    id: string
    logo?: string | undefined
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: GraphQLTypes['PaymentTermsType'] | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    updatedAt: string
    websiteUrl?: string | undefined
  }
  ['ConnectedAccount']: {
    __typename: 'ConnectedAccount'
    accountId: string
    accountType: GraphQLTypes['ConnectedAccountType']
    createdAt?: string | undefined
    updatedAt?: string | undefined
    user?: GraphQLTypes['User'] | undefined
    userId: string
  }
  ['ContentApprovalConfiguration']: {
    __typename: 'ContentApprovalConfiguration'
    contentApprovalAssignee?: string | undefined
    contentApprovalType: GraphQLTypes['ContentApprovalType']
  }
  ['ContentComplianceReviewCriteria']: {
    __typename: 'ContentComplianceReviewCriteria'
    compliant?: boolean | undefined
    criteria: GraphQLTypes['ComplianceReviewCriteria']
    requirement?: string | undefined
  }
  ['ContentGuidance']: {
    __typename: 'ContentGuidance'
    instance: number
    text: string
  }
  ['ContentLabelResult']: {
    __typename: 'ContentLabelResult'
    confidence?: number | undefined
    label: GraphQLTypes['ContentSafetyLabel']
    severity?: number | undefined
  }
  ['ContentRatingSponsorshipContent']: {
    __typename: 'ContentRatingSponsorshipContent'
    inputSource?: GraphQLTypes['ContentRatingSponsorshipContentInputSource'] | undefined
    isCompliant?: boolean | undefined
    isInappropriate?: boolean | undefined
    link: string
    qualityRating?: number | undefined
  }
  ['ContentSafetyAnalysis']: {
    __typename: 'ContentSafetyAnalysis'
    isBrandSafe?: GraphQLTypes['BrandSafe'] | undefined
    summary?: Array<GraphQLTypes['ContentSafetyLabelSummary'] | undefined> | undefined
    textInstances?: Array<GraphQLTypes['ContentSafetyTextInstance'] | undefined> | undefined
  }
  ['ContentSafetyLabelSummary']: {
    __typename: 'ContentSafetyLabelSummary'
    confidence?: number | undefined
    label?: GraphQLTypes['ContentSafetyLabel'] | undefined
    severity?: GraphQLTypes['ContentSafetySeverity'] | undefined
  }
  ['ContentSafetySeverity']: {
    __typename: 'ContentSafetySeverity'
    high?: number | undefined
    low?: number | undefined
    medium?: number | undefined
  }
  ['ContentSafetyTextInstance']: {
    __typename: 'ContentSafetyTextInstance'
    labels?: Array<GraphQLTypes['ContentLabelResult'] | undefined> | undefined
    text: string
    timestampEnd?: number | undefined
    timestampStart?: number | undefined
  }
  ['Conversation']: {
    __typename: 'Conversation'
    contactOwner?: GraphQLTypes['User'] | undefined
    contactOwnerId?: string | undefined
    createdAt: string
    lastContactAt?: string | undefined
    messages?: GraphQLTypes['ModelConversationMessageConnection'] | undefined
    status?: GraphQLTypes['ConversationStatus'] | undefined
    statusChangedAt?: string | undefined
    updatedAt: string
    user: GraphQLTypes['User']
    userId: string
  }
  ['ConversationInternalStatusDetails']: {
    __typename: 'ConversationInternalStatusDetails'
    status?: GraphQLTypes['ConversationInternalStatus'] | undefined
    statusUpdatedAt?: string | undefined
    statusUpdatedByFirstName?: string | undefined
    statusUpdatedById?: string | undefined
    statusUpdatedByLastName?: string | undefined
  }
  ['ConversationMessage']: {
    __typename: 'ConversationMessage'
    channel: GraphQLTypes['ConversationChannel']
    conversation: GraphQLTypes['Conversation']
    createdAt: string
    id: string
    messageContent: string
    messageId?: string | undefined
    messageMediaKeys?: Array<string> | undefined
    replyFrom?: GraphQLTypes['User'] | undefined
    replyFromId?: string | undefined
    sendAt?: string | undefined
    sendAttempts?: number | undefined
    sentAt?: string | undefined
    status: GraphQLTypes['ConversationMessageStatus']
    updatedAt: string
    user?: GraphQLTypes['User'] | undefined
    userId: string
  }
  ['CreatorContentRatingRawValue']: {
    __typename: 'CreatorContentRatingRawValue'
    name: string
    value?: string | undefined
  }
  ['CreatorReferralBonus']: {
    __typename: 'CreatorReferralBonus'
    earnings: number
    payoutDate?: string | undefined
  }
  ['CreatorReferralSignup']: {
    __typename: 'CreatorReferralSignup'
    signupDate: string
  }
  ['EditUserSocialContentResponse']: {
    __typename: 'EditUserSocialContentResponse'
    externalUrl: string
    id: string
  }
  ['EtailerSkuMetrics']: {
    __typename: 'EtailerSkuMetrics'
    createdAt: string
    date: string
    pageViews?: number | undefined
    sku: string
    skuId: string
    totalSalesAmount?: number | undefined
    unitsSold?: number | undefined
    updatedAt: string
  }
  ['FormDefinition']: {
    __typename: 'FormDefinition'
    formFields?: Array<GraphQLTypes['FormFieldDefinition']> | undefined
    name?: string | undefined
    pages?: number | undefined
  }
  ['FormFieldDefinition']: {
    __typename: 'FormFieldDefinition'
    bonus?: string | undefined
    componentType?: GraphQLTypes['FormComponentType'] | undefined
    destination?: string | undefined
    formName?: string | undefined
    isProfileQuestion?: boolean | undefined
    link?: string | undefined
    options?: Array<string> | undefined
    page?: number | undefined
    placeholder?: string | undefined
    primaryText?: string | undefined
    secondaryText?: string | undefined
    sequenceComponent?: GraphQLTypes['FormSequenceComponent'] | undefined
    sequenceId?: string | undefined
    tooltipText?: string | undefined
    type?: GraphQLTypes['FormFieldType'] | undefined
    validation?: Array<GraphQLTypes['FormValidation']> | undefined
    withOther?: boolean | undefined
  }
  ['FormSequence']: {
    __typename: 'FormSequence'
    components?: Array<GraphQLTypes['FormSequenceComponent']> | undefined
    id: string
  }
  ['FormSequenceComponent']: {
    __typename: 'FormSequenceComponent'
    componentName: string
    componentType: GraphQLTypes['FormComponentType']
    content?: string | undefined
    id: string
    images?: Array<string> | undefined
    numbered?: boolean | undefined
    placeholder?: string | undefined
    type?: GraphQLTypes['FormFieldType'] | undefined
    validation?: Array<GraphQLTypes['FormValidationType']> | undefined
  }
  ['FormSequenceConfiguration']: {
    __typename: 'FormSequenceConfiguration'
    active: GraphQLTypes['Active']
    createdAt: string
    id: string
    sequenceName: string
    sequences?: Array<GraphQLTypes['FormSequence']> | undefined
    updatedAt: string
  }
  ['FormValidation']: {
    __typename: 'FormValidation'
    type: GraphQLTypes['FormValidationType']
    validationText?: string | undefined
  }
  ['GetConversationsAccessTokenResponse']: {
    __typename: 'GetConversationsAccessTokenResponse'
    accessToken: string
    ttl?: number | undefined
  }
  ['GetFeatureFlagResponse']: {
    __typename: 'GetFeatureFlagResponse'
    value?: string | undefined
  }
  ['InspirationalContent']: {
    __typename: 'InspirationalContent'
    socialChannel?: GraphQLTypes['SocialChannel'] | undefined
    type: GraphQLTypes['InspirationalContentType']
    url: string
  }
  ['InstagramCountryDistributionItem']: {
    __typename: 'InstagramCountryDistributionItem'
    count: number
    countryCode: string
    pctDistribution: number
  }
  ['InstagramGenderAgeDistribution']: {
    __typename: 'InstagramGenderAgeDistribution'
    femaleCount: number
    genderAgeDistribution?: Array<GraphQLTypes['InstagramGenderAgeDistributionItem']> | undefined
    maleCount: number
    unspecifiedCount: number
  }
  ['InstagramGenderAgeDistributionItem']: {
    __typename: 'InstagramGenderAgeDistributionItem'
    ageRangeEnd?: number | undefined
    ageRangeStart: number
    count: number
    gender: GraphQLTypes['InstagramGenderAgeDistributionGender']
  }
  ['InstagramStoryScreen']: {
    __typename: 'InstagramStoryScreen'
    createdAt: string
    exits?: number | undefined
    expirationUnixTime: number
    externalId?: string | undefined
    id: string
    impressions?: number | undefined
    instagramUserId: string
    mediaId: string
    postedAt?: string | undefined
    postingDate: string
    reach?: number | undefined
    replies?: number | undefined
    s3Bucket?: string | undefined
    tapsBack?: number | undefined
    tapsForward?: number | undefined
    updatedAt: string
    userCampaignId?: string | undefined
  }
  ['KeyAttribute']: {
    __typename: 'KeyAttribute'
    key: string
    value: string
  }
  ['KeywordResponse']: {
    __typename: 'KeywordResponse'
    hash?: string | undefined
    keywordId?: string | undefined
  }
  ['ListCampaignsForUserResponse']: {
    __typename: 'ListCampaignsForUserResponse'
    campaigns: Array<GraphQLTypes['CampaignForUser']>
    emptyReason?: string | undefined
  }
  ['Message']: {
    __typename: 'Message'
    body: string
    createdAt?: string | undefined
    id: string
    updatedAt?: string | undefined
    userCampaign?: GraphQLTypes['UserCampaign'] | undefined
    userCampaignId?: string | undefined
    userHasSeen?: boolean | undefined
    userId: string
  }
  ['MessagingChannelConfiguration']: {
    __typename: 'MessagingChannelConfiguration'
    attributes?: Array<GraphQLTypes['KeyAttribute']> | undefined
    enabled: boolean
    templateContent: string
  }
  ['MinMaxRange']: {
    __typename: 'MinMaxRange'
    max?: number | undefined
    min?: number | undefined
  }
  ['ModelAutoBuyDistributionConnection']: {
    __typename: 'ModelAutoBuyDistributionConnection'
    items: Array<GraphQLTypes['AutoBuyDistribution'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelAutoBuyFlowConfigurationConnection']: {
    __typename: 'ModelAutoBuyFlowConfigurationConnection'
    items: Array<GraphQLTypes['AutoBuyFlowConfiguration'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelAutoBuyFlowReleaseScheduleConnection']: {
    __typename: 'ModelAutoBuyFlowReleaseScheduleConnection'
    items: Array<GraphQLTypes['AutoBuyFlowReleaseSchedule'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelAutoBuyReleaseConnection']: {
    __typename: 'ModelAutoBuyReleaseConnection'
    items: Array<GraphQLTypes['AutoBuyRelease'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelBrandApiConnection']: {
    __typename: 'ModelBrandApiConnection'
    items: Array<GraphQLTypes['BrandApi'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelBrandConnection']: {
    __typename: 'ModelBrandConnection'
    items: Array<GraphQLTypes['Brand'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCampaignClicksConnection']: {
    __typename: 'ModelCampaignClicksConnection'
    items: Array<GraphQLTypes['CampaignClicks'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCampaignConnection']: {
    __typename: 'ModelCampaignConnection'
    items: Array<GraphQLTypes['Campaign'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCampaignInviteConnection']: {
    __typename: 'ModelCampaignInviteConnection'
    items: Array<GraphQLTypes['CampaignInvite'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCampaignSourcingProspectConnection']: {
    __typename: 'ModelCampaignSourcingProspectConnection'
    items: Array<GraphQLTypes['CampaignSourcingProspect'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelChatConversationV2Connection']: {
    __typename: 'ModelChatConversationV2Connection'
    items: Array<GraphQLTypes['ChatConversationV2'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCommunicationNotificationConnection']: {
    __typename: 'ModelCommunicationNotificationConnection'
    items: Array<GraphQLTypes['CommunicationNotification'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelCompanyConnection']: {
    __typename: 'ModelCompanyConnection'
    items: Array<GraphQLTypes['Company'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelConnectedAccountConnection']: {
    __typename: 'ModelConnectedAccountConnection'
    items: Array<GraphQLTypes['ConnectedAccount'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelConversationConnection']: {
    __typename: 'ModelConversationConnection'
    items: Array<GraphQLTypes['Conversation'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelConversationMessageConnection']: {
    __typename: 'ModelConversationMessageConnection'
    items: Array<GraphQLTypes['ConversationMessage'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelEtailerSkuMetricsConnection']: {
    __typename: 'ModelEtailerSkuMetricsConnection'
    items: Array<GraphQLTypes['EtailerSkuMetrics'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelFormSequenceConfigurationConnection']: {
    __typename: 'ModelFormSequenceConfigurationConnection'
    items: Array<GraphQLTypes['FormSequenceConfiguration'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelInstagramStoryScreenConnection']: {
    __typename: 'ModelInstagramStoryScreenConnection'
    items: Array<GraphQLTypes['InstagramStoryScreen'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelMessageConnection']: {
    __typename: 'ModelMessageConnection'
    items: Array<GraphQLTypes['Message'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelNoteConnection']: {
    __typename: 'ModelNoteConnection'
    items: Array<GraphQLTypes['Note'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelNotificationConfigurationConnection']: {
    __typename: 'ModelNotificationConfigurationConnection'
    items: Array<GraphQLTypes['NotificationConfiguration'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelProductBonusConfigSnapshotConnection']: {
    __typename: 'ModelProductBonusConfigSnapshotConnection'
    items: Array<GraphQLTypes['ProductBonusConfigSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelProductConnection']: {
    __typename: 'ModelProductConnection'
    items: Array<GraphQLTypes['Product'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelProductKeywordsConnection']: {
    __typename: 'ModelProductKeywordsConnection'
    items: Array<GraphQLTypes['ProductKeywords'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelProductRatingSnapshotConnection']: {
    __typename: 'ModelProductRatingSnapshotConnection'
    items: Array<GraphQLTypes['ProductRatingSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelPurchaseConnection']: {
    __typename: 'ModelPurchaseConnection'
    items: Array<GraphQLTypes['Purchase'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelReferralConnection']: {
    __typename: 'ModelReferralConnection'
    items: Array<GraphQLTypes['Referral'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelReviewConnection']: {
    __typename: 'ModelReviewConnection'
    items: Array<GraphQLTypes['Review'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelSkuConnection']: {
    __typename: 'ModelSkuConnection'
    items: Array<GraphQLTypes['Sku'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelSkuSnapshotConnection']: {
    __typename: 'ModelSkuSnapshotConnection'
    items: Array<GraphQLTypes['SkuSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserBonusPayoutConnection']: {
    __typename: 'ModelUserBonusPayoutConnection'
    items: Array<GraphQLTypes['UserBonusPayout'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignChangelogConnection']: {
    __typename: 'ModelUserCampaignChangelogConnection'
    items: Array<GraphQLTypes['UserCampaignChangelog'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignConnection']: {
    __typename: 'ModelUserCampaignConnection'
    items: Array<GraphQLTypes['UserCampaign'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignSnapshotConnection']: {
    __typename: 'ModelUserCampaignSnapshotConnection'
    items: Array<GraphQLTypes['UserCampaignSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignSocialContentConnection']: {
    __typename: 'ModelUserCampaignSocialContentConnection'
    items: Array<GraphQLTypes['UserCampaignSocialContent'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignSocialContentDraftHistoryConnection']: {
    __typename: 'ModelUserCampaignSocialContentDraftHistoryConnection'
    items: Array<GraphQLTypes['UserCampaignSocialContentDraftHistory'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserCampaignSocialContentReviewConnection']: {
    __typename: 'ModelUserCampaignSocialContentReviewConnection'
    items: Array<GraphQLTypes['UserCampaignSocialContentReview'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserConnection']: {
    __typename: 'ModelUserConnection'
    items: Array<GraphQLTypes['User'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserDeviceSnapshotConnection']: {
    __typename: 'ModelUserDeviceSnapshotConnection'
    items: Array<GraphQLTypes['UserDeviceSnapshot'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserFollowerConnection']: {
    __typename: 'ModelUserFollowerConnection'
    items: Array<GraphQLTypes['UserFollower'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserLinkConnection']: {
    __typename: 'ModelUserLinkConnection'
    items: Array<GraphQLTypes['UserLink'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserNotificationPreferenceConnection']: {
    __typename: 'ModelUserNotificationPreferenceConnection'
    items: Array<GraphQLTypes['UserNotificationPreference'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserPayoutRequestConnection']: {
    __typename: 'ModelUserPayoutRequestConnection'
    items: Array<GraphQLTypes['UserPayoutRequest'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelUserStagingApprovalConnection']: {
    __typename: 'ModelUserStagingApprovalConnection'
    items: Array<GraphQLTypes['UserStagingApproval'] | undefined>
    nextToken?: string | undefined
  }
  ['ModelVideoContentScanConnection']: {
    __typename: 'ModelVideoContentScanConnection'
    items: Array<GraphQLTypes['VideoContentScan'] | undefined>
    nextToken?: string | undefined
  }
  ['Mutation']: {
    __typename: 'Mutation'
    adminSendPushMessage?: GraphQLTypes['AdminSendPushMessageResponse'] | undefined
    applyToCampaign?: GraphQLTypes['ApplyToCampaignOutput'] | undefined
    approveUserCampaignForPurchase?: GraphQLTypes['ApproveUserCampaignForPurchaseResponse'] | undefined
    createAutoBuyDistribution?: GraphQLTypes['AutoBuyDistribution'] | undefined
    createAutoBuyFlowConfiguration?: GraphQLTypes['AutoBuyFlowConfiguration'] | undefined
    createAutoBuyFlowReleaseSchedule?: GraphQLTypes['AutoBuyFlowReleaseSchedule'] | undefined
    createAutoBuyRelease?: GraphQLTypes['AutoBuyRelease'] | undefined
    createBrand?: GraphQLTypes['Brand'] | undefined
    createBrandApi?: GraphQLTypes['BrandApi'] | undefined
    createCampaign?: GraphQLTypes['Campaign'] | undefined
    createCampaignClicks?: GraphQLTypes['CampaignClicks'] | undefined
    createCampaignInvite?: GraphQLTypes['CampaignInvite'] | undefined
    createCampaignSourcingProspect?: GraphQLTypes['CampaignSourcingProspect'] | undefined
    createChatConversationV2?: GraphQLTypes['ChatConversationV2'] | undefined
    createCommunicationNotification?: GraphQLTypes['CommunicationNotification'] | undefined
    createCompany?: GraphQLTypes['Company'] | undefined
    createConnectedAccount?: GraphQLTypes['ConnectedAccount'] | undefined
    createConversation?: GraphQLTypes['Conversation'] | undefined
    createConversationMessage?: GraphQLTypes['ConversationMessage'] | undefined
    createEtailerSkuMetrics?: GraphQLTypes['EtailerSkuMetrics'] | undefined
    createFormSequenceConfiguration?: GraphQLTypes['FormSequenceConfiguration'] | undefined
    createInstagramStoryScreen?: GraphQLTypes['InstagramStoryScreen'] | undefined
    createManualPurchase?: GraphQLTypes['UserCampaign'] | undefined
    createMessage?: GraphQLTypes['Message'] | undefined
    createNote?: GraphQLTypes['Note'] | undefined
    createNotificationConfiguration?: GraphQLTypes['NotificationConfiguration'] | undefined
    createProduct?: GraphQLTypes['Product'] | undefined
    createProductBonusConfigSnapshot?: GraphQLTypes['ProductBonusConfigSnapshot'] | undefined
    createProductFromUrl?: Array<GraphQLTypes['Sku']> | undefined
    createProductKeywords?: GraphQLTypes['ProductKeywords'] | undefined
    createProductRatingSnapshot?: GraphQLTypes['ProductRatingSnapshot'] | undefined
    createPurchase?: GraphQLTypes['Purchase'] | undefined
    createReferral?: GraphQLTypes['Referral'] | undefined
    createReview?: GraphQLTypes['Review'] | undefined
    createShortLink: GraphQLTypes['UserLink']
    createSku?: GraphQLTypes['Sku'] | undefined
    createSkuSnapshot?: GraphQLTypes['SkuSnapshot'] | undefined
    createTwilioConversation: GraphQLTypes['ChatConversationV2']
    createUser?: GraphQLTypes['User'] | undefined
    createUserBonusPayout?: GraphQLTypes['UserBonusPayout'] | undefined
    createUserCampaign?: GraphQLTypes['UserCampaign'] | undefined
    createUserCampaignChangelog?: GraphQLTypes['UserCampaignChangelog'] | undefined
    createUserCampaignSnapshot?: GraphQLTypes['UserCampaignSnapshot'] | undefined
    createUserCampaignSocialContent?: GraphQLTypes['UserCampaignSocialContent'] | undefined
    createUserCampaignSocialContentDraftHistory?: GraphQLTypes['UserCampaignSocialContentDraftHistory'] | undefined
    createUserCampaignSocialContentReview?: GraphQLTypes['UserCampaignSocialContentReview'] | undefined
    createUserDeviceSnapshot?: GraphQLTypes['UserDeviceSnapshot'] | undefined
    createUserFollower?: GraphQLTypes['UserFollower'] | undefined
    createUserLink?: GraphQLTypes['UserLink'] | undefined
    createUserNotificationPreference?: GraphQLTypes['UserNotificationPreference'] | undefined
    createUserPayoutRequest?: GraphQLTypes['UserPayoutRequest'] | undefined
    createUserStagingApproval?: GraphQLTypes['UserStagingApproval'] | undefined
    createVideoContentScan?: GraphQLTypes['VideoContentScan'] | undefined
    deleteAutoBuyDistribution?: GraphQLTypes['AutoBuyDistribution'] | undefined
    deleteAutoBuyFlowConfiguration?: GraphQLTypes['AutoBuyFlowConfiguration'] | undefined
    deleteAutoBuyFlowReleaseSchedule?: GraphQLTypes['AutoBuyFlowReleaseSchedule'] | undefined
    deleteAutoBuyRelease?: GraphQLTypes['AutoBuyRelease'] | undefined
    deleteBrand?: GraphQLTypes['Brand'] | undefined
    deleteBrandApi?: GraphQLTypes['BrandApi'] | undefined
    deleteCampaign?: GraphQLTypes['Campaign'] | undefined
    deleteCampaignClicks?: GraphQLTypes['CampaignClicks'] | undefined
    deleteCampaignInvite?: GraphQLTypes['CampaignInvite'] | undefined
    deleteCampaignSourcingProspect?: GraphQLTypes['CampaignSourcingProspect'] | undefined
    deleteChatConversationV2?: GraphQLTypes['ChatConversationV2'] | undefined
    deleteCommunicationNotification?: GraphQLTypes['CommunicationNotification'] | undefined
    deleteCompany?: GraphQLTypes['Company'] | undefined
    deleteConnectedAccount?: GraphQLTypes['ConnectedAccount'] | undefined
    deleteConversation?: GraphQLTypes['Conversation'] | undefined
    deleteConversationMessage?: GraphQLTypes['ConversationMessage'] | undefined
    deleteEtailerSkuMetrics?: GraphQLTypes['EtailerSkuMetrics'] | undefined
    deleteFormSequenceConfiguration?: GraphQLTypes['FormSequenceConfiguration'] | undefined
    deleteInstagramStoryScreen?: GraphQLTypes['InstagramStoryScreen'] | undefined
    deleteMessage?: GraphQLTypes['Message'] | undefined
    deleteNote?: GraphQLTypes['Note'] | undefined
    deleteNotificationConfiguration?: GraphQLTypes['NotificationConfiguration'] | undefined
    deleteProduct?: GraphQLTypes['Product'] | undefined
    deleteProductBonusConfigSnapshot?: GraphQLTypes['ProductBonusConfigSnapshot'] | undefined
    deleteProductKeywords?: GraphQLTypes['ProductKeywords'] | undefined
    deleteProductRatingSnapshot?: GraphQLTypes['ProductRatingSnapshot'] | undefined
    deletePurchase?: GraphQLTypes['Purchase'] | undefined
    deleteReferral?: GraphQLTypes['Referral'] | undefined
    deleteReview?: GraphQLTypes['Review'] | undefined
    deleteSku?: GraphQLTypes['Sku'] | undefined
    deleteSkuSnapshot?: GraphQLTypes['SkuSnapshot'] | undefined
    deleteUser?: GraphQLTypes['User'] | undefined
    deleteUserBonusPayout?: GraphQLTypes['UserBonusPayout'] | undefined
    deleteUserCampaign?: GraphQLTypes['UserCampaign'] | undefined
    deleteUserCampaignChangelog?: GraphQLTypes['UserCampaignChangelog'] | undefined
    deleteUserCampaignSnapshot?: GraphQLTypes['UserCampaignSnapshot'] | undefined
    deleteUserCampaignSocialContent?: GraphQLTypes['UserCampaignSocialContent'] | undefined
    deleteUserCampaignSocialContentDraftHistory?: GraphQLTypes['UserCampaignSocialContentDraftHistory'] | undefined
    deleteUserCampaignSocialContentReview?: GraphQLTypes['UserCampaignSocialContentReview'] | undefined
    deleteUserDeviceSnapshot?: GraphQLTypes['UserDeviceSnapshot'] | undefined
    deleteUserFollower?: GraphQLTypes['UserFollower'] | undefined
    deleteUserLink?: GraphQLTypes['UserLink'] | undefined
    deleteUserNotificationPreference?: GraphQLTypes['UserNotificationPreference'] | undefined
    deleteUserPayoutRequest?: GraphQLTypes['UserPayoutRequest'] | undefined
    deleteUserStagingApproval?: GraphQLTypes['UserStagingApproval'] | undefined
    deleteVideoContentScan?: GraphQLTypes['VideoContentScan'] | undefined
    editUserSocialContent: GraphQLTypes['EditUserSocialContentResponse']
    executeBrandSafetyAnalysis: boolean
    generateSocialVerificationCode: GraphQLTypes['SocialVerificationCode']
    handleUserCampaignStagingApprovals?: Array<GraphQLTypes['UserStagingApproval']> | undefined
    inviteUsersToCampaign: Array<GraphQLTypes['CampaignInvite']>
    markCreatorAsFavorite: GraphQLTypes['UserFollower']
    processSelectedInstagramStories?: Array<GraphQLTypes['InstagramStoryScreen'] | undefined> | undefined
    purchasesCardCreate: GraphQLTypes['CardCreateOutput']
    purchasesCardDelete: GraphQLTypes['CardDeleteOutput']
    purchasesEphemeralCreate: string
    refreshSocialToken?: string | undefined
    requestUserBalancePayout?: Array<GraphQLTypes['UserPayoutRequest']> | undefined
    requestUserDeactivation?: string | undefined
    resetUnverifiedUserAttributes?: GraphQLTypes['ResetUserAttributeResponse'] | undefined
    resetUserEmail?: GraphQLTypes['ResetUserAttributeResponse'] | undefined
    resetUserPhoneNumber?: GraphQLTypes['ResetUserAttributeResponse'] | undefined
    rollbackUserCampaign?: GraphQLTypes['UserCampaign'] | undefined
    scrapeMediaAssetToStorage: string
    socialUnlink: GraphQLTypes['SocialUnlinkResult']
    submitUserCampaignStateChange: GraphQLTypes['UserCampaign']
    updateAutoBuyDistribution?: GraphQLTypes['AutoBuyDistribution'] | undefined
    updateAutoBuyFlowConfiguration?: GraphQLTypes['AutoBuyFlowConfiguration'] | undefined
    updateAutoBuyFlowReleaseSchedule?: GraphQLTypes['AutoBuyFlowReleaseSchedule'] | undefined
    updateAutoBuyRelease?: GraphQLTypes['AutoBuyRelease'] | undefined
    updateBrand?: GraphQLTypes['Brand'] | undefined
    updateBrandApi?: GraphQLTypes['BrandApi'] | undefined
    updateCampaign?: GraphQLTypes['Campaign'] | undefined
    updateCampaignClicks?: GraphQLTypes['CampaignClicks'] | undefined
    updateCampaignInvite?: GraphQLTypes['CampaignInvite'] | undefined
    updateCampaignSourcingProspect?: GraphQLTypes['CampaignSourcingProspect'] | undefined
    updateChatConversationV2?: GraphQLTypes['ChatConversationV2'] | undefined
    updateCommunicationNotification?: GraphQLTypes['CommunicationNotification'] | undefined
    updateCompany?: GraphQLTypes['Company'] | undefined
    updateConnectedAccount?: GraphQLTypes['ConnectedAccount'] | undefined
    updateContentApprovals: Array<GraphQLTypes['UserCampaignSocialContentReview']>
    updateConversation?: GraphQLTypes['Conversation'] | undefined
    updateConversationMessage?: GraphQLTypes['ConversationMessage'] | undefined
    updateEtailerSkuMetrics?: GraphQLTypes['EtailerSkuMetrics'] | undefined
    updateFormSequenceConfiguration?: GraphQLTypes['FormSequenceConfiguration'] | undefined
    updateInstagramStoryScreen?: GraphQLTypes['InstagramStoryScreen'] | undefined
    updateMessage?: GraphQLTypes['Message'] | undefined
    updateNote?: GraphQLTypes['Note'] | undefined
    updateNotificationConfiguration?: GraphQLTypes['NotificationConfiguration'] | undefined
    updateProduct?: GraphQLTypes['Product'] | undefined
    updateProductBonusConfigSnapshot?: GraphQLTypes['ProductBonusConfigSnapshot'] | undefined
    updateProductKeywords?: GraphQLTypes['ProductKeywords'] | undefined
    updateProductRatingSnapshot?: GraphQLTypes['ProductRatingSnapshot'] | undefined
    updatePurchase?: GraphQLTypes['Purchase'] | undefined
    updateReferral?: GraphQLTypes['Referral'] | undefined
    updateReview?: GraphQLTypes['Review'] | undefined
    updateSku?: GraphQLTypes['Sku'] | undefined
    updateSkuSnapshot?: GraphQLTypes['SkuSnapshot'] | undefined
    updateUser?: GraphQLTypes['User'] | undefined
    updateUserBonusPayout?: GraphQLTypes['UserBonusPayout'] | undefined
    updateUserCampaign?: GraphQLTypes['UserCampaign'] | undefined
    updateUserCampaignChangelog?: GraphQLTypes['UserCampaignChangelog'] | undefined
    updateUserCampaignSnapshot?: GraphQLTypes['UserCampaignSnapshot'] | undefined
    updateUserCampaignSocialContent?: GraphQLTypes['UserCampaignSocialContent'] | undefined
    updateUserCampaignSocialContentDraftHistory?: GraphQLTypes['UserCampaignSocialContentDraftHistory'] | undefined
    updateUserCampaignSocialContentReview?: GraphQLTypes['UserCampaignSocialContentReview'] | undefined
    updateUserDeviceSnapshot?: GraphQLTypes['UserDeviceSnapshot'] | undefined
    updateUserFollower?: GraphQLTypes['UserFollower'] | undefined
    updateUserLink?: GraphQLTypes['UserLink'] | undefined
    updateUserNotificationPreference?: GraphQLTypes['UserNotificationPreference'] | undefined
    updateUserPayoutRequest?: GraphQLTypes['UserPayoutRequest'] | undefined
    updateUserPhone: GraphQLTypes['UpdateUserPhoneResult']
    updateUserStagingApproval?: GraphQLTypes['UserStagingApproval'] | undefined
    updateVideoContentScan?: GraphQLTypes['VideoContentScan'] | undefined
    verifySocialVerificationCode: GraphQLTypes['VerifySocialCodeResult']
  }
  ['Note']: {
    __typename: 'Note'
    createdAt: string
    id: string
    note: string
    parentId: string
    updatedAt: string
    user?: GraphQLTypes['User'] | undefined
    userId: string
  }
  ['NotificationConfiguration']: {
    __typename: 'NotificationConfiguration'
    category: string
    createdAt: string
    description?: string | undefined
    email?: GraphQLTypes['ChannelConfiguration'] | undefined
    enabled?: boolean | undefined
    id: string
    inAppChatChannel?: GraphQLTypes['MessagingChannelConfiguration'] | undefined
    name: string
    occurrenceConstraint?: GraphQLTypes['OccurrenceConstraint'] | undefined
    push?: GraphQLTypes['PushChannelConfiguration'] | undefined
    sms?: GraphQLTypes['ChannelConfiguration'] | undefined
    updatedAt: string
  }
  ['OccurrenceConstraint']: {
    __typename: 'OccurrenceConstraint'
    period?: GraphQLTypes['OccurrenceConstraintPeriod'] | undefined
    type: GraphQLTypes['OccurrenceConstraintType']
    value?: number | undefined
  }
  ['OrderConfirmationScreenshotVerificationOutput']: {
    __typename: 'OrderConfirmationScreenshotVerificationOutput'
    orderDate?: string | undefined
    orderId?: string | undefined
    orderTotal?: number | undefined
    rejectionMessage?: string | undefined
    result: GraphQLTypes['OrderConfirmationScreenshotVerificationResult']
  }
  ['PayForImpressionsBonusConfig']: {
    __typename: 'PayForImpressionsBonusConfig'
    enabled?: boolean | undefined
    growthRate?: number | undefined
    impressionsBaseline?: number | undefined
    impressionsCap?: number | undefined
    impressionsIncrement?: number | undefined
    initialBonus?: number | undefined
  }
  ['PayForPostingContentEarlyConfig']: {
    __typename: 'PayForPostingContentEarlyConfig'
    bonusCents?: number | undefined
    daysBeforeDeadline?: number | undefined
    enabled?: boolean | undefined
  }
  ['Product']: {
    __typename: 'Product'
    accountRequired?: boolean | undefined
    active?: string | undefined
    approvalFilters?: Array<GraphQLTypes['ApprovalFilterValue']> | undefined
    autoApprovalSeatsAvailable?: number | undefined
    autoApprovalStatusTiers?: Array<GraphQLTypes['StatusTier']> | undefined
    autoApprovalV2Config?: GraphQLTypes['AutoApprovalV2Config'] | undefined
    autoBuyConfiguration?: GraphQLTypes['AutoBuyFlowConfiguration'] | undefined
    autoBuyReleaseSchedule?: GraphQLTypes['ModelAutoBuyFlowReleaseScheduleConnection'] | undefined
    automaticReviewRelease?: boolean | undefined
    baseBonus?: number | undefined
    bonusHistory?: GraphQLTypes['ModelProductBonusConfigSnapshotConnection'] | undefined
    brandAdvocateCount?: number | undefined
    brandTalkingPoints?: Array<string> | undefined
    buyOnlinePickupInStore?: GraphQLTypes['BOPISValue'] | undefined
    campaignId?: string | undefined
    campaignStateMachine?: string | undefined
    closeoutFormBonus?: number | undefined
    contentApprovalConfiguration?: Array<GraphQLTypes['ContentApprovalConfiguration']> | undefined
    contentApprovalLeadDays?: number | undefined
    contentRequirement?: Array<GraphQLTypes['ProductSocialContent']> | undefined
    createdAt: string
    creatorCount?: number | undefined
    creatorPreference?: GraphQLTypes['CreatorPreference'] | undefined
    creatorPreferenceDetails?: string | undefined
    customFtcHashtags?: Array<string> | undefined
    customHashtags?: Array<string> | undefined
    directPurchaseLink?: string | undefined
    engagementRateInstagramRangeRequirement?: GraphQLTypes['MinMaxRange'] | undefined
    engagementRateTiktokRangeRequirement?: GraphQLTypes['MinMaxRange'] | undefined
    followersInstagramRangeRequirement?: GraphQLTypes['MinMaxRange'] | undefined
    followersTiktokRangeRequirement?: GraphQLTypes['MinMaxRange'] | undefined
    hideBrandContentApprovals?: boolean | undefined
    id: string
    impressionsInstagramRangeRequirement?: GraphQLTypes['MinMaxRange'] | undefined
    impressionsTiktokRangeRequirement?: GraphQLTypes['MinMaxRange'] | undefined
    instaHandle?: string | undefined
    internalCreatorsStagingGuidance?: string | undefined
    internalDescription?: string | undefined
    internalName?: string | undefined
    internalNotes?: string | undefined
    isDirectListingPurchase?: boolean | undefined
    isQualityRequirementsEnabled?: boolean | undefined
    listingGuidance?: string | undefined
    maxDiscoveryBonus?: number | undefined
    minimumCreatorQualityScore?: number | undefined
    nonRetailReviewLink?: string | undefined
    nonRetailReviewWebsiteName?: string | undefined
    payForClicksEnabled?: GraphQLTypes['EnabledFlag'] | undefined
    payForClicksMinClicks?: number | undefined
    payForImpressionsBonus?: GraphQLTypes['PayForImpressionsBonusConfig'] | undefined
    payForPerformanceBonus?: GraphQLTypes['StatusTierBonusConfig'] | undefined
    payForPostingContentEarly?: GraphQLTypes['PayForPostingContentEarlyConfig'] | undefined
    postingDeadlineDaysRelativeToApproval?: number | undefined
    productLink?: GraphQLTypes['UserLink'] | undefined
    productLinkId?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    promoCode?: string | undefined
    promoCodeGuidance?: string | undefined
    promoCodeType?: GraphQLTypes['PromoCodeType'] | undefined
    promoDiscountAmount?: number | undefined
    promoDiscountType?: GraphQLTypes['PromoDiscountType'] | undefined
    promoEndDate?: string | undefined
    promoLink?: string | undefined
    promoStartDate?: string | undefined
    promotionLink?: GraphQLTypes['UserLink'] | undefined
    promotionLinkId?: string | undefined
    purchaseByPromoCode?: boolean | undefined
    purchaseDiscountCode?: string | undefined
    purchaseDiscountCodeDetails?: string | undefined
    purchaseDiscountCodePercentage?: string | undefined
    qualityLevel?: GraphQLTypes['ProductQualityLevel'] | undefined
    requestEarlyProductFeedback?: boolean | undefined
    requestProductFeedback?: boolean | undefined
    requiredProductLinkEnabled?: GraphQLTypes['RequiredProductLinkFlag'] | undefined
    sequence?: GraphQLTypes['FormSequenceConfiguration'] | undefined
    sequenceId?: string | undefined
    shopifyPriceRule?: string | undefined
    shopifyPromotionId?: string | undefined
    shopifyPromotionType?: GraphQLTypes['ShopifyPromotionType'] | undefined
    sku?: GraphQLTypes['Sku'] | undefined
    skuId: string
    socialMediaExampleUrls?: Array<string> | undefined
    stagingApprovalConfiguration?: Array<GraphQLTypes['StagingApprovalConfiguration']> | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined
    stagingRequirementType?: GraphQLTypes['StagingRequirementType'] | undefined
    stagingSocialQualityRequirement?: GraphQLTypes['SocialQuality'] | undefined
    tikTokHandle?: string | undefined
    unitsPurchased?: number | undefined
    updatedAt: string
    validateProductFoundVariations?: Array<string> | undefined
    visibleAt?: string | undefined
    visibleEndsAt?: string | undefined
  }
  ['ProductBonusConfigSnapshot']: {
    __typename: 'ProductBonusConfigSnapshot'
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<GraphQLTypes['ProductSocialContent']> | undefined
    createdAt: string
    discoveryBonus?: number | undefined
    payForImpressionsBonus?: GraphQLTypes['PayForImpressionsBonusConfig'] | undefined
    productId: string
    snapshotAt: string
    updatedAt: string
  }
  ['ProductKeywords']: {
    __typename: 'ProductKeywords'
    adminPriority?: number | undefined
    brandPriority?: number | undefined
    brandResponse?: GraphQLTypes['KeywordAcceptanceResponse'] | undefined
    campaignId: string
    createdAt: string
    creationSource?: GraphQLTypes['ProductKeywordCreationSource'] | undefined
    endDate?: string | undefined
    heliumAccount?: string | undefined
    heliumAsin?: string | undefined
    heliumLink?: string | undefined
    id: string
    keyword: string
    monthlySearchVolume?: number | undefined
    rank?: number | undefined
    rankingHistory?: Array<GraphQLTypes['RankPoint']> | undefined
    rankingLastSkippedAt?: string | undefined
    rankingLastSkippedByUserId?: string | undefined
    rankingLastUpdatedAt?: string | undefined
    rankingLastUpdatedByUserId?: string | undefined
    recommended?: boolean | undefined
    skuId?: string | undefined
    startDate?: string | undefined
    updatedAt: string
    usedForCampaign?: boolean | undefined
    visible?: boolean | undefined
    weight?: number | undefined
  }
  ['ProductRatingSnapshot']: {
    __typename: 'ProductRatingSnapshot'
    createdAt: string
    numFiveStar?: number | undefined
    numFourStar?: number | undefined
    numOneStar?: number | undefined
    numRatings?: number | undefined
    numReviews?: number | undefined
    numThreeStar?: number | undefined
    numTwoStar?: number | undefined
    rating?: number | undefined
    sku: string
    skuId: string
    snapshotAt: string
    updatedAt: string
  }
  ['ProductSocialContent']: {
    __typename: 'ProductSocialContent'
    bonus: number
    brandContentGuidance?: Array<GraphQLTypes['ContentGuidance']> | undefined
    brandContentRequirements?: Array<string> | undefined
    bronzeBonus?: number | undefined
    channel: GraphQLTypes['SocialChannel']
    diamondBonus?: number | undefined
    goldBonus?: number | undefined
    inspirationalContent?: Array<GraphQLTypes['InspirationalContent']> | undefined
    instances: number
    platinumBonus?: number | undefined
    requirementLevel: GraphQLTypes['ContentRequirementLevel']
    requiresManualApproval?: boolean | undefined
    silverBonus?: number | undefined
    talkingPoints?: Array<GraphQLTypes['ContentGuidance']> | undefined
    totalExpectedCount?: number | undefined
    type: GraphQLTypes['SocialContentType']
  }
  ['Purchase']: {
    __typename: 'Purchase'
    amount: number
    cardId?: string | undefined
    createdAt: string
    currency: string
    id: string
    isFraud?: boolean | undefined
    keywordAtPurchase?: string | undefined
    purchasedAt: string
    shippedAt?: string | undefined
    status: GraphQLTypes['PurchaseStatus']
    updatedAt: string
    userCampaignId: string
  }
  ['PushAddress']: {
    __typename: 'PushAddress'
    address?: string | undefined
    pushChannel?: GraphQLTypes['PushChannel'] | undefined
  }
  ['PushChannelConfiguration']: {
    __typename: 'PushChannelConfiguration'
    attributes?: Array<GraphQLTypes['KeyAttribute'] | undefined> | undefined
    enabled?: boolean | undefined
    templateId: string
  }
  ['Query']: {
    __typename: 'Query'
    ByAgencyId?: GraphQLTypes['ModelCompanyConnection'] | undefined
    ByBrandId?: GraphQLTypes['ModelCampaignConnection'] | undefined
    BySkuIdByStoreName?: GraphQLTypes['ModelSkuConnection'] | undefined
    ByVisibilityToUser?: GraphQLTypes['ModelCampaignConnection'] | undefined
    ByVisibilityToUserByCreatedAt?: GraphQLTypes['ModelCampaignConnection'] | undefined
    CampaignsBySkuId?: GraphQLTypes['ModelCampaignConnection'] | undefined
    asinSnapshotsBySkuId?: GraphQLTypes['ModelSkuSnapshotConnection'] | undefined
    autoBuyFlowConfigurationByStatusByStartDate?: GraphQLTypes['ModelAutoBuyFlowConfigurationConnection'] | undefined
    autoBuyReleasesByProductIdDate?: GraphQLTypes['ModelAutoBuyReleaseConnection'] | undefined
    brandApiByShopId?: GraphQLTypes['ModelBrandApiConnection'] | undefined
    brandStoreScrapeUrl?: GraphQLTypes['ModelBrandConnection'] | undefined
    brandsByActive?: GraphQLTypes['ModelBrandConnection'] | undefined
    brandsByActiveBySubscriptionEndsAt?: GraphQLTypes['ModelBrandConnection'] | undefined
    brandsByCompanyId?: GraphQLTypes['ModelBrandConnection'] | undefined
    brandsByPlatform?: GraphQLTypes['ModelBrandConnection'] | undefined
    byCampaignByCoarseCampaignStatus?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    bySkuBySnapshotAt?: GraphQLTypes['ModelProductRatingSnapshotConnection'] | undefined
    byUserByCoarseCampaignStatus?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    campaignInviteByCampaignByUser?: GraphQLTypes['ModelCampaignInviteConnection'] | undefined
    campaignInviteByNotificationStatusByPriority?: GraphQLTypes['ModelCampaignInviteConnection'] | undefined
    campaignInviteByUserByCreatedAt?: GraphQLTypes['ModelCampaignInviteConnection'] | undefined
    campaignSourcingProspectsByIgHandle?: GraphQLTypes['ModelCampaignSourcingProspectConnection'] | undefined
    campaignSourcingProspectsByTiktokHandle?: GraphQLTypes['ModelCampaignSourcingProspectConnection'] | undefined
    campaignSourcingProspectsByUserIdentifier?: GraphQLTypes['ModelCampaignSourcingProspectConnection'] | undefined
    campaignSourcingProspectsByYoutubeHandle?: GraphQLTypes['ModelCampaignSourcingProspectConnection'] | undefined
    campaignsByActive?: GraphQLTypes['ModelCampaignConnection'] | undefined
    campaignsByActiveByAnticipatedStartDate?: GraphQLTypes['ModelCampaignConnection'] | undefined
    campaignsByPayForPerformanceActive?: GraphQLTypes['ModelCampaignConnection'] | undefined
    chatConversationsV2ByActiveByAdminUnreadCount?: GraphQLTypes['ModelChatConversationV2Connection'] | undefined
    chatConversationsV2ByChatTypeByActive?: GraphQLTypes['ModelChatConversationV2Connection'] | undefined
    chatConversationsV2ByChatTypeByAdminUnreadCount?: GraphQLTypes['ModelChatConversationV2Connection'] | undefined
    chatConversationsV2ByUserIdByActive?: GraphQLTypes['ModelChatConversationV2Connection'] | undefined
    communicationNotificationsByTraceId?: GraphQLTypes['ModelCommunicationNotificationConnection'] | undefined
    communicationNotificationsByUserCampaignId?: GraphQLTypes['ModelCommunicationNotificationConnection'] | undefined
    communicationNotificationsByUserId?: GraphQLTypes['ModelCommunicationNotificationConnection'] | undefined
    communicationNotificationsByUserIdNotificationId?:
      | GraphQLTypes['ModelCommunicationNotificationConnection']
      | undefined
    connectedAccountsByAccountIdByAccountType?: GraphQLTypes['ModelConnectedAccountConnection'] | undefined
    conversationByContactOwnerIdByStatus?: GraphQLTypes['ModelConversationConnection'] | undefined
    conversationByStatusByStatusChange?: GraphQLTypes['ModelConversationConnection'] | undefined
    conversationMessageByMessageId?: GraphQLTypes['ModelConversationMessageConnection'] | undefined
    conversationMessageByStatusBySendAt?: GraphQLTypes['ModelConversationMessageConnection'] | undefined
    conversationMessageByStatusBySendAttempts?: GraphQLTypes['ModelConversationMessageConnection'] | undefined
    draftHistoryBySocialContentId?: GraphQLTypes['ModelUserCampaignSocialContentDraftHistoryConnection'] | undefined
    getAutoBuyDistribution?: GraphQLTypes['AutoBuyDistribution'] | undefined
    getAutoBuyFlowConfiguration?: GraphQLTypes['AutoBuyFlowConfiguration'] | undefined
    getAutoBuyFlowReleaseSchedule?: GraphQLTypes['AutoBuyFlowReleaseSchedule'] | undefined
    getAutoBuyRelease?: GraphQLTypes['AutoBuyRelease'] | undefined
    getBrand?: GraphQLTypes['Brand'] | undefined
    getBrandApi?: GraphQLTypes['BrandApi'] | undefined
    getCampaign?: GraphQLTypes['Campaign'] | undefined
    getCampaignClicks?: GraphQLTypes['CampaignClicks'] | undefined
    getCampaignDetailsForUser?: GraphQLTypes['CampaignForUser'] | undefined
    getCampaignInvite?: GraphQLTypes['CampaignInvite'] | undefined
    getCampaignSourcingProspect?: GraphQLTypes['CampaignSourcingProspect'] | undefined
    getChatConversationV2?: GraphQLTypes['ChatConversationV2'] | undefined
    getCommunicationNotification?: GraphQLTypes['CommunicationNotification'] | undefined
    getCompany?: GraphQLTypes['Company'] | undefined
    getConnectedAccount?: GraphQLTypes['ConnectedAccount'] | undefined
    getConversation?: GraphQLTypes['Conversation'] | undefined
    getConversationMessage?: GraphQLTypes['ConversationMessage'] | undefined
    getConversationsAccessToken: GraphQLTypes['GetConversationsAccessTokenResponse']
    getEtailerSkuMetrics?: GraphQLTypes['EtailerSkuMetrics'] | undefined
    getFeatureFlag: GraphQLTypes['GetFeatureFlagResponse']
    getFormDefinition?: GraphQLTypes['FormDefinition'] | undefined
    getFormSequenceConfiguration?: GraphQLTypes['FormSequenceConfiguration'] | undefined
    getInstagramStoryScreen?: GraphQLTypes['InstagramStoryScreen'] | undefined
    getMessage?: GraphQLTypes['Message'] | undefined
    getNote?: GraphQLTypes['Note'] | undefined
    getNotificationConfiguration?: GraphQLTypes['NotificationConfiguration'] | undefined
    getProduct?: GraphQLTypes['Product'] | undefined
    getProductBonusConfigSnapshot?: GraphQLTypes['ProductBonusConfigSnapshot'] | undefined
    getProductKeywords?: GraphQLTypes['ProductKeywords'] | undefined
    getProductRatingSnapshot?: GraphQLTypes['ProductRatingSnapshot'] | undefined
    getPurchase?: GraphQLTypes['Purchase'] | undefined
    getReferral?: GraphQLTypes['Referral'] | undefined
    getReview?: GraphQLTypes['Review'] | undefined
    getSku?: GraphQLTypes['Sku'] | undefined
    getSkuInformation?: GraphQLTypes['SkuInformation'] | undefined
    getSkuSnapshot?: GraphQLTypes['SkuSnapshot'] | undefined
    getSocialMediaPosts?: Array<GraphQLTypes['SocialMediaPost']> | undefined
    getUser?: GraphQLTypes['User'] | undefined
    getUserBonusPayout?: GraphQLTypes['UserBonusPayout'] | undefined
    getUserCampaign?: GraphQLTypes['UserCampaign'] | undefined
    getUserCampaignChangelog?: GraphQLTypes['UserCampaignChangelog'] | undefined
    getUserCampaignSnapshot?: GraphQLTypes['UserCampaignSnapshot'] | undefined
    getUserCampaignSocialContent?: GraphQLTypes['UserCampaignSocialContent'] | undefined
    getUserCampaignSocialContentDraftHistory?: GraphQLTypes['UserCampaignSocialContentDraftHistory'] | undefined
    getUserCampaignSocialContentReview?: GraphQLTypes['UserCampaignSocialContentReview'] | undefined
    getUserDeviceSnapshot?: GraphQLTypes['UserDeviceSnapshot'] | undefined
    getUserFollower?: GraphQLTypes['UserFollower'] | undefined
    getUserLink?: GraphQLTypes['UserLink'] | undefined
    getUserNotificationPreference?: GraphQLTypes['UserNotificationPreference'] | undefined
    getUserPayoutRequest?: GraphQLTypes['UserPayoutRequest'] | undefined
    getUserStagingApproval?: GraphQLTypes['UserStagingApproval'] | undefined
    getVideoContentScan?: GraphQLTypes['VideoContentScan'] | undefined
    instagramStoryByExternalId?: GraphQLTypes['ModelInstagramStoryScreenConnection'] | undefined
    instagramStoryByInstagramUserId?: GraphQLTypes['ModelInstagramStoryScreenConnection'] | undefined
    instagramStoryByMediaId?: GraphQLTypes['ModelInstagramStoryScreenConnection'] | undefined
    listAutoBuyDistributions?: GraphQLTypes['ModelAutoBuyDistributionConnection'] | undefined
    listAutoBuyFlowConfigurations?: GraphQLTypes['ModelAutoBuyFlowConfigurationConnection'] | undefined
    listAutoBuyFlowReleaseSchedules?: GraphQLTypes['ModelAutoBuyFlowReleaseScheduleConnection'] | undefined
    listAutoBuyReleases?: GraphQLTypes['ModelAutoBuyReleaseConnection'] | undefined
    listBrandApis?: GraphQLTypes['ModelBrandApiConnection'] | undefined
    listBrands?: GraphQLTypes['ModelBrandConnection'] | undefined
    listCampaignClickss?: GraphQLTypes['ModelCampaignClicksConnection'] | undefined
    listCampaignInvites?: GraphQLTypes['ModelCampaignInviteConnection'] | undefined
    listCampaignSourcingProspects?: GraphQLTypes['ModelCampaignSourcingProspectConnection'] | undefined
    listCampaigns?: GraphQLTypes['ModelCampaignConnection'] | undefined
    listCampaignsForUsersToApplyTo: GraphQLTypes['ListCampaignsForUserResponse']
    listChatConversationV2s?: GraphQLTypes['ModelChatConversationV2Connection'] | undefined
    listCommunicationNotifications?: GraphQLTypes['ModelCommunicationNotificationConnection'] | undefined
    listCompanys?: GraphQLTypes['ModelCompanyConnection'] | undefined
    listConnectedAccounts?: GraphQLTypes['ModelConnectedAccountConnection'] | undefined
    listConversationMessages?: GraphQLTypes['ModelConversationMessageConnection'] | undefined
    listConversations?: GraphQLTypes['ModelConversationConnection'] | undefined
    listEtailerSkuMetricss?: GraphQLTypes['ModelEtailerSkuMetricsConnection'] | undefined
    listFormSequenceConfigurations?: GraphQLTypes['ModelFormSequenceConfigurationConnection'] | undefined
    listInstagramStoryScreens?: GraphQLTypes['ModelInstagramStoryScreenConnection'] | undefined
    listMessages?: GraphQLTypes['ModelMessageConnection'] | undefined
    listNotes?: GraphQLTypes['ModelNoteConnection'] | undefined
    listNotificationConfigurations?: GraphQLTypes['ModelNotificationConfigurationConnection'] | undefined
    listProductBonusConfigSnapshots?: GraphQLTypes['ModelProductBonusConfigSnapshotConnection'] | undefined
    listProductKeywordss?: GraphQLTypes['ModelProductKeywordsConnection'] | undefined
    listProductRatingSnapshots?: GraphQLTypes['ModelProductRatingSnapshotConnection'] | undefined
    listProducts?: GraphQLTypes['ModelProductConnection'] | undefined
    listPurchases?: GraphQLTypes['ModelPurchaseConnection'] | undefined
    listReferrals?: GraphQLTypes['ModelReferralConnection'] | undefined
    listReviews?: GraphQLTypes['ModelReviewConnection'] | undefined
    listSkuSnapshots?: GraphQLTypes['ModelSkuSnapshotConnection'] | undefined
    listSkus?: GraphQLTypes['ModelSkuConnection'] | undefined
    listUserBonusPayouts?: GraphQLTypes['ModelUserBonusPayoutConnection'] | undefined
    listUserCampaignChangelogs?: GraphQLTypes['ModelUserCampaignChangelogConnection'] | undefined
    listUserCampaignSnapshots?: GraphQLTypes['ModelUserCampaignSnapshotConnection'] | undefined
    listUserCampaignSocialContentDraftHistorys?:
      | GraphQLTypes['ModelUserCampaignSocialContentDraftHistoryConnection']
      | undefined
    listUserCampaignSocialContentReviews?: GraphQLTypes['ModelUserCampaignSocialContentReviewConnection'] | undefined
    listUserCampaignSocialContents?: GraphQLTypes['ModelUserCampaignSocialContentConnection'] | undefined
    listUserCampaigns?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    listUserDeviceSnapshots?: GraphQLTypes['ModelUserDeviceSnapshotConnection'] | undefined
    listUserFollowers?: GraphQLTypes['ModelUserFollowerConnection'] | undefined
    listUserLinks?: GraphQLTypes['ModelUserLinkConnection'] | undefined
    listUserNotificationPreferences?: GraphQLTypes['ModelUserNotificationPreferenceConnection'] | undefined
    listUserPayoutRequests?: GraphQLTypes['ModelUserPayoutRequestConnection'] | undefined
    listUserStagingApprovals?: GraphQLTypes['ModelUserStagingApprovalConnection'] | undefined
    listUsers?: GraphQLTypes['ModelUserConnection'] | undefined
    listVideoContentScans?: GraphQLTypes['ModelVideoContentScanConnection'] | undefined
    messagesByUserCampaign?: GraphQLTypes['ModelMessageConnection'] | undefined
    notesByParentId?: GraphQLTypes['ModelNoteConnection'] | undefined
    osSearchUsers: GraphQLTypes['UserSearchOutput']
    productKeywordsByCampaignIdByKeyword?: GraphQLTypes['ModelProductKeywordsConnection'] | undefined
    productsByActive?: GraphQLTypes['ModelProductConnection'] | undefined
    productsByCampaignId?: GraphQLTypes['ModelProductConnection'] | undefined
    productsBySkuId?: GraphQLTypes['ModelProductConnection'] | undefined
    purchasesCardRetrieve: GraphQLTypes['CardDetails']
    referralsByReferrerUserIdReferredUserId?: GraphQLTypes['ModelReferralConnection'] | undefined
    requestSocialContentScript: GraphQLTypes['RequestSocialContentScriptResponse']
    reviewsByAttributedUserId?: GraphQLTypes['ModelReviewConnection'] | undefined
    reviewsByProductId?: GraphQLTypes['ModelReviewConnection'] | undefined
    reviewsByReviewerId?: GraphQLTypes['ModelReviewConnection'] | undefined
    reviewsBySkuId?: GraphQLTypes['ModelReviewConnection'] | undefined
    skusByActive?: GraphQLTypes['ModelSkuConnection'] | undefined
    skusByActiveByAvailabilityDate?: GraphQLTypes['ModelSkuConnection'] | undefined
    skusByActiveByReleaseDate?: GraphQLTypes['ModelSkuConnection'] | undefined
    skusByBrand?: GraphQLTypes['ModelSkuConnection'] | undefined
    skusByParentSkuId?: GraphQLTypes['ModelSkuConnection'] | undefined
    socialContentByApprovalStatusByApprovedAt?: GraphQLTypes['ModelUserCampaignSocialContentConnection'] | undefined
    socialContentByApprovalStatusByDraftSubmittedAt?:
      | GraphQLTypes['ModelUserCampaignSocialContentConnection']
      | undefined
    socialContentByDraftStatusByEnteredBrandApprovalAt?:
      | GraphQLTypes['ModelUserCampaignSocialContentConnection']
      | undefined
    socialContentByReviewStatusByPostedAt?: GraphQLTypes['ModelUserCampaignSocialContentConnection'] | undefined
    socialContentByReviewStatusByReviewedAt?: GraphQLTypes['ModelUserCampaignSocialContentConnection'] | undefined
    socialContentByUserCampaignId?: GraphQLTypes['ModelUserCampaignSocialContentConnection'] | undefined
    socialContentReviewBySocialContentIdByCreatedAt?:
      | GraphQLTypes['ModelUserCampaignSocialContentReviewConnection']
      | undefined
    socialContentReviewByTypeByStatus?: GraphQLTypes['ModelUserCampaignSocialContentReviewConnection'] | undefined
    userBonusesByPayoutBatchId?: GraphQLTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByPayoutState?: GraphQLTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByUserCampaignId?: GraphQLTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByUserId?: GraphQLTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByUserIdByCreatedAt?: GraphQLTypes['ModelUserBonusPayoutConnection'] | undefined
    userBonusesByUserIdByPayoutState?: GraphQLTypes['ModelUserBonusPayoutConnection'] | undefined
    userCampaignByCampaignByAcceptanceStatus?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCampaignByCard?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCampaignByStatus?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCampaignByUser?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCardId?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByCoarseCampaignStatus?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByKeywordHash?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByOrderId?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByProductByCampaignAcceptanceStatus?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByProductByCampaignStatus?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByStatusByCreatedAt?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByUser?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignByUserByCard?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userCampaignChangelogByUserCampaignIdByEntryType?: GraphQLTypes['ModelUserCampaignChangelogConnection'] | undefined
    userCampaignsByUserByCampaignAcceptanceStatus?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    userDeviceSnapshotByIdfvBySnapshotAt?: GraphQLTypes['ModelUserDeviceSnapshotConnection'] | undefined
    userFollowerByFollowerUserId?: GraphQLTypes['ModelUserFollowerConnection'] | undefined
    userFollowerByUserIdByIsFavorite?: GraphQLTypes['ModelUserFollowerConnection'] | undefined
    userLinksByCampaignIdUniqueClicks?: GraphQLTypes['ModelUserLinkConnection'] | undefined
    userLinksByUserEncodedLink?: GraphQLTypes['ModelUserLinkConnection'] | undefined
    userPayoutRequestsByPayoutBatchId?: GraphQLTypes['ModelUserPayoutRequestConnection'] | undefined
    userPayoutRequestsByPayoutState?: GraphQLTypes['ModelUserPayoutRequestConnection'] | undefined
    userPayoutRequestsByUserId?: GraphQLTypes['ModelUserPayoutRequestConnection'] | undefined
    userStagingApprovalsByOutcomeByType?: GraphQLTypes['ModelUserStagingApprovalConnection'] | undefined
    userStagingApprovalsByUserCampaignId?: GraphQLTypes['ModelUserStagingApprovalConnection'] | undefined
    usersByActiveByCreatedAt?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByActiveByLastActiveAt?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByAmznProfileId?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByAmznReviewerId?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByContentRatingSubmittedBy?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByContentReviewStatusByCompletedAt?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByContentReviewStatusByLastActiveAt?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByEmail?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByFacebookUserId?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByInstagram?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByInstagramTrailingPostEngagementAvg?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByInstagramUserId?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByReferralCode?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByReferrer?: GraphQLTypes['ModelUserConnection'] | undefined
    usersBySupportPriorityFlagByLastActiveAt?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByTiktokAccountId?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByTiktokHandle?: GraphQLTypes['ModelUserConnection'] | undefined
    usersByYoutubeAccountId?: GraphQLTypes['ModelUserConnection'] | undefined
    verifyOrderConfirmationScreenshot: GraphQLTypes['OrderConfirmationScreenshotVerificationOutput']
    verifyReviewScreenshot: GraphQLTypes['ReviewScreenshotVerificationResult']
    videoContentScanByProcessId?: GraphQLTypes['ModelVideoContentScanConnection'] | undefined
    videoContentScanByScanStatus?: GraphQLTypes['ModelVideoContentScanConnection'] | undefined
    videoContentScanByUrl?: GraphQLTypes['ModelVideoContentScanConnection'] | undefined
    videoContentScanByUserId?: GraphQLTypes['ModelVideoContentScanConnection'] | undefined
  }
  ['RankPoint']: {
    __typename: 'RankPoint'
    rank: number
    rankAt: string
  }
  ['Rate']: {
    __typename: 'Rate'
    count?: number | undefined
    isIntegrationFallbackRate?: boolean | undefined
    period?: GraphQLTypes['Period'] | undefined
  }
  ['Referral']: {
    __typename: 'Referral'
    bonusPayout?: GraphQLTypes['UserBonusPayout'] | undefined
    bonusPayoutId?: string | undefined
    campaign?: GraphQLTypes['Campaign'] | undefined
    campaignId?: string | undefined
    createdAt: string
    id: string
    referralType?: GraphQLTypes['ReferralType'] | undefined
    referredUserId: string
    referrerUserId: string
    updatedAt: string
  }
  ['ReferralBonuses']: {
    __typename: 'ReferralBonuses'
    campaignReferralBonuses: Array<GraphQLTypes['CampaignReferralBonus']>
    creatorReferralBonuses: Array<GraphQLTypes['CreatorReferralBonus']>
    creatorReferralSignups: Array<GraphQLTypes['CreatorReferralSignup']>
  }
  ['RequestSocialContentScriptResponse']: {
    __typename: 'RequestSocialContentScriptResponse'
    status: string
  }
  ['ResetUserAttributeResponse']: {
    __typename: 'ResetUserAttributeResponse'
    cognitoUserModel?: GraphQLTypes['CognitoUserModel'] | undefined
    statusCode: number
  }
  ['Review']: {
    __typename: 'Review'
    attributedUserId?: string | undefined
    author?: string | undefined
    authorBadge?: string | undefined
    badge?: string | undefined
    commentCount?: number | undefined
    createdAt: string
    helpfulReactions?: number | undefined
    id: string
    negativeReactions?: number | undefined
    photos?: Array<string> | undefined
    productId: string
    productVariant?: string | undefined
    rating?: number | undefined
    removed?: boolean | undefined
    reviewedAt: string
    reviewedSku?: string | undefined
    reviewerId?: string | undefined
    sku?: string | undefined
    skuId: string
    store?: string | undefined
    text?: string | undefined
    title?: string | undefined
    updatedAt: string
    userCampaignId?: string | undefined
    videos?: Array<string> | undefined
  }
  ['SequenceFormSubmissionValues']: {
    __typename: 'SequenceFormSubmissionValues'
    componentId: string
    componentName: string
    componentType?: GraphQLTypes['FormComponentType'] | undefined
    sequenceId: string
    type?: GraphQLTypes['FormFieldType'] | undefined
    value: string
  }
  ['Sku']: {
    __typename: 'Sku'
    active?: string | undefined
    approxRetailPrice?: string | undefined
    approxShippingCost?: number | undefined
    attributes?: Array<GraphQLTypes['SkuAttribute']> | undefined
    availabilityDate?: string | undefined
    availabilityNotified?: boolean | undefined
    availabilityNotifiedAt?: string | undefined
    brandId?: string | undefined
    brandListingKey?: string | undefined
    brandStoreId?: string | undefined
    bundle?: string | undefined
    childSkus?: GraphQLTypes['ModelSkuConnection'] | undefined
    createdAt: string
    createdByUserId?: string | undefined
    creationSource?: GraphQLTypes['SkuCreationSource'] | undefined
    creationSourceUrl?: string | undefined
    currency?: GraphQLTypes['Currency'] | undefined
    currentBuyBoxPrice?: number | undefined
    currentBuyBoxPriceCents?: number | undefined
    estimatedAvailabilityDate?: string | undefined
    estimatedDeliveryDays?: number | undefined
    estimatedMonthlyUnitsSold?: number | undefined
    estimatedWeeklyUnitsSold?: number | undefined
    exactListingTitle?: string | undefined
    exactRetailPrice?: string | undefined
    fullReviewScrapeComplete?: boolean | undefined
    id: string
    inventory?: number | undefined
    iosAppProviderId?: string | undefined
    isAplusPage?: string | undefined
    isPrime?: string | undefined
    isRecommendationsHidden?: boolean | undefined
    itemClassification?: GraphQLTypes['ItemClassification'] | undefined
    lastAutoFilledAt?: string | undefined
    lastKnownBuyBoxPrice?: number | undefined
    lastKnownBuyBoxPriceCents?: number | undefined
    listingBrand?: string | undefined
    listingCategory?: Array<string> | undefined
    listingDescription?: string | undefined
    listingFulfillmentType?: GraphQLTypes['ListingFulfillmentType'] | undefined
    listingGuidance?: string | undefined
    listingLink?: string | undefined
    mccValue?: GraphQLTypes['ValidMccCodes'] | undefined
    minimumOrderQuantity?: number | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    nonSponsoredListingKey?: string | undefined
    parentSku?: GraphQLTypes['Sku'] | undefined
    parentSkuId?: string | undefined
    priceCents?: number | undefined
    product?: string | undefined
    productImageKey?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    productSearchImageKey?: string | undefined
    releaseDate?: string | undefined
    skuId?: string | undefined
    sponsoredListingKey?: string | undefined
    storeName?: string | undefined
    targetReviewRate?: GraphQLTypes['Rate'] | undefined
    targetReviewRateInvalid?: string | undefined
    updatedAt: string
  }
  ['SkuAttribute']: {
    __typename: 'SkuAttribute'
    name: string
    value: string
  }
  ['SkuDimensions']: {
    __typename: 'SkuDimensions'
    name?: string | undefined
    value?: string | undefined
  }
  ['SkuInformation']: {
    __typename: 'SkuInformation'
    brand?: string | undefined
    childSkus?: Array<string | undefined> | undefined
    id: string
    image?: string | undefined
    link?: string | undefined
    name: string
    parentSku?: string | undefined
    price?: number | undefined
    regularPrice?: number | undefined
    shippingCost?: number | undefined
    store?: string | undefined
  }
  ['SkuSnapshot']: {
    __typename: 'SkuSnapshot'
    creationDateTime: string
    inventory?: number | undefined
    marketplaceSku: string
    price?: number | undefined
    skuId: string
    snapshotDate: string
    updatedDateTime: string
  }
  ['SocialMediaFeedback']: {
    __typename: 'SocialMediaFeedback'
    comments?: string | undefined
    rating?: number | undefined
  }
  ['SocialMediaPost']: {
    __typename: 'SocialMediaPost'
    caption?: string | undefined
    contentType?: GraphQLTypes['SocialMediaPostContentType'] | undefined
    groupTypes?: Array<GraphQLTypes['SocialMediaPostGroupType'] | undefined> | undefined
    id?: string | undefined
    isCopyrightedContent?: boolean | undefined
    link?: string | undefined
    mediaUrl?: string | undefined
    postedAt?: string | undefined
    views?: number | undefined
  }
  ['SocialMetric']: {
    __typename: 'SocialMetric'
    name: GraphQLTypes['SocialMetricName']
    value: number
  }
  ['SocialUnlinkResult']: {
    __typename: 'SocialUnlinkResult'
    userId: string
    verified: boolean
  }
  ['SocialVerificationCode']: {
    __typename: 'SocialVerificationCode'
    code: string
    id: string
    ttl: number
  }
  ['StagingApprovalConfiguration']: {
    __typename: 'StagingApprovalConfiguration'
    stagingApprovalAssignee?: string | undefined
    stagingApprovalType: GraphQLTypes['UserStagingApprovalType']
  }
  ['StatusHistory']: {
    __typename: 'StatusHistory'
    campaignStatus: GraphQLTypes['CampaignStatus']
    createdById?: string | undefined
    source?: GraphQLTypes['StateChangeSource'] | undefined
    time: number
  }
  ['StatusTierBonusConfig']: {
    __typename: 'StatusTierBonusConfig'
    baseBonus?: number | undefined
    diamondBonus?: number | undefined
    goldBonus?: number | undefined
    platinumBonus?: number | undefined
    silverBonus?: number | undefined
  }
  ['StatusTierEntry']: {
    __typename: 'StatusTierEntry'
    statusTier: GraphQLTypes['StatusTier']
    statusTierAt: string
  }
  ['Subscription']: {
    __typename: 'Subscription'
    onCreateAutoBuyDistribution?: GraphQLTypes['AutoBuyDistribution'] | undefined
    onCreateAutoBuyFlowConfiguration?: GraphQLTypes['AutoBuyFlowConfiguration'] | undefined
    onCreateAutoBuyFlowReleaseSchedule?: GraphQLTypes['AutoBuyFlowReleaseSchedule'] | undefined
    onCreateAutoBuyRelease?: GraphQLTypes['AutoBuyRelease'] | undefined
    onCreateBrand?: GraphQLTypes['Brand'] | undefined
    onCreateBrandApi?: GraphQLTypes['BrandApi'] | undefined
    onCreateCampaign?: GraphQLTypes['Campaign'] | undefined
    onCreateCampaignClicks?: GraphQLTypes['CampaignClicks'] | undefined
    onCreateCampaignInvite?: GraphQLTypes['CampaignInvite'] | undefined
    onCreateCampaignSourcingProspect?: GraphQLTypes['CampaignSourcingProspect'] | undefined
    onCreateChatConversationV2?: GraphQLTypes['ChatConversationV2'] | undefined
    onCreateCommunicationNotification?: GraphQLTypes['CommunicationNotification'] | undefined
    onCreateCompany?: GraphQLTypes['Company'] | undefined
    onCreateConnectedAccount?: GraphQLTypes['ConnectedAccount'] | undefined
    onCreateConversation?: GraphQLTypes['Conversation'] | undefined
    onCreateConversationMessage?: GraphQLTypes['ConversationMessage'] | undefined
    onCreateEtailerSkuMetrics?: GraphQLTypes['EtailerSkuMetrics'] | undefined
    onCreateFormSequenceConfiguration?: GraphQLTypes['FormSequenceConfiguration'] | undefined
    onCreateInstagramStoryScreen?: GraphQLTypes['InstagramStoryScreen'] | undefined
    onCreateMessage?: GraphQLTypes['Message'] | undefined
    onCreateNote?: GraphQLTypes['Note'] | undefined
    onCreateNotificationConfiguration?: GraphQLTypes['NotificationConfiguration'] | undefined
    onCreateProduct?: GraphQLTypes['Product'] | undefined
    onCreateProductBonusConfigSnapshot?: GraphQLTypes['ProductBonusConfigSnapshot'] | undefined
    onCreateProductKeywords?: GraphQLTypes['ProductKeywords'] | undefined
    onCreateProductRatingSnapshot?: GraphQLTypes['ProductRatingSnapshot'] | undefined
    onCreatePurchase?: GraphQLTypes['Purchase'] | undefined
    onCreateReferral?: GraphQLTypes['Referral'] | undefined
    onCreateReview?: GraphQLTypes['Review'] | undefined
    onCreateSku?: GraphQLTypes['Sku'] | undefined
    onCreateSkuSnapshot?: GraphQLTypes['SkuSnapshot'] | undefined
    onCreateUser?: GraphQLTypes['User'] | undefined
    onCreateUserBonusPayout?: GraphQLTypes['UserBonusPayout'] | undefined
    onCreateUserCampaign?: GraphQLTypes['UserCampaign'] | undefined
    onCreateUserCampaignChangelog?: GraphQLTypes['UserCampaignChangelog'] | undefined
    onCreateUserCampaignSnapshot?: GraphQLTypes['UserCampaignSnapshot'] | undefined
    onCreateUserCampaignSocialContent?: GraphQLTypes['UserCampaignSocialContent'] | undefined
    onCreateUserCampaignSocialContentDraftHistory?: GraphQLTypes['UserCampaignSocialContentDraftHistory'] | undefined
    onCreateUserCampaignSocialContentReview?: GraphQLTypes['UserCampaignSocialContentReview'] | undefined
    onCreateUserDeviceSnapshot?: GraphQLTypes['UserDeviceSnapshot'] | undefined
    onCreateUserFollower?: GraphQLTypes['UserFollower'] | undefined
    onCreateUserLink?: GraphQLTypes['UserLink'] | undefined
    onCreateUserNotificationPreference?: GraphQLTypes['UserNotificationPreference'] | undefined
    onCreateUserPayoutRequest?: GraphQLTypes['UserPayoutRequest'] | undefined
    onCreateUserStagingApproval?: GraphQLTypes['UserStagingApproval'] | undefined
    onCreateVideoContentScan?: GraphQLTypes['VideoContentScan'] | undefined
    onDeleteAutoBuyDistribution?: GraphQLTypes['AutoBuyDistribution'] | undefined
    onDeleteAutoBuyFlowConfiguration?: GraphQLTypes['AutoBuyFlowConfiguration'] | undefined
    onDeleteAutoBuyFlowReleaseSchedule?: GraphQLTypes['AutoBuyFlowReleaseSchedule'] | undefined
    onDeleteAutoBuyRelease?: GraphQLTypes['AutoBuyRelease'] | undefined
    onDeleteBrand?: GraphQLTypes['Brand'] | undefined
    onDeleteBrandApi?: GraphQLTypes['BrandApi'] | undefined
    onDeleteCampaign?: GraphQLTypes['Campaign'] | undefined
    onDeleteCampaignClicks?: GraphQLTypes['CampaignClicks'] | undefined
    onDeleteCampaignInvite?: GraphQLTypes['CampaignInvite'] | undefined
    onDeleteCampaignSourcingProspect?: GraphQLTypes['CampaignSourcingProspect'] | undefined
    onDeleteChatConversationV2?: GraphQLTypes['ChatConversationV2'] | undefined
    onDeleteCommunicationNotification?: GraphQLTypes['CommunicationNotification'] | undefined
    onDeleteCompany?: GraphQLTypes['Company'] | undefined
    onDeleteConnectedAccount?: GraphQLTypes['ConnectedAccount'] | undefined
    onDeleteConversation?: GraphQLTypes['Conversation'] | undefined
    onDeleteConversationMessage?: GraphQLTypes['ConversationMessage'] | undefined
    onDeleteEtailerSkuMetrics?: GraphQLTypes['EtailerSkuMetrics'] | undefined
    onDeleteFormSequenceConfiguration?: GraphQLTypes['FormSequenceConfiguration'] | undefined
    onDeleteInstagramStoryScreen?: GraphQLTypes['InstagramStoryScreen'] | undefined
    onDeleteMessage?: GraphQLTypes['Message'] | undefined
    onDeleteNote?: GraphQLTypes['Note'] | undefined
    onDeleteNotificationConfiguration?: GraphQLTypes['NotificationConfiguration'] | undefined
    onDeleteProduct?: GraphQLTypes['Product'] | undefined
    onDeleteProductBonusConfigSnapshot?: GraphQLTypes['ProductBonusConfigSnapshot'] | undefined
    onDeleteProductKeywords?: GraphQLTypes['ProductKeywords'] | undefined
    onDeleteProductRatingSnapshot?: GraphQLTypes['ProductRatingSnapshot'] | undefined
    onDeletePurchase?: GraphQLTypes['Purchase'] | undefined
    onDeleteReferral?: GraphQLTypes['Referral'] | undefined
    onDeleteReview?: GraphQLTypes['Review'] | undefined
    onDeleteSku?: GraphQLTypes['Sku'] | undefined
    onDeleteSkuSnapshot?: GraphQLTypes['SkuSnapshot'] | undefined
    onDeleteUser?: GraphQLTypes['User'] | undefined
    onDeleteUserBonusPayout?: GraphQLTypes['UserBonusPayout'] | undefined
    onDeleteUserCampaign?: GraphQLTypes['UserCampaign'] | undefined
    onDeleteUserCampaignChangelog?: GraphQLTypes['UserCampaignChangelog'] | undefined
    onDeleteUserCampaignSnapshot?: GraphQLTypes['UserCampaignSnapshot'] | undefined
    onDeleteUserCampaignSocialContent?: GraphQLTypes['UserCampaignSocialContent'] | undefined
    onDeleteUserCampaignSocialContentDraftHistory?: GraphQLTypes['UserCampaignSocialContentDraftHistory'] | undefined
    onDeleteUserCampaignSocialContentReview?: GraphQLTypes['UserCampaignSocialContentReview'] | undefined
    onDeleteUserDeviceSnapshot?: GraphQLTypes['UserDeviceSnapshot'] | undefined
    onDeleteUserFollower?: GraphQLTypes['UserFollower'] | undefined
    onDeleteUserLink?: GraphQLTypes['UserLink'] | undefined
    onDeleteUserNotificationPreference?: GraphQLTypes['UserNotificationPreference'] | undefined
    onDeleteUserPayoutRequest?: GraphQLTypes['UserPayoutRequest'] | undefined
    onDeleteUserStagingApproval?: GraphQLTypes['UserStagingApproval'] | undefined
    onDeleteVideoContentScan?: GraphQLTypes['VideoContentScan'] | undefined
    onUpdateAutoBuyDistribution?: GraphQLTypes['AutoBuyDistribution'] | undefined
    onUpdateAutoBuyFlowConfiguration?: GraphQLTypes['AutoBuyFlowConfiguration'] | undefined
    onUpdateAutoBuyFlowReleaseSchedule?: GraphQLTypes['AutoBuyFlowReleaseSchedule'] | undefined
    onUpdateAutoBuyRelease?: GraphQLTypes['AutoBuyRelease'] | undefined
    onUpdateBrand?: GraphQLTypes['Brand'] | undefined
    onUpdateBrandApi?: GraphQLTypes['BrandApi'] | undefined
    onUpdateCampaign?: GraphQLTypes['Campaign'] | undefined
    onUpdateCampaignClicks?: GraphQLTypes['CampaignClicks'] | undefined
    onUpdateCampaignInvite?: GraphQLTypes['CampaignInvite'] | undefined
    onUpdateCampaignSourcingProspect?: GraphQLTypes['CampaignSourcingProspect'] | undefined
    onUpdateChatConversationV2?: GraphQLTypes['ChatConversationV2'] | undefined
    onUpdateCommunicationNotification?: GraphQLTypes['CommunicationNotification'] | undefined
    onUpdateCompany?: GraphQLTypes['Company'] | undefined
    onUpdateConnectedAccount?: GraphQLTypes['ConnectedAccount'] | undefined
    onUpdateConversation?: GraphQLTypes['Conversation'] | undefined
    onUpdateConversationMessage?: GraphQLTypes['ConversationMessage'] | undefined
    onUpdateEtailerSkuMetrics?: GraphQLTypes['EtailerSkuMetrics'] | undefined
    onUpdateFormSequenceConfiguration?: GraphQLTypes['FormSequenceConfiguration'] | undefined
    onUpdateInstagramStoryScreen?: GraphQLTypes['InstagramStoryScreen'] | undefined
    onUpdateMessage?: GraphQLTypes['Message'] | undefined
    onUpdateNote?: GraphQLTypes['Note'] | undefined
    onUpdateNotificationConfiguration?: GraphQLTypes['NotificationConfiguration'] | undefined
    onUpdateProduct?: GraphQLTypes['Product'] | undefined
    onUpdateProductBonusConfigSnapshot?: GraphQLTypes['ProductBonusConfigSnapshot'] | undefined
    onUpdateProductKeywords?: GraphQLTypes['ProductKeywords'] | undefined
    onUpdateProductRatingSnapshot?: GraphQLTypes['ProductRatingSnapshot'] | undefined
    onUpdatePurchase?: GraphQLTypes['Purchase'] | undefined
    onUpdateReferral?: GraphQLTypes['Referral'] | undefined
    onUpdateReview?: GraphQLTypes['Review'] | undefined
    onUpdateSku?: GraphQLTypes['Sku'] | undefined
    onUpdateSkuSnapshot?: GraphQLTypes['SkuSnapshot'] | undefined
    onUpdateUser?: GraphQLTypes['User'] | undefined
    onUpdateUserBonusPayout?: GraphQLTypes['UserBonusPayout'] | undefined
    onUpdateUserCampaign?: GraphQLTypes['UserCampaign'] | undefined
    onUpdateUserCampaignChangelog?: GraphQLTypes['UserCampaignChangelog'] | undefined
    onUpdateUserCampaignSnapshot?: GraphQLTypes['UserCampaignSnapshot'] | undefined
    onUpdateUserCampaignSocialContent?: GraphQLTypes['UserCampaignSocialContent'] | undefined
    onUpdateUserCampaignSocialContentDraftHistory?: GraphQLTypes['UserCampaignSocialContentDraftHistory'] | undefined
    onUpdateUserCampaignSocialContentReview?: GraphQLTypes['UserCampaignSocialContentReview'] | undefined
    onUpdateUserDeviceSnapshot?: GraphQLTypes['UserDeviceSnapshot'] | undefined
    onUpdateUserFollower?: GraphQLTypes['UserFollower'] | undefined
    onUpdateUserLink?: GraphQLTypes['UserLink'] | undefined
    onUpdateUserNotificationPreference?: GraphQLTypes['UserNotificationPreference'] | undefined
    onUpdateUserPayoutRequest?: GraphQLTypes['UserPayoutRequest'] | undefined
    onUpdateUserStagingApproval?: GraphQLTypes['UserStagingApproval'] | undefined
    onUpdateVideoContentScan?: GraphQLTypes['VideoContentScan'] | undefined
  }
  ['UpdateUserPhoneResult']: {
    __typename: 'UpdateUserPhoneResult'
    phoneNumber: string
    userId: string
  }
  ['User']: {
    __typename: 'User'
    active?: GraphQLTypes['Active'] | undefined
    addressComponents?: string | undefined
    amznLastChecked?: string | undefined
    amznLink?: string | undefined
    amznPrime?: string | undefined
    amznProfileId?: string | undefined
    amznReviewerId?: string | undefined
    amznVerified?: string | undefined
    attributedReviewCount?: number | undefined
    blogWebsite?: string | undefined
    brandContentRatingAvg?: number | undefined
    campaignAcceptanceRate?: number | undefined
    campaignApprovalRate?: number | undefined
    campaignCompletionRate?: number | undefined
    campaignReviewRate?: number | undefined
    cardProfileUpdates?: string | undefined
    cardholderId?: string | undefined
    cardholderRegion?: GraphQLTypes['StripeRegion'] | undefined
    characteristics?: Array<GraphQLTypes['UserCharacteristic']> | undefined
    characteristicsUpdatedAt?: string | undefined
    claimedReviewCount?: number | undefined
    collisionSkuSet?: Array<string> | undefined
    collisionSkuSetCalculatedAt?: string | undefined
    completedLifestyleCampaignCount?: number | undefined
    completedLiftCampaignCount?: number | undefined
    completedSocialCampaignCount?: number | undefined
    connectedAccounts?: GraphQLTypes['ModelConnectedAccountConnection'] | undefined
    contentRating?: GraphQLTypes['UserContentRating'] | undefined
    contentRatingCompletedAt?: string | undefined
    contentRatingCompletionTimeMillis?: number | undefined
    contentRatingInstagramCompletionTimeMillis?: number | undefined
    contentRatingInstagramSponsorshipContent?: Array<GraphQLTypes['ContentRatingSponsorshipContent']> | undefined
    contentRatingQualityScore?: number | undefined
    contentRatingReviewStatus?: GraphQLTypes['ContentRatingReviewStatus'] | undefined
    contentRatingSponsorshipContent?: Array<GraphQLTypes['ContentRatingSponsorshipContent']> | undefined
    contentRatingSubmittedBy?: string | undefined
    contentRatingTiktokCompletionTimeMillis?: number | undefined
    contentRatingTiktokSponsorshipContent?: Array<GraphQLTypes['ContentRatingSponsorshipContent']> | undefined
    contentTypes?: Array<string> | undefined
    createdAt?: string | undefined
    date_of_birth?: string | undefined
    deactivationDate?: string | undefined
    deactivationReason?: string | undefined
    declineToReviewCount?: number | undefined
    deranked?: GraphQLTypes['Deranked_Reason'] | undefined
    email?: string | undefined
    emailOptOut?: boolean | undefined
    facebookAccessToken?: string | undefined
    facebookAccessTokenExpiry?: string | undefined
    facebookPageId?: string | undefined
    facebookPageIsSubscribed?: boolean | undefined
    facebookPermissionScopes?: Array<string | undefined> | undefined
    facebookUserId?: string | undefined
    firstName?: string | undefined
    followedUsers?: GraphQLTypes['ModelUserFollowerConnection'] | undefined
    followers?: GraphQLTypes['ModelUserFollowerConnection'] | undefined
    followsWesponsored?: string | undefined
    fraudReason?: GraphQLTypes['FraudReason'] | undefined
    fraudReviewAt?: string | undefined
    fraudReviewStatus?: GraphQLTypes['FraudReviewStatus'] | undefined
    gender?: string | undefined
    hasTrueEngagement?: boolean | undefined
    id: string
    idfaId?: string | undefined
    incompleteCampaignCount?: number | undefined
    instagramBio?: string | undefined
    instagramBusinessCategoryName?: string | undefined
    instagramCategoryName?: string | undefined
    instagramChecked?: string | undefined
    instagramContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    instagramExternalLink?: string | undefined
    instagramFollowerCountryDistribution?: Array<GraphQLTypes['InstagramCountryDistributionItem']> | undefined
    instagramFollowerGenderAgeDistribution?: GraphQLTypes['InstagramGenderAgeDistribution'] | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    instagramIsBusiness?: string | undefined
    instagramIsPrivate?: string | undefined
    instagramIsRecentlyJoined?: string | undefined
    instagramIsVerified?: string | undefined
    instagramLastChecked?: string | undefined
    instagramMedianShares?: number | undefined
    instagramMetricsRetrievedAt?: string | undefined
    instagramName?: string | undefined
    instagramPostsInt?: number | undefined
    instagramPriority?: GraphQLTypes['PlatformPriority'] | undefined
    instagramProfilePic?: string | undefined
    instagramTrailingComments?: number | undefined
    instagramTrailingDateOfOldestPost?: string | undefined
    instagramTrailingLikes?: number | undefined
    instagramTrailingNumberOfPosts?: number | undefined
    instagramTrailingPostEngagementAvg?: number | undefined
    instagramTrailingPostEngagementRateAvg?: number | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined
    instagramTrailingPostEngagementRateMedian?: number | undefined
    instagramTrailingPostImpressionsAvg?: number | undefined
    instagramTrailingPostImpressionsMedian?: number | undefined
    instagramTrailingPostSponsoredPercentage?: number | undefined
    instagramTrailingPostViewsMedian?: number | undefined
    instagramUserId?: string | undefined
    instagramVerificationMethod?: GraphQLTypes['SocialVerificationMethod'] | undefined
    internalGender?: GraphQLTypes['UserGender'] | undefined
    internalNotes?: GraphQLTypes['ModelNoteConnection'] | undefined
    isBrandSafe?: GraphQLTypes['BrandSafe'] | undefined
    isBrandSafeReason?: string | undefined
    isBrandSafeUpdatedAt?: string | undefined
    lastActiveAt?: string | undefined
    lastName?: string | undefined
    manualScoringLockedUntil?: string | undefined
    moreinfo?: string | undefined
    notificationPreferences?: GraphQLTypes['ModelUserNotificationPreferenceConnection'] | undefined
    numEvenDollarPurchases?: number | undefined
    numPurchases?: number | undefined
    paypalEmail?: string | undefined
    phoneNumber?: string | undefined
    profilePicture?: string | undefined
    pushAddresses?: Array<GraphQLTypes['PushAddress']> | undefined
    pushOptOut?: boolean | undefined
    referralCode?: string | undefined
    referralCount?: number | undefined
    referrer?: string | undefined
    signupCountry?: string | undefined
    smsOptOut?: boolean | undefined
    socialQuality?: GraphQLTypes['SocialQuality'] | undefined
    specialAttributes?: GraphQLTypes['UserAttributes'] | undefined
    statusTier?: GraphQLTypes['StatusTier'] | undefined
    statusTierHistory?: Array<GraphQLTypes['StatusTierEntry']> | undefined
    statusTierLastUpdatedAt?: string | undefined
    supportPriorityAcknowledgedAt?: string | undefined
    supportPriorityFlag?: GraphQLTypes['SupportPriorityFlag'] | undefined
    supportPriorityFlagSetAt?: string | undefined
    targetAccount?: string | undefined
    tiktokAccessToken?: string | undefined
    tiktokAccessTokenExpiry?: string | undefined
    tiktokAccountId?: string | undefined
    tiktokAveragePostEngagementRate?: number | undefined
    tiktokAveragePostEngagementRateViews?: number | undefined
    tiktokBio?: string | undefined
    tiktokContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    tiktokMedianComments?: number | undefined
    tiktokMedianLikes?: number | undefined
    tiktokMedianPostEngagement?: number | undefined
    tiktokMedianPostEngagementRate?: number | undefined
    tiktokMedianPostEngagementRateViews?: number | undefined
    tiktokMedianShares?: number | undefined
    tiktokMedianViews?: number | undefined
    tiktokMetricsRetrievedAt?: string | undefined
    tiktokName?: string | undefined
    tiktokPermissionScopes?: Array<string> | undefined
    tiktokPriority?: GraphQLTypes['PlatformPriority'] | undefined
    tiktokProfilePic?: string | undefined
    tiktokRefreshToken?: string | undefined
    tiktokRefreshTokenExpiry?: string | undefined
    tiktokSponsoredPostPercentage?: number | undefined
    tiktokVerificationMethod?: GraphQLTypes['SocialVerificationMethod'] | undefined
    tiktokVerified?: string | undefined
    timeZone?: string | undefined
    trackingStatus?: GraphQLTypes['TrackingStatus'] | undefined
    twitchAccount?: string | undefined
    type: string
    updatedAt: string
    userCampaigns?: GraphQLTypes['ModelUserCampaignConnection'] | undefined
    utmReferral?: GraphQLTypes['UtmReferral'] | undefined
    videoContentScans?: GraphQLTypes['ModelVideoContentScanConnection'] | undefined
    wmartAccount?: string | undefined
    youtubeAccessToken?: string | undefined
    youtubeAccessTokenExpiry?: string | undefined
    youtubeAccount?: string | undefined
    youtubeAveragePostEngagementRate?: number | undefined
    youtubeChannelDescription?: string | undefined
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
    youtubeChannelId?: string | undefined
    youtubeChannelPicture?: string | undefined
    youtubeChannelTitle?: string | undefined
    youtubeIdToken?: string | undefined
    youtubeMedianViews?: number | undefined
    youtubeMetricsRetrievedAt?: string | undefined
    youtubePermissionScopes?: Array<string> | undefined
    youtubeRefreshToken?: string | undefined
    youtubeVerificationMethod?: GraphQLTypes['SocialVerificationMethod'] | undefined
  }
  ['UserAttributes']: {
    __typename: 'UserAttributes'
    isMother?: boolean | undefined
  }
  ['UserBonusPayout']: {
    __typename: 'UserBonusPayout'
    adminPaymentApproved?: boolean | undefined
    bonusType: GraphQLTypes['BonusPayoutTypes']
    createdAt?: string | undefined
    failureReason?: string | undefined
    id: string
    manuallyCreatedBy?: string | undefined
    payoutAmount?: number | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: GraphQLTypes['WSLocale'] | undefined
    payoutState?: GraphQLTypes['BonusPayoutState'] | undefined
    quantity?: number | undefined
    referenceId?: string | undefined
    releaseAt?: string | undefined
    revocationType?: GraphQLTypes['RevocationType'] | undefined
    revokedAt?: string | undefined
    updatedAt: string
    userCampaignId?: string | undefined
    userId: string
  }
  ['UserCampaign']: {
    __typename: 'UserCampaign'
    accountScreenShot?: string | undefined
    amznReviewLink?: string | undefined
    amznReviewSubmissionStatus?: GraphQLTypes['AmazonReviewSubmissionStatus'] | undefined
    applicationRejectedByUser?: GraphQLTypes['User'] | undefined
    applicationRejectedByUserId?: string | undefined
    approvalOutcome?: GraphQLTypes['ApprovalOutcome'] | undefined
    approvalStatus?: GraphQLTypes['UserCampaignApprovalStatus'] | undefined
    archivedFeedback?: string | undefined
    archivedReason?: GraphQLTypes['ArchivedReason'] | undefined
    autoBuyApprovalDate?: string | undefined
    autoBuyRevokeDate?: string | undefined
    bestBuyReviewScreenShot?: string | undefined
    bonusConfig?: GraphQLTypes['UserCampaignBonusConfig'] | undefined
    campaign?: GraphQLTypes['Campaign'] | undefined
    campaignAcceptanceStatus?: GraphQLTypes['UserCampaignAcceptanceStatus'] | undefined
    campaignApplication?: string | undefined
    campaignId: string
    campaignStatus?: GraphQLTypes['CampaignStatus'] | undefined
    campaignStatusOnArchive?: string | undefined
    cardId?: string | undefined
    cardRegion?: GraphQLTypes['StripeRegion'] | undefined
    changelog?: GraphQLTypes['ModelUserCampaignChangelogConnection'] | undefined
    closeoutSurvey?: string | undefined
    coarseCampaignStatus?: GraphQLTypes['CoarseCampaignStatus'] | undefined
    communications?: GraphQLTypes['ModelCommunicationNotificationConnection'] | undefined
    contentApprovalLeadDays?: number | undefined
    createdAt?: string | undefined
    discoverySurvey?: string | undefined
    id: string
    influencerApprovalInviteFeedback?: string | undefined
    influencerApprovalRejectFeedback?: string | undefined
    internalNotes?: GraphQLTypes['ModelNoteConnection'] | undefined
    invoiceKey?: string | undefined
    isAutoApproved?: boolean | undefined
    keyword?: GraphQLTypes['ProductKeywords'] | undefined
    keywordAtPurchase?: string | undefined
    keywordId?: string | undefined
    keywordsV2Hash?: string | undefined
    listingKey?: string | undefined
    localeV1?: GraphQLTypes['WSLocale'] | undefined
    manualPurchase?: boolean | undefined
    messages?: GraphQLTypes['ModelMessageConnection'] | undefined
    moderatorStagingApprovalAt?: string | undefined
    moderatorStagingApprovalBy?: string | undefined
    moderatorStagingApprovalOutcome?: GraphQLTypes['StagingApprovalOutcome'] | undefined
    orderId?: string | undefined
    orderIdInvalidReason?: string | undefined
    orderIdSubmissionDate?: string | undefined
    orderIdValidationDate?: string | undefined
    orderIdValidationStatus?: GraphQLTypes['OrderIdValidationStatus'] | undefined
    orderStatus?: string | undefined
    payForClickLink?: GraphQLTypes['UserLink'] | undefined
    payForClickLinkId?: string | undefined
    paypalEmailAtSubmission?: string | undefined
    postingDeadline?: string | undefined
    postingDeadlineExtended?: boolean | undefined
    previousCardIds?: Array<string> | undefined
    priority?: GraphQLTypes['SourcePriority'] | undefined
    product?: GraphQLTypes['Product'] | undefined
    productCriticalFeedback?: string | undefined
    productId?: string | undefined
    productPositiveFeedback?: string | undefined
    prospect?: GraphQLTypes['CampaignSourcingProspect'] | undefined
    purchases?: GraphQLTypes['ModelPurchaseConnection'] | undefined
    rankPriority?: number | undefined
    reviewScreenshot?: string | undefined
    reviewScreenshotVerificationResult?: GraphQLTypes['ReviewScreenshotVerificationResult'] | undefined
    schedulingReleaseAt?: string | undefined
    searchPhraseKey?: string | undefined
    selectedFlow?: string | undefined
    selfieVideoApprovalDate?: string | undefined
    selfieVideoApprovalNotes?: string | undefined
    selfieVideoApprovalStatus?: GraphQLTypes['SelfieVideoApprovalStatus'] | undefined
    selfieVideoKey?: string | undefined
    selfieVideoRejectionReason?: string | undefined
    selfieVideoSubmissionRequired?: boolean | undefined
    selfieVideoSubmittedAt?: string | undefined
    sequenceFormSubmissionValues?: Array<GraphQLTypes['SequenceFormSubmissionValues']> | undefined
    shippingAddress?: string | undefined
    showEarlyReviewSurvey?: boolean | undefined
    socialContent?: GraphQLTypes['ModelUserCampaignSocialContentConnection'] | undefined
    statusHistory?: string | undefined
    totalBonuses?: GraphQLTypes['ModelUserBonusPayoutConnection'] | undefined
    updatedAt?: string | undefined
    user?: GraphQLTypes['User'] | undefined
    userId: string
    userStagingApprovals?: GraphQLTypes['ModelUserStagingApprovalConnection'] | undefined
    walmartReviewScreenShot?: string | undefined
  }
  ['UserCampaignBonusConfig']: {
    __typename: 'UserCampaignBonusConfig'
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<GraphQLTypes['ProductSocialContent']> | undefined
    discoveryBonus?: number | undefined
    expectedBonus?: string | undefined
    hasCustomBonusConfig?: boolean | undefined
    payForImpressionsBonus?: GraphQLTypes['PayForImpressionsBonusConfig'] | undefined
  }
  ['UserCampaignChangelog']: {
    __typename: 'UserCampaignChangelog'
    createdAt: string
    createdByUser?: GraphQLTypes['User'] | undefined
    createdByUserId?: string | undefined
    entryType: GraphQLTypes['UserCampaignChangelogEntryType']
    extraData?: string | undefined
    updatedAt: string
    userCampaign?: GraphQLTypes['UserCampaign'] | undefined
    userCampaignId: string
  }
  ['UserCampaignSnapshot']: {
    __typename: 'UserCampaignSnapshot'
    createdAt: string
    snapshot: string
    updatedAt: string
    userCampaignId: string
    userCampaignVersion: number
  }
  ['UserCampaignSocialContent']: {
    __typename: 'UserCampaignSocialContent'
    approvalFeedback?: string | undefined
    approvalStatus?: GraphQLTypes['ContentApprovalStatus'] | undefined
    approvedAt?: string | undefined
    approverPWUserId?: string | undefined
    approverUserId?: string | undefined
    bonusEarned?: number | undefined
    bonusOverride?: number | undefined
    caption?: string | undefined
    channel: GraphQLTypes['SocialChannel']
    clientFeedback?: Array<GraphQLTypes['SocialMediaFeedback']> | undefined
    complianceReview?: Array<GraphQLTypes['ContentComplianceReviewCriteria']> | undefined
    complianceReviewStatus?: GraphQLTypes['ContentComplianceReviewStatus'] | undefined
    complianceReviewUserId?: string | undefined
    complianceReviewedAt?: string | undefined
    contentType?: GraphQLTypes['SocialContentType'] | undefined
    createdAt: string
    draftEnteredAdminApprovalAt?: string | undefined
    draftEnteredBrandApprovalAt?: string | undefined
    draftFeedback?: Array<string> | undefined
    draftHistory?: Array<string> | undefined
    draftHistoryV2?: GraphQLTypes['ModelUserCampaignSocialContentDraftHistoryConnection'] | undefined
    draftMedia?: Array<string> | undefined
    draftPreApprovedText?: string | undefined
    draftQualityScore?: number | undefined
    draftReview?: Array<GraphQLTypes['ContentComplianceReviewCriteria']> | undefined
    draftStatus?: GraphQLTypes['DraftStatus'] | undefined
    draftSubmittedAt?: string | undefined
    draftText?: string | undefined
    earlySubmissionBonusEarnedCents?: number | undefined
    experimentId?: string | undefined
    externalUrl?: string | undefined
    groupId?: string | undefined
    hashtags?: Array<string> | undefined
    id: string
    instance?: number | undefined
    internalFeedback?: Array<GraphQLTypes['SocialMediaFeedback']> | undefined
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    isPurchased?: boolean | undefined
    isUnavailable?: boolean | undefined
    manualUploadType?: GraphQLTypes['ManualUploadType'] | undefined
    mediaId?: string | undefined
    mediaUrls?: Array<string> | undefined
    metrics?: Array<GraphQLTypes['SocialMetric']> | undefined
    paymentId?: string | undefined
    postedAt?: string | undefined
    qualityScore?: number | undefined
    rejectionReason?: string | undefined
    requestedSocialScript?: boolean | undefined
    requirementLevel?: GraphQLTypes['ContentRequirementLevel'] | undefined
    reviews?: GraphQLTypes['ModelUserCampaignSocialContentReviewConnection'] | undefined
    scrapeStatus?: GraphQLTypes['ScrapeStatus'] | undefined
    unavailableAt?: string | undefined
    updatedAt: string
    usedRequestedSocialScript?: boolean | undefined
    userCampaign?: GraphQLTypes['UserCampaign'] | undefined
    userCampaignId: string
    videoEndTime?: string | undefined
    videoStartTime?: string | undefined
  }
  ['UserCampaignSocialContentDraftHistory']: {
    __typename: 'UserCampaignSocialContentDraftHistory'
    createdAt: string
    id: string
    snapshot: string
    snapshotDate: string
    updatedAt: string
    userCampaignSocialContentId: string
  }
  ['UserCampaignSocialContentReview']: {
    __typename: 'UserCampaignSocialContentReview'
    createdAt: string
    reviewFeedback?: Array<GraphQLTypes['UserCampaignSocialContentReviewFeedback']> | undefined
    reviewOutcome?: GraphQLTypes['UserCampaignSocialContentReviewOutcome'] | undefined
    reviewStatus?: GraphQLTypes['UserCampaignSocialContentReviewStatus'] | undefined
    reviewType: GraphQLTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined
    reviewer?: GraphQLTypes['User'] | undefined
    reviewerAssigneeId?: string | undefined
    reviewerFirstName?: string | undefined
    reviewerId?: string | undefined
    reviewerLastName?: string | undefined
    updatedAt: string
    userCampaignSocialContent?: GraphQLTypes['UserCampaignSocialContent'] | undefined
    userCampaignSocialContentId: string
    userId: string
  }
  ['UserCampaignSocialContentReviewFeedback']: {
    __typename: 'UserCampaignSocialContentReviewFeedback'
    atTime?: number | undefined
    createdAt?: string | undefined
    createdByFirstName?: string | undefined
    createdByLastName?: string | undefined
    createdByUserId?: string | undefined
    forAssetAtIndex?: number | undefined
    id?: string | undefined
    message?: string | undefined
    rating?: number | undefined
    updatedAt?: string | undefined
    visibility?: GraphQLTypes['ReviewFeedbackVisibility'] | undefined
  }
  ['UserCharacteristic']: {
    __typename: 'UserCharacteristic'
    characteristic: string
    reason?: string | undefined
  }
  ['UserContentRating']: {
    __typename: 'UserContentRating'
    instagramContentQualityRating?: GraphQLTypes['ContentQualityRating'] | undefined
    instagramContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    instagramCreatorContentScore?: number | undefined
    instagramCreatorContentScoreRaw?: Array<GraphQLTypes['CreatorContentRatingRawValue']> | undefined
    instagramCreatorSponsorshipContentScore?: number | undefined
    instagramSubmittedBy?: string | undefined
    submittedBy?: string | undefined
    tiktokContentQualityRating?: GraphQLTypes['ContentQualityRating'] | undefined
    tiktokContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    tiktokCreatorContentScore?: number | undefined
    tiktokCreatorContentScoreRaw?: Array<GraphQLTypes['CreatorContentRatingRawValue']> | undefined
    tiktokCreatorSponsorshipContentScore?: number | undefined
    tiktokSubmittedBy?: string | undefined
  }
  ['UserDeviceSnapshot']: {
    __typename: 'UserDeviceSnapshot'
    createdAt: string
    id: string
    idfv?: string | undefined
    ipAddress?: string | undefined
    isEmulator?: boolean | undefined
    snapshotAt: string
    timezone?: string | undefined
    updatedAt: string
    userId: string
  }
  ['UserFollower']: {
    __typename: 'UserFollower'
    createdAt: string
    follower?: GraphQLTypes['User'] | undefined
    followerUserId: string
    isFavorite?: GraphQLTypes['FavoriteFlag'] | undefined
    updatedAt: string
    user?: GraphQLTypes['User'] | undefined
    userId: string
  }
  ['UserLink']: {
    __typename: 'UserLink'
    campaignId?: string | undefined
    clicksPerPeriod?: Array<GraphQLTypes['ClicksRecords']> | undefined
    createdAt?: string | undefined
    id: string
    linkId?: string | undefined
    originalLink: string
    rank?: number | undefined
    storageType?: GraphQLTypes['LinkStorageType'] | undefined
    uniqueClicks: number
    updatedAt?: string | undefined
    userEncodedLink: string
    userId?: string | undefined
  }
  ['UserNotificationPreference']: {
    __typename: 'UserNotificationPreference'
    category: string
    createdAt: string
    emailOptOut?: boolean | undefined
    pushOptOut?: boolean | undefined
    smsOptOut?: boolean | undefined
    updatedAt: string
    userId: string
  }
  ['UserPayoutRequest']: {
    __typename: 'UserPayoutRequest'
    createdAt: string
    failureReason?: string | undefined
    id: string
    manuallyApprovedBy?: string | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: GraphQLTypes['WSLocale'] | undefined
    payoutState: GraphQLTypes['BonusPayoutState']
    updatedAt: string
    userId: string
  }
  ['UserSearchOutput']: {
    __typename: 'UserSearchOutput'
    items: Array<GraphQLTypes['User']>
    total?: number | undefined
  }
  ['UserStagingApproval']: {
    __typename: 'UserStagingApproval'
    createdAt: string
    id: string
    reviewFeedback?: string | undefined
    reviewedAt?: string | undefined
    reviewedBy?: string | undefined
    reviewedByEmail?: string | undefined
    reviewedByName?: string | undefined
    reviewedBySource?: GraphQLTypes['UserStagingApprovalReviewSource'] | undefined
    updatedAt: string
    userCampaignId: string
    userStagingApprovalOutcome: GraphQLTypes['UserStagingApprovalOutcome']
    userStagingApprovalType: GraphQLTypes['UserStagingApprovalType']
  }
  ['UtmReferral']: {
    __typename: 'UtmReferral'
    campaign?: string | undefined
    content?: string | undefined
    medium?: string | undefined
    source?: string | undefined
    term?: string | undefined
  }
  ['VerifySocialCodeResult']: {
    __typename: 'VerifySocialCodeResult'
    followers?: number | undefined
    handle: string
    isBusinessAccount?: boolean | undefined
    userId: string
    verified: boolean
  }
  ['VideoContentScan']: {
    __typename: 'VideoContentScan'
    audioDuration?: number | undefined
    audioEndAt?: number | undefined
    audioStartFrom?: number | undefined
    contentSafetyAnalysis?: GraphQLTypes['ContentSafetyAnalysis'] | undefined
    creationDateTime: string
    id: string
    processFinishedAt?: string | undefined
    processId?: string | undefined
    processInitiatedAt?: string | undefined
    scanStatus: GraphQLTypes['ContentScanStatus']
    socialMediaUrl?: string | undefined
    transcription?: string | undefined
    transcriptionConfidence?: number | undefined
    updatedDateTime: string
    url: string
    userId: string
  }
  ['Active']: Active
  ['AdminUpdateContentApprovalType']: AdminUpdateContentApprovalType
  ['AmazonReviewSubmissionStatus']: AmazonReviewSubmissionStatus
  ['ApplicationStatus']: ApplicationStatus
  ['ApprovalOutcome']: ApprovalOutcome
  ['ApprovalSort']: ApprovalSort
  ['ArchivedReason']: ArchivedReason
  ['AutoBuyDelayStrategy']: AutoBuyDelayStrategy
  ['AutoBuySkipReason']: AutoBuySkipReason
  ['AutoBuyStatus']: AutoBuyStatus
  ['BOPISValue']: BOPISValue
  ['BonusPayoutState']: BonusPayoutState
  ['BonusPayoutTypes']: BonusPayoutTypes
  ['BonusRequestTypes']: BonusRequestTypes
  ['BrandApiType']: BrandApiType
  ['BrandApiVersion']: BrandApiVersion
  ['BrandSafe']: BrandSafe
  ['CampaignBadgeType']: CampaignBadgeType
  ['CampaignGoal']: CampaignGoal
  ['CampaignProductType']: CampaignProductType
  ['CampaignReferralBonusFlag']: CampaignReferralBonusFlag
  ['CampaignSourcingSource']: CampaignSourcingSource
  ['CampaignStatus']: CampaignStatus
  ['Channel']: Channel
  ['ChatType']: ChatType
  ['CoarseCampaignStatus']: CoarseCampaignStatus
  ['ComplianceReviewCriteria']: ComplianceReviewCriteria
  ['ConnectedAccountType']: ConnectedAccountType
  ['ContentApprovalStatus']: ContentApprovalStatus
  ['ContentApprovalType']: ContentApprovalType
  ['ContentComplianceReviewStatus']: ContentComplianceReviewStatus
  ['ContentQualityRating']: ContentQualityRating
  ['ContentRatingReviewStatus']: ContentRatingReviewStatus
  ['ContentRatingSponsorshipContentInputSource']: ContentRatingSponsorshipContentInputSource
  ['ContentRequirementLevel']: ContentRequirementLevel
  ['ContentSafetyLabel']: ContentSafetyLabel
  ['ContentScanStatus']: ContentScanStatus
  ['ContentTypeRating']: ContentTypeRating
  ['ConversationChannel']: ConversationChannel
  ['ConversationInternalStatus']: ConversationInternalStatus
  ['ConversationMessageSource']: ConversationMessageSource
  ['ConversationMessageStatus']: ConversationMessageStatus
  ['ConversationStatus']: ConversationStatus
  ['CreationSourceVersion']: CreationSourceVersion
  ['CreatorPreference']: CreatorPreference
  ['Currency']: Currency
  ['Deranked_Reason']: Deranked_Reason
  ['DraftStatus']: DraftStatus
  ['EnabledFlag']: EnabledFlag
  ['FavoriteFlag']: FavoriteFlag
  ['FeatureFlagEnvironment']: FeatureFlagEnvironment
  ['FormComponentType']: FormComponentType
  ['FormFieldType']: FormFieldType
  ['FormType']: FormType
  ['FormValidationType']: FormValidationType
  ['FraudReason']: FraudReason
  ['FraudReviewStatus']: FraudReviewStatus
  ['InfluencerApprovalFlagV2']: InfluencerApprovalFlagV2
  ['InspirationalContentType']: InspirationalContentType
  ['InstagramGenderAgeDistributionGender']: InstagramGenderAgeDistributionGender
  ['ItemClassification']: ItemClassification
  ['KeywordAcceptanceResponse']: KeywordAcceptanceResponse
  ['LinkStorageType']: LinkStorageType
  ['ListingFulfillmentType']: ListingFulfillmentType
  ['ManualUploadType']: ManualUploadType
  ['ModelAttributeTypes']: ModelAttributeTypes
  ['ModelSortDirection']: ModelSortDirection
  ['NotificationStatus']: NotificationStatus
  ['OccurrenceConstraintPeriod']: OccurrenceConstraintPeriod
  ['OccurrenceConstraintType']: OccurrenceConstraintType
  ['OrderConfirmationScreenshotVerificationResult']: OrderConfirmationScreenshotVerificationResult
  ['OrderIdValidationStatus']: OrderIdValidationStatus
  ['Paused']: Paused
  ['PayForPerformanceFlag']: PayForPerformanceFlag
  ['PaymentStatus']: PaymentStatus
  ['PaymentTermsType']: PaymentTermsType
  ['Period']: Period
  ['PlatformInviteSource']: PlatformInviteSource
  ['PlatformPriority']: PlatformPriority
  ['PlatformSource']: PlatformSource
  ['PlatformStatus']: PlatformStatus
  ['PreApprovalStatus']: PreApprovalStatus
  ['ProductKeywordCreationSource']: ProductKeywordCreationSource
  ['ProductQualityLevel']: ProductQualityLevel
  ['PromoCodeType']: PromoCodeType
  ['PromoDiscountType']: PromoDiscountType
  ['PurchaseStatus']: PurchaseStatus
  ['PushChannel']: PushChannel
  ['ReferralType']: ReferralType
  ['RequiredProductLinkFlag']: RequiredProductLinkFlag
  ['ReviewFeedbackVisibility']: ReviewFeedbackVisibility
  ['ReviewScreenshotVerificationResult']: ReviewScreenshotVerificationResult
  ['RevocationType']: RevocationType
  ['ScrapeStatus']: ScrapeStatus
  ['SearchInputField']: SearchInputField
  ['SearchInputQueryScriptSource']: SearchInputQueryScriptSource
  ['SearchInputScriptType']: SearchInputScriptType
  ['SearchInputSortScriptSource']: SearchInputSortScriptSource
  ['SelfieVideoApprovalStatus']: SelfieVideoApprovalStatus
  ['ShopifyPromotionType']: ShopifyPromotionType
  ['SkuCreationSource']: SkuCreationSource
  ['SocialChannel']: SocialChannel
  ['SocialContentType']: SocialContentType
  ['SocialMediaPostContentType']: SocialMediaPostContentType
  ['SocialMediaPostGroupType']: SocialMediaPostGroupType
  ['SocialMetricName']: SocialMetricName
  ['SocialQuality']: SocialQuality
  ['SocialType']: SocialType
  ['SocialVerificationMethod']: SocialVerificationMethod
  ['SortOrder']: SortOrder
  ['SourcePriority']: SourcePriority
  ['StagingApprovalOutcome']: StagingApprovalOutcome
  ['StagingRequirementType']: StagingRequirementType
  ['StateChangeSource']: StateChangeSource
  ['StatusTier']: StatusTier
  ['StripeRegion']: StripeRegion
  ['SupportPriorityFlag']: SupportPriorityFlag
  ['TrackingStatus']: TrackingStatus
  ['USAState']: USAState
  ['UserCampaignAcceptanceStatus']: UserCampaignAcceptanceStatus
  ['UserCampaignApprovalStatus']: UserCampaignApprovalStatus
  ['UserCampaignChangelogEntryType']: UserCampaignChangelogEntryType
  ['UserCampaignSocialContentReviewOutcome']: UserCampaignSocialContentReviewOutcome
  ['UserCampaignSocialContentReviewStatus']: UserCampaignSocialContentReviewStatus
  ['UserCampaignSocialContentReviewType']: UserCampaignSocialContentReviewType
  ['UserCampaignStateChangeRequestType']: UserCampaignStateChangeRequestType
  ['UserFilterGender']: UserFilterGender
  ['UserGender']: UserGender
  ['UserStagingApprovalAction']: UserStagingApprovalAction
  ['UserStagingApprovalOutcome']: UserStagingApprovalOutcome
  ['UserStagingApprovalReviewSource']: UserStagingApprovalReviewSource
  ['UserStagingApprovalType']: UserStagingApprovalType
  ['ValidMccCodes']: ValidMccCodes
  ['WSLocale']: WSLocale
  ['AddressComponentsSearchFields']: {
    country?: GraphQLTypes['SearchInputString'] | undefined
    state?: GraphQLTypes['SearchInputString'] | undefined
  }
  ['AdminSendPushNotificationInput']: {
    body?: string | undefined
    notificationId: string
    userCampaigns?: Array<string> | undefined
    userIds?: Array<string> | undefined
  }
  ['ApplyToCampaignInput']: {
    autoApprove?: boolean | undefined
    campaignApplication?: string | undefined
    campaignId: string
    productId?: string | undefined
    userId: string
  }
  ['ApprovalFilterValueInput']: {
    filter: string
    value: string
  }
  ['ApproveUserCampaignForPurchaseInput']: {
    autoBuyApprovalDate?: string | undefined
    autoJoin?: boolean | undefined
    userCampaignId: string
  }
  ['AutoApprovalCriteriaInput']: {
    completedCampaigns?: number | undefined
  }
  ['AutoApprovalV2ConfigInput']: {
    autoApprovalCriteria?: GraphQLTypes['AutoApprovalCriteriaInput'] | undefined
    enabled?: boolean | undefined
  }
  ['AutoBuyScheduleInput']: {
    date: string
    desiredPurchaseCount: number
    willPurchasePercentage?: number | undefined
  }
  ['BillingContactInput']: {
    email: string
    name: string
  }
  ['CampaignBadgeInput']: {
    type: GraphQLTypes['CampaignBadgeType']
    value: string
  }
  ['ChannelConfigurationInput']: {
    attributes?: Array<GraphQLTypes['KeyAttributeInput'] | undefined> | undefined
    enabled?: boolean | undefined
    fromAddress?: string | undefined
    templateId: string
  }
  ['ClicksRecordsInput']: {
    clicks: number
    from: string
    to: string
  }
  ['CollisionDensityValueInput']: {
    density: number
    skuId: string
  }
  ['ContentApprovalConfigurationInput']: {
    contentApprovalAssignee?: string | undefined
    contentApprovalType: GraphQLTypes['ContentApprovalType']
  }
  ['ContentComplianceReviewCriteriaInput']: {
    compliant?: boolean | undefined
    criteria: GraphQLTypes['ComplianceReviewCriteria']
    requirement?: string | undefined
  }
  ['ContentGuidanceInput']: {
    instance: number
    text: string
  }
  ['ContentLabelResultInput']: {
    confidence?: number | undefined
    label: GraphQLTypes['ContentSafetyLabel']
    severity?: number | undefined
  }
  ['ContentRatingSponsorshipContentInput']: {
    inputSource?: GraphQLTypes['ContentRatingSponsorshipContentInputSource'] | undefined
    isCompliant?: boolean | undefined
    isInappropriate?: boolean | undefined
    link: string
    qualityRating?: number | undefined
  }
  ['ContentSafetyAnalysisInput']: {
    isBrandSafe?: GraphQLTypes['BrandSafe'] | undefined
    summary?: Array<GraphQLTypes['ContentSafetyLabelSummaryInput'] | undefined> | undefined
    textInstances?: Array<GraphQLTypes['ContentSafetyTextInstanceInput'] | undefined> | undefined
  }
  ['ContentSafetyLabelSummaryInput']: {
    confidence?: number | undefined
    label?: GraphQLTypes['ContentSafetyLabel'] | undefined
    severity?: GraphQLTypes['ContentSafetySeverityInput'] | undefined
  }
  ['ContentSafetySeverityInput']: {
    high?: number | undefined
    low?: number | undefined
    medium?: number | undefined
  }
  ['ContentSafetyTextInstanceInput']: {
    labels?: Array<GraphQLTypes['ContentLabelResultInput'] | undefined> | undefined
    text: string
    timestampEnd?: number | undefined
    timestampStart?: number | undefined
  }
  ['CreateAutoBuyDistributionInput']: {
    createdAt?: string | undefined
    description?: string | undefined
    id?: string | undefined
    name: string
    schedule: Array<number>
    updatedAt?: string | undefined
  }
  ['CreateAutoBuyFlowConfigurationInput']: {
    dailyPurchaseMax?: number | undefined
    delayStrategy?: GraphQLTypes['AutoBuyDelayStrategy'] | undefined
    flowId: string
    insufficientCreators?: string | undefined
    latestSkipReason?: GraphQLTypes['AutoBuySkipReason'] | undefined
    pauseReason?: GraphQLTypes['AutoBuySkipReason'] | undefined
    schedule?: Array<GraphQLTypes['AutoBuyScheduleInput']> | undefined
    startDate: string
    status: GraphQLTypes['AutoBuyStatus']
  }
  ['CreateAutoBuyFlowReleaseScheduleInput']: {
    actualPurchases?: number | undefined
    actualVerifiedPurchases?: number | undefined
    adjustedDesiredPurchases?: number | undefined
    autoApprovalSeats?: number | undefined
    date: string
    desiredPurchases?: number | undefined
    flowId: string
    manualPurchases?: number | undefined
    manualReleases?: number | undefined
    projectedReleaseCount?: number | undefined
    releaseCount?: number | undefined
    released?: boolean | undefined
    skippedReason?: GraphQLTypes['AutoBuySkipReason'] | undefined
  }
  ['CreateAutoBuyReleaseInput']: {
    date: string
    productId: string
    releaseDateTime: string
    skuId?: string | undefined
    userCampaignId: string
  }
  ['CreateBrandApiInput']: {
    accessToken?: string | undefined
    api: GraphQLTypes['BrandApiType']
    apiVersion?: GraphQLTypes['BrandApiVersion'] | undefined
    brandId: string
    enabled?: boolean | undefined
    locale?: GraphQLTypes['WSLocale'] | undefined
    refreshToken?: string | undefined
    shop?: string | undefined
    shopId?: string | undefined
  }
  ['CreateBrandInput']: {
    active?: string | undefined
    amazonBrandName?: string | undefined
    brandDetailsSubmitted?: boolean | undefined
    brandInstaHandle?: string | undefined
    brandLogoImageKey?: string | undefined
    brandStoreConfirmationImage?: string | undefined
    brandStoreConfirmationMobileImage?: string | undefined
    brandStoreScrapeUrl?: string | undefined
    brandTikTokHandle?: string | undefined
    companyId?: string | undefined
    contactEmails?: Array<string | undefined> | undefined
    defaultCreatorPreference?: GraphQLTypes['CreatorPreference'] | undefined
    defaultCreatorPreferenceDetails?: string | undefined
    defaultHashtags?: Array<string> | undefined
    defaultMarketingBriefKey?: string | undefined
    defaultPostingGuidelines?: Array<string> | undefined
    defaultPostingInspirationLinks?: Array<string> | undefined
    emailsSent?: Array<string | undefined> | undefined
    fullScrapedAt?: string | undefined
    id?: string | undefined
    isBrandStoreScrapeEnabled?: boolean | undefined
    isSeller?: string | undefined
    isVendor?: string | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    lastScrapedAt?: string | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    paymentBillingContact?: GraphQLTypes['BillingContactInput'] | undefined
    platform?: GraphQLTypes['PlatformSource'] | undefined
    postingGuidanceDocument?: string | undefined
    region?: GraphQLTypes['WSLocale'] | undefined
    socialMediaExampleUrls?: Array<string> | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    walmartBrandName?: string | undefined
    walmartLastScrapedAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['CreateCampaignClicksInput']: {
    campaignId: string
    clicks: number
    date: string
  }
  ['CreateCampaignInput']: {
    aboutSection?: string | undefined
    active: string
    actualStartDate?: string | undefined
    amountPaidByClient?: number | undefined
    anticipatedStartDate: string
    applicationForm?: string | undefined
    applicationProductLinkId?: string | undefined
    applicationStatus?: GraphQLTypes['ApplicationStatus'] | undefined
    attributionRefUrlSuffix?: string | undefined
    authorizedUnits?: number | undefined
    badges?: Array<GraphQLTypes['CampaignBadgeInput'] | undefined> | undefined
    brandId: string
    brandProductShort?: string | undefined
    brandStoreLink?: string | undefined
    budgetSpent?: number | undefined
    campaignProductType?: GraphQLTypes['CampaignProductType'] | undefined
    campaignReferralBonusAmount?: number | undefined
    campaignReferralBonusEnabled?: GraphQLTypes['CampaignReferralBonusFlag'] | undefined
    campaignStateMachine?: string | undefined
    closeoutReleaseFailureReason?: string | undefined
    closeoutReleaseRate?: GraphQLTypes['RateInput'] | undefined
    collisionSkuDensity?: Array<GraphQLTypes['CollisionDensityValueInput']> | undefined
    commissionFlatRate?: number | undefined
    commissionPercentageRate?: number | undefined
    committedClicks?: number | undefined
    committedPlacementWinsCount?: number | undefined
    committedReviewsCount?: number | undefined
    contentApprovalRequired?: boolean | undefined
    contentCompleteDeadline?: string | undefined
    createdAt?: string | undefined
    creationSourceVersion?: GraphQLTypes['CreationSourceVersion'] | undefined
    earlyReviewEnabled?: boolean | undefined
    enableContentComplianceReview?: boolean | undefined
    endDate?: string | undefined
    excludeGender?: GraphQLTypes['UserFilterGender'] | undefined
    expectedBonus?: string | undefined
    followersCountCeiling?: number | undefined
    followersCountFloor?: number | undefined
    goal?: GraphQLTypes['CampaignGoal'] | undefined
    id?: string | undefined
    influencerApprovalEnabled?: GraphQLTypes['InfluencerApprovalFlagV2'] | undefined
    lifestyleCommittedContentCount?: number | undefined
    localeV1?: GraphQLTypes['WSLocale'] | undefined
    marketingFee?: number | undefined
    marketingFormFilled?: boolean | undefined
    newCreatorsOnly?: boolean | undefined
    payForClicksEnabled?: GraphQLTypes['EnabledFlag'] | undefined
    payForClicksGeneratedPerPeriod?: Array<GraphQLTypes['ClicksRecordsInput']> | undefined
    payForClicksMinClicks?: number | undefined
    payForClicksOriginalLink?: string | undefined
    payForClicksTotalClicksGenerated?: number | undefined
    payForPerformanceEnabled?: GraphQLTypes['PayForPerformanceFlag'] | undefined
    postingGuidance?: string | undefined
    preApplicationProductImage?: string | undefined
    preApplicationProductPriceRange?: string | undefined
    preApplicationUgcProductImage?: string | undefined
    preLaunchDate?: string | undefined
    premiumLifestyleCommittedContentCount?: number | undefined
    requiredProductLinksEnabled?: GraphQLTypes['RequiredProductLinkFlag'] | undefined
    reviewVotingTarget?: string | undefined
    sample?: boolean | undefined
    shellCampaignBase?: string | undefined
    skuId: string
    skuPriceOverride?: number | undefined
    socialCommittedContentCount?: number | undefined
    sourcingPriority?: number | undefined
    sponsorshipInstructions?: string | undefined
    title: string
    totalEarlyReviewsAllowed?: number | undefined
    totalProductCost?: number | undefined
    userAutoApproved?: boolean | undefined
    visibleToUser: string
  }
  ['CreateCampaignInviteInput']: {
    campaignId: string
    createdAt?: string | undefined
    id?: string | undefined
    notificationStatus: GraphQLTypes['NotificationStatus']
    priority: number
    updatedAt?: string | undefined
    userId: string
  }
  ['CreateCampaignSourcingProspectInput']: {
    bonusConfig?: GraphQLTypes['UserCampaignBonusConfigInput'] | undefined
    campaignId: string
    createdAt?: string | undefined
    createdById?: string | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    internalNotesId?: string | undefined
    name?: string | undefined
    platformInviteSource: GraphQLTypes['PlatformInviteSource']
    platformStatus?: GraphQLTypes['PlatformStatus'] | undefined
    preApprovalStatus?: GraphQLTypes['PreApprovalStatus'] | undefined
    preApprovalStatusById?: string | undefined
    priority?: GraphQLTypes['SourcePriority'] | undefined
    productId?: string | undefined
    sourcingSource: GraphQLTypes['CampaignSourcingSource']
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    userCampaignId?: string | undefined
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
  }
  ['CreateChatConversationV2Input']: {
    active: GraphQLTypes['Active']
    adminLastReadMessageIndex?: number | undefined
    adminUnreadCount: number
    campaignId?: string | undefined
    chatType: GraphQLTypes['ChatType']
    creatorLastReadMessageIndex?: number | undefined
    creatorUnreadCount: number
    lastMessage?: string | undefined
    lastMessageDate?: string | undefined
    lastMessageId?: string | undefined
    lastMessageSource?: GraphQLTypes['ConversationMessageSource'] | undefined
    resourceId: string
    status: GraphQLTypes['ConversationInternalStatus']
    statusUpdatedAt?: string | undefined
    statusUpdatedByFirstName?: string | undefined
    statusUpdatedById?: string | undefined
    statusUpdatedByLastName?: string | undefined
    twilioConversationId: string
    userCampaignId?: string | undefined
    userEmail?: string | undefined
    userFirstName?: string | undefined
    userId: string
    userLastName?: string | undefined
  }
  ['CreateCommunicationNotificationInput']: {
    address: string
    attributes?: Array<GraphQLTypes['KeyAttributeInput']> | undefined
    campaignId?: string | undefined
    channel: GraphQLTypes['Channel']
    expectedSentAt: string
    experimentTreatment?: string | undefined
    id?: string | undefined
    notificationId: string
    sentAt: string
    status?: string | undefined
    statusMessage?: string | undefined
    templateId?: string | undefined
    traceId: string
    userCampaignId?: string | undefined
    userId: string
  }
  ['CreateCompanyInput']: {
    agencyId?: string | undefined
    id?: string | undefined
    logo?: string | undefined
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: GraphQLTypes['PaymentTermsType'] | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['CreateConnectedAccountInput']: {
    accountId: string
    accountType: GraphQLTypes['ConnectedAccountType']
    createdAt?: string | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['CreateConversationInput']: {
    contactOwnerId?: string | undefined
    lastContactAt?: string | undefined
    status?: GraphQLTypes['ConversationStatus'] | undefined
    statusChangedAt?: string | undefined
    userId: string
  }
  ['CreateConversationMessageInput']: {
    channel: GraphQLTypes['ConversationChannel']
    id?: string | undefined
    messageContent: string
    messageId?: string | undefined
    messageMediaKeys?: Array<string> | undefined
    replyFromId?: string | undefined
    sendAt?: string | undefined
    sendAttempts?: number | undefined
    sentAt?: string | undefined
    status: GraphQLTypes['ConversationMessageStatus']
    userId: string
  }
  ['CreateEtailerSkuMetricsInput']: {
    date: string
    pageViews?: number | undefined
    sku: string
    skuId: string
    totalSalesAmount?: number | undefined
    unitsSold?: number | undefined
  }
  ['CreateFormSequenceConfigurationInput']: {
    active: GraphQLTypes['Active']
    id?: string | undefined
    sequenceName: string
    sequences?: Array<GraphQLTypes['FormSequenceInput']> | undefined
  }
  ['CreateInstagramStoryScreenInput']: {
    exits?: number | undefined
    expirationUnixTime: number
    externalId?: string | undefined
    id?: string | undefined
    impressions?: number | undefined
    instagramUserId: string
    mediaId: string
    postedAt?: string | undefined
    postingDate: string
    reach?: number | undefined
    replies?: number | undefined
    s3Bucket?: string | undefined
    tapsBack?: number | undefined
    tapsForward?: number | undefined
    userCampaignId?: string | undefined
  }
  ['CreateMessageInput']: {
    body: string
    createdAt?: string | undefined
    id?: string | undefined
    updatedAt?: string | undefined
    userCampaignId?: string | undefined
    userHasSeen?: boolean | undefined
    userId: string
  }
  ['CreateNoteInput']: {
    createdAt?: string | undefined
    id?: string | undefined
    note: string
    parentId: string
    userId: string
  }
  ['CreateNotificationConfigurationInput']: {
    category: string
    description?: string | undefined
    email?: GraphQLTypes['ChannelConfigurationInput'] | undefined
    enabled?: boolean | undefined
    id?: string | undefined
    inAppChatChannel?: GraphQLTypes['MessagingChannelConfigurationInput'] | undefined
    name: string
    occurrenceConstraint?: GraphQLTypes['OccurrenceConstraintInput'] | undefined
    push?: GraphQLTypes['PushChannelConfigurationInput'] | undefined
    sms?: GraphQLTypes['ChannelConfigurationInput'] | undefined
  }
  ['CreateProductBonusConfigSnapshotInput']: {
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<GraphQLTypes['ProductSocialContentInput']> | undefined
    discoveryBonus?: number | undefined
    payForImpressionsBonus?: GraphQLTypes['PayForImpressionsBonusConfigInput'] | undefined
    productId: string
    snapshotAt: string
  }
  ['CreateProductFromUrlInput']: {
    brandId: string
    creationSource: GraphQLTypes['SkuCreationSource']
    productUrl: string
  }
  ['CreateProductInput']: {
    accountRequired?: boolean | undefined
    active?: string | undefined
    approvalFilters?: Array<GraphQLTypes['ApprovalFilterValueInput']> | undefined
    autoApprovalSeatsAvailable?: number | undefined
    autoApprovalStatusTiers?: Array<GraphQLTypes['StatusTier']> | undefined
    autoApprovalV2Config?: GraphQLTypes['AutoApprovalV2ConfigInput'] | undefined
    automaticReviewRelease?: boolean | undefined
    baseBonus?: number | undefined
    brandAdvocateCount?: number | undefined
    brandTalkingPoints?: Array<string> | undefined
    buyOnlinePickupInStore?: GraphQLTypes['BOPISValue'] | undefined
    campaignId?: string | undefined
    campaignStateMachine?: string | undefined
    closeoutFormBonus?: number | undefined
    contentApprovalConfiguration?: Array<GraphQLTypes['ContentApprovalConfigurationInput']> | undefined
    contentApprovalLeadDays?: number | undefined
    contentRequirement?: Array<GraphQLTypes['ProductSocialContentInput']> | undefined
    creatorCount?: number | undefined
    creatorPreference?: GraphQLTypes['CreatorPreference'] | undefined
    creatorPreferenceDetails?: string | undefined
    customFtcHashtags?: Array<string> | undefined
    customHashtags?: Array<string> | undefined
    directPurchaseLink?: string | undefined
    engagementRateInstagramRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    engagementRateTiktokRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    followersInstagramRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    followersTiktokRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    hideBrandContentApprovals?: boolean | undefined
    id?: string | undefined
    impressionsInstagramRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    impressionsTiktokRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    instaHandle?: string | undefined
    internalCreatorsStagingGuidance?: string | undefined
    internalDescription?: string | undefined
    internalName?: string | undefined
    internalNotes?: string | undefined
    isDirectListingPurchase?: boolean | undefined
    isQualityRequirementsEnabled?: boolean | undefined
    listingGuidance?: string | undefined
    maxDiscoveryBonus?: number | undefined
    minimumCreatorQualityScore?: number | undefined
    nonRetailReviewLink?: string | undefined
    nonRetailReviewWebsiteName?: string | undefined
    payForClicksEnabled?: GraphQLTypes['EnabledFlag'] | undefined
    payForClicksMinClicks?: number | undefined
    payForImpressionsBonus?: GraphQLTypes['PayForImpressionsBonusConfigInput'] | undefined
    payForPerformanceBonus?: GraphQLTypes['StatusTierBonusConfigInput'] | undefined
    payForPostingContentEarly?: GraphQLTypes['PayForPostingContentEarlyConfigInput'] | undefined
    postingDeadlineDaysRelativeToApproval?: number | undefined
    productLinkId?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    promoCode?: string | undefined
    promoCodeGuidance?: string | undefined
    promoCodeType?: GraphQLTypes['PromoCodeType'] | undefined
    promoDiscountAmount?: number | undefined
    promoDiscountType?: GraphQLTypes['PromoDiscountType'] | undefined
    promoEndDate?: string | undefined
    promoLink?: string | undefined
    promoStartDate?: string | undefined
    promotionLinkId?: string | undefined
    purchaseByPromoCode?: boolean | undefined
    purchaseDiscountCode?: string | undefined
    purchaseDiscountCodeDetails?: string | undefined
    purchaseDiscountCodePercentage?: string | undefined
    qualityLevel?: GraphQLTypes['ProductQualityLevel'] | undefined
    requestEarlyProductFeedback?: boolean | undefined
    requestProductFeedback?: boolean | undefined
    requiredProductLinkEnabled?: GraphQLTypes['RequiredProductLinkFlag'] | undefined
    sequenceId?: string | undefined
    shopifyPriceRule?: string | undefined
    shopifyPromotionId?: string | undefined
    shopifyPromotionType?: GraphQLTypes['ShopifyPromotionType'] | undefined
    skuId: string
    socialMediaExampleUrls?: Array<string> | undefined
    stagingApprovalConfiguration?: Array<GraphQLTypes['StagingApprovalConfigurationInput']> | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined
    stagingRequirementType?: GraphQLTypes['StagingRequirementType'] | undefined
    stagingSocialQualityRequirement?: GraphQLTypes['SocialQuality'] | undefined
    tikTokHandle?: string | undefined
    unitsPurchased?: number | undefined
    validateProductFoundVariations?: Array<string> | undefined
    visibleAt?: string | undefined
    visibleEndsAt?: string | undefined
  }
  ['CreateProductKeywordsInput']: {
    adminPriority?: number | undefined
    brandPriority?: number | undefined
    brandResponse?: GraphQLTypes['KeywordAcceptanceResponse'] | undefined
    campaignId: string
    creationSource?: GraphQLTypes['ProductKeywordCreationSource'] | undefined
    endDate?: string | undefined
    heliumAccount?: string | undefined
    heliumAsin?: string | undefined
    heliumLink?: string | undefined
    id?: string | undefined
    keyword: string
    monthlySearchVolume?: number | undefined
    rank?: number | undefined
    rankingHistory?: Array<GraphQLTypes['RankPointInput']> | undefined
    rankingLastSkippedAt?: string | undefined
    rankingLastSkippedByUserId?: string | undefined
    rankingLastUpdatedAt?: string | undefined
    rankingLastUpdatedByUserId?: string | undefined
    recommended?: boolean | undefined
    skuId?: string | undefined
    startDate?: string | undefined
    usedForCampaign?: boolean | undefined
    visible?: boolean | undefined
    weight?: number | undefined
  }
  ['CreateProductRatingSnapshotInput']: {
    numFiveStar?: number | undefined
    numFourStar?: number | undefined
    numOneStar?: number | undefined
    numRatings?: number | undefined
    numReviews?: number | undefined
    numThreeStar?: number | undefined
    numTwoStar?: number | undefined
    rating?: number | undefined
    sku: string
    skuId: string
    snapshotAt: string
  }
  ['CreatePurchaseInput']: {
    amount: number
    cardId?: string | undefined
    currency: string
    id?: string | undefined
    isFraud?: boolean | undefined
    keywordAtPurchase?: string | undefined
    purchasedAt: string
    shippedAt?: string | undefined
    status: GraphQLTypes['PurchaseStatus']
    userCampaignId: string
  }
  ['CreateReferralInput']: {
    bonusPayoutId?: string | undefined
    campaignId?: string | undefined
    id?: string | undefined
    referralType?: GraphQLTypes['ReferralType'] | undefined
    referredUserId: string
    referrerUserId: string
  }
  ['CreateReviewInput']: {
    attributedUserId?: string | undefined
    author?: string | undefined
    authorBadge?: string | undefined
    badge?: string | undefined
    commentCount?: number | undefined
    helpfulReactions?: number | undefined
    id?: string | undefined
    negativeReactions?: number | undefined
    photos?: Array<string> | undefined
    productId: string
    productVariant?: string | undefined
    rating?: number | undefined
    removed?: boolean | undefined
    reviewedAt: string
    reviewedSku?: string | undefined
    reviewerId?: string | undefined
    sku?: string | undefined
    skuId: string
    store?: string | undefined
    text?: string | undefined
    title?: string | undefined
    userCampaignId?: string | undefined
    videos?: Array<string> | undefined
  }
  ['CreateSkuInput']: {
    active?: string | undefined
    approxRetailPrice?: string | undefined
    approxShippingCost?: number | undefined
    attributes?: Array<GraphQLTypes['SkuAttributeInput']> | undefined
    availabilityDate?: string | undefined
    availabilityNotified?: boolean | undefined
    availabilityNotifiedAt?: string | undefined
    brandId?: string | undefined
    brandListingKey?: string | undefined
    brandStoreId?: string | undefined
    bundle?: string | undefined
    createdByUserId?: string | undefined
    creationSource?: GraphQLTypes['SkuCreationSource'] | undefined
    creationSourceUrl?: string | undefined
    currency?: GraphQLTypes['Currency'] | undefined
    currentBuyBoxPrice?: number | undefined
    currentBuyBoxPriceCents?: number | undefined
    estimatedAvailabilityDate?: string | undefined
    estimatedDeliveryDays?: number | undefined
    estimatedMonthlyUnitsSold?: number | undefined
    estimatedWeeklyUnitsSold?: number | undefined
    exactListingTitle?: string | undefined
    exactRetailPrice?: string | undefined
    fullReviewScrapeComplete?: boolean | undefined
    id?: string | undefined
    inventory?: number | undefined
    iosAppProviderId?: string | undefined
    isAplusPage?: string | undefined
    isPrime?: string | undefined
    isRecommendationsHidden?: boolean | undefined
    itemClassification?: GraphQLTypes['ItemClassification'] | undefined
    lastAutoFilledAt?: string | undefined
    lastKnownBuyBoxPrice?: number | undefined
    lastKnownBuyBoxPriceCents?: number | undefined
    listingBrand?: string | undefined
    listingCategory?: Array<string> | undefined
    listingDescription?: string | undefined
    listingFulfillmentType?: GraphQLTypes['ListingFulfillmentType'] | undefined
    listingGuidance?: string | undefined
    listingLink?: string | undefined
    mccValue?: GraphQLTypes['ValidMccCodes'] | undefined
    minimumOrderQuantity?: number | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    nonSponsoredListingKey?: string | undefined
    parentSkuId?: string | undefined
    priceCents?: number | undefined
    product?: string | undefined
    productImageKey?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    productSearchImageKey?: string | undefined
    releaseDate?: string | undefined
    skuId?: string | undefined
    sponsoredListingKey?: string | undefined
    storeName?: string | undefined
    targetReviewRate?: GraphQLTypes['RateInput'] | undefined
    targetReviewRateInvalid?: string | undefined
  }
  ['CreateSkuSnapshotInput']: {
    inventory?: number | undefined
    marketplaceSku: string
    price?: number | undefined
    skuId: string
    snapshotDate: string
  }
  ['CreateTwilioConversationInput']: {
    chatType: GraphQLTypes['ChatType']
    resourceId: string
  }
  ['CreateUserBonusPayoutInput']: {
    adminPaymentApproved?: boolean | undefined
    bonusType: GraphQLTypes['BonusPayoutTypes']
    createdAt?: string | undefined
    failureReason?: string | undefined
    id?: string | undefined
    manuallyCreatedBy?: string | undefined
    payoutAmount?: number | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: GraphQLTypes['WSLocale'] | undefined
    payoutState?: GraphQLTypes['BonusPayoutState'] | undefined
    quantity?: number | undefined
    referenceId?: string | undefined
    releaseAt?: string | undefined
    revocationType?: GraphQLTypes['RevocationType'] | undefined
    revokedAt?: string | undefined
    userCampaignId?: string | undefined
    userId: string
  }
  ['CreateUserCampaignChangelogInput']: {
    createdAt?: string | undefined
    createdByUserId?: string | undefined
    entryType: GraphQLTypes['UserCampaignChangelogEntryType']
    extraData?: string | undefined
    userCampaignId: string
  }
  ['CreateUserCampaignInput']: {
    accountScreenShot?: string | undefined
    amznReviewLink?: string | undefined
    amznReviewSubmissionStatus?: GraphQLTypes['AmazonReviewSubmissionStatus'] | undefined
    applicationRejectedByUserId?: string | undefined
    approvalOutcome?: GraphQLTypes['ApprovalOutcome'] | undefined
    approvalStatus?: GraphQLTypes['UserCampaignApprovalStatus'] | undefined
    archivedFeedback?: string | undefined
    archivedReason?: GraphQLTypes['ArchivedReason'] | undefined
    autoBuyApprovalDate?: string | undefined
    autoBuyRevokeDate?: string | undefined
    bestBuyReviewScreenShot?: string | undefined
    bonusConfig?: GraphQLTypes['UserCampaignBonusConfigInput'] | undefined
    campaignAcceptanceStatus?: GraphQLTypes['UserCampaignAcceptanceStatus'] | undefined
    campaignApplication?: string | undefined
    campaignId: string
    campaignStatus?: GraphQLTypes['CampaignStatus'] | undefined
    campaignStatusOnArchive?: string | undefined
    cardId?: string | undefined
    cardRegion?: GraphQLTypes['StripeRegion'] | undefined
    closeoutSurvey?: string | undefined
    coarseCampaignStatus?: GraphQLTypes['CoarseCampaignStatus'] | undefined
    contentApprovalLeadDays?: number | undefined
    createdAt?: string | undefined
    discoverySurvey?: string | undefined
    id?: string | undefined
    influencerApprovalInviteFeedback?: string | undefined
    influencerApprovalRejectFeedback?: string | undefined
    invoiceKey?: string | undefined
    isAutoApproved?: boolean | undefined
    keywordAtPurchase?: string | undefined
    keywordId?: string | undefined
    keywordsV2Hash?: string | undefined
    listingKey?: string | undefined
    localeV1?: GraphQLTypes['WSLocale'] | undefined
    manualPurchase?: boolean | undefined
    moderatorStagingApprovalAt?: string | undefined
    moderatorStagingApprovalBy?: string | undefined
    moderatorStagingApprovalOutcome?: GraphQLTypes['StagingApprovalOutcome'] | undefined
    orderId?: string | undefined
    orderIdInvalidReason?: string | undefined
    orderIdSubmissionDate?: string | undefined
    orderIdValidationDate?: string | undefined
    orderIdValidationStatus?: GraphQLTypes['OrderIdValidationStatus'] | undefined
    orderStatus?: string | undefined
    payForClickLinkId?: string | undefined
    paypalEmailAtSubmission?: string | undefined
    postingDeadline?: string | undefined
    postingDeadlineExtended?: boolean | undefined
    previousCardIds?: Array<string> | undefined
    priority?: GraphQLTypes['SourcePriority'] | undefined
    productCriticalFeedback?: string | undefined
    productId?: string | undefined
    productPositiveFeedback?: string | undefined
    rankPriority?: number | undefined
    reviewScreenshot?: string | undefined
    reviewScreenshotVerificationResult?: GraphQLTypes['ReviewScreenshotVerificationResult'] | undefined
    schedulingReleaseAt?: string | undefined
    searchPhraseKey?: string | undefined
    selectedFlow?: string | undefined
    selfieVideoApprovalDate?: string | undefined
    selfieVideoApprovalNotes?: string | undefined
    selfieVideoApprovalStatus?: GraphQLTypes['SelfieVideoApprovalStatus'] | undefined
    selfieVideoKey?: string | undefined
    selfieVideoRejectionReason?: string | undefined
    selfieVideoSubmissionRequired?: boolean | undefined
    selfieVideoSubmittedAt?: string | undefined
    sequenceFormSubmissionValues?: Array<GraphQLTypes['SequenceFormSubmissionValuesInput']> | undefined
    shippingAddress?: string | undefined
    showEarlyReviewSurvey?: boolean | undefined
    statusHistory?: string | undefined
    updatedAt?: string | undefined
    userId: string
    walmartReviewScreenShot?: string | undefined
  }
  ['CreateUserCampaignSnapshotInput']: {
    snapshot: string
    userCampaignId: string
    userCampaignVersion: number
  }
  ['CreateUserCampaignSocialContentDraftHistoryInput']: {
    id?: string | undefined
    snapshot: string
    snapshotDate: string
    userCampaignSocialContentId: string
  }
  ['CreateUserCampaignSocialContentInput']: {
    approvalFeedback?: string | undefined
    approvalStatus?: GraphQLTypes['ContentApprovalStatus'] | undefined
    approvedAt?: string | undefined
    approverPWUserId?: string | undefined
    approverUserId?: string | undefined
    bonusEarned?: number | undefined
    bonusOverride?: number | undefined
    caption?: string | undefined
    channel: GraphQLTypes['SocialChannel']
    clientFeedback?: Array<GraphQLTypes['SocialMediaFeedbackInput']> | undefined
    complianceReview?: Array<GraphQLTypes['ContentComplianceReviewCriteriaInput']> | undefined
    complianceReviewStatus?: GraphQLTypes['ContentComplianceReviewStatus'] | undefined
    complianceReviewUserId?: string | undefined
    complianceReviewedAt?: string | undefined
    contentType?: GraphQLTypes['SocialContentType'] | undefined
    draftEnteredAdminApprovalAt?: string | undefined
    draftEnteredBrandApprovalAt?: string | undefined
    draftFeedback?: Array<string> | undefined
    draftHistory?: Array<string> | undefined
    draftMedia?: Array<string> | undefined
    draftPreApprovedText?: string | undefined
    draftQualityScore?: number | undefined
    draftReview?: Array<GraphQLTypes['ContentComplianceReviewCriteriaInput']> | undefined
    draftStatus?: GraphQLTypes['DraftStatus'] | undefined
    draftSubmittedAt?: string | undefined
    draftText?: string | undefined
    earlySubmissionBonusEarnedCents?: number | undefined
    experimentId?: string | undefined
    externalUrl?: string | undefined
    groupId?: string | undefined
    hashtags?: Array<string> | undefined
    id?: string | undefined
    instance?: number | undefined
    internalFeedback?: Array<GraphQLTypes['SocialMediaFeedbackInput']> | undefined
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    isPurchased?: boolean | undefined
    isUnavailable?: boolean | undefined
    manualUploadType?: GraphQLTypes['ManualUploadType'] | undefined
    mediaId?: string | undefined
    mediaUrls?: Array<string> | undefined
    metrics?: Array<GraphQLTypes['SocialMetricInput']> | undefined
    paymentId?: string | undefined
    postedAt?: string | undefined
    qualityScore?: number | undefined
    rejectionReason?: string | undefined
    requestedSocialScript?: boolean | undefined
    requirementLevel?: GraphQLTypes['ContentRequirementLevel'] | undefined
    scrapeStatus?: GraphQLTypes['ScrapeStatus'] | undefined
    unavailableAt?: string | undefined
    usedRequestedSocialScript?: boolean | undefined
    userCampaignId: string
    videoEndTime?: string | undefined
    videoStartTime?: string | undefined
  }
  ['CreateUserCampaignSocialContentReviewInput']: {
    createdAt?: string | undefined
    reviewFeedback?: Array<GraphQLTypes['UserCampaignSocialContentReviewFeedbackInput']> | undefined
    reviewOutcome?: GraphQLTypes['UserCampaignSocialContentReviewOutcome'] | undefined
    reviewStatus?: GraphQLTypes['UserCampaignSocialContentReviewStatus'] | undefined
    reviewType: GraphQLTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined
    reviewerAssigneeId?: string | undefined
    reviewerFirstName?: string | undefined
    reviewerId?: string | undefined
    reviewerLastName?: string | undefined
    userCampaignSocialContentId: string
    userId: string
  }
  ['CreateUserDeviceSnapshotInput']: {
    id?: string | undefined
    idfv?: string | undefined
    ipAddress?: string | undefined
    isEmulator?: boolean | undefined
    snapshotAt: string
    timezone?: string | undefined
    userId: string
  }
  ['CreateUserFollowerInput']: {
    createdAt?: string | undefined
    followerUserId: string
    isFavorite?: GraphQLTypes['FavoriteFlag'] | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['CreateUserInput']: {
    active?: GraphQLTypes['Active'] | undefined
    addressComponents?: string | undefined
    amznLastChecked?: string | undefined
    amznLink?: string | undefined
    amznPrime?: string | undefined
    amznProfileId?: string | undefined
    amznReviewerId?: string | undefined
    amznVerified?: string | undefined
    attributedReviewCount?: number | undefined
    blogWebsite?: string | undefined
    brandContentRatingAvg?: number | undefined
    campaignAcceptanceRate?: number | undefined
    campaignApprovalRate?: number | undefined
    campaignCompletionRate?: number | undefined
    campaignReviewRate?: number | undefined
    cardProfileUpdates?: string | undefined
    cardholderId?: string | undefined
    cardholderRegion?: GraphQLTypes['StripeRegion'] | undefined
    characteristics?: Array<GraphQLTypes['UserCharacteristicInput']> | undefined
    characteristicsUpdatedAt?: string | undefined
    claimedReviewCount?: number | undefined
    collisionSkuSet?: Array<string> | undefined
    collisionSkuSetCalculatedAt?: string | undefined
    completedLifestyleCampaignCount?: number | undefined
    completedLiftCampaignCount?: number | undefined
    completedSocialCampaignCount?: number | undefined
    contentRating?: GraphQLTypes['UserContentRatingInput'] | undefined
    contentRatingCompletedAt?: string | undefined
    contentRatingCompletionTimeMillis?: number | undefined
    contentRatingInstagramCompletionTimeMillis?: number | undefined
    contentRatingInstagramSponsorshipContent?: Array<GraphQLTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentRatingQualityScore?: number | undefined
    contentRatingReviewStatus?: GraphQLTypes['ContentRatingReviewStatus'] | undefined
    contentRatingSponsorshipContent?: Array<GraphQLTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentRatingSubmittedBy?: string | undefined
    contentRatingTiktokCompletionTimeMillis?: number | undefined
    contentRatingTiktokSponsorshipContent?: Array<GraphQLTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentTypes?: Array<string> | undefined
    createdAt?: string | undefined
    date_of_birth?: string | undefined
    deactivationDate?: string | undefined
    deactivationReason?: string | undefined
    declineToReviewCount?: number | undefined
    deranked?: GraphQLTypes['Deranked_Reason'] | undefined
    email?: string | undefined
    emailOptOut?: boolean | undefined
    facebookAccessToken?: string | undefined
    facebookAccessTokenExpiry?: string | undefined
    facebookPageId?: string | undefined
    facebookPageIsSubscribed?: boolean | undefined
    facebookPermissionScopes?: Array<string | undefined> | undefined
    facebookUserId?: string | undefined
    firstName?: string | undefined
    followsWesponsored?: string | undefined
    fraudReason?: GraphQLTypes['FraudReason'] | undefined
    fraudReviewAt?: string | undefined
    fraudReviewStatus?: GraphQLTypes['FraudReviewStatus'] | undefined
    gender?: string | undefined
    hasTrueEngagement?: boolean | undefined
    id?: string | undefined
    idfaId?: string | undefined
    incompleteCampaignCount?: number | undefined
    instagramBio?: string | undefined
    instagramBusinessCategoryName?: string | undefined
    instagramCategoryName?: string | undefined
    instagramChecked?: string | undefined
    instagramContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    instagramExternalLink?: string | undefined
    instagramFollowerCountryDistribution?: Array<GraphQLTypes['InstagramCountryDistributionItemInput']> | undefined
    instagramFollowerGenderAgeDistribution?: GraphQLTypes['InstagramGenderAgeDistributionInput'] | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    instagramIsBusiness?: string | undefined
    instagramIsPrivate?: string | undefined
    instagramIsRecentlyJoined?: string | undefined
    instagramIsVerified?: string | undefined
    instagramLastChecked?: string | undefined
    instagramMedianShares?: number | undefined
    instagramMetricsRetrievedAt?: string | undefined
    instagramName?: string | undefined
    instagramPostsInt?: number | undefined
    instagramPriority?: GraphQLTypes['PlatformPriority'] | undefined
    instagramProfilePic?: string | undefined
    instagramTrailingComments?: number | undefined
    instagramTrailingDateOfOldestPost?: string | undefined
    instagramTrailingLikes?: number | undefined
    instagramTrailingNumberOfPosts?: number | undefined
    instagramTrailingPostEngagementAvg?: number | undefined
    instagramTrailingPostEngagementRateAvg?: number | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined
    instagramTrailingPostEngagementRateMedian?: number | undefined
    instagramTrailingPostImpressionsAvg?: number | undefined
    instagramTrailingPostImpressionsMedian?: number | undefined
    instagramTrailingPostSponsoredPercentage?: number | undefined
    instagramTrailingPostViewsMedian?: number | undefined
    instagramUserId?: string | undefined
    instagramVerificationMethod?: GraphQLTypes['SocialVerificationMethod'] | undefined
    internalGender?: GraphQLTypes['UserGender'] | undefined
    isBrandSafe?: GraphQLTypes['BrandSafe'] | undefined
    isBrandSafeReason?: string | undefined
    isBrandSafeUpdatedAt?: string | undefined
    lastActiveAt?: string | undefined
    lastName?: string | undefined
    manualScoringLockedUntil?: string | undefined
    moreinfo?: string | undefined
    numEvenDollarPurchases?: number | undefined
    numPurchases?: number | undefined
    paypalEmail?: string | undefined
    phoneNumber?: string | undefined
    profilePicture?: string | undefined
    pushAddresses?: Array<GraphQLTypes['PushAddressInput']> | undefined
    pushOptOut?: boolean | undefined
    referralCode?: string | undefined
    referralCount?: number | undefined
    referrer?: string | undefined
    signupCountry?: string | undefined
    smsOptOut?: boolean | undefined
    socialQuality?: GraphQLTypes['SocialQuality'] | undefined
    specialAttributes?: GraphQLTypes['UserAttributesInput'] | undefined
    statusTier?: GraphQLTypes['StatusTier'] | undefined
    statusTierHistory?: Array<GraphQLTypes['StatusTierEntryInput']> | undefined
    statusTierLastUpdatedAt?: string | undefined
    supportPriorityAcknowledgedAt?: string | undefined
    supportPriorityFlag?: GraphQLTypes['SupportPriorityFlag'] | undefined
    supportPriorityFlagSetAt?: string | undefined
    targetAccount?: string | undefined
    tiktokAccessToken?: string | undefined
    tiktokAccessTokenExpiry?: string | undefined
    tiktokAccountId?: string | undefined
    tiktokAveragePostEngagementRate?: number | undefined
    tiktokAveragePostEngagementRateViews?: number | undefined
    tiktokBio?: string | undefined
    tiktokContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    tiktokMedianComments?: number | undefined
    tiktokMedianLikes?: number | undefined
    tiktokMedianPostEngagement?: number | undefined
    tiktokMedianPostEngagementRate?: number | undefined
    tiktokMedianPostEngagementRateViews?: number | undefined
    tiktokMedianShares?: number | undefined
    tiktokMedianViews?: number | undefined
    tiktokMetricsRetrievedAt?: string | undefined
    tiktokName?: string | undefined
    tiktokPermissionScopes?: Array<string> | undefined
    tiktokPriority?: GraphQLTypes['PlatformPriority'] | undefined
    tiktokProfilePic?: string | undefined
    tiktokRefreshToken?: string | undefined
    tiktokRefreshTokenExpiry?: string | undefined
    tiktokSponsoredPostPercentage?: number | undefined
    tiktokVerificationMethod?: GraphQLTypes['SocialVerificationMethod'] | undefined
    tiktokVerified?: string | undefined
    timeZone?: string | undefined
    trackingStatus?: GraphQLTypes['TrackingStatus'] | undefined
    twitchAccount?: string | undefined
    type: string
    utmReferral?: GraphQLTypes['UtmReferralInput'] | undefined
    wmartAccount?: string | undefined
    youtubeAccessToken?: string | undefined
    youtubeAccessTokenExpiry?: string | undefined
    youtubeAccount?: string | undefined
    youtubeAveragePostEngagementRate?: number | undefined
    youtubeChannelDescription?: string | undefined
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
    youtubeChannelId?: string | undefined
    youtubeChannelPicture?: string | undefined
    youtubeChannelTitle?: string | undefined
    youtubeIdToken?: string | undefined
    youtubeMedianViews?: number | undefined
    youtubeMetricsRetrievedAt?: string | undefined
    youtubePermissionScopes?: Array<string> | undefined
    youtubeRefreshToken?: string | undefined
    youtubeVerificationMethod?: GraphQLTypes['SocialVerificationMethod'] | undefined
  }
  ['CreateUserLinkInput']: {
    campaignId?: string | undefined
    clicksPerPeriod?: Array<GraphQLTypes['ClicksRecordsInput']> | undefined
    createdAt?: string | undefined
    id?: string | undefined
    linkId?: string | undefined
    originalLink: string
    rank?: number | undefined
    storageType?: GraphQLTypes['LinkStorageType'] | undefined
    uniqueClicks: number
    updatedAt?: string | undefined
    userEncodedLink: string
    userId?: string | undefined
  }
  ['CreateUserNotificationPreferenceInput']: {
    category: string
    emailOptOut?: boolean | undefined
    pushOptOut?: boolean | undefined
    smsOptOut?: boolean | undefined
    userId: string
  }
  ['CreateUserPayoutRequestInput']: {
    failureReason?: string | undefined
    id?: string | undefined
    manuallyApprovedBy?: string | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: GraphQLTypes['WSLocale'] | undefined
    payoutState: GraphQLTypes['BonusPayoutState']
    userId: string
  }
  ['CreateUserStagingApprovalInput']: {
    id?: string | undefined
    reviewFeedback?: string | undefined
    reviewedAt?: string | undefined
    reviewedBy?: string | undefined
    reviewedByEmail?: string | undefined
    reviewedByName?: string | undefined
    reviewedBySource?: GraphQLTypes['UserStagingApprovalReviewSource'] | undefined
    userCampaignId: string
    userStagingApprovalOutcome: GraphQLTypes['UserStagingApprovalOutcome']
    userStagingApprovalType: GraphQLTypes['UserStagingApprovalType']
  }
  ['CreateVideoContentScanInput']: {
    audioDuration?: number | undefined
    audioEndAt?: number | undefined
    audioStartFrom?: number | undefined
    contentSafetyAnalysis?: GraphQLTypes['ContentSafetyAnalysisInput'] | undefined
    id?: string | undefined
    processFinishedAt?: string | undefined
    processId?: string | undefined
    processInitiatedAt?: string | undefined
    scanStatus: GraphQLTypes['ContentScanStatus']
    socialMediaUrl?: string | undefined
    transcription?: string | undefined
    transcriptionConfidence?: number | undefined
    url: string
    userId: string
  }
  ['CreatorContentRatingRawValueInput']: {
    name: string
    value?: string | undefined
  }
  ['DeleteAutoBuyDistributionInput']: {
    id: string
  }
  ['DeleteAutoBuyFlowConfigurationInput']: {
    flowId: string
  }
  ['DeleteAutoBuyFlowReleaseScheduleInput']: {
    date: string
    flowId: string
  }
  ['DeleteAutoBuyReleaseInput']: {
    date: string
    userCampaignId: string
  }
  ['DeleteBrandApiInput']: {
    api: GraphQLTypes['BrandApiType']
    brandId: string
  }
  ['DeleteBrandInput']: {
    id: string
  }
  ['DeleteCampaignClicksInput']: {
    campaignId: string
    date: string
  }
  ['DeleteCampaignInput']: {
    id: string
  }
  ['DeleteCampaignInviteInput']: {
    id: string
  }
  ['DeleteCampaignSourcingProspectInput']: {
    campaignId: string
    userIdentifier: string
  }
  ['DeleteChatConversationV2Input']: {
    chatType: GraphQLTypes['ChatType']
    resourceId: string
  }
  ['DeleteCommunicationNotificationInput']: {
    id: string
  }
  ['DeleteCompanyInput']: {
    id: string
  }
  ['DeleteConnectedAccountInput']: {
    accountId: string
    userId: string
  }
  ['DeleteConversationInput']: {
    userId: string
  }
  ['DeleteConversationMessageInput']: {
    id: string
    userId: string
  }
  ['DeleteEtailerSkuMetricsInput']: {
    date: string
    skuId: string
  }
  ['DeleteFormSequenceConfigurationInput']: {
    id: string
  }
  ['DeleteInstagramStoryScreenInput']: {
    id: string
  }
  ['DeleteMessageInput']: {
    id: string
  }
  ['DeleteNoteInput']: {
    id: string
  }
  ['DeleteNotificationConfigurationInput']: {
    id: string
  }
  ['DeleteProductBonusConfigSnapshotInput']: {
    productId: string
    snapshotAt: string
  }
  ['DeleteProductInput']: {
    id: string
  }
  ['DeleteProductKeywordsInput']: {
    id: string
  }
  ['DeleteProductRatingSnapshotInput']: {
    skuId: string
    snapshotAt: string
  }
  ['DeletePurchaseInput']: {
    id: string
  }
  ['DeleteReferralInput']: {
    id: string
  }
  ['DeleteReviewInput']: {
    id: string
  }
  ['DeleteSkuInput']: {
    id: string
  }
  ['DeleteSkuSnapshotInput']: {
    marketplaceSku: string
    snapshotDate: string
  }
  ['DeleteUserBonusPayoutInput']: {
    id: string
  }
  ['DeleteUserCampaignChangelogInput']: {
    createdAt: string
    userCampaignId: string
  }
  ['DeleteUserCampaignInput']: {
    id: string
  }
  ['DeleteUserCampaignSnapshotInput']: {
    userCampaignId: string
    userCampaignVersion: number
  }
  ['DeleteUserCampaignSocialContentDraftHistoryInput']: {
    id: string
  }
  ['DeleteUserCampaignSocialContentInput']: {
    id: string
  }
  ['DeleteUserCampaignSocialContentReviewInput']: {
    reviewType: GraphQLTypes['UserCampaignSocialContentReviewType']
    userCampaignSocialContentId: string
  }
  ['DeleteUserDeviceSnapshotInput']: {
    id: string
  }
  ['DeleteUserFollowerInput']: {
    followerUserId: string
    userId: string
  }
  ['DeleteUserInput']: {
    id: string
  }
  ['DeleteUserLinkInput']: {
    id: string
  }
  ['DeleteUserNotificationPreferenceInput']: {
    category: string
    userId: string
  }
  ['DeleteUserPayoutRequestInput']: {
    id: string
  }
  ['DeleteUserStagingApprovalInput']: {
    id: string
  }
  ['DeleteVideoContentScanInput']: {
    id: string
  }
  ['FollowersSearchFields']: {
    followerUserId?: GraphQLTypes['SearchInputString'] | undefined
    isFavorite?: GraphQLTypes['SearchInputString'] | undefined
  }
  ['FormSequenceComponentInput']: {
    componentName: string
    componentType: GraphQLTypes['FormComponentType']
    content?: string | undefined
    id: string
    images?: Array<string> | undefined
    numbered?: boolean | undefined
    placeholder?: string | undefined
    type?: GraphQLTypes['FormFieldType'] | undefined
    validation?: Array<GraphQLTypes['FormValidationType']> | undefined
  }
  ['FormSequenceInput']: {
    components?: Array<GraphQLTypes['FormSequenceComponentInput']> | undefined
    id: string
  }
  ['InspirationalContentInput']: {
    socialChannel?: GraphQLTypes['SocialChannel'] | undefined
    type: GraphQLTypes['InspirationalContentType']
    url: string
  }
  ['InstagramCountryDistributionItemInput']: {
    count: number
    countryCode: string
    pctDistribution: number
  }
  ['InstagramFollowerCountryDistributionSearchFields']: {
    countryCode: GraphQLTypes['SearchInputString']
    pctDistribution: GraphQLTypes['SearchInputNumber']
  }
  ['InstagramGenderAgeDistributionInput']: {
    femaleCount: number
    genderAgeDistribution?: Array<GraphQLTypes['InstagramGenderAgeDistributionItemInput']> | undefined
    maleCount: number
    unspecifiedCount: number
  }
  ['InstagramGenderAgeDistributionItemInput']: {
    ageRangeEnd?: number | undefined
    ageRangeStart: number
    count: number
    gender: GraphQLTypes['InstagramGenderAgeDistributionGender']
  }
  ['InviteUsersToCampaignInput']: {
    campaignId: string
    userIds: Array<string>
  }
  ['KeyAttributeInput']: {
    key: string
    value: string
  }
  ['MessagingChannelConfigurationInput']: {
    attributes?: Array<GraphQLTypes['KeyAttributeInput']> | undefined
    enabled: boolean
    templateContent: string
  }
  ['MinMaxRangeInput']: {
    max?: number | undefined
    min?: number | undefined
  }
  ['ModelActiveInput']: {
    eq?: GraphQLTypes['Active'] | undefined
    ne?: GraphQLTypes['Active'] | undefined
  }
  ['ModelAmazonReviewSubmissionStatusInput']: {
    eq?: GraphQLTypes['AmazonReviewSubmissionStatus'] | undefined
    ne?: GraphQLTypes['AmazonReviewSubmissionStatus'] | undefined
  }
  ['ModelApplicationStatusInput']: {
    eq?: GraphQLTypes['ApplicationStatus'] | undefined
    ne?: GraphQLTypes['ApplicationStatus'] | undefined
  }
  ['ModelApprovalOutcomeInput']: {
    eq?: GraphQLTypes['ApprovalOutcome'] | undefined
    ne?: GraphQLTypes['ApprovalOutcome'] | undefined
  }
  ['ModelArchivedReasonInput']: {
    eq?: GraphQLTypes['ArchivedReason'] | undefined
    ne?: GraphQLTypes['ArchivedReason'] | undefined
  }
  ['ModelAutoBuyDelayStrategyInput']: {
    eq?: GraphQLTypes['AutoBuyDelayStrategy'] | undefined
    ne?: GraphQLTypes['AutoBuyDelayStrategy'] | undefined
  }
  ['ModelAutoBuyDistributionConditionInput']: {
    and?: Array<GraphQLTypes['ModelAutoBuyDistributionConditionInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    description?: GraphQLTypes['ModelStringInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelAutoBuyDistributionConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelAutoBuyDistributionConditionInput'] | undefined> | undefined
    schedule?: GraphQLTypes['ModelIntInput'] | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelAutoBuyDistributionFilterInput']: {
    and?: Array<GraphQLTypes['ModelAutoBuyDistributionFilterInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    description?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelAutoBuyDistributionFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelAutoBuyDistributionFilterInput'] | undefined> | undefined
    schedule?: GraphQLTypes['ModelIntInput'] | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelAutoBuyFlowConfigurationConditionInput']: {
    and?: Array<GraphQLTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined> | undefined
    dailyPurchaseMax?: GraphQLTypes['ModelIntInput'] | undefined
    delayStrategy?: GraphQLTypes['ModelAutoBuyDelayStrategyInput'] | undefined
    insufficientCreators?: GraphQLTypes['ModelStringInput'] | undefined
    latestSkipReason?: GraphQLTypes['ModelAutoBuySkipReasonInput'] | undefined
    not?: GraphQLTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelAutoBuyFlowConfigurationConditionInput'] | undefined> | undefined
    pauseReason?: GraphQLTypes['ModelAutoBuySkipReasonInput'] | undefined
    startDate?: GraphQLTypes['ModelStringInput'] | undefined
    status?: GraphQLTypes['ModelAutoBuyStatusInput'] | undefined
  }
  ['ModelAutoBuyFlowConfigurationFilterInput']: {
    and?: Array<GraphQLTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined> | undefined
    dailyPurchaseMax?: GraphQLTypes['ModelIntInput'] | undefined
    delayStrategy?: GraphQLTypes['ModelAutoBuyDelayStrategyInput'] | undefined
    flowId?: GraphQLTypes['ModelIDInput'] | undefined
    insufficientCreators?: GraphQLTypes['ModelStringInput'] | undefined
    latestSkipReason?: GraphQLTypes['ModelAutoBuySkipReasonInput'] | undefined
    not?: GraphQLTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelAutoBuyFlowConfigurationFilterInput'] | undefined> | undefined
    pauseReason?: GraphQLTypes['ModelAutoBuySkipReasonInput'] | undefined
    startDate?: GraphQLTypes['ModelStringInput'] | undefined
    status?: GraphQLTypes['ModelAutoBuyStatusInput'] | undefined
  }
  ['ModelAutoBuyFlowReleaseScheduleConditionInput']: {
    actualPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    actualVerifiedPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    adjustedDesiredPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined> | undefined
    autoApprovalSeats?: GraphQLTypes['ModelIntInput'] | undefined
    desiredPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    manualPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    manualReleases?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelAutoBuyFlowReleaseScheduleConditionInput'] | undefined> | undefined
    projectedReleaseCount?: GraphQLTypes['ModelIntInput'] | undefined
    releaseCount?: GraphQLTypes['ModelIntInput'] | undefined
    released?: GraphQLTypes['ModelBooleanInput'] | undefined
    skippedReason?: GraphQLTypes['ModelAutoBuySkipReasonInput'] | undefined
  }
  ['ModelAutoBuyFlowReleaseScheduleFilterInput']: {
    actualPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    actualVerifiedPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    adjustedDesiredPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined> | undefined
    autoApprovalSeats?: GraphQLTypes['ModelIntInput'] | undefined
    date?: GraphQLTypes['ModelStringInput'] | undefined
    desiredPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    flowId?: GraphQLTypes['ModelIDInput'] | undefined
    manualPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    manualReleases?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelAutoBuyFlowReleaseScheduleFilterInput'] | undefined> | undefined
    projectedReleaseCount?: GraphQLTypes['ModelIntInput'] | undefined
    releaseCount?: GraphQLTypes['ModelIntInput'] | undefined
    released?: GraphQLTypes['ModelBooleanInput'] | undefined
    skippedReason?: GraphQLTypes['ModelAutoBuySkipReasonInput'] | undefined
  }
  ['ModelAutoBuyReleaseConditionInput']: {
    and?: Array<GraphQLTypes['ModelAutoBuyReleaseConditionInput'] | undefined> | undefined
    not?: GraphQLTypes['ModelAutoBuyReleaseConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelAutoBuyReleaseConditionInput'] | undefined> | undefined
    productId?: GraphQLTypes['ModelIDInput'] | undefined
    releaseDateTime?: GraphQLTypes['ModelStringInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelAutoBuyReleaseFilterInput']: {
    and?: Array<GraphQLTypes['ModelAutoBuyReleaseFilterInput'] | undefined> | undefined
    date?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelAutoBuyReleaseFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelAutoBuyReleaseFilterInput'] | undefined> | undefined
    productId?: GraphQLTypes['ModelIDInput'] | undefined
    releaseDateTime?: GraphQLTypes['ModelStringInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelAutoBuySkipReasonInput']: {
    eq?: GraphQLTypes['AutoBuySkipReason'] | undefined
    ne?: GraphQLTypes['AutoBuySkipReason'] | undefined
  }
  ['ModelAutoBuyStatusInput']: {
    eq?: GraphQLTypes['AutoBuyStatus'] | undefined
    ne?: GraphQLTypes['AutoBuyStatus'] | undefined
  }
  ['ModelBOPISValueInput']: {
    eq?: GraphQLTypes['BOPISValue'] | undefined
    ne?: GraphQLTypes['BOPISValue'] | undefined
  }
  ['ModelBonusPayoutStateInput']: {
    eq?: GraphQLTypes['BonusPayoutState'] | undefined
    ne?: GraphQLTypes['BonusPayoutState'] | undefined
  }
  ['ModelBonusPayoutTypesInput']: {
    eq?: GraphQLTypes['BonusPayoutTypes'] | undefined
    ne?: GraphQLTypes['BonusPayoutTypes'] | undefined
  }
  ['ModelBooleanInput']: {
    attributeExists?: boolean | undefined
    attributeType?: GraphQLTypes['ModelAttributeTypes'] | undefined
    eq?: boolean | undefined
    ne?: boolean | undefined
  }
  ['ModelBrandApiConditionInput']: {
    accessToken?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelBrandApiConditionInput'] | undefined> | undefined
    apiVersion?: GraphQLTypes['ModelBrandApiVersionInput'] | undefined
    enabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    locale?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    not?: GraphQLTypes['ModelBrandApiConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelBrandApiConditionInput'] | undefined> | undefined
    refreshToken?: GraphQLTypes['ModelStringInput'] | undefined
    shop?: GraphQLTypes['ModelStringInput'] | undefined
    shopId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelBrandApiFilterInput']: {
    accessToken?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelBrandApiFilterInput'] | undefined> | undefined
    api?: GraphQLTypes['ModelBrandApiTypeInput'] | undefined
    apiVersion?: GraphQLTypes['ModelBrandApiVersionInput'] | undefined
    brandId?: GraphQLTypes['ModelIDInput'] | undefined
    enabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    locale?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    not?: GraphQLTypes['ModelBrandApiFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelBrandApiFilterInput'] | undefined> | undefined
    refreshToken?: GraphQLTypes['ModelStringInput'] | undefined
    shop?: GraphQLTypes['ModelStringInput'] | undefined
    shopId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelBrandApiTypeInput']: {
    eq?: GraphQLTypes['BrandApiType'] | undefined
    ne?: GraphQLTypes['BrandApiType'] | undefined
  }
  ['ModelBrandApiVersionInput']: {
    eq?: GraphQLTypes['BrandApiVersion'] | undefined
    ne?: GraphQLTypes['BrandApiVersion'] | undefined
  }
  ['ModelBrandConditionInput']: {
    active?: GraphQLTypes['ModelStringInput'] | undefined
    amazonBrandName?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelBrandConditionInput'] | undefined> | undefined
    brandDetailsSubmitted?: GraphQLTypes['ModelBooleanInput'] | undefined
    brandInstaHandle?: GraphQLTypes['ModelStringInput'] | undefined
    brandLogoImageKey?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreConfirmationImage?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreConfirmationMobileImage?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreScrapeUrl?: GraphQLTypes['ModelStringInput'] | undefined
    brandTikTokHandle?: GraphQLTypes['ModelStringInput'] | undefined
    companyId?: GraphQLTypes['ModelIDInput'] | undefined
    contactEmails?: GraphQLTypes['ModelStringInput'] | undefined
    defaultCreatorPreference?: GraphQLTypes['ModelCreatorPreferenceInput'] | undefined
    defaultCreatorPreferenceDetails?: GraphQLTypes['ModelStringInput'] | undefined
    defaultHashtags?: GraphQLTypes['ModelStringInput'] | undefined
    defaultMarketingBriefKey?: GraphQLTypes['ModelStringInput'] | undefined
    defaultPostingGuidelines?: GraphQLTypes['ModelStringInput'] | undefined
    defaultPostingInspirationLinks?: GraphQLTypes['ModelStringInput'] | undefined
    emailsSent?: GraphQLTypes['ModelStringInput'] | undefined
    fullScrapedAt?: GraphQLTypes['ModelStringInput'] | undefined
    isBrandStoreScrapeEnabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    isSeller?: GraphQLTypes['ModelStringInput'] | undefined
    isVendor?: GraphQLTypes['ModelStringInput'] | undefined
    isWalmartScrapeEnabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    lastScrapedAt?: GraphQLTypes['ModelStringInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    nameAudioKey?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelBrandConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelBrandConditionInput'] | undefined> | undefined
    platform?: GraphQLTypes['ModelPlatformSourceInput'] | undefined
    postingGuidanceDocument?: GraphQLTypes['ModelStringInput'] | undefined
    region?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    socialMediaExampleUrls?: GraphQLTypes['ModelStringInput'] | undefined
    subscriptionEndsAt?: GraphQLTypes['ModelStringInput'] | undefined
    subscriptionStartsAt?: GraphQLTypes['ModelStringInput'] | undefined
    walmartBrandName?: GraphQLTypes['ModelStringInput'] | undefined
    walmartLastScrapedAt?: GraphQLTypes['ModelStringInput'] | undefined
    walmartScrapeUrl?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelBrandFilterInput']: {
    active?: GraphQLTypes['ModelStringInput'] | undefined
    amazonBrandName?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelBrandFilterInput'] | undefined> | undefined
    brandDetailsSubmitted?: GraphQLTypes['ModelBooleanInput'] | undefined
    brandInstaHandle?: GraphQLTypes['ModelStringInput'] | undefined
    brandLogoImageKey?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreConfirmationImage?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreConfirmationMobileImage?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreScrapeUrl?: GraphQLTypes['ModelStringInput'] | undefined
    brandTikTokHandle?: GraphQLTypes['ModelStringInput'] | undefined
    companyId?: GraphQLTypes['ModelIDInput'] | undefined
    contactEmails?: GraphQLTypes['ModelStringInput'] | undefined
    defaultCreatorPreference?: GraphQLTypes['ModelCreatorPreferenceInput'] | undefined
    defaultCreatorPreferenceDetails?: GraphQLTypes['ModelStringInput'] | undefined
    defaultHashtags?: GraphQLTypes['ModelStringInput'] | undefined
    defaultMarketingBriefKey?: GraphQLTypes['ModelStringInput'] | undefined
    defaultPostingGuidelines?: GraphQLTypes['ModelStringInput'] | undefined
    defaultPostingInspirationLinks?: GraphQLTypes['ModelStringInput'] | undefined
    emailsSent?: GraphQLTypes['ModelStringInput'] | undefined
    fullScrapedAt?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    isBrandStoreScrapeEnabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    isSeller?: GraphQLTypes['ModelStringInput'] | undefined
    isVendor?: GraphQLTypes['ModelStringInput'] | undefined
    isWalmartScrapeEnabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    lastScrapedAt?: GraphQLTypes['ModelStringInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    nameAudioKey?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelBrandFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelBrandFilterInput'] | undefined> | undefined
    platform?: GraphQLTypes['ModelPlatformSourceInput'] | undefined
    postingGuidanceDocument?: GraphQLTypes['ModelStringInput'] | undefined
    region?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    socialMediaExampleUrls?: GraphQLTypes['ModelStringInput'] | undefined
    subscriptionEndsAt?: GraphQLTypes['ModelStringInput'] | undefined
    subscriptionStartsAt?: GraphQLTypes['ModelStringInput'] | undefined
    walmartBrandName?: GraphQLTypes['ModelStringInput'] | undefined
    walmartLastScrapedAt?: GraphQLTypes['ModelStringInput'] | undefined
    walmartScrapeUrl?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelBrandSafeInput']: {
    eq?: GraphQLTypes['BrandSafe'] | undefined
    ne?: GraphQLTypes['BrandSafe'] | undefined
  }
  ['ModelCampaignClicksConditionInput']: {
    and?: Array<GraphQLTypes['ModelCampaignClicksConditionInput'] | undefined> | undefined
    clicks?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelCampaignClicksConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelCampaignClicksConditionInput'] | undefined> | undefined
  }
  ['ModelCampaignClicksFilterInput']: {
    and?: Array<GraphQLTypes['ModelCampaignClicksFilterInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    clicks?: GraphQLTypes['ModelIntInput'] | undefined
    date?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelCampaignClicksFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelCampaignClicksFilterInput'] | undefined> | undefined
  }
  ['ModelCampaignConditionInput']: {
    aboutSection?: GraphQLTypes['ModelStringInput'] | undefined
    active?: GraphQLTypes['ModelStringInput'] | undefined
    actualStartDate?: GraphQLTypes['ModelStringInput'] | undefined
    amountPaidByClient?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelCampaignConditionInput'] | undefined> | undefined
    anticipatedStartDate?: GraphQLTypes['ModelStringInput'] | undefined
    applicationForm?: GraphQLTypes['ModelStringInput'] | undefined
    applicationProductLinkId?: GraphQLTypes['ModelIDInput'] | undefined
    applicationStatus?: GraphQLTypes['ModelApplicationStatusInput'] | undefined
    attributionRefUrlSuffix?: GraphQLTypes['ModelStringInput'] | undefined
    authorizedUnits?: GraphQLTypes['ModelIntInput'] | undefined
    brandId?: GraphQLTypes['ModelIDInput'] | undefined
    brandProductShort?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreLink?: GraphQLTypes['ModelStringInput'] | undefined
    budgetSpent?: GraphQLTypes['ModelIntInput'] | undefined
    campaignProductType?: GraphQLTypes['ModelCampaignProductTypeInput'] | undefined
    campaignReferralBonusAmount?: GraphQLTypes['ModelIntInput'] | undefined
    campaignReferralBonusEnabled?: GraphQLTypes['ModelCampaignReferralBonusFlagInput'] | undefined
    campaignStateMachine?: GraphQLTypes['ModelStringInput'] | undefined
    closeoutReleaseFailureReason?: GraphQLTypes['ModelStringInput'] | undefined
    commissionFlatRate?: GraphQLTypes['ModelIntInput'] | undefined
    commissionPercentageRate?: GraphQLTypes['ModelIntInput'] | undefined
    committedClicks?: GraphQLTypes['ModelIntInput'] | undefined
    committedPlacementWinsCount?: GraphQLTypes['ModelIntInput'] | undefined
    committedReviewsCount?: GraphQLTypes['ModelIntInput'] | undefined
    contentApprovalRequired?: GraphQLTypes['ModelBooleanInput'] | undefined
    contentCompleteDeadline?: GraphQLTypes['ModelStringInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    creationSourceVersion?: GraphQLTypes['ModelCreationSourceVersionInput'] | undefined
    earlyReviewEnabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    enableContentComplianceReview?: GraphQLTypes['ModelBooleanInput'] | undefined
    endDate?: GraphQLTypes['ModelStringInput'] | undefined
    excludeGender?: GraphQLTypes['ModelUserFilterGenderInput'] | undefined
    expectedBonus?: GraphQLTypes['ModelStringInput'] | undefined
    followersCountCeiling?: GraphQLTypes['ModelIntInput'] | undefined
    followersCountFloor?: GraphQLTypes['ModelIntInput'] | undefined
    goal?: GraphQLTypes['ModelCampaignGoalInput'] | undefined
    influencerApprovalEnabled?: GraphQLTypes['ModelInfluencerApprovalFlagV2Input'] | undefined
    lifestyleCommittedContentCount?: GraphQLTypes['ModelIntInput'] | undefined
    localeV1?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    marketingFee?: GraphQLTypes['ModelIntInput'] | undefined
    marketingFormFilled?: GraphQLTypes['ModelBooleanInput'] | undefined
    newCreatorsOnly?: GraphQLTypes['ModelBooleanInput'] | undefined
    not?: GraphQLTypes['ModelCampaignConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelCampaignConditionInput'] | undefined> | undefined
    payForClicksEnabled?: GraphQLTypes['ModelEnabledFlagInput'] | undefined
    payForClicksMinClicks?: GraphQLTypes['ModelIntInput'] | undefined
    payForClicksOriginalLink?: GraphQLTypes['ModelStringInput'] | undefined
    payForClicksTotalClicksGenerated?: GraphQLTypes['ModelIntInput'] | undefined
    payForPerformanceEnabled?: GraphQLTypes['ModelPayForPerformanceFlagInput'] | undefined
    postingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    preApplicationProductImage?: GraphQLTypes['ModelStringInput'] | undefined
    preApplicationProductPriceRange?: GraphQLTypes['ModelStringInput'] | undefined
    preApplicationUgcProductImage?: GraphQLTypes['ModelStringInput'] | undefined
    preLaunchDate?: GraphQLTypes['ModelStringInput'] | undefined
    premiumLifestyleCommittedContentCount?: GraphQLTypes['ModelIntInput'] | undefined
    requiredProductLinksEnabled?: GraphQLTypes['ModelRequiredProductLinkFlagInput'] | undefined
    reviewVotingTarget?: GraphQLTypes['ModelStringInput'] | undefined
    sample?: GraphQLTypes['ModelBooleanInput'] | undefined
    shellCampaignBase?: GraphQLTypes['ModelIDInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    skuPriceOverride?: GraphQLTypes['ModelIntInput'] | undefined
    socialCommittedContentCount?: GraphQLTypes['ModelIntInput'] | undefined
    sourcingPriority?: GraphQLTypes['ModelIntInput'] | undefined
    sponsorshipInstructions?: GraphQLTypes['ModelStringInput'] | undefined
    title?: GraphQLTypes['ModelStringInput'] | undefined
    totalEarlyReviewsAllowed?: GraphQLTypes['ModelIntInput'] | undefined
    totalProductCost?: GraphQLTypes['ModelIntInput'] | undefined
    userAutoApproved?: GraphQLTypes['ModelBooleanInput'] | undefined
    visibleToUser?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignFilterInput']: {
    aboutSection?: GraphQLTypes['ModelStringInput'] | undefined
    active?: GraphQLTypes['ModelStringInput'] | undefined
    actualStartDate?: GraphQLTypes['ModelStringInput'] | undefined
    amountPaidByClient?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelCampaignFilterInput'] | undefined> | undefined
    anticipatedStartDate?: GraphQLTypes['ModelStringInput'] | undefined
    applicationForm?: GraphQLTypes['ModelStringInput'] | undefined
    applicationProductLinkId?: GraphQLTypes['ModelIDInput'] | undefined
    applicationStatus?: GraphQLTypes['ModelApplicationStatusInput'] | undefined
    attributionRefUrlSuffix?: GraphQLTypes['ModelStringInput'] | undefined
    authorizedUnits?: GraphQLTypes['ModelIntInput'] | undefined
    brandId?: GraphQLTypes['ModelIDInput'] | undefined
    brandProductShort?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreLink?: GraphQLTypes['ModelStringInput'] | undefined
    budgetSpent?: GraphQLTypes['ModelIntInput'] | undefined
    campaignProductType?: GraphQLTypes['ModelCampaignProductTypeInput'] | undefined
    campaignReferralBonusAmount?: GraphQLTypes['ModelIntInput'] | undefined
    campaignReferralBonusEnabled?: GraphQLTypes['ModelCampaignReferralBonusFlagInput'] | undefined
    campaignStateMachine?: GraphQLTypes['ModelStringInput'] | undefined
    closeoutReleaseFailureReason?: GraphQLTypes['ModelStringInput'] | undefined
    commissionFlatRate?: GraphQLTypes['ModelIntInput'] | undefined
    commissionPercentageRate?: GraphQLTypes['ModelIntInput'] | undefined
    committedClicks?: GraphQLTypes['ModelIntInput'] | undefined
    committedPlacementWinsCount?: GraphQLTypes['ModelIntInput'] | undefined
    committedReviewsCount?: GraphQLTypes['ModelIntInput'] | undefined
    contentApprovalRequired?: GraphQLTypes['ModelBooleanInput'] | undefined
    contentCompleteDeadline?: GraphQLTypes['ModelStringInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    creationSourceVersion?: GraphQLTypes['ModelCreationSourceVersionInput'] | undefined
    earlyReviewEnabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    enableContentComplianceReview?: GraphQLTypes['ModelBooleanInput'] | undefined
    endDate?: GraphQLTypes['ModelStringInput'] | undefined
    excludeGender?: GraphQLTypes['ModelUserFilterGenderInput'] | undefined
    expectedBonus?: GraphQLTypes['ModelStringInput'] | undefined
    followersCountCeiling?: GraphQLTypes['ModelIntInput'] | undefined
    followersCountFloor?: GraphQLTypes['ModelIntInput'] | undefined
    goal?: GraphQLTypes['ModelCampaignGoalInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    influencerApprovalEnabled?: GraphQLTypes['ModelInfluencerApprovalFlagV2Input'] | undefined
    lifestyleCommittedContentCount?: GraphQLTypes['ModelIntInput'] | undefined
    localeV1?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    marketingFee?: GraphQLTypes['ModelIntInput'] | undefined
    marketingFormFilled?: GraphQLTypes['ModelBooleanInput'] | undefined
    newCreatorsOnly?: GraphQLTypes['ModelBooleanInput'] | undefined
    not?: GraphQLTypes['ModelCampaignFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelCampaignFilterInput'] | undefined> | undefined
    payForClicksEnabled?: GraphQLTypes['ModelEnabledFlagInput'] | undefined
    payForClicksMinClicks?: GraphQLTypes['ModelIntInput'] | undefined
    payForClicksOriginalLink?: GraphQLTypes['ModelStringInput'] | undefined
    payForClicksTotalClicksGenerated?: GraphQLTypes['ModelIntInput'] | undefined
    payForPerformanceEnabled?: GraphQLTypes['ModelPayForPerformanceFlagInput'] | undefined
    postingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    preApplicationProductImage?: GraphQLTypes['ModelStringInput'] | undefined
    preApplicationProductPriceRange?: GraphQLTypes['ModelStringInput'] | undefined
    preApplicationUgcProductImage?: GraphQLTypes['ModelStringInput'] | undefined
    preLaunchDate?: GraphQLTypes['ModelStringInput'] | undefined
    premiumLifestyleCommittedContentCount?: GraphQLTypes['ModelIntInput'] | undefined
    requiredProductLinksEnabled?: GraphQLTypes['ModelRequiredProductLinkFlagInput'] | undefined
    reviewVotingTarget?: GraphQLTypes['ModelStringInput'] | undefined
    sample?: GraphQLTypes['ModelBooleanInput'] | undefined
    shellCampaignBase?: GraphQLTypes['ModelIDInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    skuPriceOverride?: GraphQLTypes['ModelIntInput'] | undefined
    socialCommittedContentCount?: GraphQLTypes['ModelIntInput'] | undefined
    sourcingPriority?: GraphQLTypes['ModelIntInput'] | undefined
    sponsorshipInstructions?: GraphQLTypes['ModelStringInput'] | undefined
    title?: GraphQLTypes['ModelStringInput'] | undefined
    totalEarlyReviewsAllowed?: GraphQLTypes['ModelIntInput'] | undefined
    totalProductCost?: GraphQLTypes['ModelIntInput'] | undefined
    userAutoApproved?: GraphQLTypes['ModelBooleanInput'] | undefined
    visibleToUser?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignGoalInput']: {
    eq?: GraphQLTypes['CampaignGoal'] | undefined
    ne?: GraphQLTypes['CampaignGoal'] | undefined
  }
  ['ModelCampaignInviteConditionInput']: {
    and?: Array<GraphQLTypes['ModelCampaignInviteConditionInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelCampaignInviteConditionInput'] | undefined
    notificationStatus?: GraphQLTypes['ModelNotificationStatusInput'] | undefined
    or?: Array<GraphQLTypes['ModelCampaignInviteConditionInput'] | undefined> | undefined
    priority?: GraphQLTypes['ModelIntInput'] | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignInviteFilterInput']: {
    and?: Array<GraphQLTypes['ModelCampaignInviteFilterInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelCampaignInviteFilterInput'] | undefined
    notificationStatus?: GraphQLTypes['ModelNotificationStatusInput'] | undefined
    or?: Array<GraphQLTypes['ModelCampaignInviteFilterInput'] | undefined> | undefined
    priority?: GraphQLTypes['ModelIntInput'] | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelCampaignProductTypeInput']: {
    eq?: GraphQLTypes['CampaignProductType'] | undefined
    ne?: GraphQLTypes['CampaignProductType'] | undefined
  }
  ['ModelCampaignReferralBonusFlagInput']: {
    eq?: GraphQLTypes['CampaignReferralBonusFlag'] | undefined
    ne?: GraphQLTypes['CampaignReferralBonusFlag'] | undefined
  }
  ['ModelCampaignSourcingProspectConditionInput']: {
    and?: Array<GraphQLTypes['ModelCampaignSourcingProspectConditionInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    createdById?: GraphQLTypes['ModelIDInput'] | undefined
    instagramFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    instagramHandle?: GraphQLTypes['ModelStringInput'] | undefined
    internalNotesId?: GraphQLTypes['ModelIDInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelCampaignSourcingProspectConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelCampaignSourcingProspectConditionInput'] | undefined> | undefined
    platformInviteSource?: GraphQLTypes['ModelPlatformInviteSourceInput'] | undefined
    platformStatus?: GraphQLTypes['ModelPlatformStatusInput'] | undefined
    preApprovalStatus?: GraphQLTypes['ModelPreApprovalStatusInput'] | undefined
    preApprovalStatusById?: GraphQLTypes['ModelIDInput'] | undefined
    priority?: GraphQLTypes['ModelSourcePriorityInput'] | undefined
    productId?: GraphQLTypes['ModelStringInput'] | undefined
    sourcingSource?: GraphQLTypes['ModelCampaignSourcingSourceInput'] | undefined
    tiktokFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokHandle?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    youtubeChannelFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    youtubeChannelHandle?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignSourcingProspectFilterInput']: {
    and?: Array<GraphQLTypes['ModelCampaignSourcingProspectFilterInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    createdById?: GraphQLTypes['ModelIDInput'] | undefined
    instagramFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    instagramHandle?: GraphQLTypes['ModelStringInput'] | undefined
    internalNotesId?: GraphQLTypes['ModelIDInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelCampaignSourcingProspectFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelCampaignSourcingProspectFilterInput'] | undefined> | undefined
    platformInviteSource?: GraphQLTypes['ModelPlatformInviteSourceInput'] | undefined
    platformStatus?: GraphQLTypes['ModelPlatformStatusInput'] | undefined
    preApprovalStatus?: GraphQLTypes['ModelPreApprovalStatusInput'] | undefined
    preApprovalStatusById?: GraphQLTypes['ModelIDInput'] | undefined
    priority?: GraphQLTypes['ModelSourcePriorityInput'] | undefined
    productId?: GraphQLTypes['ModelStringInput'] | undefined
    sourcingSource?: GraphQLTypes['ModelCampaignSourcingSourceInput'] | undefined
    tiktokFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokHandle?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    userIdentifier?: GraphQLTypes['ModelIDInput'] | undefined
    youtubeChannelFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    youtubeChannelHandle?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelCampaignSourcingSourceInput']: {
    eq?: GraphQLTypes['CampaignSourcingSource'] | undefined
    ne?: GraphQLTypes['CampaignSourcingSource'] | undefined
  }
  ['ModelCampaignStatusInput']: {
    eq?: GraphQLTypes['CampaignStatus'] | undefined
    ne?: GraphQLTypes['CampaignStatus'] | undefined
  }
  ['ModelChannelInput']: {
    eq?: GraphQLTypes['Channel'] | undefined
    ne?: GraphQLTypes['Channel'] | undefined
  }
  ['ModelChatConversationV2ConditionInput']: {
    active?: GraphQLTypes['ModelActiveInput'] | undefined
    adminLastReadMessageIndex?: GraphQLTypes['ModelIntInput'] | undefined
    adminUnreadCount?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelChatConversationV2ConditionInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelStringInput'] | undefined
    creatorLastReadMessageIndex?: GraphQLTypes['ModelIntInput'] | undefined
    creatorUnreadCount?: GraphQLTypes['ModelIntInput'] | undefined
    lastMessage?: GraphQLTypes['ModelStringInput'] | undefined
    lastMessageDate?: GraphQLTypes['ModelStringInput'] | undefined
    lastMessageId?: GraphQLTypes['ModelStringInput'] | undefined
    lastMessageSource?: GraphQLTypes['ModelConversationMessageSourceInput'] | undefined
    not?: GraphQLTypes['ModelChatConversationV2ConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelChatConversationV2ConditionInput'] | undefined> | undefined
    status?: GraphQLTypes['ModelConversationInternalStatusInput'] | undefined
    statusUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    statusUpdatedByFirstName?: GraphQLTypes['ModelStringInput'] | undefined
    statusUpdatedById?: GraphQLTypes['ModelStringInput'] | undefined
    statusUpdatedByLastName?: GraphQLTypes['ModelStringInput'] | undefined
    twilioConversationId?: GraphQLTypes['ModelIDInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelStringInput'] | undefined
    userEmail?: GraphQLTypes['ModelStringInput'] | undefined
    userFirstName?: GraphQLTypes['ModelStringInput'] | undefined
    userLastName?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelChatConversationV2FilterInput']: {
    active?: GraphQLTypes['ModelActiveInput'] | undefined
    adminLastReadMessageIndex?: GraphQLTypes['ModelIntInput'] | undefined
    adminUnreadCount?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelChatConversationV2FilterInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelStringInput'] | undefined
    chatType?: GraphQLTypes['ModelChatTypeInput'] | undefined
    creatorLastReadMessageIndex?: GraphQLTypes['ModelIntInput'] | undefined
    creatorUnreadCount?: GraphQLTypes['ModelIntInput'] | undefined
    lastMessage?: GraphQLTypes['ModelStringInput'] | undefined
    lastMessageDate?: GraphQLTypes['ModelStringInput'] | undefined
    lastMessageId?: GraphQLTypes['ModelStringInput'] | undefined
    lastMessageSource?: GraphQLTypes['ModelConversationMessageSourceInput'] | undefined
    not?: GraphQLTypes['ModelChatConversationV2FilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelChatConversationV2FilterInput'] | undefined> | undefined
    resourceId?: GraphQLTypes['ModelIDInput'] | undefined
    status?: GraphQLTypes['ModelConversationInternalStatusInput'] | undefined
    statusUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    statusUpdatedByFirstName?: GraphQLTypes['ModelStringInput'] | undefined
    statusUpdatedById?: GraphQLTypes['ModelStringInput'] | undefined
    statusUpdatedByLastName?: GraphQLTypes['ModelStringInput'] | undefined
    twilioConversationId?: GraphQLTypes['ModelIDInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelStringInput'] | undefined
    userEmail?: GraphQLTypes['ModelStringInput'] | undefined
    userFirstName?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
    userLastName?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelChatTypeInput']: {
    eq?: GraphQLTypes['ChatType'] | undefined
    ne?: GraphQLTypes['ChatType'] | undefined
  }
  ['ModelCoarseCampaignStatusInput']: {
    eq?: GraphQLTypes['CoarseCampaignStatus'] | undefined
    ne?: GraphQLTypes['CoarseCampaignStatus'] | undefined
  }
  ['ModelCommunicationNotificationConditionInput']: {
    address?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelCommunicationNotificationConditionInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    channel?: GraphQLTypes['ModelChannelInput'] | undefined
    expectedSentAt?: GraphQLTypes['ModelStringInput'] | undefined
    experimentTreatment?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelCommunicationNotificationConditionInput'] | undefined
    notificationId?: GraphQLTypes['ModelStringInput'] | undefined
    or?: Array<GraphQLTypes['ModelCommunicationNotificationConditionInput'] | undefined> | undefined
    sentAt?: GraphQLTypes['ModelStringInput'] | undefined
    status?: GraphQLTypes['ModelStringInput'] | undefined
    statusMessage?: GraphQLTypes['ModelStringInput'] | undefined
    templateId?: GraphQLTypes['ModelStringInput'] | undefined
    traceId?: GraphQLTypes['ModelIDInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelCommunicationNotificationFilterInput']: {
    address?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelCommunicationNotificationFilterInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    channel?: GraphQLTypes['ModelChannelInput'] | undefined
    expectedSentAt?: GraphQLTypes['ModelStringInput'] | undefined
    experimentTreatment?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelCommunicationNotificationFilterInput'] | undefined
    notificationId?: GraphQLTypes['ModelStringInput'] | undefined
    or?: Array<GraphQLTypes['ModelCommunicationNotificationFilterInput'] | undefined> | undefined
    sentAt?: GraphQLTypes['ModelStringInput'] | undefined
    status?: GraphQLTypes['ModelStringInput'] | undefined
    statusMessage?: GraphQLTypes['ModelStringInput'] | undefined
    templateId?: GraphQLTypes['ModelStringInput'] | undefined
    traceId?: GraphQLTypes['ModelIDInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelCompanyConditionInput']: {
    agencyId?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelCompanyConditionInput'] | undefined> | undefined
    logo?: GraphQLTypes['ModelStringInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelCompanyConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelCompanyConditionInput'] | undefined> | undefined
    paymentTermsCustomNetDays?: GraphQLTypes['ModelIntInput'] | undefined
    paymentTermsType?: GraphQLTypes['ModelPaymentTermsTypeInput'] | undefined
    subscriptionEndsAt?: GraphQLTypes['ModelStringInput'] | undefined
    subscriptionStartsAt?: GraphQLTypes['ModelStringInput'] | undefined
    websiteUrl?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelCompanyFilterInput']: {
    agencyId?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelCompanyFilterInput'] | undefined> | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    logo?: GraphQLTypes['ModelStringInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelCompanyFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelCompanyFilterInput'] | undefined> | undefined
    paymentTermsCustomNetDays?: GraphQLTypes['ModelIntInput'] | undefined
    paymentTermsType?: GraphQLTypes['ModelPaymentTermsTypeInput'] | undefined
    subscriptionEndsAt?: GraphQLTypes['ModelStringInput'] | undefined
    subscriptionStartsAt?: GraphQLTypes['ModelStringInput'] | undefined
    websiteUrl?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelConnectedAccountConditionInput']: {
    accountType?: GraphQLTypes['ModelConnectedAccountTypeInput'] | undefined
    and?: Array<GraphQLTypes['ModelConnectedAccountConditionInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelConnectedAccountConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelConnectedAccountConditionInput'] | undefined> | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelConnectedAccountFilterInput']: {
    accountId?: GraphQLTypes['ModelIDInput'] | undefined
    accountType?: GraphQLTypes['ModelConnectedAccountTypeInput'] | undefined
    and?: Array<GraphQLTypes['ModelConnectedAccountFilterInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelConnectedAccountFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelConnectedAccountFilterInput'] | undefined> | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelConnectedAccountTypeInput']: {
    eq?: GraphQLTypes['ConnectedAccountType'] | undefined
    ne?: GraphQLTypes['ConnectedAccountType'] | undefined
  }
  ['ModelContentApprovalStatusInput']: {
    eq?: GraphQLTypes['ContentApprovalStatus'] | undefined
    ne?: GraphQLTypes['ContentApprovalStatus'] | undefined
  }
  ['ModelContentComplianceReviewStatusInput']: {
    eq?: GraphQLTypes['ContentComplianceReviewStatus'] | undefined
    ne?: GraphQLTypes['ContentComplianceReviewStatus'] | undefined
  }
  ['ModelContentRatingReviewStatusInput']: {
    eq?: GraphQLTypes['ContentRatingReviewStatus'] | undefined
    ne?: GraphQLTypes['ContentRatingReviewStatus'] | undefined
  }
  ['ModelContentRequirementLevelInput']: {
    eq?: GraphQLTypes['ContentRequirementLevel'] | undefined
    ne?: GraphQLTypes['ContentRequirementLevel'] | undefined
  }
  ['ModelContentScanStatusInput']: {
    eq?: GraphQLTypes['ContentScanStatus'] | undefined
    ne?: GraphQLTypes['ContentScanStatus'] | undefined
  }
  ['ModelContentTypeRatingListInput']: {
    contains?: GraphQLTypes['ContentTypeRating'] | undefined
    eq?: Array<GraphQLTypes['ContentTypeRating'] | undefined> | undefined
    ne?: Array<GraphQLTypes['ContentTypeRating'] | undefined> | undefined
    notContains?: GraphQLTypes['ContentTypeRating'] | undefined
  }
  ['ModelConversationChannelInput']: {
    eq?: GraphQLTypes['ConversationChannel'] | undefined
    ne?: GraphQLTypes['ConversationChannel'] | undefined
  }
  ['ModelConversationConditionInput']: {
    and?: Array<GraphQLTypes['ModelConversationConditionInput'] | undefined> | undefined
    contactOwnerId?: GraphQLTypes['ModelIDInput'] | undefined
    lastContactAt?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelConversationConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelConversationConditionInput'] | undefined> | undefined
    status?: GraphQLTypes['ModelConversationStatusInput'] | undefined
    statusChangedAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelConversationFilterInput']: {
    and?: Array<GraphQLTypes['ModelConversationFilterInput'] | undefined> | undefined
    contactOwnerId?: GraphQLTypes['ModelIDInput'] | undefined
    lastContactAt?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelConversationFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelConversationFilterInput'] | undefined> | undefined
    status?: GraphQLTypes['ModelConversationStatusInput'] | undefined
    statusChangedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelConversationInternalStatusInput']: {
    eq?: GraphQLTypes['ConversationInternalStatus'] | undefined
    ne?: GraphQLTypes['ConversationInternalStatus'] | undefined
  }
  ['ModelConversationMessageConditionInput']: {
    and?: Array<GraphQLTypes['ModelConversationMessageConditionInput'] | undefined> | undefined
    channel?: GraphQLTypes['ModelConversationChannelInput'] | undefined
    messageContent?: GraphQLTypes['ModelStringInput'] | undefined
    messageId?: GraphQLTypes['ModelStringInput'] | undefined
    messageMediaKeys?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelConversationMessageConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelConversationMessageConditionInput'] | undefined> | undefined
    replyFromId?: GraphQLTypes['ModelIDInput'] | undefined
    sendAt?: GraphQLTypes['ModelStringInput'] | undefined
    sendAttempts?: GraphQLTypes['ModelIntInput'] | undefined
    sentAt?: GraphQLTypes['ModelStringInput'] | undefined
    status?: GraphQLTypes['ModelConversationMessageStatusInput'] | undefined
  }
  ['ModelConversationMessageFilterInput']: {
    and?: Array<GraphQLTypes['ModelConversationMessageFilterInput'] | undefined> | undefined
    channel?: GraphQLTypes['ModelConversationChannelInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    messageContent?: GraphQLTypes['ModelStringInput'] | undefined
    messageId?: GraphQLTypes['ModelStringInput'] | undefined
    messageMediaKeys?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelConversationMessageFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelConversationMessageFilterInput'] | undefined> | undefined
    replyFromId?: GraphQLTypes['ModelIDInput'] | undefined
    sendAt?: GraphQLTypes['ModelStringInput'] | undefined
    sendAttempts?: GraphQLTypes['ModelIntInput'] | undefined
    sentAt?: GraphQLTypes['ModelStringInput'] | undefined
    status?: GraphQLTypes['ModelConversationMessageStatusInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelConversationMessageSourceInput']: {
    eq?: GraphQLTypes['ConversationMessageSource'] | undefined
    ne?: GraphQLTypes['ConversationMessageSource'] | undefined
  }
  ['ModelConversationMessageStatusInput']: {
    eq?: GraphQLTypes['ConversationMessageStatus'] | undefined
    ne?: GraphQLTypes['ConversationMessageStatus'] | undefined
  }
  ['ModelConversationStatusInput']: {
    eq?: GraphQLTypes['ConversationStatus'] | undefined
    ne?: GraphQLTypes['ConversationStatus'] | undefined
  }
  ['ModelCreationSourceVersionInput']: {
    eq?: GraphQLTypes['CreationSourceVersion'] | undefined
    ne?: GraphQLTypes['CreationSourceVersion'] | undefined
  }
  ['ModelCreatorPreferenceInput']: {
    eq?: GraphQLTypes['CreatorPreference'] | undefined
    ne?: GraphQLTypes['CreatorPreference'] | undefined
  }
  ['ModelCurrencyInput']: {
    eq?: GraphQLTypes['Currency'] | undefined
    ne?: GraphQLTypes['Currency'] | undefined
  }
  ['ModelDeranked_ReasonInput']: {
    eq?: GraphQLTypes['Deranked_Reason'] | undefined
    ne?: GraphQLTypes['Deranked_Reason'] | undefined
  }
  ['ModelDraftStatusInput']: {
    eq?: GraphQLTypes['DraftStatus'] | undefined
    ne?: GraphQLTypes['DraftStatus'] | undefined
  }
  ['ModelEnabledFlagInput']: {
    eq?: GraphQLTypes['EnabledFlag'] | undefined
    ne?: GraphQLTypes['EnabledFlag'] | undefined
  }
  ['ModelEtailerSkuMetricsConditionInput']: {
    and?: Array<GraphQLTypes['ModelEtailerSkuMetricsConditionInput'] | undefined> | undefined
    not?: GraphQLTypes['ModelEtailerSkuMetricsConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelEtailerSkuMetricsConditionInput'] | undefined> | undefined
    pageViews?: GraphQLTypes['ModelIntInput'] | undefined
    sku?: GraphQLTypes['ModelStringInput'] | undefined
    totalSalesAmount?: GraphQLTypes['ModelIntInput'] | undefined
    unitsSold?: GraphQLTypes['ModelIntInput'] | undefined
  }
  ['ModelEtailerSkuMetricsFilterInput']: {
    and?: Array<GraphQLTypes['ModelEtailerSkuMetricsFilterInput'] | undefined> | undefined
    date?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelEtailerSkuMetricsFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelEtailerSkuMetricsFilterInput'] | undefined> | undefined
    pageViews?: GraphQLTypes['ModelIntInput'] | undefined
    sku?: GraphQLTypes['ModelStringInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    totalSalesAmount?: GraphQLTypes['ModelIntInput'] | undefined
    unitsSold?: GraphQLTypes['ModelIntInput'] | undefined
  }
  ['ModelFavoriteFlagInput']: {
    eq?: GraphQLTypes['FavoriteFlag'] | undefined
    ne?: GraphQLTypes['FavoriteFlag'] | undefined
  }
  ['ModelFloatInput']: {
    attributeExists?: boolean | undefined
    attributeType?: GraphQLTypes['ModelAttributeTypes'] | undefined
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
    ne?: number | undefined
  }
  ['ModelFloatKeyConditionInput']: {
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
  }
  ['ModelFormSequenceConfigurationConditionInput']: {
    active?: GraphQLTypes['ModelActiveInput'] | undefined
    and?: Array<GraphQLTypes['ModelFormSequenceConfigurationConditionInput'] | undefined> | undefined
    not?: GraphQLTypes['ModelFormSequenceConfigurationConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelFormSequenceConfigurationConditionInput'] | undefined> | undefined
    sequenceName?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelFormSequenceConfigurationFilterInput']: {
    active?: GraphQLTypes['ModelActiveInput'] | undefined
    and?: Array<GraphQLTypes['ModelFormSequenceConfigurationFilterInput'] | undefined> | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelFormSequenceConfigurationFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelFormSequenceConfigurationFilterInput'] | undefined> | undefined
    sequenceName?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelFraudReasonInput']: {
    eq?: GraphQLTypes['FraudReason'] | undefined
    ne?: GraphQLTypes['FraudReason'] | undefined
  }
  ['ModelFraudReviewStatusInput']: {
    eq?: GraphQLTypes['FraudReviewStatus'] | undefined
    ne?: GraphQLTypes['FraudReviewStatus'] | undefined
  }
  ['ModelIDInput']: {
    attributeExists?: boolean | undefined
    attributeType?: GraphQLTypes['ModelAttributeTypes'] | undefined
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    contains?: string | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
    ne?: string | undefined
    notContains?: string | undefined
    size?: GraphQLTypes['ModelSizeInput'] | undefined
  }
  ['ModelIDKeyConditionInput']: {
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
  }
  ['ModelInfluencerApprovalFlagV2Input']: {
    eq?: GraphQLTypes['InfluencerApprovalFlagV2'] | undefined
    ne?: GraphQLTypes['InfluencerApprovalFlagV2'] | undefined
  }
  ['ModelInstagramStoryScreenConditionInput']: {
    and?: Array<GraphQLTypes['ModelInstagramStoryScreenConditionInput'] | undefined> | undefined
    exits?: GraphQLTypes['ModelIntInput'] | undefined
    expirationUnixTime?: GraphQLTypes['ModelIntInput'] | undefined
    externalId?: GraphQLTypes['ModelStringInput'] | undefined
    impressions?: GraphQLTypes['ModelIntInput'] | undefined
    instagramUserId?: GraphQLTypes['ModelIDInput'] | undefined
    mediaId?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelInstagramStoryScreenConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelInstagramStoryScreenConditionInput'] | undefined> | undefined
    postedAt?: GraphQLTypes['ModelStringInput'] | undefined
    postingDate?: GraphQLTypes['ModelStringInput'] | undefined
    reach?: GraphQLTypes['ModelIntInput'] | undefined
    replies?: GraphQLTypes['ModelIntInput'] | undefined
    s3Bucket?: GraphQLTypes['ModelStringInput'] | undefined
    tapsBack?: GraphQLTypes['ModelIntInput'] | undefined
    tapsForward?: GraphQLTypes['ModelIntInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelInstagramStoryScreenFilterInput']: {
    and?: Array<GraphQLTypes['ModelInstagramStoryScreenFilterInput'] | undefined> | undefined
    exits?: GraphQLTypes['ModelIntInput'] | undefined
    expirationUnixTime?: GraphQLTypes['ModelIntInput'] | undefined
    externalId?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    impressions?: GraphQLTypes['ModelIntInput'] | undefined
    instagramUserId?: GraphQLTypes['ModelIDInput'] | undefined
    mediaId?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelInstagramStoryScreenFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelInstagramStoryScreenFilterInput'] | undefined> | undefined
    postedAt?: GraphQLTypes['ModelStringInput'] | undefined
    postingDate?: GraphQLTypes['ModelStringInput'] | undefined
    reach?: GraphQLTypes['ModelIntInput'] | undefined
    replies?: GraphQLTypes['ModelIntInput'] | undefined
    s3Bucket?: GraphQLTypes['ModelStringInput'] | undefined
    tapsBack?: GraphQLTypes['ModelIntInput'] | undefined
    tapsForward?: GraphQLTypes['ModelIntInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelIntInput']: {
    attributeExists?: boolean | undefined
    attributeType?: GraphQLTypes['ModelAttributeTypes'] | undefined
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
    ne?: number | undefined
  }
  ['ModelIntKeyConditionInput']: {
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
  }
  ['ModelItemClassificationInput']: {
    eq?: GraphQLTypes['ItemClassification'] | undefined
    ne?: GraphQLTypes['ItemClassification'] | undefined
  }
  ['ModelKeywordAcceptanceResponseInput']: {
    eq?: GraphQLTypes['KeywordAcceptanceResponse'] | undefined
    ne?: GraphQLTypes['KeywordAcceptanceResponse'] | undefined
  }
  ['ModelLinkStorageTypeInput']: {
    eq?: GraphQLTypes['LinkStorageType'] | undefined
    ne?: GraphQLTypes['LinkStorageType'] | undefined
  }
  ['ModelListingFulfillmentTypeInput']: {
    eq?: GraphQLTypes['ListingFulfillmentType'] | undefined
    ne?: GraphQLTypes['ListingFulfillmentType'] | undefined
  }
  ['ModelManualUploadTypeInput']: {
    eq?: GraphQLTypes['ManualUploadType'] | undefined
    ne?: GraphQLTypes['ManualUploadType'] | undefined
  }
  ['ModelMessageConditionInput']: {
    and?: Array<GraphQLTypes['ModelMessageConditionInput'] | undefined> | undefined
    body?: GraphQLTypes['ModelStringInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelMessageConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelMessageConditionInput'] | undefined> | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    userHasSeen?: GraphQLTypes['ModelBooleanInput'] | undefined
  }
  ['ModelMessageFilterInput']: {
    and?: Array<GraphQLTypes['ModelMessageFilterInput'] | undefined> | undefined
    body?: GraphQLTypes['ModelStringInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelMessageFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelMessageFilterInput'] | undefined> | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    userHasSeen?: GraphQLTypes['ModelBooleanInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelNoteConditionInput']: {
    and?: Array<GraphQLTypes['ModelNoteConditionInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelNoteConditionInput'] | undefined
    note?: GraphQLTypes['ModelStringInput'] | undefined
    or?: Array<GraphQLTypes['ModelNoteConditionInput'] | undefined> | undefined
    parentId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelNoteFilterInput']: {
    and?: Array<GraphQLTypes['ModelNoteFilterInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelNoteFilterInput'] | undefined
    note?: GraphQLTypes['ModelStringInput'] | undefined
    or?: Array<GraphQLTypes['ModelNoteFilterInput'] | undefined> | undefined
    parentId?: GraphQLTypes['ModelIDInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelNotificationConfigurationConditionInput']: {
    and?: Array<GraphQLTypes['ModelNotificationConfigurationConditionInput'] | undefined> | undefined
    category?: GraphQLTypes['ModelStringInput'] | undefined
    description?: GraphQLTypes['ModelStringInput'] | undefined
    enabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelNotificationConfigurationConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelNotificationConfigurationConditionInput'] | undefined> | undefined
  }
  ['ModelNotificationConfigurationFilterInput']: {
    and?: Array<GraphQLTypes['ModelNotificationConfigurationFilterInput'] | undefined> | undefined
    category?: GraphQLTypes['ModelStringInput'] | undefined
    description?: GraphQLTypes['ModelStringInput'] | undefined
    enabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelNotificationConfigurationFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelNotificationConfigurationFilterInput'] | undefined> | undefined
  }
  ['ModelNotificationStatusInput']: {
    eq?: GraphQLTypes['NotificationStatus'] | undefined
    ne?: GraphQLTypes['NotificationStatus'] | undefined
  }
  ['ModelOrderIdValidationStatusInput']: {
    eq?: GraphQLTypes['OrderIdValidationStatus'] | undefined
    ne?: GraphQLTypes['OrderIdValidationStatus'] | undefined
  }
  ['ModelPayForPerformanceFlagInput']: {
    eq?: GraphQLTypes['PayForPerformanceFlag'] | undefined
    ne?: GraphQLTypes['PayForPerformanceFlag'] | undefined
  }
  ['ModelPaymentTermsTypeInput']: {
    eq?: GraphQLTypes['PaymentTermsType'] | undefined
    ne?: GraphQLTypes['PaymentTermsType'] | undefined
  }
  ['ModelPlatformInviteSourceInput']: {
    eq?: GraphQLTypes['PlatformInviteSource'] | undefined
    ne?: GraphQLTypes['PlatformInviteSource'] | undefined
  }
  ['ModelPlatformPriorityInput']: {
    eq?: GraphQLTypes['PlatformPriority'] | undefined
    ne?: GraphQLTypes['PlatformPriority'] | undefined
  }
  ['ModelPlatformSourceInput']: {
    eq?: GraphQLTypes['PlatformSource'] | undefined
    ne?: GraphQLTypes['PlatformSource'] | undefined
  }
  ['ModelPlatformStatusInput']: {
    eq?: GraphQLTypes['PlatformStatus'] | undefined
    ne?: GraphQLTypes['PlatformStatus'] | undefined
  }
  ['ModelPreApprovalStatusInput']: {
    eq?: GraphQLTypes['PreApprovalStatus'] | undefined
    ne?: GraphQLTypes['PreApprovalStatus'] | undefined
  }
  ['ModelProductBonusConfigSnapshotConditionInput']: {
    and?: Array<GraphQLTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined> | undefined
    closeoutSurveyBonus?: GraphQLTypes['ModelIntInput'] | undefined
    discoveryBonus?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelProductBonusConfigSnapshotConditionInput'] | undefined> | undefined
  }
  ['ModelProductBonusConfigSnapshotFilterInput']: {
    and?: Array<GraphQLTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined> | undefined
    closeoutSurveyBonus?: GraphQLTypes['ModelIntInput'] | undefined
    discoveryBonus?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelProductBonusConfigSnapshotFilterInput'] | undefined> | undefined
    productId?: GraphQLTypes['ModelIDInput'] | undefined
    snapshotAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelProductConditionInput']: {
    accountRequired?: GraphQLTypes['ModelBooleanInput'] | undefined
    active?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelProductConditionInput'] | undefined> | undefined
    autoApprovalSeatsAvailable?: GraphQLTypes['ModelIntInput'] | undefined
    autoApprovalStatusTiers?: GraphQLTypes['ModelStatusTierListInput'] | undefined
    automaticReviewRelease?: GraphQLTypes['ModelBooleanInput'] | undefined
    baseBonus?: GraphQLTypes['ModelIntInput'] | undefined
    brandAdvocateCount?: GraphQLTypes['ModelIntInput'] | undefined
    brandTalkingPoints?: GraphQLTypes['ModelStringInput'] | undefined
    buyOnlinePickupInStore?: GraphQLTypes['ModelBOPISValueInput'] | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    campaignStateMachine?: GraphQLTypes['ModelStringInput'] | undefined
    closeoutFormBonus?: GraphQLTypes['ModelIntInput'] | undefined
    contentApprovalLeadDays?: GraphQLTypes['ModelIntInput'] | undefined
    creatorCount?: GraphQLTypes['ModelIntInput'] | undefined
    creatorPreference?: GraphQLTypes['ModelCreatorPreferenceInput'] | undefined
    creatorPreferenceDetails?: GraphQLTypes['ModelStringInput'] | undefined
    customFtcHashtags?: GraphQLTypes['ModelStringInput'] | undefined
    customHashtags?: GraphQLTypes['ModelStringInput'] | undefined
    directPurchaseLink?: GraphQLTypes['ModelStringInput'] | undefined
    hideBrandContentApprovals?: GraphQLTypes['ModelBooleanInput'] | undefined
    instaHandle?: GraphQLTypes['ModelStringInput'] | undefined
    internalCreatorsStagingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    internalDescription?: GraphQLTypes['ModelStringInput'] | undefined
    internalName?: GraphQLTypes['ModelStringInput'] | undefined
    internalNotes?: GraphQLTypes['ModelStringInput'] | undefined
    isDirectListingPurchase?: GraphQLTypes['ModelBooleanInput'] | undefined
    isQualityRequirementsEnabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    listingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    maxDiscoveryBonus?: GraphQLTypes['ModelIntInput'] | undefined
    minimumCreatorQualityScore?: GraphQLTypes['ModelIntInput'] | undefined
    nonRetailReviewLink?: GraphQLTypes['ModelStringInput'] | undefined
    nonRetailReviewWebsiteName?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelProductConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelProductConditionInput'] | undefined> | undefined
    payForClicksEnabled?: GraphQLTypes['ModelEnabledFlagInput'] | undefined
    payForClicksMinClicks?: GraphQLTypes['ModelIntInput'] | undefined
    postingDeadlineDaysRelativeToApproval?: GraphQLTypes['ModelIntInput'] | undefined
    productLinkId?: GraphQLTypes['ModelIDInput'] | undefined
    productPostingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    productPostingGuidanceDocument?: GraphQLTypes['ModelStringInput'] | undefined
    promoCode?: GraphQLTypes['ModelStringInput'] | undefined
    promoCodeGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    promoCodeType?: GraphQLTypes['ModelPromoCodeTypeInput'] | undefined
    promoDiscountAmount?: GraphQLTypes['ModelFloatInput'] | undefined
    promoDiscountType?: GraphQLTypes['ModelPromoDiscountTypeInput'] | undefined
    promoEndDate?: GraphQLTypes['ModelStringInput'] | undefined
    promoLink?: GraphQLTypes['ModelStringInput'] | undefined
    promoStartDate?: GraphQLTypes['ModelStringInput'] | undefined
    promotionLinkId?: GraphQLTypes['ModelIDInput'] | undefined
    purchaseByPromoCode?: GraphQLTypes['ModelBooleanInput'] | undefined
    purchaseDiscountCode?: GraphQLTypes['ModelStringInput'] | undefined
    purchaseDiscountCodeDetails?: GraphQLTypes['ModelStringInput'] | undefined
    purchaseDiscountCodePercentage?: GraphQLTypes['ModelStringInput'] | undefined
    qualityLevel?: GraphQLTypes['ModelProductQualityLevelInput'] | undefined
    requestEarlyProductFeedback?: GraphQLTypes['ModelBooleanInput'] | undefined
    requestProductFeedback?: GraphQLTypes['ModelBooleanInput'] | undefined
    requiredProductLinkEnabled?: GraphQLTypes['ModelRequiredProductLinkFlagInput'] | undefined
    sequenceId?: GraphQLTypes['ModelIDInput'] | undefined
    shopifyPriceRule?: GraphQLTypes['ModelStringInput'] | undefined
    shopifyPromotionId?: GraphQLTypes['ModelStringInput'] | undefined
    shopifyPromotionType?: GraphQLTypes['ModelShopifyPromotionTypeInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    socialMediaExampleUrls?: GraphQLTypes['ModelStringInput'] | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: GraphQLTypes['ModelIntInput'] | undefined
    stagingRequirementType?: GraphQLTypes['ModelStagingRequirementTypeInput'] | undefined
    stagingSocialQualityRequirement?: GraphQLTypes['ModelSocialQualityInput'] | undefined
    tikTokHandle?: GraphQLTypes['ModelStringInput'] | undefined
    unitsPurchased?: GraphQLTypes['ModelIntInput'] | undefined
    validateProductFoundVariations?: GraphQLTypes['ModelStringInput'] | undefined
    visibleAt?: GraphQLTypes['ModelStringInput'] | undefined
    visibleEndsAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelProductFilterInput']: {
    accountRequired?: GraphQLTypes['ModelBooleanInput'] | undefined
    active?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelProductFilterInput'] | undefined> | undefined
    autoApprovalSeatsAvailable?: GraphQLTypes['ModelIntInput'] | undefined
    autoApprovalStatusTiers?: GraphQLTypes['ModelStatusTierListInput'] | undefined
    automaticReviewRelease?: GraphQLTypes['ModelBooleanInput'] | undefined
    baseBonus?: GraphQLTypes['ModelIntInput'] | undefined
    brandAdvocateCount?: GraphQLTypes['ModelIntInput'] | undefined
    brandTalkingPoints?: GraphQLTypes['ModelStringInput'] | undefined
    buyOnlinePickupInStore?: GraphQLTypes['ModelBOPISValueInput'] | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    campaignStateMachine?: GraphQLTypes['ModelStringInput'] | undefined
    closeoutFormBonus?: GraphQLTypes['ModelIntInput'] | undefined
    contentApprovalLeadDays?: GraphQLTypes['ModelIntInput'] | undefined
    creatorCount?: GraphQLTypes['ModelIntInput'] | undefined
    creatorPreference?: GraphQLTypes['ModelCreatorPreferenceInput'] | undefined
    creatorPreferenceDetails?: GraphQLTypes['ModelStringInput'] | undefined
    customFtcHashtags?: GraphQLTypes['ModelStringInput'] | undefined
    customHashtags?: GraphQLTypes['ModelStringInput'] | undefined
    directPurchaseLink?: GraphQLTypes['ModelStringInput'] | undefined
    hideBrandContentApprovals?: GraphQLTypes['ModelBooleanInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    instaHandle?: GraphQLTypes['ModelStringInput'] | undefined
    internalCreatorsStagingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    internalDescription?: GraphQLTypes['ModelStringInput'] | undefined
    internalName?: GraphQLTypes['ModelStringInput'] | undefined
    internalNotes?: GraphQLTypes['ModelStringInput'] | undefined
    isDirectListingPurchase?: GraphQLTypes['ModelBooleanInput'] | undefined
    isQualityRequirementsEnabled?: GraphQLTypes['ModelBooleanInput'] | undefined
    listingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    maxDiscoveryBonus?: GraphQLTypes['ModelIntInput'] | undefined
    minimumCreatorQualityScore?: GraphQLTypes['ModelIntInput'] | undefined
    nonRetailReviewLink?: GraphQLTypes['ModelStringInput'] | undefined
    nonRetailReviewWebsiteName?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelProductFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelProductFilterInput'] | undefined> | undefined
    payForClicksEnabled?: GraphQLTypes['ModelEnabledFlagInput'] | undefined
    payForClicksMinClicks?: GraphQLTypes['ModelIntInput'] | undefined
    postingDeadlineDaysRelativeToApproval?: GraphQLTypes['ModelIntInput'] | undefined
    productLinkId?: GraphQLTypes['ModelIDInput'] | undefined
    productPostingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    productPostingGuidanceDocument?: GraphQLTypes['ModelStringInput'] | undefined
    promoCode?: GraphQLTypes['ModelStringInput'] | undefined
    promoCodeGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    promoCodeType?: GraphQLTypes['ModelPromoCodeTypeInput'] | undefined
    promoDiscountAmount?: GraphQLTypes['ModelFloatInput'] | undefined
    promoDiscountType?: GraphQLTypes['ModelPromoDiscountTypeInput'] | undefined
    promoEndDate?: GraphQLTypes['ModelStringInput'] | undefined
    promoLink?: GraphQLTypes['ModelStringInput'] | undefined
    promoStartDate?: GraphQLTypes['ModelStringInput'] | undefined
    promotionLinkId?: GraphQLTypes['ModelIDInput'] | undefined
    purchaseByPromoCode?: GraphQLTypes['ModelBooleanInput'] | undefined
    purchaseDiscountCode?: GraphQLTypes['ModelStringInput'] | undefined
    purchaseDiscountCodeDetails?: GraphQLTypes['ModelStringInput'] | undefined
    purchaseDiscountCodePercentage?: GraphQLTypes['ModelStringInput'] | undefined
    qualityLevel?: GraphQLTypes['ModelProductQualityLevelInput'] | undefined
    requestEarlyProductFeedback?: GraphQLTypes['ModelBooleanInput'] | undefined
    requestProductFeedback?: GraphQLTypes['ModelBooleanInput'] | undefined
    requiredProductLinkEnabled?: GraphQLTypes['ModelRequiredProductLinkFlagInput'] | undefined
    sequenceId?: GraphQLTypes['ModelIDInput'] | undefined
    shopifyPriceRule?: GraphQLTypes['ModelStringInput'] | undefined
    shopifyPromotionId?: GraphQLTypes['ModelStringInput'] | undefined
    shopifyPromotionType?: GraphQLTypes['ModelShopifyPromotionTypeInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    socialMediaExampleUrls?: GraphQLTypes['ModelStringInput'] | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: GraphQLTypes['ModelIntInput'] | undefined
    stagingRequirementType?: GraphQLTypes['ModelStagingRequirementTypeInput'] | undefined
    stagingSocialQualityRequirement?: GraphQLTypes['ModelSocialQualityInput'] | undefined
    tikTokHandle?: GraphQLTypes['ModelStringInput'] | undefined
    unitsPurchased?: GraphQLTypes['ModelIntInput'] | undefined
    validateProductFoundVariations?: GraphQLTypes['ModelStringInput'] | undefined
    visibleAt?: GraphQLTypes['ModelStringInput'] | undefined
    visibleEndsAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelProductKeywordCreationSourceInput']: {
    eq?: GraphQLTypes['ProductKeywordCreationSource'] | undefined
    ne?: GraphQLTypes['ProductKeywordCreationSource'] | undefined
  }
  ['ModelProductKeywordsConditionInput']: {
    adminPriority?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelProductKeywordsConditionInput'] | undefined> | undefined
    brandPriority?: GraphQLTypes['ModelIntInput'] | undefined
    brandResponse?: GraphQLTypes['ModelKeywordAcceptanceResponseInput'] | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    creationSource?: GraphQLTypes['ModelProductKeywordCreationSourceInput'] | undefined
    endDate?: GraphQLTypes['ModelStringInput'] | undefined
    heliumAccount?: GraphQLTypes['ModelStringInput'] | undefined
    heliumAsin?: GraphQLTypes['ModelStringInput'] | undefined
    heliumLink?: GraphQLTypes['ModelStringInput'] | undefined
    keyword?: GraphQLTypes['ModelStringInput'] | undefined
    monthlySearchVolume?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelProductKeywordsConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelProductKeywordsConditionInput'] | undefined> | undefined
    rank?: GraphQLTypes['ModelIntInput'] | undefined
    rankingLastSkippedAt?: GraphQLTypes['ModelStringInput'] | undefined
    rankingLastSkippedByUserId?: GraphQLTypes['ModelIDInput'] | undefined
    rankingLastUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    rankingLastUpdatedByUserId?: GraphQLTypes['ModelIDInput'] | undefined
    recommended?: GraphQLTypes['ModelBooleanInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    startDate?: GraphQLTypes['ModelStringInput'] | undefined
    usedForCampaign?: GraphQLTypes['ModelBooleanInput'] | undefined
    visible?: GraphQLTypes['ModelBooleanInput'] | undefined
    weight?: GraphQLTypes['ModelIntInput'] | undefined
  }
  ['ModelProductKeywordsFilterInput']: {
    adminPriority?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelProductKeywordsFilterInput'] | undefined> | undefined
    brandPriority?: GraphQLTypes['ModelIntInput'] | undefined
    brandResponse?: GraphQLTypes['ModelKeywordAcceptanceResponseInput'] | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    creationSource?: GraphQLTypes['ModelProductKeywordCreationSourceInput'] | undefined
    endDate?: GraphQLTypes['ModelStringInput'] | undefined
    heliumAccount?: GraphQLTypes['ModelStringInput'] | undefined
    heliumAsin?: GraphQLTypes['ModelStringInput'] | undefined
    heliumLink?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    keyword?: GraphQLTypes['ModelStringInput'] | undefined
    monthlySearchVolume?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelProductKeywordsFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelProductKeywordsFilterInput'] | undefined> | undefined
    rank?: GraphQLTypes['ModelIntInput'] | undefined
    rankingLastSkippedAt?: GraphQLTypes['ModelStringInput'] | undefined
    rankingLastSkippedByUserId?: GraphQLTypes['ModelIDInput'] | undefined
    rankingLastUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    rankingLastUpdatedByUserId?: GraphQLTypes['ModelIDInput'] | undefined
    recommended?: GraphQLTypes['ModelBooleanInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    startDate?: GraphQLTypes['ModelStringInput'] | undefined
    usedForCampaign?: GraphQLTypes['ModelBooleanInput'] | undefined
    visible?: GraphQLTypes['ModelBooleanInput'] | undefined
    weight?: GraphQLTypes['ModelIntInput'] | undefined
  }
  ['ModelProductQualityLevelInput']: {
    eq?: GraphQLTypes['ProductQualityLevel'] | undefined
    ne?: GraphQLTypes['ProductQualityLevel'] | undefined
  }
  ['ModelProductRatingSnapshotConditionInput']: {
    and?: Array<GraphQLTypes['ModelProductRatingSnapshotConditionInput'] | undefined> | undefined
    not?: GraphQLTypes['ModelProductRatingSnapshotConditionInput'] | undefined
    numFiveStar?: GraphQLTypes['ModelIntInput'] | undefined
    numFourStar?: GraphQLTypes['ModelIntInput'] | undefined
    numOneStar?: GraphQLTypes['ModelIntInput'] | undefined
    numRatings?: GraphQLTypes['ModelIntInput'] | undefined
    numReviews?: GraphQLTypes['ModelIntInput'] | undefined
    numThreeStar?: GraphQLTypes['ModelIntInput'] | undefined
    numTwoStar?: GraphQLTypes['ModelIntInput'] | undefined
    or?: Array<GraphQLTypes['ModelProductRatingSnapshotConditionInput'] | undefined> | undefined
    rating?: GraphQLTypes['ModelFloatInput'] | undefined
    sku?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelProductRatingSnapshotFilterInput']: {
    and?: Array<GraphQLTypes['ModelProductRatingSnapshotFilterInput'] | undefined> | undefined
    not?: GraphQLTypes['ModelProductRatingSnapshotFilterInput'] | undefined
    numFiveStar?: GraphQLTypes['ModelIntInput'] | undefined
    numFourStar?: GraphQLTypes['ModelIntInput'] | undefined
    numOneStar?: GraphQLTypes['ModelIntInput'] | undefined
    numRatings?: GraphQLTypes['ModelIntInput'] | undefined
    numReviews?: GraphQLTypes['ModelIntInput'] | undefined
    numThreeStar?: GraphQLTypes['ModelIntInput'] | undefined
    numTwoStar?: GraphQLTypes['ModelIntInput'] | undefined
    or?: Array<GraphQLTypes['ModelProductRatingSnapshotFilterInput'] | undefined> | undefined
    rating?: GraphQLTypes['ModelFloatInput'] | undefined
    sku?: GraphQLTypes['ModelStringInput'] | undefined
    skuId?: GraphQLTypes['ModelStringInput'] | undefined
    snapshotAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelPromoCodeTypeInput']: {
    eq?: GraphQLTypes['PromoCodeType'] | undefined
    ne?: GraphQLTypes['PromoCodeType'] | undefined
  }
  ['ModelPromoDiscountTypeInput']: {
    eq?: GraphQLTypes['PromoDiscountType'] | undefined
    ne?: GraphQLTypes['PromoDiscountType'] | undefined
  }
  ['ModelPurchaseConditionInput']: {
    amount?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelPurchaseConditionInput'] | undefined> | undefined
    cardId?: GraphQLTypes['ModelStringInput'] | undefined
    currency?: GraphQLTypes['ModelStringInput'] | undefined
    isFraud?: GraphQLTypes['ModelBooleanInput'] | undefined
    keywordAtPurchase?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelPurchaseConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelPurchaseConditionInput'] | undefined> | undefined
    purchasedAt?: GraphQLTypes['ModelStringInput'] | undefined
    shippedAt?: GraphQLTypes['ModelStringInput'] | undefined
    status?: GraphQLTypes['ModelPurchaseStatusInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelPurchaseFilterInput']: {
    amount?: GraphQLTypes['ModelIntInput'] | undefined
    and?: Array<GraphQLTypes['ModelPurchaseFilterInput'] | undefined> | undefined
    cardId?: GraphQLTypes['ModelStringInput'] | undefined
    currency?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    isFraud?: GraphQLTypes['ModelBooleanInput'] | undefined
    keywordAtPurchase?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelPurchaseFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelPurchaseFilterInput'] | undefined> | undefined
    purchasedAt?: GraphQLTypes['ModelStringInput'] | undefined
    shippedAt?: GraphQLTypes['ModelStringInput'] | undefined
    status?: GraphQLTypes['ModelPurchaseStatusInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelPurchaseStatusInput']: {
    eq?: GraphQLTypes['PurchaseStatus'] | undefined
    ne?: GraphQLTypes['PurchaseStatus'] | undefined
  }
  ['ModelReferralConditionInput']: {
    and?: Array<GraphQLTypes['ModelReferralConditionInput'] | undefined> | undefined
    bonusPayoutId?: GraphQLTypes['ModelIDInput'] | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelReferralConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelReferralConditionInput'] | undefined> | undefined
    referralType?: GraphQLTypes['ModelReferralTypeInput'] | undefined
    referredUserId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelReferralFilterInput']: {
    and?: Array<GraphQLTypes['ModelReferralFilterInput'] | undefined> | undefined
    bonusPayoutId?: GraphQLTypes['ModelIDInput'] | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelReferralFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelReferralFilterInput'] | undefined> | undefined
    referralType?: GraphQLTypes['ModelReferralTypeInput'] | undefined
    referredUserId?: GraphQLTypes['ModelIDInput'] | undefined
    referrerUserId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelReferralTypeInput']: {
    eq?: GraphQLTypes['ReferralType'] | undefined
    ne?: GraphQLTypes['ReferralType'] | undefined
  }
  ['ModelRequiredProductLinkFlagInput']: {
    eq?: GraphQLTypes['RequiredProductLinkFlag'] | undefined
    ne?: GraphQLTypes['RequiredProductLinkFlag'] | undefined
  }
  ['ModelReviewConditionInput']: {
    and?: Array<GraphQLTypes['ModelReviewConditionInput'] | undefined> | undefined
    attributedUserId?: GraphQLTypes['ModelStringInput'] | undefined
    author?: GraphQLTypes['ModelStringInput'] | undefined
    authorBadge?: GraphQLTypes['ModelStringInput'] | undefined
    badge?: GraphQLTypes['ModelStringInput'] | undefined
    commentCount?: GraphQLTypes['ModelIntInput'] | undefined
    helpfulReactions?: GraphQLTypes['ModelIntInput'] | undefined
    negativeReactions?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelReviewConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelReviewConditionInput'] | undefined> | undefined
    photos?: GraphQLTypes['ModelStringInput'] | undefined
    productId?: GraphQLTypes['ModelStringInput'] | undefined
    productVariant?: GraphQLTypes['ModelStringInput'] | undefined
    rating?: GraphQLTypes['ModelIntInput'] | undefined
    removed?: GraphQLTypes['ModelBooleanInput'] | undefined
    reviewedAt?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedSku?: GraphQLTypes['ModelStringInput'] | undefined
    reviewerId?: GraphQLTypes['ModelStringInput'] | undefined
    sku?: GraphQLTypes['ModelStringInput'] | undefined
    skuId?: GraphQLTypes['ModelStringInput'] | undefined
    store?: GraphQLTypes['ModelStringInput'] | undefined
    text?: GraphQLTypes['ModelStringInput'] | undefined
    title?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelStringInput'] | undefined
    videos?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelReviewFilterInput']: {
    and?: Array<GraphQLTypes['ModelReviewFilterInput'] | undefined> | undefined
    attributedUserId?: GraphQLTypes['ModelStringInput'] | undefined
    author?: GraphQLTypes['ModelStringInput'] | undefined
    authorBadge?: GraphQLTypes['ModelStringInput'] | undefined
    badge?: GraphQLTypes['ModelStringInput'] | undefined
    commentCount?: GraphQLTypes['ModelIntInput'] | undefined
    helpfulReactions?: GraphQLTypes['ModelIntInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    negativeReactions?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelReviewFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelReviewFilterInput'] | undefined> | undefined
    photos?: GraphQLTypes['ModelStringInput'] | undefined
    productId?: GraphQLTypes['ModelStringInput'] | undefined
    productVariant?: GraphQLTypes['ModelStringInput'] | undefined
    rating?: GraphQLTypes['ModelIntInput'] | undefined
    removed?: GraphQLTypes['ModelBooleanInput'] | undefined
    reviewedAt?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedSku?: GraphQLTypes['ModelStringInput'] | undefined
    reviewerId?: GraphQLTypes['ModelStringInput'] | undefined
    sku?: GraphQLTypes['ModelStringInput'] | undefined
    skuId?: GraphQLTypes['ModelStringInput'] | undefined
    store?: GraphQLTypes['ModelStringInput'] | undefined
    text?: GraphQLTypes['ModelStringInput'] | undefined
    title?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelStringInput'] | undefined
    videos?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelReviewScreenshotVerificationResultInput']: {
    eq?: GraphQLTypes['ReviewScreenshotVerificationResult'] | undefined
    ne?: GraphQLTypes['ReviewScreenshotVerificationResult'] | undefined
  }
  ['ModelRevocationTypeInput']: {
    eq?: GraphQLTypes['RevocationType'] | undefined
    ne?: GraphQLTypes['RevocationType'] | undefined
  }
  ['ModelScrapeStatusInput']: {
    eq?: GraphQLTypes['ScrapeStatus'] | undefined
    ne?: GraphQLTypes['ScrapeStatus'] | undefined
  }
  ['ModelSelfieVideoApprovalStatusInput']: {
    eq?: GraphQLTypes['SelfieVideoApprovalStatus'] | undefined
    ne?: GraphQLTypes['SelfieVideoApprovalStatus'] | undefined
  }
  ['ModelShopifyPromotionTypeInput']: {
    eq?: GraphQLTypes['ShopifyPromotionType'] | undefined
    ne?: GraphQLTypes['ShopifyPromotionType'] | undefined
  }
  ['ModelSizeInput']: {
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
    ne?: number | undefined
  }
  ['ModelSkuConditionInput']: {
    active?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelSkuConditionInput'] | undefined> | undefined
    approxRetailPrice?: GraphQLTypes['ModelStringInput'] | undefined
    approxShippingCost?: GraphQLTypes['ModelIntInput'] | undefined
    availabilityDate?: GraphQLTypes['ModelStringInput'] | undefined
    availabilityNotified?: GraphQLTypes['ModelBooleanInput'] | undefined
    availabilityNotifiedAt?: GraphQLTypes['ModelStringInput'] | undefined
    brandId?: GraphQLTypes['ModelIDInput'] | undefined
    brandListingKey?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreId?: GraphQLTypes['ModelStringInput'] | undefined
    bundle?: GraphQLTypes['ModelStringInput'] | undefined
    createdByUserId?: GraphQLTypes['ModelStringInput'] | undefined
    creationSource?: GraphQLTypes['ModelSkuCreationSourceInput'] | undefined
    creationSourceUrl?: GraphQLTypes['ModelStringInput'] | undefined
    currency?: GraphQLTypes['ModelCurrencyInput'] | undefined
    currentBuyBoxPrice?: GraphQLTypes['ModelIntInput'] | undefined
    currentBuyBoxPriceCents?: GraphQLTypes['ModelIntInput'] | undefined
    estimatedAvailabilityDate?: GraphQLTypes['ModelStringInput'] | undefined
    estimatedDeliveryDays?: GraphQLTypes['ModelIntInput'] | undefined
    estimatedMonthlyUnitsSold?: GraphQLTypes['ModelIntInput'] | undefined
    estimatedWeeklyUnitsSold?: GraphQLTypes['ModelIntInput'] | undefined
    exactListingTitle?: GraphQLTypes['ModelStringInput'] | undefined
    exactRetailPrice?: GraphQLTypes['ModelStringInput'] | undefined
    fullReviewScrapeComplete?: GraphQLTypes['ModelBooleanInput'] | undefined
    inventory?: GraphQLTypes['ModelIntInput'] | undefined
    iosAppProviderId?: GraphQLTypes['ModelStringInput'] | undefined
    isAplusPage?: GraphQLTypes['ModelStringInput'] | undefined
    isPrime?: GraphQLTypes['ModelStringInput'] | undefined
    isRecommendationsHidden?: GraphQLTypes['ModelBooleanInput'] | undefined
    itemClassification?: GraphQLTypes['ModelItemClassificationInput'] | undefined
    lastAutoFilledAt?: GraphQLTypes['ModelStringInput'] | undefined
    lastKnownBuyBoxPrice?: GraphQLTypes['ModelIntInput'] | undefined
    lastKnownBuyBoxPriceCents?: GraphQLTypes['ModelIntInput'] | undefined
    listingBrand?: GraphQLTypes['ModelStringInput'] | undefined
    listingCategory?: GraphQLTypes['ModelStringInput'] | undefined
    listingDescription?: GraphQLTypes['ModelStringInput'] | undefined
    listingFulfillmentType?: GraphQLTypes['ModelListingFulfillmentTypeInput'] | undefined
    listingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    listingLink?: GraphQLTypes['ModelStringInput'] | undefined
    mccValue?: GraphQLTypes['ModelValidMccCodesInput'] | undefined
    minimumOrderQuantity?: GraphQLTypes['ModelIntInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    nameAudioKey?: GraphQLTypes['ModelStringInput'] | undefined
    nonSponsoredListingKey?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelSkuConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelSkuConditionInput'] | undefined> | undefined
    parentSkuId?: GraphQLTypes['ModelIDInput'] | undefined
    priceCents?: GraphQLTypes['ModelIntInput'] | undefined
    product?: GraphQLTypes['ModelStringInput'] | undefined
    productImageKey?: GraphQLTypes['ModelStringInput'] | undefined
    productPostingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    productPostingGuidanceDocument?: GraphQLTypes['ModelStringInput'] | undefined
    productSearchImageKey?: GraphQLTypes['ModelStringInput'] | undefined
    releaseDate?: GraphQLTypes['ModelStringInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    sponsoredListingKey?: GraphQLTypes['ModelStringInput'] | undefined
    storeName?: GraphQLTypes['ModelStringInput'] | undefined
    targetReviewRateInvalid?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelSkuCreationSourceInput']: {
    eq?: GraphQLTypes['SkuCreationSource'] | undefined
    ne?: GraphQLTypes['SkuCreationSource'] | undefined
  }
  ['ModelSkuFilterInput']: {
    active?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelSkuFilterInput'] | undefined> | undefined
    approxRetailPrice?: GraphQLTypes['ModelStringInput'] | undefined
    approxShippingCost?: GraphQLTypes['ModelIntInput'] | undefined
    availabilityDate?: GraphQLTypes['ModelStringInput'] | undefined
    availabilityNotified?: GraphQLTypes['ModelBooleanInput'] | undefined
    availabilityNotifiedAt?: GraphQLTypes['ModelStringInput'] | undefined
    brandId?: GraphQLTypes['ModelIDInput'] | undefined
    brandListingKey?: GraphQLTypes['ModelStringInput'] | undefined
    brandStoreId?: GraphQLTypes['ModelStringInput'] | undefined
    bundle?: GraphQLTypes['ModelStringInput'] | undefined
    createdByUserId?: GraphQLTypes['ModelStringInput'] | undefined
    creationSource?: GraphQLTypes['ModelSkuCreationSourceInput'] | undefined
    creationSourceUrl?: GraphQLTypes['ModelStringInput'] | undefined
    currency?: GraphQLTypes['ModelCurrencyInput'] | undefined
    currentBuyBoxPrice?: GraphQLTypes['ModelIntInput'] | undefined
    currentBuyBoxPriceCents?: GraphQLTypes['ModelIntInput'] | undefined
    estimatedAvailabilityDate?: GraphQLTypes['ModelStringInput'] | undefined
    estimatedDeliveryDays?: GraphQLTypes['ModelIntInput'] | undefined
    estimatedMonthlyUnitsSold?: GraphQLTypes['ModelIntInput'] | undefined
    estimatedWeeklyUnitsSold?: GraphQLTypes['ModelIntInput'] | undefined
    exactListingTitle?: GraphQLTypes['ModelStringInput'] | undefined
    exactRetailPrice?: GraphQLTypes['ModelStringInput'] | undefined
    fullReviewScrapeComplete?: GraphQLTypes['ModelBooleanInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    inventory?: GraphQLTypes['ModelIntInput'] | undefined
    iosAppProviderId?: GraphQLTypes['ModelStringInput'] | undefined
    isAplusPage?: GraphQLTypes['ModelStringInput'] | undefined
    isPrime?: GraphQLTypes['ModelStringInput'] | undefined
    isRecommendationsHidden?: GraphQLTypes['ModelBooleanInput'] | undefined
    itemClassification?: GraphQLTypes['ModelItemClassificationInput'] | undefined
    lastAutoFilledAt?: GraphQLTypes['ModelStringInput'] | undefined
    lastKnownBuyBoxPrice?: GraphQLTypes['ModelIntInput'] | undefined
    lastKnownBuyBoxPriceCents?: GraphQLTypes['ModelIntInput'] | undefined
    listingBrand?: GraphQLTypes['ModelStringInput'] | undefined
    listingCategory?: GraphQLTypes['ModelStringInput'] | undefined
    listingDescription?: GraphQLTypes['ModelStringInput'] | undefined
    listingFulfillmentType?: GraphQLTypes['ModelListingFulfillmentTypeInput'] | undefined
    listingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    listingLink?: GraphQLTypes['ModelStringInput'] | undefined
    mccValue?: GraphQLTypes['ModelValidMccCodesInput'] | undefined
    minimumOrderQuantity?: GraphQLTypes['ModelIntInput'] | undefined
    name?: GraphQLTypes['ModelStringInput'] | undefined
    nameAudioKey?: GraphQLTypes['ModelStringInput'] | undefined
    nonSponsoredListingKey?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelSkuFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelSkuFilterInput'] | undefined> | undefined
    parentSkuId?: GraphQLTypes['ModelIDInput'] | undefined
    priceCents?: GraphQLTypes['ModelIntInput'] | undefined
    product?: GraphQLTypes['ModelStringInput'] | undefined
    productImageKey?: GraphQLTypes['ModelStringInput'] | undefined
    productPostingGuidance?: GraphQLTypes['ModelStringInput'] | undefined
    productPostingGuidanceDocument?: GraphQLTypes['ModelStringInput'] | undefined
    productSearchImageKey?: GraphQLTypes['ModelStringInput'] | undefined
    releaseDate?: GraphQLTypes['ModelStringInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    sponsoredListingKey?: GraphQLTypes['ModelStringInput'] | undefined
    storeName?: GraphQLTypes['ModelStringInput'] | undefined
    targetReviewRateInvalid?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelSkuSnapshotBySkuIdCompositeKeyConditionInput']: {
    beginsWith?: GraphQLTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    between?: Array<GraphQLTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined> | undefined
    eq?: GraphQLTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    ge?: GraphQLTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    gt?: GraphQLTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    le?: GraphQLTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
    lt?: GraphQLTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput'] | undefined
  }
  ['ModelSkuSnapshotBySkuIdCompositeKeyInput']: {
    marketplaceSku?: string | undefined
    snapshotDate?: string | undefined
  }
  ['ModelSkuSnapshotConditionInput']: {
    and?: Array<GraphQLTypes['ModelSkuSnapshotConditionInput'] | undefined> | undefined
    inventory?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelSkuSnapshotConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelSkuSnapshotConditionInput'] | undefined> | undefined
    price?: GraphQLTypes['ModelIntInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelSkuSnapshotFilterInput']: {
    and?: Array<GraphQLTypes['ModelSkuSnapshotFilterInput'] | undefined> | undefined
    inventory?: GraphQLTypes['ModelIntInput'] | undefined
    marketplaceSku?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelSkuSnapshotFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelSkuSnapshotFilterInput'] | undefined> | undefined
    price?: GraphQLTypes['ModelIntInput'] | undefined
    skuId?: GraphQLTypes['ModelIDInput'] | undefined
    snapshotDate?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelSocialChannelInput']: {
    eq?: GraphQLTypes['SocialChannel'] | undefined
    ne?: GraphQLTypes['SocialChannel'] | undefined
  }
  ['ModelSocialContentTypeInput']: {
    eq?: GraphQLTypes['SocialContentType'] | undefined
    ne?: GraphQLTypes['SocialContentType'] | undefined
  }
  ['ModelSocialQualityInput']: {
    eq?: GraphQLTypes['SocialQuality'] | undefined
    ne?: GraphQLTypes['SocialQuality'] | undefined
  }
  ['ModelSocialVerificationMethodInput']: {
    eq?: GraphQLTypes['SocialVerificationMethod'] | undefined
    ne?: GraphQLTypes['SocialVerificationMethod'] | undefined
  }
  ['ModelSourcePriorityInput']: {
    eq?: GraphQLTypes['SourcePriority'] | undefined
    ne?: GraphQLTypes['SourcePriority'] | undefined
  }
  ['ModelStagingApprovalOutcomeInput']: {
    eq?: GraphQLTypes['StagingApprovalOutcome'] | undefined
    ne?: GraphQLTypes['StagingApprovalOutcome'] | undefined
  }
  ['ModelStagingRequirementTypeInput']: {
    eq?: GraphQLTypes['StagingRequirementType'] | undefined
    ne?: GraphQLTypes['StagingRequirementType'] | undefined
  }
  ['ModelStatusTierInput']: {
    eq?: GraphQLTypes['StatusTier'] | undefined
    ne?: GraphQLTypes['StatusTier'] | undefined
  }
  ['ModelStatusTierListInput']: {
    contains?: GraphQLTypes['StatusTier'] | undefined
    eq?: Array<GraphQLTypes['StatusTier'] | undefined> | undefined
    ne?: Array<GraphQLTypes['StatusTier'] | undefined> | undefined
    notContains?: GraphQLTypes['StatusTier'] | undefined
  }
  ['ModelStringInput']: {
    attributeExists?: boolean | undefined
    attributeType?: GraphQLTypes['ModelAttributeTypes'] | undefined
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    contains?: string | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
    ne?: string | undefined
    notContains?: string | undefined
    size?: GraphQLTypes['ModelSizeInput'] | undefined
  }
  ['ModelStringKeyConditionInput']: {
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
  }
  ['ModelStripeRegionInput']: {
    eq?: GraphQLTypes['StripeRegion'] | undefined
    ne?: GraphQLTypes['StripeRegion'] | undefined
  }
  ['ModelSupportPriorityFlagInput']: {
    eq?: GraphQLTypes['SupportPriorityFlag'] | undefined
    ne?: GraphQLTypes['SupportPriorityFlag'] | undefined
  }
  ['ModelTrackingStatusInput']: {
    eq?: GraphQLTypes['TrackingStatus'] | undefined
    ne?: GraphQLTypes['TrackingStatus'] | undefined
  }
  ['ModelUserBonusPayoutConditionInput']: {
    adminPaymentApproved?: GraphQLTypes['ModelBooleanInput'] | undefined
    and?: Array<GraphQLTypes['ModelUserBonusPayoutConditionInput'] | undefined> | undefined
    bonusType?: GraphQLTypes['ModelBonusPayoutTypesInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    failureReason?: GraphQLTypes['ModelStringInput'] | undefined
    manuallyCreatedBy?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserBonusPayoutConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserBonusPayoutConditionInput'] | undefined> | undefined
    payoutAmount?: GraphQLTypes['ModelIntInput'] | undefined
    payoutAmountCents?: GraphQLTypes['ModelIntInput'] | undefined
    payoutBatchId?: GraphQLTypes['ModelStringInput'] | undefined
    payoutDate?: GraphQLTypes['ModelStringInput'] | undefined
    payoutLocale?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    payoutState?: GraphQLTypes['ModelBonusPayoutStateInput'] | undefined
    quantity?: GraphQLTypes['ModelIntInput'] | undefined
    referenceId?: GraphQLTypes['ModelStringInput'] | undefined
    releaseAt?: GraphQLTypes['ModelStringInput'] | undefined
    revocationType?: GraphQLTypes['ModelRevocationTypeInput'] | undefined
    revokedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserBonusPayoutFilterInput']: {
    adminPaymentApproved?: GraphQLTypes['ModelBooleanInput'] | undefined
    and?: Array<GraphQLTypes['ModelUserBonusPayoutFilterInput'] | undefined> | undefined
    bonusType?: GraphQLTypes['ModelBonusPayoutTypesInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    failureReason?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    manuallyCreatedBy?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserBonusPayoutFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserBonusPayoutFilterInput'] | undefined> | undefined
    payoutAmount?: GraphQLTypes['ModelIntInput'] | undefined
    payoutAmountCents?: GraphQLTypes['ModelIntInput'] | undefined
    payoutBatchId?: GraphQLTypes['ModelStringInput'] | undefined
    payoutDate?: GraphQLTypes['ModelStringInput'] | undefined
    payoutLocale?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    payoutState?: GraphQLTypes['ModelBonusPayoutStateInput'] | undefined
    quantity?: GraphQLTypes['ModelIntInput'] | undefined
    referenceId?: GraphQLTypes['ModelStringInput'] | undefined
    releaseAt?: GraphQLTypes['ModelStringInput'] | undefined
    revocationType?: GraphQLTypes['ModelRevocationTypeInput'] | undefined
    revokedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserCampaignAcceptanceStatusInput']: {
    eq?: GraphQLTypes['UserCampaignAcceptanceStatus'] | undefined
    ne?: GraphQLTypes['UserCampaignAcceptanceStatus'] | undefined
  }
  ['ModelUserCampaignApprovalStatusInput']: {
    eq?: GraphQLTypes['UserCampaignApprovalStatus'] | undefined
    ne?: GraphQLTypes['UserCampaignApprovalStatus'] | undefined
  }
  ['ModelUserCampaignChangelogConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignChangelogConditionInput'] | undefined> | undefined
    createdByUserId?: GraphQLTypes['ModelIDInput'] | undefined
    entryType?: GraphQLTypes['ModelUserCampaignChangelogEntryTypeInput'] | undefined
    extraData?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserCampaignChangelogConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignChangelogConditionInput'] | undefined> | undefined
  }
  ['ModelUserCampaignChangelogEntryTypeInput']: {
    eq?: GraphQLTypes['UserCampaignChangelogEntryType'] | undefined
    ne?: GraphQLTypes['UserCampaignChangelogEntryType'] | undefined
  }
  ['ModelUserCampaignChangelogFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignChangelogFilterInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    createdByUserId?: GraphQLTypes['ModelIDInput'] | undefined
    entryType?: GraphQLTypes['ModelUserCampaignChangelogEntryTypeInput'] | undefined
    extraData?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserCampaignChangelogFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignChangelogFilterInput'] | undefined> | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserCampaignConditionInput']: {
    accountScreenShot?: GraphQLTypes['ModelStringInput'] | undefined
    amznReviewLink?: GraphQLTypes['ModelStringInput'] | undefined
    amznReviewSubmissionStatus?: GraphQLTypes['ModelAmazonReviewSubmissionStatusInput'] | undefined
    and?: Array<GraphQLTypes['ModelUserCampaignConditionInput'] | undefined> | undefined
    applicationRejectedByUserId?: GraphQLTypes['ModelIDInput'] | undefined
    approvalOutcome?: GraphQLTypes['ModelApprovalOutcomeInput'] | undefined
    approvalStatus?: GraphQLTypes['ModelUserCampaignApprovalStatusInput'] | undefined
    archivedFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    archivedReason?: GraphQLTypes['ModelArchivedReasonInput'] | undefined
    autoBuyApprovalDate?: GraphQLTypes['ModelStringInput'] | undefined
    autoBuyRevokeDate?: GraphQLTypes['ModelStringInput'] | undefined
    bestBuyReviewScreenShot?: GraphQLTypes['ModelStringInput'] | undefined
    campaignAcceptanceStatus?: GraphQLTypes['ModelUserCampaignAcceptanceStatusInput'] | undefined
    campaignApplication?: GraphQLTypes['ModelStringInput'] | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    campaignStatus?: GraphQLTypes['ModelCampaignStatusInput'] | undefined
    campaignStatusOnArchive?: GraphQLTypes['ModelStringInput'] | undefined
    cardId?: GraphQLTypes['ModelStringInput'] | undefined
    cardRegion?: GraphQLTypes['ModelStripeRegionInput'] | undefined
    closeoutSurvey?: GraphQLTypes['ModelStringInput'] | undefined
    coarseCampaignStatus?: GraphQLTypes['ModelCoarseCampaignStatusInput'] | undefined
    contentApprovalLeadDays?: GraphQLTypes['ModelIntInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    discoverySurvey?: GraphQLTypes['ModelStringInput'] | undefined
    influencerApprovalInviteFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    influencerApprovalRejectFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    invoiceKey?: GraphQLTypes['ModelStringInput'] | undefined
    isAutoApproved?: GraphQLTypes['ModelBooleanInput'] | undefined
    keywordAtPurchase?: GraphQLTypes['ModelStringInput'] | undefined
    keywordId?: GraphQLTypes['ModelIDInput'] | undefined
    keywordsV2Hash?: GraphQLTypes['ModelStringInput'] | undefined
    listingKey?: GraphQLTypes['ModelStringInput'] | undefined
    localeV1?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    manualPurchase?: GraphQLTypes['ModelBooleanInput'] | undefined
    moderatorStagingApprovalAt?: GraphQLTypes['ModelStringInput'] | undefined
    moderatorStagingApprovalBy?: GraphQLTypes['ModelStringInput'] | undefined
    moderatorStagingApprovalOutcome?: GraphQLTypes['ModelStagingApprovalOutcomeInput'] | undefined
    not?: GraphQLTypes['ModelUserCampaignConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignConditionInput'] | undefined> | undefined
    orderId?: GraphQLTypes['ModelStringInput'] | undefined
    orderIdInvalidReason?: GraphQLTypes['ModelStringInput'] | undefined
    orderIdSubmissionDate?: GraphQLTypes['ModelStringInput'] | undefined
    orderIdValidationDate?: GraphQLTypes['ModelStringInput'] | undefined
    orderIdValidationStatus?: GraphQLTypes['ModelOrderIdValidationStatusInput'] | undefined
    orderStatus?: GraphQLTypes['ModelStringInput'] | undefined
    payForClickLinkId?: GraphQLTypes['ModelIDInput'] | undefined
    paypalEmailAtSubmission?: GraphQLTypes['ModelStringInput'] | undefined
    postingDeadline?: GraphQLTypes['ModelStringInput'] | undefined
    postingDeadlineExtended?: GraphQLTypes['ModelBooleanInput'] | undefined
    previousCardIds?: GraphQLTypes['ModelStringInput'] | undefined
    priority?: GraphQLTypes['ModelSourcePriorityInput'] | undefined
    productCriticalFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    productId?: GraphQLTypes['ModelIDInput'] | undefined
    productPositiveFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    rankPriority?: GraphQLTypes['ModelIntInput'] | undefined
    reviewScreenshot?: GraphQLTypes['ModelStringInput'] | undefined
    reviewScreenshotVerificationResult?: GraphQLTypes['ModelReviewScreenshotVerificationResultInput'] | undefined
    schedulingReleaseAt?: GraphQLTypes['ModelStringInput'] | undefined
    searchPhraseKey?: GraphQLTypes['ModelStringInput'] | undefined
    selectedFlow?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoApprovalDate?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoApprovalNotes?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoApprovalStatus?: GraphQLTypes['ModelSelfieVideoApprovalStatusInput'] | undefined
    selfieVideoKey?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoRejectionReason?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoSubmissionRequired?: GraphQLTypes['ModelBooleanInput'] | undefined
    selfieVideoSubmittedAt?: GraphQLTypes['ModelStringInput'] | undefined
    shippingAddress?: GraphQLTypes['ModelStringInput'] | undefined
    showEarlyReviewSurvey?: GraphQLTypes['ModelBooleanInput'] | undefined
    statusHistory?: GraphQLTypes['ModelStringInput'] | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    walmartReviewScreenShot?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignFilterInput']: {
    accountScreenShot?: GraphQLTypes['ModelStringInput'] | undefined
    amznReviewLink?: GraphQLTypes['ModelStringInput'] | undefined
    amznReviewSubmissionStatus?: GraphQLTypes['ModelAmazonReviewSubmissionStatusInput'] | undefined
    and?: Array<GraphQLTypes['ModelUserCampaignFilterInput'] | undefined> | undefined
    applicationRejectedByUserId?: GraphQLTypes['ModelIDInput'] | undefined
    approvalOutcome?: GraphQLTypes['ModelApprovalOutcomeInput'] | undefined
    approvalStatus?: GraphQLTypes['ModelUserCampaignApprovalStatusInput'] | undefined
    archivedFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    archivedReason?: GraphQLTypes['ModelArchivedReasonInput'] | undefined
    autoBuyApprovalDate?: GraphQLTypes['ModelStringInput'] | undefined
    autoBuyRevokeDate?: GraphQLTypes['ModelStringInput'] | undefined
    bestBuyReviewScreenShot?: GraphQLTypes['ModelStringInput'] | undefined
    campaignAcceptanceStatus?: GraphQLTypes['ModelUserCampaignAcceptanceStatusInput'] | undefined
    campaignApplication?: GraphQLTypes['ModelStringInput'] | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    campaignStatus?: GraphQLTypes['ModelCampaignStatusInput'] | undefined
    campaignStatusOnArchive?: GraphQLTypes['ModelStringInput'] | undefined
    cardId?: GraphQLTypes['ModelStringInput'] | undefined
    cardRegion?: GraphQLTypes['ModelStripeRegionInput'] | undefined
    closeoutSurvey?: GraphQLTypes['ModelStringInput'] | undefined
    coarseCampaignStatus?: GraphQLTypes['ModelCoarseCampaignStatusInput'] | undefined
    contentApprovalLeadDays?: GraphQLTypes['ModelIntInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    discoverySurvey?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    influencerApprovalInviteFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    influencerApprovalRejectFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    invoiceKey?: GraphQLTypes['ModelStringInput'] | undefined
    isAutoApproved?: GraphQLTypes['ModelBooleanInput'] | undefined
    keywordAtPurchase?: GraphQLTypes['ModelStringInput'] | undefined
    keywordId?: GraphQLTypes['ModelIDInput'] | undefined
    keywordsV2Hash?: GraphQLTypes['ModelStringInput'] | undefined
    listingKey?: GraphQLTypes['ModelStringInput'] | undefined
    localeV1?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    manualPurchase?: GraphQLTypes['ModelBooleanInput'] | undefined
    moderatorStagingApprovalAt?: GraphQLTypes['ModelStringInput'] | undefined
    moderatorStagingApprovalBy?: GraphQLTypes['ModelStringInput'] | undefined
    moderatorStagingApprovalOutcome?: GraphQLTypes['ModelStagingApprovalOutcomeInput'] | undefined
    not?: GraphQLTypes['ModelUserCampaignFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignFilterInput'] | undefined> | undefined
    orderId?: GraphQLTypes['ModelStringInput'] | undefined
    orderIdInvalidReason?: GraphQLTypes['ModelStringInput'] | undefined
    orderIdSubmissionDate?: GraphQLTypes['ModelStringInput'] | undefined
    orderIdValidationDate?: GraphQLTypes['ModelStringInput'] | undefined
    orderIdValidationStatus?: GraphQLTypes['ModelOrderIdValidationStatusInput'] | undefined
    orderStatus?: GraphQLTypes['ModelStringInput'] | undefined
    payForClickLinkId?: GraphQLTypes['ModelIDInput'] | undefined
    paypalEmailAtSubmission?: GraphQLTypes['ModelStringInput'] | undefined
    postingDeadline?: GraphQLTypes['ModelStringInput'] | undefined
    postingDeadlineExtended?: GraphQLTypes['ModelBooleanInput'] | undefined
    previousCardIds?: GraphQLTypes['ModelStringInput'] | undefined
    priority?: GraphQLTypes['ModelSourcePriorityInput'] | undefined
    productCriticalFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    productId?: GraphQLTypes['ModelIDInput'] | undefined
    productPositiveFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    rankPriority?: GraphQLTypes['ModelIntInput'] | undefined
    reviewScreenshot?: GraphQLTypes['ModelStringInput'] | undefined
    reviewScreenshotVerificationResult?: GraphQLTypes['ModelReviewScreenshotVerificationResultInput'] | undefined
    schedulingReleaseAt?: GraphQLTypes['ModelStringInput'] | undefined
    searchPhraseKey?: GraphQLTypes['ModelStringInput'] | undefined
    selectedFlow?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoApprovalDate?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoApprovalNotes?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoApprovalStatus?: GraphQLTypes['ModelSelfieVideoApprovalStatusInput'] | undefined
    selfieVideoKey?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoRejectionReason?: GraphQLTypes['ModelStringInput'] | undefined
    selfieVideoSubmissionRequired?: GraphQLTypes['ModelBooleanInput'] | undefined
    selfieVideoSubmittedAt?: GraphQLTypes['ModelStringInput'] | undefined
    shippingAddress?: GraphQLTypes['ModelStringInput'] | undefined
    showEarlyReviewSurvey?: GraphQLTypes['ModelBooleanInput'] | undefined
    statusHistory?: GraphQLTypes['ModelStringInput'] | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
    walmartReviewScreenShot?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSnapshotConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignSnapshotConditionInput'] | undefined> | undefined
    not?: GraphQLTypes['ModelUserCampaignSnapshotConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignSnapshotConditionInput'] | undefined> | undefined
    snapshot?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSnapshotFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignSnapshotFilterInput'] | undefined> | undefined
    not?: GraphQLTypes['ModelUserCampaignSnapshotFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignSnapshotFilterInput'] | undefined> | undefined
    snapshot?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    userCampaignVersion?: GraphQLTypes['ModelIntInput'] | undefined
  }
  ['ModelUserCampaignSocialContentConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignSocialContentConditionInput'] | undefined> | undefined
    approvalFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    approvalStatus?: GraphQLTypes['ModelContentApprovalStatusInput'] | undefined
    approvedAt?: GraphQLTypes['ModelStringInput'] | undefined
    approverPWUserId?: GraphQLTypes['ModelStringInput'] | undefined
    approverUserId?: GraphQLTypes['ModelIDInput'] | undefined
    bonusEarned?: GraphQLTypes['ModelIntInput'] | undefined
    bonusOverride?: GraphQLTypes['ModelIntInput'] | undefined
    caption?: GraphQLTypes['ModelStringInput'] | undefined
    channel?: GraphQLTypes['ModelSocialChannelInput'] | undefined
    complianceReviewStatus?: GraphQLTypes['ModelContentComplianceReviewStatusInput'] | undefined
    complianceReviewUserId?: GraphQLTypes['ModelIDInput'] | undefined
    complianceReviewedAt?: GraphQLTypes['ModelStringInput'] | undefined
    contentType?: GraphQLTypes['ModelSocialContentTypeInput'] | undefined
    draftEnteredAdminApprovalAt?: GraphQLTypes['ModelStringInput'] | undefined
    draftEnteredBrandApprovalAt?: GraphQLTypes['ModelStringInput'] | undefined
    draftFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    draftHistory?: GraphQLTypes['ModelStringInput'] | undefined
    draftMedia?: GraphQLTypes['ModelStringInput'] | undefined
    draftPreApprovedText?: GraphQLTypes['ModelStringInput'] | undefined
    draftQualityScore?: GraphQLTypes['ModelIntInput'] | undefined
    draftStatus?: GraphQLTypes['ModelDraftStatusInput'] | undefined
    draftSubmittedAt?: GraphQLTypes['ModelStringInput'] | undefined
    draftText?: GraphQLTypes['ModelStringInput'] | undefined
    earlySubmissionBonusEarnedCents?: GraphQLTypes['ModelIntInput'] | undefined
    experimentId?: GraphQLTypes['ModelStringInput'] | undefined
    externalUrl?: GraphQLTypes['ModelStringInput'] | undefined
    groupId?: GraphQLTypes['ModelStringInput'] | undefined
    hashtags?: GraphQLTypes['ModelStringInput'] | undefined
    instance?: GraphQLTypes['ModelIntInput'] | undefined
    isFavorite?: GraphQLTypes['ModelBooleanInput'] | undefined
    isHidden?: GraphQLTypes['ModelBooleanInput'] | undefined
    isPurchased?: GraphQLTypes['ModelBooleanInput'] | undefined
    isUnavailable?: GraphQLTypes['ModelBooleanInput'] | undefined
    manualUploadType?: GraphQLTypes['ModelManualUploadTypeInput'] | undefined
    mediaId?: GraphQLTypes['ModelStringInput'] | undefined
    mediaUrls?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserCampaignSocialContentConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignSocialContentConditionInput'] | undefined> | undefined
    paymentId?: GraphQLTypes['ModelStringInput'] | undefined
    postedAt?: GraphQLTypes['ModelStringInput'] | undefined
    qualityScore?: GraphQLTypes['ModelIntInput'] | undefined
    rejectionReason?: GraphQLTypes['ModelStringInput'] | undefined
    requestedSocialScript?: GraphQLTypes['ModelBooleanInput'] | undefined
    requirementLevel?: GraphQLTypes['ModelContentRequirementLevelInput'] | undefined
    scrapeStatus?: GraphQLTypes['ModelScrapeStatusInput'] | undefined
    unavailableAt?: GraphQLTypes['ModelStringInput'] | undefined
    usedRequestedSocialScript?: GraphQLTypes['ModelBooleanInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    videoEndTime?: GraphQLTypes['ModelStringInput'] | undefined
    videoStartTime?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSocialContentDraftHistoryConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined> | undefined
    not?: GraphQLTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput'] | undefined> | undefined
    snapshot?: GraphQLTypes['ModelStringInput'] | undefined
    snapshotDate?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignSocialContentId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserCampaignSocialContentDraftHistoryFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined> | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput'] | undefined> | undefined
    snapshot?: GraphQLTypes['ModelStringInput'] | undefined
    snapshotDate?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignSocialContentId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserCampaignSocialContentFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignSocialContentFilterInput'] | undefined> | undefined
    approvalFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    approvalStatus?: GraphQLTypes['ModelContentApprovalStatusInput'] | undefined
    approvedAt?: GraphQLTypes['ModelStringInput'] | undefined
    approverPWUserId?: GraphQLTypes['ModelStringInput'] | undefined
    approverUserId?: GraphQLTypes['ModelIDInput'] | undefined
    bonusEarned?: GraphQLTypes['ModelIntInput'] | undefined
    bonusOverride?: GraphQLTypes['ModelIntInput'] | undefined
    caption?: GraphQLTypes['ModelStringInput'] | undefined
    channel?: GraphQLTypes['ModelSocialChannelInput'] | undefined
    complianceReviewStatus?: GraphQLTypes['ModelContentComplianceReviewStatusInput'] | undefined
    complianceReviewUserId?: GraphQLTypes['ModelIDInput'] | undefined
    complianceReviewedAt?: GraphQLTypes['ModelStringInput'] | undefined
    contentType?: GraphQLTypes['ModelSocialContentTypeInput'] | undefined
    draftEnteredAdminApprovalAt?: GraphQLTypes['ModelStringInput'] | undefined
    draftEnteredBrandApprovalAt?: GraphQLTypes['ModelStringInput'] | undefined
    draftFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    draftHistory?: GraphQLTypes['ModelStringInput'] | undefined
    draftMedia?: GraphQLTypes['ModelStringInput'] | undefined
    draftPreApprovedText?: GraphQLTypes['ModelStringInput'] | undefined
    draftQualityScore?: GraphQLTypes['ModelIntInput'] | undefined
    draftStatus?: GraphQLTypes['ModelDraftStatusInput'] | undefined
    draftSubmittedAt?: GraphQLTypes['ModelStringInput'] | undefined
    draftText?: GraphQLTypes['ModelStringInput'] | undefined
    earlySubmissionBonusEarnedCents?: GraphQLTypes['ModelIntInput'] | undefined
    experimentId?: GraphQLTypes['ModelStringInput'] | undefined
    externalUrl?: GraphQLTypes['ModelStringInput'] | undefined
    groupId?: GraphQLTypes['ModelStringInput'] | undefined
    hashtags?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    instance?: GraphQLTypes['ModelIntInput'] | undefined
    isFavorite?: GraphQLTypes['ModelBooleanInput'] | undefined
    isHidden?: GraphQLTypes['ModelBooleanInput'] | undefined
    isPurchased?: GraphQLTypes['ModelBooleanInput'] | undefined
    isUnavailable?: GraphQLTypes['ModelBooleanInput'] | undefined
    manualUploadType?: GraphQLTypes['ModelManualUploadTypeInput'] | undefined
    mediaId?: GraphQLTypes['ModelStringInput'] | undefined
    mediaUrls?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserCampaignSocialContentFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignSocialContentFilterInput'] | undefined> | undefined
    paymentId?: GraphQLTypes['ModelStringInput'] | undefined
    postedAt?: GraphQLTypes['ModelStringInput'] | undefined
    qualityScore?: GraphQLTypes['ModelIntInput'] | undefined
    rejectionReason?: GraphQLTypes['ModelStringInput'] | undefined
    requestedSocialScript?: GraphQLTypes['ModelBooleanInput'] | undefined
    requirementLevel?: GraphQLTypes['ModelContentRequirementLevelInput'] | undefined
    scrapeStatus?: GraphQLTypes['ModelScrapeStatusInput'] | undefined
    unavailableAt?: GraphQLTypes['ModelStringInput'] | undefined
    usedRequestedSocialScript?: GraphQLTypes['ModelBooleanInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    videoEndTime?: GraphQLTypes['ModelStringInput'] | undefined
    videoStartTime?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignSocialContentReviewConditionInput'] | undefined> | undefined
    reviewOutcome?: GraphQLTypes['ModelUserCampaignSocialContentReviewOutcomeInput'] | undefined
    reviewStatus?: GraphQLTypes['ModelUserCampaignSocialContentReviewStatusInput'] | undefined
    reviewedAt?: GraphQLTypes['ModelStringInput'] | undefined
    reviewerAssigneeId?: GraphQLTypes['ModelStringInput'] | undefined
    reviewerFirstName?: GraphQLTypes['ModelStringInput'] | undefined
    reviewerId?: GraphQLTypes['ModelIDInput'] | undefined
    reviewerLastName?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserCampaignSocialContentReviewFilterInput'] | undefined> | undefined
    reviewOutcome?: GraphQLTypes['ModelUserCampaignSocialContentReviewOutcomeInput'] | undefined
    reviewStatus?: GraphQLTypes['ModelUserCampaignSocialContentReviewStatusInput'] | undefined
    reviewType?: GraphQLTypes['ModelUserCampaignSocialContentReviewTypeInput'] | undefined
    reviewedAt?: GraphQLTypes['ModelStringInput'] | undefined
    reviewerAssigneeId?: GraphQLTypes['ModelStringInput'] | undefined
    reviewerFirstName?: GraphQLTypes['ModelStringInput'] | undefined
    reviewerId?: GraphQLTypes['ModelIDInput'] | undefined
    reviewerLastName?: GraphQLTypes['ModelStringInput'] | undefined
    userCampaignSocialContentId?: GraphQLTypes['ModelIDInput'] | undefined
    userId?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewOutcomeInput']: {
    eq?: GraphQLTypes['UserCampaignSocialContentReviewOutcome'] | undefined
    ne?: GraphQLTypes['UserCampaignSocialContentReviewOutcome'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewStatusInput']: {
    eq?: GraphQLTypes['UserCampaignSocialContentReviewStatus'] | undefined
    ne?: GraphQLTypes['UserCampaignSocialContentReviewStatus'] | undefined
  }
  ['ModelUserCampaignSocialContentReviewTypeInput']: {
    eq?: GraphQLTypes['UserCampaignSocialContentReviewType'] | undefined
    ne?: GraphQLTypes['UserCampaignSocialContentReviewType'] | undefined
  }
  ['ModelUserConditionInput']: {
    active?: GraphQLTypes['ModelActiveInput'] | undefined
    addressComponents?: GraphQLTypes['ModelStringInput'] | undefined
    amznLastChecked?: GraphQLTypes['ModelStringInput'] | undefined
    amznLink?: GraphQLTypes['ModelStringInput'] | undefined
    amznPrime?: GraphQLTypes['ModelStringInput'] | undefined
    amznProfileId?: GraphQLTypes['ModelStringInput'] | undefined
    amznReviewerId?: GraphQLTypes['ModelStringInput'] | undefined
    amznVerified?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelUserConditionInput'] | undefined> | undefined
    attributedReviewCount?: GraphQLTypes['ModelIntInput'] | undefined
    blogWebsite?: GraphQLTypes['ModelStringInput'] | undefined
    brandContentRatingAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    campaignAcceptanceRate?: GraphQLTypes['ModelIntInput'] | undefined
    campaignApprovalRate?: GraphQLTypes['ModelIntInput'] | undefined
    campaignCompletionRate?: GraphQLTypes['ModelIntInput'] | undefined
    campaignReviewRate?: GraphQLTypes['ModelIntInput'] | undefined
    cardProfileUpdates?: GraphQLTypes['ModelStringInput'] | undefined
    cardholderId?: GraphQLTypes['ModelStringInput'] | undefined
    cardholderRegion?: GraphQLTypes['ModelStripeRegionInput'] | undefined
    characteristicsUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    claimedReviewCount?: GraphQLTypes['ModelIntInput'] | undefined
    collisionSkuSet?: GraphQLTypes['ModelStringInput'] | undefined
    collisionSkuSetCalculatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    completedLifestyleCampaignCount?: GraphQLTypes['ModelIntInput'] | undefined
    completedLiftCampaignCount?: GraphQLTypes['ModelIntInput'] | undefined
    completedSocialCampaignCount?: GraphQLTypes['ModelIntInput'] | undefined
    contentRatingCompletedAt?: GraphQLTypes['ModelStringInput'] | undefined
    contentRatingCompletionTimeMillis?: GraphQLTypes['ModelIntInput'] | undefined
    contentRatingInstagramCompletionTimeMillis?: GraphQLTypes['ModelIntInput'] | undefined
    contentRatingQualityScore?: GraphQLTypes['ModelIntInput'] | undefined
    contentRatingReviewStatus?: GraphQLTypes['ModelContentRatingReviewStatusInput'] | undefined
    contentRatingSubmittedBy?: GraphQLTypes['ModelStringInput'] | undefined
    contentRatingTiktokCompletionTimeMillis?: GraphQLTypes['ModelIntInput'] | undefined
    contentTypes?: GraphQLTypes['ModelStringInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    date_of_birth?: GraphQLTypes['ModelStringInput'] | undefined
    deactivationDate?: GraphQLTypes['ModelStringInput'] | undefined
    deactivationReason?: GraphQLTypes['ModelStringInput'] | undefined
    declineToReviewCount?: GraphQLTypes['ModelIntInput'] | undefined
    deranked?: GraphQLTypes['ModelDeranked_ReasonInput'] | undefined
    email?: GraphQLTypes['ModelStringInput'] | undefined
    emailOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    facebookAccessToken?: GraphQLTypes['ModelStringInput'] | undefined
    facebookAccessTokenExpiry?: GraphQLTypes['ModelStringInput'] | undefined
    facebookPageId?: GraphQLTypes['ModelStringInput'] | undefined
    facebookPageIsSubscribed?: GraphQLTypes['ModelBooleanInput'] | undefined
    facebookPermissionScopes?: GraphQLTypes['ModelStringInput'] | undefined
    facebookUserId?: GraphQLTypes['ModelStringInput'] | undefined
    firstName?: GraphQLTypes['ModelStringInput'] | undefined
    followsWesponsored?: GraphQLTypes['ModelStringInput'] | undefined
    fraudReason?: GraphQLTypes['ModelFraudReasonInput'] | undefined
    fraudReviewAt?: GraphQLTypes['ModelStringInput'] | undefined
    fraudReviewStatus?: GraphQLTypes['ModelFraudReviewStatusInput'] | undefined
    gender?: GraphQLTypes['ModelStringInput'] | undefined
    hasTrueEngagement?: GraphQLTypes['ModelBooleanInput'] | undefined
    idfaId?: GraphQLTypes['ModelStringInput'] | undefined
    incompleteCampaignCount?: GraphQLTypes['ModelIntInput'] | undefined
    instagramBio?: GraphQLTypes['ModelStringInput'] | undefined
    instagramBusinessCategoryName?: GraphQLTypes['ModelStringInput'] | undefined
    instagramCategoryName?: GraphQLTypes['ModelStringInput'] | undefined
    instagramChecked?: GraphQLTypes['ModelStringInput'] | undefined
    instagramContentTypeRating?: GraphQLTypes['ModelContentTypeRatingListInput'] | undefined
    instagramExternalLink?: GraphQLTypes['ModelStringInput'] | undefined
    instagramFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    instagramHandle?: GraphQLTypes['ModelStringInput'] | undefined
    instagramIsBusiness?: GraphQLTypes['ModelStringInput'] | undefined
    instagramIsPrivate?: GraphQLTypes['ModelStringInput'] | undefined
    instagramIsRecentlyJoined?: GraphQLTypes['ModelStringInput'] | undefined
    instagramIsVerified?: GraphQLTypes['ModelStringInput'] | undefined
    instagramLastChecked?: GraphQLTypes['ModelStringInput'] | undefined
    instagramMedianShares?: GraphQLTypes['ModelIntInput'] | undefined
    instagramMetricsRetrievedAt?: GraphQLTypes['ModelStringInput'] | undefined
    instagramName?: GraphQLTypes['ModelStringInput'] | undefined
    instagramPostsInt?: GraphQLTypes['ModelIntInput'] | undefined
    instagramPriority?: GraphQLTypes['ModelPlatformPriorityInput'] | undefined
    instagramProfilePic?: GraphQLTypes['ModelStringInput'] | undefined
    instagramTrailingComments?: GraphQLTypes['ModelIntInput'] | undefined
    instagramTrailingDateOfOldestPost?: GraphQLTypes['ModelStringInput'] | undefined
    instagramTrailingLikes?: GraphQLTypes['ModelIntInput'] | undefined
    instagramTrailingNumberOfPosts?: GraphQLTypes['ModelIntInput'] | undefined
    instagramTrailingPostEngagementAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateMedian?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostImpressionsAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostImpressionsMedian?: GraphQLTypes['ModelIntInput'] | undefined
    instagramTrailingPostSponsoredPercentage?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostViewsMedian?: GraphQLTypes['ModelIntInput'] | undefined
    instagramUserId?: GraphQLTypes['ModelStringInput'] | undefined
    instagramVerificationMethod?: GraphQLTypes['ModelSocialVerificationMethodInput'] | undefined
    internalGender?: GraphQLTypes['ModelUserGenderInput'] | undefined
    isBrandSafe?: GraphQLTypes['ModelBrandSafeInput'] | undefined
    isBrandSafeReason?: GraphQLTypes['ModelStringInput'] | undefined
    isBrandSafeUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    lastActiveAt?: GraphQLTypes['ModelStringInput'] | undefined
    lastName?: GraphQLTypes['ModelStringInput'] | undefined
    manualScoringLockedUntil?: GraphQLTypes['ModelStringInput'] | undefined
    moreinfo?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserConditionInput'] | undefined
    numEvenDollarPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    numPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserConditionInput'] | undefined> | undefined
    paypalEmail?: GraphQLTypes['ModelStringInput'] | undefined
    phoneNumber?: GraphQLTypes['ModelStringInput'] | undefined
    profilePicture?: GraphQLTypes['ModelStringInput'] | undefined
    pushOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    referralCode?: GraphQLTypes['ModelStringInput'] | undefined
    referralCount?: GraphQLTypes['ModelIntInput'] | undefined
    referrer?: GraphQLTypes['ModelStringInput'] | undefined
    signupCountry?: GraphQLTypes['ModelStringInput'] | undefined
    smsOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    socialQuality?: GraphQLTypes['ModelSocialQualityInput'] | undefined
    statusTier?: GraphQLTypes['ModelStatusTierInput'] | undefined
    statusTierLastUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    supportPriorityAcknowledgedAt?: GraphQLTypes['ModelStringInput'] | undefined
    supportPriorityFlag?: GraphQLTypes['ModelSupportPriorityFlagInput'] | undefined
    supportPriorityFlagSetAt?: GraphQLTypes['ModelStringInput'] | undefined
    targetAccount?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokAccessToken?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokAccessTokenExpiry?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokAccountId?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokAveragePostEngagementRate?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokAveragePostEngagementRateViews?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokBio?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokContentTypeRating?: GraphQLTypes['ModelContentTypeRatingListInput'] | undefined
    tiktokFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokHandle?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokMedianComments?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokMedianLikes?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokMedianPostEngagement?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokMedianPostEngagementRate?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokMedianPostEngagementRateViews?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokMedianShares?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokMedianViews?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokMetricsRetrievedAt?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokName?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokPermissionScopes?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokPriority?: GraphQLTypes['ModelPlatformPriorityInput'] | undefined
    tiktokProfilePic?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokRefreshToken?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokRefreshTokenExpiry?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokSponsoredPostPercentage?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokVerificationMethod?: GraphQLTypes['ModelSocialVerificationMethodInput'] | undefined
    tiktokVerified?: GraphQLTypes['ModelStringInput'] | undefined
    timeZone?: GraphQLTypes['ModelStringInput'] | undefined
    trackingStatus?: GraphQLTypes['ModelTrackingStatusInput'] | undefined
    twitchAccount?: GraphQLTypes['ModelStringInput'] | undefined
    type?: GraphQLTypes['ModelStringInput'] | undefined
    wmartAccount?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeAccessToken?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeAccessTokenExpiry?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeAccount?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeAveragePostEngagementRate?: GraphQLTypes['ModelFloatInput'] | undefined
    youtubeChannelDescription?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeChannelFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    youtubeChannelHandle?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeChannelId?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeChannelPicture?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeChannelTitle?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeIdToken?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeMedianViews?: GraphQLTypes['ModelIntInput'] | undefined
    youtubeMetricsRetrievedAt?: GraphQLTypes['ModelStringInput'] | undefined
    youtubePermissionScopes?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeRefreshToken?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeVerificationMethod?: GraphQLTypes['ModelSocialVerificationMethodInput'] | undefined
  }
  ['ModelUserDeviceSnapshotConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserDeviceSnapshotConditionInput'] | undefined> | undefined
    idfv?: GraphQLTypes['ModelStringInput'] | undefined
    ipAddress?: GraphQLTypes['ModelStringInput'] | undefined
    isEmulator?: GraphQLTypes['ModelBooleanInput'] | undefined
    not?: GraphQLTypes['ModelUserDeviceSnapshotConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserDeviceSnapshotConditionInput'] | undefined> | undefined
    snapshotAt?: GraphQLTypes['ModelStringInput'] | undefined
    timezone?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserDeviceSnapshotFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserDeviceSnapshotFilterInput'] | undefined> | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    idfv?: GraphQLTypes['ModelStringInput'] | undefined
    ipAddress?: GraphQLTypes['ModelStringInput'] | undefined
    isEmulator?: GraphQLTypes['ModelBooleanInput'] | undefined
    not?: GraphQLTypes['ModelUserDeviceSnapshotFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserDeviceSnapshotFilterInput'] | undefined> | undefined
    snapshotAt?: GraphQLTypes['ModelStringInput'] | undefined
    timezone?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserFilterGenderInput']: {
    eq?: GraphQLTypes['UserFilterGender'] | undefined
    ne?: GraphQLTypes['UserFilterGender'] | undefined
  }
  ['ModelUserFilterInput']: {
    active?: GraphQLTypes['ModelActiveInput'] | undefined
    addressComponents?: GraphQLTypes['ModelStringInput'] | undefined
    amznLastChecked?: GraphQLTypes['ModelStringInput'] | undefined
    amznLink?: GraphQLTypes['ModelStringInput'] | undefined
    amznPrime?: GraphQLTypes['ModelStringInput'] | undefined
    amznProfileId?: GraphQLTypes['ModelStringInput'] | undefined
    amznReviewerId?: GraphQLTypes['ModelStringInput'] | undefined
    amznVerified?: GraphQLTypes['ModelStringInput'] | undefined
    and?: Array<GraphQLTypes['ModelUserFilterInput'] | undefined> | undefined
    attributedReviewCount?: GraphQLTypes['ModelIntInput'] | undefined
    blogWebsite?: GraphQLTypes['ModelStringInput'] | undefined
    brandContentRatingAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    campaignAcceptanceRate?: GraphQLTypes['ModelIntInput'] | undefined
    campaignApprovalRate?: GraphQLTypes['ModelIntInput'] | undefined
    campaignCompletionRate?: GraphQLTypes['ModelIntInput'] | undefined
    campaignReviewRate?: GraphQLTypes['ModelIntInput'] | undefined
    cardProfileUpdates?: GraphQLTypes['ModelStringInput'] | undefined
    cardholderId?: GraphQLTypes['ModelStringInput'] | undefined
    cardholderRegion?: GraphQLTypes['ModelStripeRegionInput'] | undefined
    characteristicsUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    claimedReviewCount?: GraphQLTypes['ModelIntInput'] | undefined
    collisionSkuSet?: GraphQLTypes['ModelStringInput'] | undefined
    collisionSkuSetCalculatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    completedLifestyleCampaignCount?: GraphQLTypes['ModelIntInput'] | undefined
    completedLiftCampaignCount?: GraphQLTypes['ModelIntInput'] | undefined
    completedSocialCampaignCount?: GraphQLTypes['ModelIntInput'] | undefined
    contentRatingCompletedAt?: GraphQLTypes['ModelStringInput'] | undefined
    contentRatingCompletionTimeMillis?: GraphQLTypes['ModelIntInput'] | undefined
    contentRatingInstagramCompletionTimeMillis?: GraphQLTypes['ModelIntInput'] | undefined
    contentRatingQualityScore?: GraphQLTypes['ModelIntInput'] | undefined
    contentRatingReviewStatus?: GraphQLTypes['ModelContentRatingReviewStatusInput'] | undefined
    contentRatingSubmittedBy?: GraphQLTypes['ModelStringInput'] | undefined
    contentRatingTiktokCompletionTimeMillis?: GraphQLTypes['ModelIntInput'] | undefined
    contentTypes?: GraphQLTypes['ModelStringInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    date_of_birth?: GraphQLTypes['ModelStringInput'] | undefined
    deactivationDate?: GraphQLTypes['ModelStringInput'] | undefined
    deactivationReason?: GraphQLTypes['ModelStringInput'] | undefined
    declineToReviewCount?: GraphQLTypes['ModelIntInput'] | undefined
    deranked?: GraphQLTypes['ModelDeranked_ReasonInput'] | undefined
    email?: GraphQLTypes['ModelStringInput'] | undefined
    emailOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    facebookAccessToken?: GraphQLTypes['ModelStringInput'] | undefined
    facebookAccessTokenExpiry?: GraphQLTypes['ModelStringInput'] | undefined
    facebookPageId?: GraphQLTypes['ModelStringInput'] | undefined
    facebookPageIsSubscribed?: GraphQLTypes['ModelBooleanInput'] | undefined
    facebookPermissionScopes?: GraphQLTypes['ModelStringInput'] | undefined
    facebookUserId?: GraphQLTypes['ModelStringInput'] | undefined
    firstName?: GraphQLTypes['ModelStringInput'] | undefined
    followsWesponsored?: GraphQLTypes['ModelStringInput'] | undefined
    fraudReason?: GraphQLTypes['ModelFraudReasonInput'] | undefined
    fraudReviewAt?: GraphQLTypes['ModelStringInput'] | undefined
    fraudReviewStatus?: GraphQLTypes['ModelFraudReviewStatusInput'] | undefined
    gender?: GraphQLTypes['ModelStringInput'] | undefined
    hasTrueEngagement?: GraphQLTypes['ModelBooleanInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    idfaId?: GraphQLTypes['ModelStringInput'] | undefined
    incompleteCampaignCount?: GraphQLTypes['ModelIntInput'] | undefined
    instagramBio?: GraphQLTypes['ModelStringInput'] | undefined
    instagramBusinessCategoryName?: GraphQLTypes['ModelStringInput'] | undefined
    instagramCategoryName?: GraphQLTypes['ModelStringInput'] | undefined
    instagramChecked?: GraphQLTypes['ModelStringInput'] | undefined
    instagramContentTypeRating?: GraphQLTypes['ModelContentTypeRatingListInput'] | undefined
    instagramExternalLink?: GraphQLTypes['ModelStringInput'] | undefined
    instagramFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    instagramHandle?: GraphQLTypes['ModelStringInput'] | undefined
    instagramIsBusiness?: GraphQLTypes['ModelStringInput'] | undefined
    instagramIsPrivate?: GraphQLTypes['ModelStringInput'] | undefined
    instagramIsRecentlyJoined?: GraphQLTypes['ModelStringInput'] | undefined
    instagramIsVerified?: GraphQLTypes['ModelStringInput'] | undefined
    instagramLastChecked?: GraphQLTypes['ModelStringInput'] | undefined
    instagramMedianShares?: GraphQLTypes['ModelIntInput'] | undefined
    instagramMetricsRetrievedAt?: GraphQLTypes['ModelStringInput'] | undefined
    instagramName?: GraphQLTypes['ModelStringInput'] | undefined
    instagramPostsInt?: GraphQLTypes['ModelIntInput'] | undefined
    instagramPriority?: GraphQLTypes['ModelPlatformPriorityInput'] | undefined
    instagramProfilePic?: GraphQLTypes['ModelStringInput'] | undefined
    instagramTrailingComments?: GraphQLTypes['ModelIntInput'] | undefined
    instagramTrailingDateOfOldestPost?: GraphQLTypes['ModelStringInput'] | undefined
    instagramTrailingLikes?: GraphQLTypes['ModelIntInput'] | undefined
    instagramTrailingNumberOfPosts?: GraphQLTypes['ModelIntInput'] | undefined
    instagramTrailingPostEngagementAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostEngagementRateMedian?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostImpressionsAvg?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostImpressionsMedian?: GraphQLTypes['ModelIntInput'] | undefined
    instagramTrailingPostSponsoredPercentage?: GraphQLTypes['ModelFloatInput'] | undefined
    instagramTrailingPostViewsMedian?: GraphQLTypes['ModelIntInput'] | undefined
    instagramUserId?: GraphQLTypes['ModelStringInput'] | undefined
    instagramVerificationMethod?: GraphQLTypes['ModelSocialVerificationMethodInput'] | undefined
    internalGender?: GraphQLTypes['ModelUserGenderInput'] | undefined
    isBrandSafe?: GraphQLTypes['ModelBrandSafeInput'] | undefined
    isBrandSafeReason?: GraphQLTypes['ModelStringInput'] | undefined
    isBrandSafeUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    lastActiveAt?: GraphQLTypes['ModelStringInput'] | undefined
    lastName?: GraphQLTypes['ModelStringInput'] | undefined
    manualScoringLockedUntil?: GraphQLTypes['ModelStringInput'] | undefined
    moreinfo?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserFilterInput'] | undefined
    numEvenDollarPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    numPurchases?: GraphQLTypes['ModelIntInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserFilterInput'] | undefined> | undefined
    paypalEmail?: GraphQLTypes['ModelStringInput'] | undefined
    phoneNumber?: GraphQLTypes['ModelStringInput'] | undefined
    profilePicture?: GraphQLTypes['ModelStringInput'] | undefined
    pushOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    referralCode?: GraphQLTypes['ModelStringInput'] | undefined
    referralCount?: GraphQLTypes['ModelIntInput'] | undefined
    referrer?: GraphQLTypes['ModelStringInput'] | undefined
    signupCountry?: GraphQLTypes['ModelStringInput'] | undefined
    smsOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    socialQuality?: GraphQLTypes['ModelSocialQualityInput'] | undefined
    statusTier?: GraphQLTypes['ModelStatusTierInput'] | undefined
    statusTierLastUpdatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    supportPriorityAcknowledgedAt?: GraphQLTypes['ModelStringInput'] | undefined
    supportPriorityFlag?: GraphQLTypes['ModelSupportPriorityFlagInput'] | undefined
    supportPriorityFlagSetAt?: GraphQLTypes['ModelStringInput'] | undefined
    targetAccount?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokAccessToken?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokAccessTokenExpiry?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokAccountId?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokAveragePostEngagementRate?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokAveragePostEngagementRateViews?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokBio?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokContentTypeRating?: GraphQLTypes['ModelContentTypeRatingListInput'] | undefined
    tiktokFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokHandle?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokMedianComments?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokMedianLikes?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokMedianPostEngagement?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokMedianPostEngagementRate?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokMedianPostEngagementRateViews?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokMedianShares?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokMedianViews?: GraphQLTypes['ModelIntInput'] | undefined
    tiktokMetricsRetrievedAt?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokName?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokPermissionScopes?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokPriority?: GraphQLTypes['ModelPlatformPriorityInput'] | undefined
    tiktokProfilePic?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokRefreshToken?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokRefreshTokenExpiry?: GraphQLTypes['ModelStringInput'] | undefined
    tiktokSponsoredPostPercentage?: GraphQLTypes['ModelFloatInput'] | undefined
    tiktokVerificationMethod?: GraphQLTypes['ModelSocialVerificationMethodInput'] | undefined
    tiktokVerified?: GraphQLTypes['ModelStringInput'] | undefined
    timeZone?: GraphQLTypes['ModelStringInput'] | undefined
    trackingStatus?: GraphQLTypes['ModelTrackingStatusInput'] | undefined
    twitchAccount?: GraphQLTypes['ModelStringInput'] | undefined
    type?: GraphQLTypes['ModelStringInput'] | undefined
    wmartAccount?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeAccessToken?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeAccessTokenExpiry?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeAccount?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeAveragePostEngagementRate?: GraphQLTypes['ModelFloatInput'] | undefined
    youtubeChannelDescription?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeChannelFollowersInt?: GraphQLTypes['ModelIntInput'] | undefined
    youtubeChannelHandle?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeChannelId?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeChannelPicture?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeChannelTitle?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeIdToken?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeMedianViews?: GraphQLTypes['ModelIntInput'] | undefined
    youtubeMetricsRetrievedAt?: GraphQLTypes['ModelStringInput'] | undefined
    youtubePermissionScopes?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeRefreshToken?: GraphQLTypes['ModelStringInput'] | undefined
    youtubeVerificationMethod?: GraphQLTypes['ModelSocialVerificationMethodInput'] | undefined
  }
  ['ModelUserFollowerConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserFollowerConditionInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    isFavorite?: GraphQLTypes['ModelFavoriteFlagInput'] | undefined
    not?: GraphQLTypes['ModelUserFollowerConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserFollowerConditionInput'] | undefined> | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserFollowerFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserFollowerFilterInput'] | undefined> | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    followerUserId?: GraphQLTypes['ModelIDInput'] | undefined
    isFavorite?: GraphQLTypes['ModelFavoriteFlagInput'] | undefined
    not?: GraphQLTypes['ModelUserFollowerFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserFollowerFilterInput'] | undefined> | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserGenderInput']: {
    eq?: GraphQLTypes['UserGender'] | undefined
    ne?: GraphQLTypes['UserGender'] | undefined
  }
  ['ModelUserLinkConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserLinkConditionInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    linkId?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserLinkConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserLinkConditionInput'] | undefined> | undefined
    originalLink?: GraphQLTypes['ModelStringInput'] | undefined
    rank?: GraphQLTypes['ModelIntInput'] | undefined
    storageType?: GraphQLTypes['ModelLinkStorageTypeInput'] | undefined
    uniqueClicks?: GraphQLTypes['ModelIntInput'] | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userEncodedLink?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserLinkFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserLinkFilterInput'] | undefined> | undefined
    campaignId?: GraphQLTypes['ModelIDInput'] | undefined
    createdAt?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    linkId?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserLinkFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserLinkFilterInput'] | undefined> | undefined
    originalLink?: GraphQLTypes['ModelStringInput'] | undefined
    rank?: GraphQLTypes['ModelIntInput'] | undefined
    storageType?: GraphQLTypes['ModelLinkStorageTypeInput'] | undefined
    uniqueClicks?: GraphQLTypes['ModelIntInput'] | undefined
    updatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    userEncodedLink?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserNotificationPreferenceConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserNotificationPreferenceConditionInput'] | undefined> | undefined
    emailOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    not?: GraphQLTypes['ModelUserNotificationPreferenceConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserNotificationPreferenceConditionInput'] | undefined> | undefined
    pushOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    smsOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
  }
  ['ModelUserNotificationPreferenceFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserNotificationPreferenceFilterInput'] | undefined> | undefined
    category?: GraphQLTypes['ModelStringInput'] | undefined
    emailOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    not?: GraphQLTypes['ModelUserNotificationPreferenceFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserNotificationPreferenceFilterInput'] | undefined> | undefined
    pushOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    smsOptOut?: GraphQLTypes['ModelBooleanInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelUserPayoutRequestConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserPayoutRequestConditionInput'] | undefined> | undefined
    failureReason?: GraphQLTypes['ModelStringInput'] | undefined
    manuallyApprovedBy?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserPayoutRequestConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserPayoutRequestConditionInput'] | undefined> | undefined
    payoutAmountCents?: GraphQLTypes['ModelIntInput'] | undefined
    payoutBatchId?: GraphQLTypes['ModelStringInput'] | undefined
    payoutDate?: GraphQLTypes['ModelStringInput'] | undefined
    payoutLocale?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    payoutState?: GraphQLTypes['ModelBonusPayoutStateInput'] | undefined
  }
  ['ModelUserPayoutRequestFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserPayoutRequestFilterInput'] | undefined> | undefined
    failureReason?: GraphQLTypes['ModelStringInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    manuallyApprovedBy?: GraphQLTypes['ModelStringInput'] | undefined
    not?: GraphQLTypes['ModelUserPayoutRequestFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserPayoutRequestFilterInput'] | undefined> | undefined
    payoutAmountCents?: GraphQLTypes['ModelIntInput'] | undefined
    payoutBatchId?: GraphQLTypes['ModelStringInput'] | undefined
    payoutDate?: GraphQLTypes['ModelStringInput'] | undefined
    payoutLocale?: GraphQLTypes['ModelWSLocaleInput'] | undefined
    payoutState?: GraphQLTypes['ModelBonusPayoutStateInput'] | undefined
    userId?: GraphQLTypes['ModelStringInput'] | undefined
  }
  ['ModelUserStagingApprovalConditionInput']: {
    and?: Array<GraphQLTypes['ModelUserStagingApprovalConditionInput'] | undefined> | undefined
    not?: GraphQLTypes['ModelUserStagingApprovalConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserStagingApprovalConditionInput'] | undefined> | undefined
    reviewFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedAt?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedBy?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedByEmail?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedByName?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedBySource?: GraphQLTypes['ModelUserStagingApprovalReviewSourceInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    userStagingApprovalOutcome?: GraphQLTypes['ModelUserStagingApprovalOutcomeInput'] | undefined
    userStagingApprovalType?: GraphQLTypes['ModelUserStagingApprovalTypeInput'] | undefined
  }
  ['ModelUserStagingApprovalFilterInput']: {
    and?: Array<GraphQLTypes['ModelUserStagingApprovalFilterInput'] | undefined> | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelUserStagingApprovalFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelUserStagingApprovalFilterInput'] | undefined> | undefined
    reviewFeedback?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedAt?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedBy?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedByEmail?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedByName?: GraphQLTypes['ModelStringInput'] | undefined
    reviewedBySource?: GraphQLTypes['ModelUserStagingApprovalReviewSourceInput'] | undefined
    userCampaignId?: GraphQLTypes['ModelIDInput'] | undefined
    userStagingApprovalOutcome?: GraphQLTypes['ModelUserStagingApprovalOutcomeInput'] | undefined
    userStagingApprovalType?: GraphQLTypes['ModelUserStagingApprovalTypeInput'] | undefined
  }
  ['ModelUserStagingApprovalOutcomeInput']: {
    eq?: GraphQLTypes['UserStagingApprovalOutcome'] | undefined
    ne?: GraphQLTypes['UserStagingApprovalOutcome'] | undefined
  }
  ['ModelUserStagingApprovalReviewSourceInput']: {
    eq?: GraphQLTypes['UserStagingApprovalReviewSource'] | undefined
    ne?: GraphQLTypes['UserStagingApprovalReviewSource'] | undefined
  }
  ['ModelUserStagingApprovalTypeInput']: {
    eq?: GraphQLTypes['UserStagingApprovalType'] | undefined
    ne?: GraphQLTypes['UserStagingApprovalType'] | undefined
  }
  ['ModelValidMccCodesInput']: {
    eq?: GraphQLTypes['ValidMccCodes'] | undefined
    ne?: GraphQLTypes['ValidMccCodes'] | undefined
  }
  ['ModelVideoContentScanConditionInput']: {
    and?: Array<GraphQLTypes['ModelVideoContentScanConditionInput'] | undefined> | undefined
    audioDuration?: GraphQLTypes['ModelIntInput'] | undefined
    audioEndAt?: GraphQLTypes['ModelIntInput'] | undefined
    audioStartFrom?: GraphQLTypes['ModelIntInput'] | undefined
    not?: GraphQLTypes['ModelVideoContentScanConditionInput'] | undefined
    or?: Array<GraphQLTypes['ModelVideoContentScanConditionInput'] | undefined> | undefined
    processFinishedAt?: GraphQLTypes['ModelStringInput'] | undefined
    processId?: GraphQLTypes['ModelStringInput'] | undefined
    processInitiatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    scanStatus?: GraphQLTypes['ModelContentScanStatusInput'] | undefined
    socialMediaUrl?: GraphQLTypes['ModelStringInput'] | undefined
    transcription?: GraphQLTypes['ModelStringInput'] | undefined
    transcriptionConfidence?: GraphQLTypes['ModelFloatInput'] | undefined
    url?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelVideoContentScanFilterInput']: {
    and?: Array<GraphQLTypes['ModelVideoContentScanFilterInput'] | undefined> | undefined
    audioDuration?: GraphQLTypes['ModelIntInput'] | undefined
    audioEndAt?: GraphQLTypes['ModelIntInput'] | undefined
    audioStartFrom?: GraphQLTypes['ModelIntInput'] | undefined
    id?: GraphQLTypes['ModelIDInput'] | undefined
    not?: GraphQLTypes['ModelVideoContentScanFilterInput'] | undefined
    or?: Array<GraphQLTypes['ModelVideoContentScanFilterInput'] | undefined> | undefined
    processFinishedAt?: GraphQLTypes['ModelStringInput'] | undefined
    processId?: GraphQLTypes['ModelStringInput'] | undefined
    processInitiatedAt?: GraphQLTypes['ModelStringInput'] | undefined
    scanStatus?: GraphQLTypes['ModelContentScanStatusInput'] | undefined
    socialMediaUrl?: GraphQLTypes['ModelStringInput'] | undefined
    transcription?: GraphQLTypes['ModelStringInput'] | undefined
    transcriptionConfidence?: GraphQLTypes['ModelFloatInput'] | undefined
    url?: GraphQLTypes['ModelStringInput'] | undefined
    userId?: GraphQLTypes['ModelIDInput'] | undefined
  }
  ['ModelWSLocaleInput']: {
    eq?: GraphQLTypes['WSLocale'] | undefined
    ne?: GraphQLTypes['WSLocale'] | undefined
  }
  ['OccurrenceConstraintInput']: {
    period?: GraphQLTypes['OccurrenceConstraintPeriod'] | undefined
    type: GraphQLTypes['OccurrenceConstraintType']
    value?: number | undefined
  }
  ['PayForImpressionsBonusConfigInput']: {
    enabled?: boolean | undefined
    growthRate?: number | undefined
    impressionsBaseline?: number | undefined
    impressionsCap?: number | undefined
    impressionsIncrement?: number | undefined
    initialBonus?: number | undefined
  }
  ['PayForPostingContentEarlyConfigInput']: {
    bonusCents?: number | undefined
    daysBeforeDeadline?: number | undefined
    enabled?: boolean | undefined
  }
  ['ProductSocialContentInput']: {
    bonus: number
    brandContentGuidance?: Array<GraphQLTypes['ContentGuidanceInput']> | undefined
    brandContentRequirements?: Array<string> | undefined
    bronzeBonus?: number | undefined
    channel: GraphQLTypes['SocialChannel']
    diamondBonus?: number | undefined
    goldBonus?: number | undefined
    inspirationalContent?: Array<GraphQLTypes['InspirationalContentInput']> | undefined
    instances: number
    platinumBonus?: number | undefined
    requirementLevel: GraphQLTypes['ContentRequirementLevel']
    requiresManualApproval?: boolean | undefined
    silverBonus?: number | undefined
    talkingPoints?: Array<GraphQLTypes['ContentGuidanceInput']> | undefined
    totalExpectedCount?: number | undefined
    type: GraphQLTypes['SocialContentType']
  }
  ['ProspectSearchFields']: {
    sourcingSource?: GraphQLTypes['SearchInputString'] | undefined
  }
  ['PushAddressInput']: {
    address?: string | undefined
    pushChannel?: GraphQLTypes['PushChannel'] | undefined
  }
  ['PushChannelConfigurationInput']: {
    attributes?: Array<GraphQLTypes['KeyAttributeInput'] | undefined> | undefined
    enabled?: boolean | undefined
    templateId: string
  }
  ['RankPointInput']: {
    rank: number
    rankAt: string
  }
  ['RateInput']: {
    count?: number | undefined
    isIntegrationFallbackRate?: boolean | undefined
    period?: GraphQLTypes['Period'] | undefined
  }
  ['RequestedContentChangesInput']: {
    message?: string | undefined
    rating?: number | undefined
  }
  ['RollbackAdditionalArguments']: {
    removeApprovals?: boolean | undefined
    removeBonuses?: boolean | undefined
    removeCard?: boolean | undefined
    removeContent?: boolean | undefined
    removeKeyword?: boolean | undefined
    resetBonusConfig?: boolean | undefined
  }
  ['SearchInputBoolean']: {
    match?: boolean | undefined
  }
  ['SearchInputNumber']: {
    field?: GraphQLTypes['SearchInputField'] | undefined
    match?: number | undefined
    not?: boolean | undefined
    range?: GraphQLTypes['SearchInputNumberRange'] | undefined
  }
  ['SearchInputNumberRange']: {
    gt?: number | undefined
    gte?: number | undefined
    lt?: number | undefined
    lte?: number | undefined
  }
  ['SearchInputQueryScript']: {
    params?: GraphQLTypes['SearchInputScriptParams'] | undefined
    source: GraphQLTypes['SearchInputQueryScriptSource']
  }
  ['SearchInputScriptParams']: {
    fieldNames?: Array<string> | undefined
    max?: number | undefined
    min?: number | undefined
  }
  ['SearchInputSortScript']: {
    params?: GraphQLTypes['SearchInputScriptParams'] | undefined
    source: GraphQLTypes['SearchInputSortScriptSource']
    type: GraphQLTypes['SearchInputScriptType']
  }
  ['SearchInputString']: {
    field?: GraphQLTypes['SearchInputField'] | undefined
    match?: string | undefined
    match_phrase_prefix?: string | undefined
    not?: boolean | undefined
    prefix?: string | undefined
    range?: GraphQLTypes['SearchInputStringRange'] | undefined
    regexp?: string | undefined
    wildcard?: string | undefined
  }
  ['SearchInputStringRange']: {
    gt?: string | undefined
    gte?: string | undefined
    lt?: string | undefined
    lte?: string | undefined
  }
  ['SequenceFormSubmissionValuesInput']: {
    componentId: string
    componentName: string
    componentType?: GraphQLTypes['FormComponentType'] | undefined
    sequenceId: string
    type?: GraphQLTypes['FormFieldType'] | undefined
    value: string
  }
  ['SkuAttributeInput']: {
    name: string
    value: string
  }
  ['SocialMediaFeedbackInput']: {
    comments?: string | undefined
    rating?: number | undefined
  }
  ['SocialMetricInput']: {
    name: GraphQLTypes['SocialMetricName']
    value: number
  }
  ['StagingApprovalConfigurationInput']: {
    stagingApprovalAssignee?: string | undefined
    stagingApprovalType: GraphQLTypes['UserStagingApprovalType']
  }
  ['StatusTierBonusConfigInput']: {
    baseBonus?: number | undefined
    diamondBonus?: number | undefined
    goldBonus?: number | undefined
    platinumBonus?: number | undefined
    silverBonus?: number | undefined
  }
  ['StatusTierEntryInput']: {
    statusTier: GraphQLTypes['StatusTier']
    statusTierAt: string
  }
  ['SubmitUserCampaignStateChangeInput']: {
    bonusRequest?: GraphQLTypes['BonusRequestTypes'] | undefined
    campaignStatus?: GraphQLTypes['CampaignStatus'] | undefined
    defect?: boolean | undefined
    id: string
    requestedByUserId?: string | undefined
    requestedUpdates?: string | undefined
    stateChangeRequestType?: GraphQLTypes['UserCampaignStateChangeRequestType'] | undefined
  }
  ['UpdateAutoBuyDistributionInput']: {
    createdAt?: string | undefined
    description?: string | undefined
    id: string
    name?: string | undefined
    schedule?: Array<number> | undefined
    updatedAt?: string | undefined
  }
  ['UpdateAutoBuyFlowConfigurationInput']: {
    dailyPurchaseMax?: number | undefined
    delayStrategy?: GraphQLTypes['AutoBuyDelayStrategy'] | undefined
    flowId: string
    insufficientCreators?: string | undefined
    latestSkipReason?: GraphQLTypes['AutoBuySkipReason'] | undefined
    pauseReason?: GraphQLTypes['AutoBuySkipReason'] | undefined
    schedule?: Array<GraphQLTypes['AutoBuyScheduleInput']> | undefined
    startDate?: string | undefined
    status?: GraphQLTypes['AutoBuyStatus'] | undefined
  }
  ['UpdateAutoBuyFlowReleaseScheduleInput']: {
    actualPurchases?: number | undefined
    actualVerifiedPurchases?: number | undefined
    adjustedDesiredPurchases?: number | undefined
    autoApprovalSeats?: number | undefined
    date: string
    desiredPurchases?: number | undefined
    flowId: string
    manualPurchases?: number | undefined
    manualReleases?: number | undefined
    projectedReleaseCount?: number | undefined
    releaseCount?: number | undefined
    released?: boolean | undefined
    skippedReason?: GraphQLTypes['AutoBuySkipReason'] | undefined
  }
  ['UpdateAutoBuyReleaseInput']: {
    date: string
    productId?: string | undefined
    releaseDateTime?: string | undefined
    skuId?: string | undefined
    userCampaignId: string
  }
  ['UpdateBrandApiInput']: {
    accessToken?: string | undefined
    api: GraphQLTypes['BrandApiType']
    apiVersion?: GraphQLTypes['BrandApiVersion'] | undefined
    brandId: string
    enabled?: boolean | undefined
    locale?: GraphQLTypes['WSLocale'] | undefined
    refreshToken?: string | undefined
    shop?: string | undefined
    shopId?: string | undefined
  }
  ['UpdateBrandInput']: {
    active?: string | undefined
    amazonBrandName?: string | undefined
    brandDetailsSubmitted?: boolean | undefined
    brandInstaHandle?: string | undefined
    brandLogoImageKey?: string | undefined
    brandStoreConfirmationImage?: string | undefined
    brandStoreConfirmationMobileImage?: string | undefined
    brandStoreScrapeUrl?: string | undefined
    brandTikTokHandle?: string | undefined
    companyId?: string | undefined
    contactEmails?: Array<string | undefined> | undefined
    defaultCreatorPreference?: GraphQLTypes['CreatorPreference'] | undefined
    defaultCreatorPreferenceDetails?: string | undefined
    defaultHashtags?: Array<string> | undefined
    defaultMarketingBriefKey?: string | undefined
    defaultPostingGuidelines?: Array<string> | undefined
    defaultPostingInspirationLinks?: Array<string> | undefined
    emailsSent?: Array<string | undefined> | undefined
    fullScrapedAt?: string | undefined
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined
    isSeller?: string | undefined
    isVendor?: string | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    lastScrapedAt?: string | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    paymentBillingContact?: GraphQLTypes['BillingContactInput'] | undefined
    platform?: GraphQLTypes['PlatformSource'] | undefined
    postingGuidanceDocument?: string | undefined
    region?: GraphQLTypes['WSLocale'] | undefined
    socialMediaExampleUrls?: Array<string> | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    walmartBrandName?: string | undefined
    walmartLastScrapedAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['UpdateCampaignClicksInput']: {
    campaignId: string
    clicks?: number | undefined
    date: string
  }
  ['UpdateCampaignInput']: {
    aboutSection?: string | undefined
    active?: string | undefined
    actualStartDate?: string | undefined
    amountPaidByClient?: number | undefined
    anticipatedStartDate?: string | undefined
    applicationForm?: string | undefined
    applicationProductLinkId?: string | undefined
    applicationStatus?: GraphQLTypes['ApplicationStatus'] | undefined
    attributionRefUrlSuffix?: string | undefined
    authorizedUnits?: number | undefined
    badges?: Array<GraphQLTypes['CampaignBadgeInput'] | undefined> | undefined
    brandId?: string | undefined
    brandProductShort?: string | undefined
    brandStoreLink?: string | undefined
    budgetSpent?: number | undefined
    campaignProductType?: GraphQLTypes['CampaignProductType'] | undefined
    campaignReferralBonusAmount?: number | undefined
    campaignReferralBonusEnabled?: GraphQLTypes['CampaignReferralBonusFlag'] | undefined
    campaignStateMachine?: string | undefined
    closeoutReleaseFailureReason?: string | undefined
    closeoutReleaseRate?: GraphQLTypes['RateInput'] | undefined
    collisionSkuDensity?: Array<GraphQLTypes['CollisionDensityValueInput']> | undefined
    commissionFlatRate?: number | undefined
    commissionPercentageRate?: number | undefined
    committedClicks?: number | undefined
    committedPlacementWinsCount?: number | undefined
    committedReviewsCount?: number | undefined
    contentApprovalRequired?: boolean | undefined
    contentCompleteDeadline?: string | undefined
    createdAt?: string | undefined
    creationSourceVersion?: GraphQLTypes['CreationSourceVersion'] | undefined
    earlyReviewEnabled?: boolean | undefined
    enableContentComplianceReview?: boolean | undefined
    endDate?: string | undefined
    excludeGender?: GraphQLTypes['UserFilterGender'] | undefined
    expectedBonus?: string | undefined
    followersCountCeiling?: number | undefined
    followersCountFloor?: number | undefined
    goal?: GraphQLTypes['CampaignGoal'] | undefined
    id: string
    influencerApprovalEnabled?: GraphQLTypes['InfluencerApprovalFlagV2'] | undefined
    lifestyleCommittedContentCount?: number | undefined
    localeV1?: GraphQLTypes['WSLocale'] | undefined
    marketingFee?: number | undefined
    marketingFormFilled?: boolean | undefined
    newCreatorsOnly?: boolean | undefined
    payForClicksEnabled?: GraphQLTypes['EnabledFlag'] | undefined
    payForClicksGeneratedPerPeriod?: Array<GraphQLTypes['ClicksRecordsInput']> | undefined
    payForClicksMinClicks?: number | undefined
    payForClicksOriginalLink?: string | undefined
    payForClicksTotalClicksGenerated?: number | undefined
    payForPerformanceEnabled?: GraphQLTypes['PayForPerformanceFlag'] | undefined
    postingGuidance?: string | undefined
    preApplicationProductImage?: string | undefined
    preApplicationProductPriceRange?: string | undefined
    preApplicationUgcProductImage?: string | undefined
    preLaunchDate?: string | undefined
    premiumLifestyleCommittedContentCount?: number | undefined
    requiredProductLinksEnabled?: GraphQLTypes['RequiredProductLinkFlag'] | undefined
    reviewVotingTarget?: string | undefined
    sample?: boolean | undefined
    shellCampaignBase?: string | undefined
    skuId?: string | undefined
    skuPriceOverride?: number | undefined
    socialCommittedContentCount?: number | undefined
    sourcingPriority?: number | undefined
    sponsorshipInstructions?: string | undefined
    title?: string | undefined
    totalEarlyReviewsAllowed?: number | undefined
    totalProductCost?: number | undefined
    userAutoApproved?: boolean | undefined
    visibleToUser?: string | undefined
  }
  ['UpdateCampaignInviteInput']: {
    campaignId?: string | undefined
    createdAt?: string | undefined
    id: string
    notificationStatus?: GraphQLTypes['NotificationStatus'] | undefined
    priority?: number | undefined
    updatedAt?: string | undefined
    userId?: string | undefined
  }
  ['UpdateCampaignSourcingProspectInput']: {
    bonusConfig?: GraphQLTypes['UserCampaignBonusConfigInput'] | undefined
    campaignId: string
    createdAt?: string | undefined
    createdById?: string | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    internalNotesId?: string | undefined
    name?: string | undefined
    platformInviteSource?: GraphQLTypes['PlatformInviteSource'] | undefined
    platformStatus?: GraphQLTypes['PlatformStatus'] | undefined
    preApprovalStatus?: GraphQLTypes['PreApprovalStatus'] | undefined
    preApprovalStatusById?: string | undefined
    priority?: GraphQLTypes['SourcePriority'] | undefined
    productId?: string | undefined
    sourcingSource?: GraphQLTypes['CampaignSourcingSource'] | undefined
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    userCampaignId?: string | undefined
    userIdentifier: string
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
  }
  ['UpdateChatConversationV2Input']: {
    active?: GraphQLTypes['Active'] | undefined
    adminLastReadMessageIndex?: number | undefined
    adminUnreadCount?: number | undefined
    campaignId?: string | undefined
    chatType: GraphQLTypes['ChatType']
    creatorLastReadMessageIndex?: number | undefined
    creatorUnreadCount?: number | undefined
    lastMessage?: string | undefined
    lastMessageDate?: string | undefined
    lastMessageId?: string | undefined
    lastMessageSource?: GraphQLTypes['ConversationMessageSource'] | undefined
    resourceId: string
    status?: GraphQLTypes['ConversationInternalStatus'] | undefined
    statusUpdatedAt?: string | undefined
    statusUpdatedByFirstName?: string | undefined
    statusUpdatedById?: string | undefined
    statusUpdatedByLastName?: string | undefined
    twilioConversationId?: string | undefined
    userCampaignId?: string | undefined
    userEmail?: string | undefined
    userFirstName?: string | undefined
    userId?: string | undefined
    userLastName?: string | undefined
  }
  ['UpdateCommunicationNotificationInput']: {
    address?: string | undefined
    attributes?: Array<GraphQLTypes['KeyAttributeInput']> | undefined
    campaignId?: string | undefined
    channel?: GraphQLTypes['Channel'] | undefined
    expectedSentAt?: string | undefined
    experimentTreatment?: string | undefined
    id: string
    notificationId?: string | undefined
    sentAt?: string | undefined
    status?: string | undefined
    statusMessage?: string | undefined
    templateId?: string | undefined
    traceId?: string | undefined
    userCampaignId?: string | undefined
    userId?: string | undefined
  }
  ['UpdateCompanyInput']: {
    agencyId?: string | undefined
    id: string
    logo?: string | undefined
    name?: string | undefined
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: GraphQLTypes['PaymentTermsType'] | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['UpdateConnectedAccountInput']: {
    accountId: string
    accountType?: GraphQLTypes['ConnectedAccountType'] | undefined
    createdAt?: string | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['UpdateContentApprovalsInput']: {
    bonusOverride?: number | undefined
    editedCaption?: string | undefined
    nextContentApprovalAssignee?: string | undefined
    rejectionReason?: string | undefined
    requestType: GraphQLTypes['AdminUpdateContentApprovalType']
    requestedContentChanges?: Array<GraphQLTypes['RequestedContentChangesInput']> | undefined
    reviewType: GraphQLTypes['UserCampaignSocialContentReviewType']
    userCampaignSocialContentId: string
  }
  ['UpdateConversationInput']: {
    contactOwnerId?: string | undefined
    lastContactAt?: string | undefined
    status?: GraphQLTypes['ConversationStatus'] | undefined
    statusChangedAt?: string | undefined
    userId: string
  }
  ['UpdateConversationMessageInput']: {
    channel?: GraphQLTypes['ConversationChannel'] | undefined
    id: string
    messageContent?: string | undefined
    messageId?: string | undefined
    messageMediaKeys?: Array<string> | undefined
    replyFromId?: string | undefined
    sendAt?: string | undefined
    sendAttempts?: number | undefined
    sentAt?: string | undefined
    status?: GraphQLTypes['ConversationMessageStatus'] | undefined
    userId: string
  }
  ['UpdateEtailerSkuMetricsInput']: {
    date: string
    pageViews?: number | undefined
    sku?: string | undefined
    skuId: string
    totalSalesAmount?: number | undefined
    unitsSold?: number | undefined
  }
  ['UpdateFormSequenceConfigurationInput']: {
    active?: GraphQLTypes['Active'] | undefined
    id: string
    sequenceName?: string | undefined
    sequences?: Array<GraphQLTypes['FormSequenceInput']> | undefined
  }
  ['UpdateInstagramStoryScreenInput']: {
    exits?: number | undefined
    expirationUnixTime?: number | undefined
    externalId?: string | undefined
    id: string
    impressions?: number | undefined
    instagramUserId?: string | undefined
    mediaId?: string | undefined
    postedAt?: string | undefined
    postingDate?: string | undefined
    reach?: number | undefined
    replies?: number | undefined
    s3Bucket?: string | undefined
    tapsBack?: number | undefined
    tapsForward?: number | undefined
    userCampaignId?: string | undefined
  }
  ['UpdateMessageInput']: {
    body?: string | undefined
    createdAt?: string | undefined
    id: string
    updatedAt?: string | undefined
    userCampaignId?: string | undefined
    userHasSeen?: boolean | undefined
    userId?: string | undefined
  }
  ['UpdateNoteInput']: {
    createdAt?: string | undefined
    id: string
    note?: string | undefined
    parentId?: string | undefined
    userId?: string | undefined
  }
  ['UpdateNotificationConfigurationInput']: {
    category?: string | undefined
    description?: string | undefined
    email?: GraphQLTypes['ChannelConfigurationInput'] | undefined
    enabled?: boolean | undefined
    id: string
    inAppChatChannel?: GraphQLTypes['MessagingChannelConfigurationInput'] | undefined
    name?: string | undefined
    occurrenceConstraint?: GraphQLTypes['OccurrenceConstraintInput'] | undefined
    push?: GraphQLTypes['PushChannelConfigurationInput'] | undefined
    sms?: GraphQLTypes['ChannelConfigurationInput'] | undefined
  }
  ['UpdateProductBonusConfigSnapshotInput']: {
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<GraphQLTypes['ProductSocialContentInput']> | undefined
    discoveryBonus?: number | undefined
    payForImpressionsBonus?: GraphQLTypes['PayForImpressionsBonusConfigInput'] | undefined
    productId: string
    snapshotAt: string
  }
  ['UpdateProductInput']: {
    accountRequired?: boolean | undefined
    active?: string | undefined
    approvalFilters?: Array<GraphQLTypes['ApprovalFilterValueInput']> | undefined
    autoApprovalSeatsAvailable?: number | undefined
    autoApprovalStatusTiers?: Array<GraphQLTypes['StatusTier']> | undefined
    autoApprovalV2Config?: GraphQLTypes['AutoApprovalV2ConfigInput'] | undefined
    automaticReviewRelease?: boolean | undefined
    baseBonus?: number | undefined
    brandAdvocateCount?: number | undefined
    brandTalkingPoints?: Array<string> | undefined
    buyOnlinePickupInStore?: GraphQLTypes['BOPISValue'] | undefined
    campaignId?: string | undefined
    campaignStateMachine?: string | undefined
    closeoutFormBonus?: number | undefined
    contentApprovalConfiguration?: Array<GraphQLTypes['ContentApprovalConfigurationInput']> | undefined
    contentApprovalLeadDays?: number | undefined
    contentRequirement?: Array<GraphQLTypes['ProductSocialContentInput']> | undefined
    creatorCount?: number | undefined
    creatorPreference?: GraphQLTypes['CreatorPreference'] | undefined
    creatorPreferenceDetails?: string | undefined
    customFtcHashtags?: Array<string> | undefined
    customHashtags?: Array<string> | undefined
    directPurchaseLink?: string | undefined
    engagementRateInstagramRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    engagementRateTiktokRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    followersInstagramRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    followersTiktokRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    hideBrandContentApprovals?: boolean | undefined
    id: string
    impressionsInstagramRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    impressionsTiktokRangeRequirement?: GraphQLTypes['MinMaxRangeInput'] | undefined
    instaHandle?: string | undefined
    internalCreatorsStagingGuidance?: string | undefined
    internalDescription?: string | undefined
    internalName?: string | undefined
    internalNotes?: string | undefined
    isDirectListingPurchase?: boolean | undefined
    isQualityRequirementsEnabled?: boolean | undefined
    listingGuidance?: string | undefined
    maxDiscoveryBonus?: number | undefined
    minimumCreatorQualityScore?: number | undefined
    nonRetailReviewLink?: string | undefined
    nonRetailReviewWebsiteName?: string | undefined
    payForClicksEnabled?: GraphQLTypes['EnabledFlag'] | undefined
    payForClicksMinClicks?: number | undefined
    payForImpressionsBonus?: GraphQLTypes['PayForImpressionsBonusConfigInput'] | undefined
    payForPerformanceBonus?: GraphQLTypes['StatusTierBonusConfigInput'] | undefined
    payForPostingContentEarly?: GraphQLTypes['PayForPostingContentEarlyConfigInput'] | undefined
    postingDeadlineDaysRelativeToApproval?: number | undefined
    productLinkId?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    promoCode?: string | undefined
    promoCodeGuidance?: string | undefined
    promoCodeType?: GraphQLTypes['PromoCodeType'] | undefined
    promoDiscountAmount?: number | undefined
    promoDiscountType?: GraphQLTypes['PromoDiscountType'] | undefined
    promoEndDate?: string | undefined
    promoLink?: string | undefined
    promoStartDate?: string | undefined
    promotionLinkId?: string | undefined
    purchaseByPromoCode?: boolean | undefined
    purchaseDiscountCode?: string | undefined
    purchaseDiscountCodeDetails?: string | undefined
    purchaseDiscountCodePercentage?: string | undefined
    qualityLevel?: GraphQLTypes['ProductQualityLevel'] | undefined
    requestEarlyProductFeedback?: boolean | undefined
    requestProductFeedback?: boolean | undefined
    requiredProductLinkEnabled?: GraphQLTypes['RequiredProductLinkFlag'] | undefined
    sequenceId?: string | undefined
    shopifyPriceRule?: string | undefined
    shopifyPromotionId?: string | undefined
    shopifyPromotionType?: GraphQLTypes['ShopifyPromotionType'] | undefined
    skuId?: string | undefined
    socialMediaExampleUrls?: Array<string> | undefined
    stagingApprovalConfiguration?: Array<GraphQLTypes['StagingApprovalConfigurationInput']> | undefined
    stagingApprovalRequiredNumCreatorsClientReviewed?: number | undefined
    stagingRequirementType?: GraphQLTypes['StagingRequirementType'] | undefined
    stagingSocialQualityRequirement?: GraphQLTypes['SocialQuality'] | undefined
    tikTokHandle?: string | undefined
    unitsPurchased?: number | undefined
    validateProductFoundVariations?: Array<string> | undefined
    visibleAt?: string | undefined
    visibleEndsAt?: string | undefined
  }
  ['UpdateProductKeywordsInput']: {
    adminPriority?: number | undefined
    brandPriority?: number | undefined
    brandResponse?: GraphQLTypes['KeywordAcceptanceResponse'] | undefined
    campaignId?: string | undefined
    creationSource?: GraphQLTypes['ProductKeywordCreationSource'] | undefined
    endDate?: string | undefined
    heliumAccount?: string | undefined
    heliumAsin?: string | undefined
    heliumLink?: string | undefined
    id: string
    keyword?: string | undefined
    monthlySearchVolume?: number | undefined
    rank?: number | undefined
    rankingHistory?: Array<GraphQLTypes['RankPointInput']> | undefined
    rankingLastSkippedAt?: string | undefined
    rankingLastSkippedByUserId?: string | undefined
    rankingLastUpdatedAt?: string | undefined
    rankingLastUpdatedByUserId?: string | undefined
    recommended?: boolean | undefined
    skuId?: string | undefined
    startDate?: string | undefined
    usedForCampaign?: boolean | undefined
    visible?: boolean | undefined
    weight?: number | undefined
  }
  ['UpdateProductRatingSnapshotInput']: {
    numFiveStar?: number | undefined
    numFourStar?: number | undefined
    numOneStar?: number | undefined
    numRatings?: number | undefined
    numReviews?: number | undefined
    numThreeStar?: number | undefined
    numTwoStar?: number | undefined
    rating?: number | undefined
    sku?: string | undefined
    skuId: string
    snapshotAt: string
  }
  ['UpdatePurchaseInput']: {
    amount?: number | undefined
    cardId?: string | undefined
    currency?: string | undefined
    id: string
    isFraud?: boolean | undefined
    keywordAtPurchase?: string | undefined
    purchasedAt?: string | undefined
    shippedAt?: string | undefined
    status?: GraphQLTypes['PurchaseStatus'] | undefined
    userCampaignId?: string | undefined
  }
  ['UpdateReferralInput']: {
    bonusPayoutId?: string | undefined
    campaignId?: string | undefined
    id: string
    referralType?: GraphQLTypes['ReferralType'] | undefined
    referredUserId?: string | undefined
    referrerUserId?: string | undefined
  }
  ['UpdateReviewInput']: {
    attributedUserId?: string | undefined
    author?: string | undefined
    authorBadge?: string | undefined
    badge?: string | undefined
    commentCount?: number | undefined
    helpfulReactions?: number | undefined
    id: string
    negativeReactions?: number | undefined
    photos?: Array<string> | undefined
    productId?: string | undefined
    productVariant?: string | undefined
    rating?: number | undefined
    removed?: boolean | undefined
    reviewedAt?: string | undefined
    reviewedSku?: string | undefined
    reviewerId?: string | undefined
    sku?: string | undefined
    skuId?: string | undefined
    store?: string | undefined
    text?: string | undefined
    title?: string | undefined
    userCampaignId?: string | undefined
    videos?: Array<string> | undefined
  }
  ['UpdateSkuInput']: {
    active?: string | undefined
    approxRetailPrice?: string | undefined
    approxShippingCost?: number | undefined
    attributes?: Array<GraphQLTypes['SkuAttributeInput']> | undefined
    availabilityDate?: string | undefined
    availabilityNotified?: boolean | undefined
    availabilityNotifiedAt?: string | undefined
    brandId?: string | undefined
    brandListingKey?: string | undefined
    brandStoreId?: string | undefined
    bundle?: string | undefined
    createdByUserId?: string | undefined
    creationSource?: GraphQLTypes['SkuCreationSource'] | undefined
    creationSourceUrl?: string | undefined
    currency?: GraphQLTypes['Currency'] | undefined
    currentBuyBoxPrice?: number | undefined
    currentBuyBoxPriceCents?: number | undefined
    estimatedAvailabilityDate?: string | undefined
    estimatedDeliveryDays?: number | undefined
    estimatedMonthlyUnitsSold?: number | undefined
    estimatedWeeklyUnitsSold?: number | undefined
    exactListingTitle?: string | undefined
    exactRetailPrice?: string | undefined
    fullReviewScrapeComplete?: boolean | undefined
    id: string
    inventory?: number | undefined
    iosAppProviderId?: string | undefined
    isAplusPage?: string | undefined
    isPrime?: string | undefined
    isRecommendationsHidden?: boolean | undefined
    itemClassification?: GraphQLTypes['ItemClassification'] | undefined
    lastAutoFilledAt?: string | undefined
    lastKnownBuyBoxPrice?: number | undefined
    lastKnownBuyBoxPriceCents?: number | undefined
    listingBrand?: string | undefined
    listingCategory?: Array<string> | undefined
    listingDescription?: string | undefined
    listingFulfillmentType?: GraphQLTypes['ListingFulfillmentType'] | undefined
    listingGuidance?: string | undefined
    listingLink?: string | undefined
    mccValue?: GraphQLTypes['ValidMccCodes'] | undefined
    minimumOrderQuantity?: number | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    nonSponsoredListingKey?: string | undefined
    parentSkuId?: string | undefined
    priceCents?: number | undefined
    product?: string | undefined
    productImageKey?: string | undefined
    productPostingGuidance?: string | undefined
    productPostingGuidanceDocument?: string | undefined
    productSearchImageKey?: string | undefined
    releaseDate?: string | undefined
    skuId?: string | undefined
    sponsoredListingKey?: string | undefined
    storeName?: string | undefined
    targetReviewRate?: GraphQLTypes['RateInput'] | undefined
    targetReviewRateInvalid?: string | undefined
  }
  ['UpdateSkuSnapshotInput']: {
    inventory?: number | undefined
    marketplaceSku: string
    price?: number | undefined
    skuId?: string | undefined
    snapshotDate: string
  }
  ['UpdateUserBonusPayoutInput']: {
    adminPaymentApproved?: boolean | undefined
    bonusType?: GraphQLTypes['BonusPayoutTypes'] | undefined
    createdAt?: string | undefined
    failureReason?: string | undefined
    id: string
    manuallyCreatedBy?: string | undefined
    payoutAmount?: number | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: GraphQLTypes['WSLocale'] | undefined
    payoutState?: GraphQLTypes['BonusPayoutState'] | undefined
    quantity?: number | undefined
    referenceId?: string | undefined
    releaseAt?: string | undefined
    revocationType?: GraphQLTypes['RevocationType'] | undefined
    revokedAt?: string | undefined
    userCampaignId?: string | undefined
    userId?: string | undefined
  }
  ['UpdateUserCampaignChangelogInput']: {
    createdAt: string
    createdByUserId?: string | undefined
    entryType?: GraphQLTypes['UserCampaignChangelogEntryType'] | undefined
    extraData?: string | undefined
    userCampaignId: string
  }
  ['UpdateUserCampaignInput']: {
    accountScreenShot?: string | undefined
    amznReviewLink?: string | undefined
    amznReviewSubmissionStatus?: GraphQLTypes['AmazonReviewSubmissionStatus'] | undefined
    applicationRejectedByUserId?: string | undefined
    approvalOutcome?: GraphQLTypes['ApprovalOutcome'] | undefined
    approvalStatus?: GraphQLTypes['UserCampaignApprovalStatus'] | undefined
    archivedFeedback?: string | undefined
    archivedReason?: GraphQLTypes['ArchivedReason'] | undefined
    autoBuyApprovalDate?: string | undefined
    autoBuyRevokeDate?: string | undefined
    bestBuyReviewScreenShot?: string | undefined
    bonusConfig?: GraphQLTypes['UserCampaignBonusConfigInput'] | undefined
    campaignAcceptanceStatus?: GraphQLTypes['UserCampaignAcceptanceStatus'] | undefined
    campaignApplication?: string | undefined
    campaignId?: string | undefined
    campaignStatus?: GraphQLTypes['CampaignStatus'] | undefined
    campaignStatusOnArchive?: string | undefined
    cardId?: string | undefined
    cardRegion?: GraphQLTypes['StripeRegion'] | undefined
    closeoutSurvey?: string | undefined
    coarseCampaignStatus?: GraphQLTypes['CoarseCampaignStatus'] | undefined
    contentApprovalLeadDays?: number | undefined
    createdAt?: string | undefined
    discoverySurvey?: string | undefined
    id: string
    influencerApprovalInviteFeedback?: string | undefined
    influencerApprovalRejectFeedback?: string | undefined
    invoiceKey?: string | undefined
    isAutoApproved?: boolean | undefined
    keywordAtPurchase?: string | undefined
    keywordId?: string | undefined
    keywordsV2Hash?: string | undefined
    listingKey?: string | undefined
    localeV1?: GraphQLTypes['WSLocale'] | undefined
    manualPurchase?: boolean | undefined
    moderatorStagingApprovalAt?: string | undefined
    moderatorStagingApprovalBy?: string | undefined
    moderatorStagingApprovalOutcome?: GraphQLTypes['StagingApprovalOutcome'] | undefined
    orderId?: string | undefined
    orderIdInvalidReason?: string | undefined
    orderIdSubmissionDate?: string | undefined
    orderIdValidationDate?: string | undefined
    orderIdValidationStatus?: GraphQLTypes['OrderIdValidationStatus'] | undefined
    orderStatus?: string | undefined
    payForClickLinkId?: string | undefined
    paypalEmailAtSubmission?: string | undefined
    postingDeadline?: string | undefined
    postingDeadlineExtended?: boolean | undefined
    previousCardIds?: Array<string> | undefined
    priority?: GraphQLTypes['SourcePriority'] | undefined
    productCriticalFeedback?: string | undefined
    productId?: string | undefined
    productPositiveFeedback?: string | undefined
    rankPriority?: number | undefined
    reviewScreenshot?: string | undefined
    reviewScreenshotVerificationResult?: GraphQLTypes['ReviewScreenshotVerificationResult'] | undefined
    schedulingReleaseAt?: string | undefined
    searchPhraseKey?: string | undefined
    selectedFlow?: string | undefined
    selfieVideoApprovalDate?: string | undefined
    selfieVideoApprovalNotes?: string | undefined
    selfieVideoApprovalStatus?: GraphQLTypes['SelfieVideoApprovalStatus'] | undefined
    selfieVideoKey?: string | undefined
    selfieVideoRejectionReason?: string | undefined
    selfieVideoSubmissionRequired?: boolean | undefined
    selfieVideoSubmittedAt?: string | undefined
    sequenceFormSubmissionValues?: Array<GraphQLTypes['SequenceFormSubmissionValuesInput']> | undefined
    shippingAddress?: string | undefined
    showEarlyReviewSurvey?: boolean | undefined
    statusHistory?: string | undefined
    updatedAt?: string | undefined
    userId?: string | undefined
    walmartReviewScreenShot?: string | undefined
  }
  ['UpdateUserCampaignSnapshotInput']: {
    snapshot?: string | undefined
    userCampaignId: string
    userCampaignVersion: number
  }
  ['UpdateUserCampaignSocialContentDraftHistoryInput']: {
    id: string
    snapshot?: string | undefined
    snapshotDate?: string | undefined
    userCampaignSocialContentId?: string | undefined
  }
  ['UpdateUserCampaignSocialContentInput']: {
    approvalFeedback?: string | undefined
    approvalStatus?: GraphQLTypes['ContentApprovalStatus'] | undefined
    approvedAt?: string | undefined
    approverPWUserId?: string | undefined
    approverUserId?: string | undefined
    bonusEarned?: number | undefined
    bonusOverride?: number | undefined
    caption?: string | undefined
    channel?: GraphQLTypes['SocialChannel'] | undefined
    clientFeedback?: Array<GraphQLTypes['SocialMediaFeedbackInput']> | undefined
    complianceReview?: Array<GraphQLTypes['ContentComplianceReviewCriteriaInput']> | undefined
    complianceReviewStatus?: GraphQLTypes['ContentComplianceReviewStatus'] | undefined
    complianceReviewUserId?: string | undefined
    complianceReviewedAt?: string | undefined
    contentType?: GraphQLTypes['SocialContentType'] | undefined
    draftEnteredAdminApprovalAt?: string | undefined
    draftEnteredBrandApprovalAt?: string | undefined
    draftFeedback?: Array<string> | undefined
    draftHistory?: Array<string> | undefined
    draftMedia?: Array<string> | undefined
    draftPreApprovedText?: string | undefined
    draftQualityScore?: number | undefined
    draftReview?: Array<GraphQLTypes['ContentComplianceReviewCriteriaInput']> | undefined
    draftStatus?: GraphQLTypes['DraftStatus'] | undefined
    draftSubmittedAt?: string | undefined
    draftText?: string | undefined
    earlySubmissionBonusEarnedCents?: number | undefined
    experimentId?: string | undefined
    externalUrl?: string | undefined
    groupId?: string | undefined
    hashtags?: Array<string> | undefined
    id: string
    instance?: number | undefined
    internalFeedback?: Array<GraphQLTypes['SocialMediaFeedbackInput']> | undefined
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    isPurchased?: boolean | undefined
    isUnavailable?: boolean | undefined
    manualUploadType?: GraphQLTypes['ManualUploadType'] | undefined
    mediaId?: string | undefined
    mediaUrls?: Array<string> | undefined
    metrics?: Array<GraphQLTypes['SocialMetricInput']> | undefined
    paymentId?: string | undefined
    postedAt?: string | undefined
    qualityScore?: number | undefined
    rejectionReason?: string | undefined
    requestedSocialScript?: boolean | undefined
    requirementLevel?: GraphQLTypes['ContentRequirementLevel'] | undefined
    scrapeStatus?: GraphQLTypes['ScrapeStatus'] | undefined
    unavailableAt?: string | undefined
    usedRequestedSocialScript?: boolean | undefined
    userCampaignId?: string | undefined
    videoEndTime?: string | undefined
    videoStartTime?: string | undefined
  }
  ['UpdateUserCampaignSocialContentReviewInput']: {
    createdAt?: string | undefined
    reviewFeedback?: Array<GraphQLTypes['UserCampaignSocialContentReviewFeedbackInput']> | undefined
    reviewOutcome?: GraphQLTypes['UserCampaignSocialContentReviewOutcome'] | undefined
    reviewStatus?: GraphQLTypes['UserCampaignSocialContentReviewStatus'] | undefined
    reviewType: GraphQLTypes['UserCampaignSocialContentReviewType']
    reviewedAt?: string | undefined
    reviewerAssigneeId?: string | undefined
    reviewerFirstName?: string | undefined
    reviewerId?: string | undefined
    reviewerLastName?: string | undefined
    userCampaignSocialContentId: string
    userId?: string | undefined
  }
  ['UpdateUserDeviceSnapshotInput']: {
    id: string
    idfv?: string | undefined
    ipAddress?: string | undefined
    isEmulator?: boolean | undefined
    snapshotAt?: string | undefined
    timezone?: string | undefined
    userId?: string | undefined
  }
  ['UpdateUserFollowerInput']: {
    createdAt?: string | undefined
    followerUserId: string
    isFavorite?: GraphQLTypes['FavoriteFlag'] | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['UpdateUserInput']: {
    active?: GraphQLTypes['Active'] | undefined
    addressComponents?: string | undefined
    amznLastChecked?: string | undefined
    amznLink?: string | undefined
    amznPrime?: string | undefined
    amznProfileId?: string | undefined
    amznReviewerId?: string | undefined
    amznVerified?: string | undefined
    attributedReviewCount?: number | undefined
    blogWebsite?: string | undefined
    brandContentRatingAvg?: number | undefined
    campaignAcceptanceRate?: number | undefined
    campaignApprovalRate?: number | undefined
    campaignCompletionRate?: number | undefined
    campaignReviewRate?: number | undefined
    cardProfileUpdates?: string | undefined
    cardholderId?: string | undefined
    cardholderRegion?: GraphQLTypes['StripeRegion'] | undefined
    characteristics?: Array<GraphQLTypes['UserCharacteristicInput']> | undefined
    characteristicsUpdatedAt?: string | undefined
    claimedReviewCount?: number | undefined
    collisionSkuSet?: Array<string> | undefined
    collisionSkuSetCalculatedAt?: string | undefined
    completedLifestyleCampaignCount?: number | undefined
    completedLiftCampaignCount?: number | undefined
    completedSocialCampaignCount?: number | undefined
    contentRating?: GraphQLTypes['UserContentRatingInput'] | undefined
    contentRatingCompletedAt?: string | undefined
    contentRatingCompletionTimeMillis?: number | undefined
    contentRatingInstagramCompletionTimeMillis?: number | undefined
    contentRatingInstagramSponsorshipContent?: Array<GraphQLTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentRatingQualityScore?: number | undefined
    contentRatingReviewStatus?: GraphQLTypes['ContentRatingReviewStatus'] | undefined
    contentRatingSponsorshipContent?: Array<GraphQLTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentRatingSubmittedBy?: string | undefined
    contentRatingTiktokCompletionTimeMillis?: number | undefined
    contentRatingTiktokSponsorshipContent?: Array<GraphQLTypes['ContentRatingSponsorshipContentInput']> | undefined
    contentTypes?: Array<string> | undefined
    createdAt?: string | undefined
    date_of_birth?: string | undefined
    deactivationDate?: string | undefined
    deactivationReason?: string | undefined
    declineToReviewCount?: number | undefined
    deranked?: GraphQLTypes['Deranked_Reason'] | undefined
    email?: string | undefined
    emailOptOut?: boolean | undefined
    facebookAccessToken?: string | undefined
    facebookAccessTokenExpiry?: string | undefined
    facebookPageId?: string | undefined
    facebookPageIsSubscribed?: boolean | undefined
    facebookPermissionScopes?: Array<string | undefined> | undefined
    facebookUserId?: string | undefined
    firstName?: string | undefined
    followsWesponsored?: string | undefined
    fraudReason?: GraphQLTypes['FraudReason'] | undefined
    fraudReviewAt?: string | undefined
    fraudReviewStatus?: GraphQLTypes['FraudReviewStatus'] | undefined
    gender?: string | undefined
    hasTrueEngagement?: boolean | undefined
    id: string
    idfaId?: string | undefined
    incompleteCampaignCount?: number | undefined
    instagramBio?: string | undefined
    instagramBusinessCategoryName?: string | undefined
    instagramCategoryName?: string | undefined
    instagramChecked?: string | undefined
    instagramContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    instagramExternalLink?: string | undefined
    instagramFollowerCountryDistribution?: Array<GraphQLTypes['InstagramCountryDistributionItemInput']> | undefined
    instagramFollowerGenderAgeDistribution?: GraphQLTypes['InstagramGenderAgeDistributionInput'] | undefined
    instagramFollowersInt?: number | undefined
    instagramHandle?: string | undefined
    instagramIsBusiness?: string | undefined
    instagramIsPrivate?: string | undefined
    instagramIsRecentlyJoined?: string | undefined
    instagramIsVerified?: string | undefined
    instagramLastChecked?: string | undefined
    instagramMedianShares?: number | undefined
    instagramMetricsRetrievedAt?: string | undefined
    instagramName?: string | undefined
    instagramPostsInt?: number | undefined
    instagramPriority?: GraphQLTypes['PlatformPriority'] | undefined
    instagramProfilePic?: string | undefined
    instagramTrailingComments?: number | undefined
    instagramTrailingDateOfOldestPost?: string | undefined
    instagramTrailingLikes?: number | undefined
    instagramTrailingNumberOfPosts?: number | undefined
    instagramTrailingPostEngagementAvg?: number | undefined
    instagramTrailingPostEngagementRateAvg?: number | undefined
    instagramTrailingPostEngagementRateImpressionsAvg?: number | undefined
    instagramTrailingPostEngagementRateMedian?: number | undefined
    instagramTrailingPostImpressionsAvg?: number | undefined
    instagramTrailingPostImpressionsMedian?: number | undefined
    instagramTrailingPostSponsoredPercentage?: number | undefined
    instagramTrailingPostViewsMedian?: number | undefined
    instagramUserId?: string | undefined
    instagramVerificationMethod?: GraphQLTypes['SocialVerificationMethod'] | undefined
    internalGender?: GraphQLTypes['UserGender'] | undefined
    isBrandSafe?: GraphQLTypes['BrandSafe'] | undefined
    isBrandSafeReason?: string | undefined
    isBrandSafeUpdatedAt?: string | undefined
    lastActiveAt?: string | undefined
    lastName?: string | undefined
    manualScoringLockedUntil?: string | undefined
    moreinfo?: string | undefined
    numEvenDollarPurchases?: number | undefined
    numPurchases?: number | undefined
    paypalEmail?: string | undefined
    phoneNumber?: string | undefined
    profilePicture?: string | undefined
    pushAddresses?: Array<GraphQLTypes['PushAddressInput']> | undefined
    pushOptOut?: boolean | undefined
    referralCode?: string | undefined
    referralCount?: number | undefined
    referrer?: string | undefined
    signupCountry?: string | undefined
    smsOptOut?: boolean | undefined
    socialQuality?: GraphQLTypes['SocialQuality'] | undefined
    specialAttributes?: GraphQLTypes['UserAttributesInput'] | undefined
    statusTier?: GraphQLTypes['StatusTier'] | undefined
    statusTierHistory?: Array<GraphQLTypes['StatusTierEntryInput']> | undefined
    statusTierLastUpdatedAt?: string | undefined
    supportPriorityAcknowledgedAt?: string | undefined
    supportPriorityFlag?: GraphQLTypes['SupportPriorityFlag'] | undefined
    supportPriorityFlagSetAt?: string | undefined
    targetAccount?: string | undefined
    tiktokAccessToken?: string | undefined
    tiktokAccessTokenExpiry?: string | undefined
    tiktokAccountId?: string | undefined
    tiktokAveragePostEngagementRate?: number | undefined
    tiktokAveragePostEngagementRateViews?: number | undefined
    tiktokBio?: string | undefined
    tiktokContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    tiktokFollowersInt?: number | undefined
    tiktokHandle?: string | undefined
    tiktokMedianComments?: number | undefined
    tiktokMedianLikes?: number | undefined
    tiktokMedianPostEngagement?: number | undefined
    tiktokMedianPostEngagementRate?: number | undefined
    tiktokMedianPostEngagementRateViews?: number | undefined
    tiktokMedianShares?: number | undefined
    tiktokMedianViews?: number | undefined
    tiktokMetricsRetrievedAt?: string | undefined
    tiktokName?: string | undefined
    tiktokPermissionScopes?: Array<string> | undefined
    tiktokPriority?: GraphQLTypes['PlatformPriority'] | undefined
    tiktokProfilePic?: string | undefined
    tiktokRefreshToken?: string | undefined
    tiktokRefreshTokenExpiry?: string | undefined
    tiktokSponsoredPostPercentage?: number | undefined
    tiktokVerificationMethod?: GraphQLTypes['SocialVerificationMethod'] | undefined
    tiktokVerified?: string | undefined
    timeZone?: string | undefined
    trackingStatus?: GraphQLTypes['TrackingStatus'] | undefined
    twitchAccount?: string | undefined
    type?: string | undefined
    utmReferral?: GraphQLTypes['UtmReferralInput'] | undefined
    wmartAccount?: string | undefined
    youtubeAccessToken?: string | undefined
    youtubeAccessTokenExpiry?: string | undefined
    youtubeAccount?: string | undefined
    youtubeAveragePostEngagementRate?: number | undefined
    youtubeChannelDescription?: string | undefined
    youtubeChannelFollowersInt?: number | undefined
    youtubeChannelHandle?: string | undefined
    youtubeChannelId?: string | undefined
    youtubeChannelPicture?: string | undefined
    youtubeChannelTitle?: string | undefined
    youtubeIdToken?: string | undefined
    youtubeMedianViews?: number | undefined
    youtubeMetricsRetrievedAt?: string | undefined
    youtubePermissionScopes?: Array<string> | undefined
    youtubeRefreshToken?: string | undefined
    youtubeVerificationMethod?: GraphQLTypes['SocialVerificationMethod'] | undefined
  }
  ['UpdateUserLinkInput']: {
    campaignId?: string | undefined
    clicksPerPeriod?: Array<GraphQLTypes['ClicksRecordsInput']> | undefined
    createdAt?: string | undefined
    id: string
    linkId?: string | undefined
    originalLink?: string | undefined
    rank?: number | undefined
    storageType?: GraphQLTypes['LinkStorageType'] | undefined
    uniqueClicks?: number | undefined
    updatedAt?: string | undefined
    userEncodedLink?: string | undefined
    userId?: string | undefined
  }
  ['UpdateUserNotificationPreferenceInput']: {
    category: string
    emailOptOut?: boolean | undefined
    pushOptOut?: boolean | undefined
    smsOptOut?: boolean | undefined
    userId: string
  }
  ['UpdateUserPayoutRequestInput']: {
    failureReason?: string | undefined
    id: string
    manuallyApprovedBy?: string | undefined
    payoutAmountCents?: number | undefined
    payoutBatchId?: string | undefined
    payoutDate?: string | undefined
    payoutLocale?: GraphQLTypes['WSLocale'] | undefined
    payoutState?: GraphQLTypes['BonusPayoutState'] | undefined
    userId?: string | undefined
  }
  ['UpdateUserStagingApprovalInput']: {
    id: string
    reviewFeedback?: string | undefined
    reviewedAt?: string | undefined
    reviewedBy?: string | undefined
    reviewedByEmail?: string | undefined
    reviewedByName?: string | undefined
    reviewedBySource?: GraphQLTypes['UserStagingApprovalReviewSource'] | undefined
    userCampaignId?: string | undefined
    userStagingApprovalOutcome?: GraphQLTypes['UserStagingApprovalOutcome'] | undefined
    userStagingApprovalType?: GraphQLTypes['UserStagingApprovalType'] | undefined
  }
  ['UpdateVideoContentScanInput']: {
    audioDuration?: number | undefined
    audioEndAt?: number | undefined
    audioStartFrom?: number | undefined
    contentSafetyAnalysis?: GraphQLTypes['ContentSafetyAnalysisInput'] | undefined
    id: string
    processFinishedAt?: string | undefined
    processId?: string | undefined
    processInitiatedAt?: string | undefined
    scanStatus?: GraphQLTypes['ContentScanStatus'] | undefined
    socialMediaUrl?: string | undefined
    transcription?: string | undefined
    transcriptionConfidence?: number | undefined
    url?: string | undefined
    userId?: string | undefined
  }
  ['UserAttributesInput']: {
    isMother?: boolean | undefined
  }
  ['UserCampaignBonusConfigInput']: {
    closeoutSurveyBonus?: number | undefined
    contentBonuses?: Array<GraphQLTypes['ProductSocialContentInput']> | undefined
    discoveryBonus?: number | undefined
    expectedBonus?: string | undefined
    hasCustomBonusConfig?: boolean | undefined
    payForImpressionsBonus?: GraphQLTypes['PayForImpressionsBonusConfigInput'] | undefined
  }
  ['UserCampaignSearchFields']: {
    campaignId?: GraphQLTypes['SearchInputString'] | undefined
    campaignStatus?: GraphQLTypes['SearchInputString'] | undefined
    prospect?: GraphQLTypes['ProspectSearchFields'] | undefined
  }
  ['UserCampaignSocialContentReviewFeedbackInput']: {
    atTime?: number | undefined
    createdAt?: string | undefined
    createdByFirstName?: string | undefined
    createdByLastName?: string | undefined
    createdByUserId?: string | undefined
    forAssetAtIndex?: number | undefined
    id?: string | undefined
    message?: string | undefined
    rating?: number | undefined
    updatedAt?: string | undefined
    visibility?: GraphQLTypes['ReviewFeedbackVisibility'] | undefined
  }
  ['UserCharacteristicInput']: {
    characteristic: string
    reason?: string | undefined
  }
  ['UserContentRatingInput']: {
    instagramContentQualityRating?: GraphQLTypes['ContentQualityRating'] | undefined
    instagramContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    instagramCreatorContentScore?: number | undefined
    instagramCreatorContentScoreRaw?: Array<GraphQLTypes['CreatorContentRatingRawValueInput']> | undefined
    instagramCreatorSponsorshipContentScore?: number | undefined
    instagramSubmittedBy?: string | undefined
    submittedBy?: string | undefined
    tiktokContentQualityRating?: GraphQLTypes['ContentQualityRating'] | undefined
    tiktokContentTypeRating?: Array<GraphQLTypes['ContentTypeRating']> | undefined
    tiktokCreatorContentScore?: number | undefined
    tiktokCreatorContentScoreRaw?: Array<GraphQLTypes['CreatorContentRatingRawValueInput']> | undefined
    tiktokCreatorSponsorshipContentScore?: number | undefined
    tiktokSubmittedBy?: string | undefined
  }
  ['UserSearchFields']: {
    addressComponents?: GraphQLTypes['AddressComponentsSearchFields'] | undefined
    campaignAcceptanceRate?: GraphQLTypes['SearchInputNumber'] | undefined
    campaignCompletionRate?: GraphQLTypes['SearchInputNumber'] | undefined
    campaignReviewRate?: GraphQLTypes['SearchInputNumber'] | undefined
    claimedReviewCount?: GraphQLTypes['SearchInputNumber'] | undefined
    collisionSkuSet?: GraphQLTypes['SearchInputString'] | undefined
    contentRatingQualityScore?: GraphQLTypes['SearchInputNumber'] | undefined
    createdAt?: GraphQLTypes['SearchInputString'] | undefined
    declineToReviewCount?: GraphQLTypes['SearchInputNumber'] | undefined
    email?: GraphQLTypes['SearchInputString'] | undefined
    firstName?: GraphQLTypes['SearchInputString'] | undefined
    followers?: GraphQLTypes['FollowersSearchFields'] | undefined
    gender?: GraphQLTypes['SearchInputString'] | undefined
    incompleteCampaignCount?: GraphQLTypes['SearchInputNumber'] | undefined
    instagramContentTypeRating?: GraphQLTypes['SearchInputString'] | undefined
    instagramFollowerCountryDistribution?: GraphQLTypes['InstagramFollowerCountryDistributionSearchFields'] | undefined
    instagramFollowersInt?: GraphQLTypes['SearchInputNumber'] | undefined
    instagramHandle?: GraphQLTypes['SearchInputString'] | undefined
    instagramTrailingPostEngagementRateAvg?: GraphQLTypes['SearchInputNumber'] | undefined
    instagramTrailingPostEngagementRateMedian?: GraphQLTypes['SearchInputNumber'] | undefined
    instagramTrailingPostImpressionsAvg?: GraphQLTypes['SearchInputNumber'] | undefined
    instagramTrailingPostImpressionsMedian?: GraphQLTypes['SearchInputNumber'] | undefined
    instagramTrailingPostSponsoredPercentage?: GraphQLTypes['SearchInputNumber'] | undefined
    instagramVerificationMethod?: GraphQLTypes['SearchInputString'] | undefined
    lastName?: GraphQLTypes['SearchInputString'] | undefined
    osScript?: GraphQLTypes['SearchInputQueryScript'] | undefined
    socialQuality?: GraphQLTypes['SearchInputString'] | undefined
    tiktokAveragePostEngagementRate?: GraphQLTypes['SearchInputNumber'] | undefined
    tiktokContentTypeRating?: GraphQLTypes['SearchInputString'] | undefined
    tiktokFollowersInt?: GraphQLTypes['SearchInputNumber'] | undefined
    tiktokHandle?: GraphQLTypes['SearchInputString'] | undefined
    tiktokMedianPostEngagementRateViews?: GraphQLTypes['SearchInputNumber'] | undefined
    tiktokMedianViews?: GraphQLTypes['SearchInputNumber'] | undefined
    tiktokSponsoredPostPercentage?: GraphQLTypes['SearchInputNumber'] | undefined
    tiktokVerificationMethod?: GraphQLTypes['SearchInputString'] | undefined
    userCampaigns?: GraphQLTypes['UserCampaignSearchFields'] | undefined
    youtubeAveragePostEngagementRate?: GraphQLTypes['SearchInputNumber'] | undefined
    youtubeChannelFollowersInt?: GraphQLTypes['SearchInputNumber'] | undefined
    youtubeChannelHandle?: GraphQLTypes['SearchInputString'] | undefined
    youtubeMedianViews?: GraphQLTypes['SearchInputNumber'] | undefined
    youtubeVerificationMethod?: GraphQLTypes['SearchInputString'] | undefined
  }
  ['UserSearchInput']: {
    and?: Array<GraphQLTypes['UserSearchFields']> | undefined
    from?: number | undefined
    not?: Array<GraphQLTypes['UserSearchFields']> | undefined
    or?: Array<Array<GraphQLTypes['UserSearchFields']> | undefined> | undefined
    size?: number | undefined
    sort?: Array<GraphQLTypes['UserSearchInputSort']> | undefined
  }
  ['UserSearchInputSort']: {
    field?: string | undefined
    order: GraphQLTypes['SortOrder']
    osScript?: GraphQLTypes['SearchInputSortScript'] | undefined
  }
  ['UtmReferralInput']: {
    campaign?: string | undefined
    content?: string | undefined
    medium?: string | undefined
    source?: string | undefined
    term?: string | undefined
  }
}
export enum Active {
  active = 'active',
  not_active = 'not_active'
}
export enum AdminUpdateContentApprovalType {
  EDIT_CAPTION_FINAL_APPROVAL = 'EDIT_CAPTION_FINAL_APPROVAL',
  EDIT_CAPTION_SEND_FOR_BRAND_APPROVAL = 'EDIT_CAPTION_SEND_FOR_BRAND_APPROVAL',
  FINAL_APPROVAL = 'FINAL_APPROVAL',
  REJECT = 'REJECT',
  REJECT_CONTENT = 'REJECT_CONTENT',
  REQUEST_CREATOR_CONTENT_CHANGE = 'REQUEST_CREATOR_CONTENT_CHANGE',
  ROLLBACK_TO_ADMIN = 'ROLLBACK_TO_ADMIN',
  SEND_FOR_BRAND_APPROVAL = 'SEND_FOR_BRAND_APPROVAL',
  SEND_FOR_MODERATOR_APPROVAL = 'SEND_FOR_MODERATOR_APPROVAL'
}
export enum AmazonReviewSubmissionStatus {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  SUBMITTED_LIVE = 'SUBMITTED_LIVE',
  SUBMITTED_NOT_LIVE = 'SUBMITTED_NOT_LIVE'
}
export enum ApplicationStatus {
  APPLIED = 'APPLIED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_APPLIED = 'NOT_APPLIED'
}
export enum ApprovalOutcome {
  APPROVED_BY_ADMIN = 'APPROVED_BY_ADMIN',
  APPROVED_BY_BRAND = 'APPROVED_BY_BRAND',
  AUTOAPPROVED = 'AUTOAPPROVED',
  BATCH_APPROVED_BY_ADMIN = 'BATCH_APPROVED_BY_ADMIN',
  BATCH_APPROVED_BY_BRAND = 'BATCH_APPROVED_BY_BRAND',
  REJECTED_BY_ADMIN = 'REJECTED_BY_ADMIN',
  REJECTED_BY_BRAND = 'REJECTED_BY_BRAND'
}
export enum ApprovalSort {
  CAMPAIGN_REVIEW_RATE = 'CAMPAIGN_REVIEW_RATE',
  HIGHEST_RATED_CONTENT_QUALITY = 'HIGHEST_RATED_CONTENT_QUALITY',
  HIGHEST_RATED_CREATOR_VIDEO_CONTENT_QUALITY = 'HIGHEST_RATED_CREATOR_VIDEO_CONTENT_QUALITY',
  IG_CONTENT_QUALITY = 'IG_CONTENT_QUALITY',
  IG_ENGAGEMENT = 'IG_ENGAGEMENT',
  IG_IMPRESSIONS = 'IG_IMPRESSIONS',
  TT_CONTENT_QUALITY = 'TT_CONTENT_QUALITY',
  TT_ENGAGEMENT = 'TT_ENGAGEMENT',
  TT_IMPRESSIONS = 'TT_IMPRESSIONS'
}
export enum ArchivedReason {
  CAMPAIGN_COMPLETED = 'CAMPAIGN_COMPLETED',
  CAMPAIGN_FULL = 'CAMPAIGN_FULL',
  CAMPAIGN_NOT_COMPLETED = 'CAMPAIGN_NOT_COMPLETED',
  DISCOVERY_NOT_COMPLETED = 'DISCOVERY_NOT_COMPLETED',
  INSTA_POST_FALSE = 'INSTA_POST_FALSE',
  OLD_FLOW = 'OLD_FLOW',
  OTHER = 'OTHER',
  PHYSICAL_LOCATION = 'PHYSICAL_LOCATION',
  PRODUCT_FEEDBACK_NOT_COMPLETED = 'PRODUCT_FEEDBACK_NOT_COMPLETED',
  REJECTED_BY_BRAND = 'REJECTED_BY_BRAND',
  REJECTED_BY_MODERATOR = 'REJECTED_BY_MODERATOR',
  UNSPECIFIED = 'UNSPECIFIED',
  USER_SURVEY_APPLIED_BY_MISTAKE = 'USER_SURVEY_APPLIED_BY_MISTAKE',
  USER_SURVEY_CAN_NO_LONGER_PARTICIPATE = 'USER_SURVEY_CAN_NO_LONGER_PARTICIPATE',
  USER_SURVEY_CHANGED_MY_MIND = 'USER_SURVEY_CHANGED_MY_MIND',
  USER_SURVEY_NEVER_APPLIED = 'USER_SURVEY_NEVER_APPLIED'
}
export enum AutoBuyDelayStrategy {
  ACCUMULATE_REDISTRIBUTE = 'ACCUMULATE_REDISTRIBUTE',
  EXTEND_CAMPAIGN = 'EXTEND_CAMPAIGN'
}
export enum AutoBuySkipReason {
  CLIENT_REQUEST = 'CLIENT_REQUEST',
  ERROR = 'ERROR',
  INACCESSIBLE = 'INACCESSIBLE',
  INTERNAL_REQUEST = 'INTERNAL_REQUEST',
  INVALID_PRICE = 'INVALID_PRICE',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  PAUSED = 'PAUSED'
}
export enum AutoBuyStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  DISABLED = 'DISABLED',
  PAUSED = 'PAUSED'
}
export enum BOPISValue {
  FALSE = 'FALSE',
  TRUE = 'TRUE'
}
export enum BonusPayoutState {
  BACKFILL = 'BACKFILL',
  NOT_READY = 'NOT_READY',
  PAID = 'PAID',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  UNPAID = 'UNPAID'
}
export enum BonusPayoutTypes {
  CAMPAIGN_ARCHIVE = 'CAMPAIGN_ARCHIVE',
  CAMPAIGN_CLOSEOUT = 'CAMPAIGN_CLOSEOUT',
  CAMPAIGN_CONTENT_AND_PURCHASE = 'CAMPAIGN_CONTENT_AND_PURCHASE',
  CAMPAIGN_PARTICIPATION = 'CAMPAIGN_PARTICIPATION',
  CAMPAIGN_PAY_FOR_PERFORMANCE = 'CAMPAIGN_PAY_FOR_PERFORMANCE',
  CAMPAIGN_REFERRAL = 'CAMPAIGN_REFERRAL',
  PAY_FOR_IMPRESSIONS = 'PAY_FOR_IMPRESSIONS',
  REFERRAL = 'REFERRAL'
}
export enum BonusRequestTypes {
  closeout_link_v1 = 'closeout_link_v1',
  closeout_v1 = 'closeout_v1',
  contentBonus_v1 = 'contentBonus_v1',
  directPurchase_v1 = 'directPurchase_v1',
  discoveryBonus_v1 = 'discoveryBonus_v1',
  modularContentRejection_v1 = 'modularContentRejection_v1',
  modularContent_v1 = 'modularContent_v1',
  revoke_v1 = 'revoke_v1'
}
export enum BrandApiType {
  amazon_eu_seller = 'amazon_eu_seller',
  amazon_eu_vendor = 'amazon_eu_vendor',
  amazon_fe_seller = 'amazon_fe_seller',
  amazon_fe_vendor = 'amazon_fe_vendor',
  amazon_na_seller = 'amazon_na_seller',
  amazon_na_vendor = 'amazon_na_vendor',
  shopify = 'shopify'
}
export enum BrandApiVersion {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3'
}
export enum BrandSafe {
  NO = 'NO',
  NOT_DETERMINED = 'NOT_DETERMINED',
  YES = 'YES'
}
export enum CampaignBadgeType {
  CUSTOM = 'CUSTOM',
  HIGH_ACCEPTANCE_RATE = 'HIGH_ACCEPTANCE_RATE'
}
export enum CampaignGoal {
  BOOST_RATING = 'BOOST_RATING',
  EVERGREEN = 'EVERGREEN',
  EXISTING_PRODUCT = 'EXISTING_PRODUCT',
  OTHER = 'OTHER',
  EVENT = 'EVENT',
  PRODUCT_LAUNCH = 'PRODUCT_LAUNCH'
}
export enum CampaignProductType {
  app = 'app',
  in_store = 'in_store',
  product = 'product',
  website = 'website'
}
export enum CampaignReferralBonusFlag {
  FALSE = 'FALSE',
  TRUE = 'TRUE'
}
export enum CampaignSourcingSource {
  CSV_UPLOAD = 'CSV_UPLOAD',
  MANUAL = 'MANUAL',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
}
export enum CampaignStatus {
  application_approved = 'application_approved',
  application_approved_ranking = 'application_approved_ranking',
  application_denied = 'application_denied',
  application_submitted = 'application_submitted',
  archived_completed = 'archived_completed',
  archived_not_completed = 'archived_not_completed',
  assets_uploaded = 'assets_uploaded',
  blacklist = 'blacklist',
  bonus_approved = 'bonus_approved',
  close_out_submitted = 'close_out_submitted',
  close_out_submitted_awaiting_review_link = 'close_out_submitted_awaiting_review_link',
  content_posted = 'content_posted',
  content_posted_v2 = 'content_posted_v2',
  content_survey_mandatory_story_v1 = 'content_survey_mandatory_story_v1',
  content_survey_submitted = 'content_survey_submitted',
  create_content_v1 = 'create_content_v1',
  direct_listing_link = 'direct_listing_link',
  discovery_v2 = 'discovery_v2',
  discovery_v2_keywordOption = 'discovery_v2_keywordOption',
  exit_at_application_approved = 'exit_at_application_approved',
  invite_offered = 'invite_offered',
  invite_offered_ranking = 'invite_offered_ranking',
  invoice_submitted = 'invoice_submitted',
  no_purchase_discovery_v1 = 'no_purchase_discovery_v1',
  posting_instructions_ranking = 'posting_instructions_ranking',
  product_feedback_submitted = 'product_feedback_submitted',
  product_feedback_submitted_with_issue = 'product_feedback_submitted_with_issue',
  purchase_holding_queue = 'purchase_holding_queue',
  ranking_close_out = 'ranking_close_out',
  ranking_close_out_no_review = 'ranking_close_out_no_review',
  reimbursement_confirmed = 'reimbursement_confirmed',
  sponsorship_accepted = 'sponsorship_accepted',
  waiting_room_ranking = 'waiting_room_ranking'
}
export enum Channel {
  APNS = 'APNS',
  EMAIL = 'EMAIL',
  GCM = 'GCM',
  IN_APP_CHAT = 'IN_APP_CHAT',
  MESSAGING = 'MESSAGING',
  PUSH = 'PUSH',
  SMS = 'SMS'
}
export enum ChatType {
  USER = 'USER',
  USER_CAMPAIGN = 'USER_CAMPAIGN'
}
export enum CoarseCampaignStatus {
  active = 'active',
  archived = 'archived',
  before_approval = 'before_approval',
  queued_to_purchase = 'queued_to_purchase'
}
export enum ComplianceReviewCriteria {
  BRAND_PRONUNCIATION = 'BRAND_PRONUNCIATION',
  BRAND_SAFETY = 'BRAND_SAFETY',
  BRAND_SOCIAL_HANDLE = 'BRAND_SOCIAL_HANDLE',
  CONTENT_REQUIREMENT = 'CONTENT_REQUIREMENT',
  CUSTOM_NOTES = 'CUSTOM_NOTES',
  FEEDBACK_REASON = 'FEEDBACK_REASON',
  FTC_COMPLIANT = 'FTC_COMPLIANT',
  HASHTAGS = 'HASHTAGS',
  ORIGINAL_IMAGES = 'ORIGINAL_IMAGES',
  POSTING_GUIDANCE = 'POSTING_GUIDANCE',
  PRODUCT_PRONUNCIATION = 'PRODUCT_PRONUNCIATION',
  PROMO = 'PROMO'
}
export enum ConnectedAccountType {
  AMAZON_PROFILE = 'AMAZON_PROFILE',
  AMAZON_REVIEWER = 'AMAZON_REVIEWER'
}
export enum ContentApprovalStatus {
  OMITTED = 'OMITTED',
  PENDING = 'PENDING',
  SKIPPED = 'SKIPPED',
  SUBMITTED = 'SUBMITTED'
}
export enum ContentApprovalType {
  ADMIN_APPROVAL = 'ADMIN_APPROVAL',
  BRAND_APPROVAL = 'BRAND_APPROVAL',
  MODERATOR_APPROVAL = 'MODERATOR_APPROVAL'
}
export enum ContentComplianceReviewStatus {
  OMITTED = 'OMITTED',
  PENDING = 'PENDING',
  SKIPPED = 'SKIPPED',
  SUBMITTED = 'SUBMITTED'
}
export enum ContentQualityRating {
  MID_LEVEL_CONTENT = 'MID_LEVEL_CONTENT',
  NEW_USER = 'NEW_USER',
  NOT_USABLE = 'NOT_USABLE',
  PASSABLE_CONTENT = 'PASSABLE_CONTENT',
  TOP_QUALITY_CONTENT = 'TOP_QUALITY_CONTENT'
}
export enum ContentRatingReviewStatus {
  PENDING = 'PENDING',
  REVIEWED = 'REVIEWED',
  SKIPPED = 'SKIPPED'
}
export enum ContentRatingSponsorshipContentInputSource {
  MANUAL = 'MANUAL',
  SCRAPE = 'SCRAPE'
}
export enum ContentRequirementLevel {
  IS_OPTIONAL = 'IS_OPTIONAL',
  IS_REQUIRED = 'IS_REQUIRED'
}
export enum ContentSafetyLabel {
  ACCIDENTS = 'ACCIDENTS',
  ALCOHOL = 'ALCOHOL',
  CRIME_VIOLENCE = 'CRIME_VIOLENCE',
  DISASTERS = 'DISASTERS',
  DRUGS = 'DRUGS',
  FINANCIALS = 'FINANCIALS',
  GAMBLING = 'GAMBLING',
  HATE_SPEECH = 'HATE_SPEECH',
  HEALTH_ISSUES = 'HEALTH_ISSUES',
  MANGA = 'MANGA',
  MARIJUANA = 'MARIJUANA',
  NEGATIVE_NEWS = 'NEGATIVE_NEWS',
  NSFW = 'NSFW',
  PORNOGRAPHY = 'PORNOGRAPHY',
  PROFANITY = 'PROFANITY',
  SENSITIVE_SOCIAL_ISSUES = 'SENSITIVE_SOCIAL_ISSUES',
  TERRORISM = 'TERRORISM',
  TOBACCO = 'TOBACCO',
  WEAPONS = 'WEAPONS'
}
export enum ContentScanStatus {
  FINISHED = 'FINISHED',
  FINISHED_WITH_ERRORS = 'FINISHED_WITH_ERRORS',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING'
}
export enum ContentTypeRating {
  BEAUTY_AND_MAKEUP = 'BEAUTY_AND_MAKEUP',
  FASHION_AND_CLOTHING = 'FASHION_AND_CLOTHING',
  FOOD_AND_COOKING = 'FOOD_AND_COOKING',
  GAMING_AND_ENTERTAINMENT = 'GAMING_AND_ENTERTAINMENT',
  HEALTH_AND_FITNESS = 'HEALTH_AND_FITNESS',
  HOME_AND_DECOR = 'HOME_AND_DECOR',
  HOWTO_AND_UNBOXINGS = 'HOWTO_AND_UNBOXINGS',
  KIDS_AND_FAMILY = 'KIDS_AND_FAMILY',
  LIFESTYLE = 'LIFESTYLE',
  OTHER = 'OTHER',
  PETS_AND_ANIMALS = 'PETS_AND_ANIMALS',
  POLITICS_AND_MEDIA = 'POLITICS_AND_MEDIA',
  SPORTS_AND_OUTDOORS = 'SPORTS_AND_OUTDOORS',
  TECH_AND_SCIENCE = 'TECH_AND_SCIENCE',
  TRAVEL_AND_LUXURY = 'TRAVEL_AND_LUXURY'
}
export enum ConversationChannel {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SEND_BLUE = 'SEND_BLUE'
}
export enum ConversationInternalStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}
export enum ConversationMessageSource {
  ADMIN = 'ADMIN',
  APP = 'APP'
}
export enum ConversationMessageStatus {
  FAILED = 'FAILED',
  QUEUED = 'QUEUED',
  RECEIVED = 'RECEIVED',
  SENT = 'SENT'
}
export enum ConversationStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}
export enum CreationSourceVersion {
  CAMPAIGN_SETUP_V2 = 'CAMPAIGN_SETUP_V2',
  MOMENTUM = 'MOMENTUM',
  PRODUCTWIND = 'PRODUCTWIND'
}
export enum CreatorPreference {
  DEMOGRAPHIC_SPECIFIC = 'DEMOGRAPHIC_SPECIFIC',
  NONE = 'NONE',
  SPECIFIC_WITH_DETAILS = 'SPECIFIC_WITH_DETAILS'
}
export enum Currency {
  AUD = 'AUD',
  CAD = 'CAD',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  MXN = 'MXN',
  SGD = 'SGD',
  USD = 'USD'
}
export enum Deranked_Reason {
  unspecified = 'unspecified'
}
export enum DraftStatus {
  APPROVED = 'APPROVED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  REJECTED = 'REJECTED',
  REQUIRES_ADMIN_APPROVAL = 'REQUIRES_ADMIN_APPROVAL',
  REQUIRES_BRAND_APPROVAL = 'REQUIRES_BRAND_APPROVAL',
  REQUIRES_MODERATOR_APPROVAL = 'REQUIRES_MODERATOR_APPROVAL',
  REQUIRES_SYSTEM_APPROVAL = 'REQUIRES_SYSTEM_APPROVAL',
  REQUIRES_USER_CHANGES = 'REQUIRES_USER_CHANGES',
  USER_ACKNOWLEDGED_REJECTED = 'USER_ACKNOWLEDGED_REJECTED'
}
export enum EnabledFlag {
  FALSE = 'FALSE',
  TRUE = 'TRUE'
}
export enum FavoriteFlag {
  FALSE = 'FALSE',
  TRUE = 'TRUE'
}
export enum FeatureFlagEnvironment {
  MOMENTUM = 'MOMENTUM',
  WESPONSORED = 'WESPONSORED'
}
export enum FormComponentType {
  ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART',
  AMAZON_PROFILE_CONFIRMATION = 'AMAZON_PROFILE_CONFIRMATION',
  ATTRIBUTION_LINK = 'ATTRIBUTION_LINK',
  DIRECT_LISTING_LINK = 'DIRECT_LISTING_LINK',
  EDITOR = 'EDITOR',
  FIND_NON_SPONSORED_PRODUCT = 'FIND_NON_SPONSORED_PRODUCT',
  FIND_SPONSORED_PRODUCT = 'FIND_SPONSORED_PRODUCT',
  GENERATE_CARD = 'GENERATE_CARD',
  IMAGE = 'IMAGE',
  INPUT = 'INPUT',
  KEYWORD_EXAMPLE = 'KEYWORD_EXAMPLE',
  KEYWORD_SEARCH = 'KEYWORD_SEARCH',
  PRODUCT_SEARCH_DIFFICULTY = 'PRODUCT_SEARCH_DIFFICULTY',
  PRODUCT_URL_VERIFICATION = 'PRODUCT_URL_VERIFICATION',
  PROMOTE_PRODUCT_INFO = 'PROMOTE_PRODUCT_INFO',
  PURCHASE_CONFIRMATION = 'PURCHASE_CONFIRMATION',
  RETAILER_ACCOUNT_LOGIN = 'RETAILER_ACCOUNT_LOGIN',
  RETAILER_ACCOUNT_SIGNUP_LOGIN = 'RETAILER_ACCOUNT_SIGNUP_LOGIN',
  RICHTEXT = 'RICHTEXT',
  SCAN_BARCODE = 'SCAN_BARCODE',
  SHIPPING_INSTRUCTIONS = 'SHIPPING_INSTRUCTIONS',
  SHOPPING_EXPERIENCE_FEEDBACK = 'SHOPPING_EXPERIENCE_FEEDBACK',
  SPECIAL_INSTRUCTIONS = 'SPECIAL_INSTRUCTIONS',
  SUBTEXT = 'SUBTEXT',
  TITLE = 'TITLE',
  UPLOAD_KEYWORD_SCREENSHOT = 'UPLOAD_KEYWORD_SCREENSHOT',
  USE_PROMO_CODE = 'USE_PROMO_CODE'
}
export enum FormFieldType {
  address_input = 'address_input',
  checkbox_select = 'checkbox_select',
  custom_amzn_review = 'custom_amzn_review',
  date_input = 'date_input',
  dob_input = 'dob_input',
  image_upload = 'image_upload',
  image_upload_with_remove = 'image_upload_with_remove',
  media_modal = 'media_modal',
  multi_image_upload = 'multi_image_upload',
  password = 'password',
  photo_upload = 'photo_upload',
  qualtrics = 'qualtrics',
  radio_select = 'radio_select',
  radio_yes_no = 'radio_yes_no',
  sponsorship_content_list = 'sponsorship_content_list',
  telinput = 'telinput',
  text = 'text',
  text_list = 'text_list',
  textarea = 'textarea',
  textinput = 'textinput'
}
export enum FormType {
  CAMPAIGN_APPLICATION = 'CAMPAIGN_APPLICATION',
  CLOSEOUT_SURVEY = 'CLOSEOUT_SURVEY',
  CLOSEOUT_SURVEY_FOLLOWUP = 'CLOSEOUT_SURVEY_FOLLOWUP',
  CONTENT_SURVEY = 'CONTENT_SURVEY',
  CREATE_CONTENT = 'CREATE_CONTENT',
  FORM_SEQUENCE = 'FORM_SEQUENCE',
  NO_PURCHASE = 'NO_PURCHASE',
  OPT_OUT_CAMPAIGN_SURVEY = 'OPT_OUT_CAMPAIGN_SURVEY'
}
export enum FormValidationType {
  JSON = 'JSON',
  amazon_reviewer_profile = 'amazon_reviewer_profile',
  conditional_required_other = 'conditional_required_other',
  date_of_birth = 'date_of_birth',
  explanation_required_if_no = 'explanation_required_if_no',
  instagram_post = 'instagram_post',
  instagram_story = 'instagram_story',
  required = 'required',
  social_link = 'social_link',
  telephone = 'telephone',
  tiktok_video = 'tiktok_video',
  valid_email = 'valid_email',
  valid_url = 'valid_url',
  yes_required = 'yes_required'
}
export enum FraudReason {
  BAD_LOCATION = 'BAD_LOCATION',
  EVEN_DOLLAR_AUTHORIZATION_CHECK_FAILED = 'EVEN_DOLLAR_AUTHORIZATION_CHECK_FAILED',
  EXISTING_DEVICE = 'EXISTING_DEVICE'
}
export enum FraudReviewStatus {
  FRAUDULENT = 'FRAUDULENT',
  NOT_FRAUDULENT = 'NOT_FRAUDULENT'
}
export enum InfluencerApprovalFlagV2 {
  APPROVAL = 'APPROVAL',
  DISABLED = 'DISABLED',
  REVIEW = 'REVIEW'
}
export enum InspirationalContentType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}
export enum InstagramGenderAgeDistributionGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNSPECIFIED = 'UNSPECIFIED'
}
export enum ItemClassification {
  BASE_PRODUCT = 'BASE_PRODUCT',
  PRODUCT_BUNDLE = 'PRODUCT_BUNDLE',
  VARIATION_PARENT = 'VARIATION_PARENT'
}
export enum KeywordAcceptanceResponse {
  NOT_SELECTED = 'NOT_SELECTED',
  REJECTED = 'REJECTED',
  SELECTED = 'SELECTED'
}
export enum LinkStorageType {
  BITLY = 'BITLY',
  SHORT_IO = 'SHORT_IO'
}
export enum ListingFulfillmentType {
  HYBRID = 'HYBRID',
  SELLER = 'SELLER',
  VENDOR = 'VENDOR'
}
export enum ManualUploadType {
  FILE = 'FILE',
  LINK = 'LINK'
}
export enum ModelAttributeTypes {
  _null = '_null',
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet'
}
export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}
export enum NotificationStatus {
  QUEUED = 'QUEUED',
  SENT = 'SENT'
}
export enum OccurrenceConstraintPeriod {
  DAY = 'DAY',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}
export enum OccurrenceConstraintType {
  PER_CAMPAIGN = 'PER_CAMPAIGN',
  PER_PERIOD = 'PER_PERIOD',
  PER_USER = 'PER_USER',
  PER_USER_CAMPAIGN = 'PER_USER_CAMPAIGN'
}
export enum OrderConfirmationScreenshotVerificationResult {
  INVALID = 'INVALID',
  VALID = 'VALID'
}
export enum OrderIdValidationStatus {
  INVALID = 'INVALID',
  NOT_VALIDATED = 'NOT_VALIDATED',
  VALID = 'VALID'
}
export enum Paused {
  active = 'active',
  paused = 'paused'
}
export enum PayForPerformanceFlag {
  FALSE = 'FALSE',
  TRUE = 'TRUE'
}
export enum PaymentStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID = 'PAID',
  PENDING = 'PENDING'
}
export enum PaymentTermsType {
  DEFAULT_7_DAYS = 'DEFAULT_7_DAYS',
  NET_CUSTOM = 'NET_CUSTOM'
}
export enum Period {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}
export enum PlatformInviteSource {
  OFF_PLATFORM = 'OFF_PLATFORM',
  ON_PLATFORM = 'ON_PLATFORM'
}
export enum PlatformPriority {
  NOT_USABLE = 'NOT_USABLE',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}
export enum PlatformSource {
  MOMENTUM = 'MOMENTUM',
  WESPONSORED = 'WESPONSORED'
}
export enum PlatformStatus {
  OFF_PLATFORM = 'OFF_PLATFORM',
  ON_PLATFORM = 'ON_PLATFORM'
}
export enum PreApprovalStatus {
  REJECTED = 'REJECTED',
  VETTED = 'VETTED'
}
export enum ProductKeywordCreationSource {
  BRAND = 'BRAND',
  PRODUCT_WIND = 'PRODUCT_WIND'
}
export enum ProductQualityLevel {
  PREMIUM = 'PREMIUM',
  STANDARD = 'STANDARD'
}
export enum PromoCodeType {
  AMZN_INDIVIDUAL = 'AMZN_INDIVIDUAL',
  AMZN_SINGLE = 'AMZN_SINGLE',
  CLIENT_PROVIDED = 'CLIENT_PROVIDED',
  NONE = 'NONE',
  SHOPIFY = 'SHOPIFY'
}
export enum PromoDiscountType {
  fixed_amount = 'fixed_amount',
  percentage = 'percentage'
}
export enum PurchaseStatus {
  AUTHORIZED = 'AUTHORIZED',
  DECLINED = 'DECLINED',
  REVERSED = 'REVERSED'
}
export enum PushChannel {
  APNS = 'APNS',
  APNS_SANDBOX = 'APNS_SANDBOX',
  GCM = 'GCM'
}
export enum ReferralType {
  CAMPAIGN = 'CAMPAIGN',
  USER = 'USER'
}
export enum RequiredProductLinkFlag {
  FALSE = 'FALSE',
  TRUE = 'TRUE'
}
export enum ReviewFeedbackVisibility {
  INTERNAL_ONLY = 'INTERNAL_ONLY',
  SHOW_TO_CREATOR = 'SHOW_TO_CREATOR'
}
export enum ReviewScreenshotVerificationResult {
  INVALID = 'INVALID',
  VALID = 'VALID'
}
export enum RevocationType {
  CONTENT_NO_LONGER_AVAILABLE = 'CONTENT_NO_LONGER_AVAILABLE'
}
export enum ScrapeStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}
export enum SearchInputField {
  exists = 'exists'
}
export enum SearchInputQueryScriptSource {
  fieldGreaterThanField = 'fieldGreaterThanField',
  queryByUserAge = 'queryByUserAge'
}
export enum SearchInputScriptType {
  number = 'number'
}
export enum SearchInputSortScriptSource {
  maxArrayLength = 'maxArrayLength',
  maxBy = 'maxBy',
  sortByCreatorQualityTier = 'sortByCreatorQualityTier'
}
export enum SelfieVideoApprovalStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  UNCLEAR = 'UNCLEAR'
}
export enum ShopifyPromotionType {
  cart = 'cart',
  collection = 'collection',
  product = 'product'
}
export enum SkuCreationSource {
  BRAND_STORE_SCRAPE = 'BRAND_STORE_SCRAPE',
  CATEGORY_PAGE_SCRAPE = 'CATEGORY_PAGE_SCRAPE',
  MOMENTUM_MANUAL = 'MOMENTUM_MANUAL',
  MOMENTUM_SCRAPE = 'MOMENTUM_SCRAPE',
  SELLER_INTEGRATION = 'SELLER_INTEGRATION',
  VENDOR_INTEGRATION = 'VENDOR_INTEGRATION',
  WESPONSORED_SCRAPE = 'WESPONSORED_SCRAPE'
}
export enum SocialChannel {
  INSTAGRAM = 'INSTAGRAM',
  RETAILER = 'RETAILER',
  TIKTOK = 'TIKTOK',
  UGC = 'UGC',
  YOUTUBE = 'YOUTUBE'
}
export enum SocialContentType {
  IG_MULTI_STORY = 'IG_MULTI_STORY',
  IG_POST = 'IG_POST',
  IG_REEL = 'IG_REEL',
  IG_STORY = 'IG_STORY',
  IG_VIDEO = 'IG_VIDEO',
  REVIEW_PHOTO = 'REVIEW_PHOTO',
  REVIEW_TEXT = 'REVIEW_TEXT',
  TT_STORY = 'TT_STORY',
  TT_VIDEO = 'TT_VIDEO',
  UGC_PHOTO = 'UGC_PHOTO',
  UGC_VIDEO = 'UGC_VIDEO',
  YT_SHORT = 'YT_SHORT',
  YT_SHOUTOUT = 'YT_SHOUTOUT',
  YT_VIDEO = 'YT_VIDEO'
}
export enum SocialMediaPostContentType {
  CAROUSEL = 'CAROUSEL',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}
export enum SocialMediaPostGroupType {
  FEED = 'FEED',
  REELS = 'REELS'
}
export enum SocialMetricName {
  COMMENTS = 'COMMENTS',
  ENGAGEMENT = 'ENGAGEMENT',
  EXITS = 'EXITS',
  IMPRESSIONS = 'IMPRESSIONS',
  LIKES = 'LIKES',
  REACH = 'REACH',
  REPLIES = 'REPLIES',
  SAVED = 'SAVED',
  TAPSBACK = 'TAPSBACK',
  TAPSFORWARD = 'TAPSFORWARD',
  VIEWS = 'VIEWS'
}
export enum SocialQuality {
  DERANKED = 'DERANKED',
  PREMIUM = 'PREMIUM',
  STANDARD = 'STANDARD'
}
export enum SocialType {
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE'
}
export enum SocialVerificationMethod {
  CODE = 'CODE',
  INTEGRATION = 'INTEGRATION'
}
export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}
export enum SourcePriority {
  BACKUP = 'BACKUP',
  PRIMARY = 'PRIMARY'
}
export enum StagingApprovalOutcome {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}
export enum StagingRequirementType {
  BRAND_APPROVAL = 'BRAND_APPROVAL',
  MODERATOR_APPROVAL = 'MODERATOR_APPROVAL'
}
export enum StateChangeSource {
  AUTO_BUY = 'AUTO_BUY',
  MANUAL = 'MANUAL',
  USER = 'USER'
}
export enum StatusTier {
  BRONZE = 'BRONZE',
  DIAMOND = 'DIAMOND',
  GOLD = 'GOLD',
  NONE = 'NONE',
  PLATINUM = 'PLATINUM',
  SILVER = 'SILVER'
}
export enum StripeRegion {
  UK = 'UK',
  US = 'US'
}
export enum SupportPriorityFlag {
  STANDARD = 'STANDARD',
  TOP = 'TOP'
}
export enum TrackingStatus {
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  LIMITED = 'LIMITED'
}
export enum USAState {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY'
}
export enum UserCampaignAcceptanceStatus {
  CONSIDERED = 'CONSIDERED',
  PARTICIPANT = 'PARTICIPANT',
  PENDING = 'PENDING',
  REMOVED = 'REMOVED'
}
export enum UserCampaignApprovalStatus {
  APPROVED = 'APPROVED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  NOT_READY_FOR_APPROVAL = 'NOT_READY_FOR_APPROVAL'
}
export enum UserCampaignChangelogEntryType {
  CONTENT_APPROVAL_ADMIN_APPROVED = 'CONTENT_APPROVAL_ADMIN_APPROVED',
  CONTENT_APPROVAL_ADMIN_REJECTED = 'CONTENT_APPROVAL_ADMIN_REJECTED',
  CONTENT_APPROVAL_BRAND_APPROVED = 'CONTENT_APPROVAL_BRAND_APPROVED',
  CONTENT_APPROVAL_BRAND_REJECTED = 'CONTENT_APPROVAL_BRAND_REJECTED',
  CONTENT_APPROVAL_FEEDBACK_SUBMITTED = 'CONTENT_APPROVAL_FEEDBACK_SUBMITTED',
  CONTENT_APPROVAL_MODERATOR_APPROVED = 'CONTENT_APPROVAL_MODERATOR_APPROVED',
  CONTENT_APPROVAL_MODERATOR_REJECTED = 'CONTENT_APPROVAL_MODERATOR_REJECTED',
  CONTENT_DEADLINE_CHANGED = 'CONTENT_DEADLINE_CHANGED',
  CONTENT_DRAFT_DEADLINE_CHANGED = 'CONTENT_DRAFT_DEADLINE_CHANGED',
  CONTENT_DRAFT_SUBMITTED = 'CONTENT_DRAFT_SUBMITTED',
  CONTENT_SUBMITTED = 'CONTENT_SUBMITTED',
  PAYMENT_CARD_DELETED = 'PAYMENT_CARD_DELETED',
  PAYMENT_CARD_GENERATED_MANUAL = 'PAYMENT_CARD_GENERATED_MANUAL',
  PAYMENT_CUSTOM_BONUS_SET = 'PAYMENT_CUSTOM_BONUS_SET',
  PAYMENT_PAYOUT_RECEIVED = 'PAYMENT_PAYOUT_RECEIVED',
  STAGING_APPROVAL_BRAND_APPROVED = 'STAGING_APPROVAL_BRAND_APPROVED',
  STAGING_APPROVAL_BRAND_REJECTED = 'STAGING_APPROVAL_BRAND_REJECTED',
  STAGING_APPROVAL_INTERNAL_APPROVER_APPROVED = 'STAGING_APPROVAL_INTERNAL_APPROVER_APPROVED',
  STAGING_APPROVAL_INTERNAL_APPROVER_REJECTED = 'STAGING_APPROVAL_INTERNAL_APPROVER_REJECTED',
  STAGING_APPROVAL_PRE_APPROVER_APPROVED = 'STAGING_APPROVAL_PRE_APPROVER_APPROVED',
  STAGING_APPROVAL_PRE_APPROVER_REJECTED = 'STAGING_APPROVAL_PRE_APPROVER_REJECTED',
  STAGING_APPROVAL_PRE_VETTER_APPROVED = 'STAGING_APPROVAL_PRE_VETTER_APPROVED',
  STAGING_APPROVAL_SENT_TO_BRAND = 'STAGING_APPROVAL_SENT_TO_BRAND',
  STAGING_APPROVAL_STATUS_CHANGE_BACKUP = 'STAGING_APPROVAL_STATUS_CHANGE_BACKUP',
  STAGING_APPROVAL_STATUS_CHANGE_PRIMARY = 'STAGING_APPROVAL_STATUS_CHANGE_PRIMARY',
  STATE_CHANGE_AUTOBUY = 'STATE_CHANGE_AUTOBUY',
  STATE_CHANGE_CREATOR = 'STATE_CHANGE_CREATOR',
  STATE_CHANGE_INTERNAL = 'STATE_CHANGE_INTERNAL'
}
export enum UserCampaignSocialContentReviewOutcome {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}
export enum UserCampaignSocialContentReviewStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING'
}
export enum UserCampaignSocialContentReviewType {
  ADMIN_APPROVAL = 'ADMIN_APPROVAL',
  ASPECT_RATIO_CHECK = 'ASPECT_RATIO_CHECK',
  AUDIO_CHECK = 'AUDIO_CHECK',
  BRAND_APPROVAL = 'BRAND_APPROVAL',
  EXPLICIT_AUDIO_CHECK = 'EXPLICIT_AUDIO_CHECK',
  MODERATOR_APPROVAL = 'MODERATOR_APPROVAL',
  SIMILAR_CONTENT_CHECK = 'SIMILAR_CONTENT_CHECK',
  VIDEO_QUALITY_CHECK = 'VIDEO_QUALITY_CHECK'
}
export enum UserCampaignStateChangeRequestType {
  acknowledgeContentWasRejected = 'acknowledgeContentWasRejected',
  autoJoinAndUpdateTime = 'autoJoinAndUpdateTime',
  createManualSocialContent = 'createManualSocialContent',
  endCampaignAndUpdateTime = 'endCampaignAndUpdateTime',
  generatePPCLink = 'generatePPCLink',
  getNextStateAndUpdateTime = 'getNextStateAndUpdateTime',
  resurrect = 'resurrect',
  submitCampaignApplicationAndUpdateTime = 'submitCampaignApplicationAndUpdateTime'
}
export enum UserFilterGender {
  Female = 'Female',
  Male = 'Male',
  NonBinary = 'NonBinary',
  None = 'None'
}
export enum UserGender {
  Female = 'Female',
  Male = 'Male',
  NonBinary = 'NonBinary',
  None = 'None'
}
export enum UserStagingApprovalAction {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  SEND_TO_BRAND = 'SEND_TO_BRAND'
}
export enum UserStagingApprovalOutcome {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}
export enum UserStagingApprovalReviewSource {
  MOMENTUM = 'MOMENTUM',
  PRODUCTWIND = 'PRODUCTWIND',
  WESPONSORED = 'WESPONSORED'
}
export enum UserStagingApprovalType {
  ADMIN_APPROVAL = 'ADMIN_APPROVAL',
  BRAND_APPROVAL = 'BRAND_APPROVAL',
  MODERATOR_APPROVAL = 'MODERATOR_APPROVAL'
}
export enum ValidMccCodes {
  book_stores = 'book_stores',
  computer_network_services = 'computer_network_services',
  digital_goods_large_volume = 'digital_goods_large_volume',
  discount_stores = 'discount_stores',
  electronics_stores = 'electronics_stores',
  grocery_stores_supermarkets = 'grocery_stores_supermarkets',
  miscellaneous_specialty_retail = 'miscellaneous_specialty_retail',
  not_set = 'not_set',
  package_stores_beer_wine_and_liquor = 'package_stores_beer_wine_and_liquor',
  wholesale_clubs = 'wholesale_clubs'
}
export enum WSLocale {
  AE = 'AE',
  AU = 'AU',
  BR = 'BR',
  CA = 'CA',
  DE = 'DE',
  EG = 'EG',
  ES = 'ES',
  FR = 'FR',
  IN = 'IN',
  IT = 'IT',
  JP = 'JP',
  MX = 'MX',
  NL = 'NL',
  PL = 'PL',
  SA = 'SA',
  SE = 'SE',
  SG = 'SG',
  TR = 'TR',
  UK = 'UK',
  US = 'US'
}

type ZEUS_VARIABLES = {
  ['Active']: ValueTypes['Active']
  ['AdminUpdateContentApprovalType']: ValueTypes['AdminUpdateContentApprovalType']
  ['AmazonReviewSubmissionStatus']: ValueTypes['AmazonReviewSubmissionStatus']
  ['ApplicationStatus']: ValueTypes['ApplicationStatus']
  ['ApprovalOutcome']: ValueTypes['ApprovalOutcome']
  ['ApprovalSort']: ValueTypes['ApprovalSort']
  ['ArchivedReason']: ValueTypes['ArchivedReason']
  ['AutoBuyDelayStrategy']: ValueTypes['AutoBuyDelayStrategy']
  ['AutoBuySkipReason']: ValueTypes['AutoBuySkipReason']
  ['AutoBuyStatus']: ValueTypes['AutoBuyStatus']
  ['BOPISValue']: ValueTypes['BOPISValue']
  ['BonusPayoutState']: ValueTypes['BonusPayoutState']
  ['BonusPayoutTypes']: ValueTypes['BonusPayoutTypes']
  ['BonusRequestTypes']: ValueTypes['BonusRequestTypes']
  ['BrandApiType']: ValueTypes['BrandApiType']
  ['BrandApiVersion']: ValueTypes['BrandApiVersion']
  ['BrandSafe']: ValueTypes['BrandSafe']
  ['CampaignBadgeType']: ValueTypes['CampaignBadgeType']
  ['CampaignGoal']: ValueTypes['CampaignGoal']
  ['CampaignProductType']: ValueTypes['CampaignProductType']
  ['CampaignReferralBonusFlag']: ValueTypes['CampaignReferralBonusFlag']
  ['CampaignSourcingSource']: ValueTypes['CampaignSourcingSource']
  ['CampaignStatus']: ValueTypes['CampaignStatus']
  ['Channel']: ValueTypes['Channel']
  ['ChatType']: ValueTypes['ChatType']
  ['CoarseCampaignStatus']: ValueTypes['CoarseCampaignStatus']
  ['ComplianceReviewCriteria']: ValueTypes['ComplianceReviewCriteria']
  ['ConnectedAccountType']: ValueTypes['ConnectedAccountType']
  ['ContentApprovalStatus']: ValueTypes['ContentApprovalStatus']
  ['ContentApprovalType']: ValueTypes['ContentApprovalType']
  ['ContentComplianceReviewStatus']: ValueTypes['ContentComplianceReviewStatus']
  ['ContentQualityRating']: ValueTypes['ContentQualityRating']
  ['ContentRatingReviewStatus']: ValueTypes['ContentRatingReviewStatus']
  ['ContentRatingSponsorshipContentInputSource']: ValueTypes['ContentRatingSponsorshipContentInputSource']
  ['ContentRequirementLevel']: ValueTypes['ContentRequirementLevel']
  ['ContentSafetyLabel']: ValueTypes['ContentSafetyLabel']
  ['ContentScanStatus']: ValueTypes['ContentScanStatus']
  ['ContentTypeRating']: ValueTypes['ContentTypeRating']
  ['ConversationChannel']: ValueTypes['ConversationChannel']
  ['ConversationInternalStatus']: ValueTypes['ConversationInternalStatus']
  ['ConversationMessageSource']: ValueTypes['ConversationMessageSource']
  ['ConversationMessageStatus']: ValueTypes['ConversationMessageStatus']
  ['ConversationStatus']: ValueTypes['ConversationStatus']
  ['CreationSourceVersion']: ValueTypes['CreationSourceVersion']
  ['CreatorPreference']: ValueTypes['CreatorPreference']
  ['Currency']: ValueTypes['Currency']
  ['Deranked_Reason']: ValueTypes['Deranked_Reason']
  ['DraftStatus']: ValueTypes['DraftStatus']
  ['EnabledFlag']: ValueTypes['EnabledFlag']
  ['FavoriteFlag']: ValueTypes['FavoriteFlag']
  ['FeatureFlagEnvironment']: ValueTypes['FeatureFlagEnvironment']
  ['FormComponentType']: ValueTypes['FormComponentType']
  ['FormFieldType']: ValueTypes['FormFieldType']
  ['FormType']: ValueTypes['FormType']
  ['FormValidationType']: ValueTypes['FormValidationType']
  ['FraudReason']: ValueTypes['FraudReason']
  ['FraudReviewStatus']: ValueTypes['FraudReviewStatus']
  ['InfluencerApprovalFlagV2']: ValueTypes['InfluencerApprovalFlagV2']
  ['InspirationalContentType']: ValueTypes['InspirationalContentType']
  ['InstagramGenderAgeDistributionGender']: ValueTypes['InstagramGenderAgeDistributionGender']
  ['ItemClassification']: ValueTypes['ItemClassification']
  ['KeywordAcceptanceResponse']: ValueTypes['KeywordAcceptanceResponse']
  ['LinkStorageType']: ValueTypes['LinkStorageType']
  ['ListingFulfillmentType']: ValueTypes['ListingFulfillmentType']
  ['ManualUploadType']: ValueTypes['ManualUploadType']
  ['ModelAttributeTypes']: ValueTypes['ModelAttributeTypes']
  ['ModelSortDirection']: ValueTypes['ModelSortDirection']
  ['NotificationStatus']: ValueTypes['NotificationStatus']
  ['OccurrenceConstraintPeriod']: ValueTypes['OccurrenceConstraintPeriod']
  ['OccurrenceConstraintType']: ValueTypes['OccurrenceConstraintType']
  ['OrderConfirmationScreenshotVerificationResult']: ValueTypes['OrderConfirmationScreenshotVerificationResult']
  ['OrderIdValidationStatus']: ValueTypes['OrderIdValidationStatus']
  ['Paused']: ValueTypes['Paused']
  ['PayForPerformanceFlag']: ValueTypes['PayForPerformanceFlag']
  ['PaymentStatus']: ValueTypes['PaymentStatus']
  ['PaymentTermsType']: ValueTypes['PaymentTermsType']
  ['Period']: ValueTypes['Period']
  ['PlatformInviteSource']: ValueTypes['PlatformInviteSource']
  ['PlatformPriority']: ValueTypes['PlatformPriority']
  ['PlatformSource']: ValueTypes['PlatformSource']
  ['PlatformStatus']: ValueTypes['PlatformStatus']
  ['PreApprovalStatus']: ValueTypes['PreApprovalStatus']
  ['ProductKeywordCreationSource']: ValueTypes['ProductKeywordCreationSource']
  ['ProductQualityLevel']: ValueTypes['ProductQualityLevel']
  ['PromoCodeType']: ValueTypes['PromoCodeType']
  ['PromoDiscountType']: ValueTypes['PromoDiscountType']
  ['PurchaseStatus']: ValueTypes['PurchaseStatus']
  ['PushChannel']: ValueTypes['PushChannel']
  ['ReferralType']: ValueTypes['ReferralType']
  ['RequiredProductLinkFlag']: ValueTypes['RequiredProductLinkFlag']
  ['ReviewFeedbackVisibility']: ValueTypes['ReviewFeedbackVisibility']
  ['ReviewScreenshotVerificationResult']: ValueTypes['ReviewScreenshotVerificationResult']
  ['RevocationType']: ValueTypes['RevocationType']
  ['ScrapeStatus']: ValueTypes['ScrapeStatus']
  ['SearchInputField']: ValueTypes['SearchInputField']
  ['SearchInputQueryScriptSource']: ValueTypes['SearchInputQueryScriptSource']
  ['SearchInputScriptType']: ValueTypes['SearchInputScriptType']
  ['SearchInputSortScriptSource']: ValueTypes['SearchInputSortScriptSource']
  ['SelfieVideoApprovalStatus']: ValueTypes['SelfieVideoApprovalStatus']
  ['ShopifyPromotionType']: ValueTypes['ShopifyPromotionType']
  ['SkuCreationSource']: ValueTypes['SkuCreationSource']
  ['SocialChannel']: ValueTypes['SocialChannel']
  ['SocialContentType']: ValueTypes['SocialContentType']
  ['SocialMediaPostContentType']: ValueTypes['SocialMediaPostContentType']
  ['SocialMediaPostGroupType']: ValueTypes['SocialMediaPostGroupType']
  ['SocialMetricName']: ValueTypes['SocialMetricName']
  ['SocialQuality']: ValueTypes['SocialQuality']
  ['SocialType']: ValueTypes['SocialType']
  ['SocialVerificationMethod']: ValueTypes['SocialVerificationMethod']
  ['SortOrder']: ValueTypes['SortOrder']
  ['SourcePriority']: ValueTypes['SourcePriority']
  ['StagingApprovalOutcome']: ValueTypes['StagingApprovalOutcome']
  ['StagingRequirementType']: ValueTypes['StagingRequirementType']
  ['StateChangeSource']: ValueTypes['StateChangeSource']
  ['StatusTier']: ValueTypes['StatusTier']
  ['StripeRegion']: ValueTypes['StripeRegion']
  ['SupportPriorityFlag']: ValueTypes['SupportPriorityFlag']
  ['TrackingStatus']: ValueTypes['TrackingStatus']
  ['USAState']: ValueTypes['USAState']
  ['UserCampaignAcceptanceStatus']: ValueTypes['UserCampaignAcceptanceStatus']
  ['UserCampaignApprovalStatus']: ValueTypes['UserCampaignApprovalStatus']
  ['UserCampaignChangelogEntryType']: ValueTypes['UserCampaignChangelogEntryType']
  ['UserCampaignSocialContentReviewOutcome']: ValueTypes['UserCampaignSocialContentReviewOutcome']
  ['UserCampaignSocialContentReviewStatus']: ValueTypes['UserCampaignSocialContentReviewStatus']
  ['UserCampaignSocialContentReviewType']: ValueTypes['UserCampaignSocialContentReviewType']
  ['UserCampaignStateChangeRequestType']: ValueTypes['UserCampaignStateChangeRequestType']
  ['UserFilterGender']: ValueTypes['UserFilterGender']
  ['UserGender']: ValueTypes['UserGender']
  ['UserStagingApprovalAction']: ValueTypes['UserStagingApprovalAction']
  ['UserStagingApprovalOutcome']: ValueTypes['UserStagingApprovalOutcome']
  ['UserStagingApprovalReviewSource']: ValueTypes['UserStagingApprovalReviewSource']
  ['UserStagingApprovalType']: ValueTypes['UserStagingApprovalType']
  ['ValidMccCodes']: ValueTypes['ValidMccCodes']
  ['WSLocale']: ValueTypes['WSLocale']
  ['AddressComponentsSearchFields']: ValueTypes['AddressComponentsSearchFields']
  ['AdminSendPushNotificationInput']: ValueTypes['AdminSendPushNotificationInput']
  ['ApplyToCampaignInput']: ValueTypes['ApplyToCampaignInput']
  ['ApprovalFilterValueInput']: ValueTypes['ApprovalFilterValueInput']
  ['ApproveUserCampaignForPurchaseInput']: ValueTypes['ApproveUserCampaignForPurchaseInput']
  ['AutoApprovalCriteriaInput']: ValueTypes['AutoApprovalCriteriaInput']
  ['AutoApprovalV2ConfigInput']: ValueTypes['AutoApprovalV2ConfigInput']
  ['AutoBuyScheduleInput']: ValueTypes['AutoBuyScheduleInput']
  ['BillingContactInput']: ValueTypes['BillingContactInput']
  ['CampaignBadgeInput']: ValueTypes['CampaignBadgeInput']
  ['ChannelConfigurationInput']: ValueTypes['ChannelConfigurationInput']
  ['ClicksRecordsInput']: ValueTypes['ClicksRecordsInput']
  ['CollisionDensityValueInput']: ValueTypes['CollisionDensityValueInput']
  ['ContentApprovalConfigurationInput']: ValueTypes['ContentApprovalConfigurationInput']
  ['ContentComplianceReviewCriteriaInput']: ValueTypes['ContentComplianceReviewCriteriaInput']
  ['ContentGuidanceInput']: ValueTypes['ContentGuidanceInput']
  ['ContentLabelResultInput']: ValueTypes['ContentLabelResultInput']
  ['ContentRatingSponsorshipContentInput']: ValueTypes['ContentRatingSponsorshipContentInput']
  ['ContentSafetyAnalysisInput']: ValueTypes['ContentSafetyAnalysisInput']
  ['ContentSafetyLabelSummaryInput']: ValueTypes['ContentSafetyLabelSummaryInput']
  ['ContentSafetySeverityInput']: ValueTypes['ContentSafetySeverityInput']
  ['ContentSafetyTextInstanceInput']: ValueTypes['ContentSafetyTextInstanceInput']
  ['CreateAutoBuyDistributionInput']: ValueTypes['CreateAutoBuyDistributionInput']
  ['CreateAutoBuyFlowConfigurationInput']: ValueTypes['CreateAutoBuyFlowConfigurationInput']
  ['CreateAutoBuyFlowReleaseScheduleInput']: ValueTypes['CreateAutoBuyFlowReleaseScheduleInput']
  ['CreateAutoBuyReleaseInput']: ValueTypes['CreateAutoBuyReleaseInput']
  ['CreateBrandApiInput']: ValueTypes['CreateBrandApiInput']
  ['CreateBrandInput']: ValueTypes['CreateBrandInput']
  ['CreateCampaignClicksInput']: ValueTypes['CreateCampaignClicksInput']
  ['CreateCampaignInput']: ValueTypes['CreateCampaignInput']
  ['CreateCampaignInviteInput']: ValueTypes['CreateCampaignInviteInput']
  ['CreateCampaignSourcingProspectInput']: ValueTypes['CreateCampaignSourcingProspectInput']
  ['CreateChatConversationV2Input']: ValueTypes['CreateChatConversationV2Input']
  ['CreateCommunicationNotificationInput']: ValueTypes['CreateCommunicationNotificationInput']
  ['CreateCompanyInput']: ValueTypes['CreateCompanyInput']
  ['CreateConnectedAccountInput']: ValueTypes['CreateConnectedAccountInput']
  ['CreateConversationInput']: ValueTypes['CreateConversationInput']
  ['CreateConversationMessageInput']: ValueTypes['CreateConversationMessageInput']
  ['CreateEtailerSkuMetricsInput']: ValueTypes['CreateEtailerSkuMetricsInput']
  ['CreateFormSequenceConfigurationInput']: ValueTypes['CreateFormSequenceConfigurationInput']
  ['CreateInstagramStoryScreenInput']: ValueTypes['CreateInstagramStoryScreenInput']
  ['CreateMessageInput']: ValueTypes['CreateMessageInput']
  ['CreateNoteInput']: ValueTypes['CreateNoteInput']
  ['CreateNotificationConfigurationInput']: ValueTypes['CreateNotificationConfigurationInput']
  ['CreateProductBonusConfigSnapshotInput']: ValueTypes['CreateProductBonusConfigSnapshotInput']
  ['CreateProductFromUrlInput']: ValueTypes['CreateProductFromUrlInput']
  ['CreateProductInput']: ValueTypes['CreateProductInput']
  ['CreateProductKeywordsInput']: ValueTypes['CreateProductKeywordsInput']
  ['CreateProductRatingSnapshotInput']: ValueTypes['CreateProductRatingSnapshotInput']
  ['CreatePurchaseInput']: ValueTypes['CreatePurchaseInput']
  ['CreateReferralInput']: ValueTypes['CreateReferralInput']
  ['CreateReviewInput']: ValueTypes['CreateReviewInput']
  ['CreateSkuInput']: ValueTypes['CreateSkuInput']
  ['CreateSkuSnapshotInput']: ValueTypes['CreateSkuSnapshotInput']
  ['CreateTwilioConversationInput']: ValueTypes['CreateTwilioConversationInput']
  ['CreateUserBonusPayoutInput']: ValueTypes['CreateUserBonusPayoutInput']
  ['CreateUserCampaignChangelogInput']: ValueTypes['CreateUserCampaignChangelogInput']
  ['CreateUserCampaignInput']: ValueTypes['CreateUserCampaignInput']
  ['CreateUserCampaignSnapshotInput']: ValueTypes['CreateUserCampaignSnapshotInput']
  ['CreateUserCampaignSocialContentDraftHistoryInput']: ValueTypes['CreateUserCampaignSocialContentDraftHistoryInput']
  ['CreateUserCampaignSocialContentInput']: ValueTypes['CreateUserCampaignSocialContentInput']
  ['CreateUserCampaignSocialContentReviewInput']: ValueTypes['CreateUserCampaignSocialContentReviewInput']
  ['CreateUserDeviceSnapshotInput']: ValueTypes['CreateUserDeviceSnapshotInput']
  ['CreateUserFollowerInput']: ValueTypes['CreateUserFollowerInput']
  ['CreateUserInput']: ValueTypes['CreateUserInput']
  ['CreateUserLinkInput']: ValueTypes['CreateUserLinkInput']
  ['CreateUserNotificationPreferenceInput']: ValueTypes['CreateUserNotificationPreferenceInput']
  ['CreateUserPayoutRequestInput']: ValueTypes['CreateUserPayoutRequestInput']
  ['CreateUserStagingApprovalInput']: ValueTypes['CreateUserStagingApprovalInput']
  ['CreateVideoContentScanInput']: ValueTypes['CreateVideoContentScanInput']
  ['CreatorContentRatingRawValueInput']: ValueTypes['CreatorContentRatingRawValueInput']
  ['DeleteAutoBuyDistributionInput']: ValueTypes['DeleteAutoBuyDistributionInput']
  ['DeleteAutoBuyFlowConfigurationInput']: ValueTypes['DeleteAutoBuyFlowConfigurationInput']
  ['DeleteAutoBuyFlowReleaseScheduleInput']: ValueTypes['DeleteAutoBuyFlowReleaseScheduleInput']
  ['DeleteAutoBuyReleaseInput']: ValueTypes['DeleteAutoBuyReleaseInput']
  ['DeleteBrandApiInput']: ValueTypes['DeleteBrandApiInput']
  ['DeleteBrandInput']: ValueTypes['DeleteBrandInput']
  ['DeleteCampaignClicksInput']: ValueTypes['DeleteCampaignClicksInput']
  ['DeleteCampaignInput']: ValueTypes['DeleteCampaignInput']
  ['DeleteCampaignInviteInput']: ValueTypes['DeleteCampaignInviteInput']
  ['DeleteCampaignSourcingProspectInput']: ValueTypes['DeleteCampaignSourcingProspectInput']
  ['DeleteChatConversationV2Input']: ValueTypes['DeleteChatConversationV2Input']
  ['DeleteCommunicationNotificationInput']: ValueTypes['DeleteCommunicationNotificationInput']
  ['DeleteCompanyInput']: ValueTypes['DeleteCompanyInput']
  ['DeleteConnectedAccountInput']: ValueTypes['DeleteConnectedAccountInput']
  ['DeleteConversationInput']: ValueTypes['DeleteConversationInput']
  ['DeleteConversationMessageInput']: ValueTypes['DeleteConversationMessageInput']
  ['DeleteEtailerSkuMetricsInput']: ValueTypes['DeleteEtailerSkuMetricsInput']
  ['DeleteFormSequenceConfigurationInput']: ValueTypes['DeleteFormSequenceConfigurationInput']
  ['DeleteInstagramStoryScreenInput']: ValueTypes['DeleteInstagramStoryScreenInput']
  ['DeleteMessageInput']: ValueTypes['DeleteMessageInput']
  ['DeleteNoteInput']: ValueTypes['DeleteNoteInput']
  ['DeleteNotificationConfigurationInput']: ValueTypes['DeleteNotificationConfigurationInput']
  ['DeleteProductBonusConfigSnapshotInput']: ValueTypes['DeleteProductBonusConfigSnapshotInput']
  ['DeleteProductInput']: ValueTypes['DeleteProductInput']
  ['DeleteProductKeywordsInput']: ValueTypes['DeleteProductKeywordsInput']
  ['DeleteProductRatingSnapshotInput']: ValueTypes['DeleteProductRatingSnapshotInput']
  ['DeletePurchaseInput']: ValueTypes['DeletePurchaseInput']
  ['DeleteReferralInput']: ValueTypes['DeleteReferralInput']
  ['DeleteReviewInput']: ValueTypes['DeleteReviewInput']
  ['DeleteSkuInput']: ValueTypes['DeleteSkuInput']
  ['DeleteSkuSnapshotInput']: ValueTypes['DeleteSkuSnapshotInput']
  ['DeleteUserBonusPayoutInput']: ValueTypes['DeleteUserBonusPayoutInput']
  ['DeleteUserCampaignChangelogInput']: ValueTypes['DeleteUserCampaignChangelogInput']
  ['DeleteUserCampaignInput']: ValueTypes['DeleteUserCampaignInput']
  ['DeleteUserCampaignSnapshotInput']: ValueTypes['DeleteUserCampaignSnapshotInput']
  ['DeleteUserCampaignSocialContentDraftHistoryInput']: ValueTypes['DeleteUserCampaignSocialContentDraftHistoryInput']
  ['DeleteUserCampaignSocialContentInput']: ValueTypes['DeleteUserCampaignSocialContentInput']
  ['DeleteUserCampaignSocialContentReviewInput']: ValueTypes['DeleteUserCampaignSocialContentReviewInput']
  ['DeleteUserDeviceSnapshotInput']: ValueTypes['DeleteUserDeviceSnapshotInput']
  ['DeleteUserFollowerInput']: ValueTypes['DeleteUserFollowerInput']
  ['DeleteUserInput']: ValueTypes['DeleteUserInput']
  ['DeleteUserLinkInput']: ValueTypes['DeleteUserLinkInput']
  ['DeleteUserNotificationPreferenceInput']: ValueTypes['DeleteUserNotificationPreferenceInput']
  ['DeleteUserPayoutRequestInput']: ValueTypes['DeleteUserPayoutRequestInput']
  ['DeleteUserStagingApprovalInput']: ValueTypes['DeleteUserStagingApprovalInput']
  ['DeleteVideoContentScanInput']: ValueTypes['DeleteVideoContentScanInput']
  ['FollowersSearchFields']: ValueTypes['FollowersSearchFields']
  ['FormSequenceComponentInput']: ValueTypes['FormSequenceComponentInput']
  ['FormSequenceInput']: ValueTypes['FormSequenceInput']
  ['InspirationalContentInput']: ValueTypes['InspirationalContentInput']
  ['InstagramCountryDistributionItemInput']: ValueTypes['InstagramCountryDistributionItemInput']
  ['InstagramFollowerCountryDistributionSearchFields']: ValueTypes['InstagramFollowerCountryDistributionSearchFields']
  ['InstagramGenderAgeDistributionInput']: ValueTypes['InstagramGenderAgeDistributionInput']
  ['InstagramGenderAgeDistributionItemInput']: ValueTypes['InstagramGenderAgeDistributionItemInput']
  ['InviteUsersToCampaignInput']: ValueTypes['InviteUsersToCampaignInput']
  ['KeyAttributeInput']: ValueTypes['KeyAttributeInput']
  ['MessagingChannelConfigurationInput']: ValueTypes['MessagingChannelConfigurationInput']
  ['MinMaxRangeInput']: ValueTypes['MinMaxRangeInput']
  ['ModelActiveInput']: ValueTypes['ModelActiveInput']
  ['ModelAmazonReviewSubmissionStatusInput']: ValueTypes['ModelAmazonReviewSubmissionStatusInput']
  ['ModelApplicationStatusInput']: ValueTypes['ModelApplicationStatusInput']
  ['ModelApprovalOutcomeInput']: ValueTypes['ModelApprovalOutcomeInput']
  ['ModelArchivedReasonInput']: ValueTypes['ModelArchivedReasonInput']
  ['ModelAutoBuyDelayStrategyInput']: ValueTypes['ModelAutoBuyDelayStrategyInput']
  ['ModelAutoBuyDistributionConditionInput']: ValueTypes['ModelAutoBuyDistributionConditionInput']
  ['ModelAutoBuyDistributionFilterInput']: ValueTypes['ModelAutoBuyDistributionFilterInput']
  ['ModelAutoBuyFlowConfigurationConditionInput']: ValueTypes['ModelAutoBuyFlowConfigurationConditionInput']
  ['ModelAutoBuyFlowConfigurationFilterInput']: ValueTypes['ModelAutoBuyFlowConfigurationFilterInput']
  ['ModelAutoBuyFlowReleaseScheduleConditionInput']: ValueTypes['ModelAutoBuyFlowReleaseScheduleConditionInput']
  ['ModelAutoBuyFlowReleaseScheduleFilterInput']: ValueTypes['ModelAutoBuyFlowReleaseScheduleFilterInput']
  ['ModelAutoBuyReleaseConditionInput']: ValueTypes['ModelAutoBuyReleaseConditionInput']
  ['ModelAutoBuyReleaseFilterInput']: ValueTypes['ModelAutoBuyReleaseFilterInput']
  ['ModelAutoBuySkipReasonInput']: ValueTypes['ModelAutoBuySkipReasonInput']
  ['ModelAutoBuyStatusInput']: ValueTypes['ModelAutoBuyStatusInput']
  ['ModelBOPISValueInput']: ValueTypes['ModelBOPISValueInput']
  ['ModelBonusPayoutStateInput']: ValueTypes['ModelBonusPayoutStateInput']
  ['ModelBonusPayoutTypesInput']: ValueTypes['ModelBonusPayoutTypesInput']
  ['ModelBooleanInput']: ValueTypes['ModelBooleanInput']
  ['ModelBrandApiConditionInput']: ValueTypes['ModelBrandApiConditionInput']
  ['ModelBrandApiFilterInput']: ValueTypes['ModelBrandApiFilterInput']
  ['ModelBrandApiTypeInput']: ValueTypes['ModelBrandApiTypeInput']
  ['ModelBrandApiVersionInput']: ValueTypes['ModelBrandApiVersionInput']
  ['ModelBrandConditionInput']: ValueTypes['ModelBrandConditionInput']
  ['ModelBrandFilterInput']: ValueTypes['ModelBrandFilterInput']
  ['ModelBrandSafeInput']: ValueTypes['ModelBrandSafeInput']
  ['ModelCampaignClicksConditionInput']: ValueTypes['ModelCampaignClicksConditionInput']
  ['ModelCampaignClicksFilterInput']: ValueTypes['ModelCampaignClicksFilterInput']
  ['ModelCampaignConditionInput']: ValueTypes['ModelCampaignConditionInput']
  ['ModelCampaignFilterInput']: ValueTypes['ModelCampaignFilterInput']
  ['ModelCampaignGoalInput']: ValueTypes['ModelCampaignGoalInput']
  ['ModelCampaignInviteConditionInput']: ValueTypes['ModelCampaignInviteConditionInput']
  ['ModelCampaignInviteFilterInput']: ValueTypes['ModelCampaignInviteFilterInput']
  ['ModelCampaignProductTypeInput']: ValueTypes['ModelCampaignProductTypeInput']
  ['ModelCampaignReferralBonusFlagInput']: ValueTypes['ModelCampaignReferralBonusFlagInput']
  ['ModelCampaignSourcingProspectConditionInput']: ValueTypes['ModelCampaignSourcingProspectConditionInput']
  ['ModelCampaignSourcingProspectFilterInput']: ValueTypes['ModelCampaignSourcingProspectFilterInput']
  ['ModelCampaignSourcingSourceInput']: ValueTypes['ModelCampaignSourcingSourceInput']
  ['ModelCampaignStatusInput']: ValueTypes['ModelCampaignStatusInput']
  ['ModelChannelInput']: ValueTypes['ModelChannelInput']
  ['ModelChatConversationV2ConditionInput']: ValueTypes['ModelChatConversationV2ConditionInput']
  ['ModelChatConversationV2FilterInput']: ValueTypes['ModelChatConversationV2FilterInput']
  ['ModelChatTypeInput']: ValueTypes['ModelChatTypeInput']
  ['ModelCoarseCampaignStatusInput']: ValueTypes['ModelCoarseCampaignStatusInput']
  ['ModelCommunicationNotificationConditionInput']: ValueTypes['ModelCommunicationNotificationConditionInput']
  ['ModelCommunicationNotificationFilterInput']: ValueTypes['ModelCommunicationNotificationFilterInput']
  ['ModelCompanyConditionInput']: ValueTypes['ModelCompanyConditionInput']
  ['ModelCompanyFilterInput']: ValueTypes['ModelCompanyFilterInput']
  ['ModelConnectedAccountConditionInput']: ValueTypes['ModelConnectedAccountConditionInput']
  ['ModelConnectedAccountFilterInput']: ValueTypes['ModelConnectedAccountFilterInput']
  ['ModelConnectedAccountTypeInput']: ValueTypes['ModelConnectedAccountTypeInput']
  ['ModelContentApprovalStatusInput']: ValueTypes['ModelContentApprovalStatusInput']
  ['ModelContentComplianceReviewStatusInput']: ValueTypes['ModelContentComplianceReviewStatusInput']
  ['ModelContentRatingReviewStatusInput']: ValueTypes['ModelContentRatingReviewStatusInput']
  ['ModelContentRequirementLevelInput']: ValueTypes['ModelContentRequirementLevelInput']
  ['ModelContentScanStatusInput']: ValueTypes['ModelContentScanStatusInput']
  ['ModelContentTypeRatingListInput']: ValueTypes['ModelContentTypeRatingListInput']
  ['ModelConversationChannelInput']: ValueTypes['ModelConversationChannelInput']
  ['ModelConversationConditionInput']: ValueTypes['ModelConversationConditionInput']
  ['ModelConversationFilterInput']: ValueTypes['ModelConversationFilterInput']
  ['ModelConversationInternalStatusInput']: ValueTypes['ModelConversationInternalStatusInput']
  ['ModelConversationMessageConditionInput']: ValueTypes['ModelConversationMessageConditionInput']
  ['ModelConversationMessageFilterInput']: ValueTypes['ModelConversationMessageFilterInput']
  ['ModelConversationMessageSourceInput']: ValueTypes['ModelConversationMessageSourceInput']
  ['ModelConversationMessageStatusInput']: ValueTypes['ModelConversationMessageStatusInput']
  ['ModelConversationStatusInput']: ValueTypes['ModelConversationStatusInput']
  ['ModelCreationSourceVersionInput']: ValueTypes['ModelCreationSourceVersionInput']
  ['ModelCreatorPreferenceInput']: ValueTypes['ModelCreatorPreferenceInput']
  ['ModelCurrencyInput']: ValueTypes['ModelCurrencyInput']
  ['ModelDeranked_ReasonInput']: ValueTypes['ModelDeranked_ReasonInput']
  ['ModelDraftStatusInput']: ValueTypes['ModelDraftStatusInput']
  ['ModelEnabledFlagInput']: ValueTypes['ModelEnabledFlagInput']
  ['ModelEtailerSkuMetricsConditionInput']: ValueTypes['ModelEtailerSkuMetricsConditionInput']
  ['ModelEtailerSkuMetricsFilterInput']: ValueTypes['ModelEtailerSkuMetricsFilterInput']
  ['ModelFavoriteFlagInput']: ValueTypes['ModelFavoriteFlagInput']
  ['ModelFloatInput']: ValueTypes['ModelFloatInput']
  ['ModelFloatKeyConditionInput']: ValueTypes['ModelFloatKeyConditionInput']
  ['ModelFormSequenceConfigurationConditionInput']: ValueTypes['ModelFormSequenceConfigurationConditionInput']
  ['ModelFormSequenceConfigurationFilterInput']: ValueTypes['ModelFormSequenceConfigurationFilterInput']
  ['ModelFraudReasonInput']: ValueTypes['ModelFraudReasonInput']
  ['ModelFraudReviewStatusInput']: ValueTypes['ModelFraudReviewStatusInput']
  ['ModelIDInput']: ValueTypes['ModelIDInput']
  ['ModelIDKeyConditionInput']: ValueTypes['ModelIDKeyConditionInput']
  ['ModelInfluencerApprovalFlagV2Input']: ValueTypes['ModelInfluencerApprovalFlagV2Input']
  ['ModelInstagramStoryScreenConditionInput']: ValueTypes['ModelInstagramStoryScreenConditionInput']
  ['ModelInstagramStoryScreenFilterInput']: ValueTypes['ModelInstagramStoryScreenFilterInput']
  ['ModelIntInput']: ValueTypes['ModelIntInput']
  ['ModelIntKeyConditionInput']: ValueTypes['ModelIntKeyConditionInput']
  ['ModelItemClassificationInput']: ValueTypes['ModelItemClassificationInput']
  ['ModelKeywordAcceptanceResponseInput']: ValueTypes['ModelKeywordAcceptanceResponseInput']
  ['ModelLinkStorageTypeInput']: ValueTypes['ModelLinkStorageTypeInput']
  ['ModelListingFulfillmentTypeInput']: ValueTypes['ModelListingFulfillmentTypeInput']
  ['ModelManualUploadTypeInput']: ValueTypes['ModelManualUploadTypeInput']
  ['ModelMessageConditionInput']: ValueTypes['ModelMessageConditionInput']
  ['ModelMessageFilterInput']: ValueTypes['ModelMessageFilterInput']
  ['ModelNoteConditionInput']: ValueTypes['ModelNoteConditionInput']
  ['ModelNoteFilterInput']: ValueTypes['ModelNoteFilterInput']
  ['ModelNotificationConfigurationConditionInput']: ValueTypes['ModelNotificationConfigurationConditionInput']
  ['ModelNotificationConfigurationFilterInput']: ValueTypes['ModelNotificationConfigurationFilterInput']
  ['ModelNotificationStatusInput']: ValueTypes['ModelNotificationStatusInput']
  ['ModelOrderIdValidationStatusInput']: ValueTypes['ModelOrderIdValidationStatusInput']
  ['ModelPayForPerformanceFlagInput']: ValueTypes['ModelPayForPerformanceFlagInput']
  ['ModelPaymentTermsTypeInput']: ValueTypes['ModelPaymentTermsTypeInput']
  ['ModelPlatformInviteSourceInput']: ValueTypes['ModelPlatformInviteSourceInput']
  ['ModelPlatformPriorityInput']: ValueTypes['ModelPlatformPriorityInput']
  ['ModelPlatformSourceInput']: ValueTypes['ModelPlatformSourceInput']
  ['ModelPlatformStatusInput']: ValueTypes['ModelPlatformStatusInput']
  ['ModelPreApprovalStatusInput']: ValueTypes['ModelPreApprovalStatusInput']
  ['ModelProductBonusConfigSnapshotConditionInput']: ValueTypes['ModelProductBonusConfigSnapshotConditionInput']
  ['ModelProductBonusConfigSnapshotFilterInput']: ValueTypes['ModelProductBonusConfigSnapshotFilterInput']
  ['ModelProductConditionInput']: ValueTypes['ModelProductConditionInput']
  ['ModelProductFilterInput']: ValueTypes['ModelProductFilterInput']
  ['ModelProductKeywordCreationSourceInput']: ValueTypes['ModelProductKeywordCreationSourceInput']
  ['ModelProductKeywordsConditionInput']: ValueTypes['ModelProductKeywordsConditionInput']
  ['ModelProductKeywordsFilterInput']: ValueTypes['ModelProductKeywordsFilterInput']
  ['ModelProductQualityLevelInput']: ValueTypes['ModelProductQualityLevelInput']
  ['ModelProductRatingSnapshotConditionInput']: ValueTypes['ModelProductRatingSnapshotConditionInput']
  ['ModelProductRatingSnapshotFilterInput']: ValueTypes['ModelProductRatingSnapshotFilterInput']
  ['ModelPromoCodeTypeInput']: ValueTypes['ModelPromoCodeTypeInput']
  ['ModelPromoDiscountTypeInput']: ValueTypes['ModelPromoDiscountTypeInput']
  ['ModelPurchaseConditionInput']: ValueTypes['ModelPurchaseConditionInput']
  ['ModelPurchaseFilterInput']: ValueTypes['ModelPurchaseFilterInput']
  ['ModelPurchaseStatusInput']: ValueTypes['ModelPurchaseStatusInput']
  ['ModelReferralConditionInput']: ValueTypes['ModelReferralConditionInput']
  ['ModelReferralFilterInput']: ValueTypes['ModelReferralFilterInput']
  ['ModelReferralTypeInput']: ValueTypes['ModelReferralTypeInput']
  ['ModelRequiredProductLinkFlagInput']: ValueTypes['ModelRequiredProductLinkFlagInput']
  ['ModelReviewConditionInput']: ValueTypes['ModelReviewConditionInput']
  ['ModelReviewFilterInput']: ValueTypes['ModelReviewFilterInput']
  ['ModelReviewScreenshotVerificationResultInput']: ValueTypes['ModelReviewScreenshotVerificationResultInput']
  ['ModelRevocationTypeInput']: ValueTypes['ModelRevocationTypeInput']
  ['ModelScrapeStatusInput']: ValueTypes['ModelScrapeStatusInput']
  ['ModelSelfieVideoApprovalStatusInput']: ValueTypes['ModelSelfieVideoApprovalStatusInput']
  ['ModelShopifyPromotionTypeInput']: ValueTypes['ModelShopifyPromotionTypeInput']
  ['ModelSizeInput']: ValueTypes['ModelSizeInput']
  ['ModelSkuConditionInput']: ValueTypes['ModelSkuConditionInput']
  ['ModelSkuCreationSourceInput']: ValueTypes['ModelSkuCreationSourceInput']
  ['ModelSkuFilterInput']: ValueTypes['ModelSkuFilterInput']
  ['ModelSkuSnapshotBySkuIdCompositeKeyConditionInput']: ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyConditionInput']
  ['ModelSkuSnapshotBySkuIdCompositeKeyInput']: ValueTypes['ModelSkuSnapshotBySkuIdCompositeKeyInput']
  ['ModelSkuSnapshotConditionInput']: ValueTypes['ModelSkuSnapshotConditionInput']
  ['ModelSkuSnapshotFilterInput']: ValueTypes['ModelSkuSnapshotFilterInput']
  ['ModelSocialChannelInput']: ValueTypes['ModelSocialChannelInput']
  ['ModelSocialContentTypeInput']: ValueTypes['ModelSocialContentTypeInput']
  ['ModelSocialQualityInput']: ValueTypes['ModelSocialQualityInput']
  ['ModelSocialVerificationMethodInput']: ValueTypes['ModelSocialVerificationMethodInput']
  ['ModelSourcePriorityInput']: ValueTypes['ModelSourcePriorityInput']
  ['ModelStagingApprovalOutcomeInput']: ValueTypes['ModelStagingApprovalOutcomeInput']
  ['ModelStagingRequirementTypeInput']: ValueTypes['ModelStagingRequirementTypeInput']
  ['ModelStatusTierInput']: ValueTypes['ModelStatusTierInput']
  ['ModelStatusTierListInput']: ValueTypes['ModelStatusTierListInput']
  ['ModelStringInput']: ValueTypes['ModelStringInput']
  ['ModelStringKeyConditionInput']: ValueTypes['ModelStringKeyConditionInput']
  ['ModelStripeRegionInput']: ValueTypes['ModelStripeRegionInput']
  ['ModelSupportPriorityFlagInput']: ValueTypes['ModelSupportPriorityFlagInput']
  ['ModelTrackingStatusInput']: ValueTypes['ModelTrackingStatusInput']
  ['ModelUserBonusPayoutConditionInput']: ValueTypes['ModelUserBonusPayoutConditionInput']
  ['ModelUserBonusPayoutFilterInput']: ValueTypes['ModelUserBonusPayoutFilterInput']
  ['ModelUserCampaignAcceptanceStatusInput']: ValueTypes['ModelUserCampaignAcceptanceStatusInput']
  ['ModelUserCampaignApprovalStatusInput']: ValueTypes['ModelUserCampaignApprovalStatusInput']
  ['ModelUserCampaignChangelogConditionInput']: ValueTypes['ModelUserCampaignChangelogConditionInput']
  ['ModelUserCampaignChangelogEntryTypeInput']: ValueTypes['ModelUserCampaignChangelogEntryTypeInput']
  ['ModelUserCampaignChangelogFilterInput']: ValueTypes['ModelUserCampaignChangelogFilterInput']
  ['ModelUserCampaignConditionInput']: ValueTypes['ModelUserCampaignConditionInput']
  ['ModelUserCampaignFilterInput']: ValueTypes['ModelUserCampaignFilterInput']
  ['ModelUserCampaignSnapshotConditionInput']: ValueTypes['ModelUserCampaignSnapshotConditionInput']
  ['ModelUserCampaignSnapshotFilterInput']: ValueTypes['ModelUserCampaignSnapshotFilterInput']
  ['ModelUserCampaignSocialContentConditionInput']: ValueTypes['ModelUserCampaignSocialContentConditionInput']
  ['ModelUserCampaignSocialContentDraftHistoryConditionInput']: ValueTypes['ModelUserCampaignSocialContentDraftHistoryConditionInput']
  ['ModelUserCampaignSocialContentDraftHistoryFilterInput']: ValueTypes['ModelUserCampaignSocialContentDraftHistoryFilterInput']
  ['ModelUserCampaignSocialContentFilterInput']: ValueTypes['ModelUserCampaignSocialContentFilterInput']
  ['ModelUserCampaignSocialContentReviewConditionInput']: ValueTypes['ModelUserCampaignSocialContentReviewConditionInput']
  ['ModelUserCampaignSocialContentReviewFilterInput']: ValueTypes['ModelUserCampaignSocialContentReviewFilterInput']
  ['ModelUserCampaignSocialContentReviewOutcomeInput']: ValueTypes['ModelUserCampaignSocialContentReviewOutcomeInput']
  ['ModelUserCampaignSocialContentReviewStatusInput']: ValueTypes['ModelUserCampaignSocialContentReviewStatusInput']
  ['ModelUserCampaignSocialContentReviewTypeInput']: ValueTypes['ModelUserCampaignSocialContentReviewTypeInput']
  ['ModelUserConditionInput']: ValueTypes['ModelUserConditionInput']
  ['ModelUserDeviceSnapshotConditionInput']: ValueTypes['ModelUserDeviceSnapshotConditionInput']
  ['ModelUserDeviceSnapshotFilterInput']: ValueTypes['ModelUserDeviceSnapshotFilterInput']
  ['ModelUserFilterGenderInput']: ValueTypes['ModelUserFilterGenderInput']
  ['ModelUserFilterInput']: ValueTypes['ModelUserFilterInput']
  ['ModelUserFollowerConditionInput']: ValueTypes['ModelUserFollowerConditionInput']
  ['ModelUserFollowerFilterInput']: ValueTypes['ModelUserFollowerFilterInput']
  ['ModelUserGenderInput']: ValueTypes['ModelUserGenderInput']
  ['ModelUserLinkConditionInput']: ValueTypes['ModelUserLinkConditionInput']
  ['ModelUserLinkFilterInput']: ValueTypes['ModelUserLinkFilterInput']
  ['ModelUserNotificationPreferenceConditionInput']: ValueTypes['ModelUserNotificationPreferenceConditionInput']
  ['ModelUserNotificationPreferenceFilterInput']: ValueTypes['ModelUserNotificationPreferenceFilterInput']
  ['ModelUserPayoutRequestConditionInput']: ValueTypes['ModelUserPayoutRequestConditionInput']
  ['ModelUserPayoutRequestFilterInput']: ValueTypes['ModelUserPayoutRequestFilterInput']
  ['ModelUserStagingApprovalConditionInput']: ValueTypes['ModelUserStagingApprovalConditionInput']
  ['ModelUserStagingApprovalFilterInput']: ValueTypes['ModelUserStagingApprovalFilterInput']
  ['ModelUserStagingApprovalOutcomeInput']: ValueTypes['ModelUserStagingApprovalOutcomeInput']
  ['ModelUserStagingApprovalReviewSourceInput']: ValueTypes['ModelUserStagingApprovalReviewSourceInput']
  ['ModelUserStagingApprovalTypeInput']: ValueTypes['ModelUserStagingApprovalTypeInput']
  ['ModelValidMccCodesInput']: ValueTypes['ModelValidMccCodesInput']
  ['ModelVideoContentScanConditionInput']: ValueTypes['ModelVideoContentScanConditionInput']
  ['ModelVideoContentScanFilterInput']: ValueTypes['ModelVideoContentScanFilterInput']
  ['ModelWSLocaleInput']: ValueTypes['ModelWSLocaleInput']
  ['OccurrenceConstraintInput']: ValueTypes['OccurrenceConstraintInput']
  ['PayForImpressionsBonusConfigInput']: ValueTypes['PayForImpressionsBonusConfigInput']
  ['PayForPostingContentEarlyConfigInput']: ValueTypes['PayForPostingContentEarlyConfigInput']
  ['ProductSocialContentInput']: ValueTypes['ProductSocialContentInput']
  ['ProspectSearchFields']: ValueTypes['ProspectSearchFields']
  ['PushAddressInput']: ValueTypes['PushAddressInput']
  ['PushChannelConfigurationInput']: ValueTypes['PushChannelConfigurationInput']
  ['RankPointInput']: ValueTypes['RankPointInput']
  ['RateInput']: ValueTypes['RateInput']
  ['RequestedContentChangesInput']: ValueTypes['RequestedContentChangesInput']
  ['RollbackAdditionalArguments']: ValueTypes['RollbackAdditionalArguments']
  ['SearchInputBoolean']: ValueTypes['SearchInputBoolean']
  ['SearchInputNumber']: ValueTypes['SearchInputNumber']
  ['SearchInputNumberRange']: ValueTypes['SearchInputNumberRange']
  ['SearchInputQueryScript']: ValueTypes['SearchInputQueryScript']
  ['SearchInputScriptParams']: ValueTypes['SearchInputScriptParams']
  ['SearchInputSortScript']: ValueTypes['SearchInputSortScript']
  ['SearchInputString']: ValueTypes['SearchInputString']
  ['SearchInputStringRange']: ValueTypes['SearchInputStringRange']
  ['SequenceFormSubmissionValuesInput']: ValueTypes['SequenceFormSubmissionValuesInput']
  ['SkuAttributeInput']: ValueTypes['SkuAttributeInput']
  ['SocialMediaFeedbackInput']: ValueTypes['SocialMediaFeedbackInput']
  ['SocialMetricInput']: ValueTypes['SocialMetricInput']
  ['StagingApprovalConfigurationInput']: ValueTypes['StagingApprovalConfigurationInput']
  ['StatusTierBonusConfigInput']: ValueTypes['StatusTierBonusConfigInput']
  ['StatusTierEntryInput']: ValueTypes['StatusTierEntryInput']
  ['SubmitUserCampaignStateChangeInput']: ValueTypes['SubmitUserCampaignStateChangeInput']
  ['UpdateAutoBuyDistributionInput']: ValueTypes['UpdateAutoBuyDistributionInput']
  ['UpdateAutoBuyFlowConfigurationInput']: ValueTypes['UpdateAutoBuyFlowConfigurationInput']
  ['UpdateAutoBuyFlowReleaseScheduleInput']: ValueTypes['UpdateAutoBuyFlowReleaseScheduleInput']
  ['UpdateAutoBuyReleaseInput']: ValueTypes['UpdateAutoBuyReleaseInput']
  ['UpdateBrandApiInput']: ValueTypes['UpdateBrandApiInput']
  ['UpdateBrandInput']: ValueTypes['UpdateBrandInput']
  ['UpdateCampaignClicksInput']: ValueTypes['UpdateCampaignClicksInput']
  ['UpdateCampaignInput']: ValueTypes['UpdateCampaignInput']
  ['UpdateCampaignInviteInput']: ValueTypes['UpdateCampaignInviteInput']
  ['UpdateCampaignSourcingProspectInput']: ValueTypes['UpdateCampaignSourcingProspectInput']
  ['UpdateChatConversationV2Input']: ValueTypes['UpdateChatConversationV2Input']
  ['UpdateCommunicationNotificationInput']: ValueTypes['UpdateCommunicationNotificationInput']
  ['UpdateCompanyInput']: ValueTypes['UpdateCompanyInput']
  ['UpdateConnectedAccountInput']: ValueTypes['UpdateConnectedAccountInput']
  ['UpdateContentApprovalsInput']: ValueTypes['UpdateContentApprovalsInput']
  ['UpdateConversationInput']: ValueTypes['UpdateConversationInput']
  ['UpdateConversationMessageInput']: ValueTypes['UpdateConversationMessageInput']
  ['UpdateEtailerSkuMetricsInput']: ValueTypes['UpdateEtailerSkuMetricsInput']
  ['UpdateFormSequenceConfigurationInput']: ValueTypes['UpdateFormSequenceConfigurationInput']
  ['UpdateInstagramStoryScreenInput']: ValueTypes['UpdateInstagramStoryScreenInput']
  ['UpdateMessageInput']: ValueTypes['UpdateMessageInput']
  ['UpdateNoteInput']: ValueTypes['UpdateNoteInput']
  ['UpdateNotificationConfigurationInput']: ValueTypes['UpdateNotificationConfigurationInput']
  ['UpdateProductBonusConfigSnapshotInput']: ValueTypes['UpdateProductBonusConfigSnapshotInput']
  ['UpdateProductInput']: ValueTypes['UpdateProductInput']
  ['UpdateProductKeywordsInput']: ValueTypes['UpdateProductKeywordsInput']
  ['UpdateProductRatingSnapshotInput']: ValueTypes['UpdateProductRatingSnapshotInput']
  ['UpdatePurchaseInput']: ValueTypes['UpdatePurchaseInput']
  ['UpdateReferralInput']: ValueTypes['UpdateReferralInput']
  ['UpdateReviewInput']: ValueTypes['UpdateReviewInput']
  ['UpdateSkuInput']: ValueTypes['UpdateSkuInput']
  ['UpdateSkuSnapshotInput']: ValueTypes['UpdateSkuSnapshotInput']
  ['UpdateUserBonusPayoutInput']: ValueTypes['UpdateUserBonusPayoutInput']
  ['UpdateUserCampaignChangelogInput']: ValueTypes['UpdateUserCampaignChangelogInput']
  ['UpdateUserCampaignInput']: ValueTypes['UpdateUserCampaignInput']
  ['UpdateUserCampaignSnapshotInput']: ValueTypes['UpdateUserCampaignSnapshotInput']
  ['UpdateUserCampaignSocialContentDraftHistoryInput']: ValueTypes['UpdateUserCampaignSocialContentDraftHistoryInput']
  ['UpdateUserCampaignSocialContentInput']: ValueTypes['UpdateUserCampaignSocialContentInput']
  ['UpdateUserCampaignSocialContentReviewInput']: ValueTypes['UpdateUserCampaignSocialContentReviewInput']
  ['UpdateUserDeviceSnapshotInput']: ValueTypes['UpdateUserDeviceSnapshotInput']
  ['UpdateUserFollowerInput']: ValueTypes['UpdateUserFollowerInput']
  ['UpdateUserInput']: ValueTypes['UpdateUserInput']
  ['UpdateUserLinkInput']: ValueTypes['UpdateUserLinkInput']
  ['UpdateUserNotificationPreferenceInput']: ValueTypes['UpdateUserNotificationPreferenceInput']
  ['UpdateUserPayoutRequestInput']: ValueTypes['UpdateUserPayoutRequestInput']
  ['UpdateUserStagingApprovalInput']: ValueTypes['UpdateUserStagingApprovalInput']
  ['UpdateVideoContentScanInput']: ValueTypes['UpdateVideoContentScanInput']
  ['UserAttributesInput']: ValueTypes['UserAttributesInput']
  ['UserCampaignBonusConfigInput']: ValueTypes['UserCampaignBonusConfigInput']
  ['UserCampaignSearchFields']: ValueTypes['UserCampaignSearchFields']
  ['UserCampaignSocialContentReviewFeedbackInput']: ValueTypes['UserCampaignSocialContentReviewFeedbackInput']
  ['UserCharacteristicInput']: ValueTypes['UserCharacteristicInput']
  ['UserContentRatingInput']: ValueTypes['UserContentRatingInput']
  ['UserSearchFields']: ValueTypes['UserSearchFields']
  ['UserSearchInput']: ValueTypes['UserSearchInput']
  ['UserSearchInputSort']: ValueTypes['UserSearchInputSort']
  ['UtmReferralInput']: ValueTypes['UtmReferralInput']
}

import { ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { orderBy } from 'lodash'
import { OTHER_THEME } from './constants'
import { useFeedbackContext } from './feedbackContext'

export const ThemeSelector = () => {
  const { themes, selectedTheme, setSelectedTheme } = useFeedbackContext()

  const selectableThemes = orderBy(themes, t => t.criticalFeedback.length + t.positiveFeedback.length, 'desc').filter(
    t => t.theme !== OTHER_THEME
  )

  return (
    <Stack direction={'row'} spacing={2}>
      {selectableThemes.map((fTheme, index) => (
        <Stack
          justifyContent={'space-between'}
          flex={1}
          spacing={1}
          key={index}
          onClick={() => {
            setSelectedTheme(fTheme.theme)
          }}
          p={1}
          sx={{
            cursor: 'pointer',
            borderRadius: '4px',
            border: theme =>
              fTheme.theme === selectedTheme?.theme ? `2px solid ${theme.palette.primary.main}` : `2px solid #EBEDF6`
          }}
        >
          <Typography variant={'label1'} textOverflow={'ellipsis'} overflow={'hidden'}>
            {fTheme.theme}
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <ThumbUpOutlined color={'primary'} />
              <Typography variant={'label2'}>
                {Math.round(
                  (100 * fTheme.positiveFeedback.length) /
                    (fTheme.criticalFeedback.length + fTheme.positiveFeedback.length)
                )}
                %
              </Typography>
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <ThumbDownOutlined color={'primary'} />
              <Typography variant={'label2'}>
                {Math.round(
                  (100 * fTheme.criticalFeedback.length) /
                    (fTheme.criticalFeedback.length + fTheme.positiveFeedback.length)
                )}
                %
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}

import { Stack } from '@mui/system'
import { Typography } from '@mui/material'
import { EmptyIcon } from './EmptyIcon'

export const UpcomingState = () => {
  return (
    <Stack pt={12} spacing={4} alignItems={'center'} justifyContent={'center'}>
      <EmptyIcon sx={{ height: '154px', width: '202px' }} />
      <Typography variant={'h5'}>Creators are making content for your product!</Typography>
      <Typography variant={'label4'}>
        Check back after creators have had time to receive and test your product.
      </Typography>
    </Stack>
  )
}

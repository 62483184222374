import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Stack, Typography } from '@mui/material'
import { BrandInformation } from './BrandInformation'
import { BrandIntegrations } from './BrandIntegrations'
import { BrandPricing } from './BrandPricing'
import { BrandStore } from './BrandStore'
import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import Loading from '@momentum/components/loading'
import { BrandBilling } from '@momentum/routes/brand-profile/BrandBilling'

export const BrandProfile = () => {
  const { isAdminView, selectedBrand, selectedCompany, loadingResources, isViewOnly } = useUserSessionContext()
  const crumbs = useClientBreadcrumbs(selectedCompany, selectedBrand)

  if (loadingResources || !selectedBrand) {
    return <Loading />
  }

  return (
    <Stack pb={4}>
      <Stack mb={5} mt={3} spacing={1}>
        {isAdminView && <RoutesBreadcrumb crumbs={crumbs} />}
        <Typography variant={'h3'} data-cy={'profileHeader'}>
          Brand profile
        </Typography>
        <Typography variant={'h5'}>{selectedBrand.name}</Typography>
      </Stack>

      <Stack spacing={4}>
        <BrandIntegrations brand={selectedBrand} readOnly={isViewOnly} />

        <BrandInformation brand={selectedBrand} readOnly={isViewOnly} />

        <BrandBilling brand={selectedBrand} readOnly={!isAdminView} />

        <BrandStore brand={selectedBrand} />

        {isAdminView && <BrandPricing brand={selectedBrand} />}
      </Stack>
    </Stack>
  )
}

import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { SponsoredRankSummary } from '@momentum/routes/campaign/e-commerce/seoV2/sponsored/SponsoredRankSummary'
import { Paper, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { CollectingSEOResults } from '../common/collecting-rank-results'
import { KeywordTable } from '../common/keyword-table'
import { SeoTable } from '../common/seo-table'
import { SeoReportMode, useSeoContext } from '../context'

export const SponsoredSeo = () => {
  const { isAdminView } = useUserSessionContext()
  const { isLoading, productSeoSummary, seoReportMode, setSeoReportMode } = useSeoContext()

  useEffect(() => {
    setSeoReportMode(SeoReportMode.SPONSORED)
    return () => setSeoReportMode(undefined)
  }, [])

  if (isLoading || !productSeoSummary || seoReportMode !== SeoReportMode.SPONSORED) {
    return <Loading />
  }

  if (!isAdminView && !productSeoSummary?.productSeoSummaryRecords?.length) {
    return <CollectingSEOResults />
  }

  return (
    <Stack spacing={5}>
      <Paper sx={{ px: 4, py: 2 }}>
        <SponsoredRankSummary />
      </Paper>
      <Stack spacing={2}>
        <Typography variant={'h4'}>Sponsored Page 1 wins</Typography>
        <SeoTable />
      </Stack>
      <KeywordTable />
    </Stack>
  )
}

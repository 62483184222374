import { IntegratePrompt } from '@momentum/components/integrate-prompt'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { INTEGRATION_TYPE_LABELS, IntegrationType } from './utils'

export const AccountNotLinked = ({
  type,
  onIntegrationTriggered
}: {
  type: IntegrationType
  onIntegrationTriggered: () => Promise<void>
}) => {
  const [integrationLoading, setIntegrationLoading] = useState(false)

  const triggerIntegration = async () => {
    try {
      setIntegrationLoading(true)
      await onIntegrationTriggered()
    } finally {
      setIntegrationLoading(false)
    }
  }

  return (
    <Stack alignItems={'center'} spacing={3} pt={12}>
      <IntegratePrompt />
      <Typography variant={'h5'} textAlign={'center'} maxWidth={'442px'}>
        Link your Amazon {INTEGRATION_TYPE_LABELS[type]} account to unlock the Momentum dashboard
      </Typography>
      <LoadingButton variant={'contained'} onClick={triggerIntegration} loading={integrationLoading}>
        Link {INTEGRATION_TYPE_LABELS[type]} account
      </LoadingButton>
    </Stack>
  )
}

import { FormControl, Stack, Typography } from '@mui/material'
import FieldError from '../InputError'
import { VariantInputBaseProps } from './types'

const InputBase = ({
  name,
  primaryText,
  subtext,
  variant = 'standard',
  disabled,
  fullWidth,
  children,
  hideError,
  dataCy
}: VariantInputBaseProps) => {
  return (
    <FormControl
      sx={{
        '.MuiFormLabel-root': {
          ':not(.MuiInputLabel-shrink)': {
            transform: 'translate(14px, 8px) scale(1)'
          }
        }
      }}
      {...{ fullWidth, variant, disabled }}
      data-cy={dataCy}
    >
      <Stack spacing={1}>
        {!!primaryText && <Typography variant={'formPrimaryText'}>{primaryText}</Typography>}
        {!!subtext &&
          (typeof subtext === 'string' ? <Typography variant={'formSubText'}>{subtext}</Typography> : subtext)}
        {children}
        {!hideError && <FieldError name={name} variant={variant} />}
      </Stack>
    </FormControl>
  )
}

export default InputBase

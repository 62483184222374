import { DateTime } from 'luxon'
import { ZeusInterceptor } from '../types'
import { isSampleContent } from '../utils'

export const updateUserCampaignSocialContent: ZeusInterceptor<'mutation', 'updateUserCampaignSocialContent'> = async args => {
  const id = args.o.updateUserCampaignSocialContent?.[0].input.id
  if (id && isSampleContent(id)) {
    return {
      id,
      updatedAt: DateTime.now().toISO(),
      createdAt: DateTime.now().toISO(),
      isHidden: !!args.o.updateUserCampaignSocialContent?.[0].input.isHidden
    }
  }
}

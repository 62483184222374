import { Region } from '@productwindtom/shared-momentum-zeus-types'
import USFlagIcon from '@momentum/components/icons/flags/us'
import CAFlagIcon from '@momentum/components/icons/flags/ca'
import UKFlagIcon from '@momentum/components/icons/flags/uk'
import DEFlagIcon from '@momentum/components/icons/flags/de'
import { SvgIconProps } from '@mui/material'

export const DOMAIN_TO_REGION = {
  com: Region.US,
  ca: Region.CA,
  ['co.uk']: Region.UK,
  de: Region.DE
}

export const RegionToName: Record<Region, string> = {
  [Region.US]: 'United States',
  [Region.CA]: 'Canada',
  [Region.UK]: 'United Kingdom',
  [Region.DE]: 'Germany'
}

export const RegionToFlagIcon: Record<Region, (props: SvgIconProps) => JSX.Element> = {
  [Region.US]: USFlagIcon,
  [Region.CA]: CAFlagIcon,
  [Region.UK]: UKFlagIcon,
  [Region.DE]: DEFlagIcon
}

import { ProductSeoSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'
import { SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/getCampaign/sample-data/energy-drink-pd-existing'

export const SAMPLE_CAMPAIGN_SEO_ENERGY_DRINK_PD_EXISTING: ProductSeoSummary = {
  campaignId: SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id,
  productSeoSummaryRecords: [
    {
      fromDate: '2024-06-17',
      toDate: '2024-06-23',
      searchVolume: 114129,
      organicPageOneWins: 10,
      sponsoredPageOneWins: 26
    },
    {
      fromDate: '2024-06-24',
      toDate: '2024-06-30',
      searchVolume: 122808,
      organicPageOneWins: 10,
      sponsoredPageOneWins: 7
    },
    {
      fromDate: '2024-07-01',
      toDate: '2024-07-07',
      searchVolume: 121073,
      organicPageOneWins: 11,
      sponsoredPageOneWins: 8
    },
    {
      fromDate: '2024-07-08',
      toDate: '2024-07-14',
      searchVolume: 93635,
      organicPageOneWins: 3,
      sponsoredPageOneWins: 7
    },
    {
      fromDate: '2024-07-15',
      toDate: '2024-07-21',
      searchVolume: 86146,
      organicPageOneWins: 2,
      sponsoredPageOneWins: 14
    },
    {
      fromDate: '2024-07-22',
      toDate: '2024-07-28',
      searchVolume: 161101,
      organicPageOneWins: 6,
      sponsoredPageOneWins: 6
    },
    {
      fromDate: '2024-07-29',
      toDate: '2024-08-04',
      searchVolume: 97986,
      organicPageOneWins: 2,
      sponsoredPageOneWins: 1
    },
    {
      fromDate: '2024-08-05',
      toDate: '2024-08-11',
      searchVolume: 82716,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0
    }
  ]
}

import Row from '@momentum/components/row'
import { UserAvatar } from '@momentum/components/user-avatar'
import { getFeedbackTime, getReviewedAtTime } from '../helpers'
import { Button, Chip, Stack, Typography } from '@mui/material'
import { ReviewFeedback } from '@momentum/routes/campaign/context/queries'
import { useEffect, useState } from 'react'
import { useApproveContentContext } from '../context/ApproveContentContext'

const ReviewFeedbackItem = ({
  createdByFirstName,
  createdByLastName,
  message,
  atTime,
  visibleToUser,
  createdAt
}: ReviewFeedback) => {
  const { setCurrentVideoPosition } = useApproveContentContext()
  const [reviewdAt, setReviewedAt] = useState(createdAt ? getReviewedAtTime(createdAt) : '')

  useEffect(() => {
    if (createdAt) {
      const interval = setInterval(() => {
        setReviewedAt(getReviewedAtTime(createdAt))
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [createdAt])

  return (
    <Stack spacing={1} alignItems={'flex-start'}>
      <Row spacing={1}>
        <UserAvatar firstName={createdByFirstName} lastName={createdByLastName} />
        <Typography variant="body2">
          {createdByFirstName} {createdByLastName} {reviewdAt ? `- ${reviewdAt} ago` : ''}
        </Typography>
      </Row>
      <Typography variant="body2">{message}</Typography>
      <Row spacing={1} width={'100%'} justifyContent={atTime ? 'space-between' : 'flex-end'}>
        {atTime && (
          <Button
            sx={{
              minWidth: 0
            }}
            size="small"
            variant="text"
            onClick={() => setCurrentVideoPosition(atTime)}
          >
            {getFeedbackTime(atTime)}
          </Button>
        )}
        <Chip
          size="small"
          label={visibleToUser ? 'Show to creator' : 'Internal comment'}
          color={visibleToUser ? 'primary' : 'secondary'}
        />
      </Row>
    </Stack>
  )
}

export default ReviewFeedbackItem

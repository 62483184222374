import { Form, SubmitButton, NumberInput, TextInput, DateInput } from '@productwindtom/ui-base'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/system'
import * as yup from 'yup'
import { BackButton } from '@momentum/components/back-button'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { Alert, Typography, Box, Button, CircularProgress } from '@mui/material'
import { Add, Cancel } from '@mui/icons-material'
import { getCurrencySymbol } from '@productwindtom/shared-ws-currency'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { pdf } from '@react-pdf/renderer'
import { DateTime } from 'luxon'
import { notEmpty } from '@productwindtom/shared-node'
import { BuyCreditsOrderFormPdf } from './BuyCreditsOrderFormPdf'
import { uniq } from 'lodash'
import React from 'react'

const schema = yup
  .object({
    numCredits: yup.number().required('Required'),
    paymentDueDate: yup.mixed<DateTime>().required('Required'),
    paymentBillingContact: yup
      .object({
        email: yup.string().email('Invalid email').required('Required'),
        name: yup.string().required('Required')
      })
      .required('Required'),
    billingContacts: yup
      .array()
      .of(
        yup.object({
          email: yup.string().email('Invalid email'),
          name: yup.string()
        })
      )
      .required('Required')
      .test(
        'min valid',
        'You must enter at least one billing email and contact name in order to schedule the campaign.',
        v => !!v && v.some(r => r.email && r.name)
      )
  })
  .noUnknown(true)

export type BuyCreditsFormData = {
  numCredits: number
  paymentDueDate: DateTime
  paymentBillingContact: {
    email: string
    name: string
  }
  billingContacts: {
    email?: string
    name?: string
  }[]
}

export const BuyCreditsForm = ({
  onSubmit,
  onCancel,
  onManuallyAddCreditsClick
}: {
  onCancel: () => void
  onSubmit: (data: BuyCreditsFormData) => Promise<void>
  onManuallyAddCreditsClick: () => void
}) => {
  const { isAdminView, selectedBrand } = useUserSessionContext()

  const handleSubmit = async (submitValues: BuyCreditsFormData) => {
    await onSubmit(submitValues)
  }

  const defaultValues = {
    paymentDueDate: DateTime.now().plus({ days: 7 }),
    paymentBillingContact: {
      email: selectedBrand?.paymentBillingContact?.email || '',
      name: selectedBrand?.paymentBillingContact?.name || ''
    },
    billingContacts: [
      {
        email: READ_ONLY_CONTACT,
        name: 'ProductWind Billing'
      },
      {
        email: '',
        name: ''
      }
    ]
  }
  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <Stack>
          <FormBody />
          {isAdminView && (
            <Box>
              <Button
                variant={'text'}
                disableRipple
                disableFocusRipple
                onClick={onManuallyAddCreditsClick}
                data-cy={'manualCreditsButton'}
              >
                Manually add credits instead
              </Button>
            </Box>
          )}
        </Stack>
        <Stack>
          <LoadingSpinner />
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
            <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} data-cy={'cancelButton'} />
            <SubmitButton variant={'contained'} disableOnDirty={false} data-cy={'submitButton'}>
              Generate invoice for credits
            </SubmitButton>
          </Stack>
        </Stack>
      </Stack>
    </Form>
  )
}

const LoadingSpinner = () => {
  const { formState } = useFormContext()
  if (formState.isSubmitting) {
    return (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <CircularProgress size={24} />
        <Typography variant={'label3'}>We're generating your invoice!</Typography>
      </Stack>
    )
  }
  return null
}

const FormBody = () => {
  const { selectedBrand, selectedCompany } = useUserSessionContext()
  const { getValues } = useFormContext<BuyCreditsFormData>()
  const generatePdf = async () => {
    const { numCredits, billingContacts, paymentBillingContact, paymentDueDate } = getValues()

    const pdfDocument = (
      <BuyCreditsOrderFormPdf
        numCredits={numCredits || 0}
        contacts={uniq(
          [paymentBillingContact.email?.trim(), ...billingContacts.map(c => c.email?.trim())]
            .filter(c => c)
            .filter(notEmpty)
        )}
        companyName={selectedCompany!.name}
        paymentDueDate={paymentDueDate.toLocaleString(DateTime.DATE_MED)}
        creationDate={DateTime.now().toLocaleString(DateTime.DATE_MED)}
      />
    )

    await pdf(pdfDocument)
      .toBlob()
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `order-terms.pdf`
        a.click()
      })
  }

  return (
    <Stack spacing={2}>
      <NumberInput
        inputProps={{ 'data-cy': 'numCredits' }}
        returnAsNumber
        decimalScale={0}
        placeholder={'Enter credits'}
        name={'numCredits'}
        primaryText={`Enter the amount of credits you want to buy`}
      />
      <Stack spacing={1}>
        <Typography variant={'label3'}>Primary contact for this invoice</Typography>
        <Typography variant={'body2'}>
          An invoice will be generated and emailed to this contact. Contact customer success to update this billing
          contact.
        </Typography>
        <PaymentBillingContactInput name={'paymentBillingContact'} />
      </Stack>
      <Stack spacing={1}>
        <Typography variant={'label3'}>Enter additional contacts to be cc’d on the invoice</Typography>
        <Typography variant={'body2'}>An invoice will be generated and cc’ed to these contacts.</Typography>
        <BillingContactsInput name={'billingContacts'} />
      </Stack>
      <DateInput
        name={'paymentDueDate'}
        primaryText={'What is your estimated payment due date?'}
        minDate={DateTime.now().plus({ days: 1 })}
        subtext={
          'You will receive your credits as soon as we receive payment (typically 3 days after payment is completed).'
        }
      />
      <NumberInput
        disabled
        returnAsNumber
        decimalScale={0}
        name={'numCredits'}
        inputProps={{ 'data-cy': 'invoiceAmount' }}
        primaryText={`Invoice amount:`}
        subtext={
          <Typography variant={'formSubText'}>
            By clicking “Buy credits” you agree to the following order terms. A copy of the{' '}
            <Typography
              onClick={generatePdf}
              component={'span'}
              color={'primary'}
              sx={{ cursor: 'pointer', fontWeight: 800 }}
            >
              order form
            </Typography>{' '}
            will be emailed to the contacts added above.
          </Typography>
        }
        prefix={getCurrencySymbol(selectedBrand!.region)}
      />
    </Stack>
  )
}

const PaymentBillingContactInput = ({ name }: { name: 'paymentBillingContact' }) => {
  const {
    formState: { errors },
    trigger
  } = useFormContext<BuyCreditsFormData>()

  const error = errors[name]?.message

  return (
    <Stack spacing={2}>
      <Stack direction={'row'} spacing={1} alignItems={'flex-start'}>
        <TextInput
          name={`${name}.email`}
          fullWidth
          placeholder={'Enter billing email'}
          onBlur={() => trigger(name)}
          error={!!error}
          disabled
        />
        <TextInput
          name={`${name}.name`}
          fullWidth
          placeholder={'Enter contact name'}
          onBlur={() => trigger(name)}
          error={!!error}
          disabled
        />
      </Stack>

      {!!error && (
        <Alert severity={'error'} variant={'outlined'} data-cy={'paymentBillingContactErrorAlert'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}

const READ_ONLY_CONTACT = 'billing@productwind.com'
const BillingContactsInput = ({ name }: { name: 'billingContacts' }) => {
  const {
    control,
    formState: { errors },
    trigger
  } = useFormContext<BuyCreditsFormData>()
  const { append, fields, remove } = useFieldArray({ name, control })
  const handleAdd = () => {
    append({ email: '', name: '' })
  }
  const error = errors[name]?.message
  return (
    <Stack spacing={2}>
      {!!fields.length && (
        <Stack spacing={1}>
          {fields.map((v, index) => (
            <Stack key={v.id} direction={'row'} spacing={1} alignItems={'flex-start'}>
              <TextInput
                disabled={v.email === READ_ONLY_CONTACT}
                name={`${name}[${index}].email`}
                fullWidth
                placeholder={'Enter billing email'}
                onBlur={() => trigger(name)}
              />
              <TextInput
                disabled={v.email === READ_ONLY_CONTACT}
                name={`${name}[${index}].name`}
                fullWidth
                placeholder={'Enter contact name'}
                onBlur={() => trigger(name)}
              />
              <Cancel
                sx={{
                  cursor: v.email === READ_ONLY_CONTACT ? undefined : 'pointer',
                  pt: 1,
                  color: v.email === READ_ONLY_CONTACT ? 'transparent' : undefined
                }}
                onClick={() => {
                  if (v.email === READ_ONLY_CONTACT) return
                  remove(index)
                }}
                fontSize={'mSmall'}
                color={'action'}
              />
            </Stack>
          ))}
        </Stack>
      )}

      <Box>
        <Button startIcon={<Add />} variant={'text'} onClick={handleAdd} disabled={fields.length >= 5}>
          Add contact
        </Button>
      </Box>
      {!!error && (
        <Alert severity={'error'} variant={'outlined'} data-cy={'billingContactsErrorAlert'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}

import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { createCampaign } from '@momentum/routes/proposals-create/mutations'
import { BillingContactsInput } from '@momentum/routes/proposals-create/review/BillingContactsInput'
import { CreditPayment } from '@momentum/routes/proposals-create/review/CreditPayment'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { useSaveProposal } from '@momentum/routes/proposals-create/useSaveProposal'
import { ROUTE_NAMES_PROPOSAL, ROUTES } from '@momentum/routes/RouteNames'
import { errorToast } from '@momentum/utils/toastUtils'
import { ScheduleOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Stack, Typography, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import {
  InvoicePaymentType,
  InvoiceStatus,
  PaymentTermsType,
  ProposalStatus
} from '@productwindtom/shared-momentum-zeus-types'
import { toLocaleCurrency } from '@productwindtom/shared-node'
import { DateInput, RadioInput, TextInput } from '@productwindtom/ui-base'
import { captureException } from '@sentry/react'
import { max } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { Question } from '../../../components/proposal-common/Question'
import PlaceholderProductAlert from '../modules/placeholder-product-alert'
import { PaymentBillingContactInput } from '@momentum/routes/proposals-create/review/PaymentBillingContactInput'

const DEFAULT_NET_TERMS = 7

export const Review = () => {
  const { selectedCompany, agencies, isAdminView, agency, isViewOnly } = useUserSessionContext()
  const { refreshCampaigns, refreshProposal } = useBrandContext()
  const { creditsRemaining, refreshCredits } = useSubscriptionContext()
  const { totalCostCredits, totalCostCreditsForAgency } = useEstimatedDeliverables()
  const { proposal, selectedProduct } = useCreateProposalContext()
  const { saveProposal } = useSaveProposal(proposal?.id)
  const {
    watch,
    setValue,
    formState: { isValid }
  } = useFormContext<ProposalCreateForm>()
  const data = watch()
  const [launchDate, paymentType, invoiceStatus, invoiceDueDate] = watch([
    'launchDate',
    'paymentType',
    'invoiceStatus',
    'invoiceDueDate'
  ])
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [scheduling, setScheduling] = useState(false)

  const companyAgency = agencies?.find(agency => agency.id === selectedCompany?.agencyId)
  const agencyTerms = companyAgency?.paymentTermsType

  const netTermsType = agencyTerms || selectedCompany?.paymentTermsType

  const netTerms =
    netTermsType === PaymentTermsType.NET_CUSTOM
      ? companyAgency?.paymentTermsCustomNetDays || selectedCompany?.paymentTermsCustomNetDays || 0
      : DEFAULT_NET_TERMS

  useEffect(() => {
    if (!selectedProduct) {
      navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS].join('/'), {
        replace: true
      })
    }
  }, [])

  useEffect(() => {
    if (paymentType === InvoicePaymentType.NEW_INVOICE && netTerms) {
      setValue(
        'invoiceDueDate',
        netTermsType === PaymentTermsType.NET_CUSTOM
          ? DateTime.now().plus({ days: netTerms })
          : max([DateTime.now().plus({ days: 1 }), launchDate.minus({ days: netTerms })])
      )
    } else if (paymentType === InvoicePaymentType.END_OF_MONTH_INVOICE) {
      setValue('invoiceDueDate', DateTime.now().plus({ month: 1 }).startOf('month'))
    }
  }, [paymentType, netTermsType, netTerms])

  const handleBack = () => {
    navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.TIMELINE].join('/'), { replace: true })
  }

  const handleScheduleCampaign = async () => {
    setScheduling(true)
    const updatedProposal = await saveProposal(data)
    try {
      const resp = await createCampaign(updatedProposal.id)
      refreshCampaigns()
      refreshProposal(updatedProposal.id)
      if (data.paymentType === InvoicePaymentType.CREDITS) {
        refreshCredits()
      }
      navigate(
        generatePath([ROUTES.BRAND_CREATE_PROPOSAL_EDIT, ROUTE_NAMES_PROPOSAL.WIN].join('/'), {
          brandId: updatedProposal.brandId,
          id: updatedProposal.id
        }) + `?invoiceLink=${resp.invoiceLink ?? ''}`
      )
    } catch (e: any) {
      console.error(e)
      captureException(e)
      switch (e.message) {
        case 'UNAUTHORIZED':
          errorToast('You are not authorized to schedule this campaign.')
          break
        case 'PROPOSAL_NOT_FOUND':
          errorToast('Proposal not found.')
          break
        case 'CAMPAIGN_ALREADY_EXISTS':
          errorToast('Campaign already exists.')
          break
        case 'INVALID_PROPOSAL':
          errorToast('Invalid proposal.')
          break
        case 'SKU_NOT_FOUND':
          errorToast('SKU not found.')
          break
        case 'BRAND_NOT_FOUND':
          errorToast('Brand not found.')
          break
        case 'PRICE_NOT_FOUND':
          errorToast('SKU price not found.')
          break
        case 'INSUFFICIENT_CREDITS':
          errorToast('Insufficient credits.')
          break
        case 'INVOICE_DUE_DATE_REQUIRED':
          errorToast('Invoice due date required.')
          break
        case 'BILLING_CONTACTS_REQUIRED':
          errorToast('Billing contacts required.')
          break
        case 'CREDITS_CHANGED':
        default:
          errorToast('An error has occurred, please try again later!')
      }
    }
    setScheduling(false)
  }

  return (
    <Stack spacing={4}>
      <Question primaryText={'Campaign launch date'}>
        <Box sx={{ maxWidth: 165 }}>
          <DateInput fullWidth name={'launchDate'} readOnly disabled />
        </Box>
      </Question>
      <Stack>
        <Typography variant={'label1'}>How would you like to pay for this campaign?</Typography>
        {(companyAgency || isAdminView) && paymentType === InvoicePaymentType.END_OF_MONTH_INVOICE ? (
          <Alert variant="outlined" severity="info">
            <Typography variant="label3">
              {agency?.name} will be billed{' '}
              {toLocaleCurrency(totalCostCreditsForAgency || 0, 'USD', { minimumFractionDigits: 0 })} on{' '}
              {invoiceDueDate?.toLocaleString(DateTime.DATE_MED)} for this campaign. By clicking “schedule campaign” you
              agree to pay this amount.
            </Typography>
          </Alert>
        ) : (
          <RadioInput
            name={'paymentType'}
            radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
            options={[
              ...(!selectedCompany?.agencyId
                ? [
                    {
                      label: <Typography variant={'label3'}>Use existing credits</Typography>,
                      value: InvoicePaymentType.CREDITS
                    }
                  ]
                : []),
              ...(isAdminView
                ? [
                    {
                      label: <Typography variant={'label3'}>There is an existing invoice</Typography>,
                      value: InvoicePaymentType.EXISTING_INVOICE
                    }
                  ]
                : []),
              {
                label: <Typography variant={'label3'}>Generate a new invoice</Typography>,
                value: InvoicePaymentType.NEW_INVOICE
              }
            ]}
          />
        )}
      </Stack>

      {paymentType === InvoicePaymentType.EXISTING_INVOICE && (
        <Stack>
          <Typography variant={'label1'}>Has the invoice already been paid?</Typography>
          <RadioInput
            name={'invoiceStatus'}
            radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
            options={[
              { label: <Typography variant={'label3'}>Paid</Typography>, value: InvoiceStatus.PAID },
              { label: <Typography variant={'label3'}>Not paid</Typography>, value: InvoiceStatus.NOT_PAID }
            ]}
          />
        </Stack>
      )}
      {paymentType === InvoicePaymentType.EXISTING_INVOICE && invoiceStatus === InvoiceStatus.PAID && (
        <Question primaryText={'Date paid'}>
          <Box sx={{ maxWidth: 165 }}>
            <DateInput fullWidth name={'invoicePaidDate'} />
          </Box>
        </Question>
      )}
      {paymentType === InvoicePaymentType.EXISTING_INVOICE && invoiceStatus === InvoiceStatus.NOT_PAID && (
        <Question
          primaryText={'When is payment due?'}
          subtext={`The campaign start date is scheduled for ${launchDate.toLocaleString(DateTime.DATE_SHORT)}.`}
        >
          <Box sx={{ maxWidth: 165 }}>
            <DateInput fullWidth name={'invoiceDueDate'} />
          </Box>
        </Question>
      )}

      {paymentType === InvoicePaymentType.CREDITS && (
        <CreditPayment creditsRemaining={creditsRemaining} totalCostCredits={totalCostCredits || 0} />
      )}
      {paymentType === InvoicePaymentType.NEW_INVOICE && (
        <Question
          primaryText={'When is the total payment due?'}
          subtext={
            netTermsType === PaymentTermsType.NET_CUSTOM && netTerms
              ? `${companyAgency?.name || selectedCompany?.name} has Net ${netTerms} payment terms. The due date is set ${netTerms} days from the date the invoice is generated.`
              : `${companyAgency?.name || selectedCompany?.name} has payment terms which require payment ${netTerms} days before launch. The due date is set ${netTerms} days from the campaign launch date of ${launchDate.toLocaleString(DateTime.DATE_SHORT)}.`
          }
        >
          <Box sx={{ maxWidth: 165 }}>
            <DateInput fullWidth name={'invoiceDueDate'} disabled readOnly />
          </Box>
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && (
        <Question
          primaryText={'Enter the contact responsible for invoice payment'}
          subtext={`An invoice will be generated and emailed to this contact.`}
        >
          <PaymentBillingContactInput name={'paymentBillingContact'} />
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && (
        <Question
          primaryText={"Enter additional contacts to be cc'ed on the invoice"}
          subtext={`An invoice will be generated and cc’ed to these contacts.`}
        >
          <BillingContactsInput name={'billingContacts'} />
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && (
        <Box>
          <TextInput name={'invoicePONumber'} primaryText={'Enter PO number (optional)'} />
        </Box>
      )}

      {!companyAgency && paymentType && paymentType !== InvoicePaymentType.CREDITS && isAdminView && (
        <Question primaryText={'Enter the link to your existing HubSpot deal'}>
          <Box>
            <TextInput name={'hubspotDealLink'} placeholder={'HubSpot deal link'} fullWidth />
          </Box>
        </Question>
      )}

      {isViewOnly && (
        <Alert severity={'info'} variant={'outlined'}>
          <Typography color={'black'} variant={'label3'}>
            This campaign cannot be scheduled because your access level is View-only. Save the proposal and contact
            Customer Success to schedule this campaign.
          </Typography>
        </Alert>
      )}

      <Stack spacing={2} pb={2}>
        <PlaceholderProductAlert />
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button onClick={handleBack} data-cy="goBackButton">
            Go back
          </Button>
          <LoadingButton
            loading={scheduling}
            variant={'contained'}
            data-cy="scheduleCampaignButton"
            endIcon={<ScheduleOutlined />}
            disabled={
              isViewOnly ||
              !paymentType ||
              (!isAdminView && !selectedProduct?.skuId) ||
              proposal?.status === ProposalStatus.SUBMITTED ||
              (paymentType === InvoicePaymentType.CREDITS &&
                (!totalCostCredits || totalCostCredits > creditsRemaining)) ||
              !isValid
            }
            onClick={handleScheduleCampaign}
          >
            {proposal?.status === ProposalStatus.SUBMITTED ? 'Scheduled' : 'Schedule campaign'}
          </LoadingButton>
        </Stack>
        {scheduling && (
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <CircularProgress size={24} />
            <Typography variant={'label3'}>
              We're scheduling your campaign! This could take up to 30 seconds.
            </Typography>
          </Stack>
        )}
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          By clicking “schedule campaign” you agree that this campaign is governed by any existing Master Services
          Agreement and / or Order Form between you and ProductWind.
        </Typography>
      </Stack>
    </Stack>
  )
}

import { Button, Dialog, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useCampaignContext } from '../context/CampaignContext'
import { endCampaign } from '../context/queries'
import { toast } from 'react-toastify'
import Row from '@momentum/components/row'
import { LoadingButton } from '@mui/lab'
import { captureException } from '@sentry/react'

const EndCampaign = () => {
  const {
    campaignDetails: { product, id },
    updateCampaignDetails
  } = useCampaignContext()

  const [isLoading, setIsLoading] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleEndCampaign = async () => {
    try {
      setIsLoading(true)
      const resp = await endCampaign(id)

      updateCampaignDetails(
        current =>
          current && {
            ...current,
            ...resp
          }
      )

      toast(<Typography variant={'subtitle2'}>Campaign ended successfully!</Typography>, { type: 'success' })
    } catch (err) {
      captureException(err)
      console.log('err', err)
      toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
        type: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Stack>
      <Button
        variant="outlined"
        sx={{
          alignSelf: 'flex-start'
        }}
        size={'small'}
        onClick={() => setIsDialogOpen(true)}
      >
        End Campaign
      </Button>
      <Dialog open={isDialogOpen}>
        <Stack spacing={3} p={3}>
          <Typography variant="h5">Are you sure you want to mark this campaign as complete?</Typography>
          <ul>
            <li>
              <Typography variant="label3">Products to be ended:</Typography>
              <ul>
                <li>
                  <Typography variant="label3">
                    {product.skuId} - {product.name}
                  </Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="label3">Social content will stop refreshing after 14 days.</Typography>
            </li>
            <li>
              <Typography variant="label3">Reviews will stop refreshing after 120 days.</Typography>
            </li>
          </ul>
          <Row spacing={2}>
            <Button variant="outlined" color="secondary" onClick={() => setIsDialogOpen(false)}>
              No, don't mark as complete
            </Button>
            <LoadingButton loading={isLoading} variant="contained" color="primary" onClick={handleEndCampaign}>
              Yes, mark as complete
            </LoadingButton>
          </Row>
        </Stack>
      </Dialog>
    </Stack>
  )
}

export default EndCampaign

import { Box, Stack, Typography, useTheme } from '@mui/material'
import { BoxToggle } from '@momentum/components/box-toggle'
import { useTrafficContext } from '@momentum/routes/campaign/e-commerce/traffic/trafficContext'
import 'chart.js/auto'
import 'chartjs-adapter-luxon'
import { Line } from 'react-chartjs-2'
import { last } from 'lodash'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'

type TrafficRecord = {
  date: DateTime
  momentumViews: number
  otherViews: number
  totalViews: number
}

export const TrafficGraph = () => {
  const theme = useTheme()
  const {
    campaignDetails: { startDate, endDate }
  } = useCampaignContext()
  const {
    isIntegrated,
    filteredTrafficData,
    isMomentumViewsHidden,
    isOtherViewsHidden,
    isTotalViewsHidden,
    setIsMomentumViewsHidden,
    setIsOtherViewsHidden,
    setIsTotalViewsHidden
  } = useTrafficContext()

  const startDateNoTime = startDate.split('T')[0]
  const endDateNoTime = endDate ? endDate.split('T')[0] : undefined

  const cumulative = useMemo(
    () =>
      filteredTrafficData.reduce((acc: TrafficRecord[], d) => {
        const prev = last(acc)
        const momentumViews = d.momentumViews + (prev?.momentumViews || 0)
        const otherViews = d.otherViews + (prev?.otherViews || 0)
        const totalViews = d.totalViews + (prev?.totalViews || 0)

        return [...acc, { date: d.date, momentumViews, otherViews, totalViews }]
      }, []),
    [filteredTrafficData]
  )

  const momentumViews = cumulative.map(d => d.momentumViews)
  const otherViews = cumulative.map(d => d.otherViews)
  const totalViews = cumulative.map(d => d.totalViews)

  const lastRecord = last(cumulative)
  const momentumViewsSum = lastRecord?.momentumViews || 0
  const otherViewsSum = lastRecord?.otherViews || 0
  const totalViewsSum = lastRecord?.totalViews || 0

  const labels = cumulative.map(d => d.date)

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} spacing={2}>
        <BoxToggle
          value={!isMomentumViewsHidden}
          color={theme.palette.primary.main}
          onChange={v => setIsMomentumViewsHidden(!v)}
        >
          <Stack alignItems={'center'} justifyContent={'stretch'}>
            <Typography variant={'body1'}>Views from ProductWind</Typography>
            <Typography variant={'label1'}>{momentumViewsSum.toLocaleString()} views</Typography>
          </Stack>
        </BoxToggle>
        {isIntegrated && (
          <BoxToggle
            value={!isOtherViewsHidden}
            color={theme.palette.secondary.main}
            onChange={v => setIsOtherViewsHidden(!v)}
          >
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>Other views</Typography>
              <Typography variant={'label1'}>{otherViewsSum.toLocaleString()} views</Typography>
            </Stack>
          </BoxToggle>
        )}
        {isIntegrated && (
          <BoxToggle
            value={!isTotalViewsHidden}
            color={theme.palette.warning.main}
            onChange={v => setIsTotalViewsHidden(!v)}
          >
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>Total views</Typography>
              <Typography variant={'label1'}>{totalViewsSum.toLocaleString()} views</Typography>
            </Stack>
          </BoxToggle>
        )}
      </Stack>
      <Box position={'relative'} margin={'auto'}>
        <Line
          data={{
            labels: labels,
            datasets: [
              {
                borderColor: theme.palette.primary.main,
                pointBorderColor: theme.palette.primary.main,
                pointBackgroundColor: theme.palette.primary.main,
                data: momentumViews,
                hidden: isMomentumViewsHidden
              },
              ...(isIntegrated
                ? [
                    {
                      borderColor: theme.palette.secondary.main,
                      pointBorderColor: theme.palette.secondary.main,
                      pointBackgroundColor: theme.palette.secondary.main,
                      data: otherViews,
                      hidden: isOtherViewsHidden
                    },
                    {
                      borderColor: theme.palette.warning.main,
                      pointBorderColor: theme.palette.warning.main,
                      pointBackgroundColor: theme.palette.warning.main,
                      data: totalViews,
                      hidden: isTotalViewsHidden
                    }
                  ]
                : [])
            ]
          }}
          options={{
            elements: { point: { radius: 0, hitRadius: 10 } },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                ...defaultTooltipOptions,
                callbacks: {
                  label: item => {
                    const value = item.formattedValue
                    return `${value} views`
                  },
                  title: () => '',
                  footer: () => ''
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  drawOnChartArea: false,
                  drawTicks: false
                },
                offset: true,
                ticks: {
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  color: 'black',
                  padding: 16
                },
                border: {
                  color: 'black',
                  width: 3
                }
              },
              x: {
                type: 'time',
                ticks: {
                  minRotation: 45,
                  padding: 16,
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  color: value => {
                    const date = DateTime.fromMillis(value.tick.value).toISODate()
                    return date === startDateNoTime || date === endDateNoTime ? theme.palette.primary.main : 'black'
                  }
                },
                time: {
                  unit: 'day',
                  displayFormats: {
                    day: 'LL/dd'
                  }
                },
                grid: {
                  drawOnChartArea: true,
                  drawTicks: false,
                  color: function (value) {
                    const date = DateTime.fromMillis(value.tick.value).toISODate()
                    return date === startDateNoTime || date === endDateNoTime ? theme.palette.grey.A400 : 'transparent'
                  }
                },
                border: {
                  color: 'black',
                  width: 3
                }
              }
            }
          }}
          height={'560px'}
        />
      </Box>
    </Stack>
  )
}

import { Dialog, Typography, IconButton, Button } from '@mui/material'
import { Stack } from '@mui/system'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

type HideSkuRecommendationsDialogProps = {
  open: boolean
  processing: boolean
  onClose: () => void
  onSubmit: () => void
  product: { image?: string; name: string }
}
export const HideSkuRecommendationsDialog = ({
  open,
  product,
  processing,
  onClose,
  onSubmit
}: HideSkuRecommendationsDialogProps) => {
  return (
    <Dialog open={open}>
      <Stack spacing={3} p={3}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
          <Typography variant={'h4'}>Do you want to hide recommendations for this product?</Typography>
          <IconButton onClick={onClose} size={'small'}>
            <Close />
          </IconButton>
        </Stack>
        <Stack spacing={2}>
          <Typography variant={'label3'}>
            Recommendations for this product will be archived and not visible in active recommendations
          </Typography>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <img src={product.image} alt={product.name} style={{ height: 48, width: 48, objectFit: 'contain' }} />
            <Typography variant={'label3'}>{product.name}</Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
          <Button variant={'text'} onClick={onClose} disabled={processing}>
            Cancel
          </Button>
          <LoadingButton variant={'contained'} loading={processing} onClick={onSubmit}>
            Hide recommendations for this product
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

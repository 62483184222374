import { useBrandIntegrationHandler } from '@momentum/hooks/useBrandIntegrationHandler'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { getSellerBrandApi, getVendorBrandApi } from '@momentum/utils/brandApiUtils'
import { AccountLinked } from './AccountLinked'
import { AccountNotLinked } from './AccountNotLinked'
import { IntegrationType } from './utils'

export const LinkAccount = ({ type }: { type: IntegrationType }) => {
  const { brand } = useBrandContext()
  const handleIntegration = useBrandIntegrationHandler()

  const brandApi =
    type === IntegrationType.VENDOR ? getVendorBrandApi(brand.brandApis) : getSellerBrandApi(brand.brandApis)

  if (!brandApi) {
    return null
  }

  if (brandApi.isIntegrated) {
    return <AccountLinked type={type} />
  }

  return <AccountNotLinked type={type} onIntegrationTriggered={() => handleIntegration(brandApi)} />
}

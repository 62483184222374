import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { isString } from 'lodash'

const Metric = ({ label, value }: { label: string | React.ReactNode; value: string | React.ReactNode }) => {
  return (
    <Stack alignItems={'center'} spacing={0.5}>
      {isString(value) ? <Typography variant={'label1'}>{value}</Typography> : value}
      {isString(label) ? <Typography variant={'body1'}>{label}</Typography> : label}
    </Stack>
  )
}

export default Metric

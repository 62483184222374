import { UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'
import useUploadFile from '@momentum/hooks/useUploadFile'
import { InputBase } from '@productwindtom/ui-base'
import { VariantInputBaseProps } from 'libs/ui/base/src/lib/forms/InputBase/types'
import { Box, ButtonProps, Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { CheckCircleOutline } from '@mui/icons-material'
import { MEDIA_CDN } from '@momentum/env'

type AudioSelectProps = {
  uploadType: UploadRequestType
  companyId?: string
  brandId?: string
  buttonText?: string
  replacementText?: string
  buttonProps?: ButtonProps
  readOnly?: boolean
}
export const AudioSelect = ({
  uploadType,
  companyId,
  brandId,
  buttonText = 'Click to upload audio',
  replacementText = 'Click to replace',
  buttonProps,
  readOnly,
  ...rest
}: VariantInputBaseProps & AudioSelectProps) => {
  const { control } = useFormContext()
  const { name } = { ...rest }
  const {
    field: { onChange, onBlur, value }
  } = useController({ name, control })
  const [processing, setProcessing] = React.useState<boolean>(false)
  const audioRef = React.createRef<HTMLInputElement>()

  const uploadFile = useUploadFile()

  const handleAudioPick = async (file: File) => {
    setProcessing(true)
    const key = await uploadFile(file, uploadType, { name: 'name_audio_file', companyId, brandId })
    onChange(key)
    onBlur()
    setProcessing(false)
  }

  const playAudio = () => {
    if (value) {
      const audio = new Audio(MEDIA_CDN + value)
      audio.play()
    }
  }

  return (
    <InputBase {...rest}>
      <Stack direction={'row'} spacing={2}>
        {!!value && (
          <Stack direction={'row'} spacing={1} alignItems={'center'} onClick={playAudio} sx={{ cursor: 'pointer' }}>
            <CheckCircleOutline fontSize={'mSmall'} color={'success'} />
            <Typography variant={'label4'} color={theme => theme.palette.grey.A700}>
              {value.split('/').pop()}
            </Typography>
          </Stack>
        )}
        {!readOnly && (
          <Box>
            <LoadingButton
              loading={processing}
              disabled={processing}
              onClick={() => audioRef?.current?.click()}
              {...buttonProps}
            >
              {value ? replacementText : buttonText}
            </LoadingButton>
          </Box>
        )}
        <input
          style={{ display: 'none' }}
          accept={'audio/*'}
          ref={audioRef}
          value={''}
          onChange={event => {
            if (event.target.files && event.target.files.length > 0) {
              setProcessing(true)
              handleAudioPick(event.target.files[0])
            }
          }}
          type="file"
        />
      </Stack>
    </InputBase>
  )
}

export const ENV = 'production'
export const MEDIA_CDN = 'https://media.productwind.com/' as string
export const APPSYNC_CONFIG = {
  aws_user_pools_id: 'us-east-2_zYbSZFp3G',
  aws_user_pools_web_client_id: '8jcdd9b0sdcohl7sqarlvuemk',
  aws_appsync_graphqlEndpoint: 'https://rku5gsgsrrgbxijuvijmovidae.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_apiKey: 'da2-d5bwsxqvk5ge5gobcokevoelxi'
}
export const UNLEASH_CLIENT_ID =
  'momentum:production.e95827540357942da8f9d2efc550eebb1519053ebe602206018a1f56' as string
export const MIXPANEL_TOKEN = '1753b2a2b0e32b31472ef445fd34fc53'

import { Stack, Typography } from '@mui/material'
import React from 'react'
import CollectingResultsImage from './image.png'

export const CollectingResults = () => {
  return (
    <Stack alignItems={'center'} spacing={3} pt={12}>
      <img src={CollectingResultsImage} alt={'Collecting results'} width={200} />
      <Typography variant={'h5'} textAlign={'center'} maxWidth={'442px'}>
        We’re collecting reviews & ratings.
      </Typography>
      <Typography variant={'label4'}>The reviews & ratings dashboard will be available soon!</Typography>
    </Stack>
  )
}

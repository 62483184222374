import { Stack } from '@mui/system'
import { Typography } from '@mui/material'
import React from 'react'
import { EmptyIcon } from '@momentum/routes/campaign/my-actions/approve-creators/empty-state/EmptyIcon'

export const EmptyState = () => {
  return (
    <Stack pt={12} spacing={4} alignItems={'center'} justifyContent={'center'}>
      <EmptyIcon sx={{ height: '201px', width: '202px' }} />
      <Typography variant={'h5'}>You have no creators to review!</Typography>
      <Typography variant={'label4'}>Creators that you need to review will appear here.</Typography>
    </Stack>
  )
}

import { ZeusInterceptor } from '../types'
import { SAMPLE_PRODUCT_SEO_METRICS } from './sample-data'
import { SAMPLE_CAMPAIGNS } from '@momentum/api/interceptors/getCampaign/sample-data'

export const getPWProductSeoSummary: ZeusInterceptor<'query', 'getPWProductSeoSummary'> = async args => {
  const params = args.o.getPWProductSeoSummary?.[0]
  const campaign = params?.campaignId ? SAMPLE_CAMPAIGNS[params.campaignId] : undefined
  if (campaign?.skuId) {
    return SAMPLE_PRODUCT_SEO_METRICS[campaign?.skuId]
  }
}

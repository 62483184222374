import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { AGENCY_SAMPLE_BRANDS, SAMPLE_BRANDS } from '../../getBrand/sample-data'
import { SAMPLE_AGENCY } from '../../getAgency/sample-data'

export const SAMPLE_COMPANY: ModelTypes['Company'] = {
  id: 'sample-company',
  name: 'Sample Company',
  logo: 'https://media.productwind.com/sample/images/sample-company.png',
  websiteUrl: 'https://momentum.online',
  brands: Object.values(SAMPLE_BRANDS),
  pricing: [],
  createdAt: '2024-02-01T17:33:20.413Z',
  updatedAt: '2024-02-01T17:33:20.413Z',
  agencyId: SAMPLE_AGENCY.id
}

// This is a *temporary* implementation of basic companies to display for agencies
export const AGENCY_SAMPLE_COMPANIES = [
  {
    id: 'sample-company-electronics',
    name: 'Sample Company Electronics',
    logo: 'https://media.productwind.com/sample/images/sample-company-electronics.png',
    websiteUrl: 'https://momentum.online',
    brands: [AGENCY_SAMPLE_BRANDS['sample-brand-1'], AGENCY_SAMPLE_BRANDS['sample-brand-2']],
    pricing: [],
    createdAt: '2024-02-01T17:33:20.413Z',
    updatedAt: '2024-02-01T17:33:20.413Z',
    agencyId: SAMPLE_AGENCY.id
  },
  {
    id: 'sample-company-kitchen',
    name: 'Sample Company Kitchen',
    logo: 'https://media.productwind.com/sample/images/sample-company-kitchen.png',
    websiteUrl: 'https://momentum.online',
    brands: [AGENCY_SAMPLE_BRANDS['sample-brand-3']],
    pricing: [],
    createdAt: '2024-02-01T17:33:20.413Z',
    updatedAt: '2024-02-01T17:33:20.413Z',
    agencyId: SAMPLE_AGENCY.id
  }
]

import { ListingBadge, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_PRODUCT_SEO_METRICS: Record<string, ModelTypes['PWProductSeoSummary']> = {
  'sample-sku-laundry': {
    campaignId: 'sample-campaign-laundry',
    createdAt: '2023-08-01T00:00:00.000Z',
    updatedAt: '2023-09-01T00:00:00.000Z',
    productSEOSummaryRecords: [
      {
        weekIndex: 0,
        categoryRanks: [],
        impressions: 207658,
        isOutOfStock: false,
        topPageWins: 0
      },
      {
        weekIndex: 1,
        categoryRanks: [],
        impressions: 289410,
        isOutOfStock: false,
        topPageWins: 10
      },
      {
        weekIndex: 2,
        categoryRanks: [],
        impressions: 367302,
        isOutOfStock: false,
        topPageWins: 10
      },
      {
        weekIndex: 3,
        categoryRanks: [],
        impressions: 777230,
        isOutOfStock: false,
        topPageWins: 3
      },
      {
        weekIndex: 4,
        categoryRanks: [],
        impressions: 393696,
        isOutOfStock: false,
        topPageWins: 5
      },
      {
        weekIndex: 5,
        categoryRanks: [],
        impressions: 802190,
        isOutOfStock: false,
        topPageWins: 1
      },
      {
        weekIndex: 6,
        categoryRanks: [],
        impressions: 339614,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 7,
        categoryRanks: [],
        impressions: 325705,
        isOutOfStock: false,
        topPageWins: 4
      },
      {
        weekIndex: 8,
        categoryRanks: [],
        impressions: 349524,
        isOutOfStock: false,
        topPageWins: 1
      }
    ],
    keywords: [
      {
        badges: [],
        highestRank: 9,
        impressions: 8620,
        keyword: 'washing detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-10',
        startRank: 59
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 845,
        keyword: 'laundry soap sheets travel',
        pageOneWin: true,
        pageOneWinDate: '2023-08-10',
        startRank: 30
      },
      {
        badges: [],
        highestRank: 9,
        impressions: 3458,
        keyword: 'washing powder',
        pageOneWin: true,
        pageOneWinDate: '2023-08-14',
        startRank: 72
      },
      {
        badges: [],
        highestRank: 4,
        impressions: 10560,
        keyword: 'travel laundry detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-14',
        startRank: 29
      },
      {
        badges: [],
        highestRank: 4,
        impressions: 1366,
        keyword: 'travel laundry detergent sheets',
        pageOneWin: true,
        pageOneWinDate: '2023-08-14',
        startRank: 28
      },
      {
        badges: [],
        highestRank: 2,
        impressions: 1535,
        keyword: 'laundry sheet',
        pageOneWin: true,
        pageOneWinDate: '2023-08-14',
        startRank: 30
      },
      {
        badges: [],
        highestRank: 4,
        impressions: 1761,
        keyword: 'laundry sheets for travel',
        pageOneWin: true,
        pageOneWinDate: '2023-08-14',
        startRank: 31
      },
      {
        badges: [],
        highestRank: 13,
        impressions: 354949,
        keyword: 'laundry detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-15',
        startRank: 30
      },
      {
        badges: [],
        highestRank: 8,
        impressions: 7357,
        keyword: 'powder laundry detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-15',
        startRank: 31
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 16,
        impressions: 1763,
        keyword: 'laundry detergent sheets unscented',
        pageOneWin: true,
        pageOneWinDate: '2023-08-18',
        startRank: 28
      },
      {
        badges: [],
        highestRank: 18,
        impressions: 5843,
        keyword: 'all laundry detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-02',
        startRank: 22
      },
      {
        badges: [],
        highestRank: 5,
        impressions: 1937,
        keyword: 'laundry detergent bulk',
        pageOneWin: true,
        pageOneWinDate: '2023-08-02',
        startRank: 21
      },
      {
        badges: [],
        highestRank: 5,
        impressions: 443,
        keyword: 'laundry detergent liquid arm and hammer',
        pageOneWin: true,
        pageOneWinDate: '2023-08-02',
        startRank: 22
      },
      {
        badges: [],
        highestRank: 14,
        impressions: 6447,
        keyword: 'arm and hammer laundry booster',
        pageOneWin: true,
        pageOneWinDate: '2023-08-02',
        startRank: 20
      },
      {
        badges: [],
        highestRank: 10,
        impressions: 5159,
        keyword: 'arm and hammer',
        pageOneWin: true,
        pageOneWinDate: '2023-08-22',
        startRank: 22
      },
      {
        badges: [],
        highestRank: 5,
        impressions: 36116,
        keyword: 'laundry supplies',
        pageOneWin: true,
        pageOneWinDate: '2023-08-23',
        startRank: 79
      },
      {
        badges: [],
        highestRank: 3,
        impressions: 3092,
        keyword: 'travel detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-24',
        startRank: 30
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 1845,
        keyword: 'eco laundry detergent sheets',
        pageOneWin: true,
        pageOneWinDate: '2023-08-28',
        startRank: 32
      },
      {
        badges: [],
        highestRank: 18,
        impressions: 4693,
        keyword: 'laundry sheets detergent eco friendly',
        pageOneWin: true,
        pageOneWinDate: '2023-08-28',
        startRank: 26
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 4342,
        keyword: 'washer sheets detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-03',
        startRank: 32
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 3,
        impressions: 15841,
        keyword: 'laundry',
        pageOneWin: true,
        pageOneWinDate: '2023-08-30',
        startRank: 24
      },
      {
        badges: [],
        highestRank: 11,
        impressions: 40766,
        keyword: 'laundry soap',
        pageOneWin: true,
        pageOneWinDate: '2023-08-04',
        startRank: 65
      },
      {
        badges: [],
        highestRank: 2,
        impressions: 2982,
        keyword: 'detergent powder',
        pageOneWin: true,
        pageOneWinDate: '2023-08-04',
        startRank: 38
      },
      {
        badges: [],
        highestRank: 3,
        impressions: 1366,
        keyword: 'washing powder laundry detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-04',
        startRank: 25
      },
      {
        badges: [],
        highestRank: 7,
        impressions: 8856,
        keyword: 'natural laundry detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-07',
        startRank: 85
      },
      {
        badges: [],
        highestRank: 4,
        impressions: 2982,
        keyword: 'clothes detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-07',
        startRank: 51
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 2085,
        keyword: 'washing machine sheets detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-08-09',
        startRank: 32
      },
      {
        badges: [],
        highestRank: 18,
        impressions: 907,
        keyword: 'laundry detergent eco sheets',
        pageOneWin: true,
        pageOneWinDate: '2023-08-09',
        startRank: 27
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 2,
        impressions: 2318,
        keyword: 'washer sheets',
        pageOneWin: true,
        pageOneWinDate: '2023-08-09',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 5554,
        keyword: 'non toxic laundry detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-09-13',
        startRank: 306
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 5,
        impressions: 2071,
        keyword: 'arm and hammer detergent',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 5
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 1,
        impressions: 1347,
        keyword: 'washing sheets detergent',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 8
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 1,
        impressions: 27661,
        keyword: 'detergent sheets',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 7
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 93660,
        keyword: 'laundry detergent sheets',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 7
      },
      {
        badges: [],
        highestRank: 5,
        impressions: 71045,
        keyword: 'arm and hammer laundry detergent',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 17
      },
      {
        badges: [],
        highestRank: 5,
        impressions: 1365,
        keyword: 'arm and hammer laundry detergent pods',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 12
      },
      {
        badges: [],
        highestRank: 8,
        impressions: 1365,
        keyword: 'arm and hammer laundry detergent sensitive skin',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 13
      },
      {
        badges: [],
        highestRank: 9,
        impressions: 1609,
        keyword: 'arm and hammer sensitive skin laundry detergent',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 11
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 54049,
        keyword: 'laundry sheets',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 19
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 15869,
        keyword: 'laundry sheets detergent',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 9
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 5713,
        keyword: 'laundry soap sheets',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 6
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 1379,
        keyword: 'laundry strips',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 20
      },
      {
        badges: [],
        highestRank: 2,
        impressions: 1581,
        keyword: 'travel size laundry detergent',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 14
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 1365,
        keyword: 'sheet laundry detergent',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 4
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 907,
        keyword: 'laundry sheets made in usa',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 19
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 3294,
        keyword: 'eco friendly laundry detergent',
        pageOneWin: true,
        pageOneWinDate: '2023-09-14',
        startRank: 28
      },
      {
        badges: [],
        highestRank: 4,
        impressions: 32747,
        keyword: 'laundry detergent pods',
        pageOneWin: true,
        pageOneWinDate: '2023-09-14',
        startRank: 30
      },
      {
        badges: [],
        highestRank: 4,
        impressions: 2085,
        keyword: 'travel laundry sheets',
        pageOneWin: true,
        pageOneWinDate: '2023-09-14',
        startRank: 31
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 525,
        keyword: 'laundry detergent sheets eco friendly',
        pageOneWin: true,
        pageOneWinDate: '2023-09-19',
        startRank: 29
      }
    ]
  },
  'sample-sku-keyboard': {
    campaignId: 'sample-campaign-keyboard',
    createdAt: '2023-08-01T00:00:00.000Z',
    updatedAt: '2023-09-01T00:00:00.000Z',
    productSEOSummaryRecords: [
      {
        weekIndex: 0,
        categoryRanks: [],
        impressions: 151347,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 1,
        categoryRanks: [],
        impressions: 295237,
        isOutOfStock: false,
        topPageWins: 8
      },
      {
        weekIndex: 2,
        categoryRanks: [],
        impressions: 259737,
        isOutOfStock: false,
        topPageWins: 2
      },
      {
        weekIndex: 3,
        categoryRanks: [],
        impressions: 232786,
        isOutOfStock: false,
        topPageWins: 1
      },
      {
        weekIndex: 4,
        categoryRanks: [],
        impressions: 138664,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 5,
        categoryRanks: [],
        impressions: 282646,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 6,
        categoryRanks: [],
        impressions: 174549,
        isOutOfStock: false,
        topPageWins: undefined
      }
    ],
    keywords: [
      {
        badges: [],
        highestRank: 19,
        impressions: 19109,
        keyword: 'logitech',
        pageOneWin: true,
        pageOneWinDate: '2024-01-16',
        startRank: 48
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 16573,
        keyword: 'computer keyboards',
        pageOneWin: true,
        pageOneWinDate: '2024-01-16',
        startRank: 61
      },
      {
        badges: [],
        highestRank: 10,
        impressions: 6338,
        keyword: 'portable keyboard',
        pageOneWin: true,
        pageOneWinDate: '2024-01-16',
        startRank: 31
      },
      {
        badges: [],
        highestRank: 7,
        impressions: 4096,
        keyword: 'bluetooth mouse and keyboard',
        pageOneWin: true,
        pageOneWinDate: '2024-01-16',
        startRank: 21
      },
      {
        badges: [],
        highestRank: 120,
        impressions: 35541,
        keyword: 'keyboard and mouse',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 120
      },
      {
        badges: [],
        highestRank: 76,
        impressions: 32210,
        keyword: 'wireless keyboard mouse combo',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 76
      },
      {
        badges: [],
        highestRank: 92,
        impressions: 294481,
        keyword: 'keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 92
      },
      {
        badges: [],
        highestRank: 172,
        impressions: 199068,
        keyword: 'wireless keyboard and mouse',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 172
      },
      {
        badges: [],
        highestRank: 119,
        impressions: 158550,
        keyword: 'wireless keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 119
      },
      {
        badges: [],
        highestRank: 20,
        impressions: 68709,
        keyword: 'logitech keyboard',
        pageOneWin: true,
        pageOneWinDate: '2024-01-17',
        startRank: 22
      },
      {
        badges: [],
        highestRank: 92,
        impressions: 17952,
        keyword: 'keyboard for ipad',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 92
      },
      {
        badges: [],
        highestRank: 14,
        impressions: 22933,
        keyword: 'bluetooth keyboard and mouse combo',
        pageOneWin: true,
        pageOneWinDate: '2024-01-18',
        startRank: 50
      },
      {
        badges: [],
        highestRank: 83,
        impressions: 14721,
        keyword: 'white keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 83
      },
      {
        badges: [],
        highestRank: 98,
        impressions: 12608,
        keyword: 'mini keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 98
      },
      {
        badges: [],
        highestRank: 131,
        impressions: 17955,
        keyword: 'wireless mouse and keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 131
      },
      {
        badges: [],
        highestRank: 118,
        impressions: 13309,
        keyword: 'mouse and keyboard combo',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 118
      },
      {
        badges: [],
        highestRank: 29,
        impressions: 8713,
        keyword: 'wireless keyboard and mouse combo',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 29
      },
      {
        badges: [],
        highestRank: 17,
        impressions: 8469,
        keyword: 'bluetooth keyboard and mouse',
        pageOneWin: true,
        pageOneWinDate: '2024-01-22',
        startRank: 53
      },
      {
        badges: [],
        highestRank: 74,
        impressions: 5592,
        keyword: 'ipad mini keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 74
      },
      {
        badges: [],
        highestRank: 49,
        impressions: 4269,
        keyword: 'wireless keyboard for ipad',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 49
      },
      {
        badges: [],
        highestRank: 31,
        impressions: 3403,
        keyword: 'mini bluetooth keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 31
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 3345,
        keyword: 'keyboard logitech',
        pageOneWin: true,
        pageOneWinDate: '2024-01-17',
        startRank: 21
      },
      {
        badges: [],
        highestRank: 10,
        impressions: 2990,
        keyword: 'mini keyboard and mouse',
        pageOneWin: true,
        pageOneWinDate: '2024-01-17',
        startRank: 21
      },
      {
        badges: [],
        highestRank: 3,
        impressions: 69847,
        keyword: 'bluetooth keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 3
      },
      {
        badges: [],
        highestRank: 9,
        impressions: 30152,
        keyword: 'logitech keyboard and mouse combo',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 9
      },
      {
        badges: [],
        highestRank: 12,
        impressions: 14677,
        keyword: 'logitech wireless keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 12
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 12598,
        keyword: 'bluetooth keyboard for ipad',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 19
      },
      {
        badges: [],
        highestRank: 11,
        impressions: 15658,
        keyword: 'logitech wireless keyboard and mouse combo',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 11
      },
      {
        badges: [],
        highestRank: 10,
        impressions: 4833,
        keyword: 'wireless keyboard and mouse logitech',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 10
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 1,
        impressions: 3582,
        keyword: 'logitech bluetooth keyboard',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 1
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 4000,
        keyword: 'ipad keyboard and mouse',
        pageOneWin: true,
        pageOneWinDate: '2024-01-23',
        startRank: 57
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 5322,
        keyword: 'keyboard mouse combo',
        pageOneWin: true,
        pageOneWinDate: '2024-01-29',
        startRank: 130
      }
    ]
  },
  'sample-sku-whitening': {
    campaignId: 'sample-campaign-whitening',
    createdAt: '2023-08-01T00:00:00.000Z',
    updatedAt: '2023-09-01T00:00:00.000Z',
    productSEOSummaryRecords: [
      {
        weekIndex: 0,
        categoryRanks: [],
        impressions: 48998,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 1,
        categoryRanks: [],
        impressions: 117885,
        isOutOfStock: false,
        topPageWins: 1
      },
      {
        weekIndex: 2,
        categoryRanks: [],
        impressions: 158053,
        isOutOfStock: false,
        topPageWins: 3
      },
      {
        weekIndex: 3,
        categoryRanks: [],
        impressions: 490104,
        isOutOfStock: false,
        topPageWins: 6
      },
      {
        weekIndex: 4,
        categoryRanks: [],
        impressions: 78439,
        isOutOfStock: false,
        topPageWins: 3
      },
      {
        weekIndex: 5,
        categoryRanks: [],
        impressions: 130919,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 6,
        categoryRanks: [],
        impressions: 43183,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 7,
        categoryRanks: [],
        impressions: 39508,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 8,
        categoryRanks: [],
        impressions: 49981,
        isOutOfStock: false,
        topPageWins: undefined
      }
    ],
    keywords: [
      {
        badges: [],
        highestRank: 10,
        impressions: 85612,
        keyword: 'teeth whitening kit',
        pageOneWin: true,
        pageOneWinDate: '2024-02-01',
        startRank: 27
      },
      {
        badges: [],
        highestRank: 18,
        impressions: 2982,
        keyword: 'snow whitening kit',
        pageOneWin: true,
        pageOneWinDate: '2024-02-12',
        startRank: 32
      },
      {
        badges: [],
        highestRank: 12,
        impressions: 27653,
        keyword: 'teeth whitening pen',
        pageOneWin: true,
        pageOneWinDate: '2024-02-12',
        startRank: 63
      },
      {
        badges: [],
        highestRank: 13,
        impressions: 7357,
        keyword: 'teeth whitener',
        pageOneWin: true,
        pageOneWinDate: '2024-02-13',
        startRank: 69
      },
      {
        badges: [],
        highestRank: 16,
        impressions: 3272,
        keyword: 'tooth whitening pen',
        pageOneWin: true,
        pageOneWinDate: '2024-02-13',
        startRank: 35
      },
      {
        badges: [],
        highestRank: 11,
        impressions: 6436,
        keyword: 'whitening pen',
        pageOneWin: true,
        pageOneWinDate: '2024-02-14',
        startRank: 29
      },
      {
        badges: [],
        highestRank: 18,
        impressions: 360830,
        keyword: 'teeth whitening',
        pageOneWin: true,
        pageOneWinDate: '2024-02-15',
        startRank: 75
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 1827,
        keyword: 'teeth whitner',
        pageOneWin: true,
        pageOneWinDate: '2024-02-16',
        startRank: 113
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 3464,
        keyword: 'white teeth',
        pageOneWin: true,
        pageOneWinDate: '2024-02-20',
        startRank: 48
      },
      {
        badges: [],
        highestRank: 11,
        impressions: 8851,
        keyword: 'teeth whitening products',
        pageOneWin: true,
        pageOneWinDate: '2024-02-20',
        startRank: 55
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 3460,
        keyword: 'snow teeth whitening',
        pageOneWin: true,
        pageOneWinDate: '2024-02-20',
        startRank: 43
      },
      {
        badges: [],
        highestRank: 9,
        impressions: 8597,
        keyword: 'teeth whitening light',
        pageOneWin: true,
        pageOneWinDate: '2024-02-07',
        startRank: 21
      },
      {
        badges: [],
        highestRank: 8,
        impressions: 5729,
        keyword: 'whitening kit',
        pageOneWin: true,
        pageOneWinDate: '2024-02-07',
        startRank: 21
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 3,
        impressions: 4679,
        keyword: 'colgate whitening pen',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 5
      },
      {
        badges: [],
        highestRank: 44,
        impressions: 10546,
        keyword: 'white strips for teeth whitening',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 53
      },
      {
        badges: [],
        highestRank: 24,
        impressions: 5170,
        keyword: 'teeth cleaning kit',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 112,
        impressions: 148466,
        keyword: 'whitening strips',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 156
      },
      {
        badges: [],
        highestRank: 69,
        impressions: 32717,
        keyword: 'teeth whitening gel',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 306,
        impressions: 15892,
        keyword: 'color corrector',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 25,
        impressions: 10277,
        keyword: 'tooth whitening',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 105
      },
      {
        badges: [],
        highestRank: 40,
        impressions: 2280,
        keyword: 'signature whitening strips',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 61
      },
      {
        badges: [],
        highestRank: 64,
        impressions: 8092,
        keyword: 'whitening toothpaste for adults',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 165
      },
      {
        badges: [],
        highestRank: 33,
        impressions: 1365,
        keyword: 'teeth whitening essence',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 49
      },
      {
        badges: [],
        highestRank: 61,
        impressions: 6429,
        keyword: 'whitening gel',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 84
      },
      {
        badges: [],
        highestRank: 5,
        impressions: 32756,
        keyword: 'colgate optic white',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 15
      },
      {
        badges: [],
        highestRank: 12,
        impressions: 8622,
        keyword: 'snow teeth whitening kit',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 13
      },
      {
        badges: [],
        highestRank: 4,
        impressions: 2073,
        keyword: 'led teeth whitening kit',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 5
      }
    ]
  },
  'sample-sku-marker': {
    campaignId: 'sample-campaign-marker',
    createdAt: '2023-08-01T00:00:00.000Z',
    updatedAt: '2023-09-01T00:00:00.000Z',
    productSEOSummaryRecords: [
      {
        weekIndex: 0,
        categoryRanks: [],
        impressions: 6013,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 1,
        categoryRanks: [],
        impressions: 12121,
        isOutOfStock: false,
        topPageWins: 5
      },
      {
        weekIndex: 2,
        categoryRanks: [],
        impressions: 28739,
        isOutOfStock: false,
        topPageWins: 6
      },
      {
        weekIndex: 3,
        categoryRanks: [],
        impressions: 94819,
        isOutOfStock: false,
        topPageWins: 4
      },
      {
        weekIndex: 4,
        categoryRanks: [],
        impressions: 126586,
        isOutOfStock: false,
        topPageWins: undefined
      }
    ],
    keywords: [
      {
        badges: [],
        highestRank: 8,
        impressions: 3457,
        keyword: 'sharpie markers for the artist',
        pageOneWin: true,
        pageOneWinDate: '2024-02-28',
        startRank: 16
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 3278,
        keyword: 'sharpie paint pens',
        pageOneWin: true,
        pageOneWinDate: '2024-02-28',
        startRank: 17
      },
      {
        badges: [],
        highestRank: 14,
        impressions: 1588,
        keyword: 'water based markers',
        pageOneWin: true,
        pageOneWinDate: '2024-02-28',
        startRank: 29
      },
      {
        badges: [],
        highestRank: 20,
        impressions: 450,
        keyword: 'stabilo highlighters set',
        pageOneWin: true,
        pageOneWinDate: '2024-02-28',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 4,
        impressions: 484,
        keyword: 'sharpie brush tip markers',
        pageOneWin: true,
        pageOneWinDate: '2024-02-28',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 15,
        impressions: 51165,
        keyword: 'paint markers',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 13,
        impressions: 1288,
        keyword: 'grabbie acrylic markers',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 535,
        keyword: 'sharpie colored pens',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 16,
        impressions: 15910,
        keyword: 'acrylic paint markers',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 147
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 9,
        impressions: 12815,
        keyword: 'acrylic markers',
        pageOneWin: true,
        pageOneWinDate: '2024-03-04',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 17,
        impressions: 2316,
        keyword: 'sharpie oil based paint markers',
        pageOneWin: true,
        pageOneWinDate: '2024-03-04',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 16,
        impressions: 1584,
        keyword: 'colored sharpies marker sets',
        pageOneWin: true,
        pageOneWinDate: '2024-03-04',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 2,
        impressions: 1825,
        keyword: 'sharpie paint marker',
        pageOneWin: true,
        pageOneWinDate: '2024-03-04',
        startRank: 55
      },
      {
        badges: [],
        highestRank: 7,
        impressions: 438,
        keyword: 'sharpie s note duo',
        pageOneWin: true,
        pageOneWinDate: '2024-03-04',
        startRank: 306
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 20,
        impressions: 5705,
        keyword: 'sharpies marker sets',
        pageOneWin: true,
        pageOneWinDate: '2024-03-04',
        startRank: 41
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 39,
        impressions: 67100,
        keyword: 'sharpies',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 82
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 75,
        impressions: 4909,
        keyword: 'red sharpie',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 86
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 69,
        impressions: 8599,
        keyword: 'sharpie gel pens',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 103
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 56,
        impressions: 10551,
        keyword: 'sharpie pen',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 73
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 37,
        impressions: 5430,
        keyword: 'silver sharpie marker',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 43
      },
      {
        badges: [],
        highestRank: 106,
        impressions: 161605,
        keyword: 'markers',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 158
      },
      {
        badges: [],
        highestRank: 45,
        impressions: 10293,
        keyword: 'black sharpie',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 77
      },
      {
        badges: [],
        highestRank: 37,
        impressions: 148246,
        keyword: 'sharpie markers',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 113
      },
      {
        badges: [],
        highestRank: 61,
        impressions: 71042,
        keyword: 'sharpie pens',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 82
      },
      {
        badges: [],
        highestRank: 61,
        impressions: 51181,
        keyword: 'sharpie',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 78
      },
      {
        badges: [],
        highestRank: 123,
        impressions: 42651,
        keyword: 'paint pens',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 193
      },
      {
        badges: [],
        highestRank: 60,
        impressions: 30671,
        keyword: 'sharpie s gel pens',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 64
      },
      {
        badges: [],
        highestRank: 306,
        impressions: 23731,
        keyword: 'black sharpies',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 132,
        impressions: 23603,
        keyword: 'acrylic paint pens',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 184
      },
      {
        badges: [],
        highestRank: 55,
        impressions: 15899,
        keyword: 'silver sharpie',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 71
      },
      {
        badges: [],
        highestRank: 145,
        impressions: 10255,
        keyword: 'sharpies marker black',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 260
      },
      {
        badges: [],
        highestRank: 64,
        impressions: 8089,
        keyword: 'fine tip sharpie',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 84
      },
      {
        badges: [],
        highestRank: 178,
        impressions: 4689,
        keyword: 'paint marker',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 178
      },
      {
        badges: [],
        highestRank: 25,
        impressions: 5655,
        keyword: 'creative',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 45
      },
      {
        badges: [],
        highestRank: 36,
        impressions: 4344,
        keyword: 'colored sharpies',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 45
      },
      {
        badges: [],
        highestRank: 27,
        impressions: 4124,
        keyword: 'paint pens acrylic markers',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 168
      },
      {
        badges: [],
        highestRank: 58,
        impressions: 10576,
        keyword: 'mr sketch scented markers',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 77
      },
      {
        badges: [],
        highestRank: 1,
        impressions: 2429,
        keyword: 'sharpie creative markers',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 1
      },
      {
        badges: [],
        highestRank: 2,
        impressions: 1287,
        keyword: 'sharpie paint markers',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 11
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 34,
        impressions: 260,
        keyword: 'sharpues',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 100
      }
    ]
  },
  'sample-sku-pillow': {
    campaignId: 'sample-campaign-pillow',
    createdAt: '2023-08-01T00:00:00.000Z',
    updatedAt: '2023-09-01T00:00:00.000Z',
    productSEOSummaryRecords: [
      {
        weekIndex: 0,
        categoryRanks: [],
        impressions: 0,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 1,
        categoryRanks: [],
        impressions: 23754,
        isOutOfStock: false,
        topPageWins: 1
      },
      {
        weekIndex: 2,
        categoryRanks: [],
        impressions: 54984,
        isOutOfStock: false,
        topPageWins: 13
      },
      {
        weekIndex: 3,
        categoryRanks: [],
        impressions: 57280,
        isOutOfStock: false,
        topPageWins: undefined
      },
      {
        weekIndex: 4,
        categoryRanks: [],
        impressions: 58099,
        isOutOfStock: false,
        topPageWins: undefined
      }
    ],
    keywords: [
      {
        badges: [],
        highestRank: 5,
        impressions: 5680,
        keyword: 'down alternative pillow',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 19,
        impressions: 1164,
        keyword: 'beckham pillows',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 6,
        impressions: 1163,
        keyword: 'coop eden pillow',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 49
      },
      {
        badges: [],
        highestRank: 4,
        impressions: 573,
        keyword: 'coop home goods original pillow',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 24
      },
      {
        badges: [],
        highestRank: 6,
        impressions: 486,
        keyword: 'coop pillow filling',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 74
      },
      {
        badges: [],
        highestRank: 3,
        impressions: 713,
        keyword: 'coop pillows',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 27
      },
      {
        badges: [],
        highestRank: 20,
        impressions: 573,
        keyword: 'down pillow queen',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 258
      },
      {
        badges: [],
        highestRank: 3,
        impressions: 713,
        keyword: 'coop pillow king',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 33
      },
      {
        badges: [],
        highestRank: 8,
        impressions: 11,
        keyword: 'soft bed pillowbest pillows for sleeping',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 306
      },
      {
        badges: [
          {
            badge: ListingBadge.AMAZON_CHOICE,
            date: undefined
          }
        ],
        highestRank: 3,
        impressions: 642,
        keyword: 'coop pillow queen',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 35
      },
      {
        badges: [],
        highestRank: 6,
        impressions: 5432,
        keyword: 'down pillows queen size set of 2',
        pageOneWin: true,
        pageOneWinDate: '2024-03-11',
        startRank: 224
      },
      {
        badges: [],
        highestRank: 11,
        impressions: 2319,
        keyword: 'coop',
        pageOneWin: true,
        pageOneWinDate: '2024-03-12',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 12,
        impressions: 18794,
        keyword: 'down pillow',
        pageOneWin: true,
        pageOneWinDate: '2024-03-14',
        startRank: 306
      },
      {
        badges: [],
        highestRank: 3,
        impressions: 21050,
        keyword: 'coop pillow',
        pageOneWin: true,
        pageOneWinDate: '2024-03-06',
        startRank: 58
      },
      {
        badges: [],
        highestRank: 68,
        impressions: 5687,
        keyword: 'hotel pillows queen size set of 2',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 177,
        impressions: 50293,
        keyword: 'side sleeper pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 306,
        impressions: 15894,
        keyword: 'bed pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 306,
        impressions: 10284,
        keyword: 'back pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 53,
        impressions: 8849,
        keyword: 'hotel pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 104,
        impressions: 8622,
        keyword: 'small pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 53,
        impressions: 8092,
        keyword: 'hotel pillows',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 306,
        impressions: 8599,
        keyword: 'standard pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 306,
        impressions: 7320,
        keyword: 'back support pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 306,
        impressions: 6443,
        keyword: 'adjustable bed',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 140,
        impressions: 5658,
        keyword: 'hypoallergenic pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 33,
        impressions: 5690,
        keyword: 'queen size pillows',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 306,
        impressions: 5426,
        keyword: 'neck support pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 143,
        impressions: 4341,
        keyword: 'shoulder pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 306,
        impressions: 4123,
        keyword: 'neck support',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 55,
        impressions: 4123,
        keyword: 'queen pillow',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      },
      {
        badges: [],
        highestRank: 106,
        impressions: 1767,
        keyword: 'pillow king',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 113
      },
      {
        badges: [],
        highestRank: 49,
        impressions: 1290,
        keyword: 'beautyrest pillows queen',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 144
      },
      {
        badges: [],
        highestRank: 92,
        impressions: 1164,
        keyword: 'best king size pillows',
        pageOneWin: false,
        pageOneWinDate: undefined,
        startRank: 306
      }
    ]
  }
}

import { Button, Stack, Typography } from '@mui/material'
import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react'
import ErrorStateIcon from '../icons/error-state'
import { LinkRouter } from '@productwindtom/ui-base'
import { ROUTES } from '@momentum/routes/RouteNames'

const ErrorState = ({
  disableLink,
  ...props
}: ErrorBoundaryProps & {
  disableLink?: boolean
}) => {
  return (
    <ErrorBoundary
      fallback={
        <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'} spacing={3}>
          <ErrorStateIcon
            sx={{
              width: 280,
              height: 260
            }}
          />
          <Typography variant="h5" textAlign={'center'}>
            Oh no! <br /> We've encountered an error on this page.
          </Typography>
          {!disableLink && (
            <LinkRouter to={ROUTES.HOME} reloadDocument>
              <Button variant="contained">Back to home</Button>
            </LinkRouter>
          )}
        </Stack>
      }
      {...props}
    />
  )
}

export default ErrorState

import { ROUTES, ROUTE_NAMES_CAMPAIGN, ROUTE_NAMES_CAMPAIGN_OVERVIEW } from '@momentum/routes/RouteNames'
import { Tab, Tabs, Typography, Chip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Stack } from '@mui/system'
import { Link as RouterLink, useLocation, useMatch } from 'react-router-dom'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

const useStyles = makeStyles({
  tab: {
    textTransform: 'none'
  }
})

export const TabBar = () => {
  const base = [ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.OVERVIEW].join('/')
  const { pathname } = useLocation()
  const classes = useStyles()
  const { isAdminView } = useUserSessionContext()

  const baseMatch = useMatch(base)
  const defaultTab = ROUTE_NAMES_CAMPAIGN_OVERVIEW.SUMMARY
  const currentTab = baseMatch ? defaultTab : pathname.split('/').pop()

  return (
    <Tabs value={currentTab}>
      <Tab
        label={<TabTitle title={'Summary'} />}
        value={ROUTE_NAMES_CAMPAIGN_OVERVIEW.SUMMARY}
        to={ROUTE_NAMES_CAMPAIGN_OVERVIEW.SUMMARY}
        component={RouterLink}
        className={classes.tab}
        data-cy={['campaignSummaryTabs', ROUTE_NAMES_CAMPAIGN_OVERVIEW.SUMMARY].join('-')}
      />
      {isAdminView && (
        <Tab
          label={<TabTitle title={'Proposal'} chip="Admin" />}
          value={ROUTE_NAMES_CAMPAIGN_OVERVIEW.PROPOSAL}
          to={ROUTE_NAMES_CAMPAIGN_OVERVIEW.PROPOSAL}
          component={RouterLink}
          className={classes.tab}
          data-cy={['campaignSummaryTabs', ROUTE_NAMES_CAMPAIGN_OVERVIEW.PROPOSAL].join('-')}
        />
      )}
    </Tabs>
  )
}

const TabTitle = ({ title, chip }: { title: string; chip?: string }) => (
  <Stack direction={'row'} spacing={1} alignItems={'center'}>
    <Typography variant="inherit">{title}</Typography>
    {!!chip && <Chip label={chip} color="secondary" size="small" />}
  </Stack>
)

import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { mApi } from '@momentum/api'
import { pwProductSeoSummarySelector } from '@momentum/routes/campaign/context/queries'

export const createPWSeoSummary = async (input: ValueTypes['ModelInputCreatePWProductSeoSummary']) => {
  return (
    await mApi('mutation')({
      createPWProductSeoSummary: [{ input }, pwProductSeoSummarySelector]
    })
  ).createPWProductSeoSummary
}

export const updatePWSeoSummary = async (input: ValueTypes['ModelInputUpdatePWProductSeoSummary']) => {
  return (
    await mApi('mutation')({
      updatePWProductSeoSummary: [{ input }, pwProductSeoSummarySelector]
    })
  ).updatePWProductSeoSummary
}

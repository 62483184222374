import { createContext, useContext } from 'react'

export enum AlertType {
  REVIEWS_NOT_MERGED = 'Not merged'
}

export type ListingAlert = {
  alert: AlertType
  skuIds: string[]
  parentSkuId: string
}

export type ProductsContextType = {
  listingAlerts: ListingAlert[]
}

const ProductsContext = createContext<ProductsContextType>({ listingAlerts: [] })

ProductsContext.displayName = 'ProductsContext'

export default ProductsContext

export const useProductsContext = () => useContext(ProductsContext)

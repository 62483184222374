//@ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_ETAILER_PRODUCT_METRICS_ENERGY_DRINK: ModelTypes['ModelItemsEtailerProductMetrics'] = {
  items: [
    {
      date: '2024-05-23',
      pageViews: 4783,
      totalSalesAmount: 953391,
      unitsSold: 484
    },
    {
      date: '2024-05-24',
      pageViews: 4119,
      totalSalesAmount: 843587,
      unitsSold: 428
    },
    {
      date: '2024-05-25',
      pageViews: 4061,
      totalSalesAmount: 763472,
      unitsSold: 388
    },
    {
      date: '2024-05-26',
      pageViews: 4769,
      totalSalesAmount: 860279,
      unitsSold: 435
    },
    {
      date: '2024-05-27',
      pageViews: 5189,
      totalSalesAmount: 1127610,
      unitsSold: 571
    },
    {
      date: '2024-05-28',
      pageViews: 4378,
      totalSalesAmount: 1014156,
      unitsSold: 512
    },
    {
      date: '2024-05-29',
      pageViews: 4412,
      totalSalesAmount: 876225,
      unitsSold: 444
    },
    {
      date: '2024-05-30',
      pageViews: 4469,
      totalSalesAmount: 966621,
      unitsSold: 493
    },
    {
      date: '2024-05-31',
      pageViews: 3998,
      totalSalesAmount: 921416,
      unitsSold: 468
    },
    {
      date: '2024-06-01',
      pageViews: 5015,
      totalSalesAmount: 873669,
      unitsSold: 445
    },
    {
      date: '2024-06-02',
      pageViews: 6456,
      totalSalesAmount: 1060063,
      unitsSold: 538
    },
    {
      date: '2024-06-03',
      pageViews: 8562,
      totalSalesAmount: 1628150,
      unitsSold: 825
    },
    {
      date: '2024-06-04',
      pageViews: 4168,
      totalSalesAmount: 946802,
      unitsSold: 480
    },
    {
      date: '2024-06-05',
      pageViews: 3804,
      totalSalesAmount: 943185,
      unitsSold: 477
    },
    {
      date: '2024-06-06',
      pageViews: 3859,
      totalSalesAmount: 867209,
      unitsSold: 440
    },
    {
      date: '2024-06-07',
      pageViews: 4018,
      totalSalesAmount: 952154,
      unitsSold: 485
    },
    {
      date: '2024-06-08',
      pageViews: 3571,
      totalSalesAmount: 717781,
      unitsSold: 366
    },
    {
      date: '2024-06-09',
      pageViews: 3507,
      totalSalesAmount: 826347,
      unitsSold: 421
    },
    {
      date: '2024-06-10',
      pageViews: 3555,
      totalSalesAmount: 1032030,
      unitsSold: 527
    },
    {
      date: '2024-06-11',
      pageViews: 4701,
      totalSalesAmount: 993643,
      unitsSold: 508
    },
    {
      date: '2024-06-12',
      pageViews: 4759,
      totalSalesAmount: 1048490,
      unitsSold: 537
    },
    {
      date: '2024-06-13',
      pageViews: 4173,
      totalSalesAmount: 1038861,
      unitsSold: 534
    },
    {
      date: '2024-06-14',
      pageViews: 4634,
      totalSalesAmount: 1159453,
      unitsSold: 596
    },
    {
      date: '2024-06-15',
      pageViews: 4234,
      totalSalesAmount: 843433,
      unitsSold: 433
    },
    {
      date: '2024-06-16',
      pageViews: 4117,
      totalSalesAmount: 874718,
      unitsSold: 449
    },
    {
      date: '2024-06-17',
      pageViews: 4331,
      totalSalesAmount: 1214021,
      unitsSold: 622
    },
    {
      date: '2024-06-18',
      pageViews: 4592,
      totalSalesAmount: 1048965,
      unitsSold: 538
    },
    {
      date: '2024-06-19',
      pageViews: 13142,
      totalSalesAmount: 1079005,
      unitsSold: 551
    },
    {
      date: '2024-06-20',
      pageViews: 8630,
      totalSalesAmount: 1030550,
      unitsSold: 474
    },
    {
      date: '2024-06-21',
      pageViews: 8027,
      totalSalesAmount: 1178542,
      unitsSold: 594
    },
    {
      date: '2024-06-22',
      pageViews: 7164,
      totalSalesAmount: 927078,
      unitsSold: 477
    },
    {
      date: '2024-06-23',
      pageViews: 6942,
      totalSalesAmount: 921016,
      unitsSold: 473
    },
    {
      date: '2024-06-24',
      pageViews: 7170,
      totalSalesAmount: 1216899,
      unitsSold: 627
    },
    {
      date: '2024-06-25',
      pageViews: 6432,
      totalSalesAmount: 1134587,
      unitsSold: 586
    },
    {
      date: '2024-06-26',
      pageViews: 7153,
      totalSalesAmount: 869450,
      unitsSold: 449
    },
    {
      date: '2024-06-27',
      pageViews: 5965,
      totalSalesAmount: 755817,
      unitsSold: 392
    },
    {
      date: '2024-06-28',
      pageViews: 5483,
      totalSalesAmount: 1018427,
      unitsSold: 527
    },
    {
      date: '2024-06-29',
      pageViews: 5396,
      totalSalesAmount: 890577,
      unitsSold: 446
    },
    {
      date: '2024-06-30',
      pageViews: 5819,
      totalSalesAmount: 960607,
      unitsSold: 498
    },
    {
      date: '2024-07-01',
      pageViews: 6259,
      totalSalesAmount: 1307513,
      unitsSold: 679
    },
    {
      date: '2024-07-02',
      pageViews: 6483,
      totalSalesAmount: 1109319,
      unitsSold: 575
    },
    {
      date: '2024-07-03',
      pageViews: 7101,
      totalSalesAmount: 1224282,
      unitsSold: 632
    },
    {
      date: '2024-07-04',
      pageViews: 3588,
      totalSalesAmount: 648986,
      unitsSold: 268
    },
    {
      date: '2024-07-05',
      pageViews: 3521,
      totalSalesAmount: 592565,
      unitsSold: 243
    },
    {
      date: '2024-07-06',
      pageViews: 3380,
      totalSalesAmount: 491249,
      unitsSold: 171
    },
    {
      date: '2024-07-07',
      pageViews: 2982,
      totalSalesAmount: 539429,
      unitsSold: 189
    },
    {
      date: '2024-07-08',
      pageViews: 3567,
      totalSalesAmount: 929560,
      unitsSold: 343
    },
    {
      date: '2024-07-09',
      pageViews: 3801,
      totalSalesAmount: 676887,
      unitsSold: 248
    },
    {
      date: '2024-07-10',
      pageViews: 3530,
      totalSalesAmount: 707683,
      unitsSold: 258
    },
    {
      date: '2024-07-11',
      pageViews: 3473,
      totalSalesAmount: 691027,
      unitsSold: 253
    },
    {
      date: '2024-07-12',
      pageViews: 3238,
      totalSalesAmount: 834480,
      unitsSold: 304
    },
    {
      date: '2024-07-13',
      pageViews: 3116,
      totalSalesAmount: 438065,
      unitsSold: 157
    },
    {
      date: '2024-07-14',
      pageViews: 3105,
      totalSalesAmount: 395354,
      unitsSold: 143
    },
    {
      date: '2024-07-15',
      pageViews: 3405,
      totalSalesAmount: 764186,
      unitsSold: 283
    },
    {
      date: '2024-07-16',
      pageViews: 38914,
      totalSalesAmount: 3607209,
      unitsSold: 2342
    },
    {
      date: '2024-07-17',
      pageViews: 29657,
      totalSalesAmount: 2499382,
      unitsSold: 1674
    },
    {
      date: '2024-07-18',
      pageViews: 3625,
      totalSalesAmount: 278982,
      unitsSold: 107
    },
    {
      date: '2024-07-19',
      pageViews: 2502,
      totalSalesAmount: 358415,
      unitsSold: 126
    },
    {
      date: '2024-07-20',
      pageViews: 2961,
      totalSalesAmount: 235357,
      unitsSold: 83
    },
    {
      date: '2024-07-21',
      pageViews: 2614,
      totalSalesAmount: 154072,
      unitsSold: 51
    },
    {
      date: '2024-07-22',
      pageViews: 2753,
      totalSalesAmount: 573296,
      unitsSold: 208
    },
    {
      date: '2024-07-23',
      pageViews: 2750,
      totalSalesAmount: 390100,
      unitsSold: 142
    },
    {
      date: '2024-07-24',
      pageViews: 2117,
      totalSalesAmount: 288783,
      unitsSold: 108
    },
    {
      date: '2024-07-25',
      pageViews: 2113,
      totalSalesAmount: 593437,
      unitsSold: 265
    },
    {
      date: '2024-07-26',
      pageViews: 2043,
      totalSalesAmount: 601937,
      unitsSold: 271
    },
    {
      date: '2024-07-27',
      pageViews: 2034,
      totalSalesAmount: 401405,
      unitsSold: 182
    },
    {
      date: '2024-07-28',
      pageViews: 2203,
      totalSalesAmount: 453743,
      unitsSold: 203
    },
    {
      date: '2024-07-29',
      pageViews: 2880,
      totalSalesAmount: 771700,
      unitsSold: 344
    },
    {
      date: '2024-07-30',
      pageViews: 2859,
      totalSalesAmount: 808928,
      unitsSold: 362
    },
    {
      date: '2024-07-31',
      pageViews: 2611,
      totalSalesAmount: 719043,
      unitsSold: 323
    },
    {
      date: '2024-08-01',
      pageViews: 2942,
      totalSalesAmount: 667982,
      unitsSold: 298
    },
    {
      date: '2024-08-02',
      pageViews: 2700,
      totalSalesAmount: 784545,
      unitsSold: 349
    },
    {
      date: '2024-08-03',
      pageViews: 2616,
      totalSalesAmount: 667623,
      unitsSold: 297
    },
    {
      date: '2024-08-04',
      pageViews: 2879,
      totalSalesAmount: 583886,
      unitsSold: 259
    },
    {
      date: '2024-08-05',
      pageViews: 3020,
      totalSalesAmount: 860309,
      unitsSold: 383
    },
    {
      date: '2024-08-06',
      pageViews: 2650,
      totalSalesAmount: 795490,
      unitsSold: 354
    },
    {
      date: '2024-08-07',
      pageViews: 2729,
      totalSalesAmount: 739246,
      unitsSold: 329
    },
    {
      date: '2024-08-08',
      pageViews: 2959,
      totalSalesAmount: 870573,
      unitsSold: 387
    },
    {
      date: '2024-08-09',
      pageViews: 2972,
      totalSalesAmount: 831815,
      unitsSold: 370
    },
    {
      date: '2024-08-10',
      pageViews: 2684,
      totalSalesAmount: 621294,
      unitsSold: 276
    },
    {
      date: '2024-08-11',
      pageViews: 2768,
      totalSalesAmount: 697708,
      unitsSold: 311
    },
    {
      date: '2024-08-12',
      pageViews: 2866,
      totalSalesAmount: 953261,
      unitsSold: 424
    },
    {
      date: '2024-08-13',
      pageViews: 3136,
      totalSalesAmount: 674385,
      unitsSold: 300
    },
    {
      date: '2024-08-14',
      pageViews: 3335,
      totalSalesAmount: 572175,
      unitsSold: 255
    },
    {
      date: '2024-08-15',
      pageViews: 3241,
      totalSalesAmount: 668669,
      unitsSold: 298
    },
    {
      date: '2024-08-16',
      pageViews: 2959,
      totalSalesAmount: 725887,
      unitsSold: 323
    },
    {
      date: '2024-08-17',
      pageViews: 2599,
      totalSalesAmount: 454073,
      unitsSold: 202
    },
    {
      date: '2024-08-18',
      pageViews: 2516,
      totalSalesAmount: 388636,
      unitsSold: 173
    },
    {
      date: '2024-08-19',
      pageViews: 2420,
      totalSalesAmount: 671208,
      unitsSold: 299
    },
    {
      date: '2024-08-20',
      pageViews: 2646,
      totalSalesAmount: 688031,
      unitsSold: 306
    },
    {
      date: '2024-08-21',
      pageViews: 2826,
      totalSalesAmount: 638176,
      unitsSold: 284
    }
  ]
}

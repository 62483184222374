import { SvgIcon, SvgIconProps } from '@mui/material'

export default function PackagePlusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none">
      <path
        d="M11.6614 5.83395C11.6598 5.79555 11.656 5.75662 11.6459 5.71983C11.6422 5.7065 11.6347 5.6937 11.6299 5.68037C11.6187 5.64997 11.6086 5.62011 11.5926 5.59238C11.584 5.57798 11.5723 5.56625 11.5622 5.55239C11.5451 5.52839 11.5286 5.50386 11.5078 5.48307C11.4955 5.47133 11.4795 5.46174 11.4662 5.45054C11.4454 5.43347 11.4262 5.41481 11.4022 5.40041C11.4001 5.39881 11.3974 5.39881 11.3947 5.39721C11.3931 5.39615 11.3921 5.39508 11.3905 5.39401L6.60237 2.73307C6.44186 2.64349 6.24669 2.64402 6.08511 2.73254L1.27463 5.39348C1.27303 5.39508 1.27143 5.39721 1.26876 5.39881C1.26716 5.39934 1.26503 5.39988 1.26343 5.40094C1.24476 5.41161 1.2309 5.42707 1.2133 5.43987C1.1941 5.45427 1.17384 5.46707 1.15678 5.4836C1.14078 5.50013 1.12851 5.51933 1.11465 5.53799C1.10025 5.55665 1.08425 5.57319 1.07252 5.59345C1.05866 5.61638 1.05119 5.64251 1.04106 5.66757C1.03359 5.68517 1.024 5.7017 1.0192 5.7209C1.00907 5.75662 1.00587 5.79502 1.00373 5.83288C1.0032 5.84195 1 5.84994 1 5.85954V5.86008V5.86061V10.1266C1 10.3202 1.10505 10.4989 1.27463 10.5927L6.06218 13.252L6.06272 13.2526C6.06325 13.2526 6.06325 13.2526 6.06379 13.2531L6.07445 13.259C6.09738 13.2718 6.12244 13.2787 6.14644 13.2878C6.1635 13.2947 6.18003 13.3038 6.19816 13.3086C6.24136 13.3198 6.28722 13.3262 6.33255 13.3262C6.37787 13.3262 6.42373 13.3198 6.46799 13.3086C6.48559 13.3038 6.50212 13.2947 6.51972 13.2878C6.54371 13.2787 6.56878 13.2723 6.59171 13.259L6.60237 13.2531C6.6029 13.2526 6.60291 13.2526 6.60344 13.2526L6.60397 13.252L11.3915 10.5927C11.56 10.4989 11.6651 10.3202 11.6651 10.1266V5.86061V5.86008V5.85954C11.6651 5.85048 11.6619 5.84301 11.6614 5.83395ZM6.31761 7.92537L2.63069 5.86328L4.10354 5.04846L7.7478 7.13082L6.31761 7.92537ZM6.34321 3.80918L10.0339 5.86061L8.83724 6.52558L5.19511 4.44429L6.34321 3.80918ZM2.06651 6.76981L5.79929 8.85803V11.8869L2.06651 9.81256V6.76981ZM6.8658 11.8869L6.86687 8.85857L8.46556 7.96377V9.59339L9.53207 9.06014V7.36705L10.5986 6.77034V9.81256L6.8658 11.8869Z"
        fill="currentColor"
      />
      <path
        d="M12.3333 5.55556H13.2222V4.22222H14.5556V3.33333H13.2222V2H12.3333V3.33333H11V4.22222H12.3333V5.55556Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

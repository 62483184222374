import { TooltipOptions } from 'chart.js/dist/types'

export const defaultTooltipOptions: Partial<TooltipOptions> = {
  backgroundColor: 'white',
  displayColors: false,
  padding: 10,
  bodySpacing: 0,
  bodyColor: 'black',
  bodyFont: {
    size: 10,
    weight: 400,
    lineHeight: '18px'
  },
  borderColor: '#C4C4C4',
  borderWidth: 1
}

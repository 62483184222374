import { ContentTypeToString, SocialChannelToIcon } from '@momentum/utils/socialUtils'
import { Box, Button, Chip, Paper, Stack, Switch, Typography } from '@mui/material'
import { Channel, DraftStatus } from '@productwindtom/shared-momentum-zeus-types'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import VideoOrImage from '@momentum/components/video-or-image'
import { useApproveContentContext } from '../context/ApproveContentContext'
import { ApproveContentViewMode } from '../types'
import { DRAFT_CONTENT_STATUS_CONFIG } from '../constants'
import { useState } from 'react'
import ReviewedAt from './ReviewedAt'
import TimeToAction from './TimeToAction'
import { DateTime } from 'luxon'

const DraftContentCard = () => {
  const { content, handle, setApproveContentViewMode, handleToggleContentVisibility } = useApproveContentContext()

  const [isToggleDisabled, setIsToggleDisabled] = useState(false)
  const { isAdminView } = useUserSessionContext()
  const { draftStatus, isHidden, draftMedia, contentType, draftSubmittedAt } = content
  const { chip, button, icon } = DRAFT_CONTENT_STATUS_CONFIG[draftStatus as keyof typeof DRAFT_CONTENT_STATUS_CONFIG]

  return (
    <Paper elevation={0}>
      <Stack p={2} spacing={0.5}>
        <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <TimeToAction />
          {icon && icon}
          {chip && <Chip size="small" {...chip} />}
        </Stack>
        <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'label3'}>
            {content.channel !== Channel.UGC ? '@' : ''}
            {handle}
          </Typography>
          {isAdminView && (
            <Switch
              name="isHidden"
              size="small"
              disabled={isToggleDisabled}
              checked={!isHidden}
              onChange={async () => {
                setIsToggleDisabled(true)
                await handleToggleContentVisibility(!isHidden)
                setIsToggleDisabled(false)
              }}
            />
          )}
        </Stack>

        <VideoOrImage height={329} src={draftMedia?.[0]} disabled alt='Creator draft content'>
          <Box
            position={'absolute'}
            top={0}
            left={0}
            bgcolor={'#00000060'}
            width={'100%'}
            height={'100%'}
            display="flex"
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button {...button} onClick={() => setApproveContentViewMode(ApproveContentViewMode.REVIEW_CONTENT)}>
              {button.text}
            </Button>
          </Box>
          {SocialChannelToIcon[content.channel]?.({
            sx: {
              position: 'absolute',
              bottom: 10,
              left: 10,
              zIndex: 5,
              backgroundColor: 'white',
              borderRadius: '50%',
              p: 0.5
            }
          })}
        </VideoOrImage>
        <Typography pt={0.5} variant={'caption2'}>
          {ContentTypeToString[contentType]} {'\u2022'} Submitted on{' '}
          {DateTime.fromISO(draftSubmittedAt).toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
        {[DraftStatus.APPROVED, DraftStatus.REQUIRES_USER_CHANGES].includes(draftStatus) && <ReviewedAt />}
      </Stack>
    </Paper>
  )
}

export default DraftContentCard

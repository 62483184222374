import { Box, IconButton, Paper, Stack, Typography, Link, Switch } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Channel } from '@productwindtom/shared-momentum-zeus-types'
import { first, sumBy } from 'lodash'
import { isVideo } from '@productwindtom/shared-node'
import Video from '@momentum/components/video'
import { CheckCircle, Launch, CropFree, FavoriteBorder, Favorite } from '@mui/icons-material'
import { ContentTypeToString, SocialChannelToIcon, getHandle } from '@momentum/utils/socialUtils'
import { DateTime } from 'luxon'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { NoPreview } from '@momentum/components/no-preview'
import { CreatorAndContent } from '../types'

const ContentCard = ({
  content,
  selected,
  onSelect,
  onUnSelect,
  onExpand,
  onFavorite,
  onUnfavorite,
  onHiddenChange
}: {
  content: CreatorAndContent
  selected?: boolean
  onSelect: (id: string) => void
  onUnSelect: (id: string) => void
  onExpand?: () => void
  onFavorite?: () => void
  onUnfavorite?: () => void
  onHiddenChange?: (isHidden: boolean) => void
}) => {
  const { isAdminView } = useUserSessionContext()
  const handle = getHandle(content.channel, content) || `${content.firstName} ${content.lastName?.charAt(0)}`
  const [isHovered, setIsHovered] = useState(false)
  const [isFavorite, setIsFavorite] = useState(content.isFavorite)
  const [isHidden, setIsHidden] = useState(content.isHidden)

  useEffect(() => {
    setIsFavorite(content.isFavorite)
  }, [content.isFavorite])

  const hasAssets = content.content.some(c => !!c.assets.length)

  const firstMedia = first(first(content.content.filter(c => !!c.assets.length))?.assets)
  const isVideoAsset = firstMedia && isVideo(firstMedia?.url)

  const isShrink = selected

  const impressions = content.channel === Channel.UGC ? undefined : sumBy(content.content, 'views')

  return (
    <Paper elevation={0}>
      <Stack p={2} spacing={0.5}>
        <Stack direction={'row'} alignItems={'center'}>
          {!!handle && (
            <Typography variant={'label3'}>
              {content.channel !== Channel.UGC ? '@' : ''}
              {handle}
            </Typography>
          )}
          {isAdminView && (
            <Box ml={'auto'}>
              <Switch
                size={'small'}
                checked={!isHidden}
                disabled={!hasAssets}
                onChange={(_, checked) => {
                  setIsHidden(!checked)
                  onHiddenChange?.(!checked)
                }}
              />
            </Box>
          )}
        </Stack>
        <Box
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          py={isShrink ? 4 : 0}
          px={isShrink ? 3 : 0}
          height={329}
          bgcolor={'#F8FBFF'}
          borderRadius={'4px'}
          sx={{
            'transition': 'all 0.2s ease',
            'cursor': 'pointer',
            '&.pause-button': {
              display: 'inline-flex'
            },
            ...(isHovered && { background: 'rgba(0, 0, 0, .6)' })
          }}
          boxSizing={'border-box'}
          display={isVideoAsset ? 'flex' : 'block'}
          position={'relative'}
        >
          {isHovered && !isShrink && (
            <CheckCircle
              sx={{ position: 'absolute', top: 10, left: 10, zIndex: 5, opacity: 0.8, color: 'white' }}
              onClick={() => onSelect(content.groupId)}
            />
          )}
          {isShrink && (
            <CheckCircle
              sx={{ position: 'absolute', top: 24, left: 16, zIndex: 5, background: 'white', borderRadius: '50%' }}
              color={'primary'}
              onClick={() => onUnSelect(content.groupId)}
            />
          )}

          {!isShrink && (
            <IconButton
              size={'small'}
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 5,
                color: 'white'
              }}
              onClick={() => {
                setIsFavorite(!isFavorite)
                if (!isFavorite) {
                  onFavorite?.()
                } else {
                  onUnfavorite?.()
                }
              }}
            >
              {isFavorite ? <Favorite /> : <FavoriteBorder />}
            </IconButton>
          )}

          {isVideoAsset ? (
            <Video src={firstMedia?.url} width={'100%'} height={'100%'} />
          ) : firstMedia?.url ? (
            <img
              src={firstMedia?.url}
              alt={'Creator content image'}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
          ) : (
            <NoPreview height={'100%'} />
          )}
          {!isShrink &&
            SocialChannelToIcon[content.channel]?.({
              sx: {
                position: 'absolute',
                bottom: 10,
                left: 10,
                zIndex: 5,
                backgroundColor: 'white',
                borderRadius: '50%',
                p: 0.5
              }
            })}
          {!!content.platformContentLink && !isShrink && (
            <IconButton
              size={'small'}
              href={content.platformContentLink}
              sx={{
                position: 'absolute',
                bottom: 10,
                right: 40,
                zIndex: 5,
                color: 'white'
              }}
            >
              <Launch />
            </IconButton>
          )}
          {!isShrink && (
            <IconButton
              size={'small'}
              sx={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                zIndex: 5,
                color: 'white'
              }}
              component={Link}
              target={'_blank'}
              onClick={onExpand}
            >
              <CropFree />
            </IconButton>
          )}
        </Box>
        <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
          {ContentTypeToString[content.contentType]} {'\u2022'} Submitted on{' '}
          {DateTime.fromISO(content.submittedAt).toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
        {!!impressions && (
          <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
            {impressions.toLocaleString()} impressions
          </Typography>
        )}
      </Stack>
    </Paper>
  )
}

export default ContentCard

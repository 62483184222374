import { Stack } from '@mui/system'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Summary } from '@momentum/routes/campaign/content/Summary'
import { Paper } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { sumBy, orderBy } from 'lodash'
import { Channel } from '@productwindtom/shared-momentum-zeus-types'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { UpcomingState } from './UpcomingState'
import { getHandle } from '@momentum/utils/socialUtils'
import { ContentFilterData, ContentSortBy } from '@momentum/sections/content/types'
import ContentProvider from '@momentum/sections/content/context'
import { CampaignStatus } from '@productwindtom/shared-campaign'
import { NotSupportedState } from './NotSupportedState'

export const CampaignContent = () => {
  const { isAdminView } = useUserSessionContext()
  const { campaignDetails, activeStatus, updateContentGroup } = useCampaignContext()

  const content = useMemo(() => {
    return campaignDetails.creators
      .map(({ content, creator, ...rest }) =>
        content.map(o => ({
          ...o,
          ...creator,
          ...rest,
          creatorId: creator.id,
          qualityScore: sumBy(o.content, 'qualityScore'),
          handle: getHandle(o.channel, creator),
          engagements: sumBy(o.content, 'engagements'),
          comments: sumBy(o.content, 'comments'),
          views: sumBy(o.content, 'views'),
          likes: sumBy(o.content, 'likes')
        }))
      )
      .flat()
  }, [campaignDetails.creators])

  const handleFilter = useCallback(
    (data: ContentFilterData) => {
      const { search = '', isFavorite, sortBy, excludedContentTypes = [] } = data || {}

      const lowerSearch = search.toLowerCase().trim()

      const filteredContent = content.filter(
        c =>
          (isAdminView || !c.isHidden) &&
          (!isFavorite || c.isFavorite) &&
          (!excludedContentTypes.length || !excludedContentTypes.includes(c.contentType)) &&
          (!lowerSearch ||
            c?.content?.some(o => o.caption?.toLowerCase().includes(lowerSearch)) ||
            c.handle?.toLowerCase().includes(lowerSearch) ||
            c.email.toLowerCase().includes(lowerSearch) ||
            c.firstName.toLowerCase().includes(lowerSearch) ||
            c.lastName.toLowerCase().includes(lowerSearch))
      )

      switch (sortBy) {
        case ContentSortBy.DATE:
          return filteredContent.sort((a, b) => new Date(b.submittedAt).getTime() - new Date(a.submittedAt).getTime())
        case ContentSortBy.RELEVANCE:
          return orderBy(
            filteredContent,
            [
              a => (a.channel === Channel.UGC ? 0 : 1),
              a => (a.channel === Channel.UGC ? a.qualityScore : a.views || 0)
            ],
            ['desc', 'desc']
          )
        default:
          return filteredContent
      }
    },
    [isAdminView, content]
  )

  const isSupported =
    !!campaignDetails.numUgcCreators || !!campaignDetails.numSocialCreators || !!campaignDetails.numPremiumUgcCreators
  const isUpcoming = activeStatus === CampaignStatus.UPCOMING || !content.length

  if (!isSupported) {
    return (
      <Stack spacing={3}>
        <NotSupportedState />
      </Stack>
    )
  }

  return (
    <Stack spacing={3}>
      {!isUpcoming && (
        <Paper sx={{ px: 4, py: 2 }}>
          <Summary campaignContent={content} />
        </Paper>
      )}
      {isUpcoming ? (
        <UpcomingState />
      ) : (
        <ContentProvider onFilterChange={handleFilter} content={content} updateContentGroup={updateContentGroup} />
      )}
    </Stack>
  )
}

import { GridSortModel } from '@mui/x-data-grid-premium'
import { RecommendationType, Store } from '@productwindtom/shared-momentum-zeus-types'

export type RecommendationsFormData = {
  companyIds: string[]
  brandIds: string[]
  retailers: Store
  recommendationTypes: RecommendationType[] & 'ARCHIVED'
  search: string
  sortBy: GridSortModel
}

export enum RecommendationFieldNames {
  COMPANY = 'company',
  BRAND = 'brand',
  IMAGE = 'image',
  NAME = 'name',
  STORE = 'store',
  PRICE = 'priceCents',
  AVAILABILITY_DATE = 'availabilityDate',
  NUMRATINGS = 'numRatings',
  RATING = 'rating',
  RECOMMENDATION_TYPE = 'recommendationType',
  LAST_SCRAPED_AT = 'lastScrapedAt'
}

import PackageIcon from '@momentum/components/icons/package'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'

export const ProductsSummary = () => {
  const { products = [] } = useBrandContext()

  const { parentCount, childCount } = useMemo(() => {
    const parentCount = products.filter(p => !p.parentSkuId).length
    const childCount = products.filter(p => p.parentSkuId).length
    return { parentCount, childCount }
  }, [products])

  return (
    <Stack direction={'row'} mt={1} spacing={2}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <ListAltIcon />
        <Typography variant={'label3'}>{parentCount} listings</Typography>
      </Stack>

      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <PackageIcon />
        <Typography variant={'label3'}>{childCount} products</Typography>
      </Stack>
    </Stack>
  )
}

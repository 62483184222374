import Row from '@momentum/components/row'
import { Button, Stack, Typography } from '@mui/material'
import { NumberInput, SelectInput, SubmitButton } from '@productwindtom/ui-base'
import { useWatch } from 'react-hook-form'
import { BENCHMARK_TYPE_TO_GOAL_STRING, METRIC_TYPE_TO_INPUT_STRING } from '../constants'
import { useBenchmarkContext } from '../context/BenchmarkContext'
import { BenchmarkType } from '@productwindtom/shared-momentum-zeus-types'

const BenchmarkMetrics = () => {
  const { handleResetMetrics, handleApplyMetrics } = useBenchmarkContext()
  const metricType = useWatch({ name: 'metricType' }) as BenchmarkType

  return (
    <Stack spacing={3}>
      <Typography variant="label1">Choose comparison metric and launch goal</Typography>
      <Row spacing={3}>
        <SelectInput
          primaryText="Select comparison metric"
          name="metricType"
          disableClearable
          options={[BenchmarkType.TRAFFIC, BenchmarkType.UNITS_SOLD, BenchmarkType.REVENUE].map(goalType => ({
            label: METRIC_TYPE_TO_INPUT_STRING[goalType],
            value: goalType
          }))}
        />
        <NumberInput
          primaryText="Choose launch goal"
          name="metricGoal"
          returnAsNumber
          min={0}
        />
        {metricType && (
          <Typography
            sx={{
              position: 'relative',
              top: 14,
              right: 8
            }}
            variant="label3"
          >
            {BENCHMARK_TYPE_TO_GOAL_STRING[metricType]}
          </Typography>
        )}
      </Row>
      <Row spacing={1}>
        <SubmitButton color="primary" variant="contained" onClick={handleApplyMetrics}>
          Apply
        </SubmitButton>
        <Button type="button" variant="text" onClick={handleResetMetrics}>
          Reset
        </Button>
      </Row>
    </Stack>
  )
}

export default BenchmarkMetrics

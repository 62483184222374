import {
  ProposalGoal,
  Store,
  CampaignCreatorStatus,
  Channel,
  ContentType,
  CreatorType
} from '@productwindtom/shared-momentum-zeus-types'
import { CampaignDetails } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST: CampaignDetails = {
  id: 'sample-campaign-outdoor-camera-boost',
  createdAt: '2024-05-16T20:42:52.440Z',
  skuId: 'sample-sku-outdoor-camera',

  title: 'Outdoor Security Camera - Boost Star Rating',
  goal: ProposalGoal.PRODUCT_LAUNCH,
  numCreators: 800,
  numCreatorsJoined: 815,

  numBrandAdvocateCreators: 0,
  numSocialCreators: 0,
  numPremiumUgcCreators: 0,
  numUgcCreators: 800,

  expectedUgcPostCount: 400,
  expectedReviewsCount: 400,
  expectedPlacementWins: 10,

  releaseSchedule: {
    ugcWeeklySchedule: [395, 260, 13, 12, 30, 113],
    premiumUgcWeeklySchedule: [],
    socialWeeklySchedule: [],
    brandAdvocatesWeeklySchedule: []
  },
  anticipatedStartDate: '2024-05-23T04:00:00.000Z',
  startDate: '2024-05-22T15:39:27.920Z',

  endDate: '2024-09-11T20:38:58.575Z',
  cost: 7999200,
  product: {
    id: '6c49645c-ef93-48ba-a1ae-aab6e9c80244',
    name: 'Outdoor Camera',
    skuId: 'OCAMERA',
    priceCents: 14999,
    image: 'https://media.productwind.com/sample/images/sample-sku-outdoor-camera.png',
    listingLink: 'https://www.amazon.com',
    store: Store.amazon,
    availabilityDate: '2023-09-19T00:00:00.000Z'
  },
  creators: [
    {
      userCampaignId: '7be0898a-df70-441c-8668-1a840db75e03',
      content: [
        {
          groupId: 'bfaceaaa-fb55-4d78-958c-a112e98f391c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:19:08.698Z',

          createdAt: '2024-05-28T18:41:25.518Z',
          content: [
            {
              caption: '',

              id: '9880baca-b2e5-4af7-9909-848c1ebce93b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7be0898a-df70-441c-8668-1a840db75e03/ugc/bfaceaaa-fb55-4d78-958c-a112e98f391c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '615518fe-06eb-443a-ab83-f81f66a33b3a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7be0898a-df70-441c-8668-1a840db75e03/ugc/bfaceaaa-fb55-4d78-958c-a112e98f391c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3e66cddf-05fe-455e-ab20-2b5f4ab543e2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7be0898a-df70-441c-8668-1a840db75e03/ugc/bfaceaaa-fb55-4d78-958c-a112e98f391c/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5ed6496e-e3eb-4c4c-9272-6101b38d6f86',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T13:55:00.661Z',

          createdAt: '2024-05-28T18:42:09.253Z',
          content: [
            {
              caption: '',

              id: '4ea2b929-2248-44c1-b959-b490ce3eb128',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7be0898a-df70-441c-8668-1a840db75e03/ugc/5ed6496e-e3eb-4c4c-9272-6101b38d6f86/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1e2daacf-108f-413d-aa4d-247fd6657588',
        firstName: 'Randi',
        lastName: 'McCoy',
        email: 'rrmccoy19@gmail.com',
        instagramProfile: {
          handle: 'randi.mccoy19',
          followers: 5271,
          engagementRate: 0.2181749193701385
        },
        tiktokProfile: {
          handle: 'randi.mccoy19',
          followers: 10786,
          engagementRate: 12.989684644857057
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-23T16:00:00.000Z',
        amountCents: 7594
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b1f809ad-64d3-479d-a1a7-9509b0872dd3',
      content: [],
      creator: {
        id: '4b922934-854c-4a90-a1dd-af7249893a5c',
        firstName: 'Blanca',
        lastName: 'Vera',
        email: 'blanca.m.vera@gmail.com',
        instagramProfile: {
          handle: 'veraglitzandglam',
          followers: 79,
          engagementRate: 30.69620253164557
        },
        tiktokProfile: {
          handle: 'blanca_vera12',
          followers: 2981,
          engagementRate: 7.5779662514356385
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-23T23:59:34.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cae929b0-73be-49b5-b6cd-44cdc678dadc',
      content: [
        {
          groupId: '56ec0e07-e7d3-4204-b92a-8bdaf091dc23',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-27T18:46:37.695Z',

          createdAt: '2024-05-24T13:20:37.264Z',
          content: [
            {
              caption: '',

              id: '74ad7d81-1039-4434-8bdd-d8ee6a98c366',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cae929b0-73be-49b5-b6cd-44cdc678dadc/ugc/56ec0e07-e7d3-4204-b92a-8bdaf091dc23/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '8a8d4e65-34f0-4f61-ac65-e8df2ad0a5d7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-27T18:46:43.417Z',

          createdAt: '2024-05-24T13:21:40.257Z',
          content: [
            {
              caption: '',

              id: '1f35b15a-2ce3-49c7-a568-e68e68995917',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cae929b0-73be-49b5-b6cd-44cdc678dadc/ugc/8a8d4e65-34f0-4f61-ac65-e8df2ad0a5d7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e2e4b1fc-5158-4552-bba5-e4eea9a119b2',
        firstName: 'Carolyn',
        lastName: 'Chimon',
        email: 'callyc86@gmail.com',
        instagramProfile: {
          handle: 'lemmeflipthat',
          followers: 2311,
          engagementRate: 0.0410609857978279
        },
        tiktokProfile: {
          handle: '__carolync__',
          followers: 284
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-23T23:59:35.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a216f34b-4186-4126-b31e-de2767fd151f',
      content: [
        {
          groupId: '63678912-3e27-42ff-bcc0-b4fc5786ca7b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T11:59:17.413Z',

          createdAt: '2024-06-06T16:22:23.333Z',
          content: [
            {
              caption: '',

              id: '97f43be0-552d-48d2-a4e3-b4b74f88ac94',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a216f34b-4186-4126-b31e-de2767fd151f/ugc/63678912-3e27-42ff-bcc0-b4fc5786ca7b/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '41839cac-dc71-468e-85a7-b00897848da6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a216f34b-4186-4126-b31e-de2767fd151f/ugc/63678912-3e27-42ff-bcc0-b4fc5786ca7b/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '53da2fc8-fc23-428f-b320-aea82b36e551',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a216f34b-4186-4126-b31e-de2767fd151f/ugc/63678912-3e27-42ff-bcc0-b4fc5786ca7b/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bac7db73-c8ce-4b2b-be3d-63d0814d4b34',
        firstName: 'Amanda',
        lastName: 'Ham',
        email: 'maeamanda95@gmail.com',
        instagramProfile: {
          handle: 'mandyyymae95',
          followers: 237,
          engagementRate: 0.8016877637130801
        },
        tiktokProfile: {
          handle: 'mayhem.minded',
          followers: 1041,
          engagementRate: 15.577889447236181
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:01:02.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e092f62e-88c5-402e-ab3c-8438d552e403',
      content: [
        {
          groupId: '4536ad23-b0c1-4ae2-b6bc-c8a5a501d401',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-01T18:32:49.734Z',

          createdAt: '2024-07-29T12:22:06.866Z',
          content: [
            {
              caption: '',

              id: '05dea384-9c20-4bd6-afc4-0962bb282ec0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e092f62e-88c5-402e-ab3c-8438d552e403/ugc/4536ad23-b0c1-4ae2-b6bc-c8a5a501d401/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '48071932-4507-4963-9ef8-a2efc0a3202d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e092f62e-88c5-402e-ab3c-8438d552e403/ugc/4536ad23-b0c1-4ae2-b6bc-c8a5a501d401/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '22efd4c6-fd8a-45bd-8bd8-fef17c6a61e7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e092f62e-88c5-402e-ab3c-8438d552e403/ugc/4536ad23-b0c1-4ae2-b6bc-c8a5a501d401/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3239a0c6-d112-4cfa-9c6c-967e727ae333',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-01T18:32:53.572Z',

          createdAt: '2024-07-29T18:08:22.162Z',
          content: [
            {
              caption: '',

              id: '433be69c-4acb-4500-a57b-7cc9bff8be07',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e092f62e-88c5-402e-ab3c-8438d552e403/ugc/3239a0c6-d112-4cfa-9c6c-967e727ae333/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '88d572a9-500e-44fe-89b0-13e6cbe3c515',
        firstName: 'Tiffany',
        lastName: 'Humphrey',
        email: 'thumphrey1988@gmail.com',
        instagramProfile: {
          handle: 'lifewithtiffanyrenee',
          followers: 4698,
          engagementRate: 0.3937845891868881
        },
        tiktokProfile: {
          handle: 'adventureswithtiff',
          followers: 174,
          engagementRate: 5.284552845528456
        },
        youtubeProfile: {
          handle: 'allthingspositiff'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:02:29.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cfc24c06-f57a-4132-ba90-cd00f97bdc93',
      content: [
        {
          groupId: '44a627f8-7374-4df7-a5d5-04f723c5aa39',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-11T03:26:32.624Z',

          createdAt: '2024-05-31T00:44:30.589Z',
          content: [
            {
              caption: '',

              id: 'bf4d19be-9247-4839-bf90-f403c7d394e3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cfc24c06-f57a-4132-ba90-cd00f97bdc93/ugc/44a627f8-7374-4df7-a5d5-04f723c5aa39/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '0ca7aae9-93ad-4ef7-8d54-3444eb9d74ee',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T11:09:42.271Z',

          createdAt: '2024-05-31T00:51:37.641Z',
          content: [
            {
              caption: '',

              id: 'd45b735f-fb3a-4128-94e2-8304469ef7d8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cfc24c06-f57a-4132-ba90-cd00f97bdc93/ugc/0ca7aae9-93ad-4ef7-8d54-3444eb9d74ee/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6319ed53-d12f-49e4-b68c-3eb6f16b7309',
        firstName: 'Christian',
        lastName: 'Gonzalez',
        email: 'info@dadlifegaming.com',
        instagramProfile: {
          handle: 'dad.life.gaming',
          followers: 2893,
          engagementRate: 2.305565157276184
        },
        tiktokProfile: {
          handle: 'dad.life.gaming',
          followers: 568,
          engagementRate: 3.341584158415842
        },
        youtubeProfile: {
          handle: 'dadlifegaming'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:05:38.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e0691eea-fbc4-40f6-a71b-188480239146',
      content: [
        {
          groupId: 'cdc0d7f4-afc4-4985-8efb-c42114355ae0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T15:47:16.253Z',

          createdAt: '2024-05-27T18:21:21.727Z',
          content: [
            {
              caption: '',

              id: 'd913e0da-f0a0-4895-9111-4514b93751d9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0691eea-fbc4-40f6-a71b-188480239146/ugc/cdc0d7f4-afc4-4985-8efb-c42114355ae0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'dfcb8e62-880b-4ab8-9448-80e02a2244ab',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T15:47:07.399Z',

          createdAt: '2024-05-27T23:25:21.544Z',
          content: [
            {
              caption: '',

              id: '65226d79-a2ba-45bd-b963-fac127c72cba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0691eea-fbc4-40f6-a71b-188480239146/ugc/dfcb8e62-880b-4ab8-9448-80e02a2244ab/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b0a81006-28c1-4e2b-8b33-42140c579f3a',
        firstName: 'Dareo',
        lastName: 'Cunningham',
        email: 'dareocad@gmail.com',

        tiktokProfile: {
          handle: 'dareo112211',
          followers: 1443,
          engagementRate: 2.5974025974025974
        },
        youtubeProfile: {
          handle: 'dareoc.',
          followers: 40
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:11:58.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '691b2fae-545b-4de5-9d08-a516d349ec55',
      content: [],
      creator: {
        id: '8de3cc45-e20f-4dbe-8f79-63b82c7ebe4d',
        firstName: 'Velvet',
        lastName: 'Fuller',
        email: 'veetasaves@gmail.com',
        instagramProfile: {
          handle: 'vluxeoutfitters',
          followers: 101,
          engagementRate: 15.346534653465346
        },
        tiktokProfile: {
          handle: 'veetamac',
          followers: 3361,
          engagementRate: 7.17610520159952
        },
        youtubeProfile: {
          handle: 'veetamac',
          followers: 392
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:15:10.000Z',
        amountCents: 7632
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '50cfa84f-f480-4104-814f-7a164b7ed956',
      content: [
        {
          groupId: 'aac9d5bd-ba0d-48cb-902e-8665857fa8c0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T04:34:22.919Z',

          createdAt: '2024-05-30T03:32:22.958Z',
          content: [
            {
              caption: '',

              id: '3cd45f90-f299-4757-ad4e-c9c33fe53621',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50cfa84f-f480-4104-814f-7a164b7ed956/ugc/aac9d5bd-ba0d-48cb-902e-8665857fa8c0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7f3eea4d-746b-450b-a7f9-d1677093ba2a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T05:03:43.722Z',

          createdAt: '2024-05-30T21:44:20.415Z',
          content: [
            {
              caption: '',

              id: '593dbe58-be91-464e-abed-4956267754a1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50cfa84f-f480-4104-814f-7a164b7ed956/ugc/7f3eea4d-746b-450b-a7f9-d1677093ba2a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '67c42175-92dc-4158-a6af-c52ae057d3c1',
        firstName: 'Elisa ',
        lastName: 'Yi',
        email: 'enlightenedelisa1@gmail.com',
        instagramProfile: {
          handle: 'enlightened.elisa',
          followers: 35440,
          engagementRate: 2.419018058690745
        },
        tiktokProfile: {
          handle: 'enlightenedelisa',
          followers: 5195
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:15:50.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ab3a8522-d873-48dc-8b20-ab9e913242f3',
      content: [
        {
          groupId: '4e0ae16e-4f5c-4f93-8160-02bfb1585877',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T19:59:05.566Z',

          createdAt: '2024-05-27T22:47:20.928Z',
          content: [
            {
              caption: '',

              id: '479cc687-ab9b-4bbc-8f0b-9c6f8c10ca58',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab3a8522-d873-48dc-8b20-ab9e913242f3/ugc/4e0ae16e-4f5c-4f93-8160-02bfb1585877/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b80f515b-8a1f-4fa3-9a22-dcaf3aa4a38b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:40:57.119Z',

          createdAt: '2024-05-27T22:51:53.331Z',
          content: [
            {
              caption: '',

              id: 'c7d76c37-ee45-4b1d-affa-2ad3e8a9b9e7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab3a8522-d873-48dc-8b20-ab9e913242f3/ugc/b80f515b-8a1f-4fa3-9a22-dcaf3aa4a38b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8b85efad-8b2b-4e71-b12e-46fc9dcdc4bf',
        firstName: 'Diana',
        lastName: 'Ochoa',
        email: 'hazeleyez408@gmail.com',
        instagramProfile: {
          handle: 'hazeleyez123456',
          followers: 1859,
          engagementRate: 2.1194190424959656
        },
        tiktokProfile: {
          handle: 'hazeleyezbeauty',
          followers: 1693,
          engagementRate: 1.0503414390406396
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:17:07.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '223bf03a-1d2a-494f-8919-726e60d1bdca',
      content: [
        {
          groupId: '2986946c-c4c9-4938-89d5-fc0dfe7c29a6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T15:33:37.353Z',

          createdAt: '2024-05-27T20:10:39.161Z',
          content: [
            {
              caption: '',

              id: 'f07acdf7-3eb4-43cc-accf-6c8b89eab368',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/223bf03a-1d2a-494f-8919-726e60d1bdca/ugc/2986946c-c4c9-4938-89d5-fc0dfe7c29a6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2803f6a2-f3b2-41de-b843-e1b82c43d1c9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T15:33:32.722Z',

          createdAt: '2024-05-27T20:10:17.018Z',
          content: [
            {
              caption: '',

              id: 'f9e4a47f-49b6-4332-bb1b-c52b4ebc08ad',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/223bf03a-1d2a-494f-8919-726e60d1bdca/ugc/2803f6a2-f3b2-41de-b843-e1b82c43d1c9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4aec4337-fd4c-4dd0-bd4b-5d98758dbdaa',
        firstName: 'Fabiola',
        lastName: 'Swenson',
        email: 'lissetswenson0811@gmail.com',
        instagramProfile: {
          handle: 'fabslisset',
          followers: 531,
          engagementRate: 2.354048964218456
        },
        tiktokProfile: {
          handle: 'lissetswenson0811',
          followers: 7979,
          engagementRate: 7.093067750509344
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:22:39.000Z',
        amountCents: 7515
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eb775490-f33d-4fb9-8802-202e880702ef',
      content: [],
      creator: {
        id: '78523490-7d34-49a1-be15-bd032633ff18',
        firstName: 'Laquita',
        lastName: 'Wylie',
        email: 'laquitabuggs@gmail.com',
        instagramProfile: {
          handle: 'she2damnsidity',
          followers: 3209,
          engagementRate: 0.25553131816765345
        },
        tiktokProfile: {
          handle: 'mrswylieeeee',
          followers: 53,
          engagementRate: 1.7489255316160566
        },
        youtubeProfile: {
          handle: 'laquitabuggs1187',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:22:42.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd88ff27e-4b9e-4e4d-b35b-7a83c3bdd32a',
      content: [
        {
          groupId: '34b7cb79-52ae-42cd-bfac-8a3e2a830787',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T08:46:17.774Z',

          createdAt: '2024-05-27T23:40:55.897Z',
          content: [
            {
              caption: '',

              id: 'f7fd71d2-281d-4ab1-b43d-31f2c2356d4d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d88ff27e-4b9e-4e4d-b35b-7a83c3bdd32a/ugc/34b7cb79-52ae-42cd-bfac-8a3e2a830787/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '67371ad1-411f-4e99-9915-807ba5812b7a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d88ff27e-4b9e-4e4d-b35b-7a83c3bdd32a/ugc/34b7cb79-52ae-42cd-bfac-8a3e2a830787/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a40565b8-6077-4960-ba36-b56dd2ad6f08',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T10:04:25.631Z',

          createdAt: '2024-06-10T00:58:33.187Z',
          content: [
            {
              caption: '',

              id: '5087be57-7e1b-4d96-8cc9-54a13c784023',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d88ff27e-4b9e-4e4d-b35b-7a83c3bdd32a/ugc/a40565b8-6077-4960-ba36-b56dd2ad6f08/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4da5385c-25cd-4cd1-a851-f9190a042e78',
        firstName: 'Mandi',
        lastName: 'Gellis',
        email: 'mandielyse@gmail.com',
        instagramProfile: {
          handle: 'itsmandielyse',
          followers: 3261,
          engagementRate: 0.3925176326280282
        },
        tiktokProfile: {
          handle: 'itsmandielyse',
          followers: 138,
          engagementRate: 0.8714596949891068
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:23:19.000Z',
        amountCents: 7603
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8ea56529-ecbc-4999-9069-fe7d0a0f0988',
      content: [
        {
          groupId: 'f0b4a8b6-8169-4eb6-b4f0-487b922119a2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T17:09:01.361Z',

          createdAt: '2024-06-11T00:57:25.599Z',
          content: [
            {
              caption: '',

              id: 'fa1da394-3e2b-48bf-97a5-d499ac6ceea2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8ea56529-ecbc-4999-9069-fe7d0a0f0988/ugc/f0b4a8b6-8169-4eb6-b4f0-487b922119a2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e62f2744-b0c8-4f0a-a23a-248b9b495a0b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T17:08:54.201Z',

          createdAt: '2024-06-11T04:33:51.559Z',
          content: [
            {
              caption: '',

              id: '2489bcf5-8940-40f5-a5ab-40d92451f1c8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8ea56529-ecbc-4999-9069-fe7d0a0f0988/ugc/e62f2744-b0c8-4f0a-a23a-248b9b495a0b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0d106772-6fab-4e11-aebf-8173d05554e5',
        firstName: 'Crystal',
        lastName: 'McFarlane',
        email: 'Mcfarlanejody75@gmail.com',
        instagramProfile: {
          handle: 'exquisite__nails_by_jody',
          followers: 88,
          engagementRate: 3.0681818181818183
        },
        tiktokProfile: {
          handle: 'crystal_jojo',
          followers: 1153,
          engagementRate: 5.505496514251856
        },
        youtubeProfile: {
          handle: 'crystalmcfarlane9556',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:31:02.000Z',
        amountCents: 7443
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c06a016b-8c93-4391-bd93-005c857eeb1b',
      content: [
        {
          groupId: 'b92f7ca6-c661-4aa1-9460-9e8a6f651980',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T06:54:46.394Z',

          createdAt: '2024-06-06T03:48:28.735Z',
          content: [
            {
              caption: '',

              id: '2f1fb9d8-be7e-448c-8fd7-f5253b6004c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c06a016b-8c93-4391-bd93-005c857eeb1b/ugc/b92f7ca6-c661-4aa1-9460-9e8a6f651980/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '33b066f4-ee84-42eb-a04c-f1dcd2743b04',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T06:55:04.357Z',

          createdAt: '2024-06-06T03:49:57.429Z',
          content: [
            {
              caption: '',

              id: '512cf4d7-3eb6-44c1-b4cf-c74de7323d4c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c06a016b-8c93-4391-bd93-005c857eeb1b/ugc/33b066f4-ee84-42eb-a04c-f1dcd2743b04/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'fbafc201-d17d-4ce3-b42c-9b20da8b07e5',
        firstName: 'Sherica',
        lastName: 'Nicholas',
        email: 'sherican425@gmail.com',

        tiktokProfile: {
          handle: 'sosweet4_u_backup_page',
          followers: 20523
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:31:04.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e931a160-3808-4188-af40-1f6228869cf6',
      content: [],
      creator: {
        id: '257d93d2-2c83-427d-a92c-8aaf499e4b7d',
        firstName: 'Notoya ',
        lastName: 'McFarlane',
        email: 'natoyamcfarlane69@gmail.com',
        instagramProfile: {
          handle: 'toya_house_of_good_looks',
          followers: 360,
          engagementRate: 3.0470914127423825
        },
        tiktokProfile: {
          handle: 'babytoya27',
          followers: 5132,
          engagementRate: 14.158919122950705
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:41:21.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '50bb0824-5c1c-4e1c-8657-1e03ec5569ea',
      content: [
        {
          groupId: '9117d6d4-4e79-41ed-9ced-33c46a4dcdad',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T14:49:36.651Z',

          createdAt: '2024-05-27T18:43:54.953Z',
          content: [
            {
              caption: '',

              id: 'b518b995-dfdf-48fe-bd88-2bf6ef2c1c20',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50bb0824-5c1c-4e1c-8657-1e03ec5569ea/ugc/9117d6d4-4e79-41ed-9ced-33c46a4dcdad/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '4681792a-229a-43b2-b1ff-1627036da449',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T14:49:31.323Z',

          createdAt: '2024-05-27T18:33:47.953Z',
          content: [
            {
              caption: '',

              id: '6ec827be-6906-4806-8b78-3e964486471a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50bb0824-5c1c-4e1c-8657-1e03ec5569ea/ugc/4681792a-229a-43b2-b1ff-1627036da449/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '07704848-c722-4d2e-9454-bf0d3599677f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50bb0824-5c1c-4e1c-8657-1e03ec5569ea/ugc/4681792a-229a-43b2-b1ff-1627036da449/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '51d2daab-4521-4060-89fc-24cfb8b14574',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50bb0824-5c1c-4e1c-8657-1e03ec5569ea/ugc/4681792a-229a-43b2-b1ff-1627036da449/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '397ffd47-9fe3-4bc5-9cf9-dbe1c6064405',
        firstName: 'Ciayra',
        lastName: 'Ray',
        email: 'thiswaythenthat@gmail.com',
        instagramProfile: {
          handle: 'ciayramcilwain',
          followers: 17712,
          engagementRate: 1.9901761517615177
        },
        tiktokProfile: {
          handle: 'ciayramcilwain',
          followers: 3179,
          engagementRate: 7.267761694822676
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:43:36.000Z',
        amountCents: 7349
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c06fec7b-b803-4ee5-b0a9-b91a0b197073',
      content: [
        {
          groupId: 'a2817787-45a6-4d66-872a-416eecfd993f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-27T11:39:52.606Z',

          createdAt: '2024-05-24T22:16:41.445Z',
          content: [
            {
              caption: '',

              id: 'e917bcbd-0f8e-443a-ac58-b8a4fd0291ae',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c06fec7b-b803-4ee5-b0a9-b91a0b197073/ugc/a2817787-45a6-4d66-872a-416eecfd993f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e06a7157-2891-4906-bc17-73034604181d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-27T13:36:53.323Z',

          createdAt: '2024-05-25T02:39:14.271Z',
          content: [
            {
              caption: '',

              id: '20fbbc42-ace5-4a57-9fb8-f65b7dad1dec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c06fec7b-b803-4ee5-b0a9-b91a0b197073/ugc/e06a7157-2891-4906-bc17-73034604181d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bfec790c-a3f3-4d5f-9c1b-2d5045c5bdb6',
        firstName: 'Annie ',
        lastName: 'Manning',
        email: 'anniemanning777@gmail.com',

        tiktokProfile: {
          handle: 'annieamazonian',
          followers: 0,
          engagementRate: 1.4150943396226416
        },
        youtubeProfile: {
          handle: 'tayloranne'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T00:52:41.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4a006435-3cef-4071-acde-7cc4f7182bbb',
      content: [
        {
          groupId: 'bd63ec67-6c63-40bc-aa1e-a00e8c8af08f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-19T12:16:13.539Z',

          createdAt: '2024-08-15T19:48:01.655Z',
          content: [
            {
              caption: '',

              id: '9a7af355-3661-4036-a8f5-774eddc82301',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4a006435-3cef-4071-acde-7cc4f7182bbb/ugc/bd63ec67-6c63-40bc-aa1e-a00e8c8af08f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'eee43ba2-56ec-4712-aed3-4341f9b1f564',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4a006435-3cef-4071-acde-7cc4f7182bbb/ugc/bd63ec67-6c63-40bc-aa1e-a00e8c8af08f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '04a6c52f-f265-457c-bcd2-33f1cf192ba9',
        firstName: 'Kerisia',
        lastName: 'Carter',
        email: 'kerisiacarter89@gmail.com',
        instagramProfile: {
          handle: '____1.don.sher____',
          followers: 2215
        },
        tiktokProfile: {
          handle: 'sher_don',
          followers: 677,
          engagementRate: 12.82051282051282
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T01:15:33.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6b3e726c-0e00-4b20-b32c-2b79ea99090a',
      content: [
        {
          groupId: '94672f81-7f06-4b20-92de-b6d09f69bfef',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T02:07:31.972Z',

          createdAt: '2024-06-04T18:21:01.970Z',
          content: [
            {
              caption: '',

              id: '0ae76187-940d-4efa-8538-393144bd9e8a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6b3e726c-0e00-4b20-b32c-2b79ea99090a/ugc/94672f81-7f06-4b20-92de-b6d09f69bfef/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '581d96e4-3f58-4c15-a7d0-100a5512bf99',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T17:48:49.030Z',

          createdAt: '2024-06-04T17:30:17.980Z',
          content: [
            {
              caption: '',

              id: '32aae7a4-a7c8-48b0-a1d0-7596dd343310',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6b3e726c-0e00-4b20-b32c-2b79ea99090a/ugc/581d96e4-3f58-4c15-a7d0-100a5512bf99/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fb00adcc-539c-425f-ab6a-8956b4525155',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6b3e726c-0e00-4b20-b32c-2b79ea99090a/ugc/581d96e4-3f58-4c15-a7d0-100a5512bf99/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e8ab3498-2a77-4f10-b89d-505600088a4c',
        firstName: 'Kimberly',
        lastName: 'Brooks',
        email: 'brookskimberly197@gmail.com',
        instagramProfile: {
          handle: 'boss_lady_kay_',
          followers: 937,
          engagementRate: 1.814300960512273
        },
        tiktokProfile: {
          handle: 'aliciabrooks0',
          followers: 3625,
          engagementRate: 12.787723785166241
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T01:23:15.000Z',
        amountCents: 8531
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3d82282f-656f-4f64-8931-4cbd6425f649',
      content: [],
      creator: {
        id: '482d5437-5831-4c42-a7ce-3a9ef3b78634',
        firstName: 'Tay',
        lastName: 'Shaw',
        email: 'shuntavianchism@yahoo.com',
        instagramProfile: {
          handle: 'taymarleytv',
          followers: 2183,
          engagementRate: 0.6596426935409986
        },
        tiktokProfile: {
          handle: 'taymarleytv',
          followers: 3344,
          engagementRate: 2.2568198479762316
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T01:33:08.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'db0f4b27-7121-49d4-80ab-37a5cfc1459a',
      content: [
        {
          groupId: '44b46411-0483-492b-9ca4-abdd73ffb63c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-13T11:19:12.491Z',

          createdAt: '2024-07-21T19:24:50.503Z',
          content: [
            {
              caption: '',

              id: '7c5d6398-6168-4197-91d5-6e20c7198447',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/db0f4b27-7121-49d4-80ab-37a5cfc1459a/ugc/44b46411-0483-492b-9ca4-abdd73ffb63c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cef62175-442c-4680-8a97-45a90325816d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-13T11:19:05.275Z',

          createdAt: '2024-07-21T19:28:06.823Z',
          content: [
            {
              caption: '',

              id: '1b4374e7-2255-428c-b615-aaa54b112a4a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/db0f4b27-7121-49d4-80ab-37a5cfc1459a/ugc/cef62175-442c-4680-8a97-45a90325816d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c67803e6-c9e0-4f2e-b1ff-c235d61272cb',
        firstName: 'Dandra',
        lastName: 'Grant',
        email: 'dandragrant@yahoo.com',
        instagramProfile: {
          handle: 'shantal_nov7',
          followers: 14736,
          engagementRate: 0.8346905537459284
        },
        tiktokProfile: {
          handle: 'shan_180',
          followers: 774,
          engagementRate: 7.731958762886598
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T01:57:30.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ab8b9991-19a4-491d-870e-802ee66eab16',
      content: [
        {
          groupId: 'b6e0abb2-c1f2-4b86-86b5-26f7d5aa26b7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T21:19:12.793Z',

          createdAt: '2024-05-28T07:02:40.581Z',
          content: [
            {
              caption: '',

              id: 'b00a8504-c5c5-4192-b61a-503f923abce3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab8b9991-19a4-491d-870e-802ee66eab16/ugc/b6e0abb2-c1f2-4b86-86b5-26f7d5aa26b7/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '80fa08a3-fb3f-47ff-8c51-f454e2aa9d30',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab8b9991-19a4-491d-870e-802ee66eab16/ugc/b6e0abb2-c1f2-4b86-86b5-26f7d5aa26b7/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '983c2452-64b8-43f9-a7cf-046f0197e81d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab8b9991-19a4-491d-870e-802ee66eab16/ugc/b6e0abb2-c1f2-4b86-86b5-26f7d5aa26b7/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '642a9575-5101-4a16-a783-9e1a962d5ebe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:28:15.860Z',

          createdAt: '2024-05-30T21:16:02.955Z',
          content: [
            {
              caption: '',

              id: 'dc4844bb-69fb-421c-9aec-c362cc279e91',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab8b9991-19a4-491d-870e-802ee66eab16/ugc/642a9575-5101-4a16-a783-9e1a962d5ebe/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f98e4bc7-11ba-4387-aa10-a39f46018466',
        firstName: 'Kandase and Chase',
        lastName: 'Eatmon - The Great Family',
        email: 'kandasef@gmail.com',
        instagramProfile: {
          handle: 'the_greatfamily',
          followers: 1994,
          engagementRate: 1.3189568706118355
        },
        tiktokProfile: {
          handle: 'the_greatfamily',
          followers: 170,
          engagementRate: 4.878048780487805
        },
        youtubeProfile: {
          handle: 'thegreatfamily'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T02:26:53.000Z',
        amountCents: 7601
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b02a87d9-7676-4c6b-abe3-e03f9b195f54',
      content: [
        {
          groupId: 'e2b11e95-78ae-44a9-b38c-bcdb51699c61',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T01:31:28.444Z',

          createdAt: '2024-05-26T15:44:30.202Z',
          content: [
            {
              caption: '',

              id: 'ed7fafda-6946-46be-bbe6-4a10a9c57ac4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b02a87d9-7676-4c6b-abe3-e03f9b195f54/ugc/e2b11e95-78ae-44a9-b38c-bcdb51699c61/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '6e2fa8e9-587e-47f4-8d17-aeabc819bed2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T01:31:22.514Z',

          createdAt: '2024-05-26T03:59:37.118Z',
          content: [
            {
              caption: '',

              id: 'e3ed342e-bc84-4109-b6b7-89c1eeb6cb56',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b02a87d9-7676-4c6b-abe3-e03f9b195f54/ugc/6e2fa8e9-587e-47f4-8d17-aeabc819bed2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3c9f57d5-aa88-40e3-a03b-ffa430e87946',
        firstName: 'Dakota',
        lastName: 'Layfield',
        email: 'dakota.layfield@yahoo.com',
        instagramProfile: {
          handle: 'dakotalayfield',
          followers: 2330,
          engagementRate: 0.7296137339055794
        },
        tiktokProfile: {
          handle: 'itzz_kota1',
          followers: 1845,
          engagementRate: 4.23763386027537
        },
        youtubeProfile: {
          handle: 'itzzkota1',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T02:55:29.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f997c265-8e1f-454b-a4e9-1eb212889f5f',
      content: [
        {
          groupId: '3c596561-e3fa-4609-a3a5-7817dc7b7b7a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-17T00:23:56.197Z',

          createdAt: '2024-07-09T15:13:10.539Z',
          content: [
            {
              caption: '',

              id: 'ea5692d8-2340-4583-8c31-714e845bcc93',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f997c265-8e1f-454b-a4e9-1eb212889f5f/ugc/3c596561-e3fa-4609-a3a5-7817dc7b7b7a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3e0ef03c-0dfe-44a8-a11a-233dca6d06bd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-17T00:23:51.783Z',

          createdAt: '2024-07-14T21:09:43.025Z',
          content: [
            {
              caption: '',

              id: '461bf027-bd19-4f8c-8286-8cb18476b5a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f997c265-8e1f-454b-a4e9-1eb212889f5f/ugc/3e0ef03c-0dfe-44a8-a11a-233dca6d06bd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c7bd9ccd-ecea-4c77-99ae-a2ac0e022dac',
        firstName: 'Frewena',
        lastName: 'Deloria',
        email: 'frenniedeloria@gmail.com',
        instagramProfile: {
          handle: 'frewena',
          followers: 99589,
          engagementRate: 0.27533161292913877
        },
        tiktokProfile: {
          handle: 'frewena',
          followers: 221639,
          engagementRate: 4.913858009654989
        },
        youtubeProfile: {
          handle: 'frewena'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T03:02:42.000Z',
        amountCents: 7585
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4187f9fc-a143-4503-a866-2df8551583da',
      content: [
        {
          groupId: '4017f83f-b21d-41ec-8a24-814a43334708',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:16:20.262Z',

          createdAt: '2024-06-01T21:33:42.740Z',
          content: [
            {
              caption: '',

              id: '256c4bec-3336-41ab-856f-7f721383dd7b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4187f9fc-a143-4503-a866-2df8551583da/ugc/4017f83f-b21d-41ec-8a24-814a43334708/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '754bbf5d-a556-4c80-850b-07a4ae76cb5e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-03T11:51:44.083Z',

          createdAt: '2024-06-01T19:33:22.633Z',
          content: [
            {
              caption: '',

              id: 'ed9562f6-dd5e-4be1-bf0d-328835431a83',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4187f9fc-a143-4503-a866-2df8551583da/ugc/754bbf5d-a556-4c80-850b-07a4ae76cb5e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9fed51d5-a929-4d97-bbf7-1492b61ab964',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4187f9fc-a143-4503-a866-2df8551583da/ugc/754bbf5d-a556-4c80-850b-07a4ae76cb5e/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f7479652-8d24-498d-9348-53a620e83e9a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4187f9fc-a143-4503-a866-2df8551583da/ugc/754bbf5d-a556-4c80-850b-07a4ae76cb5e/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'afeb8d68-8554-423e-8e68-e2397970daee',
        firstName: 'Natasha',
        lastName: 'Welch',
        email: 'welchnatasha89@gmail.com',
        instagramProfile: {
          handle: 'facesbytash876',
          followers: 8296,
          engagementRate: 0.4713114754098361
        },
        tiktokProfile: {
          handle: 'mizz_unruly',
          followers: 2021,
          engagementRate: 2.1037868162692845
        },
        youtubeProfile: {
          handle: 'tashofficial6459',
          followers: 250
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T03:04:47.000Z',
        amountCents: 7603
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '55a9e0d3-90b8-450d-871f-2b54fd43b5fc',
      content: [
        {
          groupId: 'e0f8a63e-f2bc-49d0-8efa-ae398502dd25',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T17:46:01.060Z',

          createdAt: '2024-06-06T20:35:53.763Z',
          content: [
            {
              caption: '',

              id: '20e25939-a63f-4e0c-b6ed-aa5d7d6b5167',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55a9e0d3-90b8-450d-871f-2b54fd43b5fc/ugc/e0f8a63e-f2bc-49d0-8efa-ae398502dd25/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '8068896f-638e-4deb-8624-df734c717631',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-14T17:46:08.518Z',

          createdAt: '2024-06-06T20:34:50.840Z',
          content: [
            {
              caption: '',

              id: '9eaf25dd-5aa3-42f8-a221-7a15723aec83',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55a9e0d3-90b8-450d-871f-2b54fd43b5fc/ugc/8068896f-638e-4deb-8624-df734c717631/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1a0961c2-8aaa-4344-862b-875b672b6e40',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55a9e0d3-90b8-450d-871f-2b54fd43b5fc/ugc/8068896f-638e-4deb-8624-df734c717631/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2ed7a041-78bd-4d92-abcf-7dac01260845',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55a9e0d3-90b8-450d-871f-2b54fd43b5fc/ugc/8068896f-638e-4deb-8624-df734c717631/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a3e4edaf-de7a-4336-adc9-8a28e18bb3ea',
        firstName: 'Venel',
        lastName: 'Sanchez',
        email: 'venel38@gmail.com',
        instagramProfile: {
          handle: 'venneellll',
          followers: 1399
        },
        tiktokProfile: {
          handle: 'venelsan',
          followers: 2182
        },
        youtubeProfile: {
          handle: 'venelsanchez9952',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T03:32:13.000Z',
        amountCents: 8429
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '39e604ba-a975-47fb-89bb-192ff3d931b8',
      content: [
        {
          groupId: 'e2304db5-96e8-470d-bef2-aba1c393acfd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T06:24:28.176Z',

          createdAt: '2024-05-30T02:53:10.275Z',
          content: [
            {
              caption: '',

              id: '12bcddf4-652c-4649-b721-8bb34c5da1df',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39e604ba-a975-47fb-89bb-192ff3d931b8/ugc/e2304db5-96e8-470d-bef2-aba1c393acfd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '44794590-fb44-4265-bc7d-a7e2733b30b5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-05-30T06:19:00.325Z',

          createdAt: '2024-05-30T02:55:00.653Z',
          content: [
            {
              caption: '',

              id: '8ed8181a-f9b2-4a2a-889d-c0165505373d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39e604ba-a975-47fb-89bb-192ff3d931b8/ugc/44794590-fb44-4265-bc7d-a7e2733b30b5/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bd89e397-9d3f-4fad-b1e5-cca9dd482774',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39e604ba-a975-47fb-89bb-192ff3d931b8/ugc/44794590-fb44-4265-bc7d-a7e2733b30b5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c1536f7f-7400-484e-9e37-631550ac835c',
        firstName: 'Kasey',
        lastName: 'Smith',
        email: 'lazydazey95@aol.com',
        instagramProfile: {
          handle: 'nascarkasey_22',
          followers: 56,
          engagementRate: 1.8965517241379313
        },
        tiktokProfile: {
          handle: 'kaseyallison',
          followers: 3142,
          engagementRate: 2.7156027541136654
        },
        youtubeProfile: {
          handle: 'kaseyallison'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T03:33:20.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '610b3b68-e1c3-4653-a779-c47275f281ee',
      content: [],
      creator: {
        id: 'fa402802-3275-4ce6-bdd1-84e368e2d4e1',
        firstName: 'Dominique',
        lastName: 'Payne',
        email: 'domanaya0077@yahoo.com',
        instagramProfile: {
          handle: 'familyofpaynes',
          followers: 10212,
          engagementRate: 0.6835095965530747
        },
        tiktokProfile: {
          handle: 'domipayne',
          followers: 98
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T04:42:08.000Z',
        amountCents: 7517
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '695763dd-3684-488a-a41f-c0f90fc25fb1',
      content: [
        {
          groupId: '8bc2d689-ba93-4552-8386-ba2715314dc5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T02:26:00.787Z',

          createdAt: '2024-05-27T18:17:19.709Z',
          content: [
            {
              caption: '',

              id: '609d0b75-243b-4320-a949-57f69b2270a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/695763dd-3684-488a-a41f-c0f90fc25fb1/ugc/8bc2d689-ba93-4552-8386-ba2715314dc5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f1d5306e-07c0-4aa2-a5ea-f46fd2c7dcce',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T19:31:02.591Z',

          createdAt: '2024-05-27T18:18:04.657Z',
          content: [
            {
              caption: '',

              id: 'de5c1ad6-89d9-4fa8-a51f-8bb2185fc37b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/695763dd-3684-488a-a41f-c0f90fc25fb1/ugc/f1d5306e-07c0-4aa2-a5ea-f46fd2c7dcce/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3e69cbb6-592e-4dbc-9548-ec2c9d1c3b95',
        firstName: 'Melisha',
        lastName: 'Hudson',
        email: 'melisha.hudson1202@gmail.com',
        instagramProfile: {
          handle: 'ugvhmel',
          followers: 1130,
          engagementRate: 0.17699115044247787
        },
        tiktokProfile: {
          handle: 'mamamel0429',
          followers: 1219,
          engagementRate: 5.439768246559909
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T07:43:38.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '959cf3d5-89d7-4f2c-a223-52b52a76de45',
      content: [
        {
          groupId: 'e59f35dc-a5ac-40a2-b955-e4cebe633a66',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T02:32:58.453Z',

          createdAt: '2024-06-02T17:43:47.706Z',
          content: [
            {
              caption: '',

              id: '0de7a37b-53de-4a6d-bd80-1852584a74d8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/959cf3d5-89d7-4f2c-a223-52b52a76de45/ugc/e59f35dc-a5ac-40a2-b955-e4cebe633a66/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'cd808dc5-2b84-47bf-8a74-5c3baa6d3d79',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/959cf3d5-89d7-4f2c-a223-52b52a76de45/ugc/e59f35dc-a5ac-40a2-b955-e4cebe633a66/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'dbf79829-97a6-468e-af72-72d816a30c24',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/959cf3d5-89d7-4f2c-a223-52b52a76de45/ugc/e59f35dc-a5ac-40a2-b955-e4cebe633a66/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b259f511-0c8e-4f24-a35b-4c9de68659e2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T15:21:41.403Z',

          createdAt: '2024-06-02T17:42:48.709Z',
          content: [
            {
              caption: '',

              id: '3e03fdc5-5fe8-4552-b6dc-4a3194bb3886',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/959cf3d5-89d7-4f2c-a223-52b52a76de45/ugc/b259f511-0c8e-4f24-a35b-4c9de68659e2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b84f1221-30fc-4768-a6c9-26dd7dbff0c4',
        firstName: 'Chelsie',
        lastName: 'Cahoon',
        email: 'chelsiecahoon@gmail.com',
        instagramProfile: {
          handle: 'thesoberraver',
          followers: 24480,
          engagementRate: 1.400735294117647
        },
        tiktokProfile: {
          handle: 'thesoberraver',
          followers: 61384,
          engagementRate: 5.0585521264611115
        },
        youtubeProfile: {
          handle: 'chelsiecahoon',
          followers: 115
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T07:52:12.000Z',
        amountCents: 7370
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '39b75068-4d21-40f8-8db1-a4cf17898f4c',
      content: [
        {
          groupId: '16fc35aa-abc5-41ad-9109-e43ea534d8d6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-31T04:31:48.041Z',

          createdAt: '2024-05-28T23:53:32.670Z',
          content: [
            {
              caption: '',

              id: '573227bb-fd72-4404-82cd-ea2c506e6f62',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39b75068-4d21-40f8-8db1-a4cf17898f4c/ugc/16fc35aa-abc5-41ad-9109-e43ea534d8d6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '370d22e2-9b3a-4ecc-851f-33df28b9891f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39b75068-4d21-40f8-8db1-a4cf17898f4c/ugc/16fc35aa-abc5-41ad-9109-e43ea534d8d6/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '54e6b7a2-7024-4d5c-b00b-b9eff2f728c6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39b75068-4d21-40f8-8db1-a4cf17898f4c/ugc/16fc35aa-abc5-41ad-9109-e43ea534d8d6/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c5a28336-339f-4d35-a440-f23d4786fb2f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-31T04:31:00.773Z',

          createdAt: '2024-05-28T23:53:10.146Z',
          content: [
            {
              caption: '',

              id: '12712d57-9abb-4576-add5-3badf580248e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39b75068-4d21-40f8-8db1-a4cf17898f4c/ugc/c5a28336-339f-4d35-a440-f23d4786fb2f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e352097e-f394-4dce-be95-127b1bec0afa',
        firstName: 'Ashley',
        lastName: 'Kulbeck',
        email: 'ashkulbeck@hotmail.com',
        instagramProfile: {
          handle: 'ashley.fitnesss',
          followers: 22870,
          engagementRate: 7.204634892872759
        },
        tiktokProfile: {
          handle: 'ashleykkulb',
          followers: 1539,
          engagementRate: 2.520964360587002
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T07:54:47.000Z',
        amountCents: 7642
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0df6e4dc-ddd7-4dbe-befa-622082acd73e',
      content: [
        {
          groupId: '2361b008-8346-414e-8e0f-36480ffabe9f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-14T11:42:42.231Z',

          createdAt: '2024-06-02T03:53:20.572Z',
          content: [
            {
              caption: '',

              id: 'beb9f7b9-5ca0-4848-973c-1284ef9054ff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0df6e4dc-ddd7-4dbe-befa-622082acd73e/ugc/2361b008-8346-414e-8e0f-36480ffabe9f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4190e8e2-a386-4636-8d0d-b8804779b377',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0df6e4dc-ddd7-4dbe-befa-622082acd73e/ugc/2361b008-8346-414e-8e0f-36480ffabe9f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '79e6bfad-92ee-4741-aa46-0c8a214fb67a',
        firstName: 'Mindy',
        lastName: 'Sloan',
        email: 'minniesloan31@gmail.com',

        tiktokProfile: {
          handle: 'mindydawn20',
          followers: 4602,
          engagementRate: 1.1320754716981132
        },
        youtubeProfile: {
          handle: 'mindydawn16'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T11:34:17.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '39b843ab-9d1f-4430-a693-4ea10ba0de10',
      content: [
        {
          groupId: '1e1bfe43-47d9-4fa6-b287-5bd001de4f8c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T01:36:12.888Z',

          createdAt: '2024-05-30T20:29:55.530Z',
          content: [
            {
              caption: '',

              id: '3569d96c-2064-4442-a6e9-7f6e81827419',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39b843ab-9d1f-4430-a693-4ea10ba0de10/ugc/1e1bfe43-47d9-4fa6-b287-5bd001de4f8c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6a7e770e-f815-491d-8bbd-08f9ad425811',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39b843ab-9d1f-4430-a693-4ea10ba0de10/ugc/1e1bfe43-47d9-4fa6-b287-5bd001de4f8c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9098eaec-9d78-480a-9318-8d8bedc79a8e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39b843ab-9d1f-4430-a693-4ea10ba0de10/ugc/1e1bfe43-47d9-4fa6-b287-5bd001de4f8c/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '97dcd4af-67f9-4c93-85ec-eb9a38796ff4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:42:07.514Z',

          createdAt: '2024-05-30T20:27:36.792Z',
          content: [
            {
              caption: '',

              id: '442903dd-3225-4a5f-a551-60c92e432391',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39b843ab-9d1f-4430-a693-4ea10ba0de10/ugc/97dcd4af-67f9-4c93-85ec-eb9a38796ff4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '08e290de-3683-4053-b2eb-acfb3dd42cf4',
        firstName: 'Kimberlym',
        lastName: 'Bonilla',
        email: 'ugcwithkimm@gmail.com',

        tiktokProfile: {
          handle: 'ugcwithkimb',
          followers: 1135,
          engagementRate: 21.33838383838384
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T12:30:42.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '26c74a21-4d46-41d9-a741-f5de7d900037',
      content: [
        {
          groupId: 'e32120b9-fb78-4aa6-bee2-7f66f63d6987',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T19:27:52.311Z',

          createdAt: '2024-05-27T17:49:38.912Z',
          content: [
            {
              caption: '',

              id: 'a4d2c610-94d7-4198-9546-ba083caee997',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/26c74a21-4d46-41d9-a741-f5de7d900037/ugc/e32120b9-fb78-4aa6-bee2-7f66f63d6987/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e5c4cdca-a5b8-4f18-be78-8549af761af4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/26c74a21-4d46-41d9-a741-f5de7d900037/ugc/e32120b9-fb78-4aa6-bee2-7f66f63d6987/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '52879583-32fe-4a0d-b1ba-a271e464af85',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/26c74a21-4d46-41d9-a741-f5de7d900037/ugc/e32120b9-fb78-4aa6-bee2-7f66f63d6987/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9fa61561-fae4-4dd0-bcc6-97a0d48829de',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:47:39.536Z',

          createdAt: '2024-05-28T14:22:12.747Z',
          content: [
            {
              caption: '',

              id: '58b73127-b493-44fb-a09c-e2d387c21ac7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/26c74a21-4d46-41d9-a741-f5de7d900037/ugc/9fa61561-fae4-4dd0-bcc6-97a0d48829de/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a537bc97-f223-4fb3-9a15-5e66b0488a63',
        firstName: 'Staci',
        lastName: 'Hoover',
        email: 'starsjs@hotmail.com',
        instagramProfile: {
          handle: 'stacijsmith',
          followers: 3060,
          engagementRate: 0.9444444444444444
        },
        tiktokProfile: {
          handle: 'stacjhoov',
          followers: 13,
          engagementRate: 2.209944751381215
        },
        youtubeProfile: {
          handle: 'stacihoover3336'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T12:46:12.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5d1f7596-00b2-4741-8398-e66bf137de3f',
      content: [
        {
          groupId: 'c924b8c3-ad7d-4b6e-859d-73e3ed23a7d9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:20:38.344Z',

          createdAt: '2024-06-03T19:50:15.348Z',
          content: [
            {
              caption: '',

              id: '372b4397-bcf6-4ac9-90b3-9bf7fa8e77a3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d1f7596-00b2-4741-8398-e66bf137de3f/ugc/c924b8c3-ad7d-4b6e-859d-73e3ed23a7d9/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '6e09d54f-2faa-4564-a2ce-4260bb007564',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:20:43.103Z',

          createdAt: '2024-06-03T22:38:52.930Z',
          content: [
            {
              caption: '',

              id: '3b51b8ab-0d4d-4cf6-b355-3082d8fb3149',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d1f7596-00b2-4741-8398-e66bf137de3f/ugc/6e09d54f-2faa-4564-a2ce-4260bb007564/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '338d3fe1-ea44-453c-b1f1-eaa0c1a796df',
        firstName: 'Kristina',
        lastName: 'Reed',
        email: 'mamatimes4boys@gmail.com',
        instagramProfile: {
          handle: 'busymamachef',
          followers: 29390,
          engagementRate: 0.18339571282749234
        },
        tiktokProfile: {
          handle: 'boymamatimes4',
          followers: 3627,
          engagementRate: 5.428311451969322
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T13:15:25.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '34b01575-1abe-409e-9e00-d546f1e75a70',
      content: [
        {
          groupId: 'b1387d3d-4d4f-4a0d-b2dd-bee955c89885',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T13:47:13.876Z',

          createdAt: '2024-05-27T13:31:02.222Z',
          content: [
            {
              caption: '',

              id: '3fbbb757-0b48-4a19-b7fd-faaf715bf7df',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/34b01575-1abe-409e-9e00-d546f1e75a70/ugc/b1387d3d-4d4f-4a0d-b2dd-bee955c89885/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5a9c9f1f-5645-4c78-8d53-28a0cc995bcb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/34b01575-1abe-409e-9e00-d546f1e75a70/ugc/b1387d3d-4d4f-4a0d-b2dd-bee955c89885/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'eecaa698-73e8-4ca8-a224-6f7d7929ae13',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/34b01575-1abe-409e-9e00-d546f1e75a70/ugc/b1387d3d-4d4f-4a0d-b2dd-bee955c89885/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '44726efe-c644-404f-8bd8-ab52167ecb5f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:21:22.983Z',

          createdAt: '2024-05-31T21:33:20.796Z',
          content: [
            {
              caption: '',

              id: 'f3ca3978-a9b4-4aea-a817-2e33415e4f0d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/34b01575-1abe-409e-9e00-d546f1e75a70/ugc/44726efe-c644-404f-8bd8-ab52167ecb5f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c673939b-4534-4dc4-90c4-635c0d7ebf49',
        firstName: 'Brianna',
        lastName: 'Holliday',
        email: 'hollidaybrianna90@gmail.com',
        instagramProfile: {
          handle: 'brianna.holli',
          followers: 1419
        },
        tiktokProfile: {
          handle: 'hollerday',
          followers: 46
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T13:35:25.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '73b75241-d265-4dd3-bc0a-5c6d00f196ac',
      content: [],
      creator: {
        id: '0141b5d2-c24b-4ee6-ab79-e637c9068275',
        firstName: 'Chelsea',
        lastName: 'McQueen',
        email: 'chelsea.mcqueen98@gmail.com',
        instagramProfile: {
          handle: 'miss_chelsealynne',
          followers: 625,
          engagementRate: 1
        },
        tiktokProfile: {
          handle: 'chelslynne2018',
          followers: 83
        },
        youtubeProfile: {
          handle: 'chelseamcqueen8183'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T14:52:50.000Z',
        amountCents: 7646
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8c9a8be5-f648-4000-ad8c-b33aec180129',
      content: [
        {
          groupId: '12d10bbb-a799-4cf4-be67-8bc379c2617e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T20:30:24.631Z',

          createdAt: '2024-05-29T01:14:08.819Z',
          content: [
            {
              caption: '',

              id: '7f57eb00-db62-444d-a334-32fc8cf1d52d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c9a8be5-f648-4000-ad8c-b33aec180129/ugc/12d10bbb-a799-4cf4-be67-8bc379c2617e/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd2dd17ec-88da-4ca9-93bd-bc4d7905a1e6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c9a8be5-f648-4000-ad8c-b33aec180129/ugc/12d10bbb-a799-4cf4-be67-8bc379c2617e/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '94911510-78b0-492c-8415-9a75c51f9971',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c9a8be5-f648-4000-ad8c-b33aec180129/ugc/12d10bbb-a799-4cf4-be67-8bc379c2617e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2a3cf099-c8b2-42bd-93ce-15a9743ef4ad',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T20:30:21.244Z',

          createdAt: '2024-05-29T01:40:02.331Z',
          content: [
            {
              caption: '',

              id: '640a00be-818a-4df8-a9cb-e3483ce4e1c7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c9a8be5-f648-4000-ad8c-b33aec180129/ugc/2a3cf099-c8b2-42bd-93ce-15a9743ef4ad/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f94b2830-e82e-4b53-b52a-67a2fb3f7875',
        firstName: 'Hannah',
        lastName: 'Gresse',
        email: 'goinggresse16@gmail.com',
        instagramProfile: {
          handle: 'wonderfulhannah',
          followers: 1232,
          engagementRate: 1.6071428571428574
        },
        tiktokProfile: {
          handle: 'wonderfulhannah',
          followers: 200,
          engagementRate: 2.766798418972332
        },
        youtubeProfile: {
          handle: 'kylehannah3399',
          followers: 135
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T15:15:26.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dfb9d514-34fb-4d62-bc33-d8b138707a36',
      content: [
        {
          groupId: 'd84532b4-382f-43c4-8829-a2731f1e7eae',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T01:30:40.706Z',

          createdAt: '2024-05-28T00:46:58.525Z',
          content: [
            {
              caption: '',

              id: 'd353da05-1239-4dfe-9cf4-1ee0abc9a3ac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dfb9d514-34fb-4d62-bc33-d8b138707a36/ugc/d84532b4-382f-43c4-8829-a2731f1e7eae/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'dbfb7e37-3a41-4d64-a320-c5d6d951a16a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T01:30:47.407Z',

          createdAt: '2024-05-28T00:47:28.994Z',
          content: [
            {
              caption: '',

              id: 'ded07d5e-ed0a-40f4-a414-369b603d2772',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dfb9d514-34fb-4d62-bc33-d8b138707a36/ugc/dbfb7e37-3a41-4d64-a320-c5d6d951a16a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1dd145f5-1613-43d6-a906-6b9ca80fc73d',
        firstName: 'Sherbano',
        lastName: 'Maqbool',
        email: 'sherbano.maqbool@gmail.com',
        instagramProfile: {
          handle: 'makeupwithbano',
          followers: 1604,
          engagementRate: 3.918774492340577
        },
        tiktokProfile: {
          handle: 'sherbanomaqbool',
          followers: 137
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T15:19:06.000Z',
        amountCents: 8478
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7460f219-e38c-4185-ab94-2b2db104f021',
      content: [],
      creator: {
        id: '9e3e6e5b-d8e1-4759-9fa7-d486f32ce902',
        firstName: 'Hannah',
        lastName: 'Hantz',
        email: 'hannahpaigehantz7@gmail.com',

        tiktokProfile: {
          handle: 'hannahpbanks',
          followers: 1488,
          engagementRate: 15.277777777777779
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T15:34:11.000Z',
        amountCents: 7713
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f7539597-39d7-4646-949d-9d6d5ffd5b97',
      content: [
        {
          groupId: 'b798b9ec-b0d0-4fe4-86e5-213dd32c6676',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T14:39:02.256Z',

          createdAt: '2024-06-01T22:01:06.566Z',
          content: [
            {
              caption: '',

              id: 'f0e61b4c-9e84-4778-aa0d-04e74f720ce5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f7539597-39d7-4646-949d-9d6d5ffd5b97/ugc/b798b9ec-b0d0-4fe4-86e5-213dd32c6676/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '34285742-e348-4c5b-bc17-6960ab76cf0a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f7539597-39d7-4646-949d-9d6d5ffd5b97/ugc/b798b9ec-b0d0-4fe4-86e5-213dd32c6676/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ffac3afa-e91f-4382-bcbc-a0feac1daae5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f7539597-39d7-4646-949d-9d6d5ffd5b97/ugc/b798b9ec-b0d0-4fe4-86e5-213dd32c6676/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '498c5722-c935-4a26-b725-80fd0c38bbc0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-31T11:20:33.836Z',

          createdAt: '2024-05-29T23:12:13.960Z',
          content: [
            {
              caption: '',

              id: '98e34aad-58f7-44be-b3ae-e7577faa42b8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f7539597-39d7-4646-949d-9d6d5ffd5b97/ugc/498c5722-c935-4a26-b725-80fd0c38bbc0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '72000681-7982-42bf-8c03-096ff875e0a0',
        firstName: 'David',
        lastName: 'Lewis',
        email: 'merlotvsn@icloud.com',
        instagramProfile: {
          handle: 'merlot.papi',
          followers: 2446,
          engagementRate: 0.9239574816026166
        },
        tiktokProfile: {
          handle: 'merlot_papi',
          followers: 135
        },
        youtubeProfile: {
          handle: 'merlotvisionproductions',
          followers: 138
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:11:55.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '75aece17-73bc-433c-b83d-5fe2c0237777',
      content: [],
      creator: {
        id: '56c2cda7-5433-4e4a-875f-1b0a2dbf832d',
        firstName: 'Kayla',
        lastName: 'Cywinski',
        email: 'kaylacywinski@yahoo.com',
        instagramProfile: {
          handle: 'kaysconsin',
          followers: 1279,
          engagementRate: 3.862392494136044
        },
        tiktokProfile: {
          handle: 'kaykaycywin',
          followers: 70
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:13:06.000Z',
        amountCents: 7384
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b0b68860-40bb-41c3-a427-21ba4c0f147c',
      content: [
        {
          groupId: 'e9bcfb94-92bc-4727-b63e-ce55fe3c80da',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:42:07.897Z',

          createdAt: '2024-05-28T00:19:30.661Z',
          content: [
            {
              caption: '',

              id: 'c19c6701-abd6-4631-b1b7-3ae093f4902c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0b68860-40bb-41c3-a427-21ba4c0f147c/ugc/e9bcfb94-92bc-4727-b63e-ce55fe3c80da/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bd7c2742-d809-4ac8-a0a3-ad8e4641dcb5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T20:47:29.887Z',

          createdAt: '2024-05-28T00:29:36.634Z',
          content: [
            {
              caption: '',

              id: '913571f3-5a2b-4ff5-bf4a-441ea6cbe92b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0b68860-40bb-41c3-a427-21ba4c0f147c/ugc/bd7c2742-d809-4ac8-a0a3-ad8e4641dcb5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f449666e-2b7e-4423-a9a0-c6d30839df15',
        firstName: 'Neely',
        lastName: 'Walker',
        email: 'neelyough@gmail.com',
        instagramProfile: {
          handle: '_neelyshea',
          followers: 1340,
          engagementRate: 0.3687315634218289
        },
        tiktokProfile: {
          handle: 'nelesheuh',
          followers: 18
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:13:08.000Z',
        amountCents: 7603
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '61e8e457-5ecb-42c4-bf74-aa6e67ee7776',
      content: [],
      creator: {
        id: 'da0b2565-9c9b-442a-bd2f-5622b1c6de26',
        firstName: 'Kiara',
        lastName: 'Tejeda-Gonzalez',
        email: 'kiaratejedag@gmail.com',

        tiktokProfile: {
          handle: 'kmtg2',
          followers: 7302,
          engagementRate: 12.758806975224884
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:14:44.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '14d60378-e994-498a-a9ef-08b05e943fa0',
      content: [],
      creator: {
        id: 'ae6d1435-2a0b-4524-bfa5-7d12ff5e24b3',
        firstName: 'Erin',
        lastName: 'Whitten',
        email: 'erinmwhitten@gmail.com',
        instagramProfile: {
          handle: 'quotecatalog',
          followers: 240384,
          engagementRate: 2.1653271432374868
        },
        tiktokProfile: {
          handle: 'azulaskeeper',
          followers: 7232,
          engagementRate: 8.333333333333332
        },
        youtubeProfile: {
          handle: 'erinmwhitten',
          followers: 4
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:15:20.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '59433ce8-57b6-40a1-bd40-0a32886a3d1e',
      content: [
        {
          groupId: '0ed2b4eb-a0b2-4507-a2f4-d6beae453c86',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-01T21:50:11.581Z',

          createdAt: '2024-05-30T17:43:33.576Z',
          content: [
            {
              caption: '',

              id: '5b417099-213b-4603-94ef-97f923923e46',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/59433ce8-57b6-40a1-bd40-0a32886a3d1e/ugc/0ed2b4eb-a0b2-4507-a2f4-d6beae453c86/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0a338e75-f7f1-4c16-98c8-fe3b15d4942e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T17:26:18.691Z',

          createdAt: '2024-05-30T17:42:04.908Z',
          content: [
            {
              caption: '',

              id: '1c107dfc-3a34-4769-8b0c-a83e27a04235',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/59433ce8-57b6-40a1-bd40-0a32886a3d1e/ugc/0a338e75-f7f1-4c16-98c8-fe3b15d4942e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1d20b9f1-7d3b-4516-b267-9923e8881f7a',
        firstName: 'Yasmine',
        lastName: 'Aly',
        email: 'jazzyflips7@gmail.com',
        instagramProfile: {
          handle: 'jazzyglowbeauty',
          followers: 2429,
          engagementRate: 6.113627006998764
        },
        tiktokProfile: {
          handle: 'jazzyglowbeauty',
          followers: 1030,
          engagementRate: 6.438356164383562
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:15:44.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5d52bb51-f4d1-4588-8d96-b3faa5af6e70',
      content: [
        {
          groupId: 'f00464d1-1cf4-455d-8205-3c0dca00c1e5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:24:46.535Z',

          createdAt: '2024-06-02T00:14:57.652Z',
          content: [
            {
              caption: '',

              id: '31c2b1a0-6078-4601-ada4-b19818c808a6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d52bb51-f4d1-4588-8d96-b3faa5af6e70/ugc/f00464d1-1cf4-455d-8205-3c0dca00c1e5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '868c7f91-3467-4cd7-afb5-15fe8048f530',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T14:27:48.128Z',

          createdAt: '2024-06-01T17:55:15.234Z',
          content: [
            {
              caption: '',

              id: '1a0b4693-69cc-4774-968e-d8aac8607e13',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d52bb51-f4d1-4588-8d96-b3faa5af6e70/ugc/868c7f91-3467-4cd7-afb5-15fe8048f530/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'cf79a462-54dc-4de8-b95d-827cc4ee2ae5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d52bb51-f4d1-4588-8d96-b3faa5af6e70/ugc/868c7f91-3467-4cd7-afb5-15fe8048f530/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c5534eb5-aac4-41a9-9406-5d2141a0043e',
        firstName: 'Charnel',
        lastName: 'Dixon',
        email: 'highheelsandmotherhood@gmail.com',
        instagramProfile: {
          handle: 'mrscharneld',
          followers: 4856,
          engagementRate: 0.4262767710049424
        },
        tiktokProfile: {
          handle: 'mrscharneld',
          followers: 755,
          engagementRate: 4.714640198511166
        },
        youtubeProfile: {
          handle: 'misscharnelg',
          followers: 142
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:15:56.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '25e34c7a-3090-42e9-9c02-1d19e396ff2e',
      content: [
        {
          groupId: '55f861eb-2a6f-4db1-bde4-61fa1b9bb812',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T23:48:33.376Z',

          createdAt: '2024-06-02T01:56:41.412Z',
          content: [
            {
              caption: '',

              id: '720dbbc9-86fc-4e6c-b1ed-7f85d4346610',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/25e34c7a-3090-42e9-9c02-1d19e396ff2e/ugc/55f861eb-2a6f-4db1-bde4-61fa1b9bb812/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f9425a9c-a2ac-47f1-80bf-883909d3a860',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/25e34c7a-3090-42e9-9c02-1d19e396ff2e/ugc/55f861eb-2a6f-4db1-bde4-61fa1b9bb812/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'db4403a6-aa9c-4ba7-a870-9b807b394b40',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T23:48:10.185Z',

          createdAt: '2024-06-02T01:55:54.093Z',
          content: [
            {
              caption: '',

              id: '9cc49074-d59a-4e3b-8502-01db7abc9a8f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/25e34c7a-3090-42e9-9c02-1d19e396ff2e/ugc/db4403a6-aa9c-4ba7-a870-9b807b394b40/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '57846923-2467-437d-bafe-07cd709a03d1',
        firstName: 'Stephanie',
        lastName: 'Roberts',
        email: 'mamasteph93@gmail.com',
        instagramProfile: {
          handle: 'momlifewithsteph93',
          followers: 72,
          engagementRate: 5.416666666666667
        },
        tiktokProfile: {
          handle: 'stephm522',
          followers: 3845,
          engagementRate: 12.812061711079942
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:16:30.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e1ebcaf4-cf98-4b2f-9d6d-c7fe4d8ea762',
      content: [
        {
          groupId: '5f2298f7-31e4-471d-8146-3747d0976dbe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T13:55:50.811Z',

          createdAt: '2024-05-30T21:34:42.925Z',
          content: [
            {
              caption: '',

              id: '6b8df198-d90f-4d24-9e3e-51c0552e6c51',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1ebcaf4-cf98-4b2f-9d6d-c7fe4d8ea762/ugc/5f2298f7-31e4-471d-8146-3747d0976dbe/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'cbe60920-10b0-447c-9344-78b85f4e27dc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1ebcaf4-cf98-4b2f-9d6d-c7fe4d8ea762/ugc/5f2298f7-31e4-471d-8146-3747d0976dbe/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fdaa9703-fc60-47e4-95fb-697c65186efe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1ebcaf4-cf98-4b2f-9d6d-c7fe4d8ea762/ugc/5f2298f7-31e4-471d-8146-3747d0976dbe/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '752b5168-0ae8-4db6-aea8-2b0d83f51b2b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T13:25:39.973Z',

          createdAt: '2024-06-01T02:34:01.775Z',
          content: [
            {
              caption: '',

              id: 'b6505986-68b0-407c-84cb-dcfbfae5f618',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1ebcaf4-cf98-4b2f-9d6d-c7fe4d8ea762/ugc/752b5168-0ae8-4db6-aea8-2b0d83f51b2b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '053a0a3b-9d05-44d0-9c22-7a870185db26',
        firstName: 'Courtney',
        lastName: 'Segura',
        email: 'courtneyannpr@gmail.com',
        instagramProfile: {
          handle: 'courtney__segura',
          followers: 715,
          engagementRate: 1.9580419580419581
        },
        tiktokProfile: {
          handle: '_courtneyann_',
          followers: 10128,
          engagementRate: 3.3492335290280493
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:18:07.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '813afd2b-ef59-40ac-a6a9-3cb62c36b76b',
      content: [
        {
          groupId: 'f1179b3b-0035-48dd-b270-d30f63915d75',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:26:02.697Z',

          createdAt: '2024-06-03T17:41:58.265Z',
          content: [
            {
              caption: '',

              id: '60ae126d-e98f-4311-ae56-532dc99fc2a3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/813afd2b-ef59-40ac-a6a9-3cb62c36b76b/ugc/f1179b3b-0035-48dd-b270-d30f63915d75/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '9dfe5247-0bdd-4d8c-a0f8-28b49f771329',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T18:25:57.225Z',

          createdAt: '2024-06-03T17:43:01.032Z',
          content: [
            {
              caption: '',

              id: 'c65195e6-35c9-427d-9767-7dcb7b054de6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/813afd2b-ef59-40ac-a6a9-3cb62c36b76b/ugc/9dfe5247-0bdd-4d8c-a0f8-28b49f771329/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '880afab3-8e01-432c-8671-a591c99ca920',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/813afd2b-ef59-40ac-a6a9-3cb62c36b76b/ugc/9dfe5247-0bdd-4d8c-a0f8-28b49f771329/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f845dd64-afa3-44af-a2a1-a1eb5fd59d71',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/813afd2b-ef59-40ac-a6a9-3cb62c36b76b/ugc/9dfe5247-0bdd-4d8c-a0f8-28b49f771329/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '14fb7f05-441c-4280-996a-3a9ee8df88b4',
        firstName: 'Kristina',
        lastName: 'Sodano',
        email: 'my.fauxfarmhouse@gmail.com',
        instagramProfile: {
          handle: 'kristinasodanohome',
          followers: 29667,
          engagementRate: 1.0483028280581117
        },
        tiktokProfile: {
          handle: 'kristinasodanohome',
          followers: 11832,
          engagementRate: 3.402676987582648
        },
        youtubeProfile: {
          handle: 'kristinasodanohome',
          followers: 153
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:19:22.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0a113b45-b5cf-4993-8965-87be303e3fa7',
      content: [
        {
          groupId: '91a03854-86c7-4f98-a3eb-620038e46afe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:46:17.257Z',

          createdAt: '2024-06-03T01:53:10.730Z',
          content: [
            {
              caption: '',

              id: 'cc31e1b8-247d-4519-a8ed-7236173ce04e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0a113b45-b5cf-4993-8965-87be303e3fa7/ugc/91a03854-86c7-4f98-a3eb-620038e46afe/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c15a4df8-9056-4328-8dc5-6b242c19b2cc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T11:45:13.893Z',

          createdAt: '2024-06-03T01:53:42.020Z',
          content: [
            {
              caption: '',

              id: 'e93ed8ee-c365-4148-bb23-3068f945ea3d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0a113b45-b5cf-4993-8965-87be303e3fa7/ugc/c15a4df8-9056-4328-8dc5-6b242c19b2cc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b48c0e77-4128-470f-96ea-f59a56f044d0',
        firstName: 'Shaun',
        lastName: 'Bailey',
        email: 'shaunbailey803@gmail.com',
        instagramProfile: {
          handle: 'patrivkbailey',
          followers: 2730,
          engagementRate: 1.2307692307692308
        },
        tiktokProfile: {
          handle: 'iamshaunbailey',
          followers: 247,
          engagementRate: 6.92410119840213
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:22:00.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b5e191dc-3353-4dee-b90a-d0b25343fe30',
      content: [
        {
          groupId: '47e7f1f3-e980-468f-968d-032475643dad',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T18:11:17.311Z',

          createdAt: '2024-06-03T13:50:47.178Z',
          content: [
            {
              caption: '',

              id: '4d802c56-2a45-438a-94ec-8a64b05f6857',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b5e191dc-3353-4dee-b90a-d0b25343fe30/ugc/47e7f1f3-e980-468f-968d-032475643dad/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1852e180-23ae-4283-9f18-aded31a8bb89',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b5e191dc-3353-4dee-b90a-d0b25343fe30/ugc/47e7f1f3-e980-468f-968d-032475643dad/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7fc6b6d2-389c-47ee-9f7d-9787fe2e7b8a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b5e191dc-3353-4dee-b90a-d0b25343fe30/ugc/47e7f1f3-e980-468f-968d-032475643dad/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2eaac9a9-f758-46cc-8029-492e87bc5934',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:11:12.797Z',

          createdAt: '2024-06-03T13:39:14.689Z',
          content: [
            {
              caption: '',

              id: '27101517-4179-40aa-8e5a-8ebbce7f76b0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b5e191dc-3353-4dee-b90a-d0b25343fe30/ugc/2eaac9a9-f758-46cc-8029-492e87bc5934/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'caab7608-1a3b-4691-83e3-b2a1cabd06ad',
        firstName: 'Krystina',
        lastName: 'Sardelli',
        email: 'krystinasardelli@yahoo.com',
        instagramProfile: {
          handle: 'krystina.sardelli',
          followers: 1144,
          engagementRate: 0.4370629370629371
        },
        tiktokProfile: {
          handle: 'krystinasardelli',
          followers: 1148,
          engagementRate: 8.394160583941606
        },
        youtubeProfile: {
          handle: 'krystina1baby2',
          followers: 14
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:22:51.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fea8fb15-3fd3-4a17-ac09-c239b05e29a3',
      content: [
        {
          groupId: 'bfc8f2ca-54f6-40c3-a119-3d5c9c21ed38',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-09-11T20:39:55.943Z',

          createdAt: '2024-08-04T15:39:06.407Z',
          content: [
            {
              caption: '',

              id: '7fca44c9-b66a-4b51-992c-ae2b3ee2043e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fea8fb15-3fd3-4a17-ac09-c239b05e29a3/ugc/bfc8f2ca-54f6-40c3-a119-3d5c9c21ed38/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f3c0b9b5-19ef-406d-83ad-6009bcec4fa1',
        firstName: 'Regi',
        lastName: 'Charles',
        email: 'kingbee1k@gmail.com',
        instagramProfile: {
          handle: 'haitian_sensation101_',
          followers: 2600,
          engagementRate: 6.269230769230768
        },
        tiktokProfile: {
          handle: 'iamking2424',
          followers: 234,
          engagementRate: 1.8518518518518516
        },
        youtubeProfile: {
          handle: 'kingregdaboss'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:23:14.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd13c3fbc-3ddd-49d9-ba99-f72aaf9c9589',
      content: [
        {
          groupId: 'fbcbc6bf-9a3b-40ce-9c83-1392552f37b8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T17:18:53.809Z',

          createdAt: '2024-05-30T14:55:59.088Z',
          content: [
            {
              caption: '',

              id: 'f96f1e48-9707-41fd-b38d-9ca232f863c7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d13c3fbc-3ddd-49d9-ba99-f72aaf9c9589/ugc/fbcbc6bf-9a3b-40ce-9c83-1392552f37b8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd321006d-2b3f-401a-bde5-a4d159afdfb0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-01T20:31:26.123Z',

          createdAt: '2024-05-30T15:01:27.497Z',
          content: [
            {
              caption: '',

              id: 'b6a04910-9080-475d-a00a-f036fb2006ae',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d13c3fbc-3ddd-49d9-ba99-f72aaf9c9589/ugc/d321006d-2b3f-401a-bde5-a4d159afdfb0/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fb1bba85-4cc5-4684-bbbd-1946d22ab8c7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d13c3fbc-3ddd-49d9-ba99-f72aaf9c9589/ugc/d321006d-2b3f-401a-bde5-a4d159afdfb0/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f7e42b5a-8333-42f8-8897-769b4b807b7c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d13c3fbc-3ddd-49d9-ba99-f72aaf9c9589/ugc/d321006d-2b3f-401a-bde5-a4d159afdfb0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ca2ee641-839b-4422-b4c2-78fe842a5962',
        firstName: 'Alyssa',
        lastName: 'Vanlandingham',
        email: 'alyssalonglastname@gmail.com',
        instagramProfile: {
          handle: 'alyssalonglastname',
          followers: 42434,
          engagementRate: 0.9758684074091531
        },
        tiktokProfile: {
          handle: 'alyssalonglastname',
          followers: 68,
          engagementRate: 3.952023279344752
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:23:44.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6d62969f-851f-40f3-b9d3-81d44beff41f',
      content: [],
      creator: {
        id: '872fde12-0136-47cd-a742-f46e17dbbf66',
        firstName: 'Bianca',
        lastName: 'Cisneros',
        email: 'biancajcisneros@gmail.com',
        instagramProfile: {
          handle: 'binkyandmiguel',
          followers: 6955,
          engagementRate: 0.02588066139468009
        },
        tiktokProfile: {
          handle: 'biinkkyy',
          followers: 53
        },
        youtubeProfile: {
          handle: 'binkyfinds',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:24:14.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '21c0683d-8474-4bd0-965a-3c502af40b61',
      content: [
        {
          groupId: '5f0f576b-c367-44eb-aa65-738a1ca55a8e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-27T12:05:11.842Z',

          createdAt: '2024-08-09T17:54:50.139Z',
          content: [
            {
              caption: '',

              id: '2f91b5f2-9957-49bd-9cf3-b647c7ba785c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/21c0683d-8474-4bd0-965a-3c502af40b61/ugc/5f0f576b-c367-44eb-aa65-738a1ca55a8e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '87c63eb6-0b42-450b-8f4d-9555f97281a0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-27T12:05:14.915Z',

          createdAt: '2024-06-10T21:31:30.725Z',
          content: [
            {
              caption: '',

              id: '30213aca-7bd5-424f-97f8-8fb767bc5ca7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/21c0683d-8474-4bd0-965a-3c502af40b61/ugc/87c63eb6-0b42-450b-8f4d-9555f97281a0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ff27f9de-e152-4c84-bf61-69977f184c44',
        firstName: 'Deviana',
        lastName: 'Skyers Merizier',
        email: 'devianaskyers@yahoo.com',
        instagramProfile: {
          handle: 'devianaskyers',
          followers: 35848,
          engagementRate: 1.1755188573979023
        },
        tiktokProfile: {
          handle: 'devianaskyers',
          followers: 3876,
          engagementRate: 5.042016806722689
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:24:24.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '44f2d5a1-c534-4cd9-89c6-bd22d31c292e',
      content: [
        {
          groupId: '6925a7b1-a2d7-4861-a1ac-11e7835469f8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:26:25.943Z',

          createdAt: '2024-05-30T22:05:13.383Z',
          content: [
            {
              caption: '',

              id: '27aa0bc9-de0f-4d38-b92f-59435b75c177',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/44f2d5a1-c534-4cd9-89c6-bd22d31c292e/ugc/6925a7b1-a2d7-4861-a1ac-11e7835469f8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b0788f0e-dbb6-431b-a6fc-cd19cc5d2ec9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T15:20:09.243Z',

          createdAt: '2024-05-30T22:05:58.661Z',
          content: [
            {
              caption: '',

              id: '89aa70e6-c8d9-4d22-9b6c-8c8148fd759b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/44f2d5a1-c534-4cd9-89c6-bd22d31c292e/ugc/b0788f0e-dbb6-431b-a6fc-cd19cc5d2ec9/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c1a77fbb-36c9-4a65-a604-131d78291edc',
        firstName: 'Cece',
        lastName: 'Saldana',
        email: 'cecesaldana33@gmail.com',
        instagramProfile: {
          handle: 'cecesaldanaaa',
          followers: 1396,
          engagementRate: 7.809110629067245
        },
        tiktokProfile: {
          handle: 'ceces.studio',
          followers: 57
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:24:32.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5e62da56-c105-42c0-9063-66d780443d4f',
      content: [
        {
          groupId: '421cb390-5302-4d52-a19c-20422d08a9ec',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T16:22:52.292Z',

          createdAt: '2024-06-07T19:44:14.351Z',
          content: [
            {
              caption: '',

              id: 'f0b9ca06-af77-4ec4-82b1-eb9cee750fed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e62da56-c105-42c0-9063-66d780443d4f/ugc/421cb390-5302-4d52-a19c-20422d08a9ec/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '063fc03a-c707-4e86-9a90-ff5a867f4757',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e62da56-c105-42c0-9063-66d780443d4f/ugc/421cb390-5302-4d52-a19c-20422d08a9ec/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '65e310ee-d362-4ba7-babb-be8b9e8b4879',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e62da56-c105-42c0-9063-66d780443d4f/ugc/421cb390-5302-4d52-a19c-20422d08a9ec/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '554fd52f-2849-4876-baf3-92ab351e1dcb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T16:11:46.130Z',

          createdAt: '2024-06-07T19:42:24.449Z',
          content: [
            {
              caption: '',

              id: '208a5fae-76b3-4807-bd86-eb3ae669c70e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e62da56-c105-42c0-9063-66d780443d4f/ugc/554fd52f-2849-4876-baf3-92ab351e1dcb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd32806b0-5b88-407f-98f6-ad2902ccc902',
        firstName: 'Thao',
        lastName: 'Tran',
        email: 'tctranbusiness@gmail.com',

        tiktokProfile: {
          handle: 'tctrann',
          followers: 3180,
          engagementRate: 10.90047393364929
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:24:57.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '961ed5ec-c6b7-4c9e-b84a-f9ebbecb0847',
      content: [
        {
          groupId: '54315360-e462-4adf-9e9e-77e0a13ad6e0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T21:20:48.064Z',

          createdAt: '2024-05-27T18:27:39.208Z',
          content: [
            {
              caption: '',

              id: '85dc7900-e21a-4ba8-823e-b17943a0d4d3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/961ed5ec-c6b7-4c9e-b84a-f9ebbecb0847/ugc/54315360-e462-4adf-9e9e-77e0a13ad6e0/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '91d00799-43be-4a3d-b738-b54f272f9164',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/961ed5ec-c6b7-4c9e-b84a-f9ebbecb0847/ugc/54315360-e462-4adf-9e9e-77e0a13ad6e0/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b4f1e865-95b4-475a-85b8-7616949f0c9a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/961ed5ec-c6b7-4c9e-b84a-f9ebbecb0847/ugc/54315360-e462-4adf-9e9e-77e0a13ad6e0/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f3d5e084-4877-4af4-8f2b-daeae15affbe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:20:52.707Z',

          createdAt: '2024-05-27T18:27:06.519Z',
          content: [
            {
              caption: '',

              id: '5b51ae90-0ac3-4360-94a6-7decde5c0d90',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/961ed5ec-c6b7-4c9e-b84a-f9ebbecb0847/ugc/f3d5e084-4877-4af4-8f2b-daeae15affbe/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1817dd5e-24bd-4f3d-911c-4b384a5f4536',
        firstName: 'Angela',
        lastName: 'Stover',
        email: 'txhippiemom71@gmail.com',
        instagramProfile: {
          handle: 'tx_hippie_mom',
          followers: 531,
          engagementRate: 0.8286252354048964
        },
        tiktokProfile: {
          handle: 'tx_hippie_mom',
          followers: 8866,
          engagementRate: 4.4480519480519485
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:25:03.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f64ab730-8ecc-4d6d-b896-c52eecf8a802',
      content: [
        {
          groupId: '4606eb57-8ed5-4c23-ac64-bf0d0507d1cc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T23:58:44.735Z',

          createdAt: '2024-05-28T00:31:05.657Z',
          content: [
            {
              caption: '',

              id: '5d8c72be-f600-47d5-99af-7020e063b458',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f64ab730-8ecc-4d6d-b896-c52eecf8a802/ugc/4606eb57-8ed5-4c23-ac64-bf0d0507d1cc/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8452722e-5546-45e4-a5de-7ace57eb22b0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f64ab730-8ecc-4d6d-b896-c52eecf8a802/ugc/4606eb57-8ed5-4c23-ac64-bf0d0507d1cc/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3e42e441-3b9c-4231-afe0-384ba9f1c67d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f64ab730-8ecc-4d6d-b896-c52eecf8a802/ugc/4606eb57-8ed5-4c23-ac64-bf0d0507d1cc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '36285739-7ea5-46f1-aced-e63988000300',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T01:24:11.587Z',

          createdAt: '2024-05-28T00:30:29.957Z',
          content: [
            {
              caption: '',

              id: '9bef4323-de50-4c47-82cc-23b3ed2b01ff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f64ab730-8ecc-4d6d-b896-c52eecf8a802/ugc/36285739-7ea5-46f1-aced-e63988000300/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3381aa28-3af3-4444-bcc2-ed1271a3f646',
        firstName: 'Tola',
        lastName: 'Anif',
        email: 'tolaanif@gmail.com',
        instagramProfile: {
          handle: 'tolaanif',
          followers: 6611,
          engagementRate: 0.01346015793251974
        },
        tiktokProfile: {
          handle: 'tolaanif',
          followers: 2298,
          engagementRate: 0.6622516556291391
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:25:42.000Z',
        amountCents: 10337
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4a06e69a-97d0-482b-8aaf-fa174d0b60b6',
      content: [
        {
          groupId: '846718b5-477e-4042-88b6-168f726ab355',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T22:47:21.255Z',

          createdAt: '2024-05-28T15:40:50.405Z',
          content: [
            {
              caption: '',

              id: '1e70b1a6-fd59-4e67-8db2-685fd83c140b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4a06e69a-97d0-482b-8aaf-fa174d0b60b6/ugc/846718b5-477e-4042-88b6-168f726ab355/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5d03fafa-b9f9-4b7e-a831-6bbc729af5e0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4a06e69a-97d0-482b-8aaf-fa174d0b60b6/ugc/846718b5-477e-4042-88b6-168f726ab355/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7dd143c0-94ac-43af-8c6c-7d3ad8515ba8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4a06e69a-97d0-482b-8aaf-fa174d0b60b6/ugc/846718b5-477e-4042-88b6-168f726ab355/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '27409281-1cc1-4855-9a23-3a5488035c74',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T22:47:12.617Z',

          createdAt: '2024-05-28T15:37:49.927Z',
          content: [
            {
              caption: '',

              id: '0eb8435f-d1b1-4dd9-bd48-477187b60229',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4a06e69a-97d0-482b-8aaf-fa174d0b60b6/ugc/27409281-1cc1-4855-9a23-3a5488035c74/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '448d69f4-524d-49e3-a4a6-f876a411b41b',
        firstName: 'Kate',
        lastName: 'Spoon',
        email: 'tempt.kat@gmail.com',
        instagramProfile: {
          handle: 'kate.spoon',
          followers: 6880,
          engagementRate: 3.7906976744186047
        },
        tiktokProfile: {
          handle: 'kate.spoon',
          followers: 888,
          engagementRate: 5.9845167840599025
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:26:02.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '28af1dc3-fa2a-4204-ade1-787e70c3fd8b',
      content: [
        {
          groupId: '84c1ffd7-714e-4046-9bca-67cdd9e42fd7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T11:38:53.030Z',

          createdAt: '2024-05-27T20:50:18.761Z',
          content: [
            {
              caption: '',

              id: '9ee7e30f-2e53-4d48-99b2-f0917be28064',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28af1dc3-fa2a-4204-ade1-787e70c3fd8b/ugc/84c1ffd7-714e-4046-9bca-67cdd9e42fd7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e96c3086-c74e-46a7-9c85-235c8efd2493',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T11:39:01.784Z',

          createdAt: '2024-05-27T19:44:53.613Z',
          content: [
            {
              caption: '',

              id: 'bd43b428-3c85-4a76-858b-f0bdf3eeb3fc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28af1dc3-fa2a-4204-ade1-787e70c3fd8b/ugc/e96c3086-c74e-46a7-9c85-235c8efd2493/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1f41b245-5da4-418e-a9f1-e5b1ce158e11',
        firstName: 'Allison',
        lastName: 'Wellman',
        email: 'allisonkwellman@gmail.com',
        instagramProfile: {
          handle: 'alli_wellman',
          followers: 15138,
          engagementRate: 0.10239133306909763
        },
        tiktokProfile: {
          handle: 'wellmancreatives',
          followers: 13452,
          engagementRate: 0.35714285714285715
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:27:15.000Z',
        amountCents: 9538
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f73fe8d5-afa4-4b59-8eaa-a82009fdd080',
      content: [
        {
          groupId: 'eb846cc7-8fde-427c-8175-3adcabcd468f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T14:36:27.848Z',

          createdAt: '2024-06-19T00:08:29.321Z',
          content: [
            {
              caption: '',

              id: 'ead14f54-a9a5-4c9d-907d-5834f2f9de14',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f73fe8d5-afa4-4b59-8eaa-a82009fdd080/ugc/eb846cc7-8fde-427c-8175-3adcabcd468f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '07c2da4b-f0aa-4f6b-8de7-a2fdb0519037',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T14:36:33.568Z',

          createdAt: '2024-06-18T23:38:56.470Z',
          content: [
            {
              caption: '',

              id: 'efbc6669-3fc1-4123-9493-ed992e32a665',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f73fe8d5-afa4-4b59-8eaa-a82009fdd080/ugc/07c2da4b-f0aa-4f6b-8de7-a2fdb0519037/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2d47c04c-e501-4992-8ba3-fc66081dd3a2',
        firstName: 'Andreia',
        lastName: 'Shlepr',
        email: 'andreiashlepr@gmail.com',
        instagramProfile: {
          handle: 'andreia_shlepr',
          followers: 6165,
          engagementRate: 0.26926196269261965
        },
        tiktokProfile: {
          handle: 'andreia_shlepr',
          followers: 13283,
          engagementRate: 4.935257738506363
        },
        youtubeProfile: {
          handle: 'andreiashlepr'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:28:18.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b2375a81-49f2-4b58-8e11-b03f3a9ef2f6',
      content: [
        {
          groupId: '505f155c-72a8-4f03-bd20-75d7a8b8d6df',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T19:37:15.084Z',

          createdAt: '2024-06-05T02:22:29.653Z',
          content: [
            {
              caption: '',

              id: '234c8f30-686b-4c41-82cc-1f471b272c1f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b2375a81-49f2-4b58-8e11-b03f3a9ef2f6/ugc/505f155c-72a8-4f03-bd20-75d7a8b8d6df/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '05f90fd9-fd3d-413e-b942-7ca20e79ff45',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b2375a81-49f2-4b58-8e11-b03f3a9ef2f6/ugc/505f155c-72a8-4f03-bd20-75d7a8b8d6df/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b57ff311-79b9-44c3-b712-7ad548af5762',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b2375a81-49f2-4b58-8e11-b03f3a9ef2f6/ugc/505f155c-72a8-4f03-bd20-75d7a8b8d6df/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e7cccbcc-86ec-4629-89c0-e501fd3d5ca1',
        firstName: 'Kayla',
        lastName: 'Mena',
        email: 'kookiemonstaa21@gmail.com',
        instagramProfile: {
          handle: 'kayy_marieee_',
          followers: 2938,
          engagementRate: 1.0891763104152483
        },
        tiktokProfile: {
          handle: 'kaylathebaela',
          followers: 166
        },
        youtubeProfile: {
          handle: 'kayymarieetv'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:34:23.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2cb92d80-8b95-4046-9ba5-7737ee4705a7',
      content: [],
      creator: {
        id: '2c454c13-2c47-42ec-b6b2-d915ae6a7a16',
        firstName: 'Breana',
        lastName: 'Mueller',
        email: 'brebabyfit@gmail.com',
        instagramProfile: {
          handle: 'brebabyfit',
          followers: 1993,
          engagementRate: 0.0433382862803368
        },
        tiktokProfile: {
          handle: 'brebabyfit',
          followers: 18260
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:34:42.000Z',
        amountCents: 8641
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cbef19f5-fcf9-472a-93e1-7f9bccd4be4a',
      content: [
        {
          groupId: 'e4dba135-d2be-4aa0-b9e7-be3875c7ab2f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T11:16:15.771Z',

          createdAt: '2024-05-29T15:10:18.544Z',
          content: [
            {
              caption: '',

              id: '57cf82cd-f54d-4014-81ab-63e07de17f26',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cbef19f5-fcf9-472a-93e1-7f9bccd4be4a/ugc/e4dba135-d2be-4aa0-b9e7-be3875c7ab2f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bc9bffa4-7556-4505-85f3-86f4a9583676',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T11:02:21.390Z',

          createdAt: '2024-05-29T15:09:50.731Z',
          content: [
            {
              caption: '',

              id: 'af956fbb-f8f2-4556-a9a0-8804ad1b7d0e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cbef19f5-fcf9-472a-93e1-7f9bccd4be4a/ugc/bc9bffa4-7556-4505-85f3-86f4a9583676/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e99691e5-7ee5-428a-9997-e86eb57f5ddf',
        firstName: 'Cassandra',
        lastName: 'Arico',
        email: 'vballangel5@gmail.com',
        instagramProfile: {
          handle: 'singlemomstrongtraveler',
          followers: 6776,
          engagementRate: 0.28630460448642264
        },
        tiktokProfile: {
          handle: 'singlemomstrongtraveler',
          followers: 234
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:36:12.000Z',
        amountCents: 8148
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '84c3dacb-11e2-4fdf-87c9-e943c1ed4c07',
      content: [
        {
          groupId: 'fd31ffac-fdd3-4061-b7e8-b2c1eec860c8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T10:35:55.222Z',

          createdAt: '2024-05-29T17:47:00.215Z',
          content: [
            {
              caption: '',

              id: 'c9a6105c-1015-448a-a4ee-020249e46bfa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84c3dacb-11e2-4fdf-87c9-e943c1ed4c07/ugc/fd31ffac-fdd3-4061-b7e8-b2c1eec860c8/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2c605dee-e7ae-4e30-acf6-ba4db2cd5527',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84c3dacb-11e2-4fdf-87c9-e943c1ed4c07/ugc/fd31ffac-fdd3-4061-b7e8-b2c1eec860c8/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ae197d0a-597e-4c3e-8ed4-5f64b7c6feba',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T10:35:44.608Z',

          createdAt: '2024-05-29T17:46:09.037Z',
          content: [
            {
              caption: '',

              id: '2b69e9ae-b40d-4ff9-bf38-a32544dc610d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84c3dacb-11e2-4fdf-87c9-e943c1ed4c07/ugc/ae197d0a-597e-4c3e-8ed4-5f64b7c6feba/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f834ab22-40dc-4a90-a9fd-b2cef6eb4cf5',
        firstName: 'Nelson',
        lastName: 'Lista',
        email: 'nelsonlistam@gmail.com',
        instagramProfile: {
          handle: 'nelson_lista',
          followers: 26451,
          engagementRate: 4.2629768250727755
        },
        tiktokProfile: {
          handle: 'elpanadelugc',
          followers: 2281,
          engagementRate: 14.669206789054382
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:36:12.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '39d5d1a6-9144-4e6a-985b-c733cd64a3b9',
      content: [
        {
          groupId: '03f0a529-f197-4a9f-a486-5164a6c2bf46',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T15:39:33.989Z',

          createdAt: '2024-05-26T22:31:36.674Z',
          content: [
            {
              caption: '',

              id: 'f411171a-2b6d-4a41-9420-b9912906620e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39d5d1a6-9144-4e6a-985b-c733cd64a3b9/ugc/03f0a529-f197-4a9f-a486-5164a6c2bf46/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '26e20c83-dbbc-469e-a2e1-3d6fdfdc6283',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39d5d1a6-9144-4e6a-985b-c733cd64a3b9/ugc/03f0a529-f197-4a9f-a486-5164a6c2bf46/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a55e7cce-44b0-4012-b63b-06fb7c307d7f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39d5d1a6-9144-4e6a-985b-c733cd64a3b9/ugc/03f0a529-f197-4a9f-a486-5164a6c2bf46/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f8d9fb07-5fb5-477a-88a9-ba0d69ac9495',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T15:39:28.330Z',

          createdAt: '2024-05-29T01:36:20.628Z',
          content: [
            {
              caption: '',

              id: 'eb28efcd-7af0-44ba-8634-1c6e441cec36',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39d5d1a6-9144-4e6a-985b-c733cd64a3b9/ugc/f8d9fb07-5fb5-477a-88a9-ba0d69ac9495/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2438a187-f748-41d2-a14f-d115692266e7',
        firstName: 'Amanda',
        lastName: 'Serenbetz',
        email: 'leighlei2009@gmail.com',
        instagramProfile: {
          handle: 'leighdoeslife',
          followers: 11738,
          engagementRate: 0.05367183506559891
        },
        tiktokProfile: {
          handle: 'hiyasoup',
          followers: 48
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:42:46.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e3ddedf9-2314-46d7-892e-d1f73a7558eb',
      content: [],
      creator: {
        id: 'b12b6a43-4550-4a7f-b93c-f2a7b5a9377f',
        firstName: 'Whitney',
        lastName: 'Hollman',
        email: 'ageewhitney@yahoo.com',
        instagramProfile: {
          handle: 'whit_marieee',
          followers: 28252,
          engagementRate: 2.0929491717400537
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:45:26.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e57341e0-4096-4c33-a48d-702be0a7f234',
      content: [],
      creator: {
        id: 'f1c8d177-bdf1-4ebb-8d88-e6de8f82f398',
        firstName: 'Milena',
        lastName: 'Aleksic',
        email: 'hello@aleksictwins.com',
        instagramProfile: {
          handle: 'aleksic_twins',
          followers: 135755,
          engagementRate: 1.4646237707635077
        },
        tiktokProfile: {
          handle: 'aleksic_twins',
          followers: 25612,
          engagementRate: 10.044785668586051
        },
        youtubeProfile: {
          handle: 'aleksic_twins'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:50:00.000Z',
        amountCents: 10000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '352f08d9-599e-4c42-a209-489cb5dbc226',
      content: [
        {
          groupId: '25c4b8f2-a999-4faf-ad2e-9a6b876aaa6b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T17:23:47.795Z',

          createdAt: '2024-05-28T05:17:54.494Z',
          content: [
            {
              caption: '',

              id: 'c7407bb3-4064-4ffa-8240-bf3519ca0d65',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/352f08d9-599e-4c42-a209-489cb5dbc226/ugc/25c4b8f2-a999-4faf-ad2e-9a6b876aaa6b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '8d7fd09c-ab03-449b-b5b1-ca57ec8114e6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-04T17:23:53.112Z',

          createdAt: '2024-05-28T05:19:15.676Z',
          content: [
            {
              caption: '',

              id: '12735582-d780-466d-bd43-8d89dc9dc8ac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/352f08d9-599e-4c42-a209-489cb5dbc226/ugc/8d7fd09c-ab03-449b-b5b1-ca57ec8114e6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5c55c7d0-dc64-4fe7-9b45-0818d8e93481',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/352f08d9-599e-4c42-a209-489cb5dbc226/ugc/8d7fd09c-ab03-449b-b5b1-ca57ec8114e6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '065ee5d3-28f1-4688-b755-e01455e124d9',
        firstName: 'Keadian ',
        lastName: 'Blake',
        email: 'keadianblake979@gmail.com',
        instagramProfile: {
          handle: 'thickasskady',
          followers: 543,
          engagementRate: 3.1570639305445938
        },
        tiktokProfile: {
          handle: 'kadykay24',
          followers: 1605,
          engagementRate: 5.3708439897698215
        },
        youtubeProfile: {
          handle: 'keadianblake7152',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:52:29.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '70ac3c7e-3c94-4099-a0cd-284cc64f332b',
      content: [
        {
          groupId: '0fec2e2f-72ec-43cf-8861-38b15c72428d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-31T19:44:49.412Z',

          createdAt: '2024-05-29T23:52:41.049Z',
          content: [
            {
              caption: '',

              id: '698658b9-e42f-42fb-b0e7-79df50826f4c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/70ac3c7e-3c94-4099-a0cd-284cc64f332b/ugc/0fec2e2f-72ec-43cf-8861-38b15c72428d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '864fd728-a49c-4a7b-aa7b-bf7f88469172',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/70ac3c7e-3c94-4099-a0cd-284cc64f332b/ugc/0fec2e2f-72ec-43cf-8861-38b15c72428d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6f635966-558e-4c19-8908-d462b8728827',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/70ac3c7e-3c94-4099-a0cd-284cc64f332b/ugc/0fec2e2f-72ec-43cf-8861-38b15c72428d/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4ff673b9-4bd1-4af6-8478-91a3da013dd7',
        firstName: 'Laneishia',
        lastName: 'Maxwell',
        email: 'laneishia.maxwell@gmail.com',
        instagramProfile: {
          handle: 'laneishiamaxwell',
          followers: 2290,
          engagementRate: 1.0407569141193596
        },
        tiktokProfile: {
          handle: 'maxdesignsstudios',
          followers: 674,
          engagementRate: 4.846938775510204
        },
        youtubeProfile: {
          handle: 'babynesh05'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:53:38.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '01080bc6-9fef-4d96-9d78-8645538a237f',
      content: [],
      creator: {
        id: 'e2fce87b-58fb-4af5-b6a8-844aead52d1c',
        firstName: 'Ashlynn',
        lastName: 'Hawley',
        email: 'ashlynnhawley@rocketmail.com',
        instagramProfile: {
          handle: 'ashlynnhawley',
          followers: 2862
        },
        tiktokProfile: {
          handle: 'ashlynn7589',
          followers: 1367,
          engagementRate: 16.435643564356436
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:55:47.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '79acd098-c10f-4928-a4bb-640131607d40',
      content: [
        {
          groupId: 'a915ba8f-8410-48f0-9207-612cdbb6bd06',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T04:51:24.163Z',

          createdAt: '2024-05-31T22:59:07.238Z',
          content: [
            {
              caption: '',

              id: '08a2db09-9c82-4441-b507-4f8ee7371ad0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/79acd098-c10f-4928-a4bb-640131607d40/ugc/a915ba8f-8410-48f0-9207-612cdbb6bd06/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8a29d657-0b37-47c5-8396-b595eb13440f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/79acd098-c10f-4928-a4bb-640131607d40/ugc/a915ba8f-8410-48f0-9207-612cdbb6bd06/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ac8a354e-fb0f-4a4a-a6d0-85861124e577',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T19:47:32.012Z',

          createdAt: '2024-05-31T22:58:28.182Z',
          content: [
            {
              caption: '',

              id: 'e630325f-c15a-4ffb-be4a-4a72f07d1859',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/79acd098-c10f-4928-a4bb-640131607d40/ugc/ac8a354e-fb0f-4a4a-a6d0-85861124e577/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f4b4654b-44c6-4311-9229-d64f682f25ea',
        firstName: 'Rabia',
        lastName: 'Terzioglu',
        email: 'rabiascookies@gmail.com',
        instagramProfile: {
          handle: 'lifeofpetitelady',
          followers: 48428,
          engagementRate: 4.6985215164780705
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:56:53.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8e3ae747-b2aa-4cad-9371-0f952df6edd0',
      content: [
        {
          groupId: '0cd071a6-00cc-420c-bf93-8e491ab39251',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-15T14:02:57.120Z',

          createdAt: '2024-05-31T22:05:32.105Z',
          content: [
            {
              caption: '',

              id: 'd1332160-81d6-478a-ab17-13bc2439f4be',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e3ae747-b2aa-4cad-9371-0f952df6edd0/ugc/0cd071a6-00cc-420c-bf93-8e491ab39251/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '570ba87a-da25-4ef0-85b3-17999c87ea1b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e3ae747-b2aa-4cad-9371-0f952df6edd0/ugc/0cd071a6-00cc-420c-bf93-8e491ab39251/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4bbe6bb3-9373-41f2-a1de-c93afeb981a5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e3ae747-b2aa-4cad-9371-0f952df6edd0/ugc/0cd071a6-00cc-420c-bf93-8e491ab39251/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b38df3da-0eb3-4514-a257-0dfd1ed9b336',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T17:04:18.951Z',

          createdAt: '2024-06-14T21:55:56.834Z',
          content: [
            {
              caption: '',

              id: 'dc4005bf-ea16-41e6-a8b0-d3956ad62318',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e3ae747-b2aa-4cad-9371-0f952df6edd0/ugc/b38df3da-0eb3-4514-a257-0dfd1ed9b336/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4db54252-518b-432f-9f0d-13167945bf2b',
        firstName: 'Christopher',
        lastName: 'Schlumbrecht',
        email: 'email@christopherschlumbrecht.online',
        instagramProfile: {
          handle: 'haveaball717',
          followers: 1034,
          engagementRate: 0.04835589941972921
        },
        tiktokProfile: {
          handle: 'haveaball717',
          followers: 506,
          engagementRate: 0.42337002540220153
        },
        youtubeProfile: {
          handle: 'haveaball717',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T16:56:55.000Z',
        amountCents: 7660
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '398b32a0-d2d3-4524-91de-69972f579996',
      content: [
        {
          groupId: 'f7c99563-feca-4d51-ab66-9882aa62a36c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T12:56:07.554Z',

          createdAt: '2024-05-25T17:30:15.868Z',
          content: [
            {
              caption: '',

              id: '1dc77d1b-395e-4f84-8f31-9528b66a54d8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/398b32a0-d2d3-4524-91de-69972f579996/ugc/f7c99563-feca-4d51-ab66-9882aa62a36c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f54169a5-4acf-46f7-a913-842b3e5ec0b6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/398b32a0-d2d3-4524-91de-69972f579996/ugc/f7c99563-feca-4d51-ab66-9882aa62a36c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1f11b42c-68f9-4048-b824-70cbd1dcaefa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/398b32a0-d2d3-4524-91de-69972f579996/ugc/f7c99563-feca-4d51-ab66-9882aa62a36c/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bdc9f81f-d042-46b9-a16d-0e0b8c793342',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T12:56:01.997Z',

          createdAt: '2024-05-25T17:19:36.215Z',
          content: [
            {
              caption: '',

              id: '7e02f222-71bd-4d6e-b932-0b412cbe0f5f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/398b32a0-d2d3-4524-91de-69972f579996/ugc/bdc9f81f-d042-46b9-a16d-0e0b8c793342/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e3f388c5-e478-4104-8852-f2fc6192a4ca',
        firstName: 'Natalie',
        lastName: 'Gammonley',
        email: 'gammonleyn@gmail.com',
        instagramProfile: {
          handle: 'nataliegammonley',
          followers: 2003,
          engagementRate: 5.629056415376935
        },
        tiktokProfile: {
          handle: 'nataliegammonley',
          followers: 10412,
          engagementRate: 18.84308736217133
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:05:08.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1e8c16ff-b6a3-43bf-9993-9e80330706b7',
      content: [
        {
          groupId: 'cd00509d-c23a-4db1-9402-e963fe6d1b50',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-30T22:38:51.521Z',

          createdAt: '2024-07-26T16:39:55.109Z',
          content: [
            {
              caption: '',

              id: 'ea06331f-6560-42cd-8654-39dead7a7688',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1e8c16ff-b6a3-43bf-9993-9e80330706b7/ugc/cd00509d-c23a-4db1-9402-e963fe6d1b50/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'db193a85-9f1f-4722-b303-3f6fe84c6bc7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-30T22:38:55.353Z',

          createdAt: '2024-06-06T14:17:46.651Z',
          content: [
            {
              caption: '',

              id: '65699470-64d7-4798-ae3c-024a5a939c50',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1e8c16ff-b6a3-43bf-9993-9e80330706b7/ugc/db193a85-9f1f-4722-b303-3f6fe84c6bc7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0831cf3e-7078-4abb-81e0-564357791dfa',
        firstName: 'Kristen',
        lastName: 'Johnson',
        email: 'kristenjohnson5421@gmail.com',
        instagramProfile: {
          handle: 'fresh_faith_creations',
          followers: 4299,
          engagementRate: 0.09302325581395349
        },
        tiktokProfile: {
          handle: 'perfectlyimperfect5421',
          followers: 10311,
          engagementRate: 8.539077602872135
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:05:59.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bdafbcc4-9083-482b-ad61-bddb3d2b320e',
      content: [
        {
          groupId: '36919931-8f51-4ef3-bc61-5f5b5b97e660',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T06:27:28.326Z',

          createdAt: '2024-06-09T18:23:02.896Z',
          content: [
            {
              caption: '',

              id: 'b8b3768b-6a86-4129-b76e-7812e53f9020',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bdafbcc4-9083-482b-ad61-bddb3d2b320e/ugc/36919931-8f51-4ef3-bc61-5f5b5b97e660/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '0334724e-68af-42df-92c0-1368db101ca1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-09T18:25:45.818Z',

          createdAt: '2024-05-27T19:13:29.674Z',
          content: [
            {
              caption: '',

              id: '3682185c-df99-4a10-b1ab-fc9769edca5a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bdafbcc4-9083-482b-ad61-bddb3d2b320e/ugc/0334724e-68af-42df-92c0-1368db101ca1/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a7b0d6ee-0660-4cad-9018-0f9e83fbdace',
        firstName: 'Chelsea',
        lastName: 'Lafond',
        email: 'lafondchelsea1@gmail.com',
        instagramProfile: {
          handle: 'c_lafonddaa',
          followers: 1190,
          engagementRate: 3.82953181272509
        },
        tiktokProfile: {
          handle: 'chelsealafond',
          followers: 1016,
          engagementRate: 6.690140845070422
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:15:18.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f38b003d-51b0-4746-94e5-564284e5439a',
      content: [
        {
          groupId: '3926f2e5-4c5e-4edb-90a8-2bca5874da6e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-15T17:58:28.248Z',

          createdAt: '2024-06-13T23:17:36.645Z',
          content: [
            {
              caption: '',

              id: '8f323ef0-88a2-4a83-b87e-262a3a5aeba0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f38b003d-51b0-4746-94e5-564284e5439a/ugc/3926f2e5-4c5e-4edb-90a8-2bca5874da6e/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e23e2675-316a-46cd-ac07-a18860710765',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f38b003d-51b0-4746-94e5-564284e5439a/ugc/3926f2e5-4c5e-4edb-90a8-2bca5874da6e/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '99745da8-ed3f-4b15-b7e7-d332f682bdd2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f38b003d-51b0-4746-94e5-564284e5439a/ugc/3926f2e5-4c5e-4edb-90a8-2bca5874da6e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'eee9493b-19d2-43db-b853-234c4160caa2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T19:31:48.453Z',

          createdAt: '2024-06-16T14:10:57.130Z',
          content: [
            {
              caption: '',

              id: '26dd6339-cb11-48d9-8df3-9b405776c29a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f38b003d-51b0-4746-94e5-564284e5439a/ugc/eee9493b-19d2-43db-b853-234c4160caa2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'fe276961-2fc0-4158-bb97-23340235b18d',
        firstName: 'Otosha',
        lastName: 'Buckle',
        email: 'otoshab@gmail.com',

        tiktokProfile: {
          handle: 'tash_d_empress',
          followers: 4869,
          engagementRate: 10.233471160673266
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:24:25.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '22eb784d-cc75-4e36-b8a8-b82618a80f65',
      content: [],
      creator: {
        id: '12ebfed6-e929-4840-b55a-45ee11788d02',
        firstName: 'Renae',
        lastName: 'Hamilton Jackson',
        email: 'renae2danae@gmail.com',
        instagramProfile: {
          handle: 'trendyrenzyo1',
          followers: 101,
          engagementRate: 9.900990099009901
        },
        tiktokProfile: {
          handle: 'renzyisevertrendy',
          followers: 16063,
          engagementRate: 22.619298615503546
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:32:54.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3960c7b3-721b-4348-b3aa-558627a00bf2',
      content: [
        {
          groupId: '25c5f5c7-6cdc-4945-8c59-4354dd4ffc09',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T14:05:06.132Z',

          createdAt: '2024-05-31T21:50:53.111Z',
          content: [
            {
              caption: '',

              id: '4b90a5e7-3b0b-4b5a-90b2-9c16898511d4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3960c7b3-721b-4348-b3aa-558627a00bf2/ugc/25c5f5c7-6cdc-4945-8c59-4354dd4ffc09/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f82c9fea-20c4-4863-9467-17f66e445605',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3960c7b3-721b-4348-b3aa-558627a00bf2/ugc/25c5f5c7-6cdc-4945-8c59-4354dd4ffc09/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a7d4ec23-4042-4eac-9369-d1735e7a942a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3960c7b3-721b-4348-b3aa-558627a00bf2/ugc/25c5f5c7-6cdc-4945-8c59-4354dd4ffc09/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'aab51291-45a6-46fc-95d3-14ea090ed0a6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T05:43:45.695Z',

          createdAt: '2024-05-31T21:50:02.592Z',
          content: [
            {
              caption: '',

              id: 'df72585c-4e56-4081-944a-6104a7e42075',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3960c7b3-721b-4348-b3aa-558627a00bf2/ugc/aab51291-45a6-46fc-95d3-14ea090ed0a6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4cffcf66-925e-4726-9aae-e0339172be9f',
        firstName: 'Nicole',
        lastName: 'Justice',
        email: 'nicolechristinejustice@gmail.com',

        tiktokProfile: {
          handle: 'rootedingraceco',
          followers: 28634,
          engagementRate: 2.697435826433694
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:35:12.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd2985d11-ce7c-454e-8d35-fd368e9b4ced',
      content: [
        {
          groupId: 'dd661734-4a23-4db7-b950-915cfaf7e3f8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:38:40.382Z',

          createdAt: '2024-05-27T22:36:14.722Z',
          content: [
            {
              caption: '',

              id: '29ce82c7-25e6-4826-83ed-3f6d166a056e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d2985d11-ce7c-454e-8d35-fd368e9b4ced/ugc/dd661734-4a23-4db7-b950-915cfaf7e3f8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f489612a-f32f-4c3e-a54a-538e752ab166',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T21:38:43.897Z',

          createdAt: '2024-05-27T22:35:35.038Z',
          content: [
            {
              caption: '',

              id: 'ca828d6e-8804-4412-b6a0-86aca7c8bcac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d2985d11-ce7c-454e-8d35-fd368e9b4ced/ugc/f489612a-f32f-4c3e-a54a-538e752ab166/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a001641d-22a0-4213-85dd-adcc9e999f57',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d2985d11-ce7c-454e-8d35-fd368e9b4ced/ugc/f489612a-f32f-4c3e-a54a-538e752ab166/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '390f76ea-30f7-441b-8831-ec87b4a5e105',
        firstName: 'Kaitlin',
        lastName: 'Holm',
        email: 'kestoudt@gmail.com',
        instagramProfile: {
          handle: 'kaitholm',
          followers: 2835,
          engagementRate: 0.2857142857142857
        },
        tiktokProfile: {
          handle: 'kaitlinholm1',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:41:56.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3ee052be-0f4c-4a19-a55c-507f9a422ead',
      content: [
        {
          groupId: 'e2fba3ac-76b8-4d34-8d28-d290528a3599',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:31:56.963Z',

          createdAt: '2024-05-28T19:37:00.797Z',
          content: [
            {
              caption: '',

              id: '6bf23239-3491-4ca8-85cf-4b8c2f3ec2d2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3ee052be-0f4c-4a19-a55c-507f9a422ead/ugc/e2fba3ac-76b8-4d34-8d28-d290528a3599/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '01bec9a2-b748-4172-9c90-c840fd78f1ce',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:31:53.085Z',

          createdAt: '2024-05-28T19:39:24.617Z',
          content: [
            {
              caption: '',

              id: 'aaa8e55a-555d-4252-808c-bdb42b147d18',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3ee052be-0f4c-4a19-a55c-507f9a422ead/ugc/01bec9a2-b748-4172-9c90-c840fd78f1ce/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cf716565-b355-4843-a1ab-98857ef129f5',
        firstName: 'Taylor',
        lastName: 'Henderson',
        email: 'tayhen@udel.edu',
        instagramProfile: {
          handle: 'tayhendersonn',
          followers: 2397,
          engagementRate: 25.776293823038397
        },
        tiktokProfile: {
          handle: 'tayhendersonn',
          followers: 101
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:41:58.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '66084011-62d4-4468-9f61-7f8a75a39ed2',
      content: [],
      creator: {
        id: '5cb31c93-2edd-4f61-ae9d-3143ffbf51c5',
        firstName: 'Anesia',
        lastName: 'James',
        email: 'jamestamoya16@gmail.com',
        instagramProfile: {
          handle: '_a_nesi_a',
          followers: 1353
        },
        tiktokProfile: {
          handle: '1ne_ci',
          followers: 1069
        },
        youtubeProfile: {
          handle: 'user-xo6fi1pl7v',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:44:25.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '45484930-c5d9-432b-8e8b-ab20472d7a59',
      content: [
        {
          groupId: 'e123210a-4d30-40eb-a467-9328abe5572e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T21:12:01.616Z',

          createdAt: '2024-05-26T21:17:31.158Z',
          content: [
            {
              caption: '',

              id: 'e4828477-90e5-4b12-ba05-4339fef9f356',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/45484930-c5d9-432b-8e8b-ab20472d7a59/ugc/e123210a-4d30-40eb-a467-9328abe5572e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '94f79430-f323-40be-bde8-118ae491c6f9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/45484930-c5d9-432b-8e8b-ab20472d7a59/ugc/e123210a-4d30-40eb-a467-9328abe5572e/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '795370e2-44bb-42a6-8467-c9fbaa07e069',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-27T19:53:12.623Z',

          createdAt: '2024-05-26T22:39:28.446Z',
          content: [
            {
              caption: '',

              id: '29edfee1-d60c-4057-aaf4-c16a841cf794',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/45484930-c5d9-432b-8e8b-ab20472d7a59/ugc/795370e2-44bb-42a6-8467-c9fbaa07e069/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0268bfc8-1879-41dd-b1be-9dedc26bf065',
        firstName: 'Dariya',
        lastName: 'Abdrakhmanova',
        email: 'darij1991@msn.com',
        instagramProfile: {
          handle: 'dariya09',
          followers: 4069,
          engagementRate: 0.4521995576308675
        },
        tiktokProfile: {
          handle: 'dariya1991',
          followers: 457,
          engagementRate: 1.0336961480805578
        },
        youtubeProfile: {
          handle: 'dariyaabdrakhmanova6204',
          followers: 27
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T17:54:53.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c741d21b-e9f1-43d8-b495-54d432b22b45',
      content: [],
      creator: {
        id: '00fd961d-ec01-4a14-ad09-e1186c842f95',
        firstName: 'Jaclyn',
        lastName: 'Santos-Desil',
        email: 'jialove39@gmail.com',
        instagramProfile: {
          handle: 'modeljaclynnichole',
          followers: 17378,
          engagementRate: 1.4489584532167108
        },
        tiktokProfile: {
          handle: 'modeljaclynnichole',
          followers: 439,
          engagementRate: 26.38888888888889
        },
        youtubeProfile: {
          handle: 'jaclynsantos7454',
          followers: 6
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:02:46.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6d1ae57a-b3e3-46ea-9535-69269d00695b',
      content: [
        {
          groupId: 'f1ce20ef-a3fc-4bbf-9958-ec812147f418',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-27T14:16:42.655Z',

          createdAt: '2024-05-25T19:39:51.344Z',
          content: [
            {
              caption: '',

              id: 'ecca4042-bf2c-4d55-89ae-00c0b50f1f23',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6d1ae57a-b3e3-46ea-9535-69269d00695b/ugc/f1ce20ef-a3fc-4bbf-9958-ec812147f418/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '99ed7644-bc6f-42c4-acfc-4fb4a208d9af',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-27T12:16:56.334Z',

          createdAt: '2024-05-25T19:41:19.751Z',
          content: [
            {
              caption: '',

              id: '6eef9507-c74e-4c04-a88c-e860d6b8d91f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6d1ae57a-b3e3-46ea-9535-69269d00695b/ugc/99ed7644-bc6f-42c4-acfc-4fb4a208d9af/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '30027d67-cfeb-49a5-b962-7eb4592fe4ce',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6d1ae57a-b3e3-46ea-9535-69269d00695b/ugc/99ed7644-bc6f-42c4-acfc-4fb4a208d9af/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '74173647-2b2f-4703-a734-1a116103f22f',
        firstName: 'Anna',
        lastName: 'Abshire',
        email: 'annaabshireyl@gmail.com',
        instagramProfile: {
          handle: 'aromayogamama',
          followers: 1780,
          engagementRate: 0.2134831460674157
        },

        youtubeProfile: {
          handle: 'annaabshirewithselfcareyoga'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:04:15.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a8cd763b-aa13-4c3d-962d-57cdf517d03a',
      content: [
        {
          groupId: '3ef94a2f-48ad-4f03-bba4-11bae54a18e2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-25T16:06:27.875Z',

          createdAt: '2024-07-24T13:04:17.845Z',
          content: [
            {
              caption: '',

              id: 'c9b50cea-e7ee-493d-ad37-250addf50682',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8cd763b-aa13-4c3d-962d-57cdf517d03a/ugc/3ef94a2f-48ad-4f03-bba4-11bae54a18e2/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c2d8ab2c-f3e4-4e60-83c0-e1e3ab6b054e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8cd763b-aa13-4c3d-962d-57cdf517d03a/ugc/3ef94a2f-48ad-4f03-bba4-11bae54a18e2/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2e8fd33a-0032-4734-9490-931bdc88fbb4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8cd763b-aa13-4c3d-962d-57cdf517d03a/ugc/3ef94a2f-48ad-4f03-bba4-11bae54a18e2/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '6eb4bc22-34ae-415a-b3ac-d6a699cf7512',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-30T12:00:56.417Z',

          createdAt: '2024-07-26T17:40:50.743Z',
          content: [
            {
              caption: '',

              id: 'f04b9349-df6b-4053-a684-2f655dc179a1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8cd763b-aa13-4c3d-962d-57cdf517d03a/ugc/6eb4bc22-34ae-415a-b3ac-d6a699cf7512/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b6b7526c-a4ca-45cf-924d-824d0690ff68',
        firstName: 'Maria',
        lastName: 'Lins',
        email: 'majulins.ugc@gmail.com',
        instagramProfile: {
          handle: 'juliajatoba',
          followers: 1195,
          engagementRate: 35.12970711297071
        },
        tiktokProfile: {
          handle: 'majuxlins',
          followers: 6801,
          engagementRate: 4.923076923076923
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:04:57.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '72e728eb-a856-4587-9210-e43f63ac7570',
      content: [
        {
          groupId: '8f6bbfde-50fa-4d42-8a20-1d1f51a53dcb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T02:12:34.130Z',

          createdAt: '2024-05-31T11:11:12.570Z',
          content: [
            {
              caption: '',

              id: '9c17dbee-1c0e-4be8-aca9-840cf72bc217',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72e728eb-a856-4587-9210-e43f63ac7570/ugc/8f6bbfde-50fa-4d42-8a20-1d1f51a53dcb/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7829e609-9c77-4245-9e23-64dd907e0056',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72e728eb-a856-4587-9210-e43f63ac7570/ugc/8f6bbfde-50fa-4d42-8a20-1d1f51a53dcb/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ec0e16d6-850e-457d-a4b0-27de5aa3e991',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T02:12:39.025Z',

          createdAt: '2024-05-31T11:07:08.433Z',
          content: [
            {
              caption: '',

              id: '453ff028-43cb-43b8-b138-f30a2c6279f9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72e728eb-a856-4587-9210-e43f63ac7570/ugc/ec0e16d6-850e-457d-a4b0-27de5aa3e991/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7837c274-ac82-4215-af1c-d6b7969ee143',
        firstName: 'Amanda',
        lastName: 'VanHorn',
        email: 'amanda.lauren.barnes@gmail.com',

        tiktokProfile: {
          handle: 'amandaa_vnhrn',
          followers: 1157,
          engagementRate: 7.5596049046321525
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:15:01.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1236e402-0f2f-4ca1-9ed0-c6130e2f3e52',
      content: [],
      creator: {
        id: '218d6743-79a0-4e95-98c2-51bf47eaba7d',
        firstName: 'Cleverly Chloé',
        lastName: 'Marcano',
        email: 'cleverlychloe@gmail.com',
        instagramProfile: {
          handle: 'cleverlychloe',
          followers: 10642,
          engagementRate: 1.4376996805111821
        },
        tiktokProfile: {
          handle: 'cleverlychloe',
          followers: 57538,
          engagementRate: 12.189859762675297
        },
        youtubeProfile: {
          handle: 'cleverlychloe'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:24:57.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4018b27d-7010-46a7-8942-a9de6e833101',
      content: [
        {
          groupId: 'c872c305-59cb-4821-8f3a-27793e866926',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T18:15:35.595Z',

          createdAt: '2024-05-29T23:58:17.092Z',
          content: [
            {
              caption: '',

              id: '17c61397-5988-4797-880a-58142c5422c0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4018b27d-7010-46a7-8942-a9de6e833101/ugc/c872c305-59cb-4821-8f3a-27793e866926/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8c0c6e86-99b8-4253-b4a2-3bdcfa55d785',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4018b27d-7010-46a7-8942-a9de6e833101/ugc/c872c305-59cb-4821-8f3a-27793e866926/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'be3b4193-5634-4e20-981b-2d6163268557',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4018b27d-7010-46a7-8942-a9de6e833101/ugc/c872c305-59cb-4821-8f3a-27793e866926/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9a1382cd-79b6-422d-a969-bd1d4d81ae19',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T18:15:25.176Z',

          createdAt: '2024-05-29T23:53:42.909Z',
          content: [
            {
              caption: '',

              id: 'c06bd80e-ae99-4504-b8ba-ea73042e61a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4018b27d-7010-46a7-8942-a9de6e833101/ugc/9a1382cd-79b6-422d-a969-bd1d4d81ae19/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7500d92e-e529-4cc9-9b26-a9169c373ad3',
        firstName: 'Adela',
        lastName: 'Sasu',
        email: 'adelasasucreations@gmail.com',
        instagramProfile: {
          handle: 'ugcwithadela',
          followers: 1395,
          engagementRate: 1.4408602150537635
        },
        tiktokProfile: {
          handle: 'adela_sasu',
          followers: 1829,
          engagementRate: 5.758082206089727
        },
        youtubeProfile: {
          handle: 'adelasasu',
          followers: 714
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:24:57.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '42709399-432b-4886-978a-dd48cbed95b0',
      content: [
        {
          groupId: '905f226c-5cb5-4c1d-9238-fa0571dd8319',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-27T16:08:54.109Z',

          createdAt: '2024-05-27T05:57:32.788Z',
          content: [
            {
              caption: '',

              id: '44730b2d-b827-46e4-b38d-cc67bf6d5d43',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/42709399-432b-4886-978a-dd48cbed95b0/ugc/905f226c-5cb5-4c1d-9238-fa0571dd8319/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3db7dc16-6166-40d3-813d-82009e5c7eef',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-27T16:09:03.907Z',

          createdAt: '2024-05-27T06:48:21.892Z',
          content: [
            {
              caption: '',

              id: '44a4578d-9585-462e-afeb-3cccf6010331',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/42709399-432b-4886-978a-dd48cbed95b0/ugc/3db7dc16-6166-40d3-813d-82009e5c7eef/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bca1c2e8-84d7-4cce-be57-d2b16b2113ec',
        firstName: 'Marbella',
        lastName: 'Gonzalez',
        email: 'mecaroboa@gmail.com',
        instagramProfile: {
          handle: 'dreamsy66',
          followers: 3576,
          engagementRate: 4.890939597315437
        },
        tiktokProfile: {
          handle: '66sweetdreamsy',
          followers: 125,
          engagementRate: 3.0522543494241603
        },
        youtubeProfile: {
          handle: 'ma11spons',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:26:28.000Z',
        amountCents: 8865
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '11b01d2d-e7bc-4993-a8ce-e3170894ca12',
      content: [
        {
          groupId: '0f9b7f36-b155-44dd-ade0-c27a8b594192',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T14:23:06.186Z',

          createdAt: '2024-06-04T02:05:32.358Z',
          content: [
            {
              caption: '',

              id: '2896bc04-dd62-45e7-8522-9c97edc38ad7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/11b01d2d-e7bc-4993-a8ce-e3170894ca12/ugc/0f9b7f36-b155-44dd-ade0-c27a8b594192/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4d99f0ca-cf5c-42fb-b2bc-c97c89e0f526',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/11b01d2d-e7bc-4993-a8ce-e3170894ca12/ugc/0f9b7f36-b155-44dd-ade0-c27a8b594192/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd4bb5416-4a23-40f4-b1bf-335ae1c4f616',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T19:24:18.401Z',

          createdAt: '2024-06-04T02:04:27.587Z',
          content: [
            {
              caption: '',

              id: 'f1162aa2-a490-4f07-9dca-e0217d374445',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/11b01d2d-e7bc-4993-a8ce-e3170894ca12/ugc/d4bb5416-4a23-40f4-b1bf-335ae1c4f616/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2ad0b4d8-c0ef-477d-99b0-dbf986cb217b',
        firstName: 'Kelli',
        lastName: 'Hines',
        email: 'khines613@yahoo.com',
        instagramProfile: {
          handle: 'tkofitnesscouple',
          followers: 8204,
          engagementRate: 4.05
        },
        tiktokProfile: {
          handle: 'kelli_hines',
          followers: 5057,
          engagementRate: 14.779423018059381
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:35:23.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '062dd44a-33d3-4481-82bd-58f532aa742f',
      content: [
        {
          groupId: '5e525cef-483a-4634-a07f-e35ddb5a12aa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T14:31:32.202Z',

          createdAt: '2024-06-01T01:32:30.095Z',
          content: [
            {
              caption: '',

              id: '1bec32bb-859f-42aa-8ba2-aaf48a793960',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/062dd44a-33d3-4481-82bd-58f532aa742f/ugc/5e525cef-483a-4634-a07f-e35ddb5a12aa/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0c7193a6-69fe-4826-9755-51949bc195f9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/062dd44a-33d3-4481-82bd-58f532aa742f/ugc/5e525cef-483a-4634-a07f-e35ddb5a12aa/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e1dc1b42-146a-487e-b7c3-c51c442e7edf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/062dd44a-33d3-4481-82bd-58f532aa742f/ugc/5e525cef-483a-4634-a07f-e35ddb5a12aa/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'baa75016-a1f1-4631-bfc3-0fb8415c9c00',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T13:16:16.895Z',

          createdAt: '2024-06-01T01:34:36.751Z',
          content: [
            {
              caption: '',

              id: '25e24982-325b-4526-bf3e-7065fbdffb3e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/062dd44a-33d3-4481-82bd-58f532aa742f/ugc/baa75016-a1f1-4631-bfc3-0fb8415c9c00/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a739b40d-b719-48f9-8a8d-4d591b7d2731',
        firstName: 'Megan',
        lastName: 'Syed',
        email: 'meganlsyed@gmail.com',
        instagramProfile: {
          handle: 'enlighteningells',
          followers: 49670,
          engagementRate: 0.25931145560700625
        },
        tiktokProfile: {
          handle: 'enlighteningells',
          followers: 22
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:45:17.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '52faee02-e977-42cf-a38b-9bb843220453',
      content: [
        {
          groupId: '50174ebc-2872-40e9-898f-ca2bc778db2b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T02:32:48.204Z',

          createdAt: '2024-05-30T19:52:59.683Z',
          content: [
            {
              caption: '',

              id: 'b6d94651-c5ce-4504-9820-3af447ff548b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/52faee02-e977-42cf-a38b-9bb843220453/ugc/50174ebc-2872-40e9-898f-ca2bc778db2b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bf86e67d-9a44-4009-b985-ec69b4f1e70f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-01T20:48:06.675Z',

          createdAt: '2024-05-30T19:54:46.086Z',
          content: [
            {
              caption: '',

              id: '75878650-4101-4b2a-885e-1854f55dcc3f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/52faee02-e977-42cf-a38b-9bb843220453/ugc/bf86e67d-9a44-4009-b985-ec69b4f1e70f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b862b2b0-497c-4b52-95b2-361cb36db2f9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/52faee02-e977-42cf-a38b-9bb843220453/ugc/bf86e67d-9a44-4009-b985-ec69b4f1e70f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '876d63d5-7dfd-4688-88ce-485721f16012',
        firstName: 'Rachel',
        lastName: 'Crosson',
        email: 'itsmailtime0@yahoo.com',

        tiktokProfile: {
          handle: 'itsmailtime',
          followers: 9469,
          engagementRate: 1.8062607776253352
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:46:36.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '90d9cd24-dc3d-409b-aa90-fe6781bab878',
      content: [
        {
          groupId: 'bcaffbf9-98bd-4be4-8589-6f331779bb8e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-01T00:53:28.975Z',

          createdAt: '2024-05-28T03:14:59.328Z',
          content: [
            {
              caption: '',

              id: '8b60f715-d444-4e9a-a2c4-1de27103c615',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/90d9cd24-dc3d-409b-aa90-fe6781bab878/ugc/bcaffbf9-98bd-4be4-8589-6f331779bb8e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd4a9542c-c86f-459b-8654-1e1d18e36a85',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/90d9cd24-dc3d-409b-aa90-fe6781bab878/ugc/bcaffbf9-98bd-4be4-8589-6f331779bb8e/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '1fec8dfa-70bf-4779-a2a8-cb0a6029bd62',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-01T00:53:20.670Z',

          createdAt: '2024-05-28T02:46:47.567Z',
          content: [
            {
              caption: '',

              id: '2133b124-2015-4717-ad9a-9066a7c501e0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/90d9cd24-dc3d-409b-aa90-fe6781bab878/ugc/1fec8dfa-70bf-4779-a2a8-cb0a6029bd62/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5f81a6f7-73d4-4330-9098-080143341c47',
        firstName: 'Kiki',
        lastName: 'Demay',
        email: 'kikidemay@gmail.com',
        instagramProfile: {
          handle: 'kiki_758',
          followers: 2006,
          engagementRate: 1.181455633100698
        },
        tiktokProfile: {
          handle: 'kikid_758',
          followers: 259,
          engagementRate: 4.956268221574344
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:52:05.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '50b56789-3dc0-46f5-b63c-f97d24264065',
      content: [],
      creator: {
        id: '96d8d9a4-aec9-4b17-a1f5-3774d0933442',
        firstName: 'Kayla',
        lastName: 'Evans',
        email: 'thelighthousechapters@gmail.com',
        instagramProfile: {
          handle: 'lifeandlabnotes',
          followers: 2422,
          engagementRate: 0.37159372419488024
        },
        tiktokProfile: {
          handle: 'lifeandlabnotes',
          followers: 24,
          engagementRate: 0.06443298969072164
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:54:03.000Z',
        amountCents: 7349
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e17203db-db24-48e8-ae9a-b10423e4c674',
      content: [
        {
          groupId: '393904d7-82f8-424d-859e-b55c77a55b4b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:22:05.308Z',

          createdAt: '2024-06-03T21:38:34.620Z',
          content: [
            {
              caption: '',

              id: 'bf27979b-f908-415b-9b7e-febf4e1c0e4c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e17203db-db24-48e8-ae9a-b10423e4c674/ugc/393904d7-82f8-424d-859e-b55c77a55b4b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3dccc9f4-6e3c-4399-9cd7-98881484972c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T18:22:10.728Z',

          createdAt: '2024-06-03T20:02:17.526Z',
          content: [
            {
              caption: '',

              id: '75c8ce13-4c84-4e4d-9ec0-65e4509e1c0f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e17203db-db24-48e8-ae9a-b10423e4c674/ugc/3dccc9f4-6e3c-4399-9cd7-98881484972c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '54f08cfb-976d-4024-bebc-8017887bc27f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e17203db-db24-48e8-ae9a-b10423e4c674/ugc/3dccc9f4-6e3c-4399-9cd7-98881484972c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7f80070c-967d-4714-bbf0-818a85d84d76',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e17203db-db24-48e8-ae9a-b10423e4c674/ugc/3dccc9f4-6e3c-4399-9cd7-98881484972c/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2a90e9ce-58dc-4499-aa65-93107b9c18b5',
        firstName: 'Marionette',
        lastName: 'Solorio',
        email: 'ladyshulammite@gmail.com',
        instagramProfile: {
          handle: 'thisismarionette',
          followers: 10364,
          engagementRate: 0.17367811655731377
        },
        tiktokProfile: {
          handle: 'ladyshulammite016',
          followers: 684,
          engagementRate: 4.503105590062112
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:54:14.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a3c75ac4-5c61-4f4a-9c5d-7172239af12c',
      content: [
        {
          groupId: '4ca8dd3d-b680-4e11-8bb1-e047f5689cb4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T23:28:03.375Z',

          createdAt: '2024-05-27T14:56:49.148Z',
          content: [
            {
              caption: '',

              id: '92297919-6195-4e34-a04d-cb38985aa3b7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a3c75ac4-5c61-4f4a-9c5d-7172239af12c/ugc/4ca8dd3d-b680-4e11-8bb1-e047f5689cb4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fbd00e53-4712-42ce-b55e-2d4223321424',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T20:24:31.763Z',

          createdAt: '2024-05-27T14:50:58.410Z',
          content: [
            {
              caption: '',

              id: 'bab81c47-0afa-4978-8b55-9b600601c411',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a3c75ac4-5c61-4f4a-9c5d-7172239af12c/ugc/fbd00e53-4712-42ce-b55e-2d4223321424/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0a8ec660-7314-4515-91bc-4f4cc3f6bd48',
        firstName: 'Mackenzie',
        lastName: 'Marshall',
        email: 'jadloskim@yahoo.com',
        instagramProfile: {
          handle: 'kenziemarshall__',
          followers: 133
        },
        tiktokProfile: {
          handle: 'mackenzie.marshall_',
          followers: 5728,
          engagementRate: 4.392760901518863
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T18:55:16.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3688f7d4-e754-4feb-af13-2600302ef003',
      content: [
        {
          groupId: '2a6c047d-3e90-45c4-a255-a2e038f3a1ec',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T13:39:20.068Z',

          createdAt: '2024-06-01T22:45:32.399Z',
          content: [
            {
              caption: '',

              id: 'd9aa0a92-e120-4a15-a50b-7657308a43c5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3688f7d4-e754-4feb-af13-2600302ef003/ugc/2a6c047d-3e90-45c4-a255-a2e038f3a1ec/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '730df514-8717-4fac-b9c1-e504321ca2ba',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T13:39:28.208Z',

          createdAt: '2024-06-06T02:53:38.641Z',
          content: [
            {
              caption: '',

              id: 'e5feb58e-2edc-4875-a15e-59374409de48',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3688f7d4-e754-4feb-af13-2600302ef003/ugc/730df514-8717-4fac-b9c1-e504321ca2ba/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0446a378-1892-4bd8-aa72-15803b035f1e',
        firstName: 'Antonia',
        lastName: 'Mason',
        email: 'toniannmason1@icloud.com',
        instagramProfile: {
          handle: 'papermoonarts.co',
          followers: 1701,
          engagementRate: 1.1757789535567313
        },
        tiktokProfile: {
          handle: 'antoniaam__',
          followers: 1202,
          engagementRate: 9.73871733966746
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:04:30.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2d16ac8c-aae9-4035-a06f-05183558ca58',
      content: [],
      creator: {
        id: '095c7bb2-6f14-439d-8164-fdc7a1a42d06',
        firstName: 'joanne',
        lastName: 'romarate',
        email: 'joannekrystelle@gmail.com',
        instagramProfile: {
          handle: 'joannekdeleon',
          followers: 2759
        },
        tiktokProfile: {
          handle: 'joannekdeleon',
          followers: 36428,
          engagementRate: 1.9879620055644607
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:06:38.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4b4aecbb-da92-47cb-a0bc-df99c64b443c',
      content: [],
      creator: {
        id: 'dda2e9ba-6688-480a-8559-938dd4701628',
        firstName: 'Malani',
        lastName: 'Smolinsky',
        email: 'mesmolinsky@gmail.com',
        instagramProfile: {
          handle: 'strawberyc0ke',
          followers: 522,
          engagementRate: 2.5830258302583027
        },
        tiktokProfile: {
          handle: 'strawberyc0ke',
          followers: 1233,
          engagementRate: 21.156558533145276
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:12:57.000Z',
        amountCents: 8135
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3d694479-d772-47ba-b0be-8a6d367bd68b',
      content: [
        {
          groupId: '054b8e5a-8595-486d-ae75-9de41bc47776',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T14:47:17.328Z',

          createdAt: '2024-06-02T00:19:00.302Z',
          content: [
            {
              caption: '',

              id: '728d28cf-f2db-4626-a4ee-f58bb05139de',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3d694479-d772-47ba-b0be-8a6d367bd68b/ugc/054b8e5a-8595-486d-ae75-9de41bc47776/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0cdd7f47-e0e6-4e1a-b2de-0d9de1eb6895',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3d694479-d772-47ba-b0be-8a6d367bd68b/ugc/054b8e5a-8595-486d-ae75-9de41bc47776/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '12acd308-b34d-4f3b-8cca-21265a388642',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3d694479-d772-47ba-b0be-8a6d367bd68b/ugc/054b8e5a-8595-486d-ae75-9de41bc47776/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5d1f2fb1-d5c8-4f02-98c9-394d4181fd4c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T19:08:32.713Z',

          createdAt: '2024-06-02T00:19:32.823Z',
          content: [
            {
              caption: '',

              id: '5edb9232-ddcb-408b-b9b2-afaa20c36757',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3d694479-d772-47ba-b0be-8a6d367bd68b/ugc/5d1f2fb1-d5c8-4f02-98c9-394d4181fd4c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4f4135e7-9f04-4c72-b4d6-904ee36405d4',
        firstName: 'Reena',
        lastName: 'Patel',
        email: 'reena.dhansukh.patel@gmail.com',
        instagramProfile: {
          handle: 'historiadereina',
          followers: 1371,
          engagementRate: 5.4808794414921325
        },
        tiktokProfile: {
          handle: 'rolltide___',
          followers: 23932,
          engagementRate: 14.021571648690292
        },
        youtubeProfile: {
          handle: 'rolltide___',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:13:42.000Z',
        amountCents: 8638
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '12d76f0a-4628-47b6-baae-c89af178d149',
      content: [
        {
          groupId: '31a59741-5351-4283-a188-535a8756b18d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T08:35:17.995Z',

          createdAt: '2024-06-02T23:17:39.574Z',
          content: [
            {
              caption: '',

              id: '64ee04f6-71c1-49ee-a41d-ad6b6ade58ea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/12d76f0a-4628-47b6-baae-c89af178d149/ugc/31a59741-5351-4283-a188-535a8756b18d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '01630ee5-987a-4215-b469-a54b430ff19d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/12d76f0a-4628-47b6-baae-c89af178d149/ugc/31a59741-5351-4283-a188-535a8756b18d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6237081f-e49b-4480-8244-d40d153f4dd3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/12d76f0a-4628-47b6-baae-c89af178d149/ugc/31a59741-5351-4283-a188-535a8756b18d/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '63752439-54db-4c9a-a26c-691a03593c91',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:04:47.842Z',

          createdAt: '2024-06-02T23:16:33.424Z',
          content: [
            {
              caption: '',

              id: '74838729-1e1e-43d4-866b-092114e644f2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/12d76f0a-4628-47b6-baae-c89af178d149/ugc/63752439-54db-4c9a-a26c-691a03593c91/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ac31f6b1-fa3b-4e9f-8ea1-67dc212896f2',
        firstName: 'Camille',
        lastName: 'McDonald',
        email: 'camille_1978@icloud.com',
        instagramProfile: {
          handle: 'cammie1978',
          followers: 3893,
          engagementRate: 1.3588492165425121
        },
        tiktokProfile: {
          handle: 'camcam1978',
          followers: 1461,
          engagementRate: 16.85628047364755
        },
        youtubeProfile: {
          handle: 'camillemcdonald2199',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:14:59.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3b9d95ca-afda-4a84-93b7-8980259f4fb1',
      content: [
        {
          groupId: '4638cca0-6b74-4ca1-9a1a-053bb266e21f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:44:51.494Z',

          createdAt: '2024-06-03T21:52:06.328Z',
          content: [
            {
              caption: '',

              id: 'cd52d5ff-c326-4dce-8666-4514a61ab3b4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3b9d95ca-afda-4a84-93b7-8980259f4fb1/ugc/4638cca0-6b74-4ca1-9a1a-053bb266e21f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c7235d7e-f118-4e24-af4a-b2eed6b55752',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-05T03:53:02.691Z',

          createdAt: '2024-06-03T21:31:33.131Z',
          content: [
            {
              caption: '',

              id: '291365d9-c267-4441-9681-a2ed28fc1e31',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3b9d95ca-afda-4a84-93b7-8980259f4fb1/ugc/c7235d7e-f118-4e24-af4a-b2eed6b55752/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7a3814f2-4a11-450e-b866-c5e099adea62',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3b9d95ca-afda-4a84-93b7-8980259f4fb1/ugc/c7235d7e-f118-4e24-af4a-b2eed6b55752/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c3422a5e-1d75-443a-84d5-a908eb11914f',
        firstName: 'Hannah',
        lastName: 'Rinehart',
        email: 'hannahrinehart20@gmail.com',
        instagramProfile: {
          handle: 'infl_uensterhann',
          followers: 2681,
          engagementRate: 5.781424841477061
        },
        tiktokProfile: {
          handle: 'hannahs.records',
          followers: 5386,
          engagementRate: 6.763450554893432
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:15:21.000Z',
        amountCents: 8160
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f273242e-90ed-4380-8837-a15c10c0a3d5',
      content: [
        {
          groupId: '1450faf9-ea97-4130-a986-acbb59c8c903',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:43:15.219Z',

          createdAt: '2024-06-02T01:55:47.445Z',
          content: [
            {
              caption: '',

              id: 'e72184bb-4cec-48de-9a46-3ee1d9698ea6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f273242e-90ed-4380-8837-a15c10c0a3d5/ugc/1450faf9-ea97-4130-a986-acbb59c8c903/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4033e5db-9d80-44a4-b9c1-7c5298dcda73',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:43:09.074Z',

          createdAt: '2024-06-02T01:55:08.869Z',
          content: [
            {
              caption: '',

              id: 'b76ddfcb-f35f-4235-ba5b-ce74a00b3ebd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f273242e-90ed-4380-8837-a15c10c0a3d5/ugc/4033e5db-9d80-44a4-b9c1-7c5298dcda73/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '695cde77-fdaf-4da8-a7e0-03672519f8b4',
        firstName: 'Gulce',
        lastName: 'Sinik',
        email: 'gulcegokce82@hotmail.com',
        instagramProfile: {
          handle: 'gulcesnk',
          followers: 83120,
          engagementRate: 5.279355149181905
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:34:35.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '70906842-e177-4240-9cd1-85d30ae599a0',
      content: [
        {
          groupId: '6555dfbd-2b0f-4560-bd29-c50f153ec66c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T06:37:15.123Z',

          createdAt: '2024-05-28T16:02:39.708Z',
          content: [
            {
              caption: '',

              id: '118c3757-fb1a-4937-9725-ad46c8e095e3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/70906842-e177-4240-9cd1-85d30ae599a0/ugc/6555dfbd-2b0f-4560-bd29-c50f153ec66c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd186ec70-09c0-4b4b-907b-95acd592b722',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/70906842-e177-4240-9cd1-85d30ae599a0/ugc/6555dfbd-2b0f-4560-bd29-c50f153ec66c/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4215a5f9-d3c7-4743-be16-a71c75e5d380',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T06:37:07.558Z',

          createdAt: '2024-05-28T16:02:14.014Z',
          content: [
            {
              caption: '',

              id: '39f455ab-d00a-43de-9f62-aa6066a3b2dc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/70906842-e177-4240-9cd1-85d30ae599a0/ugc/4215a5f9-d3c7-4743-be16-a71c75e5d380/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0ed2eee0-8152-4bcc-8f20-07a2ced92c52',
        firstName: 'Gokce',
        lastName: 'Bener',
        email: 'gokcesjournal@gmail.com',
        instagramProfile: {
          handle: 'goksjournal',
          followers: 101685,
          engagementRate: 2.4947632394158434
        },
        tiktokProfile: {
          handle: 'gokcesjournal',
          followers: 15123,
          engagementRate: 0.3170674262646808
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:34:35.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5f9df3ef-b359-49e2-8f7c-652bd94199ef',
      content: [
        {
          groupId: 'f109b10a-c66d-4a7b-9e26-3c3ebcde1fef',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:27:29.148Z',

          createdAt: '2024-05-29T00:57:00.530Z',
          content: [
            {
              caption: '',

              id: 'd79762b8-6024-47c4-b46d-e8ce8f5b6cd3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5f9df3ef-b359-49e2-8f7c-652bd94199ef/ugc/f109b10a-c66d-4a7b-9e26-3c3ebcde1fef/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8f54e329-9fa0-4d57-b86b-970b0e914a89',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5f9df3ef-b359-49e2-8f7c-652bd94199ef/ugc/f109b10a-c66d-4a7b-9e26-3c3ebcde1fef/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '597d869f-df54-48a5-af71-20a3662fb012',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:27:25.457Z',

          createdAt: '2024-05-29T00:56:30.191Z',
          content: [
            {
              caption: '',

              id: 'c2e63639-d4a5-4363-8929-45f3749236f9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5f9df3ef-b359-49e2-8f7c-652bd94199ef/ugc/597d869f-df54-48a5-af71-20a3662fb012/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a5c80271-c922-45cb-a621-aa53d15a22af',
        firstName: 'Marissa',
        lastName: 'Brogan',
        email: 'marissa.brogan@gmail.com',
        instagramProfile: {
          handle: 'marissa_brogan',
          followers: 2650,
          engagementRate: 0.4867924528301887
        },
        tiktokProfile: {
          handle: 'marissabrogan',
          followers: 3379,
          engagementRate: 6.7189965561327325
        },
        youtubeProfile: {
          handle: 'marissabrogan'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:35:00.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c3525852-1585-4df2-ab1c-b1180c2381c0',
      content: [],
      creator: {
        id: '6d4d9d33-6e93-4c57-bb0b-fcb86909ce8b',
        firstName: 'Tokyo',
        lastName: 'Jackson',
        email: 'cathackley@gmail.com',

        tiktokProfile: {
          handle: 'pwppydollz',
          followers: 1394,
          engagementRate: 11.653116531165312
        },
        youtubeProfile: {
          handle: 'tkwildn',
          followers: 524
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:42:06.000Z',
        amountCents: 8160
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7d1cab35-5546-4099-8650-a55bb029187f',
      content: [],
      creator: {
        id: '4526fadc-af1e-4221-a701-a4e066314069',
        firstName: 'Kellie',
        lastName: 'Newman',
        email: 'kellienewman01@gmail.com',
        instagramProfile: {
          handle: 'kellie.n',
          followers: 3276,
          engagementRate: 3.286528286528287
        },
        tiktokProfile: {
          handle: 'ajduuxusbwhskxh',
          followers: 61,
          engagementRate: 0.7486631016042781
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:42:46.000Z',
        amountCents: 9090
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '38365016-a1e7-4f6b-8ce2-12e4996d9ebe',
      content: [
        {
          groupId: 'a4127531-f443-4e94-8093-485b8bc50fe7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T11:58:45.869Z',

          createdAt: '2024-05-29T04:29:15.890Z',
          content: [
            {
              caption: '',

              id: '1f915b45-0ba9-404a-8da1-d17ca83b01fe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38365016-a1e7-4f6b-8ce2-12e4996d9ebe/ugc/a4127531-f443-4e94-8093-485b8bc50fe7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2aa05571-36c4-4200-aa2e-ac902a9fd22f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T11:58:38.815Z',

          createdAt: '2024-05-29T04:27:28.709Z',
          content: [
            {
              caption: '',

              id: '8855addb-46a0-4883-90a0-0ff1d91a3470',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38365016-a1e7-4f6b-8ce2-12e4996d9ebe/ugc/2aa05571-36c4-4200-aa2e-ac902a9fd22f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8302721b-c800-452e-89a6-1b2fa80f0524',
        firstName: 'Alyssa',
        lastName: 'Fobb',
        email: 'alyssafobb@gmail.com',
        instagramProfile: {
          handle: '2k_luxeco',
          followers: 3735,
          engagementRate: 0.5167336010709506
        },
        tiktokProfile: {
          handle: '2k_luxeco',
          followers: 645,
          engagementRate: 7.1790635151786715
        },
        youtubeProfile: {
          handle: '2kluxeco'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:45:46.000Z',
        amountCents: 3548
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b13de92f-e922-4884-8528-a982e6154bf1',
      content: [
        {
          groupId: 'aaffc8f7-7321-4c80-acf9-cf59a4d1362e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T17:35:42.327Z',

          createdAt: '2024-05-31T22:13:12.430Z',
          content: [
            {
              caption: '',

              id: '8570c9ca-01b8-4aae-b623-a3ed0b8ac134',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b13de92f-e922-4884-8528-a982e6154bf1/ugc/aaffc8f7-7321-4c80-acf9-cf59a4d1362e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f117d056-f359-4f51-931c-ec1b7de06a6c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T20:20:25.526Z',

          createdAt: '2024-05-31T22:12:02.093Z',
          content: [
            {
              caption: '',

              id: 'c420d89c-c1b6-4ed3-9fea-0956f85e6a3a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b13de92f-e922-4884-8528-a982e6154bf1/ugc/f117d056-f359-4f51-931c-ec1b7de06a6c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0cdaf806-c0e7-477b-9c54-70ab86293dce',
        firstName: 'Sheryl',
        lastName: 'Anderson',
        email: 'beautybysheryl@gmail.com',
        instagramProfile: {
          handle: 'beautybysheryl',
          followers: 1500,
          engagementRate: 2.2266666666666666
        },
        tiktokProfile: {
          handle: 'beautybysheryl',
          followers: 218,
          engagementRate: 4.301075268817205
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:46:02.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6402c223-65d1-48db-bab3-2cd07bddc07e',
      content: [
        {
          groupId: '7586a0f9-7afc-4e11-a591-4381e93af46c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T16:09:40.655Z',

          createdAt: '2024-05-28T01:24:21.410Z',
          content: [
            {
              caption: '',

              id: 'c2bf6281-a708-4672-b905-86f03f04265a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6402c223-65d1-48db-bab3-2cd07bddc07e/ugc/7586a0f9-7afc-4e11-a591-4381e93af46c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2f937a62-c458-4e47-8da4-77697b47bfbe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6402c223-65d1-48db-bab3-2cd07bddc07e/ugc/7586a0f9-7afc-4e11-a591-4381e93af46c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0a49fa8e-c89f-4a57-9ff7-5f58926364a7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6402c223-65d1-48db-bab3-2cd07bddc07e/ugc/7586a0f9-7afc-4e11-a591-4381e93af46c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '1fff4fb8-4c4c-4148-b2a9-98b111ad9229',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T16:09:35.340Z',

          createdAt: '2024-05-28T01:22:19.379Z',
          content: [
            {
              caption: '',

              id: '564a8da4-e791-423a-81f4-edc1a42b4e9d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6402c223-65d1-48db-bab3-2cd07bddc07e/ugc/1fff4fb8-4c4c-4148-b2a9-98b111ad9229/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'dc6cd3c7-ec1f-4b2c-beab-cd68e592ddd8',
        firstName: 'Kami',
        lastName: 'Fisher',
        email: 'kamifisher246@gmail.com',
        instagramProfile: {
          handle: 'lifeof.kami',
          followers: 460,
          engagementRate: 4.130434782608695
        },
        tiktokProfile: {
          handle: 'lifeof.kami',
          followers: 11028,
          engagementRate: 10.211319125898937
        },
        youtubeProfile: {
          handle: 'kamifisher7338',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T19:54:00.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cb223dc6-9861-4b78-abef-3841f711a050',
      content: [
        {
          groupId: '9b935c75-5ed1-405c-ae92-3de8e1431a2f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:27:46.597Z',

          createdAt: '2024-06-03T18:43:38.927Z',
          content: [
            {
              caption: '',

              id: '8f9b4a12-ada9-423b-b3d5-0032cdef8863',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cb223dc6-9861-4b78-abef-3841f711a050/ugc/9b935c75-5ed1-405c-ae92-3de8e1431a2f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd24e92d0-9f05-4845-8ba9-574f04876679',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T12:32:13.918Z',

          createdAt: '2024-06-03T18:44:03.125Z',
          content: [
            {
              caption: '',

              id: 'ac0dd790-85fb-49ab-88b2-bed7509726f1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cb223dc6-9861-4b78-abef-3841f711a050/ugc/d24e92d0-9f05-4845-8ba9-574f04876679/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e1c0a71b-908c-467d-8589-3e8733294547',
        firstName: 'Heather',
        lastName: 'Larymore',
        email: 'bsowell09@gmail.com',
        instagramProfile: {
          handle: 'relatable_momish',
          followers: 13,
          engagementRate: 133.84615384615384
        },
        tiktokProfile: {
          handle: 'rachelgracedesignco',
          followers: 1683,
          engagementRate: 5.181435172601251
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:05:46.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '93caf2c4-c3fe-4557-be6d-c8bd63d96b4c',
      content: [
        {
          groupId: 'f6451929-c272-49e2-b592-af4c4ff1a28c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:24:25.681Z',

          createdAt: '2024-05-28T22:13:17.602Z',
          content: [
            {
              caption: '',

              id: '5f109451-d7fd-430c-a3a4-4cd36aa643d7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/93caf2c4-c3fe-4557-be6d-c8bd63d96b4c/ugc/f6451929-c272-49e2-b592-af4c4ff1a28c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b788f865-5653-40f3-975e-bbe1c68c9486',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/93caf2c4-c3fe-4557-be6d-c8bd63d96b4c/ugc/f6451929-c272-49e2-b592-af4c4ff1a28c/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '061c4363-9e17-424e-b8d1-c0573a67581b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:24:17.812Z',

          createdAt: '2024-05-28T21:00:30.363Z',
          content: [
            {
              caption: '',

              id: '0a668c46-be38-4b85-9d16-93aedd99ecd7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/93caf2c4-c3fe-4557-be6d-c8bd63d96b4c/ugc/061c4363-9e17-424e-b8d1-c0573a67581b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '81323cca-9ad3-4fb8-8481-770594497af2',
        firstName: 'Sashaneal',
        lastName: 'Smith',
        email: 'smithsashaneal@gmail.com',
        instagramProfile: {
          handle: 'fiwi_canvas',
          followers: 466,
          engagementRate: 0.7081545064377682
        },
        tiktokProfile: {
          handle: 'fiwi_canvas',
          followers: 1361,
          engagementRate: 7.452718384277319
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:07:25.000Z',
        amountCents: 8478
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '112d281c-003e-450a-994a-4068445d87bd',
      content: [
        {
          groupId: '5330df74-6bba-421f-9274-0dfaf66b7b08',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-25T08:32:03.043Z',

          createdAt: '2024-06-25T04:40:50.561Z',
          content: [
            {
              caption: '',

              id: '3aa9a969-74b6-4153-9cf3-054973c18145',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/112d281c-003e-450a-994a-4068445d87bd/ugc/5330df74-6bba-421f-9274-0dfaf66b7b08/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b70f1fa3-7d9a-4925-b8aa-ef4c8acd6491',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/112d281c-003e-450a-994a-4068445d87bd/ugc/5330df74-6bba-421f-9274-0dfaf66b7b08/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7ab018ff-0b3c-46da-bac5-0dc207881394',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T11:08:19.853Z',

          createdAt: '2024-06-26T21:23:48.352Z',
          content: [
            {
              caption: '',

              id: '184263f5-b0d1-45d9-8462-4e9af05c9edf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/112d281c-003e-450a-994a-4068445d87bd/ugc/7ab018ff-0b3c-46da-bac5-0dc207881394/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '59b28d99-8f28-4be8-a45a-7354db0d1a03',
        firstName: 'Sarai',
        lastName: 'Alvarez',
        email: 'alvs2221@gmail.com',
        instagramProfile: {
          handle: 'alvs_becca',
          followers: 2166,
          engagementRate: 0.9095106186518929
        },
        tiktokProfile: {
          handle: 'alvarezfam',
          followers: 11222,
          engagementRate: 6.173630804058891
        },
        youtubeProfile: {
          handle: 'sweetserenitys',
          followers: 189
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:12:46.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '85d7a9d6-0e46-47ca-af63-c09adfb531e1',
      content: [
        {
          groupId: '0fe0e9ea-cf59-4aa4-8c65-4e0a6ba9a1c6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T16:14:06.215Z',

          createdAt: '2024-05-30T14:47:52.650Z',
          content: [
            {
              caption: '',

              id: 'b17ddc9a-5351-448e-82a1-7483937a9db2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85d7a9d6-0e46-47ca-af63-c09adfb531e1/ugc/0fe0e9ea-cf59-4aa4-8c65-4e0a6ba9a1c6/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bb1b23ce-689f-419c-a572-41a0519388e3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85d7a9d6-0e46-47ca-af63-c09adfb531e1/ugc/0fe0e9ea-cf59-4aa4-8c65-4e0a6ba9a1c6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7af8edcc-5205-455f-a61a-59be81102cdf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85d7a9d6-0e46-47ca-af63-c09adfb531e1/ugc/0fe0e9ea-cf59-4aa4-8c65-4e0a6ba9a1c6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '56b0979c-9a02-468c-a7f3-69bac327a243',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T19:38:25.573Z',

          createdAt: '2024-05-30T14:44:00.088Z',
          content: [
            {
              caption: '',

              id: 'b0f595bd-92a9-431f-af5c-abdbf5c95aff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85d7a9d6-0e46-47ca-af63-c09adfb531e1/ugc/56b0979c-9a02-468c-a7f3-69bac327a243/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9ce4acb1-aab1-4534-b8d7-f4d918fd7993',
        firstName: 'Tini',
        lastName: 'Wilson',
        email: 'tini.wilson@yahoo.com',
        instagramProfile: {
          handle: 'tiniwilson',
          followers: 10415,
          engagementRate: 2.14306289006241
        },
        tiktokProfile: {
          handle: 'tiniwilson',
          followers: 329,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'tiniwilson'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:13:39.000Z',
        amountCents: 7629
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bd08d5b3-c906-43e0-b7f0-efb840679539',
      content: [
        {
          groupId: '309547a0-e68e-44f2-a0cf-daa4ffaab40e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T12:26:07.736Z',

          createdAt: '2024-05-29T16:35:01.626Z',
          content: [
            {
              caption: '',

              id: '8d423a34-86a3-4762-851c-0adefcf608f9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd08d5b3-c906-43e0-b7f0-efb840679539/ugc/309547a0-e68e-44f2-a0cf-daa4ffaab40e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f9bbff43-3b7b-4b25-b59d-f5250fdd9476',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T12:26:13.328Z',

          createdAt: '2024-05-29T16:35:44.693Z',
          content: [
            {
              caption: '',

              id: '042a0e70-95fb-410f-9536-ea6ebefe4c76',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd08d5b3-c906-43e0-b7f0-efb840679539/ugc/f9bbff43-3b7b-4b25-b59d-f5250fdd9476/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f806bd89-1ab1-4826-a43a-687733fbec92',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd08d5b3-c906-43e0-b7f0-efb840679539/ugc/f9bbff43-3b7b-4b25-b59d-f5250fdd9476/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '667f3348-edb6-45e6-8d36-a94e927cba34',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd08d5b3-c906-43e0-b7f0-efb840679539/ugc/f9bbff43-3b7b-4b25-b59d-f5250fdd9476/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9c3ba361-d4e0-430f-bfd9-eddc3b4b7427',
        firstName: 'Melissa',
        lastName: 'Savoie',
        email: 'vacationstoenjoy@yahoo.com',
        instagramProfile: {
          handle: 'honeyandheritage',
          followers: 18665,
          engagementRate: 1.7251540316099652
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:13:43.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5edc468e-82bb-45d6-98f1-e6054cfa5612',
      content: [
        {
          groupId: '5736dc31-2d28-4d3b-bb7c-189d6ecec6e6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T13:00:18.060Z',

          createdAt: '2024-06-05T22:02:55.755Z',
          content: [
            {
              caption: '',

              id: 'eeae2b00-7f7e-459c-a7de-4a7ebe18ca4e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5edc468e-82bb-45d6-98f1-e6054cfa5612/ugc/5736dc31-2d28-4d3b-bb7c-189d6ecec6e6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '020f306c-a262-4d96-8df8-5b8507bdec02',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T04:33:01.361Z',

          createdAt: '2024-06-05T22:05:06.078Z',
          content: [
            {
              caption: '',

              id: '2e8132e2-742c-49c2-8584-aa3f996941b7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5edc468e-82bb-45d6-98f1-e6054cfa5612/ugc/020f306c-a262-4d96-8df8-5b8507bdec02/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'af1d83d1-8725-4af7-894b-c9cc80485e56',
        firstName: 'Bianca',
        lastName: 'White',
        email: 'bialadybusiness@gmail.com',
        instagramProfile: {
          handle: 'bialady__',
          followers: 1004,
          engagementRate: 2.1215139442231075
        },
        tiktokProfile: {
          handle: 'bia.lady',
          followers: 148,
          engagementRate: 2.1052631578947367
        },
        youtubeProfile: {
          handle: 'bialady_',
          followers: 163
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:15:24.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3e7cd856-8a2f-4b6a-86f3-0d220c0f0c28',
      content: [
        {
          groupId: '994b96a7-17d9-4ac1-8a02-6ab1d9903ab7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-05-28T21:54:19.864Z',

          createdAt: '2024-05-28T15:26:35.527Z',
          content: [
            {
              caption: '',

              id: 'fa2aee77-363f-42b7-8f8f-4397e2659961',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3e7cd856-8a2f-4b6a-86f3-0d220c0f0c28/ugc/994b96a7-17d9-4ac1-8a02-6ab1d9903ab7/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '65a8ba65-b044-4b9f-813b-397a19cc7861',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3e7cd856-8a2f-4b6a-86f3-0d220c0f0c28/ugc/994b96a7-17d9-4ac1-8a02-6ab1d9903ab7/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8aaa5dd9-9e2f-443f-916a-0b86cab970af',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3e7cd856-8a2f-4b6a-86f3-0d220c0f0c28/ugc/994b96a7-17d9-4ac1-8a02-6ab1d9903ab7/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8a6cf239-ce02-491b-9f9e-44648049aad2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:54:09.316Z',

          createdAt: '2024-05-28T15:19:10.390Z',
          content: [
            {
              caption: '',

              id: 'e0365637-a60f-449d-be70-320dc8a29ca7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3e7cd856-8a2f-4b6a-86f3-0d220c0f0c28/ugc/8a6cf239-ce02-491b-9f9e-44648049aad2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f4365922-010c-4112-8c4b-a634afd32b05',
        firstName: 'Madeline',
        lastName: 'Maxwell',
        email: 'madelinemaxwell@aol.com',
        instagramProfile: {
          handle: 'madelineamaxwell',
          followers: 1258,
          engagementRate: 0.4531001589825119
        },
        tiktokProfile: {
          handle: 'madelinemaxwell',
          followers: 317,
          engagementRate: 6.467654520295203
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:25:52.000Z',
        amountCents: 7623
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8e758897-3783-4126-a955-4258ddcfb912',
      content: [
        {
          groupId: '5866945c-50a0-4ae7-bc86-89d87dd5f2b0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T04:53:26.111Z',

          createdAt: '2024-05-28T19:53:10.153Z',
          content: [
            {
              caption: '',

              id: '15c434f1-5dcf-4e1d-8e26-63a633db8406',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e758897-3783-4126-a955-4258ddcfb912/ugc/5866945c-50a0-4ae7-bc86-89d87dd5f2b0/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ddeb23cf-656d-4259-87b0-98e95494329a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e758897-3783-4126-a955-4258ddcfb912/ugc/5866945c-50a0-4ae7-bc86-89d87dd5f2b0/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e3e25eb2-e0de-4823-bb21-e2e7e05301c3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e758897-3783-4126-a955-4258ddcfb912/ugc/5866945c-50a0-4ae7-bc86-89d87dd5f2b0/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ddb13f6c-aa7e-43e0-932c-fd8b43a3cbd6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T04:53:21.030Z',

          createdAt: '2024-05-28T20:32:05.503Z',
          content: [
            {
              caption: '',

              id: 'c13bddcb-2f6d-408c-9d1f-bc8425749bb1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e758897-3783-4126-a955-4258ddcfb912/ugc/ddb13f6c-aa7e-43e0-932c-fd8b43a3cbd6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '78181639-fe9f-492b-94e2-06464ef783f5',
        firstName: 'Ludjero',
        lastName: 'Lopes',
        email: 'l.udjero1@gmail.com',
        instagramProfile: {
          handle: 'ludjero.closet',
          followers: 118272,
          engagementRate: 0.47323119588744594
        },
        tiktokProfile: {
          handle: 'ludjerocloset',
          followers: 5065,
          engagementRate: 3.4129692832764507
        },
        youtubeProfile: {
          handle: 'ludjerocloset',
          followers: 1170
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:29:27.000Z',
        amountCents: 8558
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '77aac939-411f-44e4-ac0f-24d8d19663ab',
      content: [
        {
          groupId: '5c4173e4-a391-4e63-bddd-9124a6f6354f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-27T16:21:03.912Z',

          createdAt: '2024-06-01T07:59:20.431Z',
          content: [
            {
              caption: '',

              id: '929d4960-aa68-4daa-8da4-7019d193906e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/77aac939-411f-44e4-ac0f-24d8d19663ab/ugc/5c4173e4-a391-4e63-bddd-9124a6f6354f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5a4b5ef4-fd27-4fb3-a2d2-db9e9e2cff8f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-27T16:20:59.294Z',

          createdAt: '2024-06-01T07:58:15.759Z',
          content: [
            {
              caption: '',

              id: '030ea6ff-61ec-4efc-a31b-a0d35f669a28',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/77aac939-411f-44e4-ac0f-24d8d19663ab/ugc/5a4b5ef4-fd27-4fb3-a2d2-db9e9e2cff8f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5b5a9c6c-21db-40cb-82f2-47e55c24d61f',
        firstName: 'Michelle ',
        lastName: 'Robson',
        email: 'ilisapeci@hotmail.com',
        instagramProfile: {
          handle: 'meshellexl',
          followers: 146,
          engagementRate: 16.458333333333332
        },
        tiktokProfile: {
          handle: 'datvegasgirl',
          followers: 1003
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:52:41.000Z',
        amountCents: 7585
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '013d6ec0-fc1d-4bf9-be46-c81cd357b4e2',
      content: [],
      creator: {
        id: '7db2acc3-4f7c-4899-bd69-eb47920fa3a7',
        firstName: 'Sabrina',
        lastName: 'Tewksbury',
        email: 'sabrinatewks@gmail.com',
        instagramProfile: {
          handle: 'sabrina.tewksbury',
          followers: 45630,
          engagementRate: 2.2347140039447733
        },
        tiktokProfile: {
          handle: 'sabrina.tewksbury',
          followers: 1283,
          engagementRate: 6.990792293784498
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T20:56:16.000Z',
        amountCents: 14054
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7d144e4b-33a8-41f2-aa6e-88418b9f520f',
      content: [
        {
          groupId: 'db975f58-82f6-4a23-b34d-f2d553d3c7fe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-11T17:59:41.830Z',

          createdAt: '2024-05-31T23:17:58.034Z',
          content: [
            {
              caption: '',

              id: '0eab6a4e-79a0-449e-a9e7-db100e149544',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d144e4b-33a8-41f2-aa6e-88418b9f520f/ugc/db975f58-82f6-4a23-b34d-f2d553d3c7fe/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3c6060ea-d6ab-46db-b706-4a0b617e8e6b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-11T17:59:45.654Z',

          createdAt: '2024-05-31T23:18:36.849Z',
          content: [
            {
              caption: '',

              id: '0880ff19-a149-47f4-ac4d-1f2f864ace97',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d144e4b-33a8-41f2-aa6e-88418b9f520f/ugc/3c6060ea-d6ab-46db-b706-4a0b617e8e6b/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ab439833-239a-4d5f-a5cc-1b1cc9c2cae1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d144e4b-33a8-41f2-aa6e-88418b9f520f/ugc/3c6060ea-d6ab-46db-b706-4a0b617e8e6b/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'af017905-3036-40c0-ab74-baa8959328ce',
        firstName: 'Chanderpaul',
        lastName: 'Cole',
        email: 'friofarm86@gmail.com',
        instagramProfile: {
          handle: 'frio_farm86',
          followers: 1227,
          engagementRate: 2.5101874490627547
        },
        tiktokProfile: {
          handle: 'frio_farm86',
          followers: 4140,
          engagementRate: 2.7744630071599046
        },
        youtubeProfile: {
          handle: 'friofarm',
          followers: 1770
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T21:21:57.000Z',
        amountCents: 8160
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eba8f86e-b5de-4b08-87a9-0654cbc4a83e',
      content: [
        {
          groupId: '4cf013b8-22a9-403c-bfcc-49c5c3c7bc5c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T01:18:41.094Z',

          createdAt: '2024-05-27T14:20:28.139Z',
          content: [
            {
              caption: '',

              id: 'cf9bd748-1e9e-4683-9851-36a32f0c2a88',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eba8f86e-b5de-4b08-87a9-0654cbc4a83e/ugc/4cf013b8-22a9-403c-bfcc-49c5c3c7bc5c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '06dbe77b-f10e-4869-905c-980203f6fdd7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eba8f86e-b5de-4b08-87a9-0654cbc4a83e/ugc/4cf013b8-22a9-403c-bfcc-49c5c3c7bc5c/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '411fc575-aefb-402f-9aa5-2c51147c3da5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T01:18:45.981Z',

          createdAt: '2024-06-02T15:29:29.877Z',
          content: [
            {
              caption: '',

              id: '8181ed25-4d8e-4cff-8899-009980cbf0fe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eba8f86e-b5de-4b08-87a9-0654cbc4a83e/ugc/411fc575-aefb-402f-9aa5-2c51147c3da5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '788fb8fa-3ace-448c-92b3-07e72dbf2f16',
        firstName: 'Kimberly',
        lastName: 'Kopecky',
        email: 'mommyinnj@gmail.com',
        instagramProfile: {
          handle: 'mommyinnj',
          followers: 2879,
          engagementRate: 0.04631237698274864
        },
        tiktokProfile: {
          handle: 'mamakopecky125',
          followers: 47,
          engagementRate: 7.6923076923076925
        },
        youtubeProfile: {
          handle: 'kimberlykopecky',
          followers: 204
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T21:37:18.000Z',
        amountCents: 13860
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f371ec9d-2785-4233-bc72-2f037ff17aaf',
      content: [],
      creator: {
        id: '48082e98-0a1c-49e2-bab9-d4dca8466d93',
        firstName: 'Rushel ',
        lastName: 'Miller ',
        email: 'rushyboo.rm@gmail.com',

        tiktokProfile: {
          handle: 'rushyboo76',
          followers: 6738,
          engagementRate: 9.0182916104825
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T21:53:23.000Z',
        amountCents: 10842
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '179cf07c-0662-436a-9530-6dc0281bb8e9',
      content: [
        {
          groupId: '0a843ed7-294b-4aed-a686-969881ca7d9c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-02T14:56:05.451Z',

          createdAt: '2024-06-01T05:16:24.866Z',
          content: [
            {
              caption: '',

              id: '7f478796-7ffa-4e17-902a-ea2c283ce7d6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/179cf07c-0662-436a-9530-6dc0281bb8e9/ugc/0a843ed7-294b-4aed-a686-969881ca7d9c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '00b22578-56b9-4c17-962a-427cf14658ac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/179cf07c-0662-436a-9530-6dc0281bb8e9/ugc/0a843ed7-294b-4aed-a686-969881ca7d9c/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f69a11bb-eecf-42c6-aa7e-c7fbe83e4312',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T20:16:17.430Z',

          createdAt: '2024-06-01T05:11:53.084Z',
          content: [
            {
              caption: '',

              id: '35c22d4c-0bce-4d13-aac5-aee011c174da',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/179cf07c-0662-436a-9530-6dc0281bb8e9/ugc/f69a11bb-eecf-42c6-aa7e-c7fbe83e4312/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2c4f5198-3be5-49ee-9ba4-5e1c5ab6be68',
        firstName: 'Ericka',
        lastName: 'Walker',
        email: 'erickaconley24@gmail.com',
        instagramProfile: {
          handle: 'ericka_maryjane',
          followers: 11641,
          engagementRate: 1.3280645992612317
        },
        tiktokProfile: {
          handle: 'ericka_maryjane',
          followers: 323
        },
        youtubeProfile: {
          handle: 'erickawalker6240',
          followers: 16
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T22:02:51.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7b6f6ee2-4345-4483-818a-22d88f59f64d',
      content: [
        {
          groupId: 'e2cbfc11-62bf-475e-a2cf-37059aa78fbd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T19:39:23.279Z',

          createdAt: '2024-05-27T18:55:51.301Z',
          content: [
            {
              caption: '',

              id: '0ca48c82-d164-43a4-9a28-b13a755c6a94',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7b6f6ee2-4345-4483-818a-22d88f59f64d/ugc/e2cbfc11-62bf-475e-a2cf-37059aa78fbd/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ed93c505-9158-47be-95cf-21fc6620f6d9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7b6f6ee2-4345-4483-818a-22d88f59f64d/ugc/e2cbfc11-62bf-475e-a2cf-37059aa78fbd/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bf17f9ec-03da-4514-856c-76133867d39f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7b6f6ee2-4345-4483-818a-22d88f59f64d/ugc/e2cbfc11-62bf-475e-a2cf-37059aa78fbd/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '79a62b64-4d79-4858-8ab1-fa63b2350692',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T19:11:55.373Z',

          createdAt: '2024-05-29T02:44:00.192Z',
          content: [
            {
              caption: '',

              id: '4d614dea-979a-4fe6-87ca-3a4afa8ffcb1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7b6f6ee2-4345-4483-818a-22d88f59f64d/ugc/79a62b64-4d79-4858-8ab1-fa63b2350692/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5f912b38-d01b-4f50-8569-50f4183bb7d4',
        firstName: 'Meagan',
        lastName: 'Riley',
        email: 'meaganmf@gmail.com',
        instagramProfile: {
          handle: 'meagiefoodie',
          followers: 2390,
          engagementRate: 1.096234309623431
        },
        tiktokProfile: {
          handle: 'meagiemm',
          followers: 5061,
          engagementRate: 4.382862124797608
        },
        youtubeProfile: {
          handle: 'meagiemm',
          followers: 94
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T22:05:22.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8c7ccfc4-22e0-47b7-bfbc-87f2f2d5b6e7',
      content: [
        {
          groupId: '945a97b8-5ca0-4b9d-9cfc-fe735ba2f6ad',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T14:58:17.844Z',

          createdAt: '2024-05-27T19:56:20.267Z',
          content: [
            {
              caption: '',

              id: '693e5c8a-754f-4817-bc3a-f1b1db5e4247',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c7ccfc4-22e0-47b7-bfbc-87f2f2d5b6e7/ugc/945a97b8-5ca0-4b9d-9cfc-fe735ba2f6ad/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '1e1aff6b-652d-4082-977a-3287214b6aa5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T14:58:23.907Z',

          createdAt: '2024-05-26T20:23:46.323Z',
          content: [
            {
              caption: '',

              id: '724feea6-f6b1-4597-952c-dd7605cc3630',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8c7ccfc4-22e0-47b7-bfbc-87f2f2d5b6e7/ugc/1e1aff6b-652d-4082-977a-3287214b6aa5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3102491e-ee51-4b85-b854-9c6539e1f16d',
        firstName: 'Ashley',
        lastName: 'Martinez',
        email: 'martinez.ashley04@gmail.com',
        instagramProfile: {
          handle: 'ashleypriscilla0618',
          followers: 2504,
          engagementRate: 4.539403620873269
        },
        tiktokProfile: {
          handle: 'ashleymartinez0615',
          followers: 289,
          engagementRate: 4.5
        },
        youtubeProfile: {
          handle: 'ashleymartinez6716'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T22:15:39.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '05cee7d3-a7df-42ef-bf3c-14e88e0cf832',
      content: [
        {
          groupId: '30c49bbb-da25-4c3f-864c-bcd58ad35c30',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T14:02:25.956Z',

          createdAt: '2024-05-30T20:47:50.464Z',
          content: [
            {
              caption: '',

              id: '8c845b84-f56a-4069-b34f-015726b5708d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/05cee7d3-a7df-42ef-bf3c-14e88e0cf832/ugc/30c49bbb-da25-4c3f-864c-bcd58ad35c30/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '42da7756-1c13-487f-bd66-e01792202b1f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/05cee7d3-a7df-42ef-bf3c-14e88e0cf832/ugc/30c49bbb-da25-4c3f-864c-bcd58ad35c30/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'eff66bda-1042-4645-8bdc-1b05bd2de3d2',
        firstName: 'Tiffany',
        lastName: 'Diaz',
        email: 'diaztiffany7@gmail.com',

        tiktokProfile: {
          handle: 'ink_credible88',
          followers: 1588
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T23:04:24.000Z',
        amountCents: 8208
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '821da74a-44d1-4a76-838c-e457d74a1ea5',
      content: [],
      creator: {
        id: '4b5230e4-074c-43bc-ae32-cddbdc158587',
        firstName: 'Avigail',
        lastName: 'Robles',
        email: 'yanezyolanda22@icloud.com',

        tiktokProfile: {
          handle: 'avigailrobless',
          followers: 1387,
          engagementRate: 5.669614397567713
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-24T23:54:57.000Z',
        amountCents: 8314
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd36ab80d-b204-46da-bb03-7db281a957cb',
      content: [
        {
          groupId: 'e270e827-8e3c-40ef-9144-b71df1a3bf01',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T05:03:30.400Z',

          createdAt: '2024-06-04T01:58:41.787Z',
          content: [
            {
              caption: '',

              id: 'c9c1782e-537b-4ba1-ba70-a7510b49615e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d36ab80d-b204-46da-bb03-7db281a957cb/ugc/e270e827-8e3c-40ef-9144-b71df1a3bf01/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '76edda4b-b956-4abf-bc71-65e193b2a625',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T05:03:24.633Z',

          createdAt: '2024-06-04T02:00:06.620Z',
          content: [
            {
              caption: '',

              id: '098ea503-0b49-4ed0-8c82-6321b8348aa6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d36ab80d-b204-46da-bb03-7db281a957cb/ugc/76edda4b-b956-4abf-bc71-65e193b2a625/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'aba78d90-d0d3-4f8d-ab1d-4826a6860bd9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d36ab80d-b204-46da-bb03-7db281a957cb/ugc/76edda4b-b956-4abf-bc71-65e193b2a625/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '86090341-6b7e-4cf5-970d-bdfe8593dc4d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d36ab80d-b204-46da-bb03-7db281a957cb/ugc/76edda4b-b956-4abf-bc71-65e193b2a625/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0d09a678-96cc-4a95-aded-7c150ebaffed',
        firstName: 'Kennedy',
        lastName: 'Beck',
        email: 'kbeckk1996@icloud.com',
        instagramProfile: {
          handle: 'kennedybeee123',
          followers: 2370,
          engagementRate: 0.04219409282700422
        },
        tiktokProfile: {
          handle: 'keepingupwithkennedyy',
          followers: 2894,
          engagementRate: 2.356020942408377
        },
        youtubeProfile: {
          handle: 'kennedybeck-ki6sz',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T00:04:46.000Z',
        amountCents: 7601
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '84589f96-24dc-4290-9d9c-e1983cb9426b',
      content: [
        {
          groupId: '304b5e54-5cb6-4b3a-879a-ea1f3fb28d59',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T20:29:51.623Z',

          createdAt: '2024-05-26T23:21:03.504Z',
          content: [
            {
              caption: '',

              id: 'b43b5d2a-3934-47ae-89d5-cb74ae84f938',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84589f96-24dc-4290-9d9c-e1983cb9426b/ugc/304b5e54-5cb6-4b3a-879a-ea1f3fb28d59/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '016275a2-601d-4c40-a57d-8bb7fa47bbbd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84589f96-24dc-4290-9d9c-e1983cb9426b/ugc/304b5e54-5cb6-4b3a-879a-ea1f3fb28d59/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '09a1b587-e36d-48f0-bb82-64bbb57cb9c8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84589f96-24dc-4290-9d9c-e1983cb9426b/ugc/304b5e54-5cb6-4b3a-879a-ea1f3fb28d59/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '1a29bfca-d078-481e-be6d-6a2825a6c2c0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T20:29:46.854Z',

          createdAt: '2024-05-26T23:19:50.124Z',
          content: [
            {
              caption: '',

              id: '7e55fecf-eedf-4336-b7a7-a2a66e5a0d40',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84589f96-24dc-4290-9d9c-e1983cb9426b/ugc/1a29bfca-d078-481e-be6d-6a2825a6c2c0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5890e4e9-eb5e-4079-aaf9-cf7eb8abd9ed',
        firstName: 'Katherine',
        lastName: 'Buczynski',
        email: 'collabswithkathy@gmail.com',
        instagramProfile: {
          handle: 'travelswithkasia',
          followers: 3884,
          engagementRate: 2.806385169927909
        },
        tiktokProfile: {
          handle: 'travelswithkasia',
          followers: 67,
          engagementRate: 4.117047740023287
        },
        youtubeProfile: {
          handle: 'katherinebuczynski2525',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T00:04:55.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9c890e6a-5946-4695-bfbe-7c891164ed9b',
      content: [
        {
          groupId: '92d09000-8d78-420b-aa33-4ad8b8c55f5c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-20T02:50:43.192Z',

          createdAt: '2024-06-10T15:22:13.911Z',
          content: [
            {
              caption: '',

              id: '781e92c5-f1a6-4a8d-9fac-fb9a4916224b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9c890e6a-5946-4695-bfbe-7c891164ed9b/ugc/92d09000-8d78-420b-aa33-4ad8b8c55f5c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5e8f89b0-6d6e-46cb-b804-165187b8c69b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9c890e6a-5946-4695-bfbe-7c891164ed9b/ugc/92d09000-8d78-420b-aa33-4ad8b8c55f5c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'af85cb6e-237b-47cb-a2ee-5490a1e5cfa1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9c890e6a-5946-4695-bfbe-7c891164ed9b/ugc/92d09000-8d78-420b-aa33-4ad8b8c55f5c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ddaf8d5b-590d-4241-ae8d-180edfcc74ad',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T02:50:59.529Z',

          createdAt: '2024-06-10T18:16:44.620Z',
          content: [
            {
              caption: '',

              id: 'd20fdee7-940c-4fb9-8ef0-7555695633f8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9c890e6a-5946-4695-bfbe-7c891164ed9b/ugc/ddaf8d5b-590d-4241-ae8d-180edfcc74ad/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2f65cf18-f93a-4b47-93e8-a484e4587902',
        firstName: 'Abigail',
        lastName: 'Castella',
        email: 'abigailcastella26@gmail.com',

        tiktokProfile: {
          handle: 'lexic2206',
          followers: 1102,
          engagementRate: 8.264462809917356
        },
        youtubeProfile: {
          handle: 'abigailcastella9045',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T00:32:31.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3fa4c72b-bd18-4dca-80c5-a5c682db29e6',
      content: [
        {
          groupId: '763cdd8f-95c3-455e-8fba-ce6dd16da02d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T17:12:05.643Z',

          createdAt: '2024-06-01T03:58:51.884Z',
          content: [
            {
              caption: '',

              id: 'd0b18fbc-2877-4473-9305-0d37b200f577',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3fa4c72b-bd18-4dca-80c5-a5c682db29e6/ugc/763cdd8f-95c3-455e-8fba-ce6dd16da02d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '205f3dbc-4845-405d-a780-6a6f6ef3029d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T05:07:40.314Z',

          createdAt: '2024-05-31T17:33:37.289Z',
          content: [
            {
              caption: '',

              id: 'fdf529f1-f240-452c-86a8-37d05229647d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3fa4c72b-bd18-4dca-80c5-a5c682db29e6/ugc/205f3dbc-4845-405d-a780-6a6f6ef3029d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '41174ecc-0692-405f-b204-3ee9dccacd8e',
        firstName: 'Kylie',
        lastName: 'Gemeasky',
        email: 'confettiboba@gmail.com',
        instagramProfile: {
          handle: 'muchadoaboutag',
          followers: 1506,
          engagementRate: 2.666666666666667
        },
        tiktokProfile: {
          handle: 'ohmydeerling1',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T00:36:31.000Z',
        amountCents: 7443
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '227bae04-c269-413c-8a8e-f47b1e076ddf',
      content: [
        {
          groupId: 'ec6567cf-6a82-4eee-a15d-a2d6d3fa8472',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-04T22:39:20.068Z',

          createdAt: '2024-06-01T20:15:02.909Z',
          content: [
            {
              caption: '',

              id: '088679db-315b-4fde-863d-d7f72ce56be2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/227bae04-c269-413c-8a8e-f47b1e076ddf/ugc/ec6567cf-6a82-4eee-a15d-a2d6d3fa8472/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '18ab2575-522c-4f29-92e5-4672f98aa9ab',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/227bae04-c269-413c-8a8e-f47b1e076ddf/ugc/ec6567cf-6a82-4eee-a15d-a2d6d3fa8472/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5b9bd086-645f-4c13-bdd6-1f6b79524f55',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/227bae04-c269-413c-8a8e-f47b1e076ddf/ugc/ec6567cf-6a82-4eee-a15d-a2d6d3fa8472/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'aec5fdb5-dc68-4fdc-b498-74905451368b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T22:39:09.860Z',

          createdAt: '2024-06-01T20:13:09.057Z',
          content: [
            {
              caption: '',

              id: '05ec2eaa-82f9-4d20-9896-f35405b78619',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/227bae04-c269-413c-8a8e-f47b1e076ddf/ugc/aec5fdb5-dc68-4fdc-b498-74905451368b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '57aad76b-d13f-4cb8-a01c-a2162e8bd3a1',
        firstName: 'Jose',
        lastName: 'Correa',
        email: 'eduardo.ortiz777@gmail.com',
        instagramProfile: {
          handle: 'innerdemonsco',
          followers: 151,
          engagementRate: 14.6
        },
        tiktokProfile: {
          handle: 'josekelly77',
          followers: 3953,
          engagementRate: 2.1157649540719907
        },
        youtubeProfile: {
          handle: 'eduardortiz777',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T01:15:30.000Z',
        amountCents: 8237
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7e3f1596-5b62-4e20-996a-b1939d6610d3',
      content: [
        {
          groupId: 'ad58bc9d-e381-4ad2-b970-c4a01c6b2733',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T02:45:55.676Z',

          createdAt: '2024-05-30T22:24:05.198Z',
          content: [
            {
              caption: '',

              id: '4f477262-330c-44b5-bf7e-74c378224043',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7e3f1596-5b62-4e20-996a-b1939d6610d3/ugc/ad58bc9d-e381-4ad2-b970-c4a01c6b2733/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b63aed05-e28d-4ec7-b7ce-f9e077733516',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-31T00:39:54.385Z',

          createdAt: '2024-05-28T20:51:14.470Z',
          content: [
            {
              caption: '',

              id: '5cd29b94-89cc-4296-b5a1-264503780bdb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7e3f1596-5b62-4e20-996a-b1939d6610d3/ugc/b63aed05-e28d-4ec7-b7ce-f9e077733516/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9bd62f7d-49f6-44b0-9dff-f3dc46280a3b',
        firstName: 'Catherine',
        lastName: 'Peel',
        email: 'peelfarms@yahoo.com',

        tiktokProfile: {
          handle: 'catherinetpeel',
          followers: 2226,
          engagementRate: 4.986648090096366
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T01:15:31.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '18829212-bc4a-4a68-a653-5488d2198acc',
      content: [
        {
          groupId: 'efdaec17-eccf-40e9-bcb6-7d62867f3ecc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-28T21:45:38.640Z',

          createdAt: '2024-06-08T01:38:19.631Z',
          content: [
            {
              caption: '',

              id: '002e2b97-ee3e-4779-80a9-3af54d1d3cc8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/18829212-bc4a-4a68-a653-5488d2198acc/ugc/efdaec17-eccf-40e9-bcb6-7d62867f3ecc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '41499eea-213f-4c58-a0da-e41e45bc778a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/18829212-bc4a-4a68-a653-5488d2198acc/ugc/efdaec17-eccf-40e9-bcb6-7d62867f3ecc/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '74433fd7-82fe-4759-bae1-c0081fb67b99',
        firstName: 'Elina',
        lastName: 'Peciul',
        email: 'peciul76@yahoo.com',
        instagramProfile: {
          handle: 'elli722',
          followers: 2189,
          engagementRate: 0.16971862438588656
        },
        tiktokProfile: {
          handle: 'ellinka722',
          followers: 263
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T01:19:18.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3f98dc6d-7afe-4ec8-8026-74f320b9a43f',
      content: [
        {
          groupId: '0ca0f102-6420-44ec-9fe0-559b7a380694',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T22:31:47.973Z',

          createdAt: '2024-05-29T04:46:52.043Z',
          content: [
            {
              caption: '',

              id: '6fdce4de-8d4c-4857-8bf4-64710be6e900',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3f98dc6d-7afe-4ec8-8026-74f320b9a43f/ugc/0ca0f102-6420-44ec-9fe0-559b7a380694/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ad79e738-6109-47fc-b37b-1b0f2824357d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T22:31:24.502Z',

          createdAt: '2024-05-29T04:54:28.647Z',
          content: [
            {
              caption: '',

              id: '3b383595-ef0f-41ef-810d-b26b9410b5ec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3f98dc6d-7afe-4ec8-8026-74f320b9a43f/ugc/ad79e738-6109-47fc-b37b-1b0f2824357d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '587d13ee-7140-40a0-85fa-554ba2e0be7f',
        firstName: 'Taylinne',
        lastName: 'Orrick',
        email: 'taylinneorrick@gmail.com',

        tiktokProfile: {
          handle: 'taylinneorrick',
          followers: 6881,
          engagementRate: 12.44320404417296
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T01:23:23.000Z',
        amountCents: 8333
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7bb4d6e9-2b3f-4f59-b30d-53857b37fa05',
      content: [
        {
          groupId: '27ff40a9-d765-4128-98bd-1527bd11f9f7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:57:43.581Z',

          createdAt: '2024-05-31T15:12:35.563Z',
          content: [
            {
              caption: '',

              id: '13b884d9-daf4-4a3a-b042-4dd1ccd3d557',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7bb4d6e9-2b3f-4f59-b30d-53857b37fa05/ugc/27ff40a9-d765-4128-98bd-1527bd11f9f7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '9a53c29e-73b0-4168-bb82-79bf89ed0d2c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T16:19:41.494Z',

          createdAt: '2024-05-31T14:57:53.555Z',
          content: [
            {
              caption: '',

              id: 'b1b9d96f-d300-448f-8b4b-90b01030fbba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7bb4d6e9-2b3f-4f59-b30d-53857b37fa05/ugc/9a53c29e-73b0-4168-bb82-79bf89ed0d2c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e2da188e-a9a7-48e5-84e6-ee86dca99d98',
        firstName: 'Krystle',
        lastName: 'Barger',
        email: 'gypsy_rose611@aol.com',
        instagramProfile: {
          handle: 'darkly_devine',
          followers: 0,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'gypsy_rose611',
          followers: 2748
        },
        youtubeProfile: {
          handle: 'gypsyrose7573'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T01:34:29.000Z',
        amountCents: 7370
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c6f2f6da-067a-46cd-9bae-6f2bb2d084ae',
      content: [
        {
          groupId: 'f58d9809-550e-4bb4-9504-15fcf2560d07',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:31:06.910Z',

          createdAt: '2024-06-04T02:53:45.559Z',
          content: [
            {
              caption: '',

              id: '6479e7f7-e7c3-49ec-bfc7-1cd769cc82c9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c6f2f6da-067a-46cd-9bae-6f2bb2d084ae/ugc/f58d9809-550e-4bb4-9504-15fcf2560d07/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ccd87c0b-bca1-4d5b-b7cb-9b83af0f1624',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T04:11:36.440Z',

          createdAt: '2024-06-04T02:55:07.534Z',
          content: [
            {
              caption: '',

              id: 'e3da9cf7-712c-4f99-a022-7b71ee804582',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c6f2f6da-067a-46cd-9bae-6f2bb2d084ae/ugc/ccd87c0b-bca1-4d5b-b7cb-9b83af0f1624/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd45de3b2-a096-46bc-a3b2-4b2b2de61afa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c6f2f6da-067a-46cd-9bae-6f2bb2d084ae/ugc/ccd87c0b-bca1-4d5b-b7cb-9b83af0f1624/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '959e371b-196e-4d3c-b5b0-5392cce0b945',
        firstName: 'Ashley',
        lastName: 'Stubbe',
        email: 'abaucum55@gmail.com',
        instagramProfile: {
          handle: 'heyyitsashleynicole',
          followers: 11784,
          engagementRate: 1.8075356415478614
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T01:58:00.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f6bb6e20-3c73-4e9e-88c0-a9a4b865096b',
      content: [
        {
          groupId: '63fbd80e-3b24-4917-8f89-0ea1716b47bb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T10:06:42.635Z',

          createdAt: '2024-06-03T22:25:54.800Z',
          content: [
            {
              caption: '',

              id: '8147a1d9-b017-476e-aa8b-add27022bad4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f6bb6e20-3c73-4e9e-88c0-a9a4b865096b/ugc/63fbd80e-3b24-4917-8f89-0ea1716b47bb/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6956c826-f6f0-4fa7-8a96-192c2dacd188',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f6bb6e20-3c73-4e9e-88c0-a9a4b865096b/ugc/63fbd80e-3b24-4917-8f89-0ea1716b47bb/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'aa2034de-99d7-47fb-b229-b5a9ee483475',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f6bb6e20-3c73-4e9e-88c0-a9a4b865096b/ugc/63fbd80e-3b24-4917-8f89-0ea1716b47bb/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '120697d7-af99-44ee-a7ee-81fbe8b5baf8',
        firstName: 'Shaunette',
        lastName: 'Williams',
        email: 'stacyturnerfml@gmail.com',
        instagramProfile: {
          handle: 'kurves_beachwear876',
          followers: 2832,
          engagementRate: 0.6002824858757062
        },
        tiktokProfile: {
          handle: 'zoeyleexoxo',
          followers: 1614,
          engagementRate: 3.2679738562091507
        },
        youtubeProfile: {
          handle: 'shann_345',
          followers: 13
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T02:06:29.000Z',
        amountCents: 13909
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0364e3f5-bbaa-4a88-80e3-3af69b81f38f',
      content: [],
      creator: {
        id: '66eb4ab8-9154-4ec0-9435-0ba62d99e914',
        firstName: 'Shakira',
        lastName: 'Jones',
        email: 'shakirajoness@outlook.com',
        instagramProfile: {
          handle: 'kiraxxj',
          followers: 1367
        },
        tiktokProfile: {
          handle: 'kiraxxxj',
          followers: 40
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T03:12:51.000Z',
        amountCents: 11012
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c723a6dc-e124-45e9-9b0e-c9fabb233e4d',
      content: [
        {
          groupId: '195f6ecf-be2a-4cdd-b693-e60439491b7b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-19T16:35:49.688Z',

          createdAt: '2024-06-18T14:16:26.290Z',
          content: [
            {
              caption: '',

              id: '411a149d-de57-48af-b40e-0379ed66e8c4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c723a6dc-e124-45e9-9b0e-c9fabb233e4d/ugc/195f6ecf-be2a-4cdd-b693-e60439491b7b/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd2814236-6720-4f7e-a12b-8a4dd748ea0b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c723a6dc-e124-45e9-9b0e-c9fabb233e4d/ugc/195f6ecf-be2a-4cdd-b693-e60439491b7b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2c720674-d421-4dc8-9ec2-409fde4d4daf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T16:35:53.347Z',

          createdAt: '2024-06-18T16:12:41.835Z',
          content: [
            {
              caption: '',

              id: 'd96bcbdb-d5ae-41c6-84f6-0f4bd8be7494',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c723a6dc-e124-45e9-9b0e-c9fabb233e4d/ugc/2c720674-d421-4dc8-9ec2-409fde4d4daf/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5853065d-f6d3-4832-b941-1623ebc8b742',
        firstName: 'Yasmina',
        lastName: 'Williams',
        email: 'sirensandchampagne@gmail.com',
        instagramProfile: {
          handle: 'sirensandchampagne',
          followers: 13086,
          engagementRate: 0.4264099037138927
        },
        tiktokProfile: {
          handle: 'sirensandchampagne',
          followers: 734,
          engagementRate: 1.950072155481005
        },
        youtubeProfile: {
          handle: 'yasminacolony7810'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T05:06:11.000Z',
        amountCents: 8487
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5c0d7b79-46ea-41f4-a469-ceaa4a0ec1f7',
      content: [],
      creator: {
        id: '6b34d32c-ec7b-4b25-b09e-5fca98ad8d39',
        firstName: 'Jeremiah',
        lastName: 'Brown',
        email: 'jerrybomb133@gmail.com',
        instagramProfile: {
          handle: 'jlab___',
          followers: 1553,
          engagementRate: 1.8609143593045718
        },
        tiktokProfile: {
          handle: 'jlabcreation',
          followers: 508,
          engagementRate: 1.6260162601626018
        },
        youtubeProfile: {
          handle: 'jerrybomb133',
          followers: 33
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T06:15:28.000Z',
        amountCents: 7660
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'da00de07-f0c3-4ae7-a4d4-118a5b2df87f',
      content: [
        {
          groupId: '77e139bc-1e01-4ca2-b65a-0c20519da1bd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T15:20:20.955Z',

          createdAt: '2024-05-29T14:45:36.603Z',
          content: [
            {
              caption: '',

              id: 'a41c7d8a-c4a1-421d-8e06-5e7b73fe3fc6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/da00de07-f0c3-4ae7-a4d4-118a5b2df87f/ugc/77e139bc-1e01-4ca2-b65a-0c20519da1bd/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '673828d2-85e2-48ce-8cc1-1d1312afac82',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/da00de07-f0c3-4ae7-a4d4-118a5b2df87f/ugc/77e139bc-1e01-4ca2-b65a-0c20519da1bd/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f4a54a7d-8cca-4ec2-9c27-2ae9527f4301',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T15:20:08.691Z',

          createdAt: '2024-05-29T14:52:17.157Z',
          content: [
            {
              caption: '',

              id: '9886bb2c-9217-4b87-a5bc-6042893a15a4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/da00de07-f0c3-4ae7-a4d4-118a5b2df87f/ugc/f4a54a7d-8cca-4ec2-9c27-2ae9527f4301/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '73dccc4a-3e4f-4cec-9f73-430b5c2f40ef',
        firstName: 'Scott',
        lastName: 'Wood',
        email: 'scottcwood@gmail.com',
        instagramProfile: {
          handle: 'scottcwood',
          followers: 7548,
          engagementRate: 0.7578166401695814
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T06:15:51.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b301b0c7-0c92-45c5-96d9-6ee8a8056ec5',
      content: [
        {
          groupId: '61da3f1f-c28c-410b-9362-703dce5a137f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T20:28:27.446Z',

          createdAt: '2024-05-31T01:42:04.350Z',
          content: [
            {
              caption: '',

              id: 'ba8ecbb9-9449-497d-a750-4d0ba2ec8b21',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b301b0c7-0c92-45c5-96d9-6ee8a8056ec5/ugc/61da3f1f-c28c-410b-9362-703dce5a137f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'dca83e0c-1e40-494b-b806-b493dfce4e51',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b301b0c7-0c92-45c5-96d9-6ee8a8056ec5/ugc/61da3f1f-c28c-410b-9362-703dce5a137f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6e89448c-3ba3-4eea-97c0-bb0938aed1ef',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b301b0c7-0c92-45c5-96d9-6ee8a8056ec5/ugc/61da3f1f-c28c-410b-9362-703dce5a137f/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '35e5924f-8936-46db-b9fa-c4d15b961887',
        firstName: 'Jennifer',
        lastName: 'Duncan',
        email: 'monstaax0@gmail.com',
        instagramProfile: {
          handle: 'zerolukdunc4',
          followers: 8,
          engagementRate: 12.5
        },
        tiktokProfile: {
          handle: 'jenn_kdunc',
          followers: 2645,
          engagementRate: 10.655737704918032
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T12:04:09.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '39988dac-610a-46f8-a674-26b8affe6fc5',
      content: [
        {
          groupId: '5ef726b2-6fa6-4635-853a-18ea36296be0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T19:27:20.962Z',

          createdAt: '2024-06-17T19:56:09.094Z',
          content: [
            {
              caption: '',

              id: '43a95a8e-b45c-4d2a-83c4-4f14ed6b3ff4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39988dac-610a-46f8-a674-26b8affe6fc5/ugc/5ef726b2-6fa6-4635-853a-18ea36296be0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a78da47e-5601-4189-9a19-34e262ef49ae',
        firstName: 'Nicoya',
        lastName: 'Foster',
        email: 'nicoyafostercollabs@icloud.com',
        instagramProfile: {
          handle: '_that.melanin.girl_',
          followers: 1214,
          engagementRate: 4.118616144975288
        },
        tiktokProfile: {
          handle: 'coya_melaningirl',
          followers: 709,
          engagementRate: 12.359550561797752
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T12:06:14.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ea0ae98f-48e4-4173-b2bb-f82a59c08714',
      content: [
        {
          groupId: 'a6717829-3e66-4240-9892-e8b442ad7cdc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:45:52.885Z',

          createdAt: '2024-05-28T02:58:55.443Z',
          content: [
            {
              caption: '',

              id: '687049fa-69bc-409c-b714-1613ba6b3bdc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ea0ae98f-48e4-4173-b2bb-f82a59c08714/ugc/a6717829-3e66-4240-9892-e8b442ad7cdc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e71c4226-7465-4d3e-9c99-ee10221dfbc4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:45:44.850Z',

          createdAt: '2024-05-28T04:57:58.181Z',
          content: [
            {
              caption: '',

              id: '3a4d78b7-0737-46ed-834f-489514451416',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ea0ae98f-48e4-4173-b2bb-f82a59c08714/ugc/e71c4226-7465-4d3e-9c99-ee10221dfbc4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '23bf365e-59f8-4662-a778-ff9281e48a91',
        firstName: 'Kaia',
        lastName: 'McMillen',
        email: 'kaiaeggs@gmail.com',
        instagramProfile: {
          handle: '_kaiamarie_',
          followers: 897,
          engagementRate: 4.050538833147529
        },
        tiktokProfile: {
          handle: 'kaiamcchicken',
          followers: 1835,
          engagementRate: 10.150501141842172
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T12:18:41.000Z',
        amountCents: 7601
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '79008719-3bfb-4b5d-9d7c-17c6d2f9a0df',
      content: [
        {
          groupId: 'b925ea75-2fce-44da-8e9b-77e96a96ad13',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T19:20:43.358Z',

          createdAt: '2024-05-27T14:58:16.484Z',
          content: [
            {
              caption: '',

              id: 'b8a55d52-487e-47c7-b723-ef3e947bc1dd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/79008719-3bfb-4b5d-9d7c-17c6d2f9a0df/ugc/b925ea75-2fce-44da-8e9b-77e96a96ad13/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd07250ef-2f2d-467e-a89d-e577264e0686',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/79008719-3bfb-4b5d-9d7c-17c6d2f9a0df/ugc/b925ea75-2fce-44da-8e9b-77e96a96ad13/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b715bb4a-a390-45e4-82b9-cd690e2bc580',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/79008719-3bfb-4b5d-9d7c-17c6d2f9a0df/ugc/b925ea75-2fce-44da-8e9b-77e96a96ad13/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '202156ab-eb44-4baf-abc1-60a80f15a3e2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:18:20.420Z',

          createdAt: '2024-05-27T15:02:56.981Z',
          content: [
            {
              caption: '',

              id: 'd1cea3b5-ce74-483e-8a51-c15257756b9b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/79008719-3bfb-4b5d-9d7c-17c6d2f9a0df/ugc/202156ab-eb44-4baf-abc1-60a80f15a3e2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c375cf16-dbc0-4e5c-91cc-63802821ae32',
        firstName: 'Iryna',
        lastName: 'Zharko',
        email: 'iragavryliuk03@gmail.com',
        instagramProfile: {
          handle: 'iryna_ugc_usa',
          followers: 7413,
          engagementRate: 0.5719681640361527
        },
        tiktokProfile: {
          handle: 'irysichka3',
          followers: 212,
          engagementRate: 7.166123778501629
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T12:36:37.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '94884225-1f17-4a2c-b4b1-87112987823b',
      content: [
        {
          groupId: '5ebf13cb-6983-4056-90ac-de43b31d04e3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T02:14:20.794Z',

          createdAt: '2024-06-05T00:42:06.397Z',
          content: [
            {
              caption: '',

              id: 'dbb50137-82c2-4675-b0b9-cff27546bd50',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/94884225-1f17-4a2c-b4b1-87112987823b/ugc/5ebf13cb-6983-4056-90ac-de43b31d04e3/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '63fe12ca-4fe7-43ca-9148-6572e72ce928',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T19:44:03.191Z',

          createdAt: '2024-06-05T00:24:48.884Z',
          content: [
            {
              caption: '',

              id: '7c123239-fdf9-4572-92c2-46e39ab9753f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/94884225-1f17-4a2c-b4b1-87112987823b/ugc/63fe12ca-4fe7-43ca-9148-6572e72ce928/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3e9613cf-afcb-487a-bec3-902bccb2b36c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/94884225-1f17-4a2c-b4b1-87112987823b/ugc/63fe12ca-4fe7-43ca-9148-6572e72ce928/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b3cf4bf0-1e5c-4cf7-9736-b6ac865582ef',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/94884225-1f17-4a2c-b4b1-87112987823b/ugc/63fe12ca-4fe7-43ca-9148-6572e72ce928/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6ac0c7b7-d64b-4225-bf96-cebf29d569ae',
        firstName: 'Amanda',
        lastName: 'Buchanan',
        email: 'amandabuchanancollabs@gmail.com',
        instagramProfile: {
          handle: 'amanda_buchanan_',
          followers: 1382,
          engagementRate: 2.134587554269175
        },
        tiktokProfile: {
          handle: 'amanda_buchanan_',
          followers: 329,
          engagementRate: 4.349097593582887
        },
        youtubeProfile: {
          handle: 'amandabuchanan3507',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T13:05:24.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5555ad05-fd03-4af3-8fcb-51da15398c69',
      content: [
        {
          groupId: 'ff2c917b-aa4c-44da-bc92-2b85238e8f98',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T21:43:23.570Z',

          createdAt: '2024-05-30T20:13:19.090Z',
          content: [
            {
              caption: '',

              id: 'c6a6b70a-c2f6-4cb3-8b03-26d180b0f59e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5555ad05-fd03-4af3-8fcb-51da15398c69/ugc/ff2c917b-aa4c-44da-bc92-2b85238e8f98/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd63d3462-f09e-4a4f-b4fc-14da757d13e6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5555ad05-fd03-4af3-8fcb-51da15398c69/ugc/ff2c917b-aa4c-44da-bc92-2b85238e8f98/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd278fb47-9556-4127-8512-ede81a4beaa0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5555ad05-fd03-4af3-8fcb-51da15398c69/ugc/ff2c917b-aa4c-44da-bc92-2b85238e8f98/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2a5bd6e7-3bd7-4b33-8235-0958cda57119',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T21:43:18.378Z',

          createdAt: '2024-05-30T20:27:50.470Z',
          content: [
            {
              caption: '',

              id: '981cf2f1-8a1d-4c35-84e9-a006b867b98d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5555ad05-fd03-4af3-8fcb-51da15398c69/ugc/2a5bd6e7-3bd7-4b33-8235-0958cda57119/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4fd1ce86-73dc-4562-b886-3ec7f522b377',
        firstName: 'Adelyn',
        lastName: 'Karst',
        email: 'adelyn.karst@gmail.com',
        instagramProfile: {
          handle: 'adelyn_karst',
          followers: 1492
        },
        tiktokProfile: {
          handle: 'addieek88',
          followers: 634,
          engagementRate: 13.043478260869565
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T13:15:13.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4e39b4e9-192f-4a21-be6f-8e124196bbb1',
      content: [
        {
          groupId: 'bfa3c810-0b27-4de4-ad29-c9f18dbd3e55',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T13:41:24.541Z',

          createdAt: '2024-05-29T23:07:34.130Z',
          content: [
            {
              caption: '',

              id: '53fbf666-ea97-4398-b517-81c899575816',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4e39b4e9-192f-4a21-be6f-8e124196bbb1/ugc/bfa3c810-0b27-4de4-ad29-c9f18dbd3e55/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '62f4abde-e0da-4ebc-83f2-dcb9985dc26c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T15:06:06.388Z',

          createdAt: '2024-05-30T13:41:59.694Z',
          content: [
            {
              caption: '',

              id: 'd21e7227-fba7-42ae-b14b-86bd28fe3e54',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4e39b4e9-192f-4a21-be6f-8e124196bbb1/ugc/62f4abde-e0da-4ebc-83f2-dcb9985dc26c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0156c137-d6ae-4f98-a1ed-f34d0a8b137b',
        firstName: 'alicia',
        lastName: 'moore',
        email: 'moorealicia309@yahoo.com',
        instagramProfile: {
          handle: 'alicia.nicole_',
          followers: 807,
          engagementRate: 4.448574969021066
        },
        tiktokProfile: {
          handle: 'alicianicole_',
          followers: 7841,
          engagementRate: 13.902712298469238
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T14:14:51.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2c714e0b-e35e-447a-907d-f732ea5b3bcc',
      content: [],
      creator: {
        id: '5862df39-47bb-4e15-a7f9-f73932d966e2',
        firstName: 'Erica',
        lastName: 'Marques',
        email: 'erica.marques2911@gmail.com',
        instagramProfile: {
          handle: 'ericaamarks',
          followers: 242124,
          engagementRate: 3.0222943615668005
        },
        tiktokProfile: {
          handle: 'ericaamarks',
          followers: 33192,
          engagementRate: 0.6593188690781103
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T15:18:16.000Z',
        amountCents: 16485
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cf98a444-7d63-4c81-983b-ac146f4284fe',
      content: [
        {
          groupId: '49742e95-bab9-4aa4-99a1-0110c4bf6355',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T02:23:26.472Z',

          createdAt: '2024-06-05T04:26:52.339Z',
          content: [
            {
              caption: '',

              id: '984e9a4e-ae54-40d5-9dac-4741ae734228',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cf98a444-7d63-4c81-983b-ac146f4284fe/ugc/49742e95-bab9-4aa4-99a1-0110c4bf6355/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '50ae0221-ca8f-4755-a24c-00596bd2cfef',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cf98a444-7d63-4c81-983b-ac146f4284fe/ugc/49742e95-bab9-4aa4-99a1-0110c4bf6355/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8072087c-f339-4747-9eee-ce92229a2f3d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-06T02:23:22.404Z',

          createdAt: '2024-06-03T06:18:38.295Z',
          content: [
            {
              caption: '',

              id: 'e7ab2e62-e726-470c-9326-ca354a9416aa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cf98a444-7d63-4c81-983b-ac146f4284fe/ugc/8072087c-f339-4747-9eee-ce92229a2f3d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '078322e7-12a0-4734-921c-5572c973a999',
        firstName: 'Jessy',
        lastName: 'Clark',
        email: 'jessymaradasilvaclark@gmail.com',
        instagramProfile: {
          handle: '_jessyclark',
          followers: 127247,
          engagementRate: 2.0915227863918204
        },
        tiktokProfile: {
          handle: '_jessyclark',
          followers: 5181,
          engagementRate: 4.976265361989633
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T15:31:55.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7d2dc033-05ae-424e-a45e-eac0780a7ae7',
      content: [
        {
          groupId: '91540652-d471-4fc6-a78e-c6fdb9177029',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T10:21:43.309Z',

          createdAt: '2024-05-29T18:25:36.967Z',
          content: [
            {
              caption: '',

              id: '1aa92bd0-69ba-41bc-b696-ec0e7b5026b5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d2dc033-05ae-424e-a45e-eac0780a7ae7/ugc/91540652-d471-4fc6-a78e-c6fdb9177029/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '02961e71-e4c2-4f07-864b-4e6dbdf8662e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:25:40.520Z',

          createdAt: '2024-05-28T18:40:04.789Z',
          content: [
            {
              caption: '',

              id: '409989cd-cc9e-4dc4-95d2-d6de748d84f1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d2dc033-05ae-424e-a45e-eac0780a7ae7/ugc/02961e71-e4c2-4f07-864b-4e6dbdf8662e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0fb94638-b114-4816-91ad-efd55f25f3e5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d2dc033-05ae-424e-a45e-eac0780a7ae7/ugc/02961e71-e4c2-4f07-864b-4e6dbdf8662e/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c3892f82-1176-4a46-a1de-c410456dc106',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d2dc033-05ae-424e-a45e-eac0780a7ae7/ugc/02961e71-e4c2-4f07-864b-4e6dbdf8662e/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '089ac68e-be5f-4c93-86c9-9bf1fda35bd0',
        firstName: 'CORBI',
        lastName: 'Greenwalt',
        email: 'corbicorb7@gmail.com',
        instagramProfile: {
          handle: 'misstryb4youbuy',
          followers: 14300,
          engagementRate: 6.448951048951049
        },
        tiktokProfile: {
          handle: 'unb0thered_777',
          followers: 1343,
          engagementRate: 9.333560851443407
        },
        youtubeProfile: {
          handle: 'thecorbichannel',
          followers: 14
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T15:32:59.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '84df9995-7bff-4a43-af5c-92a16cc397f9',
      content: [
        {
          groupId: '0bd1f6ed-a961-4a4d-b76a-aa417c22deb7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T13:20:30.991Z',

          createdAt: '2024-05-30T12:29:52.191Z',
          content: [
            {
              caption: '',

              id: '866db377-b217-4ea7-bd3b-c0205cfd81c4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84df9995-7bff-4a43-af5c-92a16cc397f9/ugc/0bd1f6ed-a961-4a4d-b76a-aa417c22deb7/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '26e96819-6c6d-452f-8d3d-31765974b0ba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84df9995-7bff-4a43-af5c-92a16cc397f9/ugc/0bd1f6ed-a961-4a4d-b76a-aa417c22deb7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '79bd1604-96e4-4081-8d45-7bd34daaf225',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T17:41:43.891Z',

          createdAt: '2024-05-31T06:39:22.177Z',
          content: [
            {
              caption: '',

              id: '40b5ed22-8e7a-4d52-8097-b6daa171c009',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/84df9995-7bff-4a43-af5c-92a16cc397f9/ugc/79bd1604-96e4-4081-8d45-7bd34daaf225/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7a003781-b78d-475e-83bd-e1746ab9485e',
        firstName: 'Madison',
        lastName: 'Jeffries',
        email: 'maddierjeffries@gmail.com',

        tiktokProfile: {
          handle: 'madisonrjeffries',
          followers: 3982,
          engagementRate: 19.897959183673468
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T15:33:38.000Z',
        amountCents: 8401
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c8fd5d94-2bee-4844-a370-2637bf89bcc4',
      content: [
        {
          groupId: '0f3be8ae-0e56-4c74-aff3-b9de2b3a5332',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T01:19:48.176Z',

          createdAt: '2024-05-27T18:20:01.576Z',
          content: [
            {
              caption: '',

              id: '8fb02cd8-5187-44c6-8824-dff0844ac678',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c8fd5d94-2bee-4844-a370-2637bf89bcc4/ugc/0f3be8ae-0e56-4c74-aff3-b9de2b3a5332/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0845d050-ee35-4c02-9d4e-52861fc5575c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T01:19:57.067Z',

          createdAt: '2024-05-27T18:18:39.809Z',
          content: [
            {
              caption: '',

              id: '3da050fe-de3c-4e1f-8119-ba0fffe101a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c8fd5d94-2bee-4844-a370-2637bf89bcc4/ugc/0845d050-ee35-4c02-9d4e-52861fc5575c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '92a9c9fe-37e4-401a-8005-9609801b532b',
        firstName: 'Tonya',
        lastName: 'Fox',
        email: 'mrs.fox323@gmail.com',
        instagramProfile: {
          handle: 'thicknbrwn',
          followers: 192,
          engagementRate: 0.6944444444444444
        },
        tiktokProfile: {
          handle: 'alluringbrowneyez',
          followers: 3679,
          engagementRate: 4.6875
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:13:40.000Z',
        amountCents: 7435
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b33c1de7-b6e2-4873-a218-a1d1008dd8e8',
      content: [],
      creator: {
        id: 'def71bcb-f264-4711-bff8-f7cb8c48cc8b',
        firstName: 'Jayme',
        lastName: 'Carpenter',
        email: 'ellison.jayme@yahoo.com',

        tiktokProfile: {
          handle: 'rizeabovefam',
          followers: 2353,
          engagementRate: 5.176880281989213
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:22:27.000Z',
        amountCents: 8720
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ea89fe6f-01c8-41c0-a61b-c77c1e585a30',
      content: [
        {
          groupId: 'aeff7947-7a19-44c3-a5b4-dddca6beb3a1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T09:33:33.434Z',

          createdAt: '2024-06-02T15:39:51.974Z',
          content: [
            {
              caption: '',

              id: 'cb717b29-67e3-43a0-9cde-1a1b39322672',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ea89fe6f-01c8-41c0-a61b-c77c1e585a30/ugc/aeff7947-7a19-44c3-a5b4-dddca6beb3a1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '5be793c0-3830-4c5a-b867-0bc276732cc1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T21:01:17.510Z',

          createdAt: '2024-05-31T02:37:49.790Z',
          content: [
            {
              caption: '',

              id: 'c9300b31-052d-471a-baa0-e01e06e9aa52',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ea89fe6f-01c8-41c0-a61b-c77c1e585a30/ugc/5be793c0-3830-4c5a-b867-0bc276732cc1/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b62d7b99-0e4c-4d14-b80d-aa02d62dd247',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ea89fe6f-01c8-41c0-a61b-c77c1e585a30/ugc/5be793c0-3830-4c5a-b867-0bc276732cc1/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b0b1c5e6-8b59-4200-809a-ec8adfea6d3e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ea89fe6f-01c8-41c0-a61b-c77c1e585a30/ugc/5be793c0-3830-4c5a-b867-0bc276732cc1/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4f3262f1-7df0-4f79-9eec-37ddd58709db',
        firstName: 'Thanique',
        lastName: 'Miller',
        email: 'thaniquemiller@yahoo.com',
        instagramProfile: {
          handle: '_than_sm',
          followers: 198
        },
        tiktokProfile: {
          handle: 'than_.sm',
          followers: 1835,
          engagementRate: 14.423416233362973
        },
        youtubeProfile: {
          handle: 'tmiller2233',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:25:08.000Z',
        amountCents: 7443
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ac48f420-55a6-4e2c-b64f-114576dc8299',
      content: [],
      creator: {
        id: 'e4a58a1d-55b0-46ed-88a0-764a53316288',
        firstName: 'Telamesha',
        lastName: 'Barlow',
        email: 'telamesha@gmail.com',

        tiktokProfile: {
          handle: 'tdejb',
          followers: 5938,
          engagementRate: 9.262475154304845
        },
        youtubeProfile: {
          handle: 'telameshadejanae',
          followers: 323
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:25:36.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '57f26b6f-88a3-4992-8522-6f18298b0099',
      content: [
        {
          groupId: 'd04f9e8f-7586-4b88-b0fb-abe4f4d29748',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T19:07:35.765Z',

          createdAt: '2024-06-04T20:32:35.337Z',
          content: [
            {
              caption: '',

              id: '5e22e1a3-632d-4315-87f7-23b8d2d8065a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/57f26b6f-88a3-4992-8522-6f18298b0099/ugc/d04f9e8f-7586-4b88-b0fb-abe4f4d29748/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bf27e461-3bd9-430b-bb75-51c80a7c104d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/57f26b6f-88a3-4992-8522-6f18298b0099/ugc/d04f9e8f-7586-4b88-b0fb-abe4f4d29748/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '891f1fb9-00a3-4b59-bd40-4c522bacd4c0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/57f26b6f-88a3-4992-8522-6f18298b0099/ugc/d04f9e8f-7586-4b88-b0fb-abe4f4d29748/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f7f6dcab-bec8-4317-a930-b9a7b85ff200',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T17:13:14.342Z',

          createdAt: '2024-06-04T20:28:41.281Z',
          content: [
            {
              caption: '',

              id: 'b7a00e4f-deab-4987-9ada-44dd6ae46e86',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/57f26b6f-88a3-4992-8522-6f18298b0099/ugc/f7f6dcab-bec8-4317-a930-b9a7b85ff200/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6d5feb16-1a33-4860-bfe8-12f449a3444f',
        firstName: 'Haley',
        lastName: 'Cox',
        email: 'hayheller@yahoo.com',
        instagramProfile: {
          handle: 'hayycoxx',
          followers: 3183,
          engagementRate: 0.6000628338045869
        },
        tiktokProfile: {
          handle: 'hayycoxx',
          followers: 411,
          engagementRate: 2.424202303720376
        },
        youtubeProfile: {
          handle: 'haleycox380'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:26:15.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a05099e5-1f91-4bd1-a07c-a79e921ee41f',
      content: [],
      creator: {
        id: '629ff385-f98c-45a8-a9b7-23611ac3e94f',
        firstName: 'Ashley',
        lastName: 'Hammock',
        email: 'ahammock1@gmail.com',
        instagramProfile: {
          handle: 'o0oitsashley',
          followers: 10270,
          engagementRate: 0.37163258682246025
        },
        tiktokProfile: {
          handle: 'o0oitsashleymarie',
          followers: 390,
          engagementRate: 0.136986301369863
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:30:01.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd0956c6d-7758-4ae2-8a21-1c10603efb61',
      content: [
        {
          groupId: 'fb870186-436c-44d1-b5d2-ef97d82d8a3a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T17:27:40.881Z',

          createdAt: '2024-06-05T06:08:15.858Z',
          content: [
            {
              caption: '',

              id: 'b38d68b5-4623-49b4-9204-1442aa02b689',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d0956c6d-7758-4ae2-8a21-1c10603efb61/ugc/fb870186-436c-44d1-b5d2-ef97d82d8a3a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '8c27f260-9fc3-4c0d-bdce-96f7b4e72629',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T23:16:01.302Z',

          createdAt: '2024-06-04T05:11:18.596Z',
          content: [
            {
              caption: '',

              id: '77199f85-28cd-4a36-a5e8-9fc76e60d7de',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d0956c6d-7758-4ae2-8a21-1c10603efb61/ugc/8c27f260-9fc3-4c0d-bdce-96f7b4e72629/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '22328179-9f09-4466-9fc3-067325e69648',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d0956c6d-7758-4ae2-8a21-1c10603efb61/ugc/8c27f260-9fc3-4c0d-bdce-96f7b4e72629/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '43e0d32d-8645-4f7b-99b6-c4f19cbefeba',
        firstName: 'Briana',
        lastName: 'Villasenor',
        email: 'brianavillasenorofficial@gmail.com',
        instagramProfile: {
          handle: 'brivillasenor',
          followers: 409,
          engagementRate: 7.408312958435208
        },
        tiktokProfile: {
          handle: 'briana.villasenor',
          followers: 4270,
          engagementRate: 10.106083171894307
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:31:36.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c1e2e898-64fa-4ee3-8246-4da19556b230',
      content: [
        {
          groupId: 'bf6f22c9-5e4f-453d-9df2-d8a0f8c6f1c7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T14:12:49.196Z',

          createdAt: '2024-06-02T01:22:35.166Z',
          content: [
            {
              caption: '',

              id: 'aa965a4f-2573-4662-9f41-c3f6c00a155d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c1e2e898-64fa-4ee3-8246-4da19556b230/ugc/bf6f22c9-5e4f-453d-9df2-d8a0f8c6f1c7/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '33fe906a-70fe-4ff0-b6a1-504ad243a3b1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c1e2e898-64fa-4ee3-8246-4da19556b230/ugc/bf6f22c9-5e4f-453d-9df2-d8a0f8c6f1c7/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c32d67df-7feb-41ba-9e99-31daca1a687d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c1e2e898-64fa-4ee3-8246-4da19556b230/ugc/bf6f22c9-5e4f-453d-9df2-d8a0f8c6f1c7/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9b5a5f1c-b40c-4803-bc6f-172f8f94b7b5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T04:48:04.990Z',

          createdAt: '2024-06-02T01:21:46.628Z',
          content: [
            {
              caption: '',

              id: '77061870-ea92-4316-bec9-be39b8a16d0b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c1e2e898-64fa-4ee3-8246-4da19556b230/ugc/9b5a5f1c-b40c-4803-bc6f-172f8f94b7b5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '101fbd3e-1858-472b-8c06-9612a0a2ad6b',
        firstName: 'AnaSofia',
        lastName: 'Cespedes',
        email: 'anasofiacdes@gmail.com',

        tiktokProfile: {
          handle: 'anasofiaspov',
          followers: 39818,
          engagementRate: 9.27031568410139
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:32:38.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '86d51e1e-8443-41be-a16b-bea609a1e3e6',
      content: [
        {
          groupId: '85356865-7432-4703-82f6-413f7400594c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-02T14:27:47.985Z',

          createdAt: '2024-06-28T21:07:30.431Z',
          content: [
            {
              caption: '',

              id: '4fa76dd6-c20f-496e-972a-1b50d0de018f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/86d51e1e-8443-41be-a16b-bea609a1e3e6/ugc/85356865-7432-4703-82f6-413f7400594c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a789b0c7-ee78-4df9-8c2e-04d38d130cea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/86d51e1e-8443-41be-a16b-bea609a1e3e6/ugc/85356865-7432-4703-82f6-413f7400594c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c832f527-50c4-4b1c-9218-d3b8ce5210a2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/86d51e1e-8443-41be-a16b-bea609a1e3e6/ugc/85356865-7432-4703-82f6-413f7400594c/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8dc185d0-b5cf-489a-ba6f-e6c32fdbb74a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T13:15:56.480Z',

          createdAt: '2024-07-08T03:53:40.401Z',
          content: [
            {
              caption: '',

              id: '69a69e3d-35a5-4478-abe3-f7bd4221dd40',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/86d51e1e-8443-41be-a16b-bea609a1e3e6/ugc/8dc185d0-b5cf-489a-ba6f-e6c32fdbb74a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f633e4cc-58da-4951-a560-bae240363e54',
        firstName: 'Danielle',
        lastName: 'Reid',
        email: 'danielle@drandassociates.com',
        instagramProfile: {
          handle: 'iamdaniellereid',
          followers: 9404,
          engagementRate: 1.271799234368354
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:34:54.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c26d20b3-85bb-4cb3-990d-2ba101d97101',
      content: [
        {
          groupId: '94c1c035-a466-4c41-947c-35acc4e6307e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:22:54.513Z',

          createdAt: '2024-05-28T19:43:36.321Z',
          content: [
            {
              caption: '',

              id: '6022c69b-69fd-4363-b052-f57b75c4f910',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c26d20b3-85bb-4cb3-990d-2ba101d97101/ugc/94c1c035-a466-4c41-947c-35acc4e6307e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '109fdea5-b596-4536-9d14-10fc6b6062bc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:18:49.279Z',

          createdAt: '2024-05-28T19:38:35.030Z',
          content: [
            {
              caption: '',

              id: '8a89b9fb-019b-4fa6-871a-bdf331876d7f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c26d20b3-85bb-4cb3-990d-2ba101d97101/ugc/109fdea5-b596-4536-9d14-10fc6b6062bc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4d5b3568-67b0-47a7-b29c-b02b10d26b56',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c26d20b3-85bb-4cb3-990d-2ba101d97101/ugc/109fdea5-b596-4536-9d14-10fc6b6062bc/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'edbcaf7f-3b66-4e07-af6e-822e34f464f5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c26d20b3-85bb-4cb3-990d-2ba101d97101/ugc/109fdea5-b596-4536-9d14-10fc6b6062bc/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9ec01d65-2526-4af0-90a5-a7342548e5b7',
        firstName: 'Abigayle',
        lastName: 'McLaughlin',
        email: 'abigayle.m.mclaughlin@gmail.com',

        tiktokProfile: {
          handle: 'airfriedvegan',
          followers: 5117,
          engagementRate: 11.81372782568647
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T16:43:50.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '422294f9-8f97-4fe6-b316-8d127404320d',
      content: [
        {
          groupId: 'ef378be4-657b-4711-988a-10810602bd3d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T11:09:49.238Z',

          createdAt: '2024-05-25T16:39:50.016Z',
          content: [
            {
              caption: '',

              id: '4081ed11-5e70-42d6-8721-d240fe166951',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/422294f9-8f97-4fe6-b316-8d127404320d/ugc/ef378be4-657b-4711-988a-10810602bd3d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bc988f5b-c227-4b5e-8b34-abf187afd2fa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T11:10:04.591Z',

          createdAt: '2024-05-25T16:40:43.043Z',
          content: [
            {
              caption: '',

              id: '8441b4fd-5ada-4dad-99a5-c9664b23ed16',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/422294f9-8f97-4fe6-b316-8d127404320d/ugc/bc988f5b-c227-4b5e-8b34-abf187afd2fa/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4d498c5c-48db-4d52-a2c1-8e6e85415470',
        firstName: 'Shanelle',
        lastName: 'Shay',
        email: 'shanelleshay101@gmail.com',
        instagramProfile: {
          handle: 'dshaycollection',
          followers: 479,
          engagementRate: 2.4374999999999996
        },
        tiktokProfile: {
          handle: 'explorewithshay',
          followers: 3888,
          engagementRate: 6.8447441250401475
        },
        youtubeProfile: {
          handle: 'explorewithshay__',
          followers: 100
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:02:11.000Z',
        amountCents: 11742
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '517b9348-b942-42ca-ae99-7a5e85a3928c',
      content: [
        {
          groupId: '12fc29c8-573f-42dc-8dad-27d07c84e703',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T14:57:17.594Z',

          createdAt: '2024-05-30T12:09:23.050Z',
          content: [
            {
              caption: '',

              id: '57e48676-0516-4f5b-a79a-e84d1257f0c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/517b9348-b942-42ca-ae99-7a5e85a3928c/ugc/12fc29c8-573f-42dc-8dad-27d07c84e703/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd3546371-dc13-4ccf-8d2c-8581f634ce99',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-01T19:58:19.160Z',

          createdAt: '2024-05-27T13:50:49.139Z',
          content: [
            {
              caption: '',

              id: '5ec9869b-3142-4c62-9bce-ec2b331ed40f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/517b9348-b942-42ca-ae99-7a5e85a3928c/ugc/d3546371-dc13-4ccf-8d2c-8581f634ce99/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c304e27e-bf54-48ff-8c8f-a4b6ff56ce6c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/517b9348-b942-42ca-ae99-7a5e85a3928c/ugc/d3546371-dc13-4ccf-8d2c-8581f634ce99/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b9e627ef-f7af-4907-879e-8f65e46e6baf',
        firstName: 'Patrick',
        lastName: 'Detamore',
        email: 'aarondetamore@gmail.com',
        instagramProfile: {
          handle: 'pawesome_pals',
          followers: 4218,
          engagementRate: 0.7191401928244033
        },
        tiktokProfile: {
          handle: 'aarondetamore',
          followers: 3
        },
        youtubeProfile: {
          handle: 'aarondetamore6524',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:03:02.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'daec921e-13eb-4a45-bf84-4ae38b207094',
      content: [
        {
          groupId: 'd17ca84e-64e5-4368-b833-af4d68b137d9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-01T18:28:14.892Z',

          createdAt: '2024-05-29T18:26:31.382Z',
          content: [
            {
              caption: '',

              id: 'ef3be6d4-2c1c-48bf-9518-d54bf691a2d1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/daec921e-13eb-4a45-bf84-4ae38b207094/ugc/d17ca84e-64e5-4368-b833-af4d68b137d9/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a8536618-3ed5-4043-af21-7d27c162c631',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/daec921e-13eb-4a45-bf84-4ae38b207094/ugc/d17ca84e-64e5-4368-b833-af4d68b137d9/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8b089f82-068f-4e66-b27e-687f92270d7a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T01:44:13.856Z',

          createdAt: '2024-05-29T18:25:49.947Z',
          content: [
            {
              caption: '',

              id: '72ec6ff6-d9c8-405f-8f56-edff62b1a9d5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/daec921e-13eb-4a45-bf84-4ae38b207094/ugc/8b089f82-068f-4e66-b27e-687f92270d7a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd68d4d95-681b-406c-a14d-907d811378cc',
        firstName: 'Tia',
        lastName: 'Doyley',
        email: 'tiadoyley553@gmail.com',

        tiktokProfile: {
          handle: 'pinkey_not_so_friendly',
          followers: 9138,
          engagementRate: 11.855670103092782
        },
        youtubeProfile: {
          handle: 'princessniah-yd4wz',
          followers: 13
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:03:23.000Z',
        amountCents: 8333
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1c938a7c-9c7b-41bb-97eb-225e4d309c0c',
      content: [
        {
          groupId: '26d8b8a0-ae0f-47d4-bfa5-f529ed6cab30',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-13T04:38:36.693Z',

          createdAt: '2024-06-01T11:29:59.522Z',
          content: [
            {
              caption: '',

              id: '7d98a48d-e9e6-4f01-891d-4f00e978fb1b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c938a7c-9c7b-41bb-97eb-225e4d309c0c/ugc/26d8b8a0-ae0f-47d4-bfa5-f529ed6cab30/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c5d3a84f-4308-4a96-a041-0b556656c6f8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c938a7c-9c7b-41bb-97eb-225e4d309c0c/ugc/26d8b8a0-ae0f-47d4-bfa5-f529ed6cab30/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '13d8941b-fa00-4832-8901-2a9ee2026191',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c938a7c-9c7b-41bb-97eb-225e4d309c0c/ugc/26d8b8a0-ae0f-47d4-bfa5-f529ed6cab30/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2c826c3f-43ee-44b4-b940-bab2e054039f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T11:33:19.289Z',

          createdAt: '2024-06-13T03:12:59.644Z',
          content: [
            {
              caption: '',

              id: '74507657-9248-4855-b25a-bb5216938299',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c938a7c-9c7b-41bb-97eb-225e4d309c0c/ugc/2c826c3f-43ee-44b4-b940-bab2e054039f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a0fbe3fd-b39a-480f-9048-a28b2598a4d2',
        firstName: 'Alecia',
        lastName: 'Daley',
        email: 'tfrasskull@gmail.com',
        instagramProfile: {
          handle: 'street_champs_music',
          followers: 462,
          engagementRate: 8.008658008658008
        },
        tiktokProfile: {
          handle: 'saskyberry',
          followers: 1648
        },
        youtubeProfile: {
          handle: 'saskyberry5753',
          followers: 130
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:12:42.000Z',
        amountCents: 14908
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'df4def28-2275-4929-8367-7e388057e757',
      content: [
        {
          groupId: 'cc29889d-32a0-41cd-bc4d-6ab9f50e52dd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-26T22:41:20.978Z',

          createdAt: '2024-06-18T16:17:28.481Z',
          content: [
            {
              caption: '',

              id: 'a87e3d70-ddde-438f-b656-23e590b4faa7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/df4def28-2275-4929-8367-7e388057e757/ugc/cc29889d-32a0-41cd-bc4d-6ab9f50e52dd/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'aa07ef43-b6e2-474d-85a0-2300140764ae',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/df4def28-2275-4929-8367-7e388057e757/ugc/cc29889d-32a0-41cd-bc4d-6ab9f50e52dd/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c616609c-5d18-46f4-815d-8cdb111e2e11',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/df4def28-2275-4929-8367-7e388057e757/ugc/cc29889d-32a0-41cd-bc4d-6ab9f50e52dd/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0947f0b4-593b-4b88-a27f-d2146347982b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T22:41:16.011Z',

          createdAt: '2024-06-18T16:16:32.484Z',
          content: [
            {
              caption: '',

              id: 'fbe9611d-85f4-440e-a563-3ac4fa3475fc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/df4def28-2275-4929-8367-7e388057e757/ugc/0947f0b4-593b-4b88-a27f-d2146347982b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd239bd9e-5e57-4ce3-8e64-3bb4079a455b',
        firstName: 'Sabrina',
        lastName: 'Kozora',
        email: 'collabwithbrinadoe@gmail.com',
        instagramProfile: {
          handle: 'brinadoe',
          followers: 4945,
          engagementRate: 0.7785642062689585
        },

        youtubeProfile: {
          handle: 'sabrinaleighton03',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:13:51.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '098d2776-6b7a-4840-a4ea-cfb18ea27bd6',
      content: [
        {
          groupId: 'b30ce0bb-ade0-465a-bf4d-589a082a53a1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T03:51:25.605Z',

          createdAt: '2024-07-22T20:52:03.786Z',
          content: [
            {
              caption: '',

              id: '0b75182d-0a9c-4f15-9758-f40b38fda08b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/098d2776-6b7a-4840-a4ea-cfb18ea27bd6/ugc/b30ce0bb-ade0-465a-bf4d-589a082a53a1/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'aec5cc01-26e4-4dd4-89e5-f85f90d9318a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T03:51:07.960Z',

          createdAt: '2024-07-22T20:51:38.245Z',
          content: [
            {
              caption: '',

              id: 'e5250a4f-ab3f-4138-a5a6-5111465368c8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/098d2776-6b7a-4840-a4ea-cfb18ea27bd6/ugc/aec5cc01-26e4-4dd4-89e5-f85f90d9318a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '69ad3f79-c339-4dd4-bbb9-58f515568a37',
        firstName: 'Jill',
        lastName: 'Thompson',
        email: 'jillvictoriaandlily@gmail.com',
        instagramProfile: {
          handle: 'parentingonaprayerandcoffee',
          followers: 6493,
          engagementRate: 0.6761127367934698
        },
        tiktokProfile: {
          handle: 'parentingonaprayerandcof',
          followers: 224,
          engagementRate: 0.671630418217839
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:18:33.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e0aa51f8-00a4-41e2-818d-69878fcf7459',
      content: [],
      creator: {
        id: 'c0953123-8378-4219-aecf-66fa84800517',
        firstName: 'Kimmy',
        lastName: 'Austin',
        email: 'austinkimara@gmail.com',

        tiktokProfile: {
          handle: 'kerry_ker20',
          followers: 2184
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:23:48.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a059d75c-27de-4d3e-b49b-e2807ac9cf48',
      content: [
        {
          groupId: '64868eb1-c9d7-4d21-9f1c-4165fbf325be',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:10:00.744Z',

          createdAt: '2024-06-03T13:02:41.614Z',
          content: [
            {
              caption: '',

              id: 'c7c7d940-554a-43d4-a71b-7994f96e04d0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a059d75c-27de-4d3e-b49b-e2807ac9cf48/ugc/64868eb1-c9d7-4d21-9f1c-4165fbf325be/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'de3edc6d-1a35-4b06-950e-5108842f2b87',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T17:18:50.469Z',

          createdAt: '2024-06-03T13:04:04.403Z',
          content: [
            {
              caption: '',

              id: '98df35b1-8173-4e94-96b1-ad934e9b3e53',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a059d75c-27de-4d3e-b49b-e2807ac9cf48/ugc/de3edc6d-1a35-4b06-950e-5108842f2b87/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9cbb93cb-6f98-4216-8dd4-181f8a4ab0c0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a059d75c-27de-4d3e-b49b-e2807ac9cf48/ugc/de3edc6d-1a35-4b06-950e-5108842f2b87/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b3d0bb82-6a9b-4052-87c7-26ee227dd0a5',
        firstName: 'Meghan',
        lastName: 'Tra',
        email: 'meghan.tra.222@gmail.com',
        instagramProfile: {
          handle: 'lifewithasideofskincare',
          followers: 2895,
          engagementRate: 0.0833160621761658
        },
        tiktokProfile: {
          handle: 'capricornchronicles',
          followers: 377,
          engagementRate: 7.9770729985322255
        },
        youtubeProfile: {
          handle: 'megtra4504',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:32:02.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5888c903-c288-45a7-9c75-63e425e450de',
      content: [
        {
          groupId: '6240b017-7bc4-4821-8f42-6addd533a7bb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-28T12:18:26.261Z',

          createdAt: '2024-06-26T04:51:35.442Z',
          content: [
            {
              caption: '',

              id: '7ade74cd-6ca6-44b8-83f6-8c53b640bb87',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5888c903-c288-45a7-9c75-63e425e450de/ugc/6240b017-7bc4-4821-8f42-6addd533a7bb/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '47864686-d8c9-4904-add6-ee36b1600975',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5888c903-c288-45a7-9c75-63e425e450de/ugc/6240b017-7bc4-4821-8f42-6addd533a7bb/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c491b3e2-097a-45c6-aaf6-3cd0de2d5dd1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5888c903-c288-45a7-9c75-63e425e450de/ugc/6240b017-7bc4-4821-8f42-6addd533a7bb/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b4112b98-01bb-4581-a266-0e8aba6de428',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T12:18:21.781Z',

          createdAt: '2024-06-26T04:51:12.639Z',
          content: [
            {
              caption: '',

              id: 'f6820c8f-d8e0-4d9d-a2c2-37dc8bf5ef1d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5888c903-c288-45a7-9c75-63e425e450de/ugc/b4112b98-01bb-4581-a266-0e8aba6de428/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'fafc838d-527b-4e9c-8d38-1f0206039035',
        firstName: 'Alexis',
        lastName: 'McCreary',
        email: 'alexismccreary333@gmail.com',
        instagramProfile: {
          handle: 'alexismccreary_',
          followers: 665,
          engagementRate: 8.601503759398495
        },
        tiktokProfile: {
          handle: 'alexismccreary_',
          followers: 4015,
          engagementRate: 7.451923076923077
        },
        youtubeProfile: {
          handle: 'lifewithlex2578'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:35:03.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1c69761d-f77e-4e55-875e-bcee25eb0f54',
      content: [
        {
          groupId: 'a252ad9e-64c1-426d-8737-fc18bf9c040b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T11:41:02.925Z',

          createdAt: '2024-06-08T20:37:21.385Z',
          content: [
            {
              caption: '',

              id: 'd33fe736-4621-49cd-964c-0adeb3e9aea4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c69761d-f77e-4e55-875e-bcee25eb0f54/ugc/a252ad9e-64c1-426d-8737-fc18bf9c040b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cc3e3fa8-16e0-4fb5-a7b8-71b4de9ee7e1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T11:40:53.308Z',

          createdAt: '2024-06-08T20:38:24.780Z',
          content: [
            {
              caption: '',

              id: '830bf0fc-38a2-4d32-a623-b377ab551dc8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c69761d-f77e-4e55-875e-bcee25eb0f54/ugc/cc3e3fa8-16e0-4fb5-a7b8-71b4de9ee7e1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8a35c48b-95bf-4214-bc04-7f0ddf6befe7',
        firstName: 'Catrina',
        lastName: 'Dickens',
        email: 'catrinad71@gmail.com',
        instagramProfile: {
          handle: 'catd71',
          followers: 995,
          engagementRate: 0.8040201005025126
        },
        tiktokProfile: {
          handle: 'catrinaa.dickens',
          followers: 142
        },
        youtubeProfile: {
          handle: 'catrinadickens9914',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:45:50.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '918c4d6d-9cf1-42d5-97cd-651990842b4d',
      content: [
        {
          groupId: '676fb827-8739-48a5-942f-9b5369772d8d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T09:03:25.451Z',

          createdAt: '2024-06-08T23:12:27.983Z',
          content: [
            {
              caption: '',

              id: 'd08a9ca3-3fdc-431d-94a5-0005fdd58a2b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/918c4d6d-9cf1-42d5-97cd-651990842b4d/ugc/676fb827-8739-48a5-942f-9b5369772d8d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3ae63c04-eeba-4efc-ad9d-2105ac5d4737',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T21:19:29.285Z',

          createdAt: '2024-06-05T21:00:36.926Z',
          content: [
            {
              caption: '',

              id: 'b67092cb-12f1-49bc-b432-bc6b03ed0264',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/918c4d6d-9cf1-42d5-97cd-651990842b4d/ugc/3ae63c04-eeba-4efc-ad9d-2105ac5d4737/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a32c10e2-da41-453b-ba72-40a996a1ecbc',
        firstName: 'Avagay',
        lastName: 'Bingham',
        email: 'doseofava9@gmail.com',
        instagramProfile: {
          handle: 'prettylittlefingersja',
          followers: 333,
          engagementRate: 1.3513513513513513
        },
        tiktokProfile: {
          handle: 'doseof_ava',
          followers: 1279,
          engagementRate: 3.2679738562091507
        },
        youtubeProfile: {
          handle: 'doseofava',
          followers: 100
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T17:53:29.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6b065fb9-f536-4164-9e95-dd2f1badaa91',
      content: [],
      creator: {
        id: '2335837e-9b95-45de-a2ca-de0228dd7aa4',
        firstName: 'Jordan',
        lastName: 'Ramirez',
        email: 'testedbyjo@gmail.com',
        instagramProfile: {
          handle: 'testedbyjo',
          followers: 1691,
          engagementRate: 7.8345802161263505
        },
        tiktokProfile: {
          handle: 'testedbyjo',
          followers: 683
        },
        youtubeProfile: {
          handle: 'testedbyjo',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T18:05:38.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a1b41041-8da6-4341-b041-0d490d9667f7',
      content: [],
      creator: {
        id: 'a4344819-6ab3-4014-a2f8-ea44a84007c1',
        firstName: 'Jennifer',
        lastName: 'Martinez',
        email: 'jennifer_martinez36@yahoo.com',

        tiktokProfile: {
          handle: 'jnieeboo',
          followers: 1515,
          engagementRate: 11.830985915492958
        },
        youtubeProfile: {
          handle: 'patito36',
          followers: 6
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T18:06:03.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7d235c79-842b-4431-ae61-9461bdde634c',
      content: [],
      creator: {
        id: 'ba23eb62-eec6-4dd7-9f89-91a16139bc40',
        firstName: 'Aysia',
        lastName: 'Sawyer',
        email: 'willis.aysia@yahoo.com',

        tiktokProfile: {
          handle: 'aysiasawyer',
          followers: 34364
        },
        youtubeProfile: {
          handle: 'luxuriousluxe',
          followers: 7960
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T18:32:04.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '25b0fe81-fd7d-4b37-8f38-f1a0712aefa4',
      content: [
        {
          groupId: '8d55cb33-cc19-4245-877e-2568cdf9a07b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T12:55:06.170Z',

          createdAt: '2024-05-29T21:54:21.241Z',
          content: [
            {
              caption: '',

              id: '6ab9a38e-f368-40b8-888a-d2f179c07df6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/25b0fe81-fd7d-4b37-8f38-f1a0712aefa4/ugc/8d55cb33-cc19-4245-877e-2568cdf9a07b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a4f6bc68-6169-4a4c-93bc-5dd6930a71f8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T17:15:27.773Z',

          createdAt: '2024-05-31T16:27:58.453Z',
          content: [
            {
              caption: '',

              id: '390e0beb-92bd-4531-90cb-f175cf1c8f6d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/25b0fe81-fd7d-4b37-8f38-f1a0712aefa4/ugc/a4f6bc68-6169-4a4c-93bc-5dd6930a71f8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'de483ba7-dcaa-41f8-a4a0-c0c4a0bf9e57',
        firstName: 'Katelin',
        lastName: 'Mansel',
        email: 'katelinmansel1@yahoo.com',

        tiktokProfile: {
          handle: 'katelinmansel',
          followers: 2527,
          engagementRate: 2.3510644427159852
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T18:32:15.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ef5dd899-b393-40d3-ac9c-5cd2d73120e4',
      content: [
        {
          groupId: 'f4c38415-102b-4415-8cee-3f27972a973b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T03:21:23.891Z',

          createdAt: '2024-06-03T00:34:05.285Z',
          content: [
            {
              caption: '',

              id: '0f379d31-7138-499c-b2b3-95e1e18d5df2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ef5dd899-b393-40d3-ac9c-5cd2d73120e4/ugc/f4c38415-102b-4415-8cee-3f27972a973b/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4b19b5ae-2d24-4d5f-a206-4b040834ed48',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ef5dd899-b393-40d3-ac9c-5cd2d73120e4/ugc/f4c38415-102b-4415-8cee-3f27972a973b/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '829f2cac-b3df-495b-8b10-79fa4d9cdd1a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ef5dd899-b393-40d3-ac9c-5cd2d73120e4/ugc/f4c38415-102b-4415-8cee-3f27972a973b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a864dffc-83a2-4a87-be20-748eb53ddd21',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T01:12:11.659Z',

          createdAt: '2024-06-03T00:33:01.787Z',
          content: [
            {
              caption: '',

              id: '3f307ec9-c780-4870-a0a9-23bc608a3131',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ef5dd899-b393-40d3-ac9c-5cd2d73120e4/ugc/a864dffc-83a2-4a87-be20-748eb53ddd21/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3f8c4ad9-d94c-4776-88a4-e573a257ffc2',
        firstName: 'Aleia',
        lastName: 'Brown',
        email: 'dalley52@yahoo.com',
        instagramProfile: {
          handle: 'alleyy.y',
          followers: 3001,
          engagementRate: 1.1196267910696434
        },
        tiktokProfile: {
          handle: 'reallyalley',
          followers: 521,
          engagementRate: 16.01615074024226
        },
        youtubeProfile: {
          handle: 'realley1373',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T18:35:45.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f19e619a-1ddd-4d25-aa26-a482ce293906',
      content: [
        {
          groupId: '39b1edf4-453f-4177-a31e-423984914e14',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T06:06:39.886Z',

          createdAt: '2024-06-03T19:36:40.476Z',
          content: [
            {
              caption: '',

              id: 'deb96240-33bc-4668-a67a-b38b29882b81',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f19e619a-1ddd-4d25-aa26-a482ce293906/ugc/39b1edf4-453f-4177-a31e-423984914e14/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1c5bb0c3-8408-4ec8-a217-d3e2d74762dc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f19e619a-1ddd-4d25-aa26-a482ce293906/ugc/39b1edf4-453f-4177-a31e-423984914e14/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a6cbbd8b-07ba-4e91-bda2-ddc7ce620755',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f19e619a-1ddd-4d25-aa26-a482ce293906/ugc/39b1edf4-453f-4177-a31e-423984914e14/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '17a2433e-a3bc-4c9c-be4b-44bf2a9bb50d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:31:53.536Z',

          createdAt: '2024-06-03T18:32:36.255Z',
          content: [
            {
              caption: '',

              id: '454c1e9b-91ff-43de-b2b2-7b5836fb3863',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f19e619a-1ddd-4d25-aa26-a482ce293906/ugc/17a2433e-a3bc-4c9c-be4b-44bf2a9bb50d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c76c41bb-f67c-4b45-a37c-cd5fc7a43c01',
        firstName: 'Yesenia',
        lastName: 'Lopez',
        email: 'leon.yes316@gmail.com',

        tiktokProfile: {
          handle: 'its.yessie_',
          followers: 10844,
          engagementRate: 10.570849057287978
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T19:12:36.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '15508f30-5a02-46b0-b12f-dd87cbc2dfd4',
      content: [
        {
          groupId: '8a38b771-e949-4a53-9841-bb40e6a88852',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:19:17.099Z',

          createdAt: '2024-05-29T02:06:00.139Z',
          content: [
            {
              caption: '',

              id: '06cc41c8-8fc7-47a4-84d2-2b58b522cef2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/15508f30-5a02-46b0-b12f-dd87cbc2dfd4/ugc/8a38b771-e949-4a53-9841-bb40e6a88852/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9ea0d026-fdb0-4cb7-a607-13d32f99a69d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/15508f30-5a02-46b0-b12f-dd87cbc2dfd4/ugc/8a38b771-e949-4a53-9841-bb40e6a88852/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '608b56cb-993e-4684-ade7-07ca0786cc56',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/15508f30-5a02-46b0-b12f-dd87cbc2dfd4/ugc/8a38b771-e949-4a53-9841-bb40e6a88852/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5489760e-d2ee-458b-bc96-4dfa8fb014a1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:30:01.409Z',

          createdAt: '2024-05-29T02:44:12.658Z',
          content: [
            {
              caption: '',

              id: '7dfbe423-9838-47b7-b970-13563fa10051',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/15508f30-5a02-46b0-b12f-dd87cbc2dfd4/ugc/5489760e-d2ee-458b-bc96-4dfa8fb014a1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bd21949b-c7a9-4473-a652-d05996e1da08',
        firstName: 'Raymond',
        lastName: 'Anacaya',
        email: 'stev_jang@yahoo.com',
        instagramProfile: {
          handle: 'raym0nd3o',
          followers: 171,
          engagementRate: 1.1764705882352942
        },
        tiktokProfile: {
          handle: 'jnl.rmlcio',
          followers: 15350,
          engagementRate: 5.012531328320802
        },
        youtubeProfile: {
          handle: 'stevjang1',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T19:22:06.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4a517fa9-ad8a-40fa-baaa-c0461847e607',
      content: [
        {
          groupId: 'fd3c8923-f108-44e9-84b2-0c6b2dd67430',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T14:06:33.325Z',

          createdAt: '2024-05-29T18:59:00.466Z',
          content: [
            {
              caption: '',

              id: 'c570e938-cc45-49a0-8a7f-728beff83ed0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4a517fa9-ad8a-40fa-baaa-c0461847e607/ugc/fd3c8923-f108-44e9-84b2-0c6b2dd67430/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '08808912-8a2d-4a81-94a4-60f123a8015b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4a517fa9-ad8a-40fa-baaa-c0461847e607/ugc/fd3c8923-f108-44e9-84b2-0c6b2dd67430/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '38c6e336-b35d-4ad2-a825-35b96a4d181a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4a517fa9-ad8a-40fa-baaa-c0461847e607/ugc/fd3c8923-f108-44e9-84b2-0c6b2dd67430/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a53c2f84-5b41-464b-bdce-3d82552d1324',
        firstName: 'Krysti',
        lastName: 'Griffith',
        email: 'themrsgriff@gmail.com',

        tiktokProfile: {
          handle: 'themrsgriff',
          followers: 16998,
          engagementRate: 1.8927098726792844
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T19:24:04.000Z',
        amountCents: 7550
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ab0659f7-3eb6-42ec-b3be-b5de7bb1218c',
      content: [
        {
          groupId: '204bd0d9-bd31-41cf-9850-0303c54048c1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T15:09:49.992Z',

          createdAt: '2024-05-31T01:40:23.766Z',
          content: [
            {
              caption: '',

              id: 'bd0ff664-c3bb-47c7-82ac-bb1c36b5fe95',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab0659f7-3eb6-42ec-b3be-b5de7bb1218c/ugc/204bd0d9-bd31-41cf-9850-0303c54048c1/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '067bd6ac-602d-4213-b3a5-52be2624566f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab0659f7-3eb6-42ec-b3be-b5de7bb1218c/ugc/204bd0d9-bd31-41cf-9850-0303c54048c1/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e4c7975c-6625-44ad-b60a-c1b822e99049',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:55:43.232Z',

          createdAt: '2024-05-31T12:35:18.193Z',
          content: [
            {
              caption: '',

              id: '8eb642f7-74db-4dcc-8fc7-e621f88fdb8e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab0659f7-3eb6-42ec-b3be-b5de7bb1218c/ugc/e4c7975c-6625-44ad-b60a-c1b822e99049/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ae2c0907-cc95-4114-8bc2-352849fa1dba',
        firstName: 'Riley',
        lastName: 'Miller',
        email: 'rileymiller1005@gmail.com',

        tiktokProfile: {
          handle: 'riley_millertnmama',
          followers: 5459,
          engagementRate: 7.015996718621821
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T19:25:02.000Z',
        amountCents: 8825
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f20fee1a-8f89-4a18-9a75-895e65fc2ac2',
      content: [
        {
          groupId: 'b2f3ee2e-e141-4c03-80cf-1e9ea3e03e4c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:13:41.911Z',

          createdAt: '2024-06-03T16:46:00.731Z',
          content: [
            {
              caption: '',

              id: '921bff71-ece2-4a24-9bb4-ae0ee12df527',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f20fee1a-8f89-4a18-9a75-895e65fc2ac2/ugc/b2f3ee2e-e141-4c03-80cf-1e9ea3e03e4c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f3728d6e-9ecf-4e9f-9c97-a886517dd2b9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T07:29:39.885Z',

          createdAt: '2024-05-31T18:15:34.489Z',
          content: [
            {
              caption: '',

              id: 'ca523f5c-94ba-4c1a-9a40-95729548549e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f20fee1a-8f89-4a18-9a75-895e65fc2ac2/ugc/f3728d6e-9ecf-4e9f-9c97-a886517dd2b9/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9065b2b1-227a-4379-ad54-3a9cdcf81b58',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f20fee1a-8f89-4a18-9a75-895e65fc2ac2/ugc/f3728d6e-9ecf-4e9f-9c97-a886517dd2b9/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3abe8baa-727d-4928-8be7-47c56ea3e846',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f20fee1a-8f89-4a18-9a75-895e65fc2ac2/ugc/f3728d6e-9ecf-4e9f-9c97-a886517dd2b9/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '13d7c202-3895-47a8-b31c-fe2b7c4ca958',
        firstName: 'Kim',
        lastName: 'Ciaccio',
        email: 'kaciaccio@gmail.com',

        tiktokProfile: {
          handle: 'kaciaccio',
          followers: 1603,
          engagementRate: 10.526315789473683
        },
        youtubeProfile: {
          handle: 'kimciaccio7187',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T20:05:55.000Z',
        amountCents: 7413
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ae83356f-3917-43b3-932e-b97ef94a873d',
      content: [
        {
          groupId: '75a0beb4-b5c5-418c-87fc-02c267eb5c3f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T05:02:57.733Z',

          createdAt: '2024-06-11T22:54:19.635Z',
          content: [
            {
              caption: '',

              id: '1c1ca3b0-7155-461a-acc9-e33ce0183555',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae83356f-3917-43b3-932e-b97ef94a873d/ugc/75a0beb4-b5c5-418c-87fc-02c267eb5c3f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '9c3286d7-c0c9-49bd-9e5d-5d996352e052',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T05:03:02.007Z',

          createdAt: '2024-06-11T22:54:55.338Z',
          content: [
            {
              caption: '',

              id: 'd8ec1231-9f3f-4ba4-934d-bf6c98c82518',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae83356f-3917-43b3-932e-b97ef94a873d/ugc/9c3286d7-c0c9-49bd-9e5d-5d996352e052/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3fc89482-f9b8-4877-8b45-3610e29b1dbf',
        firstName: 'Renee',
        lastName: 'Eide',
        email: 'srpack_95@outlook.com',
        instagramProfile: {
          handle: 'fearless.momma.lion',
          followers: 4386,
          engagementRate: 0.22115823073415414
        },
        tiktokProfile: {
          handle: 'fearlessmommalion',
          followers: 6325,
          engagementRate: 4.666289592760181
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T20:05:57.000Z',
        amountCents: 7657
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '10ac25f0-a52d-4e01-8d54-57a607199fee',
      content: [],
      creator: {
        id: '72bd6bf0-655c-4ce9-a337-831114ca0714',
        firstName: 'Taylor',
        lastName: 'Hall',
        email: 'taylor.paige71591@gmail.com',
        instagramProfile: {
          handle: 'taylor_helping_hands_cleaning',
          followers: 230,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'tayhall101218',
          followers: 2524
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T20:42:53.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0604a2f4-ada7-4d51-8a29-29495a5b8bf1',
      content: [],
      creator: {
        id: '8ddf4c80-fd1e-4d24-974b-d011adccdcb1',
        firstName: 'Shyla',
        lastName: 'Mathews',
        email: 'shylamathews97@gmail.com',
        instagramProfile: {
          handle: 'shylarrae',
          followers: 637,
          engagementRate: 0.9419152276295133
        },
        tiktokProfile: {
          handle: 'shylaaarae',
          followers: 1630,
          engagementRate: 11.036789297658862
        },
        youtubeProfile: {
          handle: 'shylamathews',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T20:45:07.000Z',
        amountCents: 7550
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f94dce07-89fc-4c07-a1c1-ac1c0378229a',
      content: [],
      creator: {
        id: 'b7248900-bc8a-42d0-b93e-2cb4b641f11a',
        firstName: 'Denis',
        lastName: 'Silva',
        email: 'denisfs83@gmail.com',
        instagramProfile: {
          handle: 'denis.silva.usa',
          followers: 8707,
          engagementRate: 0.008384058803261742
        },
        tiktokProfile: {
          handle: 'denis.fkb',
          followers: 69,
          engagementRate: 2.1621621621621623
        },
        youtubeProfile: {
          handle: 'sigaodenis',
          followers: 10400
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T21:41:47.000Z',
        amountCents: 7513
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f0d36f70-977d-45a2-beb2-25e7d9491417',
      content: [],
      creator: {
        id: '4b7231b8-3b8d-4e16-930d-452d211020b2',
        firstName: 'Claudia',
        lastName: 'López',
        email: 'bookclaudialopez@gmail.com',
        instagramProfile: {
          handle: 'itsclaudialopez',
          followers: 28929,
          engagementRate: 5.708112966227661
        },
        tiktokProfile: {
          handle: 'itsclaudialopez',
          followers: 2428,
          engagementRate: 9.107603249501386
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T22:36:06.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f487de2b-07e1-4c52-bad8-25b9295d9f8c',
      content: [],
      creator: {
        id: '215fda05-c852-45b6-be9e-09591b8642c1',
        firstName: 'Ashley',
        lastName: 'Mileur',
        email: 'ashley.mileur@gmail.com',

        tiktokProfile: {
          handle: 'ashleymileur2',
          followers: 1008,
          engagementRate: 8.778625954198473
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T23:02:37.000Z',
        amountCents: 7592
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f722c87c-8dbe-4f52-b4cc-2919af6298ae',
      content: [
        {
          groupId: '1210528c-6494-4a06-9a26-fa91b5b49c12',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T15:30:45.762Z',

          createdAt: '2024-06-19T20:27:07.401Z',
          content: [
            {
              caption: '',

              id: 'e473509d-f3cd-47d2-9a47-1584708b6059',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f722c87c-8dbe-4f52-b4cc-2919af6298ae/ugc/1210528c-6494-4a06-9a26-fa91b5b49c12/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '288477da-2d0f-49a0-8111-53ffc33290d0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T17:02:23.048Z',

          createdAt: '2024-06-14T23:22:47.313Z',
          content: [
            {
              caption: '',

              id: '07bf967c-ee04-43f0-9a33-de0a4f5beb17',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f722c87c-8dbe-4f52-b4cc-2919af6298ae/ugc/288477da-2d0f-49a0-8111-53ffc33290d0/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3635833f-92a0-4076-8c74-2c5f2e6d8272',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f722c87c-8dbe-4f52-b4cc-2919af6298ae/ugc/288477da-2d0f-49a0-8111-53ffc33290d0/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fb98c786-463d-46b7-a82f-2ddb092e9b7d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f722c87c-8dbe-4f52-b4cc-2919af6298ae/ugc/288477da-2d0f-49a0-8111-53ffc33290d0/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '68ae23d2-b9ba-4e27-a5c1-f626e20ee85a',
        firstName: 'Michaela',
        lastName: 'Zahradnikova',
        email: 'theblondeandco1@gmail.com',
        instagramProfile: {
          handle: 'the.blonde.and.co',
          followers: 9652,
          engagementRate: 5.9365934521342725
        },
        tiktokProfile: {
          handle: 'theblondeandco',
          followers: 63
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T23:19:11.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3d5f2abb-5d39-44ae-afec-d6bf9b3605a9',
      content: [
        {
          groupId: 'a9eccab2-de6e-4440-8dbf-96c0d8ab1edc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-20T14:26:02.136Z',

          createdAt: '2024-05-30T02:47:31.899Z',
          content: [
            {
              caption: '',

              id: '12f2e9b1-2d6c-40b9-9485-abbd578b0a41',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3d5f2abb-5d39-44ae-afec-d6bf9b3605a9/ugc/a9eccab2-de6e-4440-8dbf-96c0d8ab1edc/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1b421d71-8856-49ee-9382-2cf607921865',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3d5f2abb-5d39-44ae-afec-d6bf9b3605a9/ugc/a9eccab2-de6e-4440-8dbf-96c0d8ab1edc/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '58165d82-64fd-499d-8b38-3e8fab47b339',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3d5f2abb-5d39-44ae-afec-d6bf9b3605a9/ugc/a9eccab2-de6e-4440-8dbf-96c0d8ab1edc/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c8279c6f-21a4-467b-9941-f98179fe8f39',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T14:25:50.685Z',

          createdAt: '2024-06-06T10:04:33.429Z',
          content: [
            {
              caption: '',

              id: 'fe6b0f22-20fd-4303-b720-f477e3686ae7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3d5f2abb-5d39-44ae-afec-d6bf9b3605a9/ugc/c8279c6f-21a4-467b-9941-f98179fe8f39/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '420907de-3346-46a6-9cb0-90cae47eed8d',
        firstName: 'Danielle',
        lastName: 'Grady',
        email: 'daniellegrady1@gmail.com',
        instagramProfile: {
          handle: 'danails2093',
          followers: 137,
          engagementRate: 1.824817518248175
        },
        tiktokProfile: {
          handle: 'msdanilove',
          followers: 2668,
          engagementRate: 5.932557196364244
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T23:24:41.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fb228c67-dafe-4dc9-abd3-4d83bcdfe85e',
      content: [],
      creator: {
        id: 'bfe6d2d2-d2b5-4ccb-af8e-344d87fcf952',
        firstName: 'Shannon',
        lastName: 'Puhala',
        email: 'shannonamber619@gmail.com',
        instagramProfile: {
          handle: 'gastroparesis_princess',
          followers: 182,
          engagementRate: 1.6483516483516485
        },
        tiktokProfile: {
          handle: 'fallindwn_the_rabbithole',
          followers: 6245,
          engagementRate: 4.329896907216495
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-25T23:31:22.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '09a60fcf-4724-4338-8ccc-79a388070c3a',
      content: [
        {
          groupId: '09be7e22-0533-4ecb-92a3-73437ece40b0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T19:13:44.323Z',

          createdAt: '2024-05-30T01:35:22.506Z',
          content: [
            {
              caption: '',

              id: '7323b483-a0a5-4127-8da8-2239f8d79c39',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/09a60fcf-4724-4338-8ccc-79a388070c3a/ugc/09be7e22-0533-4ecb-92a3-73437ece40b0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8460cdd1-75e0-428f-9aec-e913bf155a83',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T19:13:48.911Z',

          createdAt: '2024-05-30T01:43:17.427Z',
          content: [
            {
              caption: '',

              id: 'c59d2ee5-dd5f-4499-88d3-fa93dff6a149',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/09a60fcf-4724-4338-8ccc-79a388070c3a/ugc/8460cdd1-75e0-428f-9aec-e913bf155a83/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b1631907-d732-4887-abd8-1320ba462fc7',
        firstName: 'Tiara',
        lastName: 'Yoakum',
        email: 'herwaymedia@gmail.com',
        instagramProfile: {
          handle: 'doublegthenetwork',
          followers: 1358
        },
        tiktokProfile: {
          handle: 'tbabyyyy33',
          followers: 4443,
          engagementRate: 10.078794369645044
        },
        youtubeProfile: {
          handle: 'lifewithtbabytv',
          followers: 13
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T01:53:17.000Z',
        amountCents: 8531
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dcdad3f5-a890-44b3-afb7-ef5b0c09c37f',
      content: [],
      creator: {
        id: 'c9ac05cd-9dc2-4617-855f-cb10e1c847d3',
        firstName: 'Arthur',
        lastName: 'Dube',
        email: 'arthurdube20101996@gmail.com',
        instagramProfile: {
          handle: 'arthur_dube',
          followers: 4055,
          engagementRate: 0.7768187422934648
        },
        tiktokProfile: {
          handle: 'arturod96',
          followers: 35
        },
        youtubeProfile: {
          handle: 'thestateswitharthur6607',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T01:54:23.000Z',
        amountCents: 8208
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8d57f81f-e771-4089-a933-9d4576d49ec2',
      content: [],
      creator: {
        id: '24e927ab-a561-4ee5-ae33-4d10c1e28ce9',
        firstName: 'Brandon',
        lastName: 'Michael',
        email: 'bwork87@me.com',
        instagramProfile: {
          handle: 'authentic_grain',
          followers: 1314,
          engagementRate: 3.0593607305936077
        },
        tiktokProfile: {
          handle: 'authentic_grain',
          followers: 147
        },
        youtubeProfile: {
          handle: 'brandonwork6434',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T02:44:50.000Z',
        amountCents: 7580
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b82ac0dd-3a96-4237-9817-8c139e4db195',
      content: [],
      creator: {
        id: '55cfc69d-4782-4646-96f0-4a058a33a491',
        firstName: 'Tatyana ',
        lastName: 'Robinson',
        email: 'tatyanarobinson885@gmail.com',
        instagramProfile: {
          handle: 'boujiee.ty',
          followers: 9514,
          engagementRate: 0.5749421904561699
        },
        tiktokProfile: {
          handle: 'mommyty8',
          followers: 1367,
          engagementRate: 35.61643835616438
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T03:05:29.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd3056bab-88f6-432e-9cad-af79b46b068e',
      content: [
        {
          groupId: 'acb69b59-9dc0-49e1-88ba-d8926072c8da',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:17:17.588Z',

          createdAt: '2024-05-27T17:59:04.644Z',
          content: [
            {
              caption: '',

              id: '769c77ba-1fee-4b9f-a1fd-493664bcb3c9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d3056bab-88f6-432e-9cad-af79b46b068e/ugc/acb69b59-9dc0-49e1-88ba-d8926072c8da/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '63e7af38-c3a5-47bc-87f1-f97a3eb7f989',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T19:24:32.687Z',

          createdAt: '2024-05-27T14:37:15.530Z',
          content: [
            {
              caption: '',

              id: '7b55b242-073f-4cee-a2ef-5b2425477932',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d3056bab-88f6-432e-9cad-af79b46b068e/ugc/63e7af38-c3a5-47bc-87f1-f97a3eb7f989/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '849d396c-bd86-4fbe-a3e3-a11d2e094b1c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d3056bab-88f6-432e-9cad-af79b46b068e/ugc/63e7af38-c3a5-47bc-87f1-f97a3eb7f989/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e2b3cd8b-d5f6-4d44-a1a3-45cc3952b00a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d3056bab-88f6-432e-9cad-af79b46b068e/ugc/63e7af38-c3a5-47bc-87f1-f97a3eb7f989/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '75e00afd-e4b6-4288-9b4b-87b9ebe7a806',
        firstName: 'Lubov',
        lastName: 'Chorni',
        email: 'lubovtsar25@ukr.net',
        instagramProfile: {
          handle: 'dr.lyubov_tsaryk',
          followers: 5064,
          engagementRate: 0.665481832543444
        },
        tiktokProfile: {
          handle: 'liubov_chornii',
          followers: 184,
          engagementRate: 0.6646525679758308
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T03:44:22.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f2827e70-111b-46ba-9f48-2ea867ebc55e',
      content: [],
      creator: {
        id: '8476b730-8e5b-4746-b484-39018392c417',
        firstName: 'Rosenna',
        lastName: 'Booth',
        email: 'rosennashaw@gmail.com',

        tiktokProfile: {
          handle: 'nunnyboothe',
          followers: 6256,
          engagementRate: 14.934289127837516
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T03:54:18.000Z',
        amountCents: 8344
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '226ff3c2-d2ba-4505-8961-f0871c13122b',
      content: [
        {
          groupId: 'e2eaadab-2464-4036-ae4b-c8a78dbb1515',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T13:01:52.838Z',

          createdAt: '2024-06-04T11:42:58.571Z',
          content: [
            {
              caption: '',

              id: '4ed55548-5131-4e0d-b3b6-ac7cd5f8c73c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/226ff3c2-d2ba-4505-8961-f0871c13122b/ugc/e2eaadab-2464-4036-ae4b-c8a78dbb1515/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a2c9801c-b9f4-490e-b855-b796c0c03cdb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T11:42:26.058Z',

          createdAt: '2024-05-30T20:11:34.809Z',
          content: [
            {
              caption: '',

              id: '5a91971a-1084-40ac-8bde-59f616a2e059',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/226ff3c2-d2ba-4505-8961-f0871c13122b/ugc/a2c9801c-b9f4-490e-b855-b796c0c03cdb/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f65dfe66-4c94-4c57-95da-35c03ef1cd79',
        firstName: 'Makensi',
        lastName: 'Gage',
        email: 'itsanofromme.blog@gmail.com',
        instagramProfile: {
          handle: 'makensigage',
          followers: 43167,
          engagementRate: 0.23096346746357171
        },
        tiktokProfile: {
          handle: 'makensigage',
          followers: 7862,
          engagementRate: 2.02991452991453
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T05:52:53.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e1b00f93-85c3-46b6-af79-afa54cb407f1',
      content: [
        {
          groupId: 'cd09a3ea-f42b-4055-b923-72076878a21a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T11:44:51.618Z',

          createdAt: '2024-06-26T01:03:42.350Z',
          content: [
            {
              caption: '',

              id: 'eb66be8b-f517-4ec4-a7bc-d5682176307f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1b00f93-85c3-46b6-af79-afa54cb407f1/ugc/cd09a3ea-f42b-4055-b923-72076878a21a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '71930202-e1cb-4e5a-bb55-dfffc70a7882',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-28T11:45:01.952Z',

          createdAt: '2024-06-26T01:09:43.805Z',
          content: [
            {
              caption: '',

              id: '2b492095-9f4c-4124-a423-41ace74968a7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1b00f93-85c3-46b6-af79-afa54cb407f1/ugc/71930202-e1cb-4e5a-bb55-dfffc70a7882/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '42df02ad-98dc-4206-9c98-9565949e4988',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1b00f93-85c3-46b6-af79-afa54cb407f1/ugc/71930202-e1cb-4e5a-bb55-dfffc70a7882/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8dedf6b1-bb56-4934-a2cd-058e9b65de82',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1b00f93-85c3-46b6-af79-afa54cb407f1/ugc/71930202-e1cb-4e5a-bb55-dfffc70a7882/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7a55984d-e00b-4c08-94e0-e52b05d76536',
        firstName: 'Shiann',
        lastName: 'Bent',
        email: 'shiannbent@yahoo.com',
        instagramProfile: {
          handle: 'shyyyb1',
          followers: 788,
          engagementRate: 4.073604060913706
        },
        tiktokProfile: {
          handle: 'shyyyb1',
          followers: 1058,
          engagementRate: 10.287081339712918
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T05:54:25.000Z',
        amountCents: 9843
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fdf99bf1-8bd6-44bb-a3e8-e648f65dcfcc',
      content: [
        {
          groupId: 'fa24adcb-981f-43f3-b730-be8fc451cdaa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T03:47:13.985Z',

          createdAt: '2024-06-03T00:00:45.480Z',
          content: [
            {
              caption: '',

              id: 'b882b2cb-32f2-4cdd-baec-467104fea581',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fdf99bf1-8bd6-44bb-a3e8-e648f65dcfcc/ugc/fa24adcb-981f-43f3-b730-be8fc451cdaa/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '54e4939c-c34b-473f-955d-d6f2cbc0479d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fdf99bf1-8bd6-44bb-a3e8-e648f65dcfcc/ugc/fa24adcb-981f-43f3-b730-be8fc451cdaa/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '67a934ad-b5ee-4158-92a1-d6259d981040',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fdf99bf1-8bd6-44bb-a3e8-e648f65dcfcc/ugc/fa24adcb-981f-43f3-b730-be8fc451cdaa/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c3beecbc-d64d-407e-a4b1-26a0f5b63e19',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T03:19:02.533Z',

          createdAt: '2024-06-03T00:06:40.885Z',
          content: [
            {
              caption: '',

              id: '068d6466-5863-4c0f-8e40-8bd86b7107c4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fdf99bf1-8bd6-44bb-a3e8-e648f65dcfcc/ugc/c3beecbc-d64d-407e-a4b1-26a0f5b63e19/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '037e5058-6e95-444e-9680-0afa347d54cb',
        firstName: 'Anasha',
        lastName: 'Jones',
        email: 'xo.anajuliana@gmail.com',
        instagramProfile: {
          handle: 'authentically__anasha',
          followers: 2197,
          engagementRate: 1.7250796540737368
        },
        tiktokProfile: {
          handle: 'authenticallyanasha',
          followers: 285,
          engagementRate: 6.887052341597796
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T05:54:55.000Z',
        amountCents: 7646
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2d74baf3-2023-4d76-a181-f2dd98300c73',
      content: [
        {
          groupId: '43a4b419-8bba-4bab-b56b-11f6e0258e14',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T04:50:51.691Z',

          createdAt: '2024-05-31T22:57:29.733Z',
          content: [
            {
              caption: '',

              id: 'dd7847b0-3679-4248-8d8a-fe18e8b225b3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2d74baf3-2023-4d76-a181-f2dd98300c73/ugc/43a4b419-8bba-4bab-b56b-11f6e0258e14/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'dd6eccd3-651b-4b8b-9a41-8e6a468e9275',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T03:10:37.216Z',

          createdAt: '2024-05-31T22:44:07.225Z',
          content: [
            {
              caption: '',

              id: '4d416974-31f3-4f59-b69f-c184e0a4eaa9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2d74baf3-2023-4d76-a181-f2dd98300c73/ugc/dd6eccd3-651b-4b8b-9a41-8e6a468e9275/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '69cf481e-6129-4d3e-ba90-d88ea52dd108',
        firstName: 'Maegan',
        lastName: 'Wendt',
        email: 'maewendt@gmail.com',
        instagramProfile: {
          handle: 'maegan.wendt',
          followers: 3166,
          engagementRate: 9.23124421117629
        },
        tiktokProfile: {
          handle: 'maeganww',
          followers: 1788,
          engagementRate: 5.025247212286976
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T06:32:56.000Z',
        amountCents: 7603
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '844ee090-50c5-42bd-970b-31b79be76a61',
      content: [
        {
          groupId: '884f940c-e11a-44a1-bb55-7dd20abf4fc6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:09:34.315Z',

          createdAt: '2024-05-31T13:47:48.583Z',
          content: [
            {
              caption: '',

              id: 'fe1d3f43-d735-4255-aa2c-e1b608c09ddd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/844ee090-50c5-42bd-970b-31b79be76a61/ugc/884f940c-e11a-44a1-bb55-7dd20abf4fc6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '1bbf3d2c-7b7a-458b-a1e1-eb2e5fdd9812',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T13:03:16.945Z',

          createdAt: '2024-05-31T13:49:26.204Z',
          content: [
            {
              caption: '',

              id: '7d00edd5-19a5-43ec-8179-3b27d7cafc73',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/844ee090-50c5-42bd-970b-31b79be76a61/ugc/1bbf3d2c-7b7a-458b-a1e1-eb2e5fdd9812/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f4db5831-4d1c-4ca1-9fa4-adc4d46eb5fc',
        firstName: 'Alina',
        lastName: 'Zaytseva',
        email: 'alimomlife@gmail.com',

        tiktokProfile: {
          handle: 'alimomlife',
          followers: 6560,
          engagementRate: 4.323160733297585
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T07:42:38.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ffbed56b-2fa8-4473-95cb-e94dc5eabd33',
      content: [
        {
          groupId: '1a5a8034-56df-4966-b4c7-b73f33fe1baa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T22:31:16.174Z',

          createdAt: '2024-06-04T03:48:07.212Z',
          content: [
            {
              caption: '',

              id: '97e9b2e9-afff-4cdd-b3ac-4cb695f938ef',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ffbed56b-2fa8-4473-95cb-e94dc5eabd33/ugc/1a5a8034-56df-4966-b4c7-b73f33fe1baa/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e73316f2-5caf-4eb7-8650-57229af4759a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T05:23:39.303Z',

          createdAt: '2024-06-04T04:03:57.664Z',
          content: [
            {
              caption: '',

              id: 'cce32d0a-361a-42a2-839f-b2015ec0bdd5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ffbed56b-2fa8-4473-95cb-e94dc5eabd33/ugc/e73316f2-5caf-4eb7-8650-57229af4759a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '55ed0f31-1432-4855-9f30-b903dc8f414b',
        firstName: 'Ariel',
        lastName: 'Ford',
        email: 'arieluneque1997@gmail.com',

        tiktokProfile: {
          handle: 'arielmomlife',
          followers: 2258,
          engagementRate: 10.407930295405833
        },
        youtubeProfile: {
          handle: 'arieluford',
          followers: 1510
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T07:53:39.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e8f3e71f-eb3c-4863-8ba4-5337a00c59a6',
      content: [
        {
          groupId: '30cae1d1-996a-4662-bf02-b4bd8d45dd15',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:35:44.435Z',

          createdAt: '2024-05-27T22:01:31.102Z',
          content: [
            {
              caption: '',

              id: 'b9ddcd80-f299-4dac-9df3-8b487f583371',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e8f3e71f-eb3c-4863-8ba4-5337a00c59a6/ugc/30cae1d1-996a-4662-bf02-b4bd8d45dd15/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '83f8269c-be65-4fcb-853d-a13c7e3fe2b5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T20:58:29.439Z',

          createdAt: '2024-05-27T22:27:12.509Z',
          content: [
            {
              caption: '',

              id: '14766c87-e628-42e5-842a-76de1b6b4716',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e8f3e71f-eb3c-4863-8ba4-5337a00c59a6/ugc/83f8269c-be65-4fcb-853d-a13c7e3fe2b5/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a26a1fe4-a12e-44a0-9805-3856a9f3d4ac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e8f3e71f-eb3c-4863-8ba4-5337a00c59a6/ugc/83f8269c-be65-4fcb-853d-a13c7e3fe2b5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3e73173d-a8e9-40bf-b991-35a72e7fc206',
        firstName: 'Kara',
        lastName: 'Smith',
        email: 'karaunruly@gmail.com',
        instagramProfile: {
          handle: 'unrulyglamshop',
          followers: 115,
          engagementRate: 5.043478260869565
        },
        tiktokProfile: {
          handle: 'karaunruly',
          followers: 4446,
          engagementRate: 8.862364212863863
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T08:19:59.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '97ea8da2-25b7-43b1-a15b-490c8c6d0fee',
      content: [
        {
          groupId: '4ebf60ac-79a9-4297-b7bc-e5256016a530',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T19:56:05.535Z',

          createdAt: '2024-06-08T18:37:30.307Z',
          content: [
            {
              caption: '',

              id: '548a3701-2edd-4d39-80e6-7ec66398442e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/97ea8da2-25b7-43b1-a15b-490c8c6d0fee/ugc/4ebf60ac-79a9-4297-b7bc-e5256016a530/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e1752b38-90c4-4acd-967b-654d54660268',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T19:56:12.312Z',

          createdAt: '2024-06-08T18:42:15.014Z',
          content: [
            {
              caption: '',

              id: '486ef64d-d4ba-4208-bb13-1a0a32b4b1ff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/97ea8da2-25b7-43b1-a15b-490c8c6d0fee/ugc/e1752b38-90c4-4acd-967b-654d54660268/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '086738b1-7359-41ed-aadd-62fa63651582',
        firstName: 'Dennis',
        lastName: 'Washington',
        email: 'teach.or.trav@gmail.com',
        instagramProfile: {
          handle: 'teach_or_trav',
          followers: 6062,
          engagementRate: 0.5823160673045199
        },
        tiktokProfile: {
          handle: 'teach_or_trav',
          followers: 5848,
          engagementRate: 2.2801302931596092
        },
        youtubeProfile: {
          handle: 'teach_or_trav'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T08:23:50.000Z',
        amountCents: 8381
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f788e42e-ca29-4a74-a46f-0c9027b7e394',
      content: [],
      creator: {
        id: 'd25f4c06-c5fd-4a67-bd09-947ce61ed32a',
        firstName: 'Anrea',
        lastName: 'Myrie',
        email: 'anrea_myrie@yahoo.com',

        tiktokProfile: {
          handle: 'ria_natural17curls',
          followers: 5819,
          engagementRate: 8.661417322834646
        },
        youtubeProfile: {
          handle: 'reaevergrateful',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T09:46:30.000Z',
        amountCents: 8314
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '39662676-55d1-494f-b370-9cda22ed4a78',
      content: [],
      creator: {
        id: 'b2d7e1b7-8314-4ad4-b9fd-70f1e8708ad1',
        firstName: 'Molly',
        lastName: 'Graham',
        email: 'megraham0610@gmail.com',
        instagramProfile: {
          handle: 'havensisters',
          followers: 59343,
          engagementRate: 0.6865173651483747
        },
        tiktokProfile: {
          handle: 'mollyevelyn_',
          followers: 1999,
          engagementRate: 2.631578947368421
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T10:05:08.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '67a5ad24-39af-4c16-b83e-1fddfc6f0848',
      content: [
        {
          groupId: 'df7e9b75-831a-4005-9841-fb4e99f0fd0f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T13:05:10.132Z',

          createdAt: '2024-05-31T15:29:58.684Z',
          content: [
            {
              caption: '',

              id: '612479e9-fdf2-47c3-9427-9aa933f0f12c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/67a5ad24-39af-4c16-b83e-1fddfc6f0848/ugc/df7e9b75-831a-4005-9841-fb4e99f0fd0f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '979c84f1-5eae-463a-a32e-96e20ab7092c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/67a5ad24-39af-4c16-b83e-1fddfc6f0848/ugc/df7e9b75-831a-4005-9841-fb4e99f0fd0f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5838004c-ed3d-4e9f-ae95-bc6310fd7569',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/67a5ad24-39af-4c16-b83e-1fddfc6f0848/ugc/df7e9b75-831a-4005-9841-fb4e99f0fd0f/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5cfc70bc-e39e-4a57-8cbb-fac8874e4dcb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T17:19:15.908Z',

          createdAt: '2024-05-31T18:41:23.320Z',
          content: [
            {
              caption: '',

              id: '224451e0-669d-4c26-8abb-4b1878e06637',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/67a5ad24-39af-4c16-b83e-1fddfc6f0848/ugc/5cfc70bc-e39e-4a57-8cbb-fac8874e4dcb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5bded7cf-f259-4316-93cc-e8ada5f9a8cc',
        firstName: 'Mia',
        lastName: 'Parrish',
        email: 'miaedington22@gmail.com',
        instagramProfile: {
          handle: 'daagracee',
          followers: 53,
          engagementRate: 6.73758865248227
        },
        tiktokProfile: {
          handle: 'dagracee',
          followers: 1646,
          engagementRate: 1.9491327317780165
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T10:53:41.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '50be3c28-2efc-49d2-958f-981b5c87d354',
      content: [
        {
          groupId: '83ba24e4-27c2-4f45-b28c-9b34425c3ae1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:26:48.377Z',

          createdAt: '2024-06-02T03:07:58.168Z',
          content: [
            {
              caption: '',

              id: 'c890c0e5-868e-4c3c-8570-dec58555ae8b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50be3c28-2efc-49d2-958f-981b5c87d354/ugc/83ba24e4-27c2-4f45-b28c-9b34425c3ae1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2a6b5b51-410d-4ee9-9090-26ca0d358bf4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:46:02.359Z',

          createdAt: '2024-06-02T02:44:08.354Z',
          content: [
            {
              caption: '',

              id: '9f6bc7fe-dc4b-4c38-98f8-017e92abec7b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50be3c28-2efc-49d2-958f-981b5c87d354/ugc/2a6b5b51-410d-4ee9-9090-26ca0d358bf4/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '20667b8f-8bc1-4c94-a5a3-97fd1322b34f',
        firstName: 'Brianna',
        lastName: 'Robbins',
        email: 'k.bri@mail.com',
        instagramProfile: {
          handle: 'therobbinsnest__',
          followers: 10353,
          engagementRate: 0.08016999903409641
        },
        tiktokProfile: {
          handle: 'therobbinsnest_',
          followers: 3320,
          engagementRate: 6.885547915912754
        },
        youtubeProfile: {
          handle: 'therobbinsnest__',
          followers: 70
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T11:23:49.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dce58b31-e118-42d2-841d-6e3ae6716339',
      content: [],
      creator: {
        id: '02ff1fde-e67c-4613-8bef-1745ca77b0d1',
        firstName: 'Makayla',
        lastName: 'Reid',
        email: 'makaylae.reid@outlook.com',
        instagramProfile: {
          handle: 'charm.gold',
          followers: 9144,
          engagementRate: 0.8759842519685038
        },
        tiktokProfile: {
          handle: 'charm.gold',
          followers: 184
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T11:34:14.000Z',
        amountCents: 8496
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5679c6a3-68d1-41e4-9e1f-7e543bb89b93',
      content: [
        {
          groupId: 'c83329a4-d3ab-4865-b366-48dbc1cba7f7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T18:56:03.238Z',

          createdAt: '2024-06-05T04:27:07.203Z',
          content: [
            {
              caption: '',

              id: '20457e8a-b793-47df-9459-b99a248825c0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5679c6a3-68d1-41e4-9e1f-7e543bb89b93/ugc/c83329a4-d3ab-4865-b366-48dbc1cba7f7/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6f2d9675-58a3-4168-bcb5-fe3b2b449ce6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5679c6a3-68d1-41e4-9e1f-7e543bb89b93/ugc/c83329a4-d3ab-4865-b366-48dbc1cba7f7/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c0edf70d-88fc-4cc9-9e99-bdcdcc0c5c30',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5679c6a3-68d1-41e4-9e1f-7e543bb89b93/ugc/c83329a4-d3ab-4865-b366-48dbc1cba7f7/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '528e592b-7966-47e5-b9f2-66ad46b50bb8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T18:55:48.944Z',

          createdAt: '2024-06-05T04:22:45.209Z',
          content: [
            {
              caption: '',

              id: '22b56a1c-0fa8-4bd1-83eb-568f75eed3d4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5679c6a3-68d1-41e4-9e1f-7e543bb89b93/ugc/528e592b-7966-47e5-b9f2-66ad46b50bb8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2ef2ecba-15c9-44a1-949b-3c0a9ea1faac',
        firstName: 'Fatima',
        lastName: 'Inot',
        email: 'fatimainot.teachabroad@gmail.com',
        instagramProfile: {
          handle: 'fottimaaay',
          followers: 1010,
          engagementRate: 2.4533856722276743
        },
        tiktokProfile: {
          handle: 'fatimainot',
          followers: 53
        },
        youtubeProfile: {
          handle: 'fatimainot1222',
          followers: 95
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T11:55:53.000Z',
        amountCents: 8684
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1c1703cd-9a27-4af1-bbc1-ed17ad1b0079',
      content: [
        {
          groupId: 'f250a842-1913-4a3c-a774-1352a026c500',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T07:22:26.736Z',

          createdAt: '2024-06-01T00:46:23.239Z',
          content: [
            {
              caption: '',

              id: '97c17271-a203-4718-b560-9f6cea8972ce',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c1703cd-9a27-4af1-bbc1-ed17ad1b0079/ugc/f250a842-1913-4a3c-a774-1352a026c500/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8259923c-20a0-40a2-9c49-c529372ceed6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c1703cd-9a27-4af1-bbc1-ed17ad1b0079/ugc/f250a842-1913-4a3c-a774-1352a026c500/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ba411324-2fee-4687-822b-c15a3e3ccf17',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T16:24:17.613Z',

          createdAt: '2024-06-02T00:38:08.801Z',
          content: [
            {
              caption: '',

              id: 'f7e54a42-0b44-466b-a482-5a892fc70372',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1c1703cd-9a27-4af1-bbc1-ed17ad1b0079/ugc/ba411324-2fee-4687-822b-c15a3e3ccf17/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7400e6a3-5347-440a-b404-7d6ad456556b',
        firstName: 'Christine',
        lastName: 'Brandal',
        email: 'cbat3295@aol.com',
        instagramProfile: {
          handle: 'therossifamily20',
          followers: 249,
          engagementRate: 2.4899598393574296
        },
        tiktokProfile: {
          handle: 'therossifamily',
          followers: 5288,
          engagementRate: 9.298002219755826
        },
        youtubeProfile: {
          handle: 'therossifamily-tiktok',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T12:16:28.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0d7c6eb5-bc13-462f-968c-04257d6ceb9d',
      content: [
        {
          groupId: '0b781151-3818-4fb0-a54d-7d729865809f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T15:55:58.201Z',

          createdAt: '2024-06-01T20:06:20.158Z',
          content: [
            {
              caption: '',

              id: '7222c121-7373-46fb-81a1-90e648f38124',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0d7c6eb5-bc13-462f-968c-04257d6ceb9d/ugc/0b781151-3818-4fb0-a54d-7d729865809f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a40d823e-ab1a-4283-b9f6-e526e7191907',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T15:55:53.813Z',

          createdAt: '2024-06-01T20:06:52.596Z',
          content: [
            {
              caption: '',

              id: 'aa0390ae-5863-4de4-95e9-c5e00d7bf4b1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0d7c6eb5-bc13-462f-968c-04257d6ceb9d/ugc/a40d823e-ab1a-4283-b9f6-e526e7191907/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b10d73de-6e22-4a0e-acb4-446983f8cbe7',
        firstName: 'Katie ',
        lastName: 'Roberts',
        email: 'katiercontent@gmail.com',

        tiktokProfile: {
          handle: 'sahm_milso_fosterdogmom',
          followers: 10370,
          engagementRate: 5.052535184114131
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T12:31:58.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8996803d-72fc-4922-9560-5272e5ac759c',
      content: [
        {
          groupId: 'b2dea301-16b1-4c63-9f2b-25bd2c6b469d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:16:18.698Z',

          createdAt: '2024-05-28T21:14:33.606Z',
          content: [
            {
              caption: '',

              id: 'b7b8aa74-084e-4383-8af9-511cb8202e29',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8996803d-72fc-4922-9560-5272e5ac759c/ugc/b2dea301-16b1-4c63-9f2b-25bd2c6b469d/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a6ed03f0-c391-4517-a6d8-0bdd16720bce',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8996803d-72fc-4922-9560-5272e5ac759c/ugc/b2dea301-16b1-4c63-9f2b-25bd2c6b469d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e896fbe0-a44b-4249-aac1-f476902981fd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8996803d-72fc-4922-9560-5272e5ac759c/ugc/b2dea301-16b1-4c63-9f2b-25bd2c6b469d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8716bb01-c77f-4787-ae64-f3f85225355b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:26:47.539Z',

          createdAt: '2024-05-29T01:46:30.735Z',
          content: [
            {
              caption: '',

              id: 'd3041547-716c-407b-9462-10a77930884a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8996803d-72fc-4922-9560-5272e5ac759c/ugc/8716bb01-c77f-4787-ae64-f3f85225355b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '687309d9-cb89-414b-8eac-75967eabb762',
        firstName: 'Brittany',
        lastName: 'Wilbourne',
        email: 'coldcoffeegrind@gmail.com',
        instagramProfile: {
          handle: 'brittanywilbourne',
          followers: 7318,
          engagementRate: 0.22410494670675046
        },
        tiktokProfile: {
          handle: 'brittanywilbourne',
          followers: 81,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'coldcoffeegrind'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T12:51:58.000Z',
        amountCents: 7370
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7cbfddda-8ff9-43fc-9590-70e27d2a2428',
      content: [
        {
          groupId: '8960577c-86c9-4367-b5e0-489c0b2b29d7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T15:25:50.520Z',

          createdAt: '2024-05-30T23:39:36.072Z',
          content: [
            {
              caption: '',

              id: '9555f96c-b010-495e-a471-560821e32d06',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7cbfddda-8ff9-43fc-9590-70e27d2a2428/ugc/8960577c-86c9-4367-b5e0-489c0b2b29d7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '23d2d178-69d7-4b1b-8df7-a2bfb11f9ed6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T15:25:45.774Z',

          createdAt: '2024-05-30T14:21:30.153Z',
          content: [
            {
              caption: '',

              id: '8de35343-35f7-41df-97d1-fa5078c0135d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7cbfddda-8ff9-43fc-9590-70e27d2a2428/ugc/23d2d178-69d7-4b1b-8df7-a2bfb11f9ed6/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '99adb25a-314b-46fd-a3bc-7e3356a62cee',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7cbfddda-8ff9-43fc-9590-70e27d2a2428/ugc/23d2d178-69d7-4b1b-8df7-a2bfb11f9ed6/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'abad5804-8b0f-400d-8086-7d16c647feee',
        firstName: 'Kevin',
        lastName: 'Thompson',
        email: 'iamabefroman@gmail.com',
        instagramProfile: {
          handle: 'freesamplekingofchicago',
          followers: 2287,
          engagementRate: 0.5684302579798863
        },

        youtubeProfile: {
          handle: 'iamabefroman',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T12:52:41.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f74089bf-9402-4712-a60e-1fd26b831b3f',
      content: [
        {
          groupId: 'e60df539-8dc6-4f82-93d4-852dec977d6c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:15:41.100Z',

          createdAt: '2024-05-28T20:37:59.962Z',
          content: [
            {
              caption: '',

              id: 'ad4732b9-97ce-4e73-aaa4-2fea96d2ed42',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f74089bf-9402-4712-a60e-1fd26b831b3f/ugc/e60df539-8dc6-4f82-93d4-852dec977d6c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b514f26d-20d0-4f47-8e93-1b2aab711d5f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f74089bf-9402-4712-a60e-1fd26b831b3f/ugc/e60df539-8dc6-4f82-93d4-852dec977d6c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f7d455a0-f225-4089-863c-fc5bb1285fb4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f74089bf-9402-4712-a60e-1fd26b831b3f/ugc/e60df539-8dc6-4f82-93d4-852dec977d6c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e85909a7-b39f-4233-ab38-9fc970a0a9e4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:24:25.671Z',

          createdAt: '2024-05-28T20:36:43.962Z',
          content: [
            {
              caption: '',

              id: '5ecd33fc-e75f-4a0a-8e8c-265f7cf1466e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f74089bf-9402-4712-a60e-1fd26b831b3f/ugc/e85909a7-b39f-4233-ab38-9fc970a0a9e4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd67940fa-937d-4ddf-8a95-4e79f4bc6c0f',
        firstName: 'Karla',
        lastName: 'Rodriguez',
        email: 'karlarodriguez1215@gmail.com',
        instagramProfile: {
          handle: 'chewbasanink_93',
          followers: 2360,
          engagementRate: 1.0338983050847457
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T13:56:40.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1261f3de-840f-4d12-bf5d-8d0404e7abde',
      content: [
        {
          groupId: '8eab6656-117f-4c6d-81da-636dda7094d8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T21:48:45.829Z',

          createdAt: '2024-05-28T07:07:56.654Z',
          content: [
            {
              caption: '',

              id: '1a8871da-7d4f-4ce1-8808-063257867fdd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1261f3de-840f-4d12-bf5d-8d0404e7abde/ugc/8eab6656-117f-4c6d-81da-636dda7094d8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cafcee3d-2fff-4700-b965-5d8db731eaad',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T19:49:29.748Z',

          createdAt: '2024-05-28T07:08:51.877Z',
          content: [
            {
              caption: '',

              id: '005d6092-e07c-4ce8-ad41-75aa3e99998f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1261f3de-840f-4d12-bf5d-8d0404e7abde/ugc/cafcee3d-2fff-4700-b965-5d8db731eaad/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '360e9a38-f032-4837-8f98-8ca8818e5fa3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1261f3de-840f-4d12-bf5d-8d0404e7abde/ugc/cafcee3d-2fff-4700-b965-5d8db731eaad/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '47cbc452-0ce4-4a25-9c1d-f96ed1e394e6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1261f3de-840f-4d12-bf5d-8d0404e7abde/ugc/cafcee3d-2fff-4700-b965-5d8db731eaad/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3be79806-1c5d-462a-8648-bc72b37b4b78',
        firstName: 'Nayely',
        lastName: 'Umanzor',
        email: 'nayelyhuezo14@gmail.com',
        instagramProfile: {
          handle: 'galicia_spam',
          followers: 2
        },
        tiktokProfile: {
          handle: 'reviewing._products1',
          followers: 1461,
          engagementRate: 0.4464285714285714
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T14:32:49.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e3b27589-c13a-4ee8-b316-08f173ad17c1',
      content: [],
      creator: {
        id: 'aa0f8056-99e0-4b2c-b690-fed1027e870e',
        firstName: 'Angie',
        lastName: 'Cortez',
        email: 'angieeeefts02@gmail.com',
        instagramProfile: {
          handle: 'angieefromig',
          followers: 3481,
          engagementRate: 2.5843881856540083
        },
        tiktokProfile: {
          handle: 'angieesspammmmm',
          followers: 2107,
          engagementRate: 2.646868947708199
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T14:35:45.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '882e8a6d-d7de-481c-906c-4e5f99c38da9',
      content: [
        {
          groupId: 'a8a02d2d-7110-4915-bb04-2059de300ce6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-09T01:08:18.006Z',

          createdAt: '2024-06-03T00:22:03.796Z',
          content: [
            {
              caption: '',

              id: 'e645d5bc-7c76-404b-be28-dc736c40a4db',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/882e8a6d-d7de-481c-906c-4e5f99c38da9/ugc/a8a02d2d-7110-4915-bb04-2059de300ce6/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '82cd47aa-7e6e-4112-b22a-fe37dc5c2c23',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/882e8a6d-d7de-481c-906c-4e5f99c38da9/ugc/a8a02d2d-7110-4915-bb04-2059de300ce6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b7b15046-bb92-48d8-ae06-c4a522fcc843',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/882e8a6d-d7de-481c-906c-4e5f99c38da9/ugc/a8a02d2d-7110-4915-bb04-2059de300ce6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0178c3fd-86c0-4b25-82af-0351d9d16313',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T03:07:44.191Z',

          createdAt: '2024-06-07T18:59:19.510Z',
          content: [
            {
              caption: '',

              id: '8cecd020-1523-4256-aa0b-1635baf84e7d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/882e8a6d-d7de-481c-906c-4e5f99c38da9/ugc/0178c3fd-86c0-4b25-82af-0351d9d16313/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c964e95a-2fda-4122-91be-87b4af2b5714',
        firstName: 'Stephanie',
        lastName: 'Nagy',
        email: 'steph.nagy88@gmail.com',
        instagramProfile: {
          handle: 'unstoppableethanjourney',
          followers: 16,
          engagementRate: 15
        },
        tiktokProfile: {
          handle: 'unstoppableethanjourney',
          followers: 10554,
          engagementRate: 8.118081180811808
        },
        youtubeProfile: {
          handle: 'unstoppableethansjourney',
          followers: 4
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T15:02:59.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bbc610bb-2916-4299-9c87-358d674e1c3e',
      content: [],
      creator: {
        id: 'a5c17b50-d940-4753-b763-6b5d2adb2e99',
        firstName: 'Cherisse',
        lastName: 'Murray',
        email: 'murraycherisse@yahoo.com',

        tiktokProfile: {
          handle: 'cherissemurray22',
          followers: 2878,
          engagementRate: 3.1055900621118013
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T15:03:49.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '80cf43dc-cb6c-460a-816f-db485156bee9',
      content: [],
      creator: {
        id: '4d8b4dd8-3393-46a0-83d3-d701ecf539c2',
        firstName: 'Amy',
        lastName: 'Lakabung',
        email: 'sunflowerlakabung@gmail.com',
        instagramProfile: {
          handle: 'sunflowerlakabung',
          followers: 4523,
          engagementRate: 16.535485297369
        },
        tiktokProfile: {
          handle: 'sunflowerlakabung',
          followers: 477,
          engagementRate: 2.904564315352697
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T15:12:49.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd0322cf9-d665-4177-a72d-a5caf9f29cff',
      content: [],
      creator: {
        id: '52c9b7f2-f5b2-41a1-8951-6c4360ccaaef',
        firstName: 'Nikki',
        lastName: 'McKinney',
        email: 'uxdesignernikki@gmail.com',

        tiktokProfile: {
          handle: 'songstream',
          followers: 1011,
          engagementRate: 2.1933962264150946
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T15:24:17.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '35beda15-c3a1-4e03-9c30-543ac45fd931',
      content: [
        {
          groupId: 'e8a866e2-dca2-4950-92ef-ddb82ce53939',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T14:12:49.197Z',

          createdAt: '2024-06-14T15:06:43.351Z',
          content: [
            {
              caption: '',

              id: 'f9d6064e-3315-46a8-868f-4772b96f5682',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/35beda15-c3a1-4e03-9c30-543ac45fd931/ugc/e8a866e2-dca2-4950-92ef-ddb82ce53939/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e414c5dc-535d-4153-85b5-d1d9d69c88e8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/35beda15-c3a1-4e03-9c30-543ac45fd931/ugc/e8a866e2-dca2-4950-92ef-ddb82ce53939/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '780efdd4-ed25-4ec4-8b3d-5d4962e3c860',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/35beda15-c3a1-4e03-9c30-543ac45fd931/ugc/e8a866e2-dca2-4950-92ef-ddb82ce53939/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8b104be1-853d-49e9-a233-f404e9397ff5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-22T21:20:48.219Z',

          createdAt: '2024-06-14T15:18:22.937Z',
          content: [
            {
              caption: '',

              id: 'c0f8c73c-b4b1-42c6-bdef-cc4bf0e7702a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/35beda15-c3a1-4e03-9c30-543ac45fd931/ugc/8b104be1-853d-49e9-a233-f404e9397ff5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4a2e8acc-55c2-4da4-8fa1-c529ca900678',
        firstName: 'Alyssa',
        lastName: 'Salvesen',
        email: 'alyssasalvesen@gmail.com',
        instagramProfile: {
          handle: 'salvetwinsquad',
          followers: 7509,
          engagementRate: 0.800372885870289
        },

        youtubeProfile: {
          handle: 'storytimewithmissalyssa',
          followers: 311
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T15:53:01.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '56041e86-f4f1-46af-b0d5-0c14debbc5f1',
      content: [
        {
          groupId: 'd8b756f1-f290-44bd-8550-60fabc718b0f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T17:59:27.447Z',

          createdAt: '2024-05-28T17:45:32.545Z',
          content: [
            {
              caption: '',

              id: '598eec47-f04c-4b0d-a3e3-fca9088a5f42',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56041e86-f4f1-46af-b0d5-0c14debbc5f1/ugc/d8b756f1-f290-44bd-8550-60fabc718b0f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b4d272fe-95d6-4b9e-87d5-fc527eb5867e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56041e86-f4f1-46af-b0d5-0c14debbc5f1/ugc/d8b756f1-f290-44bd-8550-60fabc718b0f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd378466e-f1eb-4ac5-9402-c58173fc823e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T12:29:14.723Z',

          createdAt: '2024-06-07T14:15:04.313Z',
          content: [
            {
              caption: '',

              id: '36470e05-5ea3-4a56-ba4b-91e3713a5691',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56041e86-f4f1-46af-b0d5-0c14debbc5f1/ugc/d378466e-f1eb-4ac5-9402-c58173fc823e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'da2a1603-d534-4d08-bd81-4b7794b3f9ba',
        firstName: 'Bruna',
        lastName: 'Proenca',
        email: 'brendatur@yahoo.com.br',
        instagramProfile: {
          handle: 'brumaite',
          followers: 2897,
          engagementRate: 4.39247497411115
        },
        tiktokProfile: {
          handle: 'venturaflcorp',
          followers: 6,
          engagementRate: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:00:00.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e9c09ad5-b3e1-47d5-b048-effcc30d9979',
      content: [
        {
          groupId: 'd909c504-b0a2-42be-bb5a-48a473d54675',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-28T20:04:20.268Z',

          createdAt: '2024-05-27T20:01:04.791Z',
          content: [
            {
              caption: '',

              id: '72afe3f2-d7c2-4f9e-915e-255820b62544',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e9c09ad5-b3e1-47d5-b048-effcc30d9979/ugc/d909c504-b0a2-42be-bb5a-48a473d54675/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'dae397e7-8aae-45bc-b96c-0f78f4e8bd0a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e9c09ad5-b3e1-47d5-b048-effcc30d9979/ugc/d909c504-b0a2-42be-bb5a-48a473d54675/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8e1c44da-4d28-4bf1-8515-b4c16007bd58',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T22:28:47.729Z',

          createdAt: '2024-05-27T20:00:38.052Z',
          content: [
            {
              caption: '',

              id: 'b4923057-2e09-4388-9bc2-404d018ceb58',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e9c09ad5-b3e1-47d5-b048-effcc30d9979/ugc/8e1c44da-4d28-4bf1-8515-b4c16007bd58/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a2b99f3f-c9c2-47d4-9ae9-36a8092790a1',
        firstName: 'Erica',
        lastName: 'Rodriguez',
        email: 'ericarodriguezbeauty@gmail.com',
        instagramProfile: {
          handle: 'ericaaarodriguez',
          followers: 12296,
          engagementRate: 0.14720234222511386
        },
        tiktokProfile: {
          handle: 'ericarodriguezbeauty',
          followers: 8438,
          engagementRate: 12.440191387559809
        },
        youtubeProfile: {
          handle: 'ericarodriguezbeauty'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:09:57.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'accfa0cf-c0d5-4997-8259-4a93c9a093d2',
      content: [],
      creator: {
        id: '6e78bc91-9c0a-40a3-8352-03962b52a69e',
        firstName: 'Idalias',
        lastName: 'Palmer',
        email: 'idaliassocialinquiries@gmail.com',
        instagramProfile: {
          handle: 'idalias.m',
          followers: 2370,
          engagementRate: 1.9514767932489452
        },
        tiktokProfile: {
          handle: 'idali.ass',
          followers: 443,
          engagementRate: 9.074074074074074
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:13:28.000Z',
        amountCents: 7386
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0c927124-9cef-4171-a316-7a5088908a86',
      content: [],
      creator: {
        id: '820b36b3-ae91-4013-8a49-ce919d51cc5e',
        firstName: 'Brittany',
        lastName: 'Grimm',
        email: 'brittanygrimmtiktok@gmail.com',

        tiktokProfile: {
          handle: 'brittany_grimm',
          followers: 10019,
          engagementRate: 8.507349534875644
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:14:31.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2bebc37d-7f9d-4848-ab60-60f511f5073c',
      content: [
        {
          groupId: 'ded2ca24-962a-4638-bffc-c753b3855b74',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T20:46:18.397Z',

          createdAt: '2024-05-29T01:12:54.808Z',
          content: [
            {
              caption: '',

              id: 'bd4e47ef-d39d-4ca0-845f-e014406d3132',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bebc37d-7f9d-4848-ab60-60f511f5073c/ugc/ded2ca24-962a-4638-bffc-c753b3855b74/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c1996586-9336-4122-be05-3d8d41cb1ada',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bebc37d-7f9d-4848-ab60-60f511f5073c/ugc/ded2ca24-962a-4638-bffc-c753b3855b74/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '165bba1e-0a12-426e-9a77-a034d06d65e7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bebc37d-7f9d-4848-ab60-60f511f5073c/ugc/ded2ca24-962a-4638-bffc-c753b3855b74/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c63404ce-cf0d-49bf-8389-193eee728397',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:03:17.885Z',

          createdAt: '2024-05-30T20:23:35.798Z',
          content: [
            {
              caption: '',

              id: 'b91c8d50-9cf0-4b0e-b747-1d604f907a69',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bebc37d-7f9d-4848-ab60-60f511f5073c/ugc/c63404ce-cf0d-49bf-8389-193eee728397/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4288883b-f7e6-48a4-a34b-53aa6eefb010',
        firstName: 'Heather',
        lastName: 'Martin',
        email: 'heathermartin1015@gmail.com',

        tiktokProfile: {
          handle: 'heathermartin80',
          followers: 1479
        },
        youtubeProfile: {
          handle: 'heathermartin7558',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:17:35.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a5bb4d87-94f9-4160-a2ea-f90608d52935',
      content: [
        {
          groupId: 'cf75661e-82f8-4131-bcda-c0dbe9ed5802',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T04:08:01.225Z',

          createdAt: '2024-06-03T20:45:25.661Z',
          content: [
            {
              caption: '',

              id: '1278f9c2-2f61-4089-945d-f4d89db39273',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a5bb4d87-94f9-4160-a2ea-f90608d52935/ugc/cf75661e-82f8-4131-bcda-c0dbe9ed5802/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5a7b6a39-6c03-4901-9553-cda1fd6232ec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a5bb4d87-94f9-4160-a2ea-f90608d52935/ugc/cf75661e-82f8-4131-bcda-c0dbe9ed5802/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd31d79c9-fb69-4be8-8f8e-89841505096f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a5bb4d87-94f9-4160-a2ea-f90608d52935/ugc/cf75661e-82f8-4131-bcda-c0dbe9ed5802/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fcd76f4f-f056-461c-a4cf-f7f78876dc50',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:19:10.953Z',

          createdAt: '2024-06-03T19:47:16.554Z',
          content: [
            {
              caption: '',

              id: '44873b6e-c0ae-46bf-86a1-441c6e8d1f7e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a5bb4d87-94f9-4160-a2ea-f90608d52935/ugc/fcd76f4f-f056-461c-a4cf-f7f78876dc50/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3abb2602-5a0b-46e7-811b-0409d043cac8',
        firstName: 'Nyejah',
        lastName: 'Davis',
        email: 'nyejahbolds@gmail.com',
        instagramProfile: {
          handle: 'nyejahboldsphotography',
          followers: 9447,
          engagementRate: 1.213083518577326
        },
        tiktokProfile: {
          handle: 'itsnyejah',
          followers: 2426,
          engagementRate: 8.744982387154913
        },
        youtubeProfile: {
          handle: 'itsnyejah',
          followers: 26
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:21:31.000Z',
        amountCents: 8734
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '27ab408f-5659-41f7-99d2-b91e166d86ac',
      content: [],
      creator: {
        id: 'a6c7f638-c75f-4dd1-b3a7-7ba88b08efbb',
        firstName: 'Samantha',
        lastName: 'Harles',
        email: 'sme.acosta@gmail.com',
        instagramProfile: {
          handle: 'howweharles',
          followers: 1936,
          engagementRate: 2.8357438016528924
        },
        tiktokProfile: {
          handle: 'theharleslibrary',
          followers: 5991,
          engagementRate: 14.953271028037381
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:22:17.000Z',
        amountCents: 7650
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0762dc91-003d-40b9-aa32-9877764377d7',
      content: [
        {
          groupId: 'e0d9b3d3-fd3c-4a2b-b2b9-a6238fc8af1f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-09T23:45:38.618Z',

          createdAt: '2024-06-01T23:58:31.551Z',
          content: [
            {
              caption: '',

              id: '0726564c-1497-49d1-ac5e-4be1a3389f6c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0762dc91-003d-40b9-aa32-9877764377d7/ugc/e0d9b3d3-fd3c-4a2b-b2b9-a6238fc8af1f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8ea600f9-00d3-4d1f-aeaa-ae3ab5992aec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0762dc91-003d-40b9-aa32-9877764377d7/ugc/e0d9b3d3-fd3c-4a2b-b2b9-a6238fc8af1f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b0d91f98-5ed7-449e-b45e-053b59702611',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0762dc91-003d-40b9-aa32-9877764377d7/ugc/e0d9b3d3-fd3c-4a2b-b2b9-a6238fc8af1f/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f0157c99-15f4-4315-82f2-ea1c8023f109',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T11:47:08.063Z',

          createdAt: '2024-06-09T23:44:03.052Z',
          content: [
            {
              caption: '',

              id: 'a08ba7b7-fed8-47c5-a316-bf97d8973dba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0762dc91-003d-40b9-aa32-9877764377d7/ugc/f0157c99-15f4-4315-82f2-ea1c8023f109/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '31b49b6d-155f-4651-b372-06729dd67185',
        firstName: 'Kasandra',
        lastName: 'Hernandez',
        email: 'fittkass@gmail.com',
        instagramProfile: {
          handle: 'kasandruhh',
          followers: 2818,
          engagementRate: 5.606813342796309
        },
        tiktokProfile: {
          handle: 'kasandruh',
          followers: 2258,
          engagementRate: 4.406364749082008
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:22:40.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ab4f7404-a4dd-4fca-82fa-78896eef1a17',
      content: [],
      creator: {
        id: '46298c1e-c66b-4ce2-b1c3-ff67cff4e3e8',
        firstName: 'Riyonte',
        lastName: 'Butler',
        email: 'reecreationss@gmail.com',
        instagramProfile: {
          handle: 'n.noyir',
          followers: 7363,
          engagementRate: 0.8040836608393187
        },
        tiktokProfile: {
          handle: 'ree.creationss',
          followers: 1569,
          engagementRate: 5.415162454873646
        },
        youtubeProfile: {
          handle: 'reecreationss',
          followers: 7680
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:24:10.000Z',
        amountCents: 7660
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6d934f65-9b53-4d6e-9c82-4ed9d5ecfd25',
      content: [],
      creator: {
        id: '7d5c7a48-8f1b-4957-815a-f704669919ae',
        firstName: 'Suddanie',
        lastName: 'Wright',
        email: 'invoiceedits@gmail.com',

        tiktokProfile: {
          handle: 'suddanie',
          followers: 1529,
          engagementRate: 8.913649025069638
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:32:20.000Z',
        amountCents: 10000
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '20bf1cab-45a8-4f03-923b-44f2d9d3603c',
      content: [
        {
          groupId: '182b93dc-124b-4f12-822d-2da863a98dba',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T20:50:06.963Z',

          createdAt: '2024-06-03T15:57:38.147Z',
          content: [
            {
              caption: '',

              id: '6475bf7f-848a-4c5e-b637-9846ca39f5c0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/20bf1cab-45a8-4f03-923b-44f2d9d3603c/ugc/182b93dc-124b-4f12-822d-2da863a98dba/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '8005de20-6dee-4c54-b745-c5bfd606162e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T20:50:00.117Z',

          createdAt: '2024-06-03T15:58:46.343Z',
          content: [
            {
              caption: '',

              id: 'b270912e-e4fe-43c4-bec2-9350ee2cbd33',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/20bf1cab-45a8-4f03-923b-44f2d9d3603c/ugc/8005de20-6dee-4c54-b745-c5bfd606162e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1dbcce73-aa88-42b9-ade5-c8b9098f64ee',
        firstName: 'Christina',
        lastName: 'Martinez',
        email: 'christinaporras215@me.com',

        tiktokProfile: {
          handle: 'christina.m.5',
          followers: 3383,
          engagementRate: 12.91116931033915
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:50:55.000Z',
        amountCents: 7699
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f43cd368-1aec-475b-b477-05963391175e',
      content: [
        {
          groupId: '3d2aaf0d-3ee1-403b-b027-9a83e77db474',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T06:40:23.848Z',

          createdAt: '2024-06-12T18:51:41.770Z',
          content: [
            {
              caption: '',

              id: '80218da3-e792-42bc-8277-1b4202919784',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f43cd368-1aec-475b-b477-05963391175e/ugc/3d2aaf0d-3ee1-403b-b027-9a83e77db474/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '09fbeada-59b6-4d5c-bf43-d0b8e1433bcd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T13:55:55.721Z',

          createdAt: '2024-06-05T10:04:46.906Z',
          content: [
            {
              caption: '',

              id: 'd522a827-169f-4cc2-92c0-7efebe645332',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f43cd368-1aec-475b-b477-05963391175e/ugc/09fbeada-59b6-4d5c-bf43-d0b8e1433bcd/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1fdf9365-6f59-4abd-92a2-637993309a4c',
        firstName: 'Jamie',
        lastName: 'Lynn',
        email: 'jamiebullington7903@icloud.com',
        instagramProfile: {
          handle: 'jamielynnugc',
          followers: 2255,
          engagementRate: 0.6651884700665188
        },
        tiktokProfile: {
          handle: 'jamielynnugc',
          followers: 2629,
          engagementRate: 11.643835616438356
        },
        youtubeProfile: {
          handle: 'jamielynnugc',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:51:39.000Z',
        amountCents: 8825
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '62b55f2d-c3b8-4abf-972e-11ae1cfa8d47',
      content: [],
      creator: {
        id: '957fd609-d6f3-4af4-99f8-15f45bb1bace',
        firstName: 'Rachel',
        lastName: 'Jordan',
        email: 'rachel.l.jordan@gmail.com',
        instagramProfile: {
          handle: 'ruhchel',
          followers: 1288,
          engagementRate: 2.5854037267080745
        },
        tiktokProfile: {
          handle: 'ruhchel4',
          followers: 13
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:52:57.000Z',
        amountCents: 7601
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4bc4b1d9-40be-4a0f-9fb1-968d13f33e91',
      content: [
        {
          groupId: 'b743ad12-68a1-4edc-a865-900c11ab9518',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T19:30:00.300Z',

          createdAt: '2024-06-05T18:09:35.886Z',
          content: [
            {
              caption: '',

              id: '54942607-aab3-458f-9abd-493a0a49fb91',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4bc4b1d9-40be-4a0f-9fb1-968d13f33e91/ugc/b743ad12-68a1-4edc-a865-900c11ab9518/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '55be16da-97a1-49b5-a04b-92f00c43a76f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4bc4b1d9-40be-4a0f-9fb1-968d13f33e91/ugc/b743ad12-68a1-4edc-a865-900c11ab9518/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6869f8f0-8352-4a6b-9b5a-b82b8155affd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4bc4b1d9-40be-4a0f-9fb1-968d13f33e91/ugc/b743ad12-68a1-4edc-a865-900c11ab9518/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '69ccf139-3310-41d5-9020-99127c3db03b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T11:47:30.583Z',

          createdAt: '2024-06-05T20:03:32.578Z',
          content: [
            {
              caption: '',

              id: 'add54270-5822-479d-ba32-55b3f268e5c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4bc4b1d9-40be-4a0f-9fb1-968d13f33e91/ugc/69ccf139-3310-41d5-9020-99127c3db03b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '46a96dfa-f3a2-496e-bd6e-f0d84b5e84d2',
        firstName: 'Allison',
        lastName: 'Hernandez',
        email: 'allirose.hern@gmail.com',
        instagramProfile: {
          handle: 'allirose_hern',
          followers: 1214,
          engagementRate: 1.0955518945634268
        },
        tiktokProfile: {
          handle: 'lifewithallirose_hern',
          followers: 14,
          engagementRate: 2.162483039348711
        },
        youtubeProfile: {
          handle: 'allirose_hern'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:54:01.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b1d534a8-dbf9-4500-b43d-b2703b79a534',
      content: [
        {
          groupId: '3bcefa9d-c495-4905-a828-7c322a4c5157',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:25:10.097Z',

          createdAt: '2024-05-29T00:22:33.419Z',
          content: [
            {
              caption: '',

              id: '31de2ffa-b018-4234-ac67-adab66a13616',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b1d534a8-dbf9-4500-b43d-b2703b79a534/ugc/3bcefa9d-c495-4905-a828-7c322a4c5157/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '4d8f06f9-0214-433c-95b5-67c4de4032c2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:16:59.596Z',

          createdAt: '2024-05-29T00:23:04.302Z',
          content: [
            {
              caption: '',

              id: '3358b73e-c385-440c-b1ab-86fb686b0285',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b1d534a8-dbf9-4500-b43d-b2703b79a534/ugc/4d8f06f9-0214-433c-95b5-67c4de4032c2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '82adbf9e-76fb-4b85-8ea0-f7514bf32a3c',
        firstName: 'Ebonie',
        lastName: 'Ghant',
        email: 'ebonieghant@gmail.com',
        instagramProfile: {
          handle: 'kurvykoutoreboutique',
          followers: 143,
          engagementRate: 0.27972027972027974
        },
        tiktokProfile: {
          handle: 'ebbieplus33',
          followers: 3924,
          engagementRate: 6.666666666666667
        },
        youtubeProfile: {
          handle: 'ghantfamily325',
          followers: 19
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:55:01.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b1319457-ad8f-499a-b0b6-6f4e1f2eb210',
      content: [
        {
          groupId: '4162787a-8072-48be-b5cf-7a238801bfff',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T16:36:01.482Z',

          createdAt: '2024-06-02T01:11:54.771Z',
          content: [
            {
              caption: '',

              id: '1cd9fb44-6733-4826-b5b8-c67e52490d7b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b1319457-ad8f-499a-b0b6-6f4e1f2eb210/ugc/4162787a-8072-48be-b5cf-7a238801bfff/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '476a2f5e-97fc-4136-9fff-e37709274a08',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b1319457-ad8f-499a-b0b6-6f4e1f2eb210/ugc/4162787a-8072-48be-b5cf-7a238801bfff/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '1109c932-a74b-43f1-a180-f207bbd1bc87',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T20:11:44.707Z',

          createdAt: '2024-06-02T01:33:09.501Z',
          content: [
            {
              caption: '',

              id: '5e7961dd-0b0c-4a22-9cec-1d627d5cddbb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b1319457-ad8f-499a-b0b6-6f4e1f2eb210/ugc/1109c932-a74b-43f1-a180-f207bbd1bc87/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '67a83120-3290-41b4-a33c-9a34257324ef',
        firstName: 'Oralia',
        lastName: 'Escutia',
        email: 'oraliaescutia@yahoo.com',
        instagramProfile: {
          handle: 'oraliael',
          followers: 1695
        },
        tiktokProfile: {
          handle: 'escutia_oralia',
          followers: 500,
          engagementRate: 7.63582966226138
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T16:55:45.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cc64a3f5-505a-4a12-81d4-3ccb12497cdb',
      content: [
        {
          groupId: 'f568d15c-6e36-45ec-b2a1-0606fc689b79',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:46:35.764Z',

          createdAt: '2024-06-01T19:27:56.802Z',
          content: [
            {
              caption: '',

              id: 'a547c298-66b6-448c-8b9e-4c60e4872c10',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cc64a3f5-505a-4a12-81d4-3ccb12497cdb/ugc/f568d15c-6e36-45ec-b2a1-0606fc689b79/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '66433696-ed9d-4226-b1ef-adfd5a30dbcc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:46:58.421Z',

          createdAt: '2024-06-01T19:27:16.293Z',
          content: [
            {
              caption: '',

              id: 'bbb5ba5b-a4c6-41c9-a2d9-4fed0dd2f786',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cc64a3f5-505a-4a12-81d4-3ccb12497cdb/ugc/66433696-ed9d-4226-b1ef-adfd5a30dbcc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '228a185a-fdc7-4574-8df9-52724f7b8ca5',
        firstName: 'Michael',
        lastName: 'DeTerlizzi',
        email: 'info.determv@gmail.com',
        instagramProfile: {
          handle: 'thegood_deetz',
          followers: 4974,
          engagementRate: 2.549256131885806
        },
        tiktokProfile: {
          handle: 'coachdeetz',
          followers: 45
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:03:22.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b69aba4d-9f4e-4da6-87df-72d9988a3def',
      content: [
        {
          groupId: '228f5c02-aac2-454b-8fa3-990cf4cb77ff',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-25T17:38:44.759Z',

          createdAt: '2024-06-24T14:21:16.345Z',
          content: [
            {
              caption: '',

              id: 'bdab2c38-df7a-4ebe-a54f-9fc4cef880a7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b69aba4d-9f4e-4da6-87df-72d9988a3def/ugc/228f5c02-aac2-454b-8fa3-990cf4cb77ff/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ca8277cd-ffff-41d5-aef7-adcff2dc5367',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b69aba4d-9f4e-4da6-87df-72d9988a3def/ugc/228f5c02-aac2-454b-8fa3-990cf4cb77ff/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cb7b237e-467f-414c-9ff4-298b866a46d2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T17:38:39.574Z',

          createdAt: '2024-06-25T03:07:43.656Z',
          content: [
            {
              caption: '',

              id: '8282eca8-8086-45aa-8f08-f0a4281a1a83',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b69aba4d-9f4e-4da6-87df-72d9988a3def/ugc/cb7b237e-467f-414c-9ff4-298b866a46d2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a00f615e-8f8a-4ed2-a6ad-95fdedbda1fa',
        firstName: 'Carolina',
        lastName: 'Viloria',
        email: 'carolinacvp23@gmail.com',
        instagramProfile: {
          handle: 'caroolvvp',
          followers: 2138,
          engagementRate: 3.208606173994387
        },
        tiktokProfile: {
          handle: 'carocv',
          followers: 1179,
          engagementRate: 13.297872340425531
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:12:41.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8e369290-f70d-4efe-a208-424e41b96f0f',
      content: [
        {
          groupId: '0b429712-87c8-4f73-b4b7-3835876e006e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-13T19:20:57.360Z',

          createdAt: '2024-06-02T00:03:36.853Z',
          content: [
            {
              caption: '',

              id: 'ed81df99-5d83-48da-ae8d-3864dca6c2b9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e369290-f70d-4efe-a208-424e41b96f0f/ugc/0b429712-87c8-4f73-b4b7-3835876e006e/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e5730214-af86-416f-8b39-62c6c7acb216',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e369290-f70d-4efe-a208-424e41b96f0f/ugc/0b429712-87c8-4f73-b4b7-3835876e006e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f577a01e-9347-4321-b88b-db9b8b135100',
        firstName: 'Tara',
        lastName: 'Enright',
        email: 'tarachristinee@gmail.com',
        instagramProfile: {
          handle: 'tarachristine03',
          followers: 3854,
          engagementRate: 0.5111572392319668
        },
        tiktokProfile: {
          handle: 'tarachristinee1985',
          followers: 2095,
          engagementRate: 1.5151515151515151
        },
        youtubeProfile: {
          handle: 'livelaughlove5'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:12:56.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '61b8d766-f539-4033-953b-d6be444fa6dc',
      content: [
        {
          groupId: '8759f6d2-2966-4f16-bda5-e442fe3e1b40',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T19:15:02.180Z',

          createdAt: '2024-05-28T17:18:42.166Z',
          content: [
            {
              caption: '',

              id: 'abc8dcb1-722b-4001-92fc-9dccce6eebea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/61b8d766-f539-4033-953b-d6be444fa6dc/ugc/8759f6d2-2966-4f16-bda5-e442fe3e1b40/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ecac22fd-c975-4845-a7b8-0bde9e805b8d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T19:14:56.046Z',

          createdAt: '2024-05-28T23:24:50.810Z',
          content: [
            {
              caption: '',

              id: 'e603c605-656a-4bc4-be05-08938b282d6c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/61b8d766-f539-4033-953b-d6be444fa6dc/ugc/ecac22fd-c975-4845-a7b8-0bde9e805b8d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '51dd64c5-394d-4c65-9c3f-35f8f0a06780',
        firstName: 'Vania',
        lastName: 'Zuniga',
        email: 'npvaniaz@gmail.com',
        instagramProfile: {
          handle: 'lovealways_vania',
          followers: 12538,
          engagementRate: 0.6157281863136067
        },
        tiktokProfile: {
          handle: 'lovealways_vania',
          followers: 374,
          engagementRate: 1.2379642365887207
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:24:50.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7c0d6100-d2d5-474f-81bd-a7c6443080be',
      content: [
        {
          groupId: '5d5edaa5-457b-485f-b95b-2140e8d7e84d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-21T10:07:33.086Z',

          createdAt: '2024-06-15T17:46:55.976Z',
          content: [
            {
              caption: '',

              id: '355eda3b-9018-491c-95bb-f3c1c83d3e1b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7c0d6100-d2d5-474f-81bd-a7c6443080be/ugc/5d5edaa5-457b-485f-b95b-2140e8d7e84d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0a0b476d-61bc-4864-8b47-388487b6647f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7c0d6100-d2d5-474f-81bd-a7c6443080be/ugc/5d5edaa5-457b-485f-b95b-2140e8d7e84d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3e06f638-4be6-4d65-be0b-d7bdf2f802be',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7c0d6100-d2d5-474f-81bd-a7c6443080be/ugc/5d5edaa5-457b-485f-b95b-2140e8d7e84d/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9d2db0b7-283e-40b3-9f42-21e5c67a18c2',
        firstName: 'Makayla',
        lastName: 'Smith',
        email: 'makrhatfield@gmail.com',
        instagramProfile: {
          handle: 'atsignmakayla',
          followers: 1206,
          engagementRate: 0.24875621890547264
        },
        tiktokProfile: {
          handle: 'makaylarsmith',
          followers: 226,
          engagementRate: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:27:35.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '400a8688-ee26-42ab-bda5-ea0d11a50fc1',
      content: [
        {
          groupId: '6897b242-c85a-4bec-9180-cb35cf8d31e3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T12:59:17.736Z',

          createdAt: '2024-06-11T01:57:55.931Z',
          content: [
            {
              caption: '',

              id: 'ca46f293-16ea-41a6-af81-a9cf270241cf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/400a8688-ee26-42ab-bda5-ea0d11a50fc1/ugc/6897b242-c85a-4bec-9180-cb35cf8d31e3/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd9477a8d-9a84-43cd-b7df-5f4c050a2b62',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:51:59.743Z',

          createdAt: '2024-06-11T00:33:37.493Z',
          content: [
            {
              caption: '',

              id: 'b9eb85ff-fa8a-47cd-a44a-6c2c045c6650',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/400a8688-ee26-42ab-bda5-ea0d11a50fc1/ugc/d9477a8d-9a84-43cd-b7df-5f4c050a2b62/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '647daa4a-4fce-48eb-b194-2c41c350a0e7',
        firstName: 'Cece',
        lastName: 'Xu',
        email: 'chillwithcece@gmail.com',
        instagramProfile: {
          handle: 'chillwithcece_24',
          followers: 1773,
          engagementRate: 6.164692611393119
        },
        tiktokProfile: {
          handle: 'chillwithcece_24',
          followers: 2322,
          engagementRate: 9.550561797752808
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:32:04.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '50505609-105a-439b-989f-953a72684188',
      content: [
        {
          groupId: '416472f5-b20d-4fbb-87ad-d43709dff6b7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T22:21:11.053Z',

          createdAt: '2024-05-27T21:58:57.953Z',
          content: [
            {
              caption: '',

              id: '9b8cac7c-c019-44ae-8b43-c2567e2667b7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50505609-105a-439b-989f-953a72684188/ugc/416472f5-b20d-4fbb-87ad-d43709dff6b7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c412a040-d899-4920-b0ed-8265868266f2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T22:21:06.145Z',

          createdAt: '2024-05-27T21:58:31.922Z',
          content: [
            {
              caption: '',

              id: 'b533a9de-9c96-49a9-bf69-d0a08f0d4f4d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50505609-105a-439b-989f-953a72684188/ugc/c412a040-d899-4920-b0ed-8265868266f2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6eb160e4-7c19-447e-bc04-5948773c1b47',
        firstName: 'Sefika',
        lastName: 'Pruitt',
        email: 'ssl0312@yahoo.com',
        instagramProfile: {
          handle: 'sefi4011',
          followers: 24853,
          engagementRate: 0.0633726310706957
        },
        tiktokProfile: {
          handle: 'gia00777',
          followers: 3272,
          engagementRate: 1.5679098457090992
        },
        youtubeProfile: {
          handle: 'bunnybee-gd7cu',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:36:27.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1094d22c-a2a5-4a6d-91e5-dbcd6d494eb7',
      content: [],
      creator: {
        id: '80fe25c7-380d-4b9f-8674-9589fd7d76d6',
        firstName: 'Stephanie',
        lastName: 'Black-Burke',
        email: 'stephtheoriginal@gmail.com',
        instagramProfile: {
          handle: 'stephtheoriginal',
          followers: 606,
          engagementRate: 13.34983498349835
        },
        tiktokProfile: {
          handle: 'stephtheoriginal',
          followers: 1192,
          engagementRate: 3.732912723449001
        },
        youtubeProfile: {
          handle: 'stephtheoriginal',
          followers: 362
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:36:31.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '00452d1d-bbb7-4ff2-ab39-947056242bb5',
      content: [],
      creator: {
        id: 'c6f0d9e6-a599-4be8-8828-91866e6f198b',
        firstName: 'Dixie',
        lastName: 'Raine',
        email: 'dixie.epps@yahoo.com',
        instagramProfile: {
          handle: 'rainesangel',
          followers: 157,
          engagementRate: 0.18198362147406733
        },
        tiktokProfile: {
          handle: 'angelicraine',
          followers: 4371,
          engagementRate: 1.8315018315018317
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:43:40.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2caab3e1-4e84-41b9-98ba-cdb08357afa4',
      content: [],
      creator: {
        id: 'c61eedf1-86a2-433e-8cda-659f94c34c23',
        firstName: 'Jessica',
        lastName: 'Roberson',
        email: 'jessicarobersonbusiness@gmail.com',
        instagramProfile: {
          handle: 'jessicanicoleroberson',
          followers: 838,
          engagementRate: 3.186157517899761
        },
        tiktokProfile: {
          handle: 'life_with_jessica_',
          followers: 274,
          engagementRate: 7.119309812723573
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:52:37.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ab6aff8d-a318-4863-b0f5-1d593b6c4078',
      content: [],
      creator: {
        id: '8651b032-2f1c-4ff9-9e5d-048bd9c9e8f5',
        firstName: 'Tiffany',
        lastName: 'Boardwine',
        email: 'tboardwine93@gmail.com',
        instagramProfile: {
          handle: 'tiffanytellsall',
          followers: 6631,
          engagementRate: 0.4478962449102699
        },
        tiktokProfile: {
          handle: 'tiffanytellsall',
          followers: 2855
        },
        youtubeProfile: {
          handle: 'tiffanytellsall'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:54:26.000Z',
        amountCents: 7370
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd37c8522-d61f-4921-b9a7-2063b504cea3',
      content: [],
      creator: {
        id: '05ac1c11-6282-43d2-b0fe-d5df81a53e79',
        firstName: 'Patsy',
        lastName: 'Collier',
        email: 'patsy.collier@outlook.com',
        instagramProfile: {
          handle: 'collierp1988',
          followers: 220,
          engagementRate: 1.5909090909090908
        },
        tiktokProfile: {
          handle: 'mrs.collier36',
          followers: 1859,
          engagementRate: 5.660377358490567
        },
        youtubeProfile: {
          handle: 'patsybennett8060'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T17:54:29.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2bcb0198-84a6-47e2-9be3-e80dfe1bf52a',
      content: [],
      creator: {
        id: 'f00443f8-80c2-4697-ae30-0a7c3fb90dca',
        firstName: 'isabella',
        lastName: 'rodriguez',
        email: 'bellarodriguez0206@gmail.com',

        tiktokProfile: {
          handle: 'iisa.raquel',
          followers: 1937
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T18:01:11.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '404f8e64-d106-47ac-a9b0-087d118f156f',
      content: [
        {
          groupId: '6c52db5f-92c8-4f30-bb0e-19398165f0bf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T12:53:55.992Z',

          createdAt: '2024-06-10T16:55:10.737Z',
          content: [
            {
              caption: '',

              id: 'e56b2bb8-6c16-4a09-9432-bdfa5399558d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/404f8e64-d106-47ac-a9b0-087d118f156f/ugc/6c52db5f-92c8-4f30-bb0e-19398165f0bf/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0c642466-8a11-41dd-a921-de06f4eb34ac',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T11:07:59.164Z',

          createdAt: '2024-06-12T19:16:29.833Z',
          content: [
            {
              caption: '',

              id: 'c8b6d41a-e813-4a41-9587-cab5b799a95d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/404f8e64-d106-47ac-a9b0-087d118f156f/ugc/0c642466-8a11-41dd-a921-de06f4eb34ac/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1e6b12f7-2cdf-4460-bcd6-b4f5011b7495',
        firstName: 'Amanda',
        lastName: 'Dantice',
        email: 'amanda.dantice@gmail.com',
        instagramProfile: {
          handle: 'dhampandas_reviews',
          followers: 3616,
          engagementRate: 1.4988938053097347
        },
        tiktokProfile: {
          handle: 'dhampandas_reviews',
          followers: 985,
          engagementRate: 4.14239258285477
        },
        youtubeProfile: {
          handle: 'dhampandasreviews',
          followers: 25
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T18:01:43.000Z',
        amountCents: 7601
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a632d54d-4be0-43b8-8f25-9c57d210f580',
      content: [
        {
          groupId: '7660a570-70a7-4b4f-9f98-2162df32c97f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T12:57:44.643Z',

          createdAt: '2024-06-01T17:16:57.324Z',
          content: [
            {
              caption: '',

              id: '2e595c8a-c4e0-4bfd-af22-d466a4c34576',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a632d54d-4be0-43b8-8f25-9c57d210f580/ugc/7660a570-70a7-4b4f-9f98-2162df32c97f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bec5855a-9312-4763-bc70-ed3cc08ce2ac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a632d54d-4be0-43b8-8f25-9c57d210f580/ugc/7660a570-70a7-4b4f-9f98-2162df32c97f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '868a28a8-5b9c-4600-8656-01d365608a5e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a632d54d-4be0-43b8-8f25-9c57d210f580/ugc/7660a570-70a7-4b4f-9f98-2162df32c97f/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7d7cebb4-cf3d-4fe7-8076-9027adbd7543',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T23:40:03.308Z',

          createdAt: '2024-06-01T17:16:20.264Z',
          content: [
            {
              caption: '',

              id: 'fcaaf99a-4c2d-41d3-a9e4-cf6b2e5bffa9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a632d54d-4be0-43b8-8f25-9c57d210f580/ugc/7d7cebb4-cf3d-4fe7-8076-9027adbd7543/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '99b1cb3e-dcb0-4499-8b19-6424427a5290',
        firstName: 'Kara',
        lastName: 'Clay',
        email: 'karaclay91@gmail.com',
        instagramProfile: {
          handle: 'kara_lee91__',
          followers: 1214,
          engagementRate: 0.5632653061224491
        },
        tiktokProfile: {
          handle: 'karaclay91',
          followers: 566,
          engagementRate: 1.1450381679389312
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T18:15:25.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '72cb7bdd-7ad3-4d7a-b547-18895c0862b9',
      content: [],
      creator: {
        id: '11eedddf-4b3d-4d25-ae3a-9efea24339d9',
        firstName: 'Jaamal',
        lastName: 'Mckeithan',
        email: 'iamdominiquebilly@gmail.com',
        instagramProfile: {
          handle: 'da_heartbreak_kidd__',
          followers: 14096,
          engagementRate: 0.641316685584563
        },
        tiktokProfile: {
          handle: 'daheartbreakkidd908',
          followers: 402
        },
        youtubeProfile: {
          handle: 'hbk908'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T18:44:31.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0365733b-d2e9-4323-a0d0-7603df708a72',
      content: [
        {
          groupId: 'bf8e1758-b694-43f7-8d8d-d9e485495cb4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:08:59.650Z',

          createdAt: '2024-05-31T21:51:31.741Z',
          content: [
            {
              caption: '',

              id: '2c043db4-874e-4874-9977-e7ff9e2cae0e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0365733b-d2e9-4323-a0d0-7603df708a72/ugc/bf8e1758-b694-43f7-8d8d-d9e485495cb4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ee41bfcf-2b00-473a-a39c-e89b6485566e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T15:21:23.693Z',

          createdAt: '2024-05-31T21:52:01.635Z',
          content: [
            {
              caption: '',

              id: 'abb364a9-87d3-4f83-a7a8-572c024f7147',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0365733b-d2e9-4323-a0d0-7603df708a72/ugc/ee41bfcf-2b00-473a-a39c-e89b6485566e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '33800314-f47b-46de-b1fc-a2e31095a3b8',
        firstName: 'Jessica',
        lastName: 'Cordero',
        email: 'jcordero418@gmail.com',

        tiktokProfile: {
          handle: 'jessicarelatablemama',
          followers: 1325,
          engagementRate: 7.060758859922039
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T18:52:52.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9acd1da5-0de8-4627-a7f2-735b5033c4b9',
      content: [
        {
          groupId: 'ee54ac68-6167-442c-a20d-5dc53bd3fa2b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T07:52:55.078Z',

          createdAt: '2024-06-04T02:14:27.808Z',
          content: [
            {
              caption: '',

              id: '30292a1e-3f0d-488f-9006-7fb382ed645f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9acd1da5-0de8-4627-a7f2-735b5033c4b9/ugc/ee54ac68-6167-442c-a20d-5dc53bd3fa2b/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '128dc0e2-9e56-4028-874e-ce7694ed4e12',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9acd1da5-0de8-4627-a7f2-735b5033c4b9/ugc/ee54ac68-6167-442c-a20d-5dc53bd3fa2b/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0ae111df-575c-410c-97ac-f70fc4504d95',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T07:53:01.044Z',

          createdAt: '2024-06-01T12:40:13.288Z',
          content: [
            {
              caption: '',

              id: 'e58357ca-5422-4ca8-b7a3-7528880e8fc9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9acd1da5-0de8-4627-a7f2-735b5033c4b9/ugc/0ae111df-575c-410c-97ac-f70fc4504d95/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c664131d-fc57-4beb-b94b-60d9707b0090',
        firstName: 'Tiffany',
        lastName: 'Bailey',
        email: 'tbailey92@live.com',
        instagramProfile: {
          handle: 'di_rebelchild',
          followers: 1668,
          engagementRate: 0.9592326139088728
        },
        tiktokProfile: {
          handle: 'lilrebel_child',
          followers: 223,
          engagementRate: 7.352941176470589
        },
        youtubeProfile: {
          handle: 'hottiffa',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T18:54:19.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0d0baf35-50ba-4a43-8043-bbb647b29e72',
      content: [
        {
          groupId: '53ceca31-f1b3-45c2-b63f-8dcaa38125c3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:33:24.110Z',

          createdAt: '2024-06-02T12:09:19.836Z',
          content: [
            {
              caption: '',

              id: 'e37fde37-a08f-4c66-abc5-4ce33f3bfc6f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0d0baf35-50ba-4a43-8043-bbb647b29e72/ugc/53ceca31-f1b3-45c2-b63f-8dcaa38125c3/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4d5622d6-7fdd-4a83-aa39-a42b45947712',
        firstName: 'Anastasia',
        lastName: 'Shannon',
        email: 'twinklestarazia@gmail.com',
        instagramProfile: {
          handle: 'a_centric_gss_solutions',
          followers: 28,
          engagementRate: 3.571428571428571
        },
        tiktokProfile: {
          handle: 'asiainfluence',
          followers: 2578,
          engagementRate: 2.8926646784910792
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T18:58:17.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '27047a3c-c7fc-4510-9249-33dda6d6debc',
      content: [
        {
          groupId: '440f4967-3a5a-42c1-a459-122955005893',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-09T12:36:41.834Z',

          createdAt: '2024-06-06T19:59:54.186Z',
          content: [
            {
              caption: '',

              id: '93d2aa80-a158-46dd-9e95-e572d4d73669',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/27047a3c-c7fc-4510-9249-33dda6d6debc/ugc/440f4967-3a5a-42c1-a459-122955005893/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2f0c8aea-788a-4c3f-a437-677d19d420d5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-09T12:36:48.881Z',

          createdAt: '2024-06-06T00:39:02.547Z',
          content: [
            {
              caption: '',

              id: '64ab1bec-6df0-4e8f-a457-1624ec28dcbb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/27047a3c-c7fc-4510-9249-33dda6d6debc/ugc/2f0c8aea-788a-4c3f-a437-677d19d420d5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ef88b1c7-1081-4290-8a59-52247de20043',
        firstName: 'Brianne',
        lastName: 'Johnson',
        email: 'justakeyatheart@gmail.com',
        instagramProfile: {
          handle: 'justakeyatheart',
          followers: 4384,
          engagementRate: 0.5063868613138686
        },
        tiktokProfile: {
          handle: 'justakeyatheart',
          followers: 1207,
          engagementRate: 2.1069761633443123
        },
        youtubeProfile: {
          handle: 'justakeyatheart7031'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:05:31.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1a98d311-e8e7-4d4e-8ba5-a4e606d47645',
      content: [],
      creator: {
        id: '450892a2-2cfa-4b3a-ba2f-e623fc67f6a9',
        firstName: 'Shannon',
        lastName: 'Busque',
        email: 'shannon@unstuckmommymindset.com',
        instagramProfile: {
          handle: 'momtipsfordaytrips',
          followers: 1355,
          engagementRate: 0.5018450184501845
        },
        tiktokProfile: {
          handle: 'momtipsfordaytrips',
          followers: 4749,
          engagementRate: 8.844509521423202
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:06:02.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '599b82b9-c0cb-4493-9da8-d3230f4c713e',
      content: [
        {
          groupId: 'a0be94a0-3de8-407e-a0de-042d89780995',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:15:13.242Z',

          createdAt: '2024-06-01T21:43:14.335Z',
          content: [
            {
              caption: '',

              id: 'c49ab9b4-c9f5-40e5-b6b4-1a68048f23ca',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/599b82b9-c0cb-4493-9da8-d3230f4c713e/ugc/a0be94a0-3de8-407e-a0de-042d89780995/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ed1b8474-73aa-471d-a629-c1f8d2e617f9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:15:19.004Z',

          createdAt: '2024-06-01T21:43:34.449Z',
          content: [
            {
              caption: '',

              id: '342af5e1-f701-4605-849b-883d663c32ac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/599b82b9-c0cb-4493-9da8-d3230f4c713e/ugc/ed1b8474-73aa-471d-a629-c1f8d2e617f9/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '86f2e8ed-592e-4c18-bff4-8d91d8dfae8c',
        firstName: 'Vio',
        lastName: 'Sych',
        email: 'villison990@gmail.com',
        instagramProfile: {
          handle: 'sychevskaia.marina',
          followers: 1618,
          engagementRate: 4.243922538112897
        },
        tiktokProfile: {
          handle: 'redpantera',
          followers: 1839,
          engagementRate: 10.72961373390558
        },
        youtubeProfile: {
          handle: 'vioson-kz6sd',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:12:10.000Z',
        amountCents: 8805
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e648a980-79ae-4d63-b235-330513178694',
      content: [],
      creator: {
        id: 'cbf9c42a-7e6d-42f3-b498-2803df5d1a9d',
        firstName: 'Adriana',
        lastName: 'Cueva',
        email: 'realtor.adrianac@gmail.com',
        instagramProfile: {
          handle: 'adri.ana_cueva',
          followers: 4677,
          engagementRate: 0.003945957538065624
        },
        tiktokProfile: {
          handle: 'mrscueva',
          followers: 31
        },
        youtubeProfile: {
          handle: 'adrianacueva',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:13:06.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '020b30e7-17dc-44e6-8f1c-644dfb01976c',
      content: [],
      creator: {
        id: 'e18334fe-5a91-4ba9-bc90-cf334a25d008',
        firstName: 'Shelby',
        lastName: 'Pick',
        email: 'shelby.pick867@gmail.com',

        tiktokProfile: {
          handle: 'shelbell',
          followers: 50140
        },
        youtubeProfile: {
          handle: 'shelbysanders841',
          followers: 45
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:14:51.000Z',
        amountCents: 8406
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f98e0577-4f06-4ad3-bb19-068159e4c31d',
      content: [
        {
          groupId: '075cec37-0a76-4564-b913-c67870b92ff4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:22:10.078Z',

          createdAt: '2024-06-12T03:26:15.244Z',
          content: [
            {
              caption: '',

              id: 'ee4b747c-e14e-4aa6-a10a-618fc2bb3fd5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f98e0577-4f06-4ad3-bb19-068159e4c31d/ugc/075cec37-0a76-4564-b913-c67870b92ff4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '62e9f453-38a1-4d5d-a1f1-22836efc248d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:22:14.542Z',

          createdAt: '2024-06-12T03:27:12.646Z',
          content: [
            {
              caption: '',

              id: '90f677d9-2397-4c18-92bb-efbd81fef1cd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f98e0577-4f06-4ad3-bb19-068159e4c31d/ugc/62e9f453-38a1-4d5d-a1f1-22836efc248d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ae0c6995-e8b9-42dd-b7bc-dc7d1f3b80ff',
        firstName: 'Ceyda',
        lastName: 'Baradehi',
        email: 'ceydadurmus96@gmail.com',
        instagramProfile: {
          handle: 'ceydabaradehi',
          followers: 30249,
          engagementRate: 2.858606896095739
        },
        tiktokProfile: {
          handle: 'ceydabaradehi',
          followers: 53,
          engagementRate: 4.201680672268908
        },
        youtubeProfile: {
          handle: 'ceydabaradehi',
          followers: 79
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:14:52.000Z',
        amountCents: 7646
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '189d6ab3-6f44-4147-b3f7-4f88ae343e68',
      content: [
        {
          groupId: 'a841718d-f1f8-45dd-9993-edb35d361407',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-28T23:04:11.766Z',

          createdAt: '2024-05-26T16:22:33.577Z',
          content: [
            {
              caption: '',

              id: 'a5b03fdb-85a0-40bc-b741-a7a8d60a27bc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/189d6ab3-6f44-4147-b3f7-4f88ae343e68/ugc/a841718d-f1f8-45dd-9993-edb35d361407/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '6513a6a8-7e90-4510-8feb-36bd4c06473e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:14:46.517Z',

          createdAt: '2024-05-28T17:06:05.411Z',
          content: [
            {
              caption: '',

              id: '15028b0b-ad9b-4243-9f01-8e5aca9bc203',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/189d6ab3-6f44-4147-b3f7-4f88ae343e68/ugc/6513a6a8-7e90-4510-8feb-36bd4c06473e/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f9210b56-2df4-4026-992c-7c32930eb50b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/189d6ab3-6f44-4147-b3f7-4f88ae343e68/ugc/6513a6a8-7e90-4510-8feb-36bd4c06473e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'fc3b19a7-86e3-46a2-898c-4f604c749847',
        firstName: 'Natasha',
        lastName: 'Barker',
        email: 'natashabarker111@gmail.com',
        instagramProfile: {
          handle: 'kushkaine',
          followers: 2715,
          engagementRate: 3.192111029948868
        },
        tiktokProfile: {
          handle: 'natashabarker',
          followers: 1083,
          engagementRate: 2.287581699346405
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:15:04.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7a929f5b-b2d2-4e61-bcbd-9ca2f00a1859',
      content: [
        {
          groupId: '7fc187ad-c34d-4966-9e97-4c69cb40d442',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T17:28:45.508Z',

          createdAt: '2024-05-30T22:40:19.595Z',
          content: [
            {
              caption: '',

              id: '45d4315c-64ff-4069-8e54-189eed492f0e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7a929f5b-b2d2-4e61-bcbd-9ca2f00a1859/ugc/7fc187ad-c34d-4966-9e97-4c69cb40d442/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f6036463-ddf0-4898-b84a-ab157ac60d1b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T11:10:33.314Z',

          createdAt: '2024-05-30T21:55:56.487Z',
          content: [
            {
              caption: '',

              id: 'b603fd30-027e-4c64-8dcb-fb80291119f4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7a929f5b-b2d2-4e61-bcbd-9ca2f00a1859/ugc/f6036463-ddf0-4898-b84a-ab157ac60d1b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'dad94ede-3c35-483e-b8a1-f23970c53c3b',
        firstName: 'Hope',
        lastName: 'Steptoe',
        email: 'hopesteptoe@yahoo.com',
        instagramProfile: {
          handle: 'hope.steptoe',
          followers: 1398,
          engagementRate: 6.175488793514544
        },
        tiktokProfile: {
          handle: 'hopesteptoe',
          followers: 1009
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:25:03.000Z',
        amountCents: 8237
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1abf53d9-6a9c-4283-86ec-6e500a0c0a2d',
      content: [
        {
          groupId: '250d2971-dfce-4e4e-b116-09f875ed169d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T17:02:57.954Z',

          createdAt: '2024-06-11T02:39:55.856Z',
          content: [
            {
              caption: '',

              id: '3406a8b9-7c17-49be-8a00-ddd5f42e55b9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1abf53d9-6a9c-4283-86ec-6e500a0c0a2d/ugc/250d2971-dfce-4e4e-b116-09f875ed169d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '072bd3aa-cda1-46ff-9ea6-7b1e93435138',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1abf53d9-6a9c-4283-86ec-6e500a0c0a2d/ugc/250d2971-dfce-4e4e-b116-09f875ed169d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3a9d31bf-7f52-4575-8740-685af991e50c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1abf53d9-6a9c-4283-86ec-6e500a0c0a2d/ugc/250d2971-dfce-4e4e-b116-09f875ed169d/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '191a65cc-2461-45d1-b0e1-1b979dd6e64d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T17:02:50.817Z',

          createdAt: '2024-06-11T02:38:26.357Z',
          content: [
            {
              caption: '',

              id: '2e167000-1ae3-4203-b0bd-b57b8e97c6ca',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1abf53d9-6a9c-4283-86ec-6e500a0c0a2d/ugc/191a65cc-2461-45d1-b0e1-1b979dd6e64d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bb174fd6-cf2d-432e-961d-dbef5ef40303',
        firstName: 'Nzinga',
        lastName: 'Jackson',
        email: 'zingaj14@gmail.com',

        tiktokProfile: {
          handle: 'brittxbritt1',
          followers: 9988,
          engagementRate: 1.0480101968559694
        },
        youtubeProfile: {
          handle: 'nzingajackson3245',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:34:50.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd076b459-44dc-4e78-bad9-137127546d3a',
      content: [
        {
          groupId: '3b10655e-3685-48f0-8443-79a379c632e9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T00:49:11.763Z',

          createdAt: '2024-06-01T00:45:02.454Z',
          content: [
            {
              caption: '',

              id: '6f81400c-5470-470e-89cc-80468f2cc06a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d076b459-44dc-4e78-bad9-137127546d3a/ugc/3b10655e-3685-48f0-8443-79a379c632e9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ed4f1c3f-f250-4943-b9bb-93ec3088d6aa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T18:49:39.618Z',

          createdAt: '2024-06-01T00:33:19.443Z',
          content: [
            {
              caption: '',

              id: 'b948b6cc-9d74-417f-9987-45667b583f94',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d076b459-44dc-4e78-bad9-137127546d3a/ugc/ed4f1c3f-f250-4943-b9bb-93ec3088d6aa/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '05525876-5016-4b7c-9bee-5f02013e3e99',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d076b459-44dc-4e78-bad9-137127546d3a/ugc/ed4f1c3f-f250-4943-b9bb-93ec3088d6aa/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e8415d0b-794b-4558-91d2-81c519c97eda',
        firstName: 'Rosario ',
        lastName: 'Maciel de Quintero ',
        email: 'rosariomvq@gmail.com',
        instagramProfile: {
          handle: 'rosariomacieloficial',
          followers: 10146,
          engagementRate: 0.10053222945002956
        },
        tiktokProfile: {
          handle: 'manatips83',
          followers: 15765,
          engagementRate: 1.4817415414252986
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T19:36:01.000Z',
        amountCents: 10824
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '89e0c40c-76c1-4b8d-9a53-ee5b9e3053fe',
      content: [
        {
          groupId: '25f7d17f-5a17-408a-a269-5b4b55f9f510',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T12:45:16.656Z',

          createdAt: '2024-05-31T04:55:14.705Z',
          content: [
            {
              caption: '',

              id: 'c1575471-23a2-4cbc-a9e6-459725c9e2ba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/89e0c40c-76c1-4b8d-9a53-ee5b9e3053fe/ugc/25f7d17f-5a17-408a-a269-5b4b55f9f510/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8ce9d2e8-fd22-426c-b2d9-9c01af96c3af',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/89e0c40c-76c1-4b8d-9a53-ee5b9e3053fe/ugc/25f7d17f-5a17-408a-a269-5b4b55f9f510/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3d4b27f0-cc0d-4deb-ac1c-76d9ef879e3e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/89e0c40c-76c1-4b8d-9a53-ee5b9e3053fe/ugc/25f7d17f-5a17-408a-a269-5b4b55f9f510/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a3e373d4-f9ef-47ed-a677-4cee44ddf769',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:54:36.866Z',

          createdAt: '2024-05-31T05:55:45.434Z',
          content: [
            {
              caption: '',

              id: 'b9aa8875-5499-4789-b873-5b7ef46aa59b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/89e0c40c-76c1-4b8d-9a53-ee5b9e3053fe/ugc/a3e373d4-f9ef-47ed-a677-4cee44ddf769/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f0463cc7-3c3b-4994-9cbf-d1105be03fa2',
        firstName: 'Marissa',
        lastName: 'Crawley',
        email: 'marissacrawley@outlook.com',
        instagramProfile: {
          handle: 'marissalately',
          followers: 405,
          engagementRate: 3.382716049382716
        },
        tiktokProfile: {
          handle: 'marissalately',
          followers: 4818,
          engagementRate: 17.124435668851696
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T20:14:56.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '632a6337-7d0d-4a2a-a079-d591ad719355',
      content: [
        {
          groupId: 'c214edbb-00a9-41db-8cf8-961b5825465f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-24T03:50:40.106Z',

          createdAt: '2024-07-18T03:13:55.845Z',
          content: [
            {
              caption: '',

              id: '3873d651-2d69-4bcd-a2e7-cdac0d247083',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/632a6337-7d0d-4a2a-a079-d591ad719355/ugc/c214edbb-00a9-41db-8cf8-961b5825465f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd48b2528-402a-463a-8d30-7e2aa79165fd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/632a6337-7d0d-4a2a-a079-d591ad719355/ugc/c214edbb-00a9-41db-8cf8-961b5825465f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '502c6fdb-00b2-45eb-b977-e70a4430fe39',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/632a6337-7d0d-4a2a-a079-d591ad719355/ugc/c214edbb-00a9-41db-8cf8-961b5825465f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '60d69da0-b1fd-44d5-997d-531ae9a1de61',
        firstName: 'Taylor',
        lastName: 'Anderson',
        email: 'taylorcheyenne.anderson@gmail.com',
        instagramProfile: {
          handle: 'tay_cheyenne_',
          followers: 3435,
          engagementRate: 2.911208151382824
        },
        tiktokProfile: {
          handle: 'taylorcanderson',
          followers: 135,
          engagementRate: 5.063291139240507
        },
        youtubeProfile: {
          handle: 'tay_cheyenne_',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T20:46:17.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ecc5a4df-55b5-435c-99bf-70eb736a6704',
      content: [
        {
          groupId: '78962986-6025-4892-9c4f-6f60b1360657',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:19:45.749Z',

          createdAt: '2024-05-29T04:56:51.006Z',
          content: [
            {
              caption: '',

              id: '03a48333-79b2-47be-b840-3f53382279ad',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ecc5a4df-55b5-435c-99bf-70eb736a6704/ugc/78962986-6025-4892-9c4f-6f60b1360657/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0921541d-de2d-4dad-9fe7-a59e22f3de26',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ecc5a4df-55b5-435c-99bf-70eb736a6704/ugc/78962986-6025-4892-9c4f-6f60b1360657/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e8885dcd-ea02-4afe-b64e-484d3adfb966',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ecc5a4df-55b5-435c-99bf-70eb736a6704/ugc/78962986-6025-4892-9c4f-6f60b1360657/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7ecc7a47-0c22-4395-a69f-e01b4d52a2cc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:28:43.438Z',

          createdAt: '2024-05-29T04:55:02.965Z',
          content: [
            {
              caption: '',

              id: '79b7e7b3-0076-4a75-8269-f926b9a517d0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ecc5a4df-55b5-435c-99bf-70eb736a6704/ugc/7ecc7a47-0c22-4395-a69f-e01b4d52a2cc/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '364c503d-5773-4385-afbb-ef0a1f476ad3',
        firstName: 'Aneshia',
        lastName: 'Sankar',
        email: 'aneshiasankar@yahoo.com',
        instagramProfile: {
          handle: 'boss_neesh_world',
          followers: 5
        },
        tiktokProfile: {
          handle: 'josiahmom01',
          followers: 1499,
          engagementRate: 6.5823461298655035
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T20:53:14.000Z',
        amountCents: 7577
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '20cdb6ce-3fb2-44ad-bc0a-3a75ff5fc1e5',
      content: [
        {
          groupId: '8fdae392-4ad5-4433-a26e-26d06510e3ae',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T15:56:13.707Z',

          createdAt: '2024-05-29T20:52:07.397Z',
          content: [
            {
              caption: '',

              id: 'aeba5107-5b67-4735-b695-b5b9096973cc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/20cdb6ce-3fb2-44ad-bc0a-3a75ff5fc1e5/ugc/8fdae392-4ad5-4433-a26e-26d06510e3ae/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9bb59a86-69b2-4a24-af26-0410f4e1b717',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T15:55:47.774Z',

          createdAt: '2024-05-29T20:38:16.541Z',
          content: [
            {
              caption: '',

              id: 'f511bff4-99c8-41f7-9fa9-c8d330cd591e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/20cdb6ce-3fb2-44ad-bc0a-3a75ff5fc1e5/ugc/9bb59a86-69b2-4a24-af26-0410f4e1b717/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '64d62c13-068b-4c75-b09d-da44eb7e46d6',
        firstName: 'Kelsie',
        lastName: 'Moyer',
        email: 'moyerkelsie@gmail.com',
        instagramProfile: {
          handle: 'kelsmoyer07',
          followers: 160,
          engagementRate: 0.6875000000000001
        },
        tiktokProfile: {
          handle: 'kels073120',
          followers: 2981,
          engagementRate: 0.4294671817862529
        },
        youtubeProfile: {
          handle: 'kelsiemoyer',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T21:05:27.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0a33a08d-37f2-4a4a-88f9-b3837ea5cfb4',
      content: [
        {
          groupId: '4e5801f5-acf8-452a-a6de-8e36c78ed44d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-01T16:53:37.113Z',

          createdAt: '2024-07-30T08:32:45.873Z',
          content: [
            {
              caption: '',

              id: 'df620dfe-3b04-4aa9-9fe6-676ab7d63b12',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0a33a08d-37f2-4a4a-88f9-b3837ea5cfb4/ugc/4e5801f5-acf8-452a-a6de-8e36c78ed44d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7f090bc4-e06e-457f-9caf-8669ac7d4aee',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-01T16:53:34.171Z',

          createdAt: '2024-07-30T08:21:19.075Z',
          content: [
            {
              caption: '',

              id: 'b84669f3-890d-4aa1-b094-d5ef3f706946',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0a33a08d-37f2-4a4a-88f9-b3837ea5cfb4/ugc/7f090bc4-e06e-457f-9caf-8669ac7d4aee/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9beb776a-5ea1-4221-8c29-e92ecbbfe0ed',
        firstName: 'Kimberly',
        lastName: 'Sanchez',
        email: 'kimysanchez30@gmail.com',
        instagramProfile: {
          handle: 'kimysales_',
          followers: 23915,
          engagementRate: 2.2170186075684715
        },
        tiktokProfile: {
          handle: 'kimysales_',
          followers: 1339,
          engagementRate: 2.0884824518042513
        },
        youtubeProfile: {
          handle: 'kimysales_'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T21:45:47.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a9a91e8c-6549-442a-adaa-a9ca316b5022',
      content: [
        {
          groupId: 'e19e6e1f-a582-4de4-a568-ea87ea68a321',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T18:56:03.015Z',

          createdAt: '2024-06-04T15:43:06.705Z',
          content: [
            {
              caption: '',

              id: 'd33049e8-5acc-47be-9bad-238170940b4f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a9a91e8c-6549-442a-adaa-a9ca316b5022/ugc/e19e6e1f-a582-4de4-a568-ea87ea68a321/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c7544927-af8f-453e-8810-1491a6302343',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a9a91e8c-6549-442a-adaa-a9ca316b5022/ugc/e19e6e1f-a582-4de4-a568-ea87ea68a321/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bc2333be-2e46-41d2-839c-6bc294dbcb04',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a9a91e8c-6549-442a-adaa-a9ca316b5022/ugc/e19e6e1f-a582-4de4-a568-ea87ea68a321/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '78c443cc-6388-4db8-910c-a8b11724ea98',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T01:13:49.750Z',

          createdAt: '2024-06-04T15:55:08.677Z',
          content: [
            {
              caption: '',

              id: '20c607f6-ac1d-4f6a-a373-441a74ca5d1b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a9a91e8c-6549-442a-adaa-a9ca316b5022/ugc/78c443cc-6388-4db8-910c-a8b11724ea98/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6773b645-9001-4ea3-b36e-f0c822f8f2fa',
        firstName: 'Maya',
        lastName: 'Kramer',
        email: 'mayafrommn@gmail.com',
        instagramProfile: {
          handle: 'mayakramer2217',
          followers: 1947,
          engagementRate: 4.345654345654346
        },
        tiktokProfile: {
          handle: 'mayakramer2217',
          followers: 11819,
          engagementRate: 21.885521885521886
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T22:05:24.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8828f620-05b9-4c28-8ae6-7e6eeadd5298',
      content: [
        {
          groupId: '594ddd3f-1c76-4001-bae9-825766576242',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T13:58:41.148Z',

          createdAt: '2024-06-06T14:29:57.280Z',
          content: [
            {
              caption: '',

              id: '9a489903-8297-4b95-abac-fdf38e10d5f0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8828f620-05b9-4c28-8ae6-7e6eeadd5298/ugc/594ddd3f-1c76-4001-bae9-825766576242/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b8ec035f-8c3f-4e1a-9aef-f306a0588a5e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8828f620-05b9-4c28-8ae6-7e6eeadd5298/ugc/594ddd3f-1c76-4001-bae9-825766576242/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ec94d431-68ae-4e89-a1b9-b0704d7e2661',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8828f620-05b9-4c28-8ae6-7e6eeadd5298/ugc/594ddd3f-1c76-4001-bae9-825766576242/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '117a2eac-3dd6-40ff-a9a5-1de445dded8a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T13:58:30.873Z',

          createdAt: '2024-06-06T14:29:37.640Z',
          content: [
            {
              caption: '',

              id: '4574ddd8-5d34-43b6-b6b3-710b3a016b68',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8828f620-05b9-4c28-8ae6-7e6eeadd5298/ugc/117a2eac-3dd6-40ff-a9a5-1de445dded8a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ab3e2c9a-de5e-4814-9542-3e1b456c5540',
        firstName: 'Sam',
        lastName: 'Billings',
        email: 'sambillings5223@yahoo.com',
        instagramProfile: {
          handle: 'sambillings52',
          followers: 1107
        },
        tiktokProfile: {
          handle: 'sambillings52',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-26T22:55:59.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e1b87ab9-90c2-46ff-b793-0ef58ed7bebe',
      content: [
        {
          groupId: '6f841ae2-2cb2-43f4-a102-966c49ffad37',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T12:17:07.122Z',

          createdAt: '2024-06-05T01:15:49.496Z',
          content: [
            {
              caption: '',

              id: 'b8429b74-ec6e-421f-bf97-eec8b7efa4e0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1b87ab9-90c2-46ff-b793-0ef58ed7bebe/ugc/6f841ae2-2cb2-43f4-a102-966c49ffad37/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f378d394-2663-420e-958c-febda5dd7bcc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1b87ab9-90c2-46ff-b793-0ef58ed7bebe/ugc/6f841ae2-2cb2-43f4-a102-966c49ffad37/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7db6302f-b7e4-49ce-970d-bfcfe7284511',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1b87ab9-90c2-46ff-b793-0ef58ed7bebe/ugc/6f841ae2-2cb2-43f4-a102-966c49ffad37/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '476d4560-3fd9-4561-9810-9bca43018444',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T12:17:01.546Z',

          createdAt: '2024-06-05T01:11:20.917Z',
          content: [
            {
              caption: '',

              id: '656e73a0-816c-4186-95d3-95eb180d4ff3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e1b87ab9-90c2-46ff-b793-0ef58ed7bebe/ugc/476d4560-3fd9-4561-9810-9bca43018444/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '389e4c01-a22c-4870-851d-3da093c2a442',
        firstName: 'Kimberly ',
        lastName: 'Rose',
        email: 'officialkimberlyrose@gmail.com',
        instagramProfile: {
          handle: 'kimberlyf.rose',
          followers: 459,
          engagementRate: 1.067538126361656
        },
        tiktokProfile: {
          handle: 'kimberlyfrose_',
          followers: 11268,
          engagementRate: 0.7995685454875369
        },
        youtubeProfile: {
          handle: 'kimberlyrosemusic',
          followers: 957
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T00:02:59.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9abce313-f4d7-43f8-9d97-f69124265dfe',
      content: [],
      creator: {
        id: '55784552-8abc-4a52-ba6e-c6fac01754f5',
        firstName: 'Jenna',
        lastName: 'Evans',
        email: 'agirllikebelle@gmail.com',
        instagramProfile: {
          handle: 'darlinsmodestwear',
          followers: 2685,
          engagementRate: 0.4692737430167597
        },
        tiktokProfile: {
          handle: 'agirllikebelle',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T00:22:59.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b6cdf3f7-c93b-4ef4-bdb9-cb193334ed12',
      content: [],
      creator: {
        id: '35d8f8f8-2069-4599-bb14-b472202a9b99',
        firstName: 'Jennifer',
        lastName: 'Crossman',
        email: 'jenlovecrossman@gmail.com',
        instagramProfile: {
          handle: 'jenlovecross',
          followers: 1982,
          engagementRate: 3.1786074672048437
        },
        tiktokProfile: {
          handle: 'jenlovecross',
          followers: 3379,
          engagementRate: 22.352921350208128
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T00:45:41.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2edb3366-161a-498c-beeb-1b5bbf55765f',
      content: [
        {
          groupId: '3b3b5e69-30cd-4eff-bcbc-0e93dd552644',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T18:29:49.299Z',

          createdAt: '2024-05-30T19:22:43.652Z',
          content: [
            {
              caption: '',

              id: '0c441c26-7416-4181-8f1e-2375de7b6089',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2edb3366-161a-498c-beeb-1b5bbf55765f/ugc/3b3b5e69-30cd-4eff-bcbc-0e93dd552644/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b260c5f7-eae2-427c-912c-c48fdc59ab41',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T18:29:53.826Z',

          createdAt: '2024-05-30T19:20:18.360Z',
          content: [
            {
              caption: '',

              id: 'c51ee186-3384-4ef4-8a7f-4dd7d1b2720e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2edb3366-161a-498c-beeb-1b5bbf55765f/ugc/b260c5f7-eae2-427c-912c-c48fdc59ab41/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'df6dca80-021d-4149-be04-b7c017e191ab',
        firstName: 'Devon',
        lastName: 'Burden',
        email: 'devonbburden@gmail.com',
        instagramProfile: {
          handle: 'devonsdoodlediary',
          followers: 13423,
          engagementRate: 0.26521641957833575
        },
        tiktokProfile: {
          handle: 'devonburden',
          followers: 1323,
          engagementRate: 19.090719933416562
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T01:16:49.000Z',
        amountCents: 8704
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2db78415-087d-4614-a8e4-e34a67d04c8e',
      content: [
        {
          groupId: 'fddbc2fd-2c29-401e-a770-c7d3e6d43d0f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T22:10:31.276Z',

          createdAt: '2024-06-07T21:34:40.701Z',
          content: [
            {
              caption: '',

              id: 'b0f3f53b-ff25-4ce0-aaf9-c89c5c9fe66e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2db78415-087d-4614-a8e4-e34a67d04c8e/ugc/fddbc2fd-2c29-401e-a770-c7d3e6d43d0f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd2a5971e-5c85-49dd-9e2d-02d51238f75f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2db78415-087d-4614-a8e4-e34a67d04c8e/ugc/fddbc2fd-2c29-401e-a770-c7d3e6d43d0f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '091fb506-2ea1-4900-9458-1cd4759d191c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2db78415-087d-4614-a8e4-e34a67d04c8e/ugc/fddbc2fd-2c29-401e-a770-c7d3e6d43d0f/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ba2608cc-f16a-4bde-96f4-18edb368a32c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T20:03:56.219Z',

          createdAt: '2024-06-07T21:44:37.123Z',
          content: [
            {
              caption: '',

              id: 'b1ab1452-f48f-4397-a84e-02b715f340a0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2db78415-087d-4614-a8e4-e34a67d04c8e/ugc/ba2608cc-f16a-4bde-96f4-18edb368a32c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '55a84b53-5f24-4702-87a1-0dc5b77c35b3',
        firstName: 'Monique',
        lastName: 'Cole',
        email: 'moniquerochellcole@gmail.com',

        tiktokProfile: {
          handle: 'moblessedd',
          followers: 1381
        },
        youtubeProfile: {
          handle: 'moniquecole9243',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T01:43:05.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '14cf92cf-aca1-44cd-b7b4-5f538cfefd44',
      content: [
        {
          groupId: '7696ed1c-e9ba-41ac-9f6f-1c40c9a54012',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-04T01:08:01.015Z',

          createdAt: '2024-06-01T20:02:31.477Z',
          content: [
            {
              caption: '',

              id: 'f5c08c08-9227-4cba-acfa-36a50c704a70',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/14cf92cf-aca1-44cd-b7b4-5f538cfefd44/ugc/7696ed1c-e9ba-41ac-9f6f-1c40c9a54012/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4d403e1c-e053-431b-9fc8-0521e64ada7d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/14cf92cf-aca1-44cd-b7b4-5f538cfefd44/ugc/7696ed1c-e9ba-41ac-9f6f-1c40c9a54012/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7139620e-de55-46ea-b4e2-3e062544d023',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T01:07:54.853Z',

          createdAt: '2024-06-02T00:09:35.147Z',
          content: [
            {
              caption: '',

              id: '597c1a20-32e7-4240-a61a-13743d3cf77c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/14cf92cf-aca1-44cd-b7b4-5f538cfefd44/ugc/7139620e-de55-46ea-b4e2-3e062544d023/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '990cb803-d403-4dfe-957e-2bebf833bc06',
        firstName: 'Rebecca',
        lastName: 'Ruiz',
        email: 'magicalclubruiz@gmail.com',
        instagramProfile: {
          handle: 'magicalclubruiz',
          followers: 2810,
          engagementRate: 1.2206405693950177
        },
        tiktokProfile: {
          handle: 'magical_club_ruiz',
          followers: 3425,
          engagementRate: 4.480324237711485
        },
        youtubeProfile: {
          handle: 'magicalclubruiz',
          followers: 25
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T01:56:41.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c991f0f6-de46-4fd2-9e17-ee58cd73b914',
      content: [
        {
          groupId: 'aeea6f34-e34c-4a39-823c-fa9cb35bf93f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-14T07:17:46.927Z',

          createdAt: '2024-06-11T01:53:04.439Z',
          content: [
            {
              caption: '',

              id: 'e8a29783-5037-49ff-8644-8dac6b1e434e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c991f0f6-de46-4fd2-9e17-ee58cd73b914/ugc/aeea6f34-e34c-4a39-823c-fa9cb35bf93f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f22db9be-b206-40c3-80a6-6b6abf37aecf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c991f0f6-de46-4fd2-9e17-ee58cd73b914/ugc/aeea6f34-e34c-4a39-823c-fa9cb35bf93f/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2e1d73b5-6008-446a-8b3c-d536bf56cbfd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T16:11:28.555Z',

          createdAt: '2024-06-11T04:04:02.698Z',
          content: [
            {
              caption: '',

              id: '8cb57758-aed1-4df0-bb15-37138e9aa04f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c991f0f6-de46-4fd2-9e17-ee58cd73b914/ugc/2e1d73b5-6008-446a-8b3c-d536bf56cbfd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9b8c3478-41cd-4d04-885f-cd76c623dfcc',
        firstName: 'Rania',
        lastName: 'Hoteit',
        email: 'hoteit.rania@gmail.com',
        instagramProfile: {
          handle: 'raniahoteit_officialpublicpage',
          followers: 2653,
          engagementRate: 1.0554089709762533
        },
        tiktokProfile: {
          handle: 'raniahoteit_official',
          followers: 18,
          engagementRate: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T02:12:54.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b81a323e-83d3-4bcc-9a6c-e91b7636cbf1',
      content: [
        {
          groupId: 'a6f72c50-cca5-42f5-bcb3-198dd35228f7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T19:01:06.694Z',

          createdAt: '2024-06-04T17:29:32.375Z',
          content: [
            {
              caption: '',

              id: '3f037219-8aae-4072-9d83-0f3d842862ed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b81a323e-83d3-4bcc-9a6c-e91b7636cbf1/ugc/a6f72c50-cca5-42f5-bcb3-198dd35228f7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '03aaf93d-20b0-4b8c-bf30-183c634adaee',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T01:48:52.397Z',

          createdAt: '2024-06-04T17:43:41.615Z',
          content: [
            {
              caption: '',

              id: '79e4d8bb-312b-452b-8f00-02a5e24a8c1e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b81a323e-83d3-4bcc-9a6c-e91b7636cbf1/ugc/03aaf93d-20b0-4b8c-bf30-183c634adaee/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3fb943b8-e4a6-4d7d-aa22-bc876fdf4c86',
        firstName: 'Aubrey',
        lastName: 'Fox',
        email: 'aubreylfox34@gmail.com',
        instagramProfile: {
          handle: 'aubrey.fox.33',
          followers: 1110,
          engagementRate: 1.1981981981981982
        },
        tiktokProfile: {
          handle: 'aubreylaine333',
          followers: 1588,
          engagementRate: 3.2448377581120944
        },
        youtubeProfile: {
          handle: 'aubreyfox88'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T02:43:25.000Z',
        amountCents: 8333
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a6f72d6c-c629-4cdf-b622-ac768385b56f',
      content: [
        {
          groupId: '64c4834a-d485-48a8-8d8b-62401aa4fbb9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T16:59:29.756Z',

          createdAt: '2024-06-01T12:40:14.866Z',
          content: [
            {
              caption: '',

              id: 'b8c289ec-23c6-4249-aa6a-77efe8773e6c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a6f72d6c-c629-4cdf-b622-ac768385b56f/ugc/64c4834a-d485-48a8-8d8b-62401aa4fbb9/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0b6e0d70-9792-4761-87c9-d8fc9abcc759',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a6f72d6c-c629-4cdf-b622-ac768385b56f/ugc/64c4834a-d485-48a8-8d8b-62401aa4fbb9/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ba8cb9d1-579b-4d21-baa5-d3bb6e3d93b4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a6f72d6c-c629-4cdf-b622-ac768385b56f/ugc/64c4834a-d485-48a8-8d8b-62401aa4fbb9/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd11cc487-76fc-4835-9e49-d0a2cb3420f0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T19:48:58.850Z',

          createdAt: '2024-06-01T12:39:45.373Z',
          content: [
            {
              caption: '',

              id: '8bfa20e6-084b-4e9f-b087-b237edcfd2e7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a6f72d6c-c629-4cdf-b622-ac768385b56f/ugc/d11cc487-76fc-4835-9e49-d0a2cb3420f0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5ebe1427-2297-4252-a313-c9cd9a54ffa0',
        firstName: 'Taylor ',
        lastName: 'Haynes',
        email: 'taylorbuxton1410@gmail.com',

        tiktokProfile: {
          handle: 'taylorhaynes1120',
          followers: 88832,
          engagementRate: 13.455551517099117
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T02:43:43.000Z',
        amountCents: 7730
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '597255e8-11fe-455e-ab36-d28c2aa076c1',
      content: [],
      creator: {
        id: '3d86004d-f5cd-492b-b3f8-a2a962a6b7c4',
        firstName: 'Tina',
        lastName: 'Smith',
        email: 'bbtran.2806@gmail.com',
        instagramProfile: {
          handle: 'channie.22',
          followers: 1250
        },
        tiktokProfile: {
          handle: 'moonlightbabi22',
          followers: 34
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T02:53:22.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '43e29700-2cb8-4509-8d55-0bcc705ea5cd',
      content: [
        {
          groupId: '2a7c53ab-9b5a-47ca-9583-b9b1e36be0b6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T17:30:56.984Z',

          createdAt: '2024-06-01T14:00:35.714Z',
          content: [
            {
              caption: '',

              id: '6e02224a-455a-4d85-a97c-803e290abb0d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/43e29700-2cb8-4509-8d55-0bcc705ea5cd/ugc/2a7c53ab-9b5a-47ca-9583-b9b1e36be0b6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c2c10920-2842-4980-9206-9f5ec82466ce',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T17:30:51.048Z',

          createdAt: '2024-06-12T02:15:27.474Z',
          content: [
            {
              caption: '',

              id: '0df17732-06dc-4de7-88bf-1e2daa9a51e7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/43e29700-2cb8-4509-8d55-0bcc705ea5cd/ugc/c2c10920-2842-4980-9206-9f5ec82466ce/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e5a7f697-fa4f-474b-b259-bb4ea5d6cfad',
        firstName: 'Leyra',
        lastName: 'De los santos',
        email: 'bellabeauty1708@gmail.com',
        instagramProfile: {
          handle: 'allthingsleys',
          followers: 1734,
          engagementRate: 1.7416378316032293
        },
        tiktokProfile: {
          handle: 'allthingsleys',
          followers: 5567,
          engagementRate: 12.307912273721902
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T03:12:36.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a9d78875-90ea-4cf5-9cf6-1bfa1cf4624d',
      content: [
        {
          groupId: '2d4901ee-e1a0-4e91-8d70-a7e0879dd119',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T14:02:21.949Z',

          createdAt: '2024-05-27T15:47:27.213Z',
          content: [
            {
              caption: '',

              id: '06bad229-a8a8-4b00-83ff-332522112688',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a9d78875-90ea-4cf5-9cf6-1bfa1cf4624d/ugc/2d4901ee-e1a0-4e91-8d70-a7e0879dd119/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a8f7a508-02b9-437b-8061-e5f985bf33af',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a9d78875-90ea-4cf5-9cf6-1bfa1cf4624d/ugc/2d4901ee-e1a0-4e91-8d70-a7e0879dd119/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ddf041bf-00a3-4a0c-a549-66f4dece0c2c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a9d78875-90ea-4cf5-9cf6-1bfa1cf4624d/ugc/2d4901ee-e1a0-4e91-8d70-a7e0879dd119/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b8f9571e-9003-4696-a14c-fbad1ab8fe55',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T15:37:33.619Z',

          createdAt: '2024-05-30T14:02:11.677Z',
          content: [
            {
              caption: '',

              id: 'bc1e3103-e6fc-492a-88f0-a450e38528a8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a9d78875-90ea-4cf5-9cf6-1bfa1cf4624d/ugc/b8f9571e-9003-4696-a14c-fbad1ab8fe55/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '87dad00f-a6e1-4acc-a47b-5c51aa64bbd6',
        firstName: 'Grace',
        lastName: 'Hennessee',
        email: 'hennesseege@yahoo.com',
        instagramProfile: {
          handle: 'goodieswithgraceh',
          followers: 2111,
          engagementRate: 5.056845097110374
        },
        tiktokProfile: {
          handle: 'gehreviews',
          followers: 1052,
          engagementRate: 2.263083451202263
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T04:15:26.000Z',
        amountCents: 8299
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5a453d7c-3046-408c-8166-58924bd26faa',
      content: [
        {
          groupId: '9c11514a-20cd-446b-b69d-b3cd9dc66466',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T06:41:34.981Z',

          createdAt: '2024-05-30T01:08:38.419Z',
          content: [
            {
              caption: '',

              id: '5a073f1f-7482-44f4-a2c4-fe8f19d1b5da',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5a453d7c-3046-408c-8166-58924bd26faa/ugc/9c11514a-20cd-446b-b69d-b3cd9dc66466/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8c3a3c5e-043d-4059-ae5b-fe56e7b2102c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T06:41:29.925Z',

          createdAt: '2024-05-30T01:06:57.399Z',
          content: [
            {
              caption: '',

              id: '26a9368f-7e03-4e6f-b10a-73c35f5ddf05',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5a453d7c-3046-408c-8166-58924bd26faa/ugc/8c3a3c5e-043d-4059-ae5b-fe56e7b2102c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '88d27240-b438-46d8-a43c-058f5e2d138f',
        firstName: 'Elizabeth',
        lastName: 'Foster',
        email: 'bella7air@gmail.com',

        tiktokProfile: {
          handle: 'lizchristine1',
          followers: 3600,
          engagementRate: 11.695856227106226
        },
        youtubeProfile: {
          handle: 'elizabethfoster6983',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T05:02:35.000Z',
        amountCents: 7513
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5e4a0902-be51-43a2-ae9c-ce753dcdf916',
      content: [
        {
          groupId: '69bb42f3-e4a7-495c-80d9-4738621943e6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T00:42:59.818Z',

          createdAt: '2024-05-24T16:30:13.285Z',
          content: [
            {
              caption: '',

              id: 'eab0214f-0e77-435a-8940-a1715d405d91',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e4a0902-be51-43a2-ae9c-ce753dcdf916/ugc/69bb42f3-e4a7-495c-80d9-4738621943e6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9e5a3c6f-bddc-44d9-a4d1-3c1142e7981f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T15:42:42.281Z',

          createdAt: '2024-05-24T16:32:28.160Z',
          content: [
            {
              caption: '',

              id: 'fac64f1e-aefa-4bc3-910e-cbb1ade2e01e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e4a0902-be51-43a2-ae9c-ce753dcdf916/ugc/9e5a3c6f-bddc-44d9-a4d1-3c1142e7981f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '96bf72fa-283c-44e5-8c9c-3256633ef615',
        firstName: 'Sadii',
        lastName: 'Sg',
        email: 'sadefagan436@gmail.com',
        instagramProfile: {
          handle: '_s.ade',
          followers: 4062
        },
        tiktokProfile: {
          handle: '_s.ade',
          followers: 3119,
          engagementRate: 11.918604651162791
        },
        youtubeProfile: {
          handle: 'nptent-sq2kw',
          followers: 200
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T05:51:46.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5437a98d-c724-4433-9174-722858fbe954',
      content: [
        {
          groupId: 'e138676a-d6e2-40f5-879d-b953c58efa97',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T05:57:07.661Z',

          createdAt: '2024-06-06T09:48:50.971Z',
          content: [
            {
              caption: '',

              id: '8299653f-703a-40f3-bf78-270d3b4f58a8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5437a98d-c724-4433-9174-722858fbe954/ugc/e138676a-d6e2-40f5-879d-b953c58efa97/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b495f864-f5c4-45e8-b7db-318765f273c8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-11T05:57:29.232Z',

          createdAt: '2024-06-06T09:52:09.932Z',
          content: [
            {
              caption: '',

              id: '2bd4b0cc-f7e4-4ac7-9b78-e6ef750283f7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5437a98d-c724-4433-9174-722858fbe954/ugc/b495f864-f5c4-45e8-b7db-318765f273c8/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6bc08a78-f31b-4634-8be7-c2203d4e8744',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5437a98d-c724-4433-9174-722858fbe954/ugc/b495f864-f5c4-45e8-b7db-318765f273c8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c8e8d5c2-6c2f-4bee-b0bb-0f988f480249',
        firstName: 'Koneika',
        lastName: 'Morrison',
        email: 'morrisonkoneika@gmail.com',

        tiktokProfile: {
          handle: 'konnietheamazing',
          followers: 3827,
          engagementRate: 14.123376623376624
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T06:01:57.000Z',
        amountCents: 10699
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '075b9369-4d91-4a96-8bd8-41a2cff0799e',
      content: [],
      creator: {
        id: '91b00e8c-fccf-4be4-9cc6-55a1a7b0bebf',
        firstName: 'Natalie',
        lastName: 'Hall',
        email: 'hallnatalie30052@gmail.com',
        instagramProfile: {
          handle: 'nataliesnailbar__',
          followers: 256,
          engagementRate: 4.545454545454546
        },
        tiktokProfile: {
          handle: 'acrobatnat',
          followers: 1234
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T06:23:47.000Z',
        amountCents: 8314
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bb8d3814-c6e0-4ade-82ab-d96c2d4ebd9c',
      content: [
        {
          groupId: '684a796e-3eb4-4fa0-a31f-885eeedbe451',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T04:03:22.715Z',

          createdAt: '2024-06-04T03:26:35.910Z',
          content: [
            {
              caption: '',

              id: '5f3f5106-ff50-4607-b046-a891883f955c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bb8d3814-c6e0-4ade-82ab-d96c2d4ebd9c/ugc/684a796e-3eb4-4fa0-a31f-885eeedbe451/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7fabb6bf-38d3-472b-a4b6-9a069df1a479',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T23:41:50.239Z',

          createdAt: '2024-06-05T03:57:08.363Z',
          content: [
            {
              caption: '',

              id: 'ec1a1d04-fd79-4222-bd11-02e894686b3d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bb8d3814-c6e0-4ade-82ab-d96c2d4ebd9c/ugc/7fabb6bf-38d3-472b-a4b6-9a069df1a479/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cddcd85a-4451-4ebc-8c5e-14ae0a15588b',
        firstName: 'Breona',
        lastName: 'Cone',
        email: 'breshonda2018@gmail.com',
        instagramProfile: {
          handle: '8tethat.snickers',
          followers: 341,
          engagementRate: 1.2609970674486803
        },
        tiktokProfile: {
          handle: 'boujee_blue32',
          followers: 4779,
          engagementRate: 1.8445697728566253
        },
        youtubeProfile: {
          handle: 'boujee_32'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T07:16:29.000Z',
        amountCents: 8223
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1d3d3773-3447-416c-b70f-9c063b11b1e4',
      content: [
        {
          groupId: '2181c399-fed5-44cc-b1e3-9f1c3f4cda94',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T06:39:05.360Z',

          createdAt: '2024-06-09T15:34:28.850Z',
          content: [
            {
              caption: '',

              id: '57efd59d-6c43-4c23-9015-e0f7a00aabb6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1d3d3773-3447-416c-b70f-9c063b11b1e4/ugc/2181c399-fed5-44cc-b1e3-9f1c3f4cda94/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '4dbee74f-339f-4dde-8002-1dd2fac9c374',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T14:53:51.194Z',

          createdAt: '2024-06-09T15:26:32.100Z',
          content: [
            {
              caption: '',

              id: '314ba952-8c15-492c-b4fd-0b0d7dffdbd0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1d3d3773-3447-416c-b70f-9c063b11b1e4/ugc/4dbee74f-339f-4dde-8002-1dd2fac9c374/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '133bb9c5-52ca-4acb-9074-ff3df0cee317',
        firstName: 'Evelyn',
        lastName: 'Jenkins',
        email: 'info.evelynjenkins@gmail.com',

        tiktokProfile: {
          handle: '.evelynjenkins',
          followers: 60414,
          engagementRate: 16.728395061728396
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T07:55:16.000Z',
        amountCents: 8644
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bb18141d-9c2c-4119-aa91-194b7e7ba29f',
      content: [],
      creator: {
        id: '5cc49d17-1ee4-4de8-a8b6-cf432d66885c',
        firstName: 'Triston',
        lastName: 'Wilks',
        email: 'wilkstriston@gmail.com',
        instagramProfile: {
          handle: 'triston_wilks',
          followers: 357
        },
        tiktokProfile: {
          handle: 'tristonwilks',
          followers: 1165
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T08:04:22.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd90f1f6e-763f-466e-880e-bbbf16544bdb',
      content: [],
      creator: {
        id: '04c0e39a-2bef-4833-9312-3fda57055882',
        firstName: 'Kerry-Ann',
        lastName: 'Tracey',
        email: 'azariahskai@gmail.com',
        instagramProfile: {
          handle: 'keepingupwithazariah',
          followers: 66932,
          engagementRate: 0.12445467041176118
        },
        tiktokProfile: {
          handle: 'kerryfabulous',
          followers: 1754,
          engagementRate: 3.628506539933549
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T09:42:39.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd49c8cb2-8afb-4871-82d8-038097da8f6d',
      content: [
        {
          groupId: '98fbc6a7-4578-40b5-9743-a6559bad2795',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T15:11:43.201Z',

          createdAt: '2024-05-29T16:30:23.110Z',
          content: [
            {
              caption: '',

              id: 'f8581d98-f07f-41e4-9792-ee4ddfb7fbd3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d49c8cb2-8afb-4871-82d8-038097da8f6d/ugc/98fbc6a7-4578-40b5-9743-a6559bad2795/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '29c908ab-16a0-4f5b-8fbd-78b66d295a53',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d49c8cb2-8afb-4871-82d8-038097da8f6d/ugc/98fbc6a7-4578-40b5-9743-a6559bad2795/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '694eabe9-5558-4f93-add8-38a663139efc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d49c8cb2-8afb-4871-82d8-038097da8f6d/ugc/98fbc6a7-4578-40b5-9743-a6559bad2795/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fe7e3bd1-dbc5-4467-8194-a78662b5a819',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T15:11:38.851Z',

          createdAt: '2024-05-29T16:42:40.658Z',
          content: [
            {
              caption: '',

              id: 'a531ac12-90b0-4dd2-a9c1-55496996cfa9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d49c8cb2-8afb-4871-82d8-038097da8f6d/ugc/fe7e3bd1-dbc5-4467-8194-a78662b5a819/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '959129d6-ba49-4302-975b-42105a35d590',
        firstName: 'Bliss',
        lastName: 'Courtney',
        email: 'blissfulartcreations98@gmail.com',
        instagramProfile: {
          handle: 'joplinmocato',
          followers: 424,
          engagementRate: 0.1396551724137931
        },
        tiktokProfile: {
          handle: 'bliss_98',
          followers: 17054,
          engagementRate: 12.325741244102852
        },
        youtubeProfile: {
          handle: 'blisscourtney6593'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T09:53:53.000Z',
        amountCents: 7295
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'de89a3d8-d25e-46b1-9e66-5aa91d7bd710',
      content: [
        {
          groupId: 'b422112d-6e55-48ab-bf60-7c30e28de8f0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-14T13:24:44.832Z',

          createdAt: '2024-06-12T18:50:01.019Z',
          content: [
            {
              caption: '',

              id: '9ca61cd6-43fa-49e9-958c-96e857955795',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/de89a3d8-d25e-46b1-9e66-5aa91d7bd710/ugc/b422112d-6e55-48ab-bf60-7c30e28de8f0/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '602be950-935d-421c-b6af-476efb9c4e0a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/de89a3d8-d25e-46b1-9e66-5aa91d7bd710/ugc/b422112d-6e55-48ab-bf60-7c30e28de8f0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '94ab3d5a-08f5-4c55-a80a-69a248a39007',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T22:30:23.001Z',

          createdAt: '2024-06-12T18:26:04.035Z',
          content: [
            {
              caption: '',

              id: 'd1973121-fe25-4322-8670-7c5e636cbff7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/de89a3d8-d25e-46b1-9e66-5aa91d7bd710/ugc/94ab3d5a-08f5-4c55-a80a-69a248a39007/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6a0efba0-5f16-490a-a9da-3298dd4d54cb',
        firstName: 'Keyton',
        lastName: 'Woolcock',
        email: 'shawtyddon@gmail.com',
        instagramProfile: {
          handle: 'jaynell_contents',
          followers: 688,
          engagementRate: 1.4534883720930232
        },
        tiktokProfile: {
          handle: 'shawty_ddon',
          followers: 2448,
          engagementRate: 27.380952380952383
        },
        youtubeProfile: {
          handle: 'janiellerobinson1030',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T10:54:23.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '340ca56b-cabe-4484-8499-c8f73505aa48',
      content: [
        {
          groupId: 'fbca2ca7-6490-45b1-be7d-276aedc764c8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:14:04.465Z',

          createdAt: '2024-06-03T17:11:43.944Z',
          content: [
            {
              caption: '',

              id: 'e5db2583-a7b6-41c9-a461-e82ade5c41e2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/340ca56b-cabe-4484-8499-c8f73505aa48/ugc/fbca2ca7-6490-45b1-be7d-276aedc764c8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'abd366d5-e319-4ece-a798-773d33c238a6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T13:28:32.765Z',

          createdAt: '2024-06-03T16:44:51.420Z',
          content: [
            {
              caption: '',

              id: '879e6aa4-bdc4-44a1-9d43-f02854b271ff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/340ca56b-cabe-4484-8499-c8f73505aa48/ugc/abd366d5-e319-4ece-a798-773d33c238a6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cfd67b3a-3907-40d3-bc87-bdb4378d29b0',
        firstName: 'Bianca',
        lastName: 'Porras',
        email: 'bporras8@gmail.com',
        instagramProfile: {
          handle: 'biancaporras9',
          followers: 98
        },
        tiktokProfile: {
          handle: 'biancaporras9',
          followers: 20178,
          engagementRate: 17.28813559322034
        },
        youtubeProfile: {
          handle: 'biancaporras7207',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T12:04:47.000Z',
        amountCents: 7545
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c4deae9c-222d-4841-b4d4-6cac088e9dea',
      content: [
        {
          groupId: '690f950b-4ace-4a78-bef1-f652981830b6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T02:42:27.224Z',

          createdAt: '2024-06-11T17:20:33.129Z',
          content: [
            {
              caption: '',

              id: 'aa0701d1-ac30-436e-bd1f-a96ef6da8e33',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c4deae9c-222d-4841-b4d4-6cac088e9dea/ugc/690f950b-4ace-4a78-bef1-f652981830b6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c2f855b0-b0cd-4ac3-b964-56d22a8de559',
        firstName: 'Ivory',
        lastName: 'Wise',
        email: 'ivoryc15@gmail.com',
        instagramProfile: {
          handle: 'makeuplucki',
          followers: 715,
          engagementRate: 1.4405594405594406
        },
        tiktokProfile: {
          handle: 'makeuplucki',
          followers: 1731,
          engagementRate: 4.417670682730924
        },
        youtubeProfile: {
          handle: 'makeuplucki',
          followers: 86
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T12:35:33.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f332992d-9052-4828-a2c7-7136c567924a',
      content: [
        {
          groupId: '80a777ad-405a-48d0-8e76-a9a148aec388',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T03:18:28.451Z',

          createdAt: '2024-05-28T23:29:52.906Z',
          content: [
            {
              caption: '',

              id: '6d70e7f9-1aea-48eb-affb-72fbc0f72c2f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f332992d-9052-4828-a2c7-7136c567924a/ugc/80a777ad-405a-48d0-8e76-a9a148aec388/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7df9a73e-6cfb-47b4-83b1-48ec8f6fcdca',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T03:18:32.637Z',

          createdAt: '2024-05-28T23:27:23.583Z',
          content: [
            {
              caption: '',

              id: 'ae8fd2cb-753c-4ea8-8ace-2e4fe425e7c7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f332992d-9052-4828-a2c7-7136c567924a/ugc/7df9a73e-6cfb-47b4-83b1-48ec8f6fcdca/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a88af9e1-6e01-4210-a348-ade1d03c3803',
        firstName: 'Jasmine',
        lastName: 'Lowe',
        email: 'jlowe97123@gmail.com',
        instagramProfile: {
          handle: 'jasmineerudite',
          followers: 1660,
          engagementRate: 0.03733572281959379
        },
        tiktokProfile: {
          handle: 'jasmineerudite',
          followers: 1335,
          engagementRate: 6.29045792375929
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T13:25:04.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4ab98fa1-6ff0-4ce7-8b71-923c1a0516ec',
      content: [
        {
          groupId: '226a56b7-0f00-4046-9da2-b60844f05f5e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T10:24:10.825Z',

          createdAt: '2024-06-05T12:53:48.291Z',
          content: [
            {
              caption: '',

              id: 'b03cec28-7440-49f9-b2e8-28b30086188f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4ab98fa1-6ff0-4ce7-8b71-923c1a0516ec/ugc/226a56b7-0f00-4046-9da2-b60844f05f5e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'da602d5f-2d0f-4bbb-a1ba-43cd1a7f9d1b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-04T03:50:54.037Z',

          createdAt: '2024-05-31T18:02:12.193Z',
          content: [
            {
              caption: '',

              id: 'c23a1e42-281a-437c-af19-513fb56a8db5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4ab98fa1-6ff0-4ce7-8b71-923c1a0516ec/ugc/da602d5f-2d0f-4bbb-a1ba-43cd1a7f9d1b/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a958181a-8fda-46a2-881f-95a48d717a3f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4ab98fa1-6ff0-4ce7-8b71-923c1a0516ec/ugc/da602d5f-2d0f-4bbb-a1ba-43cd1a7f9d1b/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '83d23ef2-5295-4b00-bf99-18f1fbcca588',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4ab98fa1-6ff0-4ce7-8b71-923c1a0516ec/ugc/da602d5f-2d0f-4bbb-a1ba-43cd1a7f9d1b/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f3083390-6a45-4a24-aadf-7b50de700ee4',
        firstName: 'Tasheika',
        lastName: 'Hylton',
        email: 'thyltoncampbell@gmail.com',

        tiktokProfile: {
          handle: 'frozen_queen_iceucg',
          followers: 950,
          engagementRate: 24.137931034482758
        },
        youtubeProfile: {
          handle: 'tasheikahylton5230',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T14:12:05.000Z',
        amountCents: 8160
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '55611a5a-c2ba-487d-a2fd-e0c64643e9a4',
      content: [
        {
          groupId: '2e70bc1f-1f49-493d-bdc8-a34580a4007a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T22:58:05.290Z',

          createdAt: '2024-06-05T15:28:20.416Z',
          content: [
            {
              caption: '',

              id: '453e0236-0185-4ceb-8688-b6844adf5496',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55611a5a-c2ba-487d-a2fd-e0c64643e9a4/ugc/2e70bc1f-1f49-493d-bdc8-a34580a4007a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '587fafaf-94cb-460b-9167-b304e619deea',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-31T11:19:00.398Z',

          createdAt: '2024-05-28T21:17:03.667Z',
          content: [
            {
              caption: '',

              id: '400a7922-5304-4fae-bc2f-e39e3375f688',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55611a5a-c2ba-487d-a2fd-e0c64643e9a4/ugc/587fafaf-94cb-460b-9167-b304e619deea/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1893fd58-8c65-47c8-b39a-57f1881b119d',
        firstName: 'Katie',
        lastName: 'Record',
        email: 'katieriley.cin@gmail.com',
        instagramProfile: {
          handle: 'kriles',
          followers: 4647,
          engagementRate: 0.44473136790761064
        },
        tiktokProfile: {
          handle: 'kriles12',
          followers: 109
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T14:35:26.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b0c1f32f-dfcc-40d2-90d6-3b0133c7ecd2',
      content: [
        {
          groupId: '30f28dc6-126c-4479-b31f-9e9beddd7309',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T07:37:44.410Z',

          createdAt: '2024-06-05T22:30:47.084Z',
          content: [
            {
              caption: '',

              id: '2fe68cd9-5d44-4b18-8b8a-39f55491809c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0c1f32f-dfcc-40d2-90d6-3b0133c7ecd2/ugc/30f28dc6-126c-4479-b31f-9e9beddd7309/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2b39c11e-7c8d-4fb2-bf95-9173486989fc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T22:10:42.685Z',

          createdAt: '2024-06-12T03:11:33.322Z',
          content: [
            {
              caption: '',

              id: '64a475be-7ddf-4d15-8886-2241deba13ed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0c1f32f-dfcc-40d2-90d6-3b0133c7ecd2/ugc/2b39c11e-7c8d-4fb2-bf95-9173486989fc/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5fecb676-a27b-4b2b-b91a-6c809cb919c0',
        firstName: 'Cristy',
        lastName: 'Almonte',
        email: 'cristyjalmonte@gmail.com',

        tiktokProfile: {
          handle: 'thecrunchpot',
          followers: 10180,
          engagementRate: 14.303605011976678
        },
        youtubeProfile: {
          handle: 'thecrunchpot',
          followers: 710
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T16:14:15.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '54b77f41-a879-45e2-81ca-4b5cbd6a70ef',
      content: [
        {
          groupId: '0533c579-5413-486f-975a-00080bfb1a93',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T15:03:28.813Z',

          createdAt: '2024-06-06T22:04:14.885Z',
          content: [
            {
              caption: '',

              id: '7a7514a6-a6ed-431e-8c17-3e02efd24b2e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54b77f41-a879-45e2-81ca-4b5cbd6a70ef/ugc/0533c579-5413-486f-975a-00080bfb1a93/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '179c2f7f-709a-401a-91b9-07cbd6f83795',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54b77f41-a879-45e2-81ca-4b5cbd6a70ef/ugc/0533c579-5413-486f-975a-00080bfb1a93/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '869fbc26-9c3a-4457-98a4-899d62c9dc22',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54b77f41-a879-45e2-81ca-4b5cbd6a70ef/ugc/0533c579-5413-486f-975a-00080bfb1a93/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ba157a1b-6008-4a19-a59a-b8f09a9e2c52',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T03:10:09.138Z',

          createdAt: '2024-06-07T20:02:59.734Z',
          content: [
            {
              caption: '',

              id: '40a6bc47-3cde-4d47-aa00-33f1d1078430',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54b77f41-a879-45e2-81ca-4b5cbd6a70ef/ugc/ba157a1b-6008-4a19-a59a-b8f09a9e2c52/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1acae01d-91d9-438a-a40d-e3ea09e798da',
        firstName: 'Pamela',
        lastName: 'Todd',
        email: 'pmtodd119@gmail.com',
        instagramProfile: {
          handle: 'pammtodd19',
          followers: 2444,
          engagementRate: 0.8346972176759411
        },
        tiktokProfile: {
          handle: 'pammtodd',
          followers: 1669,
          engagementRate: 5.0679511745085515
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T16:21:25.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9320dfb3-e0e6-413d-8a6c-7fc7ebdb8426',
      content: [
        {
          groupId: '96cbd5e7-8512-4cf9-8475-3dcd22fd4ebc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-09T18:45:44.110Z',

          createdAt: '2024-06-04T05:57:24.290Z',
          content: [
            {
              caption: '',

              id: 'bc79cb0f-b20f-49b9-a486-e2c8e1ae66d1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9320dfb3-e0e6-413d-8a6c-7fc7ebdb8426/ugc/96cbd5e7-8512-4cf9-8475-3dcd22fd4ebc/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '92004d3a-ca49-4c76-9b52-8a377319c68c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-09T18:45:58.025Z',

          createdAt: '2024-06-04T06:00:17.668Z',
          content: [
            {
              caption: '',

              id: '95711e60-a364-45a1-81b0-ceef3dc39e11',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9320dfb3-e0e6-413d-8a6c-7fc7ebdb8426/ugc/92004d3a-ca49-4c76-9b52-8a377319c68c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4c8a5c0a-20d3-47f6-b5df-b6a78f9e160c',
        firstName: 'Jazmyn',
        lastName: 'Gere',
        email: 'jazmyngere@yahoo.com',
        instagramProfile: {
          handle: 'jazzyjuggz10',
          followers: 1256,
          engagementRate: 1.6878980891719746
        },
        tiktokProfile: {
          handle: 'jazzyjuggz10',
          followers: 229,
          engagementRate: 4.446640316205533
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T16:25:46.000Z',
        amountCents: 7622
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '91909aba-f932-44a5-a1ca-51e13033519e',
      content: [],
      creator: {
        id: '5034ee19-23a0-417b-8822-8e1dfc1f104b',
        firstName: 'Mackenzie',
        lastName: 'Cruey',
        email: '14cruey@gmail.com',

        tiktokProfile: {
          handle: 'kenziecruey',
          followers: 7892,
          engagementRate: 5.548714791442204
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T16:25:48.000Z',
        amountCents: 3217
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '46caad0f-d9c5-492c-ac9d-d7ddb82b8ebd',
      content: [
        {
          groupId: 'd7088aa5-8a93-4422-8109-80162fe09702',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:23:57.665Z',

          createdAt: '2024-05-28T21:20:02.509Z',
          content: [
            {
              caption: '',

              id: 'ce39c8e4-d2c1-47d7-a570-bcc5ba38844d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46caad0f-d9c5-492c-ac9d-d7ddb82b8ebd/ugc/d7088aa5-8a93-4422-8109-80162fe09702/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '88010cd3-7269-47c2-a2b1-d6105f328de2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:15:50.129Z',

          createdAt: '2024-05-28T21:00:46.550Z',
          content: [
            {
              caption: '',

              id: '0acd980a-e24a-4ff5-a47f-fc8c1937c2aa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46caad0f-d9c5-492c-ac9d-d7ddb82b8ebd/ugc/88010cd3-7269-47c2-a2b1-d6105f328de2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '07b6d498-2159-4548-8631-e8b681069003',
        firstName: 'sese',
        lastName: 'alexis',
        email: 'sesealexis9@icloud.com',
        instagramProfile: {
          handle: 'sese.szn',
          followers: 1919,
          engagementRate: 5.185561769191662
        },
        tiktokProfile: {
          handle: 'sesesznn',
          followers: 1637,
          engagementRate: 26.666666666666668
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T16:29:11.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd6c84c49-2a60-4beb-b570-7f570f239993',
      content: [
        {
          groupId: '4d80cb78-b2b8-46bd-9fc8-45112cc09830',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:53:38.762Z',

          createdAt: '2024-05-28T23:48:22.673Z',
          content: [
            {
              caption: '',

              id: 'f29a28ca-aa23-497f-90eb-169ce7cdac53',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d6c84c49-2a60-4beb-b570-7f570f239993/ugc/4d80cb78-b2b8-46bd-9fc8-45112cc09830/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '331b4685-b8df-49cb-a480-4a0cdc1f4851',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:53:43.375Z',

          createdAt: '2024-05-28T23:52:02.448Z',
          content: [
            {
              caption: '',

              id: 'd186e9d5-44e2-45d7-a1a0-42e90674becb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d6c84c49-2a60-4beb-b570-7f570f239993/ugc/331b4685-b8df-49cb-a480-4a0cdc1f4851/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '439d3f2f-bf14-4353-b25d-cbeb05a92021',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d6c84c49-2a60-4beb-b570-7f570f239993/ugc/331b4685-b8df-49cb-a480-4a0cdc1f4851/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd62cc26a-74a8-420c-8b8c-112fab15a8ab',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d6c84c49-2a60-4beb-b570-7f570f239993/ugc/331b4685-b8df-49cb-a480-4a0cdc1f4851/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '114f6151-42b9-463f-af47-8c6a2c576af4',
        firstName: 'Melanie',
        lastName: 'Herrera',
        email: 'meniedu3@icloud.com',
        instagramProfile: {
          handle: 'menyeduworld',
          followers: 23028,
          engagementRate: 0.5128539169706444
        },
        tiktokProfile: {
          handle: 'pinkymeny',
          followers: 6336,
          engagementRate: 13.114754098360656
        },
        youtubeProfile: {
          handle: 'pinkymenysfamily'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T16:30:11.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '25186dff-df1a-4e48-9a73-7d80e66b3aec',
      content: [
        {
          groupId: '141e36df-1ed0-44ea-a17c-7734165d9d73',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-29T18:25:19.530Z',

          createdAt: '2024-05-28T19:25:09.415Z',
          content: [
            {
              caption: '',

              id: 'da93d1f3-861e-4fbb-a903-350e39677a45',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/25186dff-df1a-4e48-9a73-7d80e66b3aec/ugc/141e36df-1ed0-44ea-a17c-7734165d9d73/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'cbcfcef1-0bd3-4d3f-99a9-cda7c222cead',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/25186dff-df1a-4e48-9a73-7d80e66b3aec/ugc/141e36df-1ed0-44ea-a17c-7734165d9d73/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '11fbb034-aa9b-4848-8250-43bcb6a6fcad',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-29T18:25:14.738Z',

          createdAt: '2024-05-28T22:05:40.470Z',
          content: [
            {
              caption: '',

              id: '92621995-7e25-42db-81fa-dac313cb4208',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/25186dff-df1a-4e48-9a73-7d80e66b3aec/ugc/11fbb034-aa9b-4848-8250-43bcb6a6fcad/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4285a667-376e-4302-b108-4247b5e5283e',
        firstName: 'Tosh and Ty',
        lastName: 'Bell',
        email: 'hello@tntswirl.com',
        instagramProfile: {
          handle: 'tntswirl',
          followers: 132107,
          engagementRate: 0.7755077323684588
        },
        tiktokProfile: {
          handle: 'tntswirl',
          followers: 34549,
          engagementRate: 2.8199301623950714
        },
        youtubeProfile: {
          handle: 'tntswirl.official'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T16:32:48.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7e527ab6-be32-4033-9668-021130264c40',
      content: [],
      creator: {
        id: '562d6fd8-30b6-47d6-a0b8-eb1c3e5d8030',
        firstName: 'Aly',
        lastName: 'Thompson',
        email: 'alybd6@gmail.com',
        instagramProfile: {
          handle: 'alythompsoninteriors',
          followers: 3461,
          engagementRate: 1.0632765096792833
        },
        tiktokProfile: {
          handle: 'byalythompson',
          followers: 275,
          engagementRate: 3.8461538461538463
        },
        youtubeProfile: {
          handle: 'byalythompson'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T16:32:56.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3fdce638-d630-42fa-9d79-482aca03c3b6',
      content: [
        {
          groupId: '4a231383-c452-47c1-8a7e-6ced4fa997ed',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T05:16:50.946Z',

          createdAt: '2024-06-05T04:41:23.960Z',
          content: [
            {
              caption: '',

              id: '5b6f88fa-0462-40e8-b76d-b8e268b896ca',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3fdce638-d630-42fa-9d79-482aca03c3b6/ugc/4a231383-c452-47c1-8a7e-6ced4fa997ed/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3e50fa28-8478-4e40-a08a-ce0b02ec1a24',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T19:34:55.814Z',

          createdAt: '2024-06-05T04:44:13.943Z',
          content: [
            {
              caption: '',

              id: '141b7f63-83db-43bb-99f5-8672a62e1501',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3fdce638-d630-42fa-9d79-482aca03c3b6/ugc/3e50fa28-8478-4e40-a08a-ce0b02ec1a24/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a74c88df-f32b-4712-9977-8f76e0d2a026',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3fdce638-d630-42fa-9d79-482aca03c3b6/ugc/3e50fa28-8478-4e40-a08a-ce0b02ec1a24/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '770bfad4-acbd-4a39-8ff1-d46acba0a2c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3fdce638-d630-42fa-9d79-482aca03c3b6/ugc/3e50fa28-8478-4e40-a08a-ce0b02ec1a24/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd2ce7400-7757-4481-8f04-f38b315b1405',
        firstName: 'Chelsea',
        lastName: 'Greer',
        email: 'chelsea757@gmail.com',
        instagramProfile: {
          handle: 'chelsealizgreer',
          followers: 8586,
          engagementRate: 2.9315164220824594
        },
        tiktokProfile: {
          handle: 'chelsealizgreer',
          followers: 168,
          engagementRate: 2.5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T16:42:01.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a39fec03-fc32-4618-ae76-fa73f8f126d6',
      content: [
        {
          groupId: '4f521e80-a50a-48e6-b9ec-62510122977c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T14:46:14.930Z',

          createdAt: '2024-05-30T12:51:20.573Z',
          content: [
            {
              caption: '',

              id: 'aa86ef79-a0a9-4db9-ad9f-276f68c79359',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a39fec03-fc32-4618-ae76-fa73f8f126d6/ugc/4f521e80-a50a-48e6-b9ec-62510122977c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a1bd513c-0537-4e90-83b1-7f0d29cd89e6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T13:17:10.245Z',

          createdAt: '2024-05-31T20:01:34.262Z',
          content: [
            {
              caption: '',

              id: '1a4c0999-87ef-4f0f-ab62-7b2c9f4ab27e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a39fec03-fc32-4618-ae76-fa73f8f126d6/ugc/a1bd513c-0537-4e90-83b1-7f0d29cd89e6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '04c7d444-18c0-42da-8c36-d265a65e1ec4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a39fec03-fc32-4618-ae76-fa73f8f126d6/ugc/a1bd513c-0537-4e90-83b1-7f0d29cd89e6/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a53ce7bf-746b-47a3-a5eb-2a7244b12bc9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a39fec03-fc32-4618-ae76-fa73f8f126d6/ugc/a1bd513c-0537-4e90-83b1-7f0d29cd89e6/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '59b9863b-35fe-4e58-8ba2-af12aa2749af',
        firstName: 'Lanthia',
        lastName: 'Lewis',
        email: 'lanthialewis101@gmail.com',

        tiktokProfile: {
          handle: 'thiia_miya',
          followers: 3167,
          engagementRate: 2.5830258302583027
        },
        youtubeProfile: {
          handle: 'thiiamiyainfluencer',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T17:02:26.000Z',
        amountCents: 8668
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6162f803-2475-4f9e-8192-b96dc7c707b4',
      content: [],
      creator: {
        id: '8eeeaf45-c5de-4810-85a6-89b1581a0f60',
        firstName: 'Jamie',
        lastName: 'Nousain',
        email: 'jbartos97@yahoo.com',

        tiktokProfile: {
          handle: 'momlifewithjamie',
          followers: 3726,
          engagementRate: 22.92581106870229
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T17:02:39.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '51413c3d-a175-4674-8dd4-810952c8c944',
      content: [],
      creator: {
        id: '1e6541d5-1611-40d4-83a5-39feb043d900',
        firstName: 'Melissa',
        lastName: 'Henry',
        email: 'melissahenry2104@gmail.com',
        instagramProfile: {
          handle: 'melissajeanjo',
          followers: 172323,
          engagementRate: 0.5715429745303877
        },
        tiktokProfile: {
          handle: 'melissajeanjo',
          followers: 2920,
          engagementRate: 11.319612590799032
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T17:44:28.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a09d5fc2-27eb-4502-a7b9-275e4b49d7fd',
      content: [
        {
          groupId: '5f533e83-2681-4eca-b3c1-ea8b7e0aca46',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T16:11:29.234Z',

          createdAt: '2024-06-07T04:53:41.801Z',
          content: [
            {
              caption: '',

              id: '7173b90f-eddf-44f2-aad7-07dad18f954a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a09d5fc2-27eb-4502-a7b9-275e4b49d7fd/ugc/5f533e83-2681-4eca-b3c1-ea8b7e0aca46/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a10d01e4-a40c-4c5b-bdda-b3cc6c42b73f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T16:11:33.900Z',

          createdAt: '2024-06-07T04:58:42.718Z',
          content: [
            {
              caption: '',

              id: 'ecbc7d49-4cf7-4338-82cc-f74919710f52',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a09d5fc2-27eb-4502-a7b9-275e4b49d7fd/ugc/a10d01e4-a40c-4c5b-bdda-b3cc6c42b73f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '84d21f77-c73b-4671-a1af-56ede59adb71',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a09d5fc2-27eb-4502-a7b9-275e4b49d7fd/ugc/a10d01e4-a40c-4c5b-bdda-b3cc6c42b73f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f0b94d81-dfe8-4972-8ae3-7a434e5b864f',
        firstName: 'Karen',
        lastName: 'Diegues',
        email: 'karendiegues@icloud.com',

        tiktokProfile: {
          handle: 'gladwematched',
          followers: 11420,
          engagementRate: 1.8310738185222428
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T17:46:47.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0c7774ab-9e52-44ee-8df6-4769bc59ac94',
      content: [],
      creator: {
        id: '992127fe-621d-4aba-bbcd-0d30ed4f8150',
        firstName: 'Amani',
        lastName: 'Garvin',
        email: 'beautyandthebeatajg@gmail.com',
        instagramProfile: {
          handle: '__maniigee',
          followers: 1053,
          engagementRate: 4.919278252611585
        },
        tiktokProfile: {
          handle: '__maniigee',
          followers: 407,
          engagementRate: 1.0869565217391304
        },
        youtubeProfile: {
          handle: 'maniigee9425'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T17:58:14.000Z',
        amountCents: 8617
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2a16b6eb-879b-4737-87ef-797d5fb0db04',
      content: [],
      creator: {
        id: '46960a3d-8f6b-413c-add1-6be5bd3f6a0e',
        firstName: 'Krystal',
        lastName: 'Giraldo',
        email: 'krystaljanet@yahoo.com',
        instagramProfile: {
          handle: 'theinclusivemama',
          followers: 7367,
          engagementRate: 1.551513506176191
        },
        tiktokProfile: {
          handle: 'theinclusivemama_',
          followers: 382,
          engagementRate: 5.263157894736842
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T18:02:42.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9efb36ce-7d67-4b6a-b4b4-d7ef4fccec8e',
      content: [
        {
          groupId: '4a83d7f0-b60e-4701-a17a-0784fbf17b78',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T13:33:04.850Z',

          createdAt: '2024-06-06T23:08:41.394Z',
          content: [
            {
              caption: '',

              id: '91aa0d16-4d6d-42e6-aab9-6a67d725c4a7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9efb36ce-7d67-4b6a-b4b4-d7ef4fccec8e/ugc/4a83d7f0-b60e-4701-a17a-0784fbf17b78/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3b29189b-14e8-428f-906a-b3edfc8e2ffb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T17:34:23.414Z',

          createdAt: '2024-06-07T01:14:03.170Z',
          content: [
            {
              caption: '',

              id: '4ebb0bd4-5bfe-4136-8923-ab4899a7e7f0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9efb36ce-7d67-4b6a-b4b4-d7ef4fccec8e/ugc/3b29189b-14e8-428f-906a-b3edfc8e2ffb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f0e5ce6a-210e-4d07-be34-f99072ed0639',
        firstName: 'Jessica',
        lastName: 'Zemko',
        email: 'jessicazemko123@gmail.com',
        instagramProfile: {
          handle: 'samplecentralfreebies',
          followers: 1815,
          engagementRate: 1.5671870217324764
        },
        tiktokProfile: {
          handle: 'samplecentralfreebies',
          followers: 999,
          engagementRate: 5.947955390334572
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T18:06:32.000Z',
        amountCents: 8551
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c0b6b1b5-c1e8-4f39-be1e-b752ccdaab62',
      content: [],
      creator: {
        id: '3c9eda87-0b07-4a0f-aca0-2bef03fba4c2',
        firstName: 'Rita',
        lastName: 'O’Neal',
        email: 'momwithadotcom@gmail.com',
        instagramProfile: {
          handle: 'momwithadotcom',
          followers: 1664,
          engagementRate: 0.30649038461538464
        },
        tiktokProfile: {
          handle: 'ritaoneal',
          followers: 1086,
          engagementRate: 5.042016806722689
        },
        youtubeProfile: {
          handle: 'ritarageous'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T18:16:27.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '46548357-74e8-425b-99a7-7a84c5f53861',
      content: [
        {
          groupId: '91f94d4d-e55f-4632-a1e9-aa66ecc6455b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:08:18.717Z',

          createdAt: '2024-05-30T19:29:05.700Z',
          content: [
            {
              caption: '',

              id: '1dc98ef6-675e-4843-879f-2f4060222d81',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46548357-74e8-425b-99a7-7a84c5f53861/ugc/91f94d4d-e55f-4632-a1e9-aa66ecc6455b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '96721770-4d72-4050-baf3-6465be10856b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T14:48:39.837Z',

          createdAt: '2024-05-30T22:50:18.172Z',
          content: [
            {
              caption: '',

              id: 'ff602658-adbb-4d85-bd7e-4a97e02ebbc8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46548357-74e8-425b-99a7-7a84c5f53861/ugc/96721770-4d72-4050-baf3-6465be10856b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ab3de3e1-9453-4b03-9116-cc8b005e5e9d',
        firstName: 'Justin',
        lastName: 'Thomas',
        email: 'jussfitz@gmail.com',
        instagramProfile: {
          handle: 'justicefitzz',
          followers: 653,
          engagementRate: 2.879019908116386
        },
        tiktokProfile: {
          handle: 'justicefitzz',
          followers: 3323,
          engagementRate: 14.1782325029656
        },
        youtubeProfile: {
          handle: 'itsjustintee'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T18:34:59.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '29740248-06bc-4a5c-abd1-6d10131207d0',
      content: [
        {
          groupId: '00f50763-3e04-431e-a773-260dfff42ac5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T23:46:36.824Z',

          createdAt: '2024-06-02T22:22:22.011Z',
          content: [
            {
              caption: '',

              id: '32896a87-a2b0-4013-9626-dbf299fd1f2d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29740248-06bc-4a5c-abd1-6d10131207d0/ugc/00f50763-3e04-431e-a773-260dfff42ac5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b5b7a1d8-1db0-492d-a952-0e13b176e219',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T23:46:41.772Z',

          createdAt: '2024-06-02T22:23:20.813Z',
          content: [
            {
              caption: '',

              id: '0b11596f-a1bd-4db0-a5d5-8b1aae7d8f8e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29740248-06bc-4a5c-abd1-6d10131207d0/ugc/b5b7a1d8-1db0-492d-a952-0e13b176e219/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e541f32f-8db6-4d68-b0e2-9bc90e70970f',
        firstName: 'Felicia',
        lastName: 'Lefebvre',
        email: 'flefebvre2010@icloud.com',
        instagramProfile: {
          handle: 'felicialefebvre',
          followers: 1780,
          engagementRate: 0.1404494382022472
        },
        tiktokProfile: {
          handle: 'felicia_lefebvre',
          followers: 5296,
          engagementRate: 20.588235294117645
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T19:13:43.000Z',
        amountCents: 8448
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '44e39af9-8e92-4cef-90ce-4c6c5cafdd1a',
      content: [],
      creator: {
        id: '33a7bc88-ba46-4b19-9902-1a4c30d4625e',
        firstName: 'Destiny',
        lastName: 'Trivino',
        email: 'destinytrivinocollab@gmail.com',
        instagramProfile: {
          handle: 'destrivvv',
          followers: 2518,
          engagementRate: 4.5472597299444
        },
        tiktokProfile: {
          handle: 'destrivlifts',
          followers: 519,
          engagementRate: 4.3175487465181055
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T19:23:39.000Z',
        amountCents: 7629
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '250ea4c3-0a05-4f68-a091-4a6ddb2a1b89',
      content: [
        {
          groupId: '98cb6d08-9ebc-4d97-a890-61185cf755e4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T06:43:37.528Z',

          createdAt: '2024-05-29T17:47:06.593Z',
          content: [
            {
              caption: '',

              id: '9d624496-5ea8-4a06-aa1a-f72f5bedb6ba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/250ea4c3-0a05-4f68-a091-4a6ddb2a1b89/ugc/98cb6d08-9ebc-4d97-a890-61185cf755e4/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '57901e10-47fa-48ea-8f68-33a1cd68b09b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/250ea4c3-0a05-4f68-a091-4a6ddb2a1b89/ugc/98cb6d08-9ebc-4d97-a890-61185cf755e4/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f6081732-10c9-477a-acbf-4130c7899a48',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/250ea4c3-0a05-4f68-a091-4a6ddb2a1b89/ugc/98cb6d08-9ebc-4d97-a890-61185cf755e4/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bab952c3-00b2-4d92-b112-558f079ac6e8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T06:43:42.132Z',

          createdAt: '2024-05-29T17:58:21.269Z',
          content: [
            {
              caption: '',

              id: '6af23f2c-cc17-4623-bd73-cd7be17cf4a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/250ea4c3-0a05-4f68-a091-4a6ddb2a1b89/ugc/bab952c3-00b2-4d92-b112-558f079ac6e8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4ee0a2dd-2cc8-4520-bf86-dab80b149c48',
        firstName: 'Tabyre',
        lastName: 'Hoyle',
        email: 'tabyreeaston13@live.com',
        instagramProfile: {
          handle: 'tabyrehoyle',
          followers: 1370,
          engagementRate: 7.226277372262774
        },
        tiktokProfile: {
          handle: 'tabes0',
          followers: 4497,
          engagementRate: 7.702569169960475
        },
        youtubeProfile: {
          handle: 'hollistergirl2',
          followers: 8
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T19:23:44.000Z',
        amountCents: 10905
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e0fb4708-87e1-4239-a169-fd75ee5ef442',
      content: [
        {
          groupId: '7473c824-16a0-4f6a-b3ec-d6f4107dcd2f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-07T01:04:49.406Z',

          createdAt: '2024-06-14T03:45:12.456Z',
          content: [
            {
              caption: '',

              id: 'cdf3ea93-dd15-4447-9b64-877509a87303',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0fb4708-87e1-4239-a169-fd75ee5ef442/ugc/7473c824-16a0-4f6a-b3ec-d6f4107dcd2f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8a019265-27c3-4407-a2a5-348a44e03d26',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0fb4708-87e1-4239-a169-fd75ee5ef442/ugc/7473c824-16a0-4f6a-b3ec-d6f4107dcd2f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '711e14ee-6f1a-4a6c-a04f-c8e9bcdb22e4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0fb4708-87e1-4239-a169-fd75ee5ef442/ugc/7473c824-16a0-4f6a-b3ec-d6f4107dcd2f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ee4e78a7-98b7-4e70-94d4-391e0dd2fe7d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T13:27:53.639Z',

          createdAt: '2024-06-14T03:37:36.580Z',
          content: [
            {
              caption: '',

              id: 'e747a930-95e2-4b92-9450-632257d1cf6b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e0fb4708-87e1-4239-a169-fd75ee5ef442/ugc/ee4e78a7-98b7-4e70-94d4-391e0dd2fe7d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3856e469-4911-4eda-becf-e2792fd78e33',
        firstName: 'Chanice',
        lastName: 'Washington',
        email: 'chanicewashington1998@gmail.com',

        tiktokProfile: {
          handle: 'chaniceandrea98',
          followers: 1135,
          engagementRate: 6.369426751592357
        },
        youtubeProfile: {
          handle: 'chaniceandrea',
          followers: 16
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T19:33:36.000Z',
        amountCents: 7730
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '29e68f1b-b078-40f3-bfe5-a6a07b4453f5',
      content: [
        {
          groupId: 'ce960033-7fd7-48bf-b015-f4ac5a96ec4f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T09:41:18.373Z',

          createdAt: '2024-05-29T18:06:41.293Z',
          content: [
            {
              caption: '',

              id: '3776bc7e-0dc1-4c83-8cf0-2f7671a22dac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29e68f1b-b078-40f3-bfe5-a6a07b4453f5/ugc/ce960033-7fd7-48bf-b015-f4ac5a96ec4f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3ebc869b-e3e5-4d49-ab31-be4877402151',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29e68f1b-b078-40f3-bfe5-a6a07b4453f5/ugc/ce960033-7fd7-48bf-b015-f4ac5a96ec4f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '64bae5a0-0a9a-42d9-ab73-386e02921249',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29e68f1b-b078-40f3-bfe5-a6a07b4453f5/ugc/ce960033-7fd7-48bf-b015-f4ac5a96ec4f/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '23266374-35d2-4607-b9c2-2ce6dc63f5e8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:36:28.792Z',

          createdAt: '2024-05-31T00:39:46.613Z',
          content: [
            {
              caption: '',

              id: '41137b30-f941-45fc-9ed0-7713bf19ca09',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29e68f1b-b078-40f3-bfe5-a6a07b4453f5/ugc/23266374-35d2-4607-b9c2-2ce6dc63f5e8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4f4b2bfe-56db-4952-beed-44a8dcdb1fbb',
        firstName: 'Sylvia',
        lastName: 'Flanagan',
        email: 'anointedtouch.sf@gmail.com',
        instagramProfile: {
          handle: 'courageously_you_girl',
          followers: 1656,
          engagementRate: 0.71256038647343
        },
        tiktokProfile: {
          handle: 'courageously_you_girl',
          followers: 121,
          engagementRate: 4.869775066023132
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T19:44:58.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6d98be0a-1f28-471f-be3c-8a603abe6af9',
      content: [],
      creator: {
        id: '3a9cbf77-7e31-467d-94cb-8c8070ae20f9',
        firstName: 'Rachel',
        lastName: 'Glover',
        email: 'rnglover32@gmail.com',
        instagramProfile: {
          handle: 'justsimplyrachelg',
          followers: 551,
          engagementRate: 0.16333938294010888
        },
        tiktokProfile: {
          handle: 'justsimplyrachel_g',
          followers: 2075,
          engagementRate: 6.154591023012075
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T19:52:46.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e84a9f74-7c06-4a03-a3c2-137ce54806c0',
      content: [],
      creator: {
        id: '6c5cba95-9e78-408d-b360-624fcea2d5df',
        firstName: 'Razan',
        lastName: 'Khazil',
        email: 'khazilr@yahoo.com',

        tiktokProfile: {
          handle: 'razanalani',
          followers: 5082,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'razankhazil3528',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T20:02:36.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6c8f0e56-7e6a-49a2-9e01-0575587a5fb7',
      content: [
        {
          groupId: 'c6e5baa0-48d5-436b-86c9-71be302569bb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T00:05:58.449Z',

          createdAt: '2024-06-03T06:37:27.006Z',
          content: [
            {
              caption: '',

              id: 'd1ec05c9-3d3e-4f42-aca7-cc131b6acdba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6c8f0e56-7e6a-49a2-9e01-0575587a5fb7/ugc/c6e5baa0-48d5-436b-86c9-71be302569bb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fcdd5b35-450e-4f09-908b-a386985a515a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T00:06:06.441Z',

          createdAt: '2024-05-30T22:15:22.677Z',
          content: [
            {
              caption: '',

              id: 'c862d002-7654-4a17-a1f7-c49c100357f3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6c8f0e56-7e6a-49a2-9e01-0575587a5fb7/ugc/fcdd5b35-450e-4f09-908b-a386985a515a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2efdf910-0ea0-42e2-aa10-e728710efcb8',
        firstName: 'Vladyslava',
        lastName: 'Lisnykh',
        email: 'vbohashova.usa@gmail.com',
        instagramProfile: {
          handle: 'vlada_lisnykh_us',
          followers: 1163,
          engagementRate: 2.4161650902837493
        },
        tiktokProfile: {
          handle: 'lvlada_',
          followers: 244,
          engagementRate: 4.074074074074074
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T20:16:51.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ad1bfc6b-dcee-48dc-8d0b-6381edea796f',
      content: [
        {
          groupId: '618e14b8-b64b-4927-ab0e-aad5591a1539',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T17:42:10.357Z',

          createdAt: '2024-05-31T23:18:15.555Z',
          content: [
            {
              caption: '',

              id: 'a6e968e7-f8af-49da-bedf-32ed3018c790',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad1bfc6b-dcee-48dc-8d0b-6381edea796f/ugc/618e14b8-b64b-4927-ab0e-aad5591a1539/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e9d09ab6-c36d-4743-aa6b-a017c765607f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad1bfc6b-dcee-48dc-8d0b-6381edea796f/ugc/618e14b8-b64b-4927-ab0e-aad5591a1539/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd4ff4a48-f749-4798-9f29-e377a80fee02',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad1bfc6b-dcee-48dc-8d0b-6381edea796f/ugc/618e14b8-b64b-4927-ab0e-aad5591a1539/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5d115915-c0f3-4583-b8c2-11d1124ba03d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T17:42:02.552Z',

          createdAt: '2024-05-31T23:17:23.245Z',
          content: [
            {
              caption: '',

              id: '0ba6da27-9722-4425-89aa-bac9658dc957',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad1bfc6b-dcee-48dc-8d0b-6381edea796f/ugc/5d115915-c0f3-4583-b8c2-11d1124ba03d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '32b1c4e0-70f5-427c-8bdc-3af5741017f5',
        firstName: 'Tajera',
        lastName: 'Francis',
        email: 'chanderpaul.tajera@gmail.com',
        instagramProfile: {
          handle: 'tajj_era',
          followers: 2617,
          engagementRate: 1.180741306839893
        },
        tiktokProfile: {
          handle: 'tajj_era',
          followers: 1666,
          engagementRate: 7.335515699202361
        },
        youtubeProfile: {
          handle: 'frio_taj',
          followers: 18
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T20:41:28.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '188b629a-4f72-487d-a0e2-cfeb016d98e4',
      content: [
        {
          groupId: 'c792e6d9-7647-4db5-ae05-7826a87bf516',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:39:45.826Z',

          createdAt: '2024-06-01T21:32:16.206Z',
          content: [
            {
              caption: '',

              id: 'd8f10400-d4e7-4211-bf38-26f2645b08e9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/188b629a-4f72-487d-a0e2-cfeb016d98e4/ugc/c792e6d9-7647-4db5-ae05-7826a87bf516/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '25f9ca3b-c173-419a-b5cd-12d647157ce4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:39:39.855Z',

          createdAt: '2024-06-06T18:59:40.490Z',
          content: [
            {
              caption: '',

              id: '5e2eabac-9419-49bf-94d7-efa7f292d9d8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/188b629a-4f72-487d-a0e2-cfeb016d98e4/ugc/25f9ca3b-c173-419a-b5cd-12d647157ce4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ed8a36b7-0e70-4a18-8755-cf3bb2999dc5',
        firstName: 'Amber',
        lastName: 'Santos',
        email: 'amberlsantos1@gmail.com',
        instagramProfile: {
          handle: 'amberlsantos',
          followers: 1344,
          engagementRate: 0.9375
        },
        tiktokProfile: {
          handle: 'amberlsantos',
          followers: 4373,
          engagementRate: 1.9848991651368029
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T20:43:47.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7572ad03-b915-476e-80ba-654b9a1229fc',
      content: [
        {
          groupId: 'f1eb7949-7dd2-49db-9d34-fe535d81561f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-16T03:35:51.675Z',

          createdAt: '2024-06-02T03:20:22.226Z',
          content: [
            {
              caption: '',

              id: 'c36b683d-caa9-4827-a9f3-4ef1d36e2a8d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7572ad03-b915-476e-80ba-654b9a1229fc/ugc/f1eb7949-7dd2-49db-9d34-fe535d81561f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'df1ccb10-7ed5-44c6-ba68-ab2a866d4e84',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7572ad03-b915-476e-80ba-654b9a1229fc/ugc/f1eb7949-7dd2-49db-9d34-fe535d81561f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2ce8f9ee-471a-42d6-82fb-97ef391a8abc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7572ad03-b915-476e-80ba-654b9a1229fc/ugc/f1eb7949-7dd2-49db-9d34-fe535d81561f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8cb74624-b6bc-45e8-9db7-358e6b0d06fb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-16T03:35:47.617Z',

          createdAt: '2024-06-02T14:09:17.114Z',
          content: [
            {
              caption: '',

              id: '727b12bb-f541-4473-a0d5-ee80e3ed024b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7572ad03-b915-476e-80ba-654b9a1229fc/ugc/8cb74624-b6bc-45e8-9db7-358e6b0d06fb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5aabf53b-f0dd-4284-ba70-fb9732c5b94d',
        firstName: 'Kayla',
        lastName: 'Whitter',
        email: 'kaylakedavra@gmail.com',
        instagramProfile: {
          handle: 'kaylakedavra',
          followers: 1362,
          engagementRate: 1.2701908957415566
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T22:01:43.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b8181ce1-5914-44d9-8bc5-0a77275d36b8',
      content: [
        {
          groupId: 'fe75244c-ec9c-4a00-8693-cf15c3108a8b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T17:02:48.413Z',

          createdAt: '2024-06-01T03:22:51.215Z',
          content: [
            {
              caption: '',

              id: 'bc1ce24f-d849-436c-b2f3-f577e40cd7f1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b8181ce1-5914-44d9-8bc5-0a77275d36b8/ugc/fe75244c-ec9c-4a00-8693-cf15c3108a8b/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4c81a8ab-51d9-4ed3-b306-e3dcc77511fb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b8181ce1-5914-44d9-8bc5-0a77275d36b8/ugc/fe75244c-ec9c-4a00-8693-cf15c3108a8b/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '19288908-6ff2-4a3d-b372-57ece54deddf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b8181ce1-5914-44d9-8bc5-0a77275d36b8/ugc/fe75244c-ec9c-4a00-8693-cf15c3108a8b/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '743f0de7-1ad3-4e73-885b-0df58d69aa49',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:10:34.453Z',

          createdAt: '2024-06-01T03:19:45.358Z',
          content: [
            {
              caption: '',

              id: '2614c50c-9f11-4de1-aafb-17f4d8704292',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b8181ce1-5914-44d9-8bc5-0a77275d36b8/ugc/743f0de7-1ad3-4e73-885b-0df58d69aa49/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '921c5498-5a2e-4084-98ce-75541ca7e778',
        firstName: 'Ayda',
        lastName: 'Kalafat',
        email: 'aydaugc@gmail.com',
        instagramProfile: {
          handle: 'theaydasdiary',
          followers: 96672,
          engagementRate: 2.134537404832837
        },
        tiktokProfile: {
          handle: 'aydasdiary',
          followers: 480,
          engagementRate: 2.64797507788162
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T23:14:29.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a5d4cae5-5f1d-4448-8cbd-5251d9a2583d',
      content: [
        {
          groupId: '0d45544c-919c-4f6c-96f8-ede15711c88c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:38:30.310Z',

          createdAt: '2024-05-30T22:58:59.562Z',
          content: [
            {
              caption: '',

              id: '8d73ec6f-3f49-4d11-b2f4-468576d1087b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a5d4cae5-5f1d-4448-8cbd-5251d9a2583d/ugc/0d45544c-919c-4f6c-96f8-ede15711c88c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2eb893a6-2958-45ee-ada9-8ccfec751c21',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T14:00:13.883Z',

          createdAt: '2024-05-30T22:59:52.196Z',
          content: [
            {
              caption: '',

              id: '7066cb43-713e-4d3e-a736-0d12de324f39',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a5d4cae5-5f1d-4448-8cbd-5251d9a2583d/ugc/2eb893a6-2958-45ee-ada9-8ccfec751c21/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '198562e7-6891-45e4-8ee7-5b936315310e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a5d4cae5-5f1d-4448-8cbd-5251d9a2583d/ugc/2eb893a6-2958-45ee-ada9-8ccfec751c21/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8eb6cb6f-e628-4b2a-ad26-6bb53fc8e2b8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a5d4cae5-5f1d-4448-8cbd-5251d9a2583d/ugc/2eb893a6-2958-45ee-ada9-8ccfec751c21/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1eee5574-9801-483b-bb7d-a8e4cc4f1179',
        firstName: 'JIJI',
        lastName: 'ROD',
        email: 'reviewsbyjiji@gmail.com',

        tiktokProfile: {
          handle: 'jiji.rod',
          followers: 4194,
          engagementRate: 12.538226299694188
        },
        youtubeProfile: {
          handle: 'jijirod1047',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T23:26:32.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '57ba0497-74ff-4268-a335-a8fd97da5d83',
      content: [],
      creator: {
        id: '5a0d7f04-e45e-4fec-9885-fd07143159ff',
        firstName: 'Marian',
        lastName: 'Davis',
        email: 'marianwassefdavis@gmail.com',
        instagramProfile: {
          handle: 'marian.wassef.davis',
          followers: 646,
          engagementRate: 3.0030959752321977
        },
        tiktokProfile: {
          handle: 'marianwassefdavis',
          followers: 3435,
          engagementRate: 12.07989854153456
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T23:32:53.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7d9e1f8a-1ac6-4ad8-9c2b-b55262fc33b3',
      content: [
        {
          groupId: '6e0efb82-eb3b-4c78-894e-399702ead113',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-14T21:47:23.127Z',

          createdAt: '2024-06-12T21:56:30.617Z',
          content: [
            {
              caption: '',

              id: 'd6698c73-2a9a-4e86-9f29-f3817530c53c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d9e1f8a-1ac6-4ad8-9c2b-b55262fc33b3/ugc/6e0efb82-eb3b-4c78-894e-399702ead113/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4664c9ee-8023-4d8b-8f06-cbd1a55c93ec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d9e1f8a-1ac6-4ad8-9c2b-b55262fc33b3/ugc/6e0efb82-eb3b-4c78-894e-399702ead113/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0dc722bb-9d31-481c-94d8-b0c0a62de848',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d9e1f8a-1ac6-4ad8-9c2b-b55262fc33b3/ugc/6e0efb82-eb3b-4c78-894e-399702ead113/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f69535c8-3dbc-42ab-b95a-9f80c6f8fc0b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T16:19:19.776Z',

          createdAt: '2024-06-12T22:00:48.020Z',
          content: [
            {
              caption: '',

              id: '3be53f03-5646-4532-9cf1-23e44df74fdd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d9e1f8a-1ac6-4ad8-9c2b-b55262fc33b3/ugc/f69535c8-3dbc-42ab-b95a-9f80c6f8fc0b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '180e19c3-b9eb-4d06-a5b9-76dd72932ba2',
        firstName: 'Abigail',
        lastName: 'Fagan',
        email: 'abbyfagan97@gmail.com',

        tiktokProfile: {
          handle: 'dipped_in_hersheys',
          followers: 1146
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T23:58:17.000Z',
        amountCents: 20000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3bd65ebc-c5a2-4783-8a94-8d1bd51f4e50',
      content: [
        {
          groupId: '2612d137-7159-4337-a86f-b541b4780795',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-09T21:07:24.221Z',

          createdAt: '2024-05-30T20:51:44.128Z',
          content: [
            {
              caption: '',

              id: '438a0659-e297-4eed-b2f7-b4c0c48b2a2d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3bd65ebc-c5a2-4783-8a94-8d1bd51f4e50/ugc/2612d137-7159-4337-a86f-b541b4780795/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1b2e6b7b-32d4-428c-8c6b-654d705a294b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3bd65ebc-c5a2-4783-8a94-8d1bd51f4e50/ugc/2612d137-7159-4337-a86f-b541b4780795/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fc84f28b-b895-452b-8e64-d122bc5ec16e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3bd65ebc-c5a2-4783-8a94-8d1bd51f4e50/ugc/2612d137-7159-4337-a86f-b541b4780795/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'aaf329e1-11d8-4170-b2ce-a35059fc67ce',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-09T21:07:17.832Z',

          createdAt: '2024-05-30T20:51:12.982Z',
          content: [
            {
              caption: '',

              id: 'ac6dc62b-0f7b-4bff-9abc-b812b6db7c1f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3bd65ebc-c5a2-4783-8a94-8d1bd51f4e50/ugc/aaf329e1-11d8-4170-b2ce-a35059fc67ce/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cb6827ec-d744-48f2-aaa6-8793f425f2aa',
        firstName: 'Oleksandra',
        lastName: 'Savotchenko',
        email: 'oleksandrasavotcenko@gmail.com',
        instagramProfile: {
          handle: 'vperta_usa',
          followers: 7564,
          engagementRate: 4.826811210999471
        },
        tiktokProfile: {
          handle: 'vperta_usa_',
          followers: 3769,
          engagementRate: 3.0804595686334086
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-27T23:58:24.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f8e0d34c-6a80-42b8-b4b6-d6f14f600b66',
      content: [
        {
          groupId: '9ea4ac0b-4910-47d8-9d80-1cb243ce6136',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-04T04:06:05.648Z',

          createdAt: '2024-06-04T19:16:14.384Z',
          content: [
            {
              caption: '',

              id: 'b4b660da-1418-4da0-9e4a-03097b45f718',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f8e0d34c-6a80-42b8-b4b6-d6f14f600b66/ugc/9ea4ac0b-4910-47d8-9d80-1cb243ce6136/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6fdb844c-5edd-42e5-8ee8-c36007948e99',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f8e0d34c-6a80-42b8-b4b6-d6f14f600b66/ugc/9ea4ac0b-4910-47d8-9d80-1cb243ce6136/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '604e779a-a1b4-41c0-a4fd-34033ac919d8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f8e0d34c-6a80-42b8-b4b6-d6f14f600b66/ugc/9ea4ac0b-4910-47d8-9d80-1cb243ce6136/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '6ee31789-2126-4520-b302-24bcea9b97f6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T03:08:31.019Z',

          createdAt: '2024-07-02T18:01:41.371Z',
          content: [
            {
              caption: '',

              id: '0f0c310d-ace3-4692-b83a-bdb6af91008c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f8e0d34c-6a80-42b8-b4b6-d6f14f600b66/ugc/6ee31789-2126-4520-b302-24bcea9b97f6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '32a06d59-7122-4d3c-8ecd-a0205a4f0858',
        firstName: 'Joyce',
        lastName: 'Rosa',
        email: 'joycebareis@gmail.com',
        instagramProfile: {
          handle: 'joycereisrosa.95',
          followers: 5830,
          engagementRate: 0.8042691061558984
        },
        tiktokProfile: {
          handle: 'joycereis98',
          followers: 194,
          engagementRate: 3.0683320340304374
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T00:44:22.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cd9c3709-d0b5-4ef3-9327-eee1873ef481',
      content: [
        {
          groupId: '979d8c76-7096-4e50-b2dd-ee5458df5ace',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T22:52:17.683Z',

          createdAt: '2024-06-07T21:14:49.548Z',
          content: [
            {
              caption: '',

              id: 'c62dfafa-fa88-480c-b2d9-91d9cd210d32',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cd9c3709-d0b5-4ef3-9327-eee1873ef481/ugc/979d8c76-7096-4e50-b2dd-ee5458df5ace/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '5d715a4e-343a-42d6-9e23-86fda70c9985',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T20:53:12.379Z',

          createdAt: '2024-06-06T22:51:42.875Z',
          content: [
            {
              caption: '',

              id: '05fc21e7-a04a-4d23-bcd9-a64eaf208801',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cd9c3709-d0b5-4ef3-9327-eee1873ef481/ugc/5d715a4e-343a-42d6-9e23-86fda70c9985/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '72fafbcb-88b2-402d-b40f-f621759e244d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cd9c3709-d0b5-4ef3-9327-eee1873ef481/ugc/5d715a4e-343a-42d6-9e23-86fda70c9985/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3a4f8816-c672-42f7-b0da-c6f6c81c50da',
        firstName: 'Breana',
        lastName: 'Hart',
        email: 'br3ana.x.o@gmail.com',

        tiktokProfile: {
          handle: 'breana.x.o',
          followers: 1329,
          engagementRate: 6.837606837606838
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T00:51:47.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5bb9772e-311a-457b-8cdf-856f8aa2a596',
      content: [
        {
          groupId: 'cc9520c4-1cd1-434e-9243-4f52e1b17f9d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T09:59:18.739Z',

          createdAt: '2024-06-09T20:05:32.813Z',
          content: [
            {
              caption: '',

              id: '042068cc-d658-4017-b2ce-79f9a514df5e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5bb9772e-311a-457b-8cdf-856f8aa2a596/ugc/cc9520c4-1cd1-434e-9243-4f52e1b17f9d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '44252f63-7810-49e0-aee8-d8295ff75325',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T03:38:00.440Z',

          createdAt: '2024-06-05T23:13:07.036Z',
          content: [
            {
              caption: '',

              id: '9966a72f-7b05-473c-8142-66cc8c2f957b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5bb9772e-311a-457b-8cdf-856f8aa2a596/ugc/44252f63-7810-49e0-aee8-d8295ff75325/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ab0b40e8-35a1-47e0-acc9-f5eed248be7a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5bb9772e-311a-457b-8cdf-856f8aa2a596/ugc/44252f63-7810-49e0-aee8-d8295ff75325/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'cf866373-e15c-4ec1-83ec-cc6006392ded',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5bb9772e-311a-457b-8cdf-856f8aa2a596/ugc/44252f63-7810-49e0-aee8-d8295ff75325/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1fcb3803-5c6c-476c-bd10-d3c4e4d15f28',
        firstName: 'Tiffany',
        lastName: 'Gonzalez',
        email: 'tiffanygonzalez1992@gmail.com',
        instagramProfile: {
          handle: 'tiffanygonzalez155',
          followers: 110,
          engagementRate: 5.555555555555556
        },
        tiktokProfile: {
          handle: 'tiffanygonzalez2105',
          followers: 10679,
          engagementRate: 10.637095228971068
        },
        youtubeProfile: {
          handle: 'tiffanygonzalez5768'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T00:52:53.000Z',
        amountCents: 13779
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c3eaf50a-22d5-4433-ab5b-72a327101bfc',
      content: [
        {
          groupId: '7342243d-dfde-446b-a2b7-29f157cda238',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T17:01:37.200Z',

          createdAt: '2024-06-02T14:56:12.152Z',
          content: [
            {
              caption: '',

              id: 'd2498f88-834e-45f6-a3cf-ee13d39fbaac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c3eaf50a-22d5-4433-ab5b-72a327101bfc/ugc/7342243d-dfde-446b-a2b7-29f157cda238/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd12e58ed-7101-4557-9cba-bea9447ffb00',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T17:01:42.942Z',

          createdAt: '2024-06-02T14:56:48.328Z',
          content: [
            {
              caption: '',

              id: '665be0a5-fb2b-4fb0-afc5-834ba59b1589',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c3eaf50a-22d5-4433-ab5b-72a327101bfc/ugc/d12e58ed-7101-4557-9cba-bea9447ffb00/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '54e19c3c-1530-49d7-9649-77cee71c4562',
        firstName: 'Chrissyann',
        lastName: 'Taylor',
        email: 'chrissytaylor59@icloud.com',

        tiktokProfile: {
          handle: 'chrissyann4',
          followers: 1153,
          engagementRate: 17.344173441734416
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T00:59:15.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd8f30ec1-b1af-4ae1-b970-168c98f4bc86',
      content: [],
      creator: {
        id: '1e379880-f39f-4964-9d44-1ea013a03f25',
        firstName: 'Brianna',
        lastName: 'Sampson',
        email: 'brianna54464@gmail.com',
        instagramProfile: {
          handle: 'bree_yun_aa',
          followers: 1712,
          engagementRate: 1.8496884735202495
        },
        tiktokProfile: {
          handle: 'blasianbarbz0603',
          followers: 496,
          engagementRate: 5.612244897959184
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T02:33:41.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '04581c9e-6aaa-4631-86ea-c0e4962c956f',
      content: [],
      creator: {
        id: '933989c3-9d6c-4027-b63b-699b9bbe4258',
        firstName: 'Alannah',
        lastName: 'Ratliff',
        email: 'lannahratliff@gmail.com',

        tiktokProfile: {
          handle: 'alannahrcontentcreator',
          followers: 1072
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T04:05:13.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '81707f4f-d311-4240-8253-fb27d03cdf03',
      content: [
        {
          groupId: '709a7df0-7d98-4fb6-84b0-3c5a4cd49509',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-01T20:46:37.432Z',

          createdAt: '2024-05-30T17:55:02.917Z',
          content: [
            {
              caption: '',

              id: '756399e1-bba2-4aa3-8160-8401352e5860',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/81707f4f-d311-4240-8253-fb27d03cdf03/ugc/709a7df0-7d98-4fb6-84b0-3c5a4cd49509/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a058e405-7947-4400-8c15-6e6148afa972',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/81707f4f-d311-4240-8253-fb27d03cdf03/ugc/709a7df0-7d98-4fb6-84b0-3c5a4cd49509/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '63cbecd8-873f-4684-bf12-23a28f8c4149',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/81707f4f-d311-4240-8253-fb27d03cdf03/ugc/709a7df0-7d98-4fb6-84b0-3c5a4cd49509/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'aeb566d0-e22e-4e15-a23c-a883d9442603',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T15:26:11.423Z',

          createdAt: '2024-05-30T17:54:14.191Z',
          content: [
            {
              caption: '',

              id: 'a612046b-da0d-44b2-ad10-baabeb45ea9e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/81707f4f-d311-4240-8253-fb27d03cdf03/ugc/aeb566d0-e22e-4e15-a23c-a883d9442603/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7eec2a76-85b0-42de-9d1e-1382eb1327cc',
        firstName: 'Mikayla',
        lastName: 'Seidel',
        email: 'mikaylaseidel512@gmail.com',

        tiktokProfile: {
          handle: 'mikaylaseidell',
          followers: 1108,
          engagementRate: 9.375
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T12:06:32.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '014f06e3-bf28-4028-8cd8-adb232283c53',
      content: [
        {
          groupId: '146db4ac-bd0c-4eed-857b-9425ff18bd10',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T11:11:17.974Z',

          createdAt: '2024-06-05T19:51:12.981Z',
          content: [
            {
              caption: '',

              id: 'cc7e61a5-f1be-4a66-8fc3-82cead808c4a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/014f06e3-bf28-4028-8cd8-adb232283c53/ugc/146db4ac-bd0c-4eed-857b-9425ff18bd10/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '138207e6-27d0-43bf-af81-ce1a9f69844a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T11:34:12.816Z',

          createdAt: '2024-06-05T19:51:53.314Z',
          content: [
            {
              caption: '',

              id: '92611a7c-5bc1-4986-b3bf-69e7b590822c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/014f06e3-bf28-4028-8cd8-adb232283c53/ugc/138207e6-27d0-43bf-af81-ce1a9f69844a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '525c3912-9f7d-4c78-80ee-3f6d7471e040',
        firstName: 'Alli',
        lastName: 'Johnson',
        email: 'allisonkayjohnson@gmail.com',

        tiktokProfile: {
          handle: 'alllikay',
          followers: 5097,
          engagementRate: 6.316040952234007
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T12:52:30.000Z',
        amountCents: 7384
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '48254114-899b-45a0-852f-8e8c119d793b',
      content: [],
      creator: {
        id: 'a13106df-c14b-49eb-b7a8-7d2dc1125bf9',
        firstName: 'Kimberly',
        lastName: 'Permenter',
        email: 'kimberlypermenter24@gmail.com',

        tiktokProfile: {
          handle: 'kimberly.permenter',
          followers: 3538,
          engagementRate: 13.768472321216441
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T12:53:42.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '077b63a7-9c82-4b1d-a2a0-f47989e6c789',
      content: [],
      creator: {
        id: 'daa373a2-511b-4d6f-b8ca-477a4ad147aa',
        firstName: 'Tenisha',
        lastName: 'James',
        email: 'mrs.brumfield1993@icloud.com',
        instagramProfile: {
          handle: 'baddgalnoona.9',
          followers: 2970,
          engagementRate: 0.8754208754208754
        },
        tiktokProfile: {
          handle: 'baddgalnoona.9',
          followers: 32,
          engagementRate: 7.4074074074074066
        },
        youtubeProfile: {
          handle: 'tenishajames8846',
          followers: 31
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T13:15:24.000Z',
        amountCents: 7660
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a60fcc42-8d93-4de2-ab7a-f8fe3e856aee',
      content: [
        {
          groupId: '9dac51aa-46f1-4977-8a2d-896dcd19b100',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-11T14:39:58.361Z',

          createdAt: '2024-06-07T02:18:33.007Z',
          content: [
            {
              caption: '',

              id: 'f9b03475-98bc-429e-a62c-88c2b6677aa6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a60fcc42-8d93-4de2-ab7a-f8fe3e856aee/ugc/9dac51aa-46f1-4977-8a2d-896dcd19b100/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ad7e571d-c349-4176-bb5f-744b701e9325',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a60fcc42-8d93-4de2-ab7a-f8fe3e856aee/ugc/9dac51aa-46f1-4977-8a2d-896dcd19b100/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2cdc8b11-7b0a-497f-9409-fa337e05313a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a60fcc42-8d93-4de2-ab7a-f8fe3e856aee/ugc/9dac51aa-46f1-4977-8a2d-896dcd19b100/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b56ac763-85c6-4fe5-8c3e-995c2fe183da',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T14:39:49.862Z',

          createdAt: '2024-06-08T01:22:46.100Z',
          content: [
            {
              caption: '',

              id: '9c4be9d3-66ee-4633-bd14-3dedded58e2d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a60fcc42-8d93-4de2-ab7a-f8fe3e856aee/ugc/b56ac763-85c6-4fe5-8c3e-995c2fe183da/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7835dedf-2923-4c4b-9f7b-acb3b1845d96',
        firstName: 'Clara- Ann',
        lastName: 'Smith',
        email: 'smithannclara@gmail.com',
        instagramProfile: {
          handle: 'lippiesbycece',
          followers: 137,
          engagementRate: 9.124087591240876
        },
        tiktokProfile: {
          handle: 'ceceloves3',
          followers: 1460,
          engagementRate: 12.333778710949925
        },
        youtubeProfile: {
          handle: 'missunbotheredcece',
          followers: 30
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T13:24:05.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '29cc1917-87b3-4c82-aa14-14e12210dfbd',
      content: [
        {
          groupId: 'f184246e-2d14-45d0-9fa6-8ee07393cb8f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-14T13:12:57.580Z',

          createdAt: '2024-06-09T18:02:37.308Z',
          content: [
            {
              caption: '',

              id: '2154acbc-8944-40ac-8193-dab8fa8ce79f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29cc1917-87b3-4c82-aa14-14e12210dfbd/ugc/f184246e-2d14-45d0-9fa6-8ee07393cb8f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '73c6aa09-46af-4864-be30-c8caedb7acf4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29cc1917-87b3-4c82-aa14-14e12210dfbd/ugc/f184246e-2d14-45d0-9fa6-8ee07393cb8f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '98885506-3bc0-4ec2-8a5d-766aef76fbee',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29cc1917-87b3-4c82-aa14-14e12210dfbd/ugc/f184246e-2d14-45d0-9fa6-8ee07393cb8f/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f6ce8cc0-4f35-4c80-b153-4c099aacefd6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T13:12:52.847Z',

          createdAt: '2024-06-09T17:37:56.454Z',
          content: [
            {
              caption: '',

              id: 'c12e50be-d85b-436b-b0b5-5b50c4fa1375',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29cc1917-87b3-4c82-aa14-14e12210dfbd/ugc/f6ce8cc0-4f35-4c80-b153-4c099aacefd6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '682e74a7-e555-460c-b8ae-f6031f8027f8',
        firstName: 'Cameron',
        lastName: 'Monette',
        email: 'cameronmonette3@gmail.com',
        instagramProfile: {
          handle: 'cmonette99',
          followers: 2989,
          engagementRate: 5.408720865395338
        },
        tiktokProfile: {
          handle: 'cameronmonette',
          followers: 3167,
          engagementRate: 3.6741214057507987
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T13:39:22.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c63944e4-1e3f-4131-a795-291c622a9628',
      content: [
        {
          groupId: '83a39c32-c57f-414d-934a-0095f18b0c9e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T18:14:33.268Z',

          createdAt: '2024-05-31T01:41:05.215Z',
          content: [
            {
              caption: '',

              id: 'a5a5b4a6-13bc-439e-a949-2491ed65b7bb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c63944e4-1e3f-4131-a795-291c622a9628/ugc/83a39c32-c57f-414d-934a-0095f18b0c9e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5b878018-5d8c-4b1c-9228-865770f04d00',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c63944e4-1e3f-4131-a795-291c622a9628/ugc/83a39c32-c57f-414d-934a-0095f18b0c9e/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8e936836-d50e-48ea-bc29-9833d0bcde65',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c63944e4-1e3f-4131-a795-291c622a9628/ugc/83a39c32-c57f-414d-934a-0095f18b0c9e/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '70a8b686-0924-4fad-b6d2-184cbdcf092e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:23:13.988Z',

          createdAt: '2024-05-31T01:35:22.626Z',
          content: [
            {
              caption: '',

              id: 'b5564930-f6ee-46ea-9c8f-67f090477aef',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c63944e4-1e3f-4131-a795-291c622a9628/ugc/70a8b686-0924-4fad-b6d2-184cbdcf092e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '62e96a58-c235-4ecc-b91b-ac4fee58968a',
        firstName: 'Alexis',
        lastName: 'Brandon',
        email: 'alexisanea43@gmail.com',
        instagramProfile: {
          handle: 'influenced_by_lex',
          followers: 1790,
          engagementRate: 2.1564245810055866
        },
        tiktokProfile: {
          handle: 'illest_lexx_alive',
          followers: 683,
          engagementRate: 7.194244604316546
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T14:02:08.000Z',
        amountCents: 7054
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1e582ca5-cffd-4e04-92de-08d155667a6f',
      content: [
        {
          groupId: 'b73c4348-7370-4173-8e8e-36d7401c1531',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T17:14:23.937Z',

          createdAt: '2024-06-03T19:57:40.956Z',
          content: [
            {
              caption: '',

              id: '70747f8f-2b99-41cf-890d-b92794d790b2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1e582ca5-cffd-4e04-92de-08d155667a6f/ugc/b73c4348-7370-4173-8e8e-36d7401c1531/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'adb2e15d-b1e3-4191-a93e-79b538a196e6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1e582ca5-cffd-4e04-92de-08d155667a6f/ugc/b73c4348-7370-4173-8e8e-36d7401c1531/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a7986c84-9ed0-4ef3-bd1a-258be5a3d239',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:07:06.743Z',

          createdAt: '2024-06-05T17:14:16.903Z',
          content: [
            {
              caption: '',

              id: '8e349bbe-b33e-4793-8320-dd6a148af7f7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1e582ca5-cffd-4e04-92de-08d155667a6f/ugc/a7986c84-9ed0-4ef3-bd1a-258be5a3d239/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '00fe2fe5-4645-4cca-9f58-d3e110821238',
        firstName: 'Trinity',
        lastName: 'Woodbury',
        email: 'trinity.woodbury0@gmail.com',
        instagramProfile: {
          handle: 'trinitywoodbury',
          followers: 92909,
          engagementRate: 2.180628356779214
        },
        tiktokProfile: {
          handle: 'trinityymae',
          followers: 22965,
          engagementRate: 17.145642662585136
        },
        youtubeProfile: {
          handle: 'trinitywoodburyy',
          followers: 113
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:10:07.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8ece5810-46a3-45ad-9e5f-e131e2d31718',
      content: [],
      creator: {
        id: '206db654-23af-4a09-bb59-40623ebc1cff',
        firstName: 'Kirklyn',
        lastName: 'Hames',
        email: 'kirklynolivia@gmail.com',
        instagramProfile: {
          handle: '_kirklynolivia',
          followers: 1564
        },
        tiktokProfile: {
          handle: 'hitchinwiththehames',
          followers: 1075,
          engagementRate: 7.430340557275541
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:14:39.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dacaad6d-0451-4cb8-97d9-fc1da56a42e0',
      content: [
        {
          groupId: '7ece9155-d6a8-43da-8c52-204e62fbf6f9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T15:45:21.996Z',

          createdAt: '2024-06-03T00:27:19.229Z',
          content: [
            {
              caption: '',

              id: 'acea6c60-7d1e-4717-89cd-e8d01a45aebe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dacaad6d-0451-4cb8-97d9-fc1da56a42e0/ugc/7ece9155-d6a8-43da-8c52-204e62fbf6f9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '5b9b6500-2352-4334-a65d-9447092d3467',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T20:09:03.823Z',

          createdAt: '2024-06-03T00:29:19.796Z',
          content: [
            {
              caption: '',

              id: 'ac63db6a-ddc9-4d0e-a73b-ffd6a6d0085b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dacaad6d-0451-4cb8-97d9-fc1da56a42e0/ugc/5b9b6500-2352-4334-a65d-9447092d3467/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '71a8ddf2-07ff-4de7-b106-fa018bda94f7',
        firstName: 'Lisette',
        lastName: 'Molina',
        email: 'lisettem874@gmail.com',

        tiktokProfile: {
          handle: 'lizzylizzy1998',
          followers: 2476,
          engagementRate: 6.843623236946894
        },
        youtubeProfile: {
          handle: 'lisettegomez4381',
          followers: 23
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:14:48.000Z',
        amountCents: 7670
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '25666002-2574-47d2-b6ed-135596011c2a',
      content: [],
      creator: {
        id: 'd2425fe6-f91a-43b2-96a3-0783d503e609',
        firstName: 'Elizabeth ',
        lastName: 'Garcia ',
        email: 'emarieg86@yahoo.com',
        instagramProfile: {
          handle: '_elizabeth__garcia_',
          followers: 2531,
          engagementRate: 3.5401027261951796
        },
        tiktokProfile: {
          handle: '_elizabeth__garcia_',
          followers: 64
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:15:06.000Z',
        amountCents: 7629
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6a1d1e5e-4509-4cda-b50d-5c51f267d27a',
      content: [],
      creator: {
        id: '0fada899-1159-477b-a3c2-3e19a078a04d',
        firstName: 'Emily',
        lastName: 'Holland',
        email: 'emily.katherine92@gmail.com',

        tiktokProfile: {
          handle: 'emily_luna92',
          followers: 837,
          engagementRate: 10.311912353854959
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:17:49.000Z',
        amountCents: 7384
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c3330815-934c-4efe-be42-0ddaa1a0d647',
      content: [],
      creator: {
        id: '60d3fda7-6b50-4b05-b946-4b815725e829',
        firstName: 'Valeria ',
        lastName: 'Irizarry ',
        email: 'vnicole18@gmail.com',
        instagramProfile: {
          handle: 'valeayee',
          followers: 1209,
          engagementRate: 3.1430934656741107
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:20:45.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9f142ee4-2c03-46de-afd5-46daef56653e',
      content: [
        {
          groupId: '59da2d6b-454d-4341-a176-42b204f5c191',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T19:05:42.406Z',

          createdAt: '2024-06-11T03:02:53.368Z',
          content: [
            {
              caption: '',

              id: '0a4aaab9-186f-4f8e-85b6-2caf0c310478',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9f142ee4-2c03-46de-afd5-46daef56653e/ugc/59da2d6b-454d-4341-a176-42b204f5c191/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0b685ec3-4737-4d5f-821f-495251a3c2cd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9f142ee4-2c03-46de-afd5-46daef56653e/ugc/59da2d6b-454d-4341-a176-42b204f5c191/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b0936775-01a6-406c-93c7-d79da8827d27',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9f142ee4-2c03-46de-afd5-46daef56653e/ugc/59da2d6b-454d-4341-a176-42b204f5c191/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '21ab73f4-a2d2-4988-a932-6997bf8a9d8d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T19:05:37.090Z',

          createdAt: '2024-06-11T03:02:12.737Z',
          content: [
            {
              caption: '',

              id: 'e3d71f2b-1768-4c32-8ce6-6980991c3f20',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9f142ee4-2c03-46de-afd5-46daef56653e/ugc/21ab73f4-a2d2-4988-a932-6997bf8a9d8d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7d1b1bb4-4bce-421e-b6cc-899796a6f317',
        firstName: 'Aron',
        lastName: 'Gonzalez',
        email: 'arongonzalez530@yahoo.com',

        tiktokProfile: {
          handle: 'arongonzalez530',
          followers: 4679,
          engagementRate: 16.26236798221234
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:22:15.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '573af909-f4bb-49d9-9b4e-972e929758a5',
      content: [
        {
          groupId: '2ba60ce8-b3ff-4396-86ef-349b529bd2be',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T10:56:20.908Z',

          createdAt: '2024-06-01T19:52:12.065Z',
          content: [
            {
              caption: '',

              id: 'db66cf3c-58e4-485d-9eec-4ce1e849cd9f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/573af909-f4bb-49d9-9b4e-972e929758a5/ugc/2ba60ce8-b3ff-4396-86ef-349b529bd2be/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '90ac5f4b-33b3-4f4a-b860-ae4a9808d78f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T21:54:48.824Z',

          createdAt: '2024-06-01T19:46:41.478Z',
          content: [
            {
              caption: '',

              id: 'ea8b5e0a-cef7-4ac4-aca7-2940a4e13494',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/573af909-f4bb-49d9-9b4e-972e929758a5/ugc/90ac5f4b-33b3-4f4a-b860-ae4a9808d78f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '937f6085-21c0-4a03-817b-ff85b351e932',
        firstName: 'Jamila',
        lastName: 'Wilson',
        email: 'jamilawilson2020@gmail.com',
        instagramProfile: {
          handle: 'everythingjamila_',
          followers: 755,
          engagementRate: 4.039735099337748
        },
        tiktokProfile: {
          handle: 'everythingjamila_',
          followers: 2410,
          engagementRate: 11.1731843575419
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:27:25.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '401ba838-6c5e-4ba0-9a94-e1e0fdfb224d',
      content: [],
      creator: {
        id: 'f9353206-0122-4723-8cbb-f31a588d5cb8',
        firstName: 'Tennille',
        lastName: 'Nagel',
        email: 'tennillenagel@hotmail.com',
        instagramProfile: {
          handle: 'nagelacres',
          followers: 60,
          engagementRate: 14.166666666666666
        },
        tiktokProfile: {
          handle: 'nagelacres',
          followers: 1042,
          engagementRate: 11.068702290076336
        },
        youtubeProfile: {
          handle: 'tennillenagel1'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:31:13.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6d55bad1-876f-4a67-b9c9-630f715f8dbd',
      content: [],
      creator: {
        id: '4269263c-61cf-418e-ac14-58a00d071cd1',
        firstName: 'Denise',
        lastName: 'Frutos',
        email: 'denisefrutos88@gmail.com',
        instagramProfile: {
          handle: 'princessaofdarkness_',
          followers: 1016,
          engagementRate: 4.295740561471442
        },
        tiktokProfile: {
          handle: 'extrapickles7',
          followers: 485,
          engagementRate: 0.411522633744856
        },
        youtubeProfile: {
          handle: 'denisefrutos',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:32:48.000Z',
        amountCents: 19900
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '833e4df7-b29b-4fac-b38d-2269e4fd219c',
      content: [
        {
          groupId: 'cf737da9-158a-4f13-869d-867564a55765',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T01:33:20.230Z',

          createdAt: '2024-06-03T21:54:30.335Z',
          content: [
            {
              caption: '',

              id: 'a8c33234-b5ba-4195-8894-6f1a898cbbe2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/833e4df7-b29b-4fac-b38d-2269e4fd219c/ugc/cf737da9-158a-4f13-869d-867564a55765/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '60b3960d-14de-47ec-bae3-2b584a3261bc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T01:33:15.525Z',

          createdAt: '2024-06-03T21:41:54.225Z',
          content: [
            {
              caption: '',

              id: 'a65688d7-998a-4795-8147-ed9f2d408877',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/833e4df7-b29b-4fac-b38d-2269e4fd219c/ugc/60b3960d-14de-47ec-bae3-2b584a3261bc/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0ae76c42-e019-4789-aca7-46262ac38f15',
        firstName: 'Liz',
        lastName: 'Spire',
        email: 'liz.padilla4576@gmail.com',

        tiktokProfile: {
          handle: 'meximomma00',
          followers: 5444,
          engagementRate: 9.248554913294797
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:33:22.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cb44bbde-c651-40bc-af59-bcef494d5eea',
      content: [
        {
          groupId: '0017a2bf-399d-44e1-b5c2-72d8ac0c749c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T14:37:09.310Z',

          createdAt: '2024-06-18T01:04:58.520Z',
          content: [
            {
              caption: '',

              id: 'c407784a-3ec7-4db6-bfde-6403e266873e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cb44bbde-c651-40bc-af59-bcef494d5eea/ugc/0017a2bf-399d-44e1-b5c2-72d8ac0c749c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4cddcd17-9900-44fa-9cf4-748264876e2b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T14:37:03.872Z',

          createdAt: '2024-06-18T01:06:31.718Z',
          content: [
            {
              caption: '',

              id: 'c81a4212-73da-4950-9c19-3ae9acd8d5ed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cb44bbde-c651-40bc-af59-bcef494d5eea/ugc/4cddcd17-9900-44fa-9cf4-748264876e2b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5baaddf7-b303-4291-9655-e58f4fce1178',
        firstName: 'Britany',
        lastName: 'Peart',
        email: 'aikenbrittanny2023@gmail.com',
        instagramProfile: {
          handle: 'briibeautysupplies',
          followers: 60,
          engagementRate: 38.333333333333336
        },
        tiktokProfile: {
          handle: 'lareinabrii',
          followers: 364,
          engagementRate: 2.3090586145648313
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:42:21.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bb876fa6-5f9e-4333-b239-3ea2e24a3686',
      content: [],
      creator: {
        id: 'c556fcc1-83e7-4b39-92b6-455ce6b4d040',
        firstName: 'Jenny',
        lastName: 'Cruz',
        email: 'jennycruz479@yahoo.com',
        instagramProfile: {
          handle: 'yeseniaa_j',
          followers: 2976,
          engagementRate: 0.228494623655914
        },
        tiktokProfile: {
          handle: 'jenny_yesi',
          followers: 697,
          engagementRate: 5.080558539205156
        },
        youtubeProfile: {
          handle: 'jennycruz479',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T16:47:28.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '61522848-e31c-4441-8672-6b652a574a3a',
      content: [],
      creator: {
        id: 'b66090f8-ff77-45ac-adb8-98ade5630f11',
        firstName: 'Vanasa',
        lastName: 'Palmer',
        email: 'vanasa.vp@gmail.com',
        instagramProfile: {
          handle: 'vanasa_p',
          followers: 365
        },
        tiktokProfile: {
          handle: 'nasavp',
          followers: 1010,
          engagementRate: 15.780141843971629
        },
        youtubeProfile: {
          handle: 'vanasapalmer221',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T17:23:44.000Z',
        amountCents: 11770
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dcda77ce-85da-4de3-843a-908f5f4432d2',
      content: [],
      creator: {
        id: 'cd58cbaa-9b3f-440a-8d2e-d117f2be3ab2',
        firstName: 'Laura',
        lastName: 'Campbell',
        email: 'laura.lakovic@gmail.com',
        instagramProfile: {
          handle: 'marvelousmrscampbell',
          followers: 998,
          engagementRate: 1.182364729458918
        },
        tiktokProfile: {
          handle: 'ttshopfindswithlaura',
          followers: 1792,
          engagementRate: 5.259252673906502
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T17:25:03.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a00785b3-27fa-4226-b09a-b0ffadcae298',
      content: [
        {
          groupId: '84faa11a-37e3-44dc-8fc5-b7883c72aa2a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T05:50:57.144Z',

          createdAt: '2024-06-02T03:32:23.340Z',
          content: [
            {
              caption: '',

              id: '820fa23a-f898-4d71-9a52-62391b8313d2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a00785b3-27fa-4226-b09a-b0ffadcae298/ugc/84faa11a-37e3-44dc-8fc5-b7883c72aa2a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '24c33329-7168-4795-8171-8afbff6a0064',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T05:50:41.519Z',

          createdAt: '2024-06-02T03:31:55.559Z',
          content: [
            {
              caption: '',

              id: 'ab6d3808-c0e4-4f71-99f1-feafd4678b84',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a00785b3-27fa-4226-b09a-b0ffadcae298/ugc/24c33329-7168-4795-8171-8afbff6a0064/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3438a2b9-9ec5-4857-a285-06692463aee2',
        firstName: 'Kimberly ',
        lastName: 'Mera',
        email: 'merakimberly401@gmail.com',

        tiktokProfile: {
          handle: 'kimberly.michellee',
          followers: 42189,
          engagementRate: 6.977892035249475
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T17:25:16.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5bf9e2d5-353e-43f7-9c02-7eba23164ad8',
      content: [
        {
          groupId: 'b3e05623-10e5-4124-bf02-c0b1a35124a7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T09:30:59.577Z',

          createdAt: '2024-06-09T03:01:57.736Z',
          content: [
            {
              caption: '',

              id: 'ea5fd399-02b7-47bc-8fb6-bf12755b6db1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5bf9e2d5-353e-43f7-9c02-7eba23164ad8/ugc/b3e05623-10e5-4124-bf02-c0b1a35124a7/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '82bffd94-d859-4dc4-9f0e-d442d519f784',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5bf9e2d5-353e-43f7-9c02-7eba23164ad8/ugc/b3e05623-10e5-4124-bf02-c0b1a35124a7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8fbbb053-87d2-48c7-acfb-7bf6b2f17ef5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T09:30:52.509Z',

          createdAt: '2024-06-09T03:05:15.520Z',
          content: [
            {
              caption: '',

              id: 'aeaeb830-9ade-45c0-b2df-f578ecf074fd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5bf9e2d5-353e-43f7-9c02-7eba23164ad8/ugc/8fbbb053-87d2-48c7-acfb-7bf6b2f17ef5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6547d6b4-a374-4987-9b29-a3aec9723654',
        firstName: 'Pauriany',
        lastName: 'Segarra',
        email: 'pauriany@gmail.com',
        instagramProfile: {
          handle: 'pauriany',
          followers: 9972,
          engagementRate: 1.307661452065784
        },
        tiktokProfile: {
          handle: 'pauriany_',
          followers: 304,
          engagementRate: 0.7385524372230428
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T17:42:25.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7d78ad67-c5df-4a22-8804-ec5fca5e3c83',
      content: [
        {
          groupId: 'c730f5c9-3d9d-4521-aeeb-a71350f3fa39',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T14:13:14.925Z',

          createdAt: '2024-06-02T20:43:47.435Z',
          content: [
            {
              caption: '',

              id: '8b1077a7-c87c-4a0c-aaad-49c0296c811c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d78ad67-c5df-4a22-8804-ec5fca5e3c83/ugc/c730f5c9-3d9d-4521-aeeb-a71350f3fa39/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e6e86dd1-de76-46e5-a490-1a5beb7a7341',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d78ad67-c5df-4a22-8804-ec5fca5e3c83/ugc/c730f5c9-3d9d-4521-aeeb-a71350f3fa39/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9316d6fe-363e-44d8-b078-51c20be4f4dc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7d78ad67-c5df-4a22-8804-ec5fca5e3c83/ugc/c730f5c9-3d9d-4521-aeeb-a71350f3fa39/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5a6e6551-59b3-41e7-ae7b-c2197ee7321d',
        firstName: 'Anna',
        lastName: 'Padgett',
        email: 'annapadgett2@gmail.com',
        instagramProfile: {
          handle: 'annapadgett',
          followers: 1976,
          engagementRate: 2.479757085020243
        },
        tiktokProfile: {
          handle: '.annapadgett',
          followers: 96887,
          engagementRate: 6.285499706552339
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T18:02:40.000Z',
        amountCents: 7678
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '06acedc2-cf80-4dbc-9282-0322fbcc8ae4',
      content: [],
      creator: {
        id: 'df06b01e-f789-461c-99c3-f40ce67c33b2',
        firstName: 'Chyna Maria',
        lastName: 'Sophia',
        email: 'mssanon@utica.edu',
        instagramProfile: {
          handle: 'chyna_maria_sophia',
          followers: 25009,
          engagementRate: 0.1867327762005678
        },
        tiktokProfile: {
          handle: 'chynamariasophia',
          followers: 14682,
          engagementRate: 1.1712419362264204
        },
        youtubeProfile: {
          handle: 'chynamariasophia',
          followers: 895
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T18:44:24.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4b460fa4-479a-4835-aa72-b94850e2738c',
      content: [],
      creator: {
        id: 'e8278fdd-f93f-490d-a1e7-8f4bf9ad378c',
        firstName: 'Sierra',
        lastName: 'Herd',
        email: 'sierraherdcontent@gmail.com',
        instagramProfile: {
          handle: 'sierra_herd',
          followers: 1991,
          engagementRate: 5.3016351358892795
        },
        tiktokProfile: {
          handle: 'sierradenaeee',
          followers: 3293,
          engagementRate: 5.948275862068965
        },
        youtubeProfile: {
          handle: 'sierradherd',
          followers: 87
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T19:11:48.000Z',
        amountCents: 7629
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '81bdc7d5-9571-4465-a19c-6baf8f4f49f7',
      content: [],
      creator: {
        id: '31bd8bf3-cf0e-4905-9778-bca1c1562823',
        firstName: 'Heather',
        lastName: 'Schumacher',
        email: 'heythurbaby@gmail.com',
        instagramProfile: {
          handle: 'heythurbaby',
          followers: 336,
          engagementRate: 2.6785714285714284
        },
        tiktokProfile: {
          handle: 'heythurbaby24',
          followers: 1111,
          engagementRate: 3.214285714285714
        },
        youtubeProfile: {
          handle: 'heythurbaby',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T19:13:10.000Z',
        amountCents: 7552
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '093afc54-f9a5-48d1-b8a5-5c55859e50ae',
      content: [
        {
          groupId: 'c66064c1-9379-4c03-91eb-85f68f182063',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T12:51:53.356Z',

          createdAt: '2024-06-03T10:52:52.109Z',
          content: [
            {
              caption: '',

              id: '195c7f51-c48d-4a0b-b1c3-ba0ecff67467',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/093afc54-f9a5-48d1-b8a5-5c55859e50ae/ugc/c66064c1-9379-4c03-91eb-85f68f182063/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '25d1038a-0090-4ec2-be1d-746f18533386',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T12:51:59.858Z',

          createdAt: '2024-06-03T22:48:59.718Z',
          content: [
            {
              caption: '',

              id: 'a45648e1-f192-4461-b76c-ecbfe545cdde',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/093afc54-f9a5-48d1-b8a5-5c55859e50ae/ugc/25d1038a-0090-4ec2-be1d-746f18533386/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e2a38a77-ec90-48f5-a9a1-7e677ba2d91f',
        firstName: 'Niki',
        lastName: 'Tolliver',
        email: 'girlwiththespidertattoo@gmail.com',
        instagramProfile: {
          handle: 'the_black_widow3',
          followers: 2888,
          engagementRate: 0.19736842105263158
        },
        tiktokProfile: {
          handle: 'theblackwidow06',
          followers: 857,
          engagementRate: 1.4263621551462262
        },
        youtubeProfile: {
          handle: 'spiderlily'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T19:26:02.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fd638de1-6b97-471c-92da-fa581a40c67a',
      content: [
        {
          groupId: '6f371f29-707b-4752-b4f8-4a62bd22d280',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T00:38:40.559Z',

          createdAt: '2024-05-31T21:12:09.919Z',
          content: [
            {
              caption: '',

              id: '6c60e33b-193e-4d91-bb8b-41cda05c0289',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fd638de1-6b97-471c-92da-fa581a40c67a/ugc/6f371f29-707b-4752-b4f8-4a62bd22d280/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '5fce6d75-57cf-41bb-89be-60f06d78f538',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T00:38:35.440Z',

          createdAt: '2024-05-31T21:03:55.449Z',
          content: [
            {
              caption: '',

              id: '7c2457f5-f39a-4c2d-893c-3d7060d6847f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fd638de1-6b97-471c-92da-fa581a40c67a/ugc/5fce6d75-57cf-41bb-89be-60f06d78f538/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '43091729-5e19-4147-ba3e-a2e5fe3991b6',
        firstName: 'Alyssa',
        lastName: 'Walker',
        email: 'alyssahodge19@gmail.com',
        instagramProfile: {
          handle: 'alyssa_walker191',
          followers: 1008
        },
        tiktokProfile: {
          handle: 'alyssahodge191',
          followers: 10840,
          engagementRate: 10.014160199803765
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T19:31:50.000Z',
        amountCents: 8118
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f2555f3c-4faf-411f-96e4-afde4add518b',
      content: [
        {
          groupId: '73879163-2456-49cd-b1dc-820ba7d2ce23',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T13:12:19.961Z',

          createdAt: '2024-06-02T18:30:19.949Z',
          content: [
            {
              caption: '',

              id: 'fb551cf0-49f4-4d30-b203-344c7af43bdb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f2555f3c-4faf-411f-96e4-afde4add518b/ugc/73879163-2456-49cd-b1dc-820ba7d2ce23/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e4f01c76-d724-4302-a3d1-27f044e9b0a1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f2555f3c-4faf-411f-96e4-afde4add518b/ugc/73879163-2456-49cd-b1dc-820ba7d2ce23/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd6305598-8ad8-429a-922f-366fcd121462',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f2555f3c-4faf-411f-96e4-afde4add518b/ugc/73879163-2456-49cd-b1dc-820ba7d2ce23/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '950eec0b-f39d-4ad2-920c-0042af86baf7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T13:12:15.055Z',

          createdAt: '2024-06-02T18:29:47.376Z',
          content: [
            {
              caption: '',

              id: '2dcdecb5-39eb-4385-90cb-a71d2bf3677c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f2555f3c-4faf-411f-96e4-afde4add518b/ugc/950eec0b-f39d-4ad2-920c-0042af86baf7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '305d3552-bc0c-44e4-992b-762b55174741',
        firstName: 'Joanna',
        lastName: 'Weeks',
        email: 'joannaweeks15@gmail.com',

        tiktokProfile: {
          handle: 'joanna_weeks1',
          followers: 9585,
          engagementRate: 15.3951367781155
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T19:39:53.000Z',
        amountCents: 10998
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '897e24c5-11c1-4aee-a1bb-81383e8bcdb5',
      content: [
        {
          groupId: '52e2699a-d841-42ed-b345-e4d4c57c93e0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T20:14:36.165Z',

          createdAt: '2024-06-22T19:32:15.494Z',
          content: [
            {
              caption: '',

              id: '78ec0aba-4956-443f-a203-e6314e94e692',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/897e24c5-11c1-4aee-a1bb-81383e8bcdb5/ugc/52e2699a-d841-42ed-b345-e4d4c57c93e0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a1e99616-4a3d-4a95-abb3-dd42e4947890',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-25T20:14:41.602Z',

          createdAt: '2024-06-16T04:48:45.210Z',
          content: [
            {
              caption: '',

              id: 'ef68bfe4-feff-401a-9a88-9908698f49e5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/897e24c5-11c1-4aee-a1bb-81383e8bcdb5/ugc/a1e99616-4a3d-4a95-abb3-dd42e4947890/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c83407f2-44ab-464a-bb4b-c47d719c3777',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/897e24c5-11c1-4aee-a1bb-81383e8bcdb5/ugc/a1e99616-4a3d-4a95-abb3-dd42e4947890/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9d51e0b0-d4b6-4e3b-b6ad-5318fb7dee15',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/897e24c5-11c1-4aee-a1bb-81383e8bcdb5/ugc/a1e99616-4a3d-4a95-abb3-dd42e4947890/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '55ed9dba-e844-459a-b76d-5c6883827818',
        firstName: 'Mahalia',
        lastName: 'Watson',
        email: 'collabwithhalia@gmail.com',
        instagramProfile: {
          handle: 'hali._.aa',
          followers: 1403,
          engagementRate: 2.851033499643621
        },
        tiktokProfile: {
          handle: 'hali._.a',
          followers: 4934,
          engagementRate: 14.782608695652174
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T20:12:46.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd7deabbf-de80-4a0e-8bd0-515b0f3ec22d',
      content: [
        {
          groupId: '96bc2196-a250-4f8b-92f7-71be82f54d27',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-22T19:21:58.329Z',

          createdAt: '2024-06-09T03:36:44.294Z',
          content: [
            {
              caption: '',

              id: 'e84537b5-79dc-456e-b8ef-4efa202febed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d7deabbf-de80-4a0e-8bd0-515b0f3ec22d/ugc/96bc2196-a250-4f8b-92f7-71be82f54d27/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b9f64a26-1bbf-4973-ba7a-33ba2e283aab',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T10:26:19.407Z',

          createdAt: '2024-06-09T03:36:16.983Z',
          content: [
            {
              caption: '',

              id: '3d9f39f7-ec46-4b35-a0f2-dcb590e93613',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d7deabbf-de80-4a0e-8bd0-515b0f3ec22d/ugc/b9f64a26-1bbf-4973-ba7a-33ba2e283aab/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c79eb6c7-a8a8-4d0b-8786-0f92c2b7a8bd',
        firstName: 'Monique',
        lastName: 'Duran',
        email: 'moniqueaduran56@gmail.com',
        instagramProfile: {
          handle: 'mo.mermaid_',
          followers: 1930
        },
        tiktokProfile: {
          handle: 'babymomo19',
          followers: 2046,
          engagementRate: 11.311458494557087
        },
        youtubeProfile: {
          handle: 'moniqueduran9847',
          followers: 38
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T20:31:58.000Z',
        amountCents: 7585
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '79be9929-bbca-4093-b9a2-3343b25b25b2',
      content: [
        {
          groupId: 'c5acfb77-150d-4398-8767-055cd8d5d8d3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T03:36:13.818Z',

          createdAt: '2024-06-05T22:42:46.446Z',
          content: [
            {
              caption: '',

              id: '7131e312-eefa-4561-8823-6d60fea51283',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/79be9929-bbca-4093-b9a2-3343b25b25b2/ugc/c5acfb77-150d-4398-8767-055cd8d5d8d3/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '89a8931e-880e-41f4-b07a-25d107e48759',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T04:48:26.120Z',

          createdAt: '2024-06-05T06:06:29.855Z',
          content: [
            {
              caption: '',

              id: 'b8dfb3de-01c0-4144-b7b2-6f09be46da4a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/79be9929-bbca-4093-b9a2-3343b25b25b2/ugc/89a8931e-880e-41f4-b07a-25d107e48759/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'da977de5-7978-4fb6-b937-618d467a26a6',
        firstName: 'Celeste',
        lastName: 'Godinez',
        email: 'celeste800@icloud.com',

        tiktokProfile: {
          handle: 'cellysobadd',
          followers: 1488,
          engagementRate: 12.021533745043996
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T20:33:14.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f6bbd12c-f9d4-4e2c-89a2-6602a1077548',
      content: [
        {
          groupId: '0fa483db-89b6-4704-80f4-62599c39566e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T20:20:49.172Z',

          createdAt: '2024-06-01T15:31:29.651Z',
          content: [
            {
              caption: '',

              id: '838d44cc-561e-4a9a-9f06-a2914528ffff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f6bbd12c-f9d4-4e2c-89a2-6602a1077548/ugc/0fa483db-89b6-4704-80f4-62599c39566e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '5eaa4db5-a40f-4ff8-807b-f0b5ac66815c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T14:36:38.603Z',

          createdAt: '2024-06-01T02:19:46.581Z',
          content: [
            {
              caption: '',

              id: '75c5c92c-5a25-4768-a7c0-b1e4bb149ad7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f6bbd12c-f9d4-4e2c-89a2-6602a1077548/ugc/5eaa4db5-a40f-4ff8-807b-f0b5ac66815c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '788e9309-8fb4-47eb-b751-cbf663a95579',
        firstName: 'Nonyelum',
        lastName: 'Okaro',
        email: 'nonyeoka3@gmail.com',
        instagramProfile: {
          handle: 'nonyelumoka',
          followers: 1941,
          engagementRate: 1.076764554353426
        },
        tiktokProfile: {
          handle: 'nonyelumokaro',
          followers: 274,
          engagementRate: 8.60655737704918
        },
        youtubeProfile: {
          handle: 'thenonyelumoka'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T20:33:44.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'be462948-ffe2-4149-ae16-d32cb38d1f24',
      content: [
        {
          groupId: '57c1bdac-f573-4efd-b6f3-66c1365cea88',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T07:13:38.066Z',

          createdAt: '2024-06-05T03:00:21.401Z',
          content: [
            {
              caption: '',

              id: '85f53cb5-9e81-4feb-83cf-1f8d38bb3f2e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/be462948-ffe2-4149-ae16-d32cb38d1f24/ugc/57c1bdac-f573-4efd-b6f3-66c1365cea88/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'df2571f8-b630-4950-9129-db0d81bf1055',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T07:13:43.697Z',

          createdAt: '2024-06-03T20:11:51.081Z',
          content: [
            {
              caption: '',

              id: '193f925d-66b3-449b-95fa-6649e79131ab',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/be462948-ffe2-4149-ae16-d32cb38d1f24/ugc/df2571f8-b630-4950-9129-db0d81bf1055/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0e058a7d-c90f-4073-8f7a-766bcbd7051c',
        firstName: 'Stephanie',
        lastName: 'Weston',
        email: 'smweston4@gmail.com',
        instagramProfile: {
          handle: 'sellwithsteph_',
          followers: 2421,
          engagementRate: 2.073523337463858
        },
        tiktokProfile: {
          handle: 'sellwithsteph_',
          followers: 672,
          engagementRate: 4.236127649440342
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T20:55:30.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '667f98db-6431-453b-bd9a-4f2e68936062',
      content: [
        {
          groupId: 'e55fcd4d-d964-4935-a885-633276259160',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T18:12:30.960Z',

          createdAt: '2024-06-01T14:44:54.925Z',
          content: [
            {
              caption: '',

              id: '693ca5f8-293c-467f-a411-aed784224f61',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/667f98db-6431-453b-bd9a-4f2e68936062/ugc/e55fcd4d-d964-4935-a885-633276259160/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c6e35121-be44-41f2-b3b7-fe45b53c1d69',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/667f98db-6431-453b-bd9a-4f2e68936062/ugc/e55fcd4d-d964-4935-a885-633276259160/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e405604f-87d2-4a28-8cd9-3634a0b87ec4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:12:26.288Z',

          createdAt: '2024-06-01T16:30:38.126Z',
          content: [
            {
              caption: '',

              id: '9c19bf93-706b-4605-80a8-4e51b0aa83ed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/667f98db-6431-453b-bd9a-4f2e68936062/ugc/e405604f-87d2-4a28-8cd9-3634a0b87ec4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c023cb0c-1fad-4257-a611-4adf8b0a1d69',
        firstName: 'Selen',
        lastName: 'Ayaz',
        email: 'selenugc@gmail.com',
        instagramProfile: {
          handle: 'selenayazs',
          followers: 129851,
          engagementRate: 2.2471139998921843
        },
        tiktokProfile: {
          handle: 'selenayazugc',
          followers: 2844,
          engagementRate: 2.030456852791878
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T22:59:54.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5998e880-d344-4ea3-9794-9d5e1dde9c7e',
      content: [
        {
          groupId: '52f239e0-7454-4ba5-af8b-78b8dc8ae001',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T15:46:36.348Z',

          createdAt: '2024-05-30T19:05:12.979Z',
          content: [
            {
              caption: '',

              id: '2943ae5c-4153-41ba-987b-e3dc241b3d05',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5998e880-d344-4ea3-9794-9d5e1dde9c7e/ugc/52f239e0-7454-4ba5-af8b-78b8dc8ae001/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '677ddad8-b200-4e0e-98da-bfa8623dd27f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T09:38:33.922Z',

          createdAt: '2024-05-30T19:03:38.725Z',
          content: [
            {
              caption: '',

              id: '63a25836-8200-4d3e-82ba-c0405011b4c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5998e880-d344-4ea3-9794-9d5e1dde9c7e/ugc/677ddad8-b200-4e0e-98da-bfa8623dd27f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5afc0727-263e-4744-8e12-8a723ace2e0b',
        firstName: 'Victoria',
        lastName: 'Kantor',
        email: 'vsouthard98@gmail.com',
        instagramProfile: {
          handle: 'tori_kantor',
          followers: 1484,
          engagementRate: 0.48517520215633425
        },
        tiktokProfile: {
          handle: 'torikantor',
          followers: 4377,
          engagementRate: 2.390587529976019
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T23:31:17.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '32e2ef84-97a8-489f-89b0-f9ed934de852',
      content: [
        {
          groupId: 'ab370e33-1897-4c40-b9d0-4344c272e98a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-11T13:07:34.937Z',

          createdAt: '2024-06-03T02:22:29.793Z',
          content: [
            {
              caption: '',

              id: '42384e47-716e-4614-b859-557690b54531',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/32e2ef84-97a8-489f-89b0-f9ed934de852/ugc/ab370e33-1897-4c40-b9d0-4344c272e98a/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'da47fc2e-9acf-4394-9d7a-310750dd14fd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/32e2ef84-97a8-489f-89b0-f9ed934de852/ugc/ab370e33-1897-4c40-b9d0-4344c272e98a/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1c3b7ca0-74e7-4d9c-b02a-7c301b5ef68d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/32e2ef84-97a8-489f-89b0-f9ed934de852/ugc/ab370e33-1897-4c40-b9d0-4344c272e98a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd9019093-0919-401e-a357-97719b7ec831',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-09T03:33:41.170Z',

          createdAt: '2024-06-03T19:19:57.778Z',
          content: [
            {
              caption: '',

              id: 'f080e61b-c7f3-4862-a9e0-9e2affac9c3f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/32e2ef84-97a8-489f-89b0-f9ed934de852/ugc/d9019093-0919-401e-a357-97719b7ec831/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '79248ba5-0347-4558-a05c-5a5e954f5943',
        firstName: 'Jaz',
        lastName: 'Valencia',
        email: 'jazminevalencia@gmail.com',
        instagramProfile: {
          handle: 'djjazzy',
          followers: 12176,
          engagementRate: 0.41967805519053875
        },
        tiktokProfile: {
          handle: 'jvagency',
          followers: 10659,
          engagementRate: 3.7785174120205745
        },
        youtubeProfile: {
          handle: 'jvagency',
          followers: 289
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T23:37:11.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b2353a85-a75a-4b12-add4-82d2384821f7',
      content: [
        {
          groupId: '3fe4818e-320d-45ad-a9d7-ed91003bf5da',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T22:22:05.425Z',

          createdAt: '2024-06-24T09:53:28.358Z',
          content: [
            {
              caption: '',

              id: '5bcfd203-9ad8-484e-aef8-12c1590299bf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b2353a85-a75a-4b12-add4-82d2384821f7/ugc/3fe4818e-320d-45ad-a9d7-ed91003bf5da/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b88d66a4-13e1-4e0a-9986-388ef2203f77',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T22:22:00.276Z',

          createdAt: '2024-06-24T09:52:51.087Z',
          content: [
            {
              caption: '',

              id: 'd9c05b9b-1e3c-4145-846b-9c02c32e6f08',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b2353a85-a75a-4b12-add4-82d2384821f7/ugc/b88d66a4-13e1-4e0a-9986-388ef2203f77/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e70e515e-b6bf-46cc-a1c4-7d262b4a0bd5',
        firstName: 'Steven',
        lastName: 'Karr Jr',
        email: 'skarr888@me.com',
        instagramProfile: {
          handle: 'stevedaboss',
          followers: 85099,
          engagementRate: 3.256207475998543
        },
        tiktokProfile: {
          handle: 'stevedaboss18',
          followers: 1281,
          engagementRate: 27.27272727272727
        },
        youtubeProfile: {
          handle: 'hikebeasthi'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-28T23:55:16.000Z',
        amountCents: 7329
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a4f3a92e-6aec-4109-823b-c51b27a58318',
      content: [
        {
          groupId: '78ca47af-14c9-41fd-aba5-74fa3e8b905d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T15:12:57.327Z',

          createdAt: '2024-06-03T13:16:19.155Z',
          content: [
            {
              caption: '',

              id: '267ef8a2-3883-4a69-a0a7-8d9d8586cac8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4f3a92e-6aec-4109-823b-c51b27a58318/ugc/78ca47af-14c9-41fd-aba5-74fa3e8b905d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '404bdb9a-8725-4da1-8405-3e3033d6787f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T10:49:56.444Z',

          createdAt: '2024-06-03T13:17:36.341Z',
          content: [
            {
              caption: '',

              id: '35cf40e1-7462-448d-8817-22414a8b98a3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4f3a92e-6aec-4109-823b-c51b27a58318/ugc/404bdb9a-8725-4da1-8405-3e3033d6787f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5964eedf-9271-4d13-9d88-07aa0fe89348',
        firstName: 'Denay',
        lastName: 'Dominic',
        email: 'mydivinebeautyobsession@gmail.com',
        instagramProfile: {
          handle: 'mydivinebeautyobsession',
          followers: 477,
          engagementRate: 0.3983228511530398
        },
        tiktokProfile: {
          handle: 'thesacredlotustemple',
          followers: 4260,
          engagementRate: 2.2602397602397604
        },
        youtubeProfile: {
          handle: 'mydivinebeautyobsession',
          followers: 17
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T01:15:28.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cc2bc14a-8ab2-4b27-8cb2-d853f82178f0',
      content: [],
      creator: {
        id: '126a29fe-9fff-4230-98c9-ece2b8c0069a',
        firstName: 'Ariana',
        lastName: 'Bradley',
        email: 'a.brad0716@gmail.com',
        instagramProfile: {
          handle: 'ariana.bradleyy',
          followers: 1177
        },
        tiktokProfile: {
          handle: 'glowaesthet1c',
          followers: 2811,
          engagementRate: 6.97211155378486
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T01:23:45.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd047dd5e-3935-4655-900c-beb524adb82a',
      content: [
        {
          groupId: 'bb8aa44e-b366-4398-a5a3-1685fe003a02',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T15:56:27.037Z',

          createdAt: '2024-06-10T00:05:22.506Z',
          content: [
            {
              caption: '',

              id: 'd95f29ca-2c1c-414d-b2e1-59301300ccfe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d047dd5e-3935-4655-900c-beb524adb82a/ugc/bb8aa44e-b366-4398-a5a3-1685fe003a02/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c4791201-9616-4836-ae25-f217df8663b1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d047dd5e-3935-4655-900c-beb524adb82a/ugc/bb8aa44e-b366-4398-a5a3-1685fe003a02/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '186fe91c-c1d6-43e0-b400-7688a0c5602f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T20:47:14.654Z',

          createdAt: '2024-06-15T17:18:14.903Z',
          content: [
            {
              caption: '',

              id: '0c390bd8-6520-4a18-ad49-8eab377c05ed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d047dd5e-3935-4655-900c-beb524adb82a/ugc/186fe91c-c1d6-43e0-b400-7688a0c5602f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '29a3a9ed-4e8e-4e1e-aede-165610fc422f',
        firstName: 'Ashley',
        lastName: 'Bourne',
        email: 'ashlopez92@gmail.com',
        instagramProfile: {
          handle: 'smashleybourne',
          followers: 12453,
          engagementRate: 2.728659760700233
        },
        tiktokProfile: {
          handle: 'smashleybourne',
          followers: 563,
          engagementRate: 14.76510067114094
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T01:40:08.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '497ff1be-06ea-4bbe-9122-e10fa949b7e9',
      content: [
        {
          groupId: '6c160d1a-c55b-4ecd-b9cd-6ddb524226d8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T03:14:37.081Z',

          createdAt: '2024-06-05T05:47:19.062Z',
          content: [
            {
              caption: '',

              id: 'd27a7d48-b63e-4df9-b337-7602fa3e5898',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/497ff1be-06ea-4bbe-9122-e10fa949b7e9/ugc/6c160d1a-c55b-4ecd-b9cd-6ddb524226d8/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'dd794d4e-d3dc-4daa-a5cb-01b3e72179af',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/497ff1be-06ea-4bbe-9122-e10fa949b7e9/ugc/6c160d1a-c55b-4ecd-b9cd-6ddb524226d8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c2c854d9-fa46-42a4-8022-3d2c13e59166',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T03:14:29.990Z',

          createdAt: '2024-06-05T05:45:55.859Z',
          content: [
            {
              caption: '',

              id: '81119af7-2d77-4b69-9ec0-c273d3be7daf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/497ff1be-06ea-4bbe-9122-e10fa949b7e9/ugc/c2c854d9-fa46-42a4-8022-3d2c13e59166/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'be53919b-c7ee-4294-bde3-e446a8f0fd3f',
        firstName: 'Indiara',
        lastName: 'Schimanski',
        email: 'indiara.a.s@gmail.com',
        instagramProfile: {
          handle: 'indiaraschimanski',
          followers: 64652,
          engagementRate: 7.724741693992451
        },
        tiktokProfile: {
          handle: 'indiaraschimanski',
          followers: 2622
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T01:52:48.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '00a8f6b3-6ecc-4860-b934-8ab671d4f921',
      content: [
        {
          groupId: 'b10c8308-1606-4f2b-87ac-644ac99b0cd2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T04:42:33.593Z',

          createdAt: '2024-06-04T05:58:20.289Z',
          content: [
            {
              caption: '',

              id: '6c8e674c-b85d-4bee-acf3-64364b2bd7b7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00a8f6b3-6ecc-4860-b934-8ab671d4f921/ugc/b10c8308-1606-4f2b-87ac-644ac99b0cd2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '332c6089-c3b6-4af1-9a45-9681bf56b8b2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-02T22:32:34.395Z',

          createdAt: '2024-06-11T21:54:45.839Z',
          content: [
            {
              caption: '',

              id: '4e1e7296-ac21-46a5-8e68-4de1966143ec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00a8f6b3-6ecc-4860-b934-8ab671d4f921/ugc/332c6089-c3b6-4af1-9a45-9681bf56b8b2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'dd35ca53-693f-4b07-b646-c6d7601e298c',
        firstName: 'Rawan',
        lastName: 'Khazil',
        email: 'rkhazil@yahoo.com',
        instagramProfile: {
          handle: 'alanirawanmail.ru',
          followers: 767,
          engagementRate: 5.1629726205997395
        },
        tiktokProfile: {
          handle: 'rawanalani4',
          followers: 14137,
          engagementRate: 6.693680355333904
        },
        youtubeProfile: {
          handle: 'rawan2275',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T04:39:50.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '72f6aea0-df1b-4b87-8db3-e4de5e04233f',
      content: [
        {
          groupId: '88d8e7f3-2eaf-4953-8902-bfbc04e1af45',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T02:55:36.057Z',

          createdAt: '2024-06-05T21:41:57.823Z',
          content: [
            {
              caption: '',

              id: '54086282-121a-424c-bfc6-f39a141933f5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72f6aea0-df1b-4b87-8db3-e4de5e04233f/ugc/88d8e7f3-2eaf-4953-8902-bfbc04e1af45/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '53b6b206-d5d1-4188-8c03-d37279c5ddbc',
        firstName: 'Ikeisha',
        lastName: 'Bent',
        email: 'edmondsonkylie35@gmail.com',
        instagramProfile: {
          handle: 'kulturekloset_',
          followers: 2142,
          engagementRate: 0.350140056022409
        },
        tiktokProfile: {
          handle: 'chrissycontentcreator',
          followers: 123
        },
        youtubeProfile: {
          handle: 'pauledmondson-uj4ct',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T04:56:46.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '899c0cbc-7363-4309-9fd8-e8ef18c78643',
      content: [],
      creator: {
        id: '8480a787-c9a2-4e17-9c5c-c2847d008409',
        firstName: 'Jenni',
        lastName: 'Windsor',
        email: 'jenwindsor22.fit@gmail.com',
        instagramProfile: {
          handle: 'jenwindsor22',
          followers: 2039,
          engagementRate: 1.2849435998038254
        },
        tiktokProfile: {
          handle: 'jwinz23',
          followers: 49
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T07:12:22.000Z',
        amountCents: 7384
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b4d02f9d-0d51-48aa-ba88-f8010dec40e7',
      content: [
        {
          groupId: '436c2c30-e404-4768-9cec-0d6bd521d14c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T17:30:14.349Z',

          createdAt: '2024-05-30T21:55:11.712Z',
          content: [
            {
              caption: '',

              id: 'c22fc219-3695-4591-a0c9-98f10216c0c9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b4d02f9d-0d51-48aa-ba88-f8010dec40e7/ugc/436c2c30-e404-4768-9cec-0d6bd521d14c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3b9449dc-2dec-49e6-9517-d647f548fc98',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T17:30:19.751Z',

          createdAt: '2024-05-30T21:37:32.850Z',
          content: [
            {
              caption: '',

              id: 'fddcd60f-a604-4ceb-ad4e-8a32b6cc5e6e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b4d02f9d-0d51-48aa-ba88-f8010dec40e7/ugc/3b9449dc-2dec-49e6-9517-d647f548fc98/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e31808b2-8f57-4b80-9da8-2e675daa8f00',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b4d02f9d-0d51-48aa-ba88-f8010dec40e7/ugc/3b9449dc-2dec-49e6-9517-d647f548fc98/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2a00927e-11c2-4847-8dc0-2469021d0d15',
        firstName: 'Alina',
        lastName: 'Battalova',
        email: 'alinaodair@gmail.com',
        instagramProfile: {
          handle: 'alinaodair.ugc',
          followers: 1740,
          engagementRate: 2.3505747126436782
        },
        tiktokProfile: {
          handle: 'alinaodair',
          followers: 685,
          engagementRate: 4.834254143646409
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T07:45:19.000Z',
        amountCents: 7720
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2c6c45a9-0c23-4cf5-a33c-9b606f7dbf3d',
      content: [
        {
          groupId: '3af6092a-7407-4b53-a3db-55e974b62be0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T13:14:07.139Z',

          createdAt: '2024-06-13T00:25:36.390Z',
          content: [
            {
              caption: '',

              id: 'bb546d9e-381d-41e1-baba-fb85e0535641',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2c6c45a9-0c23-4cf5-a33c-9b606f7dbf3d/ugc/3af6092a-7407-4b53-a3db-55e974b62be0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c8e6729f-818e-4d38-b266-36836d739d0b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T13:14:20.009Z',

          createdAt: '2024-06-12T20:38:26.108Z',
          content: [
            {
              caption: '',

              id: '1d8969c6-d30f-463e-aa2e-69e3ff5a1aaa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2c6c45a9-0c23-4cf5-a33c-9b606f7dbf3d/ugc/c8e6729f-818e-4d38-b266-36836d739d0b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c8ee798f-8fad-4486-a24b-7cc7b52462f8',
        firstName: 'Yulanda',
        lastName: 'Langrin',
        email: 'ylangrin@gmail.com',

        tiktokProfile: {
          handle: 'landa_x_landa',
          followers: 3549,
          engagementRate: 16.374269005847953
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T08:52:47.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f6240978-728c-409a-a1c0-6364a66400f1',
      content: [
        {
          groupId: '7cc494e7-4f71-425e-b15c-8feeb256449f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T15:16:51.522Z',

          createdAt: '2024-05-31T00:55:56.986Z',
          content: [
            {
              caption: '',

              id: 'f60b1c18-c00e-4901-9f5b-7f6f64dc6938',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f6240978-728c-409a-a1c0-6364a66400f1/ugc/7cc494e7-4f71-425e-b15c-8feeb256449f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f8b574e7-f095-4bdc-bcf3-100a5a2c49fc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T16:52:37.123Z',

          createdAt: '2024-05-31T01:13:11.692Z',
          content: [
            {
              caption: '',

              id: '67dc6309-eea3-4a69-a73a-6a4d13d3c2df',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f6240978-728c-409a-a1c0-6364a66400f1/ugc/f8b574e7-f095-4bdc-bcf3-100a5a2c49fc/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ef522af4-0081-40d0-a194-677c6623151b',
        firstName: 'Ari',
        lastName: 'Mellado',
        email: 'ariii.mo26@gmail.com',
        instagramProfile: {
          handle: 'princess_jezmin',
          followers: 1418,
          engagementRate: 5.675675675675676
        },
        tiktokProfile: {
          handle: 'princess_jezzz_',
          followers: 118
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T09:26:01.000Z',
        amountCents: 7583
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '39ed3660-e18e-481c-bfb6-7edc2ecd8bdf',
      content: [
        {
          groupId: 'e67c21c9-6be0-4f96-ab09-55a8a3ac0d9a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-16T21:31:41.846Z',

          createdAt: '2024-06-06T16:34:25.610Z',
          content: [
            {
              caption: '',

              id: '3610edec-4cb3-43bb-8c27-d2dafc2e5a63',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39ed3660-e18e-481c-bfb6-7edc2ecd8bdf/ugc/e67c21c9-6be0-4f96-ab09-55a8a3ac0d9a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7ffa0eee-2190-4454-8a65-3d757f619c4d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T12:52:16.381Z',

          createdAt: '2024-06-06T16:35:20.983Z',
          content: [
            {
              caption: '',

              id: '25c3bcbd-0f24-47bd-82c9-f97bec447e84',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39ed3660-e18e-481c-bfb6-7edc2ecd8bdf/ugc/7ffa0eee-2190-4454-8a65-3d757f619c4d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3dd363bc-fd72-4eac-980e-d675dd3a8388',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39ed3660-e18e-481c-bfb6-7edc2ecd8bdf/ugc/7ffa0eee-2190-4454-8a65-3d757f619c4d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '85f7ddea-65c5-41e9-bf84-f2cdeeeb9243',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/39ed3660-e18e-481c-bfb6-7edc2ecd8bdf/ugc/7ffa0eee-2190-4454-8a65-3d757f619c4d/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b1fede61-6f97-462d-a30c-17e0460632f8',
        firstName: 'Bri',
        lastName: 'Benson',
        email: 'briannabenson13@aol.com',
        instagramProfile: {
          handle: 'notnarcs',
          followers: 1357,
          engagementRate: 7.369196757553427
        },

        youtubeProfile: {
          handle: 'notnarcs-co8947',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T09:43:37.000Z',
        amountCents: 7622
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'df87c991-6386-4eca-a504-eb665a56d541',
      content: [
        {
          groupId: '7ea2e702-71fb-4067-ad89-4c626b3a3a20',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:25:29.220Z',

          createdAt: '2024-05-28T16:25:33.398Z',
          content: [
            {
              caption: '',

              id: '196a68d5-ef22-4372-924c-d056fff878a6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/df87c991-6386-4eca-a504-eb665a56d541/ugc/7ea2e702-71fb-4067-ad89-4c626b3a3a20/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '6683a5e0-2c18-4a6c-afc0-345ad3a699a7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T16:24:19.501Z',

          createdAt: '2024-05-28T16:18:21.637Z',
          content: [
            {
              caption: '',

              id: '294f9c4f-5e8a-4942-89d3-a6978eb2deed',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/df87c991-6386-4eca-a504-eb665a56d541/ugc/6683a5e0-2c18-4a6c-afc0-345ad3a699a7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a2ed0190-709c-4286-8874-8a018d5c7628',
        firstName: 'emily',
        lastName: 'whited',
        email: 'blazedbeading@gmail.com',
        instagramProfile: {
          handle: 'blazedbeading',
          followers: 1248,
          engagementRate: 2.6062550120288694
        },
        tiktokProfile: {
          handle: 'emilywhited2',
          followers: 83,
          engagementRate: 4.081632653061225
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T10:53:38.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd9048e8e-88ce-492f-bf6b-42d4e2eb5415',
      content: [
        {
          groupId: '7d719be3-7ea3-4441-956a-b881e2c22213',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T17:15:39.065Z',

          createdAt: '2024-06-02T21:32:59.276Z',
          content: [
            {
              caption: '',

              id: '3f368e32-a20a-43b3-9910-24c8b95e47d6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d9048e8e-88ce-492f-bf6b-42d4e2eb5415/ugc/7d719be3-7ea3-4441-956a-b881e2c22213/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '72e0bf90-a020-45c7-a492-5b70181b8370',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d9048e8e-88ce-492f-bf6b-42d4e2eb5415/ugc/7d719be3-7ea3-4441-956a-b881e2c22213/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4732f1d5-0bea-4690-af02-33c3dcaa80f5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d9048e8e-88ce-492f-bf6b-42d4e2eb5415/ugc/7d719be3-7ea3-4441-956a-b881e2c22213/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '08bc6f08-2ef5-464a-8faa-be378d6f18f5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T17:15:33.712Z',

          createdAt: '2024-06-15T22:30:47.221Z',
          content: [
            {
              caption: '',

              id: '07909848-c873-4e2a-b49a-2d99a4894773',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d9048e8e-88ce-492f-bf6b-42d4e2eb5415/ugc/08bc6f08-2ef5-464a-8faa-be378d6f18f5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b6da1ce3-fbca-4216-b5a4-3624658c5568',
        firstName: 'Faghri',
        lastName: 'Yousif',
        email: 'store615@yahoo.com',
        instagramProfile: {
          handle: 'tampafoodboss',
          followers: 2955,
          engagementRate: 0.8020304568527918
        },
        tiktokProfile: {
          handle: 'tampafoodboss',
          followers: 1503,
          engagementRate: 4.328087167070218
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T11:23:01.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b6f239aa-6b15-446f-9490-1fe3af1239ab',
      content: [
        {
          groupId: '0dd3e1f0-18ab-4fbf-b088-5f0b85f7ff69',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T16:23:09.167Z',

          createdAt: '2024-06-14T15:37:33.709Z',
          content: [
            {
              caption: '',

              id: '4e36e071-1478-4e8b-964a-63b3fe0ba847',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6f239aa-6b15-446f-9490-1fe3af1239ab/ugc/0dd3e1f0-18ab-4fbf-b088-5f0b85f7ff69/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '059f2eb4-f486-451e-8fba-e70b6fdb9d93',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6f239aa-6b15-446f-9490-1fe3af1239ab/ugc/0dd3e1f0-18ab-4fbf-b088-5f0b85f7ff69/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '63209363-5f5a-432a-9d4e-422baaeb8ec0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6f239aa-6b15-446f-9490-1fe3af1239ab/ugc/0dd3e1f0-18ab-4fbf-b088-5f0b85f7ff69/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f84af9c4-87fd-4acc-8773-ffd842f2b45d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T16:23:43.368Z',

          createdAt: '2024-06-14T15:47:55.287Z',
          content: [
            {
              caption: '',

              id: '75b3d36e-aaa4-494b-a3b1-43144cb67149',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6f239aa-6b15-446f-9490-1fe3af1239ab/ugc/f84af9c4-87fd-4acc-8773-ffd842f2b45d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '69d0f8c9-6bf5-4d73-adb9-a81ecea2bfd8',
        firstName: 'Mo’naya',
        lastName: 'Henry',
        email: 'monayahenry703@gmail.com',
        instagramProfile: {
          handle: 'noyaessentials876',
          followers: 0,
          engagementRate: 0.3779289493575208
        },
        tiktokProfile: {
          handle: 'explorewithnoya',
          followers: 74
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T12:46:20.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0e6c27bd-558c-4e61-a493-02f9104c2589',
      content: [
        {
          groupId: '7bfba1a6-df2e-468e-89f0-e709aaddb715',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T02:29:37.815Z',

          createdAt: '2024-06-02T16:22:07.434Z',
          content: [
            {
              caption: '',

              id: 'f998073b-8c1c-4008-b236-2a803981fabb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0e6c27bd-558c-4e61-a493-02f9104c2589/ugc/7bfba1a6-df2e-468e-89f0-e709aaddb715/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a33fff6a-b7dc-4ffe-889d-879974ab5854',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0e6c27bd-558c-4e61-a493-02f9104c2589/ugc/7bfba1a6-df2e-468e-89f0-e709aaddb715/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ca632a3b-961a-433a-89fa-9f647a4412ce',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0e6c27bd-558c-4e61-a493-02f9104c2589/ugc/7bfba1a6-df2e-468e-89f0-e709aaddb715/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c4e00ed0-bfd1-475c-b00b-3cd0ebaa4575',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T02:11:31.426Z',

          createdAt: '2024-06-04T02:22:16.421Z',
          content: [
            {
              caption: '',

              id: '8c15b882-79d7-49cb-b5c2-e0b4dcda327a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0e6c27bd-558c-4e61-a493-02f9104c2589/ugc/c4e00ed0-bfd1-475c-b00b-3cd0ebaa4575/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b16feabb-f8e3-4101-a391-17bdb11adf98',
        firstName: 'Heer',
        lastName: 'Doshi',
        email: 'sociallyheer@gmail.com',
        instagramProfile: {
          handle: 'socially_heer',
          followers: 1459,
          engagementRate: 0.31528444139821793
        },
        tiktokProfile: {
          handle: 'socially_heer',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T12:56:31.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '33f25827-d3d9-4939-9fb1-aed7ffd7008a',
      content: [],
      creator: {
        id: '1c902d53-c0c6-43f1-b872-1c4c0d1d3bde',
        firstName: 'Tina',
        lastName: 'Wang',
        email: 'hello.tinawang@gmail.com',
        instagramProfile: {
          handle: 'teaa.nuuh',
          followers: 1571,
          engagementRate: 4.659452577975812
        },
        tiktokProfile: {
          handle: 'teaa.nuuh',
          followers: 106,
          engagementRate: 8.4260106091092
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T13:13:03.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd8006771-ebe3-4899-88aa-75edd1c98d5a',
      content: [
        {
          groupId: '50abff6b-4cbe-4573-a4ae-5dd61cdc539e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T01:55:14.351Z',

          createdAt: '2024-06-04T16:44:01.404Z',
          content: [
            {
              caption: '',

              id: 'b85cfd08-695c-42e1-881f-b0d0babc6e96',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d8006771-ebe3-4899-88aa-75edd1c98d5a/ugc/50abff6b-4cbe-4573-a4ae-5dd61cdc539e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '1e19dd5c-4963-45d4-a37e-39826aaf0931',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T01:55:08.855Z',

          createdAt: '2024-06-04T16:49:27.415Z',
          content: [
            {
              caption: '',

              id: '23ff1faf-e42f-448f-bff5-c97181c035e5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d8006771-ebe3-4899-88aa-75edd1c98d5a/ugc/1e19dd5c-4963-45d4-a37e-39826aaf0931/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c34fa7a9-84bc-4f6e-8c2f-32db885a78b2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d8006771-ebe3-4899-88aa-75edd1c98d5a/ugc/1e19dd5c-4963-45d4-a37e-39826aaf0931/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '74f23ead-24c2-4652-904f-3f6c1a44781f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d8006771-ebe3-4899-88aa-75edd1c98d5a/ugc/1e19dd5c-4963-45d4-a37e-39826aaf0931/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd6647fb1-2908-4e39-9cf8-4f96e61b9590',
        firstName: 'Vonnie',
        lastName: 'Norrgard',
        email: 'vonnieugc@gmail.com',
        instagramProfile: {
          handle: 'vonnienono',
          followers: 4553,
          engagementRate: 0.5381067428069405
        },
        tiktokProfile: {
          handle: 'vonnienono',
          followers: 30508,
          engagementRate: 5.5945517316401485
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T13:19:52.000Z',
        amountCents: 7596
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '89dcc72b-93b6-4ba0-a748-de566baaf8cc',
      content: [
        {
          groupId: '4c96318d-2fe9-4e3c-be7f-c534fcce26ba',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T17:16:54.263Z',

          createdAt: '2024-06-02T23:07:44.809Z',
          content: [
            {
              caption: '',

              id: 'd56cf984-6855-4fc3-acde-f7087df79421',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/89dcc72b-93b6-4ba0-a748-de566baaf8cc/ugc/4c96318d-2fe9-4e3c-be7f-c534fcce26ba/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f87dba2c-9fc3-448f-9096-3252fdfc9ede',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/89dcc72b-93b6-4ba0-a748-de566baaf8cc/ugc/4c96318d-2fe9-4e3c-be7f-c534fcce26ba/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '524767be-3044-4706-b227-cda2ca05ac0b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T17:16:48.497Z',

          createdAt: '2024-06-02T23:45:09.115Z',
          content: [
            {
              caption: '',

              id: '4b145888-b9b6-4be9-b460-5a674acd1328',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/89dcc72b-93b6-4ba0-a748-de566baaf8cc/ugc/524767be-3044-4706-b227-cda2ca05ac0b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f141031e-33fb-4fa9-8a9d-12a380fa4575',
        firstName: 'Africka',
        lastName: 'McDonald',
        email: 'stonewall_africka@yahoo.com',

        tiktokProfile: {
          handle: 'queenafricka',
          followers: 17606,
          engagementRate: 17.719568567026194
        },
        youtubeProfile: {
          handle: 'queen_africka',
          followers: 29
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T13:46:25.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c524b17d-cc47-4060-8bae-58f009570cf1',
      content: [],
      creator: {
        id: '32d08185-a4b1-496e-9789-204f7e95c2e5',
        firstName: 'Cory',
        lastName: 'Sprague',
        email: 'coryann128@yahoo.com',
        instagramProfile: {
          handle: 'coryann128',
          followers: 1122
        },
        tiktokProfile: {
          handle: 'coryann28',
          followers: 553,
          engagementRate: 7.522697795071336
        },
        youtubeProfile: {
          handle: 'coryann128',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T15:31:03.000Z',
        amountCents: 7478
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '83cc0036-5a07-492f-af2a-efb914ee70bd',
      content: [],
      creator: {
        id: '232cb023-f9c6-484b-8594-110257c2475d',
        firstName: 'Michelle',
        lastName: 'Stoll',
        email: 'ivystorm87@gmail.com',

        tiktokProfile: {
          handle: 'therealivystormugc',
          followers: 1389,
          engagementRate: 15.789473684210526
        },
        youtubeProfile: {
          handle: 'therealivystorm'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:11:34.000Z',
        amountCents: 15000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b068cfaf-e2b0-4fce-94e6-62ec5245826b',
      content: [
        {
          groupId: '678175de-46c0-4b52-abf6-a1c214e651a0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-05-30T14:48:30.077Z',

          createdAt: '2024-05-29T19:56:02.036Z',
          content: [
            {
              caption: '',

              id: '9e7b6626-4374-4ef7-bb54-409efa8c67a5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b068cfaf-e2b0-4fce-94e6-62ec5245826b/ugc/678175de-46c0-4b52-abf6-a1c214e651a0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '75ac25ed-1e4b-44f8-b27c-0d34bbcdb720',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-05-30T14:48:35.195Z',

          createdAt: '2024-05-29T19:56:37.709Z',
          content: [
            {
              caption: '',

              id: '82b9f78f-b3da-4ebc-a8b8-b4fe70d5d154',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b068cfaf-e2b0-4fce-94e6-62ec5245826b/ugc/75ac25ed-1e4b-44f8-b27c-0d34bbcdb720/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fe13de70-ef87-4268-bcf2-9d9a1145dfc7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b068cfaf-e2b0-4fce-94e6-62ec5245826b/ugc/75ac25ed-1e4b-44f8-b27c-0d34bbcdb720/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5ea6d6cb-9d09-4f6f-9fdb-8d6ad986c725',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b068cfaf-e2b0-4fce-94e6-62ec5245826b/ugc/75ac25ed-1e4b-44f8-b27c-0d34bbcdb720/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cf7532c4-8abf-40d4-8063-35b3826d0238',
        firstName: 'Shawntoya',
        lastName: 'Byrd',
        email: 'nessabyrd1@gmail.com',
        instagramProfile: {
          handle: 'prolificnessa',
          followers: 7190,
          engagementRate: 0.1015299026425591
        },
        tiktokProfile: {
          handle: 'prolificnessa',
          followers: 15527,
          engagementRate: 8.668813741277509
        },
        youtubeProfile: {
          handle: 'prolificnessa2205'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:15:46.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a8265835-d2ca-4b14-9651-79e583cd7bbe',
      content: [
        {
          groupId: 'e95619fe-7365-4b33-a33a-f358c2bbe003',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-02T22:31:30.120Z',

          createdAt: '2024-06-05T01:02:54.946Z',
          content: [
            {
              caption: '',

              id: '0b70d51d-4bb7-4a7b-82ec-204536b801e0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8265835-d2ca-4b14-9651-79e583cd7bbe/ugc/e95619fe-7365-4b33-a33a-f358c2bbe003/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7014726d-1623-46db-a50d-2e28f193ceba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8265835-d2ca-4b14-9651-79e583cd7bbe/ugc/e95619fe-7365-4b33-a33a-f358c2bbe003/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '726a9e71-fc68-4c68-ae86-f3d862f942ab',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8265835-d2ca-4b14-9651-79e583cd7bbe/ugc/e95619fe-7365-4b33-a33a-f358c2bbe003/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '91091690-d753-4719-887d-25bf5ed76fc7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T02:49:17.053Z',

          createdAt: '2024-06-05T01:01:50.696Z',
          content: [
            {
              caption: '',

              id: 'a2ee03f9-c172-45c1-b769-6ae8f0959fe1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8265835-d2ca-4b14-9651-79e583cd7bbe/ugc/91091690-d753-4719-887d-25bf5ed76fc7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '333543fd-0589-4e00-970c-843eb81000dd',
        firstName: 'Iryna',
        lastName: 'Muntian',
        email: 'munechkaira@gmail.com',
        instagramProfile: {
          handle: 'sergeeevna__',
          followers: 1323,
          engagementRate: 2.5321239606953894
        },
        tiktokProfile: {
          handle: 'irau4uusa',
          followers: 1580,
          engagementRate: 10.766718946854189
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:16:22.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0f424618-5257-4229-b4fa-97c30edc441f',
      content: [],
      creator: {
        id: '048514e6-7fbc-4600-93e4-95e522080cba',
        firstName: 'Delton Brissett',
        lastName: 'Renae Farquharson',
        email: 'renaefarquharson21@gmail.com',
        instagramProfile: {
          handle: 'naes_piecescoutureja',
          followers: 129
        },
        tiktokProfile: {
          handle: 'blessed.a1',
          followers: 2622,
          engagementRate: 31.648351648351646
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:17:59.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'da081733-3311-43e8-8930-fd4b246b2c0c',
      content: [
        {
          groupId: 'c1e42053-70cb-47f6-aafa-a30d7bbe4461',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T17:06:52.638Z',

          createdAt: '2024-06-06T06:11:05.464Z',
          content: [
            {
              caption: '',

              id: '9863f5f1-618f-45b3-b599-752bd005aafe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/da081733-3311-43e8-8930-fd4b246b2c0c/ugc/c1e42053-70cb-47f6-aafa-a30d7bbe4461/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'aa2e0a0e-1618-439f-9efc-689d7b1dfa0a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T17:06:46.981Z',

          createdAt: '2024-06-06T06:00:14.303Z',
          content: [
            {
              caption: '',

              id: 'bdf67cb6-8bac-46a3-a72e-eab87544f59f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/da081733-3311-43e8-8930-fd4b246b2c0c/ugc/aa2e0a0e-1618-439f-9efc-689d7b1dfa0a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4f000966-1f49-4a42-b789-ae10161d9d72',
        firstName: 'Nicole',
        lastName: 'Burgos',
        email: 'nicolejburgos28@gmail.com',
        instagramProfile: {
          handle: 'nicolefrmazyt',
          followers: 2849,
          engagementRate: 4.587574587574587
        },
        tiktokProfile: {
          handle: 'nicolefrmaz',
          followers: 1324,
          engagementRate: 31.03448275862069
        },
        youtubeProfile: {
          handle: 'nicolefrmaz',
          followers: 6
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:21:03.000Z',
        amountCents: 7545
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '985675fa-1744-429f-b954-48687525ecf4',
      content: [
        {
          groupId: '56e9478d-97f2-4cad-b62c-dd3597558a4d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-02T18:30:31.951Z',

          createdAt: '2024-05-31T18:19:06.685Z',
          content: [
            {
              caption: '',

              id: '963fdfd3-a131-48b4-a4e5-f94268333e57',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/985675fa-1744-429f-b954-48687525ecf4/ugc/56e9478d-97f2-4cad-b62c-dd3597558a4d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3181fe24-b59b-4d42-a35a-41c529344250',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T20:42:07.822Z',

          createdAt: '2024-05-31T18:46:56.225Z',
          content: [
            {
              caption: '',

              id: '2167ae90-ea04-4409-b9d7-2c08e31b8d52',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/985675fa-1744-429f-b954-48687525ecf4/ugc/3181fe24-b59b-4d42-a35a-41c529344250/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e5121ff4-9032-45f6-b6b2-93c1612d44dc',
        firstName: 'Mercedes',
        lastName: 'Smith',
        email: 'merwaagen12@gmail.com',
        instagramProfile: {
          handle: 'calisclosetcc',
          followers: 1844,
          engagementRate: 0.14642082429501085
        },
        tiktokProfile: {
          handle: 'minnbabe42',
          followers: 96,
          engagementRate: 11.379310344827587
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:22:27.000Z',
        amountCents: 7568
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b111acd4-a434-41d5-8e2b-272e42ae996e',
      content: [
        {
          groupId: '7bcd8c0d-6e39-4b0b-8770-414285428c11',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:06:54.769Z',

          createdAt: '2024-06-03T00:16:44.263Z',
          content: [
            {
              caption: '',

              id: '122a175b-e3e1-4dae-a5e0-b54c39ff3439',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b111acd4-a434-41d5-8e2b-272e42ae996e/ugc/7bcd8c0d-6e39-4b0b-8770-414285428c11/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '02c41e09-7f3c-41ea-bc07-3a58b4261ac6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T03:45:56.391Z',

          createdAt: '2024-06-02T22:37:59.730Z',
          content: [
            {
              caption: '',

              id: '0b8a0075-f7bf-436a-bca3-addb719e931e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b111acd4-a434-41d5-8e2b-272e42ae996e/ugc/02c41e09-7f3c-41ea-bc07-3a58b4261ac6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9b3263b4-4396-42f7-9bc2-448809834959',
        firstName: 'Musa',
        lastName: 'Chan',
        email: 'musachanpr@gmail.com',
        instagramProfile: {
          handle: '__aiamor__',
          followers: 3673,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'urgurlnaomii',
          followers: 352,
          engagementRate: 7.142857142857142
        },
        youtubeProfile: {
          handle: 'subliminals3487',
          followers: 11
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:23:40.000Z',
        amountCents: 16516
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c7897ed4-8a19-4cfb-8050-4be167aaf44f',
      content: [
        {
          groupId: 'cb95ae85-a0bc-43bf-b625-ba0198119338',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T14:34:09.401Z',

          createdAt: '2024-06-17T00:10:06.809Z',
          content: [
            {
              caption: '',

              id: 'dac39785-126a-4752-9fed-7a48c980e2ff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c7897ed4-8a19-4cfb-8050-4be167aaf44f/ugc/cb95ae85-a0bc-43bf-b625-ba0198119338/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4e5292ef-b99b-4931-93f4-bac37f802c7c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c7897ed4-8a19-4cfb-8050-4be167aaf44f/ugc/cb95ae85-a0bc-43bf-b625-ba0198119338/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c7b67759-d5b3-4a5c-989b-d8249aed0263',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c7897ed4-8a19-4cfb-8050-4be167aaf44f/ugc/cb95ae85-a0bc-43bf-b625-ba0198119338/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '294a364b-6208-4298-91e6-7764264d240e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T17:06:54.383Z',

          createdAt: '2024-06-17T00:09:28.058Z',
          content: [
            {
              caption: '',

              id: '47d79fc9-dece-4185-a302-da261f3716f7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c7897ed4-8a19-4cfb-8050-4be167aaf44f/ugc/294a364b-6208-4298-91e6-7764264d240e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f22f30fb-ab9b-41ac-a640-52ea8b55db55',
        firstName: 'Alexa',
        lastName: 'Hughes',
        email: 'alexahughesblog@gmail.com',
        instagramProfile: {
          handle: 'alexajhughes',
          followers: 25362,
          engagementRate: 9.923901900481034
        },
        tiktokProfile: {
          handle: 'alexajhughes',
          followers: 103
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:25:49.000Z',
        amountCents: 7405
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0c9248a1-f816-4fb4-b249-2379f5898bf9',
      content: [
        {
          groupId: 'f44f4a2c-f379-4d42-bd67-ebdbac526d81',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T19:05:52.615Z',

          createdAt: '2024-05-31T19:49:57.449Z',
          content: [
            {
              caption: '',

              id: 'dbe0c377-9572-450a-87dc-e0aacf4b9713',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c9248a1-f816-4fb4-b249-2379f5898bf9/ugc/f44f4a2c-f379-4d42-bd67-ebdbac526d81/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0e0ebba1-4642-4c23-ac09-112667d8d4f9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c9248a1-f816-4fb4-b249-2379f5898bf9/ugc/f44f4a2c-f379-4d42-bd67-ebdbac526d81/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '19b38732-b560-4fb2-82c7-c66133357200',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c9248a1-f816-4fb4-b249-2379f5898bf9/ugc/f44f4a2c-f379-4d42-bd67-ebdbac526d81/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '1f057590-c280-4fce-bac7-86c59b2cf207',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T19:05:48.005Z',

          createdAt: '2024-06-03T02:54:53.526Z',
          content: [
            {
              caption: '',

              id: '5b18df9f-cf60-4a6e-8df7-008a2b95cd99',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c9248a1-f816-4fb4-b249-2379f5898bf9/ugc/1f057590-c280-4fce-bac7-86c59b2cf207/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6219d0d3-7048-40b0-bc96-993e589b57a9',
        firstName: 'Crystal',
        lastName: 'Vazquez',
        email: 'crystal_velez@icloud.com',

        tiktokProfile: {
          handle: 'crystalrubyv',
          followers: 16900,
          engagementRate: 2.8955640936081033
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:31:10.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a8d68128-107f-4ecd-832c-13cfe3c154e2',
      content: [
        {
          groupId: '2dc2e05e-a7ed-4771-be35-75fc71ca0fcf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-01T20:46:18.952Z',

          createdAt: '2024-05-30T18:04:18.797Z',
          content: [
            {
              caption: '',

              id: 'f54d986a-d9f5-4baa-a9b4-67df06ec6ed2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8d68128-107f-4ecd-832c-13cfe3c154e2/ugc/2dc2e05e-a7ed-4771-be35-75fc71ca0fcf/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5e448f0a-b7df-4532-a5e7-aa9396135db5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8d68128-107f-4ecd-832c-13cfe3c154e2/ugc/2dc2e05e-a7ed-4771-be35-75fc71ca0fcf/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '1ef8de77-126c-42db-a72a-b7ce5ccd6eae',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T15:40:17.364Z',

          createdAt: '2024-05-30T18:11:31.167Z',
          content: [
            {
              caption: '',

              id: '1ef62c5e-c1dc-46e9-85d3-d12eb15b6c9a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a8d68128-107f-4ecd-832c-13cfe3c154e2/ugc/1ef8de77-126c-42db-a72a-b7ce5ccd6eae/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e3abe673-2fc6-4483-a42c-ed09711fb3ea',
        firstName: 'Phillip',
        lastName: 'Reese',
        email: 'chaostheorymediagroup@gmail.com',
        instagramProfile: {
          handle: 'chaostheorymedia1',
          followers: 1042985,
          engagementRate: 2.2331577155951425
        },
        tiktokProfile: {
          handle: 'chaostheorymedia',
          followers: 12829
        },
        youtubeProfile: {
          handle: 'chaostheorymediagroup'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:35:16.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '01820cc6-c586-46c9-91df-cdb4160eff67',
      content: [
        {
          groupId: '3d1ba013-3bbb-419b-bb70-9e40f0983f4c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T20:25:22.675Z',

          createdAt: '2024-05-31T18:13:38.318Z',
          content: [
            {
              caption: '',

              id: 'e7188cc3-9a5d-4c37-a9b9-2c30b54d89d4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/01820cc6-c586-46c9-91df-cdb4160eff67/ugc/3d1ba013-3bbb-419b-bb70-9e40f0983f4c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '558110f4-7a22-4ffb-a370-89d82874825f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/01820cc6-c586-46c9-91df-cdb4160eff67/ugc/3d1ba013-3bbb-419b-bb70-9e40f0983f4c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '08b852a4-eb96-48f0-9274-cf6daf7ff96a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/01820cc6-c586-46c9-91df-cdb4160eff67/ugc/3d1ba013-3bbb-419b-bb70-9e40f0983f4c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7f82ff8d-9a46-4222-b295-5470e90ea7f7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:08:54.766Z',

          createdAt: '2024-05-31T18:31:49.140Z',
          content: [
            {
              caption: '',

              id: '7b9ba5d4-0e92-401c-970b-c8340252e65f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/01820cc6-c586-46c9-91df-cdb4160eff67/ugc/7f82ff8d-9a46-4222-b295-5470e90ea7f7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bdd44fa8-10f0-47a5-8d05-132d946c4a6c',
        firstName: 'Deidre',
        lastName: 'Anderson',
        email: 'noveldesignbydeidre@gmail.com',
        instagramProfile: {
          handle: 'dealswithdeids',
          followers: 38,
          engagementRate: 11.05263157894737
        },
        tiktokProfile: {
          handle: 'deids_anne',
          followers: 2452,
          engagementRate: 1.7334798245861154
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T16:45:18.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1b3fcc8a-bb39-4bfa-9720-34b9f7ef3d41',
      content: [],
      creator: {
        id: '44862474-5094-4963-8879-35d454dab7a8',
        firstName: 'Tedeshia',
        lastName: 'Rowe',
        email: 'tedeshia.rowe@yahoo.com',
        instagramProfile: {
          handle: 'tedeshia.rowe',
          followers: 662,
          engagementRate: 0.5740181268882175
        },
        tiktokProfile: {
          handle: 'favours1',
          followers: 10430,
          engagementRate: 4.851330203442879
        },
        youtubeProfile: {
          handle: 'tedjaycoley2226',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T17:06:39.000Z',
        amountCents: 7603
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a1a0a5fd-1033-434c-8ffc-2fe4d156c0e2',
      content: [
        {
          groupId: '6be720c4-e777-4686-bf6a-d802cb89a083',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T16:16:34.075Z',

          createdAt: '2024-06-01T17:48:45.657Z',
          content: [
            {
              caption: '',

              id: '382a3ad7-9e28-466e-912c-23ee770b6094',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a1a0a5fd-1033-434c-8ffc-2fe4d156c0e2/ugc/6be720c4-e777-4686-bf6a-d802cb89a083/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '68d97e9f-2209-4ca3-8ac2-9d2e2afe4d28',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T16:16:38.621Z',

          createdAt: '2024-06-01T17:26:57.486Z',
          content: [
            {
              caption: '',

              id: '7f3d6659-f78e-4fdd-aaec-6276e8551169',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a1a0a5fd-1033-434c-8ffc-2fe4d156c0e2/ugc/68d97e9f-2209-4ca3-8ac2-9d2e2afe4d28/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'db186c55-8348-4fae-91ec-517f8305722b',
        firstName: 'Brittany',
        lastName: 'Seal',
        email: 'hi.brittanyseal@gmail.com',
        instagramProfile: {
          handle: 'brittany_sealy',
          followers: 187,
          engagementRate: 8.395721925133689
        },
        tiktokProfile: {
          handle: 'brittanyeseal',
          followers: 3955,
          engagementRate: 2.7202215455227505
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T17:09:26.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ae56acce-86ae-4620-8e8a-735ce8ba2274',
      content: [
        {
          groupId: '2f638986-96c6-476c-b9fa-50827b5bd8a3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T22:55:10.459Z',

          createdAt: '2024-06-02T16:51:07.702Z',
          content: [
            {
              caption: '',

              id: '965f117c-53a0-4742-91a7-04fa5f89619f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae56acce-86ae-4620-8e8a-735ce8ba2274/ugc/2f638986-96c6-476c-b9fa-50827b5bd8a3/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd05204c6-1196-4108-a693-7c8aa4cc2b4f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae56acce-86ae-4620-8e8a-735ce8ba2274/ugc/2f638986-96c6-476c-b9fa-50827b5bd8a3/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '54bf95f6-2278-4a5d-be99-eb1c9552dcc2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae56acce-86ae-4620-8e8a-735ce8ba2274/ugc/2f638986-96c6-476c-b9fa-50827b5bd8a3/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'eead321b-fc9d-4c5a-8e12-18273993e431',
        firstName: 'candace',
        lastName: 'ragbir',
        email: 'candaceragbir@gmail.com',

        tiktokProfile: {
          handle: 'candace_rebecca',
          followers: 2846,
          engagementRate: 40.49194181044695
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T17:22:45.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd93da3e8-7515-4a5c-b4e4-889eeb5d4f54',
      content: [
        {
          groupId: '422974e7-c3c2-4c0c-8d46-e20fd644ab96',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T19:13:15.157Z',

          createdAt: '2024-06-04T21:56:23.682Z',
          content: [
            {
              caption: '',

              id: '65d92a34-712c-492c-8300-b92386e03ee2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d93da3e8-7515-4a5c-b4e4-889eeb5d4f54/ugc/422974e7-c3c2-4c0c-8d46-e20fd644ab96/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '49b150ee-cdc5-4eaf-a9be-987f962f930a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d93da3e8-7515-4a5c-b4e4-889eeb5d4f54/ugc/422974e7-c3c2-4c0c-8d46-e20fd644ab96/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '43cebaae-d8fb-47fa-a321-89e6bccff87c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d93da3e8-7515-4a5c-b4e4-889eeb5d4f54/ugc/422974e7-c3c2-4c0c-8d46-e20fd644ab96/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd3528dfb-cd5b-4a65-bde3-20d4a571c32f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T09:04:34.641Z',

          createdAt: '2024-06-04T21:55:23.760Z',
          content: [
            {
              caption: '',

              id: 'dc03703c-222c-4b5e-803a-6028b73b7082',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d93da3e8-7515-4a5c-b4e4-889eeb5d4f54/ugc/d3528dfb-cd5b-4a65-bde3-20d4a571c32f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6c302ec9-a11b-4eb5-ba25-dda07cec2ee7',
        firstName: 'George',
        lastName: 'Mossey',
        email: 'georgemossey@gmail.com',
        instagramProfile: {
          handle: 'georgemossey',
          followers: 15190,
          engagementRate: 0.1718235681369322
        },
        tiktokProfile: {
          handle: 'georgemossey',
          followers: 349,
          engagementRate: 4.628551467163484
        },
        youtubeProfile: {
          handle: 'georgemossey',
          followers: 386
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T17:32:38.000Z',
        amountCents: 8523
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b104f795-8978-4e47-beb2-980386df16ec',
      content: [],
      creator: {
        id: 'eee5172b-c161-4b84-9671-61ffc4a284dc',
        firstName: 'Cassey',
        lastName: 'Ahmed',
        email: 'villagesisdoula@gmail.com',
        instagramProfile: {
          handle: 'villagesisdoula',
          followers: 1327,
          engagementRate: 0.2185380557648832
        },
        tiktokProfile: {
          handle: 'villagesisdoula',
          followers: 1493,
          engagementRate: 7.171314741035857
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T17:52:05.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '404e266b-6161-4548-88d4-97f335bba802',
      content: [],
      creator: {
        id: '8a3a25a1-49e1-4304-9319-7f5c012722d1',
        firstName: 'Genesis',
        lastName: 'del Orbe',
        email: 'delorbeg1@gmail.com',
        instagramProfile: {
          handle: 'genesisdelorbeg',
          followers: 1877,
          engagementRate: 4.4432605221097505
        },
        tiktokProfile: {
          handle: 'genesisdelorbe1',
          followers: 49
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T17:52:21.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '00fe89c2-b622-43c0-99db-63396caf04e9',
      content: [
        {
          groupId: '7d9b96e1-1a88-4d70-ab14-4601e4b9ffd7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T18:54:17.378Z',

          createdAt: '2024-05-31T00:27:20.169Z',
          content: [
            {
              caption: '',

              id: '56a16e9a-5c6e-4d5e-9940-24287655c120',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00fe89c2-b622-43c0-99db-63396caf04e9/ugc/7d9b96e1-1a88-4d70-ab14-4601e4b9ffd7/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a8b4dd99-2649-42d0-874b-c0a696e3e83a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00fe89c2-b622-43c0-99db-63396caf04e9/ugc/7d9b96e1-1a88-4d70-ab14-4601e4b9ffd7/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6c938aa2-3470-403c-8a71-321d1e528df5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00fe89c2-b622-43c0-99db-63396caf04e9/ugc/7d9b96e1-1a88-4d70-ab14-4601e4b9ffd7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f42538aa-c154-4dcf-95d7-9facd5da1b0c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T03:04:11.258Z',

          createdAt: '2024-06-05T00:59:56.414Z',
          content: [
            {
              caption: '',

              id: 'c61f3c6a-3a06-4292-b146-8c87197bd246',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00fe89c2-b622-43c0-99db-63396caf04e9/ugc/f42538aa-c154-4dcf-95d7-9facd5da1b0c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cac4e874-02d7-4326-9dda-3262a838203f',
        firstName: 'nataly',
        lastName: 'perez',
        email: 'nataly8698@gmail.com',
        instagramProfile: {
          handle: 'nataly_perez',
          followers: 2276,
          engagementRate: 1.072056239015817
        },

        youtubeProfile: {
          handle: 'natalyp845',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T17:58:21.000Z',
        amountCents: 7601
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dfe8a4a3-1c0a-4ec5-97b1-21672e7231c0',
      content: [
        {
          groupId: '0d3b7d05-30c4-4dbd-8d37-cc97086a0d33',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-04T20:46:26.658Z',

          createdAt: '2024-05-31T19:56:19.230Z',
          content: [
            {
              caption: '',

              id: '2b0f41dc-7914-4c4b-813e-1cbaafe091c6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dfe8a4a3-1c0a-4ec5-97b1-21672e7231c0/ugc/0d3b7d05-30c4-4dbd-8d37-cc97086a0d33/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8a52e513-1de7-4e90-bc5f-a37af41ec1ac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dfe8a4a3-1c0a-4ec5-97b1-21672e7231c0/ugc/0d3b7d05-30c4-4dbd-8d37-cc97086a0d33/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0b9b85a1-00b4-43f5-beb1-8fa3df61ba00',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dfe8a4a3-1c0a-4ec5-97b1-21672e7231c0/ugc/0d3b7d05-30c4-4dbd-8d37-cc97086a0d33/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '982cd3ea-f84f-498f-8d30-15f05805857c',
        firstName: 'Brooke',
        lastName: 'Sterrett',
        email: 'brookesterrett21@gmail.com',
        instagramProfile: {
          handle: 'rainbowmamadeals',
          followers: 1
        },
        tiktokProfile: {
          handle: 'rainbowmama99',
          followers: 1307,
          engagementRate: 8.187702265372168
        },
        youtubeProfile: {
          handle: 'brookesterrett4429',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T18:22:05.000Z',
        amountCents: 7594
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '99d0759f-6e3a-49c8-9f19-5f31431a551b',
      content: [],
      creator: {
        id: 'd0a39612-25c7-455d-951c-d0da3231e4e6',
        firstName: 'Mo',
        lastName: 'Farguharson',
        email: 'mof1421@icloud.com',
        instagramProfile: {
          handle: '_au_naturel__',
          followers: 15756,
          engagementRate: 7.683845307607684
        },
        tiktokProfile: {
          handle: 'aunaturel_mo',
          followers: 1956,
          engagementRate: 16.0676532769556
        },
        youtubeProfile: {
          handle: '1nemodon',
          followers: 118
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T19:04:29.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '93ef1221-c081-493e-bc2d-4a6ff2dc7324',
      content: [],
      creator: {
        id: 'f058c1bf-1fea-4eb5-b7ef-5ac4e06744fb',
        firstName: 'Vuyo',
        lastName: 'Dasha',
        email: 'vuyodasha@gmail.com',
        instagramProfile: {
          handle: 'kaioshin.w',
          followers: 30793,
          engagementRate: 0.41600363719027045
        },
        tiktokProfile: {
          handle: 'kaioshin.w',
          followers: 2196,
          engagementRate: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T19:18:12.000Z',
        amountCents: 10595
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6e128f8b-4cd6-4375-839d-e4cefeb89102',
      content: [
        {
          groupId: '574e9aa1-abc2-472a-a04d-09815d120e12',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:02:35.957Z',

          createdAt: '2024-06-06T22:15:26.882Z',
          content: [
            {
              caption: '',

              id: '5128ba0d-3047-412e-a869-ff0c9d9cd408',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6e128f8b-4cd6-4375-839d-e4cefeb89102/ugc/574e9aa1-abc2-472a-a04d-09815d120e12/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3a09933b-0cf1-46de-b91a-84b96b2edfe1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T12:53:30.711Z',

          createdAt: '2024-06-06T21:02:34.820Z',
          content: [
            {
              caption: '',

              id: '359f82e1-0622-418f-9f6f-ba0eb4873546',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6e128f8b-4cd6-4375-839d-e4cefeb89102/ugc/3a09933b-0cf1-46de-b91a-84b96b2edfe1/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '32f12b41-ea90-4932-9fe7-ff4c22e81bd1',
        firstName: 'Kira',
        lastName: 'Stevens',
        email: 'latrigirl04@gmail.com',
        instagramProfile: {
          handle: 'latrigirl',
          followers: 2088,
          engagementRate: 0.8812260536398466
        },
        tiktokProfile: {
          handle: 'latrigirl',
          followers: 299,
          engagementRate: 3.9429951084911576
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T19:23:41.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b2b3df91-0905-416a-827e-562af251e143',
      content: [
        {
          groupId: '806ac577-e0ef-4582-9e18-9c84e566639b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T21:42:19.586Z',

          createdAt: '2024-06-02T22:37:14.390Z',
          content: [
            {
              caption: '',

              id: 'ed040c10-8b88-4d8b-ba60-d1cf88757fc8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b2b3df91-0905-416a-827e-562af251e143/ugc/806ac577-e0ef-4582-9e18-9c84e566639b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '1c588532-a01b-4f4f-979d-8446399b78f3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T21:42:26.475Z',

          createdAt: '2024-06-02T22:38:59.732Z',
          content: [
            {
              caption: '',

              id: '795f45b0-d357-4bda-bf46-0b28ac9bccc0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b2b3df91-0905-416a-827e-562af251e143/ugc/1c588532-a01b-4f4f-979d-8446399b78f3/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cb7fbfcb-3481-42ff-8802-bfd64dbbaade',
        firstName: 'Laura',
        lastName: 'Millan',
        email: 'millanlaura83@gmail.com',
        instagramProfile: {
          handle: '__lauramillan',
          followers: 9903,
          engagementRate: 1.893365646773705
        },
        tiktokProfile: {
          handle: '__aidettemillan',
          followers: 95,
          engagementRate: 4.287690179806362
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T19:36:27.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '30221acc-2e69-4f68-86ec-c6148b26f269',
      content: [],
      creator: {
        id: 'a34c6f06-11f0-47a4-921e-176202c49364',
        firstName: 'Jalyn ',
        lastName: 'Roper ',
        email: 'jalynroper605@gmail.com',
        instagramProfile: {
          handle: 'itz__jalyn',
          followers: 1847
        },
        tiktokProfile: {
          handle: 'vendetta_bby',
          followers: 1313
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T19:36:28.000Z',
        amountCents: 8041
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6a0b23c5-b89b-4782-b220-8bbab85bd29b',
      content: [],
      creator: {
        id: '9b528818-4862-41e4-847e-58b543d5cd4a',
        firstName: 'Charissa',
        lastName: 'Johnson',
        email: 'johnsoncharissa55@gmail.com',

        tiktokProfile: {
          handle: 'cha_rissa21',
          followers: 1389,
          engagementRate: 19.33233682112606
        },
        youtubeProfile: {
          handle: 'charissajohnson1626',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T19:46:28.000Z',
        amountCents: 3373
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '05406a84-09c3-45c2-b77f-9764d46a532e',
      content: [],
      creator: {
        id: '0ac3616b-4124-4ceb-a8d4-69888ef059be',
        firstName: 'Victoria',
        lastName: 'Rogers',
        email: 'torancell@aim.com',
        instagramProfile: {
          handle: 'victorialynn1107n',
          followers: 206,
          engagementRate: 2.1359223300970878
        },
        tiktokProfile: {
          handle: 'victorialynn1107',
          followers: 2608
        },
        youtubeProfile: {
          handle: 'victorialynn4750',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T19:53:13.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4a7e007c-93cc-4235-8721-5fb6aa23177e',
      content: [],
      creator: {
        id: '83a62b7f-e749-43d6-8103-861d2f2be37c',
        firstName: 'Ocean',
        lastName: 'Brown',
        email: 'oceanpopstar@gmail.com',

        tiktokProfile: {
          handle: 'osie_thee_scientist',
          followers: 1987,
          engagementRate: 14.737105886641153
        },
        youtubeProfile: {
          handle: 'oceanbrown7453',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T19:56:36.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3567cbff-d3cb-4d89-89f3-dc8e0484b1da',
      content: [],
      creator: {
        id: 'f6427fe0-da11-497c-a62a-b8eb7b6e44d0',
        firstName: 'SHANTOYA',
        lastName: 'Barton',
        email: 'shantoyadixon21@gmail.com',
        instagramProfile: {
          handle: 'big_dwag_frl',
          followers: 1183,
          engagementRate: 0.5554884675763796
        },
        tiktokProfile: {
          handle: 'shantoyadixon843',
          followers: 2249,
          engagementRate: 7.747318235995232
        },
        youtubeProfile: {
          handle: 'shantoyadixon3422',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T20:02:51.000Z',
        amountCents: 9136
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0ea42b76-9455-447c-bb68-0a7f5741f909',
      content: [],
      creator: {
        id: '72f6993c-3905-49b2-938f-3a6b27e989f7',
        firstName: 'Kayla',
        lastName: 'Nurmi',
        email: 'kaylaprice620@icloud.com',
        instagramProfile: {
          handle: 'kayla92212',
          followers: 2221,
          engagementRate: 0.7113912651958577
        },
        tiktokProfile: {
          handle: 'kaylaprice0',
          followers: 303,
          engagementRate: 1.3513513513513513
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T20:06:30.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '68ea84c7-4083-4cc2-9df7-c1d45f77ecf5',
      content: [
        {
          groupId: '604447b7-3936-44cb-ad7f-17134e3d49ce',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-04T21:42:32.483Z',

          createdAt: '2024-05-31T22:46:37.539Z',
          content: [
            {
              caption: '',

              id: '7c400dc5-0a7d-4a97-8177-d0a34c916520',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/68ea84c7-4083-4cc2-9df7-c1d45f77ecf5/ugc/604447b7-3936-44cb-ad7f-17134e3d49ce/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '79a8d607-fc95-48ab-a4a3-b119453c1437',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/68ea84c7-4083-4cc2-9df7-c1d45f77ecf5/ugc/604447b7-3936-44cb-ad7f-17134e3d49ce/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '14bb2a4b-256b-4b9b-8dfe-cdc7f8a2fe1a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/68ea84c7-4083-4cc2-9df7-c1d45f77ecf5/ugc/604447b7-3936-44cb-ad7f-17134e3d49ce/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '69618c36-c228-4eeb-9e48-06747a2c4a99',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T21:42:22.949Z',

          createdAt: '2024-06-02T09:20:31.289Z',
          content: [
            {
              caption: '',

              id: '7017e984-3fdc-43c0-a32b-b53dbe276308',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/68ea84c7-4083-4cc2-9df7-c1d45f77ecf5/ugc/69618c36-c228-4eeb-9e48-06747a2c4a99/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8010366b-b58e-44e5-9af0-b27c1e37afba',
        firstName: 'Latoya',
        lastName: 'Palmer',
        email: 'lattieshanicolepalmer28@gmail.com',

        tiktokProfile: {
          handle: 'boymomlatty',
          followers: 1323,
          engagementRate: 10.38961038961039
        },
        youtubeProfile: {
          handle: 'lattyoffical',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T20:16:24.000Z',
        amountCents: 9508
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ff945727-ab73-442d-9346-fec450f46657',
      content: [],
      creator: {
        id: '41a8a587-5b2d-4a61-b785-4f9b43351496',
        firstName: 'Payal',
        lastName: 'Kalia',
        email: 'payalkaliaexe@gmail.com',
        instagramProfile: {
          handle: '_awakenedaura_',
          followers: 6,
          engagementRate: 5.555555555555555
        },
        tiktokProfile: {
          handle: '_wellness_wisdom_',
          followers: 987,
          engagementRate: 10.309278350515463
        },
        youtubeProfile: {
          handle: 'smileswithpayal3093',
          followers: 4
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T20:16:28.000Z',
        amountCents: 8223
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1610aca6-6c17-465e-9e15-c4a514857fc7',
      content: [
        {
          groupId: '8aa2b73c-80bf-44fb-b98e-b3ccf9aec732',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T17:53:03.601Z',

          createdAt: '2024-06-08T21:04:39.310Z',
          content: [
            {
              caption: '',

              id: 'a383c8e1-a1c1-4f3b-ba23-3c9989460fb9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1610aca6-6c17-465e-9e15-c4a514857fc7/ugc/8aa2b73c-80bf-44fb-b98e-b3ccf9aec732/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7cbd5b54-15f7-4042-ba05-1f57ae2d4644',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T13:49:36.935Z',

          createdAt: '2024-06-08T20:42:15.718Z',
          content: [
            {
              caption: '',

              id: '8470614c-784a-4bc7-9275-6bf5f959d38e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1610aca6-6c17-465e-9e15-c4a514857fc7/ugc/7cbd5b54-15f7-4042-ba05-1f57ae2d4644/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c7bbdaea-8434-4ad7-a07d-dd1a8b131cf4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1610aca6-6c17-465e-9e15-c4a514857fc7/ugc/7cbd5b54-15f7-4042-ba05-1f57ae2d4644/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4da7246d-fc27-444b-b1ac-cf2cdeea3c75',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1610aca6-6c17-465e-9e15-c4a514857fc7/ugc/7cbd5b54-15f7-4042-ba05-1f57ae2d4644/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7b2073ff-a965-472d-93cd-d1cfb8a03e1a',
        firstName: 'Jaidah',
        lastName: 'Liverman',
        email: 'info@jaidahofficial.com',
        instagramProfile: {
          handle: 'jaidahofficial',
          followers: 2471,
          engagementRate: 1.1290975313638203
        },
        tiktokProfile: {
          handle: 'jaidahofficial',
          followers: 456,
          engagementRate: 0.6864988558352403
        },
        youtubeProfile: {
          handle: 'jaidahofficial'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T20:43:40.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '242f1b5e-dc6f-49fa-a198-9aa4173ee373',
      content: [],
      creator: {
        id: '1cb23bf2-2693-42e5-850d-9e1b5ad3098b',
        firstName: 'Ashley',
        lastName: 'Champ',
        email: 'hashleychamp@gmail.com',
        instagramProfile: {
          handle: 'empoweredmamamarketing',
          followers: 1147,
          engagementRate: 2.6068003487358324
        },
        tiktokProfile: {
          handle: '_zechamp',
          followers: 1120,
          engagementRate: 0.4149377593360996
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T22:45:47.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2115bfcf-5d6b-4287-a836-274559e3cffd',
      content: [
        {
          groupId: '13c84521-96fa-47a6-9cb1-58fab24fa956',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-02T15:20:02.876Z',

          createdAt: '2024-05-31T16:15:54.922Z',
          content: [
            {
              caption: '',

              id: '485f99cd-b717-4473-9520-a50b23b43da4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2115bfcf-5d6b-4287-a836-274559e3cffd/ugc/13c84521-96fa-47a6-9cb1-58fab24fa956/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3d81042e-be7d-4249-b7bf-aaef46adefa5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2115bfcf-5d6b-4287-a836-274559e3cffd/ugc/13c84521-96fa-47a6-9cb1-58fab24fa956/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e4ef4e6d-45e0-45c5-ba83-1f3748710a54',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2115bfcf-5d6b-4287-a836-274559e3cffd/ugc/13c84521-96fa-47a6-9cb1-58fab24fa956/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '92e50106-e9af-4611-84f2-b2bddfcfdb42',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-04T16:28:03.926Z',

          createdAt: '2024-05-31T23:41:46.352Z',
          content: [
            {
              caption: '',

              id: 'f7490459-4ec2-49d0-9133-67147288e62e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2115bfcf-5d6b-4287-a836-274559e3cffd/ugc/92e50106-e9af-4611-84f2-b2bddfcfdb42/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '15f434f8-87c1-4195-99ac-583aea54fdf7',
        firstName: 'Howana',
        lastName: 'Daley',
        email: 'howanadaley1@gmail.com',
        instagramProfile: {
          handle: 'therealtrappa_',
          followers: 4292,
          engagementRate: 1.2581547064305685
        },
        tiktokProfile: {
          handle: 'theprayerwarrior_1',
          followers: 270,
          engagementRate: 4.577423854917461
        },
        youtubeProfile: {
          handle: 'lovelyjayda',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-29T23:43:31.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '46f87e3f-8e32-48a3-87e3-2627b4538f48',
      content: [
        {
          groupId: 'fedfb43d-ec5c-429e-b861-3e2133b72a79',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T14:25:15.789Z',

          createdAt: '2024-06-02T03:08:06.660Z',
          content: [
            {
              caption: '',

              id: 'e3b4a4e5-6dba-4a77-ae05-c483ad42b7c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46f87e3f-8e32-48a3-87e3-2627b4538f48/ugc/fedfb43d-ec5c-429e-b861-3e2133b72a79/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '870c4408-7e1f-411c-a432-e84aff276258',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46f87e3f-8e32-48a3-87e3-2627b4538f48/ugc/fedfb43d-ec5c-429e-b861-3e2133b72a79/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f1f98ae5-a0e0-4158-a4a1-13c852645dd7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46f87e3f-8e32-48a3-87e3-2627b4538f48/ugc/fedfb43d-ec5c-429e-b861-3e2133b72a79/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '40ef71b4-e312-4e78-85c5-634a3e8d1515',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:27:18.463Z',

          createdAt: '2024-06-02T03:07:02.118Z',
          content: [
            {
              caption: '',

              id: '6a4ba3d1-9703-4da1-8c6b-e466916433e8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46f87e3f-8e32-48a3-87e3-2627b4538f48/ugc/40ef71b4-e312-4e78-85c5-634a3e8d1515/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '86c76176-0848-4609-8ca7-cde6fe4603bd',
        firstName: 'Krizza',
        lastName: 'Sayavedra',
        email: 'kriz.postrero@gmail.com',
        instagramProfile: {
          handle: 'krizkringkles',
          followers: 1232,
          engagementRate: 0.7792207792207793
        },
        tiktokProfile: {
          handle: 'krizkringkles',
          followers: 115,
          engagementRate: 1.5872109620325436
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T00:13:00.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e57d4437-13ea-4db6-947a-f0767c466c23',
      content: [
        {
          groupId: '6c7dce08-9da1-4d37-97f1-e78c31333632',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T04:22:44.859Z',

          createdAt: '2024-06-16T00:23:45.584Z',
          content: [
            {
              caption: '',

              id: 'b2557158-2249-4fae-a8e9-cc8405a72900',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e57d4437-13ea-4db6-947a-f0767c466c23/ugc/6c7dce08-9da1-4d37-97f1-e78c31333632/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '83b44b5c-70dd-465c-88fa-ee1dff11d800',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T04:22:39.134Z',

          createdAt: '2024-06-16T00:22:12.971Z',
          content: [
            {
              caption: '',

              id: '1762243b-26a1-4b6f-a4db-e2d1c17b55e7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e57d4437-13ea-4db6-947a-f0767c466c23/ugc/83b44b5c-70dd-465c-88fa-ee1dff11d800/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '23da3f8e-7393-4946-808a-1b580b0a90d2',
        firstName: 'Michael',
        lastName: 'Moore',
        email: 'michael.moore0724@gmail.com',
        instagramProfile: {
          handle: 'mooreamichael',
          followers: 19590,
          engagementRate: 2.156712608473711
        },
        tiktokProfile: {
          handle: 'mooreamichael',
          followers: 2130
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T00:13:08.000Z',
        amountCents: 13644
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7f77bf67-8e50-4a04-8793-b9a4a8e26bba',
      content: [
        {
          groupId: '6c3a4b39-02ac-4de4-8a9c-387cf8246814',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T10:25:19.442Z',

          createdAt: '2024-06-08T17:13:15.399Z',
          content: [
            {
              caption: '',

              id: '282c9d62-98eb-4ca5-ad4c-2a61ed235e6e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7f77bf67-8e50-4a04-8793-b9a4a8e26bba/ugc/6c3a4b39-02ac-4de4-8a9c-387cf8246814/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a4458a69-60bc-4ee9-9710-2228a043f65b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T14:53:35.442Z',

          createdAt: '2024-06-08T11:00:13.051Z',
          content: [
            {
              caption: '',

              id: 'a725acb9-5c05-4a7f-a7d2-e93216fe2414',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7f77bf67-8e50-4a04-8793-b9a4a8e26bba/ugc/a4458a69-60bc-4ee9-9710-2228a043f65b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e2a1d713-93ce-4caf-b46c-b645d31c050d',
        firstName: 'Nikita',
        lastName: 'Keithahn',
        email: 'nikitakeithahn@gmail.com',

        tiktokProfile: {
          handle: 'nikita_keith',
          followers: 2945,
          engagementRate: 8.547368421052632
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T00:18:07.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1f94f370-09b3-4c2a-a998-bb16027f68fd',
      content: [
        {
          groupId: '26306a88-1b2f-4853-951e-e585de7b6c02',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T11:00:59.184Z',

          createdAt: '2024-06-06T10:27:45.259Z',
          content: [
            {
              caption: '',

              id: '387e8c53-daf9-42a2-8839-ca6c35a5d597',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1f94f370-09b3-4c2a-a998-bb16027f68fd/ugc/26306a88-1b2f-4853-951e-e585de7b6c02/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7b97c39b-754e-4443-979c-be49e44fb61c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-08T11:01:04.082Z',

          createdAt: '2024-06-03T10:33:49.436Z',
          content: [
            {
              caption: '',

              id: '8e07385e-e716-4074-af88-8916a1f49712',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1f94f370-09b3-4c2a-a998-bb16027f68fd/ugc/7b97c39b-754e-4443-979c-be49e44fb61c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '59e95a76-b12f-4133-aa9e-3f86ca6670ff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1f94f370-09b3-4c2a-a998-bb16027f68fd/ugc/7b97c39b-754e-4443-979c-be49e44fb61c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6001b130-3dd9-427f-8e9b-3d71ac49be48',
        firstName: 'Alex',
        lastName: 'Carter',
        email: 'alexandra.carter1997@yahoo.com',

        tiktokProfile: {
          handle: 'alexcarter827',
          followers: 2182,
          engagementRate: 9.433962264150944
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T03:23:48.000Z',
        amountCents: 8160
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f1e78a05-8af5-4923-be64-f206fb07ca5e',
      content: [
        {
          groupId: '1c20983b-81ab-4a8b-a96f-85c7282e63b2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T16:59:49.797Z',

          createdAt: '2024-06-16T01:21:32.453Z',
          content: [
            {
              caption: '',

              id: 'cd0724c1-a06a-4f2f-a774-528a4bb2bb48',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f1e78a05-8af5-4923-be64-f206fb07ca5e/ugc/1c20983b-81ab-4a8b-a96f-85c7282e63b2/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bfa4fe5f-d384-46ec-80f5-04da7a53c4bb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f1e78a05-8af5-4923-be64-f206fb07ca5e/ugc/1c20983b-81ab-4a8b-a96f-85c7282e63b2/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c913b4c3-d386-41b7-a1ce-659f7c26c8a2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T16:59:44.775Z',

          createdAt: '2024-06-16T01:20:38.315Z',
          content: [
            {
              caption: '',

              id: 'd8bfe980-b8a9-4950-b96f-1999c9ec3d3d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f1e78a05-8af5-4923-be64-f206fb07ca5e/ugc/c913b4c3-d386-41b7-a1ce-659f7c26c8a2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0a0ef715-bbe5-48a3-b7ee-8fc27af76998',
        firstName: 'Celestina',
        lastName: 'Antwi',
        email: 'melaninbytina@gmail.com',

        tiktokProfile: {
          handle: 'emprezz_xx1',
          followers: 9255,
          engagementRate: 8.399244486101852
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T04:14:24.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'aba4692f-c813-4228-9228-99c49c7f5e6e',
      content: [
        {
          groupId: '31a26526-93ea-4c50-87df-e8d3cb1e5152',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-19T15:22:04.965Z',

          createdAt: '2024-06-17T14:19:02.624Z',
          content: [
            {
              caption: '',

              id: '78ec587e-61a4-47da-b43f-309e8e8ecbcc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aba4692f-c813-4228-9228-99c49c7f5e6e/ugc/31a26526-93ea-4c50-87df-e8d3cb1e5152/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '80ec2294-faf6-465c-add4-9c41e7bb18e3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aba4692f-c813-4228-9228-99c49c7f5e6e/ugc/31a26526-93ea-4c50-87df-e8d3cb1e5152/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '021f01f2-4544-47ce-b82e-4be9c0b317fe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T15:22:10.276Z',

          createdAt: '2024-06-18T18:05:24.413Z',
          content: [
            {
              caption: '',

              id: '92a544cc-79ac-4b1b-962c-b120776d9a28',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aba4692f-c813-4228-9228-99c49c7f5e6e/ugc/021f01f2-4544-47ce-b82e-4be9c0b317fe/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9eec26df-3574-4a49-8526-77bf348730c4',
        firstName: 'Nadia ',
        lastName: 'Shu',
        email: 'nadia.shutava@gmail.com',
        instagramProfile: {
          handle: 'flauntyourfangs',
          followers: 3461,
          engagementRate: 2.161225079456804
        },
        tiktokProfile: {
          handle: 'flauntyourfangs',
          followers: 27,
          engagementRate: 4.263565891472868
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T04:46:49.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b0a51211-ad06-4c76-9520-69fc3b8ad59d',
      content: [
        {
          groupId: '7ac49e8e-d721-4f90-91da-3fe6efebe33b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T23:14:43.452Z',

          createdAt: '2024-06-12T22:00:08.916Z',
          content: [
            {
              caption: '',

              id: '10e73b2a-0267-487e-9f2a-5e5e8c66ac7e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0a51211-ad06-4c76-9520-69fc3b8ad59d/ugc/7ac49e8e-d721-4f90-91da-3fe6efebe33b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '282a798a-3b3d-4759-9848-8d24325ae00b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T14:40:34.578Z',

          createdAt: '2024-06-21T19:39:00.807Z',
          content: [
            {
              caption: '',

              id: 'e00ebb20-5de7-4e34-a1e0-7c7f60331a88',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0a51211-ad06-4c76-9520-69fc3b8ad59d/ugc/282a798a-3b3d-4759-9848-8d24325ae00b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6fdb3f49-a5c5-4a79-b11a-d9f7333d7351',
        firstName: 'Amoya',
        lastName: 'Stewart',
        email: 'amoyastewart34@gmail.com',
        instagramProfile: {
          handle: 'funsize_moyy',
          followers: 2390,
          engagementRate: 0.33098882912701694
        },
        tiktokProfile: {
          handle: '.ayoma_',
          followers: 157
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T10:22:38.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'df885a97-d8e5-44be-9c47-e5c5b5b532cb',
      content: [
        {
          groupId: '733661d9-0bf0-4022-9fcf-b526bb374f7d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T12:47:10.369Z',

          createdAt: '2024-06-02T17:37:36.359Z',
          content: [
            {
              caption: '',

              id: '4d807c96-be10-4142-9b69-662268a14473',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/df885a97-d8e5-44be-9c47-e5c5b5b532cb/ugc/733661d9-0bf0-4022-9fcf-b526bb374f7d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2165a53a-025e-4be7-b27c-fd87c835b6e0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T12:47:02.210Z',

          createdAt: '2024-06-02T17:35:56.267Z',
          content: [
            {
              caption: '',

              id: '65e111dc-fd9e-47e7-9502-dc828ffe3c2f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/df885a97-d8e5-44be-9c47-e5c5b5b532cb/ugc/2165a53a-025e-4be7-b27c-fd87c835b6e0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '02ea0465-a14e-4228-93da-f8d406f03516',
        firstName: 'Courtney',
        lastName: 'Jeffcoat',
        email: 'courtneyjeffcoatmarketing@gmail.com',
        instagramProfile: {
          handle: 'yourplrmrrbestie',
          followers: 4
        },
        tiktokProfile: {
          handle: 'courtney.jeffcoat',
          followers: 13907,
          engagementRate: 6.012658227848101
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T12:25:47.000Z',
        amountCents: 8314
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c5680e97-98ad-484f-b54d-2e6faeee04fb',
      content: [
        {
          groupId: 'fa73f768-ef8c-41fb-9c37-a08f41c0f9b9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T07:36:34.957Z',

          createdAt: '2024-06-05T20:53:21.707Z',
          content: [
            {
              caption: '',

              id: 'a150cc1d-2552-4568-89e9-d28e5561594c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c5680e97-98ad-484f-b54d-2e6faeee04fb/ugc/fa73f768-ef8c-41fb-9c37-a08f41c0f9b9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ab479a67-231f-4ef0-9dfe-1a6bf5eadb8d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T07:36:41.503Z',

          createdAt: '2024-06-05T20:37:32.306Z',
          content: [
            {
              caption: '',

              id: 'b2cea624-7751-4ca0-a42c-48020d3c2f3f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c5680e97-98ad-484f-b54d-2e6faeee04fb/ugc/ab479a67-231f-4ef0-9dfe-1a6bf5eadb8d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '27260b6b-5cfa-4186-8914-ff4e3e6beb73',
        firstName: 'Sarah',
        lastName: 'Munir',
        email: 'sarah.munir.k@gmail.com',
        instagramProfile: {
          handle: 'thesarahmunir',
          followers: 1930,
          engagementRate: 1.6476683937823835
        },
        tiktokProfile: {
          handle: 'sarahmunir',
          followers: 46
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T15:13:33.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '15f05afd-ed3c-47e5-bf39-243a6a3a2eb5',
      content: [],
      creator: {
        id: '19807e6e-0ab0-47b4-97e4-3ce07ff1dcaa',
        firstName: 'Ngoc',
        lastName: 'Kim',
        email: 'ngockim979@yahoo.com',
        instagramProfile: {
          handle: 'hardngoclife_xoxo',
          followers: 1642
        },
        tiktokProfile: {
          handle: 'ngocoutpanda',
          followers: 129
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:16:00.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6dc0d511-ac3f-4d30-be78-c75d57c5e8cd',
      content: [],
      creator: {
        id: '7aec0cbe-7473-4fac-87ba-27e0484670f4',
        firstName: 'Nai’Anna Rayne',
        lastName: 'Beckwith',
        email: 'kadezagooden@gmail.com',
        instagramProfile: {
          handle: 'naianna_szn',
          followers: 2117,
          engagementRate: 1.139584317430326
        },
        tiktokProfile: {
          handle: 'naianna_szn',
          followers: 346
        },
        youtubeProfile: {
          handle: 'naianna_szn',
          followers: 502
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:17:23.000Z',
        amountCents: 7295
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '162861a1-5e2c-4134-9b27-e758919001a3',
      content: [
        {
          groupId: '0c7f2ef4-937f-4a40-b139-f2b4b7dd8cdd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T18:56:39.468Z',

          createdAt: '2024-06-11T16:50:21.143Z',
          content: [
            {
              caption: '',

              id: 'cd5f7533-d831-4920-ab71-913b02a1a7a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/162861a1-5e2c-4134-9b27-e758919001a3/ugc/0c7f2ef4-937f-4a40-b139-f2b4b7dd8cdd/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '52679f57-b310-4d53-a350-1593cbb7999d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/162861a1-5e2c-4134-9b27-e758919001a3/ugc/0c7f2ef4-937f-4a40-b139-f2b4b7dd8cdd/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '05f8ad31-fc6f-4889-b154-1f69aa273708',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/162861a1-5e2c-4134-9b27-e758919001a3/ugc/0c7f2ef4-937f-4a40-b139-f2b4b7dd8cdd/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3dd91ca4-59d9-4178-a3f4-6af17b8c775d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T18:56:32.089Z',

          createdAt: '2024-06-11T16:49:43.319Z',
          content: [
            {
              caption: '',

              id: '385058c3-9599-4a84-9c0b-7a3c91317051',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/162861a1-5e2c-4134-9b27-e758919001a3/ugc/3dd91ca4-59d9-4178-a3f4-6af17b8c775d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e9ee28f0-8c84-4bd4-883b-c5889c64b80d',
        firstName: 'Akilah',
        lastName: 'Levers',
        email: 'livingwithkeelie@gmail.com',

        tiktokProfile: {
          handle: 'hustler.of.all.trades',
          followers: 1260,
          engagementRate: 6.706204379562044
        },
        youtubeProfile: {
          handle: 'akilahstv4176',
          followers: 397
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:22:37.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '650422ed-ef12-4f4e-9c14-6921868e6645',
      content: [],
      creator: {
        id: 'ec7b1630-a6e5-47aa-9ca2-3d0fad6a9744',
        firstName: 'gabriela',
        lastName: 'ogeerally',
        email: 'gabrielaoggee@icloud.com',

        tiktokProfile: {
          handle: 'user384747298',
          followers: 7427,
          engagementRate: 8.106666666666666
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:22:41.000Z',
        amountCents: 8523
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1a646a8a-f9bc-41ce-8002-d8425d3f6b8c',
      content: [],
      creator: {
        id: 'e3de3d25-0c4c-4e86-bdc7-5be0e25c4f0a',
        firstName: 'London',
        lastName: 'King',
        email: 'london.ocain02@gmail.com',
        instagramProfile: {
          handle: 'londonking__',
          followers: 1085,
          engagementRate: 6.057838660578386
        },
        tiktokProfile: {
          handle: 'l.o.n.d.o.n22',
          followers: 1815,
          engagementRate: 2.840909090909091
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:25:23.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e9963d2e-7586-4bab-abf7-d1b15a1a2929',
      content: [
        {
          groupId: '9e59493c-c892-478e-9b34-6f5afd89fef9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T15:33:57.878Z',

          createdAt: '2024-06-05T23:34:39.004Z',
          content: [
            {
              caption: '',

              id: 'fee770e0-506f-41e0-ba74-f9339eb3d333',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e9963d2e-7586-4bab-abf7-d1b15a1a2929/ugc/9e59493c-c892-478e-9b34-6f5afd89fef9/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2fddeada-7354-4f4d-ba67-6a46341f44f8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T15:33:53.793Z',

          createdAt: '2024-06-05T23:33:53.259Z',
          content: [
            {
              caption: '',

              id: 'ff0361a4-c014-4ece-8adf-50cbf3b3a43c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e9963d2e-7586-4bab-abf7-d1b15a1a2929/ugc/2fddeada-7354-4f4d-ba67-6a46341f44f8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b5cb4ba9-815f-47f7-8787-98c8520d206a',
        firstName: 'maria',
        lastName: 'alcoleas',
        email: 'mjalcoleas@gmail.com',
        instagramProfile: {
          handle: 'sahlthrifts_reviews',
          followers: 1719,
          engagementRate: 4.873634542046409
        },
        tiktokProfile: {
          handle: 'sahlthrifts.reviews',
          followers: 909,
          engagementRate: 5.4613935969868175
        },
        youtubeProfile: {
          handle: 'mariaalcoleas',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:32:08.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cf3d7875-564b-4d50-872c-44a1120a2803',
      content: [],
      creator: {
        id: 'd402cc6b-df8d-4ddd-89a9-8b8eafe6b8f5',
        firstName: 'Pam ',
        lastName: 'Taylor ',
        email: 'trudreampam@gmail.com',
        instagramProfile: {
          handle: 'trudreampam',
          followers: 6000,
          engagementRate: 0.025
        },
        tiktokProfile: {
          handle: 'dreamwithpam',
          followers: 2092
        },
        youtubeProfile: {
          handle: 'greentigerdreams'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:32:38.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0d795264-5e8c-48a6-ad29-2a085e83bd25',
      content: [],
      creator: {
        id: 'fe62113e-9b39-406a-a70f-5c0048dc5b6f',
        firstName: 'Lori',
        lastName: 'Vajgrt',
        email: 'lorivajgrt@icloud.com',
        instagramProfile: {
          handle: 'lorivajgrt',
          followers: 159
        },
        tiktokProfile: {
          handle: 'lollie50158',
          followers: 4335,
          engagementRate: 5.857672653940151
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:35:27.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '96409f3a-93b8-433a-8606-821ea50148e1',
      content: [
        {
          groupId: '2fba01a1-f754-4f0b-adac-4059eb5e2b1a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T19:27:07.696Z',

          createdAt: '2024-06-05T00:51:13.945Z',
          content: [
            {
              caption: '',

              id: 'f0aed67b-a7f8-46a0-8fb4-605b13389e44',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/96409f3a-93b8-433a-8606-821ea50148e1/ugc/2fba01a1-f754-4f0b-adac-4059eb5e2b1a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2e372d5a-48f6-49e3-9cb2-6eeafad400c6',
        firstName: 'Hannah',
        lastName: 'Strader',
        email: 'hannahlosi16@gmail.com',
        instagramProfile: {
          handle: 'hannbann5678',
          followers: 2508,
          engagementRate: 29.02711323763955
        },
        tiktokProfile: {
          handle: 'hannahlosi',
          followers: 84
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:40:20.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '49c0983d-e524-4342-84c8-497b73705ad3',
      content: [],
      creator: {
        id: '8eca1a84-0aba-450d-b40f-59427390b627',
        firstName: 'Timi',
        lastName: 'Sims',
        email: 'loveangel6594@gmail.com',
        instagramProfile: {
          handle: 'babbiigirlluvshergirls',
          followers: 92,
          engagementRate: 10.326086956521738
        },
        tiktokProfile: {
          handle: 'misslove6594',
          followers: 3273,
          engagementRate: 1.3963480128893664
        },
        youtubeProfile: {
          handle: 'timisims7838',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:43:39.000Z',
        amountCents: 8646
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '08c5edef-2f13-404d-97b5-632afc2f7522',
      content: [
        {
          groupId: '33c8db67-9eca-4617-bb9e-053134305c43',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T16:25:09.982Z',

          createdAt: '2024-06-10T22:31:51.408Z',
          content: [
            {
              caption: '',

              id: 'bceb1805-3776-48f3-9af9-c4380705cb6f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/08c5edef-2f13-404d-97b5-632afc2f7522/ugc/33c8db67-9eca-4617-bb9e-053134305c43/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fb63ca2d-a5c1-4542-bd5e-9dc8f09cb916',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T12:50:30.341Z',

          createdAt: '2024-06-12T18:31:33.794Z',
          content: [
            {
              caption: '',

              id: 'f2d56cb2-0f0c-434e-b69b-3a6dfa13e8fc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/08c5edef-2f13-404d-97b5-632afc2f7522/ugc/fb63ca2d-a5c1-4542-bd5e-9dc8f09cb916/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6efe6e06-8bb5-40b7-9a10-e2ac174a1b0d',
        firstName: 'Dennay',
        lastName: 'Green',
        email: 'dennayg@gmail.com',
        instagramProfile: {
          handle: '_aleasoto',
          followers: 1388
        },
        tiktokProfile: {
          handle: 'lajefa777_',
          followers: 53
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:45:55.000Z',
        amountCents: 10532
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '591cd9dc-7302-433a-85a0-fbe0aa684afa',
      content: [
        {
          groupId: '0fe40561-99e6-4662-a8f8-b808bc9d0e2c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-08T16:34:31.314Z',

          createdAt: '2024-06-05T18:17:18.631Z',
          content: [
            {
              caption: '',

              id: '0823042c-39b6-4eed-9dd0-fdd99b3c2344',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/591cd9dc-7302-433a-85a0-fbe0aa684afa/ugc/0fe40561-99e6-4662-a8f8-b808bc9d0e2c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4559114f-39d3-4dcb-9b32-249cd33bb9f2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/591cd9dc-7302-433a-85a0-fbe0aa684afa/ugc/0fe40561-99e6-4662-a8f8-b808bc9d0e2c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '898d2687-878f-4746-a4b9-feb1482976a5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/591cd9dc-7302-433a-85a0-fbe0aa684afa/ugc/0fe40561-99e6-4662-a8f8-b808bc9d0e2c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd14687aa-2990-4dda-8bff-bbbab3e8df85',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T16:48:37.544Z',

          createdAt: '2024-06-09T21:52:36.668Z',
          content: [
            {
              caption: '',

              id: '59161849-aae6-42db-842d-0a35e0c68308',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/591cd9dc-7302-433a-85a0-fbe0aa684afa/ugc/d14687aa-2990-4dda-8bff-bbbab3e8df85/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5df7d665-3d0c-4092-a65e-29f6815a97bb',
        firstName: 'Christina',
        lastName: 'Horner',
        email: 'christinahorner.collab@gmail.com',
        instagramProfile: {
          handle: 'christinaandreaphotography',
          followers: 199,
          engagementRate: 8.522396742292031
        },
        tiktokProfile: {
          handle: 'christinaahorner',
          followers: 4173,
          engagementRate: 12.264150943396226
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:45:56.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2f50d881-0a4f-4ee7-a8f9-a2c7656798cd',
      content: [
        {
          groupId: 'bec47e09-0fbe-49ea-bb61-1797171106c7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T18:19:30.742Z',

          createdAt: '2024-06-01T20:14:05.256Z',
          content: [
            {
              caption: '',

              id: '538170d7-982f-4081-9b66-fccf9cfc4fde',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2f50d881-0a4f-4ee7-a8f9-a2c7656798cd/ugc/bec47e09-0fbe-49ea-bb61-1797171106c7/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f902dfca-8165-4c8d-b578-3a265899e72f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2f50d881-0a4f-4ee7-a8f9-a2c7656798cd/ugc/bec47e09-0fbe-49ea-bb61-1797171106c7/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0f94f252-5e11-4f69-953e-b99dc934baca',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:19:26.937Z',

          createdAt: '2024-06-01T20:08:08.073Z',
          content: [
            {
              caption: '',

              id: '13a3502c-5f53-4b7e-93ad-4dd1c0936671',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2f50d881-0a4f-4ee7-a8f9-a2c7656798cd/ugc/0f94f252-5e11-4f69-953e-b99dc934baca/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a9d52d62-51cc-4f5d-bece-c9a2ef344673',
        firstName: 'Macie',
        lastName: 'Fox',
        email: 'macieeleece13@gmail.com',
        instagramProfile: {
          handle: 'macieeleece13',
          followers: 2845,
          engagementRate: 1.3239601640304628
        },
        tiktokProfile: {
          handle: 'mama.mace',
          followers: 2176,
          engagementRate: 10.611510791366907
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T16:56:35.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ebcdeb44-1a50-4bc8-9e6f-b69a43cccc20',
      content: [
        {
          groupId: 'd49da433-0519-406d-b43d-76856617df50',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-09-10T12:30:58.389Z',

          createdAt: '2024-09-09T01:25:41.650Z',
          content: [
            {
              caption: '',

              id: '10e641c7-d696-43ab-846d-343b9a99a326',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ebcdeb44-1a50-4bc8-9e6f-b69a43cccc20/ugc/d49da433-0519-406d-b43d-76856617df50/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3c57f377-c552-4b44-abc9-a888729c3e13',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-09-10T12:30:47.816Z',

          createdAt: '2024-09-09T01:26:01.184Z',
          content: [
            {
              caption: '',

              id: '3d29b4a6-b535-4d69-8f86-c63ca69bd52b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ebcdeb44-1a50-4bc8-9e6f-b69a43cccc20/ugc/3c57f377-c552-4b44-abc9-a888729c3e13/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ca4d2227-f9c9-4e76-b57e-953e7b25817a',
        firstName: 'Julia',
        lastName: 'Graves',
        email: 'julia.graves01@gmail.com',
        instagramProfile: {
          handle: 'jules_g5',
          followers: 1025,
          engagementRate: 1.6585365853658538
        },
        tiktokProfile: {
          handle: 'jules_g5',
          followers: 163
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T17:05:41.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ee8dd504-7423-421f-a26f-c665b8f523e6',
      content: [],
      creator: {
        id: '57ef7ffa-4b05-4d5c-8c59-e8ed9db67866',
        firstName: 'Antoneia',
        lastName: 'Mitchell',
        email: 'mitchellantoneia@gmail.com',

        tiktokProfile: {
          handle: 'antoneiam',
          followers: 1992,
          engagementRate: 17.532467532467532
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T17:16:18.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd6b44238-f6fd-44c6-b488-4ae305a5d697',
      content: [],
      creator: {
        id: '812d6d23-3d86-4601-8dba-dd3c97041b49',
        firstName: 'Lissette',
        lastName: 'Lugo',
        email: 'lissylugo25@gmail.com',
        instagramProfile: {
          handle: 'lissytasticx3',
          followers: 957,
          engagementRate: 4.06478578892372
        },
        tiktokProfile: {
          handle: 'lissytasticx3',
          followers: 1476,
          engagementRate: 9.324194324194323
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T17:38:51.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '96a6c835-c926-4d58-8917-6bf0014adbb0',
      content: [
        {
          groupId: '8cb72fee-cdb0-4b1a-8501-e195db761316',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T23:09:15.899Z',

          createdAt: '2024-06-06T00:45:01.329Z',
          content: [
            {
              caption: '',

              id: 'bbd87ba4-1294-4065-94a9-bcdd40839f9f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/96a6c835-c926-4d58-8917-6bf0014adbb0/ugc/8cb72fee-cdb0-4b1a-8501-e195db761316/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '659cacca-1d96-44e6-96c4-1dcdda9ad7ea',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T23:09:19.710Z',

          createdAt: '2024-06-06T00:45:39.596Z',
          content: [
            {
              caption: '',

              id: 'c22a16d5-9d4e-4881-a1ae-148c9eff2e06',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/96a6c835-c926-4d58-8917-6bf0014adbb0/ugc/659cacca-1d96-44e6-96c4-1dcdda9ad7ea/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '645f3c54-a077-4ec2-afb1-c00f31fa1084',
        firstName: 'Jagleny',
        lastName: 'Jimenez',
        email: 'jagleny1017@gmail.com',
        instagramProfile: {
          handle: 'ugcwithjag',
          followers: 1717,
          engagementRate: 2.754804892253931
        },
        tiktokProfile: {
          handle: 'ugcwithjag',
          followers: 1081,
          engagementRate: 2.2633594414030727
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T17:46:39.000Z',
        amountCents: 8704
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '03ba57b7-3e86-40e7-852b-bfb6236b6069',
      content: [],
      creator: {
        id: '181e35c2-2051-4b8e-9280-25332aac8f8f',
        firstName: 'Imani',
        lastName: 'Simpson',
        email: 'simpson_imani@yahoo.com',
        instagramProfile: {
          handle: '_lorriee._',
          followers: 1602
        },
        tiktokProfile: {
          handle: 'imanisimpson178',
          followers: 17
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T17:52:28.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd1ea3ccd-9ff7-4f7e-a6fc-b404c28acb90',
      content: [
        {
          groupId: 'f449a8b9-5c53-4b57-8472-37b74e51f03a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T17:24:18.997Z',

          createdAt: '2024-06-02T19:33:46.906Z',
          content: [
            {
              caption: '',

              id: '23e80dbe-12e1-43fa-8169-d79c9e60bb6f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d1ea3ccd-9ff7-4f7e-a6fc-b404c28acb90/ugc/f449a8b9-5c53-4b57-8472-37b74e51f03a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f136e772-74d9-4c3c-b2ac-3334ef459233',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T17:24:25.293Z',

          createdAt: '2024-06-02T19:39:36.687Z',
          content: [
            {
              caption: '',

              id: '66693df8-a1d5-4cda-83dd-11a0c75cc1c6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d1ea3ccd-9ff7-4f7e-a6fc-b404c28acb90/ugc/f136e772-74d9-4c3c-b2ac-3334ef459233/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b61b925b-eb9b-451c-84fd-59e0a8fa1a72',
        firstName: 'Lashaun',
        lastName: 'Graham',
        email: 'lashaungraham19@gmail.com',
        instagramProfile: {
          handle: '_lashaunnnnn',
          followers: 2117,
          engagementRate: 1.1242324043457723
        },
        tiktokProfile: {
          handle: '_lashaun0725',
          followers: 2544,
          engagementRate: 7.882384569832118
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T17:57:57.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd33dc137-cfea-4862-adb3-40995750e896',
      content: [
        {
          groupId: '30c59f94-5c87-4c11-8bb3-6af3e885c72e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T15:49:02.545Z',

          createdAt: '2024-06-09T22:32:40.978Z',
          content: [
            {
              caption: '',

              id: 'a499b2d3-3fb4-4dc7-ba48-3e9bb8441487',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d33dc137-cfea-4862-adb3-40995750e896/ugc/30c59f94-5c87-4c11-8bb3-6af3e885c72e/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '36d8844c-870a-4065-bcb5-276360e9e2a1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d33dc137-cfea-4862-adb3-40995750e896/ugc/30c59f94-5c87-4c11-8bb3-6af3e885c72e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd2c971fb-0739-4c31-a444-c79b963b79c5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d33dc137-cfea-4862-adb3-40995750e896/ugc/30c59f94-5c87-4c11-8bb3-6af3e885c72e/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bfecf09d-0396-4207-935a-2795e4dadc60',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T06:28:40.446Z',

          createdAt: '2024-06-09T22:34:16.097Z',
          content: [
            {
              caption: '',

              id: '0723a9d5-d893-4c1e-aee5-fd9d21534cc5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d33dc137-cfea-4862-adb3-40995750e896/ugc/bfecf09d-0396-4207-935a-2795e4dadc60/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a7e9061c-39f3-46a2-9033-fa78c852ff6d',
        firstName: 'Shelby',
        lastName: 'Reyes',
        email: 'aislareyescollabs@gmail.com',
        instagramProfile: {
          handle: 'aisla.sky',
          followers: 13392,
          engagementRate: 1.2753882915173238
        },

        youtubeProfile: {
          handle: 'aislasky',
          followers: 29
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T18:05:51.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5f6ffb98-b212-45d2-a46e-7f5090bf3863',
      content: [],
      creator: {
        id: '6e81320c-abd8-4f55-a088-7b2f52814086',
        firstName: 'Tabitha',
        lastName: 'Taylor',
        email: 'jtmrktinggroup@gmail.com',
        instagramProfile: {
          handle: 'herglowdiaries',
          followers: 173,
          engagementRate: 2.485549132947977
        },
        tiktokProfile: {
          handle: 'herglowdiaries',
          followers: 1653,
          engagementRate: 6.401798838104571
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T18:20:34.000Z',
        amountCents: 7370
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '74d622cf-68c0-45e5-91d3-a3a26ce3cdf0',
      content: [],
      creator: {
        id: 'ac1cc4e3-65f7-4d0a-aa1d-cd463c038a8b',
        firstName: 'Codie',
        lastName: 'Rodriguez',
        email: 'oaklynnbrosboutique@gmail.com',
        instagramProfile: {
          handle: 'oaklynnbrosboutique',
          followers: 1038,
          engagementRate: 1.0308285163776492
        },
        tiktokProfile: {
          handle: 'codietheoneandonly98',
          followers: 9990,
          engagementRate: 5.94900849858357
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T19:13:24.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f0e4505b-638b-4b03-9d86-75407209415c',
      content: [
        {
          groupId: '235db1b5-a77e-4f78-b3c9-f3c7b4e3fa3b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T04:10:51.119Z',

          createdAt: '2024-06-03T20:19:31.936Z',
          content: [
            {
              caption: '',

              id: '3ec29097-906e-4640-afa3-125047d5ac69',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f0e4505b-638b-4b03-9d86-75407209415c/ugc/235db1b5-a77e-4f78-b3c9-f3c7b4e3fa3b/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b23341b7-3c79-45b8-bfc4-3a3a6c915552',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f0e4505b-638b-4b03-9d86-75407209415c/ugc/235db1b5-a77e-4f78-b3c9-f3c7b4e3fa3b/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '171b8a77-46de-47f0-8850-3b944da87f4c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f0e4505b-638b-4b03-9d86-75407209415c/ugc/235db1b5-a77e-4f78-b3c9-f3c7b4e3fa3b/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a78bd214-4747-4cd0-b56e-8ee88594f8a2',
        firstName: 'Gayon',
        lastName: 'Reid',
        email: 'gayonreid21@gmail.com',

        tiktokProfile: {
          handle: 'geegee595',
          followers: 4651,
          engagementRate: 28.611872734029575
        },
        youtubeProfile: {
          handle: 'geesworld2432',
          followers: 153
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T19:31:48.000Z',
        amountCents: 8118
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ab316c0c-d552-4197-9963-4be441fa5ed1',
      content: [
        {
          groupId: '6757c294-85b3-4128-a683-ff7ea38faeb8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T13:55:27.341Z',

          createdAt: '2024-06-10T00:53:25.076Z',
          content: [
            {
              caption: '',

              id: 'b4d385a1-46be-4d73-a2bc-3f77fc861a22',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab316c0c-d552-4197-9963-4be441fa5ed1/ugc/6757c294-85b3-4128-a683-ff7ea38faeb8/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8a9be15f-350d-4f37-aecc-ca95782a6528',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab316c0c-d552-4197-9963-4be441fa5ed1/ugc/6757c294-85b3-4128-a683-ff7ea38faeb8/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '078b5089-89e8-4efd-b1cf-8e48ea889557',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab316c0c-d552-4197-9963-4be441fa5ed1/ugc/6757c294-85b3-4128-a683-ff7ea38faeb8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '51be44e5-b713-40d9-a194-5b625bb086b7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:12:12.700Z',

          createdAt: '2024-06-10T03:34:34.408Z',
          content: [
            {
              caption: '',

              id: 'bc7d3aab-89cc-4173-abcb-8bc5ac79f749',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ab316c0c-d552-4197-9963-4be441fa5ed1/ugc/51be44e5-b713-40d9-a194-5b625bb086b7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e5d67997-271a-4c4c-8653-f1ea1406010c',
        firstName: 'Kimberly',
        lastName: 'Cruz',
        email: 'kimschavez18@gmail.com',
        instagramProfile: {
          handle: 'kimschavez',
          followers: 10111,
          engagementRate: 0.9237464147957671
        },
        tiktokProfile: {
          handle: 'kimschavez',
          followers: 505,
          engagementRate: 11.69122496442084
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T20:41:49.000Z',
        amountCents: 7520
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '937015a3-bcd6-49c3-b43b-c2f276b9dbb6',
      content: [
        {
          groupId: '61ffef41-eb10-490e-a87d-f4b1d3ba9a08',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T03:07:56.650Z',

          createdAt: '2024-06-02T20:59:49.197Z',
          content: [
            {
              caption: '',

              id: '8074650e-dcc4-4c51-99a8-4b54c8dd3b04',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/937015a3-bcd6-49c3-b43b-c2f276b9dbb6/ugc/61ffef41-eb10-490e-a87d-f4b1d3ba9a08/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '47c3a854-1923-476a-aeee-e0c0c8b476e8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/937015a3-bcd6-49c3-b43b-c2f276b9dbb6/ugc/61ffef41-eb10-490e-a87d-f4b1d3ba9a08/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '998c7a0b-4175-4e3b-be49-eff7da2ecc2c',
        firstName: 'Taylor',
        lastName: 'Wade',
        email: 'doingmybesttok6@gmail.com',

        tiktokProfile: {
          handle: 'doingmybest4',
          followers: 1329,
          engagementRate: 12.18836565096953
        },
        youtubeProfile: {
          handle: 'doingmybestt8',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T20:43:26.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c562c872-d2ba-4071-b0ec-9bbbd04d1e3d',
      content: [
        {
          groupId: '621d23c5-de2b-47c3-910f-bacab2527c19',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T14:12:52.417Z',

          createdAt: '2024-06-14T17:37:03.219Z',
          content: [
            {
              caption: '',

              id: '4f62878b-5fd5-43bf-869a-88d845d9ffee',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c562c872-d2ba-4071-b0ec-9bbbd04d1e3d/ugc/621d23c5-de2b-47c3-910f-bacab2527c19/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd4d40fae-0a4a-4f74-9449-d78fbfd9a997',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c562c872-d2ba-4071-b0ec-9bbbd04d1e3d/ugc/621d23c5-de2b-47c3-910f-bacab2527c19/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cfffd673-35ab-4432-8f2f-c4fe0894b314',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T15:34:21.107Z',

          createdAt: '2024-06-14T18:12:51.973Z',
          content: [
            {
              caption: '',

              id: '6c0482d9-e4bd-47ed-938e-e2dfafba7949',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c562c872-d2ba-4071-b0ec-9bbbd04d1e3d/ugc/cfffd673-35ab-4432-8f2f-c4fe0894b314/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '98264f43-3f54-461d-b453-5524d774920e',
        firstName: 'Katie',
        lastName: 'Ensminger',
        email: 'katieannensminger@gmail.com',
        instagramProfile: {
          handle: 'katieannensminger',
          followers: 5464,
          engagementRate: 0.8418740849194729
        },
        tiktokProfile: {
          handle: 'katieannensminger',
          followers: 44
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T20:52:38.000Z',
        amountCents: 7443
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '17d1babe-0914-4411-9a73-4aa65bed26f4',
      content: [
        {
          groupId: '78c9bb5f-0e67-4b28-9994-b28aa5ef4438',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T19:26:01.096Z',

          createdAt: '2024-06-03T01:45:35.870Z',
          content: [
            {
              caption: '',

              id: '1ea8e7c6-a52c-492b-bcb7-e4e9a6b3d436',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/17d1babe-0914-4411-9a73-4aa65bed26f4/ugc/78c9bb5f-0e67-4b28-9994-b28aa5ef4438/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '168a7a2b-a924-4049-bbae-43f512a988a0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/17d1babe-0914-4411-9a73-4aa65bed26f4/ugc/78c9bb5f-0e67-4b28-9994-b28aa5ef4438/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'aeae2041-ad62-4d65-95ea-3a4999484d53',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/17d1babe-0914-4411-9a73-4aa65bed26f4/ugc/78c9bb5f-0e67-4b28-9994-b28aa5ef4438/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bb1c25d2-c6ca-484d-aff7-cf1848b52868',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-09T00:17:00.181Z',

          createdAt: '2024-06-03T17:15:02.284Z',
          content: [
            {
              caption: '',

              id: 'e5c48bf3-9ce9-4757-b47a-d5128dd3cb6d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/17d1babe-0914-4411-9a73-4aa65bed26f4/ugc/bb1c25d2-c6ca-484d-aff7-cf1848b52868/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '98b44e79-9d4e-4aa0-9fa1-b2b7f6117c65',
        firstName: 'Carolina',
        lastName: 'Cacapava ',
        email: 'cacapava.carolina@gmail.com',
        instagramProfile: {
          handle: 'carolcacapava',
          followers: 1760,
          engagementRate: 3.9261363636363638
        },
        tiktokProfile: {
          handle: 'carolina.cacapava1',
          followers: 1362,
          engagementRate: 1.02843315184513
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T21:44:22.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '56fb40c8-e1c5-44af-9a54-ee3bae85a58e',
      content: [
        {
          groupId: '55633c10-adf7-4449-a794-7f84161648f5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T12:50:30.317Z',

          createdAt: '2024-06-06T20:29:17.726Z',
          content: [
            {
              caption: '',

              id: '8735259a-14e5-4e5c-98e1-d28a61459bf0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56fb40c8-e1c5-44af-9a54-ee3bae85a58e/ugc/55633c10-adf7-4449-a794-7f84161648f5/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fdb9d4aa-75a5-4f12-a5c3-c31f2de8030e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56fb40c8-e1c5-44af-9a54-ee3bae85a58e/ugc/55633c10-adf7-4449-a794-7f84161648f5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '28dc8a27-1449-4a1b-a5ad-10aba8d5cc09',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T13:57:58.451Z',

          createdAt: '2024-06-06T20:25:53.730Z',
          content: [
            {
              caption: '',

              id: '7af14863-d8e5-4756-b172-6873afdd03a1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/56fb40c8-e1c5-44af-9a54-ee3bae85a58e/ugc/28dc8a27-1449-4a1b-a5ad-10aba8d5cc09/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '641363e1-44ac-409f-92a9-e06a54c7c7ab',
        firstName: 'Chiss',
        lastName: 'Batista',
        email: 'chissrachel15@gmail.com',
        instagramProfile: {
          handle: 'chissbatista',
          followers: 871,
          engagementRate: 6.486796785304248
        },
        tiktokProfile: {
          handle: 'chissrachel15',
          followers: 2478,
          engagementRate: 6.896551724137931
        },
        youtubeProfile: {
          handle: 'chissbatista1551',
          followers: 4
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T22:18:55.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ae15b0c2-a09b-479a-bc9a-652a4ebf04bc',
      content: [
        {
          groupId: '64c8bf7f-ae92-4670-ab40-1b1bd1655930',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T17:07:16.593Z',

          createdAt: '2024-06-10T18:21:26.422Z',
          content: [
            {
              caption: '',

              id: 'b600a58b-217c-4dac-846d-ec69d8579113',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae15b0c2-a09b-479a-bc9a-652a4ebf04bc/ugc/64c8bf7f-ae92-4670-ab40-1b1bd1655930/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd8c76b07-3977-4675-80f7-45633e132640',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T17:07:12.262Z',

          createdAt: '2024-06-19T06:46:17.240Z',
          content: [
            {
              caption: '',

              id: '8bc08f1c-6893-4639-8d9e-fc31be6bab53',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae15b0c2-a09b-479a-bc9a-652a4ebf04bc/ugc/d8c76b07-3977-4675-80f7-45633e132640/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bd6ec6fd-ada8-4b93-a63a-edec223f3893',
        firstName: 'Erika',
        lastName: 'Campos',
        email: 'erikahcampos91@gmail.com',
        instagramProfile: {
          handle: 'erikahcampos',
          followers: 3750,
          engagementRate: 2.2720000000000002
        },
        tiktokProfile: {
          handle: 'erikahcampos91',
          followers: 1288,
          engagementRate: 7.732383895174594
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-30T23:16:38.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2cec09ed-4de9-4113-a388-10d7123eaa8b',
      content: [
        {
          groupId: 'de5a502b-b95a-4ebf-9622-17506a9e5eab',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T21:10:02.631Z',

          createdAt: '2024-06-05T09:17:15.233Z',
          content: [
            {
              caption: '',

              id: '596799b9-2127-4e42-8e59-8d1cb4ca8baa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2cec09ed-4de9-4113-a388-10d7123eaa8b/ugc/de5a502b-b95a-4ebf-9622-17506a9e5eab/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4995633b-7be1-409a-88cf-dac8f9487acd',
        firstName: 'Trissia',
        lastName: 'Lawrence',
        email: 'jaysisajamea@gmail.com',

        tiktokProfile: {
          handle: 'certifiedhustler05',
          followers: 7342,
          engagementRate: 6.367924528301887
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T01:50:47.000Z',
        amountCents: 7443
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '867bc2c9-2725-44fa-b448-a38630555671',
      content: [],
      creator: {
        id: 'cb053ac7-545f-4fed-ad20-da90b75d6b10',
        firstName: 'Sherry',
        lastName: 'Fink',
        email: 'sherryw@grandecom.net',
        instagramProfile: {
          handle: 'sherrylynn27',
          followers: 2096,
          engagementRate: 0.8874045801526719
        },
        tiktokProfile: {
          handle: 'sherrylynn_27',
          followers: 5534,
          engagementRate: 9.234828496042216
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T02:25:57.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7f78e234-8199-403b-8baa-cec58765bcad',
      content: [
        {
          groupId: '89a73a12-5b8f-4de7-aeb5-f737ed9c8275',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T19:04:51.584Z',

          createdAt: '2024-06-11T02:37:29.763Z',
          content: [
            {
              caption: '',

              id: '7736d547-79dc-4236-9297-b23e79b6d3f2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7f78e234-8199-403b-8baa-cec58765bcad/ugc/89a73a12-5b8f-4de7-aeb5-f737ed9c8275/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '21909c45-60f5-4cd6-b233-def70553cee8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T19:05:03.911Z',

          createdAt: '2024-06-11T02:38:31.805Z',
          content: [
            {
              caption: '',

              id: '43207109-ee31-452a-ad3e-e5dfc059f24b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7f78e234-8199-403b-8baa-cec58765bcad/ugc/21909c45-60f5-4cd6-b233-def70553cee8/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e300f90c-e3dc-4f94-b93c-e711514a8a77',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7f78e234-8199-403b-8baa-cec58765bcad/ugc/21909c45-60f5-4cd6-b233-def70553cee8/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6727b4f0-b932-422c-8c0e-e7a15e78ec2d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7f78e234-8199-403b-8baa-cec58765bcad/ugc/21909c45-60f5-4cd6-b233-def70553cee8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5b98e60d-8ce6-464d-b114-5a04896bd9ae',
        firstName: 'Aaliyah',
        lastName: 'Arreola',
        email: 'aaliyaharreola@yahoo.com',

        tiktokProfile: {
          handle: 'aaliyaahh03',
          followers: 3784,
          engagementRate: 6.7497226369923
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T03:06:09.000Z',
        amountCents: 8256
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7523b8e2-a263-4f54-aded-74b309189116',
      content: [],
      creator: {
        id: '1cc26f5e-1122-4dc9-828f-650c780ec9e9',
        firstName: 'Tavar',
        lastName: 'Harriott',
        email: 'tavarjh@gmail.com',
        instagramProfile: {
          handle: 'streettoursjamaica',
          followers: 46,
          engagementRate: 35.869565217391305
        },
        tiktokProfile: {
          handle: 'tavarjh',
          followers: 1229,
          engagementRate: 3.265940902021773
        },
        youtubeProfile: {
          handle: 'tavarharriott5500',
          followers: 98
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T03:32:43.000Z',
        amountCents: 17760
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0b4bf7fd-b104-427c-a508-0e04d990d170',
      content: [],
      creator: {
        id: 'c9f6c768-2f42-4a27-a1c7-3de52d877d2e',
        firstName: 'Headley',
        lastName: 'Martha',
        email: 'janicemartha18@yahoo.com',

        tiktokProfile: {
          handle: 'one1_martha',
          followers: 1417
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T05:29:12.000Z',
        amountCents: 4706
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b6c7862f-7b90-4a61-bf54-e4b394886e16',
      content: [
        {
          groupId: '8a721ffc-6b8a-40da-aaa5-bbf877e6723f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T19:06:22.533Z',

          createdAt: '2024-06-25T17:50:30.312Z',
          content: [
            {
              caption: '',

              id: '56a511da-671e-4bb4-b789-09607d36b535',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6c7862f-7b90-4a61-bf54-e4b394886e16/ugc/8a721ffc-6b8a-40da-aaa5-bbf877e6723f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f7c92e46-77f7-4241-a1aa-c7445facf5a2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T14:10:05.689Z',

          createdAt: '2024-06-23T04:20:59.267Z',
          content: [
            {
              caption: '',

              id: 'eed4113e-970f-415d-9bca-7393a7c73f5d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6c7862f-7b90-4a61-bf54-e4b394886e16/ugc/f7c92e46-77f7-4241-a1aa-c7445facf5a2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2bcd0408-84d6-4bfc-8371-7efebe59c854',
        firstName: 'Leona',
        lastName: 'Williams',
        email: 'williamsleona759@gmail.com',
        instagramProfile: {
          handle: 'xoxo.lillyyyy',
          followers: 1581,
          engagementRate: 0.4013519222644698
        },
        tiktokProfile: {
          handle: 'dailyydoseoflee',
          followers: 1170,
          engagementRate: 7.250755287009064
        },
        youtubeProfile: {
          handle: 'vibeewithlee',
          followers: 123
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T06:04:21.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '504929e6-2ed6-431d-a815-84572ac25e21',
      content: [
        {
          groupId: 'c99064e2-7713-482e-b4a7-ead18654a02e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:20:22.278Z',

          createdAt: '2024-06-03T22:38:48.580Z',
          content: [
            {
              caption: '',

              id: 'dadbab73-7c79-4aff-bb27-b1e10bce283f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/504929e6-2ed6-431d-a815-84572ac25e21/ugc/c99064e2-7713-482e-b4a7-ead18654a02e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '9f8ee3b9-e0d5-42a6-92f5-a04ffd2a439a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-05T05:26:47.174Z',

          createdAt: '2024-06-03T22:10:44.169Z',
          content: [
            {
              caption: '',

              id: 'e2473627-a518-435a-b2c0-b16ad936b69c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/504929e6-2ed6-431d-a815-84572ac25e21/ugc/9f8ee3b9-e0d5-42a6-92f5-a04ffd2a439a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0faa26f5-e2b8-481e-8320-b6d47afaa058',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/504929e6-2ed6-431d-a815-84572ac25e21/ugc/9f8ee3b9-e0d5-42a6-92f5-a04ffd2a439a/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '52574d62-ba31-439b-a420-6f2fda3eb2c8',
        firstName: 'Olivia',
        lastName: 'Tuggle',
        email: 'otugglecollabs@gmail.com',
        instagramProfile: {
          handle: 'oliviatuggle',
          followers: 951,
          engagementRate: 1.1987381703470033
        },
        tiktokProfile: {
          handle: 'oliviatuggle',
          followers: 2605,
          engagementRate: 10.7408929926638
        },
        youtubeProfile: {
          handle: 'oliviatuggle',
          followers: 53
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T07:55:36.000Z',
        amountCents: 8256
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '68ca8fbf-6499-49df-804a-182f35f16ccd',
      content: [
        {
          groupId: '66c561f9-e618-4519-9536-1b3570d7e938',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T14:21:32.558Z',

          createdAt: '2024-06-15T17:31:38.333Z',
          content: [
            {
              caption: '',

              id: 'faf9d55b-ccd2-4a78-a4ee-3b429551d90b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/68ca8fbf-6499-49df-804a-182f35f16ccd/ugc/66c561f9-e618-4519-9536-1b3570d7e938/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8be6751c-cf93-4e2b-a271-3b56d9d971c6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/68ca8fbf-6499-49df-804a-182f35f16ccd/ugc/66c561f9-e618-4519-9536-1b3570d7e938/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bf6224d6-4edd-472f-ae42-27a5b63f80e1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/68ca8fbf-6499-49df-804a-182f35f16ccd/ugc/66c561f9-e618-4519-9536-1b3570d7e938/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c703823d-937d-4b4c-b0fe-682b39fb2e89',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:12:06.682Z',

          createdAt: '2024-06-10T23:54:03.294Z',
          content: [
            {
              caption: '',

              id: '6692ea17-0008-4c4e-a1de-5e5f465e1fa4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/68ca8fbf-6499-49df-804a-182f35f16ccd/ugc/c703823d-937d-4b4c-b0fe-682b39fb2e89/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e089293b-a3bd-492d-9b0f-b29b83929ef4',
        firstName: 'Ariel',
        lastName: 'Simon',
        email: 'arielsimon56@gmail.com',

        tiktokProfile: {
          handle: 'cece_cece4',
          followers: 13607,
          engagementRate: 14.285714285714285
        },
        youtubeProfile: {
          handle: 'arielcecesimon556',
          followers: 37
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T08:55:23.000Z',
        amountCents: 8644
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e517ab7a-0e79-4c98-bec1-147b133ffd71',
      content: [],
      creator: {
        id: 'fed64309-b8a7-4ff5-9c18-167d838cb8d2',
        firstName: 'Monique',
        lastName: 'Wilson',
        email: 'wilsonmonique355@gmail.com',
        instagramProfile: {
          handle: 'kollectionz_by_mo',
          followers: 165,
          engagementRate: 12.727272727272727
        },
        tiktokProfile: {
          handle: 'momo_shanii',
          followers: 2773,
          engagementRate: 7.348560079443893
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T09:02:02.000Z',
        amountCents: 11340
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6d45e563-07b1-4e98-b8fa-7446a222ac02',
      content: [
        {
          groupId: 'dc61c47d-b71c-4424-85d3-89869aac3855',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T17:47:32.605Z',

          createdAt: '2024-06-16T08:30:39.437Z',
          content: [
            {
              caption: '',

              id: 'db132123-297e-433d-ab4c-d3c59d3931a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6d45e563-07b1-4e98-b8fa-7446a222ac02/ugc/dc61c47d-b71c-4424-85d3-89869aac3855/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '353ff3f0-5a75-4c3f-8775-b299fddf77dd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T15:24:32.464Z',

          createdAt: '2024-06-16T08:31:24.621Z',
          content: [
            {
              caption: '',

              id: '16361bf7-fa7f-4022-9600-0b1d1cc624c4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6d45e563-07b1-4e98-b8fa-7446a222ac02/ugc/353ff3f0-5a75-4c3f-8775-b299fddf77dd/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f07df3d4-9b01-44c3-9d56-72c5fa0214bd',
        firstName: 'Brittany',
        lastName: 'Austin',
        email: 'timetotalkwithb@gmail.com',
        instagramProfile: {
          handle: 'brittanyhaleyes',
          followers: 12950,
          engagementRate: 4.677220077220078
        },
        tiktokProfile: {
          handle: 'brittanyhaleyes',
          followers: 926,
          engagementRate: 9.558823529411764
        },
        youtubeProfile: {
          handle: 'timetotalkwithb',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T09:14:52.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6c0da403-e010-473f-9eff-497411052f94',
      content: [],
      creator: {
        id: '1a19fe6d-9233-45ca-9dc3-a0ba6b0159c6',
        firstName: 'Taylor',
        lastName: 'Hoebbel',
        email: 'taylorhoebbel2@gmail.com',
        instagramProfile: {
          handle: 'tayyhoe',
          followers: 1006,
          engagementRate: 2.8827037773359843
        },
        tiktokProfile: {
          handle: 'tayyhoe',
          followers: 327
        },
        youtubeProfile: {
          handle: 'taylorhoebbel',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T10:31:33.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a579d5a4-f7d5-447c-9aa4-3e4f60e5e773',
      content: [],
      creator: {
        id: 'e28cbb28-bf0d-4ce6-b58f-5fbd72a1b913',
        firstName: 'renaco',
        lastName: 'jamieson',
        email: 'renacojamieson18@gmail.com',
        instagramProfile: {
          handle: 'reneditz_',
          followers: 59,
          engagementRate: 0.08103448275862069
        },
        tiktokProfile: {
          handle: 'naco.876',
          followers: 2252,
          engagementRate: 1.9642333626502493
        },
        youtubeProfile: {
          handle: 'renacojamieson4100',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:06:19.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8619702f-2b9c-4fd9-9bec-f36d32350188',
      content: [],
      creator: {
        id: 'a8f809e5-6287-40b1-b4f7-34b249b1cc41',
        firstName: 'Lindsey',
        lastName: 'Amore',
        email: 'lindseyamore@yahoo.com',

        tiktokProfile: {
          handle: 'itssolinz',
          followers: 1955,
          engagementRate: 7.123311751091318
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:11:12.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eff2e3e1-bf2b-4976-9965-ff0e1ee36bca',
      content: [
        {
          groupId: 'e9c35ed7-72ec-416d-8c60-acd3d74557e5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:19:24.255Z',

          createdAt: '2024-06-11T18:50:26.359Z',
          content: [
            {
              caption: '',

              id: '170b1fe0-e6cd-449e-a51b-014817efe6c8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eff2e3e1-bf2b-4976-9965-ff0e1ee36bca/ugc/e9c35ed7-72ec-416d-8c60-acd3d74557e5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '966460b2-c564-4f03-9503-be98929b17d0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T14:06:17.139Z',

          createdAt: '2024-06-11T18:51:27.235Z',
          content: [
            {
              caption: '',

              id: '14310758-b223-4930-91ed-b4fe6d605066',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eff2e3e1-bf2b-4976-9965-ff0e1ee36bca/ugc/966460b2-c564-4f03-9503-be98929b17d0/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9dbe71f7-accd-4e81-9f78-e9aaa2dcf7d4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eff2e3e1-bf2b-4976-9965-ff0e1ee36bca/ugc/966460b2-c564-4f03-9503-be98929b17d0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0bb283f9-8ce2-497e-ae98-737ce92b76cd',
        firstName: 'Thalia',
        lastName: 'aguilera',
        email: 'retrodenverfinds@gmail.com',
        instagramProfile: {
          handle: 'retrodenverfinds',
          followers: 3365,
          engagementRate: 2.3506686478454677
        },
        tiktokProfile: {
          handle: 'retrodenverfinds',
          followers: 115,
          engagementRate: 6.086956521739131
        },
        youtubeProfile: {
          handle: 'retrodenverfinds',
          followers: 47
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:11:25.000Z',
        amountCents: 7587
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cd96c320-c8dc-4b24-870d-4d537930dcdd',
      content: [
        {
          groupId: '93e16da6-ef42-410e-abde-69a37972ba10',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T20:52:52.542Z',

          createdAt: '2024-06-04T22:57:55.694Z',
          content: [
            {
              caption: '',

              id: '841318d9-54e7-4387-ad60-ed2da4530d95',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cd96c320-c8dc-4b24-870d-4d537930dcdd/ugc/93e16da6-ef42-410e-abde-69a37972ba10/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fb37ec83-5d53-42e0-9a3a-404fb02a5255',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cd96c320-c8dc-4b24-870d-4d537930dcdd/ugc/93e16da6-ef42-410e-abde-69a37972ba10/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8c307183-7d10-4efa-b786-bd83263c0f1a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T06:11:39.660Z',

          createdAt: '2024-06-04T22:53:38.554Z',
          content: [
            {
              caption: '',

              id: '32aa283f-00b3-4a35-a6bf-f3dfdd1d5725',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cd96c320-c8dc-4b24-870d-4d537930dcdd/ugc/8c307183-7d10-4efa-b786-bd83263c0f1a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '12fa4cc8-110b-43e5-8ae4-293c999963c1',
        firstName: 'Celene',
        lastName: 'Rivera',
        email: 'celene.rivera93@gmail.com',
        instagramProfile: {
          handle: 'nenex2b',
          followers: 54,
          engagementRate: 25.102880658436217
        },
        tiktokProfile: {
          handle: 'nenex2b',
          followers: 5048,
          engagementRate: 3.962716888301894
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:12:17.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '73ca0514-20de-475e-b77b-233ef2075d1f',
      content: [
        {
          groupId: 'c1096dc7-e5e8-41d9-9b48-9e28a7b95cb5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T12:35:56.573Z',

          createdAt: '2024-06-09T01:58:07.851Z',
          content: [
            {
              caption: '',

              id: 'f63c59f5-ef98-4d50-9439-db8f478f5871',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/73ca0514-20de-475e-b77b-233ef2075d1f/ugc/c1096dc7-e5e8-41d9-9b48-9e28a7b95cb5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7d9fd205-8030-4964-863a-94ef90a388d9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T13:45:45.486Z',

          createdAt: '2024-06-09T02:05:45.473Z',
          content: [
            {
              caption: '',

              id: 'd583964e-427d-4590-9c71-4b69644c9fce',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/73ca0514-20de-475e-b77b-233ef2075d1f/ugc/7d9fd205-8030-4964-863a-94ef90a388d9/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '949b37ba-e20d-4601-89be-8703a0584204',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/73ca0514-20de-475e-b77b-233ef2075d1f/ugc/7d9fd205-8030-4964-863a-94ef90a388d9/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '03fefc70-540a-4ec9-8e69-090d22b68596',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/73ca0514-20de-475e-b77b-233ef2075d1f/ugc/7d9fd205-8030-4964-863a-94ef90a388d9/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '98b5b087-d405-43d7-825f-fabb777f3670',
        firstName: 'Thomas',
        lastName: 'Murphy',
        email: 'thomasmurphy27@gmail.com',
        instagramProfile: {
          handle: 'tmtsystems',
          followers: 1746,
          engagementRate: 1.4547537227949598
        },
        tiktokProfile: {
          handle: 'thomasmurphy450',
          followers: 19
        },
        youtubeProfile: {
          handle: 'tmtsystemsatl'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:23:00.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a2f7e5fd-8380-4d01-9cdf-49c1c8931353',
      content: [
        {
          groupId: 'eb217002-6ebe-460f-9559-53472f060d08',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T04:18:28.399Z',

          createdAt: '2024-06-06T00:48:51.840Z',
          content: [
            {
              caption: '',

              id: 'b776fa7d-de6c-4641-a94d-dee1e762f76f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a2f7e5fd-8380-4d01-9cdf-49c1c8931353/ugc/eb217002-6ebe-460f-9559-53472f060d08/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9c2e52a0-30c6-47c5-88c6-8d8313633096',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a2f7e5fd-8380-4d01-9cdf-49c1c8931353/ugc/eb217002-6ebe-460f-9559-53472f060d08/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2a729284-a854-480e-a014-e1aedb7d6fb7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a2f7e5fd-8380-4d01-9cdf-49c1c8931353/ugc/eb217002-6ebe-460f-9559-53472f060d08/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '390171a4-1585-4d0c-8e8a-cc35eb04043f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:12:15.924Z',

          createdAt: '2024-06-07T06:06:21.776Z',
          content: [
            {
              caption: '',

              id: 'c1d1fac3-b8bd-4570-ad2c-072296a3d08c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a2f7e5fd-8380-4d01-9cdf-49c1c8931353/ugc/390171a4-1585-4d0c-8e8a-cc35eb04043f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7dd2cf7c-09cd-4621-a882-dcd75e2d769e',
        firstName: 'zulkhumar',
        lastName: 'dursunova',
        email: 'zulkhumar.dursunova@gmail.com',
        instagramProfile: {
          handle: 'zuliyaa_',
          followers: 2916,
          engagementRate: 4.828532235939644
        },
        tiktokProfile: {
          handle: 'zuliyaa_',
          followers: 131,
          engagementRate: 10.326086956521738
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:24:12.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1943bff3-333c-4605-9722-a2c539ea26d8',
      content: [
        {
          groupId: 'a74483c3-48fb-46f4-aad0-61cb144b4670',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:02:15.054Z',

          createdAt: '2024-06-03T04:25:28.843Z',
          content: [
            {
              caption: '',

              id: '01ba6481-fd64-4d77-b5fd-daba92e4a5fe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1943bff3-333c-4605-9722-a2c539ea26d8/ugc/a74483c3-48fb-46f4-aad0-61cb144b4670/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '11568cd1-7ecb-41ab-a0fd-d3e0b26137d4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T03:12:18.779Z',

          createdAt: '2024-06-02T21:43:47.173Z',
          content: [
            {
              caption: '',

              id: 'b989c824-0b2a-4411-8373-5f7a092ea8c7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1943bff3-333c-4605-9722-a2c539ea26d8/ugc/11568cd1-7ecb-41ab-a0fd-d3e0b26137d4/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e5ee65e1-0779-436b-901b-a02946127b7b',
        firstName: 'Thu',
        lastName: 'Nguyen',
        email: 'ntat259@gmail.com',
        instagramProfile: {
          handle: 'thu.beo259',
          followers: 184,
          engagementRate: 5.978260869565218
        },
        tiktokProfile: {
          handle: 'motnguyen558',
          followers: 1
        },
        youtubeProfile: {
          handle: 'golds7803',
          followers: 1090
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:32:08.000Z',
        amountCents: 8666
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4a567926-1391-4d0c-b08e-c91580a3260e',
      content: [],
      creator: {
        id: '32ffd04a-6dee-4842-a3eb-f416f06af67e',
        firstName: 'Stacey Ann',
        lastName: 'Gordon',
        email: 'staceyannforrester@gmail.com',

        tiktokProfile: {
          handle: 'cyrenestaceyann',
          followers: 1158,
          engagementRate: 5.387709536804055
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:32:24.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4352cad7-07c8-49f5-943d-c6ce62108539',
      content: [],
      creator: {
        id: 'd0a64837-e301-47af-8eaf-1d4b1e5dc952',
        firstName: 'Jessica',
        lastName: 'Drummond',
        email: 'jdrummond@thermotekservice.com',
        instagramProfile: {
          handle: 'jess4review',
          followers: 2249,
          engagementRate: 9.659848821698533
        },
        tiktokProfile: {
          handle: 'jess_lynn_29',
          followers: 25,
          engagementRate: 2.19435736677116
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:33:24.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '735c88e3-7c66-4a43-bf39-f55c2105d5a4',
      content: [
        {
          groupId: '2cde0ef3-db13-4816-bd74-749cae2323d8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T12:34:23.581Z',

          createdAt: '2024-06-03T21:32:29.737Z',
          content: [
            {
              caption: '',

              id: 'fb9a9623-16b1-47eb-8580-371702f67547',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/735c88e3-7c66-4a43-bf39-f55c2105d5a4/ugc/2cde0ef3-db13-4816-bd74-749cae2323d8/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '39187a13-0b98-4818-949a-c5d93d800d72',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/735c88e3-7c66-4a43-bf39-f55c2105d5a4/ugc/2cde0ef3-db13-4816-bd74-749cae2323d8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '16e9a700-e6bf-4268-945e-b9e30b9dd0a8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:24:30.947Z',

          createdAt: '2024-06-04T00:57:41.653Z',
          content: [
            {
              caption: '',

              id: '6e8cb6a4-bf4e-4863-81b7-eb2442a3c227',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/735c88e3-7c66-4a43-bf39-f55c2105d5a4/ugc/16e9a700-e6bf-4268-945e-b9e30b9dd0a8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '66aa96c1-2d4f-4bb5-8850-cb34e3b4e783',
        firstName: 'Shelby',
        lastName: 'Henritzy',
        email: 'omhcreations@gmail.com',

        tiktokProfile: {
          handle: 'lowensteindesigns',
          followers: 5520,
          engagementRate: 14.285714285714285
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T16:42:02.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ad591a99-1b2f-49fb-87cf-f10808e8b32a',
      content: [
        {
          groupId: 'c9542981-3e7b-4426-82d8-89d9bd00fbf8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T15:51:04.658Z',

          createdAt: '2024-06-18T00:57:54.975Z',
          content: [
            {
              caption: '',

              id: 'e61e458d-a2fb-4c72-8b27-114758edae67',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad591a99-1b2f-49fb-87cf-f10808e8b32a/ugc/c9542981-3e7b-4426-82d8-89d9bd00fbf8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '60dbdeb6-9bf4-4b36-ab64-9953bc462b4b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T04:27:20.486Z',

          createdAt: '2024-06-18T04:27:43.476Z',
          content: [
            {
              caption: '',

              id: '1f86a8dc-d030-4491-82df-65aaaa39c70e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad591a99-1b2f-49fb-87cf-f10808e8b32a/ugc/60dbdeb6-9bf4-4b36-ab64-9953bc462b4b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f504107a-a03b-4f18-ac41-10c173dd861f',
        firstName: 'Nikki',
        lastName: 'Marie',
        email: 'nikkionbeauty@gmail.com',
        instagramProfile: {
          handle: 'cinemabarbie',
          followers: 5897,
          engagementRate: 0.7122265558758691
        },
        tiktokProfile: {
          handle: 'veuvebarbie',
          followers: 3547
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T17:12:47.000Z',
        amountCents: 7585
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0c7339c8-68b5-40b5-bf78-db05803fbcb3',
      content: [
        {
          groupId: '113da678-97cf-4915-83ac-e938d976c88c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T17:55:21.757Z',

          createdAt: '2024-06-01T22:53:55.338Z',
          content: [
            {
              caption: '',

              id: '266df23e-6b37-4205-8b65-2fec008308c5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c7339c8-68b5-40b5-bf78-db05803fbcb3/ugc/113da678-97cf-4915-83ac-e938d976c88c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '30f2bcb3-d5a1-4579-81b2-70728c949372',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c7339c8-68b5-40b5-bf78-db05803fbcb3/ugc/113da678-97cf-4915-83ac-e938d976c88c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6279b236-8ac1-46ef-a4a9-842f9510d5f2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c7339c8-68b5-40b5-bf78-db05803fbcb3/ugc/113da678-97cf-4915-83ac-e938d976c88c/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '36b04a07-aa49-4471-9df4-43e3d312ddc4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T19:56:17.858Z',

          createdAt: '2024-06-01T22:53:11.793Z',
          content: [
            {
              caption: '',

              id: '9da984df-d950-4f1c-b2d2-f64b556b94b4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c7339c8-68b5-40b5-bf78-db05803fbcb3/ugc/36b04a07-aa49-4471-9df4-43e3d312ddc4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '31712833-84e4-493e-ad0e-a5803b87f0d2',
        firstName: 'Eddie',
        lastName: 'Moreno',
        email: 'edmunda95@icloud.com',
        instagramProfile: {
          handle: 'edmundamoreno',
          followers: 1325,
          engagementRate: 0.07156177156177157
        },
        tiktokProfile: {
          handle: 'unmundoenormee',
          followers: 1047,
          engagementRate: 4.232804232804233
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T17:22:26.000Z',
        amountCents: 8688
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9ae39549-5230-472b-aa24-45b89bd57d65',
      content: [],
      creator: {
        id: '655ad360-6a35-41df-8bbe-33ff400ea93a',
        firstName: 'Makenzie',
        lastName: 'Benton',
        email: 'makenziesavoie@gmail.com',
        instagramProfile: {
          handle: 'makkbenton',
          followers: 3851,
          engagementRate: 4.531290573876915
        },
        tiktokProfile: {
          handle: 'makkbenton',
          followers: 162,
          engagementRate: 5.555555555555555
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T17:33:44.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '63b0e7a2-3fdf-48ff-a362-adbd21f7eccd',
      content: [],
      creator: {
        id: '6257ebd8-0949-4ec9-bde2-b26555c2c555',
        firstName: 'Kyra',
        lastName: 'Crosby',
        email: 'kyracrosby97@yahoo.com',
        instagramProfile: {
          handle: 'kingkyrie__g',
          followers: 13142,
          engagementRate: 4.0014837924212445
        },
        tiktokProfile: {
          handle: 'colormekyraa',
          followers: 2136,
          engagementRate: 8.987341772151899
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T17:36:43.000Z',
        amountCents: 7699
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '31b940d2-89ff-44f3-a2c5-30bd01edc992',
      content: [
        {
          groupId: 'dd33ec20-1d1b-417b-8fa9-40b1b09b0391',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T11:53:34.449Z',

          createdAt: '2024-06-07T01:11:16.790Z',
          content: [
            {
              caption: '',

              id: '76992ecb-a894-41a1-a837-8d3fb100f0d0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/31b940d2-89ff-44f3-a2c5-30bd01edc992/ugc/dd33ec20-1d1b-417b-8fa9-40b1b09b0391/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '9ede065a-d8e9-40e8-af24-47fcc6c7c614',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T13:12:34.675Z',

          createdAt: '2024-06-07T01:13:54.492Z',
          content: [
            {
              caption: '',

              id: '18e7ea6a-e182-402d-ae77-56654922bbc6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/31b940d2-89ff-44f3-a2c5-30bd01edc992/ugc/9ede065a-d8e9-40e8-af24-47fcc6c7c614/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f08fb899-d539-48ba-9915-7030e63fbfca',
        firstName: 'Tabitha',
        lastName: 'Sandiford',
        email: 'tabithawheeler86@gmail.com',
        instagramProfile: {
          handle: 'tabithamsandiford',
          followers: 302,
          engagementRate: 1.2771996215704824
        },
        tiktokProfile: {
          handle: 'tabitha_marie86',
          followers: 2965,
          engagementRate: 2.2315202231520224
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T17:38:57.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ad0d9f0d-2b82-4da9-a64a-4ed3427788a4',
      content: [
        {
          groupId: '7282a465-32c2-4dc5-9b7c-40a1edfb84d8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T02:03:16.360Z',

          createdAt: '2024-06-04T15:56:44.312Z',
          content: [
            {
              caption: '',

              id: 'e6ea8d4e-5388-408f-a318-ff5deac67f5f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad0d9f0d-2b82-4da9-a64a-4ed3427788a4/ugc/7282a465-32c2-4dc5-9b7c-40a1edfb84d8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '17138955-7024-422d-a3b1-27e20d46efd0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T02:03:21.877Z',

          createdAt: '2024-06-04T15:47:50.089Z',
          content: [
            {
              caption: '',

              id: 'c195b1e8-219d-438e-a730-6407af785232',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad0d9f0d-2b82-4da9-a64a-4ed3427788a4/ugc/17138955-7024-422d-a3b1-27e20d46efd0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1f2c8af0-7ada-4acd-90f8-fce3b908d51d',
        firstName: 'Hoa',
        lastName: 'Nguyen',
        email: 'nguyendohoa@gmail.com',
        instagramProfile: {
          handle: 'fiduciado',
          followers: 4137,
          engagementRate: 0.6744017403915881
        },
        tiktokProfile: {
          handle: 'fiduciado',
          followers: 3543,
          engagementRate: 3.122770305528045
        },
        youtubeProfile: {
          handle: 'gabysclumsy8554',
          followers: 128
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T17:43:53.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '16bb1e09-62c4-477a-b3ae-46d4af18202c',
      content: [],
      creator: {
        id: '72d4ea6c-bdb3-4179-becb-6b3c82452912',
        firstName: 'Michael',
        lastName: 'Work',
        email: 'workb87@gmail.com',
        instagramProfile: {
          handle: 'authentic_grain',
          followers: 1315,
          engagementRate: 3.0646387832699618
        },
        tiktokProfile: {
          handle: 'authentic_grain',
          followers: 144
        },
        youtubeProfile: {
          handle: 'authenticgrain5672',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T17:46:37.000Z',
        amountCents: 6602
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '85696437-5ba5-4585-87a1-6e817780faea',
      content: [
        {
          groupId: '8ece5e27-0375-48b1-99f2-ae004167708a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T14:41:58.127Z',

          createdAt: '2024-06-02T10:11:27.055Z',
          content: [
            {
              caption: '',

              id: '6b228c30-7340-4825-b14f-ed5acd77476f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85696437-5ba5-4585-87a1-6e817780faea/ugc/8ece5e27-0375-48b1-99f2-ae004167708a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '90c62e45-b90d-462e-bf5d-50a69d254842',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85696437-5ba5-4585-87a1-6e817780faea/ugc/8ece5e27-0375-48b1-99f2-ae004167708a/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b250c8f8-b1a1-4a73-97b7-3a20e97b5220',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T02:22:22.807Z',

          createdAt: '2024-06-06T22:17:39.854Z',
          content: [
            {
              caption: '',

              id: '8f4a2399-ed89-46e5-9111-711f813cd3e0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/85696437-5ba5-4585-87a1-6e817780faea/ugc/b250c8f8-b1a1-4a73-97b7-3a20e97b5220/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '81f6d7ac-4832-41b3-8d57-69ad17c59947',
        firstName: 'Juliana',
        lastName: 'Almeida',
        email: 'jsaugc@gmail.com',
        instagramProfile: {
          handle: 'jujusagomes',
          followers: 1924,
          engagementRate: 3.991683991683991
        },
        tiktokProfile: {
          handle: 'ugc_byjuliana',
          followers: 158,
          engagementRate: 12.132352941176471
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T17:46:40.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '55b92955-e17f-402a-bf5b-42a436827f4b',
      content: [
        {
          groupId: 'a4d28657-6662-42b7-b58d-fe071eac7000',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-19T14:38:56.096Z',

          createdAt: '2024-06-18T18:31:43.472Z',
          content: [
            {
              caption: '',

              id: 'b176af85-28c2-404d-baad-cc5c05337ff4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55b92955-e17f-402a-bf5b-42a436827f4b/ugc/a4d28657-6662-42b7-b58d-fe071eac7000/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd9b5a608-337b-4757-9b32-1255d1cf98d9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55b92955-e17f-402a-bf5b-42a436827f4b/ugc/a4d28657-6662-42b7-b58d-fe071eac7000/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bef680de-0326-447e-b156-256ccca17fa2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T14:38:51.990Z',

          createdAt: '2024-06-18T18:47:12.405Z',
          content: [
            {
              caption: '',

              id: '6826eaf7-0400-4afd-82be-995e9d805377',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/55b92955-e17f-402a-bf5b-42a436827f4b/ugc/bef680de-0326-447e-b156-256ccca17fa2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '41f90eef-6744-43e7-8784-8d61798cc3c1',
        firstName: 'Crystal',
        lastName: 'Watts',
        email: 'crystalcwatts14@gmail.com',
        instagramProfile: {
          handle: 'crystalcwatts',
          followers: 2028,
          engagementRate: 1.183431952662722
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T18:06:01.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a99ff53d-e55c-481a-abe8-104c563e3672',
      content: [],
      creator: {
        id: '4550439c-942d-4a80-8be2-bb27c3928139',
        firstName: 'Timoy',
        lastName: 'Johnson',
        email: 'timoyjohn123@gmail.com',

        tiktokProfile: {
          handle: 'mr_inevitable_t',
          followers: 5389,
          engagementRate: 3.2
        },
        youtubeProfile: {
          handle: 'timoyjohnson9695',
          followers: 180
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T18:42:10.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '295c2ae2-4444-4355-a4f6-8f3d12e43c6c',
      content: [],
      creator: {
        id: '8ffea10f-d97a-4a18-945a-94fbc3164673',
        firstName: 'Adeisha',
        lastName: 'Johnson',
        email: 'adeishajohnson@icloud.com',
        instagramProfile: {
          handle: 'ava.di.m.plezzz',
          followers: 1692
        },
        tiktokProfile: {
          handle: 'd.i.m.p.l.es',
          followers: 192
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T18:44:27.000Z',
        amountCents: 8754
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e5907a82-e0e4-4a73-a0f1-4ff60bc51ba6',
      content: [
        {
          groupId: '43681d95-6842-4f74-b9bd-986d09424905',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T18:42:58.179Z',

          createdAt: '2024-06-14T18:20:46.774Z',
          content: [
            {
              caption: '',

              id: '1ecd307a-71d4-437c-b205-cb3c60286e62',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5907a82-e0e4-4a73-a0f1-4ff60bc51ba6/ugc/43681d95-6842-4f74-b9bd-986d09424905/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f9e63e82-5ba1-429d-9b17-1a56b21cb6a4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5907a82-e0e4-4a73-a0f1-4ff60bc51ba6/ugc/43681d95-6842-4f74-b9bd-986d09424905/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '644ba73a-de34-4584-a73c-db1d5d6b2e0d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5907a82-e0e4-4a73-a0f1-4ff60bc51ba6/ugc/43681d95-6842-4f74-b9bd-986d09424905/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '6679d481-aac5-4f28-8645-88b41e39e2aa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T12:10:44.335Z',

          createdAt: '2024-06-18T19:00:45.424Z',
          content: [
            {
              caption: '',

              id: 'f634d445-9411-4361-990d-43528dd2c795',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5907a82-e0e4-4a73-a0f1-4ff60bc51ba6/ugc/6679d481-aac5-4f28-8645-88b41e39e2aa/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b55cb4e0-bba1-4353-b441-bcf8278d7d17',
        firstName: 'Aneisha',
        lastName: 'Hylton',
        email: 'sionplace@hotmail.com',

        tiktokProfile: {
          handle: 'caribbeankitchenwithsio1',
          followers: 390,
          engagementRate: 5.971277399848828
        },
        youtubeProfile: {
          handle: 'caribbeankitchenwithsionhy',
          followers: 1080
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T19:02:26.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '30cb7d13-4501-4795-882a-59af258a57dd',
      content: [
        {
          groupId: 'a6c936e0-8510-450e-9974-452259d46058',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T20:40:04.160Z',

          createdAt: '2024-06-09T22:28:12.517Z',
          content: [
            {
              caption: '',

              id: '2b5b9858-9637-4f1a-b2e2-362a0e529b25',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/30cb7d13-4501-4795-882a-59af258a57dd/ugc/a6c936e0-8510-450e-9974-452259d46058/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e2c4c9fe-bad2-4807-8010-e2443c23dac6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/30cb7d13-4501-4795-882a-59af258a57dd/ugc/a6c936e0-8510-450e-9974-452259d46058/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '231eed53-c5a9-4f73-a738-d3e9807bc9d4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/30cb7d13-4501-4795-882a-59af258a57dd/ugc/a6c936e0-8510-450e-9974-452259d46058/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '02e2064c-94ca-4139-81d1-33ab3d6f5972',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T11:57:38.777Z',

          createdAt: '2024-06-10T20:39:56.063Z',
          content: [
            {
              caption: '',

              id: '14a2938d-be2f-477b-9c67-b682bfe27c82',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/30cb7d13-4501-4795-882a-59af258a57dd/ugc/02e2064c-94ca-4139-81d1-33ab3d6f5972/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a329fa22-86f4-4bf7-8793-d208eb988dd0',
        firstName: 'Monique',
        lastName: 'Williams',
        email: 'letniquesassist@gmail.com',
        instagramProfile: {
          handle: 'niques_21',
          followers: 1285,
          engagementRate: 0.7859922178988326
        },
        tiktokProfile: {
          handle: 'niques_21',
          followers: 4446,
          engagementRate: 4.547819817560578
        },
        youtubeProfile: {
          handle: 'niqueswilliams4928',
          followers: 49
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T19:22:39.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '93c64d3e-39f0-4eaa-94fd-e923d8bf5fe7',
      content: [
        {
          groupId: '9d1e812f-0375-4cb2-97f0-6490ac15c2c2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T13:31:55.418Z',

          createdAt: '2024-06-02T14:39:56.460Z',
          content: [
            {
              caption: '',

              id: '3d4345df-6866-4838-a138-399d0f841c63',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/93c64d3e-39f0-4eaa-94fd-e923d8bf5fe7/ugc/9d1e812f-0375-4cb2-97f0-6490ac15c2c2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bbc93918-1a06-44a5-b010-12a27429b4df',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T13:31:50.815Z',

          createdAt: '2024-06-02T14:43:49.388Z',
          content: [
            {
              caption: '',

              id: '7b25b696-24c8-4274-a8d2-dec95be92e4a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/93c64d3e-39f0-4eaa-94fd-e923d8bf5fe7/ugc/bbc93918-1a06-44a5-b010-12a27429b4df/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '75e683cc-23cb-4003-91b7-49dd4f96f53b',
        firstName: 'Vashti',
        lastName: 'Altieri',
        email: 'vashticassidy123@gmail.com',
        instagramProfile: {
          handle: 'vashtialtieri',
          followers: 2291,
          engagementRate: 4.296314772089542
        },
        tiktokProfile: {
          handle: 'vashtialtieri',
          followers: 22
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T19:33:09.000Z',
        amountCents: 7629
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a70b6fbb-0a9d-4010-a307-86b78838702e',
      content: [
        {
          groupId: '43e012db-df86-40c8-a262-7c3aa0c4165c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T17:27:50.584Z',

          createdAt: '2024-06-11T01:23:10.932Z',
          content: [
            {
              caption: '',

              id: '3b5baf06-5efb-4178-85ea-6db41f0ca7c9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a70b6fbb-0a9d-4010-a307-86b78838702e/ugc/43e012db-df86-40c8-a262-7c3aa0c4165c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3749ca03-d710-4118-828e-9ada3fc3af77',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T17:27:55.847Z',

          createdAt: '2024-06-11T01:24:10.500Z',
          content: [
            {
              caption: '',

              id: 'd63327e7-e414-4cab-8068-7a2379ab6665',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a70b6fbb-0a9d-4010-a307-86b78838702e/ugc/3749ca03-d710-4118-828e-9ada3fc3af77/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '25d41af8-3921-4c78-9b32-b707fa030b4a',
        firstName: 'Lizbeth',
        lastName: 'Barbosa Sanchez',
        email: 'bellezabyliz@gmail.com',
        instagramProfile: {
          handle: 'bellezabyliz',
          followers: 1405,
          engagementRate: 3.1387900355871885
        },
        tiktokProfile: {
          handle: 'bellezabyliz',
          followers: 2243,
          engagementRate: 11.456285649834037
        },
        youtubeProfile: {
          handle: 'bellezabyliz',
          followers: 19
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T19:34:44.000Z',
        amountCents: 7643
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9bae7f87-c175-4404-9017-e79de982349b',
      content: [
        {
          groupId: '4b077045-2b5b-4374-8e47-dcc6c66407ab',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T20:51:07.926Z',

          createdAt: '2024-06-08T03:29:09.828Z',
          content: [
            {
              caption: '',

              id: '93a7f955-4fdf-4c10-a3c2-423d98e9feb6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9bae7f87-c175-4404-9017-e79de982349b/ugc/4b077045-2b5b-4374-8e47-dcc6c66407ab/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2a32632f-dd31-440a-9a82-16b5b3c2a6e6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T03:29:16.024Z',

          createdAt: '2024-06-07T03:32:09.159Z',
          content: [
            {
              caption: '',

              id: '78a1e687-6b83-4557-9642-c077c45f174a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9bae7f87-c175-4404-9017-e79de982349b/ugc/2a32632f-dd31-440a-9a82-16b5b3c2a6e6/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a8b2c9f4-9720-470c-a2a6-7804792cf4e1',
        firstName: 'Jenna',
        lastName: 'Zabona',
        email: 'jennazbusiness@gmail.com',
        instagramProfile: {
          handle: 'jennazabona',
          followers: 8652,
          engagementRate: 1.8396517182924947
        },
        tiktokProfile: {
          handle: 'jennazabona',
          followers: 1687,
          engagementRate: 7.194481888318467
        },
        youtubeProfile: {
          handle: 'jennazabona',
          followers: 165
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T19:51:52.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e73cfadf-0e5d-4383-b4d0-127e8c0a16d3',
      content: [],
      creator: {
        id: '5472f946-a04c-4641-a99d-62cb9c3da303',
        firstName: 'Tiana',
        lastName: 'Brooks',
        email: 'embracethecurls18@gmail.com',
        instagramProfile: {
          handle: 'embracethecurls_with_t',
          followers: 11530,
          engagementRate: 0.17346053772766695
        },
        tiktokProfile: {
          handle: 'embracethecurls_with_t',
          followers: 1897,
          engagementRate: 0.38314176245210724
        },
        youtubeProfile: {
          handle: 'embracethecurlswitht'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T19:54:59.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1b99369e-077f-4b42-89b8-7145876b6032',
      content: [
        {
          groupId: '89578504-2937-475d-aa74-86f7ffc4d506',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T03:35:06.111Z',

          createdAt: '2024-06-08T02:34:32.533Z',
          content: [
            {
              caption: '',

              id: 'cdcccdc7-a9ac-47bf-81a4-49b98c6d5651',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b99369e-077f-4b42-89b8-7145876b6032/ugc/89578504-2937-475d-aa74-86f7ffc4d506/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3e9d6df2-7c9a-43db-b9a0-ed95a5ce26fa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T14:55:15.832Z',

          createdAt: '2024-06-08T02:35:12.383Z',
          content: [
            {
              caption: '',

              id: 'b5dc4088-2713-45c5-86bf-923ca6b6dd3a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b99369e-077f-4b42-89b8-7145876b6032/ugc/3e9d6df2-7c9a-43db-b9a0-ed95a5ce26fa/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bd96b025-b60c-4d55-bfab-10a575994bcf',
        firstName: 'Carley',
        lastName: 'Lockaton',
        email: 'carleylock11@gmail.com',
        instagramProfile: {
          handle: 'carleyy.alyssaa',
          followers: 7208,
          engagementRate: 0.32371439141694414
        },
        tiktokProfile: {
          handle: 'carleyy.alyssaa',
          followers: 400
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T20:02:23.000Z',
        amountCents: 7685
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a44b4f4f-0afb-4022-ab7c-1146bbd20906',
      content: [
        {
          groupId: '72dd3633-0204-4599-a600-d0367a67cb6c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-15T03:06:16.457Z',

          createdAt: '2024-06-07T02:18:06.765Z',
          content: [
            {
              caption: '',

              id: '6c68546e-9822-41bd-af79-9290b0367e95',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a44b4f4f-0afb-4022-ab7c-1146bbd20906/ugc/72dd3633-0204-4599-a600-d0367a67cb6c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '38ec230c-7c55-42d3-be56-b6f5c0498c01',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a44b4f4f-0afb-4022-ab7c-1146bbd20906/ugc/72dd3633-0204-4599-a600-d0367a67cb6c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '437e4c82-753d-47b2-9601-9ba55d90baf2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a44b4f4f-0afb-4022-ab7c-1146bbd20906/ugc/72dd3633-0204-4599-a600-d0367a67cb6c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '8a2267ce-236c-4ff0-a05d-ebb83b595c58',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-15T03:03:16.130Z',

          createdAt: '2024-06-07T02:17:04.725Z',
          content: [
            {
              caption: '',

              id: 'fe8aa964-da03-4f3c-a35c-22d2a46881d4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a44b4f4f-0afb-4022-ab7c-1146bbd20906/ugc/8a2267ce-236c-4ff0-a05d-ebb83b595c58/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6c3d823a-f84a-49c3-bd6c-5485f86d922b',
        firstName: 'Kristin',
        lastName: 'Morrison',
        email: 'agirlwholovespink4@gmail.com',
        instagramProfile: {
          handle: 'fabulousinmyforties',
          followers: 850,
          engagementRate: 0.6
        },
        tiktokProfile: {
          handle: 'fabulousinmyforties',
          followers: 1484,
          engagementRate: 2.0040862138510374
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T20:03:44.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3f73e289-565f-4511-a07b-74a2c03819e1',
      content: [
        {
          groupId: 'a116fb28-8ac4-4fa5-baf3-c7ce042d4c46',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T06:13:14.164Z',

          createdAt: '2024-06-05T21:47:48.915Z',
          content: [
            {
              caption: '',

              id: 'd18d2220-b7c3-43ae-a04e-17398384a3db',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3f73e289-565f-4511-a07b-74a2c03819e1/ugc/a116fb28-8ac4-4fa5-baf3-c7ce042d4c46/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '80d311c7-56b3-4d81-9c02-1c0127ccc97f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T21:47:55.262Z',

          createdAt: '2024-06-02T20:03:43.144Z',
          content: [
            {
              caption: '',

              id: '6c837945-a9de-4efe-9b8f-0faafcc23f7f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3f73e289-565f-4511-a07b-74a2c03819e1/ugc/80d311c7-56b3-4d81-9c02-1c0127ccc97f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'faae218e-8ff3-465f-b1de-c5a38e9426cc',
        firstName: 'Olivia',
        lastName: 'Simons',
        email: 'liv281976@gmail.com',
        instagramProfile: {
          handle: 'olivia_marie_simons',
          followers: 1020,
          engagementRate: 1.0784313725490196
        },
        tiktokProfile: {
          handle: 'oliviamariesimons',
          followers: 1013,
          engagementRate: 10.335522101217169
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T20:15:04.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '83731671-8303-46e3-87f5-85e45193c3a4',
      content: [
        {
          groupId: '25eabbcc-0337-48c1-86aa-5454c688c127',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T19:03:04.234Z',

          createdAt: '2024-06-04T12:06:28.239Z',
          content: [
            {
              caption: '',

              id: '30da8515-e1ea-4504-aa3f-e63280ce7b26',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/83731671-8303-46e3-87f5-85e45193c3a4/ugc/25eabbcc-0337-48c1-86aa-5454c688c127/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9cc298f2-1198-460d-a16c-a9c5158427d2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/83731671-8303-46e3-87f5-85e45193c3a4/ugc/25eabbcc-0337-48c1-86aa-5454c688c127/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e43394ba-870c-462b-89ed-efd450feac55',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/83731671-8303-46e3-87f5-85e45193c3a4/ugc/25eabbcc-0337-48c1-86aa-5454c688c127/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '142b9928-89cc-43ed-9d4e-fdfac27ade18',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T22:41:32.027Z',

          createdAt: '2024-06-04T20:03:57.438Z',
          content: [
            {
              caption: '',

              id: '5c19a902-4520-4431-b6b0-ab1a6d535cb4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/83731671-8303-46e3-87f5-85e45193c3a4/ugc/142b9928-89cc-43ed-9d4e-fdfac27ade18/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1cca1b92-3b04-4451-b2e7-4ce8eeb82e2b',
        firstName: 'Cruz',
        lastName: 'Labrada',
        email: 'lorenafashion96@gmail.com',
        instagramProfile: {
          handle: 'cruz_labrada',
          followers: 3320,
          engagementRate: 4.686746987951807
        },
        tiktokProfile: {
          handle: 'cruz_ugc',
          followers: 1150,
          engagementRate: 14.507208387942333
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T20:43:23.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1da690ae-631e-4615-9c7b-462f43892294',
      content: [],
      creator: {
        id: 'da8c6316-b661-48e7-bb4d-71352d59e8bc',
        firstName: 'Kyle',
        lastName: 'Nale',
        email: 'kylenale@gmail.com',

        tiktokProfile: {
          handle: 'naledit5533',
          followers: 26512,
          engagementRate: 9.199217404739876
        },
        youtubeProfile: {
          handle: 'mrnaler55',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T21:45:05.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1cf792f6-b24d-43c7-b18c-f1a9ed7b2f64',
      content: [],
      creator: {
        id: '5940c525-9604-4cd5-930a-c916f57ecc80',
        firstName: 'Aaliyah',
        lastName: 'M',
        email: 'aaliyah.1mellado@gmail.com',
        instagramProfile: {
          handle: 'aaliyah.jms',
          followers: 1806,
          engagementRate: 6.316928069226608
        },
        tiktokProfile: {
          handle: 'ligaam',
          followers: 93,
          engagementRate: 7.878787878787878
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T22:02:16.000Z',
        amountCents: 8371
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ae4c15c3-a15c-4d4e-afcd-4127a6af38d3',
      content: [
        {
          groupId: '91b9bcdb-3b17-447f-9d38-4858af7b113a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T13:33:42.667Z',

          createdAt: '2024-06-06T20:54:24.857Z',
          content: [
            {
              caption: '',

              id: '3d107116-fd83-4012-983c-945c17aacabe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae4c15c3-a15c-4d4e-afcd-4127a6af38d3/ugc/91b9bcdb-3b17-447f-9d38-4858af7b113a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '89f1f070-a5f4-451d-bf16-85c7e7c029bd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae4c15c3-a15c-4d4e-afcd-4127a6af38d3/ugc/91b9bcdb-3b17-447f-9d38-4858af7b113a/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6ae54ae8-6a16-4214-80c5-8dd2faf39cf4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae4c15c3-a15c-4d4e-afcd-4127a6af38d3/ugc/91b9bcdb-3b17-447f-9d38-4858af7b113a/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a1516bb8-5805-4406-847a-18e4d788a577',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:01:08.068Z',

          createdAt: '2024-06-06T20:53:20.780Z',
          content: [
            {
              caption: '',

              id: '7d191b79-ab8d-4de9-8b58-dbf5d15679f3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ae4c15c3-a15c-4d4e-afcd-4127a6af38d3/ugc/a1516bb8-5805-4406-847a-18e4d788a577/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '843188c4-7b42-4ea8-8ecd-69c8d784beaa',
        firstName: 'briana',
        lastName: 'perez',
        email: 'perezbriana027@gmail.com',
        instagramProfile: {
          handle: 'txsbriana',
          followers: 456
        },
        tiktokProfile: {
          handle: 'txbriana',
          followers: 2449,
          engagementRate: 15.068493150684931
        },
        youtubeProfile: {
          handle: 'brii3901',
          followers: 36
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T22:39:31.000Z',
        amountCents: 3568
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '943252e9-3839-4430-919c-378c13b0c9d2',
      content: [
        {
          groupId: '0117d2da-557c-4fab-a95a-7c77e483c472',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T14:45:11.653Z',

          createdAt: '2024-06-05T20:50:05.981Z',
          content: [
            {
              caption: '',

              id: '860c2f77-5fa6-4d62-88ca-da804ee0d9a7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/943252e9-3839-4430-919c-378c13b0c9d2/ugc/0117d2da-557c-4fab-a95a-7c77e483c472/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9644a807-f16d-4409-a988-f327bb815b61',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/943252e9-3839-4430-919c-378c13b0c9d2/ugc/0117d2da-557c-4fab-a95a-7c77e483c472/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '82845dce-75c5-4b6f-8239-11133b1846db',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/943252e9-3839-4430-919c-378c13b0c9d2/ugc/0117d2da-557c-4fab-a95a-7c77e483c472/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '48ffee2c-8d62-4443-b9a1-23713d69d224',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T14:45:03.168Z',

          createdAt: '2024-06-05T20:49:01.783Z',
          content: [
            {
              caption: '',

              id: 'f5037670-a42f-4b01-a167-11721758c4e4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/943252e9-3839-4430-919c-378c13b0c9d2/ugc/48ffee2c-8d62-4443-b9a1-23713d69d224/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '62a12646-cfb0-489b-b742-655955f962c0',
        firstName: 'Melanie',
        lastName: 'Malone',
        email: 'melanie_malone@comcast.net',
        instagramProfile: {
          handle: 'melanielynnmalone',
          followers: 976,
          engagementRate: 0.33811475409836067
        },
        tiktokProfile: {
          handle: 'melanie_malone',
          followers: 5150,
          engagementRate: 3.668329769541459
        },
        youtubeProfile: {
          handle: 'melaniemalone4802',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-05-31T23:25:09.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5d129944-86df-467c-959d-24334aea1501',
      content: [
        {
          groupId: '9533b54b-0d6e-4865-b10f-40e7fcde7b9f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T13:24:22.531Z',

          createdAt: '2024-06-05T20:00:02.024Z',
          content: [
            {
              caption: '',

              id: 'eb6a7478-4149-4805-b368-54c7bad47b45',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d129944-86df-467c-959d-24334aea1501/ugc/9533b54b-0d6e-4865-b10f-40e7fcde7b9f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ba90c5af-4cd2-4761-b4f7-820e9ef82d49',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T19:49:07.378Z',

          createdAt: '2024-06-05T01:58:57.972Z',
          content: [
            {
              caption: '',

              id: '3f6010da-c78e-418a-8784-5ec179936701',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d129944-86df-467c-959d-24334aea1501/ugc/ba90c5af-4cd2-4761-b4f7-820e9ef82d49/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2c6b7ee4-847c-40bb-a4a8-a6c0371f6ef1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5d129944-86df-467c-959d-24334aea1501/ugc/ba90c5af-4cd2-4761-b4f7-820e9ef82d49/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4ed6c172-a600-4d06-92c7-330fb0572767',
        firstName: 'Kimberly',
        lastName: 'Arreaga',
        email: 'kimberlyharris7@yahoo.com',
        instagramProfile: {
          handle: 'kimmmmy16',
          followers: 2069,
          engagementRate: 0.2609956500724988
        },
        tiktokProfile: {
          handle: 'kimmyarreaga',
          followers: 1384,
          engagementRate: 2.0634920634920633
        },
        youtubeProfile: {
          handle: 'thearreagas04',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T00:07:48.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '71d560e4-3ab5-42d1-9c7d-51c97d1a8389',
      content: [],
      creator: {
        id: 'bf304d05-59da-431e-a37d-265aac682856',
        firstName: 'Jr',
        lastName: 'Sparrow',
        email: 'wvuncommonplace@gmail.com',
        instagramProfile: {
          handle: 'wvuncommonplace',
          followers: 1387,
          engagementRate: 0.41816870944484497
        },
        tiktokProfile: {
          handle: 'wvuncommonplace',
          followers: 5004,
          engagementRate: 4.594974521173783
        },
        youtubeProfile: {
          handle: 'wvuncommonplace3936',
          followers: 18
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T00:16:20.000Z',
        amountCents: 8223
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '75077625-661b-4140-a695-9afbeabf8cca',
      content: [
        {
          groupId: '12618af1-fc89-49c8-b4e4-9880fb5efc66',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T15:51:00.803Z',

          createdAt: '2024-06-08T18:20:11.708Z',
          content: [
            {
              caption: '',

              id: 'b780796b-d3b0-4375-a50b-797faabe42ec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/75077625-661b-4140-a695-9afbeabf8cca/ugc/12618af1-fc89-49c8-b4e4-9880fb5efc66/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4b414cf5-a78a-4053-afa0-09d95004e7ac',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T03:56:40.645Z',

          createdAt: '2024-06-08T18:19:12.515Z',
          content: [
            {
              caption: '',

              id: 'e65c5a9c-049b-4d7a-933c-fd5196abc052',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/75077625-661b-4140-a695-9afbeabf8cca/ugc/4b414cf5-a78a-4053-afa0-09d95004e7ac/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4ab5b2a8-4e7a-40b8-8199-976eb7a3c38e',
        firstName: 'Roberto',
        lastName: 'Valderrama',
        email: 'rideboundless@gmail.com',
        instagramProfile: {
          handle: 'rideboundless',
          followers: 5436,
          engagementRate: 0.7597498160412067
        },
        tiktokProfile: {
          handle: 'rideboundless',
          followers: 481,
          engagementRate: 6.5420560747663545
        },
        youtubeProfile: {
          handle: 'rideboundless'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T01:52:09.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eb84de2e-e7fe-406e-92cc-425d71d22834',
      content: [],
      creator: {
        id: '2e3fb174-15b4-4f88-8d3c-cd09ec28e59f',
        firstName: 'Brianna',
        lastName: 'Baltozer',
        email: 'bbaltozer@aol.com',
        instagramProfile: {
          handle: 'bakedbri.e',
          followers: 1342,
          engagementRate: 2.421758569299553
        },
        tiktokProfile: {
          handle: 'bakedbri.e',
          followers: 88,
          engagementRate: 0.2796420581655481
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T03:04:12.000Z',
        amountCents: 13460
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c4a73c02-9be1-4cc6-a3d9-ffc9c99a4fce',
      content: [
        {
          groupId: 'adf4441f-da30-4351-847b-7b0224219056',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-09-11T20:47:49.816Z',

          createdAt: '2024-06-12T17:08:40.282Z',
          content: [
            {
              caption: '',

              id: 'bdacda4f-7bcc-4bb4-8907-a5dd5c1a9c86',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c4a73c02-9be1-4cc6-a3d9-ffc9c99a4fce/ugc/adf4441f-da30-4351-847b-7b0224219056/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '42c8ce29-a0cb-4ad4-acbc-dcd8bd0f5ae2',
        firstName: 'Mena',
        lastName: 'Nathaniel',
        email: 'menanathaniel502@gmail.com',
        instagramProfile: {
          handle: 'me_na230',
          followers: 1169,
          engagementRate: 18.71495105028039
        },
        tiktokProfile: {
          handle: '_nato230',
          followers: 3071,
          engagementRate: 6.185567010309279
        },
        youtubeProfile: {
          handle: 'menanathaniel3634',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T04:26:06.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f9f0642e-86d3-4d03-99a4-ba3c5fc143a7',
      content: [
        {
          groupId: 'f41af755-597f-4c09-b3c5-7cc7de5bd774',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T04:17:45.535Z',

          createdAt: '2024-06-09T05:21:15.089Z',
          content: [
            {
              caption: '',

              id: 'a0b1cbab-0a2b-4b55-ba8e-d97de1cb613b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f9f0642e-86d3-4d03-99a4-ba3c5fc143a7/ugc/f41af755-597f-4c09-b3c5-7cc7de5bd774/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '93d4ec5d-ae95-4400-b1ab-7be437da4001',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T18:46:22.661Z',

          createdAt: '2024-06-09T05:19:18.099Z',
          content: [
            {
              caption: '',

              id: '6c9ed3ca-3793-47fd-a55c-bb0ab6dae953',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f9f0642e-86d3-4d03-99a4-ba3c5fc143a7/ugc/93d4ec5d-ae95-4400-b1ab-7be437da4001/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ef09b852-20ab-496b-bdec-9f8d8fe4c203',
        firstName: 'Briana',
        lastName: 'Gutierrez',
        email: 'brianacandyg06@gmail.com',
        instagramProfile: {
          handle: 'briana_gutierrezz',
          followers: 1011,
          engagementRate: 10.781404549950544
        },
        tiktokProfile: {
          handle: 'bri.bri_gutierrez',
          followers: 1405,
          engagementRate: 8.532778355879293
        },
        youtubeProfile: {
          handle: 'brianagutierrez6944',
          followers: 4
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T04:56:46.000Z',
        amountCents: 8429
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6dfe6990-1087-4ffc-b9a8-33b7c213b58d',
      content: [],
      creator: {
        id: 'd7710b8f-5e9e-43d2-9af9-dcabd49de067',
        firstName: 'anjelica',
        lastName: 'villa',
        email: 'anjjjvilla9@gmail.com',

        tiktokProfile: {
          handle: 'anjelicaaavilla',
          followers: 4952,
          engagementRate: 3.1914893617021276
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T08:14:27.000Z',
        amountCents: 9333
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '29bd3e66-819f-4dc2-9152-ade9599ac509',
      content: [],
      creator: {
        id: '0dc37bde-b1c9-48f0-bdad-cff7d41f22a7',
        firstName: 'Amanda',
        lastName: 'Carson',
        email: 'amandalavernecarson@hotmail.com',

        tiktokProfile: {
          handle: 'uhohitslaverne',
          followers: 2600
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T08:21:31.000Z',
        amountCents: 8179
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '01aff72a-6a2a-47b3-8873-59beb9672419',
      content: [
        {
          groupId: 'c43e5e10-522f-42d5-a026-02497321c21b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T09:37:30.545Z',

          createdAt: '2024-06-05T18:54:36.350Z',
          content: [
            {
              caption: '',

              id: '599612ca-ee03-46f2-906d-9375b0dd383f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/01aff72a-6a2a-47b3-8873-59beb9672419/ugc/c43e5e10-522f-42d5-a026-02497321c21b/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b287e740-8046-443b-9754-58df04a12df6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/01aff72a-6a2a-47b3-8873-59beb9672419/ugc/c43e5e10-522f-42d5-a026-02497321c21b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ed2d678d-3e52-4ff2-815a-fdc407fcaebb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T09:12:04.656Z',

          createdAt: '2024-06-05T18:54:10.459Z',
          content: [
            {
              caption: '',

              id: '7ad8ff0a-7e05-46dc-8dfb-27d505f7bbd7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/01aff72a-6a2a-47b3-8873-59beb9672419/ugc/ed2d678d-3e52-4ff2-815a-fdc407fcaebb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '333bd1a3-b85f-4fca-97e1-d3484971879c',
        firstName: 'Taylor',
        lastName: 'Campos',
        email: 'dubetaylor26@gmail.com',

        tiktokProfile: {
          handle: 'queenbitchhhhh_',
          followers: 11795,
          engagementRate: 8.509014263204207
        },
        youtubeProfile: {
          handle: 'zaiandzargirls',
          followers: 10
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T09:13:50.000Z',
        amountCents: 9993
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5c06c564-9e49-47ef-a08e-44cb00704421',
      content: [
        {
          groupId: '41a5ae6a-0f68-4cea-b64d-3fd3c346fceb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T04:52:03.807Z',

          createdAt: '2024-06-04T03:14:37.536Z',
          content: [
            {
              caption: '',

              id: '81b75fb3-cc72-4459-8621-4b54d44bf55d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5c06c564-9e49-47ef-a08e-44cb00704421/ugc/41a5ae6a-0f68-4cea-b64d-3fd3c346fceb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '6ecdd163-c3a7-48ac-b166-e45270789309',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T03:54:12.125Z',

          createdAt: '2024-06-04T00:13:29.853Z',
          content: [
            {
              caption: '',

              id: '6f29b12d-cb30-4520-baa6-907f29ae7788',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5c06c564-9e49-47ef-a08e-44cb00704421/ugc/6ecdd163-c3a7-48ac-b166-e45270789309/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '06da085d-e764-4aed-8844-aedbfa95b509',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5c06c564-9e49-47ef-a08e-44cb00704421/ugc/6ecdd163-c3a7-48ac-b166-e45270789309/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '531803b1-d162-4ab2-9b10-11854e05e5aa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5c06c564-9e49-47ef-a08e-44cb00704421/ugc/6ecdd163-c3a7-48ac-b166-e45270789309/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd0f36728-b202-4f89-b42d-98c065874ace',
        firstName: 'Tegan',
        lastName: 'Allen',
        email: 'teganraine2@gmail.com',
        instagramProfile: {
          handle: 'greengoddess1212',
          followers: 3,
          engagementRate: 66.66666666666666
        },
        tiktokProfile: {
          handle: 'greengoddess11',
          followers: 16596,
          engagementRate: 1.7157509157509159
        },
        youtubeProfile: {
          handle: 'teganallen5077'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T11:35:35.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'be715790-eebd-44b4-9e31-63fdb460bcc2',
      content: [],
      creator: {
        id: 'eb0e033e-acf8-4b0f-a778-2a5a86d8064b',
        firstName: 'Emilee',
        lastName: 'Eychner',
        email: 'mommyemilee22@gmail.com',

        tiktokProfile: {
          handle: 'emilee.47',
          followers: 24705,
          engagementRate: 2.4439918533604885
        },
        youtubeProfile: {
          handle: 'emileeeychner1359'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T11:39:30.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e5ad5d57-b2b9-41a9-9600-dc98997662e5',
      content: [],
      creator: {
        id: '2bdb862d-0381-4c9d-b3a8-315167c2c792',
        firstName: 'Torie',
        lastName: 'Small',
        email: 'toriesmallz@gmail.com',

        tiktokProfile: {
          handle: 'toriesmallz',
          followers: 5579,
          engagementRate: 8.842562046388874
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T11:45:27.000Z',
        amountCents: 8237
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b0f6b01b-4d55-49a9-9bb6-8a8b3b90328a',
      content: [
        {
          groupId: 'a6006ae3-9563-42a5-b7ad-8ecb78ea5616',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T14:44:10.973Z',

          createdAt: '2024-06-17T04:16:04.762Z',
          content: [
            {
              caption: '',

              id: '93f2d141-e1ef-435a-9035-3166e420d478',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0f6b01b-4d55-49a9-9bb6-8a8b3b90328a/ugc/a6006ae3-9563-42a5-b7ad-8ecb78ea5616/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1551e6d7-c40a-4167-a44b-8b1c98e41ada',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0f6b01b-4d55-49a9-9bb6-8a8b3b90328a/ugc/a6006ae3-9563-42a5-b7ad-8ecb78ea5616/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '24910de0-e44a-4027-9bad-18a3ddff8b92',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0f6b01b-4d55-49a9-9bb6-8a8b3b90328a/ugc/a6006ae3-9563-42a5-b7ad-8ecb78ea5616/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3675d531-530b-4080-9355-1c713e0888e2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T17:45:10.568Z',

          createdAt: '2024-06-17T04:15:28.998Z',
          content: [
            {
              caption: '',

              id: '68340202-0f70-47f1-8ddf-9efb9856e43a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0f6b01b-4d55-49a9-9bb6-8a8b3b90328a/ugc/3675d531-530b-4080-9355-1c713e0888e2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a4954f58-ab9b-4e5d-8cf2-36749496e865',
        firstName: 'Taylor',
        lastName: 'Travis',
        email: 'thunter4198@icloud.com',
        instagramProfile: {
          handle: 'tay_trav',
          followers: 507
        },
        tiktokProfile: {
          handle: 'taytravlol',
          followers: 5457,
          engagementRate: 4.628465918788499
        },
        youtubeProfile: {
          handle: 'taylorhunter6126',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T12:57:15.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b4839ecf-942c-4445-8a90-11b111e164e5',
      content: [],
      creator: {
        id: '4ea36506-665b-47d9-8440-c2f3c40db325',
        firstName: 'Julia',
        lastName: 'Osborne',
        email: 'jewels261315@icloud.com',
        instagramProfile: {
          handle: 'jewelsreviews2.0',
          followers: 1514,
          engagementRate: 2.9502421840598854
        },
        tiktokProfile: {
          handle: 'jewels_261315',
          followers: 67
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:12:55.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c8b36f86-8205-4b2f-809f-3230f75659b0',
      content: [],
      creator: {
        id: 'b3f1c3f2-877a-4796-818b-1c3660bcdc32',
        firstName: 'Elizabeth',
        lastName: 'Vang',
        email: 'lizzysleeved@gmail.com',
        instagramProfile: {
          handle: 'lizzysleeved',
          followers: 1096,
          engagementRate: 0.03525046382189239
        },
        tiktokProfile: {
          handle: 'user2507610381320',
          followers: 8
        },
        youtubeProfile: {
          handle: 'elizabethvang3256',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:15:33.000Z',
        amountCents: 7660
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a563c1a7-79c8-4a8e-80de-2191173990c6',
      content: [],
      creator: {
        id: '89c1a233-7a2e-4ef1-824b-22336ed3c5d5',
        firstName: 'Samantha',
        lastName: 'Hefner',
        email: 'themcclurefam6@gmail.com',
        instagramProfile: {
          handle: 'themcclurefam6',
          followers: 236,
          engagementRate: 2.4576271186440675
        },
        tiktokProfile: {
          handle: 'themcclurefam6',
          followers: 5273,
          engagementRate: 9.014916971573319
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:18:18.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '15a5220a-0c2f-4114-a253-8c7196720165',
      content: [
        {
          groupId: '31d6d79e-29fd-4a5f-bc94-f104203a56e5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T12:57:42.408Z',

          createdAt: '2024-06-07T22:00:30.772Z',
          content: [
            {
              caption: '',

              id: '0df7e7d6-545e-4203-966e-1a3abacf4c2d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/15a5220a-0c2f-4114-a253-8c7196720165/ugc/31d6d79e-29fd-4a5f-bc94-f104203a56e5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e01ce2a7-0090-4e11-978e-68c123369218',
        firstName: 'Monica',
        lastName: 'Ciaccia',
        email: 'ciaccia.m@aol.com',
        instagramProfile: {
          handle: 'monicachachaa',
          followers: 2212
        },
        tiktokProfile: {
          handle: 'monicachachaa',
          followers: 191
        },
        youtubeProfile: {
          handle: 'monicaciaccia-kx9be',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:19:26.000Z',
        amountCents: 8583
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ed1c53c3-6965-4257-b055-726f84dbd1a6',
      content: [
        {
          groupId: '47b04351-07ee-48ff-b1ec-0ebe61a93369',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T03:11:55.855Z',

          createdAt: '2024-06-07T22:46:41.998Z',
          content: [
            {
              caption: '',

              id: '58dd7005-7d4f-490d-926d-52f2f2a24362',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ed1c53c3-6965-4257-b055-726f84dbd1a6/ugc/47b04351-07ee-48ff-b1ec-0ebe61a93369/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '8400f49b-f058-4055-b9dd-e2041768ac0b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T17:31:12.621Z',

          createdAt: '2024-06-05T23:37:20.963Z',
          content: [
            {
              caption: '',

              id: 'bf080c84-de5d-4aa7-a837-66b2a7624b73',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ed1c53c3-6965-4257-b055-726f84dbd1a6/ugc/8400f49b-f058-4055-b9dd-e2041768ac0b/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a7fa1506-a351-422d-99f7-6ff931cdfc9c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ed1c53c3-6965-4257-b055-726f84dbd1a6/ugc/8400f49b-f058-4055-b9dd-e2041768ac0b/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f9f80d00-6f4f-420a-b213-8dde03cef398',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ed1c53c3-6965-4257-b055-726f84dbd1a6/ugc/8400f49b-f058-4055-b9dd-e2041768ac0b/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cc0c2e5f-6981-433e-b412-b6e557855366',
        firstName: 'Yanellis',
        lastName: 'Linares',
        email: 'yanellislinares@yahoo.com',
        instagramProfile: {
          handle: 'yanellislinares',
          followers: 1150,
          engagementRate: 2.0695652173913044
        },
        tiktokProfile: {
          handle: 'yanellislinares',
          followers: 8506,
          engagementRate: 3.4923788431474727
        },
        youtubeProfile: {
          handle: 'yanellislinares',
          followers: 541
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:29:24.000Z',
        amountCents: 7515
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ec212569-ce58-4d2b-ba2c-ff4092e471a7',
      content: [
        {
          groupId: 'd80602f1-a56a-4fc9-aab5-21522642b446',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-16T15:05:11.722Z',

          createdAt: '2024-06-03T18:46:23.965Z',
          content: [
            {
              caption: '',

              id: 'f1bb8924-90aa-4317-be32-4244b3f8d3d0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ec212569-ce58-4d2b-ba2c-ff4092e471a7/ugc/d80602f1-a56a-4fc9-aab5-21522642b446/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5e97cd0e-481b-46b7-9d56-ebd645223304',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ec212569-ce58-4d2b-ba2c-ff4092e471a7/ugc/d80602f1-a56a-4fc9-aab5-21522642b446/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7ff299cb-4f8f-4ac1-90dd-98b37b2dd6c3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ec212569-ce58-4d2b-ba2c-ff4092e471a7/ugc/d80602f1-a56a-4fc9-aab5-21522642b446/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '10594067-a848-48b6-a408-30e8e249721c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-16T15:05:04.856Z',

          createdAt: '2024-06-03T18:45:49.332Z',
          content: [
            {
              caption: '',

              id: '1bb14ef2-9ab2-42b4-a478-483a0d14b0c4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ec212569-ce58-4d2b-ba2c-ff4092e471a7/ugc/10594067-a848-48b6-a408-30e8e249721c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a85c7d12-40df-4051-8814-aa4ffb6649ef',
        firstName: 'Angeline',
        lastName: 'Osborne',
        email: 'anglitchke@gmail.com',

        tiktokProfile: {
          handle: 'angosborne',
          followers: 9503,
          engagementRate: 4.060372286023202
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:43:43.000Z',
        amountCents: 7585
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '53f179d4-a131-4c49-8e8e-abbc693776bf',
      content: [],
      creator: {
        id: 'b879a155-404c-4dbf-9434-61ed974cc37f',
        firstName: 'Tracy',
        lastName: 'Treviño',
        email: 'texastracydesigns@gmail.com',
        instagramProfile: {
          handle: 'tracytrainsbjj',
          followers: 976,
          engagementRate: 2.0901639344262293
        },
        tiktokProfile: {
          handle: 'texastracy01',
          followers: 79,
          engagementRate: 5.333333333333334
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:45:39.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1c78d554-9342-4f81-8fd9-395ec51e3cc1',
      content: [],
      creator: {
        id: 'c3f06c7e-65a9-4433-a1b8-c3bf3bdcae1a',
        firstName: 'Dani',
        lastName: 'Edwards',
        email: 'dyamrobinson@gmail.com',

        tiktokProfile: {
          handle: 'nini_dcs',
          followers: 1320
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:51:53.000Z',
        amountCents: 8760
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e485ec6d-6771-43be-a339-c28916bfafaf',
      content: [],
      creator: {
        id: 'fdc20048-87a8-40d0-ae23-af1e60e0071b',
        firstName: 'kailey',
        lastName: 'hartman',
        email: 'kailey.hartman@pcc.edu',
        instagramProfile: {
          handle: 'kayy.ugc',
          followers: 941,
          engagementRate: 0.6376195536663124
        },
        tiktokProfile: {
          handle: 'kayy.ugc',
          followers: 2332,
          engagementRate: 7.142857142857142
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:55:10.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9fa31bf7-5ab5-4d6b-94b3-e9d624aa4e2e',
      content: [
        {
          groupId: '277ddb26-8f81-444e-97d8-2b8684d97b4d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T17:18:02.592Z',

          createdAt: '2024-06-06T23:43:14.229Z',
          content: [
            {
              caption: '',

              id: '0301aa0c-816e-4d27-8828-0e6f57ac9449',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9fa31bf7-5ab5-4d6b-94b3-e9d624aa4e2e/ugc/277ddb26-8f81-444e-97d8-2b8684d97b4d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a2b6d9db-1e44-4838-b429-4e0dcdf8137b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T17:18:09.818Z',

          createdAt: '2024-06-06T23:54:36.691Z',
          content: [
            {
              caption: '',

              id: 'aaf1a222-3856-4991-b189-403490fe33cc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9fa31bf7-5ab5-4d6b-94b3-e9d624aa4e2e/ugc/a2b6d9db-1e44-4838-b429-4e0dcdf8137b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cd76483e-5bf5-4d5b-9a3c-a8e04f87bc1f',
        firstName: 'Maria',
        lastName: 'Arnold',
        email: 'maria.unboxingfunstuff@gmail.com',
        instagramProfile: {
          handle: 'unboxingfunstuff',
          followers: 2104,
          engagementRate: 1.3688212927756656
        },
        tiktokProfile: {
          handle: 'unboxingfunstuff',
          followers: 4161,
          engagementRate: 5.289287847220639
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T16:56:41.000Z',
        amountCents: 7603
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'aafbfe4e-ec38-480b-ab8d-45de247fdddb',
      content: [
        {
          groupId: 'c18e6709-096e-4456-bab5-2761e9098b3f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T03:45:39.893Z',

          createdAt: '2024-07-16T06:04:49.958Z',
          content: [
            {
              caption: '',

              id: '0424caf2-ab6b-452b-b200-575540e8e769',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aafbfe4e-ec38-480b-ab8d-45de247fdddb/ugc/c18e6709-096e-4456-bab5-2761e9098b3f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fca4d52c-dba3-48fd-896c-038d8e8b19ea',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T03:45:44.599Z',

          createdAt: '2024-07-16T06:06:07.127Z',
          content: [
            {
              caption: '',

              id: '03cdce05-71a7-4041-b945-3f0e7664efb6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aafbfe4e-ec38-480b-ab8d-45de247fdddb/ugc/fca4d52c-dba3-48fd-896c-038d8e8b19ea/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '556f3de1-c290-4cba-87f5-32a25ab77c62',
        firstName: 'Marcela',
        lastName: 'Najera',
        email: 'marcelanajera1997@gmail.com',
        instagramProfile: {
          handle: 'shorty.mvilla617',
          followers: 192,
          engagementRate: 0.03854166666666667
        },
        tiktokProfile: {
          handle: 'shorty61700',
          followers: 1441,
          engagementRate: 4.761904761904762
        },
        youtubeProfile: {
          handle: 'marcearmaniandneymar7954',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T17:05:25.000Z',
        amountCents: 8524
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '886de365-de97-48f6-87bd-7738e82835f5',
      content: [],
      creator: {
        id: '9375547b-d0f8-42b6-b7d1-b5d382db2433',
        firstName: 'Erica',
        lastName: 'Chavez',
        email: 'erica.page3@gmail.com',
        instagramProfile: {
          handle: 'paigesaidso',
          followers: 3908,
          engagementRate: 1.55237120436711
        },
        tiktokProfile: {
          handle: 'eachavezzz',
          followers: 935,
          engagementRate: 12.951807228915662
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T17:12:54.000Z',
        amountCents: 7568
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'def2652f-1101-4346-b2fd-22cd432b05b1',
      content: [],
      creator: {
        id: '7e1ee360-0dc6-4e31-9497-dfbb6c65130f',
        firstName: 'Vicki',
        lastName: 'Pachalis',
        email: 'daycare1063@yahoo.com',
        instagramProfile: {
          handle: 'nextstopforever',
          followers: 1770,
          engagementRate: 1.2668918918918919
        },
        tiktokProfile: {
          handle: 'daycare10638',
          followers: 0
        },
        youtubeProfile: {
          handle: 'daycare1063',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T17:13:36.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '59ef3387-4abf-4de1-b1cd-35cefb826400',
      content: [
        {
          groupId: '5a1736dd-ff4c-43dd-8325-5129e9596157',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T23:38:31.008Z',

          createdAt: '2024-06-10T01:17:58.789Z',
          content: [
            {
              caption: '',

              id: '17a3443a-3adb-40db-bc14-90465b8d8939',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/59ef3387-4abf-4de1-b1cd-35cefb826400/ugc/5a1736dd-ff4c-43dd-8325-5129e9596157/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '33ed7fea-70b8-44c5-ae3f-d81cbad4d0ab',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T23:38:25.274Z',

          createdAt: '2024-06-10T01:12:08.519Z',
          content: [
            {
              caption: '',

              id: 'a5857e81-74e6-4127-a362-a12a4292776d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/59ef3387-4abf-4de1-b1cd-35cefb826400/ugc/33ed7fea-70b8-44c5-ae3f-d81cbad4d0ab/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bb49bd90-1d3f-4144-ab7f-04778f494518',
        firstName: 'Gretchen',
        lastName: 'Pabon',
        email: 'chinnie@gmail.com',
        instagramProfile: {
          handle: 'mzpabon01',
          followers: 517,
          engagementRate: 7.0599613152804634
        },
        tiktokProfile: {
          handle: 'catnkidmama',
          followers: 1295,
          engagementRate: 1.465201465201465
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T17:21:32.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '22ffa52a-393a-4e5a-a7d0-dcdc80f311a4',
      content: [
        {
          groupId: 'b816d407-9b7e-4806-bf75-e80a61b2bbd5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-21T16:52:28.206Z',

          createdAt: '2024-06-20T04:08:29.321Z',
          content: [
            {
              caption: '',

              id: '13c0d7bc-069d-4b7c-8803-01485ed2e7cd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/22ffa52a-393a-4e5a-a7d0-dcdc80f311a4/ugc/b816d407-9b7e-4806-bf75-e80a61b2bbd5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '5ca46bca-e3ac-4882-a277-762f44455579',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-21T16:52:33.236Z',

          createdAt: '2024-06-20T04:12:44.742Z',
          content: [
            {
              caption: '',

              id: 'a0654dda-59ef-4617-b164-6ab4b79ac72b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/22ffa52a-393a-4e5a-a7d0-dcdc80f311a4/ugc/5ca46bca-e3ac-4882-a277-762f44455579/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2e5bf06f-3619-481b-8eba-dccbaf97b14d',
        firstName: 'Shayna ',
        lastName: 'Sherie ',
        email: 'officialshaynasherie@gmail.com',
        instagramProfile: {
          handle: 'themogulgirl',
          followers: 2668,
          engagementRate: 0.23238380809595202
        },
        tiktokProfile: {
          handle: 'shaynasherie',
          followers: 865,
          engagementRate: 1.6892580513656745
        },
        youtubeProfile: {
          handle: 'officialshaynasherie',
          followers: 88
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T17:23:54.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '46e7913f-57b1-4c2d-9933-bb797378d316',
      content: [],
      creator: {
        id: 'f43d99ec-8f5a-4b15-b9c4-613ffcf94ae1',
        firstName: 'Megan',
        lastName: 'Almanzar',
        email: 'almanzarmegan8@gmail.com',
        instagramProfile: {
          handle: 'megan.almanzar',
          followers: 1034
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T17:35:45.000Z',
        amountCents: 7629
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ef956fe7-e2fa-4aeb-be7c-def0274faffa',
      content: [],
      creator: {
        id: '3a8673df-7cf6-40b4-9ede-a3b1c7899c21',
        firstName: 'Emily',
        lastName: 'Meyers',
        email: 'emilymeyers14@duck.com',
        instagramProfile: {
          handle: 'emilymeyers0314',
          followers: 228,
          engagementRate: 1.0526315789473684
        },
        tiktokProfile: {
          handle: 'emilymeyers14',
          followers: 21567,
          engagementRate: 8.927260652242301
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T17:36:27.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '00cefe6f-5999-4b47-84c1-1c6929e473a6',
      content: [
        {
          groupId: '6dbfdf6f-3910-4c87-a2c6-d100962b0050',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T14:04:07.302Z',

          createdAt: '2024-06-09T12:41:44.848Z',
          content: [
            {
              caption: '',

              id: '3112f134-bbf2-4d03-afa2-b1840d1c61fd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00cefe6f-5999-4b47-84c1-1c6929e473a6/ugc/6dbfdf6f-3910-4c87-a2c6-d100962b0050/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fe89e171-f623-4250-aff6-c5299bb1f182',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T14:18:42.344Z',

          createdAt: '2024-06-14T14:01:30.819Z',
          content: [
            {
              caption: '',

              id: '63b67901-b8ad-4d7e-a85d-a2f99f5b83f1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00cefe6f-5999-4b47-84c1-1c6929e473a6/ugc/fe89e171-f623-4250-aff6-c5299bb1f182/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6b6b6a6b-cdce-4d8f-82bd-865b9aad29ca',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00cefe6f-5999-4b47-84c1-1c6929e473a6/ugc/fe89e171-f623-4250-aff6-c5299bb1f182/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '360a9ff6-1571-4da2-ad9c-e024f9e89435',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/00cefe6f-5999-4b47-84c1-1c6929e473a6/ugc/fe89e171-f623-4250-aff6-c5299bb1f182/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1a6934ea-dc07-4c56-83d2-8d16fb3b0bf5',
        firstName: 'Teresa',
        lastName: 'Cota',
        email: 'c1996teresa@gmail.com',
        instagramProfile: {
          handle: 'teresaaaleee',
          followers: 3082,
          engagementRate: 1.5801427644386763
        },
        tiktokProfile: {
          handle: 'teresaaaleee96',
          followers: 11906,
          engagementRate: 2.1023977759759065
        },
        youtubeProfile: {
          handle: 'alejandrinameza',
          followers: 256
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T17:46:24.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f5f01d06-e3d0-40a4-b260-d7fef36cd68f',
      content: [
        {
          groupId: '9724c74c-9b65-4bd7-917c-5e028cc3ac9c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T12:11:01.268Z',

          createdAt: '2024-06-02T19:20:52.980Z',
          content: [
            {
              caption: '',

              id: '8b66bed1-9441-48d2-91d7-f19e906b2491',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f5f01d06-e3d0-40a4-b260-d7fef36cd68f/ugc/9724c74c-9b65-4bd7-917c-5e028cc3ac9c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '44300f00-cee4-4581-8aaf-d39eccdc2900',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f5f01d06-e3d0-40a4-b260-d7fef36cd68f/ugc/9724c74c-9b65-4bd7-917c-5e028cc3ac9c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '137b906c-b455-4353-b2f1-5828d7989a82',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f5f01d06-e3d0-40a4-b260-d7fef36cd68f/ugc/9724c74c-9b65-4bd7-917c-5e028cc3ac9c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cd2d9128-5edf-46fb-be8a-b9f578ed147d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T13:00:37.911Z',

          createdAt: '2024-06-02T19:14:58.125Z',
          content: [
            {
              caption: '',

              id: '71aa7558-08ab-4d30-9582-7ef74bcaa847',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f5f01d06-e3d0-40a4-b260-d7fef36cd68f/ugc/cd2d9128-5edf-46fb-be8a-b9f578ed147d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8b52522f-f322-4468-b909-c5240e4a07e9',
        firstName: 'Steven',
        lastName: 'Jang',
        email: 'grendy.g85@gmail.com',
        instagramProfile: {
          handle: 'stev.jang',
          followers: 2288,
          engagementRate: 1.5996503496503496
        },
        tiktokProfile: {
          handle: 'stev.jang',
          followers: 1869,
          engagementRate: 12.960760998810938
        },
        youtubeProfile: {
          handle: 'stevenjang2112',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T18:03:10.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b987d789-697d-43b4-8efc-4930a7fb9978',
      content: [
        {
          groupId: '0ea62756-3f44-406d-8a44-57d5b4518ca9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T14:07:08.892Z',

          createdAt: '2024-06-12T17:38:45.879Z',
          content: [
            {
              caption: '',

              id: '1f1e832a-651d-444a-83e1-87f0dab975f3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b987d789-697d-43b4-8efc-4930a7fb9978/ugc/0ea62756-3f44-406d-8a44-57d5b4518ca9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7dfe7110-5ff3-4f33-a97e-4dc156f3c5a4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T14:07:01.553Z',

          createdAt: '2024-06-12T17:40:55.279Z',
          content: [
            {
              caption: '',

              id: 'dd2fbe8e-ba92-4965-9979-08a2dffe3d7f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b987d789-697d-43b4-8efc-4930a7fb9978/ugc/7dfe7110-5ff3-4f33-a97e-4dc156f3c5a4/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd6b8c8c9-6047-457e-863f-69f4ead16f33',
        firstName: 'Jill',
        lastName: 'Zemko',
        email: 'jillianzzz891@gmail.com',
        instagramProfile: {
          handle: 'missinfluencerr',
          followers: 1564,
          engagementRate: 2.608695652173913
        },
        tiktokProfile: {
          handle: 'missinfluencerr8',
          followers: 1126,
          engagementRate: 0.9554831704668839
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T18:12:06.000Z',
        amountCents: 8551
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7fde3617-253c-4c28-9f6e-088a68d5a3a0',
      content: [
        {
          groupId: 'b2c4658a-1b3f-49a1-9aa1-14f1d2a3099d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T03:08:19.941Z',

          createdAt: '2024-06-07T22:17:09.003Z',
          content: [
            {
              caption: '',

              id: '905d816e-6baf-4756-88d5-5e1af427bb77',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7fde3617-253c-4c28-9f6e-088a68d5a3a0/ugc/b2c4658a-1b3f-49a1-9aa1-14f1d2a3099d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '19d80226-9b1f-495d-94a5-992f22e2e9f1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T12:51:17.738Z',

          createdAt: '2024-06-07T21:54:43.088Z',
          content: [
            {
              caption: '',

              id: 'f521fefb-c2d2-4567-b98a-6e53038c8e72',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7fde3617-253c-4c28-9f6e-088a68d5a3a0/ugc/19d80226-9b1f-495d-94a5-992f22e2e9f1/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'baca3957-211f-4f1e-9a58-e87e69cf985f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7fde3617-253c-4c28-9f6e-088a68d5a3a0/ugc/19d80226-9b1f-495d-94a5-992f22e2e9f1/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'dc8dfaaa-3598-4e13-a357-d4bfb49b8d16',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7fde3617-253c-4c28-9f6e-088a68d5a3a0/ugc/19d80226-9b1f-495d-94a5-992f22e2e9f1/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a7f64168-11db-469e-9f1a-0c1bd3112ba0',
        firstName: 'Josiah',
        lastName: 'Capaci',
        email: 'jotheshow@yahoo.com',
        instagramProfile: {
          handle: 'jotheshow',
          followers: 10127,
          engagementRate: 1.597215364866199
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T18:23:40.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '607d8304-59f1-4e61-97d8-9afda3238baa',
      content: [
        {
          groupId: 'ad3bb033-141b-420b-89f5-fe5dd8112d34',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T17:38:31.727Z',

          createdAt: '2024-06-08T03:11:42.595Z',
          content: [
            {
              caption: '',

              id: 'd26b3603-7338-4b37-9298-4061b1cf8e21',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/607d8304-59f1-4e61-97d8-9afda3238baa/ugc/ad3bb033-141b-420b-89f5-fe5dd8112d34/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1f3922b0-4c3b-4ff1-8cfd-3fed228fad66',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/607d8304-59f1-4e61-97d8-9afda3238baa/ugc/ad3bb033-141b-420b-89f5-fe5dd8112d34/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0b2129d6-e799-48b7-8514-500e082a61d3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/607d8304-59f1-4e61-97d8-9afda3238baa/ugc/ad3bb033-141b-420b-89f5-fe5dd8112d34/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '64ed33c5-a3bf-4046-ba5f-240c246a8c6a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T17:38:28.533Z',

          createdAt: '2024-06-08T01:28:42.971Z',
          content: [
            {
              caption: '',

              id: 'df9c5e6a-1d86-409e-a015-1023f180196d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/607d8304-59f1-4e61-97d8-9afda3238baa/ugc/64ed33c5-a3bf-4046-ba5f-240c246a8c6a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '651044bf-4c0d-46d9-b6af-baa05f55e34c',
        firstName: 'Ellaine',
        lastName: 'Nieva',
        email: 'eonhiei@gmail.com',
        instagramProfile: {
          handle: 'bbitesph',
          followers: 1999,
          engagementRate: 0.4452226113056529
        },
        tiktokProfile: {
          handle: 'techtography',
          followers: 118,
          engagementRate: 1.7574692442882252
        },
        youtubeProfile: {
          handle: 'baguiocityguide',
          followers: 19500
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T18:35:44.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd2e513d4-ec9b-4a9b-8843-a392c38ad2a5',
      content: [],
      creator: {
        id: 'c75962e4-af31-4121-9002-c0ffa13fcd00',
        firstName: 'Vanessa',
        lastName: 'Wells',
        email: 'vebay31@gmail.com',
        instagramProfile: {
          handle: 'life_as_a_lion',
          followers: 4206,
          engagementRate: 0.3804089396100808
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T18:45:39.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8481ad82-f9f7-4f35-9293-4b41a9ff1575',
      content: [
        {
          groupId: '2b75c3a3-ccd5-4f30-8771-4f02f77b0f04',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:13:56.749Z',

          createdAt: '2024-06-06T23:05:46.130Z',
          content: [
            {
              caption: '',

              id: '70294db1-c783-4a6c-b49f-3c3978756366',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8481ad82-f9f7-4f35-9293-4b41a9ff1575/ugc/2b75c3a3-ccd5-4f30-8771-4f02f77b0f04/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '18a2cb6b-4cbd-4df0-b4c3-d164fc1fb726',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T12:59:49.577Z',

          createdAt: '2024-06-06T23:06:31.469Z',
          content: [
            {
              caption: '',

              id: 'd3190546-2a3a-4f10-b3f6-c83266761d7c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8481ad82-f9f7-4f35-9293-4b41a9ff1575/ugc/18a2cb6b-4cbd-4df0-b4c3-d164fc1fb726/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4a2c87fa-2d4d-4d7f-9151-3e82f906caf9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8481ad82-f9f7-4f35-9293-4b41a9ff1575/ugc/18a2cb6b-4cbd-4df0-b4c3-d164fc1fb726/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '866cb559-13df-4f0b-87fa-f6d017d99a17',
        firstName: 'Brigite',
        lastName: 'Workman',
        email: 'beautifulblestmess@gmail.com',
        instagramProfile: {
          handle: 'basicallybrigite',
          followers: 1474,
          engagementRate: 3.8738127544097694
        },
        tiktokProfile: {
          handle: 'basicallybrigite',
          followers: 95
        },
        youtubeProfile: {
          handle: 'brigiteworkman22',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T19:35:28.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'deb26384-641f-4f1c-8d3a-cabfd0ddc808',
      content: [],
      creator: {
        id: '2bef7134-1d99-4f93-a1f0-e122199ab203',
        firstName: 'Lori',
        lastName: 'Harrison',
        email: 'loriharrisonn@hotmail.com',
        instagramProfile: {
          handle: 'thelifeofkade_',
          followers: 2172,
          engagementRate: 4.673112338858195
        },
        tiktokProfile: {
          handle: 'thelifeofkade_',
          followers: 337,
          engagementRate: 3.0088495575221237
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T19:36:15.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8b20ad79-d74b-4a8f-845c-faedb33471ec',
      content: [
        {
          groupId: '089933a6-1ace-4e6a-8ed4-203c80aeb570',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T19:53:38.423Z',

          createdAt: '2024-06-05T03:08:43.602Z',
          content: [
            {
              caption: '',

              id: 'bfada2b0-954a-438a-b91d-aa8500932de0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8b20ad79-d74b-4a8f-845c-faedb33471ec/ugc/089933a6-1ace-4e6a-8ed4-203c80aeb570/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'eb6193f5-eb32-433c-a0e1-2e80a38b0fb0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8b20ad79-d74b-4a8f-845c-faedb33471ec/ugc/089933a6-1ace-4e6a-8ed4-203c80aeb570/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'cbc7ab88-802e-4829-bd7b-1da992512249',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8b20ad79-d74b-4a8f-845c-faedb33471ec/ugc/089933a6-1ace-4e6a-8ed4-203c80aeb570/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '88b01013-5726-4647-947b-a4a675163535',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T05:54:45.434Z',

          createdAt: '2024-06-05T02:55:33.825Z',
          content: [
            {
              caption: '',

              id: 'a1435bfb-fdfd-428f-8c2c-35fadca4b15d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8b20ad79-d74b-4a8f-845c-faedb33471ec/ugc/88b01013-5726-4647-947b-a4a675163535/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1f56a445-28e6-4963-b547-7355c8793015',
        firstName: 'Maxim',
        lastName: 'Sulimkin',
        email: 'maxim.sulimkin@gmail.com',
        instagramProfile: {
          handle: 'maxphantom777',
          followers: 240,
          engagementRate: 3.3333333333333335
        },
        tiktokProfile: {
          handle: 'max_phantom777',
          followers: 3194,
          engagementRate: 2.8314028314028317
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T21:35:21.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2bc28085-4c11-433e-91b0-4c020c32464a',
      content: [
        {
          groupId: 'e3538ee6-291f-437f-a872-5f6781a5a7bd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T11:47:20.026Z',

          createdAt: '2024-06-19T12:11:50.313Z',
          content: [
            {
              caption: '',

              id: '39d56588-fc60-4340-8fa8-d43b718f93fe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bc28085-4c11-433e-91b0-4c020c32464a/ugc/e3538ee6-291f-437f-a872-5f6781a5a7bd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7497903c-6027-482c-93cd-312134a4b7cd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-20T11:47:25.300Z',

          createdAt: '2024-06-19T12:14:50.312Z',
          content: [
            {
              caption: '',

              id: 'ef4b6fb5-1343-4fff-aedd-383e502a61e3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bc28085-4c11-433e-91b0-4c020c32464a/ugc/7497903c-6027-482c-93cd-312134a4b7cd/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a9db9dfe-b9ae-44fe-93f6-88b6259c39af',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bc28085-4c11-433e-91b0-4c020c32464a/ugc/7497903c-6027-482c-93cd-312134a4b7cd/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6d5caf6f-58ec-43a8-9c00-133f0fe1faa1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bc28085-4c11-433e-91b0-4c020c32464a/ugc/7497903c-6027-482c-93cd-312134a4b7cd/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f952eecb-95aa-4999-8179-c4611cbaae96',
        firstName: 'Cyndi',
        lastName: 'Hale',
        email: 'frugalfamilyhale@gmail.com',
        instagramProfile: {
          handle: 'cyndiphale',
          followers: 1292,
          engagementRate: 0.13157894736842105
        },
        tiktokProfile: {
          handle: 'frugalfamily6',
          followers: 3865,
          engagementRate: 0.6760906529959648
        },
        youtubeProfile: {
          handle: 'frugalfamily6',
          followers: 49
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-01T23:33:42.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'aefa6a2f-6d5d-4222-be4e-17aa63155d2c',
      content: [
        {
          groupId: '7a8e42a7-011a-4113-96c4-416114c8ebc4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T20:55:33.713Z',

          createdAt: '2024-06-15T01:36:34.569Z',
          content: [
            {
              caption: '',

              id: 'd150e94c-dee5-4d15-ac90-f8f759323779',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aefa6a2f-6d5d-4222-be4e-17aa63155d2c/ugc/7a8e42a7-011a-4113-96c4-416114c8ebc4/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7e618e2c-0077-4b3e-a8f1-78226ae3a362',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T20:55:29.157Z',

          createdAt: '2024-06-15T01:35:12.764Z',
          content: [
            {
              caption: '',

              id: 'd56d369a-9a96-4ce2-b9ac-649a88c46221',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/aefa6a2f-6d5d-4222-be4e-17aa63155d2c/ugc/7e618e2c-0077-4b3e-a8f1-78226ae3a362/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'dc83eac8-3184-4495-8536-a8b7079d6bc7',
        firstName: 'Titania',
        lastName: 'Hibbert',
        email: 'titania.h3151405@gmail.com',

        tiktokProfile: {
          handle: 'titi_africandoll',
          followers: 31275,
          engagementRate: 7.574536663980661
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T00:16:23.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6b19037c-5e5f-4d40-95b9-9c20b74cb154',
      content: [
        {
          groupId: '9d0df7bd-df39-43db-915c-7fe7666ff632',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T13:42:42.754Z',

          createdAt: '2024-06-06T00:43:10.990Z',
          content: [
            {
              caption: '',

              id: '70d69fd9-2e04-45b2-b6c9-fed5bcaa95ea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6b19037c-5e5f-4d40-95b9-9c20b74cb154/ugc/9d0df7bd-df39-43db-915c-7fe7666ff632/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'af522c0b-66a7-48ac-989f-39c33705ea4f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6b19037c-5e5f-4d40-95b9-9c20b74cb154/ugc/9d0df7bd-df39-43db-915c-7fe7666ff632/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '02bb08ce-7c85-404a-bef0-68c15c992f98',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T10:57:05.806Z',

          createdAt: '2024-06-10T01:39:02.900Z',
          content: [
            {
              caption: '',

              id: '36721874-c48d-4381-9e26-763905bad5d9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6b19037c-5e5f-4d40-95b9-9c20b74cb154/ugc/02bb08ce-7c85-404a-bef0-68c15c992f98/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '86b503f8-2346-43be-bb41-b6421c855ac8',
        firstName: 'Jenny',
        lastName: 'VARELA',
        email: 'heavenlytreasure1018@gmail.com',
        instagramProfile: {
          handle: 'heavenlytreasureliving',
          followers: 21960,
          engagementRate: 0.11384335154826959
        },

        youtubeProfile: {
          handle: 'heavenlytreasure310',
          followers: 61
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T00:47:51.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '329fbabf-d3ea-4dc7-b9c5-f65ec40386bc',
      content: [
        {
          groupId: 'd6e69311-7e2d-468c-8f13-bdbc9711b25e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-11T01:55:56.872Z',

          createdAt: '2024-06-06T18:29:47.646Z',
          content: [
            {
              caption: '',

              id: '08e59fd5-2fd4-4672-bd71-a303e485530b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/329fbabf-d3ea-4dc7-b9c5-f65ec40386bc/ugc/d6e69311-7e2d-468c-8f13-bdbc9711b25e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3ce9914e-dfa8-4ccb-aab3-6150394a281e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/329fbabf-d3ea-4dc7-b9c5-f65ec40386bc/ugc/d6e69311-7e2d-468c-8f13-bdbc9711b25e/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '178a9b0f-76b8-441b-a772-8e12dcb2eb53',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/329fbabf-d3ea-4dc7-b9c5-f65ec40386bc/ugc/d6e69311-7e2d-468c-8f13-bdbc9711b25e/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2893efef-9829-46aa-a7ad-f477a31ab1e5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T01:55:50.040Z',

          createdAt: '2024-06-06T20:13:07.378Z',
          content: [
            {
              caption: '',

              id: 'd642291b-45f7-4432-b997-8a6a20ed7fe9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/329fbabf-d3ea-4dc7-b9c5-f65ec40386bc/ugc/2893efef-9829-46aa-a7ad-f477a31ab1e5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '43d42772-c0ab-47a7-a390-13b0bde092ce',
        firstName: 'Eric',
        lastName: 'Reynolds',
        email: 'ketofiveo@gmail.com',
        instagramProfile: {
          handle: 'ketofiveo',
          followers: 71249,
          engagementRate: 0.032982919058513104
        },
        tiktokProfile: {
          handle: 'ketofiveo',
          followers: 32280,
          engagementRate: 2.1322779370778213
        },
        youtubeProfile: {
          handle: 'ketofiveo'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T00:53:49.000Z',
        amountCents: 7168
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c12bb47b-8869-4440-a08f-c56aa593b7b4',
      content: [],
      creator: {
        id: '512d89b8-59af-4d76-a19d-c015a0fb4bcf',
        firstName: 'Patrick',
        lastName: 'Ricketts',
        email: 'patrickricketts17@gmail.com',
        instagramProfile: {
          handle: 'cedella_reidd',
          followers: 634
        },
        tiktokProfile: {
          handle: 'dexta1st',
          followers: 4313,
          engagementRate: 8.78623188405797
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T01:12:55.000Z',
        amountCents: 10606
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '50cb7f6e-69c7-4dd0-a9bb-84b48931ab4d',
      content: [
        {
          groupId: '887c96d7-a6a2-4ac2-9c19-6b7df69a8eda',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T16:05:52.387Z',

          createdAt: '2024-06-10T22:09:42.125Z',
          content: [
            {
              caption: '',

              id: '636653b2-caa6-4ebb-b311-50472db74005',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50cb7f6e-69c7-4dd0-a9bb-84b48931ab4d/ugc/887c96d7-a6a2-4ac2-9c19-6b7df69a8eda/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '0a672526-f16b-4a77-aa98-96e5cb8bb3cb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T16:06:00.041Z',

          createdAt: '2024-06-10T21:22:08.554Z',
          content: [
            {
              caption: '',

              id: 'b3c9eb83-96c4-47f1-86eb-33bfed306a9a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50cb7f6e-69c7-4dd0-a9bb-84b48931ab4d/ugc/0a672526-f16b-4a77-aa98-96e5cb8bb3cb/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0dad7bf0-8a96-4d82-b92c-2204f908c159',
        firstName: 'Yanique',
        lastName: 'Miller',
        email: 'yaniquemiller145@gmail.com',
        instagramProfile: {
          handle: 'inclusiveforyou._',
          followers: 1143,
          engagementRate: 2.2309711286089238
        },
        tiktokProfile: {
          handle: 'millidon_',
          followers: 4774,
          engagementRate: 11.970342468006711
        },
        youtubeProfile: {
          handle: 'ymgang423',
          followers: 188
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T01:26:26.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7583867a-2776-4840-8737-c0ebf75682e8',
      content: [
        {
          groupId: '2aab4be2-e7b3-4539-9c55-79a6e46624de',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T14:49:05.853Z',

          createdAt: '2024-06-12T06:34:48.470Z',
          content: [
            {
              caption: '',

              id: 'f4cb25b8-5814-4be8-a9b6-d340a57e2e25',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7583867a-2776-4840-8737-c0ebf75682e8/ugc/2aab4be2-e7b3-4539-9c55-79a6e46624de/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '06561692-fc61-4e6d-8da9-6a3cbf89eb8f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-19T14:48:59.014Z',

          createdAt: '2024-06-19T04:33:36.753Z',
          content: [
            {
              caption: '',

              id: '8077ac21-553c-4ba6-a128-053fe5882c22',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7583867a-2776-4840-8737-c0ebf75682e8/ugc/06561692-fc61-4e6d-8da9-6a3cbf89eb8f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5d1e616d-2a30-4d73-ad01-f9735207d911',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7583867a-2776-4840-8737-c0ebf75682e8/ugc/06561692-fc61-4e6d-8da9-6a3cbf89eb8f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '09a50101-1394-4e8e-9509-5ad32eb82872',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7583867a-2776-4840-8737-c0ebf75682e8/ugc/06561692-fc61-4e6d-8da9-6a3cbf89eb8f/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '01865128-ccc6-4fac-b783-b3168e9739c9',
        firstName: 'Tanyakaye',
        lastName: 'Thompson',
        email: 'tanyakayethompson@gmail.com',
        instagramProfile: {
          handle: 'gabby_sweet_essence_collection',
          followers: 102,
          engagementRate: 3.8461538461538463
        },
        tiktokProfile: {
          handle: 'the_.original_gabby',
          followers: 3052,
          engagementRate: 10.625346974383376
        },
        youtubeProfile: {
          handle: 'lifeofgabby19',
          followers: 1070
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T01:26:28.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9471dd09-bed3-4bd3-9cad-72012ccdc97b',
      content: [
        {
          groupId: '0af263e0-c6a4-46fc-b582-2674ca85774c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T01:11:44.988Z',

          createdAt: '2024-06-08T01:58:15.810Z',
          content: [
            {
              caption: '',

              id: '7e396117-939d-4033-a605-c3a113fe8b4d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9471dd09-bed3-4bd3-9cad-72012ccdc97b/ugc/0af263e0-c6a4-46fc-b582-2674ca85774c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3f9b49d6-cee1-42ba-a8e2-7eac850287d3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T01:11:36.918Z',

          createdAt: '2024-06-11T04:00:58.866Z',
          content: [
            {
              caption: '',

              id: '3ef58d15-8aa5-4c86-80e8-d91cdbf064fb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9471dd09-bed3-4bd3-9cad-72012ccdc97b/ugc/3f9b49d6-cee1-42ba-a8e2-7eac850287d3/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '269012b9-d396-4713-b695-58f1103e33ae',
        firstName: 'Jamile',
        lastName: 'Dunn',
        email: 'everjamz@gmail.com',
        instagramProfile: {
          handle: 'jam_jewelz',
          followers: 916,
          engagementRate: 4.967248908296943
        },
        tiktokProfile: {
          handle: 'jam_zellaaaa',
          followers: 1487
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T01:36:23.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b4062787-5973-4a0b-ba63-8c4ae38caff9',
      content: [
        {
          groupId: '7195c8b9-e777-45ee-ac5a-80a222ec2a00',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T00:10:10.038Z',

          createdAt: '2024-06-04T21:44:19.031Z',
          content: [
            {
              caption: '',

              id: 'c6d2fea7-0c3c-4381-b8af-18dcd2c4a50c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b4062787-5973-4a0b-ba63-8c4ae38caff9/ugc/7195c8b9-e777-45ee-ac5a-80a222ec2a00/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c33b2e92-1c27-4bac-bf0b-3e7969c17f7f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T03:03:16.038Z',

          createdAt: '2024-06-04T23:46:54.444Z',
          content: [
            {
              caption: '',

              id: '7a267457-e5de-49f1-b55e-f9e4c884d425',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b4062787-5973-4a0b-ba63-8c4ae38caff9/ugc/c33b2e92-1c27-4bac-bf0b-3e7969c17f7f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '725c5a64-6965-43c1-860e-51016310f83a',
        firstName: 'Sarah',
        lastName: 'Hickman',
        email: 'greatmichadventure@gmail.com',
        instagramProfile: {
          handle: 'greatmichadventure',
          followers: 573,
          engagementRate: 1.710296684118674
        },
        tiktokProfile: {
          handle: 'greatmichadventure',
          followers: 8490,
          engagementRate: 7.424617926476662
        },
        youtubeProfile: {
          handle: 'greatmichadventure'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T01:52:59.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a2488571-8861-4c22-9275-4340ced3fb06',
      content: [],
      creator: {
        id: 'bc2958d1-dcad-442e-aa32-b956550bf376',
        firstName: 'Jackie',
        lastName: 'Bobbitt',
        email: 'mamafitnation@gmail.com',
        instagramProfile: {
          handle: 'coachjaqe',
          followers: 5490,
          engagementRate: 0.42076502732240445
        },
        tiktokProfile: {
          handle: 'theemamafit',
          followers: 266,
          engagementRate: 18.30985915492958
        },
        youtubeProfile: {
          handle: 'sincerelymamapodcast',
          followers: 161
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T01:55:32.000Z',
        amountCents: 11443
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e8c0c2aa-16d1-429e-b54b-6e515d18a9cd',
      content: [
        {
          groupId: '7b8318e7-f0c3-4dc3-a2e7-fd469a5db0c1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-16T18:03:08.790Z',

          createdAt: '2024-06-07T05:38:55.886Z',
          content: [
            {
              caption: '',

              id: '104613c3-b3f7-4e60-997c-0442938534b5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e8c0c2aa-16d1-429e-b54b-6e515d18a9cd/ugc/7b8318e7-f0c3-4dc3-a2e7-fd469a5db0c1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f914146f-9c33-4ed5-96d5-5977473997fa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-16T18:03:15.354Z',

          createdAt: '2024-06-05T00:27:42.960Z',
          content: [
            {
              caption: '',

              id: '2046c41a-4058-4372-9d2b-6d9e25aaf1ea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e8c0c2aa-16d1-429e-b54b-6e515d18a9cd/ugc/f914146f-9c33-4ed5-96d5-5977473997fa/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ce4f9a5d-f79e-4fad-ae9a-f520257b99ce',
        firstName: 'Sue ann',
        lastName: 'James',
        email: 'sueann.james@icloud.com',
        instagramProfile: {
          handle: 'suefab2024',
          followers: 41,
          engagementRate: 2.4390243902439024
        },
        tiktokProfile: {
          handle: 'suejames90',
          followers: 3385,
          engagementRate: 9.87202925045704
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T03:27:18.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eec093fe-4df3-47a3-adb2-c2d1de4e671b',
      content: [
        {
          groupId: 'd4700e66-1452-4022-8481-c29683277ad5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-16T12:02:46.023Z',

          createdAt: '2024-06-14T15:19:07.223Z',
          content: [
            {
              caption: '',

              id: '3077a103-b197-4b43-a284-32875cd3e8da',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eec093fe-4df3-47a3-adb2-c2d1de4e671b/ugc/d4700e66-1452-4022-8481-c29683277ad5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cf5c1afa-1a9b-43e9-9858-01b5bbcb773f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-14T15:14:50.632Z',

          createdAt: '2024-06-14T03:41:42.909Z',
          content: [
            {
              caption: '',

              id: '1914d40f-011f-4748-ade9-33e6215e7a3d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eec093fe-4df3-47a3-adb2-c2d1de4e671b/ugc/cf5c1afa-1a9b-43e9-9858-01b5bbcb773f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f27d4c3a-a6ce-44bd-8567-80afadd7cc8a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eec093fe-4df3-47a3-adb2-c2d1de4e671b/ugc/cf5c1afa-1a9b-43e9-9858-01b5bbcb773f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bee7eab0-e0c3-4040-b5dd-d8aab6892c97',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eec093fe-4df3-47a3-adb2-c2d1de4e671b/ugc/cf5c1afa-1a9b-43e9-9858-01b5bbcb773f/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '01b08883-3bf1-4908-b58b-10f6634abe05',
        firstName: 'Jerri',
        lastName: 'Gizzarelli',
        email: 'jgizzarelli@icloud.com',
        instagramProfile: {
          handle: 'jerseyann540',
          followers: 1640,
          engagementRate: 3.536585365853658
        },
        tiktokProfile: {
          handle: 'jerseyx3',
          followers: 344,
          engagementRate: 7.161125319693094
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T03:32:06.000Z',
        amountCents: 7370
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b6adb2fc-5c13-4cfa-94a0-d302da0d20eb',
      content: [],
      creator: {
        id: '8a48eab6-77ad-4c7a-8548-abf08466b292',
        firstName: 'Elissa',
        lastName: 'Blackburn',
        email: 'hunterandelissablackburn@gmail.com',
        instagramProfile: {
          handle: 'hunterandelissa',
          followers: 1704,
          engagementRate: 0.6259780907668231
        },
        tiktokProfile: {
          handle: 'hunterandelissa18',
          followers: 6465,
          engagementRate: 2.6954177897574128
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T04:35:13.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bd739e4d-2d2f-4942-88b1-7df53f45a6e2',
      content: [
        {
          groupId: '978c2812-157b-44a8-848b-28493b7d497f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T17:14:29.002Z',

          createdAt: '2024-06-10T16:50:35.283Z',
          content: [
            {
              caption: '',

              id: '0d861e29-0324-4ddc-b852-15e03f71f23c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd739e4d-2d2f-4942-88b1-7df53f45a6e2/ugc/978c2812-157b-44a8-848b-28493b7d497f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '57ec15e1-8344-4859-966e-3fc8c056613b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T13:56:15.730Z',

          createdAt: '2024-06-10T16:52:34.155Z',
          content: [
            {
              caption: '',

              id: '01ec5686-b48c-46fd-8660-1355001133d7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd739e4d-2d2f-4942-88b1-7df53f45a6e2/ugc/57ec15e1-8344-4859-966e-3fc8c056613b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd9ca88c9-7f02-45b5-a180-2c7b9967c83f',
        firstName: 'Aaliyah',
        lastName: 'Palmer',
        email: 'lovefun79@hotmail.com',
        instagramProfile: {
          handle: 'devineaquarius',
          followers: 3415,
          engagementRate: 0.5563689604685212
        },
        tiktokProfile: {
          handle: 'msgaza29',
          followers: 1619,
          engagementRate: 0.38314176245210724
        },
        youtubeProfile: {
          handle: 'aaliyahp9683',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T07:22:50.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '21dfd20a-76b8-494e-b2df-6c73a6f4f310',
      content: [],
      creator: {
        id: 'bedc10ce-6f25-436d-9e93-3733257fec57',
        firstName: 'Stefa',
        lastName: 'Groves',
        email: 'stefagroves@gmail.com',
        instagramProfile: {
          handle: 'stefagt',
          followers: 2513,
          engagementRate: 0.47751691205730207
        },
        tiktokProfile: {
          handle: 'stefagt',
          followers: 76
        },
        youtubeProfile: {
          handle: 'stefagt',
          followers: 9
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T08:35:39.000Z',
        amountCents: 7580
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dcd7f830-3867-4066-b86b-378093ec5c28',
      content: [
        {
          groupId: 'e618801b-76f3-4501-a987-4cd858704897',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T09:27:33.212Z',

          createdAt: '2024-06-22T01:26:07.266Z',
          content: [
            {
              caption: '',

              id: 'a4c2a535-7d77-4ac7-9154-5e537507de8b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dcd7f830-3867-4066-b86b-378093ec5c28/ugc/e618801b-76f3-4501-a987-4cd858704897/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '792af9ca-c376-41a6-ac5d-2f61e8b51531',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T09:27:44.026Z',

          createdAt: '2024-06-22T01:26:33.755Z',
          content: [
            {
              caption: '',

              id: '3c3c580c-e661-4465-9067-7b0bff007278',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dcd7f830-3867-4066-b86b-378093ec5c28/ugc/792af9ca-c376-41a6-ac5d-2f61e8b51531/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bb6159e6-f23a-4a64-b001-205231e7fca3',
        firstName: 'Diashaja',
        lastName: 'Dawson',
        email: 'daija.ugc@gmail.com',
        instagramProfile: {
          handle: 'eli_jaes',
          followers: 43,
          engagementRate: 9.30232558139535
        },
        tiktokProfile: {
          handle: 'daija_ru',
          followers: 5008,
          engagementRate: 16.17440225035162
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T12:16:20.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '46bbe639-6a97-4858-a5a2-bf1c6b001e66',
      content: [
        {
          groupId: '8d8a3713-f342-4e6d-9605-b09ab33a33c2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T22:44:11.483Z',

          createdAt: '2024-06-08T01:06:46.989Z',
          content: [
            {
              caption: '',

              id: 'f60355ee-284c-4705-a637-87fc3dbe204a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46bbe639-6a97-4858-a5a2-bf1c6b001e66/ugc/8d8a3713-f342-4e6d-9605-b09ab33a33c2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '10370368-7fe5-4604-992a-91a7e99db174',
        firstName: 'Breanna',
        lastName: 'Lawson',
        email: 'breannalawson101@gmail.com',
        instagramProfile: {
          handle: 'bree_anna_._',
          followers: 2388
        },
        tiktokProfile: {
          handle: 'bree_tania._',
          followers: 1008
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T12:52:30.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7378dc76-4005-4ca6-a8c7-eb6f10436685',
      content: [
        {
          groupId: '016157d8-4c85-488c-9b82-b9461ad6d434',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T23:16:42.377Z',

          createdAt: '2024-06-10T04:29:34.258Z',
          content: [
            {
              caption: '',

              id: '713b642d-fc20-4a30-a854-ac1ea6b9db88',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7378dc76-4005-4ca6-a8c7-eb6f10436685/ugc/016157d8-4c85-488c-9b82-b9461ad6d434/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '23ca37c2-dbf1-452a-9143-ee60bdfc07ce',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T23:16:52.125Z',

          createdAt: '2024-06-10T04:30:07.438Z',
          content: [
            {
              caption: '',

              id: '0f01fb89-fd0d-4f65-aa7c-9b8f1722f801',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7378dc76-4005-4ca6-a8c7-eb6f10436685/ugc/23ca37c2-dbf1-452a-9143-ee60bdfc07ce/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a74e2d82-2b3d-4846-8c4e-703c35fe0c85',
        firstName: 'Cordarius',
        lastName: 'Samples',
        email: 'cmsamples1@gmail.com',
        instagramProfile: {
          handle: 'itscee06',
          followers: 9281,
          engagementRate: 51.469669216679236
        },
        tiktokProfile: {
          handle: 'aleshandcee',
          followers: 5997,
          engagementRate: 7.144270730259252
        },
        youtubeProfile: {
          handle: 'aleshandcee',
          followers: 1470
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T13:05:46.000Z',
        amountCents: 8757
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3251f0f2-7b4c-469c-8272-0b6718600c44',
      content: [],
      creator: {
        id: '41f81eb0-00a0-4666-8e18-91998b3efa3c',
        firstName: 'Summer',
        lastName: 'Dawn',
        email: 'summerdawn406@gmail.com',
        instagramProfile: {
          handle: '__0110summer',
          followers: 1528
        },
        tiktokProfile: {
          handle: 'summerdawn96',
          followers: 4054
        },
        youtubeProfile: {
          handle: 'summerdawn3322',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T14:26:14.000Z',
        amountCents: 12718
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '63aeb456-8b93-451b-ac3c-b76328d22f8d',
      content: [],
      creator: {
        id: 'df64e70b-43ac-43a1-bbf0-961e4eeffeb4',
        firstName: 'Shemekia',
        lastName: 'ONeal',
        email: 'arterberry_s@yahoo.com',
        instagramProfile: {
          handle: 'shemekiashantaa',
          followers: 2023,
          engagementRate: 1.0627780523974295
        },
        tiktokProfile: {
          handle: 'demithesheepadoodle',
          followers: 214,
          engagementRate: 0.8130081300813009
        },
        youtubeProfile: {
          handle: 'soarwithchictravel',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T16:23:39.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3a95731f-6c71-447b-a956-cc9e58669d7b',
      content: [
        {
          groupId: '38dc81d8-5314-4559-acdf-2018167a9e32',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T15:20:44.449Z',

          createdAt: '2024-06-09T01:08:24.493Z',
          content: [
            {
              caption: '',

              id: 'afcb6258-c297-4e48-93c2-2a3b264e0e82',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a95731f-6c71-447b-a956-cc9e58669d7b/ugc/38dc81d8-5314-4559-acdf-2018167a9e32/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bb8dcdff-b4fc-43b7-bd6e-b5792e96d3e5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T23:33:26.616Z',

          createdAt: '2024-06-09T01:09:42.498Z',
          content: [
            {
              caption: '',

              id: 'b29a90cc-cae1-4cd0-939d-dd3ec94e90b2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a95731f-6c71-447b-a956-cc9e58669d7b/ugc/bb8dcdff-b4fc-43b7-bd6e-b5792e96d3e5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '93ac5de4-1bbe-4094-952e-ff29cf482a29',
        firstName: 'Chelsea',
        lastName: 'Wilson',
        email: 'chelseawilson0320@gmail.com',
        instagramProfile: {
          handle: 'cwilson0327',
          followers: 1042,
          engagementRate: 0.3742802303262956
        },
        tiktokProfile: {
          handle: 'cwilson0327',
          followers: 10406,
          engagementRate: 1.0204081632653061
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T16:42:02.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '06fa9ee7-a687-4c35-97a0-ca3240cb4dc6',
      content: [
        {
          groupId: '870fb08e-c284-439e-814f-dba4c5cdaad9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T21:33:21.106Z',

          createdAt: '2024-06-10T05:02:58.001Z',
          content: [
            {
              caption: '',

              id: '9e7c5994-eb31-48da-8514-efd65e637e46',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/06fa9ee7-a687-4c35-97a0-ca3240cb4dc6/ugc/870fb08e-c284-439e-814f-dba4c5cdaad9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '83d5b9b6-ae0d-4f2c-9a4c-8f235e9b3d24',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T21:33:24.579Z',

          createdAt: '2024-06-10T05:06:41.434Z',
          content: [
            {
              caption: '',

              id: '44b893df-da2c-4cd4-8b77-b506ba9bbf6e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/06fa9ee7-a687-4c35-97a0-ca3240cb4dc6/ugc/83d5b9b6-ae0d-4f2c-9a4c-8f235e9b3d24/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e59aa7e8-3a39-4cba-bdb2-4998f8a0a668',
        firstName: 'Noora',
        lastName: 'Logan',
        email: 'noora.alobaidi@icloud.com',
        instagramProfile: {
          handle: 'missnooraali',
          followers: 8294,
          engagementRate: 0.8946226187605498
        },
        tiktokProfile: {
          handle: 'missnooraali',
          followers: 146,
          engagementRate: 0.3663003663003663
        },
        youtubeProfile: {
          handle: 'na3582',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T16:42:14.000Z',
        amountCents: 7651
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a4a4b7ea-a44b-42e3-a669-4fa766ee39c4',
      content: [
        {
          groupId: '5b88d757-9475-4af5-998e-c18a0b49b77a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-08T14:17:51.130Z',

          createdAt: '2024-06-05T23:20:54.950Z',
          content: [
            {
              caption: '',

              id: 'eeb9d9a3-be88-4389-a1d1-55025f2ab93a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4a4b7ea-a44b-42e3-a669-4fa766ee39c4/ugc/5b88d757-9475-4af5-998e-c18a0b49b77a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '44fe0095-9f01-4190-8d95-5fb368f1ec6f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4a4b7ea-a44b-42e3-a669-4fa766ee39c4/ugc/5b88d757-9475-4af5-998e-c18a0b49b77a/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e351b7eb-003d-49d2-93d8-ca8786991b7f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4a4b7ea-a44b-42e3-a669-4fa766ee39c4/ugc/5b88d757-9475-4af5-998e-c18a0b49b77a/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '14260b60-0653-4fc7-a4c7-1d5682c8e6e4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-08T14:18:06.429Z',

          createdAt: '2024-06-05T23:19:35.174Z',
          content: [
            {
              caption: '',

              id: '5eb905e4-9fe8-481f-89a3-666a89bb837d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a4a4b7ea-a44b-42e3-a669-4fa766ee39c4/ugc/14260b60-0653-4fc7-a4c7-1d5682c8e6e4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5717bd9d-06b4-4bd8-b398-3acd04227f7a',
        firstName: 'Leesa',
        lastName: 'Nueva',
        email: 'analeeriotiktok@gmail.com',
        instagramProfile: {
          handle: 'auroas_ink_n_gifx',
          followers: 73,
          engagementRate: 19.17808219178082
        },
        tiktokProfile: {
          handle: 'analee_ugc_creator',
          followers: 2834,
          engagementRate: 10.156637255873898
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T16:54:04.000Z',
        amountCents: 8531
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2bf78ebc-e6a4-477e-85ce-367c13dde1b3',
      content: [],
      creator: {
        id: 'c6cc3f5f-66ed-41e9-967e-8306dacf17a0',
        firstName: 'Molly',
        lastName: 'McNeely',
        email: 'mollyecom@gmail.com',
        instagramProfile: {
          handle: 'mollydeann23',
          followers: 1746,
          engagementRate: 0.036273386788850705
        },
        tiktokProfile: {
          handle: 'gooseprobably',
          followers: 1100
        },
        youtubeProfile: {
          handle: 'ugcbymolly',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T16:55:46.000Z',
        amountCents: 8604
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7139dd5a-d586-407d-9b60-696e7a5818c2',
      content: [
        {
          groupId: '0bfe0814-461e-4428-9bc9-fc109ef2f04e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T17:51:01.262Z',

          createdAt: '2024-06-12T19:54:57.828Z',
          content: [
            {
              caption: '',

              id: 'ab7a316f-0472-43b7-98da-f934d7d22c44',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7139dd5a-d586-407d-9b60-696e7a5818c2/ugc/0bfe0814-461e-4428-9bc9-fc109ef2f04e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fb248357-fd17-4e5a-9b8a-e24b44a4cdc1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T17:51:04.528Z',

          createdAt: '2024-06-09T22:50:25.889Z',
          content: [
            {
              caption: '',

              id: 'cd5f66c2-f2a5-4a3b-aef2-dac35d973611',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7139dd5a-d586-407d-9b60-696e7a5818c2/ugc/fb248357-fd17-4e5a-9b8a-e24b44a4cdc1/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cb3af949-8e12-46aa-bdae-f946f660d425',
        firstName: 'Lexi',
        lastName: 'Kornbluth',
        email: 'reality1momma@gmail.com',
        instagramProfile: {
          handle: 'reality_momma',
          followers: 800,
          engagementRate: 1.8124999999999998
        },
        tiktokProfile: {
          handle: 'that_mom_vibe',
          followers: 15,
          engagementRate: 1.2828736369467608
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T16:58:01.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0e6151b6-8e97-499a-b91d-790c8dcb61ac',
      content: [],
      creator: {
        id: '4f1220b1-f474-487c-bf6d-84297b351787',
        firstName: 'Sherica',
        lastName: 'Wright',
        email: 'shericawright19@gmail.com',

        tiktokProfile: {
          handle: 'ricka_wright',
          followers: 22,
          engagementRate: 12.176551241284752
        },
        youtubeProfile: {
          handle: 'user-hy1fg8ov8s',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T17:05:42.000Z',
        amountCents: 10271
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0328fcc8-52ab-4a71-af30-2df26b0d9c1f',
      content: [
        {
          groupId: '1c25ff18-f7ae-4253-baf4-f7c38996649a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-03T10:48:37.250Z',

          createdAt: '2024-07-02T03:26:48.690Z',
          content: [
            {
              caption: '',

              id: '56030f4b-5724-4ef7-b1cd-786e42e52f7c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0328fcc8-52ab-4a71-af30-2df26b0d9c1f/ugc/1c25ff18-f7ae-4253-baf4-f7c38996649a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '12448be9-fc7e-421e-9a72-dc8ee92843bb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0328fcc8-52ab-4a71-af30-2df26b0d9c1f/ugc/1c25ff18-f7ae-4253-baf4-f7c38996649a/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a2e8d5f9-feeb-4ac2-b227-6d7d7594a140',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T11:10:26.095Z',

          createdAt: '2024-07-02T03:26:07.552Z',
          content: [
            {
              caption: '',

              id: 'd3e3a93d-096c-4524-baa5-338303bb4b97',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0328fcc8-52ab-4a71-af30-2df26b0d9c1f/ugc/a2e8d5f9-feeb-4ac2-b227-6d7d7594a140/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cf26b16a-75c5-4cbd-9321-f734fd26003d',
        firstName: 'Anita',
        lastName: 'Moonsammy',
        email: 'stylewmoonnme@gmail.com',
        instagramProfile: {
          handle: 'style.w.moon.n.me',
          followers: 2888,
          engagementRate: 0.9522160664819944
        },
        tiktokProfile: {
          handle: 'anitamoonsammy',
          followers: 79
        },
        youtubeProfile: {
          handle: 'anitamoonsammy4407',
          followers: 60
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T17:12:07.000Z',
        amountCents: 14838
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '62c0a80d-bb92-42f4-aa6b-408995791918',
      content: [
        {
          groupId: 'd536207d-bc74-4212-bad0-c726bb0d0e23',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-19T16:03:43.735Z',

          createdAt: '2024-06-11T20:17:02.030Z',
          content: [
            {
              caption: '',

              id: 'add13b2f-15c4-4dfc-b44a-052cdb67e2f8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/62c0a80d-bb92-42f4-aa6b-408995791918/ugc/d536207d-bc74-4212-bad0-c726bb0d0e23/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd6c400a5-5675-411d-9d69-90b94f46aec0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/62c0a80d-bb92-42f4-aa6b-408995791918/ugc/d536207d-bc74-4212-bad0-c726bb0d0e23/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '89bc489e-d137-47c4-87cd-dc1d8775d1d3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/62c0a80d-bb92-42f4-aa6b-408995791918/ugc/d536207d-bc74-4212-bad0-c726bb0d0e23/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '28c55ef5-07fa-4fcc-b8d2-6e2ee2016e61',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-04T15:22:48.549Z',

          createdAt: '2024-06-26T21:00:49.205Z',
          content: [
            {
              caption: '',

              id: '0d2083e9-e0e0-4b8b-b63f-fc55cb5c5cbf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/62c0a80d-bb92-42f4-aa6b-408995791918/ugc/28c55ef5-07fa-4fcc-b8d2-6e2ee2016e61/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '40fdcf8b-22fa-435b-8d5a-5df22587aa28',
        firstName: 'Jhoen',
        lastName: 'Delgado',
        email: 'jhoendelgado17@gmail.com',
        instagramProfile: {
          handle: 'byjhoen',
          followers: 2058,
          engagementRate: 1.5063168124392614
        },
        tiktokProfile: {
          handle: 'byjhoen',
          followers: 2203,
          engagementRate: 2.857142857142857
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T17:34:05.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8bc01ef9-a870-4a53-97ca-1a2f8037dc54',
      content: [
        {
          groupId: '77092acb-8891-496a-b3a2-af66869e251c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-16T04:13:09.841Z',

          createdAt: '2024-06-06T05:19:39.496Z',
          content: [
            {
              caption: '',

              id: '52f9cad5-e0b0-4b55-9d66-3e867ae69e25',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8bc01ef9-a870-4a53-97ca-1a2f8037dc54/ugc/77092acb-8891-496a-b3a2-af66869e251c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '87f60ee8-c06d-4a6c-ba5b-e1d491773d0d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-16T04:13:03.134Z',

          createdAt: '2024-06-06T05:21:20.802Z',
          content: [
            {
              caption: '',

              id: 'ca0c4c9f-b03f-4686-af40-1eb0475ce795',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8bc01ef9-a870-4a53-97ca-1a2f8037dc54/ugc/87f60ee8-c06d-4a6c-ba5b-e1d491773d0d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b8d8011e-7544-4553-9baa-47343c60a253',
        firstName: 'Mellyssa',
        lastName: 'Diggs',
        email: 'mellyssa.diggs@gmail.com',
        instagramProfile: {
          handle: 'mellyssaangeldiggs',
          followers: 2417,
          engagementRate: 0.08688456764584196
        },
        tiktokProfile: {
          handle: 'aikonic_graphics',
          followers: 13355,
          engagementRate: 3.961673115901972
        },
        youtubeProfile: {
          handle: 'mellyssaangeldiggs',
          followers: 139
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T17:36:50.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ca99ca5e-4d16-461c-9bc7-5584815ac963',
      content: [
        {
          groupId: '3fcb910f-f054-42d3-8abb-3e024d4e6786',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T00:11:16.210Z',

          createdAt: '2024-06-04T23:18:24.219Z',
          content: [
            {
              caption: '',

              id: '4ff4a5ad-af39-4e7a-ae0a-5ea4f25d6842',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ca99ca5e-4d16-461c-9bc7-5584815ac963/ugc/3fcb910f-f054-42d3-8abb-3e024d4e6786/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'aa734eb3-8ce3-4d0e-be26-7a36c9143a9a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ca99ca5e-4d16-461c-9bc7-5584815ac963/ugc/3fcb910f-f054-42d3-8abb-3e024d4e6786/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6c838737-7f49-49dc-8119-a6151dd5e291',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ca99ca5e-4d16-461c-9bc7-5584815ac963/ugc/3fcb910f-f054-42d3-8abb-3e024d4e6786/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5cb91c07-5416-41b5-8b46-d82aa8067a32',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T02:45:27.161Z',

          createdAt: '2024-06-04T23:36:58.004Z',
          content: [
            {
              caption: '',

              id: '5fb1def0-f540-49f1-99bb-0997de76564a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ca99ca5e-4d16-461c-9bc7-5584815ac963/ugc/5cb91c07-5416-41b5-8b46-d82aa8067a32/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2094b92c-7267-46fa-af53-ef4241a5440e',
        firstName: 'Joshua',
        lastName: 'Lively',
        email: 'livelyworshipofficial@gmail.com',
        instagramProfile: {
          handle: 'livelyworship',
          followers: 1511,
          engagementRate: 2.984778292521509
        },
        tiktokProfile: {
          handle: 'livelyworship',
          followers: 138
        },
        youtubeProfile: {
          handle: 'livelyworship',
          followers: 213
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T17:45:16.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c869adc0-6d9f-4af9-9595-d0d22310bccb',
      content: [],
      creator: {
        id: 'a94a62cb-1c0a-422a-8842-be5661958fce',
        firstName: 'Alex',
        lastName: 'Teague',
        email: 'aeteague02@gmail.com',
        instagramProfile: {
          handle: 'alexx_teague',
          followers: 6608,
          engagementRate: 4.085231447465099
        },
        tiktokProfile: {
          handle: 'alexteague2',
          followers: 1724,
          engagementRate: 6.75422138836773
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T17:56:18.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c9f0b82b-d433-454e-a63d-dcaafd8daa29',
      content: [],
      creator: {
        id: 'df1b11ea-5806-4760-aa13-44c29e8b4d85',
        firstName: 'David',
        lastName: 'Doutt',
        email: 'ddoutt@icloud.com',
        instagramProfile: {
          handle: 'truckinbeardo',
          followers: 1510,
          engagementRate: 3.205298013245033
        },
        tiktokProfile: {
          handle: 'user44593036328395',
          followers: 8
        },
        youtubeProfile: {
          handle: 'ddoutt',
          followers: 14
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T18:03:39.000Z',
        amountCents: 7646
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3a972611-ecd6-456a-8f81-48fba46d7dd9',
      content: [
        {
          groupId: 'e906e177-c047-477b-8bb4-33065f2c235b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T14:48:24.602Z',

          createdAt: '2024-06-14T23:49:06.879Z',
          content: [
            {
              caption: '',

              id: '716d9fa0-5279-4cf1-8381-5db9977e445f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a972611-ecd6-456a-8f81-48fba46d7dd9/ugc/e906e177-c047-477b-8bb4-33065f2c235b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '6331efc6-2638-4e43-b107-92e149e4d42b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T16:25:56.439Z',

          createdAt: '2024-06-16T00:52:20.035Z',
          content: [
            {
              caption: '',

              id: 'b8289929-ed9b-4c04-9728-13034659a89a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3a972611-ecd6-456a-8f81-48fba46d7dd9/ugc/6331efc6-2638-4e43-b107-92e149e4d42b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2f349e8e-3286-4d94-8f40-ec73294f82c3',
        firstName: 'Tiffony',
        lastName: 'Wike',
        email: 'tiffonycamillestudios@gmail.com',
        instagramProfile: {
          handle: 'thesmokeymermaid',
          followers: 9276,
          engagementRate: 0.6603061664510566
        },
        tiktokProfile: {
          handle: 'smokeymermaid',
          followers: 180
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T18:03:53.000Z',
        amountCents: 7629
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1149394e-379b-4c63-9ccc-e4960e2bb09c',
      content: [
        {
          groupId: 'c2319f85-db1d-451e-a592-a03cd0ba290c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T18:03:33.029Z',

          createdAt: '2024-06-20T00:58:33.568Z',
          content: [
            {
              caption: '',

              id: '6464f4cf-625f-43c1-898c-99fae23df470',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1149394e-379b-4c63-9ccc-e4960e2bb09c/ugc/c2319f85-db1d-451e-a592-a03cd0ba290c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e9813a63-a06b-4155-8b2a-0c2673c3d89c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-20T00:58:42.416Z',

          createdAt: '2024-06-07T02:56:37.305Z',
          content: [
            {
              caption: '',

              id: '14855cb1-003e-485b-9feb-6acc5268e079',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1149394e-379b-4c63-9ccc-e4960e2bb09c/ugc/e9813a63-a06b-4155-8b2a-0c2673c3d89c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0e5156a5-bd4a-4cb5-a0b4-d110ab7eb361',
        firstName: 'Chris',
        lastName: 'Snell',
        email: 'snell.c@gmail.com',
        instagramProfile: {
          handle: 'nurseandthenomad',
          followers: 10552,
          engagementRate: 0.401819560272934
        },
        tiktokProfile: {
          handle: 'nurseandthenomad',
          followers: 24
        },
        youtubeProfile: {
          handle: 'nurseandthenomad'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T18:22:28.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '525ad4bb-5d0a-4c47-932a-8c47bb89e313',
      content: [],
      creator: {
        id: 'baf93ec5-92c7-4462-b4f1-355e86bcec48',
        firstName: 'Aroonsri ',
        lastName: 'Chaiyachatti ',
        email: 'achaiyachatti@icloud.com',

        tiktokProfile: {
          handle: 'iamaroonsri',
          followers: 741,
          engagementRate: 3.2828282828282833
        },
        youtubeProfile: {
          handle: 'therealaroonsrichaiyachatti',
          followers: 4520
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T18:42:24.000Z',
        amountCents: 7585
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '54987602-7ccf-4dbe-9c30-6f6b56b5b6a1',
      content: [
        {
          groupId: '9aeb4779-007e-4287-8f9b-97c8f054574f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-06T21:26:58.482Z',

          createdAt: '2024-06-03T19:14:37.096Z',
          content: [
            {
              caption: '',

              id: '542623c2-0485-4b21-ad34-c041c9a1283a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54987602-7ccf-4dbe-9c30-6f6b56b5b6a1/ugc/9aeb4779-007e-4287-8f9b-97c8f054574f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b128fc86-f32e-4467-a77e-1c0f63062679',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54987602-7ccf-4dbe-9c30-6f6b56b5b6a1/ugc/9aeb4779-007e-4287-8f9b-97c8f054574f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4f2a3b8d-23f2-4692-a519-98164cb0a3fc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54987602-7ccf-4dbe-9c30-6f6b56b5b6a1/ugc/9aeb4779-007e-4287-8f9b-97c8f054574f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bb0d6dfb-3dc5-454c-9ff2-39dc0743e808',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T13:40:08.092Z',

          createdAt: '2024-06-06T21:26:36.568Z',
          content: [
            {
              caption: '',

              id: 'ff96fdf3-3148-4a7d-bf22-84ba4867f82c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54987602-7ccf-4dbe-9c30-6f6b56b5b6a1/ugc/bb0d6dfb-3dc5-454c-9ff2-39dc0743e808/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '175a74f8-9ec2-428f-9cc5-8afe6d46c8f3',
        firstName: 'Katie',
        lastName: 'McGovern',
        email: 'katiemonacutie@yahoo.com',
        instagramProfile: {
          handle: 'elizabeth.mo.runner',
          followers: 118,
          engagementRate: 1.694915254237288
        },
        tiktokProfile: {
          handle: 'elizabethmorunner',
          followers: 1794,
          engagementRate: 6.349206349206349
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T18:52:32.000Z',
        amountCents: 7653
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a389cdbe-c977-4ff2-8908-34317ad9e619',
      content: [
        {
          groupId: '258781f4-a36d-4636-8fcd-36845b9397b4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-05T18:56:49.384Z',

          createdAt: '2024-06-04T17:06:40.721Z',
          content: [
            {
              caption: '',

              id: '99e38c06-5a6b-4e08-bfc9-507a16239d96',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a389cdbe-c977-4ff2-8908-34317ad9e619/ugc/258781f4-a36d-4636-8fcd-36845b9397b4/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '848e0f31-c26a-4d43-bcd9-2c3ae276f99d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a389cdbe-c977-4ff2-8908-34317ad9e619/ugc/258781f4-a36d-4636-8fcd-36845b9397b4/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '288eeba2-bf4d-4fa3-930f-11de7a94d834',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a389cdbe-c977-4ff2-8908-34317ad9e619/ugc/258781f4-a36d-4636-8fcd-36845b9397b4/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fbc15d4a-9d9c-4385-9989-1d3d67fd065d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-06T03:32:31.771Z',

          createdAt: '2024-06-04T17:17:30.665Z',
          content: [
            {
              caption: '',

              id: '2e40c07e-4c7a-4354-b219-3058d1136d69',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a389cdbe-c977-4ff2-8908-34317ad9e619/ugc/fbc15d4a-9d9c-4385-9989-1d3d67fd065d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5e45806f-58b5-4724-a86a-6c97ded2c6df',
        firstName: 'Amber',
        lastName: 'Burgess',
        email: 'amfaison28@gmail.com',
        instagramProfile: {
          handle: 'ambermarionb',
          followers: 2669,
          engagementRate: 0.47583364556013485
        },
        tiktokProfile: {
          handle: 'ambermarion28',
          followers: 1325,
          engagementRate: 12.620137299771168
        },
        youtubeProfile: {
          handle: 'ambermarion'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T18:54:28.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3aeb27f3-b20e-4662-9809-c37858c7b11c',
      content: [
        {
          groupId: '7c53d74a-d30b-49ae-91ef-1ed471691e86',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T13:16:49.859Z',

          createdAt: '2024-06-07T06:03:43.044Z',
          content: [
            {
              caption: '',

              id: '45e8a48f-ba1e-4ef0-be56-f0edfbf7c231',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3aeb27f3-b20e-4662-9809-c37858c7b11c/ugc/7c53d74a-d30b-49ae-91ef-1ed471691e86/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '72e3201a-54f9-42f5-a1ae-4495bdfbaafa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3aeb27f3-b20e-4662-9809-c37858c7b11c/ugc/7c53d74a-d30b-49ae-91ef-1ed471691e86/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ce5dca60-218f-4848-a287-23bb0983402a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3aeb27f3-b20e-4662-9809-c37858c7b11c/ugc/7c53d74a-d30b-49ae-91ef-1ed471691e86/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4f7271fa-ff97-4c1e-89bf-22ee5ae2f7bd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:16:37.784Z',

          createdAt: '2024-06-07T06:02:44.186Z',
          content: [
            {
              caption: '',

              id: '0c542c75-8410-4a2c-b0d0-0638dcac7d08',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3aeb27f3-b20e-4662-9809-c37858c7b11c/ugc/4f7271fa-ff97-4c1e-89bf-22ee5ae2f7bd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5c4c8ee1-d1a7-46b1-8645-91833f6525a8',
        firstName: 'Tracey',
        lastName: 'Reynolds',
        email: 'traceylreyn@gmail.com',
        instagramProfile: {
          handle: 'misbehaving_tl',
          followers: 722,
          engagementRate: 1.5433320142461415
        },
        tiktokProfile: {
          handle: 'misbehavingtl',
          followers: 1407,
          engagementRate: 10.84598698481562
        },
        youtubeProfile: {
          handle: 'youngyalqueen',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T19:04:23.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '74399538-bcaf-441e-9716-6e466bc21987',
      content: [],
      creator: {
        id: 'e3191bea-92d8-4f07-b0a2-f4a65246e9c1',
        firstName: 'Quantrell',
        lastName: 'Priest',
        email: 'qpriest2324@gmail.com',
        instagramProfile: {
          handle: 'qt_fit',
          followers: 1488,
          engagementRate: 0.020039551746868823
        },
        tiktokProfile: {
          handle: 'quantrellpriest',
          followers: 16
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T19:13:38.000Z',
        amountCents: 10919
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c50af0a0-11e5-4c82-9b7b-cd7e04ed2b9d',
      content: [
        {
          groupId: '4fe5f5f2-9bc9-44f9-8855-9df50d316ee3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T20:23:48.881Z',

          createdAt: '2024-06-05T02:08:50.539Z',
          content: [
            {
              caption: '',

              id: 'eccaf46a-f1e2-4b92-a79c-84c79090f907',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c50af0a0-11e5-4c82-9b7b-cd7e04ed2b9d/ugc/4fe5f5f2-9bc9-44f9-8855-9df50d316ee3/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a5bd9be4-4071-44fc-ab1c-809169ad9e22',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:06:16.285Z',

          createdAt: '2024-06-05T02:07:48.064Z',
          content: [
            {
              caption: '',

              id: '201ff869-0c6b-469d-9abb-da02d36ea5c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c50af0a0-11e5-4c82-9b7b-cd7e04ed2b9d/ugc/a5bd9be4-4071-44fc-ab1c-809169ad9e22/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a02b49c7-9169-4578-a1d2-498fe3162d62',
        firstName: 'Kristina',
        lastName: 'Trtek',
        email: 'jeremy_kris@comcast.net',
        instagramProfile: {
          handle: 'thedrizzledossier',
          followers: 2568,
          engagementRate: 5.299844236760124
        },

        youtubeProfile: {
          handle: 'kristina8216',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T19:15:20.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7e77fa5a-5b21-4932-b009-5c16585e7e9e',
      content: [
        {
          groupId: '86a0f8f6-edd2-413d-af1e-7fc60a2952e9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-29T09:42:19.830Z',

          createdAt: '2024-06-26T04:25:08.617Z',
          content: [
            {
              caption: '',

              id: '74798496-5b5b-4a59-8ee1-cb8d46ee7db7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7e77fa5a-5b21-4932-b009-5c16585e7e9e/ugc/86a0f8f6-edd2-413d-af1e-7fc60a2952e9/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b259d15e-4ce1-4e66-a3eb-c39291f8120c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7e77fa5a-5b21-4932-b009-5c16585e7e9e/ugc/86a0f8f6-edd2-413d-af1e-7fc60a2952e9/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5dbfd079-5868-4059-b644-e32f1cec5d2f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7e77fa5a-5b21-4932-b009-5c16585e7e9e/ugc/86a0f8f6-edd2-413d-af1e-7fc60a2952e9/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'dd76de57-5848-485c-9f09-7f004356bff1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-29T09:42:11.656Z',

          createdAt: '2024-06-28T04:19:48.873Z',
          content: [
            {
              caption: '',

              id: '8109357a-0ab3-416f-b57e-75581f319e2e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7e77fa5a-5b21-4932-b009-5c16585e7e9e/ugc/dd76de57-5848-485c-9f09-7f004356bff1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9cbb35fb-92dc-4c31-9a2d-4ff03bbc6ea7',
        firstName: 'Danita',
        lastName: 'Baham',
        email: 'danitabaham@yahoo.com',
        instagramProfile: {
          handle: 'abcdanita',
          followers: 2493,
          engagementRate: 1.444043321299639
        },
        tiktokProfile: {
          handle: 'abcdanita',
          followers: 114,
          engagementRate: 0.694458262859417
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T19:23:29.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e8ce86a2-be0d-4060-a0fe-35c681245a49',
      content: [],
      creator: {
        id: '4580e256-1a4e-434f-9713-4ede93961486',
        firstName: 'Tony',
        lastName: 'Rose',
        email: 'tonyroseshow@yahoo.com',
        instagramProfile: {
          handle: 'tonyroseshow',
          followers: 11325,
          engagementRate: 0.09889624724061809
        },

        youtubeProfile: {
          handle: 'tonyrose',
          followers: 374
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T19:33:39.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3495d39a-91dd-4f0e-bda2-04e20210809b',
      content: [
        {
          groupId: '745dcefd-62ec-474b-9593-06d87b6a1b8c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-10T14:24:12.659Z',

          createdAt: '2024-06-10T00:49:06.781Z',
          content: [
            {
              caption: '',

              id: '1bc040ed-8549-46ce-8671-dbcf9e762ed8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3495d39a-91dd-4f0e-bda2-04e20210809b/ugc/745dcefd-62ec-474b-9593-06d87b6a1b8c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '84d5e5a0-d02f-4777-a26a-4137e1c859ad',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T14:32:30.773Z',

          createdAt: '2024-06-10T00:50:44.626Z',
          content: [
            {
              caption: '',

              id: 'c81ebf4f-47b4-4e99-a9d9-a76e22fde0ff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3495d39a-91dd-4f0e-bda2-04e20210809b/ugc/84d5e5a0-d02f-4777-a26a-4137e1c859ad/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '07f9a061-2579-4c8e-8975-2d2e71fc9094',
        firstName: 'Sabrina',
        lastName: 'Ashe',
        email: 'brinaashe@gmail.com',
        instagramProfile: {
          handle: 'nana.ashe2021',
          followers: 53,
          engagementRate: 15.849056603773587
        },
        tiktokProfile: {
          handle: 'nana.ashe',
          followers: 8435,
          engagementRate: 2.20600977368038
        },
        youtubeProfile: {
          handle: 'brinaashe',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T19:52:31.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cda81ef9-1069-48c4-af4e-6e2e6744bcf1',
      content: [
        {
          groupId: 'bf95ff8f-4183-438d-8a20-5e3e5fa6089f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T14:11:45.329Z',

          createdAt: '2024-06-07T00:00:26.686Z',
          content: [
            {
              caption: '',

              id: '985c9073-b15f-4d60-93ae-0e7f03d038c7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cda81ef9-1069-48c4-af4e-6e2e6744bcf1/ugc/bf95ff8f-4183-438d-8a20-5e3e5fa6089f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5f7efd15-0fb6-4032-a13a-aa45fe5aa1fe',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cda81ef9-1069-48c4-af4e-6e2e6744bcf1/ugc/bf95ff8f-4183-438d-8a20-5e3e5fa6089f/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '900fa5c2-e30b-4d33-9658-922ccce65d70',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:11:40.025Z',

          createdAt: '2024-06-06T23:59:36.743Z',
          content: [
            {
              caption: '',

              id: '0129a66a-c625-4627-9407-b1d0f3d1c420',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cda81ef9-1069-48c4-af4e-6e2e6744bcf1/ugc/900fa5c2-e30b-4d33-9658-922ccce65d70/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'db5af7ab-f195-409b-a32e-697cc9853ef6',
        firstName: 'Karina',
        lastName: 'Kalinchuk',
        email: 'karina.kalinchuk@gmail.com',
        instagramProfile: {
          handle: 'karina.kalinchuk',
          followers: 1894,
          engagementRate: 2.983104540654699
        },
        tiktokProfile: {
          handle: 'karina.kalinchuk',
          followers: 144,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'karinavishnevskaya9301',
          followers: 82
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T21:43:38.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '360a1a6d-bbb3-45f3-bb54-f1442fcc3279',
      content: [],
      creator: {
        id: 'db473b9c-221f-41d3-8fba-29720ff8b607',
        firstName: 'Authentic Grain',
        lastName: 'Wood',
        email: 'brandonw@authenticgrain.com',
        instagramProfile: {
          handle: 'authentic_grain',
          followers: 1311,
          engagementRate: 3.0663615560640736
        },
        tiktokProfile: {
          handle: 'authentic_grain',
          followers: 145,
          engagementRate: 3.0395136778115504
        },
        youtubeProfile: {
          handle: 'authenticgrain5672',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T22:32:04.000Z',
        amountCents: 7580
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '95ffefde-5e34-46a1-857e-923cfd1f1672',
      content: [],
      creator: {
        id: 'e55d4f08-0f95-400a-90c6-9f17fff5a832',
        firstName: 'Kira',
        lastName: 'Darling',
        email: 'kirbor@yahoo.com',

        tiktokProfile: {
          handle: 'kirbor',
          followers: 1312,
          engagementRate: 6.0606060606060606
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T22:42:55.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5cba9e44-19f1-4a55-81e6-938bb62812ee',
      content: [
        {
          groupId: '44933a0f-c638-44dd-a2dc-71a011c5371b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T16:08:33.681Z',

          createdAt: '2024-06-03T22:14:05.124Z',
          content: [
            {
              caption: '',

              id: '6ec6ed55-f6ca-46ac-87cc-434412de4983',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5cba9e44-19f1-4a55-81e6-938bb62812ee/ugc/44933a0f-c638-44dd-a2dc-71a011c5371b/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '24b97e0b-fa7e-4c9f-8c37-387d092ef3aa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5cba9e44-19f1-4a55-81e6-938bb62812ee/ugc/44933a0f-c638-44dd-a2dc-71a011c5371b/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6e539911-e82b-4929-acc0-ca50f5ba153a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5cba9e44-19f1-4a55-81e6-938bb62812ee/ugc/44933a0f-c638-44dd-a2dc-71a011c5371b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4b76a39b-a81a-4210-bfd6-25133aca08f0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T16:08:27.216Z',

          createdAt: '2024-06-03T22:13:37.512Z',
          content: [
            {
              caption: '',

              id: 'b541c7a9-3556-462d-bc95-45a787087c39',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5cba9e44-19f1-4a55-81e6-938bb62812ee/ugc/4b76a39b-a81a-4210-bfd6-25133aca08f0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ac0053e6-042a-446b-a78a-4d7486256806',
        firstName: 'Juliet',
        lastName: 'Burnette',
        email: 'julietlucillebusiness@gmail.com',
        instagramProfile: {
          handle: 'julietlucille_',
          followers: 1010,
          engagementRate: 2.1881188118811883
        },
        tiktokProfile: {
          handle: 'julietlucille_',
          followers: 43,
          engagementRate: 2.3359288097886544
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-02T22:44:09.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '45833cc9-0a01-440a-8377-a1b599db46ac',
      content: [],
      creator: {
        id: '99589dc1-7555-4e16-b168-4134eb367ade',
        firstName: 'montana',
        lastName: 'berrier',
        email: 'montanasb97@gmail.com',

        tiktokProfile: {
          handle: 'tana97_',
          followers: 6140,
          engagementRate: 7.241190314988555
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T00:13:46.000Z',
        amountCents: 7370
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8440a383-d604-4f9b-a87b-6a9fdf86f058',
      content: [
        {
          groupId: '97ddcb9e-7029-4241-bb14-8ab634a50931',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-16T17:24:14.613Z',

          createdAt: '2024-06-13T01:40:44.255Z',
          content: [
            {
              caption: '',

              id: '45b95412-dd65-4976-bb10-d0d13925b61e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8440a383-d604-4f9b-a87b-6a9fdf86f058/ugc/97ddcb9e-7029-4241-bb14-8ab634a50931/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ea9ebbae-a52c-4988-8722-f3fd1d038d86',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8440a383-d604-4f9b-a87b-6a9fdf86f058/ugc/97ddcb9e-7029-4241-bb14-8ab634a50931/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0afcdcd1-c383-4034-a28c-392f20b6c442',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-16T17:24:09.915Z',

          createdAt: '2024-06-13T01:41:19.931Z',
          content: [
            {
              caption: '',

              id: '565366c3-cb56-4fa0-a1d7-c367d5566a5f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8440a383-d604-4f9b-a87b-6a9fdf86f058/ugc/0afcdcd1-c383-4034-a28c-392f20b6c442/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e158f220-03e7-4f58-8c03-7cfbce4e9f01',
        firstName: 'Karla',
        lastName: 'Amaro',
        email: 'spot3k@yahoo.com',
        instagramProfile: {
          handle: 'the_kar',
          followers: 2477,
          engagementRate: 1.2178710806082627
        },
        tiktokProfile: {
          handle: 'the_karr',
          followers: 331,
          engagementRate: 5.5096418732782375
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T00:18:01.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9d160180-e5d5-4871-b11c-b6a112d2fc9b',
      content: [
        {
          groupId: 'bb1c6c4f-3808-4377-ad8a-9e9478c45aaa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:50:49.229Z',

          createdAt: '2024-06-11T15:00:06.436Z',
          content: [
            {
              caption: '',

              id: '6f3c989b-5cd7-4942-93f1-1e444cec8479',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9d160180-e5d5-4871-b11c-b6a112d2fc9b/ugc/bb1c6c4f-3808-4377-ad8a-9e9478c45aaa/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '1abcb585-c3c2-4c48-bc2a-49a1ce526f0e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-11T17:01:42.282Z',

          createdAt: '2024-06-09T13:07:43.207Z',
          content: [
            {
              caption: '',

              id: '88ce5eaa-9eb5-42c5-801b-aefa76960e8a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9d160180-e5d5-4871-b11c-b6a112d2fc9b/ugc/1abcb585-c3c2-4c48-bc2a-49a1ce526f0e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '972d4bca-97c2-438b-be2e-d3bb284c245e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9d160180-e5d5-4871-b11c-b6a112d2fc9b/ugc/1abcb585-c3c2-4c48-bc2a-49a1ce526f0e/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'aba20dc1-8bbb-444f-a1aa-32951d4542fe',
        firstName: 'Keniesha',
        lastName: 'Brown',
        email: 'kenieshabrown37@gmail.com',

        tiktokProfile: {
          handle: 'keniesha33',
          followers: 4399,
          engagementRate: 13.18181818181818
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T00:42:04.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6645dc10-55f4-4b7b-9c31-0558e3ef89b5',
      content: [],
      creator: {
        id: '9a5463e1-27ad-4fed-aa98-62ba2fff29ab',
        firstName: 'Tia',
        lastName: 'Black',
        email: 'lifewithtiablack@gmail.com',

        tiktokProfile: {
          handle: 'lifewithtiablack',
          followers: 5582,
          engagementRate: 4.246412383863634
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T01:43:11.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f59ebb25-e250-4eda-9072-7e35b51f21f6',
      content: [
        {
          groupId: '0a4f27d2-afb1-4c42-989d-97996d98ec9e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T16:47:18.856Z',

          createdAt: '2024-06-06T21:06:34.495Z',
          content: [
            {
              caption: '',

              id: 'b52bf7fb-c7b9-4088-8eb8-3d33d0545db6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f59ebb25-e250-4eda-9072-7e35b51f21f6/ugc/0a4f27d2-afb1-4c42-989d-97996d98ec9e/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c4e1f71b-6e2e-47b4-9faf-9819dc677136',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f59ebb25-e250-4eda-9072-7e35b51f21f6/ugc/0a4f27d2-afb1-4c42-989d-97996d98ec9e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '23665f4b-3380-41ec-aa43-3a0448c80a54',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T16:47:22.926Z',

          createdAt: '2024-06-06T21:23:29.815Z',
          content: [
            {
              caption: '',

              id: '4b5ab396-bf62-4e64-8e5c-df696d66e19f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f59ebb25-e250-4eda-9072-7e35b51f21f6/ugc/23665f4b-3380-41ec-aa43-3a0448c80a54/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b7a1627c-9a4d-4359-aea3-0cda4048b9b4',
        firstName: 'Jennifer',
        lastName: 'Jarvie',
        email: 'jarvie.jennifer@gmail.com',

        tiktokProfile: {
          handle: 'worldlyjen',
          followers: 22351,
          engagementRate: 10.289389067524116
        },
        youtubeProfile: {
          handle: 'clubjarvie3708',
          followers: 25
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T01:53:46.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7db5d9ae-92cc-4f63-a587-469cb4746bc5',
      content: [],
      creator: {
        id: 'a082a538-fbab-4d02-8eac-87bfb4290f0a',
        firstName: 'Stephanie',
        lastName: 'Gage',
        email: 'stephaniegage0@gmail.com',
        instagramProfile: {
          handle: 'ceostephanie33',
          followers: 772,
          engagementRate: 1.3385146804835923
        },
        tiktokProfile: {
          handle: 'engagedinfashion',
          followers: 10029,
          engagementRate: 4.291845493562231
        },
        youtubeProfile: {
          handle: 'engagedinfashion'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T01:55:05.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bbe9c08c-5405-4da5-865b-1643a0862fed',
      content: [],
      creator: {
        id: 'abcfd34b-e4aa-4c43-bc84-94598a9f8e3f',
        firstName: 'Christena',
        lastName: 'Lord',
        email: 'lordchristena96@icloud.com',

        tiktokProfile: {
          handle: 'lourdess876',
          followers: 1027
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T02:17:52.000Z',
        amountCents: 8688
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9b28075e-3a7c-416c-affc-c90bf3f56545',
      content: [],
      creator: {
        id: 'a53766f9-a6bf-47da-a7cd-4fb804424f61',
        firstName: 'Daviesha',
        lastName: 'Bell',
        email: 'davieshabell6@gmail.com',
        instagramProfile: {
          handle: 'davieshaaa',
          followers: 2020,
          engagementRate: 0.935093509350935
        },
        tiktokProfile: {
          handle: 'davieshaa',
          followers: 3491,
          engagementRate: 3.048065650644783
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T03:06:24.000Z',
        amountCents: 7698
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '039f54bc-135a-4610-b1d6-e7ac1cc5a48b',
      content: [
        {
          groupId: 'b4bd5744-eb1a-4611-94be-3d55fb8cff8c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T18:56:39.466Z',

          createdAt: '2024-06-04T16:26:48.553Z',
          content: [
            {
              caption: '',

              id: '0c4190ad-1bc1-4e73-b69c-ebd06dd93aca',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/039f54bc-135a-4610-b1d6-e7ac1cc5a48b/ugc/b4bd5744-eb1a-4611-94be-3d55fb8cff8c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bbf9ce7b-9e0d-483b-987a-063ea2300d5a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T02:24:01.343Z',

          createdAt: '2024-06-04T23:29:11.097Z',
          content: [
            {
              caption: '',

              id: 'f2832002-4591-447f-8398-1712159f408c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/039f54bc-135a-4610-b1d6-e7ac1cc5a48b/ugc/bbf9ce7b-9e0d-483b-987a-063ea2300d5a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'edcd3bcc-3313-4e8e-943b-5aa60f7b8ec5',
        firstName: 'Patience',
        lastName: 'Nero',
        email: 'patiencenero18@gmail.com',
        instagramProfile: {
          handle: 'patience.1k',
          followers: 2042,
          engagementRate: 1.248775710088149
        },
        tiktokProfile: {
          handle: 'patienceneroo',
          followers: 5909,
          engagementRate: 4.469086021505377
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T03:06:27.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '29f204ca-6d06-48d0-8d70-7bb32d10ce5f',
      content: [
        {
          groupId: 'f28142de-192c-4252-bbd0-7430631b2629',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T03:56:36.610Z',

          createdAt: '2024-06-03T16:07:06.338Z',
          content: [
            {
              caption: '',

              id: 'd772e381-fa67-49f2-a78e-8a25645c8b8b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29f204ca-6d06-48d0-8d70-7bb32d10ce5f/ugc/f28142de-192c-4252-bbd0-7430631b2629/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a334b835-e935-43a9-859a-4403f551bf40',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:31:46.854Z',

          createdAt: '2024-06-03T16:11:32.347Z',
          content: [
            {
              caption: '',

              id: '83c1e5b2-57af-48ea-a924-986f35b68c0a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29f204ca-6d06-48d0-8d70-7bb32d10ce5f/ugc/a334b835-e935-43a9-859a-4403f551bf40/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '76e1e3e8-4ac4-4a40-b895-72fd0e279cc2',
        firstName: 'Grace',
        lastName: 'Tr',
        email: 'foodieg2@gmail.com',
        instagramProfile: {
          handle: 'graceeats247',
          followers: 3166,
          engagementRate: 3.5217940619077703
        },
        tiktokProfile: {
          handle: 'graceeats.247',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T04:06:49.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bbfc0599-b88c-48fc-ace5-0800112c6d47',
      content: [
        {
          groupId: '28f5b615-5d08-4b75-85f0-390fea1ce806',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-13T15:25:31.095Z',

          createdAt: '2024-06-09T13:54:51.094Z',
          content: [
            {
              caption: '',

              id: '5e1d977e-25a3-4ec9-8220-95ec120632f5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bbfc0599-b88c-48fc-ace5-0800112c6d47/ugc/28f5b615-5d08-4b75-85f0-390fea1ce806/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6f0e091d-3949-4b48-b14e-c6fcf9e2d69e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bbfc0599-b88c-48fc-ace5-0800112c6d47/ugc/28f5b615-5d08-4b75-85f0-390fea1ce806/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '851fb2bc-11d4-4e43-9905-8c6841988cbf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-13T15:25:26.942Z',

          createdAt: '2024-06-10T02:52:51.248Z',
          content: [
            {
              caption: '',

              id: '9911acec-d77f-4902-92aa-04f7b0c65f2f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bbfc0599-b88c-48fc-ace5-0800112c6d47/ugc/851fb2bc-11d4-4e43-9905-8c6841988cbf/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'aceac183-867b-43de-a4ca-85c1d532e5e9',
        firstName: 'Amber',
        lastName: 'Rangel',
        email: 'amber.rangel1212@yahoo.com',
        instagramProfile: {
          handle: '_ambermichele',
          followers: 2257,
          engagementRate: 2.361541869738591
        },
        tiktokProfile: {
          handle: 'amber_rangel',
          followers: 5735,
          engagementRate: 2.3593177981892715
        },
        youtubeProfile: {
          handle: 'amberandgreggy',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T04:12:06.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd54bb5fd-6df1-4e9b-a8c3-699820492351',
      content: [
        {
          groupId: '02ad688f-9f07-4c6b-a5d2-35f596a085ed',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T14:15:33.475Z',

          createdAt: '2024-06-11T22:18:16.567Z',
          content: [
            {
              caption: '',

              id: '2130f483-14fc-47b9-9188-8c0a9607b3e8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d54bb5fd-6df1-4e9b-a8c3-699820492351/ugc/02ad688f-9f07-4c6b-a5d2-35f596a085ed/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '07b881ca-6bdd-4500-be0c-7c94c4b36e95',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d54bb5fd-6df1-4e9b-a8c3-699820492351/ugc/02ad688f-9f07-4c6b-a5d2-35f596a085ed/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b9e05c48-2536-42c3-9f43-1240b505b4ba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d54bb5fd-6df1-4e9b-a8c3-699820492351/ugc/02ad688f-9f07-4c6b-a5d2-35f596a085ed/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c6873ac6-db33-46b1-8ceb-45143b2f83e8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:15:25.631Z',

          createdAt: '2024-06-11T01:44:19.971Z',
          content: [
            {
              caption: '',

              id: '4d63fcc6-c768-4467-aaac-0aa90c526461',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d54bb5fd-6df1-4e9b-a8c3-699820492351/ugc/c6873ac6-db33-46b1-8ceb-45143b2f83e8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8b2483a4-c03b-41a0-8acb-a2ebcc74c08a',
        firstName: 'Blaina',
        lastName: 'Sanders',
        email: 'blainasanders19@yahoo.com',

        tiktokProfile: {
          handle: 'blainasanders',
          followers: 3619,
          engagementRate: 9.672043010752688
        },
        youtubeProfile: {
          handle: 'blainagrief835',
          followers: 4
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T05:01:58.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '86435867-86a7-4231-8fb0-a24c66dad860',
      content: [
        {
          groupId: '952df95e-7c39-4050-8fe8-3c7fb305b723',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T02:53:41.937Z',

          createdAt: '2024-06-05T22:28:48.433Z',
          content: [
            {
              caption: '',

              id: '5e725963-afdf-4c01-ae8b-465cd044ea61',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/86435867-86a7-4231-8fb0-a24c66dad860/ugc/952df95e-7c39-4050-8fe8-3c7fb305b723/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd684749d-eb55-43a0-bbf1-0cdec51c65ef',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T13:23:30.506Z',

          createdAt: '2024-06-05T22:37:36.062Z',
          content: [
            {
              caption: '',

              id: 'c64b9c58-c565-4cc8-b69a-d0fa46a2cbd9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/86435867-86a7-4231-8fb0-a24c66dad860/ugc/d684749d-eb55-43a0-bbf1-0cdec51c65ef/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '96fd3f14-edad-48c7-8b32-7bba251912f4',
        firstName: 'Annie',
        lastName: 'Michelle',
        email: 'thebananniediaries@yahoo.com',
        instagramProfile: {
          handle: 'thebananniediaries',
          followers: 6666,
          engagementRate: 4.618961896189619
        },
        tiktokProfile: {
          handle: 'bananniediaries',
          followers: 2258,
          engagementRate: 1.9465554142498218
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T05:25:21.000Z',
        amountCents: 7443
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd1a9e33b-ec34-4086-97a7-fb19d82357b3',
      content: [],
      creator: {
        id: '7fbb7e2f-5103-4496-ae60-acd760534c48',
        firstName: 'Sierra',
        lastName: 'Loggins',
        email: 'ciciloggins7@gmail.com',

        tiktokProfile: {
          handle: 'cici.smith',
          followers: 4900,
          engagementRate: 8.179419525065963
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T05:56:45.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e49ffca4-fec2-4aac-b1cb-c4d046772bdb',
      content: [
        {
          groupId: '43f230ad-07fe-48bc-8040-70ae3aac4c85',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T14:25:48.069Z',

          createdAt: '2024-06-14T16:58:50.805Z',
          content: [
            {
              caption: '',

              id: 'd5f60674-eebe-4a9c-8919-82b45038d7de',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e49ffca4-fec2-4aac-b1cb-c4d046772bdb/ugc/43f230ad-07fe-48bc-8040-70ae3aac4c85/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e1cee0ec-ee57-46ab-949a-8de23af7ef84',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T15:35:14.114Z',

          createdAt: '2024-06-14T16:53:19.887Z',
          content: [
            {
              caption: '',

              id: 'fead4524-6146-466a-9484-5bb0ab6b3e6a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e49ffca4-fec2-4aac-b1cb-c4d046772bdb/ugc/e1cee0ec-ee57-46ab-949a-8de23af7ef84/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5b2532c9-d6c6-48e6-ad7c-d7a85946643b',
        firstName: 'Taneisha',
        lastName: 'Thorpe',
        email: 'natashathorpe22@gmail.com',
        instagramProfile: {
          handle: 'yoniesencia',
          followers: 11663,
          engagementRate: 0.09774500557318015
        },
        tiktokProfile: {
          handle: 'taneiemprezz',
          followers: 5968,
          engagementRate: 1.7751479289940828
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T07:02:37.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '558b204f-c468-46dc-a47c-54a1e0ec3ec4',
      content: [
        {
          groupId: '5d8269ea-ff17-44d5-ac36-4f0c4e298240',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T15:50:50.882Z',

          createdAt: '2024-06-12T15:43:21.723Z',
          content: [
            {
              caption: '',

              id: '3f778573-d847-4bd2-848c-f36326ff68cd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/558b204f-c468-46dc-a47c-54a1e0ec3ec4/ugc/5d8269ea-ff17-44d5-ac36-4f0c4e298240/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7944db3d-e597-435e-ae66-7938d175ee6d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-14T15:50:57.420Z',

          createdAt: '2024-06-10T16:33:00.379Z',
          content: [
            {
              caption: '',

              id: '5c026971-fcb7-4631-8414-a83dcbfc26b4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/558b204f-c468-46dc-a47c-54a1e0ec3ec4/ugc/7944db3d-e597-435e-ae66-7938d175ee6d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7b3c7377-3e83-40ff-b64c-534f8b9ffee7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/558b204f-c468-46dc-a47c-54a1e0ec3ec4/ugc/7944db3d-e597-435e-ae66-7938d175ee6d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'eaa85d23-7bec-4232-a32a-0604ffddb886',
        firstName: 'Stephen',
        lastName: 'Aguirre',
        email: 'stephen.aguirre@gmail.com',
        instagramProfile: {
          handle: 'habibi_stephen',
          followers: 2650,
          engagementRate: 3.716981132075472
        },
        tiktokProfile: {
          handle: 'habibi_stephen',
          followers: 7291,
          engagementRate: 13.50902676903922
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T08:55:58.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b0077f06-303f-4097-8b1f-1b916296781c',
      content: [
        {
          groupId: '22159447-46ab-4bbe-a323-4defc8e74ae0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T14:19:00.440Z',

          createdAt: '2024-06-09T04:31:24.662Z',
          content: [
            {
              caption: '',

              id: 'bfc02625-1779-4501-9c91-be162061d0ab',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0077f06-303f-4097-8b1f-1b916296781c/ugc/22159447-46ab-4bbe-a323-4defc8e74ae0/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b31d7148-ed76-44a9-96ea-f32cb21af4a6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0077f06-303f-4097-8b1f-1b916296781c/ugc/22159447-46ab-4bbe-a323-4defc8e74ae0/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '15e5f2d3-b3c5-4c63-95c1-8a05ce112b3f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0077f06-303f-4097-8b1f-1b916296781c/ugc/22159447-46ab-4bbe-a323-4defc8e74ae0/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'aa46aecd-f46f-4b97-87e0-786d056d03aa',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T14:19:06.736Z',

          createdAt: '2024-06-09T04:30:43.277Z',
          content: [
            {
              caption: '',

              id: 'bd5d032b-93e2-4c27-a115-dd70c048f95c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b0077f06-303f-4097-8b1f-1b916296781c/ugc/aa46aecd-f46f-4b97-87e0-786d056d03aa/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'fe9ee51c-f34c-4205-b8a0-29279b44e227',
        firstName: 'Charles',
        lastName: 'Steynberg',
        email: 'charlessteynberg@gmail.com',
        instagramProfile: {
          handle: 'sirdaxton',
          followers: 1059,
          engagementRate: 1.6902738432483473
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T10:06:18.000Z',
        amountCents: 8805
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a8837dd7-5c5d-4ca8-b14d-c737bdf4805a',
      content: [],
      creator: {
        id: 'c9ce7dfd-4ad9-4ff3-ad54-8dbb090effb4',
        firstName: 'Kimberly',
        lastName: 'Dorame',
        email: 'kimberlyxx50@yahoo.com',
        instagramProfile: {
          handle: 'kimathy_',
          followers: 9705,
          engagementRate: 0.02326468344774981
        },
        tiktokProfile: {
          handle: 'doramekimm',
          followers: 31525,
          engagementRate: 0.9818731117824773
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T10:42:16.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dc7132ce-a415-4a09-a7ae-4b62b25a6cb0',
      content: [
        {
          groupId: 'f8f789ad-44ea-4501-838a-c13e662552d1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T11:08:09.481Z',

          createdAt: '2024-06-09T21:30:14.488Z',
          content: [
            {
              caption: '',

              id: '680066b9-220b-428b-873f-48848e753ca3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dc7132ce-a415-4a09-a7ae-4b62b25a6cb0/ugc/f8f789ad-44ea-4501-838a-c13e662552d1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e386b866-debf-4d50-a3cd-14c811beb9c4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-11T11:30:34.783Z',

          createdAt: '2024-06-09T21:31:56.984Z',
          content: [
            {
              caption: '',

              id: '78068d3e-c37b-4cb7-86e4-acd57b9142c4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dc7132ce-a415-4a09-a7ae-4b62b25a6cb0/ugc/e386b866-debf-4d50-a3cd-14c811beb9c4/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2c293be2-0164-4514-a080-7ccb08d06d59',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dc7132ce-a415-4a09-a7ae-4b62b25a6cb0/ugc/e386b866-debf-4d50-a3cd-14c811beb9c4/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f6d99de1-dd3b-4fc2-84ef-1bf8f0f52300',
        firstName: 'Melissa',
        lastName: 'Shackelford',
        email: 'melissa.shackelford77@gmail.com',
        instagramProfile: {
          handle: 'mshackhoneybee',
          followers: 1412,
          engagementRate: 0.6610009442870634
        },
        tiktokProfile: {
          handle: 'melissawardshacke',
          followers: 1026,
          engagementRate: 1.6260162601626018
        },
        youtubeProfile: {
          handle: 'melissashackelford7451',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T10:53:41.000Z',
        amountCents: 8237
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dfe7b914-eeb9-4548-9cac-3e3cc3060d2b',
      content: [],
      creator: {
        id: 'a6fb7c4d-6b2c-4e5b-b9f3-fa3318c4e5bf',
        firstName: 'Sachelle',
        lastName: 'Thomas',
        email: 'sachellethomas19@gmail.com',
        instagramProfile: {
          handle: 'dimepixce',
          followers: 8194
        },
        tiktokProfile: {
          handle: 'dimepixce',
          followers: 776,
          engagementRate: 7.3426573426573425
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T12:16:23.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '03e250ec-198c-491d-a471-d08cbd2aedca',
      content: [
        {
          groupId: '355a6736-5635-4317-b801-12345bae9999',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T14:06:32.927Z',

          createdAt: '2024-06-11T23:16:34.573Z',
          content: [
            {
              caption: '',

              id: '7bfd4c33-9cfa-4dcc-8b7a-8cdaed5f467a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/03e250ec-198c-491d-a471-d08cbd2aedca/ugc/355a6736-5635-4317-b801-12345bae9999/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '75a8346f-2b7a-4ca3-84ab-16f6bc268af1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/03e250ec-198c-491d-a471-d08cbd2aedca/ugc/355a6736-5635-4317-b801-12345bae9999/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e8d8ebe5-f62e-49b8-863c-d2aa46b2f6a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/03e250ec-198c-491d-a471-d08cbd2aedca/ugc/355a6736-5635-4317-b801-12345bae9999/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2e45c48b-d9d9-4329-bb8f-f230816572d8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T16:36:13.685Z',

          createdAt: '2024-06-15T17:30:26.102Z',
          content: [
            {
              caption: '',

              id: '7aa20d39-b2ad-4972-8f20-22cef93a794f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/03e250ec-198c-491d-a471-d08cbd2aedca/ugc/2e45c48b-d9d9-4329-bb8f-f230816572d8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7d975aa5-0de5-49fc-a470-d9ae3ba2d771',
        firstName: 'Shanae',
        lastName: 'Johnson',
        email: 'jshanae15@gmail.com',
        instagramProfile: {
          handle: 'shizz_nae',
          followers: 1410
        },
        tiktokProfile: {
          handle: 'shizz.nae',
          followers: 739,
          engagementRate: 7.247706422018349
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T12:24:08.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '66b5253c-ca81-47f7-9e6e-cf6b9037f1c1',
      content: [],
      creator: {
        id: '6dc6312b-ce33-49b8-b06a-46cd4fded611',
        firstName: 'Monique',
        lastName: 'Martinez',
        email: 'mgm4092@yahoo.com',
        instagramProfile: {
          handle: 'happilyeverabshire',
          followers: 1531,
          engagementRate: 5.127367733507512
        },
        tiktokProfile: {
          handle: 'happilyeverabshire',
          followers: 17
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T15:45:27.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '208a7122-4eaa-436f-812d-1755ebcb3045',
      content: [
        {
          groupId: '5391e4c7-ae9f-4c65-a0d7-f760ee9e3d6c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-17T20:41:38.382Z',

          createdAt: '2024-06-11T01:33:17.826Z',
          content: [
            {
              caption: '',

              id: '99685868-d037-43ca-b05d-e19a3bf29cea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/208a7122-4eaa-436f-812d-1755ebcb3045/ugc/5391e4c7-ae9f-4c65-a0d7-f760ee9e3d6c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1e3b9f13-248a-4ce9-ad70-d35f6219514c',
        firstName: 'Amanda',
        lastName: 'S',
        email: 'ashenker15@gmail.com',
        instagramProfile: {
          handle: 'mrsshenker',
          followers: 1254,
          engagementRate: 0.025985518905872883
        },
        tiktokProfile: {
          handle: 'ashenker',
          followers: 40
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T16:02:20.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f554ae2f-c034-4193-8d9a-921bbbaa0eec',
      content: [
        {
          groupId: '7437e6ea-91e4-4de2-91b3-19f064dd5091',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T13:55:41.826Z',

          createdAt: '2024-06-06T23:52:04.951Z',
          content: [
            {
              caption: '',

              id: '7eab0bc6-a007-42a5-b158-be399dba1972',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f554ae2f-c034-4193-8d9a-921bbbaa0eec/ugc/7437e6ea-91e4-4de2-91b3-19f064dd5091/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '886f42cc-db93-416e-8576-1bc89248e831',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f554ae2f-c034-4193-8d9a-921bbbaa0eec/ugc/7437e6ea-91e4-4de2-91b3-19f064dd5091/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c717d8c5-20c3-42c1-8b57-6cbb1b1650e6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f554ae2f-c034-4193-8d9a-921bbbaa0eec/ugc/7437e6ea-91e4-4de2-91b3-19f064dd5091/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e057472d-ff82-45a2-8613-01c2705d07bc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T14:10:06.214Z',

          createdAt: '2024-06-07T00:18:46.444Z',
          content: [
            {
              caption: '',

              id: '731cf946-9e56-4820-8c5a-a7889ee9ca93',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f554ae2f-c034-4193-8d9a-921bbbaa0eec/ugc/e057472d-ff82-45a2-8613-01c2705d07bc/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'aa665403-bfc6-4df1-be1c-bfc4afeee45e',
        firstName: 'DeAndra',
        lastName: 'Childs',
        email: 'cubandoll443@gmail.com',
        instagramProfile: {
          handle: 'indiavvswins',
          followers: 5246,
          engagementRate: 0.8558902020587114
        },
        tiktokProfile: {
          handle: 'fineassindiavvs',
          followers: 607,
          engagementRate: 0.5409215755025713
        },
        youtubeProfile: {
          handle: 'indiavvs',
          followers: 118
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T16:11:44.000Z',
        amountCents: 7716
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '54dd1b1f-7990-44f1-b33f-b2bba49083c9',
      content: [
        {
          groupId: 'e1725ab9-9365-4478-8fd3-f5dee520a8ee',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T21:39:25.504Z',

          createdAt: '2024-06-08T21:33:04.184Z',
          content: [
            {
              caption: '',

              id: '4470d575-dc75-4f4f-8a4c-c70ebcb6c414',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54dd1b1f-7990-44f1-b33f-b2bba49083c9/ugc/e1725ab9-9365-4478-8fd3-f5dee520a8ee/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3130610e-2272-4c92-822b-07a137401095',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-05T19:28:57.884Z',

          createdAt: '2024-06-05T17:03:54.954Z',
          content: [
            {
              caption: '',

              id: 'b21b58ab-5c6d-4bc1-999a-f346d958f4e0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/54dd1b1f-7990-44f1-b33f-b2bba49083c9/ugc/3130610e-2272-4c92-822b-07a137401095/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ee527232-e4b3-410e-9300-d14ce828c717',
        firstName: 'Heaven',
        lastName: 'Cole',
        email: 'heaven.cole@icloud.com',
        instagramProfile: {
          handle: 'the.kervin.family',
          followers: 336,
          engagementRate: 1.4285714285714286
        },
        tiktokProfile: {
          handle: 'k9chase2019',
          followers: 961
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T18:16:44.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c8d49cfe-a3af-450c-9bc6-b9580bb6bc7e',
      content: [
        {
          groupId: 'c748ec64-739f-4942-834e-a27258c48d02',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-21T13:21:40.630Z',

          createdAt: '2024-06-13T04:14:26.739Z',
          content: [
            {
              caption: '',

              id: '55fe57a7-68f1-4600-be89-de7fcf1a3fd1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c8d49cfe-a3af-450c-9bc6-b9580bb6bc7e/ugc/c748ec64-739f-4942-834e-a27258c48d02/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '4ea44141-aeac-4c4d-9c7b-599a6745550c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-21T13:21:46.764Z',

          createdAt: '2024-06-13T04:15:00.844Z',
          content: [
            {
              caption: '',

              id: '06ea35ae-ff16-47bd-be0f-83c35987d82c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c8d49cfe-a3af-450c-9bc6-b9580bb6bc7e/ugc/4ea44141-aeac-4c4d-9c7b-599a6745550c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '827743f5-c7fe-4eb0-b447-7e54f8a9126c',
        firstName: 'Chiamsey',
        lastName: 'L',
        email: 'chiammmleeling@gmail.com',
        instagramProfile: {
          handle: 'chiamseyr',
          followers: 1248,
          engagementRate: 1.5865384615384615
        },
        tiktokProfile: {
          handle: 'chiamsey',
          followers: 1099,
          engagementRate: 3.2501702470129388
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T18:22:33.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1dc65043-82e6-4e9e-bf7e-c11b9806643f',
      content: [
        {
          groupId: '726c28d0-eb54-44e1-a22e-5e84ae3eb51f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T12:56:46.943Z',

          createdAt: '2024-06-13T03:13:35.921Z',
          content: [
            {
              caption: '',

              id: '34af83f0-0f4a-4474-aa9e-021b7eb1ca26',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1dc65043-82e6-4e9e-bf7e-c11b9806643f/ugc/726c28d0-eb54-44e1-a22e-5e84ae3eb51f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ca647f59-f126-4031-80a7-18333de675e2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-13T03:00:45.227Z',

          createdAt: '2024-06-11T15:20:41.443Z',
          content: [
            {
              caption: '',

              id: 'ca376d01-2a72-48cb-8059-1dbe037c7fb2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1dc65043-82e6-4e9e-bf7e-c11b9806643f/ugc/ca647f59-f126-4031-80a7-18333de675e2/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'db3ad365-3516-430a-99a0-0411c899a6dd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1dc65043-82e6-4e9e-bf7e-c11b9806643f/ugc/ca647f59-f126-4031-80a7-18333de675e2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9916d8d3-6feb-4d3a-88ed-a6d5e778ddf3',
        firstName: 'Emily',
        lastName: 'Champlin',
        email: 'delanbub@gmail.com',

        tiktokProfile: {
          handle: 'lincolns.mom',
          followers: 1118
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T18:33:09.000Z',
        amountCents: 7067
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8338ffe6-56f3-4cb9-b53f-c50c26d7f5a2',
      content: [],
      creator: {
        id: '52f61493-7c96-4eed-acc8-469f46642283',
        firstName: 'Brittany',
        lastName: 'Prather',
        email: 'therelatablemomspot@gmail.com',
        instagramProfile: {
          handle: 'therelatablemomspot',
          followers: 30013,
          engagementRate: 0.5734181854529704
        },
        tiktokProfile: {
          handle: 'therelatablemomspot',
          followers: 11083,
          engagementRate: 7.075986893431112
        },
        youtubeProfile: {
          handle: 'therelatablemomspot',
          followers: 90
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-03T19:43:44.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bd192b13-1886-4acb-9b86-834228cc47cc',
      content: [
        {
          groupId: 'ab8363af-6114-4677-94cf-cd3815194e86',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-10T12:55:23.300Z',

          createdAt: '2024-06-07T18:24:21.313Z',
          content: [
            {
              caption: '',

              id: '8a6f8c48-7493-4ff2-839f-e6252742dc28',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd192b13-1886-4acb-9b86-834228cc47cc/ugc/ab8363af-6114-4677-94cf-cd3815194e86/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd440b137-3338-4375-ab14-51e91207cd18',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd192b13-1886-4acb-9b86-834228cc47cc/ugc/ab8363af-6114-4677-94cf-cd3815194e86/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '17c9b5e8-a8c7-4e11-914b-e16a63d5d621',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd192b13-1886-4acb-9b86-834228cc47cc/ugc/ab8363af-6114-4677-94cf-cd3815194e86/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c076d016-ca15-4e10-959f-6aa41aee272c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T12:36:10.076Z',

          createdAt: '2024-06-07T18:23:35.977Z',
          content: [
            {
              caption: '',

              id: '7dd2d188-b4d0-451c-8f7e-d0b7889c86db',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bd192b13-1886-4acb-9b86-834228cc47cc/ugc/c076d016-ca15-4e10-959f-6aa41aee272c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '67e23562-fb94-4326-bbe1-8bc9af4e52db',
        firstName: 'Kelsey',
        lastName: 'Kelchner',
        email: 'coupondogmom94@gmail.com',
        instagramProfile: {
          handle: 'coupondogmom94',
          followers: 1004,
          engagementRate: 0.21912350597609564
        },
        tiktokProfile: {
          handle: 'coupondogmom',
          followers: 10010,
          engagementRate: 2.915064042720042
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T04:55:48.000Z',
        amountCents: 7370
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7587ba8c-5c6a-4ab0-9106-e8c9e956714a',
      content: [],
      creator: {
        id: '44835c8f-7ed7-4d87-ab9d-5fcee584a243',
        firstName: 'Jalive',
        lastName: 'Williams',
        email: 'prattgonder@gmail.com',
        instagramProfile: {
          handle: 'badwasp_visual',
          followers: 5518,
          engagementRate: 0.11779630300833635
        },
        tiktokProfile: {
          handle: 'badwasp',
          followers: 6482,
          engagementRate: 9.272137227630969
        },
        youtubeProfile: {
          handle: 'jaljalwaspnest6211',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T07:02:43.000Z',
        amountCents: 10099
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2b2465bb-227b-4216-b073-8922d9f6c5f5',
      content: [],
      creator: {
        id: 'fa97914e-6dbc-440f-8c77-cb6310179291',
        firstName: 'Hannah',
        lastName: 'Baggott',
        email: 'baggottinstagram@gmail.com',
        instagramProfile: {
          handle: 'baggott_farmhouse',
          followers: 12058,
          engagementRate: 0.43456626306186763
        },
        tiktokProfile: {
          handle: 'baggott_farmhouse',
          followers: 315,
          engagementRate: 2.26537216828479
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T07:53:46.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5ee7d87e-9037-444c-8dfc-b21ee9a0f850',
      content: [],
      creator: {
        id: '34a3e07f-d9b6-45c4-b057-960936028de2',
        firstName: 'Jessica',
        lastName: 'Patton',
        email: 'mackems0698@gmail.com',
        instagramProfile: {
          handle: 'mackems0698',
          followers: 9598,
          engagementRate: 0.15533887773635408
        },
        tiktokProfile: {
          handle: 'mackems0698',
          followers: 63
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T09:45:12.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e536beef-da9d-48cd-a97d-aa334f6447b8',
      content: [
        {
          groupId: 'becc19b7-08d8-4758-871f-20eef0bb652e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-14T10:33:10.873Z',

          createdAt: '2024-06-14T03:33:10.283Z',
          content: [
            {
              caption: '',

              id: '582c984c-3a43-4755-b927-6391a6a67419',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e536beef-da9d-48cd-a97d-aa334f6447b8/ugc/becc19b7-08d8-4758-871f-20eef0bb652e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1f3448a4-d541-427b-8180-fd34b79d1e16',
        firstName: 'Dashanay',
        lastName: 'Mcgaw',
        email: 'dashanaymcgaw15@gmail.com',
        instagramProfile: {
          handle: 'itzz._tae',
          followers: 2323,
          engagementRate: 0.7318123116659492
        },
        tiktokProfile: {
          handle: 'braids_.bytae',
          followers: 1377,
          engagementRate: 5.873493975903615
        },
        youtubeProfile: {
          handle: 'keepingupwithherr',
          followers: 278
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T10:14:24.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '30d816d9-dfed-4d44-abe1-720ce6c3b5ca',
      content: [
        {
          groupId: '21b3b3ee-0d7f-4984-a263-c8309388f64f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-19T04:00:35.144Z',

          createdAt: '2024-06-18T00:49:02.383Z',
          content: [
            {
              caption: '',

              id: '44224c5f-526b-43ed-bb50-d535d6269611',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/30d816d9-dfed-4d44-abe1-720ce6c3b5ca/ugc/21b3b3ee-0d7f-4984-a263-c8309388f64f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a35297bc-8265-4f14-a750-5070057dd4d4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/30d816d9-dfed-4d44-abe1-720ce6c3b5ca/ugc/21b3b3ee-0d7f-4984-a263-c8309388f64f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '697d2632-0cfe-4b06-b2d9-fb01ef0e0b63',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/30d816d9-dfed-4d44-abe1-720ce6c3b5ca/ugc/21b3b3ee-0d7f-4984-a263-c8309388f64f/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'aa00c6f8-4afe-4a10-9c5e-f39bc519abb0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T04:00:40.074Z',

          createdAt: '2024-06-18T00:48:15.021Z',
          content: [
            {
              caption: '',

              id: '6a775f6f-7209-441c-8cda-869499ec36c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/30d816d9-dfed-4d44-abe1-720ce6c3b5ca/ugc/aa00c6f8-4afe-4a10-9c5e-f39bc519abb0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e34127f0-8a4f-4365-bec9-7e3bdfedfe12',
        firstName: 'Evangeline',
        lastName: 'Shepard',
        email: 'evangelineshepardactor@gmail.com',
        instagramProfile: {
          handle: 'evangelineshepardd',
          followers: 1238,
          engagementRate: 6.106623586429725
        },
        tiktokProfile: {
          handle: 'evangelineshepardactor',
          followers: 5404,
          engagementRate: 11.920529801324504
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T10:15:27.000Z',
        amountCents: 7698
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1834d3a6-1ad7-461e-b01b-415ec53aea8e',
      content: [],
      creator: {
        id: '69ebfb9a-7081-44a7-b1a3-ffb7a2d851a2',
        firstName: 'Kadeisha',
        lastName: 'Davis',
        email: 'kadz@myself.com',
        instagramProfile: {
          handle: 'reclusive49',
          followers: 3873,
          engagementRate: 0.1187709785695843
        },
        tiktokProfile: {
          handle: 'reclusive49_',
          followers: 10629,
          engagementRate: 14.814814814814813
        },
        youtubeProfile: {
          handle: 'kadeishadavis3993',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T12:21:47.000Z',
        amountCents: 7384
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e5acdab2-b662-49a8-ac56-fc80aec38500',
      content: [
        {
          groupId: 'feaadf1b-1803-4b94-bdcc-21832c82169e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-15T10:39:02.488Z',

          createdAt: '2024-06-11T01:47:47.710Z',
          content: [
            {
              caption: '',

              id: '6ff894fa-dcda-4deb-9f1d-82a6c01db0bb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5acdab2-b662-49a8-ac56-fc80aec38500/ugc/feaadf1b-1803-4b94-bdcc-21832c82169e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2d0d690b-383a-4da4-982c-3ae02fbc6958',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-15T10:39:11.714Z',

          createdAt: '2024-06-10T10:22:42.150Z',
          content: [
            {
              caption: '',

              id: 'f731da97-3084-4f3b-980d-0955346d9316',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e5acdab2-b662-49a8-ac56-fc80aec38500/ugc/2d0d690b-383a-4da4-982c-3ae02fbc6958/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ea6bcc52-44ee-47bc-a336-15a10810d0be',
        firstName: 'Beatriz',
        lastName: 'Delgado',
        email: 'beatrizdelgado310@gmail.com',
        instagramProfile: {
          handle: 'beazybees',
          followers: 2640,
          engagementRate: 1.6325757575757578
        },
        tiktokProfile: {
          handle: 'beazybees',
          followers: 1072,
          engagementRate: 6.20102911940124
        },
        youtubeProfile: {
          handle: 'beazybees'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T15:35:23.000Z',
        amountCents: 7751
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '319e806f-5a47-4cd3-8d9a-42106426852a',
      content: [
        {
          groupId: '2bde81ef-0cc3-4892-87a6-a8f63326fa93',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-11T03:15:16.578Z',

          createdAt: '2024-06-08T04:26:56.738Z',
          content: [
            {
              caption: '',

              id: 'e806f611-9021-42b6-9795-13322f71923f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/319e806f-5a47-4cd3-8d9a-42106426852a/ugc/2bde81ef-0cc3-4892-87a6-a8f63326fa93/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '418c2d05-a8fb-4b1c-a397-b3a3b527aef6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/319e806f-5a47-4cd3-8d9a-42106426852a/ugc/2bde81ef-0cc3-4892-87a6-a8f63326fa93/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '017dd7e8-c32d-4135-b75d-32871ff86b3b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-11T03:15:25.580Z',

          createdAt: '2024-06-08T04:25:22.535Z',
          content: [
            {
              caption: '',

              id: '08720ef6-145e-43d5-b484-af066bea8e8a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/319e806f-5a47-4cd3-8d9a-42106426852a/ugc/017dd7e8-c32d-4135-b75d-32871ff86b3b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'aac30e2f-49d5-4f83-8d6f-cbf9cfcfbf71',
        firstName: 'Charlotte',
        lastName: 'Williamson',
        email: 'wifey20222@gmail.com',
        instagramProfile: {
          handle: 'charlottefromnevada',
          followers: 1135,
          engagementRate: 0.14096916299559473
        },
        tiktokProfile: {
          handle: 'charlottefromnv',
          followers: 475,
          engagementRate: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T17:22:13.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '822788f7-0458-4521-bfca-1dbaabf2851f',
      content: [
        {
          groupId: 'f138e971-8b24-469d-88b2-5fc928451b96',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-21T11:20:28.217Z',

          createdAt: '2024-06-15T18:36:39.919Z',
          content: [
            {
              caption: '',

              id: 'c43c81bd-371d-4696-9ba8-5069eef91a21',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/822788f7-0458-4521-bfca-1dbaabf2851f/ugc/f138e971-8b24-469d-88b2-5fc928451b96/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '74728441-5fec-44b0-bae1-756626d0fabb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-21T11:20:35.964Z',

          createdAt: '2024-06-08T11:11:46.043Z',
          content: [
            {
              caption: '',

              id: '76b5aa23-fd29-4b02-bbb8-e8e4bd58ee67',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/822788f7-0458-4521-bfca-1dbaabf2851f/ugc/74728441-5fec-44b0-bae1-756626d0fabb/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1db8824e-e2be-4e79-a278-f2bf53215d7f',
        firstName: 'Zariana',
        lastName: 'Swaby',
        email: 'swabyzariana19@gmail.com',

        tiktokProfile: {
          handle: 'zayden.mommy',
          followers: 1059,
          engagementRate: 6.620881302104009
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T21:54:21.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0cf954fb-2844-403d-b30a-07b34dd7976b',
      content: [
        {
          groupId: '969aa708-5ec8-4a46-b3f2-ef37f439f8a2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T15:59:07.090Z',

          createdAt: '2024-06-10T04:43:20.963Z',
          content: [
            {
              caption: '',

              id: 'fe453911-b769-4bf3-a3b1-78be99de6c93',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0cf954fb-2844-403d-b30a-07b34dd7976b/ugc/969aa708-5ec8-4a46-b3f2-ef37f439f8a2/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '54af446d-88bb-44c6-9dda-6fee06b6a654',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0cf954fb-2844-403d-b30a-07b34dd7976b/ugc/969aa708-5ec8-4a46-b3f2-ef37f439f8a2/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c1c7ad60-66a2-4a96-ad26-abca3ab249c7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0cf954fb-2844-403d-b30a-07b34dd7976b/ugc/969aa708-5ec8-4a46-b3f2-ef37f439f8a2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3ae82f86-ce69-4cba-9ca6-9a87892ba51e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T15:59:15.829Z',

          createdAt: '2024-06-10T04:38:43.327Z',
          content: [
            {
              caption: '',

              id: '823aa19d-b2eb-482d-8215-66a012c616b0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0cf954fb-2844-403d-b30a-07b34dd7976b/ugc/3ae82f86-ce69-4cba-9ca6-9a87892ba51e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2dc979a3-de65-4892-bb7c-0d4916a01a79',
        firstName: 'Alyssa',
        lastName: 'Heinrich',
        email: 'alyssasheinrich@gmail.com',
        instagramProfile: {
          handle: 'lifestylemystic',
          followers: 9351,
          engagementRate: 0.47481552775104263
        },
        tiktokProfile: {
          handle: 'lifestylemystic',
          followers: 831,
          engagementRate: 6.183536530569452
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-04T22:03:15.000Z',
        amountCents: 7608
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '128a3598-bfd8-4114-ba63-5e39d4e7e892',
      content: [],
      creator: {
        id: 'f5e8b2db-c610-4167-b5e0-20ccbcba9041',
        firstName: 'Xiohn',
        lastName: 'Medina',
        email: 'laylamedina.lm@gmail.com',
        instagramProfile: {
          handle: 'laylamedina_',
          followers: 2736,
          engagementRate: 0.2506265664160401
        },
        tiktokProfile: {
          handle: 'chocoaventurasdemax',
          followers: 49
        },
        youtubeProfile: {
          handle: 'laylamedina6065',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-05T03:18:50.000Z',
        amountCents: 3866
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6b7e76e1-8448-4784-9d0f-701ffd109252',
      content: [],
      creator: {
        id: '7cc93540-aec8-4ddb-82b2-ca5355a5480a',
        firstName: 'Lanie',
        lastName: 'Prodonovich',
        email: 'lanieannprodonovich@gmail.com',
        instagramProfile: {
          handle: 'laniex00',
          followers: 1620
        },
        tiktokProfile: {
          handle: 'laniex00',
          followers: 2939,
          engagementRate: 3.691275167785235
        },
        youtubeProfile: {
          handle: 'lanieprodonovich4391'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-05T03:35:28.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e8308c4f-b0dc-4fcc-ab87-f73a7bd14f5d',
      content: [],
      creator: {
        id: '83d7a2cc-f91c-4b2c-8a2c-08b23561f442',
        firstName: 'Angel',
        lastName: 'Williams',
        email: 'angel808williams@gmail.com',
        instagramProfile: {
          handle: 'lightskin_angel1',
          followers: 1019,
          engagementRate: 13.187954309449637
        },
        tiktokProfile: {
          handle: 'lightskin_angel1',
          followers: 3131,
          engagementRate: 14.984376773351492
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-05T05:25:13.000Z',
        amountCents: 7443
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '804c4dd2-b9ae-4008-9396-5efe1989083c',
      content: [],
      creator: {
        id: 'b8b6ee6b-e7bf-4df0-9d82-5f99f80424e8',
        firstName: 'Rebecca',
        lastName: 'Griffin',
        email: 'mybuggingdademail@gmail.com',

        tiktokProfile: {
          handle: 'rebeccaamyerss',
          followers: 25386,
          engagementRate: 12.851949615633973
        },
        youtubeProfile: {
          handle: 'beccaamarie'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-05T16:09:15.000Z',
        amountCents: 19062
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '50726253-68b6-4acb-9e43-a782bf2735a1',
      content: [
        {
          groupId: '9e564471-4c12-43d5-ae93-40df16c6cf4c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-12T15:05:43.182Z',

          createdAt: '2024-06-11T02:39:44.010Z',
          content: [
            {
              caption: '',

              id: 'f78deb4d-083a-4984-9037-7eae91e2d225',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50726253-68b6-4acb-9e43-a782bf2735a1/ugc/9e564471-4c12-43d5-ae93-40df16c6cf4c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'df0440ed-54ca-4c1d-8b78-bd8b26066c23',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-12T15:05:47.337Z',

          createdAt: '2024-06-11T02:40:09.096Z',
          content: [
            {
              caption: '',

              id: 'd61f7c19-6984-4481-a66a-6fe44682d802',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50726253-68b6-4acb-9e43-a782bf2735a1/ugc/df0440ed-54ca-4c1d-8b78-bd8b26066c23/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5dc809d3-a4f3-46dc-b5a5-40131501dc94',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50726253-68b6-4acb-9e43-a782bf2735a1/ugc/df0440ed-54ca-4c1d-8b78-bd8b26066c23/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '21780f4c-067c-456c-ba00-767d0637ae01',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/50726253-68b6-4acb-9e43-a782bf2735a1/ugc/df0440ed-54ca-4c1d-8b78-bd8b26066c23/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6e8b8594-a9b8-4957-b03c-75ad6cc7665d',
        firstName: 'Laurie',
        lastName: 'Mitchell',
        email: 'info.itslaurie.v@gmail.com',
        instagramProfile: {
          handle: 'itslaurie.v',
          followers: 3260,
          engagementRate: 1.822085889570552
        },
        tiktokProfile: {
          handle: 'itslaurie.v',
          followers: 319,
          engagementRate: 2.7027027027027026
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-05T17:24:17.000Z',
        amountCents: 17059
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5919ede9-9f6f-46a2-ad35-1235f8348265',
      content: [],
      creator: {
        id: '7e8c9367-fd89-4dd8-9143-09b7ac404dad',
        firstName: 'Danea',
        lastName: 'Duncan',
        email: 'danea_hall@yahoo.com',
        instagramProfile: {
          handle: 'halldanea',
          followers: 366
        },
        tiktokProfile: {
          handle: 'danea_hall',
          followers: 6708,
          engagementRate: 3.153153153153153
        },
        youtubeProfile: {
          handle: 'daneahall360',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-06T02:26:30.000Z',
        amountCents: 10199
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '66f75923-95c0-4bb1-8cde-747735f9e885',
      content: [
        {
          groupId: '4f773b9f-d315-4268-9507-6c0d5520c17e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T17:06:17.734Z',

          createdAt: '2024-06-24T21:29:03.178Z',
          content: [
            {
              caption: '',

              id: '78da691f-1773-4dfe-b2b8-2e8ae508faa6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/66f75923-95c0-4bb1-8cde-747735f9e885/ugc/4f773b9f-d315-4268-9507-6c0d5520c17e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '81c378f7-7f2d-4d24-a4c5-a18ed7b98788',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T17:06:24.887Z',

          createdAt: '2024-06-24T21:30:22.468Z',
          content: [
            {
              caption: '',

              id: 'fb1c1f00-410e-492e-97ff-6c05addbe0df',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/66f75923-95c0-4bb1-8cde-747735f9e885/ugc/81c378f7-7f2d-4d24-a4c5-a18ed7b98788/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '39f4bcfd-9c07-4753-a937-c2cb0950e829',
        firstName: 'Deniel',
        lastName: 'Grant',
        email: 'deniel18grant@gmail.com',
        instagramProfile: {
          handle: 'face_time_bydiddi',
          followers: 195,
          engagementRate: 9.743589743589745
        },
        tiktokProfile: {
          handle: 'diddidolly',
          followers: 1968,
          engagementRate: 15.451540779624768
        },
        youtubeProfile: {
          handle: 'denielgrant',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-06T10:34:13.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd449683a-d6ca-4944-bcb3-b5c60b8b16c2',
      content: [
        {
          groupId: '947b5b69-c937-48c5-9e7b-6a40575ff755',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-18T21:41:55.069Z',

          createdAt: '2024-06-15T02:17:09.873Z',
          content: [
            {
              caption: '',

              id: '6d1a78b2-d4f7-4859-8627-441efa20aafb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d449683a-d6ca-4944-bcb3-b5c60b8b16c2/ugc/947b5b69-c937-48c5-9e7b-6a40575ff755/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '61ce4afe-f40a-47e0-a391-7cca8f415403',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d449683a-d6ca-4944-bcb3-b5c60b8b16c2/ugc/947b5b69-c937-48c5-9e7b-6a40575ff755/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '4d602435-1e02-46dd-90e6-652c0bc80b1b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T21:41:48.062Z',

          createdAt: '2024-06-15T02:18:01.160Z',
          content: [
            {
              caption: '',

              id: 'bd2a8fbb-9927-47ac-a3c3-aa779dcebe79',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d449683a-d6ca-4944-bcb3-b5c60b8b16c2/ugc/4d602435-1e02-46dd-90e6-652c0bc80b1b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '08751f26-3a24-4a3e-8af1-61e6bc16a416',
        firstName: 'Zoe',
        lastName: 'Bodziak',
        email: 'zoekindig@gmail.com',
        instagramProfile: {
          handle: 'zoe.bodziak',
          followers: 1871,
          engagementRate: 1.042223409941208
        },
        tiktokProfile: {
          handle: 'zoebodziak',
          followers: 571,
          engagementRate: 3.3232628398791544
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-07T12:44:29.000Z',
        amountCents: 10599
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2bb6144d-0d8b-4e04-b1bb-a14de259e481',
      content: [
        {
          groupId: 'bb570c3b-7064-40c4-8d45-c165885662d0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-23T08:12:10.939Z',

          createdAt: '2024-06-19T20:29:35.213Z',
          content: [
            {
              caption: '',

              id: '24a0197e-d252-4025-8c68-7d6a08950cd7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bb6144d-0d8b-4e04-b1bb-a14de259e481/ugc/bb570c3b-7064-40c4-8d45-c165885662d0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'af358915-c30f-42c8-99f4-2277a309a6d2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-23T08:12:18.983Z',

          createdAt: '2024-06-19T20:30:06.639Z',
          content: [
            {
              caption: '',

              id: 'eab5489f-0222-43f2-8bb0-dfcf12a3e1bf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2bb6144d-0d8b-4e04-b1bb-a14de259e481/ugc/af358915-c30f-42c8-99f4-2277a309a6d2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'eda73699-6eca-4180-b639-e337bcec9151',
        firstName: 'Eddie',
        lastName: 'Witt',
        email: 'ewittjr@gmail.com',
        instagramProfile: {
          handle: 'reallifemovie',
          followers: 14024,
          engagementRate: 11.306332002281803
        },
        tiktokProfile: {
          handle: 'reallifemovie',
          followers: 13
        },
        youtubeProfile: {
          handle: 'reallifemovie',
          followers: 258
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-07T18:06:40.000Z',
        amountCents: 11024
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '15a2e634-e8b3-420e-a0a6-db1d95d91b4e',
      content: [
        {
          groupId: 'a2162fa2-009f-4694-958c-c4eded4b4e8c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T16:36:31.818Z',

          createdAt: '2024-06-15T17:42:52.916Z',
          content: [
            {
              caption: '',

              id: '8dc7e3e7-b652-4fa6-8143-fdb95748b35d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/15a2e634-e8b3-420e-a0a6-db1d95d91b4e/ugc/a2162fa2-009f-4694-958c-c4eded4b4e8c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '37a17127-e920-40a3-a2a8-15db93373501',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-18T16:36:25.163Z',

          createdAt: '2024-06-15T17:36:34.734Z',
          content: [
            {
              caption: '',

              id: '08ed72d8-a1df-4349-9043-16725a99b36e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/15a2e634-e8b3-420e-a0a6-db1d95d91b4e/ugc/37a17127-e920-40a3-a2a8-15db93373501/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '7978813d-c23b-43bb-82ec-8942339095bb',
        firstName: 'Joy',
        lastName: 'Bishay',
        email: 'joybishay6@gmail.com',
        instagramProfile: {
          handle: 'joy_bishay',
          followers: 470
        },
        tiktokProfile: {
          handle: 'joyb4376',
          followers: 1608,
          engagementRate: 18.503937007874015
        },
        youtubeProfile: {
          handle: 'joybishay123',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-07T19:13:01.000Z',
        amountCents: 10295
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '041e6445-b2d6-440c-bc4c-cd73b543905e',
      content: [],
      creator: {
        id: 'f11fccce-6b89-41ef-bc81-7a36d08f8b28',
        firstName: 'Gray',
        lastName: 'Lang',
        email: 'graylangemo@yahoo.com',
        instagramProfile: {
          handle: 'graylangemo',
          followers: 992,
          engagementRate: 11.467444120505345
        },
        tiktokProfile: {
          handle: 'graylangemo',
          followers: 103
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-08T02:14:03.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '81fb3c6f-f0e3-44fd-a586-4734b92f1058',
      content: [],
      creator: {
        id: '5b7bce41-82a8-455d-acb7-d09abda00c9a',
        firstName: 'Ahliil',
        lastName: 'Saitanan',
        email: 'liilygyal@gmail.com',
        instagramProfile: {
          handle: 'liilygyal',
          followers: 34893,
          engagementRate: 0.12094116298397961
        },
        tiktokProfile: {
          handle: 'liilygyal',
          followers: 34
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-08T11:52:08.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8aba453e-5b41-4a76-b24d-3f2e14d404f7',
      content: [],
      creator: {
        id: '6b22e7c3-9ed7-4db6-b8d7-099c6d6ef3e0',
        firstName: 'Jodiann',
        lastName: 'Dubidad CSC-SS2',
        email: 'dubisent@gmail.com',

        tiktokProfile: {
          handle: 'darkskin_dubi',
          followers: 1644,
          engagementRate: 3.627932351336607
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-09T07:54:07.000Z',
        amountCents: 10699
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '372636e8-5cb2-4352-80eb-f1398ebf8eef',
      content: [],
      creator: {
        id: '0f9536f6-0aa1-44b9-b575-6bdb2f6f8364',
        firstName: 'Samantha',
        lastName: 'Blake',
        email: 'samanthablake100@gmail.com',

        tiktokProfile: {
          handle: 'sam_bamm17',
          followers: 52
        },
        youtubeProfile: {
          handle: 'marchm4083',
          followers: 5190
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-09T08:54:04.000Z',
        amountCents: 9090
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8a161e2e-5693-4f9e-835d-2b8877a4b53a',
      content: [
        {
          groupId: '626e285f-328f-44b4-b34a-53a493a43631',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-26T14:35:34.027Z',

          createdAt: '2024-06-25T14:03:40.210Z',
          content: [
            {
              caption: '',

              id: '98d7e866-ea50-44bd-8953-bb529d39631b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8a161e2e-5693-4f9e-835d-2b8877a4b53a/ugc/626e285f-328f-44b4-b34a-53a493a43631/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '60e5723e-3cee-4875-ac61-966c577d1fb9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8a161e2e-5693-4f9e-835d-2b8877a4b53a/ugc/626e285f-328f-44b4-b34a-53a493a43631/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a1379da5-6e32-4fd5-83d9-94475d0de5d2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8a161e2e-5693-4f9e-835d-2b8877a4b53a/ugc/626e285f-328f-44b4-b34a-53a493a43631/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b8d0b561-5ae7-4490-a17e-0e515337d6df',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T14:21:58.332Z',

          createdAt: '2024-06-25T20:10:16.038Z',
          content: [
            {
              caption: '',

              id: '9a5a3890-8111-4909-9114-f28777bf38a2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8a161e2e-5693-4f9e-835d-2b8877a4b53a/ugc/b8d0b561-5ae7-4490-a17e-0e515337d6df/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '06b6d896-b231-4738-a02e-0297f6896c79',
        firstName: 'Mo',
        lastName: 'Abou',
        email: 'appleyoutv@gmail.com',
        instagramProfile: {
          handle: 'igaconstruction',
          followers: 72,
          engagementRate: 0.7142857142857143
        },
        tiktokProfile: {
          handle: 'dragon.fruit.king6',
          followers: 0,
          engagementRate: 2.898550724637681
        },
        youtubeProfile: {
          handle: 'annaseuf715',
          followers: 3
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-12T17:36:30.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f3e52e9d-e879-4837-8a66-c4f64ee8be72',
      content: [],
      creator: {
        id: 'a87a3a3b-6bc3-4e9e-9e29-fa629f36bf81',
        firstName: 'Belen',
        lastName: 'Mozo',
        email: 'belensita90@gmail.com',
        instagramProfile: {
          handle: 'belenmozo',
          followers: 97558,
          engagementRate: 0.7486828348264623
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T05:14:28.000Z',
        amountCents: 10271
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '87a19399-dab2-4013-9913-cf1c75b37f72',
      content: [
        {
          groupId: '3eb68c92-5251-450b-ae84-64d4a279c085',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-21T14:46:12.040Z',

          createdAt: '2024-06-21T04:00:37.318Z',
          content: [
            {
              caption: '',

              id: '7a89418a-5a29-4bd6-914f-bc2048740648',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/87a19399-dab2-4013-9913-cf1c75b37f72/ugc/3eb68c92-5251-450b-ae84-64d4a279c085/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4fdf7e20-478b-4415-8198-7b9474e6c970',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/87a19399-dab2-4013-9913-cf1c75b37f72/ugc/3eb68c92-5251-450b-ae84-64d4a279c085/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '28809e86-4ef6-42ae-9f0e-a806cd2947fb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-19T16:05:04.086Z',

          createdAt: '2024-06-21T07:55:28.337Z',
          content: [
            {
              caption: '',

              id: '1e0e2490-ac73-4a83-82f7-7c744da989de',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/87a19399-dab2-4013-9913-cf1c75b37f72/ugc/28809e86-4ef6-42ae-9f0e-a806cd2947fb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '58d0f1d6-3818-478a-8ff3-786aed42d5e4',
        firstName: 'Aysim',
        lastName: 'Zaman',
        email: 'dilamira.com@gmail.com',
        instagramProfile: {
          handle: 'dilamiraco',
          followers: 58990,
          engagementRate: 4.281234107475843
        },
        tiktokProfile: {
          handle: 'dilamiraco',
          followers: 1297,
          engagementRate: 1.7811704834605597
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T16:22:50.000Z',
        amountCents: 10165
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f883fae7-5876-4c00-bd7c-5354013a43ab',
      content: [
        {
          groupId: '51430165-1622-408e-a6c0-75cd599178a3',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T16:40:07.820Z',

          createdAt: '2024-06-19T01:51:35.051Z',
          content: [
            {
              caption: '',

              id: '06006ddf-1a30-4742-be41-9bb6f14e25f8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f883fae7-5876-4c00-bd7c-5354013a43ab/ugc/51430165-1622-408e-a6c0-75cd599178a3/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '249c9d19-1329-45f7-915b-50f4d59ead47',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-19T16:40:02.851Z',

          createdAt: '2024-06-19T03:19:45.318Z',
          content: [
            {
              caption: '',

              id: '310dc5fb-b1e9-466b-8acb-e69ce1e1d3b7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f883fae7-5876-4c00-bd7c-5354013a43ab/ugc/249c9d19-1329-45f7-915b-50f4d59ead47/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ee717a2e-e6b5-4168-bdd7-64d75b48c007',
        firstName: 'Priscilla',
        lastName: 'P',
        email: 'sincerelypriscillaxo@gmail.com',

        tiktokProfile: {
          handle: 'sincerely.priscilla',
          followers: 7809,
          engagementRate: 15.35552193645991
        },
        youtubeProfile: {
          handle: 'sincerely.priscilla',
          followers: 41
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T17:15:58.000Z',
        amountCents: 10451
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a26386f4-b9fd-45d0-9fcf-f2b388a60cd4',
      content: [
        {
          groupId: '4c345d49-3e92-4696-8881-0bdb6c86b61e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T12:17:35.121Z',

          createdAt: '2024-07-05T16:03:25.270Z',
          content: [
            {
              caption: '',

              id: '0c23bde9-99db-4f19-b19d-cacb9ae0709b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a26386f4-b9fd-45d0-9fcf-f2b388a60cd4/ugc/4c345d49-3e92-4696-8881-0bdb6c86b61e/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '412df059-29ef-49de-912b-15018282e9a2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-10T12:15:16.549Z',

          createdAt: '2024-07-08T12:36:52.548Z',
          content: [
            {
              caption: '',

              id: '4f9c3b1e-2188-4b5f-aa25-d507262e911a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a26386f4-b9fd-45d0-9fcf-f2b388a60cd4/ugc/412df059-29ef-49de-912b-15018282e9a2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '783430fb-b39b-48ec-a2a9-202b1a898bd8',
        firstName: 'Dani Marie',
        lastName: 'Trueba',
        email: 'truebadanielle@yahoo.com',
        instagramProfile: {
          handle: 'daniee.marieee',
          followers: 12333,
          engagementRate: 2.972512770615422
        },
        tiktokProfile: {
          handle: 'danielandpdx',
          followers: 281,
          engagementRate: 2.235294117647059
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T17:23:35.000Z',
        amountCents: 9999
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cbdcf3f4-03b8-47ce-b206-ae825fe231a7',
      content: [
        {
          groupId: 'dced98a5-207e-4437-83c7-9dc55517a524',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-25T15:47:56.200Z',

          createdAt: '2024-06-22T01:28:08.829Z',
          content: [
            {
              caption: '',

              id: '19cdaec9-22c0-4c39-a424-a9d2588b3184',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cbdcf3f4-03b8-47ce-b206-ae825fe231a7/ugc/dced98a5-207e-4437-83c7-9dc55517a524/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b5510248-a7b9-4cf7-8886-c9ef7aa1fa26',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cbdcf3f4-03b8-47ce-b206-ae825fe231a7/ugc/dced98a5-207e-4437-83c7-9dc55517a524/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '493cfd08-a7e5-417d-9eb7-cb1f32042481',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T15:47:50.538Z',

          createdAt: '2024-06-22T00:56:15.334Z',
          content: [
            {
              caption: '',

              id: '5dc0f800-1982-4a90-ab93-fa7cc5ff5733',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/cbdcf3f4-03b8-47ce-b206-ae825fe231a7/ugc/493cfd08-a7e5-417d-9eb7-cb1f32042481/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b5652325-5d4f-4c28-9368-e0c3e3db71b3',
        firstName: 'Roonide',
        lastName: 'hopkins',
        email: 'charlesroonide@gmail.com',
        instagramProfile: {
          handle: 'itsbluex3',
          followers: 103802,
          engagementRate: 0.20548737018554558
        },
        tiktokProfile: {
          handle: 'roonidehopkins',
          followers: 99689,
          engagementRate: 5.750555991206983
        },
        youtubeProfile: {
          handle: 'jordanandblue',
          followers: 47400
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T17:35:16.000Z',
        amountCents: 15846
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '91f5057f-5fb4-4862-b345-5c409a822bf3',
      content: [
        {
          groupId: 'ebad235d-cbfa-4fa3-83d5-c08d1d0889c6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T21:06:23.770Z',

          createdAt: '2024-06-25T03:50:20.810Z',
          content: [
            {
              caption: '',

              id: '4ad34b70-d71d-4268-8d99-8f90017f0dd6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/91f5057f-5fb4-4862-b345-5c409a822bf3/ugc/ebad235d-cbfa-4fa3-83d5-c08d1d0889c6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2cab4e36-be5d-43f6-bd9b-0e4bc96ea577',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T21:06:28.787Z',

          createdAt: '2024-06-25T03:49:23.146Z',
          content: [
            {
              caption: '',

              id: '5a59e8a7-f4ff-44ab-8687-85b370e332d9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/91f5057f-5fb4-4862-b345-5c409a822bf3/ugc/2cab4e36-be5d-43f6-bd9b-0e4bc96ea577/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0014a4a6-7d2a-4d35-b7a6-52d712d028cf',
        firstName: 'Ashley',
        lastName: 'Morris',
        email: 'ashleyandalex@wearethewanderlusters.com',
        instagramProfile: {
          handle: 'we.are.the.wanderlusters',
          followers: 112688,
          engagementRate: 0.6361813147806332
        },
        tiktokProfile: {
          handle: 'thewanderlusters',
          followers: 109136,
          engagementRate: 4.388133498145859
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T18:11:50.000Z',
        amountCents: 10734
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eb82a7f0-4df7-47c4-aa63-621298465934',
      content: [],
      creator: {
        id: 'e6d6d19d-9775-4185-847f-b845b225ea13',
        firstName: 'Chelsie',
        lastName: 'Stumph',
        email: 'sincerelychelsie@outlook.com',
        instagramProfile: {
          handle: 'stumph.chelsie',
          followers: 11330,
          engagementRate: 0.8640776699029127
        },
        tiktokProfile: {
          handle: 'stumph.chelsie',
          followers: 2204,
          engagementRate: 6.866869378511689
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-14T19:48:00.000Z',
        amountCents: 7601
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'affe2515-0cb6-4afc-a5f1-d45895f68aa3',
      content: [],
      creator: {
        id: 'a455f56e-641d-43cb-acca-bc7a522139d2',
        firstName: 'Alliyah',
        lastName: 'Nembhard',
        email: 'aliyahnembhard635@gmail.com',
        instagramProfile: {
          handle: 'berii_goddess',
          followers: 1142
        },
        tiktokProfile: {
          handle: 'berrie.b',
          followers: 1241,
          engagementRate: 13.930348258706468
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T14:12:16.000Z',
        amountCents: 10271
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '33fc2e3d-52e5-4be4-857b-b1999e1a0978',
      content: [
        {
          groupId: 'dc485fef-c6e4-49d7-ae9e-a8fa31076032',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T16:33:31.051Z',

          createdAt: '2024-06-26T03:25:13.342Z',
          content: [
            {
              caption: '',

              id: '8469c0eb-e139-4844-929c-1222a5e1a898',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/33fc2e3d-52e5-4be4-857b-b1999e1a0978/ugc/dc485fef-c6e4-49d7-ae9e-a8fa31076032/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '55e2d1bb-6eaf-4408-9408-ce1bacc04e86',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T16:33:25.776Z',

          createdAt: '2024-06-28T15:13:23.728Z',
          content: [
            {
              caption: '',

              id: '7f63bee0-f459-4984-a35f-afde9554bab3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/33fc2e3d-52e5-4be4-857b-b1999e1a0978/ugc/55e2d1bb-6eaf-4408-9408-ce1bacc04e86/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0016f071-b871-4df3-8ca1-19caffe104a4',
        firstName: 'Rachel',
        lastName: 'Henthorn',
        email: 'rachel_henthorn@yahoo.com',
        instagramProfile: {
          handle: 'rachelhenthorn_influencer',
          followers: 31,
          engagementRate: 5.64516129032258
        },
        tiktokProfile: {
          handle: 'rachelhenthorn',
          followers: 3985,
          engagementRate: 1.3410729771790395
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-15T21:52:50.000Z',
        amountCents: 10175
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '27afe0af-a688-4e93-a446-a9b703104caa',
      content: [
        {
          groupId: 'fba87027-cafb-48b2-958c-2195882c528f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-27T01:19:00.321Z',

          createdAt: '2024-06-24T04:03:58.291Z',
          content: [
            {
              caption: '',

              id: '69c3a300-348f-4ced-b0dc-93f66eaad8aa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/27afe0af-a688-4e93-a446-a9b703104caa/ugc/fba87027-cafb-48b2-958c-2195882c528f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7c01f914-2878-4707-aadf-6dd84130b46f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-27T01:18:56.128Z',

          createdAt: '2024-06-24T04:02:55.689Z',
          content: [
            {
              caption: '',

              id: '97ec1d61-bbb5-48cb-8472-4509fa43fb8e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/27afe0af-a688-4e93-a446-a9b703104caa/ugc/7c01f914-2878-4707-aadf-6dd84130b46f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '879330d2-2b89-43d6-9ea6-e832c2329009',
        firstName: 'Purvasha',
        lastName: 'Sahoo',
        email: 'purvasha1992@gmail.com',
        instagramProfile: {
          handle: 'foodfashionandchill',
          followers: 24389,
          engagementRate: 2.1222682356800195
        },
        tiktokProfile: {
          handle: 'foodfashionandchill',
          followers: 111,
          engagementRate: 3.8675101534365917
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-16T11:54:11.000Z',
        amountCents: 10391
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1198b376-db1d-489d-bb0b-e8ab6a2ce4f7',
      content: [],
      creator: {
        id: 'dbe999e8-c88e-4d04-b47e-40e24a6a707c',
        firstName: 'Tiaria',
        lastName: 'Mendez',
        email: 'mendeztiara1030@gmail.com',

        tiktokProfile: {
          handle: '_teehara',
          followers: 1044,
          engagementRate: 20.557491289198605
        },
        youtubeProfile: {
          handle: 'tiaramendez9530',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-17T08:46:29.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4f477a1d-11d1-4445-a372-ebc2cad507b5',
      content: [],
      creator: {
        id: '8291d335-23bd-4b5e-865d-b41d93a72c27',
        firstName: 'Shavel',
        lastName: 'Marshalleck',
        email: 'shavel_b@yahoo.com',
        instagramProfile: {
          handle: 'this.shorty',
          followers: 10544
        },
        tiktokProfile: {
          handle: 'ms.marshalleck',
          followers: 4429,
          engagementRate: 19.489559164733176
        },
        youtubeProfile: {
          handle: 'thisshorty-',
          followers: 32
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-18T18:11:36.000Z',
        amountCents: 10920
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd17ae321-15be-46ae-bbac-34e49d3e6720',
      content: [
        {
          groupId: '2a6f2e84-27e0-4ef2-9869-f48ba72f8842',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T03:04:45.165Z',

          createdAt: '2024-07-02T19:35:18.765Z',
          content: [
            {
              caption: '',

              id: '83ac3cf3-8f0d-431d-b18d-f6142f0954c3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d17ae321-15be-46ae-bbac-34e49d3e6720/ugc/2a6f2e84-27e0-4ef2-9869-f48ba72f8842/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '45284e3e-0246-4236-aef6-738a982bd70c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T03:04:50.754Z',

          createdAt: '2024-06-23T15:41:02.821Z',
          content: [
            {
              caption: '',

              id: '0379108e-2015-49c3-b07e-598d5ecdedbf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d17ae321-15be-46ae-bbac-34e49d3e6720/ugc/45284e3e-0246-4236-aef6-738a982bd70c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd10cede4-eeb0-49b4-a576-a4b95076cdc9',
        firstName: 'Crystal',
        lastName: 'Rosario',
        email: 'hellocrys.socials929@gmail.com',
        instagramProfile: {
          handle: '4feetcrys_',
          followers: 1337,
          engagementRate: 4.225878833208676
        },
        tiktokProfile: {
          handle: '4feetcrys',
          followers: 387,
          engagementRate: 9.826589595375722
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T10:36:21.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '49ce4b15-4f4f-40d0-811f-c7a70199b74f',
      content: [
        {
          groupId: '81cd7d09-ba09-47fd-ab8b-9ab8a84ced4a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-06T02:25:55.195Z',

          createdAt: '2024-07-03T01:21:00.961Z',
          content: [
            {
              caption: '',

              id: '40edcb49-9607-4308-bd4d-473c2f145b26',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/49ce4b15-4f4f-40d0-811f-c7a70199b74f/ugc/81cd7d09-ba09-47fd-ab8b-9ab8a84ced4a/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '41c8c66f-33bb-4af7-ae89-c0bfbd84ca36',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/49ce4b15-4f4f-40d0-811f-c7a70199b74f/ugc/81cd7d09-ba09-47fd-ab8b-9ab8a84ced4a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f75da05f-6890-4733-8b76-edc239c73045',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/49ce4b15-4f4f-40d0-811f-c7a70199b74f/ugc/81cd7d09-ba09-47fd-ab8b-9ab8a84ced4a/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f4cd0ba8-d55c-4398-86e4-fe19af2dbfd7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-06T02:25:48.629Z',

          createdAt: '2024-07-03T01:21:57.909Z',
          content: [
            {
              caption: '',

              id: 'a6dd2568-77db-4d68-a5cd-c23eb1b2b167',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/49ce4b15-4f4f-40d0-811f-c7a70199b74f/ugc/f4cd0ba8-d55c-4398-86e4-fe19af2dbfd7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '92bd1476-71c0-4aad-a801-fc7dae27dc32',
        firstName: 'Diana',
        lastName: 'Carrera',
        email: 'dianacarocarrera@yahoo.com',
        instagramProfile: {
          handle: 'dianacarocarrera_',
          followers: 11306,
          engagementRate: 2.5402441181673447
        },
        tiktokProfile: {
          handle: 'dianacarocarrera_',
          followers: 1442,
          engagementRate: 3.892708149715024
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T13:42:13.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f3d4e32b-cd56-4cbe-8313-f70a8a0c99f6',
      content: [
        {
          groupId: '8fb9bcb1-aad9-41b8-9901-0c181af0c598',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-03T11:24:23.055Z',

          createdAt: '2024-07-01T14:51:13.165Z',
          content: [
            {
              caption: '',

              id: '0a433304-3a00-4622-96c3-14c1a521cc95',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f3d4e32b-cd56-4cbe-8313-f70a8a0c99f6/ugc/8fb9bcb1-aad9-41b8-9901-0c181af0c598/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f09442f8-d7ef-431b-a091-a4b0e8ca986e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f3d4e32b-cd56-4cbe-8313-f70a8a0c99f6/ugc/8fb9bcb1-aad9-41b8-9901-0c181af0c598/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e9833c0a-4228-46e1-969d-c9006e76fd03',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f3d4e32b-cd56-4cbe-8313-f70a8a0c99f6/ugc/8fb9bcb1-aad9-41b8-9901-0c181af0c598/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3dc070ce-c324-47c8-bbbe-894d5c1ae2b6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-10T15:33:58.172Z',

          createdAt: '2024-07-09T15:25:19.782Z',
          content: [
            {
              caption: '',

              id: 'a8e0e27c-3432-4203-ac6f-8e8de4a26cb9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f3d4e32b-cd56-4cbe-8313-f70a8a0c99f6/ugc/3dc070ce-c324-47c8-bbbe-894d5c1ae2b6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '47d2df8e-c8cb-4cbc-8c2d-49326e7d6cc1',
        firstName: 'Kelsey',
        lastName: 'Turner',
        email: 'kayturnerco@gmail.com',
        instagramProfile: {
          handle: 'kayturnerco',
          followers: 2497,
          engagementRate: 1.0532639167000402
        },
        tiktokProfile: {
          handle: 'thekayfost',
          followers: 5627,
          engagementRate: 4.435483870967742
        },
        youtubeProfile: {
          handle: 'thefostfam'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-19T18:33:06.000Z',
        amountCents: 8524
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'daa79879-afd1-4d9f-8dc9-36187195d4c3',
      content: [
        {
          groupId: '890de9dd-4039-4eda-8c69-77ea28ae9040',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-02T16:29:05.560Z',

          createdAt: '2024-07-31T18:35:39.746Z',
          content: [
            {
              caption: '',

              id: 'a59d09ae-706a-476e-a997-a41c547ce0d5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/daa79879-afd1-4d9f-8dc9-36187195d4c3/ugc/890de9dd-4039-4eda-8c69-77ea28ae9040/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '17644074-6cc0-467f-bb4d-2230a116dfe8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-02T16:29:09.047Z',

          createdAt: '2024-07-31T18:36:05.120Z',
          content: [
            {
              caption: '',

              id: '27d17bc6-cd25-4ffc-af97-45b2ba440e0a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/daa79879-afd1-4d9f-8dc9-36187195d4c3/ugc/17644074-6cc0-467f-bb4d-2230a116dfe8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6aa37e40-d845-4461-851b-b313a6e20709',
        firstName: 'Natalie',
        lastName: 'Minnehan',
        email: 'natalie.minnehan@gmail.com',
        instagramProfile: {
          handle: 'natalieminnehan',
          followers: 9582,
          engagementRate: 0.001963636363636364
        },
        tiktokProfile: {
          handle: 'natalie.minnehan',
          followers: 1214,
          engagementRate: 4.805491990846682
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T05:32:52.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0756b232-2511-4fb8-a36d-e5fb8ac3bf07',
      content: [
        {
          groupId: '855a6b8c-ea93-4a9c-98e8-ab3a3a98fb98',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T11:58:04.674Z',

          createdAt: '2024-06-27T16:43:09.680Z',
          content: [
            {
              caption: '',

              id: 'd7ce7a22-388b-41dc-8f2b-fd1f7722993b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0756b232-2511-4fb8-a36d-e5fb8ac3bf07/ugc/855a6b8c-ea93-4a9c-98e8-ab3a3a98fb98/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '22322ba8-deb1-4e9d-beb5-9e4468d1b572',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T11:57:58.374Z',

          createdAt: '2024-06-27T16:40:09.146Z',
          content: [
            {
              caption: '',

              id: '79bcff0b-41f0-44a4-9533-7573b0774ea2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0756b232-2511-4fb8-a36d-e5fb8ac3bf07/ugc/22322ba8-deb1-4e9d-beb5-9e4468d1b572/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'c421eef6-fc6c-40b4-b876-d3fabd2f3199',
        firstName: 'Lauren',
        lastName: 'Santamaria',
        email: 'lauren@pinkonthecheek.com',
        instagramProfile: {
          handle: 'pinkonthecheek',
          followers: 2108,
          engagementRate: 5.033206831119545
        },
        tiktokProfile: {
          handle: 'denverwithababy',
          followers: 173,
          engagementRate: 1.910299003322259
        },
        youtubeProfile: {
          handle: 'pinkonthecheek',
          followers: 418
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:22:11.000Z',
        amountCents: 7644
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd395a96c-f28d-4601-b4e4-3f6769312b38',
      content: [
        {
          groupId: '768945d7-5f8d-4421-b697-ea9b93210924',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-28T15:46:59.505Z',

          createdAt: '2024-06-24T19:08:51.224Z',
          content: [
            {
              caption: '',

              id: 'e20a2749-3843-441f-86cb-d719fd8d7de6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d395a96c-f28d-4601-b4e4-3f6769312b38/ugc/768945d7-5f8d-4421-b697-ea9b93210924/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b5b965b2-4b1c-4875-b35d-d2b8434c6d5c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d395a96c-f28d-4601-b4e4-3f6769312b38/ugc/768945d7-5f8d-4421-b697-ea9b93210924/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f68feb65-1d3d-4efd-9186-0cac92ed087e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d395a96c-f28d-4601-b4e4-3f6769312b38/ugc/768945d7-5f8d-4421-b697-ea9b93210924/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '36230f08-9449-4a44-b243-daffa5dec963',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T15:46:54.857Z',

          createdAt: '2024-06-24T19:05:25.262Z',
          content: [
            {
              caption: '',

              id: 'f11ad9fb-c653-461b-a893-c0e8c9370cb5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d395a96c-f28d-4601-b4e4-3f6769312b38/ugc/36230f08-9449-4a44-b243-daffa5dec963/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'fe02f560-b9d0-403d-b013-35dd8c790c67',
        firstName: 'Meagan',
        lastName: 'Schultz',
        email: 'collabwithmarlee@gmail.com',
        instagramProfile: {
          handle: 'thriftwithme_andmarlee',
          followers: 7081,
          engagementRate: 6.641717271571812
        },
        tiktokProfile: {
          handle: 'mommy_to_marlee',
          followers: 115,
          engagementRate: 2.178217821782178
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:24:58.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '28a63de6-9266-44c4-90b8-48eedbcec954',
      content: [
        {
          groupId: '11852063-9d77-4b91-bb39-e19689ccd505',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-09T01:20:50.976Z',

          createdAt: '2024-07-05T01:19:47.301Z',
          content: [
            {
              caption: '',

              id: '7ca7fc51-acaa-4641-8146-4f19e7a18356',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28a63de6-9266-44c4-90b8-48eedbcec954/ugc/11852063-9d77-4b91-bb39-e19689ccd505/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0f28a540-9e14-49f8-93a2-b8ee6089418b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28a63de6-9266-44c4-90b8-48eedbcec954/ugc/11852063-9d77-4b91-bb39-e19689ccd505/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '34987006-72c7-4a15-b370-2c1c4a8b6a74',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28a63de6-9266-44c4-90b8-48eedbcec954/ugc/11852063-9d77-4b91-bb39-e19689ccd505/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f0707f3a-725d-4677-9d0b-8d7ade3525f0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T01:20:45.444Z',

          createdAt: '2024-07-05T01:43:34.523Z',
          content: [
            {
              caption: '',

              id: '86889f9a-d06c-4a48-b185-3c075949b4d6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28a63de6-9266-44c4-90b8-48eedbcec954/ugc/f0707f3a-725d-4677-9d0b-8d7ade3525f0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '16e288ef-ed5c-40b6-a96c-535a5a5b1fde',
        firstName: 'Abigail',
        lastName: 'Hake',
        email: 'hellomissmartha@gmail.com',
        instagramProfile: {
          handle: 'lmmblog',
          followers: 7100,
          engagementRate: 0.17183098591549295
        },
        tiktokProfile: {
          handle: 'littlemissmartha.blog',
          followers: 185,
          engagementRate: 1.1078375011976622
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T16:29:46.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd98e5cb2-5e6e-4865-8f05-8c5b4058d291',
      content: [
        {
          groupId: 'eb3469e9-d05d-4cc4-a3c8-aedf62092d7a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T18:47:48.120Z',

          createdAt: '2024-06-24T01:59:54.087Z',
          content: [
            {
              caption: '',

              id: '7174609f-4d8b-4ae1-9b3c-5ac215c1610f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d98e5cb2-5e6e-4865-8f05-8c5b4058d291/ugc/eb3469e9-d05d-4cc4-a3c8-aedf62092d7a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7286d68d-249a-4847-8560-b1a13c768867',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T18:47:52.420Z',

          createdAt: '2024-06-24T02:04:45.330Z',
          content: [
            {
              caption: '',

              id: 'd5feed45-6ea9-470c-b44e-8b25725eca48',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d98e5cb2-5e6e-4865-8f05-8c5b4058d291/ugc/7286d68d-249a-4847-8560-b1a13c768867/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '82b59f4d-1242-439b-a757-d3a252e3a55a',
        firstName: 'Lucy',
        lastName: 'Rodriguez',
        email: 'casalucyro@gmail.com',
        instagramProfile: {
          handle: 'casalucyro',
          followers: 14077,
          engagementRate: 4.660794203310364
        },
        tiktokProfile: {
          handle: 'casalucyro',
          followers: 39641,
          engagementRate: 3.1198686371100166
        },
        youtubeProfile: {
          handle: 'casalucyro'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:17:58.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3c706b20-1c19-40a6-a969-7b86e6a2506c',
      content: [
        {
          groupId: '36969056-33b4-4ea7-baff-8c868879a9eb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T16:46:14.781Z',

          createdAt: '2024-06-24T18:40:39.555Z',
          content: [
            {
              caption: '',

              id: '6eb7d723-1dcd-4b34-9ae8-d47c97f210a3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3c706b20-1c19-40a6-a969-7b86e6a2506c/ugc/36969056-33b4-4ea7-baff-8c868879a9eb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '988cd92b-a609-46bf-8cce-0c16d3c68853',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T16:46:57.458Z',

          createdAt: '2024-06-24T18:41:13.804Z',
          content: [
            {
              caption: '',

              id: 'f6d92717-e1fd-40f3-b3fb-b8ce49d9852f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3c706b20-1c19-40a6-a969-7b86e6a2506c/ugc/988cd92b-a609-46bf-8cce-0c16d3c68853/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '89701a05-5c94-43fb-ac99-eb3929927256',
        firstName: 'Thalia',
        lastName: 'Silva',
        email: 'thaliaj627@gmail.com',

        tiktokProfile: {
          handle: 'thalias0226',
          followers: 7007,
          engagementRate: 18.27016520894072
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:32:54.000Z',
        amountCents: 8333
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1ec74c91-431b-41c0-adec-bcc167736f62',
      content: [
        {
          groupId: '87f7f157-462f-451d-b44b-e8ae05dd9dd6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T14:03:35.204Z',

          createdAt: '2024-06-28T01:03:04.305Z',
          content: [
            {
              caption: '',

              id: '7066f55a-8dbe-4961-b65c-c51aa6be2423',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ec74c91-431b-41c0-adec-bcc167736f62/ugc/87f7f157-462f-451d-b44b-e8ae05dd9dd6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'aac67f64-bd03-4d88-b90c-bee4d656d20c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-27T22:56:56.058Z',

          createdAt: '2024-06-23T12:14:33.326Z',
          content: [
            {
              caption: '',

              id: '33521b76-9eae-404b-82cc-f6688275febd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ec74c91-431b-41c0-adec-bcc167736f62/ugc/aac67f64-bd03-4d88-b90c-bee4d656d20c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '06bfde8d-f485-4953-b783-d4335da98247',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ec74c91-431b-41c0-adec-bcc167736f62/ugc/aac67f64-bd03-4d88-b90c-bee4d656d20c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a676fff7-e28d-4485-acb9-5ec81291ea53',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1ec74c91-431b-41c0-adec-bcc167736f62/ugc/aac67f64-bd03-4d88-b90c-bee4d656d20c/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9cbd86a7-ac39-4915-9501-e1afe4231145',
        firstName: 'Lori',
        lastName: 'Smith',
        email: 'ridgeranchfarmhouse@yahoo.com',
        instagramProfile: {
          handle: 'ridgeranchfarmhouse',
          followers: 8698,
          engagementRate: 0.33455966888939986
        },
        tiktokProfile: {
          handle: 'ridgeranchfarmhouse',
          followers: 3564,
          engagementRate: 3.4722222222222223
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T17:41:53.000Z',
        amountCents: 7471
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c43e9aea-a2fe-423a-b475-5627bb0d9b20',
      content: [
        {
          groupId: '1346af40-f30e-4fcc-be56-728da984ed1b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-26T01:24:52.664Z',

          createdAt: '2024-06-22T00:47:12.504Z',
          content: [
            {
              caption: '',

              id: '5508e677-1be3-4a36-87da-972fb9758ee8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c43e9aea-a2fe-423a-b475-5627bb0d9b20/ugc/1346af40-f30e-4fcc-be56-728da984ed1b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd2ac8565-f019-495a-ac1d-f0377abfc5e9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-26T01:24:55.785Z',

          createdAt: '2024-06-22T00:47:39.856Z',
          content: [
            {
              caption: '',

              id: '38e9f4f6-6449-468f-bf13-6a796a31766a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c43e9aea-a2fe-423a-b475-5627bb0d9b20/ugc/d2ac8565-f019-495a-ac1d-f0377abfc5e9/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ee01c849-e845-4ba9-b618-8433f7351c1b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c43e9aea-a2fe-423a-b475-5627bb0d9b20/ugc/d2ac8565-f019-495a-ac1d-f0377abfc5e9/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5405bc4c-2279-45e7-80d9-ac6e38873f3e',
        firstName: 'Lucas',
        lastName: 'Fisher',
        email: 'lucas.ficherr@gmail.com',
        instagramProfile: {
          handle: 'lucas.ficherr',
          followers: 50612,
          engagementRate: 0.005883980083774599
        },
        tiktokProfile: {
          handle: 'bridgetxmillerr',
          followers: 1505,
          engagementRate: 6.340210750022138
        },
        youtubeProfile: {
          handle: 'bridgetx'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:00:54.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fd05ebbb-dacf-4dac-a2fb-94851a0a1bea',
      content: [
        {
          groupId: 'b5fce335-1fca-4d90-b6d5-5757f02e883c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-30T13:36:54.034Z',

          createdAt: '2024-06-27T23:00:22.858Z',
          content: [
            {
              caption: '',

              id: 'caaad750-ea38-48af-a09e-e77d3c3e3a4a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fd05ebbb-dacf-4dac-a2fb-94851a0a1bea/ugc/b5fce335-1fca-4d90-b6d5-5757f02e883c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '49fc7de9-ac66-4063-a3df-2a78f7451165',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-30T13:36:59.322Z',

          createdAt: '2024-06-27T22:38:54.504Z',
          content: [
            {
              caption: '',

              id: '955dd1a8-dac5-4e6a-b7d1-e635e11f017a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fd05ebbb-dacf-4dac-a2fb-94851a0a1bea/ugc/49fc7de9-ac66-4063-a3df-2a78f7451165/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6cd42131-8f4d-485a-a498-30cd2644bc0a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fd05ebbb-dacf-4dac-a2fb-94851a0a1bea/ugc/49fc7de9-ac66-4063-a3df-2a78f7451165/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5a0969c2-49b3-4d70-b546-64ce8ff3b96d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fd05ebbb-dacf-4dac-a2fb-94851a0a1bea/ugc/49fc7de9-ac66-4063-a3df-2a78f7451165/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0c3b15fe-1170-4da3-8792-be127ce4a977',
        firstName: 'Britney',
        lastName: 'Barlow',
        email: 'britneybarlowcreative@gmail.com',
        instagramProfile: {
          handle: 'authenticallybritney',
          followers: 3284,
          engagementRate: 54.987819732034104
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:16:15.000Z',
        amountCents: 7295
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7c3533cb-d4e8-42ae-b5fc-d09f9bdad52c',
      content: [],
      creator: {
        id: '352a7cdd-b3fd-42aa-af0e-dfcca983ad5d',
        firstName: 'Sydney',
        lastName: 'Bailey',
        email: 'sydney.collaboration@gmail.com',
        instagramProfile: {
          handle: 'sydney_bailey',
          followers: 3878,
          engagementRate: 1.0288808664259927
        },
        tiktokProfile: {
          handle: 'sydney__bailey',
          followers: 2365,
          engagementRate: 10.66617213849635
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:23:45.000Z',
        amountCents: 7646
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8854f6bd-5c8e-4b0b-8bc2-d7efb0d6e410',
      content: [],
      creator: {
        id: '648cfb88-616f-4909-af2e-9b7a90a07dc1',
        firstName: 'Ashly',
        lastName: 'McClough',
        email: 'mccashly19@gmail.com',
        instagramProfile: {
          handle: 'ashlymcclough',
          followers: 34997,
          engagementRate: 124.86898877046603
        },
        tiktokProfile: {
          handle: 'ashlymcclough',
          followers: 6475,
          engagementRate: 15.151515151515152
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:25:52.000Z',
        amountCents: 7623
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '31dc7c1d-8d99-4bcb-9c6c-9291ad6da64b',
      content: [],
      creator: {
        id: '1abc8902-07bb-485d-bd86-c79d26a4c825',
        firstName: 'Kayla-Joy',
        lastName: 'Kay',
        email: 'kaylajoy.krueger@gmail.com',
        instagramProfile: {
          handle: 'makingitwithkay',
          followers: 7857,
          engagementRate: 1.5336642484408807
        },
        tiktokProfile: {
          handle: 'makingitwithkay',
          followers: 523,
          engagementRate: 3.0428738400510023
        },
        youtubeProfile: {
          handle: 'makingitwithkay',
          followers: 27
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T19:45:20.000Z',
        amountCents: 7629
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4e77da6a-a80f-4769-87d4-c07cc448e8b3',
      content: [
        {
          groupId: '8f0ff6a8-a760-4d70-a333-4d05882a95bc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T09:29:01.690Z',

          createdAt: '2024-06-27T21:10:56.510Z',
          content: [
            {
              caption: '',

              id: '337ab629-ecc2-4d16-8496-ebe182a29ab2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4e77da6a-a80f-4769-87d4-c07cc448e8b3/ugc/8f0ff6a8-a760-4d70-a333-4d05882a95bc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '447dff58-78c0-476f-af19-a65a1c06fc57',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T10:59:12.118Z',

          createdAt: '2024-06-28T03:04:40.416Z',
          content: [
            {
              caption: '',

              id: 'b6aee5ea-6363-4918-b798-e3bfa4616929',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4e77da6a-a80f-4769-87d4-c07cc448e8b3/ugc/447dff58-78c0-476f-af19-a65a1c06fc57/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ef41075c-e84c-47dd-a568-48ba0137d9a2',
        firstName: 'Abbey',
        lastName: 'Bartasavich',
        email: 'expressivepilates@gmail.com',
        instagramProfile: {
          handle: 'prosecconpilates',
          followers: 49470,
          engagementRate: 1.0190014149989892
        },
        tiktokProfile: {
          handle: 'prosecconpilates',
          followers: 832
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T20:42:07.000Z',
        amountCents: 7611
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4f242ea1-12d8-473b-84da-06b2f2e1ab1b',
      content: [],
      creator: {
        id: '350694c8-fd1e-4cc2-a4b3-a88cd2bd2aaa',
        firstName: 'Kesia',
        lastName: 'Gomez',
        email: 'gomezkesia@yahoo.com',
        instagramProfile: {
          handle: 'emiliavictoria01',
          followers: 14643,
          engagementRate: 2.7439732295294683
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T21:35:42.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7e0dee37-08bd-4331-9257-c59a911830fd',
      content: [],
      creator: {
        id: '87114d8b-cdf2-400f-afa8-c903a9841928',
        firstName: 'Elisia',
        lastName: 'Johnloz Pothoof',
        email: 'themittenstatemama@gmail.com',
        instagramProfile: {
          handle: 'mitten.state.mama',
          followers: 28521,
          engagementRate: 0.06521510465972442
        },
        tiktokProfile: {
          handle: 'mitten.state.mama',
          followers: 3224,
          engagementRate: 4.166666666666666
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T23:55:15.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '72592ba2-bf4c-4952-8d40-76ce41599510',
      content: [
        {
          groupId: '2f30b42c-0bc8-407d-85f2-24e61d597721',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-28T13:29:50.756Z',

          createdAt: '2024-06-28T01:18:12.689Z',
          content: [
            {
              caption: '',

              id: '230c0165-4c68-4b7e-9b83-ddb02b7dc92b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72592ba2-bf4c-4952-8d40-76ce41599510/ugc/2f30b42c-0bc8-407d-85f2-24e61d597721/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '238fe2e7-274d-45f3-9fff-d75f995e2d63',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72592ba2-bf4c-4952-8d40-76ce41599510/ugc/2f30b42c-0bc8-407d-85f2-24e61d597721/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c783d259-df29-4548-ba32-21fbe0331576',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72592ba2-bf4c-4952-8d40-76ce41599510/ugc/2f30b42c-0bc8-407d-85f2-24e61d597721/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '97f6964a-0deb-4a8c-a629-15efa702c40e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T13:29:45.547Z',

          createdAt: '2024-06-28T01:03:23.361Z',
          content: [
            {
              caption: '',

              id: 'c8798afd-ff50-4106-9226-878c7d0306f8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/72592ba2-bf4c-4952-8d40-76ce41599510/ugc/97f6964a-0deb-4a8c-a629-15efa702c40e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '522000b3-30a1-4c0e-a678-988ff78e2bf6',
        firstName: 'Meiry',
        lastName: 'Araujo',
        email: 'meiryedilla@gmail.com',
        instagramProfile: {
          handle: 'mother__reviews',
          followers: 39149,
          engagementRate: 1.5456333495108432
        },
        tiktokProfile: {
          handle: 'mother__reviews',
          followers: 10022,
          engagementRate: 3.8640571277439832
        },
        youtubeProfile: {
          handle: 'meiryedilla',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-20T23:55:27.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a0376e02-579d-40df-be93-677986a9ffea',
      content: [
        {
          groupId: 'cca2bf44-8856-4d29-b8c5-429d3ba6e560',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-25T13:59:02.952Z',

          createdAt: '2024-06-23T20:17:35.835Z',
          content: [
            {
              caption: '',

              id: '32b4ef24-2550-4d6c-a69e-406fdf69ab1c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a0376e02-579d-40df-be93-677986a9ffea/ugc/cca2bf44-8856-4d29-b8c5-429d3ba6e560/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd17b5fab-e369-4ab9-885f-16cadf382073',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a0376e02-579d-40df-be93-677986a9ffea/ugc/cca2bf44-8856-4d29-b8c5-429d3ba6e560/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '7ed11341-b9de-4065-bccb-6f9be9bfe67b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-25T17:37:05.517Z',

          createdAt: '2024-06-24T00:05:07.168Z',
          content: [
            {
              caption: '',

              id: 'c0542508-34e3-4478-bffa-2e0a111ba24b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a0376e02-579d-40df-be93-677986a9ffea/ugc/7ed11341-b9de-4065-bccb-6f9be9bfe67b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '78a05cdb-ff0d-4d7a-ac8f-cb69e7031371',
        firstName: 'Blanca',
        lastName: 'Hernandez',
        email: 'bher07@gmail.com',
        instagramProfile: {
          handle: 'mzzhernandezz',
          followers: 2405,
          engagementRate: 1.792099792099792
        },
        tiktokProfile: {
          handle: 'mzzhernandezz',
          followers: 354,
          engagementRate: 6.854130052724078
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T00:33:38.000Z',
        amountCents: 7646
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5ae109e4-5c3f-447a-beda-d45e8e200257',
      content: [
        {
          groupId: 'f0875c45-a61b-497c-9afb-6ac3c2dbbfe4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T18:15:35.848Z',

          createdAt: '2024-06-26T05:27:40.499Z',
          content: [
            {
              caption: '',

              id: 'e3538983-a2d5-4ac0-b244-de167851ae17',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5ae109e4-5c3f-447a-beda-d45e8e200257/ugc/f0875c45-a61b-497c-9afb-6ac3c2dbbfe4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '1ce09bdf-07a9-4f3b-a233-944e8b9073cf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-02T18:15:30.179Z',

          createdAt: '2024-06-26T17:42:02.202Z',
          content: [
            {
              caption: '',

              id: '19fa55d1-05a4-421e-8e7c-e520369dd341',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5ae109e4-5c3f-447a-beda-d45e8e200257/ugc/1ce09bdf-07a9-4f3b-a233-944e8b9073cf/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '03a77280-e937-41cb-a1e7-ac429be377dc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5ae109e4-5c3f-447a-beda-d45e8e200257/ugc/1ce09bdf-07a9-4f3b-a233-944e8b9073cf/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e3d7237d-e8aa-49fb-8578-80a44c4323e9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5ae109e4-5c3f-447a-beda-d45e8e200257/ugc/1ce09bdf-07a9-4f3b-a233-944e8b9073cf/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e09a8aeb-9eed-408b-8714-b316a9345c37',
        firstName: 'Lidiia',
        lastName: 'Moseichuk',
        email: '1997lida@gmail.com',
        instagramProfile: {
          handle: 'lidiyamos',
          followers: 12802,
          engagementRate: 0.8065146070926418
        },
        tiktokProfile: {
          handle: 'lidiyamos',
          followers: 36
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T01:24:00.000Z',
        amountCents: 8328
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '81092e96-45ce-4871-8c52-06d8a53eec74',
      content: [],
      creator: {
        id: '0ec84a71-b096-4256-bfa2-c9f9492a4db0',
        firstName: 'Grisel',
        lastName: 'Mozas',
        email: 'tellgrisel@gmail.com',
        instagramProfile: {
          handle: 'tell_grisel',
          followers: 2318
        },
        tiktokProfile: {
          handle: 'tell_grisel',
          followers: 3156,
          engagementRate: 27.176308811821908
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T02:55:56.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9a8747b0-ef2c-418a-8cba-bdddfd337c46',
      content: [
        {
          groupId: '6a9040a1-6335-4d78-85ff-b800407fdd29',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-29T03:06:39.250Z',

          createdAt: '2024-06-23T22:45:26.411Z',
          content: [
            {
              caption: '',

              id: 'a44248b1-5597-43ce-b965-715f4b2d235f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9a8747b0-ef2c-418a-8cba-bdddfd337c46/ugc/6a9040a1-6335-4d78-85ff-b800407fdd29/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'fefe57f9-a4a6-45e5-b9e0-fc3b1891ee82',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-29T03:06:45.787Z',

          createdAt: '2024-06-23T20:17:34.522Z',
          content: [
            {
              caption: '',

              id: '272c96f7-cae2-4949-b6f5-e9d68d9df44f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9a8747b0-ef2c-418a-8cba-bdddfd337c46/ugc/fefe57f9-a4a6-45e5-b9e0-fc3b1891ee82/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '888946a6-3a08-4909-a1d7-c3bedc98e6e9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9a8747b0-ef2c-418a-8cba-bdddfd337c46/ugc/fefe57f9-a4a6-45e5-b9e0-fc3b1891ee82/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '34fe567c-6c44-4976-ac4e-ea9c23101df1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9a8747b0-ef2c-418a-8cba-bdddfd337c46/ugc/fefe57f9-a4a6-45e5-b9e0-fc3b1891ee82/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8f7aaa32-7f83-4e43-af0f-622064eb06e3',
        firstName: 'Jorgie',
        lastName: 'Rowe',
        email: 'jorgie.chandler7@gmail.com',
        instagramProfile: {
          handle: 'jorgieisabella',
          followers: 2704,
          engagementRate: 6.703032544378698
        },
        tiktokProfile: {
          handle: 'jorgieisabella',
          followers: 306
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T06:51:49.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fc7e6e58-cb0e-4b91-9935-8e3788fb7a8d',
      content: [
        {
          groupId: '731086e7-0239-42a7-9cbc-8b5bd33f51db',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T03:43:13.988Z',

          createdAt: '2024-07-02T20:24:37.450Z',
          content: [
            {
              caption: '',

              id: '8688080d-b297-4637-bb8b-649b6efa42a7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fc7e6e58-cb0e-4b91-9935-8e3788fb7a8d/ugc/731086e7-0239-42a7-9cbc-8b5bd33f51db/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '84ea3968-69c1-472e-a0e5-f787d4fdb13f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T18:23:06.714Z',

          createdAt: '2024-07-01T10:05:31.824Z',
          content: [
            {
              caption: '',

              id: '1f59ac1e-6ca0-4d46-bf33-c988112bdb39',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/fc7e6e58-cb0e-4b91-9935-8e3788fb7a8d/ugc/84ea3968-69c1-472e-a0e5-f787d4fdb13f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '82e294b3-742c-494f-a521-f95768fde66a',
        firstName: 'Jenna',
        lastName: 'Karel',
        email: 'jennakarel@yahoo.com',
        instagramProfile: {
          handle: 'jennakaykarel',
          followers: 2607,
          engagementRate: 2.6774069812044496
        },
        tiktokProfile: {
          handle: 'jennakaykarel',
          followers: 2472,
          engagementRate: 4.448398576512456
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T10:06:00.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2e61dd69-6dc1-4998-9cfa-42ebd0b78284',
      content: [
        {
          groupId: 'e6634a28-5a74-4633-8545-d0817db8e249',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-03T13:09:41.787Z',

          createdAt: '2024-07-02T03:15:32.415Z',
          content: [
            {
              caption: '',

              id: '71641c42-63d4-493e-b2e0-217d0ce598da',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2e61dd69-6dc1-4998-9cfa-42ebd0b78284/ugc/e6634a28-5a74-4633-8545-d0817db8e249/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9379e9aa-4749-4595-9e91-cb01f29efb04',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2e61dd69-6dc1-4998-9cfa-42ebd0b78284/ugc/e6634a28-5a74-4633-8545-d0817db8e249/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'a5fa7358-d97a-455a-b87e-ce30434e9226',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2e61dd69-6dc1-4998-9cfa-42ebd0b78284/ugc/e6634a28-5a74-4633-8545-d0817db8e249/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2e8ae0cb-6105-4644-b839-7ae9cdf91e28',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T13:09:38.354Z',

          createdAt: '2024-07-02T03:30:11.349Z',
          content: [
            {
              caption: '',

              id: '6ad1ba9f-edbd-4c1d-ac96-33fb21b29deb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2e61dd69-6dc1-4998-9cfa-42ebd0b78284/ugc/2e8ae0cb-6105-4644-b839-7ae9cdf91e28/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9130c981-315b-4a49-807c-dc52e097dc91',
        firstName: 'Kristi',
        lastName: 'Idio',
        email: 'simplykristiidio@gmail.com',
        instagramProfile: {
          handle: 'theidiofam',
          followers: 3951,
          engagementRate: 0.5542900531511009
        },
        tiktokProfile: {
          handle: '_theidiofam',
          followers: 1290,
          engagementRate: 2.874037403740374
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-21T13:02:27.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8e6feb3d-d3f8-4055-9771-fe10ed27bd93',
      content: [
        {
          groupId: '9fa9550c-fec5-4261-b915-c4446b0c39f9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-04T02:53:52.284Z',

          createdAt: '2024-06-28T02:46:24.227Z',
          content: [
            {
              caption: '',

              id: 'c35395ce-f452-44dc-893b-999d134fd8b9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e6feb3d-d3f8-4055-9771-fe10ed27bd93/ugc/9fa9550c-fec5-4261-b915-c4446b0c39f9/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7b1777e6-8c04-4b0b-922e-db97333752a6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e6feb3d-d3f8-4055-9771-fe10ed27bd93/ugc/9fa9550c-fec5-4261-b915-c4446b0c39f9/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3d08b733-2bf3-4463-9a3d-e7c094152898',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e6feb3d-d3f8-4055-9771-fe10ed27bd93/ugc/9fa9550c-fec5-4261-b915-c4446b0c39f9/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '1e87f49c-0b55-4eed-bba4-3f4c372af437',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T12:00:34.753Z',

          createdAt: '2024-07-04T02:53:48.192Z',
          content: [
            {
              caption: '',

              id: '536e3d20-8488-4d20-b765-a01733ea6a38',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8e6feb3d-d3f8-4055-9771-fe10ed27bd93/ugc/1e87f49c-0b55-4eed-bba4-3f4c372af437/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'da290b6e-000b-426b-a811-f571326da5f2',
        firstName: 'Whitney',
        lastName: 'Howe',
        email: 'whitneylhowe22@gmail.com',
        instagramProfile: {
          handle: 'whitney_howe',
          followers: 2412,
          engagementRate: 3.735489220563847
        },
        tiktokProfile: {
          handle: 'whitney_howe',
          followers: 8838,
          engagementRate: 8.850507416081186
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-22T11:23:47.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '538e66cd-7520-4691-a8ec-d8a08a7ef3dc',
      content: [
        {
          groupId: '1c0d0c26-25f9-41c4-a351-20c7db35b87f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T14:40:24.993Z',

          createdAt: '2024-06-28T14:37:36.123Z',
          content: [
            {
              caption: '',

              id: '0d07aed3-4bc3-4326-8216-0e685d3f6b60',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/538e66cd-7520-4691-a8ec-d8a08a7ef3dc/ugc/1c0d0c26-25f9-41c4-a351-20c7db35b87f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2fe38faa-b0ed-47f4-8968-66fd5d71aaa7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T11:26:01.864Z',

          createdAt: '2024-07-01T16:37:35.372Z',
          content: [
            {
              caption: '',

              id: '9b9f3c8d-19e3-4811-a710-e023a2632bac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/538e66cd-7520-4691-a8ec-d8a08a7ef3dc/ugc/2fe38faa-b0ed-47f4-8968-66fd5d71aaa7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '21d564d3-57b0-4b7c-bf7e-6cdb3dc57685',
        firstName: 'Jillian',
        lastName: 'Piraino',
        email: 'jillpiraino@yahoo.com',
        instagramProfile: {
          handle: 'its_a_me_jillyp',
          followers: 872
        },
        tiktokProfile: {
          handle: 'dixiethelabradork',
          followers: 623
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-24T16:00:00.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '509dccfa-2d28-45b6-8607-80f0781ee169',
      content: [],
      creator: {
        id: 'd3bc8ce4-5aab-4325-b6f3-9af9c0b0065a',
        firstName: 'Aksana Yusupova',
        lastName: 'Yusupova',
        email: 'oksana.mehmet@gmail.com',
        instagramProfile: {
          handle: 'aksana.yusupova',
          followers: 89798,
          engagementRate: 6.092563308759661
        },
        tiktokProfile: {
          handle: 'aksana.yusupova',
          followers: 4291,
          engagementRate: 1.2906829448317825
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T16:35:15.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ff025dc7-7a68-49f7-a574-01b8138665ba',
      content: [
        {
          groupId: 'dbc05171-406a-4501-b554-d4d7e3d8c628',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T14:16:48.333Z',

          createdAt: '2024-06-26T19:16:53.154Z',
          content: [
            {
              caption: '',

              id: '4780766e-58f4-4351-ae3d-34e29e3cac46',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ff025dc7-7a68-49f7-a574-01b8138665ba/ugc/dbc05171-406a-4501-b554-d4d7e3d8c628/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2fce047f-dad8-46b8-9cd9-05aafe78e2ed',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-28T14:16:54.417Z',

          createdAt: '2024-06-26T19:17:47.947Z',
          content: [
            {
              caption: '',

              id: '955456f4-0271-41ad-9676-97791794526a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ff025dc7-7a68-49f7-a574-01b8138665ba/ugc/2fce047f-dad8-46b8-9cd9-05aafe78e2ed/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5e3bbde1-6a40-4908-adad-4ddb78ae465d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ff025dc7-7a68-49f7-a574-01b8138665ba/ugc/2fce047f-dad8-46b8-9cd9-05aafe78e2ed/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '30ec55d0-760f-4142-bcd7-268159777c6c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ff025dc7-7a68-49f7-a574-01b8138665ba/ugc/2fce047f-dad8-46b8-9cd9-05aafe78e2ed/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a6ac4e6e-0ecd-425b-96ca-b4d0c20e1a0e',
        firstName: 'Hande',
        lastName: 'Guven',
        email: 'handetoparli@gmail.com',
        instagramProfile: {
          handle: 'handesguven',
          followers: 27178,
          engagementRate: 0.39259695341820594
        },
        tiktokProfile: {
          handle: 'handesguven',
          followers: 21,
          engagementRate: 5.423484684294177
        },
        youtubeProfile: {
          handle: 'dietpackage6341',
          followers: 2030
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T16:55:35.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9066a5df-c442-4eb0-a4e0-8d64e26e9fd0',
      content: [
        {
          groupId: '121b7438-e4a4-46b8-87ce-ef87ca0d65ed',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-01T13:33:21.037Z',

          createdAt: '2024-06-28T17:07:26.235Z',
          content: [
            {
              caption: '',

              id: '59e3317e-d00e-40d2-be70-9c9022492726',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9066a5df-c442-4eb0-a4e0-8d64e26e9fd0/ugc/121b7438-e4a4-46b8-87ce-ef87ca0d65ed/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b9d65ee4-5733-40db-9ca4-b915b123f6d8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9066a5df-c442-4eb0-a4e0-8d64e26e9fd0/ugc/121b7438-e4a4-46b8-87ce-ef87ca0d65ed/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c95d8ecc-56c9-4fc7-a7aa-f6c5cedf0b65',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9066a5df-c442-4eb0-a4e0-8d64e26e9fd0/ugc/121b7438-e4a4-46b8-87ce-ef87ca0d65ed/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '66ab4621-fad7-48a7-a416-4ac8628534e4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T13:33:18.032Z',

          createdAt: '2024-06-28T17:44:20.965Z',
          content: [
            {
              caption: '',

              id: '0bbf80a8-59c7-42f8-8f4b-93c35ac66139',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/9066a5df-c442-4eb0-a4e0-8d64e26e9fd0/ugc/66ab4621-fad7-48a7-a416-4ac8628534e4/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '99ba1f05-3b5d-4113-878d-ebbcd8db8c71',
        firstName: 'Autumn',
        lastName: 'Hall',
        email: 'autumntaggartmedia@gmail.com',
        instagramProfile: {
          handle: 'autumnmarietaggart',
          followers: 3046,
          engagementRate: 0.020733704552898784
        },
        tiktokProfile: {
          handle: 'autumnmarietaggart',
          followers: 3742,
          engagementRate: 10.766045548654244
        },
        youtubeProfile: {
          handle: 'autumntaggart',
          followers: 2470
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T17:35:21.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a229762a-34d1-4b83-a084-736bf2c87fbb',
      content: [
        {
          groupId: '3b0b8086-faf5-4415-bc8c-5f28bcad73a6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-01T20:17:25.394Z',

          createdAt: '2024-06-28T22:28:04.945Z',
          content: [
            {
              caption: '',

              id: '60cf4075-fe83-4815-b3a9-83d3d7acdb44',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a229762a-34d1-4b83-a084-736bf2c87fbb/ugc/3b0b8086-faf5-4415-bc8c-5f28bcad73a6/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '52afcbd8-3036-445b-805b-772db63cf3d3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a229762a-34d1-4b83-a084-736bf2c87fbb/ugc/3b0b8086-faf5-4415-bc8c-5f28bcad73a6/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'dfc591f1-35c5-4c3f-81b0-e84a9d3a7165',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a229762a-34d1-4b83-a084-736bf2c87fbb/ugc/3b0b8086-faf5-4415-bc8c-5f28bcad73a6/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '024f3caa-8b08-4c8d-8488-e3493429fb06',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T20:17:17.863Z',

          createdAt: '2024-06-28T22:27:18.032Z',
          content: [
            {
              caption: '',

              id: '399bdd51-9c1d-4b99-8706-0769b2872af7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a229762a-34d1-4b83-a084-736bf2c87fbb/ugc/024f3caa-8b08-4c8d-8488-e3493429fb06/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9646701d-ab4e-4be5-a795-6b0a55dd22c1',
        firstName: 'Basia',
        lastName: 'Sheleg',
        email: 'alongsideourhome@gmail.com',
        instagramProfile: {
          handle: 'alongsideourhome',
          followers: 6746,
          engagementRate: 0.7500741179958494
        },
        tiktokProfile: {
          handle: 'alongsideourhome',
          followers: 8349,
          engagementRate: 1.8035437564374992
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-25T21:12:24.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'aa9f6203-534e-4a19-83d9-7d64c6e31c66',
      content: [],
      creator: {
        id: '15f0bd33-be51-480b-a1bc-7c0e575ce13f',
        firstName: 'Kübra',
        lastName: 'Tezel',
        email: 'kubra.soyvural@hotmail.com',
        instagramProfile: {
          handle: 'kubikslife',
          followers: 164676,
          engagementRate: 7.719036167990478
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T01:39:35.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '75506586-c563-4c2f-8bf9-021b61fcb7fc',
      content: [
        {
          groupId: '9a2de45e-5e79-4e01-bec9-b1e2e0a079fd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T16:14:25.662Z',

          createdAt: '2024-06-30T20:46:57.659Z',
          content: [
            {
              caption: '',

              id: '6232fc19-70f5-4bbd-9cdd-c30fe9323b48',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/75506586-c563-4c2f-8bf9-021b61fcb7fc/ugc/9a2de45e-5e79-4e01-bec9-b1e2e0a079fd/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '49fca366-8420-46f1-9cb4-03bb8cb0a46c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T16:14:20.800Z',

          createdAt: '2024-06-30T21:04:17.264Z',
          content: [
            {
              caption: '',

              id: '6e86a015-e417-49dd-aa98-962c30bf7db6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/75506586-c563-4c2f-8bf9-021b61fcb7fc/ugc/49fca366-8420-46f1-9cb4-03bb8cb0a46c/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '3dfe72eb-e84d-4fe5-b859-875081b6ce4b',
        firstName: 'Alice',
        lastName: 'Schulz',
        email: 'twoportlandmoms@gmail.com',
        instagramProfile: {
          handle: 'the.portland.mom',
          followers: 5936,
          engagementRate: 32.90768194070081
        },
        tiktokProfile: {
          handle: 'the.portland.mom',
          followers: 1725,
          engagementRate: 6.406228325703981
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T02:35:39.000Z',
        amountCents: 8041
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2c536e7b-0467-426e-b9c6-4f4297d25d06',
      content: [
        {
          groupId: 'cda6c405-b37b-4898-a4d8-e6877dccfe3a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:00:55.663Z',

          createdAt: '2024-06-01T02:43:53.033Z',
          content: [
            {
              caption: '',

              id: '08b920a0-2715-43c8-abab-661fce94bd57',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2c536e7b-0467-426e-b9c6-4f4297d25d06/ugc/cda6c405-b37b-4898-a4d8-e6877dccfe3a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd0d74e69-90be-4737-928f-e2c839645bc6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-06-03T18:01:01.550Z',

          createdAt: '2024-06-01T02:46:31.583Z',
          content: [
            {
              caption: '',

              id: '2afc775a-a476-45c9-a640-01a662cebc13',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2c536e7b-0467-426e-b9c6-4f4297d25d06/ugc/d0d74e69-90be-4737-928f-e2c839645bc6/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '81ac9a52-ad88-4ca8-9c4e-4cc4e5548b63',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2c536e7b-0467-426e-b9c6-4f4297d25d06/ugc/d0d74e69-90be-4737-928f-e2c839645bc6/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ab7c1c6c-5d8a-478d-b913-c8a0b1fedad1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2c536e7b-0467-426e-b9c6-4f4297d25d06/ugc/d0d74e69-90be-4737-928f-e2c839645bc6/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '92c318a6-7ec1-469e-ba19-1465a4b67f79',
        firstName: 'Solomiya',
        lastName: 'Brown',
        email: 'solomiyabrown@gmail.com',

        tiktokProfile: {
          handle: 'spicysolo1',
          followers: 1517,
          engagementRate: 6.3559322033898304
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T04:00:00.000Z',
        amountCents: 100
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f7c4ccc5-1bd8-4b71-b64c-a0aa9058ffb6',
      content: [
        {
          groupId: '3656c6c3-a763-466f-91e5-b75a10ab8aaf',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T16:23:12.503Z',

          createdAt: '2024-07-02T22:48:02.742Z',
          content: [
            {
              caption: '',

              id: '64a3b2f1-f7cc-44d7-ad92-ba3c0c3789ef',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f7c4ccc5-1bd8-4b71-b64c-a0aa9058ffb6/ugc/3656c6c3-a763-466f-91e5-b75a10ab8aaf/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '01d03a8d-b6a7-4bd1-923a-10a9b4cfdcbd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-03T10:49:38.613Z',

          createdAt: '2024-07-02T02:55:33.135Z',
          content: [
            {
              caption: '',

              id: '5a8ec765-4c74-48a6-baca-ce2ba8bee8ce',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f7c4ccc5-1bd8-4b71-b64c-a0aa9058ffb6/ugc/01d03a8d-b6a7-4bd1-923a-10a9b4cfdcbd/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6ad174ce-5072-47a2-ab4b-9851334f01cb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f7c4ccc5-1bd8-4b71-b64c-a0aa9058ffb6/ugc/01d03a8d-b6a7-4bd1-923a-10a9b4cfdcbd/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fd2d19b7-575d-4c01-b232-9ae44420a664',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f7c4ccc5-1bd8-4b71-b64c-a0aa9058ffb6/ugc/01d03a8d-b6a7-4bd1-923a-10a9b4cfdcbd/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8eb8fb47-b10b-49e8-8f68-6bb6e5c902d3',
        firstName: 'Alejandra',
        lastName: 'Perez',
        email: 'alejandra.prz92@yahoo.com',
        instagramProfile: {
          handle: '_ale.prez',
          followers: 4941,
          engagementRate: 0.8844363489172233
        },
        tiktokProfile: {
          handle: '_ale.prez',
          followers: 2676,
          engagementRate: 8.092485549132949
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T10:44:52.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '51541fae-b1b6-4bac-a4b1-bb9c91b7b287',
      content: [],
      creator: {
        id: 'b72d08b6-ade6-4e1e-a00d-82b764fd8eb3',
        firstName: 'Mackenzie',
        lastName: 'Lewko',
        email: 'mackenzielewko@hotmail.com',
        instagramProfile: {
          handle: 'kenzielewko',
          followers: 2391,
          engagementRate: 1.902969468841489
        },
        tiktokProfile: {
          handle: 'kenzielewko',
          followers: 3400,
          engagementRate: 5.98202471172518
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T12:52:31.000Z',
        amountCents: 7608
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1b49b5c1-201c-4c8e-95b7-0455e19ce452',
      content: [
        {
          groupId: '89878490-1ae2-4ec8-8d7b-615b343c921f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-08T18:51:21.143Z',

          createdAt: '2024-07-06T15:11:49.930Z',
          content: [
            {
              caption: '',

              id: 'dbc48abb-5676-462a-a8b9-9f3c4ab3c9fc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b49b5c1-201c-4c8e-95b7-0455e19ce452/ugc/89878490-1ae2-4ec8-8d7b-615b343c921f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'fe44517e-125a-438c-8a1d-160a3df020b8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b49b5c1-201c-4c8e-95b7-0455e19ce452/ugc/89878490-1ae2-4ec8-8d7b-615b343c921f/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '24c54269-3dff-42e9-919a-5c7f347f3d66',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b49b5c1-201c-4c8e-95b7-0455e19ce452/ugc/89878490-1ae2-4ec8-8d7b-615b343c921f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '104a031c-ecad-43ea-9b21-21d8cd367b90',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T14:59:22.149Z',

          createdAt: '2024-07-06T15:26:11.374Z',
          content: [
            {
              caption: '',

              id: 'b4d5e61f-caaf-4ed9-81af-b74acba1abe7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1b49b5c1-201c-4c8e-95b7-0455e19ce452/ugc/104a031c-ecad-43ea-9b21-21d8cd367b90/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '34a9503c-327f-4a38-8cdd-e12b9740b528',
        firstName: 'Carlie',
        lastName: 'Mohr',
        email: 'mohrcarl1992@gmail.com',

        tiktokProfile: {
          handle: 'carbar1231',
          followers: 26
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:14:31.000Z',
        amountCents: 8418
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '57f3e5eb-8a44-4c71-8838-1a97ae211cff',
      content: [],
      creator: {
        id: 'a6b16cfc-df3d-4ce4-98e4-3cc5cf745b91',
        firstName: 'Tanya',
        lastName: 'Brown',
        email: 'janetabrown14@gmail.com',

        tiktokProfile: {
          handle: 'tanyabrown5913',
          followers: 194,
          engagementRate: 13.626373626373626
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:17:56.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dc4d9199-93cb-48bf-81e2-b41556b8602b',
      content: [],
      creator: {
        id: 'dbbda49b-9e4c-4d04-88e8-a9b4babc14fb',
        firstName: 'Rujae',
        lastName: 'Chin',
        email: 'rujaechin@gmail.com',

        tiktokProfile: {
          handle: 'jae_said_so',
          followers: 183,
          engagementRate: 10.023866348448687
        },
        youtubeProfile: {
          handle: 'rujaechin515',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:18:04.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e2879817-5cef-4ae1-b683-9ea09b0afb3e',
      content: [
        {
          groupId: 'afb2a50b-e375-400d-8a3e-1f434c072a60',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T13:35:04.113Z',

          createdAt: '2024-06-28T19:08:15.990Z',
          content: [
            {
              caption: '',

              id: '3bd60110-5e10-475d-8d76-3c95001fe9c6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e2879817-5cef-4ae1-b683-9ea09b0afb3e/ugc/afb2a50b-e375-400d-8a3e-1f434c072a60/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '2163cb07-e6c9-4e10-b008-b1ec78776312',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-01T13:01:41.685Z',

          createdAt: '2024-06-28T19:09:06.586Z',
          content: [
            {
              caption: '',

              id: '59de9bba-04f4-4366-88f9-45174c07f7f1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e2879817-5cef-4ae1-b683-9ea09b0afb3e/ugc/2163cb07-e6c9-4e10-b008-b1ec78776312/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6fc03358-f87d-463f-9d7a-f57d424647cb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e2879817-5cef-4ae1-b683-9ea09b0afb3e/ugc/2163cb07-e6c9-4e10-b008-b1ec78776312/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ecf86f54-aa61-4d4d-a2ad-0cdfde8d677d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e2879817-5cef-4ae1-b683-9ea09b0afb3e/ugc/2163cb07-e6c9-4e10-b008-b1ec78776312/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '63e1f342-c527-4f54-a340-c67a32a55e1d',
        firstName: 'Kyra',
        lastName: 'Vasquez',
        email: 'kyralvasquez@gmail.com',
        instagramProfile: {
          handle: 'punkyvinyls',
          followers: 147,
          engagementRate: 11.292517006802722
        },
        tiktokProfile: {
          handle: 'poisoncherrrry',
          followers: 119,
          engagementRate: 8.333333333333332
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:22:27.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '006d6b2f-8912-4e53-9e4d-178f3cbc5da1',
      content: [],
      creator: {
        id: '33bdf70b-ead3-4150-b901-29bd7d8c61f5',
        firstName: 'Ericka',
        lastName: 'Zamora Guatemala',
        email: 'valerieeguatemala200314@gmail.com',

        tiktokProfile: {
          handle: 'guatemalavalerie_',
          followers: 2085,
          engagementRate: 9.394879134860052
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:23:14.000Z',
        amountCents: 8644
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '111b46bd-eea9-4298-a635-c239112c46f2',
      content: [],
      creator: {
        id: '31307701-ef6c-499c-9129-90cbe3916393',
        firstName: 'Lacitia',
        lastName: 'Smith',
        email: 'evette5092@gmail.com',

        tiktokProfile: {
          handle: 'keshagreen362',
          followers: 8
        },
        youtubeProfile: {
          handle: 'lg5092',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:24:44.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0d0a02dd-6b36-4b99-82a9-b3cf6b5f566b',
      content: [
        {
          groupId: '5d976244-9e6f-47fb-8cb4-d170d3344d09',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T12:19:29.739Z',

          createdAt: '2024-06-26T17:12:26.278Z',
          content: [
            {
              caption: '',

              id: '2b63d590-6774-47ce-91c4-744cc3f93564',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0d0a02dd-6b36-4b99-82a9-b3cf6b5f566b/ugc/5d976244-9e6f-47fb-8cb4-d170d3344d09/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c864a42d-eefe-4a7a-8e0f-2452bdfe1dee',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-28T09:08:04.613Z',

          createdAt: '2024-06-26T16:49:21.897Z',
          content: [
            {
              caption: '',

              id: 'bd38b0f2-6030-4825-ab24-5f3ecbc37ea8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0d0a02dd-6b36-4b99-82a9-b3cf6b5f566b/ugc/c864a42d-eefe-4a7a-8e0f-2452bdfe1dee/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9a1e4c8a-cc6b-4191-9fdf-e34dfb06731b',
        firstName: 'Deneika',
        lastName: 'Berlin',
        email: 'shanakay575@gmail.com',
        instagramProfile: {
          handle: 'itz_dennii',
          followers: 1290,
          engagementRate: 2.0155038759689923
        },
        tiktokProfile: {
          handle: 'uniqueboo',
          followers: 1483,
          engagementRate: 25.436105476673426
        },
        youtubeProfile: {
          handle: 'deneikaberlin3536',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:26:12.000Z',
        amountCents: 12000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f103ea06-620d-4a48-a446-4e2a305c3cfb',
      content: [
        {
          groupId: '7cc75a07-521d-49df-a6a8-8809f1d66692',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T22:01:57.395Z',

          createdAt: '2024-07-05T18:23:43.038Z',
          content: [
            {
              caption: '',

              id: '2007f373-c5ed-4f38-a0d5-02a582bc5ef5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f103ea06-620d-4a48-a446-4e2a305c3cfb/ugc/7cc75a07-521d-49df-a6a8-8809f1d66692/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'cf347b08-23fd-494c-a500-3ecfe209c022',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-05T16:11:13.019Z',

          createdAt: '2024-07-04T01:49:37.122Z',
          content: [
            {
              caption: '',

              id: '8aa3905b-a0d1-4cc3-9c4e-7d8c8fc6a9c4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f103ea06-620d-4a48-a446-4e2a305c3cfb/ugc/cf347b08-23fd-494c-a500-3ecfe209c022/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '403cccc9-8be2-4509-b584-79a20857c231',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f103ea06-620d-4a48-a446-4e2a305c3cfb/ugc/cf347b08-23fd-494c-a500-3ecfe209c022/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4c31eb6b-f82d-4f4f-a565-16dd6966e21f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/f103ea06-620d-4a48-a446-4e2a305c3cfb/ugc/cf347b08-23fd-494c-a500-3ecfe209c022/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4b5d209d-1696-40ca-9cda-c0ff4dd1c180',
        firstName: 'Sarah',
        lastName: 'Carver',
        email: 'sarah.carver@nm.com',

        tiktokProfile: {
          handle: 'sarahcarver5',
          followers: 20
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:28:59.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6b8f2195-266a-47a8-93f4-337839b7ae22',
      content: [],
      creator: {
        id: '85f0a054-8d78-4202-b457-824d5336e51e',
        firstName: 'Lynx',
        lastName: 'McDonald',
        email: 'lynx.mcdonald@icloud.com',
        instagramProfile: {
          handle: 'lynxelliott',
          followers: 16
        },
        tiktokProfile: {
          handle: 'lynxelliott',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:30:05.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6e03b530-be31-4c5d-a258-f057b112709d',
      content: [],
      creator: {
        id: 'e7b562d4-34e1-4a55-92e9-d03773e79723',
        firstName: 'Sofiia',
        lastName: 'Postol',
        email: 'sofapostol@icloud.com',

        tiktokProfile: {
          handle: 'sofiia.p237',
          followers: 72
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:45:17.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '63fb1adf-4691-409f-b30d-fe498bb95be6',
      content: [
        {
          groupId: '8342e395-ae4e-435c-a7b7-d1116b5b4cbc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-19T17:52:24.447Z',

          createdAt: '2024-07-11T15:24:34.120Z',
          content: [
            {
              caption: '',

              id: '98059af0-5e37-492a-a1e0-d17006e0f098',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/63fb1adf-4691-409f-b30d-fe498bb95be6/ugc/8342e395-ae4e-435c-a7b7-d1116b5b4cbc/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'daec0611-2663-4c37-834b-6de7e55af976',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-19T17:52:20.706Z',

          createdAt: '2024-07-11T15:25:37.811Z',
          content: [
            {
              caption: '',

              id: '4dccdafe-ae8a-4869-9483-a9c833bbf218',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/63fb1adf-4691-409f-b30d-fe498bb95be6/ugc/daec0611-2663-4c37-834b-6de7e55af976/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9724f758-8756-44b6-831d-af48989109ea',
        firstName: 'Mimi',
        lastName: 'Kim',
        email: 'kimanhkim@hotmail.com',

        tiktokProfile: {
          handle: '_mimitok',
          followers: 70
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:52:12.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c32a3286-d3d6-45b5-8a3e-a765320477c8',
      content: [
        {
          groupId: '4dc56eae-0889-465a-9052-71003307c502',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-18T17:16:06.300Z',

          createdAt: '2024-07-16T18:20:37.745Z',
          content: [
            {
              caption: '',

              id: '8cf97204-7c5b-456e-82fa-e5a3d6767484',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c32a3286-d3d6-45b5-8a3e-a765320477c8/ugc/4dc56eae-0889-465a-9052-71003307c502/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3898bc8b-1235-4240-941a-fe4953190c85',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c32a3286-d3d6-45b5-8a3e-a765320477c8/ugc/4dc56eae-0889-465a-9052-71003307c502/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '253baac8-7120-4c9b-b928-04e4a54a4fbf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c32a3286-d3d6-45b5-8a3e-a765320477c8/ugc/4dc56eae-0889-465a-9052-71003307c502/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '26a2de46-6f17-4063-bed5-ea772ef8e5f8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-18T17:16:03.036Z',

          createdAt: '2024-07-16T18:20:16.648Z',
          content: [
            {
              caption: '',

              id: 'fb2bbee2-296f-4695-a08f-57ce6baef8d7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c32a3286-d3d6-45b5-8a3e-a765320477c8/ugc/26a2de46-6f17-4063-bed5-ea772ef8e5f8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '308f5455-3ab2-43e6-b922-2d20fa3f2c65',
        firstName: 'Stefany',
        lastName: 'Nogueira',
        email: 'stefany_97soares@outlook.com',
        instagramProfile: {
          handle: 'stefany.brunow',
          followers: 8968,
          engagementRate: 1.4607493309545048
        },
        tiktokProfile: {
          handle: 'stefanybrunow',
          followers: 683,
          engagementRate: 2.182952182952183
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T16:59:38.000Z',
        amountCents: 7436
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '12fd93fe-f6df-4222-a26e-dab73c6fceef',
      content: [
        {
          groupId: '0abadd3c-1876-4b06-8b67-b58f874102d1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-17T01:13:31.050Z',

          createdAt: '2024-07-14T19:08:09.919Z',
          content: [
            {
              caption: '',

              id: 'ba8b0f83-5892-4262-a7f0-0b52453ebcf3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/12fd93fe-f6df-4222-a26e-dab73c6fceef/ugc/0abadd3c-1876-4b06-8b67-b58f874102d1/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0db5307d-21bb-465e-b80a-3bdfa3ea1e25',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/12fd93fe-f6df-4222-a26e-dab73c6fceef/ugc/0abadd3c-1876-4b06-8b67-b58f874102d1/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0fc17d4c-6a4a-4b00-a43b-e86dae03d84a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-17T01:13:37.093Z',

          createdAt: '2024-07-15T01:47:45.886Z',
          content: [
            {
              caption: '',

              id: '01f6f75c-ac64-4ee0-83fe-f32c49e4726a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/12fd93fe-f6df-4222-a26e-dab73c6fceef/ugc/0fc17d4c-6a4a-4b00-a43b-e86dae03d84a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f0bacab3-3ac2-4258-bc7d-0ecde123e102',
        firstName: 'Lindsay',
        lastName: 'Powell',
        email: 'oateslindsay58@gmail.com',
        instagramProfile: {
          handle: 'luxuria.a.a',
          followers: 4079,
          engagementRate: 0.100514832066683
        },
        tiktokProfile: {
          handle: '__.lindsayyy',
          followers: 1325,
          engagementRate: 11.373390557939913
        },
        youtubeProfile: {
          handle: 'lindsayo4107',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T17:11:56.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '08ed24d5-e3ca-4a51-b3bb-d91807fdcb09',
      content: [
        {
          groupId: '704841cc-2eda-4088-9af6-9432b43cea10',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-07-04T21:20:45.121Z',

          createdAt: '2024-07-03T23:15:43.582Z',
          content: [
            {
              caption: '',

              id: 'e5fbe5e9-3aaa-499f-b2c3-3285e0645d5e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/08ed24d5-e3ca-4a51-b3bb-d91807fdcb09/ugc/704841cc-2eda-4088-9af6-9432b43cea10/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '154b1b1d-07aa-46e5-a2d2-e1323eafee83',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/08ed24d5-e3ca-4a51-b3bb-d91807fdcb09/ugc/704841cc-2eda-4088-9af6-9432b43cea10/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '21036b44-ac01-4f5c-a8d7-b94528d685db',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/08ed24d5-e3ca-4a51-b3bb-d91807fdcb09/ugc/704841cc-2eda-4088-9af6-9432b43cea10/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a4e371b9-ced9-4563-a685-389d642d3eb2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T13:24:08.768Z',

          createdAt: '2024-07-03T23:14:13.266Z',
          content: [
            {
              caption: '',

              id: '78636894-d0d0-4420-b1c7-15e3d8297381',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/08ed24d5-e3ca-4a51-b3bb-d91807fdcb09/ugc/a4e371b9-ced9-4563-a685-389d642d3eb2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '081bdd72-9e74-44f2-8c58-67000f413910',
        firstName: 'Susan',
        lastName: 'Baker',
        email: 'sbcents74@hotmail.com',
        instagramProfile: {
          handle: '_susan.d.baker_',
          followers: 3178,
          engagementRate: 0.1132787916928886
        },
        tiktokProfile: {
          handle: 'doingitonawhim',
          followers: 49
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T17:22:38.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0fea1eb1-f517-4343-b28d-05d927ea86cf',
      content: [],
      creator: {
        id: '3c194921-9835-4350-bd5e-34a85cf91010',
        firstName: 'Stenia',
        lastName: 'Martin',
        email: 'steniamartin12@gmail.com',

        tiktokProfile: {
          handle: '_.stenia._',
          followers: 1100,
          engagementRate: 18.960607498813477
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T17:22:49.000Z',
        amountCents: 4172
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '82ac60cf-ffea-4ec5-aadf-1b0780c747c2',
      content: [],
      creator: {
        id: '2dc4e13f-2373-4718-9555-9a9873db60c2',
        firstName: 'Kalyn',
        lastName: 'Lagle',
        email: 'kalynlagle@gmail.com',

        tiktokProfile: {
          handle: 'kalynlagle',
          followers: 2273,
          engagementRate: 12.446958981612447
        },
        youtubeProfile: {
          handle: 'kalynlagle8721',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T17:23:44.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '164fe8d6-68d9-469e-8c42-423719a69a45',
      content: [
        {
          groupId: '038efedb-a8e8-4a94-a710-2c4edaf70c47',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-13T16:35:16.039Z',

          createdAt: '2024-07-12T01:05:42.152Z',
          content: [
            {
              caption: '',

              id: '8c1040fd-3a23-445a-80f1-53d69c07ca61',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/164fe8d6-68d9-469e-8c42-423719a69a45/ugc/038efedb-a8e8-4a94-a710-2c4edaf70c47/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'e259086b-c1e2-45f9-b024-7bd36bff9e1d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-13T16:55:00.427Z',

          createdAt: '2024-07-12T17:58:29.510Z',
          content: [
            {
              caption: '',

              id: '3a1f7dae-b999-4f37-a035-81cbf4fb3a3e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/164fe8d6-68d9-469e-8c42-423719a69a45/ugc/e259086b-c1e2-45f9-b024-7bd36bff9e1d/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '53f7831b-8523-48d8-8eb6-637780d1bb41',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/164fe8d6-68d9-469e-8c42-423719a69a45/ugc/e259086b-c1e2-45f9-b024-7bd36bff9e1d/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ea47ca46-e1fc-4c94-bda7-41d15d0da4c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/164fe8d6-68d9-469e-8c42-423719a69a45/ugc/e259086b-c1e2-45f9-b024-7bd36bff9e1d/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6a1efaa7-3557-44ef-9f3f-d07a53da3ff6',
        firstName: 'Angelee',
        lastName: 'Morrison',
        email: 'theonlyangelee@gmail.com',
        instagramProfile: {
          handle: 'theeangelee',
          followers: 1234
        },
        tiktokProfile: {
          handle: 'theeangelee',
          followers: 347
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T17:54:31.000Z',
        amountCents: 8531
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5b11564e-7025-484e-b976-8fed06c04edf',
      content: [],
      creator: {
        id: 'dc6c97a1-aa3e-4e51-8d1b-5a96ddab9af2',
        firstName: 'Chynna',
        lastName: 'Carter',
        email: 'chynna93xo@gmail.com',
        instagramProfile: {
          handle: 'pinkxspartan',
          followers: 3330,
          engagementRate: 0.5975975975975976
        },
        tiktokProfile: {
          handle: 'pinkxspartan',
          followers: 15816,
          engagementRate: 9.068627450980392
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T18:07:32.000Z',
        amountCents: 7443
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd9bf8394-71d9-47bb-be90-1049bf4a180b',
      content: [],
      creator: {
        id: '72b2de61-b66f-4817-a73c-301a219883ce',
        firstName: 'Anee',
        lastName: 'Kaur',
        email: 'rkittaney@yahoo.com',
        instagramProfile: {
          handle: 'lifeofpeeku',
          followers: 49219,
          engagementRate: 0.5871716207155774
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T18:08:54.000Z',
        amountCents: 7506
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9b080f7f-a224-4e03-b027-0e2542fd10f5',
      content: [],
      creator: {
        id: '2341063e-78c4-441c-b59b-0d702b6a5707',
        firstName: 'Ashley',
        lastName: 'Roberts',
        email: 'robertsashleym9@gmail.com',

        tiktokProfile: {
          handle: 'ashathickthick',
          followers: 768,
          engagementRate: 12.590488589068386
        },
        youtubeProfile: {
          handle: 'ashaprettyeyes619',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T18:32:48.000Z',
        amountCents: 8531
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'eb471d2a-5824-4f2f-b9cf-4b9fece3bfc2',
      content: [
        {
          groupId: '7bfa2da1-5d54-4577-8a41-672edabf4e27',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T21:22:02.229Z',

          createdAt: '2024-07-02T03:03:43.122Z',
          content: [
            {
              caption: '',

              id: 'a37eb8f4-9855-480f-9a6a-157e712e0ee6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eb471d2a-5824-4f2f-b9cf-4b9fece3bfc2/ugc/7bfa2da1-5d54-4577-8a41-672edabf4e27/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'c86959ca-20d8-4f53-a5f4-bc02651d8637',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-03T21:22:07.747Z',

          createdAt: '2024-06-28T16:54:13.898Z',
          content: [
            {
              caption: '',

              id: 'ba5c263b-8c86-4d14-a960-488a0cb36756',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eb471d2a-5824-4f2f-b9cf-4b9fece3bfc2/ugc/c86959ca-20d8-4f53-a5f4-bc02651d8637/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'afb62b9e-7140-40e1-b3f0-376f7f301ab1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eb471d2a-5824-4f2f-b9cf-4b9fece3bfc2/ugc/c86959ca-20d8-4f53-a5f4-bc02651d8637/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '15545811-70d5-4b42-9c41-6fa0a31390c2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/eb471d2a-5824-4f2f-b9cf-4b9fece3bfc2/ugc/c86959ca-20d8-4f53-a5f4-bc02651d8637/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'eeab1359-166f-4998-976a-8124530d84a6',
        firstName: 'Yasmin',
        lastName: 'Al Abidi',
        email: 'ummsade@yahoo.com',

        tiktokProfile: {
          handle: 'ummysade',
          followers: 4540,
          engagementRate: 1.1441468204848906
        },
        youtubeProfile: {
          handle: 'mokha3611',
          followers: 1670
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T18:44:07.000Z',
        amountCents: 7699
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8baa54a2-a01b-495d-adec-9828ffaa9120',
      content: [
        {
          groupId: 'b24c5cd3-a3d5-4361-a302-9cafd056c30b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T14:53:58.416Z',

          createdAt: '2024-06-30T23:36:05.553Z',
          content: [
            {
              caption: '',

              id: '641490ab-70d6-439e-ac01-3d55119c7b08',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8baa54a2-a01b-495d-adec-9828ffaa9120/ugc/b24c5cd3-a3d5-4361-a302-9cafd056c30b/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a471f9e1-76c3-4665-9210-36d84c85c694',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T06:13:23.364Z',

          createdAt: '2024-07-01T01:19:00.022Z',
          content: [
            {
              caption: '',

              id: '29348ee2-628d-488e-884a-c4a0dae8b35d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/8baa54a2-a01b-495d-adec-9828ffaa9120/ugc/a471f9e1-76c3-4665-9210-36d84c85c694/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cd7d2f0c-4019-46d0-9ae7-d3e6acfa94db',
        firstName: 'Hanna',
        lastName: 'Wagner',
        email: 'hannawagner979@gmail.com',
        instagramProfile: {
          handle: 'high__hanna',
          followers: 245,
          engagementRate: 6.9164265129683
        },
        tiktokProfile: {
          handle: 'high_hanna',
          followers: 1592
        },
        youtubeProfile: {
          handle: 'hannawagner5377',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T19:16:11.000Z',
        amountCents: 8024
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'bf03113a-a313-4bcc-8183-83fdf6ff1444',
      content: [
        {
          groupId: '14bf5691-547c-4642-a3ff-2def354c5753',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-09T16:17:36.439Z',

          createdAt: '2024-07-03T02:58:19.552Z',
          content: [
            {
              caption: '',

              id: 'e614c4cc-a5ad-422c-9f86-fab89e7f5365',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bf03113a-a313-4bcc-8183-83fdf6ff1444/ugc/14bf5691-547c-4642-a3ff-2def354c5753/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ccad4241-4817-445d-8d49-135b88c4c34f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bf03113a-a313-4bcc-8183-83fdf6ff1444/ugc/14bf5691-547c-4642-a3ff-2def354c5753/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'afe04f4f-f261-442d-b311-849c623fe531',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bf03113a-a313-4bcc-8183-83fdf6ff1444/ugc/14bf5691-547c-4642-a3ff-2def354c5753/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '52ec5ea4-c38a-43ed-9e0f-ee2091da86fb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T16:17:31.445Z',

          createdAt: '2024-07-03T20:43:13.330Z',
          content: [
            {
              caption: '',

              id: '18ace9c3-d24f-4286-a3f6-42b4fc3963d1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/bf03113a-a313-4bcc-8183-83fdf6ff1444/ugc/52ec5ea4-c38a-43ed-9e0f-ee2091da86fb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e1c1c7fd-2a8e-499b-a2a3-2e2026f76ad5',
        firstName: 'Amanda Rose',
        lastName: 'Carter',
        email: 'amandatip56@gmail.com',
        instagramProfile: {
          handle: 'queen_amanda_rose',
          followers: 1815,
          engagementRate: 1.581267217630854
        },
        tiktokProfile: {
          handle: 'amandarosecarter',
          followers: 1445,
          engagementRate: 6.793424440483264
        },
        youtubeProfile: {
          handle: 'amandatipaldo9934',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T19:22:43.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3c15d8f4-9d24-4588-8406-05e63bfea8e5',
      content: [
        {
          groupId: '9ec56f4e-522b-4d4e-ab4e-30347fd712f7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-12T17:47:17.215Z',

          createdAt: '2024-07-01T01:07:32.038Z',
          content: [
            {
              caption: '',

              id: 'f285ec70-d920-41a6-9392-b6f4fce05ecc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3c15d8f4-9d24-4588-8406-05e63bfea8e5/ugc/9ec56f4e-522b-4d4e-ab4e-30347fd712f7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ccd4a63a-4108-4824-8c0b-92543d5e5e99',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-12T17:47:10.284Z',

          createdAt: '2024-06-29T13:39:09.546Z',
          content: [
            {
              caption: '',

              id: '43ac9824-bf55-4d22-bb85-358ab56ddbd4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/3c15d8f4-9d24-4588-8406-05e63bfea8e5/ugc/ccd4a63a-4108-4824-8c0b-92543d5e5e99/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '105868a9-7ec6-48c9-857a-f719fb6dcf04',
        firstName: 'Yoisleny',
        lastName: 'Vazquez ',
        email: 'yoislenyvc@gmail.com',

        tiktokProfile: {
          handle: 'yoislenyvc',
          followers: 23,
          engagementRate: 16.666666666666664
        },
        youtubeProfile: {
          handle: 'user-fw4gn8ow7c',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T19:32:45.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9b4232cd-a652-407e-ac8d-6ad9d4e67c28',
      content: [],
      creator: {
        id: '6da6958b-65d0-42c6-8d82-841ed993e9d2',
        firstName: 'Tayler',
        lastName: 'Shaver',
        email: 'taylershaverbusiness@gmail.com',
        instagramProfile: {
          handle: 'taylershaverr',
          followers: 1049,
          engagementRate: 1.1534795042897998
        },
        tiktokProfile: {
          handle: 'taylershaver',
          followers: 4687,
          engagementRate: 4.098360655737705
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T19:47:58.000Z',
        amountCents: 7699
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a1658a0c-dd0e-4244-94fd-37e53e24d6b1',
      content: [],
      creator: {
        id: 'b014126c-8a62-44b5-b09c-97c74b764834',
        firstName: 'Larissa',
        lastName: 'Rojas',
        email: 'rojaslarissa49@gmail.com',
        instagramProfile: {
          handle: 'larissaarojass',
          followers: 1260
        },
        tiktokProfile: {
          handle: 'young_larissa',
          followers: 10339,
          engagementRate: 23.90640895218718
        },
        youtubeProfile: {
          handle: 'larissamerino',
          followers: 184
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T20:02:19.000Z',
        amountCents: 7646
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '461c635b-e21d-4ad9-a9b3-e73c08b0b163',
      content: [
        {
          groupId: 'aa729375-6634-48cc-b71a-c2372c5d341a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-10T15:33:31.210Z',

          createdAt: '2024-07-09T21:07:30.078Z',
          content: [
            {
              caption: '',

              id: 'bd2a89ec-bc13-4b2e-94c2-16032f69ea8a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/461c635b-e21d-4ad9-a9b3-e73c08b0b163/ugc/aa729375-6634-48cc-b71a-c2372c5d341a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'bc3dd592-a964-440d-910b-d6750658cf88',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-10T15:33:49.114Z',

          createdAt: '2024-07-09T21:36:17.396Z',
          content: [
            {
              caption: '',

              id: '5359965b-e69e-48ec-8807-ce43c7083ad1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/461c635b-e21d-4ad9-a9b3-e73c08b0b163/ugc/bc3dd592-a964-440d-910b-d6750658cf88/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'efb881d2-f1a6-421c-b10c-d982fd1c6c6f',
        firstName: 'Ashley',
        lastName: 'Dibbini',
        email: 'ashleyxg@gmail.com',
        instagramProfile: {
          handle: 'cuteashreview',
          followers: 2106,
          engagementRate: 7.929724596391264
        },
        tiktokProfile: {
          handle: 'cuteashreview',
          followers: 953,
          engagementRate: 11.363636363636363
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T20:34:27.000Z',
        amountCents: 7545
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e77d67c0-49a3-404f-92a6-28207d78b9e0',
      content: [],
      creator: {
        id: 'ed974050-96a6-4fd2-9bcb-4a117524163c',
        firstName: 'Grayson',
        lastName: 'King',
        email: 'graysonkingphoto@gmail.com',
        instagramProfile: {
          handle: 'graysoneking',
          followers: 20333,
          engagementRate: 0.6968966704372203
        },
        tiktokProfile: {
          handle: 'graysoneking',
          followers: 3787
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T21:42:48.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1ed101f7-0f2c-409b-9b5e-fb1f77f44a96',
      content: [],
      creator: {
        id: '46ea828a-5986-436b-af71-106e2eea25e9',
        firstName: 'Chris',
        lastName: 'Schlumbrecht',
        email: 'christopherSchlumbrecht@gmail.com',
        instagramProfile: {
          handle: 'chriscoin0052',
          followers: 2,
          engagementRate: 20
        },
        tiktokProfile: {
          handle: 'chriscoin0052',
          followers: 19
        },
        youtubeProfile: {
          handle: 'chriscoin0052'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T23:00:31.000Z',
        amountCents: 7660
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0d10462a-cb24-4f10-bf79-f0bb9fb57e20',
      content: [
        {
          groupId: '10d8d635-0a5e-407d-a4ef-ecbe29c9a451',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T12:35:00.792Z',

          createdAt: '2024-07-03T22:03:00.578Z',
          content: [
            {
              caption: '',

              id: 'a91c8446-0fa3-44bf-bc83-f07075869f2b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0d10462a-cb24-4f10-bf79-f0bb9fb57e20/ugc/10d8d635-0a5e-407d-a4ef-ecbe29c9a451/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '79e6602e-8a82-447c-b16a-760efae807b0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T12:24:58.402Z',

          createdAt: '2024-07-02T17:06:47.067Z',
          content: [
            {
              caption: '',

              id: 'aad6566b-7171-4dd0-a3a4-d21d7c4d9c69',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0d10462a-cb24-4f10-bf79-f0bb9fb57e20/ugc/79e6602e-8a82-447c-b16a-760efae807b0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'bb0df4ee-a84b-442c-9de7-7c69d394e8f3',
        firstName: 'Kristie',
        lastName: 'Delaney',
        email: 'ksdelaney@gmail.com',
        instagramProfile: {
          handle: 'ksdelaney1974',
          followers: 75
        },
        tiktokProfile: {
          handle: 'ksdelcuftdd',
          followers: 32
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-26T23:25:00.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ad2340a4-9ec3-44d0-b881-c13c3160fd96',
      content: [
        {
          groupId: 'fd3ac97c-afa1-4f13-a294-5c37d1330058',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-08T22:51:27.232Z',

          createdAt: '2024-06-30T22:03:39.709Z',
          content: [
            {
              caption: '',

              id: '500e4ddc-c6c2-4bdf-b059-41568f04039a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad2340a4-9ec3-44d0-b881-c13c3160fd96/ugc/fd3ac97c-afa1-4f13-a294-5c37d1330058/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4acff4cc-b2d2-4fab-931a-a2982a64cfd8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad2340a4-9ec3-44d0-b881-c13c3160fd96/ugc/fd3ac97c-afa1-4f13-a294-5c37d1330058/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd82e8d38-c03c-4633-9472-6d375e2c2937',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad2340a4-9ec3-44d0-b881-c13c3160fd96/ugc/fd3ac97c-afa1-4f13-a294-5c37d1330058/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd0362c51-374e-4aae-ab21-a116c82d8ce1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T22:51:21.605Z',

          createdAt: '2024-07-04T23:26:13.143Z',
          content: [
            {
              caption: '',

              id: '5beef72f-e240-4c0c-b84b-bb791e6b24cc',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ad2340a4-9ec3-44d0-b881-c13c3160fd96/ugc/d0362c51-374e-4aae-ab21-a116c82d8ce1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'baab3526-b05a-435b-9333-5d5ae8fa7fe6',
        firstName: 'nelly',
        lastName: 'tizon',
        email: 'nelly.detizon@gmail.com',
        instagramProfile: {
          handle: 'dianatizon',
          followers: 8337,
          engagementRate: 0.08996041741633681
        },
        tiktokProfile: {
          handle: 'tizondianita',
          followers: 547,
          engagementRate: 10.304509175944284
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T00:03:34.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '720b2e87-808d-472b-9435-4e9a3943257b',
      content: [],
      creator: {
        id: 'b886c84b-4d42-4f09-b550-c6633c900cfa',
        firstName: 'Kim',
        lastName: 'Cerqueira',
        email: 'kimberlycerqueira@gmail.com',
        instagramProfile: {
          handle: 'kimcerqueira',
          followers: 4970
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T00:14:13.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0fbddb97-be6b-4561-bbc5-5bbd835454a5',
      content: [
        {
          groupId: '15778172-0809-4ed3-bf1a-1cb90112e38e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-04T21:03:06.258Z',

          createdAt: '2024-07-03T22:12:15.196Z',
          content: [
            {
              caption: '',

              id: '94bf38d4-e813-4f6e-a5a2-72936758fa02',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0fbddb97-be6b-4561-bbc5-5bbd835454a5/ugc/15778172-0809-4ed3-bf1a-1cb90112e38e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '221b4cbb-0fcc-4bda-ab7e-bcadc1999c55',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0fbddb97-be6b-4561-bbc5-5bbd835454a5/ugc/15778172-0809-4ed3-bf1a-1cb90112e38e/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '10310a35-66ad-40fe-9559-05caca18a76b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0fbddb97-be6b-4561-bbc5-5bbd835454a5/ugc/15778172-0809-4ed3-bf1a-1cb90112e38e/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0735802b-e9d0-423c-a70d-b1bdcfbbe616',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T12:57:38.925Z',

          createdAt: '2024-07-04T05:29:07.998Z',
          content: [
            {
              caption: '',

              id: 'd7f25223-9d3b-476b-b167-69e043b6fa5e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0fbddb97-be6b-4561-bbc5-5bbd835454a5/ugc/0735802b-e9d0-423c-a70d-b1bdcfbbe616/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5eb70da1-4f92-416f-80b7-cfb2c25c72bf',
        firstName: 'Nia',
        lastName: 'Kirkwood',
        email: 'niakirkwood19@gmail.com',
        instagramProfile: {
          handle: 'mrs.nkirkwood',
          followers: 1115,
          engagementRate: 2.304932735426009
        },
        tiktokProfile: {
          handle: 'niatatiana10',
          followers: 91,
          engagementRate: 9.529352226720647
        },
        youtubeProfile: {
          handle: 'niakelly9528',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T01:16:15.000Z',
        amountCents: 8177
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c78f5ae6-02d2-44ad-bb50-05b690f956c6',
      content: [
        {
          groupId: '30fc9f66-61a2-449f-a90e-ecbbb5dab61c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-29T15:14:57.277Z',

          createdAt: '2024-07-20T01:25:41.740Z',
          content: [
            {
              caption: '',

              id: '3f939fe9-ca6b-4850-b597-55f027abdd52',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c78f5ae6-02d2-44ad-bb50-05b690f956c6/ugc/30fc9f66-61a2-449f-a90e-ecbbb5dab61c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6467f9cf-e5ea-4965-97fb-63a7e45f50ba',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c78f5ae6-02d2-44ad-bb50-05b690f956c6/ugc/30fc9f66-61a2-449f-a90e-ecbbb5dab61c/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '41534344-f694-491b-8652-7e3b1cd7be8d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c78f5ae6-02d2-44ad-bb50-05b690f956c6/ugc/30fc9f66-61a2-449f-a90e-ecbbb5dab61c/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '036723d2-7d49-422f-ad1f-d8a4c006c804',
        firstName: 'BarbzUGC',
        lastName: 'Creator',
        email: 'efitima93@gmail.com',
        instagramProfile: {
          handle: 'barbie._tingzja',
          followers: 284,
          engagementRate: 0.7394366197183099
        },
        tiktokProfile: {
          handle: 'barbie._ugccreator',
          followers: 977,
          engagementRate: 13.924050632911392
        },
        youtubeProfile: {
          handle: 'barbzugccreator',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T01:32:36.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1f9b36c4-0bb7-42a1-b1b3-aae9bf80e83d',
      content: [
        {
          groupId: '28a8b992-d26e-46d6-b5cf-3326c1266454',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-02T18:17:22.665Z',

          createdAt: '2024-07-20T16:45:02.288Z',
          content: [
            {
              caption: '',

              id: '097415fa-ead7-4f4c-92c8-394b177212ea',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1f9b36c4-0bb7-42a1-b1b3-aae9bf80e83d/ugc/28a8b992-d26e-46d6-b5cf-3326c1266454/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e628229f-ef75-46d5-8b16-40b1d1f53871',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1f9b36c4-0bb7-42a1-b1b3-aae9bf80e83d/ugc/28a8b992-d26e-46d6-b5cf-3326c1266454/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7b231ffa-6df4-4428-a777-1c10384d7652',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1f9b36c4-0bb7-42a1-b1b3-aae9bf80e83d/ugc/28a8b992-d26e-46d6-b5cf-3326c1266454/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd9357789-1ec9-471c-8dc1-3d72c4a39c72',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-02T18:17:09.872Z',

          createdAt: '2024-07-20T16:44:09.618Z',
          content: [
            {
              caption: '',

              id: 'de998373-ca74-4215-97f7-440a9aafa617',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/1f9b36c4-0bb7-42a1-b1b3-aae9bf80e83d/ugc/d9357789-1ec9-471c-8dc1-3d72c4a39c72/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '973e8d51-7b9f-4458-a363-b07116d4b844',
        firstName: 'MaLea',
        lastName: 'Ergle',
        email: 'mergle@wcsclass.com',
        instagramProfile: {
          handle: 'lifeonbrushycreek',
          followers: 34578,
          engagementRate: 2.697379836890508
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T03:03:52.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8c2c624f-2929-48ec-a4e8-d5cd79d55824',
      content: [],
      creator: {
        id: '65596ef9-3eb4-4ec7-8519-92a888893159',
        firstName: 'Niko',
        lastName: 'Kots',
        email: 'kotsniko17@gmail.com',

        tiktokProfile: {
          handle: 'niko.kotsailidis',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T05:12:21.000Z',
        amountCents: 12500
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a722241e-faf7-4191-a0a0-cb2ed346cfa5',
      content: [],
      creator: {
        id: '75a6ce02-fe47-4fbe-aa8a-cde96fef202f',
        firstName: 'Kots',
        lastName: 'Niko',
        email: 'kotsniko26@gmail.com',

        tiktokProfile: {
          handle: 'kotsnobh6bf',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T05:42:29.000Z',
        amountCents: 12500
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f83050c4-9d02-4566-8b93-04e4970e3145',
      content: [],
      creator: {
        id: '19ef0d46-fc5f-496c-9b8c-4606f5ec8778',
        firstName: 'Roxie',
        lastName: 'Murray',
        email: 'murrayp60254@gmail.com',
        instagramProfile: {
          handle: 'roxiefincher',
          followers: 272,
          engagementRate: 0
        },
        tiktokProfile: {
          handle: 'electricrainbow20',
          followers: 110,
          engagementRate: 7.142857142857142
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T07:45:54.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a081b55c-5df6-4dd4-a214-658904a88f3b',
      content: [
        {
          groupId: '548d02ed-8285-4f3d-b994-12ef213a7604',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-21T11:02:58.693Z',

          createdAt: '2024-07-15T00:20:22.853Z',
          content: [
            {
              caption: '',

              id: '3adeee3c-8b19-48eb-915e-99afad681918',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a081b55c-5df6-4dd4-a214-658904a88f3b/ugc/548d02ed-8285-4f3d-b994-12ef213a7604/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ad0fd6f6-8df2-435a-a74b-3a2440b28e45',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a081b55c-5df6-4dd4-a214-658904a88f3b/ugc/548d02ed-8285-4f3d-b994-12ef213a7604/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2273c525-450f-44c0-a05b-234a43b05c87',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-21T11:02:52.342Z',

          createdAt: '2024-07-15T00:19:17.514Z',
          content: [
            {
              caption: '',

              id: 'ab6d2b3a-6cc5-4e7e-88e9-75d2935a0199',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a081b55c-5df6-4dd4-a214-658904a88f3b/ugc/2273c525-450f-44c0-a05b-234a43b05c87/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '325bf5a8-8939-4459-b38d-66ff609a05a7',
        firstName: 'Tara',
        lastName: 'Duncan',
        email: 'liftlikemommy@gmail.com',
        instagramProfile: {
          handle: 'liftlikemommy',
          followers: 8047,
          engagementRate: 1.7087113209891887
        },
        tiktokProfile: {
          handle: 'liftlikemommy',
          followers: 949,
          engagementRate: 2.517162471395881
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T14:12:13.000Z',
        amountCents: 7454
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '971dbd44-87e1-43ed-ad87-2bc99e03a370',
      content: [
        {
          groupId: '243903d2-4e6e-4941-91b3-37255b3643ac',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-11T13:50:21.850Z',

          createdAt: '2024-07-10T17:16:19.696Z',
          content: [
            {
              caption: '',

              id: 'e9e8e1ea-b611-4bca-9308-9ff3afd03e99',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/971dbd44-87e1-43ed-ad87-2bc99e03a370/ugc/243903d2-4e6e-4941-91b3-37255b3643ac/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b5101210-48b1-4c60-8617-a520b30628b0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/971dbd44-87e1-43ed-ad87-2bc99e03a370/ugc/243903d2-4e6e-4941-91b3-37255b3643ac/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2c447d34-b8d6-4946-bb53-be0121acb913',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/971dbd44-87e1-43ed-ad87-2bc99e03a370/ugc/243903d2-4e6e-4941-91b3-37255b3643ac/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '94b28d4a-19d8-44fd-98dc-329dd5cd805a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-11T13:50:05.359Z',

          createdAt: '2024-07-10T17:14:23.775Z',
          content: [
            {
              caption: '',

              id: 'dbef09d4-1f1d-4499-b5f8-ebc9fa5e5946',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/971dbd44-87e1-43ed-ad87-2bc99e03a370/ugc/94b28d4a-19d8-44fd-98dc-329dd5cd805a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '66b306f1-6b46-4a25-a5fc-5362d0891d0e',
        firstName: 'Tiffany',
        lastName: 'Johnson',
        email: 'tiffanypen74@gmail.com',
        instagramProfile: {
          handle: 'ts_sparkling_coochie_clenz',
          followers: 168,
          engagementRate: 3.7426900584795324
        },
        tiktokProfile: {
          handle: 'tiffanynation70',
          followers: 1761,
          engagementRate: 8.11552375919678
        },
        youtubeProfile: {
          handle: 'tiffanynation424',
          followers: 700
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:21:27.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7de49528-ba7b-459a-b217-c75a1adf474c',
      content: [
        {
          groupId: '0213a4fb-70fe-49bd-838a-c689ab84416e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-03T10:51:24.858Z',

          createdAt: '2024-07-01T20:37:09.023Z',
          content: [
            {
              caption: '',

              id: 'c812dca9-50f3-4247-9398-fe316c2ccda6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7de49528-ba7b-459a-b217-c75a1adf474c/ugc/0213a4fb-70fe-49bd-838a-c689ab84416e/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '0f2c9f4b-e07e-4725-99e2-e2e4cffdd8fa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7de49528-ba7b-459a-b217-c75a1adf474c/ugc/0213a4fb-70fe-49bd-838a-c689ab84416e/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6afabf3f-787b-48c9-a177-9fa10ec04444',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7de49528-ba7b-459a-b217-c75a1adf474c/ugc/0213a4fb-70fe-49bd-838a-c689ab84416e/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '38dde065-b1ee-41a8-a9a4-6a32701454e2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T10:51:19.602Z',

          createdAt: '2024-07-01T20:33:40.610Z',
          content: [
            {
              caption: '',

              id: '3b9b00a8-a16d-4e21-a9de-bdfe4db542a0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7de49528-ba7b-459a-b217-c75a1adf474c/ugc/38dde065-b1ee-41a8-a9a4-6a32701454e2/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '9a2f6385-b3d4-4056-88fb-937670392e91',
        firstName: 'Emily',
        lastName: 'Ryan',
        email: 'emilyryan1197@icloud.com',
        instagramProfile: {
          handle: 'emilydryan',
          followers: 729
        },
        tiktokProfile: {
          handle: 'emmyryan77',
          followers: 65
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:26:26.000Z',
        amountCents: 7699
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3988e02d-115b-4b26-b275-6d12101d80df',
      content: [],
      creator: {
        id: '26a4edf3-61c7-40ef-8914-d2316e8c932b',
        firstName: 'Atinuke',
        lastName: 'Milner',
        email: 'kaziahmilner@gmail.com',
        instagramProfile: {
          handle: 'kaziahmilner',
          followers: 10131,
          engagementRate: 1.36018162076794
        },
        tiktokProfile: {
          handle: 'kaziah.milner',
          followers: 26,
          engagementRate: 0.3575685339690107
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:33:03.000Z',
        amountCents: 11662
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6759e09a-8e42-4b58-a7d4-222bab23fe5c',
      content: [
        {
          groupId: '41ba2492-9a40-458d-a723-199b3d94d43a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-04T14:26:33.369Z',

          createdAt: '2024-07-01T16:17:30.882Z',
          content: [
            {
              caption: '',

              id: '954cd8aa-5e78-4e05-be7f-e05c03fd35d9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6759e09a-8e42-4b58-a7d4-222bab23fe5c/ugc/41ba2492-9a40-458d-a723-199b3d94d43a/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f3ba0b1d-a02a-45ef-b7c1-13909ccb325e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6759e09a-8e42-4b58-a7d4-222bab23fe5c/ugc/41ba2492-9a40-458d-a723-199b3d94d43a/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '444705b6-bfc4-4a01-84ab-c582b70656a3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6759e09a-8e42-4b58-a7d4-222bab23fe5c/ugc/41ba2492-9a40-458d-a723-199b3d94d43a/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'f917ba96-7b49-4b98-9c83-dc5aa4db5075',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T03:57:14.501Z',

          createdAt: '2024-07-03T04:59:32.724Z',
          content: [
            {
              caption: '',

              id: '0ac39f25-dab0-4f45-b9a0-311e838674bd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6759e09a-8e42-4b58-a7d4-222bab23fe5c/ugc/f917ba96-7b49-4b98-9c83-dc5aa4db5075/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '60fd02f8-5d3d-4e08-92c5-7b8095f2fa50',
        firstName: 'John',
        lastName: 'Jones',
        email: 'bishop57sp@gmail.com',
        instagramProfile: {
          handle: 'bishop_57_studio_productions',
          followers: 12528,
          engagementRate: 0.4980842911877395
        },
        tiktokProfile: {
          handle: 'bishoptv',
          followers: 538
        },
        youtubeProfile: {
          handle: 'bishoptunnelvisionvlogs',
          followers: 2890
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:33:52.000Z',
        amountCents: 7601
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'afe2c877-1da0-4e4b-8366-b00eafed332f',
      content: [
        {
          groupId: 'e98c4d1c-7dd6-4436-bd39-7e089a7d7374',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T21:21:05.950Z',

          createdAt: '2024-07-04T02:01:20.256Z',
          content: [
            {
              caption: '',

              id: '855e0831-1e47-4311-a811-86d61c8af28a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/afe2c877-1da0-4e4b-8366-b00eafed332f/ugc/e98c4d1c-7dd6-4436-bd39-7e089a7d7374/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '132eea90-78da-47ae-9f0b-c3a0ec2d443f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-05T14:35:18.551Z',

          createdAt: '2024-07-04T02:06:51.186Z',
          content: [
            {
              caption: '',

              id: 'c18cd569-4807-4560-accf-7cf8ca3a90b2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/afe2c877-1da0-4e4b-8366-b00eafed332f/ugc/132eea90-78da-47ae-9f0b-c3a0ec2d443f/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd1ed1a6e-a380-41ca-8a43-855649c081a1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/afe2c877-1da0-4e4b-8366-b00eafed332f/ugc/132eea90-78da-47ae-9f0b-c3a0ec2d443f/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1ce6dec2-a5f8-49d9-8bfd-05a103c59b0b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/afe2c877-1da0-4e4b-8366-b00eafed332f/ugc/132eea90-78da-47ae-9f0b-c3a0ec2d443f/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5fcf206b-47e0-4b6f-8acb-a765371cc302',
        firstName: 'Karen',
        lastName: 'Hewitt',
        email: 'karen@blossomtosuccess.com',
        instagramProfile: {
          handle: 'blossomtosuccess',
          followers: 5378,
          engagementRate: 0.07623651915210115
        },
        tiktokProfile: {
          handle: 'blossom.tosuccess',
          followers: 5889,
          engagementRate: 4.411764705882353
        },
        youtubeProfile: {
          handle: 'blossomtosuccess'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:44:44.000Z',
        amountCents: 6510
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '490bd078-6dfb-4ecd-a00e-62b886a6b676',
      content: [],
      creator: {
        id: '1b62253d-c9ae-42dc-bc15-ffec1cc773eb',
        firstName: 'Cassie',
        lastName: 'Copeland',
        email: 'copeland_cassie30@yahoo.com',
        instagramProfile: {
          handle: 'cassies_books_reviews',
          followers: 25943,
          engagementRate: 0.03388408304498271
        },
        tiktokProfile: {
          handle: 'cassies_books_reviews',
          followers: 26,
          engagementRate: 0.37234042553191493
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:45:01.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '54293d93-9da6-48f4-a8ba-b50ac9d6943f',
      content: [],
      creator: {
        id: '4c191bd5-bd6b-4011-8b15-d549b24ba52c',
        firstName: 'Reyanna',
        lastName: 'Antrobus',
        email: 'yanna.antrobus@gmail.com',
        instagramProfile: {
          handle: 'themilligurl',
          followers: 69,
          engagementRate: 6.521739130434782
        },
        tiktokProfile: {
          handle: '__.yannaa',
          followers: 58,
          engagementRate: 20
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:45:34.000Z',
        amountCents: 8697
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0c65905d-028a-468f-b1aa-0db450c72adc',
      content: [
        {
          groupId: '5737700f-6a95-4fbc-9344-3720652a7a89',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T15:19:32.129Z',

          createdAt: '2024-07-09T03:49:46.578Z',
          content: [
            {
              caption: '',

              id: '458cb0c0-3967-453b-869f-b89bfd250445',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c65905d-028a-468f-b1aa-0db450c72adc/ugc/5737700f-6a95-4fbc-9344-3720652a7a89/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '505b91de-66e1-40d0-bc5e-8892f16833e2',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-09T15:20:17.725Z',

          createdAt: '2024-07-09T03:35:16.936Z',
          content: [
            {
              caption: '',

              id: '2ea3a8cf-d406-48ca-bdf0-77135b74f931',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c65905d-028a-468f-b1aa-0db450c72adc/ugc/505b91de-66e1-40d0-bc5e-8892f16833e2/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'cf6b555b-cf71-4ffa-a274-2dfda2c7419f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0c65905d-028a-468f-b1aa-0db450c72adc/ugc/505b91de-66e1-40d0-bc5e-8892f16833e2/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'b50fced8-955d-44fb-b7f9-a8e66c1bcb11',
        firstName: 'Anika',
        lastName: 'Rattray',
        email: 'rattrayanika@outlook.com',
        instagramProfile: {
          handle: 'stylus.boutique',
          followers: 982,
          engagementRate: 0.24319066147859922
        },
        tiktokProfile: {
          handle: 'anika_sly',
          followers: 10172,
          engagementRate: 5.769230769230769
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T16:54:26.000Z',
        amountCents: 8488
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a85e712f-985b-4e9f-bb83-a6a93e16db2e',
      content: [],
      creator: {
        id: '5d082e9e-5352-41c6-8bc7-6678323775fd',
        firstName: 'Shaneika',
        lastName: 'Gordon',
        email: 'shaneikagordon3@gmail.com',

        tiktokProfile: {
          handle: 'shanfindit',
          followers: 1001,
          engagementRate: 4.118358346966356
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T17:07:12.000Z',
        amountCents: 21328
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2fb35616-5b35-4682-ac41-d5dd875106d2',
      content: [
        {
          groupId: 'e15fbf09-916c-4ff2-bfcb-c6033a8fe2b8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-04T22:42:36.607Z',

          createdAt: '2024-07-01T15:44:19.566Z',
          content: [
            {
              caption: '',

              id: '5add56cc-a730-4044-a7f0-2d0f3b2ca4f2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2fb35616-5b35-4682-ac41-d5dd875106d2/ugc/e15fbf09-916c-4ff2-bfcb-c6033a8fe2b8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2584d30d-8c2c-40b0-9f2f-3ba11cb84e94',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-04T22:42:25.686Z',

          createdAt: '2024-07-01T15:43:40.290Z',
          content: [
            {
              caption: '',

              id: '8bd9f900-ba75-4ad3-941a-ea6d4e5dad66',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2fb35616-5b35-4682-ac41-d5dd875106d2/ugc/2584d30d-8c2c-40b0-9f2f-3ba11cb84e94/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '116875e4-4e5b-4a6a-b4a2-bf93e645988c',
        firstName: 'Jessica',
        lastName: 'Torres',
        email: 'jessicaroseslp@gmail.com',
        instagramProfile: {
          handle: 'jessicarose_slp_',
          followers: 1141,
          engagementRate: 1.1831726555652935
        },
        tiktokProfile: {
          handle: 'jessicaroseslp',
          followers: 11041,
          engagementRate: 5.735692272242864
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T17:16:07.000Z',
        amountCents: 7620
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '69bc16ef-2d5c-4c76-919c-caf5af425480',
      content: [
        {
          groupId: '5b7434f7-a700-4c93-9aff-ab2cc9510222',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T20:03:34.876Z',

          createdAt: '2024-07-02T16:34:39.243Z',
          content: [
            {
              caption: '',

              id: '96458cef-f5ba-474a-8fed-bac2a20ebe81',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/69bc16ef-2d5c-4c76-919c-caf5af425480/ugc/5b7434f7-a700-4c93-9aff-ab2cc9510222/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '284035ec-6feb-4bea-8bc9-0fe9fada76e0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T20:03:39.479Z',

          createdAt: '2024-07-02T16:36:51.477Z',
          content: [
            {
              caption: '',

              id: 'b1e1c55d-1ca7-4a2c-80b4-c26bf589b3fd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/69bc16ef-2d5c-4c76-919c-caf5af425480/ugc/284035ec-6feb-4bea-8bc9-0fe9fada76e0/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '793b4ca5-8c10-4da4-a2fa-18f378f4a4f9',
        firstName: 'Rayssa',
        lastName: 'Squarcio',
        email: 'squarcioray@gmail.com',
        instagramProfile: {
          handle: 'raymomofthree',
          followers: 135,
          engagementRate: 5.481481481481482
        },
        tiktokProfile: {
          handle: 'thesiberianangel',
          followers: 6,
          engagementRate: 4
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T17:44:24.000Z',
        amountCents: 7463
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '38979c3f-5b68-4359-994e-d4a7d08d7d5c',
      content: [],
      creator: {
        id: '1a64968c-332c-41ac-a5a9-2890cd5eff47',
        firstName: 'Lamona',
        lastName: 'West',
        email: 'lamona_west@yahoo.com',

        tiktokProfile: {
          handle: 'lafia_mo',
          followers: 5019,
          engagementRate: 15.368835034013607
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T17:46:14.000Z',
        amountCents: 8758
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'e98d933e-318e-42ec-ba2b-76c4ab6fee36',
      content: [
        {
          groupId: 'b2750a64-a635-4082-91ad-2c43e2c6779e',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-17T19:41:13.063Z',

          createdAt: '2024-07-04T00:02:07.214Z',
          content: [
            {
              caption: '',

              id: '9d8ba7d8-cf30-442f-ba86-5c2562ab2567',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e98d933e-318e-42ec-ba2b-76c4ab6fee36/ugc/b2750a64-a635-4082-91ad-2c43e2c6779e/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b50fe8fd-a871-4ecd-9c07-74703b9be73c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-07T17:57:47.899Z',

          createdAt: '2024-07-04T00:02:34.774Z',
          content: [
            {
              caption: '',

              id: 'ed296323-f133-44ec-86da-63d350e75b5c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/e98d933e-318e-42ec-ba2b-76c4ab6fee36/ugc/b50fe8fd-a871-4ecd-9c07-74703b9be73c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f3ab33aa-89fd-4ae8-8c02-47427dbeef48',
        firstName: 'Brittany ',
        lastName: 'Peete',
        email: 'bpeete11@gmail.com',
        instagramProfile: {
          handle: 'wherebebe',
          followers: 11451,
          engagementRate: 2.935988123308008
        },
        tiktokProfile: {
          handle: 'wherebebe',
          followers: 7988,
          engagementRate: 10.425782650171483
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T17:54:00.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c8a8ef4f-d7dc-498a-9227-ad8898a1a1ea',
      content: [],
      creator: {
        id: '56af844c-14a7-4467-a3e1-cfb6bf61cc09',
        firstName: 'Lance',
        lastName: 'Mcmurrin',
        email: 'lmacky56@gmail.com',

        tiktokProfile: {
          handle: 'soccer101114',
          followers: 576
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T17:54:15.000Z',
        amountCents: 7524
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '28beb738-31f8-4fb2-8c03-c8934ce0286f',
      content: [
        {
          groupId: 'fc279c13-31e9-46d1-a4e0-d948bce951ae',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-05T03:46:30.553Z',

          createdAt: '2024-07-04T03:10:38.248Z',
          content: [
            {
              caption: '',

              id: '145927a4-b56d-406e-bb42-4dcc792fb70c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28beb738-31f8-4fb2-8c03-c8934ce0286f/ugc/fc279c13-31e9-46d1-a4e0-d948bce951ae/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f3a43385-e82b-4cea-bb45-3143d40ce62a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28beb738-31f8-4fb2-8c03-c8934ce0286f/ugc/fc279c13-31e9-46d1-a4e0-d948bce951ae/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bb017e3b-50cb-42ce-8710-ce8463f80049',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28beb738-31f8-4fb2-8c03-c8934ce0286f/ugc/fc279c13-31e9-46d1-a4e0-d948bce951ae/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '6aec2d23-4e22-45c6-b41b-27a51b57b163',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-10T03:41:19.270Z',

          createdAt: '2024-07-04T04:17:38.288Z',
          content: [
            {
              caption: '',

              id: '560a44c7-7363-4954-b62e-fa1d97540eb6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/28beb738-31f8-4fb2-8c03-c8934ce0286f/ugc/6aec2d23-4e22-45c6-b41b-27a51b57b163/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2c30d628-f907-47e0-a369-b7dfddaac5cb',
        firstName: 'Isha',
        lastName: 'Rodriguez',
        email: 'ugcbyisha@yahoo.com',
        instagramProfile: {
          handle: 'ishaarleen',
          followers: 2944,
          engagementRate: 1.1514945652173914
        },
        tiktokProfile: {
          handle: 'isha.arleen',
          followers: 1001,
          engagementRate: 9.67741935483871
        },
        youtubeProfile: {
          handle: 'ishaarleen',
          followers: 7
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T18:12:43.000Z',
        amountCents: 8565
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a32c1f79-324c-4099-9303-35dc55c28a8c',
      content: [
        {
          groupId: 'a187ed67-65fb-4fd2-a342-c035456cdd52',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T20:25:06.271Z',

          createdAt: '2024-06-29T19:35:07.174Z',
          content: [
            {
              caption: '',

              id: '267ac445-dc76-49c7-b571-2d762d613ad1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a32c1f79-324c-4099-9303-35dc55c28a8c/ugc/a187ed67-65fb-4fd2-a342-c035456cdd52/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '194ba30b-341e-4310-a2e8-579371bb3e11',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-02T20:25:12.105Z',

          createdAt: '2024-06-29T19:31:45.327Z',
          content: [
            {
              caption: '',

              id: 'cae4f5fa-ceea-46a9-8787-f0cd50870d97',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/a32c1f79-324c-4099-9303-35dc55c28a8c/ugc/194ba30b-341e-4310-a2e8-579371bb3e11/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8e087167-90fa-477b-b5d1-af18307c41a2',
        firstName: 'Sarah',
        lastName: 'Milligan',
        email: 'sarahmilligan08152022@gmail.com',
        instagramProfile: {
          handle: 'sarahm103020',
          followers: 90,
          engagementRate: 12.222222222222221
        },
        tiktokProfile: {
          handle: 'kazzsstuff',
          followers: 973,
          engagementRate: 0.5401925660546351
        },
        youtubeProfile: {
          handle: 'sarahmilligan0822',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T18:15:03.000Z',
        amountCents: 7699
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '961aae3e-8c98-429e-b8c8-ca09d7ae1c36',
      content: [
        {
          groupId: '21cdfc53-bde1-4fb2-aad8-3f0e153b07cd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T13:33:11.231Z',

          createdAt: '2024-06-29T11:47:16.230Z',
          content: [
            {
              caption: '',

              id: 'ae79e9bb-5cac-48b4-9707-3664bebed553',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/961aae3e-8c98-429e-b8c8-ca09d7ae1c36/ugc/21cdfc53-bde1-4fb2-aad8-3f0e153b07cd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '17ffec99-7040-479e-8b27-1c59c2f694f5',
        firstName: 'Zana',
        lastName: 'Thomas',
        email: 'mingling759@gmail.com',
        instagramProfile: {
          handle: 'spahrie_saphy',
          followers: 4396,
          engagementRate: 6.023657870791629
        },
        tiktokProfile: {
          handle: 'kimiko_775',
          followers: 1900,
          engagementRate: 8.81057268722467
        },
        youtubeProfile: {
          handle: 'tialovez9349',
          followers: 2
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T18:34:28.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f64cf47d-957b-4a5b-9f20-c24ca08152f3',
      content: [],
      creator: {
        id: '4d840f60-747f-4c14-a28b-743a51830780',
        firstName: 'Alex',
        lastName: 'Brown',
        email: 'marietbrown1975@gmail.com',

        tiktokProfile: {
          handle: 'kim276637',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T18:35:45.000Z',
        amountCents: 7020
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '8bebb9ba-9cbf-4a73-a374-ac3ca7421241',
      content: [],
      creator: {
        id: '78cc9284-a54d-4e99-96db-c2bd43576372',
        firstName: 'Aciana',
        lastName: 'Small',
        email: 'a23242035@gmail.com',
        instagramProfile: {
          handle: 'laribrown47',
          followers: 0
        },
        tiktokProfile: {
          handle: 'a2324086y4h',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T18:52:42.000Z',
        amountCents: 12799
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '412e25ad-fb30-4991-a1f7-a7f7db108348',
      content: [
        {
          groupId: '7d9e6e21-cbde-4876-8500-e595a1491aa6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T16:20:42.655Z',

          createdAt: '2024-07-06T06:16:00.253Z',
          content: [
            {
              caption: '',

              id: '92e1f380-5fc8-471a-9651-d0ffa4ca47c1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/412e25ad-fb30-4991-a1f7-a7f7db108348/ugc/7d9e6e21-cbde-4876-8500-e595a1491aa6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'd90a4962-e06a-44c7-b877-6de6f058e367',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-08T16:20:53.599Z',

          createdAt: '2024-07-06T06:16:49.787Z',
          content: [
            {
              caption: '',

              id: '6cd413f9-e6b5-4d18-bdf4-ed51f03a2bae',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/412e25ad-fb30-4991-a1f7-a7f7db108348/ugc/d90a4962-e06a-44c7-b877-6de6f058e367/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e4019021-fbba-4457-8114-e2a0a1a9f03c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/412e25ad-fb30-4991-a1f7-a7f7db108348/ugc/d90a4962-e06a-44c7-b877-6de6f058e367/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '417995e2-cc6e-4eb1-90ea-35608fcbaed1',
        firstName: 'jose',
        lastName: 'Munoz',
        email: 'paka.89esoj@gmail.com',

        tiktokProfile: {
          handle: 'matyyyyyyys',
          followers: 118,
          engagementRate: 11.904761904761903
        },
        youtubeProfile: {
          handle: 'maty2tiktakmaty',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T18:54:56.000Z',
        amountCents: 8663
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5e359c99-c15e-4dad-965f-5f34998440eb',
      content: [
        {
          groupId: 'ad0a980d-5504-444c-a19a-c32e6ec030c4',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-07-01T15:17:24.021Z',

          createdAt: '2024-06-29T23:05:11.005Z',
          content: [
            {
              caption: '',

              id: 'cbcac55a-dc9d-4ac2-bfc9-92a0584ff6f2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e359c99-c15e-4dad-965f-5f34998440eb/ugc/ad0a980d-5504-444c-a19a-c32e6ec030c4/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'd80de47f-bd86-4ed5-aef3-93020f365332',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e359c99-c15e-4dad-965f-5f34998440eb/ugc/ad0a980d-5504-444c-a19a-c32e6ec030c4/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '9a534a5e-f8c3-4bcc-8434-af0b855f1707',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e359c99-c15e-4dad-965f-5f34998440eb/ugc/ad0a980d-5504-444c-a19a-c32e6ec030c4/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9926d3a7-9a24-4a3a-9af2-77aa86603f5f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T15:17:15.982Z',

          createdAt: '2024-06-29T23:14:17.071Z',
          content: [
            {
              caption: '',

              id: '20c7b249-6ba6-49c1-8b96-0abe23d28073',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/5e359c99-c15e-4dad-965f-5f34998440eb/ugc/9926d3a7-9a24-4a3a-9af2-77aa86603f5f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd460947d-743a-4b3d-b98d-bb59eff5d80f',
        firstName: 'Enarria',
        lastName: 'Rollins',
        email: 'erollins18@yahoo.com',

        tiktokProfile: {
          handle: 'cay_dab',
          followers: 172
        },
        youtubeProfile: {
          handle: 'cay_dab',
          followers: 8
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T19:22:59.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '10cfd9cd-12d3-4e2b-92e0-abc7bd172d14',
      content: [
        {
          groupId: '6b22425d-157b-4d0f-8499-31808b8c580a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T19:08:59.933Z',

          createdAt: '2024-07-03T06:26:28.340Z',
          content: [
            {
              caption: '',

              id: '448ccae1-9926-4dae-bfea-acf261742a8e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/10cfd9cd-12d3-4e2b-92e0-abc7bd172d14/ugc/6b22425d-157b-4d0f-8499-31808b8c580a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'ec47724e-3ca4-458c-859e-bc9424e80627',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-05T19:09:03.900Z',

          createdAt: '2024-07-03T06:22:29.383Z',
          content: [
            {
              caption: '',

              id: '86f92ee6-a627-4141-a6b9-e0902d99beaf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/10cfd9cd-12d3-4e2b-92e0-abc7bd172d14/ugc/ec47724e-3ca4-458c-859e-bc9424e80627/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'e38473df-dcb5-43c4-9bc2-5617a4894cd1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/10cfd9cd-12d3-4e2b-92e0-abc7bd172d14/ugc/ec47724e-3ca4-458c-859e-bc9424e80627/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ad1b4416-08a8-4a63-ba4e-0c5a2bfcd485',
        firstName: 'Chivonne',
        lastName: 'Peres',
        email: 'chivonne1998@yahoo.com',

        tiktokProfile: {
          handle: 'justnatrose',
          followers: 264,
          engagementRate: 1.5580425718674566
        },
        youtubeProfile: {
          handle: 'roblox-lava-man'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T19:37:07.000Z',
        amountCents: 10000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0a49be00-621d-47eb-b66a-ac6562bd6545',
      content: [
        {
          groupId: '7549dd5a-262f-408d-86cc-28089f0dfb48',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-24T03:56:13.762Z',

          createdAt: '2024-07-18T01:43:03.555Z',
          content: [
            {
              caption: '',

              id: 'cbf28852-1b1c-4e1b-b419-ff144cba4e76',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0a49be00-621d-47eb-b66a-ac6562bd6545/ugc/7549dd5a-262f-408d-86cc-28089f0dfb48/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '04561fed-9992-4323-832a-86f9e9060d92',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0a49be00-621d-47eb-b66a-ac6562bd6545/ugc/7549dd5a-262f-408d-86cc-28089f0dfb48/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '92962778-26cc-423c-a8d5-9a171a6fdf6f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0a49be00-621d-47eb-b66a-ac6562bd6545/ugc/7549dd5a-262f-408d-86cc-28089f0dfb48/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '1ead2e9f-0381-46f5-b3a4-f0cc8c4863a1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-15T04:44:31.402Z',

          createdAt: '2024-07-05T01:06:25.622Z',
          content: [
            {
              caption: '',

              id: '4a834d99-d798-4120-8686-030366ac547c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/0a49be00-621d-47eb-b66a-ac6562bd6545/ugc/1ead2e9f-0381-46f5-b3a4-f0cc8c4863a1/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '68358104-51ae-4e3a-81c9-b8b79ced9b0e',
        firstName: 'Neel',
        lastName: 'Kunwal',
        email: 'neelkunwal@yahoo.com',
        instagramProfile: {
          handle: 'smartreviewhub',
          followers: 1981,
          engagementRate: 5.123674911660777
        },
        tiktokProfile: {
          handle: 'nknwl',
          followers: 16
        },
        youtubeProfile: {
          handle: 'linxzgamez3095',
          followers: 152
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T20:09:18.000Z',
        amountCents: 6999
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2c95aa27-7a11-4ef8-b95e-c96a15c3a839',
      content: [
        {
          groupId: '6bb743fa-3dd4-4f58-bb59-4e57099f45ab',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-10T02:43:07.061Z',

          createdAt: '2024-07-08T00:24:16.019Z',
          content: [
            {
              caption: '',

              id: 'ae225ed1-949b-4884-b247-e5994ca61f9c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2c95aa27-7a11-4ef8-b95e-c96a15c3a839/ugc/6bb743fa-3dd4-4f58-bb59-4e57099f45ab/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '062ea648-6083-476c-9a1e-0440dfc58602',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-10T02:43:01.138Z',

          createdAt: '2024-07-08T00:23:14.053Z',
          content: [
            {
              caption: '',

              id: '077dd2da-08b2-426d-b173-689d7f854fcb',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/2c95aa27-7a11-4ef8-b95e-c96a15c3a839/ugc/062ea648-6083-476c-9a1e-0440dfc58602/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd8574597-a70d-4224-a525-ee909c5e2dfb',
        firstName: 'Svetlana',
        lastName: 'Farbota',
        email: 'sv.farbota@gmail.com',
        instagramProfile: {
          handle: 'svetlana_farbota',
          followers: 54427,
          engagementRate: 2.3585720322633987
        },
        tiktokProfile: {
          handle: 'sv_finds',
          followers: 57721,
          engagementRate: 3.2183756514708075
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T20:32:09.000Z',
        amountCents: 10291
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'daadca9f-162c-4571-b56a-523d99541a54',
      content: [
        {
          groupId: 'f7a1f4dc-c38f-4f23-8ba6-e7b04404c444',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-13T17:35:27.444Z',

          createdAt: '2024-07-12T19:03:20.863Z',
          content: [
            {
              caption: '',

              id: 'b63d0bfd-230e-4a2a-87c7-02cc14354101',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/daadca9f-162c-4571-b56a-523d99541a54/ugc/f7a1f4dc-c38f-4f23-8ba6-e7b04404c444/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '3a7a5b04-c7ec-4e60-b117-580198d1a1b5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-13T17:35:35.602Z',

          createdAt: '2024-07-12T19:04:48.532Z',
          content: [
            {
              caption: '',

              id: '1e6d1762-b700-463d-a198-1365b20952a0',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/daadca9f-162c-4571-b56a-523d99541a54/ugc/3a7a5b04-c7ec-4e60-b117-580198d1a1b5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'f2dc2ab1-1c47-4994-a77c-18b130c200fa',
        firstName: 'Sherica',
        lastName: 'Nicholas ',
        email: 'shericanicholas@gmail.com',

        tiktokProfile: {
          handle: 'sherican3',
          followers: 1000,
          engagementRate: 3.587443946188341
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T20:54:29.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4511ac80-d6ce-48f0-a183-b799cbb6c43b',
      content: [
        {
          groupId: '635212ab-29aa-4bf6-b645-dc18186e2aef',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-16T18:28:28.154Z',

          createdAt: '2024-07-08T18:18:52.101Z',
          content: [
            {
              caption: '',

              id: '1fcc9083-faa3-4b04-ad42-c95524b30301',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4511ac80-d6ce-48f0-a183-b799cbb6c43b/ugc/635212ab-29aa-4bf6-b645-dc18186e2aef/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '46988222-5fc9-4fab-8d2a-47f56938269a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-16T18:28:22.166Z',

          createdAt: '2024-07-04T16:22:41.032Z',
          content: [
            {
              caption: '',

              id: 'd219084b-4329-4cba-8897-ceee464560c1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4511ac80-d6ce-48f0-a183-b799cbb6c43b/ugc/46988222-5fc9-4fab-8d2a-47f56938269a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '34dd8997-a8dc-4454-ab94-0eea00b64f55',
        firstName: 'Patricia',
        lastName: 'Gillmore',
        email: 'ggirlscloset@gmail.com',
        instagramProfile: {
          handle: 'ggirlscloset',
          followers: 11371,
          engagementRate: 0.16621229443320726
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T21:01:44.000Z',
        amountCents: 7587
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '58bbaf8a-3f5f-4efe-b151-380808695277',
      content: [
        {
          groupId: '5fa66773-c693-4d6b-b54a-a4bbd4ec3aff',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-16T18:29:24.453Z',

          createdAt: '2024-07-14T16:02:00.181Z',
          content: [
            {
              caption: '',

              id: 'eab873bf-7c57-4c4f-91d8-dd0233079a70',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/58bbaf8a-3f5f-4efe-b151-380808695277/ugc/5fa66773-c693-4d6b-b54a-a4bbd4ec3aff/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7c736fe4-c2d3-485a-8590-dcc1cc2e9097',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-16T18:29:33.292Z',

          createdAt: '2024-07-14T16:03:32.616Z',
          content: [
            {
              caption: '',

              id: '69cab62a-c59e-4fd5-8a3b-729bac9e171f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/58bbaf8a-3f5f-4efe-b151-380808695277/ugc/7c736fe4-c2d3-485a-8590-dcc1cc2e9097/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'f331ea4c-cf35-4b77-af72-be3c141c0cb9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/58bbaf8a-3f5f-4efe-b151-380808695277/ugc/7c736fe4-c2d3-485a-8590-dcc1cc2e9097/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '71943f48-ed73-4cab-8d85-ecbd04eac6ae',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/58bbaf8a-3f5f-4efe-b151-380808695277/ugc/7c736fe4-c2d3-485a-8590-dcc1cc2e9097/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '2f29a139-8feb-4c31-b53a-cf9030887c96',
        firstName: 'Esthefany',
        lastName: 'Serrano',
        email: 'esthefany.bernie@outlook.com',
        instagramProfile: {
          handle: 'todoymas_1',
          followers: 60151,
          engagementRate: 0.215457764625692
        },
        tiktokProfile: {
          handle: 'detodounpocoymas_1',
          followers: 3727,
          engagementRate: 5.513955071477195
        },
        youtubeProfile: {
          handle: 'detodounpocoymas',
          followers: 96200
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T21:15:23.000Z',
        amountCents: 7585
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd4c3516f-3c67-4724-bb8b-f9edecca8792',
      content: [
        {
          groupId: 'ea294e5e-7a8a-4c19-9476-696bd1c2cc2d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-30T12:29:16.731Z',

          createdAt: '2024-07-26T15:43:59.108Z',
          content: [
            {
              caption: '',

              id: '60298b99-0fcc-468b-a4e9-ff831fc33009',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d4c3516f-3c67-4724-bb8b-f9edecca8792/ugc/ea294e5e-7a8a-4c19-9476-696bd1c2cc2d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '1825036f-c5bc-4060-8252-d7f902b33b06',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-30T12:29:22.005Z',

          createdAt: '2024-07-26T15:35:30.379Z',
          content: [
            {
              caption: '',

              id: 'a69c6714-b232-4cba-b1ac-6dce3aaa7958',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d4c3516f-3c67-4724-bb8b-f9edecca8792/ugc/1825036f-c5bc-4060-8252-d7f902b33b06/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'a0ecaf9c-6829-4e4e-9c1a-8af29a43b3d8',
        firstName: 'Samoya',
        lastName: 'Flowers',
        email: 'odeshaflowers@gmail.com',
        instagramProfile: {
          handle: 'dreambellehair',
          followers: 8,
          engagementRate: 66.66666666666666
        },
        tiktokProfile: {
          handle: 'moya_297',
          followers: 61
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T21:39:21.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '29070340-aa6c-423b-8160-b839d2de3413',
      content: [
        {
          groupId: '6d302706-af68-410d-add8-7863730aef2c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-06T04:55:13.133Z',

          createdAt: '2024-07-01T15:46:38.399Z',
          content: [
            {
              caption: '',

              id: '1f7d26c8-1436-4201-94b0-294cad77c209',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29070340-aa6c-423b-8160-b839d2de3413/ugc/6d302706-af68-410d-add8-7863730aef2c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '5648d79f-726d-482e-9ebe-be87ef41ebb6',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-06T04:55:04.116Z',

          createdAt: '2024-07-01T01:54:57.448Z',
          content: [
            {
              caption: '',

              id: '4f741f37-6e72-4445-8df1-43dbe21cfa10',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/29070340-aa6c-423b-8160-b839d2de3413/ugc/5648d79f-726d-482e-9ebe-be87ef41ebb6/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'e72d993d-0946-42b4-a23e-aa15eac7a308',
        firstName: 'Kay',
        lastName: 'Tolliver',
        email: 'kgrant6wildcat@yahoo.com',
        instagramProfile: {
          handle: 'savvy.deals2',
          followers: 315,
          engagementRate: 0.031746031746031744
        },
        tiktokProfile: {
          handle: 'savvy.deals2',
          followers: 502,
          engagementRate: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T21:52:38.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6ebd862e-4823-4ced-b8b3-41a52c534312',
      content: [
        {
          groupId: '142f4524-50ce-4236-aa07-40ba1c8ff72b',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T16:14:21.531Z',

          createdAt: '2024-07-04T18:37:43.345Z',
          content: [
            {
              caption: '',

              id: '602a2acb-e14e-44fd-a81f-1738cd097f80',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6ebd862e-4823-4ced-b8b3-41a52c534312/ugc/142f4524-50ce-4236-aa07-40ba1c8ff72b/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a6d44ff0-d16f-409d-b10e-86eba9d2c2b5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-04T21:14:30.012Z',

          createdAt: '2024-07-04T05:45:58.561Z',
          content: [
            {
              caption: '',

              id: '5507f677-456e-4e9e-b9c0-d9569512bf93',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6ebd862e-4823-4ced-b8b3-41a52c534312/ugc/a6d44ff0-d16f-409d-b10e-86eba9d2c2b5/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '695da56b-816c-4329-b1aa-90d8835610f3',
        firstName: 'Ihor',
        lastName: 'Husakov',
        email: 'gusakov99@icloud.com',
        instagramProfile: {
          handle: 'fanshopkorzh',
          followers: 1346,
          engagementRate: 8.588410104011887
        },
        tiktokProfile: {
          handle: 'scoobyduinamerica',
          followers: 830,
          engagementRate: 0.39215686274509803
        },
        youtubeProfile: {
          handle: 'user-eu2vz2yq8f',
          followers: 624
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T22:52:40.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0b801b2e-92dd-4053-a5f8-ae84478da3db',
      content: [],
      creator: {
        id: '60040874-5739-4078-b203-e7bb7006c054',
        firstName: 'Jasmin',
        lastName: 'Everson',
        email: 'jasmineverson@yahoo.com',
        instagramProfile: {
          handle: 'jasmincupcake',
          followers: 2325,
          engagementRate: 0.6580645161290323
        },
        tiktokProfile: {
          handle: 'divorcinglin',
          followers: 197
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T23:15:14.000Z',
        amountCents: 7918
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd2bff96f-964b-45c2-b80b-e073f267e61e',
      content: [
        {
          groupId: 'b568326c-bc23-49a8-b856-5619d9c87771',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-24T03:51:23.737Z',

          createdAt: '2024-07-22T22:55:24.748Z',
          content: [
            {
              caption: '',

              id: 'c5dade53-c020-4871-b500-a5b11887e867',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d2bff96f-964b-45c2-b80b-e073f267e61e/ugc/b568326c-bc23-49a8-b856-5619d9c87771/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '127d93a6-a829-4192-8139-60e9738e61e9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d2bff96f-964b-45c2-b80b-e073f267e61e/ugc/b568326c-bc23-49a8-b856-5619d9c87771/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2c3c3acc-b08e-46c7-b1b4-0d9684476f14',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d2bff96f-964b-45c2-b80b-e073f267e61e/ugc/b568326c-bc23-49a8-b856-5619d9c87771/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a41a9376-0a25-4da4-becd-1a9e2ef468c9',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T03:51:19.655Z',

          createdAt: '2024-07-22T22:54:44.815Z',
          content: [
            {
              caption: '',

              id: '4456c496-3e80-403b-8911-4d01aa0280a2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/d2bff96f-964b-45c2-b80b-e073f267e61e/ugc/a41a9376-0a25-4da4-becd-1a9e2ef468c9/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '366ac34e-d649-4733-86ee-ead013efc0be',
        firstName: 'Lucas',
        lastName: 'Ficher',
        email: 'lucasficher@hotmail.com',
        instagramProfile: {
          handle: 'estudio_ficher',
          followers: 24020,
          engagementRate: 1.9558701082431307
        },
        tiktokProfile: {
          handle: 'lucasficherr',
          followers: 5175,
          engagementRate: 45.3416149068323
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-27T23:37:48.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f20e3a18-37cd-42dc-abe7-6c3c9dcb8bdf',
      content: [],
      creator: {
        id: 'a74ea419-e73b-4599-b3d6-51493ac1b5d9',
        firstName: 'Niko',
        lastName: 'Kots',
        email: 'nkots3344@gmail.com',

        tiktokProfile: {
          handle: 'user4819384611690',
          followers: 2
        },
        youtubeProfile: {
          handle: 'nikokotsailidis3329'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T00:01:31.000Z',
        amountCents: 12500
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '3a0fa71d-eab0-4305-8e82-8d11a4eca033',
      content: [],
      creator: {
        id: 'f746b6d0-e9ce-47ca-aa50-e7ef14be3884',
        firstName: 'Shams',
        lastName: 'Khazil ',
        email: 'skhazil@yahoo.com',

        tiktokProfile: {
          handle: 'shamsalani7',
          followers: 10023,
          engagementRate: 6.32306688030384
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T00:15:33.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b4e6160c-3913-4980-bebc-737a47e3d74d',
      content: [],
      creator: {
        id: '1e9230a8-35e8-42c4-b6b7-1b60054d3dd9',
        firstName: 'Shanell',
        lastName: 'Mclean',
        email: 'mcleanshanell81@gmail.com',
        instagramProfile: {
          handle: 'amma_be_humble',
          followers: 1729
        },
        tiktokProfile: {
          handle: 'nellylevel23',
          followers: 382
        },
        youtubeProfile: {
          handle: 'shanellmclean211',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T01:30:42.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '235c46c0-7028-458b-a15b-1e61bde0232e',
      content: [
        {
          groupId: 'cfcb8b37-de75-4108-9158-dd066c9cf5e8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-16T20:31:17.707Z',

          createdAt: '2024-07-15T14:30:17.240Z',
          content: [
            {
              caption: '',

              id: '7304de45-7231-4b8f-b81a-155ce4a01425',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/235c46c0-7028-458b-a15b-1e61bde0232e/ugc/cfcb8b37-de75-4108-9158-dd066c9cf5e8/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'de6d18ca-3be7-435f-a252-0f9cbda16a2a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-16T20:30:21.953Z',

          createdAt: '2024-07-15T02:51:48.770Z',
          content: [
            {
              caption: '',

              id: '4dbe7b7a-a009-4d29-8d80-6b408994cb1a',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/235c46c0-7028-458b-a15b-1e61bde0232e/ugc/de6d18ca-3be7-435f-a252-0f9cbda16a2a/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5fa46607-fc5e-48d8-920b-e8fea8b4a9b2',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/235c46c0-7028-458b-a15b-1e61bde0232e/ugc/de6d18ca-3be7-435f-a252-0f9cbda16a2a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '40967fc7-a3ec-454c-9b6d-714045dcbb07',
        firstName: 'Breyn',
        lastName: 'McLaren',
        email: 'mamaladyfly@gmail.com',
        instagramProfile: {
          handle: 'mamaladyfly',
          followers: 2943,
          engagementRate: 1.913013931362555
        },
        tiktokProfile: {
          handle: 'mamaladyfly',
          followers: 1577,
          engagementRate: 3.598200899550225
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T02:22:08.000Z',
        amountCents: 7541
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b6ae208d-1209-4048-9591-5330f137d41a',
      content: [
        {
          groupId: '92b35532-c734-49cb-b0ed-f149d28d377a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-01T16:12:52.548Z',

          createdAt: '2024-06-30T01:17:17.989Z',
          content: [
            {
              caption: '',

              id: '6951f3a5-362b-4eb0-a027-ba61df322c37',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6ae208d-1209-4048-9591-5330f137d41a/ugc/92b35532-c734-49cb-b0ed-f149d28d377a/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '58a815a3-9ae6-42d8-8f5e-0a022a61bb32',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6ae208d-1209-4048-9591-5330f137d41a/ugc/92b35532-c734-49cb-b0ed-f149d28d377a/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '74a05511-d886-494b-b6d6-48f6c70bd343',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6ae208d-1209-4048-9591-5330f137d41a/ugc/92b35532-c734-49cb-b0ed-f149d28d377a/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '2408c409-d0a3-43cb-887d-e2a93142a8fe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T16:12:46.652Z',

          createdAt: '2024-06-30T01:15:52.388Z',
          content: [
            {
              caption: '',

              id: '2e49fc6c-10e8-4f49-bbb8-46fac8b06088',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/b6ae208d-1209-4048-9591-5330f137d41a/ugc/2408c409-d0a3-43cb-887d-e2a93142a8fe/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0f67b572-b20c-452b-9f0b-e56681cca589',
        firstName: 'Brittany',
        lastName: 'Duncan',
        email: 'bvestal05@gmail.com',
        instagramProfile: {
          handle: 'beautyby_brittanykc',
          followers: 65,
          engagementRate: 0.7692307692307693
        },
        tiktokProfile: {
          handle: 'girlyboymom2',
          followers: 1637,
          engagementRate: 2.3798822159988013
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T02:22:43.000Z',
        amountCents: 7295
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '14884388-ccb3-48b6-9123-787c3afba74d',
      content: [
        {
          groupId: 'acfee1a6-3046-4db5-bd79-93517f7c1a22',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-10T00:26:27.211Z',

          createdAt: '2024-07-05T02:03:11.156Z',
          content: [
            {
              caption: '',

              id: 'fd111261-1fc0-416e-b2dc-3f462d5d96a9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/14884388-ccb3-48b6-9123-787c3afba74d/ugc/acfee1a6-3046-4db5-bd79-93517f7c1a22/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '778828d9-4302-4c0f-929e-f3990992fdac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/14884388-ccb3-48b6-9123-787c3afba74d/ugc/acfee1a6-3046-4db5-bd79-93517f7c1a22/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'ed1e5d23-2b65-4928-a029-7163def190dd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/14884388-ccb3-48b6-9123-787c3afba74d/ugc/acfee1a6-3046-4db5-bd79-93517f7c1a22/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '0c1cbd68-1aad-49fc-908b-16f35cd71946',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-10T00:26:31.430Z',

          createdAt: '2024-07-07T18:30:03.064Z',
          content: [
            {
              caption: '',

              id: '65849929-d8c5-41a6-a935-ba469ff3bd5d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/14884388-ccb3-48b6-9123-787c3afba74d/ugc/0c1cbd68-1aad-49fc-908b-16f35cd71946/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '1da6be50-778f-4983-a053-87a1abfb9550',
        firstName: 'Aveer',
        lastName: 'Shah',
        email: 'aveeka2019@gmail.com',
        instagramProfile: {
          handle: 'nixm19',
          followers: 5612,
          engagementRate: 4.9198146828225235
        },
        tiktokProfile: {
          handle: 'nixm19',
          followers: 1167,
          engagementRate: 5.333333333333333
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T03:03:26.000Z',
        amountCents: 8135
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ee1f9031-f026-4467-9521-53946e576cd9',
      content: [
        {
          groupId: '37ecb980-03fa-4a19-bcc1-74b99a10a3bd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T06:22:22.772Z',

          createdAt: '2024-07-05T01:37:59.888Z',
          content: [
            {
              caption: '',

              id: '1832068e-e0aa-47e9-9758-f03b34d1d598',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ee1f9031-f026-4467-9521-53946e576cd9/ugc/37ecb980-03fa-4a19-bcc1-74b99a10a3bd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '65cc0055-9156-47dd-beb7-46412da84d31',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T06:22:28.008Z',

          createdAt: '2024-07-05T01:38:23.767Z',
          content: [
            {
              caption: '',

              id: '84a923f7-6f06-4957-a4ff-035f7488b5bf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ee1f9031-f026-4467-9521-53946e576cd9/ugc/65cc0055-9156-47dd-beb7-46412da84d31/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '747fc58b-48fa-48d8-9a0c-fadcca85a199',
        firstName: 'Tess',
        lastName: 'Near',
        email: 'tessflips@yahoo.com',
        instagramProfile: {
          handle: 'tessnearr',
          followers: 2055
        },
        tiktokProfile: {
          handle: 'tessnear',
          followers: 678
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T05:39:58.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'a3e93a01-32f5-4b0e-8235-05e819500062',
      content: [],
      creator: {
        id: 'ddde9480-83e9-461f-b000-f3d5f1310daa',
        firstName: 'Makaila',
        lastName: 'Wardlaw',
        email: 'makailawardlaw@gmail.com',
        instagramProfile: {
          handle: 'shopkayils',
          followers: 35,
          engagementRate: 9.444444444444445
        },
        tiktokProfile: {
          handle: 'makailalol',
          followers: 301,
          engagementRate: 3.959929757343551
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T06:23:47.000Z',
        amountCents: 9247
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '46c9bc32-8db7-4aca-9c67-669be91083ee',
      content: [
        {
          groupId: '65257e41-a81f-4815-8aef-a380eaafc323',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-06T23:19:56.832Z',

          createdAt: '2024-07-02T17:01:43.201Z',
          content: [
            {
              caption: '',

              id: 'ce7aa18b-3bd4-4bbb-b14f-f134fe5acb6c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46c9bc32-8db7-4aca-9c67-669be91083ee/ugc/65257e41-a81f-4815-8aef-a380eaafc323/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9c42147a-3836-4842-a093-21c17d5cc1db',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-06T23:19:51.409Z',

          createdAt: '2024-07-02T17:05:29.254Z',
          content: [
            {
              caption: '',

              id: 'd3ecc883-78e1-473b-ba6d-7d6b80882dac',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/46c9bc32-8db7-4aca-9c67-669be91083ee/ugc/9c42147a-3836-4842-a093-21c17d5cc1db/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '79eeefe9-6c37-4aec-917b-281d40e99853',
        firstName: 'Kayla',
        lastName: 'Kell',
        email: 'kshey33@gmail.com',
        instagramProfile: {
          handle: 'kaylakell38',
          followers: 1848,
          engagementRate: 6.504329004329004
        },
        tiktokProfile: {
          handle: 'kaylashey34',
          followers: 224,
          engagementRate: 0.09523809523809523
        },
        youtubeProfile: {
          handle: 'kaylakell',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T08:06:08.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6dacc366-fdf2-44f3-afc5-833aaad1831a',
      content: [],
      creator: {
        id: '2bc7dad3-35df-4e4f-8255-da45d9477ad2',
        firstName: 'Shaunna',
        lastName: 'Spivey-Spinner',
        email: 'holliwood@therealholliwood.com',
        instagramProfile: {
          handle: 'thereal_holliwood',
          followers: 219977,
          engagementRate: 0.10010137423457906
        },
        tiktokProfile: {
          handle: 'therealholliwood',
          followers: 20800
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T11:47:37.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'd4f5d285-1d3d-499c-b22c-33440298c14e',
      content: [],
      creator: {
        id: '8a53959c-cbe1-4611-9e1f-65fca71b3a16',
        firstName: 'Hayden',
        lastName: 'Llewellyn',
        email: 'haydenllewellyn22@gmail.com',
        instagramProfile: {
          handle: 'haydenllewellynn',
          followers: 310,
          engagementRate: 3.0000000000000004
        },
        tiktokProfile: {
          handle: 'haydenllewellynn',
          followers: 227,
          engagementRate: 1.6826923076923077
        },
        youtubeProfile: {
          handle: 'hayden__yeeyee',
          followers: 1
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T11:52:35.000Z',
        amountCents: 8488
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c73e1080-8b08-4153-b7c1-d6c2f2168c5a',
      content: [
        {
          groupId: 'd43abc6a-8033-43c0-a605-8d1c7ebcd785',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-17T20:08:19.257Z',

          createdAt: '2024-07-02T21:53:42.784Z',
          content: [
            {
              caption: '',

              id: '392c8739-b57d-4b85-a464-3dcd814930a7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c73e1080-8b08-4153-b7c1-d6c2f2168c5a/ugc/d43abc6a-8033-43c0-a605-8d1c7ebcd785/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b8ce1b39-bb71-4670-b650-8802615850ba',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-17T20:08:23.813Z',

          createdAt: '2024-07-02T21:40:11.818Z',
          content: [
            {
              caption: '',

              id: 'f3c37855-a3b7-4b38-81c1-9d14aa3574e4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c73e1080-8b08-4153-b7c1-d6c2f2168c5a/ugc/b8ce1b39-bb71-4670-b650-8802615850ba/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ab7d8083-4a7d-4b3a-8fb9-17e5132b579a',
        firstName: 'Symone ',
        lastName: 'Stanley ',
        email: 'symonedeonne@gmail.com',
        instagramProfile: {
          handle: 'symonewiththesports',
          followers: 5040,
          engagementRate: 8.220238095238095
        },
        tiktokProfile: {
          handle: 'symonewiththesports',
          followers: 16494,
          engagementRate: 10.667967821441529
        },
        youtubeProfile: {
          handle: 'symonewiththesports'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T12:25:47.000Z',
        amountCents: 7559
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '98c1f9e1-65fe-42aa-ae4a-a71c5586a5d2',
      content: [
        {
          groupId: '427d4d6b-8880-4272-a80e-1ce072e6d5fe',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T16:07:16.369Z',

          createdAt: '2024-06-29T23:37:53.068Z',
          content: [
            {
              caption: '',

              id: 'ea3b0449-a4df-4492-b597-572efdddc34c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/98c1f9e1-65fe-42aa-ae4a-a71c5586a5d2/ugc/427d4d6b-8880-4272-a80e-1ce072e6d5fe/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '54380651-8d05-418a-a18d-d3add5639dea',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-01T16:07:10.422Z',

          createdAt: '2024-06-29T23:42:41.177Z',
          content: [
            {
              caption: '',

              id: '1e0f17ae-79b9-456a-aeb0-b1ad16c0f158',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/98c1f9e1-65fe-42aa-ae4a-a71c5586a5d2/ugc/54380651-8d05-418a-a18d-d3add5639dea/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '61116567-34f0-4bf0-974d-6441f9712391',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/98c1f9e1-65fe-42aa-ae4a-a71c5586a5d2/ugc/54380651-8d05-418a-a18d-d3add5639dea/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'bb5ffa59-e397-47a6-85d2-cfcee2e3012f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/98c1f9e1-65fe-42aa-ae4a-a71c5586a5d2/ugc/54380651-8d05-418a-a18d-d3add5639dea/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '74e453a9-f4be-490b-8334-b1a8aa42de7c',
        firstName: 'Imane',
        lastName: 'El yassini',
        email: 'imane.elyassini@gmail.com',
        instagramProfile: {
          handle: 'imane_elyassini',
          followers: 6002
        },
        tiktokProfile: {
          handle: 'imaneelyassini2',
          followers: 58
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T12:55:51.000Z',
        amountCents: 8160
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7192ef53-dd4f-4b9d-a27f-ef4f57b390f6',
      content: [
        {
          groupId: 'f33b8848-aef5-4274-ae5d-a1b3eed4ec96',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-01T12:49:25.382Z',

          createdAt: '2024-07-01T02:40:27.597Z',
          content: [
            {
              caption: '',

              id: '6e879590-82d0-4400-ba81-933a46df323b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7192ef53-dd4f-4b9d-a27f-ef4f57b390f6/ugc/f33b8848-aef5-4274-ae5d-a1b3eed4ec96/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9ce7bc65-caf6-4274-836b-cd27ca7287eb',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-05T03:29:34.343Z',

          createdAt: '2024-07-01T02:39:53.963Z',
          content: [
            {
              caption: '',

              id: 'f8a37abb-8242-4ece-8f91-82a8e51af619',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7192ef53-dd4f-4b9d-a27f-ef4f57b390f6/ugc/9ce7bc65-caf6-4274-836b-cd27ca7287eb/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '24d759b7-f4c1-4385-952e-c8d873a956fc',
        firstName: 'Citlali',
        lastName: 'López Cabrera',
        email: 'xtrellytadxi@gmail.com',

        youtubeProfile: {
          handle: 'citlalilopez1499',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T12:55:54.000Z',
        amountCents: 8237
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '74f67b0b-aba3-4590-bf8b-fa5b60b25df9',
      content: [
        {
          groupId: '631833d6-52bf-4df7-bef2-a14eae56c5b8',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T14:16:54.539Z',

          createdAt: '2024-07-02T16:37:51.725Z',
          content: [
            {
              caption: '',

              id: '73f3c49c-653b-452d-94a1-2db6d62e8811',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/74f67b0b-aba3-4590-bf8b-fa5b60b25df9/ugc/631833d6-52bf-4df7-bef2-a14eae56c5b8/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '3ef271f5-b2be-4288-ba37-9a069e28b0cd',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T14:16:50.865Z',

          createdAt: '2024-07-02T16:37:35.406Z',
          content: [
            {
              caption: '',

              id: '46d67055-99e2-4e46-8ac2-b185ca7d8cd5',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/74f67b0b-aba3-4590-bf8b-fa5b60b25df9/ugc/3ef271f5-b2be-4288-ba37-9a069e28b0cd/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'af2cb82b-691b-4701-bc11-7c61645eae1c',
        firstName: 'Ella',
        lastName: 'Morris',
        email: 'archerinheels@gmail.com',
        instagramProfile: {
          handle: 'ellasolves',
          followers: 125640,
          engagementRate: 0.36190703597580387
        },
        tiktokProfile: {
          handle: 'ellasolves',
          followers: 53414,
          engagementRate: 4.365782644471169
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T13:42:44.000Z',
        amountCents: 7603
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '6d48a6d8-b0fb-4bdd-8f4d-71309c12c839',
      content: [
        {
          groupId: '1f17e2af-f623-40bc-90f0-70641b03e59d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-06T11:50:42.331Z',

          createdAt: '2024-07-04T03:43:00.273Z',
          content: [
            {
              caption: '',

              id: 'ba13d0b9-fb99-46be-b1a1-ee7bf7de97b8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/6d48a6d8-b0fb-4bdd-8f4d-71309c12c839/ugc/1f17e2af-f623-40bc-90f0-70641b03e59d/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '244a4107-f98c-4065-9eae-d10a4459a378',
        firstName: 'Stefani',
        lastName: 'Iotova',
        email: 'simplymamalifee@gmail.com',
        instagramProfile: {
          handle: 'simplymamalifee',
          followers: 14309,
          engagementRate: 0.25158990844922774
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T13:57:03.000Z',
        amountCents: 7419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b30b3ee9-4d1d-4b19-a366-d1a280c2b67c',
      content: [],
      creator: {
        id: 'f8ca8c67-6e6a-45e7-b63c-ed3357f95cfe',
        firstName: 'Sharla',
        lastName: 'Dehaney',
        email: 'sharladehaney@icloud.com',
        instagramProfile: {
          handle: 'lvssharla',
          followers: 3082
        },
        tiktokProfile: {
          handle: 'azi_.mommy',
          followers: 391
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T15:34:07.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '053131bf-8545-49aa-8720-dadba988aabe',
      content: [
        {
          groupId: '50bc0a0c-7a11-42aa-af92-566447617408',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-31T17:54:23.778Z',

          createdAt: '2024-07-26T18:40:44.555Z',
          content: [
            {
              caption: '',

              id: '8fe3342b-dfcb-456e-9323-47db547541af',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/053131bf-8545-49aa-8720-dadba988aabe/ugc/50bc0a0c-7a11-42aa-af92-566447617408/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '8f8ba036-88c2-4418-a3b1-ba076b9c0cb7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/053131bf-8545-49aa-8720-dadba988aabe/ugc/50bc0a0c-7a11-42aa-af92-566447617408/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6f253a55-fcce-43a3-bba6-9efac286bda3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/053131bf-8545-49aa-8720-dadba988aabe/ugc/50bc0a0c-7a11-42aa-af92-566447617408/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '91fee35b-309c-4159-bb89-40a4203d884a',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-31T17:54:16.828Z',

          createdAt: '2024-07-26T18:40:06.527Z',
          content: [
            {
              caption: '',

              id: '78f76b60-1ca5-42d4-9fad-078af67d4c88',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/053131bf-8545-49aa-8720-dadba988aabe/ugc/91fee35b-309c-4159-bb89-40a4203d884a/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd3816ce0-1cff-499a-bc60-06b40f22fd58',
        firstName: 'David',
        lastName: 'Graham',
        email: 'damianfagan45@outlook.com',

        tiktokProfile: {
          handle: 'deshaun935',
          followers: 0
        },
        youtubeProfile: {
          handle: 'desean4614',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T17:54:27.000Z',
        amountCents: 18845
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '022a7157-6e06-4a7e-a848-add7071caf3c',
      content: [],
      creator: {
        id: '54960d91-674b-4ba6-a9e9-f708967fc83a',
        firstName: 'Chris',
        lastName: 'Parker',
        email: 'officialteamhlg@gmail.com',
        instagramProfile: {
          handle: 'hlg_cpthakidd',
          followers: 83041,
          engagementRate: 0.7030262159656073
        },
        tiktokProfile: {
          handle: 'thehlgshow',
          followers: 25734,
          engagementRate: 9.762532981530343
        },
        youtubeProfile: {
          handle: 'officialhlgshow'
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T18:24:57.000Z',
        amountCents: 7576
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b054fe00-c294-421a-bc68-cd87876116a9',
      content: [],
      creator: {
        id: '42812118-2c97-4c45-8a1b-d5c12afb3c08',
        firstName: 'Jahnnoya',
        lastName: 'Suckarie',
        email: 'jahnnoyasuckarie21@gmail.com',
        instagramProfile: {
          handle: 'thee_unique_jay',
          followers: 1603,
          engagementRate: 2.272727272727273
        },
        tiktokProfile: {
          handle: 'applebottombaby15',
          followers: 247,
          engagementRate: 7.079646017699115
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-28T19:34:29.000Z',
        amountCents: 8530
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7dff16d2-de46-47d4-b60b-6cd4490bd64e',
      content: [
        {
          groupId: '3ae35dc7-7df5-44fc-8314-b695b9d6ba74',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T11:02:38.618Z',

          createdAt: '2024-07-07T22:17:56.946Z',
          content: [
            {
              caption: '',

              id: '718ebe56-7223-40af-9e24-d31e87d016bd',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7dff16d2-de46-47d4-b60b-6cd4490bd64e/ugc/3ae35dc7-7df5-44fc-8314-b695b9d6ba74/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '405b6bc6-00e6-445b-aad4-3aa2a282dba5',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-09T14:20:04.232Z',

          createdAt: '2024-07-08T14:26:53.575Z',
          content: [
            {
              caption: '',

              id: '0084f7d1-cd97-49ab-87bf-0aecdc31fc66',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7dff16d2-de46-47d4-b60b-6cd4490bd64e/ugc/405b6bc6-00e6-445b-aad4-3aa2a282dba5/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'cd6b2620-e4c5-4203-8a87-31da6f4710f8',
        firstName: 'Paula',
        lastName: 'Priest',
        email: 'ottongx6@gmail.com',
        instagramProfile: {
          handle: 'veritas_vibes',
          followers: 1684,
          engagementRate: 4.4299287410926365
        },
        tiktokProfile: {
          handle: 'veritas_vibes',
          followers: 336,
          engagementRate: 0.6451612903225806
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T00:52:52.000Z',
        amountCents: 7603
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '52c89572-727a-458f-8063-8fb7c8cb249f',
      content: [
        {
          groupId: 'c6c9b00a-7371-4d7f-9edf-5a6ab5064ea7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-24T03:56:44.966Z',

          createdAt: '2024-07-05T00:22:52.882Z',
          content: [
            {
              caption: '',

              id: 'da39efb4-1103-432f-ba06-ca4b0bbd84fa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/52c89572-727a-458f-8063-8fb7c8cb249f/ugc/c6c9b00a-7371-4d7f-9edf-5a6ab5064ea7/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'b0154e40-1f3c-44d1-886d-446926a9f6c1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/52c89572-727a-458f-8063-8fb7c8cb249f/ugc/c6c9b00a-7371-4d7f-9edf-5a6ab5064ea7/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '3ff3e8ce-969e-4854-a023-990680dbdb98',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/52c89572-727a-458f-8063-8fb7c8cb249f/ugc/c6c9b00a-7371-4d7f-9edf-5a6ab5064ea7/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'afc0db8b-1e59-4de5-a7dc-f174c929c5dc',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T03:56:35.635Z',

          createdAt: '2024-07-05T00:21:48.380Z',
          content: [
            {
              caption: '',

              id: 'dc88d9aa-8600-471f-980f-2fc2cf1e644d',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/52c89572-727a-458f-8063-8fb7c8cb249f/ugc/afc0db8b-1e59-4de5-a7dc-f174c929c5dc/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '0cbcc4bd-0434-4b62-9730-a3bca4c0158f',
        firstName: 'Oghenekaro',
        lastName: 'Itene',
        email: 'oghenekaroitene@gmail.com',
        instagramProfile: {
          handle: 'oghenekaroitene',
          followers: 184798,
          engagementRate: 0.12272860095888484
        },
        tiktokProfile: {
          handle: 'karoitene',
          followers: 1382,
          engagementRate: 1.44
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T04:15:46.000Z',
        amountCents: 7664
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4308e6ca-cce1-4b86-977c-687c9cbae262',
      content: [
        {
          groupId: '84c80d6f-77bd-4967-94fb-2bce25f6d493',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-29T22:02:40.212Z',

          createdAt: '2024-07-25T15:09:36.515Z',
          content: [
            {
              caption: '',

              id: 'e1cd2c50-1f25-4b85-a99a-334b36b458b7',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4308e6ca-cce1-4b86-977c-687c9cbae262/ugc/84c80d6f-77bd-4967-94fb-2bce25f6d493/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '6fb9e2fa-157b-40e3-87ea-b0efea10dfaf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4308e6ca-cce1-4b86-977c-687c9cbae262/ugc/84c80d6f-77bd-4967-94fb-2bce25f6d493/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1290b407-b9a1-4719-a2c3-acc02d2b8059',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4308e6ca-cce1-4b86-977c-687c9cbae262/ugc/84c80d6f-77bd-4967-94fb-2bce25f6d493/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '11c80ce0-004b-49ab-86aa-6880d09f106d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-29T22:02:30.913Z',

          createdAt: '2024-07-25T15:21:19.809Z',
          content: [
            {
              caption: '',

              id: 'e4ee1faa-2657-4bea-a950-e1466bdc50a3',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4308e6ca-cce1-4b86-977c-687c9cbae262/ugc/11c80ce0-004b-49ab-86aa-6880d09f106d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '4dcc8be5-bd1c-4483-8e1b-354ee4c3adc9',
        firstName: 'Sharay',
        lastName: 'Spence',
        email: 'spencesharay119@yahoo.com',
        instagramProfile: {
          handle: 'sharii_godbless_',
          followers: 2593
        },
        tiktokProfile: {
          handle: 'shariipsalm91',
          followers: 1019,
          engagementRate: 12.420382165605096
        },
        youtubeProfile: {
          handle: 'sharayspence2765',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T09:54:20.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '7aae0e6b-9c85-482a-8123-3e1ad74f3da7',
      content: [
        {
          groupId: 'f3a59738-7e76-4201-bae8-570a7485bf55',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-18T19:40:31.952Z',

          createdAt: '2024-07-15T17:33:14.135Z',
          content: [
            {
              caption: '',

              id: 'e6f5a407-8321-4b47-9a45-67abf096213c',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7aae0e6b-9c85-482a-8123-3e1ad74f3da7/ugc/f3a59738-7e76-4201-bae8-570a7485bf55/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7cdf6cd5-a730-4d7d-8e0a-4bfd7a186281',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-18T19:40:37.965Z',

          createdAt: '2024-07-15T13:53:12.990Z',
          content: [
            {
              caption: '',

              id: 'cf26eb9e-d6af-453f-978f-5075b535a727',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/7aae0e6b-9c85-482a-8123-3e1ad74f3da7/ugc/7cdf6cd5-a730-4d7d-8e0a-4bfd7a186281/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '5e24f7b8-27ed-4253-8530-dd265a4ca6f5',
        firstName: 'Natasia',
        lastName: 'Robinson',
        email: 'duncannatasia@gmail.com',

        tiktokProfile: {
          handle: 'natasia_robinson',
          followers: 306,
          engagementRate: 1.9656019656019657
        },
        youtubeProfile: {
          handle: 'natasiaduncan',
          followers: 77
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T10:54:20.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '38cba402-3e0d-420a-bc38-868daa489a2a',
      content: [
        {
          groupId: '9f4e0d73-74e3-4943-b26a-ac2ca969773c',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-08T10:45:08.383Z',

          createdAt: '2024-07-04T18:10:50.273Z',
          content: [
            {
              caption: '',

              id: 'b4a49aff-104c-4d18-8511-8b54568f0dfa',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38cba402-3e0d-420a-bc38-868daa489a2a/ugc/9f4e0d73-74e3-4943-b26a-ac2ca969773c/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '2054450b-bd0f-46bc-ae7d-fd48c5359a37',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38cba402-3e0d-420a-bc38-868daa489a2a/ugc/9f4e0d73-74e3-4943-b26a-ac2ca969773c/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '5b8f2fa3-94fd-4734-a1b4-88b2bad0fa61',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38cba402-3e0d-420a-bc38-868daa489a2a/ugc/9f4e0d73-74e3-4943-b26a-ac2ca969773c/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '6e0584ee-eee4-4c36-b31d-9268404755e0',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-08T10:44:56.930Z',

          createdAt: '2024-07-04T18:56:08.884Z',
          content: [
            {
              caption: '',

              id: 'cded96a0-28ae-40d2-876f-135b907d3332',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/38cba402-3e0d-420a-bc38-868daa489a2a/ugc/6e0584ee-eee4-4c36-b31d-9268404755e0/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '44954f07-c238-4079-8471-c61fde88d7bc',
        firstName: 'Miranda',
        lastName: 'Spradling',
        email: 'mirandaspradling19@icloud.com',
        instagramProfile: {
          handle: 'emeryheart',
          followers: 804,
          engagementRate: 0.02487562189054727
        },
        tiktokProfile: {
          handle: 'emeryheart93',
          followers: 9
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T14:53:36.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'dd0efff8-6be1-428d-af52-187d30dea296',
      content: [
        {
          groupId: '7ed230cf-0ad5-43ec-bdad-a802ecc7e733',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: true,
          submittedAt: '2024-07-03T11:58:58.149Z',

          createdAt: '2024-07-01T23:46:04.134Z',
          content: [
            {
              caption: '',

              id: 'bc994282-a97e-402d-8470-cbfec9e777ec',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dd0efff8-6be1-428d-af52-187d30dea296/ugc/7ed230cf-0ad5-43ec-bdad-a802ecc7e733/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '82d15026-a0c3-4ee6-bb72-693cf59510bf',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dd0efff8-6be1-428d-af52-187d30dea296/ugc/7ed230cf-0ad5-43ec-bdad-a802ecc7e733/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '4aefa20b-8ee9-474c-937d-4c746ecce468',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dd0efff8-6be1-428d-af52-187d30dea296/ugc/7ed230cf-0ad5-43ec-bdad-a802ecc7e733/UGC-CONTENT-3.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a5d2c824-dfdf-4e6c-80ca-d402f130be1d',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-03T11:58:53.068Z',

          createdAt: '2024-07-01T23:46:56.836Z',
          content: [
            {
              caption: '',

              id: 'd64b3d09-c9ef-4a34-aec7-e9834eb1328b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/dd0efff8-6be1-428d-af52-187d30dea296/ugc/a5d2c824-dfdf-4e6c-80ca-d402f130be1d/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '8eeaf431-cf68-4fdf-879a-54b4a4072c70',
        firstName: 'Lauren',
        lastName: 'Billings',
        email: 'thelo_reneeblog@yahoo.com',
        instagramProfile: {
          handle: 'lo_renee',
          followers: 3253,
          engagementRate: 2.363971718413772
        },
        tiktokProfile: {
          handle: 'lo_renee',
          followers: 28
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-29T15:22:20.000Z',
        amountCents: 7681
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ba53f013-ae80-4143-a829-69b9e4b54d6d',
      content: [
        {
          groupId: '745b6058-522f-4d2e-8e07-f2160560b59f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-08-13T15:07:20.525Z',

          createdAt: '2024-08-12T16:04:45.527Z',
          content: [
            {
              caption: '',

              id: '48b2b15a-6ba1-458c-9b96-1efd938e1f43',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ba53f013-ae80-4143-a829-69b9e4b54d6d/ugc/745b6058-522f-4d2e-8e07-f2160560b59f/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'b54cbd3a-ab11-47d4-a5b5-5c08e2846270',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-08-07T10:56:08.454Z',

          createdAt: '2024-07-14T21:01:37.471Z',
          content: [
            {
              caption: '',

              id: 'ab32ca6a-d1bf-4bf4-a5bc-7635cb0bb424',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ba53f013-ae80-4143-a829-69b9e4b54d6d/ugc/b54cbd3a-ab11-47d4-a5b5-5c08e2846270/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: 'c119f7fb-f650-4a57-9d64-7b47fc1e822f',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ba53f013-ae80-4143-a829-69b9e4b54d6d/ugc/b54cbd3a-ab11-47d4-a5b5-5c08e2846270/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'd44315ea-63cd-4420-921c-589c07e572ab',
        firstName: 'Yacine',
        lastName: 'Bent',
        email: 'graceyacine22@gmail.com',
        instagramProfile: {
          handle: 'flairs_jewelry_collection',
          followers: 6096,
          engagementRate: 0.027800490596892886
        },
        tiktokProfile: {
          handle: 'yacine_flair',
          followers: 912,
          engagementRate: 12.275449101796406
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T09:04:07.000Z',
        amountCents: 8188
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c3002cab-0e1f-445f-ba69-98b124bf5154',
      content: [
        {
          groupId: '6216dd10-2f6c-4fff-9e59-c29792fee111',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-28T15:02:43.925Z',

          createdAt: '2024-06-27T16:56:08.964Z',
          content: [
            {
              caption: '',

              id: '2dc88b56-3fac-4fe6-b035-6ce86e212eff',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c3002cab-0e1f-445f-ba69-98b124bf5154/ugc/6216dd10-2f6c-4fff-9e59-c29792fee111/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7ac5fd49-c0fc-4468-8ea8-e6dcbbb704b1',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-28T15:02:52.246Z',

          createdAt: '2024-06-27T16:53:36.885Z',
          content: [
            {
              caption: '',

              id: '453306ad-9923-45b1-b474-0c15a9f70d92',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/c3002cab-0e1f-445f-ba69-98b124bf5154/ugc/7ac5fd49-c0fc-4468-8ea8-e6dcbbb704b1/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '6c81c6c1-3264-4994-abf1-a9c25db7ddc6',
        firstName: 'Nicolette',
        lastName: 'Thomas may pen',
        email: 'nicolettthomas139@gmail.com',
        instagramProfile: {
          handle: 'nickys_cosmetics',
          followers: 75,
          engagementRate: 6.666666666666667
        },
        tiktokProfile: {
          handle: 'queenzyviralvideo',
          followers: 10125,
          engagementRate: 7.365591397849462
        },
        youtubeProfile: {
          handle: 'queenzy.629',
          followers: 5
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T09:24:05.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '36ebe190-7eb3-41c2-a9df-a69f70c18bc5',
      content: [
        {
          groupId: 'a68c9d3f-959f-4144-ba61-807506211802',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-07-24T08:53:39.719Z',

          createdAt: '2024-07-20T16:53:16.663Z',
          content: [
            {
              caption: '',

              id: '75babb7f-e045-4d7e-ba85-5a1aad0340c8',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36ebe190-7eb3-41c2-a9df-a69f70c18bc5/ugc/a68c9d3f-959f-4144-ba61-807506211802/UGC-CONTENT-3.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '7abddfec-ada3-43fd-b55e-2820487c3440',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36ebe190-7eb3-41c2-a9df-a69f70c18bc5/ugc/a68c9d3f-959f-4144-ba61-807506211802/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '87e945c1-c1fa-4671-9514-1e3c89ed4471',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36ebe190-7eb3-41c2-a9df-a69f70c18bc5/ugc/a68c9d3f-959f-4144-ba61-807506211802/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: '9f26f39e-e0d2-46b8-8490-0cf567aa5f06',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-07-24T08:53:30.864Z',

          createdAt: '2024-07-22T02:00:39.882Z',
          content: [
            {
              caption: '',

              id: '1eeafd33-c8ac-48e9-ba8b-8e4506cfb821',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/36ebe190-7eb3-41c2-a9df-a69f70c18bc5/ugc/9f26f39e-e0d2-46b8-8490-0cf567aa5f06/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'dfea97e1-9932-4e61-b53d-f784e9964a18',
        firstName: 'Vanessa',
        lastName: 'Lawrence',
        email: 'lawrencevanessa04@gmail.com',
        instagramProfile: {
          handle: 'cleanersglitter_golden',
          followers: 571,
          engagementRate: 3.371278458844133
        },
        tiktokProfile: {
          handle: 'vanessaglitterboss',
          followers: 144
        },
        youtubeProfile: {
          handle: 'vanessalawrence5234',
          followers: 0
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-06-30T14:10:06.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '0732278e-ad76-49b5-b3f3-845ce6898df0',
      content: [],
      creator: {
        id: '3ffb7f68-77b7-421d-91da-c2e93b1f50e6',
        firstName: 'Riels',
        lastName: 'Pierce',
        email: 'cp112199@gmail.com',
        instagramProfile: {
          handle: 'rielschristian',
          followers: 7805,
          engagementRate: 0.8507367072389496
        },
        tiktokProfile: {
          handle: 'rielschristian',
          followers: 5252,
          engagementRate: 8.01091511548582
        },
        youtubeProfile: {
          handle: 'rielschristian',
          followers: 27
        }
      },
      purchaseDetails: {
        purchaseDate: '2024-07-01T10:54:25.000Z',
        amountCents: 7489
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '98e2cc70-883d-4c06-b8be-091e956262a8',
      content: [],
      creator: {
        id: '1639057f-94ea-4382-9557-11b1e04b9414',
        firstName: 'Jessica',
        lastName: 'West',
        email: 'collabwjess@gmail.com',
        instagramProfile: {
          handle: 'jess.di0rr',
          followers: 42344,
          engagementRate: 0.2326185528055923
        },
        tiktokProfile: {
          handle: 'jess.di0rr',
          followers: 8752,
          engagementRate: 6.8176160138299995
        },
        youtubeProfile: {
          handle: 'jess.di0rr',
          followers: 24
        }
      },

      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '42115984-6595-4f83-92db-e51036ce4b80',
      content: [],
      creator: {
        id: '409641f2-b59e-47d4-91a7-0e5a49b8beba',
        firstName: 'Nikita',
        lastName: 'WAKCHAURE',
        email: 'niks.wakchaure@gmail.com',
        instagramProfile: {
          handle: 'nusky21',
          followers: 2080,
          engagementRate: 2.0240384615384617
        },
        tiktokProfile: {
          handle: 'nikitawakchaure',
          followers: 226,
          engagementRate: 0
        },
        youtubeProfile: {
          handle: 'nikitawakchaure'
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'fe35582f-ae48-4d99-883b-eae51963eff5',
      content: [],
      creator: {
        id: 'ed751ce4-2633-4ec9-9964-0b8ee0ea0821',
        firstName: 'Amy',
        lastName: 'Yu',
        email: 'lestinepaye@gmail.com',
        instagramProfile: {
          handle: '1xcandy',
          followers: 3785,
          engagementRate: 0.7371202113606341
        },
        tiktokProfile: {
          handle: 'lestine.paye',
          followers: 339,
          engagementRate: 3.2200357781753133
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9679cde0-9472-4788-9e45-bee9f44f44b8',
      content: [],
      creator: {
        id: '04b66a2b-18f3-4ed4-a6d0-8abe0d6aafa2',
        firstName: 'Christopher',
        lastName: 'Byles',
        email: 'support@morebalancetraining.com',
        instagramProfile: {
          handle: 'morebalancetraining_llc',
          followers: 43409,
          engagementRate: 0.008293211085258817
        },
        tiktokProfile: {
          handle: 'morebalancetrng',
          followers: 594,
          engagementRate: 0.9266570692909043
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1ccae2c2-2df6-4007-9efd-316e4a3fc07c',
      content: [],
      creator: {
        id: 'd5e6e28f-8280-40b2-9239-3873fb049432',
        firstName: 'Monique ',
        lastName: 'Bascoe',
        email: 'moniquebascoegreen@gmail.com',

        tiktokProfile: {
          handle: 'icequeen546',
          followers: 51
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '2661f936-9991-48e0-ab2d-458e4f851558',
      content: [],
      creator: {
        id: '9aa59a59-d05d-4095-9933-36eadf6f1a92',
        firstName: 'Alleisha',
        lastName: 'Johnson',
        email: 'johnsonalleisha3@gmail.com',
        instagramProfile: {
          handle: 'leyyomozuki',
          followers: 133
        },
        tiktokProfile: {
          handle: 'leyyumeko',
          followers: 550
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '9c5c7340-a496-4a80-9c2a-9654036f3c04',
      content: [],
      creator: {
        id: '381c1a7e-85bb-4078-8b04-571564190905',
        firstName: 'Aubrey',
        lastName: 'Taylor',
        email: 'tayloraubrey292@gmail.com',
        instagramProfile: {
          handle: 'queenbree8896',
          followers: 989,
          engagementRate: 0.910010111223458
        },
        tiktokProfile: {
          handle: 'queen_bree8896',
          followers: 10188,
          engagementRate: 10.817307692307693
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'cfc225a9-24ad-4a95-afa5-2c5643bda2b2',
      content: [],
      creator: {
        id: '1c4d3f0b-f41b-4e84-a29f-b4f8a48d6cd2',
        firstName: 'Monique',
        lastName: 'Thompson-Heath',
        email: 'nique2294@yahoo.com',
        instagramProfile: {
          handle: 'cla_renique_fashions',
          followers: 3153,
          engagementRate: 0.1458928005074532
        },
        tiktokProfile: {
          handle: 'niquemlv92n',
          followers: 215,
          engagementRate: 3.1248222108437163
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'c5a98a0b-456c-48d2-b16c-0761acd9948e',
      content: [],
      creator: {
        id: '22154a84-0eb8-49ef-91b7-27721b843ecd',
        firstName: 'Jada',
        lastName: 'Hyde',
        email: 'jada.m.hyde20@gmail.com',
        instagramProfile: {
          handle: 'jaydon__22_',
          followers: 1014
        },
        tiktokProfile: {
          handle: 'spamfromjay.24',
          followers: 1537
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '68a2c619-558a-40f4-b3d2-ff1a4c4d16cb',
      content: [],
      creator: {
        id: 'c84147c2-12ff-4892-b294-151aa5fc614a',
        firstName: 'Yasheka',
        lastName: 'James',
        email: 'yashekajames123@icloud.com',
        instagramProfile: {
          handle: 'divvasbeautybar',
          followers: 1265,
          engagementRate: 0.11067193675889328
        },
        tiktokProfile: {
          handle: 'divvasbeautybar',
          followers: 3461,
          engagementRate: 2.19435736677116
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '1c680220-dfd5-4a1d-970c-4fc224ab2f35',
      content: [],
      creator: {
        id: '47c90f93-88cc-4806-b8e0-dd3e2d3ca8c4',
        firstName: 'Heather',
        lastName: 'Slack',
        email: 'bellevuebluebeauty@gmail.com',
        instagramProfile: {
          handle: 'bellevueblue',
          followers: 6393,
          engagementRate: 3.253558579696543
        },
        tiktokProfile: {
          handle: 'bellevueblu',
          followers: 4375,
          engagementRate: 3.6962365591397845
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '82a9e4eb-2825-485e-9b56-306e20b33e7e',
      content: [
        {
          groupId: '03e16d4a-f192-4314-a318-4aa8571dbe24',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-07T17:50:08.695Z',

          createdAt: '2024-06-06T14:25:56.921Z',
          content: [
            {
              caption: '',

              id: '76e837ee-4fb3-4972-990a-3948001fabb4',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/82a9e4eb-2825-485e-9b56-306e20b33e7e/ugc/03e16d4a-f192-4314-a318-4aa8571dbe24/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: 'babea369-19e7-4c94-bcec-a8e5212c5bed',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-07T17:50:12.914Z',

          createdAt: '2024-06-06T14:26:23.635Z',
          content: [
            {
              caption: '',

              id: '84ddcbf2-b6d9-4f70-a864-4533987c393b',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/82a9e4eb-2825-485e-9b56-306e20b33e7e/ugc/babea369-19e7-4c94-bcec-a8e5212c5bed/UGC-CONTENT-2.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '37a38d50-65d4-426a-9bca-5bf478df17e6',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/82a9e4eb-2825-485e-9b56-306e20b33e7e/ugc/babea369-19e7-4c94-bcec-a8e5212c5bed/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: 'ade57977-8a20-4d56-8d12-4a737b3d7792',
        firstName: 'Krysten',
        lastName: 'Loaiza',
        email: 'krysten@krystenloaiza.com',
        instagramProfile: {
          handle: 'krystenloaiza',
          followers: 13007,
          engagementRate: 0.6634888905973706
        },
        tiktokProfile: {
          handle: 'krystennloaiza',
          followers: 67
        },
        youtubeProfile: {
          handle: 'krystenloaiza',
          followers: 14
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '5c6ac46d-b4a8-4832-aedf-75ed5ce62aa1',
      content: [],
      creator: {
        id: 'f93d8c1e-ed72-4cf1-9409-abf2b2e87009',
        firstName: 'Rannay',
        lastName: 'Haughton',
        email: 'step898eny@gmail.com',
        instagramProfile: {
          handle: 'xpress.travels.deals',
          followers: 1444,
          engagementRate: 0.2770083102493075
        },
        tiktokProfile: {
          handle: 'xpress.travel.deals',
          followers: 948,
          engagementRate: 2.631578947368421
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ea5bb0b2-b195-47b2-abb8-ba935038fc32',
      content: [],
      creator: {
        id: '4722291e-36fe-4de0-8d50-ba4416009608',
        firstName: 'Emma',
        lastName: 'Thomas',
        email: 'emjade1990@gmail.com',
        instagramProfile: {
          handle: '_em.jade_',
          followers: 14217,
          engagementRate: 1.7598649504114792
        },
        tiktokProfile: {
          handle: 'em_j_10',
          followers: 3094,
          engagementRate: 4.273504273504273
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'f7f5c997-fe00-45db-a6b0-2eb18811b94c',
      content: [],
      creator: {
        id: 'e8faff22-232d-4243-a80c-a7a7920d3ff3',
        firstName: 'Sherikay',
        lastName: 'Blake',
        email: 'sherikay.blake@gmail.com',

        tiktokProfile: {
          handle: 'flawless_queen97',
          followers: 1628,
          engagementRate: 11.45374449339207
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '298d0f23-8823-4b7c-9786-17bab7a060f7',
      content: [],
      creator: {
        id: 'eed289db-3b76-4728-846c-fa433dfd75bc',
        firstName: 'Viktoriia',
        lastName: 'Golubkova',
        email: 'vikadove@mail.ru',
        instagramProfile: {
          handle: 'vikadove',
          followers: 11019,
          engagementRate: 1.5872583719030764
        },
        tiktokProfile: {
          handle: 'vikadove',
          followers: 1692,
          engagementRate: 0.26420079260237783
        },
        youtubeProfile: {
          handle: 'vikadove',
          followers: 787
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'ef32b38e-dfce-417b-a33f-acd360d6e255',
      content: [
        {
          groupId: 'f8325c4b-7269-48d5-80a9-a4a4ab69f779',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-06-03T18:19:57.742Z',

          createdAt: '2024-06-02T00:18:08.109Z',
          content: [
            {
              caption: '',

              id: '1529f508-1742-457f-ad10-717ac0bcbba9',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ef32b38e-dfce-417b-a33f-acd360d6e255/ugc/f8325c4b-7269-48d5-80a9-a4a4ab69f779/UGC-CONTENT-1.jpeg'
                }
              ]
            },
            {
              caption: '',

              id: '1ba48809-7621-46f1-9787-959509440f8e',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ef32b38e-dfce-417b-a33f-acd360d6e255/ugc/f8325c4b-7269-48d5-80a9-a4a4ab69f779/UGC-CONTENT-2.jpeg'
                }
              ]
            }
          ]
        },
        {
          groupId: 'a5665419-f172-4a17-a062-51901b947392',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:19:53.021Z',

          createdAt: '2024-06-02T00:16:51.513Z',
          content: [
            {
              caption: '',

              id: '2d45b3ab-9c60-4e99-b36e-83b272a6e463',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/ef32b38e-dfce-417b-a33f-acd360d6e255/ugc/a5665419-f172-4a17-a062-51901b947392/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '729a3db3-ae56-4c20-9008-fb013fad242f',
        firstName: 'Sammi',
        lastName: 'Vondrak',
        email: 'svondrak92@gmail.com',
        instagramProfile: {
          handle: 'sammivphotography',
          followers: 948,
          engagementRate: 0.7423117709437964
        },
        tiktokProfile: {
          handle: 'shelfcarewithsammi',
          followers: 1018,
          engagementRate: 5.72742474916388
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '64258c02-ce16-4f36-9525-0a639976156e',
      content: [],
      creator: {
        id: 'c69a11d4-cfc2-4703-a2eb-d3d8d1ba54e3',
        firstName: 'Jazarah',
        lastName: 'Morgan',
        email: 'paulaannpottinger@gmail.com',
        instagramProfile: {
          handle: 'blover123_',
          followers: 1919
        },
        tiktokProfile: {
          handle: 'lonersedits',
          followers: 3101
        },
        youtubeProfile: {
          handle: 'kiemoyt5414',
          followers: 1050
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: '4acaa573-ebc6-44f1-a0bb-41f85d9009a2',
      content: [
        {
          groupId: 'b3085ab4-1b90-4de1-8646-e93068485fb7',
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T18:37:07.995Z',

          createdAt: '2024-06-02T13:21:12.404Z',
          content: [
            {
              caption: '',

              id: '5324b191-e0c6-4069-b908-63cafaf918e1',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4acaa573-ebc6-44f1-a0bb-41f85d9009a2/ugc/b3085ab4-1b90-4de1-8646-e93068485fb7/UGC-CONTENT-1.mp4'
                }
              ]
            }
          ]
        },
        {
          groupId: '7b3dec22-f676-436b-9929-7979f9db0c4f',
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-06-03T08:34:28.929Z',

          createdAt: '2024-06-01T21:01:53.801Z',
          content: [
            {
              caption: '',

              id: 'f74451f6-3332-49e4-af7d-f33e9a2db476',

              assets: [
                {
                  type: 'IMAGE',
                  url: 'https://media.productwind.com/4acaa573-ebc6-44f1-a0bb-41f85d9009a2/ugc/7b3dec22-f676-436b-9929-7979f9db0c4f/UGC-CONTENT-1.jpeg'
                }
              ]
            }
          ]
        }
      ],
      creator: {
        id: '525fce8f-9542-4845-b7d3-8d7d2a26d90e',
        firstName: 'Danielle',
        lastName: 'Rodriguez',
        email: 'takingabiteoutofbrooklyn@gmail.com',
        instagramProfile: {
          handle: 'takingabiteoutofbrooklyn',
          followers: 626,
          engagementRate: 1.0063897763578273
        },
        tiktokProfile: {
          handle: 'deeeeeehl',
          followers: 42,
          engagementRate: 6.563706563706563
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    },
    {
      userCampaignId: 'b68f9272-4dcf-4bb0-9177-fe44aa0e88c1',
      content: [],
      creator: {
        id: '603f9bb2-606f-4ebe-b1c2-25629aa75c26',
        firstName: 'Tevon',
        lastName: 'Bygrave',
        email: 'summons-seldom0d@icloud.com',
        instagramProfile: {
          handle: 'iamtevii',
          followers: 9846,
          engagementRate: 0.47396573904800593
        },
        tiktokProfile: {
          handle: 'iamtevii',
          followers: 710,
          engagementRate: 8.391608391608392
        },
        youtubeProfile: {
          handle: 'tevonbygrave8504',
          followers: 0
        }
      },

      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC
    }
  ]
}

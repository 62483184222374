import Video from '@momentum/components/video'
import { NoPreview } from '@momentum/components/no-preview'
import { isVideo } from '@productwindtom/shared-node'
import { Box } from '@mui/material'
import React, { forwardRef } from 'react'

const VideoOrImage = forwardRef(
  (
    {
      src,
      alt,
      height,
      width,
      disabled,
      controls,
      children,
      objectFit = 'cover',
      bgcolor
    }: {
      src?: string
      alt?: string
      objectFit?: 'cover' | 'contain'
      width?: number
      height?: number
      disabled?: boolean
      controls?: boolean
      children?: React.ReactNode
      bgcolor?: string
    },
    ref
  ) => {
    const isVideoAsset = src && isVideo(src)

    return (
      <Box
        height={height}
        width={width}
        bgcolor={bgcolor || '#F8FBFF'}
        borderRadius={'4px'}
        boxSizing={'border-box'}
        display={isVideoAsset ? 'flex' : 'block'}
        position={'relative'}
        onContextMenu={e => e.preventDefault()}
      >
        {isVideoAsset ? (
          <Video ref={ref} disabled={disabled} controls={controls} src={src} width={'100%'} height={'100%'} objectFit={objectFit} />
        ) : src ? (
          <img
            src={src}
            alt={alt}
            style={{
              height: '100%',
              width: '100%',
              objectFit: objectFit,
              objectPosition: 'center'
            }}
          />
        ) : (
          <NoPreview height={'100%'} />
        )}
        {children}
      </Box>
    )
  }
)

export default VideoOrImage

import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Paper, Stack, Typography } from '@mui/material'
import { CreatorApprovalStatus } from '@productwindtom/shared-momentum-zeus-types'
import { useMemo } from 'react'
import { useApproveCreatorsContext } from './approveCreatorsContext'

export const PendingApprovalsCard = () => {
  const { creators } = useApproveCreatorsContext()

  const pendingApprovalsCount = useMemo(() => {
    return creators.filter(creator => creator.approvalState.status === CreatorApprovalStatus.PENDING).length
  }, [creators])

  if (!pendingApprovalsCount) {
    return null
  }

  return (
    <Paper sx={{ px: 4, py: 2, width: 280 }}>
      <Stack direction={'row'} spacing={2}>
        <WarningAmberIcon color="warning" fontSize="medium" />
        <Stack direction={'column'} spacing={1}>
          <Typography variant="label3">
            You have {pendingApprovalsCount.toLocaleString()} {pendingApprovalsCount === 1 ? 'creator' : 'creators'} to
            review!
          </Typography>
          <Typography variant="body1">Please review creators within 24 hours.</Typography>
        </Stack>
      </Stack>
    </Paper>
  )
}

import Row from '@momentum/components/row'
import { Box, Typography } from '@mui/material'

const FinancialFlowRow = ({
  title,
  bgcolor,
  children
}: {
  title?: string
  bgcolor?: string
  children: React.ReactNode
}) => {
  return (
    <Row
      spacing={2}
      textAlign={'center'}
      alignItems={'stretch'}
      maxWidth={'100%'}
      bgcolor={bgcolor}
      sx={{
        ...(bgcolor && { pb: 2, pr: 2, ml: -2 })
      }}
    >
      <Box flex={1} textAlign={'left'} alignItems={'center'}>
        {!!title && <Typography variant="label2">{title}</Typography>}
      </Box>
      {children}
    </Row>
  )
}

export default FinancialFlowRow

import { Stack, Typography } from '@mui/material'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'

export const ProductsHeader = () => {
  const { selectedCompany, isAdminView } = useUserSessionContext()
  const { brand } = useBrandContext()

  const crumbs = useClientBreadcrumbs(selectedCompany, brand)

  return (
    <Stack mt={3} spacing={1}>
      {isAdminView && <RoutesBreadcrumb crumbs={crumbs} />}
      <Typography variant={'h3'}>All products</Typography>
      <Typography variant={'h5'}>{brand.name}</Typography>
    </Stack>
  )
}

import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTE_NAMES_PROPOSAL } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import { CampaignImpact } from '@momentum/routes/proposals-create/modules/estmated-deliverables/CampaignImpact'
import { CampaignTimeline } from '@momentum/routes/proposals-create/modules/estmated-deliverables/CampaignTimeline'
import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { InfoOutlined, SaveAltOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, ButtonGroup, Divider, Link, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { getProposalCreatorCounts, ProposalCreatorCounts, STORE_TO_CURRENCY } from '@productwindtom/shared-momentum'
import { Currency, ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { getOrBlank } from '@productwindtom/shared-node'
import { captureException } from '@sentry/react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createProposalSlide } from '../../mutations'

enum DeliverablesTab {
  IMPACT,
  TIMELINE
}

export const EstimatedDeliverables = () => {
  const [tabSelected, setTabSelected] = useState(DeliverablesTab.IMPACT)

  const {
    watch,
    formState: { isValid }
  } = useFormContext<ProposalCreateForm>()
  const { pathname } = useLocation()

  const { company } = useBrandContext()
  const { isAdminView, agencies } = useUserSessionContext()

  const { isDisplayResults, selectedProduct, locale, markAsDraft, markAsPublished, ...results } =
    useEstimatedDeliverables()
  const { proposal } = useCreateProposalContext()
  const [creatingPresentation, setCreatingPresentation] = useState(false)

  const formData = watch()

  const creatorPricing = useWatch<ProposalCreateForm, 'creatorPricing'>({ name: 'creatorPricing' })
  const proposalCreatorCounts = getProposalCreatorCounts(creatorPricing)
  const isAgencyUser = agencies.some(a => a.id === company?.agencyId)
  const canDownloadPresentation =
    isValid &&
    proposalCreatorCounts.totalCreators &&
    [
      ProposalGoal.PRODUCT_LAUNCH,
      ProposalGoal.BOOST_RATING,
      ProposalGoal.EXISTING_PRODUCT,
      ProposalGoal.OTHER
    ].includes(formData.goal)

  const isMonthly = formData?.goal === ProposalGoal.EVERGREEN
  const monthlySuffix = isMonthly ? '   /mo' : ''
  const creditsSuffix = ' credits' + monthlySuffix

  const isDisplayData =
    tabSelected === DeliverablesTab.IMPACT ? isDisplayResults : isDisplayResults && formData.launchDate

  const isLastPage = pathname.endsWith(ROUTE_NAMES_PROPOSAL.REVIEW)

  useEffect(() => {
    if (pathname.endsWith(ROUTE_NAMES_PROPOSAL.TIMELINE)) {
      setTabSelected(DeliverablesTab.TIMELINE)
    } else if (pathname.endsWith(ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS)) {
      setTabSelected(DeliverablesTab.IMPACT)
    }
  }, [pathname])

  const getLastUpdatedString = () => {
    if (!proposal) {
      return
    }

    if (proposal.updatedAt) {
      const dateTime = DateTime.fromISO(proposal.updatedAt)
      return `Saved: ${dateTime.toLocaleString(DateTime.TIME_SIMPLE)} on ${dateTime.toLocaleString(DateTime.DATE_SHORT)} by ${proposal.updatedBy?.firstName} ${proposal.updatedBy?.lastName[0]}.`
    }
  }

  const updatedString = getLastUpdatedString()

  const handleDownloadPresentation = async () => {
    setCreatingPresentation(true)
    try {
      const result = await createProposalSlide({
        productId: selectedProduct!.id,
        creatorPricing: formData.creatorPricing.map(cp => ({
          type: cp.type,
          price: cp.price,
          numCreators: cp.numCreators
        })),
        goal: formData.goal,
        ratingGoal: formData.ratingGoal,
        exchangeRate: formData.exchangeRate || 1
      })
      window.open(result.link, '_blank')
      toast(
        <Link variant={'subtitle2'} href={result.link} target="_blank">
          {result.link}
        </Link>,
        { type: 'info' }
      )
    } catch (e) {
      captureException(e)
      toast(<Typography variant={'subtitle2'}>Error creating presentation</Typography>, { type: 'error' })
    }
    setCreatingPresentation(false)
  }

  return (
    <Box px={5} py={3} sx={{ opacity: isDisplayData ? 1 : 0.4 }}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
              {!!updatedString && updatedString}
            </Typography>
            <Stack direction={'row'}>
              {isLastPage && (
                <ButtonGroup
                  color="primary"
                  variant="outlined"
                  size="medium"
                  disableRipple
                  disableElevation
                  style={{
                    alignSelf: 'flex-end'
                  }}
                >
                  <Button
                    variant={tabSelected === DeliverablesTab.IMPACT ? 'contained' : 'outlined'}
                    value={DeliverablesTab.IMPACT}
                    onClick={() => setTabSelected(DeliverablesTab.IMPACT)}
                  >
                    Deliverables
                  </Button>
                  <Button
                    variant={tabSelected === DeliverablesTab.TIMELINE ? 'contained' : 'outlined'}
                    value={DeliverablesTab.TIMELINE}
                    onClick={() => setTabSelected(DeliverablesTab.TIMELINE)}
                  >
                    Timeline
                  </Button>
                </ButtonGroup>
              )}
            </Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h3'}>
              {tabSelected === DeliverablesTab.IMPACT ? 'Campaign impact' : 'Campaign timeline'}
            </Typography>
            {(isAdminView || isAgencyUser) && tabSelected === DeliverablesTab.IMPACT && (
              <LoadingButton
                variant={'text'}
                startIcon={<SaveAltOutlined />}
                onClick={handleDownloadPresentation}
                loading={creatingPresentation}
                disabled={!canDownloadPresentation}
              >
                Download presentation
              </LoadingButton>
            )}
          </Stack>
        </Stack>
        <CreatorOverview proposalCreatorCounts={proposalCreatorCounts} />
        <Divider />
        {tabSelected === DeliverablesTab.IMPACT && <CampaignImpact />}
        {tabSelected === DeliverablesTab.TIMELINE && <CampaignTimeline />}
        <Divider />

        <Stack spacing={1}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'label1'}>Creator cost</Typography>
            <Typography variant={'label2'}>
              {results?.totalCreatorCostCredits != null
                ? results.totalCreatorCostCredits.toLocaleString() + creditsSuffix
                : '-'}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <Typography variant={'label1'}>Product cost</Typography>
              {selectedProduct?.store && STORE_TO_CURRENCY[selectedProduct?.store] !== Currency.USD && (
                <Tooltip
                  title={`Credits are in USD and using an exchange rate of ${formData.exchangeRate?.toFixed(2)} USD to ${STORE_TO_CURRENCY[selectedProduct?.store]}.`}
                >
                  <InfoOutlined sx={{ color: theme => theme.palette.grey.A700 }} fontSize={'mSmall'} />
                </Tooltip>
              )}
            </Stack>
            <Typography variant={'label2'}>
              {results?.totalProductCostCredits != null
                ? results.totalProductCostCredits.toLocaleString() + creditsSuffix
                : '-'}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack my={3} direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'h4'}>Campaign cost</Typography>
          <Typography variant={'h4'} data-cy="campaignCost">
            {results?.totalCostCredits != null ? results.totalCostCredits.toLocaleString() + creditsSuffix : '-'}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

const CreatorOverview = ({ proposalCreatorCounts }: { proposalCreatorCounts: ProposalCreatorCounts }) => {
  const { totalCreators, brandAdvocateProposalCount, ugcProposalCount, premiumUgcProposalCount, socialProposalCount } =
    proposalCreatorCounts

  return (
    <Stack spacing={2}>
      <Typography variant={'label1'}>{getOrBlank(totalCreators)} total creators</Typography>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric label={'Brand advocates'} value={getOrBlank(brandAdvocateProposalCount)} />
        <Metric label={'UGC'} value={getOrBlank(ugcProposalCount)} />
        <Metric label={'Premium UGC'} value={getOrBlank(premiumUgcProposalCount)} />
        <Metric label={'Social'} value={getOrBlank(socialProposalCount)} />
      </Stack>
    </Stack>
  )
}

const Metric = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack spacing={1} alignItems={'center'}>
      <Typography variant={'label2'}>{value}</Typography>
      <Typography variant={'body2'}>{label}</Typography>
    </Stack>
  )
}

import { Link, Stack, Typography } from '@mui/material'
import NoSubscriptionImage from './NoSubscription.png'

export const NotSubscribed = () => {
  return (
    <Stack alignItems={'center'} spacing={4} py={8}>
      <img src={NoSubscriptionImage} alt={'You are not subscribed'} width={203} />
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <Typography variant={'h5'}>You are not subscribed.</Typography>
      </Stack>
      <Stack alignItems={'center'}>
        <Typography variant={'label3'}>Contact Momentum to subscribe</Typography>
        <Link href={'mailto:sales@momentum.online'} variant={'label3'} target={'_blank'}>
          sales@momentum.online
        </Link>
      </Stack>
    </Stack>
  )
}

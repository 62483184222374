import { mApi } from '@momentum/api'
import { UserInvitationStatus, GraphQLTypes, InputType, Selector } from '@productwindtom/shared-momentum-zeus-types'

const companySelector = Selector('Company')({
  id: true,
  name: true,
  brands: {
    id: true,
    name: true
  }
})

const invitationSelector = Selector('UserInvitation')({
  id: true,
  companyId: true,
  company: companySelector,
  brandIds: true,
  status: true,
  email: true,
  isAllBrandAccess: true,
  primaryBrandId: true,
  phoneNumber: true,
  firstName: true,
  lastName: true,
  role: true,
  createdAt: true
})

export type Invitation = InputType<GraphQLTypes['UserInvitation'], typeof invitationSelector>

export const listPendingInvitations = async (nextToken?: string): Promise<Invitation[]> => {
  const resp = await mApi('query')({
    listInvitationsByStatus: [
      { status: UserInvitationStatus.PENDING, nextToken },
      {
        items: invitationSelector,
        nextToken: true
      }
    ]
  })
  if (resp?.listInvitationsByStatus?.nextToken) {
    return [
      ...resp.listInvitationsByStatus.items,
      ...(await listPendingInvitations(resp.listInvitationsByStatus.nextToken))
    ]
  }
  return resp.listInvitationsByStatus.items
}

const userSelector = Selector('User')({
  id: true,
  email: true,
  firstName: true,
  lastName: true,
  primaryBrandId: true,
  lastActiveAt: true,
  accessLevel: true,
  role: true,
  companies: {
    companyId: true,
    isAllBrandAccess: true,
    userBrands: {
      brandId: true
    }
  }
})

export type User = InputType<GraphQLTypes['User'], typeof userSelector>
export const listUsers = async (nextToken?: string): Promise<User[]> => {
  const {
    listUsers: { nextToken: nt, items }
  } = await mApi('query')({
    listUsers: [{ nextToken }, { items: userSelector, nextToken: true }]
  })

  if (nt) {
    return [...items, ...(await listUsers(nt))]
  }
  return items
}

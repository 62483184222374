//@ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { generateEtailerProductMetrics } from '../../generators'
import { timelineViewGoal } from '@momentum/routes/campaign/e-commerce/traffic/Summary'

export const SAMPLE_ETAILER_PRODUCT_METRICS_HEADPHONES: ModelTypes['ModelItemsEtailerProductMetrics'] =
  generateEtailerProductMetrics({
    startDate: '2023-12-01',
    endDate: '2024-01-15',
    daysToReachViewsGoal: {
      goal: timelineViewGoal,
      days: 9
    },
    desiredViews: 230521,
    desiredUnitsSold: 3000
  })

import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import { Box, Stack, Typography } from '@mui/material'
import { useCallback } from 'react'
import { groupBy, map, set, sumBy } from 'lodash'
import { searchBrandContent } from './queries'
import { ContentFilterData, ContentSortBy } from '@momentum/sections/content/types'
import { useBrandContext } from '../context/BrandContext'
import { CONTENT_RELEVANCE_SORT } from './constants'
import ContentProvider from '@momentum/sections/content/context'
import { getHandle } from '@momentum/utils/socialUtils'
import { notEmpty } from '@productwindtom/shared-node'

const BrandContent = () => {
  const { selectedBrand, selectedCompany } = useUserSessionContext()
  const { campaigns } = useBrandContext()
  const crumbs = useClientBreadcrumbs(selectedCompany, selectedBrand)

  const handleSearch = useCallback(
    async (data: ContentFilterData) => {
      if (campaigns?.length) {
        const brandContent = (
          await searchBrandContent({
            campaignIds: data?.campaignIds?.length ? data.campaignIds : map(campaigns, 'id'),
            search: data?.search || '',
            minShouldMatch: data?.search ? 2 : 1,
            excludedContentTypes: data?.excludedContentTypes || [],
            sortBy: JSON.stringify(
              data?.sortBy === ContentSortBy.RELEVANCE
                ? CONTENT_RELEVANCE_SORT
                : {
                    postedAt: {
                      order: 'desc'
                    }
                  }
            ),
            isFavorite: !!data.isFavorite,
            isFavoriteMinShouldMatch: data.isFavorite ? 2 : 1
          })
        )?.items

        const byUcId = groupBy(brandContent, 'userCampaignId')

        return Object.values(byUcId)
          .reduce((acc, item) => {
            const byGroupId = groupBy(item, i => i?.content?.[0]?.groupId)

            return [
              ...acc,
              ...Object.entries(byGroupId)
                .map(([key, value]) => {
                  if (!key || !value) {
                    return undefined
                  }

                  const contentGroup = value[0]

                  set(
                    contentGroup,
                    'content[0].content',
                    value
                      .map(t => {
                        return [...(t?.content?.[0]?.content || [])]
                      })
                      .flat()
                  )

                  return contentGroup
                })
                .filter(notEmpty)
            ]
          }, [])
          .map(({ content, creator, ...rest }) =>
            content.map(o => ({
              ...creator,
              ...rest,
              ...o,
              creatorId: creator.id,
              qualityScore: sumBy(o.content, 'qualityScore'),
              handle: getHandle(o.channel, creator),
              engagements: sumBy(o.content, 'engagements'),
              views: sumBy(o.content, 'views'),
              likes: sumBy(o.content, 'likes'),
              isHidden: !!content.find(c => c.isHidden)
            }))
          )
          .flat()
      } else {
        return []
      }
    },
    [campaigns]
  )

  return (
    <Box pb={3}>
      <Stack mb={4} mt={3} spacing={1}>
        <RoutesBreadcrumb crumbs={crumbs} />
        <Typography variant={'h3'}>Brand content</Typography>
        <Typography variant={'h5'}>{selectedBrand?.name}</Typography>
      </Stack>
      <ContentProvider onFilterChange={handleSearch} isSearch />
    </Box>
  )
}

export default BrandContent

import './App.css'
import Navigation from './routes'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <div className="App">
      <Navigation />
    </div>
  )
}

export default App

import { Stack, Typography } from '@mui/material'
import { sumBy } from 'lodash'
import { Metric } from '../../common/Metric'
import { useSeoContext } from '../context'

export const SponsoredRankSummary = () => {
  const { productSeoSummary, productKeywordSummaries } = useSeoContext()

  const pageOneWinsSum = sumBy(productSeoSummary?.productSeoSummaryRecords, r => r.sponsoredPageOneWins ?? 0)
  const improvedSlotsSum =
    productKeywordSummaries
      ?.map(k =>
        k.sponsoredHighestRank && k.sponsoredStartRank && k.sponsoredHighestRank <= k.sponsoredStartRank
          ? Math.abs(k.sponsoredHighestRank - k.sponsoredStartRank)
          : 0
      )
      .reduce((acc, curr) => acc + curr, 0) ?? 0

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} width={'100%'}>
          <Typography variant={'h4'}>Sponsored rank summary</Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric label={'sponsored page 1 wins'} value={pageOneWinsSum.toLocaleString() ?? '--'} />
        <Metric label={'improved slots in sponsored rank'} value={improvedSlotsSum.toLocaleString() ?? '--'} />
      </Stack>
    </Stack>
  )
}

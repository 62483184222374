import Row from '@momentum/components/row'
import { useApproveContentContext } from '../context/ApproveContentContext'
import { Stack, Typography } from '@mui/material'
import Copy from '@momentum/components/copy'

const DraftCaption = () => {
  const {
    content: { draftText }
  } = useApproveContentContext()

  if (!draftText) return null

  return (
    <Stack spacing={1}>
      <Row>
        <Typography variant="link2">Caption</Typography>
        <Copy
          iconButtonProps={{
            color: 'primary',
            size: 'small'
          }}
          copyText={draftText}
          successMessage="Copied caption!"
        />
      </Row>
      <Typography variant="body2">{draftText}</Typography>
    </Stack>
  )
}

export default DraftCaption

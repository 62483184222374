import { EmptyIcon } from './EmptyIcon'
import { Stack, Typography } from '@mui/material'
import { PerformanceComparisonMetric } from '@productwindtom/shared-momentum-zeus-types'
import React from 'react'
import { capitalize } from 'lodash'
import { COMPARISON_METRIC_LABELS } from '@momentum/routes/campaign/e-commerce/performance/timeToUtils'

export const EmptyStateDataUnavailable = ({ metric }: { metric: PerformanceComparisonMetric }) => {
  const typeText = COMPARISON_METRIC_LABELS[metric]

  return (
    <Stack pt={12} spacing={3} alignItems={'center'} justifyContent={'center'}>
      <EmptyIcon sx={{ height: '137px', width: '168px' }} />
      <Typography variant={'h5'}>{capitalize(typeText)} data is unavailable</Typography>
      <Typography variant={'label4'}>
        This product has no {typeText} benchmarking data. This could occur if the product listing was variated or
        unvariated.
      </Typography>
    </Stack>
  )
}

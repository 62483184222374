import { ContentCopy } from '@mui/icons-material'
import { IconButton, IconButtonProps, Typography } from '@mui/material'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'

const Copy = ({
  copyText,
  successMessage = 'Copied to clipboard!',
  iconButtonProps
}: {
  copyText: string
  successMessage?: string
  iconButtonProps?: IconButtonProps
}) => {
  const handleCopy = () => {
    copy(copyText)
    toast(<Typography variant={'subtitle2'}>{successMessage}</Typography>, { type: 'success' })
  }

  return (
    <IconButton onClick={handleCopy} {...iconButtonProps}>
      <ContentCopy fontSize={iconButtonProps?.size} />
    </IconButton>
  )
}

export default Copy

import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { forwardRef, Ref } from 'react'
import { useFormContext } from 'react-hook-form'

const SubmitButton = forwardRef(
  (
    {
      children,
      disableOnDirty = true,
      ...props
    }: LoadingButtonProps & {
      disableOnDirty?: boolean
    },
    ref?: Ref<HTMLButtonElement>
  ) => {
    const {
      formState: { isSubmitting, isDirty, isValid }
    } = useFormContext()

    return (
      <LoadingButton
        type="submit"
        loading={isSubmitting}
        disabled={isSubmitting || (disableOnDirty && !isDirty) || !isValid}
        {...props}
        ref={ref}
      >
        {children}
      </LoadingButton>
    )
  }
)

export default SubmitButton

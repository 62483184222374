import { useContext, createContext } from 'react'
import { useWatch } from 'react-hook-form'
import { ProposalCreateForm } from '../../types'
import { keyBy } from 'lodash'
import { FinancialSummaryContextType, FinancialSummaryCreatorType } from '../types'
import { useFlowSummaryConfig } from './hooks/useFlowSummaryConfig'
import { useCreateProposalContext } from '../CreateProposalContext'

const FinancialSummaryContext = createContext<FinancialSummaryContextType>({} as FinancialSummaryContextType)

const FinancialSummaryProvider = ({ children }: { children: React.ReactNode }) => {
  const { proposal, selectedProduct } = useCreateProposalContext()

  const liftNumCreators = useWatch<ProposalCreateForm, 'liftNumCreators'>({ name: 'liftNumCreators' })
  const liftCloseoutBonus = useWatch<ProposalCreateForm, 'liftCloseoutBonus'>({ name: 'liftCloseoutBonus' })

  const creatorPricing = useWatch<ProposalCreateForm, 'creatorPricing'>({ name: 'creatorPricing' })
  const creatorsByType = keyBy(creatorPricing, c => c.type) as FinancialSummaryCreatorType

  const flowSummary = useFlowSummaryConfig(creatorPricing, creatorsByType, liftNumCreators, liftCloseoutBonus)

  const productPrice =
    (proposal?.productPriceOverride ? proposal.productPriceOverride : selectedProduct?.priceCents || 0) / 100

  return (
    <FinancialSummaryContext.Provider
      value={{
        creatorsByType,
        flowSummary,
        productPrice
      }}
    >
      {children}
    </FinancialSummaryContext.Provider>
  )
}

export default FinancialSummaryProvider

export const useFinancialSummaryContext = () => useContext(FinancialSummaryContext)

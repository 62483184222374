import { mApi } from '@momentum/api'
import { creatorContentSelector } from '@momentum/sections/content/selectors'
import { ContentType } from '@productwindtom/shared-momentum-zeus-types'

export const searchBrandContent = async ({
  campaignIds,
  search,
  minShouldMatch,
  excludedContentTypes,
  sortBy,
  isFavorite,
  isFavoriteMinShouldMatch
}: {
  campaignIds: string[]
  search?: string
  minShouldMatch: number
  excludedContentTypes: ContentType[]
  sortBy: string
  isFavorite: boolean
  isFavoriteMinShouldMatch: number
}) => {
  return (
    await mApi('query')({
      searchBrandContent: [
        { campaignIds, search, minShouldMatch, excludedContentTypes, sortBy, isFavorite, isFavoriteMinShouldMatch },
        {
          items: creatorContentSelector
        }
      ]
    })
  ).searchBrandContent
}

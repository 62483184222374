import { CampaignClick } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_CLICKS_ENERGY_DRINK_PD_EXISTING: { items: CampaignClick[] } = {
  items: [
    {
      date: '2024-06-18',
      clicks: 1733
    },
    {
      date: '2024-06-19',
      clicks: 3046
    },
    {
      date: '2024-06-20',
      clicks: 2920
    },
    {
      date: '2024-06-21',
      clicks: 1786
    },
    {
      date: '2024-06-22',
      clicks: 2531
    },
    {
      date: '2024-06-23',
      clicks: 976
    },
    {
      date: '2024-06-24',
      clicks: 1277
    },
    {
      date: '2024-06-25',
      clicks: 843
    },
    {
      date: '2024-06-26',
      clicks: 1669
    },
    {
      date: '2024-06-27',
      clicks: 1130
    },
    {
      date: '2024-06-28',
      clicks: 371
    },
    {
      date: '2024-06-29',
      clicks: 892
    },
    {
      date: '2024-06-30',
      clicks: 465
    },
    {
      date: '2024-07-01',
      clicks: 493
    },
    {
      date: '2024-07-02',
      clicks: 545
    },
    {
      date: '2024-07-03',
      clicks: 259
    },
    {
      date: '2024-07-04',
      clicks: 163
    },
    {
      date: '2024-07-05',
      clicks: 362
    },
    {
      date: '2024-07-06',
      clicks: 518
    },
    {
      date: '2024-07-07',
      clicks: 873
    },
    {
      date: '2024-07-08',
      clicks: 622
    },
    {
      date: '2024-07-09',
      clicks: 382
    },
    {
      date: '2024-07-10',
      clicks: 480
    },
    {
      date: '2024-07-11',
      clicks: 476
    },
    {
      date: '2024-07-12',
      clicks: 785
    },
    {
      date: '2024-07-13',
      clicks: 511
    },
    {
      date: '2024-07-14',
      clicks: 732
    },
    {
      date: '2024-07-15',
      clicks: 457
    },
    {
      date: '2024-07-16',
      clicks: 789
    },
    {
      date: '2024-07-17',
      clicks: 587
    },
    {
      date: '2024-07-18',
      clicks: 791
    },
    {
      date: '2024-07-19',
      clicks: 818
    },
    {
      date: '2024-07-20',
      clicks: 495
    },
    {
      date: '2024-07-21',
      clicks: 544
    },
    {
      date: '2024-07-22',
      clicks: 563
    },
    {
      date: '2024-07-23',
      clicks: 386
    },
    {
      date: '2024-07-24',
      clicks: 228
    },
    {
      date: '2024-07-25',
      clicks: 297
    },
    {
      date: '2024-07-26',
      clicks: 219
    },
    {
      date: '2024-07-27',
      clicks: 232
    },
    {
      date: '2024-07-28',
      clicks: 305
    },
    {
      date: '2024-07-29',
      clicks: 393
    },
    {
      date: '2024-07-30',
      clicks: 311
    },
    {
      date: '2024-07-31',
      clicks: 348
    },
    {
      date: '2024-08-01',
      clicks: 236
    },
    {
      date: '2024-08-02',
      clicks: 227
    },
    {
      date: '2024-08-03',
      clicks: 181
    },
    {
      date: '2024-08-04',
      clicks: 235
    },
    {
      date: '2024-08-05',
      clicks: 129
    },
    {
      date: '2024-08-06',
      clicks: 130
    },
    {
      date: '2024-08-07',
      clicks: 55
    },
    {
      date: '2024-08-08',
      clicks: 36
    },
    {
      date: '2024-08-09',
      clicks: 48
    },
    {
      date: '2024-08-10',
      clicks: 28
    },
    {
      date: '2024-08-11',
      clicks: 23
    },
    {
      date: '2024-08-12',
      clicks: 11
    },
    {
      date: '2024-08-13',
      clicks: 21
    },
    {
      date: '2024-08-14',
      clicks: 3
    },
    {
      date: '2024-08-15',
      clicks: 18
    },
    {
      date: '2024-08-16',
      clicks: 6
    },
    {
      date: '2024-08-17',
      clicks: 7
    },
    {
      date: '2024-08-18',
      clicks: 9
    },
    {
      date: '2024-08-19',
      clicks: 5
    },
    {
      date: '2024-08-20',
      clicks: 2
    },
    {
      date: '2024-08-21',
      clicks: 1
    },
    {
      date: '2024-08-22',
      clicks: 5
    },
    {
      date: '2024-08-23',
      clicks: 1
    },
    {
      date: '2024-08-24',
      clicks: 5
    },
    {
      date: '2024-08-26',
      clicks: 3
    },
    {
      date: '2024-08-27',
      clicks: 2
    },
    {
      date: '2024-08-28',
      clicks: 1
    },
    {
      date: '2024-08-29',
      clicks: 5
    },
    {
      date: '2024-08-30',
      clicks: 1
    },
    {
      date: '2024-08-31',
      clicks: 2
    },
    {
      date: '2024-09-02',
      clicks: 2
    },
    {
      date: '2024-09-03',
      clicks: 1
    },
    {
      date: '2024-09-04',
      clicks: 1
    },
    {
      date: '2024-09-06',
      clicks: 1
    },
    {
      date: '2024-09-07',
      clicks: 3
    },
    {
      date: '2024-09-14',
      clicks: 1
    },
    {
      date: '2024-09-15',
      clicks: 1
    },
    {
      date: '2024-09-17',
      clicks: 2
    },
    {
      date: '2024-09-18',
      clicks: 1
    },
    {
      date: '2024-09-20',
      clicks: 3
    },
    {
      date: '2024-09-23',
      clicks: 1
    },
    {
      date: '2024-09-24',
      clicks: 2
    },
    {
      date: '2024-09-25',
      clicks: 2
    },
    {
      date: '2024-09-26',
      clicks: 4
    },
    {
      date: '2024-09-27',
      clicks: 2
    },
    {
      date: '2024-09-28',
      clicks: 1
    },
    {
      date: '2024-10-01',
      clicks: 1
    },
    {
      date: '2024-10-02',
      clicks: 2
    },
    {
      date: '2024-10-03',
      clicks: 2
    },
    {
      date: '2024-10-05',
      clicks: 1
    },
    {
      date: '2024-10-06',
      clicks: 1
    },
    {
      date: '2024-10-07',
      clicks: 1
    },
    {
      date: '2024-10-08',
      clicks: 2
    }
  ]
}

import { ProductSeoSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'

export const SAMPLE_CAMPAIGN_SEO_WIRELESS_EARBUDS: ProductSeoSummary = {
  campaignId: SAMPLE_CAMPAIGN_WIRELESS_EARBUDS.id,
  productSeoSummaryRecords: [
    {
      fromDate: '2024-06-03',
      toDate: '2024-06-09',
      searchVolume: 143,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0
    },
    {
      fromDate: '2024-06-10',
      toDate: '2024-06-16',
      searchVolume: 3634,
      organicPageOneWins: 4,
      sponsoredPageOneWins: 0
    },
    {
      fromDate: '2024-06-17',
      toDate: '2024-06-23',
      searchVolume: 3331,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 1
    },
    {
      fromDate: '2024-06-24',
      toDate: '2024-06-30',
      searchVolume: 2122,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0
    },
    {
      fromDate: '2024-07-01',
      toDate: '2024-07-07',
      searchVolume: 2068,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0
    },
    {
      fromDate: '2024-07-08',
      toDate: '2024-07-14',
      searchVolume: 863,
      organicPageOneWins: 1,
      sponsoredPageOneWins: 0
    },
    {
      fromDate: '2024-07-15',
      toDate: '2024-07-21',
      searchVolume: 845,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0
    },
    {
      fromDate: '2024-07-22',
      toDate: '2024-07-28',
      searchVolume: 1083,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0
    },
    {
      fromDate: '2024-07-29',
      toDate: '2024-08-04',
      searchVolume: 962,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 1
    },
    {
      fromDate: '2024-08-05',
      toDate: '2024-08-11',
      searchVolume: 922,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 1
    },
    {
      fromDate: '2024-08-12',
      toDate: '2024-08-18',
      searchVolume: 1029,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 1
    },
    {
      fromDate: '2024-08-19',
      toDate: '2024-08-25',
      searchVolume: 969,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0
    },
    {
      fromDate: '2024-08-26',
      toDate: '2024-09-01',
      searchVolume: 0,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0
    }
  ]
}

import { Stack, Typography } from '@mui/material'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import RefreshRecommendations from './RefreshRecommendations'
import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'

const BrandHeader = () => {
  const { selectedCompany, isAdminView } = useUserSessionContext()
  const { brand } = useBrandContext()

  const crumbs = useClientBreadcrumbs(selectedCompany, brand)

  return (
    <Stack mb={5} mt={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Stack spacing={1}>
        <RoutesBreadcrumb crumbs={crumbs} />
        <Typography variant={'h3'}>Campaigns</Typography>
        <Typography variant={'h5'}>{brand.name}</Typography>
      </Stack>
      {isAdminView && <RefreshRecommendations />}
    </Stack>
  )
}

export default BrandHeader

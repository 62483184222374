import { Channel, ContentType, DraftStatus, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CONTENT_PENDING_APPROVAL_LAUNDRY: Array<ModelTypes['CreatorDraftContent']> = [
  {
    id: '1ca0ddfa-7d42-417c-9b54-18df98efda78',
    creator: {
      id: '1ca0ddfa-7d42-417c-9b54-18df98efda78',
      email: 'kaylateahon@yahoo.com',
      firstName: 'Kayla',
      lastName: 'Teahon',
      instagramProfile: {
        handle: 'kaylateahon',
        followers: 15316,
        engagementRate: 2.6808566205275532,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'ok_kt3',
        followers: 85,
        isPrimary: false
      }
    },
    draftContent: [
      {
        id: '17fd2f22-e49d-42e8-a614-1db64a6aef69',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-24T00:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['stories/17991356531190771/VIDEO.mp4']
      }
    ]
  },
  {
    id: 'c6b54c09-2875-4eb6-8f51-9e3ad1d45c04',
    creator: {
      id: 'c6b54c09-2875-4eb6-8f51-9e3ad1d45c04',
      email: 'thecaliforniabelles@gmail.com',
      firstName: 'Jordan',
      lastName: 'Boncquet',
      instagramProfile: {
        handle: 'california_belles',
        followers: 28434,
        engagementRate: 0.3168741647323627,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'california_belles',
        followers: 1160,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: '124f9f78-bb32-4d82-90f5-f12bcff077ce',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-29T00:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['stories/17998540928090467/VIDEO.mp4']
      },
      {
        id: 'bfed2aaa-5b64-4809-97be-ee3669a6a7da',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_REEL,
        isHidden: false,
        draftSubmittedAt: '2023-08-29T12:33:19.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['posts/Cwh1MDCpdQe/MEDIA_0.mp4']
      },
      {
        id: '65b9dc0f-27c0-4ebf-ab3b-b26e79fddd00',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-29T00:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['stories/18073216213393556/VIDEO.mp4']
      }
    ]
  },
  {
    id: '4b589b05-03f8-479b-83b3-81513d56c77c',
    creator: {
      id: '4b589b05-03f8-479b-83b3-81513d56c77c',
      email: 'nataly8698@gmail.com',
      firstName: 'nataly',
      lastName: 'perez',
      instagramProfile: {
        handle: 'nataly_perez',
        followers: 2442,
        engagementRate: 1.3554463554463556,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'natalyp845',
        followers: 0
      }
    },
    draftContent: [
      {
        id: 'dfdabf98-a6f0-4535-ba0a-dcc15b9783ab',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_REEL,
        isHidden: false,
        draftSubmittedAt: '2023-08-29T19:39:24.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['posts/CwimA1mtmh6/MEDIA_0.mp4']
      },
      {
        id: '6d9dcffb-40ce-42b6-a6e1-05b6e73fda78',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-28T00:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['stories/17991309893057210/VIDEO.mp4']
      },
      {
        id: 'e67f69bd-0c8f-4ffd-bd20-81154b9c53a5',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-30T00:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['stories/18001227940893032/VIDEO.mp4']
      }
    ]
  },
  {
    id: '2246513f-93ff-4d63-94db-4cc521c5dfbc',
    creator: {
      id: '2246513f-93ff-4d63-94db-4cc521c5dfbc',
      email: 'littlefriedls@gmail.com',
      firstName: 'Amanda',
      lastName: 'Friedl',
      instagramProfile: {
        handle: 'amandajfriedl',
        followers: 43937,
        engagementRate: 1.6403031613446528,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'flipscot',
        followers: 2597,
        engagementRate: 1.3937282229965158,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: '69d6f281-e094-4fe7-b0b0-b397c257e537',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-23T00:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['stories/18010503898842079/VIDEO.mp4']
      },
      {
        id: 'd8a805c8-94eb-4310-87b5-2d29bcd36045',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-23T00:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['stories/18028687696587127/VIDEO.mp4']
      },
      {
        id: 'cb0ae661-dd75-4914-841a-a529247f3b31',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_REEL,
        isHidden: false,
        draftSubmittedAt: '2023-08-23T16:28:52.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['posts/CwSzRx_tghW/MEDIA_0.mp4']
      }
    ]
  },
  {
    id: 'a5aa9d8b-4b0b-4a4f-bdf2-8239b6a90a4c',
    creator: {
      id: 'a5aa9d8b-4b0b-4a4f-bdf2-8239b6a90a4c',
      email: 'foxyveganbiz@gmail.com',
      firstName: 'Ashlee',
      lastName: 'Dume',
      instagramProfile: {
        handle: '_foxyfoody',
        followers: 1029,
        engagementRate: 2.3712342079689015,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'foxyashlee',
        followers: 16128,
        engagementRate: 4.1490194412928405,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'foxyvegan',
        followers: 576
      }
    },
    draftContent: [
      {
        id: '24992a04-7e98-4cdd-a6b4-13a729168de3',
        channel: Channel.TIKTOK,
        contentType: ContentType.TT_VIDEO,
        isHidden: true,
        draftSubmittedAt: '2023-09-07T14:46:10.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['posts/7276095281052142894/MEDIA_0.mp4']
      }
    ]
  },
  {
    id: '34de5d37-55ab-497f-8925-e829d62cb245',
    creator: {
      id: '34de5d37-55ab-497f-8925-e829d62cb245',
      email: 'haleyvg63@gmail.com',
      firstName: 'Haley',
      lastName: 'Van Gilder',
      tiktokProfile: {
        handle: 'chase.and.haley',
        followers: 46192,
        engagementRate: 5.614406779661017,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: '60a9e9f4-e92f-4a4d-9382-d291371d262a',
        channel: Channel.TIKTOK,
        contentType: ContentType.TT_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-25T07:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: [
          'stories/34de5d37-55ab-497f-8925-e829d62cb245/IS_REQUIRED/33a90052-76f3-4ed5-abde-a1b99cbeebf5/tiktok-story-1.mp4'
        ]
      },
      {
        id: '3676c3b5-6610-4165-8bb3-594db95d4be6',
        channel: Channel.TIKTOK,
        contentType: ContentType.TT_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-25T07:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: [
          'stories/34de5d37-55ab-497f-8925-e829d62cb245/IS_REQUIRED/176aa5f7-07f0-4fd5-9e8a-f9d88d65880e/tiktok-story-1.mp4'
        ]
      },
      {
        id: '6cae2b66-fa24-4ec1-979f-3601c3d75387',
        channel: Channel.TIKTOK,
        contentType: ContentType.TT_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2023-08-16T20:08:35.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['posts/7268014555450625322/MEDIA_0.mp4']
      }
    ]
  },
  {
    id: '3f8adc0a-8e75-4464-89eb-f93fc01a11bc',
    creator: {
      id: '3f8adc0a-8e75-4464-89eb-f93fc01a11bc',
      email: 'sierraroy97@gmail.com',
      firstName: 'Sierra',
      lastName: 'Sterling',
      tiktokProfile: {
        handle: 'sterlingworld',
        followers: 3386,
        engagementRate: 6.120479046008234,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: 'aff124ee-6b26-4371-8ee8-035f6eddbf6c',
        channel: Channel.TIKTOK,
        contentType: ContentType.TT_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2023-08-18T18:48:53.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['posts/7268736180605275435/MEDIA_0.mp4']
      },
      {
        id: 'c62d38ea-cd31-43f9-af7b-340b50275d96',
        channel: Channel.TIKTOK,
        contentType: ContentType.TT_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-26T04:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: [
          'stories/3f8adc0a-8e75-4464-89eb-f93fc01a11bc/IS_REQUIRED/b3835949-6f01-4aee-9719-c22e644163b6/tiktok-story-1.mp4'
        ]
      },
      {
        id: '536285e0-08c5-4492-9320-67dbc6ed101e',
        channel: Channel.TIKTOK,
        contentType: ContentType.TT_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-09-08T05:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: [
          'stories/3f8adc0a-8e75-4464-89eb-f93fc01a11bc/IS_REQUIRED/c48c769a-bb35-452a-87b5-67e0a3550884/tiktok-story-1.mp4'
        ]
      }
    ]
  },
  {
    id: 'aae44dbb-34b4-4e47-b30c-326c32724c47',
    creator: {
      id: 'aae44dbb-34b4-4e47-b30c-326c32724c47',
      email: 'graceannryu.hello@gmail.com',
      firstName: 'Grace',
      lastName: 'Ryu',
      instagramProfile: {
        handle: 'graciasryu',
        followers: 1625,
        engagementRate: 4.2215384615384615,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'graceannryu',
        followers: 74481,
        engagementRate: 2.886464266623854,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: '4df73d46-34a5-4399-9244-4c2df1cc61ee',
        channel: Channel.TIKTOK,
        contentType: ContentType.TT_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2023-08-16T18:39:07.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['posts/7267991487416372526/MEDIA_0.mp4']
      }
    ]
  },
  {
    id: '25d8905d-4934-49ec-ad0e-88567fd1b781',
    creator: {
      id: '25d8905d-4934-49ec-ad0e-88567fd1b781',
      email: 'sirensandchampagne@gmail.com',
      firstName: 'Yasmina',
      lastName: 'Williams',
      instagramProfile: {
        handle: 'sirensandchampagne',
        followers: 29520,
        engagementRate: 0.2567750677506775,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'sirensandchampagne',
        followers: 714,
        engagementRate: 4.875325115910891,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'yasminacolony7810'
      }
    },
    draftContent: [
      {
        id: '32663177-3ef6-4bdb-9547-c0f63de0682c',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_REEL,
        isHidden: false,
        draftSubmittedAt: '2023-08-25T16:17:21.000Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: ['posts/CwX7qLwsWH6/MEDIA_0.mp4']
      },
      {
        id: '66da21c6-1bfe-4ed2-8ba8-03d5ce221ce4',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-09-01T00:00:00.000Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: ['stories/17986799723514221/VIDEO.mp4']
      }
    ]
  },
  {
    id: '886af559-9d11-4ac7-a415-b85f3dd4cd7c',
    creator: {
      id: '886af559-9d11-4ac7-a415-b85f3dd4cd7c',
      email: 'himylifeisqueen@gmail.com',
      firstName: 'Queen',
      lastName: 'Ekejija',
      instagramProfile: {
        handle: 'mylifeisqueen',
        followers: 70277,
        engagementRate: 12.449734621568934,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'mylifeisqueen_',
        followers: 42087,
        engagementRate: 8.229166666666666,
        isPrimary: true
      }
    },
    draftContent: []
  },
  {
    id: 'd51a11c2-ebd6-40d9-ae2e-94155df25a85',
    creator: {
      id: 'd51a11c2-ebd6-40d9-ae2e-94155df25a85',
      email: 'damacenoaliceneves@gmail.com',
      firstName: 'Alice',
      lastName: 'Neves',
      instagramProfile: {
        handle: 'damacenoalice',
        followers: 97606,
        engagementRate: 1.2471569370735407,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: '7d5eef09-0ae4-42f6-ae40-8dee5e45d430',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-31T00:00:00.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['stories/18301252996117092/VIDEO.mp4']
      },
      {
        id: '377764d3-e3e3-4415-b18d-e76c06925907',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_REEL,
        isHidden: false,
        draftSubmittedAt: '2023-08-30T22:18:24.000Z',
        draftStatus: DraftStatus.APPROVED,
        draftMedia: ['posts/Cwlc7aKSTl-/MEDIA_0.mp4']
      }
    ]
  },
  {
    id: '85611f6d-5faa-4d4b-87d7-74abb1ba35ac',
    creator: {
      id: '85611f6d-5faa-4d4b-87d7-74abb1ba35ac',
      email: 'elenmdelrosario@gmail.com',
      firstName: 'Elen',
      lastName: 'del Rosario',
      instagramProfile: {
        handle: 'elenmarri',
        followers: 246050,
        engagementRate: 0.7776874618979882,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: '41e9eb8f-a5e3-40e6-bc95-99034bc1efb0',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_REEL,
        isHidden: false,
        draftSubmittedAt: '2023-08-31T17:33:17.000Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: ['posts/CwnhCLHra1I/MEDIA_0.mp4']
      }
    ]
  },
  {
    id: 'ab134651-e64d-4acf-8bba-e5934c593de6',
    creator: {
      id: 'ab134651-e64d-4acf-8bba-e5934c593de6',
      email: 'lexieforstner@gmail.com',
      firstName: 'Alexis',
      lastName: 'Forstner',
      instagramProfile: {
        handle: 'lexielforstner',
        followers: 20941,
        engagementRate: 2.8131416837782344,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'lexielforstner',
        followers: 8343,
        engagementRate: 4.57796852646638,
        isPrimary: false
      }
    },
    draftContent: [
      {
        id: '03ba17bd-9a2a-4151-aaaa-96e625ceb675',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-26T00:00:00.000Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: ['stories/17987743895087014/IMAGE.jpg']
      }
    ]
  },
  {
    id: '3a902e6f-e4e5-4680-92b0-cae3a3141074',
    creator: {
      id: '3a902e6f-e4e5-4680-92b0-cae3a3141074',
      email: 'debynhaoliver@icloud.com',
      firstName: 'Debora',
      lastName: 'Oliver',
      instagramProfile: {
        handle: 'debbyoliverbenites',
        followers: 142723,
        engagementRate: 5.2004932631741205,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'debbyoliverbenites',
        followers: 37242,
        engagementRate: 0.9957048244236915,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: 'e423a2d2-9e3e-4bb6-b21f-b52852eefcda',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_STORY,
        isHidden: false,
        draftSubmittedAt: '2023-08-25T00:00:00.000Z',
        draftStatus: DraftStatus.REQUIRES_USER_CHANGES,
        draftMedia: ['stories/18363043078069911/VIDEO.mp4']
      },
      {
        id: '49a79ef9-6976-4967-b8eb-68784239b827',
        channel: Channel.INSTAGRAM,
        contentType: ContentType.IG_REEL,
        isHidden: false,
        draftSubmittedAt: '2023-08-25T16:25:35.000Z',
        draftStatus: DraftStatus.REQUIRES_USER_CHANGES,
        draftMedia: ['posts/CwX7FXEIgYs/MEDIA_0.mp4']
      }
    ]
  }
]

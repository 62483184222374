import StarRating from '@momentum/components/star-rating'
import TableLink from '@momentum/components/table-link'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import { WarningAmber } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Store } from '@productwindtom/shared-momentum-zeus-types'
import { isNumber } from 'lodash'

export const PRODUCT_COLUMN_DEFINITIONS: GridColDef[] = [
  {
    field: 'image',
    headerName: 'Image',
    resizable: false,
    width: 65,
    renderCell: ({ value, row }) =>
      value && (
        <img src={getCdnImageUrl(value)} alt={'product'} width={36} height={36} style={{ objectFit: 'contain' }} />
      )
  },
  {
    field: 'name',
    headerName: 'Product Name',
    flex: 1,
    cellClassName: '--wrap-cell',
    renderCell: ({ value, row }) =>
      row.listingLink ? (
        <TableLink to={row.listingLink} label={value} className={'--two-lines'} />
      ) : (
        <Typography variant={'body2'}>{value}</Typography>
      )
  },

  {
    field: 'store',
    headerName: 'Retailer',
    resizable: false,
    align: 'center',
    headerAlign: 'center',
    width: 75,
    renderCell: ({ value }) =>
      StoreToIcon[value as Store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })
  },
  {
    field: 'variation',
    headerName: 'Variation'
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 128
  },
  {
    field: 'numRatings',
    headerName: 'Reviews & ratings',
    sortable: true,
    width: 128,
    renderCell: ({ value, row }) => (row.isParent ? <RatingAlert /> : value && value.toLocaleString())
  },
  {
    field: 'rating',
    headerName: 'Rating',
    sortable: true,
    width: 128,
    renderCell: ({ value, row }) =>
      row.isParent ? <RatingAlert /> : isNumber(value) ? <StarRating rating={value} /> : value
  }
]
const RatingAlert = () => {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <WarningAmber color={'warning'} fontSize={'mSmall'} />
      <Typography variant={'body2'}>Not merged</Typography>
    </Stack>
  )
}

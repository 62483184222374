import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { ZeusInterceptor } from '../types'
import { getSampleBrand } from '../utils'

export const updateBrand: ZeusInterceptor<'mutation', 'updateBrand'> = async args => {
  const sampleBrand = getSampleBrand(args.o.updateBrand?.[0].input.id!)
  if (sampleBrand) {
    return {
      ...sampleBrand,
      ...(args.o.updateBrand?.[0].input as ModelTypes['Brand'])
    }
  }
}

import { Image, Page, Text, View } from '@react-pdf/renderer'
import { DateTime } from 'luxon'
import { FeedbackPDFProps } from '.'
import LogoImage from './images/logo.png'
import { styles } from './styles'

export const PagePDF = (props: FeedbackPDFProps & { children: React.ReactNode }) => {
  return (
    <Page
      size={{
        // A4 size
        width: 595, // effective with is 575 because of padding
        height: 841 // 821
      }}
      style={[styles.page, styles.debug]}
      wrap={false}
    >
      <Image
        source={LogoImage}
        style={{
          width: 174,
          height: 31,
          alignSelf: 'center',
          marginTop: 40
        }}
      />

      <View
        style={[
          styles.row,
          styles.debug,
          {
            marginTop: 40,
            marginBottom: 24,
            height: 22,
            alignItems: 'center',
            paddingHorizontal: 16
          }
        ]}
      >
        <Text style={[styles.label1, styles.debug]}>{props.campaignDetails.title}</Text>
        <Text
          style={[
            styles.caption3,
            styles.debug,
            {
              fontWeight: 500,
              marginLeft: 16
            }
          ]}
        >
          {DateTime.fromISO(props.campaignDetails.startDate).toLocaleString(DateTime.DATE_MED)}
          {' - '}
          {props.campaignDetails.endDate
            ? DateTime.fromISO(props.campaignDetails.endDate).toLocaleString(DateTime.DATE_MED)
            : 'today'}
        </Text>
      </View>

      <View
        style={[
          styles.debug,
          {
            height: 648
          }
        ]}
      >
        {props.children}
      </View>

      <Text
        style={[
          styles.caption3,
          styles.debug,
          {
            textAlign: 'right',
            justifyContent: 'center',
            color: '#767676',
            height: 16
          }
        ]}
      >
        Last updated: {DateTime.fromISO(props.productFeedbackSummary.updatedAt).toLocaleString(DateTime.DATE_MED)}
      </Text>
    </Page>
  )
}

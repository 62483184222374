import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { Search } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import { Button, ButtonGroup, Stack } from '@mui/material'

export const ProductsActions = ({
  onSearch,
  onExpandAll,
  onAddProduct
}: {
  onSearch: (search: string) => void
  onExpandAll: (isExpanded: boolean) => void
  onAddProduct: () => void
}) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} mb={2}>
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <DebouncedTextField
          placeholder={'Search ASIN or product title'}
          sx={{ mb: 4, width: 300 }}
          onChange={value => onSearch(value.trim().toLowerCase())}
          InputProps={{
            endAdornment: <Search />
          }}
        />
      </Stack>
      <Stack direction={'row'} spacing={2}>
        <ButtonGroup variant="outlined">
          <Button onClick={() => onExpandAll(true)} startIcon={<AddCircleRoundedIcon />}>
            Expand all
          </Button>
          <Button onClick={() => onExpandAll(false)} startIcon={<RemoveCircleRoundedIcon />}>
            Collapse all
          </Button>
        </ButtonGroup>

        <Button variant={'contained'} onClick={onAddProduct} startIcon={<AddIcon />} disableElevation>
          Add product
        </Button>
      </Stack>
    </Stack>
  )
}

import { Container, Stack, Typography, Box } from '@mui/material'
import { Navigate, useParams, useSearchParams, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import background from '@momentum/routes/account/background-gradient.png'
import { toast } from 'react-toastify'
import { ROUTES } from '@momentum/routes/RouteNames'
import LogoWhiteIcon from '@momentum/components/icons/logo-white'
import { Form, TextInput, SubmitButton } from '@productwindtom/ui-base'
import { resetPassword } from './queries'
import { captureException } from '@sentry/react'
import { passwordValidator } from '@momentum/routes/account/utils'

type ForgotPasswordSetPasswordData = {
  email: string
  password: string
  confirmPassword: string
}

const resetPasswordSchema = yup.object().shape({
  email: yup.string().required('Required'),
  password: passwordValidator,
  confirmPassword: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password')], 'Your passwords do not match.')
})

export default function ForgotPasswordSetPassword() {
  const [searchParams] = useSearchParams({})
  const { code } = useParams<{ code: string }>()
  const navigate = useNavigate()

  const onSubmit = async ({ email, password }: ForgotPasswordSetPasswordData) => {
    try {
      const resp = await resetPassword({
        username: email,
        password,
        code: code!
      })

      if (!resp.success) throw resp

      toast(<Typography variant={'subtitle2'}>Password successfully changed!</Typography>, { type: 'success' })
      navigate(ROUTES.LOGIN)
    } catch (e: any) {
      captureException(e)
      toast(
        <Typography variant={'subtitle2'}>
          {e.code === 'PASSWORD_ALREADY_USED'
            ? 'This password has already been used, please choose a different password.'
            : 'An error has occurred, please try again later!'}
        </Typography>,
        {
          type: 'error'
        }
      )
      console.log(e.code)
    }
  }

  const email = searchParams.get('username') || ''

  if (!email || !email) {
    return <Navigate to={ROUTES.LOGIN} replace={true} />
  }

  return (
    <Container maxWidth={'xl'} sx={{ height: '100vh', alignItems: 'center' }}>
      <Stack direction={'row'} spacing={10} height={'100vh'}>
        <Box py={2} width={'70%'} display={'inline-flex'}>
          <Box
            bgcolor={theme => theme.palette.primary.main}
            borderRadius={'16px'}
            display={'inline-flex'}
            width={'100%'}
            position={'relative'}
          >
            <Stack direction={'row'} spacing={1} alignItems={'center'} position={'absolute'} left={24} top={24}>
              <LogoWhiteIcon fontSize={'large'} />
              <Typography variant={'h3'} mb={2} color={'white'}>
                momentum
              </Typography>
            </Stack>
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              spacing={2}
              width={'100%'}
              sx={{
                background: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            >
              <Typography color={'white'} variant={'h2'} width={'226px'}>
                Momentum
              </Typography>
              <Typography color={'white'} variant={'subtitle2'} width={'226px'}>
                Enter a new password
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box alignSelf={'center'} width={'30%'}>
          <Form
            onSubmit={onSubmit}
            defaultValues={{
              email,
              password: '',
              confirmPassword: ''
            }}
            resolver={yupResolver(resetPasswordSchema)}
          >
            <Stack spacing={2}>
              <Typography variant={'h2'} textAlign={'center'}>
                Forgot password
              </Typography>
              <TextInput
                data-cy={'passwordInput'}
                primaryText={'Password'}
                name={'password'}
                variant={'outlined'}
                type={'password'}
                placeholder={'Enter your password'}
                autoComplete={'new-password'}
                fullWidth
              />
              <TextInput
                data-cy={'confirmPasswordInput'}
                primaryText={'Confirm password'}
                name={'confirmPassword'}
                variant={'outlined'}
                type={'password'}
                placeholder={'Confirm your password'}
                autoComplete={'new-password'}
                fullWidth
              />
              <Box>
                <SubmitButton data-cy={'submitButton'} variant={'contained'} color={'primary'}>
                  Set new password
                </SubmitButton>
              </Box>
            </Stack>
          </Form>
        </Box>
      </Stack>
    </Container>
  )
}

import { createRef, useEffect, useMemo, useState } from 'react'

import { Grid, Paper, Stack, Typography } from '@mui/material'

const KEY_CODE = {
  BACKSPACE: 'Backspace',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  DELETE: 'Delete'
}

export default function VerificationCodeInput({
  length = 6,
  readOnly = false,
  success = false,
  onChange = () => {},
  onCompleted = () => {},
  placeholder = ' ',
  value: pValue
}: {
  length?: number
  readOnly?: boolean
  success?: boolean
  onChange?: (value: string) => void
  onCompleted?: (value: string) => void
  placeholder?: string
  value: string
}) {
  const emptyValue = new Array(length).fill(placeholder)
  const [activeIndex, setActiveIndex] = useState(-1)
  const [value, setValue] = useState(pValue ? pValue.split('') : emptyValue)
  const [isCompleted, setIsCompleted] = useState(false)

  const codeInputRef = createRef<HTMLInputElement>()
  const itemsRef = useMemo(() => new Array(length).fill(null).map(() => createRef<HTMLInputElement>()), [length])
  const isCodeRegex = new RegExp(`^[0-9]{${length}}$`)

  const getItem = (index: number) => itemsRef[index]?.current
  const focusItem = (index: number) => getItem(index)?.focus()
  const blurItem = (index: number) => getItem(index)?.blur()

  const onItemFocus = (index: number) => () => {
    if (readOnly) return
    if (isCompleted) {
      setValue(emptyValue)
      setIsCompleted(false)
    }
    setActiveIndex(index)
    if (codeInputRef.current) codeInputRef.current.focus()
  }

  const onInputKeyUp = ({ key, code }: React.KeyboardEvent<HTMLInputElement>) => {
    const newValue = [...value]
    const nextIndex = activeIndex + 1
    const prevIndex = activeIndex - 1

    const codeInput = codeInputRef.current
    const currentItem = getItem(activeIndex)

    const isLast = nextIndex === length
    const isDeleting = code === KEY_CODE.DELETE || code === KEY_CODE.BACKSPACE

    // keep items focus in sync
    onItemFocus(activeIndex)

    // on delete, replace the current value
    // and focus on the previous item
    if (isDeleting) {
      newValue[activeIndex - 1] = placeholder
      setValue(newValue)

      if (activeIndex > 0) {
        setActiveIndex(prevIndex)
        focusItem(prevIndex)
      }

      return
    }

    // if the key pressed is not a number
    // don't do anything
    if (Number.isNaN(+key)) return

    // reset the current value
    // and set the new one
    if (codeInput) codeInput.value = ''
    newValue[activeIndex] = key
    setValue(newValue)

    if (!isLast) {
      setActiveIndex(nextIndex)
      focusItem(nextIndex)
      return
    }

    if (codeInput) codeInput.blur()
    if (currentItem) currentItem.blur()

    setActiveIndex(-1)
  }

  // handle mobile autocompletion
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: changeValue } = e.target
    const isCode = isCodeRegex.test(changeValue)

    if (!isCode) return

    setValue(changeValue.split(''))
    blurItem(activeIndex)
  }

  const onInputBlur = () => {
    // https://github.com/ugogo/react-input-verification-code/issues/1
    if (activeIndex === 0) return

    blurItem(activeIndex)
    setActiveIndex(-1)
  }

  // handle pasting
  useEffect(() => {
    const codeInput = codeInputRef.current
    if (!codeInput) return

    const onPaste = (e: ClipboardEvent) => {
      e.preventDefault()

      const pastedString = e.clipboardData?.getData('text')
      if (!pastedString) return

      const isNumber = !Number.isNaN(+pastedString)
      if (isNumber) setValue(pastedString.split(''))
    }

    codeInput.addEventListener('paste', onPaste)
    return () => codeInput.removeEventListener('paste', onPaste)
  }, [])

  useEffect(() => {
    const stringValue = value.join('')
    const isCompleted = !stringValue.includes(placeholder)
    setIsCompleted(isCompleted)

    if (isCompleted) onCompleted(stringValue)
    onChange(stringValue)
  }, [value])

  useEffect(() => {
    // avoid infinite loop
    if (pValue === '' && value.join('') === emptyValue.join('')) return

    // keep internal and external states in sync
    if (pValue !== value.join('')) setValue(pValue.split(''))
  }, [pValue])

  return (
    <Grid item position={'relative'}>
      <input
        style={{ position: 'absolute', opacity: 0, width: '0%' }}
        ref={codeInputRef}
        autoComplete="one-time-code"
        type="text"
        readOnly={readOnly}
        inputMode="decimal"
        id="one-time-code"
        // use onKeyUp rather than onChange for a better control
        // onChange is still needed to handle the autocompletion
        // when receiving a code by SMS
        onChange={onInputChange}
        onKeyUp={onInputKeyUp}
        onBlur={onInputBlur}
      />
      <Stack direction={'row'} spacing={1}>
        {itemsRef.map((ref, i) => (
          <Paper
            variant={'outlined'}
            style={{ height: '64px', cursor: 'pointer', borderRadius: '8px', width: '53px' }}
            square
            sx={{
              px: { xs: 0, md: 1 },
              borderColor: theme =>
                success || i === activeIndex ? theme.palette.primary.main : theme.palette.grey.A200
            }}
            key={i}
            ref={ref}
            role="button"
            tabIndex={0}
            onFocus={onItemFocus(i)}
          >
            <Grid container style={{ height: '100%' }} justifyContent={'center'} alignContent={'center'}>
              <Typography my={'auto'} variant={'h3'}>
                {value[i] || placeholder}
              </Typography>
            </Grid>
          </Paper>
        ))}
      </Stack>
    </Grid>
  )
}

import { CreatorApprovalStatus, CreatorType, ModelTypes, Region } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CREATORS_FOR_APPROVAL_WHITENING: Array<ModelTypes['CampaignCreatorForApproval']> = [
  {
    dateOfBirth: '1990-06-03',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2023-07-26T16:31:12.406Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'Very beauty focused especially on IG'
    },
    userCampaignId: '4e9bbcc6-6a13-451a-83d6-ade05c2f76c8',
    creator: {
      sponsoredPosts: [],
      id: '4e9bbcc6-6a13-451a-83d6-ade05c2f76c8',
      firstName: 'Ana',
      lastName: 'Dourado',
      email: 'karolsullivan1@gmail.com',
      instagramProfile: {
        handle: 'karolsullivan',
        followers: 224687,
        engagementRate: 4.310930316395697,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'karolsullivan1',
        followers: 138907,
        engagementRate: 4.7968797487589905,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1991-12-26',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.PENDING,
      updatedAt: '2023-07-26T17:41:49.673Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'minimal sponsored content, some foul language'
    },
    userCampaignId: '4f56ad44-8cff-4d73-972c-655acdaaec00',
    creator: {
      sponsoredPosts: [],
      id: '4f56ad44-8cff-4d73-972c-655acdaaec00',
      firstName: 'Reyhauna',
      lastName: 'Yates',
      email: 'reyhaunayates@gmail.com',
      instagramProfile: {
        handle: 'lifeofreyhauna',
        followers: 26237,
        engagementRate: 24.10069748827991,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'lifeofreyhauna',
        followers: 74025,
        engagementRate: 7.647674215859116,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'lifeofreyhauna'
      }
    }
  },
  {
    dateOfBirth: '1987-09-27',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T16:30:37.068Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'would like more TikTok reach'
    },
    userCampaignId: 'c33202f1-733f-45ec-8525-ac80339b1c18',
    creator: {
      sponsoredPosts: [],
      id: 'c33202f1-733f-45ec-8525-ac80339b1c18',
      firstName: 'Samanta',
      lastName: 'Cancelier',
      email: 'samantabio@hotmail.com',
      instagramProfile: {
        handle: 'meularnoseua',
        followers: 285498,
        engagementRate: 2.003901953779011,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'meularnoseua',
        followers: 11496,
        isPrimary: false
      }
    }
  },
  {
    dateOfBirth: '1981-11-05',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T16:32:28.895Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'would like more TikTok reach'
    },
    userCampaignId: 'b53f8e1d-7716-4048-a818-0ea8efa325fd',
    creator: {
      sponsoredPosts: [],
      id: 'b53f8e1d-7716-4048-a818-0ea8efa325fd',
      firstName: 'Danielle',
      lastName: 'Taylor',
      email: 'danny-alvarenga@hotmail.com',
      instagramProfile: {
        handle: 'cantinhonoseua',
        followers: 279197,
        engagementRate: 4.995397515016279,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'cantinhonoseua',
        followers: 3129,
        engagementRate: 6.198347107438017,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'cantinhonoseua'
      }
    }
  },
  {
    dateOfBirth: '1991-11-08',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T17:38:48.023Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com'
    },
    userCampaignId: '886af559-9d11-4ac7-a415-b85f3dd4cd7c',
    creator: {
      sponsoredPosts: [],
      id: '886af559-9d11-4ac7-a415-b85f3dd4cd7c',
      firstName: 'Queen',
      lastName: 'Ekejija',
      email: 'himylifeisqueen@gmail.com',
      instagramProfile: {
        handle: 'mylifeisqueen',
        followers: 57826,
        engagementRate: 4.604234158413939,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'mylifeisqueen_',
        followers: 41763,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1987-01-29',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T16:32:03.151Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com',
      feedback: 'good to have her in the mix but need more TikTok'
    },
    userCampaignId: '85611f6d-5faa-4d4b-87d7-74abb1ba35ac',
    creator: {
      sponsoredPosts: [],
      id: '85611f6d-5faa-4d4b-87d7-74abb1ba35ac',
      firstName: 'Elen',
      lastName: 'del Rosario',
      email: 'elenmdelrosario@gmail.com',
      instagramProfile: {
        handle: 'elenmarri',
        followers: 246891,
        engagementRate: 1.2629459964113718,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1982-08-11',
    locale: Region.US,
    creatorType: CreatorType.IG,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T16:32:36.429Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com'
    },
    userCampaignId: '3a902e6f-e4e5-4680-92b0-cae3a3141074',
    creator: {
      sponsoredPosts: [],
      id: '3a902e6f-e4e5-4680-92b0-cae3a3141074',
      firstName: 'Debora',
      lastName: 'Oliver',
      email: 'debynhaoliver@icloud.com',
      instagramProfile: {
        handle: 'debbyoliverbenites',
        followers: 142723,
        engagementRate: 5.2004932631741205,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'debbyoliverbenites',
        followers: 37242,
        engagementRate: 0.9957048244236915,
        isPrimary: true
      }
    }
  },
  {
    dateOfBirth: '1994-03-22',
    locale: Region.US,
    creatorType: CreatorType.TT,
    approvalState: {
      status: CreatorApprovalStatus.APPROVED,
      updatedAt: '2023-07-26T17:41:59.779Z',
      reviewedByUserId: '1b7faab7-cf91-4aa8-a417-d5b6f9c30c58',
      reviewedByUserEmail: 'corinne.west@churchdwight.com'
    },
    userCampaignId: '249c0333-2595-4652-8ba1-2da2813dec46',
    creator: {
      sponsoredPosts: [],
      id: '249c0333-2595-4652-8ba1-2da2813dec46',
      firstName: 'Marrianna',
      lastName: 'White',
      email: 'marriannawhiteugc@gmail.com',
      instagramProfile: {
        handle: '_mchante',
        followers: 1805,
        engagementRate: 8.89196675900277,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'marrichante',
        followers: 4078,
        engagementRate: 21.17329679551057,
        isPrimary: true
      }
    }
  }
]

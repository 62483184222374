import { Stack, Typography, Paper } from '@mui/material'
import { Summary as SummaryNumbers } from './Summary'
import { Timeline } from '@momentum/routes/campaign/overview/summary/Timeline'
import { Deliverables } from '@momentum/routes/campaign/overview/summary/Deliverables'

export const Summary = () => {
  return (
    <Stack spacing={5} data-cy={'overviewSummary'}>
      <Paper sx={{ px: 4, py: 2 }}>
        <SummaryNumbers />
      </Paper>

      <Stack spacing={3}>
        <Typography variant={'label1'}>Campaign timeline</Typography>
        <Timeline />
      </Stack>
      <Deliverables />
    </Stack>
  )
}

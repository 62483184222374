import { ThemeOptions } from '@mui/material'

export const typography: ThemeOptions['typography'] = {
  fontFamily: ['"Montserrat"'].join(','),
  allVariants: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    letterSpacing: '0.25px'
  },
  h1: {
    fontWeight: 700,
    fontSize: 48
  },
  h2: {
    fontWeight: 700,
    fontSize: 36
  },
  h3: {
    fontWeight: 600,
    fontSize: 24
  },
  h4: {
    fontWeight: 600,
    fontSize: 20
  },
  h5: {
    fontWeight: 600,
    fontSize: 12
  },
  h6: {
    fontSize: 12
  },
  body1: {
    fontSize: 16
  },
  body2: {
    fontSize: 14
  },
  formPrimaryText: {},
  formSubText: {},
  subtitle1: {
    fontWeight: 600,
    fontSize: 16
  },
  button: {
    fontWeight: 700,
    fontSize: 16
  }
} as any

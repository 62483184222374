import { isArray } from 'lodash'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { withInputBase } from '../InputBase/withInputBase'
import { CheckInputProps } from './types'

export const CheckInput = ({ field, options, checkboxProps, controlLabelProps, ...props }: CheckInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (field?.onChange) {
      const fieldValue = field.value || []

      const newValue = fieldValue.includes(value)
        ? field.value.filter((v: string) => v !== value)
        : [...fieldValue, value]

      field.onChange(newValue)
    }
  }

  return (
    <FormGroup {...field} {...props} onChange={isArray(options) ? handleChange : field?.onChange}>
      {isArray(options) ? (
        options.map(({ label, value }, index) => (
          <FormControlLabel
            key={index}
            {...{ label, value }}
            control={<Checkbox checked={field?.value?.includes(value)} {...checkboxProps} />}
            {...controlLabelProps}
          />
        ))
      ) : (
        <FormControlLabel
          label={props.label}
          control={<Checkbox checked={field?.value} {...checkboxProps} />}
          {...controlLabelProps}
        />
      )}
    </FormGroup>
  )
}

export default withInputBase(CheckInput)

import { CreatorType, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { countBy, sum } from 'lodash'
import { SOCIAL_CREATOR_TYPES } from '../configs'

export const getCampaignCreatorCounts = (campaignDetails: {
    numAdditionalBrandAdvocateCreatorsJoined?: number
    numBrandAdvocateCreators?: number
    numAdditionalBrandAdvocateCreators?: number
    numUgcCreators?: number
    numPremiumUgcCreators?: number
    numSocialCreators?: number
    numCreators?: number
    numCreatorsJoined?: number
    creators: {
        purchaseDetails?: ModelTypes['PurchaseDetails']
    }[]
}) => {
  const creatorsByType = countBy(
    campaignDetails.creators.filter(c => !!c.purchaseDetails),
    'creatorType'
  )

  const brandAdvocatesJoined =
    (campaignDetails.numAdditionalBrandAdvocateCreatorsJoined || 0) + (creatorsByType[CreatorType.ADVOCATE] || 0)
  const brandAdvocatesTotal =
    (campaignDetails.numBrandAdvocateCreators || 0) + (campaignDetails.numAdditionalBrandAdvocateCreators || 0)

  const socialJoined = sum(SOCIAL_CREATOR_TYPES.map(type => creatorsByType[type] || 0))

  return {
    // Expected
    numCreators: campaignDetails.numCreators || 0,
    brandAdvocatesTotal: brandAdvocatesTotal || 0,
    numUgcCreators: campaignDetails.numUgcCreators || 0,
    numPremiumUgcCreators: campaignDetails.numPremiumUgcCreators || 0,
    numSocialCreators: campaignDetails.numSocialCreators || 0,
    
    // Joined
    numCreatorsJoined: campaignDetails.numCreatorsJoined || 0,
    brandAdvocatesJoined: brandAdvocatesJoined || 0,
    socialJoined: socialJoined || 0,
    ugcJoined: creatorsByType[CreatorType.UGC] || 0,
    premiumUgcJoined: creatorsByType[CreatorType.PREMIUM_UGC] || 0,
    igJoined: creatorsByType[CreatorType.IG] || 0,
    ttJoined: creatorsByType[CreatorType.TT] || 0,
    ytJoined: creatorsByType[CreatorType.YOUTUBE] || 0,
  }
}

import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import Loading from '@momentum/components/loading'
import { DataGrid } from '@momentum/components/table'
import { EmptyState } from '@momentum/routes/brand/recommendations/EmptyState'
import { NotIntegratedState } from '@momentum/routes/brand/recommendations/NotIntegratedState'
import { Product } from '@momentum/routes/brand/types'
import { Search } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ItemClassification, RecommendationType, Store } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { capitalize, entries, first, maxBy, words } from 'lodash'
import { useMemo, useState } from 'react'
import { useBrandContext } from '../context/BrandContext'
import { HideSkuRecommendationsDialog } from './HideSkuRecommendationsDialog'
import { ShowSkuRecommendationsDialog } from './ShowSkuRecommendationsDialog'
import { RECOMMENDATION_COLUMN_DEFINITIONS } from './constants'
import { RECOMMENDATION_TYPE_TO_DESCRIPTION } from '@momentum/routes/recommendations/constants'
import { STORE_DETAILS } from '@momentum/utils/storeUtils'
import { withFormProvider, SwitchInput, SelectInput } from '@productwindtom/ui-base'
import { useWatch } from 'react-hook-form'

const ARCHIVED_STATE = 'ARCHIVED'

export const BrandRecommendations = withFormProvider(
  () => {
    const { recommendations, brand, adjustProductRecommendationVisibility } = useBrandContext()
    const [hideRecommendationsProduct, setHideRecommendationsProduct] = useState<Product | undefined>()
    const [showRecommendationsProduct, setShowRecommendationsProduct] = useState<Product | undefined>()
    const [processingProduct, setProcessingProduct] = useState(false)

    const [search, setSearch] = useState('')

    const { bundle, proposalTypeFilter, retailerFilter } = useWatch<{
      retailerFilter: Store | typeof ARCHIVED_STATE | string | null
      proposalTypeFilter: RecommendationType | typeof ARCHIVED_STATE | string | null
      bundle: boolean
    }>()

    const lowerSearch = search.toLowerCase()

    const recommendationsList = useMemo(() => {
      const recommendationsMapped = (recommendations || [])
        .map(r => ({
          recommendationType: getRecommendation(r.recommendations.map(r => r.recommendationType)),
          product: r
        }))
        .filter(r => r.recommendationType)

      const recommendationsGrouped = recommendationsMapped
        .filter(r => r.product.ratingSummary?.numRatings != null)
        .reduce(
          (acc: Record<string, Record<number, { product: Product; recommendationType?: RecommendationType }[]>>, r) => {
            const key = r.product.parentSkuId || r.product.id
            const parent = acc[key] || {}
            const ratingKey = r.product.ratingSummary?.numRatings || 0
            parent[ratingKey] = [...(parent[ratingKey] || []), r]

            return { ...acc, [key]: parent }
          },
          {}
        )

      return entries(recommendationsGrouped)
        .map(([skuId, value]) => {
          return entries(value).map(([numRatings, recommendations]) => {
            const pp = maxBy(recommendations, rr => rr.product.priceCents)
            if (!pp || !pp.recommendationType) {
              return undefined
            }

            return {
              product: pp.product,
              numRatings: pp.product.ratingSummary?.numRatings,
              rating: pp.product.ratingSummary?.rating,
              ...pp.product,
              brandId: brand.id,
              recommendationType: pp.recommendationType,
              variationCount: recommendations.length,
              variations: recommendations
            }
          })
        })
        .flat()
        .filter(notEmpty)
    }, [recommendations])

    const retailFilterLower = retailerFilter?.toLowerCase()

    const filteredRecommendations = recommendationsList.filter(r => {
      const searchMatch =
        !lowerSearch ||
        r.product.name.toLowerCase().includes(lowerSearch) ||
        r?.product?.skuId?.toLowerCase().includes(lowerSearch) ||
        r.variations.find(
          rr =>
            rr.product.name.toLowerCase().includes(lowerSearch) ||
            rr?.product?.skuId?.toLowerCase().includes(lowerSearch)
        )

      const recommendationTypeMatch = !proposalTypeFilter
        ? !r.isRecommendationsHidden
        : proposalTypeFilter === ARCHIVED_STATE
          ? r.isRecommendationsHidden
          : !r.isRecommendationsHidden && r.recommendationType === proposalTypeFilter

      const retailerMatch = !retailFilterLower || r.product.store.toLowerCase().includes(retailFilterLower)

      const bundleMatch = bundle || r.product.itemClassification !== ItemClassification.PRODUCT_BUNDLE

      return searchMatch && recommendationTypeMatch && retailerMatch && bundleMatch
    })

    const handleHideClick = (skuId: string) => {
      setHideRecommendationsProduct(recommendations?.find(r => r.id === skuId))
    }

    const handleShowClick = (skuId: string) => {
      setShowRecommendationsProduct(recommendations?.find(r => r.id === skuId))
    }

    const adjustSkuRecommendationsVisibility = async (id: string, isVisible: boolean) => {
      setProcessingProduct(true)
      await adjustProductRecommendationVisibility(id, isVisible)

      setShowRecommendationsProduct(undefined)
      setHideRecommendationsProduct(undefined)
      setProcessingProduct(false)
    }

    if (!recommendations) {
      return <Loading />
    }

    const integrated = brand.brandApis.find(api => api.enabled && api.isIntegrated)
    return !integrated && recommendationsList.length === 0 ? (
      <NotIntegratedState brandId={brand.id} />
    ) : (
      <Stack>
        {recommendationsList.length > 0 && (
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'} spacing={2} my={2}>
              <DebouncedTextField
                placeholder={'Search recommendations'}
                sx={{ mb: 4, width: 330 }}
                onChange={setSearch}
                InputProps={{
                  endAdornment: <Search />
                }}
              />
              <SelectInput
                name="proposalTypeFilter"
                sx={{ width: 230 }}
                options={[...Object.values(RecommendationType), 'ARCHIVED'].map(o => {
                  return {
                    value: o,
                    label: o
                      ? RECOMMENDATION_TYPE_TO_DESCRIPTION?.[o as keyof typeof RECOMMENDATION_TYPE_TO_DESCRIPTION] ||
                        words(o).map(capitalize).join(' ')
                      : o
                  }
                })}
                placeholder="All active"
              />
              <SelectInput
                name="retailerFilter"
                sx={{ width: 230 }}
                options={[
                  {
                    value: Store.amazon,
                    label: STORE_DETAILS[Store.amazon].name
                  },
                  {
                    value: Store.walmart,
                    label: STORE_DETAILS[Store.walmart].name
                  }
                ]}
                placeholder="All retailers"
              />
              <SwitchInput
                name="bundle"
                controlLabelProps={{
                  label: 'Show bundles',
                  labelPlacement: 'start',
                  componentsProps: {
                    typography: {
                      variant: 'label3',
                      color: 'grey.A700'
                    }
                  }
                }}
              />
              <Typography variant={'label3'} color={'grey.A700'}>
                Total recommendations: {recommendationsList.length}
              </Typography>
            </Stack>
          </Stack>
        )}

        {recommendationsList.length > 0 && (
          <DataGrid
            autoHeight
            rows={filteredRecommendations}
            columns={RECOMMENDATION_COLUMN_DEFINITIONS({
              hideFunction: handleHideClick,
              showFunction: handleShowClick
            }).map(d => ({
              ...d,
              sortable: d.sortable || false
            }))}
            disableColumnMenu={true}
            disableColumnReorder={true}
            pagination
            initialState={{
              sorting: { sortModel: [{ sort: 'desc', field: 'availabilityDate' }] },
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            pageSizeOptions={[10, 25, 50]}
          />
        )}
        {!recommendationsList.length && <EmptyState />}
        {hideRecommendationsProduct && (
          <HideSkuRecommendationsDialog
            open={!!hideRecommendationsProduct}
            product={hideRecommendationsProduct}
            onClose={() => setHideRecommendationsProduct(undefined)}
            onSubmit={() => adjustSkuRecommendationsVisibility(hideRecommendationsProduct.id, false)}
            processing={processingProduct}
          />
        )}
        {showRecommendationsProduct && (
          <ShowSkuRecommendationsDialog
            open={!!showRecommendationsProduct}
            product={showRecommendationsProduct}
            onClose={() => setShowRecommendationsProduct(undefined)}
            onSubmit={() => adjustSkuRecommendationsVisibility(showRecommendationsProduct.id, true)}
            processing={processingProduct}
          />
        )}
      </Stack>
    )
  },
  {
    bundle: true
  }
)

const getRecommendation = (recommendations: RecommendationType[]) => {
  if (recommendations.includes(RecommendationType.PRODUCT_LAUNCH)) {
    return RecommendationType.PRODUCT_LAUNCH
  }
  return first(recommendations)
}

import { mApi } from '@momentum/api'

export const createSupportTicket = async (input: {
  brandId: string
  companyId: string
  email: string
  message: string
  requestOrigin: string
}) => {
  return (
    await mApi('mutation')({
      createSupportTicket: [input, { success: true }]
    })
  ).createSupportTicket
}

import Row from '@momentum/components/row'
import VideoOrImage from '@momentum/components/video-or-image'
import { Product } from '@momentum/routes/brand/types'
import { Paper, Typography, Stack, Chip, Link } from '@mui/material'
import { BENCHMARK_PRODUCT_COLORS } from '../constants'
import { useBenchmarkContext } from '../context/BenchmarkContext'
import CloseButton from '@momentum/components/close-button'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import { OpenInNewOutlined } from '@mui/icons-material'
import { get } from 'lodash'
import { useFormContext, useWatch } from 'react-hook-form'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'

const BenchmarkProductCard = ({
  product: { id, image, name, priceCents, store, listingLink },
  index
}: {
  product: Product
  index: number
}) => {
  const { setValue } = useFormContext()
  const { promotedProductId } = useBenchmarkContext()
  const isPromotedProduct = promotedProductId === id

  const benchmarkedProductIds = useWatch({
    name: 'benchmarkedProductIds'
  })

  return (
    <Paper
      elevation={0}
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        ...(isPromotedProduct && { borderColor: theme => theme.palette.primary.dark })
      }}
    >
      <Row p={1} width={'100%'} spacing={2} alignItems={'flex-start'}>
        <VideoOrImage src={image} width={72} />
        <Stack spacing={0.5} alignItems={'flex-start'} width={'100%'}>
          <Row justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <Chip
              size={'small'}
              sx={theme => ({
                bgcolor: get(theme.palette, BENCHMARK_PRODUCT_COLORS[index]),
                color: () => {
                  const colorPath = BENCHMARK_PRODUCT_COLORS[index].split('.')
                  colorPath.pop()

                  return get(theme.palette, `${colorPath}.contrastText`)
                }
              })}
              label={index === 0 ? 'Promoted product' : `Product ${index}`}
            />
            {!isPromotedProduct && (
              <CloseButton
                iconButtonProps={{
                  size: 'small',
                  onClick: () =>
                    setValue(
                      'benchmarkedProductIds',
                      benchmarkedProductIds.filter((item: string) => item !== id),
                      {
                        shouldValidate: true,
                        shouldDirty: true
                      }
                    )
                }}
                iconProps={{
                  sx: {
                    height: 16,
                    width: 16
                  }
                }}
              />
            )}
          </Row>
          <Typography variant="link1">
            {name}{' '}
            <Link href={listingLink} target={'_blank'} display={'inline-flex'} sx={{ verticalAlign: 'sub' }}>
              <OpenInNewOutlined fontSize={'mSmall'} color={'primary'} />
            </Link>
          </Typography>
          <Row spacing={0.5}>
            {StoreToIcon[store]({ fontSize: 'small' })}
            {priceCents != null && (
              <Typography variant="caption1">{toCurrencyStringCents(priceCents, STORE_TO_LOCALE[store])}</Typography>
            )}
          </Row>
        </Stack>
      </Row>
    </Paper>
  )
}

export default BenchmarkProductCard

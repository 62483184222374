import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { StoreToFlagIcon, StoreToIcon } from '@momentum/utils/storeIcons'
import { ellipsisString } from '@momentum/utils/stringUtils'
import { OpenInNewOutlined } from '@mui/icons-material'
import { Link, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { Product } from '../context/queries'

export const ProductCard = ({
  product,
  maxWidth = 400,
  direction = 'column'
}: {
  product: Product
  maxWidth?: number
  direction?: 'row' | 'column'
}) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={2}
      px={2}
      border={theme => `1px solid ${theme.palette.grey.A200}`}
      borderRadius={'4px'}
      maxWidth={maxWidth}
    >
      <Box width={'68px'} height={'68px'} p={1} alignItems={'center'} justifyContent={'center'}>
        <img
          src={getCdnImageUrl(product.image)}
          loading="lazy"
          width="100%"
          height={'100%'}
          style={{ objectFit: 'contain' }}
        />
      </Box>
      <Stack spacing={1} flex={1} direction={direction} justifyContent={'space-between'}>
        <Typography variant={'body1'}>{ellipsisString(product.name, 120)}</Typography>
        <Stack direction={'row'} spacing={2}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {StoreToIcon[product.store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })}
            {StoreToFlagIcon[product.store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })}
            {product.priceCents != null && (
              <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
                {toCurrencyStringCents(product.priceCents, STORE_TO_LOCALE[product.store])}
              </Typography>
            )}
          </Stack>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
              ASIN: {product.skuId}
            </Typography>
            {!!product.listingLink && (
              <Link href={product.listingLink} target={'_blank'} display={'flex'}>
                <OpenInNewOutlined fontSize={'mSmall'} color={'primary'} />
              </Link>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

import { groupBy, map, mergeWith, orderBy } from 'lodash'

export const mergeArrays = <T>(arr1: Partial<T>[], arr2: Partial<T>[], byKey: string): T[] => {
  const combinedArray = [...arr1, ...arr2]
  const groupedByDate = groupBy(combinedArray, byKey)

  return orderBy(
    map(groupedByDate, group => mergeWith({}, ...group, (a: Partial<T>, b: Partial<T>) => (b == null ? a : undefined))),
    byKey,
    'asc'
  )
}

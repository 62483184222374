import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { creatorForApprovalSelector } from '../../context/queries'

export const submitCampaignCreatorApproval = async (
  input: ValueTypes['SubmitCampaignCreatorApprovalInput']
) => {
  return (
    await mApi('mutation')({
      submitCampaignCreatorApproval: [
        {
          input
        },
        creatorForApprovalSelector
      ]
    })
  ).submitCampaignCreatorApproval
}

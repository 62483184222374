import { Typography } from '@mui/material'
import { Stack } from '@mui/system'

export const Metric = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack alignItems={'center'} spacing={0.5}>
      <Typography variant={'label1'}>{value}</Typography>
      <Typography variant={'body1'}>{label}</Typography>
    </Stack>
  )
}

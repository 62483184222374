import { _mApi } from '@momentum/api'
import { AGENCY_SAMPLE_COMPANIES, SAMPLE_COMPANY } from '../getCompany/sample-data'
import { ZeusInterceptor } from '../types'

export const listCompanies: ZeusInterceptor<'query', 'listCompanies'> = async args => {
  const apiResult = (await _mApi(args.operation, args.graphqlOptions)(args.o, args.opts)) as any
  if (apiResult.listCompanies && !apiResult.listCompanies.nextToken) {
    apiResult.listCompanies.items = [...(apiResult.listCompanies.items ?? []), SAMPLE_COMPANY, ...AGENCY_SAMPLE_COMPANIES]
  }
  return apiResult.listCompanies
}

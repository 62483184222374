import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { CampaignPerformanceProduct } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { calculateDatesToGoal } from '@momentum/routes/campaign/e-commerce/performance/timeToUtils'
import { RocketLaunchOutlined } from '@mui/icons-material'
import { Stack, Typography, Switch } from '@mui/material'
import { PerformanceComparisonMetric, PerformanceSummaryTitleType } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { first, mean, tail } from 'lodash'
import { DateTime, Duration } from 'luxon'
import { useController, useFormContext } from 'react-hook-form'
import React from 'react'

export const SummaryTitleSelector = ({ name }: { name: string }) => {
  const { isAdminView } = useUserSessionContext()
  const { campaignDetails } = useCampaignContext()
  const { control, watch } = useFormContext()
  const {
    field: { onChange, value }
  } = useController({ name, control })

  const comparisonMetric = watch('comparisonMetric') as PerformanceComparisonMetric
  const comparisonMetricValue = watch('comparisonMetricValue')
  const products = watch('products') as CampaignPerformanceProduct[]

  const daysToGoal = products
    .map(p => calculateDatesToGoal(p, comparisonMetric, comparisonMetricValue))
    .map(d => {
      if (d) {
        return DateTime.fromISO(d.endDate).diff(DateTime.fromISO(d.startDate), 'days').days
      }
    })

  const promotedProductDays = first(daysToGoal)
  const otherProductsDays = tail(daysToGoal)
  const otherProductsDaysMean = mean(otherProductsDays.filter(notEmpty))
  const percentBetter =
    promotedProductDays != null && otherProductsDaysMean
      ? 100 - (100 * promotedProductDays) / otherProductsDaysMean
      : undefined

  if (!percentBetter) {
    return null
  }

  const duration = Duration.fromObject({ months: 0, days: promotedProductDays }).normalize()

  const durationDisplay = Duration.fromObject({
    ...(duration.months && { months: duration.months }),
    days: duration.days
  })

  return (
    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
      {!isAdminView && <RocketLaunchOutlined color={'primary'} sx={{ verticalAlign: 'sub' }} />}
      <Typography variant={'label3'}>
        The {campaignDetails.title} received {comparisonMetricValue} reviews in{' '}
        {durationDisplay.toHuman({ listStyle: 'long' })}. That's{' '}
        {percentBetter.toLocaleString(undefined, { maximumFractionDigits: 0 })}% faster than similar products!
      </Typography>
      {isAdminView && (
        <Switch
          checked={value === PerformanceSummaryTitleType.SIMILAR_PRODUCT}
          value={PerformanceSummaryTitleType.SIMILAR_PRODUCT}
          onChange={(_, checked) => onChange(checked ? PerformanceSummaryTitleType.SIMILAR_PRODUCT : null)}
        />
      )}
    </Stack>
  )
}

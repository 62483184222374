import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'

export const updatePricing = async (input: ValueTypes['ModelInputUpdatePricing']) => {
  return (await mApi('mutation')({ updatePricing: [{ input }, { id: true, store: true }] })).updatePricing
}

export const createPricing = async (input: ValueTypes['ModelInputCreatePricing']) => {
  return (await mApi('mutation')({ createPricing: [{ input }, { id: true, store: true }] })).createPricing
}

export const deletePricing = async (input: ValueTypes['ModelInputDeletePricing']) => {
  return (await mApi('mutation')({ deletePricing: [{ input }, { id: true, store: true }] })).deletePricing
}

export const updateAgencyClientPricingId = async (agencyId: string, clientPricingId: string) => {
  return (
    await mApi('mutation')({
      updateAgency: [
        {
          input: {
            id: agencyId,
            clientPricingId
          }
        },
        { id: true, clientPricingId: true }
      ]
    })
  ).updateAgency
}

import { createContext, useContext, useEffect, useState, ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '@momentum/components/loading'
import {
  getCampaignPerformance,
  CampaignPerformance,
  createCampaignPerformance,
  updateCampaignPerformance,
  createCampaignPerformanceProduct,
  updateCampaignPerformanceProduct,
  CampaignPerformanceResponse,
  CampaignPerformanceProduct,
  deleteCampaignPerformanceProduct
} from './queries'
import {
  PerformanceSummaryTitleType,
  PerformanceComparisonMetric,
  ValueTypes
} from '@productwindtom/shared-momentum-zeus-types'
import { v4 } from 'uuid'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

export type CampaignOverviewPerformanceContextType = {
  campaignPerformance?: CampaignPerformance
  createCampaignPerformance: (
    campaignPerformance: ValueTypes['ModelInputCreateCampaignPerformance']
  ) => Promise<CampaignPerformanceResponse>
  updateCampaignPerformance: (
    campaignPerformance: Omit<ValueTypes['ModelInputUpdateCampaignPerformance'], 'campaignId' | 'skuId'>
  ) => Promise<CampaignPerformanceResponse>
  createCampaignPerformanceProduct: (
    campaignPerformanceProduct: ValueTypes['ModelInputCreateCampaignPerformanceProduct']
  ) => Promise<CampaignPerformanceProduct>
  updateCampaignPerformanceProduct: (
    campaignPerformanceProduct: ValueTypes['ModelInputUpdateCampaignPerformanceProduct']
  ) => Promise<CampaignPerformanceProduct>
  deleteCampaignPerformanceProduct: (id: string) => Promise<void>
}

const CampaignOverviewPerformanceContext = createContext<CampaignOverviewPerformanceContextType>({} as any)

const CampaignOverviewPerformanceProvider = ({ children }: { children: ReactNode }) => {
  const { isAdmin } = useUserSessionContext()
  const { campaignId } = useParams<{ brandId: string; campaignId: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [campaignPerformance, setCampaignPerformance] = useState<CampaignPerformance | undefined>()
  const { campaignDetails } = useCampaignContext()

  useEffect(() => {
    if (campaignId && campaignDetails.skuId) {
      setIsLoading(true)
      getCampaignPerformance(campaignId, campaignDetails.skuId).then(async campaignPerformance => {
        if (!campaignPerformance && isAdmin) {
          // Always create an initial version
          const productId = v4()
          const product = await createCampaignPerformanceProduct({
            id: productId,
            campaignId,
            skuId: campaignDetails.skuId,
            productName: campaignDetails.product.name,
            productUrl: campaignDetails.product.listingLink,
            productImage: campaignDetails.product.image,
            productPerformanceRecords: [],
            reviewDataUploaded: false,
            bestSellerRankDataUploaded: false,
            isVisible: true
          })
          if (product) {
            const performance = await createCampaignPerformance({
              campaignId,
              skuId: campaignDetails.skuId,
              promotedProductId: productId,
              summaryTitle: PerformanceSummaryTitleType.SIMILAR_PRODUCT,
              comparisonMetric: PerformanceComparisonMetric.REVIEWS,
              isVisible: false
            })
            setCampaignPerformance({ ...performance, products: [product] })
            setIsLoading(false)
            return
          }
        }

        setCampaignPerformance(campaignPerformance)
        setIsLoading(false)
      })
    }
  }, [])

  const onCreateCampaignPerformance = async (input: ValueTypes['ModelInputCreateCampaignPerformance']) => {
    const result = await createCampaignPerformance(input)
    setCampaignPerformance({ ...result, products: [] })
    return result
  }

  const onUpdateCampaignPerformance = async (
    input: Omit<ValueTypes['ModelInputUpdateCampaignPerformance'], 'campaignId' | 'skuId'>
  ) => {
    const result = await updateCampaignPerformance({ ...input, campaignId: campaignId!, skuId: campaignDetails.skuId! })
    setCampaignPerformance(prevState => ({ ...(prevState || { products: [] }), ...result }))
    return result
  }

  const onCreateCampaignPerformanceProduct = async (
    input: ValueTypes['ModelInputCreateCampaignPerformanceProduct']
  ) => {
    const result = await createCampaignPerformanceProduct(input)
    setCampaignPerformance(prevState =>
      prevState
        ? {
            ...prevState,
            products: [...(prevState?.products || []), result]
          }
        : undefined
    )
    return result
  }

  const onUpdateCampaignPerformanceProduct = async (
    input: ValueTypes['ModelInputUpdateCampaignPerformanceProduct']
  ) => {
    const result = await updateCampaignPerformanceProduct(input)
    setCampaignPerformance(prevState =>
      prevState
        ? {
            ...prevState,
            products: prevState.products.map(product =>
              product.id === result.id ? { ...product, ...result } : product
            )
          }
        : undefined
    )
    return result
  }

  const onDeleteCampaignPerformanceProduct = async (id: string) => {
    await deleteCampaignPerformanceProduct(id)
    setCampaignPerformance(prevState =>
      prevState
        ? {
            ...prevState,
            products: prevState.products.filter(product => product.id !== id)
          }
        : undefined
    )
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <CampaignOverviewPerformanceContext.Provider
      value={{
        campaignPerformance,
        createCampaignPerformance: onCreateCampaignPerformance,
        updateCampaignPerformance: onUpdateCampaignPerformance,
        createCampaignPerformanceProduct: onCreateCampaignPerformanceProduct,
        updateCampaignPerformanceProduct: onUpdateCampaignPerformanceProduct,
        deleteCampaignPerformanceProduct: onDeleteCampaignPerformanceProduct
      }}
    >
      {children}
    </CampaignOverviewPerformanceContext.Provider>
  )
}

CampaignOverviewPerformanceContext.displayName = 'CampaignOverviewPerformanceContext'

export default CampaignOverviewPerformanceProvider

export const useCampaignOverviewPerformanceContext = () => useContext(CampaignOverviewPerformanceContext)

import { mApi } from '@momentum/api'
import { UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'

const uploadFile = async (
  file: File,
  uploadRequestType: UploadRequestType,
  {
    name,
    companyId,
    agencyId,
    campaignId,
    brandId,
    postId,
    productId
  }: {
    name?: string
    companyId?: string
    campaignId?: string
    brandId?: string
    agencyId?: string
    postId?: string
    productId?: string
  }
) => {
  const extensionIndex = file.name.lastIndexOf('.')
  const extension = file.name.slice(extensionIndex + 1)
  const fileName = name || file.name.slice(0, extensionIndex)

  const { requestUploadFileLink } = await mApi('mutation')({
    requestUploadFileLink: [
      {
        uploadRequestType,
        extension,
        additionalParameters: JSON.stringify({
          brandId,
          campaignId,
          companyId,
          postId,
          fileName,
          agencyId,
          productId
        })
      },
      {
        link: true,
        key: true
      }
    ]
  })
  const signedUrl = requestUploadFileLink.link
  const key = requestUploadFileLink.key
  if (signedUrl && key) {
    const res = await fetch(signedUrl, { method: 'PUT', body: file })
    console.log(res)
    return key
  }
}

export default function useUploadFile() {
  return uploadFile
}

import { yupResolver } from '@hookform/resolvers/yup'
import { ImageSelect } from '@momentum/components/image-select'
import { AgencyInputData } from '@momentum/forms/types'
import { getImageKeyFromUrl } from '@momentum/utils/imageUtils'
import { urlPreTransform } from '@momentum/utils/validationUtils'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { PaymentTermsType, UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'
import { Form, NumberInput, RadioInput, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { omit } from 'lodash'
import { useWatch } from 'react-hook-form'
import { v4 } from 'uuid'
import * as yup from 'yup'
import { BackButton } from '../../components/back-button'
import AgencyFormFields from '../agency-form/AgencyFormFields'
import { agencyFieldsSchema } from '../agency-form/validation'

const schema = yup
  .object({
    id: yup.string().optional(),
    name: yup.string().required('Required'),
    logo: yup
      .string()
      .notRequired()
      .transform(s => (s ? getImageKeyFromUrl(s) : s)),
    websiteUrl: yup.string().transform(urlPreTransform).url('Not a valid URL').required('Required'),
    paymentTermsType: yup.string<PaymentTermsType>().required('Required'),
    paymentTermsCustomNetDays: yup.number().when('paymentTermsType', {
      is: PaymentTermsType.NET_CUSTOM,
      then: s => s.required('Required'),
      otherwise: s => s.nullable().optional()
    }),
    agency: agencyFieldsSchema.notRequired()
  })
  .noUnknown(true)

export type CompanyFormData = {
  id?: string
  name: string
  logo?: string | null
  websiteUrl: string
  paymentTermsType: PaymentTermsType
  paymentTermsCustomNetDays?: number
  agency?: AgencyInputData | null
}

export const CompanyForm = ({
  defaultData,
  onSubmit,
  onCancel
}: {
  defaultData?: CompanyFormData
  onCancel: () => void
  onSubmit: (data: CompanyFormData) => Promise<void>
}) => {
  const handleSubmit = async (submitValues: CompanyFormData) => {
    await onSubmit({
      ...submitValues,
      paymentTermsCustomNetDays:
        submitValues.paymentTermsType === PaymentTermsType.NET_CUSTOM
          ? submitValues.paymentTermsCustomNetDays
          : undefined
    })
  }

  const initialCompany: CompanyFormData = defaultData
    ? {
        paymentTermsType: defaultData.paymentTermsType ? defaultData.paymentTermsType : PaymentTermsType.DEFAULT_7_DAYS,
        ...omit(defaultData, 'paymentTermsType')
      }
    : {
        id: v4(),
        name: '',
        websiteUrl: '',
        paymentTermsType: PaymentTermsType.DEFAULT_7_DAYS
      }
  return (
    <Form onSubmit={handleSubmit} defaultValues={initialCompany} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <TextInput name={'name'} primaryText={'Company name'} placeholder={'Company name'} />
        <TextInput name={'websiteUrl'} primaryText={'Company website'} placeholder={'Company website URL'} />

        <Stack>
          <RadioInput
            primaryText={'What are the default payment terms for the company?'}
            name={'paymentTermsType'}
            radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
            options={[
              {
                label: (
                  <Typography variant={'label3'}>
                    Payment is due 7 days before campaign start date. The campaign cannot start until payment is
                    received.
                  </Typography>
                ),
                value: PaymentTermsType.DEFAULT_7_DAYS
              },
              {
                label: <Typography variant={'label3'}>This brand has negotiated payment terms.</Typography>,
                value: PaymentTermsType.NET_CUSTOM
              }
            ]}
          />
          <PaymentNetInput />
        </Stack>
        <ImageSelect
          name={'logo'}
          primaryText="Upload company logo"
          buttonText={'Click to upload'}
          companyId={initialCompany.id}
          buttonProps={{
            variant: 'outlined'
          }}
          uploadType={UploadRequestType.UPLOAD_COMPANY_LOGO}
        />
        <AgencyFormFields />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} />
          <SubmitButton variant={'contained'} disableOnDirty={false}>
            Save
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

const PaymentNetInput = () => {
  const val = useWatch<CompanyFormData>({ name: 'paymentTermsType' })
  if (val === PaymentTermsType.NET_CUSTOM) {
    return (
      <Stack direction={'row'} ml={4} alignItems={'center'} spacing={2}>
        <Typography variant={'label3'}>Net</Typography>
        <NumberInput name={'paymentTermsCustomNetDays'} />
      </Stack>
    )
  }
  return null
}

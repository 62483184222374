import { Stack, Typography } from '@mui/material'
import { sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { useCampaignContext } from '../../context/CampaignContext'
import { Metric } from '../common/Metric'
import { determineDaysToHitGoal } from '../common/utils'
import { SalesReportView, useSalesContext } from './salesContext'
import { useValueFormatter } from './utils'

const timelineViewGoalInUnits = 1000

export const Summary = () => {
  const { campaignDetails } = useCampaignContext()
  const { minDate, maxDate, salesData, salesReportView } = useSalesContext()
  const valueFormatter = useValueFormatter()

  const fromMomentumSum = sumBy(salesData, d => d.fromMomentum)
  const fromOtherCustomersSum = Math.max(
    0,
    sumBy(salesData, d => d.fromOtherCustomers)
  )
  const totalSum = sumBy(salesData, d => d.total)

  const timelineViewGoal =
    salesReportView === SalesReportView.REVENUE
      ? timelineViewGoalInUnits * campaignDetails.product.priceCents!
      : timelineViewGoalInUnits

  const daysToHitGoal = determineDaysToHitGoal(salesData, t => t.total ?? 0, timelineViewGoal)

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} width={'100%'}>
          <Typography variant={'h4'}>Campaign sales summary</Typography>
          <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
            {minDate.toLocaleString(DateTime.DATE_MED)}
            {' - '}
            {maxDate.toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric label={`${salesReportView} from activated creators`} value={valueFormatter(fromMomentumSum) ?? '--'} />
        <Metric
          label={`${salesReportView} from other customers`}
          value={valueFormatter(fromOtherCustomersSum) ?? '--'}
        />
        <Metric label={`total ${salesReportView}`} value={valueFormatter(totalSum) ?? '--'} />
        <Metric
          label={`to reach ${valueFormatter(timelineViewGoal)} ${salesReportView === SalesReportView.REVENUE ? 'in' : ''} ${salesReportView}`}
          value={daysToHitGoal ? `${daysToHitGoal.toLocaleString()} days` : '--'}
        />
      </Stack>
    </Stack>
  )
}

import { useFormContext } from 'react-hook-form'
import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { EstimatedTimeline } from '@momentum/routes/proposals-create/common/EstimatedTimeline'

export const CampaignTimeline = () => {
  const { watch } = useFormContext<ProposalCreateForm>()
  const { isDisplayResults, timelineSteps, isMonthly } = useEstimatedDeliverables()

  const formData = watch()

  const isValid = !!isDisplayResults && !!formData.launchDate

  return (
    <EstimatedTimeline
      launchDate={isValid ? formData.launchDate : undefined}
      timelineSteps={timelineSteps}
      isMonthly={isMonthly}
    />
  )
}

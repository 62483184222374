import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { pricingSelector } from '@momentum/routes/queries'

export const updateCompany = async (input: ValueTypes['ModelInputUpdateCompany']) => {
  return (await mApi('mutation')({ updateCompany: [{ input }, { id: true }] })).updateCompany
}

export const updatePricing = async (input: ValueTypes['ModelInputUpdatePricing']) => {
  return (await mApi('mutation')({ updatePricing: [{ input }, pricingSelector] })).updatePricing
}

export const createPricing = async (input: ValueTypes['ModelInputCreatePricing']) => {
  return (await mApi('mutation')({ createPricing: [{ input }, pricingSelector] })).createPricing
}
export const deletePricing = async (input: ValueTypes['ModelInputDeletePricing']) => {
  return (await mApi('mutation')({ deletePricing: [{ input }, pricingSelector] })).deletePricing
}

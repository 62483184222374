import { yupResolver } from '@hookform/resolvers/yup'
import { PricingForm, PricingFormValues, schema } from '@momentum/components/pricing-form'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Stack, Typography } from '@mui/material'
import { createRegionalStorePricings, getAgencyStorePricing } from '@productwindtom/shared-momentum'
import { Form } from '@productwindtom/ui-base'
import { captureException } from '@sentry/react'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { createPricing, deletePricing, updateAgencyClientPricingId, updatePricing } from './queries'

export const AgencyProfile = () => {
  const { agency } = useUserSessionContext()

  if (!agency) {
    return null
  }

  return (
    <Stack pb={4}>
      <Stack mb={5} mt={3} spacing={1}>
        <Typography variant={'h3'}>Agency profile</Typography>
        <Typography variant={'h5'}>{agency?.name}</Typography>
      </Stack>

      <Stack spacing={4}>
        <AgencyPricing />
        <ClientPricing />
      </Stack>
    </Stack>
  )
}

const AgencyPricing = () => {
  const { agency, refreshAgency } = useUserSessionContext()

  if (!agency) {
    return null
  }

  const agencyPricing = useMemo(() => getAgencyStorePricing(agency?.pricing), [agency])

  const onSubmit = async (submitValues: PricingFormValues) => {
    try {
      await Promise.all(
        createRegionalStorePricings(agency!.id, submitValues.pricing).map(input => {
          const anyNotBlank = !!input.creatorPricing.find(cp => cp.price)

          if ((agency!.pricing || []).find(p => p.store === input.store)) {
            if (anyNotBlank) {
              return updatePricing(input)
            } else {
              return deletePricing({ id: agency!.id, store: input.store })
            }
          } else if (anyNotBlank) {
            return createPricing(input)
          }
        })
      )
      await refreshAgency(agency!.id)
      toast(<Typography variant={'subtitle2'}>Agency pricing saved!</Typography>, { type: 'success' })
    } catch (error) {
      captureException(error)
      toast(<Typography variant={'subtitle2'}>Failed to save pricing!</Typography>, { type: 'error' })
    }
  }

  const initialValues = {
    pricing: agencyPricing ?? []
  }

  return (
    <Stack>
      <Form onSubmit={onSubmit} defaultValues={initialValues} resolver={yupResolver(schema)}>
        <PricingForm
          initialValues={initialValues}
          title={'Agency to ProductWind pricing'}
          subtitle={
            'This is the wholesale price that the agency will pay to ProductWind. Agency pricing is only used for HubSpot and internal reporting.'
          }
          submitText={'Save agency pricing'}
        />
      </Form>
    </Stack>
  )
}

const ClientPricing = () => {
  const { agency, refreshAgency } = useUserSessionContext()

  if (!agency) {
    return null
  }

  const clientPricing = useMemo(() => getAgencyStorePricing(agency?.clientPricing), [agency])

  const onSubmit = async (submitValues: PricingFormValues) => {
    const clientPricingId = `${agency.id}_clientPricing`

    try {
      await Promise.all([
        !agency.clientPricingId && updateAgencyClientPricingId(agency.id, clientPricingId),
        ...createRegionalStorePricings(clientPricingId, submitValues.pricing).map(input => {
          const anyNotBlank = !!input.creatorPricing.find(cp => cp.price)

          if ((agency!.clientPricing || []).find(p => p.store === input.store)) {
            if (anyNotBlank) {
              return updatePricing(input)
            } else {
              return deletePricing({ id: clientPricingId, store: input.store })
            }
          } else if (anyNotBlank) {
            return createPricing(input)
          }
        })
      ])
      await refreshAgency(agency!.id)
      toast(<Typography variant={'subtitle2'}>Agency pricing saved!</Typography>, { type: 'success' })
    } catch (error) {
      captureException(error)
      toast(<Typography variant={'subtitle2'}>Failed to save pricing!</Typography>, { type: 'error' })
    }
  }

  const initialValues = {
    pricing: clientPricing ?? []
  }

  return (
    <Stack>
      <Form onSubmit={onSubmit} defaultValues={initialValues} resolver={yupResolver(schema) as any}>
        <PricingForm
          initialValues={initialValues!}
          title={'Agency to end-client pricing'}
          subtitle={
            'This is the market price the client will pay to the agency. If this pricing is set, this will be displayed for proposals and campaigns in all brands under the agency.\nIf pricing is set in the individual brand, that will override this price.'
          }
          submitText={'Save default pricing'}
        />
      </Form>
    </Stack>
  )
}

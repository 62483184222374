import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { ZeusInterceptor } from '../types'
import { SAMPLE_CAMPAIGN_PERFORMANCE } from '@momentum/api/interceptors/getCampaignPerformance/sample-data'

export const updateCampaignPerformance: ZeusInterceptor<'mutation', 'updateCampaignPerformance'> = async args => {
  const params = args.o.updateCampaignPerformance?.[0] as { input: ValueTypes['ModelInputUpdateCampaignPerformance'] }

  const sampleCampaign = SAMPLE_CAMPAIGN_PERFORMANCE[params.input.campaignId!]
  if (sampleCampaign) {
    return { ...sampleCampaign, ...params.input, products: sampleCampaign?.products || [] } as any
  }
}

import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { NotIntegrated } from '@momentum/routes/campaign/e-commerce/common/NotIntegrated'
import { Summary } from '@momentum/routes/campaign/e-commerce/traffic/Summary'
import { TrafficGraph } from '@momentum/routes/campaign/e-commerce/traffic/traffic-graph'
import { TrafficTable } from '@momentum/routes/campaign/e-commerce/traffic/traffic-table'
import TrafficContext, { TrafficData } from '@momentum/routes/campaign/e-commerce/traffic/trafficContext'
import { Paper, Stack } from '@mui/material'
import { max, min, orderBy, sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { PeriodSelector } from './PeriodSelector'
import { SAMPLE_CAMPAIGN_TRAFFIC } from './sample-data'

const ATTRIBUTION_THRESHOLD = 0.95
const ATTRIBUTION_RATE = 0.14
const DAYS_DATA = 15

export const Traffic = () => {
  const [isMomentumViewsHidden, setIsMomentumViewsHidden] = useState(false)
  const [isOtherViewsHidden, setIsOtherViewsHidden] = useState(false)
  const [isTotalViewsHidden, setIsTotalViewsHidden] = useState(true)

  const { isAdminView } = useUserSessionContext()
  const { brand } = useBrandContext()

  const integrated = brand.brandApis.find(api => api.enabled && api.isIntegrated)

  const { campaignClicks, productEtailerMetrics, campaignDetails } = useCampaignContext()

  const { startDate, endDate } = campaignDetails
  const startDateDate = startDate.split('T')[0]
  const endDateDate = endDate?.split('T')[0]

  const maxDateData = max((productEtailerMetrics || []).map(d => d.date))
  const maxDateDataDateTime = maxDateData && integrated ? DateTime.fromISO(maxDateData) : DateTime.now()

  const minDate = min([DateTime.now(), DateTime.fromISO(startDate).minus({ days: DAYS_DATA })])!
  const maxDate = endDate
    ? min([maxDateDataDateTime, DateTime.fromISO(endDate).plus({ days: DAYS_DATA })])!
    : max([minDate, maxDateDataDateTime])!

  const [startDateFilter, setStartDateFilter] = useState<DateTime | undefined>(
    min([DateTime.now(), DateTime.fromISO(startDate), maxDate.minus({ days: 1 })])!
  )
  const [endDateFilter, setEndDateFilter] = useState<DateTime | undefined>(
    min([maxDateDataDateTime, endDateDate ? DateTime.fromISO(endDateDate) : undefined])!
  )

  if (!productEtailerMetrics) {
    return <Loading />
  }

  let trafficData: TrafficData[]
  if (SAMPLE_CAMPAIGN_TRAFFIC[campaignDetails.id]) {
    trafficData = SAMPLE_CAMPAIGN_TRAFFIC[campaignDetails.id]
  } else {
    const days = Math.ceil(maxDate.diff(minDate, 'days').days) + 1
    const daysArray = new Array(days).fill(0)

    const totalMomentumViewsInPeriod = sumBy(
      campaignClicks.filter(c => isWithinPeriod(c.date, startDateDate, endDateDate)),
      'clicks'
    )
    const totalViewsInPeriod = sumBy(
      productEtailerMetrics.filter(c => isWithinPeriod(c.date, startDateDate, endDateDate)),
      'pageViews'
    )

    const isAttributeOthers = totalMomentumViewsInPeriod < ATTRIBUTION_THRESHOLD * totalViewsInPeriod

    trafficData = daysArray
      .map((_, index) => {
        const date = minDate.plus({ days: index })
        const dateString = date.toISODate()

        const pemValue = productEtailerMetrics.find(d => d.date === dateString)?.pageViews

        const momentumViews = min([pemValue, campaignClicks.find(d => d.date === dateString)?.clicks || 0])!
        const totalViews = pemValue || momentumViews || 0

        return {
          date,
          momentumViews,
          otherViews: totalViews - momentumViews,
          totalViews
        }
      })
      .filter(d => isWithinPeriod(d.date.toISODate()!, minDate.toISODate()!, maxDate.toISODate()!))
      .map(d =>
        isWithinPeriod(d.date.toISODate()!, startDateDate, endDateDate)
          ? isAttributeOthers
            ? applyAttribution(d, startDate, endDate!)
            : applyMin(d)
          : d
      )
  }

  const startDateFilterDate = startDateFilter?.toISODate()
  const endDateFilterDate = endDateFilter?.toISODate()
  const filteredTrafficData = orderBy(
    trafficData.filter(
      d => !startDateFilterDate || isWithinPeriod(d.date.toISODate()!, startDateFilterDate, endDateFilterDate)
    ),
    'date'
  )

  if (!isAdminView && !integrated) {
    return <NotIntegrated />
  }

  return (
    <TrafficContext.Provider
      value={{
        isIntegrated: !!integrated,
        trafficData,
        filteredTrafficData,
        minDate,
        maxDate,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        isMomentumViewsHidden,
        setIsMomentumViewsHidden,
        isOtherViewsHidden,
        setIsOtherViewsHidden,
        isTotalViewsHidden,
        setIsTotalViewsHidden
      }}
    >
      <Stack spacing={5}>
        <Paper sx={{ px: 4, py: 2 }}>
          <Summary />
        </Paper>
        <PeriodSelector />
        <TrafficGraph />
        <TrafficTable />
      </Stack>
    </TrafficContext.Provider>
  )
}

const isWithinPeriod = (date: string, startDate: string, endDate?: string | null) => {
  const trimDate = date.split('T')[0]
  return trimDate >= startDate && (!endDate || trimDate <= endDate)
}

const applyMin = (d: TrafficData): TrafficData => {
  const momentumViews = Math.min(d.momentumViews, Math.round(ATTRIBUTION_THRESHOLD * d.totalViews))
  return {
    ...d,
    momentumViews,
    otherViews: d.totalViews - momentumViews
  }
}

const applyAttribution = (d: TrafficData, startDate: string, endDate: string): TrafficData => {
  const momentumAttributedViews = d.momentumViews + Math.round(ATTRIBUTION_RATE * d.otherViews)
  const date = d?.date?.toISO()

  return {
    ...d,
    momentumViews: momentumAttributedViews,
    otherViews: d.totalViews - momentumAttributedViews,
    isWithinCampaignDates: !!(date && isWithinPeriod(date, startDate, endDate))
  }
}

import { Button } from '@mui/material'
import React from 'react'
import { ButtonProps } from '@mui/material/Button/Button'
import { useFormContext } from 'react-hook-form'

export const BackButton = ({ text, disabled, ...props }: ButtonProps & { text?: string }) => {
  const {
    formState: { isSubmitting }
  } = useFormContext()

  return (
    <Button {...props} disabled={isSubmitting || disabled}>
      {text ?? 'Back'}
    </Button>
  )
}

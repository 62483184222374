import { ProductKeywordSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'

export const SAMPLE_CAMPAIGN_KEYWORDS_OUTDOOR_CAMERA_BOOST: { items: ProductKeywordSummary[] } = {
  items: [
    {
      campaignId: SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id,
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_alexa cameras for home security`,
      keyword: 'alexa cameras for home security',
      searchVolume: 2511,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 77,
      sponsoredHighestRank: 49,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2549,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-03',
          searchVolume: 2549,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-02',
          searchVolume: 2549,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-01',
          searchVolume: 2549,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-30',
          searchVolume: 2549,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-29',
          searchVolume: 2549,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-28',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-27',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-26',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-25',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-23',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-21',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-20',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-19',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-18',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-17',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-16',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-15',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-14',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 44
        },
        {
          date: '2024-06-13',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-12',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-11',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-10',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-09',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-08',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-31',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-30',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-29',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-28',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-27',
          searchVolume: 2511,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 44
        },
        {
          date: '2024-05-26',
          searchVolume: 2511,
          organicRank: 306,
          sponsoredRank: 77,
          keywordSales: 44
        },
        {
          date: '2024-05-25',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-24',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 44
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_arlo`,
      keyword: 'arlo',
      searchVolume: 13910,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-11',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-30',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 16661,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-07-03',
          searchVolume: 16661,
          organicRank: 6,
          sponsoredRank: 41,
          keywordSales: 120
        },
        {
          date: '2024-07-02',
          searchVolume: 16661,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-07-01',
          searchVolume: 16661,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-30',
          searchVolume: 16661,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-29',
          searchVolume: 16661,
          organicRank: 1,

          keywordSales: 120
        },
        {
          date: '2024-06-28',
          searchVolume: 14094,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 120
        },
        {
          date: '2024-06-27',
          searchVolume: 14094,
          organicRank: 7,
          sponsoredRank: 15,
          keywordSales: 120
        },
        {
          date: '2024-06-26',
          searchVolume: 14094,
          organicRank: 1,
          sponsoredRank: 47,
          keywordSales: 120
        },
        {
          date: '2024-06-25',
          searchVolume: 14094,
          organicRank: 1,
          sponsoredRank: 15,
          keywordSales: 120
        },
        {
          date: '2024-06-23',
          searchVolume: 14094,
          organicRank: 1,
          sponsoredRank: 12,
          keywordSales: 120
        },
        {
          date: '2024-06-21',
          searchVolume: 13455,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-20',
          searchVolume: 13455,
          organicRank: 4,
          sponsoredRank: 58,
          keywordSales: 120
        },
        {
          date: '2024-06-19',
          searchVolume: 13455,
          organicRank: 1,
          sponsoredRank: 9,
          keywordSales: 120
        },
        {
          date: '2024-06-18',
          searchVolume: 13455,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-17',
          searchVolume: 13455,
          organicRank: 1,

          keywordSales: 120
        },
        {
          date: '2024-06-16',
          searchVolume: 13455,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-15',
          searchVolume: 13455,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-14',
          searchVolume: 14141,
          organicRank: 1,
          sponsoredRank: 18,
          keywordSales: 120
        },
        {
          date: '2024-06-13',
          searchVolume: 14141,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-12',
          searchVolume: 14141,
          organicRank: 2,
          sponsoredRank: 42,
          keywordSales: 120
        },
        {
          date: '2024-06-11',
          searchVolume: 14141,
          organicRank: 3,
          sponsoredRank: 59,
          keywordSales: 120
        },
        {
          date: '2024-06-10',
          searchVolume: 14141,
          organicRank: 31,
          sponsoredRank: 41,
          keywordSales: 120
        },
        {
          date: '2024-06-09',
          searchVolume: 14141,
          organicRank: 2,
          sponsoredRank: 10,
          keywordSales: 120
        },
        {
          date: '2024-06-08',
          searchVolume: 14141,
          organicRank: 1,
          sponsoredRank: 12,
          keywordSales: 120
        },
        {
          date: '2024-06-07',
          searchVolume: 15136,
          organicRank: 2,
          sponsoredRank: 18,
          keywordSales: 120
        },
        {
          date: '2024-06-06',
          searchVolume: 15136,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-05',
          searchVolume: 15136,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-04',
          searchVolume: 15136,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-03',
          searchVolume: 15136,
          organicRank: 1,

          keywordSales: 120
        },
        {
          date: '2024-06-02',
          searchVolume: 15136,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-06-01',
          searchVolume: 15136,
          organicRank: 1,
          sponsoredRank: 36,
          keywordSales: 120
        },
        {
          date: '2024-05-31',
          searchVolume: 15487,
          organicRank: 2,
          sponsoredRank: 9,
          keywordSales: 120
        },
        {
          date: '2024-05-30',
          searchVolume: 15487,
          organicRank: 2,
          sponsoredRank: 9,
          keywordSales: 120
        },
        {
          date: '2024-05-29',
          searchVolume: 15487,
          organicRank: 1,
          sponsoredRank: 29,
          keywordSales: 120
        },
        {
          date: '2024-05-28',
          searchVolume: 15487,
          organicRank: 1,
          sponsoredRank: 22,
          keywordSales: 120
        },
        {
          date: '2024-05-27',
          searchVolume: 15487,
          organicRank: 1,
          sponsoredRank: 16,
          keywordSales: 120
        },
        {
          date: '2024-05-26',
          searchVolume: 15487,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 120
        },
        {
          date: '2024-05-25',
          searchVolume: 15487,
          organicRank: 1,
          sponsoredRank: 8,
          keywordSales: 120
        },
        {
          date: '2024-05-24',
          searchVolume: 13910,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 120
        },
        {
          date: '2024-05-23',
          searchVolume: 13910,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 120
        },
        {
          date: '2024-05-22',
          searchVolume: 13910,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 120
        },
        {
          date: '2024-05-21',
          searchVolume: 13910,
          organicRank: 2,
          sponsoredRank: 9,
          keywordSales: 120
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_arlo camera`,
      keyword: 'arlo camera',
      searchVolume: 19400,
      organicStartRank: 1,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-11',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 22065,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-07-02',
          searchVolume: 22065,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-07-01',
          searchVolume: 22065,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 510
        },
        {
          date: '2024-06-30',
          searchVolume: 22065,
          organicRank: 1,

          keywordSales: 510
        },
        {
          date: '2024-06-29',
          searchVolume: 22065,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-06-28',
          searchVolume: 19642,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-06-27',
          searchVolume: 19642,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 510
        },
        {
          date: '2024-06-26',
          searchVolume: 19642,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 510
        },
        {
          date: '2024-06-25',
          searchVolume: 19642,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 510
        },
        {
          date: '2024-06-24',
          searchVolume: 19642,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-06-22',
          searchVolume: 19642,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-06-21',
          searchVolume: 18676,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-06-20',
          searchVolume: 18676,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 510
        },
        {
          date: '2024-06-19',
          searchVolume: 18676,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 510
        },
        {
          date: '2024-06-18',
          searchVolume: 18676,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 510
        },
        {
          date: '2024-06-17',
          searchVolume: 18676,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 510
        },
        {
          date: '2024-06-16',
          searchVolume: 18676,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 510
        },
        {
          date: '2024-06-15',
          searchVolume: 18676,
          organicRank: 1,
          sponsoredRank: 9,
          keywordSales: 510
        },
        {
          date: '2024-06-14',
          searchVolume: 19703,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 510
        },
        {
          date: '2024-06-13',
          searchVolume: 19703,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 510
        },
        {
          date: '2024-06-12',
          searchVolume: 19703,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 510
        },
        {
          date: '2024-06-11',
          searchVolume: 19703,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-06-10',
          searchVolume: 19703,
          organicRank: 29,
          sponsoredRank: 2,
          keywordSales: 510
        },
        {
          date: '2024-06-09',
          searchVolume: 19703,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 510
        },
        {
          date: '2024-06-08',
          searchVolume: 19703,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 510
        },
        {
          date: '2024-06-07',
          searchVolume: 18997,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 510
        },
        {
          date: '2024-06-06',
          searchVolume: 18997,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 510
        },
        {
          date: '2024-06-05',
          searchVolume: 18997,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-06-04',
          searchVolume: 18997,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-06-03',
          searchVolume: 18997,
          organicRank: 1,

          keywordSales: 510
        },
        {
          date: '2024-06-02',
          searchVolume: 18997,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-06-01',
          searchVolume: 18997,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 510
        },
        {
          date: '2024-05-31',
          searchVolume: 19605,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-05-30',
          searchVolume: 19605,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 510
        },
        {
          date: '2024-05-29',
          searchVolume: 19605,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 510
        },
        {
          date: '2024-05-28',
          searchVolume: 19605,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 510
        },
        {
          date: '2024-05-27',
          searchVolume: 19605,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-05-26',
          searchVolume: 19605,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-05-25',
          searchVolume: 19605,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-05-24',
          searchVolume: 19400,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 510
        },
        {
          date: '2024-05-21',
          searchVolume: 19400,
          organicRank: 1,

          keywordSales: 510
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_arlo doorbell`,
      keyword: 'arlo doorbell',
      searchVolume: 2511,
      organicStartRank: 22,
      organicHighestRank: 8,
      sponsoredStartRank: 25,
      sponsoredHighestRank: 13,
      organicPageOneWinDate: '2024-05-25',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 3625,
          organicRank: 26,

          keywordSales: 134
        },
        {
          date: '2024-07-02',
          searchVolume: 3625,
          organicRank: 22,

          keywordSales: 134
        },
        {
          date: '2024-07-01',
          searchVolume: 3625,
          organicRank: 25,

          keywordSales: 134
        },
        {
          date: '2024-06-30',
          searchVolume: 3625,
          organicRank: 25,

          keywordSales: 134
        },
        {
          date: '2024-06-29',
          searchVolume: 3625,
          organicRank: 24,

          keywordSales: 134
        },
        {
          date: '2024-06-28',
          searchVolume: 3131,
          organicRank: 25,

          keywordSales: 134
        },
        {
          date: '2024-06-27',
          searchVolume: 3131,
          organicRank: 16,

          keywordSales: 134
        },
        {
          date: '2024-06-26',
          searchVolume: 3131,
          organicRank: 16,

          keywordSales: 134
        },
        {
          date: '2024-06-25',
          searchVolume: 3131,
          organicRank: 12,

          keywordSales: 134
        },
        {
          date: '2024-06-24',
          searchVolume: 3131,
          organicRank: 11,

          keywordSales: 134
        },
        {
          date: '2024-06-22',
          searchVolume: 3131,
          organicRank: 10,

          keywordSales: 134
        },
        {
          date: '2024-06-21',
          searchVolume: 3126,
          organicRank: 8,
          sponsoredRank: 35,
          keywordSales: 134
        },
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 29,
          sponsoredRank: 27,
          keywordSales: 134
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 28,
          sponsoredRank: 14,
          keywordSales: 134
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 29,
          sponsoredRank: 48,
          keywordSales: 134
        },
        {
          date: '2024-06-17',
          searchVolume: 3126,
          organicRank: 30,
          sponsoredRank: 35,
          keywordSales: 134
        },
        {
          date: '2024-06-16',
          searchVolume: 3126,
          organicRank: 29,
          sponsoredRank: 13,
          keywordSales: 134
        },
        {
          date: '2024-06-15',
          searchVolume: 3126,
          organicRank: 35,
          sponsoredRank: 25,
          keywordSales: 134
        },
        {
          date: '2024-06-14',
          searchVolume: 3126,
          organicRank: 41,
          sponsoredRank: 13,
          keywordSales: 134
        },
        {
          date: '2024-06-13',
          searchVolume: 3126,
          organicRank: 44,
          sponsoredRank: 34,
          keywordSales: 134
        },
        {
          date: '2024-06-12',
          searchVolume: 3126,
          organicRank: 45,
          sponsoredRank: 22,
          keywordSales: 134
        },
        {
          date: '2024-06-11',
          searchVolume: 3126,
          organicRank: 47,
          sponsoredRank: 25,
          keywordSales: 134
        },
        {
          date: '2024-06-10',
          searchVolume: 3126,
          organicRank: 45,

          keywordSales: 134
        },
        {
          date: '2024-06-09',
          searchVolume: 3126,
          organicRank: 24,

          keywordSales: 134
        },
        {
          date: '2024-06-08',
          searchVolume: 3126,
          organicRank: 25,

          keywordSales: 134
        },
        {
          date: '2024-06-07',
          searchVolume: 2982,
          organicRank: 24,
          sponsoredRank: 36,
          keywordSales: 134
        },
        {
          date: '2024-06-06',
          searchVolume: 2982,
          organicRank: 25,
          sponsoredRank: 25,
          keywordSales: 134
        },
        {
          date: '2024-06-05',
          searchVolume: 2982,
          organicRank: 28,

          keywordSales: 134
        },
        {
          date: '2024-06-04',
          searchVolume: 2982,
          organicRank: 29,

          keywordSales: 134
        },
        {
          date: '2024-06-03',
          searchVolume: 2982,
          organicRank: 23,

          keywordSales: 134
        },
        {
          date: '2024-06-02',
          searchVolume: 2982,
          organicRank: 278,
          sponsoredRank: 24,
          keywordSales: 134
        },
        {
          date: '2024-06-01',
          searchVolume: 2982,
          organicRank: 25,

          keywordSales: 134
        },
        {
          date: '2024-05-31',
          searchVolume: 2513,
          organicRank: 24,

          keywordSales: 134
        },
        {
          date: '2024-05-30',
          searchVolume: 2513,
          organicRank: 31,

          keywordSales: 134
        },
        {
          date: '2024-05-29',
          searchVolume: 2513,
          organicRank: 33,

          keywordSales: 134
        },
        {
          date: '2024-05-28',
          searchVolume: 2513,
          organicRank: 29,
          sponsoredRank: 34,
          keywordSales: 134
        },
        {
          date: '2024-05-27',
          searchVolume: 2513,
          organicRank: 30,
          sponsoredRank: 28,
          keywordSales: 134
        },
        {
          date: '2024-05-26',
          searchVolume: 2513,
          organicRank: 20,

          keywordSales: 134
        },
        {
          date: '2024-05-25',
          searchVolume: 2513,
          organicRank: 20,
          sponsoredRank: 25,
          keywordSales: 134
        },
        {
          date: '2024-05-24',
          searchVolume: 2511,
          organicRank: 25,

          keywordSales: 134
        },
        {
          date: '2024-05-21',
          searchVolume: 2511,
          organicRank: 22,

          keywordSales: 134
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_arlo essential spotlight camera`,
      keyword: 'arlo essential spotlight camera',
      searchVolume: 1888,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-14',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1658,
          organicRank: 4,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-07-02',
          searchVolume: 1658,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-07-01',
          searchVolume: 1658,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 31
        },
        {
          date: '2024-06-30',
          searchVolume: 1658,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-06-29',
          searchVolume: 1658,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-06-28',
          searchVolume: 1659,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 31
        },
        {
          date: '2024-06-27',
          searchVolume: 1659,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-06-26',
          searchVolume: 1659,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 31
        },
        {
          date: '2024-06-25',
          searchVolume: 1659,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-06-24',
          searchVolume: 1659,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-06-22',
          searchVolume: 1659,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-06-21',
          searchVolume: 1609,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 31
        },
        {
          date: '2024-06-20',
          searchVolume: 1609,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-06-19',
          searchVolume: 1609,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 31
        },
        {
          date: '2024-06-18',
          searchVolume: 1609,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 31
        },
        {
          date: '2024-06-17',
          searchVolume: 1609,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 31
        },
        {
          date: '2024-06-16',
          searchVolume: 1609,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 31
        },
        {
          date: '2024-06-15',
          searchVolume: 1609,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 31
        },
        {
          date: '2024-06-14',
          searchVolume: 1659,
          organicRank: 1,
          sponsoredRank: 8,
          keywordSales: 31
        },
        {
          date: '2024-06-13',
          searchVolume: 1659,
          organicRank: 28,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-06-12',
          searchVolume: 1659,
          organicRank: 28,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-06-11',
          searchVolume: 1659,
          organicRank: 27,
          sponsoredRank: 5,
          keywordSales: 31
        },
        {
          date: '2024-06-10',
          searchVolume: 1659,
          organicRank: 27,
          sponsoredRank: 10,
          keywordSales: 31
        },
        {
          date: '2024-06-09',
          searchVolume: 1659,
          organicRank: 1,
          sponsoredRank: 4,
          keywordSales: 31
        },
        {
          date: '2024-06-08',
          searchVolume: 1659,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 31
        },
        {
          date: '2024-06-07',
          searchVolume: 1825,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 31
        },
        {
          date: '2024-06-06',
          searchVolume: 1825,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 31
        },
        {
          date: '2024-06-05',
          searchVolume: 1825,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-06-04',
          searchVolume: 1825,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-06-03',
          searchVolume: 1825,
          organicRank: 2,

          keywordSales: 31
        },
        {
          date: '2024-06-02',
          searchVolume: 1825,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-06-01',
          searchVolume: 1825,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 31
        },
        {
          date: '2024-05-31',
          searchVolume: 1664,
          organicRank: 1,
          sponsoredRank: 3,
          keywordSales: 31
        },
        {
          date: '2024-05-30',
          searchVolume: 1664,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 31
        },
        {
          date: '2024-05-29',
          searchVolume: 1664,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-05-28',
          searchVolume: 1664,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-05-27',
          searchVolume: 1664,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-05-26',
          searchVolume: 1664,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-05-25',
          searchVolume: 1664,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-05-24',
          searchVolume: 1888,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 31
        },
        {
          date: '2024-05-21',
          searchVolume: 1888,
          organicRank: 2,

          keywordSales: 31
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_arlo indoor camera`,
      keyword: 'arlo indoor camera',
      searchVolume: 1900,
      organicStartRank: 11,
      organicHighestRank: 3,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-11',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2430,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 94
        },
        {
          date: '2024-07-02',
          searchVolume: 2430,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-07-01',
          searchVolume: 2430,
          organicRank: 5,
          sponsoredRank: 2,
          keywordSales: 94
        },
        {
          date: '2024-06-30',
          searchVolume: 2430,
          organicRank: 4,

          keywordSales: 94
        },
        {
          date: '2024-06-29',
          searchVolume: 2430,
          organicRank: 3,
          sponsoredRank: 9,
          keywordSales: 94
        },
        {
          date: '2024-06-28',
          searchVolume: 2422,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-06-27',
          searchVolume: 2422,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 94
        },
        {
          date: '2024-06-26',
          searchVolume: 2422,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-06-25',
          searchVolume: 2422,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-06-24',
          searchVolume: 2422,
          organicRank: 5,
          sponsoredRank: 7,
          keywordSales: 94
        },
        {
          date: '2024-06-22',
          searchVolume: 2422,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-06-21',
          searchVolume: 2251,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-06-20',
          searchVolume: 2251,
          organicRank: 5,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-06-19',
          searchVolume: 2251,
          organicRank: 7,
          sponsoredRank: 4,
          keywordSales: 94
        },
        {
          date: '2024-06-18',
          searchVolume: 2251,
          organicRank: 7,
          sponsoredRank: 10,
          keywordSales: 94
        },
        {
          date: '2024-06-17',
          searchVolume: 2251,
          organicRank: 7,
          sponsoredRank: 9,
          keywordSales: 94
        },
        {
          date: '2024-06-16',
          searchVolume: 2251,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 94
        },
        {
          date: '2024-06-15',
          searchVolume: 2251,
          organicRank: 7,
          sponsoredRank: 10,
          keywordSales: 94
        },
        {
          date: '2024-06-14',
          searchVolume: 2398,
          organicRank: 7,
          sponsoredRank: 9,
          keywordSales: 94
        },
        {
          date: '2024-06-13',
          searchVolume: 2398,
          organicRank: 7,
          sponsoredRank: 8,
          keywordSales: 94
        },
        {
          date: '2024-06-12',
          searchVolume: 2398,
          organicRank: 8,
          sponsoredRank: 8,
          keywordSales: 94
        },
        {
          date: '2024-06-11',
          searchVolume: 2398,
          organicRank: 8,
          sponsoredRank: 7,
          keywordSales: 94
        },
        {
          date: '2024-06-10',
          searchVolume: 2398,
          organicRank: 30,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-06-09',
          searchVolume: 2398,
          organicRank: 8,
          sponsoredRank: 6,
          keywordSales: 94
        },
        {
          date: '2024-06-08',
          searchVolume: 2398,
          organicRank: 8,
          sponsoredRank: 6,
          keywordSales: 94
        },
        {
          date: '2024-06-07',
          searchVolume: 2280,
          organicRank: 8,
          sponsoredRank: 6,
          keywordSales: 94
        },
        {
          date: '2024-06-06',
          searchVolume: 2280,
          organicRank: 7,
          sponsoredRank: 2,
          keywordSales: 94
        },
        {
          date: '2024-06-05',
          searchVolume: 2280,
          organicRank: 10,
          sponsoredRank: 2,
          keywordSales: 94
        },
        {
          date: '2024-06-04',
          searchVolume: 2280,
          organicRank: 15,
          sponsoredRank: 2,
          keywordSales: 94
        },
        {
          date: '2024-06-03',
          searchVolume: 2280,
          organicRank: 13,

          keywordSales: 94
        },
        {
          date: '2024-06-02',
          searchVolume: 2280,
          organicRank: 10,
          sponsoredRank: 1,
          keywordSales: 94
        },
        {
          date: '2024-06-01',
          searchVolume: 2280,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-05-31',
          searchVolume: 2354,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-05-30',
          searchVolume: 2354,
          organicRank: 10,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-05-29',
          searchVolume: 2354,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-05-28',
          searchVolume: 2354,
          organicRank: 8,
          sponsoredRank: 7,
          keywordSales: 94
        },
        {
          date: '2024-05-27',
          searchVolume: 2354,
          organicRank: 8,
          sponsoredRank: 2,
          keywordSales: 94
        },
        {
          date: '2024-05-26',
          searchVolume: 2354,
          organicRank: 9,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-05-25',
          searchVolume: 2354,
          organicRank: 9,
          sponsoredRank: 7,
          keywordSales: 94
        },
        {
          date: '2024-05-24',
          searchVolume: 1900,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 94
        },
        {
          date: '2024-05-21',
          searchVolume: 1900,
          organicRank: 11,

          keywordSales: 94
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_arlo security camera system wireless`,
      keyword: 'arlo security camera system wireless',
      searchVolume: 33780,
      organicStartRank: 3,
      organicHighestRank: 1,
      sponsoredStartRank: 3,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 37897,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 595
        },
        {
          date: '2024-07-02',
          searchVolume: 37897,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-07-01',
          searchVolume: 37897,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 595
        },
        {
          date: '2024-06-30',
          searchVolume: 37897,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 595
        },
        {
          date: '2024-06-29',
          searchVolume: 37897,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 595
        },
        {
          date: '2024-06-28',
          searchVolume: 34222,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 595
        },
        {
          date: '2024-06-27',
          searchVolume: 34222,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 595
        },
        {
          date: '2024-06-26',
          searchVolume: 34222,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 595
        },
        {
          date: '2024-06-25',
          searchVolume: 34222,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-06-23',
          searchVolume: 34222,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 595
        },
        {
          date: '2024-06-22',
          searchVolume: 34222,
          organicRank: 2,
          sponsoredRank: 4,
          keywordSales: 595
        },
        {
          date: '2024-06-21',
          searchVolume: 34311,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-06-20',
          searchVolume: 34311,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 595
        },
        {
          date: '2024-06-19',
          searchVolume: 34311,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 595
        },
        {
          date: '2024-06-18',
          searchVolume: 34311,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 595
        },
        {
          date: '2024-06-17',
          searchVolume: 34311,
          organicRank: 2,
          sponsoredRank: 8,
          keywordSales: 595
        },
        {
          date: '2024-06-16',
          searchVolume: 34311,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 595
        },
        {
          date: '2024-06-15',
          searchVolume: 34311,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 595
        },
        {
          date: '2024-06-14',
          searchVolume: 34307,
          organicRank: 1,
          sponsoredRank: 10,
          keywordSales: 595
        },
        {
          date: '2024-06-13',
          searchVolume: 34307,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 595
        },
        {
          date: '2024-06-12',
          searchVolume: 34307,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 595
        },
        {
          date: '2024-06-11',
          searchVolume: 34307,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 595
        },
        {
          date: '2024-06-10',
          searchVolume: 34307,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-06-09',
          searchVolume: 34307,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-06-08',
          searchVolume: 34307,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-06-07',
          searchVolume: 32742,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-06-06',
          searchVolume: 32742,
          organicRank: 2,
          sponsoredRank: 8,
          keywordSales: 595
        },
        {
          date: '2024-06-05',
          searchVolume: 32742,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-06-04',
          searchVolume: 32742,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-06-03',
          searchVolume: 32742,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-06-02',
          searchVolume: 32742,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 595
        },
        {
          date: '2024-06-01',
          searchVolume: 32742,
          organicRank: 2,
          sponsoredRank: 1,
          keywordSales: 595
        },
        {
          date: '2024-05-31',
          searchVolume: 33791,
          organicRank: 1,
          sponsoredRank: 6,
          keywordSales: 595
        },
        {
          date: '2024-05-30',
          searchVolume: 33791,
          organicRank: 2,
          sponsoredRank: 8,
          keywordSales: 595
        },
        {
          date: '2024-05-29',
          searchVolume: 33791,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 595
        },
        {
          date: '2024-05-28',
          searchVolume: 33791,
          organicRank: 1,
          sponsoredRank: 7,
          keywordSales: 595
        },
        {
          date: '2024-05-27',
          searchVolume: 33791,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 595
        },
        {
          date: '2024-05-25',
          searchVolume: 33791,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 595
        },
        {
          date: '2024-05-24',
          searchVolume: 33780,
          organicRank: 2,
          sponsoredRank: 8,
          keywordSales: 595
        },
        {
          date: '2024-05-23',
          searchVolume: 33780,
          organicRank: 3,
          sponsoredRank: 12,
          keywordSales: 595
        },
        {
          date: '2024-05-22',
          searchVolume: 33780,
          organicRank: 3,
          sponsoredRank: 3,
          keywordSales: 595
        },
        {
          date: '2024-05-21',
          searchVolume: 33780,
          organicRank: 3,
          sponsoredRank: 8,
          keywordSales: 595
        },
        {
          date: '2024-05-20',
          searchVolume: 33780,
          organicRank: 3,
          sponsoredRank: 9,
          keywordSales: 595
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_arlo wireless security camera`,
      keyword: 'arlo wireless security camera',
      searchVolume: 4257,
      organicStartRank: 3,
      organicHighestRank: 1,
      sponsoredStartRank: 1,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-11',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2396,
          organicRank: 1,
          sponsoredRank: 2
        },
        {
          date: '2024-07-02',
          searchVolume: 2396,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-07-01',
          searchVolume: 2396,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 2396,
          organicRank: 1
        },
        {
          date: '2024-06-29',
          searchVolume: 2396,
          organicRank: 1,
          sponsoredRank: 6
        },
        {
          date: '2024-06-28',
          searchVolume: 4224,
          organicRank: 1,
          sponsoredRank: 6
        },
        {
          date: '2024-06-27',
          searchVolume: 4224,
          organicRank: 1,
          sponsoredRank: 6
        },
        {
          date: '2024-06-26',
          searchVolume: 4224,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-06-25',
          searchVolume: 4224,
          organicRank: 1,
          sponsoredRank: 4
        },
        {
          date: '2024-06-24',
          searchVolume: 4224,
          organicRank: 1,
          sponsoredRank: 3
        },
        {
          date: '2024-06-22',
          searchVolume: 4224,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-06-21',
          searchVolume: 3126,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 1,
          sponsoredRank: 4
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 1,
          sponsoredRank: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 3126,
          organicRank: 1,
          sponsoredRank: 5
        },
        {
          date: '2024-06-16',
          searchVolume: 3126,
          organicRank: 1,
          sponsoredRank: 4
        },
        {
          date: '2024-06-15',
          searchVolume: 3126,
          organicRank: 1,
          sponsoredRank: 6
        },
        {
          date: '2024-06-14',
          searchVolume: 3817,
          organicRank: 1,
          sponsoredRank: 6
        },
        {
          date: '2024-06-13',
          searchVolume: 3817,
          organicRank: 1,
          sponsoredRank: 5
        },
        {
          date: '2024-06-12',
          searchVolume: 3817,
          organicRank: 1,
          sponsoredRank: 6
        },
        {
          date: '2024-06-11',
          searchVolume: 3817,
          organicRank: 2,
          sponsoredRank: 5
        },
        {
          date: '2024-06-10',
          searchVolume: 3817,
          organicRank: 29,
          sponsoredRank: 1
        },
        {
          date: '2024-06-09',
          searchVolume: 3817,
          organicRank: 2,
          sponsoredRank: 7
        },
        {
          date: '2024-06-08',
          searchVolume: 3817,
          organicRank: 2,
          sponsoredRank: 7
        },
        {
          date: '2024-06-07',
          searchVolume: 3464,
          organicRank: 1,
          sponsoredRank: 7
        },
        {
          date: '2024-06-06',
          searchVolume: 3464,
          organicRank: 1,
          sponsoredRank: 2
        },
        {
          date: '2024-06-05',
          searchVolume: 3464,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-06-04',
          searchVolume: 3464,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-06-03',
          searchVolume: 3464,
          organicRank: 1
        },
        {
          date: '2024-06-02',
          searchVolume: 3464,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-06-01',
          searchVolume: 3464,
          organicRank: 1,
          sponsoredRank: 7
        },
        {
          date: '2024-05-31',
          searchVolume: 4171,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-05-30',
          searchVolume: 4171,
          organicRank: 1,
          sponsoredRank: 13
        },
        {
          date: '2024-05-29',
          searchVolume: 4171,
          organicRank: 1,
          sponsoredRank: 7
        },
        {
          date: '2024-05-28',
          searchVolume: 4171,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-05-27',
          searchVolume: 4171,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-05-26',
          searchVolume: 4171,
          organicRank: 1,
          sponsoredRank: 7
        },
        {
          date: '2024-05-25',
          searchVolume: 4171,
          organicRank: 1,
          sponsoredRank: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 4257,
          organicRank: 1,
          sponsoredRank: 1
        },
        {
          date: '2024-05-21',
          searchVolume: 4257,
          organicRank: 3
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_battery operated security cameras`,
      keyword: 'battery operated security cameras',
      searchVolume: 2390,
      organicStartRank: 306,
      organicHighestRank: 60,
      sponsoredStartRank: 42,
      sponsoredHighestRank: 29,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2391,
          organicRank: 88,

          keywordSales: 20
        },
        {
          date: '2024-07-02',
          searchVolume: 2391,
          organicRank: 96,

          keywordSales: 20
        },
        {
          date: '2024-07-01',
          searchVolume: 2391,
          organicRank: 85,

          keywordSales: 20
        },
        {
          date: '2024-06-30',
          searchVolume: 2391,
          organicRank: 71,

          keywordSales: 20
        },
        {
          date: '2024-06-29',
          searchVolume: 2391,
          organicRank: 70,

          keywordSales: 20
        },
        {
          date: '2024-06-28',
          searchVolume: 2422,
          organicRank: 67,

          keywordSales: 20
        },
        {
          date: '2024-06-27',
          searchVolume: 2422,
          organicRank: 69,
          sponsoredRank: 29,
          keywordSales: 20
        },
        {
          date: '2024-06-26',
          searchVolume: 2422,
          organicRank: 67,

          keywordSales: 20
        },
        {
          date: '2024-06-25',
          searchVolume: 2422,
          organicRank: 73,

          keywordSales: 20
        },
        {
          date: '2024-06-21',
          searchVolume: 1924,
          organicRank: 68,

          keywordSales: 20
        },
        {
          date: '2024-06-20',
          searchVolume: 1924,
          organicRank: 60,

          keywordSales: 20
        },
        {
          date: '2024-06-19',
          searchVolume: 1924,
          organicRank: 96,

          keywordSales: 20
        },
        {
          date: '2024-06-18',
          searchVolume: 1924,
          organicRank: 84,

          keywordSales: 20
        },
        {
          date: '2024-06-17',
          searchVolume: 1924,
          organicRank: 101,

          keywordSales: 20
        },
        {
          date: '2024-06-16',
          searchVolume: 1924,
          organicRank: 100,

          keywordSales: 20
        },
        {
          date: '2024-06-15',
          searchVolume: 1924,
          organicRank: 108,

          keywordSales: 20
        },
        {
          date: '2024-06-14',
          searchVolume: 2173,
          organicRank: 89,

          keywordSales: 20
        },
        {
          date: '2024-06-13',
          searchVolume: 2173,
          organicRank: 90,

          keywordSales: 20
        },
        {
          date: '2024-06-12',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-11',
          searchVolume: 2173,
          organicRank: 85,

          keywordSales: 20
        },
        {
          date: '2024-06-10',
          searchVolume: 2173,
          organicRank: 83,

          keywordSales: 20
        },
        {
          date: '2024-06-09',
          searchVolume: 2173,
          organicRank: 100,

          keywordSales: 20
        },
        {
          date: '2024-06-08',
          searchVolume: 2173,
          organicRank: 101,

          keywordSales: 20
        },
        {
          date: '2024-06-07',
          searchVolume: 2284,
          organicRank: 79,

          keywordSales: 20
        },
        {
          date: '2024-06-06',
          searchVolume: 2284,
          organicRank: 76,

          keywordSales: 20
        },
        {
          date: '2024-06-05',
          searchVolume: 2284,
          organicRank: 75,
          sponsoredRank: 53,
          keywordSales: 20
        },
        {
          date: '2024-06-04',
          searchVolume: 2284,
          organicRank: 67,

          keywordSales: 20
        },
        {
          date: '2024-06-03',
          searchVolume: 2284,
          organicRank: 70,

          keywordSales: 20
        },
        {
          date: '2024-06-02',
          searchVolume: 2284,
          organicRank: 62,

          keywordSales: 20
        },
        {
          date: '2024-06-01',
          searchVolume: 2284,
          organicRank: 66,

          keywordSales: 20
        },
        {
          date: '2024-05-31',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-30',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-29',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-28',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-27',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-26',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-25',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-05-24',
          searchVolume: 2390,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 20
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_camaras de seguridad para casa inalambricas`,
      keyword: 'camaras de seguridad para casa inalambricas',
      searchVolume: 93879,
      organicStartRank: 306,
      organicHighestRank: 114,
      sponsoredStartRank: 33,
      sponsoredHighestRank: 15,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 109459,
          organicRank: 144,
          sponsoredRank: 15,
          keywordSales: 1066
        },
        {
          date: '2024-07-03',
          searchVolume: 109459,
          organicRank: 146,

          keywordSales: 1066
        },
        {
          date: '2024-07-02',
          searchVolume: 109459,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-07-01',
          searchVolume: 109459,
          organicRank: 156,

          keywordSales: 1066
        },
        {
          date: '2024-06-30',
          searchVolume: 109459,
          organicRank: 139,

          keywordSales: 1066
        },
        {
          date: '2024-06-29',
          searchVolume: 109459,
          organicRank: 176,

          keywordSales: 1066
        },
        {
          date: '2024-06-28',
          searchVolume: 98123,
          organicRank: 156,

          keywordSales: 1066
        },
        {
          date: '2024-06-27',
          searchVolume: 98123,
          organicRank: 155,

          keywordSales: 1066
        },
        {
          date: '2024-06-26',
          searchVolume: 98123,
          organicRank: 150,

          keywordSales: 1066
        },
        {
          date: '2024-06-25',
          searchVolume: 98123,
          organicRank: 165,

          keywordSales: 1066
        },
        {
          date: '2024-06-24',
          searchVolume: 98123,
          organicRank: 152,

          keywordSales: 1066
        },
        {
          date: '2024-06-21',
          searchVolume: 98358,
          organicRank: 159,

          keywordSales: 1066
        },
        {
          date: '2024-06-20',
          searchVolume: 98358,
          organicRank: 157,

          keywordSales: 1066
        },
        {
          date: '2024-06-19',
          searchVolume: 98358,
          organicRank: 140,

          keywordSales: 1066
        },
        {
          date: '2024-06-18',
          searchVolume: 98358,
          organicRank: 136,

          keywordSales: 1066
        },
        {
          date: '2024-06-17',
          searchVolume: 98358,
          organicRank: 132,

          keywordSales: 1066
        },
        {
          date: '2024-06-16',
          searchVolume: 98358,
          organicRank: 142,

          keywordSales: 1066
        },
        {
          date: '2024-06-15',
          searchVolume: 98358,
          organicRank: 143,

          keywordSales: 1066
        },
        {
          date: '2024-06-14',
          searchVolume: 98460,
          organicRank: 145,

          keywordSales: 1066
        },
        {
          date: '2024-06-13',
          searchVolume: 98460,
          organicRank: 118,
          sponsoredRank: 19,
          keywordSales: 1066
        },
        {
          date: '2024-06-12',
          searchVolume: 98460,
          organicRank: 152,

          keywordSales: 1066
        },
        {
          date: '2024-06-11',
          searchVolume: 98460,
          organicRank: 149,

          keywordSales: 1066
        },
        {
          date: '2024-06-10',
          searchVolume: 98460,
          organicRank: 141,

          keywordSales: 1066
        },
        {
          date: '2024-06-09',
          searchVolume: 98460,
          organicRank: 138,

          keywordSales: 1066
        },
        {
          date: '2024-06-08',
          searchVolume: 98460,
          organicRank: 155,
          sponsoredRank: 54,
          keywordSales: 1066
        },
        {
          date: '2024-06-07',
          searchVolume: 93879,
          organicRank: 143,

          keywordSales: 1066
        },
        {
          date: '2024-06-06',
          searchVolume: 93879,
          organicRank: 124,

          keywordSales: 1066
        },
        {
          date: '2024-06-05',
          searchVolume: 93879,
          organicRank: 114,

          keywordSales: 1066
        },
        {
          date: '2024-06-04',
          searchVolume: 93879,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-06-03',
          searchVolume: 93879,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-06-02',
          searchVolume: 93879,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-06-01',
          searchVolume: 93879,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 1066
        },
        {
          date: '2024-05-31',
          searchVolume: 96909,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-05-30',
          searchVolume: 96909,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-05-29',
          searchVolume: 96909,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-05-28',
          searchVolume: 96909,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-05-27',
          searchVolume: 96909,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-05-26',
          searchVolume: 96909,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-05-25',
          searchVolume: 96909,
          organicRank: 306,

          keywordSales: 1066
        },
        {
          date: '2024-05-24',
          searchVolume: 96878,
          organicRank: 306,

          keywordSales: 1066
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_camera`,
      keyword: 'camera',
      searchVolume: 224448,
      organicStartRank: 290,
      organicHighestRank: 253,
      sponsoredStartRank: 72,
      sponsoredHighestRank: 22,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 213500,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-07-02',
          searchVolume: 213500,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-07-01',
          searchVolume: 213500,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-30',
          searchVolume: 213500,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-29',
          searchVolume: 213500,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-28',
          searchVolume: 226806,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-27',
          searchVolume: 226806,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-26',
          searchVolume: 226806,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-25',
          searchVolume: 226806,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-24',
          searchVolume: 226806,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-21',
          searchVolume: 228538,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-20',
          searchVolume: 228538,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-19',
          searchVolume: 228538,
          organicRank: 306,
          sponsoredRank: 36,
          keywordSales: 1941
        },
        {
          date: '2024-06-18',
          searchVolume: 228538,
          organicRank: 306,
          sponsoredRank: 58,
          keywordSales: 1941
        },
        {
          date: '2024-06-17',
          searchVolume: 228538,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-16',
          searchVolume: 228538,
          organicRank: 287,

          keywordSales: 1941
        },
        {
          date: '2024-06-15',
          searchVolume: 228538,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-14',
          searchVolume: 228041,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-13',
          searchVolume: 228041,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-12',
          searchVolume: 228041,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-11',
          searchVolume: 228041,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-10',
          searchVolume: 228041,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-09',
          searchVolume: 228041,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-08',
          searchVolume: 228041,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-07',
          searchVolume: 221390,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-06',
          searchVolume: 221390,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-05',
          searchVolume: 221390,
          organicRank: 306,
          sponsoredRank: 63,
          keywordSales: 1941
        },
        {
          date: '2024-06-04',
          searchVolume: 221390,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 1941
        },
        {
          date: '2024-06-03',
          searchVolume: 221390,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-06-02',
          searchVolume: 221390,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 1941
        },
        {
          date: '2024-06-01',
          searchVolume: 221390,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 1941
        },
        {
          date: '2024-05-31',
          searchVolume: 225348,
          organicRank: 306,
          sponsoredRank: 34,
          keywordSales: 1941
        },
        {
          date: '2024-05-30',
          searchVolume: 225348,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 1941
        },
        {
          date: '2024-05-29',
          searchVolume: 225348,
          organicRank: 253,
          sponsoredRank: 71,
          keywordSales: 1941
        },
        {
          date: '2024-05-28',
          searchVolume: 225348,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 1941
        },
        {
          date: '2024-05-27',
          searchVolume: 225348,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 1941
        },
        {
          date: '2024-05-26',
          searchVolume: 225348,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 1941
        },
        {
          date: '2024-05-25',
          searchVolume: 225348,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-05-24',
          searchVolume: 224448,
          organicRank: 306,

          keywordSales: 1941
        },
        {
          date: '2024-05-21',
          searchVolume: 224448,
          organicRank: 290,

          keywordSales: 1941
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_camera indoor`,
      keyword: 'camera indoor',
      searchVolume: 2153,
      organicStartRank: 271,
      organicHighestRank: 136,
      sponsoredStartRank: 56,
      sponsoredHighestRank: 56,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-07-03',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-07-02',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-07-01',
          searchVolume: 2550,
          organicRank: 259,

          keywordSales: 63
        },
        {
          date: '2024-06-30',
          searchVolume: 2550,
          organicRank: 152,

          keywordSales: 63
        },
        {
          date: '2024-06-29',
          searchVolume: 2550,
          organicRank: 145,

          keywordSales: 63
        },
        {
          date: '2024-06-28',
          searchVolume: 2180,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-27',
          searchVolume: 2180,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-26',
          searchVolume: 2180,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-25',
          searchVolume: 2180,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-23',
          searchVolume: 2180,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-21',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-20',
          searchVolume: 2251,
          organicRank: 266,

          keywordSales: 63
        },
        {
          date: '2024-06-19',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-18',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-17',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-16',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-15',
          searchVolume: 2251,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-14',
          searchVolume: 2428,
          organicRank: 306,
          sponsoredRank: 62,
          keywordSales: 63
        },
        {
          date: '2024-06-13',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-12',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-11',
          searchVolume: 2428,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 63
        },
        {
          date: '2024-06-10',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-09',
          searchVolume: 2428,
          organicRank: 277,

          keywordSales: 63
        },
        {
          date: '2024-06-08',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-07',
          searchVolume: 2319,
          organicRank: 190,

          keywordSales: 63
        },
        {
          date: '2024-06-06',
          searchVolume: 2319,
          organicRank: 187,

          keywordSales: 63
        },
        {
          date: '2024-06-05',
          searchVolume: 2319,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-04',
          searchVolume: 2319,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-03',
          searchVolume: 2319,
          organicRank: 267,

          keywordSales: 63
        },
        {
          date: '2024-06-02',
          searchVolume: 2319,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-01',
          searchVolume: 2319,
          organicRank: 148,

          keywordSales: 63
        },
        {
          date: '2024-05-31',
          searchVolume: 2140,
          organicRank: 136,

          keywordSales: 63
        },
        {
          date: '2024-05-30',
          searchVolume: 2140,
          organicRank: 149,

          keywordSales: 63
        },
        {
          date: '2024-05-29',
          searchVolume: 2140,
          organicRank: 151,

          keywordSales: 63
        },
        {
          date: '2024-05-28',
          searchVolume: 2140,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-05-27',
          searchVolume: 2140,
          organicRank: 157,
          sponsoredRank: 56,
          keywordSales: 63
        },
        {
          date: '2024-05-26',
          searchVolume: 2140,
          organicRank: 148,

          keywordSales: 63
        },
        {
          date: '2024-05-25',
          searchVolume: 2140,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-05-24',
          searchVolume: 2153,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-05-21',
          searchVolume: 2153,
          organicRank: 271,

          keywordSales: 63
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_camera outdoor`,
      keyword: 'camera outdoor',
      searchVolume: 2509,
      organicStartRank: 67,
      organicHighestRank: 16,
      sponsoredStartRank: 80,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-28',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2430,
          organicRank: 33,
          sponsoredRank: 53,
          keywordSales: 18
        },
        {
          date: '2024-07-02',
          searchVolume: 2430,
          organicRank: 22,
          sponsoredRank: 8,
          keywordSales: 18
        },
        {
          date: '2024-07-01',
          searchVolume: 2430,
          organicRank: 28,

          keywordSales: 18
        },
        {
          date: '2024-06-30',
          searchVolume: 2430,
          organicRank: 24,
          sponsoredRank: 83,
          keywordSales: 18
        },
        {
          date: '2024-06-29',
          searchVolume: 2430,
          organicRank: 20,
          sponsoredRank: 1,
          keywordSales: 18
        },
        {
          date: '2024-06-28',
          searchVolume: 2165,
          organicRank: 16,
          sponsoredRank: 1,
          keywordSales: 18
        },
        {
          date: '2024-06-27',
          searchVolume: 2165,
          organicRank: 37,
          sponsoredRank: 42,
          keywordSales: 18
        },
        {
          date: '2024-06-26',
          searchVolume: 2165,
          organicRank: 50,
          sponsoredRank: 42,
          keywordSales: 18
        },
        {
          date: '2024-06-25',
          searchVolume: 2165,
          organicRank: 37,
          sponsoredRank: 36,
          keywordSales: 18
        },
        {
          date: '2024-06-24',
          searchVolume: 2165,
          organicRank: 36,
          sponsoredRank: 6,
          keywordSales: 18
        },
        {
          date: '2024-06-23',
          searchVolume: 2165,
          organicRank: 48,
          sponsoredRank: 7,
          keywordSales: 18
        },
        {
          date: '2024-06-22',
          searchVolume: 2165,
          organicRank: 47,
          sponsoredRank: 1,
          keywordSales: 18
        },
        {
          date: '2024-06-20',
          searchVolume: 2173,
          organicRank: 54,
          sponsoredRank: 3,
          keywordSales: 18
        },
        {
          date: '2024-06-19',
          searchVolume: 2173,
          organicRank: 72,
          sponsoredRank: 2,
          keywordSales: 18
        },
        {
          date: '2024-06-18',
          searchVolume: 2173,
          organicRank: 73,

          keywordSales: 18
        },
        {
          date: '2024-06-17',
          searchVolume: 2173,
          organicRank: 43,

          keywordSales: 18
        },
        {
          date: '2024-06-16',
          searchVolume: 2173,
          organicRank: 92,
          sponsoredRank: 33,
          keywordSales: 18
        },
        {
          date: '2024-06-15',
          searchVolume: 2173,
          organicRank: 55,
          sponsoredRank: 10,
          keywordSales: 18
        },
        {
          date: '2024-06-14',
          searchVolume: 2353,
          organicRank: 267,
          sponsoredRank: 66,
          keywordSales: 18
        },
        {
          date: '2024-06-13',
          searchVolume: 2353,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 18
        },
        {
          date: '2024-06-12',
          searchVolume: 2353,
          organicRank: 306,
          sponsoredRank: 69,
          keywordSales: 18
        },
        {
          date: '2024-06-11',
          searchVolume: 2353,
          organicRank: 306,

          keywordSales: 18
        },
        {
          date: '2024-06-10',
          searchVolume: 2353,
          organicRank: 81,

          keywordSales: 18
        },
        {
          date: '2024-06-09',
          searchVolume: 2353,
          organicRank: 80,
          sponsoredRank: 97,
          keywordSales: 18
        },
        {
          date: '2024-06-08',
          searchVolume: 2353,
          organicRank: 67,
          sponsoredRank: 49,
          keywordSales: 18
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 86,

          keywordSales: 18
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 68,

          keywordSales: 18
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 63,
          sponsoredRank: 2,
          keywordSales: 18
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 52,
          sponsoredRank: 19,
          keywordSales: 18
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 25,

          keywordSales: 18
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 69,

          keywordSales: 18
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 64,

          keywordSales: 18
        },
        {
          date: '2024-05-31',
          searchVolume: 2358,
          organicRank: 64,

          keywordSales: 18
        },
        {
          date: '2024-05-30',
          searchVolume: 2358,
          organicRank: 75,

          keywordSales: 18
        },
        {
          date: '2024-05-29',
          searchVolume: 2358,
          organicRank: 78,

          keywordSales: 18
        },
        {
          date: '2024-05-28',
          searchVolume: 2358,
          organicRank: 61,
          sponsoredRank: 38,
          keywordSales: 18
        },
        {
          date: '2024-05-27',
          searchVolume: 2358,
          organicRank: 65,
          sponsoredRank: 87,
          keywordSales: 18
        },
        {
          date: '2024-05-26',
          searchVolume: 2358,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 18
        },
        {
          date: '2024-05-25',
          searchVolume: 2358,
          organicRank: 61,
          sponsoredRank: 1,
          keywordSales: 18
        },
        {
          date: '2024-05-24',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 18
        },
        {
          date: '2024-05-23',
          searchVolume: 2509,
          organicRank: 64,

          keywordSales: 18
        },
        {
          date: '2024-05-22',
          searchVolume: 2509,
          organicRank: 67,
          sponsoredRank: 80,
          keywordSales: 18
        },
        {
          date: '2024-05-21',
          searchVolume: 2509,
          organicRank: 90,
          sponsoredRank: 67,
          keywordSales: 18
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_camera outdoor wireless`,
      keyword: 'camera outdoor wireless',
      searchVolume: 3420,
      organicStartRank: 47,
      organicHighestRank: 14,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-27',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 3428,
          organicRank: 49,
          sponsoredRank: 27,
          keywordSales: 37
        },
        {
          date: '2024-07-02',
          searchVolume: 3428,
          organicRank: 46,
          sponsoredRank: 78,
          keywordSales: 37
        },
        {
          date: '2024-07-01',
          searchVolume: 3428,
          organicRank: 43,

          keywordSales: 37
        },
        {
          date: '2024-06-30',
          searchVolume: 3428,
          organicRank: 43,

          keywordSales: 37
        },
        {
          date: '2024-06-29',
          searchVolume: 3428,
          organicRank: 49,
          sponsoredRank: 1,
          keywordSales: 37
        },
        {
          date: '2024-06-28',
          searchVolume: 3116,
          organicRank: 57,
          sponsoredRank: 1,
          keywordSales: 37
        },
        {
          date: '2024-06-27',
          searchVolume: 3116,
          organicRank: 43,
          sponsoredRank: 42,
          keywordSales: 37
        },
        {
          date: '2024-06-26',
          searchVolume: 3116,
          organicRank: 58,
          sponsoredRank: 32,
          keywordSales: 37
        },
        {
          date: '2024-06-25',
          searchVolume: 3116,
          organicRank: 45,
          sponsoredRank: 29,
          keywordSales: 37
        },
        {
          date: '2024-06-24',
          searchVolume: 3116,
          organicRank: 60,
          sponsoredRank: 16,
          keywordSales: 37
        },
        {
          date: '2024-06-23',
          searchVolume: 3116,
          organicRank: 53,
          sponsoredRank: 17,
          keywordSales: 37
        },
        {
          date: '2024-06-22',
          searchVolume: 3116,
          organicRank: 58,
          sponsoredRank: 12,
          keywordSales: 37
        },
        {
          date: '2024-06-21',
          searchVolume: 2816,
          organicRank: 20,

          keywordSales: 37
        },
        {
          date: '2024-06-20',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 37
        },
        {
          date: '2024-06-19',
          searchVolume: 2816,
          organicRank: 29,
          sponsoredRank: 24,
          keywordSales: 37
        },
        {
          date: '2024-06-18',
          searchVolume: 2816,
          organicRank: 30,
          sponsoredRank: 77,
          keywordSales: 37
        },
        {
          date: '2024-06-17',
          searchVolume: 2816,
          organicRank: 25,

          keywordSales: 37
        },
        {
          date: '2024-06-16',
          searchVolume: 2816,
          organicRank: 23,
          sponsoredRank: 21,
          keywordSales: 37
        },
        {
          date: '2024-06-15',
          searchVolume: 2816,
          organicRank: 38,
          sponsoredRank: 83,
          keywordSales: 37
        },
        {
          date: '2024-06-14',
          searchVolume: 3239,
          organicRank: 96,
          sponsoredRank: 34,
          keywordSales: 37
        },
        {
          date: '2024-06-13',
          searchVolume: 3239,
          organicRank: 78,
          sponsoredRank: 41,
          keywordSales: 37
        },
        {
          date: '2024-06-12',
          searchVolume: 3239,
          organicRank: 306,

          keywordSales: 37
        },
        {
          date: '2024-06-11',
          searchVolume: 3239,
          organicRank: 89,

          keywordSales: 37
        },
        {
          date: '2024-06-10',
          searchVolume: 3239,
          organicRank: 30,

          keywordSales: 37
        },
        {
          date: '2024-06-09',
          searchVolume: 3239,
          organicRank: 23,
          sponsoredRank: 100,
          keywordSales: 37
        },
        {
          date: '2024-06-08',
          searchVolume: 3239,
          organicRank: 32,

          keywordSales: 37
        },
        {
          date: '2024-06-07',
          searchVolume: 2434,
          organicRank: 28,

          keywordSales: 37
        },
        {
          date: '2024-06-06',
          searchVolume: 2434,
          organicRank: 19,
          sponsoredRank: 19,
          keywordSales: 37
        },
        {
          date: '2024-06-05',
          searchVolume: 2434,
          organicRank: 18,
          sponsoredRank: 52,
          keywordSales: 37
        },
        {
          date: '2024-06-04',
          searchVolume: 2434,
          organicRank: 17,
          sponsoredRank: 24,
          keywordSales: 37
        },
        {
          date: '2024-06-03',
          searchVolume: 2434,
          organicRank: 28,

          keywordSales: 37
        },
        {
          date: '2024-06-02',
          searchVolume: 2434,
          organicRank: 17,

          keywordSales: 37
        },
        {
          date: '2024-06-01',
          searchVolume: 2434,
          organicRank: 36,

          keywordSales: 37
        },
        {
          date: '2024-05-31',
          searchVolume: 2513,
          organicRank: 35,
          sponsoredRank: 8,
          keywordSales: 37
        },
        {
          date: '2024-05-30',
          searchVolume: 2513,
          organicRank: 20,

          keywordSales: 37
        },
        {
          date: '2024-05-29',
          searchVolume: 2513,
          organicRank: 17,
          sponsoredRank: 47,
          keywordSales: 37
        },
        {
          date: '2024-05-28',
          searchVolume: 2513,
          organicRank: 29,
          sponsoredRank: 33,
          keywordSales: 37
        },
        {
          date: '2024-05-27',
          searchVolume: 2513,
          organicRank: 14,
          sponsoredRank: 53,
          keywordSales: 37
        },
        {
          date: '2024-05-26',
          searchVolume: 2513,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 37
        },
        {
          date: '2024-05-25',
          searchVolume: 2513,
          organicRank: 24,
          sponsoredRank: 5,
          keywordSales: 37
        },
        {
          date: '2024-05-24',
          searchVolume: 3420,
          organicRank: 30,

          keywordSales: 37
        },
        {
          date: '2024-05-23',
          searchVolume: 3420,
          organicRank: 43,
          sponsoredRank: 65,
          keywordSales: 37
        },
        {
          date: '2024-05-22',
          searchVolume: 3420,
          organicRank: 47,
          sponsoredRank: 29,
          keywordSales: 37
        },
        {
          date: '2024-05-21',
          searchVolume: 3420,
          organicRank: 49,
          sponsoredRank: 77,
          keywordSales: 37
        },
        {
          date: '2024-05-20',
          searchVolume: 3420,
          organicRank: 59,

          keywordSales: 37
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_camera security`,
      keyword: 'camera security',
      searchVolume: 3093,
      organicStartRank: 306,
      organicHighestRank: 81,
      sponsoredStartRank: 27,
      sponsoredHighestRank: 27,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 4325,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-07-03',
          searchVolume: 4325,
          organicRank: 245,

          keywordSales: 24
        },
        {
          date: '2024-07-02',
          searchVolume: 4325,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-07-01',
          searchVolume: 4325,
          organicRank: 133,

          keywordSales: 24
        },
        {
          date: '2024-06-30',
          searchVolume: 4325,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-29',
          searchVolume: 4325,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-28',
          searchVolume: 4311,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-27',
          searchVolume: 4311,
          organicRank: 306,
          sponsoredRank: 93,
          keywordSales: 24
        },
        {
          date: '2024-06-26',
          searchVolume: 4311,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 24
        },
        {
          date: '2024-06-25',
          searchVolume: 4311,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-24',
          searchVolume: 4311,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-21',
          searchVolume: 3887,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-20',
          searchVolume: 3887,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-19',
          searchVolume: 3887,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-18',
          searchVolume: 3887,
          organicRank: 91,

          keywordSales: 24
        },
        {
          date: '2024-06-17',
          searchVolume: 3887,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-16',
          searchVolume: 3887,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-15',
          searchVolume: 3887,
          organicRank: 81,

          keywordSales: 24
        },
        {
          date: '2024-06-14',
          searchVolume: 3624,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-13',
          searchVolume: 3624,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 24
        },
        {
          date: '2024-06-12',
          searchVolume: 3624,
          organicRank: 84,

          keywordSales: 24
        },
        {
          date: '2024-06-11',
          searchVolume: 3624,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-10',
          searchVolume: 3624,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-09',
          searchVolume: 3624,
          organicRank: 306,
          sponsoredRank: 58,
          keywordSales: 24
        },
        {
          date: '2024-06-08',
          searchVolume: 3624,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-07',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-06',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-05',
          searchVolume: 3093,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 24
        },
        {
          date: '2024-06-04',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-03',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-02',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-06-01',
          searchVolume: 3093,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-31',
          searchVolume: 3371,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 24
        },
        {
          date: '2024-05-30',
          searchVolume: 3371,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-29',
          searchVolume: 3371,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-28',
          searchVolume: 3371,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-27',
          searchVolume: 3371,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-26',
          searchVolume: 3371,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-25',
          searchVolume: 3371,
          organicRank: 306,

          keywordSales: 24
        },
        {
          date: '2024-05-24',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 24
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_camera system`,
      keyword: 'camera system',
      searchVolume: 2511,
      organicStartRank: 66,
      organicHighestRank: 49,
      sponsoredStartRank: 91,
      sponsoredHighestRank: 33,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2394,
          organicRank: 93,

          keywordSales: 49
        },
        {
          date: '2024-07-02',
          searchVolume: 2394,
          organicRank: 86,

          keywordSales: 49
        },
        {
          date: '2024-07-01',
          searchVolume: 2394,
          organicRank: 86,

          keywordSales: 49
        },
        {
          date: '2024-06-30',
          searchVolume: 2394,
          organicRank: 72,

          keywordSales: 49
        },
        {
          date: '2024-06-29',
          searchVolume: 2394,
          organicRank: 58,

          keywordSales: 49
        },
        {
          date: '2024-06-28',
          searchVolume: 2180,
          organicRank: 55,

          keywordSales: 49
        },
        {
          date: '2024-06-27',
          searchVolume: 2180,
          organicRank: 65,

          keywordSales: 49
        },
        {
          date: '2024-06-26',
          searchVolume: 2180,
          organicRank: 61,

          keywordSales: 49
        },
        {
          date: '2024-06-25',
          searchVolume: 2180,
          organicRank: 49,

          keywordSales: 49
        },
        {
          date: '2024-06-21',
          searchVolume: 2389,
          organicRank: 51,

          keywordSales: 49
        },
        {
          date: '2024-06-20',
          searchVolume: 2389,
          organicRank: 49,

          keywordSales: 49
        },
        {
          date: '2024-06-19',
          searchVolume: 2389,
          organicRank: 71,

          keywordSales: 49
        },
        {
          date: '2024-06-18',
          searchVolume: 2389,
          organicRank: 54,

          keywordSales: 49
        },
        {
          date: '2024-06-17',
          searchVolume: 2389,
          organicRank: 63,

          keywordSales: 49
        },
        {
          date: '2024-06-16',
          searchVolume: 2389,
          organicRank: 78,

          keywordSales: 49
        },
        {
          date: '2024-06-15',
          searchVolume: 2389,
          organicRank: 64,

          keywordSales: 49
        },
        {
          date: '2024-06-14',
          searchVolume: 2430,
          organicRank: 78,

          keywordSales: 49
        },
        {
          date: '2024-06-13',
          searchVolume: 2430,
          organicRank: 94,

          keywordSales: 49
        },
        {
          date: '2024-06-12',
          searchVolume: 2430,
          organicRank: 146,

          keywordSales: 49
        },
        {
          date: '2024-06-11',
          searchVolume: 2430,
          organicRank: 143,

          keywordSales: 49
        },
        {
          date: '2024-06-10',
          searchVolume: 2430,
          organicRank: 96,

          keywordSales: 49
        },
        {
          date: '2024-06-09',
          searchVolume: 2430,
          organicRank: 116,

          keywordSales: 49
        },
        {
          date: '2024-06-08',
          searchVolume: 2430,
          organicRank: 128,

          keywordSales: 49
        },
        {
          date: '2024-06-07',
          searchVolume: 2429,
          organicRank: 125,

          keywordSales: 49
        },
        {
          date: '2024-06-06',
          searchVolume: 2429,
          organicRank: 99,
          sponsoredRank: 83,
          keywordSales: 49
        },
        {
          date: '2024-06-05',
          searchVolume: 2429,
          organicRank: 111,
          sponsoredRank: 33,
          keywordSales: 49
        },
        {
          date: '2024-06-04',
          searchVolume: 2429,
          organicRank: 306,
          sponsoredRank: 50,
          keywordSales: 49
        },
        {
          date: '2024-06-03',
          searchVolume: 2429,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-02',
          searchVolume: 2429,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-01',
          searchVolume: 2429,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 49
        },
        {
          date: '2024-05-31',
          searchVolume: 2392,
          organicRank: 68,
          sponsoredRank: 60,
          keywordSales: 49
        },
        {
          date: '2024-05-30',
          searchVolume: 2392,
          organicRank: 61,

          keywordSales: 49
        },
        {
          date: '2024-05-29',
          searchVolume: 2392,
          organicRank: 65,
          sponsoredRank: 91,
          keywordSales: 49
        },
        {
          date: '2024-05-28',
          searchVolume: 2392,
          organicRank: 59,

          keywordSales: 49
        },
        {
          date: '2024-05-27',
          searchVolume: 2392,
          organicRank: 53,

          keywordSales: 49
        },
        {
          date: '2024-05-26',
          searchVolume: 2392,
          organicRank: 60,

          keywordSales: 49
        },
        {
          date: '2024-05-25',
          searchVolume: 2392,
          organicRank: 49,

          keywordSales: 49
        },
        {
          date: '2024-05-24',
          searchVolume: 2511,
          organicRank: 63,

          keywordSales: 49
        },
        {
          date: '2024-05-23',
          searchVolume: 2511,
          organicRank: 127,

          keywordSales: 49
        },
        {
          date: '2024-05-22',
          searchVolume: 2511,
          organicRank: 66,

          keywordSales: 49
        },
        {
          date: '2024-05-21',
          searchVolume: 2511,
          organicRank: 95,

          keywordSales: 49
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_cameras for home security`,
      keyword: 'cameras for home security',
      searchVolume: 152831,
      organicStartRank: 265,
      organicHighestRank: 53,
      sponsoredStartRank: 63,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 155206,
          organicRank: 142,
          sponsoredRank: 36,
          keywordSales: 2191
        },
        {
          date: '2024-07-02',
          searchVolume: 155206,
          organicRank: 306,
          sponsoredRank: 2,
          keywordSales: 2191
        },
        {
          date: '2024-07-01',
          searchVolume: 155206,
          organicRank: 129,

          keywordSales: 2191
        },
        {
          date: '2024-06-30',
          searchVolume: 155206,
          organicRank: 177,

          keywordSales: 2191
        },
        {
          date: '2024-06-29',
          searchVolume: 155206,
          organicRank: 53,

          keywordSales: 2191
        },
        {
          date: '2024-06-28',
          searchVolume: 154460,
          organicRank: 86,

          keywordSales: 2191
        },
        {
          date: '2024-06-27',
          searchVolume: 154460,
          organicRank: 106,
          sponsoredRank: 28,
          keywordSales: 2191
        },
        {
          date: '2024-06-26',
          searchVolume: 154460,
          organicRank: 260,
          sponsoredRank: 20,
          keywordSales: 2191
        },
        {
          date: '2024-06-25',
          searchVolume: 154460,
          organicRank: 146,

          keywordSales: 2191
        },
        {
          date: '2024-06-24',
          searchVolume: 154460,
          organicRank: 151,
          sponsoredRank: 3,
          keywordSales: 2191
        },
        {
          date: '2024-06-23',
          searchVolume: 154460,
          organicRank: 136,
          sponsoredRank: 21,
          keywordSales: 2191
        },
        {
          date: '2024-06-22',
          searchVolume: 154460,
          organicRank: 152,
          sponsoredRank: 43,
          keywordSales: 2191
        },
        {
          date: '2024-06-21',
          searchVolume: 150834,
          organicRank: 145,
          sponsoredRank: 47,
          keywordSales: 2191
        },
        {
          date: '2024-06-20',
          searchVolume: 150834,
          organicRank: 255,
          sponsoredRank: 18,
          keywordSales: 2191
        },
        {
          date: '2024-06-19',
          searchVolume: 150834,
          organicRank: 149,
          sponsoredRank: 12,
          keywordSales: 2191
        },
        {
          date: '2024-06-18',
          searchVolume: 150834,
          organicRank: 246,
          sponsoredRank: 16,
          keywordSales: 2191
        },
        {
          date: '2024-06-17',
          searchVolume: 150834,
          organicRank: 306,

          keywordSales: 2191
        },
        {
          date: '2024-06-16',
          searchVolume: 150834,
          organicRank: 306,

          keywordSales: 2191
        },
        {
          date: '2024-06-15',
          searchVolume: 150834,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 2191
        },
        {
          date: '2024-06-14',
          searchVolume: 153766,
          organicRank: 252,
          sponsoredRank: 25,
          keywordSales: 2191
        },
        {
          date: '2024-06-13',
          searchVolume: 153766,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 2191
        },
        {
          date: '2024-06-12',
          searchVolume: 153766,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 2191
        },
        {
          date: '2024-06-11',
          searchVolume: 153766,
          organicRank: 306,
          sponsoredRank: 45,
          keywordSales: 2191
        },
        {
          date: '2024-06-10',
          searchVolume: 153766,
          organicRank: 268,

          keywordSales: 2191
        },
        {
          date: '2024-06-09',
          searchVolume: 153766,
          organicRank: 251,
          sponsoredRank: 31,
          keywordSales: 2191
        },
        {
          date: '2024-06-08',
          searchVolume: 153766,
          organicRank: 306,

          keywordSales: 2191
        },
        {
          date: '2024-06-07',
          searchVolume: 136702,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 2191
        },
        {
          date: '2024-06-06',
          searchVolume: 136702,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 2191
        },
        {
          date: '2024-06-05',
          searchVolume: 136702,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 2191
        },
        {
          date: '2024-06-04',
          searchVolume: 136702,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 2191
        },
        {
          date: '2024-06-03',
          searchVolume: 136702,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 2191
        },
        {
          date: '2024-06-02',
          searchVolume: 136702,
          organicRank: 306,
          sponsoredRank: 45,
          keywordSales: 2191
        },
        {
          date: '2024-06-01',
          searchVolume: 136702,
          organicRank: 306,
          sponsoredRank: 70,
          keywordSales: 2191
        },
        {
          date: '2024-05-31',
          searchVolume: 146725,
          organicRank: 306,
          sponsoredRank: 88,
          keywordSales: 2191
        },
        {
          date: '2024-05-30',
          searchVolume: 146725,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 2191
        },
        {
          date: '2024-05-29',
          searchVolume: 146725,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 2191
        },
        {
          date: '2024-05-28',
          searchVolume: 146725,
          organicRank: 306,
          sponsoredRank: 54,
          keywordSales: 2191
        },
        {
          date: '2024-05-27',
          searchVolume: 146725,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 2191
        },
        {
          date: '2024-05-26',
          searchVolume: 146725,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 2191
        },
        {
          date: '2024-05-25',
          searchVolume: 146725,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 2191
        },
        {
          date: '2024-05-24',
          searchVolume: 152831,
          organicRank: 306,

          keywordSales: 2191
        },
        {
          date: '2024-05-23',
          searchVolume: 152831,
          organicRank: 174,
          sponsoredRank: 47,
          keywordSales: 2191
        },
        {
          date: '2024-05-22',
          searchVolume: 152831,
          organicRank: 265,
          sponsoredRank: 63,
          keywordSales: 2191
        },
        {
          date: '2024-05-21',
          searchVolume: 152831,
          organicRank: 266,
          sponsoredRank: 37,
          keywordSales: 2191
        },
        {
          date: '2024-05-20',
          searchVolume: 152831,
          organicRank: 271,

          keywordSales: 2191
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_cameras for home security indoor`,
      keyword: 'cameras for home security indoor',
      searchVolume: 3574,
      organicStartRank: 274,
      organicHighestRank: 131,
      sponsoredStartRank: 39,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 4550,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-07-02',
          searchVolume: 4550,
          organicRank: 168,
          sponsoredRank: 21,
          keywordSales: 54
        },
        {
          date: '2024-07-01',
          searchVolume: 4550,
          organicRank: 163,

          keywordSales: 54
        },
        {
          date: '2024-06-30',
          searchVolume: 4550,
          organicRank: 159,

          keywordSales: 54
        },
        {
          date: '2024-06-29',
          searchVolume: 4550,
          organicRank: 163,

          keywordSales: 54
        },
        {
          date: '2024-06-28',
          searchVolume: 4533,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-27',
          searchVolume: 4533,
          organicRank: 146,

          keywordSales: 54
        },
        {
          date: '2024-06-26',
          searchVolume: 4533,
          organicRank: 260,

          keywordSales: 54
        },
        {
          date: '2024-06-25',
          searchVolume: 4533,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-23',
          searchVolume: 4533,
          organicRank: 274,

          keywordSales: 54
        },
        {
          date: '2024-06-21',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-20',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-19',
          searchVolume: 4322,
          organicRank: 256,

          keywordSales: 54
        },
        {
          date: '2024-06-18',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-17',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-16',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-15',
          searchVolume: 4322,
          organicRank: 171,
          sponsoredRank: 64,
          keywordSales: 54
        },
        {
          date: '2024-06-14',
          searchVolume: 4324,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-13',
          searchVolume: 4324,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 54
        },
        {
          date: '2024-06-12',
          searchVolume: 4324,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-11',
          searchVolume: 4324,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-10',
          searchVolume: 4324,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-09',
          searchVolume: 4324,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 54
        },
        {
          date: '2024-06-08',
          searchVolume: 4324,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-07',
          searchVolume: 4123,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-06',
          searchVolume: 4123,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-05',
          searchVolume: 4123,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 54
        },
        {
          date: '2024-06-04',
          searchVolume: 4123,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 54
        },
        {
          date: '2024-06-03',
          searchVolume: 4123,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-02',
          searchVolume: 4123,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-01',
          searchVolume: 4123,
          organicRank: 157,

          keywordSales: 54
        },
        {
          date: '2024-05-31',
          searchVolume: 4256,
          organicRank: 143,

          keywordSales: 54
        },
        {
          date: '2024-05-30',
          searchVolume: 4256,
          organicRank: 270,

          keywordSales: 54
        },
        {
          date: '2024-05-29',
          searchVolume: 4256,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-05-28',
          searchVolume: 4256,
          organicRank: 139,

          keywordSales: 54
        },
        {
          date: '2024-05-27',
          searchVolume: 4256,
          organicRank: 131,

          keywordSales: 54
        },
        {
          date: '2024-05-26',
          searchVolume: 4256,
          organicRank: 139,

          keywordSales: 54
        },
        {
          date: '2024-05-25',
          searchVolume: 4256,
          organicRank: 136,

          keywordSales: 54
        },
        {
          date: '2024-05-24',
          searchVolume: 3574,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-05-21',
          searchVolume: 3574,
          organicRank: 274,
          sponsoredRank: 39,
          keywordSales: 54
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_cameras for home security outdoor`,
      keyword: 'cameras for home security outdoor',
      searchVolume: 12011,
      organicStartRank: 89,
      organicHighestRank: 66,
      sponsoredStartRank: 95,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 13427,
          organicRank: 258,
          sponsoredRank: 13,
          keywordSales: 256
        },
        {
          date: '2024-07-02',
          searchVolume: 13427,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 256
        },
        {
          date: '2024-07-01',
          searchVolume: 13427,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-30',
          searchVolume: 13427,
          organicRank: 85,

          keywordSales: 256
        },
        {
          date: '2024-06-29',
          searchVolume: 13427,
          organicRank: 70,

          keywordSales: 256
        },
        {
          date: '2024-06-28',
          searchVolume: 12161,
          organicRank: 77,
          sponsoredRank: 8,
          keywordSales: 256
        },
        {
          date: '2024-06-27',
          searchVolume: 12161,
          organicRank: 77,
          sponsoredRank: 10,
          keywordSales: 256
        },
        {
          date: '2024-06-26',
          searchVolume: 12161,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 256
        },
        {
          date: '2024-06-25',
          searchVolume: 12161,
          organicRank: 83,
          sponsoredRank: 11,
          keywordSales: 256
        },
        {
          date: '2024-06-24',
          searchVolume: 12161,
          organicRank: 66,

          keywordSales: 256
        },
        {
          date: '2024-06-23',
          searchVolume: 12161,
          organicRank: 69,
          sponsoredRank: 23,
          keywordSales: 256
        },
        {
          date: '2024-06-22',
          searchVolume: 12161,
          organicRank: 82,
          sponsoredRank: 2,
          keywordSales: 256
        },
        {
          date: '2024-06-20',
          searchVolume: 12198,
          organicRank: 76,
          sponsoredRank: 9,
          keywordSales: 256
        },
        {
          date: '2024-06-19',
          searchVolume: 12198,
          organicRank: 87,
          sponsoredRank: 57,
          keywordSales: 256
        },
        {
          date: '2024-06-18',
          searchVolume: 12198,
          organicRank: 306,
          sponsoredRank: 82,
          keywordSales: 256
        },
        {
          date: '2024-06-17',
          searchVolume: 12198,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-16',
          searchVolume: 12198,
          organicRank: 95,

          keywordSales: 256
        },
        {
          date: '2024-06-15',
          searchVolume: 12198,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 256
        },
        {
          date: '2024-06-14',
          searchVolume: 13429,
          organicRank: 117,
          sponsoredRank: 11,
          keywordSales: 256
        },
        {
          date: '2024-06-13',
          searchVolume: 13429,
          organicRank: 257,
          sponsoredRank: 32,
          keywordSales: 256
        },
        {
          date: '2024-06-12',
          searchVolume: 13429,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-11',
          searchVolume: 13429,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 256
        },
        {
          date: '2024-06-10',
          searchVolume: 13429,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-09',
          searchVolume: 13429,
          organicRank: 123,
          sponsoredRank: 47,
          keywordSales: 256
        },
        {
          date: '2024-06-08',
          searchVolume: 13429,
          organicRank: 93,

          keywordSales: 256
        },
        {
          date: '2024-06-07',
          searchVolume: 13506,
          organicRank: 123,
          sponsoredRank: 39,
          keywordSales: 256
        },
        {
          date: '2024-06-06',
          searchVolume: 13506,
          organicRank: 115,
          sponsoredRank: 23,
          keywordSales: 256
        },
        {
          date: '2024-06-05',
          searchVolume: 13506,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 256
        },
        {
          date: '2024-06-04',
          searchVolume: 13506,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 256
        },
        {
          date: '2024-06-03',
          searchVolume: 13506,
          organicRank: 98,

          keywordSales: 256
        },
        {
          date: '2024-06-02',
          searchVolume: 13506,
          organicRank: 86,

          keywordSales: 256
        },
        {
          date: '2024-06-01',
          searchVolume: 13506,
          organicRank: 95,

          keywordSales: 256
        },
        {
          date: '2024-05-31',
          searchVolume: 13239,
          organicRank: 106,

          keywordSales: 256
        },
        {
          date: '2024-05-30',
          searchVolume: 13239,
          organicRank: 88,

          keywordSales: 256
        },
        {
          date: '2024-05-29',
          searchVolume: 13239,
          organicRank: 90,
          sponsoredRank: 66,
          keywordSales: 256
        },
        {
          date: '2024-05-28',
          searchVolume: 13239,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-05-27',
          searchVolume: 13239,
          organicRank: 91,
          sponsoredRank: 45,
          keywordSales: 256
        },
        {
          date: '2024-05-26',
          searchVolume: 13239,
          organicRank: 103,
          sponsoredRank: 7,
          keywordSales: 256
        },
        {
          date: '2024-05-25',
          searchVolume: 13239,
          organicRank: 92,
          sponsoredRank: 52,
          keywordSales: 256
        },
        {
          date: '2024-05-24',
          searchVolume: 12011,
          organicRank: 97,
          sponsoredRank: 59,
          keywordSales: 256
        },
        {
          date: '2024-05-23',
          searchVolume: 12011,
          organicRank: 93,
          sponsoredRank: 10,
          keywordSales: 256
        },
        {
          date: '2024-05-22',
          searchVolume: 12011,
          organicRank: 89,
          sponsoredRank: 95,
          keywordSales: 256
        },
        {
          date: '2024-05-21',
          searchVolume: 12011,
          organicRank: 80,
          sponsoredRank: 21,
          keywordSales: 256
        },
        {
          date: '2024-05-20',
          searchVolume: 12011,
          organicRank: 93,

          keywordSales: 256
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_cameras for home security outdoor wireless wifi`,
      keyword: 'cameras for home security outdoor wireless wifi',
      searchVolume: 1888,
      organicStartRank: 240,
      organicHighestRank: 74,
      sponsoredStartRank: 33,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1608,
          organicRank: 306,
          sponsoredRank: 4,
          keywordSales: 14
        },
        {
          date: '2024-07-02',
          searchVolume: 1608,
          organicRank: 116,

          keywordSales: 14
        },
        {
          date: '2024-07-01',
          searchVolume: 1608,
          organicRank: 99,

          keywordSales: 14
        },
        {
          date: '2024-06-30',
          searchVolume: 1608,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-29',
          searchVolume: 1608,
          organicRank: 77,

          keywordSales: 14
        },
        {
          date: '2024-06-28',
          searchVolume: 1428,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-27',
          searchVolume: 1428,
          organicRank: 86,
          sponsoredRank: 8,
          keywordSales: 14
        },
        {
          date: '2024-06-26',
          searchVolume: 1428,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-25',
          searchVolume: 1428,
          organicRank: 92,

          keywordSales: 14
        },
        {
          date: '2024-06-24',
          searchVolume: 1428,
          organicRank: 91,

          keywordSales: 14
        },
        {
          date: '2024-06-21',
          searchVolume: 1661,
          organicRank: 114,

          keywordSales: 14
        },
        {
          date: '2024-06-20',
          searchVolume: 1661,
          organicRank: 107,

          keywordSales: 14
        },
        {
          date: '2024-06-19',
          searchVolume: 1661,
          organicRank: 77,

          keywordSales: 14
        },
        {
          date: '2024-06-18',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-17',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-16',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-15',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-14',
          searchVolume: 1846,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 14
        },
        {
          date: '2024-06-13',
          searchVolume: 1846,
          organicRank: 241,
          sponsoredRank: 2,
          keywordSales: 14
        },
        {
          date: '2024-06-12',
          searchVolume: 1846,
          organicRank: 134,

          keywordSales: 14
        },
        {
          date: '2024-06-11',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-10',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-09',
          searchVolume: 1846,
          organicRank: 306,
          sponsoredRank: 76,
          keywordSales: 14
        },
        {
          date: '2024-06-08',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-07',
          searchVolume: 1761,
          organicRank: 111,

          keywordSales: 14
        },
        {
          date: '2024-06-06',
          searchVolume: 1761,
          organicRank: 106,

          keywordSales: 14
        },
        {
          date: '2024-06-05',
          searchVolume: 1761,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 14
        },
        {
          date: '2024-06-04',
          searchVolume: 1761,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 14
        },
        {
          date: '2024-06-03',
          searchVolume: 1761,
          organicRank: 91,

          keywordSales: 14
        },
        {
          date: '2024-06-02',
          searchVolume: 1761,
          organicRank: 101,

          keywordSales: 14
        },
        {
          date: '2024-06-01',
          searchVolume: 1761,
          organicRank: 74,

          keywordSales: 14
        },
        {
          date: '2024-05-31',
          searchVolume: 1584,
          organicRank: 87,

          keywordSales: 14
        },
        {
          date: '2024-05-30',
          searchVolume: 1584,
          organicRank: 90,

          keywordSales: 14
        },
        {
          date: '2024-05-29',
          searchVolume: 1584,
          organicRank: 108,

          keywordSales: 14
        },
        {
          date: '2024-05-28',
          searchVolume: 1584,
          organicRank: 114,

          keywordSales: 14
        },
        {
          date: '2024-05-27',
          searchVolume: 1584,
          organicRank: 94,

          keywordSales: 14
        },
        {
          date: '2024-05-26',
          searchVolume: 1584,
          organicRank: 102,
          sponsoredRank: 31,
          keywordSales: 14
        },
        {
          date: '2024-05-25',
          searchVolume: 1584,
          organicRank: 111,

          keywordSales: 14
        },
        {
          date: '2024-05-24',
          searchVolume: 1888,
          organicRank: 102,

          keywordSales: 14
        },
        {
          date: '2024-05-22',
          searchVolume: 1888,
          organicRank: 240,
          sponsoredRank: 33,
          keywordSales: 14
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_cameras for home security wireless wifi`,
      keyword: 'cameras for home security wireless wifi',
      searchVolume: 2434,
      organicStartRank: 306,
      organicHighestRank: 100,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 3240,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 33
        },
        {
          date: '2024-07-03',
          searchVolume: 3240,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-07-02',
          searchVolume: 3240,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-07-01',
          searchVolume: 3240,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-30',
          searchVolume: 3240,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-29',
          searchVolume: 3240,
          organicRank: 100,

          keywordSales: 33
        },
        {
          date: '2024-06-28',
          searchVolume: 3116,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-27',
          searchVolume: 3116,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-26',
          searchVolume: 3116,
          organicRank: 151,

          keywordSales: 33
        },
        {
          date: '2024-06-25',
          searchVolume: 3116,
          organicRank: 306,
          sponsoredRank: 24,
          keywordSales: 33
        },
        {
          date: '2024-06-24',
          searchVolume: 3116,
          organicRank: 118,
          sponsoredRank: 6,
          keywordSales: 33
        },
        {
          date: '2024-06-21',
          searchVolume: 3464,
          organicRank: 119,
          sponsoredRank: 52,
          keywordSales: 33
        },
        {
          date: '2024-06-20',
          searchVolume: 3464,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-19',
          searchVolume: 3464,
          organicRank: 147,

          keywordSales: 33
        },
        {
          date: '2024-06-18',
          searchVolume: 3464,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-17',
          searchVolume: 3464,
          organicRank: 256,

          keywordSales: 33
        },
        {
          date: '2024-06-16',
          searchVolume: 3464,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-15',
          searchVolume: 3464,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-14',
          searchVolume: 3143,
          organicRank: 255,
          sponsoredRank: 10,
          keywordSales: 33
        },
        {
          date: '2024-06-13',
          searchVolume: 3143,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 33
        },
        {
          date: '2024-06-12',
          searchVolume: 3143,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-11',
          searchVolume: 3143,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-10',
          searchVolume: 3143,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-09',
          searchVolume: 3143,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 33
        },
        {
          date: '2024-06-08',
          searchVolume: 3143,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-07',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-06',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-05',
          searchVolume: 2434,
          organicRank: 306,
          sponsoredRank: 34,
          keywordSales: 33
        },
        {
          date: '2024-06-04',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-03',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-02',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-01',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-31',
          searchVolume: 3078,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 33
        },
        {
          date: '2024-05-30',
          searchVolume: 3078,
          organicRank: 261,

          keywordSales: 33
        },
        {
          date: '2024-05-29',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-28',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-27',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-26',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-25',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-05-24',
          searchVolume: 3191,
          organicRank: 306,

          keywordSales: 33
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_cellular security camera`,
      keyword: 'cellular security camera',
      searchVolume: 4257,
      organicStartRank: 148,
      organicHighestRank: 104,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 5703,
          organicRank: 186,

          keywordSales: 63
        },
        {
          date: '2024-07-03',
          searchVolume: 5703,
          organicRank: 171,

          keywordSales: 63
        },
        {
          date: '2024-07-02',
          searchVolume: 5703,
          organicRank: 170,
          sponsoredRank: 3,
          keywordSales: 63
        },
        {
          date: '2024-07-01',
          searchVolume: 5703,
          organicRank: 146,

          keywordSales: 63
        },
        {
          date: '2024-06-30',
          searchVolume: 5703,
          organicRank: 104,

          keywordSales: 63
        },
        {
          date: '2024-06-29',
          searchVolume: 5703,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-28',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-27',
          searchVolume: 4322,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 63
        },
        {
          date: '2024-06-26',
          searchVolume: 4322,
          organicRank: 141,

          keywordSales: 63
        },
        {
          date: '2024-06-25',
          searchVolume: 4322,
          organicRank: 147,

          keywordSales: 63
        },
        {
          date: '2024-06-23',
          searchVolume: 4322,
          organicRank: 157,
          sponsoredRank: 10,
          keywordSales: 63
        },
        {
          date: '2024-06-21',
          searchVolume: 4329,
          organicRank: 165,

          keywordSales: 63
        },
        {
          date: '2024-06-20',
          searchVolume: 4329,
          organicRank: 153,

          keywordSales: 63
        },
        {
          date: '2024-06-19',
          searchVolume: 4329,
          organicRank: 149,

          keywordSales: 63
        },
        {
          date: '2024-06-18',
          searchVolume: 4329,
          organicRank: 149,
          sponsoredRank: 44,
          keywordSales: 63
        },
        {
          date: '2024-06-17',
          searchVolume: 4329,
          organicRank: 171,

          keywordSales: 63
        },
        {
          date: '2024-06-16',
          searchVolume: 4329,
          organicRank: 147,

          keywordSales: 63
        },
        {
          date: '2024-06-15',
          searchVolume: 4329,
          organicRank: 162,

          keywordSales: 63
        },
        {
          date: '2024-06-14',
          searchVolume: 4549,
          organicRank: 306,
          sponsoredRank: 28,
          keywordSales: 63
        },
        {
          date: '2024-06-13',
          searchVolume: 4549,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 63
        },
        {
          date: '2024-06-12',
          searchVolume: 4549,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-11',
          searchVolume: 4549,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-10',
          searchVolume: 4549,
          organicRank: 156,

          keywordSales: 63
        },
        {
          date: '2024-06-09',
          searchVolume: 4549,
          organicRank: 167,
          sponsoredRank: 71,
          keywordSales: 63
        },
        {
          date: '2024-06-08',
          searchVolume: 4549,
          organicRank: 162,

          keywordSales: 63
        },
        {
          date: '2024-06-07',
          searchVolume: 78411,
          organicRank: 169,

          keywordSales: 63
        },
        {
          date: '2024-06-06',
          searchVolume: 78411,
          organicRank: 161,

          keywordSales: 63
        },
        {
          date: '2024-06-05',
          searchVolume: 78411,
          organicRank: 147,
          sponsoredRank: 83,
          keywordSales: 63
        },
        {
          date: '2024-06-04',
          searchVolume: 78411,
          organicRank: 152,
          sponsoredRank: 53,
          keywordSales: 63
        },
        {
          date: '2024-06-03',
          searchVolume: 78411,
          organicRank: 150,

          keywordSales: 63
        },
        {
          date: '2024-06-02',
          searchVolume: 78411,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-01',
          searchVolume: 78411,
          organicRank: 143,

          keywordSales: 63
        },
        {
          date: '2024-05-31',
          searchVolume: 83498,
          organicRank: 144,
          sponsoredRank: 42,
          keywordSales: 63
        },
        {
          date: '2024-05-30',
          searchVolume: 83498,
          organicRank: 144,

          keywordSales: 63
        },
        {
          date: '2024-05-29',
          searchVolume: 83498,
          organicRank: 121,

          keywordSales: 63
        },
        {
          date: '2024-05-28',
          searchVolume: 83498,
          organicRank: 120,

          keywordSales: 63
        },
        {
          date: '2024-05-27',
          searchVolume: 83498,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-05-26',
          searchVolume: 83498,
          organicRank: 131,

          keywordSales: 63
        },
        {
          date: '2024-05-25',
          searchVolume: 83498,
          organicRank: 129,

          keywordSales: 63
        },
        {
          date: '2024-05-24',
          searchVolume: 4257,
          organicRank: 163,

          keywordSales: 63
        },
        {
          date: '2024-05-23',
          searchVolume: 4257,
          organicRank: 134,

          keywordSales: 63
        },
        {
          date: '2024-05-22',
          searchVolume: 4257,
          organicRank: 148,

          keywordSales: 63
        },
        {
          date: '2024-05-21',
          searchVolume: 4257,
          organicRank: 156,
          sponsoredRank: 2,
          keywordSales: 63
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_eufy camera`,
      keyword: 'eufy camera',
      searchVolume: 20936,
      organicStartRank: 120,
      organicHighestRank: 109,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 19718,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-07-03',
          searchVolume: 19718,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-07-02',
          searchVolume: 19718,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-07-01',
          searchVolume: 19718,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-30',
          searchVolume: 19718,
          organicRank: 194,

          keywordSales: 593
        },
        {
          date: '2024-06-29',
          searchVolume: 19718,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-28',
          searchVolume: 21981,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-27',
          searchVolume: 21981,
          organicRank: 188,

          keywordSales: 593
        },
        {
          date: '2024-06-26',
          searchVolume: 21981,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-25',
          searchVolume: 21981,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-23',
          searchVolume: 21981,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-21',
          searchVolume: 20936,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-20',
          searchVolume: 20936,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-19',
          searchVolume: 20936,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-18',
          searchVolume: 20936,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-17',
          searchVolume: 20936,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-16',
          searchVolume: 20936,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-15',
          searchVolume: 20936,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-14',
          searchVolume: 20537,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-13',
          searchVolume: 20537,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-12',
          searchVolume: 20537,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-11',
          searchVolume: 20537,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-10',
          searchVolume: 20537,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-09',
          searchVolume: 20537,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-08',
          searchVolume: 20537,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-07',
          searchVolume: 18794,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-06',
          searchVolume: 18794,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-05',
          searchVolume: 18794,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-04',
          searchVolume: 18794,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-03',
          searchVolume: 18794,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-02',
          searchVolume: 18794,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-06-01',
          searchVolume: 18794,
          organicRank: 170,

          keywordSales: 593
        },
        {
          date: '2024-05-31',
          searchVolume: 19400,
          organicRank: 165,

          keywordSales: 593
        },
        {
          date: '2024-05-30',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-05-29',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-05-28',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-05-27',
          searchVolume: 19400,
          organicRank: 109,

          keywordSales: 593
        },
        {
          date: '2024-05-26',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-05-25',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-05-24',
          searchVolume: 19628,
          organicRank: 306,

          keywordSales: 593
        },
        {
          date: '2024-05-21',
          searchVolume: 19628,
          organicRank: 120,

          keywordSales: 593
        },
        {
          date: '2024-05-20',
          searchVolume: 19628,
          organicRank: 196,

          keywordSales: 593
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_eufy outdoor security camera`,
      keyword: 'eufy outdoor security camera',
      searchVolume: 1842,
      organicStartRank: 71,
      organicHighestRank: 57,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2398,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-07-03',
          searchVolume: 2398,
          organicRank: 142,

          keywordSales: 67
        },
        {
          date: '2024-07-02',
          searchVolume: 2398,
          organicRank: 142,

          keywordSales: 67
        },
        {
          date: '2024-07-01',
          searchVolume: 2398,
          organicRank: 128,

          keywordSales: 67
        },
        {
          date: '2024-06-30',
          searchVolume: 2398,
          organicRank: 132,

          keywordSales: 67
        },
        {
          date: '2024-06-29',
          searchVolume: 2398,
          organicRank: 110,

          keywordSales: 67
        },
        {
          date: '2024-06-28',
          searchVolume: 2165,
          organicRank: 103,

          keywordSales: 67
        },
        {
          date: '2024-06-27',
          searchVolume: 2165,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-26',
          searchVolume: 2165,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-25',
          searchVolume: 2165,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-23',
          searchVolume: 2165,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-21',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-20',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-19',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-18',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-17',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-16',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-15',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-14',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-13',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-12',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-11',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-10',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-09',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-08',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-07',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-06',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-05',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-04',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-03',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-02',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-01',
          searchVolume: 2085,
          organicRank: 74,

          keywordSales: 67
        },
        {
          date: '2024-05-31',
          searchVolume: 1891,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-05-30',
          searchVolume: 1891,
          organicRank: 87,

          keywordSales: 67
        },
        {
          date: '2024-05-29',
          searchVolume: 1891,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-05-28',
          searchVolume: 1891,
          organicRank: 80,

          keywordSales: 67
        },
        {
          date: '2024-05-27',
          searchVolume: 1891,
          organicRank: 57,

          keywordSales: 67
        },
        {
          date: '2024-05-26',
          searchVolume: 1891,
          organicRank: 60,

          keywordSales: 67
        },
        {
          date: '2024-05-25',
          searchVolume: 1891,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-05-24',
          searchVolume: 1842,
          organicRank: 71,

          keywordSales: 67
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_eufy security camera`,
      keyword: 'eufy security camera',
      searchVolume: 93812,
      organicStartRank: 306,
      organicHighestRank: 116,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 110348,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-07-02',
          searchVolume: 110348,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-07-01',
          searchVolume: 110348,
          organicRank: 166,

          keywordSales: 3161
        },
        {
          date: '2024-06-30',
          searchVolume: 110348,
          organicRank: 256,

          keywordSales: 3161
        },
        {
          date: '2024-06-29',
          searchVolume: 110348,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-28',
          searchVolume: 118116,
          organicRank: 164,

          keywordSales: 3161
        },
        {
          date: '2024-06-27',
          searchVolume: 118116,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-26',
          searchVolume: 118116,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-25',
          searchVolume: 118116,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-24',
          searchVolume: 118116,
          organicRank: 179,

          keywordSales: 3161
        },
        {
          date: '2024-06-22',
          searchVolume: 118116,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-21',
          searchVolume: 114697,
          organicRank: 190,

          keywordSales: 3161
        },
        {
          date: '2024-06-19',
          searchVolume: 114697,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-18',
          searchVolume: 114697,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-17',
          searchVolume: 114697,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-16',
          searchVolume: 114697,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-15',
          searchVolume: 114697,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-14',
          searchVolume: 98360,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-13',
          searchVolume: 98360,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-12',
          searchVolume: 98360,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-11',
          searchVolume: 98360,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-10',
          searchVolume: 98360,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-09',
          searchVolume: 98360,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-08',
          searchVolume: 98360,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-07',
          searchVolume: 93812,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-06',
          searchVolume: 93812,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-05',
          searchVolume: 93812,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-04',
          searchVolume: 93812,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-03',
          searchVolume: 93812,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-06-02',
          searchVolume: 93812,
          organicRank: 193,

          keywordSales: 3161
        },
        {
          date: '2024-06-01',
          searchVolume: 93812,
          organicRank: 159,

          keywordSales: 3161
        },
        {
          date: '2024-05-31',
          searchVolume: 101331,
          organicRank: 146,

          keywordSales: 3161
        },
        {
          date: '2024-05-30',
          searchVolume: 101331,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-05-29',
          searchVolume: 101331,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-05-28',
          searchVolume: 101331,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-05-27',
          searchVolume: 101331,
          organicRank: 132,

          keywordSales: 3161
        },
        {
          date: '2024-05-26',
          searchVolume: 101331,
          organicRank: 306,

          keywordSales: 3161
        },
        {
          date: '2024-05-25',
          searchVolume: 101331,
          organicRank: 116,

          keywordSales: 3161
        },
        {
          date: '2024-05-24',
          searchVolume: 96951,
          organicRank: 306,

          keywordSales: 3161
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_exterior cameras security wireless outdoor`,
      keyword: 'exterior cameras security wireless outdoor',
      searchVolume: 2509,
      organicStartRank: 140,
      organicHighestRank: 32,
      sponsoredStartRank: 89,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2430,
          organicRank: 53,

          keywordSales: 14
        },
        {
          date: '2024-07-03',
          searchVolume: 2430,
          organicRank: 52,

          keywordSales: 14
        },
        {
          date: '2024-07-02',
          searchVolume: 2430,
          organicRank: 63,

          keywordSales: 14
        },
        {
          date: '2024-07-01',
          searchVolume: 2430,
          organicRank: 116,

          keywordSales: 14
        },
        {
          date: '2024-06-30',
          searchVolume: 2430,
          organicRank: 115,

          keywordSales: 14
        },
        {
          date: '2024-06-29',
          searchVolume: 2430,
          organicRank: 115,

          keywordSales: 14
        },
        {
          date: '2024-06-28',
          searchVolume: 2420,
          organicRank: 103,
          sponsoredRank: 24,
          keywordSales: 14
        },
        {
          date: '2024-06-27',
          searchVolume: 2420,
          organicRank: 104,

          keywordSales: 14
        },
        {
          date: '2024-06-26',
          searchVolume: 2420,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 14
        },
        {
          date: '2024-06-25',
          searchVolume: 2420,
          organicRank: 53,

          keywordSales: 14
        },
        {
          date: '2024-06-24',
          searchVolume: 2420,
          organicRank: 67,

          keywordSales: 14
        },
        {
          date: '2024-06-21',
          searchVolume: 2171,
          organicRank: 66,
          sponsoredRank: 48,
          keywordSales: 14
        },
        {
          date: '2024-06-20',
          searchVolume: 2171,
          organicRank: 69,

          keywordSales: 14
        },
        {
          date: '2024-06-19',
          searchVolume: 2171,
          organicRank: 77,

          keywordSales: 14
        },
        {
          date: '2024-06-18',
          searchVolume: 2171,
          organicRank: 48,

          keywordSales: 14
        },
        {
          date: '2024-06-17',
          searchVolume: 2171,
          organicRank: 60,

          keywordSales: 14
        },
        {
          date: '2024-06-16',
          searchVolume: 2171,
          organicRank: 72,

          keywordSales: 14
        },
        {
          date: '2024-06-15',
          searchVolume: 2171,
          organicRank: 97,

          keywordSales: 14
        },
        {
          date: '2024-06-14',
          searchVolume: 1926,
          organicRank: 128,

          keywordSales: 14
        },
        {
          date: '2024-06-13',
          searchVolume: 1926,
          organicRank: 126,
          sponsoredRank: 15,
          keywordSales: 14
        },
        {
          date: '2024-06-12',
          searchVolume: 1926,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-11',
          searchVolume: 1926,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-10',
          searchVolume: 1926,
          organicRank: 84,

          keywordSales: 14
        },
        {
          date: '2024-06-09',
          searchVolume: 1926,
          organicRank: 79,
          sponsoredRank: 75,
          keywordSales: 14
        },
        {
          date: '2024-06-08',
          searchVolume: 1926,
          organicRank: 63,

          keywordSales: 14
        },
        {
          date: '2024-06-07',
          searchVolume: 1834,
          organicRank: 58,

          keywordSales: 14
        },
        {
          date: '2024-06-06',
          searchVolume: 1834,
          organicRank: 46,
          sponsoredRank: 2,
          keywordSales: 14
        },
        {
          date: '2024-06-05',
          searchVolume: 1834,
          organicRank: 51,
          sponsoredRank: 21,
          keywordSales: 14
        },
        {
          date: '2024-06-04',
          searchVolume: 1834,
          organicRank: 32,
          sponsoredRank: 16,
          keywordSales: 14
        },
        {
          date: '2024-06-03',
          searchVolume: 1834,
          organicRank: 306,

          keywordSales: 14
        },
        {
          date: '2024-06-02',
          searchVolume: 1834,
          organicRank: 75,
          sponsoredRank: 44,
          keywordSales: 14
        },
        {
          date: '2024-06-01',
          searchVolume: 1834,
          organicRank: 85,
          sponsoredRank: 42,
          keywordSales: 14
        },
        {
          date: '2024-05-31',
          searchVolume: 2138,
          organicRank: 82,
          sponsoredRank: 50,
          keywordSales: 14
        },
        {
          date: '2024-05-30',
          searchVolume: 2138,
          organicRank: 85,
          sponsoredRank: 67,
          keywordSales: 14
        },
        {
          date: '2024-05-29',
          searchVolume: 2138,
          organicRank: 86,
          sponsoredRank: 42,
          keywordSales: 14
        },
        {
          date: '2024-05-28',
          searchVolume: 2138,
          organicRank: 92,
          sponsoredRank: 86,
          keywordSales: 14
        },
        {
          date: '2024-05-27',
          searchVolume: 2138,
          organicRank: 73,
          sponsoredRank: 89,
          keywordSales: 14
        },
        {
          date: '2024-05-26',
          searchVolume: 2138,
          organicRank: 79,

          keywordSales: 14
        },
        {
          date: '2024-05-25',
          searchVolume: 2138,
          organicRank: 76,

          keywordSales: 14
        },
        {
          date: '2024-05-24',
          searchVolume: 2509,
          organicRank: 107,

          keywordSales: 14
        },
        {
          date: '2024-05-23',
          searchVolume: 2509,
          organicRank: 137,

          keywordSales: 14
        },
        {
          date: '2024-05-22',
          searchVolume: 2509,
          organicRank: 140,

          keywordSales: 14
        },
        {
          date: '2024-05-21',
          searchVolume: 2509,
          organicRank: 153,

          keywordSales: 14
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_front door camera wireless`,
      keyword: 'front door camera wireless',
      searchVolume: 2434,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 38,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-07-03',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-07-02',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-07-01',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-30',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-29',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-28',
          searchVolume: 2541,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-27',
          searchVolume: 2541,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 57
        },
        {
          date: '2024-06-26',
          searchVolume: 2541,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-25',
          searchVolume: 2541,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-24',
          searchVolume: 2541,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-17',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-16',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-15',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-14',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-13',
          searchVolume: 3126,
          organicRank: 306,
          sponsoredRank: 34,
          keywordSales: 57
        },
        {
          date: '2024-06-12',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-11',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-10',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-09',
          searchVolume: 3126,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 57
        },
        {
          date: '2024-06-08',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-07',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-06',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-05',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-04',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-03',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-02',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-06-01',
          searchVolume: 2434,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-05-31',
          searchVolume: 2359,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 57
        },
        {
          date: '2024-05-30',
          searchVolume: 2359,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-05-29',
          searchVolume: 2359,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-05-28',
          searchVolume: 2359,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-05-27',
          searchVolume: 2359,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-05-26',
          searchVolume: 2359,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-05-25',
          searchVolume: 2359,
          organicRank: 306,

          keywordSales: 57
        },
        {
          date: '2024-05-24',
          searchVolume: 2513,
          organicRank: 306,

          keywordSales: 57
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_google nest camera`,
      keyword: 'google nest camera',
      searchVolume: 15468,
      organicStartRank: 62,
      organicHighestRank: 46,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 16631,
          organicRank: 264,

          keywordSales: 971
        },
        {
          date: '2024-07-02',
          searchVolume: 16631,
          organicRank: 267,

          keywordSales: 971
        },
        {
          date: '2024-07-01',
          searchVolume: 16631,
          organicRank: 253,

          keywordSales: 971
        },
        {
          date: '2024-06-30',
          searchVolume: 16631,
          organicRank: 117,

          keywordSales: 971
        },
        {
          date: '2024-06-29',
          searchVolume: 16631,
          organicRank: 306,

          keywordSales: 971
        },
        {
          date: '2024-06-28',
          searchVolume: 14103,
          organicRank: 125,

          keywordSales: 971
        },
        {
          date: '2024-06-27',
          searchVolume: 14103,
          organicRank: 127,

          keywordSales: 971
        },
        {
          date: '2024-06-26',
          searchVolume: 14103,
          organicRank: 118,

          keywordSales: 971
        },
        {
          date: '2024-06-25',
          searchVolume: 14103,
          organicRank: 121,

          keywordSales: 971
        },
        {
          date: '2024-06-24',
          searchVolume: 14103,
          organicRank: 118,

          keywordSales: 971
        },
        {
          date: '2024-06-22',
          searchVolume: 14103,
          organicRank: 129,

          keywordSales: 971
        },
        {
          date: '2024-06-21',
          searchVolume: 15693,
          organicRank: 113,

          keywordSales: 971
        },
        {
          date: '2024-06-20',
          searchVolume: 15693,
          organicRank: 123,

          keywordSales: 971
        },
        {
          date: '2024-06-19',
          searchVolume: 15693,
          organicRank: 73,

          keywordSales: 971
        },
        {
          date: '2024-06-18',
          searchVolume: 15693,
          organicRank: 70,

          keywordSales: 971
        },
        {
          date: '2024-06-17',
          searchVolume: 15693,
          organicRank: 123,

          keywordSales: 971
        },
        {
          date: '2024-06-16',
          searchVolume: 15693,
          organicRank: 67,

          keywordSales: 971
        },
        {
          date: '2024-06-15',
          searchVolume: 15693,
          organicRank: 74,

          keywordSales: 971
        },
        {
          date: '2024-06-14',
          searchVolume: 15602,
          organicRank: 306,

          keywordSales: 971
        },
        {
          date: '2024-06-13',
          searchVolume: 15602,
          organicRank: 306,

          keywordSales: 971
        },
        {
          date: '2024-06-12',
          searchVolume: 15602,
          organicRank: 306,

          keywordSales: 971
        },
        {
          date: '2024-06-11',
          searchVolume: 15602,
          organicRank: 306,

          keywordSales: 971
        },
        {
          date: '2024-06-10',
          searchVolume: 15602,
          organicRank: 68,

          keywordSales: 971
        },
        {
          date: '2024-06-09',
          searchVolume: 15602,
          organicRank: 71,

          keywordSales: 971
        },
        {
          date: '2024-06-08',
          searchVolume: 15602,
          organicRank: 74,

          keywordSales: 971
        },
        {
          date: '2024-06-07',
          searchVolume: 13518,
          organicRank: 67,

          keywordSales: 971
        },
        {
          date: '2024-06-06',
          searchVolume: 13518,
          organicRank: 64,

          keywordSales: 971
        },
        {
          date: '2024-06-05',
          searchVolume: 13518,
          organicRank: 271,

          keywordSales: 971
        },
        {
          date: '2024-06-04',
          searchVolume: 13518,
          organicRank: 253,

          keywordSales: 971
        },
        {
          date: '2024-06-03',
          searchVolume: 13518,
          organicRank: 63,

          keywordSales: 971
        },
        {
          date: '2024-06-02',
          searchVolume: 13518,
          organicRank: 62,

          keywordSales: 971
        },
        {
          date: '2024-06-01',
          searchVolume: 13518,
          organicRank: 50,

          keywordSales: 971
        },
        {
          date: '2024-05-31',
          searchVolume: 16370,
          organicRank: 75,

          keywordSales: 971
        },
        {
          date: '2024-05-30',
          searchVolume: 16370,
          organicRank: 65,

          keywordSales: 971
        },
        {
          date: '2024-05-29',
          searchVolume: 16370,
          organicRank: 70,

          keywordSales: 971
        },
        {
          date: '2024-05-28',
          searchVolume: 16370,
          organicRank: 59,

          keywordSales: 971
        },
        {
          date: '2024-05-27',
          searchVolume: 16370,
          organicRank: 52,

          keywordSales: 971
        },
        {
          date: '2024-05-26',
          searchVolume: 16370,
          organicRank: 72,

          keywordSales: 971
        },
        {
          date: '2024-05-25',
          searchVolume: 16370,
          organicRank: 46,

          keywordSales: 971
        },
        {
          date: '2024-05-24',
          searchVolume: 15468,
          organicRank: 59,

          keywordSales: 971
        },
        {
          date: '2024-05-23',
          searchVolume: 15468,
          organicRank: 60,

          keywordSales: 971
        },
        {
          date: '2024-05-22',
          searchVolume: 15468,
          organicRank: 62,

          keywordSales: 971
        },
        {
          date: '2024-05-21',
          searchVolume: 15468,
          organicRank: 54,

          keywordSales: 971
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_hidden cameras for home security`,
      keyword: 'hidden cameras for home security',
      searchVolume: 1614,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 81,
      sponsoredHighestRank: 66,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 1922,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-07-03',
          searchVolume: 1922,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-07-02',
          searchVolume: 1922,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-07-01',
          searchVolume: 1922,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-30',
          searchVolume: 1922,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-29',
          searchVolume: 1922,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-28',
          searchVolume: 1602,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-27',
          searchVolume: 1602,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-26',
          searchVolume: 1602,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-25',
          searchVolume: 1602,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-23',
          searchVolume: 1602,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-21',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-20',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-19',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-18',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-17',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-16',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-15',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-14',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-13',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-12',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-11',
          searchVolume: 1661,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 59
        },
        {
          date: '2024-06-10',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-09',
          searchVolume: 1661,
          organicRank: 306,
          sponsoredRank: 81,
          keywordSales: 59
        },
        {
          date: '2024-06-08',
          searchVolume: 1661,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-07',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-06',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-05',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-04',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-03',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-02',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-01',
          searchVolume: 1614,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-31',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-30',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-29',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-28',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-27',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-26',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-25',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-05-24',
          searchVolume: 1822,
          organicRank: 306,

          keywordSales: 59
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home camera`,
      keyword: 'home camera',
      searchVolume: 6438,
      organicStartRank: 306,
      organicHighestRank: 43,
      sponsoredStartRank: 28,
      sponsoredHighestRank: 15,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 7719,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-02',
          searchVolume: 7719,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-01',
          searchVolume: 7719,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-30',
          searchVolume: 7719,
          organicRank: 52,

          keywordSales: 174
        },
        {
          date: '2024-06-29',
          searchVolume: 7719,
          organicRank: 63,

          keywordSales: 174
        },
        {
          date: '2024-06-28',
          searchVolume: 8454,
          organicRank: 59,

          keywordSales: 174
        },
        {
          date: '2024-06-27',
          searchVolume: 8454,
          organicRank: 56,

          keywordSales: 174
        },
        {
          date: '2024-06-26',
          searchVolume: 8454,
          organicRank: 54,

          keywordSales: 174
        },
        {
          date: '2024-06-25',
          searchVolume: 8454,
          organicRank: 43,

          keywordSales: 174
        },
        {
          date: '2024-06-24',
          searchVolume: 8454,
          organicRank: 47,

          keywordSales: 174
        },
        {
          date: '2024-06-21',
          searchVolume: 7288,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-20',
          searchVolume: 7288,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-19',
          searchVolume: 7288,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-18',
          searchVolume: 7288,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 174
        },
        {
          date: '2024-06-17',
          searchVolume: 7288,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-16',
          searchVolume: 7288,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-15',
          searchVolume: 7288,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-14',
          searchVolume: 8482,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 174
        },
        {
          date: '2024-06-13',
          searchVolume: 8482,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 174
        },
        {
          date: '2024-06-12',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-11',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-10',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-09',
          searchVolume: 8482,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 174
        },
        {
          date: '2024-06-08',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-07',
          searchVolume: 6438,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-06',
          searchVolume: 6438,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-05',
          searchVolume: 6438,
          organicRank: 306,
          sponsoredRank: 61,
          keywordSales: 174
        },
        {
          date: '2024-06-04',
          searchVolume: 6438,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-03',
          searchVolume: 6438,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-02',
          searchVolume: 6438,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-01',
          searchVolume: 6438,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-05-31',
          searchVolume: 7552,
          organicRank: 306,
          sponsoredRank: 28,
          keywordSales: 174
        },
        {
          date: '2024-05-30',
          searchVolume: 7552,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-05-29',
          searchVolume: 7552,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-05-28',
          searchVolume: 7552,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-05-27',
          searchVolume: 7552,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-05-26',
          searchVolume: 7552,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-05-25',
          searchVolume: 7552,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-05-24',
          searchVolume: 7178,
          organicRank: 306,

          keywordSales: 174
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home camera outdoor wireless`,
      keyword: 'home camera outdoor wireless',
      searchVolume: 5868,
      organicStartRank: 79,
      organicHighestRank: 49,
      sponsoredStartRank: 89,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 5985,
          organicRank: 118,

          keywordSales: 20
        },
        {
          date: '2024-07-02',
          searchVolume: 5985,
          organicRank: 99,
          sponsoredRank: 76,
          keywordSales: 20
        },
        {
          date: '2024-07-01',
          searchVolume: 5985,
          organicRank: 79,

          keywordSales: 20
        },
        {
          date: '2024-06-30',
          searchVolume: 5985,
          organicRank: 70,

          keywordSales: 20
        },
        {
          date: '2024-06-29',
          searchVolume: 5985,
          organicRank: 77,

          keywordSales: 20
        },
        {
          date: '2024-06-28',
          searchVolume: 6723,
          organicRank: 78,
          sponsoredRank: 42,
          keywordSales: 20
        },
        {
          date: '2024-06-27',
          searchVolume: 6723,
          organicRank: 82,
          sponsoredRank: 70,
          keywordSales: 20
        },
        {
          date: '2024-06-26',
          searchVolume: 6723,
          organicRank: 81,

          keywordSales: 20
        },
        {
          date: '2024-06-25',
          searchVolume: 6723,
          organicRank: 77,
          sponsoredRank: 12,
          keywordSales: 20
        },
        {
          date: '2024-06-24',
          searchVolume: 6723,
          organicRank: 64,

          keywordSales: 20
        },
        {
          date: '2024-06-23',
          searchVolume: 6723,
          organicRank: 86,
          sponsoredRank: 16,
          keywordSales: 20
        },
        {
          date: '2024-06-22',
          searchVolume: 6723,
          organicRank: 76,
          sponsoredRank: 9,
          keywordSales: 20
        },
        {
          date: '2024-06-21',
          searchVolume: 5148,
          organicRank: 70,
          sponsoredRank: 21,
          keywordSales: 20
        },
        {
          date: '2024-06-20',
          searchVolume: 5148,
          organicRank: 49,
          sponsoredRank: 46,
          keywordSales: 20
        },
        {
          date: '2024-06-19',
          searchVolume: 5148,
          organicRank: 66,
          sponsoredRank: 26,
          keywordSales: 20
        },
        {
          date: '2024-06-18',
          searchVolume: 5148,
          organicRank: 77,
          sponsoredRank: 71,
          keywordSales: 20
        },
        {
          date: '2024-06-17',
          searchVolume: 5148,
          organicRank: 69,
          sponsoredRank: 47,
          keywordSales: 20
        },
        {
          date: '2024-06-16',
          searchVolume: 5148,
          organicRank: 68,

          keywordSales: 20
        },
        {
          date: '2024-06-15',
          searchVolume: 5148,
          organicRank: 67,
          sponsoredRank: 75,
          keywordSales: 20
        },
        {
          date: '2024-06-14',
          searchVolume: 5148,
          organicRank: 70,
          sponsoredRank: 9,
          keywordSales: 20
        },
        {
          date: '2024-06-13',
          searchVolume: 5148,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 20
        },
        {
          date: '2024-06-12',
          searchVolume: 5148,
          organicRank: 82,

          keywordSales: 20
        },
        {
          date: '2024-06-11',
          searchVolume: 5148,
          organicRank: 306,

          keywordSales: 20
        },
        {
          date: '2024-06-10',
          searchVolume: 5148,
          organicRank: 97,

          keywordSales: 20
        },
        {
          date: '2024-06-09',
          searchVolume: 5148,
          organicRank: 91,
          sponsoredRank: 99,
          keywordSales: 20
        },
        {
          date: '2024-06-08',
          searchVolume: 5148,
          organicRank: 82,

          keywordSales: 20
        },
        {
          date: '2024-06-07',
          searchVolume: 5166,
          organicRank: 101,

          keywordSales: 20
        },
        {
          date: '2024-06-06',
          searchVolume: 5166,
          organicRank: 95,
          sponsoredRank: 10,
          keywordSales: 20
        },
        {
          date: '2024-06-05',
          searchVolume: 5166,
          organicRank: 258,
          sponsoredRank: 1,
          keywordSales: 20
        },
        {
          date: '2024-06-04',
          searchVolume: 5166,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 20
        },
        {
          date: '2024-06-03',
          searchVolume: 5166,
          organicRank: 64,

          keywordSales: 20
        },
        {
          date: '2024-06-02',
          searchVolume: 5166,
          organicRank: 90,
          sponsoredRank: 78,
          keywordSales: 20
        },
        {
          date: '2024-06-01',
          searchVolume: 5166,
          organicRank: 83,
          sponsoredRank: 75,
          keywordSales: 20
        },
        {
          date: '2024-05-31',
          searchVolume: 6049,
          organicRank: 73,
          sponsoredRank: 51,
          keywordSales: 20
        },
        {
          date: '2024-05-30',
          searchVolume: 6049,
          organicRank: 74,
          sponsoredRank: 1,
          keywordSales: 20
        },
        {
          date: '2024-05-29',
          searchVolume: 6049,
          organicRank: 67,
          sponsoredRank: 53,
          keywordSales: 20
        },
        {
          date: '2024-05-28',
          searchVolume: 6049,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 20
        },
        {
          date: '2024-05-27',
          searchVolume: 6049,
          organicRank: 96,
          sponsoredRank: 15,
          keywordSales: 20
        },
        {
          date: '2024-05-26',
          searchVolume: 6049,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 20
        },
        {
          date: '2024-05-25',
          searchVolume: 6049,
          organicRank: 78,
          sponsoredRank: 34,
          keywordSales: 20
        },
        {
          date: '2024-05-24',
          searchVolume: 5868,
          organicRank: 260,

          keywordSales: 20
        },
        {
          date: '2024-05-23',
          searchVolume: 5868,
          organicRank: 73,
          sponsoredRank: 35,
          keywordSales: 20
        },
        {
          date: '2024-05-22',
          searchVolume: 5868,
          organicRank: 79,
          sponsoredRank: 89,
          keywordSales: 20
        },
        {
          date: '2024-05-21',
          searchVolume: 5868,
          organicRank: 67,
          sponsoredRank: 55,
          keywordSales: 20
        },
        {
          date: '2024-05-20',
          searchVolume: 5868,
          organicRank: 73,
          sponsoredRank: 65,
          keywordSales: 20
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home camera system`,
      keyword: 'home camera system',
      searchVolume: 1899,
      organicStartRank: 135,
      organicHighestRank: 49,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2394,
          organicRank: 124,

          keywordSales: 11
        },
        {
          date: '2024-07-03',
          searchVolume: 2394,
          organicRank: 122,

          keywordSales: 11
        },
        {
          date: '2024-07-02',
          searchVolume: 2394,
          organicRank: 114,

          keywordSales: 11
        },
        {
          date: '2024-07-01',
          searchVolume: 2394,
          organicRank: 71,

          keywordSales: 11
        },
        {
          date: '2024-06-30',
          searchVolume: 2394,
          organicRank: 67,

          keywordSales: 11
        },
        {
          date: '2024-06-29',
          searchVolume: 2394,
          organicRank: 50,

          keywordSales: 11
        },
        {
          date: '2024-06-28',
          searchVolume: 2244,
          organicRank: 65,

          keywordSales: 11
        },
        {
          date: '2024-06-27',
          searchVolume: 2244,
          organicRank: 49,

          keywordSales: 11
        },
        {
          date: '2024-06-26',
          searchVolume: 2244,
          organicRank: 70,

          keywordSales: 11
        },
        {
          date: '2024-06-25',
          searchVolume: 2244,
          organicRank: 92,

          keywordSales: 11
        },
        {
          date: '2024-06-23',
          searchVolume: 2244,
          organicRank: 116,

          keywordSales: 11
        },
        {
          date: '2024-06-21',
          searchVolume: 2036,
          organicRank: 59,

          keywordSales: 11
        },
        {
          date: '2024-06-20',
          searchVolume: 2036,
          organicRank: 69,

          keywordSales: 11
        },
        {
          date: '2024-06-19',
          searchVolume: 2036,
          organicRank: 141,

          keywordSales: 11
        },
        {
          date: '2024-06-18',
          searchVolume: 2036,
          organicRank: 141,

          keywordSales: 11
        },
        {
          date: '2024-06-17',
          searchVolume: 2036,
          organicRank: 118,

          keywordSales: 11
        },
        {
          date: '2024-06-16',
          searchVolume: 2036,
          organicRank: 89,

          keywordSales: 11
        },
        {
          date: '2024-06-15',
          searchVolume: 2036,
          organicRank: 103,

          keywordSales: 11
        },
        {
          date: '2024-06-14',
          searchVolume: 1928,
          organicRank: 133,

          keywordSales: 11
        },
        {
          date: '2024-06-13',
          searchVolume: 1928,
          organicRank: 125,

          keywordSales: 11
        },
        {
          date: '2024-06-12',
          searchVolume: 1928,
          organicRank: 243,

          keywordSales: 11
        },
        {
          date: '2024-06-11',
          searchVolume: 1928,
          organicRank: 165,

          keywordSales: 11
        },
        {
          date: '2024-06-10',
          searchVolume: 1928,
          organicRank: 189,

          keywordSales: 11
        },
        {
          date: '2024-06-09',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-08',
          searchVolume: 1928,
          organicRank: 154,

          keywordSales: 11
        },
        {
          date: '2024-06-07',
          searchVolume: 1836,
          organicRank: 166,

          keywordSales: 11
        },
        {
          date: '2024-06-06',
          searchVolume: 1836,
          organicRank: 133,

          keywordSales: 11
        },
        {
          date: '2024-06-05',
          searchVolume: 1836,
          organicRank: 145,

          keywordSales: 11
        },
        {
          date: '2024-06-04',
          searchVolume: 1836,
          organicRank: 144,

          keywordSales: 11
        },
        {
          date: '2024-06-03',
          searchVolume: 1836,
          organicRank: 95,

          keywordSales: 11
        },
        {
          date: '2024-06-02',
          searchVolume: 1836,
          organicRank: 105,

          keywordSales: 11
        },
        {
          date: '2024-06-01',
          searchVolume: 1836,
          organicRank: 100,

          keywordSales: 11
        },
        {
          date: '2024-05-31',
          searchVolume: 1895,
          organicRank: 110,
          sponsoredRank: 43,
          keywordSales: 11
        },
        {
          date: '2024-05-30',
          searchVolume: 1895,
          organicRank: 131,

          keywordSales: 11
        },
        {
          date: '2024-05-29',
          searchVolume: 1895,
          organicRank: 129,

          keywordSales: 11
        },
        {
          date: '2024-05-28',
          searchVolume: 1895,
          organicRank: 124,

          keywordSales: 11
        },
        {
          date: '2024-05-27',
          searchVolume: 1895,
          organicRank: 143,

          keywordSales: 11
        },
        {
          date: '2024-05-26',
          searchVolume: 1895,
          organicRank: 139,

          keywordSales: 11
        },
        {
          date: '2024-05-25',
          searchVolume: 1895,
          organicRank: 67,

          keywordSales: 11
        },
        {
          date: '2024-05-24',
          searchVolume: 1899,
          organicRank: 146,

          keywordSales: 11
        },
        {
          date: '2024-05-23',
          searchVolume: 1899,
          organicRank: 101,

          keywordSales: 11
        },
        {
          date: '2024-05-22',
          searchVolume: 1899,
          organicRank: 135,
          sponsoredRank: 9,
          keywordSales: 11
        },
        {
          date: '2024-05-21',
          searchVolume: 1899,
          organicRank: 147,

          keywordSales: 11
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home cameras`,
      keyword: 'home cameras',
      searchVolume: 2431,
      organicStartRank: 306,
      organicHighestRank: 154,
      sponsoredStartRank: 94,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-07-03',
          searchVolume: 2550,
          organicRank: 271,

          keywordSales: 23
        },
        {
          date: '2024-07-02',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-07-01',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-30',
          searchVolume: 2550,
          organicRank: 154,

          keywordSales: 23
        },
        {
          date: '2024-06-29',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-28',
          searchVolume: 2544,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-27',
          searchVolume: 2544,
          organicRank: 165,

          keywordSales: 23
        },
        {
          date: '2024-06-26',
          searchVolume: 2544,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-25',
          searchVolume: 2544,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-24',
          searchVolume: 2544,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-17',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-16',
          searchVolume: 2550,
          organicRank: 284,

          keywordSales: 23
        },
        {
          date: '2024-06-15',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-14',
          searchVolume: 2550,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 23
        },
        {
          date: '2024-06-13',
          searchVolume: 2550,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 23
        },
        {
          date: '2024-06-12',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-11',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-10',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-09',
          searchVolume: 2550,
          organicRank: 306,
          sponsoredRank: 94,
          keywordSales: 23
        },
        {
          date: '2024-06-08',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 265,

          keywordSales: 23
        },
        {
          date: '2024-05-31',
          searchVolume: 2511,
          organicRank: 274,

          keywordSales: 23
        },
        {
          date: '2024-05-30',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-05-29',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-05-28',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-05-27',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-05-26',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-05-25',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-05-24',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 23
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home cameras indoor`,
      keyword: 'home cameras indoor',
      searchVolume: 8858,
      organicStartRank: 306,
      organicHighestRank: 89,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 12209,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-07-03',
          searchVolume: 12209,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-07-02',
          searchVolume: 12209,
          organicRank: 193,

          keywordSales: 258
        },
        {
          date: '2024-07-01',
          searchVolume: 12209,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-30',
          searchVolume: 12209,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-29',
          searchVolume: 12209,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-28',
          searchVolume: 11054,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-27',
          searchVolume: 11054,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-26',
          searchVolume: 11054,
          organicRank: 260,

          keywordSales: 258
        },
        {
          date: '2024-06-25',
          searchVolume: 11054,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-23',
          searchVolume: 11054,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-21',
          searchVolume: 10731,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-20',
          searchVolume: 10731,
          organicRank: 119,

          keywordSales: 258
        },
        {
          date: '2024-06-19',
          searchVolume: 10731,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-18',
          searchVolume: 10731,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-17',
          searchVolume: 10731,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-16',
          searchVolume: 10731,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-15',
          searchVolume: 10731,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-14',
          searchVolume: 10789,
          organicRank: 196,

          keywordSales: 258
        },
        {
          date: '2024-06-13',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-12',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-11',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-10',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-09',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-08',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-06-07',
          searchVolume: 8858,
          organicRank: 104,

          keywordSales: 258
        },
        {
          date: '2024-06-06',
          searchVolume: 8858,
          organicRank: 121,

          keywordSales: 258
        },
        {
          date: '2024-06-05',
          searchVolume: 8858,
          organicRank: 99,

          keywordSales: 258
        },
        {
          date: '2024-06-04',
          searchVolume: 8858,
          organicRank: 89,

          keywordSales: 258
        },
        {
          date: '2024-06-03',
          searchVolume: 8858,
          organicRank: 95,

          keywordSales: 258
        },
        {
          date: '2024-06-02',
          searchVolume: 8858,
          organicRank: 115,

          keywordSales: 258
        },
        {
          date: '2024-06-01',
          searchVolume: 8858,
          organicRank: 125,

          keywordSales: 258
        },
        {
          date: '2024-05-31',
          searchVolume: 10875,
          organicRank: 103,

          keywordSales: 258
        },
        {
          date: '2024-05-30',
          searchVolume: 10875,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-05-29',
          searchVolume: 10875,
          organicRank: 175,

          keywordSales: 258
        },
        {
          date: '2024-05-28',
          searchVolume: 10875,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-05-27',
          searchVolume: 10875,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-05-26',
          searchVolume: 10875,
          organicRank: 177,

          keywordSales: 258
        },
        {
          date: '2024-05-25',
          searchVolume: 10875,
          organicRank: 306,

          keywordSales: 258
        },
        {
          date: '2024-05-24',
          searchVolume: 11564,
          organicRank: 306,

          keywordSales: 258
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home cameras indoor wireless`,
      keyword: 'home cameras indoor wireless',
      searchVolume: 2071,
      organicStartRank: 306,
      organicHighestRank: 134,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 1935,
          organicRank: 157,

          keywordSales: 9
        },
        {
          date: '2024-07-03',
          searchVolume: 1935,
          organicRank: 162,

          keywordSales: 9
        },
        {
          date: '2024-07-02',
          searchVolume: 1935,
          organicRank: 162,

          keywordSales: 9
        },
        {
          date: '2024-07-01',
          searchVolume: 1935,
          organicRank: 134,

          keywordSales: 9
        },
        {
          date: '2024-06-30',
          searchVolume: 1935,
          organicRank: 140,

          keywordSales: 9
        },
        {
          date: '2024-06-29',
          searchVolume: 1935,
          organicRank: 162,

          keywordSales: 9
        },
        {
          date: '2024-06-28',
          searchVolume: 2346,
          organicRank: 161,

          keywordSales: 9
        },
        {
          date: '2024-06-27',
          searchVolume: 2346,
          organicRank: 166,

          keywordSales: 9
        },
        {
          date: '2024-06-26',
          searchVolume: 2346,
          organicRank: 175,

          keywordSales: 9
        },
        {
          date: '2024-06-25',
          searchVolume: 2346,
          organicRank: 142,

          keywordSales: 9
        },
        {
          date: '2024-06-23',
          searchVolume: 2346,
          organicRank: 171,

          keywordSales: 9
        },
        {
          date: '2024-06-21',
          searchVolume: 2173,
          organicRank: 156,

          keywordSales: 9
        },
        {
          date: '2024-06-20',
          searchVolume: 2173,
          organicRank: 256,

          keywordSales: 9
        },
        {
          date: '2024-06-19',
          searchVolume: 2173,
          organicRank: 139,

          keywordSales: 9
        },
        {
          date: '2024-06-18',
          searchVolume: 2173,
          organicRank: 149,

          keywordSales: 9
        },
        {
          date: '2024-06-17',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-06-16',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-06-15',
          searchVolume: 2173,
          organicRank: 179,

          keywordSales: 9
        },
        {
          date: '2024-06-14',
          searchVolume: 2186,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 9
        },
        {
          date: '2024-06-13',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-06-12',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-06-11',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-06-10',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-06-09',
          searchVolume: 2186,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 9
        },
        {
          date: '2024-06-08',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-06-07',
          searchVolume: 2071,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-06-06',
          searchVolume: 2071,
          organicRank: 276,

          keywordSales: 9
        },
        {
          date: '2024-06-05',
          searchVolume: 2071,
          organicRank: 266,
          sponsoredRank: 9,
          keywordSales: 9
        },
        {
          date: '2024-06-04',
          searchVolume: 2071,
          organicRank: 151,

          keywordSales: 9
        },
        {
          date: '2024-06-03',
          searchVolume: 2071,
          organicRank: 153,

          keywordSales: 9
        },
        {
          date: '2024-06-02',
          searchVolume: 2071,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-06-01',
          searchVolume: 2071,
          organicRank: 163,

          keywordSales: 9
        },
        {
          date: '2024-05-31',
          searchVolume: 2509,
          organicRank: 140,

          keywordSales: 9
        },
        {
          date: '2024-05-30',
          searchVolume: 2509,
          organicRank: 154,

          keywordSales: 9
        },
        {
          date: '2024-05-29',
          searchVolume: 2509,
          organicRank: 142,

          keywordSales: 9
        },
        {
          date: '2024-05-28',
          searchVolume: 2509,
          organicRank: 149,

          keywordSales: 9
        },
        {
          date: '2024-05-27',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 9
        },
        {
          date: '2024-05-26',
          searchVolume: 2509,
          organicRank: 143,

          keywordSales: 9
        },
        {
          date: '2024-05-25',
          searchVolume: 2509,
          organicRank: 142,

          keywordSales: 9
        },
        {
          date: '2024-05-24',
          searchVolume: 3089,
          organicRank: 306,

          keywordSales: 9
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home security camera`,
      keyword: 'home security camera',
      searchVolume: 3078,
      organicStartRank: 139,
      organicHighestRank: 29,
      sponsoredStartRank: 54,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-29',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2552,
          organicRank: 82,
          sponsoredRank: 13,
          keywordSales: 7
        },
        {
          date: '2024-07-03',
          searchVolume: 2552,
          organicRank: 80,

          keywordSales: 7
        },
        {
          date: '2024-07-02',
          searchVolume: 2552,
          organicRank: 73,

          keywordSales: 7
        },
        {
          date: '2024-07-01',
          searchVolume: 2552,
          organicRank: 61,

          keywordSales: 7
        },
        {
          date: '2024-06-30',
          searchVolume: 2552,
          organicRank: 44,

          keywordSales: 7
        },
        {
          date: '2024-06-29',
          searchVolume: 2552,
          organicRank: 36,
          sponsoredRank: 72,
          keywordSales: 7
        },
        {
          date: '2024-06-28',
          searchVolume: 2543,
          organicRank: 32,
          sponsoredRank: 53,
          keywordSales: 7
        },
        {
          date: '2024-06-27',
          searchVolume: 2543,
          organicRank: 29,

          keywordSales: 7
        },
        {
          date: '2024-06-25',
          searchVolume: 2543,
          organicRank: 69,

          keywordSales: 7
        },
        {
          date: '2024-06-24',
          searchVolume: 2543,
          organicRank: 71,

          keywordSales: 7
        },
        {
          date: '2024-06-23',
          searchVolume: 2543,
          organicRank: 75,

          keywordSales: 7
        },
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 68,

          keywordSales: 7
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 74,

          keywordSales: 7
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 97,

          keywordSales: 7
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 68,

          keywordSales: 7
        },
        {
          date: '2024-06-17',
          searchVolume: 2550,
          organicRank: 107,

          keywordSales: 7
        },
        {
          date: '2024-06-16',
          searchVolume: 2550,
          organicRank: 80,

          keywordSales: 7
        },
        {
          date: '2024-06-15',
          searchVolume: 2550,
          organicRank: 109,

          keywordSales: 7
        },
        {
          date: '2024-06-14',
          searchVolume: 3141,
          organicRank: 306,
          sponsoredRank: 99,
          keywordSales: 7
        },
        {
          date: '2024-06-13',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-12',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-11',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-10',
          searchVolume: 3141,
          organicRank: 254,

          keywordSales: 7
        },
        {
          date: '2024-06-09',
          searchVolume: 3141,
          organicRank: 306,

          keywordSales: 7
        },
        {
          date: '2024-06-08',
          searchVolume: 3141,
          organicRank: 93,
          sponsoredRank: 44,
          keywordSales: 7
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 70,

          keywordSales: 7
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 97,
          sponsoredRank: 62,
          keywordSales: 7
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 7
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 7
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 75,

          keywordSales: 7
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 78,

          keywordSales: 7
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 89,
          sponsoredRank: 56,
          keywordSales: 7
        },
        {
          date: '2024-05-31',
          searchVolume: 4256,
          organicRank: 69,
          sponsoredRank: 56,
          keywordSales: 7
        },
        {
          date: '2024-05-30',
          searchVolume: 4256,
          organicRank: 84,
          sponsoredRank: 95,
          keywordSales: 7
        },
        {
          date: '2024-05-29',
          searchVolume: 4256,
          organicRank: 63,
          sponsoredRank: 2,
          keywordSales: 7
        },
        {
          date: '2024-05-28',
          searchVolume: 4256,
          organicRank: 85,
          sponsoredRank: 40,
          keywordSales: 7
        },
        {
          date: '2024-05-27',
          searchVolume: 4256,
          organicRank: 75,

          keywordSales: 7
        },
        {
          date: '2024-05-26',
          searchVolume: 4256,
          organicRank: 76,
          sponsoredRank: 24,
          keywordSales: 7
        },
        {
          date: '2024-05-25',
          searchVolume: 4256,
          organicRank: 59,
          sponsoredRank: 54,
          keywordSales: 7
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 99,

          keywordSales: 7
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 120,

          keywordSales: 7
        },
        {
          date: '2024-05-22',
          searchVolume: 3078,
          organicRank: 139,

          keywordSales: 7
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 150,

          keywordSales: 7
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home security camera system`,
      keyword: 'home security camera system',
      searchVolume: 1884,
      organicStartRank: 105,
      organicHighestRank: 58,
      sponsoredStartRank: 15,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 954,
          organicRank: 263,

          keywordSales: 21
        },
        {
          date: '2024-07-03',
          searchVolume: 954,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-07-02',
          searchVolume: 954,
          organicRank: 173,

          keywordSales: 21
        },
        {
          date: '2024-07-01',
          searchVolume: 954,
          organicRank: 115,

          keywordSales: 21
        },
        {
          date: '2024-06-30',
          searchVolume: 954,
          organicRank: 70,

          keywordSales: 21
        },
        {
          date: '2024-06-29',
          searchVolume: 954,
          organicRank: 82,
          sponsoredRank: 74,
          keywordSales: 21
        },
        {
          date: '2024-06-27',
          searchVolume: 1345,
          organicRank: 115,

          keywordSales: 21
        },
        {
          date: '2024-06-26',
          searchVolume: 1345,
          organicRank: 131,

          keywordSales: 21
        },
        {
          date: '2024-06-25',
          searchVolume: 1345,
          organicRank: 100,

          keywordSales: 21
        },
        {
          date: '2024-06-24',
          searchVolume: 1345,
          organicRank: 82,

          keywordSales: 21
        },
        {
          date: '2024-06-22',
          searchVolume: 1345,
          organicRank: 112,

          keywordSales: 21
        },
        {
          date: '2024-06-21',
          searchVolume: 1430,
          organicRank: 58,

          keywordSales: 21
        },
        {
          date: '2024-06-20',
          searchVolume: 1430,
          organicRank: 74,

          keywordSales: 21
        },
        {
          date: '2024-06-19',
          searchVolume: 1430,
          organicRank: 128,

          keywordSales: 21
        },
        {
          date: '2024-06-18',
          searchVolume: 1430,
          organicRank: 121,

          keywordSales: 21
        },
        {
          date: '2024-06-17',
          searchVolume: 1430,
          organicRank: 115,

          keywordSales: 21
        },
        {
          date: '2024-06-16',
          searchVolume: 1430,
          organicRank: 104,

          keywordSales: 21
        },
        {
          date: '2024-06-15',
          searchVolume: 1430,
          organicRank: 142,

          keywordSales: 21
        },
        {
          date: '2024-06-14',
          searchVolume: 1432,
          organicRank: 115,
          sponsoredRank: 41,
          keywordSales: 21
        },
        {
          date: '2024-06-13',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-06-12',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-06-11',
          searchVolume: 1432,
          organicRank: 150,

          keywordSales: 21
        },
        {
          date: '2024-06-10',
          searchVolume: 1432,
          organicRank: 237,

          keywordSales: 21
        },
        {
          date: '2024-06-09',
          searchVolume: 1432,
          organicRank: 141,
          sponsoredRank: 33,
          keywordSales: 21
        },
        {
          date: '2024-06-08',
          searchVolume: 1432,
          organicRank: 186,

          keywordSales: 21
        },
        {
          date: '2024-06-07',
          searchVolume: 1366,
          organicRank: 162,

          keywordSales: 21
        },
        {
          date: '2024-06-06',
          searchVolume: 1366,
          organicRank: 176,

          keywordSales: 21
        },
        {
          date: '2024-06-05',
          searchVolume: 1366,
          organicRank: 151,

          keywordSales: 21
        },
        {
          date: '2024-06-04',
          searchVolume: 1366,
          organicRank: 236,

          keywordSales: 21
        },
        {
          date: '2024-06-03',
          searchVolume: 1366,
          organicRank: 112,

          keywordSales: 21
        },
        {
          date: '2024-06-02',
          searchVolume: 1366,
          organicRank: 88,

          keywordSales: 21
        },
        {
          date: '2024-06-01',
          searchVolume: 1366,
          organicRank: 68,

          keywordSales: 21
        },
        {
          date: '2024-05-31',
          searchVolume: 1637,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-05-30',
          searchVolume: 1637,
          organicRank: 100,

          keywordSales: 21
        },
        {
          date: '2024-05-29',
          searchVolume: 1637,
          organicRank: 96,

          keywordSales: 21
        },
        {
          date: '2024-05-28',
          searchVolume: 1637,
          organicRank: 119,

          keywordSales: 21
        },
        {
          date: '2024-05-27',
          searchVolume: 1637,
          organicRank: 97,
          sponsoredRank: 65,
          keywordSales: 21
        },
        {
          date: '2024-05-26',
          searchVolume: 1637,
          organicRank: 120,
          sponsoredRank: 11,
          keywordSales: 21
        },
        {
          date: '2024-05-25',
          searchVolume: 1637,
          organicRank: 107,
          sponsoredRank: 55,
          keywordSales: 21
        },
        {
          date: '2024-05-24',
          searchVolume: 1884,
          organicRank: 99,

          keywordSales: 21
        },
        {
          date: '2024-05-23',
          searchVolume: 1884,
          organicRank: 104,

          keywordSales: 21
        },
        {
          date: '2024-05-22',
          searchVolume: 1884,
          organicRank: 105,

          keywordSales: 21
        },
        {
          date: '2024-05-21',
          searchVolume: 1884,
          organicRank: 153,
          sponsoredRank: 15,
          keywordSales: 21
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home security cameras`,
      keyword: 'home security cameras',
      searchVolume: 37281,
      organicStartRank: 125,
      organicHighestRank: 64,
      sponsoredStartRank: 41,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 42809,
          organicRank: 98,
          sponsoredRank: 32,
          keywordSales: 889
        },
        {
          date: '2024-07-02',
          searchVolume: 42809,
          organicRank: 64,
          sponsoredRank: 1,
          keywordSales: 889
        },
        {
          date: '2024-07-01',
          searchVolume: 42809,
          organicRank: 94,

          keywordSales: 889
        },
        {
          date: '2024-06-30',
          searchVolume: 42809,
          organicRank: 67,

          keywordSales: 889
        },
        {
          date: '2024-06-29',
          searchVolume: 42809,
          organicRank: 76,
          sponsoredRank: 2,
          keywordSales: 889
        },
        {
          date: '2024-06-28',
          searchVolume: 37753,
          organicRank: 96,
          sponsoredRank: 43,
          keywordSales: 889
        },
        {
          date: '2024-06-27',
          searchVolume: 37753,
          organicRank: 91,
          sponsoredRank: 4,
          keywordSales: 889
        },
        {
          date: '2024-06-26',
          searchVolume: 37753,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 889
        },
        {
          date: '2024-06-25',
          searchVolume: 37753,
          organicRank: 235,
          sponsoredRank: 69,
          keywordSales: 889
        },
        {
          date: '2024-06-23',
          searchVolume: 37753,
          organicRank: 306,

          keywordSales: 889
        },
        {
          date: '2024-06-22',
          searchVolume: 37753,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 889
        },
        {
          date: '2024-06-21',
          searchVolume: 44719,
          organicRank: 306,
          sponsoredRank: 58,
          keywordSales: 889
        },
        {
          date: '2024-06-20',
          searchVolume: 44719,
          organicRank: 114,
          sponsoredRank: 58,
          keywordSales: 889
        },
        {
          date: '2024-06-19',
          searchVolume: 44719,
          organicRank: 306,
          sponsoredRank: 37,
          keywordSales: 889
        },
        {
          date: '2024-06-18',
          searchVolume: 44719,
          organicRank: 306,
          sponsoredRank: 58,
          keywordSales: 889
        },
        {
          date: '2024-06-17',
          searchVolume: 44719,
          organicRank: 306,

          keywordSales: 889
        },
        {
          date: '2024-06-16',
          searchVolume: 44719,
          organicRank: 132,

          keywordSales: 889
        },
        {
          date: '2024-06-15',
          searchVolume: 44719,
          organicRank: 135,
          sponsoredRank: 48,
          keywordSales: 889
        },
        {
          date: '2024-06-14',
          searchVolume: 42802,
          organicRank: 121,
          sponsoredRank: 16,
          keywordSales: 889
        },
        {
          date: '2024-06-13',
          searchVolume: 42802,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 889
        },
        {
          date: '2024-06-12',
          searchVolume: 42802,
          organicRank: 306,

          keywordSales: 889
        },
        {
          date: '2024-06-11',
          searchVolume: 42802,
          organicRank: 306,

          keywordSales: 889
        },
        {
          date: '2024-06-10',
          searchVolume: 42802,
          organicRank: 306,

          keywordSales: 889
        },
        {
          date: '2024-06-09',
          searchVolume: 42802,
          organicRank: 250,
          sponsoredRank: 46,
          keywordSales: 889
        },
        {
          date: '2024-06-08',
          searchVolume: 42802,
          organicRank: 306,

          keywordSales: 889
        },
        {
          date: '2024-06-07',
          searchVolume: 38639,
          organicRank: 306,

          keywordSales: 889
        },
        {
          date: '2024-06-06',
          searchVolume: 38639,
          organicRank: 238,

          keywordSales: 889
        },
        {
          date: '2024-06-05',
          searchVolume: 38639,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 889
        },
        {
          date: '2024-06-03',
          searchVolume: 38639,
          organicRank: 104,

          keywordSales: 889
        },
        {
          date: '2024-06-02',
          searchVolume: 38639,
          organicRank: 306,

          keywordSales: 889
        },
        {
          date: '2024-06-01',
          searchVolume: 38639,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 889
        },
        {
          date: '2024-05-31',
          searchVolume: 37287,
          organicRank: 257,
          sponsoredRank: 63,
          keywordSales: 889
        },
        {
          date: '2024-05-30',
          searchVolume: 37287,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 889
        },
        {
          date: '2024-05-29',
          searchVolume: 37287,
          organicRank: 251,
          sponsoredRank: 23,
          keywordSales: 889
        },
        {
          date: '2024-05-28',
          searchVolume: 37287,
          organicRank: 109,

          keywordSales: 889
        },
        {
          date: '2024-05-27',
          searchVolume: 37287,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 889
        },
        {
          date: '2024-05-26',
          searchVolume: 37287,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 889
        },
        {
          date: '2024-05-25',
          searchVolume: 37287,
          organicRank: 131,
          sponsoredRank: 48,
          keywordSales: 889
        },
        {
          date: '2024-05-24',
          searchVolume: 37281,
          organicRank: 114,

          keywordSales: 889
        },
        {
          date: '2024-05-23',
          searchVolume: 37281,
          organicRank: 143,
          sponsoredRank: 23,
          keywordSales: 889
        },
        {
          date: '2024-05-22',
          searchVolume: 37281,
          organicRank: 125,
          sponsoredRank: 41,
          keywordSales: 889
        },
        {
          date: '2024-05-21',
          searchVolume: 37281,
          organicRank: 228,
          sponsoredRank: 57,
          keywordSales: 889
        },
        {
          date: '2024-05-20',
          searchVolume: 37281,
          organicRank: 135,
          sponsoredRank: 59,
          keywordSales: 889
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home security cameras outdoor`,
      keyword: 'home security cameras outdoor',
      searchVolume: 6640,
      organicStartRank: 72,
      organicHighestRank: 33,
      sponsoredStartRank: 40,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 5703,
          organicRank: 85,
          sponsoredRank: 105,
          keywordSales: 24
        },
        {
          date: '2024-07-02',
          searchVolume: 5703,
          organicRank: 306,
          sponsoredRank: 64,
          keywordSales: 24
        },
        {
          date: '2024-07-01',
          searchVolume: 5703,
          organicRank: 69,

          keywordSales: 24
        },
        {
          date: '2024-06-30',
          searchVolume: 5703,
          organicRank: 54,

          keywordSales: 24
        },
        {
          date: '2024-06-29',
          searchVolume: 5703,
          organicRank: 47,

          keywordSales: 24
        },
        {
          date: '2024-06-28',
          searchVolume: 5912,
          organicRank: 34,
          sponsoredRank: 10,
          keywordSales: 24
        },
        {
          date: '2024-06-27',
          searchVolume: 5912,
          organicRank: 33,
          sponsoredRank: 19,
          keywordSales: 24
        },
        {
          date: '2024-06-26',
          searchVolume: 5912,
          organicRank: 86,
          sponsoredRank: 36,
          keywordSales: 24
        },
        {
          date: '2024-06-25',
          searchVolume: 5912,
          organicRank: 63,

          keywordSales: 24
        },
        {
          date: '2024-06-24',
          searchVolume: 5912,
          organicRank: 65,
          sponsoredRank: 3,
          keywordSales: 24
        },
        {
          date: '2024-06-23',
          searchVolume: 5912,
          organicRank: 53,
          sponsoredRank: 8,
          keywordSales: 24
        },
        {
          date: '2024-06-22',
          searchVolume: 5912,
          organicRank: 62,
          sponsoredRank: 12,
          keywordSales: 24
        },
        {
          date: '2024-06-21',
          searchVolume: 6740,
          organicRank: 72,
          sponsoredRank: 101,
          keywordSales: 24
        },
        {
          date: '2024-06-20',
          searchVolume: 6740,
          organicRank: 48,
          sponsoredRank: 43,
          keywordSales: 24
        },
        {
          date: '2024-06-19',
          searchVolume: 6740,
          organicRank: 87,
          sponsoredRank: 35,
          keywordSales: 24
        },
        {
          date: '2024-06-18',
          searchVolume: 6740,
          organicRank: 88,

          keywordSales: 24
        },
        {
          date: '2024-06-17',
          searchVolume: 6740,
          organicRank: 84,

          keywordSales: 24
        },
        {
          date: '2024-06-16',
          searchVolume: 6740,
          organicRank: 114,
          sponsoredRank: 65,
          keywordSales: 24
        },
        {
          date: '2024-06-15',
          searchVolume: 6740,
          organicRank: 81,
          sponsoredRank: 15,
          keywordSales: 24
        },
        {
          date: '2024-06-14',
          searchVolume: 6747,
          organicRank: 73,
          sponsoredRank: 20,
          keywordSales: 24
        },
        {
          date: '2024-06-13',
          searchVolume: 6747,
          organicRank: 306,
          sponsoredRank: 64,
          keywordSales: 24
        },
        {
          date: '2024-06-12',
          searchVolume: 6747,
          organicRank: 88,
          sponsoredRank: 63,
          keywordSales: 24
        },
        {
          date: '2024-06-11',
          searchVolume: 6747,
          organicRank: 74,

          keywordSales: 24
        },
        {
          date: '2024-06-10',
          searchVolume: 6747,
          organicRank: 252,

          keywordSales: 24
        },
        {
          date: '2024-06-09',
          searchVolume: 6747,
          organicRank: 77,
          sponsoredRank: 56,
          keywordSales: 24
        },
        {
          date: '2024-06-08',
          searchVolume: 6747,
          organicRank: 66,

          keywordSales: 24
        },
        {
          date: '2024-06-07',
          searchVolume: 6438,
          organicRank: 69,

          keywordSales: 24
        },
        {
          date: '2024-06-06',
          searchVolume: 6438,
          organicRank: 64,
          sponsoredRank: 17,
          keywordSales: 24
        },
        {
          date: '2024-06-05',
          searchVolume: 6438,
          organicRank: 63,
          sponsoredRank: 16,
          keywordSales: 24
        },
        {
          date: '2024-06-04',
          searchVolume: 6438,
          organicRank: 67,
          sponsoredRank: 3,
          keywordSales: 24
        },
        {
          date: '2024-06-03',
          searchVolume: 6438,
          organicRank: 70,
          sponsoredRank: 72,
          keywordSales: 24
        },
        {
          date: '2024-06-02',
          searchVolume: 6438,
          organicRank: 61,
          sponsoredRank: 44,
          keywordSales: 24
        },
        {
          date: '2024-06-01',
          searchVolume: 6438,
          organicRank: 53,
          sponsoredRank: 57,
          keywordSales: 24
        },
        {
          date: '2024-05-31',
          searchVolume: 6649,
          organicRank: 80,
          sponsoredRank: 13,
          keywordSales: 24
        },
        {
          date: '2024-05-30',
          searchVolume: 6649,
          organicRank: 85,
          sponsoredRank: 58,
          keywordSales: 24
        },
        {
          date: '2024-05-29',
          searchVolume: 6649,
          organicRank: 72,
          sponsoredRank: 78,
          keywordSales: 24
        },
        {
          date: '2024-05-28',
          searchVolume: 6649,
          organicRank: 71,
          sponsoredRank: 61,
          keywordSales: 24
        },
        {
          date: '2024-05-27',
          searchVolume: 6649,
          organicRank: 91,
          sponsoredRank: 53,
          keywordSales: 24
        },
        {
          date: '2024-05-26',
          searchVolume: 6649,
          organicRank: 72,
          sponsoredRank: 5,
          keywordSales: 24
        },
        {
          date: '2024-05-25',
          searchVolume: 6649,
          organicRank: 69,
          sponsoredRank: 2,
          keywordSales: 24
        },
        {
          date: '2024-05-24',
          searchVolume: 6640,
          organicRank: 96,

          keywordSales: 24
        },
        {
          date: '2024-05-23',
          searchVolume: 6640,
          organicRank: 71,
          sponsoredRank: 23,
          keywordSales: 24
        },
        {
          date: '2024-05-22',
          searchVolume: 6640,
          organicRank: 72,
          sponsoredRank: 40,
          keywordSales: 24
        },
        {
          date: '2024-05-21',
          searchVolume: 6640,
          organicRank: 80,
          sponsoredRank: 17,
          keywordSales: 24
        },
        {
          date: '2024-05-20',
          searchVolume: 6640,
          organicRank: 91,
          sponsoredRank: 66,
          keywordSales: 24
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home security cameras wireless outdoor`,
      keyword: 'home security cameras wireless outdoor',
      searchVolume: 3080,
      organicStartRank: 79,
      organicHighestRank: 32,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-28',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 3147,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 59
        },
        {
          date: '2024-07-02',
          searchVolume: 3147,
          organicRank: 76,
          sponsoredRank: 28,
          keywordSales: 59
        },
        {
          date: '2024-07-01',
          searchVolume: 3147,
          organicRank: 69,

          keywordSales: 59
        },
        {
          date: '2024-06-30',
          searchVolume: 3147,
          organicRank: 45,
          sponsoredRank: 9,
          keywordSales: 59
        },
        {
          date: '2024-06-29',
          searchVolume: 3147,
          organicRank: 52,
          sponsoredRank: 16,
          keywordSales: 59
        },
        {
          date: '2024-06-28',
          searchVolume: 3609,
          organicRank: 51,
          sponsoredRank: 12,
          keywordSales: 59
        },
        {
          date: '2024-06-27',
          searchVolume: 3609,
          organicRank: 45,
          sponsoredRank: 3,
          keywordSales: 59
        },
        {
          date: '2024-06-26',
          searchVolume: 3609,
          organicRank: 51,
          sponsoredRank: 66,
          keywordSales: 59
        },
        {
          date: '2024-06-25',
          searchVolume: 3609,
          organicRank: 44,

          keywordSales: 59
        },
        {
          date: '2024-06-24',
          searchVolume: 3609,
          organicRank: 38,
          sponsoredRank: 8,
          keywordSales: 59
        },
        {
          date: '2024-06-23',
          searchVolume: 3609,
          organicRank: 35,
          sponsoredRank: 11,
          keywordSales: 59
        },
        {
          date: '2024-06-22',
          searchVolume: 3609,
          organicRank: 39,
          sponsoredRank: 6,
          keywordSales: 59
        },
        {
          date: '2024-06-21',
          searchVolume: 3237,
          organicRank: 32,

          keywordSales: 59
        },
        {
          date: '2024-06-20',
          searchVolume: 3237,
          organicRank: 41,
          sponsoredRank: 17,
          keywordSales: 59
        },
        {
          date: '2024-06-19',
          searchVolume: 3237,
          organicRank: 49,
          sponsoredRank: 18,
          keywordSales: 59
        },
        {
          date: '2024-06-18',
          searchVolume: 3237,
          organicRank: 53,
          sponsoredRank: 27,
          keywordSales: 59
        },
        {
          date: '2024-06-17',
          searchVolume: 3237,
          organicRank: 69,
          sponsoredRank: 62,
          keywordSales: 59
        },
        {
          date: '2024-06-16',
          searchVolume: 3237,
          organicRank: 65,

          keywordSales: 59
        },
        {
          date: '2024-06-15',
          searchVolume: 3237,
          organicRank: 52,
          sponsoredRank: 4,
          keywordSales: 59
        },
        {
          date: '2024-06-14',
          searchVolume: 3243,
          organicRank: 54,
          sponsoredRank: 20,
          keywordSales: 59
        },
        {
          date: '2024-06-13',
          searchVolume: 3243,
          organicRank: 73,
          sponsoredRank: 1,
          keywordSales: 59
        },
        {
          date: '2024-06-12',
          searchVolume: 3243,
          organicRank: 306,

          keywordSales: 59
        },
        {
          date: '2024-06-11',
          searchVolume: 3243,
          organicRank: 62,
          sponsoredRank: 41,
          keywordSales: 59
        },
        {
          date: '2024-06-10',
          searchVolume: 3243,
          organicRank: 56,
          sponsoredRank: 98,
          keywordSales: 59
        },
        {
          date: '2024-06-09',
          searchVolume: 3243,
          organicRank: 63,
          sponsoredRank: 5,
          keywordSales: 59
        },
        {
          date: '2024-06-08',
          searchVolume: 3243,
          organicRank: 60,
          sponsoredRank: 59,
          keywordSales: 59
        },
        {
          date: '2024-06-07',
          searchVolume: 3090,
          organicRank: 65,

          keywordSales: 59
        },
        {
          date: '2024-06-06',
          searchVolume: 3090,
          organicRank: 85,
          sponsoredRank: 9,
          keywordSales: 59
        },
        {
          date: '2024-06-05',
          searchVolume: 3090,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 59
        },
        {
          date: '2024-06-04',
          searchVolume: 3090,
          organicRank: 68,
          sponsoredRank: 13,
          keywordSales: 59
        },
        {
          date: '2024-06-03',
          searchVolume: 3090,
          organicRank: 64,

          keywordSales: 59
        },
        {
          date: '2024-06-02',
          searchVolume: 3090,
          organicRank: 55,

          keywordSales: 59
        },
        {
          date: '2024-06-01',
          searchVolume: 3090,
          organicRank: 48,
          sponsoredRank: 71,
          keywordSales: 59
        },
        {
          date: '2024-05-31',
          searchVolume: 3418,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 59
        },
        {
          date: '2024-05-30',
          searchVolume: 3418,
          organicRank: 90,
          sponsoredRank: 65,
          keywordSales: 59
        },
        {
          date: '2024-05-29',
          searchVolume: 3418,
          organicRank: 54,
          sponsoredRank: 53,
          keywordSales: 59
        },
        {
          date: '2024-05-28',
          searchVolume: 3418,
          organicRank: 56,
          sponsoredRank: 2,
          keywordSales: 59
        },
        {
          date: '2024-05-27',
          searchVolume: 3418,
          organicRank: 306,
          sponsoredRank: 49,
          keywordSales: 59
        },
        {
          date: '2024-05-26',
          searchVolume: 3418,
          organicRank: 82,
          sponsoredRank: 57,
          keywordSales: 59
        },
        {
          date: '2024-05-25',
          searchVolume: 3418,
          organicRank: 88,
          sponsoredRank: 63,
          keywordSales: 59
        },
        {
          date: '2024-05-24',
          searchVolume: 3080,
          organicRank: 79,
          sponsoredRank: 86,
          keywordSales: 59
        },
        {
          date: '2024-05-23',
          searchVolume: 3080,
          organicRank: 62,
          sponsoredRank: 12,
          keywordSales: 59
        },
        {
          date: '2024-05-22',
          searchVolume: 3080,
          organicRank: 79,
          sponsoredRank: 11,
          keywordSales: 59
        },
        {
          date: '2024-05-21',
          searchVolume: 3080,
          organicRank: 64,
          sponsoredRank: 2,
          keywordSales: 59
        },
        {
          date: '2024-05-20',
          searchVolume: 3080,
          organicRank: 74,
          sponsoredRank: 66,
          keywordSales: 59
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home security system`,
      keyword: 'home security system',
      searchVolume: 16419,
      organicStartRank: 99,
      organicHighestRank: 22,
      sponsoredStartRank: 68,
      sponsoredHighestRank: 40,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 19718,
          organicRank: 37,

          keywordSales: 71
        },
        {
          date: '2024-07-02',
          searchVolume: 19718,
          organicRank: 69,
          sponsoredRank: 52,
          keywordSales: 71
        },
        {
          date: '2024-07-01',
          searchVolume: 19718,
          organicRank: 37,

          keywordSales: 71
        },
        {
          date: '2024-06-30',
          searchVolume: 19718,
          organicRank: 24,

          keywordSales: 71
        },
        {
          date: '2024-06-29',
          searchVolume: 19718,
          organicRank: 22,

          keywordSales: 71
        },
        {
          date: '2024-06-28',
          searchVolume: 16556,
          organicRank: 57,
          sponsoredRank: 40,
          keywordSales: 71
        },
        {
          date: '2024-06-27',
          searchVolume: 16556,
          organicRank: 49,

          keywordSales: 71
        },
        {
          date: '2024-06-26',
          searchVolume: 16556,
          organicRank: 58,

          keywordSales: 71
        },
        {
          date: '2024-06-25',
          searchVolume: 16556,
          organicRank: 56,

          keywordSales: 71
        },
        {
          date: '2024-06-24',
          searchVolume: 16556,
          organicRank: 49,

          keywordSales: 71
        },
        {
          date: '2024-06-21',
          searchVolume: 14128,
          organicRank: 59,

          keywordSales: 71
        },
        {
          date: '2024-06-20',
          searchVolume: 14128,
          organicRank: 79,

          keywordSales: 71
        },
        {
          date: '2024-06-19',
          searchVolume: 14128,
          organicRank: 134,

          keywordSales: 71
        },
        {
          date: '2024-06-18',
          searchVolume: 14128,
          organicRank: 128,

          keywordSales: 71
        },
        {
          date: '2024-06-17',
          searchVolume: 14128,
          organicRank: 142,

          keywordSales: 71
        },
        {
          date: '2024-06-16',
          searchVolume: 14128,
          organicRank: 168,

          keywordSales: 71
        },
        {
          date: '2024-06-15',
          searchVolume: 14128,
          organicRank: 193,

          keywordSales: 71
        },
        {
          date: '2024-06-14',
          searchVolume: 16659,
          organicRank: 150,

          keywordSales: 71
        },
        {
          date: '2024-06-13',
          searchVolume: 16659,
          organicRank: 143,

          keywordSales: 71
        },
        {
          date: '2024-06-12',
          searchVolume: 16659,
          organicRank: 137,

          keywordSales: 71
        },
        {
          date: '2024-06-11',
          searchVolume: 16659,
          organicRank: 143,

          keywordSales: 71
        },
        {
          date: '2024-06-10',
          searchVolume: 16659,
          organicRank: 141,

          keywordSales: 71
        },
        {
          date: '2024-06-09',
          searchVolume: 16659,
          organicRank: 141,
          sponsoredRank: 47,
          keywordSales: 71
        },
        {
          date: '2024-06-08',
          searchVolume: 16659,
          organicRank: 133,

          keywordSales: 71
        },
        {
          date: '2024-06-07',
          searchVolume: 15008,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-06',
          searchVolume: 15008,
          organicRank: 133,

          keywordSales: 71
        },
        {
          date: '2024-06-05',
          searchVolume: 15008,
          organicRank: 129,

          keywordSales: 71
        },
        {
          date: '2024-06-04',
          searchVolume: 15008,
          organicRank: 104,
          sponsoredRank: 85,
          keywordSales: 71
        },
        {
          date: '2024-06-03',
          searchVolume: 15008,
          organicRank: 89,

          keywordSales: 71
        },
        {
          date: '2024-06-02',
          searchVolume: 15008,
          organicRank: 121,

          keywordSales: 71
        },
        {
          date: '2024-06-01',
          searchVolume: 15008,
          organicRank: 117,

          keywordSales: 71
        },
        {
          date: '2024-05-31',
          searchVolume: 16417,
          organicRank: 114,
          sponsoredRank: 68,
          keywordSales: 71
        },
        {
          date: '2024-05-30',
          searchVolume: 16417,
          organicRank: 75,

          keywordSales: 71
        },
        {
          date: '2024-05-29',
          searchVolume: 16417,
          organicRank: 84,

          keywordSales: 71
        },
        {
          date: '2024-05-28',
          searchVolume: 16417,
          organicRank: 139,

          keywordSales: 71
        },
        {
          date: '2024-05-27',
          searchVolume: 16417,
          organicRank: 130,

          keywordSales: 71
        },
        {
          date: '2024-05-26',
          searchVolume: 16417,
          organicRank: 78,

          keywordSales: 71
        },
        {
          date: '2024-05-25',
          searchVolume: 16417,
          organicRank: 88,

          keywordSales: 71
        },
        {
          date: '2024-05-24',
          searchVolume: 16419,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-05-23',
          searchVolume: 16419,
          organicRank: 111,

          keywordSales: 71
        },
        {
          date: '2024-05-22',
          searchVolume: 16419,
          organicRank: 99,

          keywordSales: 71
        },
        {
          date: '2024-05-21',
          searchVolume: 16419,
          organicRank: 149,

          keywordSales: 71
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_home security systems`,
      keyword: 'home security systems',
      searchVolume: 2509,
      organicStartRank: 133,
      organicHighestRank: 44,
      sponsoredStartRank: 43,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 3128,
          organicRank: 95,

          keywordSales: 1
        },
        {
          date: '2024-07-02',
          searchVolume: 3128,
          organicRank: 92,

          keywordSales: 1
        },
        {
          date: '2024-07-01',
          searchVolume: 3128,
          organicRank: 74,

          keywordSales: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 3128,
          organicRank: 51,

          keywordSales: 1
        },
        {
          date: '2024-06-29',
          searchVolume: 3128,
          organicRank: 47,

          keywordSales: 1
        },
        {
          date: '2024-06-28',
          searchVolume: 2543,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-27',
          searchVolume: 2543,
          organicRank: 48,
          sponsoredRank: 13,
          keywordSales: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 2543,
          organicRank: 52,

          keywordSales: 1
        },
        {
          date: '2024-06-25',
          searchVolume: 2543,
          organicRank: 64,

          keywordSales: 1
        },
        {
          date: '2024-06-23',
          searchVolume: 2543,
          organicRank: 45,

          keywordSales: 1
        },
        {
          date: '2024-06-21',
          searchVolume: 2396,
          organicRank: 44,

          keywordSales: 1
        },
        {
          date: '2024-06-20',
          searchVolume: 2396,
          organicRank: 60,
          sponsoredRank: 43,
          keywordSales: 1
        },
        {
          date: '2024-06-19',
          searchVolume: 2396,
          organicRank: 94,

          keywordSales: 1
        },
        {
          date: '2024-06-18',
          searchVolume: 2396,
          organicRank: 143,

          keywordSales: 1
        },
        {
          date: '2024-06-17',
          searchVolume: 2396,
          organicRank: 101,

          keywordSales: 1
        },
        {
          date: '2024-06-16',
          searchVolume: 2396,
          organicRank: 124,

          keywordSales: 1
        },
        {
          date: '2024-06-15',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-14',
          searchVolume: 2430,
          organicRank: 143,

          keywordSales: 1
        },
        {
          date: '2024-06-13',
          searchVolume: 2430,
          organicRank: 103,

          keywordSales: 1
        },
        {
          date: '2024-06-12',
          searchVolume: 2430,
          organicRank: 123,

          keywordSales: 1
        },
        {
          date: '2024-06-11',
          searchVolume: 2430,
          organicRank: 180,

          keywordSales: 1
        },
        {
          date: '2024-06-10',
          searchVolume: 2430,
          organicRank: 197,

          keywordSales: 1
        },
        {
          date: '2024-06-09',
          searchVolume: 2430,
          organicRank: 147,

          keywordSales: 1
        },
        {
          date: '2024-06-08',
          searchVolume: 2430,
          organicRank: 120,

          keywordSales: 1
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 136,

          keywordSales: 1
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 146,

          keywordSales: 1
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 100,

          keywordSales: 1
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 83,

          keywordSales: 1
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 105,

          keywordSales: 1
        },
        {
          date: '2024-05-31',
          searchVolume: 2354,
          organicRank: 93,
          sponsoredRank: 43,
          keywordSales: 1
        },
        {
          date: '2024-05-30',
          searchVolume: 2354,
          organicRank: 81,

          keywordSales: 1
        },
        {
          date: '2024-05-29',
          searchVolume: 2354,
          organicRank: 109,

          keywordSales: 1
        },
        {
          date: '2024-05-28',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-27',
          searchVolume: 2354,
          organicRank: 136,

          keywordSales: 1
        },
        {
          date: '2024-05-26',
          searchVolume: 2354,
          organicRank: 155,

          keywordSales: 1
        },
        {
          date: '2024-05-25',
          searchVolume: 2354,
          organicRank: 104,

          keywordSales: 1
        },
        {
          date: '2024-05-24',
          searchVolume: 2509,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-05-23',
          searchVolume: 2509,
          organicRank: 93,

          keywordSales: 1
        },
        {
          date: '2024-05-22',
          searchVolume: 2509,
          organicRank: 133,

          keywordSales: 1
        },
        {
          date: '2024-05-20',
          searchVolume: 2509,
          organicRank: 199,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_house camera`,
      keyword: 'house camera',
      searchVolume: 3590,
      organicStartRank: 76,
      organicHighestRank: 48,
      sponsoredStartRank: 65,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 4340,
          organicRank: 72,

          keywordSales: 116
        },
        {
          date: '2024-07-03',
          searchVolume: 4340,
          organicRank: 71,

          keywordSales: 116
        },
        {
          date: '2024-07-02',
          searchVolume: 4340,
          organicRank: 73,

          keywordSales: 116
        },
        {
          date: '2024-07-01',
          searchVolume: 4340,
          organicRank: 68,

          keywordSales: 116
        },
        {
          date: '2024-06-30',
          searchVolume: 4340,
          organicRank: 61,

          keywordSales: 116
        },
        {
          date: '2024-06-29',
          searchVolume: 4340,
          organicRank: 67,

          keywordSales: 116
        },
        {
          date: '2024-06-28',
          searchVolume: 3805,
          organicRank: 56,

          keywordSales: 116
        },
        {
          date: '2024-06-27',
          searchVolume: 3805,
          organicRank: 65,

          keywordSales: 116
        },
        {
          date: '2024-06-26',
          searchVolume: 3805,
          organicRank: 76,

          keywordSales: 116
        },
        {
          date: '2024-06-25',
          searchVolume: 3805,
          organicRank: 76,

          keywordSales: 116
        },
        {
          date: '2024-06-24',
          searchVolume: 3805,
          organicRank: 71,

          keywordSales: 116
        },
        {
          date: '2024-06-21',
          searchVolume: 3861,
          organicRank: 65,

          keywordSales: 116
        },
        {
          date: '2024-06-20',
          searchVolume: 3861,
          organicRank: 67,

          keywordSales: 116
        },
        {
          date: '2024-06-19',
          searchVolume: 3861,
          organicRank: 88,

          keywordSales: 116
        },
        {
          date: '2024-06-18',
          searchVolume: 3861,
          organicRank: 87,
          sponsoredRank: 64,
          keywordSales: 116
        },
        {
          date: '2024-06-17',
          searchVolume: 3861,
          organicRank: 69,

          keywordSales: 116
        },
        {
          date: '2024-06-16',
          searchVolume: 3861,
          organicRank: 88,

          keywordSales: 116
        },
        {
          date: '2024-06-15',
          searchVolume: 3861,
          organicRank: 92,

          keywordSales: 116
        },
        {
          date: '2024-06-14',
          searchVolume: 4322,
          organicRank: 183,

          keywordSales: 116
        },
        {
          date: '2024-06-13',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 116
        },
        {
          date: '2024-06-12',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 116
        },
        {
          date: '2024-06-11',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 116
        },
        {
          date: '2024-06-10',
          searchVolume: 4322,
          organicRank: 91,

          keywordSales: 116
        },
        {
          date: '2024-06-09',
          searchVolume: 4322,
          organicRank: 93,

          keywordSales: 116
        },
        {
          date: '2024-06-08',
          searchVolume: 4322,
          organicRank: 99,

          keywordSales: 116
        },
        {
          date: '2024-06-07',
          searchVolume: 3453,
          organicRank: 72,

          keywordSales: 116
        },
        {
          date: '2024-06-06',
          searchVolume: 3453,
          organicRank: 93,
          sponsoredRank: 13,
          keywordSales: 116
        },
        {
          date: '2024-06-05',
          searchVolume: 3453,
          organicRank: 306,
          sponsoredRank: 35,
          keywordSales: 116
        },
        {
          date: '2024-06-04',
          searchVolume: 3453,
          organicRank: 92,
          sponsoredRank: 7,
          keywordSales: 116
        },
        {
          date: '2024-06-03',
          searchVolume: 3453,
          organicRank: 74,

          keywordSales: 116
        },
        {
          date: '2024-06-02',
          searchVolume: 3453,
          organicRank: 80,

          keywordSales: 116
        },
        {
          date: '2024-06-01',
          searchVolume: 3453,
          organicRank: 81,
          sponsoredRank: 54,
          keywordSales: 116
        },
        {
          date: '2024-05-31',
          searchVolume: 3572,
          organicRank: 73,
          sponsoredRank: 55,
          keywordSales: 116
        },
        {
          date: '2024-05-30',
          searchVolume: 3572,
          organicRank: 75,

          keywordSales: 116
        },
        {
          date: '2024-05-29',
          searchVolume: 3572,
          organicRank: 65,
          sponsoredRank: 83,
          keywordSales: 116
        },
        {
          date: '2024-05-28',
          searchVolume: 3572,
          organicRank: 72,
          sponsoredRank: 54,
          keywordSales: 116
        },
        {
          date: '2024-05-27',
          searchVolume: 3572,
          organicRank: 72,
          sponsoredRank: 1,
          keywordSales: 116
        },
        {
          date: '2024-05-26',
          searchVolume: 3572,
          organicRank: 50,

          keywordSales: 116
        },
        {
          date: '2024-05-25',
          searchVolume: 3572,
          organicRank: 48,

          keywordSales: 116
        },
        {
          date: '2024-05-24',
          searchVolume: 3590,
          organicRank: 82,

          keywordSales: 116
        },
        {
          date: '2024-05-23',
          searchVolume: 3590,
          organicRank: 90,

          keywordSales: 116
        },
        {
          date: '2024-05-22',
          searchVolume: 3590,
          organicRank: 76,
          sponsoredRank: 65,
          keywordSales: 116
        },
        {
          date: '2024-05-21',
          searchVolume: 3590,
          organicRank: 92,

          keywordSales: 116
        },
        {
          date: '2024-05-20',
          searchVolume: 3590,
          organicRank: 83,

          keywordSales: 116
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_house cameras`,
      keyword: 'house cameras',
      searchVolume: 1822,
      organicStartRank: 92,
      organicHighestRank: 53,
      sponsoredStartRank: 35,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-29',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1928,
          organicRank: 272,

          keywordSales: 31
        },
        {
          date: '2024-07-02',
          searchVolume: 1928,
          organicRank: 90,

          keywordSales: 31
        },
        {
          date: '2024-07-01',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-30',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-29',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-28',
          searchVolume: 1919,
          organicRank: 89,

          keywordSales: 31
        },
        {
          date: '2024-06-27',
          searchVolume: 1919,
          organicRank: 81,
          sponsoredRank: 13,
          keywordSales: 31
        },
        {
          date: '2024-06-26',
          searchVolume: 1919,
          organicRank: 91,

          keywordSales: 31
        },
        {
          date: '2024-06-25',
          searchVolume: 1919,
          organicRank: 93,

          keywordSales: 31
        },
        {
          date: '2024-06-24',
          searchVolume: 1919,
          organicRank: 87,

          keywordSales: 31
        },
        {
          date: '2024-06-22',
          searchVolume: 1919,
          organicRank: 91,

          keywordSales: 31
        },
        {
          date: '2024-06-21',
          searchVolume: 1921,
          organicRank: 90,

          keywordSales: 31
        },
        {
          date: '2024-06-20',
          searchVolume: 1921,
          organicRank: 85,

          keywordSales: 31
        },
        {
          date: '2024-06-19',
          searchVolume: 1921,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-18',
          searchVolume: 1921,
          organicRank: 85,

          keywordSales: 31
        },
        {
          date: '2024-06-17',
          searchVolume: 1921,
          organicRank: 90,

          keywordSales: 31
        },
        {
          date: '2024-06-16',
          searchVolume: 1921,
          organicRank: 94,

          keywordSales: 31
        },
        {
          date: '2024-06-15',
          searchVolume: 1921,
          organicRank: 102,

          keywordSales: 31
        },
        {
          date: '2024-06-14',
          searchVolume: 1923,
          organicRank: 189,

          keywordSales: 31
        },
        {
          date: '2024-06-13',
          searchVolume: 1923,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-12',
          searchVolume: 1923,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-11',
          searchVolume: 1923,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-10',
          searchVolume: 1923,
          organicRank: 75,

          keywordSales: 31
        },
        {
          date: '2024-06-09',
          searchVolume: 1923,
          organicRank: 83,

          keywordSales: 31
        },
        {
          date: '2024-06-08',
          searchVolume: 1923,
          organicRank: 79,

          keywordSales: 31
        },
        {
          date: '2024-06-07',
          searchVolume: 1830,
          organicRank: 80,

          keywordSales: 31
        },
        {
          date: '2024-06-06',
          searchVolume: 1830,
          organicRank: 77,
          sponsoredRank: 63,
          keywordSales: 31
        },
        {
          date: '2024-06-05',
          searchVolume: 1830,
          organicRank: 306,
          sponsoredRank: 53,
          keywordSales: 31
        },
        {
          date: '2024-06-04',
          searchVolume: 1830,
          organicRank: 306,
          sponsoredRank: 75,
          keywordSales: 31
        },
        {
          date: '2024-06-03',
          searchVolume: 1830,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-02',
          searchVolume: 1830,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-01',
          searchVolume: 1830,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 31
        },
        {
          date: '2024-05-31',
          searchVolume: 1818,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 31
        },
        {
          date: '2024-05-30',
          searchVolume: 1818,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-05-29',
          searchVolume: 1818,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 31
        },
        {
          date: '2024-05-28',
          searchVolume: 1818,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-05-27',
          searchVolume: 1818,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 31
        },
        {
          date: '2024-05-26',
          searchVolume: 1818,
          organicRank: 53,

          keywordSales: 31
        },
        {
          date: '2024-05-25',
          searchVolume: 1818,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-05-24',
          searchVolume: 1822,
          organicRank: 84,

          keywordSales: 31
        },
        {
          date: '2024-05-23',
          searchVolume: 1822,
          organicRank: 93,
          sponsoredRank: 35,
          keywordSales: 31
        },
        {
          date: '2024-05-22',
          searchVolume: 1822,
          organicRank: 92,

          keywordSales: 31
        },
        {
          date: '2024-05-21',
          searchVolume: 1822,
          organicRank: 117,

          keywordSales: 31
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_house cameras outdoor`,
      keyword: 'house cameras outdoor',
      searchVolume: 1897,
      organicStartRank: 73,
      organicHighestRank: 40,
      sponsoredStartRank: 53,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2175,
          organicRank: 133,

          keywordSales: 3
        },
        {
          date: '2024-07-03',
          searchVolume: 2175,
          organicRank: 248,

          keywordSales: 3
        },
        {
          date: '2024-07-02',
          searchVolume: 2175,
          organicRank: 125,

          keywordSales: 3
        },
        {
          date: '2024-07-01',
          searchVolume: 2175,
          organicRank: 98,

          keywordSales: 3
        },
        {
          date: '2024-06-30',
          searchVolume: 2175,
          organicRank: 111,

          keywordSales: 3
        },
        {
          date: '2024-06-29',
          searchVolume: 2175,
          organicRank: 100,

          keywordSales: 3
        },
        {
          date: '2024-06-28',
          searchVolume: 2180,
          organicRank: 79,

          keywordSales: 3
        },
        {
          date: '2024-06-27',
          searchVolume: 2180,
          organicRank: 98,

          keywordSales: 3
        },
        {
          date: '2024-06-26',
          searchVolume: 2180,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-25',
          searchVolume: 2180,
          organicRank: 53,

          keywordSales: 3
        },
        {
          date: '2024-06-24',
          searchVolume: 2180,
          organicRank: 68,

          keywordSales: 3
        },
        {
          date: '2024-06-21',
          searchVolume: 1928,
          organicRank: 70,

          keywordSales: 3
        },
        {
          date: '2024-06-20',
          searchVolume: 1928,
          organicRank: 67,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 1928,
          organicRank: 62,

          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 1928,
          organicRank: 72,

          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 1928,
          organicRank: 59,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 1928,
          organicRank: 56,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 1928,
          organicRank: 80,
          sponsoredRank: 8,
          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 1921,
          organicRank: 70,
          sponsoredRank: 13,
          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 1921,
          organicRank: 306,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 1921,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 1921,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 1921,
          organicRank: 82,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 1921,
          organicRank: 79,
          sponsoredRank: 64,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 1921,
          organicRank: 88,

          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 1607,
          organicRank: 63,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 1607,
          organicRank: 50,
          sponsoredRank: 21,
          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 1607,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 1607,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 1607,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 1607,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 1607,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 1884,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 1884,
          organicRank: 306,
          sponsoredRank: 99,
          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 1884,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 1884,
          organicRank: 306,
          sponsoredRank: 88,
          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 1884,
          organicRank: 40,

          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 1884,
          organicRank: 49,
          sponsoredRank: 53,
          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 1897,
          organicRank: 72,

          keywordSales: 3
        },
        {
          date: '2024-05-21',
          searchVolume: 1897,
          organicRank: 73,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_house cameras with audio and video`,
      keyword: 'house cameras with audio and video',
      searchVolume: 7340,
      organicStartRank: 79,
      organicHighestRank: 72,
      sponsoredStartRank: 76,
      sponsoredHighestRank: 15,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 7715,
          organicRank: 97,

          keywordSales: 189
        },
        {
          date: '2024-07-03',
          searchVolume: 7715,
          organicRank: 91,

          keywordSales: 189
        },
        {
          date: '2024-07-02',
          searchVolume: 7715,
          organicRank: 92,

          keywordSales: 189
        },
        {
          date: '2024-07-01',
          searchVolume: 7715,
          organicRank: 92,

          keywordSales: 189
        },
        {
          date: '2024-06-30',
          searchVolume: 7715,
          organicRank: 145,

          keywordSales: 189
        },
        {
          date: '2024-06-29',
          searchVolume: 7715,
          organicRank: 94,

          keywordSales: 189
        },
        {
          date: '2024-06-28',
          searchVolume: 7669,
          organicRank: 94,

          keywordSales: 189
        },
        {
          date: '2024-06-27',
          searchVolume: 7669,
          organicRank: 93,

          keywordSales: 189
        },
        {
          date: '2024-06-26',
          searchVolume: 7669,
          organicRank: 99,

          keywordSales: 189
        },
        {
          date: '2024-06-25',
          searchVolume: 7669,
          organicRank: 130,

          keywordSales: 189
        },
        {
          date: '2024-06-24',
          searchVolume: 7669,
          organicRank: 72,

          keywordSales: 189
        },
        {
          date: '2024-06-21',
          searchVolume: 7670,
          organicRank: 85,

          keywordSales: 189
        },
        {
          date: '2024-06-20',
          searchVolume: 7670,
          organicRank: 88,
          sponsoredRank: 72,
          keywordSales: 189
        },
        {
          date: '2024-06-19',
          searchVolume: 7670,
          organicRank: 98,

          keywordSales: 189
        },
        {
          date: '2024-06-18',
          searchVolume: 7670,
          organicRank: 168,

          keywordSales: 189
        },
        {
          date: '2024-06-17',
          searchVolume: 7670,
          organicRank: 160,

          keywordSales: 189
        },
        {
          date: '2024-06-16',
          searchVolume: 7670,
          organicRank: 114,

          keywordSales: 189
        },
        {
          date: '2024-06-15',
          searchVolume: 7670,
          organicRank: 182,

          keywordSales: 189
        },
        {
          date: '2024-06-14',
          searchVolume: 8480,
          organicRank: 115,

          keywordSales: 189
        },
        {
          date: '2024-06-13',
          searchVolume: 8480,
          organicRank: 103,
          sponsoredRank: 34,
          keywordSales: 189
        },
        {
          date: '2024-06-12',
          searchVolume: 8480,
          organicRank: 244,

          keywordSales: 189
        },
        {
          date: '2024-06-11',
          searchVolume: 8480,
          organicRank: 108,

          keywordSales: 189
        },
        {
          date: '2024-06-10',
          searchVolume: 8480,
          organicRank: 112,

          keywordSales: 189
        },
        {
          date: '2024-06-09',
          searchVolume: 8480,
          organicRank: 87,

          keywordSales: 189
        },
        {
          date: '2024-06-08',
          searchVolume: 8480,
          organicRank: 101,

          keywordSales: 189
        },
        {
          date: '2024-06-07',
          searchVolume: 6447,
          organicRank: 82,

          keywordSales: 189
        },
        {
          date: '2024-06-06',
          searchVolume: 6447,
          organicRank: 93,

          keywordSales: 189
        },
        {
          date: '2024-06-05',
          searchVolume: 6447,
          organicRank: 91,
          sponsoredRank: 15,
          keywordSales: 189
        },
        {
          date: '2024-06-04',
          searchVolume: 6447,
          organicRank: 95,

          keywordSales: 189
        },
        {
          date: '2024-06-03',
          searchVolume: 6447,
          organicRank: 91,

          keywordSales: 189
        },
        {
          date: '2024-06-02',
          searchVolume: 6447,
          organicRank: 93,

          keywordSales: 189
        },
        {
          date: '2024-06-01',
          searchVolume: 6447,
          organicRank: 85,
          sponsoredRank: 34,
          keywordSales: 189
        },
        {
          date: '2024-05-31',
          searchVolume: 7576,
          organicRank: 81,
          sponsoredRank: 41,
          keywordSales: 189
        },
        {
          date: '2024-05-30',
          searchVolume: 7576,
          organicRank: 83,

          keywordSales: 189
        },
        {
          date: '2024-05-29',
          searchVolume: 7576,
          organicRank: 81,

          keywordSales: 189
        },
        {
          date: '2024-05-28',
          searchVolume: 7576,
          organicRank: 80,

          keywordSales: 189
        },
        {
          date: '2024-05-27',
          searchVolume: 7576,
          organicRank: 84,
          sponsoredRank: 78,
          keywordSales: 189
        },
        {
          date: '2024-05-26',
          searchVolume: 7576,
          organicRank: 74,
          sponsoredRank: 76,
          keywordSales: 189
        },
        {
          date: '2024-05-25',
          searchVolume: 7576,
          organicRank: 81,

          keywordSales: 189
        },
        {
          date: '2024-05-24',
          searchVolume: 7340,
          organicRank: 79,

          keywordSales: 189
        },
        {
          date: '2024-05-23',
          searchVolume: 7340,
          organicRank: 84,

          keywordSales: 189
        },
        {
          date: '2024-05-22',
          searchVolume: 7340,
          organicRank: 79,

          keywordSales: 189
        },
        {
          date: '2024-05-21',
          searchVolume: 7340,
          organicRank: 90,

          keywordSales: 189
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_in home cameras with app for phone wireless`,
      keyword: 'in home cameras with app for phone wireless',
      searchVolume: 2431,
      organicStartRank: 272,
      organicHighestRank: 242,
      sponsoredStartRank: 92,
      sponsoredHighestRank: 15,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-07-03',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-07-02',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-07-01',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-30',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-29',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-28',
          searchVolume: 2543,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-27',
          searchVolume: 2543,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-26',
          searchVolume: 2543,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-25',
          searchVolume: 2543,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-24',
          searchVolume: 2543,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-17',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-16',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-15',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-14',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-13',
          searchVolume: 2552,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 56
        },
        {
          date: '2024-06-12',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-11',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-10',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-09',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-08',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 278,
          sponsoredRank: 92,
          keywordSales: 56
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 262,

          keywordSales: 56
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-05-31',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-05-30',
          searchVolume: 2773,
          organicRank: 242,

          keywordSales: 56
        },
        {
          date: '2024-05-29',
          searchVolume: 2773,
          organicRank: 265,

          keywordSales: 56
        },
        {
          date: '2024-05-28',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-05-27',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-05-26',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-05-25',
          searchVolume: 2773,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 56
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 266,

          keywordSales: 56
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 272,

          keywordSales: 56
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor camera`,
      keyword: 'indoor camera',
      searchVolume: 81158,
      organicStartRank: 306,
      organicHighestRank: 114,
      sponsoredStartRank: 75,
      sponsoredHighestRank: 25,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 63917,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-07-02',
          searchVolume: 63917,
          organicRank: 124,

          keywordSales: 1474
        },
        {
          date: '2024-07-01',
          searchVolume: 63917,
          organicRank: 114,

          keywordSales: 1474
        },
        {
          date: '2024-06-30',
          searchVolume: 63917,
          organicRank: 243,

          keywordSales: 1474
        },
        {
          date: '2024-06-29',
          searchVolume: 63917,
          organicRank: 117,

          keywordSales: 1474
        },
        {
          date: '2024-06-28',
          searchVolume: 63675,
          organicRank: 141,

          keywordSales: 1474
        },
        {
          date: '2024-06-27',
          searchVolume: 63675,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-26',
          searchVolume: 63675,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-25',
          searchVolume: 63675,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-23',
          searchVolume: 63675,
          organicRank: 276,

          keywordSales: 1474
        },
        {
          date: '2024-06-21',
          searchVolume: 63927,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-20',
          searchVolume: 63927,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-19',
          searchVolume: 63927,
          organicRank: 269,

          keywordSales: 1474
        },
        {
          date: '2024-06-18',
          searchVolume: 63927,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 1474
        },
        {
          date: '2024-06-17',
          searchVolume: 63927,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-16',
          searchVolume: 63927,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-15',
          searchVolume: 63927,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-14',
          searchVolume: 71651,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-13',
          searchVolume: 71651,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 1474
        },
        {
          date: '2024-06-12',
          searchVolume: 71651,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-11',
          searchVolume: 71651,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-10',
          searchVolume: 71651,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-09',
          searchVolume: 71651,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-08',
          searchVolume: 71651,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-07',
          searchVolume: 69194,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-06',
          searchVolume: 69194,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-05',
          searchVolume: 69194,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 1474
        },
        {
          date: '2024-06-04',
          searchVolume: 69194,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 1474
        },
        {
          date: '2024-06-03',
          searchVolume: 69194,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-02',
          searchVolume: 69194,
          organicRank: 306,

          keywordSales: 1474
        },
        {
          date: '2024-06-01',
          searchVolume: 69194,
          organicRank: 306,
          sponsoredRank: 80,
          keywordSales: 1474
        },
        {
          date: '2024-05-31',
          searchVolume: 83504,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 1474
        },
        {
          date: '2024-05-30',
          searchVolume: 83504,
          organicRank: 306,
          sponsoredRank: 92,
          keywordSales: 1474
        },
        {
          date: '2024-05-29',
          searchVolume: 83504,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 1474
        },
        {
          date: '2024-05-28',
          searchVolume: 83504,
          organicRank: 150,
          sponsoredRank: 61,
          keywordSales: 1474
        },
        {
          date: '2024-05-27',
          searchVolume: 83504,
          organicRank: 133,
          sponsoredRank: 75,
          keywordSales: 1474
        },
        {
          date: '2024-05-26',
          searchVolume: 83504,
          organicRank: 144,

          keywordSales: 1474
        },
        {
          date: '2024-05-25',
          searchVolume: 83504,
          organicRank: 147,

          keywordSales: 1474
        },
        {
          date: '2024-05-24',
          searchVolume: 81158,
          organicRank: 306,

          keywordSales: 1474
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor camera wireless`,
      keyword: 'indoor camera wireless',
      searchVolume: 13241,
      organicStartRank: 140,
      organicHighestRank: 84,
      sponsoredStartRank: 76,
      sponsoredHighestRank: 17,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 19735,
          organicRank: 96,

          keywordSales: 343
        },
        {
          date: '2024-07-02',
          searchVolume: 19735,
          organicRank: 95,
          sponsoredRank: 76,
          keywordSales: 343
        },
        {
          date: '2024-07-01',
          searchVolume: 19735,
          organicRank: 106,

          keywordSales: 343
        },
        {
          date: '2024-06-30',
          searchVolume: 19735,
          organicRank: 107,

          keywordSales: 343
        },
        {
          date: '2024-06-29',
          searchVolume: 19735,
          organicRank: 108,

          keywordSales: 343
        },
        {
          date: '2024-06-28',
          searchVolume: 16556,
          organicRank: 105,

          keywordSales: 343
        },
        {
          date: '2024-06-27',
          searchVolume: 16556,
          organicRank: 98,

          keywordSales: 343
        },
        {
          date: '2024-06-26',
          searchVolume: 16556,
          organicRank: 101,

          keywordSales: 343
        },
        {
          date: '2024-06-25',
          searchVolume: 16556,
          organicRank: 116,

          keywordSales: 343
        },
        {
          date: '2024-06-24',
          searchVolume: 16556,
          organicRank: 120,
          sponsoredRank: 51,
          keywordSales: 343
        },
        {
          date: '2024-06-22',
          searchVolume: 16556,
          organicRank: 113,

          keywordSales: 343
        },
        {
          date: '2024-06-21',
          searchVolume: 13426,
          organicRank: 306,

          keywordSales: 343
        },
        {
          date: '2024-06-20',
          searchVolume: 13426,
          organicRank: 111,

          keywordSales: 343
        },
        {
          date: '2024-06-19',
          searchVolume: 13426,
          organicRank: 128,

          keywordSales: 343
        },
        {
          date: '2024-06-18',
          searchVolume: 13426,
          organicRank: 141,

          keywordSales: 343
        },
        {
          date: '2024-06-17',
          searchVolume: 13426,
          organicRank: 129,

          keywordSales: 343
        },
        {
          date: '2024-06-16',
          searchVolume: 13426,
          organicRank: 120,

          keywordSales: 343
        },
        {
          date: '2024-06-15',
          searchVolume: 13426,
          organicRank: 134,

          keywordSales: 343
        },
        {
          date: '2024-06-14',
          searchVolume: 13454,
          organicRank: 263,

          keywordSales: 343
        },
        {
          date: '2024-06-13',
          searchVolume: 13454,
          organicRank: 306,

          keywordSales: 343
        },
        {
          date: '2024-06-12',
          searchVolume: 13454,
          organicRank: 306,

          keywordSales: 343
        },
        {
          date: '2024-06-11',
          searchVolume: 13454,
          organicRank: 306,

          keywordSales: 343
        },
        {
          date: '2024-06-10',
          searchVolume: 13454,
          organicRank: 125,

          keywordSales: 343
        },
        {
          date: '2024-06-09',
          searchVolume: 13454,
          organicRank: 306,

          keywordSales: 343
        },
        {
          date: '2024-06-08',
          searchVolume: 13454,
          organicRank: 245,

          keywordSales: 343
        },
        {
          date: '2024-06-07',
          searchVolume: 12787,
          organicRank: 130,

          keywordSales: 343
        },
        {
          date: '2024-06-06',
          searchVolume: 12787,
          organicRank: 107,
          sponsoredRank: 31,
          keywordSales: 343
        },
        {
          date: '2024-06-05',
          searchVolume: 12787,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 343
        },
        {
          date: '2024-06-04',
          searchVolume: 12787,
          organicRank: 306,
          sponsoredRank: 64,
          keywordSales: 343
        },
        {
          date: '2024-06-03',
          searchVolume: 12787,
          organicRank: 306,

          keywordSales: 343
        },
        {
          date: '2024-06-02',
          searchVolume: 12787,
          organicRank: 306,

          keywordSales: 343
        },
        {
          date: '2024-06-01',
          searchVolume: 12787,
          organicRank: 106,

          keywordSales: 343
        },
        {
          date: '2024-05-31',
          searchVolume: 13210,
          organicRank: 93,
          sponsoredRank: 68,
          keywordSales: 343
        },
        {
          date: '2024-05-30',
          searchVolume: 13210,
          organicRank: 101,
          sponsoredRank: 78,
          keywordSales: 343
        },
        {
          date: '2024-05-29',
          searchVolume: 13210,
          organicRank: 94,

          keywordSales: 343
        },
        {
          date: '2024-05-28',
          searchVolume: 13210,
          organicRank: 306,

          keywordSales: 343
        },
        {
          date: '2024-05-27',
          searchVolume: 13210,
          organicRank: 91,
          sponsoredRank: 84,
          keywordSales: 343
        },
        {
          date: '2024-05-26',
          searchVolume: 13210,
          organicRank: 84,

          keywordSales: 343
        },
        {
          date: '2024-05-25',
          searchVolume: 13210,
          organicRank: 95,
          sponsoredRank: 76,
          keywordSales: 343
        },
        {
          date: '2024-05-24',
          searchVolume: 13241,
          organicRank: 123,

          keywordSales: 343
        },
        {
          date: '2024-05-21',
          searchVolume: 13241,
          organicRank: 140,

          keywordSales: 343
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor camera wireless with phone app`,
      keyword: 'indoor camera wireless with phone app',
      searchVolume: 3299,
      organicStartRank: 266,
      organicHighestRank: 163,
      sponsoredStartRank: 15,
      sponsoredHighestRank: 15,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 4338,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-07-03',
          searchVolume: 4338,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-07-02',
          searchVolume: 4338,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-07-01',
          searchVolume: 4338,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-30',
          searchVolume: 4338,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-29',
          searchVolume: 4338,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-28',
          searchVolume: 3614,
          organicRank: 273,

          keywordSales: 67
        },
        {
          date: '2024-06-27',
          searchVolume: 3614,
          organicRank: 186,

          keywordSales: 67
        },
        {
          date: '2024-06-26',
          searchVolume: 3614,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-25',
          searchVolume: 3614,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-23',
          searchVolume: 3614,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-21',
          searchVolume: 3241,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-20',
          searchVolume: 3241,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-19',
          searchVolume: 3241,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-18',
          searchVolume: 3241,
          organicRank: 306,
          sponsoredRank: 84,
          keywordSales: 67
        },
        {
          date: '2024-06-17',
          searchVolume: 3241,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-16',
          searchVolume: 3241,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-15',
          searchVolume: 3241,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-14',
          searchVolume: 3237,
          organicRank: 306,
          sponsoredRank: 51,
          keywordSales: 67
        },
        {
          date: '2024-06-13',
          searchVolume: 3237,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 67
        },
        {
          date: '2024-06-12',
          searchVolume: 3237,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-11',
          searchVolume: 3237,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-10',
          searchVolume: 3237,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-09',
          searchVolume: 3237,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-08',
          searchVolume: 3237,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-07',
          searchVolume: 3299,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-06',
          searchVolume: 3299,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-05',
          searchVolume: 3299,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-04',
          searchVolume: 3299,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-03',
          searchVolume: 3299,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-02',
          searchVolume: 3299,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-06-01',
          searchVolume: 3299,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-05-31',
          searchVolume: 3089,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-05-30',
          searchVolume: 3089,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-05-29',
          searchVolume: 3089,
          organicRank: 163,

          keywordSales: 67
        },
        {
          date: '2024-05-28',
          searchVolume: 3089,
          organicRank: 172,

          keywordSales: 67
        },
        {
          date: '2024-05-27',
          searchVolume: 3089,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-05-26',
          searchVolume: 3089,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-05-25',
          searchVolume: 3089,
          organicRank: 294,

          keywordSales: 67
        },
        {
          date: '2024-05-24',
          searchVolume: 3566,
          organicRank: 306,

          keywordSales: 67
        },
        {
          date: '2024-05-22',
          searchVolume: 3566,
          organicRank: 266,

          keywordSales: 67
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor cameras`,
      keyword: 'indoor cameras',
      searchVolume: 2982,
      organicStartRank: 275,
      organicHighestRank: 120,
      sponsoredStartRank: 58,
      sponsoredHighestRank: 32,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 3634,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-07-03',
          searchVolume: 3634,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-07-02',
          searchVolume: 3634,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-07-01',
          searchVolume: 3634,
          organicRank: 147,

          keywordSales: 58
        },
        {
          date: '2024-06-30',
          searchVolume: 3634,
          organicRank: 140,

          keywordSales: 58
        },
        {
          date: '2024-06-29',
          searchVolume: 3634,
          organicRank: 144,

          keywordSales: 58
        },
        {
          date: '2024-06-28',
          searchVolume: 3116,
          organicRank: 140,

          keywordSales: 58
        },
        {
          date: '2024-06-27',
          searchVolume: 3116,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-26',
          searchVolume: 3116,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-25',
          searchVolume: 3116,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-23',
          searchVolume: 3116,
          organicRank: 193,

          keywordSales: 58
        },
        {
          date: '2024-06-21',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-20',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-19',
          searchVolume: 2816,
          organicRank: 268,

          keywordSales: 58
        },
        {
          date: '2024-06-18',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-17',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-16',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-15',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-14',
          searchVolume: 3126,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 58
        },
        {
          date: '2024-06-13',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-12',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-11',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-10',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-09',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-08',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-07',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-06',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-05',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-04',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-03',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-02',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-06-01',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-05-31',
          searchVolume: 3078,
          organicRank: 124,
          sponsoredRank: 58,
          keywordSales: 58
        },
        {
          date: '2024-05-30',
          searchVolume: 3078,
          organicRank: 146,

          keywordSales: 58
        },
        {
          date: '2024-05-29',
          searchVolume: 3078,
          organicRank: 137,

          keywordSales: 58
        },
        {
          date: '2024-05-28',
          searchVolume: 3078,
          organicRank: 143,

          keywordSales: 58
        },
        {
          date: '2024-05-27',
          searchVolume: 3078,
          organicRank: 146,

          keywordSales: 58
        },
        {
          date: '2024-05-26',
          searchVolume: 3078,
          organicRank: 120,

          keywordSales: 58
        },
        {
          date: '2024-05-25',
          searchVolume: 3078,
          organicRank: 137,

          keywordSales: 58
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 275,

          keywordSales: 58
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor cameras for home`,
      keyword: 'indoor cameras for home',
      searchVolume: 6640,
      organicStartRank: 272,
      organicHighestRank: 138,
      sponsoredStartRank: 33,
      sponsoredHighestRank: 40,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 13423,
          organicRank: 266,

          keywordSales: 164
        },
        {
          date: '2024-07-03',
          searchVolume: 13423,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-07-02',
          searchVolume: 13423,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-07-01',
          searchVolume: 13423,
          organicRank: 182,

          keywordSales: 164
        },
        {
          date: '2024-06-30',
          searchVolume: 13423,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-29',
          searchVolume: 13423,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-28',
          searchVolume: 9252,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-27',
          searchVolume: 9252,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-26',
          searchVolume: 9252,
          organicRank: 263,

          keywordSales: 164
        },
        {
          date: '2024-06-25',
          searchVolume: 9252,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-23',
          searchVolume: 9252,
          organicRank: 138,

          keywordSales: 164
        },
        {
          date: '2024-06-21',
          searchVolume: 7711,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-20',
          searchVolume: 7711,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-19',
          searchVolume: 7711,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-18',
          searchVolume: 7711,
          organicRank: 262,

          keywordSales: 164
        },
        {
          date: '2024-06-17',
          searchVolume: 7711,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-16',
          searchVolume: 7711,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-15',
          searchVolume: 7711,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-14',
          searchVolume: 7678,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-13',
          searchVolume: 7678,
          organicRank: 306,
          sponsoredRank: 40,
          keywordSales: 164
        },
        {
          date: '2024-06-12',
          searchVolume: 7678,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-11',
          searchVolume: 7678,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-10',
          searchVolume: 7678,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-09',
          searchVolume: 7678,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-08',
          searchVolume: 7678,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-07',
          searchVolume: 6953,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-06',
          searchVolume: 6953,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-05',
          searchVolume: 6953,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-04',
          searchVolume: 6953,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-03',
          searchVolume: 6953,
          organicRank: 168,

          keywordSales: 164
        },
        {
          date: '2024-06-02',
          searchVolume: 6953,
          organicRank: 180,

          keywordSales: 164
        },
        {
          date: '2024-06-01',
          searchVolume: 6953,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-05-31',
          searchVolume: 5912,
          organicRank: 141,

          keywordSales: 164
        },
        {
          date: '2024-05-30',
          searchVolume: 5912,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-05-29',
          searchVolume: 5912,
          organicRank: 161,

          keywordSales: 164
        },
        {
          date: '2024-05-28',
          searchVolume: 5912,
          organicRank: 182,

          keywordSales: 164
        },
        {
          date: '2024-05-27',
          searchVolume: 5912,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-05-26',
          searchVolume: 5912,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-05-25',
          searchVolume: 5912,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-05-24',
          searchVolume: 6640,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-05-23',
          searchVolume: 6640,
          organicRank: 270,

          keywordSales: 164
        },
        {
          date: '2024-05-21',
          searchVolume: 6640,
          organicRank: 272,
          sponsoredRank: 33,
          keywordSales: 164
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor outdoor camera wireless`,
      keyword: 'indoor outdoor camera wireless',
      searchVolume: 2153,
      organicStartRank: 61,
      organicHighestRank: 39,
      sponsoredStartRank: 26,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1658,
          organicRank: 74,
          sponsoredRank: 69,
          keywordSales: 16
        },
        {
          date: '2024-07-02',
          searchVolume: 1658,
          organicRank: 59,
          sponsoredRank: 63,
          keywordSales: 16
        },
        {
          date: '2024-07-01',
          searchVolume: 1658,
          organicRank: 52,

          keywordSales: 16
        },
        {
          date: '2024-06-30',
          searchVolume: 1658,
          organicRank: 48,

          keywordSales: 16
        },
        {
          date: '2024-06-29',
          searchVolume: 1658,
          organicRank: 46,
          sponsoredRank: 34,
          keywordSales: 16
        },
        {
          date: '2024-06-28',
          searchVolume: 1920,
          organicRank: 46,
          sponsoredRank: 45,
          keywordSales: 16
        },
        {
          date: '2024-06-27',
          searchVolume: 1920,
          organicRank: 52,
          sponsoredRank: 7,
          keywordSales: 16
        },
        {
          date: '2024-06-26',
          searchVolume: 1920,
          organicRank: 49,
          sponsoredRank: 34,
          keywordSales: 16
        },
        {
          date: '2024-06-25',
          searchVolume: 1920,
          organicRank: 62,
          sponsoredRank: 28,
          keywordSales: 16
        },
        {
          date: '2024-06-24',
          searchVolume: 1920,
          organicRank: 48,
          sponsoredRank: 81,
          keywordSales: 16
        },
        {
          date: '2024-06-23',
          searchVolume: 1920,
          organicRank: 57,
          sponsoredRank: 17,
          keywordSales: 16
        },
        {
          date: '2024-06-22',
          searchVolume: 1920,
          organicRank: 53,
          sponsoredRank: 3,
          keywordSales: 16
        },
        {
          date: '2024-06-21',
          searchVolume: 1924,
          organicRank: 48,
          sponsoredRank: 24,
          keywordSales: 16
        },
        {
          date: '2024-06-20',
          searchVolume: 1924,
          organicRank: 59,
          sponsoredRank: 29,
          keywordSales: 16
        },
        {
          date: '2024-06-19',
          searchVolume: 1924,
          organicRank: 58,
          sponsoredRank: 40,
          keywordSales: 16
        },
        {
          date: '2024-06-18',
          searchVolume: 1924,
          organicRank: 62,
          sponsoredRank: 9,
          keywordSales: 16
        },
        {
          date: '2024-06-17',
          searchVolume: 1924,
          organicRank: 69,

          keywordSales: 16
        },
        {
          date: '2024-06-16',
          searchVolume: 1924,
          organicRank: 69,

          keywordSales: 16
        },
        {
          date: '2024-06-15',
          searchVolume: 1924,
          organicRank: 77,

          keywordSales: 16
        },
        {
          date: '2024-06-14',
          searchVolume: 1923,
          organicRank: 104,
          sponsoredRank: 4,
          keywordSales: 16
        },
        {
          date: '2024-06-13',
          searchVolume: 1923,
          organicRank: 68,
          sponsoredRank: 90,
          keywordSales: 16
        },
        {
          date: '2024-06-12',
          searchVolume: 1923,
          organicRank: 86,

          keywordSales: 16
        },
        {
          date: '2024-06-11',
          searchVolume: 1923,
          organicRank: 76,

          keywordSales: 16
        },
        {
          date: '2024-06-10',
          searchVolume: 1923,
          organicRank: 62,
          sponsoredRank: 33,
          keywordSales: 16
        },
        {
          date: '2024-06-09',
          searchVolume: 1923,
          organicRank: 71,
          sponsoredRank: 69,
          keywordSales: 16
        },
        {
          date: '2024-06-08',
          searchVolume: 1923,
          organicRank: 66,
          sponsoredRank: 76,
          keywordSales: 16
        },
        {
          date: '2024-06-07',
          searchVolume: 2085,
          organicRank: 66,
          sponsoredRank: 3,
          keywordSales: 16
        },
        {
          date: '2024-06-06',
          searchVolume: 2085,
          organicRank: 58,
          sponsoredRank: 48,
          keywordSales: 16
        },
        {
          date: '2024-06-05',
          searchVolume: 2085,
          organicRank: 58,
          sponsoredRank: 52,
          keywordSales: 16
        },
        {
          date: '2024-06-04',
          searchVolume: 2085,
          organicRank: 54,
          sponsoredRank: 31,
          keywordSales: 16
        },
        {
          date: '2024-06-03',
          searchVolume: 2085,
          organicRank: 48,

          keywordSales: 16
        },
        {
          date: '2024-06-02',
          searchVolume: 2085,
          organicRank: 49,

          keywordSales: 16
        },
        {
          date: '2024-06-01',
          searchVolume: 2085,
          organicRank: 47,
          sponsoredRank: 70,
          keywordSales: 16
        },
        {
          date: '2024-05-31',
          searchVolume: 2217,
          organicRank: 39,
          sponsoredRank: 13,
          keywordSales: 16
        },
        {
          date: '2024-05-30',
          searchVolume: 2217,
          organicRank: 46,

          keywordSales: 16
        },
        {
          date: '2024-05-29',
          searchVolume: 2217,
          organicRank: 46,
          sponsoredRank: 69,
          keywordSales: 16
        },
        {
          date: '2024-05-28',
          searchVolume: 2217,
          organicRank: 51,
          sponsoredRank: 87,
          keywordSales: 16
        },
        {
          date: '2024-05-27',
          searchVolume: 2217,
          organicRank: 47,
          sponsoredRank: 69,
          keywordSales: 16
        },
        {
          date: '2024-05-26',
          searchVolume: 2217,
          organicRank: 47,
          sponsoredRank: 76,
          keywordSales: 16
        },
        {
          date: '2024-05-25',
          searchVolume: 2217,
          organicRank: 50,
          sponsoredRank: 70,
          keywordSales: 16
        },
        {
          date: '2024-05-24',
          searchVolume: 2153,
          organicRank: 68,

          keywordSales: 16
        },
        {
          date: '2024-05-23',
          searchVolume: 2153,
          organicRank: 78,
          sponsoredRank: 21,
          keywordSales: 16
        },
        {
          date: '2024-05-22',
          searchVolume: 2153,
          organicRank: 61,
          sponsoredRank: 26,
          keywordSales: 16
        },
        {
          date: '2024-05-21',
          searchVolume: 2153,
          organicRank: 67,
          sponsoredRank: 18,
          keywordSales: 16
        },
        {
          date: '2024-05-20',
          searchVolume: 2153,
          organicRank: 83,

          keywordSales: 16
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor security camera`,
      keyword: 'indoor security camera',
      searchVolume: 8878,
      organicStartRank: 270,
      organicHighestRank: 91,
      sponsoredStartRank: 55,
      sponsoredHighestRank: 31,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 10882,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-07-02',
          searchVolume: 10882,
          organicRank: 306,
          sponsoredRank: 51,
          keywordSales: 159
        },
        {
          date: '2024-07-01',
          searchVolume: 10882,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-30',
          searchVolume: 10882,
          organicRank: 249,

          keywordSales: 159
        },
        {
          date: '2024-06-29',
          searchVolume: 10882,
          organicRank: 147,

          keywordSales: 159
        },
        {
          date: '2024-06-28',
          searchVolume: 9254,
          organicRank: 134,

          keywordSales: 159
        },
        {
          date: '2024-06-27',
          searchVolume: 9254,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 159
        },
        {
          date: '2024-06-26',
          searchVolume: 9254,
          organicRank: 147,

          keywordSales: 159
        },
        {
          date: '2024-06-25',
          searchVolume: 9254,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-24',
          searchVolume: 9254,
          organicRank: 236,
          sponsoredRank: 63,
          keywordSales: 159
        },
        {
          date: '2024-06-22',
          searchVolume: 9254,
          organicRank: 157,
          sponsoredRank: 36,
          keywordSales: 159
        },
        {
          date: '2024-06-21',
          searchVolume: 9037,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-20',
          searchVolume: 9037,
          organicRank: 186,

          keywordSales: 159
        },
        {
          date: '2024-06-19',
          searchVolume: 9037,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-18',
          searchVolume: 9037,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-17',
          searchVolume: 9037,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-16',
          searchVolume: 9037,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-15',
          searchVolume: 9037,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-14',
          searchVolume: 9279,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-13',
          searchVolume: 9279,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-12',
          searchVolume: 9279,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-11',
          searchVolume: 9279,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-10',
          searchVolume: 9279,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-09',
          searchVolume: 9279,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-08',
          searchVolume: 9279,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-07',
          searchVolume: 8617,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-06',
          searchVolume: 8617,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-05',
          searchVolume: 8617,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 159
        },
        {
          date: '2024-06-04',
          searchVolume: 8617,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 159
        },
        {
          date: '2024-06-03',
          searchVolume: 8617,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-02',
          searchVolume: 8617,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-06-01',
          searchVolume: 8617,
          organicRank: 91,
          sponsoredRank: 31,
          keywordSales: 159
        },
        {
          date: '2024-05-31',
          searchVolume: 8353,
          organicRank: 95,
          sponsoredRank: 59,
          keywordSales: 159
        },
        {
          date: '2024-05-30',
          searchVolume: 8353,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 159
        },
        {
          date: '2024-05-29',
          searchVolume: 8353,
          organicRank: 122,

          keywordSales: 159
        },
        {
          date: '2024-05-28',
          searchVolume: 8353,
          organicRank: 306,
          sponsoredRank: 88,
          keywordSales: 159
        },
        {
          date: '2024-05-27',
          searchVolume: 8353,
          organicRank: 107,
          sponsoredRank: 62,
          keywordSales: 159
        },
        {
          date: '2024-05-26',
          searchVolume: 8353,
          organicRank: 106,

          keywordSales: 159
        },
        {
          date: '2024-05-25',
          searchVolume: 8353,
          organicRank: 123,
          sponsoredRank: 55,
          keywordSales: 159
        },
        {
          date: '2024-05-24',
          searchVolume: 8878,
          organicRank: 306,

          keywordSales: 159
        },
        {
          date: '2024-05-21',
          searchVolume: 8878,
          organicRank: 270,

          keywordSales: 159
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor security camera wireless`,
      keyword: 'indoor security camera wireless',
      searchVolume: 2513,
      organicStartRank: 122,
      organicHighestRank: 46,
      sponsoredStartRank: 86,
      sponsoredHighestRank: 39,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 3461,
          organicRank: 178,

          keywordSales: 71
        },
        {
          date: '2024-07-02',
          searchVolume: 3461,
          organicRank: 142,

          keywordSales: 71
        },
        {
          date: '2024-07-01',
          searchVolume: 3461,
          organicRank: 107,

          keywordSales: 71
        },
        {
          date: '2024-06-30',
          searchVolume: 3461,
          organicRank: 93,

          keywordSales: 71
        },
        {
          date: '2024-06-29',
          searchVolume: 3461,
          organicRank: 85,

          keywordSales: 71
        },
        {
          date: '2024-06-28',
          searchVolume: 3116,
          organicRank: 90,
          sponsoredRank: 87,
          keywordSales: 71
        },
        {
          date: '2024-06-27',
          searchVolume: 3116,
          organicRank: 89,

          keywordSales: 71
        },
        {
          date: '2024-06-26',
          searchVolume: 3116,
          organicRank: 96,

          keywordSales: 71
        },
        {
          date: '2024-06-25',
          searchVolume: 3116,
          organicRank: 102,

          keywordSales: 71
        },
        {
          date: '2024-06-22',
          searchVolume: 3116,
          organicRank: 114,

          keywordSales: 71
        },
        {
          date: '2024-06-21',
          searchVolume: 2816,
          organicRank: 247,

          keywordSales: 71
        },
        {
          date: '2024-06-20',
          searchVolume: 2816,
          organicRank: 134,

          keywordSales: 71
        },
        {
          date: '2024-06-19',
          searchVolume: 2816,
          organicRank: 94,

          keywordSales: 71
        },
        {
          date: '2024-06-18',
          searchVolume: 2816,
          organicRank: 105,
          sponsoredRank: 80,
          keywordSales: 71
        },
        {
          date: '2024-06-17',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-16',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-15',
          searchVolume: 2816,
          organicRank: 111,

          keywordSales: 71
        },
        {
          date: '2024-06-14',
          searchVolume: 2552,
          organicRank: 124,

          keywordSales: 71
        },
        {
          date: '2024-06-13',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-12',
          searchVolume: 2552,
          organicRank: 107,

          keywordSales: 71
        },
        {
          date: '2024-06-11',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-10',
          searchVolume: 2552,
          organicRank: 94,

          keywordSales: 71
        },
        {
          date: '2024-06-09',
          searchVolume: 2552,
          organicRank: 92,

          keywordSales: 71
        },
        {
          date: '2024-06-08',
          searchVolume: 2552,
          organicRank: 106,
          sponsoredRank: 82,
          keywordSales: 71
        },
        {
          date: '2024-06-07',
          searchVolume: 2433,
          organicRank: 97,

          keywordSales: 71
        },
        {
          date: '2024-06-06',
          searchVolume: 2433,
          organicRank: 87,

          keywordSales: 71
        },
        {
          date: '2024-06-05',
          searchVolume: 2433,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 71
        },
        {
          date: '2024-06-04',
          searchVolume: 2433,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 71
        },
        {
          date: '2024-06-03',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-02',
          searchVolume: 2433,
          organicRank: 59,

          keywordSales: 71
        },
        {
          date: '2024-06-01',
          searchVolume: 2433,
          organicRank: 68,
          sponsoredRank: 39,
          keywordSales: 71
        },
        {
          date: '2024-05-31',
          searchVolume: 3078,
          organicRank: 59,
          sponsoredRank: 39,
          keywordSales: 71
        },
        {
          date: '2024-05-30',
          searchVolume: 3078,
          organicRank: 71,
          sponsoredRank: 48,
          keywordSales: 71
        },
        {
          date: '2024-05-29',
          searchVolume: 3078,
          organicRank: 47,

          keywordSales: 71
        },
        {
          date: '2024-05-28',
          searchVolume: 3078,
          organicRank: 59,
          sponsoredRank: 53,
          keywordSales: 71
        },
        {
          date: '2024-05-27',
          searchVolume: 3078,
          organicRank: 53,
          sponsoredRank: 86,
          keywordSales: 71
        },
        {
          date: '2024-05-26',
          searchVolume: 3078,
          organicRank: 46,

          keywordSales: 71
        },
        {
          date: '2024-05-25',
          searchVolume: 3078,
          organicRank: 52,

          keywordSales: 71
        },
        {
          date: '2024-05-24',
          searchVolume: 2513,
          organicRank: 250,

          keywordSales: 71
        },
        {
          date: '2024-05-23',
          searchVolume: 2513,
          organicRank: 116,

          keywordSales: 71
        },
        {
          date: '2024-05-22',
          searchVolume: 2513,
          organicRank: 122,

          keywordSales: 71
        },
        {
          date: '2024-05-20',
          searchVolume: 2513,
          organicRank: 247,

          keywordSales: 71
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor security cameras wireless wifi`,
      keyword: 'indoor security cameras wireless wifi',
      searchVolume: 1889,
      organicStartRank: 306,
      organicHighestRank: 92,
      sponsoredStartRank: 61,
      sponsoredHighestRank: 39,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2253,
          organicRank: 306
        },
        {
          date: '2024-07-03',
          searchVolume: 2253,
          organicRank: 306
        },
        {
          date: '2024-07-02',
          searchVolume: 2253,
          organicRank: 191
        },
        {
          date: '2024-07-01',
          searchVolume: 2253,
          organicRank: 179
        },
        {
          date: '2024-06-30',
          searchVolume: 2253,
          organicRank: 164
        },
        {
          date: '2024-06-29',
          searchVolume: 2253,
          organicRank: 155
        },
        {
          date: '2024-06-28',
          searchVolume: 1911,
          organicRank: 176,
          sponsoredRank: 39
        },
        {
          date: '2024-06-27',
          searchVolume: 1911,
          organicRank: 181
        },
        {
          date: '2024-06-26',
          searchVolume: 1911,
          organicRank: 185
        },
        {
          date: '2024-06-25',
          searchVolume: 1911,
          organicRank: 196
        },
        {
          date: '2024-06-23',
          searchVolume: 1911,
          organicRank: 193
        },
        {
          date: '2024-06-21',
          searchVolume: 1923,
          organicRank: 306
        },
        {
          date: '2024-06-20',
          searchVolume: 1923,
          organicRank: 200
        },
        {
          date: '2024-06-19',
          searchVolume: 1923,
          organicRank: 306
        },
        {
          date: '2024-06-18',
          searchVolume: 1923,
          organicRank: 257
        },
        {
          date: '2024-06-17',
          searchVolume: 1923,
          organicRank: 306
        },
        {
          date: '2024-06-16',
          searchVolume: 1923,
          organicRank: 170
        },
        {
          date: '2024-06-15',
          searchVolume: 1923,
          organicRank: 149
        },
        {
          date: '2024-06-14',
          searchVolume: 1921,
          organicRank: 306,
          sponsoredRank: 59
        },
        {
          date: '2024-06-13',
          searchVolume: 1921,
          organicRank: 306
        },
        {
          date: '2024-06-12',
          searchVolume: 1921,
          organicRank: 306
        },
        {
          date: '2024-06-11',
          searchVolume: 1921,
          organicRank: 265
        },
        {
          date: '2024-06-10',
          searchVolume: 1921,
          organicRank: 306
        },
        {
          date: '2024-06-09',
          searchVolume: 1921,
          organicRank: 306
        },
        {
          date: '2024-06-08',
          searchVolume: 1921,
          organicRank: 262
        },
        {
          date: '2024-06-07',
          searchVolume: 1761,
          organicRank: 127
        },
        {
          date: '2024-06-06',
          searchVolume: 1761,
          organicRank: 306
        },
        {
          date: '2024-06-05',
          searchVolume: 1761,
          organicRank: 306,
          sponsoredRank: 89
        },
        {
          date: '2024-06-04',
          searchVolume: 1761,
          organicRank: 306
        },
        {
          date: '2024-06-03',
          searchVolume: 1761,
          organicRank: 123
        },
        {
          date: '2024-06-02',
          searchVolume: 1761,
          organicRank: 122
        },
        {
          date: '2024-06-01',
          searchVolume: 1761,
          organicRank: 133
        },
        {
          date: '2024-05-31',
          searchVolume: 1822,
          organicRank: 107,
          sponsoredRank: 63
        },
        {
          date: '2024-05-30',
          searchVolume: 1822,
          organicRank: 116
        },
        {
          date: '2024-05-29',
          searchVolume: 1822,
          organicRank: 103,
          sponsoredRank: 72
        },
        {
          date: '2024-05-28',
          searchVolume: 1822,
          organicRank: 95
        },
        {
          date: '2024-05-27',
          searchVolume: 1822,
          organicRank: 102,
          sponsoredRank: 65
        },
        {
          date: '2024-05-26',
          searchVolume: 1822,
          organicRank: 118,
          sponsoredRank: 61
        },
        {
          date: '2024-05-25',
          searchVolume: 1822,
          organicRank: 92
        },
        {
          date: '2024-05-24',
          searchVolume: 1889,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_indoor wireless camera`,
      keyword: 'indoor wireless camera',
      searchVolume: 1886,
      organicStartRank: 148,
      organicHighestRank: 69,
      sponsoredStartRank: 73,
      sponsoredHighestRank: 30,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1853,
          organicRank: 109,

          keywordSales: 26
        },
        {
          date: '2024-07-02',
          searchVolume: 1853,
          organicRank: 115,

          keywordSales: 26
        },
        {
          date: '2024-07-01',
          searchVolume: 1853,
          organicRank: 114,

          keywordSales: 26
        },
        {
          date: '2024-06-30',
          searchVolume: 1853,
          organicRank: 79,

          keywordSales: 26
        },
        {
          date: '2024-06-29',
          searchVolume: 1853,
          organicRank: 82,

          keywordSales: 26
        },
        {
          date: '2024-06-28',
          searchVolume: 1659,
          organicRank: 74,

          keywordSales: 26
        },
        {
          date: '2024-06-27',
          searchVolume: 1659,
          organicRank: 73,
          sponsoredRank: 88,
          keywordSales: 26
        },
        {
          date: '2024-06-26',
          searchVolume: 1659,
          organicRank: 86,

          keywordSales: 26
        },
        {
          date: '2024-06-25',
          searchVolume: 1659,
          organicRank: 168,

          keywordSales: 26
        },
        {
          date: '2024-06-24',
          searchVolume: 1659,
          organicRank: 166,

          keywordSales: 26
        },
        {
          date: '2024-06-22',
          searchVolume: 1659,
          organicRank: 140,

          keywordSales: 26
        },
        {
          date: '2024-06-21',
          searchVolume: 1609,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-20',
          searchVolume: 1609,
          organicRank: 134,

          keywordSales: 26
        },
        {
          date: '2024-06-19',
          searchVolume: 1609,
          organicRank: 96,

          keywordSales: 26
        },
        {
          date: '2024-06-18',
          searchVolume: 1609,
          organicRank: 97,

          keywordSales: 26
        },
        {
          date: '2024-06-17',
          searchVolume: 1609,
          organicRank: 98,

          keywordSales: 26
        },
        {
          date: '2024-06-16',
          searchVolume: 1609,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-15',
          searchVolume: 1609,
          organicRank: 149,

          keywordSales: 26
        },
        {
          date: '2024-06-14',
          searchVolume: 1930,
          organicRank: 137,

          keywordSales: 26
        },
        {
          date: '2024-06-13',
          searchVolume: 1930,
          organicRank: 151,

          keywordSales: 26
        },
        {
          date: '2024-06-12',
          searchVolume: 1930,
          organicRank: 170,

          keywordSales: 26
        },
        {
          date: '2024-06-11',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-10',
          searchVolume: 1930,
          organicRank: 113,

          keywordSales: 26
        },
        {
          date: '2024-06-09',
          searchVolume: 1930,
          organicRank: 117,

          keywordSales: 26
        },
        {
          date: '2024-06-08',
          searchVolume: 1930,
          organicRank: 274,

          keywordSales: 26
        },
        {
          date: '2024-06-07',
          searchVolume: 1611,
          organicRank: 237,

          keywordSales: 26
        },
        {
          date: '2024-06-06',
          searchVolume: 1611,
          organicRank: 102,

          keywordSales: 26
        },
        {
          date: '2024-06-05',
          searchVolume: 1611,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 26
        },
        {
          date: '2024-06-04',
          searchVolume: 1611,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 26
        },
        {
          date: '2024-06-03',
          searchVolume: 1611,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-06-02',
          searchVolume: 1611,
          organicRank: 70,

          keywordSales: 26
        },
        {
          date: '2024-06-01',
          searchVolume: 1611,
          organicRank: 79,
          sponsoredRank: 76,
          keywordSales: 26
        },
        {
          date: '2024-05-31',
          searchVolume: 1820,
          organicRank: 72,
          sponsoredRank: 40,
          keywordSales: 26
        },
        {
          date: '2024-05-30',
          searchVolume: 1820,
          organicRank: 69,
          sponsoredRank: 90,
          keywordSales: 26
        },
        {
          date: '2024-05-29',
          searchVolume: 1820,
          organicRank: 80,
          sponsoredRank: 58,
          keywordSales: 26
        },
        {
          date: '2024-05-28',
          searchVolume: 1820,
          organicRank: 72,
          sponsoredRank: 74,
          keywordSales: 26
        },
        {
          date: '2024-05-27',
          searchVolume: 1820,
          organicRank: 76,
          sponsoredRank: 73,
          keywordSales: 26
        },
        {
          date: '2024-05-26',
          searchVolume: 1820,
          organicRank: 74,

          keywordSales: 26
        },
        {
          date: '2024-05-25',
          searchVolume: 1820,
          organicRank: 74,

          keywordSales: 26
        },
        {
          date: '2024-05-24',
          searchVolume: 1886,
          organicRank: 306,

          keywordSales: 26
        },
        {
          date: '2024-05-23',
          searchVolume: 1886,
          organicRank: 123,

          keywordSales: 26
        },
        {
          date: '2024-05-21',
          searchVolume: 1886,
          organicRank: 148,

          keywordSales: 26
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_inside cameras for house`,
      keyword: 'inside cameras for house',
      searchVolume: 4130,
      organicStartRank: 178,
      organicHighestRank: 119,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 31,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 4325,
          organicRank: 182,

          keywordSales: 168
        },
        {
          date: '2024-07-03',
          searchVolume: 4325,
          organicRank: 161,

          keywordSales: 168
        },
        {
          date: '2024-07-02',
          searchVolume: 4325,
          organicRank: 173,

          keywordSales: 168
        },
        {
          date: '2024-07-01',
          searchVolume: 4325,
          organicRank: 154,

          keywordSales: 168
        },
        {
          date: '2024-06-30',
          searchVolume: 4325,
          organicRank: 168,

          keywordSales: 168
        },
        {
          date: '2024-06-29',
          searchVolume: 4325,
          organicRank: 168,

          keywordSales: 168
        },
        {
          date: '2024-06-28',
          searchVolume: 4224,
          organicRank: 171,

          keywordSales: 168
        },
        {
          date: '2024-06-27',
          searchVolume: 4224,
          organicRank: 168,

          keywordSales: 168
        },
        {
          date: '2024-06-26',
          searchVolume: 4224,
          organicRank: 189,

          keywordSales: 168
        },
        {
          date: '2024-06-25',
          searchVolume: 4224,
          organicRank: 164,

          keywordSales: 168
        },
        {
          date: '2024-06-23',
          searchVolume: 4224,
          organicRank: 167,

          keywordSales: 168
        },
        {
          date: '2024-06-21',
          searchVolume: 4324,
          organicRank: 153,

          keywordSales: 168
        },
        {
          date: '2024-06-20',
          searchVolume: 4324,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-06-19',
          searchVolume: 4324,
          organicRank: 168,

          keywordSales: 168
        },
        {
          date: '2024-06-18',
          searchVolume: 4324,
          organicRank: 165,

          keywordSales: 168
        },
        {
          date: '2024-06-17',
          searchVolume: 4324,
          organicRank: 170,

          keywordSales: 168
        },
        {
          date: '2024-06-16',
          searchVolume: 4324,
          organicRank: 179,

          keywordSales: 168
        },
        {
          date: '2024-06-15',
          searchVolume: 4324,
          organicRank: 182,

          keywordSales: 168
        },
        {
          date: '2024-06-14',
          searchVolume: 4552,
          organicRank: 171,

          keywordSales: 168
        },
        {
          date: '2024-06-13',
          searchVolume: 4552,
          organicRank: 170,

          keywordSales: 168
        },
        {
          date: '2024-06-12',
          searchVolume: 4552,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-06-11',
          searchVolume: 4552,
          organicRank: 161,

          keywordSales: 168
        },
        {
          date: '2024-06-10',
          searchVolume: 4552,
          organicRank: 172,

          keywordSales: 168
        },
        {
          date: '2024-06-09',
          searchVolume: 4552,
          organicRank: 184,
          sponsoredRank: 31,
          keywordSales: 168
        },
        {
          date: '2024-06-08',
          searchVolume: 4552,
          organicRank: 171,

          keywordSales: 168
        },
        {
          date: '2024-06-07',
          searchVolume: 4130,
          organicRank: 174,

          keywordSales: 168
        },
        {
          date: '2024-06-06',
          searchVolume: 4130,
          organicRank: 177,

          keywordSales: 168
        },
        {
          date: '2024-06-05',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-06-04',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-06-03',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-06-02',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-06-01',
          searchVolume: 4130,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-05-31',
          searchVolume: 4830,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-05-30',
          searchVolume: 4830,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-05-29',
          searchVolume: 4830,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-05-28',
          searchVolume: 4830,
          organicRank: 306,

          keywordSales: 168
        },
        {
          date: '2024-05-27',
          searchVolume: 4830,
          organicRank: 133,

          keywordSales: 168
        },
        {
          date: '2024-05-26',
          searchVolume: 4830,
          organicRank: 128,

          keywordSales: 168
        },
        {
          date: '2024-05-25',
          searchVolume: 4830,
          organicRank: 119,

          keywordSales: 168
        },
        {
          date: '2024-05-24',
          searchVolume: 4837,
          organicRank: 237,

          keywordSales: 168
        },
        {
          date: '2024-05-23',
          searchVolume: 4837,
          organicRank: 177,

          keywordSales: 168
        },
        {
          date: '2024-05-22',
          searchVolume: 4837,
          organicRank: 178,

          keywordSales: 168
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_no wifi security camera`,
      keyword: 'no wifi security camera',
      searchVolume: 6655,
      organicStartRank: 166,
      organicHighestRank: 81,
      sponsoredStartRank: 65,
      sponsoredHighestRank: 10,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 9026,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-07-02',
          searchVolume: 9026,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-07-01',
          searchVolume: 9026,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-06-30',
          searchVolume: 9026,
          organicRank: 108,

          keywordSales: 115
        },
        {
          date: '2024-06-29',
          searchVolume: 9026,
          organicRank: 115,

          keywordSales: 115
        },
        {
          date: '2024-06-28',
          searchVolume: 7267,
          organicRank: 112,

          keywordSales: 115
        },
        {
          date: '2024-06-27',
          searchVolume: 7267,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 115
        },
        {
          date: '2024-06-26',
          searchVolume: 7267,
          organicRank: 107,

          keywordSales: 115
        },
        {
          date: '2024-06-25',
          searchVolume: 7267,
          organicRank: 81,

          keywordSales: 115
        },
        {
          date: '2024-06-23',
          searchVolume: 7267,
          organicRank: 96,

          keywordSales: 115
        },
        {
          date: '2024-06-21',
          searchVolume: 6749,
          organicRank: 83,

          keywordSales: 115
        },
        {
          date: '2024-06-20',
          searchVolume: 6749,
          organicRank: 115,

          keywordSales: 115
        },
        {
          date: '2024-06-19',
          searchVolume: 6749,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-06-18',
          searchVolume: 6749,
          organicRank: 102,

          keywordSales: 115
        },
        {
          date: '2024-06-17',
          searchVolume: 6749,
          organicRank: 89,

          keywordSales: 115
        },
        {
          date: '2024-06-16',
          searchVolume: 6749,
          organicRank: 108,

          keywordSales: 115
        },
        {
          date: '2024-06-15',
          searchVolume: 6749,
          organicRank: 280,

          keywordSales: 115
        },
        {
          date: '2024-06-14',
          searchVolume: 6753,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-06-13',
          searchVolume: 6753,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-06-12',
          searchVolume: 6753,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-06-11',
          searchVolume: 6753,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-06-10',
          searchVolume: 6753,
          organicRank: 104,

          keywordSales: 115
        },
        {
          date: '2024-06-09',
          searchVolume: 6753,
          organicRank: 306,
          sponsoredRank: 53,
          keywordSales: 115
        },
        {
          date: '2024-06-08',
          searchVolume: 6753,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-06-07',
          searchVolume: 6441,
          organicRank: 253,

          keywordSales: 115
        },
        {
          date: '2024-06-06',
          searchVolume: 6441,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-06-05',
          searchVolume: 6441,
          organicRank: 306,

          keywordSales: 115
        },
        {
          date: '2024-06-04',
          searchVolume: 6441,
          organicRank: 138,

          keywordSales: 115
        },
        {
          date: '2024-06-03',
          searchVolume: 6441,
          organicRank: 104,

          keywordSales: 115
        },
        {
          date: '2024-06-02',
          searchVolume: 6441,
          organicRank: 111,

          keywordSales: 115
        },
        {
          date: '2024-06-01',
          searchVolume: 6441,
          organicRank: 258,

          keywordSales: 115
        },
        {
          date: '2024-05-31',
          searchVolume: 7554,
          organicRank: 145,

          keywordSales: 115
        },
        {
          date: '2024-05-30',
          searchVolume: 7554,
          organicRank: 130,

          keywordSales: 115
        },
        {
          date: '2024-05-29',
          searchVolume: 7554,
          organicRank: 116,

          keywordSales: 115
        },
        {
          date: '2024-05-28',
          searchVolume: 7554,
          organicRank: 127,

          keywordSales: 115
        },
        {
          date: '2024-05-27',
          searchVolume: 7554,
          organicRank: 197,

          keywordSales: 115
        },
        {
          date: '2024-05-26',
          searchVolume: 7554,
          organicRank: 114,
          sponsoredRank: 10,
          keywordSales: 115
        },
        {
          date: '2024-05-25',
          searchVolume: 7554,
          organicRank: 126,

          keywordSales: 115
        },
        {
          date: '2024-05-24',
          searchVolume: 6655,
          organicRank: 132,
          sponsoredRank: 81,
          keywordSales: 115
        },
        {
          date: '2024-05-23',
          searchVolume: 6655,
          organicRank: 182,

          keywordSales: 115
        },
        {
          date: '2024-05-22',
          searchVolume: 6655,
          organicRank: 166,
          sponsoredRank: 65,
          keywordSales: 115
        },
        {
          date: '2024-05-21',
          searchVolume: 6655,
          organicRank: 185,
          sponsoredRank: 78,
          keywordSales: 115
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_non wifi security cameras`,
      keyword: 'non wifi security cameras',
      searchVolume: 1889,
      organicStartRank: 124,
      organicHighestRank: 53,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 489,
          organicRank: 137
        },
        {
          date: '2024-07-02',
          searchVolume: 489,
          organicRank: 148
        },
        {
          date: '2024-07-01',
          searchVolume: 489,
          organicRank: 141
        },
        {
          date: '2024-06-30',
          searchVolume: 489,
          organicRank: 121
        },
        {
          date: '2024-06-29',
          searchVolume: 489,
          organicRank: 121
        },
        {
          date: '2024-06-28',
          searchVolume: 1428,
          organicRank: 109
        },
        {
          date: '2024-06-27',
          searchVolume: 1428,
          organicRank: 120
        },
        {
          date: '2024-06-26',
          searchVolume: 1428,
          organicRank: 111
        },
        {
          date: '2024-06-25',
          searchVolume: 1428,
          organicRank: 93
        },
        {
          date: '2024-06-24',
          searchVolume: 1428,
          organicRank: 113
        },
        {
          date: '2024-06-22',
          searchVolume: 1428,
          organicRank: 115
        },
        {
          date: '2024-06-21',
          searchVolume: 951,
          organicRank: 59
        },
        {
          date: '2024-06-20',
          searchVolume: 951,
          organicRank: 78
        },
        {
          date: '2024-06-19',
          searchVolume: 951,
          organicRank: 69
        },
        {
          date: '2024-06-18',
          searchVolume: 951,
          organicRank: 113
        },
        {
          date: '2024-06-17',
          searchVolume: 951,
          organicRank: 91
        },
        {
          date: '2024-06-16',
          searchVolume: 951,
          organicRank: 115
        },
        {
          date: '2024-06-15',
          searchVolume: 951,
          organicRank: 104
        },
        {
          date: '2024-06-14',
          searchVolume: 1430,
          organicRank: 95
        },
        {
          date: '2024-06-13',
          searchVolume: 1430,
          organicRank: 109
        },
        {
          date: '2024-06-12',
          searchVolume: 1430,
          organicRank: 102
        },
        {
          date: '2024-06-11',
          searchVolume: 1430,
          organicRank: 260
        },
        {
          date: '2024-06-10',
          searchVolume: 1430,
          organicRank: 109
        },
        {
          date: '2024-06-09',
          searchVolume: 1430,
          organicRank: 103
        },
        {
          date: '2024-06-08',
          searchVolume: 1430,
          organicRank: 100
        },
        {
          date: '2024-06-07',
          searchVolume: 1338,
          organicRank: 99
        },
        {
          date: '2024-06-06',
          searchVolume: 1338,
          organicRank: 95,
          sponsoredRank: 2
        },
        {
          date: '2024-06-05',
          searchVolume: 1338,
          organicRank: 90,
          sponsoredRank: 1
        },
        {
          date: '2024-06-04',
          searchVolume: 1338,
          organicRank: 83,
          sponsoredRank: 42
        },
        {
          date: '2024-06-03',
          searchVolume: 1338,
          organicRank: 61
        },
        {
          date: '2024-06-02',
          searchVolume: 1338,
          organicRank: 67
        },
        {
          date: '2024-06-01',
          searchVolume: 1338,
          organicRank: 84,
          sponsoredRank: 23
        },
        {
          date: '2024-05-31',
          searchVolume: 1387,
          organicRank: 85,
          sponsoredRank: 2
        },
        {
          date: '2024-05-30',
          searchVolume: 1387,
          organicRank: 61,
          sponsoredRank: 2
        },
        {
          date: '2024-05-29',
          searchVolume: 1387,
          organicRank: 54,
          sponsoredRank: 2
        },
        {
          date: '2024-05-28',
          searchVolume: 1387,
          organicRank: 60,
          sponsoredRank: 2
        },
        {
          date: '2024-05-27',
          searchVolume: 1387,
          organicRank: 53,
          sponsoredRank: 2
        },
        {
          date: '2024-05-26',
          searchVolume: 1387,
          organicRank: 76
        },
        {
          date: '2024-05-25',
          searchVolume: 1387,
          organicRank: 62
        },
        {
          date: '2024-05-24',
          searchVolume: 1889,
          organicRank: 91
        },
        {
          date: '2024-05-21',
          searchVolume: 1889,
          organicRank: 124
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor camera`,
      keyword: 'outdoor camera',
      searchVolume: 37283,
      organicStartRank: 93,
      organicHighestRank: 28,
      sponsoredStartRank: 25,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 37899,
          organicRank: 28,
          sponsoredRank: 36,
          keywordSales: 504
        },
        {
          date: '2024-07-03',
          searchVolume: 37899,
          organicRank: 29,
          sponsoredRank: 48,
          keywordSales: 504
        },
        {
          date: '2024-07-02',
          searchVolume: 37899,
          organicRank: 31,
          sponsoredRank: 45,
          keywordSales: 504
        },
        {
          date: '2024-07-01',
          searchVolume: 37899,
          organicRank: 35,

          keywordSales: 504
        },
        {
          date: '2024-06-30',
          searchVolume: 37899,
          organicRank: 35,

          keywordSales: 504
        },
        {
          date: '2024-06-29',
          searchVolume: 37899,
          organicRank: 82,
          sponsoredRank: 13,
          keywordSales: 504
        },
        {
          date: '2024-06-28',
          searchVolume: 37746,
          organicRank: 66,
          sponsoredRank: 23,
          keywordSales: 504
        },
        {
          date: '2024-06-27',
          searchVolume: 37746,
          organicRank: 59,
          sponsoredRank: 22,
          keywordSales: 504
        },
        {
          date: '2024-06-26',
          searchVolume: 37746,
          organicRank: 73,
          sponsoredRank: 45,
          keywordSales: 504
        },
        {
          date: '2024-06-25',
          searchVolume: 37746,
          organicRank: 71,
          sponsoredRank: 11,
          keywordSales: 504
        },
        {
          date: '2024-06-24',
          searchVolume: 37746,
          organicRank: 86,
          sponsoredRank: 71,
          keywordSales: 504
        },
        {
          date: '2024-06-23',
          searchVolume: 37746,
          organicRank: 81,
          sponsoredRank: 18,
          keywordSales: 504
        },
        {
          date: '2024-06-22',
          searchVolume: 37746,
          organicRank: 75,
          sponsoredRank: 16,
          keywordSales: 504
        },
        {
          date: '2024-06-21',
          searchVolume: 37862,
          organicRank: 80,

          keywordSales: 504
        },
        {
          date: '2024-06-20',
          searchVolume: 37862,
          organicRank: 79,
          sponsoredRank: 49,
          keywordSales: 504
        },
        {
          date: '2024-06-19',
          searchVolume: 37862,
          organicRank: 122,
          sponsoredRank: 54,
          keywordSales: 504
        },
        {
          date: '2024-06-18',
          searchVolume: 37862,
          organicRank: 130,
          sponsoredRank: 63,
          keywordSales: 504
        },
        {
          date: '2024-06-17',
          searchVolume: 37862,
          organicRank: 110,

          keywordSales: 504
        },
        {
          date: '2024-06-16',
          searchVolume: 37862,
          organicRank: 130,

          keywordSales: 504
        },
        {
          date: '2024-06-15',
          searchVolume: 37862,
          organicRank: 124,
          sponsoredRank: 33,
          keywordSales: 504
        },
        {
          date: '2024-06-14',
          searchVolume: 37862,
          organicRank: 120,
          sponsoredRank: 19,
          keywordSales: 504
        },
        {
          date: '2024-06-13',
          searchVolume: 37862,
          organicRank: 264,
          sponsoredRank: 37,
          keywordSales: 504
        },
        {
          date: '2024-06-12',
          searchVolume: 37862,
          organicRank: 306,

          keywordSales: 504
        },
        {
          date: '2024-06-11',
          searchVolume: 37862,
          organicRank: 306,

          keywordSales: 504
        },
        {
          date: '2024-06-10',
          searchVolume: 37862,
          organicRank: 117,
          sponsoredRank: 66,
          keywordSales: 504
        },
        {
          date: '2024-06-09',
          searchVolume: 37862,
          organicRank: 107,
          sponsoredRank: 80,
          keywordSales: 504
        },
        {
          date: '2024-06-08',
          searchVolume: 37862,
          organicRank: 243,

          keywordSales: 504
        },
        {
          date: '2024-06-07',
          searchVolume: 36115,
          organicRank: 118,

          keywordSales: 504
        },
        {
          date: '2024-06-06',
          searchVolume: 36115,
          organicRank: 110,
          sponsoredRank: 91,
          keywordSales: 504
        },
        {
          date: '2024-06-05',
          searchVolume: 36115,
          organicRank: 306,
          sponsoredRank: 88,
          keywordSales: 504
        },
        {
          date: '2024-06-04',
          searchVolume: 36115,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 504
        },
        {
          date: '2024-06-03',
          searchVolume: 36115,
          organicRank: 84,

          keywordSales: 504
        },
        {
          date: '2024-06-02',
          searchVolume: 36115,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 504
        },
        {
          date: '2024-06-01',
          searchVolume: 36115,
          organicRank: 81,
          sponsoredRank: 59,
          keywordSales: 504
        },
        {
          date: '2024-05-31',
          searchVolume: 33796,
          organicRank: 96,
          sponsoredRank: 22,
          keywordSales: 504
        },
        {
          date: '2024-05-30',
          searchVolume: 33796,
          organicRank: 89,
          sponsoredRank: 2,
          keywordSales: 504
        },
        {
          date: '2024-05-29',
          searchVolume: 33796,
          organicRank: 88,
          sponsoredRank: 2,
          keywordSales: 504
        },
        {
          date: '2024-05-28',
          searchVolume: 33796,
          organicRank: 306,
          sponsoredRank: 2,
          keywordSales: 504
        },
        {
          date: '2024-05-27',
          searchVolume: 33796,
          organicRank: 85,
          sponsoredRank: 41,
          keywordSales: 504
        },
        {
          date: '2024-05-26',
          searchVolume: 33796,
          organicRank: 97,
          sponsoredRank: 9,
          keywordSales: 504
        },
        {
          date: '2024-05-25',
          searchVolume: 33796,
          organicRank: 306,
          sponsoredRank: 87,
          keywordSales: 504
        },
        {
          date: '2024-05-24',
          searchVolume: 37283,
          organicRank: 87,

          keywordSales: 504
        },
        {
          date: '2024-05-23',
          searchVolume: 37283,
          organicRank: 95,
          sponsoredRank: 24,
          keywordSales: 504
        },
        {
          date: '2024-05-22',
          searchVolume: 37283,
          organicRank: 93,
          sponsoredRank: 25,
          keywordSales: 504
        },
        {
          date: '2024-05-21',
          searchVolume: 37283,
          organicRank: 94,
          sponsoredRank: 1,
          keywordSales: 504
        },
        {
          date: '2024-05-20',
          searchVolume: 37283,
          organicRank: 88,
          sponsoredRank: 66,
          keywordSales: 504
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor camera wireless`,
      keyword: 'outdoor camera wireless',
      searchVolume: 62925,
      organicStartRank: 101,
      organicHighestRank: 6,
      sponsoredStartRank: 22,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-06-01',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 71899,
          organicRank: 84,

          keywordSales: 1621
        },
        {
          date: '2024-07-02',
          searchVolume: 71899,
          organicRank: 78,
          sponsoredRank: 26,
          keywordSales: 1621
        },
        {
          date: '2024-07-01',
          searchVolume: 71899,
          organicRank: 124,

          keywordSales: 1621
        },
        {
          date: '2024-06-30',
          searchVolume: 71899,
          organicRank: 72,

          keywordSales: 1621
        },
        {
          date: '2024-06-29',
          searchVolume: 71899,
          organicRank: 37,

          keywordSales: 1621
        },
        {
          date: '2024-06-28',
          searchVolume: 63690,
          organicRank: 24,
          sponsoredRank: 54,
          keywordSales: 1621
        },
        {
          date: '2024-06-27',
          searchVolume: 63690,
          organicRank: 13,
          sponsoredRank: 4,
          keywordSales: 1621
        },
        {
          date: '2024-06-26',
          searchVolume: 63690,
          organicRank: 19,
          sponsoredRank: 21,
          keywordSales: 1621
        },
        {
          date: '2024-06-25',
          searchVolume: 63690,
          organicRank: 14,

          keywordSales: 1621
        },
        {
          date: '2024-06-24',
          searchVolume: 63690,
          organicRank: 14,

          keywordSales: 1621
        },
        {
          date: '2024-06-23',
          searchVolume: 63690,
          organicRank: 15,
          sponsoredRank: 18,
          keywordSales: 1621
        },
        {
          date: '2024-06-22',
          searchVolume: 63690,
          organicRank: 10,
          sponsoredRank: 18,
          keywordSales: 1621
        },
        {
          date: '2024-06-21',
          searchVolume: 64599,
          organicRank: 12,
          sponsoredRank: 12,
          keywordSales: 1621
        },
        {
          date: '2024-06-20',
          searchVolume: 64599,
          organicRank: 9,
          sponsoredRank: 59,
          keywordSales: 1621
        },
        {
          date: '2024-06-19',
          searchVolume: 64599,
          organicRank: 12,
          sponsoredRank: 15,
          keywordSales: 1621
        },
        {
          date: '2024-06-18',
          searchVolume: 64599,
          organicRank: 12,
          sponsoredRank: 53,
          keywordSales: 1621
        },
        {
          date: '2024-06-17',
          searchVolume: 64599,
          organicRank: 13,
          sponsoredRank: 38,
          keywordSales: 1621
        },
        {
          date: '2024-06-16',
          searchVolume: 64599,
          organicRank: 16,

          keywordSales: 1621
        },
        {
          date: '2024-06-15',
          searchVolume: 64599,
          organicRank: 19,
          sponsoredRank: 11,
          keywordSales: 1621
        },
        {
          date: '2024-06-14',
          searchVolume: 66209,
          organicRank: 32,
          sponsoredRank: 17,
          keywordSales: 1621
        },
        {
          date: '2024-06-13',
          searchVolume: 66209,
          organicRank: 32,
          sponsoredRank: 32,
          keywordSales: 1621
        },
        {
          date: '2024-06-12',
          searchVolume: 66209,
          organicRank: 32,
          sponsoredRank: 33,
          keywordSales: 1621
        },
        {
          date: '2024-06-11',
          searchVolume: 66209,
          organicRank: 32,
          sponsoredRank: 54,
          keywordSales: 1621
        },
        {
          date: '2024-06-10',
          searchVolume: 66209,
          organicRank: 10,

          keywordSales: 1621
        },
        {
          date: '2024-06-09',
          searchVolume: 66209,
          organicRank: 13,
          sponsoredRank: 51,
          keywordSales: 1621
        },
        {
          date: '2024-06-08',
          searchVolume: 66209,
          organicRank: 14,

          keywordSales: 1621
        },
        {
          date: '2024-06-07',
          searchVolume: 61051,
          organicRank: 13,
          sponsoredRank: 10,
          keywordSales: 1621
        },
        {
          date: '2024-06-06',
          searchVolume: 61051,
          organicRank: 10,
          sponsoredRank: 14,
          keywordSales: 1621
        },
        {
          date: '2024-06-05',
          searchVolume: 61051,
          organicRank: 9,
          sponsoredRank: 39,
          keywordSales: 1621
        },
        {
          date: '2024-06-04',
          searchVolume: 61051,
          organicRank: 7,
          sponsoredRank: 9,
          keywordSales: 1621
        },
        {
          date: '2024-06-03',
          searchVolume: 61051,
          organicRank: 8,
          sponsoredRank: 32,
          keywordSales: 1621
        },
        {
          date: '2024-06-02',
          searchVolume: 61051,
          organicRank: 6,
          sponsoredRank: 54,
          keywordSales: 1621
        },
        {
          date: '2024-06-01',
          searchVolume: 61051,
          organicRank: 18,
          sponsoredRank: 83,
          keywordSales: 1621
        },
        {
          date: '2024-05-31',
          searchVolume: 62909,
          organicRank: 77,
          sponsoredRank: 10,
          keywordSales: 1621
        },
        {
          date: '2024-05-30',
          searchVolume: 62909,
          organicRank: 78,
          sponsoredRank: 55,
          keywordSales: 1621
        },
        {
          date: '2024-05-29',
          searchVolume: 62909,
          organicRank: 91,
          sponsoredRank: 69,
          keywordSales: 1621
        },
        {
          date: '2024-05-28',
          searchVolume: 62909,
          organicRank: 90,
          sponsoredRank: 2,
          keywordSales: 1621
        },
        {
          date: '2024-05-27',
          searchVolume: 62909,
          organicRank: 87,
          sponsoredRank: 74,
          keywordSales: 1621
        },
        {
          date: '2024-05-26',
          searchVolume: 62909,
          organicRank: 80,
          sponsoredRank: 12,
          keywordSales: 1621
        },
        {
          date: '2024-05-25',
          searchVolume: 62909,
          organicRank: 84,
          sponsoredRank: 66,
          keywordSales: 1621
        },
        {
          date: '2024-05-24',
          searchVolume: 62925,
          organicRank: 102,
          sponsoredRank: 41,
          keywordSales: 1621
        },
        {
          date: '2024-05-23',
          searchVolume: 62925,
          organicRank: 102,
          sponsoredRank: 12,
          keywordSales: 1621
        },
        {
          date: '2024-05-22',
          searchVolume: 62925,
          organicRank: 101,
          sponsoredRank: 22,
          keywordSales: 1621
        },
        {
          date: '2024-05-21',
          searchVolume: 62925,
          organicRank: 96,
          sponsoredRank: 11,
          keywordSales: 1621
        },
        {
          date: '2024-05-20',
          searchVolume: 62925,
          organicRank: 84,

          keywordSales: 1621
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor camera wireless no subscription`,
      keyword: 'outdoor camera wireless no subscription',
      searchVolume: 1841,
      organicStartRank: 126,
      organicHighestRank: 21,
      sponsoredStartRank: 33,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-15',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1922,
          organicRank: 47
        },
        {
          date: '2024-07-02',
          searchVolume: 1922,
          organicRank: 48,
          sponsoredRank: 12
        },
        {
          date: '2024-07-01',
          searchVolume: 1922,
          organicRank: 55
        },
        {
          date: '2024-06-30',
          searchVolume: 1922,
          organicRank: 43
        },
        {
          date: '2024-06-29',
          searchVolume: 1922,
          organicRank: 45
        },
        {
          date: '2024-06-28',
          searchVolume: 1911,
          organicRank: 32,
          sponsoredRank: 64
        },
        {
          date: '2024-06-27',
          searchVolume: 1911,
          organicRank: 27,
          sponsoredRank: 20
        },
        {
          date: '2024-06-26',
          searchVolume: 1911,
          organicRank: 29
        },
        {
          date: '2024-06-25',
          searchVolume: 1911,
          organicRank: 33
        },
        {
          date: '2024-06-24',
          searchVolume: 1911,
          organicRank: 35,
          sponsoredRank: 11
        },
        {
          date: '2024-06-22',
          searchVolume: 1911,
          organicRank: 37
        },
        {
          date: '2024-06-21',
          searchVolume: 1930,
          organicRank: 21,
          sponsoredRank: 11
        },
        {
          date: '2024-06-20',
          searchVolume: 1930,
          organicRank: 32,
          sponsoredRank: 18
        },
        {
          date: '2024-06-19',
          searchVolume: 1930,
          organicRank: 41,
          sponsoredRank: 96
        },
        {
          date: '2024-06-18',
          searchVolume: 1930,
          organicRank: 41
        },
        {
          date: '2024-06-17',
          searchVolume: 1930,
          organicRank: 47
        },
        {
          date: '2024-06-16',
          searchVolume: 1930,
          organicRank: 46
        },
        {
          date: '2024-06-15',
          searchVolume: 1930,
          organicRank: 48,
          sponsoredRank: 18
        },
        {
          date: '2024-06-14',
          searchVolume: 1921,
          organicRank: 47,
          sponsoredRank: 75
        },
        {
          date: '2024-06-13',
          searchVolume: 1921,
          organicRank: 306,
          sponsoredRank: 69
        },
        {
          date: '2024-06-12',
          searchVolume: 1921,
          organicRank: 113
        },
        {
          date: '2024-06-11',
          searchVolume: 1921,
          organicRank: 46
        },
        {
          date: '2024-06-10',
          searchVolume: 1921,
          organicRank: 43
        },
        {
          date: '2024-06-09',
          searchVolume: 1921,
          organicRank: 48
        },
        {
          date: '2024-06-08',
          searchVolume: 1921,
          organicRank: 119
        },
        {
          date: '2024-06-07',
          searchVolume: 1841,
          organicRank: 124
        },
        {
          date: '2024-06-06',
          searchVolume: 1841,
          organicRank: 135
        },
        {
          date: '2024-06-05',
          searchVolume: 1841,
          organicRank: 306,
          sponsoredRank: 33
        },
        {
          date: '2024-06-04',
          searchVolume: 1841,
          organicRank: 306
        },
        {
          date: '2024-06-03',
          searchVolume: 1841,
          organicRank: 119
        },
        {
          date: '2024-06-02',
          searchVolume: 1841,
          organicRank: 246
        },
        {
          date: '2024-06-01',
          searchVolume: 1841,
          organicRank: 97
        },
        {
          date: '2024-05-31',
          searchVolume: 1897,
          organicRank: 306
        },
        {
          date: '2024-05-30',
          searchVolume: 1897,
          organicRank: 99
        },
        {
          date: '2024-05-29',
          searchVolume: 1897,
          organicRank: 101
        },
        {
          date: '2024-05-28',
          searchVolume: 1897,
          organicRank: 100
        },
        {
          date: '2024-05-27',
          searchVolume: 1897,
          organicRank: 104
        },
        {
          date: '2024-05-26',
          searchVolume: 1897,
          organicRank: 101
        },
        {
          date: '2024-05-25',
          searchVolume: 1897,
          organicRank: 105
        },
        {
          date: '2024-05-24',
          searchVolume: 1886,
          organicRank: 113
        },
        {
          date: '2024-05-22',
          searchVolume: 1886,
          organicRank: 126
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor cameras`,
      keyword: 'outdoor cameras',
      searchVolume: 4835,
      organicStartRank: 90,
      organicHighestRank: 81,
      sponsoredStartRank: 66,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 5693,
          organicRank: 247,

          keywordSales: 31
        },
        {
          date: '2024-07-02',
          searchVolume: 5693,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-07-01',
          searchVolume: 5693,
          organicRank: 119,

          keywordSales: 31
        },
        {
          date: '2024-06-30',
          searchVolume: 5693,
          organicRank: 87,

          keywordSales: 31
        },
        {
          date: '2024-06-29',
          searchVolume: 5693,
          organicRank: 85,
          sponsoredRank: 48,
          keywordSales: 31
        },
        {
          date: '2024-06-28',
          searchVolume: 5123,
          organicRank: 89,
          sponsoredRank: 34,
          keywordSales: 31
        },
        {
          date: '2024-06-27',
          searchVolume: 5123,
          organicRank: 84,
          sponsoredRank: 9,
          keywordSales: 31
        },
        {
          date: '2024-06-26',
          searchVolume: 5123,
          organicRank: 81,

          keywordSales: 31
        },
        {
          date: '2024-06-25',
          searchVolume: 5123,
          organicRank: 108,

          keywordSales: 31
        },
        {
          date: '2024-06-24',
          searchVolume: 5123,
          organicRank: 99,

          keywordSales: 31
        },
        {
          date: '2024-06-22',
          searchVolume: 5123,
          organicRank: 103,

          keywordSales: 31
        },
        {
          date: '2024-06-21',
          searchVolume: 4916,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-20',
          searchVolume: 4916,
          organicRank: 101,

          keywordSales: 31
        },
        {
          date: '2024-06-19',
          searchVolume: 4916,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-18',
          searchVolume: 4916,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-17',
          searchVolume: 4916,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-16',
          searchVolume: 4916,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-15',
          searchVolume: 4916,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-14',
          searchVolume: 5693,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-13',
          searchVolume: 5693,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-12',
          searchVolume: 5693,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-11',
          searchVolume: 5693,
          organicRank: 109,

          keywordSales: 31
        },
        {
          date: '2024-06-10',
          searchVolume: 5693,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-09',
          searchVolume: 5693,
          organicRank: 254,
          sponsoredRank: 23,
          keywordSales: 31
        },
        {
          date: '2024-06-08',
          searchVolume: 5693,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-07',
          searchVolume: 5173,
          organicRank: 122,

          keywordSales: 31
        },
        {
          date: '2024-06-06',
          searchVolume: 5173,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-05',
          searchVolume: 5173,
          organicRank: 306,
          sponsoredRank: 45,
          keywordSales: 31
        },
        {
          date: '2024-06-04',
          searchVolume: 5173,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 31
        },
        {
          date: '2024-06-03',
          searchVolume: 5173,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-02',
          searchVolume: 5173,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-06-01',
          searchVolume: 5173,
          organicRank: 97,
          sponsoredRank: 53,
          keywordSales: 31
        },
        {
          date: '2024-05-31',
          searchVolume: 5060,
          organicRank: 102,
          sponsoredRank: 33,
          keywordSales: 31
        },
        {
          date: '2024-05-30',
          searchVolume: 5060,
          organicRank: 93,
          sponsoredRank: 70,
          keywordSales: 31
        },
        {
          date: '2024-05-29',
          searchVolume: 5060,
          organicRank: 94,
          sponsoredRank: 48,
          keywordSales: 31
        },
        {
          date: '2024-05-28',
          searchVolume: 5060,
          organicRank: 91,
          sponsoredRank: 35,
          keywordSales: 31
        },
        {
          date: '2024-05-27',
          searchVolume: 5060,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 31
        },
        {
          date: '2024-05-26',
          searchVolume: 5060,
          organicRank: 90,
          sponsoredRank: 8,
          keywordSales: 31
        },
        {
          date: '2024-05-25',
          searchVolume: 5060,
          organicRank: 89,
          sponsoredRank: 21,
          keywordSales: 31
        },
        {
          date: '2024-05-24',
          searchVolume: 4835,
          organicRank: 306,

          keywordSales: 31
        },
        {
          date: '2024-05-23',
          searchVolume: 4835,
          organicRank: 88,

          keywordSales: 31
        },
        {
          date: '2024-05-22',
          searchVolume: 4835,
          organicRank: 90,
          sponsoredRank: 66,
          keywordSales: 31
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor cameras for home security`,
      keyword: 'outdoor cameras for home security',
      searchVolume: 11559,
      organicStartRank: 90,
      organicHighestRank: 46,
      sponsoredStartRank: 20,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 11771,
          organicRank: 104,
          sponsoredRank: 77,
          keywordSales: 69
        },
        {
          date: '2024-07-02',
          searchVolume: 11771,
          organicRank: 103,
          sponsoredRank: 79,
          keywordSales: 69
        },
        {
          date: '2024-07-01',
          searchVolume: 11771,
          organicRank: 112,

          keywordSales: 69
        },
        {
          date: '2024-06-30',
          searchVolume: 11771,
          organicRank: 78,

          keywordSales: 69
        },
        {
          date: '2024-06-29',
          searchVolume: 11771,
          organicRank: 81,

          keywordSales: 69
        },
        {
          date: '2024-06-28',
          searchVolume: 11037,
          organicRank: 46,
          sponsoredRank: 41,
          keywordSales: 69
        },
        {
          date: '2024-06-27',
          searchVolume: 11037,
          organicRank: 47,
          sponsoredRank: 5,
          keywordSales: 69
        },
        {
          date: '2024-06-26',
          searchVolume: 11037,
          organicRank: 71,
          sponsoredRank: 30,
          keywordSales: 69
        },
        {
          date: '2024-06-25',
          searchVolume: 11037,
          organicRank: 88,

          keywordSales: 69
        },
        {
          date: '2024-06-24',
          searchVolume: 11037,
          organicRank: 86,

          keywordSales: 69
        },
        {
          date: '2024-06-23',
          searchVolume: 11037,
          organicRank: 68,
          sponsoredRank: 29,
          keywordSales: 69
        },
        {
          date: '2024-06-22',
          searchVolume: 11037,
          organicRank: 96,
          sponsoredRank: 16,
          keywordSales: 69
        },
        {
          date: '2024-06-21',
          searchVolume: 11095,
          organicRank: 85,
          sponsoredRank: 55,
          keywordSales: 69
        },
        {
          date: '2024-06-20',
          searchVolume: 11095,
          organicRank: 72,
          sponsoredRank: 8,
          keywordSales: 69
        },
        {
          date: '2024-06-19',
          searchVolume: 11095,
          organicRank: 87,
          sponsoredRank: 26,
          keywordSales: 69
        },
        {
          date: '2024-06-18',
          searchVolume: 11095,
          organicRank: 75,
          sponsoredRank: 93,
          keywordSales: 69
        },
        {
          date: '2024-06-17',
          searchVolume: 11095,
          organicRank: 80,

          keywordSales: 69
        },
        {
          date: '2024-06-16',
          searchVolume: 11095,
          organicRank: 92,
          sponsoredRank: 86,
          keywordSales: 69
        },
        {
          date: '2024-06-15',
          searchVolume: 11095,
          organicRank: 83,
          sponsoredRank: 45,
          keywordSales: 69
        },
        {
          date: '2024-06-14',
          searchVolume: 11084,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 69
        },
        {
          date: '2024-06-13',
          searchVolume: 11084,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 69
        },
        {
          date: '2024-06-12',
          searchVolume: 11084,
          organicRank: 306,

          keywordSales: 69
        },
        {
          date: '2024-06-11',
          searchVolume: 11084,
          organicRank: 306,

          keywordSales: 69
        },
        {
          date: '2024-06-10',
          searchVolume: 11084,
          organicRank: 87,
          sponsoredRank: 22,
          keywordSales: 69
        },
        {
          date: '2024-06-09',
          searchVolume: 11084,
          organicRank: 114,
          sponsoredRank: 81,
          keywordSales: 69
        },
        {
          date: '2024-06-08',
          searchVolume: 11084,
          organicRank: 242,
          sponsoredRank: 71,
          keywordSales: 69
        },
        {
          date: '2024-06-07',
          searchVolume: 11198,
          organicRank: 75,

          keywordSales: 69
        },
        {
          date: '2024-06-06',
          searchVolume: 11198,
          organicRank: 68,
          sponsoredRank: 51,
          keywordSales: 69
        },
        {
          date: '2024-06-05',
          searchVolume: 11198,
          organicRank: 98,
          sponsoredRank: 5,
          keywordSales: 69
        },
        {
          date: '2024-06-04',
          searchVolume: 11198,
          organicRank: 244,
          sponsoredRank: 7,
          keywordSales: 69
        },
        {
          date: '2024-06-03',
          searchVolume: 11198,
          organicRank: 119,
          sponsoredRank: 68,
          keywordSales: 69
        },
        {
          date: '2024-06-02',
          searchVolume: 11198,
          organicRank: 93,

          keywordSales: 69
        },
        {
          date: '2024-06-01',
          searchVolume: 11198,
          organicRank: 93,
          sponsoredRank: 54,
          keywordSales: 69
        },
        {
          date: '2024-05-31',
          searchVolume: 11566,
          organicRank: 72,
          sponsoredRank: 71,
          keywordSales: 69
        },
        {
          date: '2024-05-30',
          searchVolume: 11566,
          organicRank: 92,
          sponsoredRank: 76,
          keywordSales: 69
        },
        {
          date: '2024-05-29',
          searchVolume: 11566,
          organicRank: 90,
          sponsoredRank: 84,
          keywordSales: 69
        },
        {
          date: '2024-05-28',
          searchVolume: 11566,
          organicRank: 82,
          sponsoredRank: 67,
          keywordSales: 69
        },
        {
          date: '2024-05-27',
          searchVolume: 11566,
          organicRank: 87,
          sponsoredRank: 84,
          keywordSales: 69
        },
        {
          date: '2024-05-26',
          searchVolume: 11566,
          organicRank: 119,
          sponsoredRank: 45,
          keywordSales: 69
        },
        {
          date: '2024-05-25',
          searchVolume: 11566,
          organicRank: 96,
          sponsoredRank: 71,
          keywordSales: 69
        },
        {
          date: '2024-05-24',
          searchVolume: 11559,
          organicRank: 240,

          keywordSales: 69
        },
        {
          date: '2024-05-23',
          searchVolume: 11559,
          organicRank: 88,
          sponsoredRank: 32,
          keywordSales: 69
        },
        {
          date: '2024-05-22',
          searchVolume: 11559,
          organicRank: 90,
          sponsoredRank: 20,
          keywordSales: 69
        },
        {
          date: '2024-05-21',
          searchVolume: 11559,
          organicRank: 78,
          sponsoredRank: 5,
          keywordSales: 69
        },
        {
          date: '2024-05-20',
          searchVolume: 11559,
          organicRank: 83,

          keywordSales: 69
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor security camera`,
      keyword: 'outdoor security camera',
      searchVolume: 28555,
      organicStartRank: 75,
      organicHighestRank: 5,
      sponsoredStartRank: 14,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-27',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 32146,
          organicRank: 33,
          sponsoredRank: 60,
          keywordSales: 309
        },
        {
          date: '2024-07-02',
          searchVolume: 32146,
          organicRank: 35,
          sponsoredRank: 83,
          keywordSales: 309
        },
        {
          date: '2024-07-01',
          searchVolume: 32146,
          organicRank: 36,

          keywordSales: 309
        },
        {
          date: '2024-06-30',
          searchVolume: 32146,
          organicRank: 41,

          keywordSales: 309
        },
        {
          date: '2024-06-29',
          searchVolume: 32146,
          organicRank: 35,
          sponsoredRank: 41,
          keywordSales: 309
        },
        {
          date: '2024-06-28',
          searchVolume: 25932,
          organicRank: 37,
          sponsoredRank: 2,
          keywordSales: 309
        },
        {
          date: '2024-06-27',
          searchVolume: 25932,
          organicRank: 61,
          sponsoredRank: 23,
          keywordSales: 309
        },
        {
          date: '2024-06-26',
          searchVolume: 25932,
          organicRank: 65,

          keywordSales: 309
        },
        {
          date: '2024-06-25',
          searchVolume: 25932,
          organicRank: 21,
          sponsoredRank: 19,
          keywordSales: 309
        },
        {
          date: '2024-06-24',
          searchVolume: 25932,
          organicRank: 17,
          sponsoredRank: 16,
          keywordSales: 309
        },
        {
          date: '2024-06-23',
          searchVolume: 25932,
          organicRank: 20,
          sponsoredRank: 5,
          keywordSales: 309
        },
        {
          date: '2024-06-22',
          searchVolume: 25932,
          organicRank: 21,
          sponsoredRank: 10,
          keywordSales: 309
        },
        {
          date: '2024-06-21',
          searchVolume: 29002,
          organicRank: 19,

          keywordSales: 309
        },
        {
          date: '2024-06-20',
          searchVolume: 29002,
          organicRank: 20,
          sponsoredRank: 95,
          keywordSales: 309
        },
        {
          date: '2024-06-19',
          searchVolume: 29002,
          organicRank: 24,
          sponsoredRank: 12,
          keywordSales: 309
        },
        {
          date: '2024-06-18',
          searchVolume: 29002,
          organicRank: 23,
          sponsoredRank: 15,
          keywordSales: 309
        },
        {
          date: '2024-06-17',
          searchVolume: 29002,
          organicRank: 28,

          keywordSales: 309
        },
        {
          date: '2024-06-16',
          searchVolume: 29002,
          organicRank: 35,
          sponsoredRank: 24,
          keywordSales: 309
        },
        {
          date: '2024-06-15',
          searchVolume: 29002,
          organicRank: 36,
          sponsoredRank: 34,
          keywordSales: 309
        },
        {
          date: '2024-06-14',
          searchVolume: 32201,
          organicRank: 253,
          sponsoredRank: 21,
          keywordSales: 309
        },
        {
          date: '2024-06-13',
          searchVolume: 32201,
          organicRank: 114,
          sponsoredRank: 23,
          keywordSales: 309
        },
        {
          date: '2024-06-12',
          searchVolume: 32201,
          organicRank: 306,

          keywordSales: 309
        },
        {
          date: '2024-06-11',
          searchVolume: 32201,
          organicRank: 306,

          keywordSales: 309
        },
        {
          date: '2024-06-10',
          searchVolume: 32201,
          organicRank: 39,
          sponsoredRank: 13,
          keywordSales: 309
        },
        {
          date: '2024-06-09',
          searchVolume: 32201,
          organicRank: 33,
          sponsoredRank: 57,
          keywordSales: 309
        },
        {
          date: '2024-06-08',
          searchVolume: 32201,
          organicRank: 33,
          sponsoredRank: 58,
          keywordSales: 309
        },
        {
          date: '2024-06-07',
          searchVolume: 30664,
          organicRank: 33,
          sponsoredRank: 58,
          keywordSales: 309
        },
        {
          date: '2024-06-06',
          searchVolume: 30664,
          organicRank: 29,
          sponsoredRank: 17,
          keywordSales: 309
        },
        {
          date: '2024-06-05',
          searchVolume: 30664,
          organicRank: 29,
          sponsoredRank: 4,
          keywordSales: 309
        },
        {
          date: '2024-06-04',
          searchVolume: 30664,
          organicRank: 24,
          sponsoredRank: 6,
          keywordSales: 309
        },
        {
          date: '2024-06-03',
          searchVolume: 30664,
          organicRank: 33,
          sponsoredRank: 86,
          keywordSales: 309
        },
        {
          date: '2024-06-02',
          searchVolume: 30664,
          organicRank: 23,
          sponsoredRank: 32,
          keywordSales: 309
        },
        {
          date: '2024-06-01',
          searchVolume: 30664,
          organicRank: 21,
          sponsoredRank: 23,
          keywordSales: 309
        },
        {
          date: '2024-05-31',
          searchVolume: 28555,
          organicRank: 15,
          sponsoredRank: 1,
          keywordSales: 309
        },
        {
          date: '2024-05-30',
          searchVolume: 28555,
          organicRank: 15,
          sponsoredRank: 24,
          keywordSales: 309
        },
        {
          date: '2024-05-29',
          searchVolume: 28555,
          organicRank: 14,
          sponsoredRank: 111,
          keywordSales: 309
        },
        {
          date: '2024-05-28',
          searchVolume: 28555,
          organicRank: 6,
          sponsoredRank: 61,
          keywordSales: 309
        },
        {
          date: '2024-05-27',
          searchVolume: 28555,
          organicRank: 5,
          sponsoredRank: 63,
          keywordSales: 309
        },
        {
          date: '2024-05-26',
          searchVolume: 28555,
          organicRank: 40,
          sponsoredRank: 9,
          keywordSales: 309
        },
        {
          date: '2024-05-25',
          searchVolume: 28555,
          organicRank: 43,
          sponsoredRank: 54,
          keywordSales: 309
        },
        {
          date: '2024-05-24',
          searchVolume: 28555,
          organicRank: 71,
          sponsoredRank: 86,
          keywordSales: 309
        },
        {
          date: '2024-05-23',
          searchVolume: 28555,
          organicRank: 82,
          sponsoredRank: 35,
          keywordSales: 309
        },
        {
          date: '2024-05-22',
          searchVolume: 28555,
          organicRank: 75,
          sponsoredRank: 14,
          keywordSales: 309
        },
        {
          date: '2024-05-21',
          searchVolume: 28555,
          organicRank: 114,
          sponsoredRank: 11,
          keywordSales: 309
        },
        {
          date: '2024-05-20',
          searchVolume: 28555,
          organicRank: 114,
          sponsoredRank: 62,
          keywordSales: 309
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor security camera wireless`,
      keyword: 'outdoor security camera wireless',
      searchVolume: 13241,
      organicStartRank: 67,
      organicHighestRank: 17,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-05-25',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 13429,
          organicRank: 26,
          sponsoredRank: 51,
          keywordSales: 81
        },
        {
          date: '2024-07-03',
          searchVolume: 13429,
          organicRank: 53,
          sponsoredRank: 82,
          keywordSales: 81
        },
        {
          date: '2024-07-02',
          searchVolume: 13429,
          organicRank: 28,
          sponsoredRank: 75,
          keywordSales: 81
        },
        {
          date: '2024-07-01',
          searchVolume: 13429,
          organicRank: 22,

          keywordSales: 81
        },
        {
          date: '2024-06-30',
          searchVolume: 13429,
          organicRank: 30,

          keywordSales: 81
        },
        {
          date: '2024-06-29',
          searchVolume: 13429,
          organicRank: 21,

          keywordSales: 81
        },
        {
          date: '2024-06-28',
          searchVolume: 12161,
          organicRank: 36,
          sponsoredRank: 59,
          keywordSales: 81
        },
        {
          date: '2024-06-27',
          searchVolume: 12161,
          organicRank: 37,
          sponsoredRank: 52,
          keywordSales: 81
        },
        {
          date: '2024-06-26',
          searchVolume: 12161,
          organicRank: 78,

          keywordSales: 81
        },
        {
          date: '2024-06-25',
          searchVolume: 12161,
          organicRank: 94,
          sponsoredRank: 26,
          keywordSales: 81
        },
        {
          date: '2024-06-24',
          searchVolume: 12161,
          organicRank: 85,
          sponsoredRank: 60,
          keywordSales: 81
        },
        {
          date: '2024-06-23',
          searchVolume: 12161,
          organicRank: 98,
          sponsoredRank: 22,
          keywordSales: 81
        },
        {
          date: '2024-06-22',
          searchVolume: 12161,
          organicRank: 93,
          sponsoredRank: 21,
          keywordSales: 81
        },
        {
          date: '2024-06-21',
          searchVolume: 11769,
          organicRank: 72,
          sponsoredRank: 92,
          keywordSales: 81
        },
        {
          date: '2024-06-20',
          searchVolume: 11769,
          organicRank: 69,
          sponsoredRank: 90,
          keywordSales: 81
        },
        {
          date: '2024-06-19',
          searchVolume: 11769,
          organicRank: 77,
          sponsoredRank: 78,
          keywordSales: 81
        },
        {
          date: '2024-06-18',
          searchVolume: 11769,
          organicRank: 73,
          sponsoredRank: 42,
          keywordSales: 81
        },
        {
          date: '2024-06-17',
          searchVolume: 11769,
          organicRank: 306,

          keywordSales: 81
        },
        {
          date: '2024-06-16',
          searchVolume: 11769,
          organicRank: 59,
          sponsoredRank: 7,
          keywordSales: 81
        },
        {
          date: '2024-06-15',
          searchVolume: 11769,
          organicRank: 62,
          sponsoredRank: 13,
          keywordSales: 81
        },
        {
          date: '2024-06-14',
          searchVolume: 11754,
          organicRank: 73,
          sponsoredRank: 10,
          keywordSales: 81
        },
        {
          date: '2024-06-13',
          searchVolume: 11754,
          organicRank: 81,
          sponsoredRank: 58,
          keywordSales: 81
        },
        {
          date: '2024-06-12',
          searchVolume: 11754,
          organicRank: 306,

          keywordSales: 81
        },
        {
          date: '2024-06-11',
          searchVolume: 11754,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 81
        },
        {
          date: '2024-06-10',
          searchVolume: 11754,
          organicRank: 52,

          keywordSales: 81
        },
        {
          date: '2024-06-09',
          searchVolume: 11754,
          organicRank: 53,
          sponsoredRank: 54,
          keywordSales: 81
        },
        {
          date: '2024-06-08',
          searchVolume: 11754,
          organicRank: 57,

          keywordSales: 81
        },
        {
          date: '2024-06-07',
          searchVolume: 12794,
          organicRank: 54,

          keywordSales: 81
        },
        {
          date: '2024-06-06',
          searchVolume: 12794,
          organicRank: 55,
          sponsoredRank: 19,
          keywordSales: 81
        },
        {
          date: '2024-06-05',
          searchVolume: 12794,
          organicRank: 93,
          sponsoredRank: 3,
          keywordSales: 81
        },
        {
          date: '2024-06-04',
          searchVolume: 12794,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 81
        },
        {
          date: '2024-06-03',
          searchVolume: 12794,
          organicRank: 42,

          keywordSales: 81
        },
        {
          date: '2024-06-02',
          searchVolume: 12794,
          organicRank: 40,

          keywordSales: 81
        },
        {
          date: '2024-06-01',
          searchVolume: 12794,
          organicRank: 38,
          sponsoredRank: 37,
          keywordSales: 81
        },
        {
          date: '2024-05-31',
          searchVolume: 13236,
          organicRank: 29,
          sponsoredRank: 61,
          keywordSales: 81
        },
        {
          date: '2024-05-30',
          searchVolume: 13236,
          organicRank: 43,
          sponsoredRank: 71,
          keywordSales: 81
        },
        {
          date: '2024-05-29',
          searchVolume: 13236,
          organicRank: 61,
          sponsoredRank: 59,
          keywordSales: 81
        },
        {
          date: '2024-05-28',
          searchVolume: 13236,
          organicRank: 69,
          sponsoredRank: 64,
          keywordSales: 81
        },
        {
          date: '2024-05-27',
          searchVolume: 13236,
          organicRank: 63,
          sponsoredRank: 66,
          keywordSales: 81
        },
        {
          date: '2024-05-26',
          searchVolume: 13236,
          organicRank: 61,
          sponsoredRank: 45,
          keywordSales: 81
        },
        {
          date: '2024-05-25',
          searchVolume: 13236,
          organicRank: 17,
          sponsoredRank: 81,
          keywordSales: 81
        },
        {
          date: '2024-05-24',
          searchVolume: 13241,
          organicRank: 79,

          keywordSales: 81
        },
        {
          date: '2024-05-23',
          searchVolume: 13241,
          organicRank: 67,
          sponsoredRank: 21,
          keywordSales: 81
        },
        {
          date: '2024-05-22',
          searchVolume: 13241,
          organicRank: 67,
          sponsoredRank: 7,
          keywordSales: 81
        },
        {
          date: '2024-05-21',
          searchVolume: 13241,
          organicRank: 57,
          sponsoredRank: 54,
          keywordSales: 81
        },
        {
          date: '2024-05-20',
          searchVolume: 13241,
          organicRank: 79,

          keywordSales: 81
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor security cameras`,
      keyword: 'outdoor security cameras',
      searchVolume: 3563,
      organicStartRank: 86,
      organicHighestRank: 23,
      sponsoredStartRank: 51,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 3625,
          organicRank: 71,
          sponsoredRank: 1,
          keywordSales: 3
        },
        {
          date: '2024-07-02',
          searchVolume: 3625,
          organicRank: 77,
          sponsoredRank: 21,
          keywordSales: 3
        },
        {
          date: '2024-07-01',
          searchVolume: 3625,
          organicRank: 82,

          keywordSales: 3
        },
        {
          date: '2024-06-30',
          searchVolume: 3625,
          organicRank: 70,
          sponsoredRank: 24,
          keywordSales: 3
        },
        {
          date: '2024-06-29',
          searchVolume: 3625,
          organicRank: 65,

          keywordSales: 3
        },
        {
          date: '2024-06-28',
          searchVolume: 3611,
          organicRank: 62,
          sponsoredRank: 17,
          keywordSales: 3
        },
        {
          date: '2024-06-27',
          searchVolume: 3611,
          organicRank: 76,
          sponsoredRank: 64,
          keywordSales: 3
        },
        {
          date: '2024-06-26',
          searchVolume: 3611,
          organicRank: 62,
          sponsoredRank: 1,
          keywordSales: 3
        },
        {
          date: '2024-06-25',
          searchVolume: 3611,
          organicRank: 59,
          sponsoredRank: 59,
          keywordSales: 3
        },
        {
          date: '2024-06-24',
          searchVolume: 3611,
          organicRank: 55,
          sponsoredRank: 16,
          keywordSales: 3
        },
        {
          date: '2024-06-23',
          searchVolume: 3611,
          organicRank: 53,
          sponsoredRank: 2,
          keywordSales: 3
        },
        {
          date: '2024-06-22',
          searchVolume: 3611,
          organicRank: 61,
          sponsoredRank: 4,
          keywordSales: 3
        },
        {
          date: '2024-06-21',
          searchVolume: 3427,
          organicRank: 56,
          sponsoredRank: 50,
          keywordSales: 3
        },
        {
          date: '2024-06-20',
          searchVolume: 3427,
          organicRank: 57,
          sponsoredRank: 24,
          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 3427,
          organicRank: 100,
          sponsoredRank: 11,
          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 3427,
          organicRank: 96,
          sponsoredRank: 53,
          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 3427,
          organicRank: 73,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 3427,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 3427,
          organicRank: 82,
          sponsoredRank: 42,
          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 3470,
          organicRank: 95,
          sponsoredRank: 22,
          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 3470,
          organicRank: 98,
          sponsoredRank: 75,
          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 3470,
          organicRank: 86,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 3470,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 3470,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 3470,
          organicRank: 77,
          sponsoredRank: 21,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 3470,
          organicRank: 87,
          sponsoredRank: 25,
          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 3451,
          organicRank: 96,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 3451,
          organicRank: 77,
          sponsoredRank: 8,
          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 3451,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 3451,
          organicRank: 50,
          sponsoredRank: 21,
          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 3451,
          organicRank: 70,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 3451,
          organicRank: 39,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 3451,
          organicRank: 39,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 3093,
          organicRank: 36,

          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 3093,
          organicRank: 32,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 3093,
          organicRank: 26,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 3093,
          organicRank: 23,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 3093,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 3093,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 3093,
          organicRank: 71,
          sponsoredRank: 41,
          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 3563,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-23',
          searchVolume: 3563,
          organicRank: 93,
          sponsoredRank: 68,
          keywordSales: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 3563,
          organicRank: 86,
          sponsoredRank: 51,
          keywordSales: 3
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor wifi camera`,
      keyword: 'outdoor wifi camera',
      searchVolume: 2509,
      organicStartRank: 140,
      organicHighestRank: 99,
      sponsoredStartRank: 24,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2554,
          organicRank: 306,
          sponsoredRank: 87,
          keywordSales: 29
        },
        {
          date: '2024-07-02',
          searchVolume: 2554,
          organicRank: 164,
          sponsoredRank: 11,
          keywordSales: 29
        },
        {
          date: '2024-07-01',
          searchVolume: 2554,
          organicRank: 141,

          keywordSales: 29
        },
        {
          date: '2024-06-30',
          searchVolume: 2554,
          organicRank: 148,

          keywordSales: 29
        },
        {
          date: '2024-06-29',
          searchVolume: 2554,
          organicRank: 140,

          keywordSales: 29
        },
        {
          date: '2024-06-28',
          searchVolume: 2541,
          organicRank: 133,

          keywordSales: 29
        },
        {
          date: '2024-06-27',
          searchVolume: 2541,
          organicRank: 306,
          sponsoredRank: 24,
          keywordSales: 29
        },
        {
          date: '2024-06-26',
          searchVolume: 2541,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 29
        },
        {
          date: '2024-06-25',
          searchVolume: 2541,
          organicRank: 148,
          sponsoredRank: 18,
          keywordSales: 29
        },
        {
          date: '2024-06-24',
          searchVolume: 2541,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 29
        },
        {
          date: '2024-06-23',
          searchVolume: 2541,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 29
        },
        {
          date: '2024-06-22',
          searchVolume: 2541,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 29
        },
        {
          date: '2024-06-21',
          searchVolume: 2548,
          organicRank: 138,

          keywordSales: 29
        },
        {
          date: '2024-06-20',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 97,
          keywordSales: 29
        },
        {
          date: '2024-06-19',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 2,
          keywordSales: 29
        },
        {
          date: '2024-06-18',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 80,
          keywordSales: 29
        },
        {
          date: '2024-06-17',
          searchVolume: 2548,
          organicRank: 306,
          sponsoredRank: 2,
          keywordSales: 29
        },
        {
          date: '2024-06-16',
          searchVolume: 2548,
          organicRank: 282,

          keywordSales: 29
        },
        {
          date: '2024-06-15',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-06-14',
          searchVolume: 2547,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 29
        },
        {
          date: '2024-06-13',
          searchVolume: 2547,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 29
        },
        {
          date: '2024-06-12',
          searchVolume: 2547,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 29
        },
        {
          date: '2024-06-11',
          searchVolume: 2547,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 29
        },
        {
          date: '2024-06-10',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-06-09',
          searchVolume: 2547,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 29
        },
        {
          date: '2024-06-08',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-06-07',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-06-06',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-06-05',
          searchVolume: 2433,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 29
        },
        {
          date: '2024-06-04',
          searchVolume: 2433,
          organicRank: 306,
          sponsoredRank: 18,
          keywordSales: 29
        },
        {
          date: '2024-06-03',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-06-02',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-06-01',
          searchVolume: 2433,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 29
        },
        {
          date: '2024-05-31',
          searchVolume: 2511,
          organicRank: 306,
          sponsoredRank: 78,
          keywordSales: 29
        },
        {
          date: '2024-05-30',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-05-29',
          searchVolume: 2511,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 29
        },
        {
          date: '2024-05-28',
          searchVolume: 2511,
          organicRank: 306,

          keywordSales: 29
        },
        {
          date: '2024-05-27',
          searchVolume: 2511,
          organicRank: 306,
          sponsoredRank: 41,
          keywordSales: 29
        },
        {
          date: '2024-05-26',
          searchVolume: 2511,
          organicRank: 99,
          sponsoredRank: 11,
          keywordSales: 29
        },
        {
          date: '2024-05-25',
          searchVolume: 2511,
          organicRank: 103,
          sponsoredRank: 59,
          keywordSales: 29
        },
        {
          date: '2024-05-24',
          searchVolume: 2509,
          organicRank: 246,

          keywordSales: 29
        },
        {
          date: '2024-05-23',
          searchVolume: 2509,
          organicRank: 154,

          keywordSales: 29
        },
        {
          date: '2024-05-22',
          searchVolume: 2509,
          organicRank: 140,
          sponsoredRank: 24,
          keywordSales: 29
        },
        {
          date: '2024-05-21',
          searchVolume: 2509,
          organicRank: 276,
          sponsoredRank: 62,
          keywordSales: 29
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor wireless camera`,
      keyword: 'outdoor wireless camera',
      searchVolume: 3392,
      organicStartRank: 55,
      organicHighestRank: 10,
      sponsoredStartRank: 63,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-05-25',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 3623,
          organicRank: 35,
          sponsoredRank: 28,
          keywordSales: 52
        },
        {
          date: '2024-07-03',
          searchVolume: 3623,
          organicRank: 31,
          sponsoredRank: 40,
          keywordSales: 52
        },
        {
          date: '2024-07-02',
          searchVolume: 3623,
          organicRank: 28,
          sponsoredRank: 58,
          keywordSales: 52
        },
        {
          date: '2024-07-01',
          searchVolume: 3623,
          organicRank: 15,

          keywordSales: 52
        },
        {
          date: '2024-06-30',
          searchVolume: 3623,
          organicRank: 17,

          keywordSales: 52
        },
        {
          date: '2024-06-29',
          searchVolume: 3623,
          organicRank: 20,
          sponsoredRank: 30,
          keywordSales: 52
        },
        {
          date: '2024-06-28',
          searchVolume: 3424,
          organicRank: 22,
          sponsoredRank: 53,
          keywordSales: 52
        },
        {
          date: '2024-06-27',
          searchVolume: 3424,
          organicRank: 28,
          sponsoredRank: 10,
          keywordSales: 52
        },
        {
          date: '2024-06-26',
          searchVolume: 3424,
          organicRank: 16,

          keywordSales: 52
        },
        {
          date: '2024-06-25',
          searchVolume: 3424,
          organicRank: 17,

          keywordSales: 52
        },
        {
          date: '2024-06-24',
          searchVolume: 3424,
          organicRank: 19,

          keywordSales: 52
        },
        {
          date: '2024-06-23',
          searchVolume: 3424,
          organicRank: 23,
          sponsoredRank: 11,
          keywordSales: 52
        },
        {
          date: '2024-06-22',
          searchVolume: 3424,
          organicRank: 14,
          sponsoredRank: 5,
          keywordSales: 52
        },
        {
          date: '2024-06-21',
          searchVolume: 3445,
          organicRank: 15,
          sponsoredRank: 36,
          keywordSales: 52
        },
        {
          date: '2024-06-20',
          searchVolume: 3445,
          organicRank: 13,
          sponsoredRank: 71,
          keywordSales: 52
        },
        {
          date: '2024-06-19',
          searchVolume: 3445,
          organicRank: 13,
          sponsoredRank: 10,
          keywordSales: 52
        },
        {
          date: '2024-06-18',
          searchVolume: 3445,
          organicRank: 13,
          sponsoredRank: 99,
          keywordSales: 52
        },
        {
          date: '2024-06-17',
          searchVolume: 3445,
          organicRank: 13,

          keywordSales: 52
        },
        {
          date: '2024-06-16',
          searchVolume: 3445,
          organicRank: 15,

          keywordSales: 52
        },
        {
          date: '2024-06-15',
          searchVolume: 3445,
          organicRank: 18,
          sponsoredRank: 2,
          keywordSales: 52
        },
        {
          date: '2024-06-14',
          searchVolume: 3624,
          organicRank: 15,
          sponsoredRank: 21,
          keywordSales: 52
        },
        {
          date: '2024-06-13',
          searchVolume: 3624,
          organicRank: 10,
          sponsoredRank: 47,
          keywordSales: 52
        },
        {
          date: '2024-06-12',
          searchVolume: 3624,
          organicRank: 14,

          keywordSales: 52
        },
        {
          date: '2024-06-11',
          searchVolume: 3624,
          organicRank: 15,
          sponsoredRank: 17,
          keywordSales: 52
        },
        {
          date: '2024-06-10',
          searchVolume: 3624,
          organicRank: 13,
          sponsoredRank: 10,
          keywordSales: 52
        },
        {
          date: '2024-06-09',
          searchVolume: 3624,
          organicRank: 16,
          sponsoredRank: 34,
          keywordSales: 52
        },
        {
          date: '2024-06-08',
          searchVolume: 3624,
          organicRank: 16,
          sponsoredRank: 33,
          keywordSales: 52
        },
        {
          date: '2024-06-07',
          searchVolume: 2982,
          organicRank: 17,

          keywordSales: 52
        },
        {
          date: '2024-06-06',
          searchVolume: 2982,
          organicRank: 13,
          sponsoredRank: 17,
          keywordSales: 52
        },
        {
          date: '2024-06-05',
          searchVolume: 2982,
          organicRank: 17,
          sponsoredRank: 9,
          keywordSales: 52
        },
        {
          date: '2024-06-04',
          searchVolume: 2982,
          organicRank: 16,
          sponsoredRank: 19,
          keywordSales: 52
        },
        {
          date: '2024-06-03',
          searchVolume: 2982,
          organicRank: 11,

          keywordSales: 52
        },
        {
          date: '2024-06-02',
          searchVolume: 2982,
          organicRank: 10,

          keywordSales: 52
        },
        {
          date: '2024-06-01',
          searchVolume: 2982,
          organicRank: 12,
          sponsoredRank: 65,
          keywordSales: 52
        },
        {
          date: '2024-05-31',
          searchVolume: 3078,
          organicRank: 23,
          sponsoredRank: 56,
          keywordSales: 52
        },
        {
          date: '2024-05-30',
          searchVolume: 3078,
          organicRank: 35,
          sponsoredRank: 65,
          keywordSales: 52
        },
        {
          date: '2024-05-29',
          searchVolume: 3078,
          organicRank: 33,
          sponsoredRank: 29,
          keywordSales: 52
        },
        {
          date: '2024-05-28',
          searchVolume: 3078,
          organicRank: 36,
          sponsoredRank: 69,
          keywordSales: 52
        },
        {
          date: '2024-05-27',
          searchVolume: 3078,
          organicRank: 31,
          sponsoredRank: 68,
          keywordSales: 52
        },
        {
          date: '2024-05-26',
          searchVolume: 3078,
          organicRank: 25,
          sponsoredRank: 13,
          keywordSales: 52
        },
        {
          date: '2024-05-25',
          searchVolume: 3078,
          organicRank: 19,
          sponsoredRank: 51,
          keywordSales: 52
        },
        {
          date: '2024-05-24',
          searchVolume: 3392,
          organicRank: 30,

          keywordSales: 52
        },
        {
          date: '2024-05-23',
          searchVolume: 3392,
          organicRank: 56,
          sponsoredRank: 16,
          keywordSales: 52
        },
        {
          date: '2024-05-22',
          searchVolume: 3392,
          organicRank: 55,
          sponsoredRank: 63,
          keywordSales: 52
        },
        {
          date: '2024-05-21',
          searchVolume: 3392,
          organicRank: 59,
          sponsoredRank: 76,
          keywordSales: 52
        },
        {
          date: '2024-05-20',
          searchVolume: 3392,
          organicRank: 65,

          keywordSales: 52
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outdoor wireless security camera`,
      keyword: 'outdoor wireless security camera',
      searchVolume: 5890,
      organicStartRank: 93,
      organicHighestRank: 18,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-07-01',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 6747,
          organicRank: 31,
          sponsoredRank: 42,
          keywordSales: 23
        },
        {
          date: '2024-07-02',
          searchVolume: 6747,
          organicRank: 32,
          sponsoredRank: 57,
          keywordSales: 23
        },
        {
          date: '2024-07-01',
          searchVolume: 6747,
          organicRank: 18,

          keywordSales: 23
        },
        {
          date: '2024-06-30',
          searchVolume: 6747,
          organicRank: 37,

          keywordSales: 23
        },
        {
          date: '2024-06-29',
          searchVolume: 6747,
          organicRank: 33,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-06-28',
          searchVolume: 5905,
          organicRank: 52,
          sponsoredRank: 53,
          keywordSales: 23
        },
        {
          date: '2024-06-27',
          searchVolume: 5905,
          organicRank: 49,
          sponsoredRank: 46,
          keywordSales: 23
        },
        {
          date: '2024-06-26',
          searchVolume: 5905,
          organicRank: 58,
          sponsoredRank: 16,
          keywordSales: 23
        },
        {
          date: '2024-06-25',
          searchVolume: 5905,
          organicRank: 74,
          sponsoredRank: 49,
          keywordSales: 23
        },
        {
          date: '2024-06-24',
          searchVolume: 5905,
          organicRank: 84,
          sponsoredRank: 25,
          keywordSales: 23
        },
        {
          date: '2024-06-23',
          searchVolume: 5905,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 23
        },
        {
          date: '2024-06-22',
          searchVolume: 5905,
          organicRank: 57,
          sponsoredRank: 20,
          keywordSales: 23
        },
        {
          date: '2024-06-21',
          searchVolume: 5915,
          organicRank: 74,
          sponsoredRank: 60,
          keywordSales: 23
        },
        {
          date: '2024-06-20',
          searchVolume: 5915,
          organicRank: 62,
          sponsoredRank: 52,
          keywordSales: 23
        },
        {
          date: '2024-06-19',
          searchVolume: 5915,
          organicRank: 78,
          sponsoredRank: 6,
          keywordSales: 23
        },
        {
          date: '2024-06-18',
          searchVolume: 5915,
          organicRank: 79,
          sponsoredRank: 46,
          keywordSales: 23
        },
        {
          date: '2024-06-17',
          searchVolume: 5915,
          organicRank: 77,
          sponsoredRank: 43,
          keywordSales: 23
        },
        {
          date: '2024-06-16',
          searchVolume: 5915,
          organicRank: 48,
          sponsoredRank: 13,
          keywordSales: 23
        },
        {
          date: '2024-06-15',
          searchVolume: 5915,
          organicRank: 59,
          sponsoredRank: 15,
          keywordSales: 23
        },
        {
          date: '2024-06-14',
          searchVolume: 6742,
          organicRank: 58,
          sponsoredRank: 39,
          keywordSales: 23
        },
        {
          date: '2024-06-13',
          searchVolume: 6742,
          organicRank: 306,
          sponsoredRank: 51,
          keywordSales: 23
        },
        {
          date: '2024-06-12',
          searchVolume: 6742,
          organicRank: 63,

          keywordSales: 23
        },
        {
          date: '2024-06-11',
          searchVolume: 6742,
          organicRank: 306,
          sponsoredRank: 80,
          keywordSales: 23
        },
        {
          date: '2024-06-10',
          searchVolume: 6742,
          organicRank: 37,
          sponsoredRank: 25,
          keywordSales: 23
        },
        {
          date: '2024-06-09',
          searchVolume: 6742,
          organicRank: 43,
          sponsoredRank: 8,
          keywordSales: 23
        },
        {
          date: '2024-06-08',
          searchVolume: 6742,
          organicRank: 46,
          sponsoredRank: 65,
          keywordSales: 23
        },
        {
          date: '2024-06-07',
          searchVolume: 6443,
          organicRank: 31,

          keywordSales: 23
        },
        {
          date: '2024-06-06',
          searchVolume: 6443,
          organicRank: 32,
          sponsoredRank: 18,
          keywordSales: 23
        },
        {
          date: '2024-06-05',
          searchVolume: 6443,
          organicRank: 306,
          sponsoredRank: 17,
          keywordSales: 23
        },
        {
          date: '2024-06-04',
          searchVolume: 6443,
          organicRank: 34,
          sponsoredRank: 13,
          keywordSales: 23
        },
        {
          date: '2024-06-03',
          searchVolume: 6443,
          organicRank: 22,

          keywordSales: 23
        },
        {
          date: '2024-06-02',
          searchVolume: 6443,
          organicRank: 27,

          keywordSales: 23
        },
        {
          date: '2024-06-01',
          searchVolume: 6443,
          organicRank: 29,
          sponsoredRank: 41,
          keywordSales: 23
        },
        {
          date: '2024-05-31',
          searchVolume: 5888,
          organicRank: 23,
          sponsoredRank: 40,
          keywordSales: 23
        },
        {
          date: '2024-05-30',
          searchVolume: 5888,
          organicRank: 36,
          sponsoredRank: 66,
          keywordSales: 23
        },
        {
          date: '2024-05-29',
          searchVolume: 5888,
          organicRank: 68,
          sponsoredRank: 78,
          keywordSales: 23
        },
        {
          date: '2024-05-28',
          searchVolume: 5888,
          organicRank: 63,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-05-27',
          searchVolume: 5888,
          organicRank: 62,
          sponsoredRank: 81,
          keywordSales: 23
        },
        {
          date: '2024-05-26',
          searchVolume: 5888,
          organicRank: 71,
          sponsoredRank: 15,
          keywordSales: 23
        },
        {
          date: '2024-05-25',
          searchVolume: 5888,
          organicRank: 37,
          sponsoredRank: 2,
          keywordSales: 23
        },
        {
          date: '2024-05-24',
          searchVolume: 5890,
          organicRank: 93,
          sponsoredRank: 58,
          keywordSales: 23
        },
        {
          date: '2024-05-23',
          searchVolume: 5890,
          organicRank: 79,
          sponsoredRank: 24,
          keywordSales: 23
        },
        {
          date: '2024-05-22',
          searchVolume: 5890,
          organicRank: 93,
          sponsoredRank: 29,
          keywordSales: 23
        },
        {
          date: '2024-05-21',
          searchVolume: 5890,
          organicRank: 71,
          sponsoredRank: 25,
          keywordSales: 23
        },
        {
          date: '2024-05-20',
          searchVolume: 5890,
          organicRank: 125,
          sponsoredRank: 39,
          keywordSales: 23
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outside camera`,
      keyword: 'outside camera',
      searchVolume: 3190,
      organicStartRank: 306,
      organicHighestRank: 44,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 21,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 4325,
          organicRank: 306
        },
        {
          date: '2024-07-03',
          searchVolume: 4325,
          organicRank: 306
        },
        {
          date: '2024-07-02',
          searchVolume: 4325,
          organicRank: 306
        },
        {
          date: '2024-07-01',
          searchVolume: 4325,
          organicRank: 279
        },
        {
          date: '2024-06-30',
          searchVolume: 4325,
          organicRank: 268
        },
        {
          date: '2024-06-29',
          searchVolume: 4325,
          organicRank: 306
        },
        {
          date: '2024-06-28',
          searchVolume: 3461,
          organicRank: 306,
          sponsoredRank: 25
        },
        {
          date: '2024-06-27',
          searchVolume: 3461,
          organicRank: 306,
          sponsoredRank: 81
        },
        {
          date: '2024-06-26',
          searchVolume: 3461,
          organicRank: 306
        },
        {
          date: '2024-06-25',
          searchVolume: 3461,
          organicRank: 306
        },
        {
          date: '2024-06-24',
          searchVolume: 3461,
          organicRank: 306
        },
        {
          date: '2024-06-21',
          searchVolume: 3626,
          organicRank: 306
        },
        {
          date: '2024-06-20',
          searchVolume: 3626,
          organicRank: 306
        },
        {
          date: '2024-06-19',
          searchVolume: 3626,
          organicRank: 275
        },
        {
          date: '2024-06-18',
          searchVolume: 3626,
          organicRank: 156
        },
        {
          date: '2024-06-17',
          searchVolume: 3626,
          organicRank: 306
        },
        {
          date: '2024-06-16',
          searchVolume: 3626,
          organicRank: 306
        },
        {
          date: '2024-06-15',
          searchVolume: 3626,
          organicRank: 271
        },
        {
          date: '2024-06-14',
          searchVolume: 3475,
          organicRank: 306
        },
        {
          date: '2024-06-13',
          searchVolume: 3475,
          organicRank: 306,
          sponsoredRank: 42
        },
        {
          date: '2024-06-12',
          searchVolume: 3475,
          organicRank: 306
        },
        {
          date: '2024-06-11',
          searchVolume: 3475,
          organicRank: 306
        },
        {
          date: '2024-06-10',
          searchVolume: 3475,
          organicRank: 306
        },
        {
          date: '2024-06-09',
          searchVolume: 3475,
          organicRank: 306,
          sponsoredRank: 21
        },
        {
          date: '2024-06-08',
          searchVolume: 3475,
          organicRank: 306
        },
        {
          date: '2024-06-07',
          searchVolume: 3318,
          organicRank: 306
        },
        {
          date: '2024-06-06',
          searchVolume: 3318,
          organicRank: 306
        },
        {
          date: '2024-06-05',
          searchVolume: 3318,
          organicRank: 306,
          sponsoredRank: 73
        },
        {
          date: '2024-06-04',
          searchVolume: 3318,
          organicRank: 306,
          sponsoredRank: 57
        },
        {
          date: '2024-06-03',
          searchVolume: 3318,
          organicRank: 306
        },
        {
          date: '2024-06-02',
          searchVolume: 3318,
          organicRank: 306
        },
        {
          date: '2024-06-01',
          searchVolume: 3318,
          organicRank: 306
        },
        {
          date: '2024-05-31',
          searchVolume: 3563,
          organicRank: 306,
          sponsoredRank: 31
        },
        {
          date: '2024-05-30',
          searchVolume: 3563,
          organicRank: 306
        },
        {
          date: '2024-05-29',
          searchVolume: 3563,
          organicRank: 306
        },
        {
          date: '2024-05-28',
          searchVolume: 3563,
          organicRank: 306
        },
        {
          date: '2024-05-27',
          searchVolume: 3563,
          organicRank: 44
        },
        {
          date: '2024-05-26',
          searchVolume: 3563,
          organicRank: 268
        },
        {
          date: '2024-05-25',
          searchVolume: 3563,
          organicRank: 306
        },
        {
          date: '2024-05-24',
          searchVolume: 3190,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outside camera wireless`,
      keyword: 'outside camera wireless',
      searchVolume: 2511,
      organicStartRank: 255,
      organicHighestRank: 54,
      sponsoredStartRank: 20,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2552,
          organicRank: 94,
          sponsoredRank: 80,
          keywordSales: 35
        },
        {
          date: '2024-07-03',
          searchVolume: 2552,
          organicRank: 101,
          sponsoredRank: 21,
          keywordSales: 35
        },
        {
          date: '2024-07-02',
          searchVolume: 2552,
          organicRank: 250,
          sponsoredRank: 81,
          keywordSales: 35
        },
        {
          date: '2024-07-01',
          searchVolume: 2552,
          organicRank: 84,

          keywordSales: 35
        },
        {
          date: '2024-06-30',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-06-29',
          searchVolume: 2552,
          organicRank: 78,

          keywordSales: 35
        },
        {
          date: '2024-06-28',
          searchVolume: 2543,
          organicRank: 68,
          sponsoredRank: 91,
          keywordSales: 35
        },
        {
          date: '2024-06-27',
          searchVolume: 2543,
          organicRank: 76,
          sponsoredRank: 24,
          keywordSales: 35
        },
        {
          date: '2024-06-26',
          searchVolume: 2543,
          organicRank: 66,
          sponsoredRank: 46,
          keywordSales: 35
        },
        {
          date: '2024-06-25',
          searchVolume: 2543,
          organicRank: 81,

          keywordSales: 35
        },
        {
          date: '2024-06-23',
          searchVolume: 2543,
          organicRank: 82,

          keywordSales: 35
        },
        {
          date: '2024-06-22',
          searchVolume: 2543,
          organicRank: 82,

          keywordSales: 35
        },
        {
          date: '2024-06-21',
          searchVolume: 2547,
          organicRank: 71,
          sponsoredRank: 45,
          keywordSales: 35
        },
        {
          date: '2024-06-20',
          searchVolume: 2547,
          organicRank: 72,
          sponsoredRank: 12,
          keywordSales: 35
        },
        {
          date: '2024-06-19',
          searchVolume: 2547,
          organicRank: 94,
          sponsoredRank: 15,
          keywordSales: 35
        },
        {
          date: '2024-06-18',
          searchVolume: 2547,
          organicRank: 92,

          keywordSales: 35
        },
        {
          date: '2024-06-17',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-06-16',
          searchVolume: 2547,
          organicRank: 102,
          sponsoredRank: 37,
          keywordSales: 35
        },
        {
          date: '2024-06-15',
          searchVolume: 2547,
          organicRank: 116,
          sponsoredRank: 26,
          keywordSales: 35
        },
        {
          date: '2024-06-14',
          searchVolume: 2396,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 35
        },
        {
          date: '2024-06-13',
          searchVolume: 2396,
          organicRank: 306,
          sponsoredRank: 41,
          keywordSales: 35
        },
        {
          date: '2024-06-12',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-06-11',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 35
        },
        {
          date: '2024-06-10',
          searchVolume: 2396,
          organicRank: 96,

          keywordSales: 35
        },
        {
          date: '2024-06-09',
          searchVolume: 2396,
          organicRank: 104,
          sponsoredRank: 44,
          keywordSales: 35
        },
        {
          date: '2024-06-08',
          searchVolume: 2396,
          organicRank: 96,
          sponsoredRank: 99,
          keywordSales: 35
        },
        {
          date: '2024-06-07',
          searchVolume: 2279,
          organicRank: 93,

          keywordSales: 35
        },
        {
          date: '2024-06-06',
          searchVolume: 2279,
          organicRank: 70,

          keywordSales: 35
        },
        {
          date: '2024-06-05',
          searchVolume: 2279,
          organicRank: 77,
          sponsoredRank: 6,
          keywordSales: 35
        },
        {
          date: '2024-06-04',
          searchVolume: 2279,
          organicRank: 57,
          sponsoredRank: 82,
          keywordSales: 35
        },
        {
          date: '2024-06-03',
          searchVolume: 2279,
          organicRank: 63,

          keywordSales: 35
        },
        {
          date: '2024-06-02',
          searchVolume: 2279,
          organicRank: 54,

          keywordSales: 35
        },
        {
          date: '2024-06-01',
          searchVolume: 2279,
          organicRank: 55,
          sponsoredRank: 48,
          keywordSales: 35
        },
        {
          date: '2024-05-31',
          searchVolume: 2392,
          organicRank: 55,
          sponsoredRank: 39,
          keywordSales: 35
        },
        {
          date: '2024-05-30',
          searchVolume: 2392,
          organicRank: 54,

          keywordSales: 35
        },
        {
          date: '2024-05-29',
          searchVolume: 2392,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 35
        },
        {
          date: '2024-05-28',
          searchVolume: 2392,
          organicRank: 306,
          sponsoredRank: 81,
          keywordSales: 35
        },
        {
          date: '2024-05-27',
          searchVolume: 2392,
          organicRank: 54,

          keywordSales: 35
        },
        {
          date: '2024-05-25',
          searchVolume: 2392,
          organicRank: 80,
          sponsoredRank: 34,
          keywordSales: 35
        },
        {
          date: '2024-05-24',
          searchVolume: 2511,
          organicRank: 100,

          keywordSales: 35
        },
        {
          date: '2024-05-23',
          searchVolume: 2511,
          organicRank: 74,
          sponsoredRank: 60,
          keywordSales: 35
        },
        {
          date: '2024-05-22',
          searchVolume: 2511,
          organicRank: 255,
          sponsoredRank: 20,
          keywordSales: 35
        },
        {
          date: '2024-05-21',
          searchVolume: 2511,
          organicRank: 77,
          sponsoredRank: 2,
          keywordSales: 35
        },
        {
          date: '2024-05-20',
          searchVolume: 2511,
          organicRank: 254,

          keywordSales: 35
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outside cameras for home security`,
      keyword: 'outside cameras for home security',
      searchVolume: 11594,
      organicStartRank: 96,
      organicHighestRank: 86,
      sponsoredStartRank: 88,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 14165,
          organicRank: 134,
          sponsoredRank: 48,
          keywordSales: 139
        },
        {
          date: '2024-07-02',
          searchVolume: 14165,
          organicRank: 122,

          keywordSales: 139
        },
        {
          date: '2024-07-01',
          searchVolume: 14165,
          organicRank: 104,

          keywordSales: 139
        },
        {
          date: '2024-06-30',
          searchVolume: 14165,
          organicRank: 100,

          keywordSales: 139
        },
        {
          date: '2024-06-29',
          searchVolume: 14165,
          organicRank: 86,

          keywordSales: 139
        },
        {
          date: '2024-06-28',
          searchVolume: 13409,
          organicRank: 91,

          keywordSales: 139
        },
        {
          date: '2024-06-27',
          searchVolume: 13409,
          organicRank: 306,
          sponsoredRank: 24,
          keywordSales: 139
        },
        {
          date: '2024-06-26',
          searchVolume: 13409,
          organicRank: 92,
          sponsoredRank: 22,
          keywordSales: 139
        },
        {
          date: '2024-06-25',
          searchVolume: 13409,
          organicRank: 90,
          sponsoredRank: 45,
          keywordSales: 139
        },
        {
          date: '2024-06-24',
          searchVolume: 13409,
          organicRank: 88,
          sponsoredRank: 21,
          keywordSales: 139
        },
        {
          date: '2024-06-23',
          searchVolume: 13409,
          organicRank: 88,
          sponsoredRank: 7,
          keywordSales: 139
        },
        {
          date: '2024-06-22',
          searchVolume: 13409,
          organicRank: 86,
          sponsoredRank: 15,
          keywordSales: 139
        },
        {
          date: '2024-06-21',
          searchVolume: 13403,
          organicRank: 90,

          keywordSales: 139
        },
        {
          date: '2024-06-20',
          searchVolume: 13403,
          organicRank: 306,
          sponsoredRank: 35,
          keywordSales: 139
        },
        {
          date: '2024-06-19',
          searchVolume: 13403,
          organicRank: 108,
          sponsoredRank: 78,
          keywordSales: 139
        },
        {
          date: '2024-06-18',
          searchVolume: 13403,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 139
        },
        {
          date: '2024-06-17',
          searchVolume: 13403,
          organicRank: 141,
          sponsoredRank: 33,
          keywordSales: 139
        },
        {
          date: '2024-06-16',
          searchVolume: 13403,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 139
        },
        {
          date: '2024-06-15',
          searchVolume: 13403,
          organicRank: 245,
          sponsoredRank: 32,
          keywordSales: 139
        },
        {
          date: '2024-06-14',
          searchVolume: 13405,
          organicRank: 151,
          sponsoredRank: 43,
          keywordSales: 139
        },
        {
          date: '2024-06-13',
          searchVolume: 13405,
          organicRank: 306,
          sponsoredRank: 28,
          keywordSales: 139
        },
        {
          date: '2024-06-12',
          searchVolume: 13405,
          organicRank: 167,

          keywordSales: 139
        },
        {
          date: '2024-06-11',
          searchVolume: 13405,
          organicRank: 144,

          keywordSales: 139
        },
        {
          date: '2024-06-10',
          searchVolume: 13405,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-09',
          searchVolume: 13405,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 139
        },
        {
          date: '2024-06-08',
          searchVolume: 13405,
          organicRank: 246,
          sponsoredRank: 64,
          keywordSales: 139
        },
        {
          date: '2024-06-07',
          searchVolume: 11635,
          organicRank: 268,
          sponsoredRank: 25,
          keywordSales: 139
        },
        {
          date: '2024-06-06',
          searchVolume: 11635,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-05',
          searchVolume: 11635,
          organicRank: 306,
          sponsoredRank: 88,
          keywordSales: 139
        },
        {
          date: '2024-06-04',
          searchVolume: 11635,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 139
        },
        {
          date: '2024-06-03',
          searchVolume: 11635,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-02',
          searchVolume: 11635,
          organicRank: 101,

          keywordSales: 139
        },
        {
          date: '2024-06-01',
          searchVolume: 11635,
          organicRank: 94,
          sponsoredRank: 24,
          keywordSales: 139
        },
        {
          date: '2024-05-31',
          searchVolume: 11594,
          organicRank: 92,
          sponsoredRank: 14,
          keywordSales: 139
        },
        {
          date: '2024-05-30',
          searchVolume: 11594,
          organicRank: 90,
          sponsoredRank: 96,
          keywordSales: 139
        },
        {
          date: '2024-05-29',
          searchVolume: 11594,
          organicRank: 92,

          keywordSales: 139
        },
        {
          date: '2024-05-28',
          searchVolume: 11594,
          organicRank: 90,
          sponsoredRank: 44,
          keywordSales: 139
        },
        {
          date: '2024-05-27',
          searchVolume: 11594,
          organicRank: 98,
          sponsoredRank: 37,
          keywordSales: 139
        },
        {
          date: '2024-05-26',
          searchVolume: 11594,
          organicRank: 96,
          sponsoredRank: 10,
          keywordSales: 139
        },
        {
          date: '2024-05-25',
          searchVolume: 11594,
          organicRank: 91,
          sponsoredRank: 69,
          keywordSales: 139
        },
        {
          date: '2024-05-24',
          searchVolume: 11594,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 139
        },
        {
          date: '2024-05-23',
          searchVolume: 11594,
          organicRank: 97,
          sponsoredRank: 66,
          keywordSales: 139
        },
        {
          date: '2024-05-22',
          searchVolume: 11594,
          organicRank: 96,
          sponsoredRank: 88,
          keywordSales: 139
        },
        {
          date: '2024-05-21',
          searchVolume: 11594,
          organicRank: 246,
          sponsoredRank: 18,
          keywordSales: 139
        },
        {
          date: '2024-05-20',
          searchVolume: 11594,
          organicRank: 250,

          keywordSales: 139
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_outside security cameras with night vision`,
      keyword: 'outside security cameras with night vision',
      searchVolume: 1886,
      organicStartRank: 306,
      organicHighestRank: 267,
      sponsoredStartRank: 67,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-07-03',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-07-02',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-07-01',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-30',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-29',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-28',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-27',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-26',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-25',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-24',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-21',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-20',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-19',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-18',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-17',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-16',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-15',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-14',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-13',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-12',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-11',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-10',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-09',
          searchVolume: 1913,
          organicRank: 306,
          sponsoredRank: 8,
          keywordSales: 3
        },
        {
          date: '2024-06-08',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-07',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-06',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-05',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-03',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-02',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-06-01',
          searchVolume: 1613,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-31',
          searchVolume: 1895,
          organicRank: 306,
          sponsoredRank: 61,
          keywordSales: 3
        },
        {
          date: '2024-05-30',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-29',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-28',
          searchVolume: 1895,
          organicRank: 267,

          keywordSales: 3
        },
        {
          date: '2024-05-27',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-26',
          searchVolume: 1895,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 3
        },
        {
          date: '2024-05-25',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 3
        },
        {
          date: '2024-05-24',
          searchVolume: 1886,
          organicRank: 306,

          keywordSales: 3
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_pet camera indoor`,
      keyword: 'pet camera indoor',
      searchVolume: 11747,
      organicStartRank: 306,
      organicHighestRank: 217,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 11059,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-07-03',
          searchVolume: 11059,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-07-02',
          searchVolume: 11059,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-07-01',
          searchVolume: 11059,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-30',
          searchVolume: 11059,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-29',
          searchVolume: 11059,
          organicRank: 268,

          keywordSales: 467
        },
        {
          date: '2024-06-28',
          searchVolume: 11729,
          organicRank: 278,

          keywordSales: 467
        },
        {
          date: '2024-06-27',
          searchVolume: 11729,
          organicRank: 227,

          keywordSales: 467
        },
        {
          date: '2024-06-26',
          searchVolume: 11729,
          organicRank: 217,

          keywordSales: 467
        },
        {
          date: '2024-06-25',
          searchVolume: 11729,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-23',
          searchVolume: 11729,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-21',
          searchVolume: 11747,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-20',
          searchVolume: 11747,
          organicRank: 280,

          keywordSales: 467
        },
        {
          date: '2024-06-19',
          searchVolume: 11747,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-18',
          searchVolume: 11747,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-17',
          searchVolume: 11747,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-16',
          searchVolume: 11747,
          organicRank: 262,

          keywordSales: 467
        },
        {
          date: '2024-06-15',
          searchVolume: 11747,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-14',
          searchVolume: 11067,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-13',
          searchVolume: 11067,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-12',
          searchVolume: 11067,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-11',
          searchVolume: 11067,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-10',
          searchVolume: 11067,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-09',
          searchVolume: 11067,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-08',
          searchVolume: 11067,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-07',
          searchVolume: 10539,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-06',
          searchVolume: 10539,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-05',
          searchVolume: 10539,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-04',
          searchVolume: 10539,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-03',
          searchVolume: 10539,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-06-02',
          searchVolume: 10539,
          organicRank: 243,

          keywordSales: 467
        },
        {
          date: '2024-06-01',
          searchVolume: 10539,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-05-31',
          searchVolume: 10604,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-05-30',
          searchVolume: 10604,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-05-29',
          searchVolume: 10604,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-05-28',
          searchVolume: 10604,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-05-27',
          searchVolume: 10604,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-05-26',
          searchVolume: 10604,
          organicRank: 256,

          keywordSales: 467
        },
        {
          date: '2024-05-25',
          searchVolume: 10604,
          organicRank: 306,

          keywordSales: 467
        },
        {
          date: '2024-05-24',
          searchVolume: 10703,
          organicRank: 306,

          keywordSales: 467
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_pet camera indoor with phone app`,
      keyword: 'pet camera indoor with phone app',
      searchVolume: 6429,
      organicStartRank: 306,
      organicHighestRank: 245,
      sponsoredStartRank: 89,
      sponsoredHighestRank: 77,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 9035,
          organicRank: 306
        },
        {
          date: '2024-07-03',
          searchVolume: 9035,
          organicRank: 306
        },
        {
          date: '2024-07-02',
          searchVolume: 9035,
          organicRank: 306
        },
        {
          date: '2024-07-01',
          searchVolume: 9035,
          organicRank: 306
        },
        {
          date: '2024-06-30',
          searchVolume: 9035,
          organicRank: 306
        },
        {
          date: '2024-06-29',
          searchVolume: 9035,
          organicRank: 306
        },
        {
          date: '2024-06-28',
          searchVolume: 8454,
          organicRank: 306
        },
        {
          date: '2024-06-27',
          searchVolume: 8454,
          organicRank: 306
        },
        {
          date: '2024-06-26',
          searchVolume: 8454,
          organicRank: 306
        },
        {
          date: '2024-06-25',
          searchVolume: 8454,
          organicRank: 306
        },
        {
          date: '2024-06-23',
          searchVolume: 8454,
          organicRank: 306
        },
        {
          date: '2024-06-21',
          searchVolume: 7709,
          organicRank: 306
        },
        {
          date: '2024-06-20',
          searchVolume: 7709,
          organicRank: 306
        },
        {
          date: '2024-06-19',
          searchVolume: 7709,
          organicRank: 306
        },
        {
          date: '2024-06-18',
          searchVolume: 7709,
          organicRank: 306,
          sponsoredRank: 77
        },
        {
          date: '2024-06-17',
          searchVolume: 7709,
          organicRank: 306
        },
        {
          date: '2024-06-16',
          searchVolume: 7709,
          organicRank: 306
        },
        {
          date: '2024-06-15',
          searchVolume: 7709,
          organicRank: 306
        },
        {
          date: '2024-06-14',
          searchVolume: 7290,
          organicRank: 306
        },
        {
          date: '2024-06-13',
          searchVolume: 7290,
          organicRank: 306
        },
        {
          date: '2024-06-12',
          searchVolume: 7290,
          organicRank: 306
        },
        {
          date: '2024-06-11',
          searchVolume: 7290,
          organicRank: 306
        },
        {
          date: '2024-06-10',
          searchVolume: 7290,
          organicRank: 306
        },
        {
          date: '2024-06-09',
          searchVolume: 7290,
          organicRank: 306,
          sponsoredRank: 89
        },
        {
          date: '2024-06-08',
          searchVolume: 7290,
          organicRank: 306
        },
        {
          date: '2024-06-07',
          searchVolume: 6429,
          organicRank: 306
        },
        {
          date: '2024-06-06',
          searchVolume: 6429,
          organicRank: 306
        },
        {
          date: '2024-06-05',
          searchVolume: 6429,
          organicRank: 306
        },
        {
          date: '2024-06-04',
          searchVolume: 6429,
          organicRank: 306
        },
        {
          date: '2024-06-03',
          searchVolume: 6429,
          organicRank: 306
        },
        {
          date: '2024-06-02',
          searchVolume: 6429,
          organicRank: 306
        },
        {
          date: '2024-06-01',
          searchVolume: 6429,
          organicRank: 306
        },
        {
          date: '2024-05-31',
          searchVolume: 6647,
          organicRank: 306
        },
        {
          date: '2024-05-30',
          searchVolume: 6647,
          organicRank: 306
        },
        {
          date: '2024-05-29',
          searchVolume: 6647,
          organicRank: 306
        },
        {
          date: '2024-05-28',
          searchVolume: 6647,
          organicRank: 306
        },
        {
          date: '2024-05-27',
          searchVolume: 6647,
          organicRank: 245
        },
        {
          date: '2024-05-26',
          searchVolume: 6647,
          organicRank: 306
        },
        {
          date: '2024-05-25',
          searchVolume: 6647,
          organicRank: 306
        },
        {
          date: '2024-05-24',
          searchVolume: 6647,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_ring camera outdoor wireless 2 pack`,
      keyword: 'ring camera outdoor wireless 2 pack',
      searchVolume: 2392,
      organicStartRank: 75,
      organicHighestRank: 56,
      sponsoredStartRank: 27,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 3472,
          organicRank: 121,

          keywordSales: 40
        },
        {
          date: '2024-07-03',
          searchVolume: 3472,
          organicRank: 110,

          keywordSales: 40
        },
        {
          date: '2024-07-02',
          searchVolume: 3472,
          organicRank: 114,

          keywordSales: 40
        },
        {
          date: '2024-07-01',
          searchVolume: 3472,
          organicRank: 123,

          keywordSales: 40
        },
        {
          date: '2024-06-30',
          searchVolume: 3472,
          organicRank: 124,

          keywordSales: 40
        },
        {
          date: '2024-06-29',
          searchVolume: 3472,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-28',
          searchVolume: 2543,
          organicRank: 124,

          keywordSales: 40
        },
        {
          date: '2024-06-27',
          searchVolume: 2543,
          organicRank: 127,

          keywordSales: 40
        },
        {
          date: '2024-06-26',
          searchVolume: 2543,
          organicRank: 143,

          keywordSales: 40
        },
        {
          date: '2024-06-25',
          searchVolume: 2543,
          organicRank: 141,

          keywordSales: 40
        },
        {
          date: '2024-06-23',
          searchVolume: 2543,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-21',
          searchVolume: 2389,
          organicRank: 138,

          keywordSales: 40
        },
        {
          date: '2024-06-20',
          searchVolume: 2389,
          organicRank: 105,

          keywordSales: 40
        },
        {
          date: '2024-06-19',
          searchVolume: 2389,
          organicRank: 95,

          keywordSales: 40
        },
        {
          date: '2024-06-18',
          searchVolume: 2389,
          organicRank: 114,

          keywordSales: 40
        },
        {
          date: '2024-06-17',
          searchVolume: 2389,
          organicRank: 105,

          keywordSales: 40
        },
        {
          date: '2024-06-16',
          searchVolume: 2389,
          organicRank: 100,

          keywordSales: 40
        },
        {
          date: '2024-06-15',
          searchVolume: 2389,
          organicRank: 56,

          keywordSales: 40
        },
        {
          date: '2024-06-14',
          searchVolume: 2428,
          organicRank: 68,

          keywordSales: 40
        },
        {
          date: '2024-06-13',
          searchVolume: 2428,
          organicRank: 80,

          keywordSales: 40
        },
        {
          date: '2024-06-12',
          searchVolume: 2428,
          organicRank: 116,

          keywordSales: 40
        },
        {
          date: '2024-06-11',
          searchVolume: 2428,
          organicRank: 99,

          keywordSales: 40
        },
        {
          date: '2024-06-10',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-09',
          searchVolume: 2428,
          organicRank: 100,

          keywordSales: 40
        },
        {
          date: '2024-06-08',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-07',
          searchVolume: 2282,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-06',
          searchVolume: 2282,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-05',
          searchVolume: 2282,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-04',
          searchVolume: 2282,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-03',
          searchVolume: 2282,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-02',
          searchVolume: 2282,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-01',
          searchVolume: 2282,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-05-31',
          searchVolume: 2390,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 40
        },
        {
          date: '2024-05-30',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-05-29',
          searchVolume: 2390,
          organicRank: 71,

          keywordSales: 40
        },
        {
          date: '2024-05-28',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-05-27',
          searchVolume: 2390,
          organicRank: 266,
          sponsoredRank: 27,
          keywordSales: 40
        },
        {
          date: '2024-05-26',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-05-25',
          searchVolume: 2390,
          organicRank: 69,

          keywordSales: 40
        },
        {
          date: '2024-05-24',
          searchVolume: 2392,
          organicRank: 83,

          keywordSales: 40
        },
        {
          date: '2024-05-23',
          searchVolume: 2392,
          organicRank: 68,

          keywordSales: 40
        },
        {
          date: '2024-05-22',
          searchVolume: 2392,
          organicRank: 75,

          keywordSales: 40
        },
        {
          date: '2024-05-21',
          searchVolume: 2392,
          organicRank: 68,

          keywordSales: 40
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_ring camera package`,
      keyword: 'ring camera package',
      searchVolume: 1830,
      organicStartRank: 147,
      organicHighestRank: 93,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 406,
          organicRank: 108,

          keywordSales: 171
        },
        {
          date: '2024-07-03',
          searchVolume: 406,
          organicRank: 112,

          keywordSales: 171
        },
        {
          date: '2024-07-02',
          searchVolume: 406,
          organicRank: 116,

          keywordSales: 171
        },
        {
          date: '2024-07-01',
          searchVolume: 406,
          organicRank: 107,

          keywordSales: 171
        },
        {
          date: '2024-06-30',
          searchVolume: 406,
          organicRank: 107,

          keywordSales: 171
        },
        {
          date: '2024-06-29',
          searchVolume: 406,
          organicRank: 106,

          keywordSales: 171
        },
        {
          date: '2024-06-28',
          searchVolume: 281,
          organicRank: 108,

          keywordSales: 171
        },
        {
          date: '2024-06-27',
          searchVolume: 281,
          organicRank: 306,

          keywordSales: 171
        },
        {
          date: '2024-06-26',
          searchVolume: 281,
          organicRank: 93,

          keywordSales: 171
        },
        {
          date: '2024-06-25',
          searchVolume: 281,
          organicRank: 99,

          keywordSales: 171
        },
        {
          date: '2024-06-23',
          searchVolume: 281,
          organicRank: 110,

          keywordSales: 171
        },
        {
          date: '2024-06-21',
          searchVolume: 953,
          organicRank: 105,

          keywordSales: 171
        },
        {
          date: '2024-06-20',
          searchVolume: 953,
          organicRank: 98,

          keywordSales: 171
        },
        {
          date: '2024-06-19',
          searchVolume: 953,
          organicRank: 118,

          keywordSales: 171
        },
        {
          date: '2024-06-18',
          searchVolume: 953,
          organicRank: 120,

          keywordSales: 171
        },
        {
          date: '2024-06-17',
          searchVolume: 953,
          organicRank: 121,

          keywordSales: 171
        },
        {
          date: '2024-06-16',
          searchVolume: 953,
          organicRank: 119,

          keywordSales: 171
        },
        {
          date: '2024-06-15',
          searchVolume: 953,
          organicRank: 124,

          keywordSales: 171
        },
        {
          date: '2024-06-14',
          searchVolume: 2428,
          organicRank: 127,

          keywordSales: 171
        },
        {
          date: '2024-06-13',
          searchVolume: 2428,
          organicRank: 125,

          keywordSales: 171
        },
        {
          date: '2024-06-12',
          searchVolume: 2428,
          organicRank: 123,

          keywordSales: 171
        },
        {
          date: '2024-06-11',
          searchVolume: 2428,
          organicRank: 121,

          keywordSales: 171
        },
        {
          date: '2024-06-10',
          searchVolume: 2428,
          organicRank: 121,

          keywordSales: 171
        },
        {
          date: '2024-06-09',
          searchVolume: 2428,
          organicRank: 122,

          keywordSales: 171
        },
        {
          date: '2024-06-08',
          searchVolume: 2428,
          organicRank: 120,

          keywordSales: 171
        },
        {
          date: '2024-06-07',
          searchVolume: 1830,
          organicRank: 115,

          keywordSales: 171
        },
        {
          date: '2024-06-06',
          searchVolume: 1830,
          organicRank: 133,

          keywordSales: 171
        },
        {
          date: '2024-06-05',
          searchVolume: 1830,
          organicRank: 139,

          keywordSales: 171
        },
        {
          date: '2024-06-04',
          searchVolume: 1830,
          organicRank: 127,

          keywordSales: 171
        },
        {
          date: '2024-06-03',
          searchVolume: 1830,
          organicRank: 117,

          keywordSales: 171
        },
        {
          date: '2024-06-02',
          searchVolume: 1830,
          organicRank: 111,

          keywordSales: 171
        },
        {
          date: '2024-06-01',
          searchVolume: 1830,
          organicRank: 116,

          keywordSales: 171
        },
        {
          date: '2024-05-31',
          searchVolume: 1666,
          organicRank: 130,

          keywordSales: 171
        },
        {
          date: '2024-05-30',
          searchVolume: 1666,
          organicRank: 98,

          keywordSales: 171
        },
        {
          date: '2024-05-29',
          searchVolume: 1666,
          organicRank: 102,

          keywordSales: 171
        },
        {
          date: '2024-05-28',
          searchVolume: 1666,
          organicRank: 100,

          keywordSales: 171
        },
        {
          date: '2024-05-27',
          searchVolume: 1666,
          organicRank: 125,

          keywordSales: 171
        },
        {
          date: '2024-05-26',
          searchVolume: 1666,
          organicRank: 121,

          keywordSales: 171
        },
        {
          date: '2024-05-25',
          searchVolume: 1666,
          organicRank: 251,

          keywordSales: 171
        },
        {
          date: '2024-05-24',
          searchVolume: 1900,
          organicRank: 118,

          keywordSales: 171
        },
        {
          date: '2024-05-21',
          searchVolume: 1900,
          organicRank: 147,

          keywordSales: 171
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_ring cameras outdoor wireless`,
      keyword: 'ring cameras outdoor wireless',
      searchVolume: 2394,
      organicStartRank: 94,
      organicHighestRank: 56,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 3128,
          organicRank: 152,

          keywordSales: 47
        },
        {
          date: '2024-07-03',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 47
        },
        {
          date: '2024-07-02',
          searchVolume: 3128,
          organicRank: 149,

          keywordSales: 47
        },
        {
          date: '2024-07-01',
          searchVolume: 3128,
          organicRank: 87,

          keywordSales: 47
        },
        {
          date: '2024-06-30',
          searchVolume: 3128,
          organicRank: 68,

          keywordSales: 47
        },
        {
          date: '2024-06-29',
          searchVolume: 3128,
          organicRank: 80,

          keywordSales: 47
        },
        {
          date: '2024-06-28',
          searchVolume: 2539,
          organicRank: 70,

          keywordSales: 47
        },
        {
          date: '2024-06-27',
          searchVolume: 2539,
          organicRank: 71,

          keywordSales: 47
        },
        {
          date: '2024-06-26',
          searchVolume: 2539,
          organicRank: 68,

          keywordSales: 47
        },
        {
          date: '2024-06-25',
          searchVolume: 2539,
          organicRank: 64,

          keywordSales: 47
        },
        {
          date: '2024-06-23',
          searchVolume: 2539,
          organicRank: 58,

          keywordSales: 47
        },
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 75,

          keywordSales: 47
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 57,

          keywordSales: 47
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 74,

          keywordSales: 47
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 91,

          keywordSales: 47
        },
        {
          date: '2024-06-17',
          searchVolume: 2550,
          organicRank: 127,

          keywordSales: 47
        },
        {
          date: '2024-06-16',
          searchVolume: 2550,
          organicRank: 106,

          keywordSales: 47
        },
        {
          date: '2024-06-15',
          searchVolume: 2550,
          organicRank: 71,

          keywordSales: 47
        },
        {
          date: '2024-06-14',
          searchVolume: 2548,
          organicRank: 108,

          keywordSales: 47
        },
        {
          date: '2024-06-13',
          searchVolume: 2548,
          organicRank: 87,

          keywordSales: 47
        },
        {
          date: '2024-06-12',
          searchVolume: 2548,
          organicRank: 82,

          keywordSales: 47
        },
        {
          date: '2024-06-11',
          searchVolume: 2548,
          organicRank: 80,

          keywordSales: 47
        },
        {
          date: '2024-06-10',
          searchVolume: 2548,
          organicRank: 80,

          keywordSales: 47
        },
        {
          date: '2024-06-09',
          searchVolume: 2548,
          organicRank: 88,

          keywordSales: 47
        },
        {
          date: '2024-06-08',
          searchVolume: 2548,
          organicRank: 86,

          keywordSales: 47
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 85,

          keywordSales: 47
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 92,

          keywordSales: 47
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 95,

          keywordSales: 47
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 83,

          keywordSales: 47
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 74,

          keywordSales: 47
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 79,

          keywordSales: 47
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 56,

          keywordSales: 47
        },
        {
          date: '2024-05-31',
          searchVolume: 2392,
          organicRank: 72,

          keywordSales: 47
        },
        {
          date: '2024-05-30',
          searchVolume: 2392,
          organicRank: 66,

          keywordSales: 47
        },
        {
          date: '2024-05-29',
          searchVolume: 2392,
          organicRank: 255,

          keywordSales: 47
        },
        {
          date: '2024-05-28',
          searchVolume: 2392,
          organicRank: 255,

          keywordSales: 47
        },
        {
          date: '2024-05-27',
          searchVolume: 2392,
          organicRank: 95,

          keywordSales: 47
        },
        {
          date: '2024-05-26',
          searchVolume: 2392,
          organicRank: 79,

          keywordSales: 47
        },
        {
          date: '2024-05-25',
          searchVolume: 2392,
          organicRank: 69,

          keywordSales: 47
        },
        {
          date: '2024-05-24',
          searchVolume: 2394,
          organicRank: 94,

          keywordSales: 47
        },
        {
          date: '2024-05-23',
          searchVolume: 2394,
          organicRank: 94,

          keywordSales: 47
        },
        {
          date: '2024-05-22',
          searchVolume: 2394,
          organicRank: 94,

          keywordSales: 47
        },
        {
          date: '2024-05-20',
          searchVolume: 2394,
          organicRank: 110,

          keywordSales: 47
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_ring stick up cam`,
      keyword: 'ring stick up cam',
      searchVolume: 2390,
      organicStartRank: 110,
      organicHighestRank: 58,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 3238,
          organicRank: 141,

          keywordSales: 63
        },
        {
          date: '2024-07-03',
          searchVolume: 3238,
          organicRank: 142,

          keywordSales: 63
        },
        {
          date: '2024-07-02',
          searchVolume: 3238,
          organicRank: 133,

          keywordSales: 63
        },
        {
          date: '2024-07-01',
          searchVolume: 3238,
          organicRank: 112,

          keywordSales: 63
        },
        {
          date: '2024-06-30',
          searchVolume: 3238,
          organicRank: 110,

          keywordSales: 63
        },
        {
          date: '2024-06-29',
          searchVolume: 3238,
          organicRank: 112,

          keywordSales: 63
        },
        {
          date: '2024-06-28',
          searchVolume: 2539,
          organicRank: 102,

          keywordSales: 63
        },
        {
          date: '2024-06-27',
          searchVolume: 2539,
          organicRank: 104,

          keywordSales: 63
        },
        {
          date: '2024-06-26',
          searchVolume: 2539,
          organicRank: 101,

          keywordSales: 63
        },
        {
          date: '2024-06-25',
          searchVolume: 2539,
          organicRank: 102,

          keywordSales: 63
        },
        {
          date: '2024-06-23',
          searchVolume: 2539,
          organicRank: 96,

          keywordSales: 63
        },
        {
          date: '2024-06-21',
          searchVolume: 2552,
          organicRank: 91,

          keywordSales: 63
        },
        {
          date: '2024-06-20',
          searchVolume: 2552,
          organicRank: 95,

          keywordSales: 63
        },
        {
          date: '2024-06-19',
          searchVolume: 2552,
          organicRank: 72,

          keywordSales: 63
        },
        {
          date: '2024-06-18',
          searchVolume: 2552,
          organicRank: 94,

          keywordSales: 63
        },
        {
          date: '2024-06-17',
          searchVolume: 2552,
          organicRank: 108,

          keywordSales: 63
        },
        {
          date: '2024-06-16',
          searchVolume: 2552,
          organicRank: 86,

          keywordSales: 63
        },
        {
          date: '2024-06-15',
          searchVolume: 2552,
          organicRank: 106,

          keywordSales: 63
        },
        {
          date: '2024-06-14',
          searchVolume: 2394,
          organicRank: 145,

          keywordSales: 63
        },
        {
          date: '2024-06-13',
          searchVolume: 2394,
          organicRank: 138,

          keywordSales: 63
        },
        {
          date: '2024-06-12',
          searchVolume: 2394,
          organicRank: 133,

          keywordSales: 63
        },
        {
          date: '2024-06-11',
          searchVolume: 2394,
          organicRank: 141,

          keywordSales: 63
        },
        {
          date: '2024-06-10',
          searchVolume: 2394,
          organicRank: 106,

          keywordSales: 63
        },
        {
          date: '2024-06-09',
          searchVolume: 2394,
          organicRank: 102,

          keywordSales: 63
        },
        {
          date: '2024-06-08',
          searchVolume: 2394,
          organicRank: 95,

          keywordSales: 63
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 92,

          keywordSales: 63
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 81,

          keywordSales: 63
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 75,

          keywordSales: 63
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 75,

          keywordSales: 63
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 70,

          keywordSales: 63
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 101,

          keywordSales: 63
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 106,

          keywordSales: 63
        },
        {
          date: '2024-05-31',
          searchVolume: 3078,
          organicRank: 64,

          keywordSales: 63
        },
        {
          date: '2024-05-30',
          searchVolume: 3078,
          organicRank: 99,

          keywordSales: 63
        },
        {
          date: '2024-05-29',
          searchVolume: 3078,
          organicRank: 64,

          keywordSales: 63
        },
        {
          date: '2024-05-28',
          searchVolume: 3078,
          organicRank: 274,

          keywordSales: 63
        },
        {
          date: '2024-05-27',
          searchVolume: 3078,
          organicRank: 84,

          keywordSales: 63
        },
        {
          date: '2024-05-26',
          searchVolume: 3078,
          organicRank: 58,

          keywordSales: 63
        },
        {
          date: '2024-05-25',
          searchVolume: 3078,
          organicRank: 77,

          keywordSales: 63
        },
        {
          date: '2024-05-24',
          searchVolume: 2390,
          organicRank: 110,

          keywordSales: 63
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security camera`,
      keyword: 'security camera',
      searchVolume: 74184,
      organicStartRank: 278,
      organicHighestRank: 89,
      sponsoredStartRank: 27,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 74501,
          organicRank: 154,
          sponsoredRank: 54,
          keywordSales: 1269
        },
        {
          date: '2024-07-02',
          searchVolume: 74501,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 1269
        },
        {
          date: '2024-07-01',
          searchVolume: 74501,
          organicRank: 306,

          keywordSales: 1269
        },
        {
          date: '2024-06-30',
          searchVolume: 74501,
          organicRank: 306,

          keywordSales: 1269
        },
        {
          date: '2024-06-29',
          searchVolume: 74501,
          organicRank: 306,

          keywordSales: 1269
        },
        {
          date: '2024-06-28',
          searchVolume: 73138,
          organicRank: 142,
          sponsoredRank: 47,
          keywordSales: 1269
        },
        {
          date: '2024-06-27',
          searchVolume: 73138,
          organicRank: 108,
          sponsoredRank: 71,
          keywordSales: 1269
        },
        {
          date: '2024-06-26',
          searchVolume: 73138,
          organicRank: 101,

          keywordSales: 1269
        },
        {
          date: '2024-06-25',
          searchVolume: 73138,
          organicRank: 141,

          keywordSales: 1269
        },
        {
          date: '2024-06-24',
          searchVolume: 73138,
          organicRank: 134,
          sponsoredRank: 38,
          keywordSales: 1269
        },
        {
          date: '2024-06-23',
          searchVolume: 73138,
          organicRank: 137,
          sponsoredRank: 47,
          keywordSales: 1269
        },
        {
          date: '2024-06-22',
          searchVolume: 73138,
          organicRank: 134,
          sponsoredRank: 22,
          keywordSales: 1269
        },
        {
          date: '2024-06-21',
          searchVolume: 74444,
          organicRank: 121,

          keywordSales: 1269
        },
        {
          date: '2024-06-20',
          searchVolume: 74444,
          organicRank: 113,
          sponsoredRank: 76,
          keywordSales: 1269
        },
        {
          date: '2024-06-19',
          searchVolume: 74444,
          organicRank: 127,
          sponsoredRank: 25,
          keywordSales: 1269
        },
        {
          date: '2024-06-18',
          searchVolume: 74444,
          organicRank: 121,
          sponsoredRank: 64,
          keywordSales: 1269
        },
        {
          date: '2024-06-17',
          searchVolume: 74444,
          organicRank: 107,
          sponsoredRank: 71,
          keywordSales: 1269
        },
        {
          date: '2024-06-16',
          searchVolume: 74444,
          organicRank: 121,

          keywordSales: 1269
        },
        {
          date: '2024-06-15',
          searchVolume: 74444,
          organicRank: 133,
          sponsoredRank: 1,
          keywordSales: 1269
        },
        {
          date: '2024-06-14',
          searchVolume: 74394,
          organicRank: 139,
          sponsoredRank: 27,
          keywordSales: 1269
        },
        {
          date: '2024-06-13',
          searchVolume: 74394,
          organicRank: 141,
          sponsoredRank: 66,
          keywordSales: 1269
        },
        {
          date: '2024-06-12',
          searchVolume: 74394,
          organicRank: 306,

          keywordSales: 1269
        },
        {
          date: '2024-06-11',
          searchVolume: 74394,
          organicRank: 306,

          keywordSales: 1269
        },
        {
          date: '2024-06-10',
          searchVolume: 74394,
          organicRank: 137,

          keywordSales: 1269
        },
        {
          date: '2024-06-09',
          searchVolume: 74394,
          organicRank: 137,
          sponsoredRank: 82,
          keywordSales: 1269
        },
        {
          date: '2024-06-08',
          searchVolume: 74394,
          organicRank: 135,
          sponsoredRank: 65,
          keywordSales: 1269
        },
        {
          date: '2024-06-07',
          searchVolume: 67745,
          organicRank: 111,

          keywordSales: 1269
        },
        {
          date: '2024-06-06',
          searchVolume: 67745,
          organicRank: 111,
          sponsoredRank: 81,
          keywordSales: 1269
        },
        {
          date: '2024-06-05',
          searchVolume: 67745,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 1269
        },
        {
          date: '2024-06-04',
          searchVolume: 67745,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 1269
        },
        {
          date: '2024-06-03',
          searchVolume: 67745,
          organicRank: 306,

          keywordSales: 1269
        },
        {
          date: '2024-06-02',
          searchVolume: 67745,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 1269
        },
        {
          date: '2024-06-01',
          searchVolume: 67745,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 1269
        },
        {
          date: '2024-05-31',
          searchVolume: 69898,
          organicRank: 306,
          sponsoredRank: 2,
          keywordSales: 1269
        },
        {
          date: '2024-05-30',
          searchVolume: 69898,
          organicRank: 95,
          sponsoredRank: 89,
          keywordSales: 1269
        },
        {
          date: '2024-05-29',
          searchVolume: 69898,
          organicRank: 89,
          sponsoredRank: 60,
          keywordSales: 1269
        },
        {
          date: '2024-05-28',
          searchVolume: 69898,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 1269
        },
        {
          date: '2024-05-27',
          searchVolume: 69898,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 1269
        },
        {
          date: '2024-05-26',
          searchVolume: 69898,
          organicRank: 306,
          sponsoredRank: 4,
          keywordSales: 1269
        },
        {
          date: '2024-05-25',
          searchVolume: 69898,
          organicRank: 139,
          sponsoredRank: 71,
          keywordSales: 1269
        },
        {
          date: '2024-05-24',
          searchVolume: 74184,
          organicRank: 120,

          keywordSales: 1269
        },
        {
          date: '2024-05-23',
          searchVolume: 74184,
          organicRank: 122,
          sponsoredRank: 82,
          keywordSales: 1269
        },
        {
          date: '2024-05-21',
          searchVolume: 74184,
          organicRank: 278,
          sponsoredRank: 27,
          keywordSales: 1269
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security camera indoor`,
      keyword: 'security camera indoor',
      searchVolume: 18964,
      organicStartRank: 306,
      organicHighestRank: 80,
      sponsoredStartRank: 70,
      sponsoredHighestRank: 70,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 20434,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-07-02',
          searchVolume: 20434,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-07-01',
          searchVolume: 20434,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-30',
          searchVolume: 20434,
          organicRank: 93,

          keywordSales: 456
        },
        {
          date: '2024-06-29',
          searchVolume: 20434,
          organicRank: 83,

          keywordSales: 456
        },
        {
          date: '2024-06-28',
          searchVolume: 19874,
          organicRank: 85,
          sponsoredRank: 70,
          keywordSales: 456
        },
        {
          date: '2024-06-27',
          searchVolume: 19874,
          organicRank: 80,

          keywordSales: 456
        },
        {
          date: '2024-06-26',
          searchVolume: 19874,
          organicRank: 84,

          keywordSales: 456
        },
        {
          date: '2024-06-25',
          searchVolume: 19874,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-23',
          searchVolume: 19874,
          organicRank: 94,

          keywordSales: 456
        },
        {
          date: '2024-06-21',
          searchVolume: 19887,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-20',
          searchVolume: 19887,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-19',
          searchVolume: 19887,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-18',
          searchVolume: 19887,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-17',
          searchVolume: 19887,
          organicRank: 175,

          keywordSales: 456
        },
        {
          date: '2024-06-16',
          searchVolume: 19887,
          organicRank: 126,

          keywordSales: 456
        },
        {
          date: '2024-06-15',
          searchVolume: 19887,
          organicRank: 133,
          sponsoredRank: 80,
          keywordSales: 456
        },
        {
          date: '2024-06-14',
          searchVolume: 20526,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-13',
          searchVolume: 20526,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-12',
          searchVolume: 20526,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-11',
          searchVolume: 20526,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-10',
          searchVolume: 20526,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-09',
          searchVolume: 20526,
          organicRank: 306,
          sponsoredRank: 75,
          keywordSales: 456
        },
        {
          date: '2024-06-08',
          searchVolume: 20526,
          organicRank: 247,

          keywordSales: 456
        },
        {
          date: '2024-06-07',
          searchVolume: 18964,
          organicRank: 259,

          keywordSales: 456
        },
        {
          date: '2024-06-06',
          searchVolume: 18964,
          organicRank: 126,

          keywordSales: 456
        },
        {
          date: '2024-06-05',
          searchVolume: 18964,
          organicRank: 306,
          sponsoredRank: 70,
          keywordSales: 456
        },
        {
          date: '2024-06-04',
          searchVolume: 18964,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-03',
          searchVolume: 18964,
          organicRank: 106,

          keywordSales: 456
        },
        {
          date: '2024-06-02',
          searchVolume: 18964,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-06-01',
          searchVolume: 18964,
          organicRank: 101,

          keywordSales: 456
        },
        {
          date: '2024-05-31',
          searchVolume: 20197,
          organicRank: 109,

          keywordSales: 456
        },
        {
          date: '2024-05-30',
          searchVolume: 20197,
          organicRank: 111,

          keywordSales: 456
        },
        {
          date: '2024-05-29',
          searchVolume: 20197,
          organicRank: 102,

          keywordSales: 456
        },
        {
          date: '2024-05-28',
          searchVolume: 20197,
          organicRank: 107,

          keywordSales: 456
        },
        {
          date: '2024-05-27',
          searchVolume: 20197,
          organicRank: 105,

          keywordSales: 456
        },
        {
          date: '2024-05-26',
          searchVolume: 20197,
          organicRank: 105,

          keywordSales: 456
        },
        {
          date: '2024-05-25',
          searchVolume: 20197,
          organicRank: 306,

          keywordSales: 456
        },
        {
          date: '2024-05-24',
          searchVolume: 20603,
          organicRank: 306,

          keywordSales: 456
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security camera no internet needed`,
      keyword: 'security camera no internet needed',
      searchVolume: 2509,
      organicStartRank: 70,
      organicHighestRank: 31,
      sponsoredStartRank: 60,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-29',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2433,
          organicRank: 75
        },
        {
          date: '2024-07-02',
          searchVolume: 2433,
          organicRank: 75,
          sponsoredRank: 58
        },
        {
          date: '2024-07-01',
          searchVolume: 2433,
          organicRank: 66
        },
        {
          date: '2024-06-30',
          searchVolume: 2433,
          organicRank: 53
        },
        {
          date: '2024-06-29',
          searchVolume: 2433,
          organicRank: 49
        },
        {
          date: '2024-06-28',
          searchVolume: 2543,
          organicRank: 38
        },
        {
          date: '2024-06-27',
          searchVolume: 2543,
          organicRank: 43,
          sponsoredRank: 35
        },
        {
          date: '2024-06-26',
          searchVolume: 2543,
          organicRank: 42
        },
        {
          date: '2024-06-25',
          searchVolume: 2543,
          organicRank: 39
        },
        {
          date: '2024-06-24',
          searchVolume: 2543,
          organicRank: 38
        },
        {
          date: '2024-06-22',
          searchVolume: 2543,
          organicRank: 31
        },
        {
          date: '2024-06-21',
          searchVolume: 2552,
          organicRank: 32
        },
        {
          date: '2024-06-20',
          searchVolume: 2552,
          organicRank: 41,
          sponsoredRank: 1
        },
        {
          date: '2024-06-19',
          searchVolume: 2552,
          organicRank: 35
        },
        {
          date: '2024-06-18',
          searchVolume: 2552,
          organicRank: 44
        },
        {
          date: '2024-06-17',
          searchVolume: 2552,
          organicRank: 45
        },
        {
          date: '2024-06-16',
          searchVolume: 2552,
          organicRank: 35
        },
        {
          date: '2024-06-15',
          searchVolume: 2552,
          organicRank: 53
        },
        {
          date: '2024-06-14',
          searchVolume: 2353,
          organicRank: 101
        },
        {
          date: '2024-06-13',
          searchVolume: 2353,
          organicRank: 108
        },
        {
          date: '2024-06-12',
          searchVolume: 2353,
          organicRank: 102
        },
        {
          date: '2024-06-11',
          searchVolume: 2353,
          organicRank: 89,
          sponsoredRank: 23
        },
        {
          date: '2024-06-10',
          searchVolume: 2353,
          organicRank: 62
        },
        {
          date: '2024-06-09',
          searchVolume: 2353,
          organicRank: 66
        },
        {
          date: '2024-06-08',
          searchVolume: 2353,
          organicRank: 66
        },
        {
          date: '2024-06-07',
          searchVolume: 2085,
          organicRank: 65
        },
        {
          date: '2024-06-06',
          searchVolume: 2085,
          organicRank: 61
        },
        {
          date: '2024-06-05',
          searchVolume: 2085,
          organicRank: 58,
          sponsoredRank: 8
        },
        {
          date: '2024-06-04',
          searchVolume: 2085,
          organicRank: 60,
          sponsoredRank: 41
        },
        {
          date: '2024-06-03',
          searchVolume: 2085,
          organicRank: 60
        },
        {
          date: '2024-06-02',
          searchVolume: 2085,
          organicRank: 47
        },
        {
          date: '2024-06-01',
          searchVolume: 2085,
          organicRank: 49,
          sponsoredRank: 47
        },
        {
          date: '2024-05-31',
          searchVolume: 2394,
          organicRank: 46,
          sponsoredRank: 43
        },
        {
          date: '2024-05-30',
          searchVolume: 2394,
          organicRank: 49,
          sponsoredRank: 77
        },
        {
          date: '2024-05-29',
          searchVolume: 2394,
          organicRank: 39,
          sponsoredRank: 1
        },
        {
          date: '2024-05-28',
          searchVolume: 2394,
          organicRank: 55,
          sponsoredRank: 79
        },
        {
          date: '2024-05-27',
          searchVolume: 2394,
          organicRank: 55,
          sponsoredRank: 60
        },
        {
          date: '2024-05-26',
          searchVolume: 2394,
          organicRank: 45
        },
        {
          date: '2024-05-25',
          searchVolume: 2394,
          organicRank: 47
        },
        {
          date: '2024-05-24',
          searchVolume: 2509,
          organicRank: 52,
          sponsoredRank: 60
        },
        {
          date: '2024-05-21',
          searchVolume: 2509,
          organicRank: 70
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security camera outdoor`,
      keyword: 'security camera outdoor',
      searchVolume: 39889,
      organicStartRank: 112,
      organicHighestRank: 39,
      sponsoredStartRank: 10,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 44755,
          organicRank: 306,
          sponsoredRank: 29,
          keywordSales: 574
        },
        {
          date: '2024-07-03',
          searchVolume: 44755,
          organicRank: 306,
          sponsoredRank: 12,
          keywordSales: 574
        },
        {
          date: '2024-07-02',
          searchVolume: 44755,
          organicRank: 270,
          sponsoredRank: 62,
          keywordSales: 574
        },
        {
          date: '2024-07-01',
          searchVolume: 44755,
          organicRank: 246,

          keywordSales: 574
        },
        {
          date: '2024-06-30',
          searchVolume: 44755,
          organicRank: 129,

          keywordSales: 574
        },
        {
          date: '2024-06-29',
          searchVolume: 44755,
          organicRank: 102,

          keywordSales: 574
        },
        {
          date: '2024-06-28',
          searchVolume: 40388,
          organicRank: 306,
          sponsoredRank: 28,
          keywordSales: 574
        },
        {
          date: '2024-06-27',
          searchVolume: 40388,
          organicRank: 110,
          sponsoredRank: 33,
          keywordSales: 574
        },
        {
          date: '2024-06-26',
          searchVolume: 40388,
          organicRank: 134,

          keywordSales: 574
        },
        {
          date: '2024-06-25',
          searchVolume: 40388,
          organicRank: 102,

          keywordSales: 574
        },
        {
          date: '2024-06-24',
          searchVolume: 40388,
          organicRank: 109,
          sponsoredRank: 2,
          keywordSales: 574
        },
        {
          date: '2024-06-23',
          searchVolume: 40388,
          organicRank: 87,
          sponsoredRank: 11,
          keywordSales: 574
        },
        {
          date: '2024-06-22',
          searchVolume: 40388,
          organicRank: 105,
          sponsoredRank: 5,
          keywordSales: 574
        },
        {
          date: '2024-06-21',
          searchVolume: 42806,
          organicRank: 70,
          sponsoredRank: 59,
          keywordSales: 574
        },
        {
          date: '2024-06-20',
          searchVolume: 42806,
          organicRank: 112,
          sponsoredRank: 21,
          keywordSales: 574
        },
        {
          date: '2024-06-19',
          searchVolume: 42806,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 574
        },
        {
          date: '2024-06-18',
          searchVolume: 42806,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 574
        },
        {
          date: '2024-06-17',
          searchVolume: 42806,
          organicRank: 306,

          keywordSales: 574
        },
        {
          date: '2024-06-16',
          searchVolume: 42806,
          organicRank: 306,

          keywordSales: 574
        },
        {
          date: '2024-06-15',
          searchVolume: 42806,
          organicRank: 306,
          sponsoredRank: 32,
          keywordSales: 574
        },
        {
          date: '2024-06-14',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 574
        },
        {
          date: '2024-06-13',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 574
        },
        {
          date: '2024-06-12',
          searchVolume: 40512,
          organicRank: 306,

          keywordSales: 574
        },
        {
          date: '2024-06-11',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 574
        },
        {
          date: '2024-06-10',
          searchVolume: 40512,
          organicRank: 80,

          keywordSales: 574
        },
        {
          date: '2024-06-09',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 574
        },
        {
          date: '2024-06-08',
          searchVolume: 40512,
          organicRank: 306,
          sponsoredRank: 75,
          keywordSales: 574
        },
        {
          date: '2024-06-07',
          searchVolume: 38642,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 574
        },
        {
          date: '2024-06-06',
          searchVolume: 38642,
          organicRank: 71,

          keywordSales: 574
        },
        {
          date: '2024-06-05',
          searchVolume: 38642,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 574
        },
        {
          date: '2024-06-04',
          searchVolume: 38642,
          organicRank: 306,
          sponsoredRank: 20,
          keywordSales: 574
        },
        {
          date: '2024-06-03',
          searchVolume: 38642,
          organicRank: 306,

          keywordSales: 574
        },
        {
          date: '2024-06-02',
          searchVolume: 38642,
          organicRank: 61,
          sponsoredRank: 45,
          keywordSales: 574
        },
        {
          date: '2024-06-01',
          searchVolume: 38642,
          organicRank: 65,
          sponsoredRank: 77,
          keywordSales: 574
        },
        {
          date: '2024-05-31',
          searchVolume: 39883,
          organicRank: 58,
          sponsoredRank: 49,
          keywordSales: 574
        },
        {
          date: '2024-05-30',
          searchVolume: 39883,
          organicRank: 54,
          sponsoredRank: 65,
          keywordSales: 574
        },
        {
          date: '2024-05-29',
          searchVolume: 39883,
          organicRank: 39,
          sponsoredRank: 41,
          keywordSales: 574
        },
        {
          date: '2024-05-28',
          searchVolume: 39883,
          organicRank: 39,
          sponsoredRank: 81,
          keywordSales: 574
        },
        {
          date: '2024-05-27',
          searchVolume: 39883,
          organicRank: 306,
          sponsoredRank: 77,
          keywordSales: 574
        },
        {
          date: '2024-05-26',
          searchVolume: 39883,
          organicRank: 306,
          sponsoredRank: 4,
          keywordSales: 574
        },
        {
          date: '2024-05-25',
          searchVolume: 39883,
          organicRank: 94,
          sponsoredRank: 2,
          keywordSales: 574
        },
        {
          date: '2024-05-24',
          searchVolume: 39889,
          organicRank: 116,

          keywordSales: 574
        },
        {
          date: '2024-05-23',
          searchVolume: 39889,
          organicRank: 93,
          sponsoredRank: 44,
          keywordSales: 574
        },
        {
          date: '2024-05-22',
          searchVolume: 39889,
          organicRank: 112,
          sponsoredRank: 10,
          keywordSales: 574
        },
        {
          date: '2024-05-21',
          searchVolume: 39889,
          organicRank: 130,
          sponsoredRank: 13,
          keywordSales: 574
        },
        {
          date: '2024-05-20',
          searchVolume: 39889,
          organicRank: 118,
          sponsoredRank: 73,
          keywordSales: 574
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security camera system`,
      keyword: 'security camera system',
      searchVolume: 19400,
      organicStartRank: 118,
      organicHighestRank: 55,
      sponsoredStartRank: 89,
      sponsoredHighestRank: 21,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 19718,
          organicRank: 62,

          keywordSales: 269
        },
        {
          date: '2024-07-02',
          searchVolume: 19718,
          organicRank: 55,

          keywordSales: 269
        },
        {
          date: '2024-07-01',
          searchVolume: 19718,
          organicRank: 73,

          keywordSales: 269
        },
        {
          date: '2024-06-30',
          searchVolume: 19718,
          organicRank: 122,

          keywordSales: 269
        },
        {
          date: '2024-06-29',
          searchVolume: 19718,
          organicRank: 71,

          keywordSales: 269
        },
        {
          date: '2024-06-28',
          searchVolume: 19642,
          organicRank: 73,

          keywordSales: 269
        },
        {
          date: '2024-06-27',
          searchVolume: 19642,
          organicRank: 83,
          sponsoredRank: 21,
          keywordSales: 269
        },
        {
          date: '2024-06-26',
          searchVolume: 19642,
          organicRank: 87,

          keywordSales: 269
        },
        {
          date: '2024-06-25',
          searchVolume: 19642,
          organicRank: 68,

          keywordSales: 269
        },
        {
          date: '2024-06-24',
          searchVolume: 19642,
          organicRank: 68,

          keywordSales: 269
        },
        {
          date: '2024-06-22',
          searchVolume: 19642,
          organicRank: 72,

          keywordSales: 269
        },
        {
          date: '2024-06-21',
          searchVolume: 19703,
          organicRank: 74,

          keywordSales: 269
        },
        {
          date: '2024-06-20',
          searchVolume: 19703,
          organicRank: 76,

          keywordSales: 269
        },
        {
          date: '2024-06-19',
          searchVolume: 19703,
          organicRank: 98,

          keywordSales: 269
        },
        {
          date: '2024-06-18',
          searchVolume: 19703,
          organicRank: 75,

          keywordSales: 269
        },
        {
          date: '2024-06-17',
          searchVolume: 19703,
          organicRank: 63,

          keywordSales: 269
        },
        {
          date: '2024-06-16',
          searchVolume: 19703,
          organicRank: 306,

          keywordSales: 269
        },
        {
          date: '2024-06-15',
          searchVolume: 19703,
          organicRank: 62,

          keywordSales: 269
        },
        {
          date: '2024-06-14',
          searchVolume: 19703,
          organicRank: 72,

          keywordSales: 269
        },
        {
          date: '2024-06-13',
          searchVolume: 19703,
          organicRank: 90,

          keywordSales: 269
        },
        {
          date: '2024-06-12',
          searchVolume: 19703,
          organicRank: 151,

          keywordSales: 269
        },
        {
          date: '2024-06-11',
          searchVolume: 19703,
          organicRank: 129,

          keywordSales: 269
        },
        {
          date: '2024-06-10',
          searchVolume: 19703,
          organicRank: 166,

          keywordSales: 269
        },
        {
          date: '2024-06-09',
          searchVolume: 19703,
          organicRank: 179,

          keywordSales: 269
        },
        {
          date: '2024-06-08',
          searchVolume: 19703,
          organicRank: 306,

          keywordSales: 269
        },
        {
          date: '2024-06-07',
          searchVolume: 17155,
          organicRank: 181,

          keywordSales: 269
        },
        {
          date: '2024-06-06',
          searchVolume: 17155,
          organicRank: 146,

          keywordSales: 269
        },
        {
          date: '2024-06-05',
          searchVolume: 17155,
          organicRank: 306,
          sponsoredRank: 41,
          keywordSales: 269
        },
        {
          date: '2024-06-04',
          searchVolume: 17155,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 269
        },
        {
          date: '2024-06-03',
          searchVolume: 17155,
          organicRank: 100,

          keywordSales: 269
        },
        {
          date: '2024-06-02',
          searchVolume: 17155,
          organicRank: 93,
          sponsoredRank: 54,
          keywordSales: 269
        },
        {
          date: '2024-06-01',
          searchVolume: 17155,
          organicRank: 98,
          sponsoredRank: 96,
          keywordSales: 269
        },
        {
          date: '2024-05-31',
          searchVolume: 17703,
          organicRank: 110,
          sponsoredRank: 32,
          keywordSales: 269
        },
        {
          date: '2024-05-30',
          searchVolume: 17703,
          organicRank: 125,

          keywordSales: 269
        },
        {
          date: '2024-05-29',
          searchVolume: 17703,
          organicRank: 306,
          sponsoredRank: 69,
          keywordSales: 269
        },
        {
          date: '2024-05-28',
          searchVolume: 17703,
          organicRank: 91,
          sponsoredRank: 50,
          keywordSales: 269
        },
        {
          date: '2024-05-27',
          searchVolume: 17703,
          organicRank: 90,
          sponsoredRank: 78,
          keywordSales: 269
        },
        {
          date: '2024-05-26',
          searchVolume: 17703,
          organicRank: 128,

          keywordSales: 269
        },
        {
          date: '2024-05-25',
          searchVolume: 17703,
          organicRank: 70,

          keywordSales: 269
        },
        {
          date: '2024-05-24',
          searchVolume: 19400,
          organicRank: 91,

          keywordSales: 269
        },
        {
          date: '2024-05-22',
          searchVolume: 19400,
          organicRank: 118,
          sponsoredRank: 89,
          keywordSales: 269
        },
        {
          date: '2024-05-21',
          searchVolume: 19400,
          organicRank: 161,
          sponsoredRank: 95,
          keywordSales: 269
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security camera system wireless`,
      keyword: 'security camera system wireless',
      searchVolume: 2507,
      organicStartRank: 14,
      organicHighestRank: 12,
      sponsoredStartRank: 25,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-05-22',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2554,
          organicRank: 33
        },
        {
          date: '2024-07-02',
          searchVolume: 2554,
          organicRank: 30,
          sponsoredRank: 25
        },
        {
          date: '2024-07-01',
          searchVolume: 2554,
          organicRank: 28
        },
        {
          date: '2024-06-30',
          searchVolume: 2554,
          organicRank: 33
        },
        {
          date: '2024-06-29',
          searchVolume: 2554,
          organicRank: 32
        },
        {
          date: '2024-06-28',
          searchVolume: 2541,
          organicRank: 37,
          sponsoredRank: 64
        },
        {
          date: '2024-06-27',
          searchVolume: 2541,
          organicRank: 38,
          sponsoredRank: 5
        },
        {
          date: '2024-06-26',
          searchVolume: 2541,
          organicRank: 28,
          sponsoredRank: 11
        },
        {
          date: '2024-06-25',
          searchVolume: 2541,
          organicRank: 28
        },
        {
          date: '2024-06-24',
          searchVolume: 2541,
          organicRank: 39
        },
        {
          date: '2024-06-23',
          searchVolume: 2541,
          organicRank: 24,
          sponsoredRank: 7
        },
        {
          date: '2024-06-22',
          searchVolume: 2541,
          organicRank: 30
        },
        {
          date: '2024-06-21',
          searchVolume: 2391,
          organicRank: 25,
          sponsoredRank: 14
        },
        {
          date: '2024-06-20',
          searchVolume: 2391,
          organicRank: 37,
          sponsoredRank: 42
        },
        {
          date: '2024-06-19',
          searchVolume: 2391,
          organicRank: 29,
          sponsoredRank: 12
        },
        {
          date: '2024-06-18',
          searchVolume: 2391,
          organicRank: 24,
          sponsoredRank: 78
        },
        {
          date: '2024-06-17',
          searchVolume: 2391,
          organicRank: 20
        },
        {
          date: '2024-06-16',
          searchVolume: 2391,
          organicRank: 18,
          sponsoredRank: 35
        },
        {
          date: '2024-06-15',
          searchVolume: 2391,
          organicRank: 14,
          sponsoredRank: 54
        },
        {
          date: '2024-06-14',
          searchVolume: 2547,
          organicRank: 24,
          sponsoredRank: 59
        },
        {
          date: '2024-06-13',
          searchVolume: 2547,
          organicRank: 21,
          sponsoredRank: 74
        },
        {
          date: '2024-06-12',
          searchVolume: 2547,
          organicRank: 36
        },
        {
          date: '2024-06-11',
          searchVolume: 2547,
          organicRank: 35,
          sponsoredRank: 83
        },
        {
          date: '2024-06-10',
          searchVolume: 2547,
          organicRank: 55
        },
        {
          date: '2024-06-09',
          searchVolume: 2547,
          organicRank: 62
        },
        {
          date: '2024-06-08',
          searchVolume: 2547,
          organicRank: 104
        },
        {
          date: '2024-06-07',
          searchVolume: 2316,
          organicRank: 62
        },
        {
          date: '2024-06-06',
          searchVolume: 2316,
          organicRank: 82,
          sponsoredRank: 11
        },
        {
          date: '2024-06-05',
          searchVolume: 2316,
          organicRank: 70,
          sponsoredRank: 14
        },
        {
          date: '2024-06-04',
          searchVolume: 2316,
          organicRank: 71,
          sponsoredRank: 2
        },
        {
          date: '2024-06-03',
          searchVolume: 2316,
          organicRank: 66
        },
        {
          date: '2024-06-02',
          searchVolume: 2316,
          organicRank: 50
        },
        {
          date: '2024-06-01',
          searchVolume: 2316,
          organicRank: 51,
          sponsoredRank: 24
        },
        {
          date: '2024-05-31',
          searchVolume: 2511,
          organicRank: 41,
          sponsoredRank: 2
        },
        {
          date: '2024-05-30',
          searchVolume: 2511,
          organicRank: 29,
          sponsoredRank: 77
        },
        {
          date: '2024-05-29',
          searchVolume: 2511,
          organicRank: 43,
          sponsoredRank: 66
        },
        {
          date: '2024-05-28',
          searchVolume: 2511,
          organicRank: 39,
          sponsoredRank: 53
        },
        {
          date: '2024-05-27',
          searchVolume: 2511,
          organicRank: 23,
          sponsoredRank: 60
        },
        {
          date: '2024-05-26',
          searchVolume: 2511,
          organicRank: 33,
          sponsoredRank: 94
        },
        {
          date: '2024-05-25',
          searchVolume: 2511,
          organicRank: 19,
          sponsoredRank: 25
        },
        {
          date: '2024-05-24',
          searchVolume: 2507,
          organicRank: 51
        },
        {
          date: '2024-05-23',
          searchVolume: 2507,
          organicRank: 12
        },
        {
          date: '2024-05-22',
          searchVolume: 2507,
          organicRank: 14
        },
        {
          date: '2024-05-21',
          searchVolume: 2507,
          organicRank: 21
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security camera wireless`,
      keyword: 'security camera wireless',
      searchVolume: 2390,
      organicStartRank: 39,
      organicHighestRank: 17,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-25',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2355,
          organicRank: 39,

          keywordSales: 16
        },
        {
          date: '2024-07-02',
          searchVolume: 2355,
          organicRank: 40,

          keywordSales: 16
        },
        {
          date: '2024-07-01',
          searchVolume: 2355,
          organicRank: 32,

          keywordSales: 16
        },
        {
          date: '2024-06-30',
          searchVolume: 2355,
          organicRank: 33,

          keywordSales: 16
        },
        {
          date: '2024-06-29',
          searchVolume: 2355,
          organicRank: 20,

          keywordSales: 16
        },
        {
          date: '2024-06-28',
          searchVolume: 2344,
          organicRank: 21,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-06-27',
          searchVolume: 2344,
          organicRank: 25,
          sponsoredRank: 4,
          keywordSales: 16
        },
        {
          date: '2024-06-26',
          searchVolume: 2344,
          organicRank: 25,
          sponsoredRank: 22,
          keywordSales: 16
        },
        {
          date: '2024-06-25',
          searchVolume: 2344,
          organicRank: 24,

          keywordSales: 16
        },
        {
          date: '2024-06-22',
          searchVolume: 2344,
          organicRank: 36,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-06-21',
          searchVolume: 2398,
          organicRank: 28,

          keywordSales: 16
        },
        {
          date: '2024-06-20',
          searchVolume: 2398,
          organicRank: 34,
          sponsoredRank: 14,
          keywordSales: 16
        },
        {
          date: '2024-06-19',
          searchVolume: 2398,
          organicRank: 45,
          sponsoredRank: 10,
          keywordSales: 16
        },
        {
          date: '2024-06-18',
          searchVolume: 2398,
          organicRank: 39,
          sponsoredRank: 34,
          keywordSales: 16
        },
        {
          date: '2024-06-17',
          searchVolume: 2398,
          organicRank: 35,

          keywordSales: 16
        },
        {
          date: '2024-06-16',
          searchVolume: 2398,
          organicRank: 46,

          keywordSales: 16
        },
        {
          date: '2024-06-15',
          searchVolume: 2398,
          organicRank: 41,
          sponsoredRank: 76,
          keywordSales: 16
        },
        {
          date: '2024-06-14',
          searchVolume: 2548,
          organicRank: 33,
          sponsoredRank: 55,
          keywordSales: 16
        },
        {
          date: '2024-06-13',
          searchVolume: 2548,
          organicRank: 30,
          sponsoredRank: 23,
          keywordSales: 16
        },
        {
          date: '2024-06-12',
          searchVolume: 2548,
          organicRank: 40,
          sponsoredRank: 52,
          keywordSales: 16
        },
        {
          date: '2024-06-11',
          searchVolume: 2548,
          organicRank: 41,

          keywordSales: 16
        },
        {
          date: '2024-06-10',
          searchVolume: 2548,
          organicRank: 32,

          keywordSales: 16
        },
        {
          date: '2024-06-09',
          searchVolume: 2548,
          organicRank: 32,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-06-08',
          searchVolume: 2548,
          organicRank: 29,

          keywordSales: 16
        },
        {
          date: '2024-06-07',
          searchVolume: 2318,
          organicRank: 32,

          keywordSales: 16
        },
        {
          date: '2024-06-06',
          searchVolume: 2318,
          organicRank: 33,

          keywordSales: 16
        },
        {
          date: '2024-06-05',
          searchVolume: 2318,
          organicRank: 32,
          sponsoredRank: 9,
          keywordSales: 16
        },
        {
          date: '2024-06-04',
          searchVolume: 2318,
          organicRank: 38,

          keywordSales: 16
        },
        {
          date: '2024-06-03',
          searchVolume: 2318,
          organicRank: 34,

          keywordSales: 16
        },
        {
          date: '2024-06-02',
          searchVolume: 2318,
          organicRank: 36,

          keywordSales: 16
        },
        {
          date: '2024-06-01',
          searchVolume: 2318,
          organicRank: 30,

          keywordSales: 16
        },
        {
          date: '2024-05-31',
          searchVolume: 2140,
          organicRank: 29,

          keywordSales: 16
        },
        {
          date: '2024-05-30',
          searchVolume: 2140,
          organicRank: 25,

          keywordSales: 16
        },
        {
          date: '2024-05-29',
          searchVolume: 2140,
          organicRank: 20,

          keywordSales: 16
        },
        {
          date: '2024-05-28',
          searchVolume: 2140,
          organicRank: 19,

          keywordSales: 16
        },
        {
          date: '2024-05-27',
          searchVolume: 2140,
          organicRank: 17,

          keywordSales: 16
        },
        {
          date: '2024-05-26',
          searchVolume: 2140,
          organicRank: 18,
          sponsoredRank: 70,
          keywordSales: 16
        },
        {
          date: '2024-05-25',
          searchVolume: 2140,
          organicRank: 17,

          keywordSales: 16
        },
        {
          date: '2024-05-24',
          searchVolume: 2390,
          organicRank: 32,

          keywordSales: 16
        },
        {
          date: '2024-05-23',
          searchVolume: 2390,
          organicRank: 41,

          keywordSales: 16
        },
        {
          date: '2024-05-22',
          searchVolume: 2390,
          organicRank: 39,
          sponsoredRank: 11,
          keywordSales: 16
        },
        {
          date: '2024-05-21',
          searchVolume: 2390,
          organicRank: 55,
          sponsoredRank: 54,
          keywordSales: 16
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security cameras`,
      keyword: 'security cameras',
      searchVolume: 20146,
      organicStartRank: 306,
      organicHighestRank: 56,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 20960,
          organicRank: 84,

          keywordSales: 132
        },
        {
          date: '2024-07-02',
          searchVolume: 20960,
          organicRank: 83,

          keywordSales: 132
        },
        {
          date: '2024-07-01',
          searchVolume: 20960,
          organicRank: 101,

          keywordSales: 132
        },
        {
          date: '2024-06-30',
          searchVolume: 20960,
          organicRank: 105,

          keywordSales: 132
        },
        {
          date: '2024-06-29',
          searchVolume: 20960,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-28',
          searchVolume: 19883,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 132
        },
        {
          date: '2024-06-27',
          searchVolume: 19883,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-26',
          searchVolume: 19883,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-25',
          searchVolume: 19883,
          organicRank: 147,

          keywordSales: 132
        },
        {
          date: '2024-06-23',
          searchVolume: 19883,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-21',
          searchVolume: 20501,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-20',
          searchVolume: 20501,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-19',
          searchVolume: 20501,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-18',
          searchVolume: 20501,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-17',
          searchVolume: 20501,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-16',
          searchVolume: 20501,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-15',
          searchVolume: 20501,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-14',
          searchVolume: 20440,
          organicRank: 56,
          sponsoredRank: 9,
          keywordSales: 132
        },
        {
          date: '2024-06-13',
          searchVolume: 20440,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 132
        },
        {
          date: '2024-06-12',
          searchVolume: 20440,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-11',
          searchVolume: 20440,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-10',
          searchVolume: 20440,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-09',
          searchVolume: 20440,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-08',
          searchVolume: 20440,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-07',
          searchVolume: 19456,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-06',
          searchVolume: 19456,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-05',
          searchVolume: 19456,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-04',
          searchVolume: 19456,
          organicRank: 306,
          sponsoredRank: 82,
          keywordSales: 132
        },
        {
          date: '2024-06-03',
          searchVolume: 19456,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-02',
          searchVolume: 19456,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-06-01',
          searchVolume: 19456,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-05-31',
          searchVolume: 20587,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-05-30',
          searchVolume: 20587,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 132
        },
        {
          date: '2024-05-29',
          searchVolume: 20587,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-05-28',
          searchVolume: 20587,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-05-27',
          searchVolume: 20587,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-05-26',
          searchVolume: 20587,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 132
        },
        {
          date: '2024-05-25',
          searchVolume: 20587,
          organicRank: 306,

          keywordSales: 132
        },
        {
          date: '2024-05-24',
          searchVolume: 20146,
          organicRank: 306,

          keywordSales: 132
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security cameras outdoor`,
      keyword: 'security cameras outdoor',
      searchVolume: 3828,
      organicStartRank: 78,
      organicHighestRank: 29,
      sponsoredStartRank: 17,
      sponsoredHighestRank: 10,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 4327,
          organicRank: 71,

          keywordSales: 11
        },
        {
          date: '2024-07-02',
          searchVolume: 4327,
          organicRank: 64,

          keywordSales: 11
        },
        {
          date: '2024-07-01',
          searchVolume: 4327,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-30',
          searchVolume: 4327,
          organicRank: 49,

          keywordSales: 11
        },
        {
          date: '2024-06-29',
          searchVolume: 4327,
          organicRank: 58,

          keywordSales: 11
        },
        {
          date: '2024-06-28',
          searchVolume: 4311,
          organicRank: 70,

          keywordSales: 11
        },
        {
          date: '2024-06-27',
          searchVolume: 4311,
          organicRank: 51,

          keywordSales: 11
        },
        {
          date: '2024-06-26',
          searchVolume: 4311,
          organicRank: 58,

          keywordSales: 11
        },
        {
          date: '2024-06-25',
          searchVolume: 4311,
          organicRank: 35,

          keywordSales: 11
        },
        {
          date: '2024-06-22',
          searchVolume: 4311,
          organicRank: 43,
          sponsoredRank: 11,
          keywordSales: 11
        },
        {
          date: '2024-06-21',
          searchVolume: 4322,
          organicRank: 42,

          keywordSales: 11
        },
        {
          date: '2024-06-20',
          searchVolume: 4322,
          organicRank: 44,
          sponsoredRank: 47,
          keywordSales: 11
        },
        {
          date: '2024-06-19',
          searchVolume: 4322,
          organicRank: 61,
          sponsoredRank: 51,
          keywordSales: 11
        },
        {
          date: '2024-06-18',
          searchVolume: 4322,
          organicRank: 306,
          sponsoredRank: 46,
          keywordSales: 11
        },
        {
          date: '2024-06-17',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 11
        },
        {
          date: '2024-06-16',
          searchVolume: 4322,
          organicRank: 76,

          keywordSales: 11
        },
        {
          date: '2024-06-15',
          searchVolume: 4322,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 11
        },
        {
          date: '2024-06-14',
          searchVolume: 4322,
          organicRank: 75,
          sponsoredRank: 51,
          keywordSales: 11
        },
        {
          date: '2024-06-13',
          searchVolume: 4322,
          organicRank: 306,
          sponsoredRank: 37,
          keywordSales: 11
        },
        {
          date: '2024-06-12',
          searchVolume: 4322,
          organicRank: 74,

          keywordSales: 11
        },
        {
          date: '2024-06-11',
          searchVolume: 4322,
          organicRank: 306,
          sponsoredRank: 16,
          keywordSales: 11
        },
        {
          date: '2024-06-10',
          searchVolume: 4322,
          organicRank: 57,

          keywordSales: 11
        },
        {
          date: '2024-06-09',
          searchVolume: 4322,
          organicRank: 69,

          keywordSales: 11
        },
        {
          date: '2024-06-08',
          searchVolume: 4322,
          organicRank: 76,

          keywordSales: 11
        },
        {
          date: '2024-06-07',
          searchVolume: 4123,
          organicRank: 83,

          keywordSales: 11
        },
        {
          date: '2024-06-06',
          searchVolume: 4123,
          organicRank: 75,
          sponsoredRank: 13,
          keywordSales: 11
        },
        {
          date: '2024-06-05',
          searchVolume: 4123,
          organicRank: 76,
          sponsoredRank: 10,
          keywordSales: 11
        },
        {
          date: '2024-06-04',
          searchVolume: 4123,
          organicRank: 65,
          sponsoredRank: 33,
          keywordSales: 11
        },
        {
          date: '2024-06-03',
          searchVolume: 4123,
          organicRank: 68,

          keywordSales: 11
        },
        {
          date: '2024-06-02',
          searchVolume: 4123,
          organicRank: 56,

          keywordSales: 11
        },
        {
          date: '2024-06-01',
          searchVolume: 4123,
          organicRank: 42,
          sponsoredRank: 79,
          keywordSales: 11
        },
        {
          date: '2024-05-31',
          searchVolume: 4256,
          organicRank: 35,
          sponsoredRank: 42,
          keywordSales: 11
        },
        {
          date: '2024-05-30',
          searchVolume: 4256,
          organicRank: 35,

          keywordSales: 11
        },
        {
          date: '2024-05-29',
          searchVolume: 4256,
          organicRank: 29,
          sponsoredRank: 90,
          keywordSales: 11
        },
        {
          date: '2024-05-28',
          searchVolume: 4256,
          organicRank: 29,
          sponsoredRank: 68,
          keywordSales: 11
        },
        {
          date: '2024-05-27',
          searchVolume: 4256,
          organicRank: 32,
          sponsoredRank: 50,
          keywordSales: 11
        },
        {
          date: '2024-05-26',
          searchVolume: 4256,
          organicRank: 87,

          keywordSales: 11
        },
        {
          date: '2024-05-25',
          searchVolume: 4256,
          organicRank: 74,

          keywordSales: 11
        },
        {
          date: '2024-05-24',
          searchVolume: 3828,
          organicRank: 93,

          keywordSales: 11
        },
        {
          date: '2024-05-22',
          searchVolume: 3828,
          organicRank: 78,
          sponsoredRank: 17,
          keywordSales: 11
        },
        {
          date: '2024-05-21',
          searchVolume: 3828,
          organicRank: 93,

          keywordSales: 11
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security cameras wireless`,
      keyword: 'security cameras wireless',
      searchVolume: 3566,
      organicStartRank: 42,
      organicHighestRank: 23,
      sponsoredStartRank: 59,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 3627,
          organicRank: 47,

          keywordSales: 21
        },
        {
          date: '2024-07-02',
          searchVolume: 3627,
          organicRank: 49,

          keywordSales: 21
        },
        {
          date: '2024-07-01',
          searchVolume: 3627,
          organicRank: 44,

          keywordSales: 21
        },
        {
          date: '2024-06-30',
          searchVolume: 3627,
          organicRank: 41,

          keywordSales: 21
        },
        {
          date: '2024-06-29',
          searchVolume: 3627,
          organicRank: 60,

          keywordSales: 21
        },
        {
          date: '2024-06-28',
          searchVolume: 3415,
          organicRank: 36,

          keywordSales: 21
        },
        {
          date: '2024-06-27',
          searchVolume: 3415,
          organicRank: 43,

          keywordSales: 21
        },
        {
          date: '2024-06-26',
          searchVolume: 3415,
          organicRank: 42,

          keywordSales: 21
        },
        {
          date: '2024-06-25',
          searchVolume: 3415,
          organicRank: 85,

          keywordSales: 21
        },
        {
          date: '2024-06-24',
          searchVolume: 3415,
          organicRank: 81,

          keywordSales: 21
        },
        {
          date: '2024-06-22',
          searchVolume: 3415,
          organicRank: 106,

          keywordSales: 21
        },
        {
          date: '2024-06-21',
          searchVolume: 3432,
          organicRank: 78,

          keywordSales: 21
        },
        {
          date: '2024-06-20',
          searchVolume: 3432,
          organicRank: 46,

          keywordSales: 21
        },
        {
          date: '2024-06-19',
          searchVolume: 3432,
          organicRank: 51,

          keywordSales: 21
        },
        {
          date: '2024-06-18',
          searchVolume: 3432,
          organicRank: 41,

          keywordSales: 21
        },
        {
          date: '2024-06-17',
          searchVolume: 3432,
          organicRank: 43,

          keywordSales: 21
        },
        {
          date: '2024-06-16',
          searchVolume: 3432,
          organicRank: 62,

          keywordSales: 21
        },
        {
          date: '2024-06-15',
          searchVolume: 3432,
          organicRank: 43,

          keywordSales: 21
        },
        {
          date: '2024-06-14',
          searchVolume: 3237,
          organicRank: 38,
          sponsoredRank: 47,
          keywordSales: 21
        },
        {
          date: '2024-06-13',
          searchVolume: 3237,
          organicRank: 41,

          keywordSales: 21
        },
        {
          date: '2024-06-12',
          searchVolume: 3237,
          organicRank: 47,

          keywordSales: 21
        },
        {
          date: '2024-06-11',
          searchVolume: 3237,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-06-10',
          searchVolume: 3237,
          organicRank: 36,

          keywordSales: 21
        },
        {
          date: '2024-06-09',
          searchVolume: 3237,
          organicRank: 34,

          keywordSales: 21
        },
        {
          date: '2024-06-08',
          searchVolume: 3237,
          organicRank: 40,

          keywordSales: 21
        },
        {
          date: '2024-06-07',
          searchVolume: 3281,
          organicRank: 37,

          keywordSales: 21
        },
        {
          date: '2024-06-06',
          searchVolume: 3281,
          organicRank: 42,
          sponsoredRank: 14,
          keywordSales: 21
        },
        {
          date: '2024-06-05',
          searchVolume: 3281,
          organicRank: 38,
          sponsoredRank: 1,
          keywordSales: 21
        },
        {
          date: '2024-06-04',
          searchVolume: 3281,
          organicRank: 306,
          sponsoredRank: 3,
          keywordSales: 21
        },
        {
          date: '2024-06-03',
          searchVolume: 3281,
          organicRank: 48,
          sponsoredRank: 66,
          keywordSales: 21
        },
        {
          date: '2024-06-02',
          searchVolume: 3281,
          organicRank: 37,

          keywordSales: 21
        },
        {
          date: '2024-06-01',
          searchVolume: 3281,
          organicRank: 43,
          sponsoredRank: 80,
          keywordSales: 21
        },
        {
          date: '2024-05-31',
          searchVolume: 3380,
          organicRank: 41,
          sponsoredRank: 46,
          keywordSales: 21
        },
        {
          date: '2024-05-30',
          searchVolume: 3380,
          organicRank: 38,
          sponsoredRank: 69,
          keywordSales: 21
        },
        {
          date: '2024-05-29',
          searchVolume: 3380,
          organicRank: 29,
          sponsoredRank: 62,
          keywordSales: 21
        },
        {
          date: '2024-05-28',
          searchVolume: 3380,
          organicRank: 26,
          sponsoredRank: 60,
          keywordSales: 21
        },
        {
          date: '2024-05-27',
          searchVolume: 3380,
          organicRank: 24,
          sponsoredRank: 15,
          keywordSales: 21
        },
        {
          date: '2024-05-26',
          searchVolume: 3380,
          organicRank: 23,

          keywordSales: 21
        },
        {
          date: '2024-05-25',
          searchVolume: 3380,
          organicRank: 40,
          sponsoredRank: 59,
          keywordSales: 21
        },
        {
          date: '2024-05-24',
          searchVolume: 3566,
          organicRank: 306,

          keywordSales: 21
        },
        {
          date: '2024-05-23',
          searchVolume: 3566,
          organicRank: 44,

          keywordSales: 21
        },
        {
          date: '2024-05-22',
          searchVolume: 3566,
          organicRank: 42,

          keywordSales: 21
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security cameras wireless indoor`,
      keyword: 'security cameras wireless indoor',
      searchVolume: 8092,
      organicStartRank: 153,
      organicHighestRank: 55,
      sponsoredStartRank: 70,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 7719,
          organicRank: 105,

          keywordSales: 63
        },
        {
          date: '2024-07-02',
          searchVolume: 7719,
          organicRank: 135,
          sponsoredRank: 45,
          keywordSales: 63
        },
        {
          date: '2024-07-01',
          searchVolume: 7719,
          organicRank: 103,

          keywordSales: 63
        },
        {
          date: '2024-06-30',
          searchVolume: 7719,
          organicRank: 91,

          keywordSales: 63
        },
        {
          date: '2024-06-29',
          searchVolume: 7719,
          organicRank: 81,

          keywordSales: 63
        },
        {
          date: '2024-06-28',
          searchVolume: 6738,
          organicRank: 82,
          sponsoredRank: 57,
          keywordSales: 63
        },
        {
          date: '2024-06-27',
          searchVolume: 6738,
          organicRank: 93,

          keywordSales: 63
        },
        {
          date: '2024-06-26',
          searchVolume: 6738,
          organicRank: 90,

          keywordSales: 63
        },
        {
          date: '2024-06-25',
          searchVolume: 6738,
          organicRank: 106,

          keywordSales: 63
        },
        {
          date: '2024-06-22',
          searchVolume: 6738,
          organicRank: 108,

          keywordSales: 63
        },
        {
          date: '2024-06-21',
          searchVolume: 6755,
          organicRank: 96,

          keywordSales: 63
        },
        {
          date: '2024-06-20',
          searchVolume: 6755,
          organicRank: 82,

          keywordSales: 63
        },
        {
          date: '2024-06-19',
          searchVolume: 6755,
          organicRank: 91,

          keywordSales: 63
        },
        {
          date: '2024-06-18',
          searchVolume: 6755,
          organicRank: 88,

          keywordSales: 63
        },
        {
          date: '2024-06-17',
          searchVolume: 6755,
          organicRank: 99,

          keywordSales: 63
        },
        {
          date: '2024-06-16',
          searchVolume: 6755,
          organicRank: 95,

          keywordSales: 63
        },
        {
          date: '2024-06-15',
          searchVolume: 6755,
          organicRank: 104,

          keywordSales: 63
        },
        {
          date: '2024-06-14',
          searchVolume: 6757,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-13',
          searchVolume: 6757,
          organicRank: 78,

          keywordSales: 63
        },
        {
          date: '2024-06-12',
          searchVolume: 6757,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-11',
          searchVolume: 6757,
          organicRank: 306,

          keywordSales: 63
        },
        {
          date: '2024-06-10',
          searchVolume: 6757,
          organicRank: 118,

          keywordSales: 63
        },
        {
          date: '2024-06-09',
          searchVolume: 6757,
          organicRank: 95,
          sponsoredRank: 58,
          keywordSales: 63
        },
        {
          date: '2024-06-08',
          searchVolume: 6757,
          organicRank: 247,

          keywordSales: 63
        },
        {
          date: '2024-06-07',
          searchVolume: 6440,
          organicRank: 105,

          keywordSales: 63
        },
        {
          date: '2024-06-06',
          searchVolume: 6440,
          organicRank: 81,
          sponsoredRank: 42,
          keywordSales: 63
        },
        {
          date: '2024-06-05',
          searchVolume: 6440,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 63
        },
        {
          date: '2024-06-04',
          searchVolume: 6440,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 63
        },
        {
          date: '2024-06-03',
          searchVolume: 6440,
          organicRank: 60,

          keywordSales: 63
        },
        {
          date: '2024-06-02',
          searchVolume: 6440,
          organicRank: 64,

          keywordSales: 63
        },
        {
          date: '2024-06-01',
          searchVolume: 6440,
          organicRank: 67,
          sponsoredRank: 79,
          keywordSales: 63
        },
        {
          date: '2024-05-31',
          searchVolume: 7593,
          organicRank: 57,
          sponsoredRank: 32,
          keywordSales: 63
        },
        {
          date: '2024-05-30',
          searchVolume: 7593,
          organicRank: 64,
          sponsoredRank: 38,
          keywordSales: 63
        },
        {
          date: '2024-05-29',
          searchVolume: 7593,
          organicRank: 64,

          keywordSales: 63
        },
        {
          date: '2024-05-28',
          searchVolume: 7593,
          organicRank: 59,
          sponsoredRank: 62,
          keywordSales: 63
        },
        {
          date: '2024-05-27',
          searchVolume: 7593,
          organicRank: 55,
          sponsoredRank: 75,
          keywordSales: 63
        },
        {
          date: '2024-05-26',
          searchVolume: 7593,
          organicRank: 57,
          sponsoredRank: 70,
          keywordSales: 63
        },
        {
          date: '2024-05-25',
          searchVolume: 7593,
          organicRank: 56,

          keywordSales: 63
        },
        {
          date: '2024-05-24',
          searchVolume: 8092,
          organicRank: 94,

          keywordSales: 63
        },
        {
          date: '2024-05-22',
          searchVolume: 8092,
          organicRank: 153,

          keywordSales: 63
        },
        {
          date: '2024-05-21',
          searchVolume: 8092,
          organicRank: 129,

          keywordSales: 63
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security cameras wireless outdoor`,
      keyword: 'security cameras wireless outdoor',
      searchVolume: 153244,
      organicStartRank: 45,
      organicHighestRank: 12,
      sponsoredStartRank: 19,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-29',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 155695,
          organicRank: 26,
          sponsoredRank: 8,
          keywordSales: 1974
        },
        {
          date: '2024-07-03',
          searchVolume: 155695,
          organicRank: 23,
          sponsoredRank: 88,
          keywordSales: 1974
        },
        {
          date: '2024-07-02',
          searchVolume: 155695,
          organicRank: 16,
          sponsoredRank: 64,
          keywordSales: 1974
        },
        {
          date: '2024-07-01',
          searchVolume: 155695,
          organicRank: 17,

          keywordSales: 1974
        },
        {
          date: '2024-06-30',
          searchVolume: 155695,
          organicRank: 21,
          sponsoredRank: 47,
          keywordSales: 1974
        },
        {
          date: '2024-06-29',
          searchVolume: 155695,
          organicRank: 12,
          sponsoredRank: 15,
          keywordSales: 1974
        },
        {
          date: '2024-06-28',
          searchVolume: 154913,
          organicRank: 33,
          sponsoredRank: 16,
          keywordSales: 1974
        },
        {
          date: '2024-06-27',
          searchVolume: 154913,
          organicRank: 43,
          sponsoredRank: 15,
          keywordSales: 1974
        },
        {
          date: '2024-06-26',
          searchVolume: 154913,
          organicRank: 41,
          sponsoredRank: 33,
          keywordSales: 1974
        },
        {
          date: '2024-06-25',
          searchVolume: 154913,
          organicRank: 100,
          sponsoredRank: 20,
          keywordSales: 1974
        },
        {
          date: '2024-06-24',
          searchVolume: 154913,
          organicRank: 112,
          sponsoredRank: 17,
          keywordSales: 1974
        },
        {
          date: '2024-06-23',
          searchVolume: 154913,
          organicRank: 58,
          sponsoredRank: 9,
          keywordSales: 1974
        },
        {
          date: '2024-06-22',
          searchVolume: 154913,
          organicRank: 105,
          sponsoredRank: 3,
          keywordSales: 1974
        },
        {
          date: '2024-06-21',
          searchVolume: 166807,
          organicRank: 93,
          sponsoredRank: 42,
          keywordSales: 1974
        },
        {
          date: '2024-06-20',
          searchVolume: 166807,
          organicRank: 40,
          sponsoredRank: 36,
          keywordSales: 1974
        },
        {
          date: '2024-06-19',
          searchVolume: 166807,
          organicRank: 80,
          sponsoredRank: 18,
          keywordSales: 1974
        },
        {
          date: '2024-06-18',
          searchVolume: 166807,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 1974
        },
        {
          date: '2024-06-17',
          searchVolume: 166807,
          organicRank: 306,

          keywordSales: 1974
        },
        {
          date: '2024-06-16',
          searchVolume: 166807,
          organicRank: 22,
          sponsoredRank: 1,
          keywordSales: 1974
        },
        {
          date: '2024-06-15',
          searchVolume: 166807,
          organicRank: 38,
          sponsoredRank: 17,
          keywordSales: 1974
        },
        {
          date: '2024-06-14',
          searchVolume: 150056,
          organicRank: 43,
          sponsoredRank: 5,
          keywordSales: 1974
        },
        {
          date: '2024-06-13',
          searchVolume: 150056,
          organicRank: 44,
          sponsoredRank: 39,
          keywordSales: 1974
        },
        {
          date: '2024-06-12',
          searchVolume: 150056,
          organicRank: 35,

          keywordSales: 1974
        },
        {
          date: '2024-06-11',
          searchVolume: 150056,
          organicRank: 306,
          sponsoredRank: 87,
          keywordSales: 1974
        },
        {
          date: '2024-06-10',
          searchVolume: 150056,
          organicRank: 45,

          keywordSales: 1974
        },
        {
          date: '2024-06-09',
          searchVolume: 150056,
          organicRank: 44,
          sponsoredRank: 32,
          keywordSales: 1974
        },
        {
          date: '2024-06-08',
          searchVolume: 150056,
          organicRank: 41,
          sponsoredRank: 39,
          keywordSales: 1974
        },
        {
          date: '2024-06-07',
          searchVolume: 148000,
          organicRank: 40,
          sponsoredRank: 34,
          keywordSales: 1974
        },
        {
          date: '2024-06-06',
          searchVolume: 148000,
          organicRank: 34,

          keywordSales: 1974
        },
        {
          date: '2024-06-05',
          searchVolume: 148000,
          organicRank: 36,
          sponsoredRank: 28,
          keywordSales: 1974
        },
        {
          date: '2024-06-04',
          searchVolume: 148000,
          organicRank: 25,
          sponsoredRank: 2,
          keywordSales: 1974
        },
        {
          date: '2024-06-03',
          searchVolume: 148000,
          organicRank: 15,
          sponsoredRank: 37,
          keywordSales: 1974
        },
        {
          date: '2024-06-02',
          searchVolume: 148000,
          organicRank: 14,
          sponsoredRank: 39,
          keywordSales: 1974
        },
        {
          date: '2024-06-01',
          searchVolume: 148000,
          organicRank: 16,
          sponsoredRank: 36,
          keywordSales: 1974
        },
        {
          date: '2024-05-31',
          searchVolume: 152671,
          organicRank: 12,
          sponsoredRank: 58,
          keywordSales: 1974
        },
        {
          date: '2024-05-30',
          searchVolume: 152671,
          organicRank: 15,
          sponsoredRank: 60,
          keywordSales: 1974
        },
        {
          date: '2024-05-29',
          searchVolume: 152671,
          organicRank: 20,
          sponsoredRank: 23,
          keywordSales: 1974
        },
        {
          date: '2024-05-28',
          searchVolume: 152671,
          organicRank: 62,
          sponsoredRank: 79,
          keywordSales: 1974
        },
        {
          date: '2024-05-27',
          searchVolume: 152671,
          organicRank: 71,
          sponsoredRank: 43,
          keywordSales: 1974
        },
        {
          date: '2024-05-26',
          searchVolume: 152671,
          organicRank: 85,
          sponsoredRank: 49,
          keywordSales: 1974
        },
        {
          date: '2024-05-25',
          searchVolume: 152671,
          organicRank: 59,
          sponsoredRank: 51,
          keywordSales: 1974
        },
        {
          date: '2024-05-24',
          searchVolume: 153244,
          organicRank: 44,
          sponsoredRank: 63,
          keywordSales: 1974
        },
        {
          date: '2024-05-23',
          searchVolume: 153244,
          organicRank: 33,
          sponsoredRank: 17,
          keywordSales: 1974
        },
        {
          date: '2024-05-22',
          searchVolume: 153244,
          organicRank: 45,
          sponsoredRank: 19,
          keywordSales: 1974
        },
        {
          date: '2024-05-21',
          searchVolume: 153244,
          organicRank: 18,
          sponsoredRank: 7,
          keywordSales: 1974
        },
        {
          date: '2024-05-20',
          searchVolume: 153244,
          organicRank: 23,
          sponsoredRank: 69,
          keywordSales: 1974
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security system`,
      keyword: 'security system',
      searchVolume: 6651,
      organicStartRank: 120,
      organicHighestRank: 58,
      sponsoredStartRank: 20,
      sponsoredHighestRank: 20,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 6755,
          organicRank: 112,

          keywordSales: 40
        },
        {
          date: '2024-07-03',
          searchVolume: 6755,
          organicRank: 106,

          keywordSales: 40
        },
        {
          date: '2024-07-02',
          searchVolume: 6755,
          organicRank: 83,

          keywordSales: 40
        },
        {
          date: '2024-07-01',
          searchVolume: 6755,
          organicRank: 68,

          keywordSales: 40
        },
        {
          date: '2024-06-30',
          searchVolume: 6755,
          organicRank: 65,

          keywordSales: 40
        },
        {
          date: '2024-06-29',
          searchVolume: 6755,
          organicRank: 61,

          keywordSales: 40
        },
        {
          date: '2024-06-28',
          searchVolume: 5951,
          organicRank: 59,

          keywordSales: 40
        },
        {
          date: '2024-06-27',
          searchVolume: 5951,
          organicRank: 59,

          keywordSales: 40
        },
        {
          date: '2024-06-26',
          searchVolume: 5951,
          organicRank: 66,

          keywordSales: 40
        },
        {
          date: '2024-06-25',
          searchVolume: 5951,
          organicRank: 60,

          keywordSales: 40
        },
        {
          date: '2024-06-23',
          searchVolume: 5951,
          organicRank: 58,

          keywordSales: 40
        },
        {
          date: '2024-06-21',
          searchVolume: 5923,
          organicRank: 69,

          keywordSales: 40
        },
        {
          date: '2024-06-20',
          searchVolume: 5923,
          organicRank: 86,

          keywordSales: 40
        },
        {
          date: '2024-06-19',
          searchVolume: 5923,
          organicRank: 119,

          keywordSales: 40
        },
        {
          date: '2024-06-18',
          searchVolume: 5923,
          organicRank: 67,

          keywordSales: 40
        },
        {
          date: '2024-06-17',
          searchVolume: 5923,
          organicRank: 77,

          keywordSales: 40
        },
        {
          date: '2024-06-16',
          searchVolume: 5923,
          organicRank: 83,

          keywordSales: 40
        },
        {
          date: '2024-06-15',
          searchVolume: 5923,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-14',
          searchVolume: 5804,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-13',
          searchVolume: 5804,
          organicRank: 113,

          keywordSales: 40
        },
        {
          date: '2024-06-12',
          searchVolume: 5804,
          organicRank: 260,

          keywordSales: 40
        },
        {
          date: '2024-06-11',
          searchVolume: 5804,
          organicRank: 155,

          keywordSales: 40
        },
        {
          date: '2024-06-10',
          searchVolume: 5804,
          organicRank: 140,

          keywordSales: 40
        },
        {
          date: '2024-06-09',
          searchVolume: 5804,
          organicRank: 133,

          keywordSales: 40
        },
        {
          date: '2024-06-08',
          searchVolume: 5804,
          organicRank: 133,

          keywordSales: 40
        },
        {
          date: '2024-06-07',
          searchVolume: 6438,
          organicRank: 150,

          keywordSales: 40
        },
        {
          date: '2024-06-06',
          searchVolume: 6438,
          organicRank: 126,

          keywordSales: 40
        },
        {
          date: '2024-06-05',
          searchVolume: 6438,
          organicRank: 306,
          sponsoredRank: 66,
          keywordSales: 40
        },
        {
          date: '2024-06-04',
          searchVolume: 6438,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 40
        },
        {
          date: '2024-06-03',
          searchVolume: 6438,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-02',
          searchVolume: 6438,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-06-01',
          searchVolume: 6438,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-05-31',
          searchVolume: 5835,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-05-30',
          searchVolume: 5835,
          organicRank: 306,
          sponsoredRank: 54,
          keywordSales: 40
        },
        {
          date: '2024-05-29',
          searchVolume: 5835,
          organicRank: 108,

          keywordSales: 40
        },
        {
          date: '2024-05-28',
          searchVolume: 5835,
          organicRank: 115,

          keywordSales: 40
        },
        {
          date: '2024-05-27',
          searchVolume: 5835,
          organicRank: 151,

          keywordSales: 40
        },
        {
          date: '2024-05-26',
          searchVolume: 5835,
          organicRank: 82,

          keywordSales: 40
        },
        {
          date: '2024-05-25',
          searchVolume: 5835,
          organicRank: 144,

          keywordSales: 40
        },
        {
          date: '2024-05-24',
          searchVolume: 6651,
          organicRank: 143,

          keywordSales: 40
        },
        {
          date: '2024-05-22',
          searchVolume: 6651,
          organicRank: 120,
          sponsoredRank: 20,
          keywordSales: 40
        },
        {
          date: '2024-05-21',
          searchVolume: 6651,
          organicRank: 191,
          sponsoredRank: 25,
          keywordSales: 40
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_security systems for house`,
      keyword: 'security systems for house',
      searchVolume: 2511,
      organicStartRank: 127,
      organicHighestRank: 51,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 3128,
          organicRank: 115,

          keywordSales: 1
        },
        {
          date: '2024-07-03',
          searchVolume: 3128,
          organicRank: 112,

          keywordSales: 1
        },
        {
          date: '2024-07-02',
          searchVolume: 3128,
          organicRank: 102,

          keywordSales: 1
        },
        {
          date: '2024-07-01',
          searchVolume: 3128,
          organicRank: 90,

          keywordSales: 1
        },
        {
          date: '2024-06-30',
          searchVolume: 3128,
          organicRank: 87,

          keywordSales: 1
        },
        {
          date: '2024-06-29',
          searchVolume: 3128,
          organicRank: 87,

          keywordSales: 1
        },
        {
          date: '2024-06-28',
          searchVolume: 2544,
          organicRank: 125,

          keywordSales: 1
        },
        {
          date: '2024-06-27',
          searchVolume: 2544,
          organicRank: 85,

          keywordSales: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 2544,
          organicRank: 63,

          keywordSales: 1
        },
        {
          date: '2024-06-25',
          searchVolume: 2544,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-23',
          searchVolume: 2544,
          organicRank: 71,

          keywordSales: 1
        },
        {
          date: '2024-06-21',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 67,

          keywordSales: 1
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 86,

          keywordSales: 1
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 126,

          keywordSales: 1
        },
        {
          date: '2024-06-17',
          searchVolume: 3126,
          organicRank: 131,

          keywordSales: 1
        },
        {
          date: '2024-06-16',
          searchVolume: 3126,
          organicRank: 146,

          keywordSales: 1
        },
        {
          date: '2024-06-15',
          searchVolume: 3126,
          organicRank: 143,

          keywordSales: 1
        },
        {
          date: '2024-06-14',
          searchVolume: 2552,
          organicRank: 112,

          keywordSales: 1
        },
        {
          date: '2024-06-13',
          searchVolume: 2552,
          organicRank: 131,

          keywordSales: 1
        },
        {
          date: '2024-06-12',
          searchVolume: 2552,
          organicRank: 131,

          keywordSales: 1
        },
        {
          date: '2024-06-11',
          searchVolume: 2552,
          organicRank: 145,

          keywordSales: 1
        },
        {
          date: '2024-06-10',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 1
        },
        {
          date: '2024-06-09',
          searchVolume: 2552,
          organicRank: 142,

          keywordSales: 1
        },
        {
          date: '2024-06-08',
          searchVolume: 2552,
          organicRank: 170,

          keywordSales: 1
        },
        {
          date: '2024-06-07',
          searchVolume: 2982,
          organicRank: 149,

          keywordSales: 1
        },
        {
          date: '2024-06-06',
          searchVolume: 2982,
          organicRank: 152,

          keywordSales: 1
        },
        {
          date: '2024-06-05',
          searchVolume: 2982,
          organicRank: 136,

          keywordSales: 1
        },
        {
          date: '2024-06-04',
          searchVolume: 2982,
          organicRank: 134,

          keywordSales: 1
        },
        {
          date: '2024-06-03',
          searchVolume: 2982,
          organicRank: 136,

          keywordSales: 1
        },
        {
          date: '2024-06-02',
          searchVolume: 2982,
          organicRank: 126,

          keywordSales: 1
        },
        {
          date: '2024-06-01',
          searchVolume: 2982,
          organicRank: 236,

          keywordSales: 1
        },
        {
          date: '2024-05-31',
          searchVolume: 2513,
          organicRank: 121,

          keywordSales: 1
        },
        {
          date: '2024-05-30',
          searchVolume: 2513,
          organicRank: 115,

          keywordSales: 1
        },
        {
          date: '2024-05-29',
          searchVolume: 2513,
          organicRank: 120,

          keywordSales: 1
        },
        {
          date: '2024-05-28',
          searchVolume: 2513,
          organicRank: 120,

          keywordSales: 1
        },
        {
          date: '2024-05-27',
          searchVolume: 2513,
          organicRank: 59,

          keywordSales: 1
        },
        {
          date: '2024-05-26',
          searchVolume: 2513,
          organicRank: 85,

          keywordSales: 1
        },
        {
          date: '2024-05-25',
          searchVolume: 2513,
          organicRank: 51,

          keywordSales: 1
        },
        {
          date: '2024-05-24',
          searchVolume: 2511,
          organicRank: 306,
          sponsoredRank: 9,
          keywordSales: 1
        },
        {
          date: '2024-05-23',
          searchVolume: 2511,
          organicRank: 135,

          keywordSales: 1
        },
        {
          date: '2024-05-22',
          searchVolume: 2511,
          organicRank: 127,

          keywordSales: 1
        },
        {
          date: '2024-05-21',
          searchVolume: 2511,
          organicRank: 139,

          keywordSales: 1
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_simplisafe security system`,
      keyword: 'simplisafe security system',
      searchVolume: 4257,
      organicStartRank: 49,
      organicHighestRank: 39,
      sponsoredStartRank: 21,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 4325,
          organicRank: 67,

          keywordSales: 26
        },
        {
          date: '2024-07-02',
          searchVolume: 4325,
          organicRank: 58,

          keywordSales: 26
        },
        {
          date: '2024-07-01',
          searchVolume: 4325,
          organicRank: 65,

          keywordSales: 26
        },
        {
          date: '2024-06-30',
          searchVolume: 4325,
          organicRank: 47,

          keywordSales: 26
        },
        {
          date: '2024-06-29',
          searchVolume: 4325,
          organicRank: 44,

          keywordSales: 26
        },
        {
          date: '2024-06-28',
          searchVolume: 3805,
          organicRank: 45,
          sponsoredRank: 3,
          keywordSales: 26
        },
        {
          date: '2024-06-27',
          searchVolume: 3805,
          organicRank: 45,

          keywordSales: 26
        },
        {
          date: '2024-06-26',
          searchVolume: 3805,
          organicRank: 44,

          keywordSales: 26
        },
        {
          date: '2024-06-25',
          searchVolume: 3805,
          organicRank: 40,

          keywordSales: 26
        },
        {
          date: '2024-06-24',
          searchVolume: 3805,
          organicRank: 45,

          keywordSales: 26
        },
        {
          date: '2024-06-22',
          searchVolume: 3805,
          organicRank: 43,

          keywordSales: 26
        },
        {
          date: '2024-06-21',
          searchVolume: 3629,
          organicRank: 41,

          keywordSales: 26
        },
        {
          date: '2024-06-20',
          searchVolume: 3629,
          organicRank: 44,
          sponsoredRank: 16,
          keywordSales: 26
        },
        {
          date: '2024-06-19',
          searchVolume: 3629,
          organicRank: 44,

          keywordSales: 26
        },
        {
          date: '2024-06-18',
          searchVolume: 3629,
          organicRank: 50,
          sponsoredRank: 20,
          keywordSales: 26
        },
        {
          date: '2024-06-17',
          searchVolume: 3629,
          organicRank: 47,

          keywordSales: 26
        },
        {
          date: '2024-06-16',
          searchVolume: 3629,
          organicRank: 50,

          keywordSales: 26
        },
        {
          date: '2024-06-15',
          searchVolume: 3629,
          organicRank: 57,
          sponsoredRank: 6,
          keywordSales: 26
        },
        {
          date: '2024-06-14',
          searchVolume: 4918,
          organicRank: 49,
          sponsoredRank: 19,
          keywordSales: 26
        },
        {
          date: '2024-06-13',
          searchVolume: 4918,
          organicRank: 61,
          sponsoredRank: 35,
          keywordSales: 26
        },
        {
          date: '2024-06-12',
          searchVolume: 4918,
          organicRank: 66,

          keywordSales: 26
        },
        {
          date: '2024-06-11',
          searchVolume: 4918,
          organicRank: 70,

          keywordSales: 26
        },
        {
          date: '2024-06-10',
          searchVolume: 4918,
          organicRank: 55,

          keywordSales: 26
        },
        {
          date: '2024-06-09',
          searchVolume: 4918,
          organicRank: 60,
          sponsoredRank: 10,
          keywordSales: 26
        },
        {
          date: '2024-06-08',
          searchVolume: 4918,
          organicRank: 63,
          sponsoredRank: 20,
          keywordSales: 26
        },
        {
          date: '2024-06-07',
          searchVolume: 4344,
          organicRank: 59,

          keywordSales: 26
        },
        {
          date: '2024-06-06',
          searchVolume: 4344,
          organicRank: 55,

          keywordSales: 26
        },
        {
          date: '2024-06-05',
          searchVolume: 4344,
          organicRank: 65,

          keywordSales: 26
        },
        {
          date: '2024-06-04',
          searchVolume: 4344,
          organicRank: 62,

          keywordSales: 26
        },
        {
          date: '2024-06-03',
          searchVolume: 4344,
          organicRank: 52,

          keywordSales: 26
        },
        {
          date: '2024-06-02',
          searchVolume: 4344,
          organicRank: 58,

          keywordSales: 26
        },
        {
          date: '2024-06-01',
          searchVolume: 4344,
          organicRank: 56,

          keywordSales: 26
        },
        {
          date: '2024-05-31',
          searchVolume: 4482,
          organicRank: 47,
          sponsoredRank: 47,
          keywordSales: 26
        },
        {
          date: '2024-05-30',
          searchVolume: 4482,
          organicRank: 49,

          keywordSales: 26
        },
        {
          date: '2024-05-29',
          searchVolume: 4482,
          organicRank: 50,

          keywordSales: 26
        },
        {
          date: '2024-05-28',
          searchVolume: 4482,
          organicRank: 45,

          keywordSales: 26
        },
        {
          date: '2024-05-27',
          searchVolume: 4482,
          organicRank: 47,
          sponsoredRank: 21,
          keywordSales: 26
        },
        {
          date: '2024-05-26',
          searchVolume: 4482,
          organicRank: 45,

          keywordSales: 26
        },
        {
          date: '2024-05-25',
          searchVolume: 4482,
          organicRank: 39,

          keywordSales: 26
        },
        {
          date: '2024-05-24',
          searchVolume: 4257,
          organicRank: 40,

          keywordSales: 26
        },
        {
          date: '2024-05-23',
          searchVolume: 4257,
          organicRank: 50,

          keywordSales: 26
        },
        {
          date: '2024-05-22',
          searchVolume: 4257,
          organicRank: 49,

          keywordSales: 26
        },
        {
          date: '2024-05-21',
          searchVolume: 4257,
          organicRank: 69,

          keywordSales: 26
        },
        {
          date: '2024-05-20',
          searchVolume: 4257,
          organicRank: 56,

          keywordSales: 26
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_small camera`,
      keyword: 'small camera',
      searchVolume: 13206,
      organicStartRank: 289,
      organicHighestRank: 235,
      sponsoredStartRank: 68,
      sponsoredHighestRank: 38,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 12208,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-07-02',
          searchVolume: 12208,
          organicRank: 235,

          keywordSales: 329
        },
        {
          date: '2024-07-01',
          searchVolume: 12208,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-30',
          searchVolume: 12208,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-29',
          searchVolume: 12208,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-28',
          searchVolume: 12163,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-27',
          searchVolume: 12163,
          organicRank: 269,

          keywordSales: 329
        },
        {
          date: '2024-06-26',
          searchVolume: 12163,
          organicRank: 273,

          keywordSales: 329
        },
        {
          date: '2024-06-25',
          searchVolume: 12163,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-21',
          searchVolume: 13407,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-20',
          searchVolume: 13407,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-19',
          searchVolume: 13407,
          organicRank: 280,
          sponsoredRank: 42,
          keywordSales: 329
        },
        {
          date: '2024-06-18',
          searchVolume: 13407,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-17',
          searchVolume: 13407,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-16',
          searchVolume: 13407,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-15',
          searchVolume: 13407,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-14',
          searchVolume: 13418,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-13',
          searchVolume: 13418,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-12',
          searchVolume: 13418,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-11',
          searchVolume: 13418,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-10',
          searchVolume: 13418,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-09',
          searchVolume: 13418,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-08',
          searchVolume: 13418,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-07',
          searchVolume: 11637,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-06',
          searchVolume: 11637,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-05',
          searchVolume: 11637,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-04',
          searchVolume: 11637,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-03',
          searchVolume: 11637,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-02',
          searchVolume: 11637,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-06-01',
          searchVolume: 11637,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-05-31',
          searchVolume: 13203,
          organicRank: 273,

          keywordSales: 329
        },
        {
          date: '2024-05-30',
          searchVolume: 13203,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-05-29',
          searchVolume: 13203,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-05-28',
          searchVolume: 13203,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-05-27',
          searchVolume: 13203,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-05-26',
          searchVolume: 13203,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 329
        },
        {
          date: '2024-05-25',
          searchVolume: 13203,
          organicRank: 306,

          keywordSales: 329
        },
        {
          date: '2024-05-24',
          searchVolume: 13206,
          organicRank: 306,
          sponsoredRank: 38,
          keywordSales: 329
        },
        {
          date: '2024-05-21',
          searchVolume: 13206,
          organicRank: 289,
          sponsoredRank: 68,
          keywordSales: 329
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_small security camera`,
      keyword: 'small security camera',
      searchVolume: 1818,
      organicStartRank: 274,
      organicHighestRank: 90,
      sponsoredStartRank: 79,
      sponsoredHighestRank: 21,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2353,
          organicRank: 150,

          keywordSales: 54
        },
        {
          date: '2024-07-03',
          searchVolume: 2353,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-07-02',
          searchVolume: 2353,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-07-01',
          searchVolume: 2353,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-30',
          searchVolume: 2353,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-29',
          searchVolume: 2353,
          organicRank: 125,

          keywordSales: 54
        },
        {
          date: '2024-06-28',
          searchVolume: 1911,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 54
        },
        {
          date: '2024-06-27',
          searchVolume: 1911,
          organicRank: 240,

          keywordSales: 54
        },
        {
          date: '2024-06-26',
          searchVolume: 1911,
          organicRank: 123,

          keywordSales: 54
        },
        {
          date: '2024-06-25',
          searchVolume: 1911,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-23',
          searchVolume: 1911,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-21',
          searchVolume: 1685,
          organicRank: 306,
          sponsoredRank: 83,
          keywordSales: 54
        },
        {
          date: '2024-06-20',
          searchVolume: 1685,
          organicRank: 250,

          keywordSales: 54
        },
        {
          date: '2024-06-19',
          searchVolume: 1685,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-18',
          searchVolume: 1685,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-17',
          searchVolume: 1685,
          organicRank: 129,

          keywordSales: 54
        },
        {
          date: '2024-06-16',
          searchVolume: 1685,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-15',
          searchVolume: 1685,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-14',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-13',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-12',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-11',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-10',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-09',
          searchVolume: 1445,
          organicRank: 306,
          sponsoredRank: 37,
          keywordSales: 54
        },
        {
          date: '2024-06-08',
          searchVolume: 1445,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-07',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-06',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-05',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-04',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-03',
          searchVolume: 1586,
          organicRank: 269,

          keywordSales: 54
        },
        {
          date: '2024-06-02',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-06-01',
          searchVolume: 1586,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-05-31',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-05-30',
          searchVolume: 1895,
          organicRank: 90,

          keywordSales: 54
        },
        {
          date: '2024-05-29',
          searchVolume: 1895,
          organicRank: 90,

          keywordSales: 54
        },
        {
          date: '2024-05-28',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-05-27',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-05-26',
          searchVolume: 1895,
          organicRank: 306,
          sponsoredRank: 79,
          keywordSales: 54
        },
        {
          date: '2024-05-25',
          searchVolume: 1895,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-05-24',
          searchVolume: 1818,
          organicRank: 306,

          keywordSales: 54
        },
        {
          date: '2024-05-21',
          searchVolume: 1818,
          organicRank: 274,

          keywordSales: 54
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_smart wireless remote video doorbell`,
      keyword: 'smart wireless remote video doorbell',
      searchVolume: 2073,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 27,
      sponsoredHighestRank: 27,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-07-02',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-07-01',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-30',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-29',
          searchVolume: 1222,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-28',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-27',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-26',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-25',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-23',
          searchVolume: 1345,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-21',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-20',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-19',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-18',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-17',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-16',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-15',
          searchVolume: 2548,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-14',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-13',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-12',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-11',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-10',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-09',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-08',
          searchVolume: 1917,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-07',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-06',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-05',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-04',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-03',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-02',
          searchVolume: 2073,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-06-01',
          searchVolume: 2073,
          organicRank: 306,
          sponsoredRank: 27,
          keywordSales: 725
        },
        {
          date: '2024-05-31',
          searchVolume: 4477,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-05-30',
          searchVolume: 4477,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-05-29',
          searchVolume: 4477,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-05-28',
          searchVolume: 4477,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-05-27',
          searchVolume: 4477,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-05-26',
          searchVolume: 4477,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-05-25',
          searchVolume: 4477,
          organicRank: 306,

          keywordSales: 725
        },
        {
          date: '2024-05-24',
          searchVolume: 3758,
          organicRank: 306,

          keywordSales: 725
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_solar camera outdoor wireless`,
      keyword: 'solar camera outdoor wireless',
      searchVolume: 19703,
      organicStartRank: 252,
      organicHighestRank: 182,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 19718,
          organicRank: 273,

          keywordSales: 256
        },
        {
          date: '2024-07-02',
          searchVolume: 19718,
          organicRank: 293,

          keywordSales: 256
        },
        {
          date: '2024-07-01',
          searchVolume: 19718,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-30',
          searchVolume: 19718,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-29',
          searchVolume: 19718,
          organicRank: 288,

          keywordSales: 256
        },
        {
          date: '2024-06-28',
          searchVolume: 19642,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-27',
          searchVolume: 19642,
          organicRank: 182,

          keywordSales: 256
        },
        {
          date: '2024-06-26',
          searchVolume: 19642,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-25',
          searchVolume: 19642,
          organicRank: 273,

          keywordSales: 256
        },
        {
          date: '2024-06-24',
          searchVolume: 19642,
          organicRank: 197,

          keywordSales: 256
        },
        {
          date: '2024-06-23',
          searchVolume: 19642,
          organicRank: 250,

          keywordSales: 256
        },
        {
          date: '2024-06-22',
          searchVolume: 19642,
          organicRank: 250,

          keywordSales: 256
        },
        {
          date: '2024-06-21',
          searchVolume: 19703,
          organicRank: 236,

          keywordSales: 256
        },
        {
          date: '2024-06-20',
          searchVolume: 19703,
          organicRank: 199,

          keywordSales: 256
        },
        {
          date: '2024-06-19',
          searchVolume: 19703,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-18',
          searchVolume: 19703,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-17',
          searchVolume: 19703,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-16',
          searchVolume: 19703,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-15',
          searchVolume: 19703,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-14',
          searchVolume: 17666,
          organicRank: 255,

          keywordSales: 256
        },
        {
          date: '2024-06-13',
          searchVolume: 17666,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-12',
          searchVolume: 17666,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-11',
          searchVolume: 17666,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-10',
          searchVolume: 17666,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-09',
          searchVolume: 17666,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-08',
          searchVolume: 17666,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-07',
          searchVolume: 24069,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-06',
          searchVolume: 24069,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-05',
          searchVolume: 24069,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-04',
          searchVolume: 24069,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-03',
          searchVolume: 24069,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-02',
          searchVolume: 24069,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-06-01',
          searchVolume: 24069,
          organicRank: 303,

          keywordSales: 256
        },
        {
          date: '2024-05-31',
          searchVolume: 116716,
          organicRank: 262,

          keywordSales: 256
        },
        {
          date: '2024-05-30',
          searchVolume: 116716,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-05-29',
          searchVolume: 116716,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-05-28',
          searchVolume: 116716,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-05-27',
          searchVolume: 116716,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-05-26',
          searchVolume: 116716,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-05-25',
          searchVolume: 116716,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-05-24',
          searchVolume: 19400,
          organicRank: 306,

          keywordSales: 256
        },
        {
          date: '2024-05-23',
          searchVolume: 19400,
          organicRank: 278,

          keywordSales: 256
        },
        {
          date: '2024-05-22',
          searchVolume: 19400,
          organicRank: 252,

          keywordSales: 256
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_solar outdoor camera`,
      keyword: 'solar outdoor camera',
      searchVolume: 3641,
      organicStartRank: 306,
      organicHighestRank: 256,
      sponsoredStartRank: 63,
      sponsoredHighestRank: 63,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 4240,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-07-02',
          searchVolume: 4240,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-07-01',
          searchVolume: 4240,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-30',
          searchVolume: 4240,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-29',
          searchVolume: 4240,
          organicRank: 290,

          keywordSales: 103
        },
        {
          date: '2024-06-28',
          searchVolume: 4309,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-27',
          searchVolume: 4309,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-26',
          searchVolume: 4309,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-25',
          searchVolume: 4309,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-24',
          searchVolume: 4309,
          organicRank: 256,

          keywordSales: 103
        },
        {
          date: '2024-06-23',
          searchVolume: 4309,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-22',
          searchVolume: 4309,
          organicRank: 284,

          keywordSales: 103
        },
        {
          date: '2024-06-21',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-20',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-19',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-18',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-17',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-16',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-15',
          searchVolume: 4322,
          organicRank: 281,

          keywordSales: 103
        },
        {
          date: '2024-06-14',
          searchVolume: 3373,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-13',
          searchVolume: 3373,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-12',
          searchVolume: 3373,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-11',
          searchVolume: 3373,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-10',
          searchVolume: 3373,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-09',
          searchVolume: 3373,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-08',
          searchVolume: 3373,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-07',
          searchVolume: 3641,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-06',
          searchVolume: 3641,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-05',
          searchVolume: 3641,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-04',
          searchVolume: 3641,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-03',
          searchVolume: 3641,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-02',
          searchVolume: 3641,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-01',
          searchVolume: 3641,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-05-31',
          searchVolume: 3574,
          organicRank: 306,
          sponsoredRank: 63,
          keywordSales: 103
        },
        {
          date: '2024-05-30',
          searchVolume: 3574,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-05-29',
          searchVolume: 3574,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-05-28',
          searchVolume: 3574,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-05-27',
          searchVolume: 3574,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-05-26',
          searchVolume: 3574,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-05-25',
          searchVolume: 3574,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-05-24',
          searchVolume: 3566,
          organicRank: 306,

          keywordSales: 103
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_streaming camera`,
      keyword: 'streaming camera',
      searchVolume: 9019,
      organicStartRank: 268,
      organicHighestRank: 268,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-07-03',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-07-02',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-07-01',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-30',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-29',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-28',
          searchVolume: 8985,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-27',
          searchVolume: 8985,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-26',
          searchVolume: 8985,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-25',
          searchVolume: 8985,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-23',
          searchVolume: 8985,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-21',
          searchVolume: 9019,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-20',
          searchVolume: 9019,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-19',
          searchVolume: 9019,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-18',
          searchVolume: 9019,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-17',
          searchVolume: 9019,
          organicRank: 273,

          keywordSales: 211
        },
        {
          date: '2024-06-16',
          searchVolume: 9019,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-15',
          searchVolume: 9019,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-14',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-13',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-12',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-11',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-10',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-09',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-08',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-07',
          searchVolume: 7355,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-06',
          searchVolume: 7355,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-05',
          searchVolume: 7355,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-04',
          searchVolume: 7355,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-03',
          searchVolume: 7355,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-02',
          searchVolume: 7355,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-06-01',
          searchVolume: 7355,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-05-31',
          searchVolume: 8352,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-05-30',
          searchVolume: 8352,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-05-29',
          searchVolume: 8352,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-05-28',
          searchVolume: 8352,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-05-27',
          searchVolume: 8352,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-05-26',
          searchVolume: 8352,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-05-25',
          searchVolume: 8352,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-05-24',
          searchVolume: 7565,
          organicRank: 306,

          keywordSales: 211
        },
        {
          date: '2024-05-22',
          searchVolume: 7565,
          organicRank: 268,

          keywordSales: 211
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_surveillance security cameras`,
      keyword: 'surveillance security cameras',
      searchVolume: 68,
      organicStartRank: 306,
      organicHighestRank: 23,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-02',
          searchVolume: 67,
          organicRank: 148
        },
        {
          date: '2024-06-30',
          searchVolume: 67,
          organicRank: 306
        },
        {
          date: '2024-06-28',
          searchVolume: 63,
          organicRank: 41
        },
        {
          date: '2024-06-26',
          searchVolume: 63,
          organicRank: 110
        },
        {
          date: '2024-06-24',
          searchVolume: 63,
          organicRank: 44
        },
        {
          date: '2024-06-21',
          searchVolume: 66,
          organicRank: 23
        },
        {
          date: '2024-06-19',
          searchVolume: 66,
          organicRank: 51
        },
        {
          date: '2024-06-17',
          searchVolume: 66,
          organicRank: 85
        },
        {
          date: '2024-06-15',
          searchVolume: 66,
          organicRank: 110
        },
        {
          date: '2024-06-13',
          searchVolume: 63,
          organicRank: 306,
          sponsoredRank: 72
        },
        {
          date: '2024-06-11',
          searchVolume: 63,
          organicRank: 140
        },
        {
          date: '2024-06-09',
          searchVolume: 63,
          organicRank: 87,
          sponsoredRank: 23
        },
        {
          date: '2024-06-07',
          searchVolume: 61,
          organicRank: 306
        },
        {
          date: '2024-06-05',
          searchVolume: 61,
          organicRank: 252,
          sponsoredRank: 13
        },
        {
          date: '2024-06-03',
          searchVolume: 61,
          organicRank: 96
        },
        {
          date: '2024-06-01',
          searchVolume: 61,
          organicRank: 306
        },
        {
          date: '2024-05-30',
          searchVolume: 51,
          organicRank: 72
        },
        {
          date: '2024-05-28',
          searchVolume: 51,
          organicRank: 306
        },
        {
          date: '2024-05-26',
          searchVolume: 51,
          organicRank: 75
        },
        {
          date: '2024-05-24',
          searchVolume: 68,
          organicRank: 306
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_video camera for home`,
      keyword: 'video camera for home',
      searchVolume: 2085,
      organicStartRank: 306,
      organicHighestRank: 77,
      sponsoredStartRank: 16,
      sponsoredHighestRank: 16,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2175,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-07-03',
          searchVolume: 2175,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-07-02',
          searchVolume: 2175,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-07-01',
          searchVolume: 2175,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-30',
          searchVolume: 2175,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-29',
          searchVolume: 2175,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-28',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-27',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-26',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-25',
          searchVolume: 1913,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-24',
          searchVolume: 1913,
          organicRank: 77,

          keywordSales: 41
        },
        {
          date: '2024-06-21',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-20',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-19',
          searchVolume: 1919,
          organicRank: 184,

          keywordSales: 41
        },
        {
          date: '2024-06-18',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-17',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-16',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-15',
          searchVolume: 1919,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-14',
          searchVolume: 2430,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 41
        },
        {
          date: '2024-06-13',
          searchVolume: 2430,
          organicRank: 306,
          sponsoredRank: 44,
          keywordSales: 41
        },
        {
          date: '2024-06-12',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-11',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-10',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-09',
          searchVolume: 2430,
          organicRank: 306,
          sponsoredRank: 16,
          keywordSales: 41
        },
        {
          date: '2024-06-08',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-07',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-06',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-05',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-04',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-03',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-02',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-06-01',
          searchVolume: 2085,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-05-31',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-05-30',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-05-29',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-05-28',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-05-27',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-05-26',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-05-25',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 41
        },
        {
          date: '2024-05-24',
          searchVolume: 2356,
          organicRank: 306,

          keywordSales: 41
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wifi camera`,
      keyword: 'wifi camera',
      searchVolume: 18835,
      organicStartRank: 260,
      organicHighestRank: 151,
      sponsoredStartRank: 48,
      sponsoredHighestRank: 72,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 13451,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-07-02',
          searchVolume: 13451,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-07-01',
          searchVolume: 13451,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-30',
          searchVolume: 13451,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-29',
          searchVolume: 13451,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-28',
          searchVolume: 14107,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-27',
          searchVolume: 14107,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-26',
          searchVolume: 14107,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-25',
          searchVolume: 14107,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-21',
          searchVolume: 15634,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-20',
          searchVolume: 15634,
          organicRank: 186,

          keywordSales: 407
        },
        {
          date: '2024-06-19',
          searchVolume: 15634,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-18',
          searchVolume: 15634,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-17',
          searchVolume: 15634,
          organicRank: 268,

          keywordSales: 407
        },
        {
          date: '2024-06-16',
          searchVolume: 15634,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-15',
          searchVolume: 15634,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-14',
          searchVolume: 14131,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-13',
          searchVolume: 14131,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-12',
          searchVolume: 14131,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-11',
          searchVolume: 14131,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-10',
          searchVolume: 14131,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-09',
          searchVolume: 14131,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 407
        },
        {
          date: '2024-06-08',
          searchVolume: 14131,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-07',
          searchVolume: 13502,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-06',
          searchVolume: 13502,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-05',
          searchVolume: 13502,
          organicRank: 306,
          sponsoredRank: 73,
          keywordSales: 407
        },
        {
          date: '2024-06-04',
          searchVolume: 13502,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-03',
          searchVolume: 13502,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-02',
          searchVolume: 13502,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-06-01',
          searchVolume: 13502,
          organicRank: 158,

          keywordSales: 407
        },
        {
          date: '2024-05-31',
          searchVolume: 20197,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-05-30',
          searchVolume: 20197,
          organicRank: 151,

          keywordSales: 407
        },
        {
          date: '2024-05-29',
          searchVolume: 20197,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-05-28',
          searchVolume: 20197,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-05-27',
          searchVolume: 20197,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-05-26',
          searchVolume: 20197,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-05-25',
          searchVolume: 20197,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-05-24',
          searchVolume: 18835,
          organicRank: 306,

          keywordSales: 407
        },
        {
          date: '2024-05-21',
          searchVolume: 18835,
          organicRank: 260,
          sponsoredRank: 48,
          keywordSales: 407
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wifi camera indoor`,
      keyword: 'wifi camera indoor',
      searchVolume: 1582,
      organicStartRank: 306,
      organicHighestRank: 195,
      sponsoredStartRank: 56,
      sponsoredHighestRank: 56,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1853,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-07-02',
          searchVolume: 1853,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-07-01',
          searchVolume: 1853,
          organicRank: 230,

          keywordSales: 48
        },
        {
          date: '2024-06-30',
          searchVolume: 1853,
          organicRank: 254,

          keywordSales: 48
        },
        {
          date: '2024-06-29',
          searchVolume: 1853,
          organicRank: 218,

          keywordSales: 48
        },
        {
          date: '2024-06-28',
          searchVolume: 1654,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-27',
          searchVolume: 1654,
          organicRank: 222,
          sponsoredRank: 78,
          keywordSales: 48
        },
        {
          date: '2024-06-26',
          searchVolume: 1654,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-25',
          searchVolume: 1654,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-24',
          searchVolume: 1654,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-21',
          searchVolume: 1848,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-20',
          searchVolume: 1848,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-19',
          searchVolume: 1848,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-18',
          searchVolume: 1848,
          organicRank: 306,
          sponsoredRank: 94,
          keywordSales: 48
        },
        {
          date: '2024-06-17',
          searchVolume: 1848,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-16',
          searchVolume: 1848,
          organicRank: 260,

          keywordSales: 48
        },
        {
          date: '2024-06-15',
          searchVolume: 1848,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-14',
          searchVolume: 1659,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-13',
          searchVolume: 1659,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-12',
          searchVolume: 1659,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-11',
          searchVolume: 1659,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-10',
          searchVolume: 1659,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-09',
          searchVolume: 1659,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-08',
          searchVolume: 1659,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-07',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-06',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-05',
          searchVolume: 1582,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 48
        },
        {
          date: '2024-06-04',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-03',
          searchVolume: 1582,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-02',
          searchVolume: 1582,
          organicRank: 199,
          sponsoredRank: 56,
          keywordSales: 48
        },
        {
          date: '2024-06-01',
          searchVolume: 1582,
          organicRank: 259,

          keywordSales: 48
        },
        {
          date: '2024-05-31',
          searchVolume: 1891,
          organicRank: 259,

          keywordSales: 48
        },
        {
          date: '2024-05-30',
          searchVolume: 1891,
          organicRank: 272,

          keywordSales: 48
        },
        {
          date: '2024-05-29',
          searchVolume: 1891,
          organicRank: 195,

          keywordSales: 48
        },
        {
          date: '2024-05-28',
          searchVolume: 1891,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-05-27',
          searchVolume: 1891,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-05-26',
          searchVolume: 1891,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-05-25',
          searchVolume: 1891,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-05-24',
          searchVolume: 1884,
          organicRank: 306,

          keywordSales: 48
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wifi camera indoor wireless`,
      keyword: 'wifi camera indoor wireless',
      searchVolume: 4339,
      organicStartRank: 274,
      organicHighestRank: 137,
      sponsoredStartRank: 71,
      sponsoredHighestRank: 60,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 4911,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-07-02',
          searchVolume: 4911,
          organicRank: 172,

          keywordSales: 164
        },
        {
          date: '2024-07-01',
          searchVolume: 4911,
          organicRank: 157,

          keywordSales: 164
        },
        {
          date: '2024-06-30',
          searchVolume: 4911,
          organicRank: 253,

          keywordSales: 164
        },
        {
          date: '2024-06-29',
          searchVolume: 4911,
          organicRank: 159,

          keywordSales: 164
        },
        {
          date: '2024-06-28',
          searchVolume: 4542,
          organicRank: 137,

          keywordSales: 164
        },
        {
          date: '2024-06-27',
          searchVolume: 4542,
          organicRank: 152,

          keywordSales: 164
        },
        {
          date: '2024-06-26',
          searchVolume: 4542,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-25',
          searchVolume: 4542,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-23',
          searchVolume: 4542,
          organicRank: 187,

          keywordSales: 164
        },
        {
          date: '2024-06-21',
          searchVolume: 4335,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-20',
          searchVolume: 4335,
          organicRank: 279,

          keywordSales: 164
        },
        {
          date: '2024-06-19',
          searchVolume: 4335,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-18',
          searchVolume: 4335,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-17',
          searchVolume: 4335,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-16',
          searchVolume: 4335,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-15',
          searchVolume: 4335,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-14',
          searchVolume: 4547,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 164
        },
        {
          date: '2024-06-13',
          searchVolume: 4547,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 164
        },
        {
          date: '2024-06-12',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-11',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-10',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-09',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-08',
          searchVolume: 4547,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-07',
          searchVolume: 4339,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-06',
          searchVolume: 4339,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-05',
          searchVolume: 4339,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-04',
          searchVolume: 4339,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-06-03',
          searchVolume: 4339,
          organicRank: 174,

          keywordSales: 164
        },
        {
          date: '2024-06-02',
          searchVolume: 4339,
          organicRank: 284,

          keywordSales: 164
        },
        {
          date: '2024-06-01',
          searchVolume: 4339,
          organicRank: 286,

          keywordSales: 164
        },
        {
          date: '2024-05-31',
          searchVolume: 4480,
          organicRank: 168,

          keywordSales: 164
        },
        {
          date: '2024-05-30',
          searchVolume: 4480,
          organicRank: 176,

          keywordSales: 164
        },
        {
          date: '2024-05-29',
          searchVolume: 4480,
          organicRank: 184,

          keywordSales: 164
        },
        {
          date: '2024-05-28',
          searchVolume: 4480,
          organicRank: 254,

          keywordSales: 164
        },
        {
          date: '2024-05-27',
          searchVolume: 4480,
          organicRank: 170,

          keywordSales: 164
        },
        {
          date: '2024-05-26',
          searchVolume: 4480,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-05-25',
          searchVolume: 4480,
          organicRank: 188,

          keywordSales: 164
        },
        {
          date: '2024-05-24',
          searchVolume: 4482,
          organicRank: 306,

          keywordSales: 164
        },
        {
          date: '2024-05-23',
          searchVolume: 4482,
          organicRank: 274,

          keywordSales: 164
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wifi camera outdoor wireless`,
      keyword: 'wifi camera outdoor wireless',
      searchVolume: 8896,
      organicStartRank: 84,
      organicHighestRank: 63,
      sponsoredStartRank: 18,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 9284,
          organicRank: 127,

          keywordSales: 106
        },
        {
          date: '2024-07-03',
          searchVolume: 9284,
          organicRank: 107,

          keywordSales: 106
        },
        {
          date: '2024-07-02',
          searchVolume: 9284,
          organicRank: 112,
          sponsoredRank: 54,
          keywordSales: 106
        },
        {
          date: '2024-07-01',
          searchVolume: 9284,
          organicRank: 66,

          keywordSales: 106
        },
        {
          date: '2024-06-30',
          searchVolume: 9284,
          organicRank: 70,

          keywordSales: 106
        },
        {
          date: '2024-06-29',
          searchVolume: 9284,
          organicRank: 67,

          keywordSales: 106
        },
        {
          date: '2024-06-28',
          searchVolume: 9008,
          organicRank: 63,
          sponsoredRank: 18,
          keywordSales: 106
        },
        {
          date: '2024-06-27',
          searchVolume: 9008,
          organicRank: 66,
          sponsoredRank: 72,
          keywordSales: 106
        },
        {
          date: '2024-06-26',
          searchVolume: 9008,
          organicRank: 67,
          sponsoredRank: 50,
          keywordSales: 106
        },
        {
          date: '2024-06-25',
          searchVolume: 9008,
          organicRank: 73,

          keywordSales: 106
        },
        {
          date: '2024-06-24',
          searchVolume: 9008,
          organicRank: 71,

          keywordSales: 106
        },
        {
          date: '2024-06-23',
          searchVolume: 9008,
          organicRank: 73,
          sponsoredRank: 37,
          keywordSales: 106
        },
        {
          date: '2024-06-22',
          searchVolume: 9008,
          organicRank: 74,
          sponsoredRank: 12,
          keywordSales: 106
        },
        {
          date: '2024-06-21',
          searchVolume: 9013,
          organicRank: 73,
          sponsoredRank: 32,
          keywordSales: 106
        },
        {
          date: '2024-06-20',
          searchVolume: 9013,
          organicRank: 69,
          sponsoredRank: 32,
          keywordSales: 106
        },
        {
          date: '2024-06-19',
          searchVolume: 9013,
          organicRank: 306,
          sponsoredRank: 47,
          keywordSales: 106
        },
        {
          date: '2024-06-18',
          searchVolume: 9013,
          organicRank: 84,
          sponsoredRank: 48,
          keywordSales: 106
        },
        {
          date: '2024-06-17',
          searchVolume: 9013,
          organicRank: 126,

          keywordSales: 106
        },
        {
          date: '2024-06-16',
          searchVolume: 9013,
          organicRank: 130,

          keywordSales: 106
        },
        {
          date: '2024-06-15',
          searchVolume: 9013,
          organicRank: 93,

          keywordSales: 106
        },
        {
          date: '2024-06-14',
          searchVolume: 9021,
          organicRank: 91,
          sponsoredRank: 16,
          keywordSales: 106
        },
        {
          date: '2024-06-13',
          searchVolume: 9021,
          organicRank: 73,
          sponsoredRank: 49,
          keywordSales: 106
        },
        {
          date: '2024-06-12',
          searchVolume: 9021,
          organicRank: 306,

          keywordSales: 106
        },
        {
          date: '2024-06-11',
          searchVolume: 9021,
          organicRank: 306,

          keywordSales: 106
        },
        {
          date: '2024-06-10',
          searchVolume: 9021,
          organicRank: 306,

          keywordSales: 106
        },
        {
          date: '2024-06-09',
          searchVolume: 9021,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 106
        },
        {
          date: '2024-06-08',
          searchVolume: 9021,
          organicRank: 306,

          keywordSales: 106
        },
        {
          date: '2024-06-07',
          searchVolume: 7355,
          organicRank: 267,

          keywordSales: 106
        },
        {
          date: '2024-06-06',
          searchVolume: 7355,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 106
        },
        {
          date: '2024-06-05',
          searchVolume: 7355,
          organicRank: 306,
          sponsoredRank: 68,
          keywordSales: 106
        },
        {
          date: '2024-06-04',
          searchVolume: 7355,
          organicRank: 306,
          sponsoredRank: 48,
          keywordSales: 106
        },
        {
          date: '2024-06-03',
          searchVolume: 7355,
          organicRank: 306,

          keywordSales: 106
        },
        {
          date: '2024-06-02',
          searchVolume: 7355,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 106
        },
        {
          date: '2024-06-01',
          searchVolume: 7355,
          organicRank: 91,
          sponsoredRank: 69,
          keywordSales: 106
        },
        {
          date: '2024-05-31',
          searchVolume: 8896,
          organicRank: 100,
          sponsoredRank: 81,
          keywordSales: 106
        },
        {
          date: '2024-05-30',
          searchVolume: 8896,
          organicRank: 87,

          keywordSales: 106
        },
        {
          date: '2024-05-29',
          searchVolume: 8896,
          organicRank: 306,

          keywordSales: 106
        },
        {
          date: '2024-05-28',
          searchVolume: 8896,
          organicRank: 306,
          sponsoredRank: 84,
          keywordSales: 106
        },
        {
          date: '2024-05-27',
          searchVolume: 8896,
          organicRank: 306,
          sponsoredRank: 53,
          keywordSales: 106
        },
        {
          date: '2024-05-26',
          searchVolume: 8896,
          organicRank: 68,
          sponsoredRank: 11,
          keywordSales: 106
        },
        {
          date: '2024-05-25',
          searchVolume: 8896,
          organicRank: 106,
          sponsoredRank: 78,
          keywordSales: 106
        },
        {
          date: '2024-05-24',
          searchVolume: 8896,
          organicRank: 64,
          sponsoredRank: 32,
          keywordSales: 106
        },
        {
          date: '2024-05-23',
          searchVolume: 8896,
          organicRank: 77,
          sponsoredRank: 17,
          keywordSales: 106
        },
        {
          date: '2024-05-22',
          searchVolume: 8896,
          organicRank: 84,
          sponsoredRank: 18,
          keywordSales: 106
        },
        {
          date: '2024-05-21',
          searchVolume: 8896,
          organicRank: 88,
          sponsoredRank: 16,
          keywordSales: 106
        },
        {
          date: '2024-05-20',
          searchVolume: 8896,
          organicRank: 94,

          keywordSales: 106
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wifi cameras for home security`,
      keyword: 'wifi cameras for home security',
      searchVolume: 2138,
      organicStartRank: 247,
      organicHighestRank: 153,
      sponsoredStartRank: 12,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-07-02',
          searchVolume: 1928,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 44
        },
        {
          date: '2024-07-01',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-30',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-29',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-28',
          searchVolume: 1915,
          organicRank: 306,
          sponsoredRank: 18,
          keywordSales: 44
        },
        {
          date: '2024-06-27',
          searchVolume: 1915,
          organicRank: 153,
          sponsoredRank: 34,
          keywordSales: 44
        },
        {
          date: '2024-06-26',
          searchVolume: 1915,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-25',
          searchVolume: 1915,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-24',
          searchVolume: 1915,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-23',
          searchVolume: 1915,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-22',
          searchVolume: 1915,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-20',
          searchVolume: 2352,
          organicRank: 306,
          sponsoredRank: 23,
          keywordSales: 44
        },
        {
          date: '2024-06-19',
          searchVolume: 2352,
          organicRank: 306,
          sponsoredRank: 53,
          keywordSales: 44
        },
        {
          date: '2024-06-18',
          searchVolume: 2352,
          organicRank: 280,
          sponsoredRank: 84,
          keywordSales: 44
        },
        {
          date: '2024-06-17',
          searchVolume: 2352,
          organicRank: 263,

          keywordSales: 44
        },
        {
          date: '2024-06-16',
          searchVolume: 2352,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-15',
          searchVolume: 2352,
          organicRank: 306,
          sponsoredRank: 24,
          keywordSales: 44
        },
        {
          date: '2024-06-14',
          searchVolume: 2428,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 44
        },
        {
          date: '2024-06-13',
          searchVolume: 2428,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 44
        },
        {
          date: '2024-06-12',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-11',
          searchVolume: 2428,
          organicRank: 306,
          sponsoredRank: 43,
          keywordSales: 44
        },
        {
          date: '2024-06-10',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-09',
          searchVolume: 2428,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 44
        },
        {
          date: '2024-06-08',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-07',
          searchVolume: 2286,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-06',
          searchVolume: 2286,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 44
        },
        {
          date: '2024-06-05',
          searchVolume: 2286,
          organicRank: 306,
          sponsoredRank: 16,
          keywordSales: 44
        },
        {
          date: '2024-06-04',
          searchVolume: 2286,
          organicRank: 306,
          sponsoredRank: 31,
          keywordSales: 44
        },
        {
          date: '2024-06-03',
          searchVolume: 2286,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-02',
          searchVolume: 2286,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-06-01',
          searchVolume: 2286,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-31',
          searchVolume: 1900,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-30',
          searchVolume: 1900,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-29',
          searchVolume: 1900,
          organicRank: 275,

          keywordSales: 44
        },
        {
          date: '2024-05-28',
          searchVolume: 1900,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-27',
          searchVolume: 1900,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 44
        },
        {
          date: '2024-05-26',
          searchVolume: 1900,
          organicRank: 306,
          sponsoredRank: 35,
          keywordSales: 44
        },
        {
          date: '2024-05-25',
          searchVolume: 1900,
          organicRank: 306,
          sponsoredRank: 65,
          keywordSales: 44
        },
        {
          date: '2024-05-24',
          searchVolume: 2138,
          organicRank: 306,

          keywordSales: 44
        },
        {
          date: '2024-05-23',
          searchVolume: 2138,
          organicRank: 253,
          sponsoredRank: 20,
          keywordSales: 44
        },
        {
          date: '2024-05-21',
          searchVolume: 2138,
          organicRank: 247,
          sponsoredRank: 12,
          keywordSales: 44
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wifi cameras without subscription`,
      keyword: 'wifi cameras without subscription',
      searchVolume: 2390,
      organicStartRank: 61,
      organicHighestRank: 25,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 3630,
          organicRank: 70,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-07-02',
          searchVolume: 3630,
          organicRank: 76,
          sponsoredRank: 29,
          keywordSales: 16
        },
        {
          date: '2024-07-01',
          searchVolume: 3630,
          organicRank: 84,

          keywordSales: 16
        },
        {
          date: '2024-06-30',
          searchVolume: 3630,
          organicRank: 79,

          keywordSales: 16
        },
        {
          date: '2024-06-29',
          searchVolume: 3630,
          organicRank: 82,

          keywordSales: 16
        },
        {
          date: '2024-06-28',
          searchVolume: 3229,
          organicRank: 79,

          keywordSales: 16
        },
        {
          date: '2024-06-27',
          searchVolume: 3229,
          organicRank: 77,
          sponsoredRank: 9,
          keywordSales: 16
        },
        {
          date: '2024-06-26',
          searchVolume: 3229,
          organicRank: 69,

          keywordSales: 16
        },
        {
          date: '2024-06-25',
          searchVolume: 3229,
          organicRank: 70,

          keywordSales: 16
        },
        {
          date: '2024-06-24',
          searchVolume: 3229,
          organicRank: 64,

          keywordSales: 16
        },
        {
          date: '2024-06-22',
          searchVolume: 3229,
          organicRank: 97,

          keywordSales: 16
        },
        {
          date: '2024-06-21',
          searchVolume: 3126,
          organicRank: 68,

          keywordSales: 16
        },
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 39,
          sponsoredRank: 23,
          keywordSales: 16
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 58,
          sponsoredRank: 81,
          keywordSales: 16
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 57,
          sponsoredRank: 22,
          keywordSales: 16
        },
        {
          date: '2024-06-17',
          searchVolume: 3126,
          organicRank: 56,

          keywordSales: 16
        },
        {
          date: '2024-06-16',
          searchVolume: 3126,
          organicRank: 49,

          keywordSales: 16
        },
        {
          date: '2024-06-15',
          searchVolume: 3126,
          organicRank: 44,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-06-14',
          searchVolume: 2548,
          organicRank: 35,
          sponsoredRank: 72,
          keywordSales: 16
        },
        {
          date: '2024-06-13',
          searchVolume: 2548,
          organicRank: 50,
          sponsoredRank: 86,
          keywordSales: 16
        },
        {
          date: '2024-06-12',
          searchVolume: 2548,
          organicRank: 50,
          sponsoredRank: 25,
          keywordSales: 16
        },
        {
          date: '2024-06-11',
          searchVolume: 2548,
          organicRank: 50,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-06-10',
          searchVolume: 2548,
          organicRank: 46,

          keywordSales: 16
        },
        {
          date: '2024-06-09',
          searchVolume: 2548,
          organicRank: 84,

          keywordSales: 16
        },
        {
          date: '2024-06-08',
          searchVolume: 2548,
          organicRank: 62,

          keywordSales: 16
        },
        {
          date: '2024-06-07',
          searchVolume: 2982,
          organicRank: 85,

          keywordSales: 16
        },
        {
          date: '2024-06-06',
          searchVolume: 2982,
          organicRank: 65,
          sponsoredRank: 19,
          keywordSales: 16
        },
        {
          date: '2024-06-05',
          searchVolume: 2982,
          organicRank: 64,
          sponsoredRank: 21,
          keywordSales: 16
        },
        {
          date: '2024-06-04',
          searchVolume: 2982,
          organicRank: 67,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-06-03',
          searchVolume: 2982,
          organicRank: 52,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-06-02',
          searchVolume: 2982,
          organicRank: 49,

          keywordSales: 16
        },
        {
          date: '2024-06-01',
          searchVolume: 2982,
          organicRank: 53,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-05-31',
          searchVolume: 2507,
          organicRank: 48,
          sponsoredRank: 9,
          keywordSales: 16
        },
        {
          date: '2024-05-30',
          searchVolume: 2507,
          organicRank: 60,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-05-29',
          searchVolume: 2507,
          organicRank: 52,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-05-28',
          searchVolume: 2507,
          organicRank: 51,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-05-27',
          searchVolume: 2507,
          organicRank: 25,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-05-26',
          searchVolume: 2507,
          organicRank: 60,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-05-25',
          searchVolume: 2507,
          organicRank: 52,
          sponsoredRank: 1,
          keywordSales: 16
        },
        {
          date: '2024-05-24',
          searchVolume: 2390,
          organicRank: 67,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-05-23',
          searchVolume: 2390,
          organicRank: 69,

          keywordSales: 16
        },
        {
          date: '2024-05-22',
          searchVolume: 2390,
          organicRank: 61,
          sponsoredRank: 2,
          keywordSales: 16
        },
        {
          date: '2024-05-21',
          searchVolume: 2390,
          organicRank: 77,

          keywordSales: 16
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wifi security camera`,
      keyword: 'wifi security camera',
      searchVolume: 2217,
      organicStartRank: 306,
      organicHighestRank: 248,
      sponsoredStartRank: 96,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-09',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2353,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-07-02',
          searchVolume: 2353,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-07-01',
          searchVolume: 2353,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-30',
          searchVolume: 2353,
          organicRank: 263,

          keywordSales: 10
        },
        {
          date: '2024-06-29',
          searchVolume: 2353,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-28',
          searchVolume: 1922,
          organicRank: 306,
          sponsoredRank: 10,
          keywordSales: 10
        },
        {
          date: '2024-06-27',
          searchVolume: 1922,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 10
        },
        {
          date: '2024-06-26',
          searchVolume: 1922,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-25',
          searchVolume: 1922,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-24',
          searchVolume: 1922,
          organicRank: 264,

          keywordSales: 10
        },
        {
          date: '2024-06-21',
          searchVolume: 2173,
          organicRank: 267,

          keywordSales: 10
        },
        {
          date: '2024-06-20',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-19',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-18',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-17',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-16',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-15',
          searchVolume: 2173,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-14',
          searchVolume: 2186,
          organicRank: 306,
          sponsoredRank: 53,
          keywordSales: 10
        },
        {
          date: '2024-06-13',
          searchVolume: 2186,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 10
        },
        {
          date: '2024-06-12',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-11',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-10',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-09',
          searchVolume: 2186,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 10
        },
        {
          date: '2024-06-08',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-07',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-06',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-05',
          searchVolume: 1846,
          organicRank: 306,
          sponsoredRank: 60,
          keywordSales: 10
        },
        {
          date: '2024-06-04',
          searchVolume: 1846,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 10
        },
        {
          date: '2024-06-03',
          searchVolume: 1846,
          organicRank: 248,

          keywordSales: 10
        },
        {
          date: '2024-06-02',
          searchVolume: 1846,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-06-01',
          searchVolume: 1846,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 10
        },
        {
          date: '2024-05-31',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-05-30',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-05-29',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-05-28',
          searchVolume: 1899,
          organicRank: 269,

          keywordSales: 10
        },
        {
          date: '2024-05-27',
          searchVolume: 1899,
          organicRank: 306,

          keywordSales: 10
        },
        {
          date: '2024-05-26',
          searchVolume: 1899,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 10
        },
        {
          date: '2024-05-25',
          searchVolume: 1899,
          organicRank: 306,
          sponsoredRank: 72,
          keywordSales: 10
        },
        {
          date: '2024-05-24',
          searchVolume: 2217,
          organicRank: 306,
          sponsoredRank: 96,
          keywordSales: 10
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wifi security camera outdoor wireless`,
      keyword: 'wifi security camera outdoor wireless',
      searchVolume: 4272,
      organicStartRank: 131,
      organicHighestRank: 26,
      sponsoredStartRank: 53,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 4374,
          organicRank: 81,
          sponsoredRank: 48,
          keywordSales: 23
        },
        {
          date: '2024-07-03',
          searchVolume: 4374,
          organicRank: 76,
          sponsoredRank: 76,
          keywordSales: 23
        },
        {
          date: '2024-07-02',
          searchVolume: 4374,
          organicRank: 81,
          sponsoredRank: 46,
          keywordSales: 23
        },
        {
          date: '2024-07-01',
          searchVolume: 4374,
          organicRank: 51,

          keywordSales: 23
        },
        {
          date: '2024-06-30',
          searchVolume: 4374,
          organicRank: 52,

          keywordSales: 23
        },
        {
          date: '2024-06-29',
          searchVolume: 4374,
          organicRank: 47,
          sponsoredRank: 9,
          keywordSales: 23
        },
        {
          date: '2024-06-28',
          searchVolume: 4311,
          organicRank: 55,
          sponsoredRank: 108,
          keywordSales: 23
        },
        {
          date: '2024-06-27',
          searchVolume: 4311,
          organicRank: 39,
          sponsoredRank: 7,
          keywordSales: 23
        },
        {
          date: '2024-06-26',
          searchVolume: 4311,
          organicRank: 43,
          sponsoredRank: 13,
          keywordSales: 23
        },
        {
          date: '2024-06-25',
          searchVolume: 4311,
          organicRank: 31,
          sponsoredRank: 23,
          keywordSales: 23
        },
        {
          date: '2024-06-24',
          searchVolume: 4311,
          organicRank: 26,
          sponsoredRank: 11,
          keywordSales: 23
        },
        {
          date: '2024-06-23',
          searchVolume: 4311,
          organicRank: 32,
          sponsoredRank: 18,
          keywordSales: 23
        },
        {
          date: '2024-06-22',
          searchVolume: 4311,
          organicRank: 34,
          sponsoredRank: 6,
          keywordSales: 23
        },
        {
          date: '2024-06-21',
          searchVolume: 4324,
          organicRank: 34,
          sponsoredRank: 59,
          keywordSales: 23
        },
        {
          date: '2024-06-20',
          searchVolume: 4324,
          organicRank: 37,
          sponsoredRank: 54,
          keywordSales: 23
        },
        {
          date: '2024-06-19',
          searchVolume: 4324,
          organicRank: 38,
          sponsoredRank: 20,
          keywordSales: 23
        },
        {
          date: '2024-06-18',
          searchVolume: 4324,
          organicRank: 37,
          sponsoredRank: 51,
          keywordSales: 23
        },
        {
          date: '2024-06-17',
          searchVolume: 4324,
          organicRank: 52,

          keywordSales: 23
        },
        {
          date: '2024-06-16',
          searchVolume: 4324,
          organicRank: 46,

          keywordSales: 23
        },
        {
          date: '2024-06-15',
          searchVolume: 4324,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 23
        },
        {
          date: '2024-06-14',
          searchVolume: 4905,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 23
        },
        {
          date: '2024-06-13',
          searchVolume: 4905,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 23
        },
        {
          date: '2024-06-12',
          searchVolume: 4905,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-11',
          searchVolume: 4905,
          organicRank: 306,
          sponsoredRank: 101,
          keywordSales: 23
        },
        {
          date: '2024-06-10',
          searchVolume: 4905,
          organicRank: 52,
          sponsoredRank: 50,
          keywordSales: 23
        },
        {
          date: '2024-06-09',
          searchVolume: 4905,
          organicRank: 306,
          sponsoredRank: 97,
          keywordSales: 23
        },
        {
          date: '2024-06-08',
          searchVolume: 4905,
          organicRank: 306,

          keywordSales: 23
        },
        {
          date: '2024-06-07',
          searchVolume: 4337,
          organicRank: 50,

          keywordSales: 23
        },
        {
          date: '2024-06-06',
          searchVolume: 4337,
          organicRank: 53,
          sponsoredRank: 61,
          keywordSales: 23
        },
        {
          date: '2024-06-05',
          searchVolume: 4337,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 23
        },
        {
          date: '2024-06-04',
          searchVolume: 4337,
          organicRank: 306,
          sponsoredRank: 55,
          keywordSales: 23
        },
        {
          date: '2024-06-03',
          searchVolume: 4337,
          organicRank: 66,
          sponsoredRank: 71,
          keywordSales: 23
        },
        {
          date: '2024-06-02',
          searchVolume: 4337,
          organicRank: 77,
          sponsoredRank: 88,
          keywordSales: 23
        },
        {
          date: '2024-06-01',
          searchVolume: 4337,
          organicRank: 88,
          sponsoredRank: 65,
          keywordSales: 23
        },
        {
          date: '2024-05-31',
          searchVolume: 4256,
          organicRank: 74,
          sponsoredRank: 37,
          keywordSales: 23
        },
        {
          date: '2024-05-30',
          searchVolume: 4256,
          organicRank: 81,
          sponsoredRank: 84,
          keywordSales: 23
        },
        {
          date: '2024-05-29',
          searchVolume: 4256,
          organicRank: 71,

          keywordSales: 23
        },
        {
          date: '2024-05-28',
          searchVolume: 4256,
          organicRank: 70,

          keywordSales: 23
        },
        {
          date: '2024-05-27',
          searchVolume: 4256,
          organicRank: 68,
          sponsoredRank: 60,
          keywordSales: 23
        },
        {
          date: '2024-05-26',
          searchVolume: 4256,
          organicRank: 59,
          sponsoredRank: 8,
          keywordSales: 23
        },
        {
          date: '2024-05-25',
          searchVolume: 4256,
          organicRank: 77,
          sponsoredRank: 84,
          keywordSales: 23
        },
        {
          date: '2024-05-24',
          searchVolume: 4272,
          organicRank: 94,

          keywordSales: 23
        },
        {
          date: '2024-05-23',
          searchVolume: 4272,
          organicRank: 137,
          sponsoredRank: 41,
          keywordSales: 23
        },
        {
          date: '2024-05-22',
          searchVolume: 4272,
          organicRank: 131,
          sponsoredRank: 53,
          keywordSales: 23
        },
        {
          date: '2024-05-21',
          searchVolume: 4272,
          organicRank: 122,
          sponsoredRank: 36,
          keywordSales: 23
        },
        {
          date: '2024-05-20',
          searchVolume: 4272,
          organicRank: 108,
          sponsoredRank: 64,
          keywordSales: 23
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wired security camera system`,
      keyword: 'wired security camera system',
      searchVolume: 3078,
      organicStartRank: 148,
      organicHighestRank: 62,
      sponsoredStartRank: 70,
      sponsoredHighestRank: 28,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2554,
          organicRank: 117,

          keywordSales: 48
        },
        {
          date: '2024-07-03',
          searchVolume: 2554,
          organicRank: 107,

          keywordSales: 48
        },
        {
          date: '2024-07-02',
          searchVolume: 2554,
          organicRank: 120,

          keywordSales: 48
        },
        {
          date: '2024-07-01',
          searchVolume: 2554,
          organicRank: 95,

          keywordSales: 48
        },
        {
          date: '2024-06-30',
          searchVolume: 2554,
          organicRank: 70,

          keywordSales: 48
        },
        {
          date: '2024-06-29',
          searchVolume: 2554,
          organicRank: 86,

          keywordSales: 48
        },
        {
          date: '2024-06-28',
          searchVolume: 3116,
          organicRank: 78,

          keywordSales: 48
        },
        {
          date: '2024-06-27',
          searchVolume: 3116,
          organicRank: 73,

          keywordSales: 48
        },
        {
          date: '2024-06-26',
          searchVolume: 3116,
          organicRank: 78,

          keywordSales: 48
        },
        {
          date: '2024-06-25',
          searchVolume: 3116,
          organicRank: 69,

          keywordSales: 48
        },
        {
          date: '2024-06-24',
          searchVolume: 3116,
          organicRank: 62,

          keywordSales: 48
        },
        {
          date: '2024-06-21',
          searchVolume: 2552,
          organicRank: 81,

          keywordSales: 48
        },
        {
          date: '2024-06-20',
          searchVolume: 2552,
          organicRank: 88,

          keywordSales: 48
        },
        {
          date: '2024-06-19',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-18',
          searchVolume: 2552,
          organicRank: 81,

          keywordSales: 48
        },
        {
          date: '2024-06-17',
          searchVolume: 2552,
          organicRank: 95,

          keywordSales: 48
        },
        {
          date: '2024-06-16',
          searchVolume: 2552,
          organicRank: 90,

          keywordSales: 48
        },
        {
          date: '2024-06-15',
          searchVolume: 2552,
          organicRank: 72,

          keywordSales: 48
        },
        {
          date: '2024-06-14',
          searchVolume: 3145,
          organicRank: 88,

          keywordSales: 48
        },
        {
          date: '2024-06-13',
          searchVolume: 3145,
          organicRank: 114,

          keywordSales: 48
        },
        {
          date: '2024-06-12',
          searchVolume: 3145,
          organicRank: 142,

          keywordSales: 48
        },
        {
          date: '2024-06-11',
          searchVolume: 3145,
          organicRank: 146,

          keywordSales: 48
        },
        {
          date: '2024-06-10',
          searchVolume: 3145,
          organicRank: 121,

          keywordSales: 48
        },
        {
          date: '2024-06-09',
          searchVolume: 3145,
          organicRank: 124,

          keywordSales: 48
        },
        {
          date: '2024-06-08',
          searchVolume: 3145,
          organicRank: 135,

          keywordSales: 48
        },
        {
          date: '2024-06-07',
          searchVolume: 2982,
          organicRank: 134,

          keywordSales: 48
        },
        {
          date: '2024-06-06',
          searchVolume: 2982,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-06-05',
          searchVolume: 2982,
          organicRank: 113,
          sponsoredRank: 80,
          keywordSales: 48
        },
        {
          date: '2024-06-04',
          searchVolume: 2982,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 48
        },
        {
          date: '2024-06-03',
          searchVolume: 2982,
          organicRank: 91,

          keywordSales: 48
        },
        {
          date: '2024-06-02',
          searchVolume: 2982,
          organicRank: 80,

          keywordSales: 48
        },
        {
          date: '2024-06-01',
          searchVolume: 2982,
          organicRank: 82,
          sponsoredRank: 28,
          keywordSales: 48
        },
        {
          date: '2024-05-31',
          searchVolume: 3078,
          organicRank: 85,
          sponsoredRank: 28,
          keywordSales: 48
        },
        {
          date: '2024-05-30',
          searchVolume: 3078,
          organicRank: 82,
          sponsoredRank: 71,
          keywordSales: 48
        },
        {
          date: '2024-05-29',
          searchVolume: 3078,
          organicRank: 72,
          sponsoredRank: 57,
          keywordSales: 48
        },
        {
          date: '2024-05-28',
          searchVolume: 3078,
          organicRank: 71,
          sponsoredRank: 91,
          keywordSales: 48
        },
        {
          date: '2024-05-27',
          searchVolume: 3078,
          organicRank: 65,
          sponsoredRank: 36,
          keywordSales: 48
        },
        {
          date: '2024-05-26',
          searchVolume: 3078,
          organicRank: 67,
          sponsoredRank: 74,
          keywordSales: 48
        },
        {
          date: '2024-05-25',
          searchVolume: 3078,
          organicRank: 62,
          sponsoredRank: 35,
          keywordSales: 48
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 62,

          keywordSales: 48
        },
        {
          date: '2024-05-23',
          searchVolume: 3078,
          organicRank: 116,
          sponsoredRank: 70,
          keywordSales: 48
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 148,

          keywordSales: 48
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless camera`,
      keyword: 'wireless camera',
      searchVolume: 16399,
      organicStartRank: 86,
      organicHighestRank: 13,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-01',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 14163,
          organicRank: 27,
          sponsoredRank: 2,
          keywordSales: 207
        },
        {
          date: '2024-07-02',
          searchVolume: 14163,
          organicRank: 16,
          sponsoredRank: 33,
          keywordSales: 207
        },
        {
          date: '2024-07-01',
          searchVolume: 14163,
          organicRank: 13,
          sponsoredRank: 20,
          keywordSales: 207
        },
        {
          date: '2024-06-30',
          searchVolume: 14163,
          organicRank: 25,

          keywordSales: 207
        },
        {
          date: '2024-06-29',
          searchVolume: 14163,
          organicRank: 40,

          keywordSales: 207
        },
        {
          date: '2024-06-28',
          searchVolume: 14095,
          organicRank: 30,
          sponsoredRank: 76,
          keywordSales: 207
        },
        {
          date: '2024-06-27',
          searchVolume: 14095,
          organicRank: 23,
          sponsoredRank: 81,
          keywordSales: 207
        },
        {
          date: '2024-06-26',
          searchVolume: 14095,
          organicRank: 47,

          keywordSales: 207
        },
        {
          date: '2024-06-25',
          searchVolume: 14095,
          organicRank: 23,
          sponsoredRank: 54,
          keywordSales: 207
        },
        {
          date: '2024-06-24',
          searchVolume: 14095,
          organicRank: 24,
          sponsoredRank: 15,
          keywordSales: 207
        },
        {
          date: '2024-06-23',
          searchVolume: 14095,
          organicRank: 32,
          sponsoredRank: 20,
          keywordSales: 207
        },
        {
          date: '2024-06-22',
          searchVolume: 14095,
          organicRank: 35,
          sponsoredRank: 17,
          keywordSales: 207
        },
        {
          date: '2024-06-21',
          searchVolume: 14165,
          organicRank: 32,
          sponsoredRank: 69,
          keywordSales: 207
        },
        {
          date: '2024-06-20',
          searchVolume: 14165,
          organicRank: 46,
          sponsoredRank: 48,
          keywordSales: 207
        },
        {
          date: '2024-06-19',
          searchVolume: 14165,
          organicRank: 67,
          sponsoredRank: 16,
          keywordSales: 207
        },
        {
          date: '2024-06-18',
          searchVolume: 14165,
          organicRank: 52,
          sponsoredRank: 13,
          keywordSales: 207
        },
        {
          date: '2024-06-17',
          searchVolume: 14165,
          organicRank: 57,

          keywordSales: 207
        },
        {
          date: '2024-06-16',
          searchVolume: 14165,
          organicRank: 47,

          keywordSales: 207
        },
        {
          date: '2024-06-15',
          searchVolume: 14165,
          organicRank: 45,

          keywordSales: 207
        },
        {
          date: '2024-06-14',
          searchVolume: 16707,
          organicRank: 268,
          sponsoredRank: 29,
          keywordSales: 207
        },
        {
          date: '2024-06-13',
          searchVolume: 16707,
          organicRank: 125,
          sponsoredRank: 28,
          keywordSales: 207
        },
        {
          date: '2024-06-12',
          searchVolume: 16707,
          organicRank: 306,

          keywordSales: 207
        },
        {
          date: '2024-06-11',
          searchVolume: 16707,
          organicRank: 306,
          sponsoredRank: 28,
          keywordSales: 207
        },
        {
          date: '2024-06-10',
          searchVolume: 16707,
          organicRank: 86,

          keywordSales: 207
        },
        {
          date: '2024-06-09',
          searchVolume: 16707,
          organicRank: 106,
          sponsoredRank: 61,
          keywordSales: 207
        },
        {
          date: '2024-06-08',
          searchVolume: 16707,
          organicRank: 108,
          sponsoredRank: 18,
          keywordSales: 207
        },
        {
          date: '2024-06-07',
          searchVolume: 13495,
          organicRank: 306,

          keywordSales: 207
        },
        {
          date: '2024-06-06',
          searchVolume: 13495,
          organicRank: 100,
          sponsoredRank: 11,
          keywordSales: 207
        },
        {
          date: '2024-06-05',
          searchVolume: 13495,
          organicRank: 306,
          sponsoredRank: 18,
          keywordSales: 207
        },
        {
          date: '2024-06-04',
          searchVolume: 13495,
          organicRank: 81,
          sponsoredRank: 25,
          keywordSales: 207
        },
        {
          date: '2024-06-03',
          searchVolume: 13495,
          organicRank: 59,
          sponsoredRank: 1,
          keywordSales: 207
        },
        {
          date: '2024-06-02',
          searchVolume: 13495,
          organicRank: 58,

          keywordSales: 207
        },
        {
          date: '2024-06-01',
          searchVolume: 13495,
          organicRank: 57,

          keywordSales: 207
        },
        {
          date: '2024-05-31',
          searchVolume: 16342,
          organicRank: 63,
          sponsoredRank: 70,
          keywordSales: 207
        },
        {
          date: '2024-05-30',
          searchVolume: 16342,
          organicRank: 59,

          keywordSales: 207
        },
        {
          date: '2024-05-29',
          searchVolume: 16342,
          organicRank: 306,

          keywordSales: 207
        },
        {
          date: '2024-05-28',
          searchVolume: 16342,
          organicRank: 103,
          sponsoredRank: 87,
          keywordSales: 207
        },
        {
          date: '2024-05-27',
          searchVolume: 16342,
          organicRank: 90,
          sponsoredRank: 31,
          keywordSales: 207
        },
        {
          date: '2024-05-26',
          searchVolume: 16342,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 207
        },
        {
          date: '2024-05-25',
          searchVolume: 16342,
          organicRank: 255,
          sponsoredRank: 63,
          keywordSales: 207
        },
        {
          date: '2024-05-24',
          searchVolume: 16399,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 207
        },
        {
          date: '2024-05-23',
          searchVolume: 16399,
          organicRank: 90,
          sponsoredRank: 1,
          keywordSales: 207
        },
        {
          date: '2024-05-22',
          searchVolume: 16399,
          organicRank: 86,
          sponsoredRank: 2,
          keywordSales: 207
        },
        {
          date: '2024-05-21',
          searchVolume: 16399,
          organicRank: 97,
          sponsoredRank: 34,
          keywordSales: 207
        },
        {
          date: '2024-05-20',
          searchVolume: 16399,
          organicRank: 92,

          keywordSales: 207
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless camera indoor`,
      keyword: 'wireless camera indoor',
      searchVolume: 4477,
      organicStartRank: 140,
      organicHighestRank: 72,
      sponsoredStartRank: 51,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 4325,
          organicRank: 118,

          keywordSales: 173
        },
        {
          date: '2024-07-03',
          searchVolume: 4325,
          organicRank: 114,

          keywordSales: 173
        },
        {
          date: '2024-07-02',
          searchVolume: 4325,
          organicRank: 208,

          keywordSales: 173
        },
        {
          date: '2024-07-01',
          searchVolume: 4325,
          organicRank: 94,

          keywordSales: 173
        },
        {
          date: '2024-06-30',
          searchVolume: 4325,
          organicRank: 95,

          keywordSales: 173
        },
        {
          date: '2024-06-29',
          searchVolume: 4325,
          organicRank: 75,

          keywordSales: 173
        },
        {
          date: '2024-06-28',
          searchVolume: 3807,
          organicRank: 81,

          keywordSales: 173
        },
        {
          date: '2024-06-27',
          searchVolume: 3807,
          organicRank: 93,

          keywordSales: 173
        },
        {
          date: '2024-06-26',
          searchVolume: 3807,
          organicRank: 93,

          keywordSales: 173
        },
        {
          date: '2024-06-25',
          searchVolume: 3807,
          organicRank: 108,

          keywordSales: 173
        },
        {
          date: '2024-06-23',
          searchVolume: 3807,
          organicRank: 109,

          keywordSales: 173
        },
        {
          date: '2024-06-21',
          searchVolume: 4322,
          organicRank: 94,
          sponsoredRank: 66,
          keywordSales: 173
        },
        {
          date: '2024-06-20',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 173
        },
        {
          date: '2024-06-19',
          searchVolume: 4322,
          organicRank: 117,

          keywordSales: 173
        },
        {
          date: '2024-06-18',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 173
        },
        {
          date: '2024-06-17',
          searchVolume: 4322,
          organicRank: 103,

          keywordSales: 173
        },
        {
          date: '2024-06-16',
          searchVolume: 4322,
          organicRank: 109,

          keywordSales: 173
        },
        {
          date: '2024-06-15',
          searchVolume: 4322,
          organicRank: 125,

          keywordSales: 173
        },
        {
          date: '2024-06-14',
          searchVolume: 4322,
          organicRank: 106,

          keywordSales: 173
        },
        {
          date: '2024-06-13',
          searchVolume: 4322,
          organicRank: 166,

          keywordSales: 173
        },
        {
          date: '2024-06-12',
          searchVolume: 4322,
          organicRank: 196,

          keywordSales: 173
        },
        {
          date: '2024-06-11',
          searchVolume: 4322,
          organicRank: 306,

          keywordSales: 173
        },
        {
          date: '2024-06-10',
          searchVolume: 4322,
          organicRank: 117,

          keywordSales: 173
        },
        {
          date: '2024-06-09',
          searchVolume: 4322,
          organicRank: 117,

          keywordSales: 173
        },
        {
          date: '2024-06-08',
          searchVolume: 4322,
          organicRank: 127,

          keywordSales: 173
        },
        {
          date: '2024-06-07',
          searchVolume: 4124,
          organicRank: 117,

          keywordSales: 173
        },
        {
          date: '2024-06-06',
          searchVolume: 4124,
          organicRank: 97,
          sponsoredRank: 63,
          keywordSales: 173
        },
        {
          date: '2024-06-05',
          searchVolume: 4124,
          organicRank: 306,
          sponsoredRank: 1,
          keywordSales: 173
        },
        {
          date: '2024-06-04',
          searchVolume: 4124,
          organicRank: 306,
          sponsoredRank: 13,
          keywordSales: 173
        },
        {
          date: '2024-06-03',
          searchVolume: 4124,
          organicRank: 75,

          keywordSales: 173
        },
        {
          date: '2024-06-02',
          searchVolume: 4124,
          organicRank: 84,

          keywordSales: 173
        },
        {
          date: '2024-06-01',
          searchVolume: 4124,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 173
        },
        {
          date: '2024-05-31',
          searchVolume: 4270,
          organicRank: 74,
          sponsoredRank: 68,
          keywordSales: 173
        },
        {
          date: '2024-05-30',
          searchVolume: 4270,
          organicRank: 83,

          keywordSales: 173
        },
        {
          date: '2024-05-29',
          searchVolume: 4270,
          organicRank: 78,
          sponsoredRank: 70,
          keywordSales: 173
        },
        {
          date: '2024-05-28',
          searchVolume: 4270,
          organicRank: 76,
          sponsoredRank: 69,
          keywordSales: 173
        },
        {
          date: '2024-05-27',
          searchVolume: 4270,
          organicRank: 72,
          sponsoredRank: 58,
          keywordSales: 173
        },
        {
          date: '2024-05-26',
          searchVolume: 4270,
          organicRank: 72,

          keywordSales: 173
        },
        {
          date: '2024-05-25',
          searchVolume: 4270,
          organicRank: 77,
          sponsoredRank: 51,
          keywordSales: 173
        },
        {
          date: '2024-05-24',
          searchVolume: 4477,
          organicRank: 91,

          keywordSales: 173
        },
        {
          date: '2024-05-23',
          searchVolume: 4477,
          organicRank: 139,

          keywordSales: 173
        },
        {
          date: '2024-05-21',
          searchVolume: 4477,
          organicRank: 140,

          keywordSales: 173
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless camera outdoor`,
      keyword: 'wireless camera outdoor',
      searchVolume: 8353,
      organicStartRank: 51,
      organicHighestRank: 16,
      sponsoredStartRank: 60,
      sponsoredHighestRank: 5,
      organicPageOneWinDate: '2024-06-05',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 8490,
          organicRank: 69,
          sponsoredRank: 11,
          keywordSales: 296
        },
        {
          date: '2024-07-03',
          searchVolume: 8490,
          organicRank: 82,
          sponsoredRank: 69,
          keywordSales: 296
        },
        {
          date: '2024-07-02',
          searchVolume: 8490,
          organicRank: 81,
          sponsoredRank: 52,
          keywordSales: 296
        },
        {
          date: '2024-07-01',
          searchVolume: 8490,
          organicRank: 67,

          keywordSales: 296
        },
        {
          date: '2024-06-30',
          searchVolume: 8490,
          organicRank: 73,

          keywordSales: 296
        },
        {
          date: '2024-06-29',
          searchVolume: 8490,
          organicRank: 69,
          sponsoredRank: 16,
          keywordSales: 296
        },
        {
          date: '2024-06-28',
          searchVolume: 8458,
          organicRank: 70,
          sponsoredRank: 8,
          keywordSales: 296
        },
        {
          date: '2024-06-27',
          searchVolume: 8458,
          organicRank: 79,
          sponsoredRank: 15,
          keywordSales: 296
        },
        {
          date: '2024-06-26',
          searchVolume: 8458,
          organicRank: 85,
          sponsoredRank: 47,
          keywordSales: 296
        },
        {
          date: '2024-06-25',
          searchVolume: 8458,
          organicRank: 84,

          keywordSales: 296
        },
        {
          date: '2024-06-24',
          searchVolume: 8458,
          organicRank: 85,
          sponsoredRank: 28,
          keywordSales: 296
        },
        {
          date: '2024-06-23',
          searchVolume: 8458,
          organicRank: 94,
          sponsoredRank: 20,
          keywordSales: 296
        },
        {
          date: '2024-06-22',
          searchVolume: 8458,
          organicRank: 103,
          sponsoredRank: 11,
          keywordSales: 296
        },
        {
          date: '2024-06-21',
          searchVolume: 8482,
          organicRank: 60,
          sponsoredRank: 78,
          keywordSales: 296
        },
        {
          date: '2024-06-20',
          searchVolume: 8482,
          organicRank: 36,
          sponsoredRank: 30,
          keywordSales: 296
        },
        {
          date: '2024-06-19',
          searchVolume: 8482,
          organicRank: 37,
          sponsoredRank: 35,
          keywordSales: 296
        },
        {
          date: '2024-06-18',
          searchVolume: 8482,
          organicRank: 51,
          sponsoredRank: 58,
          keywordSales: 296
        },
        {
          date: '2024-06-17',
          searchVolume: 8482,
          organicRank: 36,
          sponsoredRank: 24,
          keywordSales: 296
        },
        {
          date: '2024-06-16',
          searchVolume: 8482,
          organicRank: 49,

          keywordSales: 296
        },
        {
          date: '2024-06-15',
          searchVolume: 8482,
          organicRank: 35,
          sponsoredRank: 14,
          keywordSales: 296
        },
        {
          date: '2024-06-14',
          searchVolume: 7713,
          organicRank: 306,
          sponsoredRank: 58,
          keywordSales: 296
        },
        {
          date: '2024-06-13',
          searchVolume: 7713,
          organicRank: 306,
          sponsoredRank: 59,
          keywordSales: 296
        },
        {
          date: '2024-06-12',
          searchVolume: 7713,
          organicRank: 306,

          keywordSales: 296
        },
        {
          date: '2024-06-11',
          searchVolume: 7713,
          organicRank: 100,

          keywordSales: 296
        },
        {
          date: '2024-06-10',
          searchVolume: 7713,
          organicRank: 26,
          sponsoredRank: 26,
          keywordSales: 296
        },
        {
          date: '2024-06-09',
          searchVolume: 7713,
          organicRank: 21,
          sponsoredRank: 70,
          keywordSales: 296
        },
        {
          date: '2024-06-08',
          searchVolume: 7713,
          organicRank: 21,
          sponsoredRank: 66,
          keywordSales: 296
        },
        {
          date: '2024-06-07',
          searchVolume: 7111,
          organicRank: 18,
          sponsoredRank: 35,
          keywordSales: 296
        },
        {
          date: '2024-06-06',
          searchVolume: 7111,
          organicRank: 16,
          sponsoredRank: 75,
          keywordSales: 296
        },
        {
          date: '2024-06-05',
          searchVolume: 7111,
          organicRank: 16,
          sponsoredRank: 5,
          keywordSales: 296
        },
        {
          date: '2024-06-04',
          searchVolume: 7111,
          organicRank: 23,
          sponsoredRank: 51,
          keywordSales: 296
        },
        {
          date: '2024-06-03',
          searchVolume: 7111,
          organicRank: 21,

          keywordSales: 296
        },
        {
          date: '2024-06-02',
          searchVolume: 7111,
          organicRank: 21,
          sponsoredRank: 66,
          keywordSales: 296
        },
        {
          date: '2024-06-01',
          searchVolume: 7111,
          organicRank: 53,
          sponsoredRank: 15,
          keywordSales: 296
        },
        {
          date: '2024-05-31',
          searchVolume: 8352,
          organicRank: 27,
          sponsoredRank: 55,
          keywordSales: 296
        },
        {
          date: '2024-05-30',
          searchVolume: 8352,
          organicRank: 51,

          keywordSales: 296
        },
        {
          date: '2024-05-29',
          searchVolume: 8352,
          organicRank: 53,
          sponsoredRank: 97,
          keywordSales: 296
        },
        {
          date: '2024-05-28',
          searchVolume: 8352,
          organicRank: 49,
          sponsoredRank: 68,
          keywordSales: 296
        },
        {
          date: '2024-05-27',
          searchVolume: 8352,
          organicRank: 51,
          sponsoredRank: 63,
          keywordSales: 296
        },
        {
          date: '2024-05-26',
          searchVolume: 8352,
          organicRank: 41,
          sponsoredRank: 21,
          keywordSales: 296
        },
        {
          date: '2024-05-25',
          searchVolume: 8352,
          organicRank: 42,
          sponsoredRank: 35,
          keywordSales: 296
        },
        {
          date: '2024-05-24',
          searchVolume: 8353,
          organicRank: 36,
          sponsoredRank: 83,
          keywordSales: 296
        },
        {
          date: '2024-05-23',
          searchVolume: 8353,
          organicRank: 54,
          sponsoredRank: 42,
          keywordSales: 296
        },
        {
          date: '2024-05-22',
          searchVolume: 8353,
          organicRank: 51,
          sponsoredRank: 60,
          keywordSales: 296
        },
        {
          date: '2024-05-21',
          searchVolume: 8353,
          organicRank: 46,
          sponsoredRank: 33,
          keywordSales: 296
        },
        {
          date: '2024-05-20',
          searchVolume: 8353,
          organicRank: 49,

          keywordSales: 296
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless cameras`,
      keyword: 'wireless cameras',
      searchVolume: 3078,
      organicStartRank: 95,
      organicHighestRank: 21,
      sponsoredStartRank: 15,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-31',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 3439,
          organicRank: 38,
          sponsoredRank: 40,
          keywordSales: 38
        },
        {
          date: '2024-07-02',
          searchVolume: 3439,
          organicRank: 37,

          keywordSales: 38
        },
        {
          date: '2024-07-01',
          searchVolume: 3439,
          organicRank: 38,

          keywordSales: 38
        },
        {
          date: '2024-06-30',
          searchVolume: 3439,
          organicRank: 54,

          keywordSales: 38
        },
        {
          date: '2024-06-29',
          searchVolume: 3439,
          organicRank: 40,

          keywordSales: 38
        },
        {
          date: '2024-06-28',
          searchVolume: 3126,
          organicRank: 38,
          sponsoredRank: 9,
          keywordSales: 38
        },
        {
          date: '2024-06-27',
          searchVolume: 3126,
          organicRank: 26,
          sponsoredRank: 15,
          keywordSales: 38
        },
        {
          date: '2024-06-26',
          searchVolume: 3126,
          organicRank: 24,
          sponsoredRank: 5,
          keywordSales: 38
        },
        {
          date: '2024-06-25',
          searchVolume: 3126,
          organicRank: 73,

          keywordSales: 38
        },
        {
          date: '2024-06-24',
          searchVolume: 3126,
          organicRank: 79,
          sponsoredRank: 7,
          keywordSales: 38
        },
        {
          date: '2024-06-22',
          searchVolume: 3126,
          organicRank: 96,
          sponsoredRank: 3,
          keywordSales: 38
        },
        {
          date: '2024-06-21',
          searchVolume: 3126,
          organicRank: 74,
          sponsoredRank: 13,
          keywordSales: 38
        },
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 27,
          sponsoredRank: 8,
          keywordSales: 38
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 66,
          sponsoredRank: 4,
          keywordSales: 38
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 57,
          sponsoredRank: 20,
          keywordSales: 38
        },
        {
          date: '2024-06-17',
          searchVolume: 3126,
          organicRank: 47,
          sponsoredRank: 19,
          keywordSales: 38
        },
        {
          date: '2024-06-16',
          searchVolume: 3126,
          organicRank: 40,

          keywordSales: 38
        },
        {
          date: '2024-06-15',
          searchVolume: 3126,
          organicRank: 54,
          sponsoredRank: 64,
          keywordSales: 38
        },
        {
          date: '2024-06-14',
          searchVolume: 2816,
          organicRank: 61,
          sponsoredRank: 37,
          keywordSales: 38
        },
        {
          date: '2024-06-13',
          searchVolume: 2816,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 38
        },
        {
          date: '2024-06-12',
          searchVolume: 2816,
          organicRank: 306,
          sponsoredRank: 45,
          keywordSales: 38
        },
        {
          date: '2024-06-11',
          searchVolume: 2816,
          organicRank: 306,
          sponsoredRank: 5,
          keywordSales: 38
        },
        {
          date: '2024-06-10',
          searchVolume: 2816,
          organicRank: 49,

          keywordSales: 38
        },
        {
          date: '2024-06-09',
          searchVolume: 2816,
          organicRank: 53,
          sponsoredRank: 11,
          keywordSales: 38
        },
        {
          date: '2024-06-08',
          searchVolume: 2816,
          organicRank: 53,

          keywordSales: 38
        },
        {
          date: '2024-06-07',
          searchVolume: 2431,
          organicRank: 49,

          keywordSales: 38
        },
        {
          date: '2024-06-06',
          searchVolume: 2431,
          organicRank: 43,
          sponsoredRank: 3,
          keywordSales: 38
        },
        {
          date: '2024-06-05',
          searchVolume: 2431,
          organicRank: 56,
          sponsoredRank: 25,
          keywordSales: 38
        },
        {
          date: '2024-06-04',
          searchVolume: 2431,
          organicRank: 40,
          sponsoredRank: 15,
          keywordSales: 38
        },
        {
          date: '2024-06-03',
          searchVolume: 2431,
          organicRank: 22,
          sponsoredRank: 2,
          keywordSales: 38
        },
        {
          date: '2024-06-02',
          searchVolume: 2431,
          organicRank: 21,
          sponsoredRank: 53,
          keywordSales: 38
        },
        {
          date: '2024-06-01',
          searchVolume: 2431,
          organicRank: 47,
          sponsoredRank: 53,
          keywordSales: 38
        },
        {
          date: '2024-05-31',
          searchVolume: 2513,
          organicRank: 40,
          sponsoredRank: 2,
          keywordSales: 38
        },
        {
          date: '2024-05-30',
          searchVolume: 2513,
          organicRank: 46,
          sponsoredRank: 29,
          keywordSales: 38
        },
        {
          date: '2024-05-29',
          searchVolume: 2513,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 38
        },
        {
          date: '2024-05-28',
          searchVolume: 2513,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 38
        },
        {
          date: '2024-05-27',
          searchVolume: 2513,
          organicRank: 306,
          sponsoredRank: 30,
          keywordSales: 38
        },
        {
          date: '2024-05-26',
          searchVolume: 2513,
          organicRank: 82,

          keywordSales: 38
        },
        {
          date: '2024-05-25',
          searchVolume: 2513,
          organicRank: 37,

          keywordSales: 38
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 47,

          keywordSales: 38
        },
        {
          date: '2024-05-22',
          searchVolume: 3078,
          organicRank: 95,
          sponsoredRank: 15,
          keywordSales: 38
        },
        {
          date: '2024-05-21',
          searchVolume: 3078,
          organicRank: 84,
          sponsoredRank: 7,
          keywordSales: 38
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless cameras for home security`,
      keyword: 'wireless cameras for home security',
      searchVolume: 28539,
      organicStartRank: 93,
      organicHighestRank: 42,
      sponsoredStartRank: 15,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 29024,
          organicRank: 45,
          sponsoredRank: 14,
          keywordSales: 173
        },
        {
          date: '2024-07-02',
          searchVolume: 29024,
          organicRank: 43,
          sponsoredRank: 33,
          keywordSales: 173
        },
        {
          date: '2024-07-01',
          searchVolume: 29024,
          organicRank: 44,

          keywordSales: 173
        },
        {
          date: '2024-06-30',
          searchVolume: 29024,
          organicRank: 51,

          keywordSales: 173
        },
        {
          date: '2024-06-29',
          searchVolume: 29024,
          organicRank: 42,
          sponsoredRank: 59,
          keywordSales: 173
        },
        {
          date: '2024-06-28',
          searchVolume: 25946,
          organicRank: 90,
          sponsoredRank: 37,
          keywordSales: 173
        },
        {
          date: '2024-06-27',
          searchVolume: 25946,
          organicRank: 94,
          sponsoredRank: 7,
          keywordSales: 173
        },
        {
          date: '2024-06-26',
          searchVolume: 25946,
          organicRank: 93,
          sponsoredRank: 6,
          keywordSales: 173
        },
        {
          date: '2024-06-25',
          searchVolume: 25946,
          organicRank: 96,

          keywordSales: 173
        },
        {
          date: '2024-06-24',
          searchVolume: 25946,
          organicRank: 98,
          sponsoredRank: 25,
          keywordSales: 173
        },
        {
          date: '2024-06-23',
          searchVolume: 25946,
          organicRank: 106,
          sponsoredRank: 11,
          keywordSales: 173
        },
        {
          date: '2024-06-22',
          searchVolume: 25946,
          organicRank: 103,
          sponsoredRank: 4,
          keywordSales: 173
        },
        {
          date: '2024-06-21',
          searchVolume: 26019,
          organicRank: 103,
          sponsoredRank: 35,
          keywordSales: 173
        },
        {
          date: '2024-06-20',
          searchVolume: 26019,
          organicRank: 68,
          sponsoredRank: 54,
          keywordSales: 173
        },
        {
          date: '2024-06-19',
          searchVolume: 26019,
          organicRank: 83,
          sponsoredRank: 25,
          keywordSales: 173
        },
        {
          date: '2024-06-18',
          searchVolume: 26019,
          organicRank: 105,
          sponsoredRank: 25,
          keywordSales: 173
        },
        {
          date: '2024-06-17',
          searchVolume: 26019,
          organicRank: 97,
          sponsoredRank: 70,
          keywordSales: 173
        },
        {
          date: '2024-06-16',
          searchVolume: 26019,
          organicRank: 101,
          sponsoredRank: 22,
          keywordSales: 173
        },
        {
          date: '2024-06-15',
          searchVolume: 26019,
          organicRank: 99,
          sponsoredRank: 25,
          keywordSales: 173
        },
        {
          date: '2024-06-14',
          searchVolume: 28990,
          organicRank: 100,
          sponsoredRank: 58,
          keywordSales: 173
        },
        {
          date: '2024-06-13',
          searchVolume: 28990,
          organicRank: 306,
          sponsoredRank: 22,
          keywordSales: 173
        },
        {
          date: '2024-06-12',
          searchVolume: 28990,
          organicRank: 107,

          keywordSales: 173
        },
        {
          date: '2024-06-11',
          searchVolume: 28990,
          organicRank: 306,

          keywordSales: 173
        },
        {
          date: '2024-06-10',
          searchVolume: 28990,
          organicRank: 102,
          sponsoredRank: 95,
          keywordSales: 173
        },
        {
          date: '2024-06-09',
          searchVolume: 28990,
          organicRank: 103,
          sponsoredRank: 86,
          keywordSales: 173
        },
        {
          date: '2024-06-08',
          searchVolume: 28990,
          organicRank: 110,
          sponsoredRank: 99,
          keywordSales: 173
        },
        {
          date: '2024-06-07',
          searchVolume: 26129,
          organicRank: 122,

          keywordSales: 173
        },
        {
          date: '2024-06-06',
          searchVolume: 26129,
          organicRank: 116,
          sponsoredRank: 1,
          keywordSales: 173
        },
        {
          date: '2024-06-05',
          searchVolume: 26129,
          organicRank: 112,
          sponsoredRank: 7,
          keywordSales: 173
        },
        {
          date: '2024-06-04',
          searchVolume: 26129,
          organicRank: 106,
          sponsoredRank: 19,
          keywordSales: 173
        },
        {
          date: '2024-06-03',
          searchVolume: 26129,
          organicRank: 84,

          keywordSales: 173
        },
        {
          date: '2024-06-02',
          searchVolume: 26129,
          organicRank: 107,
          sponsoredRank: 48,
          keywordSales: 173
        },
        {
          date: '2024-06-01',
          searchVolume: 26129,
          organicRank: 102,
          sponsoredRank: 27,
          keywordSales: 173
        },
        {
          date: '2024-05-31',
          searchVolume: 25004,
          organicRank: 92,
          sponsoredRank: 44,
          keywordSales: 173
        },
        {
          date: '2024-05-30',
          searchVolume: 25004,
          organicRank: 306,
          sponsoredRank: 67,
          keywordSales: 173
        },
        {
          date: '2024-05-29',
          searchVolume: 25004,
          organicRank: 89,
          sponsoredRank: 88,
          keywordSales: 173
        },
        {
          date: '2024-05-28',
          searchVolume: 25004,
          organicRank: 104,
          sponsoredRank: 82,
          keywordSales: 173
        },
        {
          date: '2024-05-27',
          searchVolume: 25004,
          organicRank: 81,
          sponsoredRank: 100,
          keywordSales: 173
        },
        {
          date: '2024-05-26',
          searchVolume: 25004,
          organicRank: 102,
          sponsoredRank: 28,
          keywordSales: 173
        },
        {
          date: '2024-05-25',
          searchVolume: 25004,
          organicRank: 102,
          sponsoredRank: 67,
          keywordSales: 173
        },
        {
          date: '2024-05-24',
          searchVolume: 28539,
          organicRank: 85,
          sponsoredRank: 54,
          keywordSales: 173
        },
        {
          date: '2024-05-23',
          searchVolume: 28539,
          organicRank: 65,
          sponsoredRank: 9,
          keywordSales: 173
        },
        {
          date: '2024-05-22',
          searchVolume: 28539,
          organicRank: 93,
          sponsoredRank: 15,
          keywordSales: 173
        },
        {
          date: '2024-05-21',
          searchVolume: 28539,
          organicRank: 78,
          sponsoredRank: 11,
          keywordSales: 173
        },
        {
          date: '2024-05-20',
          searchVolume: 28539,
          organicRank: 83,

          keywordSales: 173
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless cameras for home security outdoor`,
      keyword: 'wireless cameras for home security outdoor',
      searchVolume: 2392,
      organicStartRank: 53,
      organicHighestRank: 22,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1937,
          organicRank: 76,
          sponsoredRank: 9
        },
        {
          date: '2024-07-02',
          searchVolume: 1937,
          organicRank: 60,
          sponsoredRank: 69
        },
        {
          date: '2024-07-01',
          searchVolume: 1937,
          organicRank: 62
        },
        {
          date: '2024-06-30',
          searchVolume: 1937,
          organicRank: 48
        },
        {
          date: '2024-06-29',
          searchVolume: 1937,
          organicRank: 35,
          sponsoredRank: 7
        },
        {
          date: '2024-06-28',
          searchVolume: 1919,
          organicRank: 32,
          sponsoredRank: 33
        },
        {
          date: '2024-06-27',
          searchVolume: 1919,
          organicRank: 31,
          sponsoredRank: 1
        },
        {
          date: '2024-06-26',
          searchVolume: 1919,
          organicRank: 22,
          sponsoredRank: 8
        },
        {
          date: '2024-06-25',
          searchVolume: 1919,
          organicRank: 306
        },
        {
          date: '2024-06-22',
          searchVolume: 1919,
          organicRank: 79,
          sponsoredRank: 33
        },
        {
          date: '2024-06-21',
          searchVolume: 1924,
          organicRank: 70,
          sponsoredRank: 64
        },
        {
          date: '2024-06-20',
          searchVolume: 1924,
          organicRank: 33,
          sponsoredRank: 82
        },
        {
          date: '2024-06-19',
          searchVolume: 1924,
          organicRank: 30,
          sponsoredRank: 36
        },
        {
          date: '2024-06-18',
          searchVolume: 1924,
          organicRank: 108,
          sponsoredRank: 22
        },
        {
          date: '2024-06-17',
          searchVolume: 1924,
          organicRank: 27
        },
        {
          date: '2024-06-16',
          searchVolume: 1924,
          organicRank: 28,
          sponsoredRank: 60
        },
        {
          date: '2024-06-15',
          searchVolume: 1924,
          organicRank: 65,
          sponsoredRank: 64
        },
        {
          date: '2024-06-14',
          searchVolume: 1924,
          organicRank: 306,
          sponsoredRank: 51
        },
        {
          date: '2024-06-13',
          searchVolume: 1924,
          organicRank: 306,
          sponsoredRank: 60
        },
        {
          date: '2024-06-12',
          searchVolume: 1924,
          organicRank: 48
        },
        {
          date: '2024-06-11',
          searchVolume: 1924,
          organicRank: 306
        },
        {
          date: '2024-06-10',
          searchVolume: 1924,
          organicRank: 71
        },
        {
          date: '2024-06-09',
          searchVolume: 1924,
          organicRank: 56
        },
        {
          date: '2024-06-08',
          searchVolume: 1924,
          organicRank: 48
        },
        {
          date: '2024-06-07',
          searchVolume: 1834,
          organicRank: 59,
          sponsoredRank: 14
        },
        {
          date: '2024-06-06',
          searchVolume: 1834,
          organicRank: 83,
          sponsoredRank: 86
        },
        {
          date: '2024-06-05',
          searchVolume: 1834,
          organicRank: 306,
          sponsoredRank: 3
        },
        {
          date: '2024-06-04',
          searchVolume: 1834,
          organicRank: 306,
          sponsoredRank: 59
        },
        {
          date: '2024-06-03',
          searchVolume: 1834,
          organicRank: 52
        },
        {
          date: '2024-06-02',
          searchVolume: 1834,
          organicRank: 58
        },
        {
          date: '2024-06-01',
          searchVolume: 1834,
          organicRank: 50,
          sponsoredRank: 34
        },
        {
          date: '2024-05-31',
          searchVolume: 1897,
          organicRank: 54,
          sponsoredRank: 75
        },
        {
          date: '2024-05-30',
          searchVolume: 1897,
          organicRank: 59,
          sponsoredRank: 82
        },
        {
          date: '2024-05-29',
          searchVolume: 1897,
          organicRank: 72,
          sponsoredRank: 81
        },
        {
          date: '2024-05-28',
          searchVolume: 1897,
          organicRank: 56
        },
        {
          date: '2024-05-27',
          searchVolume: 1897,
          organicRank: 59,
          sponsoredRank: 66
        },
        {
          date: '2024-05-25',
          searchVolume: 1897,
          organicRank: 71,
          sponsoredRank: 52
        },
        {
          date: '2024-05-24',
          searchVolume: 2392,
          organicRank: 65
        },
        {
          date: '2024-05-23',
          searchVolume: 2392,
          organicRank: 53,
          sponsoredRank: 31
        },
        {
          date: '2024-05-22',
          searchVolume: 2392,
          organicRank: 53,
          sponsoredRank: 4
        },
        {
          date: '2024-05-21',
          searchVolume: 2392,
          organicRank: 64,
          sponsoredRank: 1
        },
        {
          date: '2024-05-20',
          searchVolume: 2392,
          organicRank: 63
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless home security cameras`,
      keyword: 'wireless home security cameras',
      searchVolume: 2513,
      organicStartRank: 76,
      organicHighestRank: 26,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 2550,
          organicRank: 57,
          sponsoredRank: 10
        },
        {
          date: '2024-07-03',
          searchVolume: 2550,
          organicRank: 59,
          sponsoredRank: 6
        },
        {
          date: '2024-07-02',
          searchVolume: 2550,
          organicRank: 44,
          sponsoredRank: 11
        },
        {
          date: '2024-07-01',
          searchVolume: 2550,
          organicRank: 56
        },
        {
          date: '2024-06-30',
          searchVolume: 2550,
          organicRank: 52
        },
        {
          date: '2024-06-29',
          searchVolume: 2550,
          organicRank: 46,
          sponsoredRank: 10
        },
        {
          date: '2024-06-28',
          searchVolume: 2539,
          organicRank: 37,
          sponsoredRank: 19
        },
        {
          date: '2024-06-27',
          searchVolume: 2539,
          organicRank: 31,
          sponsoredRank: 15
        },
        {
          date: '2024-06-26',
          searchVolume: 2539,
          organicRank: 37,
          sponsoredRank: 30
        },
        {
          date: '2024-06-25',
          searchVolume: 2539,
          organicRank: 45
        },
        {
          date: '2024-06-24',
          searchVolume: 2539,
          organicRank: 42,
          sponsoredRank: 5
        },
        {
          date: '2024-06-23',
          searchVolume: 2539,
          organicRank: 39,
          sponsoredRank: 18
        },
        {
          date: '2024-06-22',
          searchVolume: 2539,
          organicRank: 52,
          sponsoredRank: 13
        },
        {
          date: '2024-06-21',
          searchVolume: 2548,
          organicRank: 47,
          sponsoredRank: 34
        },
        {
          date: '2024-06-20',
          searchVolume: 2548,
          organicRank: 44,
          sponsoredRank: 23
        },
        {
          date: '2024-06-19',
          searchVolume: 2548,
          organicRank: 55,
          sponsoredRank: 26
        },
        {
          date: '2024-06-18',
          searchVolume: 2548,
          organicRank: 55,
          sponsoredRank: 54
        },
        {
          date: '2024-06-17',
          searchVolume: 2548,
          organicRank: 68
        },
        {
          date: '2024-06-16',
          searchVolume: 2548,
          organicRank: 73,
          sponsoredRank: 23
        },
        {
          date: '2024-06-15',
          searchVolume: 2548,
          organicRank: 56,
          sponsoredRank: 58
        },
        {
          date: '2024-06-14',
          searchVolume: 3158,
          organicRank: 57,
          sponsoredRank: 9
        },
        {
          date: '2024-06-13',
          searchVolume: 3158,
          organicRank: 50,
          sponsoredRank: 82
        },
        {
          date: '2024-06-12',
          searchVolume: 3158,
          organicRank: 306
        },
        {
          date: '2024-06-11',
          searchVolume: 3158,
          organicRank: 55
        },
        {
          date: '2024-06-10',
          searchVolume: 3158,
          organicRank: 71
        },
        {
          date: '2024-06-09',
          searchVolume: 3158,
          organicRank: 73,
          sponsoredRank: 9
        },
        {
          date: '2024-06-08',
          searchVolume: 3158,
          organicRank: 78,
          sponsoredRank: 65
        },
        {
          date: '2024-06-07',
          searchVolume: 2686,
          organicRank: 48
        },
        {
          date: '2024-06-06',
          searchVolume: 2686,
          organicRank: 59
        },
        {
          date: '2024-06-05',
          searchVolume: 2686,
          organicRank: 306,
          sponsoredRank: 7
        },
        {
          date: '2024-06-04',
          searchVolume: 2686,
          organicRank: 306,
          sponsoredRank: 8
        },
        {
          date: '2024-06-03',
          searchVolume: 2686,
          organicRank: 57
        },
        {
          date: '2024-06-02',
          searchVolume: 2686,
          organicRank: 44
        },
        {
          date: '2024-06-01',
          searchVolume: 2686,
          organicRank: 27,
          sponsoredRank: 36
        },
        {
          date: '2024-05-31',
          searchVolume: 2513,
          organicRank: 42,
          sponsoredRank: 7
        },
        {
          date: '2024-05-30',
          searchVolume: 2513,
          organicRank: 26
        },
        {
          date: '2024-05-29',
          searchVolume: 2513,
          organicRank: 35,
          sponsoredRank: 64
        },
        {
          date: '2024-05-28',
          searchVolume: 2513,
          organicRank: 36,
          sponsoredRank: 65
        },
        {
          date: '2024-05-27',
          searchVolume: 2513,
          organicRank: 64,
          sponsoredRank: 17
        },
        {
          date: '2024-05-26',
          searchVolume: 2513,
          organicRank: 57,
          sponsoredRank: 7
        },
        {
          date: '2024-05-25',
          searchVolume: 2513,
          organicRank: 49,
          sponsoredRank: 48
        },
        {
          date: '2024-05-24',
          searchVolume: 2513,
          organicRank: 65
        },
        {
          date: '2024-05-23',
          searchVolume: 2513,
          organicRank: 62,
          sponsoredRank: 3
        },
        {
          date: '2024-05-22',
          searchVolume: 2513,
          organicRank: 76,
          sponsoredRank: 29
        },
        {
          date: '2024-05-21',
          searchVolume: 2513,
          organicRank: 85,
          sponsoredRank: 6
        },
        {
          date: '2024-05-20',
          searchVolume: 2513,
          organicRank: 92,
          sponsoredRank: 52
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless indoor camera`,
      keyword: 'wireless indoor camera',
      searchVolume: 3078,
      organicStartRank: 91,
      organicHighestRank: 59,
      sponsoredStartRank: 46,
      sponsoredHighestRank: 26,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2552,
          organicRank: 131,

          keywordSales: 97
        },
        {
          date: '2024-07-02',
          searchVolume: 2552,
          organicRank: 136,

          keywordSales: 97
        },
        {
          date: '2024-07-01',
          searchVolume: 2552,
          organicRank: 187,

          keywordSales: 97
        },
        {
          date: '2024-06-30',
          searchVolume: 2552,
          organicRank: 113,

          keywordSales: 97
        },
        {
          date: '2024-06-29',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 97
        },
        {
          date: '2024-06-28',
          searchVolume: 2544,
          organicRank: 92,

          keywordSales: 97
        },
        {
          date: '2024-06-27',
          searchVolume: 2544,
          organicRank: 92,
          sponsoredRank: 72,
          keywordSales: 97
        },
        {
          date: '2024-06-26',
          searchVolume: 2544,
          organicRank: 100,

          keywordSales: 97
        },
        {
          date: '2024-06-25',
          searchVolume: 2544,
          organicRank: 118,

          keywordSales: 97
        },
        {
          date: '2024-06-24',
          searchVolume: 2544,
          organicRank: 114,
          sponsoredRank: 80,
          keywordSales: 97
        },
        {
          date: '2024-06-22',
          searchVolume: 2544,
          organicRank: 135,

          keywordSales: 97
        },
        {
          date: '2024-06-21',
          searchVolume: 3126,
          organicRank: 194,

          keywordSales: 97
        },
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 115,

          keywordSales: 97
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 97
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 97,

          keywordSales: 97
        },
        {
          date: '2024-06-17',
          searchVolume: 3126,
          organicRank: 101,

          keywordSales: 97
        },
        {
          date: '2024-06-16',
          searchVolume: 3126,
          organicRank: 103,

          keywordSales: 97
        },
        {
          date: '2024-06-15',
          searchVolume: 3126,
          organicRank: 95,

          keywordSales: 97
        },
        {
          date: '2024-06-14',
          searchVolume: 3126,
          organicRank: 138,

          keywordSales: 97
        },
        {
          date: '2024-06-13',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 97
        },
        {
          date: '2024-06-12',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 97
        },
        {
          date: '2024-06-11',
          searchVolume: 3126,
          organicRank: 306,

          keywordSales: 97
        },
        {
          date: '2024-06-10',
          searchVolume: 3126,
          organicRank: 110,

          keywordSales: 97
        },
        {
          date: '2024-06-09',
          searchVolume: 3126,
          organicRank: 109,

          keywordSales: 97
        },
        {
          date: '2024-06-08',
          searchVolume: 3126,
          organicRank: 257,

          keywordSales: 97
        },
        {
          date: '2024-06-07',
          searchVolume: 2686,
          organicRank: 104,

          keywordSales: 97
        },
        {
          date: '2024-06-06',
          searchVolume: 2686,
          organicRank: 90,
          sponsoredRank: 26,
          keywordSales: 97
        },
        {
          date: '2024-06-05',
          searchVolume: 2686,
          organicRank: 306,
          sponsoredRank: 52,
          keywordSales: 97
        },
        {
          date: '2024-06-04',
          searchVolume: 2686,
          organicRank: 306,
          sponsoredRank: 64,
          keywordSales: 97
        },
        {
          date: '2024-06-03',
          searchVolume: 2686,
          organicRank: 306,

          keywordSales: 97
        },
        {
          date: '2024-06-02',
          searchVolume: 2686,
          organicRank: 306,

          keywordSales: 97
        },
        {
          date: '2024-06-01',
          searchVolume: 2686,
          organicRank: 69,
          sponsoredRank: 56,
          keywordSales: 97
        },
        {
          date: '2024-05-31',
          searchVolume: 2513,
          organicRank: 61,
          sponsoredRank: 49,
          keywordSales: 97
        },
        {
          date: '2024-05-30',
          searchVolume: 2513,
          organicRank: 60,
          sponsoredRank: 47,
          keywordSales: 97
        },
        {
          date: '2024-05-29',
          searchVolume: 2513,
          organicRank: 65,
          sponsoredRank: 52,
          keywordSales: 97
        },
        {
          date: '2024-05-28',
          searchVolume: 2513,
          organicRank: 59,
          sponsoredRank: 71,
          keywordSales: 97
        },
        {
          date: '2024-05-27',
          searchVolume: 2513,
          organicRank: 69,
          sponsoredRank: 46,
          keywordSales: 97
        },
        {
          date: '2024-05-26',
          searchVolume: 2513,
          organicRank: 60,

          keywordSales: 97
        },
        {
          date: '2024-05-25',
          searchVolume: 2513,
          organicRank: 77,

          keywordSales: 97
        },
        {
          date: '2024-05-24',
          searchVolume: 3078,
          organicRank: 91,

          keywordSales: 97
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless outdoor camera`,
      keyword: 'wireless outdoor camera',
      searchVolume: 9143,
      organicStartRank: 74,
      organicHighestRank: 11,
      sponsoredStartRank: 22,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-01',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 9293,
          organicRank: 33,
          sponsoredRank: 24,
          keywordSales: 254
        },
        {
          date: '2024-07-03',
          searchVolume: 9293,
          organicRank: 37,
          sponsoredRank: 81,
          keywordSales: 254
        },
        {
          date: '2024-07-02',
          searchVolume: 9293,
          organicRank: 45,
          sponsoredRank: 16,
          keywordSales: 254
        },
        {
          date: '2024-07-01',
          searchVolume: 9293,
          organicRank: 48,

          keywordSales: 254
        },
        {
          date: '2024-06-30',
          searchVolume: 9293,
          organicRank: 44,

          keywordSales: 254
        },
        {
          date: '2024-06-29',
          searchVolume: 9293,
          organicRank: 47,
          sponsoredRank: 47,
          keywordSales: 254
        },
        {
          date: '2024-06-28',
          searchVolume: 10700,
          organicRank: 44,
          sponsoredRank: 36,
          keywordSales: 254
        },
        {
          date: '2024-06-27',
          searchVolume: 10700,
          organicRank: 47,
          sponsoredRank: 51,
          keywordSales: 254
        },
        {
          date: '2024-06-26',
          searchVolume: 10700,
          organicRank: 65,
          sponsoredRank: 12,
          keywordSales: 254
        },
        {
          date: '2024-06-25',
          searchVolume: 10700,
          organicRank: 51,
          sponsoredRank: 13,
          keywordSales: 254
        },
        {
          date: '2024-06-24',
          searchVolume: 10700,
          organicRank: 52,
          sponsoredRank: 17,
          keywordSales: 254
        },
        {
          date: '2024-06-23',
          searchVolume: 10700,
          organicRank: 55,
          sponsoredRank: 29,
          keywordSales: 254
        },
        {
          date: '2024-06-22',
          searchVolume: 10700,
          organicRank: 41,
          sponsoredRank: 25,
          keywordSales: 254
        },
        {
          date: '2024-06-21',
          searchVolume: 10766,
          organicRank: 37,
          sponsoredRank: 7,
          keywordSales: 254
        },
        {
          date: '2024-06-20',
          searchVolume: 10766,
          organicRank: 20,
          sponsoredRank: 72,
          keywordSales: 254
        },
        {
          date: '2024-06-19',
          searchVolume: 10766,
          organicRank: 24,
          sponsoredRank: 14,
          keywordSales: 254
        },
        {
          date: '2024-06-18',
          searchVolume: 10766,
          organicRank: 16,
          sponsoredRank: 65,
          keywordSales: 254
        },
        {
          date: '2024-06-17',
          searchVolume: 10766,
          organicRank: 27,

          keywordSales: 254
        },
        {
          date: '2024-06-16',
          searchVolume: 10766,
          organicRank: 24,

          keywordSales: 254
        },
        {
          date: '2024-06-15',
          searchVolume: 10766,
          organicRank: 25,
          sponsoredRank: 15,
          keywordSales: 254
        },
        {
          date: '2024-06-14',
          searchVolume: 10872,
          organicRank: 69,
          sponsoredRank: 1,
          keywordSales: 254
        },
        {
          date: '2024-06-13',
          searchVolume: 10872,
          organicRank: 93,
          sponsoredRank: 53,
          keywordSales: 254
        },
        {
          date: '2024-06-12',
          searchVolume: 10872,
          organicRank: 94,

          keywordSales: 254
        },
        {
          date: '2024-06-11',
          searchVolume: 10872,
          organicRank: 79,

          keywordSales: 254
        },
        {
          date: '2024-06-10',
          searchVolume: 10872,
          organicRank: 19,
          sponsoredRank: 61,
          keywordSales: 254
        },
        {
          date: '2024-06-09',
          searchVolume: 10872,
          organicRank: 19,
          sponsoredRank: 35,
          keywordSales: 254
        },
        {
          date: '2024-06-08',
          searchVolume: 10872,
          organicRank: 14,
          sponsoredRank: 69,
          keywordSales: 254
        },
        {
          date: '2024-06-07',
          searchVolume: 10289,
          organicRank: 27,

          keywordSales: 254
        },
        {
          date: '2024-06-06',
          searchVolume: 10289,
          organicRank: 15,
          sponsoredRank: 21,
          keywordSales: 254
        },
        {
          date: '2024-06-05',
          searchVolume: 10289,
          organicRank: 15,
          sponsoredRank: 3,
          keywordSales: 254
        },
        {
          date: '2024-06-04',
          searchVolume: 10289,
          organicRank: 11,
          sponsoredRank: 25,
          keywordSales: 254
        },
        {
          date: '2024-06-03',
          searchVolume: 10289,
          organicRank: 19,
          sponsoredRank: 8,
          keywordSales: 254
        },
        {
          date: '2024-06-02',
          searchVolume: 10289,
          organicRank: 22,

          keywordSales: 254
        },
        {
          date: '2024-06-01',
          searchVolume: 10289,
          organicRank: 18,
          sponsoredRank: 76,
          keywordSales: 254
        },
        {
          date: '2024-05-31',
          searchVolume: 9136,
          organicRank: 51,
          sponsoredRank: 37,
          keywordSales: 254
        },
        {
          date: '2024-05-30',
          searchVolume: 9136,
          organicRank: 57,
          sponsoredRank: 69,
          keywordSales: 254
        },
        {
          date: '2024-05-29',
          searchVolume: 9136,
          organicRank: 78,
          sponsoredRank: 58,
          keywordSales: 254
        },
        {
          date: '2024-05-28',
          searchVolume: 9136,
          organicRank: 50,
          sponsoredRank: 73,
          keywordSales: 254
        },
        {
          date: '2024-05-27',
          searchVolume: 9136,
          organicRank: 60,
          sponsoredRank: 64,
          keywordSales: 254
        },
        {
          date: '2024-05-26',
          searchVolume: 9136,
          organicRank: 56,
          sponsoredRank: 11,
          keywordSales: 254
        },
        {
          date: '2024-05-25',
          searchVolume: 9136,
          organicRank: 55,
          sponsoredRank: 2,
          keywordSales: 254
        },
        {
          date: '2024-05-24',
          searchVolume: 9143,
          organicRank: 73,

          keywordSales: 254
        },
        {
          date: '2024-05-23',
          searchVolume: 9143,
          organicRank: 68,
          sponsoredRank: 75,
          keywordSales: 254
        },
        {
          date: '2024-05-22',
          searchVolume: 9143,
          organicRank: 74,
          sponsoredRank: 22,
          keywordSales: 254
        },
        {
          date: '2024-05-21',
          searchVolume: 9143,
          organicRank: 70,
          sponsoredRank: 20,
          keywordSales: 254
        },
        {
          date: '2024-05-20',
          searchVolume: 9143,
          organicRank: 55,

          keywordSales: 254
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless outdoor cameras for home security`,
      keyword: 'wireless outdoor cameras for home security',
      searchVolume: 5896,
      organicStartRank: 76,
      organicHighestRank: 5,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-16',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 5987,
          organicRank: 112,
          sponsoredRank: 29,
          keywordSales: 66
        },
        {
          date: '2024-07-03',
          searchVolume: 5987,
          organicRank: 19,
          sponsoredRank: 8,
          keywordSales: 66
        },
        {
          date: '2024-07-02',
          searchVolume: 5987,
          organicRank: 11,
          sponsoredRank: 46,
          keywordSales: 66
        },
        {
          date: '2024-07-01',
          searchVolume: 5987,
          organicRank: 12,

          keywordSales: 66
        },
        {
          date: '2024-06-30',
          searchVolume: 5987,
          organicRank: 12,

          keywordSales: 66
        },
        {
          date: '2024-06-29',
          searchVolume: 5987,
          organicRank: 8,
          sponsoredRank: 11,
          keywordSales: 66
        },
        {
          date: '2024-06-28',
          searchVolume: 5671,
          organicRank: 5,
          sponsoredRank: 7,
          keywordSales: 66
        },
        {
          date: '2024-06-27',
          searchVolume: 5671,
          organicRank: 5,
          sponsoredRank: 1,
          keywordSales: 66
        },
        {
          date: '2024-06-26',
          searchVolume: 5671,
          organicRank: 8,
          sponsoredRank: 18,
          keywordSales: 66
        },
        {
          date: '2024-06-25',
          searchVolume: 5671,
          organicRank: 60,

          keywordSales: 66
        },
        {
          date: '2024-06-24',
          searchVolume: 5671,
          organicRank: 44,
          sponsoredRank: 3,
          keywordSales: 66
        },
        {
          date: '2024-06-23',
          searchVolume: 5671,
          organicRank: 41,
          sponsoredRank: 15,
          keywordSales: 66
        },
        {
          date: '2024-06-22',
          searchVolume: 5671,
          organicRank: 45,
          sponsoredRank: 11,
          keywordSales: 66
        },
        {
          date: '2024-06-21',
          searchVolume: 5815,
          organicRank: 54,
          sponsoredRank: 36,
          keywordSales: 66
        },
        {
          date: '2024-06-20',
          searchVolume: 5815,
          organicRank: 51,
          sponsoredRank: 7,
          keywordSales: 66
        },
        {
          date: '2024-06-19',
          searchVolume: 5815,
          organicRank: 70,
          sponsoredRank: 11,
          keywordSales: 66
        },
        {
          date: '2024-06-18',
          searchVolume: 5815,
          organicRank: 35,
          sponsoredRank: 50,
          keywordSales: 66
        },
        {
          date: '2024-06-17',
          searchVolume: 5815,
          organicRank: 26,
          sponsoredRank: 26,
          keywordSales: 66
        },
        {
          date: '2024-06-16',
          searchVolume: 5815,
          organicRank: 13,
          sponsoredRank: 7,
          keywordSales: 66
        },
        {
          date: '2024-06-15',
          searchVolume: 5815,
          organicRank: 65,
          sponsoredRank: 8,
          keywordSales: 66
        },
        {
          date: '2024-06-14',
          searchVolume: 5148,
          organicRank: 58,
          sponsoredRank: 1,
          keywordSales: 66
        },
        {
          date: '2024-06-13',
          searchVolume: 5148,
          organicRank: 56,
          sponsoredRank: 24,
          keywordSales: 66
        },
        {
          date: '2024-06-12',
          searchVolume: 5148,
          organicRank: 64,
          sponsoredRank: 60,
          keywordSales: 66
        },
        {
          date: '2024-06-11',
          searchVolume: 5148,
          organicRank: 77,
          sponsoredRank: 34,
          keywordSales: 66
        },
        {
          date: '2024-06-10',
          searchVolume: 5148,
          organicRank: 74,
          sponsoredRank: 9,
          keywordSales: 66
        },
        {
          date: '2024-06-09',
          searchVolume: 5148,
          organicRank: 228,
          sponsoredRank: 66,
          keywordSales: 66
        },
        {
          date: '2024-06-08',
          searchVolume: 5148,
          organicRank: 74,
          sponsoredRank: 64,
          keywordSales: 66
        },
        {
          date: '2024-06-07',
          searchVolume: 5650,
          organicRank: 76,

          keywordSales: 66
        },
        {
          date: '2024-06-06',
          searchVolume: 5650,
          organicRank: 80,
          sponsoredRank: 15,
          keywordSales: 66
        },
        {
          date: '2024-06-05',
          searchVolume: 5650,
          organicRank: 78,
          sponsoredRank: 9,
          keywordSales: 66
        },
        {
          date: '2024-06-04',
          searchVolume: 5650,
          organicRank: 306,
          sponsoredRank: 74,
          keywordSales: 66
        },
        {
          date: '2024-06-03',
          searchVolume: 5650,
          organicRank: 72,

          keywordSales: 66
        },
        {
          date: '2024-06-02',
          searchVolume: 5650,
          organicRank: 54,

          keywordSales: 66
        },
        {
          date: '2024-06-01',
          searchVolume: 5650,
          organicRank: 56,
          sponsoredRank: 61,
          keywordSales: 66
        },
        {
          date: '2024-05-31',
          searchVolume: 5603,
          organicRank: 58,
          sponsoredRank: 5,
          keywordSales: 66
        },
        {
          date: '2024-05-30',
          searchVolume: 5603,
          organicRank: 110,
          sponsoredRank: 60,
          keywordSales: 66
        },
        {
          date: '2024-05-29',
          searchVolume: 5603,
          organicRank: 74,
          sponsoredRank: 60,
          keywordSales: 66
        },
        {
          date: '2024-05-28',
          searchVolume: 5603,
          organicRank: 80,
          sponsoredRank: 34,
          keywordSales: 66
        },
        {
          date: '2024-05-27',
          searchVolume: 5603,
          organicRank: 81,
          sponsoredRank: 41,
          keywordSales: 66
        },
        {
          date: '2024-05-26',
          searchVolume: 5603,
          organicRank: 77,
          sponsoredRank: 11,
          keywordSales: 66
        },
        {
          date: '2024-05-25',
          searchVolume: 5603,
          organicRank: 57,
          sponsoredRank: 2,
          keywordSales: 66
        },
        {
          date: '2024-05-24',
          searchVolume: 5896,
          organicRank: 94,

          keywordSales: 66
        },
        {
          date: '2024-05-23',
          searchVolume: 5896,
          organicRank: 68,
          sponsoredRank: 20,
          keywordSales: 66
        },
        {
          date: '2024-05-22',
          searchVolume: 5896,
          organicRank: 76,
          sponsoredRank: 2,
          keywordSales: 66
        },
        {
          date: '2024-05-21',
          searchVolume: 5896,
          organicRank: 68,
          sponsoredRank: 60,
          keywordSales: 66
        },
        {
          date: '2024-05-20',
          searchVolume: 5896,
          organicRank: 74,

          keywordSales: 66
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless outdoor security camera`,
      keyword: 'wireless outdoor security camera',
      searchVolume: 1895,
      organicStartRank: 75,
      organicHighestRank: 12,
      sponsoredStartRank: 15,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-05-30',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-25',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1932,
          organicRank: 36,
          sponsoredRank: 52,
          keywordSales: 33
        },
        {
          date: '2024-07-02',
          searchVolume: 1932,
          organicRank: 32,
          sponsoredRank: 86,
          keywordSales: 33
        },
        {
          date: '2024-07-01',
          searchVolume: 1932,
          organicRank: 24,

          keywordSales: 33
        },
        {
          date: '2024-06-30',
          searchVolume: 1932,
          organicRank: 22,

          keywordSales: 33
        },
        {
          date: '2024-06-29',
          searchVolume: 1932,
          organicRank: 12,
          sponsoredRank: 12,
          keywordSales: 33
        },
        {
          date: '2024-06-28',
          searchVolume: 1922,
          organicRank: 19,
          sponsoredRank: 57,
          keywordSales: 33
        },
        {
          date: '2024-06-27',
          searchVolume: 1922,
          organicRank: 44,
          sponsoredRank: 15,
          keywordSales: 33
        },
        {
          date: '2024-06-26',
          searchVolume: 1922,
          organicRank: 65,
          sponsoredRank: 23,
          keywordSales: 33
        },
        {
          date: '2024-06-25',
          searchVolume: 1922,
          organicRank: 78,
          sponsoredRank: 49,
          keywordSales: 33
        },
        {
          date: '2024-06-24',
          searchVolume: 1922,
          organicRank: 60,
          sponsoredRank: 36,
          keywordSales: 33
        },
        {
          date: '2024-06-23',
          searchVolume: 1922,
          organicRank: 72,
          sponsoredRank: 5,
          keywordSales: 33
        },
        {
          date: '2024-06-22',
          searchVolume: 1922,
          organicRank: 58,
          sponsoredRank: 13,
          keywordSales: 33
        },
        {
          date: '2024-06-21',
          searchVolume: 2171,
          organicRank: 64,
          sponsoredRank: 72,
          keywordSales: 33
        },
        {
          date: '2024-06-20',
          searchVolume: 2171,
          organicRank: 65,
          sponsoredRank: 93,
          keywordSales: 33
        },
        {
          date: '2024-06-19',
          searchVolume: 2171,
          organicRank: 56,
          sponsoredRank: 35,
          keywordSales: 33
        },
        {
          date: '2024-06-18',
          searchVolume: 2171,
          organicRank: 55,
          sponsoredRank: 31,
          keywordSales: 33
        },
        {
          date: '2024-06-17',
          searchVolume: 2171,
          organicRank: 66,

          keywordSales: 33
        },
        {
          date: '2024-06-16',
          searchVolume: 2171,
          organicRank: 43,

          keywordSales: 33
        },
        {
          date: '2024-06-15',
          searchVolume: 2171,
          organicRank: 51,

          keywordSales: 33
        },
        {
          date: '2024-06-14',
          searchVolume: 2428,
          organicRank: 41,
          sponsoredRank: 25,
          keywordSales: 33
        },
        {
          date: '2024-06-13',
          searchVolume: 2428,
          organicRank: 51,
          sponsoredRank: 23,
          keywordSales: 33
        },
        {
          date: '2024-06-12',
          searchVolume: 2428,
          organicRank: 51,
          sponsoredRank: 59,
          keywordSales: 33
        },
        {
          date: '2024-06-11',
          searchVolume: 2428,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 33
        },
        {
          date: '2024-06-10',
          searchVolume: 2428,
          organicRank: 24,
          sponsoredRank: 16,
          keywordSales: 33
        },
        {
          date: '2024-06-09',
          searchVolume: 2428,
          organicRank: 32,
          sponsoredRank: 58,
          keywordSales: 33
        },
        {
          date: '2024-06-08',
          searchVolume: 2428,
          organicRank: 33,
          sponsoredRank: 88,
          keywordSales: 33
        },
        {
          date: '2024-06-07',
          searchVolume: 2243,
          organicRank: 46,

          keywordSales: 33
        },
        {
          date: '2024-06-06',
          searchVolume: 2243,
          organicRank: 29,
          sponsoredRank: 23,
          keywordSales: 33
        },
        {
          date: '2024-06-05',
          searchVolume: 2243,
          organicRank: 37,
          sponsoredRank: 7,
          keywordSales: 33
        },
        {
          date: '2024-06-04',
          searchVolume: 2243,
          organicRank: 37,
          sponsoredRank: 15,
          keywordSales: 33
        },
        {
          date: '2024-06-03',
          searchVolume: 2243,
          organicRank: 29,

          keywordSales: 33
        },
        {
          date: '2024-06-02',
          searchVolume: 2243,
          organicRank: 23,

          keywordSales: 33
        },
        {
          date: '2024-06-01',
          searchVolume: 2243,
          organicRank: 21,
          sponsoredRank: 19,
          keywordSales: 33
        },
        {
          date: '2024-05-31',
          searchVolume: 1906,
          organicRank: 15,
          sponsoredRank: 64,
          keywordSales: 33
        },
        {
          date: '2024-05-30',
          searchVolume: 1906,
          organicRank: 18,
          sponsoredRank: 60,
          keywordSales: 33
        },
        {
          date: '2024-05-29',
          searchVolume: 1906,
          organicRank: 33,
          sponsoredRank: 77,
          keywordSales: 33
        },
        {
          date: '2024-05-28',
          searchVolume: 1906,
          organicRank: 47,
          sponsoredRank: 92,
          keywordSales: 33
        },
        {
          date: '2024-05-27',
          searchVolume: 1906,
          organicRank: 45,
          sponsoredRank: 2,
          keywordSales: 33
        },
        {
          date: '2024-05-26',
          searchVolume: 1906,
          organicRank: 62,
          sponsoredRank: 11,
          keywordSales: 33
        },
        {
          date: '2024-05-25',
          searchVolume: 1906,
          organicRank: 68,
          sponsoredRank: 12,
          keywordSales: 33
        },
        {
          date: '2024-05-24',
          searchVolume: 1895,
          organicRank: 73,
          sponsoredRank: 71,
          keywordSales: 33
        },
        {
          date: '2024-05-23',
          searchVolume: 1895,
          organicRank: 64,
          sponsoredRank: 20,
          keywordSales: 33
        },
        {
          date: '2024-05-22',
          searchVolume: 1895,
          organicRank: 75,
          sponsoredRank: 15,
          keywordSales: 33
        },
        {
          date: '2024-05-21',
          searchVolume: 1895,
          organicRank: 47,
          sponsoredRank: 10,
          keywordSales: 33
        },
        {
          date: '2024-05-20',
          searchVolume: 1895,
          organicRank: 91,

          keywordSales: 33
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless security camera`,
      keyword: 'wireless security camera',
      searchVolume: 6651,
      organicStartRank: 63,
      organicHighestRank: 15,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-05-25',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 8489,
          organicRank: 52,

          keywordSales: 145
        },
        {
          date: '2024-07-03',
          searchVolume: 8489,
          organicRank: 51,
          sponsoredRank: 23,
          keywordSales: 145
        },
        {
          date: '2024-07-02',
          searchVolume: 8489,
          organicRank: 39,
          sponsoredRank: 48,
          keywordSales: 145
        },
        {
          date: '2024-07-01',
          searchVolume: 8489,
          organicRank: 72,

          keywordSales: 145
        },
        {
          date: '2024-06-30',
          searchVolume: 8489,
          organicRank: 29,

          keywordSales: 145
        },
        {
          date: '2024-06-29',
          searchVolume: 8489,
          organicRank: 23,
          sponsoredRank: 42,
          keywordSales: 145
        },
        {
          date: '2024-06-28',
          searchVolume: 7654,
          organicRank: 23,
          sponsoredRank: 40,
          keywordSales: 145
        },
        {
          date: '2024-06-27',
          searchVolume: 7654,
          organicRank: 30,
          sponsoredRank: 26,
          keywordSales: 145
        },
        {
          date: '2024-06-26',
          searchVolume: 7654,
          organicRank: 20,
          sponsoredRank: 68,
          keywordSales: 145
        },
        {
          date: '2024-06-25',
          searchVolume: 7654,
          organicRank: 21,

          keywordSales: 145
        },
        {
          date: '2024-06-24',
          searchVolume: 7654,
          organicRank: 15,

          keywordSales: 145
        },
        {
          date: '2024-06-23',
          searchVolume: 7654,
          organicRank: 40,
          sponsoredRank: 11,
          keywordSales: 145
        },
        {
          date: '2024-06-22',
          searchVolume: 7654,
          organicRank: 89,
          sponsoredRank: 5,
          keywordSales: 145
        },
        {
          date: '2024-06-21',
          searchVolume: 7711,
          organicRank: 18,

          keywordSales: 145
        },
        {
          date: '2024-06-20',
          searchVolume: 7711,
          organicRank: 24,
          sponsoredRank: 50,
          keywordSales: 145
        },
        {
          date: '2024-06-19',
          searchVolume: 7711,
          organicRank: 47,
          sponsoredRank: 17,
          keywordSales: 145
        },
        {
          date: '2024-06-18',
          searchVolume: 7711,
          organicRank: 43,

          keywordSales: 145
        },
        {
          date: '2024-06-17',
          searchVolume: 7711,
          organicRank: 46,
          sponsoredRank: 19,
          keywordSales: 145
        },
        {
          date: '2024-06-16',
          searchVolume: 7711,
          organicRank: 24,

          keywordSales: 145
        },
        {
          date: '2024-06-15',
          searchVolume: 7711,
          organicRank: 28,
          sponsoredRank: 31,
          keywordSales: 145
        },
        {
          date: '2024-06-14',
          searchVolume: 7713,
          organicRank: 40,
          sponsoredRank: 65,
          keywordSales: 145
        },
        {
          date: '2024-06-13',
          searchVolume: 7713,
          organicRank: 50,
          sponsoredRank: 21,
          keywordSales: 145
        },
        {
          date: '2024-06-12',
          searchVolume: 7713,
          organicRank: 52,
          sponsoredRank: 17,
          keywordSales: 145
        },
        {
          date: '2024-06-11',
          searchVolume: 7713,
          organicRank: 49,

          keywordSales: 145
        },
        {
          date: '2024-06-10',
          searchVolume: 7713,
          organicRank: 49,

          keywordSales: 145
        },
        {
          date: '2024-06-09',
          searchVolume: 7713,
          organicRank: 52,
          sponsoredRank: 73,
          keywordSales: 145
        },
        {
          date: '2024-06-08',
          searchVolume: 7713,
          organicRank: 51,
          sponsoredRank: 12,
          keywordSales: 145
        },
        {
          date: '2024-06-07',
          searchVolume: 7355,
          organicRank: 46,
          sponsoredRank: 18,
          keywordSales: 145
        },
        {
          date: '2024-06-06',
          searchVolume: 7355,
          organicRank: 45,
          sponsoredRank: 16,
          keywordSales: 145
        },
        {
          date: '2024-06-05',
          searchVolume: 7355,
          organicRank: 52,
          sponsoredRank: 81,
          keywordSales: 145
        },
        {
          date: '2024-06-04',
          searchVolume: 7355,
          organicRank: 52,
          sponsoredRank: 20,
          keywordSales: 145
        },
        {
          date: '2024-06-03',
          searchVolume: 7355,
          organicRank: 58,

          keywordSales: 145
        },
        {
          date: '2024-06-02',
          searchVolume: 7355,
          organicRank: 62,

          keywordSales: 145
        },
        {
          date: '2024-06-01',
          searchVolume: 7355,
          organicRank: 32,

          keywordSales: 145
        },
        {
          date: '2024-05-31',
          searchVolume: 7554,
          organicRank: 21,
          sponsoredRank: 26,
          keywordSales: 145
        },
        {
          date: '2024-05-30',
          searchVolume: 7554,
          organicRank: 24,

          keywordSales: 145
        },
        {
          date: '2024-05-29',
          searchVolume: 7554,
          organicRank: 22,
          sponsoredRank: 1,
          keywordSales: 145
        },
        {
          date: '2024-05-28',
          searchVolume: 7554,
          organicRank: 15,
          sponsoredRank: 1,
          keywordSales: 145
        },
        {
          date: '2024-05-27',
          searchVolume: 7554,
          organicRank: 21,
          sponsoredRank: 78,
          keywordSales: 145
        },
        {
          date: '2024-05-26',
          searchVolume: 7554,
          organicRank: 15,
          sponsoredRank: 12,
          keywordSales: 145
        },
        {
          date: '2024-05-25',
          searchVolume: 7554,
          organicRank: 15,
          sponsoredRank: 48,
          keywordSales: 145
        },
        {
          date: '2024-05-24',
          searchVolume: 6651,
          organicRank: 39,

          keywordSales: 145
        },
        {
          date: '2024-05-23',
          searchVolume: 6651,
          organicRank: 46,
          sponsoredRank: 18,
          keywordSales: 145
        },
        {
          date: '2024-05-22',
          searchVolume: 6651,
          organicRank: 63,
          sponsoredRank: 11,
          keywordSales: 145
        },
        {
          date: '2024-05-21',
          searchVolume: 6651,
          organicRank: 71,
          sponsoredRank: 1,
          keywordSales: 145
        },
        {
          date: '2024-05-20',
          searchVolume: 6651,
          organicRank: 69,
          sponsoredRank: 74,
          keywordSales: 145
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless security camera indoor`,
      keyword: 'wireless security camera indoor',
      searchVolume: 1999,
      organicStartRank: 126,
      organicHighestRank: 42,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-05-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 2175,
          organicRank: 121,

          keywordSales: 33
        },
        {
          date: '2024-07-02',
          searchVolume: 2175,
          organicRank: 112,

          keywordSales: 33
        },
        {
          date: '2024-07-01',
          searchVolume: 2175,
          organicRank: 173,

          keywordSales: 33
        },
        {
          date: '2024-06-30',
          searchVolume: 2175,
          organicRank: 67,

          keywordSales: 33
        },
        {
          date: '2024-06-29',
          searchVolume: 2175,
          organicRank: 117,

          keywordSales: 33
        },
        {
          date: '2024-06-28',
          searchVolume: 2165,
          organicRank: 76,

          keywordSales: 33
        },
        {
          date: '2024-06-27',
          searchVolume: 2165,
          organicRank: 89,
          sponsoredRank: 54,
          keywordSales: 33
        },
        {
          date: '2024-06-26',
          searchVolume: 2165,
          organicRank: 93,

          keywordSales: 33
        },
        {
          date: '2024-06-25',
          searchVolume: 2165,
          organicRank: 102,

          keywordSales: 33
        },
        {
          date: '2024-06-24',
          searchVolume: 2165,
          organicRank: 113,

          keywordSales: 33
        },
        {
          date: '2024-06-22',
          searchVolume: 2165,
          organicRank: 121,

          keywordSales: 33
        },
        {
          date: '2024-06-21',
          searchVolume: 1930,
          organicRank: 95,

          keywordSales: 33
        },
        {
          date: '2024-06-20',
          searchVolume: 1930,
          organicRank: 144,

          keywordSales: 33
        },
        {
          date: '2024-06-19',
          searchVolume: 1930,
          organicRank: 97,

          keywordSales: 33
        },
        {
          date: '2024-06-18',
          searchVolume: 1930,
          organicRank: 114,
          sponsoredRank: 68,
          keywordSales: 33
        },
        {
          date: '2024-06-17',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-16',
          searchVolume: 1930,
          organicRank: 98,

          keywordSales: 33
        },
        {
          date: '2024-06-15',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-14',
          searchVolume: 2186,
          organicRank: 122,

          keywordSales: 33
        },
        {
          date: '2024-06-13',
          searchVolume: 2186,
          organicRank: 116,

          keywordSales: 33
        },
        {
          date: '2024-06-12',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-11',
          searchVolume: 2186,
          organicRank: 306,

          keywordSales: 33
        },
        {
          date: '2024-06-10',
          searchVolume: 2186,
          organicRank: 108,

          keywordSales: 33
        },
        {
          date: '2024-06-09',
          searchVolume: 2186,
          organicRank: 107,
          sponsoredRank: 88,
          keywordSales: 33
        },
        {
          date: '2024-06-08',
          searchVolume: 2186,
          organicRank: 94,

          keywordSales: 33
        },
        {
          date: '2024-06-07',
          searchVolume: 2071,
          organicRank: 121,

          keywordSales: 33
        },
        {
          date: '2024-06-06',
          searchVolume: 2071,
          organicRank: 73,
          sponsoredRank: 20,
          keywordSales: 33
        },
        {
          date: '2024-06-05',
          searchVolume: 2071,
          organicRank: 306,
          sponsoredRank: 11,
          keywordSales: 33
        },
        {
          date: '2024-06-04',
          searchVolume: 2071,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 33
        },
        {
          date: '2024-06-03',
          searchVolume: 2071,
          organicRank: 63,

          keywordSales: 33
        },
        {
          date: '2024-06-02',
          searchVolume: 2071,
          organicRank: 63,

          keywordSales: 33
        },
        {
          date: '2024-06-01',
          searchVolume: 2071,
          organicRank: 66,
          sponsoredRank: 51,
          keywordSales: 33
        },
        {
          date: '2024-05-31',
          searchVolume: 2140,
          organicRank: 66,
          sponsoredRank: 88,
          keywordSales: 33
        },
        {
          date: '2024-05-30',
          searchVolume: 2140,
          organicRank: 63,

          keywordSales: 33
        },
        {
          date: '2024-05-29',
          searchVolume: 2140,
          organicRank: 54,
          sponsoredRank: 93,
          keywordSales: 33
        },
        {
          date: '2024-05-28',
          searchVolume: 2140,
          organicRank: 46,
          sponsoredRank: 51,
          keywordSales: 33
        },
        {
          date: '2024-05-27',
          searchVolume: 2140,
          organicRank: 47,
          sponsoredRank: 64,
          keywordSales: 33
        },
        {
          date: '2024-05-26',
          searchVolume: 2140,
          organicRank: 42,

          keywordSales: 33
        },
        {
          date: '2024-05-25',
          searchVolume: 2140,
          organicRank: 48,

          keywordSales: 33
        },
        {
          date: '2024-05-24',
          searchVolume: 1999,
          organicRank: 120,

          keywordSales: 33
        },
        {
          date: '2024-05-23',
          searchVolume: 1999,
          organicRank: 256,

          keywordSales: 33
        },
        {
          date: '2024-05-22',
          searchVolume: 1999,
          organicRank: 126,
          sponsoredRank: 2,
          keywordSales: 33
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless security camera outdoor`,
      keyword: 'wireless security camera outdoor',
      searchVolume: 20625,
      organicStartRank: 91,
      organicHighestRank: 20,
      sponsoredStartRank: 55,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-03',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 20917,
          organicRank: 83,
          sponsoredRank: 58,
          keywordSales: 257
        },
        {
          date: '2024-07-02',
          searchVolume: 20917,
          organicRank: 27,
          sponsoredRank: 58,
          keywordSales: 257
        },
        {
          date: '2024-07-01',
          searchVolume: 20917,
          organicRank: 39,

          keywordSales: 257
        },
        {
          date: '2024-06-30',
          searchVolume: 20917,
          organicRank: 33,

          keywordSales: 257
        },
        {
          date: '2024-06-29',
          searchVolume: 20917,
          organicRank: 60,

          keywordSales: 257
        },
        {
          date: '2024-06-28',
          searchVolume: 20853,
          organicRank: 55,
          sponsoredRank: 39,
          keywordSales: 257
        },
        {
          date: '2024-06-27',
          searchVolume: 20853,
          organicRank: 64,
          sponsoredRank: 9,
          keywordSales: 257
        },
        {
          date: '2024-06-26',
          searchVolume: 20853,
          organicRank: 88,
          sponsoredRank: 24,
          keywordSales: 257
        },
        {
          date: '2024-06-25',
          searchVolume: 20853,
          organicRank: 57,
          sponsoredRank: 35,
          keywordSales: 257
        },
        {
          date: '2024-06-24',
          searchVolume: 20853,
          organicRank: 58,
          sponsoredRank: 48,
          keywordSales: 257
        },
        {
          date: '2024-06-23',
          searchVolume: 20853,
          organicRank: 58,
          sponsoredRank: 24,
          keywordSales: 257
        },
        {
          date: '2024-06-22',
          searchVolume: 20853,
          organicRank: 62,
          sponsoredRank: 3,
          keywordSales: 257
        },
        {
          date: '2024-06-21',
          searchVolume: 22048,
          organicRank: 61,
          sponsoredRank: 41,
          keywordSales: 257
        },
        {
          date: '2024-06-20',
          searchVolume: 22048,
          organicRank: 59,
          sponsoredRank: 29,
          keywordSales: 257
        },
        {
          date: '2024-06-19',
          searchVolume: 22048,
          organicRank: 83,
          sponsoredRank: 12,
          keywordSales: 257
        },
        {
          date: '2024-06-18',
          searchVolume: 22048,
          organicRank: 89,
          sponsoredRank: 24,
          keywordSales: 257
        },
        {
          date: '2024-06-17',
          searchVolume: 22048,
          organicRank: 88,

          keywordSales: 257
        },
        {
          date: '2024-06-16',
          searchVolume: 22048,
          organicRank: 46,

          keywordSales: 257
        },
        {
          date: '2024-06-15',
          searchVolume: 22048,
          organicRank: 52,
          sponsoredRank: 33,
          keywordSales: 257
        },
        {
          date: '2024-06-14',
          searchVolume: 22052,
          organicRank: 83,
          sponsoredRank: 74,
          keywordSales: 257
        },
        {
          date: '2024-06-13',
          searchVolume: 22052,
          organicRank: 306,
          sponsoredRank: 71,
          keywordSales: 257
        },
        {
          date: '2024-06-12',
          searchVolume: 22052,
          organicRank: 306,
          sponsoredRank: 80,
          keywordSales: 257
        },
        {
          date: '2024-06-11',
          searchVolume: 22052,
          organicRank: 306,

          keywordSales: 257
        },
        {
          date: '2024-06-10',
          searchVolume: 22052,
          organicRank: 52,
          sponsoredRank: 90,
          keywordSales: 257
        },
        {
          date: '2024-06-09',
          searchVolume: 22052,
          organicRank: 50,
          sponsoredRank: 76,
          keywordSales: 257
        },
        {
          date: '2024-06-08',
          searchVolume: 22052,
          organicRank: 49,
          sponsoredRank: 64,
          keywordSales: 257
        },
        {
          date: '2024-06-07',
          searchVolume: 19950,
          organicRank: 48,

          keywordSales: 257
        },
        {
          date: '2024-06-06',
          searchVolume: 19950,
          organicRank: 43,
          sponsoredRank: 21,
          keywordSales: 257
        },
        {
          date: '2024-06-05',
          searchVolume: 19950,
          organicRank: 81,
          sponsoredRank: 14,
          keywordSales: 257
        },
        {
          date: '2024-06-04',
          searchVolume: 19950,
          organicRank: 83,
          sponsoredRank: 27,
          keywordSales: 257
        },
        {
          date: '2024-06-03',
          searchVolume: 19950,
          organicRank: 20,

          keywordSales: 257
        },
        {
          date: '2024-06-02',
          searchVolume: 19950,
          organicRank: 33,

          keywordSales: 257
        },
        {
          date: '2024-06-01',
          searchVolume: 19950,
          organicRank: 22,
          sponsoredRank: 25,
          keywordSales: 257
        },
        {
          date: '2024-05-31',
          searchVolume: 20133,
          organicRank: 31,
          sponsoredRank: 57,
          keywordSales: 257
        },
        {
          date: '2024-05-30',
          searchVolume: 20133,
          organicRank: 49,
          sponsoredRank: 54,
          keywordSales: 257
        },
        {
          date: '2024-05-29',
          searchVolume: 20133,
          organicRank: 80,

          keywordSales: 257
        },
        {
          date: '2024-05-28',
          searchVolume: 20133,
          organicRank: 73,
          sponsoredRank: 31,
          keywordSales: 257
        },
        {
          date: '2024-05-27',
          searchVolume: 20133,
          organicRank: 71,
          sponsoredRank: 1,
          keywordSales: 257
        },
        {
          date: '2024-05-26',
          searchVolume: 20133,
          organicRank: 68,
          sponsoredRank: 21,
          keywordSales: 257
        },
        {
          date: '2024-05-25',
          searchVolume: 20133,
          organicRank: 81,
          sponsoredRank: 56,
          keywordSales: 257
        },
        {
          date: '2024-05-24',
          searchVolume: 20625,
          organicRank: 101,
          sponsoredRank: 64,
          keywordSales: 257
        },
        {
          date: '2024-05-23',
          searchVolume: 20625,
          organicRank: 60,
          sponsoredRank: 19,
          keywordSales: 257
        },
        {
          date: '2024-05-22',
          searchVolume: 20625,
          organicRank: 91,
          sponsoredRank: 55,
          keywordSales: 257
        },
        {
          date: '2024-05-21',
          searchVolume: 20625,
          organicRank: 80,
          sponsoredRank: 19,
          keywordSales: 257
        },
        {
          date: '2024-05-20',
          searchVolume: 20625,
          organicRank: 81,

          keywordSales: 257
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless security camera system`,
      keyword: 'wireless security camera system',
      searchVolume: 5903,
      organicStartRank: 12,
      organicHighestRank: 9,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 10,
      organicPageOneWinDate: '2024-05-27',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-05',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 5703,
          organicRank: 27,
          sponsoredRank: 69,
          keywordSales: 23
        },
        {
          date: '2024-07-03',
          searchVolume: 5703,
          organicRank: 24,
          sponsoredRank: 71,
          keywordSales: 23
        },
        {
          date: '2024-07-02',
          searchVolume: 5703,
          organicRank: 28,
          sponsoredRank: 42,
          keywordSales: 23
        },
        {
          date: '2024-07-01',
          searchVolume: 5703,
          organicRank: 41,

          keywordSales: 23
        },
        {
          date: '2024-06-30',
          searchVolume: 5703,
          organicRank: 26,

          keywordSales: 23
        },
        {
          date: '2024-06-29',
          searchVolume: 5703,
          organicRank: 24,
          sponsoredRank: 40,
          keywordSales: 23
        },
        {
          date: '2024-06-28',
          searchVolume: 5131,
          organicRank: 34,
          sponsoredRank: 58,
          keywordSales: 23
        },
        {
          date: '2024-06-27',
          searchVolume: 5131,
          organicRank: 36,
          sponsoredRank: 24,
          keywordSales: 23
        },
        {
          date: '2024-06-26',
          searchVolume: 5131,
          organicRank: 25,
          sponsoredRank: 78,
          keywordSales: 23
        },
        {
          date: '2024-06-25',
          searchVolume: 5131,
          organicRank: 30,
          sponsoredRank: 58,
          keywordSales: 23
        },
        {
          date: '2024-06-24',
          searchVolume: 5131,
          organicRank: 23,
          sponsoredRank: 11,
          keywordSales: 23
        },
        {
          date: '2024-06-23',
          searchVolume: 5131,
          organicRank: 25,
          sponsoredRank: 36,
          keywordSales: 23
        },
        {
          date: '2024-06-22',
          searchVolume: 5131,
          organicRank: 27,
          sponsoredRank: 23,
          keywordSales: 23
        },
        {
          date: '2024-06-21',
          searchVolume: 5696,
          organicRank: 24,
          sponsoredRank: 102,
          keywordSales: 23
        },
        {
          date: '2024-06-20',
          searchVolume: 5696,
          organicRank: 24,
          sponsoredRank: 76,
          keywordSales: 23
        },
        {
          date: '2024-06-19',
          searchVolume: 5696,
          organicRank: 22,
          sponsoredRank: 14,
          keywordSales: 23
        },
        {
          date: '2024-06-18',
          searchVolume: 5696,
          organicRank: 22,
          sponsoredRank: 97,
          keywordSales: 23
        },
        {
          date: '2024-06-17',
          searchVolume: 5696,
          organicRank: 17,
          sponsoredRank: 43,
          keywordSales: 23
        },
        {
          date: '2024-06-16',
          searchVolume: 5696,
          organicRank: 15,

          keywordSales: 23
        },
        {
          date: '2024-06-15',
          searchVolume: 5696,
          organicRank: 17,
          sponsoredRank: 23,
          keywordSales: 23
        },
        {
          date: '2024-06-14',
          searchVolume: 5691,
          organicRank: 25,
          sponsoredRank: 10,
          keywordSales: 23
        },
        {
          date: '2024-06-13',
          searchVolume: 5691,
          organicRank: 27,
          sponsoredRank: 35,
          keywordSales: 23
        },
        {
          date: '2024-06-12',
          searchVolume: 5691,
          organicRank: 50,

          keywordSales: 23
        },
        {
          date: '2024-06-11',
          searchVolume: 5691,
          organicRank: 29,
          sponsoredRank: 27,
          keywordSales: 23
        },
        {
          date: '2024-06-10',
          searchVolume: 5691,
          organicRank: 43,
          sponsoredRank: 80,
          keywordSales: 23
        },
        {
          date: '2024-06-09',
          searchVolume: 5691,
          organicRank: 73,
          sponsoredRank: 34,
          keywordSales: 23
        },
        {
          date: '2024-06-08',
          searchVolume: 5691,
          organicRank: 96,
          sponsoredRank: 76,
          keywordSales: 23
        },
        {
          date: '2024-06-07',
          searchVolume: 5430,
          organicRank: 41,

          keywordSales: 23
        },
        {
          date: '2024-06-06',
          searchVolume: 5430,
          organicRank: 42,
          sponsoredRank: 66,
          keywordSales: 23
        },
        {
          date: '2024-06-05',
          searchVolume: 5430,
          organicRank: 35,
          sponsoredRank: 18,
          keywordSales: 23
        },
        {
          date: '2024-06-04',
          searchVolume: 5430,
          organicRank: 41,
          sponsoredRank: 42,
          keywordSales: 23
        },
        {
          date: '2024-06-03',
          searchVolume: 5430,
          organicRank: 34,

          keywordSales: 23
        },
        {
          date: '2024-06-02',
          searchVolume: 5430,
          organicRank: 37,

          keywordSales: 23
        },
        {
          date: '2024-06-01',
          searchVolume: 5430,
          organicRank: 22,
          sponsoredRank: 62,
          keywordSales: 23
        },
        {
          date: '2024-05-31',
          searchVolume: 5336,
          organicRank: 35,
          sponsoredRank: 48,
          keywordSales: 23
        },
        {
          date: '2024-05-30',
          searchVolume: 5336,
          organicRank: 39,
          sponsoredRank: 36,
          keywordSales: 23
        },
        {
          date: '2024-05-29',
          searchVolume: 5336,
          organicRank: 14,
          sponsoredRank: 77,
          keywordSales: 23
        },
        {
          date: '2024-05-28',
          searchVolume: 5336,
          organicRank: 19,
          sponsoredRank: 55,
          keywordSales: 23
        },
        {
          date: '2024-05-27',
          searchVolume: 5336,
          organicRank: 15,
          sponsoredRank: 66,
          keywordSales: 23
        },
        {
          date: '2024-05-26',
          searchVolume: 5336,
          organicRank: 45,
          sponsoredRank: 47,
          keywordSales: 23
        },
        {
          date: '2024-05-25',
          searchVolume: 5336,
          organicRank: 33,
          sponsoredRank: 29,
          keywordSales: 23
        },
        {
          date: '2024-05-24',
          searchVolume: 5903,
          organicRank: 19,
          sponsoredRank: 35,
          keywordSales: 23
        },
        {
          date: '2024-05-23',
          searchVolume: 5903,
          organicRank: 9,
          sponsoredRank: 59,
          keywordSales: 23
        },
        {
          date: '2024-05-22',
          searchVolume: 5903,
          organicRank: 12,
          sponsoredRank: 13,
          keywordSales: 23
        },
        {
          date: '2024-05-21',
          searchVolume: 5903,
          organicRank: 16,
          sponsoredRank: 5,
          keywordSales: 23
        },
        {
          date: '2024-05-20',
          searchVolume: 5903,
          organicRank: 24,
          sponsoredRank: 52,
          keywordSales: 23
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wireless security cameras`,
      keyword: 'wireless security cameras',
      searchVolume: 4257,
      organicStartRank: 42,
      organicHighestRank: 19,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-06-14',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-05-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-07-04',
          searchVolume: 4338,
          organicRank: 39,

          keywordSales: 6
        },
        {
          date: '2024-07-03',
          searchVolume: 4338,
          organicRank: 43,
          sponsoredRank: 35,
          keywordSales: 6
        },
        {
          date: '2024-07-02',
          searchVolume: 4338,
          organicRank: 70,
          sponsoredRank: 35,
          keywordSales: 6
        },
        {
          date: '2024-07-01',
          searchVolume: 4338,
          organicRank: 52,

          keywordSales: 6
        },
        {
          date: '2024-06-30',
          searchVolume: 4338,
          organicRank: 46,

          keywordSales: 6
        },
        {
          date: '2024-06-29',
          searchVolume: 4338,
          organicRank: 54,

          keywordSales: 6
        },
        {
          date: '2024-06-28',
          searchVolume: 3805,
          organicRank: 49,
          sponsoredRank: 10,
          keywordSales: 6
        },
        {
          date: '2024-06-27',
          searchVolume: 3805,
          organicRank: 47,
          sponsoredRank: 18,
          keywordSales: 6
        },
        {
          date: '2024-06-26',
          searchVolume: 3805,
          organicRank: 76,
          sponsoredRank: 24,
          keywordSales: 6
        },
        {
          date: '2024-06-25',
          searchVolume: 3805,
          organicRank: 77,
          sponsoredRank: 10,
          keywordSales: 6
        },
        {
          date: '2024-06-24',
          searchVolume: 3805,
          organicRank: 35,
          sponsoredRank: 5,
          keywordSales: 6
        },
        {
          date: '2024-06-23',
          searchVolume: 3805,
          organicRank: 58,
          sponsoredRank: 9,
          keywordSales: 6
        },
        {
          date: '2024-06-22',
          searchVolume: 3805,
          organicRank: 66,
          sponsoredRank: 10,
          keywordSales: 6
        },
        {
          date: '2024-06-21',
          searchVolume: 4324,
          organicRank: 65,
          sponsoredRank: 12,
          keywordSales: 6
        },
        {
          date: '2024-06-20',
          searchVolume: 4324,
          organicRank: 64,
          sponsoredRank: 32,
          keywordSales: 6
        },
        {
          date: '2024-06-19',
          searchVolume: 4324,
          organicRank: 31,
          sponsoredRank: 30,
          keywordSales: 6
        },
        {
          date: '2024-06-18',
          searchVolume: 4324,
          organicRank: 44,
          sponsoredRank: 33,
          keywordSales: 6
        },
        {
          date: '2024-06-17',
          searchVolume: 4324,
          organicRank: 33,
          sponsoredRank: 39,
          keywordSales: 6
        },
        {
          date: '2024-06-16',
          searchVolume: 4324,
          organicRank: 38,
          sponsoredRank: 39,
          keywordSales: 6
        },
        {
          date: '2024-06-15',
          searchVolume: 4324,
          organicRank: 25,
          sponsoredRank: 33,
          keywordSales: 6
        },
        {
          date: '2024-06-14',
          searchVolume: 4324,
          organicRank: 19,
          sponsoredRank: 13,
          keywordSales: 6
        },
        {
          date: '2024-06-13',
          searchVolume: 4324,
          organicRank: 34,
          sponsoredRank: 35,
          keywordSales: 6
        },
        {
          date: '2024-06-12',
          searchVolume: 4324,
          organicRank: 47,
          sponsoredRank: 31,
          keywordSales: 6
        },
        {
          date: '2024-06-11',
          searchVolume: 4324,
          organicRank: 78,
          sponsoredRank: 44,
          keywordSales: 6
        },
        {
          date: '2024-06-10',
          searchVolume: 4324,
          organicRank: 38,
          sponsoredRank: 5,
          keywordSales: 6
        },
        {
          date: '2024-06-09',
          searchVolume: 4324,
          organicRank: 33,
          sponsoredRank: 63,
          keywordSales: 6
        },
        {
          date: '2024-06-08',
          searchVolume: 4324,
          organicRank: 36,
          sponsoredRank: 28,
          keywordSales: 6
        },
        {
          date: '2024-06-07',
          searchVolume: 3641,
          organicRank: 37,

          keywordSales: 6
        },
        {
          date: '2024-06-06',
          searchVolume: 3641,
          organicRank: 35,
          sponsoredRank: 41,
          keywordSales: 6
        },
        {
          date: '2024-06-05',
          searchVolume: 3641,
          organicRank: 36,
          sponsoredRank: 7,
          keywordSales: 6
        },
        {
          date: '2024-06-04',
          searchVolume: 3641,
          organicRank: 33,
          sponsoredRank: 2,
          keywordSales: 6
        },
        {
          date: '2024-06-03',
          searchVolume: 3641,
          organicRank: 40,

          keywordSales: 6
        },
        {
          date: '2024-06-02',
          searchVolume: 3641,
          organicRank: 37,

          keywordSales: 6
        },
        {
          date: '2024-06-01',
          searchVolume: 3641,
          organicRank: 42,
          sponsoredRank: 53,
          keywordSales: 6
        },
        {
          date: '2024-05-31',
          searchVolume: 4477,
          organicRank: 34,
          sponsoredRank: 74,
          keywordSales: 6
        },
        {
          date: '2024-05-30',
          searchVolume: 4477,
          organicRank: 32,
          sponsoredRank: 89,
          keywordSales: 6
        },
        {
          date: '2024-05-29',
          searchVolume: 4477,
          organicRank: 29,
          sponsoredRank: 76,
          keywordSales: 6
        },
        {
          date: '2024-05-28',
          searchVolume: 4477,
          organicRank: 26,
          sponsoredRank: 52,
          keywordSales: 6
        },
        {
          date: '2024-05-27',
          searchVolume: 4477,
          organicRank: 24,
          sponsoredRank: 32,
          keywordSales: 6
        },
        {
          date: '2024-05-26',
          searchVolume: 4477,
          organicRank: 21,
          sponsoredRank: 35,
          keywordSales: 6
        },
        {
          date: '2024-05-25',
          searchVolume: 4477,
          organicRank: 49,
          sponsoredRank: 18,
          keywordSales: 6
        },
        {
          date: '2024-05-24',
          searchVolume: 4257,
          organicRank: 90,
          sponsoredRank: 2,
          keywordSales: 6
        },
        {
          date: '2024-05-23',
          searchVolume: 4257,
          organicRank: 46,
          sponsoredRank: 53,
          keywordSales: 6
        },
        {
          date: '2024-05-22',
          searchVolume: 4257,
          organicRank: 42,
          sponsoredRank: 29,
          keywordSales: 6
        },
        {
          date: '2024-05-21',
          searchVolume: 4257,
          organicRank: 47,
          sponsoredRank: 9,
          keywordSales: 6
        },
        {
          date: '2024-05-20',
          searchVolume: 4257,
          organicRank: 59,
          sponsoredRank: 50,
          keywordSales: 6
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wyze camera bundle`,
      keyword: 'wyze camera bundle',
      searchVolume: 2138,
      organicStartRank: 66,
      organicHighestRank: 56,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 1666,
          organicRank: 72
        },
        {
          date: '2024-07-02',
          searchVolume: 1666,
          organicRank: 72
        },
        {
          date: '2024-07-01',
          searchVolume: 1666,
          organicRank: 71
        },
        {
          date: '2024-06-30',
          searchVolume: 1666,
          organicRank: 61
        },
        {
          date: '2024-06-29',
          searchVolume: 1666,
          organicRank: 73
        },
        {
          date: '2024-06-28',
          searchVolume: 2180,
          organicRank: 62
        },
        {
          date: '2024-06-27',
          searchVolume: 2180,
          organicRank: 68
        },
        {
          date: '2024-06-26',
          searchVolume: 2180,
          organicRank: 59
        },
        {
          date: '2024-06-25',
          searchVolume: 2180,
          organicRank: 56
        },
        {
          date: '2024-06-24',
          searchVolume: 2180,
          organicRank: 59
        },
        {
          date: '2024-06-22',
          searchVolume: 2180,
          organicRank: 65
        },
        {
          date: '2024-06-21',
          searchVolume: 1919,
          organicRank: 61
        },
        {
          date: '2024-06-20',
          searchVolume: 1919,
          organicRank: 59
        },
        {
          date: '2024-06-19',
          searchVolume: 1919,
          organicRank: 65
        },
        {
          date: '2024-06-18',
          searchVolume: 1919,
          organicRank: 70
        },
        {
          date: '2024-06-17',
          searchVolume: 1919,
          organicRank: 71
        },
        {
          date: '2024-06-16',
          searchVolume: 1919,
          organicRank: 71
        },
        {
          date: '2024-06-15',
          searchVolume: 1919,
          organicRank: 69
        },
        {
          date: '2024-06-14',
          searchVolume: 2389,
          organicRank: 70
        },
        {
          date: '2024-06-13',
          searchVolume: 2389,
          organicRank: 69
        },
        {
          date: '2024-06-12',
          searchVolume: 2389,
          organicRank: 78
        },
        {
          date: '2024-06-11',
          searchVolume: 2389,
          organicRank: 76
        },
        {
          date: '2024-06-10',
          searchVolume: 2389,
          organicRank: 68
        },
        {
          date: '2024-06-09',
          searchVolume: 2389,
          organicRank: 70
        },
        {
          date: '2024-06-08',
          searchVolume: 2389,
          organicRank: 71
        },
        {
          date: '2024-06-07',
          searchVolume: 2147,
          organicRank: 70
        },
        {
          date: '2024-06-06',
          searchVolume: 2147,
          organicRank: 74
        },
        {
          date: '2024-06-05',
          searchVolume: 2147,
          organicRank: 73
        },
        {
          date: '2024-06-04',
          searchVolume: 2147,
          organicRank: 69
        },
        {
          date: '2024-06-03',
          searchVolume: 2147,
          organicRank: 69
        },
        {
          date: '2024-06-02',
          searchVolume: 2147,
          organicRank: 65
        },
        {
          date: '2024-06-01',
          searchVolume: 2147,
          organicRank: 62
        },
        {
          date: '2024-05-31',
          searchVolume: 1893,
          organicRank: 61
        },
        {
          date: '2024-05-30',
          searchVolume: 1893,
          organicRank: 58
        },
        {
          date: '2024-05-29',
          searchVolume: 1893,
          organicRank: 69
        },
        {
          date: '2024-05-28',
          searchVolume: 1893,
          organicRank: 249
        },
        {
          date: '2024-05-27',
          searchVolume: 1893,
          organicRank: 306
        },
        {
          date: '2024-05-26',
          searchVolume: 1893,
          organicRank: 68
        },
        {
          date: '2024-05-25',
          searchVolume: 1893,
          organicRank: 306
        },
        {
          date: '2024-05-24',
          searchVolume: 2138,
          organicRank: 69
        },
        {
          date: '2024-05-22',
          searchVolume: 2138,
          organicRank: 66
        }
      ]
    },
    {
      campaignId: '829c1f69-2edf-4bd7-8dc5-bee037f67d5c',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id}_wyze outdoor camera wireless`,
      keyword: 'wyze outdoor camera wireless',
      searchVolume: 24439,
      organicStartRank: 68,
      organicHighestRank: 60,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-07-03',
          searchVolume: 20540,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-07-02',
          searchVolume: 20540,
          organicRank: 268,

          keywordSales: 1034
        },
        {
          date: '2024-07-01',
          searchVolume: 20540,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-30',
          searchVolume: 20540,
          organicRank: 79,

          keywordSales: 1034
        },
        {
          date: '2024-06-29',
          searchVolume: 20540,
          organicRank: 76,

          keywordSales: 1034
        },
        {
          date: '2024-06-28',
          searchVolume: 20285,
          organicRank: 78,

          keywordSales: 1034
        },
        {
          date: '2024-06-27',
          searchVolume: 20285,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-26',
          searchVolume: 20285,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-25',
          searchVolume: 20285,
          organicRank: 68,

          keywordSales: 1034
        },
        {
          date: '2024-06-24',
          searchVolume: 20285,
          organicRank: 69,

          keywordSales: 1034
        },
        {
          date: '2024-06-22',
          searchVolume: 20285,
          organicRank: 63,

          keywordSales: 1034
        },
        {
          date: '2024-06-21',
          searchVolume: 22071,
          organicRank: 62,

          keywordSales: 1034
        },
        {
          date: '2024-06-20',
          searchVolume: 22071,
          organicRank: 64,

          keywordSales: 1034
        },
        {
          date: '2024-06-19',
          searchVolume: 22071,
          organicRank: 66,

          keywordSales: 1034
        },
        {
          date: '2024-06-18',
          searchVolume: 22071,
          organicRank: 63,

          keywordSales: 1034
        },
        {
          date: '2024-06-17',
          searchVolume: 22071,
          organicRank: 62,

          keywordSales: 1034
        },
        {
          date: '2024-06-16',
          searchVolume: 22071,
          organicRank: 69,

          keywordSales: 1034
        },
        {
          date: '2024-06-15',
          searchVolume: 22071,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-14',
          searchVolume: 25471,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-13',
          searchVolume: 25471,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-12',
          searchVolume: 25471,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-11',
          searchVolume: 25471,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-10',
          searchVolume: 25471,
          organicRank: 63,

          keywordSales: 1034
        },
        {
          date: '2024-06-09',
          searchVolume: 25471,
          organicRank: 64,

          keywordSales: 1034
        },
        {
          date: '2024-06-08',
          searchVolume: 25471,
          organicRank: 60,

          keywordSales: 1034
        },
        {
          date: '2024-06-07',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-06',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-05',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-04',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-03',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-02',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-06-01',
          searchVolume: 30652,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-05-31',
          searchVolume: 28541,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-05-30',
          searchVolume: 28541,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-05-29',
          searchVolume: 28541,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-05-28',
          searchVolume: 28541,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-05-27',
          searchVolume: 28541,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-05-26',
          searchVolume: 28541,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-05-25',
          searchVolume: 28541,
          organicRank: 306,

          keywordSales: 1034
        },
        {
          date: '2024-05-24',
          searchVolume: 24439,
          organicRank: 67,

          keywordSales: 1034
        },
        {
          date: '2024-05-23',
          searchVolume: 24439,
          organicRank: 62,

          keywordSales: 1034
        },
        {
          date: '2024-05-22',
          searchVolume: 24439,
          organicRank: 68,

          keywordSales: 1034
        },
        {
          date: '2024-05-21',
          searchVolume: 24439,
          organicRank: 77,

          keywordSales: 1034
        }
      ]
    }
  ]
}

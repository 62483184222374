import { ModelTypes } from '@productwindtom/shared-ws-zeus-types'
import { StoreName } from './store-name'

export const STORE_DETAILS: any = {
  [StoreName.amazon]: {
    store: 'Amazon',
    storeLink: 'amazon.com',
    productLink: 'amazon.com/dp/{id}',
    storeReviewLink: 'https://www.amazon.com/review/create-review',
    currency: 'USD'
  },
  [StoreName.amazon_ca]: {
    store: 'Amazon',
    storeLink: 'amazon.ca',
    productLink: 'amazon.ca/dp/{id}',
    storeReviewLink: 'https://www.amazon.ca/review/create-review',
    currency: 'CAD'
  },
  [StoreName.amazon_uk]: {
    store: 'Amazon',
    storeLink: 'amazon.co.uk',
    productLink: 'amazon.co.uk/dp/{id}',
    storeReviewLink: 'https://www.amazon.co.uk/review/create-review',
    currency: 'GBP'
  },
  [StoreName.amazon_de]: {
    store: 'Amazon',
    storeLink: 'amazon.de',
    productLink: 'amazon.de/dp/{id}',
    storeReviewLink: 'https://www.amazon.de/review/create-review',
    currency: 'EUR'
  },
  [StoreName.bestBuy]: {
    store: 'Best Buy',
    storeLink: 'bestbuy.com',
    productLink: 'bestbuy.com/site/{id}.p',
    storeReviewLink: 'https://www.bestbuy.com/purchasehistory/purchases',
    currency: 'USD'
  },
  [StoreName.walmart]: {
    store: 'Walmart',
    storeLink: 'walmart.com',
    productLink: 'walmart.com/ip/{id}',
    storeLoginLink: 'https://www.walmart.com/account/login',
    storeReviewLink: 'https://www.walmart.com/account/wmpurchasehistory',
    currency: 'USD'
  },
  [StoreName.kroger]: {
    store: 'Kroger',
    storeLink: 'kroger.com',
    storeLoginLink: 'https://www.kroger.com/signin',
    storeReviewLink: 'https://www.kroger.com/mypurchases',
    currency: 'USD'
  },
  [StoreName.costco]: {
    store: 'Costco',
    storeLink: 'Costco.com',
    storeLoginLink: 'https://www.costco.com/RenewMembershipView',
    storeReviewLink: 'https://www.costco.com',
    currency: 'USD'
  },
  [StoreName.target]: {
    store: 'Target',
    storeLink: 'target.com',
    storeLoginLink:
      'https://www.target.com/login?client_id=ecom-web-1.0.0&ui_namespace=ui-default&actions=create_session_signin',
    storeReviewLink: 'https://www.target.com/account/orders?lnk=acct_nav_my_account',
    currency: 'USD'
  },
  [StoreName.total_wine]: {
    store: 'Total Wine',
    storeLink: 'totalwine.com',
    storeLoginLink: 'https://www.totalwine.com/register',
    storeReviewLink: 'https://www.totalwine.com/my-account/orders',
    currency: 'USD'
  },
  [StoreName.shopify]: (campaign: ModelTypes['Campaign'], sku: ModelTypes['Sku']) => {
    return {
      store: campaign?.brand?.name,
      storeLink: campaign.applicationProductLink?.originalLink || campaign.brandStoreLink,
      storeReviewLink: sku ? sku.listingLink : undefined,
      currency: 'USD'
    }
  },
  [StoreName.in_store]: (campaign: ModelTypes['Campaign'], sku: ModelTypes['Sku']) => {
    return {
      store: campaign?.brand?.name,
      storeLink: campaign.applicationProductLink?.originalLink || campaign.brandStoreLink,
      storeReviewLink: sku ? sku.listingLink : undefined,
      currency: 'USD'
    }
  },
  [StoreName.dtc]: (campaign: ModelTypes['Campaign'], sku: ModelTypes['Sku']) => {
    return {
      store: campaign?.brand?.name,
      storeLink: campaign.applicationProductLink?.originalLink || campaign.brandStoreLink,
      storeReviewLink: sku ? sku.listingLink : undefined,
      currency: 'USD'
    }
  },
  [StoreName.ios]: {
    store: 'IOS App Store',
    storeLink: 'itms-apps://?action=discover'
  },
  [StoreName.android]: {
    store: 'Android App Store',
    storeLink: 'https://play.google.com/store/apps'
  }
}

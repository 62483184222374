import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import theme from './global'

export const pwTheme = createTheme(
  deepmerge(theme, {
    palette: {
      primary: {
        main: '#002880'
      },
      secondary: {
        main: '#007A7D',
        light: '#E4F1EE'
      }
    }
  })
)

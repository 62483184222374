import { mApiKey } from '@momentum/api'
import { GraphQLTypes, InputType, Selector } from '@productwindtom/shared-momentum-zeus-types'

const invitationSelector = Selector('UserInvitation')({
  id: true,
  email: true,
  brandIds: true,
  agencyId: true,
  companyId: true,
  firstName: true,
  lastName: true,
  phoneNumber: true,
  role: true,
  company: {
    id: true,
    name: true,
    logo: true,
    brands: {
      id: true,
      name: true
    }
  }
})

export type Invitation = InputType<GraphQLTypes['UserInvitation'], typeof invitationSelector>

export const getInvitation = async (id: string) => {
  return (
    await mApiKey('query')({
      getUserInvitation: [{ id }, invitationSelector]
    })
  ).getUserInvitation
}

export const signupUser = async (input: { username: string; password: string; attributes: string }) => {
  return (
    await mApiKey('mutation')({
      signupUser: [
        input,
        {
          code: true,
          success: true
        }
      ]
    })
  ).signupUser
}
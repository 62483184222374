import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_REVIEW_METRICS_LAUNDRY: Array<ModelTypes['CampaignProductReviewRatingMetric']> = [
  {
    fromDate: '2023-08-01',
    toDate: '2023-08-02',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-02',
    toDate: '2023-08-03',
    numRatings: 0,
    numReviews: 0,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-03',
    toDate: '2023-08-04',
    numRatings: 1,
    numReviews: 1,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 4
  },
  {
    fromDate: '2023-08-04',
    toDate: '2023-08-05',
    numRatings: 5,
    numReviews: 5,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.8,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2023-08-05',
    toDate: '2023-08-06',
    numRatings: 8,
    numReviews: 8,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2023-08-06',
    toDate: '2023-08-07',
    numRatings: 11,
    numReviews: 11,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2023-08-07',
    toDate: '2023-08-08',
    numRatings: 15,
    numReviews: 15,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 4,
    reviews: [],
    rating: 4.6,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 4.666666666666667
  },
  {
    fromDate: '2023-08-08',
    toDate: '2023-08-09',
    numRatings: 18,
    numReviews: 18,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        id: '7558c5cf-5666-49f4-a59e-7eb3a3f0a55b',
        title: 'So travel friendly!',
        text: 'I love that this product is so easy! And it leaves my clothes smelling amazing. Also is perfect for travel!',
        name: 'Meg at Our New Nest 244',
        rating: 5,
        attributedUserId: 'd95282fd-73ac-4ea8-9085-02f72ae716b6',
        reviewedAt: '2023-08-10T00:00:00.000Z'
      }
    ],
    rating: 4.6,
    momentumRating: 4.866666666666667,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-09',
    toDate: '2023-08-10',
    numRatings: 19,
    numReviews: 19,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.6,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-10',
    toDate: '2023-08-11',
    numRatings: 19,
    numReviews: 19,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.6,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-11',
    toDate: '2023-08-12',
    numRatings: 23,
    numReviews: 22,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 2,
    momentumReviewRating: 4.866666666666667,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        id: '7da457a5-b727-4444-9f70-7cf50dcecaa4',
        title: 'So practical',
        text: 'Less hassle so easy to use',
        name: 'Laura Gargani',
        rating: 5,
        attributedUserId: '41c89184-73cb-4e55-ae2c-364efcb33d0e',
        reviewedAt: '2023-08-11T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.866666666666667,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2023-08-12',
    toDate: '2023-08-13',
    numRatings: 23,
    numReviews: 22,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-13',
    toDate: '2023-08-14',
    numRatings: 23,
    numReviews: 22,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.3,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-14',
    toDate: '2023-08-15',
    numRatings: 23,
    numReviews: 22,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [
      {
        id: '0d098e4c-90d5-4b6f-af34-9d2306322ba1',
        title: 'Great product and value!',
        text: '$15 for 100 loads plus it works well, smells good and no plastic bottles! Great product and value!',
        name: 'Coach Lora Erickson',
        rating: 5,
        attributedUserId: '915cf822-b2b7-4604-9225-e669af702594',
        reviewedAt: '2023-08-14T00:00:00.000Z'
      }
    ],
    rating: 4.2,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-15',
    toDate: '2023-08-16',
    numRatings: 26,
    numReviews: 25,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.8500000000000005,
    organicRatingCount: 0,
    reviewsInPeriod: 3,
    reviews: [
      {
        id: 'b43033ec-a260-41f8-a577-a44f3bd03bb1',
        title: 'Super love',
        text: 'I love these!! Other brands don’t dissolve or smell good! This smells great and cleans the clothes well!',
        name: 'Michelle',
        rating: 5,
        attributedUserId: 'c0ff4e90-f342-4c75-a666-9e53bce23b16',
        reviewedAt: '2023-08-16T00:00:00.000Z'
      }
    ],
    rating: 4.3,
    momentumRating: 4.8500000000000005,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-16',
    toDate: '2023-08-17',
    numRatings: 27,
    numReviews: 26,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.6000000000000005,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        id: 'dbb31807-cc97-443f-9397-86f673be1563',
        title: 'Great',
        text: 'The smell is super strong but washed the clothes so well! I didn’t expect something so small to do a great job, but I do use their washing powered and had no complaints so I’m not too shocked',
        name: 'makaila wardlaw',
        rating: 4,
        attributedUserId: 'ddde9480-83e9-461f-b000-f3d5f1310daa',
        reviewedAt: '2023-08-17T00:00:00.000Z'
      },
      {
        id: 'e9d82e46-c42b-411f-96aa-1465dabebba3',
        title: 'Super easy',
        text: 'Love how easy these are definitely beats carrying around big jugs that spill. It’s nice because we go to the laundromat so this is way easier! And clothes still feeling fresh and clean :)',
        name: 'Bria Jaime',
        rating: 5,
        attributedUserId: '9793f12c-f097-40b5-8627-f1973f7ccbd0',
        reviewedAt: '2023-08-17T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 4.6000000000000005,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-17',
    toDate: '2023-08-18',
    numRatings: 28,
    numReviews: 27,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.666666666666667,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        id: '62f83134-1f19-4e96-8ee9-cef502cf7a5e',
        title: 'I love laundry sheets',
        text: 'I originally bought some for traveling. They’re great to pack in suitcase to wash clothes at Airbnb or hotel in sink. But I like to use them at home now because it’s less plastic, less bulky, less messy. the sheets have a strong fresh detergent smell strong and leave clothes smelling good and clean 👍',
        name: 'AnnieAmazon',
        rating: 5,
        attributedUserId: 'bfec790c-a3f3-4d5f-9c1b-2d5045c5bdb6',
        reviewedAt: '2023-08-18T00:00:00.000Z'
      },
      {
        id: 'e0b7c4ad-2760-4dc3-9cd1-98b29f62106b',
        title: 'These Smell Amazing!!!',
        text: 'Such and easy thoughtless product! Just throw it in and go! I love it!',
        name: 'Tayler Shaver',
        rating: 5,
        attributedUserId: '6da6958b-65d0-42c6-8d82-841ed993e9d2',
        reviewedAt: '2023-08-18T00:00:00.000Z'
      },
      {
        id: '6675a9ba-e823-4140-a483-d7dd7b4ec4ee',
        title: 'Great product concept!',
        text: 'I love the concept of this product, I live in the city within a smaller home so the space saving versus traditional detergent is a big plus for me. They were super easy to use and got the job done just as well as my other detergents — I get spray tans so there’s usually bronzer on my sheets after and I can be lazy when it comes to pre-treating for stain removal. I really didn’t need it as it got nearly everything out.  My only complaint is that it has fragrance. I prefer “clear” detergent lines that are typically fragrance free as I have very sensitive skin. I’m hoping in the future that option becomes available!',
        name: 'Katelyn Masek',
        rating: 4,
        attributedUserId: 'ce4ef097-75c8-47ac-85e9-ee81144571cd',
        reviewedAt: '2023-08-18T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 4.666666666666667,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-18',
    toDate: '2023-08-19',
    numRatings: 29,
    numReviews: 28,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.4,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-19',
    toDate: '2023-08-20',
    numRatings: 31,
    numReviews: 29,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.933333333333334,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: '4015a70e-5381-422d-a528-ddf660c7b852',
        title: 'Very practical',
        text: 'I loved those sheets detergent. I don’t have a messy from liquid detergent anymore. The box is small and fits in any sport in my laundry room. And the most important, I got very clean clothes.',
        name: 'Thalita Moure',
        rating: 5,
        attributedUserId: '247e0694-7e43-4826-a59b-773188be1289',
        reviewedAt: '2023-08-20T00:00:00.000Z'
      },
      {
        id: '9a58bfe9-43f6-4bd8-a073-e77f3e829d2d',
        title: 'No mess',
        text: 'I love that there is no mess and it only takes 1/2-2 for each load depending on the size!',
        name: 'Kaylee Ellsworth',
        rating: 5,
        attributedUserId: 'b9209642-ec44-40d5-a461-78a8ca62bd20',
        reviewedAt: '2023-08-20T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 4.933333333333334,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-20',
    toDate: '2023-08-21',
    numRatings: 33,
    numReviews: 31,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: 'b8789a44-a991-4509-a334-722169eef69b',
        title: 'Recyclable and compact',
        text: 'This is my new go to detergent from now on. The scent is so so refreshing and it makes my laundry smell so good. I love how it doesn’t take up a lot of space and is compact. The biggest bonus is the whole entire packaging is recyclable. Definitely worth a try!',
        name: 'Dems',
        rating: 5,
        attributedUserId: 'c177bbb6-c4db-469d-8be1-70ecce9ed9e9',
        reviewedAt: '2023-08-21T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 5,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2023-08-21',
    toDate: '2023-08-22',
    numRatings: 34,
    numReviews: 32,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.888888888888888,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        id: 'f459f4b7-3c66-49b7-81c6-2f70d21a670f',
        title: 'Great product!',
        text: 'Love this idea! Takes up less space. No spills, no mess! Easy to travel with',
        name: 'Lisa Powell',
        rating: 5,
        attributedUserId: '2c07b6df-e44f-4cbd-92e9-6a33689cd955',
        reviewedAt: '2023-08-21T00:00:00.000Z'
      },
      {
        id: 'e61611dc-6e3f-4d8d-b5c8-1dd260c5eb25',
        title: 'Very fresh',
        text: 'Love the long lasting smell',
        name: 'Angelika mendoza',
        rating: 5,
        attributedUserId: 'c1e59b3b-7323-4427-af21-c014cf47400c',
        reviewedAt: '2023-08-21T00:00:00.000Z'
      },
      {
        id: 'ca71dea6-03fe-48f2-8bc4-e5285680b240',
        title: 'Convenient clean!',
        text: 'Y’all those big plastic jugs are a thing of the past! I love how convenient and easy these new Power Sheets are. I don’t have to lug around a heavy plastic bottle or worry about its waste.  The sheets are perforated and easily tear apart making laundry even easier. They smell good and leave my clothes smelling so fresh. I’ve used Arm & Hammer detergent for many years and the clean is still the same!  I can definitely see us packing these along on camper trips when we travel because we won’t have to worry about spills!',
        name: 'Monica S.',
        rating: 5,
        attributedUserId: 'ac2cb015-fe2b-4453-b63c-2960fe3b18bf',
        reviewedAt: '2023-08-21T00:00:00.000Z'
      },
      {
        id: '59a5af1d-889c-40c6-93b9-9d1fda81908f',
        title: 'Simple',
        text: 'Smells great and easy to use!',
        name: 'Emilee Eychner',
        rating: 5,
        attributedUserId: 'eb0e033e-acf8-4b0f-a778-2a5a86d8064b',
        reviewedAt: '2023-08-22T00:00:00.000Z'
      }
    ],
    rating: 4.4,
    momentumRating: 4.888888888888888,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-22',
    toDate: '2023-08-23',
    numRatings: 36,
    numReviews: 34,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.776470588235292,
    organicRatingCount: 0,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: 'd71dd184-b496-4125-9076-9de3367411cd',
        title: 'No more waste or mess!',
        text: 'This is such a unique product and I’m glad I tried it! One of my kids is always around and I rarely have 2 hands to be able to pour and dump liquid detergent. These sheets make it so easy to do one handed!  I didn’t find details on the box about this but I did look it up in their website and for small loads you can use just one sheet, which is why there are perforated lines on them. So basically, you’re getting 100 sheets not 50.',
        name: 'Emily + Katie',
        rating: 5,
        attributedUserId: '94b35c12-0808-4c00-b671-2da1467cc737',
        reviewedAt: '2023-08-23T00:00:00.000Z'
      },
      {
        id: 'e5986acd-dfa0-4686-a4bf-0ab9de694354',
        title: 'BEST scent!',
        text: 'My new favorite way to do laundry!!',
        name: 'Marri Chante',
        rating: 5,
        attributedUserId: 'a248153b-538c-4a34-9cfe-dbd37692307e',
        reviewedAt: '2023-08-23T00:00:00.000Z'
      },
      {
        id: 'a6d28886-5b1b-4ad0-8e5f-8ec33eabf17b',
        title: 'Works great!',
        text: 'It cleans the clothes really great. But the smell is not my favorite. It’s really strong too just sitting in the box in my laundry room. But I love that they are nice and portable and easy for travel!',
        name: 'Emma Donaldson',
        rating: 4,
        attributedUserId: '0543f8dd-dc62-4b3c-8dbb-22dd33860866',
        reviewedAt: '2023-08-23T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.776470588235292,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-23',
    toDate: '2023-08-24',
    numRatings: 37,
    numReviews: 35,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.714285714285714,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [
      {
        id: '3f1ab7b0-b77e-4384-8cf8-d35f96457dce',
        title: 'Great and easy to use product!',
        text: 'These detergent sheets are mess free and easy to use. They clean my clothes effectively and gives them a nice fresh scent.',
        name: 'Violet',
        rating: 5,
        attributedUserId: '31769098-bc74-43a1-ab11-855fdf973818',
        reviewedAt: '2023-08-24T00:00:00.000Z'
      },
      {
        id: '1314543a-8680-442b-8994-a9cf641b61c1',
        title: 'Easy to use and Smells so good!',
        text: "How am I just discovering? Wow, I'm pleasantly impressed at how well, they clean my clothes and how amazing they smell. I didn't even use fabric softener or dryer sheets just to see how it would do and they exceeded my expectations. This is perfect because I've been trying to find a way to reduce the amount of plastic I use as well. Win-Win!!",
        name: 'Curvy Runner Girl',
        rating: 5,
        attributedUserId: '02ace3ee-dd34-4895-927e-f38862756427',
        reviewedAt: '2023-08-24T00:00:00.000Z'
      },
      {
        id: '9f382893-51ab-4706-b774-ec37006504a9',
        title: 'Convenient and smell amazing',
        text: 'These are a must have! Super easy to use and smell so good.',
        name: 'Ali',
        rating: 4,
        attributedUserId: '461c32a3-99a7-4b85-9cd4-06e079c79cc3',
        reviewedAt: '2023-08-24T00:00:00.000Z'
      },
      {
        id: 'b9fe8653-cee6-43e1-a187-3387f0f96a25',
        title: 'So useful',
        text: 'I was pleasantly surprised with this product. I didn’t even know these things existed but now love them!',
        name: 'Tez Byrnes',
        rating: 5,
        attributedUserId: 'a29827b6-9a03-4dfa-a788-7f32615adffc',
        reviewedAt: '2023-08-24T00:00:00.000Z'
      },
      {
        id: '2debb9ad-b6de-4497-a8e1-5a4bf17ada60',
        title: 'Smells AMAZING!',
        text: "If you're like me and you want to be able to smell your laundry, then this is for you! I was pleasantly surprised by the cleaning power these Arm & Hammer Laundry Detergent Power Sheets gave. These innovative product is very good for environment and less wastage of plastic . The fresh linen smell is light and subtle and not overpowering. They are so convenient to use and carry anywhere with you easily .They dissolve quickly in both hot and cold water .",
        name: 'Tsaruk Kateryna',
        rating: 5,
        attributedUserId: '4e5ca644-d9c4-4edd-a6af-32976e459fac',
        reviewedAt: '2023-08-24T00:00:00.000Z'
      },
      {
        id: '984df506-e553-4339-80de-8e07de1aea9a',
        title: 'Power Detergent',
        text: 'Works very well, and compact in size. What else one need. 👍🏻',
        name: 'Sarah Munir',
        rating: 5,
        attributedUserId: '27260b6b-5cfa-4186-8914-ff4e3e6beb73',
        reviewedAt: '2023-08-24T00:00:00.000Z'
      },
      {
        id: '16b83ee7-bbb7-417e-9d61-2c65c9809a32',
        title: 'Good product',
        text: 'I really like this product makes it easy for my kiddos to help and it’s mess free. I will say use the required amount per load or you clothes will have a film on them!',
        name: 'Alexandrea McKithen',
        rating: 4,
        attributedUserId: 'ef92ef9d-119d-4ff5-97b7-816cc616dc2a',
        reviewedAt: '2023-08-24T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.714285714285714,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-08-24',
    toDate: '2023-08-25',
    numRatings: 41,
    numReviews: 38,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.872727272727271,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        id: '37a9dfcf-48c3-4c3c-87be-de87de3d7e13',
        title: 'Works well for travel',
        text: 'Easy and compact to take if you are traveling!',
        name: 'Rachelle Farquhar',
        rating: 5,
        attributedUserId: 'f5effb07-2ba6-4f7e-9cc8-afbba029dd11',
        reviewedAt: '2023-08-24T00:00:00.000Z'
      },
      {
        id: 'c173a3ab-b800-491a-8cfc-88cbb7957239',
        title: 'New laundry addition',
        text: 'Love the scent & how convenient they are',
        name: 'Megan',
        rating: 5,
        attributedUserId: '65877f1b-f232-485a-b296-0d8cafc8108a',
        reviewedAt: '2023-08-25T00:00:00.000Z'
      },
      {
        id: 'c6357586-f64d-48ab-b10c-62065ac1c821',
        title: 'Best laundry sheets',
        text: "I really like how easy this product is to use, I don't know why I didn't buy it before",
        name: 'Rina',
        rating: 5,
        attributedUserId: '0cfe24f9-4a28-40fe-9554-1a8db8dc90ca',
        reviewedAt: '2023-08-25T00:00:00.000Z'
      },
      {
        id: '181ff6d8-33c3-4e41-8e94-54d11ab89c28',
        title: 'Easy to Use!',
        text: 'These laundry detergent sheets are easy to use -- at home or on the road -- and environmentally friendly! They are also great for my sensitive skin.',
        name: 'Healthy DC Mom',
        rating: 5,
        attributedUserId: 'f2f51c5f-a3c2-441b-9fa3-7eecfd332a19',
        reviewedAt: '2023-08-25T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.872727272727271,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2023-08-25',
    toDate: '2023-08-26',
    numRatings: 47,
    numReviews: 43,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 4,
    momentumReviewRating: 4.822222222222222,
    organicRatingCount: 1,
    reviewsInPeriod: 6,
    reviews: [
      {
        id: '1c97ef49-36a9-47cf-bbba-11b6d13beaf2',
        title: 'So convenient!',
        text: 'This product is amazing for traveling and smells so good',
        name: 'Holly Van Gilder',
        rating: 5,
        attributedUserId: 'ad1be5a0-c12e-4f0d-90a3-3e988489b6e5',
        reviewedAt: '2023-08-26T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.822222222222222,
    organicRating: 5,
    vineRating: 5
  },
  {
    fromDate: '2023-08-26',
    toDate: '2023-08-27',
    numRatings: 51,
    numReviews: 46,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.6,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        id: 'b287b90b-9a77-4439-a169-a7399da7390c',
        title: 'Takes up less space than large liquid detergent!',
        text: 'Love this product, works extremely well. Takes up less space than the large liquid detergent bottles, plus don’t have to deal with any spills. Will continue to purchase.',
        name: 'Shannon B',
        rating: 5,
        attributedUserId: '450892a2-2cfa-4b3a-ba2f-e623fc67f6a9',
        reviewedAt: '2023-08-27T00:00:00.000Z'
      },
      {
        id: '19577b11-8f0e-4b20-829e-9a381b0fa3ac',
        title: 'Super fragrant but portable',
        text: 'Love the sheets! I just wish they weren’t SO fragrant.',
        name: 'Keylimepixie_',
        rating: 3,
        attributedUserId: 'd150aed6-1e98-44c2-812c-a472164ee983',
        reviewedAt: '2023-08-27T00:00:00.000Z'
      }
    ],
    rating: 4.6,
    momentumRating: 4.6,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2023-08-27',
    toDate: '2023-08-28',
    numRatings: 55,
    numReviews: 49,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.83076923076923,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        id: 'f1767df8-6c8d-4f68-9e83-e295d6f5df04',
        title: 'Nice laundry addition',
        text: 'These sheets are such a nice change from detergent. Compact so less storage space. Clean to use so no spills or messy cups and they smell so good!',
        name: 'Jenna',
        rating: 5,
        attributedUserId: '7bc3218b-6515-49eb-9cb2-1b1df5125448',
        reviewedAt: '2023-08-28T00:00:00.000Z'
      },
      {
        id: 'b0a9f44f-94c9-4660-a88d-8d097886d1d2',
        title: 'So awesome',
        text: 'This is honestly the coolest product and helps the environment.. it also makes laundry so much more simple!',
        name: 'Marta Maria Adames',
        rating: 5,
        attributedUserId: '8103375f-caf9-483b-a33f-d93319cdb892',
        reviewedAt: '2023-08-28T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.83076923076923,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2023-08-28',
    toDate: '2023-08-29',
    numRatings: 57,
    numReviews: 50,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.833333333333334,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: '516d79c8-1fca-492d-8801-6f9f025ead30',
        title: 'Convenient',
        text: 'This packaging is so convenient for home, vacation, college or anywhere. I also liked how fast it dissolved. I have a machine that shuts off when I opened the lid so I can’t let it agitate before putting in detergent. With these sheets I don’t have to worry about any residues on my clothes.',
        name: 'Caleb Watts',
        rating: 5,
        attributedUserId: '41f90eef-6744-43e7-8784-8d61798cc3c1',
        reviewedAt: '2023-08-29T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.833333333333334,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2023-08-29',
    toDate: '2023-08-30',
    numRatings: 61,
    numReviews: 53,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 3,
    momentumReviewRating: 4.933333333333334,
    organicRatingCount: 1,
    reviewsInPeriod: 4,
    reviews: [
      {
        id: '8dfcfeae-3f21-4ab8-ab2f-012613dd459a',
        title: 'This is a game changer!',
        text: 'These are my new favorite thing. I love that I can easily take them with me traveling. They’re perfect for an RV or an Airbnb and their last Waste. They smelled really great and make my laundry perfect.',
        name: 'California Belles',
        rating: 5,
        attributedUserId: '1f05f884-773b-48d5-b766-08bb06a42be3',
        reviewedAt: '2023-08-30T00:00:00.000Z'
      },
      {
        id: '5af3f847-b9ac-47f8-adfe-18df15332147',
        title: 'Woo, less plastic!',
        text: 'I’ve been trying to reduce my plastic consumption over the years and I’m SO excited about these. This laundry detergent works just as well as something that comes in a bottle and it’s super convenient and compact (easy to bring a sheet or two when you’re traveling too!)',
        name: 'Judith',
        rating: 5,
        attributedUserId: '32e52f2f-53da-463e-86e7-57bc7a382e06',
        reviewedAt: '2023-08-30T00:00:00.000Z'
      },
      {
        id: '4a0a735b-1aa2-4142-9eee-9414e123ce34',
        title: 'I love them!',
        text: 'These smell wonderful. I could smell them even before I opened the box. I also really like that they save space, I can travel easily with them, and I don’t have to pour and measure my detergent anymore. Overall, I would highly recommend!',
        name: 'Kelli Hines',
        rating: 5,
        attributedUserId: '2ad0b4d8-c0ef-477d-99b0-dbf986cb217b',
        reviewedAt: '2023-08-30T00:00:00.000Z'
      },
      {
        id: '851ac36b-1b46-4da2-b586-78ec4287610f',
        title: 'Absolutely love these!!',
        text: 'Love the scent and such a game changer!!',
        name: 'Alexis F.',
        rating: 5,
        attributedUserId: '6d94dc8c-408a-4778-bb8d-d352bf67585f',
        reviewedAt: '2023-08-30T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.933333333333334,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2023-08-30',
    toDate: '2023-08-31',
    numRatings: 64,
    numReviews: 55,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.92,
    organicRatingCount: 1,
    reviewsInPeriod: 3,
    reviews: [
      {
        id: '0cc65c89-366d-41f3-af6c-a999b94e0dc9',
        title: 'No mess and quick dissolve',
        text: 'This product is a laundry game changer. It smells amazing, shows high performance cleaning results, plus is totally mess free and by far the best sheet detergent I’ve ever tried. The sheets come pre-measured and are amazing for travel not only saving space but also being completely mess free. They truly are quick dissolve please see video for reference. Also this product is amazing quality and is climate pledge friendly certified on Amazon. Overall really great product!',
        name: 'The Fun Jess',
        rating: 5,
        attributedUserId: 'ffb43554-3c89-44f9-9a17-3e0b91a6be83',
        reviewedAt: '2023-08-31T00:00:00.000Z'
      },
      {
        id: '9b3da835-d87d-4cf7-9c2a-3e102e2008d6',
        title: 'love how easy if is',
        text: "not many people like doing laundry so i feel like this makes life a lot easier when you don't wanna be/don't have time to be doing laundry, just grab one of these sheets n you're good to go!!",
        name: 'nataly perez',
        rating: 5,
        attributedUserId: 'cac4e874-02d7-4326-9dda-3262a838203f',
        reviewedAt: '2023-08-31T00:00:00.000Z'
      },
      {
        id: '8e2ed583-e04a-4474-934f-461496d66a2a',
        title: 'Love it',
        text: 'I love that it doesn’t drip everywhere in my laundry room. Perfect',
        name: 'Emily Marcelin',
        rating: 5,
        attributedUserId: '560e6a1d-d6e3-46ab-96ca-b57cbbca03af',
        reviewedAt: '2023-08-31T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.92,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2023-08-31',
    toDate: '2023-09-01',
    numRatings: 66,
    numReviews: 56,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: '96a6bf2f-6b05-4956-b109-934d5ee9c2d3',
        title: 'The best thing since sliced bread!!',
        text: 'This are the most convenient things that were ever made when it comes to doing laundry. I won’t ever buy liquid again!',
        name: 'Logan Edwards',
        rating: 5,
        attributedUserId: '8aa31fbc-5932-48f5-a42e-32e7ee126cf7',
        reviewedAt: '2023-09-01T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 4.475,
    vineRating: 0
  },
  {
    fromDate: '2023-09-01',
    toDate: '2023-09-02',
    numRatings: 68,
    numReviews: 57,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: '54083dbb-19e3-4351-a6de-f3797a4a96b1',
        title: 'Dissolves fast & deep cleans',
        text: 'I really like these! I wasn’t sure at first how well they’d work. But they’re pretty great and leave the clothes smelling amazing as well! It’s so much faster to grab the pre-measured sheet instead of having to measure everything out yourself! Saves time and saves on messes!',
        name: 'Yasmina',
        rating: 5,
        attributedUserId: '5853065d-f6d3-4832-b941-1623ebc8b742',
        reviewedAt: '2023-09-02T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 4.466666666666667,
    vineRating: 0
  },
  {
    fromDate: '2023-09-02',
    toDate: '2023-09-03',
    numRatings: 68,
    numReviews: 57,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-09-03',
    toDate: '2023-09-04',
    numRatings: 70,
    numReviews: 58,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: '0c01f783-792f-4de8-bf3a-94130d3d52b5',
        title: 'They WORK but are gentle enough for sensitive skin',
        text: 'I was skeptical when I got this product, but it is now one of my favorite laundry products. I’ve been trying to reduce my use of plastics, so this was perfect for me but it’s been a transition slowly and we know: most laundry products use plastics in some way. This laundry detergent works wonders. It melts in hot AND cold water, and doesn’t leave a residue like some of the pods do in cold water. It also is tough enough to clean, but gentle enough that my sensitive skin toddler clothing doesn’t leave them itchy. I highly recommend it, especially when traveling. I took some on my trip to London and it was a life saver! Get it! You’ll love it!',
        name: 'Dani',
        rating: 5,
        attributedUserId: 'f633e4cc-58da-4951-a560-bae240363e54',
        reviewedAt: '2023-09-04T00:00:00.000Z'
      },
      {
        id: '30d228e7-879b-40a9-9d0f-900733b587cb',
        title: 'Super nice!',
        text: 'I have no complaints! It works great and love that it’s compact and takes up less space. Also love that the packaging is better for the environment!',
        name: 'Anjellica Hamrick',
        rating: 5,
        attributedUserId: 'e03ac1b5-5ea3-4b0a-a8fb-f7a5de2f50f7',
        reviewedAt: '2023-09-04T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 4.766666666666667,
    vineRating: 0
  },
  {
    fromDate: '2023-09-04',
    toDate: '2023-09-05',
    numRatings: 73,
    numReviews: 60,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.75,
    organicRatingCount: 1,
    reviewsInPeriod: 3,
    reviews: [
      {
        id: 'd17b7e9b-5e50-4f0e-8f0d-f375c1931b97',
        title: 'Everything is 5 stars except the smell',
        text: 'The convenience of these things is incredible, and I love that they are compact (our “laundry closet” shares space with our kitchen storage, so real estate is valuable. I don’t love the scent, but it smells the same as their other products in this scent 😊 I just tend to go for unscented but ordered these to try out',
        name: 'Taylorv224',
        rating: 4,
        attributedUserId: 'd9afb228-72cb-4c59-9278-7fc63e0cfbac',
        reviewedAt: '2023-09-05T00:00:00.000Z'
      },
      {
        id: '3893559a-251e-4e0c-9603-e7d85af9e839',
        title: 'Great quality',
        text: 'These work and smell amazing ! Such good quality',
        name: 'Alexys Duncan',
        rating: 5,
        attributedUserId: 'a0286e42-e58e-4a71-aaa6-831d01aa3df0',
        reviewedAt: '2023-09-05T00:00:00.000Z'
      },
      {
        id: '092d8501-906a-4ba6-8c4e-97ccdf87d05c',
        title: 'Love this!!',
        text: 'I received this from a sponsor and my honest opinion is these are my favorite. So easy to throw in the washer and you can use have a sheet for small/medium loads or the whole sheet for larger loads. I love the smell it’s so fresh.',
        name: 'Kristen Johnson',
        rating: 5,
        attributedUserId: '0831cf3e-7078-4abb-81e0-564357791dfa',
        reviewedAt: '2023-09-05T00:00:00.000Z'
      },
      {
        id: 'dff07d3a-a2d4-4021-ab03-840065ba7f28',
        title: 'Game Changer for Apartment Dwellers',
        text: 'I have FULLY switched to these sheets ever since trying them. The scent is amazing, they take up no storage space, and my clothes come out clean. Can’t ask for anything more!',
        name: 'Kaila DeRienzo',
        rating: 5,
        attributedUserId: 'e7a3d55d-a712-40d5-9760-0fe78bda9098',
        reviewedAt: '2023-09-05T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.75,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2023-09-05',
    toDate: '2023-09-06',
    numRatings: 75,
    numReviews: 61,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: '8650a6ef-9a2f-45f8-ad82-927b2f0ce938',
        title: 'Amazing',
        text: 'I love using my Arm & Hammer Power Sheets Laundry because comes in pre-measured, concentrated laundry detergent sheets. That means no drips, no spills and is climate pledge friendly!',
        name: 'Elen del Rosario',
        rating: 5,
        attributedUserId: 'ae1e8603-755c-4e5d-b356-984dbabe2c16',
        reviewedAt: '2023-09-06T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 4.466666666666667,
    vineRating: 0
  },
  {
    fromDate: '2023-09-06',
    toDate: '2023-09-07',
    numRatings: 77,
    numReviews: 62,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: '257d4812-b098-45b6-8791-f661cbd6283f',
        title: 'It really really cleans',
        text: 'I am sure people can’t believe that a sheet of detergent will work but this product really works amazing. We are letting our girl do her own laundry now that she doesnt need to measure anything or get anything dirty. The sheet disolved super easy and it smelled amazing',
        name: 'Cynthia Locke',
        rating: 5,
        attributedUserId: '2bce7815-ed8c-4c05-83d6-9b71476fcd14',
        reviewedAt: '2023-09-07T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 4.458333333333333,
    vineRating: 0
  },
  {
    fromDate: '2023-09-07',
    toDate: '2023-09-08',
    numRatings: 77,
    numReviews: 62,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-09-08',
    toDate: '2023-09-09',
    numRatings: 79,
    numReviews: 63,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: 'b44ae466-1e04-4991-b5a5-c1045437ae04',
        title: 'Easy & takes up less space',
        text: 'I honestly hate dealing with huge jugs of laundry detergent and they have a tendency of leaving spots on my clothes (especially shirts). THESE ARE A GAME CHANGER. Not only does the box take up less space & is light weight, I don’t have to deal with liquid anymore. Just drop it in, add clothes, and wash! The only con is that I wish it didn’t have any scent.',
        name: 'Ashley Stanton',
        rating: 4,
        attributedUserId: 'e13ad1f4-f902-46d1-b1ac-6e6385807e8c',
        reviewedAt: '2023-09-09T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 4.527777777777778,
    vineRating: 0
  },
  {
    fromDate: '2023-09-09',
    toDate: '2023-09-10',
    numRatings: 82,
    numReviews: 65,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 3,
    reviews: [
      {
        id: 'e53645f9-af47-4c11-9e7c-25ebaf73cae1',
        title: 'Convenient and Mess Free!',
        text: 'The pre measured concentrated detergent sheets literally make life so easy! They smell amazing as well and the package leaves my house smelling great. Because the sheets are dry they are completely mess free. No spills of drips! Definitely recommend for taking on trips as well!',
        name: 'Fostfam Vibes',
        rating: 5,
        attributedUserId: '47d2df8e-c8cb-4cbc-8c2d-49326e7d6cc1',
        reviewedAt: '2023-09-10T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 4.409090909090909,
    vineRating: 5
  },
  {
    fromDate: '2023-09-10',
    toDate: '2023-09-11',
    numRatings: 82,
    numReviews: 65,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-09-11',
    toDate: '2023-09-12',
    numRatings: 85,
    numReviews: 67,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 2,
    momentumReviewRating: 4.5,
    organicRatingCount: 1,
    reviewsInPeriod: 3,
    reviews: [
      {
        id: '44f1bc20-5040-43fb-bf99-ffb8089c8a2a',
        title: 'it’s nice',
        text: 'it’s a nice product',
        name: 'Shelby Hall',
        rating: 5,
        attributedUserId: '767e0855-1461-4993-82d0-6361edfbc4c6',
        reviewedAt: '2023-09-12T00:00:00.000Z'
      },
      {
        id: 'e9b4fdf9-b040-40c3-a0b4-78b3973bc8e5',
        title: 'Wish it came with softener',
        text: 'I do love that it is better from the environment and I don’t have the big bulky bottle of detergent but I feel it is missing the softener aspect',
        name: 'Courtney',
        rating: 3,
        attributedUserId: 'c018bcc8-df13-4574-aa6e-ef169c4b035d',
        reviewedAt: '2023-09-12T00:00:00.000Z'
      },
      {
        id: '94ec9dec-1320-4f42-92af-d62d4c7141df',
        title: 'RV MUST!!',
        text: 'We love these for the RV! Makes walking to laundry mats easier!',
        name: 'Amanda Ingrid May',
        rating: 5,
        attributedUserId: '296dd660-85b1-4081-8db3-5dd70a31e545',
        reviewedAt: '2023-09-12T00:00:00.000Z'
      },
      {
        id: '2c154c56-cbd2-4cdc-9d16-afca2dcac5b2',
        title: 'Fantastic, easy to use and eco friendly!',
        text: 'I loved this product. I got this on a whim and it was worth every penny. It’s helpful, eco friendly and makes my life so much easier not hailing detergent to the laundry mat!',
        name: 'Brittany Zae',
        rating: 5,
        attributedUserId: '6025d363-9eb4-4163-b8ae-8b8d21c5de19',
        reviewedAt: '2023-09-12T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.5,
    organicRating: 5,
    vineRating: 0
  },
  {
    fromDate: '2023-09-12',
    toDate: '2023-09-13',
    numRatings: 85,
    numReviews: 67,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-09-13',
    toDate: '2023-09-14',
    numRatings: 87,
    numReviews: 68,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 4.8,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: '49e88485-81ba-4d82-b603-d035ddda80a8',
        title: 'It’s alright',
        text: 'I put 2 in the laundry but I don’t think it got it very clean to be honest. I think maybe add a little detergent still.',
        name: 'Carly Reeves',
        rating: 4,
        attributedUserId: 'be290e63-cbf9-4fe6-aef3-4db84debd89f',
        reviewedAt: '2023-09-14T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 4.527777777777778,
    vineRating: 0
  },
  {
    fromDate: '2023-09-14',
    toDate: '2023-09-15',
    numRatings: 87,
    numReviews: 68,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-09-15',
    toDate: '2023-09-16',
    numRatings: 87,
    numReviews: 68,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-09-16',
    toDate: '2023-09-17',
    numRatings: 87,
    numReviews: 68,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-09-17',
    toDate: '2023-09-18',
    numRatings: 88,
    numReviews: 69,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 1,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 1,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 5
  },
  {
    fromDate: '2023-09-18',
    toDate: '2023-09-19',
    numRatings: 90,
    numReviews: 70,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 1,
    momentumReviewRating: 5,
    organicRatingCount: 1,
    reviewsInPeriod: 2,
    reviews: [
      {
        id: '855ed6ab-894d-4d52-ae82-02d946ec4a7b',
        title: 'Smells amazing!',
        text: 'So easy to use and love the scent!',
        name: 'Lady',
        rating: 5,
        attributedUserId: '3b4a6760-6126-48bb-9e00-425c4e187124',
        reviewedAt: '2023-09-19T00:00:00.000Z'
      }
    ],
    rating: 4.5,
    momentumRating: 5,
    organicRating: 4.461538461538462,
    vineRating: 0
  },
  {
    fromDate: '2023-09-19',
    toDate: '2023-09-20',
    numRatings: 90,
    numReviews: 70,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  },
  {
    fromDate: '2023-09-20',
    toDate: '2023-09-21',
    numRatings: 90,
    numReviews: 70,
    ratingsInPeriod: 0,
    momentumRatingCount: 0,
    vineReviewCount: 0,
    momentumReviewCount: 0,
    momentumReviewRating: 4.8,
    organicRatingCount: 0,
    reviewsInPeriod: 0,
    reviews: [],
    rating: 4.5,
    momentumRating: 4.8,
    organicRating: 0,
    vineRating: 0
  }
]

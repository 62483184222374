import { Close } from '@mui/icons-material'
import { DialogContent, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { Box, Stack } from '@mui/system'
import { CreatorApprovalStatus } from '@productwindtom/shared-momentum-zeus-types'
import { DateTime } from 'luxon'
import { CampaignCreatorForApproval } from '../../context/queries'

type ApproveDialogProps = {
  creator: CampaignCreatorForApproval
  open: boolean
  handleClose: () => void
}

export const ViewCreatorFeedbackDialog = ({ creator, open, handleClose }: ApproveDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose} sx={{ p: 1 }}>
      <DialogContent>
        <Stack direction="column">
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <Typography variant="h4" flex={1}>
              Feedback
            </Typography>
            <IconButton
              size="small"
              onClick={handleClose}
              sx={{
                color: theme => theme.palette.grey.A700
              }}
            >
              <Close />
            </IconButton>
          </Stack>

          <Typography variant="label3" mt={2}>
            {creator.approvalState.status === CreatorApprovalStatus.APPROVED ? 'Approved by' : 'Rejected by'}{' '}
            {creator.approvalState.reviewedByUserName || creator.approvalState.reviewedByUserEmail} on{' '}
            {DateTime.fromISO(creator.approvalState.updatedAt).toLocaleString(DateTime.DATE_SHORT)}
          </Typography>

          <Typography variant="label3" mt={3}>
            Provided feedback:
          </Typography>

          <Typography variant="body1" color={theme => theme.palette.grey.A700}>
            Feedback is not shared with the creator
          </Typography>

          <Box
            sx={{
              borderRadius: 1,
              mt: 2,
              p: 1,
              backgroundColor: '#EBEDF6'
            }}
          >
            <Typography variant="label3">{creator.approvalState.feedback}</Typography>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { useReviewsContext } from '@momentum/routes/campaign/e-commerce/reviews/reviewsContext'
import { Stack, Typography, Box, Rating, Paper } from '@mui/material'
import { orderBy, min } from 'lodash'
import React, { useMemo } from 'react'
import { Review } from '@momentum/routes/campaign/context/queries'
import Slider from '@momentum/components/slider'

export const SelectedReviews = () => {
  const { reviewsData } = useReviewsContext()

  const reviews = useMemo(
    () =>
      orderBy(
        reviewsData.flatMap(r => r.reviews).filter(r => r.text),
        r => r.rating,
        'desc'
      ),
    [reviewsData]
  )

  if (!reviews.length) {
    return null
  }

  return (
    <Stack spacing={2}>
      <Typography variant={'h4'}>Select reviews from ProductWind campaign</Typography>
      <Box>
        <Slider dots={reviews.length > 1} adaptiveHeight slidesToShow={min([3, reviews.length])} slidesToScroll={3}>
          {reviews.map((r, index) => (
            <Box key={index}>
              <Box px={1} pb={1}>
                <ReviewBox review={r} />
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Stack>
  )
}

const ReviewBox = ({ review }: { review: Review }) => {
  return (
    <Stack
      alignItems={'space-between'}
      height={'100%'}
      component={Paper}
      sx={{ borderRadius: '8px', width: '100%', height: 180 }}
    >
      <Typography
        px={2}
        pt={2}
        variant={'body1'}
        mb={'auto'}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
        sx={{
          display: '-webkit-box',
          WebkitLineBreak: '5',
          WebkitLineClamp: '5',
          WebkitBoxOrient: 'vertical'
        }}
      >
        {review.text}
      </Typography>
      <Stack spacing={1} mt={2} px={2} pb={2}>
        <Typography variant={'label2'}>{review.name}</Typography>
        <Rating readOnly value={review.rating} />
      </Stack>
    </Stack>
  )
}

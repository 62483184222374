import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { BRANDS } from '../../types'
import { proposalsByBrand } from '../../getProposal/sample-data'

export const SAMPLE_PROPOSALS_BY_BRAND_ID: Record<string, ModelTypes['ModelItemsProposal']> = {
  [BRANDS.CE]: {
    items: proposalsByBrand[BRANDS.CE]
  },
  [BRANDS.CPG]: {
    items: proposalsByBrand[BRANDS.CPG]
  }
}

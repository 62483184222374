import AmazonChoiceIcon from '@momentum/components/icons/amazon-choice'
import { Chip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { GridColDef } from '@mui/x-data-grid-premium'
import { SwitchInput } from '@productwindtom/ui-base'
import { maxBy } from 'lodash'
import { ProductKeywordRecord } from '../../context/selectors'
import { KeywordWinCell } from './KeywordWinsCell'
import { TrendMiniGraph } from './TrendMiniGraph'

export type KeywordWin = {
  type: KeywordWinType
  date?: string
}

// Keyword win types with sorted by priority
export enum KeywordWinType {
  PAGE_ONE_WIN = 3,
  RANK_SURGE = 2,
  NEWLY_RANKED = 1 // Not used yet
}

export type KeywordRowType = {
  id: string
  keyword: string
  trend: ProductKeywordRecord[]
  searchVolume: number
  organicKeywordWins?: KeywordWin[]
  sponsoredKeywordWins?: KeywordWin[]
  organicStartRank?: number
  organicHighestRank?: number
  sponsoredStartRank?: number
  sponsoredHighestRank?: number
  organicSlotsImproved: number
  sponsoredSlotsImproved: number
  hasAmazonChoiceBadge: boolean
  isEditMode: boolean
  idx: number
}

export const ORGANIC_COLUMN_DEFINITIONS: GridColDef<KeywordRowType>[] = [
  {
    headerName: 'Search term',
    field: 'keyword',
    flex: 3,
    display: 'flex',
    align: 'left',
    renderCell: ({ value, row }) => {
      return (
        <Stack spacing={1}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Typography variant="label4">{value}</Typography>
            {!row.isEditMode && row.hasAmazonChoiceBadge && <AmazonChoiceIcon />}
          </Stack>
          {row.isEditMode && (
            <SwitchInput
              name={`keywords[${row.idx}].hasAmazonChoiceBadge`}
              controlLabelProps={{
                label: 'Amazon choice badge'
              }}
              size="small"
              sx={{
                marginLeft: -1
              }}
            />
          )}
        </Stack>
      )
    }
  },
  {
    headerName: 'Trend',
    field: 'trend',
    flex: 1,
    display: 'flex',
    align: 'left',
    sortable: false,
    renderHeader: () => (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography variant="inherit">Trend</Typography>
        <Chip label={'Admin'} color="secondary" size="small" />
      </Stack>
    ),
    renderCell: ({ row }) => {
      return <TrendMiniGraph row={row} />
    }
  },
  {
    headerName: 'Monthly search volume',
    field: 'searchVolume',
    flex: 1.5,
    display: 'flex',
    align: 'center',
    renderCell: ({ value }) => <Typography variant="caption2">{value?.toLocaleString()}</Typography>
  },
  {
    headerName: 'Keyword wins',
    field: 'organicKeywordWins',
    flex: 2,
    display: 'flex',
    align: 'left',
    renderCell: ({ row }) => <KeywordWinCell key={row.keyword} keywordWins={row.organicKeywordWins} />,
    sortComparator: (v1: KeywordWin[] | undefined, v2: KeywordWin[] | undefined) => {
      const v1Type = maxBy(v1, kw => kw.type)?.type
      const v2Type = maxBy(v2, kw => kw.type)?.type
      return (v2Type ?? 0) - (v1Type ?? 0)
    }
  },
  {
    headerName: 'Starting rank',
    field: 'organicStartRank',
    flex: 1,
    display: 'flex',
    align: 'center',
    renderCell: ({ value }) => !!value && <Typography variant="caption2">{value?.toLocaleString()}</Typography>
  },
  {
    headerName: 'Highest rank',
    field: 'organicHighestRank',
    flex: 1,
    display: 'flex',
    align: 'center',
    renderCell: ({ value }) => !!value && <Typography variant="caption2">{value?.toLocaleString()}</Typography>
  },
  {
    headerName: 'Slots improved',
    field: 'organicSlotsImproved',
    flex: 1,
    display: 'flex',
    align: 'center',
    renderCell: ({ value }) =>
      !!value && (
        <Typography variant="caption1" color={theme => (value ? theme.palette.success.main : undefined)}>
          {value ? `+${value.toLocaleString()}` : 0}
        </Typography>
      )
  }
]

export const SPONSORED_COLUMN_DEFINITIONS: GridColDef<KeywordRowType>[] = [
  {
    headerName: 'Search term',
    field: 'keyword',
    flex: 2,
    display: 'flex',
    align: 'left',
    renderCell: ({ value, row }) => {
      return (
        <Stack spacing={1}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Typography variant="label4">{value}</Typography>
            {!row.isEditMode && row.hasAmazonChoiceBadge && <AmazonChoiceIcon />}
          </Stack>
          {row.isEditMode && (
            <SwitchInput
              name={`keywords[${row.idx}].hasAmazonChoiceBadge`}
              controlLabelProps={{ label: 'Amazon choice badge' }}
              size="small"
              sx={{
                marginLeft: -1
              }}
            />
          )}
        </Stack>
      )
    }
  },
  {
    headerName: 'Monthly search volume',
    field: 'searchVolume',
    flex: 1.5,
    display: 'flex',
    align: 'center',
    renderCell: ({ value }) => <Typography variant="caption2">{value?.toLocaleString()}</Typography>
  },
  {
    headerName: 'Keyword wins',
    field: 'sponsoredKeywordWins',
    flex: 2,
    display: 'flex',
    align: 'left',
    renderCell: ({ row }) => <KeywordWinCell keywordWins={row.sponsoredKeywordWins} />
  },
  {
    headerName: 'Starting rank',
    field: 'sponsoredStartRank',
    flex: 1,
    display: 'flex',
    align: 'center',
    renderCell: ({ value }) => !!value && <Typography variant="caption2">{value?.toLocaleString()}</Typography>
  },
  {
    headerName: 'Highest rank',
    field: 'sponsoredHighestRank',
    flex: 1,
    display: 'flex',
    align: 'center',
    renderCell: ({ value }) => !!value && <Typography variant="caption2">{value?.toLocaleString()}</Typography>
  },
  {
    headerName: 'Slots improved',
    field: 'sponsoredSlotsImproved',
    flex: 1,
    display: 'flex',
    align: 'center',
    renderCell: ({ value }) =>
      !!value && (
        <Typography variant="caption1" color={theme => (value ? theme.palette.success.main : undefined)}>
          {value ? `+${value.toLocaleString()}` : 0}
        </Typography>
      )
  }
]

import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, createContext, useContext } from 'react'

export enum SalesReportView {
  REVENUE = 'revenue',
  UNITS_SOLD = 'units sold'
}

export type SalesData = {
  date: DateTime
  fromMomentum: number
  fromOtherCustomers: number
  total: number
}

export type SalesContextType = {
  isIntegrated: boolean
  salesData: SalesData[]
  filteredSalesData: SalesData[]
  minDate: DateTime
  maxDate: DateTime
  startDateFilter?: DateTime
  setStartDateFilter: Dispatch<SetStateAction<DateTime | undefined>>
  endDateFilter?: DateTime
  setEndDateFilter: Dispatch<SetStateAction<DateTime | undefined>>
  isActivatedCreatorsHidden: boolean
  setIsActivatedCreatorsHidden: Dispatch<SetStateAction<boolean>>
  isOtherCustomersHidden: boolean
  setIsOtherCustomersHidden: Dispatch<SetStateAction<boolean>>
  isTotalHidden: boolean
  setIsTotalHidden: Dispatch<SetStateAction<boolean>>
  salesReportView: SalesReportView
  setSalesReportView: Dispatch<SetStateAction<SalesReportView>>
}

const SalesContext = createContext<SalesContextType>({
  isIntegrated: false,
  salesData: [],
  filteredSalesData: [],
  minDate: DateTime.now(),
  maxDate: DateTime.now(),
  startDateFilter: undefined,
  setStartDateFilter: () => {},
  endDateFilter: undefined,
  setEndDateFilter: () => {},
  isActivatedCreatorsHidden: false,
  setIsActivatedCreatorsHidden: () => {},
  isOtherCustomersHidden: false,
  setIsOtherCustomersHidden: () => {},
  isTotalHidden: false,
  setIsTotalHidden: () => {},
  salesReportView: SalesReportView.UNITS_SOLD,
  setSalesReportView: () => {}
})

SalesContext.displayName = 'SalesContext'

export default SalesContext

export const useSalesContext = () => useContext(SalesContext)

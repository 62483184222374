import { withInputBase } from '../InputBase/withInputBase'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { InputBaseProps } from '../InputBase/types'
import { PickersInputComponentLocaleText } from '@mui/x-date-pickers/locales/utils/pickersLocaleTextApi'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

interface DateInputProps extends InputBaseProps {
  minDate?: DateTime
  maxDate?: DateTime
  placeholder?: string
  readOnly?: boolean
  localeText?: PickersInputComponentLocaleText<DateTime>
  sx?: SxProps<Theme>
  disablePast?: boolean
  shouldDisableDate?: (day: unknown) => boolean
  slotProps?: any
}

export const DateInput = ({
  field,
  minDate,
  maxDate,
  localeText,
  readOnly,
  sx,
  slotProps,
  ...rest
}: DateInputProps) => {
  const convertDate = (date?: DateTime | Date | string | null) => {
    if (date instanceof DateTime) {
      return date
    }
    if (date instanceof Date) {
      return DateTime.fromJSDate(date)
    }
    if (typeof date === 'string') {
      return DateTime.fromISO(date)
    }
    return date
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        readOnly={readOnly}
        format="MM/dd/yyyy"
        localeText={localeText}
        value={field?.value ? convertDate(field.value) : null}
        onChange={field?.onChange}
        onViewChange={field?.onBlur}
        disabled={rest.disabled}
        minDate={minDate}
        maxDate={maxDate}
        label={rest.placeholder}
        sx={sx}
        disablePast={rest.disablePast}
        shouldDisableDate={rest.shouldDisableDate}
        slotProps={slotProps}
      />
    </LocalizationProvider>
  )
}

export default withInputBase(DateInput)

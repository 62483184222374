import Loading from '@momentum/components/loading'
import { Summary } from '@momentum/routes/campaign/e-commerce/seo/Summary'
import { SeoGraph } from '@momentum/routes/campaign/e-commerce/seo/seo-graph'
import { Paper, Stack, Button, Dialog } from '@mui/material'
import { Store, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { useCampaignContext } from '../../context/CampaignContext'
import { CollectingSEOResults } from './collecting-rank-results'
import { KeywordTable } from './keyword-table'
import { SeoTable } from './seo-table'
import { useState } from 'react'
import { SeoEditor } from '@momentum/routes/campaign/e-commerce/seo/editor'
import { updatePWSeoSummary, createPWSeoSummary } from '@momentum/routes/campaign/e-commerce/seo/queries'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

export const Seo = () => {
  const { isAdminView } = useUserSessionContext()
  const { legacyProductSEOSummary, setLegacyProductSEOSummary, rawLegacyProductSEOSummary, campaignDetails } =
    useCampaignContext()

  const [seoUploadOpen, setSeoUploadOpen] = useState(false)

  const productSeoSummary = rawLegacyProductSEOSummary || {
    campaignId: campaignDetails.id,
    productSEOSummaryRecords: [],
    keywords: []
  }

  const onSave = async (input: ValueTypes['ModelInputCreatePWProductSeoSummary']) => {
    if (legacyProductSEOSummary?.existing) {
      setLegacyProductSEOSummary(await updatePWSeoSummary({ ...input, campaignId: campaignDetails.id }))
    } else {
      setLegacyProductSEOSummary(await createPWSeoSummary({ ...input, campaignId: campaignDetails.id }))
    }
    setSeoUploadOpen(false)
  }

  if (!legacyProductSEOSummary) {
    return <Loading />
  }

  const isSeoVisible = legacyProductSEOSummary.productSEOSummaryRecords.find(s => !!s.impressions)
  const isKeywordsVisible = legacyProductSEOSummary.keywords.length > 0

  if (!isSeoVisible && !isKeywordsVisible) {
    return (
      <Stack>
        {isAdminView && (
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <Button onClick={() => setSeoUploadOpen(true)} variant={'outlined'}>
              Upload SEO
            </Button>
          </Stack>
        )}
        <CollectingSEOResults />
        <Dialog open={seoUploadOpen} maxWidth={'xl'} fullWidth onClose={() => setSeoUploadOpen(false)}>
          <SeoEditor input={productSeoSummary} onSubmit={onSave} onClose={() => setSeoUploadOpen(false)} />
        </Dialog>
      </Stack>
    )
  }

  const isSeoGraphVisible = campaignDetails.product.store !== Store.kroger

  return (
    <Stack spacing={5}>
      <Stack spacing={2}>
        <Paper sx={{ px: 4, py: 2 }}>
          <Summary />
        </Paper>
        {isAdminView && (
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <Button onClick={() => setSeoUploadOpen(true)} variant={'outlined'}>
              Upload SEO
            </Button>
          </Stack>
        )}
      </Stack>
      {isSeoVisible && isSeoGraphVisible && <SeoGraph />}
      {isSeoVisible && <SeoTable />}
      {isKeywordsVisible && <KeywordTable />}

      <Dialog open={seoUploadOpen} maxWidth={'xl'} fullWidth onClose={() => setSeoUploadOpen(false)}>
        <SeoEditor input={productSeoSummary} onSubmit={onSave} onClose={() => setSeoUploadOpen(false)} />
      </Dialog>
    </Stack>
  )
}

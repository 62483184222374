import { Stack, Typography } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import { Channel, DraftStatus } from '@productwindtom/shared-momentum-zeus-types'
import { DRAFT_CONTENT_STATUS_CONFIG } from '../constants'
import { SocialChannelToString } from '@momentum/utils/socialUtils'
import { notEmpty } from '@productwindtom/shared-node'

const DraftEmptyState = ({
  status,
  platform
}: {
  status: keyof typeof DRAFT_CONTENT_STATUS_CONFIG
  platform: Channel
}) => {
  const platformString = SocialChannelToString[platform] && ` for ${SocialChannelToString[platform]}`

  const text =
    status === DraftStatus.REQUIRES_BRAND_APPROVAL
      ? ['You have no pending content to review', platformString, '!']
      : status === DraftStatus.REQUIRES_USER_CHANGES
        ? ['Your team did not request any content edits', platformString, '.']
        : ['Your team did not approve any content', platformString, '.']

  return (
    <Stack
      py={12}
      alignItems={'center'}
      justifyContent={'center'}
      border={theme => `1px solid ${theme.palette.grey.A200}`}
      borderRadius={'4px'}
    >
      <Stack direction={'row'} spacing={1}>
        {status === DraftStatus.REQUIRES_BRAND_APPROVAL && <CheckCircleOutline color="success" />}
        <Typography variant={'h5'}>{text.filter(notEmpty).join('')}</Typography>
      </Stack>
    </Stack>
  )
}

export default DraftEmptyState

import { Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useApproveContentContext } from '../context/ApproveContentContext'
import { DraftStatus } from '@productwindtom/shared-momentum-zeus-types'

const TimeToAction = () => {
  const {
    content: { draftSubmittedAt, reviewedAt, draftStatus }
  } = useApproveContentContext()

  const timeToAction = useMemo(() => {
    const { days, hours } =
      draftStatus === DraftStatus.REQUIRES_BRAND_APPROVAL
        ? DateTime.now().diff(DateTime.fromISO(draftSubmittedAt), ['days', 'hours'])
        : DateTime.fromISO(reviewedAt!).diff(DateTime.fromISO(draftSubmittedAt!), ['days', 'hours'])

    return [days && `${days} days`, hours && `${Math.round(hours)} hours`].filter(val => !!val).join(', ')
  }, [draftSubmittedAt, draftStatus, reviewedAt])

  return (
    <Typography variant="caption2" color={draftStatus === DraftStatus.REQUIRES_BRAND_APPROVAL ? 'error' : 'inherit'}>
      {draftStatus === DraftStatus.REQUIRES_BRAND_APPROVAL ? 'Waiting ' : 'Reviewed in '}
      {timeToAction}
    </Typography>
  )
}

export default TimeToAction

import { SvgIconProps, SvgIcon } from '@mui/material'

export default function DEFlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 50 50" fill="none">
      <path
        d="M50.6044 19.9802H0.604492V9.92265C0.604492 9.44658 0.990527 9.06055 1.4666 9.06055H49.7424C50.2185 9.06055 50.6045 9.44658 50.6045 9.92265L50.6044 19.9802Z"
        fill="#464655"
      />
      <path
        d="M49.7423 41.8189H1.4666C0.990527 41.8189 0.604492 41.4329 0.604492 40.9568V30.8994H50.6044V40.9568C50.6044 41.433 50.2184 41.8189 49.7423 41.8189Z"
        fill="#FFE15A"
      />
      <path d="M50.6044 19.9795H0.604492V30.8984H50.6044V19.9795Z" fill="#FF4B55" />
    </SvgIcon>
  )
}

import { ProductSeoSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'
import { SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getCampaign/sample-data/keyboard-reviews-product-launch'

export const SAMPLE_CAMPAIGN_SEO_KEYBOARD_REVIEWS_PL: ProductSeoSummary = {
  campaignId: SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL.id,
  productSeoSummaryRecords: [
    {
      fromDate: '2024-04-29',
      toDate: '2024-05-05',
      searchVolume: 16602,
      organicPageOneWins: 6,
      sponsoredPageOneWins: 10
    },
    {
      fromDate: '2024-05-06',
      toDate: '2024-05-12',
      searchVolume: 153674,
      organicPageOneWins: 11,
      sponsoredPageOneWins: 47
    },
    {
      fromDate: '2024-05-13',
      toDate: '2024-05-19',
      searchVolume: 76749,
      organicPageOneWins: 5,
      sponsoredPageOneWins: 34
    },
    {
      fromDate: '2024-05-20',
      toDate: '2024-05-26',
      searchVolume: 79308,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 9
    },
    {
      fromDate: '2024-05-27',
      toDate: '2024-06-02',
      searchVolume: 14397,
      organicPageOneWins: 1,
      sponsoredPageOneWins: 7
    },
    {
      fromDate: '2024-06-03',
      toDate: '2024-06-09',
      searchVolume: 59436,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 4
    },
    {
      fromDate: '2024-06-10',
      toDate: '2024-06-16',
      searchVolume: 32221,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 2
    },
    {
      fromDate: '2024-06-17',
      toDate: '2024-06-23',
      searchVolume: 9120,
      organicPageOneWins: 1,
      sponsoredPageOneWins: 0
    }
  ]
}

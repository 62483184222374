import { yupResolver } from '@hookform/resolvers/yup'
import { ImageSelect } from '@momentum/components/image-select'
import { ManualProduct, Product, ProductUrl } from '@momentum/routes/brand/types'
import { getRetailerOptions } from '@momentum/utils/selectOptions'
import { Close } from '@mui/icons-material'
import { Alert, Button, CircularProgress, Dialog, IconButton, Stack, Typography } from '@mui/material'
import { Retailer, STORE_TO_RETAILER } from '@productwindtom/shared-momentum'
import { ScrapeRequestStatus, UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'
import { getCurrencySymbol, toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { Form, NumberInput, SelectInput, TextInput, RadioInput } from '@productwindtom/ui-base'
import { useFormContext, useWatch } from 'react-hook-form'
import * as uuid from 'uuid'
import * as yup from 'yup'
import { useBrandContext } from '../../context/BrandContext'
import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce, isFunction, uniqBy } from 'lodash'
import { updateManualProduct, createManualProduct, createProductFromUrl } from '../../mutations'
import {
  AMAZON_ASIN_FULL_URL_REGEX,
  AMAZON_ASIN_REGEX,
  getStoreFromRegionRetailer,
  WALMART_ITEM_ID_REGEX
} from '@momentum/utils/storeUtils'
import { captureException } from '@sentry/react'
import { notEmpty } from '@productwindtom/shared-node'
import { retry } from '@lifeomic/attempt'
import { getProductVariations } from '../../queries'
import { LoadingButton } from '@mui/lab'
import Row from '@momentum/components/row'
import CheckOutlineIcon from '@momentum/components/icons/check-outline'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

const schema = yup.object().shape({
  isProductLive: yup.string().required(),
  productId: yup.string().optional(),
  productUrl: yup.string().optional(),
  productTitle: yup
    .string()
    .optional()
    .when('isProductLive', {
      is: 'false',
      then: (schema: yup.Schema) => schema.required('Product title is required when product URL is not provided'),
      otherwise: (schema: yup.Schema) => schema.optional()
    }),
  productImage: yup.string().optional(),
  retailer: yup
    .string()
    .oneOf(Object.values(Retailer))
    .optional()
    .when('isProductLive', {
      is: 'false',
      then: (schema: yup.Schema) => schema.required('Retailer is required when product URL is not provided'),
      otherwise: (schema: yup.Schema) => schema.optional()
    }),
  price: yup
    .number()
    .optional()
    .when('isProductLive', {
      is: 'false',
      then: (schema: yup.Schema) => schema.required('Price is required when product URL is not provided'),
      otherwise: (schema: yup.Schema) => schema.optional()
    })
})

const AddProductDialog = () => {
  const { isAddProductActiveOrCallback, setIsAddProductActiveOrCallback, products } = useBrandContext()
  const handleClose = () => setIsAddProductActiveOrCallback(undefined)

  const selectedProduct = products?.find(p => p.id === isAddProductActiveOrCallback?.selectedProductId)

  return (
    <Dialog open={!!isAddProductActiveOrCallback} onClose={handleClose} fullWidth maxWidth="xs">
      <Form
        autocomplete={'off'}
        onSubmit={() => null}
        resolver={yupResolver(schema) as any}
        defaultValues={{
          productId: selectedProduct?.id || uuid.v4(),
          isProductLive: selectedProduct ? 'false' : 'true',

          productTitle: selectedProduct?.name || undefined,
          productImage: selectedProduct?.productImageKey || undefined,
          retailer: (selectedProduct?.store && STORE_TO_RETAILER[selectedProduct.store]) || undefined,
          price: selectedProduct?.priceCents || undefined
        }}
      >
        <AddProductFormBody handleClose={handleClose} selectedProduct={selectedProduct} />
      </Form>
    </Dialog>
  )
}

const AddProductFormBody = ({
  handleClose,
  selectedProduct
}: {
  handleClose: () => void
  selectedProduct?: Product
}) => {
  const { isAdminView } = useUserSessionContext()
  const { brand, setProducts, isAddProductActiveOrCallback, setIsAddProductActiveOrCallback } = useBrandContext()
  const {
    getValues,
    watch,
    formState: { isValid }
  } = useFormContext()

  // Keeps track of product url fetch execution count in order to ignore past executions when updating state
  const addProductExecutionCount = useRef<number>(0)
  const currentSkuScrape = useRef<string | undefined>()

  // Caching for product url fetches in order to prevent duplicate calls for the same ASIN
  const addProductCache = useRef<{
    [key: string]: Promise<Product | undefined>
  }>({})

  const [addProductFromUrl, setAddProductFromUrlResponse] = useState<Product | undefined>()

  const [addProductState, setAddProductState] = useState<
    | {
        status: ScrapeRequestStatus
        message: string
      }
    | undefined
  >()

  const availableRetailers = getRetailerOptions(brand.region, isAdminView)

  const productId = useWatch({ name: 'productId' })
  const isProductLive = useWatch({ name: 'isProductLive' }) === 'true'

  const handleAddProductFromUrl = useCallback(
    debounce(async () => {
      const data = getValues() as ProductUrl
      const productUrl = (data as ProductUrl)?.productUrl?.split('?')?.[0]

      const productSku = (productUrl?.match(AMAZON_ASIN_REGEX) ||
        productUrl?.match(AMAZON_ASIN_FULL_URL_REGEX) ||
        productUrl?.match(WALMART_ITEM_ID_REGEX))?.[1]

      if (productSku && productSku === currentSkuScrape.current) {
        return
      }

      currentSkuScrape.current = productSku

      let product: Product | undefined

      const currentExecution = addProductExecutionCount.current
      const brandId = brand.id

      try {
        if (!productUrl) {
          return setAddProductState(undefined)
        }

        if (!productSku) {
          if (currentExecution === addProductExecutionCount.current) {
            return setAddProductState({
              status: ScrapeRequestStatus.ERROR,
              message: 'The product URL is not a valid product page. Enter a different URL or contact customer success.'
            })
          } else {
            return
          }
        }

        setAddProductState({
          status: ScrapeRequestStatus.IN_PROGRESS,
          message: `We're searching for your product! This could take up to 30 seconds.`
        })

        // We want to cache the promise to prevent duplicate calls in the event of clearing the input and pasting the same string repeatedly
        const existingProductPromise = addProductCache.current[productSku]

        if (!existingProductPromise) {
          addProductCache.current[productSku] = createProductFromUrl({
            brandId,
            productUrl
          })
        }

        product = await addProductCache.current[productSku]
      } catch (err: any) {
        console.log('err', err)
        captureException(err)
        const error = err?.errors?.[0]

        if (error?.message?.includes('Execution timed out') || error?.message?.includes('IN_PROGRESS')) {
          setAddProductState({
            status: ScrapeRequestStatus.IN_PROGRESS,
            message:
              'Finding the product is taking longer than expected, this operation may take an additional 60 seconds.'
          })

          try {
            product = await retry(
              async () => {
                if (currentExecution === addProductExecutionCount.current) {
                  const createdProduct = await createProductFromUrl({
                    brandId,
                    productUrl
                  })

                  if (!createdProduct) {
                    throw new Error('Product not found')
                  }

                  return createdProduct
                }
              },
              {
                delay: 15000,
                maxAttempts: 8
              }
            )
          } catch (err) {
            console.log('err', err)
          }
        } else if (error.errorType === 'ERROR') {
          setAddProductState({
            status: ScrapeRequestStatus[error.message as keyof typeof ScrapeRequestStatus] || ScrapeRequestStatus.ERROR,
            message: error.message || `Your product was not found. Enter a different URL or contact customer success.`
          })

          return
        }
      }

      if (currentExecution === addProductExecutionCount.current) {
        if (product) {
          const productResp = await getProductVariations(product.id)
          const variations = [
            productResp,
            productResp?.parentSku,
            ...(productResp?.parentSku?.childSkus?.items || []),
            ...(productResp?.childSkus?.items || [])
          ].filter(notEmpty)

          setProducts(currentProducts => uniqBy([product!, ...variations, ...(currentProducts || [])], 'id'))

          if (product.priceCents) {
            setAddProductState({
              status: ScrapeRequestStatus.SUCCESS,
              message: `Success! We found your product.`
            })
            setAddProductFromUrlResponse(product)
          } else {
            setAddProductState({
              status: ScrapeRequestStatus.ERROR,
              message: `We have found your product, but it does not have a price. Please update the price on the retailer or add a placeholder product for this proposal.`
            })
          }
        } else {
          setAddProductState({
            status: ScrapeRequestStatus.ERROR,
            message: `Your product was not found. Enter a different URL or contact customer success.`
          })
        }
      }
    }, 750),
    []
  )

  const handleManualProduct = async () => {
    const data = getValues() as ManualProduct

    try {
      setAddProductState({
        status: ScrapeRequestStatus.IN_PROGRESS,
        message: `We're creating your product!`
      })

      const region = brand.region
      const brandId = brand.id

      const { productId, productTitle: title, productImage, retailer, price } = data

      const productInput = {
        id: productId!,
        store: getStoreFromRegionRetailer(region, retailer),
        name: title,
        price: toCurrencyStringCents(price, region),
        image: productImage
      }

      const product = await (selectedProduct
        ? updateManualProduct(productInput)
        : createManualProduct({
            ...productInput,
            brandId
          }))

      if (product && isFunction(isAddProductActiveOrCallback?.callback)) {
        setAddProductState({
          status: ScrapeRequestStatus.SUCCESS,
          message: `Success! We found your product.`
        })
        setProducts(currentProducts => uniqBy([product!, ...(currentProducts || [])], 'id'))
        isAddProductActiveOrCallback?.callback(product)
        setIsAddProductActiveOrCallback(undefined)
      }
    } catch (err) {
      console.log('err', err)
      setAddProductState({
        status: ScrapeRequestStatus.ERROR,
        message: `Your product was not found. Enter a different URL or contact customer success.`
      })
    }
  }

  useEffect(() => {
    addProductExecutionCount.current++
    setAddProductFromUrlResponse(undefined)
  }, [isProductLive])

  useEffect(() => {
    const watchSub = watch((value, { name }) => {
      if (name && ['isProductLive', 'productUrl'].includes(name)) {
        const isProductLive = value.isProductLive === 'true'
        const productUrl = value.productUrl
        const validProductUrl = isProductLive ? productUrl : undefined

        if (validProductUrl) {
          handleAddProductFromUrl()
        } else {
          setAddProductState(undefined)
        }
      }
    })

    return () => watchSub.unsubscribe()
  }, [])

  return (
    <Stack spacing={3} p={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'h4'}>Add product</Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Stack>
      <Typography variant={'label3'}>
        Add a product via its URL if the product is live. Manually add the product info if the product is not live yet.
      </Typography>
      <RadioInput
        name="isProductLive"
        radioProps={{
          size: 'medium'
        }}
        options={[
          {
            value: true,
            label: <Typography variant="label3">The product is live</Typography>
          },
          {
            value: false,
            label: <Typography variant="label3">The product is NOT live</Typography>
          }
        ]}
      />

      {isProductLive ? (
        <TextInput primaryText={'Enter Product URL'} name={'productUrl'} data-cy="createProduct-productUrlInput" />
      ) : (
        <Stack spacing={3}>
          {!isAdminView && selectedProduct && !selectedProduct?.skuId && (
            <Alert variant="outlined" severity="info" data-cy="selfService-placeholderItem">
              <Typography variant="label3">Select "the product is live" to schedule this campaign.</Typography>
            </Alert>
          )}
          <TextInput primaryText={'Product Title'} name={'productTitle'} data-cy="createProduct-productTitleInput" />
          <ImageSelect
            name={'productImage'}
            productId={productId}
            primaryText={'Product image'}
            buttonText={'Click to upload'}
            replacementText={'Click to replace'}
            buttonProps={{
              variant: 'outlined'
            }}
            uploadType={UploadRequestType.UPLOAD_PRODUCT_IMAGE}
            dataCy="createProduct-imageInput"
          />
          <SelectInput
            primaryText={'Retailer'}
            name={'retailer'}
            options={availableRetailers}
            dataCy="createProduct-retailerInput"
          />
          <Stack direction={'row'} justifyContent={'space-between'} spacing={3}>
            <NumberInput
              primaryText={'Product Price (ASP)'}
              name={'price'}
              prefix={getCurrencySymbol(brand.region)}
              decimalScale={2}
              fullWidth
              returnAsNumber
              returnAsCents
              data-cy="createProduct-priceInput"
            />
          </Stack>
        </Stack>
      )}
      {addProductState && (
        <Row spacing={1} alignItems={'flex-start'} justifyContent={'flex-start'}>
          {addProductState.status === ScrapeRequestStatus.IN_PROGRESS && (
            <CircularProgress
              size={16}
              sx={{
                flex: '0 0 auto'
              }}
            />
          )}
          {addProductState.status === ScrapeRequestStatus.SUCCESS && <CheckOutlineIcon color="success" />}

          <Typography
            data-cy={`createProduct-message-${addProductState.status}`}
            variant="label3"
            {...(addProductState.status === ScrapeRequestStatus.ERROR && { color: 'error' })}
          >
            {addProductState.status === ScrapeRequestStatus.ERROR && '*'}
            {addProductState.message}
          </Typography>
        </Row>
      )}
      <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
        <Button variant={'outlined'} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          data-cy="createProduct-addProduct"
          variant={'contained'}
          disabled={
            !isValid ||
            (isProductLive && addProductState?.status !== ScrapeRequestStatus.SUCCESS) ||
            addProductState?.status === ScrapeRequestStatus.IN_PROGRESS
          }
          onClick={() => {
            addProductExecutionCount.current++
            setAddProductFromUrlResponse(undefined)

            if (isProductLive) {
              if (addProductFromUrl && isFunction(isAddProductActiveOrCallback?.callback)) {
                isAddProductActiveOrCallback?.callback(addProductFromUrl)
                setIsAddProductActiveOrCallback(undefined)
              }
            } else {
              handleManualProduct()
            }
          }}
        >
          {selectedProduct && !isProductLive ? 'Save Product' : 'Add Product'}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default AddProductDialog

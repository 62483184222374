import { yupResolver } from '@hookform/resolvers/yup'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { TableHeadCell } from '@momentum/routes/campaign/e-commerce/common/TableHeadCell'
import EditIcon from '@mui/icons-material/Edit'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import { Stack } from '@mui/system'
import { Store } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { Form, SubmitButton, SwitchInput } from '@productwindtom/ui-base'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { UseFormReturn, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { SeoReportMode, useSeoContext } from '../../context'
import { getWeekIndex } from '../utils'

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body2
}))

const schema = yup
  .object({
    productSeoSummaryRecords: yup
      .array()
      .of(
        yup
          .object({
            fromDate: yup.string().required(),
            toDate: yup.string().required(),
            searchVolume: yup
              .number()
              .notRequired()
              .transform(v => (isNaN(v) ? undefined : v)),
            organicPageOneWins: yup
              .number()
              .notRequired()
              .transform(v => (isNaN(v) ? undefined : v)),
            sponsoredPageOneWins: yup
              .number()
              .notRequired()
              .transform(v => (isNaN(v) ? undefined : v)),
            isOutOfStock: yup.boolean().notRequired()
          })
          .required()
      )
      .required()
  })
  .required()

type SeoTableForm = {
  productSeoSummaryRecords: {
    fromDate: string
    toDate: string
    searchVolume?: number | null
    organicPageOneWins?: number | null
    sponsoredPageOneWins?: number | null
    isOutOfStock?: boolean | null
  }[]
}

export const SeoTable = () => {
  const { productSeoSummary, updateProductSeoSummaryRecords, seoReportMode } = useSeoContext()
  const [isEditMode, setIsEditMode] = useState(false)
  const { isAdminView } = useUserSessionContext()

  const defaultValues: SeoTableForm = useMemo(
    () => ({
      productSeoSummaryRecords:
        productSeoSummary?.productSeoSummaryRecords
          ?.filter(notEmpty)
          .map(({ fromDate, toDate, searchVolume, organicPageOneWins, sponsoredPageOneWins, isOutOfStock }) => ({
            fromDate,
            toDate,
            searchVolume,
            organicPageOneWins,
            sponsoredPageOneWins,
            isOutOfStock
          })) ?? []
    }),
    [productSeoSummary?.productSeoSummaryRecords]
  )

  const handleSubmit = async (data: SeoTableForm, methods: UseFormReturn<SeoTableForm>) => {
    try {
      await updateProductSeoSummaryRecords(data.productSeoSummaryRecords)
    } catch (e) {
      console.error(e)
      toast(<Typography variant={'subtitle2'}>Unknown issue, please try again later</Typography>, { type: 'error' })
    } finally {
      setIsEditMode(false)
    }
  }

  if (!productSeoSummary?.productSeoSummaryRecords.length) {
    return null
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        {isAdminView && (
          <Button
            variant={'contained'}
            startIcon={<EditIcon />}
            onClick={() => setIsEditMode(true)}
            disabled={isEditMode}
            sx={{ alignSelf: 'end' }}
          >
            {seoReportMode === SeoReportMode.ORGANIC ? 'Edit organic wins' : 'Edit sponsored rank wins'}
          </Button>
        )}
        <SeoTableBody defaultValues={defaultValues} isEditMode={isEditMode} />
      </Stack>
    </Form>
  )
}

const SeoTableBody = ({ defaultValues, isEditMode }: { defaultValues: SeoTableForm; isEditMode: boolean }) => {
  const { campaignDetails } = useCampaignContext()
  const { productSeoSummary, seoReportMode } = useSeoContext()

  useEffect(() => {
    reset(defaultValues)
  }, [productSeoSummary?.productSeoSummaryRecords])

  const { watch, reset } = useFormContext<SeoTableForm>()

  const records = watch('productSeoSummaryRecords')

  return (
    <Stack>
      <TableContainer>
        <Table
          sx={{
            display: 'block',
            overflowX: 'auto',
            whiteSpace: 'nowrap'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {records?.map((record, index) => (
                <TableHeadCell
                  key={index}
                  week={(getWeekIndex(campaignDetails.startDate, record.fromDate) ?? 0) + 1}
                  date={DateTime.fromISO(record.fromDate)}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {campaignDetails.product.store !== Store.walmart && seoReportMode === SeoReportMode.ORGANIC && (
              <TableRow>
                <BodyTableCell>
                  <Stack spacing={0.5}>
                    <Stack spacing={0.5} direction={'row'}>
                      <Typography variant="body2">Weekly search impressions</Typography>
                      <Tooltip
                        title={
                          <Stack spacing={1}>
                            <Typography variant={'caption1'}>Organic product impressions in search results</Typography>
                            <Typography variant={'caption2'}>
                              This is the number of times per week that ProductWind estimates your product was viewed by
                              customers in the retailer’s organic search results.
                            </Typography>
                          </Stack>
                        }
                      >
                        <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
                      </Tooltip>
                    </Stack>
                  </Stack>
                </BodyTableCell>
                {records?.map((d, index) => (
                  <BodyTableCell key={index}>
                    <Stack>
                      {defaultValues.productSeoSummaryRecords[index]?.searchVolume?.toLocaleString() ?? '--'}
                    </Stack>
                  </BodyTableCell>
                ))}
              </TableRow>
            )}
            {seoReportMode === SeoReportMode.ORGANIC && (
              <TableRow>
                <BodyTableCell>
                  <Stack spacing={0.5}>
                    <Stack spacing={0.5} direction={'row'}>
                      <Typography variant="body2">Organic Page 1 wins</Typography>
                      <Tooltip
                        title={
                          <Stack>
                            <Typography variant={'caption1'}>Organic Page 1 wins</Typography>
                            <Typography variant={'caption2'}>
                              Your product receives a Page 1 Win for a search term when it is displayed in one of the
                              top 20 organic slots in the retailer’s search results.
                            </Typography>
                          </Stack>
                        }
                      >
                        <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
                      </Tooltip>
                    </Stack>
                  </Stack>
                </BodyTableCell>
                {records?.map((d, index) => (
                  <BodyTableCell key={index}>
                    <Stack>
                      {defaultValues.productSeoSummaryRecords[index]?.organicPageOneWins?.toLocaleString() ?? '--'}
                    </Stack>
                  </BodyTableCell>
                ))}
              </TableRow>
            )}
            {seoReportMode === SeoReportMode.SPONSORED && (
              <TableRow>
                <BodyTableCell>
                  <Stack spacing={0.5}>
                    <Stack spacing={0.5} direction={'row'}>
                      <Typography variant="body2">Sponsored Page 1 wins</Typography>
                      <Tooltip
                        title={
                          <Stack>
                            <Typography variant={'caption1'}>Sponsored Page 1 wins</Typography>
                            <Typography variant={'caption2'}>
                              Your product receives a Page 1 Win for a search term when it is displayed in one of the
                              top 20 sponsored slots in the retailer’s search results.
                            </Typography>
                          </Stack>
                        }
                      >
                        <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
                      </Tooltip>
                    </Stack>
                  </Stack>
                </BodyTableCell>
                {records?.map((d, index) => (
                  <BodyTableCell key={index}>
                    <Stack>
                      {defaultValues.productSeoSummaryRecords[index]?.sponsoredPageOneWins?.toLocaleString() ?? '--'}
                    </Stack>
                  </BodyTableCell>
                ))}
              </TableRow>
            )}
            <TableRow>
              <BodyTableCell>
                <Stack spacing={0.5}>
                  <Stack spacing={0.5} direction={'row'}>
                    <Typography variant="body2">Product OOS?</Typography>
                    <Tooltip
                      title={
                        <Stack>
                          <Typography variant={'caption1'}>Out of stock</Typography>
                          <Typography variant={'caption2'}>Was your product out of stock during the week?</Typography>
                        </Stack>
                      }
                    >
                      <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
                    </Tooltip>
                  </Stack>
                </Stack>
              </BodyTableCell>
              {records?.map((d, index) => (
                <BodyTableCell key={index}>
                  {isEditMode ? (
                    <Stack direction={'row'} alignItems={'center'}>
                      <Typography variant="label3" color={'black'}>
                        No
                      </Typography>
                      <SwitchInput name={`productSeoSummaryRecords[${index}].isOutOfStock`} />
                      <Typography variant="label3" color={theme => theme.palette.grey.A700}>
                        Yes
                      </Typography>
                    </Stack>
                  ) : d.isOutOfStock ? (
                    <Typography variant="label2">Yes</Typography>
                  ) : (
                    <Typography variant="label3" color={theme => theme.palette.grey.A700}>
                      No
                    </Typography>
                  )}
                </BodyTableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {isEditMode && (
        <Stack direction={'row'} spacing={2} mt={2}>
          <SubmitButton variant={'contained'}>Save</SubmitButton>
        </Stack>
      )}
    </Stack>
  )
}

import AlertCard from '@momentum/components/alert-card/AlertCard'
import Metric from '@momentum/components/metric'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Paper, Stack, Typography } from '@mui/material'
import { Channel, DraftStatus } from '@productwindtom/shared-momentum-zeus-types'
import { useMemo } from 'react'

export const Summary = () => {
  const { campaignContentForApproval } = useCampaignContext()

  const { tiktok, instagram, ugc, pendingApproval } = useMemo(
    () =>
      campaignContentForApproval.reduce(
        (acc, { draftContent }) => {
          draftContent?.forEach(({ channel, draftStatus }) => {
            if (draftStatus === DraftStatus.APPROVED) {
              switch (channel) {
                case Channel.INSTAGRAM:
                  acc.instagram++
                  break
                case Channel.TIKTOK:
                  acc.tiktok++
                  break
                case Channel.UGC:
                  acc.ugc++
                  break
              }
            } else if (draftStatus === DraftStatus.REQUIRES_BRAND_APPROVAL) {
              acc.pendingApproval++
            }
          })

          return acc
        },
        {
          tiktok: 0,
          instagram: 0,
          ugc: 0,
          pendingApproval: 0
        }
      ),
    [campaignContentForApproval]
  )

  return (
    <Stack spacing={2}>
      {!!pendingApproval && (
        <AlertCard
          text={`You have ${pendingApproval} pieces of content to review!`}
          subText="Please review content within 24 hours."
        />
      )}
      <Paper sx={{ px: 4, py: 2 }}>
        <Stack spacing={3}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography variant={'h4'}>Approved content summary</Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-evenly'}>
            <Metric label={'pieces of approved Tiktok content'} value={tiktok ? tiktok.toLocaleString() : '--'} />
            <Metric
              label={'pieces of approved Instagram content'}
              value={instagram ? instagram.toLocaleString() : '--'}
            />
            <Metric label={'pieces of approved UGC content'} value={ugc ? ugc.toLocaleString() : '--'} />
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  )
}

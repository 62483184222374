import { Region, Role, AccessLevel } from '@productwindtom/shared-momentum-zeus-types'
import { capitalize, without, words } from 'lodash'
import { Retailer } from '@productwindtom/shared-momentum'
import { notEmpty } from '@productwindtom/shared-node'

export const RETAILER_OPTION_CONFIGS = [
  {
    value: Retailer.AMAZON
  },
  {
    value: Retailer.BEST_BUY,
    regions: [Region.US]
  },
  {
    value: Retailer.TARGET,
    regions: [Region.US]
  },
  {
    value: Retailer.WALMART,
    regions: [Region.US]
  },
  {
    value: Retailer.COSTCO,
    regions: [Region.US]
  },
  {
    value: Retailer.KROGER,
    regions: [Region.US]
  },
  {
    value: Retailer.DTC
  }
]

export const CLIENT_RETAILER_OPTION_CONFIGS = [
  {
    value: Retailer.AMAZON
  },
  {
    value: Retailer.WALMART,
    regions: [Region.US]
  }
]

export const RETAILER_OPTIONS = RETAILER_OPTION_CONFIGS.map(o => ({ value: o.value, label: o.value }))

export const getRetailerOptions = (region: Region, isAdminView?: boolean) =>
  (isAdminView ? RETAILER_OPTION_CONFIGS : CLIENT_RETAILER_OPTION_CONFIGS)
    .filter(o => !o.regions || o.regions.includes(region))
    .map(o => ({
      value: o.value,
      label: o.value
    }))

export const ROLE_OPTIONS = [...without(Object.values(Role), Role.OTHER, Role.AGENCY), Role.OTHER]
  .filter(notEmpty)
  .map(r => ({
    value: r,
    label: words(r).map(capitalize).join(' ')
  }))

export const AGENCY_ROLE_OPTIONS = [{ value: Role.AGENCY, label: words(Role.AGENCY).map(capitalize).join(' ') }]

export const getRoleOptions = (isAgency: boolean) => (isAgency ? AGENCY_ROLE_OPTIONS : ROLE_OPTIONS)

export const ACCESS_LEVEL_OPTIONS = [
  {
    value: AccessLevel.FULL_ACCESS,
    label: 'Editor',
    sublabel: 'The Editor role has full access to schedule campaigns and edit the Brand Profile.'
  },
  {
    value: AccessLevel.VIEW_ONLY,
    label: 'View-only',
    sublabel: 'The View-only role can create proposals but cannot schedule campaigns and cannot edit the Brand Profile.'
  }
]

export const REGION_OPTIONS = [
  { value: Region.US, label: 'US' },
  { value: Region.CA, label: 'Canada' },
  { value: Region.UK, label: 'United Kingdom' },
  { value: Region.DE, label: 'Germany' }
]

import { PRODUCT_COLUMN_DEFINITIONS } from './constants'
import { GridRenderCellParams, useGridApiRef, GridRowId } from '@mui/x-data-grid-premium'
import { IconButton, Stack } from '@mui/material'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { DataGrid } from '@momentum/components/table'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useState, useMemo } from 'react'
import { keyBy, uniq, map, uniqBy } from 'lodash'
import { notEmpty } from '@productwindtom/shared-node'
import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { Search } from '@mui/icons-material'
import { useProductsContext } from '@momentum/routes/products/productsContext'

export const ProductAlerts = () => {
  const { products, brand } = useBrandContext()
  const { listingAlerts } = useProductsContext()
  const [search, setSearch] = useState('')

  const apiRef = useGridApiRef()

  const productsById = useMemo(() => keyBy(products, p => p.id), [products])
  const alertsByParent = useMemo(() => keyBy(listingAlerts, a => a.parentSkuId), [listingAlerts])
  const alertSkus = useMemo(() => keyBy(listingAlerts.map(a => a.skuIds).flat(), 'id'), [listingAlerts])

  const productRows = useMemo(
    () =>
      (products || [])
        .filter(p => alertsByParent[p.parentSkuId || p.id!] || alertSkus[p.id])
        .map(product => {
          const children = (products || []).filter(p => p.parentSkuId === product.id)

          const alert = alertsByParent[product.parentSkuId || product.id!]

          return {
            ...(product?.ratingSummary || {}),
            ...product,
            isParent: !!children.length,
            variation: children.length ? 'Parent' : '',
            path: uniq([product.parentSkuId, product.id].filter(notEmpty)),
            store: product.store,
            alertType: alert.alert
          }
        }),
    [products, brand, productsById, alertsByParent, alertSkus]
  )

  const filteredProductRows = useMemo(() => {
    const filteredRows = productRows.filter(
      p =>
        !search ||
        p.name.toLowerCase().includes(search) ||
        (search.length > 1 &&
          (p.skuId?.toLowerCase()?.startsWith(search) ||
            productsById?.[p.parentSkuId || '']?.skuId?.toLowerCase().startsWith(search)))
    )

    const parentSkuIds = uniq(map(filteredRows, p => p.parentSkuId))
    const parentSkus = productRows.filter(p => parentSkuIds.includes(p.id))

    return uniqBy([...filteredRows, ...parentSkus], r => r.id)
  }, [productRows, search])

  const expandRow = (id: GridRowId, isExpanded: boolean) => {
    const rowNode = apiRef.current.getRowNode(id)
    if (rowNode?.type === 'group') {
      apiRef.current.setRowChildrenExpansion(id, isExpanded)
    }
  }

  return (
    <Stack>
      <Stack direction={'row'} alignItems={'center'}>
        <DebouncedTextField
          placeholder={'Search ASIN or product title'}
          sx={{ mb: 4, width: 300 }}
          onChange={value => setSearch(value.trim().toLowerCase())}
          InputProps={{
            endAdornment: <Search />
          }}
        />
      </Stack>
      <DataGrid
        apiRef={apiRef}
        autoHeight
        rows={filteredProductRows}
        columns={PRODUCT_COLUMN_DEFINITIONS.map(d => ({ ...d, sortable: d.sortable || false }))}
        disableColumnMenu={true}
        disableColumnReorder={true}
        pagination
        treeData
        getTreeDataPath={row => row.path}
        groupingColDef={{
          headerName: '',
          width: 50,
          renderCell: ({ id, field, rowNode }: GridRenderCellParams) => {
            if (rowNode.type !== 'group') {
              return null
            }

            return (
              <IconButton
                onClick={event => {
                  expandRow(id, !rowNode.childrenExpanded)
                  apiRef.current.setCellFocus(id, field)
                  event.stopPropagation()
                }}
                size="small"
                color="primary"
              >
                {rowNode.childrenExpanded ? <RemoveCircleRoundedIcon /> : <AddCircleRoundedIcon />}
              </IconButton>
            )
          }
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[10, 25, 50]}
      />
    </Stack>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { BackButton } from '@momentum/components/back-button'
import { PhoneInput } from '@momentum/components/phone-input'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { getRoleOptions, ACCESS_LEVEL_OPTIONS } from '@momentum/utils/selectOptions'
import { Stack } from '@mui/material'
import { Role, AccessLevel } from '@productwindtom/shared-momentum-zeus-types'
import { Form, SelectInput, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { isValidPhoneNumber } from 'libphonenumber-js'
import * as yup from 'yup'
import { Invitation, inviteUser, updateInvitation } from './queries'
import { useMatch } from 'react-router-dom'
import { ROUTES } from '../RouteNames'
import { useFlag } from '@unleash/proxy-client-react'

type InviteFormData = {
  companyId?: string
  agencyId?: string
  firstName: string
  lastName: string
  role?: Role
  phoneNumber?: string
  email: string
  isAllBrandAccess?: boolean
  primaryBrandId?: string
  accessLevel: AccessLevel
  // brandIds: string[]
}

const schema = (isAgency: boolean) =>
  yup.object().shape({
    email: yup
      .string()
      .required('Required')
      .email('Invalid email')
      .transform(value => value?.toLowerCase()?.trim()),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phoneNumber: yup
      .string()
      .transform(value => value?.trim())
      .test('phone', 'Invalid phone number', value => !value || isValidPhoneNumber(value))
      .transform(value => value?.replaceAll(/\s/g, '')),
    role: yup.string().optional().nullable(),
    ...(isAgency
      ? {
          agencyId: yup.string().required('Required')
        }
      : {
          companyId: yup.string().required('Required'),
          isAllBrandAccess: yup.boolean().required('Required'),
          primaryBrandId: yup.string().optional().nullable(),
          brandIds: yup.array().optional().nullable() //.required('Required').min(1, 'Required')
        }),
    accessLevel: yup.string().required('Required')
  })

export const InviteForm = ({
  invitation,
  onCancel,
  onCreated,
  onUpdated
}: {
  invitation?: Invitation
  onCancel: () => void
  onCreated: (invitation: Invitation) => void
  onUpdated: (invitation: Invitation) => void
}) => {
  const { selectedCompany, brands, agencyId } = useUserSessionContext()
  const viewOnlyPermissionsFlag = useFlag('ViewOnlyPermissions')

  const isTeamBase = !!useMatch(ROUTES.TEAM_BASE)
  const isAgency = !!useMatch(ROUTES.AGENCY_TEAM) || isTeamBase

  const handleSubmit = async (data: InviteFormData) => {
    if (invitation) {
      const resp = await updateInvitation({ id: invitation.id, ...data })
      onUpdated(resp)
    } else {
      const resp = await inviteUser(data)
      onCreated(resp)
    }
  }

  return (
    <Form
      defaultValues={{
        ...invitation,
        isAllBrandAccess: true,
        companyId: selectedCompany?.id,
        agencyId,
        ...(isAgency && { role: Role.AGENCY }),
        ...(!viewOnlyPermissionsFlag && { accessLevel: AccessLevel.FULL_ACCESS })
      }}
      onSubmit={handleSubmit}
      resolver={yupResolver(schema(isAgency)) as any}
    >
      <Stack spacing={2}>
        <Stack direction={'row'} spacing={2}>
          <TextInput data-cy={'firstNameInput'} primaryText={'First name'} name={'firstName'} fullWidth />
          <TextInput data-cy={'lastNameInput'} primaryText={'Last name'} name={'lastName'} fullWidth />
        </Stack>
        <TextInput data-cy={'emailInput'} primaryText={'Email'} name={'email'} disabled={!!invitation} />
        <PhoneInput
          data-cy={'phoneInput'}
          name={'phoneNumber'}
          primaryText={'Phone number'}
          defaultCountry={'US'}
          forceCallingCode
          onlyCountries={['US', 'CA', 'GB', 'DE']}
        />
        <SelectInput
          data-cy={'roleInput'}
          disabled={isAgency}
          options={getRoleOptions(isAgency)}
          primaryText={'Role'}
          name={'role'}
          disableClearable
        />
        {/*//  NOTE: For now a user has access to all brands in a company. GK Feb 8, 2024*/}
        {/*<BrandSelect />*/}
        {(!!selectedCompany || isAgency) && (
          <SelectInput
            data-cy={'primaryBrandInput'}
            options={brands.map(b => ({ label: b.name, value: b.id }))}
            primaryText={'Primary brand'}
            name={'primaryBrandId'}
          />
        )}
        {viewOnlyPermissionsFlag && (
          <SelectInput
            data-cy={'accessLevelInput'}
            options={ACCESS_LEVEL_OPTIONS}
            primaryText={'Access level'}
            name={'accessLevel'}
            disableClearable
          />
        )}
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton text={'Cancel'} onClick={onCancel} />
          <SubmitButton data-cy={'inviteSubmit'} variant={'contained'}>
            Submit
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

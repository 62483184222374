// @ts-nocheck
import { ModelTypes, ProductFeedbackStatus } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_PRODUCT_FEEDBACK_HEADPHONES: Array<ModelTypes['ProductFeedbackSummary']> = {
  status: ProductFeedbackStatus.COMPLETED,
  jsonDownloadUrl:
    'https://media.productwind.com/productFeedback/sample-reporting-group/sample-sku-headphones/productFeedback.json',
  createdAt: '2024-03-19T21:40:43.008Z',
  updatedAt: '2024-03-19T21:40:43.008Z',
  skuId: 'sample-sku-headphones'
}

import { ProductSeoSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'

export const SAMPLE_CAMPAIGN_SEO_OUTDOOR_CAMERA_BOOST: ProductSeoSummary = {
  campaignId: SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST.id,
  productSeoSummaryRecords: [
    {
      fromDate: '2024-05-20',
      toDate: '2024-05-26',
      searchVolume: 60912,
      organicPageOneWins: 6,
      sponsoredPageOneWins: 39
    },
    {
      fromDate: '2024-05-27',
      toDate: '2024-06-02',
      searchVolume: 81213,
      organicPageOneWins: 7,
      sponsoredPageOneWins: 19
    },
    {
      fromDate: '2024-06-03',
      toDate: '2024-06-09',
      searchVolume: 75089,
      organicPageOneWins: 2,
      sponsoredPageOneWins: 18
    },
    {
      fromDate: '2024-06-10',
      toDate: '2024-06-16',
      searchVolume: 40148,
      organicPageOneWins: 7,
      sponsoredPageOneWins: 10
    },
    {
      fromDate: '2024-06-17',
      toDate: '2024-06-23',
      searchVolume: 46715,
      organicPageOneWins: 0,
      sponsoredPageOneWins: 0
    },
    {
      fromDate: '2024-06-24',
      toDate: '2024-06-30',
      searchVolume: 79886,
      organicPageOneWins: 1,
      sponsoredPageOneWins: 1
    },
    {
      fromDate: '2024-07-01',
      toDate: '2024-07-07',
      searchVolume: 61985,
      organicPageOneWins: 2,
      sponsoredPageOneWins: 0
    }
  ]
}

import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

type StateStorage = {
  proposals: {
    [key: string]: ModelTypes['Proposal']
  }
  campaigns: {
    [key: string]: ModelTypes['CampaignDetails']
  }
}

export const STORAGE: StateStorage = {
  proposals: {},
  campaigns: {}
}

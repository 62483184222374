import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useFeedbackContext } from '@momentum/routes/campaign/e-commerce/feedback/feedbackContext'
import { SaveAltOutlined } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { DateTime } from 'luxon'
import { Metric } from '../common/Metric'
import { GenerateFeedbackButton } from './GenerateFeedbackButton'

export const Summary = () => {
  const { isAdminView } = useUserSessionContext()
  const feedbackPdfFlag = useFlag('FeedbackPdf')
  const { aiSummary, productFeedbackSummary, generatePdf } = useFeedbackContext()

  const feedbackEntries = aiSummary?.feedback.filter(f => f.sentiment !== 'neutral').length || 0

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} flexGrow={1}>
          <Typography variant={'h4'}>Product feedback summary</Typography>
          {productFeedbackSummary?.status === 'COMPLETED' && (
            <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
              Last updated: {DateTime.fromISO(productFeedbackSummary.updatedAt).toLocaleString(DateTime.DATE_MED)}
            </Typography>
          )}
        </Stack>
        {feedbackPdfFlag && (
          <Button variant={'text'} startIcon={<SaveAltOutlined />} onClick={generatePdf}>
            Download feedback
          </Button>
        )}
        {isAdminView && <GenerateFeedbackButton />}
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        {/*<Metric label={'shared feedback'} value={creatorsLeftFeedback.toLocaleString()} />*/}
        <Metric label={'pieces of feedback'} value={aiSummary ? feedbackEntries.toLocaleString() : '--'} />
      </Stack>
    </Stack>
  )
}

import { Box, Select, Stack, Typography, MenuItem } from '@mui/material'
import { DateInput, Form, SubmitButton } from '@productwindtom/ui-base'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  useReviewsContext,
  ViewType,
  TopLevelViewType
} from '@momentum/routes/campaign/e-commerce/reviews/reviewsContext'
import React from 'react'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

export const schema = yup.object({
  startDate: yup.date().optional(),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), () => `Date needs to be greater than the start date`)
    .optional()
})

export const PeriodSelector = () => {
  const { isAdminView } = useUserSessionContext()
  const {
    minDate,
    maxDate,
    startDateFilter,
    endDateFilter,
    setStartDateFilter,
    setEndDateFilter,
    topLevelViewType,
    viewType,
    setViewType
  } = useReviewsContext()

  const onSubmit = (data: { startDate?: Date; endDate?: Date }) => {
    setStartDateFilter(data.startDate ? DateTime.fromJSDate(data.startDate) : undefined)
    setEndDateFilter(data.endDate ? DateTime.fromJSDate(data.endDate) : undefined)
  }

  return (
    <Form
      defaultValues={{ startDate: startDateFilter?.toJSDate(), endDate: endDateFilter?.toJSDate() }}
      onSubmit={onSubmit}
      resolver={yupResolver(schema)}
    >
      <Stack spacing={4}>
        <Typography variant={'h4'}>Select your period</Typography>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
          <Stack direction={'row'} spacing={3}>
            <DateInput name={'startDate'} primaryText={'Start date'} minDate={minDate} maxDate={maxDate} />
            <DateInput name={'endDate'} primaryText={'End date'} minDate={minDate} maxDate={maxDate} />
            <Box pt={3}>
              <SubmitButton variant={'contained'}>Apply</SubmitButton>
            </Box>
          </Stack>
          {isAdminView && topLevelViewType === TopLevelViewType.MOMENTUM && (
            <Box>
              <Select
                size={'small'}
                value={viewType}
                onChange={e => setViewType(e.target.value as ViewType)}
                sx={{ width: '200px' }}
              >
                <MenuItem value={ViewType.COUNT}>Count</MenuItem>
                <MenuItem value={ViewType.RATING}>Rating</MenuItem>
              </Select>
            </Box>
          )}
        </Stack>
      </Stack>
    </Form>
  )
}

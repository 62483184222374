import { ProposalGoal, Store } from '@productwindtom/shared-momentum-zeus-types'
import { BRANDS } from '../../types'
import { Campaign } from '@momentum/routes/brand/types'
import { SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH } from '@momentum/api/interceptors/getCampaign/sample-data/water-flosser-pd-launch'
import { SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL } from '@momentum/api/interceptors/getCampaign/sample-data/keyboard-reviews-product-launch'
import { SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/getCampaign/sample-data/energy-drink-pd-existing'
import { SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST } from '@momentum/api/interceptors/getCampaign/sample-data/outdoor-camera-boost'
import { SAMPLE_CAMPAIGN_WIRELESS_EARBUDS } from '@momentum/api/interceptors/getCampaign/sample-data/wireless-earbuds'
import { SAMPLE_CAMPAIGN_HEADPHONES } from '@momentum/api/interceptors/getCampaign/sample-data/headphones'
import { SAMPLE_CAMPAIGN_KEYBOARD } from '@momentum/api/interceptors/getCampaign/sample-data/keyboard'
import { SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER } from '@momentum/api/interceptors/getCampaign/sample-data/bluetooth-speaker'

type CampaignRecord<T extends Campaign> = Record<string, Array<T>>

export const SAMPLE_BRAND_CAMPAIGNS: CampaignRecord<any> = {
  [BRANDS.CPG]: [
    {
      id: 'sample-campaign-laundry',
      brandId: BRANDS.CPG,
      title: 'Laundry Detergent Launch',
      createdAt: '2023-07-20T16:44:19.000Z',
      startDate: '2023-08-01T16:44:19.000Z',
      endDate: '2023-09-20T17:28:38.448Z',
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-laundry',
      numCreators: 500,
      numCreatorsJoined: 500,
      cost: 5000,
      product: {
        name: 'Laundry Detergent',
        priceCents: 1498,
        image: 'https://media.productwind.com/sample/images/laundry.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/Arm-Hammer-Laundry-Detergent-Packaging/dp/B0C8RD2XHD',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CE,
        id: 'sample-sku-laundry',
        ratingSnapshots: {
          items: []
        },
        recommendations: [],
        childSkus: { items: [] }
      }
    },
    {
      id: 'sample-campaign-whitening',
      brandId: BRANDS.CPG,
      createdAt: '2024-01-20T21:34:07.000Z',
      startDate: '2024-01-31T21:34:07.000Z',
      endDate: undefined,
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-whitening',
      numCreators: 246,
      numCreatorsJoined: 246,
      cost: 5000,
      title: 'Teeth Whitening Kit Launch',
      product: {
        name: 'Teeth Whitening Kit',
        price: '$64.99',
        priceCents: 6499,
        image: 'https://media.productwind.com/sample/images/whitening.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/Colgate-ComfortFit-Whitening-Enamel-Android/dp/B0B9HVCMBB',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CE,
        id: 'sample-sku-whitening',
        ratingSnapshots: {
          items: []
        },
        recommendations: [],
        childSkus: { items: [] }
      }
    },
    {
      id: 'sample-campaign-marker',
      brandId: BRANDS.CPG,
      createdAt: '2024-02-20T17:36:22.000Z',
      startDate: '2024-02-27T17:36:22.000Z',
      endDate: '2024-03-18T21:52:35.607Z',
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-marker',
      numCreators: 150,
      numCreatorsJoined: 150,
      cost: 3000,
      title: 'Writing Marker Launch',
      product: {
        name: 'Writing Marker',
        price: '$33.48',
        priceCents: 3348,
        image: 'https://media.productwind.com/sample/images/marker.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/SHARPIE-Creative-Markers-Water-Based-Assorted/dp/B0CPMFV2ZF',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CE,
        id: 'sample-sku-marker',
        ratingSnapshots: {
          items: []
        },
        recommendations: [],
        childSkus: { items: [] }
      }
    },
    {
      id: 'sample-campaign-pillow',
      brandId: BRANDS.CPG,
      createdAt: '2024-02-20T17:43:28.000Z',
      startDate: '2024-03-05T17:43:28.000Z',
      endDate: undefined,
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-pillow',
      numCreators: 230,
      numCreatorsJoined: 160,
      cost: 4000,
      title: 'Down Pillow Launch',
      product: {
        name: 'Down pillow',
        price: '$79',
        image: 'https://media.productwind.com/sample/images/pillow.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/dp/B0CJT69H2K?th=1',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CE,
        id: 'sample-sku-pillow',
        ratingSnapshots: {
          items: []
        },
        recommendations: [],
        childSkus: { items: [] }
      }
    },
    SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING
  ],
  [BRANDS.CE]: [
    SAMPLE_CAMPAIGN_KEYBOARD,
    SAMPLE_CAMPAIGN_HEADPHONES,
    SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER,
    SAMPLE_CAMPAIGN_WATER_FLOSSER_PRIME_DAY_LAUNCH,
    SAMPLE_CAMPAIGN_KEYBOARD_REVIEWS_PL,
    SAMPLE_CAMPAIGN_OUTDOOR_CAMERA_BOOST,
    SAMPLE_CAMPAIGN_WIRELESS_EARBUDS
  ]
}

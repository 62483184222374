import { PurchaseStatus, Selector, InputType, GraphQLTypes } from '@productwindtom/shared-ws-zeus-types'
import { DateTime } from 'luxon'

const userCampaignSelector = Selector('UserCampaign')({
  purchases: {
    items: {
      status: true,
      purchasedAt: true
    }
  }
})
type UserCampaign = InputType<GraphQLTypes['UserCampaign'], typeof userCampaignSelector>

export const PURCHASE_COLLISION_THRESHOLD_DAYS = 45
export const isUserCampaignAtRiskOfCollision = (userCampaign: UserCampaign) => {
  const { purchases } = userCampaign
  const authorizedPurchase = (purchases?.items || []).find(purchase => purchase.status === PurchaseStatus.AUTHORIZED)

  if (authorizedPurchase && authorizedPurchase.purchasedAt) {
    const daysSincePurchase = Math.abs(DateTime.fromISO(authorizedPurchase.purchasedAt).diffNow('days').days)

    /* purchases that happened over PURCHASE_COLLISION_THRESHOLD_DAYS days ago do not count to collisions */
    if (daysSincePurchase > PURCHASE_COLLISION_THRESHOLD_DAYS) {
      return false
    }
  }

  return !!authorizedPurchase
}

import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_PROPOSAL_HEADPHONES_SOCIAL } from './headphones-social'
import { SAMPLE_PROPOSAL_HEADPHONES_PRODUCT_LAUNCH } from './headphones-product-launch'
import { SAMPLE_PROPOSAL_HEADPHONES_STAR_RATING } from './headphones-star-rating'
import { BRANDS } from '../../types'
import { SAMPLE_PROPOSAL_KEYBOARD } from './keyboard'
import { SAMPLE_PROPOSAL_LAUNDRY } from './laundry'
import { SAMPLE_PROPOSAL_MARKER } from './marker'

export const proposalsByBrand = {
  [BRANDS.CE]: [
    SAMPLE_PROPOSAL_HEADPHONES_SOCIAL,
    SAMPLE_PROPOSAL_HEADPHONES_PRODUCT_LAUNCH,
    SAMPLE_PROPOSAL_HEADPHONES_STAR_RATING,
    SAMPLE_PROPOSAL_KEYBOARD
  ],
  [BRANDS.CPG]: [SAMPLE_PROPOSAL_LAUNDRY, SAMPLE_PROPOSAL_MARKER]
}

export const SAMPLE_PROPOSAL_METRICS: Record<string, ModelTypes['Proposal']> = Object.values(proposalsByBrand)
  .flat()
  .reduce((acc, item) => {
    return {
      ...acc,
      [item.id]: item
    }
  }, {})

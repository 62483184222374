import {
  CampaignCreatorStatus,
  Channel,
  ContentType,
  CreatorType,
  ModelTypes,
  Store
} from '@productwindtom/shared-momentum-zeus-types'
import { BRANDS } from '../../types'

export const SAMPLE_CAMPAIGN_WHITENING: ModelTypes['CampaignDetails'] = {
  brandId: BRANDS.CE,
  id: 'sample-campaign-whitening',
  skuId: 'sample-sku-whitening',
  title: 'Teeth Whitening Kit',
  numCreators: 246,
  numCreatorsJoined: 246,
  numAdditionalBrandAdvocateCreatorsJoined: 175,
  numAdditionalBrandAdvocateCreators: 175,
  numBrandAdvocateCreators: 1,
  numSocialCreators: 0,
  numPremiumUgcCreators: 0,
  numUgcCreators: 70,
  expectedUgcPostCount: 40,
  releaseSchedule: {
    socialWeeklySchedule: [0, 0, 0, 0, 0],
    premiumUgcWeeklySchedule: [0, 0, 0, 0],
    ugcWeeklySchedule: [30, 119, 112, 264, 4],
    brandAdvocatesWeeklySchedule: [0, 0, 0, 0, 1]
  },
  createdAt: '2024-01-20T21:34:07.000Z',
  anticipatedStartDate: '2024-01-31T21:34:07.000Z',
  startDate: '2024-01-31T21:34:07.000Z',
  cost: 1475784,
  product: {
    id: 'sample-sku-whitening',
    name: 'Teeth Whitening Kit',
    skuId: 'B0B9HVCMBB',
    price: '$64.99',
    priceCents: 6499,
    image: 'https://media.productwind.com/sample/images/whitening.png',
    listingLink: 'https://www.amazon.com/Colgate-ComfortFit-Whitening-Enamel-Android/dp/B0B9HVCMBB',
    store: Store.amazon,
    ratingSnapshots: {
      items: []
    },
    brandId: BRANDS.CE,
    recommendations: [],
    createdAt: '2024-01-13T16:22:12.000Z',
    updatedAt: '2024-01-13T16:22:12.000Z',
    childSkus: { items: [] }
  },
  creators: [
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T15:46:16.000Z',
        amountCents: 4240
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd74f5de2-e670-417b-acd0-83914cf8a753',
        firstName: 'Kaitlyn',
        lastName: 'Siegle',
        email: 'kaitlynrsiegle@gmail.com',
        instagramProfile: {
          handle: 'kateekovacs',
          followers: 9295,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'simplystellarcrafts',
          followers: 129943,
          engagementRate: 5.03221869953246,
          isPrimary: false
        }
      },
      userCampaignId: 'd74f5de2-e670-417b-acd0-83914cf8a753'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T19:55:35.000Z',
        amountCents: 5433
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-03T05:33:08.282Z',
          createdAt: '2024-02-11T23:35:07.893Z',
          content: [
            {
              id: 'a9b27f2c-2d67-48c0-a9df-64fadc0f49a3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9500cd2b-187c-449a-9c55-e975e7bfd4d0/ugc/65a29206-6a46-4729-909f-0502b2cad142/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '65a29206-6a46-4729-909f-0502b2cad142'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-15T20:25:20.624Z',
          createdAt: '2024-02-11T07:23:44.752Z',
          content: [
            {
              id: 'bf3397db-5422-46d0-bdd9-a44277a50c63',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9500cd2b-187c-449a-9c55-e975e7bfd4d0/ugc/f008f1ed-3781-487d-87e2-102519236e45/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b545bb53-a20a-42b3-82d7-5007a7df15ef',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9500cd2b-187c-449a-9c55-e975e7bfd4d0/ugc/f008f1ed-3781-487d-87e2-102519236e45/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '571fae04-9482-40eb-bf24-6a5cb680674b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9500cd2b-187c-449a-9c55-e975e7bfd4d0/ugc/f008f1ed-3781-487d-87e2-102519236e45/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f008f1ed-3781-487d-87e2-102519236e45'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '9500cd2b-187c-449a-9c55-e975e7bfd4d0',
        firstName: 'Tia',
        lastName: 'Bat',
        email: 'tia55tir@gmail.com',
        instagramProfile: {
          handle: 't.u.n.g.a.a',
          followers: 3093,
          engagementRate: 0.6401551891367605,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'modern.nova',
          followers: 107,
          engagementRate: 3.994161596277738,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'modernnova7997',
          followers: 2000
        }
      },
      userCampaignId: '9500cd2b-187c-449a-9c55-e975e7bfd4d0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T15:39:58.000Z',
        amountCents: 4280
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '36cfc7ab-f291-4b80-9d3b-a9cc1edbceae',
        firstName: 'Del',
        lastName: 'Ro',
        email: 'delro@doubleamgmt.com',
        instagramProfile: {
          handle: 'delrodaily',
          followers: 29115,
          engagementRate: 1.1300017173278378,
          isPrimary: true
        }
      },
      userCampaignId: '36cfc7ab-f291-4b80-9d3b-a9cc1edbceae'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T15:57:20.000Z',
        amountCents: 4280
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd1ca52fb-5138-4c76-98be-9976b64c6d1d',
        firstName: 'Moises',
        lastName: 'Romero',
        email: 'romero.moises10@yahoo.com',
        instagramProfile: {
          handle: 'moy_cess',
          followers: 3547,
          engagementRate: 2.091908655201579,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'moy_cess',
          followers: 163,
          engagementRate: 4.250559284116331,
          isPrimary: true
        }
      },
      userCampaignId: 'd1ca52fb-5138-4c76-98be-9976b64c6d1d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T16:02:08.000Z',
        amountCents: 4280
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '4bb5d055-70da-4d24-b40a-52536bebb6ca',
        firstName: 'Grace',
        lastName: 'Jaye',
        email: 'info@gracejaye.com',
        instagramProfile: {
          handle: 'gracejaye_',
          followers: 7810,
          engagementRate: 93.82202304737515,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'gracejaye_',
          followers: 4081,
          engagementRate: 12.697579747479567,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'gracejaye',
          followers: 819
        }
      },
      userCampaignId: '4bb5d055-70da-4d24-b40a-52536bebb6ca'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T12:34:08.000Z',
        amountCents: 5288
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f23679d2-9766-486e-8231-6f12c36538ee',
        firstName: 'Purvasha',
        lastName: 'Sahoo',
        email: 'purvasha1992@gmail.com',
        instagramProfile: {
          handle: 'foodfashionandchill',
          followers: 24758,
          engagementRate: 2.6056224250747233,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'foodfashionandchill',
          followers: 103,
          engagementRate: 7.080200501253133,
          isPrimary: false
        }
      },
      userCampaignId: 'f23679d2-9766-486e-8231-6f12c36538ee'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-12T22:05:25.000Z',
        amountCents: 3180
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5995cca1-1f01-4f94-944b-06bf0623f6ca',
        firstName: 'Kadin',
        lastName: 'Nikole',
        email: 'corncobgirl16@gmail.com',
        instagramProfile: {
          handle: 'kadinnx',
          followers: 6497,
          engagementRate: 2.7012467292596583,
          isPrimary: false
        }
      },
      userCampaignId: '5995cca1-1f01-4f94-944b-06bf0623f6ca'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-05T19:32:06.000Z',
        amountCents: 5178
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fbaed770-4dbb-414a-bdfb-b83a4d155bfd',
        firstName: 'Kate',
        lastName: 'Budzinski',
        email: 'katebudzinskiblog@gmail.com',
        instagramProfile: {
          handle: 'kateharley',
          followers: 18312,
          engagementRate: 1.956094364351245,
          isPrimary: false
        },
        tiktokProfile: {
          handle: '_kateharley',
          followers: 4224,
          engagementRate: 2.9268949498859707,
          isPrimary: true
        }
      },
      userCampaignId: 'fbaed770-4dbb-414a-bdfb-b83a4d155bfd'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-12T16:55:26.000Z',
        amountCents: 3195
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '65135402-18a7-4f83-b78b-55aa323d7820',
        firstName: 'Lindsey',
        lastName: 'Hawkins',
        email: 'l.hawkins016@gmail.com',
        instagramProfile: {
          handle: 'lindsey_hawkins18',
          followers: 2297,
          engagementRate: 2.0200261210274273,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'lindsey_hawkins18',
          followers: 11002,
          engagementRate: 6.2641789428766135,
          isPrimary: true
        }
      },
      userCampaignId: '65135402-18a7-4f83-b78b-55aa323d7820'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T17:16:15.000Z',
        amountCents: 4319
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f5b3b7b8-b7b4-4924-8efe-132ac1a210b3',
        firstName: 'Wendy',
        lastName: 'Lopez',
        email: 'bizxonayeliw@gmail.com',
        tiktokProfile: {
          handle: 'xonayeliw',
          followers: 10672,
          engagementRate: 15.12742400183933,
          isPrimary: false
        }
      },
      userCampaignId: 'f5b3b7b8-b7b4-4924-8efe-132ac1a210b3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T06:25:21.000Z',
        amountCents: 4345
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '6aaedef6-9100-47d6-9e38-38f68de999c7',
        firstName: 'Braxton',
        lastName: 'Poe',
        email: 'braxtonpoe6@gmail.com',
        instagramProfile: {
          handle: 'showboat_6',
          followers: 17226,
          engagementRate: 0.6651075616592858,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'showboat6',
          followers: 1808,
          engagementRate: 8.176021087384845,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'burgersbratspod'
        }
      },
      userCampaignId: '6aaedef6-9100-47d6-9e38-38f68de999c7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T12:33:12.000Z',
        amountCents: 3799
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e145a40d-6b66-40de-b7f8-de308dc88abf',
        firstName: 'Carisia',
        lastName: 'Gallegos',
        email: 'prettycarisia@gmail.com',
        instagramProfile: {
          handle: 'chaicari',
          followers: 2370,
          engagementRate: 4.736286919831223,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'haveyoumetcari',
          followers: 22015,
          engagementRate: 18.915953113309502,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'cari8124',
          followers: 9
        }
      },
      userCampaignId: 'e145a40d-6b66-40de-b7f8-de308dc88abf'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T17:15:10.000Z',
        amountCents: 5327
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fee5d792-50a4-4026-bef0-6ed6753d4c2b',
        firstName: 'Alexa',
        lastName: 'Denike',
        email: 'alexa.lovesamazon1@gmail.com',
        instagramProfile: {
          handle: 'alexa.lovesshopping',
          followers: 7025,
          engagementRate: 0.8298932384341637,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'alexa.lovesshopping',
          followers: 2890,
          engagementRate: 8.314495546643053,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'alexa.lovesshopping'
        }
      },
      userCampaignId: 'fee5d792-50a4-4026-bef0-6ed6753d4c2b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T04:34:09.000Z',
        amountCents: 5439
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e4770e50-0fdf-468d-a555-404da0d03ed7',
        firstName: 'Jennifer',
        lastName: 'Luu',
        email: 'ibubblii@gmail.com',
        instagramProfile: {
          handle: 'iibubblii',
          followers: 5714,
          engagementRate: 1.3895694784739236,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ibubblii',
          followers: 564,
          engagementRate: 0.9900990099009901,
          isPrimary: true
        }
      },
      userCampaignId: 'e4770e50-0fdf-468d-a555-404da0d03ed7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T16:42:17.000Z',
        amountCents: 4300
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'b1e7530d-9d0e-4838-ad2c-350c67d97cd2',
        firstName: 'Laura',
        lastName: 'Solares',
        email: '610laura@gmail.com',
        instagramProfile: {
          handle: 'lifeoflaura06',
          followers: 78104,
          engagementRate: 0.18385742087473111,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'lifeoflaura06',
          followers: 3881,
          engagementRate: 10.443880786303108,
          isPrimary: true
        }
      },
      userCampaignId: 'b1e7530d-9d0e-4838-ad2c-350c67d97cd2'
    },
    {
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0d313072-f62a-44f0-97a8-3c8bef9fb0e8',
        firstName: 'Sahanie ',
        lastName: 'Morris',
        email: 'contact.haniemo@gmail.com',
        instagramProfile: {
          handle: 'sahanie',
          followers: 12236,
          engagementRate: 1.4089571755475645,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'sahaniemo',
          followers: 41,
          engagementRate: 3.0145530145530146,
          isPrimary: false
        }
      },
      userCampaignId: '0d313072-f62a-44f0-97a8-3c8bef9fb0e8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-02T09:05:10.000Z',
        amountCents: 5740
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-15T15:12:33.500Z',
          createdAt: '2024-03-15T15:12:33.760Z',
          content: [
            {
              id: 'c916868a-6ef4-4b51-abbb-e3183e2e9366',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a12d48cf-ac1f-4153-97f3-1b2cadb34d9e/ugc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'df74f035-a72a-4611-949b-244b1dc2c2a6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a12d48cf-ac1f-4153-97f3-1b2cadb34d9e/ugc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6e912948-261c-4bf9-a3fb-a82ef6d3db51',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a12d48cf-ac1f-4153-97f3-1b2cadb34d9e/ugc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b32106bd-d3e9-45ac-af2d-39dd5588e13b'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-03-14T15:30:48.976Z',
          createdAt: '2024-03-07T02:18:52.075Z',
          content: [
            {
              id: 'aee9af88-cb65-41a4-814c-e55545e7176a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a12d48cf-ac1f-4153-97f3-1b2cadb34d9e/ugc/90ed66a0-17eb-4d3a-b975-abe913b72aa6/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '90ed66a0-17eb-4d3a-b975-abe913b72aa6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a12d48cf-ac1f-4153-97f3-1b2cadb34d9e',
        firstName: 'Alexus',
        lastName: 'Hilliard',
        email: 'alexushilliard17@gmail.com',
        instagramProfile: {
          handle: 'thatalexusgirl',
          followers: 4989,
          engagementRate: 1.2968530767688917,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thatalexusgirl',
          followers: 732,
          isPrimary: true
        }
      },
      userCampaignId: 'a12d48cf-ac1f-4153-97f3-1b2cadb34d9e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-12T23:05:05.000Z',
        amountCents: 3195
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '9875efb7-f29b-49a7-90e6-cb4514a6e865',
        firstName: 'Kristina',
        lastName: 'DeRobertis',
        email: '491westmainst@gmail.com',
        instagramProfile: {
          handle: '491westmainst',
          followers: 14617,
          engagementRate: 0.9789970582198808,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kristinaderoberti',
          followers: 44,
          isPrimary: false
        }
      },
      userCampaignId: '9875efb7-f29b-49a7-90e6-cb4514a6e865'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T07:04:07.000Z',
        amountCents: 5321
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'cd26798f-5ee0-4c62-bbf7-3c3e87d82296',
        firstName: 'Peyton',
        lastName: 'Allen',
        email: 'peytoneverafter@gmail.com',
        instagramProfile: {
          handle: 'peytoneverafter',
          followers: 14529,
          engagementRate: 3.0421914791107443,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'peytoneverafter',
          followers: 1399,
          engagementRate: 7.889529624604252,
          isPrimary: true
        }
      },
      userCampaignId: 'cd26798f-5ee0-4c62-bbf7-3c3e87d82296'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T18:35:31.000Z',
        amountCents: 5219
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fceee2f7-d87b-4383-a46d-137afb352320',
        firstName: 'Amanda',
        lastName: 'Solomon',
        email: 'hello@mandiandthecity.com',
        instagramProfile: {
          handle: 'mandiandthecity',
          followers: 3383,
          engagementRate: 2.5362104640851313,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mandiandthecity',
          followers: 333,
          engagementRate: 10.833333333333334,
          isPrimary: false
        }
      },
      userCampaignId: 'fceee2f7-d87b-4383-a46d-137afb352320'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-05T23:35:11.000Z',
        amountCents: 7004
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fb0cdd0b-fc21-4a04-b2b0-b8347cbeb5dc',
        firstName: 'cristina ',
        lastName: 'sisson',
        email: 'cristina_moralesw@yahoo.com',
        instagramProfile: {
          handle: 'cristina.sisson',
          followers: 13308,
          engagementRate: 1.178238653441539,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'cristinasiss',
          followers: 10400,
          engagementRate: 12.725566413077857,
          isPrimary: true
        }
      },
      userCampaignId: 'fb0cdd0b-fc21-4a04-b2b0-b8347cbeb5dc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-10T20:55:13.000Z',
        amountCents: 3225
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '2b946c31-66c1-42fd-93c1-1a11d63ef5af',
        firstName: 'Alex',
        lastName: 'Teague',
        email: 'aeteague02@gmail.com',
        instagramProfile: {
          handle: 'alexx_teague',
          followers: 6954,
          engagementRate: 5.130859936727063,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'alexteague2',
          followers: 1089,
          engagementRate: 10.853658536585366,
          isPrimary: true
        }
      },
      userCampaignId: '2b946c31-66c1-42fd-93c1-1a11d63ef5af'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T23:31:26.000Z',
        amountCents: 5243
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0913ca7e-92ca-43da-989d-931bf1db4570',
        firstName: 'Jessica',
        lastName: 'Guerra',
        email: 'ugc.jessicaguerra@gmail.com',
        instagramProfile: {
          handle: 'alebasi_g7',
          followers: 10509,
          engagementRate: 0.439623180131316,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'alebasig',
          followers: 1881,
          engagementRate: 3.0733206838179212,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'jessg7',
          followers: 9
        }
      },
      userCampaignId: '0913ca7e-92ca-43da-989d-931bf1db4570'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-01T17:42:52.000Z',
        amountCents: 5250
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'be646678-f465-43f7-a615-e0b2f2073e14',
        firstName: 'Jorie-Anne',
        lastName: 'Morris',
        email: 'jorieanne.m@gmail.com',
        instagramProfile: {
          handle: 'jorieanne',
          followers: 23057,
          engagementRate: 1.1376154746931517,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'jorie.anne',
          followers: 1209,
          engagementRate: 3.9942938659058487,
          isPrimary: false
        }
      },
      userCampaignId: 'be646678-f465-43f7-a615-e0b2f2073e14'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T18:42:42.000Z',
        amountCents: 4240
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3dc08609-3539-483e-ac61-42ed81da0208',
        firstName: 'Evan S.',
        lastName: 'Brown',
        email: 'info@itscoachev.com',
        instagramProfile: {
          handle: 'itscoachev',
          followers: 15830,
          engagementRate: 1.0505369551484522,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'itscoachev',
          followers: 4963,
          engagementRate: 1.0373850068506556,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'itscoachev'
        }
      },
      userCampaignId: '3dc08609-3539-483e-ac61-42ed81da0208'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-05T17:26:38.000Z',
        amountCents: 5926
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-03T18:05:49.945Z',
          createdAt: '2024-02-13T16:39:29.246Z',
          content: [
            {
              id: '8492108d-4d6f-476e-80f3-b4055677e979',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/040f733d-4c1c-4bdf-bb9d-80145a7bb2f4/ugc/578e63fe-61e9-4e78-b27f-7a9234c01af2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '773595c8-c101-42e1-84d0-37af33df156c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/040f733d-4c1c-4bdf-bb9d-80145a7bb2f4/ugc/578e63fe-61e9-4e78-b27f-7a9234c01af2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '69a52749-c782-4948-91aa-3c09af71cb87',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/040f733d-4c1c-4bdf-bb9d-80145a7bb2f4/ugc/578e63fe-61e9-4e78-b27f-7a9234c01af2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '578e63fe-61e9-4e78-b27f-7a9234c01af2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '040f733d-4c1c-4bdf-bb9d-80145a7bb2f4',
        firstName: 'Alex',
        lastName: 'Hill',
        email: 'alexvaughn1@gmail.com',
        instagramProfile: {
          handle: 'alexreneehill',
          followers: 13739,
          engagementRate: 0.8414003930417061,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'alexreneehill',
          followers: 9833,
          isPrimary: false
        }
      },
      userCampaignId: '040f733d-4c1c-4bdf-bb9d-80145a7bb2f4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T15:45:27.000Z',
        amountCents: 4240
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ad2bc099-9cc8-445d-9588-32521e4de0c4',
        firstName: 'Ivy',
        lastName: 'McGlaughlin',
        email: 'ivymcgl@gmail.com',
        instagramProfile: {
          handle: 'ivymoon14',
          followers: 1714,
          engagementRate: 4.807467911318553,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'ivymoon14',
          followers: 13021,
          engagementRate: 9.947959830351484,
          isPrimary: true
        }
      },
      userCampaignId: 'ad2bc099-9cc8-445d-9588-32521e4de0c4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-13T20:55:26.000Z',
        amountCents: 3180
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd20100f3-f35c-4af6-9eab-a0ac913b947c',
        firstName: 'Crystal',
        lastName: 'White',
        email: 'crystal89white@gmail.com',
        instagramProfile: {
          handle: 'iamcrystalwhite',
          followers: 17763,
          engagementRate: 1.8915723695321736,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'crystalxwhite',
          followers: 70662,
          engagementRate: 12.135372415746248,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'crystalxwhite',
          followers: 1020
        }
      },
      userCampaignId: 'd20100f3-f35c-4af6-9eab-a0ac913b947c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-03T09:32:50.000Z',
        amountCents: 5304
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-02T17:19:31.143Z',
          createdAt: '2024-03-01T17:55:02.963Z',
          content: [
            {
              id: '90daaaad-c56c-4275-83d0-4bed9dc7650d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cf1fdc55-eec9-4898-b338-edd40fc5b311/ugc/cf117a93-4e0e-4254-a6cf-2a6107bb364e/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'cf117a93-4e0e-4254-a6cf-2a6107bb364e'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-02T17:19:39.878Z',
          createdAt: '2024-03-03T05:23:20.606Z',
          content: [
            {
              id: 'bbc9146d-5134-4c85-af96-daf10f8b71a5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cf1fdc55-eec9-4898-b338-edd40fc5b311/ugc/302f4e72-a0e4-4def-ab8f-e614dba04dd3/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e4c6d3db-b894-4ccf-b896-b095f54f7cdb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cf1fdc55-eec9-4898-b338-edd40fc5b311/ugc/302f4e72-a0e4-4def-ab8f-e614dba04dd3/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '998011f9-59e5-4f20-a500-23ee7b33f3cf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cf1fdc55-eec9-4898-b338-edd40fc5b311/ugc/302f4e72-a0e4-4def-ab8f-e614dba04dd3/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '302f4e72-a0e4-4def-ab8f-e614dba04dd3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'cf1fdc55-eec9-4898-b338-edd40fc5b311',
        firstName: 'Vicki',
        lastName: 'Rodriguez',
        email: 'vickirod1987@gmail.com',
        instagramProfile: {
          handle: 'vickirodriguez87',
          followers: 77111,
          engagementRate: 4.625021073517397,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thenevereverhappyfamily',
          followers: 5468,
          engagementRate: 2.7661357921207044,
          isPrimary: false
        }
      },
      userCampaignId: 'cf1fdc55-eec9-4898-b338-edd40fc5b311'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-05T19:34:13.000Z',
        amountCents: 5209
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c8c88bc9-e707-43c4-98d3-942e1ffc0ff0',
        firstName: 'Karina',
        lastName: 'Gomez',
        email: 'karinabusinesstok@gmail.com',
        tiktokProfile: {
          handle: 'kari_lifestylee',
          followers: 15541,
          engagementRate: 17.162272798775867,
          isPrimary: false
        }
      },
      userCampaignId: 'c8c88bc9-e707-43c4-98d3-942e1ffc0ff0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-14T02:15:10.000Z',
        amountCents: 3240
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a6126bf9-918e-487d-92ea-55e0b36357ac',
        firstName: 'Katherin',
        lastName: 'Marquez',
        email: 'katherinmarquezr@gmail.com',
        instagramProfile: {
          handle: 'katherinmarquezr',
          followers: 5737,
          engagementRate: 6.2384521526930445,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'katherinmarquezr',
          followers: 807,
          engagementRate: 10.766625066856838,
          isPrimary: false
        }
      },
      userCampaignId: 'a6126bf9-918e-487d-92ea-55e0b36357ac'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-15T15:05:24.000Z',
        amountCents: 4529
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a162b9e2-d259-41ed-8fc1-769c0ff83e07',
        firstName: 'Miranda ',
        lastName: 'Checkwicz',
        email: 'themickeymousetraveler@yahoo.com',
        instagramProfile: {
          handle: 'the.magical.life.of.miranda',
          followers: 10280,
          engagementRate: 2.401750972762646,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'themagicallifeofmiranda',
          followers: 3495,
          engagementRate: 6.876583423814695,
          isPrimary: true
        }
      },
      userCampaignId: 'a162b9e2-d259-41ed-8fc1-769c0ff83e07'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T01:52:56.000Z',
        amountCents: 5366
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'cc237eb7-4618-4c0c-bc29-303c5b99cc69',
        firstName: 'Madison',
        lastName: 'Allshouse',
        email: 'madison@houseofprieto.com',
        instagramProfile: {
          handle: 'madisonallshouse',
          followers: 2876,
          engagementRate: 2.447844228094576,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'madisonallshouse',
          followers: 65768,
          engagementRate: 13.956234742969423,
          isPrimary: false
        }
      },
      userCampaignId: 'cc237eb7-4618-4c0c-bc29-303c5b99cc69'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-13T20:55:12.000Z',
        amountCents: 3240
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-05T14:57:14.242Z',
          createdAt: '2024-02-25T00:16:50.970Z',
          content: [
            {
              id: '0ca7087d-4e25-4189-a110-a070c3bf3eb5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/09840d27-284d-4bb5-910c-0f7890a49e1a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '09840d27-284d-4bb5-910c-0f7890a49e1a'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-12T15:00:32.446Z',
          createdAt: '2024-03-12T15:00:32.809Z',
          content: [
            {
              id: '34b4f147-5224-4b0a-bd57-980e9cb1d8ce',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/9e7b0e34-640d-4cb7-8905-0479b73955ce/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '48fea161-3a1b-499a-a0ea-d53da05d3b36',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/9e7b0e34-640d-4cb7-8905-0479b73955ce/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9e7b0e34-640d-4cb7-8905-0479b73955ce'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-12T15:00:43.674Z',
          createdAt: '2024-02-25T00:20:41.191Z',
          content: [
            {
              id: 'ca88940a-e846-41ab-bbd2-833160c9b76c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/ac24e5e8-9f95-440c-8421-0143a33fd580/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '941f0f36-3a5a-4744-a55a-f5fb31970067',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/ac24e5e8-9f95-440c-8421-0143a33fd580/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4a004f69-4ce9-47ee-a74d-8fa94081c558',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/ac24e5e8-9f95-440c-8421-0143a33fd580/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ac24e5e8-9f95-440c-8421-0143a33fd580'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T18:18:49.389Z',
          createdAt: '2024-03-21T20:50:12.080Z',
          content: [
            {
              id: '87174a04-e7d8-43ca-a01a-3fe069236a93',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/119b8524-d7d0-4108-a018-e5d6b75661ac/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '119b8524-d7d0-4108-a018-e5d6b75661ac'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-12T15:00:38.743Z',
          createdAt: '2024-03-12T15:00:38.963Z',
          content: [
            {
              id: '8ec9df91-8b6e-47e4-8508-5eda788501d0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/12e704dd-1b89-403c-b67d-327bf1c265a0/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5a0d9bd0-24f6-4e5e-b314-91af88a7016a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/12e704dd-1b89-403c-b67d-327bf1c265a0/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '12e704dd-1b89-403c-b67d-327bf1c265a0'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-12T15:00:40.911Z',
          createdAt: '2024-03-12T15:00:41.126Z',
          content: [
            {
              id: 'eaf45be9-a562-4e71-9b70-668d05dabbe2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/9332b505-9bf2-4f37-a606-5d4109304b18/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'bd8ea83d-f992-4058-9392-c20942a0d674',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/9332b505-9bf2-4f37-a606-5d4109304b18/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9332b505-9bf2-4f37-a606-5d4109304b18'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-12T15:00:35.220Z',
          createdAt: '2024-03-12T15:00:35.526Z',
          content: [
            {
              id: '4836702c-701e-4426-ad96-d54cbba27cc7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/983e0b91-d0ac-4f39-9814-570d8e858731/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '09934fa7-2968-4719-8ad4-6fe5381590c2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/43497612-65d7-424d-99c9-8bb299a24695/ugc/983e0b91-d0ac-4f39-9814-570d8e858731/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '983e0b91-d0ac-4f39-9814-570d8e858731'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '43497612-65d7-424d-99c9-8bb299a24695',
        firstName: 'Samantha',
        lastName: 'Sanderson',
        email: 'samjonescontent@gmail.com',
        instagramProfile: {
          handle: 'samantha_joness_',
          followers: 3531,
          engagementRate: 1.2177853299348627,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'sam_joness_',
          followers: 106786,
          engagementRate: 1.413477934794176,
          isPrimary: false
        }
      },
      userCampaignId: '43497612-65d7-424d-99c9-8bb299a24695'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-13T03:42:50.000Z',
        amountCents: 3240
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:03:48.172Z',
          createdAt: '2024-03-14T03:03:48.618Z',
          content: [
            {
              id: 'ad8ff0c9-a108-422a-895d-e5e8b50c611f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/56388158-0d6f-43ad-8d3b-d89025f00401/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1a5e45b3-5c95-402f-8b22-c76fa52a2c01',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/56388158-0d6f-43ad-8d3b-d89025f00401/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '56388158-0d6f-43ad-8d3b-d89025f00401'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:03:57.267Z',
          createdAt: '2024-03-14T03:03:57.645Z',
          content: [
            {
              id: '7b24e479-d596-4147-9726-a9f850a879a9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/35503a09-dff7-4792-8afd-727f02dbb822/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '90e250d0-c9a1-440f-afba-d83748320291',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/35503a09-dff7-4792-8afd-727f02dbb822/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '35503a09-dff7-4792-8afd-727f02dbb822'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:03:40.185Z',
          createdAt: '2024-03-14T03:03:40.521Z',
          content: [
            {
              id: 'fc6a9c5c-ae93-4a78-a6e4-598f90ac8091',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/50c0d6a7-e8ef-41d6-96df-bbafbcaf4757/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4c8417cb-f23d-46e0-843c-f636edf2a8de',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/50c0d6a7-e8ef-41d6-96df-bbafbcaf4757/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '50c0d6a7-e8ef-41d6-96df-bbafbcaf4757'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:04:00.482Z',
          createdAt: '2024-02-22T18:54:43.234Z',
          content: [
            {
              id: '236887db-533c-4212-b4be-c63901a86862',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/aff51929-44af-47a8-8d5c-0052a569a5d9/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '92d9a9f3-3e78-445c-b127-6a08a0776b2c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/aff51929-44af-47a8-8d5c-0052a569a5d9/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'cbf863b6-ebbc-4dc1-bcd2-6b92335bae8a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/aff51929-44af-47a8-8d5c-0052a569a5d9/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'aff51929-44af-47a8-8d5c-0052a569a5d9'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:03:54.693Z',
          createdAt: '2024-03-14T03:03:55.021Z',
          content: [
            {
              id: '40549e19-bc61-4391-9edc-6dea8251cfe1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/6e4a3125-17f6-4513-89f2-b2a1cb432fe4/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '70de77e6-2b11-4cf9-bea4-c1b283278f09',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/6e4a3125-17f6-4513-89f2-b2a1cb432fe4/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6e4a3125-17f6-4513-89f2-b2a1cb432fe4'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:03:34.784Z',
          createdAt: '2024-03-14T03:03:35.320Z',
          content: [
            {
              id: '7a7c6820-76aa-4281-b163-b0c6ba9abdd9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/e76a7640-d274-42d1-b38c-01f24afb1314/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3ce3f07a-855d-4612-967c-96756cc0e413',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/e76a7640-d274-42d1-b38c-01f24afb1314/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e76a7640-d274-42d1-b38c-01f24afb1314'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:03:51.542Z',
          createdAt: '2024-03-14T03:03:52.039Z',
          content: [
            {
              id: '380de6f2-c41e-4fb9-afea-857274d08577',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/fa62faf1-4976-460c-96f3-3a0a2afbc53c/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9127048e-407d-457d-beef-14774d578a13',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/fa62faf1-4976-460c-96f3-3a0a2afbc53c/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'fa62faf1-4976-460c-96f3-3a0a2afbc53c'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:03:37.534Z',
          createdAt: '2024-03-14T03:03:37.904Z',
          content: [
            {
              id: '8d838c75-e693-43b8-8e48-6dfc1e3b804b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/ba6be921-f6ef-4ed9-a492-f3a157ac55fb/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7be9991d-3047-4e70-93ce-1cc2d82b40fa',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/ba6be921-f6ef-4ed9-a492-f3a157ac55fb/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ba6be921-f6ef-4ed9-a492-f3a157ac55fb'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:03:20.251Z',
          createdAt: '2024-02-22T19:14:30.200Z',
          content: [
            {
              id: 'e6b281c2-34a0-4d16-9ac6-1c011ab18342',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/58768ea1-4905-4e62-bc1f-8810d68c37ee/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '58768ea1-4905-4e62-bc1f-8810d68c37ee'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T03:03:44.058Z',
          createdAt: '2024-03-14T03:03:44.383Z',
          content: [
            {
              id: '2ab086f9-b4d8-447b-949b-e6509b58bc6e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/5fc01b51-f91f-47b9-b3a5-d30fbe325bcf/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c69e9460-80c6-471d-b99c-66cb4b52b541',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/78703146-f865-4878-9ece-07e8494ec56d/ugc/5fc01b51-f91f-47b9-b3a5-d30fbe325bcf/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5fc01b51-f91f-47b9-b3a5-d30fbe325bcf'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '78703146-f865-4878-9ece-07e8494ec56d',
        firstName: 'McKenzie',
        lastName: 'Hedrick',
        email: 'kenzerella23@gmail.com',
        instagramProfile: {
          handle: '__kenzerella',
          followers: 6701,
          engagementRate: 1.4117295925981197,
          isPrimary: false
        },
        tiktokProfile: {
          handle: '__kenzerella',
          followers: 24853,
          engagementRate: 6.673663415236448,
          isPrimary: true
        }
      },
      userCampaignId: '78703146-f865-4878-9ece-07e8494ec56d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T00:37:54.000Z',
        amountCents: 4280
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ae159ee6-f252-488b-b435-a7390965fcf6',
        firstName: 'Meriam ',
        lastName: 'Gonzalez ',
        email: 'meriamrouchdi@gmail.com',
        instagramProfile: {
          handle: 'meriamgonzalez',
          followers: 98026,
          engagementRate: 6.8343092648889066,
          isPrimary: false
        }
      },
      userCampaignId: 'ae159ee6-f252-488b-b435-a7390965fcf6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-13T20:15:11.000Z',
        amountCents: 3210
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e175c35e-6fba-4ba3-a5ec-abe5301d80c1',
        firstName: 'Ashley',
        lastName: 'Lamura',
        email: 'thebestdisneydays@gmail.com',
        instagramProfile: {
          handle: 'magicallifeofashley',
          followers: 12482,
          engagementRate: 1.7617369011376383,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'magicallifeofashley',
          followers: 1825,
          engagementRate: 2.127659574468085,
          isPrimary: true
        }
      },
      userCampaignId: 'e175c35e-6fba-4ba3-a5ec-abe5301d80c1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-05T16:39:21.000Z',
        amountCents: 7000
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '77a1d4ad-c6d2-4654-b355-a7cead23948e',
        firstName: 'Milena',
        lastName: 'Aleksic',
        email: 'hello@aleksictwins.com',
        instagramProfile: {
          handle: 'aleksic_twins',
          followers: 136702,
          engagementRate: 1.7473043554593202,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'aleksic_twins',
          followers: 25630,
          engagementRate: 5.533596837944664,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'aleksic_twins'
        }
      },
      userCampaignId: '77a1d4ad-c6d2-4654-b355-a7cead23948e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-05T17:12:51.000Z',
        amountCents: 5239
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '530a32e8-ab46-4303-b102-027c7607057a',
        firstName: 'Isabelle',
        lastName: 'Foster',
        email: 'isajacobo01@gmail.com',
        instagramProfile: {
          handle: 'mrs.izzyfoster',
          followers: 1552,
          engagementRate: 5.296391752577319,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'mrs.izzyfoster',
          followers: 20476,
          engagementRate: 4.4819505771167405,
          isPrimary: true
        }
      },
      userCampaignId: '530a32e8-ab46-4303-b102-027c7607057a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T01:50:18.000Z',
        amountCents: 3655
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '606dd9ea-0d34-47bb-adf4-7cf72c64b0a2',
        firstName: 'Aubree',
        lastName: 'Matthews',
        email: 'aubreejeanmatthews@gmail.com',
        instagramProfile: {
          handle: 'aubreejeanxo',
          followers: 19857,
          engagementRate: 0.7896459686760338,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'aubreejeanxo',
          followers: 2494,
          engagementRate: 9.215017064846416,
          isPrimary: false
        }
      },
      userCampaignId: '606dd9ea-0d34-47bb-adf4-7cf72c64b0a2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-03-04T11:55:29.000Z',
        amountCents: 5219
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-14T02:03:25.673Z',
          createdAt: '2024-03-14T02:03:26.535Z',
          content: [
            {
              id: 'fa4e89db-9cb0-40c4-8b7d-c5a05ba8b1b3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d6196bce-fcce-450c-875e-7a6925df9d4f/ugc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e49f9d6f-b050-4324-ae12-4d10297526bb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d6196bce-fcce-450c-875e-7a6925df9d4f/ugc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3b788162-07f0-4734-acba-4f8853c7b7ba',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d6196bce-fcce-450c-875e-7a6925df9d4f/ugc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '45d9a0a9-de0e-42b5-96a3-005f2bea9828'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd6196bce-fcce-450c-875e-7a6925df9d4f',
        firstName: 'Tracy',
        lastName: 'Chattah',
        email: 'hello@tracychattah.com',
        instagramProfile: {
          handle: 'tracychattah',
          followers: 7344,
          engagementRate: 1.8082788671023968,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'tracychattah',
          followers: 915,
          engagementRate: 6.746439040585625,
          isPrimary: true
        }
      },
      userCampaignId: 'd6196bce-fcce-450c-875e-7a6925df9d4f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-10T01:04:08.000Z',
        amountCents: 4250
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '6959738b-d075-4dae-9ace-3e4c878c5a79',
        firstName: 'Aya',
        lastName: 'Alghanmeh',
        email: 'aya.ghanameh@gmail.com',
        instagramProfile: {
          handle: 'ayaghanameh',
          followers: 12980,
          engagementRate: 3.3212634822804317,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kharoofa',
          followers: 21847,
          engagementRate: 11.24880838894185,
          isPrimary: true
        }
      },
      userCampaignId: '6959738b-d075-4dae-9ace-3e4c878c5a79'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-12T19:53:01.000Z',
        amountCents: 3233
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '74a00f6a-5ed4-4496-8382-e8cf6f77ace7',
        firstName: 'Michelle ',
        lastName: 'Bruni',
        email: 'bilingualcoco@gmail.com',
        instagramProfile: {
          handle: 'bilingualcoco',
          followers: 55915,
          engagementRate: 12.805150675131896,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'bilingualcoco',
          followers: 242246,
          engagementRate: 8.008039024126152,
          isPrimary: true
        }
      },
      userCampaignId: '74a00f6a-5ed4-4496-8382-e8cf6f77ace7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T11:46:17.000Z',
        amountCents: 3824
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-28T19:11:59.063Z',
          createdAt: '2024-02-15T02:50:51.390Z',
          content: [
            {
              id: '8362b120-b118-4b20-9e37-10f72a3e7d62',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8d0f28ea-86d6-4570-8392-2292466de09c/ugc/c9a39867-a81c-4299-bb92-9d4066ea81a3/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8886a412-4523-45cb-8b50-8b4626387811',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8d0f28ea-86d6-4570-8392-2292466de09c/ugc/c9a39867-a81c-4299-bb92-9d4066ea81a3/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '95d26346-a618-4a08-8ed8-686139302df6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8d0f28ea-86d6-4570-8392-2292466de09c/ugc/c9a39867-a81c-4299-bb92-9d4066ea81a3/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c9a39867-a81c-4299-bb92-9d4066ea81a3'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-28T19:11:51.541Z',
          createdAt: '2024-02-17T14:58:29.100Z',
          content: [
            {
              id: '732764bf-968c-4c17-a0d0-1013ff1c3a70',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8d0f28ea-86d6-4570-8392-2292466de09c/ugc/0033aede-8317-41fa-b039-8c22340d8b4c/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0033aede-8317-41fa-b039-8c22340d8b4c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '8d0f28ea-86d6-4570-8392-2292466de09c',
        firstName: 'Sarah',
        lastName: 'Gould',
        email: 'gouldsarah13@gmail.com',
        tiktokProfile: {
          handle: 'sarah.go.13',
          followers: 11263,
          engagementRate: 3.9192598822539946,
          isPrimary: true
        }
      },
      userCampaignId: '8d0f28ea-86d6-4570-8392-2292466de09c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-15T21:23:10.000Z',
        amountCents: 4918
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.ADVOCATE,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'aaf6342e-beba-4e1f-90fe-a6865905d8a7',
        firstName: 'Lucas',
        lastName: 'Ficher',
        email: 'lucas4t@hotmail.com',
        instagramProfile: {
          handle: 'lucas.ficherr',
          followers: 108554,
          engagementRate: 0.5231497687786724,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'lucasficherr',
          followers: 5186,
          engagementRate: 45.3416149068323,
          isPrimary: true
        }
      },
      userCampaignId: 'aaf6342e-beba-4e1f-90fe-a6865905d8a7'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-19T17:33:12.000Z',
        amountCents: 4757
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-08T20:27:37.483Z',
          createdAt: '2024-02-27T23:24:41.981Z',
          content: [
            {
              id: '91f7ae62-9fb3-4db0-bb77-47b4211e850d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/80a4c42b-be1c-405b-9c06-42f88b8cc950/ugc/eb140db0-e9c2-4d77-94e5-d69dd482c573/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4d1ff8c2-b326-4510-b9da-e69821dbfdbb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/80a4c42b-be1c-405b-9c06-42f88b8cc950/ugc/eb140db0-e9c2-4d77-94e5-d69dd482c573/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6c9d1d19-2c83-4a2f-a45d-bae62b8991f6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/80a4c42b-be1c-405b-9c06-42f88b8cc950/ugc/eb140db0-e9c2-4d77-94e5-d69dd482c573/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'eb140db0-e9c2-4d77-94e5-d69dd482c573'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '80a4c42b-be1c-405b-9c06-42f88b8cc950',
        firstName: 'Ella',
        lastName: 'Leonard',
        email: 'ellaannleonard@gmail.com',
        tiktokProfile: {
          handle: 'cherrychronicle',
          followers: 1790,
          engagementRate: 15.490797546012269,
          isPrimary: false
        }
      },
      userCampaignId: '80a4c42b-be1c-405b-9c06-42f88b8cc950'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-13T15:57:27.000Z',
        amountCents: 3210
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '914a9511-fa78-4384-8d0a-b8b34bc78820',
        firstName: 'Meagan',
        lastName: 'Riley',
        email: 'meaganmf@gmail.com',
        instagramProfile: {
          handle: 'meagiefoodie',
          followers: 2415,
          engagementRate: 88.96066252587993,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'meagiemm',
          followers: 4770,
          engagementRate: 3.7249283667621778,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'meagiemm',
          followers: 94
        }
      },
      userCampaignId: '914a9511-fa78-4384-8d0a-b8b34bc78820'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T01:24:06.000Z',
        amountCents: 5161
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-12T20:57:16.829Z',
          createdAt: '2024-02-04T21:44:43.426Z',
          content: [
            {
              id: 'a699d4e7-aa2c-4028-9ca5-ece7b0cf7d88',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f318b1c-f767-4471-bec0-d71f75461984/ugc/ebdacf2a-61aa-472c-b12a-d8b95637c2f5/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1f950574-f449-4a27-8fa9-f8bf29463b8c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f318b1c-f767-4471-bec0-d71f75461984/ugc/ebdacf2a-61aa-472c-b12a-d8b95637c2f5/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ba0e6de2-5eac-40d7-bd2c-b26533df0841',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f318b1c-f767-4471-bec0-d71f75461984/ugc/ebdacf2a-61aa-472c-b12a-d8b95637c2f5/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ebdacf2a-61aa-472c-b12a-d8b95637c2f5'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-14T04:48:01.827Z',
          createdAt: '2024-02-06T20:59:15.747Z',
          content: [
            {
              id: '8c1f1607-a710-4525-b919-e6d1b93861bb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f318b1c-f767-4471-bec0-d71f75461984/ugc/1fb2a23f-125a-4624-9c88-b763b962eedd/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1fb2a23f-125a-4624-9c88-b763b962eedd'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0f318b1c-f767-4471-bec0-d71f75461984',
        firstName: 'Katherine',
        lastName: 'Baker',
        email: 'kittenkbshops@gmail.com',
        instagramProfile: {
          handle: 'katherinemarie.shops',
          followers: 15035,
          engagementRate: 1.5011639507815098,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kittenkb',
          followers: 2147,
          engagementRate: 4.166666666666666,
          isPrimary: true
        }
      },
      userCampaignId: '0f318b1c-f767-4471-bec0-d71f75461984'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T23:33:00.000Z',
        amountCents: 5210
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-01T16:27:30.940Z',
          createdAt: '2024-02-27T02:12:21.911Z',
          content: [
            {
              id: 'eff9aefc-0cd8-441b-b9c5-d2ca2b9cf509',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2fd36cd1-0cb6-45dd-a0c7-01f4f2c7d4e2/ugc/8c2b5723-e4d7-4c03-9e12-463ad84e3b48/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8c2b5723-e4d7-4c03-9e12-463ad84e3b48'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2fd36cd1-0cb6-45dd-a0c7-01f4f2c7d4e2',
        firstName: 'Jessy',
        lastName: 'Clark',
        email: 'jessymaradasilvaclark@gmail.com',
        instagramProfile: {
          handle: '_jessyclark',
          followers: 102715,
          engagementRate: 1.1103538918366354,
          isPrimary: true
        },
        tiktokProfile: {
          handle: '_jessyclark',
          followers: 4187,
          engagementRate: 0.07702888583218706,
          isPrimary: true
        }
      },
      userCampaignId: '2fd36cd1-0cb6-45dd-a0c7-01f4f2c7d4e2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-12T20:34:11.000Z',
        amountCents: 3233
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-29T17:47:50.514Z',
          createdAt: '2024-02-26T22:57:31.308Z',
          content: [
            {
              id: '09c932a3-4b9b-4b10-b134-f05b85a60439',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8b278094-a348-4044-abca-e98adf81bea3/ugc/f7d89b0d-f43e-4432-b195-00728fe76294/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f7d89b0d-f43e-4432-b195-00728fe76294'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '8b278094-a348-4044-abca-e98adf81bea3',
        firstName: 'Haley',
        lastName: 'Cox',
        email: 'hayheller@yahoo.com',
        instagramProfile: {
          handle: 'yogahay',
          followers: 3244,
          engagementRate: 0.406905055487053,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'yoga_hay',
          followers: 450,
          engagementRate: 3.1192660550458715,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'haleycox380'
        }
      },
      userCampaignId: '8b278094-a348-4044-abca-e98adf81bea3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-12T16:12:09.000Z',
        amountCents: 3159
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-10T17:48:59.294Z',
          createdAt: '2024-02-27T01:25:22.576Z',
          content: [
            {
              id: 'fae18584-176a-4b48-b64e-ccd10268ce16',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6e45549a-a7d8-4f8d-9ff6-0c057be4d09b/ugc/85dac7bc-7c0d-4601-9242-d2297b03e72a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '85dac7bc-7c0d-4601-9242-d2297b03e72a'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-10T17:49:03.792Z',
          createdAt: '2024-02-27T01:06:19.753Z',
          content: [
            {
              id: 'bf72e432-ac36-4608-a98f-83f10e293b24',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6e45549a-a7d8-4f8d-9ff6-0c057be4d09b/ugc/9bf25906-7a65-49ef-8d9a-5f789cf24d08/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6c5e7070-7764-42dc-ad76-b2bed545469e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6e45549a-a7d8-4f8d-9ff6-0c057be4d09b/ugc/9bf25906-7a65-49ef-8d9a-5f789cf24d08/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9bf25906-7a65-49ef-8d9a-5f789cf24d08'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '6e45549a-a7d8-4f8d-9ff6-0c057be4d09b',
        firstName: 'Takiyah',
        lastName: 'Pittman',
        email: 'kiyahsklosett@gmail.com',
        instagramProfile: {
          handle: 'takiyahdenise',
          followers: 39105,
          engagementRate: 0.4337041299066615,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'takiyahdenise',
          followers: 771,
          engagementRate: 6.0606060606060606,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'takiyahdenise'
        }
      },
      userCampaignId: '6e45549a-a7d8-4f8d-9ff6-0c057be4d09b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T21:34:06.000Z',
        amountCents: 5301
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-14T03:15:55.419Z',
          createdAt: '2024-02-13T17:10:21.205Z',
          content: [
            {
              id: '0944799e-93d9-4893-aea2-134e37d1e73a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1acd7870-905e-4d61-a139-968ac01b8429/ugc/7dce7cad-8594-45d1-99db-16cc85a106c8/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7dce7cad-8594-45d1-99db-16cc85a106c8'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-13T19:43:31.963Z',
          createdAt: '2024-02-13T17:11:36.763Z',
          content: [
            {
              id: '7ca3611e-0037-4dc1-bbd9-463b46abebd2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1acd7870-905e-4d61-a139-968ac01b8429/ugc/bc6c455d-a294-44be-ae4b-b88d27b283a6/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a0bccc86-07e3-4a2a-9f52-f6edbbe7e945',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1acd7870-905e-4d61-a139-968ac01b8429/ugc/bc6c455d-a294-44be-ae4b-b88d27b283a6/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '256172e4-e1ba-4b21-bd03-a816068e8adb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1acd7870-905e-4d61-a139-968ac01b8429/ugc/bc6c455d-a294-44be-ae4b-b88d27b283a6/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bc6c455d-a294-44be-ae4b-b88d27b283a6'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-04-01T15:00:35.407Z',
          createdAt: '2024-03-29T00:21:55.083Z',
          content: [
            {
              id: '5e5564b8-55e5-40a1-b11e-eab4781fa029',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1acd7870-905e-4d61-a139-968ac01b8429/ugc/8adc1062-d265-4f89-8c6a-db57f9871019/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8adc1062-d265-4f89-8c6a-db57f9871019'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '1acd7870-905e-4d61-a139-968ac01b8429',
        firstName: 'Mariann',
        lastName: 'Yip',
        email: 'mariann.yip@gmail.com',
        instagramProfile: {
          handle: 'mariann_yip',
          followers: 112688,
          engagementRate: 0.4484949595342893,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mariann_yip',
          followers: 3997,
          engagementRate: 7.6873044878743775,
          isPrimary: true
        }
      },
      userCampaignId: '1acd7870-905e-4d61-a139-968ac01b8429'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-20T21:15:09.000Z',
        amountCents: 5284
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-29T21:24:15.971Z',
          createdAt: '2024-02-27T01:32:26.595Z',
          content: [
            {
              id: '30ff1ca5-809c-4eed-9687-4773169d5daf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bef98520-427c-4b89-852d-6e0847042676/ugc/b689c71c-788c-4dbc-a5e7-87ebb278bb53/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '255da41f-808d-44d7-8b45-afec86e54bb3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bef98520-427c-4b89-852d-6e0847042676/ugc/b689c71c-788c-4dbc-a5e7-87ebb278bb53/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5767d544-2103-4fd3-9059-b78cce3f313f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bef98520-427c-4b89-852d-6e0847042676/ugc/b689c71c-788c-4dbc-a5e7-87ebb278bb53/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b689c71c-788c-4dbc-a5e7-87ebb278bb53'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-29T21:24:19.631Z',
          createdAt: '2024-02-27T01:31:40.037Z',
          content: [
            {
              id: 'e93545df-a029-4263-a2f9-c84c9ac5725f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bef98520-427c-4b89-852d-6e0847042676/ugc/87dcdd4f-b6a8-427e-92a8-fcdb58e531b9/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '87dcdd4f-b6a8-427e-92a8-fcdb58e531b9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'bef98520-427c-4b89-852d-6e0847042676',
        firstName: 'Lexi',
        lastName: 'Ludgate',
        email: 'lexiiludgate@gmail.com',
        instagramProfile: {
          handle: 'lexiiludgate',
          followers: 1413,
          engagementRate: 4.656758669497523,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'lexiiludgate',
          followers: 1915,
          engagementRate: 18.12759388602085,
          isPrimary: true
        }
      },
      userCampaignId: 'bef98520-427c-4b89-852d-6e0847042676'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-10T18:25:31.000Z',
        amountCents: 3248
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-02T00:10:37.544Z',
          createdAt: '2024-02-19T16:22:04.729Z',
          content: [
            {
              id: 'cb93974e-76b7-4d15-9b71-899268670a4d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0932c04d-f13b-473f-95db-b1cb648e5a33/ugc/8ed90743-b01e-408d-9b99-8b632238a97e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '278e7fc2-2f23-44a7-b156-e4d1da285f3c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0932c04d-f13b-473f-95db-b1cb648e5a33/ugc/8ed90743-b01e-408d-9b99-8b632238a97e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '44c232f0-6b6d-4220-88eb-e36c25c4509a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0932c04d-f13b-473f-95db-b1cb648e5a33/ugc/8ed90743-b01e-408d-9b99-8b632238a97e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8ed90743-b01e-408d-9b99-8b632238a97e'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-02T00:10:31.104Z',
          createdAt: '2024-02-20T04:45:26.529Z',
          content: [
            {
              id: '282e9150-f6b6-4709-ab39-5f397312da6d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0932c04d-f13b-473f-95db-b1cb648e5a33/ugc/1ed32d78-23be-43c6-9e9c-43b57d84c1b9/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1ed32d78-23be-43c6-9e9c-43b57d84c1b9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0932c04d-f13b-473f-95db-b1cb648e5a33',
        firstName: 'Carina',
        lastName: 'Contreras',
        email: 'carinav1000@gmail.com',
        instagramProfile: {
          handle: 'ohcari',
          followers: 1403,
          engagementRate: 6.6999287241625085,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'caweenaa_',
          followers: 7875,
          engagementRate: 7.125875875875876,
          isPrimary: false
        }
      },
      userCampaignId: '0932c04d-f13b-473f-95db-b1cb648e5a33'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-03T20:14:13.000Z',
        amountCents: 5327
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-04-12T15:01:20.480Z',
          createdAt: '2024-02-12T16:29:46.633Z',
          content: [
            {
              id: '36349cb4-508c-499a-be67-138033b46190',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cecab417-182f-4632-a2f6-e87281f6d4db/ugc/82606c31-10cd-48b5-b329-1a4b182350a9/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '82606c31-10cd-48b5-b329-1a4b182350a9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'cecab417-182f-4632-a2f6-e87281f6d4db',
        firstName: 'Marian',
        lastName: 'Betancourt',
        email: 'mbetancourts1924@gmail.com',
        instagramProfile: {
          handle: 'armaries.co',
          followers: 3352,
          engagementRate: 0.6264916467780429,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'armaries_',
          followers: 375,
          engagementRate: 2.8009799227877883,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'armaries8019'
        }
      },
      userCampaignId: 'cecab417-182f-4632-a2f6-e87281f6d4db'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T03:15:13.000Z',
        amountCents: 5346
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-27T14:49:01.243Z',
          createdAt: '2024-02-14T21:17:47.527Z',
          content: [
            {
              id: '1ab405e0-0ad7-478d-9777-5a335176c79e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bf5e8d26-f776-48ac-8514-f317a618dabc/ugc/95bded54-4504-4e6b-a532-9f21022c106d/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e2be2347-3c17-46ad-a62d-029d58599725',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bf5e8d26-f776-48ac-8514-f317a618dabc/ugc/95bded54-4504-4e6b-a532-9f21022c106d/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ca95e09e-ce37-4806-b867-ea096311f5ee',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bf5e8d26-f776-48ac-8514-f317a618dabc/ugc/95bded54-4504-4e6b-a532-9f21022c106d/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '95bded54-4504-4e6b-a532-9f21022c106d'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-27T14:48:43.842Z',
          createdAt: '2024-02-14T22:09:21.000Z',
          content: [
            {
              id: '299dc8ef-ace9-474f-9bbc-a6bbd5a9da76',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bf5e8d26-f776-48ac-8514-f317a618dabc/ugc/ffd1296d-da79-4b47-a541-cc0e88d56588/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ffd1296d-da79-4b47-a541-cc0e88d56588'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'bf5e8d26-f776-48ac-8514-f317a618dabc',
        firstName: 'Laura',
        lastName: 'Bambrick',
        email: 'laura.bambrick@gmail.com',
        instagramProfile: {
          handle: 'lbambrick',
          followers: 10427,
          engagementRate: 1.373357629231802,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'idodeclaire',
          followers: 260,
          engagementRate: 1.5060240963855422,
          isPrimary: true
        }
      },
      userCampaignId: 'bf5e8d26-f776-48ac-8514-f317a618dabc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T09:14:06.000Z',
        amountCents: 5161
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-06T16:25:13.945Z',
          createdAt: '2024-02-05T04:48:43.038Z',
          content: [
            {
              id: '7476ce71-d716-4ed3-a33c-b0ef2516b39f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4dcf600c-9099-4ac3-9413-ca562d324aed/ugc/0fc6b63f-862a-4769-ad16-eb93ae69e9f2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '015b3df6-1488-40c1-ab7a-fd6ce6ab38c9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4dcf600c-9099-4ac3-9413-ca562d324aed/ugc/0fc6b63f-862a-4769-ad16-eb93ae69e9f2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c34bad8b-2478-41bd-960e-c32cbe067cbe',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4dcf600c-9099-4ac3-9413-ca562d324aed/ugc/0fc6b63f-862a-4769-ad16-eb93ae69e9f2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0fc6b63f-862a-4769-ad16-eb93ae69e9f2'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-13T02:17:33.680Z',
          createdAt: '2024-02-08T05:03:57.941Z',
          content: [
            {
              id: '7f729d5d-5fd2-4e9e-92ff-898cfa9722b4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4dcf600c-9099-4ac3-9413-ca562d324aed/ugc/b29bfdb3-7e1a-4de5-84cb-87eb446d2be7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b29bfdb3-7e1a-4de5-84cb-87eb446d2be7'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4dcf600c-9099-4ac3-9413-ca562d324aed',
        firstName: 'Masha',
        lastName: 'Mukanova',
        email: 'mukanovam@gmail.com',
        instagramProfile: {
          handle: 'mashka.ugc',
          followers: 4624,
          engagementRate: 8.473183391003461,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'masha.nova',
          followers: 729,
          engagementRate: 5.429831387808042,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'mashkaugc',
          followers: 614
        }
      },
      userCampaignId: '4dcf600c-9099-4ac3-9413-ca562d324aed'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T09:25:36.000Z',
        amountCents: 5063
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-23T23:43:45.123Z',
          createdAt: '2024-02-12T18:07:08.191Z',
          content: [
            {
              id: 'a588f762-87d8-4de4-be6c-d2c0e2fe8401',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cfaf6b8e-9fb9-4504-a2a5-d52a4a438644/ugc/c1db0e4d-a994-4ead-bea1-5365f58d3900/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'db546a89-640a-4323-9ce0-e7ecdcd60671',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cfaf6b8e-9fb9-4504-a2a5-d52a4a438644/ugc/c1db0e4d-a994-4ead-bea1-5365f58d3900/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '74421af5-5df0-48de-bbe5-f89dbffd513e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cfaf6b8e-9fb9-4504-a2a5-d52a4a438644/ugc/c1db0e4d-a994-4ead-bea1-5365f58d3900/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c1db0e4d-a994-4ead-bea1-5365f58d3900'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-23T23:43:40.779Z',
          createdAt: '2024-02-12T18:53:39.863Z',
          content: [
            {
              id: '7508bb75-70c5-4bfc-b4bc-92b739672339',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cfaf6b8e-9fb9-4504-a2a5-d52a4a438644/ugc/90118979-be67-42d3-85be-9ac9bd444859/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '90118979-be67-42d3-85be-9ac9bd444859'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'cfaf6b8e-9fb9-4504-a2a5-d52a4a438644',
        firstName: 'Michele',
        lastName: 'Katsaris',
        email: 'michelekatsaris@gmail.com',
        instagramProfile: {
          handle: 'michelekatsaris',
          followers: 1556,
          engagementRate: 1.5984654731457801,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'michelekatsaris',
          followers: 8972,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'michelekatsaris',
          followers: 295
        }
      },
      userCampaignId: 'cfaf6b8e-9fb9-4504-a2a5-d52a4a438644'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T22:14:06.000Z',
        amountCents: 5210
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-16T19:43:00.095Z',
          createdAt: '2024-02-08T00:22:59.084Z',
          content: [
            {
              id: 'b24d0931-a87a-414f-a672-4f7c3f378977',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5f5900ad-1d64-443d-a0c3-bc893beab375/ugc/9db19be2-9927-4416-a237-40b9fc5eb2fc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3b28f59a-97a1-4813-88ff-0a6daf77fe98',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5f5900ad-1d64-443d-a0c3-bc893beab375/ugc/9db19be2-9927-4416-a237-40b9fc5eb2fc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'be2a7a44-8bf8-4747-887b-71931b030686',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5f5900ad-1d64-443d-a0c3-bc893beab375/ugc/9db19be2-9927-4416-a237-40b9fc5eb2fc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9db19be2-9927-4416-a237-40b9fc5eb2fc'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-13T02:55:44.326Z',
          createdAt: '2024-02-08T00:19:49.030Z',
          content: [
            {
              id: '648ad1c3-441f-4ceb-917e-96a8da861595',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5f5900ad-1d64-443d-a0c3-bc893beab375/ugc/e76d9252-6c02-4481-8326-702e1e264386/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e76d9252-6c02-4481-8326-702e1e264386'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '5f5900ad-1d64-443d-a0c3-bc893beab375',
        firstName: 'Desiree ',
        lastName: 'Modica ',
        email: 'desireemodica@gmail.com',
        instagramProfile: {
          handle: 'desiimodica',
          followers: 4093,
          engagementRate: 0.8404593207915954,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'desiimodicaa',
          followers: 3554,
          engagementRate: 15.083428373084743,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'desiimodica'
        }
      },
      userCampaignId: '5f5900ad-1d64-443d-a0c3-bc893beab375'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T14:34:05.000Z',
        amountCents: 5251
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T17:33:59.251Z',
          createdAt: '2024-02-22T21:51:31.618Z',
          content: [
            {
              id: 'b967c92d-6205-42f8-ace4-c21266daf1f7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/13f793d2-0d13-4a96-ad4a-88e5e6018090/ugc/2c7084c4-4b3e-4ce4-8361-4438ddf1ce3a/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8a35c375-620c-4c49-a79e-eb2a2242d07a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/13f793d2-0d13-4a96-ad4a-88e5e6018090/ugc/2c7084c4-4b3e-4ce4-8361-4438ddf1ce3a/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '614416fe-ce73-4ffe-83c8-7980838d5e35',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/13f793d2-0d13-4a96-ad4a-88e5e6018090/ugc/2c7084c4-4b3e-4ce4-8361-4438ddf1ce3a/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2c7084c4-4b3e-4ce4-8361-4438ddf1ce3a'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T17:33:54.181Z',
          createdAt: '2024-02-22T21:49:52.163Z',
          content: [
            {
              id: 'c565bbbb-b812-4fa6-af80-5c1303cfc0c6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/13f793d2-0d13-4a96-ad4a-88e5e6018090/ugc/24c1b0cc-3997-4696-89f5-7a42cc15abc0/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '24c1b0cc-3997-4696-89f5-7a42cc15abc0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '13f793d2-0d13-4a96-ad4a-88e5e6018090',
        firstName: 'Janessa',
        lastName: 'Marcos',
        email: 'wearethemarcos@gmail.com',
        instagramProfile: {
          handle: 'wearethemarcos',
          followers: 28077,
          engagementRate: 0.8487374007194501,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'rocknessa',
          followers: 24960,
          engagementRate: 20.556803319527916,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'wearethemarcos',
          followers: 126
        }
      },
      userCampaignId: '13f793d2-0d13-4a96-ad4a-88e5e6018090'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T21:25:12.000Z',
        amountCents: 5271
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-06T15:12:51.494Z',
          createdAt: '2024-02-03T22:01:49.361Z',
          content: [
            {
              id: '1bc4cf86-aea1-445c-8903-426d226862f9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/beee42df-5465-45b9-87fc-0b23b09da4af/ugc/8fa99077-05c7-4a15-ad49-a749a60f1af9/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd80f00c3-2336-4e36-a40d-c5ea4ac0e026',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/beee42df-5465-45b9-87fc-0b23b09da4af/ugc/8fa99077-05c7-4a15-ad49-a749a60f1af9/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2b213923-db23-4f4f-b3e5-8ac6f18ccde4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/beee42df-5465-45b9-87fc-0b23b09da4af/ugc/8fa99077-05c7-4a15-ad49-a749a60f1af9/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8fa99077-05c7-4a15-ad49-a749a60f1af9'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-07T17:53:40.917Z',
          createdAt: '2024-02-07T17:53:41.422Z',
          content: [
            {
              id: 'ee89b47c-002d-4edf-b36f-9c32b5548bc0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/beee42df-5465-45b9-87fc-0b23b09da4af/ugc/cfe69cee-fa2a-4be8-b93b-c3cf3b07a192/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '99659158-680e-4139-b691-19c5464d80b9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/beee42df-5465-45b9-87fc-0b23b09da4af/ugc/cfe69cee-fa2a-4be8-b93b-c3cf3b07a192/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'cfe69cee-fa2a-4be8-b93b-c3cf3b07a192'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-16T00:44:19.952Z',
          createdAt: '2024-02-09T21:21:13.707Z',
          content: [
            {
              id: '9ccc6fc8-c9bf-4c7c-835d-aa0975eadbdb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/beee42df-5465-45b9-87fc-0b23b09da4af/ugc/8839b9a2-b6b1-4a7a-8872-b2b9478ac1e4/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8839b9a2-b6b1-4a7a-8872-b2b9478ac1e4'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'beee42df-5465-45b9-87fc-0b23b09da4af',
        firstName: 'Aley',
        lastName: 'Rose',
        email: 'aleyroseblog@gmail.com',
        instagramProfile: {
          handle: 'aley.rose',
          followers: 33209,
          engagementRate: 2.412900117438044,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'aley.rose',
          followers: 1259,
          engagementRate: 11.267605633802818,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'aleyrose'
        }
      },
      userCampaignId: 'beee42df-5465-45b9-87fc-0b23b09da4af'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-13T17:14:52.000Z',
        amountCents: 3258
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-09T05:07:04.805Z',
          createdAt: '2024-02-21T02:03:33.492Z',
          content: [
            {
              id: '4b535c33-f5a8-411c-b816-bdd56979bac3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/69229c59-acc4-4a39-bdeb-39fc67b8d277/ugc/881e3bfc-34cf-4b1a-a870-696816b35c01/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f095022e-cdbc-4e2d-9a68-3ed3b9fe5136',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/69229c59-acc4-4a39-bdeb-39fc67b8d277/ugc/881e3bfc-34cf-4b1a-a870-696816b35c01/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'afe3dd50-bb2b-4bd8-88ba-da2d831439bd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/69229c59-acc4-4a39-bdeb-39fc67b8d277/ugc/881e3bfc-34cf-4b1a-a870-696816b35c01/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '881e3bfc-34cf-4b1a-a870-696816b35c01'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-09T05:06:54.369Z',
          createdAt: '2024-02-21T02:02:17.828Z',
          content: [
            {
              id: 'f18cece0-3ea7-495f-8555-20a0008b65cf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/69229c59-acc4-4a39-bdeb-39fc67b8d277/ugc/93b841a4-a1cf-489e-914f-f7e9e462399a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '93b841a4-a1cf-489e-914f-f7e9e462399a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '69229c59-acc4-4a39-bdeb-39fc67b8d277',
        firstName: 'Kylie ',
        lastName: 'Walker',
        email: 'kyliewalkerbrands@gmail.com',
        tiktokProfile: {
          handle: 'kylie_walker',
          followers: 86809,
          engagementRate: 5.802268090970908,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'kyliewalker2898',
          followers: 0
        }
      },
      userCampaignId: '69229c59-acc4-4a39-bdeb-39fc67b8d277'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-12T17:44:07.000Z',
        amountCents: 3199
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-27T11:32:24.255Z',
          createdAt: '2024-02-22T02:45:29.931Z',
          content: [
            {
              id: '68020b27-70fa-4b10-9b1c-6774804c8f3d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6fb4fe10-d796-41ba-aae4-8654abf10595/ugc/21a86504-02be-4192-a9a2-ccd29b79d917/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '21a86504-02be-4192-a9a2-ccd29b79d917'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-27T11:32:28.934Z',
          createdAt: '2024-02-22T02:21:15.697Z',
          content: [
            {
              id: '36a2dd03-5b14-4ff8-a25a-06dd98cd2723',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6fb4fe10-d796-41ba-aae4-8654abf10595/ugc/28113d0e-665c-462c-a851-72c8ecbdc4fb/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6e6bc441-3b0a-4949-84b5-69ed38521a6c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6fb4fe10-d796-41ba-aae4-8654abf10595/ugc/28113d0e-665c-462c-a851-72c8ecbdc4fb/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e6a6ae7a-7f60-4764-a55e-508e3c7cfa01',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6fb4fe10-d796-41ba-aae4-8654abf10595/ugc/28113d0e-665c-462c-a851-72c8ecbdc4fb/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '28113d0e-665c-462c-a851-72c8ecbdc4fb'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '6fb4fe10-d796-41ba-aae4-8654abf10595',
        firstName: 'Jessica',
        lastName: 'McGrath',
        email: 'jmspeechpath@gmail.com',
        instagramProfile: {
          handle: 'jessmmmcg',
          followers: 5516,
          engagementRate: 1.7839013778100075,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'jessmmmcg',
          followers: 10835,
          engagementRate: 1.4185795692347316,
          isPrimary: false
        }
      },
      userCampaignId: '6fb4fe10-d796-41ba-aae4-8654abf10595'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T19:46:44.000Z',
        amountCents: 4300
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'aec77a9d-9ff6-4dc7-9440-032c9f780cda',
        firstName: 'Grace',
        lastName: 'Coburn',
        email: 'g12rupp@gmail.com',
        instagramProfile: {
          handle: 'g12ugcoburn',
          followers: 4743,
          engagementRate: 9.938857263335441,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sunbeam_ugc',
          followers: 236,
          engagementRate: 2.0032339202299676,
          isPrimary: true
        }
      },
      userCampaignId: 'aec77a9d-9ff6-4dc7-9440-032c9f780cda'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-13T19:56:03.000Z',
        amountCents: 3210
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'b0ea7cb5-c050-4237-b7e3-834824b64040',
        firstName: 'Shelly',
        lastName: 'Ray',
        email: 'madisynandmamareviews@gmail.com',
        instagramProfile: {
          handle: 'madisyn_and_the3bears',
          followers: 27968,
          engagementRate: 5.780892448512585,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'madisynandmamareviews',
          followers: 4009,
          engagementRate: 7.673923891062846,
          isPrimary: false
        }
      },
      userCampaignId: 'b0ea7cb5-c050-4237-b7e3-834824b64040'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-12T19:14:08.000Z',
        amountCents: 3266
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-08T21:49:52.191Z',
          createdAt: '2024-02-27T17:02:46.231Z',
          content: [
            {
              id: '854eca17-2d4a-40b1-811e-3f629be148b6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b33c53ad-862d-42db-842e-0ce82e0cc510/ugc/08671491-2d67-40d2-bfae-cc25a215085f/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6dd2ad22-fd04-4a68-b511-7313e7fc50ff',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b33c53ad-862d-42db-842e-0ce82e0cc510/ugc/08671491-2d67-40d2-bfae-cc25a215085f/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e7f69962-14a0-40e6-bf3e-8e1197001d75',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b33c53ad-862d-42db-842e-0ce82e0cc510/ugc/08671491-2d67-40d2-bfae-cc25a215085f/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '08671491-2d67-40d2-bfae-cc25a215085f'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-29T22:38:22.433Z',
          createdAt: '2024-02-15T19:33:56.559Z',
          content: [
            {
              id: 'be0c0a3e-4c7a-4bed-ba2c-8fb2eb799f59',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b33c53ad-862d-42db-842e-0ce82e0cc510/ugc/323ca3df-4eba-455f-b084-276b2f9dc8c1/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '323ca3df-4eba-455f-b084-276b2f9dc8c1'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b33c53ad-862d-42db-842e-0ce82e0cc510',
        firstName: 'Summer',
        lastName: 'Brown',
        email: 'sumstrawberries@gmail.com',
        instagramProfile: {
          handle: 'summerstrawberries',
          followers: 4208,
          engagementRate: 0.5442015209125475,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'summersstrawberries',
          followers: 15771,
          engagementRate: 4.076054125891023,
          isPrimary: true
        }
      },
      userCampaignId: 'b33c53ad-862d-42db-842e-0ce82e0cc510'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-15T01:35:33.000Z',
        amountCents: 3218
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T18:18:52.999Z',
          createdAt: '2024-02-24T15:01:08.892Z',
          content: [
            {
              id: 'ab9440a5-fd90-454f-a271-9bd9f916f957',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/508fb47c-3a9d-45dc-bb86-bafa03da7a17/ugc/630b446e-6555-401a-bafc-14a20ffa4879/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0679c6ea-864d-4810-bbdf-59ff16e8e651',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/508fb47c-3a9d-45dc-bb86-bafa03da7a17/ugc/630b446e-6555-401a-bafc-14a20ffa4879/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'fce5026a-1567-4314-a369-1ef45209ab38',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/508fb47c-3a9d-45dc-bb86-bafa03da7a17/ugc/630b446e-6555-401a-bafc-14a20ffa4879/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '630b446e-6555-401a-bafc-14a20ffa4879'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T18:18:45.923Z',
          createdAt: '2024-02-24T01:17:00.946Z',
          content: [
            {
              id: '56d92a87-0475-4f81-88f9-7989f8babe2a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/508fb47c-3a9d-45dc-bb86-bafa03da7a17/ugc/453ae201-ed94-4ce4-aa99-8dff20d3b22e/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '453ae201-ed94-4ce4-aa99-8dff20d3b22e'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T17:54:03.676Z',
          createdAt: '2024-03-25T01:06:16.900Z',
          content: [
            {
              id: '90f246ce-4352-4117-904d-03fd23f0c542',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/508fb47c-3a9d-45dc-bb86-bafa03da7a17/ugc/f8fcf51e-e815-4ad9-93f9-88d519541886/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f8fcf51e-e815-4ad9-93f9-88d519541886'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '508fb47c-3a9d-45dc-bb86-bafa03da7a17',
        firstName: 'Sam',
        lastName: 'Stroheker',
        email: 'sam@socialsbysam.net',
        instagramProfile: {
          handle: 'sam.stroheker',
          followers: 2739,
          engagementRate: 1.6064257028112447,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'socials.by.sam',
          followers: 1595,
          engagementRate: 4.83271375464684,
          isPrimary: false
        }
      },
      userCampaignId: '508fb47c-3a9d-45dc-bb86-bafa03da7a17'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-13T19:15:11.000Z',
        amountCents: 3210
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-06T20:48:35.501Z',
          createdAt: '2024-02-21T19:27:48.355Z',
          content: [
            {
              id: 'af14ae92-6333-45ae-8559-79ecd01dee43',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7ea22052-a323-427a-9f9b-0622241137cc/ugc/59645304-d330-488a-98c6-926bbd91bed1/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '166fdc87-6633-4ea1-b7ce-c06c77513d3c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7ea22052-a323-427a-9f9b-0622241137cc/ugc/59645304-d330-488a-98c6-926bbd91bed1/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'df360fdb-12e2-4648-84eb-d7b9f8583348',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7ea22052-a323-427a-9f9b-0622241137cc/ugc/59645304-d330-488a-98c6-926bbd91bed1/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '59645304-d330-488a-98c6-926bbd91bed1'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-06T20:48:31.098Z',
          createdAt: '2024-02-22T00:08:20.284Z',
          content: [
            {
              id: '8e7ad586-fde8-46eb-a52c-1ae220ef30b2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7ea22052-a323-427a-9f9b-0622241137cc/ugc/fbcb1cf3-6f47-4b32-a2ae-7be182f633f3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'fbcb1cf3-6f47-4b32-a2ae-7be182f633f3'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T17:58:24.685Z',
          createdAt: '2024-03-21T18:02:13.851Z',
          content: [
            {
              id: '0df0b031-47ba-4cdc-b1e0-1d238dc9e023',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7ea22052-a323-427a-9f9b-0622241137cc/ugc/4f0463ee-a54c-4959-8689-4b25942721c6/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4f0463ee-a54c-4959-8689-4b25942721c6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7ea22052-a323-427a-9f9b-0622241137cc',
        firstName: 'Anahys',
        lastName: 'Ramos',
        email: 'anahysramos99@gmail.com',
        instagramProfile: {
          handle: 'anahysramos',
          followers: 68768,
          engagementRate: 1.8562412750116335,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'anahysramos',
          followers: 3851,
          engagementRate: 5.479609808968974,
          isPrimary: true
        }
      },
      userCampaignId: '7ea22052-a323-427a-9f9b-0622241137cc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-15T21:35:12.000Z',
        amountCents: 4869
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-09T02:59:25.840Z',
          createdAt: '2024-02-25T18:41:32.830Z',
          content: [
            {
              id: 'ba4a7c38-e0b2-410c-b466-d48e071adc9d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1f75c13f-51a9-47d0-ac76-20b8c3964b69/ugc/5c633231-2691-49cb-9215-e4ba4b42bc22/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3ed209de-b5ad-4e76-9111-727f9283e367',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1f75c13f-51a9-47d0-ac76-20b8c3964b69/ugc/5c633231-2691-49cb-9215-e4ba4b42bc22/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8bbb6711-a773-4725-87b6-7e02f2633224',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1f75c13f-51a9-47d0-ac76-20b8c3964b69/ugc/5c633231-2691-49cb-9215-e4ba4b42bc22/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5c633231-2691-49cb-9215-e4ba4b42bc22'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-09T02:59:18.866Z',
          createdAt: '2024-02-25T18:39:55.609Z',
          content: [
            {
              id: '0c1f3d91-7121-45d6-98f8-e9484ff0a766',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1f75c13f-51a9-47d0-ac76-20b8c3964b69/ugc/5d1a2609-2260-47a8-9088-68a329ba3d23/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5d1a2609-2260-47a8-9088-68a329ba3d23'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-28T18:19:58.828Z',
          createdAt: '2024-03-27T14:40:18.650Z',
          content: [
            {
              id: '1fea77cd-3776-490c-b324-a3e5638b5827',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1f75c13f-51a9-47d0-ac76-20b8c3964b69/ugc/2d6672c0-e2ad-46b1-93df-ffd7ea8b14c1/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2d6672c0-e2ad-46b1-93df-ffd7ea8b14c1'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '1f75c13f-51a9-47d0-ac76-20b8c3964b69',
        firstName: 'Emily',
        lastName: 'Schaffer',
        email: 'emilyschafferbusiness@gmail.com',
        instagramProfile: {
          handle: 'ugcemilys',
          followers: 2037,
          engagementRate: 3.7997054491899855,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'emschaffer_',
          followers: 10155,
          engagementRate: 0.9060763322162539,
          isPrimary: false
        }
      },
      userCampaignId: '1f75c13f-51a9-47d0-ac76-20b8c3964b69'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-21T18:10:35.867Z',
          createdAt: '2024-03-21T16:42:19.640Z',
          content: [
            {
              id: 'a6f14f0e-65cb-446e-94f0-6de7c1377f01',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d7c070bf-a0db-458e-bfa2-155354fb5462/ugc/01cb7ef7-2ad9-4fc3-9b22-aef0b2cee3f2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '81ebf044-107c-4e83-9c84-82a4cb4aafa2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d7c070bf-a0db-458e-bfa2-155354fb5462/ugc/01cb7ef7-2ad9-4fc3-9b22-aef0b2cee3f2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7b8390d7-1c6b-405e-a7ff-20ab67a19ad5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d7c070bf-a0db-458e-bfa2-155354fb5462/ugc/01cb7ef7-2ad9-4fc3-9b22-aef0b2cee3f2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '01cb7ef7-2ad9-4fc3-9b22-aef0b2cee3f2'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-03-27T18:06:11.023Z',
          createdAt: '2024-03-21T16:41:48.993Z',
          content: [
            {
              id: '612d03e3-eb35-4004-8133-11481dca911d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d7c070bf-a0db-458e-bfa2-155354fb5462/ugc/75ec99b6-d392-42ba-99b5-d189eb714895/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '75ec99b6-d392-42ba-99b5-d189eb714895'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd7c070bf-a0db-458e-bfa2-155354fb5462',
        firstName: 'Liana',
        lastName: 'Jo',
        email: 'liana.jo97@gmail.com',
        instagramProfile: {
          handle: 'lianacreativestudios',
          followers: 1485,
          engagementRate: 31.75084175084175,
          isPrimary: false
        }
      },
      userCampaignId: 'd7c070bf-a0db-458e-bfa2-155354fb5462'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-05T17:06:09.000Z',
        amountCents: 5333
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-13T16:05:13.550Z',
          createdAt: '2024-02-07T21:04:03.877Z',
          content: [
            {
              id: '221410ee-0895-4503-9a47-020e1713f059',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b5c62520-401c-43d3-9573-1eebfe2efa8d/ugc/b5b0dfe4-945c-4691-a527-59f27a0c2679/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '453d8bbb-8490-424c-bf4b-91f8b9a3273b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b5c62520-401c-43d3-9573-1eebfe2efa8d/ugc/b5b0dfe4-945c-4691-a527-59f27a0c2679/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3e835694-df6d-45be-9a38-f97c7e22e3a4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b5c62520-401c-43d3-9573-1eebfe2efa8d/ugc/b5b0dfe4-945c-4691-a527-59f27a0c2679/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b5b0dfe4-945c-4691-a527-59f27a0c2679'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-16T01:47:27.474Z',
          createdAt: '2024-02-07T21:03:35.208Z',
          content: [
            {
              id: '7bf0eddc-ded8-41f2-9bf4-55b0fb5f5a48',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b5c62520-401c-43d3-9573-1eebfe2efa8d/ugc/8e0db51e-f372-4db0-a58f-f0223a33884a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8e0db51e-f372-4db0-a58f-f0223a33884a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b5c62520-401c-43d3-9573-1eebfe2efa8d',
        firstName: 'Ashley',
        lastName: 'Kulbeck',
        email: 'ashkulbeck@hotmail.com',
        instagramProfile: {
          handle: 'ashley.fitnesss',
          followers: 22592,
          engagementRate: 2.941749291784703,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ashleykkulb',
          followers: 1400,
          engagementRate: 0.6821396664681357,
          isPrimary: true
        }
      },
      userCampaignId: 'b5c62520-401c-43d3-9573-1eebfe2efa8d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-10T16:11:37.000Z',
        amountCents: 3249
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-26T22:50:06.915Z',
          createdAt: '2024-02-19T21:50:19.242Z',
          content: [
            {
              id: '786c2d2c-a518-4063-8b73-a49271ef059c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca2d5c50-dacb-4577-a06d-7c7fd9a614d5/ugc/bbee49b9-ea8e-48e9-90f9-53cc5370f9d4/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5f450d06-a4f2-4182-92d6-3445526c4d08',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca2d5c50-dacb-4577-a06d-7c7fd9a614d5/ugc/bbee49b9-ea8e-48e9-90f9-53cc5370f9d4/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9076b10c-a619-49c8-a933-6d41a4224e7e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca2d5c50-dacb-4577-a06d-7c7fd9a614d5/ugc/bbee49b9-ea8e-48e9-90f9-53cc5370f9d4/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bbee49b9-ea8e-48e9-90f9-53cc5370f9d4'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-27T00:08:36.838Z',
          createdAt: '2024-02-19T21:49:30.537Z',
          content: [
            {
              id: '7eca4100-82f3-4c3d-88ef-ec891838f0dc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca2d5c50-dacb-4577-a06d-7c7fd9a614d5/ugc/8972da51-61de-449a-b8e9-e2c650a128a9/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8972da51-61de-449a-b8e9-e2c650a128a9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ca2d5c50-dacb-4577-a06d-7c7fd9a614d5',
        firstName: 'Akaylia',
        lastName: 'Arias',
        email: 'akaylia.arias@gmail.com',
        instagramProfile: {
          handle: 'akayliaart',
          followers: 13055,
          engagementRate: 1.3741861355802374,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'akayliaasmr',
          followers: 3155,
          engagementRate: 3.75,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'akayliaasmr',
          followers: 6040
        }
      },
      userCampaignId: 'ca2d5c50-dacb-4577-a06d-7c7fd9a614d5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T03:15:29.000Z',
        amountCents: 4260
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ffa2c221-4dcd-4def-8bc1-de4ca2d734ef',
        firstName: 'Cameron',
        lastName: 'Resor',
        email: 'camresor@gmail.com',
        instagramProfile: {
          handle: 'cameronresor',
          followers: 1603,
          engagementRate: 4.734872114784778,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'cameronresor',
          followers: 8045,
          engagementRate: 13.180202043004686,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'cameronresor',
          followers: 245
        }
      },
      userCampaignId: 'ffa2c221-4dcd-4def-8bc1-de4ca2d734ef'
    }
  ]
}

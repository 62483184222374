import { SwitchProps, Switch, FormControlLabel, FormControlLabelProps } from '@mui/material'
import { withInputBase } from '../InputBase/withInputBase'
import { VariantInputBaseProps } from '@productwindtom/ui-base'

export const SwitchInput = ({
  field,
  controlLabelProps,
  ...props
}: VariantInputBaseProps &
  SwitchProps & {
    controlLabelProps?: Partial<FormControlLabelProps>
  }) => (
  <FormControlLabel
    {...controlLabelProps}
    label={controlLabelProps?.label}
    control={<Switch {...field} {...props} value={Boolean(field?.value)} checked={Boolean(field?.value)} />}
  />
)

export default withInputBase(SwitchInput)

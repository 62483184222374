import { Channel, ContentType, DraftStatus, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CONTENT_PENDING_APPROVAL_WHITENING: Array<ModelTypes['CreatorDraftContent']> = [
  {
    id: '80a4c42b-be1c-405b-9c06-42f88b8cc950',
    creator: {
      id: '80a4c42b-be1c-405b-9c06-42f88b8cc950',
      email: 'ellaannleonard@gmail.com',
      firstName: 'Ella',
      lastName: 'Leonard',
      tiktokProfile: {
        handle: 'cherrychronicle',
        followers: 1727,
        engagementRate: 9.9928261537936,
        isPrimary: false
      }
    },
    draftContent: [
      {
        id: 'ce714176-b00b-44fc-900f-a45cf69d4ac3',
        channel: Channel.UGC,
        contentType: ContentType.UGC_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2023-09-01T00:00:00.000Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: ['80a4c42b-be1c-405b-9c06-42f88b8cc950/ugc/ce714176-b00b-44fc-900f-a45cf69d4ac3/UGC-CONTENT-1.mp4']
      }
    ]
  },
  {
    id: '914a9511-fa78-4384-8d0a-b8b34bc78820',
    creator: {
      id: '914a9511-fa78-4384-8d0a-b8b34bc78820',
      email: 'meaganmf@gmail.com',
      firstName: 'Meagan',
      lastName: 'Riley',
      instagramProfile: {
        handle: 'meagiefoodie',
        followers: 2416,
        engagementRate: 90.716059602649,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'meagiemm',
        followers: 4770,
        engagementRate: 3.7249283667621778,
        isPrimary: true
      },
      youtubeProfile: {
        handle: 'meagiemm',
        followers: 94
      }
    },
    draftContent: [
      {
        id: '05fc21e1-59b9-4fbe-a048-0a58738b0dc1',
        channel: Channel.UGC,
        contentType: ContentType.UGC_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2024-02-27T03:57:30.926Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: ['914a9511-fa78-4384-8d0a-b8b34bc78820/ugc/05fc21e1-59b9-4fbe-a048-0a58738b0dc1/UGC-CONTENT-1.mp4']
      },
      {
        id: '4556ea34-69d5-4653-b5ef-cdd2407106d2',
        channel: Channel.UGC,
        contentType: ContentType.UGC_PHOTO,
        isHidden: false,
        draftSubmittedAt: '2024-02-26T23:31:13.613Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: [
          '914a9511-fa78-4384-8d0a-b8b34bc78820/ugc/4556ea34-69d5-4653-b5ef-cdd2407106d2/UGC-CONTENT-1.jpeg',
          '914a9511-fa78-4384-8d0a-b8b34bc78820/ugc/4556ea34-69d5-4653-b5ef-cdd2407106d2/UGC-CONTENT-2.jpeg',
          '914a9511-fa78-4384-8d0a-b8b34bc78820/ugc/4556ea34-69d5-4653-b5ef-cdd2407106d2/UGC-CONTENT-3.jpeg'
        ]
      }
    ]
  }
]

import { Alert, Typography } from '@mui/material'
import { Store, STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { uniq, maxBy } from 'lodash'
import { useMemo } from 'react'

type Price = {
  priceCents?: number
  store?: Store
}

const VariationPriceAlert = ({ prices }: { prices: Price[] }) => {
  const { isOutSideBounds, isVaryingPrices, highestPrice } = useVariationPriceAlert(prices)

  return isOutSideBounds ? (
    <Alert variant="outlined" severity="warning">
      <Typography variant="label3">
        Selected products must be priced within $10 or 20% of each other. Remove products or create a separate campaign
        for each product.
      </Typography>
    </Alert>
  ) : isVaryingPrices ? (
    <Alert variant="outlined" severity="info">
      <Typography variant="label3">
        Selected products have varying prices. ProductWind will use the max selling price of{' '}
        {highestPrice?.toLocaleString()} to calculate the cost of product fulfillment.
      </Typography>
    </Alert>
  ) : !prices.length ? (
    <Alert variant="outlined" severity="warning">
      <Typography variant="label3">You must select at least one product variation</Typography>
    </Alert>
  ) : null
}

export default VariationPriceAlert

export const useVariationPriceAlert = (prices: Price[]) => {
  const { highestPrice, isVaryingPrices, isOutSideBounds } = useMemo(() => {
    const priceCents = prices.map(p => p.priceCents)

    const maxRecord = maxBy(prices, 'priceCents')

    return {
      highestPrice:
        maxRecord?.priceCents && maxRecord?.store
          ? toCurrencyStringCents(maxRecord?.priceCents, STORE_TO_LOCALE[maxRecord?.store])
          : undefined,
      isVaryingPrices: uniq(priceCents).length > 1,
      isOutSideBounds: checkNumberRange(priceCents)
    }
  }, [prices])

  return {
    highestPrice,
    isVaryingPrices,
    isOutSideBounds
  }
}

const DOLLAR_AMOUNT_BOUND = 1000 // $10
const DOLLAR_PERCENT_BOUND = 1.2 // 20%

const checkNumberRange = (numbers: (number | undefined)[]) => {
  const validNumbers = numbers.filter(notEmpty)

  if (validNumbers.length < 2) return false

  const minNumber = Math.min(...validNumbers)
  const maxNumber = Math.max(...validNumbers)

  return maxNumber > minNumber * DOLLAR_PERCENT_BOUND && maxNumber - minNumber > DOLLAR_AMOUNT_BOUND
}

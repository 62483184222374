import { mApi } from '@momentum/api'
import { BrandApiType, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'

export const updateBrand = async (input: ValueTypes['UpdateBrandInput']) => {
  return (
    await mApi('mutation')({
      updateBrand: [
        { input },
        {
          id: true,
          isBrandStoreScrapeEnabled: true,
          isWalmartScrapeEnabled: true
        }
      ]
    })
  ).updateBrand
}

export const updateBrandPricing = async (input: ValueTypes['ModelInputUpdatePricing']) => {
  return (await mApi('mutation')({ updatePricing: [{ input }, { id: true, store: true }] })).updatePricing
}

export const createBrandPricing = async (input: ValueTypes['ModelInputCreatePricing']) => {
  return (await mApi('mutation')({ createPricing: [{ input }, { id: true, store: true }] })).createPricing
}

export const deleteBrandPricing = async (input: ValueTypes['ModelInputDeletePricing']) => {
  return (await mApi('mutation')({ deletePricing: [{ input }, { id: true, store: true }] })).deletePricing
}

export const getAmazonAuthorizationUri = async (brandApiType: BrandApiType, brandId: string, redirectUri: string) => {
  return (await mApi('query')({ getAmazonAuthorizationUri: [{ brandApiType, brandId, redirectUri }, true] }))
    .getAmazonAuthorizationUri
}

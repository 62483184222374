import { Box, Stack, Typography, useTheme } from '@mui/material'
import { BoxToggle } from '@momentum/components/box-toggle'
import 'chart.js/auto'
import 'chartjs-adapter-luxon'
import { Line } from 'react-chartjs-2'
import { last, round, maxBy, minBy, first } from 'lodash'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { DateTime } from 'luxon'
import {
  useReviewsContext,
  ViewType,
  TopLevelViewType
} from '@momentum/routes/campaign/e-commerce/reviews/reviewsContext'
import { useMemo, useState } from 'react'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { retailerProgramReviewsName } from '@momentum/utils/storeUtils'

import { STORE_TO_RETAILER } from '@productwindtom/shared-momentum'

type AccumulatedData = {
  date: string
  numRatings: number
  numReviews: number
  numNonReviewRatings: number
  momentumRatingCount: number
  momentumReviewCountInPeriod: number
  momentumReviewCount: number
  organic: number
  organicInPeriod: number
  vineReviewCount: number
  vineReviewCountInPeriod: number
  reviews: number
  reviewsInPeriod: number
  ratings: number
  ratingsInPeriod: number
  rating: number
  momentumRating?: number
  vineRating?: number
  organicRating?: number
}

export const ReviewsGraph = () => {
  const [isMomentumHidden, setIsMomentumHidden] = useState(false)
  const [isVineHidden, setIsVineHidden] = useState(false)
  const [isOrganicHidden, setIsOrganicHidden] = useState(false)
  const [isTotalHidden, setIsTotalHidden] = useState(true)
  const [isTotalReviewsHidden, setIsTotalReviewsHidden] = useState(false)
  const [isTotalRatingsHidden, setIsTotalRatingsHidden] = useState(false)
  const [isTotalReviewsRatingsHidden, setIsTotalReviewsRatingsHidden] = useState(true)

  const theme = useTheme()
  const {
    campaignDetails: { startDate, endDate, product }
  } = useCampaignContext()
  const { topLevelViewType, viewType, filteredReviewData, is3pReviewsVisible } = useReviewsContext()

  const startDateNoTime = startDate.split('T')[0]
  const endDateNoTime = endDate ? endDate.split('T')[0] : undefined

  const mappedData = useMemo(
    () =>
      filteredReviewData.reduce((array: AccumulatedData[], d) => {
        const fromDate = DateTime.fromISO(d.fromDate)
        const toDate = DateTime.fromISO(d.toDate)
        const diffDays = toDate.diff(fromDate, 'days').days
        const prev = last(array)

        const momentumReviewCount = d.momentumReviewCount + (prev?.momentumReviewCount || 0)
        const momentumRatingCount = d.momentumRatingCount + (prev?.momentumRatingCount || 0)
        const vineReviewCount = d.vineReviewCount + (prev?.vineReviewCount || 0)
        const reviews = d.reviewsInPeriod + (prev?.reviews || 0)
        const ratings = d.ratingsInPeriod + (prev?.ratings || 0)
        const organic = d.organicRatingCount + (prev?.organic || 0)

        const momentumRating =
          d.momentumReviewCount || prev?.momentumReviewCount
            ? (d.momentumRating * d.momentumReviewCount +
                (prev?.momentumRating || 0) * (prev?.momentumReviewCount || 0)) /
              (momentumReviewCount || 1)
            : undefined

        const vineRating =
          d.vineReviewCount || prev?.vineReviewCount
            ? (d.vineRating * d.vineReviewCount + (prev?.vineRating || 0) * (prev?.vineReviewCount || 0)) /
              (vineReviewCount || 1)
            : undefined

        const organicRating =
          d.organicRatingCount || prev?.organic
            ? (d.organicRating * d.organicRatingCount + (prev?.organicRating || 0) * (prev?.organic || 0)) /
              (organic || 1)
            : undefined

        const accum = {
          numRatings: d.numRatings,
          numReviews: d.numReviews,
          numNonReviewRatings: d.numRatings - d.numReviews,
          momentumReviewCount,
          momentumReviewCountInPeriod: d.momentumReviewCount,
          momentumRatingCount,
          vineReviewCount,
          vineReviewCountInPeriod: d.vineReviewCount,
          reviews: reviews,
          reviewsInPeriod: d.reviewsInPeriod,
          ratings: ratings,
          ratingsInPeriod: d.ratingsInPeriod,
          organic,
          organicInPeriod: d.organicRatingCount,
          rating: d.rating || prev?.rating || 0,
          momentumRating: momentumRating ? round(momentumRating, 1) : momentumRating,
          vineRating: vineRating ? round(vineRating, 1) : vineRating,
          organicRating: organicRating ? round(organicRating, 1) : organicRating
        }

        if (diffDays > 1) {
          return [
            ...array,
            ...new Array(diffDays)
              .fill(0)
              .map((_, index) => ({ date: fromDate.plus({ days: index }).toISODate()!, ...accum }))
          ]
        }
        return [...array, { date: d.fromDate, ...accum }]
      }, []),
    [filteredReviewData]
  )

  const inCampaignData = mappedData.filter(
    d => d.date >= startDateNoTime && (!endDateNoTime || d.date <= endDateNoTime)
  )
  const startCampaignData = minBy(inCampaignData, 'date')
  const endCampaignData = maxBy(inCampaignData, 'date')

  const momentumReviewsRatings = mappedData.map(d => d.momentumReviewCount) // + d.momentumRatingCount)
  const organicReviews = mappedData.map(d => d.organic)
  const vineReviews = mappedData.map(d => d.vineReviewCount)
  const totalReviews = mappedData.map(d => d.reviews + d.ratings)

  const totalRatingList = mappedData.map(d => d.rating)
  const momentumRatingList = mappedData.map(d => d.momentumRating)
  const vineRatingList = mappedData.map(d => d.vineRating)
  const organicRatingList = mappedData.map(d => d.organicRating)

  const totalReviewsRatingsList = mappedData.map(d => d.numRatings)
  const totalRatingsList = mappedData.map(d => d.numRatings - d.numReviews)
  const totalReviewsList = mappedData.map(d => d.numReviews)

  const earliest = first(mappedData)
  const latest = last(mappedData)

  const momentumReviewsRatingsSum =
    (latest?.momentumReviewCount || 0) -
    (earliest?.momentumReviewCount || 0) +
    (earliest?.momentumReviewCountInPeriod || 0) // + (latest?.momentumRatingCount || 0)
  const organicReviewsSum = (latest?.organic || 0) - (earliest?.organic || 0) + (earliest?.organicInPeriod || 0)
  const vineReviewsSum =
    (latest?.vineReviewCount || 0) - (earliest?.vineReviewCount || 0) + (earliest?.vineReviewCountInPeriod || 0)
  const totalReviewsRatingsSum =
    (latest?.reviews || 0) +
    (latest?.ratings || 0) -
    ((earliest?.reviews || 0) + (earliest?.ratings || 0)) +
    (earliest?.reviewsInPeriod || 0) +
    (earliest?.ratingsInPeriod || 0)

  const labels = mappedData.map(d => d.date)

  const getToggleLabel = (reviewCount: number, averageRating: number) =>
    viewType === ViewType.RATING
      ? `${averageRating.toFixed(1)} star rating`
      : `${reviewCount.toLocaleString()} reviews & ratings`

  const momentumDataset = [
    {
      borderColor: theme.palette.primary.main,
      pointBorderColor: theme.palette.primary.main,
      pointBackgroundColor: theme.palette.primary.main,
      data: viewType === ViewType.RATING ? momentumRatingList : momentumReviewsRatings,
      hidden: isMomentumHidden
    },
    {
      borderColor: theme.palette.secondary.main,
      pointBorderColor: theme.palette.secondary.main,
      pointBackgroundColor: theme.palette.secondary.main,
      data: viewType === ViewType.RATING ? vineRatingList : vineReviews,
      hidden: isVineHidden || !is3pReviewsVisible
    },
    {
      borderColor: theme.palette.warning.main,
      pointBorderColor: theme.palette.warning.main,
      pointBackgroundColor: theme.palette.warning.main,
      data: viewType === ViewType.RATING ? organicRatingList : organicReviews,
      hidden: isOrganicHidden
    },
    {
      borderColor: theme.palette.grey.A700,
      pointBorderColor: theme.palette.grey.A700,
      pointBackgroundColor: theme.palette.grey.A700,
      data: viewType === ViewType.RATING ? totalRatingList : totalReviews,
      hidden: isTotalHidden
    }
  ]

  const totalDataSet = [
    {
      borderColor: theme.palette.secondary.main,
      pointBorderColor: theme.palette.secondary.main,
      pointBackgroundColor: theme.palette.secondary.main,
      data: totalReviewsList,
      hidden: isTotalReviewsHidden
    },
    {
      borderColor: theme.palette.warning.main,
      pointBorderColor: theme.palette.warning.main,
      pointBackgroundColor: theme.palette.warning.main,
      data: totalRatingsList,
      hidden: isTotalRatingsHidden
    },
    {
      borderColor: theme.palette.grey.A700,
      pointBorderColor: theme.palette.grey.A700,
      pointBackgroundColor: theme.palette.grey.A700,
      data: totalReviewsRatingsList,
      hidden: isTotalReviewsRatingsHidden
    }
  ]

  return (
    <Stack spacing={3}>
      {topLevelViewType === TopLevelViewType.MOMENTUM && (
        <Stack direction={'row'} spacing={2}>
          <BoxToggle
            value={!isMomentumHidden}
            color={theme.palette.primary.main}
            onChange={v => setIsMomentumHidden(!v)}
          >
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>ProductWind</Typography>
              <Typography variant={'label1'}>
                {getToggleLabel(momentumReviewsRatingsSum, latest?.momentumRating || 0)}
              </Typography>
            </Stack>
          </BoxToggle>
          {is3pReviewsVisible && (
            <BoxToggle value={!isVineHidden} color={theme.palette.secondary.main} onChange={v => setIsVineHidden(!v)}>
              <Stack alignItems={'center'} justifyContent={'stretch'}>
                <Typography variant={'body1'}>
                  {' '}
                  {retailerProgramReviewsName(STORE_TO_RETAILER[product.store])}
                </Typography>
                <Typography variant={'label1'}>{getToggleLabel(vineReviewsSum, latest?.vineRating || 0)}</Typography>
              </Stack>
            </BoxToggle>
          )}
          <BoxToggle value={!isOrganicHidden} color={theme.palette.warning.main} onChange={v => setIsOrganicHidden(!v)}>
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>Organic</Typography>
              <Typography variant={'label1'}>
                {getToggleLabel(organicReviewsSum, latest?.organicRating || 0)}
              </Typography>
            </Stack>
          </BoxToggle>
          <BoxToggle value={!isTotalHidden} color={theme.palette.grey.A700} onChange={v => setIsTotalHidden(!v)}>
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>Total</Typography>
              <Typography variant={'label1'}>{getToggleLabel(totalReviewsRatingsSum, latest?.rating || 0)}</Typography>
            </Stack>
          </BoxToggle>
        </Stack>
      )}
      {topLevelViewType === TopLevelViewType.LISTING && (
        <Stack direction={'row'} spacing={2}>
          <BoxToggle
            value={!isTotalReviewsHidden}
            color={theme.palette.secondary.main}
            onChange={v => setIsTotalReviewsHidden(!v)}
          >
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>Reviews</Typography>
              <Typography variant={'label1'}>
                {endCampaignData && startCampaignData
                  ? (endCampaignData.numReviews - startCampaignData.numReviews).toLocaleString()
                  : '--'}{' '}
                reviews
              </Typography>
              <Typography variant={'body1'}>
                From {startCampaignData?.numReviews.toLocaleString() || '--'} to{' '}
                {endCampaignData?.numReviews.toLocaleString() || '--'} reviews
              </Typography>
            </Stack>
          </BoxToggle>
          <BoxToggle
            value={!isTotalRatingsHidden}
            color={theme.palette.warning.main}
            onChange={v => setIsTotalRatingsHidden(!v)}
          >
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>Ratings</Typography>
              <Typography variant={'label1'}>
                {endCampaignData && startCampaignData
                  ? (endCampaignData.numNonReviewRatings - startCampaignData.numNonReviewRatings)?.toLocaleString()
                  : '--'}{' '}
                ratings
              </Typography>

              <Typography variant={'body1'}>
                From {startCampaignData?.numNonReviewRatings?.toLocaleString() || '--'} to{' '}
                {endCampaignData?.numNonReviewRatings?.toLocaleString() || '--'} ratings
              </Typography>
            </Stack>
          </BoxToggle>
          <BoxToggle
            value={!isTotalReviewsRatingsHidden}
            color={theme.palette.grey.A700}
            onChange={v => setIsTotalReviewsRatingsHidden(!v)}
          >
            <Stack alignItems={'center'} justifyContent={'stretch'}>
              <Typography variant={'body1'}>Total</Typography>
              <Typography variant={'label1'}>
                {endCampaignData && startCampaignData
                  ? (endCampaignData.numRatings - startCampaignData.numRatings).toLocaleString()
                  : '--'}{' '}
                reviews & ratings
              </Typography>
              <Typography variant={'body1'}>
                From {startCampaignData?.numRatings.toLocaleString() || '--'} to{' '}
                {endCampaignData?.numRatings.toLocaleString() || '--'} reviews & ratings
              </Typography>
            </Stack>
          </BoxToggle>
        </Stack>
      )}
      <Box>
        <Line
          data={{
            labels: labels,
            datasets: topLevelViewType === TopLevelViewType.MOMENTUM ? momentumDataset : totalDataSet
          }}
          height={500}
          options={{
            elements: { point: { radius: 0, hitRadius: 10 } },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                ...defaultTooltipOptions,
                callbacks: {
                  label: item =>
                    `${item.formattedValue} ${viewType === ViewType.RATING ? 'star rating' : 'reviews & ratings'}`,
                  title: () => '',
                  footer: () => ''
                }
              }
            },
            scales: {
              y: {
                beginAtZero: viewType !== ViewType.RATING,
                grid: {
                  drawOnChartArea: false,
                  drawTicks: false
                },
                offset: true,
                ticks: {
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  color: 'black',
                  padding: 16
                },
                border: {
                  color: 'black',
                  width: 3
                }
              },
              x: {
                type: 'time',
                ticks: {
                  source: 'labels',
                  minRotation: 45,
                  padding: 16,
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  // stepSize: 1,
                  // autoSkip: false,
                  color: value => {
                    const date = DateTime.fromMillis(value.tick.value).toISODate()
                    return date === startDateNoTime || date === endDateNoTime ? theme.palette.primary.main : 'black'
                  }
                },
                time: {
                  unit: 'day',
                  tooltipFormat: 'LL/dd',
                  displayFormats: {
                    day: 'LL/dd'
                  }
                },
                grid: {
                  drawOnChartArea: true,
                  drawTicks: false,
                  color: function (value) {
                    const date = DateTime.fromMillis(value.tick.value).toISODate()
                    return date === startDateNoTime || date === endDateNoTime ? theme.palette.grey.A400 : 'transparent'
                  }
                },
                border: {
                  color: 'black',
                  width: 3
                }
              }
            }
          }}
        />
      </Box>
    </Stack>
  )
}

import { Channel, ContentType, DraftStatus, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CONTENT_PENDING_APPROVAL_PILLOW: Array<ModelTypes['CreatorDraftContent']> = [
  {
    id: 'f266a663-d73f-4e6c-af92-a54dddd72e2d',
    creator: {
      id: 'f266a663-d73f-4e6c-af92-a54dddd72e2d',
      email: 'kacicollins421@gmail.com',
      firstName: 'Kaci',
      lastName: 'Kilfoile',
      instagramProfile: {
        handle: 'kacicollins',
        followers: 3463,
        engagementRate: 0.9991336991048224,
        isPrimary: true
      },
      tiktokProfile: {
        handle: 'kacicollins4',
        followers: 231,
        isPrimary: true
      }
    },
    draftContent: [
      {
        id: 'a35d3636-4aff-4b14-bdea-534251dd8ed4',
        channel: Channel.UGC,
        contentType: ContentType.UGC_PHOTO,
        isHidden: false,
        draftSubmittedAt: '2024-03-12T14:03:29.000Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: [
          'f266a663-d73f-4e6c-af92-a54dddd72e2d/ugc/a35d3636-4aff-4b14-bdea-534251dd8ed4/UGC-CONTENT-1.jpeg',
          'f266a663-d73f-4e6c-af92-a54dddd72e2d/ugc/a35d3636-4aff-4b14-bdea-534251dd8ed4/UGC-CONTENT-2.jpeg',
          'f266a663-d73f-4e6c-af92-a54dddd72e2d/ugc/a35d3636-4aff-4b14-bdea-534251dd8ed4/UGC-CONTENT-3.jpeg'
        ]
      }
    ]
  },
  {
    id: 'f6c7c592-e195-424b-a4bc-de84e05ced6e',
    creator: {
      id: 'f6c7c592-e195-424b-a4bc-de84e05ced6e',
      email: 'tiana.doing.things@gmail.com',
      firstName: 'Tiana',
      lastName: 'Wilson',
      instagramProfile: {
        handle: 'tiana.doing.things',
        followers: 119,
        engagementRate: 9.075630252100842,
        isPrimary: false
      },
      tiktokProfile: {
        handle: 'tiana.doing.things',
        followers: 8797,
        engagementRate: 10.296944910514542,
        isPrimary: false
      }
    },
    draftContent: [
      {
        id: 'f9570062-1010-4cc1-820f-73313eefdea4',
        channel: Channel.UGC,
        contentType: ContentType.UGC_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2024-03-19T19:35:47.075Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: ['f6c7c592-e195-424b-a4bc-de84e05ced6e/ugc/776ef5ea-9255-4821-b6db-007ad09cbe43/UGC-CONTENT-1.mp4']
      }
    ]
  },
  {
    id: '11cf0784-125e-46f4-a20a-9fc9d45d4e1f',
    creator: {
      id: '11cf0784-125e-46f4-a20a-9fc9d45d4e1f',
      email: 'blh10e@gmail.com',
      firstName: 'Brittany',
      lastName: 'Ferrouge',
      tiktokProfile: {
        handle: 'brittanyferrouge',
        followers: 27388,
        engagementRate: 4.811554454569583,
        isPrimary: false
      }
    },

    draftContent: [
      {
        id: '7a8fb70b-09e2-47e1-8e09-b5b365e47864',
        channel: Channel.UGC,
        contentType: ContentType.UGC_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2024-03-18T21:22:08.428Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: [
          '11cf0784-125e-46f4-a20a-9fc9d45d4e1f/ugc/7a8fb70b-09e2-47e1-8e09-b5b365e47864/UGC-CONTENT-1.jpeg',
          '11cf0784-125e-46f4-a20a-9fc9d45d4e1f/ugc/7a8fb70b-09e2-47e1-8e09-b5b365e47864/UGC-CONTENT-2.jpeg',
          '11cf0784-125e-46f4-a20a-9fc9d45d4e1f/ugc/7a8fb70b-09e2-47e1-8e09-b5b365e47864/UGC-CONTENT-3.jpeg'
        ]
      },
      {
        id: '241e7c7b-8192-40e2-b6a3-97e6be0c5547',
        channel: Channel.UGC,
        contentType: ContentType.UGC_VIDEO,
        isHidden: false,
        draftSubmittedAt: '2024-03-18T21:22:03.740Z',
        draftStatus: DraftStatus.REQUIRES_BRAND_APPROVAL,
        draftMedia: ['11cf0784-125e-46f4-a20a-9fc9d45d4e1f/ugc/241e7c7b-8192-40e2-b6a3-97e6be0c5547/UGC-CONTENT-1.mp4']
      }
    ]
  }
]

import Papa from 'papaparse'
import { DateTime } from 'luxon'

export type ReviewInputRecordRow = {
  date: string
  reviewCount?: number
  rating?: number
}

const readFileToString = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = () => reject(reader.error)
    reader.readAsText(file)
  })
}

export const parseReviewFile = async (file: File) => {
  const text = await readFileToString(file)
  try {
    const csvData = Papa.parse<ReviewInputRecordRow>(text, {
      skipEmptyLines: true,
      dynamicTyping: false,
      header: true,
      transformHeader: (header: string, index: number) => {
        switch (index) {
          case 0:
            return 'date'
          case 1:
            return 'reviewCount'
          case 2:
            return 'rating'
          default:
            return header
        }
      },
      transform: (value, field) => {
        switch (field) {
          case 'date':
            return DateTime.fromJSDate(new Date(value)).toISODate()
          case 'reviewCount':
          case 'rating': {
            const numericValue = parseInt(value)
            if (isNaN(numericValue)) {
              return null
            }
            return numericValue
          }
          default:
            return value
        }
      }
    })

    if (csvData.errors.length) {
      throw new Error(csvData.errors[0].message)
    }
    return csvData.data
      .filter(row => row.date && (row.reviewCount != null || row.rating != null))
      .map(row => ({ date: row.date, reviewCount: row.reviewCount, rating: row.rating }))
  } catch (e) {
    throw new Error('Invalid file format')
  }
}

export type BestSellerRankInputRecordRow = {
  date: string
  salesRank?: number
}
export const parseBSRFile = async (file: File) => {
  const text = await readFileToString(file)
  try {
    const csvData = Papa.parse<BestSellerRankInputRecordRow>(text, {
      skipEmptyLines: true,
      dynamicTyping: false,
      header: true,
      transformHeader: (header: string, index: number) => {
        switch (index) {
          case 0:
            return 'date'
          case 1:
            return 'salesRank'
          default:
            return header
        }
      },
      transform: (value, field) => {
        switch (field) {
          case 'date':
            return DateTime.fromJSDate(new Date(value)).toISODate()
          case 'salesRank': {
            const numericValue = parseInt(value)
            if (isNaN(numericValue)) {
              return null
            }
            return numericValue
          }
          default:
            return value
        }
      }
    })

    if (csvData.errors.length) {
      throw new Error(csvData.errors[0].message)
    }
    return csvData.data
      .filter(row => row.date && row.salesRank != null)
      .map(row => ({ date: row.date, salesRank: row.salesRank }))
  } catch (e) {
    throw new Error('Invalid file format')
  }
}

import { CampaignClick } from '@momentum/routes/campaign/context/queries'

export const SAMPLE_CAMPAIGN_CLICKS_OUTDOOR_CAMERA_BOOST: { items: CampaignClick[] } = {
  items: [
    {
      date: '2024-05-16',
      clicks: 730
    },
    {
      date: '2024-05-17',
      clicks: 507
    },
    {
      date: '2024-05-18',
      clicks: 170
    },
    {
      date: '2024-05-19',
      clicks: 148
    },
    {
      date: '2024-05-20',
      clicks: 137
    },
    {
      date: '2024-05-21',
      clicks: 115
    },
    {
      date: '2024-05-22',
      clicks: 97
    },
    {
      date: '2024-05-23',
      clicks: 88
    },
    {
      date: '2024-05-24',
      clicks: 70
    },
    {
      date: '2024-05-25',
      clicks: 55
    },
    {
      date: '2024-05-26',
      clicks: 41
    },
    {
      date: '2024-05-27',
      clicks: 60
    },
    {
      date: '2024-05-28',
      clicks: 7276
    },
    {
      date: '2024-05-29',
      clicks: 5956
    },
    {
      date: '2024-05-30',
      clicks: 1649
    },
    {
      date: '2024-05-31',
      clicks: 1945
    },
    {
      date: '2024-06-01',
      clicks: 2343
    },
    {
      date: '2024-06-02',
      clicks: 2581
    },
    {
      date: '2024-06-03',
      clicks: 1965
    },
    {
      date: '2024-06-04',
      clicks: 1615
    },
    {
      date: '2024-06-05',
      clicks: 1484
    },
    {
      date: '2024-06-06',
      clicks: 1698
    },
    {
      date: '2024-06-07',
      clicks: 946
    },
    {
      date: '2024-06-08',
      clicks: 940
    },
    {
      date: '2024-06-09',
      clicks: 536
    },
    {
      date: '2024-06-10',
      clicks: 1126
    },
    {
      date: '2024-06-11',
      clicks: 1058
    },
    {
      date: '2024-06-12',
      clicks: 921
    },
    {
      date: '2024-06-13',
      clicks: 800
    },
    {
      date: '2024-06-14',
      clicks: 832
    },
    {
      date: '2024-06-15',
      clicks: 1156
    },
    {
      date: '2024-06-16',
      clicks: 667
    },
    {
      date: '2024-06-17',
      clicks: 653
    },
    {
      date: '2024-06-18',
      clicks: 612
    },
    {
      date: '2024-06-19',
      clicks: 479
    },
    {
      date: '2024-06-20',
      clicks: 883
    },
    {
      date: '2024-06-21',
      clicks: 642
    },
    {
      date: '2024-06-22',
      clicks: 1028
    },
    {
      date: '2024-06-23',
      clicks: 597
    },
    {
      date: '2024-06-24',
      clicks: 781
    },
    {
      date: '2024-06-25',
      clicks: 820
    },
    {
      date: '2024-06-26',
      clicks: 920
    },
    {
      date: '2024-06-27',
      clicks: 663
    },
    {
      date: '2024-06-28',
      clicks: 555
    },
    {
      date: '2024-06-29',
      clicks: 592
    },
    {
      date: '2024-06-30',
      clicks: 519
    },
    {
      date: '2024-07-01',
      clicks: 644
    },
    {
      date: '2024-07-02',
      clicks: 547
    },
    {
      date: '2024-07-03',
      clicks: 216
    },
    {
      date: '2024-07-04',
      clicks: 105
    },
    {
      date: '2024-07-05',
      clicks: 140
    },
    {
      date: '2024-07-06',
      clicks: 750
    },
    {
      date: '2024-07-07',
      clicks: 164
    },
    {
      date: '2024-07-08',
      clicks: 2131
    },
    {
      date: '2024-07-09',
      clicks: 323
    },
    {
      date: '2024-07-10',
      clicks: 285
    },
    {
      date: '2024-07-11',
      clicks: 183
    },
    {
      date: '2024-07-12',
      clicks: 241
    },
    {
      date: '2024-07-13',
      clicks: 175
    },
    {
      date: '2024-07-14',
      clicks: 86
    },
    {
      date: '2024-07-15',
      clicks: 152
    },
    {
      date: '2024-07-16',
      clicks: 132
    },
    {
      date: '2024-07-17',
      clicks: 276
    },
    {
      date: '2024-07-18',
      clicks: 269
    },
    {
      date: '2024-07-19',
      clicks: 185
    },
    {
      date: '2024-07-20',
      clicks: 213
    },
    {
      date: '2024-07-21',
      clicks: 463
    },
    {
      date: '2024-07-22',
      clicks: 229
    },
    {
      date: '2024-07-23',
      clicks: 214
    },
    {
      date: '2024-07-24',
      clicks: 154
    },
    {
      date: '2024-07-25',
      clicks: 211
    },
    {
      date: '2024-07-26',
      clicks: 126
    },
    {
      date: '2024-07-27',
      clicks: 106
    },
    {
      date: '2024-07-28',
      clicks: 116
    },
    {
      date: '2024-07-29',
      clicks: 190
    },
    {
      date: '2024-07-30',
      clicks: 89
    },
    {
      date: '2024-07-31',
      clicks: 178
    },
    {
      date: '2024-08-01',
      clicks: 65
    },
    {
      date: '2024-08-02',
      clicks: 74
    },
    {
      date: '2024-08-03',
      clicks: 78
    },
    {
      date: '2024-08-04',
      clicks: 77
    },
    {
      date: '2024-08-05',
      clicks: 58
    },
    {
      date: '2024-08-06',
      clicks: 326
    },
    {
      date: '2024-08-07',
      clicks: 449
    },
    {
      date: '2024-08-08',
      clicks: 98
    },
    {
      date: '2024-08-09',
      clicks: 65
    },
    {
      date: '2024-08-10',
      clicks: 64
    },
    {
      date: '2024-08-11',
      clicks: 62
    },
    {
      date: '2024-08-12',
      clicks: 48
    },
    {
      date: '2024-08-13',
      clicks: 18
    },
    {
      date: '2024-08-14',
      clicks: 177
    },
    {
      date: '2024-08-15',
      clicks: 119
    },
    {
      date: '2024-08-16',
      clicks: 18
    },
    {
      date: '2024-08-17',
      clicks: 32
    },
    {
      date: '2024-08-18',
      clicks: 49
    },
    {
      date: '2024-08-19',
      clicks: 46
    },
    {
      date: '2024-08-20',
      clicks: 20
    },
    {
      date: '2024-08-21',
      clicks: 16
    },
    {
      date: '2024-08-22',
      clicks: 42
    },
    {
      date: '2024-08-23',
      clicks: 17
    },
    {
      date: '2024-08-24',
      clicks: 125
    },
    {
      date: '2024-08-25',
      clicks: 75
    },
    {
      date: '2024-08-26',
      clicks: 74
    },
    {
      date: '2024-08-27',
      clicks: 31
    },
    {
      date: '2024-08-28',
      clicks: 55
    },
    {
      date: '2024-08-29',
      clicks: 69
    },
    {
      date: '2024-08-30',
      clicks: 17
    },
    {
      date: '2024-08-31',
      clicks: 6
    },
    {
      date: '2024-09-01',
      clicks: 28
    },
    {
      date: '2024-09-02',
      clicks: 26
    },
    {
      date: '2024-09-03',
      clicks: 12
    },
    {
      date: '2024-09-04',
      clicks: 37
    },
    {
      date: '2024-09-05',
      clicks: 17
    },
    {
      date: '2024-09-06',
      clicks: 13
    },
    {
      date: '2024-09-07',
      clicks: 31
    },
    {
      date: '2024-09-08',
      clicks: 83
    },
    {
      date: '2024-09-09',
      clicks: 43
    },
    {
      date: '2024-09-10',
      clicks: 33
    },
    {
      date: '2024-09-11',
      clicks: 32
    },
    {
      date: '2024-09-12',
      clicks: 21
    },
    {
      date: '2024-09-13',
      clicks: 2
    },
    {
      date: '2024-09-14',
      clicks: 1
    },
    {
      date: '2024-09-15',
      clicks: 3
    },
    {
      date: '2024-09-16',
      clicks: 2
    },
    {
      date: '2024-09-17',
      clicks: 1
    },
    {
      date: '2024-09-18',
      clicks: 3
    },
    {
      date: '2024-09-19',
      clicks: 2
    },
    {
      date: '2024-09-20',
      clicks: 2
    },
    {
      date: '2024-09-21',
      clicks: 1
    },
    {
      date: '2024-09-22',
      clicks: 1
    },
    {
      date: '2024-09-23',
      clicks: 1
    },
    {
      date: '2024-09-25',
      clicks: 2
    },
    {
      date: '2024-09-28',
      clicks: 1
    },
    {
      date: '2024-09-30',
      clicks: 1
    },
    {
      date: '2024-10-01',
      clicks: 2
    },
    {
      date: '2024-10-05',
      clicks: 1
    },
    {
      date: '2024-10-06',
      clicks: 2
    },
    {
      date: '2024-10-08',
      clicks: 1
    }
  ]
}

import ContentIcon from '@momentum/components/icons/content'
import { Stack, Typography } from '@mui/material'

const EmptyState = () => {
  return (
    <Stack alignSelf={'center'} alignItems={'center'} spacing={3} pt={12}>
      <ContentIcon
        sx={{
          width: 202,
          height: 189
        }}
      />
      <Typography variant='h5'>You have no content to review!</Typography>
      <Typography variant='label4'>Content that you need to approve will appear here.</Typography>
    </Stack>
  )
}

export default EmptyState

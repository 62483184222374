import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { ZeusInterceptor } from '../types'
import { STORAGE } from '@momentum/api/interceptors/storage'
import { merge } from 'lodash'

export const updateProposal: ZeusInterceptor<'mutation', 'updateProposal'> = async args => {
  const params = args.o.updateProposal?.[0] as { input: ValueTypes['ModelInputUpdateProposal'] }
  const sampleProposal = STORAGE.proposals[params.input.id!]
  if (sampleProposal) {
    STORAGE.proposals[params.input.id!] = merge(sampleProposal, params.input)

    return STORAGE.proposals[params.input.id!]
  }
}

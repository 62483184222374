import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { ZeusInterceptor } from '../types'
import { STORAGE } from '@momentum/api/interceptors/storage'
import { v4 } from 'uuid'
import { SAMPLE_BRANDS } from '@momentum/api/interceptors/getBrand/sample-data'
import { SAMPLE_PRODUCTS_BY_ID } from '@momentum/api/interceptors/listProductsByBrandId/sample-data'
import { getSampleBrand } from '@momentum/api/interceptors/utils'

export const createProposal: ZeusInterceptor<'mutation', 'createProposal'> = async args => {
  const params = args.o.createProposal?.[0] as { input: ModelTypes['ModelInputCreateProposal'] }

  const sampleBrand = getSampleBrand(params.input.brandId)
  if (sampleBrand) {
    const { input } = params
    const id = input.id || v4()
    const now = new Date().toISOString()
    STORAGE.proposals[id] = {
      ...input,
      creatorPricing: input.creatorPricing || [],
      id,
      createdAt: input.createdAt || now,
      updatedAt: now,
      brand: SAMPLE_BRANDS[input.brandId],
      product: SAMPLE_PRODUCTS_BY_ID[input.productId]
    }

    return STORAGE.proposals[id]
  }
}

import { yupResolver } from '@hookform/resolvers/yup'
import { BrandNameInput } from '@momentum/forms/brand-form/BrandNameInput'
import { ImageSelect } from '@momentum/components/image-select'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { BRAND_API_TYPE_BY_REGION, BRAND_API_TYPE_LABELS } from '@momentum/utils/brandApiUtils'
import { getImageKeyFromUrl } from '@momentum/utils/imageUtils'
import { REGION_OPTIONS } from '@momentum/utils/selectOptions'
import { Grid, Stack, Typography } from '@mui/material'
import { BrandApiType, Region, UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'
import { CheckInput, Form, SelectInput, SubmitButton, SwitchInput } from '@productwindtom/ui-base'
import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Brand } from '../queries'
import { updateBrand } from './queries'
import { AudioSelect } from '@momentum/components/audio-select'
import { notEmpty } from '@productwindtom/shared-node'
import { captureException } from '@sentry/react'

type BrandInformationFormValues = {
  name: string
  logo?: string | null
  nameAudioKey?: string | null
  region: Region
  enabledBrandApiTypes: BrandApiType[]
}

const schema = (existingBrandNames: string[]) =>
  yup.object({
    name: yup
      .string()
      .required('Required')
      .test(
        'unique',
        'Brand with this name already exists',
        value => !value.trim() || !existingBrandNames.includes(value.toLowerCase().trim())
      ),
    nameAudioKey: yup
      .string()
      .optional()
      .nullable()
      .transform(s => (s ? getImageKeyFromUrl(s) : s)),
    logo: yup
      .string()
      .optional()
      .nullable()
      .transform(s => (s ? getImageKeyFromUrl(s) : s)),
    region: yup.string().oneOf(Object.values(Region)).required(),
    enabledBrandApiTypes: yup
      .array()
      .of(yup.string().oneOf(Object.values(BrandApiType)).required())
      .required()
  })

export const BrandInformation = ({ brand, readOnly }: { brand: Brand; readOnly: boolean }) => {
  const { brands, refreshBrand } = useUserSessionContext()

  const existingBrandNames = brands.filter(b => b.id !== brand.id).map(b => b.name.toLowerCase().trim())

  const defaultValues = useMemo(
    () => ({
      name: brand.name,
      logo: brand.logo,
      nameAudioKey: brand.nameAudioKey,
      region: brand.region,
      isBrandStoreScrapeEnabled: brand.isBrandStoreScrapeEnabled,
      enabledBrandApiTypes: brand.brandApis
        .filter(brandApi => brandApi.enabled && BRAND_API_TYPE_BY_REGION[brand.region].includes(brandApi.api))
        .map(brandApi => brandApi.api)
        .filter(notEmpty)
    }),
    [brand]
  )

  const onSubmit = async (submitValues: BrandInformationFormValues) => {
    try {
      await updateBrand({ id: brand.id, ...submitValues })
    } catch (error) {
      captureException(error)
      toast(<Typography variant={'subtitle2'}>Failed to save brand information!</Typography>, { type: 'error' })
    }
    await refreshBrand(brand.id)
  }

  return (
    <Form defaultValues={defaultValues} resolver={yupResolver(schema(existingBrandNames))} onSubmit={onSubmit}>
      <BrandInformationForm brand={brand} defaultValues={defaultValues} readOnly={readOnly} />
    </Form>
  )
}

const BrandInformationForm = ({
  brand,
  defaultValues,
  readOnly
}: {
  brand: Brand
  defaultValues: BrandInformationFormValues
  readOnly?: boolean
}) => {
  const { brands, isAdminView } = useUserSessionContext()
  const { reset } = useFormContext()
  const isBrandStoreScrapeEnabledWatch = useWatch({ name: 'isBrandStoreScrapeEnabled' })

  useEffect(() => reset(defaultValues), [brand])

  const existingBrandNames = brands.filter(b => b.id !== brand.id).map(b => b.name.toLowerCase().trim())

  return (
    <Stack spacing={2}>
      <Typography variant={'h4'}>Brand information</Typography>

      <Stack border={1} borderRadius={'4px'} borderColor={'#EAEAEA'} p={3}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={4}>
            <Stack style={{ maxWidth: '300px' }}>
              <Typography variant={'label2'}>Brand name</Typography>
              {!readOnly ? (
                <BrandNameInput name={'name'} existingBrandNames={existingBrandNames} primaryText="" placeholder="" />
              ) : (
                <Typography variant={'label4'}>{defaultValues.name}</Typography>
              )}
            </Stack>
            <Stack style={{ maxWidth: '300px' }} mt={3}>
              <Typography variant={'label2'}>Brand region</Typography>
              {!readOnly ? (
                <SelectInput options={REGION_OPTIONS} name={'region'} />
              ) : (
                <Typography variant={'label4'}>{defaultValues.region}</Typography>
              )}
            </Stack>
            <Stack style={{ maxWidth: '300px' }} mt={3}>
              <ImageSelect
                name={'logo'}
                readOnly={readOnly}
                brandId={brand.id}
                primaryText={'Brand logo'}
                buttonText={'Click to upload'}
                replacementText={'Click to replace'}
                buttonProps={{
                  variant: 'outlined'
                }}
                uploadType={UploadRequestType.UPLOAD_BRAND_LOGO}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack maxWidth={'500px'} spacing={3}>
              {isAdminView && (
                <Stack spacing={1}>
                  <Typography variant={'label2'}>Weekly products and recommendations</Typography>
                  <Typography variant={'label4'}>
                    Turn on weekly products & recommendations ONLY for Enterprise clients and active prospects. Go to
                    the recommendations page if you need to immediately refresh your recommendations.
                  </Typography>
                  <Stack direction={'row'} alignItems={'center'}>
                    <SwitchInput name="isBrandStoreScrapeEnabled" />
                    <Typography variant={'body1'}>
                      Weekly products & recommendations {isBrandStoreScrapeEnabledWatch ? 'on' : 'off'}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              <Stack>
                <Typography variant={'label2'}>Retailer integrations</Typography>
                <Typography variant={'label4'}>
                  Momentum will show 1P brand recommendations if you select “Amazon vendor” and 3P recommendations if
                  you select “Amazon seller.”
                </Typography>
                {isAdminView ? (
                  <CheckInput
                    options={Object.values(BRAND_API_TYPE_BY_REGION[brand.region]).map(apiType => ({
                      value: apiType,
                      label: BRAND_API_TYPE_LABELS[apiType]
                    }))}
                    name={'enabledBrandApiTypes'}
                    disabled={!isAdminView}
                  />
                ) : (
                  Object.values(BRAND_API_TYPE_BY_REGION[brand.region])
                    .filter(apiType => defaultValues.enabledBrandApiTypes.find(a => a === apiType))
                    .map(apiType => (
                      <Typography key={apiType} variant={'label4'}>
                        {BRAND_API_TYPE_LABELS[apiType]}
                      </Typography>
                    ))
                )}
              </Stack>
              <Stack spacing={1}>
                <Typography variant={'label2'}>Brand name pronunciation</Typography>
                <AudioSelect
                  uploadType={UploadRequestType.UPLOAD_BRAND_AUDIO}
                  brandId={brand.id}
                  name={'nameAudioKey'}
                  primaryText={'Add an audio clip of someone pronouncing the brand correctly'}
                  readOnly={readOnly}
                  buttonProps={{
                    variant: 'outlined'
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        {!readOnly && (
          <SubmitButton
            data-cy={'saveBrandInformationButton'}
            variant={'contained'}
            style={{
              alignSelf: 'start',
              marginTop: '24px'
            }}
          >
            Save brand information
          </SubmitButton>
        )}
      </Stack>
    </Stack>
  )
}

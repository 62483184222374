// @ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_REVIEW_METRICS_HEADPHONES: Array<ModelTypes['CampaignProductReviewRatingMetric']> = [
  {
    "fromDate": "2023-12-01",
    "toDate": "2023-12-02",
    "numRatings": 0,
    "numReviews": 0,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 0,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-02",
    "toDate": "2023-12-03",
    "numRatings": 0,
    "numReviews": 0,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.8,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [
      [
        null
      ]
    ],
    "rating": 4,
    "momentumRating": 4,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-03",
    "toDate": "2023-12-04",
    "numRatings": 1,
    "numReviews": 1,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-04",
    "toDate": "2023-12-05",
    "numRatings": 8,
    "numReviews": 8,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 2,
    "reviews": [],
    "rating": 4,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 3.7142857142857144
  },
  {
    "fromDate": "2023-12-05",
    "toDate": "2023-12-06",
    "numRatings": 12,
    "numReviews": 12,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 1,
    "momentumReviewRating": 4.866666666666667,
    "organicRatingCount": 0,
    "reviewsInPeriod": 4,
    "reviews": [
      [
        null
      ]
    ],
    "rating": 4,
    "momentumRating": 4.866666666666667,
    "organicRating": 0,
    "vineRating": 5
  },
  {
    "fromDate": "2023-12-06",
    "toDate": "2023-12-07",
    "numRatings": 15,
    "numReviews": 15,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 4,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 4,
    "reviews": [],
    "rating": 4.2,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-07",
    "toDate": "2023-12-08",
    "numRatings": 16,
    "numReviews": 16,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 1,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-08",
    "toDate": "2023-12-09",
    "numRatings": 17,
    "numReviews": 16,
    "ratingsInPeriod": 1,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 4.9,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-09",
    "toDate": "2023-12-10",
    "numRatings": 17,
    "numReviews": 16,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-10",
    "toDate": "2023-12-11",
    "numRatings": 17,
    "numReviews": 16,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-11",
    "toDate": "2023-12-12",
    "numRatings": 18,
    "numReviews": 20,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 1,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 4,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 4
  },
  {
    "fromDate": "2023-12-12",
    "toDate": "2023-12-13",
    "numRatings": 19,
    "numReviews": 21,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 3,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 4,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-13",
    "toDate": "2023-12-14",
    "numRatings": 19,
    "numReviews": 22,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 3,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-14",
    "toDate": "2023-12-15",
    "numRatings": 20,
    "numReviews": 23,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 3,
    "momentumReviewRating": 4.8,
    "organicRatingCount": 1,
    "reviewsInPeriod": 4,
    "reviews": [
      [
        null
      ]
    ],
    "rating": 4.2,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-15",
    "toDate": "2023-12-16",
    "numRatings": 20,
    "numReviews": 23,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 3,
    "reviews": [],
    "rating": 4.2,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-16",
    "toDate": "2023-12-17",
    "numRatings": 22,
    "numReviews": 23,
    "ratingsInPeriod": 2,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 3,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 3,
    "reviewsInPeriod": 3,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 4.9,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-17",
    "toDate": "2023-12-18",
    "numRatings": 22,
    "numReviews": 25,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 3,
    "reviews": [],
    "rating": 4.2,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-18",
    "toDate": "2023-12-19",
    "numRatings": 23,
    "numReviews": 26,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 3,
    "momentumReviewRating": 5,
    "organicRatingCount": 1,
    "reviewsInPeriod": 4,
    "reviews": [
      [
        null
      ]
    ],
    "rating": 4.2,
    "momentumRating": 5,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-19",
    "toDate": "2023-12-20",
    "numRatings": 28,
    "numReviews": 30,
    "ratingsInPeriod": 1,
    "momentumRatingCount": 1,
    "vineReviewCount": 1,
    "momentumReviewCount": 5,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 2,
    "reviewsInPeriod": 7,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 5,
    "vineRating": 4
  },
  {
    "fromDate": "2023-12-20",
    "toDate": "2023-12-21",
    "numRatings": 28,
    "numReviews": 31,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 3,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-21",
    "toDate": "2023-12-22",
    "numRatings": 31,
    "numReviews": 33,
    "ratingsInPeriod": 1,
    "momentumRatingCount": 1,
    "vineReviewCount": 1,
    "momentumReviewCount": 3,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 2,
    "reviewsInPeriod": 5,
    "reviews": [],
    "rating": 4.4,
    "momentumRating": 4.9,
    "organicRating": 5,
    "vineRating": 2
  },
  {
    "fromDate": "2023-12-22",
    "toDate": "2023-12-23",
    "numRatings": 31,
    "numReviews": 34,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 3,
    "reviews": [],
    "rating": 4.4,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-23",
    "toDate": "2023-12-24",
    "numRatings": 33,
    "numReviews": 36,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 1,
    "momentumReviewCount": 3,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 5,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 2
  },
  {
    "fromDate": "2023-12-24",
    "toDate": "2023-12-25",
    "numRatings": 33,
    "numReviews": 36,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 3,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-25",
    "toDate": "2023-12-26",
    "numRatings": 33,
    "numReviews": 33,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 1,
    "vineReviewCount": 0,
    "momentumReviewCount": 3,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 3,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-26",
    "toDate": "2023-12-27",
    "numRatings": 33,
    "numReviews": 33,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-27",
    "toDate": "2023-12-28",
    "numRatings": 33,
    "numReviews": 33,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 1,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 1,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-28",
    "toDate": "2023-12-29",
    "numRatings": 34,
    "numReviews": 34,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 1,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 1,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2023-12-29",
    "toDate": "2023-12-30",
    "numRatings": 35,
    "numReviews": 35,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 1,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 1,
    "reviews": [],
    "rating": 4.2,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 5
  },
  {
    "fromDate": "2023-12-30",
    "toDate": "2023-12-31",
    "numRatings": 37,
    "numReviews": 37,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 1,
    "momentumReviewCount": 1,
    "momentumReviewRating": 5,
    "organicRatingCount": 0,
    "reviewsInPeriod": 2,
    "reviews": [
      [
        null
      ]
    ],
    "rating": 4.9,
    "momentumRating": 5,
    "organicRating": 0,
    "vineRating": 5
  },
  {
    "fromDate": "2023-12-31",
    "toDate": "2024-01-01",
    "numRatings": 39,
    "numReviews": 39,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 2,
    "reviews": [
      [
        null
      ]
    ],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-01",
    "toDate": "2024-01-02",
    "numRatings": 39,
    "numReviews": 39,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-02",
    "toDate": "2024-01-03",
    "numRatings": 40,
    "numReviews": 39,
    "ratingsInPeriod": 1,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 4.9,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-03",
    "toDate": "2024-01-04",
    "numRatings": 40,
    "numReviews": 39,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-04",
    "toDate": "2024-01-05",
    "numRatings": 40,
    "numReviews": 39,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-05",
    "toDate": "2024-01-06",
    "numRatings": 41,
    "numReviews": 40,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 1,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 1,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 2
  },
  {
    "fromDate": "2024-01-06",
    "toDate": "2024-01-07",
    "numRatings": 42,
    "numReviews": 41,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 1,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 1,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 5
  },
  {
    "fromDate": "2024-01-07",
    "toDate": "2024-01-08",
    "numRatings": 42,
    "numReviews": 42,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-08",
    "toDate": "2024-01-09",
    "numRatings": 44,
    "numReviews": 44,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 2,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 2,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-09",
    "toDate": "2024-01-10",
    "numRatings": 45,
    "numReviews": 44,
    "ratingsInPeriod": 1,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 4.9,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-10",
    "toDate": "2024-01-11",
    "numRatings": 46,
    "numReviews": 44,
    "ratingsInPeriod": 1,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 4.9,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-11",
    "toDate": "2024-01-12",
    "numRatings": 46,
    "numReviews": 44,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-12",
    "toDate": "2024-01-13",
    "numRatings": 47,
    "numReviews": 45,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 1,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 1,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-13",
    "toDate": "2024-01-14",
    "numRatings": 47,
    "numReviews": 45,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-14",
    "toDate": "2024-01-15",
    "numRatings": 47,
    "numReviews": 45,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-15",
    "toDate": "2024-01-16",
    "numRatings": 50,
    "numReviews": 45,
    "ratingsInPeriod": 1,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 4.9,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-16",
    "toDate": "2024-01-17",
    "numRatings": 50,
    "numReviews": 45,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-17",
    "toDate": "2024-01-18",
    "numRatings": 50,
    "numReviews": 45,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-18",
    "toDate": "2024-01-19",
    "numRatings": 50,
    "numReviews": 45,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-19",
    "toDate": "2024-01-20",
    "numRatings": 50,
    "numReviews": 45,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-20",
    "toDate": "2024-01-21",
    "numRatings": 50,
    "numReviews": 45,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-21",
    "toDate": "2024-01-22",
    "numRatings": 50,
    "numReviews": 46,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 1,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 1,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 4
  },
  {
    "fromDate": "2024-01-22",
    "toDate": "2024-01-23",
    "numRatings": 50,
    "numReviews": 46,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-23",
    "toDate": "2024-01-24",
    "numRatings": 50,
    "numReviews": 46,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-24",
    "toDate": "2024-01-25",
    "numRatings": 50,
    "numReviews": 47,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 1,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-25",
    "toDate": "2024-01-26",
    "numRatings": 50,
    "numReviews": 47,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.8,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-26",
    "toDate": "2024-01-27",
    "numRatings": 51,
    "numReviews": 50,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 2,
    "reviews": [],
    "rating": 4.9,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-27",
    "toDate": "2024-01-28",
    "numRatings": 52,
    "numReviews": 52,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.4,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-28",
    "toDate": "2024-01-29",
    "numRatings": 53,
    "numReviews": 52,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 0,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.4,
    "momentumRating": 4.9,
    "organicRating": 0,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-29",
    "toDate": "2024-01-30",
    "numRatings": 56,
    "numReviews": 53,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.2,
    "momentumRating": 4.9,
    "organicRating": 5,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-30",
    "toDate": "2024-01-31",
    "numRatings": 70,
    "numReviews": 64,
    "ratingsInPeriod": 3,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 14,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.4,
    "momentumRating": 4.8,
    "organicRating": 4.4,
    "vineRating": 0
  },
  {
    "fromDate": "2024-01-31",
    "toDate": "2024-02-01",
    "numRatings": 72,
    "numReviews": 66,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 2,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.4,
    "momentumRating": 4.8,
    "organicRating": 4.4,
    "vineRating": 0
  },
  {
    "fromDate": "2024-02-01",
    "toDate": "2024-02-02",
    "numRatings": 73,
    "numReviews": 67,
    "ratingsInPeriod": 0,
    "momentumRatingCount": 0,
    "vineReviewCount": 0,
    "momentumReviewCount": 0,
    "momentumReviewRating": 4.9,
    "organicRatingCount": 1,
    "reviewsInPeriod": 0,
    "reviews": [],
    "rating": 4.4,
    "momentumRating": 4.8,
    "organicRating": 4.4,
    "vineRating": 0
  }
]

import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { ZeusInterceptor } from '../types'
import { STORAGE } from '@momentum/api/interceptors/storage'

export const deleteProposal: ZeusInterceptor<'mutation', 'deleteProposal'> = async args => {
  const params = args.o.deleteProposal?.[0] as { input: ModelTypes['ModelInputDeleteProposal'] }

  const sampleProposal = STORAGE.proposals[params.input.id]
  if (sampleProposal) {
    delete STORAGE.proposals[params.input.id]

    return sampleProposal
  }
}

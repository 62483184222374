import { ProductKeywordSummary } from '@momentum/routes/campaign/e-commerce/seoV2/context/selectors'
import { SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING } from '@momentum/api/interceptors/getCampaign/sample-data/energy-drink-pd-existing'

export const SAMPLE_CAMPAIGN_KEYWORDS_ENERGY_DRINK_PD_EXISTING: { items: ProductKeywordSummary[] } = {
  items: [
    {
      campaignId: SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id,
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_3d energy drink`,
      keyword: '3d energy drink',
      searchVolume: 2550,
      organicStartRank: 7,
      organicHighestRank: 7,
      sponsoredStartRank: 43,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-07-03',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 1778,
          organicRank: 34,
          sponsoredRank: 30,
          keywordSales: 86
        },
        {
          date: '2024-08-05',
          searchVolume: 1778,
          organicRank: 34,

          keywordSales: 86
        },
        {
          date: '2024-08-04',
          searchVolume: 1778,
          organicRank: 34,

          keywordSales: 86
        },
        {
          date: '2024-08-03',
          searchVolume: 1778,
          organicRank: 48,

          keywordSales: 86
        },
        {
          date: '2024-08-02',
          searchVolume: 1778,
          organicRank: 30,

          keywordSales: 86
        },
        {
          date: '2024-08-01',
          searchVolume: 1778,
          organicRank: 49,

          keywordSales: 86
        },
        {
          date: '2024-07-31',
          searchVolume: 1778,
          organicRank: 14,

          keywordSales: 86
        },
        {
          date: '2024-07-30',
          searchVolume: 1778,
          organicRank: 23,

          keywordSales: 86
        },
        {
          date: '2024-07-29',
          searchVolume: 1778,
          organicRank: 17,

          keywordSales: 86
        },
        {
          date: '2024-07-28',
          searchVolume: 1778,
          organicRank: 34,

          keywordSales: 86
        },
        {
          date: '2024-07-27',
          searchVolume: 1778,
          organicRank: 40,

          keywordSales: 86
        },
        {
          date: '2024-07-26',
          searchVolume: 2433,
          organicRank: 20,

          keywordSales: 86
        },
        {
          date: '2024-07-25',
          searchVolume: 2433,
          organicRank: 24,

          keywordSales: 86
        },
        {
          date: '2024-07-24',
          searchVolume: 2433,
          organicRank: 42,

          keywordSales: 86
        },
        {
          date: '2024-07-23',
          searchVolume: 2433,
          organicRank: 51,
          sponsoredRank: 4,
          keywordSales: 86
        },
        {
          date: '2024-07-22',
          searchVolume: 2433,
          organicRank: 19,

          keywordSales: 86
        },
        {
          date: '2024-07-21',
          searchVolume: 2433,
          organicRank: 23,

          keywordSales: 86
        },
        {
          date: '2024-07-20',
          searchVolume: 2433,
          organicRank: 21,
          sponsoredRank: 29,
          keywordSales: 86
        },
        {
          date: '2024-07-19',
          searchVolume: 2245,
          organicRank: 17,
          sponsoredRank: 24,
          keywordSales: 86
        },
        {
          date: '2024-07-18',
          searchVolume: 2245,
          organicRank: 21,

          keywordSales: 86
        },
        {
          date: '2024-07-17',
          searchVolume: 2245,
          organicRank: 25,
          sponsoredRank: 4,
          keywordSales: 86
        },
        {
          date: '2024-07-16',
          searchVolume: 2245,
          organicRank: 24,

          keywordSales: 86
        },
        {
          date: '2024-07-15',
          searchVolume: 2245,
          organicRank: 21,

          keywordSales: 86
        },
        {
          date: '2024-07-14',
          searchVolume: 2245,
          organicRank: 21,

          keywordSales: 86
        },
        {
          date: '2024-07-13',
          searchVolume: 2245,
          organicRank: 38,

          keywordSales: 86
        },
        {
          date: '2024-07-12',
          searchVolume: 2078,
          organicRank: 22,

          keywordSales: 86
        },
        {
          date: '2024-07-11',
          searchVolume: 2078,
          organicRank: 28,

          keywordSales: 86
        },
        {
          date: '2024-07-10',
          searchVolume: 2078,
          organicRank: 28,

          keywordSales: 86
        },
        {
          date: '2024-07-09',
          searchVolume: 2078,
          organicRank: 31,

          keywordSales: 86
        },
        {
          date: '2024-07-08',
          searchVolume: 2078,
          organicRank: 18,

          keywordSales: 86
        },
        {
          date: '2024-07-07',
          searchVolume: 2078,
          organicRank: 17,

          keywordSales: 86
        },
        {
          date: '2024-07-06',
          searchVolume: 2078,
          organicRank: 16,

          keywordSales: 86
        },
        {
          date: '2024-07-05',
          searchVolume: 2432,
          organicRank: 18,

          keywordSales: 86
        },
        {
          date: '2024-07-04',
          searchVolume: 2432,
          organicRank: 17,

          keywordSales: 86
        },
        {
          date: '2024-07-03',
          searchVolume: 2432,
          organicRank: 12,

          keywordSales: 86
        },
        {
          date: '2024-07-02',
          searchVolume: 2432,
          organicRank: 28,

          keywordSales: 86
        },
        {
          date: '2024-07-01',
          searchVolume: 2432,
          organicRank: 10,
          sponsoredRank: 10,
          keywordSales: 86
        },
        {
          date: '2024-06-30',
          searchVolume: 2432,
          organicRank: 20,

          keywordSales: 86
        },
        {
          date: '2024-06-29',
          searchVolume: 2432,
          organicRank: 16,

          keywordSales: 86
        },
        {
          date: '2024-06-28',
          searchVolume: 2539,
          organicRank: 11,

          keywordSales: 86
        },
        {
          date: '2024-06-27',
          searchVolume: 2539,
          organicRank: 20,
          sponsoredRank: 43,
          keywordSales: 86
        },
        {
          date: '2024-06-26',
          searchVolume: 2539,
          organicRank: 20,

          keywordSales: 86
        },
        {
          date: '2024-06-25',
          searchVolume: 2539,
          organicRank: 15,

          keywordSales: 86
        },
        {
          date: '2024-06-24',
          searchVolume: 2539,
          organicRank: 14,

          keywordSales: 86
        },
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 14,

          keywordSales: 86
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 20,

          keywordSales: 86
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 7,

          keywordSales: 86
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 13,

          keywordSales: 86
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_4c energy rush`,
      keyword: '4c energy rush',
      searchVolume: 6749,
      organicStartRank: 37,
      organicHighestRank: 36,
      sponsoredStartRank: 8,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 5359,
          organicRank: 41,

          keywordSales: 287
        },
        {
          date: '2024-08-05',
          searchVolume: 5359,
          organicRank: 46,

          keywordSales: 287
        },
        {
          date: '2024-08-04',
          searchVolume: 5359,
          organicRank: 47,

          keywordSales: 287
        },
        {
          date: '2024-08-03',
          searchVolume: 5359,
          organicRank: 43,

          keywordSales: 287
        },
        {
          date: '2024-08-02',
          searchVolume: 5359,
          organicRank: 46,

          keywordSales: 287
        },
        {
          date: '2024-08-01',
          searchVolume: 5359,
          organicRank: 42,

          keywordSales: 287
        },
        {
          date: '2024-07-31',
          searchVolume: 5359,
          organicRank: 47,

          keywordSales: 287
        },
        {
          date: '2024-07-30',
          searchVolume: 5359,
          organicRank: 37,

          keywordSales: 287
        },
        {
          date: '2024-07-29',
          searchVolume: 5359,
          organicRank: 41,

          keywordSales: 287
        },
        {
          date: '2024-07-28',
          searchVolume: 5359,
          organicRank: 45,

          keywordSales: 287
        },
        {
          date: '2024-07-27',
          searchVolume: 5359,
          organicRank: 51,

          keywordSales: 287
        },
        {
          date: '2024-07-26',
          searchVolume: 6445,
          organicRank: 44,

          keywordSales: 287
        },
        {
          date: '2024-07-25',
          searchVolume: 6445,
          organicRank: 55,

          keywordSales: 287
        },
        {
          date: '2024-07-24',
          searchVolume: 6445,
          organicRank: 46,

          keywordSales: 287
        },
        {
          date: '2024-07-23',
          searchVolume: 6445,
          organicRank: 41,

          keywordSales: 287
        },
        {
          date: '2024-07-22',
          searchVolume: 6445,
          organicRank: 41,

          keywordSales: 287
        },
        {
          date: '2024-07-21',
          searchVolume: 6445,
          organicRank: 39,

          keywordSales: 287
        },
        {
          date: '2024-07-20',
          searchVolume: 6445,
          organicRank: 38,
          sponsoredRank: 8,
          keywordSales: 287
        },
        {
          date: '2024-07-19',
          searchVolume: 6334,
          organicRank: 43,

          keywordSales: 287
        },
        {
          date: '2024-07-18',
          searchVolume: 6334,
          organicRank: 40,

          keywordSales: 287
        },
        {
          date: '2024-07-17',
          searchVolume: 6334,
          organicRank: 40,

          keywordSales: 287
        },
        {
          date: '2024-07-16',
          searchVolume: 6334,
          organicRank: 42,

          keywordSales: 287
        },
        {
          date: '2024-07-15',
          searchVolume: 6334,
          organicRank: 48,

          keywordSales: 287
        },
        {
          date: '2024-07-14',
          searchVolume: 6334,
          organicRank: 42,

          keywordSales: 287
        },
        {
          date: '2024-07-13',
          searchVolume: 6334,
          organicRank: 38,

          keywordSales: 287
        },
        {
          date: '2024-07-12',
          searchVolume: 5717,
          organicRank: 37,

          keywordSales: 287
        },
        {
          date: '2024-07-10',
          searchVolume: 5717,
          organicRank: 47,

          keywordSales: 287
        },
        {
          date: '2024-07-09',
          searchVolume: 5717,
          organicRank: 42,

          keywordSales: 287
        },
        {
          date: '2024-07-08',
          searchVolume: 5717,
          organicRank: 36,

          keywordSales: 287
        },
        {
          date: '2024-07-07',
          searchVolume: 5717,
          organicRank: 36,

          keywordSales: 287
        },
        {
          date: '2024-07-06',
          searchVolume: 5717,
          organicRank: 37,

          keywordSales: 287
        },
        {
          date: '2024-07-05',
          searchVolume: 6745,
          organicRank: 44,

          keywordSales: 287
        },
        {
          date: '2024-07-04',
          searchVolume: 6745,
          organicRank: 43,

          keywordSales: 287
        },
        {
          date: '2024-07-03',
          searchVolume: 6745,
          organicRank: 44,

          keywordSales: 287
        },
        {
          date: '2024-07-02',
          searchVolume: 6745,
          organicRank: 40,

          keywordSales: 287
        },
        {
          date: '2024-07-01',
          searchVolume: 6745,
          organicRank: 43,

          keywordSales: 287
        },
        {
          date: '2024-06-30',
          searchVolume: 6745,
          organicRank: 36,

          keywordSales: 287
        },
        {
          date: '2024-06-29',
          searchVolume: 6745,
          organicRank: 36,

          keywordSales: 287
        },
        {
          date: '2024-06-28',
          searchVolume: 6727,
          organicRank: 38,

          keywordSales: 287
        },
        {
          date: '2024-06-27',
          searchVolume: 6727,
          organicRank: 37,

          keywordSales: 287
        },
        {
          date: '2024-06-26',
          searchVolume: 6727,
          organicRank: 38,

          keywordSales: 287
        },
        {
          date: '2024-06-25',
          searchVolume: 6727,
          organicRank: 39,

          keywordSales: 287
        },
        {
          date: '2024-06-23',
          searchVolume: 6727,
          organicRank: 37,

          keywordSales: 287
        },
        {
          date: '2024-06-21',
          searchVolume: 6749,
          organicRank: 36,
          sponsoredRank: 8,
          keywordSales: 287
        },
        {
          date: '2024-06-20',
          searchVolume: 6749,
          organicRank: 37,

          keywordSales: 287
        },
        {
          date: '2024-06-19',
          searchVolume: 6749,
          organicRank: 37,

          keywordSales: 287
        },
        {
          date: '2024-06-18',
          searchVolume: 6749,
          organicRank: 39,

          keywordSales: 287
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_accelerator active energy drink`,
      keyword: 'accelerator active energy drink',
      searchVolume: 8482,
      organicStartRank: 52,
      organicHighestRank: 16,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-07-26',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-15',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 3991,
          organicRank: 23,

          keywordSales: 218
        },
        {
          date: '2024-08-06',
          searchVolume: 3991,
          organicRank: 17,

          keywordSales: 218
        },
        {
          date: '2024-08-05',
          searchVolume: 3991,
          organicRank: 17,

          keywordSales: 218
        },
        {
          date: '2024-08-04',
          searchVolume: 3991,
          organicRank: 16,

          keywordSales: 218
        },
        {
          date: '2024-08-03',
          searchVolume: 3991,
          organicRank: 21,

          keywordSales: 218
        },
        {
          date: '2024-08-01',
          searchVolume: 3991,
          organicRank: 19,

          keywordSales: 218
        },
        {
          date: '2024-07-31',
          searchVolume: 3991,
          organicRank: 16,

          keywordSales: 218
        },
        {
          date: '2024-07-30',
          searchVolume: 3991,
          organicRank: 17,

          keywordSales: 218
        },
        {
          date: '2024-07-29',
          searchVolume: 3991,
          organicRank: 22,

          keywordSales: 218
        },
        {
          date: '2024-07-28',
          searchVolume: 3991,
          organicRank: 17,

          keywordSales: 218
        },
        {
          date: '2024-07-27',
          searchVolume: 3991,
          organicRank: 19,

          keywordSales: 218
        },
        {
          date: '2024-07-26',
          searchVolume: 5686,
          organicRank: 17,

          keywordSales: 218
        },
        {
          date: '2024-07-25',
          searchVolume: 5686,
          organicRank: 21,

          keywordSales: 218
        },
        {
          date: '2024-07-24',
          searchVolume: 5686,
          organicRank: 23,

          keywordSales: 218
        },
        {
          date: '2024-07-23',
          searchVolume: 5686,
          organicRank: 21,

          keywordSales: 218
        },
        {
          date: '2024-07-22',
          searchVolume: 5686,
          organicRank: 22,

          keywordSales: 218
        },
        {
          date: '2024-07-21',
          searchVolume: 5686,
          organicRank: 22,
          sponsoredRank: 23,
          keywordSales: 218
        },
        {
          date: '2024-07-20',
          searchVolume: 5686,
          organicRank: 22,
          sponsoredRank: 8,
          keywordSales: 218
        },
        {
          date: '2024-07-19',
          searchVolume: 4835,
          organicRank: 26,

          keywordSales: 218
        },
        {
          date: '2024-07-18',
          searchVolume: 4835,
          organicRank: 41,

          keywordSales: 218
        },
        {
          date: '2024-07-17',
          searchVolume: 4835,
          organicRank: 26,
          sponsoredRank: 2,
          keywordSales: 218
        },
        {
          date: '2024-07-16',
          searchVolume: 4835,
          organicRank: 28,

          keywordSales: 218
        },
        {
          date: '2024-07-15',
          searchVolume: 4835,
          organicRank: 28,
          sponsoredRank: 2,
          keywordSales: 218
        },
        {
          date: '2024-07-14',
          searchVolume: 4835,
          organicRank: 29,

          keywordSales: 218
        },
        {
          date: '2024-07-13',
          searchVolume: 4835,
          organicRank: 23,

          keywordSales: 218
        },
        {
          date: '2024-07-12',
          searchVolume: 6448,
          organicRank: 24,

          keywordSales: 218
        },
        {
          date: '2024-07-11',
          searchVolume: 6448,
          organicRank: 57,

          keywordSales: 218
        },
        {
          date: '2024-07-10',
          searchVolume: 6448,
          organicRank: 22,

          keywordSales: 218
        },
        {
          date: '2024-07-09',
          searchVolume: 6448,
          organicRank: 31,

          keywordSales: 218
        },
        {
          date: '2024-07-08',
          searchVolume: 6448,
          organicRank: 26,

          keywordSales: 218
        },
        {
          date: '2024-07-07',
          searchVolume: 6448,
          organicRank: 29,

          keywordSales: 218
        },
        {
          date: '2024-07-06',
          searchVolume: 6448,
          organicRank: 27,

          keywordSales: 218
        },
        {
          date: '2024-07-05',
          searchVolume: 6753,
          organicRank: 21,

          keywordSales: 218
        },
        {
          date: '2024-07-04',
          searchVolume: 6753,
          organicRank: 31,

          keywordSales: 218
        },
        {
          date: '2024-07-03',
          searchVolume: 6753,
          organicRank: 23,

          keywordSales: 218
        },
        {
          date: '2024-07-02',
          searchVolume: 6753,
          organicRank: 22,

          keywordSales: 218
        },
        {
          date: '2024-07-01',
          searchVolume: 6753,
          organicRank: 23,

          keywordSales: 218
        },
        {
          date: '2024-06-30',
          searchVolume: 6753,
          organicRank: 22,

          keywordSales: 218
        },
        {
          date: '2024-06-29',
          searchVolume: 6753,
          organicRank: 36,

          keywordSales: 218
        },
        {
          date: '2024-06-28',
          searchVolume: 6730,
          organicRank: 24,

          keywordSales: 218
        },
        {
          date: '2024-06-27',
          searchVolume: 6730,
          organicRank: 26,

          keywordSales: 218
        },
        {
          date: '2024-06-26',
          searchVolume: 6730,
          organicRank: 23,

          keywordSales: 218
        },
        {
          date: '2024-06-25',
          searchVolume: 6730,
          organicRank: 25,

          keywordSales: 218
        },
        {
          date: '2024-06-24',
          searchVolume: 6730,
          organicRank: 23,

          keywordSales: 218
        },
        {
          date: '2024-06-21',
          searchVolume: 8482,
          organicRank: 26,

          keywordSales: 218
        },
        {
          date: '2024-06-20',
          searchVolume: 8482,
          organicRank: 51,

          keywordSales: 218
        },
        {
          date: '2024-06-19',
          searchVolume: 8482,
          organicRank: 52,

          keywordSales: 218
        },
        {
          date: '2024-06-18',
          searchVolume: 8482,
          organicRank: 49,

          keywordSales: 218
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_alani energy drink`,
      keyword: 'alani energy drink',
      searchVolume: 74433,
      organicStartRank: 48,
      organicHighestRank: 44,
      sponsoredStartRank: 25,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-06',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 59630,
          organicRank: 86,

          keywordSales: 1395
        },
        {
          date: '2024-08-06',
          searchVolume: 59630,
          organicRank: 143,

          keywordSales: 1395
        },
        {
          date: '2024-08-05',
          searchVolume: 59630,
          organicRank: 153,

          keywordSales: 1395
        },
        {
          date: '2024-08-04',
          searchVolume: 59630,
          organicRank: 105,

          keywordSales: 1395
        },
        {
          date: '2024-08-02',
          searchVolume: 59630,
          organicRank: 113,

          keywordSales: 1395
        },
        {
          date: '2024-08-01',
          searchVolume: 59630,
          organicRank: 71,

          keywordSales: 1395
        },
        {
          date: '2024-07-31',
          searchVolume: 59630,
          organicRank: 56,

          keywordSales: 1395
        },
        {
          date: '2024-07-30',
          searchVolume: 59630,
          organicRank: 54,

          keywordSales: 1395
        },
        {
          date: '2024-07-29',
          searchVolume: 59630,
          organicRank: 52,

          keywordSales: 1395
        },
        {
          date: '2024-07-28',
          searchVolume: 59630,
          organicRank: 70,

          keywordSales: 1395
        },
        {
          date: '2024-07-27',
          searchVolume: 59630,
          organicRank: 54,

          keywordSales: 1395
        },
        {
          date: '2024-07-26',
          searchVolume: 148712,
          organicRank: 51,

          keywordSales: 1395
        },
        {
          date: '2024-07-25',
          searchVolume: 148712,
          organicRank: 47,

          keywordSales: 1395
        },
        {
          date: '2024-07-24',
          searchVolume: 148712,
          organicRank: 52,

          keywordSales: 1395
        },
        {
          date: '2024-07-23',
          searchVolume: 148712,
          organicRank: 47,

          keywordSales: 1395
        },
        {
          date: '2024-07-22',
          searchVolume: 148712,
          organicRank: 48,

          keywordSales: 1395
        },
        {
          date: '2024-07-21',
          searchVolume: 148712,
          organicRank: 48,

          keywordSales: 1395
        },
        {
          date: '2024-07-20',
          searchVolume: 148712,
          organicRank: 57,

          keywordSales: 1395
        },
        {
          date: '2024-07-19',
          searchVolume: 69830,
          organicRank: 306,

          keywordSales: 1395
        },
        {
          date: '2024-07-18',
          searchVolume: 69830,
          organicRank: 83,

          keywordSales: 1395
        },
        {
          date: '2024-07-17',
          searchVolume: 69830,
          organicRank: 56,

          keywordSales: 1395
        },
        {
          date: '2024-07-16',
          searchVolume: 69830,
          organicRank: 48,

          keywordSales: 1395
        },
        {
          date: '2024-07-15',
          searchVolume: 69830,
          organicRank: 47,

          keywordSales: 1395
        },
        {
          date: '2024-07-14',
          searchVolume: 69830,
          organicRank: 66,

          keywordSales: 1395
        },
        {
          date: '2024-07-13',
          searchVolume: 69830,
          organicRank: 79,

          keywordSales: 1395
        },
        {
          date: '2024-07-12',
          searchVolume: 61103,
          organicRank: 75,

          keywordSales: 1395
        },
        {
          date: '2024-07-11',
          searchVolume: 61103,
          organicRank: 58,

          keywordSales: 1395
        },
        {
          date: '2024-07-10',
          searchVolume: 61103,
          organicRank: 49,

          keywordSales: 1395
        },
        {
          date: '2024-07-09',
          searchVolume: 61103,
          organicRank: 52,

          keywordSales: 1395
        },
        {
          date: '2024-07-08',
          searchVolume: 61103,
          organicRank: 76,

          keywordSales: 1395
        },
        {
          date: '2024-07-07',
          searchVolume: 61103,
          organicRank: 77,

          keywordSales: 1395
        },
        {
          date: '2024-07-06',
          searchVolume: 61103,
          organicRank: 76,
          sponsoredRank: 2,
          keywordSales: 1395
        },
        {
          date: '2024-07-05',
          searchVolume: 71132,
          organicRank: 49,

          keywordSales: 1395
        },
        {
          date: '2024-07-04',
          searchVolume: 71132,
          organicRank: 53,

          keywordSales: 1395
        },
        {
          date: '2024-07-03',
          searchVolume: 71132,
          organicRank: 76,

          keywordSales: 1395
        },
        {
          date: '2024-07-02',
          searchVolume: 71132,
          organicRank: 80,

          keywordSales: 1395
        },
        {
          date: '2024-07-01',
          searchVolume: 71132,
          organicRank: 48,

          keywordSales: 1395
        },
        {
          date: '2024-06-30',
          searchVolume: 71132,
          organicRank: 44,

          keywordSales: 1395
        },
        {
          date: '2024-06-29',
          searchVolume: 71132,
          organicRank: 62,

          keywordSales: 1395
        },
        {
          date: '2024-06-28',
          searchVolume: 74193,
          organicRank: 46,

          keywordSales: 1395
        },
        {
          date: '2024-06-27',
          searchVolume: 74193,
          organicRank: 45,

          keywordSales: 1395
        },
        {
          date: '2024-06-25',
          searchVolume: 74193,
          organicRank: 67,

          keywordSales: 1395
        },
        {
          date: '2024-06-24',
          searchVolume: 74193,
          organicRank: 61,

          keywordSales: 1395
        },
        {
          date: '2024-06-23',
          searchVolume: 74193,
          organicRank: 46,

          keywordSales: 1395
        },
        {
          date: '2024-06-21',
          searchVolume: 74433,
          organicRank: 44,
          sponsoredRank: 25,
          keywordSales: 1395
        },
        {
          date: '2024-06-20',
          searchVolume: 74433,
          organicRank: 46,

          keywordSales: 1395
        },
        {
          date: '2024-06-19',
          searchVolume: 74433,
          organicRank: 48,

          keywordSales: 1395
        },
        {
          date: '2024-06-18',
          searchVolume: 74433,
          organicRank: 44,

          keywordSales: 1395
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_alani energy drink packets`,
      keyword: 'alani energy drink packets',
      searchVolume: 2430,
      organicStartRank: 72,
      organicHighestRank: 72,
      sponsoredStartRank: 17,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-08-06',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-08-05',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-08-04',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-08-03',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-07-29',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-07-26',
          searchVolume: 3089,
          organicRank: 306,

          keywordSales: 48
        },
        {
          date: '2024-07-25',
          searchVolume: 3089,
          organicRank: 96,

          keywordSales: 48
        },
        {
          date: '2024-07-24',
          searchVolume: 3089,
          organicRank: 93,

          keywordSales: 48
        },
        {
          date: '2024-07-23',
          searchVolume: 3089,
          organicRank: 87,

          keywordSales: 48
        },
        {
          date: '2024-07-22',
          searchVolume: 3089,
          organicRank: 90,

          keywordSales: 48
        },
        {
          date: '2024-07-21',
          searchVolume: 3089,
          organicRank: 92,
          sponsoredRank: 32,
          keywordSales: 48
        },
        {
          date: '2024-07-20',
          searchVolume: 3089,
          organicRank: 89,

          keywordSales: 48
        },
        {
          date: '2024-07-19',
          searchVolume: 2244,
          organicRank: 87,

          keywordSales: 48
        },
        {
          date: '2024-07-18',
          searchVolume: 2244,
          organicRank: 79,
          sponsoredRank: 30,
          keywordSales: 48
        },
        {
          date: '2024-07-17',
          searchVolume: 2244,
          organicRank: 78,
          sponsoredRank: 17,
          keywordSales: 48
        },
        {
          date: '2024-07-16',
          searchVolume: 2244,
          organicRank: 91,
          sponsoredRank: 3,
          keywordSales: 48
        },
        {
          date: '2024-07-15',
          searchVolume: 2244,
          organicRank: 95,

          keywordSales: 48
        },
        {
          date: '2024-07-14',
          searchVolume: 2244,
          organicRank: 98,

          keywordSales: 48
        },
        {
          date: '2024-07-13',
          searchVolume: 2244,
          organicRank: 98,

          keywordSales: 48
        },
        {
          date: '2024-07-12',
          searchVolume: 2091,
          organicRank: 89,

          keywordSales: 48
        },
        {
          date: '2024-07-11',
          searchVolume: 2091,
          organicRank: 88,

          keywordSales: 48
        },
        {
          date: '2024-07-10',
          searchVolume: 2091,
          organicRank: 89,

          keywordSales: 48
        },
        {
          date: '2024-07-09',
          searchVolume: 2091,
          organicRank: 90,

          keywordSales: 48
        },
        {
          date: '2024-07-08',
          searchVolume: 2091,
          organicRank: 86,

          keywordSales: 48
        },
        {
          date: '2024-07-07',
          searchVolume: 2091,
          organicRank: 92,

          keywordSales: 48
        },
        {
          date: '2024-07-06',
          searchVolume: 2091,
          organicRank: 86,

          keywordSales: 48
        },
        {
          date: '2024-07-05',
          searchVolume: 2253,
          organicRank: 83,

          keywordSales: 48
        },
        {
          date: '2024-07-04',
          searchVolume: 2253,
          organicRank: 93,

          keywordSales: 48
        },
        {
          date: '2024-07-03',
          searchVolume: 2253,
          organicRank: 86,

          keywordSales: 48
        },
        {
          date: '2024-07-02',
          searchVolume: 2253,
          organicRank: 112,

          keywordSales: 48
        },
        {
          date: '2024-07-01',
          searchVolume: 2253,
          organicRank: 90,

          keywordSales: 48
        },
        {
          date: '2024-06-30',
          searchVolume: 2253,
          organicRank: 88,

          keywordSales: 48
        },
        {
          date: '2024-06-29',
          searchVolume: 2253,
          organicRank: 90,

          keywordSales: 48
        },
        {
          date: '2024-06-28',
          searchVolume: 2244,
          organicRank: 95,

          keywordSales: 48
        },
        {
          date: '2024-06-27',
          searchVolume: 2244,
          organicRank: 93,

          keywordSales: 48
        },
        {
          date: '2024-06-26',
          searchVolume: 2244,
          organicRank: 88,

          keywordSales: 48
        },
        {
          date: '2024-06-25',
          searchVolume: 2244,
          organicRank: 87,

          keywordSales: 48
        },
        {
          date: '2024-06-24',
          searchVolume: 2244,
          organicRank: 110,

          keywordSales: 48
        },
        {
          date: '2024-06-21',
          searchVolume: 2430,
          organicRank: 91,

          keywordSales: 48
        },
        {
          date: '2024-06-20',
          searchVolume: 2430,
          organicRank: 86,
          sponsoredRank: 17,
          keywordSales: 48
        },
        {
          date: '2024-06-19',
          searchVolume: 2430,
          organicRank: 72,

          keywordSales: 48
        },
        {
          date: '2024-06-18',
          searchVolume: 2430,
          organicRank: 89,

          keywordSales: 48
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_amino energy`,
      keyword: 'amino energy',
      searchVolume: 20518,
      organicStartRank: 148,
      organicHighestRank: 89,
      sponsoredStartRank: 21,
      sponsoredHighestRank: 21,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 34950,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-08-05',
          searchVolume: 34950,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-08-04',
          searchVolume: 34950,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-08-03',
          searchVolume: 34950,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-08-02',
          searchVolume: 34950,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-08-01',
          searchVolume: 34950,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-31',
          searchVolume: 34950,
          organicRank: 115,

          keywordSales: 1787
        },
        {
          date: '2024-07-30',
          searchVolume: 34950,
          organicRank: 115,

          keywordSales: 1787
        },
        {
          date: '2024-07-29',
          searchVolume: 34950,
          organicRank: 277,

          keywordSales: 1787
        },
        {
          date: '2024-07-28',
          searchVolume: 34950,
          organicRank: 125,

          keywordSales: 1787
        },
        {
          date: '2024-07-27',
          searchVolume: 34950,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-26',
          searchVolume: 36146,
          organicRank: 266,

          keywordSales: 1787
        },
        {
          date: '2024-07-25',
          searchVolume: 36146,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-24',
          searchVolume: 36146,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-23',
          searchVolume: 36146,
          organicRank: 131,

          keywordSales: 1787
        },
        {
          date: '2024-07-22',
          searchVolume: 36146,
          organicRank: 122,

          keywordSales: 1787
        },
        {
          date: '2024-07-21',
          searchVolume: 36146,
          organicRank: 118,

          keywordSales: 1787
        },
        {
          date: '2024-07-20',
          searchVolume: 36146,
          organicRank: 265,

          keywordSales: 1787
        },
        {
          date: '2024-07-19',
          searchVolume: 19623,
          organicRank: 257,

          keywordSales: 1787
        },
        {
          date: '2024-07-17',
          searchVolume: 19623,
          organicRank: 104,

          keywordSales: 1787
        },
        {
          date: '2024-07-16',
          searchVolume: 19623,
          organicRank: 101,

          keywordSales: 1787
        },
        {
          date: '2024-07-15',
          searchVolume: 19623,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-14',
          searchVolume: 19623,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-13',
          searchVolume: 19623,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-12',
          searchVolume: 19439,
          organicRank: 256,

          keywordSales: 1787
        },
        {
          date: '2024-07-11',
          searchVolume: 19439,
          organicRank: 256,

          keywordSales: 1787
        },
        {
          date: '2024-07-10',
          searchVolume: 19439,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-09',
          searchVolume: 19439,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-08',
          searchVolume: 19439,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-07',
          searchVolume: 19439,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-06',
          searchVolume: 19439,
          organicRank: 131,

          keywordSales: 1787
        },
        {
          date: '2024-07-05',
          searchVolume: 22073,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-07-04',
          searchVolume: 22073,
          organicRank: 92,

          keywordSales: 1787
        },
        {
          date: '2024-07-03',
          searchVolume: 22073,
          organicRank: 89,

          keywordSales: 1787
        },
        {
          date: '2024-07-02',
          searchVolume: 22073,
          organicRank: 117,

          keywordSales: 1787
        },
        {
          date: '2024-07-01',
          searchVolume: 22073,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-06-30',
          searchVolume: 22073,
          organicRank: 249,

          keywordSales: 1787
        },
        {
          date: '2024-06-29',
          searchVolume: 22073,
          organicRank: 103,

          keywordSales: 1787
        },
        {
          date: '2024-06-28',
          searchVolume: 21981,
          organicRank: 104,

          keywordSales: 1787
        },
        {
          date: '2024-06-27',
          searchVolume: 21981,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-06-26',
          searchVolume: 21981,
          organicRank: 253,

          keywordSales: 1787
        },
        {
          date: '2024-06-25',
          searchVolume: 21981,
          organicRank: 113,

          keywordSales: 1787
        },
        {
          date: '2024-06-24',
          searchVolume: 21981,
          organicRank: 110,

          keywordSales: 1787
        },
        {
          date: '2024-06-23',
          searchVolume: 21981,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-06-22',
          searchVolume: 21981,
          organicRank: 306,

          keywordSales: 1787
        },
        {
          date: '2024-06-21',
          searchVolume: 20518,
          organicRank: 114,

          keywordSales: 1787
        },
        {
          date: '2024-06-20',
          searchVolume: 20518,
          organicRank: 278,
          sponsoredRank: 21,
          keywordSales: 1787
        },
        {
          date: '2024-06-19',
          searchVolume: 20518,
          organicRank: 148,

          keywordSales: 1787
        },
        {
          date: '2024-06-18',
          searchVolume: 20518,
          organicRank: 142,

          keywordSales: 1787
        },
        {
          date: '2024-06-17',
          searchVolume: 20518,
          organicRank: 115,

          keywordSales: 1787
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_amino energy drinks`,
      keyword: 'amino energy drinks',
      searchVolume: 4322,
      organicStartRank: 16,
      organicHighestRank: 13,
      sponsoredStartRank: 36,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-07-02',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2241,
          organicRank: 72,

          keywordSales: 109
        },
        {
          date: '2024-08-05',
          searchVolume: 2241,
          organicRank: 49,

          keywordSales: 109
        },
        {
          date: '2024-08-04',
          searchVolume: 2241,
          organicRank: 87,

          keywordSales: 109
        },
        {
          date: '2024-08-03',
          searchVolume: 2241,
          organicRank: 63,

          keywordSales: 109
        },
        {
          date: '2024-08-02',
          searchVolume: 2241,
          organicRank: 33,

          keywordSales: 109
        },
        {
          date: '2024-08-01',
          searchVolume: 2241,
          organicRank: 30,

          keywordSales: 109
        },
        {
          date: '2024-07-31',
          searchVolume: 2241,
          organicRank: 27,

          keywordSales: 109
        },
        {
          date: '2024-07-30',
          searchVolume: 2241,
          organicRank: 37,

          keywordSales: 109
        },
        {
          date: '2024-07-29',
          searchVolume: 2241,
          organicRank: 30,

          keywordSales: 109
        },
        {
          date: '2024-07-28',
          searchVolume: 2241,
          organicRank: 20,

          keywordSales: 109
        },
        {
          date: '2024-07-27',
          searchVolume: 2241,
          organicRank: 32,

          keywordSales: 109
        },
        {
          date: '2024-07-26',
          searchVolume: 4683,
          organicRank: 47,

          keywordSales: 109
        },
        {
          date: '2024-07-25',
          searchVolume: 4683,
          organicRank: 37,

          keywordSales: 109
        },
        {
          date: '2024-07-24',
          searchVolume: 4683,
          organicRank: 61,
          sponsoredRank: 62,
          keywordSales: 109
        },
        {
          date: '2024-07-23',
          searchVolume: 4683,
          organicRank: 51,

          keywordSales: 109
        },
        {
          date: '2024-07-22',
          searchVolume: 4683,
          organicRank: 65,

          keywordSales: 109
        },
        {
          date: '2024-07-21',
          searchVolume: 4683,
          organicRank: 69,
          sponsoredRank: 61,
          keywordSales: 109
        },
        {
          date: '2024-07-20',
          searchVolume: 4683,
          organicRank: 76,
          sponsoredRank: 72,
          keywordSales: 109
        },
        {
          date: '2024-07-19',
          searchVolume: 3408,
          organicRank: 57,

          keywordSales: 109
        },
        {
          date: '2024-07-18',
          searchVolume: 3408,
          organicRank: 48,

          keywordSales: 109
        },
        {
          date: '2024-07-17',
          searchVolume: 3408,
          organicRank: 47,

          keywordSales: 109
        },
        {
          date: '2024-07-16',
          searchVolume: 3408,
          organicRank: 66,

          keywordSales: 109
        },
        {
          date: '2024-07-15',
          searchVolume: 3408,
          organicRank: 306,
          sponsoredRank: 40,
          keywordSales: 109
        },
        {
          date: '2024-07-14',
          searchVolume: 3408,
          organicRank: 24,

          keywordSales: 109
        },
        {
          date: '2024-07-13',
          searchVolume: 3408,
          organicRank: 306,

          keywordSales: 109
        },
        {
          date: '2024-07-12',
          searchVolume: 3472,
          organicRank: 64,

          keywordSales: 109
        },
        {
          date: '2024-07-11',
          searchVolume: 3472,
          organicRank: 77,

          keywordSales: 109
        },
        {
          date: '2024-07-10',
          searchVolume: 3472,
          organicRank: 22,

          keywordSales: 109
        },
        {
          date: '2024-07-09',
          searchVolume: 3472,
          organicRank: 45,
          sponsoredRank: 25,
          keywordSales: 109
        },
        {
          date: '2024-07-08',
          searchVolume: 3472,
          organicRank: 28,

          keywordSales: 109
        },
        {
          date: '2024-07-07',
          searchVolume: 3472,
          organicRank: 22,
          sponsoredRank: 68,
          keywordSales: 109
        },
        {
          date: '2024-07-06',
          searchVolume: 3472,
          organicRank: 60,
          sponsoredRank: 5,
          keywordSales: 109
        },
        {
          date: '2024-07-05',
          searchVolume: 4333,
          organicRank: 36,

          keywordSales: 109
        },
        {
          date: '2024-07-04',
          searchVolume: 4333,
          organicRank: 22,
          sponsoredRank: 7,
          keywordSales: 109
        },
        {
          date: '2024-07-03',
          searchVolume: 4333,
          organicRank: 20,

          keywordSales: 109
        },
        {
          date: '2024-07-02',
          searchVolume: 4333,
          organicRank: 20,

          keywordSales: 109
        },
        {
          date: '2024-07-01',
          searchVolume: 4333,
          organicRank: 22,
          sponsoredRank: 10,
          keywordSales: 109
        },
        {
          date: '2024-06-30',
          searchVolume: 4333,
          organicRank: 25,
          sponsoredRank: 12,
          keywordSales: 109
        },
        {
          date: '2024-06-29',
          searchVolume: 4333,
          organicRank: 22,
          sponsoredRank: 6,
          keywordSales: 109
        },
        {
          date: '2024-06-28',
          searchVolume: 4311,
          organicRank: 21,

          keywordSales: 109
        },
        {
          date: '2024-06-27',
          searchVolume: 4311,
          organicRank: 24,

          keywordSales: 109
        },
        {
          date: '2024-06-26',
          searchVolume: 4311,
          organicRank: 19,

          keywordSales: 109
        },
        {
          date: '2024-06-25',
          searchVolume: 4311,
          organicRank: 13,

          keywordSales: 109
        },
        {
          date: '2024-06-21',
          searchVolume: 4322,
          organicRank: 14,
          sponsoredRank: 3,
          keywordSales: 109
        },
        {
          date: '2024-06-20',
          searchVolume: 4322,
          organicRank: 14,

          keywordSales: 109
        },
        {
          date: '2024-06-19',
          searchVolume: 4322,
          organicRank: 16,
          sponsoredRank: 36,
          keywordSales: 109
        },
        {
          date: '2024-06-18',
          searchVolume: 4322,
          organicRank: 16,

          keywordSales: 109
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_amp energy drinks`,
      keyword: 'amp energy drinks',
      searchVolume: 1430,
      organicStartRank: 26,
      organicHighestRank: 6,
      sponsoredStartRank: 66,
      sponsoredHighestRank: 5,
      organicPageOneWinDate: '2024-06-27',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-15',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1322,
          organicRank: 42,

          keywordSales: 62
        },
        {
          date: '2024-08-06',
          searchVolume: 1322,
          organicRank: 33,

          keywordSales: 62
        },
        {
          date: '2024-08-05',
          searchVolume: 1322,
          organicRank: 26,

          keywordSales: 62
        },
        {
          date: '2024-08-04',
          searchVolume: 1322,
          organicRank: 30,

          keywordSales: 62
        },
        {
          date: '2024-08-03',
          searchVolume: 1322,
          organicRank: 30,

          keywordSales: 62
        },
        {
          date: '2024-08-01',
          searchVolume: 1322,
          organicRank: 27,

          keywordSales: 62
        },
        {
          date: '2024-07-31',
          searchVolume: 1322,
          organicRank: 29,

          keywordSales: 62
        },
        {
          date: '2024-07-30',
          searchVolume: 1322,
          organicRank: 25,

          keywordSales: 62
        },
        {
          date: '2024-07-29',
          searchVolume: 1322,
          organicRank: 25,

          keywordSales: 62
        },
        {
          date: '2024-07-28',
          searchVolume: 1322,
          organicRank: 33,

          keywordSales: 62
        },
        {
          date: '2024-07-27',
          searchVolume: 1322,
          organicRank: 31,

          keywordSales: 62
        },
        {
          date: '2024-07-26',
          searchVolume: 1834,
          organicRank: 21,

          keywordSales: 62
        },
        {
          date: '2024-07-25',
          searchVolume: 1834,
          organicRank: 22,

          keywordSales: 62
        },
        {
          date: '2024-07-24',
          searchVolume: 1834,
          organicRank: 20,

          keywordSales: 62
        },
        {
          date: '2024-07-23',
          searchVolume: 1834,
          organicRank: 21,

          keywordSales: 62
        },
        {
          date: '2024-07-22',
          searchVolume: 1834,
          organicRank: 20,

          keywordSales: 62
        },
        {
          date: '2024-07-21',
          searchVolume: 1834,
          organicRank: 18,

          keywordSales: 62
        },
        {
          date: '2024-07-20',
          searchVolume: 1834,
          organicRank: 19,

          keywordSales: 62
        },
        {
          date: '2024-07-19',
          searchVolume: 1268,
          organicRank: 15,

          keywordSales: 62
        },
        {
          date: '2024-07-18',
          searchVolume: 1268,
          organicRank: 14,

          keywordSales: 62
        },
        {
          date: '2024-07-17',
          searchVolume: 1268,
          organicRank: 16,
          sponsoredRank: 7,
          keywordSales: 62
        },
        {
          date: '2024-07-16',
          searchVolume: 1268,
          organicRank: 17,

          keywordSales: 62
        },
        {
          date: '2024-07-15',
          searchVolume: 1268,
          organicRank: 17,
          sponsoredRank: 5,
          keywordSales: 62
        },
        {
          date: '2024-07-14',
          searchVolume: 1268,
          organicRank: 19,

          keywordSales: 62
        },
        {
          date: '2024-07-13',
          searchVolume: 1268,
          organicRank: 12,

          keywordSales: 62
        },
        {
          date: '2024-07-12',
          searchVolume: 1536,
          organicRank: 17,

          keywordSales: 62
        },
        {
          date: '2024-07-11',
          searchVolume: 1536,
          organicRank: 11,

          keywordSales: 62
        },
        {
          date: '2024-07-10',
          searchVolume: 1536,
          organicRank: 13,

          keywordSales: 62
        },
        {
          date: '2024-07-09',
          searchVolume: 1536,
          organicRank: 9,

          keywordSales: 62
        },
        {
          date: '2024-07-08',
          searchVolume: 1536,
          organicRank: 31,

          keywordSales: 62
        },
        {
          date: '2024-07-07',
          searchVolume: 1536,
          organicRank: 12,

          keywordSales: 62
        },
        {
          date: '2024-07-06',
          searchVolume: 1536,
          organicRank: 14,

          keywordSales: 62
        },
        {
          date: '2024-07-05',
          searchVolume: 1433,
          organicRank: 6,

          keywordSales: 62
        },
        {
          date: '2024-07-04',
          searchVolume: 1433,
          organicRank: 12,

          keywordSales: 62
        },
        {
          date: '2024-07-03',
          searchVolume: 1433,
          organicRank: 11,

          keywordSales: 62
        },
        {
          date: '2024-07-02',
          searchVolume: 1433,
          organicRank: 8,

          keywordSales: 62
        },
        {
          date: '2024-07-01',
          searchVolume: 1433,
          organicRank: 9,

          keywordSales: 62
        },
        {
          date: '2024-06-30',
          searchVolume: 1433,
          organicRank: 7,

          keywordSales: 62
        },
        {
          date: '2024-06-29',
          searchVolume: 1433,
          organicRank: 11,

          keywordSales: 62
        },
        {
          date: '2024-06-28',
          searchVolume: 1426,
          organicRank: 23,

          keywordSales: 62
        },
        {
          date: '2024-06-27',
          searchVolume: 1426,
          organicRank: 18,
          sponsoredRank: 66,
          keywordSales: 62
        },
        {
          date: '2024-06-26',
          searchVolume: 1426,
          organicRank: 27,

          keywordSales: 62
        },
        {
          date: '2024-06-25',
          searchVolume: 1426,
          organicRank: 24,

          keywordSales: 62
        },
        {
          date: '2024-06-24',
          searchVolume: 1426,
          organicRank: 21,

          keywordSales: 62
        },
        {
          date: '2024-06-21',
          searchVolume: 1430,
          organicRank: 32,

          keywordSales: 62
        },
        {
          date: '2024-06-20',
          searchVolume: 1430,
          organicRank: 22,

          keywordSales: 62
        },
        {
          date: '2024-06-19',
          searchVolume: 1430,
          organicRank: 26,

          keywordSales: 62
        },
        {
          date: '2024-06-18',
          searchVolume: 1430,
          organicRank: 25,

          keywordSales: 62
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_bang energy`,
      keyword: 'bang energy',
      searchVolume: 2394,
      organicStartRank: 49,
      organicHighestRank: 17,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-06-27',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-19',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 1777,
          organicRank: 64,

          keywordSales: 64
        },
        {
          date: '2024-08-05',
          searchVolume: 1777,
          organicRank: 78,

          keywordSales: 64
        },
        {
          date: '2024-08-04',
          searchVolume: 1777,
          organicRank: 67,

          keywordSales: 64
        },
        {
          date: '2024-08-03',
          searchVolume: 1777,
          organicRank: 70,

          keywordSales: 64
        },
        {
          date: '2024-08-02',
          searchVolume: 1777,
          organicRank: 74,

          keywordSales: 64
        },
        {
          date: '2024-08-01',
          searchVolume: 1777,
          organicRank: 29,

          keywordSales: 64
        },
        {
          date: '2024-07-31',
          searchVolume: 1777,
          organicRank: 62,

          keywordSales: 64
        },
        {
          date: '2024-07-30',
          searchVolume: 1777,
          organicRank: 30,

          keywordSales: 64
        },
        {
          date: '2024-07-29',
          searchVolume: 1777,
          organicRank: 61,

          keywordSales: 64
        },
        {
          date: '2024-07-28',
          searchVolume: 1777,
          organicRank: 30,

          keywordSales: 64
        },
        {
          date: '2024-07-27',
          searchVolume: 1777,
          organicRank: 58,

          keywordSales: 64
        },
        {
          date: '2024-07-26',
          searchVolume: 3466,
          organicRank: 64,

          keywordSales: 64
        },
        {
          date: '2024-07-25',
          searchVolume: 3466,
          organicRank: 68,

          keywordSales: 64
        },
        {
          date: '2024-07-24',
          searchVolume: 3466,
          organicRank: 58,

          keywordSales: 64
        },
        {
          date: '2024-07-23',
          searchVolume: 3466,
          organicRank: 56,

          keywordSales: 64
        },
        {
          date: '2024-07-22',
          searchVolume: 3466,
          organicRank: 74,

          keywordSales: 64
        },
        {
          date: '2024-07-21',
          searchVolume: 3466,
          organicRank: 57,

          keywordSales: 64
        },
        {
          date: '2024-07-20',
          searchVolume: 3466,
          organicRank: 62,
          sponsoredRank: 4,
          keywordSales: 64
        },
        {
          date: '2024-07-19',
          searchVolume: 2040,
          organicRank: 63,

          keywordSales: 64
        },
        {
          date: '2024-07-18',
          searchVolume: 2040,
          organicRank: 61,

          keywordSales: 64
        },
        {
          date: '2024-07-17',
          searchVolume: 2040,
          organicRank: 75,

          keywordSales: 64
        },
        {
          date: '2024-07-16',
          searchVolume: 2040,
          organicRank: 66,

          keywordSales: 64
        },
        {
          date: '2024-07-15',
          searchVolume: 2040,
          organicRank: 65,

          keywordSales: 64
        },
        {
          date: '2024-07-14',
          searchVolume: 2040,
          organicRank: 70,

          keywordSales: 64
        },
        {
          date: '2024-07-13',
          searchVolume: 2040,
          organicRank: 69,

          keywordSales: 64
        },
        {
          date: '2024-07-12',
          searchVolume: 1844,
          organicRank: 55,

          keywordSales: 64
        },
        {
          date: '2024-07-11',
          searchVolume: 1844,
          organicRank: 65,

          keywordSales: 64
        },
        {
          date: '2024-07-10',
          searchVolume: 1844,
          organicRank: 85,

          keywordSales: 64
        },
        {
          date: '2024-07-09',
          searchVolume: 1844,
          organicRank: 82,

          keywordSales: 64
        },
        {
          date: '2024-07-08',
          searchVolume: 1844,
          organicRank: 55,

          keywordSales: 64
        },
        {
          date: '2024-07-07',
          searchVolume: 1844,
          organicRank: 66,

          keywordSales: 64
        },
        {
          date: '2024-07-06',
          searchVolume: 1844,
          organicRank: 60,
          sponsoredRank: 4,
          keywordSales: 64
        },
        {
          date: '2024-07-05',
          searchVolume: 2173,
          organicRank: 29,

          keywordSales: 64
        },
        {
          date: '2024-07-04',
          searchVolume: 2173,
          organicRank: 37,

          keywordSales: 64
        },
        {
          date: '2024-07-03',
          searchVolume: 2173,
          organicRank: 28,

          keywordSales: 64
        },
        {
          date: '2024-07-02',
          searchVolume: 2173,
          organicRank: 20,

          keywordSales: 64
        },
        {
          date: '2024-07-01',
          searchVolume: 2173,
          organicRank: 28,

          keywordSales: 64
        },
        {
          date: '2024-06-30',
          searchVolume: 2173,
          organicRank: 26,

          keywordSales: 64
        },
        {
          date: '2024-06-29',
          searchVolume: 2173,
          organicRank: 47,
          sponsoredRank: 19,
          keywordSales: 64
        },
        {
          date: '2024-06-28',
          searchVolume: 2165,
          organicRank: 38,

          keywordSales: 64
        },
        {
          date: '2024-06-27',
          searchVolume: 2165,
          organicRank: 17,

          keywordSales: 64
        },
        {
          date: '2024-06-26',
          searchVolume: 2165,
          organicRank: 27,

          keywordSales: 64
        },
        {
          date: '2024-06-25',
          searchVolume: 2165,
          organicRank: 23,

          keywordSales: 64
        },
        {
          date: '2024-06-24',
          searchVolume: 2165,
          organicRank: 50,

          keywordSales: 64
        },
        {
          date: '2024-06-21',
          searchVolume: 2394,
          organicRank: 28,

          keywordSales: 64
        },
        {
          date: '2024-06-20',
          searchVolume: 2394,
          organicRank: 63,
          sponsoredRank: 12,
          keywordSales: 64
        },
        {
          date: '2024-06-19',
          searchVolume: 2394,
          organicRank: 49,
          sponsoredRank: 4,
          keywordSales: 64
        },
        {
          date: '2024-06-18',
          searchVolume: 2394,
          organicRank: 306,
          sponsoredRank: 26,
          keywordSales: 64
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_bang energy drink`,
      keyword: 'bang energy drink',
      searchVolume: 1430,
      organicStartRank: 23,
      organicHighestRank: 14,
      sponsoredStartRank: 58,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-07-31',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 817,
          organicRank: 30,

          keywordSales: 34
        },
        {
          date: '2024-08-06',
          searchVolume: 817,
          organicRank: 34,

          keywordSales: 34
        },
        {
          date: '2024-08-05',
          searchVolume: 817,
          organicRank: 27,

          keywordSales: 34
        },
        {
          date: '2024-08-04',
          searchVolume: 817,
          organicRank: 25,

          keywordSales: 34
        },
        {
          date: '2024-08-03',
          searchVolume: 817,
          organicRank: 27,

          keywordSales: 34
        },
        {
          date: '2024-08-01',
          searchVolume: 817,
          organicRank: 25,

          keywordSales: 34
        },
        {
          date: '2024-07-31',
          searchVolume: 817,
          organicRank: 14,

          keywordSales: 34
        },
        {
          date: '2024-07-30',
          searchVolume: 817,
          organicRank: 25,

          keywordSales: 34
        },
        {
          date: '2024-07-29',
          searchVolume: 817,
          organicRank: 26,

          keywordSales: 34
        },
        {
          date: '2024-07-28',
          searchVolume: 817,
          organicRank: 22,

          keywordSales: 34
        },
        {
          date: '2024-07-27',
          searchVolume: 817,
          organicRank: 23,

          keywordSales: 34
        },
        {
          date: '2024-07-26',
          searchVolume: 1587,
          organicRank: 26,

          keywordSales: 34
        },
        {
          date: '2024-07-25',
          searchVolume: 1587,
          organicRank: 22,

          keywordSales: 34
        },
        {
          date: '2024-07-24',
          searchVolume: 1587,
          organicRank: 27,
          sponsoredRank: 3,
          keywordSales: 34
        },
        {
          date: '2024-07-23',
          searchVolume: 1587,
          organicRank: 30,
          sponsoredRank: 7,
          keywordSales: 34
        },
        {
          date: '2024-07-22',
          searchVolume: 1587,
          organicRank: 27,
          sponsoredRank: 20,
          keywordSales: 34
        },
        {
          date: '2024-07-21',
          searchVolume: 1587,
          organicRank: 26,

          keywordSales: 34
        },
        {
          date: '2024-07-20',
          searchVolume: 1587,
          organicRank: 27,
          sponsoredRank: 46,
          keywordSales: 34
        },
        {
          date: '2024-07-19',
          searchVolume: 1147,
          organicRank: 28,

          keywordSales: 34
        },
        {
          date: '2024-07-18',
          searchVolume: 1147,
          organicRank: 32,

          keywordSales: 34
        },
        {
          date: '2024-07-17',
          searchVolume: 1147,
          organicRank: 26,

          keywordSales: 34
        },
        {
          date: '2024-07-16',
          searchVolume: 1147,
          organicRank: 23,

          keywordSales: 34
        },
        {
          date: '2024-07-15',
          searchVolume: 1147,
          organicRank: 24,

          keywordSales: 34
        },
        {
          date: '2024-07-14',
          searchVolume: 1147,
          organicRank: 24,

          keywordSales: 34
        },
        {
          date: '2024-07-13',
          searchVolume: 1147,
          organicRank: 25,

          keywordSales: 34
        },
        {
          date: '2024-07-12',
          searchVolume: 1290,
          organicRank: 25,

          keywordSales: 34
        },
        {
          date: '2024-07-11',
          searchVolume: 1290,
          organicRank: 26,

          keywordSales: 34
        },
        {
          date: '2024-07-10',
          searchVolume: 1290,
          organicRank: 22,

          keywordSales: 34
        },
        {
          date: '2024-07-09',
          searchVolume: 1290,
          organicRank: 62,

          keywordSales: 34
        },
        {
          date: '2024-07-08',
          searchVolume: 1290,
          organicRank: 24,

          keywordSales: 34
        },
        {
          date: '2024-07-07',
          searchVolume: 1290,
          organicRank: 28,

          keywordSales: 34
        },
        {
          date: '2024-07-06',
          searchVolume: 1290,
          organicRank: 32,

          keywordSales: 34
        },
        {
          date: '2024-07-05',
          searchVolume: 1348,
          organicRank: 37,

          keywordSales: 34
        },
        {
          date: '2024-07-04',
          searchVolume: 1348,
          organicRank: 47,

          keywordSales: 34
        },
        {
          date: '2024-07-03',
          searchVolume: 1348,
          organicRank: 48,

          keywordSales: 34
        },
        {
          date: '2024-07-02',
          searchVolume: 1348,
          organicRank: 59,

          keywordSales: 34
        },
        {
          date: '2024-07-01',
          searchVolume: 1348,
          organicRank: 49,

          keywordSales: 34
        },
        {
          date: '2024-06-30',
          searchVolume: 1348,
          organicRank: 47,

          keywordSales: 34
        },
        {
          date: '2024-06-29',
          searchVolume: 1348,
          organicRank: 39,

          keywordSales: 34
        },
        {
          date: '2024-06-28',
          searchVolume: 1346,
          organicRank: 38,

          keywordSales: 34
        },
        {
          date: '2024-06-27',
          searchVolume: 1346,
          organicRank: 30,

          keywordSales: 34
        },
        {
          date: '2024-06-26',
          searchVolume: 1346,
          organicRank: 26,

          keywordSales: 34
        },
        {
          date: '2024-06-25',
          searchVolume: 1346,
          organicRank: 37,

          keywordSales: 34
        },
        {
          date: '2024-06-24',
          searchVolume: 1346,
          organicRank: 28,

          keywordSales: 34
        },
        {
          date: '2024-06-21',
          searchVolume: 1430,
          organicRank: 26,

          keywordSales: 34
        },
        {
          date: '2024-06-20',
          searchVolume: 1430,
          organicRank: 41,
          sponsoredRank: 58,
          keywordSales: 34
        },
        {
          date: '2024-06-19',
          searchVolume: 1430,
          organicRank: 23,

          keywordSales: 34
        },
        {
          date: '2024-06-18',
          searchVolume: 1430,
          organicRank: 26,

          keywordSales: 34
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_bang energy drinks`,
      keyword: 'bang energy drinks',
      searchVolume: 28998,
      organicStartRank: 31,
      organicHighestRank: 13,
      sponsoredStartRank: 38,
      sponsoredHighestRank: 8,
      organicPageOneWinDate: '2024-07-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-15',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 18835,
          organicRank: 21,

          keywordSales: 493
        },
        {
          date: '2024-08-06',
          searchVolume: 18835,
          organicRank: 19,

          keywordSales: 493
        },
        {
          date: '2024-08-05',
          searchVolume: 18835,
          organicRank: 19,

          keywordSales: 493
        },
        {
          date: '2024-08-04',
          searchVolume: 18835,
          organicRank: 13,

          keywordSales: 493
        },
        {
          date: '2024-08-03',
          searchVolume: 18835,
          organicRank: 15,

          keywordSales: 493
        },
        {
          date: '2024-08-02',
          searchVolume: 18835,
          organicRank: 13,

          keywordSales: 493
        },
        {
          date: '2024-08-01',
          searchVolume: 18835,
          organicRank: 14,

          keywordSales: 493
        },
        {
          date: '2024-07-31',
          searchVolume: 18835,
          organicRank: 14,

          keywordSales: 493
        },
        {
          date: '2024-07-30',
          searchVolume: 18835,
          organicRank: 14,

          keywordSales: 493
        },
        {
          date: '2024-07-28',
          searchVolume: 18835,
          organicRank: 16,

          keywordSales: 493
        },
        {
          date: '2024-07-27',
          searchVolume: 18835,
          organicRank: 15,

          keywordSales: 493
        },
        {
          date: '2024-07-26',
          searchVolume: 30666,
          organicRank: 19,

          keywordSales: 493
        },
        {
          date: '2024-07-25',
          searchVolume: 30666,
          organicRank: 20,

          keywordSales: 493
        },
        {
          date: '2024-07-24',
          searchVolume: 30666,
          organicRank: 20,

          keywordSales: 493
        },
        {
          date: '2024-07-23',
          searchVolume: 30666,
          organicRank: 25,
          sponsoredRank: 33,
          keywordSales: 493
        },
        {
          date: '2024-07-22',
          searchVolume: 30666,
          organicRank: 24,

          keywordSales: 493
        },
        {
          date: '2024-07-21',
          searchVolume: 30666,
          organicRank: 21,

          keywordSales: 493
        },
        {
          date: '2024-07-20',
          searchVolume: 30666,
          organicRank: 26,

          keywordSales: 493
        },
        {
          date: '2024-07-19',
          searchVolume: 23219,
          organicRank: 23,

          keywordSales: 493
        },
        {
          date: '2024-07-18',
          searchVolume: 23219,
          organicRank: 27,

          keywordSales: 493
        },
        {
          date: '2024-07-17',
          searchVolume: 23219,
          organicRank: 29,
          sponsoredRank: 8,
          keywordSales: 493
        },
        {
          date: '2024-07-16',
          searchVolume: 23219,
          organicRank: 30,
          sponsoredRank: 15,
          keywordSales: 493
        },
        {
          date: '2024-07-15',
          searchVolume: 23219,
          organicRank: 29,
          sponsoredRank: 13,
          keywordSales: 493
        },
        {
          date: '2024-07-14',
          searchVolume: 23219,
          organicRank: 29,

          keywordSales: 493
        },
        {
          date: '2024-07-13',
          searchVolume: 23219,
          organicRank: 32,

          keywordSales: 493
        },
        {
          date: '2024-07-12',
          searchVolume: 21103,
          organicRank: 29,

          keywordSales: 493
        },
        {
          date: '2024-07-11',
          searchVolume: 21103,
          organicRank: 53,

          keywordSales: 493
        },
        {
          date: '2024-07-10',
          searchVolume: 21103,
          organicRank: 72,

          keywordSales: 493
        },
        {
          date: '2024-07-09',
          searchVolume: 21103,
          organicRank: 46,

          keywordSales: 493
        },
        {
          date: '2024-07-08',
          searchVolume: 21103,
          organicRank: 55,

          keywordSales: 493
        },
        {
          date: '2024-07-07',
          searchVolume: 21103,
          organicRank: 55,

          keywordSales: 493
        },
        {
          date: '2024-07-06',
          searchVolume: 21103,
          organicRank: 25,

          keywordSales: 493
        },
        {
          date: '2024-07-05',
          searchVolume: 24853,
          organicRank: 41,

          keywordSales: 493
        },
        {
          date: '2024-07-04',
          searchVolume: 24853,
          organicRank: 20,

          keywordSales: 493
        },
        {
          date: '2024-07-03',
          searchVolume: 24853,
          organicRank: 24,

          keywordSales: 493
        },
        {
          date: '2024-07-02',
          searchVolume: 24853,
          organicRank: 24,

          keywordSales: 493
        },
        {
          date: '2024-07-01',
          searchVolume: 24853,
          organicRank: 29,

          keywordSales: 493
        },
        {
          date: '2024-06-30',
          searchVolume: 24853,
          organicRank: 48,

          keywordSales: 493
        },
        {
          date: '2024-06-29',
          searchVolume: 24853,
          organicRank: 24,

          keywordSales: 493
        },
        {
          date: '2024-06-28',
          searchVolume: 25928,
          organicRank: 30,

          keywordSales: 493
        },
        {
          date: '2024-06-27',
          searchVolume: 25928,
          organicRank: 29,

          keywordSales: 493
        },
        {
          date: '2024-06-26',
          searchVolume: 25928,
          organicRank: 59,

          keywordSales: 493
        },
        {
          date: '2024-06-25',
          searchVolume: 25928,
          organicRank: 24,

          keywordSales: 493
        },
        {
          date: '2024-06-24',
          searchVolume: 25928,
          organicRank: 28,

          keywordSales: 493
        },
        {
          date: '2024-06-21',
          searchVolume: 28998,
          organicRank: 29,

          keywordSales: 493
        },
        {
          date: '2024-06-20',
          searchVolume: 28998,
          organicRank: 24,
          sponsoredRank: 33,
          keywordSales: 493
        },
        {
          date: '2024-06-19',
          searchVolume: 28998,
          organicRank: 31,

          keywordSales: 493
        },
        {
          date: '2024-06-18',
          searchVolume: 28998,
          organicRank: 24,
          sponsoredRank: 38,
          keywordSales: 493
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_bebida energetica`,
      keyword: 'bebida energetica',
      searchVolume: 92,
      organicStartRank: 16,
      organicHighestRank: 7,
      sponsoredStartRank: 28,
      sponsoredHighestRank: 28,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 78,
          organicRank: 37
        },
        {
          date: '2024-08-04',
          searchVolume: 78,
          organicRank: 43
        },
        {
          date: '2024-08-02',
          searchVolume: 78,
          organicRank: 44
        },
        {
          date: '2024-07-31',
          searchVolume: 78,
          organicRank: 9
        },
        {
          date: '2024-07-30',
          searchVolume: 78,
          organicRank: 8
        },
        {
          date: '2024-07-28',
          searchVolume: 78,
          organicRank: 11
        },
        {
          date: '2024-07-27',
          searchVolume: 78,
          organicRank: 15
        },
        {
          date: '2024-07-26',
          searchVolume: 119,
          organicRank: 37
        },
        {
          date: '2024-07-25',
          searchVolume: 119,
          organicRank: 36
        },
        {
          date: '2024-07-24',
          searchVolume: 119,
          organicRank: 29
        },
        {
          date: '2024-07-23',
          searchVolume: 119,
          organicRank: 35
        },
        {
          date: '2024-07-22',
          searchVolume: 119,
          organicRank: 35
        },
        {
          date: '2024-07-21',
          searchVolume: 119,
          organicRank: 34,
          sponsoredRank: 31
        },
        {
          date: '2024-07-20',
          searchVolume: 119,
          organicRank: 36
        },
        {
          date: '2024-07-19',
          searchVolume: 132,
          organicRank: 35,
          sponsoredRank: 28
        },
        {
          date: '2024-07-18',
          searchVolume: 132,
          organicRank: 28
        },
        {
          date: '2024-07-17',
          searchVolume: 132,
          organicRank: 33
        },
        {
          date: '2024-07-16',
          searchVolume: 132,
          organicRank: 28
        },
        {
          date: '2024-07-15',
          searchVolume: 132,
          organicRank: 38
        },
        {
          date: '2024-07-14',
          searchVolume: 132,
          organicRank: 41
        },
        {
          date: '2024-07-13',
          searchVolume: 132,
          organicRank: 44
        },
        {
          date: '2024-07-12',
          searchVolume: 141,
          organicRank: 40
        },
        {
          date: '2024-07-11',
          searchVolume: 141,
          organicRank: 54
        },
        {
          date: '2024-07-10',
          searchVolume: 141,
          organicRank: 36
        },
        {
          date: '2024-07-09',
          searchVolume: 141,
          organicRank: 15
        },
        {
          date: '2024-07-08',
          searchVolume: 141,
          organicRank: 25
        },
        {
          date: '2024-07-07',
          searchVolume: 141,
          organicRank: 28
        },
        {
          date: '2024-07-06',
          searchVolume: 141,
          organicRank: 41
        },
        {
          date: '2024-07-05',
          searchVolume: 111,
          organicRank: 10
        },
        {
          date: '2024-07-04',
          searchVolume: 111,
          organicRank: 10
        },
        {
          date: '2024-07-03',
          searchVolume: 111,
          organicRank: 7
        },
        {
          date: '2024-07-02',
          searchVolume: 111,
          organicRank: 7
        },
        {
          date: '2024-07-01',
          searchVolume: 111,
          organicRank: 9
        },
        {
          date: '2024-06-29',
          searchVolume: 111,
          organicRank: 9
        },
        {
          date: '2024-06-27',
          searchVolume: 113,
          organicRank: 9
        },
        {
          date: '2024-06-25',
          searchVolume: 113,
          organicRank: 8
        },
        {
          date: '2024-06-21',
          searchVolume: 92,
          organicRank: 11
        },
        {
          date: '2024-06-19',
          searchVolume: 92,
          organicRank: 16
        },
        {
          date: '2024-06-18',
          searchVolume: 92,
          organicRank: 14
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_beverages`,
      keyword: 'beverages',
      searchVolume: 9282,
      organicStartRank: 81,
      organicHighestRank: 12,

      organicPageOneWinDate: '2024-06-28',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 8342,
          organicRank: 33,

          keywordSales: 234
        },
        {
          date: '2024-08-05',
          searchVolume: 8342,
          organicRank: 24,

          keywordSales: 234
        },
        {
          date: '2024-08-04',
          searchVolume: 8342,
          organicRank: 25,

          keywordSales: 234
        },
        {
          date: '2024-08-02',
          searchVolume: 8342,
          organicRank: 32,

          keywordSales: 234
        },
        {
          date: '2024-08-01',
          searchVolume: 8342,
          organicRank: 32,

          keywordSales: 234
        },
        {
          date: '2024-07-31',
          searchVolume: 8342,
          organicRank: 29,

          keywordSales: 234
        },
        {
          date: '2024-07-30',
          searchVolume: 8342,
          organicRank: 30,

          keywordSales: 234
        },
        {
          date: '2024-07-28',
          searchVolume: 8342,
          organicRank: 38,

          keywordSales: 234
        },
        {
          date: '2024-07-27',
          searchVolume: 8342,
          organicRank: 37,

          keywordSales: 234
        },
        {
          date: '2024-07-26',
          searchVolume: 10561,
          organicRank: 30,

          keywordSales: 234
        },
        {
          date: '2024-07-25',
          searchVolume: 10561,
          organicRank: 32,

          keywordSales: 234
        },
        {
          date: '2024-07-24',
          searchVolume: 10561,
          organicRank: 70,

          keywordSales: 234
        },
        {
          date: '2024-07-23',
          searchVolume: 10561,
          organicRank: 64,

          keywordSales: 234
        },
        {
          date: '2024-07-22',
          searchVolume: 10561,
          organicRank: 56,

          keywordSales: 234
        },
        {
          date: '2024-07-21',
          searchVolume: 10561,
          organicRank: 40,

          keywordSales: 234
        },
        {
          date: '2024-07-20',
          searchVolume: 10561,
          organicRank: 27,

          keywordSales: 234
        },
        {
          date: '2024-07-19',
          searchVolume: 8715,
          organicRank: 38,

          keywordSales: 234
        },
        {
          date: '2024-07-18',
          searchVolume: 8715,
          organicRank: 60,

          keywordSales: 234
        },
        {
          date: '2024-07-17',
          searchVolume: 8715,
          organicRank: 41,

          keywordSales: 234
        },
        {
          date: '2024-07-16',
          searchVolume: 8715,
          organicRank: 54,

          keywordSales: 234
        },
        {
          date: '2024-07-15',
          searchVolume: 8715,
          organicRank: 108,

          keywordSales: 234
        },
        {
          date: '2024-07-14',
          searchVolume: 8715,
          organicRank: 100,

          keywordSales: 234
        },
        {
          date: '2024-07-13',
          searchVolume: 8715,
          organicRank: 306,

          keywordSales: 234
        },
        {
          date: '2024-07-12',
          searchVolume: 8682,
          organicRank: 165,

          keywordSales: 234
        },
        {
          date: '2024-07-11',
          searchVolume: 8682,
          organicRank: 42,

          keywordSales: 234
        },
        {
          date: '2024-07-10',
          searchVolume: 8682,
          organicRank: 73,

          keywordSales: 234
        },
        {
          date: '2024-07-09',
          searchVolume: 8682,
          organicRank: 58,

          keywordSales: 234
        },
        {
          date: '2024-07-08',
          searchVolume: 8682,
          organicRank: 60,

          keywordSales: 234
        },
        {
          date: '2024-07-07',
          searchVolume: 8682,
          organicRank: 71,

          keywordSales: 234
        },
        {
          date: '2024-07-06',
          searchVolume: 8682,
          organicRank: 86,

          keywordSales: 234
        },
        {
          date: '2024-07-05',
          searchVolume: 9028,
          organicRank: 69,

          keywordSales: 234
        },
        {
          date: '2024-07-04',
          searchVolume: 9028,
          organicRank: 60,

          keywordSales: 234
        },
        {
          date: '2024-07-03',
          searchVolume: 9028,
          organicRank: 40,

          keywordSales: 234
        },
        {
          date: '2024-07-02',
          searchVolume: 9028,
          organicRank: 306,

          keywordSales: 234
        },
        {
          date: '2024-07-01',
          searchVolume: 9028,
          organicRank: 73,

          keywordSales: 234
        },
        {
          date: '2024-06-30',
          searchVolume: 9028,
          organicRank: 30,

          keywordSales: 234
        },
        {
          date: '2024-06-29',
          searchVolume: 9028,
          organicRank: 26,

          keywordSales: 234
        },
        {
          date: '2024-06-28',
          searchVolume: 8998,
          organicRank: 12,

          keywordSales: 234
        },
        {
          date: '2024-06-27',
          searchVolume: 8998,
          organicRank: 46,

          keywordSales: 234
        },
        {
          date: '2024-06-26',
          searchVolume: 8998,
          organicRank: 37,

          keywordSales: 234
        },
        {
          date: '2024-06-25',
          searchVolume: 8998,
          organicRank: 57,

          keywordSales: 234
        },
        {
          date: '2024-06-21',
          searchVolume: 9282,
          organicRank: 44,

          keywordSales: 234
        },
        {
          date: '2024-06-20',
          searchVolume: 9282,
          organicRank: 61,

          keywordSales: 234
        },
        {
          date: '2024-06-19',
          searchVolume: 9282,
          organicRank: 81,

          keywordSales: 234
        },
        {
          date: '2024-06-18',
          searchVolume: 9282,
          organicRank: 95,

          keywordSales: 234
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_boost drink`,
      keyword: 'boost drink',
      searchVolume: 1928,
      organicStartRank: 98,
      organicHighestRank: 63,
      sponsoredStartRank: 55,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2599,
          organicRank: 80,

          keywordSales: 92
        },
        {
          date: '2024-08-06',
          searchVolume: 2599,
          organicRank: 72,

          keywordSales: 92
        },
        {
          date: '2024-08-05',
          searchVolume: 2599,
          organicRank: 77,

          keywordSales: 92
        },
        {
          date: '2024-08-04',
          searchVolume: 2599,
          organicRank: 92,

          keywordSales: 92
        },
        {
          date: '2024-08-03',
          searchVolume: 2599,
          organicRank: 82,

          keywordSales: 92
        },
        {
          date: '2024-08-02',
          searchVolume: 2599,
          organicRank: 84,

          keywordSales: 92
        },
        {
          date: '2024-08-01',
          searchVolume: 2599,
          organicRank: 82,

          keywordSales: 92
        },
        {
          date: '2024-07-31',
          searchVolume: 2599,
          organicRank: 80,

          keywordSales: 92
        },
        {
          date: '2024-07-30',
          searchVolume: 2599,
          organicRank: 79,

          keywordSales: 92
        },
        {
          date: '2024-07-28',
          searchVolume: 2599,
          organicRank: 85,

          keywordSales: 92
        },
        {
          date: '2024-07-27',
          searchVolume: 2599,
          organicRank: 80,

          keywordSales: 92
        },
        {
          date: '2024-07-26',
          searchVolume: 2688,
          organicRank: 84,

          keywordSales: 92
        },
        {
          date: '2024-07-25',
          searchVolume: 2688,
          organicRank: 85,

          keywordSales: 92
        },
        {
          date: '2024-07-24',
          searchVolume: 2688,
          organicRank: 89,

          keywordSales: 92
        },
        {
          date: '2024-07-23',
          searchVolume: 2688,
          organicRank: 77,
          sponsoredRank: 6,
          keywordSales: 92
        },
        {
          date: '2024-07-22',
          searchVolume: 2688,
          organicRank: 86,

          keywordSales: 92
        },
        {
          date: '2024-07-21',
          searchVolume: 2688,
          organicRank: 80,
          sponsoredRank: 20,
          keywordSales: 92
        },
        {
          date: '2024-07-20',
          searchVolume: 2688,
          organicRank: 79,

          keywordSales: 92
        },
        {
          date: '2024-07-19',
          searchVolume: 2282,
          organicRank: 70,
          sponsoredRank: 44,
          keywordSales: 92
        },
        {
          date: '2024-07-18',
          searchVolume: 2282,
          organicRank: 71,

          keywordSales: 92
        },
        {
          date: '2024-07-17',
          searchVolume: 2282,
          organicRank: 63,

          keywordSales: 92
        },
        {
          date: '2024-07-16',
          searchVolume: 2282,
          organicRank: 74,

          keywordSales: 92
        },
        {
          date: '2024-07-15',
          searchVolume: 2282,
          organicRank: 89,

          keywordSales: 92
        },
        {
          date: '2024-07-14',
          searchVolume: 2282,
          organicRank: 89,

          keywordSales: 92
        },
        {
          date: '2024-07-13',
          searchVolume: 2282,
          organicRank: 83,

          keywordSales: 92
        },
        {
          date: '2024-07-12',
          searchVolume: 2288,
          organicRank: 89,

          keywordSales: 92
        },
        {
          date: '2024-07-11',
          searchVolume: 2288,
          organicRank: 86,

          keywordSales: 92
        },
        {
          date: '2024-07-10',
          searchVolume: 2288,
          organicRank: 306,

          keywordSales: 92
        },
        {
          date: '2024-07-09',
          searchVolume: 2288,
          organicRank: 91,

          keywordSales: 92
        },
        {
          date: '2024-07-08',
          searchVolume: 2288,
          organicRank: 86,

          keywordSales: 92
        },
        {
          date: '2024-07-07',
          searchVolume: 2288,
          organicRank: 86,

          keywordSales: 92
        },
        {
          date: '2024-07-06',
          searchVolume: 2288,
          organicRank: 94,

          keywordSales: 92
        },
        {
          date: '2024-07-05',
          searchVolume: 2188,
          organicRank: 86,

          keywordSales: 92
        },
        {
          date: '2024-07-04',
          searchVolume: 2188,
          organicRank: 84,

          keywordSales: 92
        },
        {
          date: '2024-07-03',
          searchVolume: 2188,
          organicRank: 90,

          keywordSales: 92
        },
        {
          date: '2024-07-02',
          searchVolume: 2188,
          organicRank: 132,

          keywordSales: 92
        },
        {
          date: '2024-07-01',
          searchVolume: 2188,
          organicRank: 140,

          keywordSales: 92
        },
        {
          date: '2024-06-30',
          searchVolume: 2188,
          organicRank: 103,

          keywordSales: 92
        },
        {
          date: '2024-06-29',
          searchVolume: 2188,
          organicRank: 84,

          keywordSales: 92
        },
        {
          date: '2024-06-28',
          searchVolume: 2244,
          organicRank: 99,

          keywordSales: 92
        },
        {
          date: '2024-06-27',
          searchVolume: 2244,
          organicRank: 99,

          keywordSales: 92
        },
        {
          date: '2024-06-26',
          searchVolume: 2244,
          organicRank: 87,

          keywordSales: 92
        },
        {
          date: '2024-06-25',
          searchVolume: 2244,
          organicRank: 79,

          keywordSales: 92
        },
        {
          date: '2024-06-24',
          searchVolume: 2244,
          organicRank: 95,

          keywordSales: 92
        },
        {
          date: '2024-06-21',
          searchVolume: 1928,
          organicRank: 90,

          keywordSales: 92
        },
        {
          date: '2024-06-20',
          searchVolume: 1928,
          organicRank: 89,

          keywordSales: 92
        },
        {
          date: '2024-06-19',
          searchVolume: 1928,
          organicRank: 98,
          sponsoredRank: 55,
          keywordSales: 92
        },
        {
          date: '2024-06-18',
          searchVolume: 1928,
          organicRank: 97,

          keywordSales: 92
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_bucked up energy drink`,
      keyword: 'bucked up energy drink',
      searchVolume: 4554,
      organicStartRank: 38,
      organicHighestRank: 26,
      sponsoredStartRank: 20,
      sponsoredHighestRank: 20,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 3990,
          organicRank: 33,

          keywordSales: 120
        },
        {
          date: '2024-08-06',
          searchVolume: 3990,
          organicRank: 30,

          keywordSales: 120
        },
        {
          date: '2024-08-05',
          searchVolume: 3990,
          organicRank: 30,

          keywordSales: 120
        },
        {
          date: '2024-08-04',
          searchVolume: 3990,
          organicRank: 33,

          keywordSales: 120
        },
        {
          date: '2024-08-03',
          searchVolume: 3990,
          organicRank: 30,

          keywordSales: 120
        },
        {
          date: '2024-08-01',
          searchVolume: 3990,
          organicRank: 28,

          keywordSales: 120
        },
        {
          date: '2024-07-31',
          searchVolume: 3990,
          organicRank: 31,

          keywordSales: 120
        },
        {
          date: '2024-07-30',
          searchVolume: 3990,
          organicRank: 26,

          keywordSales: 120
        },
        {
          date: '2024-07-29',
          searchVolume: 3990,
          organicRank: 30,

          keywordSales: 120
        },
        {
          date: '2024-07-28',
          searchVolume: 3990,
          organicRank: 29,

          keywordSales: 120
        },
        {
          date: '2024-07-27',
          searchVolume: 3990,
          organicRank: 29,

          keywordSales: 120
        },
        {
          date: '2024-07-26',
          searchVolume: 5658,
          organicRank: 30,

          keywordSales: 120
        },
        {
          date: '2024-07-25',
          searchVolume: 5658,
          organicRank: 31,

          keywordSales: 120
        },
        {
          date: '2024-07-24',
          searchVolume: 5658,
          organicRank: 33,

          keywordSales: 120
        },
        {
          date: '2024-07-23',
          searchVolume: 5658,
          organicRank: 31,

          keywordSales: 120
        },
        {
          date: '2024-07-22',
          searchVolume: 5658,
          organicRank: 33,

          keywordSales: 120
        },
        {
          date: '2024-07-21',
          searchVolume: 5658,
          organicRank: 28,

          keywordSales: 120
        },
        {
          date: '2024-07-20',
          searchVolume: 5658,
          organicRank: 31,

          keywordSales: 120
        },
        {
          date: '2024-07-19',
          searchVolume: 4267,
          organicRank: 30,

          keywordSales: 120
        },
        {
          date: '2024-07-18',
          searchVolume: 4267,
          organicRank: 35,

          keywordSales: 120
        },
        {
          date: '2024-07-17',
          searchVolume: 4267,
          organicRank: 34,

          keywordSales: 120
        },
        {
          date: '2024-07-16',
          searchVolume: 4267,
          organicRank: 33,

          keywordSales: 120
        },
        {
          date: '2024-07-15',
          searchVolume: 4267,
          organicRank: 31,
          sponsoredRank: 26,
          keywordSales: 120
        },
        {
          date: '2024-07-14',
          searchVolume: 4267,
          organicRank: 37,

          keywordSales: 120
        },
        {
          date: '2024-07-13',
          searchVolume: 4267,
          organicRank: 31,
          sponsoredRank: 20,
          keywordSales: 120
        },
        {
          date: '2024-07-12',
          searchVolume: 4134,
          organicRank: 35,

          keywordSales: 120
        },
        {
          date: '2024-07-11',
          searchVolume: 4134,
          organicRank: 35,

          keywordSales: 120
        },
        {
          date: '2024-07-10',
          searchVolume: 4134,
          organicRank: 36,

          keywordSales: 120
        },
        {
          date: '2024-07-09',
          searchVolume: 4134,
          organicRank: 35,

          keywordSales: 120
        },
        {
          date: '2024-07-08',
          searchVolume: 4134,
          organicRank: 34,

          keywordSales: 120
        },
        {
          date: '2024-07-07',
          searchVolume: 4134,
          organicRank: 37,

          keywordSales: 120
        },
        {
          date: '2024-07-06',
          searchVolume: 4134,
          organicRank: 33,

          keywordSales: 120
        },
        {
          date: '2024-07-05',
          searchVolume: 4909,
          organicRank: 33,

          keywordSales: 120
        },
        {
          date: '2024-07-04',
          searchVolume: 4909,
          organicRank: 38,

          keywordSales: 120
        },
        {
          date: '2024-07-03',
          searchVolume: 4909,
          organicRank: 35,

          keywordSales: 120
        },
        {
          date: '2024-07-02',
          searchVolume: 4909,
          organicRank: 35,

          keywordSales: 120
        },
        {
          date: '2024-07-01',
          searchVolume: 4909,
          organicRank: 35,

          keywordSales: 120
        },
        {
          date: '2024-06-30',
          searchVolume: 4909,
          organicRank: 37,

          keywordSales: 120
        },
        {
          date: '2024-06-29',
          searchVolume: 4909,
          organicRank: 33,

          keywordSales: 120
        },
        {
          date: '2024-06-28',
          searchVolume: 4898,
          organicRank: 29,

          keywordSales: 120
        },
        {
          date: '2024-06-27',
          searchVolume: 4898,
          organicRank: 36,

          keywordSales: 120
        },
        {
          date: '2024-06-26',
          searchVolume: 4898,
          organicRank: 50,

          keywordSales: 120
        },
        {
          date: '2024-06-25',
          searchVolume: 4898,
          organicRank: 34,

          keywordSales: 120
        },
        {
          date: '2024-06-24',
          searchVolume: 4898,
          organicRank: 30,

          keywordSales: 120
        },
        {
          date: '2024-06-21',
          searchVolume: 4554,
          organicRank: 34,

          keywordSales: 120
        },
        {
          date: '2024-06-20',
          searchVolume: 4554,
          organicRank: 306,

          keywordSales: 120
        },
        {
          date: '2024-06-19',
          searchVolume: 4554,
          organicRank: 38,

          keywordSales: 120
        },
        {
          date: '2024-06-18',
          searchVolume: 4554,
          organicRank: 40,

          keywordSales: 120
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_bum energy`,
      keyword: 'bum energy',
      searchVolume: 3631,
      organicStartRank: 14,
      organicHighestRank: 12,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-07-03',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-19',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2987,
          organicRank: 13,

          keywordSales: 63
        },
        {
          date: '2024-08-05',
          searchVolume: 2987,
          organicRank: 14,

          keywordSales: 63
        },
        {
          date: '2024-08-04',
          searchVolume: 2987,
          organicRank: 13,

          keywordSales: 63
        },
        {
          date: '2024-08-03',
          searchVolume: 2987,
          organicRank: 13,

          keywordSales: 63
        },
        {
          date: '2024-08-02',
          searchVolume: 2987,
          organicRank: 13,

          keywordSales: 63
        },
        {
          date: '2024-08-01',
          searchVolume: 2987,
          organicRank: 14,

          keywordSales: 63
        },
        {
          date: '2024-07-31',
          searchVolume: 2987,
          organicRank: 12,

          keywordSales: 63
        },
        {
          date: '2024-07-30',
          searchVolume: 2987,
          organicRank: 14,

          keywordSales: 63
        },
        {
          date: '2024-07-29',
          searchVolume: 2987,
          organicRank: 21,

          keywordSales: 63
        },
        {
          date: '2024-07-28',
          searchVolume: 2987,
          organicRank: 20,

          keywordSales: 63
        },
        {
          date: '2024-07-27',
          searchVolume: 2987,
          organicRank: 19,

          keywordSales: 63
        },
        {
          date: '2024-07-26',
          searchVolume: 4344,
          organicRank: 22,

          keywordSales: 63
        },
        {
          date: '2024-07-25',
          searchVolume: 4344,
          organicRank: 24,

          keywordSales: 63
        },
        {
          date: '2024-07-24',
          searchVolume: 4344,
          organicRank: 21,

          keywordSales: 63
        },
        {
          date: '2024-07-23',
          searchVolume: 4344,
          organicRank: 20,

          keywordSales: 63
        },
        {
          date: '2024-07-22',
          searchVolume: 4344,
          organicRank: 18,

          keywordSales: 63
        },
        {
          date: '2024-07-21',
          searchVolume: 4344,
          organicRank: 21,

          keywordSales: 63
        },
        {
          date: '2024-07-20',
          searchVolume: 4344,
          organicRank: 16,

          keywordSales: 63
        },
        {
          date: '2024-07-19',
          searchVolume: 2951,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 63
        },
        {
          date: '2024-07-18',
          searchVolume: 2951,
          organicRank: 21,

          keywordSales: 63
        },
        {
          date: '2024-07-17',
          searchVolume: 2951,
          organicRank: 19,

          keywordSales: 63
        },
        {
          date: '2024-07-16',
          searchVolume: 2951,
          organicRank: 24,

          keywordSales: 63
        },
        {
          date: '2024-07-15',
          searchVolume: 2951,
          organicRank: 20,

          keywordSales: 63
        },
        {
          date: '2024-07-14',
          searchVolume: 2951,
          organicRank: 20,

          keywordSales: 63
        },
        {
          date: '2024-07-13',
          searchVolume: 2951,
          organicRank: 19,

          keywordSales: 63
        },
        {
          date: '2024-07-12',
          searchVolume: 3305,
          organicRank: 18,

          keywordSales: 63
        },
        {
          date: '2024-07-11',
          searchVolume: 3305,
          organicRank: 16,

          keywordSales: 63
        },
        {
          date: '2024-07-10',
          searchVolume: 3305,
          organicRank: 15,

          keywordSales: 63
        },
        {
          date: '2024-07-09',
          searchVolume: 3305,
          organicRank: 15,

          keywordSales: 63
        },
        {
          date: '2024-07-08',
          searchVolume: 3305,
          organicRank: 15,

          keywordSales: 63
        },
        {
          date: '2024-07-07',
          searchVolume: 3305,
          organicRank: 19,

          keywordSales: 63
        },
        {
          date: '2024-07-06',
          searchVolume: 3305,
          organicRank: 15,

          keywordSales: 63
        },
        {
          date: '2024-07-05',
          searchVolume: 3483,
          organicRank: 18,

          keywordSales: 63
        },
        {
          date: '2024-07-04',
          searchVolume: 3483,
          organicRank: 19,

          keywordSales: 63
        },
        {
          date: '2024-07-03',
          searchVolume: 3483,
          organicRank: 16,

          keywordSales: 63
        },
        {
          date: '2024-07-02',
          searchVolume: 3483,
          organicRank: 21,

          keywordSales: 63
        },
        {
          date: '2024-07-01',
          searchVolume: 3483,
          organicRank: 15,

          keywordSales: 63
        },
        {
          date: '2024-06-30',
          searchVolume: 3483,
          organicRank: 14,

          keywordSales: 63
        },
        {
          date: '2024-06-29',
          searchVolume: 3483,
          organicRank: 14,

          keywordSales: 63
        },
        {
          date: '2024-06-28',
          searchVolume: 3618,
          organicRank: 12,

          keywordSales: 63
        },
        {
          date: '2024-06-27',
          searchVolume: 3618,
          organicRank: 15,

          keywordSales: 63
        },
        {
          date: '2024-06-26',
          searchVolume: 3618,
          organicRank: 15,

          keywordSales: 63
        },
        {
          date: '2024-06-25',
          searchVolume: 3618,
          organicRank: 12,

          keywordSales: 63
        },
        {
          date: '2024-06-24',
          searchVolume: 3618,
          organicRank: 15,

          keywordSales: 63
        },
        {
          date: '2024-06-21',
          searchVolume: 3631,
          organicRank: 12,

          keywordSales: 63
        },
        {
          date: '2024-06-20',
          searchVolume: 3631,
          organicRank: 13,

          keywordSales: 63
        },
        {
          date: '2024-06-19',
          searchVolume: 3631,
          organicRank: 14,

          keywordSales: 63
        },
        {
          date: '2024-06-18',
          searchVolume: 3631,
          organicRank: 15,

          keywordSales: 63
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_bum energy drinks`,
      keyword: 'bum energy drinks',
      searchVolume: 2547,
      organicStartRank: 15,
      organicHighestRank: 9,
      sponsoredStartRank: 2,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-06-28',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-06',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2205,
          organicRank: 12,
          sponsoredRank: 22,
          keywordSales: 45
        },
        {
          date: '2024-08-05',
          searchVolume: 2205,
          organicRank: 10,

          keywordSales: 45
        },
        {
          date: '2024-08-04',
          searchVolume: 2205,
          organicRank: 13,

          keywordSales: 45
        },
        {
          date: '2024-08-03',
          searchVolume: 2205,
          organicRank: 9,

          keywordSales: 45
        },
        {
          date: '2024-08-02',
          searchVolume: 2205,
          organicRank: 11,

          keywordSales: 45
        },
        {
          date: '2024-08-01',
          searchVolume: 2205,
          organicRank: 10,

          keywordSales: 45
        },
        {
          date: '2024-07-31',
          searchVolume: 2205,
          organicRank: 11,

          keywordSales: 45
        },
        {
          date: '2024-07-30',
          searchVolume: 2205,
          organicRank: 11,

          keywordSales: 45
        },
        {
          date: '2024-07-29',
          searchVolume: 2205,
          organicRank: 23,

          keywordSales: 45
        },
        {
          date: '2024-07-28',
          searchVolume: 2205,
          organicRank: 11,

          keywordSales: 45
        },
        {
          date: '2024-07-27',
          searchVolume: 2205,
          organicRank: 13,

          keywordSales: 45
        },
        {
          date: '2024-07-26',
          searchVolume: 2984,
          organicRank: 10,

          keywordSales: 45
        },
        {
          date: '2024-07-25',
          searchVolume: 2984,
          organicRank: 18,

          keywordSales: 45
        },
        {
          date: '2024-07-24',
          searchVolume: 2984,
          organicRank: 15,

          keywordSales: 45
        },
        {
          date: '2024-07-23',
          searchVolume: 2984,
          organicRank: 15,

          keywordSales: 45
        },
        {
          date: '2024-07-22',
          searchVolume: 2984,
          organicRank: 12,
          sponsoredRank: 17,
          keywordSales: 45
        },
        {
          date: '2024-07-21',
          searchVolume: 2984,
          organicRank: 13,

          keywordSales: 45
        },
        {
          date: '2024-07-20',
          searchVolume: 2984,
          organicRank: 21,
          sponsoredRank: 22,
          keywordSales: 45
        },
        {
          date: '2024-07-19',
          searchVolume: 2395,
          organicRank: 16,

          keywordSales: 45
        },
        {
          date: '2024-07-18',
          searchVolume: 2395,
          organicRank: 18,

          keywordSales: 45
        },
        {
          date: '2024-07-17',
          searchVolume: 2395,
          organicRank: 11,
          sponsoredRank: 20,
          keywordSales: 45
        },
        {
          date: '2024-07-16',
          searchVolume: 2395,
          organicRank: 20,

          keywordSales: 45
        },
        {
          date: '2024-07-15',
          searchVolume: 2395,
          organicRank: 19,

          keywordSales: 45
        },
        {
          date: '2024-07-14',
          searchVolume: 2395,
          organicRank: 16,
          sponsoredRank: 2,
          keywordSales: 45
        },
        {
          date: '2024-07-13',
          searchVolume: 2395,
          organicRank: 18,
          sponsoredRank: 16,
          keywordSales: 45
        },
        {
          date: '2024-07-12',
          searchVolume: 2989,
          organicRank: 14,

          keywordSales: 45
        },
        {
          date: '2024-07-11',
          searchVolume: 2989,
          organicRank: 18,

          keywordSales: 45
        },
        {
          date: '2024-07-10',
          searchVolume: 2989,
          organicRank: 14,

          keywordSales: 45
        },
        {
          date: '2024-07-09',
          searchVolume: 2989,
          organicRank: 21,

          keywordSales: 45
        },
        {
          date: '2024-07-08',
          searchVolume: 2989,
          organicRank: 18,
          sponsoredRank: 12,
          keywordSales: 45
        },
        {
          date: '2024-07-07',
          searchVolume: 2989,
          organicRank: 20,

          keywordSales: 45
        },
        {
          date: '2024-07-06',
          searchVolume: 2989,
          organicRank: 18,
          sponsoredRank: 2,
          keywordSales: 45
        },
        {
          date: '2024-07-05',
          searchVolume: 2550,
          organicRank: 22,

          keywordSales: 45
        },
        {
          date: '2024-07-04',
          searchVolume: 2550,
          organicRank: 19,

          keywordSales: 45
        },
        {
          date: '2024-07-03',
          searchVolume: 2550,
          organicRank: 20,

          keywordSales: 45
        },
        {
          date: '2024-07-02',
          searchVolume: 2550,
          organicRank: 16,

          keywordSales: 45
        },
        {
          date: '2024-07-01',
          searchVolume: 2550,
          organicRank: 18,

          keywordSales: 45
        },
        {
          date: '2024-06-30',
          searchVolume: 2550,
          organicRank: 16,

          keywordSales: 45
        },
        {
          date: '2024-06-29',
          searchVolume: 2550,
          organicRank: 21,

          keywordSales: 45
        },
        {
          date: '2024-06-28',
          searchVolume: 2385,
          organicRank: 13,

          keywordSales: 45
        },
        {
          date: '2024-06-27',
          searchVolume: 2385,
          organicRank: 28,

          keywordSales: 45
        },
        {
          date: '2024-06-26',
          searchVolume: 2385,
          organicRank: 17,

          keywordSales: 45
        },
        {
          date: '2024-06-25',
          searchVolume: 2385,
          organicRank: 14,

          keywordSales: 45
        },
        {
          date: '2024-06-24',
          searchVolume: 2385,
          organicRank: 19,

          keywordSales: 45
        },
        {
          date: '2024-06-21',
          searchVolume: 2547,
          organicRank: 14,

          keywordSales: 45
        },
        {
          date: '2024-06-20',
          searchVolume: 2547,
          organicRank: 16,

          keywordSales: 45
        },
        {
          date: '2024-06-19',
          searchVolume: 2547,
          organicRank: 15,

          keywordSales: 45
        },
        {
          date: '2024-06-18',
          searchVolume: 2547,
          organicRank: 16,

          keywordSales: 45
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_c4 energy`,
      keyword: 'c4 energy',
      searchVolume: 1659,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 1321,
          organicRank: 2,
          sponsoredRank: 14,
          keywordSales: 50
        },
        {
          date: '2024-08-05',
          searchVolume: 1321,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 50
        },
        {
          date: '2024-08-04',
          searchVolume: 1321,
          organicRank: 3,

          keywordSales: 50
        },
        {
          date: '2024-08-03',
          searchVolume: 1321,
          organicRank: 3,
          sponsoredRank: 2,
          keywordSales: 50
        },
        {
          date: '2024-08-02',
          searchVolume: 1321,
          organicRank: 3,
          sponsoredRank: 1,
          keywordSales: 50
        },
        {
          date: '2024-08-01',
          searchVolume: 1321,
          organicRank: 3,

          keywordSales: 50
        },
        {
          date: '2024-07-31',
          searchVolume: 1321,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-30',
          searchVolume: 1321,
          organicRank: 1,

          keywordSales: 50
        },
        {
          date: '2024-07-29',
          searchVolume: 1321,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-28',
          searchVolume: 1321,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-27',
          searchVolume: 1321,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-26',
          searchVolume: 2431,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-25',
          searchVolume: 2431,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-24',
          searchVolume: 2431,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-23',
          searchVolume: 2431,
          organicRank: 2,
          sponsoredRank: 3,
          keywordSales: 50
        },
        {
          date: '2024-07-22',
          searchVolume: 2431,
          organicRank: 2,
          sponsoredRank: 14,
          keywordSales: 50
        },
        {
          date: '2024-07-21',
          searchVolume: 2431,
          organicRank: 2,
          sponsoredRank: 14,
          keywordSales: 50
        },
        {
          date: '2024-07-20',
          searchVolume: 2431,
          organicRank: 2,
          sponsoredRank: 26,
          keywordSales: 50
        },
        {
          date: '2024-07-19',
          searchVolume: 1560,
          organicRank: 2,
          sponsoredRank: 2,
          keywordSales: 50
        },
        {
          date: '2024-07-18',
          searchVolume: 1560,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-17',
          searchVolume: 1560,
          organicRank: 2,
          sponsoredRank: 26,
          keywordSales: 50
        },
        {
          date: '2024-07-16',
          searchVolume: 1560,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-15',
          searchVolume: 1560,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-07-14',
          searchVolume: 1560,
          organicRank: 2,
          sponsoredRank: 13,
          keywordSales: 50
        },
        {
          date: '2024-07-13',
          searchVolume: 1560,
          organicRank: 2,
          sponsoredRank: 13,
          keywordSales: 50
        },
        {
          date: '2024-07-12',
          searchVolume: 1432,
          organicRank: 2,
          sponsoredRank: 16,
          keywordSales: 50
        },
        {
          date: '2024-07-11',
          searchVolume: 1432,
          organicRank: 2,
          sponsoredRank: 19,
          keywordSales: 50
        },
        {
          date: '2024-07-10',
          searchVolume: 1432,
          organicRank: 2,
          sponsoredRank: 7,
          keywordSales: 50
        },
        {
          date: '2024-07-09',
          searchVolume: 1432,
          organicRank: 2,
          sponsoredRank: 18,
          keywordSales: 50
        },
        {
          date: '2024-07-08',
          searchVolume: 1432,
          organicRank: 2,
          sponsoredRank: 6,
          keywordSales: 50
        },
        {
          date: '2024-07-07',
          searchVolume: 1432,
          organicRank: 2,
          sponsoredRank: 41,
          keywordSales: 50
        },
        {
          date: '2024-07-06',
          searchVolume: 1432,
          organicRank: 2,
          sponsoredRank: 30,
          keywordSales: 50
        },
        {
          date: '2024-07-05',
          searchVolume: 1664,
          organicRank: 2,
          sponsoredRank: 19,
          keywordSales: 50
        },
        {
          date: '2024-07-04',
          searchVolume: 1664,
          organicRank: 1,
          sponsoredRank: 18,
          keywordSales: 50
        },
        {
          date: '2024-07-03',
          searchVolume: 1664,
          organicRank: 2,
          sponsoredRank: 18,
          keywordSales: 50
        },
        {
          date: '2024-07-02',
          searchVolume: 1664,
          organicRank: 2,
          sponsoredRank: 19,
          keywordSales: 50
        },
        {
          date: '2024-07-01',
          searchVolume: 1664,
          organicRank: 2,
          sponsoredRank: 30,
          keywordSales: 50
        },
        {
          date: '2024-06-30',
          searchVolume: 1664,
          organicRank: 2,
          sponsoredRank: 46,
          keywordSales: 50
        },
        {
          date: '2024-06-29',
          searchVolume: 1664,
          organicRank: 2,
          sponsoredRank: 18,
          keywordSales: 50
        },
        {
          date: '2024-06-28',
          searchVolume: 1437,
          organicRank: 2,
          sponsoredRank: 30,
          keywordSales: 50
        },
        {
          date: '2024-06-27',
          searchVolume: 1437,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-06-26',
          searchVolume: 1437,
          organicRank: 2,
          sponsoredRank: 5,
          keywordSales: 50
        },
        {
          date: '2024-06-25',
          searchVolume: 1437,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-06-24',
          searchVolume: 1437,
          organicRank: 2,

          keywordSales: 50
        },
        {
          date: '2024-06-21',
          searchVolume: 1659,
          organicRank: 2,
          sponsoredRank: 14,
          keywordSales: 50
        },
        {
          date: '2024-06-20',
          searchVolume: 1659,
          organicRank: 2,
          sponsoredRank: 14,
          keywordSales: 50
        },
        {
          date: '2024-06-19',
          searchVolume: 1659,
          organicRank: 2,
          sponsoredRank: 29,
          keywordSales: 50
        },
        {
          date: '2024-06-18',
          searchVolume: 1659,
          organicRank: 2,
          sponsoredRank: 17,
          keywordSales: 50
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_c4 energy drink`,
      keyword: 'c4 energy drink',
      searchVolume: 56578,
      organicStartRank: 2,
      organicHighestRank: 1,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 48645,
          organicRank: 3,

          keywordSales: 1280
        },
        {
          date: '2024-08-06',
          searchVolume: 48645,
          organicRank: 3,

          keywordSales: 1280
        },
        {
          date: '2024-08-05',
          searchVolume: 48645,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-08-04',
          searchVolume: 48645,
          organicRank: 4,
          sponsoredRank: 17,
          keywordSales: 1280
        },
        {
          date: '2024-08-02',
          searchVolume: 48645,
          organicRank: 4,

          keywordSales: 1280
        },
        {
          date: '2024-08-01',
          searchVolume: 48645,
          organicRank: 1,
          sponsoredRank: 22,
          keywordSales: 1280
        },
        {
          date: '2024-07-31',
          searchVolume: 48645,
          organicRank: 4,

          keywordSales: 1280
        },
        {
          date: '2024-07-30',
          searchVolume: 48645,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-07-29',
          searchVolume: 48645,
          organicRank: 3,

          keywordSales: 1280
        },
        {
          date: '2024-07-28',
          searchVolume: 48645,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-07-27',
          searchVolume: 48645,
          organicRank: 3,

          keywordSales: 1280
        },
        {
          date: '2024-07-26',
          searchVolume: 78636,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-07-25',
          searchVolume: 78636,
          organicRank: 3,

          keywordSales: 1280
        },
        {
          date: '2024-07-24',
          searchVolume: 78636,
          organicRank: 3,
          sponsoredRank: 13,
          keywordSales: 1280
        },
        {
          date: '2024-07-23',
          searchVolume: 78636,
          organicRank: 3,

          keywordSales: 1280
        },
        {
          date: '2024-07-22',
          searchVolume: 78636,
          organicRank: 3,
          sponsoredRank: 14,
          keywordSales: 1280
        },
        {
          date: '2024-07-21',
          searchVolume: 78636,
          organicRank: 3,

          keywordSales: 1280
        },
        {
          date: '2024-07-20',
          searchVolume: 78636,
          organicRank: 3,
          sponsoredRank: 6,
          keywordSales: 1280
        },
        {
          date: '2024-07-19',
          searchVolume: 50346,
          organicRank: 2,
          sponsoredRank: 16,
          keywordSales: 1280
        },
        {
          date: '2024-07-18',
          searchVolume: 50346,
          organicRank: 3,
          sponsoredRank: 14,
          keywordSales: 1280
        },
        {
          date: '2024-07-17',
          searchVolume: 50346,
          organicRank: 3,
          sponsoredRank: 34,
          keywordSales: 1280
        },
        {
          date: '2024-07-16',
          searchVolume: 50346,
          organicRank: 2,
          sponsoredRank: 17,
          keywordSales: 1280
        },
        {
          date: '2024-07-15',
          searchVolume: 50346,
          organicRank: 2,
          sponsoredRank: 21,
          keywordSales: 1280
        },
        {
          date: '2024-07-14',
          searchVolume: 50346,
          organicRank: 2,
          sponsoredRank: 15,
          keywordSales: 1280
        },
        {
          date: '2024-07-13',
          searchVolume: 50346,
          organicRank: 1,
          sponsoredRank: 15,
          keywordSales: 1280
        },
        {
          date: '2024-07-12',
          searchVolume: 51249,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-07-10',
          searchVolume: 51249,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-07-09',
          searchVolume: 51249,
          organicRank: 3,

          keywordSales: 1280
        },
        {
          date: '2024-07-08',
          searchVolume: 51249,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-07-07',
          searchVolume: 51249,
          organicRank: 2,
          sponsoredRank: 13,
          keywordSales: 1280
        },
        {
          date: '2024-07-06',
          searchVolume: 51249,
          organicRank: 2,
          sponsoredRank: 21,
          keywordSales: 1280
        },
        {
          date: '2024-07-05',
          searchVolume: 56572,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-07-04',
          searchVolume: 56572,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-07-03',
          searchVolume: 56572,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-07-02',
          searchVolume: 56572,
          organicRank: 3,

          keywordSales: 1280
        },
        {
          date: '2024-07-01',
          searchVolume: 56572,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-06-30',
          searchVolume: 56572,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-06-29',
          searchVolume: 56572,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-06-28',
          searchVolume: 56375,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-06-27',
          searchVolume: 56375,
          organicRank: 2,
          sponsoredRank: 22,
          keywordSales: 1280
        },
        {
          date: '2024-06-26',
          searchVolume: 56375,
          organicRank: 2,
          sponsoredRank: 15,
          keywordSales: 1280
        },
        {
          date: '2024-06-25',
          searchVolume: 56375,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-06-21',
          searchVolume: 56578,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-06-20',
          searchVolume: 56578,
          organicRank: 2,
          sponsoredRank: 38,
          keywordSales: 1280
        },
        {
          date: '2024-06-19',
          searchVolume: 56578,
          organicRank: 2,

          keywordSales: 1280
        },
        {
          date: '2024-06-18',
          searchVolume: 56578,
          organicRank: 3,
          sponsoredRank: 23,
          keywordSales: 1280
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_c4 pre workout`,
      keyword: 'c4 pre workout',
      searchVolume: 37875,
      organicStartRank: 11,
      organicHighestRank: 10,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-07-23',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 31699,
          organicRank: 20,

          keywordSales: 1656
        },
        {
          date: '2024-08-06',
          searchVolume: 31699,
          organicRank: 21,

          keywordSales: 1656
        },
        {
          date: '2024-08-05',
          searchVolume: 31699,
          organicRank: 21,

          keywordSales: 1656
        },
        {
          date: '2024-08-04',
          searchVolume: 31699,
          organicRank: 32,

          keywordSales: 1656
        },
        {
          date: '2024-08-02',
          searchVolume: 31699,
          organicRank: 27,

          keywordSales: 1656
        },
        {
          date: '2024-08-01',
          searchVolume: 31699,
          organicRank: 25,

          keywordSales: 1656
        },
        {
          date: '2024-07-31',
          searchVolume: 31699,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-30',
          searchVolume: 31699,
          organicRank: 12,

          keywordSales: 1656
        },
        {
          date: '2024-07-29',
          searchVolume: 31699,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-28',
          searchVolume: 31699,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-27',
          searchVolume: 31699,
          organicRank: 11,
          sponsoredRank: 4,
          keywordSales: 1656
        },
        {
          date: '2024-07-26',
          searchVolume: 42695,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-24',
          searchVolume: 42695,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-23',
          searchVolume: 42695,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-22',
          searchVolume: 42695,
          organicRank: 26,
          sponsoredRank: 16,
          keywordSales: 1656
        },
        {
          date: '2024-07-21',
          searchVolume: 42695,
          organicRank: 22,
          sponsoredRank: 16,
          keywordSales: 1656
        },
        {
          date: '2024-07-20',
          searchVolume: 42695,
          organicRank: 23,
          sponsoredRank: 7,
          keywordSales: 1656
        },
        {
          date: '2024-07-19',
          searchVolume: 35543,
          organicRank: 23,
          sponsoredRank: 8,
          keywordSales: 1656
        },
        {
          date: '2024-07-18',
          searchVolume: 35543,
          organicRank: 31,
          sponsoredRank: 9,
          keywordSales: 1656
        },
        {
          date: '2024-07-17',
          searchVolume: 35543,
          organicRank: 30,

          keywordSales: 1656
        },
        {
          date: '2024-07-16',
          searchVolume: 35543,
          organicRank: 30,

          keywordSales: 1656
        },
        {
          date: '2024-07-15',
          searchVolume: 35543,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-14',
          searchVolume: 35543,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-13',
          searchVolume: 35543,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-12',
          searchVolume: 36204,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-11',
          searchVolume: 36204,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-10',
          searchVolume: 36204,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-09',
          searchVolume: 36204,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-08',
          searchVolume: 36204,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-07',
          searchVolume: 36204,
          organicRank: 19,

          keywordSales: 1656
        },
        {
          date: '2024-07-06',
          searchVolume: 36204,
          organicRank: 12,

          keywordSales: 1656
        },
        {
          date: '2024-07-05',
          searchVolume: 37902,
          organicRank: 19,

          keywordSales: 1656
        },
        {
          date: '2024-07-04',
          searchVolume: 37902,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-03',
          searchVolume: 37902,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-07-02',
          searchVolume: 37902,
          organicRank: 10,

          keywordSales: 1656
        },
        {
          date: '2024-07-01',
          searchVolume: 37902,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-06-30',
          searchVolume: 37902,
          organicRank: 10,

          keywordSales: 1656
        },
        {
          date: '2024-06-29',
          searchVolume: 37902,
          organicRank: 10,

          keywordSales: 1656
        },
        {
          date: '2024-06-28',
          searchVolume: 37757,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-06-27',
          searchVolume: 37757,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-06-26',
          searchVolume: 37757,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-06-25',
          searchVolume: 37757,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-06-24',
          searchVolume: 37757,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-06-23',
          searchVolume: 37757,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-06-22',
          searchVolume: 37757,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-06-21',
          searchVolume: 37875,
          organicRank: 11,
          sponsoredRank: 59,
          keywordSales: 1656
        },
        {
          date: '2024-06-20',
          searchVolume: 37875,
          organicRank: 12,
          sponsoredRank: 6,
          keywordSales: 1656
        },
        {
          date: '2024-06-19',
          searchVolume: 37875,
          organicRank: 11,

          keywordSales: 1656
        },
        {
          date: '2024-06-18',
          searchVolume: 37875,
          organicRank: 10,

          keywordSales: 1656
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_c4 pre workout powder`,
      keyword: 'c4 pre workout powder',
      searchVolume: 3137,
      organicStartRank: 17,
      organicHighestRank: 13,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 6,
      organicPageOneWinDate: '2024-06-25',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-18',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2886,
          organicRank: 39,

          keywordSales: 137
        },
        {
          date: '2024-08-06',
          searchVolume: 2886,
          organicRank: 41,

          keywordSales: 137
        },
        {
          date: '2024-08-05',
          searchVolume: 2886,
          organicRank: 42,

          keywordSales: 137
        },
        {
          date: '2024-08-04',
          searchVolume: 2886,
          organicRank: 306,

          keywordSales: 137
        },
        {
          date: '2024-08-02',
          searchVolume: 2886,
          organicRank: 306,

          keywordSales: 137
        },
        {
          date: '2024-08-01',
          searchVolume: 2886,
          organicRank: 306,

          keywordSales: 137
        },
        {
          date: '2024-07-31',
          searchVolume: 2886,
          organicRank: 306,

          keywordSales: 137
        },
        {
          date: '2024-07-30',
          searchVolume: 2886,
          organicRank: 13,

          keywordSales: 137
        },
        {
          date: '2024-07-29',
          searchVolume: 2886,
          organicRank: 13,

          keywordSales: 137
        },
        {
          date: '2024-07-28',
          searchVolume: 2886,
          organicRank: 13,

          keywordSales: 137
        },
        {
          date: '2024-07-27',
          searchVolume: 2886,
          organicRank: 14,

          keywordSales: 137
        },
        {
          date: '2024-07-26',
          searchVolume: 3459,
          organicRank: 17,

          keywordSales: 137
        },
        {
          date: '2024-07-25',
          searchVolume: 3459,
          organicRank: 22,

          keywordSales: 137
        },
        {
          date: '2024-07-24',
          searchVolume: 3459,
          organicRank: 23,

          keywordSales: 137
        },
        {
          date: '2024-07-23',
          searchVolume: 3459,
          organicRank: 22,

          keywordSales: 137
        },
        {
          date: '2024-07-22',
          searchVolume: 3459,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-07-21',
          searchVolume: 3459,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-07-20',
          searchVolume: 3459,
          organicRank: 18,

          keywordSales: 137
        },
        {
          date: '2024-07-19',
          searchVolume: 2395,
          organicRank: 20,

          keywordSales: 137
        },
        {
          date: '2024-07-18',
          searchVolume: 2395,
          organicRank: 20,
          sponsoredRank: 6,
          keywordSales: 137
        },
        {
          date: '2024-07-17',
          searchVolume: 2395,
          organicRank: 23,

          keywordSales: 137
        },
        {
          date: '2024-07-16',
          searchVolume: 2395,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-07-15',
          searchVolume: 2395,
          organicRank: 17,

          keywordSales: 137
        },
        {
          date: '2024-07-14',
          searchVolume: 2395,
          organicRank: 18,

          keywordSales: 137
        },
        {
          date: '2024-07-13',
          searchVolume: 2395,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-07-12',
          searchVolume: 2440,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-07-11',
          searchVolume: 2440,
          organicRank: 17,

          keywordSales: 137
        },
        {
          date: '2024-07-10',
          searchVolume: 2440,
          organicRank: 17,

          keywordSales: 137
        },
        {
          date: '2024-07-09',
          searchVolume: 2440,
          organicRank: 21,

          keywordSales: 137
        },
        {
          date: '2024-07-08',
          searchVolume: 2440,
          organicRank: 21,

          keywordSales: 137
        },
        {
          date: '2024-07-07',
          searchVolume: 2440,
          organicRank: 21,

          keywordSales: 137
        },
        {
          date: '2024-07-06',
          searchVolume: 2440,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-07-05',
          searchVolume: 2818,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-07-04',
          searchVolume: 2818,
          organicRank: 24,

          keywordSales: 137
        },
        {
          date: '2024-07-03',
          searchVolume: 2818,
          organicRank: 22,

          keywordSales: 137
        },
        {
          date: '2024-07-02',
          searchVolume: 2818,
          organicRank: 22,

          keywordSales: 137
        },
        {
          date: '2024-07-01',
          searchVolume: 2818,
          organicRank: 18,

          keywordSales: 137
        },
        {
          date: '2024-06-30',
          searchVolume: 2818,
          organicRank: 17,

          keywordSales: 137
        },
        {
          date: '2024-06-29',
          searchVolume: 2818,
          organicRank: 20,

          keywordSales: 137
        },
        {
          date: '2024-06-28',
          searchVolume: 2807,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-06-27',
          searchVolume: 2807,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-06-26',
          searchVolume: 2807,
          organicRank: 20,

          keywordSales: 137
        },
        {
          date: '2024-06-25',
          searchVolume: 2807,
          organicRank: 17,

          keywordSales: 137
        },
        {
          date: '2024-06-24',
          searchVolume: 2807,
          organicRank: 23,

          keywordSales: 137
        },
        {
          date: '2024-06-21',
          searchVolume: 3137,
          organicRank: 20,

          keywordSales: 137
        },
        {
          date: '2024-06-20',
          searchVolume: 3137,
          organicRank: 19,

          keywordSales: 137
        },
        {
          date: '2024-06-19',
          searchVolume: 3137,
          organicRank: 17,

          keywordSales: 137
        },
        {
          date: '2024-06-18',
          searchVolume: 3137,
          organicRank: 18,

          keywordSales: 137
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_c4 ripped`,
      keyword: 'c4 ripped',
      searchVolume: 1923,
      organicStartRank: 19,
      organicHighestRank: 14,
      sponsoredStartRank: 14,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-06-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1321,
          organicRank: 19,
          sponsoredRank: 3,
          keywordSales: 61
        },
        {
          date: '2024-08-06',
          searchVolume: 1321,
          organicRank: 14,

          keywordSales: 61
        },
        {
          date: '2024-08-05',
          searchVolume: 1321,
          organicRank: 18,

          keywordSales: 61
        },
        {
          date: '2024-08-04',
          searchVolume: 1321,
          organicRank: 17,

          keywordSales: 61
        },
        {
          date: '2024-08-03',
          searchVolume: 1321,
          organicRank: 17,

          keywordSales: 61
        },
        {
          date: '2024-08-01',
          searchVolume: 1321,
          organicRank: 21,

          keywordSales: 61
        },
        {
          date: '2024-07-31',
          searchVolume: 1321,
          organicRank: 21,

          keywordSales: 61
        },
        {
          date: '2024-07-30',
          searchVolume: 1321,
          organicRank: 20,

          keywordSales: 61
        },
        {
          date: '2024-07-29',
          searchVolume: 1321,
          organicRank: 19,

          keywordSales: 61
        },
        {
          date: '2024-07-28',
          searchVolume: 1321,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-07-27',
          searchVolume: 1321,
          organicRank: 21,

          keywordSales: 61
        },
        {
          date: '2024-07-26',
          searchVolume: 2087,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-07-25',
          searchVolume: 2087,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-07-24',
          searchVolume: 2087,
          organicRank: 21,
          sponsoredRank: 4,
          keywordSales: 61
        },
        {
          date: '2024-07-23',
          searchVolume: 2087,
          organicRank: 19,
          sponsoredRank: 4,
          keywordSales: 61
        },
        {
          date: '2024-07-22',
          searchVolume: 2087,
          organicRank: 20,
          sponsoredRank: 3,
          keywordSales: 61
        },
        {
          date: '2024-07-21',
          searchVolume: 2087,
          organicRank: 19,
          sponsoredRank: 4,
          keywordSales: 61
        },
        {
          date: '2024-07-20',
          searchVolume: 2087,
          organicRank: 19,
          sponsoredRank: 3,
          keywordSales: 61
        },
        {
          date: '2024-07-19',
          searchVolume: 1587,
          organicRank: 19,
          sponsoredRank: 4,
          keywordSales: 61
        },
        {
          date: '2024-07-18',
          searchVolume: 1587,
          organicRank: 22,
          sponsoredRank: 4,
          keywordSales: 61
        },
        {
          date: '2024-07-17',
          searchVolume: 1587,
          organicRank: 16,

          keywordSales: 61
        },
        {
          date: '2024-07-16',
          searchVolume: 1587,
          organicRank: 17,

          keywordSales: 61
        },
        {
          date: '2024-07-15',
          searchVolume: 1587,
          organicRank: 20,

          keywordSales: 61
        },
        {
          date: '2024-07-14',
          searchVolume: 1587,
          organicRank: 19,

          keywordSales: 61
        },
        {
          date: '2024-07-13',
          searchVolume: 1587,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-07-12',
          searchVolume: 1380,
          organicRank: 19,

          keywordSales: 61
        },
        {
          date: '2024-07-11',
          searchVolume: 1380,
          organicRank: 21,

          keywordSales: 61
        },
        {
          date: '2024-07-10',
          searchVolume: 1380,
          organicRank: 21,

          keywordSales: 61
        },
        {
          date: '2024-07-09',
          searchVolume: 1380,
          organicRank: 24,

          keywordSales: 61
        },
        {
          date: '2024-07-08',
          searchVolume: 1380,
          organicRank: 21,

          keywordSales: 61
        },
        {
          date: '2024-07-07',
          searchVolume: 1380,
          organicRank: 25,

          keywordSales: 61
        },
        {
          date: '2024-07-06',
          searchVolume: 1380,
          organicRank: 23,

          keywordSales: 61
        },
        {
          date: '2024-07-05',
          searchVolume: 1664,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-07-04',
          searchVolume: 1664,
          organicRank: 21,
          sponsoredRank: 14,
          keywordSales: 61
        },
        {
          date: '2024-07-03',
          searchVolume: 1664,
          organicRank: 19,

          keywordSales: 61
        },
        {
          date: '2024-07-02',
          searchVolume: 1664,
          organicRank: 21,

          keywordSales: 61
        },
        {
          date: '2024-07-01',
          searchVolume: 1664,
          organicRank: 24,

          keywordSales: 61
        },
        {
          date: '2024-06-30',
          searchVolume: 1664,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-06-29',
          searchVolume: 1664,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-06-28',
          searchVolume: 1846,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-06-27',
          searchVolume: 1846,
          organicRank: 19,

          keywordSales: 61
        },
        {
          date: '2024-06-26',
          searchVolume: 1846,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-06-25',
          searchVolume: 1846,
          organicRank: 22,

          keywordSales: 61
        },
        {
          date: '2024-06-24',
          searchVolume: 1846,
          organicRank: 20,

          keywordSales: 61
        },
        {
          date: '2024-06-21',
          searchVolume: 1923,
          organicRank: 20,

          keywordSales: 61
        },
        {
          date: '2024-06-20',
          searchVolume: 1923,
          organicRank: 20,

          keywordSales: 61
        },
        {
          date: '2024-06-19',
          searchVolume: 1923,
          organicRank: 19,

          keywordSales: 61
        },
        {
          date: '2024-06-18',
          searchVolume: 1923,
          organicRank: 23,

          keywordSales: 61
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_c4 ripped pre workout`,
      keyword: 'c4 ripped pre workout',
      searchVolume: 3462,
      organicStartRank: 16,
      organicHighestRank: 8,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-07-20',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-19',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2351,
          organicRank: 34,

          keywordSales: 143
        },
        {
          date: '2024-08-06',
          searchVolume: 2351,
          organicRank: 39,

          keywordSales: 143
        },
        {
          date: '2024-08-05',
          searchVolume: 2351,
          organicRank: 37,

          keywordSales: 143
        },
        {
          date: '2024-08-04',
          searchVolume: 2351,
          organicRank: 43,

          keywordSales: 143
        },
        {
          date: '2024-08-03',
          searchVolume: 2351,
          organicRank: 39,

          keywordSales: 143
        },
        {
          date: '2024-08-01',
          searchVolume: 2351,
          organicRank: 42,

          keywordSales: 143
        },
        {
          date: '2024-07-31',
          searchVolume: 2351,
          organicRank: 44,

          keywordSales: 143
        },
        {
          date: '2024-07-30',
          searchVolume: 2351,
          organicRank: 21,

          keywordSales: 143
        },
        {
          date: '2024-07-29',
          searchVolume: 2351,
          organicRank: 19,

          keywordSales: 143
        },
        {
          date: '2024-07-28',
          searchVolume: 2351,
          organicRank: 20,

          keywordSales: 143
        },
        {
          date: '2024-07-27',
          searchVolume: 2351,
          organicRank: 20,

          keywordSales: 143
        },
        {
          date: '2024-07-26',
          searchVolume: 3644,
          organicRank: 19,

          keywordSales: 143
        },
        {
          date: '2024-07-25',
          searchVolume: 3644,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-07-24',
          searchVolume: 3644,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-07-23',
          searchVolume: 3644,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-07-22',
          searchVolume: 3644,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-07-21',
          searchVolume: 3644,
          organicRank: 17,
          sponsoredRank: 4,
          keywordSales: 143
        },
        {
          date: '2024-07-20',
          searchVolume: 3644,
          organicRank: 18,
          sponsoredRank: 4,
          keywordSales: 143
        },
        {
          date: '2024-07-19',
          searchVolume: 3399,
          organicRank: 21,
          sponsoredRank: 4,
          keywordSales: 143
        },
        {
          date: '2024-07-18',
          searchVolume: 3399,
          organicRank: 24,

          keywordSales: 143
        },
        {
          date: '2024-07-17',
          searchVolume: 3399,
          organicRank: 25,

          keywordSales: 143
        },
        {
          date: '2024-07-16',
          searchVolume: 3399,
          organicRank: 24,

          keywordSales: 143
        },
        {
          date: '2024-07-15',
          searchVolume: 3399,
          organicRank: 18,

          keywordSales: 143
        },
        {
          date: '2024-07-14',
          searchVolume: 3399,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-07-13',
          searchVolume: 3399,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-07-12',
          searchVolume: 3007,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-07-11',
          searchVolume: 3007,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-07-10',
          searchVolume: 3007,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-07-09',
          searchVolume: 3007,
          organicRank: 20,

          keywordSales: 143
        },
        {
          date: '2024-07-08',
          searchVolume: 3007,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-07-07',
          searchVolume: 3007,
          organicRank: 15,

          keywordSales: 143
        },
        {
          date: '2024-07-06',
          searchVolume: 3007,
          organicRank: 13,

          keywordSales: 143
        },
        {
          date: '2024-07-05',
          searchVolume: 3441,
          organicRank: 10,

          keywordSales: 143
        },
        {
          date: '2024-07-04',
          searchVolume: 3441,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-07-03',
          searchVolume: 3441,
          organicRank: 8,

          keywordSales: 143
        },
        {
          date: '2024-07-02',
          searchVolume: 3441,
          organicRank: 8,

          keywordSales: 143
        },
        {
          date: '2024-07-01',
          searchVolume: 3441,
          organicRank: 10,

          keywordSales: 143
        },
        {
          date: '2024-06-30',
          searchVolume: 3441,
          organicRank: 10,

          keywordSales: 143
        },
        {
          date: '2024-06-29',
          searchVolume: 3441,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-06-28',
          searchVolume: 3452,
          organicRank: 13,

          keywordSales: 143
        },
        {
          date: '2024-06-27',
          searchVolume: 3452,
          organicRank: 10,

          keywordSales: 143
        },
        {
          date: '2024-06-26',
          searchVolume: 3452,
          organicRank: 11,

          keywordSales: 143
        },
        {
          date: '2024-06-25',
          searchVolume: 3452,
          organicRank: 12,

          keywordSales: 143
        },
        {
          date: '2024-06-24',
          searchVolume: 3452,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-06-21',
          searchVolume: 3462,
          organicRank: 11,

          keywordSales: 143
        },
        {
          date: '2024-06-20',
          searchVolume: 3462,
          organicRank: 11,

          keywordSales: 143
        },
        {
          date: '2024-06-19',
          searchVolume: 3462,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-06-18',
          searchVolume: 3462,
          organicRank: 14,

          keywordSales: 143
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_c4 smart energy drink`,
      keyword: 'c4 smart energy drink',
      searchVolume: 4554,
      organicStartRank: 1,
      organicHighestRank: 1,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-28',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 3347,
          organicRank: 1,
          sponsoredRank: 14,
          keywordSales: 164
        },
        {
          date: '2024-08-06',
          searchVolume: 3347,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-08-05',
          searchVolume: 3347,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-08-04',
          searchVolume: 3347,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-08-03',
          searchVolume: 3347,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-08-01',
          searchVolume: 3347,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-31',
          searchVolume: 3347,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-30',
          searchVolume: 3347,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-29',
          searchVolume: 3347,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-28',
          searchVolume: 3347,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-07-27',
          searchVolume: 3347,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-07-26',
          searchVolume: 5535,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-07-25',
          searchVolume: 5535,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-24',
          searchVolume: 5535,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-07-23',
          searchVolume: 5535,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-07-22',
          searchVolume: 5535,
          organicRank: 1,
          sponsoredRank: 13,
          keywordSales: 164
        },
        {
          date: '2024-07-21',
          searchVolume: 5535,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-07-20',
          searchVolume: 5535,
          organicRank: 1,
          sponsoredRank: 13,
          keywordSales: 164
        },
        {
          date: '2024-07-19',
          searchVolume: 4056,
          organicRank: 1,
          sponsoredRank: 13,
          keywordSales: 164
        },
        {
          date: '2024-07-18',
          searchVolume: 4056,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-17',
          searchVolume: 4056,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-07-16',
          searchVolume: 4056,
          organicRank: 1,
          sponsoredRank: 2,
          keywordSales: 164
        },
        {
          date: '2024-07-15',
          searchVolume: 4056,
          organicRank: 1,
          sponsoredRank: 14,
          keywordSales: 164
        },
        {
          date: '2024-07-14',
          searchVolume: 4056,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-07-13',
          searchVolume: 4056,
          organicRank: 1,
          sponsoredRank: 13,
          keywordSales: 164
        },
        {
          date: '2024-07-12',
          searchVolume: 4133,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-07-11',
          searchVolume: 4133,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-10',
          searchVolume: 4133,
          organicRank: 1,
          sponsoredRank: 5,
          keywordSales: 164
        },
        {
          date: '2024-07-09',
          searchVolume: 4133,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-08',
          searchVolume: 4133,
          organicRank: 1,
          sponsoredRank: 13,
          keywordSales: 164
        },
        {
          date: '2024-07-07',
          searchVolume: 4133,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-06',
          searchVolume: 4133,
          organicRank: 1,
          sponsoredRank: 13,
          keywordSales: 164
        },
        {
          date: '2024-07-05',
          searchVolume: 4331,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-04',
          searchVolume: 4331,
          organicRank: 1,
          sponsoredRank: 18,
          keywordSales: 164
        },
        {
          date: '2024-07-03',
          searchVolume: 4331,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-07-02',
          searchVolume: 4331,
          organicRank: 1,
          sponsoredRank: 21,
          keywordSales: 164
        },
        {
          date: '2024-07-01',
          searchVolume: 4331,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-06-30',
          searchVolume: 4331,
          organicRank: 1,
          sponsoredRank: 16,
          keywordSales: 164
        },
        {
          date: '2024-06-29',
          searchVolume: 4331,
          organicRank: 1,
          sponsoredRank: 14,
          keywordSales: 164
        },
        {
          date: '2024-06-28',
          searchVolume: 4537,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-06-27',
          searchVolume: 4537,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-06-26',
          searchVolume: 4537,
          organicRank: 1,
          sponsoredRank: 34,
          keywordSales: 164
        },
        {
          date: '2024-06-25',
          searchVolume: 4537,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-06-24',
          searchVolume: 4537,
          organicRank: 1,

          keywordSales: 164
        },
        {
          date: '2024-06-21',
          searchVolume: 4554,
          organicRank: 1,
          sponsoredRank: 1,
          keywordSales: 164
        },
        {
          date: '2024-06-20',
          searchVolume: 4554,
          organicRank: 1,
          sponsoredRank: 13,
          keywordSales: 164
        },
        {
          date: '2024-06-19',
          searchVolume: 4554,
          organicRank: 1,
          sponsoredRank: 13,
          keywordSales: 164
        },
        {
          date: '2024-06-18',
          searchVolume: 4554,
          organicRank: 1,
          sponsoredRank: 13,
          keywordSales: 164
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_c4 ultimate`,
      keyword: 'c4 ultimate',
      searchVolume: 1934,
      organicStartRank: 7,
      organicHighestRank: 5,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1780,
          organicRank: 6,

          keywordSales: 77
        },
        {
          date: '2024-08-06',
          searchVolume: 1780,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-08-05',
          searchVolume: 1780,
          organicRank: 6,

          keywordSales: 77
        },
        {
          date: '2024-08-04',
          searchVolume: 1780,
          organicRank: 6,

          keywordSales: 77
        },
        {
          date: '2024-08-02',
          searchVolume: 1780,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-08-01',
          searchVolume: 1780,
          organicRank: 6,

          keywordSales: 77
        },
        {
          date: '2024-07-31',
          searchVolume: 1780,
          organicRank: 6,

          keywordSales: 77
        },
        {
          date: '2024-07-30',
          searchVolume: 1780,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-07-29',
          searchVolume: 1780,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-07-28',
          searchVolume: 1780,
          organicRank: 5,
          sponsoredRank: 4,
          keywordSales: 77
        },
        {
          date: '2024-07-27',
          searchVolume: 1780,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-07-26',
          searchVolume: 3091,
          organicRank: 6,

          keywordSales: 77
        },
        {
          date: '2024-07-25',
          searchVolume: 3091,
          organicRank: 6,
          sponsoredRank: 3,
          keywordSales: 77
        },
        {
          date: '2024-07-24',
          searchVolume: 3091,
          organicRank: 7,
          sponsoredRank: 10,
          keywordSales: 77
        },
        {
          date: '2024-07-23',
          searchVolume: 3091,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 77
        },
        {
          date: '2024-07-22',
          searchVolume: 3091,
          organicRank: 8,
          sponsoredRank: 4,
          keywordSales: 77
        },
        {
          date: '2024-07-21',
          searchVolume: 3091,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-07-20',
          searchVolume: 3091,
          organicRank: 8,
          sponsoredRank: 5,
          keywordSales: 77
        },
        {
          date: '2024-07-19',
          searchVolume: 2052,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 77
        },
        {
          date: '2024-07-18',
          searchVolume: 2052,
          organicRank: 8,
          sponsoredRank: 3,
          keywordSales: 77
        },
        {
          date: '2024-07-17',
          searchVolume: 2052,
          organicRank: 9,
          sponsoredRank: 4,
          keywordSales: 77
        },
        {
          date: '2024-07-16',
          searchVolume: 2052,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-07-15',
          searchVolume: 2052,
          organicRank: 9,

          keywordSales: 77
        },
        {
          date: '2024-07-14',
          searchVolume: 2052,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-07-13',
          searchVolume: 2052,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-07-12',
          searchVolume: 2078,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-07-10',
          searchVolume: 2078,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-07-09',
          searchVolume: 2078,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-07-08',
          searchVolume: 2078,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-07-07',
          searchVolume: 2078,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-07-06',
          searchVolume: 2078,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-07-05',
          searchVolume: 2433,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-07-04',
          searchVolume: 2433,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-07-03',
          searchVolume: 2433,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-07-02',
          searchVolume: 2433,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-07-01',
          searchVolume: 2433,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-06-30',
          searchVolume: 2433,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-06-29',
          searchVolume: 2433,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-06-28',
          searchVolume: 2167,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-06-27',
          searchVolume: 2167,
          organicRank: 7,
          sponsoredRank: 5,
          keywordSales: 77
        },
        {
          date: '2024-06-26',
          searchVolume: 2167,
          organicRank: 8,

          keywordSales: 77
        },
        {
          date: '2024-06-25',
          searchVolume: 2167,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-06-21',
          searchVolume: 1934,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-06-20',
          searchVolume: 1934,
          organicRank: 7,
          sponsoredRank: 6,
          keywordSales: 77
        },
        {
          date: '2024-06-19',
          searchVolume: 1934,
          organicRank: 7,

          keywordSales: 77
        },
        {
          date: '2024-06-18',
          searchVolume: 1934,
          organicRank: 8,

          keywordSales: 77
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_c4 ultimate pre workout`,
      keyword: 'c4 ultimate pre workout',
      searchVolume: 1926,
      organicStartRank: 17,
      organicHighestRank: 13,
      sponsoredStartRank: 19,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-07-22',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-29',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-08-06',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-08-05',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-08-04',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-08-03',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-08-01',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-07-31',
          searchVolume: 878,
          organicRank: 306,

          keywordSales: 40
        },
        {
          date: '2024-07-30',
          searchVolume: 878,
          organicRank: 19,

          keywordSales: 40
        },
        {
          date: '2024-07-29',
          searchVolume: 878,
          organicRank: 19,

          keywordSales: 40
        },
        {
          date: '2024-07-28',
          searchVolume: 878,
          organicRank: 15,

          keywordSales: 40
        },
        {
          date: '2024-07-27',
          searchVolume: 878,
          organicRank: 20,

          keywordSales: 40
        },
        {
          date: '2024-07-26',
          searchVolume: 1380,
          organicRank: 21,

          keywordSales: 40
        },
        {
          date: '2024-07-25',
          searchVolume: 1380,
          organicRank: 22,

          keywordSales: 40
        },
        {
          date: '2024-07-24',
          searchVolume: 1380,
          organicRank: 22,

          keywordSales: 40
        },
        {
          date: '2024-07-23',
          searchVolume: 1380,
          organicRank: 20,
          sponsoredRank: 2,
          keywordSales: 40
        },
        {
          date: '2024-07-22',
          searchVolume: 1380,
          organicRank: 20,
          sponsoredRank: 3,
          keywordSales: 40
        },
        {
          date: '2024-07-21',
          searchVolume: 1380,
          organicRank: 24,
          sponsoredRank: 4,
          keywordSales: 40
        },
        {
          date: '2024-07-20',
          searchVolume: 1380,
          organicRank: 24,
          sponsoredRank: 4,
          keywordSales: 40
        },
        {
          date: '2024-07-19',
          searchVolume: 1355,
          organicRank: 23,
          sponsoredRank: 5,
          keywordSales: 40
        },
        {
          date: '2024-07-18',
          searchVolume: 1355,
          organicRank: 23,
          sponsoredRank: 4,
          keywordSales: 40
        },
        {
          date: '2024-07-17',
          searchVolume: 1355,
          organicRank: 21,

          keywordSales: 40
        },
        {
          date: '2024-07-16',
          searchVolume: 1355,
          organicRank: 20,

          keywordSales: 40
        },
        {
          date: '2024-07-15',
          searchVolume: 1355,
          organicRank: 19,

          keywordSales: 40
        },
        {
          date: '2024-07-14',
          searchVolume: 1355,
          organicRank: 18,

          keywordSales: 40
        },
        {
          date: '2024-07-13',
          searchVolume: 1355,
          organicRank: 18,

          keywordSales: 40
        },
        {
          date: '2024-07-12',
          searchVolume: 1584,
          organicRank: 19,

          keywordSales: 40
        },
        {
          date: '2024-07-11',
          searchVolume: 1584,
          organicRank: 15,

          keywordSales: 40
        },
        {
          date: '2024-07-10',
          searchVolume: 1584,
          organicRank: 20,

          keywordSales: 40
        },
        {
          date: '2024-07-09',
          searchVolume: 1584,
          organicRank: 17,

          keywordSales: 40
        },
        {
          date: '2024-07-08',
          searchVolume: 1584,
          organicRank: 20,

          keywordSales: 40
        },
        {
          date: '2024-07-07',
          searchVolume: 1584,
          organicRank: 18,

          keywordSales: 40
        },
        {
          date: '2024-07-06',
          searchVolume: 1584,
          organicRank: 17,

          keywordSales: 40
        },
        {
          date: '2024-07-05',
          searchVolume: 1664,
          organicRank: 15,

          keywordSales: 40
        },
        {
          date: '2024-07-04',
          searchVolume: 1664,
          organicRank: 15,

          keywordSales: 40
        },
        {
          date: '2024-07-03',
          searchVolume: 1664,
          organicRank: 14,

          keywordSales: 40
        },
        {
          date: '2024-07-02',
          searchVolume: 1664,
          organicRank: 14,

          keywordSales: 40
        },
        {
          date: '2024-07-01',
          searchVolume: 1664,
          organicRank: 14,

          keywordSales: 40
        },
        {
          date: '2024-06-30',
          searchVolume: 1664,
          organicRank: 15,

          keywordSales: 40
        },
        {
          date: '2024-06-29',
          searchVolume: 1664,
          organicRank: 19,
          sponsoredRank: 19,
          keywordSales: 40
        },
        {
          date: '2024-06-28',
          searchVolume: 1911,
          organicRank: 15,

          keywordSales: 40
        },
        {
          date: '2024-06-27',
          searchVolume: 1911,
          organicRank: 13,

          keywordSales: 40
        },
        {
          date: '2024-06-26',
          searchVolume: 1911,
          organicRank: 14,

          keywordSales: 40
        },
        {
          date: '2024-06-25',
          searchVolume: 1911,
          organicRank: 14,

          keywordSales: 40
        },
        {
          date: '2024-06-24',
          searchVolume: 1911,
          organicRank: 16,

          keywordSales: 40
        },
        {
          date: '2024-06-21',
          searchVolume: 1926,
          organicRank: 17,

          keywordSales: 40
        },
        {
          date: '2024-06-20',
          searchVolume: 1926,
          organicRank: 16,

          keywordSales: 40
        },
        {
          date: '2024-06-19',
          searchVolume: 1926,
          organicRank: 17,

          keywordSales: 40
        },
        {
          date: '2024-06-18',
          searchVolume: 1926,
          organicRank: 18,

          keywordSales: 40
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_caffeine free energy drink`,
      keyword: 'caffeine free energy drink',
      searchVolume: 1685,
      organicStartRank: 19,
      organicHighestRank: 12,
      sponsoredStartRank: 14,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-08-02',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1533,
          organicRank: 27,

          keywordSales: 7
        },
        {
          date: '2024-08-06',
          searchVolume: 1533,
          organicRank: 17,

          keywordSales: 7
        },
        {
          date: '2024-08-05',
          searchVolume: 1533,
          organicRank: 20,

          keywordSales: 7
        },
        {
          date: '2024-08-04',
          searchVolume: 1533,
          organicRank: 22,
          sponsoredRank: 4,
          keywordSales: 7
        },
        {
          date: '2024-08-02',
          searchVolume: 1533,
          organicRank: 13,

          keywordSales: 7
        },
        {
          date: '2024-08-01',
          searchVolume: 1533,
          organicRank: 23,

          keywordSales: 7
        },
        {
          date: '2024-07-31',
          searchVolume: 1533,
          organicRank: 26,

          keywordSales: 7
        },
        {
          date: '2024-07-30',
          searchVolume: 1533,
          organicRank: 22,

          keywordSales: 7
        },
        {
          date: '2024-07-29',
          searchVolume: 1533,
          organicRank: 49,

          keywordSales: 7
        },
        {
          date: '2024-07-28',
          searchVolume: 1533,
          organicRank: 33,

          keywordSales: 7
        },
        {
          date: '2024-07-27',
          searchVolume: 1533,
          organicRank: 28,

          keywordSales: 7
        },
        {
          date: '2024-07-26',
          searchVolume: 1366,
          organicRank: 31,

          keywordSales: 7
        },
        {
          date: '2024-07-25',
          searchVolume: 1366,
          organicRank: 37,

          keywordSales: 7
        },
        {
          date: '2024-07-24',
          searchVolume: 1366,
          organicRank: 38,

          keywordSales: 7
        },
        {
          date: '2024-07-23',
          searchVolume: 1366,
          organicRank: 43,

          keywordSales: 7
        },
        {
          date: '2024-07-22',
          searchVolume: 1366,
          organicRank: 34,

          keywordSales: 7
        },
        {
          date: '2024-07-21',
          searchVolume: 1366,
          organicRank: 49,

          keywordSales: 7
        },
        {
          date: '2024-07-20',
          searchVolume: 1366,
          organicRank: 41,

          keywordSales: 7
        },
        {
          date: '2024-07-19',
          searchVolume: 1344,
          organicRank: 44,

          keywordSales: 7
        },
        {
          date: '2024-07-18',
          searchVolume: 1344,
          organicRank: 33,

          keywordSales: 7
        },
        {
          date: '2024-07-17',
          searchVolume: 1344,
          organicRank: 34,

          keywordSales: 7
        },
        {
          date: '2024-07-16',
          searchVolume: 1344,
          organicRank: 32,

          keywordSales: 7
        },
        {
          date: '2024-07-15',
          searchVolume: 1344,
          organicRank: 38,

          keywordSales: 7
        },
        {
          date: '2024-07-14',
          searchVolume: 1344,
          organicRank: 31,

          keywordSales: 7
        },
        {
          date: '2024-07-13',
          searchVolume: 1344,
          organicRank: 23,

          keywordSales: 7
        },
        {
          date: '2024-07-12',
          searchVolume: 1536,
          organicRank: 28,

          keywordSales: 7
        },
        {
          date: '2024-07-11',
          searchVolume: 1536,
          organicRank: 33,

          keywordSales: 7
        },
        {
          date: '2024-07-10',
          searchVolume: 1536,
          organicRank: 28,

          keywordSales: 7
        },
        {
          date: '2024-07-09',
          searchVolume: 1536,
          organicRank: 26,

          keywordSales: 7
        },
        {
          date: '2024-07-08',
          searchVolume: 1536,
          organicRank: 30,

          keywordSales: 7
        },
        {
          date: '2024-07-07',
          searchVolume: 1536,
          organicRank: 34,

          keywordSales: 7
        },
        {
          date: '2024-07-06',
          searchVolume: 1536,
          organicRank: 83,

          keywordSales: 7
        },
        {
          date: '2024-07-05',
          searchVolume: 1660,
          organicRank: 30,

          keywordSales: 7
        },
        {
          date: '2024-07-04',
          searchVolume: 1660,
          organicRank: 28,

          keywordSales: 7
        },
        {
          date: '2024-07-03',
          searchVolume: 1660,
          organicRank: 23,

          keywordSales: 7
        },
        {
          date: '2024-07-02',
          searchVolume: 1660,
          organicRank: 29,

          keywordSales: 7
        },
        {
          date: '2024-07-01',
          searchVolume: 1660,
          organicRank: 24,

          keywordSales: 7
        },
        {
          date: '2024-06-30',
          searchVolume: 1660,
          organicRank: 22,

          keywordSales: 7
        },
        {
          date: '2024-06-29',
          searchVolume: 1660,
          organicRank: 29,

          keywordSales: 7
        },
        {
          date: '2024-06-28',
          searchVolume: 1428,
          organicRank: 25,

          keywordSales: 7
        },
        {
          date: '2024-06-27',
          searchVolume: 1428,
          organicRank: 17,

          keywordSales: 7
        },
        {
          date: '2024-06-26',
          searchVolume: 1428,
          organicRank: 17,

          keywordSales: 7
        },
        {
          date: '2024-06-25',
          searchVolume: 1428,
          organicRank: 16,

          keywordSales: 7
        },
        {
          date: '2024-06-24',
          searchVolume: 1428,
          organicRank: 14,

          keywordSales: 7
        },
        {
          date: '2024-06-21',
          searchVolume: 1685,
          organicRank: 18,

          keywordSales: 7
        },
        {
          date: '2024-06-20',
          searchVolume: 1685,
          organicRank: 12,
          sponsoredRank: 14,
          keywordSales: 7
        },
        {
          date: '2024-06-19',
          searchVolume: 1685,
          organicRank: 19,

          keywordSales: 7
        },
        {
          date: '2024-06-18',
          searchVolume: 1685,
          organicRank: 18,

          keywordSales: 7
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_caffeine powder`,
      keyword: 'caffeine powder',
      searchVolume: 8484,
      organicStartRank: 306,
      organicHighestRank: 290,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 7831,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-08-05',
          searchVolume: 7831,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-08-04',
          searchVolume: 7831,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-08-01',
          searchVolume: 7831,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-31',
          searchVolume: 7831,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-30',
          searchVolume: 7831,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-29',
          searchVolume: 7831,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-28',
          searchVolume: 7831,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-27',
          searchVolume: 7831,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-26',
          searchVolume: 6450,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-25',
          searchVolume: 6450,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-24',
          searchVolume: 6450,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-23',
          searchVolume: 6450,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-22',
          searchVolume: 6450,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-21',
          searchVolume: 6450,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-20',
          searchVolume: 6450,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-19',
          searchVolume: 8279,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-18',
          searchVolume: 8279,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-17',
          searchVolume: 8279,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-16',
          searchVolume: 8279,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-15',
          searchVolume: 8279,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-14',
          searchVolume: 8279,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-13',
          searchVolume: 8279,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-12',
          searchVolume: 8627,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-11',
          searchVolume: 8627,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-10',
          searchVolume: 8627,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-09',
          searchVolume: 8627,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-08',
          searchVolume: 8627,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-07',
          searchVolume: 8627,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-06',
          searchVolume: 8627,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-05',
          searchVolume: 9020,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-04',
          searchVolume: 9020,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-03',
          searchVolume: 9020,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-02',
          searchVolume: 9020,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-01',
          searchVolume: 9020,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-30',
          searchVolume: 9020,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-29',
          searchVolume: 9020,
          organicRank: 290,

          keywordSales: 223
        },
        {
          date: '2024-06-28',
          searchVolume: 8456,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-27',
          searchVolume: 8456,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-26',
          searchVolume: 8456,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-25',
          searchVolume: 8456,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-24',
          searchVolume: 8456,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-21',
          searchVolume: 8484,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-20',
          searchVolume: 8484,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-19',
          searchVolume: 8484,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-18',
          searchVolume: 8484,
          organicRank: 80,

          keywordSales: 223
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_celcius energy drink`,
      keyword: 'celcius energy drink',
      searchVolume: 196,
      organicStartRank: 10,
      organicHighestRank: 10,
      sponsoredStartRank: 8,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 307,
          organicRank: 26,

          keywordSales: 42
        },
        {
          date: '2024-08-05',
          searchVolume: 307,
          organicRank: 38,

          keywordSales: 42
        },
        {
          date: '2024-08-04',
          searchVolume: 307,
          organicRank: 46,

          keywordSales: 42
        },
        {
          date: '2024-08-03',
          searchVolume: 307,
          organicRank: 36,

          keywordSales: 42
        },
        {
          date: '2024-08-02',
          searchVolume: 307,
          organicRank: 21,

          keywordSales: 42
        },
        {
          date: '2024-08-01',
          searchVolume: 307,
          organicRank: 18,

          keywordSales: 42
        },
        {
          date: '2024-07-31',
          searchVolume: 307,
          organicRank: 48,

          keywordSales: 42
        },
        {
          date: '2024-07-30',
          searchVolume: 307,
          organicRank: 41,

          keywordSales: 42
        },
        {
          date: '2024-07-29',
          searchVolume: 307,
          organicRank: 44,

          keywordSales: 42
        },
        {
          date: '2024-07-28',
          searchVolume: 307,
          organicRank: 60,

          keywordSales: 42
        },
        {
          date: '2024-07-27',
          searchVolume: 307,
          organicRank: 38,

          keywordSales: 42
        },
        {
          date: '2024-07-26',
          searchVolume: 571,
          organicRank: 17,

          keywordSales: 42
        },
        {
          date: '2024-07-25',
          searchVolume: 571,
          organicRank: 28,

          keywordSales: 42
        },
        {
          date: '2024-07-24',
          searchVolume: 571,
          organicRank: 22,

          keywordSales: 42
        },
        {
          date: '2024-07-23',
          searchVolume: 571,
          organicRank: 23,

          keywordSales: 42
        },
        {
          date: '2024-07-22',
          searchVolume: 571,
          organicRank: 62,

          keywordSales: 42
        },
        {
          date: '2024-07-21',
          searchVolume: 571,
          organicRank: 20,

          keywordSales: 42
        },
        {
          date: '2024-07-20',
          searchVolume: 571,
          organicRank: 27,

          keywordSales: 42
        },
        {
          date: '2024-07-19',
          searchVolume: 401,
          organicRank: 38,

          keywordSales: 42
        },
        {
          date: '2024-07-18',
          searchVolume: 401,
          organicRank: 55,

          keywordSales: 42
        },
        {
          date: '2024-07-17',
          searchVolume: 401,
          organicRank: 34,
          sponsoredRank: 2,
          keywordSales: 42
        },
        {
          date: '2024-07-16',
          searchVolume: 401,
          organicRank: 18,
          sponsoredRank: 53,
          keywordSales: 42
        },
        {
          date: '2024-07-15',
          searchVolume: 401,
          organicRank: 14,
          sponsoredRank: 40,
          keywordSales: 42
        },
        {
          date: '2024-07-14',
          searchVolume: 401,
          organicRank: 23,

          keywordSales: 42
        },
        {
          date: '2024-07-13',
          searchVolume: 401,
          organicRank: 50,
          sponsoredRank: 36,
          keywordSales: 42
        },
        {
          date: '2024-07-12',
          searchVolume: 404,
          organicRank: 33,

          keywordSales: 42
        },
        {
          date: '2024-07-10',
          searchVolume: 404,
          organicRank: 14,

          keywordSales: 42
        },
        {
          date: '2024-07-09',
          searchVolume: 404,
          organicRank: 14,

          keywordSales: 42
        },
        {
          date: '2024-07-08',
          searchVolume: 404,
          organicRank: 21,

          keywordSales: 42
        },
        {
          date: '2024-07-07',
          searchVolume: 404,
          organicRank: 34,

          keywordSales: 42
        },
        {
          date: '2024-07-06',
          searchVolume: 404,
          organicRank: 19,

          keywordSales: 42
        },
        {
          date: '2024-07-05',
          searchVolume: 370,
          organicRank: 67,
          sponsoredRank: 14,
          keywordSales: 42
        },
        {
          date: '2024-07-04',
          searchVolume: 370,
          organicRank: 23,
          sponsoredRank: 15,
          keywordSales: 42
        },
        {
          date: '2024-07-03',
          searchVolume: 370,
          organicRank: 25,

          keywordSales: 42
        },
        {
          date: '2024-07-02',
          searchVolume: 370,
          organicRank: 10,

          keywordSales: 42
        },
        {
          date: '2024-07-01',
          searchVolume: 370,
          organicRank: 11,

          keywordSales: 42
        },
        {
          date: '2024-06-30',
          searchVolume: 370,
          organicRank: 17,

          keywordSales: 42
        },
        {
          date: '2024-06-29',
          searchVolume: 370,
          organicRank: 23,

          keywordSales: 42
        },
        {
          date: '2024-06-28',
          searchVolume: 159,
          organicRank: 59,

          keywordSales: 42
        },
        {
          date: '2024-06-27',
          searchVolume: 159,
          organicRank: 10,
          sponsoredRank: 38,
          keywordSales: 42
        },
        {
          date: '2024-06-26',
          searchVolume: 159,
          organicRank: 21,

          keywordSales: 42
        },
        {
          date: '2024-06-25',
          searchVolume: 159,
          organicRank: 27,

          keywordSales: 42
        },
        {
          date: '2024-06-24',
          searchVolume: 159,
          organicRank: 21,
          sponsoredRank: 16,
          keywordSales: 42
        },
        {
          date: '2024-06-21',
          searchVolume: 196,
          organicRank: 91,

          keywordSales: 42
        },
        {
          date: '2024-06-20',
          searchVolume: 196,
          organicRank: 11,
          sponsoredRank: 23,
          keywordSales: 42
        },
        {
          date: '2024-06-19',
          searchVolume: 196,
          organicRank: 10,

          keywordSales: 42
        },
        {
          date: '2024-06-18',
          searchVolume: 196,
          organicRank: 35,
          sponsoredRank: 8,
          keywordSales: 42
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_celsius drinks`,
      keyword: 'celsius drinks',
      searchVolume: 16646,
      organicStartRank: 76,
      organicHighestRank: 25,
      sponsoredStartRank: 5,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-19',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 11262,
          organicRank: 37,

          keywordSales: 300
        },
        {
          date: '2024-08-06',
          searchVolume: 11262,
          organicRank: 30,

          keywordSales: 300
        },
        {
          date: '2024-08-05',
          searchVolume: 11262,
          organicRank: 28,

          keywordSales: 300
        },
        {
          date: '2024-08-03',
          searchVolume: 11262,
          organicRank: 33,

          keywordSales: 300
        },
        {
          date: '2024-08-02',
          searchVolume: 11262,
          organicRank: 34,

          keywordSales: 300
        },
        {
          date: '2024-08-01',
          searchVolume: 11262,
          organicRank: 57,

          keywordSales: 300
        },
        {
          date: '2024-07-31',
          searchVolume: 11262,
          organicRank: 26,

          keywordSales: 300
        },
        {
          date: '2024-07-30',
          searchVolume: 11262,
          organicRank: 29,

          keywordSales: 300
        },
        {
          date: '2024-07-28',
          searchVolume: 11262,
          organicRank: 26,

          keywordSales: 300
        },
        {
          date: '2024-07-27',
          searchVolume: 11262,
          organicRank: 26,

          keywordSales: 300
        },
        {
          date: '2024-07-26',
          searchVolume: 30682,
          organicRank: 27,

          keywordSales: 300
        },
        {
          date: '2024-07-25',
          searchVolume: 30682,
          organicRank: 28,

          keywordSales: 300
        },
        {
          date: '2024-07-24',
          searchVolume: 30682,
          organicRank: 36,

          keywordSales: 300
        },
        {
          date: '2024-07-23',
          searchVolume: 30682,
          organicRank: 40,

          keywordSales: 300
        },
        {
          date: '2024-07-22',
          searchVolume: 30682,
          organicRank: 27,

          keywordSales: 300
        },
        {
          date: '2024-07-21',
          searchVolume: 30682,
          organicRank: 42,

          keywordSales: 300
        },
        {
          date: '2024-07-20',
          searchVolume: 30682,
          organicRank: 35,

          keywordSales: 300
        },
        {
          date: '2024-07-19',
          searchVolume: 13257,
          organicRank: 36,

          keywordSales: 300
        },
        {
          date: '2024-07-18',
          searchVolume: 13257,
          organicRank: 39,

          keywordSales: 300
        },
        {
          date: '2024-07-17',
          searchVolume: 13257,
          organicRank: 37,

          keywordSales: 300
        },
        {
          date: '2024-07-16',
          searchVolume: 13257,
          organicRank: 47,
          sponsoredRank: 6,
          keywordSales: 300
        },
        {
          date: '2024-07-15',
          searchVolume: 13257,
          organicRank: 42,

          keywordSales: 300
        },
        {
          date: '2024-07-14',
          searchVolume: 13257,
          organicRank: 48,

          keywordSales: 300
        },
        {
          date: '2024-07-13',
          searchVolume: 13257,
          organicRank: 46,

          keywordSales: 300
        },
        {
          date: '2024-07-12',
          searchVolume: 11251,
          organicRank: 47,

          keywordSales: 300
        },
        {
          date: '2024-07-11',
          searchVolume: 11251,
          organicRank: 42,

          keywordSales: 300
        },
        {
          date: '2024-07-10',
          searchVolume: 11251,
          organicRank: 35,

          keywordSales: 300
        },
        {
          date: '2024-07-09',
          searchVolume: 11251,
          organicRank: 29,

          keywordSales: 300
        },
        {
          date: '2024-07-08',
          searchVolume: 11251,
          organicRank: 38,

          keywordSales: 300
        },
        {
          date: '2024-07-07',
          searchVolume: 11251,
          organicRank: 39,

          keywordSales: 300
        },
        {
          date: '2024-07-06',
          searchVolume: 11251,
          organicRank: 39,

          keywordSales: 300
        },
        {
          date: '2024-07-05',
          searchVolume: 12209,
          organicRank: 29,
          sponsoredRank: 3,
          keywordSales: 300
        },
        {
          date: '2024-07-04',
          searchVolume: 12209,
          organicRank: 37,

          keywordSales: 300
        },
        {
          date: '2024-07-03',
          searchVolume: 12209,
          organicRank: 30,

          keywordSales: 300
        },
        {
          date: '2024-07-02',
          searchVolume: 12209,
          organicRank: 25,

          keywordSales: 300
        },
        {
          date: '2024-07-01',
          searchVolume: 12209,
          organicRank: 36,
          sponsoredRank: 3,
          keywordSales: 300
        },
        {
          date: '2024-06-30',
          searchVolume: 12209,
          organicRank: 33,

          keywordSales: 300
        },
        {
          date: '2024-06-29',
          searchVolume: 12209,
          organicRank: 28,

          keywordSales: 300
        },
        {
          date: '2024-06-28',
          searchVolume: 13405,
          organicRank: 36,
          sponsoredRank: 10,
          keywordSales: 300
        },
        {
          date: '2024-06-27',
          searchVolume: 13405,
          organicRank: 43,

          keywordSales: 300
        },
        {
          date: '2024-06-26',
          searchVolume: 13405,
          organicRank: 31,

          keywordSales: 300
        },
        {
          date: '2024-06-25',
          searchVolume: 13405,
          organicRank: 41,

          keywordSales: 300
        },
        {
          date: '2024-06-21',
          searchVolume: 16646,
          organicRank: 40,

          keywordSales: 300
        },
        {
          date: '2024-06-20',
          searchVolume: 16646,
          organicRank: 46,
          sponsoredRank: 11,
          keywordSales: 300
        },
        {
          date: '2024-06-19',
          searchVolume: 16646,
          organicRank: 76,
          sponsoredRank: 5,
          keywordSales: 300
        },
        {
          date: '2024-06-18',
          searchVolume: 16646,
          organicRank: 90,

          keywordSales: 300
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_celsius energy drink`,
      keyword: 'celsius energy drink',
      searchVolume: 118501,
      organicStartRank: 10,
      organicHighestRank: 10,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 7,
      organicPageOneWinDate: '2024-06-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-19',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 90794,
          organicRank: 22,

          keywordSales: 1347
        },
        {
          date: '2024-08-06',
          searchVolume: 90794,
          organicRank: 50,

          keywordSales: 1347
        },
        {
          date: '2024-08-05',
          searchVolume: 90794,
          organicRank: 20,

          keywordSales: 1347
        },
        {
          date: '2024-08-04',
          searchVolume: 90794,
          organicRank: 27,

          keywordSales: 1347
        },
        {
          date: '2024-08-03',
          searchVolume: 90794,
          organicRank: 38,

          keywordSales: 1347
        },
        {
          date: '2024-08-01',
          searchVolume: 90794,
          organicRank: 19,

          keywordSales: 1347
        },
        {
          date: '2024-07-31',
          searchVolume: 90794,
          organicRank: 56,

          keywordSales: 1347
        },
        {
          date: '2024-07-30',
          searchVolume: 90794,
          organicRank: 42,

          keywordSales: 1347
        },
        {
          date: '2024-07-29',
          searchVolume: 90794,
          organicRank: 36,

          keywordSales: 1347
        },
        {
          date: '2024-07-28',
          searchVolume: 90794,
          organicRank: 42,

          keywordSales: 1347
        },
        {
          date: '2024-07-27',
          searchVolume: 90794,
          organicRank: 45,

          keywordSales: 1347
        },
        {
          date: '2024-07-26',
          searchVolume: 184084,
          organicRank: 18,

          keywordSales: 1347
        },
        {
          date: '2024-07-25',
          searchVolume: 184084,
          organicRank: 18,

          keywordSales: 1347
        },
        {
          date: '2024-07-24',
          searchVolume: 184084,
          organicRank: 23,

          keywordSales: 1347
        },
        {
          date: '2024-07-23',
          searchVolume: 184084,
          organicRank: 19,

          keywordSales: 1347
        },
        {
          date: '2024-07-22',
          searchVolume: 184084,
          organicRank: 23,

          keywordSales: 1347
        },
        {
          date: '2024-07-21',
          searchVolume: 184084,
          organicRank: 75,

          keywordSales: 1347
        },
        {
          date: '2024-07-20',
          searchVolume: 184084,
          organicRank: 27,

          keywordSales: 1347
        },
        {
          date: '2024-07-19',
          searchVolume: 92165,
          organicRank: 37,

          keywordSales: 1347
        },
        {
          date: '2024-07-18',
          searchVolume: 92165,
          organicRank: 37,

          keywordSales: 1347
        },
        {
          date: '2024-07-17',
          searchVolume: 92165,
          organicRank: 21,
          sponsoredRank: 33,
          keywordSales: 1347
        },
        {
          date: '2024-07-16',
          searchVolume: 92165,
          organicRank: 19,

          keywordSales: 1347
        },
        {
          date: '2024-07-15',
          searchVolume: 92165,
          organicRank: 16,
          sponsoredRank: 23,
          keywordSales: 1347
        },
        {
          date: '2024-07-14',
          searchVolume: 92165,
          organicRank: 21,

          keywordSales: 1347
        },
        {
          date: '2024-07-13',
          searchVolume: 92165,
          organicRank: 66,

          keywordSales: 1347
        },
        {
          date: '2024-07-12',
          searchVolume: 81935,
          organicRank: 27,

          keywordSales: 1347
        },
        {
          date: '2024-07-10',
          searchVolume: 81935,
          organicRank: 16,

          keywordSales: 1347
        },
        {
          date: '2024-07-09',
          searchVolume: 81935,
          organicRank: 16,

          keywordSales: 1347
        },
        {
          date: '2024-07-08',
          searchVolume: 81935,
          organicRank: 21,

          keywordSales: 1347
        },
        {
          date: '2024-07-07',
          searchVolume: 81935,
          organicRank: 28,

          keywordSales: 1347
        },
        {
          date: '2024-07-06',
          searchVolume: 81935,
          organicRank: 20,

          keywordSales: 1347
        },
        {
          date: '2024-07-05',
          searchVolume: 104885,
          organicRank: 84,
          sponsoredRank: 41,
          keywordSales: 1347
        },
        {
          date: '2024-07-04',
          searchVolume: 104885,
          organicRank: 25,
          sponsoredRank: 15,
          keywordSales: 1347
        },
        {
          date: '2024-07-03',
          searchVolume: 104885,
          organicRank: 24,
          sponsoredRank: 10,
          keywordSales: 1347
        },
        {
          date: '2024-07-02',
          searchVolume: 104885,
          organicRank: 18,

          keywordSales: 1347
        },
        {
          date: '2024-07-01',
          searchVolume: 104885,
          organicRank: 10,

          keywordSales: 1347
        },
        {
          date: '2024-06-30',
          searchVolume: 104885,
          organicRank: 16,

          keywordSales: 1347
        },
        {
          date: '2024-06-29',
          searchVolume: 104885,
          organicRank: 23,

          keywordSales: 1347
        },
        {
          date: '2024-06-28',
          searchVolume: 109039,
          organicRank: 11,

          keywordSales: 1347
        },
        {
          date: '2024-06-27',
          searchVolume: 109039,
          organicRank: 11,
          sponsoredRank: 44,
          keywordSales: 1347
        },
        {
          date: '2024-06-26',
          searchVolume: 109039,
          organicRank: 19,
          sponsoredRank: 43,
          keywordSales: 1347
        },
        {
          date: '2024-06-25',
          searchVolume: 109039,
          organicRank: 23,

          keywordSales: 1347
        },
        {
          date: '2024-06-24',
          searchVolume: 109039,
          organicRank: 26,
          sponsoredRank: 39,
          keywordSales: 1347
        },
        {
          date: '2024-06-23',
          searchVolume: 109039,
          organicRank: 20,

          keywordSales: 1347
        },
        {
          date: '2024-06-22',
          searchVolume: 109039,
          organicRank: 26,
          sponsoredRank: 8,
          keywordSales: 1347
        },
        {
          date: '2024-06-21',
          searchVolume: 118501,
          organicRank: 20,
          sponsoredRank: 18,
          keywordSales: 1347
        },
        {
          date: '2024-06-20',
          searchVolume: 118501,
          organicRank: 10,
          sponsoredRank: 19,
          keywordSales: 1347
        },
        {
          date: '2024-06-19',
          searchVolume: 118501,
          organicRank: 10,
          sponsoredRank: 7,
          keywordSales: 1347
        },
        {
          date: '2024-06-18',
          searchVolume: 118501,
          organicRank: 90,
          sponsoredRank: 69,
          keywordSales: 1347
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_celsius energy drink orange`,
      keyword: 'celsius energy drink orange',
      searchVolume: 1913,
      organicStartRank: 28,
      organicHighestRank: 12,
      sponsoredStartRank: 9,
      sponsoredHighestRank: 7,
      organicPageOneWinDate: '2024-07-05',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-15',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 818,
          organicRank: 30,
          sponsoredRank: 12,
          keywordSales: 36
        },
        {
          date: '2024-08-06',
          searchVolume: 818,
          organicRank: 30,

          keywordSales: 36
        },
        {
          date: '2024-08-05',
          searchVolume: 818,
          organicRank: 24,

          keywordSales: 36
        },
        {
          date: '2024-08-04',
          searchVolume: 818,
          organicRank: 28,

          keywordSales: 36
        },
        {
          date: '2024-08-03',
          searchVolume: 818,
          organicRank: 27,

          keywordSales: 36
        },
        {
          date: '2024-08-01',
          searchVolume: 818,
          organicRank: 48,

          keywordSales: 36
        },
        {
          date: '2024-07-31',
          searchVolume: 818,
          organicRank: 22,

          keywordSales: 36
        },
        {
          date: '2024-07-30',
          searchVolume: 818,
          organicRank: 21,

          keywordSales: 36
        },
        {
          date: '2024-07-29',
          searchVolume: 818,
          organicRank: 35,

          keywordSales: 36
        },
        {
          date: '2024-07-28',
          searchVolume: 818,
          organicRank: 35,

          keywordSales: 36
        },
        {
          date: '2024-07-27',
          searchVolume: 818,
          organicRank: 34,

          keywordSales: 36
        },
        {
          date: '2024-07-26',
          searchVolume: 1827,
          organicRank: 47,

          keywordSales: 36
        },
        {
          date: '2024-07-25',
          searchVolume: 1827,
          organicRank: 45,

          keywordSales: 36
        },
        {
          date: '2024-07-24',
          searchVolume: 1827,
          organicRank: 57,

          keywordSales: 36
        },
        {
          date: '2024-07-23',
          searchVolume: 1827,
          organicRank: 48,

          keywordSales: 36
        },
        {
          date: '2024-07-22',
          searchVolume: 1827,
          organicRank: 30,

          keywordSales: 36
        },
        {
          date: '2024-07-21',
          searchVolume: 1827,
          organicRank: 47,

          keywordSales: 36
        },
        {
          date: '2024-07-20',
          searchVolume: 1827,
          organicRank: 28,

          keywordSales: 36
        },
        {
          date: '2024-07-19',
          searchVolume: 834,
          organicRank: 47,

          keywordSales: 36
        },
        {
          date: '2024-07-18',
          searchVolume: 834,
          organicRank: 25,

          keywordSales: 36
        },
        {
          date: '2024-07-17',
          searchVolume: 834,
          organicRank: 25,

          keywordSales: 36
        },
        {
          date: '2024-07-16',
          searchVolume: 834,
          organicRank: 54,
          sponsoredRank: 7,
          keywordSales: 36
        },
        {
          date: '2024-07-15',
          searchVolume: 834,
          organicRank: 58,
          sponsoredRank: 9,
          keywordSales: 36
        },
        {
          date: '2024-07-14',
          searchVolume: 834,
          organicRank: 26,

          keywordSales: 36
        },
        {
          date: '2024-07-13',
          searchVolume: 834,
          organicRank: 23,

          keywordSales: 36
        },
        {
          date: '2024-07-12',
          searchVolume: 716,
          organicRank: 53,

          keywordSales: 36
        },
        {
          date: '2024-07-11',
          searchVolume: 716,
          organicRank: 21,

          keywordSales: 36
        },
        {
          date: '2024-07-10',
          searchVolume: 716,
          organicRank: 14,

          keywordSales: 36
        },
        {
          date: '2024-07-09',
          searchVolume: 716,
          organicRank: 17,

          keywordSales: 36
        },
        {
          date: '2024-07-08',
          searchVolume: 716,
          organicRank: 17,

          keywordSales: 36
        },
        {
          date: '2024-07-07',
          searchVolume: 716,
          organicRank: 20,

          keywordSales: 36
        },
        {
          date: '2024-07-06',
          searchVolume: 716,
          organicRank: 23,

          keywordSales: 36
        },
        {
          date: '2024-07-05',
          searchVolume: 748,
          organicRank: 12,

          keywordSales: 36
        },
        {
          date: '2024-07-04',
          searchVolume: 748,
          organicRank: 26,

          keywordSales: 36
        },
        {
          date: '2024-07-03',
          searchVolume: 748,
          organicRank: 47,

          keywordSales: 36
        },
        {
          date: '2024-07-02',
          searchVolume: 748,
          organicRank: 48,

          keywordSales: 36
        },
        {
          date: '2024-07-01',
          searchVolume: 748,
          organicRank: 26,

          keywordSales: 36
        },
        {
          date: '2024-06-30',
          searchVolume: 748,
          organicRank: 38,

          keywordSales: 36
        },
        {
          date: '2024-06-29',
          searchVolume: 748,
          organicRank: 28,

          keywordSales: 36
        },
        {
          date: '2024-06-28',
          searchVolume: 884,
          organicRank: 34,

          keywordSales: 36
        },
        {
          date: '2024-06-27',
          searchVolume: 884,
          organicRank: 27,

          keywordSales: 36
        },
        {
          date: '2024-06-26',
          searchVolume: 884,
          organicRank: 21,

          keywordSales: 36
        },
        {
          date: '2024-06-25',
          searchVolume: 884,
          organicRank: 21,

          keywordSales: 36
        },
        {
          date: '2024-06-24',
          searchVolume: 884,
          organicRank: 22,

          keywordSales: 36
        },
        {
          date: '2024-06-21',
          searchVolume: 1913,
          organicRank: 24,

          keywordSales: 36
        },
        {
          date: '2024-06-20',
          searchVolume: 1913,
          organicRank: 25,

          keywordSales: 36
        },
        {
          date: '2024-06-19',
          searchVolume: 1913,
          organicRank: 28,

          keywordSales: 36
        },
        {
          date: '2024-06-18',
          searchVolume: 1913,
          organicRank: 44,

          keywordSales: 36
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_celsius energy drink packets`,
      keyword: 'celsius energy drink packets',
      searchVolume: 3126,
      organicStartRank: 94,
      organicHighestRank: 75,
      sponsoredStartRank: 36,
      sponsoredHighestRank: 18,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 130
        },
        {
          date: '2024-08-06',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 130
        },
        {
          date: '2024-08-05',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 130
        },
        {
          date: '2024-08-03',
          searchVolume: 2354,
          organicRank: 306,
          sponsoredRank: 18,
          keywordSales: 130
        },
        {
          date: '2024-08-02',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 130
        },
        {
          date: '2024-08-01',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 130
        },
        {
          date: '2024-07-31',
          searchVolume: 2354,
          organicRank: 132,

          keywordSales: 130
        },
        {
          date: '2024-07-30',
          searchVolume: 2354,
          organicRank: 130,

          keywordSales: 130
        },
        {
          date: '2024-07-28',
          searchVolume: 2354,
          organicRank: 113,

          keywordSales: 130
        },
        {
          date: '2024-07-27',
          searchVolume: 2354,
          organicRank: 118,

          keywordSales: 130
        },
        {
          date: '2024-07-26',
          searchVolume: 3459,
          organicRank: 116,

          keywordSales: 130
        },
        {
          date: '2024-07-25',
          searchVolume: 3459,
          organicRank: 104,

          keywordSales: 130
        },
        {
          date: '2024-07-24',
          searchVolume: 3459,
          organicRank: 105,

          keywordSales: 130
        },
        {
          date: '2024-07-23',
          searchVolume: 3459,
          organicRank: 111,

          keywordSales: 130
        },
        {
          date: '2024-07-22',
          searchVolume: 3459,
          organicRank: 114,

          keywordSales: 130
        },
        {
          date: '2024-07-21',
          searchVolume: 3459,
          organicRank: 111,
          sponsoredRank: 18,
          keywordSales: 130
        },
        {
          date: '2024-07-20',
          searchVolume: 3459,
          organicRank: 82,

          keywordSales: 130
        },
        {
          date: '2024-07-19',
          searchVolume: 2944,
          organicRank: 78,

          keywordSales: 130
        },
        {
          date: '2024-07-18',
          searchVolume: 2944,
          organicRank: 103,
          sponsoredRank: 30,
          keywordSales: 130
        },
        {
          date: '2024-07-17',
          searchVolume: 2944,
          organicRank: 89,

          keywordSales: 130
        },
        {
          date: '2024-07-16',
          searchVolume: 2944,
          organicRank: 107,
          sponsoredRank: 36,
          keywordSales: 130
        },
        {
          date: '2024-07-15',
          searchVolume: 2944,
          organicRank: 117,

          keywordSales: 130
        },
        {
          date: '2024-07-14',
          searchVolume: 2944,
          organicRank: 114,

          keywordSales: 130
        },
        {
          date: '2024-07-13',
          searchVolume: 2944,
          organicRank: 87,

          keywordSales: 130
        },
        {
          date: '2024-07-12',
          searchVolume: 3096,
          organicRank: 109,

          keywordSales: 130
        },
        {
          date: '2024-07-11',
          searchVolume: 3096,
          organicRank: 105,

          keywordSales: 130
        },
        {
          date: '2024-07-10',
          searchVolume: 3096,
          organicRank: 101,

          keywordSales: 130
        },
        {
          date: '2024-07-09',
          searchVolume: 3096,
          organicRank: 95,

          keywordSales: 130
        },
        {
          date: '2024-07-08',
          searchVolume: 3096,
          organicRank: 95,

          keywordSales: 130
        },
        {
          date: '2024-07-07',
          searchVolume: 3096,
          organicRank: 113,

          keywordSales: 130
        },
        {
          date: '2024-07-06',
          searchVolume: 3096,
          organicRank: 96,

          keywordSales: 130
        },
        {
          date: '2024-07-05',
          searchVolume: 3623,
          organicRank: 306,

          keywordSales: 130
        },
        {
          date: '2024-07-04',
          searchVolume: 3623,
          organicRank: 106,

          keywordSales: 130
        },
        {
          date: '2024-07-03',
          searchVolume: 3623,
          organicRank: 98,

          keywordSales: 130
        },
        {
          date: '2024-07-02',
          searchVolume: 3623,
          organicRank: 92,

          keywordSales: 130
        },
        {
          date: '2024-07-01',
          searchVolume: 3623,
          organicRank: 89,

          keywordSales: 130
        },
        {
          date: '2024-06-30',
          searchVolume: 3623,
          organicRank: 91,

          keywordSales: 130
        },
        {
          date: '2024-06-29',
          searchVolume: 3623,
          organicRank: 97,

          keywordSales: 130
        },
        {
          date: '2024-06-28',
          searchVolume: 3116,
          organicRank: 97,

          keywordSales: 130
        },
        {
          date: '2024-06-27',
          searchVolume: 3116,
          organicRank: 103,

          keywordSales: 130
        },
        {
          date: '2024-06-26',
          searchVolume: 3116,
          organicRank: 98,

          keywordSales: 130
        },
        {
          date: '2024-06-25',
          searchVolume: 3116,
          organicRank: 83,

          keywordSales: 130
        },
        {
          date: '2024-06-21',
          searchVolume: 3126,
          organicRank: 89,

          keywordSales: 130
        },
        {
          date: '2024-06-20',
          searchVolume: 3126,
          organicRank: 75,

          keywordSales: 130
        },
        {
          date: '2024-06-19',
          searchVolume: 3126,
          organicRank: 94,

          keywordSales: 130
        },
        {
          date: '2024-06-18',
          searchVolume: 3126,
          organicRank: 96,

          keywordSales: 130
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_celsius non carbonated`,
      keyword: 'celsius non carbonated',
      searchVolume: 1432,
      organicStartRank: 52,
      organicHighestRank: 35,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1170,
          organicRank: 49,
          sponsoredRank: 3,
          keywordSales: 37
        },
        {
          date: '2024-08-06',
          searchVolume: 1170,
          organicRank: 54,

          keywordSales: 37
        },
        {
          date: '2024-08-05',
          searchVolume: 1170,
          organicRank: 50,

          keywordSales: 37
        },
        {
          date: '2024-08-04',
          searchVolume: 1170,
          organicRank: 83,

          keywordSales: 37
        },
        {
          date: '2024-08-03',
          searchVolume: 1170,
          organicRank: 88,

          keywordSales: 37
        },
        {
          date: '2024-08-01',
          searchVolume: 1170,
          organicRank: 85,

          keywordSales: 37
        },
        {
          date: '2024-07-31',
          searchVolume: 1170,
          organicRank: 75,

          keywordSales: 37
        },
        {
          date: '2024-07-30',
          searchVolume: 1170,
          organicRank: 80,

          keywordSales: 37
        },
        {
          date: '2024-07-29',
          searchVolume: 1170,
          organicRank: 83,

          keywordSales: 37
        },
        {
          date: '2024-07-28',
          searchVolume: 1170,
          organicRank: 73,

          keywordSales: 37
        },
        {
          date: '2024-07-27',
          searchVolume: 1170,
          organicRank: 70,

          keywordSales: 37
        },
        {
          date: '2024-07-26',
          searchVolume: 2073,
          organicRank: 66,

          keywordSales: 37
        },
        {
          date: '2024-07-25',
          searchVolume: 2073,
          organicRank: 72,

          keywordSales: 37
        },
        {
          date: '2024-07-24',
          searchVolume: 2073,
          organicRank: 66,

          keywordSales: 37
        },
        {
          date: '2024-07-23',
          searchVolume: 2073,
          organicRank: 70,

          keywordSales: 37
        },
        {
          date: '2024-07-22',
          searchVolume: 2073,
          organicRank: 70,

          keywordSales: 37
        },
        {
          date: '2024-07-21',
          searchVolume: 2073,
          organicRank: 71,

          keywordSales: 37
        },
        {
          date: '2024-07-20',
          searchVolume: 2073,
          organicRank: 57,

          keywordSales: 37
        },
        {
          date: '2024-07-19',
          searchVolume: 1269,
          organicRank: 51,

          keywordSales: 37
        },
        {
          date: '2024-07-18',
          searchVolume: 1269,
          organicRank: 57,

          keywordSales: 37
        },
        {
          date: '2024-07-17',
          searchVolume: 1269,
          organicRank: 46,

          keywordSales: 37
        },
        {
          date: '2024-07-16',
          searchVolume: 1269,
          organicRank: 65,

          keywordSales: 37
        },
        {
          date: '2024-07-15',
          searchVolume: 1269,
          organicRank: 62,

          keywordSales: 37
        },
        {
          date: '2024-07-14',
          searchVolume: 1269,
          organicRank: 59,

          keywordSales: 37
        },
        {
          date: '2024-07-13',
          searchVolume: 1269,
          organicRank: 54,

          keywordSales: 37
        },
        {
          date: '2024-07-12',
          searchVolume: 1290,
          organicRank: 53,

          keywordSales: 37
        },
        {
          date: '2024-07-11',
          searchVolume: 1290,
          organicRank: 49,

          keywordSales: 37
        },
        {
          date: '2024-07-10',
          searchVolume: 1290,
          organicRank: 51,

          keywordSales: 37
        },
        {
          date: '2024-07-09',
          searchVolume: 1290,
          organicRank: 35,

          keywordSales: 37
        },
        {
          date: '2024-07-08',
          searchVolume: 1290,
          organicRank: 39,

          keywordSales: 37
        },
        {
          date: '2024-07-07',
          searchVolume: 1290,
          organicRank: 44,

          keywordSales: 37
        },
        {
          date: '2024-07-06',
          searchVolume: 1290,
          organicRank: 44,

          keywordSales: 37
        },
        {
          date: '2024-07-05',
          searchVolume: 1443,
          organicRank: 43,

          keywordSales: 37
        },
        {
          date: '2024-07-04',
          searchVolume: 1443,
          organicRank: 67,

          keywordSales: 37
        },
        {
          date: '2024-07-03',
          searchVolume: 1443,
          organicRank: 49,

          keywordSales: 37
        },
        {
          date: '2024-07-02',
          searchVolume: 1443,
          organicRank: 53,

          keywordSales: 37
        },
        {
          date: '2024-07-01',
          searchVolume: 1443,
          organicRank: 45,

          keywordSales: 37
        },
        {
          date: '2024-06-30',
          searchVolume: 1443,
          organicRank: 51,

          keywordSales: 37
        },
        {
          date: '2024-06-29',
          searchVolume: 1443,
          organicRank: 49,

          keywordSales: 37
        },
        {
          date: '2024-06-28',
          searchVolume: 1428,
          organicRank: 44,

          keywordSales: 37
        },
        {
          date: '2024-06-27',
          searchVolume: 1428,
          organicRank: 54,

          keywordSales: 37
        },
        {
          date: '2024-06-26',
          searchVolume: 1428,
          organicRank: 41,

          keywordSales: 37
        },
        {
          date: '2024-06-25',
          searchVolume: 1428,
          organicRank: 35,

          keywordSales: 37
        },
        {
          date: '2024-06-24',
          searchVolume: 1428,
          organicRank: 46,

          keywordSales: 37
        },
        {
          date: '2024-06-21',
          searchVolume: 1432,
          organicRank: 54,

          keywordSales: 37
        },
        {
          date: '2024-06-20',
          searchVolume: 1432,
          organicRank: 36,

          keywordSales: 37
        },
        {
          date: '2024-06-19',
          searchVolume: 1432,
          organicRank: 52,

          keywordSales: 37
        },
        {
          date: '2024-06-18',
          searchVolume: 1432,
          organicRank: 34,
          sponsoredRank: 7,
          keywordSales: 37
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_celsius powder packets`,
      keyword: 'celsius powder packets',
      searchVolume: 52691,
      organicStartRank: 306,
      organicHighestRank: 91,
      sponsoredStartRank: 13,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 39530,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-08-06',
          searchVolume: 39530,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-08-05',
          searchVolume: 39530,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-08-03',
          searchVolume: 39530,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-08-02',
          searchVolume: 39530,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-08-01',
          searchVolume: 39530,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-31',
          searchVolume: 39530,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-30',
          searchVolume: 39530,
          organicRank: 91,

          keywordSales: 913
        },
        {
          date: '2024-07-28',
          searchVolume: 39530,
          organicRank: 100,

          keywordSales: 913
        },
        {
          date: '2024-07-27',
          searchVolume: 39530,
          organicRank: 105,

          keywordSales: 913
        },
        {
          date: '2024-07-26',
          searchVolume: 60976,
          organicRank: 103,

          keywordSales: 913
        },
        {
          date: '2024-07-25',
          searchVolume: 60976,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-24',
          searchVolume: 60976,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-23',
          searchVolume: 60976,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-22',
          searchVolume: 60976,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-21',
          searchVolume: 60976,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-20',
          searchVolume: 60976,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-19',
          searchVolume: 49473,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-18',
          searchVolume: 49473,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-17',
          searchVolume: 49473,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-16',
          searchVolume: 49473,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-15',
          searchVolume: 49473,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-14',
          searchVolume: 49473,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-13',
          searchVolume: 49473,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-12',
          searchVolume: 48682,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-11',
          searchVolume: 48682,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-10',
          searchVolume: 48682,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-09',
          searchVolume: 48682,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-08',
          searchVolume: 48682,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-07',
          searchVolume: 48682,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-06',
          searchVolume: 48682,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-05',
          searchVolume: 53615,
          organicRank: 150,

          keywordSales: 913
        },
        {
          date: '2024-07-04',
          searchVolume: 53615,
          organicRank: 147,

          keywordSales: 913
        },
        {
          date: '2024-07-03',
          searchVolume: 53615,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-02',
          searchVolume: 53615,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-07-01',
          searchVolume: 53615,
          organicRank: 154,

          keywordSales: 913
        },
        {
          date: '2024-06-30',
          searchVolume: 53615,
          organicRank: 149,

          keywordSales: 913
        },
        {
          date: '2024-06-29',
          searchVolume: 53615,
          organicRank: 146,

          keywordSales: 913
        },
        {
          date: '2024-06-28',
          searchVolume: 52533,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-06-27',
          searchVolume: 52533,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-06-26',
          searchVolume: 52533,
          organicRank: 157,

          keywordSales: 913
        },
        {
          date: '2024-06-25',
          searchVolume: 52533,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-06-24',
          searchVolume: 52533,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-06-21',
          searchVolume: 52691,
          organicRank: 136,
          sponsoredRank: 13,
          keywordSales: 913
        },
        {
          date: '2024-06-20',
          searchVolume: 52691,
          organicRank: 161,

          keywordSales: 913
        },
        {
          date: '2024-06-19',
          searchVolume: 52691,
          organicRank: 306,

          keywordSales: 913
        },
        {
          date: '2024-06-18',
          searchVolume: 52691,
          organicRank: 306,

          keywordSales: 913
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_celsius variety pack`,
      keyword: 'celsius variety pack',
      searchVolume: 2816,
      organicStartRank: 16,
      organicHighestRank: 14,

      organicPageOneWinDate: '2024-06-19',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 3206,
          organicRank: 109,

          keywordSales: 143
        },
        {
          date: '2024-08-06',
          searchVolume: 3206,
          organicRank: 108,

          keywordSales: 143
        },
        {
          date: '2024-08-05',
          searchVolume: 3206,
          organicRank: 92,

          keywordSales: 143
        },
        {
          date: '2024-08-03',
          searchVolume: 3206,
          organicRank: 91,

          keywordSales: 143
        },
        {
          date: '2024-08-02',
          searchVolume: 3206,
          organicRank: 89,

          keywordSales: 143
        },
        {
          date: '2024-08-01',
          searchVolume: 3206,
          organicRank: 99,

          keywordSales: 143
        },
        {
          date: '2024-07-31',
          searchVolume: 3206,
          organicRank: 19,

          keywordSales: 143
        },
        {
          date: '2024-07-30',
          searchVolume: 3206,
          organicRank: 24,

          keywordSales: 143
        },
        {
          date: '2024-07-28',
          searchVolume: 3206,
          organicRank: 20,

          keywordSales: 143
        },
        {
          date: '2024-07-27',
          searchVolume: 3206,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-07-26',
          searchVolume: 6450,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-07-25',
          searchVolume: 6450,
          organicRank: 23,

          keywordSales: 143
        },
        {
          date: '2024-07-24',
          searchVolume: 6450,
          organicRank: 90,

          keywordSales: 143
        },
        {
          date: '2024-07-23',
          searchVolume: 6450,
          organicRank: 90,

          keywordSales: 143
        },
        {
          date: '2024-07-22',
          searchVolume: 6450,
          organicRank: 90,

          keywordSales: 143
        },
        {
          date: '2024-07-21',
          searchVolume: 6450,
          organicRank: 91,

          keywordSales: 143
        },
        {
          date: '2024-07-20',
          searchVolume: 6450,
          organicRank: 91,

          keywordSales: 143
        },
        {
          date: '2024-07-19',
          searchVolume: 3396,
          organicRank: 85,

          keywordSales: 143
        },
        {
          date: '2024-07-18',
          searchVolume: 3396,
          organicRank: 86,

          keywordSales: 143
        },
        {
          date: '2024-07-17',
          searchVolume: 3396,
          organicRank: 85,

          keywordSales: 143
        },
        {
          date: '2024-07-16',
          searchVolume: 3396,
          organicRank: 91,

          keywordSales: 143
        },
        {
          date: '2024-07-15',
          searchVolume: 3396,
          organicRank: 19,

          keywordSales: 143
        },
        {
          date: '2024-07-14',
          searchVolume: 3396,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-07-13',
          searchVolume: 3396,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-07-12',
          searchVolume: 2440,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-07-11',
          searchVolume: 2440,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-07-10',
          searchVolume: 2440,
          organicRank: 83,

          keywordSales: 143
        },
        {
          date: '2024-07-09',
          searchVolume: 2440,
          organicRank: 19,

          keywordSales: 143
        },
        {
          date: '2024-07-08',
          searchVolume: 2440,
          organicRank: 18,

          keywordSales: 143
        },
        {
          date: '2024-07-07',
          searchVolume: 2440,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-07-06',
          searchVolume: 2440,
          organicRank: 19,

          keywordSales: 143
        },
        {
          date: '2024-07-05',
          searchVolume: 2554,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-07-04',
          searchVolume: 2554,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-07-03',
          searchVolume: 2554,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-07-02',
          searchVolume: 2554,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-07-01',
          searchVolume: 2554,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-06-30',
          searchVolume: 2554,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-06-29',
          searchVolume: 2554,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-06-28',
          searchVolume: 3116,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-06-27',
          searchVolume: 3116,
          organicRank: 15,

          keywordSales: 143
        },
        {
          date: '2024-06-26',
          searchVolume: 3116,
          organicRank: 20,

          keywordSales: 143
        },
        {
          date: '2024-06-25',
          searchVolume: 3116,
          organicRank: 15,

          keywordSales: 143
        },
        {
          date: '2024-06-24',
          searchVolume: 3116,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-06-21',
          searchVolume: 2816,
          organicRank: 17,

          keywordSales: 143
        },
        {
          date: '2024-06-20',
          searchVolume: 2816,
          organicRank: 14,

          keywordSales: 143
        },
        {
          date: '2024-06-19',
          searchVolume: 2816,
          organicRank: 16,

          keywordSales: 143
        },
        {
          date: '2024-06-18',
          searchVolume: 2816,
          organicRank: 38,

          keywordSales: 143
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_clean energy drink`,
      keyword: 'clean energy drink',
      searchVolume: 2552,
      organicStartRank: 14,
      organicHighestRank: 9,
      sponsoredStartRank: 39,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-07-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2078,
          organicRank: 18,
          sponsoredRank: 46,
          keywordSales: 85
        },
        {
          date: '2024-08-05',
          searchVolume: 2078,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-08-04',
          searchVolume: 2078,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-08-03',
          searchVolume: 2078,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-08-02',
          searchVolume: 2078,
          organicRank: 16,

          keywordSales: 85
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 16,

          keywordSales: 85
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 20,

          keywordSales: 85
        },
        {
          date: '2024-07-29',
          searchVolume: 2078,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 20,

          keywordSales: 85
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-07-26',
          searchVolume: 2984,
          organicRank: 13,

          keywordSales: 85
        },
        {
          date: '2024-07-25',
          searchVolume: 2984,
          organicRank: 19,

          keywordSales: 85
        },
        {
          date: '2024-07-24',
          searchVolume: 2984,
          organicRank: 15,

          keywordSales: 85
        },
        {
          date: '2024-07-23',
          searchVolume: 2984,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-07-22',
          searchVolume: 2984,
          organicRank: 20,

          keywordSales: 85
        },
        {
          date: '2024-07-21',
          searchVolume: 2984,
          organicRank: 17,

          keywordSales: 85
        },
        {
          date: '2024-07-20',
          searchVolume: 2984,
          organicRank: 15,

          keywordSales: 85
        },
        {
          date: '2024-07-19',
          searchVolume: 2390,
          organicRank: 16,

          keywordSales: 85
        },
        {
          date: '2024-07-18',
          searchVolume: 2390,
          organicRank: 10,

          keywordSales: 85
        },
        {
          date: '2024-07-17',
          searchVolume: 2390,
          organicRank: 10,

          keywordSales: 85
        },
        {
          date: '2024-07-16',
          searchVolume: 2390,
          organicRank: 19,

          keywordSales: 85
        },
        {
          date: '2024-07-15',
          searchVolume: 2390,
          organicRank: 14,

          keywordSales: 85
        },
        {
          date: '2024-07-14',
          searchVolume: 2390,
          organicRank: 17,

          keywordSales: 85
        },
        {
          date: '2024-07-13',
          searchVolume: 2390,
          organicRank: 14,

          keywordSales: 85
        },
        {
          date: '2024-07-12',
          searchVolume: 2439,
          organicRank: 13,

          keywordSales: 85
        },
        {
          date: '2024-07-11',
          searchVolume: 2439,
          organicRank: 14,

          keywordSales: 85
        },
        {
          date: '2024-07-09',
          searchVolume: 2439,
          organicRank: 13,

          keywordSales: 85
        },
        {
          date: '2024-07-08',
          searchVolume: 2439,
          organicRank: 12,

          keywordSales: 85
        },
        {
          date: '2024-07-07',
          searchVolume: 2439,
          organicRank: 11,

          keywordSales: 85
        },
        {
          date: '2024-07-06',
          searchVolume: 2439,
          organicRank: 15,

          keywordSales: 85
        },
        {
          date: '2024-07-05',
          searchVolume: 2549,
          organicRank: 13,

          keywordSales: 85
        },
        {
          date: '2024-07-04',
          searchVolume: 2549,
          organicRank: 16,

          keywordSales: 85
        },
        {
          date: '2024-07-03',
          searchVolume: 2549,
          organicRank: 25,

          keywordSales: 85
        },
        {
          date: '2024-07-02',
          searchVolume: 2549,
          organicRank: 13,

          keywordSales: 85
        },
        {
          date: '2024-07-01',
          searchVolume: 2549,
          organicRank: 9,

          keywordSales: 85
        },
        {
          date: '2024-06-30',
          searchVolume: 2549,
          organicRank: 14,

          keywordSales: 85
        },
        {
          date: '2024-06-29',
          searchVolume: 2549,
          organicRank: 18,
          sponsoredRank: 55,
          keywordSales: 85
        },
        {
          date: '2024-06-28',
          searchVolume: 2539,
          organicRank: 14,

          keywordSales: 85
        },
        {
          date: '2024-06-27',
          searchVolume: 2539,
          organicRank: 14,

          keywordSales: 85
        },
        {
          date: '2024-06-26',
          searchVolume: 2539,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-06-25',
          searchVolume: 2539,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-06-24',
          searchVolume: 2539,
          organicRank: 18,

          keywordSales: 85
        },
        {
          date: '2024-06-21',
          searchVolume: 2552,
          organicRank: 12,
          sponsoredRank: 3,
          keywordSales: 85
        },
        {
          date: '2024-06-20',
          searchVolume: 2552,
          organicRank: 16,

          keywordSales: 85
        },
        {
          date: '2024-06-19',
          searchVolume: 2552,
          organicRank: 14,
          sponsoredRank: 39,
          keywordSales: 85
        },
        {
          date: '2024-06-18',
          searchVolume: 2552,
          organicRank: 14,

          keywordSales: 85
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_coffee drinks`,
      keyword: 'coffee drinks',
      searchVolume: 2396,
      organicStartRank: 81,
      organicHighestRank: 56,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2243,
          organicRank: 306,

          keywordSales: 82
        },
        {
          date: '2024-08-05',
          searchVolume: 2243,
          organicRank: 306,

          keywordSales: 82
        },
        {
          date: '2024-08-04',
          searchVolume: 2243,
          organicRank: 306,

          keywordSales: 82
        },
        {
          date: '2024-08-02',
          searchVolume: 2243,
          organicRank: 289,

          keywordSales: 82
        },
        {
          date: '2024-08-01',
          searchVolume: 2243,
          organicRank: 115,

          keywordSales: 82
        },
        {
          date: '2024-07-31',
          searchVolume: 2243,
          organicRank: 154,

          keywordSales: 82
        },
        {
          date: '2024-07-30',
          searchVolume: 2243,
          organicRank: 114,

          keywordSales: 82
        },
        {
          date: '2024-07-28',
          searchVolume: 2243,
          organicRank: 94,

          keywordSales: 82
        },
        {
          date: '2024-07-27',
          searchVolume: 2243,
          organicRank: 119,

          keywordSales: 82
        },
        {
          date: '2024-07-26',
          searchVolume: 2984,
          organicRank: 102,

          keywordSales: 82
        },
        {
          date: '2024-07-25',
          searchVolume: 2984,
          organicRank: 106,

          keywordSales: 82
        },
        {
          date: '2024-07-24',
          searchVolume: 2984,
          organicRank: 75,

          keywordSales: 82
        },
        {
          date: '2024-07-23',
          searchVolume: 2984,
          organicRank: 108,

          keywordSales: 82
        },
        {
          date: '2024-07-22',
          searchVolume: 2984,
          organicRank: 94,

          keywordSales: 82
        },
        {
          date: '2024-07-21',
          searchVolume: 2984,
          organicRank: 98,

          keywordSales: 82
        },
        {
          date: '2024-07-20',
          searchVolume: 2984,
          organicRank: 89,

          keywordSales: 82
        },
        {
          date: '2024-07-19',
          searchVolume: 2392,
          organicRank: 71,

          keywordSales: 82
        },
        {
          date: '2024-07-18',
          searchVolume: 2392,
          organicRank: 71,

          keywordSales: 82
        },
        {
          date: '2024-07-17',
          searchVolume: 2392,
          organicRank: 63,

          keywordSales: 82
        },
        {
          date: '2024-07-16',
          searchVolume: 2392,
          organicRank: 79,

          keywordSales: 82
        },
        {
          date: '2024-07-15',
          searchVolume: 2392,
          organicRank: 78,

          keywordSales: 82
        },
        {
          date: '2024-07-14',
          searchVolume: 2392,
          organicRank: 76,

          keywordSales: 82
        },
        {
          date: '2024-07-13',
          searchVolume: 2392,
          organicRank: 84,

          keywordSales: 82
        },
        {
          date: '2024-07-12',
          searchVolume: 2325,
          organicRank: 65,

          keywordSales: 82
        },
        {
          date: '2024-07-11',
          searchVolume: 2325,
          organicRank: 62,

          keywordSales: 82
        },
        {
          date: '2024-07-10',
          searchVolume: 2325,
          organicRank: 56,

          keywordSales: 82
        },
        {
          date: '2024-07-09',
          searchVolume: 2325,
          organicRank: 67,

          keywordSales: 82
        },
        {
          date: '2024-07-08',
          searchVolume: 2325,
          organicRank: 115,

          keywordSales: 82
        },
        {
          date: '2024-07-07',
          searchVolume: 2325,
          organicRank: 73,

          keywordSales: 82
        },
        {
          date: '2024-07-06',
          searchVolume: 2325,
          organicRank: 69,

          keywordSales: 82
        },
        {
          date: '2024-07-05',
          searchVolume: 2394,
          organicRank: 57,

          keywordSales: 82
        },
        {
          date: '2024-07-04',
          searchVolume: 2394,
          organicRank: 70,

          keywordSales: 82
        },
        {
          date: '2024-07-03',
          searchVolume: 2394,
          organicRank: 76,

          keywordSales: 82
        },
        {
          date: '2024-07-02',
          searchVolume: 2394,
          organicRank: 67,

          keywordSales: 82
        },
        {
          date: '2024-07-01',
          searchVolume: 2394,
          organicRank: 76,

          keywordSales: 82
        },
        {
          date: '2024-06-30',
          searchVolume: 2394,
          organicRank: 70,

          keywordSales: 82
        },
        {
          date: '2024-06-29',
          searchVolume: 2394,
          organicRank: 72,

          keywordSales: 82
        },
        {
          date: '2024-06-28',
          searchVolume: 2385,
          organicRank: 97,

          keywordSales: 82
        },
        {
          date: '2024-06-27',
          searchVolume: 2385,
          organicRank: 65,

          keywordSales: 82
        },
        {
          date: '2024-06-26',
          searchVolume: 2385,
          organicRank: 67,

          keywordSales: 82
        },
        {
          date: '2024-06-25',
          searchVolume: 2385,
          organicRank: 64,

          keywordSales: 82
        },
        {
          date: '2024-06-21',
          searchVolume: 2396,
          organicRank: 64,

          keywordSales: 82
        },
        {
          date: '2024-06-20',
          searchVolume: 2396,
          organicRank: 67,

          keywordSales: 82
        },
        {
          date: '2024-06-19',
          searchVolume: 2396,
          organicRank: 81,

          keywordSales: 82
        },
        {
          date: '2024-06-18',
          searchVolume: 2396,
          organicRank: 71,

          keywordSales: 82
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_crush drink mix packets`,
      keyword: 'crush drink mix packets',
      searchVolume: 2396,
      organicStartRank: 209,
      organicHighestRank: 179,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-08-05',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-08-04',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-08-03',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-08-02',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-08-01',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-07-31',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-07-30',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-07-29',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-07-28',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-07-27',
          searchVolume: 2245,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-07-26',
          searchVolume: 2688,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-07-25',
          searchVolume: 2688,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-07-24',
          searchVolume: 2688,
          organicRank: 192,

          keywordSales: 96
        },
        {
          date: '2024-07-23',
          searchVolume: 2688,
          organicRank: 206,

          keywordSales: 96
        },
        {
          date: '2024-07-22',
          searchVolume: 2688,
          organicRank: 197,

          keywordSales: 96
        },
        {
          date: '2024-07-21',
          searchVolume: 2688,
          organicRank: 192,

          keywordSales: 96
        },
        {
          date: '2024-07-20',
          searchVolume: 2688,
          organicRank: 230,

          keywordSales: 96
        },
        {
          date: '2024-07-19',
          searchVolume: 2393,
          organicRank: 180,

          keywordSales: 96
        },
        {
          date: '2024-07-18',
          searchVolume: 2393,
          organicRank: 179,

          keywordSales: 96
        },
        {
          date: '2024-07-17',
          searchVolume: 2393,
          organicRank: 188,

          keywordSales: 96
        },
        {
          date: '2024-07-16',
          searchVolume: 2393,
          organicRank: 228,

          keywordSales: 96
        },
        {
          date: '2024-07-15',
          searchVolume: 2393,
          organicRank: 232,

          keywordSales: 96
        },
        {
          date: '2024-07-14',
          searchVolume: 2393,
          organicRank: 193,

          keywordSales: 96
        },
        {
          date: '2024-07-13',
          searchVolume: 2393,
          organicRank: 183,

          keywordSales: 96
        },
        {
          date: '2024-07-12',
          searchVolume: 2439,
          organicRank: 206,

          keywordSales: 96
        },
        {
          date: '2024-07-11',
          searchVolume: 2439,
          organicRank: 204,

          keywordSales: 96
        },
        {
          date: '2024-07-10',
          searchVolume: 2439,
          organicRank: 207,

          keywordSales: 96
        },
        {
          date: '2024-07-09',
          searchVolume: 2439,
          organicRank: 233,

          keywordSales: 96
        },
        {
          date: '2024-07-08',
          searchVolume: 2439,
          organicRank: 232,

          keywordSales: 96
        },
        {
          date: '2024-07-07',
          searchVolume: 2439,
          organicRank: 225,

          keywordSales: 96
        },
        {
          date: '2024-07-06',
          searchVolume: 2439,
          organicRank: 227,

          keywordSales: 96
        },
        {
          date: '2024-07-05',
          searchVolume: 2552,
          organicRank: 229,

          keywordSales: 96
        },
        {
          date: '2024-07-04',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-07-03',
          searchVolume: 2552,
          organicRank: 219,

          keywordSales: 96
        },
        {
          date: '2024-07-02',
          searchVolume: 2552,
          organicRank: 188,

          keywordSales: 96
        },
        {
          date: '2024-07-01',
          searchVolume: 2552,
          organicRank: 281,

          keywordSales: 96
        },
        {
          date: '2024-06-30',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-06-29',
          searchVolume: 2552,
          organicRank: 227,

          keywordSales: 96
        },
        {
          date: '2024-06-27',
          searchVolume: 3116,
          organicRank: 224,

          keywordSales: 96
        },
        {
          date: '2024-06-26',
          searchVolume: 3116,
          organicRank: 224,

          keywordSales: 96
        },
        {
          date: '2024-06-25',
          searchVolume: 3116,
          organicRank: 208,

          keywordSales: 96
        },
        {
          date: '2024-06-24',
          searchVolume: 3116,
          organicRank: 227,

          keywordSales: 96
        },
        {
          date: '2024-06-22',
          searchVolume: 3116,
          organicRank: 288,

          keywordSales: 96
        },
        {
          date: '2024-06-21',
          searchVolume: 2396,
          organicRank: 306,

          keywordSales: 96
        },
        {
          date: '2024-06-20',
          searchVolume: 2396,
          organicRank: 291,

          keywordSales: 96
        },
        {
          date: '2024-06-19',
          searchVolume: 2396,
          organicRank: 209,

          keywordSales: 96
        },
        {
          date: '2024-06-18',
          searchVolume: 2396,
          organicRank: 200,

          keywordSales: 96
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_drink`,
      keyword: 'drink',
      searchVolume: 5147,
      organicStartRank: 102,
      organicHighestRank: 78,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 5539,
          organicRank: 106,

          keywordSales: 236
        },
        {
          date: '2024-08-05',
          searchVolume: 5539,
          organicRank: 90,

          keywordSales: 236
        },
        {
          date: '2024-08-04',
          searchVolume: 5539,
          organicRank: 95,

          keywordSales: 236
        },
        {
          date: '2024-08-02',
          searchVolume: 5539,
          organicRank: 163,

          keywordSales: 236
        },
        {
          date: '2024-08-01',
          searchVolume: 5539,
          organicRank: 89,

          keywordSales: 236
        },
        {
          date: '2024-07-31',
          searchVolume: 5539,
          organicRank: 95,

          keywordSales: 236
        },
        {
          date: '2024-07-30',
          searchVolume: 5539,
          organicRank: 109,

          keywordSales: 236
        },
        {
          date: '2024-07-29',
          searchVolume: 5539,
          organicRank: 102,

          keywordSales: 236
        },
        {
          date: '2024-07-28',
          searchVolume: 5539,
          organicRank: 162,

          keywordSales: 236
        },
        {
          date: '2024-07-27',
          searchVolume: 5539,
          organicRank: 106,

          keywordSales: 236
        },
        {
          date: '2024-07-26',
          searchVolume: 5700,
          organicRank: 103,

          keywordSales: 236
        },
        {
          date: '2024-07-25',
          searchVolume: 5700,
          organicRank: 101,

          keywordSales: 236
        },
        {
          date: '2024-07-24',
          searchVolume: 5700,
          organicRank: 100,

          keywordSales: 236
        },
        {
          date: '2024-07-23',
          searchVolume: 5700,
          organicRank: 104,

          keywordSales: 236
        },
        {
          date: '2024-07-22',
          searchVolume: 5700,
          organicRank: 105,

          keywordSales: 236
        },
        {
          date: '2024-07-21',
          searchVolume: 5700,
          organicRank: 118,

          keywordSales: 236
        },
        {
          date: '2024-07-20',
          searchVolume: 5700,
          organicRank: 114,

          keywordSales: 236
        },
        {
          date: '2024-07-19',
          searchVolume: 5344,
          organicRank: 85,

          keywordSales: 236
        },
        {
          date: '2024-07-18',
          searchVolume: 5344,
          organicRank: 87,

          keywordSales: 236
        },
        {
          date: '2024-07-17',
          searchVolume: 5344,
          organicRank: 96,

          keywordSales: 236
        },
        {
          date: '2024-07-16',
          searchVolume: 5344,
          organicRank: 100,

          keywordSales: 236
        },
        {
          date: '2024-07-15',
          searchVolume: 5344,
          organicRank: 129,

          keywordSales: 236
        },
        {
          date: '2024-07-14',
          searchVolume: 5344,
          organicRank: 118,

          keywordSales: 236
        },
        {
          date: '2024-07-13',
          searchVolume: 5344,
          organicRank: 154,

          keywordSales: 236
        },
        {
          date: '2024-07-12',
          searchVolume: 5734,
          organicRank: 120,

          keywordSales: 236
        },
        {
          date: '2024-07-11',
          searchVolume: 5734,
          organicRank: 114,

          keywordSales: 236
        },
        {
          date: '2024-07-10',
          searchVolume: 5734,
          organicRank: 117,

          keywordSales: 236
        },
        {
          date: '2024-07-09',
          searchVolume: 5734,
          organicRank: 105,

          keywordSales: 236
        },
        {
          date: '2024-07-08',
          searchVolume: 5734,
          organicRank: 114,

          keywordSales: 236
        },
        {
          date: '2024-07-07',
          searchVolume: 5734,
          organicRank: 107,

          keywordSales: 236
        },
        {
          date: '2024-07-06',
          searchVolume: 5734,
          organicRank: 92,

          keywordSales: 236
        },
        {
          date: '2024-07-05',
          searchVolume: 5693,
          organicRank: 106,

          keywordSales: 236
        },
        {
          date: '2024-07-04',
          searchVolume: 5693,
          organicRank: 146,

          keywordSales: 236
        },
        {
          date: '2024-07-03',
          searchVolume: 5693,
          organicRank: 101,

          keywordSales: 236
        },
        {
          date: '2024-07-02',
          searchVolume: 5693,
          organicRank: 78,

          keywordSales: 236
        },
        {
          date: '2024-07-01',
          searchVolume: 5693,
          organicRank: 90,

          keywordSales: 236
        },
        {
          date: '2024-06-30',
          searchVolume: 5693,
          organicRank: 105,

          keywordSales: 236
        },
        {
          date: '2024-06-29',
          searchVolume: 5693,
          organicRank: 136,

          keywordSales: 236
        },
        {
          date: '2024-06-28',
          searchVolume: 5671,
          organicRank: 140,

          keywordSales: 236
        },
        {
          date: '2024-06-27',
          searchVolume: 5671,
          organicRank: 157,

          keywordSales: 236
        },
        {
          date: '2024-06-26',
          searchVolume: 5671,
          organicRank: 108,

          keywordSales: 236
        },
        {
          date: '2024-06-25',
          searchVolume: 5671,
          organicRank: 102,

          keywordSales: 236
        },
        {
          date: '2024-06-22',
          searchVolume: 5671,
          organicRank: 123,

          keywordSales: 236
        },
        {
          date: '2024-06-21',
          searchVolume: 5147,
          organicRank: 147,

          keywordSales: 236
        },
        {
          date: '2024-06-20',
          searchVolume: 5147,
          organicRank: 115,

          keywordSales: 236
        },
        {
          date: '2024-06-19',
          searchVolume: 5147,
          organicRank: 102,

          keywordSales: 236
        },
        {
          date: '2024-06-18',
          searchVolume: 5147,
          organicRank: 134,

          keywordSales: 236
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_drinks`,
      keyword: 'drinks',
      searchVolume: 37873,
      organicStartRank: 65,
      organicHighestRank: 37,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 37389,
          organicRank: 59,

          keywordSales: 1708
        },
        {
          date: '2024-08-06',
          searchVolume: 37389,
          organicRank: 51,

          keywordSales: 1708
        },
        {
          date: '2024-08-05',
          searchVolume: 37389,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-08-04',
          searchVolume: 37389,
          organicRank: 68,

          keywordSales: 1708
        },
        {
          date: '2024-08-03',
          searchVolume: 37389,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-08-01',
          searchVolume: 37389,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-31',
          searchVolume: 37389,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-30',
          searchVolume: 37389,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-29',
          searchVolume: 37389,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-28',
          searchVolume: 37389,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-27',
          searchVolume: 37389,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-26',
          searchVolume: 42703,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-25',
          searchVolume: 42703,
          organicRank: 70,

          keywordSales: 1708
        },
        {
          date: '2024-07-24',
          searchVolume: 42703,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-23',
          searchVolume: 42703,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-22',
          searchVolume: 42703,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-21',
          searchVolume: 42703,
          organicRank: 59,

          keywordSales: 1708
        },
        {
          date: '2024-07-20',
          searchVolume: 42703,
          organicRank: 54,

          keywordSales: 1708
        },
        {
          date: '2024-07-19',
          searchVolume: 38019,
          organicRank: 111,

          keywordSales: 1708
        },
        {
          date: '2024-07-18',
          searchVolume: 38019,
          organicRank: 70,

          keywordSales: 1708
        },
        {
          date: '2024-07-17',
          searchVolume: 38019,
          organicRank: 77,

          keywordSales: 1708
        },
        {
          date: '2024-07-16',
          searchVolume: 38019,
          organicRank: 65,

          keywordSales: 1708
        },
        {
          date: '2024-07-15',
          searchVolume: 38019,
          organicRank: 120,

          keywordSales: 1708
        },
        {
          date: '2024-07-14',
          searchVolume: 38019,
          organicRank: 130,

          keywordSales: 1708
        },
        {
          date: '2024-07-13',
          searchVolume: 38019,
          organicRank: 72,

          keywordSales: 1708
        },
        {
          date: '2024-07-12',
          searchVolume: 36213,
          organicRank: 69,

          keywordSales: 1708
        },
        {
          date: '2024-07-11',
          searchVolume: 36213,
          organicRank: 60,

          keywordSales: 1708
        },
        {
          date: '2024-07-10',
          searchVolume: 36213,
          organicRank: 66,

          keywordSales: 1708
        },
        {
          date: '2024-07-09',
          searchVolume: 36213,
          organicRank: 66,

          keywordSales: 1708
        },
        {
          date: '2024-07-08',
          searchVolume: 36213,
          organicRank: 62,

          keywordSales: 1708
        },
        {
          date: '2024-07-07',
          searchVolume: 36213,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-06',
          searchVolume: 36213,
          organicRank: 66,

          keywordSales: 1708
        },
        {
          date: '2024-07-05',
          searchVolume: 37897,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-04',
          searchVolume: 37897,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-07-03',
          searchVolume: 37897,
          organicRank: 66,

          keywordSales: 1708
        },
        {
          date: '2024-07-02',
          searchVolume: 37897,
          organicRank: 54,

          keywordSales: 1708
        },
        {
          date: '2024-07-01',
          searchVolume: 37897,
          organicRank: 87,

          keywordSales: 1708
        },
        {
          date: '2024-06-30',
          searchVolume: 37897,
          organicRank: 56,

          keywordSales: 1708
        },
        {
          date: '2024-06-29',
          searchVolume: 37897,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-06-28',
          searchVolume: 37751,
          organicRank: 75,

          keywordSales: 1708
        },
        {
          date: '2024-06-27',
          searchVolume: 37751,
          organicRank: 71,

          keywordSales: 1708
        },
        {
          date: '2024-06-26',
          searchVolume: 37751,
          organicRank: 74,

          keywordSales: 1708
        },
        {
          date: '2024-06-25',
          searchVolume: 37751,
          organicRank: 37,

          keywordSales: 1708
        },
        {
          date: '2024-06-23',
          searchVolume: 37751,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-06-22',
          searchVolume: 37751,
          organicRank: 55,

          keywordSales: 1708
        },
        {
          date: '2024-06-21',
          searchVolume: 37873,
          organicRank: 306,

          keywordSales: 1708
        },
        {
          date: '2024-06-20',
          searchVolume: 37873,
          organicRank: 55,

          keywordSales: 1708
        },
        {
          date: '2024-06-19',
          searchVolume: 37873,
          organicRank: 65,

          keywordSales: 1708
        },
        {
          date: '2024-06-18',
          searchVolume: 37873,
          organicRank: 88,

          keywordSales: 1708
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_drinks variety pack`,
      keyword: 'drinks variety pack',
      searchVolume: 3440,
      organicStartRank: 60,
      organicHighestRank: 41,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2241,
          organicRank: 74,

          keywordSales: 87
        },
        {
          date: '2024-08-06',
          searchVolume: 2241,
          organicRank: 76,

          keywordSales: 87
        },
        {
          date: '2024-08-05',
          searchVolume: 2241,
          organicRank: 263,

          keywordSales: 87
        },
        {
          date: '2024-08-04',
          searchVolume: 2241,
          organicRank: 171,

          keywordSales: 87
        },
        {
          date: '2024-08-03',
          searchVolume: 2241,
          organicRank: 144,

          keywordSales: 87
        },
        {
          date: '2024-08-01',
          searchVolume: 2241,
          organicRank: 148,

          keywordSales: 87
        },
        {
          date: '2024-07-31',
          searchVolume: 2241,
          organicRank: 111,

          keywordSales: 87
        },
        {
          date: '2024-07-30',
          searchVolume: 2241,
          organicRank: 70,

          keywordSales: 87
        },
        {
          date: '2024-07-29',
          searchVolume: 2241,
          organicRank: 66,

          keywordSales: 87
        },
        {
          date: '2024-07-28',
          searchVolume: 2241,
          organicRank: 84,

          keywordSales: 87
        },
        {
          date: '2024-07-27',
          searchVolume: 2241,
          organicRank: 85,

          keywordSales: 87
        },
        {
          date: '2024-07-26',
          searchVolume: 2435,
          organicRank: 71,

          keywordSales: 87
        },
        {
          date: '2024-07-25',
          searchVolume: 2435,
          organicRank: 64,

          keywordSales: 87
        },
        {
          date: '2024-07-24',
          searchVolume: 2435,
          organicRank: 81,

          keywordSales: 87
        },
        {
          date: '2024-07-23',
          searchVolume: 2435,
          organicRank: 56,

          keywordSales: 87
        },
        {
          date: '2024-07-22',
          searchVolume: 2435,
          organicRank: 75,

          keywordSales: 87
        },
        {
          date: '2024-07-21',
          searchVolume: 2435,
          organicRank: 71,

          keywordSales: 87
        },
        {
          date: '2024-07-20',
          searchVolume: 2435,
          organicRank: 73,

          keywordSales: 87
        },
        {
          date: '2024-07-19',
          searchVolume: 2643,
          organicRank: 67,

          keywordSales: 87
        },
        {
          date: '2024-07-18',
          searchVolume: 2643,
          organicRank: 41,

          keywordSales: 87
        },
        {
          date: '2024-07-17',
          searchVolume: 2643,
          organicRank: 45,

          keywordSales: 87
        },
        {
          date: '2024-07-16',
          searchVolume: 2643,
          organicRank: 44,

          keywordSales: 87
        },
        {
          date: '2024-07-15',
          searchVolume: 2643,
          organicRank: 76,

          keywordSales: 87
        },
        {
          date: '2024-07-14',
          searchVolume: 2643,
          organicRank: 82,

          keywordSales: 87
        },
        {
          date: '2024-07-13',
          searchVolume: 2643,
          organicRank: 65,

          keywordSales: 87
        },
        {
          date: '2024-07-12',
          searchVolume: 2989,
          organicRank: 46,

          keywordSales: 87
        },
        {
          date: '2024-07-11',
          searchVolume: 2989,
          organicRank: 50,

          keywordSales: 87
        },
        {
          date: '2024-07-10',
          searchVolume: 2989,
          organicRank: 54,

          keywordSales: 87
        },
        {
          date: '2024-07-09',
          searchVolume: 2989,
          organicRank: 65,

          keywordSales: 87
        },
        {
          date: '2024-07-08',
          searchVolume: 2989,
          organicRank: 46,

          keywordSales: 87
        },
        {
          date: '2024-07-07',
          searchVolume: 2989,
          organicRank: 64,

          keywordSales: 87
        },
        {
          date: '2024-07-06',
          searchVolume: 2989,
          organicRank: 59,

          keywordSales: 87
        },
        {
          date: '2024-07-05',
          searchVolume: 3128,
          organicRank: 68,

          keywordSales: 87
        },
        {
          date: '2024-07-04',
          searchVolume: 3128,
          organicRank: 69,

          keywordSales: 87
        },
        {
          date: '2024-07-03',
          searchVolume: 3128,
          organicRank: 64,

          keywordSales: 87
        },
        {
          date: '2024-07-02',
          searchVolume: 3128,
          organicRank: 63,

          keywordSales: 87
        },
        {
          date: '2024-07-01',
          searchVolume: 3128,
          organicRank: 52,

          keywordSales: 87
        },
        {
          date: '2024-06-30',
          searchVolume: 3128,
          organicRank: 47,

          keywordSales: 87
        },
        {
          date: '2024-06-29',
          searchVolume: 3128,
          organicRank: 48,

          keywordSales: 87
        },
        {
          date: '2024-06-28',
          searchVolume: 3427,
          organicRank: 49,

          keywordSales: 87
        },
        {
          date: '2024-06-27',
          searchVolume: 3427,
          organicRank: 53,

          keywordSales: 87
        },
        {
          date: '2024-06-26',
          searchVolume: 3427,
          organicRank: 44,

          keywordSales: 87
        },
        {
          date: '2024-06-25',
          searchVolume: 3427,
          organicRank: 41,

          keywordSales: 87
        },
        {
          date: '2024-06-24',
          searchVolume: 3427,
          organicRank: 45,

          keywordSales: 87
        },
        {
          date: '2024-06-21',
          searchVolume: 3440,
          organicRank: 70,

          keywordSales: 87
        },
        {
          date: '2024-06-20',
          searchVolume: 3440,
          organicRank: 46,

          keywordSales: 87
        },
        {
          date: '2024-06-19',
          searchVolume: 3440,
          organicRank: 60,

          keywordSales: 87
        },
        {
          date: '2024-06-18',
          searchVolume: 3440,
          organicRank: 49,

          keywordSales: 87
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy`,
      keyword: 'energy',
      searchVolume: 6749,
      organicStartRank: 22,
      organicHighestRank: 11,
      sponsoredStartRank: 55,
      sponsoredHighestRank: 30,
      organicPageOneWinDate: '2024-06-20',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 6727,
          organicRank: 16,

          keywordSales: 184
        },
        {
          date: '2024-08-06',
          searchVolume: 6727,
          organicRank: 18,

          keywordSales: 184
        },
        {
          date: '2024-08-05',
          searchVolume: 6727,
          organicRank: 12,

          keywordSales: 184
        },
        {
          date: '2024-08-04',
          searchVolume: 6727,
          organicRank: 28,

          keywordSales: 184
        },
        {
          date: '2024-08-02',
          searchVolume: 6727,
          organicRank: 36,

          keywordSales: 184
        },
        {
          date: '2024-08-01',
          searchVolume: 6727,
          organicRank: 15,

          keywordSales: 184
        },
        {
          date: '2024-07-31',
          searchVolume: 6727,
          organicRank: 13,

          keywordSales: 184
        },
        {
          date: '2024-07-30',
          searchVolume: 6727,
          organicRank: 16,

          keywordSales: 184
        },
        {
          date: '2024-07-29',
          searchVolume: 6727,
          organicRank: 21,

          keywordSales: 184
        },
        {
          date: '2024-07-28',
          searchVolume: 6727,
          organicRank: 34,

          keywordSales: 184
        },
        {
          date: '2024-07-27',
          searchVolume: 6727,
          organicRank: 27,

          keywordSales: 184
        },
        {
          date: '2024-07-26',
          searchVolume: 6959,
          organicRank: 23,

          keywordSales: 184
        },
        {
          date: '2024-07-25',
          searchVolume: 6959,
          organicRank: 42,

          keywordSales: 184
        },
        {
          date: '2024-07-24',
          searchVolume: 6959,
          organicRank: 67,

          keywordSales: 184
        },
        {
          date: '2024-07-23',
          searchVolume: 6959,
          organicRank: 71,

          keywordSales: 184
        },
        {
          date: '2024-07-22',
          searchVolume: 6959,
          organicRank: 59,

          keywordSales: 184
        },
        {
          date: '2024-07-21',
          searchVolume: 6959,
          organicRank: 52,

          keywordSales: 184
        },
        {
          date: '2024-07-20',
          searchVolume: 6959,
          organicRank: 69,

          keywordSales: 184
        },
        {
          date: '2024-07-19',
          searchVolume: 6336,
          organicRank: 45,

          keywordSales: 184
        },
        {
          date: '2024-07-18',
          searchVolume: 6336,
          organicRank: 38,

          keywordSales: 184
        },
        {
          date: '2024-07-17',
          searchVolume: 6336,
          organicRank: 38,

          keywordSales: 184
        },
        {
          date: '2024-07-16',
          searchVolume: 6336,
          organicRank: 63,

          keywordSales: 184
        },
        {
          date: '2024-07-15',
          searchVolume: 6336,
          organicRank: 22,

          keywordSales: 184
        },
        {
          date: '2024-07-14',
          searchVolume: 6336,
          organicRank: 59,
          sponsoredRank: 32,
          keywordSales: 184
        },
        {
          date: '2024-07-13',
          searchVolume: 6336,
          organicRank: 48,

          keywordSales: 184
        },
        {
          date: '2024-07-12',
          searchVolume: 6450,
          organicRank: 53,

          keywordSales: 184
        },
        {
          date: '2024-07-10',
          searchVolume: 6450,
          organicRank: 306,

          keywordSales: 184
        },
        {
          date: '2024-07-09',
          searchVolume: 6450,
          organicRank: 38,

          keywordSales: 184
        },
        {
          date: '2024-07-08',
          searchVolume: 6450,
          organicRank: 35,

          keywordSales: 184
        },
        {
          date: '2024-07-07',
          searchVolume: 6450,
          organicRank: 26,
          sponsoredRank: 46,
          keywordSales: 184
        },
        {
          date: '2024-07-06',
          searchVolume: 6450,
          organicRank: 27,

          keywordSales: 184
        },
        {
          date: '2024-07-05',
          searchVolume: 6760,
          organicRank: 27,

          keywordSales: 184
        },
        {
          date: '2024-07-04',
          searchVolume: 6760,
          organicRank: 24,

          keywordSales: 184
        },
        {
          date: '2024-07-03',
          searchVolume: 6760,
          organicRank: 24,

          keywordSales: 184
        },
        {
          date: '2024-07-02',
          searchVolume: 6760,
          organicRank: 24,

          keywordSales: 184
        },
        {
          date: '2024-07-01',
          searchVolume: 6760,
          organicRank: 25,

          keywordSales: 184
        },
        {
          date: '2024-06-30',
          searchVolume: 6760,
          organicRank: 26,

          keywordSales: 184
        },
        {
          date: '2024-06-29',
          searchVolume: 6760,
          organicRank: 23,

          keywordSales: 184
        },
        {
          date: '2024-06-28',
          searchVolume: 6732,
          organicRank: 22,

          keywordSales: 184
        },
        {
          date: '2024-06-26',
          searchVolume: 6732,
          organicRank: 16,

          keywordSales: 184
        },
        {
          date: '2024-06-25',
          searchVolume: 6732,
          organicRank: 25,

          keywordSales: 184
        },
        {
          date: '2024-06-24',
          searchVolume: 6732,
          organicRank: 14,

          keywordSales: 184
        },
        {
          date: '2024-06-23',
          searchVolume: 6732,
          organicRank: 14,

          keywordSales: 184
        },
        {
          date: '2024-06-22',
          searchVolume: 6732,
          organicRank: 18,

          keywordSales: 184
        },
        {
          date: '2024-06-21',
          searchVolume: 6749,
          organicRank: 11,
          sponsoredRank: 30,
          keywordSales: 184
        },
        {
          date: '2024-06-20',
          searchVolume: 6749,
          organicRank: 12,
          sponsoredRank: 67,
          keywordSales: 184
        },
        {
          date: '2024-06-19',
          searchVolume: 6749,
          organicRank: 22,

          keywordSales: 184
        },
        {
          date: '2024-06-18',
          searchVolume: 6749,
          organicRank: 24,

          keywordSales: 184
        },
        {
          date: '2024-06-17',
          searchVolume: 6749,
          organicRank: 25,
          sponsoredRank: 55,
          keywordSales: 184
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy drink`,
      keyword: 'energy drink',
      searchVolume: 82641,
      organicStartRank: 7,
      organicHighestRank: 2,
      sponsoredStartRank: 51,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-07-23',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 68735,
          organicRank: 19,

          keywordSales: 2374
        },
        {
          date: '2024-08-05',
          searchVolume: 68735,
          organicRank: 9,

          keywordSales: 2374
        },
        {
          date: '2024-08-04',
          searchVolume: 68735,
          organicRank: 3,

          keywordSales: 2374
        },
        {
          date: '2024-08-01',
          searchVolume: 68735,
          organicRank: 3,

          keywordSales: 2374
        },
        {
          date: '2024-07-31',
          searchVolume: 68735,
          organicRank: 2,

          keywordSales: 2374
        },
        {
          date: '2024-07-30',
          searchVolume: 68735,
          organicRank: 4,

          keywordSales: 2374
        },
        {
          date: '2024-07-29',
          searchVolume: 68735,
          organicRank: 2,

          keywordSales: 2374
        },
        {
          date: '2024-07-28',
          searchVolume: 68735,
          organicRank: 4,

          keywordSales: 2374
        },
        {
          date: '2024-07-27',
          searchVolume: 68735,
          organicRank: 4,

          keywordSales: 2374
        },
        {
          date: '2024-07-26',
          searchVolume: 113199,
          organicRank: 2,

          keywordSales: 2374
        },
        {
          date: '2024-07-25',
          searchVolume: 113199,
          organicRank: 5,

          keywordSales: 2374
        },
        {
          date: '2024-07-24',
          searchVolume: 113199,
          organicRank: 9,
          sponsoredRank: 6,
          keywordSales: 2374
        },
        {
          date: '2024-07-23',
          searchVolume: 113199,
          organicRank: 2,

          keywordSales: 2374
        },
        {
          date: '2024-07-22',
          searchVolume: 113199,
          organicRank: 37,
          sponsoredRank: 20,
          keywordSales: 2374
        },
        {
          date: '2024-07-21',
          searchVolume: 113199,
          organicRank: 12,
          sponsoredRank: 45,
          keywordSales: 2374
        },
        {
          date: '2024-07-20',
          searchVolume: 113199,
          organicRank: 4,
          sponsoredRank: 57,
          keywordSales: 2374
        },
        {
          date: '2024-07-19',
          searchVolume: 77575,
          organicRank: 5,

          keywordSales: 2374
        },
        {
          date: '2024-07-18',
          searchVolume: 77575,
          organicRank: 2,

          keywordSales: 2374
        },
        {
          date: '2024-07-17',
          searchVolume: 77575,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 2374
        },
        {
          date: '2024-07-16',
          searchVolume: 77575,
          organicRank: 15,
          sponsoredRank: 13,
          keywordSales: 2374
        },
        {
          date: '2024-07-15',
          searchVolume: 77575,
          organicRank: 9,
          sponsoredRank: 14,
          keywordSales: 2374
        },
        {
          date: '2024-07-14',
          searchVolume: 77575,
          organicRank: 13,
          sponsoredRank: 16,
          keywordSales: 2374
        },
        {
          date: '2024-07-13',
          searchVolume: 77575,
          organicRank: 13,
          sponsoredRank: 4,
          keywordSales: 2374
        },
        {
          date: '2024-07-12',
          searchVolume: 71272,
          organicRank: 13,
          sponsoredRank: 8,
          keywordSales: 2374
        },
        {
          date: '2024-07-11',
          searchVolume: 71272,
          organicRank: 10,
          sponsoredRank: 56,
          keywordSales: 2374
        },
        {
          date: '2024-07-10',
          searchVolume: 71272,
          organicRank: 7,
          sponsoredRank: 46,
          keywordSales: 2374
        },
        {
          date: '2024-07-09',
          searchVolume: 71272,
          organicRank: 9,
          sponsoredRank: 27,
          keywordSales: 2374
        },
        {
          date: '2024-07-08',
          searchVolume: 71272,
          organicRank: 10,
          sponsoredRank: 46,
          keywordSales: 2374
        },
        {
          date: '2024-07-07',
          searchVolume: 71272,
          organicRank: 10,
          sponsoredRank: 8,
          keywordSales: 2374
        },
        {
          date: '2024-07-06',
          searchVolume: 71272,
          organicRank: 11,
          sponsoredRank: 50,
          keywordSales: 2374
        },
        {
          date: '2024-07-05',
          searchVolume: 77337,
          organicRank: 3,
          sponsoredRank: 38,
          keywordSales: 2374
        },
        {
          date: '2024-07-04',
          searchVolume: 77337,
          organicRank: 8,
          sponsoredRank: 37,
          keywordSales: 2374
        },
        {
          date: '2024-07-03',
          searchVolume: 77337,
          organicRank: 9,
          sponsoredRank: 58,
          keywordSales: 2374
        },
        {
          date: '2024-07-02',
          searchVolume: 77337,
          organicRank: 7,

          keywordSales: 2374
        },
        {
          date: '2024-07-01',
          searchVolume: 77337,
          organicRank: 5,
          sponsoredRank: 79,
          keywordSales: 2374
        },
        {
          date: '2024-06-30',
          searchVolume: 77337,
          organicRank: 5,
          sponsoredRank: 8,
          keywordSales: 2374
        },
        {
          date: '2024-06-29',
          searchVolume: 77337,
          organicRank: 4,
          sponsoredRank: 16,
          keywordSales: 2374
        },
        {
          date: '2024-06-28',
          searchVolume: 74256,
          organicRank: 3,
          sponsoredRank: 67,
          keywordSales: 2374
        },
        {
          date: '2024-06-27',
          searchVolume: 74256,
          organicRank: 4,
          sponsoredRank: 46,
          keywordSales: 2374
        },
        {
          date: '2024-06-26',
          searchVolume: 74256,
          organicRank: 7,
          sponsoredRank: 9,
          keywordSales: 2374
        },
        {
          date: '2024-06-25',
          searchVolume: 74256,
          organicRank: 8,
          sponsoredRank: 75,
          keywordSales: 2374
        },
        {
          date: '2024-06-24',
          searchVolume: 74256,
          organicRank: 7,
          sponsoredRank: 27,
          keywordSales: 2374
        },
        {
          date: '2024-06-23',
          searchVolume: 74256,
          organicRank: 6,
          sponsoredRank: 10,
          keywordSales: 2374
        },
        {
          date: '2024-06-22',
          searchVolume: 74256,
          organicRank: 6,
          sponsoredRank: 48,
          keywordSales: 2374
        },
        {
          date: '2024-06-21',
          searchVolume: 82641,
          organicRank: 6,
          sponsoredRank: 6,
          keywordSales: 2374
        },
        {
          date: '2024-06-20',
          searchVolume: 82641,
          organicRank: 15,
          sponsoredRank: 27,
          keywordSales: 2374
        },
        {
          date: '2024-06-19',
          searchVolume: 82641,
          organicRank: 7,
          sponsoredRank: 51,
          keywordSales: 2374
        },
        {
          date: '2024-06-18',
          searchVolume: 82641,
          organicRank: 17,
          sponsoredRank: 58,
          keywordSales: 2374
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy drink mix`,
      keyword: 'energy drink mix',
      searchVolume: 1928,
      organicStartRank: 65,
      organicHighestRank: 63,
      sponsoredStartRank: 49,
      sponsoredHighestRank: 22,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1771,
          organicRank: 88,

          keywordSales: 56
        },
        {
          date: '2024-08-06',
          searchVolume: 1771,
          organicRank: 83,

          keywordSales: 56
        },
        {
          date: '2024-08-05',
          searchVolume: 1771,
          organicRank: 81,

          keywordSales: 56
        },
        {
          date: '2024-08-04',
          searchVolume: 1771,
          organicRank: 79,

          keywordSales: 56
        },
        {
          date: '2024-08-03',
          searchVolume: 1771,
          organicRank: 84,

          keywordSales: 56
        },
        {
          date: '2024-08-01',
          searchVolume: 1771,
          organicRank: 90,

          keywordSales: 56
        },
        {
          date: '2024-07-31',
          searchVolume: 1771,
          organicRank: 90,

          keywordSales: 56
        },
        {
          date: '2024-07-30',
          searchVolume: 1771,
          organicRank: 69,

          keywordSales: 56
        },
        {
          date: '2024-07-29',
          searchVolume: 1771,
          organicRank: 83,

          keywordSales: 56
        },
        {
          date: '2024-07-28',
          searchVolume: 1771,
          organicRank: 63,

          keywordSales: 56
        },
        {
          date: '2024-07-27',
          searchVolume: 1771,
          organicRank: 86,

          keywordSales: 56
        },
        {
          date: '2024-07-26',
          searchVolume: 1835,
          organicRank: 84,

          keywordSales: 56
        },
        {
          date: '2024-07-25',
          searchVolume: 1835,
          organicRank: 93,

          keywordSales: 56
        },
        {
          date: '2024-07-24',
          searchVolume: 1835,
          organicRank: 89,

          keywordSales: 56
        },
        {
          date: '2024-07-23',
          searchVolume: 1835,
          organicRank: 84,

          keywordSales: 56
        },
        {
          date: '2024-07-22',
          searchVolume: 1835,
          organicRank: 93,

          keywordSales: 56
        },
        {
          date: '2024-07-21',
          searchVolume: 1835,
          organicRank: 88,
          sponsoredRank: 59,
          keywordSales: 56
        },
        {
          date: '2024-07-20',
          searchVolume: 1835,
          organicRank: 83,
          sponsoredRank: 63,
          keywordSales: 56
        },
        {
          date: '2024-07-19',
          searchVolume: 2038,
          organicRank: 82,
          sponsoredRank: 64,
          keywordSales: 56
        },
        {
          date: '2024-07-18',
          searchVolume: 2038,
          organicRank: 85,
          sponsoredRank: 63,
          keywordSales: 56
        },
        {
          date: '2024-07-17',
          searchVolume: 2038,
          organicRank: 83,
          sponsoredRank: 30,
          keywordSales: 56
        },
        {
          date: '2024-07-16',
          searchVolume: 2038,
          organicRank: 90,
          sponsoredRank: 27,
          keywordSales: 56
        },
        {
          date: '2024-07-15',
          searchVolume: 2038,
          organicRank: 89,
          sponsoredRank: 48,
          keywordSales: 56
        },
        {
          date: '2024-07-14',
          searchVolume: 2038,
          organicRank: 92,
          sponsoredRank: 22,
          keywordSales: 56
        },
        {
          date: '2024-07-13',
          searchVolume: 2038,
          organicRank: 90,

          keywordSales: 56
        },
        {
          date: '2024-07-12',
          searchVolume: 1840,
          organicRank: 89,

          keywordSales: 56
        },
        {
          date: '2024-07-10',
          searchVolume: 1840,
          organicRank: 85,

          keywordSales: 56
        },
        {
          date: '2024-07-09',
          searchVolume: 1840,
          organicRank: 86,

          keywordSales: 56
        },
        {
          date: '2024-07-08',
          searchVolume: 1840,
          organicRank: 80,

          keywordSales: 56
        },
        {
          date: '2024-07-07',
          searchVolume: 1840,
          organicRank: 87,

          keywordSales: 56
        },
        {
          date: '2024-07-06',
          searchVolume: 1840,
          organicRank: 84,

          keywordSales: 56
        },
        {
          date: '2024-07-05',
          searchVolume: 1932,
          organicRank: 78,

          keywordSales: 56
        },
        {
          date: '2024-07-03',
          searchVolume: 1932,
          organicRank: 86,

          keywordSales: 56
        },
        {
          date: '2024-07-02',
          searchVolume: 1932,
          organicRank: 80,

          keywordSales: 56
        },
        {
          date: '2024-07-01',
          searchVolume: 1932,
          organicRank: 70,

          keywordSales: 56
        },
        {
          date: '2024-06-30',
          searchVolume: 1932,
          organicRank: 65,

          keywordSales: 56
        },
        {
          date: '2024-06-29',
          searchVolume: 1932,
          organicRank: 72,

          keywordSales: 56
        },
        {
          date: '2024-06-28',
          searchVolume: 1919,
          organicRank: 68,

          keywordSales: 56
        },
        {
          date: '2024-06-27',
          searchVolume: 1919,
          organicRank: 71,

          keywordSales: 56
        },
        {
          date: '2024-06-26',
          searchVolume: 1919,
          organicRank: 136,

          keywordSales: 56
        },
        {
          date: '2024-06-25',
          searchVolume: 1919,
          organicRank: 64,

          keywordSales: 56
        },
        {
          date: '2024-06-23',
          searchVolume: 1919,
          organicRank: 67,

          keywordSales: 56
        },
        {
          date: '2024-06-20',
          searchVolume: 1928,
          organicRank: 78,
          sponsoredRank: 60,
          keywordSales: 56
        },
        {
          date: '2024-06-19',
          searchVolume: 1928,
          organicRank: 65,
          sponsoredRank: 49,
          keywordSales: 56
        },
        {
          date: '2024-06-18',
          searchVolume: 1928,
          organicRank: 56,
          sponsoredRank: 52,
          keywordSales: 56
        },
        {
          date: '2024-06-17',
          searchVolume: 1928,
          organicRank: 79,
          sponsoredRank: 19,
          keywordSales: 56
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy drink packets`,
      keyword: 'energy drink packets',
      searchVolume: 2548,
      organicStartRank: 56,
      organicHighestRank: 52,
      sponsoredStartRank: 55,
      sponsoredHighestRank: 13,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2354,
          organicRank: 306,
          sponsoredRank: 57,
          keywordSales: 87
        },
        {
          date: '2024-08-05',
          searchVolume: 2354,
          organicRank: 306,
          sponsoredRank: 33,
          keywordSales: 87
        },
        {
          date: '2024-08-04',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-08-03',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-08-02',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-08-01',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-07-31',
          searchVolume: 2354,
          organicRank: 61,

          keywordSales: 87
        },
        {
          date: '2024-07-30',
          searchVolume: 2354,
          organicRank: 77,

          keywordSales: 87
        },
        {
          date: '2024-07-29',
          searchVolume: 2354,
          organicRank: 77,

          keywordSales: 87
        },
        {
          date: '2024-07-28',
          searchVolume: 2354,
          organicRank: 83,

          keywordSales: 87
        },
        {
          date: '2024-07-27',
          searchVolume: 2354,
          organicRank: 80,

          keywordSales: 87
        },
        {
          date: '2024-07-26',
          searchVolume: 2688,
          organicRank: 83,

          keywordSales: 87
        },
        {
          date: '2024-07-25',
          searchVolume: 2688,
          organicRank: 169,

          keywordSales: 87
        },
        {
          date: '2024-07-24',
          searchVolume: 2688,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-07-23',
          searchVolume: 2688,
          organicRank: 109,

          keywordSales: 87
        },
        {
          date: '2024-07-22',
          searchVolume: 2688,
          organicRank: 109,

          keywordSales: 87
        },
        {
          date: '2024-07-21',
          searchVolume: 2688,
          organicRank: 104,
          sponsoredRank: 60,
          keywordSales: 87
        },
        {
          date: '2024-07-20',
          searchVolume: 2688,
          organicRank: 98,
          sponsoredRank: 57,
          keywordSales: 87
        },
        {
          date: '2024-07-19',
          searchVolume: 2934,
          organicRank: 82,
          sponsoredRank: 58,
          keywordSales: 87
        },
        {
          date: '2024-07-18',
          searchVolume: 2934,
          organicRank: 72,
          sponsoredRank: 83,
          keywordSales: 87
        },
        {
          date: '2024-07-17',
          searchVolume: 2934,
          organicRank: 112,
          sponsoredRank: 16,
          keywordSales: 87
        },
        {
          date: '2024-07-16',
          searchVolume: 2934,
          organicRank: 82,
          sponsoredRank: 39,
          keywordSales: 87
        },
        {
          date: '2024-07-15',
          searchVolume: 2934,
          organicRank: 88,
          sponsoredRank: 27,
          keywordSales: 87
        },
        {
          date: '2024-07-14',
          searchVolume: 2934,
          organicRank: 89,
          sponsoredRank: 20,
          keywordSales: 87
        },
        {
          date: '2024-07-13',
          searchVolume: 2934,
          organicRank: 306,
          sponsoredRank: 56,
          keywordSales: 87
        },
        {
          date: '2024-07-12',
          searchVolume: 2325,
          organicRank: 70,
          sponsoredRank: 64,
          keywordSales: 87
        },
        {
          date: '2024-07-10',
          searchVolume: 2325,
          organicRank: 118,

          keywordSales: 87
        },
        {
          date: '2024-07-09',
          searchVolume: 2325,
          organicRank: 68,

          keywordSales: 87
        },
        {
          date: '2024-07-08',
          searchVolume: 2325,
          organicRank: 183,

          keywordSales: 87
        },
        {
          date: '2024-07-07',
          searchVolume: 2325,
          organicRank: 67,

          keywordSales: 87
        },
        {
          date: '2024-07-06',
          searchVolume: 2325,
          organicRank: 306,

          keywordSales: 87
        },
        {
          date: '2024-07-05',
          searchVolume: 2552,
          organicRank: 130,

          keywordSales: 87
        },
        {
          date: '2024-07-04',
          searchVolume: 2552,
          organicRank: 73,

          keywordSales: 87
        },
        {
          date: '2024-07-03',
          searchVolume: 2552,
          organicRank: 73,

          keywordSales: 87
        },
        {
          date: '2024-07-02',
          searchVolume: 2552,
          organicRank: 58,

          keywordSales: 87
        },
        {
          date: '2024-07-01',
          searchVolume: 2552,
          organicRank: 55,

          keywordSales: 87
        },
        {
          date: '2024-06-30',
          searchVolume: 2552,
          organicRank: 134,

          keywordSales: 87
        },
        {
          date: '2024-06-29',
          searchVolume: 2552,
          organicRank: 58,

          keywordSales: 87
        },
        {
          date: '2024-06-28',
          searchVolume: 2539,
          organicRank: 122,

          keywordSales: 87
        },
        {
          date: '2024-06-27',
          searchVolume: 2539,
          organicRank: 61,

          keywordSales: 87
        },
        {
          date: '2024-06-25',
          searchVolume: 2539,
          organicRank: 56,

          keywordSales: 87
        },
        {
          date: '2024-06-24',
          searchVolume: 2539,
          organicRank: 53,

          keywordSales: 87
        },
        {
          date: '2024-06-23',
          searchVolume: 2539,
          organicRank: 52,

          keywordSales: 87
        },
        {
          date: '2024-06-20',
          searchVolume: 2548,
          organicRank: 54,
          sponsoredRank: 13,
          keywordSales: 87
        },
        {
          date: '2024-06-19',
          searchVolume: 2548,
          organicRank: 56,
          sponsoredRank: 55,
          keywordSales: 87
        },
        {
          date: '2024-06-18',
          searchVolume: 2548,
          organicRank: 48,
          sponsoredRank: 6,
          keywordSales: 87
        },
        {
          date: '2024-06-17',
          searchVolume: 2548,
          organicRank: 50,
          sponsoredRank: 42,
          keywordSales: 87
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy drink powder`,
      keyword: 'energy drink powder',
      searchVolume: 10755,
      organicStartRank: 84,
      organicHighestRank: 65,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 10223,
          organicRank: 132,

          keywordSales: 368
        },
        {
          date: '2024-08-06',
          searchVolume: 10223,
          organicRank: 120,

          keywordSales: 368
        },
        {
          date: '2024-08-05',
          searchVolume: 10223,
          organicRank: 108,

          keywordSales: 368
        },
        {
          date: '2024-08-04',
          searchVolume: 10223,
          organicRank: 126,

          keywordSales: 368
        },
        {
          date: '2024-08-02',
          searchVolume: 10223,
          organicRank: 124,

          keywordSales: 368
        },
        {
          date: '2024-08-01',
          searchVolume: 10223,
          organicRank: 118,

          keywordSales: 368
        },
        {
          date: '2024-07-31',
          searchVolume: 10223,
          organicRank: 107,

          keywordSales: 368
        },
        {
          date: '2024-07-30',
          searchVolume: 10223,
          organicRank: 82,

          keywordSales: 368
        },
        {
          date: '2024-07-29',
          searchVolume: 10223,
          organicRank: 77,

          keywordSales: 368
        },
        {
          date: '2024-07-28',
          searchVolume: 10223,
          organicRank: 72,

          keywordSales: 368
        },
        {
          date: '2024-07-27',
          searchVolume: 10223,
          organicRank: 72,

          keywordSales: 368
        },
        {
          date: '2024-07-26',
          searchVolume: 11217,
          organicRank: 79,

          keywordSales: 368
        },
        {
          date: '2024-07-25',
          searchVolume: 11217,
          organicRank: 73,

          keywordSales: 368
        },
        {
          date: '2024-07-24',
          searchVolume: 11217,
          organicRank: 282,

          keywordSales: 368
        },
        {
          date: '2024-07-23',
          searchVolume: 11217,
          organicRank: 136,

          keywordSales: 368
        },
        {
          date: '2024-07-22',
          searchVolume: 11217,
          organicRank: 127,

          keywordSales: 368
        },
        {
          date: '2024-07-20',
          searchVolume: 11217,
          organicRank: 149,

          keywordSales: 368
        },
        {
          date: '2024-07-19',
          searchVolume: 10120,
          organicRank: 103,

          keywordSales: 368
        },
        {
          date: '2024-07-18',
          searchVolume: 10120,
          organicRank: 100,

          keywordSales: 368
        },
        {
          date: '2024-07-17',
          searchVolume: 10120,
          organicRank: 96,

          keywordSales: 368
        },
        {
          date: '2024-07-16',
          searchVolume: 10120,
          organicRank: 104,

          keywordSales: 368
        },
        {
          date: '2024-07-15',
          searchVolume: 10120,
          organicRank: 80,

          keywordSales: 368
        },
        {
          date: '2024-07-14',
          searchVolume: 10120,
          organicRank: 92,

          keywordSales: 368
        },
        {
          date: '2024-07-13',
          searchVolume: 10120,
          organicRank: 252,

          keywordSales: 368
        },
        {
          date: '2024-07-12',
          searchVolume: 8875,
          organicRank: 103,

          keywordSales: 368
        },
        {
          date: '2024-07-11',
          searchVolume: 8875,
          organicRank: 77,

          keywordSales: 368
        },
        {
          date: '2024-07-10',
          searchVolume: 8875,
          organicRank: 70,

          keywordSales: 368
        },
        {
          date: '2024-07-09',
          searchVolume: 8875,
          organicRank: 177,

          keywordSales: 368
        },
        {
          date: '2024-07-08',
          searchVolume: 8875,
          organicRank: 71,

          keywordSales: 368
        },
        {
          date: '2024-07-07',
          searchVolume: 8875,
          organicRank: 69,

          keywordSales: 368
        },
        {
          date: '2024-07-06',
          searchVolume: 8875,
          organicRank: 66,

          keywordSales: 368
        },
        {
          date: '2024-07-05',
          searchVolume: 11068,
          organicRank: 79,

          keywordSales: 368
        },
        {
          date: '2024-07-03',
          searchVolume: 11068,
          organicRank: 81,

          keywordSales: 368
        },
        {
          date: '2024-07-02',
          searchVolume: 11068,
          organicRank: 85,

          keywordSales: 368
        },
        {
          date: '2024-07-01',
          searchVolume: 11068,
          organicRank: 76,

          keywordSales: 368
        },
        {
          date: '2024-06-30',
          searchVolume: 11068,
          organicRank: 78,

          keywordSales: 368
        },
        {
          date: '2024-06-29',
          searchVolume: 11068,
          organicRank: 68,

          keywordSales: 368
        },
        {
          date: '2024-06-28',
          searchVolume: 11013,
          organicRank: 65,

          keywordSales: 368
        },
        {
          date: '2024-06-27',
          searchVolume: 11013,
          organicRank: 77,

          keywordSales: 368
        },
        {
          date: '2024-06-26',
          searchVolume: 11013,
          organicRank: 79,

          keywordSales: 368
        },
        {
          date: '2024-06-25',
          searchVolume: 11013,
          organicRank: 79,

          keywordSales: 368
        },
        {
          date: '2024-06-23',
          searchVolume: 11013,
          organicRank: 78,

          keywordSales: 368
        },
        {
          date: '2024-06-20',
          searchVolume: 10755,
          organicRank: 78,

          keywordSales: 368
        },
        {
          date: '2024-06-19',
          searchVolume: 10755,
          organicRank: 84,

          keywordSales: 368
        },
        {
          date: '2024-06-18',
          searchVolume: 10755,
          organicRank: 70,

          keywordSales: 368
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy drinks`,
      keyword: 'energy drinks',
      searchVolume: 19929,
      organicStartRank: 10,
      organicHighestRank: 3,
      sponsoredStartRank: 31,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-23',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 18187,
          organicRank: 14,
          sponsoredRank: 30,
          keywordSales: 227
        },
        {
          date: '2024-08-06',
          searchVolume: 18187,
          organicRank: 22,

          keywordSales: 227
        },
        {
          date: '2024-08-05',
          searchVolume: 18187,
          organicRank: 17,

          keywordSales: 227
        },
        {
          date: '2024-08-04',
          searchVolume: 18187,
          organicRank: 18,

          keywordSales: 227
        },
        {
          date: '2024-08-03',
          searchVolume: 18187,
          organicRank: 21,

          keywordSales: 227
        },
        {
          date: '2024-08-01',
          searchVolume: 18187,
          organicRank: 16,

          keywordSales: 227
        },
        {
          date: '2024-07-31',
          searchVolume: 18187,
          organicRank: 19,

          keywordSales: 227
        },
        {
          date: '2024-07-30',
          searchVolume: 18187,
          organicRank: 12,

          keywordSales: 227
        },
        {
          date: '2024-07-29',
          searchVolume: 18187,
          organicRank: 11,

          keywordSales: 227
        },
        {
          date: '2024-07-28',
          searchVolume: 18187,
          organicRank: 10,

          keywordSales: 227
        },
        {
          date: '2024-07-27',
          searchVolume: 18187,
          organicRank: 7,

          keywordSales: 227
        },
        {
          date: '2024-07-26',
          searchVolume: 38667,
          organicRank: 9,

          keywordSales: 227
        },
        {
          date: '2024-07-25',
          searchVolume: 38667,
          organicRank: 7,

          keywordSales: 227
        },
        {
          date: '2024-07-24',
          searchVolume: 38667,
          organicRank: 12,
          sponsoredRank: 28,
          keywordSales: 227
        },
        {
          date: '2024-07-23',
          searchVolume: 38667,
          organicRank: 4,

          keywordSales: 227
        },
        {
          date: '2024-07-22',
          searchVolume: 38667,
          organicRank: 23,
          sponsoredRank: 31,
          keywordSales: 227
        },
        {
          date: '2024-07-21',
          searchVolume: 38667,
          organicRank: 4,
          sponsoredRank: 21,
          keywordSales: 227
        },
        {
          date: '2024-07-20',
          searchVolume: 38667,
          organicRank: 7,
          sponsoredRank: 43,
          keywordSales: 227
        },
        {
          date: '2024-07-19',
          searchVolume: 18491,
          organicRank: 5,

          keywordSales: 227
        },
        {
          date: '2024-07-18',
          searchVolume: 18491,
          organicRank: 9,
          sponsoredRank: 43,
          keywordSales: 227
        },
        {
          date: '2024-07-17',
          searchVolume: 18491,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 227
        },
        {
          date: '2024-07-16',
          searchVolume: 18491,
          organicRank: 18,
          sponsoredRank: 41,
          keywordSales: 227
        },
        {
          date: '2024-07-15',
          searchVolume: 18491,
          organicRank: 16,
          sponsoredRank: 51,
          keywordSales: 227
        },
        {
          date: '2024-07-14',
          searchVolume: 18491,
          organicRank: 14,
          sponsoredRank: 27,
          keywordSales: 227
        },
        {
          date: '2024-07-13',
          searchVolume: 18491,
          organicRank: 16,
          sponsoredRank: 28,
          keywordSales: 227
        },
        {
          date: '2024-07-12',
          searchVolume: 18839,
          organicRank: 14,
          sponsoredRank: 26,
          keywordSales: 227
        },
        {
          date: '2024-07-11',
          searchVolume: 18839,
          organicRank: 13,

          keywordSales: 227
        },
        {
          date: '2024-07-10',
          searchVolume: 18839,
          organicRank: 12,
          sponsoredRank: 59,
          keywordSales: 227
        },
        {
          date: '2024-07-09',
          searchVolume: 18839,
          organicRank: 11,
          sponsoredRank: 42,
          keywordSales: 227
        },
        {
          date: '2024-07-08',
          searchVolume: 18839,
          organicRank: 13,
          sponsoredRank: 2,
          keywordSales: 227
        },
        {
          date: '2024-07-07',
          searchVolume: 18839,
          organicRank: 11,
          sponsoredRank: 15,
          keywordSales: 227
        },
        {
          date: '2024-07-06',
          searchVolume: 18839,
          organicRank: 17,
          sponsoredRank: 40,
          keywordSales: 227
        },
        {
          date: '2024-07-05',
          searchVolume: 19718,
          organicRank: 6,
          sponsoredRank: 8,
          keywordSales: 227
        },
        {
          date: '2024-07-04',
          searchVolume: 19718,
          organicRank: 10,
          sponsoredRank: 45,
          keywordSales: 227
        },
        {
          date: '2024-07-03',
          searchVolume: 19718,
          organicRank: 3,
          sponsoredRank: 58,
          keywordSales: 227
        },
        {
          date: '2024-07-02',
          searchVolume: 19718,
          organicRank: 3,
          sponsoredRank: 21,
          keywordSales: 227
        },
        {
          date: '2024-07-01',
          searchVolume: 19718,
          organicRank: 4,
          sponsoredRank: 30,
          keywordSales: 227
        },
        {
          date: '2024-06-30',
          searchVolume: 19718,
          organicRank: 8,
          sponsoredRank: 12,
          keywordSales: 227
        },
        {
          date: '2024-06-29',
          searchVolume: 19718,
          organicRank: 3,
          sponsoredRank: 43,
          keywordSales: 227
        },
        {
          date: '2024-06-28',
          searchVolume: 19642,
          organicRank: 3,
          sponsoredRank: 10,
          keywordSales: 227
        },
        {
          date: '2024-06-27',
          searchVolume: 19642,
          organicRank: 3,
          sponsoredRank: 45,
          keywordSales: 227
        },
        {
          date: '2024-06-26',
          searchVolume: 19642,
          organicRank: 6,
          sponsoredRank: 8,
          keywordSales: 227
        },
        {
          date: '2024-06-25',
          searchVolume: 19642,
          organicRank: 10,
          sponsoredRank: 22,
          keywordSales: 227
        },
        {
          date: '2024-06-24',
          searchVolume: 19642,
          organicRank: 10,
          sponsoredRank: 20,
          keywordSales: 227
        },
        {
          date: '2024-06-23',
          searchVolume: 19642,
          organicRank: 13,
          sponsoredRank: 33,
          keywordSales: 227
        },
        {
          date: '2024-06-22',
          searchVolume: 19642,
          organicRank: 12,
          sponsoredRank: 48,
          keywordSales: 227
        },
        {
          date: '2024-06-21',
          searchVolume: 19929,
          organicRank: 14,
          sponsoredRank: 15,
          keywordSales: 227
        },
        {
          date: '2024-06-20',
          searchVolume: 19929,
          organicRank: 12,
          sponsoredRank: 61,
          keywordSales: 227
        },
        {
          date: '2024-06-19',
          searchVolume: 19929,
          organicRank: 10,
          sponsoredRank: 31,
          keywordSales: 227
        },
        {
          date: '2024-06-18',
          searchVolume: 19929,
          organicRank: 7,

          keywordSales: 227
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy drinks sugar free`,
      keyword: 'energy drinks sugar free',
      searchVolume: 11769,
      organicStartRank: 5,
      organicHighestRank: 2,
      sponsoredStartRank: 58,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 5459,
          organicRank: 13,
          sponsoredRank: 60,
          keywordSales: 133
        },
        {
          date: '2024-08-06',
          searchVolume: 5459,
          organicRank: 12,

          keywordSales: 133
        },
        {
          date: '2024-08-05',
          searchVolume: 5459,
          organicRank: 12,

          keywordSales: 133
        },
        {
          date: '2024-08-04',
          searchVolume: 5459,
          organicRank: 6,

          keywordSales: 133
        },
        {
          date: '2024-08-03',
          searchVolume: 5459,
          organicRank: 9,

          keywordSales: 133
        },
        {
          date: '2024-08-01',
          searchVolume: 5459,
          organicRank: 6,

          keywordSales: 133
        },
        {
          date: '2024-07-31',
          searchVolume: 5459,
          organicRank: 10,

          keywordSales: 133
        },
        {
          date: '2024-07-30',
          searchVolume: 5459,
          organicRank: 6,

          keywordSales: 133
        },
        {
          date: '2024-07-29',
          searchVolume: 5459,
          organicRank: 5,

          keywordSales: 133
        },
        {
          date: '2024-07-28',
          searchVolume: 5459,
          organicRank: 6,

          keywordSales: 133
        },
        {
          date: '2024-07-27',
          searchVolume: 5459,
          organicRank: 6,

          keywordSales: 133
        },
        {
          date: '2024-07-26',
          searchVolume: 8613,
          organicRank: 6,

          keywordSales: 133
        },
        {
          date: '2024-07-25',
          searchVolume: 8613,
          organicRank: 6,

          keywordSales: 133
        },
        {
          date: '2024-07-24',
          searchVolume: 8613,
          organicRank: 7,
          sponsoredRank: 39,
          keywordSales: 133
        },
        {
          date: '2024-07-23',
          searchVolume: 8613,
          organicRank: 6,

          keywordSales: 133
        },
        {
          date: '2024-07-22',
          searchVolume: 8613,
          organicRank: 6,
          sponsoredRank: 4,
          keywordSales: 133
        },
        {
          date: '2024-07-21',
          searchVolume: 8613,
          organicRank: 6,
          sponsoredRank: 42,
          keywordSales: 133
        },
        {
          date: '2024-07-20',
          searchVolume: 8613,
          organicRank: 7,
          sponsoredRank: 58,
          keywordSales: 133
        },
        {
          date: '2024-07-19',
          searchVolume: 7960,
          organicRank: 4,
          sponsoredRank: 59,
          keywordSales: 133
        },
        {
          date: '2024-07-18',
          searchVolume: 7960,
          organicRank: 4,

          keywordSales: 133
        },
        {
          date: '2024-07-17',
          searchVolume: 7960,
          organicRank: 4,
          sponsoredRank: 20,
          keywordSales: 133
        },
        {
          date: '2024-07-16',
          searchVolume: 7960,
          organicRank: 9,
          sponsoredRank: 39,
          keywordSales: 133
        },
        {
          date: '2024-07-15',
          searchVolume: 7960,
          organicRank: 14,
          sponsoredRank: 8,
          keywordSales: 133
        },
        {
          date: '2024-07-14',
          searchVolume: 7960,
          organicRank: 16,
          sponsoredRank: 3,
          keywordSales: 133
        },
        {
          date: '2024-07-13',
          searchVolume: 7960,
          organicRank: 19,
          sponsoredRank: 19,
          keywordSales: 133
        },
        {
          date: '2024-07-12',
          searchVolume: 10390,
          organicRank: 12,
          sponsoredRank: 71,
          keywordSales: 133
        },
        {
          date: '2024-07-11',
          searchVolume: 10390,
          organicRank: 10,

          keywordSales: 133
        },
        {
          date: '2024-07-09',
          searchVolume: 10390,
          organicRank: 12,

          keywordSales: 133
        },
        {
          date: '2024-07-08',
          searchVolume: 10390,
          organicRank: 9,
          sponsoredRank: 45,
          keywordSales: 133
        },
        {
          date: '2024-07-07',
          searchVolume: 10390,
          organicRank: 10,

          keywordSales: 133
        },
        {
          date: '2024-07-06',
          searchVolume: 10390,
          organicRank: 11,
          sponsoredRank: 16,
          keywordSales: 133
        },
        {
          date: '2024-07-05',
          searchVolume: 11100,
          organicRank: 8,
          sponsoredRank: 28,
          keywordSales: 133
        },
        {
          date: '2024-07-04',
          searchVolume: 11100,
          organicRank: 6,
          sponsoredRank: 28,
          keywordSales: 133
        },
        {
          date: '2024-07-03',
          searchVolume: 11100,
          organicRank: 3,

          keywordSales: 133
        },
        {
          date: '2024-07-02',
          searchVolume: 11100,
          organicRank: 13,

          keywordSales: 133
        },
        {
          date: '2024-07-01',
          searchVolume: 11100,
          organicRank: 15,
          sponsoredRank: 32,
          keywordSales: 133
        },
        {
          date: '2024-06-30',
          searchVolume: 11100,
          organicRank: 15,
          sponsoredRank: 32,
          keywordSales: 133
        },
        {
          date: '2024-06-29',
          searchVolume: 11100,
          organicRank: 4,
          sponsoredRank: 12,
          keywordSales: 133
        },
        {
          date: '2024-06-28',
          searchVolume: 11735,
          organicRank: 4,
          sponsoredRank: 48,
          keywordSales: 133
        },
        {
          date: '2024-06-27',
          searchVolume: 11735,
          organicRank: 4,

          keywordSales: 133
        },
        {
          date: '2024-06-26',
          searchVolume: 11735,
          organicRank: 13,

          keywordSales: 133
        },
        {
          date: '2024-06-25',
          searchVolume: 11735,
          organicRank: 2,
          sponsoredRank: 23,
          keywordSales: 133
        },
        {
          date: '2024-06-21',
          searchVolume: 11769,
          organicRank: 4,

          keywordSales: 133
        },
        {
          date: '2024-06-20',
          searchVolume: 11769,
          organicRank: 6,
          sponsoredRank: 2,
          keywordSales: 133
        },
        {
          date: '2024-06-19',
          searchVolume: 11769,
          organicRank: 5,
          sponsoredRank: 58,
          keywordSales: 133
        },
        {
          date: '2024-06-18',
          searchVolume: 11769,
          organicRank: 8,

          keywordSales: 133
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy powder`,
      keyword: 'energy powder',
      searchVolume: 3236,
      organicStartRank: 306,
      organicHighestRank: 287,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-08-06',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-08-05',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-08-04',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-08-02',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-08-01',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-31',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-30',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-29',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-28',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-27',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-26',
          searchVolume: 3312,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-25',
          searchVolume: 3312,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-24',
          searchVolume: 3312,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-23',
          searchVolume: 3312,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-22',
          searchVolume: 3312,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-21',
          searchVolume: 3312,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-20',
          searchVolume: 3312,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-19',
          searchVolume: 2939,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-18',
          searchVolume: 2939,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-17',
          searchVolume: 2939,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-16',
          searchVolume: 2939,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-15',
          searchVolume: 2939,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-14',
          searchVolume: 2939,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-13',
          searchVolume: 2939,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-12',
          searchVolume: 2692,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-11',
          searchVolume: 2692,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-10',
          searchVolume: 2692,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-09',
          searchVolume: 2692,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-08',
          searchVolume: 2692,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-07',
          searchVolume: 2692,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-06',
          searchVolume: 2692,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-05',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-03',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-02',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-07-01',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-06-30',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-06-29',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-06-28',
          searchVolume: 3118,
          organicRank: 287,

          keywordSales: 75
        },
        {
          date: '2024-06-27',
          searchVolume: 3118,
          organicRank: 288,

          keywordSales: 75
        },
        {
          date: '2024-06-26',
          searchVolume: 3118,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-06-25',
          searchVolume: 3118,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-06-24',
          searchVolume: 3118,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-06-21',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-06-20',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-06-19',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 75
        },
        {
          date: '2024-06-18',
          searchVolume: 3236,
          organicRank: 306,

          keywordSales: 75
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy shot`,
      keyword: 'energy shot',
      searchVolume: 1930,
      organicStartRank: 130,
      organicHighestRank: 82,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2078,
          organicRank: 105,

          keywordSales: 99
        },
        {
          date: '2024-08-06',
          searchVolume: 2078,
          organicRank: 107,

          keywordSales: 99
        },
        {
          date: '2024-08-05',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-08-04',
          searchVolume: 2078,
          organicRank: 106,

          keywordSales: 99
        },
        {
          date: '2024-08-03',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 111,

          keywordSales: 99
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 135,

          keywordSales: 99
        },
        {
          date: '2024-07-29',
          searchVolume: 2078,
          organicRank: 114,

          keywordSales: 99
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 108,

          keywordSales: 99
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 115,

          keywordSales: 99
        },
        {
          date: '2024-07-26',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-07-25',
          searchVolume: 1839,
          organicRank: 104,

          keywordSales: 99
        },
        {
          date: '2024-07-24',
          searchVolume: 1839,
          organicRank: 104,

          keywordSales: 99
        },
        {
          date: '2024-07-23',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-07-22',
          searchVolume: 1839,
          organicRank: 93,

          keywordSales: 99
        },
        {
          date: '2024-07-21',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-07-20',
          searchVolume: 1839,
          organicRank: 90,

          keywordSales: 99
        },
        {
          date: '2024-07-19',
          searchVolume: 1810,
          organicRank: 106,

          keywordSales: 99
        },
        {
          date: '2024-07-18',
          searchVolume: 1810,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-07-17',
          searchVolume: 1810,
          organicRank: 105,

          keywordSales: 99
        },
        {
          date: '2024-07-16',
          searchVolume: 1810,
          organicRank: 132,

          keywordSales: 99
        },
        {
          date: '2024-07-15',
          searchVolume: 1810,
          organicRank: 115,

          keywordSales: 99
        },
        {
          date: '2024-07-14',
          searchVolume: 1810,
          organicRank: 90,

          keywordSales: 99
        },
        {
          date: '2024-07-13',
          searchVolume: 1810,
          organicRank: 91,

          keywordSales: 99
        },
        {
          date: '2024-07-12',
          searchVolume: 1837,
          organicRank: 82,

          keywordSales: 99
        },
        {
          date: '2024-07-10',
          searchVolume: 1837,
          organicRank: 90,

          keywordSales: 99
        },
        {
          date: '2024-07-09',
          searchVolume: 1837,
          organicRank: 82,

          keywordSales: 99
        },
        {
          date: '2024-07-08',
          searchVolume: 1837,
          organicRank: 87,

          keywordSales: 99
        },
        {
          date: '2024-07-07',
          searchVolume: 1837,
          organicRank: 83,

          keywordSales: 99
        },
        {
          date: '2024-07-06',
          searchVolume: 1837,
          organicRank: 95,

          keywordSales: 99
        },
        {
          date: '2024-07-05',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-07-04',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-07-03',
          searchVolume: 1928,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-07-02',
          searchVolume: 1928,
          organicRank: 93,

          keywordSales: 99
        },
        {
          date: '2024-07-01',
          searchVolume: 1928,
          organicRank: 101,

          keywordSales: 99
        },
        {
          date: '2024-06-30',
          searchVolume: 1928,
          organicRank: 102,

          keywordSales: 99
        },
        {
          date: '2024-06-29',
          searchVolume: 1928,
          organicRank: 108,

          keywordSales: 99
        },
        {
          date: '2024-06-28',
          searchVolume: 2381,
          organicRank: 108,

          keywordSales: 99
        },
        {
          date: '2024-06-27',
          searchVolume: 2381,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-06-26',
          searchVolume: 2381,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-06-25',
          searchVolume: 2381,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-06-24',
          searchVolume: 2381,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-06-21',
          searchVolume: 1930,
          organicRank: 306,

          keywordSales: 99
        },
        {
          date: '2024-06-20',
          searchVolume: 1930,
          organicRank: 106,

          keywordSales: 99
        },
        {
          date: '2024-06-19',
          searchVolume: 1930,
          organicRank: 130,

          keywordSales: 99
        },
        {
          date: '2024-06-18',
          searchVolume: 1930,
          organicRank: 107,

          keywordSales: 99
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy shots`,
      keyword: 'energy shots',
      searchVolume: 3627,
      organicStartRank: 306,
      organicHighestRank: 144,
      sponsoredStartRank: 21,
      sponsoredHighestRank: 21,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-08-06',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-08-05',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-08-04',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-08-03',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-08-01',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-31',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-30',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-29',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-28',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-27',
          searchVolume: 3990,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-26',
          searchVolume: 3456,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-25',
          searchVolume: 3456,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-24',
          searchVolume: 3456,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-23',
          searchVolume: 3456,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-22',
          searchVolume: 3456,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-21',
          searchVolume: 3456,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-20',
          searchVolume: 3456,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-19',
          searchVolume: 3396,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-18',
          searchVolume: 3396,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-17',
          searchVolume: 3396,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-16',
          searchVolume: 3396,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-15',
          searchVolume: 3396,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-14',
          searchVolume: 3396,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-13',
          searchVolume: 3396,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-12',
          searchVolume: 3305,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-10',
          searchVolume: 3305,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-09',
          searchVolume: 3305,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-08',
          searchVolume: 3305,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-07',
          searchVolume: 3305,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-06',
          searchVolume: 3305,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-05',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-07-04',
          searchVolume: 3627,
          organicRank: 145,

          keywordSales: 174
        },
        {
          date: '2024-07-03',
          searchVolume: 3627,
          organicRank: 144,

          keywordSales: 174
        },
        {
          date: '2024-07-02',
          searchVolume: 3627,
          organicRank: 151,

          keywordSales: 174
        },
        {
          date: '2024-07-01',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-30',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-29',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-28',
          searchVolume: 3805,
          organicRank: 180,

          keywordSales: 174
        },
        {
          date: '2024-06-27',
          searchVolume: 3805,
          organicRank: 169,

          keywordSales: 174
        },
        {
          date: '2024-06-26',
          searchVolume: 3805,
          organicRank: 171,

          keywordSales: 174
        },
        {
          date: '2024-06-25',
          searchVolume: 3805,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-21',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-20',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 174
        },
        {
          date: '2024-06-19',
          searchVolume: 3627,
          organicRank: 306,
          sponsoredRank: 21,
          keywordSales: 174
        },
        {
          date: '2024-06-18',
          searchVolume: 3627,
          organicRank: 306,

          keywordSales: 174
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_energy tea`,
      keyword: 'energy tea',
      searchVolume: 1659,
      organicStartRank: 232,
      organicHighestRank: 169,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1561,
          organicRank: 259,

          keywordSales: 51
        },
        {
          date: '2024-08-06',
          searchVolume: 1561,
          organicRank: 200,

          keywordSales: 51
        },
        {
          date: '2024-08-05',
          searchVolume: 1561,
          organicRank: 194,

          keywordSales: 51
        },
        {
          date: '2024-08-04',
          searchVolume: 1561,
          organicRank: 169,

          keywordSales: 51
        },
        {
          date: '2024-08-03',
          searchVolume: 1561,
          organicRank: 179,

          keywordSales: 51
        },
        {
          date: '2024-08-02',
          searchVolume: 1561,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-08-01',
          searchVolume: 1561,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-31',
          searchVolume: 1561,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-30',
          searchVolume: 1561,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-28',
          searchVolume: 1561,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-27',
          searchVolume: 1561,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-26',
          searchVolume: 1367,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-25',
          searchVolume: 1367,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-24',
          searchVolume: 1367,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-23',
          searchVolume: 1367,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-22',
          searchVolume: 1367,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-21',
          searchVolume: 1367,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-20',
          searchVolume: 1367,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-19',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-18',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-17',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-16',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-15',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-14',
          searchVolume: 1733,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-13',
          searchVolume: 1733,
          organicRank: 176,

          keywordSales: 51
        },
        {
          date: '2024-07-12',
          searchVolume: 1588,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-11',
          searchVolume: 1588,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-10',
          searchVolume: 1588,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-09',
          searchVolume: 1588,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-08',
          searchVolume: 1588,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-07',
          searchVolume: 1588,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-06',
          searchVolume: 1588,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-05',
          searchVolume: 1690,
          organicRank: 204,

          keywordSales: 51
        },
        {
          date: '2024-07-04',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-03',
          searchVolume: 1690,
          organicRank: 204,

          keywordSales: 51
        },
        {
          date: '2024-07-02',
          searchVolume: 1690,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-07-01',
          searchVolume: 1690,
          organicRank: 198,

          keywordSales: 51
        },
        {
          date: '2024-06-30',
          searchVolume: 1690,
          organicRank: 218,

          keywordSales: 51
        },
        {
          date: '2024-06-29',
          searchVolume: 1690,
          organicRank: 189,

          keywordSales: 51
        },
        {
          date: '2024-06-28',
          searchVolume: 1659,
          organicRank: 203,

          keywordSales: 51
        },
        {
          date: '2024-06-27',
          searchVolume: 1659,
          organicRank: 195,

          keywordSales: 51
        },
        {
          date: '2024-06-26',
          searchVolume: 1659,
          organicRank: 202,

          keywordSales: 51
        },
        {
          date: '2024-06-25',
          searchVolume: 1659,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-06-21',
          searchVolume: 1659,
          organicRank: 306,

          keywordSales: 51
        },
        {
          date: '2024-06-20',
          searchVolume: 1659,
          organicRank: 279,

          keywordSales: 51
        },
        {
          date: '2024-06-19',
          searchVolume: 1659,
          organicRank: 232,

          keywordSales: 51
        },
        {
          date: '2024-06-18',
          searchVolume: 1659,
          organicRank: 255,

          keywordSales: 51
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_georgie energy drink`,
      keyword: 'georgie energy drink',
      searchVolume: 2550,
      organicStartRank: 22,
      organicHighestRank: 8,
      sponsoredStartRank: 12,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-06-20',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2241,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-08-06',
          searchVolume: 2241,
          organicRank: 24,

          keywordSales: 90
        },
        {
          date: '2024-08-05',
          searchVolume: 2241,
          organicRank: 10,

          keywordSales: 90
        },
        {
          date: '2024-08-04',
          searchVolume: 2241,
          organicRank: 11,

          keywordSales: 90
        },
        {
          date: '2024-08-03',
          searchVolume: 2241,
          organicRank: 10,

          keywordSales: 90
        },
        {
          date: '2024-08-01',
          searchVolume: 2241,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-31',
          searchVolume: 2241,
          organicRank: 24,

          keywordSales: 90
        },
        {
          date: '2024-07-30',
          searchVolume: 2241,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-29',
          searchVolume: 2241,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-28',
          searchVolume: 2241,
          organicRank: 10,

          keywordSales: 90
        },
        {
          date: '2024-07-27',
          searchVolume: 2241,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-26',
          searchVolume: 4044,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-25',
          searchVolume: 4044,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-24',
          searchVolume: 4044,
          organicRank: 13,

          keywordSales: 90
        },
        {
          date: '2024-07-23',
          searchVolume: 4044,
          organicRank: 13,

          keywordSales: 90
        },
        {
          date: '2024-07-22',
          searchVolume: 4044,
          organicRank: 13,

          keywordSales: 90
        },
        {
          date: '2024-07-21',
          searchVolume: 4044,
          organicRank: 14,

          keywordSales: 90
        },
        {
          date: '2024-07-20',
          searchVolume: 4044,
          organicRank: 13,

          keywordSales: 90
        },
        {
          date: '2024-07-19',
          searchVolume: 2280,
          organicRank: 16,

          keywordSales: 90
        },
        {
          date: '2024-07-18',
          searchVolume: 2280,
          organicRank: 16,

          keywordSales: 90
        },
        {
          date: '2024-07-17',
          searchVolume: 2280,
          organicRank: 18,

          keywordSales: 90
        },
        {
          date: '2024-07-16',
          searchVolume: 2280,
          organicRank: 15,
          sponsoredRank: 4,
          keywordSales: 90
        },
        {
          date: '2024-07-15',
          searchVolume: 2280,
          organicRank: 16,
          sponsoredRank: 3,
          keywordSales: 90
        },
        {
          date: '2024-07-14',
          searchVolume: 2280,
          organicRank: 12,

          keywordSales: 90
        },
        {
          date: '2024-07-13',
          searchVolume: 2280,
          organicRank: 25,

          keywordSales: 90
        },
        {
          date: '2024-07-12',
          searchVolume: 2437,
          organicRank: 24,

          keywordSales: 90
        },
        {
          date: '2024-07-11',
          searchVolume: 2437,
          organicRank: 23,

          keywordSales: 90
        },
        {
          date: '2024-07-10',
          searchVolume: 2437,
          organicRank: 26,

          keywordSales: 90
        },
        {
          date: '2024-07-09',
          searchVolume: 2437,
          organicRank: 22,

          keywordSales: 90
        },
        {
          date: '2024-07-08',
          searchVolume: 2437,
          organicRank: 22,

          keywordSales: 90
        },
        {
          date: '2024-07-07',
          searchVolume: 2437,
          organicRank: 22,

          keywordSales: 90
        },
        {
          date: '2024-07-06',
          searchVolume: 2437,
          organicRank: 22,

          keywordSales: 90
        },
        {
          date: '2024-07-05',
          searchVolume: 2552,
          organicRank: 21,

          keywordSales: 90
        },
        {
          date: '2024-07-04',
          searchVolume: 2552,
          organicRank: 17,
          sponsoredRank: 4,
          keywordSales: 90
        },
        {
          date: '2024-07-03',
          searchVolume: 2552,
          organicRank: 20,

          keywordSales: 90
        },
        {
          date: '2024-07-02',
          searchVolume: 2552,
          organicRank: 20,

          keywordSales: 90
        },
        {
          date: '2024-07-01',
          searchVolume: 2552,
          organicRank: 18,
          sponsoredRank: 12,
          keywordSales: 90
        },
        {
          date: '2024-06-30',
          searchVolume: 2552,
          organicRank: 19,

          keywordSales: 90
        },
        {
          date: '2024-06-29',
          searchVolume: 2552,
          organicRank: 16,

          keywordSales: 90
        },
        {
          date: '2024-06-28',
          searchVolume: 3805,
          organicRank: 23,

          keywordSales: 90
        },
        {
          date: '2024-06-27',
          searchVolume: 3805,
          organicRank: 21,

          keywordSales: 90
        },
        {
          date: '2024-06-26',
          searchVolume: 3805,
          organicRank: 37,

          keywordSales: 90
        },
        {
          date: '2024-06-25',
          searchVolume: 3805,
          organicRank: 27,

          keywordSales: 90
        },
        {
          date: '2024-06-24',
          searchVolume: 3805,
          organicRank: 20,

          keywordSales: 90
        },
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 17,

          keywordSales: 90
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 19,

          keywordSales: 90
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 22,

          keywordSales: 90
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 21,

          keywordSales: 90
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_ghost energy drink`,
      keyword: 'ghost energy drink',
      searchVolume: 53646,
      organicStartRank: 14,
      organicHighestRank: 7,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 2,
      organicPageOneWinDate: '2024-06-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 46045,
          organicRank: 22,

          keywordSales: 1556
        },
        {
          date: '2024-08-05',
          searchVolume: 46045,
          organicRank: 21,

          keywordSales: 1556
        },
        {
          date: '2024-08-04',
          searchVolume: 46045,
          organicRank: 21,

          keywordSales: 1556
        },
        {
          date: '2024-08-03',
          searchVolume: 46045,
          organicRank: 23,

          keywordSales: 1556
        },
        {
          date: '2024-08-02',
          searchVolume: 46045,
          organicRank: 14,

          keywordSales: 1556
        },
        {
          date: '2024-08-01',
          searchVolume: 46045,
          organicRank: 24,

          keywordSales: 1556
        },
        {
          date: '2024-07-31',
          searchVolume: 46045,
          organicRank: 11,

          keywordSales: 1556
        },
        {
          date: '2024-07-30',
          searchVolume: 46045,
          organicRank: 14,
          sponsoredRank: 22,
          keywordSales: 1556
        },
        {
          date: '2024-07-29',
          searchVolume: 46045,
          organicRank: 26,

          keywordSales: 1556
        },
        {
          date: '2024-07-28',
          searchVolume: 46045,
          organicRank: 17,

          keywordSales: 1556
        },
        {
          date: '2024-07-27',
          searchVolume: 46045,
          organicRank: 24,

          keywordSales: 1556
        },
        {
          date: '2024-07-26',
          searchVolume: 60974,
          organicRank: 19,
          sponsoredRank: 11,
          keywordSales: 1556
        },
        {
          date: '2024-07-25',
          searchVolume: 60974,
          organicRank: 35,

          keywordSales: 1556
        },
        {
          date: '2024-07-24',
          searchVolume: 60974,
          organicRank: 34,

          keywordSales: 1556
        },
        {
          date: '2024-07-23',
          searchVolume: 60974,
          organicRank: 29,

          keywordSales: 1556
        },
        {
          date: '2024-07-22',
          searchVolume: 60974,
          organicRank: 31,
          sponsoredRank: 10,
          keywordSales: 1556
        },
        {
          date: '2024-07-21',
          searchVolume: 60974,
          organicRank: 28,
          sponsoredRank: 16,
          keywordSales: 1556
        },
        {
          date: '2024-07-20',
          searchVolume: 60974,
          organicRank: 28,

          keywordSales: 1556
        },
        {
          date: '2024-07-19',
          searchVolume: 49484,
          organicRank: 26,

          keywordSales: 1556
        },
        {
          date: '2024-07-18',
          searchVolume: 49484,
          organicRank: 27,

          keywordSales: 1556
        },
        {
          date: '2024-07-17',
          searchVolume: 49484,
          organicRank: 33,
          sponsoredRank: 30,
          keywordSales: 1556
        },
        {
          date: '2024-07-16',
          searchVolume: 49484,
          organicRank: 36,

          keywordSales: 1556
        },
        {
          date: '2024-07-15',
          searchVolume: 49484,
          organicRank: 33,

          keywordSales: 1556
        },
        {
          date: '2024-07-14',
          searchVolume: 49484,
          organicRank: 18,

          keywordSales: 1556
        },
        {
          date: '2024-07-13',
          searchVolume: 49484,
          organicRank: 33,

          keywordSales: 1556
        },
        {
          date: '2024-07-12',
          searchVolume: 47675,
          organicRank: 17,

          keywordSales: 1556
        },
        {
          date: '2024-07-11',
          searchVolume: 47675,
          organicRank: 27,

          keywordSales: 1556
        },
        {
          date: '2024-07-10',
          searchVolume: 47675,
          organicRank: 21,

          keywordSales: 1556
        },
        {
          date: '2024-07-09',
          searchVolume: 47675,
          organicRank: 33,

          keywordSales: 1556
        },
        {
          date: '2024-07-08',
          searchVolume: 47675,
          organicRank: 27,

          keywordSales: 1556
        },
        {
          date: '2024-07-07',
          searchVolume: 47675,
          organicRank: 30,

          keywordSales: 1556
        },
        {
          date: '2024-07-06',
          searchVolume: 47675,
          organicRank: 24,
          sponsoredRank: 14,
          keywordSales: 1556
        },
        {
          date: '2024-07-05',
          searchVolume: 52771,
          organicRank: 29,
          sponsoredRank: 2,
          keywordSales: 1556
        },
        {
          date: '2024-07-04',
          searchVolume: 52771,
          organicRank: 9,
          sponsoredRank: 57,
          keywordSales: 1556
        },
        {
          date: '2024-07-03',
          searchVolume: 52771,
          organicRank: 29,

          keywordSales: 1556
        },
        {
          date: '2024-07-02',
          searchVolume: 52771,
          organicRank: 30,

          keywordSales: 1556
        },
        {
          date: '2024-07-01',
          searchVolume: 52771,
          organicRank: 13,

          keywordSales: 1556
        },
        {
          date: '2024-06-30',
          searchVolume: 52771,
          organicRank: 28,

          keywordSales: 1556
        },
        {
          date: '2024-06-28',
          searchVolume: 52557,
          organicRank: 13,
          sponsoredRank: 29,
          keywordSales: 1556
        },
        {
          date: '2024-06-27',
          searchVolume: 52557,
          organicRank: 34,
          sponsoredRank: 31,
          keywordSales: 1556
        },
        {
          date: '2024-06-26',
          searchVolume: 52557,
          organicRank: 10,
          sponsoredRank: 34,
          keywordSales: 1556
        },
        {
          date: '2024-06-25',
          searchVolume: 52557,
          organicRank: 36,
          sponsoredRank: 62,
          keywordSales: 1556
        },
        {
          date: '2024-06-24',
          searchVolume: 52557,
          organicRank: 9,

          keywordSales: 1556
        },
        {
          date: '2024-06-23',
          searchVolume: 52557,
          organicRank: 10,

          keywordSales: 1556
        },
        {
          date: '2024-06-22',
          searchVolume: 52557,
          organicRank: 10,
          sponsoredRank: 6,
          keywordSales: 1556
        },
        {
          date: '2024-06-21',
          searchVolume: 53646,
          organicRank: 7,
          sponsoredRank: 10,
          keywordSales: 1556
        },
        {
          date: '2024-06-20',
          searchVolume: 53646,
          organicRank: 16,
          sponsoredRank: 23,
          keywordSales: 1556
        },
        {
          date: '2024-06-19',
          searchVolume: 53646,
          organicRank: 14,

          keywordSales: 1556
        },
        {
          date: '2024-06-18',
          searchVolume: 53646,
          organicRank: 35,

          keywordSales: 1556
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_good energy`,
      keyword: 'good energy',
      searchVolume: 6841,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 42,
      sponsoredHighestRank: 42,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-08-06',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-08-05',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-08-04',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-08-03',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-08-01',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-31',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-30',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-29',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-28',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-27',
          searchVolume: 7082,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-26',
          searchVolume: 4342,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-25',
          searchVolume: 4342,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-24',
          searchVolume: 4342,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-23',
          searchVolume: 4342,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-22',
          searchVolume: 4342,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-21',
          searchVolume: 4342,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-20',
          searchVolume: 4342,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-19',
          searchVolume: 6841,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-18',
          searchVolume: 6841,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-17',
          searchVolume: 6841,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-16',
          searchVolume: 6841,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-15',
          searchVolume: 6841,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-14',
          searchVolume: 6841,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-13',
          searchVolume: 6841,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-12',
          searchVolume: 8639,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-11',
          searchVolume: 8639,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-10',
          searchVolume: 8639,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-09',
          searchVolume: 8639,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-08',
          searchVolume: 8639,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-07',
          searchVolume: 8639,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-06',
          searchVolume: 8639,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-05',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-04',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-03',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-02',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-07-01',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-30',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-29',
          searchVolume: 9042,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-28',
          searchVolume: 10700,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-27',
          searchVolume: 10700,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-26',
          searchVolume: 10700,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-25',
          searchVolume: 10700,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-24',
          searchVolume: 10700,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-21',
          searchVolume: 11765,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-20',
          searchVolume: 11765,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-19',
          searchVolume: 11765,
          organicRank: 306,

          keywordSales: 223
        },
        {
          date: '2024-06-18',
          searchVolume: 11765,
          organicRank: 306,

          keywordSales: 223
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_guru energy drink`,
      keyword: 'guru energy drink',
      searchVolume: 2352,
      organicStartRank: 18,
      organicHighestRank: 8,
      sponsoredStartRank: 23,
      sponsoredHighestRank: 23,
      organicPageOneWinDate: '2024-07-08',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2078,
          organicRank: 35,

          keywordSales: 50
        },
        {
          date: '2024-08-06',
          searchVolume: 2078,
          organicRank: 38,

          keywordSales: 50
        },
        {
          date: '2024-08-05',
          searchVolume: 2078,
          organicRank: 39,

          keywordSales: 50
        },
        {
          date: '2024-08-04',
          searchVolume: 2078,
          organicRank: 37,

          keywordSales: 50
        },
        {
          date: '2024-08-02',
          searchVolume: 2078,
          organicRank: 40,

          keywordSales: 50
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 41,

          keywordSales: 50
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 23,

          keywordSales: 50
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 33,

          keywordSales: 50
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 23,

          keywordSales: 50
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 31,

          keywordSales: 50
        },
        {
          date: '2024-07-26',
          searchVolume: 2319,
          organicRank: 27,

          keywordSales: 50
        },
        {
          date: '2024-07-25',
          searchVolume: 2319,
          organicRank: 28,

          keywordSales: 50
        },
        {
          date: '2024-07-24',
          searchVolume: 2319,
          organicRank: 26,

          keywordSales: 50
        },
        {
          date: '2024-07-23',
          searchVolume: 2319,
          organicRank: 24,

          keywordSales: 50
        },
        {
          date: '2024-07-22',
          searchVolume: 2319,
          organicRank: 23,

          keywordSales: 50
        },
        {
          date: '2024-07-21',
          searchVolume: 2319,
          organicRank: 22,

          keywordSales: 50
        },
        {
          date: '2024-07-20',
          searchVolume: 2319,
          organicRank: 26,

          keywordSales: 50
        },
        {
          date: '2024-07-19',
          searchVolume: 1811,
          organicRank: 24,

          keywordSales: 50
        },
        {
          date: '2024-07-18',
          searchVolume: 1811,
          organicRank: 15,
          sponsoredRank: 23,
          keywordSales: 50
        },
        {
          date: '2024-07-17',
          searchVolume: 1811,
          organicRank: 14,

          keywordSales: 50
        },
        {
          date: '2024-07-16',
          searchVolume: 1811,
          organicRank: 20,

          keywordSales: 50
        },
        {
          date: '2024-07-15',
          searchVolume: 1811,
          organicRank: 22,

          keywordSales: 50
        },
        {
          date: '2024-07-14',
          searchVolume: 1811,
          organicRank: 21,

          keywordSales: 50
        },
        {
          date: '2024-07-13',
          searchVolume: 1811,
          organicRank: 21,

          keywordSales: 50
        },
        {
          date: '2024-07-12',
          searchVolume: 1840,
          organicRank: 21,

          keywordSales: 50
        },
        {
          date: '2024-07-11',
          searchVolume: 1840,
          organicRank: 19,

          keywordSales: 50
        },
        {
          date: '2024-07-10',
          searchVolume: 1840,
          organicRank: 18,

          keywordSales: 50
        },
        {
          date: '2024-07-09',
          searchVolume: 1840,
          organicRank: 16,

          keywordSales: 50
        },
        {
          date: '2024-07-08',
          searchVolume: 1840,
          organicRank: 19,

          keywordSales: 50
        },
        {
          date: '2024-07-07',
          searchVolume: 1840,
          organicRank: 22,

          keywordSales: 50
        },
        {
          date: '2024-07-06',
          searchVolume: 1840,
          organicRank: 20,

          keywordSales: 50
        },
        {
          date: '2024-07-05',
          searchVolume: 1930,
          organicRank: 15,

          keywordSales: 50
        },
        {
          date: '2024-07-04',
          searchVolume: 1930,
          organicRank: 9,

          keywordSales: 50
        },
        {
          date: '2024-07-03',
          searchVolume: 1930,
          organicRank: 11,

          keywordSales: 50
        },
        {
          date: '2024-07-02',
          searchVolume: 1930,
          organicRank: 18,

          keywordSales: 50
        },
        {
          date: '2024-07-01',
          searchVolume: 1930,
          organicRank: 8,

          keywordSales: 50
        },
        {
          date: '2024-06-30',
          searchVolume: 1930,
          organicRank: 19,

          keywordSales: 50
        },
        {
          date: '2024-06-29',
          searchVolume: 1930,
          organicRank: 11,

          keywordSales: 50
        },
        {
          date: '2024-06-28',
          searchVolume: 2383,
          organicRank: 11,

          keywordSales: 50
        },
        {
          date: '2024-06-27',
          searchVolume: 2383,
          organicRank: 14,

          keywordSales: 50
        },
        {
          date: '2024-06-26',
          searchVolume: 2383,
          organicRank: 13,

          keywordSales: 50
        },
        {
          date: '2024-06-25',
          searchVolume: 2383,
          organicRank: 11,

          keywordSales: 50
        },
        {
          date: '2024-06-24',
          searchVolume: 2383,
          organicRank: 16,

          keywordSales: 50
        },
        {
          date: '2024-06-21',
          searchVolume: 2352,
          organicRank: 14,

          keywordSales: 50
        },
        {
          date: '2024-06-20',
          searchVolume: 2352,
          organicRank: 11,

          keywordSales: 50
        },
        {
          date: '2024-06-19',
          searchVolume: 2352,
          organicRank: 18,

          keywordSales: 50
        },
        {
          date: '2024-06-18',
          searchVolume: 2352,
          organicRank: 13,

          keywordSales: 50
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_healthy drinks`,
      keyword: 'healthy drinks',
      searchVolume: 3128,
      organicStartRank: 60,
      organicHighestRank: 59,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2886,
          organicRank: 129,

          keywordSales: 86
        },
        {
          date: '2024-08-05',
          searchVolume: 2886,
          organicRank: 114,

          keywordSales: 86
        },
        {
          date: '2024-08-04',
          searchVolume: 2886,
          organicRank: 114,

          keywordSales: 86
        },
        {
          date: '2024-08-03',
          searchVolume: 2886,
          organicRank: 111,

          keywordSales: 86
        },
        {
          date: '2024-08-02',
          searchVolume: 2886,
          organicRank: 126,

          keywordSales: 86
        },
        {
          date: '2024-08-01',
          searchVolume: 2886,
          organicRank: 114,

          keywordSales: 86
        },
        {
          date: '2024-07-31',
          searchVolume: 2886,
          organicRank: 115,

          keywordSales: 86
        },
        {
          date: '2024-07-30',
          searchVolume: 2886,
          organicRank: 111,

          keywordSales: 86
        },
        {
          date: '2024-07-29',
          searchVolume: 2886,
          organicRank: 127,

          keywordSales: 86
        },
        {
          date: '2024-07-28',
          searchVolume: 2886,
          organicRank: 100,

          keywordSales: 86
        },
        {
          date: '2024-07-27',
          searchVolume: 2886,
          organicRank: 115,

          keywordSales: 86
        },
        {
          date: '2024-07-26',
          searchVolume: 2435,
          organicRank: 125,

          keywordSales: 86
        },
        {
          date: '2024-07-25',
          searchVolume: 2435,
          organicRank: 119,

          keywordSales: 86
        },
        {
          date: '2024-07-24',
          searchVolume: 2435,
          organicRank: 121,

          keywordSales: 86
        },
        {
          date: '2024-07-23',
          searchVolume: 2435,
          organicRank: 113,

          keywordSales: 86
        },
        {
          date: '2024-07-22',
          searchVolume: 2435,
          organicRank: 136,

          keywordSales: 86
        },
        {
          date: '2024-07-21',
          searchVolume: 2435,
          organicRank: 125,

          keywordSales: 86
        },
        {
          date: '2024-07-20',
          searchVolume: 2435,
          organicRank: 113,

          keywordSales: 86
        },
        {
          date: '2024-07-19',
          searchVolume: 2937,
          organicRank: 88,

          keywordSales: 86
        },
        {
          date: '2024-07-18',
          searchVolume: 2937,
          organicRank: 74,

          keywordSales: 86
        },
        {
          date: '2024-07-17',
          searchVolume: 2937,
          organicRank: 73,

          keywordSales: 86
        },
        {
          date: '2024-07-16',
          searchVolume: 2937,
          organicRank: 109,

          keywordSales: 86
        },
        {
          date: '2024-07-15',
          searchVolume: 2937,
          organicRank: 123,

          keywordSales: 86
        },
        {
          date: '2024-07-14',
          searchVolume: 2937,
          organicRank: 111,

          keywordSales: 86
        },
        {
          date: '2024-07-13',
          searchVolume: 2937,
          organicRank: 112,

          keywordSales: 86
        },
        {
          date: '2024-07-12',
          searchVolume: 2989,
          organicRank: 118,

          keywordSales: 86
        },
        {
          date: '2024-07-11',
          searchVolume: 2989,
          organicRank: 107,

          keywordSales: 86
        },
        {
          date: '2024-07-10',
          searchVolume: 2989,
          organicRank: 107,

          keywordSales: 86
        },
        {
          date: '2024-07-09',
          searchVolume: 2989,
          organicRank: 98,

          keywordSales: 86
        },
        {
          date: '2024-07-08',
          searchVolume: 2989,
          organicRank: 80,

          keywordSales: 86
        },
        {
          date: '2024-07-07',
          searchVolume: 2989,
          organicRank: 91,

          keywordSales: 86
        },
        {
          date: '2024-07-06',
          searchVolume: 2989,
          organicRank: 76,

          keywordSales: 86
        },
        {
          date: '2024-07-05',
          searchVolume: 3134,
          organicRank: 96,

          keywordSales: 86
        },
        {
          date: '2024-07-04',
          searchVolume: 3134,
          organicRank: 66,

          keywordSales: 86
        },
        {
          date: '2024-07-03',
          searchVolume: 3134,
          organicRank: 59,

          keywordSales: 86
        },
        {
          date: '2024-07-02',
          searchVolume: 3134,
          organicRank: 83,

          keywordSales: 86
        },
        {
          date: '2024-07-01',
          searchVolume: 3134,
          organicRank: 78,

          keywordSales: 86
        },
        {
          date: '2024-06-30',
          searchVolume: 3134,
          organicRank: 97,

          keywordSales: 86
        },
        {
          date: '2024-06-29',
          searchVolume: 3134,
          organicRank: 88,

          keywordSales: 86
        },
        {
          date: '2024-06-28',
          searchVolume: 3137,
          organicRank: 78,

          keywordSales: 86
        },
        {
          date: '2024-06-27',
          searchVolume: 3137,
          organicRank: 75,

          keywordSales: 86
        },
        {
          date: '2024-06-26',
          searchVolume: 3137,
          organicRank: 78,

          keywordSales: 86
        },
        {
          date: '2024-06-25',
          searchVolume: 3137,
          organicRank: 84,

          keywordSales: 86
        },
        {
          date: '2024-06-24',
          searchVolume: 3137,
          organicRank: 83,

          keywordSales: 86
        },
        {
          date: '2024-06-21',
          searchVolume: 3128,
          organicRank: 82,

          keywordSales: 86
        },
        {
          date: '2024-06-20',
          searchVolume: 3128,
          organicRank: 74,

          keywordSales: 86
        },
        {
          date: '2024-06-19',
          searchVolume: 3128,
          organicRank: 60,

          keywordSales: 86
        },
        {
          date: '2024-06-18',
          searchVolume: 3128,
          organicRank: 55,

          keywordSales: 86
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_healthy energy drinks`,
      keyword: 'healthy energy drinks',
      searchVolume: 3133,
      organicStartRank: 10,
      organicHighestRank: 5,
      sponsoredStartRank: 37,
      sponsoredHighestRank: 37,
      organicPageOneWinDate: '2024-07-25',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2886,
          organicRank: 6,

          keywordSales: 90
        },
        {
          date: '2024-08-06',
          searchVolume: 2886,
          organicRank: 6,

          keywordSales: 90
        },
        {
          date: '2024-08-05',
          searchVolume: 2886,
          organicRank: 5,

          keywordSales: 90
        },
        {
          date: '2024-08-03',
          searchVolume: 2886,
          organicRank: 5,

          keywordSales: 90
        },
        {
          date: '2024-08-02',
          searchVolume: 2886,
          organicRank: 6,

          keywordSales: 90
        },
        {
          date: '2024-08-01',
          searchVolume: 2886,
          organicRank: 6,

          keywordSales: 90
        },
        {
          date: '2024-07-31',
          searchVolume: 2886,
          organicRank: 6,

          keywordSales: 90
        },
        {
          date: '2024-07-30',
          searchVolume: 2886,
          organicRank: 7,

          keywordSales: 90
        },
        {
          date: '2024-07-29',
          searchVolume: 2886,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-28',
          searchVolume: 2886,
          organicRank: 7,

          keywordSales: 90
        },
        {
          date: '2024-07-27',
          searchVolume: 2886,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-26',
          searchVolume: 2435,
          organicRank: 7,

          keywordSales: 90
        },
        {
          date: '2024-07-25',
          searchVolume: 2435,
          organicRank: 7,

          keywordSales: 90
        },
        {
          date: '2024-07-24',
          searchVolume: 2435,
          organicRank: 28,

          keywordSales: 90
        },
        {
          date: '2024-07-23',
          searchVolume: 2435,
          organicRank: 24,

          keywordSales: 90
        },
        {
          date: '2024-07-22',
          searchVolume: 2435,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-21',
          searchVolume: 2435,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-20',
          searchVolume: 2435,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-19',
          searchVolume: 2395,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-18',
          searchVolume: 2395,
          organicRank: 8,
          sponsoredRank: 72,
          keywordSales: 90
        },
        {
          date: '2024-07-17',
          searchVolume: 2395,
          organicRank: 7,

          keywordSales: 90
        },
        {
          date: '2024-07-16',
          searchVolume: 2395,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-15',
          searchVolume: 2395,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-14',
          searchVolume: 2395,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-13',
          searchVolume: 2395,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-12',
          searchVolume: 2437,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-10',
          searchVolume: 2437,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-09',
          searchVolume: 2437,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-08',
          searchVolume: 2437,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-07',
          searchVolume: 2437,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-06',
          searchVolume: 2437,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-05',
          searchVolume: 3128,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-07-04',
          searchVolume: 3128,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-03',
          searchVolume: 3128,
          organicRank: 11,

          keywordSales: 90
        },
        {
          date: '2024-07-02',
          searchVolume: 3128,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-07-01',
          searchVolume: 3128,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-06-30',
          searchVolume: 3128,
          organicRank: 6,

          keywordSales: 90
        },
        {
          date: '2024-06-29',
          searchVolume: 3128,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-06-28',
          searchVolume: 2807,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-06-27',
          searchVolume: 2807,
          organicRank: 10,

          keywordSales: 90
        },
        {
          date: '2024-06-26',
          searchVolume: 2807,
          organicRank: 10,

          keywordSales: 90
        },
        {
          date: '2024-06-25',
          searchVolume: 2807,
          organicRank: 9,

          keywordSales: 90
        },
        {
          date: '2024-06-21',
          searchVolume: 3133,
          organicRank: 8,

          keywordSales: 90
        },
        {
          date: '2024-06-20',
          searchVolume: 3133,
          organicRank: 10,
          sponsoredRank: 37,
          keywordSales: 90
        },
        {
          date: '2024-06-19',
          searchVolume: 3133,
          organicRank: 10,

          keywordSales: 90
        },
        {
          date: '2024-06-18',
          searchVolume: 3133,
          organicRank: 18,

          keywordSales: 90
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_ice drinks`,
      keyword: 'ice drinks',
      searchVolume: 10766,
      organicStartRank: 94,
      organicHighestRank: 60,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-08-06',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-08-05',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-08-04',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-08-03',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-08-01',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-31',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-30',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-29',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-28',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-27',
          searchVolume: 9947,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-26',
          searchVolume: 11645,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-25',
          searchVolume: 11645,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-24',
          searchVolume: 11645,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-23',
          searchVolume: 11645,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-22',
          searchVolume: 11645,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-21',
          searchVolume: 11645,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-20',
          searchVolume: 11645,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-19',
          searchVolume: 10125,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-18',
          searchVolume: 10125,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-17',
          searchVolume: 10125,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-16',
          searchVolume: 10125,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-15',
          searchVolume: 10125,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-14',
          searchVolume: 10125,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-13',
          searchVolume: 10125,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-12',
          searchVolume: 10562,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-11',
          searchVolume: 10562,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-10',
          searchVolume: 10562,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-09',
          searchVolume: 10562,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-08',
          searchVolume: 10562,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-07',
          searchVolume: 10562,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-06',
          searchVolume: 10562,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-05',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-04',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-03',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-02',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-07-01',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-06-30',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-06-29',
          searchVolume: 10789,
          organicRank: 306,

          keywordSales: 355
        },
        {
          date: '2024-06-28',
          searchVolume: 10742,
          organicRank: 83,

          keywordSales: 355
        },
        {
          date: '2024-06-27',
          searchVolume: 10742,
          organicRank: 60,

          keywordSales: 355
        },
        {
          date: '2024-06-26',
          searchVolume: 10742,
          organicRank: 98,

          keywordSales: 355
        },
        {
          date: '2024-06-25',
          searchVolume: 10742,
          organicRank: 94,

          keywordSales: 355
        },
        {
          date: '2024-06-24',
          searchVolume: 10742,
          organicRank: 93,

          keywordSales: 355
        },
        {
          date: '2024-06-21',
          searchVolume: 10766,
          organicRank: 95,

          keywordSales: 355
        },
        {
          date: '2024-06-20',
          searchVolume: 10766,
          organicRank: 101,

          keywordSales: 355
        },
        {
          date: '2024-06-19',
          searchVolume: 10766,
          organicRank: 94,

          keywordSales: 355
        },
        {
          date: '2024-06-18',
          searchVolume: 10766,
          organicRank: 83,

          keywordSales: 355
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_lime powder`,
      keyword: 'lime powder',
      searchVolume: 2547,
      organicStartRank: 306,
      organicHighestRank: 190,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-08-06',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-08-05',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-08-04',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-08-03',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-08-01',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-31',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-30',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-29',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-28',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-27',
          searchVolume: 2018,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-26',
          searchVolume: 1834,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-25',
          searchVolume: 1834,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-24',
          searchVolume: 1834,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-23',
          searchVolume: 1834,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-22',
          searchVolume: 1834,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-21',
          searchVolume: 1834,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-20',
          searchVolume: 1834,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-19',
          searchVolume: 2280,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-18',
          searchVolume: 2280,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-17',
          searchVolume: 2280,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-16',
          searchVolume: 2280,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-15',
          searchVolume: 2280,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-14',
          searchVolume: 2280,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-13',
          searchVolume: 2280,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-12',
          searchVolume: 2091,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-11',
          searchVolume: 2091,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-10',
          searchVolume: 2091,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-09',
          searchVolume: 2091,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-08',
          searchVolume: 2091,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-07',
          searchVolume: 2091,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-06',
          searchVolume: 2091,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-05',
          searchVolume: 2253,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-04',
          searchVolume: 2253,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-03',
          searchVolume: 2253,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-02',
          searchVolume: 2253,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-07-01',
          searchVolume: 2253,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-30',
          searchVolume: 2253,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-29',
          searchVolume: 2253,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-28',
          searchVolume: 2420,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-27',
          searchVolume: 2420,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-26',
          searchVolume: 2420,
          organicRank: 277,

          keywordSales: 74
        },
        {
          date: '2024-06-25',
          searchVolume: 2420,
          organicRank: 190,

          keywordSales: 74
        },
        {
          date: '2024-06-23',
          searchVolume: 2420,
          organicRank: 278,

          keywordSales: 74
        },
        {
          date: '2024-06-21',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-20',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-19',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 74
        },
        {
          date: '2024-06-18',
          searchVolume: 2547,
          organicRank: 306,

          keywordSales: 74
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_lost and found energy drink`,
      keyword: 'lost and found energy drink',
      searchVolume: 884,
      organicStartRank: 15,
      organicHighestRank: 15,
      sponsoredStartRank: 12,
      sponsoredHighestRank: 3,
      organicPageOneWinDate: '2024-06-19',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2901,
          organicRank: 73,

          keywordSales: 22
        },
        {
          date: '2024-08-06',
          searchVolume: 2901,
          organicRank: 81,

          keywordSales: 22
        },
        {
          date: '2024-08-05',
          searchVolume: 2901,
          organicRank: 75,

          keywordSales: 22
        },
        {
          date: '2024-08-04',
          searchVolume: 2901,
          organicRank: 76,

          keywordSales: 22
        },
        {
          date: '2024-08-03',
          searchVolume: 2901,
          organicRank: 73,

          keywordSales: 22
        },
        {
          date: '2024-08-01',
          searchVolume: 2901,
          organicRank: 69,

          keywordSales: 22
        },
        {
          date: '2024-07-31',
          searchVolume: 2901,
          organicRank: 72,

          keywordSales: 22
        },
        {
          date: '2024-07-30',
          searchVolume: 2901,
          organicRank: 26,

          keywordSales: 22
        },
        {
          date: '2024-07-29',
          searchVolume: 2901,
          organicRank: 35,

          keywordSales: 22
        },
        {
          date: '2024-07-28',
          searchVolume: 2901,
          organicRank: 26,

          keywordSales: 22
        },
        {
          date: '2024-07-27',
          searchVolume: 2901,
          organicRank: 23,

          keywordSales: 22
        },
        {
          date: '2024-07-26',
          searchVolume: 1536,
          organicRank: 25,

          keywordSales: 22
        },
        {
          date: '2024-07-25',
          searchVolume: 1536,
          organicRank: 31,

          keywordSales: 22
        },
        {
          date: '2024-07-24',
          searchVolume: 1536,
          organicRank: 35,
          sponsoredRank: 14,
          keywordSales: 22
        },
        {
          date: '2024-07-23',
          searchVolume: 1536,
          organicRank: 44,

          keywordSales: 22
        },
        {
          date: '2024-07-22',
          searchVolume: 1536,
          organicRank: 40,

          keywordSales: 22
        },
        {
          date: '2024-07-21',
          searchVolume: 1536,
          organicRank: 37,

          keywordSales: 22
        },
        {
          date: '2024-07-20',
          searchVolume: 1536,
          organicRank: 44,

          keywordSales: 22
        },
        {
          date: '2024-07-19',
          searchVolume: 701,
          organicRank: 53,

          keywordSales: 22
        },
        {
          date: '2024-07-18',
          searchVolume: 701,
          organicRank: 39,

          keywordSales: 22
        },
        {
          date: '2024-07-17',
          searchVolume: 701,
          organicRank: 38,
          sponsoredRank: 3,
          keywordSales: 22
        },
        {
          date: '2024-07-16',
          searchVolume: 701,
          organicRank: 49,

          keywordSales: 22
        },
        {
          date: '2024-07-15',
          searchVolume: 701,
          organicRank: 43,
          sponsoredRank: 3,
          keywordSales: 22
        },
        {
          date: '2024-07-14',
          searchVolume: 701,
          organicRank: 39,

          keywordSales: 22
        },
        {
          date: '2024-07-13',
          searchVolume: 701,
          organicRank: 53,

          keywordSales: 22
        },
        {
          date: '2024-07-12',
          searchVolume: 910,
          organicRank: 50,

          keywordSales: 22
        },
        {
          date: '2024-07-11',
          searchVolume: 910,
          organicRank: 49,

          keywordSales: 22
        },
        {
          date: '2024-07-10',
          searchVolume: 910,
          organicRank: 42,

          keywordSales: 22
        },
        {
          date: '2024-07-09',
          searchVolume: 910,
          organicRank: 35,

          keywordSales: 22
        },
        {
          date: '2024-07-08',
          searchVolume: 910,
          organicRank: 49,

          keywordSales: 22
        },
        {
          date: '2024-07-07',
          searchVolume: 910,
          organicRank: 43,

          keywordSales: 22
        },
        {
          date: '2024-07-06',
          searchVolume: 910,
          organicRank: 34,

          keywordSales: 22
        },
        {
          date: '2024-07-05',
          searchVolume: 1411,
          organicRank: 30,

          keywordSales: 22
        },
        {
          date: '2024-07-04',
          searchVolume: 1411,
          organicRank: 29,

          keywordSales: 22
        },
        {
          date: '2024-07-03',
          searchVolume: 1411,
          organicRank: 27,

          keywordSales: 22
        },
        {
          date: '2024-07-02',
          searchVolume: 1411,
          organicRank: 18,

          keywordSales: 22
        },
        {
          date: '2024-07-01',
          searchVolume: 1411,
          organicRank: 22,
          sponsoredRank: 12,
          keywordSales: 22
        },
        {
          date: '2024-06-30',
          searchVolume: 1411,
          organicRank: 21,

          keywordSales: 22
        },
        {
          date: '2024-06-29',
          searchVolume: 1411,
          organicRank: 35,

          keywordSales: 22
        },
        {
          date: '2024-06-28',
          searchVolume: 745,
          organicRank: 26,

          keywordSales: 22
        },
        {
          date: '2024-06-27',
          searchVolume: 745,
          organicRank: 26,

          keywordSales: 22
        },
        {
          date: '2024-06-26',
          searchVolume: 745,
          organicRank: 29,

          keywordSales: 22
        },
        {
          date: '2024-06-25',
          searchVolume: 745,
          organicRank: 18,

          keywordSales: 22
        },
        {
          date: '2024-06-24',
          searchVolume: 745,
          organicRank: 25,

          keywordSales: 22
        },
        {
          date: '2024-06-21',
          searchVolume: 884,
          organicRank: 26,

          keywordSales: 22
        },
        {
          date: '2024-06-20',
          searchVolume: 884,
          organicRank: 25,

          keywordSales: 22
        },
        {
          date: '2024-06-19',
          searchVolume: 884,
          organicRank: 15,

          keywordSales: 22
        },
        {
          date: '2024-06-18',
          searchVolume: 884,
          organicRank: 23,

          keywordSales: 22
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_lotus energy`,
      keyword: 'lotus energy',
      searchVolume: 3237,
      organicStartRank: 78,
      organicHighestRank: 57,
      sponsoredStartRank: 22,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 4206,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 25
        },
        {
          date: '2024-08-06',
          searchVolume: 4206,
          organicRank: 306,

          keywordSales: 25
        },
        {
          date: '2024-08-05',
          searchVolume: 4206,
          organicRank: 306,
          sponsoredRank: 7,
          keywordSales: 25
        },
        {
          date: '2024-08-04',
          searchVolume: 4206,
          organicRank: 306,

          keywordSales: 25
        },
        {
          date: '2024-08-03',
          searchVolume: 4206,
          organicRank: 85,

          keywordSales: 25
        },
        {
          date: '2024-08-01',
          searchVolume: 4206,
          organicRank: 85,

          keywordSales: 25
        },
        {
          date: '2024-07-31',
          searchVolume: 4206,
          organicRank: 88,

          keywordSales: 25
        },
        {
          date: '2024-07-30',
          searchVolume: 4206,
          organicRank: 89,

          keywordSales: 25
        },
        {
          date: '2024-07-29',
          searchVolume: 4206,
          organicRank: 75,

          keywordSales: 25
        },
        {
          date: '2024-07-28',
          searchVolume: 4206,
          organicRank: 82,

          keywordSales: 25
        },
        {
          date: '2024-07-27',
          searchVolume: 4206,
          organicRank: 77,

          keywordSales: 25
        },
        {
          date: '2024-07-26',
          searchVolume: 4168,
          organicRank: 92,

          keywordSales: 25
        },
        {
          date: '2024-07-25',
          searchVolume: 4168,
          organicRank: 88,

          keywordSales: 25
        },
        {
          date: '2024-07-24',
          searchVolume: 4168,
          organicRank: 83,

          keywordSales: 25
        },
        {
          date: '2024-07-23',
          searchVolume: 4168,
          organicRank: 77,

          keywordSales: 25
        },
        {
          date: '2024-07-22',
          searchVolume: 4168,
          organicRank: 76,

          keywordSales: 25
        },
        {
          date: '2024-07-21',
          searchVolume: 4168,
          organicRank: 81,

          keywordSales: 25
        },
        {
          date: '2024-07-20',
          searchVolume: 4168,
          organicRank: 75,

          keywordSales: 25
        },
        {
          date: '2024-07-19',
          searchVolume: 4274,
          organicRank: 66,

          keywordSales: 25
        },
        {
          date: '2024-07-18',
          searchVolume: 4274,
          organicRank: 57,

          keywordSales: 25
        },
        {
          date: '2024-07-17',
          searchVolume: 4274,
          organicRank: 60,

          keywordSales: 25
        },
        {
          date: '2024-07-16',
          searchVolume: 4274,
          organicRank: 72,

          keywordSales: 25
        },
        {
          date: '2024-07-15',
          searchVolume: 4274,
          organicRank: 81,

          keywordSales: 25
        },
        {
          date: '2024-07-14',
          searchVolume: 4274,
          organicRank: 90,

          keywordSales: 25
        },
        {
          date: '2024-07-13',
          searchVolume: 4274,
          organicRank: 81,

          keywordSales: 25
        },
        {
          date: '2024-07-12',
          searchVolume: 4134,
          organicRank: 83,

          keywordSales: 25
        },
        {
          date: '2024-07-11',
          searchVolume: 4134,
          organicRank: 81,

          keywordSales: 25
        },
        {
          date: '2024-07-10',
          searchVolume: 4134,
          organicRank: 82,

          keywordSales: 25
        },
        {
          date: '2024-07-09',
          searchVolume: 4134,
          organicRank: 86,

          keywordSales: 25
        },
        {
          date: '2024-07-08',
          searchVolume: 4134,
          organicRank: 78,

          keywordSales: 25
        },
        {
          date: '2024-07-07',
          searchVolume: 4134,
          organicRank: 78,

          keywordSales: 25
        },
        {
          date: '2024-07-06',
          searchVolume: 4134,
          organicRank: 88,

          keywordSales: 25
        },
        {
          date: '2024-07-05',
          searchVolume: 4379,
          organicRank: 79,

          keywordSales: 25
        },
        {
          date: '2024-07-04',
          searchVolume: 4379,
          organicRank: 78,

          keywordSales: 25
        },
        {
          date: '2024-07-03',
          searchVolume: 4379,
          organicRank: 82,

          keywordSales: 25
        },
        {
          date: '2024-07-02',
          searchVolume: 4379,
          organicRank: 77,

          keywordSales: 25
        },
        {
          date: '2024-07-01',
          searchVolume: 4379,
          organicRank: 80,

          keywordSales: 25
        },
        {
          date: '2024-06-30',
          searchVolume: 4379,
          organicRank: 101,

          keywordSales: 25
        },
        {
          date: '2024-06-29',
          searchVolume: 4379,
          organicRank: 78,

          keywordSales: 25
        },
        {
          date: '2024-06-28',
          searchVolume: 3613,
          organicRank: 76,

          keywordSales: 25
        },
        {
          date: '2024-06-27',
          searchVolume: 3613,
          organicRank: 72,
          sponsoredRank: 10,
          keywordSales: 25
        },
        {
          date: '2024-06-26',
          searchVolume: 3613,
          organicRank: 61,

          keywordSales: 25
        },
        {
          date: '2024-06-25',
          searchVolume: 3613,
          organicRank: 76,

          keywordSales: 25
        },
        {
          date: '2024-06-24',
          searchVolume: 3613,
          organicRank: 78,

          keywordSales: 25
        },
        {
          date: '2024-06-21',
          searchVolume: 3237,
          organicRank: 69,

          keywordSales: 25
        },
        {
          date: '2024-06-20',
          searchVolume: 3237,
          organicRank: 74,
          sponsoredRank: 22,
          keywordSales: 25
        },
        {
          date: '2024-06-19',
          searchVolume: 3237,
          organicRank: 78,

          keywordSales: 25
        },
        {
          date: '2024-06-18',
          searchVolume: 3237,
          organicRank: 89,

          keywordSales: 25
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_lucky energy drink`,
      keyword: 'lucky energy drink',
      searchVolume: 3475,
      organicStartRank: 16,
      organicHighestRank: 2,
      sponsoredStartRank: 57,
      sponsoredHighestRank: 5,
      organicPageOneWinDate: '2024-06-27',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-29',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 6222,
          organicRank: 3,
          sponsoredRank: 43,
          keywordSales: 176
        },
        {
          date: '2024-08-05',
          searchVolume: 6222,
          organicRank: 4,

          keywordSales: 176
        },
        {
          date: '2024-08-04',
          searchVolume: 6222,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-08-03',
          searchVolume: 6222,
          organicRank: 4,

          keywordSales: 176
        },
        {
          date: '2024-08-02',
          searchVolume: 6222,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-08-01',
          searchVolume: 6222,
          organicRank: 2,
          sponsoredRank: 45,
          keywordSales: 176
        },
        {
          date: '2024-07-31',
          searchVolume: 6222,
          organicRank: 2,

          keywordSales: 176
        },
        {
          date: '2024-07-30',
          searchVolume: 6222,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-07-29',
          searchVolume: 6222,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-07-28',
          searchVolume: 6222,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-07-27',
          searchVolume: 6222,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-07-26',
          searchVolume: 8097,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-07-25',
          searchVolume: 8097,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-07-24',
          searchVolume: 8097,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-07-23',
          searchVolume: 8097,
          organicRank: 3,

          keywordSales: 176
        },
        {
          date: '2024-07-22',
          searchVolume: 8097,
          organicRank: 6,

          keywordSales: 176
        },
        {
          date: '2024-07-21',
          searchVolume: 8097,
          organicRank: 4,
          sponsoredRank: 59,
          keywordSales: 176
        },
        {
          date: '2024-07-20',
          searchVolume: 8097,
          organicRank: 3,
          sponsoredRank: 27,
          keywordSales: 176
        },
        {
          date: '2024-07-19',
          searchVolume: 4826,
          organicRank: 6,
          sponsoredRank: 24,
          keywordSales: 176
        },
        {
          date: '2024-07-18',
          searchVolume: 4826,
          organicRank: 6,

          keywordSales: 176
        },
        {
          date: '2024-07-17',
          searchVolume: 4826,
          organicRank: 6,
          sponsoredRank: 14,
          keywordSales: 176
        },
        {
          date: '2024-07-16',
          searchVolume: 4826,
          organicRank: 6,

          keywordSales: 176
        },
        {
          date: '2024-07-15',
          searchVolume: 4826,
          organicRank: 6,

          keywordSales: 176
        },
        {
          date: '2024-07-14',
          searchVolume: 4826,
          organicRank: 6,

          keywordSales: 176
        },
        {
          date: '2024-07-13',
          searchVolume: 4826,
          organicRank: 6,

          keywordSales: 176
        },
        {
          date: '2024-07-12',
          searchVolume: 3650,
          organicRank: 6,

          keywordSales: 176
        },
        {
          date: '2024-07-11',
          searchVolume: 3650,
          organicRank: 7,

          keywordSales: 176
        },
        {
          date: '2024-07-10',
          searchVolume: 3650,
          organicRank: 6,

          keywordSales: 176
        },
        {
          date: '2024-07-09',
          searchVolume: 3650,
          organicRank: 5,

          keywordSales: 176
        },
        {
          date: '2024-07-08',
          searchVolume: 3650,
          organicRank: 5,

          keywordSales: 176
        },
        {
          date: '2024-07-07',
          searchVolume: 3650,
          organicRank: 5,
          sponsoredRank: 56,
          keywordSales: 176
        },
        {
          date: '2024-07-06',
          searchVolume: 3650,
          organicRank: 7,

          keywordSales: 176
        },
        {
          date: '2024-07-05',
          searchVolume: 4325,
          organicRank: 4,

          keywordSales: 176
        },
        {
          date: '2024-07-04',
          searchVolume: 4325,
          organicRank: 11,

          keywordSales: 176
        },
        {
          date: '2024-07-03',
          searchVolume: 4325,
          organicRank: 12,

          keywordSales: 176
        },
        {
          date: '2024-07-02',
          searchVolume: 4325,
          organicRank: 13,

          keywordSales: 176
        },
        {
          date: '2024-07-01',
          searchVolume: 4325,
          organicRank: 21,

          keywordSales: 176
        },
        {
          date: '2024-06-30',
          searchVolume: 4325,
          organicRank: 14,

          keywordSales: 176
        },
        {
          date: '2024-06-29',
          searchVolume: 4325,
          organicRank: 17,
          sponsoredRank: 5,
          keywordSales: 176
        },
        {
          date: '2024-06-28',
          searchVolume: 3611,
          organicRank: 11,

          keywordSales: 176
        },
        {
          date: '2024-06-27',
          searchVolume: 3611,
          organicRank: 10,
          sponsoredRank: 57,
          keywordSales: 176
        },
        {
          date: '2024-06-26',
          searchVolume: 3611,
          organicRank: 22,

          keywordSales: 176
        },
        {
          date: '2024-06-25',
          searchVolume: 3611,
          organicRank: 8,

          keywordSales: 176
        },
        {
          date: '2024-06-24',
          searchVolume: 3611,
          organicRank: 10,

          keywordSales: 176
        },
        {
          date: '2024-06-21',
          searchVolume: 3475,
          organicRank: 9,

          keywordSales: 176
        },
        {
          date: '2024-06-20',
          searchVolume: 3475,
          organicRank: 11,

          keywordSales: 176
        },
        {
          date: '2024-06-19',
          searchVolume: 3475,
          organicRank: 16,

          keywordSales: 176
        },
        {
          date: '2024-06-18',
          searchVolume: 3475,
          organicRank: 9,

          keywordSales: 176
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_lucky fck energy drink`,
      keyword: 'lucky fck energy drink',
      searchVolume: 3819,
      organicStartRank: 13,
      organicHighestRank: 7,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 4,
      organicPageOneWinDate: '2024-07-02',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-17',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 3211,
          organicRank: 12,

          keywordSales: 70
        },
        {
          date: '2024-08-06',
          searchVolume: 3211,
          organicRank: 14,

          keywordSales: 70
        },
        {
          date: '2024-08-05',
          searchVolume: 3211,
          organicRank: 10,

          keywordSales: 70
        },
        {
          date: '2024-08-04',
          searchVolume: 3211,
          organicRank: 13,

          keywordSales: 70
        },
        {
          date: '2024-08-03',
          searchVolume: 3211,
          organicRank: 19,

          keywordSales: 70
        },
        {
          date: '2024-08-01',
          searchVolume: 3211,
          organicRank: 13,

          keywordSales: 70
        },
        {
          date: '2024-07-31',
          searchVolume: 3211,
          organicRank: 12,

          keywordSales: 70
        },
        {
          date: '2024-07-30',
          searchVolume: 3211,
          organicRank: 15,

          keywordSales: 70
        },
        {
          date: '2024-07-29',
          searchVolume: 3211,
          organicRank: 13,

          keywordSales: 70
        },
        {
          date: '2024-07-28',
          searchVolume: 3211,
          organicRank: 13,

          keywordSales: 70
        },
        {
          date: '2024-07-27',
          searchVolume: 3211,
          organicRank: 15,

          keywordSales: 70
        },
        {
          date: '2024-07-26',
          searchVolume: 4699,
          organicRank: 13,

          keywordSales: 70
        },
        {
          date: '2024-07-25',
          searchVolume: 4699,
          organicRank: 17,

          keywordSales: 70
        },
        {
          date: '2024-07-24',
          searchVolume: 4699,
          organicRank: 14,

          keywordSales: 70
        },
        {
          date: '2024-07-23',
          searchVolume: 4699,
          organicRank: 16,

          keywordSales: 70
        },
        {
          date: '2024-07-22',
          searchVolume: 4699,
          organicRank: 16,

          keywordSales: 70
        },
        {
          date: '2024-07-21',
          searchVolume: 4699,
          organicRank: 22,

          keywordSales: 70
        },
        {
          date: '2024-07-20',
          searchVolume: 4699,
          organicRank: 16,

          keywordSales: 70
        },
        {
          date: '2024-07-19',
          searchVolume: 3406,
          organicRank: 15,

          keywordSales: 70
        },
        {
          date: '2024-07-18',
          searchVolume: 3406,
          organicRank: 9,

          keywordSales: 70
        },
        {
          date: '2024-07-17',
          searchVolume: 3406,
          organicRank: 10,
          sponsoredRank: 4,
          keywordSales: 70
        },
        {
          date: '2024-07-16',
          searchVolume: 3406,
          organicRank: 12,

          keywordSales: 70
        },
        {
          date: '2024-07-15',
          searchVolume: 3406,
          organicRank: 14,

          keywordSales: 70
        },
        {
          date: '2024-07-14',
          searchVolume: 3406,
          organicRank: 11,

          keywordSales: 70
        },
        {
          date: '2024-07-13',
          searchVolume: 3406,
          organicRank: 14,

          keywordSales: 70
        },
        {
          date: '2024-07-12',
          searchVolume: 2989,
          organicRank: 13,

          keywordSales: 70
        },
        {
          date: '2024-07-11',
          searchVolume: 2989,
          organicRank: 15,

          keywordSales: 70
        },
        {
          date: '2024-07-10',
          searchVolume: 2989,
          organicRank: 48,

          keywordSales: 70
        },
        {
          date: '2024-07-09',
          searchVolume: 2989,
          organicRank: 8,

          keywordSales: 70
        },
        {
          date: '2024-07-08',
          searchVolume: 2989,
          organicRank: 11,

          keywordSales: 70
        },
        {
          date: '2024-07-07',
          searchVolume: 2989,
          organicRank: 13,

          keywordSales: 70
        },
        {
          date: '2024-07-06',
          searchVolume: 2989,
          organicRank: 7,

          keywordSales: 70
        },
        {
          date: '2024-07-05',
          searchVolume: 3435,
          organicRank: 8,

          keywordSales: 70
        },
        {
          date: '2024-07-04',
          searchVolume: 3435,
          organicRank: 12,

          keywordSales: 70
        },
        {
          date: '2024-07-03',
          searchVolume: 3435,
          organicRank: 17,

          keywordSales: 70
        },
        {
          date: '2024-07-02',
          searchVolume: 3435,
          organicRank: 10,

          keywordSales: 70
        },
        {
          date: '2024-07-01',
          searchVolume: 3435,
          organicRank: 25,

          keywordSales: 70
        },
        {
          date: '2024-06-30',
          searchVolume: 3435,
          organicRank: 10,

          keywordSales: 70
        },
        {
          date: '2024-06-29',
          searchVolume: 3435,
          organicRank: 13,

          keywordSales: 70
        },
        {
          date: '2024-06-28',
          searchVolume: 3116,
          organicRank: 12,

          keywordSales: 70
        },
        {
          date: '2024-06-27',
          searchVolume: 3116,
          organicRank: 11,

          keywordSales: 70
        },
        {
          date: '2024-06-26',
          searchVolume: 3116,
          organicRank: 14,

          keywordSales: 70
        },
        {
          date: '2024-06-25',
          searchVolume: 3116,
          organicRank: 8,

          keywordSales: 70
        },
        {
          date: '2024-06-24',
          searchVolume: 3116,
          organicRank: 12,

          keywordSales: 70
        },
        {
          date: '2024-06-21',
          searchVolume: 3819,
          organicRank: 8,

          keywordSales: 70
        },
        {
          date: '2024-06-20',
          searchVolume: 3819,
          organicRank: 10,

          keywordSales: 70
        },
        {
          date: '2024-06-19',
          searchVolume: 3819,
          organicRank: 13,

          keywordSales: 70
        },
        {
          date: '2024-06-18',
          searchVolume: 3819,
          organicRank: 13,

          keywordSales: 70
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_mio energy`,
      keyword: 'mio energy',
      searchVolume: 11062,
      organicStartRank: 85,
      organicHighestRank: 49,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 10027,
          organicRank: 122,

          keywordSales: 313
        },
        {
          date: '2024-08-06',
          searchVolume: 10027,
          organicRank: 112,

          keywordSales: 313
        },
        {
          date: '2024-08-05',
          searchVolume: 10027,
          organicRank: 112,

          keywordSales: 313
        },
        {
          date: '2024-08-04',
          searchVolume: 10027,
          organicRank: 109,

          keywordSales: 313
        },
        {
          date: '2024-08-03',
          searchVolume: 10027,
          organicRank: 117,

          keywordSales: 313
        },
        {
          date: '2024-08-01',
          searchVolume: 10027,
          organicRank: 127,

          keywordSales: 313
        },
        {
          date: '2024-07-31',
          searchVolume: 10027,
          organicRank: 125,

          keywordSales: 313
        },
        {
          date: '2024-07-30',
          searchVolume: 10027,
          organicRank: 111,

          keywordSales: 313
        },
        {
          date: '2024-07-29',
          searchVolume: 10027,
          organicRank: 126,

          keywordSales: 313
        },
        {
          date: '2024-07-28',
          searchVolume: 10027,
          organicRank: 125,

          keywordSales: 313
        },
        {
          date: '2024-07-27',
          searchVolume: 10027,
          organicRank: 132,

          keywordSales: 313
        },
        {
          date: '2024-07-26',
          searchVolume: 10239,
          organicRank: 124,

          keywordSales: 313
        },
        {
          date: '2024-07-25',
          searchVolume: 10239,
          organicRank: 127,

          keywordSales: 313
        },
        {
          date: '2024-07-24',
          searchVolume: 10239,
          organicRank: 119,

          keywordSales: 313
        },
        {
          date: '2024-07-23',
          searchVolume: 10239,
          organicRank: 118,

          keywordSales: 313
        },
        {
          date: '2024-07-22',
          searchVolume: 10239,
          organicRank: 136,

          keywordSales: 313
        },
        {
          date: '2024-07-21',
          searchVolume: 10239,
          organicRank: 113,

          keywordSales: 313
        },
        {
          date: '2024-07-20',
          searchVolume: 10239,
          organicRank: 79,

          keywordSales: 313
        },
        {
          date: '2024-07-19',
          searchVolume: 11019,
          organicRank: 109,

          keywordSales: 313
        },
        {
          date: '2024-07-18',
          searchVolume: 11019,
          organicRank: 102,

          keywordSales: 313
        },
        {
          date: '2024-07-17',
          searchVolume: 11019,
          organicRank: 88,

          keywordSales: 313
        },
        {
          date: '2024-07-16',
          searchVolume: 11019,
          organicRank: 88,

          keywordSales: 313
        },
        {
          date: '2024-07-15',
          searchVolume: 11019,
          organicRank: 57,

          keywordSales: 313
        },
        {
          date: '2024-07-14',
          searchVolume: 11019,
          organicRank: 75,

          keywordSales: 313
        },
        {
          date: '2024-07-13',
          searchVolume: 11019,
          organicRank: 89,

          keywordSales: 313
        },
        {
          date: '2024-07-12',
          searchVolume: 10323,
          organicRank: 76,

          keywordSales: 313
        },
        {
          date: '2024-07-11',
          searchVolume: 10323,
          organicRank: 73,

          keywordSales: 313
        },
        {
          date: '2024-07-10',
          searchVolume: 10323,
          organicRank: 71,

          keywordSales: 313
        },
        {
          date: '2024-07-09',
          searchVolume: 10323,
          organicRank: 67,

          keywordSales: 313
        },
        {
          date: '2024-07-08',
          searchVolume: 10323,
          organicRank: 62,

          keywordSales: 313
        },
        {
          date: '2024-07-07',
          searchVolume: 10323,
          organicRank: 62,

          keywordSales: 313
        },
        {
          date: '2024-07-06',
          searchVolume: 10323,
          organicRank: 51,

          keywordSales: 313
        },
        {
          date: '2024-07-05',
          searchVolume: 11765,
          organicRank: 69,

          keywordSales: 313
        },
        {
          date: '2024-07-04',
          searchVolume: 11765,
          organicRank: 79,

          keywordSales: 313
        },
        {
          date: '2024-07-03',
          searchVolume: 11765,
          organicRank: 90,

          keywordSales: 313
        },
        {
          date: '2024-07-02',
          searchVolume: 11765,
          organicRank: 71,

          keywordSales: 313
        },
        {
          date: '2024-07-01',
          searchVolume: 11765,
          organicRank: 98,

          keywordSales: 313
        },
        {
          date: '2024-06-30',
          searchVolume: 11765,
          organicRank: 67,

          keywordSales: 313
        },
        {
          date: '2024-06-29',
          searchVolume: 11765,
          organicRank: 69,

          keywordSales: 313
        },
        {
          date: '2024-06-28',
          searchVolume: 10848,
          organicRank: 49,

          keywordSales: 313
        },
        {
          date: '2024-06-27',
          searchVolume: 10848,
          organicRank: 55,

          keywordSales: 313
        },
        {
          date: '2024-06-26',
          searchVolume: 10848,
          organicRank: 86,

          keywordSales: 313
        },
        {
          date: '2024-06-25',
          searchVolume: 10848,
          organicRank: 72,

          keywordSales: 313
        },
        {
          date: '2024-06-24',
          searchVolume: 10848,
          organicRank: 77,

          keywordSales: 313
        },
        {
          date: '2024-06-21',
          searchVolume: 11062,
          organicRank: 93,

          keywordSales: 313
        },
        {
          date: '2024-06-20',
          searchVolume: 11062,
          organicRank: 84,

          keywordSales: 313
        },
        {
          date: '2024-06-19',
          searchVolume: 11062,
          organicRank: 85,

          keywordSales: 313
        },
        {
          date: '2024-06-18',
          searchVolume: 11062,
          organicRank: 78,

          keywordSales: 313
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_mio energy water enhancer`,
      keyword: 'mio energy water enhancer',
      searchVolume: 2390,
      organicStartRank: 306,
      organicHighestRank: 144,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2351,
          organicRank: 146,

          keywordSales: 71
        },
        {
          date: '2024-08-06',
          searchVolume: 2351,
          organicRank: 160,

          keywordSales: 71
        },
        {
          date: '2024-08-05',
          searchVolume: 2351,
          organicRank: 165,

          keywordSales: 71
        },
        {
          date: '2024-08-04',
          searchVolume: 2351,
          organicRank: 157,

          keywordSales: 71
        },
        {
          date: '2024-08-03',
          searchVolume: 2351,
          organicRank: 168,

          keywordSales: 71
        },
        {
          date: '2024-08-01',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-31',
          searchVolume: 2351,
          organicRank: 151,

          keywordSales: 71
        },
        {
          date: '2024-07-30',
          searchVolume: 2351,
          organicRank: 157,

          keywordSales: 71
        },
        {
          date: '2024-07-29',
          searchVolume: 2351,
          organicRank: 152,

          keywordSales: 71
        },
        {
          date: '2024-07-28',
          searchVolume: 2351,
          organicRank: 147,

          keywordSales: 71
        },
        {
          date: '2024-07-27',
          searchVolume: 2351,
          organicRank: 155,

          keywordSales: 71
        },
        {
          date: '2024-07-26',
          searchVolume: 2433,
          organicRank: 153,

          keywordSales: 71
        },
        {
          date: '2024-07-25',
          searchVolume: 2433,
          organicRank: 151,

          keywordSales: 71
        },
        {
          date: '2024-07-24',
          searchVolume: 2433,
          organicRank: 144,

          keywordSales: 71
        },
        {
          date: '2024-07-23',
          searchVolume: 2433,
          organicRank: 145,

          keywordSales: 71
        },
        {
          date: '2024-07-22',
          searchVolume: 2433,
          organicRank: 149,

          keywordSales: 71
        },
        {
          date: '2024-07-21',
          searchVolume: 2433,
          organicRank: 153,

          keywordSales: 71
        },
        {
          date: '2024-07-20',
          searchVolume: 2433,
          organicRank: 150,

          keywordSales: 71
        },
        {
          date: '2024-07-19',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-18',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-17',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-16',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-15',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-14',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-13',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-12',
          searchVolume: 2321,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-11',
          searchVolume: 2321,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-10',
          searchVolume: 2321,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-09',
          searchVolume: 2321,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-08',
          searchVolume: 2321,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-07',
          searchVolume: 2321,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-06',
          searchVolume: 2321,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-05',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-04',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-03',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-02',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-07-01',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-30',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-29',
          searchVolume: 2550,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-28',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-27',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-26',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-25',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-24',
          searchVolume: 2539,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-21',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-20',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-19',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 71
        },
        {
          date: '2024-06-18',
          searchVolume: 2428,
          organicRank: 306,

          keywordSales: 71
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_monster energy`,
      keyword: 'monster energy',
      searchVolume: 84829,
      organicStartRank: 73,
      organicHighestRank: 61,
      sponsoredStartRank: 16,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 76021,
          organicRank: 76,

          keywordSales: 2033
        },
        {
          date: '2024-08-05',
          searchVolume: 76021,
          organicRank: 82,

          keywordSales: 2033
        },
        {
          date: '2024-08-04',
          searchVolume: 76021,
          organicRank: 91,

          keywordSales: 2033
        },
        {
          date: '2024-08-03',
          searchVolume: 76021,
          organicRank: 89,
          sponsoredRank: 26,
          keywordSales: 2033
        },
        {
          date: '2024-08-02',
          searchVolume: 76021,
          organicRank: 87,

          keywordSales: 2033
        },
        {
          date: '2024-08-01',
          searchVolume: 76021,
          organicRank: 76,

          keywordSales: 2033
        },
        {
          date: '2024-07-31',
          searchVolume: 76021,
          organicRank: 69,

          keywordSales: 2033
        },
        {
          date: '2024-07-30',
          searchVolume: 76021,
          organicRank: 73,

          keywordSales: 2033
        },
        {
          date: '2024-07-29',
          searchVolume: 76021,
          organicRank: 61,

          keywordSales: 2033
        },
        {
          date: '2024-07-28',
          searchVolume: 76021,
          organicRank: 76,

          keywordSales: 2033
        },
        {
          date: '2024-07-27',
          searchVolume: 76021,
          organicRank: 82,

          keywordSales: 2033
        },
        {
          date: '2024-07-26',
          searchVolume: 106376,
          organicRank: 62,

          keywordSales: 2033
        },
        {
          date: '2024-07-25',
          searchVolume: 106376,
          organicRank: 95,

          keywordSales: 2033
        },
        {
          date: '2024-07-24',
          searchVolume: 106376,
          organicRank: 62,
          sponsoredRank: 4,
          keywordSales: 2033
        },
        {
          date: '2024-07-23',
          searchVolume: 106376,
          organicRank: 76,
          sponsoredRank: 21,
          keywordSales: 2033
        },
        {
          date: '2024-07-22',
          searchVolume: 106376,
          organicRank: 76,
          sponsoredRank: 36,
          keywordSales: 2033
        },
        {
          date: '2024-07-21',
          searchVolume: 106376,
          organicRank: 77,
          sponsoredRank: 17,
          keywordSales: 2033
        },
        {
          date: '2024-07-20',
          searchVolume: 106376,
          organicRank: 66,
          sponsoredRank: 44,
          keywordSales: 2033
        },
        {
          date: '2024-07-19',
          searchVolume: 79618,
          organicRank: 79,
          sponsoredRank: 43,
          keywordSales: 2033
        },
        {
          date: '2024-07-18',
          searchVolume: 79618,
          organicRank: 77,
          sponsoredRank: 18,
          keywordSales: 2033
        },
        {
          date: '2024-07-17',
          searchVolume: 79618,
          organicRank: 94,
          sponsoredRank: 11,
          keywordSales: 2033
        },
        {
          date: '2024-07-16',
          searchVolume: 79618,
          organicRank: 81,

          keywordSales: 2033
        },
        {
          date: '2024-07-15',
          searchVolume: 79618,
          organicRank: 81,

          keywordSales: 2033
        },
        {
          date: '2024-07-14',
          searchVolume: 79618,
          organicRank: 82,
          sponsoredRank: 40,
          keywordSales: 2033
        },
        {
          date: '2024-07-13',
          searchVolume: 79618,
          organicRank: 78,

          keywordSales: 2033
        },
        {
          date: '2024-07-12',
          searchVolume: 81140,
          organicRank: 66,

          keywordSales: 2033
        },
        {
          date: '2024-07-11',
          searchVolume: 81140,
          organicRank: 87,

          keywordSales: 2033
        },
        {
          date: '2024-07-09',
          searchVolume: 81140,
          organicRank: 88,

          keywordSales: 2033
        },
        {
          date: '2024-07-08',
          searchVolume: 81140,
          organicRank: 86,
          sponsoredRank: 36,
          keywordSales: 2033
        },
        {
          date: '2024-07-07',
          searchVolume: 81140,
          organicRank: 91,

          keywordSales: 2033
        },
        {
          date: '2024-07-06',
          searchVolume: 81140,
          organicRank: 91,
          sponsoredRank: 21,
          keywordSales: 2033
        },
        {
          date: '2024-07-05',
          searchVolume: 84930,
          organicRank: 91,
          sponsoredRank: 16,
          keywordSales: 2033
        },
        {
          date: '2024-07-04',
          searchVolume: 84930,
          organicRank: 113,
          sponsoredRank: 23,
          keywordSales: 2033
        },
        {
          date: '2024-07-03',
          searchVolume: 84930,
          organicRank: 88,

          keywordSales: 2033
        },
        {
          date: '2024-07-02',
          searchVolume: 84930,
          organicRank: 96,

          keywordSales: 2033
        },
        {
          date: '2024-07-01',
          searchVolume: 84930,
          organicRank: 85,
          sponsoredRank: 48,
          keywordSales: 2033
        },
        {
          date: '2024-06-30',
          searchVolume: 84930,
          organicRank: 82,
          sponsoredRank: 43,
          keywordSales: 2033
        },
        {
          date: '2024-06-29',
          searchVolume: 84930,
          organicRank: 88,
          sponsoredRank: 42,
          keywordSales: 2033
        },
        {
          date: '2024-06-28',
          searchVolume: 84600,
          organicRank: 86,
          sponsoredRank: 46,
          keywordSales: 2033
        },
        {
          date: '2024-06-27',
          searchVolume: 84600,
          organicRank: 87,

          keywordSales: 2033
        },
        {
          date: '2024-06-26',
          searchVolume: 84600,
          organicRank: 81,

          keywordSales: 2033
        },
        {
          date: '2024-06-25',
          searchVolume: 84600,
          organicRank: 95,
          sponsoredRank: 20,
          keywordSales: 2033
        },
        {
          date: '2024-06-24',
          searchVolume: 84600,
          organicRank: 114,

          keywordSales: 2033
        },
        {
          date: '2024-06-21',
          searchVolume: 84829,
          organicRank: 83,

          keywordSales: 2033
        },
        {
          date: '2024-06-20',
          searchVolume: 84829,
          organicRank: 85,
          sponsoredRank: 16,
          keywordSales: 2033
        },
        {
          date: '2024-06-19',
          searchVolume: 84829,
          organicRank: 73,

          keywordSales: 2033
        },
        {
          date: '2024-06-18',
          searchVolume: 84829,
          organicRank: 72,

          keywordSales: 2033
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_monster energy 24 pack`,
      keyword: 'monster energy 24 pack',
      searchVolume: 2816,
      organicStartRank: 90,
      organicHighestRank: 57,
      sponsoredStartRank: 7,
      sponsoredHighestRank: 1,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2241,
          organicRank: 95,

          keywordSales: 61
        },
        {
          date: '2024-08-05',
          searchVolume: 2241,
          organicRank: 106,

          keywordSales: 61
        },
        {
          date: '2024-08-04',
          searchVolume: 2241,
          organicRank: 87,

          keywordSales: 61
        },
        {
          date: '2024-08-02',
          searchVolume: 2241,
          organicRank: 91,

          keywordSales: 61
        },
        {
          date: '2024-08-01',
          searchVolume: 2241,
          organicRank: 93,

          keywordSales: 61
        },
        {
          date: '2024-07-31',
          searchVolume: 2241,
          organicRank: 92,

          keywordSales: 61
        },
        {
          date: '2024-07-30',
          searchVolume: 2241,
          organicRank: 68,

          keywordSales: 61
        },
        {
          date: '2024-07-28',
          searchVolume: 2241,
          organicRank: 73,

          keywordSales: 61
        },
        {
          date: '2024-07-27',
          searchVolume: 2241,
          organicRank: 88,

          keywordSales: 61
        },
        {
          date: '2024-07-26',
          searchVolume: 3094,
          organicRank: 64,

          keywordSales: 61
        },
        {
          date: '2024-07-25',
          searchVolume: 3094,
          organicRank: 63,

          keywordSales: 61
        },
        {
          date: '2024-07-24',
          searchVolume: 3094,
          organicRank: 66,

          keywordSales: 61
        },
        {
          date: '2024-07-23',
          searchVolume: 3094,
          organicRank: 66,
          sponsoredRank: 1,
          keywordSales: 61
        },
        {
          date: '2024-07-22',
          searchVolume: 3094,
          organicRank: 57,

          keywordSales: 61
        },
        {
          date: '2024-07-21',
          searchVolume: 3094,
          organicRank: 59,
          sponsoredRank: 2,
          keywordSales: 61
        },
        {
          date: '2024-07-20',
          searchVolume: 3094,
          organicRank: 64,
          sponsoredRank: 12,
          keywordSales: 61
        },
        {
          date: '2024-07-19',
          searchVolume: 2477,
          organicRank: 60,

          keywordSales: 61
        },
        {
          date: '2024-07-18',
          searchVolume: 2477,
          organicRank: 58,

          keywordSales: 61
        },
        {
          date: '2024-07-17',
          searchVolume: 2477,
          organicRank: 62,

          keywordSales: 61
        },
        {
          date: '2024-07-16',
          searchVolume: 2477,
          organicRank: 90,

          keywordSales: 61
        },
        {
          date: '2024-07-15',
          searchVolume: 2477,
          organicRank: 89,

          keywordSales: 61
        },
        {
          date: '2024-07-14',
          searchVolume: 2477,
          organicRank: 92,
          sponsoredRank: 11,
          keywordSales: 61
        },
        {
          date: '2024-07-13',
          searchVolume: 2477,
          organicRank: 92,
          sponsoredRank: 7,
          keywordSales: 61
        },
        {
          date: '2024-07-12',
          searchVolume: 2437,
          organicRank: 90,

          keywordSales: 61
        },
        {
          date: '2024-07-11',
          searchVolume: 2437,
          organicRank: 94,

          keywordSales: 61
        },
        {
          date: '2024-07-10',
          searchVolume: 2437,
          organicRank: 306,

          keywordSales: 61
        },
        {
          date: '2024-07-09',
          searchVolume: 2437,
          organicRank: 91,

          keywordSales: 61
        },
        {
          date: '2024-07-08',
          searchVolume: 2437,
          organicRank: 97,

          keywordSales: 61
        },
        {
          date: '2024-07-07',
          searchVolume: 2437,
          organicRank: 88,

          keywordSales: 61
        },
        {
          date: '2024-07-06',
          searchVolume: 2437,
          organicRank: 92,

          keywordSales: 61
        },
        {
          date: '2024-07-05',
          searchVolume: 3128,
          organicRank: 112,

          keywordSales: 61
        },
        {
          date: '2024-07-04',
          searchVolume: 3128,
          organicRank: 100,

          keywordSales: 61
        },
        {
          date: '2024-07-03',
          searchVolume: 3128,
          organicRank: 114,

          keywordSales: 61
        },
        {
          date: '2024-07-02',
          searchVolume: 3128,
          organicRank: 107,

          keywordSales: 61
        },
        {
          date: '2024-07-01',
          searchVolume: 3128,
          organicRank: 103,

          keywordSales: 61
        },
        {
          date: '2024-06-30',
          searchVolume: 3128,
          organicRank: 109,

          keywordSales: 61
        },
        {
          date: '2024-06-29',
          searchVolume: 3128,
          organicRank: 106,

          keywordSales: 61
        },
        {
          date: '2024-06-28',
          searchVolume: 2807,
          organicRank: 107,

          keywordSales: 61
        },
        {
          date: '2024-06-27',
          searchVolume: 2807,
          organicRank: 116,

          keywordSales: 61
        },
        {
          date: '2024-06-26',
          searchVolume: 2807,
          organicRank: 119,

          keywordSales: 61
        },
        {
          date: '2024-06-25',
          searchVolume: 2807,
          organicRank: 99,

          keywordSales: 61
        },
        {
          date: '2024-06-24',
          searchVolume: 2807,
          organicRank: 126,

          keywordSales: 61
        },
        {
          date: '2024-06-21',
          searchVolume: 2816,
          organicRank: 105,

          keywordSales: 61
        },
        {
          date: '2024-06-20',
          searchVolume: 2816,
          organicRank: 122,

          keywordSales: 61
        },
        {
          date: '2024-06-19',
          searchVolume: 2816,
          organicRank: 90,

          keywordSales: 61
        },
        {
          date: '2024-06-18',
          searchVolume: 2816,
          organicRank: 91,

          keywordSales: 61
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_monster energy drink`,
      keyword: 'monster energy drink',
      searchVolume: 6746,
      organicStartRank: 127,
      organicHighestRank: 90,
      sponsoredStartRank: 42,
      sponsoredHighestRank: 7,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 4538,
          organicRank: 183,

          keywordSales: 183
        },
        {
          date: '2024-08-05',
          searchVolume: 4538,
          organicRank: 173,
          sponsoredRank: 19,
          keywordSales: 183
        },
        {
          date: '2024-08-04',
          searchVolume: 4538,
          organicRank: 192,

          keywordSales: 183
        },
        {
          date: '2024-08-03',
          searchVolume: 4538,
          organicRank: 171,

          keywordSales: 183
        },
        {
          date: '2024-08-02',
          searchVolume: 4538,
          organicRank: 181,

          keywordSales: 183
        },
        {
          date: '2024-08-01',
          searchVolume: 4538,
          organicRank: 194,

          keywordSales: 183
        },
        {
          date: '2024-07-31',
          searchVolume: 4538,
          organicRank: 183,

          keywordSales: 183
        },
        {
          date: '2024-07-30',
          searchVolume: 4538,
          organicRank: 128,

          keywordSales: 183
        },
        {
          date: '2024-07-28',
          searchVolume: 4538,
          organicRank: 176,

          keywordSales: 183
        },
        {
          date: '2024-07-27',
          searchVolume: 4538,
          organicRank: 306,

          keywordSales: 183
        },
        {
          date: '2024-07-26',
          searchVolume: 8097,
          organicRank: 99,

          keywordSales: 183
        },
        {
          date: '2024-07-25',
          searchVolume: 8097,
          organicRank: 156,

          keywordSales: 183
        },
        {
          date: '2024-07-24',
          searchVolume: 8097,
          organicRank: 162,

          keywordSales: 183
        },
        {
          date: '2024-07-23',
          searchVolume: 8097,
          organicRank: 90,
          sponsoredRank: 7,
          keywordSales: 183
        },
        {
          date: '2024-07-22',
          searchVolume: 8097,
          organicRank: 167,
          sponsoredRank: 33,
          keywordSales: 183
        },
        {
          date: '2024-07-21',
          searchVolume: 8097,
          organicRank: 156,

          keywordSales: 183
        },
        {
          date: '2024-07-20',
          searchVolume: 8097,
          organicRank: 148,
          sponsoredRank: 30,
          keywordSales: 183
        },
        {
          date: '2024-07-19',
          searchVolume: 4835,
          organicRank: 120,

          keywordSales: 183
        },
        {
          date: '2024-07-18',
          searchVolume: 4835,
          organicRank: 121,

          keywordSales: 183
        },
        {
          date: '2024-07-17',
          searchVolume: 4835,
          organicRank: 124,

          keywordSales: 183
        },
        {
          date: '2024-07-16',
          searchVolume: 4835,
          organicRank: 159,
          sponsoredRank: 28,
          keywordSales: 183
        },
        {
          date: '2024-07-15',
          searchVolume: 4835,
          organicRank: 123,
          sponsoredRank: 48,
          keywordSales: 183
        },
        {
          date: '2024-07-14',
          searchVolume: 4835,
          organicRank: 128,
          sponsoredRank: 12,
          keywordSales: 183
        },
        {
          date: '2024-07-13',
          searchVolume: 4835,
          organicRank: 112,
          sponsoredRank: 23,
          keywordSales: 183
        },
        {
          date: '2024-07-12',
          searchVolume: 5708,
          organicRank: 123,

          keywordSales: 183
        },
        {
          date: '2024-07-11',
          searchVolume: 5708,
          organicRank: 98,

          keywordSales: 183
        },
        {
          date: '2024-07-10',
          searchVolume: 5708,
          organicRank: 113,

          keywordSales: 183
        },
        {
          date: '2024-07-09',
          searchVolume: 5708,
          organicRank: 131,

          keywordSales: 183
        },
        {
          date: '2024-07-08',
          searchVolume: 5708,
          organicRank: 127,

          keywordSales: 183
        },
        {
          date: '2024-07-07',
          searchVolume: 5708,
          organicRank: 134,

          keywordSales: 183
        },
        {
          date: '2024-07-06',
          searchVolume: 5708,
          organicRank: 145,

          keywordSales: 183
        },
        {
          date: '2024-07-05',
          searchVolume: 5693,
          organicRank: 143,

          keywordSales: 183
        },
        {
          date: '2024-07-04',
          searchVolume: 5693,
          organicRank: 127,

          keywordSales: 183
        },
        {
          date: '2024-07-03',
          searchVolume: 5693,
          organicRank: 164,

          keywordSales: 183
        },
        {
          date: '2024-07-02',
          searchVolume: 5693,
          organicRank: 114,

          keywordSales: 183
        },
        {
          date: '2024-07-01',
          searchVolume: 5693,
          organicRank: 148,

          keywordSales: 183
        },
        {
          date: '2024-06-30',
          searchVolume: 5693,
          organicRank: 160,

          keywordSales: 183
        },
        {
          date: '2024-06-29',
          searchVolume: 5693,
          organicRank: 161,

          keywordSales: 183
        },
        {
          date: '2024-06-28',
          searchVolume: 6725,
          organicRank: 140,

          keywordSales: 183
        },
        {
          date: '2024-06-27',
          searchVolume: 6725,
          organicRank: 112,

          keywordSales: 183
        },
        {
          date: '2024-06-26',
          searchVolume: 6725,
          organicRank: 109,

          keywordSales: 183
        },
        {
          date: '2024-06-25',
          searchVolume: 6725,
          organicRank: 145,

          keywordSales: 183
        },
        {
          date: '2024-06-21',
          searchVolume: 6746,
          organicRank: 124,

          keywordSales: 183
        },
        {
          date: '2024-06-20',
          searchVolume: 6746,
          organicRank: 182,
          sponsoredRank: 42,
          keywordSales: 183
        },
        {
          date: '2024-06-19',
          searchVolume: 6746,
          organicRank: 127,

          keywordSales: 183
        },
        {
          date: '2024-06-18',
          searchVolume: 6746,
          organicRank: 203,

          keywordSales: 183
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_monster energy zero`,
      keyword: 'monster energy zero',
      searchVolume: 1661,
      organicStartRank: 106,
      organicHighestRank: 77,
      sponsoredStartRank: 32,
      sponsoredHighestRank: 32,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1300,
          organicRank: 306,

          keywordSales: 47
        },
        {
          date: '2024-08-06',
          searchVolume: 1300,
          organicRank: 306,

          keywordSales: 47
        },
        {
          date: '2024-08-05',
          searchVolume: 1300,
          organicRank: 306,

          keywordSales: 47
        },
        {
          date: '2024-08-04',
          searchVolume: 1300,
          organicRank: 306,

          keywordSales: 47
        },
        {
          date: '2024-08-03',
          searchVolume: 1300,
          organicRank: 306,

          keywordSales: 47
        },
        {
          date: '2024-08-02',
          searchVolume: 1300,
          organicRank: 306,

          keywordSales: 47
        },
        {
          date: '2024-08-01',
          searchVolume: 1300,
          organicRank: 306,

          keywordSales: 47
        },
        {
          date: '2024-07-31',
          searchVolume: 1300,
          organicRank: 100,

          keywordSales: 47
        },
        {
          date: '2024-07-30',
          searchVolume: 1300,
          organicRank: 109,

          keywordSales: 47
        },
        {
          date: '2024-07-28',
          searchVolume: 1300,
          organicRank: 82,

          keywordSales: 47
        },
        {
          date: '2024-07-27',
          searchVolume: 1300,
          organicRank: 84,

          keywordSales: 47
        },
        {
          date: '2024-07-26',
          searchVolume: 1764,
          organicRank: 77,

          keywordSales: 47
        },
        {
          date: '2024-07-25',
          searchVolume: 1764,
          organicRank: 83,

          keywordSales: 47
        },
        {
          date: '2024-07-24',
          searchVolume: 1764,
          organicRank: 83,

          keywordSales: 47
        },
        {
          date: '2024-07-23',
          searchVolume: 1764,
          organicRank: 84,

          keywordSales: 47
        },
        {
          date: '2024-07-22',
          searchVolume: 1764,
          organicRank: 83,

          keywordSales: 47
        },
        {
          date: '2024-07-21',
          searchVolume: 1764,
          organicRank: 84,

          keywordSales: 47
        },
        {
          date: '2024-07-20',
          searchVolume: 1764,
          organicRank: 85,

          keywordSales: 47
        },
        {
          date: '2024-07-19',
          searchVolume: 1189,
          organicRank: 89,

          keywordSales: 47
        },
        {
          date: '2024-07-18',
          searchVolume: 1189,
          organicRank: 93,

          keywordSales: 47
        },
        {
          date: '2024-07-17',
          searchVolume: 1189,
          organicRank: 306,

          keywordSales: 47
        },
        {
          date: '2024-07-16',
          searchVolume: 1189,
          organicRank: 105,

          keywordSales: 47
        },
        {
          date: '2024-07-15',
          searchVolume: 1189,
          organicRank: 93,
          sponsoredRank: 32,
          keywordSales: 47
        },
        {
          date: '2024-07-14',
          searchVolume: 1189,
          organicRank: 96,

          keywordSales: 47
        },
        {
          date: '2024-07-13',
          searchVolume: 1189,
          organicRank: 99,

          keywordSales: 47
        },
        {
          date: '2024-07-12',
          searchVolume: 1293,
          organicRank: 98,

          keywordSales: 47
        },
        {
          date: '2024-07-11',
          searchVolume: 1293,
          organicRank: 92,

          keywordSales: 47
        },
        {
          date: '2024-07-10',
          searchVolume: 1293,
          organicRank: 95,

          keywordSales: 47
        },
        {
          date: '2024-07-09',
          searchVolume: 1293,
          organicRank: 103,

          keywordSales: 47
        },
        {
          date: '2024-07-08',
          searchVolume: 1293,
          organicRank: 99,

          keywordSales: 47
        },
        {
          date: '2024-07-07',
          searchVolume: 1293,
          organicRank: 97,

          keywordSales: 47
        },
        {
          date: '2024-07-06',
          searchVolume: 1293,
          organicRank: 107,

          keywordSales: 47
        },
        {
          date: '2024-07-05',
          searchVolume: 1433,
          organicRank: 102,

          keywordSales: 47
        },
        {
          date: '2024-07-04',
          searchVolume: 1433,
          organicRank: 97,

          keywordSales: 47
        },
        {
          date: '2024-07-03',
          searchVolume: 1433,
          organicRank: 97,

          keywordSales: 47
        },
        {
          date: '2024-07-02',
          searchVolume: 1433,
          organicRank: 99,

          keywordSales: 47
        },
        {
          date: '2024-07-01',
          searchVolume: 1433,
          organicRank: 103,

          keywordSales: 47
        },
        {
          date: '2024-06-30',
          searchVolume: 1433,
          organicRank: 97,

          keywordSales: 47
        },
        {
          date: '2024-06-29',
          searchVolume: 1433,
          organicRank: 107,

          keywordSales: 47
        },
        {
          date: '2024-06-28',
          searchVolume: 1680,
          organicRank: 106,

          keywordSales: 47
        },
        {
          date: '2024-06-27',
          searchVolume: 1680,
          organicRank: 106,

          keywordSales: 47
        },
        {
          date: '2024-06-26',
          searchVolume: 1680,
          organicRank: 98,

          keywordSales: 47
        },
        {
          date: '2024-06-25',
          searchVolume: 1680,
          organicRank: 98,

          keywordSales: 47
        },
        {
          date: '2024-06-21',
          searchVolume: 1661,
          organicRank: 155,

          keywordSales: 47
        },
        {
          date: '2024-06-20',
          searchVolume: 1661,
          organicRank: 105,

          keywordSales: 47
        },
        {
          date: '2024-06-19',
          searchVolume: 1661,
          organicRank: 106,

          keywordSales: 47
        },
        {
          date: '2024-06-18',
          searchVolume: 1661,
          organicRank: 88,

          keywordSales: 47
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_monster energy zero sugar`,
      keyword: 'monster energy zero sugar',
      searchVolume: 5407,
      organicStartRank: 73,
      organicHighestRank: 51,
      sponsoredStartRank: 58,
      sponsoredHighestRank: 8,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 5661,
          organicRank: 91,

          keywordSales: 377
        },
        {
          date: '2024-08-06',
          searchVolume: 5661,
          organicRank: 111,

          keywordSales: 377
        },
        {
          date: '2024-08-05',
          searchVolume: 5661,
          organicRank: 100,

          keywordSales: 377
        },
        {
          date: '2024-08-04',
          searchVolume: 5661,
          organicRank: 101,

          keywordSales: 377
        },
        {
          date: '2024-08-03',
          searchVolume: 5661,
          organicRank: 94,

          keywordSales: 377
        },
        {
          date: '2024-08-01',
          searchVolume: 5661,
          organicRank: 103,

          keywordSales: 377
        },
        {
          date: '2024-07-31',
          searchVolume: 5661,
          organicRank: 93,

          keywordSales: 377
        },
        {
          date: '2024-07-30',
          searchVolume: 5661,
          organicRank: 82,

          keywordSales: 377
        },
        {
          date: '2024-07-29',
          searchVolume: 5661,
          organicRank: 59,

          keywordSales: 377
        },
        {
          date: '2024-07-28',
          searchVolume: 5661,
          organicRank: 77,

          keywordSales: 377
        },
        {
          date: '2024-07-27',
          searchVolume: 5661,
          organicRank: 51,

          keywordSales: 377
        },
        {
          date: '2024-07-26',
          searchVolume: 11645,
          organicRank: 69,

          keywordSales: 377
        },
        {
          date: '2024-07-25',
          searchVolume: 11645,
          organicRank: 79,

          keywordSales: 377
        },
        {
          date: '2024-07-24',
          searchVolume: 11645,
          organicRank: 76,

          keywordSales: 377
        },
        {
          date: '2024-07-23',
          searchVolume: 11645,
          organicRank: 80,
          sponsoredRank: 11,
          keywordSales: 377
        },
        {
          date: '2024-07-22',
          searchVolume: 11645,
          organicRank: 84,
          sponsoredRank: 22,
          keywordSales: 377
        },
        {
          date: '2024-07-21',
          searchVolume: 11645,
          organicRank: 75,
          sponsoredRank: 31,
          keywordSales: 377
        },
        {
          date: '2024-07-20',
          searchVolume: 11645,
          organicRank: 68,
          sponsoredRank: 34,
          keywordSales: 377
        },
        {
          date: '2024-07-19',
          searchVolume: 5285,
          organicRank: 67,

          keywordSales: 377
        },
        {
          date: '2024-07-18',
          searchVolume: 5285,
          organicRank: 70,
          sponsoredRank: 36,
          keywordSales: 377
        },
        {
          date: '2024-07-17',
          searchVolume: 5285,
          organicRank: 51,

          keywordSales: 377
        },
        {
          date: '2024-07-16',
          searchVolume: 5285,
          organicRank: 82,

          keywordSales: 377
        },
        {
          date: '2024-07-15',
          searchVolume: 5285,
          organicRank: 88,

          keywordSales: 377
        },
        {
          date: '2024-07-14',
          searchVolume: 5285,
          organicRank: 103,
          sponsoredRank: 8,
          keywordSales: 377
        },
        {
          date: '2024-07-13',
          searchVolume: 5285,
          organicRank: 96,

          keywordSales: 377
        },
        {
          date: '2024-07-12',
          searchVolume: 5372,
          organicRank: 88,

          keywordSales: 377
        },
        {
          date: '2024-07-11',
          searchVolume: 5372,
          organicRank: 94,

          keywordSales: 377
        },
        {
          date: '2024-07-10',
          searchVolume: 5372,
          organicRank: 97,

          keywordSales: 377
        },
        {
          date: '2024-07-09',
          searchVolume: 5372,
          organicRank: 83,

          keywordSales: 377
        },
        {
          date: '2024-07-08',
          searchVolume: 5372,
          organicRank: 71,

          keywordSales: 377
        },
        {
          date: '2024-07-07',
          searchVolume: 5372,
          organicRank: 84,

          keywordSales: 377
        },
        {
          date: '2024-07-06',
          searchVolume: 5372,
          organicRank: 73,

          keywordSales: 377
        },
        {
          date: '2024-07-05',
          searchVolume: 5961,
          organicRank: 74,

          keywordSales: 377
        },
        {
          date: '2024-07-04',
          searchVolume: 5961,
          organicRank: 77,
          sponsoredRank: 36,
          keywordSales: 377
        },
        {
          date: '2024-07-03',
          searchVolume: 5961,
          organicRank: 70,

          keywordSales: 377
        },
        {
          date: '2024-07-02',
          searchVolume: 5961,
          organicRank: 60,

          keywordSales: 377
        },
        {
          date: '2024-07-01',
          searchVolume: 5961,
          organicRank: 66,

          keywordSales: 377
        },
        {
          date: '2024-06-30',
          searchVolume: 5961,
          organicRank: 80,

          keywordSales: 377
        },
        {
          date: '2024-06-29',
          searchVolume: 5961,
          organicRank: 68,
          sponsoredRank: 33,
          keywordSales: 377
        },
        {
          date: '2024-06-28',
          searchVolume: 5392,
          organicRank: 67,

          keywordSales: 377
        },
        {
          date: '2024-06-27',
          searchVolume: 5392,
          organicRank: 70,

          keywordSales: 377
        },
        {
          date: '2024-06-26',
          searchVolume: 5392,
          organicRank: 68,

          keywordSales: 377
        },
        {
          date: '2024-06-25',
          searchVolume: 5392,
          organicRank: 61,

          keywordSales: 377
        },
        {
          date: '2024-06-24',
          searchVolume: 5392,
          organicRank: 66,

          keywordSales: 377
        },
        {
          date: '2024-06-21',
          searchVolume: 5407,
          organicRank: 56,

          keywordSales: 377
        },
        {
          date: '2024-06-20',
          searchVolume: 5407,
          organicRank: 68,
          sponsoredRank: 58,
          keywordSales: 377
        },
        {
          date: '2024-06-19',
          searchVolume: 5407,
          organicRank: 73,

          keywordSales: 377
        },
        {
          date: '2024-06-18',
          searchVolume: 5407,
          organicRank: 61,

          keywordSales: 377
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_monster ultra`,
      keyword: 'monster ultra',
      searchVolume: 2816,
      organicStartRank: 306,
      organicHighestRank: 140,
      sponsoredStartRank: 39,
      sponsoredHighestRank: 5,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-14',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2354,
          organicRank: 306,
          sponsoredRank: 6,
          keywordSales: 149
        },
        {
          date: '2024-08-06',
          searchVolume: 2354,
          organicRank: 184,

          keywordSales: 149
        },
        {
          date: '2024-08-05',
          searchVolume: 2354,
          organicRank: 183,

          keywordSales: 149
        },
        {
          date: '2024-08-04',
          searchVolume: 2354,
          organicRank: 188,

          keywordSales: 149
        },
        {
          date: '2024-08-03',
          searchVolume: 2354,
          organicRank: 184,

          keywordSales: 149
        },
        {
          date: '2024-08-01',
          searchVolume: 2354,
          organicRank: 190,

          keywordSales: 149
        },
        {
          date: '2024-07-31',
          searchVolume: 2354,
          organicRank: 197,

          keywordSales: 149
        },
        {
          date: '2024-07-30',
          searchVolume: 2354,
          organicRank: 171,

          keywordSales: 149
        },
        {
          date: '2024-07-29',
          searchVolume: 2354,
          organicRank: 156,

          keywordSales: 149
        },
        {
          date: '2024-07-28',
          searchVolume: 2354,
          organicRank: 177,

          keywordSales: 149
        },
        {
          date: '2024-07-27',
          searchVolume: 2354,
          organicRank: 172,

          keywordSales: 149
        },
        {
          date: '2024-07-26',
          searchVolume: 4342,
          organicRank: 179,

          keywordSales: 149
        },
        {
          date: '2024-07-25',
          searchVolume: 4342,
          organicRank: 169,

          keywordSales: 149
        },
        {
          date: '2024-07-24',
          searchVolume: 4342,
          organicRank: 167,
          sponsoredRank: 5,
          keywordSales: 149
        },
        {
          date: '2024-07-23',
          searchVolume: 4342,
          organicRank: 165,

          keywordSales: 149
        },
        {
          date: '2024-07-22',
          searchVolume: 4342,
          organicRank: 165,
          sponsoredRank: 12,
          keywordSales: 149
        },
        {
          date: '2024-07-21',
          searchVolume: 4342,
          organicRank: 169,
          sponsoredRank: 37,
          keywordSales: 149
        },
        {
          date: '2024-07-20',
          searchVolume: 4342,
          organicRank: 164,

          keywordSales: 149
        },
        {
          date: '2024-07-19',
          searchVolume: 2934,
          organicRank: 167,

          keywordSales: 149
        },
        {
          date: '2024-07-18',
          searchVolume: 2934,
          organicRank: 146,

          keywordSales: 149
        },
        {
          date: '2024-07-17',
          searchVolume: 2934,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-07-16',
          searchVolume: 2934,
          organicRank: 140,

          keywordSales: 149
        },
        {
          date: '2024-07-15',
          searchVolume: 2934,
          organicRank: 168,

          keywordSales: 149
        },
        {
          date: '2024-07-14',
          searchVolume: 2934,
          organicRank: 170,
          sponsoredRank: 6,
          keywordSales: 149
        },
        {
          date: '2024-07-13',
          searchVolume: 2934,
          organicRank: 176,

          keywordSales: 149
        },
        {
          date: '2024-07-12',
          searchVolume: 3097,
          organicRank: 174,

          keywordSales: 149
        },
        {
          date: '2024-07-11',
          searchVolume: 3097,
          organicRank: 171,

          keywordSales: 149
        },
        {
          date: '2024-07-10',
          searchVolume: 3097,
          organicRank: 188,

          keywordSales: 149
        },
        {
          date: '2024-07-09',
          searchVolume: 3097,
          organicRank: 182,

          keywordSales: 149
        },
        {
          date: '2024-07-08',
          searchVolume: 3097,
          organicRank: 183,

          keywordSales: 149
        },
        {
          date: '2024-07-07',
          searchVolume: 3097,
          organicRank: 182,

          keywordSales: 149
        },
        {
          date: '2024-07-06',
          searchVolume: 3097,
          organicRank: 181,
          sponsoredRank: 39,
          keywordSales: 149
        },
        {
          date: '2024-07-05',
          searchVolume: 3625,
          organicRank: 181,

          keywordSales: 149
        },
        {
          date: '2024-07-04',
          searchVolume: 3625,
          organicRank: 183,

          keywordSales: 149
        },
        {
          date: '2024-07-03',
          searchVolume: 3625,
          organicRank: 191,

          keywordSales: 149
        },
        {
          date: '2024-07-02',
          searchVolume: 3625,
          organicRank: 178,

          keywordSales: 149
        },
        {
          date: '2024-07-01',
          searchVolume: 3625,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-30',
          searchVolume: 3625,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-29',
          searchVolume: 3625,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-28',
          searchVolume: 3422,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-27',
          searchVolume: 3422,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-26',
          searchVolume: 3422,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-25',
          searchVolume: 3422,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-24',
          searchVolume: 3422,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-21',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-20',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 149
        },
        {
          date: '2024-06-19',
          searchVolume: 2816,
          organicRank: 306,
          sponsoredRank: 39,
          keywordSales: 149
        },
        {
          date: '2024-06-18',
          searchVolume: 2816,
          organicRank: 306,

          keywordSales: 149
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_monster zero sugar`,
      keyword: 'monster zero sugar',
      searchVolume: 10785,
      organicStartRank: 90,
      organicHighestRank: 80,
      sponsoredStartRank: 12,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-18',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 9967,
          organicRank: 117,

          keywordSales: 642
        },
        {
          date: '2024-08-06',
          searchVolume: 9967,
          organicRank: 119,

          keywordSales: 642
        },
        {
          date: '2024-08-05',
          searchVolume: 9967,
          organicRank: 143,

          keywordSales: 642
        },
        {
          date: '2024-08-04',
          searchVolume: 9967,
          organicRank: 306,

          keywordSales: 642
        },
        {
          date: '2024-08-03',
          searchVolume: 9967,
          organicRank: 134,

          keywordSales: 642
        },
        {
          date: '2024-08-01',
          searchVolume: 9967,
          organicRank: 135,

          keywordSales: 642
        },
        {
          date: '2024-07-31',
          searchVolume: 9967,
          organicRank: 148,

          keywordSales: 642
        },
        {
          date: '2024-07-30',
          searchVolume: 9967,
          organicRank: 126,

          keywordSales: 642
        },
        {
          date: '2024-07-29',
          searchVolume: 9967,
          organicRank: 98,

          keywordSales: 642
        },
        {
          date: '2024-07-28',
          searchVolume: 9967,
          organicRank: 94,

          keywordSales: 642
        },
        {
          date: '2024-07-27',
          searchVolume: 9967,
          organicRank: 80,

          keywordSales: 642
        },
        {
          date: '2024-07-26',
          searchVolume: 15880,
          organicRank: 93,

          keywordSales: 642
        },
        {
          date: '2024-07-25',
          searchVolume: 15880,
          organicRank: 90,

          keywordSales: 642
        },
        {
          date: '2024-07-24',
          searchVolume: 15880,
          organicRank: 108,
          sponsoredRank: 5,
          keywordSales: 642
        },
        {
          date: '2024-07-23',
          searchVolume: 15880,
          organicRank: 114,
          sponsoredRank: 2,
          keywordSales: 642
        },
        {
          date: '2024-07-22',
          searchVolume: 15880,
          organicRank: 107,

          keywordSales: 642
        },
        {
          date: '2024-07-21',
          searchVolume: 15880,
          organicRank: 105,

          keywordSales: 642
        },
        {
          date: '2024-07-20',
          searchVolume: 15880,
          organicRank: 108,

          keywordSales: 642
        },
        {
          date: '2024-07-19',
          searchVolume: 10386,
          organicRank: 121,

          keywordSales: 642
        },
        {
          date: '2024-07-18',
          searchVolume: 10386,
          organicRank: 112,
          sponsoredRank: 12,
          keywordSales: 642
        },
        {
          date: '2024-07-17',
          searchVolume: 10386,
          organicRank: 121,

          keywordSales: 642
        },
        {
          date: '2024-07-16',
          searchVolume: 10386,
          organicRank: 130,

          keywordSales: 642
        },
        {
          date: '2024-07-15',
          searchVolume: 10386,
          organicRank: 123,

          keywordSales: 642
        },
        {
          date: '2024-07-14',
          searchVolume: 10386,
          organicRank: 127,

          keywordSales: 642
        },
        {
          date: '2024-07-13',
          searchVolume: 10386,
          organicRank: 137,

          keywordSales: 642
        },
        {
          date: '2024-07-12',
          searchVolume: 10570,
          organicRank: 134,

          keywordSales: 642
        },
        {
          date: '2024-07-11',
          searchVolume: 10570,
          organicRank: 130,

          keywordSales: 642
        },
        {
          date: '2024-07-10',
          searchVolume: 10570,
          organicRank: 111,

          keywordSales: 642
        },
        {
          date: '2024-07-09',
          searchVolume: 10570,
          organicRank: 138,

          keywordSales: 642
        },
        {
          date: '2024-07-08',
          searchVolume: 10570,
          organicRank: 113,

          keywordSales: 642
        },
        {
          date: '2024-07-07',
          searchVolume: 10570,
          organicRank: 138,

          keywordSales: 642
        },
        {
          date: '2024-07-06',
          searchVolume: 10570,
          organicRank: 114,

          keywordSales: 642
        },
        {
          date: '2024-07-05',
          searchVolume: 11066,
          organicRank: 102,

          keywordSales: 642
        },
        {
          date: '2024-07-04',
          searchVolume: 11066,
          organicRank: 104,

          keywordSales: 642
        },
        {
          date: '2024-07-03',
          searchVolume: 11066,
          organicRank: 111,

          keywordSales: 642
        },
        {
          date: '2024-07-02',
          searchVolume: 11066,
          organicRank: 119,

          keywordSales: 642
        },
        {
          date: '2024-07-01',
          searchVolume: 11066,
          organicRank: 95,

          keywordSales: 642
        },
        {
          date: '2024-06-30',
          searchVolume: 11066,
          organicRank: 96,

          keywordSales: 642
        },
        {
          date: '2024-06-29',
          searchVolume: 11066,
          organicRank: 128,

          keywordSales: 642
        },
        {
          date: '2024-06-28',
          searchVolume: 10844,
          organicRank: 102,

          keywordSales: 642
        },
        {
          date: '2024-06-27',
          searchVolume: 10844,
          organicRank: 99,

          keywordSales: 642
        },
        {
          date: '2024-06-26',
          searchVolume: 10844,
          organicRank: 306,

          keywordSales: 642
        },
        {
          date: '2024-06-25',
          searchVolume: 10844,
          organicRank: 103,

          keywordSales: 642
        },
        {
          date: '2024-06-24',
          searchVolume: 10844,
          organicRank: 91,

          keywordSales: 642
        },
        {
          date: '2024-06-21',
          searchVolume: 10785,
          organicRank: 95,

          keywordSales: 642
        },
        {
          date: '2024-06-20',
          searchVolume: 10785,
          organicRank: 84,

          keywordSales: 642
        },
        {
          date: '2024-06-19',
          searchVolume: 10785,
          organicRank: 90,

          keywordSales: 642
        },
        {
          date: '2024-06-18',
          searchVolume: 10785,
          organicRank: 121,

          keywordSales: 642
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_naked energy`,
      keyword: 'naked energy',
      searchVolume: 2394,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 10,
      sponsoredHighestRank: 10,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-27',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-08-06',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-08-05',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-08-04',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-08-03',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-29',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-26',
          searchVolume: 2087,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-25',
          searchVolume: 2087,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-24',
          searchVolume: 2087,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-23',
          searchVolume: 2087,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-22',
          searchVolume: 2087,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-21',
          searchVolume: 2087,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-20',
          searchVolume: 2087,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-19',
          searchVolume: 2278,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-18',
          searchVolume: 2278,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-17',
          searchVolume: 2278,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-16',
          searchVolume: 2278,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-15',
          searchVolume: 2278,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-14',
          searchVolume: 2278,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-13',
          searchVolume: 2278,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-12',
          searchVolume: 1845,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-11',
          searchVolume: 1845,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-10',
          searchVolume: 1845,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-09',
          searchVolume: 1845,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-08',
          searchVolume: 1845,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-07',
          searchVolume: 1845,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-06',
          searchVolume: 1845,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-05',
          searchVolume: 1932,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-04',
          searchVolume: 1932,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-03',
          searchVolume: 1932,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-02',
          searchVolume: 1932,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-07-01',
          searchVolume: 1932,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-30',
          searchVolume: 1932,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-29',
          searchVolume: 1932,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-28',
          searchVolume: 2244,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-27',
          searchVolume: 2244,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-26',
          searchVolume: 2244,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-25',
          searchVolume: 2244,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-24',
          searchVolume: 2244,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-21',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-20',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-19',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 100
        },
        {
          date: '2024-06-18',
          searchVolume: 2394,
          organicRank: 306,

          keywordSales: 100
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_natural energy drinks`,
      keyword: 'natural energy drinks',
      searchVolume: 1926,
      organicStartRank: 9,
      organicHighestRank: 3,
      sponsoredStartRank: 28,
      sponsoredHighestRank: 10,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-01',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 1530,
          organicRank: 12,

          keywordSales: 13
        },
        {
          date: '2024-08-05',
          searchVolume: 1530,
          organicRank: 6,

          keywordSales: 13
        },
        {
          date: '2024-08-04',
          searchVolume: 1530,
          organicRank: 7,

          keywordSales: 13
        },
        {
          date: '2024-08-01',
          searchVolume: 1530,
          organicRank: 9,

          keywordSales: 13
        },
        {
          date: '2024-07-31',
          searchVolume: 1530,
          organicRank: 7,

          keywordSales: 13
        },
        {
          date: '2024-07-30',
          searchVolume: 1530,
          organicRank: 8,

          keywordSales: 13
        },
        {
          date: '2024-07-29',
          searchVolume: 1530,
          organicRank: 7,

          keywordSales: 13
        },
        {
          date: '2024-07-28',
          searchVolume: 1530,
          organicRank: 7,

          keywordSales: 13
        },
        {
          date: '2024-07-27',
          searchVolume: 1530,
          organicRank: 8,

          keywordSales: 13
        },
        {
          date: '2024-07-26',
          searchVolume: 1585,
          organicRank: 8,

          keywordSales: 13
        },
        {
          date: '2024-07-25',
          searchVolume: 1585,
          organicRank: 8,

          keywordSales: 13
        },
        {
          date: '2024-07-24',
          searchVolume: 1585,
          organicRank: 8,
          sponsoredRank: 43,
          keywordSales: 13
        },
        {
          date: '2024-07-23',
          searchVolume: 1585,
          organicRank: 8,

          keywordSales: 13
        },
        {
          date: '2024-07-22',
          searchVolume: 1585,
          organicRank: 8,
          sponsoredRank: 43,
          keywordSales: 13
        },
        {
          date: '2024-07-21',
          searchVolume: 1585,
          organicRank: 8,
          sponsoredRank: 60,
          keywordSales: 13
        },
        {
          date: '2024-07-20',
          searchVolume: 1585,
          organicRank: 7,
          sponsoredRank: 69,
          keywordSales: 13
        },
        {
          date: '2024-07-19',
          searchVolume: 1557,
          organicRank: 5,
          sponsoredRank: 31,
          keywordSales: 13
        },
        {
          date: '2024-07-18',
          searchVolume: 1557,
          organicRank: 6,
          sponsoredRank: 25,
          keywordSales: 13
        },
        {
          date: '2024-07-17',
          searchVolume: 1557,
          organicRank: 5,

          keywordSales: 13
        },
        {
          date: '2024-07-16',
          searchVolume: 1557,
          organicRank: 7,
          sponsoredRank: 68,
          keywordSales: 13
        },
        {
          date: '2024-07-15',
          searchVolume: 1557,
          organicRank: 7,

          keywordSales: 13
        },
        {
          date: '2024-07-14',
          searchVolume: 1557,
          organicRank: 6,
          sponsoredRank: 34,
          keywordSales: 13
        },
        {
          date: '2024-07-13',
          searchVolume: 1557,
          organicRank: 8,

          keywordSales: 13
        },
        {
          date: '2024-07-12',
          searchVolume: 1613,
          organicRank: 7,

          keywordSales: 13
        },
        {
          date: '2024-07-11',
          searchVolume: 1613,
          organicRank: 6,

          keywordSales: 13
        },
        {
          date: '2024-07-10',
          searchVolume: 1613,
          organicRank: 7,

          keywordSales: 13
        },
        {
          date: '2024-07-09',
          searchVolume: 1613,
          organicRank: 7,

          keywordSales: 13
        },
        {
          date: '2024-07-08',
          searchVolume: 1613,
          organicRank: 6,
          sponsoredRank: 30,
          keywordSales: 13
        },
        {
          date: '2024-07-07',
          searchVolume: 1613,
          organicRank: 6,
          sponsoredRank: 60,
          keywordSales: 13
        },
        {
          date: '2024-07-06',
          searchVolume: 1613,
          organicRank: 5,
          sponsoredRank: 12,
          keywordSales: 13
        },
        {
          date: '2024-07-05',
          searchVolume: 1610,
          organicRank: 7,
          sponsoredRank: 15,
          keywordSales: 13
        },
        {
          date: '2024-07-04',
          searchVolume: 1610,
          organicRank: 7,
          sponsoredRank: 16,
          keywordSales: 13
        },
        {
          date: '2024-07-03',
          searchVolume: 1610,
          organicRank: 8,

          keywordSales: 13
        },
        {
          date: '2024-07-02',
          searchVolume: 1610,
          organicRank: 6,

          keywordSales: 13
        },
        {
          date: '2024-07-01',
          searchVolume: 1610,
          organicRank: 3,
          sponsoredRank: 10,
          keywordSales: 13
        },
        {
          date: '2024-06-30',
          searchVolume: 1610,
          organicRank: 5,

          keywordSales: 13
        },
        {
          date: '2024-06-29',
          searchVolume: 1610,
          organicRank: 8,
          sponsoredRank: 36,
          keywordSales: 13
        },
        {
          date: '2024-06-28',
          searchVolume: 1917,
          organicRank: 5,
          sponsoredRank: 69,
          keywordSales: 13
        },
        {
          date: '2024-06-27',
          searchVolume: 1917,
          organicRank: 7,

          keywordSales: 13
        },
        {
          date: '2024-06-26',
          searchVolume: 1917,
          organicRank: 4,

          keywordSales: 13
        },
        {
          date: '2024-06-25',
          searchVolume: 1917,
          organicRank: 7,
          sponsoredRank: 67,
          keywordSales: 13
        },
        {
          date: '2024-06-24',
          searchVolume: 1917,
          organicRank: 6,

          keywordSales: 13
        },
        {
          date: '2024-06-21',
          searchVolume: 1926,
          organicRank: 3,

          keywordSales: 13
        },
        {
          date: '2024-06-20',
          searchVolume: 1926,
          organicRank: 7,
          sponsoredRank: 42,
          keywordSales: 13
        },
        {
          date: '2024-06-19',
          searchVolume: 1926,
          organicRank: 9,
          sponsoredRank: 28,
          keywordSales: 13
        },
        {
          date: '2024-06-18',
          searchVolume: 1926,
          organicRank: 9,
          sponsoredRank: 69,
          keywordSales: 13
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_nos energy drinks`,
      keyword: 'nos energy drinks',
      searchVolume: 2552,
      organicStartRank: 51,
      organicHighestRank: 24,
      sponsoredStartRank: 47,
      sponsoredHighestRank: 3,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-15',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2354,
          organicRank: 50,

          keywordSales: 114
        },
        {
          date: '2024-08-06',
          searchVolume: 2354,
          organicRank: 54,

          keywordSales: 114
        },
        {
          date: '2024-08-05',
          searchVolume: 2354,
          organicRank: 53,

          keywordSales: 114
        },
        {
          date: '2024-08-04',
          searchVolume: 2354,
          organicRank: 54,

          keywordSales: 114
        },
        {
          date: '2024-08-03',
          searchVolume: 2354,
          organicRank: 52,

          keywordSales: 114
        },
        {
          date: '2024-08-01',
          searchVolume: 2354,
          organicRank: 52,

          keywordSales: 114
        },
        {
          date: '2024-07-31',
          searchVolume: 2354,
          organicRank: 306,

          keywordSales: 114
        },
        {
          date: '2024-07-30',
          searchVolume: 2354,
          organicRank: 56,

          keywordSales: 114
        },
        {
          date: '2024-07-29',
          searchVolume: 2354,
          organicRank: 47,

          keywordSales: 114
        },
        {
          date: '2024-07-28',
          searchVolume: 2354,
          organicRank: 40,

          keywordSales: 114
        },
        {
          date: '2024-07-27',
          searchVolume: 2354,
          organicRank: 52,

          keywordSales: 114
        },
        {
          date: '2024-07-26',
          searchVolume: 2993,
          organicRank: 37,

          keywordSales: 114
        },
        {
          date: '2024-07-25',
          searchVolume: 2993,
          organicRank: 53,

          keywordSales: 114
        },
        {
          date: '2024-07-24',
          searchVolume: 2993,
          organicRank: 52,

          keywordSales: 114
        },
        {
          date: '2024-07-23',
          searchVolume: 2993,
          organicRank: 55,

          keywordSales: 114
        },
        {
          date: '2024-07-22',
          searchVolume: 2993,
          organicRank: 74,

          keywordSales: 114
        },
        {
          date: '2024-07-21',
          searchVolume: 2993,
          organicRank: 57,

          keywordSales: 114
        },
        {
          date: '2024-07-20',
          searchVolume: 2993,
          organicRank: 55,

          keywordSales: 114
        },
        {
          date: '2024-07-19',
          searchVolume: 2643,
          organicRank: 55,

          keywordSales: 114
        },
        {
          date: '2024-07-18',
          searchVolume: 2643,
          organicRank: 49,
          sponsoredRank: 35,
          keywordSales: 114
        },
        {
          date: '2024-07-17',
          searchVolume: 2643,
          organicRank: 56,

          keywordSales: 114
        },
        {
          date: '2024-07-16',
          searchVolume: 2643,
          organicRank: 57,
          sponsoredRank: 19,
          keywordSales: 114
        },
        {
          date: '2024-07-15',
          searchVolume: 2643,
          organicRank: 56,
          sponsoredRank: 3,
          keywordSales: 114
        },
        {
          date: '2024-07-14',
          searchVolume: 2643,
          organicRank: 52,

          keywordSales: 114
        },
        {
          date: '2024-07-13',
          searchVolume: 2643,
          organicRank: 39,

          keywordSales: 114
        },
        {
          date: '2024-07-12',
          searchVolume: 2989,
          organicRank: 38,

          keywordSales: 114
        },
        {
          date: '2024-07-11',
          searchVolume: 2989,
          organicRank: 54,

          keywordSales: 114
        },
        {
          date: '2024-07-10',
          searchVolume: 2989,
          organicRank: 47,

          keywordSales: 114
        },
        {
          date: '2024-07-09',
          searchVolume: 2989,
          organicRank: 35,
          sponsoredRank: 47,
          keywordSales: 114
        },
        {
          date: '2024-07-08',
          searchVolume: 2989,
          organicRank: 35,

          keywordSales: 114
        },
        {
          date: '2024-07-07',
          searchVolume: 2989,
          organicRank: 47,

          keywordSales: 114
        },
        {
          date: '2024-07-06',
          searchVolume: 2989,
          organicRank: 30,

          keywordSales: 114
        },
        {
          date: '2024-07-05',
          searchVolume: 2818,
          organicRank: 36,

          keywordSales: 114
        },
        {
          date: '2024-07-04',
          searchVolume: 2818,
          organicRank: 40,

          keywordSales: 114
        },
        {
          date: '2024-07-03',
          searchVolume: 2818,
          organicRank: 33,

          keywordSales: 114
        },
        {
          date: '2024-07-02',
          searchVolume: 2818,
          organicRank: 28,

          keywordSales: 114
        },
        {
          date: '2024-07-01',
          searchVolume: 2818,
          organicRank: 37,

          keywordSales: 114
        },
        {
          date: '2024-06-30',
          searchVolume: 2818,
          organicRank: 33,

          keywordSales: 114
        },
        {
          date: '2024-06-29',
          searchVolume: 2818,
          organicRank: 33,

          keywordSales: 114
        },
        {
          date: '2024-06-28',
          searchVolume: 2807,
          organicRank: 44,

          keywordSales: 114
        },
        {
          date: '2024-06-27',
          searchVolume: 2807,
          organicRank: 24,

          keywordSales: 114
        },
        {
          date: '2024-06-26',
          searchVolume: 2807,
          organicRank: 306,

          keywordSales: 114
        },
        {
          date: '2024-06-25',
          searchVolume: 2807,
          organicRank: 50,

          keywordSales: 114
        },
        {
          date: '2024-06-24',
          searchVolume: 2807,
          organicRank: 96,

          keywordSales: 114
        },
        {
          date: '2024-06-21',
          searchVolume: 2552,
          organicRank: 306,

          keywordSales: 114
        },
        {
          date: '2024-06-20',
          searchVolume: 2552,
          organicRank: 57,

          keywordSales: 114
        },
        {
          date: '2024-06-19',
          searchVolume: 2552,
          organicRank: 51,

          keywordSales: 114
        },
        {
          date: '2024-06-18',
          searchVolume: 2552,
          organicRank: 105,

          keywordSales: 114
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_organic energy drink`,
      keyword: 'organic energy drink',
      searchVolume: 1690,
      organicStartRank: 75,
      organicHighestRank: 31,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1321,
          organicRank: 69,

          keywordSales: 39
        },
        {
          date: '2024-08-06',
          searchVolume: 1321,
          organicRank: 40,

          keywordSales: 39
        },
        {
          date: '2024-08-05',
          searchVolume: 1321,
          organicRank: 37,

          keywordSales: 39
        },
        {
          date: '2024-08-04',
          searchVolume: 1321,
          organicRank: 58,

          keywordSales: 39
        },
        {
          date: '2024-08-03',
          searchVolume: 1321,
          organicRank: 62,

          keywordSales: 39
        },
        {
          date: '2024-08-01',
          searchVolume: 1321,
          organicRank: 68,

          keywordSales: 39
        },
        {
          date: '2024-07-31',
          searchVolume: 1321,
          organicRank: 38,

          keywordSales: 39
        },
        {
          date: '2024-07-30',
          searchVolume: 1321,
          organicRank: 51,

          keywordSales: 39
        },
        {
          date: '2024-07-29',
          searchVolume: 1321,
          organicRank: 38,

          keywordSales: 39
        },
        {
          date: '2024-07-28',
          searchVolume: 1321,
          organicRank: 65,

          keywordSales: 39
        },
        {
          date: '2024-07-27',
          searchVolume: 1321,
          organicRank: 65,

          keywordSales: 39
        },
        {
          date: '2024-07-26',
          searchVolume: 1367,
          organicRank: 63,

          keywordSales: 39
        },
        {
          date: '2024-07-25',
          searchVolume: 1367,
          organicRank: 39,

          keywordSales: 39
        },
        {
          date: '2024-07-24',
          searchVolume: 1367,
          organicRank: 32,

          keywordSales: 39
        },
        {
          date: '2024-07-23',
          searchVolume: 1367,
          organicRank: 31,

          keywordSales: 39
        },
        {
          date: '2024-07-22',
          searchVolume: 1367,
          organicRank: 31,

          keywordSales: 39
        },
        {
          date: '2024-07-21',
          searchVolume: 1367,
          organicRank: 68,

          keywordSales: 39
        },
        {
          date: '2024-07-20',
          searchVolume: 1367,
          organicRank: 36,

          keywordSales: 39
        },
        {
          date: '2024-07-19',
          searchVolume: 1344,
          organicRank: 55,

          keywordSales: 39
        },
        {
          date: '2024-07-18',
          searchVolume: 1344,
          organicRank: 54,

          keywordSales: 39
        },
        {
          date: '2024-07-17',
          searchVolume: 1344,
          organicRank: 306,

          keywordSales: 39
        },
        {
          date: '2024-07-16',
          searchVolume: 1344,
          organicRank: 33,

          keywordSales: 39
        },
        {
          date: '2024-07-15',
          searchVolume: 1344,
          organicRank: 37,

          keywordSales: 39
        },
        {
          date: '2024-07-14',
          searchVolume: 1344,
          organicRank: 41,

          keywordSales: 39
        },
        {
          date: '2024-07-13',
          searchVolume: 1344,
          organicRank: 38,

          keywordSales: 39
        },
        {
          date: '2024-07-12',
          searchVolume: 1370,
          organicRank: 38,

          keywordSales: 39
        },
        {
          date: '2024-07-11',
          searchVolume: 1370,
          organicRank: 40,

          keywordSales: 39
        },
        {
          date: '2024-07-10',
          searchVolume: 1370,
          organicRank: 37,

          keywordSales: 39
        },
        {
          date: '2024-07-09',
          searchVolume: 1370,
          organicRank: 48,

          keywordSales: 39
        },
        {
          date: '2024-07-08',
          searchVolume: 1370,
          organicRank: 40,

          keywordSales: 39
        },
        {
          date: '2024-07-07',
          searchVolume: 1370,
          organicRank: 38,

          keywordSales: 39
        },
        {
          date: '2024-07-06',
          searchVolume: 1370,
          organicRank: 37,

          keywordSales: 39
        },
        {
          date: '2024-07-05',
          searchVolume: 1433,
          organicRank: 37,

          keywordSales: 39
        },
        {
          date: '2024-07-04',
          searchVolume: 1433,
          organicRank: 41,

          keywordSales: 39
        },
        {
          date: '2024-07-03',
          searchVolume: 1433,
          organicRank: 46,

          keywordSales: 39
        },
        {
          date: '2024-07-02',
          searchVolume: 1433,
          organicRank: 42,

          keywordSales: 39
        },
        {
          date: '2024-07-01',
          searchVolume: 1433,
          organicRank: 33,

          keywordSales: 39
        },
        {
          date: '2024-06-30',
          searchVolume: 1433,
          organicRank: 32,

          keywordSales: 39
        },
        {
          date: '2024-06-29',
          searchVolume: 1433,
          organicRank: 31,

          keywordSales: 39
        },
        {
          date: '2024-06-28',
          searchVolume: 1652,
          organicRank: 46,

          keywordSales: 39
        },
        {
          date: '2024-06-27',
          searchVolume: 1652,
          organicRank: 32,

          keywordSales: 39
        },
        {
          date: '2024-06-26',
          searchVolume: 1652,
          organicRank: 48,

          keywordSales: 39
        },
        {
          date: '2024-06-25',
          searchVolume: 1652,
          organicRank: 68,

          keywordSales: 39
        },
        {
          date: '2024-06-21',
          searchVolume: 1690,
          organicRank: 63,

          keywordSales: 39
        },
        {
          date: '2024-06-20',
          searchVolume: 1690,
          organicRank: 70,

          keywordSales: 39
        },
        {
          date: '2024-06-19',
          searchVolume: 1690,
          organicRank: 75,

          keywordSales: 39
        },
        {
          date: '2024-06-18',
          searchVolume: 1690,
          organicRank: 99,

          keywordSales: 39
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_passion fruit juice`,
      keyword: 'passion fruit juice',
      searchVolume: 3470,
      organicStartRank: 228,
      organicHighestRank: 173,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2894,
          organicRank: 184,

          keywordSales: 111
        },
        {
          date: '2024-08-06',
          searchVolume: 2894,
          organicRank: 188,

          keywordSales: 111
        },
        {
          date: '2024-08-05',
          searchVolume: 2894,
          organicRank: 187,

          keywordSales: 111
        },
        {
          date: '2024-08-04',
          searchVolume: 2894,
          organicRank: 187,

          keywordSales: 111
        },
        {
          date: '2024-08-03',
          searchVolume: 2894,
          organicRank: 203,

          keywordSales: 111
        },
        {
          date: '2024-08-02',
          searchVolume: 2894,
          organicRank: 196,

          keywordSales: 111
        },
        {
          date: '2024-08-01',
          searchVolume: 2894,
          organicRank: 192,

          keywordSales: 111
        },
        {
          date: '2024-07-31',
          searchVolume: 2894,
          organicRank: 191,

          keywordSales: 111
        },
        {
          date: '2024-07-30',
          searchVolume: 2894,
          organicRank: 194,

          keywordSales: 111
        },
        {
          date: '2024-07-28',
          searchVolume: 2894,
          organicRank: 306,

          keywordSales: 111
        },
        {
          date: '2024-07-27',
          searchVolume: 2894,
          organicRank: 202,

          keywordSales: 111
        },
        {
          date: '2024-07-26',
          searchVolume: 2321,
          organicRank: 204,

          keywordSales: 111
        },
        {
          date: '2024-07-25',
          searchVolume: 2321,
          organicRank: 270,

          keywordSales: 111
        },
        {
          date: '2024-07-24',
          searchVolume: 2321,
          organicRank: 173,

          keywordSales: 111
        },
        {
          date: '2024-07-23',
          searchVolume: 2321,
          organicRank: 187,

          keywordSales: 111
        },
        {
          date: '2024-07-22',
          searchVolume: 2321,
          organicRank: 277,

          keywordSales: 111
        },
        {
          date: '2024-07-21',
          searchVolume: 2321,
          organicRank: 173,

          keywordSales: 111
        },
        {
          date: '2024-07-20',
          searchVolume: 2321,
          organicRank: 178,

          keywordSales: 111
        },
        {
          date: '2024-07-19',
          searchVolume: 3042,
          organicRank: 224,

          keywordSales: 111
        },
        {
          date: '2024-07-18',
          searchVolume: 3042,
          organicRank: 306,

          keywordSales: 111
        },
        {
          date: '2024-07-17',
          searchVolume: 3042,
          organicRank: 226,

          keywordSales: 111
        },
        {
          date: '2024-07-16',
          searchVolume: 3042,
          organicRank: 227,

          keywordSales: 111
        },
        {
          date: '2024-07-15',
          searchVolume: 3042,
          organicRank: 267,

          keywordSales: 111
        },
        {
          date: '2024-07-14',
          searchVolume: 3042,
          organicRank: 306,

          keywordSales: 111
        },
        {
          date: '2024-07-13',
          searchVolume: 3042,
          organicRank: 233,

          keywordSales: 111
        },
        {
          date: '2024-07-12',
          searchVolume: 3486,
          organicRank: 239,

          keywordSales: 111
        },
        {
          date: '2024-07-11',
          searchVolume: 3486,
          organicRank: 245,

          keywordSales: 111
        },
        {
          date: '2024-07-10',
          searchVolume: 3486,
          organicRank: 259,

          keywordSales: 111
        },
        {
          date: '2024-07-09',
          searchVolume: 3486,
          organicRank: 252,

          keywordSales: 111
        },
        {
          date: '2024-07-08',
          searchVolume: 3486,
          organicRank: 264,

          keywordSales: 111
        },
        {
          date: '2024-07-07',
          searchVolume: 3486,
          organicRank: 226,

          keywordSales: 111
        },
        {
          date: '2024-07-06',
          searchVolume: 3486,
          organicRank: 240,

          keywordSales: 111
        },
        {
          date: '2024-07-05',
          searchVolume: 3459,
          organicRank: 235,

          keywordSales: 111
        },
        {
          date: '2024-07-04',
          searchVolume: 3459,
          organicRank: 242,

          keywordSales: 111
        },
        {
          date: '2024-07-03',
          searchVolume: 3459,
          organicRank: 245,

          keywordSales: 111
        },
        {
          date: '2024-07-02',
          searchVolume: 3459,
          organicRank: 256,

          keywordSales: 111
        },
        {
          date: '2024-07-01',
          searchVolume: 3459,
          organicRank: 240,

          keywordSales: 111
        },
        {
          date: '2024-06-30',
          searchVolume: 3459,
          organicRank: 266,

          keywordSales: 111
        },
        {
          date: '2024-06-29',
          searchVolume: 3459,
          organicRank: 306,

          keywordSales: 111
        },
        {
          date: '2024-06-28',
          searchVolume: 3431,
          organicRank: 241,

          keywordSales: 111
        },
        {
          date: '2024-06-27',
          searchVolume: 3431,
          organicRank: 223,

          keywordSales: 111
        },
        {
          date: '2024-06-26',
          searchVolume: 3431,
          organicRank: 235,

          keywordSales: 111
        },
        {
          date: '2024-06-25',
          searchVolume: 3431,
          organicRank: 227,

          keywordSales: 111
        },
        {
          date: '2024-06-24',
          searchVolume: 3431,
          organicRank: 229,

          keywordSales: 111
        },
        {
          date: '2024-06-21',
          searchVolume: 3470,
          organicRank: 232,

          keywordSales: 111
        },
        {
          date: '2024-06-20',
          searchVolume: 3470,
          organicRank: 222,

          keywordSales: 111
        },
        {
          date: '2024-06-19',
          searchVolume: 3470,
          organicRank: 228,

          keywordSales: 111
        },
        {
          date: '2024-06-18',
          searchVolume: 3470,
          organicRank: 238,

          keywordSales: 111
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_prime drink`,
      keyword: 'prime drink',
      searchVolume: 4324,
      organicStartRank: 306,
      organicHighestRank: 32,
      sponsoredStartRank: 30,
      sponsoredHighestRank: 30,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 6232,
          organicRank: 47,

          keywordSales: 103
        },
        {
          date: '2024-08-05',
          searchVolume: 6232,
          organicRank: 48,

          keywordSales: 103
        },
        {
          date: '2024-08-04',
          searchVolume: 6232,
          organicRank: 55,

          keywordSales: 103
        },
        {
          date: '2024-08-02',
          searchVolume: 6232,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-08-01',
          searchVolume: 6232,
          organicRank: 61,

          keywordSales: 103
        },
        {
          date: '2024-07-31',
          searchVolume: 6232,
          organicRank: 60,

          keywordSales: 103
        },
        {
          date: '2024-07-30',
          searchVolume: 6232,
          organicRank: 80,

          keywordSales: 103
        },
        {
          date: '2024-07-28',
          searchVolume: 6232,
          organicRank: 56,

          keywordSales: 103
        },
        {
          date: '2024-07-27',
          searchVolume: 6232,
          organicRank: 53,

          keywordSales: 103
        },
        {
          date: '2024-07-26',
          searchVolume: 8095,
          organicRank: 53,

          keywordSales: 103
        },
        {
          date: '2024-07-25',
          searchVolume: 8095,
          organicRank: 47,

          keywordSales: 103
        },
        {
          date: '2024-07-24',
          searchVolume: 8095,
          organicRank: 76,

          keywordSales: 103
        },
        {
          date: '2024-07-23',
          searchVolume: 8095,
          organicRank: 83,

          keywordSales: 103
        },
        {
          date: '2024-07-22',
          searchVolume: 8095,
          organicRank: 80,

          keywordSales: 103
        },
        {
          date: '2024-07-21',
          searchVolume: 8095,
          organicRank: 92,

          keywordSales: 103
        },
        {
          date: '2024-07-20',
          searchVolume: 8095,
          organicRank: 74,

          keywordSales: 103
        },
        {
          date: '2024-07-19',
          searchVolume: 6331,
          organicRank: 72,

          keywordSales: 103
        },
        {
          date: '2024-07-18',
          searchVolume: 6331,
          organicRank: 52,
          sponsoredRank: 30,
          keywordSales: 103
        },
        {
          date: '2024-07-17',
          searchVolume: 6331,
          organicRank: 90,

          keywordSales: 103
        },
        {
          date: '2024-07-16',
          searchVolume: 6331,
          organicRank: 72,

          keywordSales: 103
        },
        {
          date: '2024-07-15',
          searchVolume: 6331,
          organicRank: 74,

          keywordSales: 103
        },
        {
          date: '2024-07-14',
          searchVolume: 6331,
          organicRank: 70,

          keywordSales: 103
        },
        {
          date: '2024-07-13',
          searchVolume: 6331,
          organicRank: 102,

          keywordSales: 103
        },
        {
          date: '2024-07-12',
          searchVolume: 5551,
          organicRank: 98,

          keywordSales: 103
        },
        {
          date: '2024-07-11',
          searchVolume: 5551,
          organicRank: 42,

          keywordSales: 103
        },
        {
          date: '2024-07-10',
          searchVolume: 5551,
          organicRank: 77,

          keywordSales: 103
        },
        {
          date: '2024-07-09',
          searchVolume: 5551,
          organicRank: 48,

          keywordSales: 103
        },
        {
          date: '2024-07-08',
          searchVolume: 5551,
          organicRank: 57,

          keywordSales: 103
        },
        {
          date: '2024-07-07',
          searchVolume: 5551,
          organicRank: 62,

          keywordSales: 103
        },
        {
          date: '2024-07-06',
          searchVolume: 5551,
          organicRank: 57,

          keywordSales: 103
        },
        {
          date: '2024-07-05',
          searchVolume: 3890,
          organicRank: 46,

          keywordSales: 103
        },
        {
          date: '2024-07-04',
          searchVolume: 3890,
          organicRank: 58,

          keywordSales: 103
        },
        {
          date: '2024-07-03',
          searchVolume: 3890,
          organicRank: 48,

          keywordSales: 103
        },
        {
          date: '2024-07-02',
          searchVolume: 3890,
          organicRank: 46,

          keywordSales: 103
        },
        {
          date: '2024-07-01',
          searchVolume: 3890,
          organicRank: 46,

          keywordSales: 103
        },
        {
          date: '2024-06-30',
          searchVolume: 3890,
          organicRank: 64,

          keywordSales: 103
        },
        {
          date: '2024-06-29',
          searchVolume: 3890,
          organicRank: 55,

          keywordSales: 103
        },
        {
          date: '2024-06-28',
          searchVolume: 4309,
          organicRank: 48,

          keywordSales: 103
        },
        {
          date: '2024-06-27',
          searchVolume: 4309,
          organicRank: 50,

          keywordSales: 103
        },
        {
          date: '2024-06-26',
          searchVolume: 4309,
          organicRank: 59,

          keywordSales: 103
        },
        {
          date: '2024-06-25',
          searchVolume: 4309,
          organicRank: 57,

          keywordSales: 103
        },
        {
          date: '2024-06-21',
          searchVolume: 4324,
          organicRank: 32,

          keywordSales: 103
        },
        {
          date: '2024-06-20',
          searchVolume: 4324,
          organicRank: 58,

          keywordSales: 103
        },
        {
          date: '2024-06-19',
          searchVolume: 4324,
          organicRank: 306,

          keywordSales: 103
        },
        {
          date: '2024-06-18',
          searchVolume: 4324,
          organicRank: 48,

          keywordSales: 103
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_prime drinks`,
      keyword: 'prime drinks',
      searchVolume: 1928,
      organicStartRank: 100,
      organicHighestRank: 31,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1780,
          organicRank: 43,

          keywordSales: 49
        },
        {
          date: '2024-08-06',
          searchVolume: 1780,
          organicRank: 63,

          keywordSales: 49
        },
        {
          date: '2024-08-05',
          searchVolume: 1780,
          organicRank: 64,

          keywordSales: 49
        },
        {
          date: '2024-08-04',
          searchVolume: 1780,
          organicRank: 71,

          keywordSales: 49
        },
        {
          date: '2024-08-03',
          searchVolume: 1780,
          organicRank: 148,

          keywordSales: 49
        },
        {
          date: '2024-08-01',
          searchVolume: 1780,
          organicRank: 60,

          keywordSales: 49
        },
        {
          date: '2024-07-31',
          searchVolume: 1780,
          organicRank: 66,

          keywordSales: 49
        },
        {
          date: '2024-07-30',
          searchVolume: 1780,
          organicRank: 57,

          keywordSales: 49
        },
        {
          date: '2024-07-29',
          searchVolume: 1780,
          organicRank: 73,

          keywordSales: 49
        },
        {
          date: '2024-07-28',
          searchVolume: 1780,
          organicRank: 66,

          keywordSales: 49
        },
        {
          date: '2024-07-27',
          searchVolume: 1780,
          organicRank: 71,

          keywordSales: 49
        },
        {
          date: '2024-07-26',
          searchVolume: 2318,
          organicRank: 36,

          keywordSales: 49
        },
        {
          date: '2024-07-25',
          searchVolume: 2318,
          organicRank: 54,

          keywordSales: 49
        },
        {
          date: '2024-07-24',
          searchVolume: 2318,
          organicRank: 39,

          keywordSales: 49
        },
        {
          date: '2024-07-23',
          searchVolume: 2318,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-07-22',
          searchVolume: 2318,
          organicRank: 68,

          keywordSales: 49
        },
        {
          date: '2024-07-21',
          searchVolume: 2318,
          organicRank: 64,

          keywordSales: 49
        },
        {
          date: '2024-07-20',
          searchVolume: 2318,
          organicRank: 91,

          keywordSales: 49
        },
        {
          date: '2024-07-19',
          searchVolume: 1797,
          organicRank: 68,

          keywordSales: 49
        },
        {
          date: '2024-07-18',
          searchVolume: 1797,
          organicRank: 52,

          keywordSales: 49
        },
        {
          date: '2024-07-17',
          searchVolume: 1797,
          organicRank: 60,

          keywordSales: 49
        },
        {
          date: '2024-07-16',
          searchVolume: 1797,
          organicRank: 93,

          keywordSales: 49
        },
        {
          date: '2024-07-15',
          searchVolume: 1797,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-07-14',
          searchVolume: 1797,
          organicRank: 97,

          keywordSales: 49
        },
        {
          date: '2024-07-13',
          searchVolume: 1797,
          organicRank: 84,

          keywordSales: 49
        },
        {
          date: '2024-07-12',
          searchVolume: 1616,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-07-11',
          searchVolume: 1616,
          organicRank: 47,

          keywordSales: 49
        },
        {
          date: '2024-07-10',
          searchVolume: 1616,
          organicRank: 60,

          keywordSales: 49
        },
        {
          date: '2024-07-09',
          searchVolume: 1616,
          organicRank: 50,

          keywordSales: 49
        },
        {
          date: '2024-07-08',
          searchVolume: 1616,
          organicRank: 56,

          keywordSales: 49
        },
        {
          date: '2024-07-07',
          searchVolume: 1616,
          organicRank: 63,

          keywordSales: 49
        },
        {
          date: '2024-07-06',
          searchVolume: 1616,
          organicRank: 73,

          keywordSales: 49
        },
        {
          date: '2024-07-05',
          searchVolume: 1658,
          organicRank: 56,

          keywordSales: 49
        },
        {
          date: '2024-07-04',
          searchVolume: 1658,
          organicRank: 62,

          keywordSales: 49
        },
        {
          date: '2024-07-03',
          searchVolume: 1658,
          organicRank: 58,

          keywordSales: 49
        },
        {
          date: '2024-07-02',
          searchVolume: 1658,
          organicRank: 53,

          keywordSales: 49
        },
        {
          date: '2024-07-01',
          searchVolume: 1658,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-30',
          searchVolume: 1658,
          organicRank: 46,

          keywordSales: 49
        },
        {
          date: '2024-06-29',
          searchVolume: 1658,
          organicRank: 49,

          keywordSales: 49
        },
        {
          date: '2024-06-28',
          searchVolume: 1915,
          organicRank: 39,

          keywordSales: 49
        },
        {
          date: '2024-06-27',
          searchVolume: 1915,
          organicRank: 306,

          keywordSales: 49
        },
        {
          date: '2024-06-26',
          searchVolume: 1915,
          organicRank: 31,

          keywordSales: 49
        },
        {
          date: '2024-06-25',
          searchVolume: 1915,
          organicRank: 52,

          keywordSales: 49
        },
        {
          date: '2024-06-24',
          searchVolume: 1915,
          organicRank: 56,

          keywordSales: 49
        },
        {
          date: '2024-06-21',
          searchVolume: 1928,
          organicRank: 38,

          keywordSales: 49
        },
        {
          date: '2024-06-20',
          searchVolume: 1928,
          organicRank: 54,

          keywordSales: 49
        },
        {
          date: '2024-06-19',
          searchVolume: 1928,
          organicRank: 100,

          keywordSales: 49
        },
        {
          date: '2024-06-18',
          searchVolume: 1928,
          organicRank: 73,

          keywordSales: 49
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_prime energy`,
      keyword: 'prime energy',
      searchVolume: 2251,
      organicStartRank: 9,
      organicHighestRank: 4,
      sponsoredStartRank: 8,
      sponsoredHighestRank: 8,
      organicPageOneWinDate: '2024-06-26',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-13',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1782,
          organicRank: 8,

          keywordSales: 78
        },
        {
          date: '2024-08-06',
          searchVolume: 1782,
          organicRank: 12,

          keywordSales: 78
        },
        {
          date: '2024-08-05',
          searchVolume: 1782,
          organicRank: 9,

          keywordSales: 78
        },
        {
          date: '2024-08-04',
          searchVolume: 1782,
          organicRank: 12,

          keywordSales: 78
        },
        {
          date: '2024-08-03',
          searchVolume: 1782,
          organicRank: 17,

          keywordSales: 78
        },
        {
          date: '2024-08-01',
          searchVolume: 1782,
          organicRank: 12,

          keywordSales: 78
        },
        {
          date: '2024-07-31',
          searchVolume: 1782,
          organicRank: 14,

          keywordSales: 78
        },
        {
          date: '2024-07-30',
          searchVolume: 1782,
          organicRank: 12,

          keywordSales: 78
        },
        {
          date: '2024-07-29',
          searchVolume: 1782,
          organicRank: 26,

          keywordSales: 78
        },
        {
          date: '2024-07-28',
          searchVolume: 1782,
          organicRank: 8,

          keywordSales: 78
        },
        {
          date: '2024-07-27',
          searchVolume: 1782,
          organicRank: 8,

          keywordSales: 78
        },
        {
          date: '2024-07-26',
          searchVolume: 2435,
          organicRank: 24,

          keywordSales: 78
        },
        {
          date: '2024-07-25',
          searchVolume: 2435,
          organicRank: 30,

          keywordSales: 78
        },
        {
          date: '2024-07-24',
          searchVolume: 2435,
          organicRank: 64,

          keywordSales: 78
        },
        {
          date: '2024-07-23',
          searchVolume: 2435,
          organicRank: 64,

          keywordSales: 78
        },
        {
          date: '2024-07-22',
          searchVolume: 2435,
          organicRank: 41,

          keywordSales: 78
        },
        {
          date: '2024-07-21',
          searchVolume: 2435,
          organicRank: 37,

          keywordSales: 78
        },
        {
          date: '2024-07-20',
          searchVolume: 2435,
          organicRank: 31,

          keywordSales: 78
        },
        {
          date: '2024-07-19',
          searchVolume: 1810,
          organicRank: 29,

          keywordSales: 78
        },
        {
          date: '2024-07-18',
          searchVolume: 1810,
          organicRank: 23,

          keywordSales: 78
        },
        {
          date: '2024-07-17',
          searchVolume: 1810,
          organicRank: 15,

          keywordSales: 78
        },
        {
          date: '2024-07-16',
          searchVolume: 1810,
          organicRank: 22,

          keywordSales: 78
        },
        {
          date: '2024-07-15',
          searchVolume: 1810,
          organicRank: 44,

          keywordSales: 78
        },
        {
          date: '2024-07-14',
          searchVolume: 1810,
          organicRank: 44,

          keywordSales: 78
        },
        {
          date: '2024-07-13',
          searchVolume: 1810,
          organicRank: 50,
          sponsoredRank: 8,
          keywordSales: 78
        },
        {
          date: '2024-07-12',
          searchVolume: 1794,
          organicRank: 4,

          keywordSales: 78
        },
        {
          date: '2024-07-11',
          searchVolume: 1794,
          organicRank: 8,

          keywordSales: 78
        },
        {
          date: '2024-07-10',
          searchVolume: 1794,
          organicRank: 5,

          keywordSales: 78
        },
        {
          date: '2024-07-09',
          searchVolume: 1794,
          organicRank: 24,

          keywordSales: 78
        },
        {
          date: '2024-07-08',
          searchVolume: 1794,
          organicRank: 7,

          keywordSales: 78
        },
        {
          date: '2024-07-07',
          searchVolume: 1794,
          organicRank: 29,

          keywordSales: 78
        },
        {
          date: '2024-07-06',
          searchVolume: 1794,
          organicRank: 8,

          keywordSales: 78
        },
        {
          date: '2024-07-05',
          searchVolume: 1928,
          organicRank: 7,

          keywordSales: 78
        },
        {
          date: '2024-07-04',
          searchVolume: 1928,
          organicRank: 5,

          keywordSales: 78
        },
        {
          date: '2024-07-03',
          searchVolume: 1928,
          organicRank: 6,

          keywordSales: 78
        },
        {
          date: '2024-07-02',
          searchVolume: 1928,
          organicRank: 7,

          keywordSales: 78
        },
        {
          date: '2024-07-01',
          searchVolume: 1928,
          organicRank: 7,

          keywordSales: 78
        },
        {
          date: '2024-06-30',
          searchVolume: 1928,
          organicRank: 4,

          keywordSales: 78
        },
        {
          date: '2024-06-29',
          searchVolume: 1928,
          organicRank: 5,

          keywordSales: 78
        },
        {
          date: '2024-06-28',
          searchVolume: 2165,
          organicRank: 4,

          keywordSales: 78
        },
        {
          date: '2024-06-27',
          searchVolume: 2165,
          organicRank: 27,

          keywordSales: 78
        },
        {
          date: '2024-06-26',
          searchVolume: 2165,
          organicRank: 4,

          keywordSales: 78
        },
        {
          date: '2024-06-25',
          searchVolume: 2165,
          organicRank: 27,

          keywordSales: 78
        },
        {
          date: '2024-06-24',
          searchVolume: 2165,
          organicRank: 33,

          keywordSales: 78
        },
        {
          date: '2024-06-21',
          searchVolume: 2251,
          organicRank: 44,

          keywordSales: 78
        },
        {
          date: '2024-06-20',
          searchVolume: 2251,
          organicRank: 7,

          keywordSales: 78
        },
        {
          date: '2024-06-19',
          searchVolume: 2251,
          organicRank: 9,

          keywordSales: 78
        },
        {
          date: '2024-06-18',
          searchVolume: 2251,
          organicRank: 14,

          keywordSales: 78
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_prime energy drink`,
      keyword: 'prime energy drink',
      searchVolume: 5698,
      organicStartRank: 7,
      organicHighestRank: 3,
      sponsoredStartRank: 39,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-06-21',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-21',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 5260,
          organicRank: 7,

          keywordSales: 246
        },
        {
          date: '2024-08-05',
          searchVolume: 5260,
          organicRank: 8,

          keywordSales: 246
        },
        {
          date: '2024-08-04',
          searchVolume: 5260,
          organicRank: 7,

          keywordSales: 246
        },
        {
          date: '2024-08-03',
          searchVolume: 5260,
          organicRank: 5,

          keywordSales: 246
        },
        {
          date: '2024-08-02',
          searchVolume: 5260,
          organicRank: 8,

          keywordSales: 246
        },
        {
          date: '2024-08-01',
          searchVolume: 5260,
          organicRank: 4,

          keywordSales: 246
        },
        {
          date: '2024-07-31',
          searchVolume: 5260,
          organicRank: 5,

          keywordSales: 246
        },
        {
          date: '2024-07-30',
          searchVolume: 5260,
          organicRank: 6,
          sponsoredRank: 71,
          keywordSales: 246
        },
        {
          date: '2024-07-29',
          searchVolume: 5260,
          organicRank: 6,

          keywordSales: 246
        },
        {
          date: '2024-07-28',
          searchVolume: 5260,
          organicRank: 6,

          keywordSales: 246
        },
        {
          date: '2024-07-27',
          searchVolume: 5260,
          organicRank: 9,

          keywordSales: 246
        },
        {
          date: '2024-07-26',
          searchVolume: 7239,
          organicRank: 7,
          sponsoredRank: 50,
          keywordSales: 246
        },
        {
          date: '2024-07-25',
          searchVolume: 7239,
          organicRank: 14,

          keywordSales: 246
        },
        {
          date: '2024-07-24',
          searchVolume: 7239,
          organicRank: 19,

          keywordSales: 246
        },
        {
          date: '2024-07-23',
          searchVolume: 7239,
          organicRank: 34,
          sponsoredRank: 34,
          keywordSales: 246
        },
        {
          date: '2024-07-22',
          searchVolume: 7239,
          organicRank: 20,
          sponsoredRank: 35,
          keywordSales: 246
        },
        {
          date: '2024-07-21',
          searchVolume: 7239,
          organicRank: 20,
          sponsoredRank: 36,
          keywordSales: 246
        },
        {
          date: '2024-07-20',
          searchVolume: 7239,
          organicRank: 23,
          sponsoredRank: 47,
          keywordSales: 246
        },
        {
          date: '2024-07-19',
          searchVolume: 5346,
          organicRank: 18,
          sponsoredRank: 36,
          keywordSales: 246
        },
        {
          date: '2024-07-18',
          searchVolume: 5346,
          organicRank: 17,

          keywordSales: 246
        },
        {
          date: '2024-07-17',
          searchVolume: 5346,
          organicRank: 6,

          keywordSales: 246
        },
        {
          date: '2024-07-16',
          searchVolume: 5346,
          organicRank: 7,

          keywordSales: 246
        },
        {
          date: '2024-07-15',
          searchVolume: 5346,
          organicRank: 6,

          keywordSales: 246
        },
        {
          date: '2024-07-14',
          searchVolume: 5346,
          organicRank: 8,

          keywordSales: 246
        },
        {
          date: '2024-07-13',
          searchVolume: 5346,
          organicRank: 6,
          sponsoredRank: 34,
          keywordSales: 246
        },
        {
          date: '2024-07-12',
          searchVolume: 5184,
          organicRank: 6,

          keywordSales: 246
        },
        {
          date: '2024-07-11',
          searchVolume: 5184,
          organicRank: 8,

          keywordSales: 246
        },
        {
          date: '2024-07-10',
          searchVolume: 5184,
          organicRank: 8,

          keywordSales: 246
        },
        {
          date: '2024-07-09',
          searchVolume: 5184,
          organicRank: 5,

          keywordSales: 246
        },
        {
          date: '2024-07-08',
          searchVolume: 5184,
          organicRank: 7,

          keywordSales: 246
        },
        {
          date: '2024-07-07',
          searchVolume: 5184,
          organicRank: 5,

          keywordSales: 246
        },
        {
          date: '2024-07-06',
          searchVolume: 5184,
          organicRank: 3,

          keywordSales: 246
        },
        {
          date: '2024-07-05',
          searchVolume: 6007,
          organicRank: 4,

          keywordSales: 246
        },
        {
          date: '2024-07-04',
          searchVolume: 6007,
          organicRank: 7,

          keywordSales: 246
        },
        {
          date: '2024-07-03',
          searchVolume: 6007,
          organicRank: 7,

          keywordSales: 246
        },
        {
          date: '2024-07-02',
          searchVolume: 6007,
          organicRank: 6,

          keywordSales: 246
        },
        {
          date: '2024-07-01',
          searchVolume: 6007,
          organicRank: 6,

          keywordSales: 246
        },
        {
          date: '2024-06-30',
          searchVolume: 6007,
          organicRank: 6,

          keywordSales: 246
        },
        {
          date: '2024-06-29',
          searchVolume: 6007,
          organicRank: 13,
          sponsoredRank: 14,
          keywordSales: 246
        },
        {
          date: '2024-06-28',
          searchVolume: 5673,
          organicRank: 5,

          keywordSales: 246
        },
        {
          date: '2024-06-27',
          searchVolume: 5673,
          organicRank: 8,

          keywordSales: 246
        },
        {
          date: '2024-06-26',
          searchVolume: 5673,
          organicRank: 10,

          keywordSales: 246
        },
        {
          date: '2024-06-25',
          searchVolume: 5673,
          organicRank: 5,

          keywordSales: 246
        },
        {
          date: '2024-06-24',
          searchVolume: 5673,
          organicRank: 5,

          keywordSales: 246
        },
        {
          date: '2024-06-21',
          searchVolume: 5698,
          organicRank: 12,
          sponsoredRank: 1,
          keywordSales: 246
        },
        {
          date: '2024-06-20',
          searchVolume: 5698,
          organicRank: 21,
          sponsoredRank: 21,
          keywordSales: 246
        },
        {
          date: '2024-06-19',
          searchVolume: 5698,
          organicRank: 7,

          keywordSales: 246
        },
        {
          date: '2024-06-18',
          searchVolume: 5698,
          organicRank: 17,
          sponsoredRank: 39,
          keywordSales: 246
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_pure boost clean energy drink mix`,
      keyword: 'pure boost clean energy drink mix',
      searchVolume: 1432,
      organicStartRank: 70,
      organicHighestRank: 38,
      sponsoredStartRank: 46,
      sponsoredHighestRank: 18,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-16',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1249,
          organicRank: 56,

          keywordSales: 47
        },
        {
          date: '2024-08-06',
          searchVolume: 1249,
          organicRank: 54,

          keywordSales: 47
        },
        {
          date: '2024-08-05',
          searchVolume: 1249,
          organicRank: 57,

          keywordSales: 47
        },
        {
          date: '2024-08-04',
          searchVolume: 1249,
          organicRank: 49,

          keywordSales: 47
        },
        {
          date: '2024-08-03',
          searchVolume: 1249,
          organicRank: 57,

          keywordSales: 47
        },
        {
          date: '2024-08-01',
          searchVolume: 1249,
          organicRank: 48,
          sponsoredRank: 20,
          keywordSales: 47
        },
        {
          date: '2024-07-31',
          searchVolume: 1249,
          organicRank: 56,

          keywordSales: 47
        },
        {
          date: '2024-07-30',
          searchVolume: 1249,
          organicRank: 56,

          keywordSales: 47
        },
        {
          date: '2024-07-28',
          searchVolume: 1249,
          organicRank: 55,

          keywordSales: 47
        },
        {
          date: '2024-07-27',
          searchVolume: 1249,
          organicRank: 57,

          keywordSales: 47
        },
        {
          date: '2024-07-26',
          searchVolume: 1768,
          organicRank: 52,

          keywordSales: 47
        },
        {
          date: '2024-07-25',
          searchVolume: 1768,
          organicRank: 54,

          keywordSales: 47
        },
        {
          date: '2024-07-24',
          searchVolume: 1768,
          organicRank: 58,

          keywordSales: 47
        },
        {
          date: '2024-07-23',
          searchVolume: 1768,
          organicRank: 48,

          keywordSales: 47
        },
        {
          date: '2024-07-22',
          searchVolume: 1768,
          organicRank: 48,

          keywordSales: 47
        },
        {
          date: '2024-07-21',
          searchVolume: 1768,
          organicRank: 45,
          sponsoredRank: 53,
          keywordSales: 47
        },
        {
          date: '2024-07-20',
          searchVolume: 1768,
          organicRank: 43,
          sponsoredRank: 35,
          keywordSales: 47
        },
        {
          date: '2024-07-19',
          searchVolume: 1508,
          organicRank: 61,
          sponsoredRank: 47,
          keywordSales: 47
        },
        {
          date: '2024-07-18',
          searchVolume: 1508,
          organicRank: 40,
          sponsoredRank: 46,
          keywordSales: 47
        },
        {
          date: '2024-07-17',
          searchVolume: 1508,
          organicRank: 47,

          keywordSales: 47
        },
        {
          date: '2024-07-16',
          searchVolume: 1508,
          organicRank: 54,
          sponsoredRank: 18,
          keywordSales: 47
        },
        {
          date: '2024-07-15',
          searchVolume: 1508,
          organicRank: 58,

          keywordSales: 47
        },
        {
          date: '2024-07-14',
          searchVolume: 1508,
          organicRank: 60,

          keywordSales: 47
        },
        {
          date: '2024-07-13',
          searchVolume: 1508,
          organicRank: 56,

          keywordSales: 47
        },
        {
          date: '2024-07-12',
          searchVolume: 1370,
          organicRank: 59,

          keywordSales: 47
        },
        {
          date: '2024-07-11',
          searchVolume: 1370,
          organicRank: 64,

          keywordSales: 47
        },
        {
          date: '2024-07-10',
          searchVolume: 1370,
          organicRank: 54,

          keywordSales: 47
        },
        {
          date: '2024-07-09',
          searchVolume: 1370,
          organicRank: 53,

          keywordSales: 47
        },
        {
          date: '2024-07-08',
          searchVolume: 1370,
          organicRank: 64,

          keywordSales: 47
        },
        {
          date: '2024-07-07',
          searchVolume: 1370,
          organicRank: 56,

          keywordSales: 47
        },
        {
          date: '2024-07-06',
          searchVolume: 1370,
          organicRank: 52,

          keywordSales: 47
        },
        {
          date: '2024-07-05',
          searchVolume: 1664,
          organicRank: 62,

          keywordSales: 47
        },
        {
          date: '2024-07-04',
          searchVolume: 1664,
          organicRank: 55,

          keywordSales: 47
        },
        {
          date: '2024-07-03',
          searchVolume: 1664,
          organicRank: 62,

          keywordSales: 47
        },
        {
          date: '2024-07-02',
          searchVolume: 1664,
          organicRank: 52,
          sponsoredRank: 46,
          keywordSales: 47
        },
        {
          date: '2024-07-01',
          searchVolume: 1664,
          organicRank: 51,

          keywordSales: 47
        },
        {
          date: '2024-06-30',
          searchVolume: 1664,
          organicRank: 55,

          keywordSales: 47
        },
        {
          date: '2024-06-29',
          searchVolume: 1664,
          organicRank: 55,

          keywordSales: 47
        },
        {
          date: '2024-06-28',
          searchVolume: 1426,
          organicRank: 50,

          keywordSales: 47
        },
        {
          date: '2024-06-27',
          searchVolume: 1426,
          organicRank: 55,

          keywordSales: 47
        },
        {
          date: '2024-06-26',
          searchVolume: 1426,
          organicRank: 59,

          keywordSales: 47
        },
        {
          date: '2024-06-25',
          searchVolume: 1426,
          organicRank: 79,

          keywordSales: 47
        },
        {
          date: '2024-06-24',
          searchVolume: 1426,
          organicRank: 38,

          keywordSales: 47
        },
        {
          date: '2024-06-21',
          searchVolume: 1432,
          organicRank: 48,

          keywordSales: 47
        },
        {
          date: '2024-06-20',
          searchVolume: 1432,
          organicRank: 55,

          keywordSales: 47
        },
        {
          date: '2024-06-19',
          searchVolume: 1432,
          organicRank: 70,

          keywordSales: 47
        },
        {
          date: '2024-06-18',
          searchVolume: 1432,
          organicRank: 66,

          keywordSales: 47
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_pure kick energy drink mix`,
      keyword: 'pure kick energy drink mix',
      searchVolume: 1430,
      organicStartRank: 99,
      organicHighestRank: 72,
      sponsoredStartRank: 19,
      sponsoredHighestRank: 19,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 1242,
          organicRank: 108,
          sponsoredRank: 32,
          keywordSales: 43
        },
        {
          date: '2024-08-05',
          searchVolume: 1242,
          organicRank: 108,

          keywordSales: 43
        },
        {
          date: '2024-08-04',
          searchVolume: 1242,
          organicRank: 109,

          keywordSales: 43
        },
        {
          date: '2024-08-03',
          searchVolume: 1242,
          organicRank: 109,

          keywordSales: 43
        },
        {
          date: '2024-08-02',
          searchVolume: 1242,
          organicRank: 111,

          keywordSales: 43
        },
        {
          date: '2024-08-01',
          searchVolume: 1242,
          organicRank: 110,

          keywordSales: 43
        },
        {
          date: '2024-07-31',
          searchVolume: 1242,
          organicRank: 111,

          keywordSales: 43
        },
        {
          date: '2024-07-30',
          searchVolume: 1242,
          organicRank: 115,

          keywordSales: 43
        },
        {
          date: '2024-07-29',
          searchVolume: 1242,
          organicRank: 111,

          keywordSales: 43
        },
        {
          date: '2024-07-28',
          searchVolume: 1242,
          organicRank: 110,

          keywordSales: 43
        },
        {
          date: '2024-07-27',
          searchVolume: 1242,
          organicRank: 113,

          keywordSales: 43
        },
        {
          date: '2024-07-26',
          searchVolume: 1167,
          organicRank: 117,

          keywordSales: 43
        },
        {
          date: '2024-07-25',
          searchVolume: 1167,
          organicRank: 110,

          keywordSales: 43
        },
        {
          date: '2024-07-24',
          searchVolume: 1167,
          organicRank: 116,

          keywordSales: 43
        },
        {
          date: '2024-07-23',
          searchVolume: 1167,
          organicRank: 107,

          keywordSales: 43
        },
        {
          date: '2024-07-22',
          searchVolume: 1167,
          organicRank: 109,

          keywordSales: 43
        },
        {
          date: '2024-07-21',
          searchVolume: 1167,
          organicRank: 108,

          keywordSales: 43
        },
        {
          date: '2024-07-20',
          searchVolume: 1167,
          organicRank: 104,

          keywordSales: 43
        },
        {
          date: '2024-07-19',
          searchVolume: 895,
          organicRank: 95,

          keywordSales: 43
        },
        {
          date: '2024-07-18',
          searchVolume: 895,
          organicRank: 99,

          keywordSales: 43
        },
        {
          date: '2024-07-17',
          searchVolume: 895,
          organicRank: 106,

          keywordSales: 43
        },
        {
          date: '2024-07-16',
          searchVolume: 895,
          organicRank: 109,

          keywordSales: 43
        },
        {
          date: '2024-07-15',
          searchVolume: 895,
          organicRank: 113,

          keywordSales: 43
        },
        {
          date: '2024-07-14',
          searchVolume: 895,
          organicRank: 72,

          keywordSales: 43
        },
        {
          date: '2024-07-13',
          searchVolume: 895,
          organicRank: 97,

          keywordSales: 43
        },
        {
          date: '2024-07-12',
          searchVolume: 911,
          organicRank: 102,

          keywordSales: 43
        },
        {
          date: '2024-07-11',
          searchVolume: 911,
          organicRank: 99,

          keywordSales: 43
        },
        {
          date: '2024-07-10',
          searchVolume: 911,
          organicRank: 99,

          keywordSales: 43
        },
        {
          date: '2024-07-09',
          searchVolume: 911,
          organicRank: 93,

          keywordSales: 43
        },
        {
          date: '2024-07-08',
          searchVolume: 911,
          organicRank: 91,

          keywordSales: 43
        },
        {
          date: '2024-07-07',
          searchVolume: 911,
          organicRank: 102,

          keywordSales: 43
        },
        {
          date: '2024-07-06',
          searchVolume: 911,
          organicRank: 96,

          keywordSales: 43
        },
        {
          date: '2024-07-05',
          searchVolume: 1432,
          organicRank: 82,

          keywordSales: 43
        },
        {
          date: '2024-07-04',
          searchVolume: 1432,
          organicRank: 105,

          keywordSales: 43
        },
        {
          date: '2024-07-03',
          searchVolume: 1432,
          organicRank: 104,

          keywordSales: 43
        },
        {
          date: '2024-07-02',
          searchVolume: 1432,
          organicRank: 103,

          keywordSales: 43
        },
        {
          date: '2024-07-01',
          searchVolume: 1432,
          organicRank: 306,

          keywordSales: 43
        },
        {
          date: '2024-06-30',
          searchVolume: 1432,
          organicRank: 104,

          keywordSales: 43
        },
        {
          date: '2024-06-29',
          searchVolume: 1432,
          organicRank: 103,

          keywordSales: 43
        },
        {
          date: '2024-06-27',
          searchVolume: 1426,
          organicRank: 102,

          keywordSales: 43
        },
        {
          date: '2024-06-26',
          searchVolume: 1426,
          organicRank: 95,

          keywordSales: 43
        },
        {
          date: '2024-06-25',
          searchVolume: 1426,
          organicRank: 110,

          keywordSales: 43
        },
        {
          date: '2024-06-24',
          searchVolume: 1426,
          organicRank: 98,

          keywordSales: 43
        },
        {
          date: '2024-06-22',
          searchVolume: 1426,
          organicRank: 99,

          keywordSales: 43
        },
        {
          date: '2024-06-21',
          searchVolume: 1430,
          organicRank: 101,

          keywordSales: 43
        },
        {
          date: '2024-06-20',
          searchVolume: 1430,
          organicRank: 98,
          sponsoredRank: 19,
          keywordSales: 43
        },
        {
          date: '2024-06-19',
          searchVolume: 1430,
          organicRank: 99,

          keywordSales: 43
        },
        {
          date: '2024-06-18',
          searchVolume: 1430,
          organicRank: 103,

          keywordSales: 43
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_red bull`,
      keyword: 'red bull',
      searchVolume: 66317,
      organicStartRank: 94,
      organicHighestRank: 38,
      sponsoredStartRank: 42,
      sponsoredHighestRank: 42,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 49564,
          organicRank: 306,
          sponsoredRank: 42,
          keywordSales: 1330
        },
        {
          date: '2024-08-06',
          searchVolume: 49564,
          organicRank: 224,

          keywordSales: 1330
        },
        {
          date: '2024-08-05',
          searchVolume: 49564,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-08-04',
          searchVolume: 49564,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-08-02',
          searchVolume: 49564,
          organicRank: 230,

          keywordSales: 1330
        },
        {
          date: '2024-08-01',
          searchVolume: 49564,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-31',
          searchVolume: 49564,
          organicRank: 222,

          keywordSales: 1330
        },
        {
          date: '2024-07-30',
          searchVolume: 49564,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-29',
          searchVolume: 49564,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-28',
          searchVolume: 49564,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-27',
          searchVolume: 49564,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-26',
          searchVolume: 113370,
          organicRank: 218,

          keywordSales: 1330
        },
        {
          date: '2024-07-25',
          searchVolume: 113370,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-24',
          searchVolume: 113370,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-23',
          searchVolume: 113370,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-22',
          searchVolume: 113370,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-21',
          searchVolume: 113370,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-20',
          searchVolume: 113370,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-19',
          searchVolume: 53036,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-18',
          searchVolume: 53036,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-17',
          searchVolume: 53036,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-16',
          searchVolume: 53036,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-15',
          searchVolume: 53036,
          organicRank: 243,

          keywordSales: 1330
        },
        {
          date: '2024-07-14',
          searchVolume: 53036,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-13',
          searchVolume: 53036,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-12',
          searchVolume: 51679,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-11',
          searchVolume: 51679,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-10',
          searchVolume: 51679,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-09',
          searchVolume: 51679,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-08',
          searchVolume: 51679,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-07',
          searchVolume: 51679,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-06',
          searchVolume: 51679,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-05',
          searchVolume: 56640,
          organicRank: 46,

          keywordSales: 1330
        },
        {
          date: '2024-07-04',
          searchVolume: 56640,
          organicRank: 38,

          keywordSales: 1330
        },
        {
          date: '2024-07-03',
          searchVolume: 56640,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-07-02',
          searchVolume: 56640,
          organicRank: 42,

          keywordSales: 1330
        },
        {
          date: '2024-07-01',
          searchVolume: 56640,
          organicRank: 40,

          keywordSales: 1330
        },
        {
          date: '2024-06-30',
          searchVolume: 56640,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-06-29',
          searchVolume: 56640,
          organicRank: 45,

          keywordSales: 1330
        },
        {
          date: '2024-06-28',
          searchVolume: 56399,
          organicRank: 47,

          keywordSales: 1330
        },
        {
          date: '2024-06-27',
          searchVolume: 56399,
          organicRank: 43,

          keywordSales: 1330
        },
        {
          date: '2024-06-26',
          searchVolume: 56399,
          organicRank: 44,

          keywordSales: 1330
        },
        {
          date: '2024-06-25',
          searchVolume: 56399,
          organicRank: 50,

          keywordSales: 1330
        },
        {
          date: '2024-06-21',
          searchVolume: 66317,
          organicRank: 46,

          keywordSales: 1330
        },
        {
          date: '2024-06-20',
          searchVolume: 66317,
          organicRank: 306,

          keywordSales: 1330
        },
        {
          date: '2024-06-19',
          searchVolume: 66317,
          organicRank: 94,

          keywordSales: 1330
        },
        {
          date: '2024-06-18',
          searchVolume: 66317,
          organicRank: 114,

          keywordSales: 1330
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_red bull 12 oz 24 pack`,
      keyword: 'red bull 12 oz 24 pack',
      searchVolume: 9035,
      organicStartRank: 50,
      organicHighestRank: 34,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 4597,
          organicRank: 306,

          keywordSales: 283
        },
        {
          date: '2024-08-06',
          searchVolume: 4597,
          organicRank: 306,

          keywordSales: 283
        },
        {
          date: '2024-08-05',
          searchVolume: 4597,
          organicRank: 306,

          keywordSales: 283
        },
        {
          date: '2024-08-04',
          searchVolume: 4597,
          organicRank: 306,

          keywordSales: 283
        },
        {
          date: '2024-08-03',
          searchVolume: 4597,
          organicRank: 306,

          keywordSales: 283
        },
        {
          date: '2024-08-01',
          searchVolume: 4597,
          organicRank: 306,

          keywordSales: 283
        },
        {
          date: '2024-07-31',
          searchVolume: 4597,
          organicRank: 306,

          keywordSales: 283
        },
        {
          date: '2024-07-30',
          searchVolume: 4597,
          organicRank: 56,

          keywordSales: 283
        },
        {
          date: '2024-07-29',
          searchVolume: 4597,
          organicRank: 52,

          keywordSales: 283
        },
        {
          date: '2024-07-28',
          searchVolume: 4597,
          organicRank: 65,

          keywordSales: 283
        },
        {
          date: '2024-07-27',
          searchVolume: 4597,
          organicRank: 61,

          keywordSales: 283
        },
        {
          date: '2024-07-26',
          searchVolume: 8613,
          organicRank: 67,

          keywordSales: 283
        },
        {
          date: '2024-07-25',
          searchVolume: 8613,
          organicRank: 63,

          keywordSales: 283
        },
        {
          date: '2024-07-24',
          searchVolume: 8613,
          organicRank: 68,

          keywordSales: 283
        },
        {
          date: '2024-07-23',
          searchVolume: 8613,
          organicRank: 82,

          keywordSales: 283
        },
        {
          date: '2024-07-22',
          searchVolume: 8613,
          organicRank: 85,

          keywordSales: 283
        },
        {
          date: '2024-07-21',
          searchVolume: 8613,
          organicRank: 77,

          keywordSales: 283
        },
        {
          date: '2024-07-20',
          searchVolume: 8613,
          organicRank: 77,

          keywordSales: 283
        },
        {
          date: '2024-07-19',
          searchVolume: 5341,
          organicRank: 69,

          keywordSales: 283
        },
        {
          date: '2024-07-18',
          searchVolume: 5341,
          organicRank: 66,

          keywordSales: 283
        },
        {
          date: '2024-07-17',
          searchVolume: 5341,
          organicRank: 69,

          keywordSales: 283
        },
        {
          date: '2024-07-16',
          searchVolume: 5341,
          organicRank: 70,

          keywordSales: 283
        },
        {
          date: '2024-07-15',
          searchVolume: 5341,
          organicRank: 65,

          keywordSales: 283
        },
        {
          date: '2024-07-14',
          searchVolume: 5341,
          organicRank: 71,

          keywordSales: 283
        },
        {
          date: '2024-07-13',
          searchVolume: 5341,
          organicRank: 64,

          keywordSales: 283
        },
        {
          date: '2024-07-12',
          searchVolume: 6461,
          organicRank: 49,

          keywordSales: 283
        },
        {
          date: '2024-07-11',
          searchVolume: 6461,
          organicRank: 73,

          keywordSales: 283
        },
        {
          date: '2024-07-10',
          searchVolume: 6461,
          organicRank: 73,

          keywordSales: 283
        },
        {
          date: '2024-07-09',
          searchVolume: 6461,
          organicRank: 70,

          keywordSales: 283
        },
        {
          date: '2024-07-08',
          searchVolume: 6461,
          organicRank: 67,

          keywordSales: 283
        },
        {
          date: '2024-07-07',
          searchVolume: 6461,
          organicRank: 55,

          keywordSales: 283
        },
        {
          date: '2024-07-06',
          searchVolume: 6461,
          organicRank: 52,

          keywordSales: 283
        },
        {
          date: '2024-07-05',
          searchVolume: 7678,
          organicRank: 44,

          keywordSales: 283
        },
        {
          date: '2024-07-04',
          searchVolume: 7678,
          organicRank: 47,

          keywordSales: 283
        },
        {
          date: '2024-07-03',
          searchVolume: 7678,
          organicRank: 48,

          keywordSales: 283
        },
        {
          date: '2024-07-02',
          searchVolume: 7678,
          organicRank: 42,

          keywordSales: 283
        },
        {
          date: '2024-07-01',
          searchVolume: 7678,
          organicRank: 53,

          keywordSales: 283
        },
        {
          date: '2024-06-30',
          searchVolume: 7678,
          organicRank: 42,

          keywordSales: 283
        },
        {
          date: '2024-06-29',
          searchVolume: 7678,
          organicRank: 38,

          keywordSales: 283
        },
        {
          date: '2024-06-28',
          searchVolume: 7266,
          organicRank: 38,

          keywordSales: 283
        },
        {
          date: '2024-06-27',
          searchVolume: 7266,
          organicRank: 38,

          keywordSales: 283
        },
        {
          date: '2024-06-26',
          searchVolume: 7266,
          organicRank: 41,

          keywordSales: 283
        },
        {
          date: '2024-06-25',
          searchVolume: 7266,
          organicRank: 40,

          keywordSales: 283
        },
        {
          date: '2024-06-24',
          searchVolume: 7266,
          organicRank: 37,

          keywordSales: 283
        },
        {
          date: '2024-06-21',
          searchVolume: 9035,
          organicRank: 34,

          keywordSales: 283
        },
        {
          date: '2024-06-20',
          searchVolume: 9035,
          organicRank: 36,

          keywordSales: 283
        },
        {
          date: '2024-06-19',
          searchVolume: 9035,
          organicRank: 50,

          keywordSales: 283
        },
        {
          date: '2024-06-18',
          searchVolume: 9035,
          organicRank: 33,

          keywordSales: 283
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_red bull sugar free`,
      keyword: 'red bull sugar free',
      searchVolume: 6747,
      organicStartRank: 60,
      organicHighestRank: 36,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 10216,
          organicRank: 54,

          keywordSales: 705
        },
        {
          date: '2024-08-06',
          searchVolume: 10216,
          organicRank: 40,

          keywordSales: 705
        },
        {
          date: '2024-08-05',
          searchVolume: 10216,
          organicRank: 53,

          keywordSales: 705
        },
        {
          date: '2024-08-04',
          searchVolume: 10216,
          organicRank: 49,

          keywordSales: 705
        },
        {
          date: '2024-08-03',
          searchVolume: 10216,
          organicRank: 43,

          keywordSales: 705
        },
        {
          date: '2024-08-01',
          searchVolume: 10216,
          organicRank: 42,

          keywordSales: 705
        },
        {
          date: '2024-07-31',
          searchVolume: 10216,
          organicRank: 38,

          keywordSales: 705
        },
        {
          date: '2024-07-30',
          searchVolume: 10216,
          organicRank: 56,

          keywordSales: 705
        },
        {
          date: '2024-07-29',
          searchVolume: 10216,
          organicRank: 51,

          keywordSales: 705
        },
        {
          date: '2024-07-28',
          searchVolume: 10216,
          organicRank: 43,

          keywordSales: 705
        },
        {
          date: '2024-07-27',
          searchVolume: 10216,
          organicRank: 37,

          keywordSales: 705
        },
        {
          date: '2024-07-26',
          searchVolume: 21059,
          organicRank: 46,

          keywordSales: 705
        },
        {
          date: '2024-07-25',
          searchVolume: 21059,
          organicRank: 46,

          keywordSales: 705
        },
        {
          date: '2024-07-24',
          searchVolume: 21059,
          organicRank: 43,

          keywordSales: 705
        },
        {
          date: '2024-07-23',
          searchVolume: 21059,
          organicRank: 40,

          keywordSales: 705
        },
        {
          date: '2024-07-22',
          searchVolume: 21059,
          organicRank: 37,

          keywordSales: 705
        },
        {
          date: '2024-07-21',
          searchVolume: 21059,
          organicRank: 36,

          keywordSales: 705
        },
        {
          date: '2024-07-20',
          searchVolume: 21059,
          organicRank: 37,

          keywordSales: 705
        },
        {
          date: '2024-07-19',
          searchVolume: 10386,
          organicRank: 44,

          keywordSales: 705
        },
        {
          date: '2024-07-18',
          searchVolume: 10386,
          organicRank: 44,

          keywordSales: 705
        },
        {
          date: '2024-07-17',
          searchVolume: 10386,
          organicRank: 44,

          keywordSales: 705
        },
        {
          date: '2024-07-16',
          searchVolume: 10386,
          organicRank: 47,

          keywordSales: 705
        },
        {
          date: '2024-07-15',
          searchVolume: 10386,
          organicRank: 48,

          keywordSales: 705
        },
        {
          date: '2024-07-14',
          searchVolume: 10386,
          organicRank: 58,

          keywordSales: 705
        },
        {
          date: '2024-07-13',
          searchVolume: 10386,
          organicRank: 53,

          keywordSales: 705
        },
        {
          date: '2024-07-12',
          searchVolume: 10321,
          organicRank: 53,

          keywordSales: 705
        },
        {
          date: '2024-07-11',
          searchVolume: 10321,
          organicRank: 60,

          keywordSales: 705
        },
        {
          date: '2024-07-10',
          searchVolume: 10321,
          organicRank: 56,

          keywordSales: 705
        },
        {
          date: '2024-07-09',
          searchVolume: 10321,
          organicRank: 47,

          keywordSales: 705
        },
        {
          date: '2024-07-08',
          searchVolume: 10321,
          organicRank: 52,

          keywordSales: 705
        },
        {
          date: '2024-07-07',
          searchVolume: 10321,
          organicRank: 54,

          keywordSales: 705
        },
        {
          date: '2024-07-06',
          searchVolume: 10321,
          organicRank: 51,

          keywordSales: 705
        },
        {
          date: '2024-07-05',
          searchVolume: 9020,
          organicRank: 53,

          keywordSales: 705
        },
        {
          date: '2024-07-04',
          searchVolume: 9020,
          organicRank: 55,

          keywordSales: 705
        },
        {
          date: '2024-07-03',
          searchVolume: 9020,
          organicRank: 51,

          keywordSales: 705
        },
        {
          date: '2024-07-02',
          searchVolume: 9020,
          organicRank: 52,

          keywordSales: 705
        },
        {
          date: '2024-07-01',
          searchVolume: 9020,
          organicRank: 54,

          keywordSales: 705
        },
        {
          date: '2024-06-30',
          searchVolume: 9020,
          organicRank: 61,

          keywordSales: 705
        },
        {
          date: '2024-06-29',
          searchVolume: 9020,
          organicRank: 50,

          keywordSales: 705
        },
        {
          date: '2024-06-28',
          searchVolume: 6736,
          organicRank: 62,

          keywordSales: 705
        },
        {
          date: '2024-06-27',
          searchVolume: 6736,
          organicRank: 60,

          keywordSales: 705
        },
        {
          date: '2024-06-26',
          searchVolume: 6736,
          organicRank: 59,

          keywordSales: 705
        },
        {
          date: '2024-06-25',
          searchVolume: 6736,
          organicRank: 61,

          keywordSales: 705
        },
        {
          date: '2024-06-24',
          searchVolume: 6736,
          organicRank: 65,

          keywordSales: 705
        },
        {
          date: '2024-06-21',
          searchVolume: 6747,
          organicRank: 61,

          keywordSales: 705
        },
        {
          date: '2024-06-20',
          searchVolume: 6747,
          organicRank: 60,
          sponsoredRank: 11,
          keywordSales: 705
        },
        {
          date: '2024-06-19',
          searchVolume: 6747,
          organicRank: 60,

          keywordSales: 705
        },
        {
          date: '2024-06-18',
          searchVolume: 6747,
          organicRank: 62,

          keywordSales: 705
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_redbull 12oz case 24 pack`,
      keyword: 'redbull 12oz case 24 pack',
      searchVolume: 7237,
      organicStartRank: 306,
      organicHighestRank: 37,
      sponsoredStartRank: 4,
      sponsoredHighestRank: 4,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-24',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-08-06',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-08-05',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-08-04',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-08-03',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-08-01',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-31',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-30',
          searchVolume: 7830,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-29',
          searchVolume: 7830,
          organicRank: 41,

          keywordSales: 357
        },
        {
          date: '2024-07-28',
          searchVolume: 7830,
          organicRank: 37,

          keywordSales: 357
        },
        {
          date: '2024-07-27',
          searchVolume: 7830,
          organicRank: 43,

          keywordSales: 357
        },
        {
          date: '2024-07-26',
          searchVolume: 11233,
          organicRank: 51,

          keywordSales: 357
        },
        {
          date: '2024-07-25',
          searchVolume: 11233,
          organicRank: 73,

          keywordSales: 357
        },
        {
          date: '2024-07-24',
          searchVolume: 11233,
          organicRank: 63,
          sponsoredRank: 4,
          keywordSales: 357
        },
        {
          date: '2024-07-23',
          searchVolume: 11233,
          organicRank: 68,

          keywordSales: 357
        },
        {
          date: '2024-07-22',
          searchVolume: 11233,
          organicRank: 77,

          keywordSales: 357
        },
        {
          date: '2024-07-21',
          searchVolume: 11233,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-20',
          searchVolume: 11233,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-19',
          searchVolume: 7237,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-18',
          searchVolume: 7237,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-17',
          searchVolume: 7237,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-16',
          searchVolume: 7237,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-15',
          searchVolume: 7237,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-14',
          searchVolume: 7237,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-13',
          searchVolume: 7237,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-12',
          searchVolume: 7876,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-11',
          searchVolume: 7876,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-10',
          searchVolume: 7876,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-09',
          searchVolume: 7876,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-08',
          searchVolume: 7876,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-07',
          searchVolume: 7876,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-06',
          searchVolume: 7876,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-05',
          searchVolume: 7459,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-04',
          searchVolume: 7459,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-03',
          searchVolume: 7459,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-02',
          searchVolume: 7459,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-07-01',
          searchVolume: 7459,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-30',
          searchVolume: 7459,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-29',
          searchVolume: 7459,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-28',
          searchVolume: 7688,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-27',
          searchVolume: 7688,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-26',
          searchVolume: 7688,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-25',
          searchVolume: 7688,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-24',
          searchVolume: 7688,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-21',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-20',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-19',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 357
        },
        {
          date: '2024-06-18',
          searchVolume: 8482,
          organicRank: 306,

          keywordSales: 357
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_redbull sugar free pack`,
      keyword: 'redbull sugar free pack',
      searchVolume: 1687,
      organicStartRank: 26,
      organicHighestRank: 19,
      sponsoredStartRank: 15,
      sponsoredHighestRank: 15,
      organicPageOneWinDate: '2024-06-27',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1778,
          organicRank: 56,

          keywordSales: 101
        },
        {
          date: '2024-08-06',
          searchVolume: 1778,
          organicRank: 53,

          keywordSales: 101
        },
        {
          date: '2024-08-05',
          searchVolume: 1778,
          organicRank: 51,

          keywordSales: 101
        },
        {
          date: '2024-08-04',
          searchVolume: 1778,
          organicRank: 60,

          keywordSales: 101
        },
        {
          date: '2024-08-03',
          searchVolume: 1778,
          organicRank: 58,

          keywordSales: 101
        },
        {
          date: '2024-08-01',
          searchVolume: 1778,
          organicRank: 63,

          keywordSales: 101
        },
        {
          date: '2024-07-31',
          searchVolume: 1778,
          organicRank: 59,

          keywordSales: 101
        },
        {
          date: '2024-07-30',
          searchVolume: 1778,
          organicRank: 61,

          keywordSales: 101
        },
        {
          date: '2024-07-29',
          searchVolume: 1778,
          organicRank: 38,

          keywordSales: 101
        },
        {
          date: '2024-07-28',
          searchVolume: 1778,
          organicRank: 39,

          keywordSales: 101
        },
        {
          date: '2024-07-27',
          searchVolume: 1778,
          organicRank: 57,

          keywordSales: 101
        },
        {
          date: '2024-07-26',
          searchVolume: 4126,
          organicRank: 48,

          keywordSales: 101
        },
        {
          date: '2024-07-25',
          searchVolume: 4126,
          organicRank: 41,

          keywordSales: 101
        },
        {
          date: '2024-07-24',
          searchVolume: 4126,
          organicRank: 49,

          keywordSales: 101
        },
        {
          date: '2024-07-23',
          searchVolume: 4126,
          organicRank: 48,

          keywordSales: 101
        },
        {
          date: '2024-07-22',
          searchVolume: 4126,
          organicRank: 48,

          keywordSales: 101
        },
        {
          date: '2024-07-21',
          searchVolume: 4126,
          organicRank: 46,

          keywordSales: 101
        },
        {
          date: '2024-07-20',
          searchVolume: 4126,
          organicRank: 41,

          keywordSales: 101
        },
        {
          date: '2024-07-19',
          searchVolume: 2038,
          organicRank: 47,

          keywordSales: 101
        },
        {
          date: '2024-07-18',
          searchVolume: 2038,
          organicRank: 44,

          keywordSales: 101
        },
        {
          date: '2024-07-17',
          searchVolume: 2038,
          organicRank: 42,

          keywordSales: 101
        },
        {
          date: '2024-07-16',
          searchVolume: 2038,
          organicRank: 64,

          keywordSales: 101
        },
        {
          date: '2024-07-15',
          searchVolume: 2038,
          organicRank: 40,

          keywordSales: 101
        },
        {
          date: '2024-07-14',
          searchVolume: 2038,
          organicRank: 47,

          keywordSales: 101
        },
        {
          date: '2024-07-13',
          searchVolume: 2038,
          organicRank: 44,

          keywordSales: 101
        },
        {
          date: '2024-07-12',
          searchVolume: 1845,
          organicRank: 37,

          keywordSales: 101
        },
        {
          date: '2024-07-11',
          searchVolume: 1845,
          organicRank: 41,

          keywordSales: 101
        },
        {
          date: '2024-07-10',
          searchVolume: 1845,
          organicRank: 40,

          keywordSales: 101
        },
        {
          date: '2024-07-09',
          searchVolume: 1845,
          organicRank: 44,

          keywordSales: 101
        },
        {
          date: '2024-07-08',
          searchVolume: 1845,
          organicRank: 39,

          keywordSales: 101
        },
        {
          date: '2024-07-07',
          searchVolume: 1845,
          organicRank: 40,

          keywordSales: 101
        },
        {
          date: '2024-07-06',
          searchVolume: 1845,
          organicRank: 41,

          keywordSales: 101
        },
        {
          date: '2024-07-05',
          searchVolume: 2188,
          organicRank: 49,

          keywordSales: 101
        },
        {
          date: '2024-07-04',
          searchVolume: 2188,
          organicRank: 39,

          keywordSales: 101
        },
        {
          date: '2024-07-03',
          searchVolume: 2188,
          organicRank: 39,

          keywordSales: 101
        },
        {
          date: '2024-07-02',
          searchVolume: 2188,
          organicRank: 41,

          keywordSales: 101
        },
        {
          date: '2024-07-01',
          searchVolume: 2188,
          organicRank: 35,

          keywordSales: 101
        },
        {
          date: '2024-06-30',
          searchVolume: 2188,
          organicRank: 35,

          keywordSales: 101
        },
        {
          date: '2024-06-29',
          searchVolume: 2188,
          organicRank: 40,

          keywordSales: 101
        },
        {
          date: '2024-06-28',
          searchVolume: 1682,
          organicRank: 37,

          keywordSales: 101
        },
        {
          date: '2024-06-27',
          searchVolume: 1682,
          organicRank: 19,

          keywordSales: 101
        },
        {
          date: '2024-06-26',
          searchVolume: 1682,
          organicRank: 37,

          keywordSales: 101
        },
        {
          date: '2024-06-25',
          searchVolume: 1682,
          organicRank: 42,

          keywordSales: 101
        },
        {
          date: '2024-06-24',
          searchVolume: 1682,
          organicRank: 43,

          keywordSales: 101
        },
        {
          date: '2024-06-21',
          searchVolume: 1687,
          organicRank: 36,

          keywordSales: 101
        },
        {
          date: '2024-06-20',
          searchVolume: 1687,
          organicRank: 37,
          sponsoredRank: 15,
          keywordSales: 101
        },
        {
          date: '2024-06-19',
          searchVolume: 1687,
          organicRank: 26,

          keywordSales: 101
        },
        {
          date: '2024-06-18',
          searchVolume: 1687,
          organicRank: 27,

          keywordSales: 101
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_reign energy drink`,
      keyword: 'reign energy drink',
      searchVolume: 1917,
      organicStartRank: 33,
      organicHighestRank: 17,
      sponsoredStartRank: 8,
      sponsoredHighestRank: 8,
      organicPageOneWinDate: '2024-07-20',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1322,
          organicRank: 19,

          keywordSales: 27
        },
        {
          date: '2024-08-06',
          searchVolume: 1322,
          organicRank: 18,

          keywordSales: 27
        },
        {
          date: '2024-08-05',
          searchVolume: 1322,
          organicRank: 20,

          keywordSales: 27
        },
        {
          date: '2024-08-04',
          searchVolume: 1322,
          organicRank: 20,

          keywordSales: 27
        },
        {
          date: '2024-08-03',
          searchVolume: 1322,
          organicRank: 20,

          keywordSales: 27
        },
        {
          date: '2024-08-01',
          searchVolume: 1322,
          organicRank: 20,

          keywordSales: 27
        },
        {
          date: '2024-07-31',
          searchVolume: 1322,
          organicRank: 20,

          keywordSales: 27
        },
        {
          date: '2024-07-30',
          searchVolume: 1322,
          organicRank: 20,

          keywordSales: 27
        },
        {
          date: '2024-07-29',
          searchVolume: 1322,
          organicRank: 21,

          keywordSales: 27
        },
        {
          date: '2024-07-28',
          searchVolume: 1322,
          organicRank: 19,

          keywordSales: 27
        },
        {
          date: '2024-07-27',
          searchVolume: 1322,
          organicRank: 18,

          keywordSales: 27
        },
        {
          date: '2024-07-26',
          searchVolume: 1834,
          organicRank: 18,

          keywordSales: 27
        },
        {
          date: '2024-07-25',
          searchVolume: 1834,
          organicRank: 21,

          keywordSales: 27
        },
        {
          date: '2024-07-24',
          searchVolume: 1834,
          organicRank: 23,

          keywordSales: 27
        },
        {
          date: '2024-07-23',
          searchVolume: 1834,
          organicRank: 20,

          keywordSales: 27
        },
        {
          date: '2024-07-22',
          searchVolume: 1834,
          organicRank: 20,

          keywordSales: 27
        },
        {
          date: '2024-07-21',
          searchVolume: 1834,
          organicRank: 20,

          keywordSales: 27
        },
        {
          date: '2024-07-20',
          searchVolume: 1834,
          organicRank: 17,

          keywordSales: 27
        },
        {
          date: '2024-07-19',
          searchVolume: 1344,
          organicRank: 25,

          keywordSales: 27
        },
        {
          date: '2024-07-18',
          searchVolume: 1344,
          organicRank: 28,

          keywordSales: 27
        },
        {
          date: '2024-07-17',
          searchVolume: 1344,
          organicRank: 29,

          keywordSales: 27
        },
        {
          date: '2024-07-16',
          searchVolume: 1344,
          organicRank: 28,

          keywordSales: 27
        },
        {
          date: '2024-07-15',
          searchVolume: 1344,
          organicRank: 33,

          keywordSales: 27
        },
        {
          date: '2024-07-14',
          searchVolume: 1344,
          organicRank: 34,
          sponsoredRank: 11,
          keywordSales: 27
        },
        {
          date: '2024-07-13',
          searchVolume: 1344,
          organicRank: 33,

          keywordSales: 27
        },
        {
          date: '2024-07-12',
          searchVolume: 1611,
          organicRank: 30,

          keywordSales: 27
        },
        {
          date: '2024-07-11',
          searchVolume: 1611,
          organicRank: 34,

          keywordSales: 27
        },
        {
          date: '2024-07-10',
          searchVolume: 1611,
          organicRank: 34,

          keywordSales: 27
        },
        {
          date: '2024-07-09',
          searchVolume: 1611,
          organicRank: 34,

          keywordSales: 27
        },
        {
          date: '2024-07-08',
          searchVolume: 1611,
          organicRank: 33,

          keywordSales: 27
        },
        {
          date: '2024-07-07',
          searchVolume: 1611,
          organicRank: 34,

          keywordSales: 27
        },
        {
          date: '2024-07-06',
          searchVolume: 1611,
          organicRank: 27,

          keywordSales: 27
        },
        {
          date: '2024-07-05',
          searchVolume: 1658,
          organicRank: 25,

          keywordSales: 27
        },
        {
          date: '2024-07-04',
          searchVolume: 1658,
          organicRank: 28,

          keywordSales: 27
        },
        {
          date: '2024-07-03',
          searchVolume: 1658,
          organicRank: 29,

          keywordSales: 27
        },
        {
          date: '2024-07-02',
          searchVolume: 1658,
          organicRank: 33,

          keywordSales: 27
        },
        {
          date: '2024-07-01',
          searchVolume: 1658,
          organicRank: 34,

          keywordSales: 27
        },
        {
          date: '2024-06-30',
          searchVolume: 1658,
          organicRank: 24,

          keywordSales: 27
        },
        {
          date: '2024-06-29',
          searchVolume: 1658,
          organicRank: 29,

          keywordSales: 27
        },
        {
          date: '2024-06-28',
          searchVolume: 1846,
          organicRank: 28,

          keywordSales: 27
        },
        {
          date: '2024-06-27',
          searchVolume: 1846,
          organicRank: 27,

          keywordSales: 27
        },
        {
          date: '2024-06-26',
          searchVolume: 1846,
          organicRank: 29,

          keywordSales: 27
        },
        {
          date: '2024-06-25',
          searchVolume: 1846,
          organicRank: 23,

          keywordSales: 27
        },
        {
          date: '2024-06-24',
          searchVolume: 1846,
          organicRank: 36,

          keywordSales: 27
        },
        {
          date: '2024-06-21',
          searchVolume: 1917,
          organicRank: 25,

          keywordSales: 27
        },
        {
          date: '2024-06-20',
          searchVolume: 1917,
          organicRank: 33,
          sponsoredRank: 8,
          keywordSales: 27
        },
        {
          date: '2024-06-19',
          searchVolume: 1917,
          organicRank: 33,

          keywordSales: 27
        },
        {
          date: '2024-06-18',
          searchVolume: 1917,
          organicRank: 36,

          keywordSales: 27
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_reign energy drinks`,
      keyword: 'reign energy drinks',
      searchVolume: 28994,
      organicStartRank: 25,
      organicHighestRank: 20,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 11,
      organicPageOneWinDate: '2024-06-29',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 22842,
          organicRank: 76,

          keywordSales: 1384
        },
        {
          date: '2024-08-05',
          searchVolume: 22842,
          organicRank: 26,

          keywordSales: 1384
        },
        {
          date: '2024-08-04',
          searchVolume: 22842,
          organicRank: 24,

          keywordSales: 1384
        },
        {
          date: '2024-08-03',
          searchVolume: 22842,
          organicRank: 25,

          keywordSales: 1384
        },
        {
          date: '2024-08-02',
          searchVolume: 22842,
          organicRank: 28,

          keywordSales: 1384
        },
        {
          date: '2024-08-01',
          searchVolume: 22842,
          organicRank: 24,

          keywordSales: 1384
        },
        {
          date: '2024-07-31',
          searchVolume: 22842,
          organicRank: 26,

          keywordSales: 1384
        },
        {
          date: '2024-07-30',
          searchVolume: 22842,
          organicRank: 24,

          keywordSales: 1384
        },
        {
          date: '2024-07-29',
          searchVolume: 22842,
          organicRank: 22,

          keywordSales: 1384
        },
        {
          date: '2024-07-28',
          searchVolume: 22842,
          organicRank: 28,

          keywordSales: 1384
        },
        {
          date: '2024-07-27',
          searchVolume: 22842,
          organicRank: 23,

          keywordSales: 1384
        },
        {
          date: '2024-07-26',
          searchVolume: 36144,
          organicRank: 29,

          keywordSales: 1384
        },
        {
          date: '2024-07-25',
          searchVolume: 36144,
          organicRank: 29,

          keywordSales: 1384
        },
        {
          date: '2024-07-24',
          searchVolume: 36144,
          organicRank: 33,

          keywordSales: 1384
        },
        {
          date: '2024-07-23',
          searchVolume: 36144,
          organicRank: 24,

          keywordSales: 1384
        },
        {
          date: '2024-07-22',
          searchVolume: 36144,
          organicRank: 27,

          keywordSales: 1384
        },
        {
          date: '2024-07-21',
          searchVolume: 36144,
          organicRank: 26,

          keywordSales: 1384
        },
        {
          date: '2024-07-20',
          searchVolume: 36144,
          organicRank: 23,

          keywordSales: 1384
        },
        {
          date: '2024-07-19',
          searchVolume: 24409,
          organicRank: 26,

          keywordSales: 1384
        },
        {
          date: '2024-07-18',
          searchVolume: 24409,
          organicRank: 27,

          keywordSales: 1384
        },
        {
          date: '2024-07-17',
          searchVolume: 24409,
          organicRank: 33,

          keywordSales: 1384
        },
        {
          date: '2024-07-16',
          searchVolume: 24409,
          organicRank: 28,

          keywordSales: 1384
        },
        {
          date: '2024-07-15',
          searchVolume: 24409,
          organicRank: 34,

          keywordSales: 1384
        },
        {
          date: '2024-07-14',
          searchVolume: 24409,
          organicRank: 29,

          keywordSales: 1384
        },
        {
          date: '2024-07-13',
          searchVolume: 24409,
          organicRank: 33,

          keywordSales: 1384
        },
        {
          date: '2024-07-12',
          searchVolume: 24237,
          organicRank: 22,

          keywordSales: 1384
        },
        {
          date: '2024-07-10',
          searchVolume: 24237,
          organicRank: 23,

          keywordSales: 1384
        },
        {
          date: '2024-07-09',
          searchVolume: 24237,
          organicRank: 24,

          keywordSales: 1384
        },
        {
          date: '2024-07-08',
          searchVolume: 24237,
          organicRank: 34,

          keywordSales: 1384
        },
        {
          date: '2024-07-07',
          searchVolume: 24237,
          organicRank: 20,

          keywordSales: 1384
        },
        {
          date: '2024-07-06',
          searchVolume: 24237,
          organicRank: 23,

          keywordSales: 1384
        },
        {
          date: '2024-07-05',
          searchVolume: 27569,
          organicRank: 29,

          keywordSales: 1384
        },
        {
          date: '2024-07-04',
          searchVolume: 27569,
          organicRank: 48,

          keywordSales: 1384
        },
        {
          date: '2024-07-03',
          searchVolume: 27569,
          organicRank: 20,

          keywordSales: 1384
        },
        {
          date: '2024-07-02',
          searchVolume: 27569,
          organicRank: 20,

          keywordSales: 1384
        },
        {
          date: '2024-07-01',
          searchVolume: 27569,
          organicRank: 21,

          keywordSales: 1384
        },
        {
          date: '2024-06-30',
          searchVolume: 27569,
          organicRank: 22,

          keywordSales: 1384
        },
        {
          date: '2024-06-29',
          searchVolume: 27569,
          organicRank: 20,

          keywordSales: 1384
        },
        {
          date: '2024-06-28',
          searchVolume: 28905,
          organicRank: 22,

          keywordSales: 1384
        },
        {
          date: '2024-06-27',
          searchVolume: 28905,
          organicRank: 21,

          keywordSales: 1384
        },
        {
          date: '2024-06-26',
          searchVolume: 28905,
          organicRank: 24,
          sponsoredRank: 11,
          keywordSales: 1384
        },
        {
          date: '2024-06-25',
          searchVolume: 28905,
          organicRank: 24,

          keywordSales: 1384
        },
        {
          date: '2024-06-23',
          searchVolume: 28905,
          organicRank: 25,

          keywordSales: 1384
        },
        {
          date: '2024-06-21',
          searchVolume: 28994,
          organicRank: 22,

          keywordSales: 1384
        },
        {
          date: '2024-06-20',
          searchVolume: 28994,
          organicRank: 24,

          keywordSales: 1384
        },
        {
          date: '2024-06-19',
          searchVolume: 28994,
          organicRank: 25,

          keywordSales: 1384
        },
        {
          date: '2024-06-18',
          searchVolume: 28994,
          organicRank: 28,

          keywordSales: 1384
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_roar organic drink`,
      keyword: 'roar organic drink',
      searchVolume: 7455,
      organicStartRank: 68,
      organicHighestRank: 48,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 6729,
          organicRank: 306,

          keywordSales: 278
        },
        {
          date: '2024-08-06',
          searchVolume: 6729,
          organicRank: 306,

          keywordSales: 278
        },
        {
          date: '2024-08-05',
          searchVolume: 6729,
          organicRank: 306,

          keywordSales: 278
        },
        {
          date: '2024-08-04',
          searchVolume: 6729,
          organicRank: 306,

          keywordSales: 278
        },
        {
          date: '2024-08-03',
          searchVolume: 6729,
          organicRank: 85,

          keywordSales: 278
        },
        {
          date: '2024-08-01',
          searchVolume: 6729,
          organicRank: 75,

          keywordSales: 278
        },
        {
          date: '2024-07-31',
          searchVolume: 6729,
          organicRank: 70,

          keywordSales: 278
        },
        {
          date: '2024-07-30',
          searchVolume: 6729,
          organicRank: 93,

          keywordSales: 278
        },
        {
          date: '2024-07-29',
          searchVolume: 6729,
          organicRank: 84,

          keywordSales: 278
        },
        {
          date: '2024-07-28',
          searchVolume: 6729,
          organicRank: 90,

          keywordSales: 278
        },
        {
          date: '2024-07-27',
          searchVolume: 6729,
          organicRank: 79,

          keywordSales: 278
        },
        {
          date: '2024-07-26',
          searchVolume: 8608,
          organicRank: 79,

          keywordSales: 278
        },
        {
          date: '2024-07-25',
          searchVolume: 8608,
          organicRank: 73,

          keywordSales: 278
        },
        {
          date: '2024-07-24',
          searchVolume: 8608,
          organicRank: 74,

          keywordSales: 278
        },
        {
          date: '2024-07-23',
          searchVolume: 8608,
          organicRank: 74,

          keywordSales: 278
        },
        {
          date: '2024-07-22',
          searchVolume: 8608,
          organicRank: 72,

          keywordSales: 278
        },
        {
          date: '2024-07-21',
          searchVolume: 8608,
          organicRank: 89,

          keywordSales: 278
        },
        {
          date: '2024-07-20',
          searchVolume: 8608,
          organicRank: 72,

          keywordSales: 278
        },
        {
          date: '2024-07-19',
          searchVolume: 7962,
          organicRank: 82,

          keywordSales: 278
        },
        {
          date: '2024-07-18',
          searchVolume: 7962,
          organicRank: 48,

          keywordSales: 278
        },
        {
          date: '2024-07-17',
          searchVolume: 7962,
          organicRank: 68,

          keywordSales: 278
        },
        {
          date: '2024-07-16',
          searchVolume: 7962,
          organicRank: 86,

          keywordSales: 278
        },
        {
          date: '2024-07-15',
          searchVolume: 7962,
          organicRank: 74,

          keywordSales: 278
        },
        {
          date: '2024-07-14',
          searchVolume: 7962,
          organicRank: 72,

          keywordSales: 278
        },
        {
          date: '2024-07-13',
          searchVolume: 7962,
          organicRank: 93,

          keywordSales: 278
        },
        {
          date: '2024-07-12',
          searchVolume: 7336,
          organicRank: 75,

          keywordSales: 278
        },
        {
          date: '2024-07-11',
          searchVolume: 7336,
          organicRank: 82,

          keywordSales: 278
        },
        {
          date: '2024-07-10',
          searchVolume: 7336,
          organicRank: 82,

          keywordSales: 278
        },
        {
          date: '2024-07-09',
          searchVolume: 7336,
          organicRank: 85,

          keywordSales: 278
        },
        {
          date: '2024-07-08',
          searchVolume: 7336,
          organicRank: 89,

          keywordSales: 278
        },
        {
          date: '2024-07-07',
          searchVolume: 7336,
          organicRank: 86,

          keywordSales: 278
        },
        {
          date: '2024-07-06',
          searchVolume: 7336,
          organicRank: 70,

          keywordSales: 278
        },
        {
          date: '2024-07-05',
          searchVolume: 8487,
          organicRank: 94,

          keywordSales: 278
        },
        {
          date: '2024-07-04',
          searchVolume: 8487,
          organicRank: 75,

          keywordSales: 278
        },
        {
          date: '2024-07-03',
          searchVolume: 8487,
          organicRank: 96,

          keywordSales: 278
        },
        {
          date: '2024-07-02',
          searchVolume: 8487,
          organicRank: 78,

          keywordSales: 278
        },
        {
          date: '2024-07-01',
          searchVolume: 8487,
          organicRank: 75,

          keywordSales: 278
        },
        {
          date: '2024-06-30',
          searchVolume: 8487,
          organicRank: 102,

          keywordSales: 278
        },
        {
          date: '2024-06-29',
          searchVolume: 8487,
          organicRank: 98,

          keywordSales: 278
        },
        {
          date: '2024-06-28',
          searchVolume: 9008,
          organicRank: 76,

          keywordSales: 278
        },
        {
          date: '2024-06-27',
          searchVolume: 9008,
          organicRank: 82,

          keywordSales: 278
        },
        {
          date: '2024-06-26',
          searchVolume: 9008,
          organicRank: 70,

          keywordSales: 278
        },
        {
          date: '2024-06-25',
          searchVolume: 9008,
          organicRank: 103,

          keywordSales: 278
        },
        {
          date: '2024-06-24',
          searchVolume: 9008,
          organicRank: 105,

          keywordSales: 278
        },
        {
          date: '2024-06-21',
          searchVolume: 7455,
          organicRank: 76,

          keywordSales: 278
        },
        {
          date: '2024-06-20',
          searchVolume: 7455,
          organicRank: 79,

          keywordSales: 278
        },
        {
          date: '2024-06-19',
          searchVolume: 7455,
          organicRank: 68,

          keywordSales: 278
        },
        {
          date: '2024-06-18',
          searchVolume: 7455,
          organicRank: 79,

          keywordSales: 278
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_rockstar energy drink`,
      keyword: 'rockstar energy drink',
      searchVolume: 18018,
      organicStartRank: 53,
      organicHighestRank: 19,

      organicPageOneWinDate: '2024-06-30',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 14515,
          organicRank: 120,

          keywordSales: 542
        },
        {
          date: '2024-08-06',
          searchVolume: 14515,
          organicRank: 75,

          keywordSales: 542
        },
        {
          date: '2024-08-05',
          searchVolume: 14515,
          organicRank: 102,

          keywordSales: 542
        },
        {
          date: '2024-08-04',
          searchVolume: 14515,
          organicRank: 82,

          keywordSales: 542
        },
        {
          date: '2024-08-03',
          searchVolume: 14515,
          organicRank: 95,

          keywordSales: 542
        },
        {
          date: '2024-08-02',
          searchVolume: 14515,
          organicRank: 89,

          keywordSales: 542
        },
        {
          date: '2024-08-01',
          searchVolume: 14515,
          organicRank: 74,

          keywordSales: 542
        },
        {
          date: '2024-07-31',
          searchVolume: 14515,
          organicRank: 77,

          keywordSales: 542
        },
        {
          date: '2024-07-30',
          searchVolume: 14515,
          organicRank: 97,

          keywordSales: 542
        },
        {
          date: '2024-07-28',
          searchVolume: 14515,
          organicRank: 107,

          keywordSales: 542
        },
        {
          date: '2024-07-27',
          searchVolume: 14515,
          organicRank: 85,

          keywordSales: 542
        },
        {
          date: '2024-07-26',
          searchVolume: 23619,
          organicRank: 93,

          keywordSales: 542
        },
        {
          date: '2024-07-25',
          searchVolume: 23619,
          organicRank: 95,

          keywordSales: 542
        },
        {
          date: '2024-07-24',
          searchVolume: 23619,
          organicRank: 94,

          keywordSales: 542
        },
        {
          date: '2024-07-23',
          searchVolume: 23619,
          organicRank: 92,

          keywordSales: 542
        },
        {
          date: '2024-07-22',
          searchVolume: 23619,
          organicRank: 85,

          keywordSales: 542
        },
        {
          date: '2024-07-21',
          searchVolume: 23619,
          organicRank: 85,

          keywordSales: 542
        },
        {
          date: '2024-07-20',
          searchVolume: 23619,
          organicRank: 83,

          keywordSales: 542
        },
        {
          date: '2024-07-19',
          searchVolume: 17957,
          organicRank: 98,

          keywordSales: 542
        },
        {
          date: '2024-07-18',
          searchVolume: 17957,
          organicRank: 64,

          keywordSales: 542
        },
        {
          date: '2024-07-17',
          searchVolume: 17957,
          organicRank: 124,

          keywordSales: 542
        },
        {
          date: '2024-07-16',
          searchVolume: 17957,
          organicRank: 109,

          keywordSales: 542
        },
        {
          date: '2024-07-15',
          searchVolume: 17957,
          organicRank: 78,

          keywordSales: 542
        },
        {
          date: '2024-07-14',
          searchVolume: 17957,
          organicRank: 93,

          keywordSales: 542
        },
        {
          date: '2024-07-13',
          searchVolume: 17957,
          organicRank: 73,

          keywordSales: 542
        },
        {
          date: '2024-07-12',
          searchVolume: 16873,
          organicRank: 89,

          keywordSales: 542
        },
        {
          date: '2024-07-11',
          searchVolume: 16873,
          organicRank: 43,

          keywordSales: 542
        },
        {
          date: '2024-07-10',
          searchVolume: 16873,
          organicRank: 58,

          keywordSales: 542
        },
        {
          date: '2024-07-09',
          searchVolume: 16873,
          organicRank: 47,

          keywordSales: 542
        },
        {
          date: '2024-07-08',
          searchVolume: 16873,
          organicRank: 48,

          keywordSales: 542
        },
        {
          date: '2024-07-07',
          searchVolume: 16873,
          organicRank: 51,

          keywordSales: 542
        },
        {
          date: '2024-07-06',
          searchVolume: 16873,
          organicRank: 56,

          keywordSales: 542
        },
        {
          date: '2024-07-05',
          searchVolume: 18029,
          organicRank: 48,

          keywordSales: 542
        },
        {
          date: '2024-07-04',
          searchVolume: 18029,
          organicRank: 39,

          keywordSales: 542
        },
        {
          date: '2024-07-03',
          searchVolume: 18029,
          organicRank: 25,

          keywordSales: 542
        },
        {
          date: '2024-07-02',
          searchVolume: 18029,
          organicRank: 22,

          keywordSales: 542
        },
        {
          date: '2024-07-01',
          searchVolume: 18029,
          organicRank: 39,

          keywordSales: 542
        },
        {
          date: '2024-06-30',
          searchVolume: 18029,
          organicRank: 19,

          keywordSales: 542
        },
        {
          date: '2024-06-29',
          searchVolume: 18029,
          organicRank: 39,

          keywordSales: 542
        },
        {
          date: '2024-06-28',
          searchVolume: 17569,
          organicRank: 40,

          keywordSales: 542
        },
        {
          date: '2024-06-27',
          searchVolume: 17569,
          organicRank: 40,

          keywordSales: 542
        },
        {
          date: '2024-06-26',
          searchVolume: 17569,
          organicRank: 39,

          keywordSales: 542
        },
        {
          date: '2024-06-25',
          searchVolume: 17569,
          organicRank: 21,

          keywordSales: 542
        },
        {
          date: '2024-06-24',
          searchVolume: 17569,
          organicRank: 24,

          keywordSales: 542
        },
        {
          date: '2024-06-21',
          searchVolume: 18018,
          organicRank: 28,

          keywordSales: 542
        },
        {
          date: '2024-06-20',
          searchVolume: 18018,
          organicRank: 25,

          keywordSales: 542
        },
        {
          date: '2024-06-19',
          searchVolume: 18018,
          organicRank: 53,

          keywordSales: 542
        },
        {
          date: '2024-06-18',
          searchVolume: 18018,
          organicRank: 55,

          keywordSales: 542
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_ryse energy drink`,
      keyword: 'ryse energy drink',
      searchVolume: 7290,
      organicStartRank: 18,
      organicHighestRank: 4,
      sponsoredStartRank: 24,
      sponsoredHighestRank: 11,
      organicPageOneWinDate: '2024-07-03',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-26',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 6230,
          organicRank: 32,
          sponsoredRank: 35,
          keywordSales: 280
        },
        {
          date: '2024-08-05',
          searchVolume: 6230,
          organicRank: 42,

          keywordSales: 280
        },
        {
          date: '2024-08-04',
          searchVolume: 6230,
          organicRank: 39,

          keywordSales: 280
        },
        {
          date: '2024-08-03',
          searchVolume: 6230,
          organicRank: 35,

          keywordSales: 280
        },
        {
          date: '2024-08-02',
          searchVolume: 6230,
          organicRank: 25,

          keywordSales: 280
        },
        {
          date: '2024-08-01',
          searchVolume: 6230,
          organicRank: 32,

          keywordSales: 280
        },
        {
          date: '2024-07-31',
          searchVolume: 6230,
          organicRank: 19,

          keywordSales: 280
        },
        {
          date: '2024-07-30',
          searchVolume: 6230,
          organicRank: 25,

          keywordSales: 280
        },
        {
          date: '2024-07-29',
          searchVolume: 6230,
          organicRank: 26,

          keywordSales: 280
        },
        {
          date: '2024-07-28',
          searchVolume: 6230,
          organicRank: 25,

          keywordSales: 280
        },
        {
          date: '2024-07-27',
          searchVolume: 6230,
          organicRank: 26,

          keywordSales: 280
        },
        {
          date: '2024-07-26',
          searchVolume: 8097,
          organicRank: 25,
          sponsoredRank: 11,
          keywordSales: 280
        },
        {
          date: '2024-07-25',
          searchVolume: 8097,
          organicRank: 27,

          keywordSales: 280
        },
        {
          date: '2024-07-24',
          searchVolume: 8097,
          organicRank: 31,

          keywordSales: 280
        },
        {
          date: '2024-07-23',
          searchVolume: 8097,
          organicRank: 29,

          keywordSales: 280
        },
        {
          date: '2024-07-22',
          searchVolume: 8097,
          organicRank: 30,

          keywordSales: 280
        },
        {
          date: '2024-07-21',
          searchVolume: 8097,
          organicRank: 39,

          keywordSales: 280
        },
        {
          date: '2024-07-20',
          searchVolume: 8097,
          organicRank: 43,
          sponsoredRank: 24,
          keywordSales: 280
        },
        {
          date: '2024-07-19',
          searchVolume: 6329,
          organicRank: 39,

          keywordSales: 280
        },
        {
          date: '2024-07-18',
          searchVolume: 6329,
          organicRank: 33,

          keywordSales: 280
        },
        {
          date: '2024-07-17',
          searchVolume: 6329,
          organicRank: 33,

          keywordSales: 280
        },
        {
          date: '2024-07-16',
          searchVolume: 6329,
          organicRank: 37,

          keywordSales: 280
        },
        {
          date: '2024-07-15',
          searchVolume: 6329,
          organicRank: 33,

          keywordSales: 280
        },
        {
          date: '2024-07-14',
          searchVolume: 6329,
          organicRank: 33,

          keywordSales: 280
        },
        {
          date: '2024-07-13',
          searchVolume: 6329,
          organicRank: 42,

          keywordSales: 280
        },
        {
          date: '2024-07-12',
          searchVolume: 6453,
          organicRank: 17,

          keywordSales: 280
        },
        {
          date: '2024-07-11',
          searchVolume: 6453,
          organicRank: 18,

          keywordSales: 280
        },
        {
          date: '2024-07-10',
          searchVolume: 6453,
          organicRank: 41,

          keywordSales: 280
        },
        {
          date: '2024-07-09',
          searchVolume: 6453,
          organicRank: 31,

          keywordSales: 280
        },
        {
          date: '2024-07-08',
          searchVolume: 6453,
          organicRank: 34,

          keywordSales: 280
        },
        {
          date: '2024-07-07',
          searchVolume: 6453,
          organicRank: 20,

          keywordSales: 280
        },
        {
          date: '2024-07-06',
          searchVolume: 6453,
          organicRank: 20,

          keywordSales: 280
        },
        {
          date: '2024-07-05',
          searchVolume: 7294,
          organicRank: 19,

          keywordSales: 280
        },
        {
          date: '2024-07-04',
          searchVolume: 7294,
          organicRank: 17,

          keywordSales: 280
        },
        {
          date: '2024-07-03',
          searchVolume: 7294,
          organicRank: 12,

          keywordSales: 280
        },
        {
          date: '2024-07-02',
          searchVolume: 7294,
          organicRank: 27,

          keywordSales: 280
        },
        {
          date: '2024-07-01',
          searchVolume: 7294,
          organicRank: 9,

          keywordSales: 280
        },
        {
          date: '2024-06-30',
          searchVolume: 7294,
          organicRank: 12,

          keywordSales: 280
        },
        {
          date: '2024-06-29',
          searchVolume: 7294,
          organicRank: 4,

          keywordSales: 280
        },
        {
          date: '2024-06-28',
          searchVolume: 7267,
          organicRank: 10,

          keywordSales: 280
        },
        {
          date: '2024-06-27',
          searchVolume: 7267,
          organicRank: 9,

          keywordSales: 280
        },
        {
          date: '2024-06-26',
          searchVolume: 7267,
          organicRank: 16,

          keywordSales: 280
        },
        {
          date: '2024-06-25',
          searchVolume: 7267,
          organicRank: 16,

          keywordSales: 280
        },
        {
          date: '2024-06-24',
          searchVolume: 7267,
          organicRank: 18,

          keywordSales: 280
        },
        {
          date: '2024-06-21',
          searchVolume: 7290,
          organicRank: 20,

          keywordSales: 280
        },
        {
          date: '2024-06-20',
          searchVolume: 7290,
          organicRank: 17,

          keywordSales: 280
        },
        {
          date: '2024-06-19',
          searchVolume: 7290,
          organicRank: 18,

          keywordSales: 280
        },
        {
          date: '2024-06-18',
          searchVolume: 7290,
          organicRank: 16,

          keywordSales: 280
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sneak energy`,
      keyword: 'sneak energy',
      searchVolume: 1921,
      organicStartRank: 20,
      organicHighestRank: 12,

      organicPageOneWinDate: '2024-06-19',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 1771,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-08-05',
          searchVolume: 1771,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-08-04',
          searchVolume: 1771,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-08-02',
          searchVolume: 1771,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-08-01',
          searchVolume: 1771,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-07-31',
          searchVolume: 1771,
          organicRank: 306,

          keywordSales: 58
        },
        {
          date: '2024-07-30',
          searchVolume: 1771,
          organicRank: 15,

          keywordSales: 58
        },
        {
          date: '2024-07-29',
          searchVolume: 1771,
          organicRank: 12,

          keywordSales: 58
        },
        {
          date: '2024-07-28',
          searchVolume: 1771,
          organicRank: 12,

          keywordSales: 58
        },
        {
          date: '2024-07-27',
          searchVolume: 1771,
          organicRank: 34,

          keywordSales: 58
        },
        {
          date: '2024-07-26',
          searchVolume: 1832,
          organicRank: 27,

          keywordSales: 58
        },
        {
          date: '2024-07-25',
          searchVolume: 1832,
          organicRank: 27,

          keywordSales: 58
        },
        {
          date: '2024-07-24',
          searchVolume: 1832,
          organicRank: 33,

          keywordSales: 58
        },
        {
          date: '2024-07-23',
          searchVolume: 1832,
          organicRank: 33,

          keywordSales: 58
        },
        {
          date: '2024-07-22',
          searchVolume: 1832,
          organicRank: 30,

          keywordSales: 58
        },
        {
          date: '2024-07-21',
          searchVolume: 1832,
          organicRank: 29,

          keywordSales: 58
        },
        {
          date: '2024-07-20',
          searchVolume: 1832,
          organicRank: 31,

          keywordSales: 58
        },
        {
          date: '2024-07-19',
          searchVolume: 1796,
          organicRank: 37,

          keywordSales: 58
        },
        {
          date: '2024-07-18',
          searchVolume: 1796,
          organicRank: 64,

          keywordSales: 58
        },
        {
          date: '2024-07-17',
          searchVolume: 1796,
          organicRank: 30,

          keywordSales: 58
        },
        {
          date: '2024-07-16',
          searchVolume: 1796,
          organicRank: 34,

          keywordSales: 58
        },
        {
          date: '2024-07-15',
          searchVolume: 1796,
          organicRank: 30,

          keywordSales: 58
        },
        {
          date: '2024-07-14',
          searchVolume: 1796,
          organicRank: 29,

          keywordSales: 58
        },
        {
          date: '2024-07-13',
          searchVolume: 1796,
          organicRank: 30,

          keywordSales: 58
        },
        {
          date: '2024-07-12',
          searchVolume: 1613,
          organicRank: 31,

          keywordSales: 58
        },
        {
          date: '2024-07-10',
          searchVolume: 1613,
          organicRank: 29,

          keywordSales: 58
        },
        {
          date: '2024-07-09',
          searchVolume: 1613,
          organicRank: 24,

          keywordSales: 58
        },
        {
          date: '2024-07-08',
          searchVolume: 1613,
          organicRank: 29,

          keywordSales: 58
        },
        {
          date: '2024-07-07',
          searchVolume: 1613,
          organicRank: 29,

          keywordSales: 58
        },
        {
          date: '2024-07-06',
          searchVolume: 1613,
          organicRank: 24,

          keywordSales: 58
        },
        {
          date: '2024-07-05',
          searchVolume: 1919,
          organicRank: 25,

          keywordSales: 58
        },
        {
          date: '2024-07-04',
          searchVolume: 1919,
          organicRank: 24,

          keywordSales: 58
        },
        {
          date: '2024-07-03',
          searchVolume: 1919,
          organicRank: 25,

          keywordSales: 58
        },
        {
          date: '2024-07-02',
          searchVolume: 1919,
          organicRank: 24,

          keywordSales: 58
        },
        {
          date: '2024-07-01',
          searchVolume: 1919,
          organicRank: 25,

          keywordSales: 58
        },
        {
          date: '2024-06-30',
          searchVolume: 1919,
          organicRank: 25,

          keywordSales: 58
        },
        {
          date: '2024-06-29',
          searchVolume: 1919,
          organicRank: 23,

          keywordSales: 58
        },
        {
          date: '2024-06-28',
          searchVolume: 1909,
          organicRank: 24,

          keywordSales: 58
        },
        {
          date: '2024-06-27',
          searchVolume: 1909,
          organicRank: 24,

          keywordSales: 58
        },
        {
          date: '2024-06-26',
          searchVolume: 1909,
          organicRank: 22,

          keywordSales: 58
        },
        {
          date: '2024-06-25',
          searchVolume: 1909,
          organicRank: 23,

          keywordSales: 58
        },
        {
          date: '2024-06-21',
          searchVolume: 1921,
          organicRank: 25,

          keywordSales: 58
        },
        {
          date: '2024-06-20',
          searchVolume: 1921,
          organicRank: 22,

          keywordSales: 58
        },
        {
          date: '2024-06-19',
          searchVolume: 1921,
          organicRank: 20,

          keywordSales: 58
        },
        {
          date: '2024-06-18',
          searchVolume: 1921,
          organicRank: 22,

          keywordSales: 58
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sparkling ice caffeine`,
      keyword: 'sparkling ice caffeine',
      searchVolume: 10764,
      organicStartRank: 69,
      organicHighestRank: 29,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 8565,
          organicRank: 42,

          keywordSales: 263
        },
        {
          date: '2024-08-06',
          searchVolume: 8565,
          organicRank: 48,

          keywordSales: 263
        },
        {
          date: '2024-08-05',
          searchVolume: 8565,
          organicRank: 53,

          keywordSales: 263
        },
        {
          date: '2024-08-04',
          searchVolume: 8565,
          organicRank: 55,

          keywordSales: 263
        },
        {
          date: '2024-08-03',
          searchVolume: 8565,
          organicRank: 56,

          keywordSales: 263
        },
        {
          date: '2024-08-01',
          searchVolume: 8565,
          organicRank: 52,

          keywordSales: 263
        },
        {
          date: '2024-07-31',
          searchVolume: 8565,
          organicRank: 50,

          keywordSales: 263
        },
        {
          date: '2024-07-30',
          searchVolume: 8565,
          organicRank: 49,

          keywordSales: 263
        },
        {
          date: '2024-07-29',
          searchVolume: 8565,
          organicRank: 48,

          keywordSales: 263
        },
        {
          date: '2024-07-28',
          searchVolume: 8565,
          organicRank: 49,

          keywordSales: 263
        },
        {
          date: '2024-07-27',
          searchVolume: 8565,
          organicRank: 53,

          keywordSales: 263
        },
        {
          date: '2024-07-26',
          searchVolume: 10574,
          organicRank: 47,

          keywordSales: 263
        },
        {
          date: '2024-07-25',
          searchVolume: 10574,
          organicRank: 67,

          keywordSales: 263
        },
        {
          date: '2024-07-24',
          searchVolume: 10574,
          organicRank: 63,

          keywordSales: 263
        },
        {
          date: '2024-07-23',
          searchVolume: 10574,
          organicRank: 58,

          keywordSales: 263
        },
        {
          date: '2024-07-22',
          searchVolume: 10574,
          organicRank: 40,

          keywordSales: 263
        },
        {
          date: '2024-07-21',
          searchVolume: 10574,
          organicRank: 38,

          keywordSales: 263
        },
        {
          date: '2024-07-20',
          searchVolume: 10574,
          organicRank: 43,

          keywordSales: 263
        },
        {
          date: '2024-07-19',
          searchVolume: 8711,
          organicRank: 29,

          keywordSales: 263
        },
        {
          date: '2024-07-18',
          searchVolume: 8711,
          organicRank: 33,

          keywordSales: 263
        },
        {
          date: '2024-07-17',
          searchVolume: 8711,
          organicRank: 52,

          keywordSales: 263
        },
        {
          date: '2024-07-16',
          searchVolume: 8711,
          organicRank: 48,

          keywordSales: 263
        },
        {
          date: '2024-07-15',
          searchVolume: 8711,
          organicRank: 54,

          keywordSales: 263
        },
        {
          date: '2024-07-14',
          searchVolume: 8711,
          organicRank: 56,

          keywordSales: 263
        },
        {
          date: '2024-07-13',
          searchVolume: 8711,
          organicRank: 75,

          keywordSales: 263
        },
        {
          date: '2024-07-12',
          searchVolume: 8636,
          organicRank: 36,

          keywordSales: 263
        },
        {
          date: '2024-07-11',
          searchVolume: 8636,
          organicRank: 61,

          keywordSales: 263
        },
        {
          date: '2024-07-10',
          searchVolume: 8636,
          organicRank: 42,

          keywordSales: 263
        },
        {
          date: '2024-07-09',
          searchVolume: 8636,
          organicRank: 42,

          keywordSales: 263
        },
        {
          date: '2024-07-08',
          searchVolume: 8636,
          organicRank: 47,

          keywordSales: 263
        },
        {
          date: '2024-07-07',
          searchVolume: 8636,
          organicRank: 35,

          keywordSales: 263
        },
        {
          date: '2024-07-06',
          searchVolume: 8636,
          organicRank: 34,

          keywordSales: 263
        },
        {
          date: '2024-07-05',
          searchVolume: 10806,
          organicRank: 48,

          keywordSales: 263
        },
        {
          date: '2024-07-04',
          searchVolume: 10806,
          organicRank: 54,

          keywordSales: 263
        },
        {
          date: '2024-07-03',
          searchVolume: 10806,
          organicRank: 55,

          keywordSales: 263
        },
        {
          date: '2024-07-02',
          searchVolume: 10806,
          organicRank: 44,

          keywordSales: 263
        },
        {
          date: '2024-07-01',
          searchVolume: 10806,
          organicRank: 36,

          keywordSales: 263
        },
        {
          date: '2024-06-30',
          searchVolume: 10806,
          organicRank: 57,

          keywordSales: 263
        },
        {
          date: '2024-06-29',
          searchVolume: 10806,
          organicRank: 35,

          keywordSales: 263
        },
        {
          date: '2024-06-28',
          searchVolume: 11028,
          organicRank: 38,

          keywordSales: 263
        },
        {
          date: '2024-06-27',
          searchVolume: 11028,
          organicRank: 49,

          keywordSales: 263
        },
        {
          date: '2024-06-26',
          searchVolume: 11028,
          organicRank: 58,

          keywordSales: 263
        },
        {
          date: '2024-06-25',
          searchVolume: 11028,
          organicRank: 65,

          keywordSales: 263
        },
        {
          date: '2024-06-24',
          searchVolume: 11028,
          organicRank: 50,

          keywordSales: 263
        },
        {
          date: '2024-06-21',
          searchVolume: 10764,
          organicRank: 30,

          keywordSales: 263
        },
        {
          date: '2024-06-20',
          searchVolume: 10764,
          organicRank: 37,

          keywordSales: 263
        },
        {
          date: '2024-06-19',
          searchVolume: 10764,
          organicRank: 69,

          keywordSales: 263
        },
        {
          date: '2024-06-18',
          searchVolume: 10764,
          organicRank: 72,

          keywordSales: 263
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sports drinks`,
      keyword: 'sports drinks',
      searchVolume: 5420,
      organicStartRank: 98,
      organicHighestRank: 17,

      organicPageOneWinDate: '2024-07-03',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 4543,
          organicRank: 306,

          keywordSales: 224
        },
        {
          date: '2024-08-06',
          searchVolume: 4543,
          organicRank: 306,

          keywordSales: 224
        },
        {
          date: '2024-08-05',
          searchVolume: 4543,
          organicRank: 306,

          keywordSales: 224
        },
        {
          date: '2024-08-04',
          searchVolume: 4543,
          organicRank: 306,

          keywordSales: 224
        },
        {
          date: '2024-08-02',
          searchVolume: 4543,
          organicRank: 306,

          keywordSales: 224
        },
        {
          date: '2024-08-01',
          searchVolume: 4543,
          organicRank: 306,

          keywordSales: 224
        },
        {
          date: '2024-07-31',
          searchVolume: 4543,
          organicRank: 34,

          keywordSales: 224
        },
        {
          date: '2024-07-30',
          searchVolume: 4543,
          organicRank: 31,

          keywordSales: 224
        },
        {
          date: '2024-07-28',
          searchVolume: 4543,
          organicRank: 30,

          keywordSales: 224
        },
        {
          date: '2024-07-27',
          searchVolume: 4543,
          organicRank: 33,

          keywordSales: 224
        },
        {
          date: '2024-07-26',
          searchVolume: 5656,
          organicRank: 35,

          keywordSales: 224
        },
        {
          date: '2024-07-25',
          searchVolume: 5656,
          organicRank: 28,

          keywordSales: 224
        },
        {
          date: '2024-07-24',
          searchVolume: 5656,
          organicRank: 27,

          keywordSales: 224
        },
        {
          date: '2024-07-23',
          searchVolume: 5656,
          organicRank: 306,

          keywordSales: 224
        },
        {
          date: '2024-07-22',
          searchVolume: 5656,
          organicRank: 39,

          keywordSales: 224
        },
        {
          date: '2024-07-21',
          searchVolume: 5656,
          organicRank: 38,

          keywordSales: 224
        },
        {
          date: '2024-07-20',
          searchVolume: 5656,
          organicRank: 29,

          keywordSales: 224
        },
        {
          date: '2024-07-19',
          searchVolume: 5567,
          organicRank: 45,

          keywordSales: 224
        },
        {
          date: '2024-07-18',
          searchVolume: 5567,
          organicRank: 44,

          keywordSales: 224
        },
        {
          date: '2024-07-17',
          searchVolume: 5567,
          organicRank: 306,

          keywordSales: 224
        },
        {
          date: '2024-07-16',
          searchVolume: 5567,
          organicRank: 35,

          keywordSales: 224
        },
        {
          date: '2024-07-15',
          searchVolume: 5567,
          organicRank: 29,

          keywordSales: 224
        },
        {
          date: '2024-07-14',
          searchVolume: 5567,
          organicRank: 29,

          keywordSales: 224
        },
        {
          date: '2024-07-13',
          searchVolume: 5567,
          organicRank: 28,

          keywordSales: 224
        },
        {
          date: '2024-07-12',
          searchVolume: 4706,
          organicRank: 33,

          keywordSales: 224
        },
        {
          date: '2024-07-11',
          searchVolume: 4706,
          organicRank: 29,

          keywordSales: 224
        },
        {
          date: '2024-07-10',
          searchVolume: 4706,
          organicRank: 33,

          keywordSales: 224
        },
        {
          date: '2024-07-09',
          searchVolume: 4706,
          organicRank: 27,

          keywordSales: 224
        },
        {
          date: '2024-07-08',
          searchVolume: 4706,
          organicRank: 28,

          keywordSales: 224
        },
        {
          date: '2024-07-07',
          searchVolume: 4706,
          organicRank: 25,

          keywordSales: 224
        },
        {
          date: '2024-07-06',
          searchVolume: 4706,
          organicRank: 26,

          keywordSales: 224
        },
        {
          date: '2024-07-05',
          searchVolume: 5701,
          organicRank: 26,

          keywordSales: 224
        },
        {
          date: '2024-07-04',
          searchVolume: 5701,
          organicRank: 21,

          keywordSales: 224
        },
        {
          date: '2024-07-03',
          searchVolume: 5701,
          organicRank: 17,

          keywordSales: 224
        },
        {
          date: '2024-07-02',
          searchVolume: 5701,
          organicRank: 35,

          keywordSales: 224
        },
        {
          date: '2024-07-01',
          searchVolume: 5701,
          organicRank: 39,

          keywordSales: 224
        },
        {
          date: '2024-06-30',
          searchVolume: 5701,
          organicRank: 46,

          keywordSales: 224
        },
        {
          date: '2024-06-29',
          searchVolume: 5701,
          organicRank: 51,

          keywordSales: 224
        },
        {
          date: '2024-06-28',
          searchVolume: 6727,
          organicRank: 47,

          keywordSales: 224
        },
        {
          date: '2024-06-27',
          searchVolume: 6727,
          organicRank: 61,

          keywordSales: 224
        },
        {
          date: '2024-06-26',
          searchVolume: 6727,
          organicRank: 58,

          keywordSales: 224
        },
        {
          date: '2024-06-25',
          searchVolume: 6727,
          organicRank: 54,

          keywordSales: 224
        },
        {
          date: '2024-06-21',
          searchVolume: 5420,
          organicRank: 59,

          keywordSales: 224
        },
        {
          date: '2024-06-20',
          searchVolume: 5420,
          organicRank: 56,

          keywordSales: 224
        },
        {
          date: '2024-06-19',
          searchVolume: 5420,
          organicRank: 98,

          keywordSales: 224
        },
        {
          date: '2024-06-18',
          searchVolume: 5420,
          organicRank: 53,

          keywordSales: 224
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sugar free`,
      keyword: 'sugar free',
      searchVolume: 17653,
      organicStartRank: 123,
      organicHighestRank: 117,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 14483,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-08-06',
          searchVolume: 14483,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-08-05',
          searchVolume: 14483,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-08-02',
          searchVolume: 14483,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-08-01',
          searchVolume: 14483,
          organicRank: 161,

          keywordSales: 488
        },
        {
          date: '2024-07-31',
          searchVolume: 14483,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-30',
          searchVolume: 14483,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-29',
          searchVolume: 14483,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-28',
          searchVolume: 14483,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-27',
          searchVolume: 14483,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-26',
          searchVolume: 15937,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-25',
          searchVolume: 15937,
          organicRank: 278,

          keywordSales: 488
        },
        {
          date: '2024-07-24',
          searchVolume: 15937,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-23',
          searchVolume: 15937,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-22',
          searchVolume: 15937,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-21',
          searchVolume: 15937,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-20',
          searchVolume: 15937,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-19',
          searchVolume: 15660,
          organicRank: 285,

          keywordSales: 488
        },
        {
          date: '2024-07-18',
          searchVolume: 15660,
          organicRank: 138,

          keywordSales: 488
        },
        {
          date: '2024-07-17',
          searchVolume: 15660,
          organicRank: 125,

          keywordSales: 488
        },
        {
          date: '2024-07-16',
          searchVolume: 15660,
          organicRank: 156,

          keywordSales: 488
        },
        {
          date: '2024-07-15',
          searchVolume: 15660,
          organicRank: 147,

          keywordSales: 488
        },
        {
          date: '2024-07-14',
          searchVolume: 15660,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-13',
          searchVolume: 15660,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-12',
          searchVolume: 15962,
          organicRank: 128,

          keywordSales: 488
        },
        {
          date: '2024-07-10',
          searchVolume: 15962,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-09',
          searchVolume: 15962,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-08',
          searchVolume: 15962,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-07',
          searchVolume: 15962,
          organicRank: 269,

          keywordSales: 488
        },
        {
          date: '2024-07-06',
          searchVolume: 15962,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-05',
          searchVolume: 15744,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-04',
          searchVolume: 15744,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-03',
          searchVolume: 15744,
          organicRank: 270,

          keywordSales: 488
        },
        {
          date: '2024-07-02',
          searchVolume: 15744,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-07-01',
          searchVolume: 15744,
          organicRank: 139,

          keywordSales: 488
        },
        {
          date: '2024-06-30',
          searchVolume: 15744,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-06-29',
          searchVolume: 15744,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-06-28',
          searchVolume: 15630,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-06-27',
          searchVolume: 15630,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-06-26',
          searchVolume: 15630,
          organicRank: 117,

          keywordSales: 488
        },
        {
          date: '2024-06-25',
          searchVolume: 15630,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-06-24',
          searchVolume: 15630,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-06-21',
          searchVolume: 17653,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-06-20',
          searchVolume: 17653,
          organicRank: 306,

          keywordSales: 488
        },
        {
          date: '2024-06-19',
          searchVolume: 17653,
          organicRank: 123,

          keywordSales: 488
        },
        {
          date: '2024-06-18',
          searchVolume: 17653,
          organicRank: 102,

          keywordSales: 488
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sugar free drinks`,
      keyword: 'sugar free drinks',
      searchVolume: 2816,
      organicStartRank: 70,
      organicHighestRank: 32,
      sponsoredStartRank: 79,
      sponsoredHighestRank: 79,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2351,
          organicRank: 111,

          keywordSales: 113
        },
        {
          date: '2024-08-05',
          searchVolume: 2351,
          organicRank: 120,

          keywordSales: 113
        },
        {
          date: '2024-08-04',
          searchVolume: 2351,
          organicRank: 115,

          keywordSales: 113
        },
        {
          date: '2024-08-02',
          searchVolume: 2351,
          organicRank: 105,

          keywordSales: 113
        },
        {
          date: '2024-08-01',
          searchVolume: 2351,
          organicRank: 110,

          keywordSales: 113
        },
        {
          date: '2024-07-31',
          searchVolume: 2351,
          organicRank: 95,

          keywordSales: 113
        },
        {
          date: '2024-07-30',
          searchVolume: 2351,
          organicRank: 46,

          keywordSales: 113
        },
        {
          date: '2024-07-29',
          searchVolume: 2351,
          organicRank: 39,

          keywordSales: 113
        },
        {
          date: '2024-07-28',
          searchVolume: 2351,
          organicRank: 98,

          keywordSales: 113
        },
        {
          date: '2024-07-27',
          searchVolume: 2351,
          organicRank: 106,

          keywordSales: 113
        },
        {
          date: '2024-07-26',
          searchVolume: 2435,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-07-25',
          searchVolume: 2435,
          organicRank: 88,

          keywordSales: 113
        },
        {
          date: '2024-07-24',
          searchVolume: 2435,
          organicRank: 103,

          keywordSales: 113
        },
        {
          date: '2024-07-23',
          searchVolume: 2435,
          organicRank: 102,

          keywordSales: 113
        },
        {
          date: '2024-07-22',
          searchVolume: 2435,
          organicRank: 85,

          keywordSales: 113
        },
        {
          date: '2024-07-21',
          searchVolume: 2435,
          organicRank: 95,

          keywordSales: 113
        },
        {
          date: '2024-07-20',
          searchVolume: 2435,
          organicRank: 90,

          keywordSales: 113
        },
        {
          date: '2024-07-19',
          searchVolume: 2395,
          organicRank: 70,

          keywordSales: 113
        },
        {
          date: '2024-07-18',
          searchVolume: 2395,
          organicRank: 61,

          keywordSales: 113
        },
        {
          date: '2024-07-17',
          searchVolume: 2395,
          organicRank: 88,

          keywordSales: 113
        },
        {
          date: '2024-07-16',
          searchVolume: 2395,
          organicRank: 124,

          keywordSales: 113
        },
        {
          date: '2024-07-15',
          searchVolume: 2395,
          organicRank: 42,

          keywordSales: 113
        },
        {
          date: '2024-07-14',
          searchVolume: 2395,
          organicRank: 109,

          keywordSales: 113
        },
        {
          date: '2024-07-13',
          searchVolume: 2395,
          organicRank: 116,

          keywordSales: 113
        },
        {
          date: '2024-07-12',
          searchVolume: 2439,
          organicRank: 40,

          keywordSales: 113
        },
        {
          date: '2024-07-10',
          searchVolume: 2439,
          organicRank: 34,

          keywordSales: 113
        },
        {
          date: '2024-07-09',
          searchVolume: 2439,
          organicRank: 101,

          keywordSales: 113
        },
        {
          date: '2024-07-08',
          searchVolume: 2439,
          organicRank: 115,

          keywordSales: 113
        },
        {
          date: '2024-07-07',
          searchVolume: 2439,
          organicRank: 35,

          keywordSales: 113
        },
        {
          date: '2024-07-06',
          searchVolume: 2439,
          organicRank: 34,

          keywordSales: 113
        },
        {
          date: '2024-07-05',
          searchVolume: 2554,
          organicRank: 124,

          keywordSales: 113
        },
        {
          date: '2024-07-04',
          searchVolume: 2554,
          organicRank: 35,

          keywordSales: 113
        },
        {
          date: '2024-07-03',
          searchVolume: 2554,
          organicRank: 32,

          keywordSales: 113
        },
        {
          date: '2024-07-02',
          searchVolume: 2554,
          organicRank: 33,

          keywordSales: 113
        },
        {
          date: '2024-07-01',
          searchVolume: 2554,
          organicRank: 100,

          keywordSales: 113
        },
        {
          date: '2024-06-30',
          searchVolume: 2554,
          organicRank: 110,

          keywordSales: 113
        },
        {
          date: '2024-06-29',
          searchVolume: 2554,
          organicRank: 85,

          keywordSales: 113
        },
        {
          date: '2024-06-28',
          searchVolume: 2543,
          organicRank: 37,

          keywordSales: 113
        },
        {
          date: '2024-06-27',
          searchVolume: 2543,
          organicRank: 306,

          keywordSales: 113
        },
        {
          date: '2024-06-26',
          searchVolume: 2543,
          organicRank: 87,

          keywordSales: 113
        },
        {
          date: '2024-06-25',
          searchVolume: 2543,
          organicRank: 93,

          keywordSales: 113
        },
        {
          date: '2024-06-24',
          searchVolume: 2543,
          organicRank: 34,

          keywordSales: 113
        },
        {
          date: '2024-06-21',
          searchVolume: 2816,
          organicRank: 101,

          keywordSales: 113
        },
        {
          date: '2024-06-20',
          searchVolume: 2816,
          organicRank: 87,
          sponsoredRank: 79,
          keywordSales: 113
        },
        {
          date: '2024-06-19',
          searchVolume: 2816,
          organicRank: 70,

          keywordSales: 113
        },
        {
          date: '2024-06-18',
          searchVolume: 2816,
          organicRank: 71,

          keywordSales: 113
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sugar free energy drink`,
      keyword: 'sugar free energy drink',
      searchVolume: 1345,
      organicStartRank: 8,
      organicHighestRank: 3,
      sponsoredStartRank: 42,
      sponsoredHighestRank: 2,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 1766,
          organicRank: 18,

          keywordSales: 41
        },
        {
          date: '2024-08-06',
          searchVolume: 1766,
          organicRank: 14,

          keywordSales: 41
        },
        {
          date: '2024-08-05',
          searchVolume: 1766,
          organicRank: 15,
          sponsoredRank: 2,
          keywordSales: 41
        },
        {
          date: '2024-08-04',
          searchVolume: 1766,
          organicRank: 15,

          keywordSales: 41
        },
        {
          date: '2024-08-03',
          searchVolume: 1766,
          organicRank: 18,

          keywordSales: 41
        },
        {
          date: '2024-08-02',
          searchVolume: 1766,
          organicRank: 14,

          keywordSales: 41
        },
        {
          date: '2024-08-01',
          searchVolume: 1766,
          organicRank: 17,

          keywordSales: 41
        },
        {
          date: '2024-07-31',
          searchVolume: 1766,
          organicRank: 7,

          keywordSales: 41
        },
        {
          date: '2024-07-30',
          searchVolume: 1766,
          organicRank: 7,

          keywordSales: 41
        },
        {
          date: '2024-07-28',
          searchVolume: 1766,
          organicRank: 11,

          keywordSales: 41
        },
        {
          date: '2024-07-27',
          searchVolume: 1766,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-26',
          searchVolume: 2087,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-25',
          searchVolume: 2087,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-24',
          searchVolume: 2087,
          organicRank: 6,

          keywordSales: 41
        },
        {
          date: '2024-07-23',
          searchVolume: 2087,
          organicRank: 9,
          sponsoredRank: 60,
          keywordSales: 41
        },
        {
          date: '2024-07-22',
          searchVolume: 2087,
          organicRank: 11,
          sponsoredRank: 45,
          keywordSales: 41
        },
        {
          date: '2024-07-21',
          searchVolume: 2087,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-20',
          searchVolume: 2087,
          organicRank: 11,

          keywordSales: 41
        },
        {
          date: '2024-07-19',
          searchVolume: 1269,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-07-18',
          searchVolume: 1269,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-07-17',
          searchVolume: 1269,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-16',
          searchVolume: 1269,
          organicRank: 16,

          keywordSales: 41
        },
        {
          date: '2024-07-15',
          searchVolume: 1269,
          organicRank: 19,

          keywordSales: 41
        },
        {
          date: '2024-07-14',
          searchVolume: 1269,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-13',
          searchVolume: 1269,
          organicRank: 18,

          keywordSales: 41
        },
        {
          date: '2024-07-12',
          searchVolume: 1343,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-11',
          searchVolume: 1343,
          organicRank: 9,

          keywordSales: 41
        },
        {
          date: '2024-07-10',
          searchVolume: 1343,
          organicRank: 14,

          keywordSales: 41
        },
        {
          date: '2024-07-09',
          searchVolume: 1343,
          organicRank: 17,

          keywordSales: 41
        },
        {
          date: '2024-07-08',
          searchVolume: 1343,
          organicRank: 16,

          keywordSales: 41
        },
        {
          date: '2024-07-07',
          searchVolume: 1343,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-07-06',
          searchVolume: 1343,
          organicRank: 14,

          keywordSales: 41
        },
        {
          date: '2024-07-05',
          searchVolume: 1432,
          organicRank: 14,

          keywordSales: 41
        },
        {
          date: '2024-07-04',
          searchVolume: 1432,
          organicRank: 6,

          keywordSales: 41
        },
        {
          date: '2024-07-03',
          searchVolume: 1432,
          organicRank: 3,

          keywordSales: 41
        },
        {
          date: '2024-07-02',
          searchVolume: 1432,
          organicRank: 4,

          keywordSales: 41
        },
        {
          date: '2024-07-01',
          searchVolume: 1432,
          organicRank: 14,

          keywordSales: 41
        },
        {
          date: '2024-06-30',
          searchVolume: 1432,
          organicRank: 3,

          keywordSales: 41
        },
        {
          date: '2024-06-29',
          searchVolume: 1432,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-06-28',
          searchVolume: 1428,
          organicRank: 5,

          keywordSales: 41
        },
        {
          date: '2024-06-27',
          searchVolume: 1428,
          organicRank: 13,

          keywordSales: 41
        },
        {
          date: '2024-06-26',
          searchVolume: 1428,
          organicRank: 13,

          keywordSales: 41
        },
        {
          date: '2024-06-25',
          searchVolume: 1428,
          organicRank: 5,

          keywordSales: 41
        },
        {
          date: '2024-06-21',
          searchVolume: 1345,
          organicRank: 8,

          keywordSales: 41
        },
        {
          date: '2024-06-20',
          searchVolume: 1345,
          organicRank: 11,
          sponsoredRank: 10,
          keywordSales: 41
        },
        {
          date: '2024-06-19',
          searchVolume: 1345,
          organicRank: 8,
          sponsoredRank: 42,
          keywordSales: 41
        },
        {
          date: '2024-06-18',
          searchVolume: 1345,
          organicRank: 8,

          keywordSales: 41
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sugar free energy drinks`,
      keyword: 'sugar free energy drinks',
      searchVolume: 4380,
      organicStartRank: 8,
      organicHighestRank: 5,
      sponsoredStartRank: 28,
      sponsoredHighestRank: 21,
      organicPageOneWinDate: '2024-07-08',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 3352,
          organicRank: 17,
          sponsoredRank: 58,
          keywordSales: 195
        },
        {
          date: '2024-08-06',
          searchVolume: 3352,
          organicRank: 16,

          keywordSales: 195
        },
        {
          date: '2024-08-05',
          searchVolume: 3352,
          organicRank: 11,

          keywordSales: 195
        },
        {
          date: '2024-08-04',
          searchVolume: 3352,
          organicRank: 15,

          keywordSales: 195
        },
        {
          date: '2024-08-02',
          searchVolume: 3352,
          organicRank: 7,

          keywordSales: 195
        },
        {
          date: '2024-08-01',
          searchVolume: 3352,
          organicRank: 15,

          keywordSales: 195
        },
        {
          date: '2024-07-31',
          searchVolume: 3352,
          organicRank: 14,

          keywordSales: 195
        },
        {
          date: '2024-07-30',
          searchVolume: 3352,
          organicRank: 8,

          keywordSales: 195
        },
        {
          date: '2024-07-29',
          searchVolume: 3352,
          organicRank: 9,

          keywordSales: 195
        },
        {
          date: '2024-07-28',
          searchVolume: 3352,
          organicRank: 10,

          keywordSales: 195
        },
        {
          date: '2024-07-27',
          searchVolume: 3352,
          organicRank: 11,

          keywordSales: 195
        },
        {
          date: '2024-07-26',
          searchVolume: 4342,
          organicRank: 9,

          keywordSales: 195
        },
        {
          date: '2024-07-25',
          searchVolume: 4342,
          organicRank: 9,

          keywordSales: 195
        },
        {
          date: '2024-07-24',
          searchVolume: 4342,
          organicRank: 11,

          keywordSales: 195
        },
        {
          date: '2024-07-23',
          searchVolume: 4342,
          organicRank: 11,

          keywordSales: 195
        },
        {
          date: '2024-07-22',
          searchVolume: 4342,
          organicRank: 11,

          keywordSales: 195
        },
        {
          date: '2024-07-21',
          searchVolume: 4342,
          organicRank: 11,

          keywordSales: 195
        },
        {
          date: '2024-07-20',
          searchVolume: 4342,
          organicRank: 10,

          keywordSales: 195
        },
        {
          date: '2024-07-19',
          searchVolume: 4056,
          organicRank: 8,

          keywordSales: 195
        },
        {
          date: '2024-07-18',
          searchVolume: 4056,
          organicRank: 13,

          keywordSales: 195
        },
        {
          date: '2024-07-17',
          searchVolume: 4056,
          organicRank: 15,

          keywordSales: 195
        },
        {
          date: '2024-07-16',
          searchVolume: 4056,
          organicRank: 13,

          keywordSales: 195
        },
        {
          date: '2024-07-15',
          searchVolume: 4056,
          organicRank: 14,
          sponsoredRank: 21,
          keywordSales: 195
        },
        {
          date: '2024-07-14',
          searchVolume: 4056,
          organicRank: 14,

          keywordSales: 195
        },
        {
          date: '2024-07-13',
          searchVolume: 4056,
          organicRank: 14,

          keywordSales: 195
        },
        {
          date: '2024-07-12',
          searchVolume: 4134,
          organicRank: 15,

          keywordSales: 195
        },
        {
          date: '2024-07-11',
          searchVolume: 4134,
          organicRank: 11,

          keywordSales: 195
        },
        {
          date: '2024-07-09',
          searchVolume: 4134,
          organicRank: 11,

          keywordSales: 195
        },
        {
          date: '2024-07-08',
          searchVolume: 4134,
          organicRank: 14,

          keywordSales: 195
        },
        {
          date: '2024-07-07',
          searchVolume: 4134,
          organicRank: 25,

          keywordSales: 195
        },
        {
          date: '2024-07-06',
          searchVolume: 4134,
          organicRank: 15,

          keywordSales: 195
        },
        {
          date: '2024-07-05',
          searchVolume: 4383,
          organicRank: 14,

          keywordSales: 195
        },
        {
          date: '2024-07-04',
          searchVolume: 4383,
          organicRank: 14,

          keywordSales: 195
        },
        {
          date: '2024-07-03',
          searchVolume: 4383,
          organicRank: 5,

          keywordSales: 195
        },
        {
          date: '2024-07-02',
          searchVolume: 4383,
          organicRank: 8,

          keywordSales: 195
        },
        {
          date: '2024-07-01',
          searchVolume: 4383,
          organicRank: 8,

          keywordSales: 195
        },
        {
          date: '2024-06-30',
          searchVolume: 4383,
          organicRank: 10,

          keywordSales: 195
        },
        {
          date: '2024-06-29',
          searchVolume: 4383,
          organicRank: 17,

          keywordSales: 195
        },
        {
          date: '2024-06-28',
          searchVolume: 4535,
          organicRank: 10,

          keywordSales: 195
        },
        {
          date: '2024-06-27',
          searchVolume: 4535,
          organicRank: 8,

          keywordSales: 195
        },
        {
          date: '2024-06-26',
          searchVolume: 4535,
          organicRank: 6,

          keywordSales: 195
        },
        {
          date: '2024-06-25',
          searchVolume: 4535,
          organicRank: 5,

          keywordSales: 195
        },
        {
          date: '2024-06-23',
          searchVolume: 4535,
          organicRank: 6,

          keywordSales: 195
        },
        {
          date: '2024-06-21',
          searchVolume: 4380,
          organicRank: 7,
          sponsoredRank: 28,
          keywordSales: 195
        },
        {
          date: '2024-06-20',
          searchVolume: 4380,
          organicRank: 14,

          keywordSales: 195
        },
        {
          date: '2024-06-19',
          searchVolume: 4380,
          organicRank: 8,

          keywordSales: 195
        },
        {
          date: '2024-06-18',
          searchVolume: 4380,
          organicRank: 8,

          keywordSales: 195
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sugar free juice`,
      keyword: 'sugar free juice',
      searchVolume: 2430,
      organicStartRank: 216,
      organicHighestRank: 172,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2207,
          organicRank: 223,

          keywordSales: 119
        },
        {
          date: '2024-08-05',
          searchVolume: 2207,
          organicRank: 277,

          keywordSales: 119
        },
        {
          date: '2024-08-04',
          searchVolume: 2207,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-08-03',
          searchVolume: 2207,
          organicRank: 278,

          keywordSales: 119
        },
        {
          date: '2024-08-02',
          searchVolume: 2207,
          organicRank: 250,

          keywordSales: 119
        },
        {
          date: '2024-08-01',
          searchVolume: 2207,
          organicRank: 219,

          keywordSales: 119
        },
        {
          date: '2024-07-31',
          searchVolume: 2207,
          organicRank: 225,

          keywordSales: 119
        },
        {
          date: '2024-07-30',
          searchVolume: 2207,
          organicRank: 256,

          keywordSales: 119
        },
        {
          date: '2024-07-29',
          searchVolume: 2207,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-28',
          searchVolume: 2207,
          organicRank: 278,

          keywordSales: 119
        },
        {
          date: '2024-07-27',
          searchVolume: 2207,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-26',
          searchVolume: 1839,
          organicRank: 217,

          keywordSales: 119
        },
        {
          date: '2024-07-25',
          searchVolume: 1839,
          organicRank: 210,

          keywordSales: 119
        },
        {
          date: '2024-07-24',
          searchVolume: 1839,
          organicRank: 283,

          keywordSales: 119
        },
        {
          date: '2024-07-23',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-22',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-21',
          searchVolume: 1839,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-20',
          searchVolume: 1839,
          organicRank: 200,

          keywordSales: 119
        },
        {
          date: '2024-07-19',
          searchVolume: 2392,
          organicRank: 177,

          keywordSales: 119
        },
        {
          date: '2024-07-18',
          searchVolume: 2392,
          organicRank: 254,

          keywordSales: 119
        },
        {
          date: '2024-07-17',
          searchVolume: 2392,
          organicRank: 213,

          keywordSales: 119
        },
        {
          date: '2024-07-16',
          searchVolume: 2392,
          organicRank: 232,

          keywordSales: 119
        },
        {
          date: '2024-07-15',
          searchVolume: 2392,
          organicRank: 272,

          keywordSales: 119
        },
        {
          date: '2024-07-14',
          searchVolume: 2392,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-13',
          searchVolume: 2392,
          organicRank: 223,

          keywordSales: 119
        },
        {
          date: '2024-07-12',
          searchVolume: 2286,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-11',
          searchVolume: 2286,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-10',
          searchVolume: 2286,
          organicRank: 266,

          keywordSales: 119
        },
        {
          date: '2024-07-09',
          searchVolume: 2286,
          organicRank: 215,

          keywordSales: 119
        },
        {
          date: '2024-07-08',
          searchVolume: 2286,
          organicRank: 204,

          keywordSales: 119
        },
        {
          date: '2024-07-07',
          searchVolume: 2286,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-06',
          searchVolume: 2286,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-05',
          searchVolume: 2253,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-04',
          searchVolume: 2253,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-07-03',
          searchVolume: 2253,
          organicRank: 184,

          keywordSales: 119
        },
        {
          date: '2024-07-02',
          searchVolume: 2253,
          organicRank: 272,

          keywordSales: 119
        },
        {
          date: '2024-07-01',
          searchVolume: 2253,
          organicRank: 274,

          keywordSales: 119
        },
        {
          date: '2024-06-30',
          searchVolume: 2253,
          organicRank: 274,

          keywordSales: 119
        },
        {
          date: '2024-06-29',
          searchVolume: 2253,
          organicRank: 291,

          keywordSales: 119
        },
        {
          date: '2024-06-28',
          searchVolume: 2180,
          organicRank: 255,

          keywordSales: 119
        },
        {
          date: '2024-06-27',
          searchVolume: 2180,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-06-26',
          searchVolume: 2180,
          organicRank: 251,

          keywordSales: 119
        },
        {
          date: '2024-06-25',
          searchVolume: 2180,
          organicRank: 178,

          keywordSales: 119
        },
        {
          date: '2024-06-24',
          searchVolume: 2180,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-06-21',
          searchVolume: 2430,
          organicRank: 306,

          keywordSales: 119
        },
        {
          date: '2024-06-20',
          searchVolume: 2430,
          organicRank: 172,

          keywordSales: 119
        },
        {
          date: '2024-06-19',
          searchVolume: 2430,
          organicRank: 216,

          keywordSales: 119
        },
        {
          date: '2024-06-18',
          searchVolume: 2430,
          organicRank: 167,

          keywordSales: 119
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sugar free red bull`,
      keyword: 'sugar free red bull',
      searchVolume: 7709,
      organicStartRank: 77,
      organicHighestRank: 50,
      sponsoredStartRank: 11,
      sponsoredHighestRank: 11,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 8569,
          organicRank: 76,

          keywordSales: 613
        },
        {
          date: '2024-08-06',
          searchVolume: 8569,
          organicRank: 74,

          keywordSales: 613
        },
        {
          date: '2024-08-05',
          searchVolume: 8569,
          organicRank: 67,

          keywordSales: 613
        },
        {
          date: '2024-08-04',
          searchVolume: 8569,
          organicRank: 67,

          keywordSales: 613
        },
        {
          date: '2024-08-03',
          searchVolume: 8569,
          organicRank: 71,

          keywordSales: 613
        },
        {
          date: '2024-08-01',
          searchVolume: 8569,
          organicRank: 64,

          keywordSales: 613
        },
        {
          date: '2024-07-31',
          searchVolume: 8569,
          organicRank: 75,

          keywordSales: 613
        },
        {
          date: '2024-07-30',
          searchVolume: 8569,
          organicRank: 57,

          keywordSales: 613
        },
        {
          date: '2024-07-29',
          searchVolume: 8569,
          organicRank: 50,

          keywordSales: 613
        },
        {
          date: '2024-07-28',
          searchVolume: 8569,
          organicRank: 50,

          keywordSales: 613
        },
        {
          date: '2024-07-27',
          searchVolume: 8569,
          organicRank: 54,

          keywordSales: 613
        },
        {
          date: '2024-07-26',
          searchVolume: 17215,
          organicRank: 63,

          keywordSales: 613
        },
        {
          date: '2024-07-25',
          searchVolume: 17215,
          organicRank: 66,

          keywordSales: 613
        },
        {
          date: '2024-07-24',
          searchVolume: 17215,
          organicRank: 57,

          keywordSales: 613
        },
        {
          date: '2024-07-23',
          searchVolume: 17215,
          organicRank: 58,
          sponsoredRank: 11,
          keywordSales: 613
        },
        {
          date: '2024-07-22',
          searchVolume: 17215,
          organicRank: 82,

          keywordSales: 613
        },
        {
          date: '2024-07-21',
          searchVolume: 17215,
          organicRank: 51,

          keywordSales: 613
        },
        {
          date: '2024-07-20',
          searchVolume: 17215,
          organicRank: 53,

          keywordSales: 613
        },
        {
          date: '2024-07-19',
          searchVolume: 10372,
          organicRank: 81,

          keywordSales: 613
        },
        {
          date: '2024-07-18',
          searchVolume: 10372,
          organicRank: 82,

          keywordSales: 613
        },
        {
          date: '2024-07-17',
          searchVolume: 10372,
          organicRank: 86,

          keywordSales: 613
        },
        {
          date: '2024-07-16',
          searchVolume: 10372,
          organicRank: 89,

          keywordSales: 613
        },
        {
          date: '2024-07-15',
          searchVolume: 10372,
          organicRank: 64,

          keywordSales: 613
        },
        {
          date: '2024-07-14',
          searchVolume: 10372,
          organicRank: 99,

          keywordSales: 613
        },
        {
          date: '2024-07-13',
          searchVolume: 10372,
          organicRank: 98,

          keywordSales: 613
        },
        {
          date: '2024-07-12',
          searchVolume: 10280,
          organicRank: 95,

          keywordSales: 613
        },
        {
          date: '2024-07-11',
          searchVolume: 10280,
          organicRank: 70,

          keywordSales: 613
        },
        {
          date: '2024-07-10',
          searchVolume: 10280,
          organicRank: 93,

          keywordSales: 613
        },
        {
          date: '2024-07-09',
          searchVolume: 10280,
          organicRank: 97,

          keywordSales: 613
        },
        {
          date: '2024-07-08',
          searchVolume: 10280,
          organicRank: 104,

          keywordSales: 613
        },
        {
          date: '2024-07-07',
          searchVolume: 10280,
          organicRank: 72,

          keywordSales: 613
        },
        {
          date: '2024-07-06',
          searchVolume: 10280,
          organicRank: 70,

          keywordSales: 613
        },
        {
          date: '2024-07-05',
          searchVolume: 10869,
          organicRank: 80,

          keywordSales: 613
        },
        {
          date: '2024-07-04',
          searchVolume: 10869,
          organicRank: 67,

          keywordSales: 613
        },
        {
          date: '2024-07-03',
          searchVolume: 10869,
          organicRank: 86,

          keywordSales: 613
        },
        {
          date: '2024-07-02',
          searchVolume: 10869,
          organicRank: 80,

          keywordSales: 613
        },
        {
          date: '2024-07-01',
          searchVolume: 10869,
          organicRank: 78,

          keywordSales: 613
        },
        {
          date: '2024-06-30',
          searchVolume: 10869,
          organicRank: 83,

          keywordSales: 613
        },
        {
          date: '2024-06-29',
          searchVolume: 10869,
          organicRank: 75,

          keywordSales: 613
        },
        {
          date: '2024-06-28',
          searchVolume: 9254,
          organicRank: 89,

          keywordSales: 613
        },
        {
          date: '2024-06-27',
          searchVolume: 9254,
          organicRank: 83,

          keywordSales: 613
        },
        {
          date: '2024-06-26',
          searchVolume: 9254,
          organicRank: 98,

          keywordSales: 613
        },
        {
          date: '2024-06-25',
          searchVolume: 9254,
          organicRank: 91,

          keywordSales: 613
        },
        {
          date: '2024-06-24',
          searchVolume: 9254,
          organicRank: 85,

          keywordSales: 613
        },
        {
          date: '2024-06-21',
          searchVolume: 7709,
          organicRank: 89,

          keywordSales: 613
        },
        {
          date: '2024-06-20',
          searchVolume: 7709,
          organicRank: 80,

          keywordSales: 613
        },
        {
          date: '2024-06-19',
          searchVolume: 7709,
          organicRank: 77,

          keywordSales: 613
        },
        {
          date: '2024-06-18',
          searchVolume: 7709,
          organicRank: 84,

          keywordSales: 613
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sugar free redbull`,
      keyword: 'sugar free redbull',
      searchVolume: 1663,
      organicStartRank: 12,
      organicHighestRank: 12,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 6,
      organicPageOneWinDate: '2024-06-21',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2078,
          organicRank: 65,

          keywordSales: 104
        },
        {
          date: '2024-08-06',
          searchVolume: 2078,
          organicRank: 25,

          keywordSales: 104
        },
        {
          date: '2024-08-05',
          searchVolume: 2078,
          organicRank: 33,

          keywordSales: 104
        },
        {
          date: '2024-08-04',
          searchVolume: 2078,
          organicRank: 34,

          keywordSales: 104
        },
        {
          date: '2024-08-03',
          searchVolume: 2078,
          organicRank: 26,

          keywordSales: 104
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 23,

          keywordSales: 104
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 25,

          keywordSales: 104
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 33,

          keywordSales: 104
        },
        {
          date: '2024-07-29',
          searchVolume: 2078,
          organicRank: 46,

          keywordSales: 104
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 22,

          keywordSales: 104
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 22,

          keywordSales: 104
        },
        {
          date: '2024-07-26',
          searchVolume: 3318,
          organicRank: 22,

          keywordSales: 104
        },
        {
          date: '2024-07-25',
          searchVolume: 3318,
          organicRank: 24,

          keywordSales: 104
        },
        {
          date: '2024-07-24',
          searchVolume: 3318,
          organicRank: 20,

          keywordSales: 104
        },
        {
          date: '2024-07-23',
          searchVolume: 3318,
          organicRank: 23,

          keywordSales: 104
        },
        {
          date: '2024-07-22',
          searchVolume: 3318,
          organicRank: 20,

          keywordSales: 104
        },
        {
          date: '2024-07-21',
          searchVolume: 3318,
          organicRank: 21,

          keywordSales: 104
        },
        {
          date: '2024-07-20',
          searchVolume: 3318,
          organicRank: 18,
          sponsoredRank: 11,
          keywordSales: 104
        },
        {
          date: '2024-07-19',
          searchVolume: 2390,
          organicRank: 19,

          keywordSales: 104
        },
        {
          date: '2024-07-18',
          searchVolume: 2390,
          organicRank: 23,

          keywordSales: 104
        },
        {
          date: '2024-07-17',
          searchVolume: 2390,
          organicRank: 19,

          keywordSales: 104
        },
        {
          date: '2024-07-16',
          searchVolume: 2390,
          organicRank: 26,

          keywordSales: 104
        },
        {
          date: '2024-07-15',
          searchVolume: 2390,
          organicRank: 22,

          keywordSales: 104
        },
        {
          date: '2024-07-14',
          searchVolume: 2390,
          organicRank: 29,

          keywordSales: 104
        },
        {
          date: '2024-07-13',
          searchVolume: 2390,
          organicRank: 29,

          keywordSales: 104
        },
        {
          date: '2024-07-12',
          searchVolume: 2291,
          organicRank: 29,

          keywordSales: 104
        },
        {
          date: '2024-07-11',
          searchVolume: 2291,
          organicRank: 28,

          keywordSales: 104
        },
        {
          date: '2024-07-10',
          searchVolume: 2291,
          organicRank: 25,

          keywordSales: 104
        },
        {
          date: '2024-07-09',
          searchVolume: 2291,
          organicRank: 24,

          keywordSales: 104
        },
        {
          date: '2024-07-08',
          searchVolume: 2291,
          organicRank: 28,

          keywordSales: 104
        },
        {
          date: '2024-07-07',
          searchVolume: 2291,
          organicRank: 28,

          keywordSales: 104
        },
        {
          date: '2024-07-06',
          searchVolume: 2291,
          organicRank: 25,

          keywordSales: 104
        },
        {
          date: '2024-07-05',
          searchVolume: 2549,
          organicRank: 29,

          keywordSales: 104
        },
        {
          date: '2024-07-04',
          searchVolume: 2549,
          organicRank: 25,

          keywordSales: 104
        },
        {
          date: '2024-07-03',
          searchVolume: 2549,
          organicRank: 23,

          keywordSales: 104
        },
        {
          date: '2024-07-02',
          searchVolume: 2549,
          organicRank: 19,

          keywordSales: 104
        },
        {
          date: '2024-07-01',
          searchVolume: 2549,
          organicRank: 18,

          keywordSales: 104
        },
        {
          date: '2024-06-30',
          searchVolume: 2549,
          organicRank: 21,

          keywordSales: 104
        },
        {
          date: '2024-06-29',
          searchVolume: 2549,
          organicRank: 19,

          keywordSales: 104
        },
        {
          date: '2024-06-28',
          searchVolume: 2180,
          organicRank: 16,

          keywordSales: 104
        },
        {
          date: '2024-06-27',
          searchVolume: 2180,
          organicRank: 23,

          keywordSales: 104
        },
        {
          date: '2024-06-26',
          searchVolume: 2180,
          organicRank: 17,

          keywordSales: 104
        },
        {
          date: '2024-06-25',
          searchVolume: 2180,
          organicRank: 18,

          keywordSales: 104
        },
        {
          date: '2024-06-24',
          searchVolume: 2180,
          organicRank: 23,

          keywordSales: 104
        },
        {
          date: '2024-06-21',
          searchVolume: 1663,
          organicRank: 17,

          keywordSales: 104
        },
        {
          date: '2024-06-20',
          searchVolume: 1663,
          organicRank: 22,
          sponsoredRank: 6,
          keywordSales: 104
        },
        {
          date: '2024-06-19',
          searchVolume: 1663,
          organicRank: 12,

          keywordSales: 104
        },
        {
          date: '2024-06-18',
          searchVolume: 1663,
          organicRank: 9,

          keywordSales: 104
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sugar free soda`,
      keyword: 'sugar free soda',
      searchVolume: 2433,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 34,
      sponsoredHighestRank: 9,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-03',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-08-05',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-08-04',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-08-03',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-08-02',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-08-01',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-31',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-30',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-29',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-28',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-27',
          searchVolume: 2351,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-26',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-25',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-24',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-23',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-22',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-21',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-20',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-19',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-18',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-17',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-16',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-15',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-14',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-13',
          searchVolume: 2390,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-12',
          searchVolume: 2440,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-11',
          searchVolume: 2440,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-10',
          searchVolume: 2440,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-09',
          searchVolume: 2440,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-08',
          searchVolume: 2440,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-07',
          searchVolume: 2440,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-06',
          searchVolume: 2440,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-05',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-04',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-03',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-02',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-07-01',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-30',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-29',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-28',
          searchVolume: 2424,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-27',
          searchVolume: 2424,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-26',
          searchVolume: 2424,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-25',
          searchVolume: 2424,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-24',
          searchVolume: 2424,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-21',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-20',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-19',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        },
        {
          date: '2024-06-18',
          searchVolume: 2431,
          organicRank: 306,

          keywordSales: 129
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_sugar free strawberry syrup`,
      keyword: 'sugar free strawberry syrup',
      searchVolume: 2643,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 25,
      sponsoredHighestRank: 15,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-23',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-08-06',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-08-05',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-08-04',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-08-03',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-08-01',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-31',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-30',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-29',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-28',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-27',
          searchVolume: 2673,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-26',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-25',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-24',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-23',
          searchVolume: 2433,
          organicRank: 306,
          sponsoredRank: 15,
          keywordSales: 139
        },
        {
          date: '2024-07-22',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-21',
          searchVolume: 2433,
          organicRank: 306,
          sponsoredRank: 25,
          keywordSales: 139
        },
        {
          date: '2024-07-20',
          searchVolume: 2433,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-19',
          searchVolume: 2643,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-18',
          searchVolume: 2643,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-17',
          searchVolume: 2643,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-16',
          searchVolume: 2643,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-15',
          searchVolume: 2643,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-14',
          searchVolume: 2643,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-13',
          searchVolume: 2643,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-12',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-11',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-10',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-09',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-08',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-07',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-06',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-05',
          searchVolume: 3467,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-04',
          searchVolume: 3467,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-03',
          searchVolume: 3467,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-02',
          searchVolume: 3467,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-07-01',
          searchVolume: 3467,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-30',
          searchVolume: 3467,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-29',
          searchVolume: 3467,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-28',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-27',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-26',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-25',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-24',
          searchVolume: 3128,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-21',
          searchVolume: 3425,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-20',
          searchVolume: 3425,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-19',
          searchVolume: 3425,
          organicRank: 306,

          keywordSales: 139
        },
        {
          date: '2024-06-18',
          searchVolume: 3425,
          organicRank: 306,

          keywordSales: 139
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_update energy drink`,
      keyword: 'update energy drink',
      searchVolume: 1915,
      organicStartRank: 16,
      organicHighestRank: 8,
      sponsoredStartRank: 32,
      sponsoredHighestRank: 1,
      organicPageOneWinDate: '2024-07-05',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-06-20',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-06',
          searchVolume: 2078,
          organicRank: 42,
          sponsoredRank: 11,
          keywordSales: 69
        },
        {
          date: '2024-08-05',
          searchVolume: 2078,
          organicRank: 37,

          keywordSales: 69
        },
        {
          date: '2024-08-04',
          searchVolume: 2078,
          organicRank: 66,
          sponsoredRank: 27,
          keywordSales: 69
        },
        {
          date: '2024-08-03',
          searchVolume: 2078,
          organicRank: 38,

          keywordSales: 69
        },
        {
          date: '2024-08-02',
          searchVolume: 2078,
          organicRank: 40,

          keywordSales: 69
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 49,

          keywordSales: 69
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 27,

          keywordSales: 69
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 49,

          keywordSales: 69
        },
        {
          date: '2024-07-29',
          searchVolume: 2078,
          organicRank: 37,

          keywordSales: 69
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 58,

          keywordSales: 69
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 39,

          keywordSales: 69
        },
        {
          date: '2024-07-26',
          searchVolume: 3695,
          organicRank: 65,

          keywordSales: 69
        },
        {
          date: '2024-07-25',
          searchVolume: 3695,
          organicRank: 46,

          keywordSales: 69
        },
        {
          date: '2024-07-24',
          searchVolume: 3695,
          organicRank: 17,

          keywordSales: 69
        },
        {
          date: '2024-07-23',
          searchVolume: 3695,
          organicRank: 27,

          keywordSales: 69
        },
        {
          date: '2024-07-22',
          searchVolume: 3695,
          organicRank: 23,
          sponsoredRank: 29,
          keywordSales: 69
        },
        {
          date: '2024-07-21',
          searchVolume: 3695,
          organicRank: 34,
          sponsoredRank: 20,
          keywordSales: 69
        },
        {
          date: '2024-07-20',
          searchVolume: 3695,
          organicRank: 23,

          keywordSales: 69
        },
        {
          date: '2024-07-19',
          searchVolume: 2207,
          organicRank: 15,

          keywordSales: 69
        },
        {
          date: '2024-07-18',
          searchVolume: 2207,
          organicRank: 22,

          keywordSales: 69
        },
        {
          date: '2024-07-17',
          searchVolume: 2207,
          organicRank: 15,
          sponsoredRank: 4,
          keywordSales: 69
        },
        {
          date: '2024-07-16',
          searchVolume: 2207,
          organicRank: 16,

          keywordSales: 69
        },
        {
          date: '2024-07-15',
          searchVolume: 2207,
          organicRank: 20,

          keywordSales: 69
        },
        {
          date: '2024-07-14',
          searchVolume: 2207,
          organicRank: 29,

          keywordSales: 69
        },
        {
          date: '2024-07-13',
          searchVolume: 2207,
          organicRank: 19,
          sponsoredRank: 23,
          keywordSales: 69
        },
        {
          date: '2024-07-12',
          searchVolume: 2435,
          organicRank: 18,

          keywordSales: 69
        },
        {
          date: '2024-07-11',
          searchVolume: 2435,
          organicRank: 12,

          keywordSales: 69
        },
        {
          date: '2024-07-10',
          searchVolume: 2435,
          organicRank: 8,

          keywordSales: 69
        },
        {
          date: '2024-07-09',
          searchVolume: 2435,
          organicRank: 16,

          keywordSales: 69
        },
        {
          date: '2024-07-08',
          searchVolume: 2435,
          organicRank: 13,

          keywordSales: 69
        },
        {
          date: '2024-07-07',
          searchVolume: 2435,
          organicRank: 20,

          keywordSales: 69
        },
        {
          date: '2024-07-06',
          searchVolume: 2435,
          organicRank: 8,

          keywordSales: 69
        },
        {
          date: '2024-07-05',
          searchVolume: 1917,
          organicRank: 12,

          keywordSales: 69
        },
        {
          date: '2024-07-04',
          searchVolume: 1917,
          organicRank: 21,

          keywordSales: 69
        },
        {
          date: '2024-07-03',
          searchVolume: 1917,
          organicRank: 11,

          keywordSales: 69
        },
        {
          date: '2024-07-02',
          searchVolume: 1917,
          organicRank: 10,

          keywordSales: 69
        },
        {
          date: '2024-07-01',
          searchVolume: 1917,
          organicRank: 8,

          keywordSales: 69
        },
        {
          date: '2024-06-30',
          searchVolume: 1917,
          organicRank: 15,
          sponsoredRank: 35,
          keywordSales: 69
        },
        {
          date: '2024-06-28',
          searchVolume: 1915,
          organicRank: 12,
          sponsoredRank: 3,
          keywordSales: 69
        },
        {
          date: '2024-06-27',
          searchVolume: 1915,
          organicRank: 14,
          sponsoredRank: 24,
          keywordSales: 69
        },
        {
          date: '2024-06-26',
          searchVolume: 1915,
          organicRank: 14,
          sponsoredRank: 26,
          keywordSales: 69
        },
        {
          date: '2024-06-25',
          searchVolume: 1915,
          organicRank: 9,
          sponsoredRank: 1,
          keywordSales: 69
        },
        {
          date: '2024-06-24',
          searchVolume: 1915,
          organicRank: 13,
          sponsoredRank: 1,
          keywordSales: 69
        },
        {
          date: '2024-06-23',
          searchVolume: 1915,
          organicRank: 10,

          keywordSales: 69
        },
        {
          date: '2024-06-22',
          searchVolume: 1915,
          organicRank: 9,
          sponsoredRank: 9,
          keywordSales: 69
        },
        {
          date: '2024-06-21',
          searchVolume: 1915,
          organicRank: 9,
          sponsoredRank: 26,
          keywordSales: 69
        },
        {
          date: '2024-06-20',
          searchVolume: 1915,
          organicRank: 10,
          sponsoredRank: 19,
          keywordSales: 69
        },
        {
          date: '2024-06-19',
          searchVolume: 1915,
          organicRank: 16,
          sponsoredRank: 32,
          keywordSales: 69
        },
        {
          date: '2024-06-18',
          searchVolume: 1915,
          organicRank: 15,

          keywordSales: 69
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_uptime energy drinks`,
      keyword: 'uptime energy drinks',
      searchVolume: 8484,
      organicStartRank: 25,
      organicHighestRank: 25,
      sponsoredStartRank: 29,
      sponsoredHighestRank: 10,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-22',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 5498,
          organicRank: 37,

          keywordSales: 249
        },
        {
          date: '2024-08-06',
          searchVolume: 5498,
          organicRank: 42,

          keywordSales: 249
        },
        {
          date: '2024-08-05',
          searchVolume: 5498,
          organicRank: 38,

          keywordSales: 249
        },
        {
          date: '2024-08-04',
          searchVolume: 5498,
          organicRank: 70,

          keywordSales: 249
        },
        {
          date: '2024-08-03',
          searchVolume: 5498,
          organicRank: 55,

          keywordSales: 249
        },
        {
          date: '2024-08-01',
          searchVolume: 5498,
          organicRank: 56,

          keywordSales: 249
        },
        {
          date: '2024-07-31',
          searchVolume: 5498,
          organicRank: 51,

          keywordSales: 249
        },
        {
          date: '2024-07-30',
          searchVolume: 5498,
          organicRank: 61,

          keywordSales: 249
        },
        {
          date: '2024-07-29',
          searchVolume: 5498,
          organicRank: 57,

          keywordSales: 249
        },
        {
          date: '2024-07-28',
          searchVolume: 5498,
          organicRank: 56,

          keywordSales: 249
        },
        {
          date: '2024-07-27',
          searchVolume: 5498,
          organicRank: 61,

          keywordSales: 249
        },
        {
          date: '2024-07-26',
          searchVolume: 5730,
          organicRank: 59,

          keywordSales: 249
        },
        {
          date: '2024-07-25',
          searchVolume: 5730,
          organicRank: 54,

          keywordSales: 249
        },
        {
          date: '2024-07-24',
          searchVolume: 5730,
          organicRank: 53,

          keywordSales: 249
        },
        {
          date: '2024-07-23',
          searchVolume: 5730,
          organicRank: 48,

          keywordSales: 249
        },
        {
          date: '2024-07-22',
          searchVolume: 5730,
          organicRank: 46,
          sponsoredRank: 10,
          keywordSales: 249
        },
        {
          date: '2024-07-21',
          searchVolume: 5730,
          organicRank: 50,

          keywordSales: 249
        },
        {
          date: '2024-07-20',
          searchVolume: 5730,
          organicRank: 45,

          keywordSales: 249
        },
        {
          date: '2024-07-19',
          searchVolume: 6338,
          organicRank: 43,

          keywordSales: 249
        },
        {
          date: '2024-07-18',
          searchVolume: 6338,
          organicRank: 37,

          keywordSales: 249
        },
        {
          date: '2024-07-17',
          searchVolume: 6338,
          organicRank: 35,

          keywordSales: 249
        },
        {
          date: '2024-07-16',
          searchVolume: 6338,
          organicRank: 44,

          keywordSales: 249
        },
        {
          date: '2024-07-15',
          searchVolume: 6338,
          organicRank: 48,

          keywordSales: 249
        },
        {
          date: '2024-07-14',
          searchVolume: 6338,
          organicRank: 54,

          keywordSales: 249
        },
        {
          date: '2024-07-13',
          searchVolume: 6338,
          organicRank: 55,

          keywordSales: 249
        },
        {
          date: '2024-07-12',
          searchVolume: 6450,
          organicRank: 52,

          keywordSales: 249
        },
        {
          date: '2024-07-11',
          searchVolume: 6450,
          organicRank: 56,

          keywordSales: 249
        },
        {
          date: '2024-07-10',
          searchVolume: 6450,
          organicRank: 54,

          keywordSales: 249
        },
        {
          date: '2024-07-09',
          searchVolume: 6450,
          organicRank: 36,

          keywordSales: 249
        },
        {
          date: '2024-07-08',
          searchVolume: 6450,
          organicRank: 64,

          keywordSales: 249
        },
        {
          date: '2024-07-07',
          searchVolume: 6450,
          organicRank: 52,

          keywordSales: 249
        },
        {
          date: '2024-07-06',
          searchVolume: 6450,
          organicRank: 51,

          keywordSales: 249
        },
        {
          date: '2024-07-05',
          searchVolume: 7715,
          organicRank: 57,

          keywordSales: 249
        },
        {
          date: '2024-07-04',
          searchVolume: 7715,
          organicRank: 65,

          keywordSales: 249
        },
        {
          date: '2024-07-03',
          searchVolume: 7715,
          organicRank: 43,

          keywordSales: 249
        },
        {
          date: '2024-07-02',
          searchVolume: 7715,
          organicRank: 42,

          keywordSales: 249
        },
        {
          date: '2024-07-01',
          searchVolume: 7715,
          organicRank: 40,

          keywordSales: 249
        },
        {
          date: '2024-06-30',
          searchVolume: 7715,
          organicRank: 56,

          keywordSales: 249
        },
        {
          date: '2024-06-29',
          searchVolume: 7715,
          organicRank: 52,

          keywordSales: 249
        },
        {
          date: '2024-06-28',
          searchVolume: 7689,
          organicRank: 42,

          keywordSales: 249
        },
        {
          date: '2024-06-27',
          searchVolume: 7689,
          organicRank: 32,

          keywordSales: 249
        },
        {
          date: '2024-06-26',
          searchVolume: 7689,
          organicRank: 30,

          keywordSales: 249
        },
        {
          date: '2024-06-25',
          searchVolume: 7689,
          organicRank: 34,

          keywordSales: 249
        },
        {
          date: '2024-06-24',
          searchVolume: 7689,
          organicRank: 33,

          keywordSales: 249
        },
        {
          date: '2024-06-21',
          searchVolume: 8484,
          organicRank: 28,
          sponsoredRank: 29,
          keywordSales: 249
        },
        {
          date: '2024-06-20',
          searchVolume: 8484,
          organicRank: 26,

          keywordSales: 249
        },
        {
          date: '2024-06-19',
          searchVolume: 8484,
          organicRank: 25,

          keywordSales: 249
        },
        {
          date: '2024-06-18',
          searchVolume: 8484,
          organicRank: 29,

          keywordSales: 249
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_v8 energy drink`,
      keyword: 'v8 energy drink',
      searchVolume: 32135,
      organicStartRank: 75,
      organicHighestRank: 41,
      sponsoredStartRank: 6,
      sponsoredHighestRank: 6,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-07-08',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 29704,
          organicRank: 80,

          keywordSales: 1611
        },
        {
          date: '2024-08-06',
          searchVolume: 29704,
          organicRank: 78,

          keywordSales: 1611
        },
        {
          date: '2024-08-05',
          searchVolume: 29704,
          organicRank: 73,

          keywordSales: 1611
        },
        {
          date: '2024-08-04',
          searchVolume: 29704,
          organicRank: 71,

          keywordSales: 1611
        },
        {
          date: '2024-08-02',
          searchVolume: 29704,
          organicRank: 53,

          keywordSales: 1611
        },
        {
          date: '2024-08-01',
          searchVolume: 29704,
          organicRank: 83,

          keywordSales: 1611
        },
        {
          date: '2024-07-31',
          searchVolume: 29704,
          organicRank: 81,

          keywordSales: 1611
        },
        {
          date: '2024-07-30',
          searchVolume: 29704,
          organicRank: 83,

          keywordSales: 1611
        },
        {
          date: '2024-07-29',
          searchVolume: 29704,
          organicRank: 85,

          keywordSales: 1611
        },
        {
          date: '2024-07-28',
          searchVolume: 29704,
          organicRank: 83,

          keywordSales: 1611
        },
        {
          date: '2024-07-27',
          searchVolume: 29704,
          organicRank: 85,

          keywordSales: 1611
        },
        {
          date: '2024-07-26',
          searchVolume: 36147,
          organicRank: 84,

          keywordSales: 1611
        },
        {
          date: '2024-07-25',
          searchVolume: 36147,
          organicRank: 89,

          keywordSales: 1611
        },
        {
          date: '2024-07-24',
          searchVolume: 36147,
          organicRank: 59,

          keywordSales: 1611
        },
        {
          date: '2024-07-23',
          searchVolume: 36147,
          organicRank: 85,

          keywordSales: 1611
        },
        {
          date: '2024-07-22',
          searchVolume: 36147,
          organicRank: 94,

          keywordSales: 1611
        },
        {
          date: '2024-07-21',
          searchVolume: 36147,
          organicRank: 72,

          keywordSales: 1611
        },
        {
          date: '2024-07-20',
          searchVolume: 36147,
          organicRank: 70,

          keywordSales: 1611
        },
        {
          date: '2024-07-19',
          searchVolume: 30171,
          organicRank: 95,

          keywordSales: 1611
        },
        {
          date: '2024-07-18',
          searchVolume: 30171,
          organicRank: 80,

          keywordSales: 1611
        },
        {
          date: '2024-07-17',
          searchVolume: 30171,
          organicRank: 64,

          keywordSales: 1611
        },
        {
          date: '2024-07-16',
          searchVolume: 30171,
          organicRank: 75,

          keywordSales: 1611
        },
        {
          date: '2024-07-15',
          searchVolume: 30171,
          organicRank: 82,

          keywordSales: 1611
        },
        {
          date: '2024-07-14',
          searchVolume: 30171,
          organicRank: 75,

          keywordSales: 1611
        },
        {
          date: '2024-07-13',
          searchVolume: 30171,
          organicRank: 103,

          keywordSales: 1611
        },
        {
          date: '2024-07-12',
          searchVolume: 30733,
          organicRank: 79,

          keywordSales: 1611
        },
        {
          date: '2024-07-11',
          searchVolume: 30733,
          organicRank: 65,

          keywordSales: 1611
        },
        {
          date: '2024-07-10',
          searchVolume: 30733,
          organicRank: 63,

          keywordSales: 1611
        },
        {
          date: '2024-07-09',
          searchVolume: 30733,
          organicRank: 54,

          keywordSales: 1611
        },
        {
          date: '2024-07-08',
          searchVolume: 30733,
          organicRank: 59,
          sponsoredRank: 6,
          keywordSales: 1611
        },
        {
          date: '2024-07-07',
          searchVolume: 30733,
          organicRank: 58,

          keywordSales: 1611
        },
        {
          date: '2024-07-06',
          searchVolume: 30733,
          organicRank: 62,

          keywordSales: 1611
        },
        {
          date: '2024-07-05',
          searchVolume: 29028,
          organicRank: 56,

          keywordSales: 1611
        },
        {
          date: '2024-07-04',
          searchVolume: 29028,
          organicRank: 61,

          keywordSales: 1611
        },
        {
          date: '2024-07-03',
          searchVolume: 29028,
          organicRank: 64,

          keywordSales: 1611
        },
        {
          date: '2024-07-02',
          searchVolume: 29028,
          organicRank: 64,

          keywordSales: 1611
        },
        {
          date: '2024-07-01',
          searchVolume: 29028,
          organicRank: 56,

          keywordSales: 1611
        },
        {
          date: '2024-06-30',
          searchVolume: 29028,
          organicRank: 88,

          keywordSales: 1611
        },
        {
          date: '2024-06-29',
          searchVolume: 29028,
          organicRank: 45,

          keywordSales: 1611
        },
        {
          date: '2024-06-28',
          searchVolume: 32041,
          organicRank: 67,

          keywordSales: 1611
        },
        {
          date: '2024-06-27',
          searchVolume: 32041,
          organicRank: 87,

          keywordSales: 1611
        },
        {
          date: '2024-06-26',
          searchVolume: 32041,
          organicRank: 51,

          keywordSales: 1611
        },
        {
          date: '2024-06-25',
          searchVolume: 32041,
          organicRank: 41,

          keywordSales: 1611
        },
        {
          date: '2024-06-24',
          searchVolume: 32041,
          organicRank: 58,

          keywordSales: 1611
        },
        {
          date: '2024-06-21',
          searchVolume: 32135,
          organicRank: 49,

          keywordSales: 1611
        },
        {
          date: '2024-06-20',
          searchVolume: 32135,
          organicRank: 50,

          keywordSales: 1611
        },
        {
          date: '2024-06-19',
          searchVolume: 32135,
          organicRank: 75,

          keywordSales: 1611
        },
        {
          date: '2024-06-18',
          searchVolume: 32135,
          organicRank: 71,

          keywordSales: 1611
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_v8 sparkling energy drinks`,
      keyword: 'v8 sparkling energy drinks',
      searchVolume: 1928,
      organicStartRank: 69,
      organicHighestRank: 60,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2078,
          organicRank: 103,

          keywordSales: 170
        },
        {
          date: '2024-08-06',
          searchVolume: 2078,
          organicRank: 104,

          keywordSales: 170
        },
        {
          date: '2024-08-05',
          searchVolume: 2078,
          organicRank: 100,

          keywordSales: 170
        },
        {
          date: '2024-08-04',
          searchVolume: 2078,
          organicRank: 99,

          keywordSales: 170
        },
        {
          date: '2024-08-02',
          searchVolume: 2078,
          organicRank: 99,

          keywordSales: 170
        },
        {
          date: '2024-08-01',
          searchVolume: 2078,
          organicRank: 80,

          keywordSales: 170
        },
        {
          date: '2024-07-31',
          searchVolume: 2078,
          organicRank: 80,

          keywordSales: 170
        },
        {
          date: '2024-07-30',
          searchVolume: 2078,
          organicRank: 81,

          keywordSales: 170
        },
        {
          date: '2024-07-29',
          searchVolume: 2078,
          organicRank: 82,

          keywordSales: 170
        },
        {
          date: '2024-07-28',
          searchVolume: 2078,
          organicRank: 79,

          keywordSales: 170
        },
        {
          date: '2024-07-27',
          searchVolume: 2078,
          organicRank: 81,

          keywordSales: 170
        },
        {
          date: '2024-07-26',
          searchVolume: 2431,
          organicRank: 65,

          keywordSales: 170
        },
        {
          date: '2024-07-25',
          searchVolume: 2431,
          organicRank: 68,

          keywordSales: 170
        },
        {
          date: '2024-07-24',
          searchVolume: 2431,
          organicRank: 81,

          keywordSales: 170
        },
        {
          date: '2024-07-23',
          searchVolume: 2431,
          organicRank: 83,

          keywordSales: 170
        },
        {
          date: '2024-07-22',
          searchVolume: 2431,
          organicRank: 84,

          keywordSales: 170
        },
        {
          date: '2024-07-21',
          searchVolume: 2431,
          organicRank: 81,

          keywordSales: 170
        },
        {
          date: '2024-07-20',
          searchVolume: 2431,
          organicRank: 80,

          keywordSales: 170
        },
        {
          date: '2024-07-19',
          searchVolume: 2113,
          organicRank: 80,

          keywordSales: 170
        },
        {
          date: '2024-07-18',
          searchVolume: 2113,
          organicRank: 74,

          keywordSales: 170
        },
        {
          date: '2024-07-17',
          searchVolume: 2113,
          organicRank: 68,

          keywordSales: 170
        },
        {
          date: '2024-07-16',
          searchVolume: 2113,
          organicRank: 85,

          keywordSales: 170
        },
        {
          date: '2024-07-15',
          searchVolume: 2113,
          organicRank: 96,

          keywordSales: 170
        },
        {
          date: '2024-07-14',
          searchVolume: 2113,
          organicRank: 93,

          keywordSales: 170
        },
        {
          date: '2024-07-13',
          searchVolume: 2113,
          organicRank: 95,

          keywordSales: 170
        },
        {
          date: '2024-07-12',
          searchVolume: 1845,
          organicRank: 91,

          keywordSales: 170
        },
        {
          date: '2024-07-11',
          searchVolume: 1845,
          organicRank: 91,

          keywordSales: 170
        },
        {
          date: '2024-07-10',
          searchVolume: 1845,
          organicRank: 76,

          keywordSales: 170
        },
        {
          date: '2024-07-09',
          searchVolume: 1845,
          organicRank: 78,

          keywordSales: 170
        },
        {
          date: '2024-07-08',
          searchVolume: 1845,
          organicRank: 84,

          keywordSales: 170
        },
        {
          date: '2024-07-07',
          searchVolume: 1845,
          organicRank: 86,

          keywordSales: 170
        },
        {
          date: '2024-07-06',
          searchVolume: 1845,
          organicRank: 74,

          keywordSales: 170
        },
        {
          date: '2024-07-05',
          searchVolume: 1932,
          organicRank: 72,

          keywordSales: 170
        },
        {
          date: '2024-07-04',
          searchVolume: 1932,
          organicRank: 72,

          keywordSales: 170
        },
        {
          date: '2024-07-03',
          searchVolume: 1932,
          organicRank: 77,

          keywordSales: 170
        },
        {
          date: '2024-07-02',
          searchVolume: 1932,
          organicRank: 73,

          keywordSales: 170
        },
        {
          date: '2024-07-01',
          searchVolume: 1932,
          organicRank: 69,

          keywordSales: 170
        },
        {
          date: '2024-06-30',
          searchVolume: 1932,
          organicRank: 61,

          keywordSales: 170
        },
        {
          date: '2024-06-29',
          searchVolume: 1932,
          organicRank: 74,

          keywordSales: 170
        },
        {
          date: '2024-06-28',
          searchVolume: 1922,
          organicRank: 75,

          keywordSales: 170
        },
        {
          date: '2024-06-27',
          searchVolume: 1922,
          organicRank: 72,

          keywordSales: 170
        },
        {
          date: '2024-06-26',
          searchVolume: 1922,
          organicRank: 96,

          keywordSales: 170
        },
        {
          date: '2024-06-25',
          searchVolume: 1922,
          organicRank: 60,

          keywordSales: 170
        },
        {
          date: '2024-06-24',
          searchVolume: 1922,
          organicRank: 81,

          keywordSales: 170
        },
        {
          date: '2024-06-21',
          searchVolume: 1928,
          organicRank: 76,

          keywordSales: 170
        },
        {
          date: '2024-06-20',
          searchVolume: 1928,
          organicRank: 82,

          keywordSales: 170
        },
        {
          date: '2024-06-19',
          searchVolume: 1928,
          organicRank: 69,

          keywordSales: 170
        },
        {
          date: '2024-06-18',
          searchVolume: 1928,
          organicRank: 82,

          keywordSales: 170
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_zero sugar`,
      keyword: 'zero sugar',
      searchVolume: 4274,
      organicStartRank: 306,
      organicHighestRank: 306,
      sponsoredStartRank: 59,
      sponsoredHighestRank: 59,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-08-06',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-08-05',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-08-04',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-08-03',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-08-02',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-08-01',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-31',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-30',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-28',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-27',
          searchVolume: 4197,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-26',
          searchVolume: 4695,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-25',
          searchVolume: 4695,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-24',
          searchVolume: 4695,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-23',
          searchVolume: 4695,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-22',
          searchVolume: 4695,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-21',
          searchVolume: 4695,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-20',
          searchVolume: 4695,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-19',
          searchVolume: 4274,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-18',
          searchVolume: 4274,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-17',
          searchVolume: 4274,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-16',
          searchVolume: 4274,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-15',
          searchVolume: 4274,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-14',
          searchVolume: 4274,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-13',
          searchVolume: 4274,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-12',
          searchVolume: 4694,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-11',
          searchVolume: 4694,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-10',
          searchVolume: 4694,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-09',
          searchVolume: 4694,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-08',
          searchVolume: 4694,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-07',
          searchVolume: 4694,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-06',
          searchVolume: 4694,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-05',
          searchVolume: 4550,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-04',
          searchVolume: 4550,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-03',
          searchVolume: 4550,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-02',
          searchVolume: 4550,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-07-01',
          searchVolume: 4550,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-30',
          searchVolume: 4550,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-29',
          searchVolume: 4550,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-28',
          searchVolume: 4312,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-27',
          searchVolume: 4312,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-26',
          searchVolume: 4312,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-25',
          searchVolume: 4312,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-21',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-20',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-19',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 176
        },
        {
          date: '2024-06-18',
          searchVolume: 4329,
          organicRank: 306,

          keywordSales: 176
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_zero sugar drinks`,
      keyword: 'zero sugar drinks',
      searchVolume: 3434,
      organicStartRank: 49,
      organicHighestRank: 39,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2891,
          organicRank: 107,

          keywordSales: 123
        },
        {
          date: '2024-08-06',
          searchVolume: 2891,
          organicRank: 112,

          keywordSales: 123
        },
        {
          date: '2024-08-05',
          searchVolume: 2891,
          organicRank: 109,

          keywordSales: 123
        },
        {
          date: '2024-08-04',
          searchVolume: 2891,
          organicRank: 184,

          keywordSales: 123
        },
        {
          date: '2024-08-02',
          searchVolume: 2891,
          organicRank: 93,

          keywordSales: 123
        },
        {
          date: '2024-08-01',
          searchVolume: 2891,
          organicRank: 101,

          keywordSales: 123
        },
        {
          date: '2024-07-31',
          searchVolume: 2891,
          organicRank: 101,

          keywordSales: 123
        },
        {
          date: '2024-07-30',
          searchVolume: 2891,
          organicRank: 57,

          keywordSales: 123
        },
        {
          date: '2024-07-29',
          searchVolume: 2891,
          organicRank: 63,

          keywordSales: 123
        },
        {
          date: '2024-07-28',
          searchVolume: 2891,
          organicRank: 53,

          keywordSales: 123
        },
        {
          date: '2024-07-27',
          searchVolume: 2891,
          organicRank: 56,

          keywordSales: 123
        },
        {
          date: '2024-07-26',
          searchVolume: 3089,
          organicRank: 86,

          keywordSales: 123
        },
        {
          date: '2024-07-25',
          searchVolume: 3089,
          organicRank: 96,

          keywordSales: 123
        },
        {
          date: '2024-07-24',
          searchVolume: 3089,
          organicRank: 90,

          keywordSales: 123
        },
        {
          date: '2024-07-23',
          searchVolume: 3089,
          organicRank: 105,

          keywordSales: 123
        },
        {
          date: '2024-07-22',
          searchVolume: 3089,
          organicRank: 90,

          keywordSales: 123
        },
        {
          date: '2024-07-21',
          searchVolume: 3089,
          organicRank: 78,

          keywordSales: 123
        },
        {
          date: '2024-07-20',
          searchVolume: 3089,
          organicRank: 88,

          keywordSales: 123
        },
        {
          date: '2024-07-19',
          searchVolume: 3038,
          organicRank: 78,

          keywordSales: 123
        },
        {
          date: '2024-07-18',
          searchVolume: 3038,
          organicRank: 122,

          keywordSales: 123
        },
        {
          date: '2024-07-17',
          searchVolume: 3038,
          organicRank: 70,

          keywordSales: 123
        },
        {
          date: '2024-07-16',
          searchVolume: 3038,
          organicRank: 106,

          keywordSales: 123
        },
        {
          date: '2024-07-15',
          searchVolume: 3038,
          organicRank: 99,

          keywordSales: 123
        },
        {
          date: '2024-07-14',
          searchVolume: 3038,
          organicRank: 51,

          keywordSales: 123
        },
        {
          date: '2024-07-13',
          searchVolume: 3038,
          organicRank: 53,

          keywordSales: 123
        },
        {
          date: '2024-07-12',
          searchVolume: 3094,
          organicRank: 150,

          keywordSales: 123
        },
        {
          date: '2024-07-10',
          searchVolume: 3094,
          organicRank: 88,

          keywordSales: 123
        },
        {
          date: '2024-07-09',
          searchVolume: 3094,
          organicRank: 51,

          keywordSales: 123
        },
        {
          date: '2024-07-08',
          searchVolume: 3094,
          organicRank: 50,

          keywordSales: 123
        },
        {
          date: '2024-07-07',
          searchVolume: 3094,
          organicRank: 49,

          keywordSales: 123
        },
        {
          date: '2024-07-06',
          searchVolume: 3094,
          organicRank: 306,

          keywordSales: 123
        },
        {
          date: '2024-07-05',
          searchVolume: 3238,
          organicRank: 46,

          keywordSales: 123
        },
        {
          date: '2024-07-04',
          searchVolume: 3238,
          organicRank: 46,

          keywordSales: 123
        },
        {
          date: '2024-07-03',
          searchVolume: 3238,
          organicRank: 48,

          keywordSales: 123
        },
        {
          date: '2024-07-02',
          searchVolume: 3238,
          organicRank: 47,

          keywordSales: 123
        },
        {
          date: '2024-07-01',
          searchVolume: 3238,
          organicRank: 49,

          keywordSales: 123
        },
        {
          date: '2024-06-30',
          searchVolume: 3238,
          organicRank: 51,

          keywordSales: 123
        },
        {
          date: '2024-06-29',
          searchVolume: 3238,
          organicRank: 43,

          keywordSales: 123
        },
        {
          date: '2024-06-28',
          searchVolume: 3231,
          organicRank: 164,

          keywordSales: 123
        },
        {
          date: '2024-06-27',
          searchVolume: 3231,
          organicRank: 158,

          keywordSales: 123
        },
        {
          date: '2024-06-26',
          searchVolume: 3231,
          organicRank: 47,

          keywordSales: 123
        },
        {
          date: '2024-06-25',
          searchVolume: 3231,
          organicRank: 39,

          keywordSales: 123
        },
        {
          date: '2024-06-21',
          searchVolume: 3434,
          organicRank: 45,

          keywordSales: 123
        },
        {
          date: '2024-06-20',
          searchVolume: 3434,
          organicRank: 41,

          keywordSales: 123
        },
        {
          date: '2024-06-19',
          searchVolume: 3434,
          organicRank: 49,

          keywordSales: 123
        },
        {
          date: '2024-06-18',
          searchVolume: 3434,
          organicRank: 59,

          keywordSales: 123
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_zero sugar energy drinks`,
      keyword: 'zero sugar energy drinks',
      searchVolume: 2550,
      organicStartRank: 14,
      organicHighestRank: 7,
      sponsoredStartRank: 28,
      sponsoredHighestRank: 10,
      organicPageOneWinDate: '2024-07-04',
      organicPageOneWin: true,
      sponsoredPageOneWinDate: '2024-07-15',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2005,
          organicRank: 11,

          keywordSales: 83
        },
        {
          date: '2024-08-06',
          searchVolume: 2005,
          organicRank: 19,

          keywordSales: 83
        },
        {
          date: '2024-08-05',
          searchVolume: 2005,
          organicRank: 18,
          sponsoredRank: 72,
          keywordSales: 83
        },
        {
          date: '2024-08-04',
          searchVolume: 2005,
          organicRank: 19,

          keywordSales: 83
        },
        {
          date: '2024-08-02',
          searchVolume: 2005,
          organicRank: 18,

          keywordSales: 83
        },
        {
          date: '2024-08-01',
          searchVolume: 2005,
          organicRank: 16,

          keywordSales: 83
        },
        {
          date: '2024-07-31',
          searchVolume: 2005,
          organicRank: 16,

          keywordSales: 83
        },
        {
          date: '2024-07-30',
          searchVolume: 2005,
          organicRank: 14,

          keywordSales: 83
        },
        {
          date: '2024-07-29',
          searchVolume: 2005,
          organicRank: 12,

          keywordSales: 83
        },
        {
          date: '2024-07-28',
          searchVolume: 2005,
          organicRank: 11,

          keywordSales: 83
        },
        {
          date: '2024-07-27',
          searchVolume: 2005,
          organicRank: 14,

          keywordSales: 83
        },
        {
          date: '2024-07-26',
          searchVolume: 2431,
          organicRank: 18,

          keywordSales: 83
        },
        {
          date: '2024-07-25',
          searchVolume: 2431,
          organicRank: 15,

          keywordSales: 83
        },
        {
          date: '2024-07-24',
          searchVolume: 2431,
          organicRank: 16,
          sponsoredRank: 10,
          keywordSales: 83
        },
        {
          date: '2024-07-23',
          searchVolume: 2431,
          organicRank: 14,

          keywordSales: 83
        },
        {
          date: '2024-07-22',
          searchVolume: 2431,
          organicRank: 16,

          keywordSales: 83
        },
        {
          date: '2024-07-21',
          searchVolume: 2431,
          organicRank: 16,
          sponsoredRank: 71,
          keywordSales: 83
        },
        {
          date: '2024-07-20',
          searchVolume: 2431,
          organicRank: 13,

          keywordSales: 83
        },
        {
          date: '2024-07-19',
          searchVolume: 2390,
          organicRank: 15,
          sponsoredRank: 54,
          keywordSales: 83
        },
        {
          date: '2024-07-18',
          searchVolume: 2390,
          organicRank: 12,
          sponsoredRank: 70,
          keywordSales: 83
        },
        {
          date: '2024-07-17',
          searchVolume: 2390,
          organicRank: 16,
          sponsoredRank: 32,
          keywordSales: 83
        },
        {
          date: '2024-07-16',
          searchVolume: 2390,
          organicRank: 9,
          sponsoredRank: 19,
          keywordSales: 83
        },
        {
          date: '2024-07-15',
          searchVolume: 2390,
          organicRank: 17,
          sponsoredRank: 10,
          keywordSales: 83
        },
        {
          date: '2024-07-14',
          searchVolume: 2390,
          organicRank: 15,

          keywordSales: 83
        },
        {
          date: '2024-07-13',
          searchVolume: 2390,
          organicRank: 16,

          keywordSales: 83
        },
        {
          date: '2024-07-12',
          searchVolume: 1842,
          organicRank: 16,

          keywordSales: 83
        },
        {
          date: '2024-07-11',
          searchVolume: 1842,
          organicRank: 17,

          keywordSales: 83
        },
        {
          date: '2024-07-10',
          searchVolume: 1842,
          organicRank: 17,

          keywordSales: 83
        },
        {
          date: '2024-07-09',
          searchVolume: 1842,
          organicRank: 14,

          keywordSales: 83
        },
        {
          date: '2024-07-08',
          searchVolume: 1842,
          organicRank: 14,

          keywordSales: 83
        },
        {
          date: '2024-07-07',
          searchVolume: 1842,
          organicRank: 8,

          keywordSales: 83
        },
        {
          date: '2024-07-06',
          searchVolume: 1842,
          organicRank: 9,

          keywordSales: 83
        },
        {
          date: '2024-07-05',
          searchVolume: 2433,
          organicRank: 7,

          keywordSales: 83
        },
        {
          date: '2024-07-04',
          searchVolume: 2433,
          organicRank: 14,

          keywordSales: 83
        },
        {
          date: '2024-07-03',
          searchVolume: 2433,
          organicRank: 23,

          keywordSales: 83
        },
        {
          date: '2024-07-02',
          searchVolume: 2433,
          organicRank: 9,

          keywordSales: 83
        },
        {
          date: '2024-07-01',
          searchVolume: 2433,
          organicRank: 12,

          keywordSales: 83
        },
        {
          date: '2024-06-30',
          searchVolume: 2433,
          organicRank: 20,

          keywordSales: 83
        },
        {
          date: '2024-06-29',
          searchVolume: 2433,
          organicRank: 17,
          sponsoredRank: 44,
          keywordSales: 83
        },
        {
          date: '2024-06-28',
          searchVolume: 2543,
          organicRank: 12,

          keywordSales: 83
        },
        {
          date: '2024-06-27',
          searchVolume: 2543,
          organicRank: 11,

          keywordSales: 83
        },
        {
          date: '2024-06-26',
          searchVolume: 2543,
          organicRank: 9,

          keywordSales: 83
        },
        {
          date: '2024-06-25',
          searchVolume: 2543,
          organicRank: 7,

          keywordSales: 83
        },
        {
          date: '2024-06-21',
          searchVolume: 2550,
          organicRank: 8,

          keywordSales: 83
        },
        {
          date: '2024-06-20',
          searchVolume: 2550,
          organicRank: 11,
          sponsoredRank: 28,
          keywordSales: 83
        },
        {
          date: '2024-06-19',
          searchVolume: 2550,
          organicRank: 14,

          keywordSales: 83
        },
        {
          date: '2024-06-18',
          searchVolume: 2550,
          organicRank: 12,

          keywordSales: 83
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_zero sugar soda`,
      keyword: 'zero sugar soda',
      searchVolume: 9282,
      organicStartRank: 131,
      organicHighestRank: 47,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 8567,
          organicRank: 100,

          keywordSales: 496
        },
        {
          date: '2024-08-06',
          searchVolume: 8567,
          organicRank: 118,

          keywordSales: 496
        },
        {
          date: '2024-08-05',
          searchVolume: 8567,
          organicRank: 115,

          keywordSales: 496
        },
        {
          date: '2024-08-04',
          searchVolume: 8567,
          organicRank: 102,

          keywordSales: 496
        },
        {
          date: '2024-08-03',
          searchVolume: 8567,
          organicRank: 115,

          keywordSales: 496
        },
        {
          date: '2024-08-02',
          searchVolume: 8567,
          organicRank: 106,

          keywordSales: 496
        },
        {
          date: '2024-08-01',
          searchVolume: 8567,
          organicRank: 110,

          keywordSales: 496
        },
        {
          date: '2024-07-31',
          searchVolume: 8567,
          organicRank: 86,

          keywordSales: 496
        },
        {
          date: '2024-07-30',
          searchVolume: 8567,
          organicRank: 96,

          keywordSales: 496
        },
        {
          date: '2024-07-28',
          searchVolume: 8567,
          organicRank: 91,

          keywordSales: 496
        },
        {
          date: '2024-07-27',
          searchVolume: 8567,
          organicRank: 90,

          keywordSales: 496
        },
        {
          date: '2024-07-26',
          searchVolume: 10251,
          organicRank: 99,

          keywordSales: 496
        },
        {
          date: '2024-07-25',
          searchVolume: 10251,
          organicRank: 103,

          keywordSales: 496
        },
        {
          date: '2024-07-24',
          searchVolume: 10251,
          organicRank: 47,

          keywordSales: 496
        },
        {
          date: '2024-07-23',
          searchVolume: 10251,
          organicRank: 59,

          keywordSales: 496
        },
        {
          date: '2024-07-22',
          searchVolume: 10251,
          organicRank: 59,

          keywordSales: 496
        },
        {
          date: '2024-07-21',
          searchVolume: 10251,
          organicRank: 65,

          keywordSales: 496
        },
        {
          date: '2024-07-20',
          searchVolume: 10251,
          organicRank: 63,

          keywordSales: 496
        },
        {
          date: '2024-07-19',
          searchVolume: 10066,
          organicRank: 65,

          keywordSales: 496
        },
        {
          date: '2024-07-18',
          searchVolume: 10066,
          organicRank: 82,

          keywordSales: 496
        },
        {
          date: '2024-07-17',
          searchVolume: 10066,
          organicRank: 88,

          keywordSales: 496
        },
        {
          date: '2024-07-16',
          searchVolume: 10066,
          organicRank: 112,

          keywordSales: 496
        },
        {
          date: '2024-07-15',
          searchVolume: 10066,
          organicRank: 110,

          keywordSales: 496
        },
        {
          date: '2024-07-14',
          searchVolume: 10066,
          organicRank: 111,

          keywordSales: 496
        },
        {
          date: '2024-07-13',
          searchVolume: 10066,
          organicRank: 111,

          keywordSales: 496
        },
        {
          date: '2024-07-12',
          searchVolume: 8877,
          organicRank: 110,

          keywordSales: 496
        },
        {
          date: '2024-07-11',
          searchVolume: 8877,
          organicRank: 117,

          keywordSales: 496
        },
        {
          date: '2024-07-10',
          searchVolume: 8877,
          organicRank: 118,

          keywordSales: 496
        },
        {
          date: '2024-07-09',
          searchVolume: 8877,
          organicRank: 97,

          keywordSales: 496
        },
        {
          date: '2024-07-08',
          searchVolume: 8877,
          organicRank: 106,

          keywordSales: 496
        },
        {
          date: '2024-07-07',
          searchVolume: 8877,
          organicRank: 120,

          keywordSales: 496
        },
        {
          date: '2024-07-06',
          searchVolume: 8877,
          organicRank: 116,

          keywordSales: 496
        },
        {
          date: '2024-07-05',
          searchVolume: 9022,
          organicRank: 122,

          keywordSales: 496
        },
        {
          date: '2024-07-04',
          searchVolume: 9022,
          organicRank: 118,

          keywordSales: 496
        },
        {
          date: '2024-07-03',
          searchVolume: 9022,
          organicRank: 112,

          keywordSales: 496
        },
        {
          date: '2024-07-02',
          searchVolume: 9022,
          organicRank: 306,

          keywordSales: 496
        },
        {
          date: '2024-07-01',
          searchVolume: 9022,
          organicRank: 106,

          keywordSales: 496
        },
        {
          date: '2024-06-30',
          searchVolume: 9022,
          organicRank: 117,

          keywordSales: 496
        },
        {
          date: '2024-06-29',
          searchVolume: 9022,
          organicRank: 121,

          keywordSales: 496
        },
        {
          date: '2024-06-28',
          searchVolume: 9250,
          organicRank: 116,

          keywordSales: 496
        },
        {
          date: '2024-06-27',
          searchVolume: 9250,
          organicRank: 98,

          keywordSales: 496
        },
        {
          date: '2024-06-26',
          searchVolume: 9250,
          organicRank: 306,

          keywordSales: 496
        },
        {
          date: '2024-06-25',
          searchVolume: 9250,
          organicRank: 93,

          keywordSales: 496
        },
        {
          date: '2024-06-21',
          searchVolume: 9282,
          organicRank: 129,

          keywordSales: 496
        },
        {
          date: '2024-06-20',
          searchVolume: 9282,
          organicRank: 94,

          keywordSales: 496
        },
        {
          date: '2024-06-19',
          searchVolume: 9282,
          organicRank: 131,

          keywordSales: 496
        },
        {
          date: '2024-06-18',
          searchVolume: 9282,
          organicRank: 122,

          keywordSales: 496
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_zevia energy drink`,
      keyword: 'zevia energy drink',
      searchVolume: 4911,
      organicStartRank: 38,
      organicHighestRank: 21,
      sponsoredStartRank: 10,
      sponsoredHighestRank: 10,

      organicPageOneWin: false,
      sponsoredPageOneWinDate: '2024-08-04',
      sponsoredPageOneWin: true,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 4201,
          organicRank: 57,

          keywordSales: 229
        },
        {
          date: '2024-08-06',
          searchVolume: 4201,
          organicRank: 44,

          keywordSales: 229
        },
        {
          date: '2024-08-05',
          searchVolume: 4201,
          organicRank: 52,

          keywordSales: 229
        },
        {
          date: '2024-08-04',
          searchVolume: 4201,
          organicRank: 48,
          sponsoredRank: 10,
          keywordSales: 229
        },
        {
          date: '2024-08-02',
          searchVolume: 4201,
          organicRank: 57,

          keywordSales: 229
        },
        {
          date: '2024-08-01',
          searchVolume: 4201,
          organicRank: 44,

          keywordSales: 229
        },
        {
          date: '2024-07-31',
          searchVolume: 4201,
          organicRank: 41,

          keywordSales: 229
        },
        {
          date: '2024-07-30',
          searchVolume: 4201,
          organicRank: 39,

          keywordSales: 229
        },
        {
          date: '2024-07-29',
          searchVolume: 4201,
          organicRank: 50,

          keywordSales: 229
        },
        {
          date: '2024-07-28',
          searchVolume: 4201,
          organicRank: 48,

          keywordSales: 229
        },
        {
          date: '2024-07-27',
          searchVolume: 4201,
          organicRank: 49,

          keywordSales: 229
        },
        {
          date: '2024-07-26',
          searchVolume: 4344,
          organicRank: 63,

          keywordSales: 229
        },
        {
          date: '2024-07-25',
          searchVolume: 4344,
          organicRank: 45,

          keywordSales: 229
        },
        {
          date: '2024-07-24',
          searchVolume: 4344,
          organicRank: 50,

          keywordSales: 229
        },
        {
          date: '2024-07-23',
          searchVolume: 4344,
          organicRank: 46,

          keywordSales: 229
        },
        {
          date: '2024-07-22',
          searchVolume: 4344,
          organicRank: 45,

          keywordSales: 229
        },
        {
          date: '2024-07-21',
          searchVolume: 4344,
          organicRank: 54,

          keywordSales: 229
        },
        {
          date: '2024-07-20',
          searchVolume: 4344,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-07-19',
          searchVolume: 4272,
          organicRank: 60,

          keywordSales: 229
        },
        {
          date: '2024-07-18',
          searchVolume: 4272,
          organicRank: 34,

          keywordSales: 229
        },
        {
          date: '2024-07-17',
          searchVolume: 4272,
          organicRank: 45,

          keywordSales: 229
        },
        {
          date: '2024-07-16',
          searchVolume: 4272,
          organicRank: 58,

          keywordSales: 229
        },
        {
          date: '2024-07-15',
          searchVolume: 4272,
          organicRank: 47,

          keywordSales: 229
        },
        {
          date: '2024-07-14',
          searchVolume: 4272,
          organicRank: 55,

          keywordSales: 229
        },
        {
          date: '2024-07-13',
          searchVolume: 4272,
          organicRank: 60,

          keywordSales: 229
        },
        {
          date: '2024-07-12',
          searchVolume: 4349,
          organicRank: 45,

          keywordSales: 229
        },
        {
          date: '2024-07-11',
          searchVolume: 4349,
          organicRank: 46,

          keywordSales: 229
        },
        {
          date: '2024-07-10',
          searchVolume: 4349,
          organicRank: 54,

          keywordSales: 229
        },
        {
          date: '2024-07-09',
          searchVolume: 4349,
          organicRank: 306,

          keywordSales: 229
        },
        {
          date: '2024-07-08',
          searchVolume: 4349,
          organicRank: 43,

          keywordSales: 229
        },
        {
          date: '2024-07-07',
          searchVolume: 4349,
          organicRank: 49,

          keywordSales: 229
        },
        {
          date: '2024-07-06',
          searchVolume: 4349,
          organicRank: 42,

          keywordSales: 229
        },
        {
          date: '2024-07-05',
          searchVolume: 4560,
          organicRank: 25,

          keywordSales: 229
        },
        {
          date: '2024-07-04',
          searchVolume: 4560,
          organicRank: 42,

          keywordSales: 229
        },
        {
          date: '2024-07-03',
          searchVolume: 4560,
          organicRank: 47,

          keywordSales: 229
        },
        {
          date: '2024-07-02',
          searchVolume: 4560,
          organicRank: 38,

          keywordSales: 229
        },
        {
          date: '2024-07-01',
          searchVolume: 4560,
          organicRank: 44,

          keywordSales: 229
        },
        {
          date: '2024-06-30',
          searchVolume: 4560,
          organicRank: 25,

          keywordSales: 229
        },
        {
          date: '2024-06-29',
          searchVolume: 4560,
          organicRank: 35,

          keywordSales: 229
        },
        {
          date: '2024-06-28',
          searchVolume: 5136,
          organicRank: 26,

          keywordSales: 229
        },
        {
          date: '2024-06-27',
          searchVolume: 5136,
          organicRank: 27,

          keywordSales: 229
        },
        {
          date: '2024-06-26',
          searchVolume: 5136,
          organicRank: 21,

          keywordSales: 229
        },
        {
          date: '2024-06-25',
          searchVolume: 5136,
          organicRank: 27,

          keywordSales: 229
        },
        {
          date: '2024-06-24',
          searchVolume: 5136,
          organicRank: 33,

          keywordSales: 229
        },
        {
          date: '2024-06-21',
          searchVolume: 4911,
          organicRank: 40,

          keywordSales: 229
        },
        {
          date: '2024-06-20',
          searchVolume: 4911,
          organicRank: 40,

          keywordSales: 229
        },
        {
          date: '2024-06-19',
          searchVolume: 4911,
          organicRank: 38,

          keywordSales: 229
        },
        {
          date: '2024-06-18',
          searchVolume: 4911,
          organicRank: 38,

          keywordSales: 229
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_zipfizz energy drink`,
      keyword: 'zipfizz energy drink',
      searchVolume: 3431,
      organicStartRank: 64,
      organicHighestRank: 48,

      organicPageOneWin: false,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 2894,
          organicRank: 74,

          keywordSales: 153
        },
        {
          date: '2024-08-06',
          searchVolume: 2894,
          organicRank: 66,

          keywordSales: 153
        },
        {
          date: '2024-08-05',
          searchVolume: 2894,
          organicRank: 71,

          keywordSales: 153
        },
        {
          date: '2024-08-04',
          searchVolume: 2894,
          organicRank: 72,

          keywordSales: 153
        },
        {
          date: '2024-08-03',
          searchVolume: 2894,
          organicRank: 68,

          keywordSales: 153
        },
        {
          date: '2024-08-01',
          searchVolume: 2894,
          organicRank: 71,

          keywordSales: 153
        },
        {
          date: '2024-07-31',
          searchVolume: 2894,
          organicRank: 76,

          keywordSales: 153
        },
        {
          date: '2024-07-30',
          searchVolume: 2894,
          organicRank: 71,

          keywordSales: 153
        },
        {
          date: '2024-07-29',
          searchVolume: 2894,
          organicRank: 67,

          keywordSales: 153
        },
        {
          date: '2024-07-28',
          searchVolume: 2894,
          organicRank: 76,

          keywordSales: 153
        },
        {
          date: '2024-07-27',
          searchVolume: 2894,
          organicRank: 73,

          keywordSales: 153
        },
        {
          date: '2024-07-26',
          searchVolume: 3463,
          organicRank: 65,

          keywordSales: 153
        },
        {
          date: '2024-07-25',
          searchVolume: 3463,
          organicRank: 65,

          keywordSales: 153
        },
        {
          date: '2024-07-24',
          searchVolume: 3463,
          organicRank: 56,

          keywordSales: 153
        },
        {
          date: '2024-07-23',
          searchVolume: 3463,
          organicRank: 56,

          keywordSales: 153
        },
        {
          date: '2024-07-22',
          searchVolume: 3463,
          organicRank: 63,

          keywordSales: 153
        },
        {
          date: '2024-07-21',
          searchVolume: 3463,
          organicRank: 48,

          keywordSales: 153
        },
        {
          date: '2024-07-20',
          searchVolume: 3463,
          organicRank: 48,

          keywordSales: 153
        },
        {
          date: '2024-07-19',
          searchVolume: 3976,
          organicRank: 61,

          keywordSales: 153
        },
        {
          date: '2024-07-18',
          searchVolume: 3976,
          organicRank: 59,

          keywordSales: 153
        },
        {
          date: '2024-07-17',
          searchVolume: 3976,
          organicRank: 66,

          keywordSales: 153
        },
        {
          date: '2024-07-16',
          searchVolume: 3976,
          organicRank: 74,

          keywordSales: 153
        },
        {
          date: '2024-07-15',
          searchVolume: 3976,
          organicRank: 72,

          keywordSales: 153
        },
        {
          date: '2024-07-14',
          searchVolume: 3976,
          organicRank: 54,

          keywordSales: 153
        },
        {
          date: '2024-07-13',
          searchVolume: 3976,
          organicRank: 82,

          keywordSales: 153
        },
        {
          date: '2024-07-12',
          searchVolume: 3710,
          organicRank: 72,

          keywordSales: 153
        },
        {
          date: '2024-07-11',
          searchVolume: 3710,
          organicRank: 65,

          keywordSales: 153
        },
        {
          date: '2024-07-10',
          searchVolume: 3710,
          organicRank: 63,

          keywordSales: 153
        },
        {
          date: '2024-07-09',
          searchVolume: 3710,
          organicRank: 74,

          keywordSales: 153
        },
        {
          date: '2024-07-08',
          searchVolume: 3710,
          organicRank: 71,

          keywordSales: 153
        },
        {
          date: '2024-07-07',
          searchVolume: 3710,
          organicRank: 70,

          keywordSales: 153
        },
        {
          date: '2024-07-06',
          searchVolume: 3710,
          organicRank: 68,

          keywordSales: 153
        },
        {
          date: '2024-07-05',
          searchVolume: 4327,
          organicRank: 53,

          keywordSales: 153
        },
        {
          date: '2024-07-04',
          searchVolume: 4327,
          organicRank: 56,

          keywordSales: 153
        },
        {
          date: '2024-07-03',
          searchVolume: 4327,
          organicRank: 67,

          keywordSales: 153
        },
        {
          date: '2024-07-02',
          searchVolume: 4327,
          organicRank: 67,

          keywordSales: 153
        },
        {
          date: '2024-07-01',
          searchVolume: 4327,
          organicRank: 60,

          keywordSales: 153
        },
        {
          date: '2024-06-30',
          searchVolume: 4327,
          organicRank: 65,

          keywordSales: 153
        },
        {
          date: '2024-06-29',
          searchVolume: 4327,
          organicRank: 57,

          keywordSales: 153
        },
        {
          date: '2024-06-28',
          searchVolume: 3602,
          organicRank: 63,

          keywordSales: 153
        },
        {
          date: '2024-06-27',
          searchVolume: 3602,
          organicRank: 58,

          keywordSales: 153
        },
        {
          date: '2024-06-26',
          searchVolume: 3602,
          organicRank: 60,

          keywordSales: 153
        },
        {
          date: '2024-06-25',
          searchVolume: 3602,
          organicRank: 64,

          keywordSales: 153
        },
        {
          date: '2024-06-24',
          searchVolume: 3602,
          organicRank: 61,

          keywordSales: 153
        },
        {
          date: '2024-06-21',
          searchVolume: 3431,
          organicRank: 57,

          keywordSales: 153
        },
        {
          date: '2024-06-20',
          searchVolume: 3431,
          organicRank: 61,

          keywordSales: 153
        },
        {
          date: '2024-06-19',
          searchVolume: 3431,
          organicRank: 64,

          keywordSales: 153
        },
        {
          date: '2024-06-18',
          searchVolume: 3431,
          organicRank: 63,

          keywordSales: 153
        }
      ]
    },
    {
      campaignId: '47a0e449-29e4-4cd2-b3f7-237fb7817f85',
      campaignId_keyword: `${SAMPLE_CAMPAIGN_ENERGY_DRINK_PD_EXISTING.id}_zoa energy drink`,
      keyword: 'zoa energy drink',
      searchVolume: 22060,
      organicStartRank: 13,
      organicHighestRank: 8,

      organicPageOneWinDate: '2024-07-10',
      organicPageOneWin: true,

      sponsoredPageOneWin: false,

      records: [
        {
          date: '2024-08-07',
          searchVolume: 18187,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-08-06',
          searchVolume: 18187,
          organicRank: 10,

          keywordSales: 961
        },
        {
          date: '2024-08-05',
          searchVolume: 18187,
          organicRank: 9,

          keywordSales: 961
        },
        {
          date: '2024-08-03',
          searchVolume: 18187,
          organicRank: 9,

          keywordSales: 961
        },
        {
          date: '2024-08-02',
          searchVolume: 18187,
          organicRank: 8,

          keywordSales: 961
        },
        {
          date: '2024-08-01',
          searchVolume: 18187,
          organicRank: 8,

          keywordSales: 961
        },
        {
          date: '2024-07-31',
          searchVolume: 18187,
          organicRank: 9,

          keywordSales: 961
        },
        {
          date: '2024-07-30',
          searchVolume: 18187,
          organicRank: 8,

          keywordSales: 961
        },
        {
          date: '2024-07-29',
          searchVolume: 18187,
          organicRank: 8,

          keywordSales: 961
        },
        {
          date: '2024-07-28',
          searchVolume: 18187,
          organicRank: 8,

          keywordSales: 961
        },
        {
          date: '2024-07-27',
          searchVolume: 18187,
          organicRank: 10,

          keywordSales: 961
        },
        {
          date: '2024-07-26',
          searchVolume: 38670,
          organicRank: 9,

          keywordSales: 961
        },
        {
          date: '2024-07-25',
          searchVolume: 38670,
          organicRank: 8,

          keywordSales: 961
        },
        {
          date: '2024-07-24',
          searchVolume: 38670,
          organicRank: 8,

          keywordSales: 961
        },
        {
          date: '2024-07-23',
          searchVolume: 38670,
          organicRank: 9,

          keywordSales: 961
        },
        {
          date: '2024-07-22',
          searchVolume: 38670,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-07-21',
          searchVolume: 38670,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-07-20',
          searchVolume: 38670,
          organicRank: 10,

          keywordSales: 961
        },
        {
          date: '2024-07-19',
          searchVolume: 23695,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-07-18',
          searchVolume: 23695,
          organicRank: 12,

          keywordSales: 961
        },
        {
          date: '2024-07-17',
          searchVolume: 23695,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-07-16',
          searchVolume: 23695,
          organicRank: 9,

          keywordSales: 961
        },
        {
          date: '2024-07-15',
          searchVolume: 23695,
          organicRank: 8,

          keywordSales: 961
        },
        {
          date: '2024-07-14',
          searchVolume: 23695,
          organicRank: 10,

          keywordSales: 961
        },
        {
          date: '2024-07-13',
          searchVolume: 23695,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-07-12',
          searchVolume: 19029,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-07-11',
          searchVolume: 19029,
          organicRank: 12,

          keywordSales: 961
        },
        {
          date: '2024-07-10',
          searchVolume: 19029,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-07-09',
          searchVolume: 19029,
          organicRank: 24,

          keywordSales: 961
        },
        {
          date: '2024-07-08',
          searchVolume: 19029,
          organicRank: 10,

          keywordSales: 961
        },
        {
          date: '2024-07-07',
          searchVolume: 19029,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-07-06',
          searchVolume: 19029,
          organicRank: 12,

          keywordSales: 961
        },
        {
          date: '2024-07-05',
          searchVolume: 20553,
          organicRank: 10,

          keywordSales: 961
        },
        {
          date: '2024-07-04',
          searchVolume: 20553,
          organicRank: 16,

          keywordSales: 961
        },
        {
          date: '2024-07-03',
          searchVolume: 20553,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-07-02',
          searchVolume: 20553,
          organicRank: 9,

          keywordSales: 961
        },
        {
          date: '2024-07-01',
          searchVolume: 20553,
          organicRank: 10,

          keywordSales: 961
        },
        {
          date: '2024-06-30',
          searchVolume: 20553,
          organicRank: 13,

          keywordSales: 961
        },
        {
          date: '2024-06-29',
          searchVolume: 20553,
          organicRank: 12,

          keywordSales: 961
        },
        {
          date: '2024-06-28',
          searchVolume: 20314,
          organicRank: 19,

          keywordSales: 961
        },
        {
          date: '2024-06-27',
          searchVolume: 20314,
          organicRank: 15,

          keywordSales: 961
        },
        {
          date: '2024-06-26',
          searchVolume: 20314,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-06-25',
          searchVolume: 20314,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-06-24',
          searchVolume: 20314,
          organicRank: 10,

          keywordSales: 961
        },
        {
          date: '2024-06-21',
          searchVolume: 22060,
          organicRank: 10,

          keywordSales: 961
        },
        {
          date: '2024-06-20',
          searchVolume: 22060,
          organicRank: 11,

          keywordSales: 961
        },
        {
          date: '2024-06-19',
          searchVolume: 22060,
          organicRank: 13,

          keywordSales: 961
        },
        {
          date: '2024-06-18',
          searchVolume: 22060,
          organicRank: 13,

          keywordSales: 961
        }
      ]
    }
  ]
}

import { Avatar, AvatarOwnProps, Typography, colors } from '@mui/material'

const MAGIC_CONSTANT = 5381

const availableColors = Object.values(colors).flatMap(c =>
  Object.entries(c)
    .filter(([key, _value]) => ['100', '500', '900', 'A700'].includes(key))
    .map(([_key, value]) => value)
)

export const UserAvatar = ({
  firstName,
  lastName,
  onClick,
  ...rest
}: { firstName?: string; lastName?: string; onClick?: () => void } & AvatarOwnProps) => {
  return (
    <Avatar
      sx={{
        bgcolor: getColorForUser(`${firstName} ${lastName}`),
        height: 24,
        width: 24,
        fontSize: 10,
        fontWeight: 700,
        ...rest.sx
      }}
      onClick={onClick}
    >
      {firstName?.[0]}
      {lastName?.[0]}
    </Avatar>
  )
}

// djb2 hash
const hashText = (text: string): number => {
  let hash = MAGIC_CONSTANT
  for (let index = 0; index < text.length; index++) {
    hash = ((hash << 5) + hash) ^ text.charCodeAt(index)
  }
  return hash >>> 0
}

const getColorForUser = (fullName: string) => {
  const hashedValue = hashText(fullName)
  const normalizedHash = hashedValue % availableColors.length
  return availableColors[normalizedHash]
}

import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CREATORS_FOR_APPROVAL_KEYBOARD } from './keyboard'
import { SAMPLE_CREATORS_FOR_APPROVAL_LAUNDRY } from './laundry'
import { SAMPLE_CREATORS_FOR_APPROVAL_MARKER } from './marker'
import { SAMPLE_CREATORS_FOR_APPROVAL_PILLOW } from './pillow'
import { SAMPLE_CREATORS_FOR_APPROVAL_WHITENING } from './whitening'
import { SAMPLE_CREATORS_FOR_APPROVAL_HEADPHONES } from './headphones'

export const SAMPLE_CAMPAIGN_CREATORS_FOR_APPROVAL: Record<string, Array<ModelTypes['CampaignCreatorForApproval']>> = {
  'sample-campaign-laundry': SAMPLE_CREATORS_FOR_APPROVAL_LAUNDRY,
  'sample-campaign-keyboard': SAMPLE_CREATORS_FOR_APPROVAL_KEYBOARD,
  'sample-campaign-whitening': SAMPLE_CREATORS_FOR_APPROVAL_WHITENING,
  'sample-campaign-marker': SAMPLE_CREATORS_FOR_APPROVAL_MARKER,
  'sample-campaign-pillow': SAMPLE_CREATORS_FOR_APPROVAL_PILLOW,
  'sample-campaign-headphones': SAMPLE_CREATORS_FOR_APPROVAL_HEADPHONES,
  'sample-campaign-bluetooth-speaker': []
}

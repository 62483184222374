import { SvgIcon, SvgIconProps } from '@mui/material'

export default function RocketIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="M6 14.9992C5.17 14.9992 4.42 15.3392 3.88 15.8792C2.7 17.0592 2 21.9992 2 21.9992C2 21.9992 6.94 21.2992 8.12 20.1192C8.66 19.5792 9 18.8292 9 17.9992C9 16.3392 7.66 14.9992 6 14.9992ZM6.71 18.7092C6.43 18.9892 4.54 19.4692 4.54 19.4692C4.54 19.4692 5.01 17.5892 5.3 17.2992C5.47 17.1092 5.72 16.9992 6 16.9992C6.55 16.9992 7 17.4492 7 17.9992C7 18.2792 6.89 18.5292 6.71 18.7092ZM17.42 13.6492C23.78 7.2892 21.66 2.3392 21.66 2.3392C21.66 2.3392 16.71 0.2192 10.35 6.5792L7.86 6.0792C7.21 5.9492 6.53 6.1592 6.05 6.6292L2 10.6892L7 12.8292L11.17 16.9992L13.31 21.9992L17.36 17.9492C17.83 17.4792 18.04 16.7992 17.91 16.1392L17.42 13.6492ZM7.41 10.8292L5.5 10.0092L7.47 8.0392L8.91 8.3292C8.34 9.1592 7.83 10.0292 7.41 10.8292ZM13.99 18.4992L13.17 16.5892C13.97 16.1692 14.84 15.6592 15.66 15.0892L15.95 16.5292L13.99 18.4992ZM16 12.2392C14.68 13.5592 12.62 14.6392 11.96 14.9692L9.03 12.0392C9.35 11.3892 10.43 9.3292 11.76 7.9992C16.44 3.3192 19.99 4.0092 19.99 4.0092C19.99 4.0092 20.68 7.5592 16 12.2392ZM15 10.9992C16.1 10.9992 17 10.0992 17 8.9992C17 7.8992 16.1 6.9992 15 6.9992C13.9 6.9992 13 7.8992 13 8.9992C13 10.0992 13.9 10.9992 15 10.9992Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

import { createContext, useContext, useState } from 'react'
import { ApproveContentContextType, ApproveContentViewMode, ContentEditsFormData, CreatorContent } from '../types'
import { getHandleLink, getSocialProfile } from '@momentum/utils/socialUtils'
import ApproveContentDialog from '../components/ApproveContentDialog'
import { approveContent, requestContentEdits, toggleContentVisibility } from '../daos/mutations'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { DraftContent } from '@momentum/routes/campaign/context/queries'
import { DraftStatus } from '@productwindtom/shared-momentum-zeus-types'
import { Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { FormMethodsType } from '@productwindtom/ui-base'
import { DateTime } from 'luxon'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { captureException } from '@sentry/react'

const ApproveContentProvider = ({
  children,
  content,
  creator,
  draftContent,
  id
}: { children?: React.ReactNode } & CreatorContent) => {
  const { profile } = useUserSessionContext()
  const { updateCampaignContentForApproval } = useCampaignContext()
  const [approveContentViewMode, setApproveContentViewMode] = useState<ApproveContentViewMode>()
  const [selectedAsset, setSelectedAsset] = useState(0)
  const [currentVideoPosition, setCurrentVideoPosition] = useState(0)

  const { firstName, lastName } = creator
  const { channel, draftMedia } = content

  const socialProfile = getSocialProfile(channel, creator)
  const handle = socialProfile?.handle || `${firstName} ${lastName?.charAt(0)}`
  const handleLink = getHandleLink(channel, creator)

  const handleUpdateContentObject = (input: Partial<DraftContent>) => {
    updateCampaignContentForApproval({
      id,
      draftContent: draftContent?.map(draft => ({
        ...draft,
        ...(draft.id === content.id && {
          ...input
        })
      }))
    })
  }

  const handleToggleContentVisibility = async (isHidden: boolean) => {
    try {
      const visibility = await toggleContentVisibility({
        userCampaignSocialContentId: content.id,
        isHidden
      })

      handleUpdateContentObject({
        isHidden: visibility.isHidden
      })

      toast(
        <Typography variant={'subtitle2'}>
          Content successfully {visibility.isHidden ? 'hidden from' : 'shown to'} client!
        </Typography>,
        { type: 'success' }
      )
    } catch (err) {
      captureException(err)
      toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
        type: 'error'
      })
    }
  }

  const handleApproveContent = async () => {
    try {
      await approveContent({
        userCampaignSocialContentId: content.id
      })

      handleUpdateContentObject({
        reviewerFirstName: profile.firstName,
        reviewerLastName: profile.lastName,
        reviewedAt: DateTime.now().toISO(),
        draftStatus: DraftStatus.APPROVED
      })

      toast(<Typography variant={'subtitle2'}>You approved 1 creator to post!</Typography>, {
        type: 'success'
      })
    } catch (err) {
      captureException(err)
      toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
        type: 'error'
      })
    }
  }

  const handleContentEdits = async (
    data: ContentEditsFormData,
    { reset }: FormMethodsType
  ) => {
    try {
      const updatedFeedback = await requestContentEdits({
        shouldSendToCreator: !!data.shouldSendToCreator,
        userCampaignSocialContentId: content.id,
        message: data.message,
        atTime: data?.atTime?.[0] === 'true' ? Math.round(currentVideoPosition) : undefined,
        visibleToUser: data.visibleToUser,
        forAssetAtIndex: selectedAsset
      })

      handleUpdateContentObject({
        reviewFeedback: updatedFeedback,
        reviewerFirstName: profile.firstName,
        reviewerLastName: profile.lastName,
        reviewedAt: DateTime.now().toISO(),
        ...(data.shouldSendToCreator && { draftStatus: DraftStatus.REQUIRES_USER_CHANGES })
      })

      toast(
        <Typography variant={'subtitle2'}>
          {data.shouldSendToCreator ? 'Creator has been sent the requested edits!' : 'Comment has been added!'}
        </Typography>,
        {
          type: 'success'
        }
      )
    } catch (err) {
      captureException(err)
      toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
        type: 'error'
      })
    } finally {
      reset()
    }
  }

  return (
    <ApproveContentContext.Provider
      value={{
        creator,
        content: {
          ...content,
          draftMedia: draftMedia?.map(media => getCdnImageUrl(media)!)
        },
        socialProfile,
        handle,
        handleLink,
        approveContentViewMode,
        setApproveContentViewMode,
        handleApproveContent,
        handleToggleContentVisibility,
        handleContentEdits,
        selectedAsset,
        setSelectedAsset,
        currentVideoPosition,
        setCurrentVideoPosition
      }}
    >
      {children}
      <ApproveContentDialog />
    </ApproveContentContext.Provider>
  )
}

export default ApproveContentProvider

const ApproveContentContext = createContext<ApproveContentContextType>({} as ApproveContentContextType)
export const useApproveContentContext = () => useContext(ApproveContentContext)

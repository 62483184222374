import { createContext, useContext } from 'react'
import { DateTime } from 'luxon'

export type TrafficData = {
  date: DateTime
  momentumViews: number
  otherViews: number
  totalViews: number
  isWithinCampaignDates?: boolean
}

export type TrafficContextType = {
  isIntegrated: boolean
  trafficData: TrafficData[]
  filteredTrafficData: TrafficData[]
  minDate: DateTime
  maxDate: DateTime
  startDateFilter?: DateTime
  setStartDateFilter: (date?: DateTime) => void
  endDateFilter?: DateTime
  setEndDateFilter: (date?: DateTime) => void
  isMomentumViewsHidden: boolean
  setIsMomentumViewsHidden: (isHidden: boolean) => void
  isOtherViewsHidden: boolean
  setIsOtherViewsHidden: (isHidden: boolean) => void
  isTotalViewsHidden: boolean
  setIsTotalViewsHidden: (isHidden: boolean) => void
}
const TrafficContext = createContext<TrafficContextType>({
  isIntegrated: false,
  trafficData: [],
  filteredTrafficData: [],
  minDate: DateTime.now(),
  maxDate: DateTime.now(),
  startDateFilter: undefined,
  setStartDateFilter: () => {},
  endDateFilter: undefined,
  setEndDateFilter: () => {},
  isMomentumViewsHidden: false,
  setIsMomentumViewsHidden: () => {},
  isOtherViewsHidden: false,
  setIsOtherViewsHidden: () => {},
  isTotalViewsHidden: false,
  setIsTotalViewsHidden: () => {}
})
TrafficContext.displayName = 'TrafficContext'

export default TrafficContext

export const useTrafficContext = () => useContext(TrafficContext)

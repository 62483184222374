// @ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_SEO_HEADPHONES: Array<ModelTypes['ProductSeoSummary']> = {
  "skuId": "ae855bf2-2eb2-4297-b6b2-65eba2ec26ea",
  "productSeoSummaryRecords": [
    {
      "fromDate": "2023-12-01",
      "toDate": "2023-12-07",
      "searchVolume": 17201,
      "organicPageOneWins": 8,
      "sponsoredPageOneWins": 11,
      "isOutOfStock": null
    },
    {
      "fromDate": "2023-12-08",
      "toDate": "2023-12-14",
      "searchVolume": 29925,
      "organicPageOneWins": 13,
      "sponsoredPageOneWins": 11,
      "isOutOfStock": null
    },
    {
      "fromDate": "2023-12-15",
      "toDate": "2023-12-21",
      "searchVolume": 30799,
      "organicPageOneWins": 4,
      "sponsoredPageOneWins": 10,
      "isOutOfStock": null
    },
    {
      "fromDate": "2023-12-22",
      "toDate": "2023-12-28",
      "searchVolume": 29929,
      "organicPageOneWins": 1,
      "sponsoredPageOneWins": 1,
      "isOutOfStock": null
    },
    {
      "fromDate": "2023-12-29",
      "toDate": "2024-01-05",
      "searchVolume": 28126,
      "organicPageOneWins": 1,
      "sponsoredPageOneWins": 0,
      "isOutOfStock": null
    },
    {
      "fromDate": "2024-01-06",
      "toDate": "2024-01-13",
      "searchVolume": 13118,
      "organicPageOneWins": 0,
      "sponsoredPageOneWins": 0,
      "isOutOfStock": null
    }
  ]
}
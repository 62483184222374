// @ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_PRODUCTS_BY_ID } from '@momentum/api/interceptors/listProductsByBrandId/sample-data'

export const SAMPLE_PROPOSAL_HEADPHONES_PRODUCT_LAUNCH: ModelTypes['Proposal'] = {
  id: 'sample-proposal-headphones-product-launch',
  title: 'Wireless Headphones - Product Launch',
  brandId: 'ed8dccef-7b0f-4faa-93de-0367f8f7e80e',
  status: 'PUBLISHED',
  statusChangedAt: '2024-05-22T17:51:11.196Z',
  goal: 'PRODUCT_LAUNCH',
  ratingGoal: null,
  durationWeeks: null,
  productId: 'sample-sku-headphones',
  product: SAMPLE_PRODUCTS_BY_ID['sample-sku-headphones'],
  productPriceOverride: null,
  launchDate: '2024-06-05',
  estimatedUnitsSoldPerWeek: null,
  searchTerms: [],
  creatorPricing: [
    {
      type: 'ADVOCATE',
      price: 50,
      numCreators: 200,
      hidden: null,
      schedule: [10, 20, 80, 90],
      closeoutBonus: 500,
      contentRequirements: []
    },
    {
      type: 'UGC',
      price: 350,
      numCreators: 50,
      hidden: null,
      schedule: [20, 30, 0, 0],
      closeoutBonus: 1000,
      contentRequirements: [
        {
          contentType: 'UGC_VIDEO',
          bonus: 1000
        },
        {
          contentType: 'UGC_PHOTO',
          bonus: 500
        }
      ]
    },
    {
      type: 'PREMIUM_UGC',
      price: 600,
      numCreators: 0,
      hidden: null,
      schedule: [0, 0, 0, 0],
      closeoutBonus: 500,
      contentRequirements: [
        {
          contentType: 'UGC_VIDEO',
          bonus: 0
        },
        {
          contentType: 'UGC_PHOTO',
          bonus: 0
        }
      ]
    },
    {
      type: 'IG',
      price: 1800,
      numCreators: 0,
      hidden: null,
      schedule: [0, 0, 0, 0],
      closeoutBonus: 500,
      contentRequirements: [
        {
          contentType: 'IG_REEL',
          bonus: 0
        },
        {
          contentType: 'IG_STORY',
          bonus: 0
        },
        {
          contentType: 'IG_STORY',
          bonus: 0
        }
      ]
    },
    {
      type: 'TT',
      price: 1800,
      numCreators: 0,
      hidden: null,
      schedule: [0, 0, 0, 0],
      closeoutBonus: 500,
      contentRequirements: [
        {
          contentType: 'IG_REEL',
          bonus: 0
        },
        {
          contentType: 'IG_STORY',
          bonus: 0
        },
        {
          contentType: 'IG_STORY',
          bonus: 0
        }
      ]
    }
  ],
  invoiceDueDate: null,
  invoiceLink: '',
  invoicePaidDate: null,
  invoiceStatus: null,
  createdBy: {
    firstName: 'Jackie',
    lastName: 'Roland'
  },
  updatedBy: {
    firstName: 'Jackie',
    lastName: 'Roland'
  },
  hiddenNumReviews: null,
  hiddenEndRating: null,
  hiddenPdpTraffic: null,
  hiddenRetailEfficiency: null,
  hiddenSeo: null,
  hiddenSocialContent: null,
  hiddenUgcContent: null,
  updatedAt: '2024-05-22T17:51:11.560Z',
  createdAt: '2024-05-22T17:51:11.560Z',
  referralCost: null,
  operatingCost: null,
  liftNumCreators: null,
  liftCloseoutBonus: null
}

import { SvgIconProps, SvgIcon } from '@mui/material'
import React from 'react'

export const EmptyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 204 156" fill="none">
      <path
        d="M202.262 156H1.73631C1.23486 156 0.830078 155.595 0.830078 155.094C0.830078 154.592 1.23486 154.188 1.73631 154.188H202.262C202.764 154.188 203.169 154.592 203.169 155.094C203.169 155.595 202.764 156 202.262 156Z"
        fill="#243C98"
      />
      <path
        d="M104.822 153.227C147.135 153.227 181.435 118.926 181.435 76.6133C181.435 34.3009 147.135 0 104.822 0C62.5099 0 28.209 34.3009 28.209 76.6133C28.209 118.926 62.5099 153.227 104.822 153.227Z"
        fill="#94D5D9"
      />
      <path
        d="M179.101 111.016V124.935C179.101 127.419 177.089 129.436 174.6 129.436H34.7432C32.2601 129.436 30.2422 127.419 30.2422 124.935V111.016H179.101Z"
        fill="#7C8EE1"
      />
      <path
        d="M174.6 130.342H34.7432C31.7647 130.342 29.3359 127.92 29.3359 124.935V111.016C29.3359 110.514 29.7407 110.109 30.2422 110.109H179.101C179.602 110.109 180.007 110.514 180.007 111.016V124.935C180.007 127.914 177.584 130.342 174.6 130.342ZM31.1484 111.922V124.935C31.1484 126.917 32.7615 128.53 34.7432 128.53H174.6C176.581 128.53 178.194 126.917 178.194 124.935V111.922H31.1484Z"
        fill="#243C98"
      />
      <path
        d="M179.101 21.3956V111.022H30.2422V21.3956C30.2422 18.9125 32.2601 16.8945 34.7432 16.8945H174.6C177.089 16.8945 179.101 18.9065 179.101 21.3956Z"
        fill="white"
      />
      <path
        d="M179.101 111.923H30.2422C29.7407 111.923 29.3359 111.518 29.3359 111.017V21.3898C29.3359 18.4113 31.7586 15.9824 34.7432 15.9824H174.6C177.578 15.9824 180.007 18.4052 180.007 21.3898V111.017C180.007 111.518 179.602 111.923 179.101 111.923ZM31.1484 110.11H178.194V21.3898C178.194 19.4081 176.581 17.7949 174.6 17.7949H34.7432C32.7615 17.7949 31.1484 19.4081 31.1484 21.3898V110.11Z"
        fill="#243C98"
      />
      <path
        d="M104.668 124.037C106.773 124.037 108.48 122.33 108.48 120.224C108.48 118.119 106.773 116.412 104.668 116.412C102.562 116.412 100.855 118.119 100.855 120.224C100.855 122.33 102.562 124.037 104.668 124.037Z"
        fill="#243C98"
      />
      <path
        d="M123.004 129.439H104.843H86.6823C83.879 139.311 77.7408 147.552 68.2676 154.162H104.843H141.419C131.946 147.546 125.808 139.305 123.004 129.439Z"
        fill="#324AA8"
      />
      <path
        d="M141.419 155.068H68.2676C67.8688 155.068 67.5245 154.808 67.4037 154.434C67.2828 154.059 67.4278 153.642 67.748 153.418C77.0037 146.96 83.0755 138.81 85.8124 129.192C85.9211 128.799 86.2776 128.533 86.6823 128.533H123.004C123.409 128.533 123.766 128.799 123.874 129.192C126.605 138.81 132.683 146.96 141.939 153.418C142.265 153.648 142.404 154.059 142.283 154.434C142.162 154.808 141.812 155.068 141.419 155.068ZM71.0346 153.256H138.646C130.502 146.984 125.016 139.287 122.322 130.346H87.359C84.6645 139.287 79.1847 146.978 71.0346 153.256Z"
        fill="#243C98"
      />
      <path
        d="M82.6376 62.3083H44.5092C43.2646 62.3083 42.2617 61.2992 42.2617 60.0607V32.5051C42.2617 31.2605 43.2706 30.2578 44.5092 30.2578H82.6376C83.8822 30.2578 84.885 31.2666 84.885 32.5051V60.0607C84.8911 61.3052 83.8822 62.3083 82.6376 62.3083Z"
        fill="#324AA8"
      />
      <path
        d="M166.535 42.5342H112.716C111.472 42.5342 110.469 41.5251 110.469 40.2866V32.5113C110.469 31.2667 111.478 30.2637 112.716 30.2637H166.535C167.779 30.2637 168.782 31.2727 168.782 32.5113V40.2866C168.782 41.5251 167.779 42.5342 166.535 42.5342Z"
        fill="#324AA8"
      />
      <path
        d="M166.535 54.8078H112.716C111.472 54.8078 110.469 53.7991 110.469 52.5605V50.4097C110.469 49.1651 111.478 48.1621 112.716 48.1621H166.535C167.779 48.1621 168.782 49.1712 168.782 50.4097V52.5605C168.782 53.7991 167.779 54.8078 166.535 54.8078Z"
        fill="#324AA8"
      />
      <path
        d="M166.535 82.772H112.716C111.472 82.772 110.469 81.763 110.469 80.5244V71.8547C110.469 70.6102 111.478 69.6074 112.716 69.6074H166.535C167.779 69.6074 168.782 70.6162 168.782 71.8547V80.5244C168.782 81.769 167.779 82.772 166.535 82.772Z"
        fill="#324AA8"
      />
      <path
        d="M166.535 94.5363H112.716C111.472 94.5363 110.469 93.5272 110.469 92.2887V90.9898C110.469 89.7452 111.478 88.7422 112.716 88.7422H166.535C167.779 88.7422 168.782 89.7513 168.782 90.9898V92.2887C168.782 93.5272 167.779 94.5363 166.535 94.5363Z"
        fill="#324AA8"
      />
      <path
        d="M82.6376 102.215H44.5092C43.2646 102.215 42.2617 101.206 42.2617 99.9672V72.4117C42.2617 71.1671 43.2706 70.1641 44.5092 70.1641H82.6376C83.8822 70.1641 84.885 71.1731 84.885 72.4117V99.9672C84.8911 101.206 83.8822 102.215 82.6376 102.215Z"
        fill="#324AA8"
      />
    </SvgIcon>
  )
}

import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Close } from '@mui/icons-material'
import { Box, Button, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { Form, SelectInput, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { createSupportTicket } from './queries'
import { errorToast, successToast } from '@momentum/utils/toastUtils'
import { useFormContext, useWatch } from 'react-hook-form'
import { useEffect } from 'react'
import { sortBy } from 'lodash'

type ContactSupportData = {
  email: string
  companyId: string
  brandId: string
  message: string
}

export const createSupportTicketSchema = yup.object({
  companyId: yup.string().required('*A company name is required in order to submit a support request.'),
  brandId: yup.string().required('*A brand name is required in order to submit a support request.'),
  email: yup
    .string()
    .email('*A valid email is required in order to submit a support request.')
    .required('*An email is required in order to submit a support request.'),
  message: yup.string().required('*A message is required in order to submit a support request.')
})

const ContactSupportForm = ({ onClose }: { onClose: () => void }) => {
  const { profile, companies, selectedCompany, selectedBrand } = useUserSessionContext()

  const handleSubmit = async (data: ContactSupportData) => {
    try {
      const { success } = await createSupportTicket({
        brandId: data.brandId,
        companyId: data.companyId,
        email: data.email,
        message: data.message,
        requestOrigin: window.location.href
      })

      if (!success) throw new Error('Something went wrong, please try again or contact your client success manager.')

      successToast('Your request for support was submitted')
      onClose()
    } catch (err) {
      console.log('err', err)
      errorToast('Something went wrong, please try again or contact your client success manager.')
    }
  }

  return (
    <Box mt={2}>
      <Form
        onSubmit={handleSubmit}
        defaultValues={{
          companyId: selectedCompany?.id,
          brandId: selectedBrand?.id,
          email: profile.email
        }}
        resolver={yupResolver(createSupportTicketSchema)}
      >
        <DialogTitle>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Submit a request</Typography>
            <IconButton size={'small'} onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="label3">
              Provide as much information as possible in your request to allow us to help you faster.
            </Typography>
            <SelectInput
              primaryText="Company name*"
              name="companyId"
              options={(sortBy(companies, 'name') || []).map(company => ({
                label: company.name,
                value: company.id
              }))}
              disableClearable
            />
            <BrandsSelect />
            <TextInput primaryText="Email*" name="email" />
            <TextInput
              primaryText="Message*"
              name="message"
              placeholder="Type your message here..."
              minRows={5}
              multiline
            />
            <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
              <Button onClick={onClose}>Cancel</Button>
              <SubmitButton variant={'contained'}>Submit</SubmitButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Form>
    </Box>
  )
}

export default ContactSupportForm

const BrandsSelect = () => {
  const { brands } = useUserSessionContext()
  const { setValue, getValues } = useFormContext()
  const selectedCompanyId = useWatch({ name: 'companyId' })

  const filteredBrands = brands.filter(brand => brand.companyId === selectedCompanyId)

  useEffect(() => {
    if (selectedCompanyId !== getValues().companyId) {
      setValue('brandId', null)
    }
  }, [selectedCompanyId, setValue, getValues])

  return (
    <SelectInput
      primaryText="Brand*"
      name="brandId"
      options={(sortBy(filteredBrands, 'name') || []).map(brand => ({
        label: brand.name,
        value: brand.id
      }))}
      disableClearable
      disabled={!selectedCompanyId}
    />
  )
}

import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { campaignSelector, proposalSelector } from './context/queries'

export const updateUserCampaignSocialContent = async (
  input: ValueTypes['ModelInputUpdateUserCampaignSocialContent']
) => {
  return (
    await mApi('mutation')({
      updateUserCampaignSocialContent: [{ input }, { id: true }]
    })
  ).updateUserCampaignSocialContent
}

export const uploadCreatorContent = async (input: ValueTypes['UploadCreatorContentInput']) => {
  const resp = await mApi('mutation')({
    uploadCreatorContent: [
      {
        input
      },
      campaignSelector.creators.content
    ]
  })

  return resp.uploadCreatorContent
}

export const updateProposal = async (input: ValueTypes['ModelInputUpdateProposal']) => {
  return (
    await mApi('mutation')({
      updateProposal: [{ input }, proposalSelector]
    })
  ).updateProposal
}

export const adjustCampaignStartDate = async (input: ValueTypes['AdjustCampaignStartDateInput']) => {
  return (
    await mApi('mutation')({
      adjustCampaignStartDate: [
        { input },
        {
          startDate: true
        }
      ]
    })
  ).adjustCampaignStartDate
}

import Loading from '@momentum/components/loading'
import { ProductCard } from '@momentum/routes/campaign/components/ProductCard'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, IconButton, Typography, useTheme } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { isNil, minBy } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { toast } from 'react-toastify'
import { SeoReportMode, useSeoContext } from '../../context'
import { ProductKeywordRecord, ProductKeywordSummary } from '../../context/selectors'
import { KeywordRowType } from './constants'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { captureException } from '@sentry/react'

export const TrendFullGraphModal = ({
  row,
  open,
  onClose
}: {
  row: KeywordRowType
  open: boolean
  onClose: () => void
}) => {
  const { seoReportMode } = useSeoContext()
  const [fullyLoadedSummary, setFullyLoadedSummary] = useState<ProductKeywordSummary>()

  const {
    campaignDetails: { product }
  } = useCampaignContext()
  const { loadAllKeywordRecords } = useSeoContext()

  useEffect(() => {
    if (open) {
      loadAllKeywordRecords(row.keyword)
        .then(setFullyLoadedSummary)
        .catch(e => {
          captureException(e)
          toast(<Typography variant={'subtitle2'}>Unknown issue, please try again later</Typography>, { type: 'error' })
          onClose()
        })
    }
  }, [open, row.keyword])

  const handleClose = () => {
    setFullyLoadedSummary(undefined)
    onClose()
  }

  const topPosition = seoReportMode === SeoReportMode.ORGANIC ? row.organicHighestRank : row.sponsoredHighestRank
  const topPositionDate = minBy(fullyLoadedSummary?.records, r =>
    seoReportMode === SeoReportMode.ORGANIC ? r.organicRank : r.sponsoredRank
  )?.date

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
      <DialogContent>
        <Stack>
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              alignSelf: 'end',
              color: 'black'
            }}
          >
            <Close />
          </IconButton>
          <Stack spacing={2}>
            <ProductCard product={product} maxWidth={600} direction="row" />

            <Stack direction={'row'} spacing={3} width={'100%'}>
              <Stack
                sx={{ p: 1 }}
                border={theme => `1px solid ${theme.palette.grey.A200}`}
                borderRadius={'4px'}
                direction={'row'}
                spacing={1}
                alignItems={'center'}
              >
                <Typography variant={'link2'}>Keyword: {row.keyword}</Typography>
                <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
                  monthly search volume: {row.searchVolume.toLocaleString()}
                </Typography>
              </Stack>
              <Stack
                sx={{ p: 2 }}
                border={theme => `1px solid ${theme.palette.grey.A200}`}
                borderRadius={'4px'}
                alignContent={'center'}
                direction={'row'}
                spacing={1}
                alignItems={'center'}
              >
                <Typography variant={'link2'}>Your product’s top position: {topPosition}</Typography>
                {topPositionDate && (
                  <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
                    reached on: {topPositionDate}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Box
              sx={{ px: 4, py: 2 }}
              border={theme => `1px solid ${theme.palette.grey.A200}`}
              borderRadius={'4px'}
              bgcolor={theme => theme.palette.grey.A100}
              minHeight={'500px'}
              alignContent={'center'}
            >
              {fullyLoadedSummary ? <TrendGraph records={fullyLoadedSummary.records} /> : <Loading />}
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

const TrendGraph = ({ records }: { records: ProductKeywordRecord[] }) => {
  const { seoReportMode } = useSeoContext()
  const theme = useTheme()

  const firstRecordWithRank = records.findIndex(
    ({ organicRank, sponsoredRank }) => !isNil(seoReportMode === SeoReportMode.ORGANIC ? organicRank : sponsoredRank)
  )
  const lastRecordWithRank = records
    .slice()
    .reverse()
    .findIndex(
      ({ organicRank, sponsoredRank }) => !isNil(seoReportMode === SeoReportMode.ORGANIC ? organicRank : sponsoredRank)
    )

  const recordsWithRanks = records.slice(firstRecordWithRank, records.length - lastRecordWithRank)

  return (
    <Line
      data={{
        labels: recordsWithRanks.map(({ date }) => DateTime.fromISO(date).toFormat('dd/MM')),
        datasets: [
          {
            borderColor: theme.palette.primary.main,
            borderWidth: 3,
            label: '',
            pointRadius: 0,
            pointHoverRadius: 0,
            data: recordsWithRanks.map(({ organicRank, sponsoredRank }) =>
              seoReportMode === SeoReportMode.ORGANIC ? organicRank : sponsoredRank
            )
          }
        ]
      }}
      options={{
        elements: { point: { radius: 0, hitRadius: 10 } },
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            ...defaultTooltipOptions,
            callbacks: {
              label: item => {
                const value = item.formattedValue
                return `Position: ${value}`
              },
              title: () => '',
              footer: () => ''
            }
          }
        },
        scales: {
          y: {
            reverse: true,
            grid: {
              drawOnChartArea: false,
              drawTicks: false
            },
            offset: true,
            ticks: {
              padding: 16,
              stepSize: 1,
              precision: 0,
              font: {
                family: theme.typography.fontFamily as string,
                weight: theme.typography.fontWeightRegular as number,
                size: theme.typography.subtitle2.fontSize as number
              },
              color: 'black'
            },
            border: {
              color: 'black',
              width: 3
            }
          },
          x: {
            type: 'category',
            ticks: {
              minRotation: 45,
              padding: 16,
              font: {
                family: theme.typography.fontFamily as string,
                weight: theme.typography.fontWeightRegular as number,
                size: theme.typography.subtitle2.fontSize as number
              }
            },
            grid: {
              drawOnChartArea: false,
              drawTicks: false,
              color: 'black'
            },
            border: {
              color: 'black',
              width: 3
            }
          }
        }
      }}
      height={'465px'}
    />
  )
}

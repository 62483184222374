import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { listBrandCampaigns, listProducts, listProposals, getProposal } from '@momentum/routes/brand/queries'
import { Campaign, Product, Proposal } from '@momentum/routes/brand/types'
import { Brand, Company } from '@momentum/routes/queries'
import { Retailer, STORE_TO_RETAILER } from '@productwindtom/shared-momentum'
import { map, max } from 'lodash'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import AddProductDialog from '../components/add-product-dialog'
import { adjustProductRecommendationVisibility } from '../mutations'
import { AddProductOrActiveCallback, BrandContextType } from './types'

const BrandContext = createContext<BrandContextType>({
  brand: {} as Brand,
  company: {} as Company,
  proposals: [],
  setProposals: () => [],
  setProducts: () => [],
  refreshProposal: async () => undefined,
  products: [],
  recommendations: [],
  campaigns: [],
  refreshCampaigns: async () => undefined,
  isAddProductActiveOrCallback: undefined,
  setIsAddProductActiveOrCallback: () => false,
  adjustProductRecommendationVisibility: async () => console.log('Not implemented')
})

const BrandProvider = () => {
  const { selectedCompany, selectedBrand: brand, loadingResources, countsByBrand } = useUserSessionContext()
  const brandId = brand?.id

  const [products, setProducts] = useState<Product[] | undefined>()
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [proposals, setProposals] = useState<Proposal[] | undefined>()
  const [recommendations, setRecommendations] = useState<Product[] | undefined>()
  const [campaigns, setCampaigns] = useState<Campaign[] | undefined>()

  const [isAddProductActiveOrCallback, setIsAddProductActiveOrCallback] = useState<AddProductOrActiveCallback>()

  const company = selectedCompany
  const productCount = brandId ? countsByBrand?.[brandId]?.totalProducts : undefined

  useEffect(() => {
    if (brandId) {
      setCampaigns(undefined)
      setProducts(undefined)
      setProposals(undefined)
      setRecommendations(undefined)

      listProducts(brandId, productCount).then(ps => {
        setProducts(ps)
        setRecommendations(ps.filter(({ recommendations }) => !!recommendations?.length))
        setLoadingProducts(false)
      })
      listProposals(brandId).then(setProposals)
      refreshCampaigns()
    }
  }, [brandId, productCount])

  const refreshCampaigns = async () => {
    if (brandId) {
      setCampaigns(await listBrandCampaigns(brandId))
    }
  }

  const refreshProposal = async (proposalId: string) => {
    const proposal = await getProposal(proposalId)
    if (proposal) {
      setProposals(prevState => (prevState || []).map(p => (p.id === proposalId ? proposal : p)))
    }
  }

  const amazonScrapedAt = useMemo(() => {
    return (
      brand?.lastScrapedAt ||
      max(
        map(
          recommendations?.filter(r => STORE_TO_RETAILER[r.store] === Retailer.AMAZON),
          product => map(product.recommendations, 'createdAt')
        ).flat()
      )
    )
  }, [recommendations, brand])

  const walmartScrapedAt = useMemo(() => {
    return (
      brand?.walmartLastScrapedAt ||
      max(
        map(
          recommendations?.filter(r => STORE_TO_RETAILER[r.store] === Retailer.WALMART),
          product => map(product.recommendations, 'createdAt')
        ).flat()
      )
    )
  }, [recommendations, brand])

  if (loadingResources || !brand || !company) {
    return <Loading />
  }

  const handleAdjustProductRecommendationVisibility = async (id: string, isVisible: boolean) => {
    const newProducts = await adjustProductRecommendationVisibility({ id, isVisible })
    setProducts(prevState => (prevState || []).map(product => (product.id === id ? newProducts : product)))
    setRecommendations(prevState => (prevState || []).map(product => (product.id === id ? newProducts : product)))
  }

  return (
    <BrandContext.Provider
      value={{
        brand,
        company,
        products,
        loadingProducts,
        proposals,
        campaigns,
        refreshCampaigns,
        setProposals,
        refreshProposal,
        recommendations,
        adjustProductRecommendationVisibility: handleAdjustProductRecommendationVisibility,
        isAddProductActiveOrCallback,
        setIsAddProductActiveOrCallback,
        amazonScrapedAt,
        walmartScrapedAt,
        setProducts
      }}
    >
      <Outlet />
      <AddProductDialog />
    </BrandContext.Provider>
  )
}

BrandContext.displayName = 'BrandContext'

export default BrandProvider

export const useBrandContext = () => useContext(BrandContext)

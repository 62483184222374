import { ZeusInterceptor } from '../types'
import { SAMPLE_BRAND_CAMPAIGNS } from './sample-data'
import { getSampleBrand } from '@momentum/api/interceptors/utils'
import { STORAGE } from '@momentum/api/interceptors/storage'
import { values } from 'lodash'

export const listBrandCampaigns: ZeusInterceptor<'query', 'listBrandCampaigns'> = async args => {
  const brandId = args.o.listBrandCampaigns?.[0].brandId!
  const sampleBrand = getSampleBrand(brandId)
  if (sampleBrand) {
    return [
      ...values(STORAGE.campaigns).filter(c => c.brandId === brandId),
      ...(SAMPLE_BRAND_CAMPAIGNS[args.o.listBrandCampaigns?.[0].brandId!] || [])
    ]
  }
}

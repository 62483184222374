import { mApi } from '@momentum/api'
import { User } from '@momentum/routes/queries'
import { Typography } from '@mui/material'
import { getAuthDomainConfigurationFromEmail } from '@productwindtom/shared-momentum'
import { Auth } from 'aws-amplify'
import { useIdleTimer } from 'react-idle-timer'
import { toast } from 'react-toastify'

export const useIdleHandler = (user?: User) => {
  const config = getAuthDomainConfigurationFromEmail(user?.email || '')
  const isIdleConfigured = config?.idleSessionTimeoutMinutes
  const onAction = async () => {
    if (user) {
      const invalidated = await isSessionInvalidated()
      if (invalidated) {
        console.log('SIGNING OUT')
        await Auth.signOut()
        toast(<Typography variant={'subtitle2'}>You have been logged out due to inactivity!</Typography>, {
          type: 'warning'
        })
      }
      if (isIdleConfigured) {
        await callPingActivity(user?.id)
      }
    }
  }

  useIdleTimer({
    onAction,
    timeout: 1_000,
    throttle: 1_000 * 60
  })
}

const callPingActivity = async (userId?: string) => {
  await mApi('mutation')({
    pingActivity: [{ userId }, true]
  })
}

const isSessionInvalidated = async (): Promise<boolean> => {
  let user = await Auth.currentUserPoolUser()
  if (!user) {
    return true
  }

  try {
    // try to fetch user attributes, this should fail with NotAuthorized if session was revoked
    await Auth.userAttributes(user)
    return false
  } catch (err: any) {
    console.error('Error retrieving user information', err)
    if (err.code === 'NetworkError') {
      return false
    }
    return true
  }
}

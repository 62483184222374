import { DateTime } from 'luxon'

export const getFeedbackTime = (atTime: number) => DateTime.fromMillis(atTime * 1000).toFormat('mm:ss')

export const getReviewedAtTime = (createdAt: string) => {
  const { days, hours, minutes, seconds } = DateTime.now().diff(DateTime.fromISO(createdAt), [
    'days',
    'hours',
    'minutes',
    'seconds'
  ])

  const timeStrings = [
    days && `${days} days`,
    hours && `${Math.round(hours)} hrs`,
    minutes && `${Math.round(minutes)} mins`,
    seconds && `${Math.round(seconds)} secs`
  ].filter(val => !!val)

  return timeStrings.slice(0, 2).join(', ')
}

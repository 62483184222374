import { BenchmarkType, ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_BENCHMARK_LAUNDRY: ModelTypes['CampaignBenchmark'] = {
  skuId: 'sample-sku-laundry',
  campaignId: 'sample-campaign-laundry',
  benchmarkedProductIds: ['sample-sku-laundry'],
  benchmarkGoal: 10000,
  benchmarkType: BenchmarkType.UNITS_SOLD,
  createdAt: '2021-07-01T00:00:00.000Z',
  updatedAt: '2021-07-01T00:00:00.000Z'
}

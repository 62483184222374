import { CreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { findMostCommonValue } from '@productwindtom/shared-node'
import {
  ProductQualityLevel,
  Selector,
  SocialChannel,
  InputType,
  GraphQLTypes
} from '@productwindtom/shared-ws-zeus-types'
import { LogMetricSeverity } from '@productwindtom/shared-aws-types'
import { lambdaLog } from '@productwindtom/shared-lambda-utils'
import { Flow } from '@productwindtom/shared-campaign'

const inputSelector = Selector('Product')({
  qualityLevel: true,
  brandAdvocateCount: true,
  campaignStateMachine: true,
  contentRequirement: {
    channel: true
  }
})
export type Product = InputType<GraphQLTypes['Product'], typeof inputSelector>

export const getCreatorType = (product?: Product): CreatorType | undefined => {
  const channel = findMostCommonValue(product?.contentRequirement ?? [], 'channel')

  if (channel) {
    switch (channel) {
      case SocialChannel.INSTAGRAM:
        return CreatorType.IG
      case SocialChannel.TIKTOK:
        return CreatorType.TT
      case SocialChannel.UGC:
        return product?.qualityLevel === ProductQualityLevel.PREMIUM ? CreatorType.PREMIUM_UGC : CreatorType.UGC
      case SocialChannel.YOUTUBE:
        return CreatorType.YOUTUBE
      case undefined:
      case SocialChannel.RETAILER:
        return CreatorType.ADVOCATE
      default:
        lambdaLog(`Invalid channel ${channel}`, LogMetricSeverity.SEVERE)
        return
    }
  }
  if (product?.campaignStateMachine === Flow.no_content_v1 && product?.brandAdvocateCount) {
    return CreatorType.ADVOCATE
  }
}

import { CreatorType, PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { keyBy, sum } from 'lodash'
import { SOCIAL_CREATOR_TYPES } from '../configs'

export type ProposalCreatorCounts = ReturnType<typeof getProposalCreatorCounts>

export const getProposalCreatorCounts = (
  creatorPricing?: {
    numCreators: number
    price: number
    type: PricingCreatorType | CreatorType
  }[]
) => {
  const totalCreators = sum(creatorPricing?.map(cp => cp.numCreators)) || 0
  const creatorsByType = keyBy(creatorPricing, c => c.type)

  const brandAdvocateProposalCount = creatorsByType[PricingCreatorType.ADVOCATE]?.numCreators || 0
  const ugcProposalCount = creatorsByType[PricingCreatorType.UGC]?.numCreators || 0
  const premiumUgcProposalCount = creatorsByType[PricingCreatorType.PREMIUM_UGC]?.numCreators || 0

  const brandAdvocateCost = brandAdvocateProposalCount
    ? brandAdvocateProposalCount * creatorsByType?.[PricingCreatorType.ADVOCATE].price
    : undefined
  const ugcCost = ugcProposalCount ? ugcProposalCount * creatorsByType?.[PricingCreatorType.UGC].price : undefined
  const premiumUgcCost = premiumUgcProposalCount
    ? premiumUgcProposalCount * creatorsByType?.[PricingCreatorType.PREMIUM_UGC].price
    : undefined

  let socialCost: number
  let socialProposalCount: number
  const socialPricingEntry = creatorsByType[PricingCreatorType.SOCIAL]
  if (socialPricingEntry) {
    socialProposalCount = socialPricingEntry.numCreators
    socialCost = socialPricingEntry.numCreators * socialPricingEntry.price
  } else {
    const socialChannelEntries = SOCIAL_CREATOR_TYPES.map(creatorType => creatorsByType?.[creatorType])
    socialProposalCount = sum(socialChannelEntries.map(entry => entry?.numCreators))
    socialCost = sum(socialChannelEntries.map(entry => entry?.numCreators * entry?.price))
  }

  const totalCreatorCost = sum([brandAdvocateCost, ugcCost, premiumUgcCost, socialCost].filter(notEmpty))

  return {
    totalCreators,
    brandAdvocateProposalCount,
    ugcProposalCount,
    premiumUgcProposalCount,
    socialProposalCount,
    brandAdvocateCost,
    ugcCost,
    premiumUgcCost,
    socialCost,
    totalCreatorCost
  }
}

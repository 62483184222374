import { ModelTypes, PricingCreatorType, Region, Store } from '@productwindtom/shared-momentum-zeus-types'
import { REGIONAL_STORES, STORES_BY_REGION } from '../stores'

export const DEFAULT_PRICING: Record<
  PricingCreatorType,
  {
    min: number
    max: number
    default: number
  }
> = {
  [PricingCreatorType.UGC]: {
    min: 25000,
    max: 55000,
    default: 350
  },
  [PricingCreatorType.PREMIUM_UGC]: {
    min: 25000,
    max: 55000,
    default: 600
  },
  [PricingCreatorType.ADVOCATE]: {
    min: 3000,
    max: 7500,
    default: 50
  },
  [PricingCreatorType.SOCIAL]: {
    min: 120000,
    max: 180000,
    default: 1800
  }
}

export const SUPPORTED_CREATOR_TYPES = [
  PricingCreatorType.ADVOCATE,
  PricingCreatorType.UGC,
  PricingCreatorType.PREMIUM_UGC,
  PricingCreatorType.SOCIAL
]

export const SUPPORTED_AGENCY_CREATOR_TYPES = [PricingCreatorType.ADVOCATE, PricingCreatorType.UGC]

export const SUPPORTED_STORES = [
  Store.amazon,
  Store.amazon_ca,
  Store.amazon_uk,
  Store.amazon_de,
  Store.walmart,
  Store.target,
  Store.other
]

export type StorePricing = {
  store: Store
  creatorPricing: CreatorPricing[]
}

export type CreatorPricing = {
  type: PricingCreatorType
  price?: number | null // if price is overriden, it will be set here
  fallbackPrice: number // otherwise, this default price should be used
}

export const getBrandStorePricing = (
  brandRegion: Region,
  brandPricing?: Array<Partial<ModelTypes['Pricing']>>,
  companyPricing?: Array<Partial<ModelTypes['Pricing']>>,
  clientAgencyPricing?: Array<Partial<ModelTypes['Pricing']>>
) => {
  return buildStorePricing(
    brandPricing ?? [],
    getCompanyStorePricing(companyPricing, clientAgencyPricing),
    STORES_BY_REGION[brandRegion].filter(s => SUPPORTED_STORES.includes(s))
  )
}

export const getCompanyStorePricing = (
  companyPricing?: Array<Partial<ModelTypes['Pricing']>>,
  clientAgencyPricing?: Array<Partial<ModelTypes['Pricing']>>
) => {
  return buildStorePricing(
    companyPricing,
    getAgencyStorePricing(clientAgencyPricing),
    STORES_BY_REGION[Region.US].filter(s => SUPPORTED_STORES.includes(s))
  )
}

export const getAgencyStorePricing = (agencyPricing?: Array<Partial<ModelTypes['Pricing']>>) => {
  return buildStorePricing(
    agencyPricing,
    [],
    STORES_BY_REGION[Region.US].filter(s => SUPPORTED_STORES.includes(s))
  )
}

// Maps ModelTypes['Pricing'] into StorePricing, filling in missing creatorPricing with fallback pricing.
// if no fallbackPrice is provided, it will use the default pricing.
export const buildStorePricing = (
  ownStorePricing: Array<Partial<ModelTypes['Pricing']>> = [],
  fallbackStorePricing: StorePricing[] = [], // company or agency pricing
  supportedStores: Store[]
): StorePricing[] => {
  return supportedStores.map(store => ({
    store,
    creatorPricing: SUPPORTED_CREATOR_TYPES.map(type => {
      const ownCreatorPricing = (ownStorePricing || [])
        .find(bp => bp.store === store)
        ?.creatorPricing?.find(cp => cp.type === type)

      const fallbackCreatorPricing = fallbackStorePricing
        .find(bp => bp.store === store)
        ?.creatorPricing.find(cp => cp.type === type)

      return {
        type,
        price: ownCreatorPricing?.price,
        fallbackPrice:
          fallbackCreatorPricing?.price ?? fallbackCreatorPricing?.fallbackPrice ?? DEFAULT_PRICING[type]?.default
      }
    })
  }))
}

// Expands store pricing so that all regional stores use the same pricing.
// Used when calculating Agency or Company pricing where the pricing is the same across all regional stores.
export const createRegionalStorePricings = (id: string, storePricing: StorePricing[]) => {
  return storePricing.flatMap(sp =>
    (REGIONAL_STORES[sp.store] || []).map(store => ({
      id,
      store: store,
      creatorPricing: sp.creatorPricing
        .filter(cp => cp.price != null)
        .map(cp => ({
          type: cp.type,
          price: cp.price!
        }))
    }))
  )
}

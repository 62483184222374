import { useTheme } from '@mui/material'
import { orderBy } from 'lodash'
import { useState } from 'react'
import { Line } from 'react-chartjs-2'
import { SeoReportMode, useSeoContext } from '../../context'
import { TrendFullGraphModal } from './TrendFullGraphModal'
import { KeywordRowType } from './constants'

export const TrendMiniGraph = ({ row }: { row: KeywordRowType }) => {
  const { seoReportMode } = useSeoContext()
  const theme = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const sortedRecords = orderBy(row.trend, t => t.date, 'asc')

  // manually reverse the ranks so that the graph goes from top to bottom and
  // keep it in the positive range so that the area under the line is filled correctly
  const reversedRanks = sortedRecords.map(({ organicRank, sponsoredRank }) => {
    switch (seoReportMode) {
      case SeoReportMode.ORGANIC:
        return organicRank ? Number.MAX_SAFE_INTEGER - organicRank : organicRank
      case SeoReportMode.SPONSORED:
        return sponsoredRank ? Number.MAX_SAFE_INTEGER - sponsoredRank : sponsoredRank
    }
  })

  if (!reversedRanks.filter(r => r).length) {
    return null
  }

  let width: number, height: number, gradient: CanvasGradient | undefined
  return (
    <>
      <Line
        onClick={() => setIsModalOpen(true)}
        style={{ cursor: 'pointer', padding: 4 }}
        data={{
          labels: reversedRanks.map(() => ''),
          datasets: [
            {
              fill: true,
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
              backgroundColor: context => {
                const chart = context.chart
                const { ctx, chartArea } = chart

                if (!chartArea) {
                  // This case happens on initial chart load
                  return
                }
                const chartWidth = chartArea.right - chartArea.left
                const chartHeight = chartArea.bottom - chartArea.top
                if (!gradient || width !== chartWidth || height !== chartHeight) {
                  // Create the gradient because this is either the first render
                  // or the size of the chart has changed
                  width = chartWidth
                  height = chartHeight
                  gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
                  gradient.addColorStop(0, '#FFFFFF')
                  gradient.addColorStop(1, theme.palette.primary.main)
                }
                return gradient
              },
              label: '',
              pointRadius: 0,
              pointHoverRadius: 0,
              data: reversedRanks
            }
          ]
        }}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          spanGaps: true,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          scales: {
            y: {
              display: false
            },
            x: {
              display: false
            }
          }
        }}
        height={'56px'}
        width={'56px'}
      />
      <TrendFullGraphModal row={row} open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}

import { Paper, Stack, Typography } from '@mui/material'
import { pluralWord } from '@momentum/utils/stringUtils'

type CompanyAgency = {
  id: string
  name: string
  companyId?: string
  logo?: string
  products?: number
  brands?: number
  proposals?: number
}

export type CompanyAgencyCardProps = {
  resource: CompanyAgency
  onClick?: () => void
}

export const CompanyAgencyCard = ({ resource, onClick }: CompanyAgencyCardProps) => {
  const { products = 0 } = resource
  return (
    <Paper data-cy={`companyAgencyCard-${resource.id}`} sx={{ p: 1, cursor: 'pointer' }} onClick={onClick}>
      <Stack spacing={2}>
        <img style={{ height: 140, objectFit: 'contain' }} src={resource.logo} alt={`${resource.name} logo`} />
        <Stack spacing={0.5}>
          <Typography variant={'subtitle1'}>{resource.name}</Typography>
          <Stack direction={'row'} spacing={2}>
            <Typography variant={'caption2'}>
              {resource.brands?.toLocaleString()} {pluralWord('Brand', (resource.brands || 0) !== 1)}
            </Typography>
            <Typography variant={'caption2'}>
              {products.toLocaleString()} {pluralWord('Product', products !== 1)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

import { LinkProps, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
  reloadDocument?: boolean
}

export const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink} />

export default LinkRouter
